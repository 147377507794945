import React,{useState} from "react";
import { Tab, Tabs } from "react-bootstrap";
import MasterHeadCreate from "./MasterHeadCreate";
import MasterHeadView from "./MasterHeadView";

function MasterHeadTab() {
  const [viewTabClicked, setViewTabClicked] = useState(false);
  const [activeKey, setActiveKey] = useState("tab1");
  const handleTabClick = (tabKey) => {
    setActiveKey(tabKey)
    if (tabKey === "tab2") {
      setViewTabClicked(true);
    } else {
      setViewTabClicked(false);
    }
  };
  return (
    <div>
      <div>
        <Tabs
          defaultActiveKey="tab1"
          // transition={false}
          id="noanim-tab-example"
          className="mb-3"
          onSelect={handleTabClick}
        >
          <Tab name="M.head.CreateTab" eventKey="tab1" title="Create">
            <div>
              <div className="set-layout">
                <MasterHeadCreate tab1={activeKey === "tab1"}/>
              </div>
            </div>
          </Tab>
          <Tab name="M.head.ViewTab" eventKey="tab2" title="View">
            <div>
              <div className="set-layout">
              {viewTabClicked &&<MasterHeadView />}
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default MasterHeadTab;
