import React, { useEffect, useState, useRef } from "react";
import {
  SECRET_KEY,
  CODEGEN_URL,
  PROCESS_TRANSACTION_URL,
} from "../../../Utils/constants.js";
import { getAPIRequest } from "../../../Utils/APIUtility";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import axios from "axios";
import { API_URL } from "../../../Utils/constants.js";
import "../../../Styles/MCActRequestReport.css";
import Unitechlogo from "../../../icons/Unitechlogo.jpeg";
import DropdownFetcher from "../../../Lib/DropdownFetcher.js";
import DatePicker from "../../../Lib/DatePicker.js";
import useScanDetection from "use-scan-detection";
import Select from "react-select";
import { setMessage } from "../../../Lib/SetMessage.js";
import { HoursMinutesSelect } from "../../../Lib/Hrs,Min.js";
import CreateApproval from "../../../Lib/CreateApproval";
import ReadRecord from "../../../Lib/API/ReadRecord.js";
import {
  CURRENT_Date,
  CURRENT_TIME,
  SHIFTMOLDINGPRODUCTION,
} from "../../../Utils/GeneralConstants";

function mcareqctRequestReportCreate() {
  const scrollTop = useRef(null);
  const handleDateChange = (newDate) => {
    setDate1(newDate);
  };
  // const handleDateChange1 = (newDate) => {
  //   setDate0(newDate);
  // };
  const handleHoursChange = (hours) => {
    setSelectedHours(hours);
    setTWhrs(`${hours}:${selectedMinutes}`);
  };

  const handleMinutesChange = (minutes) => {
    setSelectedMinutes(minutes);
    setTWhrs(`${selectedHours}:${minutes}`);
  }

  const [MouldName, setMouldName] = useState([]);

  //const [Date0, setDate0] = useState("");
  const [ERPReq, setERPReq] = useState("");
  const [prb, setprb] = useState("");
  const [Date1, setDate1] = useState("");
  const [SftTm, setSftTm] = useState(SHIFTMOLDINGPRODUCTION);
  const [BAY, setBay] = useState("");
  const [MacNo, setMacNo] = useState("");
  const [preSOM, setpreSOM] = useState("");
  const [customerName, setcustomerName] = useState("");
  const [Model, setModel] = useState("");
  const [ModelNumber, setModelNumber] = useState("");
  const [Mldname, setMldname] = useState("");
  const [Prio, setPrio] = useState("");
  const [NofPrb, setNofPrb] = useState("");
  const [AppCV, setAppCV] = useState("");
  const [RootCA, setRootCA] = useState("");
  const [MTC, setMTC] = useState("");
  const [CycTm, setCycTm] = useState("");
  const [WhyWhy, setWhyWhy] = useState("");
  const [Alrm, setAlrm] = useState("");
  const [Cusion, setCusion] = useState("");
  const [InjP, setInjP] = useState("");
  const [EjP, setEjP] = useState("");
  const [Speed, setSpeed] = useState("");
  const [Temp, setTemp] = useState("");
  const [LimitC, setLimitC] = useState("");
  const [Press, setPress] = useState("");
  const [RootC, setRootC] = useState("");
  const [CorrAct, setCorrAct] = useState("");
  const [SpareD, setSpareD] = useState("");
  const [Cost, setCost] = useState("");
  const [Stock, setStock] = useState("");
  const [ERPReqS, setERPReqS] = useState("");
  const [TWhrs, setTWhrs] = useState('');
  const [selectedHours, setSelectedHours] = useState(0);
  const [selectedMinutes, setSelectedMinutes] = useState(0);
  const [PRP1, setPRP1] = useState("");
  const [PRP2, setPRP2] = useState("");
  const [PRP3, setPRP3] = useState("");
  const [StsORec, setStsORec] = useState("");
  const [QP1, setQP1] = useState("");
  const [QP2, setQP2] = useState("");
  const [QP3, setQP3] = useState("");

  const [ReqBy, setReqBy] = useState("");
  const [SignBy, setSignBy] = useState("");
  const [APRBy, setAPRBy] = useState("");
  const [ProHd, setProHd] = useState("");
  const [Qty, setQty] = useState("");
  const [Purc, setPurc] = useState("");
  const [PlHd, setPlHd] = useState("");
  const [Rsn, setRsn] = useState("");

  var [mcareq_req, setmcareq_req] = useState([]);
  const [machine, setmachine] = useState([]);
  const [Customer, setCustomer] = useState([]);
  const [Employee, setEmployee] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  //Unique id and name
  const id = "mcareqreport";
  const name = "mcareqreport";

  // const CodeGen = async () => {
  //   try {
  //     const val = {
  //       tablename: "mould_corrective",
  //       PrimaryCode: "Erp_req_no",
  //       PrimaryVal: ERPReq,
  //       FieldCode: "Model",
  //       FieldVal: Model,
  //     };

  //     const response = await axios.post(
  //       `http://${API_URL}/AppContextService/CodeGen`,
  //       val
  //     );
  //     return response.data.success === "true";
  //   } catch (error) {
  //     throw error;
  //   }
  // };

  const handleCreate = async (e) => {
    e.preventDefault();
    const isSubmissionSuccessful = true;

    if (

      !ERPReq
      || !MouldName || !MacNo || !Customer || !ReqBy || !SignBy || !APRBy || !ProHd || !Qty || !Purc || !PlHd

    ) {

      alert("Please fill the mandatory fields");

      setIsCreating(true);
      if (scrollTop.current) {
        scrollTop.current.scrollIntoView({ behavior: "smooth" });
      }
      return;
    }

    try {
      // const isDuplicate = await CodeGen();

      // if (isDuplicate) {
      //   alert(`${ERPReq} Already Exist.`);
      //   setERPReq("");
      //   return;
      // }

      const data = {
        data: [
          {

            // RDate: Date0,
            erp_req_no: ERPReq,
            Prbs: prb,
            M_date: Date1,
            Shift_time: SftTm,
            Bay: BAY,
            Machine_no: MacNo.value,
            Machine_status: preSOM,
            Customer: customerName.value,
            Model: Model,
            Mould_name: Mldname.value,
            Mould_number: ModelNumber,
            Priority: Prio,
            N_of_prob: NofPrb,
            Approx_cost: AppCV,
            Root_causeA: RootCA,
            Mtc: MTC,
            Cycle_time: CycTm,
            Mould: WhyWhy,
            Alarm: Alrm,
            Cusion: Cusion,
            Injection_pressure: InjP,
            Ejection_pressure: EjP,
            Speed: Speed,
            Temperature: Temp,
            Limit_switch_connection: LimitC,
            Pressure: Press,
            Root_cause: RootC,
            Corrective_action: CorrAct,
            Spare_details: SpareD,
            Cost: Cost,
            Stock: Stock,
            Erp_req_status: ERPReqS,
            total_working_hours: TWhrs,
            prod_points1: PRP1,
            prod_points2: PRP2,
            prod_points3: PRP3,
            quality_points1: QP1,
            quality_points2: QP2,
            quality_points3: QP3,
            rectification_status: StsORec,
            requested_by: ReqBy.value,
            tech_sign: SignBy.value,
            tool_hod_approval: APRBy.value,
            production_hod: ProHd.value,
            quality: Qty.value,
            purchase: Purc.value,
            plant_head_approval: PlHd.value,
          },
        ],
      }
      data["_keyword_"] = "MouldCorrective";
      data["secretkey"] = SECRET_KEY;

      const response = await getAPIRequest(
        PROCESS_TRANSACTION_URL,
        "POST",
        data
      )
      const response1 = await response.json();
      console.log("response1", response1);
      // setMessage({
      //   NoteFrom: localStorage.getItem("empId"),
      //   NoteTo: ReqBy.value,

      //   NoteMessage: 
      //   `The MouldCorrective Action Report Has been Created By ${localStorage.getItem("empId")}-
      //   ${localStorage.getItem("name")} 
      //   , For The Mould Name - 
      //   ${Mldname.value}
      //   and The Problem is
      //   ${prb}`,
      //   NoteRecordId: response1.ROWID,
      //   TableName: "MCActRequestReport",
      //   NoteCreatedBy : localStorage.getItem("empId"),


      // });
      setMessage({
        NoteFrom: localStorage.getItem("empId"),
        NoteTo: SignBy.value,

        NoteMessage:
          `The MouldCorrective Action Report Has been Created By ${localStorage.getItem("empId")}-
        ${localStorage.getItem("name")} 
        , For The Mould Name - 
        ${Mldname.value}
        and The Problem is
        ${prb}`,
        NoteRecordId: response1.ROWID,
        TableName: "MCActRequestReport",
        NoteCreatedBy: localStorage.getItem("empId"),


      });
      setMessage({
        NoteFrom: localStorage.getItem("empId"),
        NoteTo: APRBy.value,

        NoteMessage:
          `The MouldCorrective Action Report Has been Created By ${localStorage.getItem("empId")}-
        ${localStorage.getItem("name")} 
        , For The Mould Name - 
        ${Mldname.value}
        and The Problem is
        ${prb}`,
        NoteRecordId: response1.ROWID,
        TableName: "MCActRequestReport",
        NoteCreatedBy: localStorage.getItem("empId"),


      });
      setMessage({
        NoteFrom: localStorage.getItem("empId"),
        NoteTo: ProHd.value,

        NoteMessage:
          `The MouldCorrective Action Report Has been Created By ${localStorage.getItem("empId")}-
        ${localStorage.getItem("name")} 
        , For The Mould Name - 
        ${Mldname.value}
        and The Problem is
        ${prb}`,
        NoteRecordId: response1.ROWID,
        TableName: "MCActRequestReport",
        NoteCreatedBy: localStorage.getItem("empId"),


      });
      setMessage({
        NoteFrom: localStorage.getItem("empId"),
        NoteTo: Qty.value,

        NoteMessage:
          `The MouldCorrective Action Report Has been Created By ${localStorage.getItem("empId")}-
        ${localStorage.getItem("name")} 
        , For The Mould Name - 
        ${Mldname.value}
        and The Problem is
        ${prb}`,
        NoteRecordId: response1.ROWID,
        TableName: "MCActRequestReport",
        NoteCreatedBy: localStorage.getItem("empId"),


      });
      //  console.log('message:', message);
      setMessage({
        NoteFrom: localStorage.getItem("empId"),
        NoteTo: Purc.value,

        NoteMessage:
          `The MouldCorrective Action Report Has been Created By ${localStorage.getItem("empId")}-
      ${localStorage.getItem("name")} 
      , For The Mould Name - 
      ${Mldname.value}
      and The Problem is
      ${prb}`,
        NoteRecordId: response1.ROWID,
        TableName: "MCActRequestReport",
        NoteCreatedBy: localStorage.getItem("empId"),


      });

      setMessage({
        NoteFrom: localStorage.getItem("empId"),
        NoteTo: PlHd.value,

        NoteMessage:
          `The MouldCorrective Action Report Has been Created By ${localStorage.getItem("empId")}-
      ${localStorage.getItem("name")} 
      , For The Mould Name - 
      ${Mldname.value}
      and The Problem is
      ${prb}`,
        NoteRecordId: response1.ROWID,
        TableName: "MCActRequestReport",
        NoteCreatedBy: localStorage.getItem("empId"),

      });

      CreateApproval({
        RequestedBy:
          ReqBy.value,
        Approver: APRBy.value,
        Level: 2,
        Status: "Waiting for Approval",
        TableName: "MCActRequestReport",
        TableID: response1.ROWID,
        Description: `The MouldCorrective Action Report Has been Created By ${localStorage.getItem("empId")}-
      ${localStorage.getItem("name")} 
      , For The Mould Name - 
      ${Mldname.value}
      and The Problem is
      ${prb}`,
        CreatedBy: localStorage.getItem("empId"),
      });

      CreateApproval({
        RequestedBy:
          ReqBy.value,
        Approver: ProHd.value,
        Level: 1,
        Status: "Waiting for Approval",
        TableName: "MCActRequestReport",
        TableID: response1.ROWID,
        Description: `The MouldCorrective Action Report Has been Created By ${localStorage.getItem("empId")}-
      ${localStorage.getItem("name")} 
      , For The Mould Name - 
      ${Mldname.value}
      and The Problem is
      ${prb}`,
        CreatedBy: localStorage.getItem("empId"),
      });



      // if (response.data.success === "false") {
      //   alert("Problem in Saving Machine details");
      // } else {
      //   setmcareq_req([...mcareq_req, response.data]);
      // }
      console.log(response1.feedback[0].success);
      if (response1.feedback[0].success == true) {

        console.log(response1.feedback[0].success);
        alert("Submission success.");
        if (scrollTop.current) {
          scrollTop.current.scrollIntoView({ behavior: "smooth" });
        }

        window.location.reload();

      }

    } catch (error) {
      console.log(error);
      alert("Submission failed.");

      window.location.reload();

    }

  };
  // const determineShift = () => {
  //   const datetime = new Date();
  //   if (datetime.getHours() < 18 && datetime.getMinutes() < 59) {
  //     return "A12";
  //   } else {
  //     return "B12";
  //   }
  // };

  // barcode scannning to get input 
  useScanDetection({
    onComplete: (code) => {
      code = code.replaceAll("Shift", "");
      setField(code);
    },
    minLength: 3,
    // preventDefault: true,
  });

  // set value to the appropiate field
  function setField(code) {
    let isnumber = Number(code).toString() !== "NaN";
    let current_element = document.activeElement.id;

    if (code.includes("EMP")) {
      if (current_element === "" || current_element === "react-select-5-input") {
        handleEmployee({ value: code });
      } else {
        alert("Invalid Input");
        return false;
      }
    } else
      if (code.includes("RMI")) {
        alert("Invalid Input");
        return false;
      } else if (code.includes("CUS")) {
        if (current_element === "" || current_element === "react-select-3-input") {

          MacNo && handleCustomer({ value: code })
        } else {
          alert("Invalid Input");
          return false;
        }
      } else if (code.includes("FG")) {
        alert("Invalid Input");
        return false;
      } else if (code.includes("IMM")) {
        if (current_element === "" || current_element === "react-select-2-input") {
          setMacNo({ value: code, label: code });
          // handleMachine({ value: code })
          GetPartModel(code);
          GetBayMachine(code);
          // GetSelectValue1(code, "mac_part_dtl_with_model_dropdown");
          // GetPartModel(code);

        } else {
          alert("Invalid Input");
          return false;
        }
      } else if (code.includes("SUP")) {
        alert("Invalid Input");
        return false;
      } else {
        MacNo && handleMouldName({ value: code });
        // GetSelectValue1(code, "mac_part_dtl_with_model_dropdown");

      }

  }

  const handleMouldName = (selectedOption) => {
    console.log("selectedOption", selectedOption);
    const selected = MouldName.find(
      (option) => option.mc_part_name === selectedOption.value
    );

    if (selected) {
      setMldname({ value: selected.mc_part_name, label: selected.mc_part_name });
      setModelNumber(selected.mc_part_code);
      setModel(selected.mc_part_flex_01);
    } else {
      alert('Selected Part not connected with Machine');
    }
  };
  const handleMachine = (selectedOption) => {
    GetPartModel(selectedOption.value);
    GetBayMachine(selectedOption.value);
    const selectedMachine = machine.find(
      (option) => option.mh_mac_name === selectedOption.value
    );
    if (selectedMachine) {
      setMacNo({ value: selectedMachine.mh_mac_name, label: selectedMachine.mh_mac_name });
      setMldname("");
      setModel("");
      setModelNumber("");
      // setBay(selectedMachine.mc_flex_01);
    } else {
      alert("Selected Machine is not available in SMP!");
    }
  };
  function GetBayMachine(val) {
    ReadRecord({
      _keyword_: "Get_bay_by_machine",
      machine: val,
    }).then((res) => {
      if (res.rows > 0) {
        // console.log("respose of raw", res.data);
        setBay(res.data[0].mc_mac_bay);
      } else {
        alert("Contact Administrator to add Bay for this Selected Machine!");
      }
    })
  }
  function GetPartModel(val) {
    ReadRecord({
      _keyword_: "Get_part_from_smp_by_machine",
      machine: val,
    }).then((res) => {
      if (res.rows == "1") {
        // console.log("respose of raw", res.data);
        // setBay(res.data[0].mc_mac_bay);
        setMldname({ value: res.data[0].mc_part_name, label: res.data[0].mc_part_name });
        setModelNumber(res.data[0].mc_part_code);
        setModel(res.data[0].mc_part_flex_01);
      } else if (res.rows > "1") {
        setMouldName(res.data);
      } else {
        alert("Contact Administrator to add Part for this Selected Machine!");
      }
    })
  }
  const handleCustomer = (selectedOption) => {
    const selected = Customer.find(
      (option) => option.Cus_code === selectedOption.value
    );

    if (selected) {
      setcustomerName({
        value: selected.Cus_code,
        label: selected.Cus_name,
      });
    }
  };
  const handleEmployee = (selectedOption) => {
    console.log("selectedOption", selectedOption);
    const selected = Employee.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setReqBy({
        value: selected.Emp_code,
        label: selected.Emp_name,
      });
    }
  };

  useEffect(() => {
    handleDateChange(new Date().toISOString().split("T")[0]);
    // setSftTm(determineShift());
    document.title = "Mould Corrective Action"
  }, []);
  function GetSelectValue(value, keyword, setName) {
    if (value.length > 0) {
      ReadRecord({
        _keyword_: keyword,
        key: "%" + value + "%",
        value: "%" + value + "%"
      }).then((res) => {
        console.log(res.data);
        setName(res.data);
        // setKey(keyvalue[options.reference]);
      });
    }
  }
  function GetSelectValue1(value, keyword) {
    ReadRecord({
      _keyword_: keyword,
      key: "%" + value + "%",
      value: "%" + value + "%"
    }).then((res) => {
      if (res.rows > 0) {
        setMldname({ value: res.data[0].mc_part_code, label: res.data[0].mc_part_name });
        setModelNumber(res.data[0].mc_part_code);
        setModel(res.data[0].mc_part_flex_01);
      } else {
        alert("Not Available")
      }
    });
  }

  return (
    <div>
      <form
        id="mcareq-formContainer-Create"
        name="mcareq-formContainer-Create"
        className="form-mcareq-Create"
      >
        <div ref={scrollTop}>
          <table style={{ textAlign: "center", width: "100%" }}>
            <tbody>
              <tr>
                {/* <th colSpan={1} style={{ textAlign: "center", width: "10%" }}>
                <img src={Unitechlogo} style={{ width: "40px" }}></img>
              </th> */}
                {/* <th
                id={`${id}-Title`}
                name={`${name}-Title`}
                colSpan={7}
                style={{ textAlign: "center",padding:"20px" }}
              >
                <font color="#8B0000">UNITECH PLASTO COMPONENTS (P) LTD</font>
              </th> */}
                {/* <th colSpan={2}>
                <span
                  style={{ marginRight: "135px", float: "left", width: "30%" }}
                >
                  FORMAT NO :
                </span>
                <label>{"UPCL/PRO/R06"}</label>
                <span
                  style={{ marginRight: "133px", float: "left", width: "30%" }}
                >
                  REV NO :
                </span>
                <label>{"02"}</label>
                <span
                  style={{ marginRight: "135px", float: "left", width: "30%" }}
                >
                  DATE :
                </span>
                <label>{"05.01.2021"}</label>
              </th> */}
              </tr>
              <tr>
                <th
                  colSpan={6}
                  style={{
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    padding: "10px 0px",
                    color: "#8B0000"
                  }}
                >
                  Mould Corrective Action Request Report
                </th>
                <th colSpan={1}>
                  <span
                    style={{ marginRight: "5px", float: "left", width: "70%" }}
                  >
                    ERP REQ NO<span style={{ color: "red" }}>*</span> :
                  </span>
                  {ERPReq === '' && isCreating && <span className="Req">Required *</span>}
                  <input
                    id="mcareq.Cr.erpno"
                    name="mcareq.Cr.erpno"
                    type="number"
                    value={ERPReq}
                    onChange={(e) => setERPReq(e.target.value)}
                    style={{ width: "97%", backgroundColor: "#efd9b1" }}
                  />
                </th>
              </tr>
              <tr>
                <td colSpan={2}>
                  {prb === '' && isCreating && <span className="Req">Required *</span>}
                  <label>
                    <input
                      className="mcareq-checkbox"
                      type="radio"
                      value="MOULD PROBLEM"
                      checked={prb === "MOULD PROBLEM"}
                      onChange={(e) => setprb(e.target.value)}
                      disabled={ERPReq === ""}
                    />
                    MOULD PROBLEM
                  </label>
                </td>

                <td colSpan={2}>
                  <label>
                    <input
                      className="mcareq-checkbox"
                      type="radio"
                      value={"PRODUCTION PROBLEM"}
                      checked={prb === "PRODUCTION PROBLEM"}
                      onChange={(e) => setprb(e.target.value)}
                      disabled={ERPReq === ""}

                    />
                    PRODUCTION PROBLEM
                  </label>
                </td>

                <td colSpan={3}>
                  <label>
                    <input
                      className="mcareq-checkbox"
                      type="radio"
                      value={"FLASH PROBLEM"}
                      checked={prb === "FLASH PROBLEM"}
                      onChange={(e) => setprb(e.target.value)}
                      disabled={ERPReq === ""}

                    />
                    FLASH PROBLEM
                  </label>
                </td>
              </tr>
              <tr>
                <td className="topic-head">
                  <span style={{ marginRight: "0px", float: "left" }}>
                    Date<span style={{ color: "red" }}>*</span> :
                  </span>
                  <DatePicker value={Date1} onChange={handleDateChange} dis={true} />
                </td>
                <td className="topic-head">
                  <span
                    style={{ marginRight: "5px", float: "left", width: "55%" }}
                  >
                    SHIFT/TIME<span style={{ color: "red" }}>*</span> :
                  </span>
                  <select
                    value={SftTm}
                    onChange={(e) => setSftTm(e.target.value)}
                    style={{ textAlign: "center" }}
                    disabled
                  >
                    <option
                      id={`${id}-Shift-Select`}
                      name={`${name}-Shift-Select`}
                      value=""
                    >
                      -- Select --
                    </option>
                    <option value="A">A12</option>
                    <option value="B">B12</option>
                  </select>
                </td>
                <td className="topic-head">
                  <span
                    style={{ marginRight: "1px", float: "left", width: "25%" }}
                  >
                    BAY<span style={{ color: "red" }}>*</span> :
                  </span>
                  <input
                    id="mcareq.Cr.erpno"
                    name="mcareq.Cr.erpno"
                    type="text"
                    value={BAY}
                    onChange={(e) => setBay(e.target.value)}
                    style={{ width: "100%", backgroundColor: "#efd9b1" }}
                    disabled
                  />
                </td>
                <td className="topic-head">
                  {MacNo === '' && isCreating && <span className="Req">Required *</span>}
                  <span>

                    MACHINE NO<span style={{ color: "red" }}>*</span> :
                  </span>
                  {/* <DropdownFetcher keyword="Get_machine_from_smp" setValue={setmachine} /> */}
                  <Select
                    value={MacNo}
                    onChange={handleMachine}
                    // onInputChange={(e)=>GetMachineNo(e)}
                    onInputChange={(event) => { GetSelectValue(event, "Get_machine_from_smp", setmachine) }}
                    options={machine.map((i) => ({
                      value: i.mh_mac_name,
                      label: i.mh_mac_name,
                    }))}
                    isDisabled={prb === ""}
                  />
                </td>
                {/* <input
                id="mcareq.Cr.erpno" name="mcareq.Cr.erpno"
                  type="text"
                  value={MacNo}
                  onChange={(e) => setMacNo(e.target.value)}
                  style={{ width: "40%", backgroundColor: "#efd9b1" }}
                />{MacNo === '' && <span className="required ">Required *</span>}</td> */}
                <td colSpan="3">
                  <span className="topic-head" >PRESENT STATUS OF MACHINE :</span>
                  <input
                    className="mcareq-checkbox"
                    type="radio"
                    value="Running"
                    checked={preSOM === "Running"}
                    onChange={(e) => setpreSOM(e.target.value)}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                  RUNNING
                  <input
                    className="mcareq-checkbox"
                    type="radio"
                    value="Stopped"
                    checked={preSOM === "Stopped"}
                    onChange={(e) => setpreSOM(e.target.value)}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                  STOPPED
                </td>
              </tr>
              <tr>
                <td className="topic-head">
                  <span
                    style={{ marginRight: "0px", float: "left", width: "100%" }}
                  >
                    CUSTOMER<span style={{ color: "red" }}>*</span> :
                  </span>
                  <DropdownFetcher keyword="customer_details" setValue={setCustomer} />
                  {customerName === '' && isCreating && <span className="Req">Required *</span>}
                  <Select
                    value={customerName}
                    onChange={handleCustomer}
                    options={Customer.map((i) => ({
                      value: i.Cus_code,
                      label: i.Cus_name,
                    }))}
                    isDisabled={MacNo === ""}
                  />
                </td>
                <td className="topic-head">

                  <span
                    style={{ marginRight: "0px", float: "left", width: "60%" }}
                  >
                    MODEL<span style={{ color: "red" }}>*</span> :
                  </span>
                  <input
                    type="text"
                    value={Model}
                    onChange={(e) => setModel(e.target.value)}
                    style={{ width: "100%", backgroundColor: "#efd9b1" }}
                    disabled={Mldname === ""}
                  />
                </td>
                <td className="topic-head">
                  {Mldname === '' && isCreating && <span className="Req">Required *</span>}
                  <span style={{ width: "60%" }}>
                    MOULD NAME<span style={{ color: "red" }}>*</span> :
                  </span>
                  {/* <DropdownFetcher
                    keyword="mac_part_dtl_with_model_dropdown"
                    setValue={setMouldName}
                  /> */}
                  <Select
                    styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                    value={Mldname}
                    onChange={handleMouldName}
                    options={MouldName.map((i) => ({
                      value: i.mc_part_name,
                      label: i.mc_part_name,
                    }))}
                    // onInputChange={(event) => { GetSelectValue(event, "mac_part_dtl_with_model_dropdown", setMouldName) }}
                    isDisabled={MacNo === ""}
                    placeholder="Search"
                  />
                </td>
                <td className="topic-head">
                  <span
                    style={{ marginRight: "0px", float: "left", width: "60%" }}
                  >
                    MOULD NUMBER:
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </span>
                  <input
                    type="text"
                    value={ModelNumber}
                    onChange={(e) => setModelNumber(e.target.value)}
                    style={{ width: "100%", backgroundColor: "#efd9b1" }}
                    disabled
                  />
                </td>
                <td colSpan="3">
                  <span className="topic-head">PRIORITY :</span>
                  <input
                    className="mcareq-checkbox"
                    type="radio"
                    value="VERYHIGH"
                    checked={Prio === "VERYHIGH"}
                    onChange={(e) => setPrio(e.target.value)}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                  VERYHIGH
                  <input
                    className="mcareq-checkbox"
                    type="radio"
                    value="HIGH"
                    checked={Prio === "HIGH"}
                    onChange={(e) => setPrio(e.target.value)}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                  HIGH
                  <input
                    className="mcareq-checkbox"
                    type="radio"
                    value="LOW"
                    checked={Prio === "LOW"}
                    onChange={(e) => setPrio(e.target.value)}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                  LOW
                  <input
                    className="mcareq-checkbox"
                    type="radio"
                    value="VERYLOW"
                    checked={Prio === "VERYLOW"}
                    onChange={(e) => setPrio(e.target.value)}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                  VERYLOW
                </td>
              </tr>

              <tr>
                <td colSpan={4}>
                  <span className="topic-head">NATURE OF PROBLEM</span>
                  <input
                    type="text"
                    value={NofPrb}
                    onChange={(e) => setNofPrb(e.target.value)}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                </td>
                <td colSpan={3} className="topic-head">
                  <span
                    style={{ marginRight: "10px", float: "left", width: "50%" }}
                  >
                    APPROXIMATE COST VALUE :
                  </span>
                  <input
                    type="number"
                    value={AppCV}
                    onChange={(e) => setAppCV(e.target.value)}
                    style={{ width: "98%", backgroundColor: "#efd9b1" }}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={1} className="topic-head">
                  ROOT CAUSE ANALYSIS
                </td>
                <td colSpan={4}>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "auto auto auto",
                    }}
                  >
                    <div>
                      <input
                        className="mcareq-checkbox"
                        type="radio"
                        value="DOUBLE SHOT"
                        checked={RootCA === "DOUBLE SHOT"}
                        onChange={(e) => setRootCA(e.target.value)}
                        disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                      />
                      <span>DOUBLE SHOT</span>
                    </div>
                    <div>
                      <input
                        className="mcareq-checkbox"
                        type="radio"
                        value="OVER PACK"
                        checked={RootCA === "OVER PACK"}
                        onChange={(e) => setRootCA(e.target.value)}
                        disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                      />
                      <span>OVER PACK</span>
                    </div>
                    <div>
                      {" "}
                      <input
                        className="mcareq-checkbox"
                        type="radio"
                        value="DELAY SHORT"
                        checked={RootCA === "DELAY SHORT"}
                        onChange={(e) => setRootCA(e.target.value)}
                        disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                      />
                      <span>DELAY SHORT</span>
                    </div>
                  </div>
                </td>

                <td colSpan={2} className="topic-head">
                  WHY WHY ANALYSIS
                </td>
              </tr>

              <tr>
                <td colSpan={1} className="topic-head">
                  MTC :
                </td>
                <td colSpan={2}>
                  <div
                    style={{ display: "grid", gridTemplateColumns: "auto auto" }}
                  >
                    <div>
                      <input
                        className="mcareq-checkbox"
                        type="radio"
                        value="MOULD"
                        checked={MTC === "MOULD"}
                        onChange={(e) => setMTC(e.target.value)}
                        disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                      />
                      <span>MOULD</span>
                    </div>
                    <div>
                      <input
                        className="mcareq-checkbox"
                        type="radio"
                        value="MACHINE"
                        checked={MTC === "MACHINE"}
                        onChange={(e) => setMTC(e.target.value)}
                        disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                      />
                      <span>MACHINE</span>
                    </div>
                  </div>
                </td>
                <td className="topic-head" colSpan={2}>
                  CycleTime:
                  <span>
                    <input
                      type="text"
                      value={CycTm}
                      onChange={(e) => setCycTm(e.target.value)}
                      disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                    />
                  </span>
                </td>
                <td className="topic-head" colSpan={2} rowSpan={5} style={{ verticalAlign: "top" }}>
                  <label style={{ display: "block", fontSize: "15px" }}>
                    MOULD
                    <input
                      className="mcareq-checkbox"
                      type="radio"
                      value="MOULD"
                      checked={WhyWhy === "MOULD"}
                      onChange={(e) => setWhyWhy(e.target.value)}
                      style={{ padding: "0", margin: "0" }}
                      disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                    /></label>
                  <br />
                  <label style={{ display: "block", fontSize: "15px" }}>
                    MEN
                    <input
                      className="mcareq-checkbox"
                      type="radio"
                      value="MEN"
                      checked={WhyWhy === "MEN"}
                      onChange={(e) => setWhyWhy(e.target.value)}
                      style={{ padding: "0", margin: "0" }}
                      disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                    /></label>
                  <br />
                  <label style={{ display: "block", fontSize: "15px" }}>
                    MATERIAL
                    <input
                      className="mcareq-checkbox"
                      type="radio"
                      value="MATERIAL"
                      checked={WhyWhy === "MATERIAL"}
                      onChange={(e) => setWhyWhy(e.target.value)}
                      style={{ padding: "0", margin: "0" }}
                      disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                    /></label>
                  <br />
                  <label style={{ display: "block", fontSize: "15px" }}>
                    MACHINE
                    <input
                      className="mcareq-checkbox"
                      type="radio"
                      value="MACHINE"
                      checked={WhyWhy === "MACHINE"}
                      onChange={(e) => setWhyWhy(e.target.value)}
                      style={{ padding: "0", margin: "0" }}
                      disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                    /></label>
                  <br />
                  <label style={{ display: "block", fontSize: "15px" }}>
                    METHOD
                    <input
                      className="mcareq-checkbox"
                      type="radio"
                      value="METHOD"
                      checked={WhyWhy === "METHOD"}
                      onChange={(e) => setWhyWhy(e.target.value)}
                      style={{ padding: "0", margin: "0" }}
                      disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                    /></label>
                </td>

              </tr>

              <tr>
                <td className="topic-head">ALARM :</td>
                <td colSpan={2}>
                  <div
                    style={{ display: "grid", gridTemplateColumns: "auto auto" }}
                  >
                    <div>
                      <input
                        className="mcareq-checkbox"
                        type="radio"
                        value="METHOD"
                        checked={Alrm === "METHOD"}
                        onChange={(e) => setAlrm(e.target.value)}
                        disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                      />
                      METHOD
                    </div>
                    <div>
                      <input
                        className="mcareq-checkbox"
                        type="radio"
                        value="MATERIAL"
                        checked={Alrm === "MATERIAL"}
                        onChange={(e) => setAlrm(e.target.value)}
                        disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                      />
                      MATERIAL
                    </div>
                  </div>
                </td>
                <td className="topic-head" colSpan={2}>
                  CUSION :
                  <span>
                    <input
                      type="text"
                      value={Cusion}
                      onChange={(e) => setCusion(e.target.value)}
                      disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                    />
                  </span>
                </td>

                {/* <td className="topic-head" colSpan={2} style={{textAlign:"center"}}>MEN
              <input
                      className="mcareq-checkbox"
                      type="radio"
                      value="MEN"
                      checked={WhyWhy === "MEN"}
                      onChange={(e) => setWhyWhy(e.target.value)}
                      disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === ""|| customerName === "" || Mldname === ""}
                    />
               </td> */}
              </tr>
              <tr>
                <td className="topic-head">SMP:</td>
                <td className="topic-head">INJECTION PRESSURE:</td>
                <td>
                  <input
                    type="text"
                    value={InjP}
                    onChange={(e) => setInjP(e.target.value)}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                </td>
                <td className="topic-head" colSpan={2}>
                  SPEED :
                  <span>
                    <input
                      type="text"
                      value={Speed}
                      onChange={(e) => setSpeed(e.target.value)}
                      disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                    />
                  </span>
                </td>

                {/* <td className="topic-head" colSpan={2} style={{textAlign:"center"}}>MATERIAL
              <input
                      className="mcareq-checkbox"
                      type="radio"
                      value="MATERIAL"
                      checked={WhyWhy === "MATERIAL"}
                      onChange={(e) => setWhyWhy(e.target.value)}
                      disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === ""|| customerName === "" || Mldname === ""}
                    />
               </td> */}
              </tr>
              <tr>
                <td></td>
                <td className="topic-head">EJECTION PRESSURE:</td>
                <td colSpan={1}>
                  <input
                    type="text"
                    value={EjP}
                    onChange={(e) => setEjP(e.target.value)}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                </td>
                <td className="topic-head" colSpan={2}>
                  TEMPERATURE:
                  <input
                    type="text"
                    value={Temp}
                    onChange={(e) => setTemp(e.target.value)}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                </td>

                {/* <td className="topic-head" colSpan={2} style={{textAlign:"center"}}>MACHINE
              <input
                      className="mcareq-checkbox"
                      type="radio"
                      value="MACHINE"
                      checked={WhyWhy === "MACHINE"}
                      onChange={(e) => setWhyWhy(e.target.value)}
                      disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === ""|| customerName === "" || Mldname === ""}
                    />
               </td> */}
              </tr>
              <tr>
                <td colSpan={1} className="topic-head">
                  HRC
                </td>
                <td colSpan={2} className="topic-head">
                  LIMIT SWITCH CONNECTION :<span>ON/OFF/NILL</span>
                  <input
                    type="text"
                    value={LimitC}
                    onChange={(e) => setLimitC(e.target.value)}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                </td>
                <td className="topic-head" colSpan={2}>
                  PRESSURE :
                  <input
                    type="text"
                    value={Press}
                    onChange={(e) => setPress(e.target.value)}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                </td>
                {/* <td className="topic-head" colSpan={2} style={{textAlign:"center"}}>METHOD
              <input
                      className="mcareq-checkbox"
                      type="radio"
                      value="METHOD"
                      checked={WhyWhy === "METHOD"}
                      onChange={(e) => setWhyWhy(e.target.value)}
                      disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === ""|| customerName === "" || Mldname === ""}
                    />
               </td> */}

              </tr>
              <tr>
                <td className="topic-head">ROOT CAUSE :</td>
                <td colSpan={6}>
                  <input
                    type="text"
                    value={RootC}
                    onChange={(e) => setRootC(e.target.value)}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">CORRECTIVE ACTION :</td>
                <td colSpan={6}>
                  <input
                    id="mcareq.Cr.revno"
                    name="mcareq.Cr.revno"
                    type="text"
                    value={CorrAct}
                    onChange={(e) => setCorrAct(e.target.value)}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span className="topic-head">SPARE DETAILS :</span>
                  <input
                    id="mcareq.Cr.revno"
                    name="mcareq.Cr.revno"
                    type="text"
                    value={SpareD}
                    onChange={(e) => setSpareD(e.target.value)}
                    style={{ width: "98%", backgroundColor: "#efd9b1" }}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                </td>
                <td colSpan={1} className="topic-head">
                  <span style={{ width: "40%" }}>COST :</span>
                  <input
                    id="mcareq.Cr.revno"
                    name="mcareq.Cr.revno"
                    type="number"
                    value={Cost}
                    onChange={(e) => setCost(e.target.value)}
                    style={{ width: "100%", backgroundColor: "#efd9b1" }}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                </td>
                <td colSpan={1} className="topic-head">
                  <span style={{ width: "40%" }}>STOCK :</span>
                  <input
                    id="mcareq.Cr.revno"
                    name="mcareq.Cr.revno"
                    type="text"
                    value={Stock}
                    onChange={(e) => setStock(e.target.value)}
                    style={{ width: "100%", backgroundColor: "#efd9b1" }}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                </td>

                <td colSpan={3} className="topic-head">
                  <span style={{ width: "40%" }}>ERP REQ STATUS :</span>
                  <input
                    type="text"
                    value={ERPReqS}
                    onChange={(e) => setERPReqS(e.target.value)}
                    style={{ width: "98%", backgroundColor: "#efd9b1" }}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                </td>
              </tr>
              <tr>
                <th colSpan={7} style={{ textAlign: "center" }}>
                  AFTER CORRECTION STATUS
                </th>
              </tr>
              <tr>
                <td></td>
                <th style={{ textAlign: "center" }}>TRIAL-1</th>
                <th style={{ textAlign: "center" }}>TRIAL-2</th>
                <th style={{ textAlign: "center" }}>TRIAL-3</th>
                <td colSpan="3" className="topic-head">
                  <span
                    style={{ marginRight: "5px", float: "left", width: "40%" }}
                  >
                    TOTAL WORKING HOURS :
                  </span>
                  <div>
                    <HoursMinutesSelect
                      hours={selectedHours}
                      minutes={selectedMinutes}
                      onHoursChange={handleHoursChange}
                      onMinutesChange={handleMinutesChange}
                    />
                  </div>
                  <span style={{ fontSize: "20px" }}>{TWhrs}</span>

                </td>
              </tr>
              <tr>
                <td colSpan={1} className="topic-head">PRODUCTION POINT</td>
                <td colSpan={1}>
                  <input
                    type="text"
                    value={PRP1}
                    onChange={(e) => setPRP1(e.target.value)}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                </td>
                <td colSpan={1}>
                  <input
                    type="text"
                    value={PRP2}
                    onChange={(e) => setPRP2(e.target.value)}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                </td>
                <td colSpan={1}>
                  <input
                    type="text"
                    value={PRP3}
                    onChange={(e) => setPRP3(e.target.value)}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                </td>
                <td colSpan={3}
                  style={{ textAlign: "center" }}
                  className="topic-head"
                >
                  STATUS OF RECTIFICATION
                </td>
              </tr>
              <tr>
                <td colSpan={1} className="topic-head">QUALITY POINTS</td>
                <td colSpan={1}>
                  <input
                    type="text"
                    value={QP1}
                    onChange={(e) => setQP1(e.target.value)}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                </td>
                <td colSpan={1}>
                  <input
                    type="text"
                    value={QP2}
                    onChange={(e) => setQP2(e.target.value)}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                </td>
                <td colSpan={1}>
                  <input
                    type="text"
                    value={QP3}
                    onChange={(e) => setQP3(e.target.value)}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                </td>
                <td colSpan={1}>
                  <input
                    className="mcareq-checkbox"
                    type="radio"
                    value="TEMPORARY"
                    checked={StsORec === "TEMPORARY"}
                    onChange={(e) => setStsORec(e.target.value)}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                  TEMPORARY
                </td>
                <td colSpan={1}>
                  <input
                    className="mcareq-checkbox"
                    type="radio"
                    value="PERMANENT"
                    checked={StsORec === "PERMANENT"}
                    onChange={(e) => setStsORec(e.target.value)}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                  PERMANENT
                </td>
                <td colSpan={1}>
                  <input
                    className="mcareq-checkbox"
                    type="radio"
                    value="UNDER OBSERVATION"
                    checked={StsORec === "UNDER OBSERVATION"}
                    onChange={(e) => setStsORec(e.target.value)}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                  UNDER OBSERVATION
                </td>
              </tr>
              <tr>
                <td className="topic-head">REQUESTED BY<span style={{ color: "red" }}>*</span></td>
                <td className="topic-head">SIGNATURE OF TECHNICIAN<span style={{ color: "red" }}>*</span></td>
                <td className="topic-head">APPROVED BY (TOOLROOMHOD)<span style={{ color: "red" }}>*</span></td>
                <td className="topic-head">PRODUCTION HOD<span style={{ color: "red" }}>*</span></td>
                <td className="topic-head">QUALITY<span style={{ color: "red" }}>*</span></td>
                <td className="topic-head">PURCHASE<span style={{ color: "red" }}>*</span></td>
                <td className="topic-head">PLANT HEAD<span style={{ color: "red" }}>*</span></td>
              </tr>
              <tr>
                <td colSpan={1}>
                  <DropdownFetcher keyword="emp_details" setValue={setEmployee} />
                  {ReqBy === '' && isCreating && <span className="Req">Required *</span>}
                  <Select
                    styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                    value={ReqBy}
                    onChange={handleEmployee}
                    options={Employee.map((i) => ({
                      value: i.Emp_code,
                      label: i.Emp_name,
                    }))}
                    isDisabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                </td>
                <td colSpan={1}>
                  {SignBy === '' && isCreating && <span className="Req">Required *</span>}
                  <Select
                    styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                    value={SignBy}
                    onChange={setSignBy}
                    options={Employee.map((i) => ({
                      value: i.Emp_code,
                      label: i.Emp_name,
                    }))}
                    isDisabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                </td>
                <td colSpan={1}>
                  {APRBy === '' && isCreating && <span className="Req">Required *</span>}
                  <Select
                    styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                    value={APRBy}
                    onChange={setAPRBy}
                    options={Employee.map((i) => ({
                      value: i.Emp_code,
                      label: i.Emp_name,
                    }))}
                    isDisabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                </td>
                <td colSpan={1}>
                  {ProHd === '' && isCreating && <span className="Req">Required *</span>}
                  <Select
                    styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                    value={ProHd}
                    onChange={setProHd}
                    options={Employee.map((i) => ({
                      value: i.Emp_code,
                      label: i.Emp_name,
                    }))}
                    isDisabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                </td>
                <td colSpan={1}>
                  {Qty === '' && isCreating && <span className="Req">Required *</span>}
                  <Select
                    styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                    value={Qty}
                    onChange={setQty}
                    options={Employee.map((i) => ({
                      value: i.Emp_code,
                      label: i.Emp_name,
                    }))}
                    isDisabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                </td>
                <td colSpan={1}>
                  {Purc === '' && isCreating && <span className="Req">Required *</span>}
                  <Select
                    styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                    value={Purc}
                    onChange={setPurc}
                    options={Employee.map((i) => ({
                      value: i.Emp_code,
                      label: i.Emp_name,
                    }))}
                    isDisabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                </td>
                <td colSpan={1}>
                  {PlHd === '' && isCreating && <span className="Req">Required *</span>}
                  <Select
                    styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                    value={PlHd}
                    onChange={setPlHd}
                    options={Employee.map((i) => ({
                      value: i.Emp_code,
                      label: i.Emp_name,
                    }))}
                    isDisabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                  />
                </td>
              </tr>

              <tr>
                <td colSpan="14" style={{ border: "none" }}>
                  <button
                    type="submit"
                    id="mcareq.cr.submit"
                    name="mcareq.cr.submit"
                    onClick={(e) => handleCreate(e)}
                    disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                    hidden={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === "" || PlHd === ""}
                  >
                    Submit
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  );
}

export default mcareqctRequestReportCreate;
