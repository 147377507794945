import React, { useState, useEffect ,useRef} from "react";
import axios from "axios";
import Popup from "../../../Components/Popup/Popup";
import { API_URL } from "../../../Utils/constants";
import "../../../Styles/tsheet.css";
//import "bootstrap/dist/css/bootstrap.min.css";
import Unitechlogo from "../../../icons/Unitechlogo.jpeg";
import Select from "react-select";
import Modal from 'react-modal';
import HandleRead from "../../../Lib/HandleRead";
import Pagination from "../../../Components/Pagination";
import DropdownFetcher from "../../../Lib/DropdownFetcher.js";
import Base64Convertor from "../../../Lib/Base64Convertor";
import { useNavigate, useParams } from "react-router-dom";
import GetRecord from '../../../Lib/GetRecord';
import { FaBackspace,FaPen } from 'react-icons/fa';
import reject from "../../../Lib/reject";
import approve from "../../../Lib/approve";
import { GetAccess } from "../../../Lib/GetAccess";
import UpdateRecord from "../../../Lib/updateRecord";
import { MdDelete } from "react-icons/md";
import ReadRecord from "../../../Lib/API/ReadRecord.js";
import CanvasDraw from 'react-canvas-draw';
import Draggable from 'react-draggable';
import { MdRestore } from 'react-icons/md';

function TemperatureSheetView() {
  const [Id, setId] = useState("");
  const [AppBy, setAppBy] = useState("");
  const [PrepBy, setPrepBy] = useState("");
 
  const [brushColor, setBrushColor] = useState('#000');
  const [image, setImage] = useState(null);
  const [image1, setImage1] = useState(null);
  const [CoreImg, setCoreImg] = useState("");
  const [cimage, setcImage] = useState(null);
  const [cimage1, setcImage1] = useState(null);
  const [CavImg, setCavImg] = useState("");
  const [NotesBefore, setNotesBefore] = useState("");
  const [NotesAfter, setNotesAfter] = useState("");
  const [Approval, setApproval] = useState("");
  const [EmployeeDropdown, setEmployeeDropdown] = useState([]);
  const [Approver, setApprover] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [RejectedReason, setRejectedReason] = useState("");
  var [t_sheet, sett_sheet] = useState([]);


  const [isPaginationVisible, setPaginationVisible] = useState(true);


  const [ButtonPopup, setButtonPopup] = useState(false);
  const [ButtonPopup2, setButtonPopup2] = useState(false);
  const [ButtonPopup3, setButtonPopup3] = useState(false);
  const [ButtonPopup4, setButtonPopup4] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
  const [isDrawing1, setIsDrawing1] = useState(false);
  // const records = t_sheet.slice(firstIndex, lastIndex);


  const idView = "tsheetView";
  const nameView = "tsheetView";
  const nameEdit = "tsheetEdit";
  const idEdit = "tsheetEdit";
  const nameList = "tsheetList";
  const idList = "tsheetList";

  const handleView = (e) => {

    handleReadRecord(e.Id);
    setPaginationVisible(false);
    setButtonPopup2(true);


    setAppBy(`${e.App_by}-${e.Approved_by}`);
    setPrepBy(`${e.Prep_by}-${e.Prepared_by}`);
    setCoreImg(e.Core_img);
    setCavImg(e.Cav_img);
    setNotesAfter(e.Notes_after);
    setNotesBefore(e.Note_before);
    setApproval(e.Approval_status);



  };



  const viewPagination = () => {
    setPaginationVisible(true);
  };


  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 20;


  const [filterDate, setFilterDate] = useState("");
  const [filterMcno, setFilterMcno] = useState("");
  // const [filterMachine, setFilterMachine] = useState("");
  const filterRecords = () => {
    const filteredData = t_sheet.filter((record) => {
      // const dateMatch = record.TrackDate && record.TrackDate.includes(filterDate);
      const mcnoMatch = record.App_by && record.App_by.toLowerCase().includes(filterMcno.toLowerCase());
      const dateMatch = record.Prep_by && record.Prep_by.toLowerCase().includes(filterDate.toLowerCase());

      return mcnoMatch && dateMatch;
    });

    return filteredData;
  };

  const filteredData = filterRecords();
  const npage = Math.ceil(filteredData.length / recordPerPage);
  const numbers = [...Array(npage).keys()].map((num) => num + 1);

  function handleFilterDateChange(event) {
    setFilterDate(event.target.value);
    setCurrentPage(1);
  }

  function handleFilterMcnoChange(event) {
    setFilterMcno(event.target.value);
    setCurrentPage(1);
  }


  const firstIndex = (currentPage - 1) * recordPerPage;
  const lastIndex = firstIndex + recordPerPage;
  const filteredRecords = filteredData.slice(firstIndex, lastIndex);
  function prePage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function nextPage() {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function changeCpage(Id) {
    setCurrentPage(Id);
  }





  const handleEdit = (e) => {
    setPaginationVisible(false);
    setButtonPopup(true);
    setId(e.Id);
    setAppBy({ value: e.App_by, label: e.Approved_by });
    setPrepBy({ value: e.Prep_by, label: e.Prepared_by });
    setCoreImg(e.Core_img);
    setCavImg(e.Cav_img);
    setNotesAfter(e.Notes_after);
    setNotesBefore(e.Note_before);
    // setCrDate(e.CreatedDate);
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    if (!CavImg || !PrepBy || !NotesBefore) {

      alert("Please fill the mandatory fields");

      return;
    }
    const data = {
      data: [
        {
          Id: Id,
          Core_img: CoreImg,
          Cav_img: CavImg,
          App_by: AppBy.value,
          Prep_by: PrepBy.value,
          Notes_after: NotesAfter,
          Note_before: NotesBefore,

        },
      ],
    };
    data["_keyword_"] = "MouldTemperature";
    data["secretkey"] = "2bf52be7-9f68-4d52-9523-53f7f267153b";
    axios
      .post(
        `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
        data
      )
      .then((response) => {
        // sett_sheet([...t_sheet, response.data]);
        alert("Updation Successfull!");
        setButtonPopup(false);
        handleReadMouldTemperature();
        setPaginationVisible(true);

        // window.location.reload();

      })
      .catch((error) => {
        console.log(error);
        alert("Updation Failed!");
        window.location.reload();
      });
  };


  const empMoldSetterOptions = EmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const handlePreparedBy = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setPrepBy({ value: selected.Emp_code, label: selected.Emp_name });
    }
  };
  const handleApprovedBy = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setAppBy({ value: selected.Emp_code, label: selected.Emp_name });
    }
  };
  

  const [back, setback] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [ENC, setENC] = useState("");
  const [remarks, setremarks] = useState('');
  const [cardId, setcardId] = useState('');
  useEffect(() => {
    console.log(id);
    if (id !== undefined) {
      GetRecord(
        {
          _keyword_: "MouldTemperature_ID",
          Id: id,
        },
        setENC
      ).then(async (res) => {
        console.log("res - ", res);
        handleView(res[0])
        setButtonPopup2(true)
        await timeout(1000);
        setback(true)
        const popupDiv = document.querySelector(".popup");
        if (popupDiv) {
          const elementInsidePopup = popupDiv.querySelector("#close-btn");
          elementInsidePopup.style.display = "none";
        }
      })
    }
  }, [id])
  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }
  function handleReadRecord(id) {
    ReadRecord({
      _keyword_: "Get_approver_by_empId",
      TableID: id,
      TableName: "TemperatureSheet",
      Approver: localStorage.getItem("empId"),
    }).then((res) => {
      // console.log("res.data,",res);
      if (res.rows > 0) {
        setApprover(res.data[0].Approver);
        setRejectedReason(res.data[0].ApprovalRemark);
      } else {
        setApprover("");
        setRejectedReason("");
      }
    }).then(()=>{
      ReadRecord({
        _keyword_: "Get_approver_ApprovalRemark",
        TableID: id,
        TableName: "TemperatureSheet"
      }).then((res)=>{
        if(res.rows>0){
          setRejectedReason(res.data[0].ApprovalRemark);
        }else{
          setRejectedReason("");
        }
      })
    })
  }
  function handleReadMouldTemperature() {
    ReadRecord({
      _keyword_: "MouldTemperature"
    }).then((res) => {
      // console.log(res.data);
      sett_sheet(res.data);
    });
  }
  useEffect(() => {
    handleReadMouldTemperature();
  }, [])


  const canvasDrawRef1 = useRef(null);
  const canvasDrawRef2 = useRef(null);
  const [inputText, setInputText] = useState('');

  const handleColorChange = (e) => {
    setBrushColor(e.target.value);
  };
  const [draggedNumbers, setDraggedNumbers] = useState([]);
  const [textInputs, setTextInputs] = useState({});
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [prevNum, setPrevNum] = useState([]);

  const handleDrag = (e, num) => {
    e.preventDefault();
    setIsDrawing(false)
    const canvas = canvasDrawRef1.current.canvas.drawing;
    const context = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;
    console.log('Drag event:', num, x, y);
    console.log('Previous num:', prevNum, 'Current num:', num);
    if (x >= 0 && x <= canvas.width && y >= 0 && y <= canvas.height) {
        if (!prevNum.includes(num)) {
            const radius = 10; 
            context.beginPath();
            context.arc(x, y, radius, 0, 2 * Math.PI);
            context.strokeStyle = 'black'; 
            context.stroke();
            context.font = '14px Arial';
            context.fillStyle = 'black';
            context.textAlign = 'center';
            context.textBaseline = 'middle';
            context.fillText(num.toString(), x, y);
            draggedNumbers.push(num);
            prevNum.push(num);
            if (!textInputs[num]) {
              let input = document.createElement('input');
              input.id = `textInput${num}`;
              input.type = 'text';
              input.style.position = 'absolute';
              input.style.width = '120px';
              input.style.height = '20px';
              input.style.left = `${x + 90}px`;
              input.style.top = `${y + 40}px`;
              input.placeholder = 'Enter text';
              document.body.appendChild(input);
              textInputs[num] = input;
              }
            let button = document.createElement('button');
            button.innerText = '+';
            button.style.position = 'absolute';
            button.style.font = '13px Arial';
            button.style.left = `${x + 200}px`;
            button.style.top = `${y + 40}px`;
            button.onclick = () => {
              setIsButtonClicked(true)
                context.fillText(textInputs[num].value, x+55, y);
                document.body.removeChild(textInputs[num]);
                document.body.removeChild(button);
                console.log(textInputs[num].value);
                delete textInputs[num];              
              };      
            document.body.appendChild(button);
            }
            }
            };
            
            const handleDrag1 = (e, num) => {
              e.preventDefault();
              setIsDrawing(false)
              const canvas = canvasDrawRef2.current.canvas.drawing;
              const context = canvas.getContext('2d');
              const rect = canvas.getBoundingClientRect();
              let x = e.clientX - rect.left;
              let y = e.clientY - rect.top;
              console.log('Drag event:', num, x, y);
              console.log('Previous num:', prevNum, 'Current num:', num);
              if (x >= 0 && x <= canvas.width && y >= 0 && y <= canvas.height) {
                  if (!prevNum.includes(num)) {
                      const radius = 10; 
                      context.beginPath();
                      context.arc(x, y, radius, 0, 2 * Math.PI);
                      context.strokeStyle = 'black'; 
                      context.stroke();
                      context.font = '14px Arial';
                      context.fillStyle = 'black';
                      context.textAlign = 'center';
                      context.textBaseline = 'middle';
                      context.fillText(num.toString(), x, y);
                      draggedNumbers.push(num);
                      prevNum.push(num);
                      if (!textInputs[num]) {
                        let input = document.createElement('input');
                        input.id = `textInput${num}`;
                        input.type = 'text';
                        input.style.position = 'absolute';
                        input.style.width = '120px';
                        input.style.height = '20px';
                        input.style.left = `${x + 60}px`;
                        input.style.top = `${y + 40}px`;
          
                        input.placeholder = 'Enter text';
                        document.body.appendChild(input);
                        textInputs[num] = input;
                    }
                      let button = document.createElement('button');
                      button.innerText = '+';
                      button.style.position = 'absolute';
                      button.style.font = '13px Arial';
                      button.style.left = `${x + 180}px`;
                      button.style.top = `${y + 40}px`;
                      button.onclick = () => {
                       setIsButtonClicked(true)
                          context.fillText(textInputs[num].value, x+55, y);
                          document.body.removeChild(textInputs[num]);
                          document.body.removeChild(button);
                          console.log(textInputs[num].value);
                          delete textInputs[num];
                       
                        
                      };
                      document.body.appendChild(button);
                      }
                      }
                      };
                      
  

   const handleDrop = (e, number) => {
    e.preventDefault();

    let input = textInputs[number];
    if (input) {      
        setInputText(input.value);  
        const canvas = canvasDrawRef1.current.canvas.drawing;
        const context = canvas.getContext('2d');
         context.clearRect(0, 0, canvas.width, canvas.height);
         numbers.forEach((num) => {
            context.font = '20px Arial';
            context.fillStyle = 'black'; 
            context.fillText(`${num}: ${input.value}`, num * 30, 30);
            context.fillText((input).toString(), x, y)
         });
    }
    };


    const set1 = (e) => {
   e.preventDefault();  
   setIsVisible(false)
     };
     const set2 = (e) => {
      e.preventDefault(); 
      setIsVisible(false)
      };
     const setedit = (e) => {
      e.preventDefault();
      setIsVisible(true)
      setIsDrawing(false)
        };
        const setedit1 = (e) => {
          e.preventDefault();
          setIsVisible(true)
          setIsDrawing1(false)
            }; 
        const combineImages = (image, editedImageData) => {
          return new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
        
   
            const img = new Image();
            img.src = image;
            img.onload = () => {
              canvas.width = img.width;
              canvas.height = img.height;
              ctx.drawImage(img, 0, 0);
              
              const editedImg = new Image();
              editedImg.src = editedImageData;
              editedImg.onload = () => {
                ctx.drawImage(editedImg, 0, 0, canvas.width, canvas.height); 
                
    
                const combinedImageData = canvas.toDataURL('image/png', 1.0);
                resolve(combinedImageData);
              };
            };
          });
        };
        
        const combineImages1 = (cimage, editedImageData) => {
          return new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
     
            const img = new Image();
            img.src = cimage;
            img.onload = () => {
              canvas.width = img.width;
              canvas.height = img.height;
              ctx.drawImage(img, 0, 0);
              
              const editedImg = new Image();
              editedImg.src = editedImageData;
              editedImg.onload = () => {
                ctx.drawImage(editedImg, 0, 0, canvas.width, canvas.height); 
                
    
                const combinedImageData = canvas.toDataURL('image/png', 1.0);
                resolve(combinedImageData);
              };
            };
          });
        };
    

    
    useEffect(() => {
      if (image && canvasDrawRef1.current) {
        const editedImageData = canvasDrawRef1.current.canvas.drawing.toDataURL('image/png',1.0);
        combineImages(image, editedImageData).then((combinedImageData) => {
          setImage1(combinedImageData);
          setCoreImg(combinedImageData);
        });
        }
        }, [set1]);
        useEffect(() => {
          if (cimage && canvasDrawRef2.current) {
            const editedImageData = canvasDrawRef2.current.canvas.drawing.toDataURL('image/png',1.0);
            combineImages1(cimage, editedImageData).then((combinedImageData) => {
              setcImage1(combinedImageData);
              setCavImg(combinedImageData);
            });
            }
            }, [set2]);
     
        const handleReset = (e) => {
          e.preventDefault();
          canvasDrawRef1.current.clear();
          const canvas = canvasDrawRef1.current.canvas.drawing;
          const context = canvas.getContext('2d');
          
          context.clearRect(0, 0, canvas.width, canvas.height);
          const img = new Image();
          img.src = image;
          img.onload = () => {
            context.drawImage(img, 0, 0, canvas.width, canvas.height);
          };
           setDraggedNumbers([]);
          setTextInputs({});
          setPrevNum([]);
        };
        
        const handleReset1 = (e) => {
          e.preventDefault();
          canvasDrawRef2.current.clear();
          const canvas = canvasDrawRef2.current.canvas.drawing;
          const context = canvas.getContext('2d');
          
          context.clearRect(0, 0, canvas.width, canvas.height);
          const img = new Image();
          img.src = image;
          img.onload = () => {
            context.drawImage(img, 0, 0, canvas.width, canvas.height);
          };
           setDraggedNumbers([]);
          setTextInputs({});
          setPrevNum([]);
        };
        
   
      const handleImageChange = (e) => {
      e.preventDefault(); 
      setIsDrawing(false)
      setButtonPopup3(true)
       setIsVisible(false)
      const file = e.target.files[0];
      if (!file) return;
      const reader = new FileReader();
      reader.onload = () => {
      const imageData = reader.result;
      
      setImage(imageData);
      setIsOpen(true)
        };
        reader.readAsDataURL(file);
        };
        const handleImageChange1 = (e) => {
          e.preventDefault(); 
          setIsDrawing1(false)
          setButtonPopup4(true)
           setIsVisible(false)
          const file = e.target.files[0];
          if (!file) return;
          const reader = new FileReader();
          reader.onload = () => {
          const imageData = reader.result;
          
          setcImage(imageData);
          setIsOpen(true)
            };
            reader.readAsDataURL(file);
            };
     
         const [canvasDimensions, setCanvasDimensions] = useState({ width: 500, height: 600 });
         useEffect(() => {
          if (image) {
            const img = new Image();
            img.src = image;
            img.onload = () => {
              const aspectRatio = img.width / img.height;
              const maxWidth = 600; 
              const maxHeight = 500; 
        
              let canvasWidth = maxWidth;
              let canvasHeight = maxHeight;
        
              if (aspectRatio > 1) {
                canvasWidth = Math.min(maxWidth, img.width);
                canvasHeight = canvasWidth / aspectRatio;
              } else {
                canvasHeight = Math.min(maxHeight, img.height);
                canvasWidth = canvasHeight * aspectRatio;
              }
        
              setCanvasDimensions({ width: canvasWidth, height: canvasHeight });
            };
          }
          if (cimage) {
            const img = new Image();
            img.src = cimage;
            img.onload = () => {
              const aspectRatio = img.width / img.height;
              const maxWidth = 600; 
              const maxHeight = 500; 
        
              let canvasWidth = maxWidth;
              let canvasHeight = maxHeight;
        
              if (aspectRatio > 1) {
                canvasWidth = Math.min(maxWidth, img.width);
                canvasHeight = canvasWidth / aspectRatio;
              } else {
                canvasHeight = Math.min(maxHeight, img.height);
                canvasWidth = canvasHeight * aspectRatio;
              }
        
              setCanvasDimensions({ width: canvasWidth, height: canvasHeight });
            };
          }
        }, [image,cimage]);


  return (
    <div>
      <Popup
        trigger={ButtonPopup2}
        setTrigger={setButtonPopup2}
        viewPagination={viewPagination}
      >

        <br></br>
        {Approver === localStorage.getItem("empId") ?

          (Approval === "0" ? <div>{back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}<span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#DA251A', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>This Form has been Rejected due to {RejectedReason}</span></div> : Approval === "1" ? <div>{back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}<span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#B9A44C', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>It is Approved Already, Contact Administrator if any change needed </span></div> :
            back && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>
              <div style={{ display: 'flex', width: '25%', gap: '5', justifyContent: 'space-between', alignItems: 'center', marginRight: "10px" }}><button
                className="icon"
                style={{ backgroundColor: "#06802f", marginRight: '10px' }}
                type="button"
                onClick={(e) => {
                  approve(id, "TemperatureSheet", "MouldTemperature")
                  navigate(-1)
                }}
              >
                Approve
              </button>
                <button
                  className="icon"
                  style={{ backgroundColor: "#9d080f" }}
                  type="button"
                  onClick={() => { setcardId(id) }}
                >
                  Reject
                </button>
                {id === cardId && <div><div style={{ backgroundColor: 'gray', filter: 'blur(8px)', position: 'absolute', opacity: '.8', right: '0%', bottom: '0%', width: '100%', height: '100%' }}></div><div style={{ position: 'absolute', right: '32%', color: 'white', bottom: '35%', padding: '20px', borderRadius: '10px' }}><h3>Remarks</h3><textarea rows={5} cols={50} onChange={e => { setremarks(e.target.value) }}></textarea><button onClick={e => { reject(id, "TemperatureSheet", remarks, "MouldTemperature"); navigate(-1) }}>Submit</button><button onClick={() => { setcardId('') }}>Cancel</button></div></div>}
              </div>
            </div>) :
          (Approval === "0" ? <div>{back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}<span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#DA251A', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>This Form has been Rejected due to {RejectedReason}</span></div> : Approval === "1" ? <div>{back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}<span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#B9A44C', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>It is Approved Already, Contact Administrator if any change needed </span></div> : (back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>))
        }
        <form id="tsheetformContainer-View" name="tsheetformContainer-View" className="form-tsheet-View">
          <table>
            <tbody>
              <tr>
                <th
                  id={`${idView}-Title`}
                  name={`${nameView}-Title`}
                  colSpan="6"
                  style={{ textAlign: "center", whiteSpace: "nowrap" }}
                >
                  <font color="#8B0000">View - MOULD TEMPERATURE</font>
                </th>
              </tr>
              <tr>
                <td colSpan={6} className="topic-head" style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                  The Maintenance Inspector Should Following Instructions For Mould Core & Cavity Temperature :
                </td>
              </tr>
              <tr>

                <td colSpan={2} className="topic-head"><div>
                  <br />
                  <br />
                  <img src={CoreImg} style={{
                    display: "block",
                    width: "350px",
                    height: "350px",
                    margin: "auto",
                  }} />
                  <br></br>
                  <br></br>
                  <span>Notes:

                    <br></br>
                    <label>{NotesBefore}</label>

                  </span>

                  {/* <span>CORE Side Temperature Should be check the above mention location</span> */}
                </div>
                  <br></br>
                  <span>Prepared By
                    <label>{PrepBy}</label></span>
                </td>

                <td colSpan={2} className="topic-head"><div>
                  <br />
                  <br />
                  <img src={CavImg} style={{
                    display: "block",
                    width: "350px",
                    height: "350px",
                    margin: "auto",
                  }} />
                  <br></br>
                  <br></br>
                  <span>
                    Notes:
                    <br></br>
                    <label>{NotesAfter}</label>

                  </span>
                  {/* <span>CAVITY Side Temperature Should be check the above mention location</span> */}
                </div>
                  <br />
                  <span>Approved By
                    <label>{AppBy}</label></span>
                </td>
              </tr>

            </tbody>
          </table>
        </form>
      </Popup>

      <Popup
        trigger={ButtonPopup}
        setTrigger={setButtonPopup}
        viewPagination={viewPagination}
      >
        <br></br>

        <form id="tsheet-formContainer-Edit" name="tsheet-formContainer-Edit" className="form-tsheet-Edit">
          <table>
            <tbody>
              <tr>
                <th style={{ textAlign: "center", width: "10%" }}>
                  <img src={Unitechlogo} style={{ width: "40px" }} alt="Unitech Logo" />
                </th>
                <th colSpan={4} id={`${idEdit}-Title`} name={`${nameEdit}-Title`} style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                  <font color="#8B0000">WORK INSTRUCTIONS</font>
                </th>
              </tr>
              <tr>
                <th colSpan={6} className="topic-head" style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                  MOULD TEMPERATURE MEASURING AREA (APPLICABLE FOR ALL MOULD)
                </th>
              </tr>
              <tr>
                <td colSpan={6} className="topic-head" style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                  The Maintenance Inspector Should Following Instructions For Mould Core & Cavity Temperature :
                </td>
              </tr>
              <tr>

                <td colSpan={2} className="topic-head"><div>
                <input type="file" accept="image/*" onChange={handleImageChange} />
                <br />
                 <Popup
    trigger={ButtonPopup3}
    setTrigger={setButtonPopup3}
    viewPagination={viewPagination}
>
    <button onClick={() => setButtonPopup3(false)}>X</button>
  < br />
    <div style={{
        marginLeft:"5%",
        padding:"15px",
        position: 'absolute',
        width: '70%',
        height: '170%',

         backgroundColor: 'white',
        // // display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        }}>
      
        <div
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            style={{ position: 'relative',marginLeft:"1%" }}
        >
        {!isVisible && (
            <button
            onClick={(e) => {
                if (canvasDrawRef1.current) {
                    handleReset(e);
                    setedit(e);
                }
            }}
            style={{ border: 'none', padding: '10px', backgroundColor: 'rgba(0, 0, 0, 0.1)', marginLeft:"10px"}}
        >
            edit
        </button>)}

        {isVisible && (
            <>
                <span
                    style={{ width: '20px', height: '20px', marginLeft:"10px", border: 'none', padding: 0 }}
                    onClick={() => {
                        setIsDrawing(prevState => !prevState);
                    }}
                >
                    <FaPen />
                </span>

                <input
                    type="color"
                    value={brushColor}
                    onChange={handleColorChange}
                    style={{ width: '20px', height: '20px', border: 'none', padding: 0 , marginLeft:"30px",}}
                />color

               
                <MdRestore onClick={handleReset} style={{ width: '20px', height: '20px', border: 'none', padding: 0,marginLeft:"20px" }} />Reset
                <button onClick={set1} style={{ marginLeft: '20px', border: 'none', padding: 0 }}>OK</button>
            </>
        )}
          
            <CanvasDraw
                ref={canvasDrawRef1}
                imgSrc={image}
                hideGrid={true}
                canvasHeight={canvasDimensions.height}
                canvasWidth={canvasDimensions.width}
                // canvasHeight={600}
                // canvasWidth={400}
                brushRadius={1}
                lazyRadius={0}
                brushColor={brushColor}
                disabled={!isDrawing}
                style={{ padding: 1 }}
            />
           
        
                </div>
                {isVisible && (
              <>
             {[...Array(5).keys()].map((num) => (
                    <Draggable key={num}>
                        <span
                            draggable
                            onDragStart={(e) => handleDrag(e, (num + 1))}
                            style={{
                                borderRadius: '100%',
                                 display: 'inline-block',
                                width: '20px',
                                height: '20px',
                                margin: '5px',
                                border: '1px solid black',
                                textAlign: 'center',
                                lineHeight: '20px',
                                marginLeft:"10px",
                                
                            }}>
                            {num + 1}
                        </span>
                    </Draggable>
                ))}</>
               )} 
                <br />   
                </div>
               </Popup>
               
                {CoreImg && ( 
                   <img
                  src={CoreImg}
                     alt="Selected"
                    style={{
                      display: "block",
                       width: "400px",
                       height: "350px",
                       margin: "0 auto",
                    }}
                 />
               )}

                 


              
                  <br></br>
                  <br></br>
                  <span>Notes:

                    <br></br>
                    <textarea
                      rows={3}
                      cols={2}
                      value={NotesBefore}
                      onChange={(e) => setNotesBefore(e.target.value)}

                    />
                  </span>

                  {/* <span>CORE Side Temperature Should be check the above mention location</span> */}
                </div>
                  <br></br>
                  <span>Prepared By</span>
                  <br></br>

                  <DropdownFetcher keyword="emp_details_dropdown_all" setValue={setEmployeeDropdown} />

                  <Select
                    id={`${idEdit}-PreparedBy`}
                    name={`${nameEdit}-PreparedBy`}
                    value={PrepBy}
                    onChange={handlePreparedBy}
                    options={empMoldSetterOptions}

                    placeholder="Select..."
                  />

                </td>

                <td colSpan={2} className="topic-head"><div>
                <input type="file" accept="image/*" onChange={handleImageChange1} />
                <br />
                <Popup
    trigger={ButtonPopup4}
    setTrigger={setButtonPopup4}
    viewPagination={viewPagination}
>
 
    <button onClick={() => setButtonPopup4(false)}>X</button>
  < br />
  <div style={{
        marginLeft:"5%",
        padding:"15px",
        position: 'absolute',
        width: '70%',
        height: '170%',
         backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        }}>
           <div
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            style={{ position: 'relative',marginLeft:"1%" }}
        >
        {!isVisible && (
            <button
            onClick={(e) => {
                if (canvasDrawRef2.current) {
                    handleReset1(e);
                    setedit1(e);
                }
            }}
            style={{ border: 'none', padding: '10px', backgroundColor: 'rgba(0, 0, 0, 0.1)', marginLeft:"10px"}}
        >
            edit
        </button>)}

        {isVisible && (
            <>
                <span
                    style={{ width: '20px', height: '20px', marginLeft:"10px", border: 'none', padding: 0 }}
                    onClick={() => {
                        setIsDrawing1(prevState => !prevState);
                    }}
                >
                    <FaPen />
                </span>

                <input
                    type="color"
                    value={brushColor}
                    onChange={handleColorChange}
                    style={{ width: '20px', height: '20px', border: 'none', padding: 0 , marginLeft:"30px",}}
                />color

               
                <MdRestore onClick={handleReset1} style={{ width: '20px', height: '20px', border: 'none', padding: 0,marginLeft:"20px" }} />Reset
                <button onClick={set2} style={{ marginLeft: '20px', border: 'none', padding: 0 }}>OK</button>
            </>
        )}
            <CanvasDraw
                ref={canvasDrawRef2}
                imgSrc={cimage}
                hideGrid={true}
                canvasHeight={canvasDimensions.height}
                canvasWidth={canvasDimensions.width}
                brushRadius={1}
                lazyRadius={0}
                brushColor={brushColor}
                disabled={!isDrawing1}
                style={{ padding: 1 }}
            />
                  {isVisible && (
              <>
             {[...Array(5).keys()].map((num) => (
                    <Draggable key={num}>
                        <span
                            draggable
                            onDragStart={(e) => handleDrag1(e, (num + 1))}
                            style={{
                                borderRadius: '100%',
                                display: 'inline-block',
                                width: '20px',
                                height: '20px',
                                margin: '5px',
                                border: '1px solid black',
                                textAlign: 'center',
                                lineHeight: '20px',
                                marginLeft:"10px",
                            }}
                        >
                            {num + 1}
                        </span>
                    </Draggable>
                ))}</>)}
        </div>
        <br />
      
    </div>
</Popup>
{CavImg && (
                  <img
                    src={CavImg}
                    alt="Selected"
                    style={{
                      display: "block",
                      width: "400px",
                      height: "350px",
                      margin: "0 auto",
                    }}
                  />
                )}
                  <br></br>
                  <br />
                  <span>
                    Notes:
                    <br></br>
                    <textarea
                      rows={3}
                      cols={2}
                      value={NotesAfter}
                      onChange={(e) => setNotesAfter(e.target.value)}

                    />
                  </span>
                  {/* <span>CAVITY Side Temperature Should be check the above mention location</span> */}
                </div>
                  <br />
                  <span>Approved By</span>
                  <br></br>
                  <Select
                    id={`${idEdit}-ApprovedBy`}
                    name={`${nameEdit}-ApprovedBy`}
                    value={AppBy}
                    onChange={handleApprovedBy}
                    options={empMoldSetterOptions}

                    placeholder="Select..."
                  />
                </td>
              </tr>

            </tbody>
            <td colSpan={5} style={{ border: "none" }}>
              <button type="submit" id="sub" onClick={(e) => handleUpdate(e)}>
                Update
              </button>
            </td>

          </table>
        </form>
      </Popup>

      <div>
        <table
          style={{ textAlign: "center", width: "100%" }}
        /*{ cellPadding="5" }*/
        >
          <thead>
            <tr>
              <th
                id="tsheetcss-Title.List"
                name="tsheet-Title.List"
                colSpan="12"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">List - MOULD TEMPERATURE </font>
              </th>
            </tr>
            <tr>
              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                Prepared By
              </td>
              <td colSpan="1">
                <input
                  id="TempSheet-PreparedBy"
                  name="TempSheet-PreparedBy"
                  type="text"
                  placeholder="Filter Prepared By"
                  style={{ fontSize: "70%", width: "100%", backgroundColor: "#c7c6c4" }}

                  value={filterDate}
                  onChange={handleFilterDateChange}
                />
              </td>

              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                Approved By
              </td>
              <td colSpan={2}>
                <input
                  id="TempSheet-EmpName"
                  name="TempSheet-EmpName"
                  type="text"
                  placeholder="Filter ApprovedBy"
                  style={{ fontSize: "70%", width: "100%", backgroundColor: "#c7c6c4" }}
                  value={filterMcno}
                  onChange={handleFilterMcnoChange}
                />
              </td>

              <td colSpan="8"></td>
            </tr>
            <tr className="table_position">

              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                Prepared By
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                Problem Notes
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                Approved By
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                Problem Notes
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                Status
              </th>
              <th className="table-list-head" >

              </th>
              <th className="table-list-head"></th>
              <th className="table-list-head"></th>
            </tr>
          </thead>
          <tbody>
            {/* <HandleRead keyword="MouldTemperature" setData={sett_sheet} /> */}
            {filteredRecords.length === 0 ? (
              // <tr>
              <td colSpan="12" style={{ textAlign: "center" }}>
                <p
                  id="lump-list-NoRec"
                  name="lump-list-noRec"
                  style={{ fontWeight: "bold" }}
                >
                  No records found.
                </p>
              </td>
            ) : (
              // </tr>
              filteredRecords.map((tsheet) => (
                <tr className="table_view" key={tsheet.Id}>

                  <td id={`${idList}-Prep_by`}
                    name={`${nameList}-Prep_by`}
                    className="table-cell">{tsheet.Prep_by}</td>
                  <td id={`${idList}-Notes`}
                    name={`${nameList}-Notes`}
                    className="table-cell">{tsheet.Note_before}</td>
                  <td id={`${idList}-App_by`}
                    name={`${nameList}-App_by`}
                    className="table-cell">{tsheet.App_by}</td>
                  <td id={`${idList}-Notes`}
                    name={`${nameList}-Notes`}
                    className="table-cell">{tsheet.Notes_after}</td>
                  <td
                    style={{

                      color: tsheet.Approval_status === "0" ? 'red' : tsheet.Approval_status === "1" ? 'green' : tsheet.Approval_status === undefined ? '#E59500' : "",

                    }}
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{tsheet.Approval_status === undefined ? "Waiting for approval..." : tsheet.Approval_status === "1" ? "Approved" : tsheet.Approval_status === "0" ? "Rejected" : ""}
                  </td>
                  <td>
                    <button
                      id={`${idView}-view`} name={`${idView}-view`}
                      style={{ backgroundColor: "#cba423" }}
                      type="button"
                      onClick={() => handleView(tsheet)}
                    >
                      View&#x1F441;
                    </button>
                  </td>
                  {/* {GetAccess("production", "hod") ?  */}
                  <td>
                    <button
                      id={`${idEdit}-Edit`} name={`${idEdit}-Edit`}
                      type="button" onClick={() => handleEdit(tsheet)}>
                      Edit&#x270E;
                    </button>
                  </td> 
                  {/* : ''} */}
                  <td>
                    <button
                      id={`${idEdit}-Delete`}
                      name={`${idEdit}-Delete`}
                      style={{ whiteSpace: "nowrap", backgroundColor: "#DA251A" }}
                      type="button"
                      onClick={() => {
                        const userConfirmed = window.confirm('Are you sure you want to delete?');
                        if (userConfirmed) {
                          UpdateRecord({
                            _keyword_: 'MouldTemperature',
                            data: [
                              {
                                Id: tsheet.Id,
                                ActiveStatus: 'InActive',
                              },
                            ],
                          }).then(() => {
                            handleReadMouldTemperature();
                          })
                          // window.location.reload();
                        }
                      }}
                    >
                      Delete<MdDelete />
                    </button>
                  </td>
                </tr>
              )))}
          </tbody>
        </table>
        <div className="pagination" id="pagination">
          {isPaginationVisible && filteredData.length > 0 && (

            <Pagination currentPage={currentPage} npage={npage} prePage={prePage} nextPage={nextPage} changeCpage={changeCpage} numbers={numbers} />
          )}
        </div>
      </div>
    </div>
  );
}

export default TemperatureSheetView;
