import axios from "axios"
import { BUILD_RESULTS_GENERIC, BUILD_RESULTS_GENERIC_QUARY } from "../../Utils/constants"

const ReadRecord = async(Input) => {
    
    if(Input._keyword_===undefined){
        return axios
        .post(BUILD_RESULTS_GENERIC_QUARY, {
            "sql-query": Input,
          "sql-query-params": [],
          })
        .then(res => {
            console.log("ReadRecord",Input,res.data);
            return res.data
        })
        .catch(error => {
            console.log(error);
            return {data:[{count:'',rows:''}]}
        })
    }else{
        return axios
        .post(BUILD_RESULTS_GENERIC, Input)
        .then(res => {
            console.log("ReadRecord",Input,res.data);
            return res.data
        })
        .catch(error => {
            console.log(error);
            return {data:[{count:'',rows:''}]}
        })
    }
    
}
export default ReadRecord