//external import
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CloseButton from "react-bootstrap/CloseButton";
import { IoMdDownload } from "react-icons/io";

import * as XLSX from "xlsx";
//internal import
import Field from "../../../../Components/Fields/Field";
import Lable from "../../../../Components/Fields/Lable";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Dropdown } from "react-bootstrap";

//css import

//API imports
import approve from "../../../../Lib/approvePaint";
import reject from "../../../../Lib/rejectPaint";
import ReadRecordPaint from "../../../../Lib/API/ReadRecordPaint";
const PreViewPage = ({
  Records,
  setState,
  HandleCreate,
  option,
  HandleUpdate,
  id,
}) => {

  useEffect(() => {
    console.log("90909", Records);
    // console.log("window.Location.href;", window.location.href.split('newSmpMain/')[1].split('/')[0]);
    console.log("window.Location.href;", window.location.href.includes("view"));
  }, []);
  const contentRef = useRef(null);
  const navigate = useNavigate();
  const [exc, setexc] = useState(false);
  const [Approval, setApproval] = useState("");
  const [back, setback] = useState(false);
  const [cardId, setcardId] = useState("");
  const [remarks, setremarks] = useState("");
  const generatePDF = () => {
    const pdf = new jsPDF("landscape", "mm", "a4");
    html2canvas(contentRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 3, 3, 290, 203);
      pdf.save("Paint Loading Report.pdf");
    });
  };
  const generatePDF11 = () => {
    const pdf = new jsPDF("portrait", "mm", "a5");
    html2canvas(contentRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 10, 10, 200, 200);

      pdf.save("Paint Loading Report.pdf");
    });
  };
  const exportToExcel = () => {
    setexc(true);
    setTimeout(() => {
      if (Records.length === 0) {
        alert("No records found. Cannot export to Excel.");
        return;
      }
      const sheetData = document.getElementById("excelTable");
      const wsData = XLSX.utils.table_to_sheet(sheetData);
      wsData["!rows"] = [{ hpx: 50 }];
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, wsData, "Sheet1");
      XLSX.writeFile(wb, "Paint Loading Report.xlsx");
      setexc(false);
    }, 1000);
  };
  const uniquePartNames = Array.from(
    new Set(
      Records[0].paint_loading_bulk_report.map((item) => item.PartName.label)
    )
  );
  const uniquePartNames1 = Array.from(
    new Set(
      Records[0].paint_loading_bulk_report.map(
        (item) => item.PartName[0]?.Part_name
      )
    )
  );
  // const uniquePartNames = Array.from(
  //   new Set(
  //     Records[0].paint_loading_bulk_report
  //       .filter(item => item.PartName && Array.isArray(item.PartName))
  //       .map((item) => item.PartName[0]?.Part_name)
  //   )
  // );

  const paintNames = Records[0].paint_names.map((item, index) => {
    let value = "";
    if (index === 0) {
      value = Records[0].Loader_Name[0].Emp_name || "";
    } else if (index === 1) {
      value = Records[0].Ipa_Name[0].Emp_name || "";
    } else if (index === 2) {
      value = Records[0].Booth_Name[0].Emp_name || "";
    } else if (index === 3) {
      value = Records[0].Painter_Name1[0].Emp_name || "";
    } else if (index === 4) {
      value = Records[0].Painter_Name2[0].Emp_name || "";
    } else if (index === 5) {
      value = Records[0].Operator_Name1[0].Emp_name || "";
    } else if (index === 6) {
      value = Records[0].Operator_Name2[0].Emp_name || "";
    }
    return { key: item.key, value };
  });

  const totalReport = [
    uniquePartNames.length > 0 ? uniquePartNames : uniquePartNames1,
    paintNames,
  ];
  console.log("total:", totalReport);

  const totalFreshParts = {};

  Records[0].paint_loading_bulk_report.forEach((report) => {
    let partName = "";
    if (
      report.PartName &&
      Array.isArray(report.PartName) &&
      report.PartName[0]
    ) {
      partName = report.PartName[0].Part_name;
    } else if (report.PartName && report.PartName.label) {
      partName = report.PartName.label;
    }

    const freshPart = parseInt(report.Fresh_part) || 0;
    const reworkPart = parseInt(report.Rework_part) || 0;
    const rejPart = parseInt(report.Rej_part) || 0;
    const totalPart = parseInt(report.Total_Part) || 0;

    if (totalFreshParts.hasOwnProperty(partName)) {
      totalFreshParts[partName].fresh += freshPart;
      totalFreshParts[partName].rework += reworkPart;
      totalFreshParts[partName].rej += rejPart;
      totalFreshParts[partName].total += totalPart;
    } else {
      totalFreshParts[partName] = {
        part: partName,
        fresh: freshPart,
        rework: reworkPart,
        rej: rejPart,
        total: totalPart,
      };
    }
  });

  console.log("TotalReport:", totalFreshParts);
  const maxLength =
    Object.keys(totalFreshParts).length > 7
      ? Object.keys(totalFreshParts).length
      : 7;
      useEffect(() => {
        if (Records[0].Id !== undefined) {
          const Id = Records[0].Id;
          const tablename = window.location.pathname.split("/")[1];
          ReadRecordPaint(
            `SELECT * FROM upd_data_model.approval where Approver="${localStorage.getItem(
              "empId"
            )}" and TableID="${Id}" and TableName="${tablename}"`
          ).then((res) => {
            console.log("Approcals Record", res.data[0].rows);
            setApproval(res.data[0].rows);
          });
        }
      }, []);
      useEffect(() => {
        console.log("Approcals Record ", Approval);
      });


  return (
    // <div
    //   style={{ position: "absolute", top: "20px", left: "3%", width: "94%",  background: "#f2f2f2",   boxShadow: "0px 0px 10px rgba(43, 42, 42, 0.2)"}}
    // >
    <div
      style={{
        padding: "15px",
        position: "absolute",
        top: "0px",
        left: "2%",
        width: "97%",
        background: "white",
      }}
    >
      <div style={{ right: "4%", flexDirection: "row-reverse" }}>
        <CloseButton
          style={{
            padding: "12px",
            textAlign: "center",
            backgroundColor: "black",
            width: "5%",
          }}
          onClick={(e) => {
            setState(false);
          }}
        />
        {/* <button onClick={(e) => { setState(false) }}>x</button> */}
      </div>
      {Approval[0] &&
      ( window.location.href.includes("view")) &&
      //  (localStorage.getItem("role") === "hod" ||
      //   localStorage.getItem("role") === "SR.EXECUTIVE" ||
      //   localStorage.getItem("role") === "GM" ||
      //   localStorage.getItem("role") === "ADMIN") &&
      Approval[0].Status === "Waiting for Approval" ? (
        <div className="actions">
          <button
            onClick={(e) => {
              approve(
                Records[0].Id,
                "LoadingReport",
                "paint_loading_report"
              );
              navigate(-1);
            }}
            className="approvebtn"
          >
            Approve
          </button>
          <button
            onClick={() => {
              setcardId(id);
              // navigate(-1);
            }}
            className="rejectbtn"
          >
            Reject
          </button>
          {id === cardId && (
            <div>
              <div
                style={{
                  backgroundColor: "gray",
                  zIndex: "100",
                  filter: "blur(8px)",
                  position: "absolute",
                  opacity: ".8",
                  right: "0%",
                  bottom: "0%",
                  width: "100%",
                  height: "100%",
                }}
              ></div>
              <div
                style={{
                  position: "absolute",
                  zIndex: "110",
                  right: "32%",
                  color: "white",
                  bottom: "35%",
                  padding: "20px",
                  borderRadius: "10px",
                }}
              >
                <h3>Remarks</h3>
                <textarea
                  rows={5}
                  cols={50}
                  onChange={(e) => {
                    setremarks(e.target.value);
                  }}
                ></textarea>
                <button
                  onClick={(e) => {
                    reject(
                      Records[0].Id,
                      "LoadingReport",
                      remarks,
                      "paint_loading_report"
                    );
                    navigate(-1);
                  }}
                >
                  Submit
                </button>
                <button
                  onClick={() => {
                    setcardId("");
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="ApprovalRemarks">
          {/* {console.log(Approval[0])} */}
          {Approval?.[0]?.Status === "Rejected" ? (
            <div className="reject">
              <div style={{ fontSize: "16px", fontWeight: "600" }}>
                The Reason OF Rejection is -
                {Approval[0].ApprovalRemark === undefined ||
                Approval[0].ApprovalRemark === ""
                  ? " "
                  : Approval[0].ApprovalRemark}
              </div>
            </div>
          ) : Approval?.[0]?.Status === "Approved" ? (
            <div
              className="approve"
              style={{ fontSize: "16px", fontWeight: "600" }}
            >
              The Record is Approved
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      {window.location.href.includes("view") && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "80%",
              padding: "1px",
            }}
          >
            <Dropdown style={{ width: "50%" }}>
              <Dropdown.Toggle>Download ⬇️</Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <button className="custom-button" onClick={generatePDF}>
                    PDF ⬇️
                  </button>
                </Dropdown.Item>
                <Dropdown.Item>
                  <button
                    className="custom-button"
                    style={{ color: "black" }}
                    onClick={() => exportToExcel()}
                  >
                    Excel ⬇️
                  </button>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>{" "}
        </>
      )}
      <div style={{ marginLeft: "1%", width: "99%" }} ref={contentRef}>
        <table>
          <thead>
            <tr>
              <th colSpan={10}>
                <Lable value={"LoadingReport"} type={"Head"} />
              </th>
              <th colSpan={2} style={{ width: "20%" }}>
                <div className="previewLabel">
                  <span className="previewLabelLeft">Format No:</span>{" "}
                  <span className="previewLabelRight">
                    {Records[0].Format_no}
                  </span>
                  <span className="previewLabelLeft">Rev No:</span>{" "}
                  <span className="previewLabelRight">{Records[0].Rev_no}</span>
                  <span className="previewLabelLeft">Rev Date:</span>{" "}
                  <span className="previewLabelRight">
                    {Records[0].Rev_ate}
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tr>
            <td colSpan={4}>
              <div className="previewLabel">
                <span className="previewLabelLeft">Date:</span>
              </div>
            </td>
            <td colSpan={2}>
              <div className="previewLabel">
                <span className="previewLabelRight">
                  {Records[0].Entry_date}
                </span>
              </div>
            </td>
            <td colSpan={4}>
              <div className="previewLabel">
                <span className="previewLabelLeft">Shift:</span>
              </div>
            </td>
            <td colSpan={2}>
              <div className="previewLabel">
                <span className="previewLabelRight">{Records[0].Shift}</span>
              </div>
            </td>
          </tr>

          <tr>
            <th colSpan={1} style={{ padding: "0px" }}>
              <Lable value={"Time"} type={"Head"} style={{ padding: "1px" }} />
            </th>
            <th colSpan={1} style={{ padding: "0px" }}>
              <Lable
                value={"Part Name"}
                type={"Head"}
                style={{ padding: "1px" }}
              />
            </th>
            <th colSpan={1} style={{ padding: "0px" }}>
              <Lable
                value={"Lot No"}
                type={"Head"}
                style={{ padding: "1px" }}
              />
            </th>
            <th colSpan={2} style={{ padding: "0px" }}>
              <Lable
                value={"Fresh Part"}
                type={"Head"}
                style={{ padding: "1px" }}
              />
            </th>
            <th colSpan={2} style={{ padding: "0px" }}>
              <Lable
                value={"Rework Part"}
                type={"Head"}
                style={{ padding: "1px" }}
              />
            </th>
            <th colSpan={2} style={{ padding: "0px" }}>
              <Lable
                value={"Rejection Part"}
                type={"Head"}
                style={{ padding: "1px" }}
              />
            </th>
            <th colSpan={2} style={{ padding: "0px" }}>
              <Lable
                value={"Total Part"}
                type={"Head"}
                style={{ padding: "1px" }}
              />
            </th>
            <th colSpan={1} style={{ padding: "0px" }}>
              <Lable
                value={"Remarks"}
                type={"Head"}
                style={{ padding: "1px" }}
              />
            </th>
          </tr>

          {Array.from(
            { length: Records[0].paint_loading_bulk_report.length },
            (_, i) => i + 1
          ).map((item) => (
            <tr>
              <td className="previewLabelValue" colSpan={1}>
                <span>
                  {Records[0].paint_loading_bulk_report[Number(item) - 1].Time}
                </span>
              </td>
              <td className="previewLabelValue" colSpan={1}>
                <span>
                  {Records?.[0]?.paint_loading_bulk_report[Number(item) - 1]
                    .PartName[0]?.Part_name ||
                    Records?.[0]?.paint_loading_bulk_report[Number(item) - 1]
                      .PartName?.label ||
                    ""}
                </span>
              </td>
              <td className="previewLabelValue" colSpan={1}>
                <span>
                  {
                    Records[0].paint_loading_bulk_report[Number(item) - 1]
                      .Lot_no
                  }
                </span>
              </td>
              <td className="previewLabelValue" colSpan={2}>
                <span>
                  {
                    Records[0].paint_loading_bulk_report[Number(item) - 1]
                      .Fresh_part
                  }
                </span>
              </td>
              <td className="previewLabelValue" colSpan={2}>
                <span>
                  {
                    Records[0].paint_loading_bulk_report[Number(item) - 1]
                      .Rework_part
                  }
                </span>
              </td>
              <td className="previewLabelValue" colSpan={2}>
                <span>
                  {
                    Records[0].paint_loading_bulk_report[Number(item) - 1]
                      .Rej_part
                  }
                </span>
              </td>
              <td className="previewLabelValue" colSpan={2}>
                <span>
                  {
                    Records[0].paint_loading_bulk_report[Number(item) - 1]
                      .Total_Part
                  }
                </span>
              </td>
              <td className="previewLabelValue" colSpan={1}>
                <span>
                  {
                    Records[0].paint_loading_bulk_report[Number(item) - 1]
                      .Remarks
                  }
                </span>
              </td>
            </tr>
          ))}
          <tr>
            <th colSpan={1} style={{ padding: "0px" }}>
              <Lable
                value={"Part Name"}
                type={"Head"}
                style={{ padding: "1px" }}
              />
            </th>
            <th colSpan={2} style={{ padding: "0px" }}>
              <Lable
                value={"Total Fresh Part Loading Qty."}
                type={"Head"}
                style={{ padding: "1px" }}
              />
            </th>
            <th colSpan={2} style={{ padding: "0px" }}>
              <Lable
                value={"Total Rework Part Loading Qty"}
                type={"Head"}
                style={{ padding: "1px" }}
              />
            </th>
            <th colSpan={2} style={{ padding: "0px" }}>
              <Lable
                value={"Total Rejection Qty"}
                type={"Head"}
                style={{ padding: "1px" }}
              />
            </th>
            <th colSpan={2} style={{ padding: "0px" }}>
              <Lable
                value={"Total Loading Qty"}
                type={"Head"}
                style={{ padding: "1px" }}
              />
            </th>
            <th colSpan={3} style={{ padding: "0px" }}>
              <Lable
                value={"Manpower Details"}
                type={"Head"}
                style={{ padding: "1px" }}
              />
            </th>
          </tr>

          {Array.from(
            { length: Math.max(7, Object.keys(totalFreshParts).length) },
            (_, index) => (
              <tr key={index}>
                <td className="previewLabelValue">
                  {totalFreshParts[Object.keys(totalFreshParts)[index]]?.part ||
                    ""}
                </td>
                <td colSpan={2} className="previewLabelValue">
                  {totalFreshParts[Object.keys(totalFreshParts)[index]]
                    ?.fresh || ""}
                </td>
                <td colSpan={2} className="previewLabelValue">
                  {totalFreshParts[Object.keys(totalFreshParts)[index]]
                    ?.rework || ""}
                </td>
                <td colSpan={2} className="previewLabelValue">
                  {totalFreshParts[Object.keys(totalFreshParts)[index]]?.rej ||
                    ""}
                </td>
                <td colSpan={2} className="previewLabelValue">
                  {totalFreshParts[Object.keys(totalFreshParts)[index]]
                    ?.total || ""}
                </td>
               <td className="previewLabelValue" colSpan={2}>
                  {index < paintNames.length && paintNames[index]
                    ? paintNames[index].key
                    : ""}
                </td>
                <td className="previewLabelValue" colSpan={1}>
                  {index < paintNames.length && paintNames[index]
                    ? paintNames[index].value
                    : ""}
                </td>
              </tr>
            )
          )}

          {/* {[...Array(maxLength)].map((_, index,part) => (
            <tr key={index}>
              <td className="previewLabelRight">
                {index < uniquePartNames.length
                  ? uniquePartNames[index]
                  : uniquePartNames1[index - uniquePartNames.length]}
              </td>
              <td colSpan={2} className="previewLabelRight">{}</td>
              <td colSpan={2} className="previewLabelRight">{}</td>
              <td colSpan={2} className="previewLabelRight"></td>
              <td colSpan={2} className="previewLabelRight"></td>
              <td colSpan={2}>
                {index < paintNames.length
                  ? paintNames[index]
                    ? paintNames[index].key
                    : ""
                  : ""}
              </td>
              <td className="previewLabelRight" colSpan={2}>
                {index < paintNames.length
                  ? paintNames[index]
                    ? paintNames[index].value
                    : ""
                  : ""}
              </td>
            </tr>
          ))} */}

          <tr>
            <td colSpan={2}>
            <div style={{marginLeft:"2%"}}>
                <span className="previewLabelValue">Loading-In-charge:</span>
              </div>
              </td>
              <td colSpan={2}>
              <div className="previewLabel">
                <span className="previewLabelValue">
                  {Records[0].Load_Incharge[0].Emp_name}
                </span>
              </div>
            </td>
            <td colSpan={2} >
            <div style={{marginLeft:"3%"}}>
                <span className="previewLabelValue">Shift-In-charge:</span>
              </div>
              </td>
              <td colSpan={3}>
            <div style={{marginLeft:"3%"}}>
                <span className="previewLabelValue">
                  {Records[0].Shift_Incharge[0].Emp_name}
                </span>
              </div>
            </td>
            <td colSpan={2}>
              <div className="previewLabel">
              <span className="previewLabelValue">ApprovedBy:</span>
              </div>
              </td>
              <td className="previewLabelValue" colSpan={1}>
                  {Records[0].Approved_By[0].Emp_name}
               
            </td>
          </tr>
        
          {!window.location.href.includes("view") && (
            <tr>
              {window.location.href.includes("edit") ? (
                <td colSpan={12}>
                  {" "}
                  <Field
                    className={"submit"}
                    label={"Update"}
                    options={{ type: "Button" }}
                    onClick={(e) => {
                      HandleUpdate(e);
                    }}
                  />
                </td>
              ) : (
                <td colSpan={12}>
                  {" "}
                  <Field
                    className={"submit"}
                    label={"Submit"}
                    options={{ type: "Button" }}
                    onClick={(e) => {
                      HandleCreate(e);
                    }}
                  />
                </td>
              )}
              <br />
              <br />
              <br />
              <br />
              <br />
            </tr>
          )}
            <br />
          <br />
          <br />
          <br />
          
  <br />
          <br />
          <br />
          <br />
        </table>
      </div>

      <div>
        {exc ? (
          <table id="excelTable">
            <thead>
              <tr>
              <td colSpan={2} rowSpan={3}>
                </td>
                <td colSpan={12} rowSpan={3}>
                  <label>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LOADING
                    REPORT
                  </label>
                </td>
                <td colSpan={4} style={{ width: "20%" }}>
                  Format NO: &nbsp;&nbsp; {Records[0].Format_no}
                </td>
              </tr>
              <tr>
                {" "}
                <td colSpan={4} style={{ width: "20%" }}>
                  Rev No: &nbsp;&nbsp; {Records[0].Rev_No}
                </td>
              </tr>
              <tr>
                {" "}
                <td colSpan={4} style={{ width: "20%" }}>
                  Rev Date: &nbsp;&nbsp; {Records[0].Rev_ate}
                </td>
              </tr>
            </thead>

            <tr>
              <td colSpan={2}>
                <div className="previewLabel">
                  <span className="previewLabelLeft">Date:</span>
                </div>
              </td>
              <td colSpan={3}>
                <div className="previewLabel">
                  <span className="previewLabelRight">
                    {Records[0].Entry_date}
                  </span>
                </div>
              </td>
              <td colSpan={3}>
                <div className="previewLabel">
                  <span className="previewLabelLeft">Shift:</span>
                </div>
              </td>
              <td colSpan={10}>
                <div className="previewLabel">
                  <span className="previewLabelRight">{Records[0].Shift}</span>
                </div>
              </td>
            </tr>

            <tr>
              <th colSpan={2} style={{ padding: "0px" }}>
                <Lable
                  value={"Time"}
                  type={"Head"}
                  style={{ padding: "1px" }}
                />
              </th>
              <th colSpan={3} style={{ padding: "0px" }}>
                <Lable
                  value={"PART NAME"}
                  type={"Head"}
                  style={{ padding: "1px" }}
                />
              </th>
              <th colSpan={2} style={{ padding: "0px" }}>
                <Lable
                  value={"Lot No"}
                  type={"Head"}
                  style={{ padding: "1px" }}
                />
              </th>
              <th colSpan={2} style={{ padding: "0px" }}>
                <Lable
                  value={"Fresh Part"}
                  type={"Head"}
                  style={{ padding: "1px" }}
                />
              </th>
              <th colSpan={2} style={{ padding: "0px" }}>
                <Lable
                  value={"Rework Part"}
                  type={"Head"}
                  style={{ padding: "1px" }}
                />
              </th>
              <th colSpan={2} style={{ padding: "0px" }}>
                <Lable
                  value={"Rejection Part"}
                  type={"Head"}
                  style={{ padding: "1px" }}
                />
              </th>
              <th colSpan={2} style={{ padding: "0px" }}>
                <Lable
                  value={"Total Part"}
                  type={"Head"}
                  style={{ padding: "1px" }}
                />
              </th>
              <th colSpan={3} style={{ padding: "0px" }}>
                <Lable
                  value={"Remarks"}
                  type={"Head"}
                  style={{ padding: "1px" }}
                />
              </th>
            </tr>

            {Array.from(
              { length: Records[0].paint_loading_bulk_report.length },
              (_, i) => i + 1
            ).map((item) => (
              <tr>
                <td className="previewLabelValue" colSpan={2}>
                  <span>
                    {
                      Records[0].paint_loading_bulk_report[Number(item) - 1]
                        .Time
                    }
                  </span>
                </td>
                <td className="previewLabelValue" colSpan={3}>
                  <span>
                    {Records?.[0]?.paint_loading_bulk_report[Number(item) - 1]
                      .PartName[0]?.Part_name ||
                      Records?.[0]?.paint_loading_bulk_report[Number(item) - 1]
                        .PartName?.label ||
                      ""}
                  </span>
                </td>
                <td className="previewLabelValue" colSpan={2}>
                  <span>
                    {
                      Records[0].paint_loading_bulk_report[Number(item) - 1]
                        .Lot_no
                    }
                  </span>
                </td>
                <td className="previewLabelValue" colSpan={2}>
                  <span>
                    {
                      Records[0].paint_loading_bulk_report[Number(item) - 1]
                        .Fresh_part
                    }
                  </span>
                </td>
                <td className="previewLabelValue" colSpan={2}>
                  <span>
                    {
                      Records[0].paint_loading_bulk_report[Number(item) - 1]
                        .Rework_part
                    }
                  </span>
                </td>
                <td className="previewLabelValue" colSpan={2}>
                  <span>
                    {
                      Records[0].paint_loading_bulk_report[Number(item) - 1]
                        .Rej_part
                    }
                  </span>
                </td>
                <td className="previewLabelValue" colSpan={2}>
                  <span>
                    {
                      Records[0].paint_loading_bulk_report[Number(item) - 1]
                        .Total_Part
                    }
                  </span>
                </td>
                <td className="previewLabelValue" colSpan={3}>
                  <span>
                    {
                      Records[0].paint_loading_bulk_report[Number(item) - 1]
                        .Remarks
                    }
                  </span>
                </td>
              </tr>
            ))}
            <tr>
              <th colSpan={2} style={{ padding: "0px" }}>
                <Lable
                  value={"PART NAME"}
                  type={"Head"}
                  style={{ padding: "1px" }}
                />
              </th>
              <th colSpan={3} style={{ padding: "0px" }}>
                <Lable
                  value={"Total Fresh Part Loading Qty."}
                  type={"Head"}
                  style={{ padding: "1px" }}
                />
              </th>
              <th colSpan={3} style={{ padding: "0px" }}>
                <Lable
                  value={"Total Rework Part Loading Qty"}
                  type={"Head"}
                  style={{ padding: "1px" }}
                />
              </th>
              <th colSpan={3} style={{ padding: "0px" }}>
                <Lable
                  value={"Total Rejection Qty"}
                  type={"Head"}
                  style={{ padding: "1px" }}
                />
              </th>
              <th colSpan={3} style={{ padding: "0px" }}>
                <Lable
                  value={"Total Loading Qty"}
                  type={"Head"}
                  style={{ padding: "1px" }}
                />
              </th>
              <th colSpan={4} style={{ padding: "0px" }}>
                <Lable
                  value={"Manpower Details"}
                  type={"Head"}
                  style={{ padding: "1px" }}
                />
              </th>
            </tr>

            {Array.from(
              { length: Math.max(7, Object.keys(totalFreshParts).length) },
              (_, index) => (
                <tr key={index}>
                  <td colSpan={2} className="previewLabelValue">
                    {totalFreshParts[Object.keys(totalFreshParts)[index]]
                      ?.part || ""}
                  </td>
                  <td colSpan={3} className="previewLabelValue">
                    {totalFreshParts[Object.keys(totalFreshParts)[index]]
                      ?.fresh || ""}
                  </td>
                  <td colSpan={3} className="previewLabelRight">
                    {totalFreshParts[Object.keys(totalFreshParts)[index]]
                      ?.rework || ""}
                  </td>
                  <td colSpan={3} className="previewLabelRight">
                    {totalFreshParts[Object.keys(totalFreshParts)[index]]
                      ?.rej || ""}
                  </td>
                  <td colSpan={3} className="previewLabelRight">
                    {totalFreshParts[Object.keys(totalFreshParts)[index]]
                      ?.total || ""}
                  </td>
                  <td colSpan={2}>
                    {index < paintNames.length && paintNames[index]
                      ? paintNames[index].key
                      : ""}
                  </td>
                  <td className="previewLabelRight" colSpan={2}>
                    {index < paintNames.length && paintNames[index]
                      ? paintNames[index].value
                      : ""}
                  </td>
                </tr>
              )
            )}

            <tr>
              <td colSpan={3}>
                <span className="previewLabelLeftt">Loading In-charge:</span>
              </td>

              <td colSpan={3}className="previewLabel">
                &nbsp;&nbsp; {Records[0].Load_Incharge[0].Emp_name}
              </td>
              <td colSpan={3}>
                <span className="previewLabelLeftt">Shift In-charge:</span>
              </td>

              <td colSpan={3} className="previewLabel">
                &nbsp;&nbsp; {Records[0].Shift_Incharge[0].Emp_name}
              </td>
              <td colSpan={3}>
                <span className="previewLabeLeftt">Approved By:</span>
              </td>

              <td colSpan={3}>
                &nbsp;&nbsp; {Records[0].Approved_By[0].Emp_name}
              </td>
            </tr>
          </table>
        ) : null}
      </div>
    </div>
  );
};
export default PreViewPage;
