import React, { useEffect, useState } from "react";
import QaLayout from "../../../Quality/QaLayout";
import Field from "../../../../Components/Fields/Field";
import Lable from "../../../../Components/Fields/Lable";
import CreateRecordPaint from "../../../../Lib/API/CreateRecordPaint";
import { useNavigate, useParams } from "react-router-dom";
import ReadRecordPaint from "../../../../Lib/API/ReadRecordPaint";
import LoadingPage from "../../../../Components/Handler/LoadingPage";
import UpdateRecordPaint from "../../../../Lib/API/UpdateRecordPaint";
import * as GeneralConstants from "../../../../Utils/GeneralConstants";
import IsDuplicatePaint from "../../../../Lib/IsDuplicatePaint";
const initState = 
  {
  Primer_code: "",
  Primer_name: "",
  Flex1: "",
  ActiveStatus: "Active",
};
function PrimerMaster() {
  const [Create, setCreate] = useState(initState);
  const [warning, setwarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const { option, id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    console.log("Create", Create);
  });
  useEffect(() => {
    setwarning(false);
    document.title = "Paint Primer Master";
    const fetchData = async () => {
      if (id === undefined) {
        setCreate(initState);
      } else {
        setLoading(true);
        try {
          const res = await ReadRecordPaint({
            _keyword_: "paint_primer_master",
            Id: id,
          });
          console.log("res", res);
          setCreate(res.data[0]);
           console.log("Res2",Create)
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  const HandleCreate = async () => {
    try {
      // console.log(Create);
      const IsDuplicatePaintCode = await IsDuplicatePaint("paint_primer_master", [`primer_code="${Create.Primer_code}"`]);
  
      if (IsDuplicatePaintCode) {
        alert(`Primer with code ${Create.Primer_code} already exists.`);
        return;
      }
  
      const IsDuplicatePaintName = await IsDuplicatePaint("paint_primer_master", [`primer_name="${Create.Primer_name}"`]);
  
      if (IsDuplicatePaintName) {
        alert(`Primer with name ${Create.Primer_name} already exists.`);
        return;
      }
      if (!Create.Primer_code || !Create.Primer_name || !Create.ActiveStatus){
        alert("Please Fill the Mandatory Fields");
        setwarning(true);
        return;
      }
      let record = [{ ...Create }];
      const res = await CreateRecordPaint("paint_primer_master", record);
      console.log("HandleCreate", res);
  
      alert(GeneralConstants.SUBMISSION_SUCCESSFUL);
      setwarning(false);
      setCreate(initState);
    } catch (error) {
      console.error(error);
      alert(GeneralConstants.SUBMISSION_FAILED);
      setwarning(false);
    }
  };
  
  const HandleUpdate = async () => {
    try { 
      if (
        !Create.Primer_code || !Create.Primer_name || !Create.ActiveStatus
        ){
        alert("Please Fill the Mandatory Fields");
        setwarning(true);
        return;
      } 
      let record = [{ ...Create }];
      const res = await UpdateRecordPaint("paint_primer_master", record);
      console.log("HandleUpdate", res);
  
      alert(GeneralConstants.UPDATE_SUCCESSFUL);
      setwarning(false);
    } catch (error) {
      console.error(error);
      alert(GeneralConstants.UPDATE_FAILURE);
      setwarning(false);
    }
    navigate(-1);
  };
 

  return (
    <QaLayout>
      <div>
        <table>
          <tbody>
            <tr>
              <th colSpan={8}>
                <Lable value={"Primer Master"} type={"Head"} />
              </th>
            </tr>
            <tr>
              <td>
                <Field
                  label={"Primer Code"}
                  name={"Primer_code"}
                  options={{ type: "text" ,
                  required: true,
                  disabled: option === "view"|| option === "edit",
                  }}
                  warning={warning}
                  value={Create}
                  OnChange={(e) =>
                    setCreate({ ...Create, Primer_code: e.target.value })
                  }
                />
              </td>
              <td>
                <Field
                  label={"Primer Name"}
                  name={"Primer_name"}
                  options={{ type: "text",  required: true,
                  disabled: option === "view"|| option === "edit",
                  }}
                  warning={warning}
                  value={Create}
                  OnChange={(e) =>
                    setCreate({ ...Create, Primer_name: e.target.value })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>
                <Field
                  label={"Flex"}
                  name={"Flex1"}
                  options={{ type: "text", disabled: option === "view", }}
                  value={Create}
                  OnChange={(e) =>
                    setCreate({ ...Create, Flex1: e.target.value })
                  }
                />
              </td>
              <td>
                <Field
                  label={"Active Status"}
                  name={"ActiveStatus"}
                  options={{
                    type: "dropdown",
                    required:true,
                    disabled: option === "view",
                    values: [
                      { value: "", label: "--Select--" },
                      { value: "Active", label: "Active" },
                      { value: "InActive", label: "InActive" },
                    ],
                  }}
                  warning={warning}
                  value={Create}
                  OnChange={(e) =>
                    setCreate({ ...Create, ActiveStatus: e.target.value })
                  }
                />
              </td>
            </tr>
          
            {!(option === "view") && (
                <tr>
                  {id === undefined ? (
                    <td colSpan={19}>
                      {" "}
                      <Field
                        label={"Submit"}
                        options={{ type: "Button" }}
                        onClick={(e) => {
                          HandleCreate(e);
                        }}
                      />
                    </td>
                  ) : (
                    <td colSpan={19}>
                      {" "}
                      <Field
                        label={"Update"}
                        options={{ type: "Button" }}
                        onClick={(e) => {
                          HandleUpdate(e);
                        }}
                      />
                    </td>
                  )}
                </tr>
              )}
          </tbody>
        </table>
      </div>
    </QaLayout>
  );
}

export default PrimerMaster;
