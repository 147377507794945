import React, { useState, useEffect } from "react";
import Popup from "../../../Components/Popup/Popup";
import { SECRET_KEY, PROCESS_TRANSACTION_UPDATE_URL } from "../../../Utils/constants.js";
//import "bootstrap/dist/css/bootstrap.min.css";
import HandleRead from "../../../Lib/HandleRead"
import "../../../Styles/Part3.css";
import Pagination from "../../../Components/Pagination.js";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import UpdateRecord from "../../../Lib/updateRecord";
import { MdDelete } from "react-icons/md";
import ReadRecord from "../../../Lib/API/ReadRecord.js";


function MachineMasterView() {
  const [isCreating, setIsCreating] = useState(false);
  const [machineMasterAddState, setMachineMasterAddState] = useState({
    SelectedOption: "",
    id: "",
    MacCde: "",
    MacNme: "",
    MacVer: "",
    Flexy1: "",
    Flexy2: "",
    Flexy3: "",
    isPaginationVisible: true,
    currentPage: 1,
    filterMcno: "",
    filterMccd: ""
  })
  const [mac_mas, setmac_mas] = useState([]);
  const [ButtonPopup2, setButtonPopup2] = useState(false);
  const [ButtonPopup, setButtonPopup] = useState(false);

  const recordPerPage = 20;

  const filterRecords = () => {
    const filteredData = mac_mas.filter((record) => {
      const mcnoMatch = record.mc_name
        .toLowerCase()
        .includes(machineMasterAddState.filterMcno.toLowerCase());
      const mccdMatch = record.mc_code
        .toLowerCase().
        includes(machineMasterAddState.filterMccd.toLowerCase());
      return mcnoMatch && mccdMatch;
    });
    return filteredData || [];
  };
  const filteredData = filterRecords();
  const npage = Math.ceil(filteredData.length / recordPerPage);
  const numbers = [...Array(npage).keys()].map((num) => num + 1);

  function handleFilterMcnoChange(event) {
    setMachineMasterAddState({
      ...machineMasterAddState,
      currentPage: 1,
      filterMcno: event.target.value
    });
  }
  function handleFilterMccdChange(event) {
    setMachineMasterAddState({
      ...machineMasterAddState,
      currentPage: 1,
      filterMccd: event.target.value
    });
  }

  const firstIndex = (machineMasterAddState.currentPage - 1) * recordPerPage;
  const lastIndex = firstIndex + recordPerPage;
  const filteredRecords = filteredData.slice(firstIndex, lastIndex);


  function prePage() {
    if (machineMasterAddState.currentPage > 1) {
      setMachineMasterAddState({
        ...machineMasterAddState,
        currentPage: machineMasterAddState.currentPage - 1
      });
    }
  }

  function changeCpage(id) {
    setMachineMasterAddState({
      ...machineMasterAddState,
      currentPage: id
    });
  }
  function nextPage() {
    if (machineMasterAddState.currentPage < npage) {
      setMachineMasterAddState({
        ...machineMasterAddState,
        currentPage: machineMasterAddState.currentPage + 1
      });
    }
  }

  const handleView = (e) => {
    setMachineMasterAddState({
      ...machineMasterAddState,
      isPaginationVisible: false,
      SelectedOption: e.mc_active_status,
      MacCde: e.mc_code,
      MacNme: e.mc_name,
      MacVer: e.mc_version,
      Flexy1: e.mc_flex_01,
      Flexy2: e.mc_flex_02,
      Flexy3: e.mc_flex_03,
      MacBay:e.mc_mac_bay,
    });
    setButtonPopup2(true);
  };

  const viewPagination = () => {
    setMachineMasterAddState({
      ...machineMasterAddState,
      isPaginationVisible: true
    });
  };

  const handleEdit = (e) => {
    setMachineMasterAddState({
      ...machineMasterAddState,
      isPaginationVisible: false,
      SelectedOption: e.mc_active_status,
      MacCde: e.mc_code,
      MacNme: e.mc_name,
      MacVer: e.mc_version,
      Flexy1: e.mc_flex_01,
      Flexy2: e.mc_flex_02,
      Flexy3: e.mc_flex_03,
      id: e.id,
      MacBay:e.mc_mac_bay,
    });
    setButtonPopup(true);
  };
  const handleUpdate = (e) => {
    e.preventDefault();

    if (!machineMasterAddState.MacCde || !machineMasterAddState.MacNme || !machineMasterAddState.SelectedOption) {
      setIsCreating(true);
      alert(GeneralConstants.MANDATORY_ALERT);
      return;
    }
    const data = {
      data: [
        {
          id: machineMasterAddState.id,
          mc_code: machineMasterAddState.MacCde,
          mc_name: machineMasterAddState.MacNme,
          mc_version: machineMasterAddState.MacVer,
          mc_flex_01: machineMasterAddState.Flexy1,
          mc_flex_02: machineMasterAddState.Flexy2,
          mc_flex_03: machineMasterAddState.Flexy3,
          mc_active_status: machineMasterAddState.SelectedOption,
        },
      ],
    };
    data["_keyword_"] = "mac_dtl";
    data["secretkey"] = SECRET_KEY;
    ;
    getAPIRequest(
      PROCESS_TRANSACTION_UPDATE_URL, 'POST', data
    ).then((response) => {
      setMachineMasterAddState({
        ...machineMasterAddState,
        SelectedOption: "",
        MacCde: "",
        MacNme: "",
        MacVer: "",
        Flexy1: "",
        Flexy2: "",
        Flexy3: "",
        SelectedOption: "",
        isPaginationVisible: true,
      });
      // setmac_mas([...mac_mas, response.data]);
      alert(`Machine master code ${GeneralConstants.UPDATE_SUCCESSFUL}`);
      // window.location.reload();
      setButtonPopup(false);
      handleReadMachineMaster();

    })
      .catch((error) => {
        console.log(error);
        alert(GeneralConstants.UPDATE_FAILURE);
        window.location.reload();
      });
  };
  function handleReadMachineMaster() {
    ReadRecord({
      _keyword_: "mac_dtl"
    }).then((res) => {
      // console.log(res.data);
      setmac_mas(res.data);
    });
  }
  useEffect(() => {
    handleReadMachineMaster();
  }, [])
  return (
    <div>
      <Popup
        trigger={ButtonPopup2}
        setTrigger={setButtonPopup2}
        viewPagination={viewPagination}
      >
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        
        <form id="mmv.view.container"
          name="mmv.view.container" className="form-machineMaster-View">
          <table
            id="mmv.view.table"
            name="mmv.view.table"
            style={{ textAlign: "center", width: "100%" }}
            cellPadding="5"
          >
            <tbody>
              <tr>
                <th id="mmv.Title.View"
                  name="mmv.Title.View"
                  height="20%"
                  colSpan="4"
                  style={{ textAlign: "center", whiteSpace: "nowrap" }}
                >
                  <font color="#8B0000">View - Machine Master</font>
                </th>
              </tr>
              <tr>
                <th>&nbsp;&nbsp;Bay</th>
                <td>
                  <label>{machineMasterAddState.MacBay}</label>

                </td>
               
             
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Machine-Code
                </th>
                <td
                  width="25%">
                  <label id="mmv.view.machineCode"
                    name="mmv.view.machineCode" >{machineMasterAddState.MacCde}</label>
                </td>
                </tr>
                <tr>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Machine-Name
                </th>
                <td
                  width="25%">
                  <label id="mmv.view.machineName"
                    name="mmv.view.machineName">{machineMasterAddState.MacNme}</label>
                </td>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Machine-Version
                </th>
                <td>
                  <label id="mmv.view.machineVer"
                    name="mmv.view.machineVer"
                    width="25%">{machineMasterAddState.MacVer}</label>
                </td>
              </tr>
              <tr>
               
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Flex 1
                </th>
                <td>
                  <label id="mmv.view.machineFlex1"
                    name="mmv.view.machineFlex1"
                    width="25%">{machineMasterAddState.Flexy1}</label>
                </td>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Flex 2
                </th>
                <td>
                  <label id="mmv.view.machineFlex2"
                    name="mmv.view.machineFlex2"
                    width="25%">{machineMasterAddState.Flexy2}</label>
                </td>
              </tr>
              <tr>
                
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Flex 3
                </th>
                <td>
                  <label id="mmv.view.machineFlex3"
                    name="mmv.view.machineFlex3"
                    width="25%">{machineMasterAddState.Flexy3}</label>
                </td>
          
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Machine Active Status
                </th>
                <td>
                  <label id="mmv.view.machineSelectedOption"
                    name="mmv.view.machineSelectedOption"
                    width="25%">{machineMasterAddState.SelectedOption}</label>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </Popup>
      <Popup
        trigger={ButtonPopup}
        setTrigger={setButtonPopup}
        viewPagination={viewPagination}
      >
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <form id="mmv.edit.container"
          name="mmv.edit.container"
          className="form-machineMaster-Edit">
          <table
            style={{ textAlign: "center", width: "100%" }}
            cellPadding="5"
          >
            <tbody>
              <tr>
                <th
                  id="mmv.Title.Edit"
                  name="mmv.Title.Edit"
                  colSpan="4"
                  style={{ textAlign: "center", whiteSpace: "nowrap" }}
                >
                  <font color="#8B0000">Edit - Machine Master</font>
                </th>
              </tr>
              <tr>
                <th>&nbsp;&nbsp;Bay<span className="Req-i">*</span></th>
                <td>
                  {machineMasterAddState.MacBay === '' && isCreating && <span className="Req">Required *</span>}
                  <select
                    id="mmc.edit.MacBay"
                    name="mmc.edit.MacBay"
                    onChange={(e) =>
                      setMachineMasterAddState({
                        ...machineMasterAddState,
                        MacBay: e.target.value,
                      })
                    }
                    style={{ textAlign: "center" }}
                    value={machineMasterAddState.MacBay}
                  >
                    <option value="">--Select Bay--</option>
                    <option value="Bay 1">Bay 1</option>
                    <option value="Bay 2">Bay 2</option>
                    <option value="Bay 3">Bay 3</option>
                    <option value="Bay 4">Bay 4</option>
                    <option value="Bay 5">Bay 5</option>
                    <option value="Bay 6">Bay 6</option>
                    <option value="Plant 2">Plant 2</option>
                  </select>
                </td>
                
             
                <th>Machine Code<span style={{ color: "red" }}>*</span></th>
                <td>
                  <input
                    id="mmv.edit.machineCode"
                    name="mmv.edit.machineCode"
                    type="text"
                    value={machineMasterAddState.MacCde}
                    onChange={(e) => setMachineMasterAddState({
                      ...machineMasterAddState,
                      MacCde: e.target.value
                    })}
                    disabled
                  />
                </td>
                </tr>
                <tr>
                <th>Machine Name<span className="Req-i">*</span></th>
                <td>
                  {machineMasterAddState.MacNme === '' && isCreating && <span className="Req">Required *</span>}
                  <input
                    id="mmv.edit.machineName"
                    name="mmv.edit.machineName"
                    type="text"
                    value={machineMasterAddState.MacNme}
                    onChange={(e) => setMachineMasterAddState({
                      ...machineMasterAddState,
                      MacNme: e.target.value
                    })}
                    disabled
                  />
                </td>
                <th>Machine Version</th>
                <td>
                  <input
                    id="mmv.edit.machineVer"
                    name="mmv.edit.machineVer"
                    type="text"
                    value={machineMasterAddState.MacVer}
                    onChange={(e) => setMachineMasterAddState({
                      ...machineMasterAddState,
                      MacVer: e.target.value
                    })}
                  />
                </td>
              </tr>
              <tr>
                
                <th>Flex 1</th>
                <td>
                  <input
                    id="mmv.edit.machineFlex1"
                    name="mmv.edit.machineFlex1"
                    type="text"
                    value={machineMasterAddState.Flexy1}
                    onChange={(e) => setMachineMasterAddState({
                      ...machineMasterAddState,
                      Flexy1: e.target.value
                    })}
                  />
                </td>
                <th>Flex 2</th>
                <td>
                  <input
                    id="mmv.edit.machineFlex2"
                    name="mmv.edit.machineFlex2"
                    type="text"
                    value={machineMasterAddState.Flexy2}
                    onChange={(e) => setMachineMasterAddState({
                      ...machineMasterAddState,
                      Flexy2: e.target.value
                    })}
                  />
                </td>
              </tr>
              <tr>
               
                <th>Flex 3</th>
                <td>
                  <input
                    id="mmv.edit.machineFlex3"
                    name="mmv.edit.machineFlex3"
                    type="text"
                    value={machineMasterAddState.Flexy3}
                    onChange={(e) => setMachineMasterAddState({
                      ...machineMasterAddState,
                      Flexy3: e.target.value
                    })}
                  />
                </td>
              
                <th>Machine Active Status</th>
                <td>
                {machineMasterAddState.SelectedOption === '' && isCreating && <span className="Req">Required *</span>}
              
                  <select
                    id="mmv.edit.SelectedOption"
                    name="mmv.edit.SelectedOption"
                    style={{ width: "80%", textAlign: "center" }}

                    value={machineMasterAddState.SelectedOption}
                    onChange={(e) => setMachineMasterAddState({
                      ...machineMasterAddState,
                      SelectedOption: e.target.value
                    })}
                  >
                    <option id="mmv.edit.SelectedOption.select"
                      name="mmv.edit.SelectedOption.select" value="">-- Select --</option>
                    <option id="mmv.edit.SelectedOption.active"
                      name="mmv.edit.SelectedOption.active"
                      value="Active">Active</option>
                    <option id="mmv.edit.SelectedOption.inactive"
                      name="mmv.edit.SelectedOption.inactive"
                      value="InActive">InActive</option>
                  </select>
                  </td>
              </tr>
              <tr>
                <td colSpan="4" style={{ border: "none" }}>
                  <button
                    id="mmv.update" name="mmv.update"
                    type="submit"

                    onClick={(e) => handleUpdate(e)}
                  >
                    Update
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </Popup>

      <div>
        <table
          style={{ textAlign: "center", width: "100%" }}
        /*{ cellPadding="5" }*/
        >
          <thead>
            <tr>
              <th
                id="mmv.Title.List"
                name="mmv.Title.List"
                colSpan="9"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">List - Machine Master</font>
              </th>
            </tr>
            <tr>
              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                Machine Name
              </td>
              <td>
                <input
                  id="mpmv.filter.Mcname"
                  name="mpmv.filter.Mcname"
                  type="text"
                  placeholder="Filter Machine Name"
                  style={{ fontSize: "80%", width: "100%" }}
                  value={machineMasterAddState.filterMcno}
                  onChange={handleFilterMcnoChange}
                />
              </td>
              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                Machine Code
              </td>
              <td>
                <input
                  id="mpmv.filter.Mccode"
                  name="mpmv.filter.Mccode"
                  type="text"
                  placeholder="Filter Machine Code"
                  style={{ fontSize: "80%", width: "100%" }}
                  value={machineMasterAddState.filterMccd}
                  onChange={handleFilterMccdChange}
                />
              </td>
              <td colSpan={3}></td>
            </tr>
            <tr className="table_position">
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Machine-Code&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Machine-Name&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Bay&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Machine-Version&nbsp;&nbsp;
              </th>
              {/* <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Flex-1&nbsp;&nbsp;
              </th> */}
              {/*
			  <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Flex-2&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Flex-3&nbsp;&nbsp;
              </th>*/}
              {/* <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Created Date&nbsp;&nbsp;
              </th> */}
              <th width="10%">

              </th>
              <th width="10%">

              </th>
              <th width="10%">

              </th>
            </tr>
          </thead>
          <tbody>
            {/* <HandleRead keyword="mac_dtl" setData={setmac_mas} /> */}
            {filteredRecords.length === 0 ? (
              <td colSpan="12" style={{ textAlign: "center" }}>
                <p id="mmv.noRecordFound" name="mmv.noRecordFound" style={{ fontWeight: "bold" }}>No records found.</p>
              </td>
            ) : (
              filteredRecords.map((ele) => (
                <tr className="table_view" key={ele.id}>
                  <td id="mmv.list.machineCode"
                    name="mmv.list.machineCode" className="table-cell">&nbsp;&nbsp;{ele.mc_code}</td>
                  <td id="mmv.list.machineName"
                    name="mmv.list.machineName" className="table-cell">&nbsp;&nbsp;{ele.mc_name}</td>
                     <td id="mmv.list.machineName"
                    name="mmv.list.machineName" className="table-cell">&nbsp;&nbsp;{ele.mc_mac_bay}</td>
                  <td id="mmv.list.machinever"
                    name="mmv.list.machinever"
                    className="table-cell">&nbsp;&nbsp;{ele.mc_version}</td>
                  {/* <td id="mmv.list.flex1"
        name="mmv.list.flex1"
        className="table-cell">&nbsp;&nbsp;{ele.mc_flex_01}</td> */}
                  {/*
				<td className="table-cell">&nbsp;&nbsp;{mac_mas.mc_flex_02}</td>
                <td className="table-cell">&nbsp;&nbsp;{mac_mas.mc_flex_03}</td>
					*/}
                  {/* <td id="mmv.list.createddate"
        name="mmv.list.createddate"
        className="table-cell">&nbsp;&nbsp;{ele.mc_cr_dt}</td> */}
                  <td>
                    <button
                      id="mmv.view" name="mmv.view"
                      style={{ backgroundColor: "#cba423", whiteSpace: "nowrap" }}
                      type="button"
                      onClick={() => handleView(ele)}
                    >
                      View&#x1F441;
                    </button>
                  </td>
                  <td>
                    <button
                      id="mmv.edit" name="mmv.edit"
                      type="button" onClick={() => handleEdit(ele)}>
                      Edit&#x270E;
                    </button>
                  </td>
                  <td>
                    <button
                      id={`mac_dtl-Delete`}
                      name={`mac_dtl-Delete`}
                      style={{ whiteSpace: "nowrap", backgroundColor: "#DA251A" }}
                      type="button"
                      onClick={() => {
                        const userConfirmed = window.confirm('Are you sure you want to delete?');
                        if (userConfirmed) {
                          UpdateRecord({
                            _keyword_: 'mac_dtl',
                            data: [
                              {
                                id: ele.id,
                                mc_active_status: 'InActive',
                              },
                            ],
                          }).then(() => {
                            handleReadMachineMaster();
                          })
                          // window.location.reload();
                        }
                      }}
                    >
                      Delete<MdDelete />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <div className="pagination" id="mmv.pagination" name="mmv.pagination">
          {machineMasterAddState.isPaginationVisible && filteredData.length > 0 && (
            <Pagination currentPage={machineMasterAddState.currentPage} npage={npage} prePage={prePage} nextPage={nextPage} changeCpage={changeCpage} numbers={numbers} />
          )}


        </div>
      </div>
    </div>
  );
}

export default MachineMasterView;
