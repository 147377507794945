import React, { useState, useEffect } from "react";

import Popup from "../../../Components/Popup/Popup";
import HandleRead from "../../../Lib/HandleRead"
import Pagination from "../../../Components/Pagination";

import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import { getAPIRequest } from "../../../Utils/APIUtility";
import { SECRET_KEY, PROCESS_TRANSACTION_UPDATE_URL } from "../../../Utils/constants";
import UpdateRecord from "../../../Lib/updateRecord";
import { MdDelete } from "react-icons/md";
import ReadRecord from "../../../Lib/API/ReadRecord.js";

//import "bootstrap/dist/css/bootstrap.min.css";
import "../../../Styles/Part3.css";

function CustomerMasterView() {
  const [isCreating, setIsCreating] = useState(false);
  const [customerMasterAddState, setCustomerMasterAddState] = useState({
    SelectedOption: "",
    CusCde: "",
    CusNme: "",

    Flexy1: "",
    Flexy2: "",
    Flexy3: "",
    Flexy4: "",
    isPaginationVisible: true,
    currentPage: 1,
    filterCusnm: "",
    filterCuscd: ""
  })

  const [cus_mas, setcus_mas] = useState([]);
  const [ButtonPopup2, setButtonPopup2] = useState(false);
  const [ButtonPopup, setButtonPopup] = useState(false);

  const recordPerPage = 20;
  const filterRecords = () => {
    const filteredData = cus_mas.filter((record) => {
      const cusnmMatch = record.Cus_name
        .toLowerCase()
        .includes(customerMasterAddState.filterCusnm.toLowerCase());
      const cuscdMatch = record.Cus_code
        .toLowerCase().
        includes(customerMasterAddState.filterCuscd.toLowerCase());
      return cusnmMatch && cuscdMatch;
    });
    return filteredData || [];
  };
  const filteredData = filterRecords();
  const npage = Math.ceil(filteredData.length / recordPerPage);
  const numbers = [...Array(npage).keys()].map((num) => num + 1);

  function handleFilterCusnmChange(event) {
    setCustomerMasterAddState({
      ...customerMasterAddState,
      currentPage: 1,
      filterCusnm: event.target.value
    });
  }
  function handleFilterCuscdChange(event) {
    setCustomerMasterAddState({
      ...customerMasterAddState,
      currentPage: 1,
      filterCuscd: event.target.value
    });
  }

  const firstIndex = (customerMasterAddState.currentPage - 1) * recordPerPage;
  const lastIndex = firstIndex + recordPerPage;
  const filteredRecords = filteredData.slice(firstIndex, lastIndex);

  function prePage() {
    if (customerMasterAddState.currentPage > 1) {
      setCustomerMasterAddState({
        ...customerMasterAddState,
        currentPage: customerMasterAddState.currentPage - 1
      });
    }
  }


  function changeCpage(id) {
    setCustomerMasterAddState({
      ...customerMasterAddState,
      currentPage: id
    });
  }

  function nextPage() {
    if (customerMasterAddState.currentPage < npage) {
      setCustomerMasterAddState({
        ...customerMasterAddState,
        currentPage: customerMasterAddState.currentPage + 1
      });
    }
  }

  function nextPage() {
    if (customerMasterAddState.currentPage < npage) {
      setCustomerMasterAddState({
        ...customerMasterAddState,
        currentPage: customerMasterAddState.currentPage + 1
      });
    }
  }

  const handleView = (e) => {
    setCustomerMasterAddState({
      ...customerMasterAddState,
      isPaginationVisible: false,
      //   SelectedOption: e.mc_active_status,
      CusCde: e.Cus_code,
      CusNme: e.Cus_name,

      Flexy1: e.Cus_flex_01,
      Flexy2: e.Cus_flex_02,
      Flexy3: e.Cus_flex_03,
      Flexy4: e.Cus_flex_04,
    });
    setButtonPopup2(true);
  };

  const viewPagination = () => {
    setCustomerMasterAddState({
      ...customerMasterAddState,
      isPaginationVisible: true
    });
  };

  const handleEdit = (e) => {
    setCustomerMasterAddState({
      ...customerMasterAddState,
      isPaginationVisible: false,
      //   SelectedOption: e.mc_active_status,

      Id: e.Id,
      CusCde: e.Cus_code,
      CusNme: e.Cus_name,

      Flexy1: e.Cus_flex_01,
      Flexy2: e.Cus_flex_02,
      Flexy3: e.Cus_flex_03,
      Flexy4: e.Cus_flex_04,

    });
    setButtonPopup(true);
  };

  const handleUpdate = (e) => {
    e.preventDefault();


    if (!customerMasterAddState.CusCde || !customerMasterAddState.CusNme) {
      setIsCreating(true);
      alert(GeneralConstants.MANDATORY_ALERT);
      return;
    }

    const data = {
      data: [
        {
          Id: customerMasterAddState.Id,
          Cus_code: customerMasterAddState.CusCde,
          Cus_name: customerMasterAddState.CusNme,

          Cus_flex_01: customerMasterAddState.Flexy1,
          Cus_flex_02: customerMasterAddState.Flexy2,
          Cus_flex_03: customerMasterAddState.Flexy3,
          Cus_flex_04: customerMasterAddState.Flexy4,
          // mc_active_status: machineMasterAddState.SelectedOption,
        },
      ],
    };
    data["_keyword_"] = "customer_details";
    data["secretkey"] = SECRET_KEY;
    ;
    getAPIRequest(
      PROCESS_TRANSACTION_UPDATE_URL, 'POST', data
    ).then((response) => {
      setCustomerMasterAddState({
        ...customerMasterAddState,
        SelectedOption: "",
        CusCde: "",
        CusNme: "",
        Flexy1: "",
        Flexy2: "",
        Flexy3: "",
        Flexy4: "",
        isPaginationVisible: true,
      });
      // setcus_mas([...cus_mas, response.data]);
      alert(`Customer master code ${GeneralConstants.UPDATE_SUCCESSFUL}`);
      // window.location.reload();
      setButtonPopup(false);
      handleReadCustomerMaster();

    })
      .catch((error) => {
        console.log(error);
        alert(GeneralConstants.UPDATE_FAILURE);
        window.location.reload();
      });
  };

  function handleReadCustomerMaster() {
    ReadRecord({
      _keyword_: "customer_details"
    }).then((res) => {
      // console.log(res.data);
      setcus_mas(res.data);
    });
  }
  useEffect(() => {
    handleReadCustomerMaster();
  }, [])
  return (
    <div>
      <Popup

        trigger={ButtonPopup2}
        setTrigger={setButtonPopup2}
        viewPagination={viewPagination}
      >
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <form id="cmv.view.container"
          name="cmv.view.container" className="form-Master-customerView">
          <table
            id="cmv.view.table"
            name="cmv.view.table"
            style={{ textAlign: "center", width: "100%" }}
            cellPadding="5"
          >
            <tbody>
              <tr>
                <th id="cmv.Title.View"
                  name="cmv.Title.View"
                  height="20%"
                  colSpan="4"
                  style={{ textAlign: "center", whiteSpace: "nowrap" }}
                >
                  <font color="#8B0000">View - Customer Master</font>
                </th>
              </tr>
              <tr>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Customer-Code
                </th>
                <td
                  width="25%">
                  <label id="cmv.view.customerCode"
                    name="cmv.view.customerCode" >{customerMasterAddState.CusCde}</label>
                </td>


                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Customer-Name
                </th>
                <td
                  width="25%">
                  <label id="cmv.view.customerName"
                    name="cmv.view.customerName">{customerMasterAddState.CusNme}</label>
                </td>
              </tr>
              <tr>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Flex 1
                </th>
                <td>
                  <label id="cmv.view.customerFlex1"
                    name="cmv.view.customerFlex1"
                    width="25%">{customerMasterAddState.Flexy1}</label>
                </td>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Flex 2
                </th>
                <td>
                  <label id="cmv.view.customerFlex2"
                    name="cmv.view.customerFlex2"
                    width="25%">{customerMasterAddState.Flexy2}</label>
                </td>
              </tr>

              <tr>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Flex 3
                </th>
                <td>
                  <label id="cmv.view.customerFlex3"
                    name="cmv.view.customerFlex3"
                    width="25%">{customerMasterAddState.Flexy3}</label>
                </td>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Flex 4
                </th>
                <td>
                  <label id="cmv.view.customerFlex4"
                    name="cmv.view.customerFlex4"
                    width="25%">{customerMasterAddState.Flexy4}</label>
                </td>
              </tr>



            </tbody>
          </table>
        </form>
      </Popup>
      <Popup
        trigger={ButtonPopup}
        setTrigger={setButtonPopup}
        viewPagination={viewPagination}
      >
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <form id="cmv.edit.container"
          name="cmv.edit.container"
          className="form-customerMaster-Edit">
          <table
            style={{ textAlign: "center", width: "100%" }}
            cellPadding="5"
          >
            <tbody>
              <tr>
                <th
                  id="cmv.Title.Edit"
                  name="cmv.Title.Edit"
                  colSpan="4"
                  style={{ textAlign: "center", whiteSpace: "nowrap" }}
                >
                  <font color="#8B0000">Edit - Customer Master</font>
                </th>
              </tr>

              <tr>
                <th>Customer Code<span style={{ color: "red" }}>*</span></th>
                <td>
                  <input
                    id="cmv.edit.customerCode"
                    name="cmv.edit.customerCode"
                    type="text"
                    value={customerMasterAddState.CusCde}
                    onChange={(e) => setCustomerMasterAddState({
                      ...customerMasterAddState,
                      CusCde: e.target.value
                    })}
                    disabled
                  />
                </td>
                <th>Customer Name<span style={{ color: "red" }}>*</span></th>
                <td>
                  {customerMasterAddState.CusNme === '' && isCreating && <span className="Req">Required *</span>}
                  <input
                    id="cmv.edit.customerName"
                    name="cmv.edit.customerName"
                    type="text"
                    value={customerMasterAddState.CusNme}
                    onChange={(e) => setCustomerMasterAddState({
                      ...customerMasterAddState,
                      CusNme: e.target.value
                    })}

                  />
                </td>
              </tr>
              <tr>
                <th>Flex 1</th>
                <td>
                  <input
                    id="cmv.edit.Flex1"
                    name="cmv.edit.Flex1"
                    type="text"
                    value={customerMasterAddState.Flexy1}
                    onChange={(e) => setCustomerMasterAddState({
                      ...customerMasterAddState,
                      Flexy1: e.target.value
                    })}
                  />
                </td>
                <th>Flex 2</th>
                <td>
                  <input
                    id="mmv.edit.customerFlex2"
                    name="mmv.edit.customerFlex2"
                    type="text"
                    value={customerMasterAddState.Flexy2}
                    onChange={(e) => setCustomerMasterAddState({
                      ...customerMasterAddState,
                      Flexy2: e.target.value
                    })}
                  />
                </td>
              </tr>
              <tr>
                <th>Flex 3</th>
                <td>
                  <input
                    id="cmv.edit.customerFlex3"
                    name="mmv.edit.customerFlex3"
                    type="text"
                    value={customerMasterAddState.Flexy3}
                    onChange={(e) => setCustomerMasterAddState({
                      ...customerMasterAddState,
                      Flexy3: e.target.value
                    })}
                  />
                </td>
                <th>Flex 4</th>
                <td>
                  <input
                    id="mmv.edit.customerFlex4"
                    name="mmv.edit.customerFlex4"
                    type="text"
                    value={customerMasterAddState.Flexy4}
                    onChange={(e) => setCustomerMasterAddState({
                      ...customerMasterAddState,
                      Flexy4: e.target.value
                    })}
                  />
                </td>

              </tr>
              <tr>
                <td colSpan="4" style={{ border: "none" }}>
                  <button
                    id="mmv.update" name="mmv.update"
                    type="submit"
                    style={{ border: "none" }}
                    onClick={(e) => handleUpdate(e)}
                  >
                    Update
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </Popup>



      <div>
        <table
          style={{ textAlign: "center", width: "100%" }}
        /*{ cellPadding="5" }*/
        >
          <thead>
            <tr>
              <th
                id="cmv.Title.List"
                name="cmv.Title.List"
                colSpan="9"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">List - Customer Master</font>
              </th>
            </tr>
            <tr>
              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                Customer Name
              </td>
              <td>
                <input
                  id="cmv.filter.Cusname"
                  name="cmv.filter.Cusname"
                  type="text"
                  placeholder="Filter Customer Name"
                  style={{ fontSize: "80%", width: "100%" }}
                  value={customerMasterAddState.filterCusnm}
                  onChange={handleFilterCusnmChange}
                />
              </td>
              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                Customer Code
              </td>
              <td>
                <input
                  id="cmv.filter.Cuscode"
                  name="cmv.filter.Cuscode"
                  type="text"
                  placeholder="Filter Customer Code"
                  style={{ fontSize: "80%", width: "100%" }}
                  value={customerMasterAddState.filterCuscd}
                  onChange={handleFilterCuscdChange}
                />
              </td>
              <td></td>
            </tr>

            <tr className="table_position">
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Customer-Code&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Customer-Name&nbsp;&nbsp;
              </th>

              {/* <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Flex-1&nbsp;&nbsp;
              </th> */}
              {/*
			  <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Flex-2&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Flex-3&nbsp;&nbsp;
              </th>*/}
              {/* <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Created Date&nbsp;&nbsp;
              </th> */}
              <th width="10%">

              </th>
              <th width="10%">

              </th>
              <th width="10%">

              </th>
            </tr>
          </thead>
          <tbody>
            {/* <HandleRead keyword="customer_details" setData={setcus_mas} /> */}
            {filteredRecords.length === 0 ? (
              <td colSpan="13" style={{ textAlign: "center" }}>
                <p id="mmv.noRecordFound" name="mmv.noRecordFound" style={{ fontWeight: "bold" }}>No records found.</p>
              </td>
            ) : (
              filteredRecords.map((ele) => (
                <tr className="table_view" key={ele.Id}>
                  <td id="cmv.list.CustomerCode"
                    name="cmv.list.CustomerCode" className="table-cell">&nbsp;&nbsp;{ele.Cus_code}</td>
                  <td id="cmv.list.customerName"
                    name="cmv.list.customerName" className="table-cell">&nbsp;&nbsp;{ele.Cus_name}</td>

                  {/* <td id="cmv.list.flex1"
        name="cmv.list.flex1"
        className="table-cell">&nbsp;&nbsp;{ele.Cus_flex_01}</td> */}
                  {/*
				<td className="table-cell">&nbsp;&nbsp;{mac_mas.mc_flex_02}</td>
                <td className="table-cell">&nbsp;&nbsp;{mac_mas.mc_flex_03}</td>
					*/}
                  {/* <td id="mmv.list.createddate"
        name="mmv.list.createddate"
        className="table-cell">&nbsp;&nbsp;{ele.mc_cr_dt}</td> */}
                  <td>
                    <button
                      id="cmv.view" name="cmv.view"
                      style={{ backgroundColor: "#cba423", whiteSpace: "nowrap" }}
                      type="button"
                      onClick={() => handleView(ele)}
                    >
                      View&#x1F441;
                    </button>
                  </td>
                  <td>
                    <button
                      id="cmv.edit" name="cmv.edit"
                      type="button" onClick={() => handleEdit(ele)}>
                      Edit&#x270E;
                    </button>
                  </td>
                  <td>
                    <button
                      id={`cusomerMaster-Delete`}
                      name={`cusomerMaster-Delete`}
                      style={{ whiteSpace: "nowrap", backgroundColor: "#DA251A" }}
                      type="button"
                      onClick={() => {
                        const userConfirmed = window.confirm('Are you sure you want to delete?');
                        if (userConfirmed) {
                          UpdateRecord({
                            _keyword_: 'customer_details',
                            data: [
                              {
                                Id: ele.Id,
                                Active_status: 'InActive',
                              },
                            ],
                          }).then(() => {
                            handleReadCustomerMaster();
                          })
                          // window.location.reload();
                        }
                      }}
                    >
                      Delete<MdDelete />
                    </button>
                  </td>
                </tr>
              )))}
          </tbody>
        </table>
        <div className="pagination" id="cmv.pagination" name="cmv.pagination">
          {customerMasterAddState.isPaginationVisible && filteredData.length > 0 && (
            <Pagination currentPage={customerMasterAddState.currentPage} npage={npage} prePage={prePage} nextPage={nextPage} changeCpage={changeCpage} numbers={numbers} />
          )}


        </div>
      </div>
    </div>
  );
}

export default CustomerMasterView;