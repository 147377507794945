import axios from "axios"
import { PROCESS_TRANSACTION_URL, SECRET_KEY } from "../../Utils/constants"

const CreateRecord = (KeyWord, Input) => {
    return axios
        .post(PROCESS_TRANSACTION_URL, {
            data: Input,
            _keyword_: KeyWord,
            secretkey: SECRET_KEY,
        })
        .then(res => {
            console.log("CreateRecord",Input,res);
            
            return res.data
        })
        .catch(error => {
            return { error: error }
        })
}
export default CreateRecord