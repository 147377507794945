export default async function Base64Convertor(file){
  const base64 = await convertToBase64(file);
  return base64;
  
};
const convertToBase64 = (file) => {
  return new Promise((Resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      Resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
