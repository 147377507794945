import React, { useEffect, useState, useRef } from "react";
import "../../../Styles/EcnReport.css";
import {
  SECRET_KEY,
  CODEGEN_URL,
  PROCESS_TRANSACTION_URL,
} from "../../../Utils/constants.js";
import { getAPIRequest } from "../../../Utils/APIUtility";

import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import Unitechlogo from "../../../icons/Unitechlogo.jpeg";

import DatePicker from "../../../Lib/DatePicker.js";
import Base64Convertor from "../../../Lib/Base64Convertor";
import DropdownFetcher from "../../../Lib/DropdownFetcher.js";
import { setMessage } from "../../../Lib/SetMessage.js";
import ReadRecord from "../../../Lib/API/ReadRecord.js";

import Select from "react-select";
import CreateApproval from "../../../Lib/CreateApproval";
import useScanDetection from "use-scan-detection";
const initialState = {
  Respnse: "",
  Customer: "",
  Model: "",
  PartName: "",
  Problem: "",
  CrtDetails: "",
  Remarks: "",
  Before: "",
  After: "",
  TargDate: "",
  QualityHead: "",
  HoldHead: "",
  PlantHead: "",
  Stock: "",
  ecn_report: [],
};

function EcnReportCreate() {
  const scrollTop = useRef(null);
  const [ecnReport, setecnReport] = useState(initialState);
  const [customer, setcustomer] = useState([]);
  const [part, setPart] = useState([]);
  const [Employee, setEmployee] = useState([]);
  const [HODEmployee, setHODEmployee] = useState([]);
  const [isCreating, setIsCreating] = useState(false);

  //Unique id and name
  const id = "EcnReport";
  const name = "EcnReport";

  // const CodeGen = async () => {
  //   try {
  //     const val = {
  //       tablename: "ecn_report",
  //       PrimaryCode: "mc_part_name",
  //       PrimaryVal: ecnReport.MachineNo,
  //       FieldCode: "Part_name",
  //       FieldVal: ecnReport.MachineName,
  //     };
  //     console.log(val);
  //     const response = await getAPIRequest(CODEGEN_URL, "POST", val);
  //     const response1 = await response.json();
  //     // console.log("response1", response1);
  //     return response1.success === "true";
  //   } catch (error) {
  //     throw error;
  //   }
  // };
  useEffect(() => {
    document.title = "ECN Report"
  }, [])

  const handleCreate = async (e) => {
    e.preventDefault();
    const isSubmissionSuccessful = true;
    if (
      !ecnReport.Respnse ||
      !ecnReport.Customer ||
      !ecnReport.Model ||
      !ecnReport.PartName ||
      !ecnReport.TargDate ||
      !ecnReport.QualityHead ||
      !ecnReport.HoldHead ||
      !ecnReport.PlantHead
    ) {
      setIsCreating(true);
      alert(GeneralConstants.MANDATORY_ALERT);
      if (scrollTop.current) {
        scrollTop.current.scrollIntoView({ behavior: "smooth" });
      }
      return;
    }
    try {
      // const isDuplicate = await CodeGen();

      // if (isDuplicate) {
      //   setIsCreating(false);
      //   alert(` ${GeneralConstants.ALREADY_EXIST}`);
      //   setecnReport({ ...ecnReport, ...initialState });
      //   return;
      // }
      const data = {
        data: [
          {
            Respnse: ecnReport.Respnse,
            Customer: ecnReport.Customer.value,
            Model: ecnReport.Model,
            Part_name: ecnReport.PartName.value,
            Problem: ecnReport.Problem,
            Crt_details: ecnReport.CrtDetails,
            Remarks_ecn: ecnReport.Remarks,
            Before_ecn_pic: ecnReport.Before,
            After_ecn_pic: ecnReport.After,
            target_date: ecnReport.TargDate,
            Quality_head: ecnReport.QualityHead.value,
            Hold_head: ecnReport.HoldHead.value,
            Plant_head: ecnReport.PlantHead.value,
            Stock: ecnReport.Stock,
            Quality_head_approval_status: '0'
          },
        ],
      };

      data["_keyword_"] = "Ecn_report";
      data["secretkey"] = SECRET_KEY;
      const response = await getAPIRequest(
        PROCESS_TRANSACTION_URL,
        "POST",
        data
      );
      const response1 = await response.json();
      console.log(response1);
      CreateApproval({
        RequestedBy:
          localStorage.getItem("empId"),
        Approver: ecnReport.QualityHead.value,
        Level: 1,
        Status: "Waiting for Approval",
        TableName: "EcnReport",
        TableID: response1.ROWID,
        Description:
          "The ECN Report Has been Created By - " +
          localStorage.getItem("name") +
          ", For The Part Name - " +
          ecnReport.PartName.label +
          " and The Problem is " +
          ecnReport.Problem,
          CreatedBy:localStorage.getItem("empId"),
      });
      CreateApproval({
        RequestedBy:
          localStorage.getItem("empId"),
        Approver: ecnReport.HoldHead.value,
        Level: 1,
        Status: "Waiting for Approval",
        TableName: "EcnReport",
        TableID: response1.ROWID,
        Description:
          "The ECN Report Has been Created By - " +
          localStorage.getItem("name") +
          ", For The Part Name - " +
          ecnReport.PartName.label +
          " and The Problem is " +
          ecnReport.Problem,
          CreatedBy:localStorage.getItem("empId"),
      });
      CreateApproval({
        RequestedBy:
          localStorage.getItem("empId"),
        Approver: ecnReport.PlantHead.value,
        Level: 1,
        Status: "Waiting for Approval",
        TableName: "EcnReport",
        TableID: response1.ROWID,
        Description:
          "The ECN Report Has been Created By - " +
          localStorage.getItem("name") +
          ", For The Part Name - " +
          ecnReport.PartName.label +
          " and The Problem is " +
          ecnReport.Problem,
          CreatedBy:localStorage.getItem("empId"),
      });
      setMessage({
        NoteFrom: localStorage.getItem("empId"),
        NoteTo: ecnReport.HoldHead.value,
        NoteMessage:
          `The ECN Report Has been Created By ${localStorage.getItem("empId")}-
        ${localStorage.getItem("name")} 
        , For The Part Name - 
        ${ecnReport.PartName.label}
        and The Problem is
        ${ecnReport.Problem}`,
        NoteRecordId: response1.ROWID,
        TableName: "EcnReport",
        NoteCreatedBy: localStorage.getItem("empId"),


      });
      setMessage({
        NoteFrom: localStorage.getItem("empId"),
        NoteTo: ecnReport.QualityHead.value,
        NoteMessage:
          `The ECN Report Has been Created By ${localStorage.getItem("empId")}-
        ${localStorage.getItem("name")} 
        , For The Part Name - 
        ${ecnReport.PartName.label}
        and The Problem is
        ${ecnReport.Problem}`,
        NoteRecordId: response1.ROWID,
        TableName: "EcnReport",
        NoteCreatedBy: localStorage.getItem("empId"),


      });
      setMessage({
        NoteFrom: localStorage.getItem("empId"),
        NoteTo: ecnReport.PlantHead.value,
        NoteMessage:
          `The ECN Report Has been Created By ${localStorage.getItem("empId")}-
        ${localStorage.getItem("name")} 
        , For The Part Name - 
        ${ecnReport.PartName.label}
        and The Problem is
        ${ecnReport.Problem}`,
        NoteRecordId: response1.ROWID,
        TableName: "EcnReport",
        NoteCreatedBy: localStorage.getItem("empId"),


      });
      if (response1.success === "false") {
        alert(GeneralConstants.FAIL_TO_SAVE_MACHINE);
        window.location.reload();
      } else {
        const dailypro = [...ecnReport.ecn_report, response.data];
        setecnReport({
          ...ecnReport,
          ecn_report: dailypro,
        });

      }
      if (isSubmissionSuccessful) {
        alert(`${GeneralConstants.SUBMISSION_SUCCESSFUL}`);
        setecnReport({ ...ecnReport, ...initialState });
        setIsCreating(false);
        const img = document.getElementById('ecn_pics');
        img.setAttribute('src', '');
        img.value = "";
        if (scrollTop.current) {
          scrollTop.current.scrollIntoView({ behavior: "smooth" });
        }
      }
    } catch (error) {
      console.log(error);
      alert(`${ecnReport.PartName.value} ${GeneralConstants.SUBMISSION_FAILED}`);
      window.location.reload();
    }
  };

  const handlePhotoChangeAfter = async (e) => {
    const selectedFile = e.target.files[0];
    let img = await Base64Convertor(selectedFile);
    setecnReport({
      ...ecnReport,
      After: img,
    });
  };
  const handlePhotoChangeBefore = async (e) => {
    const selectedFile = e.target.files[0];
    let img = await Base64Convertor(selectedFile);
    setecnReport({
      ...ecnReport,
      Before: img,
    });
  };
  const handleDateChange = (newDate) => {
    setecnReport({ ...ecnReport, TargDate: newDate });
  };
  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 3;
  const today = new Date().toISOString().split("T")[0];

  const handleCustomer = (selectedOption) => {
    const selected = customer.find(
      (option) => option.Cus_code === selectedOption.value
    );
    if (selected) {
      setecnReport({
        ...ecnReport,
        Customer: {
          value: selected.Cus_code,
          label: selected.Cus_name,
        },
      });
    }
  };
  const handlepart = (selectedOption) => {
    const selected = part.find(
      (option) => option.mc_part_name === selectedOption.value
    );

    if (selected) {
      setecnReport({
        ...ecnReport,
        PartName: {
          value: selected.mc_part_name,
          label: selected.mc_part_name,
        },
        Model: selected.mc_part_flex_01,
      });
    }
  };
  const handleQualityHead = (selectedOption) => {
    const selected = Employee.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setecnReport({
        ...ecnReport,
        QualityHead: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      });
    }
  };
  const HandleHoldingHead = (selectedOption) => {
    const selected = Employee.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setecnReport({
        ...ecnReport,
        HoldHead: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      });
    }
  };
  const HandlePlantHead = (selectedOption) => {
    const selected = HODEmployee.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setecnReport({
        ...ecnReport,
        PlantHead: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      });
    }
  };
  // barcode scannning to get input
  useScanDetection({
    onComplete: (code) => {
      code = code.replaceAll("Shift", "");
      setField(code);
    },
    minLength: 3,

    // preventDefault: true,
  });

  // set value to the appropiate field
  function setField(code) {
    let isnumber = Number(code).toString() !== "NaN";

    if (code.includes("EMP")) {
      alert("Invalid Input");
      return false;
    } else
      if (code.includes("RMI")) {
        alert("Invalid Input");
        return false;
      } else if (code.includes("CUS")) {
        alert("Invalid Input");
        return false;
      } else if (code.includes("FG")) {
        alert("Invalid Input");
        return false;
      } else if (code.includes("IMM")) {
        alert("Invalid Input");
        return false;
      } else if (code.includes("SUP")) {
        alert("Invalid Input");
        return false;
      } else {
        // handlepart({value:code})
        GetSelectValue1(code, "Get_part_from_smp")
      }

  }
  function GetSelectValue(value, keyword, setName) {
    if (value.length > 0) {
      ReadRecord({
        _keyword_: keyword,
        key: "%" + value + "%",
        value: "%" + value + "%"
      }).then((res) => {
        console.log(res.data);
        setName(res.data);
      });
    }
  }
  function GetSelectValue1(value, keyword) {    
      ReadRecord({
        _keyword_: keyword,
        key: "%" + value + "%",
        value: "%" + value + "%"
      }).then((res) => {
        if(res.rows>0){
          setecnReport({
            ...ecnReport,
            PartName: {
              value: res.data[0].mc_part_name,
              label: res.data[0].mc_part_name,
            },
            Model: res.data[0].mc_part_flex_01,
          });
        }else{
          alert("Not Available")
        }
      });
    
  }
  return (
    <div>
      <form
        id="EcnReport-formContainer-Create"
        name="EcnReport-formContainer-Create"
        className="form-EcnReport-Create"
      >
        <div ref={scrollTop}>
          <table style={{ textAlign: "center", width: "100%" }}>
            <tbody>
              <tr>
                <th
                  id={`${id}-Title`}
                  name={`${name}-Title`}
                  colSpan="7"
                  style={{
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    padding: "10px 0px",
                  }}
                >
                  {/* <img src={Unitechlogo} style={{ width: "70px" }}></img>
                </th>
                <th
                  id={`${id}-Title`}
                  name={`${name}-Title`}
                  colSpan="5"
                  style={{
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    padding: "10px 0px",
                  }}
                >
                  <font style={{ fontSize: "24px" }}>
                    UNITECH PLASTO COMPONENTS (P) LTD
                  </font>
                  <br></br> */}
                  <font color="#8B0000">ECN Report</font>
                </th>
              </tr>

              <tr>
                <td colSpan={2} className="topic-head">
                  Response<span style={{ color: "red" }}>*</span>
                </td>
                <td>
                  <input
                    id={`${id}-Response`}
                    name={`${name}-Response`}
                    type="text"
                    value={ecnReport.Respnse}
                    onChange={(e) =>
                      setecnReport({
                        ...ecnReport,
                        Respnse: e.target.value,
                      })
                    }
                  />
                  {ecnReport.Respnse === "" && isCreating && (
                    <span className="Req">Required</span>
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="topic-head">
                  Customer<span style={{ color: "red" }}>*</span>
                </td>
                <td>
                  <DropdownFetcher
                    keyword="customer_details"
                    setValue={setcustomer}
                  />
                  {/* <DropdownFilterSelect
                  value={ecnReport.Customer}
                  onChange={(e) =>
                    setecnReport({
                      ...ecnReport,
                      Customer: e.value,
                    })
                  }
                  options={mcNoOptions}
                  placeholder="Select Customer"
                /> */}
                  <Select
                    value={ecnReport.Customer}
                    onChange={handleCustomer}
                    options={customer.map((i) => ({
                      value: i.Cus_code,
                      label: i.Cus_name,
                    }))}
                    placeholder="Select Customer"
                  />
                  {ecnReport.Customer === "" && isCreating && (
                    <span className="Req">Required</span>
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="topic-head">
                  Model<span style={{ color: "red" }}>*</span>
                </td>
                <td>
                  <input
                    id={`${id}-Model`}
                    name={`${name}-Model`}
                    type="text"
                    value={ecnReport.Model}
                    onChange={(e) =>
                      setecnReport({
                        ...ecnReport,
                        Model: e.target.value,
                      })
                    }
                    disabled={ecnReport.PartName === ""}
                  />
                  {ecnReport.Model === "" && isCreating && (
                    <span className="Req">Required</span>
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="topic-head">
                  Part Name<span style={{ color: "red" }}>*</span>
                </td>
                <td>
                  {/* <DropdownFetcher
                    keyword="Get_part_from_smp"
                    setValue={setPart}
                  /> */}
                  <Select
                    value={ecnReport.PartName}
                    onChange={handlepart}
                    options={part.map((i) => ({
                      value: i.mc_part_name,
                      label: i.mc_part_name,
                    }))}
                    onInputChange={(event) => { GetSelectValue(event, "Get_part_from_smp", setPart) }}
                    placeholder="Search Part..."
                  />
                  {ecnReport.PartName === "" && isCreating && (
                    <span className="Req">Required</span>
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="topic-head">
                  Problem
                </td>
                <td>
                  <input
                    id={`${id}-Problem`}
                    name={`${name}-Problem`}
                    type="text"
                    value={ecnReport.Problem}
                    onChange={(e) =>
                      setecnReport({
                        ...ecnReport,
                        Problem: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>

              <tr>
                <td colSpan={2} className="topic-head">
                  Correction Details
                </td>
                <td>
                  <input
                    id={`${id}-CrtDetails`}
                    name={`${name}-CrtDetails`}
                    type="text"
                    value={ecnReport.CrtDetails}
                    onChange={(e) =>
                      setecnReport({
                        ...ecnReport,
                        CrtDetails: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="topic-head">
                  Remarks
                </td>
                <td>
                  {/* <input
                id={`${id}-Remarks`}
                name={`${name}-Remarks`}
                  type="text"
                  value={ecnReport.Remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                /> */}
                  <textarea
                    id={`${id}-Remarks`}
                    name={`${name}-Remarks`}
                    rows={4}
                    cols={50}
                    value={ecnReport.Remarks}
                    onChange={(e) =>
                      setecnReport({
                        ...ecnReport,
                        Remarks: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="topic-head">
                  Stock
                </td>
                <td>
                  <input
                    id={`${id}-Stock`}
                    name={`${name}-Stock`}
                    type="text"
                    value={ecnReport.Stock}
                    onChange={(e) =>
                      setecnReport({
                        ...ecnReport,
                        Stock: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <table>
          <tbody>
            <tr className="th-head">
              <th colSpan={3} style={{ textAlign: "center" }}>
                Part Profile
              </th>
            </tr>
            <tr className="th-head">
              <th>Before</th>
              <th colSpan={2}>After</th>
            </tr>
            <tr>
              <td style={{ height: "250px", width: "50%" }}>
                {ecnReport.Before && (
                  <img
                    src={ecnReport.Before}
                    alt="Selected"
                    style={{
                      display: "block",
                      width: "500px",
                      height: "300px",
                    }}
                  />
                )}
                <input

                  id={`ecn_pics`}
                  name={`${name}-Before`}
                  type="file"
                  accept="image/*"
                  capture="camera"
                  onChange={(e) => handlePhotoChangeBefore(e)}
                />
              </td>

              <td colSpan={2}>
                {ecnReport.After && (
                  <img
                    src={ecnReport.After}
                    alt="Selected"
                    style={{
                      display: "block",
                      width: "500px",
                      height: "300px",
                    }}
                  />
                )}
                <input
                  id={`ecn_pics`}
                  name={`${name}-After`}
                  type="file"
                  accept="image/*"
                  capture="camera"
                  onChange={(e) => handlePhotoChangeAfter(e)}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td className="topic-head" colSpan={2}>
                Target Date<span style={{ color: "red" }}>*</span>
              </td>
              <td colSpan={5}>
                <DatePicker
                  value={ecnReport.TargDate}
                  onChange={handleDateChange}
                />
                {ecnReport.TargDate === "" && isCreating && (
                  <span className="Req">Required</span>
                )}
              </td>
            </tr>
            <tr>
              <td className="topic-head" colSpan={2}>
                Quality HOD<span style={{ color: "red" }}>*</span>
                <br></br>
                <br></br>
                <DropdownFetcher keyword="emp_details_dropdown_all" setValue={setEmployee} />
                <Select
                  styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                  value={ecnReport.QualityHead}
                  onChange={handleQualityHead}
                  options={Employee.map((i) => ({
                    value: i.Emp_code,
                    label: i.Emp_name,
                  }))}
                />
                {ecnReport.QualityHead === "" && isCreating && (
                  <span className="Req">Required</span>
                )}
              </td>

              <td className="topic-head" colSpan={2}>
                Molding HOD<span style={{ color: "red" }}>*</span>
                <br></br>
                <br></br>
                <Select
                  styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                  value={ecnReport.HoldHead}
                  onChange={HandleHoldingHead}
                  options={Employee.map((i) => ({
                    value: i.Emp_code,
                    label: i.Emp_name,
                  }))}
                />
                {ecnReport.HoldHead === "" && isCreating && (
                  <span className="Req">Required</span>
                )}
              </td>
              <DropdownFetcher keyword="emp_details_hod" setValue={setHODEmployee} />
              <td className="topic-head" colSpan={3}>
                Plant HOD<span style={{ color: "red" }}>*</span>
                <br></br>
                <br></br>
                <Select
                  styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                  value={ecnReport.PlantHead}
                  onChange={HandlePlantHead}
                  options={HODEmployee.map((i) => ({
                    value: i.Emp_code,
                    label: i.Emp_name,
                  }))}
                />
                {ecnReport.PlantHead === "" && isCreating && (
                  <span className="Req">Required</span>
                )}
              </td>
            </tr>

            <tr>
              <td colSpan="7" align="center" style={{ border: "none" }}>
                <button
                  type="submit"
                  id={`${id}-submit`}
                  name={`${name}-submit`}
                  onClick={(e) => handleCreate(e)}
                  disabled={ecnReport.PartName === "" || ecnReport.QualityHead === "" || ecnReport.HoldHead === "" || ecnReport.PlantHead === ""}
                  hidden={ecnReport.PartName === "" || ecnReport.QualityHead === "" || ecnReport.HoldHead === "" || ecnReport.PlantHead === ""}
                >
                  Submit
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
}

export default EcnReportCreate;
