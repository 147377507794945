import React from "react";
import ListView from "../../../Components/ListView/ListView";
import QaLayout from "../../Quality/QaLayout";

const NewSmpList = () => {
  return (
    <QaLayout quary={"production_smp_parent"}>
    <div>
      <ListView
        Title={"List - Standard Moulding Parameter"}
        table={"production_smp_parent smp"}
        tableDelete={"Production_smp_parent"}
        standard={"smp.ActiveStatus = 1"}
        quary={"SELECT distinct smp.Id, smp.Part_name,smp.Part_number,smp.Raw_material_name,smp.Machine_no,smp.Prepared_by,smp.Approved_by,prepared_by_details.emp_name AS Prepared_by_name, approved_by_details.emp_name AS Approved_by_name, rm_details.rm_description AS Rm_name FROM production_smp_parent smp LEFT JOIN  emp_details AS prepared_by_details ON smp.Prepared_by = prepared_by_details.emp_code LEFT JOIN  emp_details AS approved_by_details ON smp.Approved_by = approved_by_details.emp_code  LEFT JOIN  rm_details ON smp.Raw_material_name = rm_details.rm_code order by smp.Id DESC"}
        navigation={"newSmpMain"}
        filter={["smp.Machine_no","smp.Part_number","smp.Part_name", "smp.Id" ]}
       // group by smp.Id,rm_details.rm_description, smp.Part_name, smp.Part_number, smp.Raw_material_name, smp.Machine_no, smp.Prepared_by, smp.Approved_by, prepared_by_details.emp_name, approved_by_details.emp_name
        dateFilter={"smp.CreatedDate"}
        header={[
          { value: "smp.Id", label: "ID" },
          { value: "smp.Machine_no", label: "Machine No" },
          { value: "smp.Part_number", label: "Part Number" },
          { value: "smp.Part_name", label: "Part Name" },
          { value: "smp.Raw_material_name", label: "RM Code" },
          { value: "Rm_name", label: "RM Name" },
          // { value: "Prepared_by_name", label: "Prepared By" },
          // { value: "Approved_by_name", label: "Approved By" },
        ]}
      />
    </div>
    </QaLayout>
  );
};

export default NewSmpList;
