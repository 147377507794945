import React, { useEffect, useState } from "react";
import { API_URL } from "../../../Utils/constants.js";
import axios from "axios";
import "../../../Styles/Part3.css";
import {
  SECRET_KEY,
  PROCESS_TRANSACTION_URL,
} from "../../../Utils/constants.js";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import IsDuplicate from "../../../Lib/IsDuplicate.js";
import CreateRecord from "../../../Lib/API/CreateRecord.js";

const initialState = {
  Material: "",
  DryTemp: "",
  DryTempTol: "",
  DMinHrs: "",
  DMaxHrs: "",
  Id: "",
  ActSts: "",
  Mat_stand: [],
};

function MaterialStandardCreate({ tab1 }) {
  const [isCreating, setIsCreating] = useState(false);
  const [MatStand, setMatStand] = useState(initialState);
useEffect(()=>{
setMatStand(initialState)
},[tab1])
  const handleCreate = async (e) => {
    e.preventDefault();
    if (
      !MatStand.Material ||
      !MatStand.DryTemp ||
      !MatStand.DryTempTol ||
      !MatStand.DMinHrs ||
      !MatStand.DMaxHrs ||
      !MatStand.ActSts
    ) {
      setIsCreating(true);
      alert(GeneralConstants.MANDATORY_ALERT);
      return;
    }
    const trimmedCode = MatStand.Material.trim();
    try {
      const isDuplicateCode = await IsDuplicate("materialstandard_details", [
        `Material="${trimmedCode}"`,
        `ActiveStatus = 'Active'`,
      ]);

      if (isDuplicateCode) {
        alert(`Material ${MatStand.Material} already exists.`);
        return;
      }
      
      const data = {
        data: [
          {
            Material: trimmedCode,
            Dry_temp: MatStand.DryTemp,
            Dry_temp_tol: MatStand.DryTempTol,
            Min_hrs: MatStand.DMinHrs,
            Max_hrs: MatStand.DMaxHrs,
            ActiveStatus: MatStand.ActSts,
          },
        ],
      };
      CreateRecord("material_standard_details", data.data).then((res) => {
        if (res.feedback[0].success === true) {
          alert(`Material ${GeneralConstants.SUBMISSION_SUCCESSFUL}`);
          setMatStand({
            ...MatStand,
            ...initialState,
          });
          setIsCreating(false);
        }
      });
    } catch (error) {
      console.log(error);
      alert(
        `${MatStand.Material} ${GeneralConstants.SUBMISSION_FAILED}`
      );
      window.location.reload();
    }
  };

  return (
    <div
      id="MaterialStandardCreate.formContainer"
      name="MaterialStandardCreate.formContainer"
    >
      <form className="form-MasterHead-Create">
        <table style={{ textAlign: "center", width: "100%" }} cellPadding="5">
          <tbody>
            <tr>
              <th
                id="ms.Title"
                name="ms.Title"
                colSpan="4"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">Material Standard</font>
              </th>
            </tr>
            <tr>
              <th>
                &nbsp;&nbsp; Material<span className="Req-i">*</span>
              </th>
              <td>
              {MatStand.Material === "" &&  isCreating && (
                  <span className="Req">Required *</span>
                )}
                <input
                  type="text"
                  id="Material"
                  name="Material"
                  value={MatStand.Material}
                  onChange={(e) =>
                    setMatStand({ ...MatStand, Material: e.target.value })
                  }
                  required
                />
              </td>
              <th>
                &nbsp;&nbsp; Dry Temperature<span className="Req-i">*</span>
              </th>
              <td>
              <div>
              {MatStand.DryTemp === "" && MatStand.DryTempTol === "" && isCreating && (
                  <span className="Req">Required *</span>
                )}</div>
                <input
                  style={{ marginLeft: "30px", width: "100px", float: "left" }}
                  type="number"
                  id="DryTemp"
                  name="DryTemp"
                  value={MatStand.DryTemp}
                  onChange={(e) =>
                    setMatStand({ ...MatStand, DryTemp: e.target.value })
                  }
                  required
                />
                <span
                  style={{
                    marginLeft: "10px",
                    float: "left",
                    fontSize: "25px",
                  }}
                >
                  ±
                </span>
                <input
                  style={{ marginLeft: "10px", width: "100px", float: "left" }}
                  type="number"
                  id="DryTempTol"
                  name="DryTempTol"
                  value={MatStand.DryTempTol}
                  onChange={(e) =>
                    setMatStand({ ...MatStand, DryTempTol: e.target.value })
                  }
                  required
                />
              </td>
            </tr>
            <tr>
              {/* <th>&nbsp;&nbsp; Dry Temperature Tolerance<span className="Req-i">*</span></th><td>
                                <input
                                    type="number"
                                    id="DryTemp"
                                    name="DryTemp"
                                    value={MatStand.DryTempTol}
                                    onChange={(e) => setMatStand({ ...MatStand, DryTempTol: e.target.value })}
                                    required
                                />
                            </td> */}
              <th>
              
                &nbsp;&nbsp; Dry Time<span className="Req-i">*</span>

              </th>
          
              <td style={{ alignItems: "center" }}>
              <div>
              {MatStand.DMaxHrs === "" && MatStand.DMinHrs === "" && isCreating && (
                  <span className="Req">Required *</span>
                )}</div>
                <span style={{ marginLeft: "10px", float: "left" }}>MIN </span>
                
                <input
                  style={{ marginLeft: "30px", width: "100px", float: "left" }}
                  type="number"
                  id="MinHrs"
                  name="MinHrs"
                  value={MatStand.DMinHrs}
                  onChange={(e) =>
                    setMatStand({ ...MatStand, DMinHrs: e.target.value })
                  }
                  required
                />
                <span style={{ marginLeft: "10px", float: "left" }}>MAX </span>
               
                <input
                  style={{ marginLeft: "230px", width: "100px" }}
                  type="number"
                  id="MaxHrs"
                  name="MaxHrs"
                  value={MatStand.DMaxHrs}
                  onChange={(e) =>
                    setMatStand({ ...MatStand, DMaxHrs: e.target.value })
                  }
                  required
                />
                 
              </td>

              <th>&nbsp;&nbsp;Active Status <span className="Req-i">*</span></th>
              <td>
              {MatStand.ActSts === "" && isCreating && (
                  <span className="Req">Required *</span>
                )}
                <select
                  id="ms.ActiveStatus"
                  name="ms.ActiveStatus"
                  value={MatStand.ActSts}
                  onChange={(e) =>
                    setMatStand({ ...MatStand, ActSts: e.target.value })
                  }
                  style={{ width: "36%", textAlign: "center" }}
                >
                  <option
                    id="ms.ActiveStatus.select"
                    name="ms.ActiveStatus.select"
                    value=""
                  >
                    -- Select --
                  </option>
                  <option
                    id="ms.ActiveStatus.active"
                    name="ms.ActiveStatus.active"
                    value="Active"
                  >
                    Active
                  </option>
                  <option
                    id="ms.ActiveStatus.inactive"
                    name="ms.ActiveStatus.inactive"
                    value="InActive"
                  >
                    InActive
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <td colSpan="4" style={{ border: "none" }}>
                <button
                  id="ms.submit"
                  name="ms.submit"
                  type="submit"
                  onClick={(e) => handleCreate(e)}
                >
                  Create
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
}

export default MaterialStandardCreate;
