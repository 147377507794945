import React,{useState} from "react";
import { Tab, Tabs } from "react-bootstrap";
import DailyShiftProcessCheckListCreate from "./DailyShiftProcessCheckListCreate";
import DailyShiftProcessCheckListView from "./DailyShiftProcessCheckListView";
import DailyShiftProcessCheckListReport from "./DailyShiftProcessCheckListReport";
function MaterialLoadingTabs() {
  const [viewTabClicked, setViewTabClicked] = useState(false);
  const [refreshReport, setRefreshReport] = useState(0);

  const handleTabClick = (tabKey) => {
    if (tabKey === "tab2") {
      setViewTabClicked(true);
    } else {
      setViewTabClicked(false);
    }
    if (tabKey === "tab3") {
     
      setRefreshReport((prevRefresh) => prevRefresh + 1);
    }
  };
  
  return (
    <div>
      <div>
        <Tabs
          defaultActiveKey="tab1"
          // transition={false}
          id="noanim-tab-example"
          className="mb-3"
          onSelect={handleTabClick}
        >
          <Tab name="mat.load.CreateTab" eventKey="tab1" title="Create">
            <div>
              <div className="set-layout">
                <DailyShiftProcessCheckListCreate />
              </div>
            </div>
          </Tab>
          <Tab name="mat.load.ViewTab" eventKey="tab2" title="View">
            <div>
              <div className="set-layout">
              {viewTabClicked && <DailyShiftProcessCheckListView />}
              </div>
            </div>
          </Tab>
          <Tab name="mat.load.ReportTab" eventKey="tab3" title="Report">
            <div>
              <div className="set-layout">
                <DailyShiftProcessCheckListReport  key={refreshReport}/>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default MaterialLoadingTabs;
