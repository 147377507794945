import React, { useState, useEffect } from "react";
import "./login.css";
import axios from "axios";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import {
  API_URL,
  PROCESS_TRANSACTION_URL,
  SECRET_KEY,
  SIGNIN_URL,
} from "../../Utils/constants";
import ReadRecord from "../../Lib/API/ReadRecord.js";

const Login = () => {
  const userdetails = {
    userName: "",
    password: "",
    otp: "",
    resetpassword: null,
    id: null,
    userId: null,
  };
  const states = {
    login: true,
    error: false,
    verify: true,
    needotp: true,
  };
  const [userDetails, setuserDetails] = useState(userdetails);
  const [States, setStates] = useState(states);
  let id = "";

  const handleClick = (event) => {
    event.preventDefault();
    ReadRecord(`SELECT emp_details.emp_code,login_details.emp_role AS role_name,login_details.emp_group AS group_name,emp_details.emp_name AS full_name
    FROM emp_details emp_details
    JOIN emp_login login_details ON emp_details.emp_code = login_details.emp_code where login_details.emp_code = '${userDetails.userName}' and login_details.emp_password = '${userDetails.password}' and emp_details.ActiveStatus='true';`)
      // axios
      //   .post(SIGNIN_URL, {
      //     username: userDetails.userName,
      //     password: base64_encode(userDetails.password),
      //   })
      .then((response) => {
        console.log(response.data);
        if (response.data[0].count == 1) {
          localStorage.setItem("role", response.data[0].rows[0]["role_name"]);
          localStorage.setItem("group", response.data[0].rows[0]["group_name"]);
          localStorage.setItem("name", response.data[0].rows[0]["full_name"]);
          localStorage.setItem("token", response.data[0].rows[0]["token"]);
          localStorage.setItem("id", response.data[0].rows[0]["id"]);
          localStorage.setItem("empId", response.data[0].rows[0]["emp_code"]);
          localStorage.setItem("login", true);
          window.location.reload();
        } else {
          setStates({ ...states, error: true });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleForgetpassword() {
    setStates({ ...States, login: false, needotp: true, verify: false });
    setuserDetails(userdetails);
  }

  function handleSignIn() {
    setStates({
      ...States,
      login: true,
      needotp: true,
      verify: false,
      error: false,
    });
    setuserDetails(userdetails);
  }

  function GetId(id) {
    axios
      .post(`http://${API_URL}/GenericResultBuilderService/buildResults`, {
        _keyword_: "emp_login_using_emp_code",
        code: id,
      })
      .then((response) => {
        setuserDetails({
          ...userDetails,
          id: response.data.data[0].Id,
          userId: id,
        });
      })
      .catch((error) => {
        console.log("no data");
      });
  }

  function Getotp() {
    axios
      .post(`http://${API_URL}/GenericResultBuilderService/buildResults`, {
        _keyword_: "emp_login_using_emp_code",
        code: userDetails.userId,
      })
      .then((response) => {
        response.data.data[0].Emp_otp === userDetails.otp
          ? setStates({ ...States, verify: true, error: false })
          : setStates({ ...States, error: true });
      })
      .catch((error) => {
        console.log("no data");
      });
  }
  const OtpSms = (otp) => {
    const data = {
      phoneNumber: "+91 8072262684",
      message: `Document sent for approval. OTP=${otp}`,
    };

    axios
      .post(`http://${API_URL}/AppContextService/sendShortMessage`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  function createOtp() {
    const otp = Math.floor(100000 + Math.random() * 900000);
    const data = {
      data: [
        {
          Id: userDetails.id,
          Emp_otp: otp + "",
        },
      ],
    };
    console.log("otp", otp);
    OtpSms(otp);
    data["_keyword_"] = "emp_login";
    data["secretkey"] = SECRET_KEY;
    axios
      .post(
        `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
        data
      )
      .then((response) => {
        response.data.feedback[0].count
          ? setStates({ ...States, needotp: false, error: false })
          : setStates({ ...States, error: true });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function verifyOtp() {
    //
    Getotp();
  }

  function submitpassword() {
    let otp = Math.floor(100000 + Math.random() * 900000);
    const data = {
      data: [
        {
          Id: userDetails.id,
          Emp_password: userDetails.password,
        },
      ],
    };
    data["_keyword_"] = "emp_login";
    data["secretkey"] = SECRET_KEY;
    axios
      .post(
        `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
        data
      )
      .then((response) => {
        if (response.data.feedback[0].count) {
          setStates(states);
          setuserDetails(userdetails);
          alert("Your Password has ReSet");
        } else {
          setStates({ ...States, error: true });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleResetPassword(e) {
    setuserDetails({
      ...userDetails,
      resetpassword: e.target.value,
    });
    if (userDetails.password === e.target.value) {
      setStates({ ...States, error: false });
    } else {
      setStates({ ...States, error: true });
    }
  }

  function handleuserid(e) {
    GetId(e.target.value);
  }

  return (
    <div className="background">
      <div className="Card">
        <img className="image" src="/login_background.svg" />
        <div className="branding">
          <img style={{ width: "100px", height: '50px' }} className="branding_logo" src="/Logo.png" />
          &nbsp;<h1>KASH Tech LLC </h1>
        </div>
        <div className="Login_card">
          {States.login ? (
            <div>
              {/* {localStorage.getItem("name") ? (
                <div className="welcomeMessage">
                  <h1>Welcome Back</h1>
                  <h3>{localStorage.getItem("name")}</h3>
                </div>  
              ) : ( */}

              <h1 className="Login_header">Login</h1>

              {/* )} */}
              {States.error && (
                <div className="error">Invalid username or password</div>
              )}
              <form className="form" onSubmit={handleClick}>
                <input
                  type="text"
                  value={userDetails.userName}
                  onChange={(e) =>
                    setuserDetails({ ...userDetails, userName: e.target.value })
                  }
                  placeholder="User Id"
                />

                <input
                  type="password"
                  value={userDetails.password}
                  onChange={(e) =>
                    setuserDetails({ ...userDetails, password: e.target.value })
                  }
                  placeholder="Password"
                />

                <button className="btn-submit" type="submit">
                  Submit
                </button>
              </form>
              {/* <div className="forget_password" onClick={handleForgetpassword}>
                Forget Password ?
              </div> */}
            </div>
          ) : (
            <div>
              <h1 style={{ marginBottom: "20px" }}>Password Reset</h1>

              {States.verify ? (
                <div className="form">
                  {States.error && (
                    <div className="error">Re-Enter password not Matching</div>
                  )}
                  <input
                    type="password"
                    value={userDetails.password}
                    onChange={(e) =>
                      setuserDetails({
                        ...userDetails,
                        password: e.target.value,
                      })
                    }
                    placeholder="New Password"
                  />
                  <input
                    type="password"
                    value={userDetails.resetpassword}
                    onChange={handleResetPassword}
                    placeholder="Re-Enter Password"
                  />
                  <button
                    type="submit"
                    className="btn-submit"
                    onClick={submitpassword}
                  >
                    Submit
                  </button>
                </div>
              ) : (
                <div>
                  {States.needotp ? (
                    <div className="form">
                      {States.error && (
                        <div className="error">Invalid User Id</div>
                      )}
                      <input
                        type="text"
                        value={userDetails.userId}
                        onChange={handleuserid}
                        placeholder="User ID"
                      />
                      <button
                        type="submit"
                        className="btn-submit"
                        onClick={createOtp}
                      >
                        {" "}
                        Get OTP
                      </button>
                    </div>
                  ) : (
                    <div className="form">
                      {States.error && (
                        <div className="error">Invalid One Time Password</div>
                      )}
                      <input
                        type="text"
                        value={userDetails.otp}
                        onChange={(e) =>
                          setuserDetails({
                            ...userDetails,
                            otp: e.target.value,
                          })
                        }
                        placeholder="OTP"
                      />
                      <button
                        type="submit"
                        className="btn-submit"
                        onClick={verifyOtp}
                      >
                        {" "}
                        Verify{" "}
                      </button>
                    </div>
                  )}
                </div>
              )}

              <div className="forget_password" onClick={handleSignIn}>
                Sign IN
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
