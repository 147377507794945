import React, { useState, useEffect ,useRef} from "react";

import {
  SECRET_KEY,
  CODEGEN_URL,
  PROCESS_TRANSACTION_URL,
} from "../../../Utils/constants.js";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import "../../../Styles/DailyShift.css";
import Unitechlogo from "../../../icons/Unitechlogo.jpeg";
import DatePicker from "../../../Lib/DatePicker.js";
import RadioOkNotOkayFetch from "../../../Components/RadioOkandNotOkFetcher.js";
import DropdownFetcher from "../../../Lib/DropdownFetcher.js";
import ShiftDropdownown from "../../../Components/ShiftDropdown.js";
import DropdownFilterSelect from "../../../Components/DropdownFilterSelectFetcher.js";
import { setMessage } from "../../../Lib/SetMessage.js";
import {
  CURRENT_Date,
  CURRENT_TIME,
  SHIFTMOLDINGPRODUCTION,
} from "../../../Utils/GeneralConstants";
import useScanDetection from "use-scan-detection";
import Select from "react-select";


function DailyShiftProcessCheckListCreate() {
  const determineShift = () => {
    const datetime = new Date();
    if (datetime.getHours() < 18 && datetime.getMinutes() < 59) {
      return "B12";
    } else {
      return "A12";
    }
  };
  const initialState = {
    Shift: SHIFTMOLDINGPRODUCTION,
    MachineName: "",
    MachineNo: "",
    TrackDate: new Date().toISOString().split("T")[0],
    MoldSafPresBlw1: "",
    MoldSafTon1: "",
    CycleTmeAlrm1: "",
    CushMonAlrm1: "",
    EjecPresBlw1: "",
    BarTempVari1: "",
    MoldClean1: "",
    CAndS1: "",
    SmpAndPmsVeri1: "",
    OperTrain1: "",
    LmpMonitor1: "",
    Operator1: "",
    MoldSetter1: "",
    Inchrge1: "",
    SeniorEngg1: "",
    Daily_shift_pro: [],
  };
  const scrollTop = useRef(null);
  const [isCreating, setIsCreating] = useState(false);
  const [DailyShiftProcess, setDailyShiftProcess] = useState(initialState);
  const [dropdown, setDropdown] = useState([]);
  const [EmployeeDropdown, setEmployeeDropdown] = useState([]);
  const [EmployeeMoldDropdown, setEmployeeMoldDropdown] = useState([]);
  const [InchargeEmployeeDropdown, setInchargeEmployeeDropdown] = useState([]);
  const [EnggEmployeeDropdown, setEnggEmployeeDropdown] = useState([]);

  const id = "DailyShiftProcess";
  const name = "DailyShiftProcess";

  // const CodeGen = async () => {
  //   const val = {
  //     tablename: "machine_part_details",
  //     PrimaryCode: "mc_part_code",
  //     PrimaryVal: DailyShiftProcess.MachineNo,
  //     FieldCode: "mc_part_name",
  //     FieldVal: DailyShiftProcess.MachineName,
  //   };
  //   console.log(val);
  
  //   const response = await getAPIRequest(CODEGEN_URL, "POST", val);
  //   const response1 = await response.json();
  //   console.log("response1", response1);
  
  //   return response1.success === "true";
  // };
  useEffect(() => {
    document.title = "Daily Shift Process"
  },[]);

  const handleCreate = async (e) => {
    e.preventDefault();
    
    const isSubmissionSuccessful = true;
    if (!DailyShiftProcess.MachineNo || !DailyShiftProcess.MachineName || !DailyShiftProcess.SeniorEngg1 || !DailyShiftProcess.Inchrge1 || !DailyShiftProcess.MoldSetter1 || !DailyShiftProcess.Operator1) {
      alert(GeneralConstants.MANDATORY_ALERT);
      setIsCreating(true);
      if (scrollTop.current) {
        scrollTop.current.scrollIntoView({ behavior: "smooth" });
      }
      return;
    }
    try {
      // const isDuplicate = await CodeGen();

      // if (isDuplicate) {
      //   alert(`${GeneralConstants.ALREADY_EXIST}`);
      //   setDailyShiftProcess({ ...DailyShiftProcess, ...initialState });
      //   setIsCreating(false);
      //   if (scrollTop.current) {
      //     scrollTop.current.scrollIntoView({ behavior: "smooth" });
      //   }
      //   return;
      // }
      const data = {
        data: [
          {
            Machine_name: DailyShiftProcess.MachineName,
            Machine_no: DailyShiftProcess.MachineNo,
            TrackDate: DailyShiftProcess.TrackDate,
            Mold_saf_pres_blw_1: DailyShiftProcess.MoldSafPresBlw1,
            Mold_saf_ton_1: DailyShiftProcess.MoldSafTon1,
            Cycle_tme_alrm_1: DailyShiftProcess.CycleTmeAlrm1,
            Cush_mon_alrm_1: DailyShiftProcess.CushMonAlrm1,
            Ejec_pres_blw_1: DailyShiftProcess.EjecPresBlw1,
            Bar_temp_vari_1: DailyShiftProcess.BarTempVari1,
            Mold_clean_1: DailyShiftProcess.MoldClean1,
            C_and_s_1: DailyShiftProcess.CAndS1,
            Smp_and_pms_veri_1: DailyShiftProcess.SmpAndPmsVeri1,
            Oper_train_1: DailyShiftProcess.OperTrain1,
            Lmp_monitor_1: DailyShiftProcess.LmpMonitor1,
            Shift: DailyShiftProcess.Shift,
            Operator_1: DailyShiftProcess.Operator1.value,
            Mold_setter_1: DailyShiftProcess.MoldSetter1.value,
            Inchrge_1: DailyShiftProcess.Inchrge1.value,
            Senior_engg_1: DailyShiftProcess.SeniorEngg1.value,
          },
        ],
      };
      data["_keyword_"] = "Daily_shift_process_checklist";
      data["secretkey"] = SECRET_KEY;

      const response = await getAPIRequest(
        PROCESS_TRANSACTION_URL,
        "POST",
        data
      );
      const response1 = await response.json();

      if (response1.success === "false") {
        alert(GeneralConstants.FAIL_TO_SAVE_MACHINE);
        window.location.reload();
      } else {
        const dailypro = [...DailyShiftProcess.Daily_shift_pro, response.data];
        setDailyShiftProcess({
          ...DailyShiftProcess,
          Daily_shift_pro: dailypro,
        });
      }
      if (isSubmissionSuccessful) {
        
        alert(`${GeneralConstants.SUBMISSION_SUCCESSFUL}`);
        setDailyShiftProcess({ ...DailyShiftProcess, ...initialState });
        setIsCreating(false);
        setMessage({
         
          NoteFrom: DailyShiftProcess.Operator1.value,
          NoteTo: DailyShiftProcess.Inchrge1.value,
          NoteMessage: 
          `${DailyShiftProcess.Operator1.value}-${DailyShiftProcess.Operator1.label} has submitted the Daily Shift Process Check List on date ${DailyShiftProcess.TrackDate} shift ${DailyShiftProcess.Shift}`,
          NoteRecordId: response1.ROWID,
          TableName: "DailyShiftProcessCheckList",
  
        });
        setMessage({
          NoteFrom: DailyShiftProcess.Operator1.value,
          NoteTo: DailyShiftProcess.SeniorEngg1.value,
          NoteMessage: 
          `${DailyShiftProcess.Operator1.value}-${DailyShiftProcess.Operator1.label} has submitted the Daily Shift Process Check List on date ${DailyShiftProcess.TrackDate} shift ${DailyShiftProcess.Shift}`,
          NoteRecordId: response1.ROWID,
          TableName: "DailyShiftProcessCheckList",
  
        });
        setMessage({
          NoteFrom: DailyShiftProcess.Operator1.value,
          NoteTo: DailyShiftProcess.MoldSetter1.value,
          NoteMessage: 
          `${DailyShiftProcess.Operator1.value}-${DailyShiftProcess.Operator1.label} has submitted the Daily Shift Process Check List on date ${DailyShiftProcess.TrackDate} shift ${DailyShiftProcess.Shift}`,
          NoteRecordId: response1.ROWID,
          TableName: "DailyShiftProcessCheckList",
  
        });
        
        if (scrollTop.current) {
          scrollTop.current.scrollIntoView({ behavior: "smooth" });
        }
      }
      
    } catch (error) {
      console.log(error);
      alert(
        `${DailyShiftProcess.MachineName} ${GeneralConstants.SUBMISSION_FAILED}`
      );

      window.location.reload();
    }
    
  };

  const handleDateChange = (newDate) => {
    setDailyShiftProcess({ ...DailyShiftProcess, TrackDate: newDate });
  };
  const mcNoOptions = dropdown.map((i) => ({
    value: i.mc_code,
    label: i.mc_name,
  }));

  const handleDropdownChange = (selectedOption) => {
    console.log(selectedOption.value);
    const selectedMachine = dropdown.find(
      (option) => option.mc_code === selectedOption.value
    );

    if (selectedMachine) {
      setDailyShiftProcess({
        ...DailyShiftProcess,
        MachineName: selectedMachine.mc_name,
        MachineNo: selectedMachine.mc_code,
        
      });
    }
  };

  // useEffect(() => {
  //   setDailyShiftProcess({
  //     ...DailyShiftProcess,
  //     // TrackDate: new Date().toISOString().split("T")[0],
  //       Shift: determineShift(),
  //   });
  //   console.log("Machine no");

  // },[]);

  

  // barcode scannning to get input
  useScanDetection({
    onComplete: (code) => {
      code = code.replaceAll("Shift", "");
      // let isnumber = Number(code).toString() !== "NaN";
    let current_element = document.activeElement.id;

      if (code.includes("EMP")) {
        // setEmployeeName(code);
      if(current_element === "" || current_element === "react-select-2-input"){

        DailyShiftProcess.MachineName&&handleOperator({value : code})
      }else{
        alert("Invalid Scan");
        return(false);
      }
      } else if (code.includes("IMM")){
      if(current_element === "" || current_element === "react-select-3-input"){

        handleDropdownChange({ value: code });
      }else{
        alert("Invalid Scan");
        return(false);
      }
       
        } else {
          alert(
            "Please Check for the Page or check for the proper object for Scanning"
          );
        }
      
      
    },
    minLength: 3,
  });
  // auto populate employee names
  // function setEmployeeName(code) {
  //   // add the DB table for the user to the data field
  //   // let values = data.find(key=>key["Material Name"]===code);
  //   // setInchrge("Samvictor");
  // }
  const empOperatorOptions = EmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const empMoldSetterOptions = EmployeeMoldDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const empInchrgeOptions = InchargeEmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const empEnggOptions = EnggEmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const handleOperator = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      
      setDailyShiftProcess({
        ...DailyShiftProcess,
        Operator1: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      })
    }
  };
  const handleMoldSetter = (selectedOption) => {
    const selected = EmployeeMoldDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      
      setDailyShiftProcess({
        ...DailyShiftProcess,
        MoldSetter1: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      })
    }
  };
  const handleEnggSetter = (selectedOption) => {
    const selected = EnggEmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      
      setDailyShiftProcess({
        ...DailyShiftProcess,
        SeniorEngg1: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      })
    }
  };
  const handleInchrge = (selectedOption) => {
    const selected = InchargeEmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      
      setDailyShiftProcess({
        ...DailyShiftProcess,
        Inchrge1: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      })
    }
  };

  return (
    <div>
      <form
        id="DailyShiftProcessCheckList-formContainer-Create"
        name="DailyShiftProcessCheckList-formContainer-Create"
        className="form-DailyShiftProcessCheckList-Create"
      >
        <div ref={scrollTop}>
        <table style={{ textAlign: "center", width: "100%" }}>
          <tbody>
          <tr>
                {/* <th style={{ textAlign: "center", width: "15%" }}>
                  <img src={Unitechlogo} style={{ width: "70px" }}></img>
                </th> */}
                <th
                  id={`${id}-Title`}
                  name={`${name}-Title`}
                  colSpan="7"
                  style={{
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    padding: "10px 0px",
                  }}
                >
                  {/* <font style={{ fontSize: "24px" }}>
                    UNITECH PLASTO COMPONENTS (P) LTD
                  </font>
                  <br></br> */}
                  <font color="#8B0000">Daily Shift Wise Process Check List</font>
                </th>
              </tr>
            <tr>
              <td className="topic-head">
                M/C Name:<span style={{ color: "red" }}>*</span>
              </td>
              <td>
              
                <DropdownFetcher keyword="mac_dtl_dropdown" setValue={setDropdown} />
                <DropdownFilterSelect
                  value={DailyShiftProcess.MachineName}
                  onChange={handleDropdownChange}
                  options={mcNoOptions}
                  placeholder="Select Machine No"
                />
                {DailyShiftProcess.MachineName === '' && isCreating && <span className="Req">Required *</span>}
              </td>

              <td className="topic-head">
                M/C No:<span style={{ color: "red" }}>*</span>
              </td>
              <td>
              {DailyShiftProcess.MachineNo === '' && isCreating && <span className="Req">Required *</span>}
                <input
                  id={`${id}-MachineNo`}
                  name={`${name}-MachineNo`}
                  type="text"
                  value={DailyShiftProcess.MachineNo}
                  onChange={(e) =>
                    setDailyShiftProcess({
                      ...DailyShiftProcess,
                      MachineNo: e.target.value,
                    })
                  }
                  required
                  disabled
                />
              </td>

              <td className="topic-head">Date</td>
              <td>
                <DatePicker
                  value={DailyShiftProcess.TrackDate}
                  onChange={handleDateChange}
                  dis={true}
                />
              </td>
            </tr>
            <tr>
              <th>S. No.,</th>
              <th>Parameters</th>
              <th style={{ whiteSpace: "nowrap" }}>Check Area</th>
              <th style={{ whiteSpace: "nowrap" }}>How to Check</th>
              <th style={{ whiteSpace: "nowrap", padding: "10px 0px" }}>
                Reaction if not ok
              </th>
              <th>Ok/Not Ok</th>
            </tr>
            <tr>
              <td className="topic-head">D1</td>
              <td className="topic-head">
                Mold Safety Pressure Below 20 BAR & Time in SEC 0.05 SEC
              </td>
              <td className="topic-head">Clamping Page</td>
              <td className="topic-head">Visual</td>
              <td className="topic-head">Informed to incharge</td>
              <td>
                {/* <OkNotOkayFetchvalue={DailyShiftProcess.MoldSafPresBlw1} setValue={setMoldSafPresBlw1} /> */}
                <RadioOkNotOkayFetch 

                disabled={DailyShiftProcess.MachineNo=== ""}
                  inputName="MoldSafPresBlw1"
                  selectedValue={DailyShiftProcess.MoldSafPresBlw1}
                  onChange={(e) =>
                    setDailyShiftProcess({
                      ...DailyShiftProcess,
                      MoldSafPresBlw1: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td className="topic-head">D2</td>
              <td className="topic-head">
                Mold Safety Tonnage Not Exit Position 2MM
              </td>
              <td className="topic-head">Clamping Page</td>
              <td className="topic-head">Visual</td>
              <td className="topic-head">Informed to incharge</td>
              <td>
                <RadioOkNotOkayFetch 
disabled={DailyShiftProcess.MachineNo=== ""}
                  inputName="MoldSafTon1"
                  selectedValue={DailyShiftProcess.MoldSafTon1}
                  onChange={(e) =>
                    setDailyShiftProcess({
                      ...DailyShiftProcess,
                      MoldSafTon1: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td className="topic-head">D3</td>
              <td className="topic-head">Cycle Time Alarm ±5SEC</td>
              <td className="topic-head">Proc mon/Feat time</td>
              <td className="topic-head">Visual</td>
              <td className="topic-head">Informed to incharge</td>
              <td>
                <RadioOkNotOkayFetch 
disabled={DailyShiftProcess.MachineNo=== ""}
                  inputName="CycleTmeAlrm1"
                  selectedValue={DailyShiftProcess.CycleTmeAlrm1}
                  onChange={(e) =>
                    setDailyShiftProcess({
                      ...DailyShiftProcess,
                      CycleTmeAlrm1: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td className="topic-head">D4</td>
              <td className="topic-head">Cushion Monitoring Alarm ±0.5MM</td>
              <td className="topic-head">Proc mon</td>
              <td className="topic-head">Visual</td>
              <td className="topic-head">Informed to incharge</td>
              <td>
                <RadioOkNotOkayFetch 
disabled={DailyShiftProcess.MachineNo=== ""}
                  inputName="CushMonAlrm1"
                  selectedValue={DailyShiftProcess.CushMonAlrm1}
                  onChange={(e) =>
                    setDailyShiftProcess({
                      ...DailyShiftProcess,
                      CushMonAlrm1: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td className="topic-head">D5</td>
              <td className="topic-head">Ejection Pressure Below (20 BAR)</td>
              <td className="topic-head">Ejection Page</td>
              <td className="topic-head">Visual</td>
              <td className="topic-head">Informed to incharge</td>
              <td>
                <RadioOkNotOkayFetch 
disabled={DailyShiftProcess.MachineNo=== ""}
                  inputName="EjecPresBlw1"
                  selectedValue={DailyShiftProcess.EjecPresBlw1}
                  onChange={(e) =>
                    setDailyShiftProcess({
                      ...DailyShiftProcess,
                      EjecPresBlw1: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td className="topic-head">D6</td>
              <td className="topic-head">Barrel Temperature Variation ±10°C</td>
              <td className="topic-head">Temp Page</td>
              <td className="topic-head">Visual</td>
              <td className="topic-head">Informed to incharge</td>
              <td>
                <RadioOkNotOkayFetch 
disabled={DailyShiftProcess.MachineNo=== ""}
                  inputName="BarTempVari1"
                  selectedValue={DailyShiftProcess.BarTempVari1}
                  onChange={(e) =>
                    setDailyShiftProcess({
                      ...DailyShiftProcess,
                      BarTempVari1: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td className="topic-head">D7</td>
              <td className="topic-head">Mold Cleanning (MATERIAL DUST)</td>
              <td className="topic-head">Molding Side</td>
              <td className="topic-head">Visual</td>
              <td className="topic-head">Informed to incharge</td>
              <td>
                <RadioOkNotOkayFetch 
disabled={DailyShiftProcess.MachineNo=== ""}
                  inputName="MoldClean1"
                  selectedValue={DailyShiftProcess.MoldClean1}
                  onChange={(e) =>
                    setDailyShiftProcess({
                      ...DailyShiftProcess,
                      MoldClean1: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td className="topic-head">D8</td>
              <td className="topic-head">3C 5S</td>
              <td className="topic-head">All Area</td>
              <td className="topic-head">Visual</td>
              <td className="topic-head">Informed to incharge</td>
              <td>
                <RadioOkNotOkayFetch 
disabled={DailyShiftProcess.MachineNo=== ""}
                  inputName="CAndS1"
                  selectedValue={DailyShiftProcess.CAndS1}
                  onChange={(e) =>
                    setDailyShiftProcess({
                      ...DailyShiftProcess,
                      CAndS1: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td className="topic-head">D9</td>
              <td className="topic-head">SMP & PMS Verification</td>
              <td className="topic-head">SMP & PMS Sheet</td>
              <td className="topic-head">Visual</td>
              <td className="topic-head">Informed to incharge</td>
              <td>
                <RadioOkNotOkayFetch 
disabled={DailyShiftProcess.MachineNo=== ""}
                  inputName="SmpAndPmsVeri1"
                  selectedValue={DailyShiftProcess.SmpAndPmsVeri1}
                  onChange={(e) =>
                    setDailyShiftProcess({
                      ...DailyShiftProcess,
                      SmpAndPmsVeri1: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            {/* <tr>
              <td className="topic-head">D10</td>
              <td className="topic-head">Operator1 training</td>
              <td className="topic-head">Operator1 Skill</td>
              <td className="topic-head">Visual</td>
              <td className="topic-head">Informed to incharge</td>
              <td>
                <RadioOkNotOkayFetch 
disabled={DailyShiftProcess.MachineNo=== ""}
                  inputName="OperTrain1"
                  selectedValue={DailyShiftProcess.OperTrain1}
                  onChange={(e) =>
                    setDailyShiftProcess({
                      ...DailyShiftProcess,
                      OperTrain1: e.target.value,
                    })
                  }
                />
              </td>
            </tr> */}
            <tr>
              <td className="topic-head">D10</td>
              <td className="topic-head">LMP Monitoring</td>
              <td className="topic-head">Molding Side</td>
              <td className="topic-head">Visual</td>
              <td className="topic-head">Informed to incharge</td>
              <td>
                <RadioOkNotOkayFetch 
disabled={DailyShiftProcess.MachineNo=== ""}
                  inputName="LmpMonitor1"
                  selectedValue={DailyShiftProcess.LmpMonitor1}
                  onChange={(e) =>
                    setDailyShiftProcess({
                      ...DailyShiftProcess,
                      LmpMonitor1: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td colSpan="4" rowSpan={4} className="topic-head">
                Shift
                <ShiftDropdownown
                disabled1={true}
                  className="Shift-dropdown"
                  id={id}
                  name={name}
                  value={DailyShiftProcess.Shift}
                  onChange={(e) =>
                    setDailyShiftProcess({
                      ...DailyShiftProcess,
                      Shift: e.target.value,
                    })
                  }
                  
                />
              </td>

              <td className="topic-head">Operator<span style={{ color: "red" }}>*</span></td>
              <td>
                {/* <input
                  id={`${id}-Operator1`}
                  name={`${name}-Operator1`}
                  type="text"
                  value={DailyShiftProcess.Operator1}
                  onChange={(e) =>
                    setDailyShiftProcess({
                      ...DailyShiftProcess,
                      Operator1: e.target.value,
                    })
                  }
                /> */}
                <DropdownFetcher keyword="emp_details_operator" setValue={setEmployeeDropdown} />
              <Select
                id={`${id}-Operator1`}
                name={`${name}-Operator1`}
                value={DailyShiftProcess.Operator1}
                onChange={handleOperator}
                  options={empOperatorOptions}
                  
                  placeholder="Select Operator"
                  isDisabled={DailyShiftProcess.MachineNo === ""}
                  
                />
                {DailyShiftProcess.Operator1 === '' && isCreating && <span className="Req">Required *</span>}

              </td>
            </tr>
            <tr>
              <td className="topic-head">Mold Setter<span style={{ color: "red" }}>*</span></td>
              <td>
                {/* <input
                  id={`${id}-MoldSetter1`}
                  name={`${name}-MoldSetter1`}
                  type="text"
                  value={DailyShiftProcess.MoldSetter1}
                  onChange={(e) =>
                    setDailyShiftProcess({
                      ...DailyShiftProcess,
                      MoldSetter1: e.target.value,
                    })
                  }
                /> */}
                <DropdownFetcher keyword="emp_details_moldsetter" setValue={setEmployeeMoldDropdown} />
              <Select
                id={`${id}-MoldSetter1`}
                name={`${name}-MoldSetter1`}
                value={DailyShiftProcess.MoldSetter1}
                onChange={handleMoldSetter}
                  options={empMoldSetterOptions}
                  
                  placeholder="Select Mould Setter"
                  isDisabled={DailyShiftProcess.MachineNo === ""}

                />
                                {DailyShiftProcess.MoldSetter1 === '' && isCreating && <span className="Req">Required *</span>}

              </td>
            </tr>

            <tr>
              <td className="topic-head">Incharge Name<span style={{ color: "red" }}>*</span></td>
              <td>
                {/* <input
                  id={`${id}-Inchrge1`}
                  name={`${name}-Inchrge1`}
                  type="text"
                  value={DailyShiftProcess.Inchrge1}
                  onChange={(e) =>
                    setDailyShiftProcess({
                      ...DailyShiftProcess,
                      Inchrge1: e.target.value,
                    })
                  }
                /> */}
                <DropdownFetcher keyword="emp_details_incharge" setValue={setInchargeEmployeeDropdown} />
              <Select
                id={`${id}-Inchrge1`}
                name={`${name}-Inchrge1`}
                value={DailyShiftProcess.Inchrge1}
                onChange={handleInchrge}
                  options={empInchrgeOptions}
                  
                  placeholder="Select Incharge"
                  isDisabled={DailyShiftProcess.MachineNo === ""}

                />
                                {DailyShiftProcess.Inchrge1 === '' && isCreating && <span className="Req">Required *</span>}

              </td>
            </tr>
            <tr>
              <td className="topic-head">Senior Engg<span style={{ color: "red" }}>*</span></td>
              <td>

                {/* <input
                  id={`${id}-SeniorEngg1`}
                  name={`${name}-SeniorEngg1`}
                  type="text"
                  value={DailyShiftProcess.SeniorEngg1}
                  onChange={(e) =>
                    setDailyShiftProcess({
                      ...DailyShiftProcess,
                      SeniorEngg1: e.target.value,
                    })
                  }
                /> */}
                <DropdownFetcher keyword="emp_details_engg" setValue={setEnggEmployeeDropdown} />
              <Select
                id={`${id}-SeniorEngg1`}
                name={`${name}-SeniorEngg1`}
                value={DailyShiftProcess.SeniorEngg1}
                onChange={handleEnggSetter}
                  options={empEnggOptions}
                  
                  placeholder="Select Engg"
                  isDisabled={DailyShiftProcess.MachineNo === ""}

                />
                                {DailyShiftProcess.SeniorEngg1 === '' && isCreating && <span className="Req">Required *</span>}

              </td>
            </tr>

            <tr>
              <td colSpan="6" align="center" style={{ border: "none" }}>
                <button
                  type="submit"
                  id={`${id}-submit`}
                  name={`${name}-submit`}
                  onClick={(e) => handleCreate(e)}
                  disabled={DailyShiftProcess.MachineName === "" || DailyShiftProcess.Operator1 === "" || DailyShiftProcess.MoldSetter1 === "" || DailyShiftProcess.Inchrge1 === ""}
                  hidden={DailyShiftProcess.MachineName === "" || DailyShiftProcess.Operator1 === "" || DailyShiftProcess.MoldSetter1 === "" || DailyShiftProcess.Inchrge1 === ""}
                >
                  Submit
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
      </form>
    </div>
  );
}

export default DailyShiftProcessCheckListCreate;
