import React, { useEffect, useState, useRef } from "react";
import ReadRecord from "../../Lib/API/ReadRecord";
import Lable from "../Fields/Lable";
import Field from "../Fields/Field";
import { useNavigate } from "react-router-dom";
import "./Listview.css";
import * as XLSX from 'xlsx';
 import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
import html2pdf from 'html2pdf.js';
import { Dropdown } from "react-bootstrap";
import Logo from '../../icons/Logo1.png';
const ReportView = ({
  Title,
  header,
  filter,
  quary,
  table,
  standard,
  dateFilter,
  DefaultDateFilter,
}) => {
  const limit = 50;
  const [records, setrecords] = useState([{}]);
  const [Counts, setCounts] = useState(1);
  const [showTable, setShowTable] = useState(false);
  const [Quary, setQuary] = useState(CreateQuary());
  const [Filters, setFilters] = useState(() => {
    let fill = filter.map
    ((item) => {
      return { [item]: "" };
    });
    fill.push({ start: "", end: "" });
    return fill;
  });
  const [FilterQuary, setFilterQuary] = useState("");
  const [Loading, setLoading] = useState(false);
  const [exc, setexc] = useState(false);
  const [Selected, setSelected] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (Filters[Filters.length - 1].start && Filters[Filters.length - 1].end) {
      let filter = CreateFilterQuary();
      runQuary(Quary.replace("Quary%", filter).replace("limit%", 0));
      getcount("where " + standard + " " + filter);
    }
  }, [Filters]);
  
  useEffect(() => {
    console.log("records review", records);
  }, [records]);


  function CreateFilterQuary() {
    let quary = Quary;
    let alter = "";
    Filters.map((item, index) => {
      if (index != Filters.length - 1) {
        let key = Object.keys(item)[0];
        if (item[key] === "") {
          alter += "and " + key + " like '%' ";
        } else {
          alter += "and " + key + " like '%" + item[key] + "%' ";
        }
      }
    });

      if (dateFilter) {      
      let date = Filters[Filters.length - 1];
      if(DefaultDateFilter?.start){
        date.start = DefaultDateFilter?.start
      }
      if(DefaultDateFilter?.end){
        date.start = DefaultDateFilter?.end
      }
      if (date.start === "") {
        if (date.end === "") {
        }
        else if (date.start === date.end) {
          
          const startDateTime = date.start + " 00:00:00";
          const endDateTime = date.end + " 23:59:59";
          alter += `and ${dateFilter} BETWEEN '${startDateTime}' and '${endDateTime}' `;
        } else {
          alter += "and " + dateFilter + " <= '" + date.end + "' ";
        }
        } else {
        if (date.end === "") {
          alter += "and " + dateFilter + " >= '" + date.start + "' ";
        }  else if (date.start === date.end) {
          
          const startDateTime = date.start + " 00:00:00";
          const endDateTime = date.end + " 23:59:59";
          alter += `and ${dateFilter} BETWEEN '${startDateTime}' and '${endDateTime}' `;
        }else {
          alter +=
            "and " +
            dateFilter +
            " BETWEEN '" +
            date.start +
            "' and '" +
            date.end +
            "' ";
            }
            }
            }
            let filterQuary = "All";
            Filters.map((item, index) => {
            if (index != Filters.length - 1) {
            let key = Object.keys(item)[0];
            if (item[key] !== "") {
            filterQuary +=
            " > " +
            header
              .map((item) => (item.value === key ? item.label : false))
              .filter((item) => item != false)[0] +
            "=" +
            item[key];
            }
            }
            });
            setFilterQuary(filterQuary);
            return alter;
            }

            function runQuary(Quary) {
            setLoading((prev) => {
            prev = true;
            return prev;
            });
            ReadRecord(Quary).then((res) => {
            setrecords(res.data[0].count === "0" ? [] : res.data[0].rows);
            setLoading((prev) => {
            prev = false;
            return prev;
            });
            });
            }
           function getcount(quary) {
           ReadRecord("Select count(Id) count from " + table + " " + quary).then(
           (res) => {
           setCounts(
           Math.ceil(
          (res.data[0].rows[0] ? res.data[0].rows[0].count : 1) / limit
          )
          );
          }
          );
          }

          function CreateQuary() {
          let Quary = "";
          if (quary === undefined) {
          Quary += "SELECT ";
          header.map((item) => {
          Quary += item.value + ",";
          });
          Quary = Quary.substring(0, Quary.length - 1);
          Quary += " from " + table + " where ActiveStatus = 1 Quary%";
          } else {
          if (quary.includes("group by ")) {
          Quary = quary;
          Quary = Quary.split("group by ");
          Quary[1] = "group by " + Quary[1];
          Quary = Quary[0] + "where " + standard + " Quary% " + Quary[1];
          } else {
          Quary = quary + " where " + standard + " Quary% ";
          }
          }
          return Quary;   
          }
      
          const exportToExcel = () => {
            setShowTable(true)
            setexc(true);
  
            setTimeout(() => {
            if (records.length === 0) {
              alert("No records found. Cannot export to Excel.");
              setFilters(() => {
                let fill = filter.map((item) => {
                  return { [item]: "" };
                });
                fill.push({ start: "", end: "" });
                return fill;
              });
            
              setFilterQuary("All");
              getcount("where " + standard + " " + "");
              setrecords("")
              setexc(false)
              setTimeout(() => {
                setShowTable(false)
              }, 1000);
              return;
              
            }
 
            const sheetData = document.getElementById("excelTable");
            const wsData = XLSX.utils.table_to_sheet(sheetData);
            wsData['!rows'] = [{ hpx: 50, }];          
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, wsData, "Sheet1");
            XLSX.writeFile(wb, "SMP.xlsx");
            setFilters(() => {
              let fill = filter.map((item) => {
                return { [item]: "" };
              });
              fill.push({ start: "", end: "" });
              return fill;
            });
          
            setFilterQuary("All");
            getcount("where " + standard + " " + "");
            setrecords("")
            setexc(false)
            setShowTable(false)
          },1000);
        }
          const contentRef = useRef(null);

          const generatePDF = () => {
          //  setShowTable(true)
            if (records.length === 0) {
              alert("No records found. Cannot export to PDF.");
              setFilters(() => {
                let fill = filter.map((item) => {
                  return { [item]: "" };
                });
                fill.push({ start: "", end: "" });
                return fill;
                  });
                setFilterQuary("All");
                getcount("where " + standard + " " + "");
                setrecords("");
                setShowTable(false)
                 return;
                 }
          
            const opt = {
              margin: 0.3,
              filename: 'SMP.pdf',
              image: { type: 'jpeg', quality: 0.98 },
              html2canvas: { scale: 1 },
              jsPDF: { unit: 'in', format: 'A4', orientation: 'landscape' }
            };
          
            html2pdf().from(contentRef.current).set(opt).save();
            setFilters(() => {
              let fill = filter.map((item) => {
                return { [item]: "" };
              });
              fill.push({ start: "", end: "" });
              return fill;
            });
          
            setFilterQuary("All");
            getcount("where " + standard + " " + "");
            setrecords("");
          };
          
          
    return (
    <div className="ReportView">       
      <table style={{ textAlign: "center", width: "100%" }} cellPadding="1"  >
        <thead>
          <tr>
            <th colSpan={5}>
              <Lable type={"Head"} value={Title} />
            </th>
          </tr>
          <tr>
            <td colSpan={5} style={{ textAlign: "center" }}>
              <div
                style={
                  dateFilter
                    ? {
                        display: "grid",
                        gridTemplateColumns: "35% 25% 25% 15%",
                        alignItems: "center",
                      }
                    : {
                        display: "grid",
                        gridTemplateColumns: "40% 40% 20%",
                        alignItems: "center",
                      }
                }
              >
                {dateFilter && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      borderRight: "1px solid",
                    }}
                  >
                    <label style={{ fontSize: "16px", paddingRight: "10px" }}>
                      Start
                    </label>
                    <input
                      type="date"
                      placeholder="Filter by Start Date"
                      value={Filters[Filters.length - 1].start}
                      onChange={(event) => {
                        const currentEndDate = Filters[Filters.length - 1].end;

                        if (
                          currentEndDate &&
                          event.target.value > currentEndDate
                        ) {
                          alert("Start date cannot be after end date");
                          return;
                        }
                        setFilters(() => {
                          let prev = [...Filters];
                          prev[Filters.length - 1].start = event.target.value;
                          return prev;
                        });
                      }}
                      style={{ marginRight: "10px", width: "120px" }}
                    />

                    <label
                      style={{
                        fontSize: "16px",
                        paddingRight: "10px",
                        paddingLeft: "30px",
                      }}
                    >
                      End
                    </label>
                    <input
                      type="date"
                      style={{ marginRight: "10px", width: "120px" }}
                      placeholder="Filter by End Date"
                      value={Filters[Filters.length - 1].end}
                      onChange={(event) => {
                        if (
                          event.target.value < Filters[Filters.length - 1].start
                        ) {
                          alert("End date cannot be before start date");
                          return;
                        }
                        setFilters(() => { 
                          let prev = [...Filters];
                          prev[Filters.length - 1].end = event.target.value;
                          return prev;
                        });
                      }}
                    />
                  </div>
                )}
                 <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                    borderRight: "1px solid",
                  }}
                >
                  <Field
                    name={Selected}
                    options={{
                      type: "dropdown",
                      values: filter.map(
                        (item) =>
                          header.filter((head) => head.value === item)[0]
                      ),
                    }}
                    value={filter}
                    OnChange={(e) => {
                      setSelected(
                        Filters.map((item, index) => {
                          if (Object.keys(item).includes(e.target.value))
                            return index;
                        }).filter((item) => item != undefined)[0]
                      );
                      setQuary(CreateQuary());
                    }}
                  />
                  <h3> = </h3>
                  <Field
                    name={filter[Selected]}
                    options={{ type: "text" }}
                    value={Filters[Selected]}
                    OnChange={(e) => {
                      setFilters(() => {
                        let prev = [...Filters];
                        prev[Selected][filter[Selected]] = e.target.value;
                        return prev;
                      });
                      // HandleFilter(e.target.value);
                    }}
                  />
 
                </div>
             
                {Filters[Filters.length - 1].start && Filters[Filters.length - 1].end ? (
                
                    <div style={{ width: '100%',marginLeft:"20px",borderRadius:"20px"}} >
                     <Dropdown style={{ width: '50%', al: "center" }} >
                     <Dropdown.Toggle style={{color:"black",borderBottom: "1px solid #5abfbf" }}>
                       Download ⬇️
                     </Dropdown.Toggle>
               
                     <Dropdown.Menu>
                       <Dropdown.Item>
                         <button style={{color: "black", borderBottom: "1px solid #5abfbf" }} onClick={generatePDF}>
                           PDF ⬇️
                         </button>
                       </Dropdown.Item>
                       <Dropdown.Item>
                         
                       <button style={{ color: "black", borderBottom: "1px solid #5abfbf" }} onClick={exportToExcel}>
                         Excel⬇️
                   </button>
                       </Dropdown.Item>
                     </Dropdown.Menu>
                   </Dropdown> 
                     </div> 
                     ) : ( 
                    <div style={{ fontSize: "16px", marginLeft: "20%", padding: "5px" }}>
                    <Field
                     label={"Download"}
                     options={{ type: "Button" }}
                      onClick={() => alert("Choose both start and end dates")}
                     />
                  </div>
                )}
             </div>
             </td>     
             </tr>   
             </thead> 
             </table>
             <table>
              <tr>
                <td style={{textAlign:'center',fontSize:'large',fontWeight:'bold',color:'red'}}>
                {records.length == 1 ? 'Please use filter to load Records': `${records.length} Records Found` }
                </td>
              </tr>
              
             </table>
             <div style={{marginTop:"200%",display: showTable ? "block" : "none"}}>
              <table  ref={contentRef} id="excelTable"> 
               <thead> 
                <tr>
                {exc ? (
               <td colSpan={7}></td>
                       ) : (
                     null 
                        )}
                          {!exc && (
                        <td style={{ height:"10%",width:"10%" }}>
                         <img style={{ width: "100%"}} src={Logo} alt="Logo" />
                         </td>)}

                 <td colSpan="20" style={{fontWeight:"bold", whiteSpace: "nowrap",textAlign:"center",fontSize:"20px" }}>
            
                  <font>
                   REPORT - STANDARD MOULDING PARAMETER
                  </font>
                 </td>
                 </tr>
                
                 <tr>
              <td colSpan={2}style={{ "text-align": "center",fontWeight:"bold", whiteSpace: "nowrap" }}>Track Date</td>
              <td colSpan={2}style={{ "text-align": "center",fontWeight:"bold", whiteSpace: "nowrap" }}>Machine No</td>
              <td colSpan={2}style={{ "text-align": "center",fontWeight:"bold", whiteSpace: "nowrap" }}>Part Number</td>
              <td colSpan={3}style={{ "text-align": "center",fontWeight:"bold", whiteSpace: "nowrap" }}>Part Name</td>
              <td colSpan={2}style={{ "text-align": "center",fontWeight:"bold", whiteSpace: "nowrap" }}>RM Code</td>
              <td colSpan={3}style={{ "text-align": "center",fontWeight:"bold", whiteSpace: "nowrap" }}>RM Name</td>
              <td colSpan={3}style={{ "text-align": "center",fontWeight:"bold", whiteSpace: "nowrap" }}>Prepared By</td>
              <td colSpan={3}style={{ "text-align": "center",fontWeight:"bold", whiteSpace: "nowrap" }}>Approved By</td>
            </tr>
            {records.length > 0 ? (
            records.map((record) => (
                  <tr key={record.Id}>
                     <td colSpan={2}> {record.Entry_date}</td>
                     <td colSpan={2}> {record.Machine_no}</td>
                     <td colSpan={2}> {record.Part_number}</td>     
                     <td colSpan={3}> {record.Part_name}</td>
                     <td colSpan={2}> {record.Raw_material_name}</td>
                     <td colSpan={3}> {record.Rm_name}</td>
                     <td colSpan={3}>{record.Prepared_by ? `${record.Prepared_by}/` : ""}{record.Prepared_by_name}</td>
                     <td colSpan={3}>{record.Approved_by ? `${record.Approved_by}/` : ""}{record.Approved_by_name}</td>
                     </tr>
                                   ))
                                   ) : (
                             
                                     <tr>
                                       <td colSpan="20" style={{ "text-align": "center", whiteSpace: "nowrap" }}>No Record Found</td>
                                     </tr>
                                   )}

          </thead>
          </table>
          </div>
          </div>
          );
          };

export default ReportView;
