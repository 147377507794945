import { useEffect } from 'react';

export const useScrollEffect = () => {
  useEffect(() => {
    const handleScroll = (event) => {
      if (event.target.type === 'number') {
        event.preventDefault();
      }
    };
    document.addEventListener('wheel', handleScroll, { passive: false });
    return () => {
    document.removeEventListener('wheel', handleScroll);
    };
  }, []);
};