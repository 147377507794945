import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../../Utils/constants.js";
import Select from "react-select";
import DatePicker from "../../../Lib/DatePicker.js";
import "../../../Styles/Part3.css";
import GetRecord from "../../../Lib/GetRecord.js";
import useScanDetection from "use-scan-detection";
import { setMessage } from "../../../Lib/SetMessage.js";
import CreateApproval from "../../../Lib/CreateApproval.js";

function PmsCreate() {
  const initMachine = {
    ShiftInchargeShiftC: "",
    ShiftInchargeShiftB: "",
    ShiftInchargeShiftA: "",
    LineInspecQaShiftAEmp_name: "",
    LineInspecQaShiftCEmp_name: "",
    LineInspecQaShiftBEmp_name: "",
    ShiftInchargeShiftAEmp_name: "",
    ShiftInchargeShiftBEmp_name: "",
    ShiftInchargeShiftCEmp_name: "",
    LineInspecQaShiftA: "",
    LineInspecQaShiftC: "",
    LineInspecQaShiftB: "",
    CoreSet: "",
    Cavity: "",
    CavSet: "",
    Core: "",
    Tempcore1: "",
    Tempcore2: "",
    Tempcore3: "",
    Tempcore4: "",
    Tempcore5: "",
    Tempcore6: "",
    Tempcav1: "",
    Tempcav2: "",
    Tempcav3: "",
    Tempcav4: "",
    Tempcav5: "",
    Tempcav6: "",
    RM2: "",
    RM1: "",
    RM4: "",
    RM3: "",
    RM6: "",
    RM5: "",
    PartWeight: "",
    PW1: "",
    PW2: "",
    PW3: "",
    PW4: "",
    PW5: "",
    PW6: "",
    PW11: "",
    PW21: "",
    PW31: "",
    PW41: "",
    PW51: "",
    PW61: "",
    PrtSet: "",
    TrackDate: "",
    Mcno: "",
    mc_name: "",
    part_weight_1:"",
    part_weight_2:"",
    part_weight_set_minus_1:"",
    part_weight_set_minus_2:"", 
    part_weight_set_plus_1:"",
    part_weight_set_plus_2:"",   
  };
  const initTracking = {
    Track_15_pm: "",
    Track_19_pm: "",
    Track_7_am: "",
    Track_11_am: "",
    Track_23_pm: "",
    Track_3_am: "",
    Pms_id: "",
  };
  const initHeat = {
    Heat_inst_used: "",
    Heat_actual_1: "",
    Heat_units: "",
    Heat_desc: "",
    Heat_sl: "",
    Heat_smp_set: "",
    Heat_smp_data: "",
    Heat_actual_6: "",
    Heat_actual_5: "",
    Pms_id: "",
    Heat_actual_4: "",
    Heat_actual_3: "",
    Heat_actual_2: "",
  };
  const initInject = {
    Inject_desc: "",
    Inject_smp_data: "",
    Inject_actual_4: "",
    Inject_actual_3: "",
    Inject_actual_6: "",
    Inject_actual_5: "",
    Inject_actual_2: "",
    Inject_actual_1: "",
    Inject_inst_used: "",
    Inject_smp_set: "",
    Inject_sl: "",
    Inject_units: "",
    Pms_id: "",
  };

  const injectvalue = [
    { key: "Cycletme", value: "CycTmeSet" },
    { key: "Cooltme", value: "CoolTmeSet" },
    { key: "Inj104", value: "InjTmeSet" },
    { key: "Inj204", value: "InjTmeSet" },
    { key: "Inj304", value: "InjTmeSet" },
    { key: "Inj404", value: "InjTmeSet" },
    { key: "Inj101", value: "InjPresSet" },
    { key: "Inj201", value: "InjPresSet" },
    { key: "Inj301", value: "InjPresSet" },
    { key: "Inj401", value: "InjPresSet" },
    { key: "Inj102", value: "InjSpdSet" },
    { key: "Inj202", value: "InjSpdSet" },
    { key: "Inj302", value: "InjSpdSet" },
    { key: "Inj402", value: "InjSpdSet" },
    { key: "Hold101", value: "InjPresSet" },
    { key: "Hold102", value: "InjPresSet" },
    { key: "Hold103", value: "InjPresSet" },
    { key: "Hold104", value: "InjPresSet" },
    { key: "Hold201", value: "InjSpdSet" },
    { key: "Hold202", value: "InjSpdSet" },
    { key: "Hold203", value: "InjSpdSet" },
    { key: "Hold204", value: "InjSpdSet" },
    { key: "Hold401", value: "InjTmeSet" },
    { key: "Hold402", value: "InjTmeSet" },
    { key: "Hold403", value: "InjTmeSet" },
    { key: "Hold404", value: "InjTmeSet" },
    { key: "RefillEnd", value: "ReFillPosSet" },
    { key: "Melt104", value: "CusSet" },
  ];

  const Heatvalue = [
    { key: "MTC", value: "MtcSet" },
    { key: "Hopp", value: "HoppSet" },
    { key: "Hozz", value: "NozzSet" },
    { key: "Zone1", value: "ZneSet1" },
    { key: "Zone2", value: "ZneSet2" },
    { key: "Zone3", value: "ZneSet3" },
    { key: "Zone4", value: "ZneSet4" },
    { key: "Zone5", value: "ZneSet5" },
    { key: "Zone6", value: "ZneSet6" },
  ];

  const initsmp = {
    OnTme: "",
    CoreSet: "",
    OffSet: "",
    SprBushHle: "",
    ActiveStatus: "",
    Cavity: "",
    Gte2: "",
    Gte3: "",
    Gte6: "",
    Gte4: "",
    Gte5: "",
    Close202: "",
    Close203: "",
    CreatedBy: "",
    Close201: "",
    ReFillSpdSet: "",
    McNo: "",
    NozzHole: "",
    NoCav: "",
    ModifiedDate: "",
    CoolTmeSet: "",
    ManiFldSet1: "",
    ManiFldSet2: "",
    SuckBack: "",
    Close301: "",
    Close302: "",
    Close303: "",
    Transfer_id: "",
    MldSfy102: "",
    CavSet: "",
    ManiFldSet3: "",
    ManiFldSet4: "",
    ManiFldSet5: "",
    RefillEnd: "",
    MldSfy101: "",
    MoldSafeT: "",
    CusSet: "",
    Hozz: "",
    GrdNme: "",
    Remarks: "",
    MldSfy201: "",
    MldSfy202: "",
    Gate1: "",
    MtcSet: "",
    Gate2: "",
    PrtWgt: "",
    Gate9: "",
    CreatedDate: "",
    ClseSpdSet: "",
    Gate7: "",
    Gate8: "",
    Gate5: "",
    Gate6: "",
    Gate3: "",
    Gate4: "",
    Inj104: "",
    InjTmeSet: "",
    Zone1: "",
    Zone2: "",
    Zone3: "",
    Zone4: "",
    Zone5: "",
    Zone6: "",
    RetSpd: "",
    Inj101: "",
    Inj102: "",
    Inj103: "",
    Open201: "",
    Open203: "",
    Transfer_status: "",
    Open202: "",
    MldSfy301: "",
    Transfer_dt: "",
    MldSfy302: "",
    InjSpdSet: "",
    Hold203: "",
    Hold204: "",
    Hold201: "",
    Hold202: "",
    Hold304: "",
    Hold302: "",
    Hold303: "",
    Hold301: "",
    Hold101: "",
    Hold104: "",
    Hold102: "",
    Hold103: "",
    Hold403: "",
    Hold404: "",
    Hold401: "",
    Hold402: "",
    Cooltme: "",
    Gate12: "",
    Gate11: "",
    Gate10: "",
    Inj203: "",
    Inj204: "",
    EjecRet104: "",
    ManFld5: "",
    EjecRet103: "",
    ManFld4: "",
    EjecRet102: "",
    EjecRet101: "",
    EjecFrd104: "",
    ModifiedBy: "",
    Bp102: "",
    Bp101: "",
    Bp104: "",
    Bp103: "",
    Id: "",
    ManFld1: "",
    ManFld3: "",
    ManFld2: "",
    Cre1: "",
    Cre2: "",
    ZneSet1: "",
    Cre3: "",
    Inj201: "",
    Cre4: "",
    Inj202: "",
    FillT104: "",
    Open303: "",
    ReFillTmeSet: "",
    Open302: "",
    Open301: "",
    Oilt: "",
    Switch: "",
    EjecFrd101: "",
    EjecFrd102: "",
    Core401: "",
    EjecFrd103: "",
    Core402: "",
    ReFill302: "",
    Core403: "",
    ReFill301: "",
    SukSet: "",
    Inj302: "",
    Inj303: "",
    Inj304: "",
    Runn: "",
    Hopp: "",
    CycTmeSet: "",
    ReFill304: "",
    ReFill303: "",
    EjecFrd203: "",
    EjecFrd204: "",
    Colour: "",
    Bp201: "",
    Bp203: "",
    Bp202: "",
    Cycletme: "",
    InjPresSet: "",
    Bp204: "",
    ClsePresSet: "",
    Inj301: "",
    Open403: "",
    Open402: "",
    Open401: "",
    ReFillPresSet: "",
    OnSet: "",
    PrtSet: "",
    Melt101: "",
    Melt102: "",
    Melt104: "",
    Melt104: "",
    Core301: "",
    EjecFrd201: "",
    Core302: "",
    EjecFrd202: "",
    Core303: "",
    ReFill203: "",
    ReFill202: "",
    ReFill201: "",
    MTC: "",
    Inj401: "",
    Inj402: "",
    EjcPresSet: "",
    Inj403: "",
    Inj404: "",
    ReFill204: "",
    Fwd: "",
    TrackDate: "",
    PrtNme: "",
    OilSet: "",
    Core: "",
    FormetNo: "",
    EjcSpdSet: "",
    Core201: "",
    Core202: "",
    Core203: "",
    ReFill104: "",
    ReFill103: "",
    ReFillPosSet: "",
    ReFill102: "",
    ReFill101: "",
    Actual1: "",
    NozzSet: "",
    EjecRet203: "",
    EjecRet202: "",
    EjecRet201: "",
    EjecRet204: "",
    Dely: "",
    MatNme: "",
    MatrialName:"",
    Close103: "",
    Close101: "",
    Close102: "",
    Modl: "",
    HoppSet: "",
    Core101: "",
    GteSet: "",
    Core102: "",
    Core103: "",
    InjPosSet: "",
    ZneSet2: "",
    ZneSet3: "",
    PartWeight1: "",
    PartWeight2: "",
    PartWeightMinus1: "",
    PartWeightMinus2: "",
    PartWeightPlus1: "",
    PartWeightPlus2: "",
    part_weight_1:"",
    part_weight_2:"",
    part_weight_set_minus_1:"",
    part_weight_set_minus_2:"", 
    part_weight_set_plus_1:"",
    part_weight_set_plus_2:"",
  };

  const initEmpName = {
    ShiftInchargeShiftC: "",
    ShiftInchargeShiftB: "",
    ShiftInchargeShiftA: "",
    LineInspecQaShiftAEmp_name: "",
    LineInspecQaShiftCEmp_name: "",
    LineInspecQaShiftBEmp_name: "",
    ShiftInchargeShiftAEmp_name: "",
    ShiftInchargeShiftBEmp_name: "",
    ShiftInchargeShiftCEmp_name: "",
    LineInspecQaShiftA: "",
    LineInspecQaShiftC: "",
    LineInspecQaShiftB: "",
  };

  const [Machine, setMachine] = useState([initMachine]);
  const [Part, setPart] = useState([{}]);
  const [Disable, setDisable] = useState({});
  const [submit, setsubmit] = useState();
  const [New, setNew] = useState();
  const [NewInsert, setNewInsert] = useState();
  const [Insert, setInsert] = useState(["", "", ""]);
  const [PMS, setPMS] = useState([{}]);
  const [emp, setemp] = useState([{}]);
  const [empDetails, setempDetails] = useState([{}]);
  const [Smp, setSmp] = useState([initsmp]);
  const [EmpName, setEmpName] = useState(initEmpName);

  const [Tracking, setTracking] = useState([
    initTracking,
    initTracking,
    initTracking,
    initTracking,
    initTracking,
  ]);
  const [Heat, setHeat] = useState([
    initHeat,
    initHeat,
    initHeat,
    initHeat,
    initHeat,
    initHeat,
    initHeat,
    initHeat,
    initHeat,
  ]);
  const [Inject, setInject] = useState([
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
  ]);

  function reset() {
    setHeat([
      initHeat,
      initHeat,
      initHeat,
      initHeat,
      initHeat,
      initHeat,
      initHeat,
      initHeat,
      initHeat,
    ]);
    setInject([
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
    ]);
    setTracking([
      initTracking,
      initTracking,
      initTracking,
      initTracking,
      initTracking,
    ]);
    setPMS([initMachine]);
    setEmpName(initEmpName);
    setNew();
    setNewInsert();
    setsubmit(false);
  }
  function formatTimeDigits(value) {
    return value.toString().padStart(2, "0");
  }

  async function handleMachine(event) {
    gettracking();
    let selected = Machine.find((option) => option.McNo === event.value);
    if (!selected) {
      selected = { McNo: undefined, mc_name: undefined };
      alert("The Machine not having any SMP information");
    }
    const result = await GetRecord(
      {
        _keyword_: "Get_PMS_Parant_by_MachineNo_in_CurrentDate",
        Mcno: event.value,
      },
      setPMS
    ).then((res) => {
      if (res === "NoRecordFound") {
        setNew(true);
        setPMS([
          {
            ...initMachine,
            Mcno: selected.McNo,
            mc_name: selected.mc_name,
            TrackDate: new Date(
              new Date().getTime() - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .split("T")[0],
          },
        ]);
      } else {
        setNew(false);
        setInsert(false);
      }
      let employee = {};
      GetRecord(
        {
          _keyword_: "emp_details_for_PMS_by_empCode",
          emp_code: res[0].ShiftInchargeShiftA,
        },
        setemp
      ).then((empres) => {
        employee.ShiftInchargeShiftA = empres[0].Emp_code;
        employee.ShiftInchargeShiftAEmp_name = empres[0].Emp_name;
        GetRecord(
          {
            _keyword_: "emp_details_for_PMS_by_empCode",
            emp_code: res[0].ShiftInchargeShiftB,
          },
          setemp
        ).then((empres) => {
          employee.ShiftInchargeShiftB = empres[0].Emp_code;
          employee.ShiftInchargeShiftBEmp_name = empres[0].Emp_name;
          // console.log(empres);
          GetRecord(
            {
              _keyword_: "emp_details_for_PMS_by_empCode",
              emp_code: res[0].ShiftInchargeShiftC,
            },
            setemp
          ).then((empres) => {
            employee.ShiftInchargeShiftC = empres[0].Emp_code;
            employee.ShiftInchargeShiftCEmp_name = empres[0].Emp_name;
            GetRecord(
              {
                _keyword_: "emp_details_for_PMS_by_empCode",
                emp_code: res[0].LineInspecQaShiftA,
              },
              setemp
            ).then((empres) => {
              employee.LineInspecQaShiftA = empres[0].Emp_code;
              employee.LineInspecQaShiftAEmp_name = empres[0].Emp_name;
              GetRecord(
                {
                  _keyword_: "emp_details_for_PMS_by_empCode",
                  emp_code: res[0].LineInspecQaShiftB,
                },
                setemp
              ).then((empres) => {
                employee.LineInspecQaShiftB = empres[0].Emp_code;
                employee.LineInspecQaShiftBEmp_name = empres[0].Emp_name;
                GetRecord(
                  {
                    _keyword_: "emp_details_for_PMS_by_empCode",
                    emp_code: res[0].LineInspecQaShiftC,
                  },
                  setemp
                ).then((empres) => {
                  employee.LineInspecQaShiftC = empres[0].Emp_code;
                  employee.LineInspecQaShiftCEmp_name = empres[0].Emp_name;
                  setEmpName(employee);
                });
              });
            });
          });
        });
      });
    });
    GetCurrentTime();
  }

  function HandleFeildChangePMS(event) {
    setPMS([{ ...PMS[0], [event.target.name]: event.target.value }]);
  }

  function HandlePart7Am(event) {
    if (event.target.value === "MOULD CHANGE") {
      setInsert(["Track_7_am", "Heat_actual_1", "Inject_actual_1"]);
      setTracking([
        {
          ...Tracking[0],
          Track_7_am: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[1]
            .substring(0, 5),
        },
        { ...Tracking[1], Track_7_am: event.target.value },
        { ...Tracking[2], Track_7_am: event.target.value },
        { ...Tracking[3], Track_7_am: event.target.value },
        { ...Tracking[4], Track_7_am: event.target.value },
      ]);
      setHeat([
        { ...Heat[0], Heat_actual_1: event.target.value },
        { ...Heat[1], Heat_actual_1: event.target.value },
        { ...Heat[2], Heat_actual_1: event.target.value },
        { ...Heat[3], Heat_actual_1: event.target.value },
        { ...Heat[4], Heat_actual_1: event.target.value },
        { ...Heat[5], Heat_actual_1: event.target.value },
        { ...Heat[6], Heat_actual_1: event.target.value },
        { ...Heat[7], Heat_actual_1: event.target.value },
        { ...Heat[8], Heat_actual_1: event.target.value },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_1: event.target.value },
        { ...Inject[1], Inject_actual_1: event.target.value },
        { ...Inject[2], Inject_actual_1: event.target.value },
        { ...Inject[3], Inject_actual_1: event.target.value },
        { ...Inject[4], Inject_actual_1: event.target.value },
        { ...Inject[5], Inject_actual_1: event.target.value },
        { ...Inject[6], Inject_actual_1: event.target.value },
        { ...Inject[7], Inject_actual_1: event.target.value },
        { ...Inject[8], Inject_actual_1: event.target.value },
        { ...Inject[9], Inject_actual_1: event.target.value },
        { ...Inject[10], Inject_actual_1: event.target.value },
        { ...Inject[11], Inject_actual_1: event.target.value },
        { ...Inject[12], Inject_actual_1: event.target.value },
        { ...Inject[13], Inject_actual_1: event.target.value },
        { ...Inject[14], Inject_actual_1: event.target.value },
        { ...Inject[15], Inject_actual_1: event.target.value },
        { ...Inject[16], Inject_actual_1: event.target.value },
        { ...Inject[17], Inject_actual_1: event.target.value },
        { ...Inject[18], Inject_actual_1: event.target.value },
        { ...Inject[19], Inject_actual_1: event.target.value },
        { ...Inject[20], Inject_actual_1: event.target.value },
        { ...Inject[21], Inject_actual_1: event.target.value },
        { ...Inject[22], Inject_actual_1: event.target.value },
        { ...Inject[23], Inject_actual_1: event.target.value },
        { ...Inject[24], Inject_actual_1: event.target.value },
        { ...Inject[25], Inject_actual_1: event.target.value },
        { ...Inject[26], Inject_actual_1: event.target.value },
        { ...Inject[27], Inject_actual_1: event.target.value },
      ]);
    } else if (event.target.value === "IDEAL") {
      setTracking([
        {
          ...Tracking[0],
          Track_7_am: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[1]
            .substring(0, 5),
        },
        { ...Tracking[1], Track_7_am: event.target.value },
        { ...Tracking[2], Track_7_am: event.target.value },
        { ...Tracking[3], Track_7_am: event.target.value },
        { ...Tracking[4], Track_7_am: event.target.value },
      ]);
      setHeat([
        { ...Heat[0], Heat_actual_1: event.target.value },
        { ...Heat[1], Heat_actual_1: event.target.value },
        { ...Heat[2], Heat_actual_1: event.target.value },
        { ...Heat[3], Heat_actual_1: event.target.value },
        { ...Heat[4], Heat_actual_1: event.target.value },
        { ...Heat[5], Heat_actual_1: event.target.value },
        { ...Heat[6], Heat_actual_1: event.target.value },
        { ...Heat[7], Heat_actual_1: event.target.value },
        { ...Heat[8], Heat_actual_1: event.target.value },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_1: event.target.value },
        { ...Inject[1], Inject_actual_1: event.target.value },
        { ...Inject[2], Inject_actual_1: event.target.value },
        { ...Inject[3], Inject_actual_1: event.target.value },
        { ...Inject[4], Inject_actual_1: event.target.value },
        { ...Inject[5], Inject_actual_1: event.target.value },
        { ...Inject[6], Inject_actual_1: event.target.value },
        { ...Inject[7], Inject_actual_1: event.target.value },
        { ...Inject[8], Inject_actual_1: event.target.value },
        { ...Inject[9], Inject_actual_1: event.target.value },
        { ...Inject[10], Inject_actual_1: event.target.value },
        { ...Inject[11], Inject_actual_1: event.target.value },
        { ...Inject[12], Inject_actual_1: event.target.value },
        { ...Inject[13], Inject_actual_1: event.target.value },
        { ...Inject[14], Inject_actual_1: event.target.value },
        { ...Inject[15], Inject_actual_1: event.target.value },
        { ...Inject[16], Inject_actual_1: event.target.value },
        { ...Inject[17], Inject_actual_1: event.target.value },
        { ...Inject[18], Inject_actual_1: event.target.value },
        { ...Inject[19], Inject_actual_1: event.target.value },
        { ...Inject[20], Inject_actual_1: event.target.value },
        { ...Inject[21], Inject_actual_1: event.target.value },
        { ...Inject[22], Inject_actual_1: event.target.value },
        { ...Inject[23], Inject_actual_1: event.target.value },
        { ...Inject[24], Inject_actual_1: event.target.value },
        { ...Inject[25], Inject_actual_1: event.target.value },
        { ...Inject[26], Inject_actual_1: event.target.value },
        { ...Inject[27], Inject_actual_1: event.target.value },
      ]);
    } else {
      GetRecord(
        {
          _keyword_: "std_mld_params_by_part_pms",
          PrtNme: event.target.value,
          McNo: PMS[0].Mcno,
        },
        setSmp
      ).then((res) => {
        setTracking([
          {
            ...Tracking[0],
            Track_7_am: new Date(
              new Date().getTime() - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .split("T")[1]
              .substring(0, 5),
          },
          { ...Tracking[1], Track_7_am: res[0].PrtNme },
          { ...Tracking[2], Track_7_am: res[0].Colour },
          { ...Tracking[3], Track_7_am: res[0].MatrialName },
          { ...Tracking[4], Track_7_am: res[0].GrdNme },
        ]);
        // console.log("initial - ",Heat);
        // console.log("setheat in HandlePart7Am")

        setHeat([
          { ...Heat[0], Heat_actual_1: "" },
          { ...Heat[1], Heat_actual_1: "" },
          { ...Heat[2], Heat_actual_1: "" },
          { ...Heat[3], Heat_actual_1: "" },
          { ...Heat[4], Heat_actual_1: "" },
          { ...Heat[5], Heat_actual_1: "" },
          { ...Heat[6], Heat_actual_1: "" },
          { ...Heat[7], Heat_actual_1: "" },
          { ...Heat[8], Heat_actual_1: "" },
        ]);
        console.log("final - ", Heat);
        setInject([
          { ...Inject[0], Inject_actual_1: "" },
          { ...Inject[1], Inject_actual_1: "" },
          { ...Inject[2], Inject_actual_1: "" },
          { ...Inject[3], Inject_actual_1: "" },
          { ...Inject[4], Inject_actual_1: "" },
          { ...Inject[5], Inject_actual_1: "" },
          { ...Inject[6], Inject_actual_1: "" },
          { ...Inject[7], Inject_actual_1: "" },
          { ...Inject[8], Inject_actual_1: "" },
          { ...Inject[9], Inject_actual_1: "" },
          { ...Inject[10], Inject_actual_1: "" },
          { ...Inject[11], Inject_actual_1: "" },
          { ...Inject[12], Inject_actual_1: "" },
          { ...Inject[13], Inject_actual_1: "" },
          { ...Inject[14], Inject_actual_1: "" },
          { ...Inject[15], Inject_actual_1: "" },
          { ...Inject[16], Inject_actual_1: "" },
          { ...Inject[17], Inject_actual_1: "" },
          { ...Inject[18], Inject_actual_1: "" },
          { ...Inject[19], Inject_actual_1: "" },
          { ...Inject[20], Inject_actual_1: "" },
          { ...Inject[21], Inject_actual_1: "" },
          { ...Inject[22], Inject_actual_1: "" },
          { ...Inject[23], Inject_actual_1: "" },
          { ...Inject[24], Inject_actual_1: "" },
          { ...Inject[25], Inject_actual_1: "" },
          { ...Inject[26], Inject_actual_1: "" },
          { ...Inject[27], Inject_actual_1: "" },
        ]);
      });
    }
  }
  function HandlePart11Am(event) {
    if (event.target.value === "MOULD CHANGE") {
      setInsert(["Track_11_am", "Heat_actual_2", "Inject_actual_2"]);
      setTracking([
        {
          ...Tracking[0],
          Track_11_am: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[1]
            .substring(0, 5),
        },
        { ...Tracking[1], Track_11_am: event.target.value },
        { ...Tracking[2], Track_11_am: event.target.value },
        { ...Tracking[3], Track_11_am: event.target.value },
        { ...Tracking[4], Track_11_am: event.target.value },
      ]);
      setHeat([
        { ...Heat[0], Heat_actual_2: event.target.value },
        { ...Heat[1], Heat_actual_2: event.target.value },
        { ...Heat[2], Heat_actual_2: event.target.value },
        { ...Heat[3], Heat_actual_2: event.target.value },
        { ...Heat[4], Heat_actual_2: event.target.value },
        { ...Heat[5], Heat_actual_2: event.target.value },
        { ...Heat[6], Heat_actual_2: event.target.value },
        { ...Heat[7], Heat_actual_2: event.target.value },
        { ...Heat[8], Heat_actual_2: event.target.value },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_2: event.target.value },
        { ...Inject[1], Inject_actual_2: event.target.value },
        { ...Inject[2], Inject_actual_2: event.target.value },
        { ...Inject[3], Inject_actual_2: event.target.value },
        { ...Inject[4], Inject_actual_2: event.target.value },
        { ...Inject[5], Inject_actual_2: event.target.value },
        { ...Inject[6], Inject_actual_2: event.target.value },
        { ...Inject[7], Inject_actual_2: event.target.value },
        { ...Inject[8], Inject_actual_2: event.target.value },
        { ...Inject[9], Inject_actual_2: event.target.value },
        { ...Inject[10], Inject_actual_2: event.target.value },
        { ...Inject[11], Inject_actual_2: event.target.value },
        { ...Inject[12], Inject_actual_2: event.target.value },
        { ...Inject[13], Inject_actual_2: event.target.value },
        { ...Inject[14], Inject_actual_2: event.target.value },
        { ...Inject[15], Inject_actual_2: event.target.value },
        { ...Inject[16], Inject_actual_2: event.target.value },
        { ...Inject[17], Inject_actual_2: event.target.value },
        { ...Inject[18], Inject_actual_2: event.target.value },
        { ...Inject[19], Inject_actual_2: event.target.value },
        { ...Inject[20], Inject_actual_2: event.target.value },
        { ...Inject[21], Inject_actual_2: event.target.value },
        { ...Inject[22], Inject_actual_2: event.target.value },
        { ...Inject[23], Inject_actual_2: event.target.value },
        { ...Inject[24], Inject_actual_2: event.target.value },
        { ...Inject[25], Inject_actual_2: event.target.value },
        { ...Inject[26], Inject_actual_2: event.target.value },
        { ...Inject[27], Inject_actual_2: event.target.value },
      ]);
    } else if (event.target.value === "IDEAL") {
      setTracking([
        {
          ...Tracking[0],
          Track_11_am: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[1]
            .substring(0, 5),
        },
        { ...Tracking[1], Track_11_am: event.target.value },
        { ...Tracking[2], Track_11_am: event.target.value },
        { ...Tracking[3], Track_11_am: event.target.value },
        { ...Tracking[4], Track_11_am: event.target.value },
      ]);
      setHeat([
        { ...Heat[0], Heat_actual_2: event.target.value },
        { ...Heat[1], Heat_actual_2: event.target.value },
        { ...Heat[2], Heat_actual_2: event.target.value },
        { ...Heat[3], Heat_actual_2: event.target.value },
        { ...Heat[4], Heat_actual_2: event.target.value },
        { ...Heat[5], Heat_actual_2: event.target.value },
        { ...Heat[6], Heat_actual_2: event.target.value },
        { ...Heat[7], Heat_actual_2: event.target.value },
        { ...Heat[8], Heat_actual_2: event.target.value },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_2: event.target.value },
        { ...Inject[1], Inject_actual_2: event.target.value },
        { ...Inject[2], Inject_actual_2: event.target.value },
        { ...Inject[3], Inject_actual_2: event.target.value },
        { ...Inject[4], Inject_actual_2: event.target.value },
        { ...Inject[5], Inject_actual_2: event.target.value },
        { ...Inject[6], Inject_actual_2: event.target.value },
        { ...Inject[7], Inject_actual_2: event.target.value },
        { ...Inject[8], Inject_actual_2: event.target.value },
        { ...Inject[9], Inject_actual_2: event.target.value },
        { ...Inject[10], Inject_actual_2: event.target.value },
        { ...Inject[11], Inject_actual_2: event.target.value },
        { ...Inject[12], Inject_actual_2: event.target.value },
        { ...Inject[13], Inject_actual_2: event.target.value },
        { ...Inject[14], Inject_actual_2: event.target.value },
        { ...Inject[15], Inject_actual_2: event.target.value },
        { ...Inject[16], Inject_actual_2: event.target.value },
        { ...Inject[17], Inject_actual_2: event.target.value },
        { ...Inject[18], Inject_actual_2: event.target.value },
        { ...Inject[19], Inject_actual_2: event.target.value },
        { ...Inject[20], Inject_actual_2: event.target.value },
        { ...Inject[21], Inject_actual_2: event.target.value },
        { ...Inject[22], Inject_actual_2: event.target.value },
        { ...Inject[23], Inject_actual_2: event.target.value },
        { ...Inject[24], Inject_actual_2: event.target.value },
        { ...Inject[25], Inject_actual_2: event.target.value },
        { ...Inject[26], Inject_actual_2: event.target.value },
        { ...Inject[27], Inject_actual_2: event.target.value },
      ]);
    } else {
      GetRecord(
        {
          _keyword_: "std_mld_params_by_part_pms",
          PrtNme: event.target.value,
          McNo: PMS[0].Mcno,
        },
        setSmp
      ).then((res) => {
        setTracking([
          {
            ...Tracking[0],
            Track_11_am: new Date(
              new Date().getTime() - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .split("T")[1]
              .substring(0, 5),
          },
          { ...Tracking[1], Track_11_am: res[0].PrtNme },
          { ...Tracking[2], Track_11_am: res[0].Colour },
          { ...Tracking[3], Track_11_am: res[0].MatrialName },
          { ...Tracking[4], Track_11_am: res[0].GrdNme },
        ]);
      });
      setHeat([
        { ...Heat[0], Heat_actual_2: "" },
        { ...Heat[1], Heat_actual_2: "" },
        { ...Heat[2], Heat_actual_2: "" },
        { ...Heat[3], Heat_actual_2: "" },
        { ...Heat[4], Heat_actual_2: "" },
        { ...Heat[5], Heat_actual_2: "" },
        { ...Heat[6], Heat_actual_2: "" },
        { ...Heat[7], Heat_actual_2: "" },
        { ...Heat[8], Heat_actual_2: "" },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_2: "" },
        { ...Inject[1], Inject_actual_2: "" },
        { ...Inject[2], Inject_actual_2: "" },
        { ...Inject[3], Inject_actual_2: "" },
        { ...Inject[4], Inject_actual_2: "" },
        { ...Inject[5], Inject_actual_2: "" },
        { ...Inject[6], Inject_actual_2: "" },
        { ...Inject[7], Inject_actual_2: "" },
        { ...Inject[8], Inject_actual_2: "" },
        { ...Inject[9], Inject_actual_2: "" },
        { ...Inject[10], Inject_actual_2: "" },
        { ...Inject[11], Inject_actual_2: "" },
        { ...Inject[12], Inject_actual_2: "" },
        { ...Inject[13], Inject_actual_2: "" },
        { ...Inject[14], Inject_actual_2: "" },
        { ...Inject[15], Inject_actual_2: "" },
        { ...Inject[16], Inject_actual_2: "" },
        { ...Inject[17], Inject_actual_2: "" },
        { ...Inject[18], Inject_actual_2: "" },
        { ...Inject[19], Inject_actual_2: "" },
        { ...Inject[20], Inject_actual_2: "" },
        { ...Inject[21], Inject_actual_2: "" },
        { ...Inject[22], Inject_actual_2: "" },
        { ...Inject[23], Inject_actual_2: "" },
        { ...Inject[24], Inject_actual_2: "" },
        { ...Inject[25], Inject_actual_2: "" },
        { ...Inject[26], Inject_actual_2: "" },
        { ...Inject[27], Inject_actual_2: "" },
      ]);
    }
  }
  function HandlePart15Pm(event) {
    if (event.target.value === "MOULD CHANGE") {
      setInsert(["Track_15_pm", "Heat_actual_3", "Inject_actual_3"]);
      setTracking([
        {
          ...Tracking[0],
          Track_15_pm: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[1]
            .substring(0, 5),
        },
        { ...Tracking[1], Track_15_pm: event.target.value },
        { ...Tracking[2], Track_15_pm: event.target.value },
        { ...Tracking[3], Track_15_pm: event.target.value },
        { ...Tracking[4], Track_15_pm: event.target.value },
      ]);
      setHeat([
        { ...Heat[0], Heat_actual_3: event.target.value },
        { ...Heat[1], Heat_actual_3: event.target.value },
        { ...Heat[2], Heat_actual_3: event.target.value },
        { ...Heat[3], Heat_actual_3: event.target.value },
        { ...Heat[4], Heat_actual_3: event.target.value },
        { ...Heat[5], Heat_actual_3: event.target.value },
        { ...Heat[6], Heat_actual_3: event.target.value },
        { ...Heat[7], Heat_actual_3: event.target.value },
        { ...Heat[8], Heat_actual_3: event.target.value },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_3: event.target.value },
        { ...Inject[1], Inject_actual_3: event.target.value },
        { ...Inject[2], Inject_actual_3: event.target.value },
        { ...Inject[3], Inject_actual_3: event.target.value },
        { ...Inject[4], Inject_actual_3: event.target.value },
        { ...Inject[5], Inject_actual_3: event.target.value },
        { ...Inject[6], Inject_actual_3: event.target.value },
        { ...Inject[7], Inject_actual_3: event.target.value },
        { ...Inject[8], Inject_actual_3: event.target.value },
        { ...Inject[9], Inject_actual_3: event.target.value },
        { ...Inject[10], Inject_actual_3: event.target.value },
        { ...Inject[11], Inject_actual_3: event.target.value },
        { ...Inject[12], Inject_actual_3: event.target.value },
        { ...Inject[13], Inject_actual_3: event.target.value },
        { ...Inject[14], Inject_actual_3: event.target.value },
        { ...Inject[15], Inject_actual_3: event.target.value },
        { ...Inject[16], Inject_actual_3: event.target.value },
        { ...Inject[17], Inject_actual_3: event.target.value },
        { ...Inject[18], Inject_actual_3: event.target.value },
        { ...Inject[19], Inject_actual_3: event.target.value },
        { ...Inject[20], Inject_actual_3: event.target.value },
        { ...Inject[21], Inject_actual_3: event.target.value },
        { ...Inject[22], Inject_actual_3: event.target.value },
        { ...Inject[23], Inject_actual_3: event.target.value },
        { ...Inject[24], Inject_actual_3: event.target.value },
        { ...Inject[25], Inject_actual_3: event.target.value },
        { ...Inject[26], Inject_actual_3: event.target.value },
        { ...Inject[27], Inject_actual_3: event.target.value },
      ]);
    } else if (event.target.value === "IDEAL") {
      setTracking([
        {
          ...Tracking[0],
          Track_15_pm: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[1]
            .substring(0, 5),
        },
        { ...Tracking[1], Track_15_pm: event.target.value },
        { ...Tracking[2], Track_15_pm: event.target.value },
        { ...Tracking[3], Track_15_pm: event.target.value },
        { ...Tracking[4], Track_15_pm: event.target.value },
      ]);
      setHeat([
        { ...Heat[0], Heat_actual_3: event.target.value },
        { ...Heat[1], Heat_actual_3: event.target.value },
        { ...Heat[2], Heat_actual_3: event.target.value },
        { ...Heat[3], Heat_actual_3: event.target.value },
        { ...Heat[4], Heat_actual_3: event.target.value },
        { ...Heat[5], Heat_actual_3: event.target.value },
        { ...Heat[6], Heat_actual_3: event.target.value },
        { ...Heat[7], Heat_actual_3: event.target.value },
        { ...Heat[8], Heat_actual_3: event.target.value },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_3: event.target.value },
        { ...Inject[1], Inject_actual_3: event.target.value },
        { ...Inject[2], Inject_actual_3: event.target.value },
        { ...Inject[3], Inject_actual_3: event.target.value },
        { ...Inject[4], Inject_actual_3: event.target.value },
        { ...Inject[5], Inject_actual_3: event.target.value },
        { ...Inject[6], Inject_actual_3: event.target.value },
        { ...Inject[7], Inject_actual_3: event.target.value },
        { ...Inject[8], Inject_actual_3: event.target.value },
        { ...Inject[9], Inject_actual_3: event.target.value },
        { ...Inject[10], Inject_actual_3: event.target.value },
        { ...Inject[11], Inject_actual_3: event.target.value },
        { ...Inject[12], Inject_actual_3: event.target.value },
        { ...Inject[13], Inject_actual_3: event.target.value },
        { ...Inject[14], Inject_actual_3: event.target.value },
        { ...Inject[15], Inject_actual_3: event.target.value },
        { ...Inject[16], Inject_actual_3: event.target.value },
        { ...Inject[17], Inject_actual_3: event.target.value },
        { ...Inject[18], Inject_actual_3: event.target.value },
        { ...Inject[19], Inject_actual_3: event.target.value },
        { ...Inject[20], Inject_actual_3: event.target.value },
        { ...Inject[21], Inject_actual_3: event.target.value },
        { ...Inject[22], Inject_actual_3: event.target.value },
        { ...Inject[23], Inject_actual_3: event.target.value },
        { ...Inject[24], Inject_actual_3: event.target.value },
        { ...Inject[25], Inject_actual_3: event.target.value },
        { ...Inject[26], Inject_actual_3: event.target.value },
        { ...Inject[27], Inject_actual_3: event.target.value },
      ]);
    } else {
      GetRecord(
        {
          _keyword_: "std_mld_params_by_part_pms",
          PrtNme: event.target.value,
          McNo: PMS[0].Mcno,
        },
        setSmp
      ).then((res) => {
        setTracking([
          {
            ...Tracking[0],
            Track_15_pm: new Date(
              new Date().getTime() - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .split("T")[1]
              .substring(0, 5),
          },
          { ...Tracking[1], Track_15_pm: res[0].PrtNme },
          { ...Tracking[2], Track_15_pm: res[0].Colour },
          { ...Tracking[3], Track_15_pm: res[0].MatrialName },
          { ...Tracking[4], Track_15_pm: res[0].GrdNme },
        ]);
      });
      setHeat([
        { ...Heat[0], Heat_actual_3: "" },
        { ...Heat[1], Heat_actual_3: "" },
        { ...Heat[2], Heat_actual_3: "" },
        { ...Heat[3], Heat_actual_3: "" },
        { ...Heat[4], Heat_actual_3: "" },
        { ...Heat[5], Heat_actual_3: "" },
        { ...Heat[6], Heat_actual_3: "" },
        { ...Heat[7], Heat_actual_3: "" },
        { ...Heat[8], Heat_actual_3: "" },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_3: "" },
        { ...Inject[1], Inject_actual_3: "" },
        { ...Inject[2], Inject_actual_3: "" },
        { ...Inject[3], Inject_actual_3: "" },
        { ...Inject[4], Inject_actual_3: "" },
        { ...Inject[5], Inject_actual_3: "" },
        { ...Inject[6], Inject_actual_3: "" },
        { ...Inject[7], Inject_actual_3: "" },
        { ...Inject[8], Inject_actual_3: "" },
        { ...Inject[9], Inject_actual_3: "" },
        { ...Inject[10], Inject_actual_3: "" },
        { ...Inject[11], Inject_actual_3: "" },
        { ...Inject[12], Inject_actual_3: "" },
        { ...Inject[13], Inject_actual_3: "" },
        { ...Inject[14], Inject_actual_3: "" },
        { ...Inject[15], Inject_actual_3: "" },
        { ...Inject[16], Inject_actual_3: "" },
        { ...Inject[17], Inject_actual_3: "" },
        { ...Inject[18], Inject_actual_3: "" },
        { ...Inject[19], Inject_actual_3: "" },
        { ...Inject[20], Inject_actual_3: "" },
        { ...Inject[21], Inject_actual_3: "" },
        { ...Inject[22], Inject_actual_3: "" },
        { ...Inject[23], Inject_actual_3: "" },
        { ...Inject[24], Inject_actual_3: "" },
        { ...Inject[25], Inject_actual_3: "" },
        { ...Inject[26], Inject_actual_3: "" },
        { ...Inject[27], Inject_actual_3: "" },
      ]);
    }
  }
  function HandlePart19Pm(event) {
    if (event.target.value === "MOULD CHANGE") {
      setInsert(["Track_19_pm", "Heat_actual_4", "Inject_actual_4"]);
      setTracking([
        {
          ...Tracking[0],
          Track_19_pm: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[1]
            .substring(0, 5),
        },
        { ...Tracking[1], Track_19_pm: event.target.value },
        { ...Tracking[2], Track_19_pm: event.target.value },
        { ...Tracking[3], Track_19_pm: event.target.value },
        { ...Tracking[4], Track_19_pm: event.target.value },
      ]);
      setHeat([
        { ...Heat[0], Heat_actual_4: event.target.value },
        { ...Heat[1], Heat_actual_4: event.target.value },
        { ...Heat[2], Heat_actual_4: event.target.value },
        { ...Heat[3], Heat_actual_4: event.target.value },
        { ...Heat[4], Heat_actual_4: event.target.value },
        { ...Heat[5], Heat_actual_4: event.target.value },
        { ...Heat[6], Heat_actual_4: event.target.value },
        { ...Heat[7], Heat_actual_4: event.target.value },
        { ...Heat[8], Heat_actual_4: event.target.value },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_4: event.target.value },
        { ...Inject[1], Inject_actual_4: event.target.value },
        { ...Inject[2], Inject_actual_4: event.target.value },
        { ...Inject[3], Inject_actual_4: event.target.value },
        { ...Inject[4], Inject_actual_4: event.target.value },
        { ...Inject[5], Inject_actual_4: event.target.value },
        { ...Inject[6], Inject_actual_4: event.target.value },
        { ...Inject[7], Inject_actual_4: event.target.value },
        { ...Inject[8], Inject_actual_4: event.target.value },
        { ...Inject[9], Inject_actual_4: event.target.value },
        { ...Inject[10], Inject_actual_4: event.target.value },
        { ...Inject[11], Inject_actual_4: event.target.value },
        { ...Inject[12], Inject_actual_4: event.target.value },
        { ...Inject[13], Inject_actual_4: event.target.value },
        { ...Inject[14], Inject_actual_4: event.target.value },
        { ...Inject[15], Inject_actual_4: event.target.value },
        { ...Inject[16], Inject_actual_4: event.target.value },
        { ...Inject[17], Inject_actual_4: event.target.value },
        { ...Inject[18], Inject_actual_4: event.target.value },
        { ...Inject[19], Inject_actual_4: event.target.value },
        { ...Inject[20], Inject_actual_4: event.target.value },
        { ...Inject[21], Inject_actual_4: event.target.value },
        { ...Inject[22], Inject_actual_4: event.target.value },
        { ...Inject[23], Inject_actual_4: event.target.value },
        { ...Inject[24], Inject_actual_4: event.target.value },
        { ...Inject[25], Inject_actual_4: event.target.value },
        { ...Inject[26], Inject_actual_4: event.target.value },
        { ...Inject[27], Inject_actual_4: event.target.value },
      ]);
    } else if (event.target.value === "IDEAL") {
      setTracking([
        {
          ...Tracking[0],
          Track_19_pm: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[1]
            .substring(0, 5),
        },
        { ...Tracking[1], Track_19_pm: event.target.value },
        { ...Tracking[2], Track_19_pm: event.target.value },
        { ...Tracking[3], Track_19_pm: event.target.value },
        { ...Tracking[4], Track_19_pm: event.target.value },
      ]);
      setHeat([
        { ...Heat[0], Heat_actual_4: event.target.value },
        { ...Heat[1], Heat_actual_4: event.target.value },
        { ...Heat[2], Heat_actual_4: event.target.value },
        { ...Heat[3], Heat_actual_4: event.target.value },
        { ...Heat[4], Heat_actual_4: event.target.value },
        { ...Heat[5], Heat_actual_4: event.target.value },
        { ...Heat[6], Heat_actual_4: event.target.value },
        { ...Heat[7], Heat_actual_4: event.target.value },
        { ...Heat[8], Heat_actual_4: event.target.value },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_4: event.target.value },
        { ...Inject[1], Inject_actual_4: event.target.value },
        { ...Inject[2], Inject_actual_4: event.target.value },
        { ...Inject[3], Inject_actual_4: event.target.value },
        { ...Inject[4], Inject_actual_4: event.target.value },
        { ...Inject[5], Inject_actual_4: event.target.value },
        { ...Inject[6], Inject_actual_4: event.target.value },
        { ...Inject[7], Inject_actual_4: event.target.value },
        { ...Inject[8], Inject_actual_4: event.target.value },
        { ...Inject[9], Inject_actual_4: event.target.value },
        { ...Inject[10], Inject_actual_4: event.target.value },
        { ...Inject[11], Inject_actual_4: event.target.value },
        { ...Inject[12], Inject_actual_4: event.target.value },
        { ...Inject[13], Inject_actual_4: event.target.value },
        { ...Inject[14], Inject_actual_4: event.target.value },
        { ...Inject[15], Inject_actual_4: event.target.value },
        { ...Inject[16], Inject_actual_4: event.target.value },
        { ...Inject[17], Inject_actual_4: event.target.value },
        { ...Inject[18], Inject_actual_4: event.target.value },
        { ...Inject[19], Inject_actual_4: event.target.value },
        { ...Inject[20], Inject_actual_4: event.target.value },
        { ...Inject[21], Inject_actual_4: event.target.value },
        { ...Inject[22], Inject_actual_4: event.target.value },
        { ...Inject[23], Inject_actual_4: event.target.value },
        { ...Inject[24], Inject_actual_4: event.target.value },
        { ...Inject[25], Inject_actual_4: event.target.value },
        { ...Inject[26], Inject_actual_4: event.target.value },
        { ...Inject[27], Inject_actual_4: event.target.value },
      ]);
    } else {
      GetRecord(
        {
          _keyword_: "std_mld_params_by_part_pms",
          PrtNme: event.target.value,
          McNo: PMS[0].Mcno,
        },
        setSmp
      ).then((res) => {
        setTracking([
          {
            ...Tracking[0],
            Track_19_pm: new Date(
              new Date().getTime() - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .split("T")[1]
              .substring(0, 5),
          },
          { ...Tracking[1], Track_19_pm: res[0].PrtNme },
          { ...Tracking[2], Track_19_pm: res[0].Colour },
          { ...Tracking[3], Track_19_pm: res[0].MatrialName },
          { ...Tracking[4], Track_19_pm: res[0].GrdNme },
        ]);
        setHeat([
          { ...Heat[0], Heat_actual_4: "" },
          { ...Heat[1], Heat_actual_4: "" },
          { ...Heat[2], Heat_actual_4: "" },
          { ...Heat[3], Heat_actual_4: "" },
          { ...Heat[4], Heat_actual_4: "" },
          { ...Heat[5], Heat_actual_4: "" },
          { ...Heat[6], Heat_actual_4: "" },
          { ...Heat[7], Heat_actual_4: "" },
          { ...Heat[8], Heat_actual_4: "" },
        ]);
        setInject([
          { ...Inject[0], Inject_actual_4: "" },
          { ...Inject[1], Inject_actual_4: "" },
          { ...Inject[2], Inject_actual_4: "" },
          { ...Inject[3], Inject_actual_4: "" },
          { ...Inject[4], Inject_actual_4: "" },
          { ...Inject[5], Inject_actual_4: "" },
          { ...Inject[6], Inject_actual_4: "" },
          { ...Inject[7], Inject_actual_4: "" },
          { ...Inject[8], Inject_actual_4: "" },
          { ...Inject[9], Inject_actual_4: "" },
          { ...Inject[10], Inject_actual_4: "" },
          { ...Inject[11], Inject_actual_4: "" },
          { ...Inject[12], Inject_actual_4: "" },
          { ...Inject[13], Inject_actual_4: "" },
          { ...Inject[14], Inject_actual_4: "" },
          { ...Inject[15], Inject_actual_4: "" },
          { ...Inject[16], Inject_actual_4: "" },
          { ...Inject[17], Inject_actual_4: "" },
          { ...Inject[18], Inject_actual_4: "" },
          { ...Inject[19], Inject_actual_4: "" },
          { ...Inject[20], Inject_actual_4: "" },
          { ...Inject[21], Inject_actual_4: "" },
          { ...Inject[22], Inject_actual_4: "" },
          { ...Inject[23], Inject_actual_4: "" },
          { ...Inject[24], Inject_actual_4: "" },
          { ...Inject[25], Inject_actual_4: "" },
          { ...Inject[26], Inject_actual_4: "" },
          { ...Inject[27], Inject_actual_4: "" },
        ]);
      });
    }
  }
  function HandlePart23Pm(event) {
    if (event.target.value === "MOULD CHANGE") {
      setInsert(["Track_23_pm", "Heat_actual_5", "Inject_actual_5"]);
      setTracking([
        {
          ...Tracking[0],
          Track_23_pm: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[1]
            .substring(0, 5),
        },
        { ...Tracking[1], Track_23_pm: event.target.value },
        { ...Tracking[2], Track_23_pm: event.target.value },
        { ...Tracking[3], Track_23_pm: event.target.value },
        { ...Tracking[4], Track_23_pm: event.target.value },
      ]);
      setHeat([
        { ...Heat[0], Heat_actual_5: event.target.value },
        { ...Heat[1], Heat_actual_5: event.target.value },
        { ...Heat[2], Heat_actual_5: event.target.value },
        { ...Heat[3], Heat_actual_5: event.target.value },
        { ...Heat[4], Heat_actual_5: event.target.value },
        { ...Heat[5], Heat_actual_5: event.target.value },
        { ...Heat[6], Heat_actual_5: event.target.value },
        { ...Heat[7], Heat_actual_5: event.target.value },
        { ...Heat[8], Heat_actual_5: event.target.value },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_5: event.target.value },
        { ...Inject[1], Inject_actual_5: event.target.value },
        { ...Inject[2], Inject_actual_5: event.target.value },
        { ...Inject[3], Inject_actual_5: event.target.value },
        { ...Inject[4], Inject_actual_5: event.target.value },
        { ...Inject[5], Inject_actual_5: event.target.value },
        { ...Inject[6], Inject_actual_5: event.target.value },
        { ...Inject[7], Inject_actual_5: event.target.value },
        { ...Inject[8], Inject_actual_5: event.target.value },
        { ...Inject[9], Inject_actual_5: event.target.value },
        { ...Inject[10], Inject_actual_5: event.target.value },
        { ...Inject[11], Inject_actual_5: event.target.value },
        { ...Inject[12], Inject_actual_5: event.target.value },
        { ...Inject[13], Inject_actual_5: event.target.value },
        { ...Inject[14], Inject_actual_5: event.target.value },
        { ...Inject[15], Inject_actual_5: event.target.value },
        { ...Inject[16], Inject_actual_5: event.target.value },
        { ...Inject[17], Inject_actual_5: event.target.value },
        { ...Inject[18], Inject_actual_5: event.target.value },
        { ...Inject[19], Inject_actual_5: event.target.value },
        { ...Inject[20], Inject_actual_5: event.target.value },
        { ...Inject[21], Inject_actual_5: event.target.value },
        { ...Inject[22], Inject_actual_5: event.target.value },
        { ...Inject[23], Inject_actual_5: event.target.value },
        { ...Inject[24], Inject_actual_5: event.target.value },
        { ...Inject[25], Inject_actual_5: event.target.value },
        { ...Inject[26], Inject_actual_5: event.target.value },
        { ...Inject[27], Inject_actual_5: event.target.value },
      ]);
    } else if (event.target.value === "IDEAL") {
      setTracking([
        {
          ...Tracking[0],
          Track_23_pm: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[1]
            .substring(0, 5),
        },
        { ...Tracking[1], Track_23_pm: event.target.value },
        { ...Tracking[2], Track_23_pm: event.target.value },
        { ...Tracking[3], Track_23_pm: event.target.value },
        { ...Tracking[4], Track_23_pm: event.target.value },
      ]);
      setHeat([
        { ...Heat[0], Heat_actual_5: event.target.value },
        { ...Heat[1], Heat_actual_5: event.target.value },
        { ...Heat[2], Heat_actual_5: event.target.value },
        { ...Heat[3], Heat_actual_5: event.target.value },
        { ...Heat[4], Heat_actual_5: event.target.value },
        { ...Heat[5], Heat_actual_5: event.target.value },
        { ...Heat[6], Heat_actual_5: event.target.value },
        { ...Heat[7], Heat_actual_5: event.target.value },
        { ...Heat[8], Heat_actual_5: event.target.value },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_5: event.target.value },
        { ...Inject[1], Inject_actual_5: event.target.value },
        { ...Inject[2], Inject_actual_5: event.target.value },
        { ...Inject[3], Inject_actual_5: event.target.value },
        { ...Inject[4], Inject_actual_5: event.target.value },
        { ...Inject[5], Inject_actual_5: event.target.value },
        { ...Inject[6], Inject_actual_5: event.target.value },
        { ...Inject[7], Inject_actual_5: event.target.value },
        { ...Inject[8], Inject_actual_5: event.target.value },
        { ...Inject[9], Inject_actual_5: event.target.value },
        { ...Inject[10], Inject_actual_5: event.target.value },
        { ...Inject[11], Inject_actual_5: event.target.value },
        { ...Inject[12], Inject_actual_5: event.target.value },
        { ...Inject[13], Inject_actual_5: event.target.value },
        { ...Inject[14], Inject_actual_5: event.target.value },
        { ...Inject[15], Inject_actual_5: event.target.value },
        { ...Inject[16], Inject_actual_5: event.target.value },
        { ...Inject[17], Inject_actual_5: event.target.value },
        { ...Inject[18], Inject_actual_5: event.target.value },
        { ...Inject[19], Inject_actual_5: event.target.value },
        { ...Inject[20], Inject_actual_5: event.target.value },
        { ...Inject[21], Inject_actual_5: event.target.value },
        { ...Inject[22], Inject_actual_5: event.target.value },
        { ...Inject[23], Inject_actual_5: event.target.value },
        { ...Inject[24], Inject_actual_5: event.target.value },
        { ...Inject[25], Inject_actual_5: event.target.value },
        { ...Inject[26], Inject_actual_5: event.target.value },
        { ...Inject[27], Inject_actual_5: event.target.value },
      ]);
    } else {
      GetRecord(
        {
          _keyword_: "std_mld_params_by_part_pms",
          PrtNme: event.target.value,
          McNo: PMS[0].Mcno,
        },
        setSmp
      ).then((res) => {
        setTracking([
          {
            ...Tracking[0],
            Track_23_pm: new Date(
              new Date().getTime() - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .split("T")[1]
              .substring(0, 5),
          },
          { ...Tracking[1], Track_23_pm: res[0].PrtNme },
          { ...Tracking[2], Track_23_pm: res[0].Colour },
          { ...Tracking[3], Track_23_pm: res[0].MatrialName },
          { ...Tracking[4], Track_23_pm: res[0].GrdNme },
        ]);
        setHeat([
          { ...Heat[0], Heat_actual_5: "" },
          { ...Heat[1], Heat_actual_5: "" },
          { ...Heat[2], Heat_actual_5: "" },
          { ...Heat[3], Heat_actual_5: "" },
          { ...Heat[4], Heat_actual_5: "" },
          { ...Heat[5], Heat_actual_5: "" },
          { ...Heat[6], Heat_actual_5: "" },
          { ...Heat[7], Heat_actual_5: "" },
          { ...Heat[8], Heat_actual_5: "" },
        ]);
        setInject([
          { ...Inject[0], Inject_actual_5: "" },
          { ...Inject[1], Inject_actual_5: "" },
          { ...Inject[2], Inject_actual_5: "" },
          { ...Inject[3], Inject_actual_5: "" },
          { ...Inject[4], Inject_actual_5: "" },
          { ...Inject[5], Inject_actual_5: "" },
          { ...Inject[6], Inject_actual_5: "" },
          { ...Inject[7], Inject_actual_5: "" },
          { ...Inject[8], Inject_actual_5: "" },
          { ...Inject[9], Inject_actual_5: "" },
          { ...Inject[10], Inject_actual_5: "" },
          { ...Inject[11], Inject_actual_5: "" },
          { ...Inject[12], Inject_actual_5: "" },
          { ...Inject[13], Inject_actual_5: "" },
          { ...Inject[14], Inject_actual_5: "" },
          { ...Inject[15], Inject_actual_5: "" },
          { ...Inject[16], Inject_actual_5: "" },
          { ...Inject[17], Inject_actual_5: "" },
          { ...Inject[18], Inject_actual_5: "" },
          { ...Inject[19], Inject_actual_5: "" },
          { ...Inject[20], Inject_actual_5: "" },
          { ...Inject[21], Inject_actual_5: "" },
          { ...Inject[22], Inject_actual_5: "" },
          { ...Inject[23], Inject_actual_5: "" },
          { ...Inject[24], Inject_actual_5: "" },
          { ...Inject[25], Inject_actual_5: "" },
          { ...Inject[26], Inject_actual_5: "" },
          { ...Inject[27], Inject_actual_5: "" },
        ]);
      });
    }
  }
  function HandlePart3Am(event) {
    if (event.target.value === "MOULD CHANGE") {
      setInsert(["Track_3_am", "Heat_actual_6", "Inject_actual_6"]);
      setTracking([
        {
          ...Tracking[0],
          Track_3_am: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[1]
            .substring(0, 5),
        },
        { ...Tracking[1], Track_3_am: event.target.value },
        { ...Tracking[2], Track_3_am: event.target.value },
        { ...Tracking[3], Track_3_am: event.target.value },
        { ...Tracking[4], Track_3_am: event.target.value },
      ]);
      setHeat([
        { ...Heat[0], Heat_actual_6: event.target.value },
        { ...Heat[1], Heat_actual_6: event.target.value },
        { ...Heat[2], Heat_actual_6: event.target.value },
        { ...Heat[3], Heat_actual_6: event.target.value },
        { ...Heat[4], Heat_actual_6: event.target.value },
        { ...Heat[5], Heat_actual_6: event.target.value },
        { ...Heat[6], Heat_actual_6: event.target.value },
        { ...Heat[7], Heat_actual_6: event.target.value },
        { ...Heat[8], Heat_actual_6: event.target.value },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_6: event.target.value },
        { ...Inject[1], Inject_actual_6: event.target.value },
        { ...Inject[2], Inject_actual_6: event.target.value },
        { ...Inject[3], Inject_actual_6: event.target.value },
        { ...Inject[4], Inject_actual_6: event.target.value },
        { ...Inject[5], Inject_actual_6: event.target.value },
        { ...Inject[6], Inject_actual_6: event.target.value },
        { ...Inject[7], Inject_actual_6: event.target.value },
        { ...Inject[8], Inject_actual_6: event.target.value },
        { ...Inject[9], Inject_actual_6: event.target.value },
        { ...Inject[10], Inject_actual_6: event.target.value },
        { ...Inject[11], Inject_actual_6: event.target.value },
        { ...Inject[12], Inject_actual_6: event.target.value },
        { ...Inject[13], Inject_actual_6: event.target.value },
        { ...Inject[14], Inject_actual_6: event.target.value },
        { ...Inject[15], Inject_actual_6: event.target.value },
        { ...Inject[16], Inject_actual_6: event.target.value },
        { ...Inject[17], Inject_actual_6: event.target.value },
        { ...Inject[18], Inject_actual_6: event.target.value },
        { ...Inject[19], Inject_actual_6: event.target.value },
        { ...Inject[20], Inject_actual_6: event.target.value },
        { ...Inject[21], Inject_actual_6: event.target.value },
        { ...Inject[22], Inject_actual_6: event.target.value },
        { ...Inject[23], Inject_actual_6: event.target.value },
        { ...Inject[24], Inject_actual_6: event.target.value },
        { ...Inject[25], Inject_actual_6: event.target.value },
        { ...Inject[26], Inject_actual_6: event.target.value },
        { ...Inject[27], Inject_actual_6: event.target.value },
      ]);
    } else if (event.target.value === "IDEAL") {
      setTracking([
        {
          ...Tracking[0],
          Track_3_am: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[1]
            .substring(0, 5),
        },
        { ...Tracking[1], Track_3_am: event.target.value },
        { ...Tracking[2], Track_3_am: event.target.value },
        { ...Tracking[3], Track_3_am: event.target.value },
        { ...Tracking[4], Track_3_am: event.target.value },
      ]);
      setHeat([
        { ...Heat[0], Heat_actual_6: event.target.value },
        { ...Heat[1], Heat_actual_6: event.target.value },
        { ...Heat[2], Heat_actual_6: event.target.value },
        { ...Heat[3], Heat_actual_6: event.target.value },
        { ...Heat[4], Heat_actual_6: event.target.value },
        { ...Heat[5], Heat_actual_6: event.target.value },
        { ...Heat[6], Heat_actual_6: event.target.value },
        { ...Heat[7], Heat_actual_6: event.target.value },
        { ...Heat[8], Heat_actual_6: event.target.value },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_6: event.target.value },
        { ...Inject[1], Inject_actual_6: event.target.value },
        { ...Inject[2], Inject_actual_6: event.target.value },
        { ...Inject[3], Inject_actual_6: event.target.value },
        { ...Inject[4], Inject_actual_6: event.target.value },
        { ...Inject[5], Inject_actual_6: event.target.value },
        { ...Inject[6], Inject_actual_6: event.target.value },
        { ...Inject[7], Inject_actual_6: event.target.value },
        { ...Inject[8], Inject_actual_6: event.target.value },
        { ...Inject[9], Inject_actual_6: event.target.value },
        { ...Inject[10], Inject_actual_6: event.target.value },
        { ...Inject[11], Inject_actual_6: event.target.value },
        { ...Inject[12], Inject_actual_6: event.target.value },
        { ...Inject[13], Inject_actual_6: event.target.value },
        { ...Inject[14], Inject_actual_6: event.target.value },
        { ...Inject[15], Inject_actual_6: event.target.value },
        { ...Inject[16], Inject_actual_6: event.target.value },
        { ...Inject[17], Inject_actual_6: event.target.value },
        { ...Inject[18], Inject_actual_6: event.target.value },
        { ...Inject[19], Inject_actual_6: event.target.value },
        { ...Inject[20], Inject_actual_6: event.target.value },
        { ...Inject[21], Inject_actual_6: event.target.value },
        { ...Inject[22], Inject_actual_6: event.target.value },
        { ...Inject[23], Inject_actual_6: event.target.value },
        { ...Inject[24], Inject_actual_6: event.target.value },
        { ...Inject[25], Inject_actual_6: event.target.value },
        { ...Inject[26], Inject_actual_6: event.target.value },
        { ...Inject[27], Inject_actual_6: event.target.value },
      ]);
    } else {
      GetRecord(
        {
          _keyword_: "std_mld_params_by_part_pms",
          PrtNme: event.target.value,
          McNo: PMS[0].Mcno,
        },
        setSmp
      ).then((res) => {
        setTracking([
          {
            ...Tracking[0],
            Track_3_am: new Date(
              new Date().getTime() - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .split("T")[1]
              .substring(0, 5),
          },
          { ...Tracking[1], Track_3_am: res[0].PrtNme },
          { ...Tracking[2], Track_3_am: res[0].Colour },
          { ...Tracking[3], Track_3_am: res[0].MatrialName },
          { ...Tracking[4], Track_3_am: res[0].GrdNme },
        ]);
        setHeat([
          { ...Heat[0], Heat_actual_6: "" },
          { ...Heat[1], Heat_actual_6: "" },
          { ...Heat[2], Heat_actual_6: "" },
          { ...Heat[3], Heat_actual_6: "" },
          { ...Heat[4], Heat_actual_6: "" },
          { ...Heat[5], Heat_actual_6: "" },
          { ...Heat[6], Heat_actual_6: "" },
          { ...Heat[7], Heat_actual_6: "" },
          { ...Heat[8], Heat_actual_6: "" },
        ]);
        setInject([
          { ...Inject[0], Inject_actual_6: "" },
          { ...Inject[1], Inject_actual_6: "" },
          { ...Inject[2], Inject_actual_6: "" },
          { ...Inject[3], Inject_actual_6: "" },
          { ...Inject[4], Inject_actual_6: "" },
          { ...Inject[5], Inject_actual_6: "" },
          { ...Inject[6], Inject_actual_6: "" },
          { ...Inject[7], Inject_actual_6: "" },
          { ...Inject[8], Inject_actual_6: "" },
          { ...Inject[9], Inject_actual_6: "" },
          { ...Inject[10], Inject_actual_6: "" },
          { ...Inject[11], Inject_actual_6: "" },
          { ...Inject[12], Inject_actual_6: "" },
          { ...Inject[13], Inject_actual_6: "" },
          { ...Inject[14], Inject_actual_6: "" },
          { ...Inject[15], Inject_actual_6: "" },
          { ...Inject[16], Inject_actual_6: "" },
          { ...Inject[17], Inject_actual_6: "" },
          { ...Inject[18], Inject_actual_6: "" },
          { ...Inject[19], Inject_actual_6: "" },
          { ...Inject[20], Inject_actual_6: "" },
          { ...Inject[21], Inject_actual_6: "" },
          { ...Inject[22], Inject_actual_6: "" },
          { ...Inject[23], Inject_actual_6: "" },
          { ...Inject[24], Inject_actual_6: "" },
          { ...Inject[25], Inject_actual_6: "" },
          { ...Inject[26], Inject_actual_6: "" },
          { ...Inject[27], Inject_actual_6: "" },
        ]);
      });
    }
  }

  // get current data and time
  function GetCurrentTime() {
    let time = new Date(
      new Date().getTime() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[1]
      .substring(0, 5);
    // console.log("Time --- ", time);
    setDisable({
      ...Disable,
      Hour1: !(time > "06:30" && time < "08:30"),
      Hour2: !(time > "10:30" && time < "12:30"),
      Hour3: !(time > "14:30" && time < "16:30"),
      Hour4: !(time > "18:30" && time < "20:30"),
      Hour5: !(time > "22:30"),
      Hour6: !(time > "00:00" && time < "04:30"),
      // Hour1:!(true)
      // ,Hour2:!(true)
      // ,Hour3:!(true)
      // ,Hour4:!(true)
      // ,Hour5:!(true)
      // ,Hour6:!(true)
    });
  }

  // Get Traching Data
  async function gettracking() {
    console.log("GET Tracking ---------------------------------------");
    // GetCurrentTime();
    let result = await GetRecord(
      { _keyword_: "Get_Tracking_by_PmsID", Pms_id: PMS[0].Id },
      setTracking
    );
    if (result === "NoRecordFound") {
      setTracking([
        { ...initTracking, Time_to_be_updated: "Actual updated time" },
        { ...initTracking, Time_to_be_updated: "Part name" },
        { ...initTracking, Time_to_be_updated: "Colour" },
        { ...initTracking, Time_to_be_updated: "Material used" },
        { ...initTracking, Time_to_be_updated: "Material grade" },
      ]);
    } else {
      console.log(Part);
    }
    // Get Heat Parameter Data
    result = await GetRecord(
      { _keyword_: "Get_HeatParams_by_PmsId", Pms_id: PMS[0].Id },
      setHeat
    );
    if (result === "NoRecordFound") {
      // console.log("setheat in gettracking");
      setHeat([
        { ...initHeat, Heat_sl: "1" },
        { ...initHeat, Heat_sl: "2" },
        { ...initHeat, Heat_sl: "3" },
        { ...initHeat, Heat_sl: "4" },
        { ...initHeat, Heat_sl: "5" },
        { ...initHeat, Heat_sl: "6" },
        { ...initHeat, Heat_sl: "7" },
        { ...initHeat, Heat_sl: "8" },
        { ...initHeat, Heat_sl: "9" },
      ]);
    }
    // Get Inject parameter data
    result = await GetRecord(
      { _keyword_: "Get_inject_by_PmsId", Pms_id: PMS[0].Id },
      setInject
    );
    if (result === "NoRecordFound") {
      setInject([
        {
          ...initInject,
          Inject_sl: "1",
          Inject_desc: "Cycle time",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "2",
          Inject_desc: "Cooling time",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "3",
          Inject_desc: "Injection time - 1",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "4",
          Inject_desc: "Injection time - 2",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "5",
          Inject_desc: "Injection time - 3",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "6",
          Inject_desc: "Injection time - 4",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "7",
          Inject_desc: "Injection-Pressure-1",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "8",
          Inject_desc: "Injection-Pressure-2",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "9",
          Inject_desc: "Injection-Pressure-3",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "10",
          Inject_desc: "Injection-Pressure-4",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "11",
          Inject_desc: "Injection speed 1",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "12",
          Inject_desc: "Injection speed 2",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "13",
          Inject_desc: "Injection speed 3",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "14",
          Inject_desc: "Injection speed 4",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "15",
          Inject_desc: "Holding pressure-1",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "16",
          Inject_desc: "Holding pressure-2",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "17",
          Inject_desc: "Holding pressure-3",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "18",
          Inject_desc: "Holding pressure-4",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "19",
          Inject_desc: "Holding speed-1",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "20",
          Inject_desc: "Holding speed-2",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "21",
          Inject_desc: "Holding speed-3",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "22",
          Inject_desc: "Holding speed-4",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "23",
          Inject_desc: "Holding time-1",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "24",
          Inject_desc: "Holding time-2",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "25",
          Inject_desc: "Holding time-3",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "26",
          Inject_desc: "Holding time-4",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "27",
          Inject_desc: "Shot size",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "28",
          Inject_desc: "Cushion",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
      ]);
    }
  }

  useEffect(() => {
    GetRecord({ _keyword_: "Get_SMP_MachineNumber" }, setMachine);
    GetRecord({ _keyword_: "emp_details" }, setempDetails);
    setDisable({
      ...Disable,
      Hour1: true,
      Hour2: true,
      Hour3: true,
      Hour4: true,
      Hour5: true,
      Hour6: true,
    });
    document.title = "Process Monitoring System";
  }, []);

  useEffect(() => {
    GetRecord(
      {
        _keyword_: "Get_PartName_basedOn_MachineNo_form_SMP",
        Mcno: PMS[0].Mcno,
      },
      setPart
    ).then(async (res) => {
      let result = await GetRecord(
        { _keyword_: "Get_Tracking_by_PmsID", Pms_id: PMS[0].Id },
        setTracking
      );
      if (result === "NoRecordFound") {
        setTracking([
          { ...initTracking, Time_to_be_updated: "Actual updated time" },
          { ...initTracking, Time_to_be_updated: "Part name" },
          { ...initTracking, Time_to_be_updated: "Colour" },
          { ...initTracking, Time_to_be_updated: "Material used" },
          { ...initTracking, Time_to_be_updated: "Material grade" },
        ]);
      } else {
        let trackvalue =
          (!Disable.Hour2 && result[1].Track_7_am) ||
          (!Disable.Hour3 && result[1].Track_11_am) ||
          (!Disable.Hour4 && result[1].Track_15_pm) ||
          (!Disable.Hour5 && result[1].Track_19_pm) ||
          (!Disable.Hour6 && result[1].Track_23_pm);
        if (trackvalue !== "MOULD CHANGE") {
          console.log("Not Mould");
          if (trackvalue !== "IDEAL") {
            console.log("Not ideal");
            console.log(
              trackvalue + "VAlue 3 ",
              res[0].PrtNme && res.filter((item) => item.PrtNme === trackvalue)
            );
            trackvalue
              ? setPart([res.filter((item) => item.PrtNme === trackvalue)[0]])
              : setPart(res);
          } else {
            console.log("Its IDEAL");
            let trackvalue =
              (result[1].Track_7_am !== "IDEAL" && result[1].Track_7_am) ||
              (result[1].Track_11_am !== "IDEAL" && result[1].Track_11_am) ||
              (result[1].Track_15_pm !== "IDEAL" && result[1].Track_15_pm) ||
              (result[1].Track_19_pm !== "IDEAL" && result[1].Track_19_pm) ||
              (result[1].Track_23_pm !== "IDEAL" && result[1].Track_23_pm);
            console.log(trackvalue);
            trackvalue !== "" && trackvalue !== false
              ? setPart([res.filter((item) => item.PrtNme === trackvalue)[0]])
              : setPart(res);
          }
        }
      }
    });
  }, [PMS[0].Mcno]);

  useEffect(() => {
    NewInsert ? handleInsertcreate() : gettracking();
  }, [PMS[0].Id]);

  useEffect(() => {
    setInject((data) => {
      let Value = [...Inject];
      for (let index = 0; index < Inject.length; index++) {
        Value[index] = {
          ...Inject[index],
          Inject_smp_data: Smp[0][injectvalue[index].key],
          Inject_smp_set: Smp[0][injectvalue[index].value],
        };
      }
      return Value;
    });
    setHeat((data) => {
      let Value = [...Heat];
      for (let index = 0; index < Heat.length; index++) {
        Value[index] = {
          ...Heat[index],
          Heat_smp_data: Smp[0][Heatvalue[index].key],
          Heat_smp_set: Smp[0][Heatvalue[index].value],
        };
      }
      return Value;
    });
    setPMS([
      {
        ...PMS[0],
        PartWeight1: Smp[0].part_weight_1,
        PartWeight2: Smp[0].part_weight_2,
        PartWeightMinus1: Smp[0].part_weight_set_minus_1,
        PartWeightMinus2: Smp[0].part_weight_set_minus_2,
        PartWeightPlus1: Smp[0].part_weight_set_plus_1,
        PartWeightPlus2: Smp[0].part_weight_set_plus_2,
        Core: Smp[0].Core,
        CoreSet: Smp[0].CoreSet,
        Cavity: Smp[0].Cavity,
        CavSet: Smp[0].CavSet,
      },
    ]);
  }, [Smp]);

  // useEffect(() => {
  //   console.log("EmpName update - ", EmpName);
  // }, [EmpName]);

  // useEffect(() => {
  //   console.log("PMS update - ", PMS);
  // }, [PMS]);

  // useEffect(() => {
  //   console.log("Tracking update - ", Tracking);
  // }, [Tracking]);

  // useEffect(() => {
  //     console.log("Heat update - ", Heat);

  //   }, [Heat]);
  //   useEffect(() => {
  //     console.log("submit update - ", submit);

  //   }, [submit]);

  // useEffect(() => {
  //   console.log("Inject update - ", Inject);
  // }, [Inject]);

  const handleCreate = (e) => {
    const currentDate = new Date().toISOString().split("T")[0];
    e.preventDefault();
    // const isSubmissionSuccessful = true;
    // console.log(PMS);
    const data1 = {
      data: PMS,
      _keyword_: "pro_mon_sys_parent",
      secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
    };
    axios
      .post(
        `http://${API_URL}/GenericTransactionService/processTransaction`,
        data1
      )
      .then((response1) => {
        // console.log("Pms respornce",response1);
        let Pms_id = response1.data.ROWID;
        setPMS([initMachine]);

        // submit heat
        Heat[0].Pms_id = Pms_id;
        Heat[1].Pms_id = Pms_id;
        Heat[2].Pms_id = Pms_id;
        Heat[3].Pms_id = Pms_id;
        Heat[4].Pms_id = Pms_id;
        Heat[5].Pms_id = Pms_id;
        Heat[6].Pms_id = Pms_id;
        Heat[7].Pms_id = Pms_id;
        Heat[8].Pms_id = Pms_id;
        axios
          .post(
            `http://${API_URL}/GenericTransactionService/processTransaction`,
            {
              data: Heat,
              _keyword_: "pro_mon_sys_heat_parameter",
              secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
            }
          )
          .then((res) => { });

        // submit Tracking
        Tracking[0].Pms_id = Pms_id;
        Tracking[1].Pms_id = Pms_id;
        Tracking[2].Pms_id = Pms_id;
        Tracking[3].Pms_id = Pms_id;
        Tracking[4].Pms_id = Pms_id;
        axios
          .post(
            `http://${API_URL}/GenericTransactionService/processTransaction`,
            {
              data: Tracking,
              _keyword_: "pro_mon_sys_tracking_machine",
              secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
            }
          )
          .then((res) => {
            // console.log(Tracking,"Tracking res",res);
          });

        // submit Inject
        Inject[0].Pms_id = Pms_id;
        Inject[1].Pms_id = Pms_id;
        Inject[2].Pms_id = Pms_id;
        Inject[3].Pms_id = Pms_id;
        Inject[4].Pms_id = Pms_id;
        Inject[5].Pms_id = Pms_id;
        Inject[6].Pms_id = Pms_id;
        Inject[7].Pms_id = Pms_id;
        Inject[8].Pms_id = Pms_id;
        Inject[9].Pms_id = Pms_id;
        Inject[10].Pms_id = Pms_id;
        Inject[11].Pms_id = Pms_id;
        Inject[12].Pms_id = Pms_id;
        Inject[13].Pms_id = Pms_id;
        Inject[14].Pms_id = Pms_id;
        Inject[15].Pms_id = Pms_id;
        Inject[16].Pms_id = Pms_id;
        Inject[17].Pms_id = Pms_id;
        Inject[18].Pms_id = Pms_id;
        Inject[19].Pms_id = Pms_id;
        Inject[20].Pms_id = Pms_id;
        Inject[21].Pms_id = Pms_id;
        Inject[22].Pms_id = Pms_id;
        Inject[23].Pms_id = Pms_id;
        Inject[24].Pms_id = Pms_id;
        Inject[25].Pms_id = Pms_id;
        Inject[26].Pms_id = Pms_id;
        Inject[27].Pms_id = Pms_id;
        axios
          .post(
            `http://${API_URL}/GenericTransactionService/processTransaction`,
            {
              data: Inject,
              _keyword_: "pro_mon_sys_inject_parameter",
              secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
            }
          )
          .then((res) => {
            // console.log(Inject,"Inject res",res);
            // setsubmit(false)
          });
        gettracking();

        setEmpName(initEmpName);

        // setHeat([
        //   { ...Heat[0], Pms_id: Pms_id },
        //   { ...Heat[1], Pms_id: Pms_id },
        //   { ...Heat[2], Pms_id: Pms_id },
        //   { ...Heat[3], Pms_id: Pms_id },
        //   { ...Heat[4], Pms_id: Pms_id },
        //   { ...Heat[5], Pms_id: Pms_id },
        //   { ...Heat[6], Pms_id: Pms_id },
        //   { ...Heat[7], Pms_id: Pms_id },
        //   { ...Heat[8], Pms_id: Pms_id },
        // ]);
        // setTracking([
        //   { ...Tracking[0], Pms_id: Pms_id },
        //   { ...Tracking[1], Pms_id: Pms_id },
        //   { ...Tracking[2], Pms_id: Pms_id },
        //   { ...Tracking[3], Pms_id: Pms_id },
        //   { ...Tracking[4], Pms_id: Pms_id },
        // ]);
        // setInject([
        //   { ...Inject[0], Pms_id: Pms_id },
        //   { ...Inject[1], Pms_id: Pms_id },
        //   { ...Inject[2], Pms_id: Pms_id },
        //   { ...Inject[3], Pms_id: Pms_id },
        //   { ...Inject[4], Pms_id: Pms_id },
        //   { ...Inject[5], Pms_id: Pms_id },
        //   { ...Inject[6], Pms_id: Pms_id },
        //   { ...Inject[7], Pms_id: Pms_id },
        //   { ...Inject[8], Pms_id: Pms_id },
        //   { ...Inject[9], Pms_id: Pms_id },
        //   { ...Inject[10], Pms_id: Pms_id },
        //   { ...Inject[11], Pms_id: Pms_id },
        //   { ...Inject[12], Pms_id: Pms_id },
        //   { ...Inject[13], Pms_id: Pms_id },
        //   { ...Inject[14], Pms_id: Pms_id },
        //   { ...Inject[15], Pms_id: Pms_id },
        //   { ...Inject[16], Pms_id: Pms_id },
        //   { ...Inject[17], Pms_id: Pms_id },
        //   { ...Inject[18], Pms_id: Pms_id },
        //   { ...Inject[19], Pms_id: Pms_id },
        //   { ...Inject[20], Pms_id: Pms_id },
        //   { ...Inject[21], Pms_id: Pms_id },
        //   { ...Inject[22], Pms_id: Pms_id },
        //   { ...Inject[23], Pms_id: Pms_id },
        //   { ...Inject[24], Pms_id: Pms_id },
        //   { ...Inject[25], Pms_id: Pms_id },
        //   { ...Inject[26], Pms_id: Pms_id },
        //   { ...Inject[27], Pms_id: Pms_id },
        // ]);
        // setsubmit(true);

        (PMS[0].ShiftInchargeShiftA && !Disable.Hour1) ||
          (!Disable.Hour2 &&
            CreateApproval({
              RequestedBy: localStorage.getItem("empId"),
              Approver: PMS[0].ShiftInchargeShiftA,
              Level: 1,
              Status: "Waiting for Approval",
              TableName: "Pms",
              TableID: PMS[0].Id,
              Description: !Disable.Hour1
                ? "The PMS Report Has been Created By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Created By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Created By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Created By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Created By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Created By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              CreatedBy: localStorage.getItem("empId"),
            }));
        (PMS[0].ShiftInchargeShiftA && !Disable.Hour1) ||
          (!Disable.Hour2 &&
            setMessage({
              NoteFrom: localStorage.getItem("empId"),
              NoteTo: PMS[0].ShiftInchargeShiftA,
              NoteMessage: !Disable.Hour1
                ? "The PMS Report Has been Created By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Created By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Created By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Created By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Created By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Created By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              NoteRecordId: PMS[0].Id,
              TableName: "Pms",
            }));
        (PMS[0].ShiftInchargeShiftB && !Disable.Hour3) ||
          (!Disable.Hour4 &&
            CreateApproval({
              RequestedBy: localStorage.getItem("empId"),
              Approver: PMS[0].ShiftInchargeShiftB,
              Level: 1,
              Status: "Waiting for Approval",
              TableName: "Pms",
              TableID: PMS[0].Id,
              Description: !Disable.Hour1
                ? "The PMS Report Has been Created By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Created By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Created By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Created By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Created By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Created By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              CreatedBy: localStorage.getItem("empId"),
            }));
        (PMS[0].ShiftInchargeShiftB && !Disable.Hour3) ||
          (!Disable.Hour4 &&
            setMessage({
              NoteFrom: localStorage.getItem("empId"),
              NoteTo: PMS[0].ShiftInchargeShiftB,
              NoteMessage: !Disable.Hour1
                ? "The PMS Report Has been Created By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Created By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Created By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Created By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Created By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Created By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              NoteRecordId: PMS[0].Id,
              TableName: "Pms",
            }));
        (PMS[0].ShiftInchargeShiftC && !Disable.Hour5) ||
          (!Disable.Hour6 &&
            CreateApproval({
              RequestedBy: localStorage.getItem("empId"),
              Approver: PMS[0].ShiftInchargeShiftC,
              Level: 1,
              Status: "Waiting for Approval",
              TableName: "Pms",
              TableID: PMS[0].Id,
              Description: !Disable.Hour1
                ? "The PMS Report Has been Created By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Created By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Created By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Created By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Created By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Created By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              CreatedBy: localStorage.getItem("empId"),
            }));
        (PMS[0].ShiftInchargeShiftC && !Disable.Hour5) ||
          (!Disable.Hour6 &&
            setMessage({
              NoteFrom: localStorage.getItem("empId"),
              NoteTo: PMS[0].ShiftInchargeShiftC,
              NoteMessage: !Disable.Hour1
                ? "The PMS Report Has been Created By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Created By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Created By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Created By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Created By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Created By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              NoteRecordId: PMS[0].Id,
              TableName: "Pms",
            }));
        (PMS[0].LineInspecQaShiftA && !Disable.Hour1) ||
          (!Disable.Hour2 &&
            CreateApproval({
              RequestedBy: localStorage.getItem("empId"),
              Approver: PMS[0].LineInspecQaShiftA,
              Level: 1,
              Status: "Waiting for Approval",
              TableName: "Pms",
              TableID: PMS[0].Id,
              Description: !Disable.Hour1
                ? "The PMS Report Has been Created By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Created By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Created By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Created By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Created By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Created By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              CreatedBy: localStorage.getItem("empId"),
            }));
        (PMS[0].LineInspecQaShiftA && !Disable.Hour1) ||
          (!Disable.Hour2 &&
            setMessage({
              NoteFrom: localStorage.getItem("empId"),
              NoteTo: PMS[0].LineInspecQaShiftA,
              NoteMessage: !Disable.Hour1
                ? "The PMS Report Has been Created By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Created By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Created By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Created By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Created By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Created By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              NoteRecordId: PMS[0].Id,
              TableName: "Pms",
            }));
        (PMS[0].LineInspecQaShiftB && !Disable.Hour3) ||
          (!Disable.Hour4 &&
            CreateApproval({
              RequestedBy: localStorage.getItem("empId"),
              Approver: PMS[0].LineInspecQaShiftB,
              Level: 1,
              Status: "Waiting for Approval",
              TableName: "Pms",
              TableID: PMS[0].Id,
              Description: !Disable.Hour1
                ? "The PMS Report Has been Created By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Created By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Created By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Created By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Created By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Created By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              CreatedBy: localStorage.getItem("empId"),
            }));
        (PMS[0].LineInspecQaShiftB && !Disable.Hour3) ||
          (!Disable.Hour4 &&
            setMessage({
              NoteFrom: localStorage.getItem("empId"),
              NoteTo: PMS[0].LineInspecQaShiftB,
              NoteMessage: !Disable.Hour1
                ? "The PMS Report Has been Created By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Created By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Created By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Created By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Created By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Created By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              NoteRecordId: PMS[0].Id,
              TableName: "Pms",
            }));
        (PMS[0].LineInspecQaShiftC && !Disable.Hour5) ||
          (!Disable.Hour6 &&
            CreateApproval({
              RequestedBy: localStorage.getItem("empId"),
              Approver: PMS[0].LineInspecQaShiftC,
              Level: 1,
              Status: "Waiting for Approval",
              TableName: "Pms",
              TableID: PMS[0].Id,
              Description: !Disable.Hour1
                ? "The PMS Report Has been Created By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Created By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Created By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Created By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Created By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Created By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              CreatedBy: localStorage.getItem("empId"),

            }));
        (PMS[0].LineInspecQaShiftC && !Disable.Hour5) ||
          (!Disable.Hour6 &&
            setMessage({
              NoteFrom: localStorage.getItem("empId"),
              NoteTo: PMS[0].LineInspecQaShiftC,
              NoteMessage: !Disable.Hour1
                ? "The PMS Report Has been Created By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Created By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Created By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Created By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Created By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Created By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              NoteRecordId: PMS[0].Id,
              TableName: "Pms",
            }));
      })
      .then((res) => {
        alert(
          "The PMS record for MacHine No - " +
          PMS[0].Mcno +
          ", Has Been Updated"
        );
        gettracking();
        setPMS([initMachine]);
        setEmpName(initEmpName);
        window.location.reload();
      })
      .catch((error) => {
        // console.log(error);
        alert("Submission failed.");
        window.location.reload();
      });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const data1 = {
      data: PMS,
      _keyword_: "pro_mon_sys_parent",
      secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
    };
    axios
      .post(
        `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
        data1
      )
      .then((response1) => {
        // console.log("Heat res",PMS[0].Id);

        axios
          .post(
            `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
            {
              data: Heat,
              _keyword_: "pro_mon_sys_heat_parameter",
              secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
            }
          )
          .then((res) => {
            // console.log("Heat res",res);
          });
        axios
          .post(
            `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
            {
              data: Tracking,
              _keyword_: "pro_mon_sys_tracking_machine",
              secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
            }
          )
          .then((res) => {
            // console.log("Tracking res",res);
          });
        axios
          .post(
            `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
            {
              data: Inject,
              _keyword_: "pro_mon_sys_inject_parameter",
              secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
            }
          )
          .then((res) => {
            // console.log("Inject res",res);
          });
        // console.log("test 2 achive",PMS[0].ShiftInchargeShiftA && !Disable.Hour1 || !Disable.Hour2 );
        (PMS[0].ShiftInchargeShiftA && !Disable.Hour1) ||
          (!Disable.Hour2 &&
            CreateApproval({
              RequestedBy: localStorage.getItem("empId"),
              Approver: PMS[0].ShiftInchargeShiftA,
              Level: 1,
              Status: "Waiting for Approval",
              TableName: "Pms",
              TableID: PMS[0].Id,
              Description: !Disable.Hour1
                ? "The PMS Report Has been Updated By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Updated By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Updated By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Updated By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Updated By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Updated By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              CreatedBy: localStorage.getItem("empId"),

            }));
        (PMS[0].ShiftInchargeShiftA && !Disable.Hour1) ||
          (!Disable.Hour2 &&
            setMessage({
              NoteFrom: localStorage.getItem("empId"),
              NoteTo: PMS[0].ShiftInchargeShiftA,
              NoteMessage: !Disable.Hour1
                ? "The PMS Report Has been Updated By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Updated By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Updated By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Updated By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Updated By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Updated By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              NoteRecordId: PMS[0].Id,
              TableName: "Pms",
            }));
        (PMS[0].ShiftInchargeShiftB && !Disable.Hour3) ||
          (!Disable.Hour4 &&
            CreateApproval({
              RequestedBy: localStorage.getItem("empId"),
              Approver: PMS[0].ShiftInchargeShiftB,
              Level: 1,
              Status: "Waiting for Approval",
              TableName: "Pms",
              TableID: PMS[0].Id,
              Description: !Disable.Hour1
                ? "The PMS Report Has been Updated By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Updated By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Updated By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Updated By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Updated By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Updated By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              CreatedBy: localStorage.getItem("empId"),

            }));
        (PMS[0].ShiftInchargeShiftB && !Disable.Hour3) ||
          (!Disable.Hour4 &&
            setMessage({
              NoteFrom: localStorage.getItem("empId"),
              NoteTo: PMS[0].ShiftInchargeShiftB,
              NoteMessage: !Disable.Hour1
                ? "The PMS Report Has been Updated By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Updated By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Updated By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Updated By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Updated By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Updated By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              NoteRecordId: PMS[0].Id,
              TableName: "Pms",
            }));
        (PMS[0].ShiftInchargeShiftC && !Disable.Hour5) ||
          (!Disable.Hour6 &&
            CreateApproval({
              RequestedBy: localStorage.getItem("empId"),
              Approver: PMS[0].ShiftInchargeShiftC,
              Level: 1,
              Status: "Waiting for Approval",
              TableName: "Pms",
              TableID: PMS[0].Id,
              Description: !Disable.Hour1
                ? "The PMS Report Has been Updated By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Updated By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Updated By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Updated By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Updated By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Updated By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              CreatedBy: localStorage.getItem("empId"),

            }));
        (PMS[0].ShiftInchargeShiftC && !Disable.Hour5) ||
          (!Disable.Hour6 &&
            setMessage({
              NoteFrom: localStorage.getItem("empId"),
              NoteTo: PMS[0].ShiftInchargeShiftC,
              NoteMessage: !Disable.Hour1
                ? "The PMS Report Has been Updated By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Updated By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Updated By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Updated By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Updated By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Updated By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              NoteRecordId: PMS[0].Id,
              TableName: "Pms",
            }));
        (PMS[0].LineInspecQaShiftA && !Disable.Hour1) ||
          (!Disable.Hour2 &&
            CreateApproval({
              RequestedBy: localStorage.getItem("empId"),
              Approver: PMS[0].LineInspecQaShiftA,
              Level: 1,
              Status: "Waiting for Approval",
              TableName: "Pms",
              TableID: PMS[0].Id,
              Description: !Disable.Hour1
                ? "The PMS Report Has been Updated By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Updated By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Updated By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Updated By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Updated By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Updated By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              CreatedBy: localStorage.getItem("empId"),

            }));
        (PMS[0].LineInspecQaShiftA && !Disable.Hour1) ||
          (!Disable.Hour2 &&
            setMessage({
              NoteFrom: localStorage.getItem("empId"),
              NoteTo: PMS[0].LineInspecQaShiftA,
              NoteMessage: !Disable.Hour1
                ? "The PMS Report Has been Updated By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Updated By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Updated By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Updated By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Updated By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Updated By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              NoteRecordId: PMS[0].Id,
              TableName: "Pms",
            }));
        (PMS[0].LineInspecQaShiftB && !Disable.Hour3) ||
          (!Disable.Hour4 &&
            CreateApproval({
              RequestedBy: localStorage.getItem("empId"),
              Approver: PMS[0].LineInspecQaShiftB,
              Level: 1,
              Status: "Waiting for Approval",
              TableName: "Pms",
              TableID: PMS[0].Id,
              Description: !Disable.Hour1
                ? "The PMS Report Has been Updated By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Updated By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Updated By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Updated By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Updated By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Updated By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              CreatedBy: localStorage.getItem("empId"),

            }));
        (PMS[0].LineInspecQaShiftB && !Disable.Hour3) ||
          (!Disable.Hour4 &&
            setMessage({
              NoteFrom: localStorage.getItem("empId"),
              NoteTo: PMS[0].LineInspecQaShiftB,
              NoteMessage: !Disable.Hour1
                ? "The PMS Report Has been Updated By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Updated By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Updated By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Updated By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Updated By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Updated By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              NoteRecordId: PMS[0].Id,
              TableName: "Pms",
            }));
        (PMS[0].LineInspecQaShiftC && !Disable.Hour5) ||
          (!Disable.Hour6 &&
            CreateApproval({
              RequestedBy: localStorage.getItem("empId"),
              Approver: PMS[0].LineInspecQaShiftC,
              Level: 1,
              Status: "Waiting for Approval",
              TableName: "Pms",
              TableID: PMS[0].Id,
              Description: !Disable.Hour1
                ? "The PMS Report Has been Updated By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Updated By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Updated By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Updated By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Updated By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Updated By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              CreatedBy: localStorage.getItem("empId"),

            }));
        (PMS[0].LineInspecQaShiftC && !Disable.Hour5) ||
          (!Disable.Hour6 &&
            setMessage({
              NoteFrom: localStorage.getItem("empId"),
              NoteTo: PMS[0].LineInspecQaShiftC,
              NoteMessage: !Disable.Hour1
                ? "The PMS Report Has been Updated By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Updated By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Updated By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Updated By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Updated By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Updated By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              NoteRecordId: PMS[0].Id,
              TableName: "Pms",
            }));
      })
      .then(() => {
        alert(
          "The PMS record for MacHine No - " +
          PMS[0].Mcno +
          ", Has Been Updated"
        );
        gettracking();
        setPMS([initMachine]);
        setEmpName(initEmpName);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        alert("Submission failed.");
        window.location.reload();
      });
  };

  const handleUpdateCreate = async (e) => {
    console.log("PMS",PMS);
    e.preventDefault();
    const data1 = {
      data: PMS,
      _keyword_: "pro_mon_sys_parent",
      secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
    };
    console.log("data1",data1);
    axios
      .post(
        `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
        data1
      )
      .then((response1) => {
        axios
          .post(
            `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
            {
              data: Heat,
              _keyword_: "pro_mon_sys_heat_parameter",
              secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
            }
          )
          .then((res) => {
            // console.log("Heat insert",res);
          });
        axios
          .post(
            `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
            {
              data: Tracking,
              _keyword_: "pro_mon_sys_tracking_machine",
              secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
            }
          )
          .then((res) => {
            // console.log("Tracking insert",res);
          });
        axios
          .post(
            `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
            {
              data: Inject,
              _keyword_: "pro_mon_sys_inject_parameter",
              secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
            }
          )
          .then((res) => {
            // console.log("Inject insert",res);
          });
        PMS[0].Mcno &&
          alert(
            "The PMS record for MacHine No - " +
            PMS[0].Mcno +
            ", Has Been Updated"
          );
          window.location.reload();
        setNewInsert(true);
      })
      .then(() => { })
      .catch((error) => {
        console.log(error);
        alert("Submission failed.");
      });
  };
  useEffect(() => {
    setPMS([
      {
        ...initMachine,
        Mcno: PMS[0].Mcno,
        mc_name: PMS[0].mc_name,
        ShiftInchargeShiftA:
          !Disable.Hour1 || !Disable.Hour2 ? PMS[0].ShiftInchargeShiftA : "",
        ShiftInchargeShiftAEmp_name:
          !Disable.Hour1 || !Disable.Hour2
            ? PMS[0].ShiftInchargeShiftAEmp_name
            : "",

        ShiftInchargeShiftB:
          !Disable.Hour3 || !Disable.Hour4 ? PMS[0].ShiftInchargeShiftB : "",
        ShiftInchargeShiftBEmp_name:
          !Disable.Hour3 || !Disable.Hour4
            ? PMS[0].ShiftInchargeShiftBEmp_name
            : "",

        ShiftInchargeShiftC:
          !Disable.Hour5 || !Disable.Hour6 ? PMS[0].ShiftInchargeShiftC : "",
        ShiftInchargeShiftCEmp_name:
          !Disable.Hour5 || !Disable.Hour6
            ? PMS[0].ShiftInchargeShiftCEmp_name
            : "",

        LineInspecQaShiftA:
          !Disable.Hour1 || !Disable.Hour2 ? PMS[0].LineInspecQaShiftA : "",
        LineInspecQaShiftAEmp_name:
          !Disable.Hour1 || !Disable.Hour2
            ? PMS[0].LineInspecQaShiftAEmp_name
            : "",

        LineInspecQaShiftB:
          !Disable.Hour3 || !Disable.Hour4 ? PMS[0].LineInspecQaShiftB : "",
        LineInspecQaShiftBEmp_name:
          !Disable.Hour3 || !Disable.Hour4
            ? PMS[0].LineInspecQaShiftBEmp_name
            : "",

        LineInspecQaShiftC:
          !Disable.Hour5 || !Disable.Hour6 ? PMS[0].LineInspecQaShiftC : "",
        LineInspecQaShiftCEmp_name:
          !Disable.Hour5 || !Disable.Hour6
            ? PMS[0].LineInspecQaShiftCEmp_name
            : "",
        TrackDate: new Date(
          new Date().getTime() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0],
      },
    ]);
    setTracking([
      {
        ...initTracking,
        [Insert[0]]: "MOULD CHANGE",
        Time_to_be_updated: "Actual updated time",
      },
      {
        ...initTracking,
        [Insert[0]]: "MOULD CHANGE",
        Time_to_be_updated: "Part name",
      },
      {
        ...initTracking,
        [Insert[0]]: "MOULD CHANGE",
        Time_to_be_updated: "Colour",
      },
      {
        ...initTracking,
        [Insert[0]]: "MOULD CHANGE",
        Time_to_be_updated: "Material used",
      },
      {
        ...initTracking,
        [Insert[0]]: "MOULD CHANGE",
        Time_to_be_updated: "Material grade",
      },
    ]);
    setHeat([
      { ...initHeat, [Insert[1]]: "MOULD CHANGE", Heat_sl: "1" },
      { ...initHeat, [Insert[1]]: "MOULD CHANGE", Heat_sl: "2" },
      { ...initHeat, [Insert[1]]: "MOULD CHANGE", Heat_sl: "3" },
      { ...initHeat, [Insert[1]]: "MOULD CHANGE", Heat_sl: "4" },
      { ...initHeat, [Insert[1]]: "MOULD CHANGE", Heat_sl: "5" },
      { ...initHeat, [Insert[1]]: "MOULD CHANGE", Heat_sl: "6" },
      { ...initHeat, [Insert[1]]: "MOULD CHANGE", Heat_sl: "7" },
      { ...initHeat, [Insert[1]]: "MOULD CHANGE", Heat_sl: "8" },
      { ...initHeat, [Insert[1]]: "MOULD CHANGE", Heat_sl: "9" },
    ]);
    setInject([
      {
        ...initInject,
        Inject_sl: "1",
        Inject_desc: "Cycle time",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "2",
        Inject_desc: "Cooling time",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "3",
        Inject_desc: "Injection time - 1",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "4",
        Inject_desc: "Injection time - 2",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "5",
        Inject_desc: "Injection time - 3",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "6",
        Inject_desc: "Injection time - 4",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "7",
        Inject_desc: "Injection-Pressure-1",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "8",
        Inject_desc: "Injection-Pressure-2",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "9",
        Inject_desc: "Injection-Pressure-3",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "10",
        Inject_desc: "Injection-Pressure-4",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "11",
        Inject_desc: "Injection speed 1",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "12",
        Inject_desc: "Injection speed 2",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "13",
        Inject_desc: "Injection speed 3",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "14",
        Inject_desc: "Injection speed 4",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "15",
        Inject_desc: "Holding pressure-1",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "16",
        Inject_desc: "Holding pressure-2",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "17",
        Inject_desc: "Holding pressure-3",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "18",
        Inject_desc: "Holding pressure-4",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "19",
        Inject_desc: "Holding speed-1",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "20",
        Inject_desc: "Holding speed-2",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "21",
        Inject_desc: "Holding speed-3",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "22",
        Inject_desc: "Holding speed-4",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "23",
        Inject_desc: "Holding time-1",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "24",
        Inject_desc: "Holding time-2",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "25",
        Inject_desc: "Holding time-3",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "26",
        Inject_desc: "Holding time-4",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "27",
        Inject_desc: "Shot size",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "28",
        Inject_desc: "Cushion",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
    ]);
  }, [NewInsert]);

  const handleInsertcreate = () => {
    // console.log("track", Tracking);
    const data1 = {
      data: PMS,
      _keyword_: "pro_mon_sys_parent",
      secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
    };
    axios
      .post(
        `http://${API_URL}/GenericTransactionService/processTransaction`,
        data1
      )
      .then((response1) => {
        // console.log(response1);
        let Pms_id = response1.data.ROWID;

        // submit heat
        Heat[0].Pms_id = Pms_id;
        Heat[1].Pms_id = Pms_id;
        Heat[2].Pms_id = Pms_id;
        Heat[3].Pms_id = Pms_id;
        Heat[4].Pms_id = Pms_id;
        Heat[5].Pms_id = Pms_id;
        Heat[6].Pms_id = Pms_id;
        Heat[7].Pms_id = Pms_id;
        Heat[8].Pms_id = Pms_id;
        axios
          .post(
            `http://${API_URL}/GenericTransactionService/processTransaction`,
            {
              data: Heat,
              _keyword_: "pro_mon_sys_heat_parameter",
              secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
            }
          )
          .then((res) => {
            // console.log(Heat,"Heat res",res);
          });

        // submit Tracking
        Tracking[0].Pms_id = Pms_id;
        Tracking[1].Pms_id = Pms_id;
        Tracking[2].Pms_id = Pms_id;
        Tracking[3].Pms_id = Pms_id;
        Tracking[4].Pms_id = Pms_id;
        axios
          .post(
            `http://${API_URL}/GenericTransactionService/processTransaction`,
            {
              data: Tracking,
              _keyword_: "pro_mon_sys_tracking_machine",
              secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
            }
          )
          .then((res) => {
            // console.log(Tracking,"Tracking res",res);
          });

        // submit Inject
        Inject[0].Pms_id = Pms_id;
        Inject[1].Pms_id = Pms_id;
        Inject[2].Pms_id = Pms_id;
        Inject[3].Pms_id = Pms_id;
        Inject[4].Pms_id = Pms_id;
        Inject[5].Pms_id = Pms_id;
        Inject[6].Pms_id = Pms_id;
        Inject[7].Pms_id = Pms_id;
        Inject[8].Pms_id = Pms_id;
        Inject[9].Pms_id = Pms_id;
        Inject[10].Pms_id = Pms_id;
        Inject[11].Pms_id = Pms_id;
        Inject[12].Pms_id = Pms_id;
        Inject[13].Pms_id = Pms_id;
        Inject[14].Pms_id = Pms_id;
        Inject[15].Pms_id = Pms_id;
        Inject[16].Pms_id = Pms_id;
        Inject[17].Pms_id = Pms_id;
        Inject[18].Pms_id = Pms_id;
        Inject[19].Pms_id = Pms_id;
        Inject[20].Pms_id = Pms_id;
        Inject[21].Pms_id = Pms_id;
        Inject[22].Pms_id = Pms_id;
        Inject[23].Pms_id = Pms_id;
        Inject[24].Pms_id = Pms_id;
        Inject[25].Pms_id = Pms_id;
        Inject[26].Pms_id = Pms_id;
        Inject[27].Pms_id = Pms_id;
        axios
          .post(
            `http://${API_URL}/GenericTransactionService/processTransaction`,
            {
              data: Inject,
              _keyword_: "pro_mon_sys_inject_parameter",
              secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
            }
          )
          .then((res) => {
            // console.log(Inject,"Inject res",res);
            // setsubmit(false)
          });
        gettracking();

        setEmpName(initEmpName);

        (PMS[0].ShiftInchargeShiftA && !Disable.Hour1) ||
          (!Disable.Hour2 &&
            CreateApproval({
              RequestedBy: localStorage.getItem("empId"),
              Approver: PMS[0].ShiftInchargeShiftA,
              Level: 1,
              Status: "Waiting for Approval",
              TableName: "Pms",
              TableID: PMS[0].Id,
              Description: !Disable.Hour1
                ? "The PMS Report Has been Submitted for MouldChange By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Submitted for MouldChange By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Submitted for MouldChange By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Submitted for MouldChange By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Submitted for MouldChange By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Submitted for MouldChange By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              CreatedBy: localStorage.getItem("empId"),

            }));
        (PMS[0].ShiftInchargeShiftA && !Disable.Hour1) ||
          (!Disable.Hour2 &&
            setMessage({
              NoteFrom: localStorage.getItem("empId"),
              NoteTo: PMS[0].ShiftInchargeShiftA,
              NoteMessage: !Disable.Hour1
                ? "The PMS Report Has been Submitted for MouldChange By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Submitted for MouldChange By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Submitted for MouldChange By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Submitted for MouldChange By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Submitted for MouldChange By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Submitted for MouldChange By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              NoteRecordId: PMS[0].Id,
              TableName: "Pms",
            }));
        (PMS[0].ShiftInchargeShiftB && !Disable.Hour3) ||
          (!Disable.Hour4 &&
            CreateApproval({
              RequestedBy: localStorage.getItem("empId"),
              Approver: PMS[0].ShiftInchargeShiftB,
              Level: 1,
              Status: "Waiting for Approval",
              TableName: "Pms",
              TableID: PMS[0].Id,
              Description: !Disable.Hour1
                ? "The PMS Report Has been Submitted for MouldChange By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Submitted for MouldChange By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Submitted for MouldChange By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Submitted for MouldChange By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Submitted for MouldChange By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Submitted for MouldChange By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              CreatedBy: localStorage.getItem("empId"),

            }));
        (PMS[0].ShiftInchargeShiftB && !Disable.Hour3) ||
          (!Disable.Hour4 &&
            setMessage({
              NoteFrom: localStorage.getItem("empId"),
              NoteTo: PMS[0].ShiftInchargeShiftB,
              NoteMessage: !Disable.Hour1
                ? "The PMS Report Has been Submitted for MouldChange By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Submitted for MouldChange By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Submitted for MouldChange By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Submitted for MouldChange By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Submitted for MouldChange By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Submitted for MouldChange By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              NoteRecordId: PMS[0].Id,
              TableName: "Pms",
            }));
        (PMS[0].ShiftInchargeShiftC && !Disable.Hour5) ||
          (!Disable.Hour6 &&
            CreateApproval({
              RequestedBy: localStorage.getItem("empId"),
              Approver: PMS[0].ShiftInchargeShiftC,
              Level: 1,
              Status: "Waiting for Approval",
              TableName: "Pms",
              TableID: PMS[0].Id,
              Description: !Disable.Hour1
                ? "The PMS Report Has been Submitted for MouldChange By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Submitted for MouldChange By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Submitted for MouldChange By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Submitted for MouldChange By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Submitted for MouldChange By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Submitted for MouldChange By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              CreatedBy: localStorage.getItem("empId"),

            }));
        (PMS[0].ShiftInchargeShiftC && !Disable.Hour5) ||
          (!Disable.Hour6 &&
            setMessage({
              NoteFrom: localStorage.getItem("empId"),
              NoteTo: PMS[0].ShiftInchargeShiftC,
              NoteMessage: !Disable.Hour1
                ? "The PMS Report Has been Submitted for MouldChange By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Submitted for MouldChange By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Submitted for MouldChange By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Submitted for MouldChange By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Submitted for MouldChange By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Submitted for MouldChange By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              NoteRecordId: PMS[0].Id,
              TableName: "Pms",
            }));
        (PMS[0].LineInspecQaShiftA && !Disable.Hour1) ||
          (!Disable.Hour2 &&
            CreateApproval({
              RequestedBy: localStorage.getItem("empId"),
              Approver: PMS[0].LineInspecQaShiftA,
              Level: 1,
              Status: "Waiting for Approval",
              TableName: "Pms",
              TableID: PMS[0].Id,
              Description: !Disable.Hour1
                ? "The PMS Report Has been Submitted for MouldChange By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Submitted for MouldChange By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Submitted for MouldChange By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Submitted for MouldChange By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Submitted for MouldChange By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Submitted for MouldChange By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              CreatedBy: localStorage.getItem("empId"),

            }));
        (PMS[0].LineInspecQaShiftA && !Disable.Hour1) ||
          (!Disable.Hour2 &&
            setMessage({
              NoteFrom: localStorage.getItem("empId"),
              NoteTo: PMS[0].LineInspecQaShiftA,
              NoteMessage: !Disable.Hour1
                ? "The PMS Report Has been Submitted for MouldChange By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Submitted for MouldChange By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Submitted for MouldChange By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Submitted for MouldChange By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Submitted for MouldChange By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Submitted for MouldChange By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              NoteRecordId: PMS[0].Id,
              TableName: "Pms",
            }));
        (PMS[0].LineInspecQaShiftB && !Disable.Hour3) ||
          (!Disable.Hour4 &&
            CreateApproval({
              RequestedBy: localStorage.getItem("empId"),
              Approver: PMS[0].LineInspecQaShiftB,
              Level: 1,
              Status: "Waiting for Approval",
              TableName: "Pms",
              TableID: PMS[0].Id,
              Description: !Disable.Hour1
                ? "The PMS Report Has been Submitted for MouldChange By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Submitted for MouldChange By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Submitted for MouldChange By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Submitted for MouldChange By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Submitted for MouldChange By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Submitted for MouldChange By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              CreatedBy: localStorage.getItem("empId"),

            }));
        (PMS[0].LineInspecQaShiftB && !Disable.Hour3) ||
          (!Disable.Hour4 &&
            setMessage({
              NoteFrom: localStorage.getItem("empId"),
              NoteTo: PMS[0].LineInspecQaShiftB,
              NoteMessage: !Disable.Hour1
                ? "The PMS Report Has been Submitted for MouldChange By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Submitted for MouldChange By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Submitted for MouldChange By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Submitted for MouldChange By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Submitted for MouldChange By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Submitted for MouldChange By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              NoteRecordId: PMS[0].Id,
              TableName: "Pms",
            }));
        (PMS[0].LineInspecQaShiftC && !Disable.Hour5) ||
          (!Disable.Hour6 &&
            CreateApproval({
              RequestedBy: localStorage.getItem("empId"),
              Approver: PMS[0].LineInspecQaShiftC,
              Level: 1,
              Status: "Waiting for Approval",
              TableName: "Pms",
              TableID: PMS[0].Id,
              Description: !Disable.Hour1
                ? "The PMS Report Has been Submitted for MouldChange By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Submitted for MouldChange By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Submitted for MouldChange By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Submitted for MouldChange By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Submitted for MouldChange By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Submitted for MouldChange By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              CreatedBy: localStorage.getItem("empId"),

            }));
        (PMS[0].LineInspecQaShiftC && !Disable.Hour5) ||
          (!Disable.Hour6 &&
            setMessage({
              NoteFrom: localStorage.getItem("empId"),
              NoteTo: PMS[0].LineInspecQaShiftC,
              NoteMessage: !Disable.Hour1
                ? "The PMS Report Has been Submitted for MouldChange By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name") +
                ", For The Part Name - " +
                Tracking[1].Track_7_am +
                " and The Machine - " +
                PMS[0].mc_name
                : !Disable.Hour2
                  ? "The PMS Report Has been Submitted for MouldChange By " +
                  localStorage.getItem("empId") +
                  "-" +
                  localStorage.getItem("name") +
                  ", For The Part Name - " +
                  Tracking[1].Track_11_am +
                  " and The Machine - " +
                  PMS[0].mc_name
                  : !Disable.Hour3
                    ? "The PMS Report Has been Submitted for MouldChange By " +
                    localStorage.getItem("empId") +
                    "-" +
                    localStorage.getItem("name") +
                    ", For The Part Name - " +
                    Tracking[1].Track_15_pm +
                    " and The Machine - " +
                    PMS[0].mc_name
                    : !Disable.Hour4
                      ? "The PMS Report Has been Submitted for MouldChange By " +
                      localStorage.getItem("empId") +
                      "-" +
                      localStorage.getItem("name") +
                      ", For The Part Name - " +
                      Tracking[1].Track_19_pm +
                      " and The Machine - " +
                      PMS[0].mc_name
                      : !Disable.Hour5
                        ? "The PMS Report Has been Submitted for MouldChange By " +
                        localStorage.getItem("empId") +
                        "-" +
                        localStorage.getItem("name") +
                        ", For The Part Name - " +
                        Tracking[1].Track_23_pm +
                        " and The Machine - " +
                        PMS[0].mc_name
                        : !Disable.Hour6
                          ? "The PMS Report Has been Submitted for MouldChange By " +
                          localStorage.getItem("empId") +
                          "-" +
                          localStorage.getItem("name") +
                          ", For The Part Name - " +
                          Tracking[1].Track_3_am +
                          " and The Machine - " +
                          PMS[0].mc_name
                          : "",
              NoteRecordId: PMS[0].Id,
              TableName: "Pms",
            }));
      })
      .then((res) => {
        // console.log(res);
      })
      .catch((error) => {
        console.log(error);
        alert("Submission failed.");
        window.location.reload();
      });
  };

  function handleShiftInchargeShiftA(event) {
    GetRecord(
      { _keyword_: "emp_details_by_empCode", emp_code: event.value },
      setemp
    ).then((res) => {
      // console.log("handleShiftInchargeShiftA res - ",res);
      setEmpName({
        ...EmpName,
        ShiftInchargeShiftA: res[0].Emp_code,
        ShiftInchargeShiftAEmp_name: res[0].Emp_name,
      });
      setPMS([{ ...PMS[0], ShiftInchargeShiftA: res[0].Emp_code }]);
    });
  }
  function handleShiftInchargeShiftB(event) {
    GetRecord(
      { _keyword_: "emp_details_by_empCode", emp_code: event.value },
      setemp
    ).then((res) => {
      setEmpName({
        ...EmpName,
        ShiftInchargeShiftB: res[0].Emp_code,
        ShiftInchargeShiftBEmp_name: res[0].Emp_name,
      });
      setPMS([{ ...PMS[0], ShiftInchargeShiftB: res[0].Emp_code }]);
    });
  }
  function handleShiftInchargeShiftC(event) {
    GetRecord(
      { _keyword_: "emp_details_by_empCode", emp_code: event.value },
      setemp
    ).then((res) => {
      setEmpName({
        ...EmpName,
        ShiftInchargeShiftC: res[0].Emp_code,
        ShiftInchargeShiftCEmp_name: res[0].Emp_name,
      });
      setPMS([{ ...PMS[0], ShiftInchargeShiftC: res[0].Emp_code }]);
    });
  }
  function handleLineInspecQaShiftA(event) {
    GetRecord(
      { _keyword_: "emp_details_by_empCode", emp_code: event.value },
      setemp
    ).then((res) => {
      setEmpName({
        ...EmpName,
        LineInspecQaShiftA: res[0].Emp_code,
        LineInspecQaShiftAEmp_name: res[0].Emp_name,
      });
      setPMS([{ ...PMS[0], LineInspecQaShiftA: res[0].Emp_code }]);
    });
  }
  function handleLineInspecQaShiftB(event) {
    GetRecord(
      { _keyword_: "emp_details_by_empCode", emp_code: event.value },
      setemp
    ).then((res) => {
      setEmpName({
        ...EmpName,
        LineInspecQaShiftB: res[0].Emp_code,
        LineInspecQaShiftBEmp_name: res[0].Emp_name,
      });
      setPMS([{ ...PMS[0], LineInspecQaShiftB: res[0].Emp_code }]);
    });
  }
  function handleLineInspecQaShiftC(event) {
    GetRecord(
      { _keyword_: "emp_details_by_empCode", emp_code: event.value },
      setemp
    ).then((res) => {
      setEmpName({
        ...EmpName,
        LineInspecQaShiftC: res[0].Emp_code,
        LineInspecQaShiftCEmp_name: res[0].Emp_name,
      });
      setPMS([{ ...PMS[0], LineInspecQaShiftC: res[0].Emp_code }]);
    });
  }
  // barcode scannning to get input
  useScanDetection({
    onComplete: (code) => {
      code = code.replaceAll("Shift", "");
      setField(code);
    },
    minLength: 3,
  });

  // set value to the appropiate field
  function setField(code) {
    // let isnumber = Number(code).toString() !== "NaN";
    if (code.includes("EMP")) {
    } else {
      if (code.includes("RMI")) {
      } else if (code.includes("CUS")) {
        alert("Invalid Input");
        return false;
      } else if (code.includes("FG")) {
        alert("Invalid Input");
        return false;
      } else if (code.includes("IMM")) {
        handleMachine({ value: code });
      } else if (code.includes("SUP")) {
        alert("Invalid Input");
        return false;
      } else {
        alert("Invalid Input");
        return false;
      }
    }
  }

  return (
    <div>
      <form
        className="form-pms-Create"
        onSubmit={(e) => {
          e.preventDefault();
          handleCreate(e);
        }}
      >
        <table
          className="table"
          width="100%"
          style={{ textAlign: "center" }}
          border="0"
        >
          <tbody>
            <tr style={{ textAlign: "center" }}>
              <th style={{ textAlign: "center" }} colSpan="4">
                <font color="#8B0000">PROCESS MONITORING SYSTEM</font>
              </th>
            </tr>
            <tr>
              <td>Format No</td>
              <td style={{ textAlign: "center" }}>
                <label>UPCL/PRO/R/28</label>
              </td>

              <td>Rev No</td>
              <td style={{ textAlign: "center" }}>
                <label>02</label>
              </td>
            </tr>
            <tr>
              <td>Track Date</td>
              <td>
                <DatePicker value={PMS[0].TrackDate} dis={true} />
              </td>
              <td>
                M/C NO<span style={{ color: "red" }}>*</span>
              </td>
              <td>
                {PMS[0].Mcno === undefined && (
                  <span className="Req">Required *</span>
                )}
                <Select
                  id="dropdown"
                  styles={{ width: "100%" }}
                  options={Machine.map((item) => ({
                    value: item.McNo,
                    label: item.mc_name,
                  }))}
                  value={{
                    value: PMS[0].Mcno,
                    label: PMS[0].mc_name,
                  }}
                  onChange={handleMachine}
                  placeholder="Select Machine No"
                  required
                />
              </td>
            </tr>
          </tbody>
        </table>

        <table
          className="table"
          width="50%"
          style={{ textAlign: "left" }}
          border="0"
        >
          <tbody>
            <tr>
              <th colSpan="15"></th>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td colSpan="2">Part weight1 (-{PMS[0].PartWeightMinus1}/+{PMS[0].PartWeightPlus1})</td>

              <td>
                <input
                  style={{ width: "100%" }}
                  type="number"
                  name="PartWeight1"
                  value={PMS[0].PartWeight1}
                  onChange={HandleFeildChangePMS}
                  disabled
                />
              </td>
              <td></td>
              <td>
                <input
                  style={
                    !(
                      parseFloat(PMS[0].PW1) >
                      parseFloat(PMS[0].PartWeight1) +
                      parseFloat(PMS[0].PartWeightPlus1) ||
                      parseFloat(PMS[0].PW1) <
                      parseFloat(PMS[0].PartWeight1) -
                      parseFloat(PMS[0].PartWeightMinus1)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  type="number"
                  name="PW1"
                  id="PW1"
                  value={PMS[0].PW1}
                  disabled={Disable.Hour1}
                  onChange={(e) => {
                    HandleFeildChangePMS(e);
                  }}
                />
              </td>

              <td>
                <input
                  style={
                    !(
                      parseFloat(PMS[0].PW2) >
                      parseFloat(PMS[0].PartWeight1) +
                      parseFloat(PMS[0].PartWeightPlus1) ||
                      parseFloat(PMS[0].PW2) <
                      parseFloat(PMS[0].PartWeight1) -
                      parseFloat(PMS[0].PartWeightMinus1)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  type="number"
                  name="PW2"
                  id="PW2"
                  value={PMS[0].PW2}
                  disabled={Disable.Hour2}
                  onChange={(e) => {
                    HandleFeildChangePMS(e);
                  }}
                />
              </td>
              <td>
                <input
                  style={
                    !(
                      parseFloat(PMS[0].PW3) >
                      parseFloat(PMS[0].PartWeight1) +
                      parseFloat(PMS[0].PartWeightPlus1) ||
                      parseFloat(PMS[0].PW3) <
                      parseFloat(PMS[0].PartWeight1) -
                      parseFloat(PMS[0].PartWeightMinus1)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  type="number"
                  name="PW3"
                  id="PW3"
                  value={PMS[0].PW3}
                  disabled={Disable.Hour3}
                  onChange={(e) => {
                    HandleFeildChangePMS(e);
                  }}
                />
              </td>
              <td>
                <input
                  style={
                    !(
                      parseFloat(PMS[0].PW4) >
                      parseFloat(PMS[0].PartWeight1) +
                      parseFloat(PMS[0].PartWeightPlus1) ||
                      parseFloat(PMS[0].PW4) <
                      parseFloat(PMS[0].PartWeight1) -
                      parseFloat(PMS[0].PartWeightMinus1)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  type="number"
                  name="PW4"
                  id="PW4"
                  value={PMS[0].PW4}
                  disabled={Disable.Hour4}
                  onChange={(e) => {
                    HandleFeildChangePMS(e);
                  }}
                />
              </td>
              <td>
                <input
                  style={
                    !(
                      parseFloat(PMS[0].PW5) >
                      parseFloat(PMS[0].PartWeight1) +
                      parseFloat(PMS[0].PartWeightPlus1) ||
                      parseFloat(PMS[0].PW5) <
                      parseFloat(PMS[0].PartWeight1) -
                      parseFloat(PMS[0].PartWeightMinus1)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  type="number"
                  name="PW5"
                  id="PW5"
                  value={PMS[0].PW5}
                  disabled={Disable.Hour5}
                  onChange={(e) => {
                    HandleFeildChangePMS(e);
                  }}
                />
              </td>
              <td>
                <input
                  style={
                    !(
                      parseFloat(PMS[0].PW6) >
                      parseFloat(PMS[0].PartWeight1) +
                      parseFloat(PMS[0].PartWeightPlus1) ||
                      parseFloat(PMS[0].PW6) <
                      parseFloat(PMS[0].PartWeight1) -
                      parseFloat(PMS[0].PartWeightMinus1)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  type="number"
                  name="PW6"
                  id="PW6"
                  value={PMS[0].PW6}
                  disabled={Disable.Hour6}
                  onChange={(e) => {
                    HandleFeildChangePMS(e);
                  }}
                />
              </td>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td colSpan="2">Part weight2 (-{PMS[0].PartWeightMinus2}/+{PMS[0].PartWeightPlus2})</td>

              <td>
                <input
                  style={{ width: "100%" }}
                  type="number"
                  name="PartWeight2"
                  value={PMS[0].PartWeight2}
                  onChange={HandleFeildChangePMS}
                  disabled
                />
              </td>
              <td></td>
              <td>
                <input
                  style={
                    !(
                      parseFloat(PMS[0].PW11) >
                      parseFloat(PMS[0].PartWeight2) +
                      parseFloat(PMS[0].PartWeightPlus2) ||
                      parseFloat(PMS[0].PW11) <
                      parseFloat(PMS[0].PartWeight2) -
                      parseFloat(PMS[0].PartWeightMinus2)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  type="number"
                  name="PW11"
                  id="PW11"
                  value={PMS[0].PW11}
                  disabled={Disable.Hour1}
                  onChange={(e) => {
                    HandleFeildChangePMS(e);
                  }}
                />
              </td>

              <td>
                <input
                  style={
                    !(
                      parseFloat(PMS[0].PW21) >
                      parseFloat(PMS[0].PartWeight2) +
                      parseFloat(PMS[0].PartWeightPlus2) ||
                      parseFloat(PMS[0].PW21) <
                      parseFloat(PMS[0].PartWeight2) -
                      parseFloat(PMS[0].PartWeightMinus2)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  type="number"
                  name="PW21"
                  id="PW21"
                  value={PMS[0].PW21}
                  disabled={Disable.Hour2}
                  onChange={(e) => {
                    HandleFeildChangePMS(e);
                  }}
                />
              </td>
              <td>
                <input
                  style={
                    !(
                      parseFloat(PMS[0].PW31) >
                      parseFloat(PMS[0].PartWeight2) +
                      parseFloat(PMS[0].PartWeightPlus2) ||
                      parseFloat(PMS[0].PW31) <
                      parseFloat(PMS[0].PartWeight2) -
                      parseFloat(PMS[0].PartWeightMinus2)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  type="number"
                  name="PW31"
                  id="PW31"
                  value={PMS[0].PW31}
                  disabled={Disable.Hour3}
                  onChange={(e) => {
                    HandleFeildChangePMS(e);
                  }}
                />
              </td>
              <td>
                <input
                  style={
                    !(
                      parseFloat(PMS[0].PW41) >
                      parseFloat(PMS[0].PartWeight2) +
                      parseFloat(PMS[0].PartWeightPlus2) ||
                      parseFloat(PMS[0].PW41) <
                      parseFloat(PMS[0].PartWeight2) -
                      parseFloat(PMS[0].PartWeightMinus2)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  type="number"
                  name="PW41"
                  id="PW41"
                  value={PMS[0].PW41}
                  disabled={Disable.Hour4}
                  onChange={(e) => {
                    HandleFeildChangePMS(e);
                  }}
                />
              </td>
              <td>
                <input
                  style={
                    !(
                      parseFloat(PMS[0].PW51) >
                      parseFloat(PMS[0].PartWeight2) +
                      parseFloat(PMS[0].PartWeightPlus2) ||
                      parseFloat(PMS[0].PW51) <
                      parseFloat(PMS[0].PartWeight2) -
                      parseFloat(PMS[0].PartWeightMinus2)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  type="number"
                  name="PW51"
                  id="PW51"
                  value={PMS[0].PW51}
                  disabled={Disable.Hour5}
                  onChange={(e) => {
                    HandleFeildChangePMS(e);
                  }}
                />
              </td>
              <td>
                <input
                  style={
                    !(
                      parseFloat(PMS[0].PW61) >
                      parseFloat(PMS[0].PartWeight2) +
                      parseFloat(PMS[0].PartWeightPlus2) ||
                      parseFloat(PMS[0].PW61) <
                      parseFloat(PMS[0].PartWeight2) -
                      parseFloat(PMS[0].PartWeightMinus2)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  type="number"
                  name="PW61"
                  id="PW61"
                  value={PMS[0].PW61}
                  disabled={Disable.Hour6}
                  onChange={(e) => {
                    HandleFeildChangePMS(e);
                  }}
                />
              </td>
            </tr>
            <tr>
              <th width="7%" style={{ textAlign: "center", width: "1%" }}>
                SL
              </th>
              <th
                style={{
                  textAlign: "center",
                  whiteSpace: "nowrap",
                  width: "15.5%",
                }}
              >
                Description
              </th>
              <th width="15%" style={{ textAlign: "center", width: "9%" }}>
                Inst used
              </th>
              <th width="20%" style={{ textAlign: "center", width: "11%" }}>
                Raw-Material
              </th>
              <th width="7%" style={{ textAlign: "center" }}>
                Actual Level
              </th>
              <th width="7%" style={{ textAlign: "center" }}>
                Actual Level
              </th>
              <th width="7%" style={{ textAlign: "center" }}>
                Actual Level
              </th>
              <th width="7%" style={{ textAlign: "center" }}>
                Actual Level
              </th>
              <th width="7%" style={{ textAlign: "center" }}>
                Actual Level
              </th>
              <th width="7%" style={{ textAlign: "center" }}>
                Actual Level
              </th>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>1</td>
              <td style={{ whiteSpace: "nowrap" }}>Raw-Material-Level</td>
              <td>On hooper</td>
              <td>Min level/Max Level</td>
              <td>
                <select
                  id="dropdown"
                  style={{ width: "100%" }}
                  name="RM1"
                  value={PMS[0].RM1}
                  onChange={HandleFeildChangePMS}
                  disabled={Disable.Hour1}
                >
                  <option value="NA">N A</option>
                  <option value="Active">✓</option>
                  <option value="Inactive">X</option>
                </select>
              </td>
              <td>
                <select
                  id="dropdown"
                  style={{ width: "100%" }}
                  name="RM2"
                  value={PMS[0].RM2}
                  onChange={HandleFeildChangePMS}
                  disabled={Disable.Hour2}
                >
                  <option value="NA">N A</option>
                  <option value="Active">✓</option>
                  <option value="Inactive">X</option>
                </select>
              </td>

              <td>
                <select
                  id="dropdown"
                  style={{ width: "100%" }}
                  name="RM3"
                  value={PMS[0].RM3}
                  onChange={HandleFeildChangePMS}
                  disabled={Disable.Hour3}
                >
                  <option value="NA">N A</option>
                  <option value="Active">✓</option>
                  <option value="Inactive">X</option>
                </select>
              </td>
              <td>
                <select
                  id="dropdown"
                  style={{ width: "100%" }}
                  name="RM4"
                  value={PMS[0].RM4}
                  onChange={HandleFeildChangePMS}
                  disabled={Disable.Hour4}
                >
                  <option value="NA">N A</option>
                  <option value="Active">✓</option>
                  <option value="Inactive">X</option>
                </select>
              </td>
              <td>
                <select
                  id="dropdown"
                  style={{ width: "100%" }}
                  name="RM5"
                  value={PMS[0].RM5}
                  onChange={HandleFeildChangePMS}
                  disabled={Disable.Hour5}
                >
                  <option value="NA">N A</option>
                  <option value="Active">✓</option>
                  <option value="Inactive">X</option>
                </select>
              </td>
              <td>
                <select
                  id="dropdown"
                  style={{ width: "100%" }}
                  name="RM6"
                  value={PMS[0].RM6}
                  onChange={HandleFeildChangePMS}
                  disabled={Disable.Hour6}
                >
                  <option value="NA">N A</option>
                  <option value="Active">✓</option>
                  <option value="Inactive">X</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>

        <table
          className="table"
          width="50%"
          style={{ textAlign: "center" }}
          border="0"
        >
          <tbody>
            <tr style={{ textAlign: "center" }}>
              <th style={{ textAlign: "center" }} colSpan="15">
                Tracking Machine
              </th>
            </tr>
            <tr>
              <th
                style={{
                  textAlign: "center",
                  whiteSpace: "nowrap",
                  width: "39%",
                }}
              >
                Time to be updated
              </th>
              <th
                width="5%"
                style={{ textAlign: "center", whiteSpace: "break-spaces" }}
              >
                07.00 AM - 08.00 AM
              </th>
              <th
                width="5%"
                style={{ textAlign: "center", whiteSpace: "break-spaces" }}
              >
                11.00 AM - 12.00 AM
              </th>
              <th
                width="5%"
                style={{ textAlign: "center", whiteSpace: "break-spaces" }}
              >
                15.00 PM - 16.00 PM
              </th>
              <th
                width="5%"
                style={{ textAlign: "center", whiteSpace: "break-spaces" }}
              >
                19.00 PM - 20.00 PM
              </th>
              <th
                width="5%"
                style={{ textAlign: "center", whiteSpace: "break-spaces" }}
              >
                23.00 PM - 01.00 AM
              </th>
              <th
                width="5%"
                style={{ textAlign: "center", whiteSpace: "break-spaces" }}
              >
                03.00 AM - 04.00 AM
              </th>
            </tr>
            <tr>
              <td>Actual updated time</td>
              <td>
                <input
                  type="text"
                  value={Tracking[0].Track_7_am}
                  onChange={(e) =>
                    setTracking([
                      { ...Tracking[0], Track_7_am: e.target.value },
                      { ...Tracking[1] },
                      { ...Tracking[2] },
                      { ...Tracking[3] },
                      { ...Tracking[4] },
                    ])
                  }
                  disabled
                />
              </td>
              <td>
                <input
                  type="text"
                  value={Tracking[0].Track_11_am}
                  onChange={(e) =>
                    setTracking([
                      { ...Tracking[0], Track_11_am: e.target.value },
                      { ...Tracking[1] },
                      { ...Tracking[2] },
                      { ...Tracking[3] },
                      { ...Tracking[4] },
                    ])
                  }
                  disabled
                />
              </td>
              <td>
                <input
                  type="text"
                  value={Tracking[0].Track_15_pm}
                  onChange={(e) =>
                    setTracking([
                      { ...Tracking[0], Track_15_pm: e.target.value },
                      { ...Tracking[1] },
                      { ...Tracking[2] },
                      { ...Tracking[3] },
                      { ...Tracking[4] },
                    ])
                  }
                  disabled
                />
              </td>
              <td>
                <input
                  type="text"
                  value={Tracking[0].Track_19_pm}
                  onChange={(e) =>
                    setTracking([
                      { ...Tracking[0], Track_19_pm: e.target.value },
                      { ...Tracking[1] },
                      { ...Tracking[2] },
                      { ...Tracking[3] },
                      { ...Tracking[4] },
                    ])
                  }
                  disabled
                />
              </td>
              <td>
                <input
                  type="text"
                  value={Tracking[0].Track_23_pm}
                  onChange={(e) =>
                    setTracking([
                      { ...Tracking[0], Track_23_pm: e.target.value },
                      { ...Tracking[1] },
                      { ...Tracking[2] },
                      { ...Tracking[3] },
                      { ...Tracking[4] },
                    ])
                  }
                  disabled
                />
              </td>
              <td>
                <input
                  type="text"
                  value={Tracking[0].Track_3_am}
                  onChange={(e) =>
                    setTracking([
                      { ...Tracking[0], Track_3_am: e.target.value },
                      { ...Tracking[1] },
                      { ...Tracking[2] },
                      { ...Tracking[3] },
                      { ...Tracking[4] },
                    ])
                  }
                  disabled
                />
              </td>
            </tr>
            <tr>
              <td>Part name</td>
              <td>
                <select
                title={Tracking[1].Track_7_am}
                  required
                  id="dropdown"
                  name="PartName1"
                  style={{ width: "100%" }}
                  value={Tracking[1].Track_7_am}
                  onChange={HandlePart7Am}
                  disabled={Disable.Hour1}
                >
                  <option value="">Select Part Name</option>
                  <option value="IDEAL">IDEAL</option>
                  <option value="MOULD CHANGE">MOULD CHANGE</option>
                  {Part.map((i, index) => (
                    <option key={index} value={i.PrtNme}>
                      {i.PrtNme}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  id="dropdown"
                  style={{ width: "100%" }}
                title={Tracking[1].Track_11_am}
                  value={Tracking[1].Track_11_am}
                  onChange={HandlePart11Am}
                  disabled={Disable.Hour2}
                >
                  <option value="">Select Part Name</option>
                  <option value="IDEAL">IDEAL</option>
                  <option value="MOULD CHANGE">MOULD CHANGE</option>
                  {Part.map((i, index) => (
                    <option key={index} value={i.PrtNme}>
                      {i.PrtNme}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  id="dropdown"
                  style={{ width: "100%" }}
                  title={Tracking[1].Track_15_pm}
                  value={Tracking[1].Track_15_pm}
                  onChange={HandlePart15Pm}
                  disabled={Disable.Hour3}
                >
                  <option value="">Select Part Name</option>
                  <option value="IDEAL">IDEAL</option>
                  <option value="MOULD CHANGE">MOULD CHANGE</option>
                  {Part.map((i, index) => (
                    <option key={index} value={i.PrtNme}>
                      {i.PrtNme}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  id="dropdown"
                  style={{ width: "100%" }}
                  title={Tracking[1].Track_19_pm}
                  value={Tracking[1].Track_19_pm}
                  onChange={HandlePart19Pm}
                  disabled={Disable.Hour4}
                >
                  <option value="">Select Part Name</option>
                  <option value="IDEAL">IDEAL</option>
                  <option value="MOULD CHANGE">MOULD CHANGE</option>
                  {Part.map((i, index) => (
                    <option key={index} value={i.PrtNme}>
                      {i.PrtNme}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  id="dropdown"
                  style={{ width: "100%" }}
                  title={Tracking[1].Track_23_pm}
                  value={Tracking[1].Track_23_pm}
                  onChange={HandlePart23Pm}
                  disabled={Disable.Hour5}
                >
                  <option value="">Select Part Name</option>
                  <option value="IDEAL">IDEAL</option>
                  <option value="MOULD CHANGE">MOULD CHANGE</option>
                  {Part.map((i, index) => (
                    <option key={index} value={i.PrtNme}>
                      {i.PrtNme}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  id="dropdown"
                  style={{ width: "100%" }}
                  title={Tracking[1].Track_3_am}
                  value={Tracking[1].Track_3_am}
                  onChange={HandlePart3Am}
                  disabled={Disable.Hour6}
                >
                  <option value="">Select Part Name</option>
                  <option value="IDEAL">IDEAL</option>
                  <option value="MOULD CHANGE">MOULD CHANGE</option>
                  {Part.map((i, index) => (
                    <option key={index} value={i.PrtNme}>
                      {i.PrtNme}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td className="lbl">Colour</td>
              <td>
                <input
                  type="text"
                  value={Tracking[2].Track_7_am}
                  onChange={(e) =>
                    setTracking([
                      { ...Tracking[0] },
                      { ...Tracking[1] },
                      { ...Tracking[2], Track_7_am: e.target.value },
                      { ...Tracking[3] },
                      { ...Tracking[4] },
                    ])
                  }
                  disabled
                />
              </td>
              <td>
                <input
                  type="text"
                  value={Tracking[2].Track_11_am}
                  onChange={(e) =>
                    setTracking([
                      { ...Tracking[0] },
                      { ...Tracking[1] },
                      { ...Tracking[2], Track_11_am: e.target.value },
                      { ...Tracking[3] },
                      { ...Tracking[4] },
                    ])
                  }
                  disabled
                />
              </td>

              <td>
                <input
                  type="text"
                  value={Tracking[2].Track_15_pm}
                  onChange={(e) =>
                    setTracking([
                      { ...Tracking[0] },
                      { ...Tracking[1] },
                      { ...Tracking[2], Track_15_pm: e.target.value },
                      { ...Tracking[3] },
                      { ...Tracking[4] },
                    ])
                  }
                  disabled
                />
              </td>
              <td>
                <input
                  type="text"
                  value={Tracking[2].Track_19_pm}
                  onChange={(e) =>
                    setTracking([
                      { ...Tracking[0] },
                      { ...Tracking[1] },
                      { ...Tracking[2], Track_19_pm: e.target.value },
                      { ...Tracking[3] },
                      { ...Tracking[4] },
                    ])
                  }
                  disabled
                />
              </td>
              <td>
                <input
                  type="text"
                  value={Tracking[2].Track_23_pm}
                  onChange={(e) =>
                    setTracking([
                      { ...Tracking[0] },
                      { ...Tracking[1] },
                      { ...Tracking[2], Track_23_pm: e.target.value },
                      { ...Tracking[3] },
                      { ...Tracking[4] },
                    ])
                  }
                  disabled
                />
              </td>
              <td>
                <input
                  type="text"
                  value={Tracking[2].Track_3_am}
                  onChange={(e) =>
                    setTracking([
                      { ...Tracking[0] },
                      { ...Tracking[1] },
                      { ...Tracking[2], Track_3_am: e.target.value },
                      { ...Tracking[3] },
                      { ...Tracking[4] },
                    ])
                  }
                  disabled
                />
              </td>
            </tr>
            <tr>
              <td>Material used</td>
              <td>
                <input
                  type="text"
                  title={Tracking[3].Track_7_am}
                  value={Tracking[3].Track_7_am}
                  onChange={(e) =>
                    setTracking([
                      { ...Tracking[0] },
                      { ...Tracking[1] },
                      { ...Tracking[2] },
                      { ...Tracking[3], Track_7_am: e.target.value },
                      { ...Tracking[4] },
                    ])
                  }
                  disabled
                />
              </td>
              <td>
                <input
                  type="text"
                  title={Tracking[3].Track_11_am}
                  value={Tracking[3].Track_11_am}
                  onChange={(e) =>
                    setTracking([
                      { ...Tracking[0] },
                      { ...Tracking[1] },
                      { ...Tracking[2] },
                      { ...Tracking[3], Track_11_am: e.target.value },
                      { ...Tracking[4] },
                    ])
                  }
                  disabled
                />
              </td>
              <td>
                <input
                  type="text"
                  title={Tracking[3].Track_15_pm}
                  value={Tracking[3].Track_15_pm}
                  onChange={(e) =>
                    setTracking([
                      { ...Tracking[0] },
                      { ...Tracking[1] },
                      { ...Tracking[2] },
                      { ...Tracking[3], Track_15_pm: e.target.value },
                      { ...Tracking[4] },
                    ])
                  }
                  disabled
                />
              </td>
              <td>
                <input
                  type="text"
                  title={Tracking[3].Track_19_pm}
                  value={Tracking[3].Track_19_pm}
                  onChange={(e) =>
                    setTracking([
                      { ...Tracking[0] },
                      { ...Tracking[1] },
                      { ...Tracking[2] },
                      { ...Tracking[3], Track_19_pm: e.target.value },
                      { ...Tracking[4] },
                    ])
                  }
                  disabled
                />
              </td>
              <td>
                <input
                  type="text"
                  title={Tracking[3].Track_23_pm}
                  value={Tracking[3].Track_23_pm}
                  onChange={(e) =>
                    setTracking([
                      { ...Tracking[0] },
                      { ...Tracking[1] },
                      { ...Tracking[2] },
                      { ...Tracking[3], Track_23_pm: e.target.value },
                      { ...Tracking[4] },
                    ])
                  }
                  disabled
                />
              </td>
              <td>
                <input
                  type="text"
                  title={Tracking[3].Track_3_am}
                  value={Tracking[3].Track_3_am}
                  onChange={(e) =>
                    setTracking([
                      { ...Tracking[0] },
                      { ...Tracking[1] },
                      { ...Tracking[2] },
                      { ...Tracking[3], Track_3_am: e.target.value },
                      { ...Tracking[4] },
                    ])
                  }
                  disabled
                />
              </td>
            </tr>
            <tr>
              <td>Material grade</td>
              <td>
                <input                
                  type="text"
                  title={Tracking[4].Track_7_am}
                  value={Tracking[4].Track_7_am}
                  onChange={(e) =>
                    setTracking([
                      { ...Tracking[0] },
                      { ...Tracking[1] },
                      { ...Tracking[2] },
                      { ...Tracking[3] },
                      { ...Tracking[4], Track_7_am: e.target.value },
                    ])
                  }
                  disabled
                />
              </td>
              <td>
                <input
                  type="text"
                  title={Tracking[4].Track_11_am}
                  value={Tracking[4].Track_11_am}
                  onChange={(e) =>
                    setTracking([
                      { ...Tracking[0] },
                      { ...Tracking[1] },
                      { ...Tracking[2] },
                      { ...Tracking[3] },
                      { ...Tracking[4], Track_11_am: e.target.value },
                    ])
                  }
                  disabled
                />
              </td>
              <td>
                <input
                  type="text"
                  title={Tracking[4].Track_15_pm}
                  value={Tracking[4].Track_15_pm}
                  onChange={(e) =>
                    setTracking([
                      { ...Tracking[0] },
                      { ...Tracking[1] },
                      { ...Tracking[2] },
                      { ...Tracking[3] },
                      { ...Tracking[4], Track_15_pm: e.target.value },
                    ])
                  }
                  disabled
                />
              </td>
              <td>
                <input
                  type="text"
                  title={Tracking[4].Track_19_pm}
                  value={Tracking[4].Track_19_pm}
                  onChange={(e) =>
                    setTracking([
                      { ...Tracking[0] },
                      { ...Tracking[1] },
                      { ...Tracking[2] },
                      { ...Tracking[3] },
                      { ...Tracking[4], Track_19_pm: e.target.value },
                    ])
                  }
                  disabled
                />
              </td>
              <td>
                <input
                  type="text"
                  title={Tracking[4].Track_23_pm}
                  value={Tracking[4].Track_23_pm}
                  onChange={(e) =>
                    setTracking([
                      { ...Tracking[0] },
                      { ...Tracking[1] },
                      { ...Tracking[2] },
                      { ...Tracking[3] },
                      { ...Tracking[4], Track_23_pm: e.target.value },
                    ])
                  }
                  disabled
                />
              </td>
              <td>
                <input
                  type="text"
                  title={Tracking[4].Track_3_am}
                  value={Tracking[4].Track_3_am}
                  onChange={(e) =>
                    setTracking([
                      { ...Tracking[0] },
                      { ...Tracking[1] },
                      { ...Tracking[2] },
                      { ...Tracking[3] },
                      { ...Tracking[4], Track_3_am: e.target.value },
                    ])
                  }
                  disabled
                />
              </td>
            </tr>
          </tbody>
        </table>

        <table
          className="table"
          width="50%"
          style={{ textAlign: "center" }}
          border="0"
        >
          <tbody>
            <tr style={{ textAlign: "center" }}>
              <th style={{ textAlign: "center" }} colSpan="15">
                Heating parameter
              </th>
            </tr>
            <tr>
              <th width="8%" style={{ textAlign: "center", width: "1%" }}>
                SL
              </th>
              <th width="30%" style={{ textAlign: "center" }}>
                Description
              </th>
              <th width="25%" style={{ textAlign: "center", width: "8%" }}>
                Inst-used
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Set
              </th>
              <th
                style={{
                  textAlign: "center",
                  width: "7%",
                  padding: "0px 20px",
                }}
              >
                Units
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Actual
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Actual
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Actual
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Actual
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Actual
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Actual
              </th>
            </tr>
            <tr>
              <td>01</td>
              <td>MTC Temperature</td>
              <td>On pannel</td>
              <td>
                {" "}
                ({Heat[0].Heat_smp_data}) ±{Heat[0].Heat_smp_set}°C
              </td>
              <td>°C</td>
              <td>
                <input
                  id="Heat1-1"
                  type="text"
                  value={Heat[0].Heat_actual_1}
                  style={
                    !(
                      parseFloat(Heat[0].Heat_actual_1) >
                      parseFloat(Heat[0].Heat_smp_data) +
                      parseFloat(Heat[0].Heat_smp_set) ||
                      parseFloat(Heat[0].Heat_actual_1) <
                      parseFloat(Heat[0].Heat_smp_data) -
                      parseFloat(Heat[0].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0], Heat_actual_1: e.target.value },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  disabled={Disable.Hour1}
                />
              </td>
              <td>
                <input
                  id="Heat1-2"
                  type="text"
                  value={Heat[0].Heat_actual_2}
                  style={
                    !(
                      parseFloat(Heat[0].Heat_actual_2) >
                      parseFloat(Heat[0].Heat_smp_data) +
                      parseFloat(Heat[0].Heat_smp_set) ||
                      parseFloat(Heat[0].Heat_actual_2) <
                      parseFloat(Heat[0].Heat_smp_data) -
                      parseFloat(Heat[0].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0], Heat_actual_2: e.target.value },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  disabled={Disable.Hour2}
                />
              </td>
              <td>
                <input
                  id="Heat1-3"
                  type="text"
                  value={Heat[0].Heat_actual_3}
                  style={
                    !(
                      parseFloat(Heat[0].Heat_actual_3) >
                      parseFloat(Heat[0].Heat_smp_data) +
                      parseFloat(Heat[0].Heat_smp_set) ||
                      parseFloat(Heat[0].Heat_actual_3) <
                      parseFloat(Heat[0].Heat_smp_data) -
                      parseFloat(Heat[0].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0], Heat_actual_3: e.target.value },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  disabled={Disable.Hour3}
                />
              </td>
              <td>
                <input
                  id="Heat1-4"
                  type="text"
                  value={Heat[0].Heat_actual_4}
                  style={
                    !(
                      parseFloat(Heat[0].Heat_actual_4) >
                      parseFloat(Heat[0].Heat_smp_data) +
                      parseFloat(Heat[0].Heat_smp_set) ||
                      parseFloat(Heat[0].Heat_actual_4) <
                      parseFloat(Heat[0].Heat_smp_data) -
                      parseFloat(Heat[0].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0], Heat_actual_4: e.target.value },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  disabled={Disable.Hour4}
                />
              </td>
              <td>
                <input
                  type="text"
                  id="Heat1-5"
                  value={Heat[0].Heat_actual_5}
                  style={
                    !(
                      parseFloat(Heat[0].Heat_actual_5) >
                      parseFloat(Heat[0].Heat_smp_data) +
                      parseFloat(Heat[0].Heat_smp_set) ||
                      parseFloat(Heat[0].Heat_actual_5) <
                      parseFloat(Heat[0].Heat_smp_data) -
                      parseFloat(Heat[0].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0], Heat_actual_5: e.target.value },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  disabled={Disable.Hour5}
                />
              </td>
              <td>
                <input
                  type="text"
                  id="Heat1-6"
                  value={Heat[0].Heat_actual_6}
                  style={
                    !(
                      parseFloat(Heat[0].Heat_actual_6) >
                      parseFloat(Heat[0].Heat_smp_data) +
                      parseFloat(Heat[0].Heat_smp_set) ||
                      parseFloat(Heat[0].Heat_actual_6) <
                      parseFloat(Heat[0].Heat_smp_data) -
                      parseFloat(Heat[0].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0], Heat_actual_6: e.target.value },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  disabled={Disable.Hour6}
                />
              </td>
            </tr>
            <tr>
              <td>02</td>
              <td>Hopper Temperature</td>
              <td>On pannel</td>
              <td>
                {" "}
                ({Heat[1].Heat_smp_data})±{Heat[1].Heat_smp_set}°C
              </td>
              <td>°C</td>
              <td>
                <input
                  type="text"
                  value={Heat[1].Heat_actual_1}
                  style={
                    !(
                      parseFloat(Heat[1].Heat_actual_1) >
                      parseFloat(Heat[1].Heat_smp_data) +
                      parseFloat(Heat[1].Heat_smp_set) ||
                      parseFloat(Heat[1].Heat_actual_1) <
                      parseFloat(Heat[1].Heat_smp_data) -
                      parseFloat(Heat[1].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1], Heat_actual_1: e.target.value },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat2-1"
                  disabled={Disable.Hour1}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[1].Heat_actual_2) >
                      parseFloat(Heat[1].Heat_smp_data) +
                      parseFloat(Heat[1].Heat_smp_set) ||
                      parseFloat(Heat[1].Heat_actual_2) <
                      parseFloat(Heat[1].Heat_smp_data) -
                      parseFloat(Heat[1].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[1].Heat_actual_2}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1], Heat_actual_2: e.target.value },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat2-2"
                  disabled={Disable.Hour2}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[1].Heat_actual_3) >
                      parseFloat(Heat[1].Heat_smp_data) +
                      parseFloat(Heat[1].Heat_smp_set) ||
                      parseFloat(Heat[1].Heat_actual_3) <
                      parseFloat(Heat[1].Heat_smp_data) -
                      parseFloat(Heat[1].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[1].Heat_actual_3}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1], Heat_actual_3: e.target.value },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat2-3"
                  disabled={Disable.Hour3}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[1].Heat_actual_4) >
                      parseFloat(Heat[1].Heat_smp_data) +
                      parseFloat(Heat[1].Heat_smp_set) ||
                      parseFloat(Heat[1].Heat_actual_4) <
                      parseFloat(Heat[1].Heat_smp_data) -
                      parseFloat(Heat[1].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[1].Heat_actual_4}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1], Heat_actual_4: e.target.value },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat2-4"
                  disabled={Disable.Hour4}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[1].Heat_actual_5) >
                      parseFloat(Heat[1].Heat_smp_data) +
                      parseFloat(Heat[1].Heat_smp_set) ||
                      parseFloat(Heat[1].Heat_actual_5) <
                      parseFloat(Heat[1].Heat_smp_data) -
                      parseFloat(Heat[1].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[1].Heat_actual_5}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1], Heat_actual_5: e.target.value },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat2-5"
                  disabled={Disable.Hour5}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[1].Heat_actual_6) >
                      parseFloat(Heat[1].Heat_smp_data) +
                      parseFloat(Heat[1].Heat_smp_set) ||
                      parseFloat(Heat[1].Heat_actual_6) <
                      parseFloat(Heat[1].Heat_smp_data) -
                      parseFloat(Heat[1].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[1].Heat_actual_6}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1], Heat_actual_6: e.target.value },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat2-6"
                  disabled={Disable.Hour6}
                />
              </td>
            </tr>
            <tr>
              <td>03</td>
              <td>Nozzle Temperature</td>
              <td>On pannel</td>
              <td>
                {" "}
                ({Heat[2].Heat_smp_data})±{Heat[2].Heat_smp_set}°C
              </td>
              <td>°C</td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[2].Heat_actual_1) >
                      parseFloat(Heat[2].Heat_smp_data) +
                      parseFloat(Heat[2].Heat_smp_set) ||
                      parseFloat(Heat[2].Heat_actual_1) <
                      parseFloat(Heat[2].Heat_smp_data) -
                      parseFloat(Heat[2].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[2].Heat_actual_1}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2], Heat_actual_1: e.target.value },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat3-1"
                  disabled={Disable.Hour1}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[2].Heat_actual_2) >
                      parseFloat(Heat[2].Heat_smp_data) +
                      parseFloat(Heat[2].Heat_smp_set) ||
                      parseFloat(Heat[2].Heat_actual_2) <
                      parseFloat(Heat[2].Heat_smp_data) -
                      parseFloat(Heat[2].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[2].Heat_actual_2}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2], Heat_actual_2: e.target.value },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat3-2"
                  disabled={Disable.Hour2}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[2].Heat_actual_3) >
                      parseFloat(Heat[2].Heat_smp_data) +
                      parseFloat(Heat[2].Heat_smp_set) ||
                      parseFloat(Heat[2].Heat_actual_3) <
                      parseFloat(Heat[2].Heat_smp_data) -
                      parseFloat(Heat[2].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[2].Heat_actual_3}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2], Heat_actual_3: e.target.value },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat3-3"
                  disabled={Disable.Hour3}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[2].Heat_actual_4) >
                      parseFloat(Heat[2].Heat_smp_data) +
                      parseFloat(Heat[2].Heat_smp_set) ||
                      parseFloat(Heat[2].Heat_actual_4) <
                      parseFloat(Heat[2].Heat_smp_data) -
                      parseFloat(Heat[2].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[2].Heat_actual_4}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2], Heat_actual_4: e.target.value },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat3-4"
                  disabled={Disable.Hour4}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[2].Heat_actual_5) >
                      parseFloat(Heat[2].Heat_smp_data) +
                      parseFloat(Heat[2].Heat_smp_set) ||
                      parseFloat(Heat[2].Heat_actual_5) <
                      parseFloat(Heat[2].Heat_smp_data) -
                      parseFloat(Heat[2].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[2].Heat_actual_5}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2], Heat_actual_5: e.target.value },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat3-5"
                  disabled={Disable.Hour5}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[2].Heat_actual_6) >
                      parseFloat(Heat[2].Heat_smp_data) +
                      parseFloat(Heat[2].Heat_smp_set) ||
                      parseFloat(Heat[2].Heat_actual_6) <
                      parseFloat(Heat[2].Heat_smp_data) -
                      parseFloat(Heat[2].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[2].Heat_actual_6}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2], Heat_actual_6: e.target.value },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat3-6"
                  disabled={Disable.Hour6}
                />
              </td>
            </tr>
            <tr>
              <td>04</td>
              <td>Zone 1</td>
              <td>On pannel</td>
              <td>
                {" "}
                ({Heat[3].Heat_smp_data})±{Heat[3].Heat_smp_set}°C
              </td>
              <td>°C</td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[3].Heat_actual_1) >
                      parseFloat(Heat[3].Heat_smp_data) +
                      parseFloat(Heat[3].Heat_smp_set) ||
                      parseFloat(Heat[3].Heat_actual_1) <
                      parseFloat(Heat[3].Heat_smp_data) -
                      parseFloat(Heat[3].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[3].Heat_actual_1}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3], Heat_actual_1: e.target.value },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat4-1"
                  disabled={Disable.Hour1}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[3].Heat_actual_2) >
                      parseFloat(Heat[3].Heat_smp_data) +
                      parseFloat(Heat[3].Heat_smp_set) ||
                      parseFloat(Heat[3].Heat_actual_2) <
                      parseFloat(Heat[3].Heat_smp_data) -
                      parseFloat(Heat[3].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[3].Heat_actual_2}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3], Heat_actual_2: e.target.value },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat4-2"
                  disabled={Disable.Hour2}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[3].Heat_actual_3) >
                      parseFloat(Heat[3].Heat_smp_data) +
                      parseFloat(Heat[3].Heat_smp_set) ||
                      parseFloat(Heat[3].Heat_actual_3) <
                      parseFloat(Heat[3].Heat_smp_data) -
                      parseFloat(Heat[3].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[3].Heat_actual_3}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3], Heat_actual_3: e.target.value },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat4-3"
                  disabled={Disable.Hour3}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[3].Heat_actual_4) >
                      parseFloat(Heat[3].Heat_smp_data) +
                      parseFloat(Heat[3].Heat_smp_set) ||
                      parseFloat(Heat[3].Heat_actual_4) <
                      parseFloat(Heat[3].Heat_smp_data) -
                      parseFloat(Heat[3].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[3].Heat_actual_4}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3], Heat_actual_4: e.target.value },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat4-4"
                  disabled={Disable.Hour4}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[3].Heat_actual_5) >
                      parseFloat(Heat[3].Heat_smp_data) +
                      parseFloat(Heat[3].Heat_smp_set) ||
                      parseFloat(Heat[3].Heat_actual_5) <
                      parseFloat(Heat[3].Heat_smp_data) -
                      parseFloat(Heat[3].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[3].Heat_actual_5}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3], Heat_actual_5: e.target.value },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat4-5"
                  disabled={Disable.Hour5}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[3].Heat_actual_6) >
                      parseFloat(Heat[3].Heat_smp_data) +
                      parseFloat(Heat[3].Heat_smp_set) ||
                      parseFloat(Heat[3].Heat_actual_6) <
                      parseFloat(Heat[3].Heat_smp_data) -
                      parseFloat(Heat[3].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[3].Heat_actual_6}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3], Heat_actual_6: e.target.value },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat4-6"
                  disabled={Disable.Hour6}
                />
              </td>
            </tr>
            <tr>
              <td>05</td>
              <td>Zone 2</td>
              <td>On pannel</td>
              <td>
                {" "}
                ({Heat[4].Heat_smp_data})±{Heat[4].Heat_smp_set}°C
              </td>
              <td>°C</td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[4].Heat_actual_1) >
                      parseFloat(Heat[4].Heat_smp_data) +
                      parseFloat(Heat[4].Heat_smp_set) ||
                      parseFloat(Heat[4].Heat_actual_1) <
                      parseFloat(Heat[4].Heat_smp_data) -
                      parseFloat(Heat[4].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[4].Heat_actual_1}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4], Heat_actual_1: e.target.value },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat5-1"
                  disabled={Disable.Hour1}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[4].Heat_actual_2) >
                      parseFloat(Heat[4].Heat_smp_data) +
                      parseFloat(Heat[4].Heat_smp_set) ||
                      parseFloat(Heat[4].Heat_actual_2) <
                      parseFloat(Heat[4].Heat_smp_data) -
                      parseFloat(Heat[4].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[4].Heat_actual_2}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4], Heat_actual_2: e.target.value },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat5-2"
                  disabled={Disable.Hour2}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[4].Heat_actual_3) >
                      parseFloat(Heat[4].Heat_smp_data) +
                      parseFloat(Heat[4].Heat_smp_set) ||
                      parseFloat(Heat[4].Heat_actual_3) <
                      parseFloat(Heat[4].Heat_smp_data) -
                      parseFloat(Heat[4].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[4].Heat_actual_3}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4], Heat_actual_3: e.target.value },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat5-3"
                  disabled={Disable.Hour3}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[4].Heat_actual_4) >
                      parseFloat(Heat[4].Heat_smp_data) +
                      parseFloat(Heat[4].Heat_smp_set) ||
                      parseFloat(Heat[4].Heat_actual_4) <
                      parseFloat(Heat[4].Heat_smp_data) -
                      parseFloat(Heat[4].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[4].Heat_actual_4}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4], Heat_actual_4: e.target.value },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat5-4"
                  disabled={Disable.Hour4}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[4].Heat_actual_5) >
                      parseFloat(Heat[4].Heat_smp_data) +
                      parseFloat(Heat[4].Heat_smp_set) ||
                      parseFloat(Heat[4].Heat_actual_5) <
                      parseFloat(Heat[4].Heat_smp_data) -
                      parseFloat(Heat[4].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[4].Heat_actual_5}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4], Heat_actual_5: e.target.value },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat5-5"
                  disabled={Disable.Hour5}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[4].Heat_actual_6) >
                      parseFloat(Heat[4].Heat_smp_data) +
                      parseFloat(Heat[4].Heat_smp_set) ||
                      parseFloat(Heat[4].Heat_actual_6) <
                      parseFloat(Heat[4].Heat_smp_data) -
                      parseFloat(Heat[4].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[4].Heat_actual_6}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4], Heat_actual_6: e.target.value },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat5-6"
                  disabled={Disable.Hour6}
                />
              </td>
            </tr>
            <tr>
              <td>06</td>
              <td>Zone 3</td>
              <td>On pannel</td>
              <td>
                {" "}
                ({Heat[5].Heat_smp_data})±{Heat[5].Heat_smp_set}°C
              </td>
              <td>°C</td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[5].Heat_actual_1) >
                      parseFloat(Heat[5].Heat_smp_data) +
                      parseFloat(Heat[5].Heat_smp_set) ||
                      parseFloat(Heat[5].Heat_actual_1) <
                      parseFloat(Heat[5].Heat_smp_data) -
                      parseFloat(Heat[5].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[5].Heat_actual_1}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5], Heat_actual_1: e.target.value },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat6-1"
                  disabled={Disable.Hour1}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[5].Heat_actual_2) >
                      parseFloat(Heat[5].Heat_smp_data) +
                      parseFloat(Heat[5].Heat_smp_set) ||
                      parseFloat(Heat[5].Heat_actual_2) <
                      parseFloat(Heat[5].Heat_smp_data) -
                      parseFloat(Heat[5].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[5].Heat_actual_2}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5], Heat_actual_2: e.target.value },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat6-2"
                  disabled={Disable.Hour2}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[5].Heat_actual_3) >
                      parseFloat(Heat[5].Heat_smp_data) +
                      parseFloat(Heat[5].Heat_smp_set) ||
                      parseFloat(Heat[5].Heat_actual_3) <
                      parseFloat(Heat[5].Heat_smp_data) -
                      parseFloat(Heat[5].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[5].Heat_actual_3}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5], Heat_actual_3: e.target.value },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat6-3"
                  disabled={Disable.Hour3}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[5].Heat_actual_4) >
                      parseFloat(Heat[5].Heat_smp_data) +
                      parseFloat(Heat[5].Heat_smp_set) ||
                      parseFloat(Heat[5].Heat_actual_4) <
                      parseFloat(Heat[5].Heat_smp_data) -
                      parseFloat(Heat[5].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[5].Heat_actual_4}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5], Heat_actual_4: e.target.value },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat6-4"
                  disabled={Disable.Hour4}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[5].Heat_actual_5) >
                      parseFloat(Heat[5].Heat_smp_data) +
                      parseFloat(Heat[5].Heat_smp_set) ||
                      parseFloat(Heat[5].Heat_actual_5) <
                      parseFloat(Heat[5].Heat_smp_data) -
                      parseFloat(Heat[5].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[5].Heat_actual_5}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5], Heat_actual_5: e.target.value },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat6-5"
                  disabled={Disable.Hour5}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[5].Heat_actual_6) >
                      parseFloat(Heat[5].Heat_smp_data) +
                      parseFloat(Heat[5].Heat_smp_set) ||
                      parseFloat(Heat[5].Heat_actual_6) <
                      parseFloat(Heat[5].Heat_smp_data) -
                      parseFloat(Heat[5].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[5].Heat_actual_6}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5], Heat_actual_6: e.target.value },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat6-6"
                  disabled={Disable.Hour6}
                />
              </td>
            </tr>
            <tr>
              <td>07</td>
              <td>Zone 4</td>
              <td>On pannel</td>
              <td>
                ({Heat[6].Heat_smp_data})±{Heat[6].Heat_smp_set}°C
              </td>
              <td>°C</td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[6].Heat_actual_1) >
                      parseFloat(Heat[6].Heat_smp_data) +
                      parseFloat(Heat[6].Heat_smp_set) ||
                      parseFloat(Heat[6].Heat_actual_1) <
                      parseFloat(Heat[6].Heat_smp_data) -
                      parseFloat(Heat[6].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[6].Heat_actual_1}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6], Heat_actual_1: e.target.value },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat7-1"
                  disabled={Disable.Hour1}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[6].Heat_actual_2) >
                      parseFloat(Heat[6].Heat_smp_data) +
                      parseFloat(Heat[6].Heat_smp_set) ||
                      parseFloat(Heat[6].Heat_actual_2) <
                      parseFloat(Heat[6].Heat_smp_data) -
                      parseFloat(Heat[6].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[6].Heat_actual_2}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6], Heat_actual_2: e.target.value },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat7-2"
                  disabled={Disable.Hour2}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[6].Heat_actual_3) >
                      parseFloat(Heat[6].Heat_smp_data) +
                      parseFloat(Heat[6].Heat_smp_set) ||
                      parseFloat(Heat[6].Heat_actual_3) <
                      parseFloat(Heat[6].Heat_smp_data) -
                      parseFloat(Heat[6].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[6].Heat_actual_3}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6], Heat_actual_3: e.target.value },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat7-3"
                  disabled={Disable.Hour3}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[6].Heat_actual_4) >
                      parseFloat(Heat[6].Heat_smp_data) +
                      parseFloat(Heat[6].Heat_smp_set) ||
                      parseFloat(Heat[6].Heat_actual_4) <
                      parseFloat(Heat[6].Heat_smp_data) -
                      parseFloat(Heat[6].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[6].Heat_actual_4}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6], Heat_actual_4: e.target.value },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat7-4"
                  disabled={Disable.Hour4}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[6].Heat_actual_5) >
                      parseFloat(Heat[6].Heat_smp_data) +
                      parseFloat(Heat[6].Heat_smp_set) ||
                      parseFloat(Heat[6].Heat_actual_5) <
                      parseFloat(Heat[6].Heat_smp_data) -
                      parseFloat(Heat[6].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[6].Heat_actual_5}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6], Heat_actual_5: e.target.value },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat7-5"
                  disabled={Disable.Hour5}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[6].Heat_actual_6) >
                      parseFloat(Heat[6].Heat_smp_data) +
                      parseFloat(Heat[6].Heat_smp_set) ||
                      parseFloat(Heat[6].Heat_actual_6) <
                      parseFloat(Heat[6].Heat_smp_data) -
                      parseFloat(Heat[6].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[6].Heat_actual_6}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6], Heat_actual_6: e.target.value },
                      { ...Heat[7] },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat7-6"
                  disabled={Disable.Hour6}
                />
              </td>
            </tr>
            <tr>
              <td>08</td>
              <td>Zone 5</td>
              <td>On pannel</td>
              <td>
                ({Heat[7].Heat_smp_data})±{Heat[7].Heat_smp_set}°C
              </td>
              <td>°C</td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[7].Heat_actual_1) >
                      parseFloat(Heat[7].Heat_smp_data) +
                      parseFloat(Heat[7].Heat_smp_set) ||
                      parseFloat(Heat[7].Heat_actual_1) <
                      parseFloat(Heat[7].Heat_smp_data) -
                      parseFloat(Heat[7].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[7].Heat_actual_1}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7], Heat_actual_1: e.target.value },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat8-1"
                  disabled={Disable.Hour1}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[7].Heat_actual_2) >
                      parseFloat(Heat[7].Heat_smp_data) +
                      parseFloat(Heat[7].Heat_smp_set) ||
                      parseFloat(Heat[7].Heat_actual_2) <
                      parseFloat(Heat[7].Heat_smp_data) -
                      parseFloat(Heat[7].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[7].Heat_actual_2}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7], Heat_actual_2: e.target.value },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat8-2"
                  disabled={Disable.Hour2}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[7].Heat_actual_3) >
                      parseFloat(Heat[7].Heat_smp_data) +
                      parseFloat(Heat[7].Heat_smp_set) ||
                      parseFloat(Heat[7].Heat_actual_3) <
                      parseFloat(Heat[7].Heat_smp_data) -
                      parseFloat(Heat[7].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[7].Heat_actual_3}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7], Heat_actual_3: e.target.value },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat8-3"
                  disabled={Disable.Hour3}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[7].Heat_actual_4) >
                      parseFloat(Heat[7].Heat_smp_data) +
                      parseFloat(Heat[7].Heat_smp_set) ||
                      parseFloat(Heat[7].Heat_actual_4) <
                      parseFloat(Heat[7].Heat_smp_data) -
                      parseFloat(Heat[7].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[7].Heat_actual_4}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7], Heat_actual_4: e.target.value },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat8-4"
                  disabled={Disable.Hour4}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[7].Heat_actual_5) >
                      parseFloat(Heat[7].Heat_smp_data) +
                      parseFloat(Heat[7].Heat_smp_set) ||
                      parseFloat(Heat[7].Heat_actual_5) <
                      parseFloat(Heat[7].Heat_smp_data) -
                      parseFloat(Heat[7].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[7].Heat_actual_5}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7], Heat_actual_5: e.target.value },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat8-5"
                  disabled={Disable.Hour5}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[7].Heat_actual_6) >
                      parseFloat(Heat[7].Heat_smp_data) +
                      parseFloat(Heat[7].Heat_smp_set) ||
                      parseFloat(Heat[7].Heat_actual_6) <
                      parseFloat(Heat[7].Heat_smp_data) -
                      parseFloat(Heat[7].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[7].Heat_actual_6}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7], Heat_actual_6: e.target.value },
                      { ...Heat[8] },
                    ]);
                  }}
                  id="Heat8-6"
                  disabled={Disable.Hour6}
                />
              </td>
            </tr>
            <tr>
              <td>09</td>
              <td>Zone 6</td>
              <td>On pannel</td>
              <td>
                ({Heat[8].Heat_smp_data})±{Heat[8].Heat_smp_set}°C
              </td>
              <td>°C</td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[8].Heat_actual_1) >
                      parseFloat(Heat[8].Heat_smp_data) +
                      parseFloat(Heat[8].Heat_smp_set) ||
                      parseFloat(Heat[8].Heat_actual_1) <
                      parseFloat(Heat[8].Heat_smp_data) -
                      parseFloat(Heat[8].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[8].Heat_actual_1}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8], Heat_actual_1: e.target.value },
                    ]);
                  }}
                  id="Heat9-1"
                  disabled={Disable.Hour1}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[8].Heat_actual_2) >
                      parseFloat(Heat[8].Heat_smp_data) +
                      parseFloat(Heat[8].Heat_smp_set) ||
                      parseFloat(Heat[8].Heat_actual_2) <
                      parseFloat(Heat[8].Heat_smp_data) -
                      parseFloat(Heat[8].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[8].Heat_actual_2}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8], Heat_actual_2: e.target.value },
                    ]);
                  }}
                  id="Heat9-2"
                  disabled={Disable.Hour2}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[8].Heat_actual_3) >
                      parseFloat(Heat[8].Heat_smp_data) +
                      parseFloat(Heat[8].Heat_smp_set) ||
                      parseFloat(Heat[8].Heat_actual_3) <
                      parseFloat(Heat[8].Heat_smp_data) -
                      parseFloat(Heat[8].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[8].Heat_actual_3}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8], Heat_actual_3: e.target.value },
                    ]);
                  }}
                  id="Heat9-3"
                  disabled={Disable.Hour3}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[8].Heat_actual_4) >
                      parseFloat(Heat[8].Heat_smp_data) +
                      parseFloat(Heat[8].Heat_smp_set) ||
                      parseFloat(Heat[8].Heat_actual_4) <
                      parseFloat(Heat[8].Heat_smp_data) -
                      parseFloat(Heat[8].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[8].Heat_actual_4}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8], Heat_actual_4: e.target.value },
                    ]);
                  }}
                  id="Heat9-4"
                  disabled={Disable.Hour4}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[8].Heat_actual_5) >
                      parseFloat(Heat[8].Heat_smp_data) +
                      parseFloat(Heat[8].Heat_smp_set) ||
                      parseFloat(Heat[8].Heat_actual_5) <
                      parseFloat(Heat[8].Heat_smp_data) -
                      parseFloat(Heat[8].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[8].Heat_actual_5}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8], Heat_actual_5: e.target.value },
                    ]);
                  }}
                  id="Heat9-5"
                  disabled={Disable.Hour5}
                />
              </td>
              <td>
                <input
                  type="text"
                  style={
                    !(
                      parseFloat(Heat[8].Heat_actual_6) >
                      parseFloat(Heat[8].Heat_smp_data) +
                      parseFloat(Heat[8].Heat_smp_set) ||
                      parseFloat(Heat[8].Heat_actual_6) <
                      parseFloat(Heat[8].Heat_smp_data) -
                      parseFloat(Heat[8].Heat_smp_set)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  value={Heat[8].Heat_actual_6}
                  onChange={(e) => {
                    setHeat([
                      { ...Heat[0] },
                      { ...Heat[1] },
                      { ...Heat[2] },
                      { ...Heat[3] },
                      { ...Heat[4] },
                      { ...Heat[5] },
                      { ...Heat[6] },
                      { ...Heat[7] },
                      { ...Heat[8], Heat_actual_6: e.target.value },
                    ]);
                  }}
                  id="Heat9-6"
                  disabled={Disable.Hour6}
                />
              </td>
            </tr>
          </tbody>
        </table>

        <table
          className="table"
          width="50%"
          style={{ textAlign: "center" }}
          border="0"
        >
          <tbody>
            <tr style={{ textAlign: "center" }}>
              <th style={{ textAlign: "center" }} colSpan="15">
                Injection parameter
              </th>
            </tr>
            <tr>
              <th width="10%" style={{ textAlign: "center", width: "1%" }}>
                SL
              </th>
              <th width="35%" style={{ textAlign: "center" }}>
                Description
              </th>
              <th width="25%" style={{ textAlign: "center", width: "8%" }}>
                Inst-used
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Set
              </th>
              <th
                width="5%"
                style={{
                  textAlign: "center",
                  width: "7%",
                  padding: "0px 20px",
                }}
              >
                Units
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Actual
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Actual
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Actual
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Actual
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Actual
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Actual
              </th>
            </tr>
            {Inject.map((item) => (
              <tr keys={item.Inject_sl}>
                <td>{item.Inject_sl}</td>
                <td>{item.Inject_desc}</td>
                <td>{item.Inject_inst_used}</td>
                <td>
                  ({item.Inject_smp_data})±{item.Inject_smp_set}
                </td>
                <td>{item.Inject_units}</td>
                <td>
                  <input
                    id={"cycle1-" + item.Inject_sl}
                    type="text"
                    style={
                      !(
                        parseFloat(item.Inject_actual_1) >
                        parseFloat(item.Inject_smp_data) +
                        parseFloat(item.Inject_smp_set) ||
                        parseFloat(item.Inject_actual_1) <
                        parseFloat(item.Inject_smp_data) -
                        parseFloat(item.Inject_smp_set)
                      )
                        ? { width: "100%" }
                        : {
                          width: "100%",
                          backgroundColor: "#d98984",
                          border: "2px solid #DA251A",
                        }
                    }
                    value={item.Inject_actual_1}
                    onChange={(e) => {
                      setInject((data) => {
                        let Value = [...Inject];
                        Value[Number(item.Inject_sl) - 1] = {
                          ...Inject[Number(item.Inject_sl) - 1],
                          Inject_actual_1: e.target.value,
                        };
                        return Value;
                      });
                    }}
                    disabled={Disable.Hour1}
                  />
                </td>
                <td>
                  <input
                    id={"cycle2-" + item.Inject_sl}
                    type="text"
                    value={item.Inject_actual_2}
                    style={
                      !(
                        parseFloat(item.Inject_actual_2) >
                        parseFloat(item.Inject_smp_data) +
                        parseFloat(item.Inject_smp_set) ||
                        parseFloat(item.Inject_actual_2) <
                        parseFloat(item.Inject_smp_data) -
                        parseFloat(item.Inject_smp_set)
                      )
                        ? { width: "100%" }
                        : {
                          width: "100%",
                          backgroundColor: "#d98984",
                          border: "2px solid #DA251A",
                        }
                    }
                    onChange={(e) => {
                      setInject((data) => {
                        let Value = [...Inject];
                        Value[Number(item.Inject_sl) - 1] = {
                          ...Inject[Number(item.Inject_sl) - 1],
                          Inject_actual_2: e.target.value,
                        };
                        return Value;
                      });
                    }}
                    disabled={Disable.Hour2}
                  />
                </td>
                <td>
                  <input
                    id={"cycle3-" + item.Inject_sl}
                    type="text"
                    style={
                      !(
                        parseFloat(item.Inject_actual_3) >
                        parseFloat(item.Inject_smp_data) +
                        parseFloat(item.Inject_smp_set) ||
                        parseFloat(item.Inject_actual_3) <
                        parseFloat(item.Inject_smp_data) -
                        parseFloat(item.Inject_smp_set)
                      )
                        ? { width: "100%" }
                        : {
                          width: "100%",
                          backgroundColor: "#d98984",
                          border: "2px solid #DA251A",
                        }
                    }
                    value={item.Inject_actual_3}
                    onChange={(e) => {
                      setInject((data) => {
                        let Value = [...Inject];
                        Value[Number(item.Inject_sl) - 1] = {
                          ...Inject[Number(item.Inject_sl) - 1],
                          Inject_actual_3: e.target.value,
                        };
                        return Value;
                      });
                    }}
                    disabled={Disable.Hour3}
                  />
                </td>
                <td>
                  <input
                    id={"cycle4-" + item.Inject_sl}
                    type="text"
                    style={
                      !(
                        parseFloat(item.Inject_actual_4) >
                        parseFloat(item.Inject_smp_data) +
                        parseFloat(item.Inject_smp_set) ||
                        parseFloat(item.Inject_actual_4) <
                        parseFloat(item.Inject_smp_data) -
                        parseFloat(item.Inject_smp_set)
                      )
                        ? { width: "100%" }
                        : {
                          width: "100%",
                          backgroundColor: "#d98984",
                          border: "2px solid #DA251A",
                        }
                    }
                    value={item.Inject_actual_4}
                    onChange={(e) => {
                      setInject((data) => {
                        let Value = [...Inject];
                        Value[Number(item.Inject_sl) - 1] = {
                          ...Inject[Number(item.Inject_sl) - 1],
                          Inject_actual_4: e.target.value,
                        };
                        return Value;
                      });
                    }}
                    disabled={Disable.Hour4}
                  />
                </td>
                <td>
                  <input
                    id={"cycle5-" + item.Inject_sl}
                    type="text"
                    style={
                      !(
                        parseFloat(item.Inject_actual_5) >
                        parseFloat(item.Inject_smp_data) +
                        parseFloat(item.Inject_smp_set) ||
                        parseFloat(item.Inject_actual_5) <
                        parseFloat(item.Inject_smp_data) -
                        parseFloat(item.Inject_smp_set)
                      )
                        ? { width: "100%" }
                        : {
                          width: "100%",
                          backgroundColor: "#d98984",
                          border: "2px solid #DA251A",
                        }
                    }
                    value={item.Inject_actual_5}
                    onChange={(e) => {
                      setInject((data) => {
                        let Value = [...Inject];
                        Value[Number(item.Inject_sl) - 1] = {
                          ...Inject[Number(item.Inject_sl) - 1],
                          Inject_actual_5: e.target.value,
                        };
                        return Value;
                      });
                    }}
                    disabled={Disable.Hour5}
                  />
                </td>
                <td>
                  <input
                    id={"cycle6-" + item.Inject_sl}
                    type="text"
                    style={
                      !(
                        parseFloat(item.Inject_actual_6) >
                        parseFloat(item.Inject_smp_data) +
                        parseFloat(item.Inject_smp_set) ||
                        parseFloat(item.Inject_actual_6) <
                        parseFloat(item.Inject_smp_data) -
                        parseFloat(item.Inject_smp_set)
                      )
                        ? { width: "100%" }
                        : {
                          width: "100%",
                          backgroundColor: "#d98984",
                          border: "2px solid #DA251A",
                        }
                    }
                    value={item.Inject_actual_6}
                    onChange={(e) => {
                      setInject((data) => {
                        let Value = [...Inject];
                        Value[Number(item.Inject_sl) - 1] = {
                          ...Inject[Number(item.Inject_sl) - 1],
                          Inject_actual_6: e.target.value,
                        };
                        return Value;
                      });
                    }}
                    disabled={Disable.Hour6}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <table
          className="table_hi"
          width="50%"
          style={{ textAlign: "center" }}
          border="5"
        >
          <tbody>
            <tr style={{ textAlign: "center" }}>
              <th style={{ textAlign: "center" }} colSpan="10">
                Mould Core & Cavity Temperature Monitoring
              </th>
            </tr>
            <tr>
              <th style={{ width: "3.5%", textAlign: "center" }} width="5%">
                A
              </th>
              <th style={{ textAlign: "center" }} width="25%">
                Normal condition
              </th>
              <th style={{ textAlign: "center", width: "5%" }}>Set</th>
              <th style={{ textAlign: "center" }}>Actual value</th>
            </tr>
            <tr>
              <th colSpan="3"></th>
              <td width="50">
                <table className="table_hi">
                  <tr valign="top">
                    <th width="30%" style={{ textAlign: "center" }}>
                      Shift-A
                    </th>
                    <th width="30%" style={{ textAlign: "center" }}>
                      Shift-B
                    </th>
                    <th width="30%" style={{ textAlign: "center" }}>
                      Shift-C
                    </th>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td>01</td>
              <td>TEMPERATURE of core side while machine running</td>
              <td>
                ({PMS[0].Core})±{PMS[0].CoreSet}°C
              </td>
              <td>
                <table className="table_hi">
                  <tbody>
                    <tr>
                      <td width="30%">
                        <input
                          type="text"
                          style={
                            !(
                              parseFloat(PMS[0].Tempcore1) >
                              parseFloat(PMS[0].Core) +
                              parseFloat(PMS[0].CoreSet) ||
                              parseFloat(PMS[0].Tempcore1) <
                              parseFloat(PMS[0].Core) -
                              parseFloat(PMS[0].CoreSet)
                            )
                              ? { width: "95%" }
                              : {
                                width: "95%",
                                backgroundColor: "#d98984",
                                border: "2px solid #DA251A",
                              }
                          }
                          value={PMS[0].Tempcore1}
                          name="Tempcore1"
                          onChange={HandleFeildChangePMS}
                          disabled={Disable.Hour1 && Disable.Hour2}
                        />
                      </td>
                      <td width="30%">
                        <input
                          type="text"
                          value={PMS[0].Tempcore2}
                          style={
                            !(
                              parseFloat(PMS[0].Tempcore2) >
                              parseFloat(PMS[0].Core) +
                              parseFloat(PMS[0].CoreSet) ||
                              parseFloat(PMS[0].Tempcore2) <
                              parseFloat(PMS[0].Core) -
                              parseFloat(PMS[0].CoreSet)
                            )
                              ? { width: "95%" }
                              : {
                                width: "95%",
                                backgroundColor: "#d98984",
                                border: "2px solid #DA251A",
                              }
                          }
                          name="Tempcore2"
                          onChange={HandleFeildChangePMS}
                          disabled={Disable.Hour3 && Disable.Hour4}
                        />
                      </td>
                      <td width="30%">
                        <input
                          type="text"
                          style={
                            !(
                              parseFloat(PMS[0].Tempcore3) >
                              parseFloat(PMS[0].Core) +
                              parseFloat(PMS[0].CoreSet) ||
                              parseFloat(PMS[0].Tempcore3) <
                              parseFloat(PMS[0].Core) -
                              parseFloat(PMS[0].CoreSet)
                            )
                              ? { width: "95%" }
                              : {
                                width: "95%",
                                backgroundColor: "#d98984",
                                border: "2px solid #DA251A",
                              }
                          }
                          value={PMS[0].Tempcore3}
                          name="Tempcore3"
                          onChange={HandleFeildChangePMS}
                          disabled={Disable.Hour5 && Disable.Hour6}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>02</td>
              <td>TEMPERATURE of CAVITY side while machine running</td>
              <td>
                ({PMS[0].Cavity})±{PMS[0].CavSet}°C
              </td>
              <td>
                <table className="table_hi">
                  <tbody>
                    <tr>
                      <td width="30%">
                        <input
                          type="text"
                          style={
                            !(
                              parseFloat(PMS[0].Tempcav1) >
                              parseFloat(PMS[0].Cavity) +
                              parseFloat(PMS[0].CavSet) ||
                              parseFloat(PMS[0].Tempcav1) <
                              parseFloat(PMS[0].Cavity) -
                              parseFloat(PMS[0].CavSet)
                            )
                              ? { width: "95%" }
                              : {
                                width: "95%",
                                backgroundColor: "#d98984",
                                border: "2px solid #DA251A",
                              }
                          }
                          value={PMS[0].Tempcav1}
                          name="Tempcav1"
                          onChange={HandleFeildChangePMS}
                          disabled={Disable.Hour1 && Disable.Hour2}
                        />
                      </td>
                      <td width="30%">
                        <input
                          type="text"
                          style={
                            !(
                              parseFloat(PMS[0].Tempcav2) >
                              parseFloat(PMS[0].Cavity) +
                              parseFloat(PMS[0].CavSet) ||
                              parseFloat(PMS[0].Tempcav2) <
                              parseFloat(PMS[0].Cavity) -
                              parseFloat(PMS[0].CavSet)
                            )
                              ? { width: "95%" }
                              : {
                                width: "95%",
                                backgroundColor: "#d98984",
                                border: "2px solid #DA251A",
                              }
                          }
                          value={PMS[0].Tempcav2}
                          name="Tempcav2"
                          onChange={HandleFeildChangePMS}
                          disabled={Disable.Hour3 && Disable.Hour4}
                        />
                      </td>
                      <td width="30%">
                        <input
                          type="text"
                          style={
                            !(
                              parseFloat(PMS[0].Tempcav3) >
                              parseFloat(PMS[0].Cavity) +
                              parseFloat(PMS[0].CavSet) ||
                              parseFloat(PMS[0].Tempcav3) <
                              parseFloat(PMS[0].Cavity) -
                              parseFloat(PMS[0].CavSet)
                            )
                              ? { width: "95%" }
                              : {
                                width: "95%",
                                backgroundColor: "#d98984",
                                border: "2px solid #DA251A",
                              }
                          }
                          value={PMS[0].Tempcav3}
                          name="Tempcav3"
                          onChange={HandleFeildChangePMS}
                          disabled={Disable.Hour5 && Disable.Hour6}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>

        <table
          className="table_hi"
          width="50%"
          style={{ textAlign: "center" }}
          border="5"
        >
          <tbody>
            <tr>
              <th style={{ width: "3.5%", textAlign: "center" }} width="5%">
                B
              </th>
              <th style={{ textAlign: "center" }} width="25%">
                With M T C
              </th>
              <th style={{ textAlign: "center", width: "5%" }}>Set</th>
              <th style={{ textAlign: "center" }}>Actual value</th>
            </tr>
            <tr>
              <td>01</td>
              <td>TEMPERATURE of core side while machine running</td>
              <td>
                ({PMS[0].Core})±{PMS[0].CoreSet}°C
              </td>
              <td>
                <table className="table_hi">
                  <tbody>
                    <tr>
                      <td width="30%">
                        <input
                          type="text"
                          style={
                            !(
                              parseFloat(PMS[0].Tempcore4) >
                              parseFloat(PMS[0].Core) +
                              parseFloat(PMS[0].CoreSet) ||
                              parseFloat(PMS[0].Tempcore4) <
                              parseFloat(PMS[0].Core) -
                              parseFloat(PMS[0].CoreSet)
                            )
                              ? { width: "95%" }
                              : {
                                width: "95%",
                                backgroundColor: "#d98984",
                                border: "2px solid #DA251A",
                              }
                          }
                          value={PMS[0].Tempcore4}
                          name="Tempcore4"
                          onChange={HandleFeildChangePMS}
                          disabled={Disable.Hour1 && Disable.Hour2}
                        />
                      </td>
                      <td width="30%">
                        <input
                          type="text"
                          style={
                            !(
                              parseFloat(PMS[0].Tempcore5) >
                              parseFloat(PMS[0].Core) +
                              parseFloat(PMS[0].CoreSet) ||
                              parseFloat(PMS[0].Tempcore5) <
                              parseFloat(PMS[0].Core) -
                              parseFloat(PMS[0].CoreSet)
                            )
                              ? { width: "95%" }
                              : {
                                width: "95%",
                                backgroundColor: "#d98984",
                                border: "2px solid #DA251A",
                              }
                          }
                          value={PMS[0].Tempcore5}
                          name="Tempcore5"
                          onChange={HandleFeildChangePMS}
                          disabled={Disable.Hour3 && Disable.Hour4}
                        />
                      </td>
                      <td width="30%">
                        <input
                          type="text"
                          style={
                            !(
                              parseFloat(PMS[0].Tempcore6) >
                              parseFloat(PMS[0].Core) +
                              parseFloat(PMS[0].CoreSet) ||
                              parseFloat(PMS[0].Tempcore6) <
                              parseFloat(PMS[0].Core) -
                              parseFloat(PMS[0].CoreSet)
                            )
                              ? { width: "95%" }
                              : {
                                width: "95%",
                                backgroundColor: "#d98984",
                                border: "2px solid #DA251A",
                              }
                          }
                          value={PMS[0].Tempcore6}
                          name="Tempcore6"
                          onChange={HandleFeildChangePMS}
                          disabled={Disable.Hour5 && Disable.Hour6}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>02</td>
              <td>TEMPERATURE of CAVITY side while machine running</td>
              <td>
                ({PMS[0].Cavity})±{PMS[0].CavSet}°C
              </td>
              <td>
                <table className="table_hi">
                  <tbody>
                    <tr>
                      <td width="30%">
                        <input
                          type="text"
                          style={
                            !(
                              parseFloat(PMS[0].Tempcav4) >
                              parseFloat(PMS[0].Cavity) +
                              parseFloat(PMS[0].CavSet) ||
                              parseFloat(PMS[0].Tempcav4) <
                              parseFloat(PMS[0].Cavity) -
                              parseFloat(PMS[0].CavSet)
                            )
                              ? { width: "95%" }
                              : {
                                width: "95%",
                                backgroundColor: "#d98984",
                                border: "2px solid #DA251A",
                              }
                          }
                          value={PMS[0].Tempcav4}
                          name="Tempcav4"
                          onChange={HandleFeildChangePMS}
                          disabled={Disable.Hour1 && Disable.Hour2}
                        />
                      </td>
                      <td width="30%">
                        <input
                          type="text"
                          style={
                            !(
                              parseFloat(PMS[0].Tempcav5) >
                              parseFloat(PMS[0].Cavity) +
                              parseFloat(PMS[0].CavSet) ||
                              parseFloat(PMS[0].Tempcav5) <
                              parseFloat(PMS[0].Cavity) -
                              parseFloat(PMS[0].CavSet)
                            )
                              ? { width: "95%" }
                              : {
                                width: "95%",
                                backgroundColor: "#d98984",
                                border: "2px solid #DA251A",
                              }
                          }
                          value={PMS[0].Tempcav5}
                          name="Tempcav5"
                          onChange={HandleFeildChangePMS}
                          disabled={Disable.Hour3 && Disable.Hour4}
                        />
                      </td>
                      <td width="30%">
                        <input
                          type="text"
                          style={
                            !(
                              parseFloat(PMS[0].Tempcav6) >
                              parseFloat(PMS[0].Cavity) +
                              parseFloat(PMS[0].CavSet) ||
                              parseFloat(PMS[0].Tempcav6) <
                              parseFloat(PMS[0].Cavity) -
                              parseFloat(PMS[0].CavSet)
                            )
                              ? { width: "95%" }
                              : {
                                width: "95%",
                                backgroundColor: "#d98984",
                                border: "2px solid #DA251A",
                              }
                          }
                          value={PMS[0].Tempcav6}
                          name="Tempcav6"
                          onChange={HandleFeildChangePMS}
                          disabled={Disable.Hour5 && Disable.Hour6}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>

        <table
          className="table_hi"
          width="50%"
          style={{ textAlign: "center" }}
          border="5"
        >
          <tbody>
            {/* <tr>
            <th style={{ textAlign: "center", width: "5%" }}>Set</th>
            <th style={{ textAlign: "center" }}>Actual value</th>
          </tr> */}
            <tr>
              {/* <td>01</td> */}
              <td style={{ width: "33.5%" }}>
                SIGNATURE OF THE SHIFT INCHARGE - PRODUCTION
              </td>
              {/* <td>±5°C</td> */}
              <td>
                <table className="table_hi">
                  <tbody>
                    <tr>
                      <td width="30%">
                        <Select
                          id="dropdown"
                          styles={{ width: "100%" }}
                          options={empDetails.map((item) => ({
                            value: item.Emp_code,
                            label: item.Emp_name,
                          }))}
                          value={{
                            value: EmpName.ShiftInchargeShiftA,
                            label: EmpName.ShiftInchargeShiftAEmp_name,
                          }}
                          onChange={handleShiftInchargeShiftA}
                          placeholder="Select Machine No"
                          isDisabled={Disable.Hour1 && Disable.Hour2}
                        />
                      </td>
                      <td width="30%">
                        <Select
                          id="dropdown"
                          styles={{ width: "100%" }}
                          options={empDetails.map((item) => ({
                            value: item.Emp_code,
                            label: item.Emp_name,
                          }))}
                          value={{
                            value: EmpName.ShiftInchargeShiftB,
                            label: EmpName.ShiftInchargeShiftBEmp_name,
                          }}
                          onChange={handleShiftInchargeShiftB}
                          placeholder="Select Machine No"
                          isDisabled={Disable.Hour3 && Disable.Hour4}
                        />
                      </td>
                      <td width="30%">
                        <Select
                          id="dropdown"
                          styles={{ width: "100%" }}
                          options={empDetails.map((item) => ({
                            value: item.Emp_code,
                            label: item.Emp_name,
                          }))}
                          value={{
                            value: EmpName.ShiftInchargeShiftC,
                            label: EmpName.ShiftInchargeShiftCEmp_name,
                          }}
                          onChange={handleShiftInchargeShiftC}
                          placeholder="Select Machine No"
                          isDisabled={Disable.Hour5 && Disable.Hour6}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>SIGNATURE OF THE ON LINE INSPECTION - QUALITY</td>

              <td colSpan={4}>
                <table className="table_hi">
                  <tbody>
                    <tr>
                      <td width="30%">
                        <Select
                          id="dropdown"
                          styles={{ width: "100%" }}
                          options={empDetails.map((item) => ({
                            value: item.Emp_code,
                            label: item.Emp_name,
                          }))}
                          value={{
                            value: EmpName.LineInspecQaShiftA,
                            label: EmpName.LineInspecQaShiftAEmp_name,
                          }}
                          onChange={handleLineInspecQaShiftA}
                          placeholder="Select Machine No"
                          isDisabled={Disable.Hour1 && Disable.Hour2}
                        />
                      </td>
                      <td width="30%">
                        <Select
                          id="dropdown"
                          styles={{ width: "100%" }}
                          options={empDetails.map((item) => ({
                            value: item.Emp_code,
                            label: item.Emp_name,
                          }))}
                          value={{
                            value: EmpName.LineInspecQaShiftB,
                            label: EmpName.LineInspecQaShiftBEmp_name,
                          }}
                          onChange={handleLineInspecQaShiftB}
                          placeholder="Select Machine No"
                          isDisabled={Disable.Hour3 && Disable.Hour4}
                        />
                      </td>
                      <td width="30%">
                        <Select
                          id="dropdown"
                          styles={{ width: "100%" }}
                          options={empDetails.map((item) => ({
                            value: item.Emp_code,
                            label: item.Emp_name,
                          }))}
                          value={{
                            value: EmpName.LineInspecQaShiftC,
                            label: EmpName.LineInspecQaShiftCEmp_name,
                          }}
                          onChange={handleLineInspecQaShiftC}
                          placeholder="Select Machine No"
                          isDisabled={Disable.Hour5 && Disable.Hour6}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan="4" style={{ border: "none" }}>
                {New === undefined ? (
                  ""
                ) : New ? (
                  <button type="submit">Submit</button>
                ) : Insert ? (
                  <button type="submit" onClick={(e) => handleUpdateCreate(e)}>
                    Insert & Update
                  </button>
                ) : (
                  <button type="submit" onClick={(e) => handleUpdate(e)}>
                    Update
                  </button>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
}

export default PmsCreate;
