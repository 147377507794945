import axios from 'axios';
import { useEffect } from 'react';
import { API_URL } from "../Utils/constants";

const DropdownFetcher = ({ keyword, setValue }) => {
  useEffect(() => {
    axios
      .post(`http://${API_URL}/GenericResultBuilderService/buildResults`, {
        _keyword_: keyword,
      })
      .then((response) => {
        setValue(response.data.data);
        console.log(keyword,response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [keyword, setValue]);

  return null; 
};

export default DropdownFetcher;
