import React from 'react';
function HoursMinutesSelect({hours, minutes, onHoursChange, onMinutesChange }) {
    const handleHoursChange = (e) => {
      const selectedHours = parseInt(e.target.value);
      onHoursChange(selectedHours);
    };
  
    const handleMinutesChange = (e) => {
      const selectedMinutes = parseInt(e.target.value);
      onMinutesChange(selectedMinutes);
    };
    const HoursOptions = Array.from({ length: 25 }, (_, index) => index);
    const minutesOptions = Array.from({ length: 61 }, (_, index) => index);
    return (
      <div >
        <span style={{ marginRight: "5px", float: "left", width: "20%" }}>
        <select value={hours} onChange={handleHoursChange}>
          {HoursOptions.map((hours) => (
            <option key={hours} value={hours}>
                {hours} {hours === 1 ? 'hr' : 'hrs'}
            </option>
          ))}

        </select>
      </span>
      
        <span style={{ marginRight: "5px", float: "left", width: "20%" }}>
        <select value={minutes} onChange={handleMinutesChange}>
          {minutesOptions.map((minute) => (
            <option key={minute} value={minute}>
              {minute} {minute === 1 ? 'minute' : 'minutes'}
            </option>
          ))}
        </select>
        </span>
      </div>
    );
  }
  export { HoursMinutesSelect };