import React, { useEffect, useState } from "react";
import QaLayout from "../../../Quality/QaLayout";
import Field from "../../../../Components/Fields/Field";
import Lable from "../../../../Components/Fields/Lable";
import CreateRecordPaint from "../../../../Lib/API/CreateRecordPaint";
import { useNavigate, useParams } from "react-router-dom";
import ReadRecordPaint from "../../../../Lib/API/ReadRecordPaint";
import LoadingPage from "../../../../Components/Handler/LoadingPage";
import UpdateRecordPaint from "../../../../Lib/API/UpdateRecordPaint";
import * as GeneralConstants from "../../../../Utils/GeneralConstants.js";
import IsDuplicatePaint from "../../../../Lib/IsDuplicatePaint.js";
const initState = 
  {
  Thinner_code: "",
  Thinner_name: "",
  Flex1: "",
  ActiveStatus: "Active",
};
function ThinnerMaster() {
  const [Create, setCreate] = useState(initState);
  const [warning, setwarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const { option, id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    console.log("Create", Create);
  });
  useEffect(() => {
    setwarning(false);
    document.title = "Paint Thinner Master";
    const fetchData = async () => {
      if (id === undefined) {
        setCreate(initState);
      } else {
        setLoading(true);
        try {
          const res = await ReadRecordPaint({
            _keyword_: "paint_thinner_master",
            Id: id,
          });
          console.log("res", res);
          setCreate(res.data[0]);
           console.log("Res2",Create)
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, []);
  if (loading) {
    return <LoadingPage />;
  }


   const HandleCreate = async () => {
      try{
        console.log(Create);
        const IsDuplicatePaintCode = await IsDuplicatePaint("paint_thinner_master", [`thinner_code="${Create.Thinner_code}"`]);
  
        if (IsDuplicatePaintCode) {
          alert(`Thinner with code ${Create.Thinner_code} already exists.`);
          return;
        }
    
        const IsDuplicatePaintName = await IsDuplicatePaint("paint_thinner_master", [`thinner_name="${Create.Thinner_name}"`]);
    
        if (IsDuplicatePaintName) {
          alert(`Thinner with name ${Create.Thinner_name} already exists.`);
          return;
        }
        if ( !Create.Thinner_code || !Create.Thinner_name || !Create.ActiveStatus) {
          alert("Please Fill the Mandatory Fields");
          setwarning(true);
          return;
        } else {
          let record = [{ ...Create }];
            CreateRecordPaint("paint_thinner_master", record).then((res) => {
            console.log("HandleCreate", res);
          });
          alert(GeneralConstants.SUBMISSION_SUCCESSFUL);
          //setwarning(false);
          setCreate(initState);
        }}
        catch (error){
          console.error(error);
          alert(GeneralConstants.SUBMISSION_FAILED);
    
        }
      }
    
    function HandleUpdate() {
      try {
        if ( !Create.Thinner_code || !Create.Thinner_name  || !Create.ActiveStatus) {
          alert("Please Fill the Mandatory Fields");
          setwarning(true);
         return;
        }
    
        let record = [{ ...Create }];
        UpdateRecordPaint("paint_thinner_master", record).then((res) => {
          console.log("HandleUpdate",res );
      });
    
        alert(GeneralConstants.UPDATE_SUCCESSFUL);
        setwarning(false);
      //  setCreate(initState);
      } catch (error) {
        console.error(error);
        alert(GeneralConstants.UPDATE_FAILURE);
       setwarning(false);
      
      }
      navigate(-1);
    };
  
  
  return (
    <QaLayout>
      <div>
        <table>
          <tbody>
            <tr>
              <th colSpan={8}>
                <Lable value={"Thinner Master"} type={"Head"} />
              </th>
            </tr>
            <tr>
              <td>
                <Field
                  label={"Thinner Code"}
                  name={"Thinner_code"}
                  options={{ type: "text" ,
                  required: true,
                  disabled: option === "view"|| option === "edit",
                  }}
                  warning={warning}
                  value={Create}
                  OnChange={(e) =>
                    setCreate({ ...Create, Thinner_code: e.target.value })
                  }
                />
              </td>
              <td>
                <Field
                  label={"Thinner Name"}
                  name={"Thinner_name"}
                  options={{ type: "text",  required: true,
                  disabled: option === "view"|| option === "edit",
                  }}
                   warning={warning}
                  value={Create}
                  OnChange={(e) =>
                    setCreate({ ...Create, Thinner_name: e.target.value })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>
                <Field
                  label={"Flex"}
                  name={"Flex1"}
                  options={{ type: "text", disabled: option === "view", }}
                  value={Create}
                  OnChange={(e) =>
                    setCreate({ ...Create, Flex1: e.target.value })
                  }
                />
              </td>
              <td>
                <Field
                  label={"Active Status"}
                  name={"ActiveStatus"}
                  options={{
                    type: "dropdown",
                    required:true,
                    disabled: option === "view",
                    values: [
                      { value: "", label: "--Select--" },
                      { value: "Active", label: "Active" },
                      { value: "InActive", label: "InActive" },
                    ],
                  }}
                  warning={warning}
                  value={Create}
                  OnChange={(e) =>
                    setCreate({ ...Create, ActiveStatus: e.target.value })
                  }
                />
              </td>
            </tr>
          
            {!(option === "view") && (
                <tr>
                  {id === undefined ? (
                    <td colSpan={19}>
                      {" "}
                      <Field
                        label={"Submit"}
                        options={{ type: "Button" }}
                        onClick={(e) => {
                          HandleCreate(e);
                        }}
                      />
                    </td>
                  ) : (
                    <td colSpan={19}>
                      {" "}
                      <Field
                        label={"Update"}
                        options={{ type: "Button" }}
                        onClick={(e) => {
                          HandleUpdate(e);
                        }}
                      />
                    </td>
                  )}
                </tr>
              )}
          </tbody>
        </table>
      </div>
    </QaLayout>
  );
}

export default ThinnerMaster;
