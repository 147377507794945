import {  PROCESS_TRANSACTION_URL_PAINT, SECRET_KEY } from "../Utils/constants";
import { getAPIRequest } from "../Utils/APIUtility";



export const setMessagePaint = async ({NoteFrom,NoteTo,NoteMessage,NoteRecordId,TableName,NoteCreatedBy}) => {  
    const data = {
        
        data: [
          {
            Note_from : NoteFrom ,            
            Note_to : NoteTo,
            Note_msg : NoteMessage,
            Note_record_id : NoteRecordId,
            Note_record_table : TableName,
            Note_created_by : NoteCreatedBy,
            
          },
        ],
        _keyword_: "Notification_details",
        secretkey: SECRET_KEY,
      }; 

      const response = await getAPIRequest(
        PROCESS_TRANSACTION_URL_PAINT,'POST', data
      );
      const response1 = await response.json();
      // console.log("response_message",response1)
}