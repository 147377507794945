
import { PROCESS_TRANSACTION_UPDATE_URL } from "../Utils/constants";
import { getAPIRequest } from "../Utils/APIUtility";

const UpdateRecord = async (data) => {
  const response = await getAPIRequest(PROCESS_TRANSACTION_UPDATE_URL, "POST", data);
  const response1 = await response.json();
  return response1.success
};


export default UpdateRecord;
