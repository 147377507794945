import React, { useState, useEffect } from "react";
import Popup from "../../../Components/Popup/Popup";
import "../../../Styles/Part3.css";
import Pagination from "../../../Components/Pagination";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import UpdateRecord from "../../../Lib/API/UpdateRecord.js";
import { MdDelete } from "react-icons/md";
import ReadRecord from "../../../Lib/API/ReadRecord.js";
import { useNavigate, useParams } from "react-router-dom";
import { FaBackspace } from "react-icons/fa";

function RMMasterView() {
  const [isCreating, setIsCreating] = useState(false);
  const [rmMasterAddState, setRMMasterAddState] = useState({
    RmCde: "",
    RmDescn: "",
    Grade: "",
    LotNo: "",
    MatLdTm: "",
    PreHtHrs: "",
    PreHtTemp: "",
    Flexy1: "",
    Flexy2: "",
    Flexy3: "",
    Quantity: "",
    LsDetails: "",
    isPaginationVisible: true,
    currentPage: 1,
    filterRmnm: "",
    filterRmcd: "",
    Colour: "",
  });

  const [rm_mas, setrm_mas] = useState([]);
  const [ButtonPopup2, setButtonPopup2] = useState(false);
  const [ButtonPopup, setButtonPopup] = useState(false);
  const [back, setback] = useState(false);
  const navigate = useNavigate();
  const { id,ord } = useParams();
  useEffect(() => {
    console.log(id,ord);
    if (id !== undefined) {
      const locater = window.location.href;
      console.log("locater", locater);
      ReadRecord(
        // `SELECT rm_code,rm_matloadingtime,rm_lotno,rm_quantity,rm_flex_03,rm_description,rm_grade,rm_Colour FROM rm_details where rm_code = '${id}' group by rm_code,rm_description,rm_grade,rm_Colour`
         `SELECT rm_code,rm_matloadingtime,rm_lotno,rm_quantity,rm_flex_03,rm_description,rm_grade,rm_Colour FROM rm_details where rm_code = '${id}'`
      ).then(async (res) => {
        console.log("res - ", res);
        switch (res.data[0].count > 0) {
          case locater.includes("view"):
            setback(true);
            handleView(res.data[0].rows[0]);
            break;
          case locater.includes("edit"):
            setback(true);
            handleEdit(res.data[0].rows[0]);
            break;
        }

        // if (locater.includes('view')){
        //   setback(true)
        //   handleView(res.data[0].rows[0])
        // }else if()
        // setButtonPopup2(true)

        await timeout(1000);
        const popupDiv = document.querySelector(".popup");
        if (popupDiv) {
          const elementInsidePopup = popupDiv.querySelector("#close-btn");
          elementInsidePopup.style.display = "none";
        }
      });
    }
  }, [id]);
  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }
  const recordPerPage = 20;
  const filterRecords = () => {
    const filteredData = rm_mas.filter((record) => {
      const RmnmMatch = record.Rm_description.toLowerCase().includes(
        rmMasterAddState.filterRmnm.toLowerCase()
      );
      const RmcdMatch = record.Rm_code.toLowerCase().includes(
        rmMasterAddState.filterRmcd.toLowerCase()
      );
      return RmnmMatch && RmcdMatch;
    });
    return filteredData || [];
  };
  const filteredData = filterRecords();
  const npage = Math.ceil(filteredData.length / recordPerPage);
  const numbers = [...Array(npage).keys()].map((num) => num + 1);

  function handleFilterRmNmChange(event) {
    setRMMasterAddState({
      ...rmMasterAddState,
      currentPage: 1,
      filterRmnm: event.target.value,
    });
  }
  function handleFilterRmcdChange(event) {
    setRMMasterAddState({
      ...rmMasterAddState,
      currentPage: 1,
      filterRmcd: event.target.value,
    });
  }
  const firstIndex = (rmMasterAddState.currentPage - 1) * recordPerPage;
  const lastIndex = firstIndex + recordPerPage;
  const filteredRecords = filteredData.slice(firstIndex, lastIndex);

  function prePage() {
    if (rmMasterAddState.currentPage > 1) {
      setRMMasterAddState({
        ...rmMasterAddState,
        currentPage: rmMasterAddState.currentPage - 1,
      });
    }
  }

  function changeCpage(id) {
    setRMMasterAddState({
      ...rmMasterAddState,
      currentPage: id,
    });
  }
  function nextPage() {
    if (rmMasterAddState.currentPage < npage) {
      setRMMasterAddState({
        ...rmMasterAddState,
        currentPage: rmMasterAddState.currentPage + 1,
      });
    }
  }

  const handleView = (e) => {
    setRMMasterAddState({
      ...rmMasterAddState,
      isPaginationVisible: false,

      RmCde: e.rm_code,
      RmDescn: e.rm_description,
      Grade: e.rm_grade,
      LotNo: e.rm_lotno,
      MatLdTm: e.rm_matloadingtime,
      PreHtHrs: e.rm_preheathrs,
      PreHtTemp: e.rm_preheattemp,
      Quantity: e.rm_quantity,
      LsDetails: e.rm_ls_details,
      Flexy1: e.rm_flex_01,
      Colour: e.rm_Colour,
      Flexy3: e.rm_flex_03,
    });
    setButtonPopup2(true);
  };

  const viewPagination = () => {
    setRMMasterAddState({
      ...rmMasterAddState,
      isPaginationVisible: true,
    });
  };

  const handleEdit = (e) => {
    setRMMasterAddState({
      ...rmMasterAddState,
      isPaginationVisible: false,
      // Id: e.Id,
      RmCde: e.rm_code,
      RmDescn: e.rm_description,
      Grade: e.rm_grade,
      LotNo: e.rm_lotno,
      MatLdTm: e.rm_matloadingtime,
      PreHtHrs: e.rm_preheathrs,
      PreHtTemp: e.rm_preheattemp,
      Quantity: e.rm_quantity,
      LsDetails: e.rm_ls_details,
      Flexy1: e.rm_flex_01,
      Colour: e.rm_Colour,
      Flexy3: e.rm_flex_03,
    });
    setButtonPopup(true);
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    console.log("handleUpdate",rmMasterAddState);

    if (
      !rmMasterAddState.RmCde ||
      !rmMasterAddState.RmDescn ||
      !rmMasterAddState.Grade ||
      !rmMasterAddState.MatLdTm 
    ) {
      setIsCreating(true);

      alert(GeneralConstants.MANDATORY_ALERT);
      return;
    }
    const data = {
      data: [
        {
          Rm_code: rmMasterAddState.RmCde,
          Rm_description: rmMasterAddState.RmDescn,
          Rm_grade: rmMasterAddState.Grade,
          Rm_matloadingtime: rmMasterAddState.MatLdTm,
          // Rm_preheathrs: rmMasterAddState.PreHtHrs,
          // Rm_preheattemp: rmMasterAddState.PreHtTemp,
          // Rm_flex_01: rmMasterAddState.Flexy1,
          Rm_Colour: rmMasterAddState.Colour,
          Rm_flex_03: rmMasterAddState.Flexy3,
          Rm_quantity: rmMasterAddState.Quantity,
          // Rm_ls_details: rmMasterAddState.LsDetails,
        }
      ]
    };
    console.log("response",data.data);

    UpdateRecord('rm_details_update',data.data).then((response)=>{
      console.log("response",response);
      if (response.feedback[0].count > 0) {
        alert(`RM master code ${GeneralConstants.UPDATE_SUCCESSFUL}`);
        navigate(-1);
      }
    })
    // data["_keyword_"] = "rm_details_update";
    // data["secretkey"] = SECRET_KEY;
    // getAPIRequest(PROCESS_TRANSACTION_UPDATE_URL, "POST", data)
    //   .then((response) => {
        // setRMMasterAddState({
        //   ...rmMasterAddState,
        //   RmCde: "",
        //   RmDescn: "",
        //   Grade: "",
        //   LotNo: "",
        //   MatLdTm: "",
        //   PreHtHrs: "",
        //   PreHtTemp: "",
        //   Flexy1: "",
        //   Flexy2: "",
        //   Flexy3: "",
        //   Quantity: "",
        //   LsDetails: "",
        //   isPaginationVisible: true,
        //   Colour: "",
        // });
        // setrm_mas([...rm_mas, response.data]);
        // alert(`RM master code ${GeneralConstants.UPDATE_SUCCESSFUL}`);
        // window.location.reload();
        // navigate(-1);
        // setButtonPopup(false);
        // handleReadRmMaster();
      // })
      .catch((error) => {
        console.log(error);
        alert(GeneralConstants.UPDATE_FAILURE);
        // window.location.reload();
      });
  };
  function handleReadRmMaster() {
    // ReadRecord({
    //   _keyword_: "rm_details_view_list"
    // }).then((res) => {
    //   // console.log(res.data);
    //   setrm_mas(res.data);
    // });
  }
  // useEffect(() => {
  //   handleReadRmMaster();
  // }, []);

  return (
    <div>
      <Popup
        trigger={ButtonPopup2}
        setTrigger={setButtonPopup2}
        viewPagination={viewPagination}
      >
        <br></br>
        {back && (
          <div
            style={{
              fontSize: "16px",
              fontWeight: "bolder",
              backgroundColor: "#86BBd8",
              width: "90px",
              padding: "10px",
              marginLeft: "20px",
              borderRadius: "10px",
              border: "2px solid #005C9F",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/RMMaster/tab2");
            }}
          >
            <FaBackspace size={20} /> Back
          </div>
        )}
        {/* <br></br>
        <br></br> */}
        <br></br>
        <form
          id="rmmv.view.container"
          name="rmmv.view.container"
          className="form-rmMaster-View"
        >
          <table
            id="rmmv.view.table"
            name="rmmv.view.table"
            style={{ textAlign: "center", width: "100%" }}
            cellPadding="5"
          >
            <tbody>
              <tr>
                <th
                  id="rmmv.Title.View"
                  name="rmmv.Title.View"
                  height="20%"
                  colSpan="15"
                  style={{ textAlign: "center", whiteSpace: "nowrap" }}
                >
                  <font color="#8B0000">View - Raw MaterialMaster</font>
                </th>
              </tr>
              <tr>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Raw Material-Code
                </th>
                <td width="25%">
                  <label id="rmmv.view.rmCode" name="rmmv.view.rmCode">
                    {rmMasterAddState.RmCde}
                  </label>
                </td>

                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Raw Material-Description
                </th>
                <td width="25%">
                  <label id="rmmv.view.rmName" name="rmmv.view.rmName">
                    {rmMasterAddState.RmDescn}
                  </label>
                </td>
              </tr>
              <tr>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Grade
                </th>
                <td>
                  <label
                    id="rmmv.view.rmGrade"
                    name="rmmv.view.rmGrade"
                    width="25%"
                  >
                    {rmMasterAddState.Grade}
                  </label>
                </td>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Lot No
                </th>
                <td>
                  <label
                    id="rmmv.view.rmLotNo"
                    name="rmmv.view.rmLotNo"
                    width="25%"
                  >
                    {rmMasterAddState.LotNo}
                  </label>
                </td>
              </tr>
              <tr>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Unit Of Measure
                </th>
                <td>
                  <label
                    id="rmmv.view.rmMatLdTm"
                    name="rmmv.view.rmMatLdTm"
                    width="25%"
                  >
                    {rmMasterAddState.MatLdTm}
                  </label>
                </td>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Quantity
                </th>
                <td>
                  <label
                    id="rmmv.view.Quantity"
                    name="rmmv.view.Quantity"
                    width="25%"
                  >
                    {rmMasterAddState.Quantity}
                  </label>
                </td>
                {/* <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Pre Heat Hours
                </th>
                <td>
                  <label
                    id="rmmv.view.rmPreHtHrs"
                    name="rmmv.view.rmPreHtHrs"
                    width="25%"
                  >
                    {rmMasterAddState.PreHtHrs}
                  </label>
                </td> */}
              </tr>
              {/* <tr>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Pre Heat Temp
                </th>
                <td>
                  <label
                    id="rmmv.view.rmPreHtTemp"
                    name="rmmv.view.rmPreHtTemp"
                    width="25%"
                  >
                    {rmMasterAddState.PreHtTemp}
                  </label>
                </td>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Quantity
                </th>
                <td>
                  <label
                    id="rmmv.view.Quantity"
                    name="rmmv.view.Quantity"
                    width="25%"
                  >
                    {rmMasterAddState.Quantity}
                  </label>
                </td>
              </tr> */}
              {/* <tr>
              <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;L/s No
                </th>
                <td>
                  <label
                    id="rmmv.view.LsDetails"
                    name="rmmv.view.LsDetails"
                    width="25%"
                  >
                    {rmMasterAddState.LsDetails}
                  </label>
                </td>
              <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Flex 1
                </th>
                <td>
                  <label
                    id="rmmv.view.rmFlex1"
                    name="rmmv.view.rmFlex1"
                    width="25%"
                  >
                    {rmMasterAddState.Flexy1}
                  </label>
                </td>
                </tr> */}
              <tr>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Colour
                </th>
                <td>
                  <label
                    id="rmmv.view.rmFlex2"
                    name="rmmv.view.rmFlex2"
                    width="25%"
                  >
                    {rmMasterAddState.Colour}
                  </label>
                </td>

                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Flex 3
                </th>
                <td>
                  <label
                    id="rmmv.view.rmFlex3"
                    name="rmmv.view.rmFlex3"
                    width="25%"
                  >
                    {rmMasterAddState.Flexy3}
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </Popup>

      <Popup
        trigger={ButtonPopup}
        setTrigger={setButtonPopup}
        viewPagination={viewPagination}
      >
        <br></br>
        <br></br>
        {back && (
          <div
            style={{
              fontSize: "16px",
              fontWeight: "bolder",
              backgroundColor: "#86BBd8",
              width: "90px",
              padding: "10px",
              marginLeft: "20px",
              borderRadius: "10px",
              border: "2px solid #005C9F",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaBackspace size={20} /> Back
          </div>
        )}
        {/* <br></br>
        <br></br> */}
        <form
          id="RmMasterEdit.formContainer"
          name="RmMasterEdit.formContainer"
          className="form-RmMaster-Edit"
        >
          <table style={{ textAlign: "center", width: "100%" }} cellPadding="5">
            <tbody>
              <tr>
                <th
                  id="rmmc.Title"
                  name="rmmc.Title"
                  colSpan="4"
                  style={{ textAlign: "center", whiteSpace: "nowrap" }}
                >
                  <font color="#8B0000">Edit - Raw Material Master</font>
                </th>
              </tr>
              <tr>
                <th>
                  &nbsp;&nbsp;Raw Material Code
                  <span style={{ color: "red" }}>*</span>
                </th>
                <td>
                  <input
                    id="rmmc.RMCode"
                    name="rmmc.RMCode"
                    type="text"
                    value={rmMasterAddState.RmCde}
                    onChange={(e) =>
                      setRMMasterAddState({
                        ...rmMasterAddState,
                        RmCde: e.target.value,
                      })
                    }
                    required
                    disabled
                  />
                </td>
                <th>
                  &nbsp;&nbsp;Raw Material Description
                  <span style={{ color: "red" }}>*</span>
                </th>
                <td>
                  {rmMasterAddState.RmDescn === "" && isCreating && (
                    <span className="Req">Required *</span>
                  )}
                  <input
                    id="rmmc.RMName"
                    name="rmmc.RMName"
                    type="text"
                    value={rmMasterAddState.RmDescn}
                    onChange={(e) =>
                      setRMMasterAddState({
                        ...rmMasterAddState,
                        RmDescn: e.target.value,
                      })
                    }
                    disabled
                    required
                  />
                </td>
              </tr>
              <tr>
                <th>
                  &nbsp;&nbsp; Grade<span style={{ color: "red" }}>*</span>
                </th>
                <td>
                  {rmMasterAddState.Grade === "" && isCreating && (
                    <span className="Req">Required *</span>
                  )}
                  <input
                    id="rmmc.FGGrade"
                    name="rmmc.FGGrade"
                    type="text"
                    value={rmMasterAddState.Grade}
                    onChange={(e) =>
                      setRMMasterAddState({
                        ...rmMasterAddState,
                        Grade: e.target.value,
                      })
                    }
                  />
                </td>
                <th>&nbsp;&nbsp;Lot No</th>
                <td>
                  <input
                    id="rmmc.Lotno"
                    name="rmmc.Lotno"
                    type="number"
                    value={rmMasterAddState.LotNo}
                    disabled
                    onChange={(e) =>
                      setRMMasterAddState({
                        ...rmMasterAddState,
                        LotNo: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>&nbsp;&nbsp;Unit Of Measure<span style={{ color: "red" }}>*</span></th>
              
                <td>
                  {/* <input
                    id="rmmc.MaterialLoadingTime"
                    name="rmmc.MaterialLoadingTime"
                    type="text"
                    value={rmMasterAddState.MatLdTm}
                    onChange={(e) =>
                      setRMMasterAddState({
                        ...rmMasterAddState,
                        MatLdTm: e.target.value,
                      })
                    }
                  /> */}
                {isCreating && (rmMasterAddState.MatLdTm === undefined || rmMasterAddState.MatLdTm === '') && <span className="Req">Required *</span>}

                  <select
                    id="rmmc.UnitOfMeasure"
                    name="rmmc.UnitOfMeasure"
                    value={rmMasterAddState.MatLdTm}
                    onChange={(e) =>
                      setRMMasterAddState({
                        ...rmMasterAddState,
                        MatLdTm: e.target.value,
                      })
                    }
                    style={{ width: "95%", textAlign: "center" }}
                  >
                    <option
                      id="rmmc.UnitOfMeasure.select"
                      name="rmmc.UnitOfMeasure.select"
                      value=""
                    >
                      -- Select --
                    </option>
                    <option
                      id="rmmc.UnitOfMeasure.KGS"
                      name="rmmc.UnitOfMeasure.KGS"
                      value="KGS"
                    >
                      KGS
                    </option>
                    <option
                      id="rmmc.UnitOfMeasure.LIT"
                      name="rmmc.UnitOfMeasure.LIT"
                      value="LIT"
                    >
                      LIT
                    </option>
                    <option
                      id="rmmc.UnitOfMeasure.NOS"
                      name="rmmc.UnitOfMeasure.NOS"
                      value="NOS"
                    >
                      NOS
                    </option>
                  </select>
                </td>
                <th>&nbsp;&nbsp;Quantity</th>
                <td>
                  <input
                    id="rmmc.Quantity"
                    name="rmmc.Quantity"
                    type="number"
                    value={rmMasterAddState.Quantity}
                    onChange={(e) =>
                      setRMMasterAddState({
                        ...rmMasterAddState,
                        Quantity: e.target.value,
                      })
                    }
                  />
                </td>
                {/* <th>&nbsp;&nbsp;Pre Heat Hours</th>
                <td>
                  <input
                    id="rmmc.PreHeatHours"
                    name="rmmc.PreHeatHours"
                    type="number"
                    value={rmMasterAddState.PreHtHrs}
                    onChange={(e) =>
                      setRMMasterAddState({
                        ...rmMasterAddState,
                        PreHtHrs: e.target.value,
                      })
                    }
                  />
                </td> */}
              </tr>

              {/* <tr>
                <th>&nbsp;&nbsp;Pre Heating Temp</th>
                <td>
                  <input
                    id="rmmc.PreHeatingTime"
                    name="rmmc.PreHeatingTime"
                    type="number"
                    value={rmMasterAddState.PreHtTemp}
                    onChange={(e) =>
                      setRMMasterAddState({
                        ...rmMasterAddState,
                        PreHtTemp: e.target.value,
                      })
                    }
                  />
                </td>

                <th>&nbsp;&nbsp;Quantity</th>
                <td>
                  <input
                    id="rmmc.Quantity"
                    name="rmmc.Quantity"
                    type="number"
                    value={rmMasterAddState.Quantity}
                    onChange={(e) =>
                      setRMMasterAddState({
                        ...rmMasterAddState,
                        Quantity: e.target.value,
                      })
                    }
                  />
                </td>
              </tr> */}
              {/* <tr>
                <th>&nbsp;&nbsp;L/s No</th>
                <td>
                  <input
                    id="rmmc.LsDetails"
                    name="rmmc.LsDetails"
                    type="text"
                    value={rmMasterAddState.LsDetails}
                    onChange={(e) =>
                      setRMMasterAddState({
                        ...rmMasterAddState,
                        LsDetails: e.target.value,
                      })
                    }
                  />
                </td>
                <th>&nbsp;&nbsp;Flex 1</th>
                <td>
                  <input
                    id="rmmc.Flexy1"
                    name="rmmc.Flexy1"
                    type="text"
                    value={rmMasterAddState.Flexy1}
                    onChange={(e) =>
                      setRMMasterAddState({
                        ...rmMasterAddState,
                        Flexy1: e.target.value,
                      })
                    }
                  />
                </td>
              </tr> */}
              <tr>
                <th>&nbsp;&nbsp;Colour</th>
                <td>
                  <input
                    id="rmmc.Colour"
                    name="rmmc.Colour"
                    type="text"
                    value={rmMasterAddState.Colour}
                    onChange={(e) =>
                      setRMMasterAddState({
                        ...rmMasterAddState,
                        Colour: e.target.value,
                      })
                    }
                  />
                </td>
                <th>&nbsp;&nbsp;Flex 3</th>
                <td>
                  <input
                    id="rmmc.Flexy3"
                    name="rmmc.Flexy3"
                    type="text"
                    value={rmMasterAddState.Flexy3}
                    onChange={(e) =>
                      setRMMasterAddState({
                        ...rmMasterAddState,
                        Flexy3: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="4" style={{ border: "none" }}>
                  <button
                    type="submit"
                    id="rmmc.submit"
                    name="rmmc.submit"
                    onClick={(e) => handleUpdate(e)}
                  >
                    Update
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </Popup>

    </div>
  );
}

export default RMMasterView;
