import React, { useEffect, useState } from "react";
import "./QaLayoutPaint.css";
import { useNavigate, useParams } from "react-router-dom";
import UpdateRecordPaint from "../../Lib/API/UpdateRecordPaint";
import ReadRecordPaint from "../../Lib/API/ReadRecordPaint";
import { setMessage } from "../../Lib/SetMessage";


const QaLayoutPaint = ({ children, record, quary }) => {
  const { option, id } = useParams();
  const navigate = useNavigate();
  const tablename = window.location.pathname.split("/")[1];

  const [cardId, setcardId] = useState("");
  const [approval, setapproval] = useState([{}]);
  const [remarks, setremarks] = useState("");

  useEffect(() => {
    if (id !== undefined) {
      ReadRecordPaint(
        `SELECT * FROM upd_data_model.approval where Approver="${localStorage.getItem(
          "empId"
        )}" and TableID="${id}" and TableName="${tablename}"`,"paint"
      ).then((res) => {
        // console.log("Approcals Record", res.data[0].rows);
        setapproval(res.data[0].rows);
      });
    }
  }, []);
  useEffect(() => {
    console.log("Approcals Record ", approval);

  }, [approval]);

  const approve = async (tableId, tableName, UpdateQueryKeyword) => {
    let Level = 0;
    let approval = "";
    ReadRecordPaint({
      _keyword_: "Get_approval_by_statuswaiting_and_empId",
      TableID: tableId,
      TableName: tableName,
      Approver: localStorage.getItem("empId"),
    })
      .then(async (res) => {
        Level = res.data[0].Level;
        approval = res.data[0].Id;

        setMessage({
          NoteFrom: localStorage.getItem("empId"),
          NoteTo: res.data[0].RequestedBy,
          NoteMessage: `Your request for record-${tableName}, Has been Approved by ${localStorage.getItem(
            "empId"
          )}-
          ${localStorage.getItem("name")}`,
          NoteRecordId: tableId,
          TableName: tableName,
          NoteCreatedBy: localStorage.getItem("empId"),
        });
      })
      .then(async () => {
        ReadRecordPaint({
          _keyword_: "Get_approval_by_statuswaiting",
          TableID: tableId,
          TableName: tableName,
        }).then(async (res) => {
          console.log(res);
          if (res.row > 1) {
            let WFA = res.data.filter(
              (item) => item.Status === "Waiting for Approval"
            );
            let levels = res.data.filter((item) => item.Level > Level);
            console.log(WFA, levels);
            if (levels.length > 0) {
              UpdateRecordPaint("approval", [
                {
                  Id: approval,
                  Status: "Approved",
                },
              ]).then((res) => {
                alert("The Record is Approved");
                navigate(-1);
              });
              return res;
            } else if (WFA.length > 0) {
              UpdateRecordPaint("approval", [
                {
                  Id: approval,
                  Status: "Approved",
                },
              ]).then((res) => {
                alert("The Record is Approved");
                navigate(-1);
              });
              return res;
            }
          } else {
            record[0].Approval_status = "1";
            record && delete record[0].complaints;
            record && delete record[0].cavity;
            record && delete record[0].Machineno;
            record && delete record[0].Onlineqasign;
            record && delete record[0].Partname;
            record && delete record[0].Prodcutionincharge;
            record && delete record[0].Prodcutionoperatorname;
            record && delete record[0].Qasign;
            record && delete record[0].Rawmaterialname;
            record && delete record[0].download;
            UpdateRecordPaint(UpdateQueryKeyword, record);
            UpdateRecordPaint("approval", [
              {
                Id: approval,
                Status: "Approved",
              },
            ]).then((res) => {
              alert("The Record is Approved");
              navigate(-1);
            });

            return res;
          }
        });
      });
  };
  const reject = (tableId, tableName, Remarks, UpdateQueryKeyword) => {
    let Level = 0;
    let approval = "";
    ReadRecordPaint({
      _keyword_: "Get_approval_by_statuswaiting_and_empId",
      TableID: tableId,
      TableName: tableName,
      Approver: localStorage.getItem("empId"),
    })
      .then(async (res) => {
        Level = res.data[0].Level;
        approval = res.data[0].Id;
        setMessage({
          NoteFrom: localStorage.getItem("empId"),
          NoteTo: res.data[0].RequestedBy,
          NoteMessage: `Your request for record-${tableName}, Has been Reject by ${localStorage.getItem(
            "empId"
          )}-
          ${localStorage.getItem("name")}. The Reason is "${Remarks}"`,
          NoteRecordId: tableId,
          TableName: tableName,
          NoteCreatedBy: localStorage.getItem("empId"),
        });
      })
      .then(async () => {
        ReadRecordPaint({
          _keyword_: "Get_approval_by_statuswaiting",
          TableID: tableId,
          TableName: tableName,
        }).then(async (res) => {
          if (res.row > 1) {
            let WFA = res.data.filter(
              (item) => item.Status === "Waiting for Approval"
            );
            let levels = res.data.filter((item) => item.Level > Level);

            if (levels.length > 0) {
              UpdateRecordPaint("approval", [
                {
                  Id: approval,
                  Status: "Rejected",
                  ApprovalRemark: Remarks,
                },
              ]).then((res) => {
                alert("The Record is Rejected");
                navigate(-1);
              });
              return res;
            } else if (WFA.length > 0) {
              UpdateRecordPaint("approval", [
                {
                  Id: approval,
                  Status: "Rejected",
                  ApprovalRemark: Remarks,
                },
              ]).then((res) => {
                alert("The Record is Rejected");
                navigate(-1);
              });
              return res;
            }
          } else {
            record[0].Approval_status = "0";
            record && delete record[0].complaints;
            record && delete record[0].cavity;
            record && delete record[0].Machineno;
            record && delete record[0].Onlineqasign;
            record && delete record[0].Partname;
            record && delete record[0].Prodcutionincharge;
            record && delete record[0].Prodcutionoperatorname;
            record && delete record[0].Qasign;
            record && delete record[0].Rawmaterialname;
            record && delete record[0].download;
            UpdateRecordPaint(UpdateQueryKeyword, record);
            UpdateRecordPaint("approval", [
              {
                Id: approval,
                Status: "Rejected",
                ApprovalRemark: Remarks,
              },
            ]).then((res) => {
              alert("The Record is Rejected");
              navigate(-1);
            });
            return res;
          }
        });
      });
  };

  return (
    <div className="QaLayoutPaint">
      {/* {console.log(quary, record)} */}
      {option === "view" ||
      option === "edit" ||
      window.location.href.includes("view") ? (
        <div className="formhead">
          <div>
            <button
              onClick={() => {
                navigate(-1);
              }}
              className="back"
            >
              Back
            </button>
          </div>

          {/* {approval[0] && ((option === "view"  || window.location.href.includes("view")) &&
          (localStorage.getItem("role") === "hod" || localStorage.getItem("role") === "SR.EXECUTIVE" || localStorage.getItem("role") === "ADMIN") && */}
          {/* approval[0].Status === "Waiting for Approval" ? ( */}

          {(approval[0] && (option === "view"  || window.location.href.includes("view")) &&
             (localStorage.getItem("role") === "hod" ||
              localStorage.getItem("role") === "SR.EXECUTIVE" 
              // ||localStorage.getItem("role") === "GM"
              //  ||
              // localStorage.getItem("role") === "ADMIN"
            ) &&
            approval?.[0]?.Status === "Waiting for Approval" ? (
              <div className="actions">
                <button
                  onClick={() => {
                    approve(id, tablename, quary);
                  }}
                  className="approvebtn"
                >
                  Approve
                </button>
                <button
                  onClick={() => {
                    setcardId(id);
                  }}
                  className="rejectbtn"
                >
                  Reject
                </button>
                {id === cardId && (
                  <div>
                    <div
                      style={{
                        backgroundColor: "gray",
                        zIndex: "100",
                        filter: "blur(8px)",
                        position: "absolute",
                        opacity: ".8",
                        right: "0%",
                        bottom: "0%",
                        width: "100%",
                        height: "100%",
                      }}
                    ></div>
                    <div
                      style={{
                        position: "absolute",
                        zIndex: "110",
                        right: "32%",
                        color: "white",
                        bottom: "35%",
                        padding: "20px",
                        borderRadius: "10px",
                      }}
                    >
                      <h3>Remarks</h3>
                      <textarea
                        rows={5}
                        cols={50}
                        onChange={(e) => {
                          setremarks(e.target.value);
                        }}
                      ></textarea>
                      <button
                        onClick={(e) => {
                          reject(id, tablename, remarks, quary);
                        }}
                      >
                        Submit
                      </button>
                      <button
                        onClick={() => {
                          setcardId("");
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="ApprovalRemarks">
                {/* {console.log(approval[0])} */}
                {approval?.[0]?.Status === "Rejected" ? (
                  <div className="reject">
                    <div style={{ fontSize: "16px", fontWeight: "600" }}>
                      The Reason OF Rejection is -
                      {approval[0].ApprovalRemark === undefined ||
                      approval[0].ApprovalRemark === ""
                        ? " "
                        : approval[0].ApprovalRemark}
                    </div>
                  </div>
                ) : approval?.[0]?.Status === "Approved" ? (
                  <div
                    className="approve"
                    style={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    The Record is Approved
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))}
        </div>
      ) : (
        <div>
          <button
            onClick={() => {
              navigate("/" + tablename);
            }}
            className={
              option === undefined && 
              !(
                window.location.pathname.substring(
                  window.location.pathname.length - 4,
                  window.location.pathname.length
                ) === "list" ||
                window.location.pathname.substring(
                  window.location.pathname.length - 6,
                  window.location.pathname.length
                ) === "Report" 
              )
                ? "selected"
                : "unselected"
            }
          >
            Create
          </button>
          <button
            onClick={() => {
              navigate("/" + tablename + "/list");
            }}
            className={
              window.location.pathname.substring(
                window.location.pathname.length - 4,
                window.location.pathname.length
              ) === "list"
                ? "selected"
                : "unselected"
            }
          >
            View
          </button>
          {tablename === "newSmpMain" || tablename === "Pms" ? (
            <button
              onClick={() => {
                navigate("/" + tablename + "/Report");
              }}
              className={
                window.location.pathname.substring(
                  window.location.pathname.length - 6,
                  window.location.pathname.length
                ) === "Report"
                  ? "selected"
                  : "unselected"
              }
            >
              Report
            </button>
          ) : null}
        </div>
      )}
      {children}
    </div>
  );
};

export default QaLayoutPaint;
