import React from 'react';
import './App.css';
import Layout from './Layout';
import Login from './Pages/login/login';
import IsDuplicate from './Lib/IsDuplicate';

function App() {
  
  return (
    <div className="App">
      {localStorage.getItem("login")?<Layout/>:<Login/>}
      {/* <Layout/> */}
    </div>
  );
  
}

export default App;