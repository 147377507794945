import React from "react";
import ListView from "../../../Components/ListView/ListView";
import QaLayout from "../QaLayout";
//group by qp.Id,qp.part_name,qp.part_number,qp.Raw_material_name,qp.model,rm_details.rm_description
const QualityPlanList = () => {
  return (
    <QaLayout quary={"quality_product_plan"}>
    <div>
      <ListView
        Title={"Product Quality Plan"}
        table={"quality_product_plan qp"}
        tableDelete={"quality_product_plan"}
        standard={"qp.ActiveStatus = 1"}
        quary={"Select distinct qp.Id,qp.part_number,qp.Part_name,qp.Raw_material_name,qp.Model,rm_details.rm_description AS rm_name from quality_product_plan qp LEFT JOIN rm_details ON qp.raw_material_name =rm_details.rm_code order by qp.Id desc"}
        navigation={"quality_plan"}
        filter={["qp.Id","qp.part_number","qp.Part_name"]}
        dateFilter={"qp.CreatedDate"}
        header={[
          { value: "qp.Id", label: "ID" },
          { value: "qp.part_number", label: "Part Number" },
          { value: "qp.Part_name", label: "Part Name" },
          { value: "qp.Raw_material_name", label: "RM Code" },
          { value: "rm_name", label: "RM Name" },
          // { value: "Model", label: "Model" }
          // { value: "Approval_status", label: "Approval status" },
        ]}
      />
    </div>
    </QaLayout>
  );
};

export default QualityPlanList;
