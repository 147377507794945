import React, { useState, useEffect } from "react";
import "../../../Styles/lumpsmon.css";
import Logo from "../../../icons/Unitechlogo.jpeg";
import { useRef } from "react";
import Pagination from "../../../Components/Pagination";
import HandleRead from "../../../Lib/HandleRead.js";
import { formatDt } from "../../../Lib/formatDt";
import FilterReport from "../../../Components/FilterReport";
import { Dropdown } from "react-bootstrap";
import html2pdf from 'html2pdf.js';
import * as XLSX from 'xlsx';
function LUMPReport() {
  const tableRef = useRef(null);

  const handleTimePeriodChange = (event) => {
    setTimePeriod(event.target.value);
    setStartDate(null);
    setEndDate(null); 
    setStartDate1(null);
    setEndDate1(null);
    setFilterDate(null);
    setFilterMonth(null);
  };

  // const handleDownloadStatement = () => {
  //   window.print();
  //   window.location.reload();
  // };
  //  const [refreshReport, setRefreshReport] = useState(0);
  const [downloadEnabled, setDownloadEnabled] = useState(false);
  const [filtermonth, setFilterMonth] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate1, setEndDate1] = useState("");
  const [startDate1, setStartDate1] = useState("");
  const [timePeriod, setTimePeriod] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [filterPartNm, setfilterPartNm] = useState("");
  const [filterMcno, setfilterMcno] = useState("");
  const [filterBay, setfilterBay] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [exc, setexc] = useState(false);
  const [selectedOption, setSelectedOption] = useState(""); 
  var [lumps_mon, setlumps_mon] = useState([]);

  const [isPaginationVisible, setPaginationVisible] = useState(true);
  let componentRef = useRef();
  useEffect(() => {
    if ((startDate1 && endDate1) || filtermonth || filterDate || (startDate && endDate ) || filterBay ||
      filterMcno ||
      filterPartNm) {
      console.log(filteredData)
      setDownloadEnabled(true);
    } else {
      setDownloadEnabled(false);
    }
  }, [startDate1, endDate1,filterDate, startDate, endDate,filtermonth,filterBay,filterMcno,filterPartNm]);

  const viewPagination = () => {
    setPaginationVisible(true);
  };

  
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 31;

  function handleFilterPartNmChange(event) {
    setfilterPartNm(event.target.value);
  }

  function handleFilterMcnoChange(event) {
    setfilterMcno(event.target.value);
  }
  function handleFilterbayChange(event) {
    // console.log("Bay")
    setfilterBay(event.target.value);
  }
  const filterRecords = () => {
    const filteredData = lumps_mon.filter((record) => {
      const trackDate = new Date(record.TrackDate);
      const mcnoMatch = record.Machine_no
      .toLowerCase()
      .includes(filterMcno.toLowerCase());
      const PartnameMatch = record.Part_name
      .toLowerCase()
      .includes(filterPartNm.toLowerCase());
      const BayMatch = record.MacBay
      .toLowerCase()
      .includes(filterBay.toLowerCase());
      const selectedStartDate = startDate ? new Date(startDate) : null;
      const selectedEndDate = endDate ? new Date(endDate) : null;
      const selectedStartDate1 = startDate1 ? new Date(startDate1) : null;
      const selectedEndDate1 = endDate1 ? new Date(endDate1) : null;
      if (filtermonth) {
        const selectedMonth = new Date(filtermonth);
        const trackMonth = trackDate.getMonth();
        const trackYear = trackDate.getFullYear();
  
        return (
          trackMonth === selectedMonth.getMonth() &&
          trackYear === selectedMonth.getFullYear()
        );
      }
       else if (selectedStartDate && selectedEndDate) {
        return trackDate >= selectedStartDate && trackDate <= selectedEndDate;
      }
       else if (selectedStartDate) {
        return trackDate >= selectedStartDate ;
      } 
      else if (selectedEndDate) {
        return trackDate <= selectedEndDate;
      } 
      else if (selectedStartDate1 && selectedEndDate1) {
        return trackDate >= selectedStartDate1 && trackDate <= selectedEndDate1;
      }
       else if (selectedStartDate1) {
        return trackDate >= selectedStartDate1 ;
      } 
      else if (selectedEndDate1) {
        return trackDate <= selectedEndDate1;
      } else { 
        return record.TrackDate && record.TrackDate.includes(filterDate) && mcnoMatch && PartnameMatch && BayMatch;
      }
    });
  
    return filteredData;
  };
  

  const filteredData = filterRecords();
  const npage = Math.ceil(filteredData.length / recordPerPage);
  const numbers = [...Array(npage).keys()].map((num) => num + 1);

  function handleFilterDateChange(event) {
    setFilterDate(event.target.value);
    setCurrentPage(1);
  }
  const handleFilterMonthChange = (event) => {
    setFilterMonth(event.target.value);
    setCurrentPage(1);
  };
  const handleStartDateChange = (newStartDate) => {
    setStartDate(newStartDate);
  };

  const handleEndDateChange = (newEndDate) => {
    setEndDate(newEndDate);
  };
  const handleStartDateChange1 = (newStartDate1) => {
    setStartDate1(newStartDate1);
  };

  const handleEndDateChange1 = (newEndDate1) => {
    setEndDate1(newEndDate1);
  };
  // const handleRefresh = () => {
  //   setRefreshReport((prevRefresh) => prevRefresh + 1);
  // };

  const firstIndex = (currentPage - 1) * recordPerPage;
  const lastIndex = firstIndex + recordPerPage;
  const filteredRecords = filteredData.slice(firstIndex, lastIndex);
  function prePage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function nextPage() {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function changeCpage(Id) {
    setCurrentPage(Id);
  }
  // const onButtonClick = () => {
  //   const pdf = new jsPDF("portrait", "mm", "a4");

  //   html2canvas(tableRef.current)
  //     .then((canvas) => {
  //       const imgData = canvas.toDataURL("image/png");
  //       pdf.addImage(imgData, "PNG", 5, 5, 200, 0);

  //       // const pageWidth = 210; // A4 page width in mm
  //       // const aspectRatio = canvas.width / canvas.height;
  //       // const pageHeight = pageWidth / aspectRatio;

  //       // pdf.addImage(imgData, "PNG", 20, 10, pageWidth - 50, pageHeight - 80);

  //       pdf.save("LumpMonitoring.pdf");
  //     })
  //     .catch((error) => {
  //       console.error("Error generating PDF:", error);
  //     });
  // };

  // const pagestyle = `@media all {
  //   .page-break {
  //     display: none;
  //   }
  // }
  
  // @media print {
  //   html, body {
  //     height: initial !important;
  //     overflow: initial !important;
  //     -webkit-print-color-adjust: exact;
  //   }
  // }
  
  // @media print and (orientation: portrait) {
  //   .page-break {
  //     margin-top: 1rem;
  //     display: block;
  //     page-break-before: auto;
  //   }
  // }
  
  // @page {
  //   size: auto;
  //   margin: 15mm;
  // }`;
  const contentRef = useRef(null);
  const exportToExcel = () => {
    setShowTable(true)
    setexc(true);

    setTimeout(() => {
    if (filteredRecords.length === 0) {
      alert("No records found. Cannot export to Excel.");
      // setFilters(() => {
      //   let fill = filter.map((item) => {
      //     return { [item]: "" };
      //   });
      //   fill.push({ start: "", end: "" });
      //   return fill;
      // });
    
      // setFilterQuary("All");
      // getcount("where " + standard + " " + "");
      // setrecords("")
      // setexc(false)
      // setTimeout(() => {
      //   setShowTable(false)
      // }, 1000);
      return;
      
    }

    const sheetData = document.getElementById("excelTable");
    const wsData = XLSX.utils.table_to_sheet(sheetData);
    wsData['!rows'] = [{ hpx: 50, }];          
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, wsData, "Sheet1");
    XLSX.writeFile(wb, "LUMP Report.xlsx");

    // setrecords("")
    setexc(false)
    setShowTable(false)
  },1000);
}

const generatePDF = () => {
  //  setShowTable(true)
  if (filteredRecords.length === 0) {
      alert("No records found. Cannot export to PDF.");
    
      //  setrecords("");
        setShowTable(false)
         return;
         }
  
    const opt = {
      margin: 0.3,
      filename: 'LumpMonitoring.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 1 },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'landscape' }
    };
  
    html2pdf().from(contentRef.current).set(opt).save();
  
  };
  
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "0%",
          padding: "0px",
        }}
      >
         {/* <td colSpan={1}> */}
         <div>
                   <select
                id="LumpCreate-select-field"
               name="LumpCreate-select-field"    
                         style={{ fontSize: "100%", width: "100%" }}
                         onChange={(e) => setSelectedOption(e.target.value)}
                                         >
                                          <option value="">Select</option>
                        <option value="PartName">PartName</option>
                        <option value="MachineName">MachineName</option>
                        <option value="Bay">Bay</option>
                         </select>
                         {/* </td> */}
                         </div>
                         <div>
                         {/* <td colSpan={2}> */}
                               <input
                                   id="LumpCreate-filter-PartName"
                                  name="LumpCreate-filter-PartName"
                                 type="text"
                                placeholder="Filter"
                              
                               style={{marginLeft:"10%",marginRight:"10%", fontSize: "70%", width: "300px" ,  background:"#005C9F",
                               color: 'white',}}
                       
                          value={(selectedOption === "PartName" && filterPartNm) ||
                           (selectedOption === "MachineName" && filterMcno) ||  (selectedOption === "Bay" && filterBay) ||""}
                         onChange={(e) => {
                          if (selectedOption === "PartName") {
                             handleFilterPartNmChange(e);
                              } else if (selectedOption === "MachineName") {
                              handleFilterMcnoChange(e);
                                     }
                                     else if (selectedOption === "Bay") {
                                      handleFilterbayChange(e);
                                             }
                                    }}
                                       />
                                    {/* </td> */}
                                    </div>
        <FilterReport
          timePeriod={timePeriod}
          handleTimePeriodChange={handleTimePeriodChange}
          filtermonth={filtermonth}
          handleFiltermonthChange={handleFilterMonthChange}
          filterDate={filterDate}
          handleFilterDateChange={handleFilterDateChange}
          startDate={startDate}
          startDate1={startDate1}
          handleStartDateChange={handleStartDateChange}
          endDate={endDate}
          endDate1={endDate1}
          handleEndDateChange={handleEndDateChange}
          handleEndDateChange1={handleEndDateChange1}
          handleStartDateChange1={handleStartDateChange1}
          componentref={componentRef}
          downloadEnabled={downloadEnabled}
        />
      
      <div style={{color: 'white',fontSize: '10px', marginLeft: '40px',marginRight: '20px', width: '150px', border: 'none' }}>
{filteredData.length > 0 && (
  <Dropdown >
    <Dropdown.Toggle>Download ⬇️</Dropdown.Toggle>
    <Dropdown.Menu>
                       <Dropdown.Item>
                       <button onClick={generatePDF} style={{borderRadius:"20%",color: 'white',fontSize: '18px',background:"#005C9F", width: '100px'}} >
                           PDF ⬇️
                       </button>
                       </Dropdown.Item>
                       <Dropdown.Item>
                         
                       <button onClick={exportToExcel} style={{borderRadius:"20%",color: 'white',fontSize: '18px',background:"#005C9F", width: '100px'}}>
                         Excel⬇️
                       </button>
                       </Dropdown.Item>
                       </Dropdown.Menu>
  </Dropdown>
)}

</div>
      </div>
                 
      <br />
      <br />
      <div>
        <table
          ref={tableRef}
        
          style={{ "text-align": "center", width: "100%" }}
          cellPadding="1"
        >
          <thead>
            <tr>
              {/* <th style={{ "text-align": "center" }}>
                <img src={Unitechlogo} style={{ width: "70px" }}></img>
              </th> */}
              <th
                id="mlcss-Title-List"
                name="mlcss-Title-List"
                colSpan="7"
                style={{ "text-align": "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">
                  Consolidated Report - LUMP Monitoring
                </font>
              </th>
            </tr>
            <tr className="table_position">
              <th style={{ "text-align": "center", whiteSpace: "nowrap" }}>
                Date
              </th>
              <th style={{ "text-align": "center", whiteSpace: "nowrap" }}>
                Bay
              </th>
              <th style={{ "text-align": "center", whiteSpace: "nowrap" }}>
                Machine
              </th>
              <th style={{ "text-align": "center", whiteSpace: "nowrap" }}>
                Part Name
              </th>
              <th style={{ "text-align": "center", whiteSpace: "nowrap" }}>
                Material
              </th>

              <th style={{ "text-align": "center", whiteSpace: "nowrap" }}>
                Lump Kg
              </th>
              <th style={{ "text-align": "center", whiteSpace: "nowrap" }}>
                Reason
              </th>
            </tr>
          </thead>
          <tbody>
            <HandleRead keyword="Lumpsmon" setData={setlumps_mon} />
            {filteredRecords.length === 0 ? (
              <td colSpan="12" style={{ textAlign: "center" }}>
                <p
                  id="mlcss-list-NoRec"
                  name="mlcss-list-noRec"
                  style={{ fontWeight: "bold" }}
                >
                  No records found.
                </p>
              </td>
            ) : (
              filteredRecords.map((lumps_mon) => (
                <tr className="table_view" key={lumps_mon.Id}>
                  <td className="table-cell">
                    {formatDt(lumps_mon.TrackDate)}
                  </td>
                  <td className="table-cell">{lumps_mon.MacBay}</td>
                  <td className="table-cell">{lumps_mon.Machine_no}</td>
                  <td className="table-cell">{lumps_mon.Part_name}</td>
                  <td className="table-cell">{lumps_mon.Material}</td>
                  <td className="table-cell">{lumps_mon.Lumps_kg}</td>
                  <td className="table-cell">{lumps_mon.Reason}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="pagination" id="pagination">
        {isPaginationVisible && filteredData.length > 0 && (
          <Pagination
            currentPage={currentPage}
            npage={npage}
            prePage={prePage}
            nextPage={nextPage}
            changeCpage={changeCpage}
            numbers={numbers}
          />
        )}
      </div>
      <div style={{marginTop:"200%",display: showTable ? "block" : "none"}}>
              <table  ref={contentRef} id="excelTable"> 
               <thead> 
               

              
            
            <tr>
                {exc ? (
               <td colSpan={7}></td>
                       ) : (
                     null 
                        )}
                          {!exc && (
                        <td style={{ width:"10%" }}>
                         <img style={{ width: "100%"}} src={Logo} alt="Logo" />
                         </td>)}
                         <td colSpan="14" style={{fontWeight:"bold", whiteSpace: "nowrap",textAlign:"center",fontSize:"20px" }}>
            
            <font>
                Consolidated Report - LUMP Monitoring
            </font>
           </td>
              {/* <td
                colSpan="14"
                style={{textAlign:"center"}}
              >
                <font color="#8B0000" >
                  
                </font>
              </td> */}
            </tr>
            <tr >
            <td colSpan={2}>
                Date
              </td>
              <td colSpan={2}>
                Bay
              </td>
              <td colSpan={2}>
                Machine
              </td>
              <td colSpan={4}>
                Part Name
                </td>
                <td colSpan={2}>
                Material
              </td>

              <td >
                Lump Kg
              </td>
              <td style={{ "text-align": "center", whiteSpace: "nowrap" }}>
                Reason
              </td>
            </tr>
          </thead>
          {filteredRecords.length === 0 ? (
              <td colSpan="12" style={{ textAlign: "center" }}>
                <p
                  id="mlcss-list-NoRec"
                  name="mlcss-list-noRec"
                  style={{ fontWeight: "bold" }}
                >
                  No records found.
                </p>
              </td>
            ) : (
              filteredRecords.map((lumps_mon) => (
                <tr className="table_view" key={lumps_mon.Id}>
                  <td colSpan={2}className="table-cell">
                    {formatDt(lumps_mon.TrackDate)}
                  </td>
                  <td colSpan={2} className="table-cell">{lumps_mon.MacBay}</td>
                  <td colSpan={2}className="table-cell">{lumps_mon.Machine_no}</td>
                  <td colSpan={4}className="table-cell">{lumps_mon.Part_name}</td>
                  <td colSpan={2}className="table-cell">{lumps_mon.Material}</td>
                  <td className="table-cell">{lumps_mon.Lumps_kg}</td>
                  <td className="table-cell">{lumps_mon.Reason}</td>
                </tr>
              ))
            )}

      
          </table>
          </div>
    </div>
  );
}

export default LUMPReport;
// {filteredData && (
//   <Dropdown style={{ width: "20%" }}>
//     <Dropdown.Toggle>Download ⬇️</Dropdown.Toggle>
//     <Dropdown.Menu>
//       <PDFDownloadLink
//         document={
//           <Document>
//             <Page>
//               {ReactDOMServer.renderToStaticMarkup(
//                 <div>
//                   {/* Your PDF content here */}
//                 </div>
//               )}
//             </Page>
//           </Document>
//         }
//       >
//         {({ loading }) =>
//           loading ? (
//             "Please Wait ..... "
//           ) : (
//             <div
//               style={{
//                 backgroundColor: "#0a8df8",
//                 padding: "4px",
//                 borderRadius: "10px",
//                 color: "white",
//                 textAlign: "center",
//                 zIndex: "100",
//                 margin: "5px 24px",
//               }}
//             >
//               PDF ⬇️
//             </div>
//           )
//         }
//       </PDFDownloadLink>
//       <Dropdown.Item>
//         <ReactHTMLTableToExcel
//           id="excelExportButton"
//           table="excelTable"
//           filename="LumpMonitor Report"
//           sheet="sheet1"
//           buttonText="Excel ⬇️"
//         />
//       </Dropdown.Item>
//     </Dropdown.Menu>
//   </Dropdown>
// )}
