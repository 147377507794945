//external import
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

//internal import
import Field from "../../../Components/Fields/Field";
import Lable from "../../../Components/Fields/Lable";
import QaLayout from "../../Quality/QaLayout";

//css import
import "./NewSmpMain.css";
const HotRunTemp = ({ Records, setRecords, setActiveKey, activeKey }) => {
  const { option, id } = useParams();
  const [ctrlPressed, setCtrlPressed] = useState(false);

  useEffect(() => {
    if (activeKey === "six") {
      document.getElementById("1-Mani_fold_celsius").focus();
    }
    const handleKeyDown = (event) => {
        
          
      let activeField = document.activeElement;
      let fieldOptions = {
        Mani_fold_celsius: ["", "Hot_runner_gate_celsius"],
        Hot_runner_gate_celsius: ["Mani_fold_celsius", ""],
      };

      let row = Records[0].hotruntemp_function.length - 1;
      let currentField = activeField.id.split("-")[1];
      let indexOfField = activeField.id.split("-")[0];
      let nextField = "";

      if (currentField != undefined) {
        switch (event.ctrlKey + "+" + event.key + "+" + activeKey) {
          case "false+ArrowRight+six":
            nextField = fieldOptions[currentField][1];
            if (indexOfField > 0 && indexOfField < row + 1) {
              if (nextField != "") {
                document.getElementById(indexOfField + "-" + nextField).focus();
              } else if (indexOfField < row) {
                document
                  .getElementById(
                    Number(indexOfField) + 1 + "-Mani_fold_celsius"
                  )
                  .focus();
              }
            }
            break;

          case "false+ArrowLeft+six":
            nextField = fieldOptions[currentField][0];
            if (
              indexOfField > 0 &&
              indexOfField < row + 1 &&
              activeField.id != "1-Mani_fold_celsius"
            ) {
              if (nextField != "") {
                document.getElementById(indexOfField + "-" + nextField).focus();
              } else {
                document
                  .getElementById(indexOfField - 1 + "-Hot_runner_gate_celsius")
                  .focus();
              }
            }
            break;

          case "false+ArrowDown+six":
            // console.log("indexOfField",indexOfField,row,indexOfField == row);
            if (indexOfField < row) {
              document
                .getElementById(Number(indexOfField) + 1 + "-" + currentField)
                .focus();
            }
            break;

          case "false+ArrowUp+six":
            if (indexOfField > 1) {
              //   if (indexOfField < row + 1) {
              document
                .getElementById(Number(indexOfField) - 1 + "-" + currentField)
                .focus();
              //   }
            }
            break;

          case "true+ArrowRight+six":
            setActiveKey("seven");
            break;

          case "true+ArrowDown+six":
            handleIncrease(Records[0].hotruntemp_function.length);
            document.getElementById("1-Mani_fold_celsius").focus();
            break;

          case "true+ArrowUp+six":
            if (row > 1) {
              handleDecrease(Records[0].hotruntemp_function.length);
              document.getElementById("1-Mani_fold_celsius").focus();
            }
            break;

          default:
            break;
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [activeKey, setActiveKey]);
  
  function InvalidCharacters(value) {
    return /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/.test(value);
  }
  function HandleRunTemp(Event, index) {
    console.log("Event", Event.target.name);
    setRecords((item) => {
      let record = [...Records];
      record[0].hotruntemp_function[index] = {
        ...record[0].hotruntemp_function[index],
        [Event.target.name]: Event.target.value,
      };
      return record;
    });
  }
  const handleIncrease = (index) => {
    setRecords((prevRecords) => {
      let Records = [...prevRecords];
      Records[0].hotruntemp_function.push({
        Mani_fold_label: "ManiFold " + index,
        Hot_runner_gate: "Gate " + index,
      });
      return Records;
    });
  };
  const handleDecrease = (index) => {
    setRecords((prevRecords) => {
      let Records = [...prevRecords];
      Records[0].hotruntemp_function.pop();
      return Records;
    });
  };

  const DropdownValues = [
    { value: "", label: "Select" },
    { value: "1", label: "±1" },
    { value: "2", label: "±2" },
    { value: "3", label: "±3" },
    { value: "4", label: "±4" },
    { value: "5", label: "±5" },
    { value: "6", label: "±6" },
    { value: "7", label: "±7" },
    { value: "8", label: "±8" },
    { value: "9", label: "±9" },
    { value: "10", label: "±10" },
    { value: "11", label: "±11" },
    { value: "12", label: "±12" },
    { value: "13", label: "±13" },
    { value: "14", label: "±14" },
    { value: "15", label: "±15" },
  ];
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th colSpan={4}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "90% 10%",
                  alignItems: "center",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <Lable
                    value={"Hot Runner System Temperature"}
                    type={"Head"}
                    style={{ padding: "1px" }}
                  />
                </div>
                <div>
                  <button
                    className="nextButtonLabel"
                    onClick={(e) => {
                      setActiveKey("seven");
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Lable
                value={Records[0].hotruntemp_function[0].Mani_fold_label}
                style={{ textAlign: "center", padding: "0px" }}
              />
            </td>
            <td>
              <Lable
                value={Records[0].hotruntemp_function[0].Mani_fold_celsius}
                style={{ textAlign: "center", padding: "0px" }}
              />
            </td>
            <td>
              <Lable
                value={Records[0].hotruntemp_function[0].Hot_runner_gate}
                style={{ textAlign: "center", padding: "0px" }}
              />
            </td>
            <td>
              <Lable
                value={`°C`}
                style={{ textAlign: "center", padding: "0px" }}
              />
              <Field
                label={""}
                name={"Hot_runner_gate_celsius"}
                className={"fieldSingleGrid"}
                options={{
                  type: "dropdown",
                  disabled: option == "view",
                  values: DropdownValues,
                }}
                value={Records[0].hotruntemp_function[0]}
                OnChange={(e) => {
                  HandleRunTemp(e, 0);
                }}
              />
            </td>
          </tr>
          {Records[0].hotruntemp_function.map((item, index) => {
            if (index != 0) {
              return (
                <tr>
                  <td>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto auto",
                      }}
                    >
                      <Lable
                        value={`${item.Mani_fold_label}`}
                        style={{ textAlign: "center", padding: "0px" }}
                      />
                      <Field
                        label={""}
                        name={"Mani_fold_set"}
                        className={"fieldSingleGrid"}
                        options={{
                          type: "dropdown",
                          disabled: option == "view",
                          values: DropdownValues,
                        }}
                        value={item}
                        OnChange={(e) => {
                          HandleRunTemp(e, index);
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <Field
                      className={"fieldSingleGrid"}
                      label={""}
                      id={index}
                      name={"Mani_fold_celsius"}
                      options={{
                        type: "text",
                        disabled: option == "view",
                      }}
                      value={item}
                      OnChange={(e) => {
                        let value = e.target.value;
                        // if (InvalidCharacters(value)) {
                        //   value = value.replace(/[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g, '');
                        //   e.target.value = value;
                        // }
                        HandleRunTemp(e, index);
                      }}
                    />
                  </td>
                  <td>
                    <Lable
                      value={`${item.Hot_runner_gate}`}
                      style={{ textAlign: "center", padding: "0px" }}
                    />
                  </td>
                  <td>
                    <Field
                      className={"fieldSingleGrid"}
                      id={index}
                      label={""}
                      name={"Hot_runner_gate_celsius"}
                      options={{
                        type: "text",
                        disabled: option == "view",
                      }}
                      value={item}
                      OnChange={(e) => {
                        let value = e.target.value;
                        // if (InvalidCharacters(value)) {
                        //   value = value.replace(/[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g, '');
                        //   e.target.value = value;
                        // }
                        HandleRunTemp(e, index);
                      }}
                    />
                  </td>
                </tr>
              );
            }
          })}
          <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
            <div style={{}}>
              <Field
                style={{ width: "30px", fontSize: "18px" }}
                label={"+"}
                options={{ type: "Button" }}
                onClick={() =>
                  handleIncrease(Records[0].hotruntemp_function.length)
                }
              />
            </div>
            <div>
              
              {Records[0].hotruntemp_function.length > 2 && (
                <Field
                  buttonStyle={"cusbtn"}
                  style={{ width: "30px", fontSize: "18px" }}
                  label={"-"}
                  options={{ type: "Button" }}
                  onClick={() =>
                    handleDecrease(Records[0].hotruntemp_function.length)
                  }
                />
              )}
            </div>
          </div>
        </tbody>
      </table>
    </div>
  );
};
export default HotRunTemp;
