import React,{useState,useEffect} from "react";
import ListViewPaint from "../../../../Components/ListView/ListViewPaint";
import QaLayoutPaint from "../../QaLayoutPaint";

const SupplierMasterList = () => {
 
  return (
    <QaLayoutPaint quary={"paint_supplier_master"}>
    <div>
      <ListViewPaint
        Title={"Paint Supplier Master"}
        standard={["delete_id=0 "]}
        quary={"Select Id,supplier_code,supplier_name,ActiveStatus,CreatedDate from paint_supplier_master group by Id ORDER BY CASE WHEN ActiveStatus = 'Active' THEN 0 ELSE 1 END,Id DESC"}
        table={"paint_supplier_master"}
        tableDelete={"paint_supplier_master"}
        navigation={"PaintSupplierMaster"}
        filter={["Id","supplier_code","supplier_name","ActiveStatus"]}
        dateFilter={"CreatedDate"}
        header={[
          { value: "Id", label: "ID" },
          { value: "CreatedDate", label: "Date" },
          { value: "supplier_code", label: "Supplier Code" },
          { value: "supplier_name", label: "Supplier Name" },
          { value: "ActiveStatus", label: "Active Status" },
        ]}
      />   
    </div>
    </QaLayoutPaint>
  );
};

export default SupplierMasterList;
