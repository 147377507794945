import React from "react";

import QaLayoutPaint from "../../QaLayoutPaint";
import ListViewPaint from "../../../../Components/ListView/ListViewPaint";

const QualityPlanList = () => {
  return (
    <QaLayoutPaint quary={"paint_mm"}>
    <div>
      <ListViewPaint
        Title={"Paint Mixing Monitor"}
        table={"paint_mixing_monitor"}
        tableDelete={"paint_mixing_monitor"}
        standard={["delete_id=0"]}
        quary={"Select Id,Part_number,Part_name from paint_mixing_monitor group by Id ORDER BY Id desc"}
        navigation={"PMMForm"}
        filter={["Id","Part_number","Part_name"]}
        dateFilter={"CreatedDate"}
        header={[
          { value: "Id", label: "ID" },
          { value: "Part_number", label: "Part Number" },
          { value: "Part_name", label: "Part Name" },
        //  { value: "Customer", label: "Customer" },
          
          // { value: "Model", label: "Model" }
          // { value: "Approval_status", label: "Approval status" },
        ]}
      />
    </div>
    </QaLayoutPaint>
  );
};

export default QualityPlanList;
