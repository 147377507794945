//external import
import React, { useEffect, useState, useRef } from "react";
import { useNavigate,useParams } from "react-router-dom";
import CloseButton from "react-bootstrap/CloseButton";
import { IoMdDownload } from "react-icons/io";

//internal import
import Field from "../../../Components/Fields/Field";
import Lable from "../../../Components/Fields/Lable";
import QaLayout from "../../Quality/QaLayout";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

//css import
import "./NewSmpMain.css";
//API imports
const PreViewSmpPage = ({ Records, setState, HandleCreate, option, HandleUpdate, id }) => {
  const Navigate = useNavigate()
  useEffect(() => {
    // console.log("window.Location.href;", window.location.href.split('newSmpMain/')[1].split('/')[0]);
    console.log("window.Location.href;", window.location.href.includes('view'));
  }, [])
  const contentRef = useRef(null);
  const generatePDF = () => {
    const pdf = new jsPDF("landscape", "mm", "a4");
    html2canvas(contentRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 3, 3, 290, 233);
      pdf.save(`SMP.pdf`);
    });
  };
  const onDuplicateCreate = (id) =>{
    Navigate(`/newSmpMain/Duplicate/${id}`)
  }

  return (
    <div style={{ position: "absolute", top: "23px", left: "3%", width: "96%" }}>
      <div style={{ display: "flex", flexDirection: "row-reverse" }}><CloseButton style={{ padding: "12px", textAlign: "center", backgroundColor: "black", width: "5%" }} onClick={(e) => { setState(false) }} />
        {/* <button onClick={(e) => { setState(false) }}>x</button> */}
      </div>
      {(window.location.href.includes('view')) && (
        <>
      <br></br>
        <div style={{display:'flex',flexDirection:"row",gap:"70%"}}>
        <div style={{fontWeight:'bold',fontSize:'20px'}}>
          <span>
            <a
              value={Records[0]?.Id}
              href="#"
              onClick={(e) => {
                onDuplicateCreate(Records[0]?.Id);
              }}
              style={{fontStyle:'italic'}}
            >
              Click here
            </a>{" "}
            to duplicate the data!
            </span>
            </div>
      {/* <br /> */}
      
        <div >
          <button style={{borderRadius:'17px',boxShadow:'4px 3px 6px 0px black',padding:'5px'}} onClick={generatePDF} >Download PDF <IoMdDownload /></button>
        </div>
        </div>
      {/* <br></br> */}
      </>
      )}
      <div className="Form" ref={contentRef}>
        <table>
          <thead>
            <tr>
              <th colSpan={3}>
                <Lable value={"Standard Moulding Parameter"} type={"Head"} />
              </th>
              <th>
                <div className="previewLabel">
                  <span className="previewLabelLeft">Format No:</span> <span className="previewLabelRight">{
                  [0].Format_no}</span>
                  <span className="previewLabelLeft">Rev No:</span> <span className="previewLabelRight">{Records[0].Rev_no}</span>
                  <span className="previewLabelLeft">Rev Date:</span> <span className="previewLabelRight">{Records[0].Rev_ate}</span></div>  </th>
            </tr>
          </thead>
          <tr>
            <td>
              <div className="previewLabel">
                <span className="previewLabelLeft">Part Number:</span>
                <span className="previewLabelRight">{Records[0].Part_number}</span>
              </div>
            </td>
            <td>
              <div className="previewLabel">
                <span className="previewLabelLeft">Part Name:</span>
                <span className="previewLabelRight">{Records[0].Part_name}</span>
              </div>
              
            </td>
            <td>
              <div className="previewLabel">
                <span className="previewLabelLeft">RM Name:</span>
                <span className="previewLabelRight">{Records[0]?.Rawmaterialname[0]?.Mh_raw_mat_name}</span>
              </div>
            </td>
            <td>
              <div className="previewLabel">
                <span className="previewLabelLeft">Machine No:</span>
                <span className="previewLabelRight">{Records[0].Machine_no}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="previewLabel">
                <span className="previewLabelLeft">Model:</span>
                <span className="previewLabelRight">{Records[0].Model}</span>
              </div>
            </td>
            <td>
              <div className="previewLabel">
                <span className="previewLabelLeft">No Of Cavity:</span>
                <span className="previewLabelRight">{Records[0].No_of_cavity}</span>
              </div>
            </td>
            <td>
              <div className="previewLabel">
                <span className="previewLabelLeft">Garde Name:</span>
                <span className="previewLabelRight">{Records[0].Grade}</span>
              </div>
            </td>
            <td>
              <div className="previewLabel">
                <span className="previewLabelLeft">Colour:</span>
                <span className="previewLabelRight">{Records[0].Colour}</span>
              </div>
            </td>
          </tr>
          
          <tr>
            <td>
              <div style={{ paddingLeft: "5px" }}>
                <span style={{ textAlign: "center", fontWeight: "bold", color: "#8B0000" }}>(-</span>
                <span className="previewLabelRight">{Records[0].Part_weight_set_minus_1}</span>
                <span style={{ textAlign: "center", fontWeight: "bold", color: "#8B0000" }}>/+</span>
                <span className="previewLabelRight">{Records[0].Part_weight_set_plus_1}</span>
                <span style={{ textAlign: "center", fontWeight: "bold", color: "#8B0000" }}>)</span>
              </div>
            </td>
            <td>
              <div className="previewLabel">
                <span className="previewLabelLeft">Part Weight 1:</span>
                <span className="previewLabelRight">{Records[0].Part_weight_1}</span>
              </div>
            </td>

            <td>
              {Records[0].Part_weight_2.length > 0 && (
                <div style={{ paddingLeft: "5px" }}>
                  <span>(-</span>
                  <span className="previewLabelLeft">
                    {Records[0].Part_weight_set_minus_2}
                  </span>
                  <span>/+</span>
                  <span className="previewLabelRight">
                    {Records[0].Part_weight_set_plus_2}
                  </span>
                  <span>)</span>
                </div>
              )}
            </td>
            <td>
              {Records[0].Part_weight_2.length > 0 && (
                <div className="previewLabel">
                  <span className="previewLabelLeft">Part Weight 2:</span>
                  <span className="previewLabelRight">
                    {Records[0].Part_weight_2}
                  </span>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <div className="previewLabel">
                <span className="previewLabelLeft">Runner Weight:</span>
                <span className="previewLabelRight">{Records[0].Runner_weight}</span>
              </div>
            </td>
            <td>
              <div className="previewLabel">
                <span className="previewLabelLeft">Date:</span>
                <span className="previewLabelRight">{Records[0].Entry_date}</span>
              </div>
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td colSpan={5} style={{ padding: "0px" }}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto auto auto",
                }}
              >
                <div>
                  <table>
                    <thead>
                      <th colSpan={5} style={{ padding: "0px" }}>
                        <Lable
                          value={"Injection Settings"}
                          type={"Head"}
                          style={{ padding: "1px" }}
                        />
                      </th>
                    </thead>
                    <tbody>
                      <tr style={{ height: "45px" }}>
                        <td>
                          <span className="previewHeading">
                            {
                              Records[0].injection_functional[0]
                                .Injection_stages
                            }
                          </span>
                        </td>
                        <td>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span>PRESSURE*(BAR)</span>
                            <span
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                                color: "#8B0000",
                              }}
                            >{`±${Records[0].injection_functional[0].Injection_pressure}`}</span>
                          </div>
                        </td>
                        <td>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span>SPEED% *</span>
                            <span
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                                color: "#8B0000",
                              }}
                            >{`±${Records[0].injection_functional[0].Injection_speed}`}</span>
                          </div>
                        </td>
                        <td>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span>POSITION(MM)⟡</span>
                            <span
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                                color: "#8B0000",
                              }}
                            >{`±${Records[0].injection_functional[0].Injection_position}`}</span>
                          </div>
                        </td>
                        <td>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span>TIME(SEC) *</span>
                            <span
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                                color: "#8B0000",
                              }}
                            >{`±${Records[0].injection_functional[0].Injection_time}`}</span>
                          </div>
                        </td>
                      </tr>
                      {Array.from({
                        length:
                          Records[0].injection_functional.length > 7
                            ? Records[0].injection_functional.length
                            : 7,
                      }).map((_, index) => {
                        if (Records[0].injection_functional.length < 7) {
                          if (
                            index != 0 &&
                            index !=
                            Records[0].injection_functional.length - 1 &&
                            index != Records[0].injection_functional.length - 2
                          ) {
                            return (
                              <tr key={index}>
                                <td>
                                  <span>
                                    {Records[0]?.injection_functional[index]
                                      ? Records[0]?.injection_functional[index]
                                        .Injection_stages
                                      : "Injection " + (index - 2)}
                                  </span>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {
                                      Records[0]?.injection_functional[index]
                                        ?.Injection_pressure
                                    }
                                  </span>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {
                                      Records[0]?.injection_functional[index]
                                        ?.Injection_speed
                                    }
                                  </span>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {
                                      Records[0]?.injection_functional[index]
                                        ?.Injection_position
                                    }
                                  </span>
                                </td>
                                <td  className="previewLabelValue">
                                  <span >
                                    {
                                      Records[0]?.injection_functional[index]
                                        ?.Injection_time
                                    }
                                  </span>
                                </td>
                              </tr>
                            );
                          }
                        } else {
                          if (
                            index != 0 &&
                            index !=
                            Records[0].injection_functional.length - 1 &&
                            index != Records[0].injection_functional.length - 2
                          ) {
                            return (
                              <tr key={index}>
                                <td  className="previewLabelValue">
                                  <span>
                                    {
                                      Records[0]?.injection_functional[index]
                                        ?.Injection_stages
                                    }
                                  </span>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {
                                      Records[0]?.injection_functional[index]
                                        ?.Injection_pressure
                                    }
                                  </span>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {
                                      Records[0]?.injection_functional[index]
                                        ?.Injection_speed
                                    }
                                  </span>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {
                                      Records[0]?.injection_functional[index]
                                        ?.Injection_position
                                    }
                                  </span>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {
                                      Records[0]?.injection_functional[index]
                                        ?.Injection_time
                                    }
                                  </span>
                                </td>
                              </tr>
                            );
                          }
                        }
                      })}
                      {/* {Records[0].injection_functional.map((item, index) => {
                                            if (index != 0 && index != Records[0].injection_functional.length - 1 && index != Records[0].injection_functional.length - 2 && index > 4) {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <span >{item.Injection_stages}</span>
                                                        </td>
                                                        <td>
                                                            <span className="previewLabelRight">{item.Injection_pressure}</span>
                                                        </td>
                                                        <td>
                                                            <span className="previewLabelRight">{item.Injection_speed}</span>
                                                        </td>
                                                        <td>
                                                            <span className="previewLabelRight">{item.Injection_position}</span>
                                                        </td>
                                                        <td>
                                                            <span className="previewLabelRight">{item.Injection_time}</span>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        })} */}
                      <tr>
                        <td >
                          <span>
                            {
                              Records[0].injection_functional[
                                Records[0].injection_functional.length - 2
                              ].Injection_stages
                            }
                          </span>
                        </td>
                        <td colSpan={3}></td>
                  <td className="previewLabelValue">
                          <span>
                            {
                              Records[0].injection_functional[
                                Records[0].injection_functional.length - 2
                              ].Injection_time
                            }
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <span>
                            {
                              Records[0].injection_functional[
                                Records[0].injection_functional.length - 1
                              ].Injection_stages
                            }
                          </span>
                        </td>
                        <td style={{ borderRight: "none" }}></td>
                        <td
                          style={{ borderLeft: "none", borderRight: "none" }}
                          className="previewLabelValue"
                        >
                          {
                            Records[0].injection_functional[
                              Records[0].injection_functional.length - 1
                            ].Injection_position
                          }
                        </td>
                        <td style={{ borderLeft: "none" }}></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <table>
                    <thead>
                      <th colSpan={5} style={{ padding: "0px" }}>
                        <Lable
                          value={"Temperature"}
                          type={"Head"}
                          style={{ padding: "1px" }}
                        />
                      </th>
                    </thead>
                    <tbody>
                      <tr style={{ height: "45px" }}>
                        <td>
                          <span style={{ textAlign: "center" }}>STAGES</span>
                        </td>
                        <td>
                          <span style={{ textAlign: "center" }}>
                            BARREL TEMP
                          </span>
                        </td>
                      </tr>
                      {Array.from({
                        length:
                          Records[0].temperature_function.length > 6
                            ? Records[0].temperature_function.length
                            : 6,
                      }).map((_, index) => {
                        if (
                          index > 2 &&
                          Records[0].temperature_function.length < 4
                        ) {
                          return (
                            <tr key={index}>
                              <td>
                                <div
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "auto auto",
                                  }}
                                >
                                  <span>
                                    {Records[0]?.temperature_function[index]
                                      ? Records[0]?.temperature_function[index]
                                        .Temperature_stages
                                      : "Zone " + (index - 1)}
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "center",
                                      fontWeight: "bold",
                                      color: "#8B0000",
                                    }}
                                  >{`±${Records[0]?.temperature_function[index]
                                    ? Records[0]?.temperature_function[index]
                                      ?.Temp_set
                                    : "10"
                                    }`}</span>
                                </div>
                              </td>
                              <td>
                                <span className="previewLabelValue">
                                  {Records[0]?.temperature_function?.Temp_value}
                                </span>
                              </td>
                            </tr>
                          );
                        } else {
                          if (index != 1) {
                            return (
                              <tr key={index}>
                                <td>
                                  <div
                                    style={{
                                      display: "grid",
                                      gridTemplateColumns: "auto auto",
                                    }}
                                  >
                                    <span>
                                      {
                                        Records[0]?.temperature_function[index]
                                          ?.Temperature_stages
                                      }
                                    </span>
                                    <span
                                      style={{
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        color: "#8B0000",
                                      }}
                                    >{`±${Records[0]?.temperature_function[index]?.Temp_set}`}</span>
                                  </div>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {
                                      Records[0]?.temperature_function[index]
                                        ?.Temp_value
                                    }
                                  </span>
                                </td>
                              </tr>
                            );
                          }
                        }
                      })}
                      <tr>
                        <td>
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "auto auto",
                            }}
                          >
                            <span>
                              {
                                Records[0]?.temperature_function[1]
                                  ?.Temperature_stages
                              }
                            </span>
                            <span
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                                color: "#8B0000",
                              }}
                            >{`±${Records[0]?.temperature_function[1]?.Temp_set}`}</span>
                          </div>
                        </td>
                        <td  className="previewLabelValue">
                          <span>
                            {Records[0]?.temperature_function[1]?.Temp_value}
                          </span>
                        </td>
                      </tr>

                      {/* {Records[0].temperature_function.map((item, index) => (
                                                <tr>
                                                    <td>
                                                        <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
                                                            <span>{item.Temperature_stages}</span>
                                                            <span>{`±${item.Temp_set}`}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span>{item.Temp_value}</span>
                                                    </td>
                                                </tr>
                                            ))} */}
                    </tbody>
                  </table>
                </div>
                <div>
                  <table>
                    <thead>
                      <th colSpan={4} style={{ padding: "0px" }}>
                        <Lable
                          value={"Hot Runner System Temperature"}
                          type={"Head"}
                          style={{ padding: "1px" }}
                        />
                      </th>
                    </thead>
                    <tbody>
                      <tr style={{ height: "45px" }}>
                        <td>
                          <span>
                            {Records[0].hotruntemp_function[0].Mani_fold_label}
                          </span>
                        </td>
                        <td>
                          <span>
                            {
                              Records[0].hotruntemp_function[0]
                                .Mani_fold_celsius
                            }
                          </span>
                        </td>
                        <td>
                          <span>
                            {Records[0].hotruntemp_function[0].Hot_runner_gate}
                          </span>
                        </td>
                        <td>
                          <span>°C</span>
                          <span
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              color: "#8B0000",
                            }}
                          >{` ±${Records[0].hotruntemp_function[0].Hot_runner_gate_celsius}`}</span>
                        </td>
                      </tr>
                      {Array.from({
                        length:
                          Records[0].hotruntemp_function.length > 6
                            ? Records[0].hotruntemp_function.length
                            : 6,
                      }).map((_, index) => {
                        if (Records[0].hotruntemp_function.length < 6) {
                          if (index != 0) {
                            return (
                              <tr key={index}>
                                <td>
                                  <div
                                    style={{
                                      display: "grid",
                                      gridTemplateColumns: "auto auto",
                                    }}
                                  >
                                    <span>
                                      {Records[0]?.hotruntemp_function[index]
                                        ? Records[0]?.hotruntemp_function[index]
                                          .Mani_fold_label
                                        : "ManiFold " + index}
                                    </span>

                                    <span
                                      style={{
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        color: "#8B0000",
                                      }}
                                    >
                                      {Records[0]?.hotruntemp_function[index]
                                        ?.Mani_fold_set !== undefined
                                        ? `±${Records[0]?.hotruntemp_function[index]?.Mani_fold_set}`
                                        : ""}
                                    </span>
                                  </div>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {
                                      Records[0]?.hotruntemp_function[index]
                                        ?.Mani_fold_celsius
                                    }
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {Records[0]?.hotruntemp_function[index]
                                      ? Records[0]?.hotruntemp_function[index]
                                        .Hot_runner_gate
                                      : "Gate " + index}
                                  </span>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {
                                      Records[0]?.hotruntemp_function[index]
                                        ?.Hot_runner_gate_celsius
                                    }
                                  </span>
                                </td>
                              </tr>
                            );
                          }
                        } else {
                          if (index != 0) {
                            return (
                              <tr key={index}>
                                <td>
                                  <div
                                    style={{
                                      display: "grid",
                                      gridTemplateColumns: "auto auto",
                                    }}
                                  >
                                    <span>
                                      {
                                        Records[0]?.hotruntemp_function[index]
                                          .Mani_fold_label
                                      }
                                    </span>
                                    <span className="previewLabelValue">
                                      {
                                        Records[0]?.hotruntemp_function[index]
                                          ?.Mani_fold_set
                                      }
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <span>
                                    {
                                      Records[0]?.hotruntemp_function[index]
                                        ?.Mani_fold_celsius
                                    }
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {Records[0]?.hotruntemp_function[index]
                                      ? Records[0]?.hotruntemp_function[index]
                                        .Hot_runner_gate
                                      : "Gate " + index}
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {
                                      Records[0]?.hotruntemp_function[index]
                                        ?.Hot_runner_gate_celsius
                                    }
                                  </span>
                                </td>
                              </tr>
                            );
                          }
                        }
                      })}
                      {/* {Records[0].hotruntemp_function.map((item, index) => {
                                                if (index != 0) {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
                                                                    <span>{item.Mani_fold_label}</span>
                                                                    <span>{item.Mani_fold_set}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <span>{item.Mani_fold_celsius}</span>
                                                            </td>
                                                            <td>
                                                                <span>{item.Hot_runner_gate}</span>
                                                            </td>
                                                            <td><span>{item.Hot_runner_gate_celsius}</span></td>
                                                        </tr>
                                                    )
                                                }
                                            })} */}
                    </tbody>
                  </table>
                </div>
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan={4}>
                          <Lable
                            value={"Seqemace Gate"}
                            type={"Head"}
                            style={{ padding: "0px" }}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ height: "45px" }}>
                        <td className="previewLabelValue">
                          <span>
                            {Records[0].seqemacegate_function[0].Seqemace_gate}
                          </span>
                        </td>
                        <td>
                          <span>ON</span>
                          <span className="previewLabelValue">{`±${Records[0].seqemacegate_function[0].Seqemace_gate_on}`}</span>
                        </td>
                        <td>
                          <span>OFF</span>
                          <span className="previewLabelValue">{`±${Records[0].seqemacegate_function[0].Seqemace_gate_off}`}</span>
                        </td>
                      </tr>
                      {Array.from({
                        length:
                          Records[0].seqemacegate_function.length > 6
                            ? Records[0].seqemacegate_function.length
                            : 6,
                      }).map((_, index) => {
                        if (Records[0].seqemacegate_function.length < 6) {
                          if (index != 0) {
                            console.log("index in < 6 ", index);
                            return (
                              <tr key={index}>
                                <td>
                                  <span>
                                    {Records[0]?.seqemacegate_function[index]
                                      ? Records[0].seqemacegate_function[index]
                                        .Seqemace_gate
                                      : "Gate " + index}
                                  </span>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {
                                      Records[0]?.seqemacegate_function[index]
                                        ?.Seqemace_gate_on
                                    }
                                  </span>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {
                                      Records[0]?.seqemacegate_function[index]
                                        ?.Seqemace_gate_off
                                    }
                                  </span>
                                </td>
                              </tr>
                            );
                          }
                        } else {
                          if (index != 0) {
                            console.log("index in > 6 ", index);
                            return (
                              <tr key={index}>
                                <td>
                                  <span>
                                    {
                                      Records[0]?.seqemacegate_function[index]
                                        ?.Seqemace_gate
                                    }
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {
                                      Records[0]?.seqemacegate_function[index]
                                        ?.Seqemace_gate_on
                                    }
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {
                                      Records[0]?.seqemacegate_function[index]
                                        ?.Seqemace_gate_off
                                    }
                                  </span>
                                </td>
                              </tr>
                            );
                          }
                        }
                      })}
                      {/* {Records[0].seqemacegate_function.map((item, index) => {
                                                if (index != 0) {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <span>{item.Seqemace_gate}</span>
                                                            </td>
                                                            <td>
                                                                <span>{item.Seqemace_gate_on}</span>
                                                            </td>
                                                            <td>
                                                                <span>{item.Seqemace_gate_off}</span>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            })} */}
                    </tbody>
                  </table>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={5} style={{ padding: "0px" }}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto auto",
                }}
              >
                <div>
                  <table>
                    <thead>
                      <th colSpan={5} style={{ padding: "0px" }}>
                        <Lable
                          value={"Holding Settings"}
                          type={"Head"}
                          style={{ padding: "1px" }}
                        />
                      </th>
                    </thead>
                    <tbody>
                      <tr style={{ height: "45px" }}>
                        <td>
                          <span>
                            {Records[0].holding_functional[0].Holding_stages}
                          </span>
                        </td>
                        <td>
                          <span>PRESSURE*(BAR)</span>
                          <span
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              color: "#8B0000",
                            }}
                          >{`±${Records[0].holding_functional[0].Holding_pressure}`}</span>
                        </td>
                        <td>
                          <span>SPEED% *</span>
                          <span
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              color: "#8B0000",
                            }}
                          >{`±${Records[0].holding_functional[0].Holding_speed}`}</span>
                        </td>
                        <td>
                          <span>POSITION(MM)⟡</span>
                          <span
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              color: "#8B0000",
                            }}
                          >{`±${Records[0].holding_functional[0].Holding_position}`}</span>
                        </td>
                        <td >
                          <span>TIME(SEC) *</span>
                          <span
                            style={{
                              
                              textAlign: "center",
                              fontWeight: "bold",
                              color: "#8B0000",
                            }}
                          >{`±${Records[0].holding_functional[0].Holding_time}`}</span>
                        </td>
                      </tr>
                      {Array.from({
                        length:
                          Records[0].holding_functional.length > 5
                            ? Records[0].holding_functional.length
                            : 5,
                      }).map((_, index) => {
                        if (Records[0].holding_functional.length < 5) {
                          if (index != 0) {
                            return (
                              <tr>
                                <td > 
                                  <span>
                                    {Records[0]?.holding_functional[index]
                                      ? Records[0]?.holding_functional[index]
                                        .Holding_stages
                                      : "Hold On " + index}
                                  </span>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {
                                      Records[0]?.holding_functional[index]
                                        ?.Holding_pressure
                                    }
                                  </span>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {
                                      Records[0]?.holding_functional[index]
                                        ?.Holding_speed
                                    }
                                  </span>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {
                                      Records[0]?.holding_functional[index]
                                        ?.Holding_position
                                    }
                                  </span>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {
                                      Records[0]?.holding_functional[index]
                                        ?.Holding_time
                                    }
                                  </span>
                                </td>
                              </tr>
                            );
                          }
                        } else {
                          if (index != 0) {
                            return (
                              <tr>
                                <td>
                                  <span>
                                    {
                                      Records[0]?.holding_functional[index]
                                        .Holding_stages
                                    }
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {
                                      Records[0]?.holding_functional[index]
                                        ?.Holding_pressure
                                    }
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {
                                      Records[0]?.holding_functional[index]
                                        ?.Holding_speed
                                    }
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {
                                      Records[0]?.holding_functional[index]
                                        ?.Holding_position
                                    }
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {
                                      Records[0]?.holding_functional[index]
                                        ?.Holding_time
                                    }
                                  </span>
                                </td>
                              </tr>
                            );
                          }
                        }
                      })}
                      {/* {
                                                Records[0].holding_functional.map((item, index) => {
                                                    if (index != 0) {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <span>{item.Holding_stages}</span>
                                                                </td>
                                                                <td>
                                                                    <span>{item.Holding_pressure}</span>
                                                                </td>
                                                                <td>
                                                                    <span>{item.Holding_speed}</span>
                                                                </td>
                                                                <td>
                                                                    <span>{item.Holding_position}</span>
                                                                </td>
                                                                <td>
                                                                    <span>{item.Holding_time}</span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                })
                                            } */}
                    </tbody>
                  </table>
                </div>
                <div>
                  <table>
                    <thead>
                      <th colSpan={6} style={{ padding: "0px" }}>
                        <Lable
                          value={"Injection Unit Settings"}
                          type={"Head"}
                          style={{ padding: "1px" }}
                        />
                      </th>
                    </thead>
                    <tbody>
                      {Records[0].injectionunitsettings_function.map(
                        (item, index) => {
                          if (index < 2) {
                            return (
                              <tr
                                key={index}
                                style={{ height: index === 0 ? "45px" : "" }}
                              >
                                <td>
                                  <span>{item.Inj_unit_stage} </span>
                                </td>
                                <td>
                                  <span>{item.Inj_unit_speed} </span>
                                </td>
                                <td>
                                  <span>{item.Inj_unit_time} </span>
                                </td>
                                <td>
                                  <span>{item.Inj_unit_sec} </span>
                                </td>
                                <td>
                                  <span>{item.Inj_unit_break_mode} </span>
                                </td>
                                <td>
                                  <span>{item.Inj_unit_value} </span>
                                </td>
                              </tr>
                            );
                          }
                        }
                      )}
                      {Records[0].injectionunitsettings_function.map(
                        (item, index) => {
                          if (index > 1 && index < 4) {
                            return (
                              <tr key={index}>
                                <td>
                                  <span>{item.Inj_unit_stage}</span>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {item.Inj_unit_speed}
                                  </span>
                                </td>
                                <td>
                                  <span>{item.Inj_unit_time}</span>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {item.Inj_unit_sec}
                                  </span>
                                </td>
                                <td>
                                  <span>{item.Inj_unit_break_mode}</span>
                                </td>
                                <td>
                                  <span>{item.Inj_unit_value}</span>
                                </td>
                              </tr>
                            );
                          }
                        }
                      )}
                      <tr>
                        <td colSpan={2}>
                          <span>
                            {
                              Records[0].injectionunitsettings_function[4]
                                .Inj_unit_stage
                            }
                          </span>
                        </td>
                        <td className="previewLabelValue">
                          <span >
                            {" "}
                            {
                              Records[0].injectionunitsettings_function[4]
                                .Inj_unit_time
                            }
                          </span>
                        </td>
                        <td colSpan={2}>
                          <span>
                            {
                              Records[0].injectionunitsettings_function[4]
                                .Inj_unit_break_mode
                            }
                          </span>
                        </td>
                        <td className="previewLabelValue">
                          <span>
                            {
                              Records[0].injectionunitsettings_function[4]
                                .Inj_unit_value
                            }
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan={5}>
                          <Lable
                            value={"Mould Temperature Details"}
                            type={"Head"}
                            style={{ padding: "0px" }}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Records[0].mouldtempdetails_function.map(
                        (item, index) => (
                          <tr
                            key={index}
                            style={{ height: index === 0 ? "45px" : "" }}
                          >
                            <td>
                              <span>{item.Mould_temp_details}</span>
                              <span
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  color: "#8B0000",
                                }}
                              >{` ±${item.Mould_temp_set}`}</span>
                            </td>
                            <td className="previewLabelValue" style={{width:"50%"}}>
                              <span >
                                {item.Mould_temp_value}
                              </span>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={5} style={{ padding: "0px" }}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto auto",
                }}
              >
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan={5}>
                          <Lable
                            value={"Refill Settings"}
                            type={"Head"}
                            style={{ padding: "0px" }}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      
                      <tr style={{ height: "45px" }}>
                      
                        <td>
                          <span className="previewLabelLeft">
                            {
                              Records[0].refilsetting_functional[0]
                                .Refilsetting_stages
                            } 
                          </span>   
                        
                        </td>
                        <td>
                          <span>PRESSURE*(BAR)</span>
                          <span
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              color: "#8B0000",
                            }}
                          >
                            {` ±${Records[0].refilsetting_functional[0].Refilsetting_pressure}`}{" "}
                          </span>
                        </td>
                        <td>
                          <span>SPEED% *</span>
                          <span
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              color: "#8B0000",
                            }}
                          >
                            {` ±${Records[0].refilsetting_functional[0].Refilsetting_speed}`}{" "}
                          </span>
                        </td>
                        <td>
                          <span>POSITION(MM)⟡</span>
                          <span
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              color: "#8B0000",
                            }}
                          >
                            {` ±${Records[0].refilsetting_functional[0].Refilsetting_position}`}{" "}
                          </span>
                        </td>
                        <td>
                          <span>TIME(SEC) *</span>
                          <span
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              color: "#8B0000",
                            }}
                          >
                            {` ±${Records[0].refilsetting_functional[0].Refilsetting_time}`}{" "}
                          </span>
                        </td>
                      </tr>
                      {Array.from({
                        length:
                          Records[0].refilsetting_functional.length > 7
                            ? Records[0].refilsetting_functional.length
                            : 7,
                      }).map((_, index) => {
                        if (Records[0].refilsetting_functional.length < 7) {
                          if (
                            index != 0 &&
                            index !=
                            Records[0].refilsetting_functional.length - 1 &&
                            index !=
                            Records[0].refilsetting_functional.length - 2
                          ) {
                            return (
                              <tr>
                                <td>
                                  <span>
                                    {Records[0]?.refilsetting_functional[index]
                                      ? Records[0]?.refilsetting_functional[
                                        index
                                      ].Refilsetting_stages
                                      : "Refill " + (index - 2)}
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {
                                      Records[0]?.refilsetting_functional[index]
                                        ?.Refilsetting_pressure
                                    }
                                  </span>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {
                                      Records[0]?.refilsetting_functional[index]
                                        ?.Refilsetting_speed
                                    }
                                  </span>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {
                                      Records[0]?.refilsetting_functional[index]
                                        ?.Refilsetting_position
                                    }
                                  </span>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {
                                      Records[0]?.refilsetting_functional[index]
                                        ?.Refilsetting_time
                                    }
                                  </span>
                                </td>
                                
                              </tr>
                            );
                          }
                        } else {
                          if (
                            index != 0 &&
                            index !=
                            Records[0].refilsetting_functional.length - 1 &&
                            index !=
                            Records[0].refilsetting_functional.length - 2
                          ) {
                            return (
                              <tr>
                                <td>
                                  <span>
                                    {
                                      Records[0]?.refilsetting_functional[index]
                                        .Refilsetting_stages
                                    }
                                  </span>
                                </td>
                                <td>
                                  <span
                                    style={{
                                      textAlign: "center",
                                      fontWeight: "bold",
                                      color: "#8B0000",
                                    }}
                                  >{` ±${Records[0]?.refilsetting_functional[index]?.Refilsetting_pressure}`}</span>
                                </td>
                                <td>
                                  <span
                                    style={{
                                      textAlign: "center",
                                      fontWeight: "bold",
                                      color: "#8B0000",
                                    }}
                                  >{` ±${Records[0]?.refilsetting_functional[index]?.Refilsetting_speed}`}</span>
                                </td>
                                <td>
                                  <span
                                    style={{
                                      textAlign: "center",
                                      fontWeight: "bold",
                                      color: "#8B0000",
                                    }}
                                  >{` ±${Records[0]?.refilsetting_functional[index]?.Refilsetting_position}`}</span>
                                </td>
                                <td>
                                  <span
                                    style={{
                                      textAlign: "center",
                                      fontWeight: "bold",
                                      color: "#8B0000",
                                    }}
                                  >{` ±${Records[0]?.refilsetting_functional[index]?.Refilsetting_time}`}</span>
                                </td>
                              </tr>
                            );
                          }
                        }
                      })}
                      {/* {
                                                Records[0].refilsetting_functional.map((item, index) => {
                                                    if (index != 0 && index != Records[0].refilsetting_functional.length - 1 && index != Records[0].refilsetting_functional.length - 2) {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <span>{item.Refilsetting_stages}</span>
                                                                </td>
                                                                <td>
                                                                    <span>{item.Refilsetting_pressure}</span>
                                                                </td>
                                                                <td>
                                                                    <span>{item.Refilsetting_speed}</span>
                                                                </td>
                                                                <td>
                                                                    <span>{item.Refilsetting_position}</span>
                                                                </td>
                                                                <td>
                                                                    <span>{item.Refilsetting_time}</span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                })
                                            } */}
                      <tr>
                        <td colSpan={3}>
                          <span>
                            {
                              Records[0].refilsetting_functional[
                                Records[0].refilsetting_functional.length - 2
                              ].Refilsetting_stages
                            }
                          </span>
                        </td>
                        <td colSpan={2} className="previewLabelValue">
                          <span>
                            {
                              Records[0].refilsetting_functional[
                                Records[0].refilsetting_functional.length - 2
                              ].Refilsetting_position
                            }
                          </span>
                        </td>
                        {/* <td>
                          <span className="previewLabelValue">
                            {
                              Records[0].refilsetting_functional[
                                Records[0].refilsetting_functional.length - 2
                              ].Refilsetting_stage_set
                            }
                          </span>
                        </td> */}
                        {/* <td colSpan={2}>
                          <span className="previewLabelValue">
                            {
                              Records[0].refilsetting_functional[
                                Records[0].refilsetting_functional.length - 2
                              ].Refilsetting_position
                            }
                          </span>
                        </td> */}
                      </tr>
                      <tr>
                      <td colSpan={3}>
                      <span>
                   {Records[0].refilsetting_functional[
                     Records[0].refilsetting_functional.length - 1
                    ].Refilsetting_stages}
                    <span style={{textAlign: "center",fontWeight: "bold",color: "#8B0000",}}>
                   {` ± ${Records[0].refilsetting_functional[
                    Records[0].refilsetting_functional.length - 1
                  ].Refilsetting_stage_set}`}
                     </span>  <span> (MM):</span></span>
                        </td>
                            <td colSpan={2} className="previewLabelValue">
                          <span >
                          {
                              Records[0].refilsetting_functional[
                                Records[0].refilsetting_functional.length - 1
                              ].Refilsetting_position
                            }
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan={5}>
                          <Lable
                            value={"Mould Close Settings"}
                            type={"Head"}
                            style={{ padding: "0px" }}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span>
                            {
                              Records[0].mouldclosesettings_function[0]
                                .Mould_close_stages
                            }
                          </span>
                        </td>
                        <td >
                       
                          <span>PRESSURE(BAR)⟡ </span>
                          <span style={{textAlign: "center",fontWeight: "bold",color: "#8B0000"}}
                          >{`±${Records[0].mouldclosesettings_function[0].Mould_close_pressure}`}</span>
                        </td>
                        <td>
                          <span>SPEED% ⟡</span>
                          <span
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              color: "#8B0000",
                            }}
                          >{` ±${Records[0].mouldclosesettings_function[0].Mould_close_speed}`}</span>
                        </td>
                        <td>
                          <span>POSITION (MM)⟡</span>
                          <span
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              color: "#8B0000",
                            }}
                          >{` ±${Records[0].mouldclosesettings_function[0].Mould_close_position}`}</span>
                        </td>
                      </tr>
                      {Records[0].mouldclosesettings_function.map(
                        (item, index) => {
                          if (index != 0 && index != Records[0].mouldclosesettings_function.length - 1) {
                            return (
                              <tr key={index}>
                                <td>
                                  <span>{item.Mould_close_stages}</span>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {item.Mould_close_pressure}
                                  </span>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {item.Mould_close_speed}
                                  </span>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {item.Mould_close_position}
                                  </span>
                                </td>
                              </tr>
                            );
                          }
                        }
                      )}
                      <tr>
                        <td>
                          <span>
                            {
                              Records[0].mouldclosesettings_function[Records[0].mouldclosesettings_function.length - 1]
                                .Mould_close_stages
                            }
                          </span>
                        </td>
                        <td>
                          <span className="previewLabelValue">
                            {
                              Records[0].mouldclosesettings_function[Records[0].mouldclosesettings_function.length - 1]
                                .Mould_close_pressure
                            }
                          </span>
                        </td>
                        <td>
                          <span>
                            {
                              Records[0].mouldclosesettings_function[Records[0].mouldclosesettings_function.length - 1]
                                .Mould_close_speed
                            }
                          </span>
                        </td>
                        <td className="previewLabelValue">
                          <span>
                            {
                              Records[0].mouldclosesettings_function[Records[0].mouldclosesettings_function.length - 1]
                                .Mould_close_position
                            }
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>Clamp Force⟡</td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan={5}>
                          <Lable
                            value={"Ejection Settings"}
                            type={"Head"}
                            style={{ padding: "0px" }}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span>
                            {
                              Records[0].ejectionsettings_function[0]
                                .Ejection_settings_stages
                            }
                          </span>
                        </td>
                        <td>
                          <span>PRESSURE (BAR)⟡</span>
                          <span
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              color: "#8B0000",
                            }}
                          >{` ±${Records[0].ejectionsettings_function[0].Ejection_settings_pressure}`}</span>
                        </td>
                        <td>
                          <span>SPEED% ⟡</span>
                          <span
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              color: "#8B0000",
                            }}
                          >{` ±${Records[0].ejectionsettings_function[0].Ejection_settings_speed}`}</span>
                        </td>
                        <td>
                          <span>TIME (SEC)</span>
                          <span
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              color: "#8B0000",
                            }}
                          >{` ±${Records[0].ejectionsettings_function[0].Ejection_settings_time}`}</span>
                        </td>
                        <td>
                          <span>POSITION (MM)⟡</span>
                          <span
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              color: "#8B0000",
                            }}
                          >{` ±${Records[0].ejectionsettings_function[0].Ejection_settings_position}`}</span>
                        </td>
                      </tr>
                      {Records[0].ejectionsettings_function.map(
                        (item, index) => {
                          if (index != 0) {
                            return (
                              <tr key={index}>
                                <td>
                                  <span>{item.Ejection_settings_stages}</span>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {item.Ejection_settings_pressure}
                                  </span>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {item.Ejection_settings_speed}
                                  </span>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {item.Ejection_settings_time}
                                  </span>
                                </td>
                                <td  className="previewLabelValue">
                                  <span>
                                    {item.Ejection_settings_position}
                                  </span>
                                </td>
                              </tr>
                            );
                          }
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={5} style={{ padding: "0px" }}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto auto",
                }}
              >
                <div>
                  <table>
                    <thead>
                      <th colSpan={5}>
                        <Lable
                          value={"Back Press Settings"}
                          type={"Head"}
                          style={{ padding: "0px" }}
                        />
                      </th>
                    </thead>
                    <tbody>
                      <tr style={{ height: "45px" }}>
                        <td>
                          <span>
                            {Records[0].backpres_function[0].Back_press_stages}
                          </span>
                        </td>
                        <td colSpan={4}>
                          <span>PRESSURE*(BAR)</span>
                          <span
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              color: "#8B0000",
                            }}
                          >{` ±${Records[0].backpres_function[0].Back_press_pressure}`}</span>
                        </td>
                        {/* <td>
                                                    <span>SPEED% *</span>
                                                    <span>{Records[0].backpres_function[0].Back_press_speed}</span>
                                                </td>
                                                <td>
                                                    <span>POSITION(MM)⟡</span>
                                                    <span>{Records[0].backpres_function[0].Back_press_position}</span>
                                                </td>
                                                <td>
                                                    <span>TIME(SEC) *</span>
                                                    <span>{Records[0].backpres_function[0].Back_press_time}</span>
                                                </td> */}
                      </tr>
                      {Records[0].backpres_function.map((item, index) => {
                        if (index != 0 && index < 3)
                          return (
                            <tr key={index}>
                              <td>
                                <span>{item.Back_press_stages}</span>
                              </td>
                              <td className="previewLabelValue">
                                <span >
                                  {item.Back_press_pressure}
                                </span>
                              </td>
                              {/* <td>
                                                            <span>{item.Back_press_speed}</span>
                                                        </td>
                                                        <td>
                                                            <span>{item.Back_press_time}</span>
                                                        </td> */}
                              <td className="previewLabelValue">
                                <span>{item.Back_press_position}</span>
                              </td>
                            </tr>
                          )
                      })}
                      <tr>
                        <td>
                          <span>{Records[0].backpres_function[3].Back_press_stages}</span>
                          <span style={{ textAlign: "center", fontWeight: "bold", color: "#8B0000" }}>{Records[0].backpres_function[3].Back_press_set}</span>
                        </td>
                        <td colSpan={4} className="previewLabelValue">
                          <span>{Records[0].backpres_function[3].Back_press_position}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>{Records[0].backpres_function[4].Back_press_stages}</span>
                          <span style={{ textAlign: "center", fontWeight: "bold", color: "#8B0000" }}>{` ±${Records[0].backpres_function[4].Back_press_set}`}</span>
                        </td>
                        <td colSpan={4} className="previewLabelValue">
                          <span>{Records[0].backpres_function[4].Back_press_time}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>{Records[0].backpres_function[5].Back_press_stages}</span>
                          <span style={{ textAlign: "center", fontWeight: "bold", color: "#8B0000" }}>{` ±${Records[0].backpres_function[5].Back_press_set}`}</span>
                        </td>
                        <td colSpan={4} className="previewLabelValue">
                          <span>{Records[0].backpres_function[5].Back_press_time}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span >{Records[0].backpres_function[6].Back_press_stages}</span>
                          <span style={{ textAlign: "center", fontWeight: "bold", color: "#8B0000" }}>{` ±${Records[0].backpres_function[6].Back_press_set}`}</span>
                        </td>
                        <td colSpan={4} className="previewLabelValue">
                          <span>{Records[0].backpres_function[6].Back_press_time}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <table>
                    <thead>
                      <th colSpan={4}>
                        <Lable value={"Mould Opening Settings"} type={"Head"} style={{ padding: "0px" }} />
                      </th>
                    </thead>
                    <tbody>
                      <tr style={{ height: "45px" }}>
                        <td>
                          <span>{Records[0].mouldopensettings_function[0].Mould_open_stages}</span>
                        </td>
                        <td>
                          <span>PRESSURE (BAR)⟡</span>
                          <span style={{ textAlign: "center", fontWeight: "bold", color: "#8B0000" }}>{` ±${Records[0].mouldopensettings_function[0].Mould_open_pressure}`}</span>
                        </td>
                        <td>
                          <span>SPEED% ⟡</span>
                          <span style={{ textAlign: "center", fontWeight: "bold", color: "#8B0000" }}>{` ±${Records[0].mouldopensettings_function[0].Mould_open_speed}`}</span>
                        </td>
                        <td>
                          <span>POSITION (MM)⟡</span>
                          <span style={{ textAlign: "center", fontWeight: "bold", color: "#8B0000" }}>{` ±${Records[0].mouldopensettings_function[0].Mould_open_position}`}</span>
                        </td>
                      </tr>
                      {Records[0].mouldopensettings_function.map((item, index) => {
                        if (index != 0) {
                          return (
                            <tr key={index}>
                              <td>
                                <span>{item.Mould_open_stages}</span>
                              </td>
                              <td className="previewLabelValue">
                                <span>{item.Mould_open_pressure}</span>
                              </td>
                              <td className="previewLabelValue">
                                <span>{item.Mould_open_speed}</span>
                              </td>
                              <td className="previewLabelValue">
                                <span>{item.Mould_open_position}</span>
                              </td>
                            </tr>
                          )
                        }
                      })}
                    </tbody>
                  </table>
                </div>
                <div>
                  <table>
                    <thead>
                      <th colSpan={5}>
                        <Lable value={"Core Settings"} type={"Head"} style={{ padding: "0px" }} />
                      </th>
                    </thead>
                    <tbody>
                      <tr style={{ height: "45px" }}>
                        <td>
                          <span>{Records[0].coresettings_function[0].Core_settings_stages}</span>
                        </td>
                        <td>
                          <span>PRESSURE (BAR)⟡</span>
                          <span style={{ textAlign: "center", fontWeight: "bold", color: "#8B0000" }}>{` ±${Records[0].coresettings_function[0].Core_settings_pressure}`}</span>
                        </td>
                        <td>
                          <span>SPEED% ⟡</span>
                          <span style={{ textAlign: "center", fontWeight: "bold", color: "#8B0000" }}>{` ±${Records[0].coresettings_function[0].Core_settings_speed}`}</span>
                        </td>
                        <td>
                          <span>TIME (SEC)</span>
                          <span style={{ textAlign: "center", fontWeight: "bold", color: "#8B0000" }}>{` ±${Records[0].coresettings_function[0].Core_settings_time}`}</span>
                        </td>
                        <td>
                          <span>POSITION (MM)⟡</span>
                          <span style={{ textAlign: "center", fontWeight: "bold", color: "#8B0000" }}>{` ±${Records[0].coresettings_function[0].Core_settings_position}`}</span>
                        </td>
                      </tr>
                      {Records[0].coresettings_function.map((item, index) => {
                        if (index != 0) {
                          return (
                            <tr key={index}>
                              <td>
                                <span>{item.Core_settings_stages}</span>
                              </td>
                              <td className="previewLabelValue">
                                <span>{item.Core_settings_pressure}</span>
                              </td>
                              <td className="previewLabelValue">
                                <span >{item.Core_settings_speed}</span>
                              </td>
                              <td className="previewLabelValue">
                                <span>{item.Core_settings_time}</span>
                              </td>
                              <td className="previewLabelValue">
                                <span>{item.Core_settings_position}</span>
                              </td>
                            </tr>
                          )
                        }
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="previewLabel">
                <span className="previewLabelLeft">Prepared By:</span>
                <span className="previewLabelRight">{Records[0].Prepared_by} - {Records[0]?.Preparedby[0]?.Emp_name}</span>
              </div>
            </td>
            <td colSpan={4}>
              <div className="previewLabel">
                <span className="previewLabelLeft">Approved By:</span>
                <span className="previewLabelRight">{Records[0].Approved_by} - {Records[0]?.Approvedby[0]?.Emp_name}</span>
              </div>
            </td>
          </tr>
          {!(window.location.href.includes('view')) && (
            <tr>
              {id === undefined || option === 'Duplicate' ? (
                <td colSpan={19}>
                  {" "}
                  <Field
                    className={"submit"}
                    label={"Submit"}
                    options={{ type: "Button" }}
                    onClick={(e) => {
                      HandleCreate(e);
                    }}
                  />
                </td>
              ) : (
                <td colSpan={19}>
                  {" "}
                  <Field
                    className={"submit"}
                    label={"Update"}
                    options={{ type: "Button" }}
                    onClick={(e) => {
                      HandleUpdate(e);
                    }}
                  />
                </td>
              )}
            </tr>
          )}
        </table>
      </div >
    </div>
  );

};
export default PreViewSmpPage;
