import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../Utils/constants';
import './Dashboard.css';

const Dashboard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleBackButton = () => {
      if (window.confirm('Are you sure you want to logout this page?')) {
        navigate('/');
        window.history.pushState(null, '', window.location.pathname);
      }
    };
    window.history.pushState(null, '', window.location.pathname);
    window.addEventListener('popstate', handleBackButton);
      document.title = "DashBoard"
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);
  
  const [das_brd_unitech, setDas_brd_unitech] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = {
          _keyword_: "das_brd_unitech",
        };
        const response = await axios.post(
          `http://${API_URL}/GenericResultBuilderService/buildResults`,
          data
        );
        console.log('API response:', response.data);
        setDas_brd_unitech(response.data.data[0]);
      } catch (error) {
        console.error('Error fetching API data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <h2 style={{ textAlign: "center" , fontSize:"24px",color:"#72705b" }}>Welcome {localStorage.getItem("name")}!</h2>
      {/* <div className="dashboard">
        <div className="box">
          <h2>Yesterday's Count</h2>
          <p>{das_brd_unitech.yesterday_count}</p>
        </div>
        <div className="box">
          <h2>Total Count</h2>
          <p>{das_brd_unitech.total_count}</p>
        </div>
      </div> */}
    </div>
  );
};

export default Dashboard;
