import axios from "axios"
import {  PROCESS_TRANSACTION_UPDATE_URL_PAINT, SECRET_KEY } from "../../Utils/constants"

const DeleteRecordByDeleteId= (KeyWord, id) => {
    return axios
        .post(PROCESS_TRANSACTION_UPDATE_URL_PAINT, {
            data: [{
                Id: id + "",
                Delete_id: "1"
             }], 
            _keyword_: KeyWord,
            secretkey: SECRET_KEY,
        })
        .then(res => {
            console.log(res.data);
            return res.data
          
        })
        .catch(error => {
            console.log("errordelete",data);
            return { error: error }
        })
}
export default DeleteRecordByDeleteId