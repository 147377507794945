
import React from "react";
import QaLayoutPaint from "../../QaLayoutPaint.js";
import ListViewPaint from "../../../../Components/ListView/ListViewPaint.js";


const SPPList = () => {
  return (
    <QaLayoutPaint quary={"painting_smp"}>
      <div>
      <ListViewPaint
          Title={"Standard Process Parameter"}
          standard={["Delete_id=0"]}
          // quary={"SELECT qp.Id,qp.Part_Name,qp.Delete_id, pr_name.part_name AS Part_Name, pg_name.program_name AS Program_Name, pn_name.paint_name AS Paint,Gun_Height,Gun_Angle FROM painting_smp qp LEFT JOIN paint_part_master pr_name ON qp.Part_Name = pr_name.part_code LEFT JOIN paint_program_master pg_name ON qp.Program_Name = pg_name.program_code LEFT JOIN paint_master pn_name ON qp.Paint = pn_name.paint_code group by Id ORDER BY Id desc"}
         quary={"SELECT Id,Part_Name,Program_Name,Paint,Delete_Id, Gun_Height,Gun_Angle FROM painting_smp group by Id ORDER BY Id desc"}
          table={"painting_smp"}
          tableDelete={"paint_SMP"}
          navigation={"StandardProcessParameter"}
          filter={["Id","Part_Name","Program_Name","Paint"]}     
          dateFilter={"CreatedDate"}
          header={[
                      { value: "Id", label: "ID" },
                      { value: "Part_Name", label: "Part Name" },
                      { value: "Program_Name", label: "Program Name" },
                      { value: "Paint", label: "Paint" },
                      { value: "Gun_Height", label: "GunHeight" },
                      { value: "Gun_Angle", label: "GunAngle" },
                    ]}
        />
      </div>
    </QaLayoutPaint>
  );
};

export default SPPList;
