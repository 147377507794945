import React from "react";

import QaLayoutPaint from "../../QaLayoutPaint";
import ListViewPaint from "../../../../Components/ListView/ListViewPaint";


const PaintMasterHeadList = () => {
 
  return (
    <QaLayoutPaint quary={"paint_master_head"}>
    <div>
      <ListViewPaint
        Title={"Paint Master Head"}
        standard={["delete_id=0"]}
        quary={"Select * from paint_master_head group by Id ORDER BY CASE WHEN ActiveStatus = 'Active' THEN 0 ELSE 1 END,Id DESC"}
        table={"paint_thinner_master"}
        tableDelete={"paint_master_head"}
        navigation={"PaintMasterHead"}
        filter={["Id","Batch_no","Ps","Category","ActiveStatus"]}
        dateFilter={"CreatedDate"}
        header={[
          { value: "Id", label: "ID" },
          { value: "CreatedDate", label: "Date" },
          { value: "Batch_no", label: "Batch No" },
          { value: "Ps", label: "PS" },
          { value: "Category", label: "Category" },
          { value: "ActiveStatus", label: "Active Status" },
        ]}
      />   
    </div>
    </QaLayoutPaint>
  );
};

export default PaintMasterHeadList;
