import React, { useState, useEffect } from "react";
import {
  SECRET_KEY,
  PROCESS_TRANSACTION_UPDATE_URL,
} from "../../../Utils/constants.js";
import Popup from "../../../Components/Popup/Popup";

import HandleRead from "../../../Lib/HandleRead.js";
import Pagination from "../../../Components/Pagination.js";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import UpdateRecord from "../../../Lib/updateRecord";
import { MdDelete } from "react-icons/md";
import ReadRecord from "../../../Lib/API/ReadRecord.js";

function MachineMasterView() {
  const [isCreating, setIsCreating] = useState(false);
  const [machinePartMasterAddState, setMachinePartMasterAddState] = useState({
    id: "",
    MacCde: "",
    MacNme: "",
    MacVer: "",
    Flexy1: "",
    Flexy2: "",
    Flexy3: "",
    isPaginationVisible: true,
    currentPage: 1,
    filterMcno: "",
    filterprtno: "",
  });

  const [ButtonPopup2, setButtonPopup2] = useState(false);
  const [ButtonPopup, setButtonPopup] = useState(false);
  const [mac_mas, setmac_mas] = useState([]);

  const recordPerPage = 20;

  const filterRecords = () => {
    const filteredData = mac_mas.filter((record) => {
      const mcnoMatch = record.mc_part_name
        .toLowerCase()
        .includes(machinePartMasterAddState.filterMcno.toLowerCase());
      const partcd = record.mc_part_code
        .toLowerCase()
        .includes(machinePartMasterAddState.filterprtno.toLowerCase());
      return mcnoMatch && partcd;
    });
    return filteredData || [];
  };
  const filteredData = filterRecords();
  const npage = Math.ceil(filteredData.length / recordPerPage);
  const numbers = [...Array(npage).keys()].map((num) => num + 1);

  function handleFilterMcnoChange(event) {
    setMachinePartMasterAddState({
      ...machinePartMasterAddState,
      currentPage: 1,
      filterMcno: event.target.value,
    });
  }
  function handleFilterPrtnoChange(event) {
    setMachinePartMasterAddState({
      ...machinePartMasterAddState,
      currentPage: 1,
      filterprtno: event.target.value,
    });
  }
  const firstIndex =
    (machinePartMasterAddState.currentPage - 1) * recordPerPage;
  const lastIndex = firstIndex + recordPerPage;
  const filteredRecords = filteredData.slice(firstIndex, lastIndex);

  function prePage() {
    if (machinePartMasterAddState.currentPage > 1) {
      setMachinePartMasterAddState({
        ...machinePartMasterAddState,
        currentPage: machinePartMasterAddState.currentPage - 1,
      });
    }
  }

  function nextPage() {
    if (machinePartMasterAddState.currentPage < npage) {
      setMachinePartMasterAddState({
        ...machinePartMasterAddState,
        currentPage: machinePartMasterAddState.currentPage + 1,
      });
    }
  }

  function changeCpage(id) {
    setMachinePartMasterAddState({
      ...machinePartMasterAddState,
      currentPage: id,
    });
  }

  const transferValue2 = (e) => {
    setMachinePartMasterAddState({
      ...machinePartMasterAddState,
      isPaginationVisible: false,
      MacCde: e.mc_part_code,
      MacNme: e.mc_part_name,
      MacVer: e.mc_part_version,
      Flexy1: e.mc_part_flex_01,
      Flexy2: e.mc_part_flex_02,
      Flexy3: e.mc_part_flex_03,
    });
    setButtonPopup2(true);
  };

  const viewPagination = () => {
    setMachinePartMasterAddState({
      ...machinePartMasterAddState,
      isPaginationVisible: true,
    });
  };

  const handleEdit = (e) => {
    setMachinePartMasterAddState({
      ...machinePartMasterAddState,
      isPaginationVisible: false,
      id: e.id,
      MacCde: e.mc_part_code,
      MacNme: e.mc_part_name,
      MacVer: e.mc_part_version,
      Flexy1: e.mc_part_flex_01,
      Flexy2: e.mc_part_flex_02,
      Flexy3: e.mc_part_flex_03,
    });
    setButtonPopup(true);
  };
  const handleUpdate = (e) => {
    e.preventDefault();

    if (
      !machinePartMasterAddState.MacCde ||
      !machinePartMasterAddState.MacNme ||
      !machinePartMasterAddState.Flexy1
    ) {
      setIsCreating(true);
      alert(GeneralConstants.MANDATORY_ALERT);
      return;
    }
    const data = {
      data: [
        {
          id: machinePartMasterAddState.id,
          mc_part_code: machinePartMasterAddState.MacCde,
          mc_part_name: machinePartMasterAddState.MacNme,
          mc_part_version: machinePartMasterAddState.MacVer,
          mc_part_flex_01: machinePartMasterAddState.Flexy1,
          mc_part_flex_02: machinePartMasterAddState.Flexy2,
          mc_part_flex_03: machinePartMasterAddState.Flexy3,
        },
      ],
    };
    data["_keyword_"] = "mac_part_dtl";
    data["secretkey"] = SECRET_KEY;
    getAPIRequest(PROCESS_TRANSACTION_UPDATE_URL, "POST", data)
      .then((response) => {
        setMachinePartMasterAddState({
          ...machinePartMasterAddState,
          MacCde: "",
          MacNme: "",
          MacVer: "",
          Flexy1: "",
          Flexy2: "",
          Flexy3: "",
          isPaginationVisible: true,
        });
        // setmac_mas([...mac_mas, response.data]);
        alert(
          `${machinePartMasterAddState.MacNme} ${GeneralConstants.UPDATE_SUCCESSFUL}`
        );
        // window.location.reload();
        setButtonPopup(false);
        setIsCreating(false);
        handleReadPartMaster();
      })
      .catch((error) => {
        console.log(error);
        alert(GeneralConstants.UPDATE_FAILURE);
        window.location.reload();
      });

    // window.location.reload();
  };
  function handleReadPartMaster() {
    ReadRecord({
      _keyword_: "mac_part_dtl",
    }).then((res) => {
      // console.log(res.data);
      setmac_mas(res.data);
    });
  }
  useEffect(() => {
    handleReadPartMaster();
  }, []);
  return (
    <div>
      <Popup
        trigger={ButtonPopup2}
        setTrigger={setButtonPopup2}
        viewPagination={viewPagination}
      >
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <form className="form-machinePartMaster-View">
          <table style={{ textAlign: "center", width: "100%" }} cellPadding="5">
            <tbody>
              <tr>
                <th
                  id="mpmv.Title.View"
                  name="mpmv.Title.View"
                  colSpan="4"
                  style={{ textAlign: "center", whiteSpace: "nowrap" }}
                >
                  <font color="#8B0000">View - Part Master</font>
                </th>
              </tr>
              <tr>
                <th
                  width="25%"
                  style={{ textAlign: "LEFT", whiteSpace: "nowrap" }}
                >
                  Part Code
                </th>
                <td width="25%">
                  <label
                    id="mpmv.machinePartCode.View"
                    name="mpmv.machinePartCode.View"
                  >
                    {machinePartMasterAddState.MacCde}
                  </label>
                </td>
                <th
                  width="25%"
                  style={{ textAlign: "LEFT", whiteSpace: "nowrap" }}
                >
                  Part Name
                </th>
                <td width="25%">
                  <label
                    id="mpmv.machinePartName.View"
                    name="mpmv.machinePartName.View"
                  >
                    {machinePartMasterAddState.MacNme}
                  </label>
                </td>
              </tr>
              <tr>
                <th
                  width="25%"
                  style={{ textAlign: "LEFT", whiteSpace: "nowrap" }}
                >
                  Part Version
                </th>
                <td width="25%">
                  <label
                    id="mpmv.machinePartVer.view"
                    name="mpmv.machinePartVer.view"
                  >
                    {machinePartMasterAddState.MacVer}
                  </label>
                </td>
                <th
                  width="25%"
                  style={{ textAlign: "LEFT", whiteSpace: "nowrap" }}
                >
                  Model
                </th>
                <td width="25%">
                  <label
                    id="mpmv.machinePartFlex1.view"
                    name="mpmv.machinePartFlex1.view"
                  >
                    {machinePartMasterAddState.Flexy1}
                  </label>
                </td>
              </tr>
              <tr>
                <th style={{ textAlign: "LEFT", whiteSpace: "nowrap" }}>
                  Cavity
                </th>
                <td>
                  <label
                    id="mpmv.machinePartFlex2.view"
                    name="mpmv.machinePartFlex2.view"
                  >
                    {machinePartMasterAddState.Flexy2}
                  </label>
                </td>
                <th style={{ textAlign: "LEFT", whiteSpace: "nowrap" }}>
                  Flex 1
                </th>
                <td>
                  <label
                    id="mpmv.machinePartFlex3.view"
                    name="mpmv.machinePartFlex3.view"
                  >
                    {machinePartMasterAddState.Flexy3}
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </Popup>
      <Popup
        trigger={ButtonPopup}
        setTrigger={setButtonPopup}
        viewPagination={viewPagination}
      >
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <form className="form-machinePartMaster-Edit">
          <table style={{ textAlign: "center", width: "100%" }} cellPadding="5">
            <tbody>
              <tr>
                <th
                  id="mpme.Title"
                  name="mpme.Title"
                  colSpan="4"
                  style={{ textAlign: "center", whiteSpace: "nowrap" }}
                >
                  <font color="#8B0000">Edit - Part Master</font>
                </th>
              </tr>
              <tr>
                <th>
                  &nbsp;&nbsp;Part Code<span className="Req-i">*</span>
                </th>
                <td>
                  <input
                    id="mpme.machinePartCode.Edit"
                    name="mpme.machinePartCode.Edit"
                    type="text"
                    value={machinePartMasterAddState.MacCde}
                    onChange={(e) =>
                      setMachinePartMasterAddState({
                        ...machinePartMasterAddState,
                        MacCde: e.target.value,
                      })
                    }
                    required
                    disabled
                  />
                </td>
                <th>
                  &nbsp;&nbsp;Part Name<span className="Req-i">*</span>
                </th>
                <td>
                  {machinePartMasterAddState.MacNme === "" && isCreating && (
                    <span className="Req">Required *</span>
                  )}
                  <input
                    id="mpme.machinePartName.Edit"
                    name="mpme.machinePartName.Edit"
                    type="text"
                    value={machinePartMasterAddState.MacNme}
                    onChange={(e) =>
                      setMachinePartMasterAddState({
                        ...machinePartMasterAddState,
                        MacNme: e.target.value,
                      })
                    }
                    required
                    disabled
                  />
                </td>
              </tr>
              <tr>
                <th>&nbsp;&nbsp;Part Version</th>
                <td>
                  <input
                    id="mpme.machinePartVer.Edit"
                    name="mpme.machinePartVer.Edit"
                    type="text"
                    value={machinePartMasterAddState.MacVer}
                    onChange={(e) =>
                      setMachinePartMasterAddState({
                        ...machinePartMasterAddState,
                        MacVer: e.target.value,
                      })
                    }
                  />
                </td>
                <th>
                  &nbsp;&nbsp;Model<span className="Req-i">*</span>
                </th>
                <td>
                  {machinePartMasterAddState.Flexy1 === "" && isCreating && (
                    <span className="Req">Required *</span>
                  )}
                  <input
                    id="mpme.machinePartFlex1.Edit"
                    name="mpme.machinePartFlex1.Edit"
                    type="text"
                    value={machinePartMasterAddState.Flexy1}
                    onChange={(e) =>
                      setMachinePartMasterAddState({
                        ...machinePartMasterAddState,
                        Flexy1: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>&nbsp;&nbsp;Cavity</th>
                <td>
                  <input
                    id="mpme.machinePartFlex2.Edit"
                    name="mpme.machinePartFlex2.Edit"
                    type="text"
                    value={machinePartMasterAddState.Flexy2}
                    onChange={(e) =>
                      setMachinePartMasterAddState({
                        ...machinePartMasterAddState,
                        Flexy2: e.target.value,
                      })
                    }
                  />
                </td>
                <th>&nbsp;&nbsp;Flex 1</th>
                <td>
                  <input
                    id="mpme.machinePartFlex3.Edit"
                    name="mpme.machinePartFlex3.Edit"
                    type="text"
                    value={machinePartMasterAddState.Flexy3}
                    onChange={(e) =>
                      setMachinePartMasterAddState({
                        ...machinePartMasterAddState,
                        Flexy3: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="4" style={{ border: "none" }}>
                  <button
                    id="mpme.submit"
                    name="mpme.submit"
                    type="submit"
                    onClick={(e) => handleUpdate(e)}
                  >
                    Update
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </Popup>

      <div>
        <table style={{ textAlign: "center", width: "100%" }}>
          <thead>
            <tr>
              <th
                id="mpmv.Title.List"
                name="mpmv.Title.List"
                colSpan="8"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">List - Part Master</font>
              </th>
            </tr>
            <tr>
              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                Part Name
              </td>
              <td>
                <input
                  id="mpmv.filter.partname"
                  name="mpmv.filter.partname"
                  type="text"
                  placeholder="Filter PartName"
                  style={{ fontSize: "80%", width: "100%" }}
                  value={machinePartMasterAddState.filterMcno}
                  onChange={handleFilterMcnoChange}
                />
              </td>
              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                Part Code
              </td>
              <td>
                <input
                  id="mpmv.filter.partcode"
                  name="mpmv.filter.partcode"
                  type="text"
                  placeholder="Filter PartCode"
                  style={{ fontSize: "80%", width: "100%" }}
                  value={machinePartMasterAddState.filterprtno}
                  onChange={handleFilterPrtnoChange}
                />
              </td>
              <td colSpan={3}></td>
            </tr>
            <tr className="table_position">
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Part Code&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Part Name&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Part Version&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Model&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Cavity&nbsp;&nbsp;
              </th>
              <th width="10%"></th>
              <th width="10%"></th>
              {/* <th width="10%"></th> */}
            </tr>
          </thead>
          <tbody>
            {/* <HandleRead keyword="mac_part_dtl" setData={setmac_mas} /> */}
            {filteredRecords.length === 0 ? (
              <td colSpan="12" style={{ textAlign: "center" }}>
                <p
                  id="mpmv.noRecordFound"
                  name="mpmv.noRecordFound"
                  style={{ fontWeight: "bold" }}
                >
                  No records found.
                </p>
              </td>
            ) : (
              filteredRecords.map((mac_mas) => (
                <tr className="table_view" key={mac_mas.id}>
                  <td
                    id="mpmv.mcPartCode"
                    name="mpmv.mcPartCode"
                    className="table-cell"
                  >
                    {mac_mas.mc_part_code}
                  </td>
                  <td
                    id="mpmv.mcPartName"
                    name="mpmv.mcPartName"
                    className="table-cell"
                  >
                    {mac_mas.mc_part_name}
                  </td>
                  <td
                    id="mpmv.mcPartName"
                    name="mpmv.mcPartName"
                    className="table-cell"
                  >
                    {mac_mas.mc_part_version}
                  </td>
                  <td
                    id="mpmv.mc_part_flex_01"
                    name="mpmv.mc_part_flex_01"
                    className="table-cell"
                  >
                    {mac_mas.mc_part_flex_01}
                  </td>
                  <td
                    id="mpmv.mc_part_flex_01"
                    name="mpmv.mc_part_flex_01"
                    className="table-cell"
                  >
                    {mac_mas.mc_part_flex_02}
                  </td>
                  <td>
                    <button
                      id="mpmv.mcPart.View"
                      name="mpmv.mcPart.View"
                      style={{ backgroundColor: "#cba423" }}
                      type="button"
                      onClick={() => transferValue2(mac_mas)}
                    >
                      View&#x1F441;
                    </button>
                  </td>
                  <td>
                    <button
                      id="mpmv.mcPart.Edit"
                      name="mpmv.mcPart.Edit"
                      type="button"
                      onClick={() => handleEdit(mac_mas)}
                    >
                      Edit&#x270E;
                    </button>
                  </td>
                  {/* <td>
                    <button
                      id={`mac_part_dtl-Delete`}
                      name={`mac_part_dtl-Delete`}
                      style={{ whiteSpace: "nowrap", backgroundColor: "#DA251A" }}
                      type="button"
                      onClick={() => {
                        const userConfirmed = window.confirm('Are you sure you want to delete?');
                        if (userConfirmed) {
                          UpdateRecord({
                            _keyword_: 'mac_part_dtl',
                            data: [
                              {
                                id: mac_mas.id,
                                mc_part_active_status: 'InActive',
                              },
                            ],
                          }).then(() => {
                            handleReadPartMaster();
                          })
                          // window.location.reload();
                        }
                      }}
                    >
                      Delete<MdDelete />
                    </button>
                  </td> */}
                </tr>
              ))
            )}
          </tbody>
        </table>
        <div className="pagination" id="mpmv.pagination" name="mpmv.pagination">
          {machinePartMasterAddState.isPaginationVisible &&
            filteredData.length > 0 && (
              <Pagination
                currentPage={machinePartMasterAddState.currentPage}
                npage={npage}
                prePage={prePage}
                nextPage={nextPage}
                changeCpage={changeCpage}
                numbers={numbers}
              />
            )}
        </div>
      </div>
    </div>
  );
}

export default MachineMasterView;
