import React, { useState,useRef } from "react";
import axios from "axios";
import { API_URL, SECRET_KEY } from "../../../Utils/constants.js";
import "../../../Styles/proskill.css";
import Unitechlogo from "../../../icons/Unitechlogo.jpeg";
import DatePicker from "../../../Lib/DatePicker.js";
import { Scores } from "../../../Components/Scores/Scores.js";
import DropdownFetcher from "../../../Lib/DropdownFetcher.js";
import Select from "react-select";
import { useEffect } from "react";

function ProductionSkillCreate() {
  const scrollTop = useRef(null);
  const initialSkillData = {
    Id: "",
    Revno: "",
    Fno: "",
    RDate: "",
    Name: "",
    Photo: "",
    Desn: "",
    Knw3C55: 0,
    Rmknw: 0,
    CommSkill: 0,
    ProKnw: 0,
    HourMon: 0,
    DocKnw: 0,
    Cumpoint: 0,
    LvlCls: "",
    EvlOn: "",
    nxtEvlOn: "",
    preparedby:"",
  };
  const [cummpoint, setcummpoint] = useState(false);

  const [skillData, setSkillData] = useState(initialSkillData);
  var [Pro_skill, setPro_skill] = useState([]);
  const [Employee, setEmployee] = useState([]);
  const [isCreating, setIsCreating] = useState(false);

useEffect(() => {
 document.title="Production Skill Matrix"
}, [])

  const handleDateChange = (newDate) => {
    setSkillData({
      ...skillData,
      EvlOn: newDate.target.value,
    });
  };
  const handlenextDateChange = (newDate) => {
    if(skillData.EvlOn!==''){
      if(skillData.EvlOn<newDate.target.value){
        setSkillData({
          ...skillData,
          nxtEvlOn: newDate.target.value,
        });
      }else{
        alert("Please Select date greater then Evaluated On Date")
      } 
    }else{
      alert("Please Select Evaluated On Date")
    }
  };
  //Unique id and name
  const id = "ProsCreate";
  const name = "ProsCreate";
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSkillData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const CodeGen = async () => {
    try {
      const val = {
        tablename: "Production_skill",
        PrimaryCode: "id",
        // PrimaryVal: Id,
        FieldCode: "Name",
        //     FieldVal: Name,
      };

      const response = await axios.post(
        `http://${API_URL}/AppContextService/CodeGen`,
        val
      );
      return response.data.success === "true";
    } catch (error) {
      throw error;
    }
  };

  const getPhotoUrlForName = (selectedName) => {
    if (selectedName === "san") {
      return employee3;
    } else if (selectedName === "krish") {
      return employee4;
    } else if (selectedName === "preeji") {
      return employee2;
    }

    return "";
  };


  const handleCreate = async (e) => {
    e.preventDefault();
    const isSubmissionSuccessful = true;
   if(!skillData.Name || !skillData.Cumpoint || !skillData.preparedby || !skillData.EvlOn || !skillData.nxtEvlOn) 
   {
      setIsCreating(true);
      alert("Please fill All the mandatory fields");
      if (scrollTop.current) {
        scrollTop.current.scrollIntoView({ behavior: "smooth" });
      }
      return;
    }

    try {
      const isDuplicate = await CodeGen();

      if (isDuplicate) {
      setIsCreating(false);

        alert(
          "Duplicate entry found in the database. Please enter unique values."
        );
        setSkillData(initialSkillData);

        // window.location.reload();
        return;
      }

      const data = {
        data: [
          {
            
            Name: skillData.Name.value,
            Photo: skillData.Photo,
            Prepared_by:skillData.preparedby.value,
            Designation: skillData.Desn,
            Knw_3c_55: skillData.Knw3C55,
            RM_Knowledge: skillData.Rmknw,
            Comm_Skill: skillData.CommSkill,
            Pro_knw: skillData.ProKnw,
            Hour_mon: skillData.HourMon,
            Doc_knw: skillData.DocKnw,
            Cum_Points: skillData.Cumpoint,
            Level_Class: skillData.LvlCls,
            Eval_on: skillData.EvlOn,
            Next_Eval_on: skillData.nxtEvlOn,
            
          },
        ],
        _keyword_: "Production_skill",
        secretkey: SECRET_KEY,
      };

      console.log("skillData",data);
      const response = await axios.post(
        `http://${API_URL}/GenericTransactionService/processTransaction`,
        data
      );

      if (response.data.success === "false") {
        alert("Problem in Saving Machine details");
        window.location.reload();
      } else {
        setPro_skill([...Pro_skill, response.data]);
      }
      if (isSubmissionSuccessful) {
        alert("Submission successful!");
        setSkillData(initialSkillData);
       // setIsCreating(false);

        if (scrollTop.current) {
          scrollTop.current.scrollIntoView({ behavior: "smooth" });
        }
   //     window.scrollTo(0, 0);
      }
    } catch (error) {
      console.log(error);
      alert("Submission failed.");
      window.location.reload();
    }
     
  };

  function setpoint(name, value) {
    setSkillData({
      ...skillData,
      [name]: value,
    });
    setcummpoint(false);
  }

  function handlecummpoint() {
    let cumm =
      skillData.Knw3C55 +
      skillData.Rmknw +
      skillData.CommSkill +
      skillData.ProKnw +
      skillData.HourMon +
      skillData.DocKnw;
let LvlCls = cumm*4
    setSkillData({
      ...skillData,
      Cumpoint: cumm,
      LvlCls:LvlCls
    });
    setcummpoint(true);
  }

  const handleEmployee = (selectedOption) => {
    const selected = Employee.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setSkillData({
        ...skillData,
        Name: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
        Photo: selected.Emp_image,
        Desn: selected.Emp_designation,
      });
    }
  };
  const handleprepared = (selectedOption) => {
    const selected = Employee.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setSkillData({
        ...skillData,
        preparedby: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      });
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          marginRight: "50px",
          position: "absolute",
          right: "35px",
          flexDirection: "column",
          borderRadius: "5px",
          overflow: "hidden",
          backgroundColor: "#96d4d4",
          border:"1px solid black",
          width:"15%"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent:"space-between",
            // gap: "50px",
            padding: "5px",
            borderBottom: "2px solid black",
          }}
        >
          <img className="pro_skill_img" src={"/0.png"} alt="image" />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent:'center'
            }}
          >
            <h3>Point - 0</h3>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent:"space-between",
            // gap: "50px",
            padding: "5px",
            borderBottom: "2px solid black",
          }}
        >
          <img  className="pro_skill_img" src={"/1.png"} alt="image" />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h3>Point - 1</h3>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent:"space-between",
            // gap: "50px",
            padding: "5px",
            borderBottom: "2px solid black",
          }}
        >
          <img className="pro_skill_img" src={"/2.png"} alt="image" />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h3>Point - 2</h3>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent:"space-between",
            // gap: "50px",
            padding: "5px",
            borderBottom: "2px solid black",
          }}
        >
          <img className="pro_skill_img" src={"/3.png"} alt="image" />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h3>Point - 3</h3>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent:"space-between",
            // gap: "50px",
            padding: "5px",
          }}
        >
          <img className="pro_skill_img" src={"/4.png"} alt="image" />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h3>Point - 4</h3>
          </div>
        </div>
      </div>
      <form
        id="proskill-formContainer-Create"
        name="proskill-formContainer-Create"
        className="form-proskill-Create"
      >
          <div ref={scrollTop}>
        <table
          style={{ textAlign: "center",  width: "80%" }}
        >
          <tbody>
            <tr>
              <th style={{ textAlign: "center", width: "10%" }}>
                <img  src={Unitechlogo} style={{ width: "40px" }}></img>
              </th>

              <th
                id={`${id}-Title`}
                name={`${name}-Title`}
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">PRODUCTION SKILL MATRIX</font>
              </th>
              <th>
                <span
                  style={{ marginRight: "100px", float: "left", width: "50%" }}
                >
                  FORMAT NO:
                </span>
                <label>{"UPCL/HRD/D/03"}</label>

                <span
                  style={{ marginRight: "40px", float: "left", width: "40%" }}
                >
                  REV NO:
                </span>
                <label>{"1"}</label>

                <span
                  style={{ marginRight: "0px", float: "left", width: "55%" }}
                >
                  REV DATE:
                </span>
                <label style={{textAlign:"end",paddingRight:"4%"}}>{"19.01.2016"}</label>
              </th>
            </tr>
            <tr>
              <td colSpan={2} className="topic-head">
                Name :{" "}<span style={{ color: "red" }}>*</span>
              </td>
              <td>
                <DropdownFetcher keyword="emp_details" setValue={setEmployee} />
                <Select
                  styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                  value={skillData.Name}
                  onChange={handleEmployee}
                  options={Employee.map((i) => ({
                    value: i.Emp_code,
                    label: i.Emp_name,
                  }))}
                />
                {skillData.Name === '' && isCreating && <span className="Req">Required *</span>}

              </td>
            </tr>
            <tr>
              <td colSpan={2} className="topic-head">
                {" "}
                Photo :
              </td>
              <td className="phototd">
                {skillData.Photo && (
                  <img  className="photo" src={skillData.Photo} alt="Selected" />
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="topic-head">
                Designation :
              </td>
              <td>
                <input
                  type="text"
                  value={skillData.Desn}
                  onChange={(e) => handleInputChange(e)}
                  disabled
                  name="Desn"
                />
              </td>{" "}
            </tr>

            <tr>
              {" "}
              <td colSpan={2} className="topic-head">
                Knowledge in 3C & 55 :<span style={{ color: "red" }}>*</span>
               
              </td>
              <td>
                <Scores
                  setpoint={setpoint}
                  name={"Knw3C55"}
                  value={skillData.Knw3C55}
                />
                  
              </td>
            </tr>
            <tr>
              {" "}
              <td colSpan={2} className="topic-head">
                RM Knowledge :<span style={{ color: "red" }}>*</span>
                {skillData.Rmknw === '' && isCreating && <span className="Req">Required *</span>}
              </td>
              <td>
                <Scores
                  value={skillData.Rmknw}
                  name={"Rmknw"}
                  setpoint={setpoint}
                />
                
              </td>
            </tr>
            <tr>
              {" "}
              <td colSpan={2} className="topic-head">
                Communication Skill :
                <span style={{ color: "red" }}>*</span>
                {skillData.CommSkill === '' && isCreating && <span className="Req">Required *</span>}
              </td>
              <td>
                <Scores
                  value={skillData.CommSkill}
                  name={"CommSkill"}
                  setpoint={setpoint}
                />
                 
              </td>
            </tr>
            <tr>
              {" "}
              <td colSpan={2} className="topic-head">
                Product Knowledge :<span style={{ color: "red" }}>*</span>
                {skillData.ProKnw === '' && isCreating && <span className="Req">Required *</span>}
              </td>
              <td>
                <Scores
                  value={skillData.ProKnw}
                  name={"ProKnw"}
                  setpoint={setpoint}
                />
                
              </td>
            </tr>
            <tr>
              {" "}
              <td colSpan={2} className="topic-head">
                Hourly Production Monitoring :<span style={{ color: "red" }}>*</span>
                {skillData.HourMon === 'Point - 0' && isCreating && <span className="Req">Required *</span>}
              </td>
              <td>
                <Scores
                  value={skillData.HourMon}
                  name={"HourMon"}
                  setpoint={setpoint}
                />
                
              </td>
            </tr>
            <tr>
              {" "}
              <td colSpan={2} className="topic-head">
                Document Knowledge :<span style={{ color: "red" }}>*</span>
                {skillData.DocKnw === '' && isCreating && <span className="Req">Required *</span>}
              </td>
              <td>
                <Scores
                  value={skillData.DocKnw}
                  name={"DocKnw"}
                  setpoint={setpoint}
                />
                
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="topic-head">
                Cumulative Points :<span style={{ color: "red" }}>*</span>
                
              </td>
              <td>
              
                {cummpoint ? (
                  <input
                    type="text"
                    value={skillData.Cumpoint}
                    name="Cumpoint"
                    disabled
                  />
                ) : (
                  <div
                    onClick={handlecummpoint}
                    style={{
                      width: "98%",
                      height: "98%",
                      backgroundColor: "#96d4d4",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <h4>Populate</h4>
                   
                  </div>
                )}
              </td>
            </tr>
            <tr>
              {" "}
              <td colSpan={2} className="topic-head">
                Level Classification :
              </td>
              <td>
                <input
                  type="text"
                  value={skillData.LvlCls}
                  onChange={(e) => handleInputChange(e)}
                  name="LvlCls"
                  disabled
                />
              </td>
            </tr>
            <tr>
              {" "}
              <td colSpan={2} className="topic-head">
                Evaluated On :<span style={{ color: "red" }}>*</span>
              </td>
              <td>
              <input
                type="date"
                value={skillData.EvlOn}
                onChange={handleDateChange}
              />
                  {skillData.EvlOn === '' && isCreating && <span className="Req">Required *</span>}
             
              </td>
            </tr>
            <tr>
              {" "}
              <td colSpan={2} className="topic-head">
                Next Evaluated On :<span style={{ color: "red" }}>*</span>
              </td>
              <td>
              <input
                type="date"
                value={skillData.nxtEvlOn}
                onChange={handlenextDateChange}
              />
                {skillData.nxtEvlOn === '' && isCreating && <span className="Req">Required *</span>}

              </td>
            </tr>
            <tr>
              <td colSpan={2} className="topic-head">
                Prepared By :{" "}<span style={{ color: "red" }}>*</span>
              </td>
              <td>
                
                <Select
                  styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                  value={skillData.preparedby}
                  onChange={handleprepared}
                  options={Employee.map((i) => ({
                    value: i.Emp_code,
                    label: i.Emp_name,
                  }))}
                />
                                {skillData.preparedby === '' && isCreating && <span className="Req">Required *</span>}

              </td>
            </tr>

            <tr>
              <td colSpan="5" align="center" style={{ border: "none" }}>
                <button
                  type="submit"
                  id={`${id}-submit`}
                  name={`${name}-submit`}
                  onClick={(e) => handleCreate(e)}
                >
                  Submit
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
      </form>
    </div>
  );
}

export default ProductionSkillCreate;
