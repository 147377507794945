import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PreViewPage from "./PreViewPage";
import { CURRENT_Date, CURRENT_TIME, SHIFTMOLDINGQUALITY } from "../../../../Utils/GeneralConstants";
import ReadRecordPaint from "../../../../Lib/API/ReadRecordPaint";

const ViewPage = () => {
  
    const InitRoot = [
        {
         download: false,
        S_time: CURRENT_TIME,
        C_time:"",
        Shift: SHIFTMOLDINGQUALITY,
        Format_no: "UPCL/PRO/R/35",
        Rev_no: "01",
        Rev_ate: "18-05-2017",
        Entry_date: CURRENT_Date,
        Ins_name: "",
        Inspector_sign: "",
        Shift_incharge: "",
        Approved_sign: "",
        InsName: [{}],
        InspectorS: [{}],
        Shiftincharge: [{}],
        ApprovedS: [{}],
        paint_final_ins_bulk_report: [
          {
            PartName: {},
            Part_name: "",
            Part_no: "",
            Qty_chk: "",
            Qty_ok: "",
            Dust: "",
            Lint: "",
            Col_mismatch: "",
            Dry_spray: "",
            Sag: "",
            Less_cover: "",
            Touch_mark: "",
            Stress_mark: "",
            Pin_hole: "",
            Bubbles: "",
            Blister: "",
            Oil_mark: "",
            Damage: "",
            Dent: "",
            Flash: "",
            Others: "",
            Total_qty_rej: "",
            Reason_for_rej: "",
            Remarks: "",
          },
        ],
        paint_names: [
          {key:"Inspector Sign",value:""},
          {key:"Shift Incharge Sign",value:""},
          {key:"Approved Sign",value:""},
       
        ],
      },
      ];
    const navigate = useNavigate();
    const [Records, setRecords] = useState(InitRoot);
    const [Preview, setPreView] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        console.log("Records", Records);
    }, [Records]);

    useEffect(() => {
        console.log("id",id);
        document.title = 'FinalInspection';
        const fetchData = async () => {
            if (id === undefined) {
                setRecords({});
            } else {
                try {
                    const res = await ReadRecordPaint({
                        _keyword_: 'paint_final_inspection',
                        Id: id,
                    });

                    if (res.data[0] === undefined) {
                        setRecords(InitRoot);
                    } else
                    {
                      console.log("view",res.data);
                    //     const updatedPaintFinalInsBulkReport = {
                    //     ...InitRoot[0].paint_final_ins_bulk_report[0],
                    //     Part_name: res.data[0].PartName,
                    //   };
            
                      const mergedData = {
                        ...res.data[0],
                        paint_names: InitRoot[0].paint_names,
                     //   paint_final_ins_bulk_report: [updatedPaintFinalInsBulkReport], 
                      };
                      setRecords([mergedData]);
                      setPreView(true)
                    }
                } catch(error) {
                    console.log(error);
                }
            }
        };

        fetchData();
    }, [id]);

    useEffect(() => {
        if (Preview === false) {
            navigate(-1);
        }
    }, [Preview, navigate]);

    return Preview && <PreViewPage Records={Records} setState={setPreView}/>;
}

export default ViewPage;
