
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Field from "../../../../Components/Fields/Field.js";
import LoadingPage from "../../../../Components/Handler/LoadingPage.js";
import * as GeneralConstants from "../../../../Utils/GeneralConstants.js";
import UpdateRecordPaint from "../../../../Lib/API/UpdateRecordPaint.js";
import IsDuplicatePaint from "../../../../Lib/IsDuplicatePaint.js";
import Lable from "../../../../Components/Fields/Lable.js";
import CreateRecordPaint from "../../../../Lib/API/CreateRecordPaint.js";
import QaLayoutPaint from "../../QaLayoutPaint.js";
import ReadRecordPaint from "../../../../Lib/API/ReadRecordPaint.js";
const initState = {
  // TrackDate: new Date().toISOString().split("T")[0],
  Customer_code: "",
  Customer_name: "",
  Active_status:"Active",
  Flex_01: "",
  Flex_02: "",
};

function CustomerMaster() {
  const [Create, setCreate] = useState(initState);
  const [warning, setwarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const { option, id } = useParams();
  const navigate = useNavigate(); 
  useEffect(() => {
    console.log("Create", Create);
  }, [Create]);
   useEffect(() => {
    setwarning(false);
    document.title = "Paint Customer Master";
    const fetchData = async () => {
      if (id === undefined) {
        setCreate(initState);
      } else {
        setLoading(true);
        try {
          const res = await ReadRecordPaint({
            _keyword_: "paint_customer_master",
            Id: id,
          },"paint");  
          console.log("res",res)    
          setCreate(res.data[0])    
         // console.log("Res2",Create)
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  const handleCreate = async () => {
    try{
   // console.log(Create);
   const IsDuplicatePaintCode = await IsDuplicatePaint("paint_customer_master", [`customer_code="${Create.Customer_code}"`]);

    if (IsDuplicatePaintCode) {
      alert(`Customer with code ${Create.Customer_code} already exists.`);
      return;
    }

    const IsDuplicatePaintName = await IsDuplicatePaint("paint_customer_master", [`customer_name="${Create.Customer_name}"`]);

    if (IsDuplicatePaintName) {
      alert(`Customer with name ${Create.Customer_name} already exists.`);
      return;
    }
    console.log(Create);
    if (!Create.Customer_code || !Create.Customer_name || !Create.Active_status) {
      alert("Please Fill the Mandatory Fields");
   setwarning(true)
      return;
    } else {
      let record = [{ ...Create }];
        CreateRecordPaint("paint_customer_master", record,"paint").then((res) => {
        console.log("HandleCreate", res);
      });
      alert(GeneralConstants.SUBMISSION_SUCCESSFUL);
      setwarning(false);
      setCreate(initState);
    }}
    catch (error){
      console.error(error);
      alert(GeneralConstants.SUBMISSION_FAILED);

    }
  }
  function handleUpdate() {
    try {
      if (!Create.Customer_code || !Create.Customer_name || !Create.Active_status) {
        alert("Please Fill the Mandatory Fields");
        setwarning(true)
        return;
      }
  
      let record = [{ ...Create }];
      UpdateRecordPaint("paint_customer_master", record).then((res) => {
        console.log("HandleUpdate",res );
    });
  
      alert(GeneralConstants.UPDATE_SUCCESSFUL);
      setwarning(false);
    //  setCreate(initState);
    } catch (error) {
      console.error(error);
      alert(GeneralConstants.UPDATE_FAILURE);
      setwarning(false);
    
    }
    navigate(-1);
  };

  return (
<QaLayoutPaint>
    <div>
      <table>
        <tbody>
          <tr>
            <th colSpan={8}>
              <Lable value={"Customer Master"} type={"Head"} />
            </th>
          </tr>
          <tr>
            <td>
              <Field
                label={"Customer Code"}
                name={"Customer_code"}
                options={{ type: "text" ,
                required: true,
                disabled: option === "view"|| option === "edit",
                }}
                warning={warning}
                value={Create}
                OnChange={(e) =>
                  setCreate({ ...Create, Customer_code: e.target.value })
                }
              />
            </td>
            <td>
              <Field
                label={"Customer Name"}
                name={"Customer_name"}
                options={{ type: "text",  required: true,
                disabled: option === "view"|| option === "edit",
                }}
                warning={warning}
                value={Create}
                OnChange={(e) =>
                  setCreate({ ...Create, Customer_name: e.target.value })
                }
              />
            </td>
          </tr>
          <tr>
            <td>
              <Field
                label={"Flex"}
                name={"Flex_01"}
                options={{ type: "text", disabled: option === "view", }}
                value={Create}
                OnChange={(e) =>
                  setCreate({ ...Create, Flex_01: e.target.value })
                }
              />
            </td>
            <td>
              <Field
                label={"Active Status"}
                name={"Active_status"}
                options={{
                  type: "dropdown",
                  required: true,
                  disabled: option === "view",
                  values: [
                    { value: "", label: "Select" },
                    { value: "Active", label: "Active" },
                    { value: "InActive", label: "InActive" },
                  ],
                }}
                warning={warning}
                value={Create}
                OnChange={(e) =>
                  setCreate({ ...Create, Active_status: e.target.value })
                }
              />
            </td>
          </tr>
        
          {!(option === "view") && (
              <tr>
                {id === undefined ? (
                  <td colSpan={19}>
                    {" "}
                    <Field
                      label={"Submit"}
                      options={{ type: "Button" }}
                      onClick={(e) => {
                        handleCreate(e);
                      }}
                    />
                  </td>
                ) : (
                  <td colSpan={19}>
                    {" "}
                    <Field
                      label={"Update"}
                      options={{ type: "Button" }}
                      onClick={(e) => {
                        handleUpdate(e);
                      }}
                    />
                  </td>
                )}
              </tr>
            )}
        </tbody>
      </table>
    </div>
    </QaLayoutPaint>
   
  );
}

export default CustomerMaster;
