import React, { useState, useRef, useEffect } from "react";
import {
  SECRET_KEY,
  CODEGEN_URL,
  PROCESS_TRANSACTION_URL,
  PROCESS_TRANSACTION_UPDATE_URL,
} from "../../../Utils/constants.js";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import "../../../Styles/DailyShift.css";
import Unitechlogo from "../../../icons/Unitechlogo.jpeg";
import "../../../Styles/MaterialLoad&UnLoad.css";

import useScanDetection from "use-scan-detection";
import Select from "react-select";
import DatePicker from "../../../Lib/DatePicker.js";
import DropdownFetcher from "../../../Lib/DropdownFetcher.js";
import DropdownFilterSelect from "../../../Components/DropdownFilterSelectFetcher.js";
import ShiftDropdownown from "../../../Components/ShiftDropdown.js";
import OkNotOkayFetch from "../../../Components/OkAndNotOkFetcher.js";
import GetTableRecord from "../../../Lib/GetTableRecord.js";
import ReadRecord from "../../../Lib/API/ReadRecord.js";
import { setMessage } from "../../../Lib/SetMessage.js";
import {
  CURRENT_Date,
  CURRENT_TIME,
  SHIFTMOLDINGPRODUCTION,
} from "../../../Utils/GeneralConstants";
function MldUnloadAndLoadCreate() {
  const currentDateTime = new Date();
  const hours = currentDateTime.getHours().toString().padStart(2, "0");
  const minutes = currentDateTime.getMinutes().toString().padStart(2, "0");
  const currentTime = `${hours}:${minutes}`;

  const initialState = {
    EjcRod: "",
    MoldPreHeat: "",
    PartNameLoadLabel: "",
    PartNameLoad: "",
    TrackDateLoad: CURRENT_Date,
    EyeLiftBely: "",
    ChkTheMTC: "",
    CoolValue: "",
    MldWtrOutFlw: "",
    ChkBlushNozle: "",
    ChkLocatingRing: "",
    LoadingTimeFrom: "",
    LoadingTimeTo: "",
    ChkCoolIntOutCondn: "",
    RawMaterialName: "",
    Shift: SHIFTMOLDINGPRODUCTION,
    // MCNo: "",
    CustomerNameLoad: "",
    CustomerNameLoadLabel: "",
    ChkNozleSizeCondn: "",
    Butting: "",
    UseSetMdeOpnMold: "",
    EnsureMldClnPro: "",
    MldSfty1Pres: "",
    MldSfty2Pres: "",
    UnLoadingTimeFrom: "",
    UnLoadingTimeTo: "",
    MoldSetterLoad: "",
    MoldSetterLoadLabel: "",
    RawMaterialNameLabel: "",
    PartNameUnload: "",

    TrackDateUnLoad: "",
    CustomerNameUnload: "",
    MoldSetterUnLoad: "",
    MouldClean: "",
    ApplyAniWaxCoat: "",
    EyeBoltLiftBelt: "",
    MouldClsRemveWatrLne: "",
    RemoveWatrUseAir: "",
    UnclampMold: "",
    ChkLocatingCover: "",
    LPerM: "",
    MouldLocationArea: "",
    Remarks: "",
    TechnicanName: "",
    ToolroomName: "",
    ProductionEnggName: "",

    EyeLiftBelyRemarks: "",
    EjcRodRemarks: "",
    CoolValueRemarks: "",
    MoldPreHeatRemarks: "",
    ChkTheMTCRemarks: "",
    MldWtrOutFlwRemarks: "",
    ChkBlushNozleRemarks: "",
    ChkLocatingRingRemarks: "",
    ButtingRemarks: "",
    ChkNozleSizeCondnRemarks: "",
    ChkCoolIntOutCondnRemarks: "",
    UseSetMdeOpnMoldRemarks: "",
    EnsureMldClnProRemarks: "",
    MldSfty1PresRemarks: "",
    MldSfty2PresRemarks: "",
    LPerMRemarks: "",
    MouldCleanRemarks: "",
    ApplyAniWaxCoatRemarks: "",
    EyeBoltLiftBeltRemarks: "",
    MouldClsRemveWatrLneRemarks: "",
    RemoveWatrUseAirRemarks: "",
    UnclampMoldRemarks: "",
    ChkLocatingCoverRemarks: "",
    MoldSetterUnLoadLabel: "",
    TechnicanNameLabel: "",
    ToolroomNameLabel: "",
    ProductionEnggNameLabel: "",

    Mld_load_unload: [],
  };
  const scrollTop = useRef(null);
  const [MCNo,setMCNo] = useState("");
  console.log("MCNo",MCNo);
  const [MouldLoadAndUnload, setMouldLoadAndUnload] = useState(initialState);
  const [partDropdown, setPartDropdown] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [dropdown, setDropdown] = useState([]);
  const [Rmdropdown, setRmDropdown] = useState([]);
  const [CustomerDropdown, setCustomerDropdown] = useState([]);
  const [EmployeeDropdown, setEmployeeDropdown] = useState([]);
  const [Moldsetter, setMoldsetterDropdown] = useState([]);
  const [Load_unload, setLoad_unload] = useState([true, true, true, true]);
  //Unique id and name
  const id = "MldUnLoadAndLoadCreate";
  const name = "MldUnLoadAndLoadCreate";

  const handleDateChange = (newDate) => {
    setMouldLoadAndUnload({ ...MouldLoadAndUnload, TrackDateLoad: newDate });
  };
  const handleDateChange1 = (newDate) => {
    setMouldLoadAndUnload({ ...MouldLoadAndUnload, TrackDateUnLoad: newDate });
  };

  const partNoOptions = partDropdown.map((i) => ({
    value: i.mc_part_code,
    label: i.mc_part_name,
  }));
  const mcNoOptions = dropdown.map((i) => ({
    value: i.mh_mac_name,
    label: i.mh_mac_name,
  }));
  const rmOptions = Rmdropdown.map((i) => ({
    value: i.Rm_code,
    label: i.Rm_description,
  }));
  const customerOptions = CustomerDropdown.map((i) => ({
    value: i.Cus_code,
    label: i.Cus_name,
  }));
  const empAllOptions = EmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const MoldsetterOptions = Moldsetter.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));

  // const CodeGen = async () => {
  //   try {
  //     const val = {
  //       tablename: "Mould_loading_unloading",
  //       PrimaryCode: "Mat_name",
  //       PrimaryVal: MouldLoadAndUnload.PartNameLoad,
  //       FieldCode: "Mat_grde",
  //       FieldVal: MouldLoadAndUnload.RawMaterialName,
  //     };
  //     console.log(val);
  //     const response = await getAPIRequest(CODEGEN_URL, "POST", val);
  //     const response1 = await response.json();
  //     console.log("response1", response1);
  //     return response1.success === "true";
  //   } catch (error) {
  //     throw error;
  //   }
  // };

  // const currentDateTime = new Date();
  // const hours = currentDateTime.getHours().toString().padStart(2, "0");
  // const minutes = currentDateTime.getMinutes().toString().padStart(2, "0");
  // const currentTime = `${hours}:${minutes}`;
  // const handleDropdownChangeLoad = (selectedOption) => {
  //   const selectedMachine = partDropdown.find(
  //     (option) => option.mc_part_code === selectedOption.value
  //   );
  //   console.log(selectedMachine, selectedOption);

  //   if (selectedMachine) {
  //     setMouldLoadAndUnload({
  //       ...MouldLoadAndUnload,
  //       PartNameLoad: selectedMachine.mc_part_code,
  //       PartNameLoadLabel: selectedMachine.mc_part_name,
  //       // TrackDateLoad: currentDateTime.toISOString().split("T")[0],
  //       // Shift: determineShift(),
  //       LoadingTimeFrom: currentTime,
  //     });
  //   }
  // };

  // const handleDropdownChangeUnLoad = (selectedOption) => {
  //   const selectedMachine1 = partDropdown.find(
  //     (option) => option.mc_part_code === selectedOption.value
  //   );

  //   if (selectedMachine1) {
  //     setMouldLoadAndUnload({
  //       ...MouldLoadAndUnload,
  //       PartNameUnload: selectedMachine1.mc_part_code,
  //       PartNameUnloadLabel: selectedMachine1.mc_part_name,
  //       TrackDateUnLoad: currentDateTime.toISOString().split("T")[0],
  //       UnLoadingTimeFrom: currentTime,
  //     });
  //   }
  // };
  // const handleDropdownMouldSetter =(selectedOption) =>{
  //   const selectedMachine = EmployeeDropdown.find(
  //     (option) => option.Emp_name === selectedOption.label
  //   );
  //   if (selectedMachine) {
  //     setMouldLoadAndUnload({
  //       ...MouldLoadAndUnload,
  //       MoldSetterLoad: selectedMachine.Emp_code,

  //     });
  //   }
  // }

  // const determineShift = () => {
  //   const datetime = new Date();
  //   if (datetime.getHours() < 18 && datetime.getMinutes() < 59) {
  //     return "A12";
  //   } else {
  //     return "B12";
  //   }
  // };

  // barcode scannning to get input
  useScanDetection({
    onComplete: (code) => {
      code = code.replaceAll("Shift", "");
      setField(code);
    },
    minLength: 3,
  });
  useEffect(()=>{
    setField("IMM-08");
  },[])

  // set value to the appropiate field
  function setField(code) {
    // let isnumber = Number(code).toString() !== "NaN";
    let current_element = document.activeElement.id;

    if (code.includes("EMP")) {
      if (current_element === "" || current_element === "react-select-6-input") {
        console.log(Load_unload);
        if (MCNo && Load_unload[2]) {
          let employeeMatch = false;
          let matchedItem = null;
          Moldsetter.map((item) => {
            if (item.Emp_code === code) {
              employeeMatch = true;
              matchedItem = item;
              // MCNo && !Load_unload[1] ?
              // setMouldLoadAndUnload({
              //   ...MouldLoadAndUnload,
              //   MoldSetterLoad: code,
              //   MoldSetterLoadLabel: code,
              // }) :
              // setMouldLoadAndUnload({
              //   ...MouldLoadAndUnload,
              //   MoldSetterUnLoad: code,
              //   MoldSetterLoadLabel: code,
              // })
            }
          })
          if (employeeMatch) {
            setMouldLoadAndUnload({
              ...MouldLoadAndUnload,
              MoldSetterLoad: matchedItem.Emp_code,
              MoldSetterLoadLabel: matchedItem.Emp_name,
            })
          } else {
            alert("Not Available")
          }
        } else if (MCNo && !Load_unload[2]) {
          let employeeMatch = false;
          let matchedItem = null;
          Moldsetter.map((item) => {
            if (item.Emp_code === code) {
              employeeMatch = true;
              matchedItem = item;
              // MCNo && !Load_unload[1] ?
              // setMouldLoadAndUnload({
              //   ...MouldLoadAndUnload,
              //   MoldSetterLoad: code,
              //   MoldSetterLoadLabel: code,
              // }) :
              // setMouldLoadAndUnload({
              //   ...MouldLoadAndUnload,
              //   MoldSetterUnLoad: code,
              //   MoldSetterLoadLabel: code,
              // })
            }
          })
          if (employeeMatch) {
            setMouldLoadAndUnload({
              ...MouldLoadAndUnload,
              MoldSetterUnLoad: matchedItem.Emp_code,
              MoldSetterUnLoadLabel: matchedItem.Emp_name,
            })
          } else {
            alert("Not Available")
          }
        }


      } else {
        alert("Invalid Input");
        return false;
      }
    } else
      // let current_element = document.activeElement.id;
      // alert(current_element);
      if (code.includes("RMI")) {
        if (current_element === "" || current_element === "react-select-3-input") {
          MCNo && setMouldLoadAndUnload({
            ...MouldLoadAndUnload,
            RawMaterialNameLabel: code,
          });
        } else {
          alert("Invalid Input");
          return false;
        }
      } else if (code.includes("CUS")) {
        if (current_element === "" || current_element === "react-select-5-input") {

          MCNo && setMouldLoadAndUnload({
            ...MouldLoadAndUnload,
            CustomerNameLoad: code,
            CustomerNameLoadLabel: code,
          });
        } else {
          alert("Invalid Input");
          return false;
        }
      } else if (code.includes("FG")) {
        alert("Invalid Input");
        return false;
      } else if (code.includes("IMM")) {
        if (current_element === "" || current_element === "react-select-2-input") {
          // setMCNo({value:code,label:code});

          // alert(code);
          // setMouldLoadAndUnload({
          //   ...MouldLoadAndUnload,
          //   MCNo: code,
          // });
          // GetSelectValueMachine(code, "Get_machine_from_smp", setDropdown);
          // CheckForLoadData({ value: code });

        } else {
          alert("Invalid Input");
          return false;
        }
      } else if (code.includes("SUP")) {
        alert("Invalid Input");
        return false;
      } else {
        MCNo && GetSelectValuePartLoad(code, "mac_part_dtl_dropdown", setPartDropdown)
        // MCNo && handleDropdownChangeLoad({ value: code });
        // MCNo && GetSelectValue(code, keyword, setName);
      }

  }
  const handleCreate = async (e) => {
    e.preventDefault();
    const isSubmissionSuccessful = true;
    if (
      !MouldLoadAndUnload.PartNameLoad ||
      !MouldLoadAndUnload.CustomerNameLoad ||
      !MCNo ||
      !MouldLoadAndUnload.RawMaterialNameLabel ||
      !MouldLoadAndUnload.MoldSetterLoad ||
      !MouldLoadAndUnload.TechnicanName ||
      !MouldLoadAndUnload.ToolroomName ||
      !MouldLoadAndUnload.ProductionEnggName
    ) {
      setIsCreating(true);
      alert(GeneralConstants.MANDATORY_ALERT);
      if (scrollTop.current) {
        scrollTop.current.scrollIntoView({ behavior: "smooth" });
      }
      return;
    }
    try {
      // const isDuplicate = await CodeGen();

      // if (isDuplicate) {
      //   alert(`${GeneralConstants.ALREADY_EXIST}`);
      //   setMouldLoadAndUnload({ ...MouldLoadAndUnload, ...initialState });
      //   return;
      // }
      const data = {
        data: [
          {
            Part_name_load: MouldLoadAndUnload.PartNameLoad.value,
            Track_date_load: MouldLoadAndUnload.TrackDateLoad,
            Raw_material_name: MouldLoadAndUnload.RawMaterialName.value,
            Shift: MouldLoadAndUnload.Shift,
            MC_no: MCNo.value,
            Customer_name_load: MouldLoadAndUnload.CustomerNameLoad,
            Mold_setter_load: MouldLoadAndUnload.MoldSetterLoad,
            Eye_lift_bely: MouldLoadAndUnload.EyeLiftBely,
            Ejc_rod: MouldLoadAndUnload.EjcRod,
            Cool_value: MouldLoadAndUnload.CoolValue,
            Mold_pre_heat: MouldLoadAndUnload.MoldPreHeat,
            Chk_the_MTC: MouldLoadAndUnload.ChkTheMTC,
            Mld_wtr_out_flw: MouldLoadAndUnload.MldWtrOutFlw,
            Chk_blush_nozle: MouldLoadAndUnload.ChkBlushNozle,
            Chk_locating_ring: MouldLoadAndUnload.ChkLocatingRing,

            Loading_time_from: MouldLoadAndUnload.LoadingTimeFrom,
            Loading_time_to: MouldLoadAndUnload.LoadingTimeTo || currentTime,
            Chk_cool_int_out_condn: MouldLoadAndUnload.ChkCoolIntOutCondn,
            Chk_nozle_size_condn: MouldLoadAndUnload.ChkNozleSizeCondn,
            Butting: MouldLoadAndUnload.Butting,
            Use_set_mde_opn_mold: MouldLoadAndUnload.UseSetMdeOpnMold,
            Ensure_mld_cln_pro: MouldLoadAndUnload.EnsureMldClnPro,
            Mld_sfty_1_pres: MouldLoadAndUnload.MldSfty1Pres,
            Mld_sfty_2_pres: MouldLoadAndUnload.MldSfty2Pres,

            Un_loading_time_from: MouldLoadAndUnload.UnLoadingTimeFrom,
            Un_loading_time_to: MouldLoadAndUnload.UnLoadingTimeTo,
            Part_name_unload: MouldLoadAndUnload.PartNameUnload,
            Track_date_unLoad: MouldLoadAndUnload.TrackDateUnLoad,
            Customer_name_unload: MouldLoadAndUnload.CustomerNameUnload,
            Mold_setter_unLoad: MouldLoadAndUnload.MoldSetterUnLoad,
            Mould_clean: MouldLoadAndUnload.MouldClean,
            Apply_ani_wax_coat: MouldLoadAndUnload.ApplyAniWaxCoat,
            Eye_bolt_lift_belt: MouldLoadAndUnload.EyeBoltLiftBelt,
            Mould_cls_remve_watr_lne: MouldLoadAndUnload.MouldClsRemveWatrLne,
            Remove_watr_use_air: MouldLoadAndUnload.RemoveWatrUseAir,
            Unclamp_mold: MouldLoadAndUnload.UnclampMold,
            Chk_locating_cover: MouldLoadAndUnload.ChkLocatingCover,
            L_per_m: MouldLoadAndUnload.LPerM,

            Mould_location_area: MouldLoadAndUnload.MouldLocationArea,
            Remarks_mold_unmold: MouldLoadAndUnload.Remarks,
            Technican_name: MouldLoadAndUnload.TechnicanName,
            Toolroom_name: MouldLoadAndUnload.ToolroomName,
            Production_engg_name: MouldLoadAndUnload.ProductionEnggName,
            Loading: "1",

            Eye_lift_bely_remarks: MouldLoadAndUnload.EyeLiftBelyRemarks,
            Ejc_rod_remarks: MouldLoadAndUnload.EjcRodRemarks,
            Cool_value_remarks: MouldLoadAndUnload.CoolValueRemarks,
            Mold_pre_heat_remarks: MouldLoadAndUnload.MoldPreHeatRemarks,
            Chk_the_mTC_remarks: MouldLoadAndUnload.ChkTheMTCRemarks,
            Mld_wtr_out_flw_remarks: MouldLoadAndUnload.MldWtrOutFlwRemarks,
            Chk_blush_nozle_remarks: MouldLoadAndUnload.ChkBlushNozleRemarks,
            Chk_locating_ring_remarks:
              MouldLoadAndUnload.ChkLocatingRingRemarks,
            Butting_remarks: MouldLoadAndUnload.ButtingRemarks,
            Chk_nozle_size_condn_remarks:
              MouldLoadAndUnload.ChkNozleSizeCondnRemarks,
            Chk_cool_int_out_condn_remarks:
              MouldLoadAndUnload.ChkCoolIntOutCondnRemarks,
            Use_set_mde_opn_mold_remarks:
              MouldLoadAndUnload.UseSetMdeOpnMoldRemarks,
            Ensure_mld_cln_pro_remarks:
              MouldLoadAndUnload.EnsureMldClnProRemarks,
            Mld_sfty_1_pres_remarks: MouldLoadAndUnload.MldSfty1PresRemarks,
            Mld_sfty_2_pres_remarks: MouldLoadAndUnload.MldSfty2PresRemarks,
            L_per_m_remarks: MouldLoadAndUnload.LPerMRemarks,
            Mould_clean_remarks: MouldLoadAndUnload.MouldCleanRemarks,
            Apply_ani_wax_coat_remarks:
              MouldLoadAndUnload.ApplyAniWaxCoatRemarks,
            Eye_bolt_lift_belt_remarks:
              MouldLoadAndUnload.EyeBoltLiftBeltRemarks,
            Mould_cls_remve_watr_lne_remarks:
              MouldLoadAndUnload.MouldClsRemveWatrLneRemarks,
            Remove_watr_use_air_remarks:
              MouldLoadAndUnload.RemoveWatrUseAirRemarks,
            Unclamp_mold_remarks: MouldLoadAndUnload.UnclampMoldRemarks,
            Chk_locating_cover_remarks:
              MouldLoadAndUnload.ChkLocatingCoverRemarks,

          },
        ],
      };
      data["_keyword_"] = "Mould_loading_unloading";
      data["secretkey"] = SECRET_KEY;

      const response = await getAPIRequest(
        PROCESS_TRANSACTION_URL,
        "POST",
        data
      );
      const response1 = await response.json();

      if (response1.success === "false") {
        alert(GeneralConstants.FAIL_TO_SAVE_MACHINE);
      } 
      // else {
      //   const moldLoadUnload = [
      //     ...MouldLoadAndUnload.Mld_load_unload,
      //     response.data,
      //   ];
      //   setMouldLoadAndUnload({
      //     ...MouldLoadAndUnload,
      //     Mld_load_unload: moldLoadUnload,
      //   });
      // }
      if (isSubmissionSuccessful) {
        alert(`${GeneralConstants.SUBMISSION_SUCCESSFUL}`);
        console.log(response1);
        setMessage({
          NoteFrom: MouldLoadAndUnload.MoldSetterLoad,
          NoteTo: MouldLoadAndUnload.TechnicanName,
          NoteMessage: `The Mould Loading Form is Submited by ${MouldLoadAndUnload.MoldSetterLoad}-${MouldLoadAndUnload.MoldSetterLoadLabel}, For the Machine No - ${MCNo}`,
          NoteRecordId: response1.ROWID,
          TableName: "MldUnLoadAndLoad",
          NoteCreatedBy: localStorage.getItem("empId"),

        });
        setMessage({
          NoteFrom: MouldLoadAndUnload.MoldSetterLoad,
          NoteTo: MouldLoadAndUnload.ToolroomName,
          NoteMessage: `The Mould Loading Form is Submited by ${MouldLoadAndUnload.MoldSetterLoad}-${MouldLoadAndUnload.MoldSetterLoadLabel}, For the Machine No - ${MCNo}`,
          NoteRecordId: response1.ROWID,
          TableName: "MldUnLoadAndLoad",
          NoteCreatedBy: localStorage.getItem("empId"),

        });
        setMessage({
          NoteFrom: MouldLoadAndUnload.MoldSetterLoad,
          NoteTo: MouldLoadAndUnload.ProductionEnggName,
          NoteMessage: `The Mould Loading Form is Submited by ${MouldLoadAndUnload.MoldSetterLoad}-${MouldLoadAndUnload.MoldSetterLoadLabel}, For the Machine No - ${MCNo}`,
          NoteRecordId: response1.ROWID,
          TableName: "MldUnLoadAndLoad",
          NoteCreatedBy: localStorage.getItem("empId"),

        });
        setMouldLoadAndUnload({ ...MouldLoadAndUnload, ...initialState });
        setMCNo("");
        if (scrollTop.current) {
          scrollTop.current.scrollIntoView({ behavior: "smooth" });
        }
        return;
      }
    } catch (error) {
      console.log(error);
      alert(`${GeneralConstants.SUBMISSION_FAILED}`);
      window.location.reload();
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const isSubmissionSuccessful = true;
    if (
      !MouldLoadAndUnload.MoldSetterUnLoad ||
      !MouldLoadAndUnload.PartNameUnload ||
      !MouldLoadAndUnload.CustomerNameUnload
    ) {
      setIsCreating(true);
      alert(GeneralConstants.MANDATORY_ALERT);
      if (scrollTop.current) {
        scrollTop.current.scrollIntoView({ behavior: "smooth" });
      }
      return;
    }
    try {
      // const isDuplicate = await CodeGen();

      // if (isDuplicate) {
      //   alert(`${GeneralConstants.ALREADY_EXIST}`);
      //   setMouldLoadAndUnload({ ...MouldLoadAndUnload, ...initialState });
      //   return;
      // }
      const data = {
        data: [
          {
            Id: MouldLoadAndUnload.Id,
            Un_loading_time_from: MouldLoadAndUnload.UnLoadingTimeFrom,
            Un_loading_time_to: MouldLoadAndUnload.UnLoadingTimeTo || currentTime,
            Part_name_unload: MouldLoadAndUnload.PartNameUnload,
            Track_date_unLoad: MouldLoadAndUnload.TrackDateUnLoad,
            Customer_name_unload: MouldLoadAndUnload.CustomerNameUnload,
            Mold_setter_unLoad: MouldLoadAndUnload.MoldSetterUnLoad,
            Mould_clean: MouldLoadAndUnload.MouldClean,
            Apply_ani_wax_coat: MouldLoadAndUnload.ApplyAniWaxCoat,
            Eye_bolt_lift_belt: MouldLoadAndUnload.EyeBoltLiftBelt,
            Mould_cls_remve_watr_lne: MouldLoadAndUnload.MouldClsRemveWatrLne,
            Remove_watr_use_air: MouldLoadAndUnload.RemoveWatrUseAir,
            Unclamp_mold: MouldLoadAndUnload.UnclampMold,
            Chk_locating_cover: MouldLoadAndUnload.ChkLocatingCover,
            Unloading: "1",
            Mould_clean_remarks: MouldLoadAndUnload.MouldCleanRemarks,
            Apply_ani_wax_coat_remarks:
              MouldLoadAndUnload.ApplyAniWaxCoatRemarks,
            Eye_bolt_lift_belt_remarks:
              MouldLoadAndUnload.EyeBoltLiftBeltRemarks,
            Mould_cls_remve_watr_lne_remarks:
              MouldLoadAndUnload.MouldClsRemveWatrLneRemarks,
            Remove_watr_use_air_remarks:
              MouldLoadAndUnload.RemoveWatrUseAirRemarks,
            Unclamp_mold_remarks: MouldLoadAndUnload.UnclampMoldRemarks,
            Chk_locating_cover_remarks:
              MouldLoadAndUnload.ChkLocatingCoverRemarks,
            Mould_location_area: MouldLoadAndUnload.MouldLocationArea,
            Remarks_mold_unmold: MouldLoadAndUnload.Remarks,
            Technican_name: MouldLoadAndUnload.TechnicanName,
            Toolroom_name: MouldLoadAndUnload.ToolroomName,
            Production_engg_name: MouldLoadAndUnload.ProductionEnggName,

          },
        ],
      };
      data["_keyword_"] = "Mould_loading_unloading";
      data["secretkey"] = SECRET_KEY;

      const response = await getAPIRequest(
        PROCESS_TRANSACTION_UPDATE_URL,
        "POST",
        data
      );

      const response1 = await response.json();
      if (response1.success === "false") {
        alert(GeneralConstants.FAIL_TO_SAVE_MACHINE);
      } else {
        const moldLoadUnload = [
          ...MouldLoadAndUnload.Mld_load_unload,
          response.data,
        ];
        setMouldLoadAndUnload({
          ...MouldLoadAndUnload,
          Mld_load_unload: moldLoadUnload,
        });
      }
      if (isSubmissionSuccessful) {
        alert(`${GeneralConstants.SUBMISSION_SUCCESSFUL}`);
        setMessage({
          NoteFrom: MouldLoadAndUnload.MoldSetterLoad,
          NoteTo: MouldLoadAndUnload.TechnicanName,
          NoteMessage: `The Mould Un-Loading Form is Submited by ${MouldLoadAndUnload.MoldSetterUnLoad}-${MouldLoadAndUnload.MoldSetterUnLoadLabel}, For the Machine No - ${MCNo}`,
          NoteRecordId: MouldLoadAndUnload.Id,
          TableName: "MldUnLoadAndLoad",
          NoteCreatedBy: localStorage.getItem("empId"),

        });
        setMessage({
          NoteFrom: MouldLoadAndUnload.MoldSetterLoad,
          NoteTo: MouldLoadAndUnload.ToolroomName,
          NoteMessage: `The Mould Un-Loading Form is Submited by ${MouldLoadAndUnload.MoldSetterUnLoad}-${MouldLoadAndUnload.MoldSetterUnLoadLabel}, For the Machine No - ${MCNo}`,
          NoteRecordId: MouldLoadAndUnload.Id,
          TableName: "MldUnLoadAndLoad",
          NoteCreatedBy: localStorage.getItem("empId"),

        });
        setMessage({
          NoteFrom: MouldLoadAndUnload.MoldSetterLoad,
          NoteTo: MouldLoadAndUnload.ProductionEnggName,
          NoteMessage: `The Mould Un-Loading Form is Submited by ${MouldLoadAndUnload.MoldSetterUnLoad}-${MouldLoadAndUnload.MoldSetterUnLoadLabel}, For the Machine No - ${MCNo}`,
          NoteRecordId: MouldLoadAndUnload.Id,
          TableName: "MldUnLoadAndLoad",
          NoteCreatedBy: localStorage.getItem("empId"),

        });
        setMouldLoadAndUnload({ ...MouldLoadAndUnload, ...initialState });
        setMCNo("");
        if (scrollTop.current) {
          scrollTop.current.scrollIntoView({ behavior: "smooth" });
        }
        return;
      }
    } catch (error) {
      console.log(error);
      alert(`${GeneralConstants.SUBMISSION_FAILED}`);
      window.location.reload();
    }
  };
  function GetRawMaterial(val) {
    ReadRecord({
      _keyword_: "Get_raw_material_by_part_from_smp",
      part: val,
    }).then((res) => {
      if (res.rows > 0) {
        console.log("respose of raw", res.data);
        setRmDropdown(res.data);
      } else {
        alert("Contact Administrator to add Raw Material for this Selected Part!");
      }
    })
  }
  function GetPartDetails(val) {
    ReadRecord({
      _keyword_: "Get_part_from_smp_by_machine",
      machine: val,
    }).then((res) => {
      if (res.rows > 0) {
        console.log("respose of raw", res.data);
        setPartDropdown(res.data);
      } else {
        alert("Contact Administrator to add Part for this Selected Machine!");
      }
    })
  }
  function CheckForLoadData(e) {
    console.log("CheckForLoadData",e);
    GetPartDetails(e.value);
    GetTableRecord({
      _keyword_: "Mould_loading_unloading_McNo",
      MC_no: e.value,
    }).then((res) => {
      console.log("MC_no",res);
      let time = new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[1].substring(0, 5)


      if (res.row=== '1') {
        console.log(res.data[0]);
        // handleDropdownChangeLoad({value:res.data[0].Part_name_load})
        setMouldLoadAndUnload({
          ...MouldLoadAndUnload,
          Id: res.data[0].Id,
          PartNameLoad: res.data[0].Part_name_load,
          PartNameLoadLabel: res.data[0].Part_name_load_Label,
          TrackDateLoad: res.data[0].Track_date_load,
          RawMaterialNameLabel: res.data[0].Raw_materials,
          RawMaterialName: res.data[0].Raw_material_name,
          Shift: res.data[0].Shift,
          // MCNo: res.data[0].MC_no,
          CustomerNameLoad: res.data[0].Customer_name_load,
          CustomerNameLoadLabel: res.data[0].Customer_name_load_Label,
          MoldSetterLoad: res.data[0].Mold_setter_load,
          MoldSetterLoadLabel: res.data[0].Mold_setter_load_Label,
          EyeLiftBely: res.data[0].Eye_lift_bely,
          EjcRod: res.data[0].Ejc_rod,
          CoolValue: res.data[0].Cool_value,
          MoldPreHeat: res.data[0].Mold_pre_heat,
          ChkTheMTC: res.data[0].Chk_the_MTC,
          MldWtrOutFlw: res.data[0].Mld_wtr_out_flw,
          ChkBlushNozle: res.data[0].Chk_blush_nozle,
          ChkLocatingRing: res.data[0].Chk_locating_ring,

          LoadingTimeFrom: res.data[0].Loading_time_from,
          LoadingTimeTo: res.data[0].Loading_time_to,
          ChkCoolIntOutCondn: res.data[0].Chk_cool_int_out_condn,
          ChkNozleSizeCondn: res.data[0].Chk_nozle_size_condn,
          Butting: res.data[0].Butting,
          UseSetMdeOpnMold: res.data[0].Use_set_mde_opn_mold,
          EnsureMldClnPro: res.data[0].Ensure_mld_cln_pro,
          MldSfty1Pres: res.data[0].Mld_sfty_1_pres,
          MldSfty2Pres: res.data[0].Mld_sfty_2_pres,

          UnLoadingTimeFrom: time,
          UnLoadingTimeTo: res.data[0].Un_loading_time_to,
          PartNameUnload: res.data[0].Part_name_load,
          PartNameUnloadLabel: res.data[0].Part_name_load_Label,
          TrackDateUnLoad: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0],
          CustomerNameUnload: res.data[0].Customer_name_load,
          CustomerNameUnloadLabel: res.data[0].Customer_name_load_Label,
          MoldSetterUnLoad: res.data[0].Mold_setter_unLoad,
          MouldClean: res.data[0].Mould_clean,
          ApplyAniWaxCoat: res.data[0].Apply_ani_wax_coat,
          EyeBoltLiftBelt: res.data[0].Eye_bolt_lift_belt,
          MouldClsRemveWatrLne: res.data[0].Mould_cls_remve_watr_lne,
          RemoveWatrUseAir: res.data[0].Remove_watr_use_air,
          UnclampMold: res.data[0].Unclamp_mold,
          ChkLocatingCover: res.data[0].Chk_locating_cover,
          LPerM: res.data[0].L_per_m,

          MouldLocationArea: res.data[0].Mould_location_area,
          Remarks: res.data[0].Remarks_mold_unmold,
          TechnicanName: res.data[0].Technican_name,
          TechnicanNameLabel: res.data[0].Technican_name_Label,
          ToolroomName: res.data[0].Toolroom_name,
          ToolroomNameLabel: res.data[0].Toolroom_name_Label,
          ProductionEnggName: res.data[0].Production_engg_name,
          ProductionEnggNameLabel: res.data[0].Production_engg_name_Label,

          EyeLiftBelyRemarks: res.data[0].Eye_lift_bely_remarks,
          EjcRodRemarks: res.data[0].Ejc_rod_remarks,
          CoolValueRemarks: res.data[0].Cool_value_remarks,
          MoldPreHeatRemarks: res.data[0].Mold_pre_heat_remarks,
          ChkTheMTCRemarks: res.data[0].Chk_the_mTC_remarks,
          MldWtrOutFlwRemarks: res.data[0].Mld_wtr_out_flw_remarks,
          ChkBlushNozleRemarks: res.data[0].Chk_blush_nozle_remarks,
          ChkLocatingRingRemarks: res.data[0].Chk_locating_ring_remarks,
          ButtingRemarks: res.data[0].Butting_remarks,
          ChkNozleSizeCondnRemarks: res.data[0].Chk_nozle_size_condn_remarks,
          ChkCoolIntOutCondnRemarks: res.data[0].Chk_cool_int_out_condn_remarks,
          UseSetMdeOpnMoldRemarks: res.data[0].Use_set_mde_opn_mold_remarks,
          EnsureMldClnProRemarks: res.data[0].Ensure_mld_cln_pro_remarks,
          MldSfty1PresRemarks: res.data[0].Mld_sfty_1_pres_remarks,
          MldSfty2PresRemarks: res.data[0].Mld_sfty_2_pres_remarks,
          LPerMRemarks: res.data[0].L_per_m_remarks,
          MouldCleanRemarks: res.data[0].Mould_clean_remarks,
          ApplyAniWaxCoatRemarks: res.data[0].Apply_ani_wax_coat_remarks,
          EyeBoltLiftBeltRemarks: res.data[0].Eye_bolt_lift_belt_remarks,
          MouldClsRemveWatrLneRemarks: res.data[0].Mould_cls_remve_watr_lne_remarks,
          RemoveWatrUseAirRemarks: res.data[0].Remove_watr_use_air_remarks,
          UnclampMoldRemarks: res.data[0].Unclamp_mold_remarks,
          ChkLocatingCoverRemarks: res.data[0].Chk_locating_cover_remarks,
        });
        setLoad_unload([true, true, false, false])
        // setMCNo(res.data[0].MC_no);
      }
      else {
        // setMCNo(e.value);
        setMouldLoadAndUnload({
          ...MouldLoadAndUnload,
          Partnameload: "",
          PartNameLoadLabel: "",
          TrackDateLoad: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0],
          RawMaterialName: "",
          Shift: time < "17:59" && time > "05:59" ? "A" : "B",
          // MCNo: e,
          CustomerNameLoad: "",
          CustomerNameLoadLabel: "",
          MoldSetterLoad: "",
          MoldSetterLoadLabel: "",
          EyeLiftBely: "",
          EjcRod: "",
          CoolValue: "",
          MoldPreHeat: "",
          ChkTheMTC: "",
          MldWtrOutFlw: "",
          ChkBlushNozle: "",
          ChkLocatingRing: "",

          LoadingTimeFrom: "",
          LoadingTimeTo: "",
          ChkCoolIntOutCondn: "",
          ChkNozleSizeCondn: "",
          Butting: "",
          UseSetMdeOpnMold: "",
          EnsureMldClnPro: "",
          MldSfty1Pres: "",
          MldSfty2Pres: "",

          UnLoadingTimeFrom: "",
          UnLoadingTimeTo: "",
          PartNameUnload: "",
          PartNameUnloadLabel: "",
          TrackDateUnLoad: '',
          CustomerNameUnload: "",
          CustomerNameUnloadLabel: "",
          MoldSetterUnLoad: "",
          MouldClean: "",
          ApplyAniWaxCoat: "",
          EyeBoltLiftBelt: "",
          MouldClsRemveWatrLne: "",
          RemoveWatrUseAir: "",
          UnclampMold: "",
          ChkLocatingCover: "",
          LPerM: "",

          MouldLocationArea: "",
          Remarks: "",
          TechnicanName: "",
          TechnicanNameLabel: "",
          ToolroomName: "",
          ToolroomNameLabel: "",
          ProductionEnggName: "",
          ProductionEnggNameLabel: "",
          RawMaterialNameLabel: "",
          EyeLiftBelyRemarks: "",
          EjcRodRemarks: "",
          CoolValueRemarks: "",
          MoldPreHeatRemarks: "",
          ChkTheMTCRemarks: "",
          MldWtrOutFlwRemarks: "",
          ChkBlushNozleRemarks: "",
          ChkLocatingRingRemarks: "",
          ButtingRemarks: "",
          ChkNozleSizeCondnRemarks: "",
          ChkCoolIntOutCondnRemarks: "",
          UseSetMdeOpnMoldRemarks: "",
          EnsureMldClnProRemarks: "",
          MldSfty1PresRemarks: "",
          MldSfty2PresRemarks: "",
          LPerMRemarks: "",
          MouldCleanRemarks: "",
          ApplyAniWaxCoatRemarks: "",
          EyeBoltLiftBeltRemarks: "",
          MouldClsRemveWatrLneRemarks: "",
          RemoveWatrUseAirRemarks: "",
          UnclampMoldRemarks: "",
          ChkLocatingCoverRemarks: "",
        });
        setLoad_unload([false, false, true, false])
      }
    });
  }
  useEffect(() => {
    document.title = "Mould Loading and Unloading"
  }, []);
  // useEffect(() => {
  //   console.log("MouldLoadAndUnload", MouldLoadAndUnload);
  // }, [MouldLoadAndUnload]);
  function GetSelectValue(value, keyword, setName) {
    if (value.length > 0) {
      ReadRecord({
        _keyword_: keyword,
        key: "%" + value + "%",
        value: "%" + value + "%"
      }).then((res) => {
        console.log(res.data);
        setName(res.data);
        // setKey(keyvalue[options.reference]);
      });
    }
  }
  function GetSelectValueMachine(val, keyword, setName) {
    if (val.length > 0) {
      ReadRecord({
        _keyword_: keyword,
        key: "%" + val + "%",
        value: "%" + val + "%"
      }).then((res) => {
        console.log(res.data);
        setName(res.data);
        // setKey(keyvalue[options.reference]);
        // handleMachine({value: val})
      });
    }
  }
  function GetSelectValuePartLoad(value, keyword, setName) {

    if (value.length > 0) {
      ReadRecord({
        _keyword_: keyword,
        key: "%" + value + "%",
        value: "%" + value + "%"
      }).then((res) => {
        if (res.rows > 0) {
          console.log(res.data);
          setName(res.data);
          setMouldLoadAndUnload({
            ...MouldLoadAndUnload,
            PartNameLoad: res.data[0].mc_part_code,
            PartNameLoadLabel: res.data[0].mc_part_name,
            // TrackDateLoad: currentDateTime.toISOString().split("T")[0],
            // Shift: determineShift(),
            LoadingTimeFrom: currentTime,
          });
        } else {
          alert("Not Available")
        }
      });

    }
  }
  function GetSelectValuePartUnload(value, keyword, setName) {

    if (value.length > 0) {
      ReadRecord({
        _keyword_: keyword,
        key: "%" + value + "%",
        value: "%" + value + "%"
      }).then((res) => {
        if (res.rows > 0) {
          console.log(res.data);
          setName(res.data);
          setMouldLoadAndUnload({
            ...MouldLoadAndUnload,
            PartNameUnload: res.data[0].mc_part_code,
            PartNameUnloadLabel: res.data[0].mc_part_name,
            TrackDateUnLoad: currentDateTime.toISOString().split("T")[0],
            UnLoadingTimeFrom: currentTime,
          });
        }
        else {
          alert("Not Available")
        }
        // setKey(keyvalue[options.reference]);
      });
    }
  }
  const handleMachine = (selectedOption) => {
    console.log("selectedOption",selectedOption);
    // GetRawMaterial(selectedOption.value);
    // GetRawMaterial(selectedOption.value);
    // GetPartDetails(selectedOption.value);
    const selected = dropdown.find(
      (option) => option.mh_mac_name === selectedOption.value
    );
    console.log("selectedOption", selected);

    if (selected) {
      setMCNo({ value: selected.mh_mac_name, label: selected.mh_mac_name });           
    }

  };
  const HandlePartLoad = (selectedOption) => {
    console.log("selectedOption",selectedOption);
    GetRawMaterial(selectedOption.label);
    const selected = partDropdown.find(
      (option) => option.mc_part_code === selectedOption.value
    );
    console.log("selectedOption", selected);

    if (selected) {
      setMouldLoadAndUnload({
        ...MouldLoadAndUnload,
        PartNameLoad: { value: selected.mc_part_code },
        PartNameLoadLabel: selected.mc_part_name,
        // TrackDateLoad: currentDateTime.toISOString().split("T")[0],
        // Shift: determineShift(),
        LoadingTimeFrom: currentTime,
      })
      // setMouldLoadAndUnload({
      //   ...MouldLoadAndUnload,
      //   MCNo: { value: selected.mh_mac_name, label: selected.mh_mac_name }
      // });
      // setMachineNo({ value: selected.mh_mac_name, label: selected.mh_mac_name });           
    }

  };
  function HandleRawMaterial(selectedOption) {
    const selected = Rmdropdown.find(
      (option) => option.Rm_code === selectedOption.value
    );
    console.log("selectedOption", selected);

    if (selected) {
      setMouldLoadAndUnload({
        ...MouldLoadAndUnload,
        RawMaterialName: {value:selected.Rm_code},
        RawMaterialNameLabel: selected.Rm_description,
      })
    }
  }
  return (
    <div>
      <form
        id="MldUnloadLoad-formContainer-Create"
        name="MldUnloadLoad-formContainer-Create"
        className="form-MldUnloadLoad-Create"
      >
        <div ref={scrollTop}>
          <table style={{ textAlign: "center", width: "100%" }}>
            <tbody>
              {/* <tr>
                <th style={{ textAlign: "center", width: "15%" }}>
                  <img src={Unitechlogo} style={{ width: "80px" }}></img>
                </th> */}

              {/*
                  <font style={{ fontSize: "24px" }}>
                    UNITECH PLASTO COMPONENTS (P) LTD
                  </font>
                  <br></br>
                  <font color="#8B0000">
                    Mould Loading & Un-Loading CheckList
                  </font>
                </th>
              </tr> */}
              <tr>
                <th
                  id={`${id}-Title`}
                  name={`${name}-Title`}
                  colSpan="6"
                  style={{
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    padding: "10px 0px",
                  }}
                >
                  {/* <th style={{ textAlign: "center" }} colSpan={5}> */}
                  {/* <img src={Unitechlogo} style={{ width: "70px" }}></img>
              </th>
              <th
                id={`${id}-Title`}
                name={`${name}-Title`}
                colSpan="4"
                style={{
                  textAlign: "center",
                  whiteSpace: "nowrap",
                  padding: "10px 0px",
                }}
              > */}
                  {/* <font style={{ fontSize: "24px" }}>
                  UNITECH PLASTO COMPONENTS (P) LTD
                </font>
                <br></br> */}
                  <font color="#8B0000">Mould Loading & Un-Loading CheckList</font>
                </th>
              </tr>

              <tr>
                <td className="topic-head">
                  M/C No<span style={{ color: "red" }}>*</span>
                </td>
                <td>
                  {/* <input
                  id={`${id}-MCNo`}
                  name={`${name}-MCNo`}
                  type="text"
                  value={MCNo}
                  onChange={(e) =>
                    setMouldLoadAndUnload({
                      ...MouldLoadAndUnload,
                      MCNo: e.target.value,
                    })
                  }
                /> */}

                  {/* <DropdownFetcher
                    id="machine_no"
                    keyword="Get_machine_from_smp"
                    setValue={setDropdown}
                  />
                  {MCNo === '' && isCreating && <span className="Req">Required *</span>}
                  <DropdownFilterSelect
                    value={MCNo}
                    onChange={(e) => {
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        MCNo: e.value,
                      });
                      CheckForLoadData(e);
                    }}
                    options={mcNoOptions}
                    placeholder="Select Machine No"
                  /> */}
                  <Select
                    id="Machine"
                    name="Machine"
                    value={MCNo}
                    onChange={(e) => { CheckForLoadData(e); handleMachine(e); }}                     
                    options={mcNoOptions}
                    onInputChange={(event) => { GetSelectValue(event, "Get_machine_from_smp", setDropdown) }}
                    placeholder="Search Machine No..."
                  />
                  {/* <label>{MCNo.value}</label> */}
                </td>

                <td className="topic-head">Date</td>
                <td colSpan={3}>

                  <input
                    type="date"
                    disabled
                    value={MouldLoadAndUnload.TrackDateLoad}
                    onChange={handleDateChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">
                  Part Name<span style={{ color: "red" }}>*</span>
                </td>
                <td style={{ width: "25%" }}>
                  {/* <input
                id={`${id}-PartNameLoad`}
                name={`${name}-PartNameLoad`}
                  type="text"
                  value={MouldLoadAndUnload.PartNameLoad}
                  onChange={(e) => setPartNameLoad(e.target.value)}
                  
                /> */}
                  {/* <DropdownFetcher keyword="mac_part_dtl_dropdown" setValue={setPartDropdown} /> */}
                  {/* <DropdownFilterSelect dropdown={partDropdown} optionLabel="mc_part_name" optionValueCode="mc_part_code" optionIdProp="Part name" setValue={setPartNameLoad} values={PartNameLoad}/> */}
                  {/* <DropdownSelect text="Select Part Name"  dropdown={partDropdown} value={MouldLoadAndUnload.PartNameLoad} optionIdProp="mc_part_code" optionNameProp="mc_part_name" onChange={(e) => setPartNameLoad(e.target.value)} /> */}
                  {/* <DropdownFetcher
                    keyword="mac_part_dtl_dropdown"
                    setValue={setPartDropdown}
                  /> */}
                  {MouldLoadAndUnload.PartNameLoadLabel === '' && isCreating && <span className="Req">Required *</span>}
                  <DropdownFilterSelect
                    id={`${id}-PartNameLoad`}
                    name={`${name}-PartNameLoad`}
                    value={MouldLoadAndUnload.PartNameLoadLabel}
                    onChange={HandlePartLoad}
                    isDisabled={MCNo === "" || Load_unload[0]}
                    options={partNoOptions}
                    placeholder="Search..."
                  // onInputChange={(event) => GetSelectValuePartLoad(event, "mac_part_dtl_dropdown", setPartDropdown)}

                  />
                </td>


                <td className="topic-head">Shift</td>
                <td colSpan={3}>
                  {/* <input
                  id={`${id}-Shift`}
                  name={`${name}-Shift`}
                  type="text"
                  value={MouldLoadAndUnload.Shift}
                  onChange={(e) =>
                    setMouldLoadAndUnload({
                      ...MouldLoadAndUnload,
                      Shift: e.target.value,
                    })
                  }
                /> */}
                  <ShiftDropdownown
                    id={id}
                    name={name}
                    disabled1={true}
                    value={MouldLoadAndUnload.Shift}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        Shift: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">
                  Raw Material Name<span style={{ color: "red" }}>*</span>
                </td>
                <td>
                  {/* <input
                  id={`${id}-RawMaterialName`}
                  name={`${name}-RawMaterialName`}
                  type="text"
                  value={MouldLoadAndUnload.RawMaterialName}
                  onChange={(e) =>
                    setMouldLoadAndUnload({
                      ...MouldLoadAndUnload,
                      RawMaterialName: e.target.value,
                    })
                  }
                /> */}
                  {/* <DropdownFetcher
                    keyword="rm_details_dropdown"
                    setValue={setRmDropdown}
                  /> */}
                  {MouldLoadAndUnload.RawMaterialName === '' && isCreating && <span className="Req">Required *</span>}
                  <DropdownFilterSelect
                    value={MouldLoadAndUnload.RawMaterialNameLabel}
                    isDisabled={MCNo === "" || Load_unload[0] || MouldLoadAndUnload.PartNameLoadLabel===""}
                    onChange={(e) => HandleRawMaterial(e)}
                    options={rmOptions}
                    placeholder="Search RM..."
                  // onInputChange={(event) => { GetSelectValue(event, "rm_details_dropdown", setRmDropdown) }}
                  />
                </td>


                <td className="topic-head">
                  Customer Name<span style={{ color: "red" }}>*</span>
                </td>
                <td colSpan={3}>
                  <DropdownFetcher
                    keyword="customer_details"
                    setValue={setCustomerDropdown}
                  />
                  {MouldLoadAndUnload.CustomerNameLoadLabel === '' && isCreating && <span className="Req">Required *</span>}
                  <DropdownFilterSelect
                    value={MouldLoadAndUnload.CustomerNameLoadLabel}
                    isDisabled={MCNo === "" || Load_unload[0]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        CustomerNameLoad: e.value,
                        CustomerNameLoadLabel: e.label,
                      })
                    }
                    options={customerOptions}
                    placeholder="Select Customer Name"
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head" colSpan={2}>
                  Mould Setter Name<span style={{ color: "red" }}>*</span>
                </td>
                <td colSpan={5}>
                  <DropdownFetcher
                    keyword="emp_details_moldsetter"
                    setValue={setMoldsetterDropdown}
                  />
                  {MouldLoadAndUnload.MoldSetterLoadLabel === '' && isCreating && <span className="Req">Required *</span>}
                  <DropdownFilterSelect
                    id={`${id}-MoldSetterLoad`}
                    name={`${name}-MoldSetterLoad`}
                    isDisabled={MCNo === "" || Load_unload[0]}
                    value={MouldLoadAndUnload.MoldSetterLoadLabel}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        MoldSetterLoad: e.value,
                        MoldSetterLoadLabel: e.label,
                      })
                    }
                    options={MoldsetterOptions}
                    placeholder="Select Mould Setter Name"
                  />
                  {/* <Select
  id={`${id}-MoldSetterLoad`}
  name={`${name}-MoldSetterLoad`}
  value={getSelectedValue(empAllOptions, MouldLoadAndUnload.MoldSetterLoad)}
  onChange={(selectedOption) => handleSelectChange(selectedOption, 'MoldSetterLoad')}
  options={empAllOptions}
  placeholder="Select Mould Setter Name"
/> */}
                  {/* <Select
  id={`${id}-MoldSetterLoad`}
  name={`${name}-MoldSetterLoad`}
  value={
    MouldLoadAndUnload.MoldSetterLoad
      ? {
          value: MouldLoadAndUnload.MoldSetterLoad,
          label: MouldLoadAndUnload.MoldSetterLoad,          
        }
      : null
  }
  onChange={(selectedOption) =>
    setMouldLoadAndUnload({
      ...MouldLoadAndUnload,
      MoldSetterLoad: selectedOption ? selectedOption.value : null,
    })
  }
  options={empAllOptions}
  placeholder="Select Mould Setter Name"
/> */}

                  {/* <input
                  id={`${id}-MoldSetterLoad`}
                  name={`${name}-MoldSetterLoad`}
                  type="text"
                  value={MouldLoadAndUnload.MoldSetterLoad}
                  onChange={(e) =>
                    setMouldLoadAndUnload({
                      ...MouldLoadAndUnload,
                      MoldSetterLoad: e.target.value,
                    })
                  }
                /> */}
                </td>
              </tr>
              <tr>
                <th
                  id={`${id}-TitleStandByActivity`}
                  name={`${name}-TitleStandByActivity`}
                  colSpan={5}
                >
                  STAND BY ACTIVITY
                </th>
              </tr>
              <tr>
                <th>S.No</th>
                <th>CheckPoints</th>
                <th>Conditions</th>
                <th colSpan={2}>Remarks</th>
              </tr>
              <tr>
                <td className="topic-head-number">1</td>
                <td className="topic-head">
                  Eye Bolt & Lifting Bar & Belt Conditions
                </td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-EyeLiftBely`}
                    name={`${name}-EyeLiftBely`}
                    Value={MouldLoadAndUnload.EyeLiftBely}
                    isDisabled={Load_unload[0]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        EyeLiftBely: e.target.value,
                      })
                    }
                  />
                </td>
                <td colSpan={2}>
                  <textarea
                    id={`${id}-EyeLiftBelyRemarks`}
                    name={`${name}-EyeLiftBelyRemarks`}
                    type="text"
                    value={MouldLoadAndUnload.EyeLiftBelyRemarks}
                    disabled={Load_unload[0]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        EyeLiftBelyRemarks: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">2</td>
                <td className="topic-head">Check The Ejection Rod</td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-EjcRod`}
                    name={`${name}-EjcRod`}
                    Value={MouldLoadAndUnload.EjcRod}
                    isDisabled={Load_unload[0]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        EjcRod: e.target.value,
                      })
                    }
                  />
                </td>
                <td colSpan={2}>
                  <textarea
                    id={`${id}-EjcRodRemarks`}
                    name={`${name}-EjcRodRemarks`}
                    type="text"
                    value={MouldLoadAndUnload.EjcRodRemarks}
                    disabled={Load_unload[0]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        EjcRodRemarks: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">3</td>
                <td className="topic-head">
                  Check The Cooling Nipples & Value Condition
                </td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-CoolValue`}
                    name={`${name}-CoolValue`}
                    Value={MouldLoadAndUnload.CoolValue}
                    isDisabled={Load_unload[0]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        CoolValue: e.target.value,
                      })
                    }
                  />
                </td>
                <td colSpan={2}>
                  <textarea
                    id={`${id}-CoolValueRemarks`}
                    name={`${name}-CoolValueRemarks`}
                    type="text"
                    value={MouldLoadAndUnload.CoolValueRemarks}
                    disabled={Load_unload[0]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        CoolValueRemarks: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">4</td>
                <td className="topic-head">Mold Preheat (If Required)</td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-MoldPreHeat`}
                    name={`${name}-MoldPreHeat`}
                    Value={MouldLoadAndUnload.MoldPreHeat}
                    isDisabled={Load_unload[0]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        MoldPreHeat: e.target.value,
                      })
                    }
                  />
                </td>
                <td colSpan={2}>
                  <textarea
                    id={`${id}-MoldPreHeatRemarks`}
                    name={`${name}-MoldPreHeatRemarks`}
                    type="text"
                    value={MouldLoadAndUnload.MoldPreHeatRemarks}
                    disabled={Load_unload[0]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        MoldPreHeatRemarks: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">5</td>
                <td className="topic-head">Check The MTC Temperature </td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-ChkTheMTC`}
                    name={`${name}-ChkTheMTC`}
                    Value={MouldLoadAndUnload.ChkTheMTC}
                    isDisabled={Load_unload[0]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        ChkTheMTC: e.target.value,
                      })
                    }
                  />
                </td>
                <td colSpan={2}>
                  <textarea
                    id={`${id}-ChkTheMTCRemarks`}
                    name={`${name}-ChkTheMTCRemarks`}
                    type="text"
                    value={MouldLoadAndUnload.ChkTheMTCRemarks}
                    disabled={Load_unload[0]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        ChkTheMTCRemarks: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">6</td>
                <td className="topic-head">
                  Check The Mould Water Outlet Flow Rate
                </td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-MldWtrOutFlw`}
                    name={`${name}-MldWtrOutFlw`}
                    Value={MouldLoadAndUnload.MldWtrOutFlw}
                    isDisabled={Load_unload[0]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        MldWtrOutFlw: e.target.value,
                      })
                    }
                  />
                </td>
                <td colSpan={2}>
                  <textarea
                    id={`${id}-MldWtrOutFlwRemarks`}
                    name={`${name}-MldWtrOutFlwRemarks`}
                    type="text"
                    value={MouldLoadAndUnload.MldWtrOutFlwRemarks}
                    disabled={Load_unload[0]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        MldWtrOutFlwRemarks: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">7</td>
                <td className="topic-head">
                  Check Spure Blush & Nozzle Holes Dia
                </td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-ChkBlushNozle`}
                    name={`${name}-ChkBlushNozle`}
                    Value={MouldLoadAndUnload.ChkBlushNozle}
                    isDisabled={Load_unload[0]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        ChkBlushNozle: e.target.value,
                      })
                    }
                  />
                </td>
                <td colSpan={2}>
                  <textarea
                    id={`${id}-ChkBlushNozleRemarks`}
                    name={`${name}-ChkBlushNozleRemarks`}
                    type="text"
                    disabled={Load_unload[0]}
                    value={MouldLoadAndUnload.ChkBlushNozleRemarks}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        ChkBlushNozleRemarks: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">8</td>
                <td className="topic-head">
                  Check The Locating Ring (As Per STD)
                </td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-ChkLocatingRing`}
                    name={`${name}-ChkLocatingRing`}
                    Value={MouldLoadAndUnload.ChkLocatingRing}
                    isDisabled={Load_unload[0]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        ChkLocatingRing: e.target.value,
                      })
                    }
                  />
                </td>
                <td colSpan={2}>
                  <textarea
                    id={`${id}-ChkLocatingRingRemarks`}
                    name={`${name}-ChkLocatingRingRemarks`}
                    type="text"
                    value={MouldLoadAndUnload.ChkLocatingRingRemarks}
                    disabled={Load_unload[0]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        ChkLocatingRingRemarks: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <th
                  id={`${id}-MouldLoading-Activity-Title`}
                  name={`${name}-MouldLoading-Activity-Title`}
                  colSpan={5}
                >
                  MOULD LOADING ACTIVITY
                </th>
              </tr>
              <tr>
                <th colSpan={2}>LOADING TIME:</th>
                <td>
                  <input
                    id={`${id}-LoadingTimeFrom`}
                    name={`${name}-LoadingTimeFrom`}
                    type="time"
                    value={MouldLoadAndUnload.LoadingTimeFrom}
                    disabled={Load_unload[1]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        LoadingTimeFrom: e.target.value,
                      })
                    }
                  />
                </td>
                <th>TO</th>
                <td>
                  <input
                    id={`${id}-LoadingTimeTo`}
                    name={`${name}-LoadingTimeTo`}
                    type="time"
                    value={MouldLoadAndUnload.LoadingTimeTo}
                    disabled={Load_unload[1]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        LoadingTimeTo: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>S.No</th>
                <th>CheckPoints</th>
                <th>Conditions</th>
                <th colSpan={2}>Remarks</th>
              </tr>
              <tr>
                <td className="topic-head-number">1</td>
                <td className="topic-head">Butting</td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-Butting`}
                    name={`${name}-Butting`}
                    Value={MouldLoadAndUnload.Butting}
                    isDisabled={Load_unload[1]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        Butting: e.target.value,
                      })
                    }
                  />
                </td>
                <td colSpan={2}>
                  <textarea
                    id={`${id}-ButtingRemarks`}
                    name={`${name}-ButtingRemarks`}
                    type="text"
                    value={MouldLoadAndUnload.ButtingRemarks}
                    disabled={Load_unload[1]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        ButtingRemarks: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">2</td>
                <td className="topic-head">Check Nozzle Size And Condition</td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-ChkNozleSizeCondn`}
                    name={`${name}-ChkNozleSizeCondn`}
                    Value={MouldLoadAndUnload.ChkNozleSizeCondn}
                    isDisabled={Load_unload[1]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        ChkNozleSizeCondn: e.target.value,
                      })
                    }
                  />
                </td>
                <td colSpan={2}>
                  <textarea
                    id={`${id}-ChkNozleSizeCondnRemarks`}
                    name={`${name}-ChkNozleSizeCondnRemarks`}
                    type="text"
                    value={MouldLoadAndUnload.ChkNozleSizeCondnRemarks}
                    disabled={Load_unload[1]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        ChkNozleSizeCondnRemarks: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">3</td>
                <td className="topic-head">
                  Check The Cooling Inlet/Outlet Condition
                </td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-ChkCoolIntOutCondn`}
                    name={`${name}-ChkCoolIntOutCondn`}
                    Value={MouldLoadAndUnload.ChkCoolIntOutCondn}
                    isDisabled={Load_unload[1]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        ChkCoolIntOutCondn: e.target.value,
                      })
                    }
                  />
                </td>
                <td colSpan={2}>
                  <textarea
                    id={`${id}-ChkCoolIntOutCondnRemarks`}
                    name={`${name}-ChkCoolIntOutCondnRemarks`}
                    type="text"
                    value={MouldLoadAndUnload.ChkCoolIntOutCondnRemarks}
                    disabled={Load_unload[1]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        ChkCoolIntOutCondnRemarks: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">4</td>
                <td className="topic-head">Use Setting Mode Open The Mold</td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-UseSetMdeOpnMold`}
                    name={`${name}-UseSetMdeOpnMold`}
                    Value={MouldLoadAndUnload.UseSetMdeOpnMold}
                    isDisabled={Load_unload[1]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        UseSetMdeOpnMold: e.target.value,
                      })
                    }
                  />
                </td>
                <td colSpan={2}>
                  <textarea
                    id={`${id}-UseSetMdeOpnMoldRemarks`}
                    name={`${name}-UseSetMdeOpnMoldRemarks`}
                    type="text"
                    value={MouldLoadAndUnload.UseSetMdeOpnMoldRemarks}
                    disabled={Load_unload[1]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        UseSetMdeOpnMoldRemarks: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">5</td>
                <td className="topic-head">Ensure Mold Cleaning Process</td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-EnsureMldClnPro`}
                    name={`${name}-EnsureMldClnPro`}
                    Value={MouldLoadAndUnload.EnsureMldClnPro}
                    isDisabled={Load_unload[1]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        EnsureMldClnPro: e.target.value,
                      })
                    }
                  />
                </td>
                <td colSpan={2}>
                  <textarea
                    id={`${id}-EnsureMldClnProRemarks`}
                    name={`${name}-EnsureMldClnProRemarks`}
                    type="text"
                    value={MouldLoadAndUnload.EnsureMldClnProRemarks}
                    disabled={Load_unload[1]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        EnsureMldClnProRemarks: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">6</td>
                <td className="topic-head">Mold Safety 1 Pressure</td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-MldSfty1Pres`}
                    name={`${name}-MldSfty1Pres`}
                    Value={MouldLoadAndUnload.MldSfty1Pres}
                    isDisabled={Load_unload[1]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        MldSfty1Pres: e.target.value,
                      })
                    }
                  />
                </td>
                <td colSpan={2}>
                  <textarea
                    id={`${id}-MldSfty1PresRemarks`}
                    name={`${name}-MldSfty1PresRemarks`}
                    type="text"
                    value={MouldLoadAndUnload.MldSfty1PresRemarks}
                    disabled={Load_unload[1]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        MldSfty1PresRemarks: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">7</td>
                <td className="topic-head">Mold Safety 2 Pressure</td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-MldSfty2Pres`}
                    name={`${name}-MldSfty2Pres`}
                    Value={MouldLoadAndUnload.MldSfty2Pres}
                    isDisabled={Load_unload[1]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        MldSfty2Pres: e.target.value,
                      })
                    }
                  />
                </td>
                <td colSpan={2}>
                  <textarea
                    id={`${id}-MldSfty2PresRemarks`}
                    name={`${name}-MldSfty2PresRemarks`}
                    type="text"
                    value={MouldLoadAndUnload.MldSfty2PresRemarks}
                    disabled={Load_unload[1]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        MldSfty2PresRemarks: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">8</td>
                <td className="topic-head">LPM</td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-LPerM`}
                    name={`${name}-LPerM`}
                    Value={MouldLoadAndUnload.LPerM}
                    isDisabled={Load_unload[1]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        LPerM: e.target.value,
                      })
                    }
                  />
                </td>
                <td colSpan={2}>
                  <textarea
                    id={`${id}-LPerMRemarks`}
                    name={`${name}-LPerMRemarks`}
                    type="text"
                    value={MouldLoadAndUnload.LPerMRemarks}
                    disabled={Load_unload[1]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        LPerMRemarks: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <th
                  id={`${id}-MouldUnloadingActivityTitle`}
                  name={`${name}-MouldUnloadingActivityTitle`}
                  colSpan={5}
                >
                  MOULD UNLOADING ACTIVITY
                </th>
              </tr>
              <tr>
                <th colSpan={2}>UN LOADING TIME:</th>
                <td>
                  <input
                    id={`${id}-UnLoadingTimeFrom`}
                    name={`${name}-UnLoadingTimeFrom`}
                    type="time"
                    value={MouldLoadAndUnload.UnLoadingTimeFrom}
                    disabled={Load_unload[2]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        UnLoadingTimeFrom: e.target.value,
                      })
                    }
                  />
                </td>
                <th>TO</th>
                <td>
                  <input
                    id={`${id}-UnLoadingTimeTo`}
                    name={`${name}-UnLoadingTimeTo`}
                    type="time"
                    value={MouldLoadAndUnload.UnLoadingTimeTo}
                    disabled={Load_unload[2]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        UnLoadingTimeTo: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">Part Name</td>
                <td>
                  {/* <input
                  id={`${id}-PartNameUnload`}
                  name={`${name}-PartNameUnload`}
                  type="text"
                  value={MouldLoadAndUnload.PartNameUnload}
                  onChange={(e) =>
                    setMouldLoadAndUnload({
                      ...MouldLoadAndUnload,
                      PartNameUnload: e.target.value,
                    })
                  }                 /> */}
                  {/* <DropdownFetcher
                  keyword="mac_part_dtl_dropdown"
                  setValue={setPartDropdown}
                /> */}

                  <DropdownFilterSelect
                    isDisabled={true}
                    value={MouldLoadAndUnload.PartNameUnloadLabel}
                    // onChange={handleDropdownChangeUnLoad}
                    onInputChange={(event) => GetSelectValuePartUnload(event, "mac_part_dtl_dropdown", setPartDropdown)}
                    options={partNoOptions}
                    placeholder="Select Part Name"
                  />
                </td>
                <td className="topic-head">Date</td>
                <td colSpan={2}>
                  {/* <DatePicker
                    value={MouldLoadAndUnload.TrackDateUnLoad}
                    
                    onChange={handleDateChange1}
                  /> */}
                  <input
                    type="date"
                    value={MouldLoadAndUnload.TrackDateUnLoad}
                    disabled={Load_unload[2]}
                    onChange={handleDateChange1}
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">Customer</td>
                <td>
                  <DropdownFilterSelect
                    value={MouldLoadAndUnload.CustomerNameUnloadLabel}
                    isDisabled={true}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        CustomerNameUnload: e.value,
                        CustomerNameUnloadLabel: e.label,
                      })
                    }
                    options={customerOptions}
                    placeholder="Select Customer Name"
                  />
                </td>

                <td className="topic-head">Mould Setter Name{!Load_unload[2] && <span style={{ color: "red" }}>*</span>}</td>
                <td colSpan={2}>
                  {MouldLoadAndUnload.MoldSetterUnLoadLabel === '' && !Load_unload[2] && isCreating && <span className="Req">Required *</span>}
                  <DropdownFilterSelect
                    id={`${id}-MoldSetterLoad`}
                    name={`${name}-MoldSetterLoad`}
                    isDisabled={Load_unload[2]}
                    value={MouldLoadAndUnload.MoldSetterUnLoadLabel}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        MoldSetterUnLoad: e.value,
                        MoldSetterUnLoadLabel: e.label,
                      })
                    }
                    options={MoldsetterOptions}
                    placeholder="Select Mould Setter Name"
                  />
                  {/* <input
                  id={`${id}-MoldSetterUnLoad`}
                  name={`${name}-MoldSetterUnLoad`}
                  type="text"
                  value={MouldLoadAndUnload.MoldSetterUnLoad}
                  onChange={(e) =>
                    setMouldLoadAndUnload({
                      ...MouldLoadAndUnload,
                      MoldSetterUnLoad: e.target.value,
                    })
                  }
                /> */}
                </td>
              </tr>
              <tr>
                <th>S.No</th>
                <th>CheckPoints</th>
                <th>Conditions</th>
                <th colSpan={2}>Remarks</th>
              </tr>
              <tr>
                <td className="topic-head-number">1</td>
                <td className="topic-head">Mould Cleaning</td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-MouldClean`}
                    name={`${name}-MouldClean`}
                    Value={MouldLoadAndUnload.MouldClean}
                    isDisabled={Load_unload[2]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        MouldClean: e.target.value,
                      })
                    }
                  />
                </td>
                <td colSpan={2}>
                  <textarea
                    id={`${id}-MouldCleanRemarks`}
                    name={`${name}-MouldCleanRemarks`}
                    type="text"
                    value={MouldLoadAndUnload.MouldCleanRemarks}
                    disabled={Load_unload[2]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        MouldCleanRemarks: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">2</td>
                <td className="topic-head">Applying Anti Wax Coating</td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-ApplyAniWaxCoat`}
                    name={`${name}-ApplyAniWaxCoat`}
                    Value={MouldLoadAndUnload.ApplyAniWaxCoat}
                    isDisabled={Load_unload[2]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        ApplyAniWaxCoat: e.target.value,
                      })
                    }
                  />
                </td>
                <td colSpan={2}>
                  <textarea
                    id={`${id}-ApplyAniWaxCoatRemarks`}
                    name={`${name}-ApplyAniWaxCoatRemarks`}
                    type="text"
                    value={MouldLoadAndUnload.ApplyAniWaxCoatRemarks}
                    disabled={Load_unload[2]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        ApplyAniWaxCoatRemarks: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">3</td>
                <td className="topic-head">
                  Eye Bolt & Lifting Bar & Belt Condition
                </td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-EyeBoltLiftBelt`}
                    name={`${name}-EyeBoltLiftBelt`}
                    Value={MouldLoadAndUnload.EyeBoltLiftBelt}
                    isDisabled={Load_unload[2]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        EyeBoltLiftBelt: e.target.value,
                      })
                    }
                  />
                </td>
                <td colSpan={2}>
                  <textarea
                    id={`${id}-EyeBoltLiftBeltRemarks`}
                    name={`${name}-EyeBoltLiftBeltRemarks`}
                    type="text"
                    value={MouldLoadAndUnload.EyeBoltLiftBeltRemarks}
                    disabled={Load_unload[2]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        EyeBoltLiftBeltRemarks: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">4</td>
                <td className="topic-head">
                  Mould Closing & Removing Water Line
                </td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-MouldClsRemveWatrLne`}
                    name={`${name}-MouldClsRemveWatrLne`}
                    Value={MouldLoadAndUnload.MouldClsRemveWatrLne}
                    isDisabled={Load_unload[2]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        MouldClsRemveWatrLne: e.target.value,
                      })
                    }
                  />
                </td>
                <td colSpan={2}>
                  <textarea
                    id={`${id}-MouldClsRemveWatrLneRemarks`}
                    name={`${name}-MouldClsRemveWatrLneRemarks`}
                    type="text"
                    value={MouldLoadAndUnload.MouldClsRemveWatrLneRemarks}
                    disabled={Load_unload[2]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        MouldClsRemveWatrLneRemarks: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">5</td>
                <td className="topic-head">Removing Water I Use Air</td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-RemoveWatrUseAir`}
                    name={`${name}-RemoveWatrUseAir`}
                    Value={MouldLoadAndUnload.RemoveWatrUseAir}
                    isDisabled={Load_unload[2]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        RemoveWatrUseAir: e.target.value,
                      })
                    }
                  />
                </td>
                <td colSpan={2}>
                  <textarea
                    id={`${id}-RemoveWatrUseAirRemarks`}
                    name={`${name}-RemoveWatrUseAirRemarks`}
                    type="text"
                    value={MouldLoadAndUnload.RemoveWatrUseAirRemarks}
                    disabled={Load_unload[2]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        RemoveWatrUseAirRemarks: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">6</td>
                <td className="topic-head">Unclamp The Mold</td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-UnclampMold`}
                    name={`${name}-UnclampMold`}
                    Value={MouldLoadAndUnload.UnclampMold}
                    isDisabled={Load_unload[2]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        UnclampMold: e.target.value,
                      })
                    }
                  />
                </td>
                <td colSpan={2}>
                  <textarea
                    id={`${id}-UnclampMoldRemarks`}
                    name={`${name}-UnclampMoldRemarks`}
                    type="text"
                    value={MouldLoadAndUnload.UnclampMoldRemarks}
                    disabled={Load_unload[2]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        UnclampMoldRemarks: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">7</td>
                <td className="topic-head">Check The Locating Cover</td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-ChkLocatingCover`}
                    name={`${name}-ChkLocatingCover`}
                    Value={MouldLoadAndUnload.ChkLocatingCover}
                    isDisabled={Load_unload[2]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        ChkLocatingCover: e.target.value,
                      })
                    }
                  />
                </td>
                <td colSpan={2}>
                  <textarea
                    id={`${id}-ChkLocatingCoverRemarks`}
                    name={`${name}-ChkLocatingCoverRemarks`}
                    type="text"
                    value={MouldLoadAndUnload.ChkLocatingCoverRemarks}
                    disabled={Load_unload[2]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        ChkLocatingCoverRemarks: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>

              <tr>
                <th
                  id={`${id}-MouldLocationIdentityActivityTitle`}
                  name={`${name}-MouldLocationIdentityActivityTitle`}
                  colSpan={5}
                >
                  MOULD LOCATION IDENTIFY ACTIVITY
                </th>
              </tr>
              <tr>
                <th>S.No</th>
                <th>CheckPoints</th>
                <th colSpan={3}>Location No</th>
              </tr>
              <tr>
                <td className="topic-head-number">1</td>
                <td className="topic-head">Mould Location Area</td>
                <td colSpan={3}>
                  <input
                    id={`${id}-MouldLocationArea`}
                    name={`${name}-MouldLocationArea`}
                    type="text"
                    value={MouldLoadAndUnload.MouldLocationArea}
                    disabled={Load_unload[3]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        MouldLocationArea: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">Remarks</td>
                <td colSpan={4}>
                  <textarea
                    id={`${id}-Remarks`}
                    name={`${name}-Remarks`}
                    rows={4}
                    cols={50}
                    value={MouldLoadAndUnload.Remarks}
                    disabled={Load_unload[3]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        Remarks: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="topic-head">
                  Technican<span style={{ color: "red" }}>*</span>
                  <br></br>
                  <br></br>
                  <DropdownFetcher
                    keyword="emp_details_dropdown_all"
                    setValue={setEmployeeDropdown}
                  />
                  {MouldLoadAndUnload.TechnicanNameLabel === '' && isCreating && <span className="Req">Required *</span>}
                  <DropdownFilterSelect
                    id={`${id}-TechnicanName`}
                    name={`${name}-TechnicanName`}
                    value={MouldLoadAndUnload.TechnicanNameLabel}
                    isDisabled={Load_unload[3]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        TechnicanName: e.value,
                        TechnicanNameLabel: e.label,
                      })
                    }
                    options={empAllOptions}
                    placeholder="Select Technican Name"
                  />
                  {/* <input
                  id={`${id}-TechnicanName`}
                  name={`${name}-TechnicanName`}
                  type="text"
                  value={MouldLoadAndUnload.TechnicanName}
                  onChange={(e) =>
                    setMouldLoadAndUnload({
                      ...MouldLoadAndUnload,
                      TechnicanName: e.target.value,
                    })
                  }
                /> */}
                </td>

                <td className="topic-head">
                  Toolroom<span style={{ color: "red" }}>*</span>
                  <br></br>
                  <br></br>
                  <DropdownFetcher
                    keyword="emp_details_dropdown_all"
                    setValue={setEmployeeDropdown}
                  />
                  {MouldLoadAndUnload.ToolroomNameLabel === '' && isCreating && <span className="Req">Required *</span>}
                  <DropdownFilterSelect
                    id={`${id}-ToolroomName`}
                    name={`${name}-ToolroomName`}
                    value={MouldLoadAndUnload.ToolroomNameLabel}
                    isDisabled={Load_unload[3]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        ToolroomName: e.value,
                        ToolroomNameLabel: e.label,
                      })
                    }
                    options={empAllOptions}
                    placeholder="Select Toolroom Name"
                  />
                  {/* <input
                  id={`${id}-ToolroomName`}
                  name={`${name}-ToolroomName`}
                  type="text"
                  value={MouldLoadAndUnload.ToolroomName}
                  onChange={(e) =>
                    setMouldLoadAndUnload({
                      ...MouldLoadAndUnload,
                      ToolroomName: e.target.value,
                    })
                  }
                /> */}
                </td>

                <td colSpan={2} className="topic-head">
                  production Engg<span style={{ color: "red" }}>*</span>
                  <br></br>
                  <br></br>
                  <DropdownFetcher
                    keyword="emp_details_dropdown_all"
                    setValue={setEmployeeDropdown}
                  />
                  {MouldLoadAndUnload.ProductionEnggNameLabel === '' && isCreating && <span className="Req">Required *</span>}
                  <DropdownFilterSelect
                    id={`${id}-ProductionEnggName`}
                    name={`${name}-ProductionEnggName`}
                    value={MouldLoadAndUnload.ProductionEnggNameLabel}
                    isDisabled={Load_unload[3]}
                    onChange={(e) =>
                      setMouldLoadAndUnload({
                        ...MouldLoadAndUnload,
                        ProductionEnggName: e.value,
                        ProductionEnggNameLabel: e.label,
                      })
                    }
                    options={empAllOptions}
                    placeholder="Select Productio Engg Name"
                  />
                  {/* <input
                  id={`${id}-ProductionEnggName`}
                  name={`${name}-ProductionEnggName`}
                  type="text"
                  value={MouldLoadAndUnload.ProductionEnggName}
                  onChange={(e) =>
                    setMouldLoadAndUnload({
                      ...MouldLoadAndUnload,
                      ProductionEnggName: e.target.value,
                    })
                  }
                /> */}
                </td>
              </tr>
              <tr>
                <td colSpan="5" align="center" style={{ border: "none" }}>
                  {!Load_unload[1] ? (<button
                    type="submit"
                    id={`${id}-submit`}
                    name={`${name}-submit`}
                    onClick={(e) => handleCreate(e)}
                    disabled={Load_unload[1]}
                  >
                    Submit
                  </button>) : !Load_unload[2] ? (<button
                    type="submit"
                    id={`${id}-submit`}
                    name={`${name}-submit`}
                    onClick={(e) => handleUpdate(e)}
                    disabled={Load_unload[2]}
                  >
                    Update
                  </button>) : ""}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  );
}

export default MldUnloadAndLoadCreate;
