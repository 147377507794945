import React, { useEffect } from "react";
import Select from "react-select";
import "./barcodestyle.css";
import Barcode from "react-barcode";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import { useState } from "react";
import { API_URL } from "../../../Utils/constants";
import axios from "axios";

export default function BarcodeHandler() {
  const [Field, setField] = useState([]);
  const [CardValue, setCardValue] = useState({ partId: "", partNumber: "" });
  const [recordvalues, setrecordvalues] = useState();
  const [Tablename, setTablename] = useState('');

  let componentref = useRef();

  const tablename = [
    ["mac_dtl_dropdown_like", "Machine"],
    ["mac_part_dtl_dropdown", "Machine part"],
    ["fg_details_dropdown", "FG"],
    ["supplier_details", "Supplier"],
    ["rm_details_view", "Raw Material"],
    ["emp_details_dropdown", "Employee"],
    ["customer_details_dropdown", "Customer"],
  ];

  const option = {
    mac_dtl_dropdown_like: ["mc_name", "mc_code"],
    mac_part_dtl_dropdown: ["mc_part_name", "mc_part_code"],
    fg_details_dropdown: ["Fg_description", "Fg_code"],
    supplier_details: ["Sup_name", "Sup_code"],
    rm_details_view: ["Rm_description", "Rm_code"],
    emp_details_dropdown: ["Emp_name", "Emp_code"],
    customer_details_dropdown: ["Cus_name", "Cus_code"],
  };
function setTablevalue2(event) {
  setField([]);
  setCardValue({ partId: "", partNumber: "" });
  setrecordvalues(null);
  setTablename(event.value);
  console.log(event);
}
function setTablevalue3(event) {
  console.log(Tablename,event);
  const data = {
    _keyword_: Tablename,
    key:"%"+event+"%",
    value:"%"+event+"%",
  };
  // console.log(tableName.value);
  // setField([]);
  // setrecordvalues(null);
  // setCardValue({ partId: "", partNumber: "" });
  axios
    .post(`http://${API_URL}/GenericResultBuilderService/buildResults`, data)
    .then((response) => {
      const data = response.data.data;
      const values = Object.keys(data).map((key) => {
        return {
          value: data[key][option[Tablename][1]],
          label: data[key][option[Tablename][0]],
        };
      });

      if (values.length > 0) {
        setField(values);
      } else {
        alert("No Record Found");
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

  // async function setTablevalue(tableName) {
  //   const data = {
  //     _keyword_: tableName.value,
  //   };
  //   console.log(tableName.value);
  //   setField([]);
  //   setrecordvalues(null);
  //   setCardValue({ partId: "", partNumber: "" });
  //   axios
  //     .post(`http://${API_URL}/GenericResultBuilderService/buildResults`, data)
  //     .then((response) => {
  //       let data = response.data.data;
  //       console.log(data);
  //       let values = Object.keys(data).map((key) => {
  //         return {
  //           value: data[key][option[tableName.value][1]],
  //           label: data[key][option[tableName.value][0]],
  //         };
  //       });

  //       if (values.length > 0) {
  //         setField(values);
  //       } else {
  //         alert("No Record Found");
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }
  useEffect(() => {
    document.title = "Barcode Generator";
  }, []);

  function setCardValues(input) {
    setrecordvalues({ value: input.value, label: input.label });
    setCardValue({ partId: input.value, partNumber: input.label });
  }

  return (
    <section>
      <div className="head">
        <img
          style={{ float: "left", padding: "20px 0px" }}
          width={40}
          height={40}
          alt="Logo"
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCYiNoJBqNFGS-A_t8rNPvSEm-6RqRWPlNyLY62NwZRQ&s"
        />
        <font style={{ fontSize: "24px" }}>
        KASH Tech LLC
        </font>
        <br></br>
        <font color="#8B0000">Barcode Code Generation</font>
      </div>

      <div className="filter">
        <Select
          id="selector"
          options={tablename.map((item) => ({
            value: item[0],
            label: item[1],
          }))}
          onChange={setTablevalue2}
          placeholder="Select Master"
          
        />
        <Select
          id="selector"
          options={Object.keys(Field).map((item) => ({
            value: Field[item].value,
            label: Field[item].label,
          }))}
          value={recordvalues === null ? null : recordvalues}
          onChange={setCardValues}
          placeholder={"Select Record"}
          onInputChange={setTablevalue3}
        />
      </div>

      <div className="pdfsection">
        <div
          className="pdfcard"
          ref={(element) => {
            componentref = element;
          }}
        >
          <div className="pdfhead">
            <img
              className="bar-img"
              alt="Logo"
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCYiNoJBqNFGS-A_t8rNPvSEm-6RqRWPlNyLY62NwZRQ&s"
            />
            <div className="title">KASH Tech LLC</div>
          </div>
          <div className="section">
            <div className="heading">Name</div>
            <div className="values">{CardValue.partNumber}</div>
          </div>
          <div>
            <div className="heading">Id</div>
            <div className="values">{CardValue.partId}</div>
          </div>
          <div className="barcode">
            {CardValue && CardValue.partId && (
              <Barcode
                value={CardValue.partId}
                width={1.5}
                height={40}
                fontSize={12}
              />
            )}
          </div>
        </div>

        <div className="printbtn">
          <ReactToPrint
            // pageStyle={pageStyle}
            trigger={() => <button type="button">Print</button>}
            content={() => componentref}
            // onAfterPrint={() => {
            //   alert("Print Screen triggered and the process initiated");
            // }}
          />
        </div>
      </div>
    </section>
  );
}
