
import React, { useState, useRef } from "react";
import Popup from "../../../Components/Popup/Popup";
import "../../../Styles/Report4M.css";
import {
  SECRET_KEY,
  PROCESS_TRANSACTION_UPDATE_URL,
} from "../../../Utils/constants.js";
import * as GeneralConstants from "../../../Utils/GeneralConstants";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import Unitechlogo from "../../../icons/Unitechlogo.jpeg";

import HandleRead from "../../../Lib/HandleRead";
import Pagination from "../../../Components/Pagination";
import DatePicker from "../../../Lib/DatePicker";
import { formatDt } from "../../../Lib/formatDt";
import ShiftDropdownown from "../../../Components/ShiftDropdown.js";
import DropdownFetcher from "../../../Lib/DropdownFetcher.js";
import DropdownFilterSelect from "../../../Components/DropdownFilterSelectFetcher.js";
import OkNotOkayFetch from "../../../Components/OkAndNotOkFetcher.js";
import { useNavigate, useParams } from "react-router-dom";
import GetTableRecord from "../../../Lib/GetTableRecord.js";
import UpdateRecord from "../../../Lib/updateRecord";
import { useEffect } from "react";
import { FaBackspace } from 'react-icons/fa';
import Select from "react-select";
import { MdDelete } from "react-icons/md";
import ReadRecord from "../../../Lib/API/ReadRecord.js";
const initialState = {
  EjcRod: "",
  MoldPreHeat: "",
  PartNameLoad: "",
  TrackDateLoad: "",
  EyeLiftBely: "",
  ChkTheMTC: "",
  CoolValue: "",
  MldWtrOutFlw: "",
  ChkBlushNozle: "",
  ChkLocatingRing: "",
  LoadingTimeFrom: "",
  LoadingTimeTo: "",
  ChkCoolIntOutCondn: "",
  RawMaterialName: "",
  Shift: "",
  MCNo: "",
  CustomerNameLoad: "",
  ChkNozleSizeCondn: "",
  Butting: "",
  UseSetMdeOpnMold: "",
  EnsureMldClnPro: "",
  MldSfty1Pres: "",
  MldSfty2Pres: "",
  UnLoadingTimeFrom: "",
  UnLoadingTimeTo: "",
  MoldSetterLoad: "",
  PartNameUnload: "",
  TrackDateUnLoad: "",
  CustomerNameUnload: "",
  MoldSetterUnLoad: "",
  MouldClean: "",
  ApplyAniWaxCoat: "",
  EyeBoltLiftBelt: "",
  MouldClsRemveWatrLne: "",
  RemoveWatrUseAir: "",
  UnclampMold: "",
  ChkLocatingCover: "",
  LPerM: "",
  MouldLocationArea: "",
  Remarks: "",
  TechnicanName: "",
  ToolroomName: "",
  ProductionEnggName: "",

  EyeLiftBelyRemarks: "",
  EjcRodRemarks: "",
  CoolValueRemarks: "",
  MoldPreHeatRemarks: "",
  ChkTheMTCRemarks: "",
  MldWtrOutFlwRemarks: "",
  ChkBlushNozleRemarks: "",
  ChkLocatingRingRemarks: "",
  ButtingRemarks: "",
  ChkNozleSizeCondnRemarks: "",
  ChkCoolIntOutCondnRemarks: "",
  UseSetMdeOpnMoldRemarks: "",
  EnsureMldClnProRemarks: "",
  MldSfty1PresRemarks: "",
  MldSfty2PresRemarks: "",
  LPerMRemarks: "",
  MouldCleanRemarks: "",
  ApplyAniWaxCoatRemarks: "",
  EyeBoltLiftBeltRemarks: "",
  MouldClsRemveWatrLneRemarks: "",
  RemoveWatrUseAirRemarks: "",
  UnclampMoldRemarks: "",
  ChkLocatingCoverRemarks: "",
  MoldSetterUnLoadLabel: "",
  TechnicanNameLabel: "",
  ToolroomNameLabel: "",
  ProductionEnggNameLabel: "",
  isPaginationVisible: true,
  currentPage: 1,
  filterMcno: "",
  filterDate: "",
  Id: "",
};
function MldUnloadAndLoadView() {
  const scrollTop = useRef(null);
  const [MouldLoadAndUnload, setMouldLoadAndUnload] = useState(initialState);
  const [dropdown, setDropdown] = useState([]);
  const [partDropdown, setPartDropdown] = useState([]);
  const [Rmdropdown, setRmDropdown] = useState([]);
  const [CustomerDropdown, setCustomerDropdown] = useState([]);
  const [CustomerUlDropdown, setCustomerUlDropdown] = useState([]);
  const [EmployeeDropdown, setEmployeeDropdown] = useState([]);
  const [MoldsetterDropdown, setMoldsetterDropdown] = useState([]);


  const idView = "MldUnloadLoad-View";
  const nameView = "MldUnloadLoad-View";
  const nameEdit = "MldUnloadLoad-Edit";
  const idEdit = "MldUnloadLoad-Edit";
  const nameList = "Report4M-List";
  const idList = "Report4M-List";
  var [Mld_load_unload, setMld_load_unload] = useState([]);
  const [ButtonPopup2, setButtonPopup2] = useState(false);
  const [ButtonPopup, setButtonPopup] = useState(false);

  const recordPerPage = 20;

  const filterRecords = () => {
    const filteredData = Mld_load_unload.filter((record) => {
      const dateMatch =
        record.Track_date_load &&
        record.Track_date_load.includes(MouldLoadAndUnload.filterDate);
      const mcnoMatch = record.MC_no.toLowerCase().includes(
        MouldLoadAndUnload.filterMcno.toLowerCase()
      );
      return dateMatch && mcnoMatch;
    });
    return filteredData || [];
  };
  const filteredData = filterRecords();
  const npage = Math.ceil(filteredData.length / recordPerPage);
  const numbers = [...Array(npage).keys()].map((num) => num + 1);

  function handleFilterMcnoChange(event) {
    setMouldLoadAndUnload({
      ...MouldLoadAndUnload,
      currentPage: 1,
      filterMcno: event.target.value,
    });
  }
  function handleFilterDateChange(event) {
    setMouldLoadAndUnload({
      ...MouldLoadAndUnload,
      currentPage: 1,
      filterDate: event.target.value,
    });
  }
  const handleCustomerload = (selectedOption) => {
    const selected = CustomerDropdown.find(
      (option) => option.Cus_code === selectedOption.value
    );
    if (selected) {
      setMouldLoadAndUnload({
        ...MouldLoadAndUnload,
        CustomerNameLoad: {
          value: selected.Cus_code,
          label: selected.Cus_name,
        },
      })
    }
  };
  const handleCustomerunload = (selectedOption) => {
    const selected = CustomerUlDropdown.find(
      (option) => option.Cus_code === selectedOption.value
    );
    if (selected) {
      setMouldLoadAndUnload({
        ...MouldLoadAndUnload,
        CustomerNameUnload: {
          value: selected.Cus_code,
          label: selected.Cus_name,
        },
      })
    }
  };
  const handleMoldSetterload = (selectedOption) => {
    const selected = MoldsetterDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setMouldLoadAndUnload({
        ...MouldLoadAndUnload,
        MoldSetterLoad: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      })
    }
  };
  const handleMoldSetterunload = (selectedOption) => {
    const selected = MoldsetterDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setMouldLoadAndUnload({
        ...MouldLoadAndUnload,
        MoldSetterUnLoad: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      })
    }
  };
  const handleTech = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setMouldLoadAndUnload({
        ...MouldLoadAndUnload,
        TechnicanName: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      })
    }
  };
  const handleTool = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setMouldLoadAndUnload({
        ...MouldLoadAndUnload,
        ToolroomName: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      })
    }
  };
  const handleProEng = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setMouldLoadAndUnload({
        ...MouldLoadAndUnload,
        ProductionEnggName: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      })
    }
  };
  const firstIndex = (MouldLoadAndUnload.currentPage - 1) * recordPerPage;
  const lastIndex = firstIndex + recordPerPage;
  const filteredRecords = filteredData.slice(firstIndex, lastIndex);

  function prePage() {
    if (MouldLoadAndUnload.currentPage > 1) {
      setMouldLoadAndUnload({
        ...MouldLoadAndUnload,
        currentPage: MouldLoadAndUnload.currentPage - 1,
      });
    }
  }

  function nextPage() {
    if (MouldLoadAndUnload.currentPage < npage) {
      setMouldLoadAndUnload({
        ...MouldLoadAndUnload,
        currentPage: MouldLoadAndUnload.currentPage + 1,
      });
    }
  }

  function changeCpage(id) {
    setMouldLoadAndUnload({ ...MouldLoadAndUnload, currentPage: id });
  }
  const viewPagination = () => {
    setMouldLoadAndUnload({ ...MouldLoadAndUnload, isPaginationVisible: true });
  };

  const handleView = (e) => {
    setMouldLoadAndUnload({
      ...MouldLoadAndUnload,
      isPaginationVisible: false,
      PartNameLoad: e.PartNameLoad,
      TrackDateLoad: e.Track_date_load,
      RawMaterialName: e.Raw_material_name,
      Shift: e.Shift,
      MCNo: e.MC_no,
      CustomerNameLoad: (`${e.Customer_name_load}-${e.CustomerNameLoad}`),
      MoldSetterLoad: (`${e.Mold_setter_load}-${e.MoldSetterLoad}`),
      EyeLiftBely: e.Eye_lift_bely,
      EjcRod: e.Ejc_rod,
      CoolValue: e.Cool_value,
      MoldPreHeat: e.Mold_pre_heat,
      ChkTheMTC: e.Chk_the_MTC,
      MldWtrOutFlw: e.Mld_wtr_out_flw,
      ChkBlushNozle: e.Chk_blush_nozle,
      ChkLocatingRing: e.Chk_locating_ring,
      LoadingTimeFrom: e.Loading_time_from,
      LoadingTimeTo: e.Loading_time_to,
      ChkCoolIntOutCondn: e.Chk_cool_int_out_condn,
      ChkNozleSizeCondn: e.Chk_nozle_size_condn,
      Butting: e.Butting,
      UseMdeOpnMold: e.Use__mde_opn_mold,
      EnsureMldClnPro: e.Ensure_mld_cln_pro,
      MldSfty1Pres: e.Mld_sfty_1_pres,
      MldSfty2Pres: e.Mld_sfty_2_pres,
      UnLoadingTimeFrom: e.Un_loading_time_from,
      UnLoadingTimeTo: e.Un_loading_time_to,
      PartNameUnload: e.PartNameUnLoad,
      TrackDateUnLoad: e.Track_date_unLoad,
      CustomerNameUnload: (`${e.Customer_name_unload || '-'}-${e.CustomerNameUnload || '- '}`),
      MoldSetterUnLoad: (`${e.Mold_setter_unLoad || '-'}-${e.MoldSetterUnLoad || '-'}`),
      MouldClean: e.Mould_clean,
      ApplyAniWaxCoat: e.Apply_ani_wax_coat,
      EyeBoltLiftBelt: e.Eye_bolt_lift_belt,
      MouldClsRemveWatrLne: e.Mould_cls_remve_watr_lne,
      RemoveWatrUseAir: e.Remove_watr_use_air,
      UnclampMold: e.Unclamp_mold,
      ChkLocatingCover: e.Chk_locating_cover,
      LPerM: e.L_per_m,
      MouldLocationArea: e.Mould_location_area,
      Remarks: e.Remarks_mold_unmold,
      TechnicanName: (`${e.Technican_name}-${e.TechnicanName}`),
      ToolroomName: (`${e.Toolroom_name}-${e.ToolroomName}`),
      ProductionEnggName: (`${e.Production_engg_name}-${e.ProductionEnggName}`),
      UseSetMdeOpnMold: e.Use_set_mde_opn_mold,
      EyeLiftBelyRemarks: e.Eye_lift_bely_remarks,
      EjcRodRemarks: e.Ejc_rod_remarks,
      CoolValueRemarks: e.Cool_value_remarks,
      MoldPreHeatRemarks: e.Mold_pre_heat_remarks,
      ChkTheMTCRemarks: e.Chk_the_mTC_remarks,
      MldWtrOutFlwRemarks: e.Mld_wtr_out_flw_remarks,
      ChkBlushNozleRemarks: e.Chk_blush_nozle_remarks,
      ChkLocatingRingRemarks: e.Chk_locating_ring_remarks,
      ButtingRemarks: e.Butting_remarks,
      ChkNozleSizeCondnRemarks: e.Chk_nozle_size_condn_remarks,
      ChkCoolIntOutCondnRemarks: e.Chk_cool_int_out_condn_remarks,
      UseSetMdeOpnMoldRemarks: e.Use_set_mde_opn_mold_remarks,
      EnsureMldClnProRemarks: e.Ensure_mld_cln_pro_remarks,
      MldSfty1PresRemarks: e.Mld_sfty_1_pres_remarks,
      MldSfty2PresRemarks: e.Mld_sfty_2_pres_remarks,
      LPerMRemarks: e.L_per_m_remarks,
      MouldCleanRemarks: e.Mould_clean_remarks,
      ApplyAniWaxCoatRemarks: e.Apply_ani_wax_coat_remarks,
      EyeBoltLiftBeltRemarks: e.Eye_bolt_lift_belt_remarks,
      MouldClsRemveWatrLneRemarks: e.Mould_cls_remve_watr_lne_remarks,
      RemoveWatrUseAirRemarks: e.Remove_watr_use_air_remarks,
      UnclampMoldRemarks: e.Unclamp_mold_remarks,
      ChkLocatingCoverRemarks: e.Chk_locating_cover_remarks,
    });
    setButtonPopup2(true);
  };
  const handleEdit = (e) => {
    setMouldLoadAndUnload({
      ...MouldLoadAndUnload,
      isPaginationVisible: false,
      Id: e.Id,
      PartNameLoad: e.PartNameLoad,
      TrackDateLoad: e.Track_date_load,
      RawMaterialName: e.Raw_material_name,
      Shift: e.Shift,
      MCNo: e.MC_no,
      CustomerNameLoad: { value: e.Customer_name_load, label: e.CustomerNameLoad },
      //    CustomerNameLoad: e.CustomerNameLoad,
      MoldSetterLoad: { value: e.Mold_setter_load, label: e.MoldSetterLoad },
      EyeLiftBely: e.Eye_lift_bely,
      EjcRod: e.Ejc_rod,
      CoolValue: e.Cool_value,
      MoldPreHeat: e.Mold_pre_heat,
      ChkTheMTC: e.Chk_the_MTC,
      MldWtrOutFlw: e.Mld_wtr_out_flw,
      ChkBlushNozle: e.Chk_blush_nozle,
      ChkLocatingRing: e.Chk_locating_ring,
      LoadingTimeFrom: e.Loading_time_from,
      LoadingTimeTo: e.Loading_time_to,
      ChkCoolIntOutCondn: e.Chk_cool_int_out_condn,
      ChkNozleSizeCondn: e.Chk_nozle_size_condn,
      Butting: e.Butting,
      UseMdeOpnMold: e.Use__mde_opn_mold,
      EnsureMldClnPro: e.Ensure_mld_cln_pro,
      MldSfty1Pres: e.Mld_sfty_1_pres,
      MldSfty2Pres: e.Mld_sfty_2_pres,
      UnLoadingTimeFrom: e.Un_loading_time_from,
      UnLoadingTimeTo: e.Un_loading_time_to,
      PartNameUnload: e.PartNameUnLoad,
      TrackDateUnLoad: e.Track_date_unLoad,
      CustomerNameUnload: { value: e.Customer_name_unload, label: e.CustomerNameUnload },
      MoldSetterUnLoad: { value: e.Mold_setter_unLoad, label: e.MoldSetterUnLoad },
      MouldClean: e.Mould_clean,
      ApplyAniWaxCoat: e.Apply_ani_wax_coat,
      EyeBoltLiftBelt: e.Eye_bolt_lift_belt,
      MouldClsRemveWatrLne: e.Mould_cls_remve_watr_lne,
      RemoveWatrUseAir: e.Remove_watr_use_air,
      UnclampMold: e.Unclamp_mold,
      ChkLocatingCover: e.Chk_locating_cover,
      LPerM: e.L_per_m,
      MouldLocationArea: e.Mould_location_area,
      Remarks: e.Remarks_mold_unmold,
      TechnicanName: { value: e.Technican_name, label: e.TechnicanName },
      ToolroomName: { value: e.Toolroom_name, label: e.ToolroomName },
      ProductionEnggName: { value: e.Production_engg_name, label: e.ProductionEnggName },
      UseSetMdeOpnMold: e.Use_set_mde_opn_mold,

      EyeLiftBelyRemarks: e.Eye_lift_bely_remarks,
      EjcRodRemarks: e.Ejc_rod_remarks,
      CoolValueRemarks: e.Cool_value_remarks,
      MoldPreHeatRemarks: e.Mold_pre_heat_remarks,
      ChkTheMTCRemarks: e.Chk_the_mTC_remarks,
      MldWtrOutFlwRemarks: e.Mld_wtr_out_flw_remarks,
      ChkBlushNozleRemarks: e.Chk_blush_nozle_remarks,
      ChkLocatingRingRemarks: e.Chk_locating_ring_remarks,
      ButtingRemarks: e.Butting_remarks,
      ChkNozleSizeCondnRemarks: e.Chk_nozle_size_condn_remarks,
      ChkCoolIntOutCondnRemarks: e.Chk_cool_int_out_condn_remarks,
      UseSetMdeOpnMoldRemarks: e.Use_set_mde_opn_mold_remarks,
      EnsureMldClnProRemarks: e.Ensure_mld_cln_pro_remarks,
      MldSfty1PresRemarks: e.Mld_sfty_1_pres_remarks,
      MldSfty2PresRemarks: e.Mld_sfty_2_pres_remarks,
      LPerMRemarks: e.L_per_m_remarks,
      MouldCleanRemarks: e.Mould_clean_remarks,
      ApplyAniWaxCoatRemarks: e.Apply_ani_wax_coat_remarks,
      EyeBoltLiftBeltRemarks: e.Eye_bolt_lift_belt_remarks,
      MouldClsRemveWatrLneRemarks: e.Mould_cls_remve_watr_lne_remarks,
      RemoveWatrUseAirRemarks: e.Remove_watr_use_air_remarks,
      UnclampMoldRemarks: e.Unclamp_mold_remarks,
      ChkLocatingCoverRemarks: e.Chk_locating_cover_remarks,
    });
    setButtonPopup(true);
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    if (
      !MouldLoadAndUnload.PartNameLoad ||
      !MouldLoadAndUnload.CustomerNameLoad ||
      !MouldLoadAndUnload.MCNo ||
      !MouldLoadAndUnload.RawMaterialName ||
      !MouldLoadAndUnload.TrackDateUnLoad
    ) {
      alert(GeneralConstants.MANDATORY_ALERT);
      if (scrollTop.current) {
        scrollTop.current.scrollIntoView({ behavior: "smooth" });
      }
      return;
    }
    const data = {
      data: [
        {
          Id: MouldLoadAndUnload.Id,
          // Part_name_load: MouldLoadAndUnload.PartNameLoad,
          Track_date_load: MouldLoadAndUnload.TrackDateLoad,
          Raw_material_name: MouldLoadAndUnload.RawMaterialName,
          Shift: MouldLoadAndUnload.Shift,
          MC_no: MouldLoadAndUnload.MCNo,
          Customer_name_load: MouldLoadAndUnload.CustomerNameLoad.value,
          Mold_setter_load: MouldLoadAndUnload.MoldSetterLoad.value,
          Eye_lift_bely: MouldLoadAndUnload.EyeLiftBely,
          Ejc_rod: MouldLoadAndUnload.EjcRod,
          Cool_value: MouldLoadAndUnload.CoolValue,
          Mold_pre_heat: MouldLoadAndUnload.MoldPreHeat,
          Chk_the_MTC: MouldLoadAndUnload.ChkTheMTC,
          Mld_wtr_out_flw: MouldLoadAndUnload.MldWtrOutFlw,
          Chk_blush_nozle: MouldLoadAndUnload.ChkBlushNozle,
          Chk_locating_ring: MouldLoadAndUnload.ChkLocatingRing,

          Loading_time_from: MouldLoadAndUnload.LoadingTimeFrom,
          Loading_time_to: MouldLoadAndUnload.LoadingTimeTo,
          Chk_cool_int_out_condn: MouldLoadAndUnload.ChkCoolIntOutCondn,
          Chk_nozle_size_condn: MouldLoadAndUnload.ChkNozleSizeCondn,
          Butting: MouldLoadAndUnload.Butting,
          Use_set_mde_opn_mold: MouldLoadAndUnload.UseSetMdeOpnMold,
          Ensure_mld_cln_pro: MouldLoadAndUnload.EnsureMldClnPro,
          Mld_sfty_1_pres: MouldLoadAndUnload.MldSfty1Pres,
          Mld_sfty_2_pres: MouldLoadAndUnload.MldSfty2Pres,

          Un_loading_time_from: MouldLoadAndUnload.UnLoadingTimeFrom,
          Un_loading_time_to: MouldLoadAndUnload.UnLoadingTimeTo,
          // Part_name_unload: MouldLoadAndUnload.PartNameUnload,
          Track_date_unLoad: MouldLoadAndUnload.TrackDateUnLoad,
          Customer_name_unload: MouldLoadAndUnload.CustomerNameUnload.value,
          Mold_setter_unLoad: MouldLoadAndUnload.MoldSetterUnLoad.value,
          Mould_clean: MouldLoadAndUnload.MouldClean,
          Apply_ani_wax_coat: MouldLoadAndUnload.ApplyAniWaxCoat,
          Eye_bolt_lift_belt: MouldLoadAndUnload.EyeBoltLiftBelt,
          Mould_cls_remve_watr_lne: MouldLoadAndUnload.MouldClsRemveWatrLne,
          Remove_watr_use_air: MouldLoadAndUnload.RemoveWatrUseAir,
          Unclamp_mold: MouldLoadAndUnload.UnclampMold,
          Chk_locating_cover: MouldLoadAndUnload.ChkLocatingCover,
          L_per_m: MouldLoadAndUnload.LPerM,

          Mould_location_area: MouldLoadAndUnload.MouldLocationArea,
          Remarks_mold_unmold: MouldLoadAndUnload.Remarks,
          Technican_name: MouldLoadAndUnload.TechnicanName.value,
          Toolroom_name: MouldLoadAndUnload.ToolroomName.value,
          Production_engg_name: MouldLoadAndUnload.ProductionEnggName.value,

          Eye_lift_bely_remarks: MouldLoadAndUnload.EyeLiftBelyRemarks,
          Ejc_rod_remarks: MouldLoadAndUnload.EjcRodRemarks,
          Cool_value_remarks: MouldLoadAndUnload.CoolValueRemarks,
          Mold_pre_heat_remarks: MouldLoadAndUnload.MoldPreHeatRemarks,
          Chk_the_mTC_remarks: MouldLoadAndUnload.ChkTheMTCRemarks,
          Mld_wtr_out_flw_remarks: MouldLoadAndUnload.MldWtrOutFlwRemarks,
          Chk_blush_nozle_remarks: MouldLoadAndUnload.ChkBlushNozleRemarks,
          Chk_locating_ring_remarks: MouldLoadAndUnload.ChkLocatingRingRemarks,
          Butting_remarks: MouldLoadAndUnload.ButtingRemarks,
          Chk_nozle_size_condn_remarks:
            MouldLoadAndUnload.ChkNozleSizeCondnRemarks,
          Chk_cool_int_out_condn_remarks:
            MouldLoadAndUnload.ChkCoolIntOutCondnRemarks,
          Use_set_mde_opn_mold_remarks:
            MouldLoadAndUnload.UseSetMdeOpnMoldRemarks,
          Ensure_mld_cln_pro_remarks: MouldLoadAndUnload.EnsureMldClnProRemarks,
          Mld_sfty_1_pres_remarks: MouldLoadAndUnload.MldSfty1PresRemarks,
          Mld_sfty_2_pres_remarks: MouldLoadAndUnload.MldSfty2PresRemarks,
          L_per_m_remarks: MouldLoadAndUnload.LPerMRemarks,
          Mould_clean_remarks: MouldLoadAndUnload.MouldCleanRemarks,
          Apply_ani_wax_coat_remarks: MouldLoadAndUnload.ApplyAniWaxCoatRemarks,
          Eye_bolt_lift_belt_remarks: MouldLoadAndUnload.EyeBoltLiftBeltRemarks,
          Mould_cls_remve_watr_lne_remarks:
            MouldLoadAndUnload.MouldClsRemveWatrLneRemarks,

          Remove_watr_use_air_remarks:
            MouldLoadAndUnload.RemoveWatrUseAirRemarks,
          Unclamp_mold_remarks: MouldLoadAndUnload.UnclampMoldRemarks,
          Chk_locating_cover_remarks:
            MouldLoadAndUnload.ChkLocatingCoverRemarks,
        },
      ],
    };
    data["_keyword_"] = "Mould_loading_unloading";
    data["secretkey"] = SECRET_KEY;
    getAPIRequest(PROCESS_TRANSACTION_UPDATE_URL, "POST", data)
      .then((response) => {
        setMouldLoadAndUnload({
          ...MouldLoadAndUnload,
          isPaginationVisible:true,
        });
        // setMld_load_unload([...Mld_load_unload, response.data]);
        alert(`${GeneralConstants.UPDATE_SUCCESSFUL}`);
        setButtonPopup(false);
        handleReadMldUnloadLoad();

        if (scrollTop.current) {
          scrollTop.current.scrollIntoView({ behavior: "smooth" });
        }
        // window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        alert(GeneralConstants.UPDATE_FAILURE);
        window.location.reload();
      });
  };
  const handleDropdownChangeUnLoad = (selectedOption) => {
    const selectedMachine1 = partDropdown.find(
      (option) => option.mc_part_code === selectedOption.value
    );

    if (selectedMachine1) {
      setMouldLoadAndUnload({
        ...MouldLoadAndUnload,
        PartNameUnload: selectedMachine1.mc_part_code,
        PartNameUnloadLabel: selectedMachine1.mc_part_name,

      });
    }
  };
  const handleDateChange = (newDate) => {
    setMouldLoadAndUnload({ ...MouldLoadAndUnload, TrackDateLoad: newDate });
  };
  const handleDateChange1 = (newDate) => {
    setMouldLoadAndUnload({ ...MouldLoadAndUnload, TrackDateUnLoad: newDate });
  };
  const partNoOptions = partDropdown.map((i) => ({
    value: i.mc_part_code,
    label: i.mc_part_name,
  }));
  const mcNoOptions = dropdown.map((i) => ({
    value: i.mc_code,
    label: i.mc_name,
  }));
  const empAllOptions = EmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const MoldsetterOptions = MoldsetterDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const rmOptions = Rmdropdown.map((i) => ({
    value: i.Rm_code,
    label: i.Rm_code,
  }));
  const customerOptions = CustomerDropdown.map((i) => ({
    value: i.Cus_code,
    label: i.Cus_name,
  }));
  const customerOptionsUl = CustomerUlDropdown.map((i) => ({
    value: i.Cus_code,
    label: i.Cus_name,
  }));

  const [back, setback] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [ENC, setENC] = useState("");
  useEffect(() => {
    console.log(id);
    if (id !== undefined) {
      GetTableRecord({
        _keyword_: "Mould_loading_unloading_ID",
        Id: id,
      }).then(async (res) => {
        console.log("res - ", res);
        handleView(res.data[0])
        await timeout(1000);
        setback(true)
        const popupDiv = document.querySelector(".popup");
        if (popupDiv) {
          const elementInsidePopup = popupDiv.querySelector("#close-btn");
          elementInsidePopup.style.display = "none";
        }
      })
    }
  }, [id])
  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }
  const handleViewBYID = (e) => {
    setMouldLoadAndUnload({
      ...MouldLoadAndUnload,
      isPaginationVisible: false,
      PartNameLoad: e.Part_name_load,
      TrackDateLoad: e.Track_date_load,
      RawMaterialName: e.Raw_material_name,
      Shift: e.Shift,
      MCNo: e.MC_no,
      CustomerNameLoad: e.Customer_name_load_Label,
      MoldSetterLoad: e.Mold_setter_load_Label,

      EyeLiftBely: e.Eye_lift_bely,
      EjcRod: e.Ejc_rod,
      CoolValue: e.Cool_value,
      MoldPreHeat: e.Mold_pre_heat,
      ChkTheMTC: e.Chk_the_MTC,
      MldWtrOutFlw: e.Mld_wtr_out_flw,
      ChkBlushNozle: e.Chk_blush_nozle,
      ChkLocatingRing: e.Chk_locating_ring,
      LoadingTimeFrom: e.Loading_time_from,
      LoadingTimeTo: e.Loading_time_to,
      ChkCoolIntOutCondn: e.Chk_cool_int_out_condn,
      ChkNozleSizeCondn: e.Chk_nozle_size_condn,
      Butting: e.Butting,
      UseMdeOpnMold: e.Use__mde_opn_mold,
      EnsureMldClnPro: e.Ensure_mld_cln_pro,
      MldSfty1Pres: e.Mld_sfty_1_pres,
      MldSfty2Pres: e.Mld_sfty_2_pres,
      UnLoadingTimeFrom: e.Un_loading_time_from,
      UnLoadingTimeTo: e.Un_loading_time_to,
      PartNameUnload: e.Part_name_unload,
      TrackDateUnLoad: e.Track_date_unLoad,
      CustomerNameUnload: e.Customer_name_unload,
      MoldSetterUnLoad: e.Mold_setter_unLoad,
      MouldClean: e.Mould_clean,
      ApplyAniWaxCoat: e.Apply_ani_wax_coat,
      EyeBoltLiftBelt: e.Eye_bolt_lift_belt,
      MouldClsRemveWatrLne: e.Mould_cls_remve_watr_lne,
      RemoveWatrUseAir: e.Remove_watr_use_air,
      UnclampMold: e.Unclamp_mold,
      ChkLocatingCover: e.Chk_locating_cover,
      LPerM: e.L_per_m,
      MouldLocationArea: e.Mould_location_area,
      Remarks: e.Remarks_mold_unmold,
      TechnicanName: e.Technican_name_Label,
      ToolroomName: e.Toolroom_name_Label,
      ProductionEnggName: e.Production_engg_name_Label,
      UseSetMdeOpnMold: e.Use_set_mde_opn_mold,

      EyeLiftBelyRemarks: e.Eye_lift_bely_remarks,
      EjcRodRemarks: e.Ejc_rod_remarks,
      CoolValueRemarks: e.Cool_value_remarks,
      MoldPreHeatRemarks: e.Mold_pre_heat_remarks,
      ChkTheMTCRemarks: e.Chk_the_mTC_remarks,
      MldWtrOutFlwRemarks: e.Mld_wtr_out_flw_remarks,
      ChkBlushNozleRemarks: e.Chk_blush_nozle_remarks,
      ChkLocatingRingRemarks: e.Chk_locating_ring_remarks,
      ButtingRemarks: e.Butting_remarks,
      ChkNozleSizeCondnRemarks: e.Chk_nozle_size_condn_remarks,
      ChkCoolIntOutCondnRemarks: e.Chk_cool_int_out_condn_remarks,
      UseSetMdeOpnMoldRemarks: e.Use_set_mde_opn_mold_remarks,
      EnsureMldClnProRemarks: e.Ensure_mld_cln_pro_remarks,
      MldSfty1PresRemarks: e.Mld_sfty_1_pres_remarks,
      MldSfty2PresRemarks: e.Mld_sfty_2_pres_remarks,
      LPerMRemarks: e.L_per_m_remarks,
      MouldCleanRemarks: e.Mould_clean_remarks,
      ApplyAniWaxCoatRemarks: e.Apply_ani_wax_coat_remarks,
      EyeBoltLiftBeltRemarks: e.Eye_bolt_lift_belt_remarks,
      MouldClsRemveWatrLneRemarks: e.Mould_cls_remve_watr_lne_remarks,
      RemoveWatrUseAirRemarks: e.Remove_watr_use_air_remarks,
      UnclampMoldRemarks: e.Unclamp_mold_remarks,
      ChkLocatingCoverRemarks: e.Chk_locating_cover_remarks,
    });
    setButtonPopup2(true);
  };
  function GetSelectValue(value, keyword, setName) {

    if (value.length > 0) {
      ReadRecord({
        _keyword_: keyword,
        key: "%" + value + "%",
        value: "%" + value + "%"
      }).then((res) => {
        console.log(res.data);
        setName(res.data);
        // setKey(keyvalue[options.reference]);
      });
    }
  }

  function handleReadMldUnloadLoad() {
    ReadRecord({
      _keyword_: "Mould_loading_unloading"
    }).then((res) => {
      // console.log(res.data);
      setMld_load_unload(res.data);
    });
  }
  useEffect(() => {
    handleReadMldUnloadLoad();
  }, [])
  return (
    <div>
      <Popup
        trigger={ButtonPopup2}
        setTrigger={setButtonPopup2}
        viewPagination={viewPagination}
      >
        <br></br>
        {back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}
        <form
          id="MldUnloadLoad-formContainer-View"
          name="MldUnloadLoad-formContainer-View"
          className="form-MldUnloadLoad-View"
        >
          <table style={{ textAlign: "center", width: "100%" }}>
            <tbody>
              <tr>
                <th style={{ textAlign: "center", width: "15%" }}>
                  <img src={Unitechlogo} style={{ width: "80px" }}></img>
                </th>
                <th
                  id={`${idView}-Title`}
                  name={`${nameView}-Title`}
                  colSpan="6"
                  style={{
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    padding: "10px 0px",
                  }}
                >
                  <font style={{ fontSize: "24px" }}>
                  KASH Tech LLC
                  </font>
                  <br></br>
                  <font color="#8B0000">
                    Mould Loading & Un-Loading CheckList
                  </font>
                </th>
              </tr>

              <tr>
                <td className="topic-head">M/C No</td>
                <td>
                  <label id={`${idView}-MCNo`} name={`${nameView}-MCNo`}>
                    {MouldLoadAndUnload.MCNo}
                  </label>
                </td>

                <td className="topic-head">Date</td>
                <td colSpan={3}>
                  <label
                    id={`${idView}-TrackDateLoad`}
                    name={`${nameView}-TrackDateLoad`}
                  >
                    {formatDt(MouldLoadAndUnload.TrackDateLoad)}
                  </label>
                </td>
              </tr>
              <tr>
                <td className="topic-head">Part Name</td>
                <td style={{ width: "25%" }}>
                  <label
                    id={`${idView}-PartNameLoad`}
                    name={`${nameView}-PartNameLoad`}
                  >
                    {MouldLoadAndUnload.PartNameLoad}
                  </label>
                </td>


                <td className="topic-head">Shift</td>
                <td colSpan={3}>
                  <label id={`${idView}-Shift`} name={`${nameView}-Shift`}>
                    {MouldLoadAndUnload.Shift}
                  </label>
                </td>
              </tr>
              <tr>
                <td className="topic-head">Raw Material Name</td>
                <td>
                  <label
                    id={`${idView}-RawMaterialName`}
                    name={`${nameView}-RawMaterialName`}
                  >
                    {MouldLoadAndUnload.RawMaterialName}
                  </label>
                </td>


                <td className="topic-head">Customer Name</td>
                <td colSpan={3}>
                  <label
                    id={`${idView}-CustomerNameLoad`}
                    name={`${nameView}-CustomerNameLoad`}
                  >
                    {MouldLoadAndUnload.CustomerNameLoad}
                  </label>
                </td>
              </tr>
              <tr>
                <td className="topic-head" colSpan={2}>
                  Mould Setter Name
                </td>
                <td colSpan={5}>
                  <label
                    id={`${idView}-MoldSetterLoad`}
                    name={`${nameView}-MoldSetterLoad`}
                  >
                    {MouldLoadAndUnload.MoldSetterLoad}
                  </label>
                </td>
              </tr>
              <tr>
                <th
                  id={`${idView}-TitleStandByActivity`}
                  name={`${nameView}-TitleStandByActivity`}
                  colSpan={5}
                >
                  STAND BY ACTIVITY
                </th>
              </tr>
              <tr>
                <th>S.No</th>
                <th>CheckPoints</th>
                <th >Conditions</th>
                <th colSpan={2}>Remarks</th>
              </tr>
              <tr>
                <td className="topic-head-number">1</td>
                <td className="topic-head">
                  Eye Bolt & Lifting Bar & Belt Conditions
                </td>
                <td >
                  <label
                    id={`${idView}-EyeLiftBely`}
                    name={`${nameView}-EyeLiftBely`}
                  >
                    {MouldLoadAndUnload.EyeLiftBely === "Ok" ? "✓" : MouldLoadAndUnload.EyeLiftBely === "NotOk" ? "X" : "NA"}
                    {/* {MouldLoadAndUnload.EyeLiftBely} */}
                  </label>
                </td><td colSpan={2}>
                  <label
                    id={`${idView}-EyeLiftBelyRemarks`}
                    name={`${nameView}-EyeLiftBelyRemarks`}
                  >

                    {MouldLoadAndUnload.EyeLiftBelyRemarks}
                  </label>
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">2</td>
                <td className="topic-head">Check The Ejection Rod</td>
                <td >
                  <label id={`${idView}-EjcRod`} name={`${nameView}-EjcRod`}>
                    {MouldLoadAndUnload.EjcRod === "Ok" ? "✓" : MouldLoadAndUnload.EjcRod === "NotOk" ? "X" : "NA"}

                    {/* {MouldLoadAndUnload.EjcRod} */}
                  </label>
                </td><td colSpan={2}>
                  <label
                    id={`${idView}-EjcRodRemarks`}
                    name={`${nameView}-EjcRodRemarks`}
                  >
                    {MouldLoadAndUnload.EjcRodRemarks}
                  </label>
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">3</td>
                <td className="topic-head">
                  Check The Cooling Nipples & Value Condition
                </td>
                <td >
                  <label
                    id={`${idView}-CoolValue`}
                    name={`${nameView}-CoolValue`}
                  >
                    {MouldLoadAndUnload.CoolValue === "Ok" ? "✓" : MouldLoadAndUnload.CoolValue === "NotOk" ? "X" : "NA"}

                    {/* {MouldLoadAndUnload.CoolValue} */}
                  </label>
                </td><td colSpan={2}>
                  <label
                    id={`${idView}-CoolValueRemarks`}
                    name={`${nameView}-CoolValueRemarks`}
                  >
                    {MouldLoadAndUnload.CoolValueRemarks}
                  </label>
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">4</td>
                <td className="topic-head">Mold Preheat (If Required)</td>
                <td >
                  <label
                    id={`${idView}-MoldPreHeat`}
                    name={`${nameView}-MoldPreHeat`}
                  >
                    {MouldLoadAndUnload.MoldPreHeat === "Ok" ? "✓" : MouldLoadAndUnload.MoldPreHeat === "NotOk" ? "X" : "NA"}

                    {/* {MouldLoadAndUnload.MoldPreHeat} */}
                  </label>
                </td><td colSpan={2}>
                  <label
                    id={`${idView}-MoldPreHeatRemarks`}
                    name={`${nameView}-MoldPreHeatRemarks`}
                  >
                    {MouldLoadAndUnload.MoldPreHeatRemarks}
                  </label>
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">5</td>
                <td className="topic-head">Check The MTC Temperature</td>
                <td >
                  <label
                    id={`${idView}-ChkTheMTC`}
                    name={`${nameView}-ChkTheMTC`}
                  >
                    {MouldLoadAndUnload.ChkTheMTC === "Ok" ? "✓" : MouldLoadAndUnload.ChkTheMTC === "NotOk" ? "X" : "NA"}

                    {/* {MouldLoadAndUnload.ChkTheMTC} */}
                  </label>
                </td><td colSpan={2}>
                  <label
                    id={`${idView}-ChkTheMTCRemarks`}
                    name={`${nameView}-ChkTheMTCRemarks`}
                  >
                    {MouldLoadAndUnload.ChkTheMTCRemarks}
                  </label>
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">6</td>
                <td className="topic-head">
                  Check The Mould Water Outlet Flow Rate
                </td>
                <td >
                  <label
                    id={`${idView}-MldWtrOutFlw`}
                    name={`${nameView}-MldWtrOutFlw`}
                  >
                    {/* {MouldLoadAndUnload.MldWtrOutFlw} */}
                    {MouldLoadAndUnload.MldWtrOutFlw === "Ok" ? "✓" : MouldLoadAndUnload.MldWtrOutFlw === "NotOk" ? "X" : "NA"}

                  </label>
                </td><td colSpan={2}>
                  <label
                    id={`${idView}-MldWtrOutFlwRemarks`}
                    name={`${nameView}-MldWtrOutFlwRemarks`}
                  >
                    {MouldLoadAndUnload.MldWtrOutFlwRemarks}
                  </label>
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">7</td>
                <td className="topic-head">
                  Check Spure Blush & Nozzle Holes Dia
                </td>
                <td >
                  <label
                    id={`${idView}-ChkBlushNozle`}
                    name={`${nameView}-ChkBlushNozle`}
                  >
                    {/* {MouldLoadAndUnload.ChkBlushNozle} */}
                    {MouldLoadAndUnload.ChkBlushNozle === "Ok" ? "✓" : MouldLoadAndUnload.ChkBlushNozle === "NotOk" ? "X" : "NA"}

                  </label>
                </td><td colSpan={2}>
                  <label
                    id={`${idView}-ChkBlushNozleRemarks`}
                    name={`${nameView}-ChkBlushNozleRemarks`}
                  >
                    {MouldLoadAndUnload.ChkBlushNozleRemarks}
                  </label>
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">8</td>
                <td className="topic-head">
                  Check The Locating Ring (As Per STD)
                </td>
                <td >
                  <label
                    id={`${idView}-ChkLocatingRing`}
                    name={`${nameView}-ChkLocatingRing`}
                  >
                    {/* {MouldLoadAndUnload.ChkLocatingRing} */}
                    {MouldLoadAndUnload.ChkLocatingRing === "Ok" ? "✓" : MouldLoadAndUnload.ChkLocatingRing === "NotOk" ? "X" : "NA"}

                  </label>
                </td><td colSpan={2}>
                  <label
                    id={`${idView}-ChkLocatingRingRemarks`}
                    name={`${nameView}-ChkLocatingRingRemarks`}
                  >
                    {MouldLoadAndUnload.ChkLocatingRingRemarks}
                  </label>
                </td>
              </tr>
              <tr>
                <th
                  id={`${idView}-MouldLoading-Activity-Title`}
                  name={`${nameView}-MouldLoading-Activity-Title`}
                  colSpan={5}
                >
                  MOULD LOADING ACTIVITY
                </th>
              </tr>
              <tr>
                <th colSpan={2}>LOADING TIME:</th>
                <td>
                  <label
                    id={`${idView}-LoadingTimeFrom`}
                    name={`${nameView}-LoadingTimeFrom`}
                  >
                    {MouldLoadAndUnload.LoadingTimeFrom}
                  </label>
                </td>
                <th>TO</th>
                <td>
                  <label
                    id={`${idView}-LoadingTimeTo`}
                    name={`${nameView}-LoadingTimeTo`}
                  >
                    {MouldLoadAndUnload.LoadingTimeTo}
                  </label>
                </td>
              </tr>
              <tr>
                <th>S.No</th>
                <th>CheckPoints</th>
                <th >Conditions</th>
                <th colSpan={2}>Remarks</th>
              </tr>
              <tr>
                <td className="topic-head-number">1</td>
                <td className="topic-head">Butting</td>
                <td >
                  <label id={`${idView}-Butting`} name={`${nameView}-Butting`}>
                    {/* {MouldLoadAndUnload.Butting} */}
                    {MouldLoadAndUnload.Butting === "Ok" ? "✓" : MouldLoadAndUnload.Butting === "NotOk" ? "X" : "NA"}

                  </label>
                </td><td colSpan={2}>
                  <label
                    id={`${idView}-ButtingRemarks`}
                    name={`${nameView}-ButtingRemarks`}
                  >
                    {MouldLoadAndUnload.ButtingRemarks}
                  </label>
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">2</td>
                <td className="topic-head">Check Nozzle Size And Condition</td>
                <td >
                  <label
                    id={`${idView}-ChkNozleSizeCondn`}
                    name={`${nameView}-ChkNozleSizeCondn`}
                  >
                    {/* {MouldLoadAndUnload.ChkNozleSizeCondn} */}
                    {MouldLoadAndUnload.ChkNozleSizeCondn === "Ok" ? "✓" : MouldLoadAndUnload.ChkNozleSizeCondn === "NotOk" ? "X" : "NA"}

                  </label>
                </td><td colSpan={2}>
                  <label
                    id={`${idView}-ChkNozleSizeCondnRemarks`}
                    name={`${nameView}-ChkNozleSizeCondnRemarks`}
                  >
                    {MouldLoadAndUnload.ChkNozleSizeCondnRemarks}
                  </label>
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">3</td>
                <td className="topic-head">
                  Check The Cooling Inlet/Outlet Condition
                </td>
                <td >
                  <label
                    id={`${idView}-ChkCoolIntOutCondn`}
                    name={`${nameView}-ChkCoolIntOutCondn`}
                  >
                    {/* {MouldLoadAndUnload.ChkCoolIntOutCondn} */}
                    {MouldLoadAndUnload.ChkCoolIntOutCondn === "Ok" ? "✓" : MouldLoadAndUnload.ChkCoolIntOutCondn === "NotOk" ? "X" : "NA"}

                  </label>
                </td><td colSpan={2}>
                  <label
                    id={`${idView}-ChkCoolIntOutCondnRemarks`}
                    name={`${nameView}-ChkCoolIntOutCondnRemarks`}
                  >
                    {MouldLoadAndUnload.ChkCoolIntOutCondnRemarks}
                  </label>
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">4</td>
                <td className="topic-head">Use Setting Mode Open The Mold</td>
                <td >
                  <label
                    id={`${idView}-UseSetMdeOpnMold`}
                    name={`${nameView}-UseSetMdeOpnMold`}
                  >
                    {/* {MouldLoadAndUnload.UseSetMdeOpnMold} */}
                    {MouldLoadAndUnload.UseSetMdeOpnMold === "Ok" ? "✓" : MouldLoadAndUnload.UseSetMdeOpnMold === "NotOk" ? "X" : "NA"}

                  </label>
                </td><td colSpan={2}>
                  <label
                    id={`${idView}-UseSetMdeOpnMoldRemarks`}
                    name={`${nameView}-UseSetMdeOpnMoldRemarks`}
                  >
                    {MouldLoadAndUnload.UseSetMdeOpnMoldRemarks}
                  </label>
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">5</td>
                <td className="topic-head">Ensure Mold Cleaning Process</td>
                <td >
                  <label
                    id={`${idView}-EnsureMldClnPro`}
                    name={`${nameView}-EnsureMldClnPro`}
                  >
                    {/* {MouldLoadAndUnload.EnsureMldClnPro} */}
                    {MouldLoadAndUnload.EnsureMldClnPro === "Ok" ? "✓" : MouldLoadAndUnload.EnsureMldClnPro === "NotOk" ? "X" : "NA"}

                  </label>
                </td><td colSpan={2}>
                  <label
                    id={`${idView}-EnsureMldClnProRemarks`}
                    name={`${nameView}-EnsureMldClnProRemarks`}
                  >
                    {MouldLoadAndUnload.EnsureMldClnProRemarks}
                  </label>
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">6</td>
                <td className="topic-head">Mold Safety 1 Pressure</td>
                <td >
                  <label
                    id={`${idView}-MldSfty1Pres`}
                    name={`${nameView}-MldSfty1Pres`}
                  >
                    {/* {MouldLoadAndUnload.MldSfty1Pres} */}
                    {MouldLoadAndUnload.MldSfty1Pres === "Ok" ? "✓" : MouldLoadAndUnload.MldSfty1Pres === "NotOk" ? "X" : "NA"}

                  </label>
                </td><td colSpan={2}>
                  <label
                    id={`${idView}-MldSfty1PresRemarks`}
                    name={`${nameView}-MldSfty1PresRemarks`}
                  >
                    {MouldLoadAndUnload.MldSfty1PresRemarks}
                  </label>
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">7</td>
                <td className="topic-head">Mold Safety 2 Pressure</td>
                <td >
                  <label
                    id={`${idView}-MldSfty2Pres`}
                    name={`${nameView}-MldSfty2Pres`}
                  >
                    {/* {MouldLoadAndUnload.MldSfty2Pres} */}
                    {MouldLoadAndUnload.MldSfty2Pres === "Ok" ? "✓" : MouldLoadAndUnload.MldSfty2Pres === "NotOk" ? "X" : "NA"}

                  </label>
                </td><td colSpan={2}>
                  <label
                    id={`${idView}-MldSfty2PresRemarks`}
                    name={`${nameView}-MldSfty2PresRemarks`}
                  >
                    {MouldLoadAndUnload.MldSfty2PresRemarks}
                  </label>
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">8</td>
                <td className="topic-head">LPM</td>
                <td >
                  <label id={`${idView}-LPerM`} name={`${nameView}-LPerM`}>
                    {/* {MouldLoadAndUnload.LPerM} */}
                    {MouldLoadAndUnload.LPerM === "Ok" ? "✓" : MouldLoadAndUnload.LPerM === "NotOk" ? "X" : "NA"}

                  </label>
                </td><td colSpan={2}>
                  <label
                    id={`${idView}-LPerMRemarks`}
                    name={`${nameView}-LPerMRemarks`}
                  >
                    {MouldLoadAndUnload.LPerMRemarks}
                  </label>
                </td>
              </tr>
              <tr>
                <th
                  id={`${idView}-MouldUnloadingActivityTitle`}
                  name={`${nameView}-MouldUnloadingActivityTitle`}
                  colSpan={5}
                >
                  MOULD UNLOADING ACTIVITY
                </th>
              </tr>
              <tr>
                <th colSpan={2}>UN LOADING TIME:</th>
                <td>
                  <label
                    id={`${idView}-UnLoadingTimeFrom`}
                    name={`${nameView}-UnLoadingTimeFrom`}
                  >
                    {MouldLoadAndUnload.UnLoadingTimeFrom}
                  </label>
                </td>
                <th>TO</th>
                <td>
                  <label
                    id={`${idView}-UnLoadingTimeTo`}
                    name={`${nameView}-UnLoadingTimeTo`}
                  >
                    {MouldLoadAndUnload.UnLoadingTimeTo}
                  </label>
                </td>
              </tr>
              <tr>
                <td className="topic-head">Part Name</td>
                <td>
                  <label
                    id={`${idView}-PartNameUnload`}
                    name={`${nameView}-PartNameUnload`}
                  >
                    {MouldLoadAndUnload.PartNameUnload}
                  </label>
                </td>
                <td className="topic-head">Date</td>
                <td colSpan={2}>
                  <label
                    id={`${idView}-TrackDateUnLoad`}
                    name={`${nameView}-TrackDateUnLoad`}
                  >
                    {MouldLoadAndUnload.TrackDateUnLoad ? formatDt(MouldLoadAndUnload.TrackDateUnLoad) : ""}
                  </label>
                </td>
              </tr>
              <tr>
                <td className="topic-head">Customer</td>
                <td>
                  <label
                    id={`${idView}-CustomerNameUnload`}
                    name={`${nameView}-CustomerNameUnload`}
                  >
                    {MouldLoadAndUnload.CustomerNameUnload}
                  </label>
                </td>

                <td className="topic-head">Mould Setter Name</td>
                <td colSpan={2}>
                  <label
                    id={`${idView}-MoldSetterUnLoad`}
                    name={`${nameView}-MoldSetterUnLoad`}
                  >
                    {MouldLoadAndUnload.MoldSetterUnLoad}
                  </label>
                </td>
              </tr>
              <tr>
                <th>S.No</th>
                <th>CheckPoints</th>
                <th >Conditions</th>
                <th colSpan={2}>Remarks</th>
              </tr>
              <tr>
                <td className="topic-head-number">1</td>
                <td className="topic-head">Mould Cleaning</td>
                <td >
                  <label
                    id={`${idView}-MouldClean`}
                    name={`${nameView}-MouldClean`}
                  >
                    {/* {MouldLoadAndUnload.MouldClean} */}
                    {MouldLoadAndUnload.MouldClean === "Ok" ? "✓" : MouldLoadAndUnload.MouldClean === "NotOk" ? "X" : "NA"}

                  </label>
                </td><td colSpan={2}>
                  <label
                    id={`${idView}-MouldCleanRemarks`}
                    name={`${nameView}-MouldCleanRemarks`}
                  >
                    {MouldLoadAndUnload.MouldCleanRemarks}
                  </label>
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">2</td>
                <td className="topic-head">Applying Anti Wax Coating</td>
                <td >
                  <label
                    id={`${idView}-ApplyAniWaxCoat`}
                    name={`${nameView}-ApplyAniWaxCoat`}
                  >
                    {/* {MouldLoadAndUnload.ApplyAniWaxCoat} */}
                    {MouldLoadAndUnload.ApplyAniWaxCoat === "Ok" ? "✓" : MouldLoadAndUnload.ApplyAniWaxCoat === "NotOk" ? "X" : "NA"}

                  </label>
                </td><td colSpan={2}>
                  <label
                    id={`${idView}-ApplyAniWaxCoatRemarks`}
                    name={`${nameView}-ApplyAniWaxCoatRemarks`}
                  >
                    {MouldLoadAndUnload.ApplyAniWaxCoatRemarks}
                  </label>
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">3</td>
                <td className="topic-head">
                  Eye Bolt & Lifting Bar & Belt Condition
                </td>
                <td >
                  <label
                    id={`${idView}-EyeBoltLiftBelt`}
                    name={`${nameView}-EyeBoltLiftBelt`}
                  >
                    {/* {MouldLoadAndUnload.EyeBoltLiftBelt} */}
                    {MouldLoadAndUnload.EyeBoltLiftBelt === "Ok" ? "✓" : MouldLoadAndUnload.EyeBoltLiftBelt === "NotOk" ? "X" : "NA"}

                  </label>
                </td><td colSpan={2}>
                  <label
                    id={`${idView}-EyeBoltLiftBeltRemarks`}
                    name={`${nameView}-EyeBoltLiftBeltRemarks`}
                  >
                    {MouldLoadAndUnload.EyeBoltLiftBeltRemarks}
                  </label>
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">4</td>
                <td className="topic-head">
                  Mould Closing & Removing Water Line
                </td>
                <td >
                  <label
                    id={`${idView}-MouldClsRemveWatrLne`}
                    name={`${nameView}-MouldClsRemveWatrLne`}
                  >
                    {/* {MouldLoadAndUnload.MouldClsRemveWatrLne} */}
                    {MouldLoadAndUnload.MouldClsRemveWatrLne === "Ok" ? "✓" : MouldLoadAndUnload.MouldClsRemveWatrLne === "NotOk" ? "X" : "NA"}

                  </label>
                </td><td colSpan={2}>
                  <label
                    id={`${idView}-MouldClsRemveWatrLneRemarks`}
                    name={`${nameView}-MouldClsRemveWatrLneRemarks`}
                  >
                    {MouldLoadAndUnload.MouldClsRemveWatrLneRemarks}
                  </label>
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">5</td>
                <td className="topic-head">Removing Water I Use Air</td>
                <td >
                  <label
                    id={`${idView}-RemoveWatrUseAir`}
                    name={`${nameView}-RemoveWatrUseAir`}
                  >
                    {/* {MouldLoadAndUnload.RemoveWatrUseAir} */}
                    {MouldLoadAndUnload.RemoveWatrUseAir === "Ok" ? "✓" : MouldLoadAndUnload.RemoveWatrUseAir === "NotOk" ? "X" : "NA"}

                  </label>
                </td><td colSpan={2}>
                  <label
                    id={`${idView}-RemoveWatrUseAirRemarks`}
                    name={`${nameView}-RemoveWatrUseAirRemarks`}
                  >
                    {MouldLoadAndUnload.RemoveWatrUseAirRemarks}
                  </label>
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">6</td>
                <td className="topic-head">Unclamp The Mold</td>
                <td >
                  <label
                    id={`${idView}-UnclampMold`}
                    name={`${nameView}-UnclampMold`}
                  >
                    {/* {MouldLoadAndUnload.UnclampMold} */}
                    {MouldLoadAndUnload.UnclampMold === "Ok" ? "✓" : MouldLoadAndUnload.UnclampMold === "NotOk" ? "X" : "NA"}

                  </label>
                </td><td colSpan={2}>
                  <label
                    id={`${idView}-UnclampMoldRemarks`}
                    name={`${nameView}-UnclampMoldRemarks`}
                  >
                    {MouldLoadAndUnload.UnclampMoldRemarks}
                  </label>
                </td>
              </tr>
              <tr>
                <td className="topic-head-number">7</td>
                <td className="topic-head">Check The Locating Cover</td>
                <td >
                  <label
                    id={`${idView}-ChkLocatingCover`}
                    name={`${nameView}-ChkLocatingCover`}
                  >
                    {/* {MouldLoadAndUnload.ChkLocatingCover} */}
                    {MouldLoadAndUnload.ChkLocatingCover === "Ok" ? "✓" : MouldLoadAndUnload.ChkLocatingCover === "NotOk" ? "X" : "NA"}

                  </label>
                </td><td colSpan={2}>
                  <label
                    id={`${idView}-ChkLocatingCoverRemarks`}
                    name={`${nameView}-ChkLocatingCoverRemarks`}
                  >
                    {MouldLoadAndUnload.ChkLocatingCoverRemarks}
                  </label>
                </td>
              </tr>

              <tr>
                <th
                  id={`${idView}-MouldLocationIdentityActivityTitle`}
                  name={`${nameView}-MouldLocationIdentityActivityTitle`}
                  colSpan={5}
                >
                  MOULD LOCATION IDENTIFY ACTIVITY
                </th>
              </tr>
              <tr>
                <th>S.No</th>
                <th>CheckPoints</th>
                <th colSpan={3}>Location No</th>
              </tr>
              <tr>
                <td className="topic-head-number">1</td>
                <td className="topic-head">Mould Location Area</td>
                <td colSpan={3}>
                  <label
                    id={`${idView}-MouldLocationArea`}
                    name={`${nameView}-MouldLocationArea`}
                  >
                    {MouldLoadAndUnload.MouldLocationArea}
                  </label>

                </td>
              </tr>
              <tr>
                <td className="topic-head">Remarks</td>
                <td colSpan={4}>
                  <label id={`${idView}-Remarks`} name={`${nameView}-Remarks`}>
                    {MouldLoadAndUnload.Remarks}
                  </label>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="topic-head">
                  Technican
                  <br></br>
                  <br></br>
                  <label
                    id={`${idView}-TechnicanName`}
                    name={`${nameView}-TechnicanName`}
                  >
                    {MouldLoadAndUnload.TechnicanName}
                  </label>
                </td>

                <td className="topic-head">
                  Toolroom
                  <br></br>
                  <br></br>
                  <label
                    id={`${idView}-ToolroomName`}
                    name={`${nameView}-ToolroomName`}
                  >
                    {MouldLoadAndUnload.ToolroomName}
                  </label>
                </td>

                <td colSpan={2} className="topic-head">
                  production Engg
                  <br></br>
                  <br></br>
                  <label
                    id={`${idView}-ProductionEnggName`}
                    name={`${nameView}-ProductionEnggName`}
                  >
                    {MouldLoadAndUnload.ProductionEnggName}
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </Popup>
      <Popup
        trigger={ButtonPopup}
        setTrigger={setButtonPopup}
        viewPagination={viewPagination}
      >

        <br></br>
        <br></br>
        <form
          id="MldUnloadLoad-formContainer-Edit"
          name="MldUnloadLoad-formContainer-Edit"
          className="form-MldUnloadLoad-Edit"
        >
          <div ref={scrollTop}>
            <table style={{ textAlign: "center", width: "100%" }}>
              <tbody>
                <tr>
                  <th style={{ textAlign: "center", width: "15%" }}>
                    <img src={Unitechlogo} style={{ width: "80px" }}></img>
                  </th>
                  <th
                    id={`${idEdit}-Title`}
                    name={`${nameEdit}-Title`}
                    colSpan="6"
                    style={{
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      padding: "10px 0px",
                    }}
                  >
                    <font style={{ fontSize: "24px" }}>
                    KASH Tech LLC
                    </font>
                    <br></br>
                    <font color="#8B0000">
                      Mould Loading & Un-Loading CheckList
                    </font>
                  </th>
                </tr>

                <tr>
                  <td className="topic-head">
                    M/C No<span style={{ color: "red" }}>*</span>
                  </td>
                  <td>
                    {/* <input
                  id={`${idEdit}-MCNo`}
                  name={`${nameEdit}-MCNo`}
                  type="text"
                  value={MouldLoadAndUnload.MCNo}
                  onChange={(e) =>
                    setMouldLoadAndUnload({
                      ...MouldLoadAndUnload,
                      MCNo: e.target.value,
                    })
                  }
                /> */}

                    <DropdownFetcher keyword="mac_dtl_dropdown" setValue={setDropdown} />
                    <DropdownFilterSelect
                      value={MouldLoadAndUnload.MCNo}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          MCNo: e.value,
                        })
                      }
                      options={mcNoOptions}
                      placeholder="Select Machine No"
                      isDisabled={true}
                    />
                  </td>

                  <td className="topic-head">Date</td>
                  <td colSpan={3}>
                    <DatePicker
                      value={MouldLoadAndUnload.TrackDateLoad}
                      onChange={handleDateChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head">
                    Part Name<span style={{ color: "red" }}>*</span>
                  </td>
                  <td style={{ width: "25%" }}>
                    {/* <input
                id={`${idEdit}-PartNameLoad`}
                name={`${nameEdit}-PartNameLoad`}
                  type="text"
                  value={MouldLoadAndUnload.PartNameLoad}
                  onChange={(e) => setPartNameLoad(e.target.value)}
                  
                /> */}
                    {/* <DropdownFetcher keyword="mac_part_dtl_dropdown" setValue={setPartDropdown} /> */}
                    {/* <DropdownFilterSelect dropdown={partDropdown} optionLabel="mc_part_name" optionValueCode="mc_part_code" optionIdProp="Part name" setValue={setPartNameLoad} values={PartNameLoad}/> */}
                    {/* <DropdownSelect text="Select Part Name"  dropdown={partDropdown} value={MouldLoadAndUnload.PartNameLoad} optionIdProp="mc_part_code" optionNameProp="mc_part_name" onChange={(e) => setPartNameLoad(e.target.value)} /> */}
                    <DropdownFetcher
                      keyword="mac_part_dtl_dropdown"
                      setValue={setPartDropdown}
                    />

                    <DropdownFilterSelect
                      id={`${idEdit}-PartNameLoad`}
                      name={`${nameEdit}-PartNameLoad`}
                      value={MouldLoadAndUnload.PartNameLoad}
                      // onChange={handleDropdownChangeLoad}
                      options={partNoOptions}
                      placeholder="Select Part Name"
                      isDisabled={true}
                    />
                  </td>


                  <td className="topic-head">Shift</td>
                  <td colSpan={3}>
                    {/* <input
                  id={`${idEdit}-Shift`}
                  name={`${nameEdit}-Shift`}
                  type="text"
                  value={MouldLoadAndUnload.Shift}
                  onChange={(e) =>
                    setMouldLoadAndUnload({
                      ...MouldLoadAndUnload,
                      Shift: e.target.value,
                    })
                  }
                /> */}
                    <ShiftDropdownown
                      id={idEdit}
                      name={nameEdit}
                      value={MouldLoadAndUnload.Shift}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          Shift: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head">
                    Raw Material Name<span style={{ color: "red" }}>*</span>
                  </td>
                  <td>
                    {/* <input
                  id={`${idEdit}-RawMaterialName`}
                  name={`${nameEdit}-RawMaterialName`}
                  type="text"
                  value={MouldLoadAndUnload.RawMaterialName}
                  onChange={(e) =>
                    setMouldLoadAndUnload({
                      ...MouldLoadAndUnload,
                      RawMaterialName: e.target.value,
                    })
                  }
                /> */}
                    {/* <DropdownFetcher
                      keyword="rm_details_dropdown"
                      setValue={setRmDropdown}
                    /> */}
                    <DropdownFilterSelect
                      value={MouldLoadAndUnload.RawMaterialName}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          RawMaterialName: e.value,
                        })
                      }
                      options={rmOptions}
                      placeholder="Search RM..."
                      isDisabled={true}
                      onInputChange={(event) => { GetSelectValue(event, "rm_details_dropdown", setRmDropdown) }}

                    />
                  </td>


                  <td className="topic-head">
                    Customer Name<span style={{ color: "red" }}>*</span>
                  </td>
                  <td colSpan={3}>
                    <DropdownFetcher
                      keyword="customer_details"
                      setValue={setCustomerDropdown}
                    />
                    <Select
                      value={MouldLoadAndUnload.CustomerNameLoad}
                      onChange={handleCustomerload}
                      options={customerOptions}
                      placeholder="Select Customer Name"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head" colSpan={2}>
                    Mould Setter Name
                  </td>
                  <td colSpan={5}>
                    <DropdownFetcher
                      keyword="emp_details_moldsetter"
                      setValue={setMoldsetterDropdown}
                    />
                    <Select
                      value={MouldLoadAndUnload.MoldSetterLoad}
                      onChange={handleMoldSetterload}
                      options={MoldsetterOptions}
                      placeholder="Select Mould Setter Name"
                    />
                    {/* <Select
  id={`${idEdit}-MoldSetterLoad`}
  name={`${nameEdit}-MoldSetterLoad`}
  value={getSelectedValue(empAllOptions, MouldLoadAndUnload.MoldSetterLoad)}
  onChange={(selectedOption) => handleSelectChange(selectedOption, 'MoldSetterLoad')}
  options={empAllOptions}
  placeholder="Select Mould Setter Name"
/> */}
                    {/* <Select
  id={`${idEdit}-MoldSetterLoad`}
  name={`${nameEdit}-MoldSetterLoad`}
  value={
    MouldLoadAndUnload.MoldSetterLoad
      ? {
          value: MouldLoadAndUnload.MoldSetterLoad,
          label: MouldLoadAndUnload.MoldSetterLoad,          
        }
      : null
  }
  onChange={(selectedOption) =>
    setMouldLoadAndUnload({
      ...MouldLoadAndUnload,
      MoldSetterLoad: selectedOption ? selectedOption.value : null,
    })
  }
  options={empAllOptions}
  placeholder="Select Mould Setter Name"
/> */}

                    {/* <input
                  id={`${idEdit}-MoldSetterLoad`}
                  name={`${nameEdit}-MoldSetterLoad`}
                  type="text"
                  value={MouldLoadAndUnload.MoldSetterLoad}
                  onChange={(e) =>
                    setMouldLoadAndUnload({
                      ...MouldLoadAndUnload,
                      MoldSetterLoad: e.target.value,
                    })
                  }
                /> */}
                  </td>
                </tr>
                <tr>
                  <th
                    id={`${idEdit}-TitleStandByActivity`}
                    name={`${nameEdit}-TitleStandByActivity`}
                    colSpan={5}
                  >
                    STAND BY ACTIVITY
                  </th>
                </tr>
                <tr>
                  <th>S.No</th>
                  <th>CheckPoints</th>
                  <th >Conditions</th>
                  <th colSpan={2}>Remarks</th>
                </tr>
                <tr>
                  <td className="topic-head-number">1</td>
                  <td className="topic-head">
                    Eye Bolt & Lifting Bar & Belt Conditions
                  </td>
                  <td>
                    <OkNotOkayFetch
                      id={`${idEdit}-EyeLiftBely`}
                      name={`${nameEdit}-EyeLiftBely`}
                      Value={MouldLoadAndUnload.EyeLiftBely}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          EyeLiftBely: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <textarea
                      id={`${idEdit}-EyeLiftBelyRemarks`}
                      name={`${nameEdit}-EyeLiftBelyRemarks`}
                      type="text"
                      value={MouldLoadAndUnload.EyeLiftBelyRemarks}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          EyeLiftBelyRemarks: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head-number">2</td>
                  <td className="topic-head">Check The Ejection Rod</td>
                  <td>
                    <OkNotOkayFetch
                      id={`${idEdit}-EjcRod`}
                      name={`${nameEdit}-EjcRod`}
                      Value={MouldLoadAndUnload.EjcRod}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          EjcRod: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <textarea
                      id={`${idEdit}-EjcRodRemarks`}
                      name={`${nameEdit}-EjcRodRemarks`}
                      type="text"
                      value={MouldLoadAndUnload.EjcRodRemarks}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          EjcRodRemarks: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head-number">3</td>
                  <td className="topic-head">
                    Check The Cooling Nipples & Value Condition
                  </td>
                  <td>
                    <OkNotOkayFetch
                      id={`${idEdit}-CoolValue`}
                      name={`${nameEdit}-CoolValue`}
                      Value={MouldLoadAndUnload.CoolValue}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          CoolValue: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <textarea
                      id={`${idEdit}-CoolValueRemarks`}
                      name={`${nameEdit}-CoolValueRemarks`}
                      type="text"
                      value={MouldLoadAndUnload.CoolValueRemarks}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          CoolValueRemarks: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head-number">4</td>
                  <td className="topic-head">Mold Preheat (If Required)</td>
                  <td>
                    <OkNotOkayFetch
                      id={`${idEdit}-MoldPreHeat`}
                      name={`${nameEdit}-MoldPreHeat`}
                      Value={MouldLoadAndUnload.MoldPreHeat}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          MoldPreHeat: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <textarea
                      id={`${idEdit}-MoldPreHeatRemarks`}
                      name={`${nameEdit}-MoldPreHeatRemarks`}
                      type="text"
                      value={MouldLoadAndUnload.MoldPreHeatRemarks}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          MoldPreHeatRemarks: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head-number">5</td>
                  <td className="topic-head">Check The MTC Temperature</td>
                  <td>
                    <OkNotOkayFetch
                      id={`${idEdit}-ChkTheMTC`}
                      name={`${nameEdit}-ChkTheMTC`}
                      Value={MouldLoadAndUnload.ChkTheMTC}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          ChkTheMTC: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <textarea
                      id={`${idEdit}-ChkTheMTCRemarks`}
                      name={`${nameEdit}-ChkTheMTCRemarks`}
                      type="text"
                      value={MouldLoadAndUnload.ChkTheMTCRemarks}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          ChkTheMTCRemarks: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head-number">6</td>
                  <td className="topic-head">
                    Check The Mould Water Outlet Flow Rate
                  </td>
                  <td>
                    <OkNotOkayFetch
                      id={`${idEdit}-MldWtrOutFlw`}
                      name={`${nameEdit}-MldWtrOutFlw`}
                      Value={MouldLoadAndUnload.MldWtrOutFlw}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          MldWtrOutFlw: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <textarea
                      id={`${idEdit}-MldWtrOutFlwRemarks`}
                      name={`${nameEdit}-MldWtrOutFlwRemarks`}
                      type="text"
                      value={MouldLoadAndUnload.MldWtrOutFlwRemarks}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          MldWtrOutFlwRemarks: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head-number">7</td>
                  <td className="topic-head">
                    Check Spure Blush & Nozzle Holes Dia
                  </td>
                  <td>
                    <OkNotOkayFetch
                      id={`${idEdit}-ChkBlushNozle`}
                      name={`${nameEdit}-ChkBlushNozle`}
                      Value={MouldLoadAndUnload.ChkBlushNozle}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          ChkBlushNozle: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <textarea
                      id={`${idEdit}-ChkBlushNozleRemarks`}
                      name={`${nameEdit}-ChkBlushNozleRemarks`}
                      type="text"
                      value={MouldLoadAndUnload.ChkBlushNozleRemarks}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          ChkBlushNozleRemarks: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head-number">8</td>
                  <td className="topic-head">
                    Check The Locating Ring (As Per STD)
                  </td>
                  <td>
                    <OkNotOkayFetch
                      id={`${idEdit}-ChkLocatingRing`}
                      name={`${nameEdit}-ChkLocatingRing`}
                      Value={MouldLoadAndUnload.ChkLocatingRing}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          ChkLocatingRing: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <textarea
                      id={`${idEdit}-ChkLocatingRingRemarks`}
                      name={`${nameEdit}-ChkLocatingRingRemarks`}
                      type="text"
                      value={MouldLoadAndUnload.ChkLocatingRingRemarks}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          ChkLocatingRingRemarks: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <th
                    id={`${idEdit}-MouldLoading-Activity-Title`}
                    name={`${nameEdit}-MouldLoading-Activity-Title`}
                    colSpan={5}
                  >
                    MOULD LOADING ACTIVITY
                  </th>
                </tr>
                <tr>
                  <th colSpan={2}>LOADING TIME:</th>
                  <td>
                    <input
                      id={`${idEdit}-LoadingTimeFrom`}
                      name={`${nameEdit}-LoadingTimeFrom`}
                      type="time"
                      value={MouldLoadAndUnload.LoadingTimeFrom}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          LoadingTimeFrom: e.target.value,
                        })
                      }
                    />
                  </td>
                  <th>TO</th>
                  <td>
                    <input
                      id={`${idEdit}-LoadingTimeTo`}
                      name={`${nameEdit}-LoadingTimeTo`}
                      type="time"
                      value={MouldLoadAndUnload.LoadingTimeTo}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          LoadingTimeTo: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <th>S.No</th>
                  <th>CheckPoints</th>
                  <th >Conditions</th>
                  <th colSpan={2}>Remarks</th>
                </tr>
                <tr>
                  <td className="topic-head-number">1</td>
                  <td className="topic-head">Butting</td>
                  <td>
                    <OkNotOkayFetch
                      id={`${idEdit}-Butting`}
                      name={`${nameEdit}-Butting`}
                      Value={MouldLoadAndUnload.Butting}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          Butting: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <textarea
                      id={`${idEdit}-ButtingRemarks`}
                      name={`${nameEdit}-ButtingRemarks`}
                      type="text"
                      value={MouldLoadAndUnload.ButtingRemarks}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          ButtingRemarks: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head-number">2</td>
                  <td className="topic-head">Check Nozzle Size And Condition</td>
                  <td>
                    <OkNotOkayFetch
                      id={`${idEdit}-ChkNozleSizeCondn`}
                      name={`${nameEdit}-ChkNozleSizeCondn`}
                      Value={MouldLoadAndUnload.ChkNozleSizeCondn}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          ChkNozleSizeCondn: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <textarea
                      id={`${idEdit}-ChkNozleSizeCondnRemarks`}
                      name={`${nameEdit}-ChkNozleSizeCondnRemarks`}
                      type="text"
                      value={MouldLoadAndUnload.ChkNozleSizeCondnRemarks}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          ChkNozleSizeCondnRemarks: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head-number">3</td>
                  <td className="topic-head">
                    Check The Cooling Inlet/Outlet Condition
                  </td>
                  <td>
                    <OkNotOkayFetch
                      id={`${idEdit}-ChkCoolIntOutCondn`}
                      name={`${nameEdit}-ChkCoolIntOutCondn`}
                      Value={MouldLoadAndUnload.ChkCoolIntOutCondn}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          ChkCoolIntOutCondn: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <textarea
                      id={`${idEdit}-ChkCoolIntOutCondnRemarks`}
                      name={`${nameEdit}-ChkCoolIntOutCondnRemarks`}
                      type="text"
                      value={MouldLoadAndUnload.ChkCoolIntOutCondnRemarks}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          ChkCoolIntOutCondnRemarks: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head-number">4</td>
                  <td className="topic-head">Use Setting Mode Open The Mold</td>
                  <td>
                    <OkNotOkayFetch
                      id={`${idEdit}-UseSetMdeOpnMold`}
                      name={`${nameEdit}-UseSetMdeOpnMold`}
                      Value={MouldLoadAndUnload.UseSetMdeOpnMold}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          UseSetMdeOpnMold: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <textarea
                      id={`${idEdit}-UseSetMdeOpnMoldRemarks`}
                      name={`${nameEdit}-UseSetMdeOpnMoldRemarks`}
                      type="text"
                      value={MouldLoadAndUnload.UseSetMdeOpnMoldRemarks}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          UseSetMdeOpnMoldRemarks: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head-number">5</td>
                  <td className="topic-head">Ensure Mold Cleaning Process</td>
                  <td>
                    <OkNotOkayFetch
                      id={`${idEdit}-EnsureMldClnPro`}
                      name={`${nameEdit}-EnsureMldClnPro`}
                      Value={MouldLoadAndUnload.EnsureMldClnPro}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          EnsureMldClnPro: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <textarea
                      id={`${idEdit}-EnsureMldClnProRemarks`}
                      name={`${nameEdit}-EnsureMldClnProRemarks`}
                      type="text"
                      value={MouldLoadAndUnload.EnsureMldClnProRemarks}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          EnsureMldClnProRemarks: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head-number">6</td>
                  <td className="topic-head">Mold Safety 1 Pressure</td>
                  <td>
                    <OkNotOkayFetch
                      id={`${idEdit}-MldSfty1Pres`}
                      name={`${nameEdit}-MldSfty1Pres`}
                      Value={MouldLoadAndUnload.MldSfty1Pres}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          MldSfty1Pres: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <textarea
                      id={`${idEdit}-MldSfty1PresRemarks`}
                      name={`${nameEdit}-MldSfty1PresRemarks`}
                      type="text"
                      value={MouldLoadAndUnload.MldSfty1PresRemarks}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          MldSfty1PresRemarks: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head-number">7</td>
                  <td className="topic-head">Mold Safety 2 Pressure</td>
                  <td>
                    <OkNotOkayFetch
                      id={`${idEdit}-MldSfty2Pres`}
                      name={`${nameEdit}-MldSfty2Pres`}
                      Value={MouldLoadAndUnload.MldSfty2Pres}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          MldSfty2Pres: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <textarea
                      id={`${idEdit}-MldSfty2PresRemarks`}
                      name={`${nameEdit}-MldSfty2PresRemarks`}
                      type="text"
                      value={MouldLoadAndUnload.MldSfty2PresRemarks}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          MldSfty2PresRemarks: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head-number">8</td>
                  <td className="topic-head">LPM</td>
                  <td>
                    <OkNotOkayFetch
                      id={`${idEdit}-LPerM`}
                      name={`${nameEdit}-LPerM`}
                      Value={MouldLoadAndUnload.LPerM}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          LPerM: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <textarea
                      id={`${idEdit}-LPerMRemarks`}
                      name={`${nameEdit}-LPerMRemarks`}
                      type="text"
                      value={MouldLoadAndUnload.LPerMRemarks}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          LPerMRemarks: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <th
                    id={`${idEdit}-MouldUnloadingActivityTitle`}
                    name={`${nameEdit}-MouldUnloadingActivityTitle`}
                    colSpan={5}
                  >
                    MOULD UNLOADING ACTIVITY
                  </th>
                </tr>
                <tr>
                  <th colSpan={2}>UN LOADING TIME:</th>
                  <td>
                    <input
                      id={`${idEdit}-UnLoadingTimeFrom`}
                      name={`${nameEdit}-UnLoadingTimeFrom`}
                      type="time"
                      value={MouldLoadAndUnload.UnLoadingTimeFrom}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          UnLoadingTimeFrom: e.target.value,
                        })
                      }
                    />
                  </td>
                  <th>TO</th>
                  <td>
                    <input
                      id={`${idEdit}-UnLoadingTimeTo`}
                      name={`${nameEdit}-UnLoadingTimeTo`}
                      type="time"
                      value={MouldLoadAndUnload.UnLoadingTimeTo}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          UnLoadingTimeTo: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head">Part Name</td>
                  <td>
                    {/* <input
                  id={`${idEdit}-PartNameUnload`}
                  name={`${nameEdit}-PartNameUnload`}
                  type="text"
                  value={MouldLoadAndUnload.PartNameUnload}
                  onChange={(e) =>
                    setMouldLoadAndUnload({
                      ...MouldLoadAndUnload,
                      PartNameUnload: e.target.value,
                    })
                  }                 /> */}
                    {/* <DropdownFetcher
                  keyword="mac_part_dtl_dropdown"
                  setValue={setPartDropdown}
                /> */}

                    <DropdownFilterSelect
                      value={MouldLoadAndUnload.PartNameUnload}
                      onChange={handleDropdownChangeUnLoad}
                      options={partNoOptions}
                      placeholder="Select Part Name"
                      isDisabled={true}
                    />
                  </td>
                  <td className="topic-head">Date</td>
                  <td colSpan={2}>
                    <DatePicker
                      value={MouldLoadAndUnload.TrackDateUnLoad}
                      onChange={handleDateChange1}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head">Customer</td>
                  <td>
                    <DropdownFetcher
                      keyword="customer_details"
                      setValue={setCustomerUlDropdown}
                    />
                    <Select
                      value={MouldLoadAndUnload.CustomerNameUnload}
                      onChange={handleCustomerunload}
                      options={customerOptionsUl}
                      placeholder="Select Customer Name"
                    />
                  </td>

                  <td className="topic-head">Mould Setter Name</td>
                  <td colSpan={2}>
                    <Select
                      id={`${idEdit}-MoldSetterLoad`}
                      name={`${nameEdit}-MoldSetterLoad`}
                      value={MouldLoadAndUnload.MoldSetterUnLoad}
                      onChange={handleMoldSetterunload}

                      options={MoldsetterOptions}
                      placeholder="Select Mould Setter Name"
                    />
                    {/* <input
                  id={`${idEdit}-MoldSetterUnLoad`}
                  name={`${nameEdit}-MoldSetterUnLoad`}
                  type="text"
                  value={MouldLoadAndUnload.MoldSetterUnLoad}
                  onChange={(e) =>
                    setMouldLoadAndUnload({
                      ...MouldLoadAndUnload,
                      MoldSetterUnLoad: e.target.value,
                    })
                  }
                /> */}
                  </td>
                </tr>
                <tr>
                  <th>S.No</th>
                  <th>CheckPoints</th>
                  <th >Conditions</th>
                  <th colSpan={2}>Remarks</th>
                </tr>
                <tr>
                  <td className="topic-head-number">1</td>
                  <td className="topic-head">Mould Cleaning</td>
                  <td>
                    <OkNotOkayFetch
                      id={`${idEdit}-MouldClean`}
                      name={`${nameEdit}-MouldClean`}
                      Value={MouldLoadAndUnload.MouldClean}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          MouldClean: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <textarea
                      id={`${idEdit}-MouldCleanRemarks`}
                      name={`${nameEdit}-MouldCleanRemarks`}
                      type="text"
                      value={MouldLoadAndUnload.MouldCleanRemarks}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          MouldCleanRemarks: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head-number">2</td>
                  <td className="topic-head">Applying Anti Wax Coating</td>
                  <td>
                    <OkNotOkayFetch
                      id={`${idEdit}-ApplyAniWaxCoat`}
                      name={`${nameEdit}-ApplyAniWaxCoat`}
                      Value={MouldLoadAndUnload.ApplyAniWaxCoat}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          ApplyAniWaxCoat: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <textarea
                      id={`${idEdit}-ApplyAniWaxCoatRemarks`}
                      name={`${nameEdit}-ApplyAniWaxCoatRemarks`}
                      type="text"
                      value={MouldLoadAndUnload.ApplyAniWaxCoatRemarks}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          ApplyAniWaxCoatRemarks: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head-number">3</td>
                  <td className="topic-head">
                    Eye Bolt & Lifting Bar & Belt Condition
                  </td>
                  <td>
                    <OkNotOkayFetch
                      id={`${idEdit}-EyeBoltLiftBelt`}
                      name={`${nameEdit}-EyeBoltLiftBelt`}
                      Value={MouldLoadAndUnload.EyeBoltLiftBelt}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          EyeBoltLiftBelt: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <textarea
                      id={`${idEdit}-EyeBoltLiftBeltRemarks`}
                      name={`${nameEdit}-EyeBoltLiftBeltRemarks`}
                      type="text"
                      value={MouldLoadAndUnload.EyeBoltLiftBeltRemarks}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          EyeBoltLiftBeltRemarks: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head-number">4</td>
                  <td className="topic-head">
                    Mould Closing & Removing Water Line
                  </td>
                  <td>
                    <OkNotOkayFetch
                      id={`${idEdit}-MouldClsRemveWatrLne`}
                      name={`${nameEdit}-MouldClsRemveWatrLne`}
                      Value={MouldLoadAndUnload.MouldClsRemveWatrLne}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          MouldClsRemveWatrLne: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <textarea
                      id={`${idEdit}-MouldClsRemveWatrLneRemarks`}
                      name={`${nameEdit}-MouldClsRemveWatrLneRemarks`}
                      type="text"
                      value={MouldLoadAndUnload.MouldClsRemveWatrLneRemarks}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          MouldClsRemveWatrLneRemarks: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head-number">5</td>
                  <td className="topic-head">Removing Water I Use Air</td>
                  <td>
                    <OkNotOkayFetch
                      id={`${idEdit}-RemoveWatrUseAir`}
                      name={`${nameEdit}-RemoveWatrUseAir`}
                      Value={MouldLoadAndUnload.RemoveWatrUseAir}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          RemoveWatrUseAir: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <textarea
                      id={`${idEdit}-RemoveWatrUseAirRemarks`}
                      name={`${nameEdit}-RemoveWatrUseAirRemarks`}
                      type="text"
                      value={MouldLoadAndUnload.RemoveWatrUseAirRemarks}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          RemoveWatrUseAirRemarks: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head-number">6</td>
                  <td className="topic-head">Unclamp The Mold</td>
                  <td>
                    <OkNotOkayFetch
                      id={`${idEdit}-UnclampMold`}
                      name={`${nameEdit}-UnclampMold`}
                      Value={MouldLoadAndUnload.UnclampMold}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          UnclampMold: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <textarea
                      id={`${idEdit}-UnclampMoldRemarks`}
                      name={`${nameEdit}-UnclampMoldRemarks`}
                      type="text"
                      value={MouldLoadAndUnload.UnclampMoldRemarks}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          UnclampMoldRemarks: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head-number">7</td>
                  <td className="topic-head">Check The Locating Cover</td>
                  <td>
                    <OkNotOkayFetch
                      id={`${idEdit}-ChkLocatingCover`}
                      name={`${nameEdit}-ChkLocatingCover`}
                      Value={MouldLoadAndUnload.ChkLocatingCover}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          ChkLocatingCover: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <textarea
                      id={`${idEdit}-ChkLocatingCoverRemarks`}
                      name={`${nameEdit}-ChkLocatingCoverRemarks`}
                      type="text"
                      value={MouldLoadAndUnload.ChkLocatingCoverRemarks}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          ChkLocatingCoverRemarks: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>

                <tr>
                  <th
                    id={`${idEdit}-MouldLocationIdentityActivityTitle`}
                    name={`${nameEdit}-MouldLocationIdentityActivityTitle`}
                    colSpan={5}
                  >
                    MOULD LOCATION IDENTIFY ACTIVITY
                  </th>
                </tr>
                <tr>
                  <th>S.No</th>
                  <th>CheckPoints</th>
                  <th colSpan={3}>Location No</th>
                </tr>
                <tr>
                  <td className="topic-head-number">1</td>
                  <td className="topic-head">Mould Location Area</td>
                  <td colSpan={3}>
                    <input
                      id={`${idEdit}-MouldLocationArea`}
                      name={`${nameEdit}-MouldLocationArea`}
                      type="text"
                      value={MouldLoadAndUnload.MouldLocationArea}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          MouldLocationArea: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head">Remarks</td>
                  <td colSpan={4}>
                    <textarea
                      id={`${idEdit}-Remarks`}
                      name={`${nameEdit}-Remarks`}
                      rows={4}
                      cols={50}
                      value={MouldLoadAndUnload.Remarks}
                      onChange={(e) =>
                        setMouldLoadAndUnload({
                          ...MouldLoadAndUnload,
                          Remarks: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="topic-head">
                    Technican
                    <br></br>
                    <br></br>
                    <DropdownFetcher keyword="emp_details_moldsetter" setValue={setEmployeeDropdown} />
                    <Select
                      id={`${idEdit}-TechnicanName`}
                      name={`${nameEdit}-TechnicanName`}
                      value={MouldLoadAndUnload.TechnicanName}
                      onChange={handleTech}

                      options={empAllOptions}

                      placeholder="Select Technican Name"
                    />
                    {/* <input
                  id={`${idEdit}-TechnicanName`}
                  name={`${nameEdit}-TechnicanName`}
                  type="text"
                  value={MouldLoadAndUnload.TechnicanName}
                  onChange={(e) =>
                    setMouldLoadAndUnload({
                      ...MouldLoadAndUnload,
                      TechnicanName: e.target.value,
                    })
                  }
                /> */}
                  </td>

                  <td className="topic-head">
                    Toolroom
                    <br></br>
                    <br></br>
                    <DropdownFetcher keyword="emp_details_moldsetter" setValue={setEmployeeDropdown} />
                    <Select
                      id={`${idEdit}-ToolroomName`}
                      name={`${nameEdit}-ToolroomName`}
                      value={MouldLoadAndUnload.ToolroomName}
                      onChange={handleTool}

                      options={empAllOptions}


                      placeholder="Select Toolroom Name"
                    />
                    {/* <input
                  id={`${idEdit}-ToolroomName`}
                  name={`${nameEdit}-ToolroomName`}
                  type="text"
                  value={MouldLoadAndUnload.ToolroomName}
                  onChange={(e) =>
                    setMouldLoadAndUnload({
                      ...MouldLoadAndUnload,
                      ToolroomName: e.target.value,
                    })
                  }
                /> */}
                  </td>

                  <td colSpan={2} className="topic-head">
                    production Engg
                    <br></br>
                    <br></br>
                    <DropdownFetcher keyword="emp_details_moldsetter" setValue={setEmployeeDropdown} />
                    <Select
                      id={`${idEdit}-ProductionEnggName`}
                      name={`${nameEdit}-ProductionEnggName`}
                      value={MouldLoadAndUnload.ProductionEnggName}
                      onChange={handleProEng}

                      options={empAllOptions}

                      placeholder="Select Production Engg Name"
                    />
                    {/* <input
                  id={`${idEdit}-ProductionEnggName`}
                  name={`${nameEdit}-ProductionEnggName`}
                  type="text"
                  value={MouldLoadAndUnload.ProductionEnggName}
                  onChange={(e) =>
                    setMouldLoadAndUnload({
                      ...MouldLoadAndUnload,
                      ProductionEnggName: e.target.value,
                    })
                  }
                /> */}
                  </td>
                </tr>
                <tr>
                  <td colSpan="5" align="center" style={{ border: "none" }}>
                    <button
                      type="submit"
                      id={`${idEdit}-submit`}
                      name={`${nameEdit}-submit`}
                      onClick={(e) => handleUpdate(e)}
                    >
                      Update
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </form>
      </Popup>

      <div>
        <table
          style={{ textAlign: "center", width: "100%" }}
        /*{ cellPadding="5" }*/
        >
          <thead>
            <tr>
              <th
                id="MldUnLoadAndLoad-Title.List"
                name="MldUnLoadAndLoad-Title.List"
                colSpan="12"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">
                  List - Mould Loading & Un-Loading CheckList
                </font>
              </th>
            </tr>
            <tr>
              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>Date</td>
              <td colSpan="1">
                <input
                  id="MouldLoadAndUnload-filter-date"
                  name="MouldLoadAndUnload-filter-date"
                  type="date"
                  placeholder="Filter by Date"
                  value={MouldLoadAndUnload.filterDate}
                  onChange={handleFilterDateChange}
                />
              </td>
              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                Machine Code
              </td>
              <td colSpan={2}>
                <input
                  id="MouldLoadAndUnload-filter-MaterialCode"
                  name="MouldLoadAndUnload-filter-MaterialCode"
                  type="text"
                  placeholder="Filter MachineCode"
                  style={{ fontSize: "70%", width: "100%" }}
                  value={MouldLoadAndUnload.filterMcno}
                  onChange={handleFilterMcnoChange}
                />
              </td>
              <td colSpan="9"></td>
            </tr>
            <tr className="table_position">
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Date&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;M/C No&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Part Name Load&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Raw Material Name&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Part Name Un-Load&nbsp;&nbsp;
              </th>

              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Mould Location Area&nbsp;&nbsp;
              </th>
              {/* <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Part Weight&nbsp;&nbsp;
              </th> */}
              {/* <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Created Date&nbsp;&nbsp;
              </th> */}
              <th className="table-list-head"></th>
              <th className="table-list-head"></th>
              <th className="table-list-head"></th>
            </tr>
          </thead>
          <tbody>
            {/* <HandleRead
              keyword="Mould_loading_unloading"
              setData={setMld_load_unload}
            /> */}
            {filteredRecords.length === 0 ? (
              // <tr>
              <td colSpan="10" style={{ textAlign: "center" }}>
                <p style={{ fontWeight: "bold" }}>No records found.</p>
              </td>
            ) : (
              // </tr>
              filteredRecords.map((Report) => (
                <tr className="table_view" key={Report.Id}>
                  <td
                    id={`${idList}-MC_no`}
                    name={`${nameList}-MC_no`}
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{formatDt(Report.Track_date_load)}
                  </td>
                  <td
                    id={`${idList}-MC_no`}
                    name={`${nameList}-MC_no`}
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{Report.MC_no}
                  </td>
                  <td
                    id={`${idList}-Part_name_load`}
                    name={`${nameList}-Part_name_load`}
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{Report.PartNameLoad}
                  </td>
                  <td
                    id={`${idList}-Raw_material_name`}
                    name={`${nameList}-Raw_material_name`}
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{Report.Raw_material_name}
                  </td>
                  <td
                    id={`${idList}-Part_name_unload`}
                    name={`${nameList}-Part_name_unload`}
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{Report.PartNameUnLoad}
                  </td>

                  <td
                    id={`${idList}-Chk_locating_cover`}
                    name={`${nameList}-Chk_locating_cover`}
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{Report.Mould_location_area}
                  </td>
                  {/* <td className="table-cell">&nbsp;&nbsp;{Report.Prt_weight}</td> */}
                  {/* <td className="table-cell">&nbsp;&nbsp;{Report.Mat_ld_time}</td> */}
                  {/* <td id={`${idList}-CreatedDate`}
                name={`${nameList}-CreatedDate`}
        className="table-cell">&nbsp;&nbsp;{Report.CreatedDate}</td> */}
                  <td>
                    <button
                      id={`${idView}-view`}
                      name={`${idView}-view`}
                      style={{ backgroundColor: "#cba423" }}
                      type="button"
                      onClick={() => handleView(Report)}
                    >
                      View&#x1F441;
                    </button>
                  </td>
                  <td>
                    <button
                      id={`${idEdit}-Edit`}
                      name={`${idEdit}-Edit`}
                      type="button"
                      onClick={() => handleEdit(Report)}
                    >
                      Edit&#x270E;
                    </button>
                  </td>
                  <td>
                    <button
                      id={`${idEdit}-Delete`}
                      name={`${idEdit}-Delete`}
                      style={{ whiteSpace: "nowrap", backgroundColor: "#DA251A" }}
                      type="button"
                      onClick={() => {
                        const userConfirmed = window.confirm('Are you sure you want to delete?');
                        if (userConfirmed) {
                          UpdateRecord({
                            _keyword_: 'Mould_loading_unloading',
                            data: [
                              {
                                Id: Report.Id,
                                ActiveStatus: 'InActive',
                              },
                            ],
                          }).then(()=>{
                            handleReadMldUnloadLoad();
                          })
                          // window.location.reload();
                        }
                      }}
                    >
                      Delete<MdDelete />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <div className="pagination" id="pagination">
          {MouldLoadAndUnload.isPaginationVisible &&
            filteredData.length > 0 && (
              <Pagination
                currentPage={MouldLoadAndUnload.currentPage}
                npage={npage}
                prePage={prePage}
                nextPage={nextPage}
                changeCpage={changeCpage}
                numbers={numbers}
              />
            )}
        </div>
      </div>
    </div>
  );
}

export default MldUnloadAndLoadView;
