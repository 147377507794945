import React, { useState, useEffect, useRef } from "react";
import style from "./MainHeader.module.css";
import notification from "../../icons/notification1.png";
import Unitechlogo from "../../icons/Unitechlogo.jpeg";
import GetTableRecord from '../../Lib/GetTableRecord'
import GetRecord from "../../Lib/GetRecord";
import { useNavigate } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import UpdateRecord from "../../Lib/updateRecord";
import {SOFTWAREBREAK,BREAKTIME,BREAKDATE} from "../../Utils/constants.js"

export default function MainHeader() {
  const navigate = useNavigate();
  const [not, setnot] = useState([]);
  const [count, setcount] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [formVisible1, setFormVisible1] = useState(false);
  const dropdownRef = useRef(null);
  const [timeLeft, setTimeLeft] = useState(0);
  const dropdownRef1 = useRef(null);

  const handleLogout = () => {
    localStorage.removeItem("role");
    localStorage.removeItem("login");
    localStorage.removeItem("token");
    navigate("/");
    window.location.reload();
  };

  const fetchData = async () => {
    {
      await GetRecord(
        {
          _keyword_: "Notification_dropdown_unread_user",
          note_to: localStorage.getItem("empId"),
        },
        setnot
      );
    }
  };

  const clearAllRecords = () => {
    // UpdateRecord({
    //   _keyword_:'Notification_count_unread_user_clearall',
    //   data:{
    //     id
    //   }
    // })
    not.map(item => {
      UpdateRecord({
        _keyword_: 'Notification_details',
        data: [{
          Id: item.Id,
          Note_readed_dropdown: '1'
        }]
      })
      console.log(item.Id);
    })
  }

  useEffect(() => {
    // const intervalId = setInterval(() => {
    //   setTimeLeft(timeLeft - 1);
    // }, 1000);
    GetRecord(
      {
        _keyword_: "Notification_count_unread_user",
        note_to: localStorage.getItem("empId"),
      },
      setcount
    );
    // return () => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setFormVisible(false);
      }
      if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
        setFormVisible1(false);
      }
    };

    const handleScroll = () => {
      setFormVisible(false);
      setFormVisible1(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("scroll", handleScroll);
    };
  }, [dropdownRef]);

  const handleClickOutside1 = () => {
    setTimeout(() => {
      setFormVisible(false);
    }, 200);
  };
  const handleClickOutside2 = () => {
    setTimeout(() => {
      setFormVisible1(false);
    }, 200);
  };

  const handleClearAllClick = () => {
    setnot([]);
  };
  const handleuser = async () => {
    setFormVisible1(!formVisible1);
    fetchData();
  };
  const id = localStorage.getItem("Code");
  const handleNotificationClick = async () => {
    setFormVisible(!formVisible);
    fetchData();
  };
  const notificationCount = count.length > 0 ? count[0].notification_count : 0;
  // if (window.location.pathname !== "/") {
  // const SOFTWAREBREAK = true;
  return (
    <div style={{ backgroundColor: '#96d4d4', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 40px' }}>
      <div>
        <img src={Unitechlogo} style={{ width: "100px", height: '50px' }}></img>
      </div>
      <div>
        <div style={{ fontSize: "24px", whiteSpace: "nowrap", fontWeight: 'bolder', marginLeft: "24%" }}>
        KASH Tech LLC
        </div>
        { SOFTWAREBREAK === true ?
        <div style={{color:"red" , textAlign:"center",fontSize:"10px",paddingLeft:"50%",fontWeight:"bold"}}> Software Break <br></br>Date:({BREAKDATE}) Time :{BREAKTIME}</div>:""
      }
      </div>
      <div style={{ display: 'flex', width: '20%', justifyContent: 'space-between' }}>

        <div style={{ position: 'relative' }}>
          <img
            src={notification}
            alt="Notification"
            onClick={handleNotificationClick}
            className={style.icon}
          />
          {notificationCount > 0 ?
            <div style={{ position: 'absolute', top: '-5px', right: '-10px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'red', borderRadius: '50%', width: '30px', height: '30px', border: '2px solid white', fontWeight: 'bolder', color: 'white' }}>
              {notificationCount > 99 ? (
                <div>99+</div>
              ) : (
                <div>{notificationCount}</div>
              )}
            </div>
            : ""
          }
        </div>

        <div className={style.separator2} onClick={handleuser} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '5' }}>
          <span>
            <CgProfile size={50} />
          </span>
          {localStorage.getItem("name") ? (
            <div style={{width:"100%"}}>
              <label className={style.ellipsis}>{localStorage.getItem("name")}</label>
              <label className={style.roleLabel}>{localStorage.getItem("role")}</label>
            </div>
          ) : (
            ""
          )}
        </div>

      </div>

      {formVisible && (
        <div
          className={`${style.dropdown} ${style.smoothHide}`}
          ref={dropdownRef}
        >
          <form>
            <div className={style.dropdownContent}>
              <div>
                <label className={style.greet}>{"Notification"}</label>
              </div>
              <hr></hr>

              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <label
                    style={{ width: "20%", color: "blue" }}
                    onClick={() => {
                      navigate("/NotificationList");
                      handleClickOutside1();
                    }}
                  >
                    ViewAll
                  </label>

                  <label
                    style={{ width: "25%", color: "blue" }}
                    onClick={() => {
                      handleClearAllClick();
                      handleClickOutside1();
                      clearAllRecords();
                    }}
                  >
                    clearAll
                  </label>
                </div>

                
                 {console.log("not",not)}
                    {not.map((entry, id) => {
                      console.log("entry,id",id,entry.Id===undefined);
                      
                      return(
                        
                      <div key={id}>
                        {entry.Id!==undefined ? <label
                          className={style.separator}
                          onClick={() => {
                            navigate("/" + entry.Note_record_table + "/view/" + entry.Note_record_id)
                            handleClickOutside1();
                            UpdateRecord({
                              _keyword_: 'Notification_details',
                              data: [{
                                Id: entry.Id,
                                Note_readed_dropdown: '1',
                                Note_readed: '1'
                              }]
                            })
                          }}
                        >
                          <span role="img" aria-label="User Symbol">
                            👤
                          </span>{" "}
                          {entry.emp_name} <br />
                          <span role="img" aria-label="Message Symbol">
                            💬
                          </span>{" "}
                          {entry.Note_msg} <br />
                        </label>:""}
                      </div>
                      )
                        })}
                 
                  

              </div>
            </div>
          </form>
        </div>
      )}

      {/* <div className={style.header_left_search}></div>

        <div className={style.header_right}>
          <div className={style.header_right_profile}></div>
        </div> */}
      {formVisible1 && (
        <div
          className={`${style.dropdown1} ${style.smoothHide}`}
          ref={dropdownRef1}
        >
          <form>
            <div className={style.dropdownContent1}>
              <div style={{ display: "flex", justifyContent: "left" }}>
                <label className={style.separator1}
                  style={{ width: "100%", fontSize: "20px" }} onClick={() => {
                    navigate("/EmployeeDetail/" + localStorage.getItem("empId"));
                    handleClickOutside2();

                  }}>{"👤 Profile"}</label>
              </div>

              <div>
                <div style={{ display: "flex", justifyContent: "left" }}>
                  <label style={{ fontSize: "30px", marginTop: "10px", color: "#FF0000" }}></label>

                  <label className={style.separator1}
                    style={{ width: "100%", fontSize: "20px" }}
                    onClick={() => {
                      navigate("/NotificationList");
                      handleClickOutside2();
                    }}
                  >
                    {"📥 All Messages"}

                  </label>
                </div>

                {/* <div style={{ display: "flex", justifyContent: "left" }}>
               {/*    <label style={{fontSize:"30px",marginTop:"10px"}}>⚙️</label>
                   <label className={style.separator1}
                      style={{ width: "60%" }}
                      onClick={() => {
                        navigate("/EmployeeMaster"+"/edit/"+localStorage.getItem("empId"));
                        handleClickOutside1();}}
                    ></div>  
                       Preference
                    </label>  */}

                <div style={{ display: "flex", justifyContent: "left" }}>
                  {/* <label style={{fontSize:"30px",marginTop:"10px",color:"#FF0000"}}>⬅️</label> */}
                  <label className={style.separator1}
                    style={{ width: "110%", fontSize: "20px" }}
                    onClick={() => {
                      handleLogout();
                      handleClickOutside2();
                    }}>

                    {"⬅️  Logout"}

                  </label>
                </div>

              </div>
            </div>
          </form>

        </div>
      )}
    </div>
  );
}
// }
