import { getAPIRequest } from "../Utils/APIUtility";
import { PROCESS_TRANSACTION_URL_PAINT, SECRET_KEY } from "../Utils/constants";

const CreateApprovalPaint = async ({
  TableID,
  Description,
  TableName,
  Approver,
  Level,
  RequestedBy,
  ApprovalRemark,
  Status,
  CreatedBy
}) => {
  const data = {
    data: [
      {
        Status: Status,
        TableID: TableID,
        Description: Description,
        TableName: TableName,
        Approver: Approver,
        Level: Level,
        RequestedBy: RequestedBy,
        ApprovalRemark: ApprovalRemark,
        CreatedBy:CreatedBy,
      },
    ],
    _keyword_: "approval",
    secretkey: SECRET_KEY,
  };
  const response = await getAPIRequest(PROCESS_TRANSACTION_URL_PAINT, "POST", data);
  const response1 = await response.json();
};

export default CreateApprovalPaint;
