import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CURRENT_Date } from "../../../../Utils/GeneralConstants";
import PreviewPage from "./PreviewPage";
import ReadRecordPaint from "../../../../Lib/API/ReadRecordPaint";

const SPPViewPage = () => {
    const InitialIs = { Injection_stages: "STAGES", Injection_pressure: "3", Injection_speed: "5", Injection_position: "5", Injection_time: "0.5" }
    const InitialHold = { Holding_stages: "", Holding_pressure: "3", Holding_speed: "5", Holding_position: "5", Holding_time: "0.5" }
    const IntialTempNozzle = { Temperature_stages: "Nozzle", Temp_set: "10", Temp_value: "" }
    const IntialTempHopper = { Temperature_stages: "Hopper", Temp_set: "10", Temp_value: "" }
    const IntialTempZone = { Temperature_stages: "Zone 1", Temp_set: "5", Temp_value: "" }
    const InitRoot = [
        {
            download: false,
            CusName: [{}],
            Createdname: [{}],
            Approvedname: [{}],
            Partname: [{}],
            Paintname: [{}],
            Programname: [{}],
            Part_name: "",
            Program_name: "",
            Paint_name: "",
            Cus_name: "",  
          Doc_no: "UPCL/PRO/D/70",
          Rev_no: "00",
          Rev_ate: "01-06-2018",
          Prodcution_operator_name: "",
          direction_function0: [      
            { Direction_0: "A0#1(Automization Air)" ,value:""},
            { Direction_0: "A0#2(Fan Air)",value:"" },
            { Direction_0: "A0#3(Material Pressure)±0.5 Bar",value:"" },
            { Direction_0: "File NO",value:"" },
            { Direction_0: "NO Of Strokes",value:"" },
            { Direction_0: "Position Speed (mm/s)",value:""},
           
          ],
          direction_function90: [      
            { Direction_90: "A0#1(Automization Air)" ,value:""},
            { Direction_90: "A0#2(Fan Air)",value:"" },
            { Direction_90: "A0#3(Material Pressure)±0.5 Bar",value:"" },
            { Direction_90: "File NO",value:"" },
            { Direction_90: "NO Of Strokes",value:"" },
            { Direction_90: "Position Speed (mm/s)",value:""}
          ],
          direction_function180: [      
            { Direction_180: "A0#1(Automization Air)" ,value:""},
            { Direction_180: "A0#2(Fan Air)",value:"" },
            { Direction_180: "A0#3(Material Pressure)±0.5 Bar",value:"" },
            { Direction_180: "File NO",value:"" },
            { Direction_180: "NO Of Strokes",value:"" },
            { Direction_180: "Position Speed (mm/s)",value:""}
          ],
          direction_function270: [      
            { Direction_270: "A0#1(Automization Air)" ,value:""},
            { Direction_270: "A0#2(Fan Air)",value:"" },
            { Direction_270: "A0#3(Material Pressure)±0.5 Bar",value:"" },
            { Direction_270: "File NO",value:"" },
            { Direction_270: "NO Of Strokes",value:"" },
            { Direction_270: "Position Speed (mm/s)",value:""}
          ],
        },
      ];
    
    const navigate = useNavigate();
    const [Records, setRecords] = useState(InitRoot);
    const [Preview, setPreView] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        console.log("VIEWRecords", Records);
        console.log("direct",Records[0].painting_smp_direction);
    }, [Records]);

    useEffect(() => {
        console.log("id",id);
        document.title = 'SPP';
        const fetchData = async () => {
            if (id === undefined) {
                setRecords({});
            } else {
                console.log("Id3434",id)
                try {
                    const res = await ReadRecordPaint({
                        _keyword_: 'paint_SMP',
                        Id: id,
                    });
// console.log("87878",res.data);
                    if (res.data[0] === undefined) {
                        setRecords(InitRoot);
                    } else {
                        setRecords(res.data);
                        setPreView(true);
                    }
                } catch(error) {
                    console.log(error);
                }
            }
        };

        fetchData();
    }, [id]);

    useEffect(() => {
        if (Preview === false) {
            navigate(-1);
        }
    }, [Preview, navigate]);

    return Preview && <PreviewPage Records={Records} setState={setPreView}/>;
}

export default SPPViewPage;
