import React, { useState, useEffect, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import QaLayout from "../../../Quality/QaLayout";
import Lable from "../../../../Components/Fields/Lable";
import Field from "../../../../Components/Fields/FieldPaint.js";
import { useNavigate, useParams } from "react-router-dom";
import * as GeneralConstants from "../../../../Utils/GeneralConstants.js";
import {
  CURRENT_Date,
  SHIFTMOLDINGQUALITY,
} from "../../../../Utils/GeneralConstants";
import PopupPdf from "../../../../Components/Popup/PopupPdf";
import ReadRecordPaint from "../../../../Lib/API/ReadRecordPaint.js";
import CreateRecordPaint from "../../../../Lib/API/CreateRecordPaint.js";
import UpdateRecordPaint from "../../../../Lib/API/UpdateRecordPaint.js";
import { setMessagePaint } from "../../../../Lib/SetMessagePaint.js";
import CreateApprovalPaint from "../../../../Lib/CreateApprovalPaint.js";

const Firstandlastoff = () => {
  const CURRENT_TIME = new Date(
    new Date().getTime() - new Date().getTimezoneOffset() * 60000
  )
    .toISOString()
    .split("T")[1]
    .substring(0, 5);
  const init = [
    {
      checkedby: [{}],
      approvedby: [{}],
      verifiedby: [{}],
      partname: [{}],
      partnumber: [{}],
      Inspection_ate: "01-06-2018",
      Format_no: "UPCL/QC/R/02",
      Rev_no: "01",
      Insp_type: "FirstOff",
      Paint_firstoff: "true",
      Paint_lastoff: "",
      Insp_type_first_off: "true",
      Insp_type_last_off: "",
      Part_name: "",
      Part_number: "",
      Color: "",
      Entry_date: CURRENT_Date,
      Model: "",
      Shift: SHIFTMOLDINGQUALITY,
      Time: CURRENT_TIME,
      Con_free1: "",
      Con_free2: "",
      Completed:"0",
      Flow_pattern1: "",
      Flow_pattern2: "",
      Spec1: "",
      Spec2: "",
      Gloss_value: "",
      Gloss_value_img1: "",
      Gloss_value_img2: "",
      Dfc_set: "",
      Primer_image1: "",
      Base_image1: "",
      Free_from_peel1: "",
      Primer_image2: "",
      Base_image2: "",
      Free_from_peel2: "",
      Color_value: "",
      Color_value_img1: "",
      Color_value_img2: "",
      Oven_temp1: "",
      Gun_pressure1: "",
      Booth_temp1: "",
      Booth_humidity1: "",
      Paint_mixing_ratio1: "",
      Viscosity1: "",
      Gun_flow_rate1: "",
      Cur_oven_con_speed1: "",
      Oven_temp2: "",
      Gun_pressure2: "",
      Booth_temp2: "",
      Booth_humidity2: "",
      Paint_mixing_ratio2: "",
      Viscosity2: "",
      Gun_flow_rate2: "",
      Cur_oven_con_speed2: "",
      Remarks: "",
      Approved_by: "",
      Checked_by: "",
      Verified_by: "",
      Id: "0",
    },
  ];
  const [downloadEnabled, setDownloadEnabled] = useState(true);
  const [Records, setRecords] = useState(init);
  const [warning, setwarning] = useState(false);
  const [firstoff, setfirstoff] = useState(false);
  const [firstadded, setfirstadded] = useState(false);
  const [lastoff, setlastoff] = useState(false);
  const [loading, setLoading] = useState(false);
  const { option, id } = useParams();
  const navigate = useNavigate();
  const contentRef = useRef(null);
  const generatePDF = () => {
    const pdf = new jsPDF("landscape", "mm", "a4");
    html2canvas(contentRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 10, 10, 280, 0);

      pdf.save("FinalInspection.pdf");
    });
  };
  useEffect(() => {
    setwarning(false);
    document.title = "Paint FirstandLastOff";
    const fetchData = async () => {
      if (id === undefined) {
        setRecords([
          {
            ...Records[0],
            // Entry_date: CURRENT_Date,
            // Time: CURRENT_TIME,
            Shift: SHIFTMOLDINGQUALITY,
          },
        ]);
      } else {
        setLoading(true);
        try {
          const res = await ReadRecordPaint({
            _keyword_: "paint_firstandlast_off",
            Id: id,
          });
          console.log("234", res);
          if (res.data[0] === undefined) {
            setRecords(init);
          } else {
            setRecords((prevRecords) => {
              let updatedRecords = [...res.data];
              return updatedRecords;
            });
          }
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData1 = async () => {
      if (
        Records[0].Part_name === undefined &&
        option !== "edit" &&
        option !== "view"
      ) {
        setRecords([
          {
            ...Records[0],
            Shift: SHIFTMOLDINGQUALITY,
          },
        ]);
      } else {
        setLoading(true);
        try {
          const res = await ReadRecordPaint({
            _keyword_: "paint_firstandlast_partname",
            Part_name: Records[0].Part_name,
          });
          console.log("Response data444:", res.data);
          let indexToUpdate = -1;
          for (let i = 0; i < res.data.length; i++) {
              let record = res.data[i];

              // Check conditions for each record
              if (
                  record.Paint_firstoff === "true" &&
                  record.Paint_lastoff === "" &&
                  record.Completed === "0" &&
                  option !== "edit" &&
                  option !== "view"
              ) {
                  console.log("Matching record found:", record);
                  indexToUpdate = i; 
                  break;
              }
          
                
          }

          if (indexToUpdate !== -1) {
              setRecords([res.data[indexToUpdate]]);
                setRecords((prevRecords) => {
              let updatedRecords = [...prevRecords];
              updatedRecords[0] = {
                ...updatedRecords[0],
                Paint_lastoff: "true",
              };
              return updatedRecords;
            });
           setfirstoff(true);
            setlastoff(false);
              // alert("Adding LAST OFF");
          }
        
          // if (
          //   res.data[0]?.Paint_firstoff === "true" &&
          //   res.data[0]?.Paint_lastoff === "true" &&
          //   res.data[0]?.Completed === "1" &&
          //   option !== "edit" &&
          //   option !== "view"
          // ) {
          //   console.log(res.data[0].Completed);
          //   alert("ADDING new Entry");
          // }
         
        //  else if (filteredRecord.length > 0) {
        //     let matchedRecord = filteredRecord[0];
        //     console.log(matchedRecord);
        //    alert('No record found where Completed is "0"')
        // } 
          // else if (
          //   res.data[0]?.Paint_firstoff === "true" &&
          //   res.data[0]?.Paint_lastoff === "" &&
          //   res.data[0]?.Completed === "0" &&
          //   option !== "edit" &&
          //   option !== "view"
          // ) {
          //   setRecords(init);
          // }
          //  if (
          //   res.data[0]?.Paint_firstoff === "true" &&
          //   res.data[0]?.Paint_lastoff === "" &&
          //   res.data[0]?.Completed === "0" &&
          //   option !== "edit" &&
          //   option !== "view"
          // ) {
          //   alert("Add lastoff");
            // setfirstoff(true);
            // setlastoff(false);

         //   console.log("Records where Completed is '0':", completedZeroRecords);
            // setRecords(completedZeroRecords);
            // setRecords((prevRecords) => {
            //   let updatedRecords = [...prevRecords];
            //   updatedRecords[0] = {
            //     ...updatedRecords[0],
            //     Paint_lastoff: "true",
            //   };
            //   return updatedRecords;
            // });
          // } 
          else if (
            res.data.length === 0 &&
            option !== "edit" &&
            option !== "view"
          ) {
            setlastoff(true);
          }
        } catch (error) {
          console.error("Error:", error);
          setRecords(init);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData1();
  }, [Records?.[0]?.Part_name]);

  function HandleRecords(Event) {
    setRecords([{ ...Records[0], [Event.target.name]: Event.target.value }]);
  }
  function HandleRecordSelectpart(Event, column, value, label) {
    setRecords(() => {
      let record = [...Records];
      record[0][Event.name] = Event.values.label;
      record[0][column][0][value] = Event.values.label;
      record[0][column][0][label] = Event.values.label;
      return record;
    });
  }
  function HandleRecordSelect(Event, column, value, label) {
    setRecords(() => {
      let record = [...Records];
      record[0][Event.name] = Event.values.value;
      record[0][column][0][value] = Event.values.value;
      record[0][column][0][label] = Event.values.label;

      return record;
    });
  }
  function Handlepartname(e) {
    const PN = e.values.label;
    console.log("786", PN);
    ReadRecordPaint({
      _keyword_: "Get_part_code_from_paint",
      PartName: PN,
    }).then((res) => {
      if (res.rows == "1") {
        setRecords(() => {
          let record = [...Records];
          console.log(res.data[0].part_code);
          record[0].Part_number = res.data[0].part_code;
          //  (record[0]["partnumber"][0]["Part_number"] = res.data[0].part_code);
          return record;
        });
      } else if (res.rows > "1") {
        console.log("Handlepartname else", res.rows);
      }
      console.log("Handlepartname", res.rows);
    });
  }
  function HandleRecordsBoolean(Event) {
    setRecords([
      { ...Records[0], [Event.target.name]: Event.target.checked + "" },
    ]);
  }
  function HandleRecordsBoolean1(event) {
    const { name, checked } = event.target;
    const CheckboxName =
      name === "Insp_type_first_off"
        ? "Insp_type_last_off"
        : "Insp_type_first_off";
    const updatedRecords = Records.map((record, index) => {
      if (index === 0) {
        return { ...record, [name]: checked + "" };
      } else {
        return record;
      }
    });
    if (checked) {
      updatedRecords[0][CheckboxName] = "false";
    }
    setRecords(updatedRecords);
  }

  useEffect(() => {
    console.log("Records", Records);
    console.log("Type", Records[0].Insp_type);

  }, [Records]);

  // useEffect(() => {
  //   if (Records[0].Insp_type_first_off === "true") {
  //     setRecords((prevRecords) => [{ ...prevRecords[0], Insp_type: "FirstOff" }]);
  //   } else if (Records[0].Insp_type_last_off === "true") {
  //     setRecords((prevRecords) => [{ ...prevRecords[0], Insp_type: "LastOff" }]);
  //   }
  // }, [Records[0].Insp_type_first_off, Records[0].Insp_type_last_off]);

  function HandleCreate(event) {
    console.log(!firstoff, !lastoff);
    try {
      if (
        (!firstoff &&
          (!Records[0].Part_name ||
            !Records[0].Paint_firstoff ||
            !Records[0].Approved_by ||
            !Records[0].Checked_by ||
            !Records[0].Verified_by ||
            // !Records[0].Gloss_value ||
            !Records[0].Gloss_value_img1 ||
            !Records[0].Primer_image1 ||
            !Records[0].Base_image1 ||
            !Records[0].Free_from_peel1 ||
            !Records[0].Color_value_img1)) ||
        (!lastoff &&
          (!Records[0].Part_name ||
            !Records[0].Paint_lastoff ||
            !Records[0].Approved_by ||
            !Records[0].Checked_by ||
            !Records[0].Verified_by ||
            // !Records[0].Gloss_value ||
            !Records[0].Gloss_value_img2 ||
            !Records[0].Primer_image2 ||
            !Records[0].Base_image2 ||
            !Records[0].Free_from_peel2 ||
            !Records[0].Color_value_img2))
      ) {
        alert("Please Fill the Mandatory Fields");
        setwarning(true);
      } else {
        let record = [...Records];
        record && delete record[0].Id;
        record && delete record[0].partname;
        record && delete record[0].partnumber;
        record && delete record[0].approvedby;
        record && delete record[0].checkedby;
        record && delete record[0].verifiedby;
        // record && delete record[0].Insp_type_first_off;
        // record && delete record[0].Insp_type_last_off;

        console.log("createafter Delete", record);
        CreateRecordPaint("paint_firstandlast_off", record).then((res) => {
          console.log("HandleCreate", res);
          setMessagePaint({
            NoteFrom: localStorage.getItem("empId"),
            NoteTo: Records[0].Approved_by,
            NoteMessage: `${localStorage.getItem(
              "empId"
            )}-${localStorage.getItem(
              "name"
            )} has submitted Paint First and Lastoff Form, Please do verify`,
            NoteRecordId: res.ROWID,
            TableName: "FirstandLastoff",
            NoteCreatedBy: localStorage.getItem("empId"),
          });
          CreateApprovalPaint({
            RequestedBy: localStorage.getItem("empId"),
            Approver: Records[0].Approved_by,
            Level: 1,
            Status: "Waiting for Approval",
            TableName: "FirstandLastoff",
            TableID: res.ROWID,
            Description:
              "The Paint First and Lastoff Form Has been Submitted By " +
              localStorage.getItem("empId") +
              "-" +
              localStorage.getItem("name"),
            CreatedBy: localStorage.getItem("empId"),
          });
        });
        alert(GeneralConstants.SUBMISSION_SUCCESSFUL);
        setwarning(false);
        setRecords(init);
      }
    } catch (error) {
      console.error(error);
      alert(GeneralConstants.SUBMISSION_FAILED);
    }
  }
  function HandleUpdate(event) {
    try {
      if (
        (!firstoff &&
          (!Records[0].Part_name ||
            !Records[0].Approved_by ||
            !Records[0].Checked_by ||
            !Records[0].Verified_by ||
            // !Records[0].Gloss_value ||
            !Records[0].Gloss_value_img1 ||
            !Records[0].Primer_image1 ||
            !Records[0].Base_image1 ||
            !Records[0].Free_from_peel1 ||
            !Records[0].Color_value_img1)) ||
        (!lastoff &&
          (!Records[0].Part_name ||
            !Records[0].Approved_by ||
            !Records[0].Checked_by ||
            !Records[0].Verified_by ||
            // !Records[0].Gloss_value ||
            !Records[0].Gloss_value_img2 ||
            !Records[0].Primer_image2 ||
            !Records[0].Base_image2 ||
            !Records[0].Free_from_peel2 ||
            !Records[0].Color_value_img2))
      ) {
        alert("Please Fill the Mandatory Fields");
        setwarning(true);
      } else {
        // if (!lastoff) {
        //   let updatedRecord = { ...Records[0], Completed: "1" };
        //   setRecords([updatedRecord]);
        //   alert(Records[0].Completed)
        // }
          let record = [...Records];
          if (record && record[0]) {
            record[0].Completed = "1";
            record[0].Insp_type = "FirstOff / LastOff"
          }
        record && delete record?.[0]?.partname;
        record && delete record?.[0]?.approvedby;
        record && delete record?.[0]?.checkedby;
        record && delete record?.[0]?.verifiedby;
        // record && delete record?.[0]?.Insp_type_first_off;
        // record && delete record?.[0]?.Insp_type_last_off;
        console.log("updateComp", record);
        UpdateRecordPaint("paint_firstandlast_off", record).then((res) => {
          console.log("HandleUpdate", res);
        });
        alert(GeneralConstants.UPDATE_SUCCESSFUL);
        navigate(-1);
      }
    } catch (error) {
      console.error(error);
      alert(GeneralConstants.UPDATE_FAILURE);
    }
  }

  return (
    <QaLayout record={Records} quary={"FirstandLastoff"}>
      <div ref={contentRef}>
        <table>
          <tbody>
            <tr>
              <th colSpan={6}>
                <Lable value={"First&Last Off Inspection"} type={"Head"} />
              </th>
              <td colSpan={2}>
                <tr>
                  <td style={{ width: "5%" }}>
                    <Field
                      label={"REV NO"}
                      name={"Rev_no"}
                      style={{ padding: "0px" }}
                      options={{
                        type: "text",

                        disabled: "true",
                      }}
                      value={Records[0]}
                      // OnChange={(e) => {
                      //   HandleRecords(e);
                      // }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "5%" }}>
                    <Field
                      label={"REV DATE"}
                      name={"Inspection_ate"}
                      style={{ padding: "0px" }}
                      options={{
                        type: "text",
                        disabled: true,
                      }}
                      value={Records[0]}
                      // OnChange={(e) => {
                      //   HandleRecords(e);
                      // }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "5%" }}>
                    <Field
                      label={"Format No"}
                      name={"Format_no"}
                      style={{ padding: "0px" }}
                      options={{
                        type: "text",
                        disabled: "true",
                      }}
                      value={Records[0]}
                      // OnChange={(e) => {
                      //   HandleRecords(e);
                      // }}
                    />
                  </td>
                </tr>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <Field
                  label={"PART NAME"}
                  name={"Part_name"}
                  // style={{ padding: "0px" }}
                  options={{
                    type: "reference",
                    reference: "Get_paint_part_name",
                    required: true,
                    disabled: option === "view" || option === "edit",
                  }}
                  warning={warning}
                  value={{
                    value: Records[0].partname[0]
                      ? Records[0].partname[0].Part_name
                      : "" || "",

                    label: Records[0].partname[0]
                      ? Records[0].partname[0].Part_name
                      : "" || "",
                  }}
                  OnChange={(e) => {
                    HandleRecordSelectpart(
                      e,
                      "partname",
                      "Part_name",
                      "Part_name"
                    );

                    Handlepartname(e);
                  }}
                />
              </td>
              <td colSpan={2}>
                <Field
                  label={"Color"}
                  name={"Color"}
                  //  style={{ padding: "0px" }}
                  options={{
                    type: "text",
                    disabled: option === "view" || option === "edit",
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                ></Field>
              </td>
              <td colSpan={2}>
                <Field
                  label={"DATE"}
                  name={"Entry_date"}
                  //  style={{ padding: "0px" }}
                  options={{
                    type: "date",
                    disabled: true,
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                ></Field>
              </td>
              <td colSpan={2}>
                <Field
                  label={"SHIFT"}
                  name={"Shift"}
                  //  style={{ padding: "0px" }}
                  options={{
                    type: "text",
                    disabled: true,
                  }}
                  value={Records[0]}
                ></Field>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <Field
                  label={"PART NUMBER"}
                  name={"Part_number"}
                  //  style={{ padding: "0px" }}
                  options={{
                    type: "text",
                    disabled: "true",
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
              <td colSpan={4}>
                <Field
                  label={"Model"}
                  name={"Model"}
                  //  style={{ padding: "0px" }}
                  options={{
                    type: "text",
                    disabled: option === "view" || option === "edit",
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                ></Field>
              </td>
              <td colSpan={2}>
                <Field
                  name={"Time"}
                  label={"TIME"}
                  //  style={{ padding: "0px" }}
                  options={{
                    type: "text",
                    disabled: "true",
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                ></Field>
              </td>
            </tr>
            <tr>
              <td colSpan={1} style={{ padding: "0px" }}>
                <Lable value={"I"} />
              </td>
              <td colSpan={3}>
                <Lable value={"APPERANCE INSPECTION"} />
              </td>
              <td colSpan={4}>
                <Field
                  // name={"Insp_type_first_off"}paint_firstoff
                  name={"Paint_firstoff"}
                  label={"First"}
                  style={{ float: "left", width: "50%" }}
                  options={{
                    type: "boolean",
                    disabled: true,
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecordsBoolean1(e);
                  }}
                />
                <Field
                  // name={"Insp_type_last_off"}
                  name={"Paint_lastoff"}
                  label={"Last"}
                  style={{ width: "30% " }}
                  options={{
                    type: "boolean",
                    disabled: option === "view" || lastoff === true,
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecordsBoolean1(e);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={1} style={{ width: "5%" }}>
                <Lable value={"1"} />
              </td>
              <td colSpan={3}>
                <Lable
                  value={
                    "FREE FROM DUST,LINT,SAG,LESS COVER,DRY SPRAY,LIGHT LEAKAGE,OVER SPRAY,BUBBLEE OIL MARK,Color VARIATION,DENT,DAMAGE,FLASH,ETC..."
                  }
                />
              </td>
              <td colSpan={2}>
                <Field
                  name={"Con_free1"}
                  label={""}
                  //  style={{ padding: "0px" }}
                  options={{
                    type: "boolean",
                    disabled: option === "view" || firstoff === true,
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecordsBoolean(e);
                  }}
                />
              </td>
              <td colSpan={2}>
                <Field
                  name={"Con_free2"}
                  label={""}
                  //  style={{ padding: "0px" }}
                  options={{
                    type: "boolean",
                    disabled: option === "view" || lastoff === true,
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecordsBoolean(e);
                  }}
                />
              </td>
              {/* <td colSpan={2}> 
              <Field
                    label={"Clear Filter"}
                    options={{ type: "Button" }}
                    onClick={() => {
                      HandleRecords(e);
                    }}

                  /> */}
              {/* <Field
                  name={"Condition_Lastoff"}
                  label={""}
                 // style={{ padding: "0px" }}
                  options={{
                    type: "boolean",
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecordsBoolean(e);
                  }}
                />
               
              </td> */}
            </tr>
            <tr>
              <td colSpan={1}>
                <Lable value={"2"} />
              </td>
              <td colSpan={3}>
                <Lable
                  value={
                    "Check all the relevant documents displays (flow pattern,ppsquality std,WI)"
                  }
                />
              </td>
              <td colSpan={2}>
                <Field
                  name={"Flow_pattern1"}
                  label={""}
                  options={{
                    type: "text",
                    disabled: option === "view" || firstoff === true,
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
              <td colSpan={2}>
                <Field
                  name={"Flow_pattern2"}
                  label={""}
                  options={{
                    type: "text",
                    disabled: option === "view" || lastoff === true,
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={1}>
                <Lable value={"II"} />
              </td>
              <td colSpan={2}>
                <Lable value={"MEASURING AND TESTING"} />
              </td>
              <td colSpan={1}>
                <Lable value={"SPEC"} />
              </td>
              <td colSpan={2}>
                {" "}
                <Field
                  name={"Spec1"}
                  label={""}
                  //  style={{ padding: "0px" }}
                  options={{
                    type: "text",
                    disabled: option === "view" || firstoff === true,
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
              <td colSpan={2}>
                {" "}
                <Field
                  name={"Spec2"}
                  label={""}
                  //  style={{ padding: "0px" }}
                  options={{
                    type: "text",
                    disabled: option === "view" || lastoff === true,
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={1}>
                <Lable value={"1"} />
              </td>
              <td colSpan={2}>
                <Lable value={"GLOSS VALUE"} />
              </td>
              <td colSpan={1}>
                <Field
                  name={"Gloss_value"}
                  //  style={{ padding: "0px" }}
                  options={{
                    type: "text",
                    // required: true,
                    disabled: option === "view",
                  }}
                  // warning={warning}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
                <PopupPdf />
              </td>
              {/* <td colSpan={2}>
                 <Field
                  name={"Gloss_value1"}
               
                //  style={{ padding: "0px" }}
                  options={{
                    type: "Icon",
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                /> 
                <PopupPdf /></td>
                {/* <td colSpan={4}>
    <label for="file-upload" class="custom-file-upload">
        <i class="fas fa-cloud-upload-alt"></i> Upload File
    </label>
    <input id="file-upload" type="file"/>
</td> */}

              <td colSpan={2}>
                <Field
                  name={"Gloss_value_img1"}
                  //  style={{ padding: "0px" }}
                  accept="image/*,application/pdf"
                  warning={firstoff === false ? warning : null}
                  options={{
                    type: "Icon1",
                    disabled: option === "view" || firstoff === true,
                    required: true,
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
              <td colSpan={2}>
                <Field
                  name={"Gloss_value_img2"}
                  //  style={{ padding: "0px" }}
                  options={{
                    type: "Icon1",
                    padding: "0px",
                    disabled: option === "view" || lastoff === true,
                  }}
                  warning={lastoff === false ? warning : null}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
            </tr>

            <tr>
              <td colSpan={1}>
                <Lable value={"2"} />
              </td>
              <td colSpan={2}>
                <Lable value={"DFC TEST"} />
              </td>
              <td colSpan={1}>
                <Field
                  name={"Dfc_set"}
                  label={""}
                  //  style={{ padding: "0px" }}
                  options={{
                    type: "text",
                    disabled: option === "view",
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
              <td colSpan={1}>
                {/* <Lable value={"PRIMER"} /> */}
                <Field
                  name={"Primer_image1"}
                  label={"PRIMER"}
                  doubleLine={true}
                  //  style={{ padding: "0px" }}
                  options={{
                    type: "Icon1",
                    disabled: option === "view" || firstoff === true,
                  }}
                  warning={firstoff === false ? warning : null}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
              <td colSpan={1}>
                <Field
                  name={"Base_image1"}
                  label={"BASE"}
                  doubleLine={true}
                  //  style={{ padding: "0px" }}
                  options={{
                    type: "Icon1",
                    disabled: option === "view" || firstoff === true,
                  }}
                  warning={firstoff === false ? warning : null}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
              <td colSpan={1}>
                {/* <Lable value={"PRIMER"} /> */}
                <Field
                  name={"Primer_image2"}
                  label={"PRIMER"}
                  doubleLine={true}
                  //  style={{ padding: "0px" }}
                  options={{
                    type: "Icon1",
                    disabled: option === "view" || lastoff === true,
                  }}
                  warning={lastoff === false ? warning : null}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>

              <td colSpan={1}>
                <Field
                  name={"Base_image2"}
                  label={"BASE"}
                  //  style={{ padding: "0px" }}
                  doubleLine={true}
                  options={{
                    type: "Icon1",
                    disabled: option === "view" || lastoff === true,
                  }}
                  warning={lastoff === false ? warning : null}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
              {/*              
                <td colSpan={1}style={{width:"2%"}}>
                // {/* <Lable value={"PRIMER"} />
                 <Field
                  name={"Last_Primer"}
                  label={"PRIMER"}
                  options={{
                    type: "Icon",
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
                </td> */}

              {/* <td colSpan={1}style={{width:"2%"}}>
              <Field
                  name={"Last_base"}
                  label={"BASE"}
                  options={{
                    type: "text",
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
                </td> */}
            </tr>
            <tr>
              <td colSpan={1}>
                <Lable value={"3"} />
              </td>
              <td colSpan={2}>
                <Lable value={"PEEL OFF TEST"} />
              </td>
              <td colSpan={1}>
                <Lable value={"FREE FROM PEEL OFF"} />
              </td>
              <td colSpan={2}>
                <Field
                  name={"Free_from_peel1"}
                  label={""}
                  style={{ padding: "0px" }}
                  options={{
                    type: "Icon1",
                    disabled: option === "view" || firstoff === true,
                  }}
                  warning={firstoff === false ? warning : null}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
              <td colSpan={2}>
                <Field
                  name={"Free_from_peel2"}
                  label={""}
                  style={{ padding: "0px" }}
                  options={{
                    type: "Icon1",
                    disabled: option === "view" || lastoff === true,
                  }}
                  warning={lastoff === false ? warning : null}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
              {/* <td colSpan={2}><Field
                  name={"Last_free_from_peel"}
                  label={""}

                  style={{ padding: "0px" }}
                  options={{
                    type: "Icon",
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                /></td> */}
            </tr>
            <tr>
              <td colSpan={1}>
                <Lable value={"4"} />
              </td>
              <td colSpan={2}>
                <Lable value={"Color VALUE"} />
              </td>
              <td colSpan={1}>
                <Field
                  name={"Color_value"}
                  label={""}
                  style={{ padding: "0px" }}
                  options={{
                    type: "text",
                    disabled: option === "view",
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
              <td colSpan={2}>
                {" "}
                <Field
                  name={"Color_value_img1"}
                  label={""}
                  style={{ padding: "0px" }}
                  options={{
                    type: "Icon1",
                    disabled: option === "view" || firstoff === true,
                  }}
                  warning={firstoff === false ? warning : null}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
              <td colSpan={2}>
                {" "}
                <Field
                  name={"Color_value_img2"}
                  label={""}
                  style={{ padding: "0px" }}
                  options={{
                    type: "Icon1",
                    disabled: option === "view" || lastoff === true,
                  }}
                  warning={lastoff === false ? warning : null}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
              {/* <td colSpan={2}> <Field
                  name={"Color_value2"}
                  label={""}

                  style={{ padding: "0px" }}
                  options={{
                    type: "Icon",
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                /></td> */}
            </tr>

            {/* <tr>
              <td colSpan={1}>
                <Lable value={"3"} />
              </td>
              <td colSpan={2}>
                <Lable value={"PEEL OFF TEST"} />
              </td>
              <td colSpan={1}>
                <Lable value={"FREE FROM PEEL OFF"} />
              </td>
              <td colSpan={2}><Field
                  name={"First_free_from_peel"}
                  label={""}

                  style={{ padding: "0px" }}
                  options={{
                    type: "Icon",
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                /></td>
              <td colSpan={2}><Field
                  name={"Last_free_from_peel"}
                  label={""}

                  style={{ padding: "0px" }}
                  options={{
                    type: "Icon",
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                /></td>
            </tr> */}
            <tr>
              <td colSpan={1}>
                <Lable value={"III"} type={"Head"} />
              </td>
              <td colSpan={7}>
                <Lable value={"PROCESS CHECKING"} type={"Head"} />
              </td>
            </tr>
            <tr>
              <td colSpan={1}>
                <Lable value={"1"} />
              </td>
              <td colSpan={1}>
                <Lable value={"OVEN TEMPERATURE 80+5 °C"} />
              </td>
              <td colSpan={3}>
                {" "}
                <Field
                  name={"Oven_temp1"}
                  label={""}
                  style={{ padding: "0px" }}
                  options={{
                    type: "Icon2",
                    disabled: option === "view" || firstoff === true,
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
              <td colSpan={3}>
                {" "}
                <Field
                  name={"Oven_temp2"}
                  label={""}
                  style={{ padding: "0px" }}
                  options={{
                    type: "Icon2",
                    disabled: option === "view" || lastoff === true,
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={1}>
                <Lable value={"2"} />
              </td>
              <td colSpan={1}>
                <Lable value={"GUN PRESSURE 4 TO 6 KG/CM2"} />
              </td>
              <td colSpan={3}>
                {" "}
                <Field
                  name={"Gun_pressure1"}
                  label={""}
                  style={{ padding: "0px" }}
                  options={{
                    type: "Icon2",
                    disabled: option === "view" || firstoff === true,
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
              <td colSpan={3}>
                {" "}
                <Field
                  name={"Gun_pressure2"}
                  label={""}
                  style={{ padding: "0px" }}
                  options={{
                    type: "Icon2",
                    disabled: option === "view" || lastoff === true,
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={1}>
                <Lable value={"3"} />
              </td>
              <td colSpan={1}>
                <Lable value={"BOOTH TEMPERATURE -(25 - 35°C)"} />
              </td>
              <td colSpan={3}>
                {" "}
                <Field
                  name={"Booth_temp1"}
                  label={""}
                  style={{ padding: "0px" }}
                  options={{
                    type: "Icon2",
                    disabled: option === "view" || firstoff === true,
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
              <td colSpan={3}>
                {" "}
                <Field
                  name={"Booth_temp2"}
                  label={""}
                  style={{ padding: "0px" }}
                  options={{
                    type: "Icon2",
                    disabled: option === "view" || lastoff === true,
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={1}>
                <Lable value={"4"} />
              </td>
              <td colSpan={1}>
                <Lable value={"BOOTH HUMIDITY - 50 - 80 %"} />
              </td>
              <td colSpan={3}>
                {" "}
                <Field
                  name={"Booth_humidity1"}
                  label={""}
                  style={{ padding: "0px" }}
                  options={{
                    type: "Icon2",
                    disabled: option === "view" || firstoff === true,
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
              <td colSpan={3}>
                {" "}
                <Field
                  name={"Booth_humidity2"}
                  label={""}
                  style={{ padding: "0px" }}
                  options={{
                    type: "Icon2",
                    disabled: option === "view" || lastoff === true,
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={1}>
                <Lable value={"5"} />
              </td>
              <td colSpan={1}>
                <Lable value={"PAINT MIXING RATIO"} />
              </td>
              <td colSpan={3}>
                {" "}
                <Field
                  name={"Paint_mixing_ratio1"}
                  label={""}
                  style={{ padding: "0px" }}
                  options={{
                    type: "Icon2",
                    disabled: option === "view" || firstoff === true,
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
              <td colSpan={3}>
                {" "}
                <Field
                  name={"Paint_mixing_ratio2"}
                  label={""}
                  style={{ padding: "0px" }}
                  options={{
                    type: "Icon2",
                    disabled: option === "view" || lastoff === true,
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={1}>
                <Lable value={"6"} />
              </td>
              <td colSpan={1}>
                <Lable value={"VISCOSITY"} />
              </td>
              <td colSpan={3}>
                {" "}
                <Field
                  name={"Viscosity1"}
                  label={""}
                  style={{ padding: "0px" }}
                  options={{
                    type: "Icon2",
                    disabled: option === "view" || firstoff === true,
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
              <td colSpan={3}>
                {" "}
                <Field
                  name={"Viscosity2"}
                  label={""}
                  style={{ padding: "0px" }}
                  options={{
                    type: "Icon2",
                    disabled: option === "view" || lastoff === true,
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={1}>
                <Lable value={"7"} />
              </td>
              <td colSpan={1}>
                <Lable value={"GUN FLOW RATE"} />
              </td>
              <td colSpan={3}>
                {" "}
                <Field
                  name={"Gun_flow_rate1"}
                  label={""}
                  style={{ padding: "0px" }}
                  options={{
                    type: "Icon2",
                    disabled: option === "view" || firstoff === true,
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
              <td colSpan={3}>
                {" "}
                <Field
                  name={"Gun_flow_rate2"}
                  label={""}
                  style={{ padding: "0px" }}
                  options={{
                    type: "Icon2",
                    disabled: option === "view" || lastoff === true,
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={1}>
                <Lable value={"8"} />
              </td>
              <td colSpan={1}>
                <Lable value={"CURING OVEN CONVEYOR SPEED"} />
              </td>
              <td colSpan={3}>
                {" "}
                <Field
                  name={"Cur_oven_con_speed1"}
                  label={""}
                  style={{ padding: "0px" }}
                  options={{
                    type: "Icon2",
                    disabled: option === "view" || firstoff === true,
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
              <td colSpan={3}>
                {" "}
                <Field
                  name={"Cur_oven_con_speed2"}
                  label={""}
                  style={{ padding: "0px" }}
                  options={{
                    type: "Icon2",
                    disabled: option === "view" || lastoff === true,
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={8}>
                <Field
                  label={"REMARKS"}
                  name={"Remarks"}
                  // style={{ padding: "0px" }}
                  options={{
                    type: "text",
                    disabled: option === "view",
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
              {/* <td colSpan={7}></td> */}
            </tr>
            <tr>
              <td colSpan={3}>
                <Field
                  label={"CHECKED BY"}
                  name={"Checked_by"}
                  options={{
                    type: "reference",
                    reference: "emp_details_dropdown",
                    required: true,
                    disabled: option === "view",
                  }}
                  warning={warning}
                  value={{
                    value: Records[0].checkedby[0]
                      ? Records[0].checkedby[0].Emp_code
                      : "",
                    label: Records[0].checkedby[0]
                      ? Records[0].checkedby[0].Emp_name
                      : "",
                  }}
                  OnChange={(e) => {
                    HandleRecordSelect(e, "checkedby", "Emp_code", "Emp_name");
                  }}
                />
              </td>
              <td colSpan={2}>
                <Field
                  label={"VERIFIED BY"}
                  name={"Verified_by"}
                  options={{
                    type: "reference",
                    reference: "emp_details_dropdown",
                    required: true,
                    disabled: option === "view",
                  }}
                  warning={warning}
                  value={{
                    value: Records[0].verifiedby[0]
                      ? Records[0].verifiedby[0].Emp_code
                      : "",
                    label: Records[0].verifiedby[0]
                      ? Records[0].verifiedby[0].Emp_name
                      : "",
                  }}
                  OnChange={(e) => {
                    HandleRecordSelect(e, "verifiedby", "Emp_code", "Emp_name");
                  }}
                />
              </td>
              <td colSpan={3}>
                <Field
                  label={"APPROVED BY"}
                  name={"Approved_by"}
                  //  style={{ padding: "0px" }}
                  options={{
                    type: "reference",
                    reference: "emp_details_dropdown",
                    required: true,
                    disabled: option === "view",
                  }}
                  warning={warning}
                  value={{
                    value: Records[0].approvedby[0]
                      ? Records[0].approvedby[0].Emp_code
                      : "",
                    label: Records[0].approvedby[0]
                      ? Records[0].approvedby[0].Emp_name
                      : "",
                  }}
                  OnChange={(e) => {
                    HandleRecordSelect(e, "approvedby", "Emp_code", "Emp_name");
                  }}
                />
              </td>
            </tr>

            {!(option === "view") && (
              <tr>
                {id === undefined && Records[0].Id === "0" ? (
                  <td colSpan={8}>
                    <Field
                      label={"Submit"}
                      options={{ type: "Button" }}
                      onClick={(e) => {
                        HandleCreate(e);
                      }}
                    />
                  </td>
                ) : (
                  <td colSpan={8}>
                    <Field
                      label={"Update"}
                      options={{ type: "Button" }}
                      onClick={(e) => {
                        HandleUpdate(e);
                      }}
                    />
                  </td>
                )}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </QaLayout>
  );
};

export default Firstandlastoff;
