import React, { useEffect, useState } from "react";
import "../../../Styles/Part3.css";
import { SECRET_KEY, CODEGEN_URL, PROCESS_TRANSACTION_URL } from "../../../Utils/constants.js";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import DropdownFetcher from "../../../Lib/DropdownFetcher.js";
import Select from "react-select";
import ReadRecord from "../../../Lib/API/ReadRecord.js";

const initialState = {

  SelectedOption: "",
  // SelectedOption1: "",
  MacNme: "",
  PrtNo: "",
  PrtName: "",
  RawMat: "",
  Flexy1: "",
  Flexy2: "",
  Flexy3: "",
  ActSts: "",
  mas_hd: []
}

function MasterHeadCreate({tab1}) {
  const [isCreating, setIsCreating] = useState(false);
  const [dropdown, setDropdown] = useState([]);
  const [Prtdropdown, setPrtDropdown] = useState([]);
  const [PartDropdownName, setPartDropdownName] = useState([]);
  const [Rmdropdown, setRmDropdown] = useState([]);
  const [MasterHeadState, setMasterHeadState] = useState(initialState);

  useEffect(()=>{
console.log("MasterHeadState useeffect",MasterHeadState);
  },[MasterHeadState]);
  useEffect(()=>{
   setMasterHeadState(initialState)
      },[tab1]);
  // const CodeGen = async () => {
  //   const val = {
  //     tablename: "master_head_details",
  //     PrimaryCode: "id",
  //     PrimaryVal: MasterHeadState.Id,
  //     FieldCode: "mh_code",
  //     FieldVal: MasterHeadState.MsCde,
  //   };

  //   const response = await getAPIRequest(
  //     CODEGEN_URL, 'POST', val
  //   );
  //   const response1 = await response.json();
  //   // console.log("response1", response1);
  //   return (response1.success === 'true');

  // };
  const mcNoOptions = dropdown.map((i) => ({
    value: i.mc_code,
    label: i.mc_name,
  }));
  const PrtNoOptions = Prtdropdown.map((i) => ({
    value: i.mc_part_code,
    label: i.mc_part_code,
  }));
  const PrtNameOptions = PartDropdownName.map((i) => ({
    value: i.mc_part_name,
    label: i.mc_part_name,
  }));
  const RmNoOptions = Rmdropdown.map((i) => ({
    value: i.Rm_code,
    label: i.Rm_description,
  }));
  const handleDropdownChange = (selectedOption) => {
    const selectedMachine = dropdown.find(
      (option) => option.mc_name === selectedOption.label
    );
    if (selectedMachine) {
      setMasterHeadState({
        ...MasterHeadState,
        MacNme: {
          value: selectedMachine.mc_code,
          label: selectedMachine.mc_name,
        }
      });
    }
  };
  function GetPartNameByPartCode(val) {
    return ReadRecord({
      _keyword_: "Get_part_name_from_master_by_code",
      key: val,
    }).then(async (res) => {
      if (res.rows === "1") {
        // console.log("res.data",res.data);
        return res.data;
        // setMasterHeadState({
        //   PrtName: {
        //     value: res.data[0].mc_part_name,
        //     label: res.data[0].mc_part_name,
        //   }
        // })
      } else if (res.rows > "1") {
        setPartDropdownName(res.data);
      }
    })
  }
  // useEffect(()=>{ console.log("MasterHeadState",MasterHeadState);},[MasterHeadState])
  const handlePrtDropdownChange = async (selectedOption) => {
    MasterHeadState.PrtName = "";
    // console.log("selectedOption",selectedOption);
    const PartNameLoad = await GetPartNameByPartCode(selectedOption.value);
    console.log("PartNameLoad", PartNameLoad);
    const selectedMachine = Prtdropdown.find(
      (option) => option.mc_part_code === selectedOption.value
    );
    // console.log("selectedMachine1",selectedMachine);

    if (selectedMachine != "")
      // console.log("selectedMachine2",selectedMachine);
      if (PartNameLoad === undefined) {
        setMasterHeadState({
          ...MasterHeadState,
          PrtNo: {
            value: selectedMachine.mc_part_code,
            label: selectedMachine.mc_part_code,
          },
        })
      } else {
        setMasterHeadState({
          ...MasterHeadState,
          PrtNo: {
            value: selectedMachine.mc_part_code,
            label: selectedMachine.mc_part_code,
          },
          PrtName: {
            value: PartNameLoad[0].mc_part_name === undefined ? "" : PartNameLoad[0].mc_part_name,
            label: PartNameLoad[0].mc_part_name === undefined ? "" : PartNameLoad[0].mc_part_name,
          }
        });
      }


  };
  function GetPartCodeByPartName(val) {
    MasterHeadState.PrtNo = "";
     return ReadRecord({
      _keyword_: "Get_part_code_from_head_by_name",
      key: val,
    }).then(async (res) => {
      if (res.rows === "1") {
        // setMasterHeadState({
        //   PrtNo: {
        //     value: res.data[0].mc_part_code,
        //     label: res.data[0].mc_part_code,
        //   }
        // })
        return res.data
      } else if (res.rows > "1") {
        setPrtDropdown(res.data);
      }
    })
  }
  const handlePrtNameDropdownChange = async (selectedOption) => {
    const partcode = await GetPartCodeByPartName(selectedOption.value);
    const selectedMachine = PartDropdownName.find(
      (option) => option.mc_part_name === selectedOption.value
    );
    console.log("selectedMachine",selectedMachine);
    if (selectedMachine) {

      setMasterHeadState({
        ...MasterHeadState,
        PrtName: {
          value: selectedMachine.mc_part_name,
          label: selectedMachine.mc_part_name,
        }
        ,PrtNo: {
              value: partcode[0].mc_part_code,
              label: partcode[0].mc_part_code,
            }
      });
    }
  };
  const handleRmDropdownChange = (selectedOption) => {
    const selectedRm = Rmdropdown.find((option) => option.Rm_code === selectedOption.value);
    if (selectedRm) {
      setMasterHeadState({
        ...MasterHeadState,
        RawMat: {
          value: selectedRm.Rm_code,
          label: selectedRm.Rm_description,
        }
      });
    }
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    console.log("MasterHeadState",MasterHeadState);
    // const isSubmissionSuccessful = true;
    if (!MasterHeadState.MacNme.value || !MasterHeadState.PrtNo.value || !MasterHeadState.PrtName.value || !MasterHeadState.RawMat.value || !MasterHeadState.SelectedOption) {
      setIsCreating(true);
      alert(GeneralConstants.MANDATORY_ALERT);
      return;
    } else {
      // console.log("field",MasterHeadState);
      await ReadRecord({
        _keyword_: "Duplicate_from_head_master",
        MacNme: MasterHeadState.MacNme.value,
        PrtNo: MasterHeadState.PrtNo.value,
        PrtName: MasterHeadState.PrtName.value,
        RawMat: MasterHeadState.RawMat.value,
      }).then((res) => {
        if (res.rows > "0") {
          alert("The Combination Record already Existed!")
        } else if (res.rows == "0") {
          CreateFunction();
        }
      });
    }
  };
  async function CreateFunction() {
    try {
      const data = {
        data: [
          {
            // Mh_bay: MasterHeadState.SelectedOption1,
            Mh_mac_name: MasterHeadState.MacNme.value,
            Mh_prt_no: MasterHeadState.PrtNo.value,
            Mh_prt_name: MasterHeadState.PrtName.value,
            Mh_raw_mat: MasterHeadState.RawMat.value,
            Mh_flex_01: MasterHeadState.Flexy1,
            Mh_flex_02: MasterHeadState.Flexy2,
            Mh_flex_03: MasterHeadState.Flexy3,
            Mh_active_status: MasterHeadState.SelectedOption,
          },
        ],
      };
      data["_keyword_"] = "master_head_details";
      data["secretkey"] = SECRET_KEY;
      const response = await getAPIRequest(
        PROCESS_TRANSACTION_URL, 'POST', data
      );
      const response1 = await response.json();
      // console.log("response1",response1.feedback[0].success);

      // if (response1.success === "false") {
      //   alert(GeneralConstants.FAIL_TO_SAVE_MACHINE);
      //   window.location.reload();
      // } else {
      //   const macmas = [...MasterHeadState.mas_hd, response.data]
      //   setMasterHeadState({ ...MasterHeadState, mas_hd: macmas });

      // }
      if (response1.feedback[0].success) {
        alert(`Master Head Code ${GeneralConstants.SUBMISSION_SUCCESSFUL}`);
        setMasterHeadState({
          ...MasterHeadState, ...initialState
        });
        setIsCreating(false);
      }
    } catch (error) {
      // console.log(error);
      alert(`${GeneralConstants.SUBMISSION_FAILED}`);
      window.location.reload();
    }
  }
  function GetSelectValue(value, keyword, setName) {
    if (value.length > 0) {
      ReadRecord({
        _keyword_: keyword,
        key: "%" + value + "%",
        value: "%" + value + "%"
      }).then((res) => {
        console.log(res.data);
        setName(res.data);
        // setKey(keyvalue[options.reference]);
      });
    }
  }

  return (
    <div id="MasterHeadCreate.formContainer" name="MasterHeadCreate.formContainer">
      <form
        className="form-MasterHead-Create">
        <table style={{ textAlign: "center", width: "100%" }} cellPadding="5">
          <tbody>
            <tr>
              <th
                id="mhd.Title"
                name="mhd.Title"
                colSpan="4"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">Master Head</font>
              </th>
            </tr>
            <tr>
              {/* <th>&nbsp;&nbsp; Bay<span className="Req-i">*</span></th>
              <td>
              <select
             value={MasterHeadState.SelectedOption1}
             onChange={(e) => setMasterHeadState({ ...MasterHeadState,
             SelectedOption1: e.target.value })}
             style={{ width: "36%", textAlign: "center" }}
               >
  <option value="">-- Select --</option>
  {[...Array(20).keys()].map((value) => (
    <option key={value + 1} value={String(value + 1)}>
      {value + 1}
    </option>
 ) )}
</select>
              </td> */}
              <th>&nbsp;&nbsp;Part Number<span className="Req-i">*</span></th>
              <td>
                {/* <DropdownFetcher
                  keyword="mac_part_dtl_dropdown"
                  setValue={setPrtDropdown}
                /> */}
                 {MasterHeadState.PrtNo === "" && isCreating && (
                  <span className="Req">Required *</span>
                )}
                <Select
                  value={MasterHeadState.PrtNo}
                  onChange={handlePrtDropdownChange}
                  onInputChange={(event) => { GetSelectValue(event, "Get_part_code_from_master", setPrtDropdown) }}
                  options={PrtNoOptions}
                  placeholder="Search Part No..."
                />
              </td>

              <th>&nbsp;&nbsp;Part Name<span className="Req-i">*</span></th>
              <td>
                {/* <DropdownFetcher
                  keyword="mac_part_dtl_dropdown"
                  setValue={setPrtDropdown}
                /> */}
                 {MasterHeadState.PrtName === "" && isCreating && (
                  <span className="Req">Required *</span>
                )}
                <Select
                  value={MasterHeadState.PrtName}
                  onChange={handlePrtNameDropdownChange}
                  onInputChange={(event) => { GetSelectValue(event, "mac_part_dtl_dropdown", setPartDropdownName) }}
                  options={PrtNameOptions}
                  placeholder="Search Part Name..."
                />
              </td>
            </tr>
            <tr>

              <th>&nbsp;&nbsp;Raw material<span className="Req-i">*</span></th>
              <td>
                {/* <DropdownFetcher
                  keyword="rm_details_dropdown"
                  setValue={setRmDropdown}
                /> */}
                 {MasterHeadState.RawMat === "" && isCreating && (
                  <span className="Req">Required *</span>
                )}
                <Select
                  value={MasterHeadState.RawMat}
                  onChange={handleRmDropdownChange}
                  options={RmNoOptions}
                  placeholder="Search RMI..."
                  onInputChange={(event) => { GetSelectValue(event, "rm_details_dropdown", setRmDropdown) }}

                />
              </td>

              <th>&nbsp;&nbsp;Machine Name<span className="Req-i">*</span></th>
              <td>
                <DropdownFetcher
                  keyword="mac_dtl_dropdown"
                  setValue={setDropdown}
                />
                 {MasterHeadState.MacNme === "" && isCreating && (
                  <span className="Req">Required *</span>
                )}
                <Select
                  value={MasterHeadState.MacNme}
                  onChange={handleDropdownChange}
                  options={mcNoOptions}
                  placeholder="Select Machine Name" />
              </td>

            </tr>
            <tr>
              <th>&nbsp;&nbsp;Active Status<span className="Req-i">*</span></th>
              <td colSpan={1}>
              {MasterHeadState.SelectedOption === "" && isCreating && (
                  <span className="Req">Required *</span>
                )}
                <select
                  id="mhd.ActiveStatus"
                  name="mhd.ActiveStatus"
                  value={MasterHeadState.SelectedOption}
                  onChange={(e) => setMasterHeadState({
                    ...MasterHeadState,
                    SelectedOption: e.target.value
                  })}
                  style={{ width: "90%", textAlign: "center" }}
                >
                  <option id="mhd.ActiveStatus.select"
                    name="mhd.ActiveStatus.select"
                    value="">-- Select --</option>
                  <option id="mhd.ActiveStatus.active"
                    name="mhd.ActiveStatus.active"
                    value="Active">Active</option>
                  <option id="mhd.ActiveStatus.inactive"
                    name="mhd.ActiveStatus.inactive"
                    value="InActive">InActive</option>
                </select>
              </td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td colSpan="4" style={{ border: "none" }}>
                <button id="mhd.submit" name="mhd.submit" type="submit" onClick={(e) => handleCreate(e)}>
                  Submit
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
}

export default MasterHeadCreate;
