import React from "react";

import QaLayoutPaint from "../../QaLayoutPaint";
import ListViewPaint from "../../../../Components/ListView/ListViewPaint";

const HardnerMasterList = () => {
 
  return (
    <QaLayoutPaint quary={"paint_hardner_master"}>
    <div>
      <ListViewPaint
        Title={"Paint Hardner Master"}
        standard={["delete_id=0"]}
        quary={"Select Id,hardner_code,hardner_name,ActiveStatus,CreatedDate from paint_hardner_master group by Id  ORDER BY CASE WHEN ActiveStatus = 'Active' THEN 0 ELSE 1 END,Id DESC"}
        table={"paint_hardner_master"}
        tableDelete={"paint_hardner_master"}
        navigation={"PaintHardnerMaster"}
        filter={["Id","hardner_code","hardner_name","ActiveStatus"]}
        dateFilter={"CreatedDate"}
        header={[
          { value: "Id", label: "ID" },
          { value: "CreatedDate", label: "Date" },
          { value: "hardner_code", label: "Hardner Code" },
          { value: "hardner_name", label: "Hardner Name" },
          { value: "ActiveStatus", label: "Active Status" },
        ]}
      />   
    </div>
    </QaLayoutPaint>
  );
};

export default HardnerMasterList;
