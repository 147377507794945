import React,{useState,useEffect} from "react";
import QaLayoutPaint from "../../QaLayoutPaint";
import ListViewPaint from "../../../../Components/ListView/ListViewPaint";

const ThinnerMasterList = () => {
 
  return (
    <QaLayoutPaint quary={"paint_thinner_master"}>
    <div>
      <ListViewPaint
        Title={"Paint Thinner Master"}
        standard={["delete_id=0"]}
        quary={"Select Id,thinner_code,thinner_name,ActiveStatus,CreatedDate from paint_thinner_master group by Id ORDER BY CASE WHEN ActiveStatus = 'Active' THEN 0 ELSE 1 END,Id DESC"}
        table={"paint_thinner_master"}
        tableDelete={"paint_thinner_master"}
        navigation={"PaintThinnerMaster"}
        filter={["Id","thinner_code","thinner_name","ActiveStatus"]}
        dateFilter={"CreatedDate"}
        header={[
          { value: "Id", label: "ID" },
          { value: "CreatedDate", label: "Date" },
          { value: "thinner_code", label: "Thinner Code" },
          { value: "thinner_name", label: "Thinner Name" },
          { value: "ActiveStatus", label: "Active Status" },
        ]}
      />   
    </div>
    </QaLayoutPaint>
  );
};

export default ThinnerMasterList;
