import React, { useEffect, useState } from "react";
import GetRecord from "../../Lib/GetRecord";
import { useNavigate } from "react-router-dom";
import UpdateRecord from "../../Lib/updateRecord";

function NotificationList() {
  const navigate = useNavigate();
  const [selectedFilter, setSelectedFilter] = useState("all");
  var [not, setnot] = useState([]);
  const filteredMessages = () => {
    if (selectedFilter === "all") {
      return not;
    } else if (selectedFilter === "unread") {
      return not.filter((entry) => entry.Note_readed == 0);
    } else {
      return [];
    }
  };
  useEffect(() => {
    document.title = "All Messages"
  }, [])
  useEffect(() => {
    {
      GetRecord(
        {
          _keyword_: "Notification_details_all_user",
          note_to: localStorage.getItem("empId"),
        },
        setnot
      );
    }
  }, []);
  return (
    <div>
      <h4>All Notifications</h4>
      <div className="filter-dropdown">
        <select
          value={selectedFilter}
          onChange={(e) => setSelectedFilter(e.target.value)}
          style={{ marginLeft: "0px", width: "150px" }}
        >
          <option value="">Filter:</option>
          <option value="all">All Messages</option>
          <option value="unread">Unread Messages</option>
        </select>
      </div>
      {(selectedFilter === "all" || selectedFilter === "unread") &&
        filteredMessages}
      <br />
      <table>
        <thead>
          <tr>
            {/* <th style={{ width: "5%" }}> */}
            {/* <img src={Unitechlogo} style={{ width: "50px" }}></img> */}
            {/* </th> */}
            <th
              colSpan="5"
              style={{
                textAlign: "center",
                whiteSpace: "nowrap",
                padding: "20px 0px",
              }}
            >
              <font style={{ fontSize: "24px" }}>
              KASH Tech LLC.
              </font>
              <br></br>
              {/* <font color="#8B0000">NotificationList</font> */}
            </th>
          </tr>

          <tr className="table_position">
            <th style={{ whiteSpace: "nowrap" }}></th>
            <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>From</th>
            <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
              Message
            </th>
            <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>Date/Time</th>
            <th style={{width:"10%"}}></th>
          </tr>
        </thead>
        <tbody>
          {/* <HandleRead keyword="Notification_detailsAll" setData={setnot} /> */}
          {filteredMessages().map((entry, id) => (
            <tr className="table_view" key={id}>
              <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                <input
                  type="checkbox"
                  checked={entry.Note_readed > 0}
                  readOnly
                />
              </td>
              <td
                role="img"
                aria-label="User Symbol"
                style={{ whiteSpace: "nowrap" }}
              >
                👤 {entry.emp_name}
              </td>
              <td
                role="img"
                aria-label="Message Symbol"
                style={{ whiteSpace: "inherit" }}
              >
                💬 {entry.Note_msg}
              </td>
              <td>
                {new Date(entry.Note_created_date).toLocaleString('en-IN', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </td>
              <td>
                <button
                  className="icon"
                  style={{ backgroundColor: "#cba423" }}
                  type="button"
                  onClick={() => {
                    // navigate("/EcnReport/view/1");
                    navigate("/" + entry.Note_record_table + "/view/" + entry.Note_record_id)
                    UpdateRecord({
                      _keyword_: 'Notification_details',
                      data: [{
                        Id: entry.Id,
                        Note_readed_dropdown: '1',
                        Note_readed: '1'
                      }]
                    })
                  }}
                >
                  view
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* <br />
      <button style={{ align: "center" }} onClick={onClose}>
        Close
      </button> */}
    </div>
  );
}
export default NotificationList;
