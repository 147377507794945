import React, { useEffect, useState } from "react";
import {
  PROCESS_TRANSACTION_URL,
  CODEGEN_URL,
  SECRET_KEY,
} from "../../../Utils/constants.js";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import IsDuplicate from "../../../Lib/IsDuplicate.js";
import CreateRecord from "../../../Lib/API/CreateRecord.js";

const initialState = {
  Id: "",
  SelectedOption: "",
  SupCde: "",
  SupNme: "",
  Flexy1: "",
  Flexy2: "",
  Flexy3: "",
  Flexy4: "",
  sup_mas: [],
};

function SupplierMasterCreate({ tab1 }) {
  const [isCreating, setIsCreating] = useState(false);
  const [supplierMasterState, setSupplierMasterState] = useState(initialState);

  const CodeGen = async () => {
    try {
      const val = {
        tablename: "supplier_details",
        PrimaryCode: "id",
        PrimaryVal: supplierMasterState.Id,
        FieldCode: "sup_code",
        FieldVal: supplierMasterState.SupCde,
      };

      const response = await getAPIRequest(CODEGEN_URL, "POST", val);
      const response1 = await response.json();
      return response1.success === "true";
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    setSupplierMasterState(initialState);
  }, [tab1]);
  const handleCreate = async (e) => {
    e.preventDefault();

    const trimmedCode = supplierMasterState.SupCde.trim();
    const trimmedName = supplierMasterState.SupNme.trim();
    const isSubmissionSuccessful = true;

    if (!supplierMasterState.SupCde || !supplierMasterState.SupNme) {
      setIsCreating(true);

      alert(GeneralConstants.MANDATORY_ALERT);
      return;
    }
    try {
      const isDuplicateCode = await IsDuplicate("supplier_details", [
        `sup_code="${trimmedCode}"`,
        `ActiveStatus = 'true'`,
      ]);

      if (isDuplicateCode) {
        alert(`Supplier code ${trimmedCode} already exists.`);
        setIsCreating(false);
        return;
      }
      const isDuplicatename = await IsDuplicate("supplier_details", [
        `sup_name="${trimmedName}"`,
        `ActiveStatus = 'true'`,
      ]);

      if (isDuplicatename) {
        alert(`Supplier name ${trimmedName} already exists.`);
        setIsCreating(false);
        return;
      }
      const data = {
        data: [
          {
            sup_code: trimmedCode,
            sup_name: trimmedName,
            sup_flex_01: supplierMasterState.Flexy1,
            sup_flex_02: supplierMasterState.Flexy2,
            sup_flex_03: supplierMasterState.Flexy3,
            sup_flex_04: supplierMasterState.Flexy4,
          },
        ],
      };
      CreateRecord("supplier_details", data.data).then((res) => {
        if (res.feedback[0].success === true) {
          alert(
            `Supplier Code ${GeneralConstants.SUBMISSION_SUCCESSFUL}`
          );
          setSupplierMasterState({
            ...SupplierMasterCreate,
            ...initialState,
          });
          setIsCreating(false);
        }
      });
    } catch (error) {
      console.log(error);
      alert(
        `${supplierMasterState.sup_name} ${GeneralConstants.SUBMISSION_FAILED}`
      );
      window.location.reload();
    }
  };


  return (
    <div>
      <form
        id="supplierMasterCreate.formContainer"
        name="supplierMasterCreate.formContainer"
        className="form-supplierMaster-Create"
      >
        <table style={{ textAlign: "center", width: "100%" }} cellPadding="5">
          <tbody>
            <tr>
              <th
                id="sd.Title"
                name="sd.Title"
                colSpan="4"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">Supplier Master</font>
              </th>
            </tr>
            <tr>
              <th>
                &nbsp;&nbsp;Supplier Code{" "}
                <span style={{ color: "red" }}>*</span>
              </th>
              <td>
                {supplierMasterState.SupCde === "" && isCreating && (
                  <span className="Req">Required *</span>
                )}
                <input
                  id="sd.SuppliererCode"
                  name="sd.SupplierCode"
                  type="text"
                  value={supplierMasterState.SupCde}
                  onChange={(e) =>
                    setSupplierMasterState({
                      ...supplierMasterState,
                      SupCde: e.target.value,
                    })
                  }
                  required
                />
              </td>
              <th>
                &nbsp;&nbsp;Supplier Name<span style={{ color: "red" }}>*</span>
              </th>
              <td>
                {supplierMasterState.SupNme === "" && isCreating && (
                  <span className="Req">Required *</span>
                )}
                <input
                  id="sd.SupplierName"
                  name="sd.SupplierName"
                  type="text"
                  value={supplierMasterState.SupNme}
                  onChange={(e) =>
                    setSupplierMasterState({
                      ...supplierMasterState,
                      SupNme: e.target.value,
                    })
                  }
                  required
                />
              </td>
            </tr>
            <tr>
              <th>&nbsp;&nbsp;Flex 1</th>
              <td>
                <input
                  id="sd.Flexy1"
                  name="sd.Flexy1"
                  type="text"
                  value={supplierMasterState.Flexy1}
                  onChange={(e) =>
                    setSupplierMasterState({
                      ...supplierMasterState,
                      Flexy1: e.target.value,
                    })
                  }
                />
              </td>

              <th>&nbsp;&nbsp;Flex 2</th>
              <td>
                <input
                  id="sd.Flexy2"
                  name="sd.Flexy2"
                  type="text"
                  value={supplierMasterState.Flexy2}
                  onChange={(e) =>
                    setSupplierMasterState({
                      ...supplierMasterState,
                      Flexy2: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <th>&nbsp;&nbsp;Flex 3</th>
              <td>
                <input
                  id="sd.Flexy3"
                  name="sd.Flexy3"
                  type="text"
                  value={supplierMasterState.Flexy3}
                  onChange={(e) =>
                    setSupplierMasterState({
                      ...supplierMasterState,
                      Flexy3: e.target.value,
                    })
                  }
                />
              </td>

              <th>&nbsp;&nbsp;Flex 4</th>
              <td>
                <input
                  id="sd.Flexy4"
                  name="sd.Flexy4"
                  type="text"
                  value={supplierMasterState.Flexy4}
                  onChange={(e) =>
                    setSupplierMasterState({
                      ...supplierMasterState,
                      Flexy4: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td colSpan="4" style={{ border: "none" }}>
                <button
                  type="submit"
                  id="sd.submit"
                  name="sd.submit"
                  onClick={(e) => handleCreate(e)}
                >
                  Submit
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
}

export default SupplierMasterCreate;
