import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import MacBDCreate from "./MacBDCreate";
import MacBDView from "./MacBDView";

function MacBDTabs() {
  const [reportTabClicked, setReportTabClicked] = useState(false);

  const handleTabClick = (tabKey) => {
    if (tabKey === "tab3") {
      setReportTabClicked(true);
    } else {
      setReportTabClicked(false);
    }
  };
  
  return (
    <div>
      <div>
        <Tabs
          defaultActiveKey="tab1"
          // transition={false}
          id="noanim-tab-example"
          className="mb-3"
          onSelect={handleTabClick}
        >
           <Tab eventKey="tab1" title="Create">
            <div>
              <div className="set-layout">
                <MacBDCreate />
              </div>
            </div>
          </Tab>
          <Tab eventKey="tab2" title="View">
            <div>
              <div className="set-layout">
                <MacBDView />
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default MacBDTabs;
