import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import RMMasterCreate from "./RMMasterCreate";
import RmMasterListView from "./RmMasterListView";
import { useNavigate, useParams } from "react-router-dom";

function RMMasterTab() {
  const { tabKey } = useParams();
  const [activeKey, setActiveKey] = useState(tabKey || "tab1");
  const navigate = useNavigate();

  useEffect(() => {
    if (tabKey) {
      setActiveKey(tabKey);
    }
  }, [tabKey]);

  const handleTabClick = (key) => {
    setActiveKey(key);
    navigate(`/RMMaster/${key}`); 
  };

  return (
    <div>
      <Tabs
        activeKey={activeKey}
        onSelect={handleTabClick}
        id="noanim-tab-example"
        className="mb-3"
      >
        <Tab eventKey="tab1" title="Create">
          <div className="set-layout">
            <RMMasterCreate tab1={activeKey === "tab1"} />
          </div>
        </Tab>
        <Tab eventKey="tab2" title="View">
          <div className="set-layout">
            {activeKey === "tab2" && <RmMasterListView />}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

export default RMMasterTab;
