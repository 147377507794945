import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../../Utils/constants";
import Select from "react-select";
import Unitechlogo from "../../../icons/Unitechlogo.jpeg";
import { formatDt } from "../../../Lib/formatDt";

// import "./Part3.css";
import "../../../Styles/SmpView.css";
function SmpView() {
  // const [Id, setId] = useState("");
  const [TrackDate, setTrackDate] = useState("");
  const [McNo, setMcNo] = useState("");
  const [MatNme, setMatNme] = useState("");
  const [NoCav, setNoCav] = useState("");
  const [PrtNme, setPrtNme] = useState("");
  // const [PartNo, setPartNo] = useState("");
  const [GrdNme, setGrdNme] = useState("");
  const [Colour, setColour] = useState("");
  const [PrtWgt, setPrtWgt] = useState("");
  const [Runn, setRunn] = useState("");
  const [Inj101, setInj101] = useState("");
  const [Inj102, setInj102] = useState("");
  const [Inj103, setInj103] = useState("");
  const [Inj104, setInj104] = useState("");
  const [Modl, setModl] = useState("");
  const [Inj201, setInj201] = useState("");
  const [Inj202, setInj202] = useState("");
  const [Inj203, setInj203] = useState("");
  const [Inj204, setInj204] = useState("");
  const [Inj301, setInj301] = useState("");
  const [Inj302, setInj302] = useState("");
  const [Inj303, setInj303] = useState("");
  const [Inj304, setInj304] = useState("");
  const [Inj401, setInj401] = useState("");
  const [Inj402, setInj402] = useState("");
  const [Inj403, setInj403] = useState("");
  const [Inj404, setInj404] = useState("");

  const [FillT104, setFillT104] = useState("");

  const [Zone2, setZone2] = useState("");
  const [Zone1, setZone1] = useState("");
  const [Zone3, setZone3] = useState("");
  const [Hozz, setHozz] = useState("");
  const [Hopp, setHopp] = useState("");

  const [Hold101, setHold101] = useState("");
  const [Hold201, setHold201] = useState("");
  const [Hold301, setHold301] = useState("");
  const [Hold401, setHold401] = useState("");
  const [Hold102, setHold102] = useState("");
  const [Hold202, setHold202] = useState("");
  const [Hold302, setHold302] = useState("");
  const [Hold402, setHold402] = useState("");
  const [Hold103, setHold103] = useState("");
  const [Hold203, setHold203] = useState("");
  const [Hold303, setHold303] = useState("");
  const [Hold403, setHold403] = useState("");
  const [Hold104, setHold104] = useState("");
  const [Hold204, setHold204] = useState("");
  const [Hold304, setHold304] = useState("");
  const [Hold404, setHold404] = useState("");
  const [Core, setCore] = useState("");
  const [Cavity, setCavity] = useState("");
  const [Dely, setDely] = useState("");
  const [MTC, setMTC] = useState("");

  const [Fwd, setFwd] = useState("");

  const [OnTme, setOnTme] = useState("");
  const [RetSpd, setRetSpd] = useState("");
  const [SprBushHle, setSprBushHle] = useState("");
  const [NozzHole, setNozzHole] = useState("");

  const [ReFill101, setReFill101] = useState("");
  const [ReFill102, setReFill102] = useState("");
  const [ReFill103, setReFill103] = useState("");
  const [ReFill104, setReFill104] = useState("");
  const [ReFill201, setReFill201] = useState("");
  const [ReFill202, setReFill202] = useState("");
  const [ReFill203, setReFill203] = useState("");
  const [ReFill204, setReFill204] = useState("");
  const [ReFill301, setReFill301] = useState("");
  const [ReFill302, setReFill302] = useState("");
  const [ReFill303, setReFill303] = useState("");
  const [ReFill304, setReFill304] = useState("");

  const [Close101, setClose101] = useState("");
  const [Close102, setClose102] = useState("");
  const [Close103, setClose103] = useState("");
  const [Close201, setClose201] = useState("");
  const [Close202, setClose202] = useState("");
  const [Close203, setClose203] = useState("");
  const [Close301, setClose301] = useState("");
  const [Close302, setClose302] = useState("");
  const [Close303, setClose303] = useState("");

  const [EjecFrd101, setEjecFrd101] = useState("");
  const [EjecFrd102, setEjecFrd102] = useState("");
  const [EjecFrd103, setEjecFrd103] = useState("");
  const [EjecFrd104, setEjecFrd104] = useState("");
  const [EjecFrd201, setEjecFrd201] = useState("");
  const [EjecFrd202, setEjecFrd202] = useState("");
  const [EjecFrd203, setEjecFrd203] = useState("");
  const [EjecFrd204, setEjecFrd204] = useState("");
  const [EjecRet101, setEjecRet101] = useState("");
  const [EjecRet102, setEjecRet102] = useState("");
  const [EjecRet103, setEjecRet103] = useState("");
  const [EjecRet104, setEjecRet104] = useState("");
  const [EjecRet201, setEjecRet201] = useState("");
  const [EjecRet202, setEjecRet202] = useState("");
  const [EjecRet203, setEjecRet203] = useState("");
  const [EjecRet204, setEjecRet204] = useState("");

  const [RefillEnd, setRefillEnd] = useState("");

  const [MldSfy101, setMldSfy101] = useState("");
  const [MldSfy201, setMldSfy201] = useState("");
  const [MldSfy301, setMldSfy301] = useState("");
  const [MldSfy102, setMldSfy102] = useState("");
  const [MldSfy202, setMldSfy202] = useState("");
  const [MldSfy302, setMldSfy302] = useState("");

  const [SuckBack, setSuckBack] = useState("");

  const [Bp101, setBp101] = useState("");
  const [Bp102, setBp102] = useState("");
  const [Bp103, setBp103] = useState("");
  const [Bp104, setBp104] = useState("");

  const [MoldSafeT, setMoldSafeT] = useState("");

  const [Actual1, setActual1] = useState("");

  const [Bp201, setBp201] = useState("");
  const [Bp202, setBp202] = useState("");
  const [Bp203, setBp203] = useState("");
  const [Bp204, setBp204] = useState("");

  const [Melt101, setMelt101] = useState("");
  const [Melt102, setMelt102] = useState("");
  const [Melt103, setMelt103] = useState("");
  const [Melt104, setMelt104] = useState("");
  const [Open401, setOpen401] = useState("");
  const [Open402, setOpen402] = useState("");
  const [Open403, setOpen403] = useState("");
  const [Open201, setOpen201] = useState("");
  const [Open202, setOpen202] = useState("");
  const [Open203, setOpen203] = useState("");
  const [Open301, setOpen301] = useState("");
  const [Open302, setOpen302] = useState("");
  const [Open303, setOpen303] = useState("");
  const [Core301, setCore301] = useState("");
  const [Core302, setCore302] = useState("");
  const [Core303, setCore303] = useState("");
  const [Core101, setCore101] = useState("");
  const [Core102, setCore102] = useState("");
  const [Core103, setCore103] = useState("");
  const [Core201, setCore201] = useState("");
  const [Core202, setCore202] = useState("");
  const [Core203, setCore203] = useState("");
  const [Core401, setCore401] = useState("");
  const [Core402, setCore402] = useState("");
  const [Core403, setCore403] = useState("");

  const [ManFld1, setManFld1] = useState("");
  const [ManFld2, setManFld2] = useState("");
  const [ManFld3, setManFld3] = useState("");
  const [ManFld4, setManFld4] = useState("");
  const [ManFld5, setManFld5] = useState("");

  const [Gte2, setGte2] = useState("");
  const [Gte3, setGte3] = useState("");
  const [Gte4, setGte4] = useState("");
  const [Gte5, setGte5] = useState("");
  const [Gte6, setGte6] = useState("");

  const [Gate1, setGate1] = useState("");
  const [Gate2, setGate2] = useState("");
  const [Gate3, setGate3] = useState("");
  const [Gate4, setGate4] = useState("");
  const [Gate5, setGate5] = useState("");
  const [Gate6, setGate6] = useState("");
  const [Gate7, setGate7] = useState("");
  const [Gate8, setGate8] = useState("");
  const [Gate9, setGate9] = useState("");
  const [Gate10, setGate10] = useState("");
  const [Gate11, setGate11] = useState("");
  const [Gate12, setGate12] = useState("");

  const [Cre1, setCre1] = useState("");
  const [Cre2, setCre2] = useState("");
  const [Cre3, setCre3] = useState("");
  const [Cre4, setCre4] = useState("");

  const [Oilt, setOilt] = useState("");

  const [Cycletme, setCycletme] = useState("");

  const [Cooltme, setCooltme] = useState("");
  const [Switch, setSwitch] = useState("");

  const [PrtSet, setPrtSet] = useState("");
  const [NozzSet, setNozzSet] = useState("");
  const [ZneSet1, setZneSet1] = useState("");
  const [ZneSet2, setZneSet2] = useState("");
  const [ZneSet3, setZneSet3] = useState("");
  const [HoppSet, setHoppSet] = useState("");
  const [CoreSet, setCoreSet] = useState("");
  const [CavSet, setCavSet] = useState("");
  const [MtcSet, setMtcSet] = useState("");
  const [InjPresSet, setInjPresSet] = useState("");
  const [InjSpdSet, setInjSpdSet] = useState("");
  const [InjPosSet, setInjPosSet] = useState("");
  const [InjTmeSet, setInjTmeSet] = useState("");
  const [CycTmeSet, setCycTmeSet] = useState("");
  const [CoolTmeSet, setCoolTmeSet] = useState("");
  const [CusSet, setCusSet] = useState("");
  const [ManiFldSet1, setManiFldSet1] = useState("");
  const [ManiFldSet2, setManiFldSet2] = useState("");
  const [ManiFldSet3, setManiFldSet3] = useState("");
  const [ManiFldSet4, setManiFldSet4] = useState("");
  const [ManiFldSet5, setManiFldSet5] = useState("");
  const [GteSet, setGteSet] = useState("");
  const [OnSet, setOnSet] = useState("");
  const [OffSet, setOffSet] = useState("");
  const [ReFillPresSet, setReFillPresSet] = useState("");
  const [ReFillSpdSet, setReFillSpdSet] = useState("");
  const [ReFillPosSet, setReFillPosSet] = useState("");
  const [ReFillTmeSet, setReFillTmeSet] = useState("");
  const [ClsePresSet, setClsePresSet] = useState("");
  const [ClseSpdSet, setClseSpdSet] = useState("");
  const [EjcSpdSet, setEjcSpdSet] = useState("");
  const [EjcPresSet, setEjcPresSet] = useState("");
  const [SukSet, setSukSet] = useState("");
  const [OilSet, setOilSet] = useState("");
  const [Zone4, setZone4] = useState("");
  const [Zone5, setZone5] = useState("");
  const [Zone6, setZone6] = useState("");
  const [ZneSet4, setZneSet4] = useState("");
  const [ZneSet5, setZneSet5] = useState("");
  const [ZneSet6, setZneSet6] = useState("");
  const [PreparedBy, setPreparedBy] = useState("");
  const [ApprovedBy, setApprovedBy] = useState("");
  const [approved_by, setapproved_by] = useState("");
  const [prepared_by, setprepared_by] = useState("");
  const [PartWeight1, setPartWeight1] = useState("");
  const [PartWeight2, setPartWeight2] = useState("");
  const [PartWeightPlus1, setPartWeightPlus1] = useState("");
  const [PartWeightPlus2, setPartWeightPlus2] = useState("");
  const [PartWeightMinus1, setPartWeightMinus1] = useState("");
  const [PartWeightMinus2, setPartWeightMinus2] = useState("");

  // const [viewMode, setviewMode] = useState(false);

  // var [std_mld_par, setstd_mld_par] = useState([]);
  const [dropdown, setDropdown] = useState([]);
  const [partDropdown, setPartDropdown] = useState([]);

  const mcNoOptions = dropdown.map((i) => ({
    value: i.McNo,
    label: i.mc_name,
  }));

  const prtNmeOptions = partDropdown.map((i) => ({
    value: i.PrtNme,
    label: i.PrtNme,
  }));

  const getDropdown = () => {
    axios
      .post(`http://${API_URL}/GenericResultBuilderService/buildResults`, {
        _keyword_: "Get_SMP_MachineNumber",
      })
      .then((response) => {
        setDropdown(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPartDropdown = (McNovalue) => {
    console.log("McNovalue", McNovalue);
    axios
      .post(`http://${API_URL}/GenericResultBuilderService/buildResults`, {
        _keyword_: "Get_PartName_basedOn_MachineNo_form_SMP",
        Mcno: McNovalue,
      })
      .then((response) => {
        console.log("response.data", response.data);
        setPartDropdown(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getDropdown();
  }, []);
  useEffect(() => {
    if (McNo.value !== undefined && PrtNme.value !== undefined) {

      axios
        .post(`http://${API_URL}/GenericResultBuilderService/buildResults`, {
          _keyword_: "std_mld_params_by_part",
          mc_part_code: PrtNme.value,
          McNo: McNo.value,
        })
        .then((response) => {
          console.log("respose", response);
          if (response.data.data.length > 0) {
            console.log("response.data.data", response.data.data);
            setTrackDate(response.data.data[0].TrackDate);
            // setMcNo(response.data.data[0].McNo);
            setMatNme(response.data.data[0].RawMaterials);
            setNoCav(response.data.data[0].NoCav);
            // setPrtNme(response.data.data[0].part_name);
            setGrdNme(response.data.data[0].GrdNme);
            setColour(response.data.data[0].Colour);
            setPrtWgt(response.data.data[0].PrtWgt);
            setRunn(response.data.data[0].Runn);
            setInj101(response.data.data[0].Inj101);
            setInj102(response.data.data[0].Inj102);
            setInj103(response.data.data[0].Inj103);
            setInj104(response.data.data[0].Inj104);
            setModl(response.data.data[0].Modl);
            setInj201(response.data.data[0].Inj201);
            setInj202(response.data.data[0].Inj202);
            setInj203(response.data.data[0].Inj203);
            setInj204(response.data.data[0].Inj204);
            setInj301(response.data.data[0].Inj301);
            setInj302(response.data.data[0].Inj302);
            setInj303(response.data.data[0].Inj303);
            setInj304(response.data.data[0].Inj304);
            setInj401(response.data.data[0].Inj401);
            setInj402(response.data.data[0].Inj402);
            setInj403(response.data.data[0].Inj403);
            setInj404(response.data.data[0].Inj404);
            setFillT104(response.data.data[0].FillT104);
            setZone2(response.data.data[0].Zone2);
            setZone1(response.data.data[0].Zone1);
            setZone3(response.data.data[0].Zone3);
            setHozz(response.data.data[0].Hozz);
            setHopp(response.data.data[0].Hopp);
            setHold101(response.data.data[0].Hold101);
            setHold201(response.data.data[0].Hold201);
            setHold301(response.data.data[0].Hold301);
            setHold401(response.data.data[0].Hold401);
            setHold102(response.data.data[0].Hold102);
            setHold202(response.data.data[0].Hold202);
            setHold302(response.data.data[0].Hold302);
            setHold402(response.data.data[0].Hold402);
            setHold103(response.data.data[0].Hold103);
            setHold203(response.data.data[0].Hold203);
            setHold303(response.data.data[0].Hold303);
            setHold403(response.data.data[0].Hold403);
            setHold104(response.data.data[0].Hold104);
            setHold204(response.data.data[0].Hold204);
            setHold304(response.data.data[0].Hold304);
            setHold404(response.data.data[0].Hold404);
            setCore(response.data.data[0].Core);
            setCavity(response.data.data[0].Cavity);
            setDely(response.data.data[0].Dely);
            setMTC(response.data.data[0].MTC);
            setFwd(response.data.data[0].Fwd);
            setOnTme(response.data.data[0].OnTme);
            setRetSpd(response.data.data[0].RetSpd);
            setSprBushHle(response.data.data[0].SprBushHle);
            setNozzHole(response.data.data[0].NozzHole);
            setReFill101(response.data.data[0].ReFill101);
            setReFill102(response.data.data[0].ReFill102);
            setReFill103(response.data.data[0].ReFill103);
            setReFill104(response.data.data[0].ReFill104);
            setReFill201(response.data.data[0].ReFill201);
            setReFill202(response.data.data[0].ReFill202);
            setReFill203(response.data.data[0].ReFill203);
            setReFill204(response.data.data[0].ReFill204);
            setReFill301(response.data.data[0].ReFill301);
            setReFill302(response.data.data[0].ReFill302);
            setReFill303(response.data.data[0].ReFill303);
            setReFill304(response.data.data[0].ReFill304);
            setClose101(response.data.data[0].Close101);
            setClose102(response.data.data[0].Close102);
            setClose103(response.data.data[0].Close103);
            setClose201(response.data.data[0].Close201);
            setClose202(response.data.data[0].Close202);
            setClose203(response.data.data[0].Close203);
            setClose301(response.data.data[0].Close301);
            setClose302(response.data.data[0].Close302);
            setClose303(response.data.data[0].Close303);
            setEjecFrd101(response.data.data[0].EjecFrd101);
            setEjecFrd102(response.data.data[0].EjecFrd102);
            setEjecFrd103(response.data.data[0].EjecFrd103);
            setEjecFrd104(response.data.data[0].EjecFrd104);
            setEjecFrd201(response.data.data[0].EjecFrd201);
            setEjecFrd202(response.data.data[0].EjecFrd202);
            setEjecFrd203(response.data.data[0].EjecFrd203);
            setEjecFrd204(response.data.data[0].EjecFrd204);
            setEjecRet101(response.data.data[0].EjecRet101);
            setEjecRet102(response.data.data[0].EjecRet102);
            setEjecRet103(response.data.data[0].EjecRet103);
            setEjecRet104(response.data.data[0].EjecRet104);
            setEjecRet201(response.data.data[0].EjecRet201);
            setEjecRet202(response.data.data[0].EjecRet202);
            setEjecRet203(response.data.data[0].EjecRet203);
            setEjecRet204(response.data.data[0].EjecRet204);
            setRefillEnd(response.data.data[0].RefillEnd);
            setMldSfy101(response.data.data[0].MldSfy101);
            setMldSfy201(response.data.data[0].MldSfy201);
            setMldSfy301(response.data.data[0].MldSfy301);
            setMldSfy102(response.data.data[0].MldSfy102);
            setMldSfy202(response.data.data[0].MldSfy202);
            setMldSfy302(response.data.data[0].MldSfy302);
            setSuckBack(response.data.data[0].SuckBack);
            setBp101(response.data.data[0].Bp101);
            setBp102(response.data.data[0].Bp102);
            setBp103(response.data.data[0].Bp103);
            setBp104(response.data.data[0].Bp104);
            setMoldSafeT(response.data.data[0].MoldSafeT);
            setActual1(response.data.data[0].Actual1);
            setBp201(response.data.data[0].Bp201);
            setBp202(response.data.data[0].Bp202);
            setBp203(response.data.data[0].Bp203);
            setBp204(response.data.data[0].Bp204);
            setMelt101(response.data.data[0].Melt101);
            setMelt102(response.data.data[0].Melt102);
            setMelt103(response.data.data[0].Melt103);
            setMelt104(response.data.data[0].Melt104);
            setOpen401(response.data.data[0].Open401);
            setOpen402(response.data.data[0].Open402);
            setOpen403(response.data.data[0].Open403);
            setOpen201(response.data.data[0].Open201);
            setOpen202(response.data.data[0].Open202);
            setOpen203(response.data.data[0].Open203);
            setOpen301(response.data.data[0].Open301);
            setOpen302(response.data.data[0].Open302);
            setOpen303(response.data.data[0].Open303);
            setCore301(response.data.data[0].Core301);
            setCore302(response.data.data[0].Core302);
            setCore303(response.data.data[0].Core303);
            setCore101(response.data.data[0].Core101);
            setCore102(response.data.data[0].Core102);
            setCore103(response.data.data[0].Core103);
            setCore201(response.data.data[0].Core201);
            setCore202(response.data.data[0].Core202);
            setCore203(response.data.data[0].Core203);
            setCore401(response.data.data[0].Core401);
            setCore402(response.data.data[0].Core402);
            setCore403(response.data.data[0].Core403);
            setOilt(response.data.data[0].Oilt);
            setCycletme(response.data.data[0].Cycletme);
            setCooltme(response.data.data[0].Cooltme);
            setSwitch(response.data.data[0].Switch);
            setManFld1(response.data.data[0].ManFld1);
            setManFld2(response.data.data[0].ManFld2);
            setManFld3(response.data.data[0].ManFld3);
            setManFld4(response.data.data[0].ManFld4);
            setManFld5(response.data.data[0].ManFld5);
            setGte2(response.data.data[0].Gte2);
            setGte3(response.data.data[0].Gte3);
            setGte4(response.data.data[0].Gte4);
            setGte5(response.data.data[0].Gte5);
            setGte6(response.data.data[0].Gte6);
            setGate1(response.data.data[0].Gate1);
            setGate2(response.data.data[0].Gate2);
            setGate3(response.data.data[0].Gate3);
            setGate4(response.data.data[0].Gate4);
            setGate5(response.data.data[0].Gate5);
            setGate6(response.data.data[0].Gate6);
            setGate7(response.data.data[0].Gate7);
            setGate8(response.data.data[0].Gate8);
            setGate9(response.data.data[0].Gate9);
            setGate10(response.data.data[0].Gate10);
            setGate11(response.data.data[0].Gate11);
            setGate12(response.data.data[0].Gate12);
            setCre1(response.data.data[0].Cre1);
            setCre2(response.data.data[0].Cre2);
            setCre3(response.data.data[0].Cre3);
            setCre4(response.data.data[0].Cre4);

            setPrtSet(response.data.data[0].PrtSet);
            setNozzSet(response.data.data[0].NozzSet);
            setZneSet1(response.data.data[0].ZneSet1);
            setZneSet2(response.data.data[0].ZneSet2);
            setZneSet3(response.data.data[0].ZneSet3);
            setHoppSet(response.data.data[0].HoppSet);
            setCoreSet(response.data.data[0].CoreSet);
            setCavSet(response.data.data[0].CavSet);
            setMtcSet(response.data.data[0].MtcSet);
            setInjPresSet(response.data.data[0].InjPresSet);
            setInjSpdSet(response.data.data[0].InjSpdSet);
            setInjPosSet(response.data.data[0].InjPosSet);
            setInjTmeSet(response.data.data[0].InjTmeSet);
            setCycTmeSet(response.data.data[0].CycTmeSet);
            setCoolTmeSet(response.data.data[0].CoolTmeSet);
            setCusSet(response.data.data[0].CusSet);
            setManiFldSet1(response.data.data[0].ManiFldSet1);
            setManiFldSet2(response.data.data[0].ManiFldSet2);
            setManiFldSet3(response.data.data[0].ManiFldSet3);
            setManiFldSet4(response.data.data[0].ManiFldSet4);
            setManiFldSet5(response.data.data[0].ManiFldSet5);
            setGteSet(response.data.data[0].GteSet);
            setOnSet(response.data.data[0].OnSet);
            setOffSet(response.data.data[0].OffSet);
            setReFillPresSet(response.data.data[0].ReFillPresSet);
            setReFillSpdSet(response.data.data[0].ReFillSpdSet);
            setReFillPosSet(response.data.data[0].ReFillPosSet);
            setReFillTmeSet(response.data.data[0].ReFillTmeSet);
            setClsePresSet(response.data.data[0].ClsePresSet);
            setClseSpdSet(response.data.data[0].ClseSpdSet);
            setEjcSpdSet(response.data.data[0].EjcSpdSet);
            setEjcPresSet(response.data.data[0].EjcPresSet);
            setSukSet(response.data.data[0].SukSet);
            setOilSet(response.data.data[0].OilSet);
            setZneSet4(response.data.data[0].ZneSet4);
            setZneSet5(response.data.data[0].ZneSet5);
            setZneSet6(response.data.data[0].ZneSet6);
            setZone4(response.data.data[0].Zone4);
            setZone5(response.data.data[0].Zone5);
            setZone6(response.data.data[0].Zone6);
            setPreparedBy(response.data.data[0].PreparedBy);
            setApprovedBy(response.data.data[0].ApprovedBy);
            setprepared_by(response.data.data[0].prepared_by);
            setapproved_by(response.data.data[0].approved_by);
            setPartWeight1(response.data.data[0].part_weight_1);
            setPartWeight2(response.data.data[0].part_weight_2);
            setPartWeightPlus1(response.data.data[0].part_weight_set_plus_1);
            setPartWeightPlus2(response.data.data[0].part_weight_set_plus_2);
            setPartWeightMinus1(response.data.data[0].part_weight_set_minus_1);
            setPartWeightMinus2(response.data.data[0].part_weight_set_minus_2);

          } else {
            console.log("else");
            setTrackDate("");
            // setMcNo("");
            setMatNme("");
            setNoCav("");
            setPrtNme("");
            setGrdNme("");
            setColour("");
            setPrtWgt("");
            setRunn("");
            setInj101("");
            setInj102("");
            setInj103("");
            setInj104("");
            setModl("");
            setInj201("");
            setInj202("");
            setInj203("");
            setInj204("");
            setInj301("");
            setInj302("");
            setInj303("");
            setInj304("");
            setInj401("");
            setInj402("");
            setInj403("");
            setInj404("");
            setFillT104("");
            setZone2("");
            setZone1("");
            setZone3("");
            setHozz("");
            setHopp("");
            setHold101("");
            setHold201("");
            setHold301("");
            setHold401("");
            setHold102("");
            setHold202("");
            setHold302("");
            setHold402("");
            setHold103("");
            setHold203("");
            setHold303("");
            setHold403("");
            setHold104("");
            setHold204("");
            setHold304("");
            setHold404("");
            setCore("");
            setCavity("");
            setDely("");
            setMTC("");
            setFwd("");
            setOnTme("");
            setRetSpd("");
            setSprBushHle("");
            setNozzHole("");
            setReFill101("");
            setReFill102("");
            setReFill103("");
            setReFill104("");
            setReFill201("");
            setReFill202("");
            setReFill203("");
            setReFill204("");
            setReFill301("");
            setReFill302("");
            setReFill303("");
            setReFill304("");
            setClose101("");
            setClose102("");
            setClose103("");
            setClose201("");
            setClose202("");
            setClose203("");
            setClose301("");
            setClose302("");
            setClose303("");
            setEjecFrd101("");
            setEjecFrd102("");
            setEjecFrd103("");
            setEjecFrd104("");
            setEjecFrd201("");
            setEjecFrd202("");
            setEjecFrd203("");
            setEjecFrd204("");
            setEjecRet101("");
            setEjecRet102("");
            setEjecRet103("");
            setEjecRet104("");
            setEjecRet201("");
            setEjecRet202("");
            setEjecRet203("");
            setEjecRet204("");
            setRefillEnd("");
            setMldSfy101("");
            setMldSfy201("");
            setMldSfy301("");
            setMldSfy102("");
            setMldSfy202("");
            setMldSfy302("");
            setSuckBack("");
            setBp101("");
            setBp102("");
            setBp103("");
            setBp104("");
            setMoldSafeT("");
            setActual1("");
            setBp201("");
            setBp202("");
            setBp203("");
            setBp204("");
            setMelt101("");
            setMelt102("");
            setMelt103("");
            setMelt104("");
            setOpen401("");
            setOpen402("");
            setOpen403("");
            setOpen201("");
            setOpen202("");
            setOpen203("");
            setOpen301("");
            setOpen302("");
            setOpen303("");
            setCore301("");
            setCore302("");
            setCore303("");
            setCore101("");
            setCore102("");
            setCore103("");
            setCore201("");
            setCore202("");
            setCore203("");
            setCore401("");
            setCore402("");
            setCore403("");
            setOilt("");
            setCycletme("");
            setCooltme("");
            setSwitch("");
            setManFld1("");
            setManFld2("");
            setManFld3("");
            setManFld4("");
            setManFld5("");
            setGte2("");
            setGte3("");
            setGte4("");
            setGte5("");
            setGte6("");
            setGate1("");
            setGate2("");
            setGate3("");
            setGate4("");
            setGate5("");
            setGate6("");
            setGate7("");
            setGate8("");
            setGate9("");
            setGate10("");
            setGate11("");
            setGate12("");
            setCre1("");
            setCre2("");
            setCre3("");
            setCre4("");

            setPrtSet("");
            setNozzSet("");
            setZneSet1("");
            setZneSet2("");
            setZneSet3("");
            setHoppSet("");
            setCoreSet("");
            setCavSet("");
            setMtcSet("");
            setInjPresSet("");
            setInjSpdSet("");
            setInjPosSet("");
            setInjTmeSet("");
            setCycTmeSet("");
            setCoolTmeSet("");
            setCusSet("");
            setManiFldSet1("");
            setManiFldSet2("");
            setManiFldSet3("");
            setManiFldSet4("");
            setManiFldSet5("");
            setGteSet("");
            setOnSet("");
            setOffSet("");
            setReFillPresSet("");
            setReFillSpdSet("");
            setReFillPosSet("");
            setReFillTmeSet("");
            setClsePresSet("");
            setClseSpdSet("");
            setEjcSpdSet("");
            setEjcPresSet("");
            setSukSet("");
            setOilSet("");
            setZone4("");
            setZone5("");
            setZone6("");
            setZneSet4("");
            setZneSet5("");
            setZneSet6("");
            setApprovedBy("");
            setPreparedBy("");
            setapproved_by("");
            setprepared_by("");
            setPartWeightMinus1("");
            setPartWeightMinus2("");
            setPartWeightPlus1("");
            setPartWeightPlus2("");
            setPartWeight1("");
            setPartWeight2("");
          }

        })
        .catch((error) => {
          console.log(error);
        });
    } else {
    }
  }, [McNo.value, PrtNme.value])
  // const getPartValues = (partCode) => {
  //   console.log("sdjsnsdjknsjk",partCode,McNo);
  //   if (partCode.value !== "" && McNo.value !== "") {
  //     axios
  //       .post(`http://${API_URL}/GenericResultBuilderService/buildResults`, {
  //         _keyword_: "std_mld_params_by_part",
  //         mc_part_code: partCode,
  //         McNo: McNo,
  //       })
  //       .then((response) => {
  //         if (response.data.data.length > 0) {
  //           console.log("response.data.data", response.data.data);
  //           setTrackDate(response.data.data[0].TrackDate);
  //           setMcNo(response.data.data[0].McNo);
  //           setMatNme(response.data.data[0].MatNme);
  //           setNoCav(response.data.data[0].NoCav);
  //           setPrtNme(response.data.data[0].part_name);
  //           setGrdNme(response.data.data[0].GrdNme);
  //           setColour(response.data.data[0].Colour);
  //           setPrtWgt(response.data.data[0].PrtWgt);
  //           setRunn(response.data.data[0].Runn);
  //           setInj101(response.data.data[0].Inj101);
  //           setInj102(response.data.data[0].Inj102);
  //           setInj103(response.data.data[0].Inj103);
  //           setInj104(response.data.data[0].Inj104);
  //           setModl(response.data.data[0].Modl);
  //           setInj201(response.data.data[0].Inj201);
  //           setInj202(response.data.data[0].Inj202);
  //           setInj203(response.data.data[0].Inj203);
  //           setInj204(response.data.data[0].Inj204);
  //           setInj301(response.data.data[0].Inj301);
  //           setInj302(response.data.data[0].Inj302);
  //           setInj303(response.data.data[0].Inj303);
  //           setInj304(response.data.data[0].Inj304);
  //           setInj401(response.data.data[0].Inj401);
  //           setInj402(response.data.data[0].Inj402);
  //           setInj403(response.data.data[0].Inj403);
  //           setInj404(response.data.data[0].Inj404);
  //           setFillT104(response.data.data[0].FillT104);
  //           setZone2(response.data.data[0].Zone2);
  //           setZone1(response.data.data[0].Zone1);
  //           setZone3(response.data.data[0].Zone3);
  //           setHozz(response.data.data[0].Hozz);
  //           setHopp(response.data.data[0].Hopp);
  //           setHold101(response.data.data[0].Hold101);
  //           setHold201(response.data.data[0].Hold201);
  //           setHold301(response.data.data[0].Hold301);
  //           setHold401(response.data.data[0].Hold401);
  //           setHold102(response.data.data[0].Hold102);
  //           setHold202(response.data.data[0].Hold202);
  //           setHold302(response.data.data[0].Hold302);
  //           setHold402(response.data.data[0].Hold402);
  //           setHold103(response.data.data[0].Hold103);
  //           setHold203(response.data.data[0].Hold203);
  //           setHold303(response.data.data[0].Hold303);
  //           setHold403(response.data.data[0].Hold403);
  //           setHold104(response.data.data[0].Hold104);
  //           setHold204(response.data.data[0].Hold204);
  //           setHold304(response.data.data[0].Hold304);
  //           setHold404(response.data.data[0].Hold404);
  //           setCore(response.data.data[0].Core);
  //           setCavity(response.data.data[0].Cavity);
  //           setDely(response.data.data[0].Dely);
  //           setMTC(response.data.data[0].MTC);
  //           setFwd(response.data.data[0].Fwd);
  //           setOnTme(response.data.data[0].OnTme);
  //           setRetSpd(response.data.data[0].RetSpd);
  //           setSprBushHle(response.data.data[0].SprBushHle);
  //           setNozzHole(response.data.data[0].NozzHole);
  //           setReFill101(response.data.data[0].ReFill101);
  //           setReFill102(response.data.data[0].ReFill102);
  //           setReFill103(response.data.data[0].ReFill103);
  //           setReFill104(response.data.data[0].ReFill104);
  //           setReFill201(response.data.data[0].ReFill201);
  //           setReFill202(response.data.data[0].ReFill202);
  //           setReFill203(response.data.data[0].ReFill203);
  //           setReFill204(response.data.data[0].ReFill204);
  //           setReFill301(response.data.data[0].ReFill301);
  //           setReFill302(response.data.data[0].ReFill302);
  //           setReFill303(response.data.data[0].ReFill303);
  //           setReFill304(response.data.data[0].ReFill304);
  //           setClose101(response.data.data[0].Close101);
  //           setClose102(response.data.data[0].Close102);
  //           setClose103(response.data.data[0].Close103);
  //           setClose201(response.data.data[0].Close201);
  //           setClose202(response.data.data[0].Close202);
  //           setClose203(response.data.data[0].Close203);
  //           setClose301(response.data.data[0].Close301);
  //           setClose302(response.data.data[0].Close302);
  //           setClose303(response.data.data[0].Close303);
  //           setEjecFrd101(response.data.data[0].EjecFrd101);
  //           setEjecFrd102(response.data.data[0].EjecFrd102);
  //           setEjecFrd103(response.data.data[0].EjecFrd103);
  //           setEjecFrd104(response.data.data[0].EjecFrd104);
  //           setEjecFrd201(response.data.data[0].EjecFrd201);
  //           setEjecFrd202(response.data.data[0].EjecFrd202);
  //           setEjecFrd203(response.data.data[0].EjecFrd203);
  //           setEjecFrd204(response.data.data[0].EjecFrd204);
  //           setEjecRet101(response.data.data[0].EjecRet101);
  //           setEjecRet102(response.data.data[0].EjecRet102);
  //           setEjecRet103(response.data.data[0].EjecRet103);
  //           setEjecRet104(response.data.data[0].EjecRet104);
  //           setEjecRet201(response.data.data[0].EjecRet201);
  //           setEjecRet202(response.data.data[0].EjecRet202);
  //           setEjecRet203(response.data.data[0].EjecRet203);
  //           setEjecRet204(response.data.data[0].EjecRet204);
  //           setRefillEnd(response.data.data[0].RefillEnd);
  //           setMldSfy101(response.data.data[0].MldSfy101);
  //           setMldSfy201(response.data.data[0].MldSfy201);
  //           setMldSfy301(response.data.data[0].MldSfy301);
  //           setMldSfy102(response.data.data[0].MldSfy102);
  //           setMldSfy202(response.data.data[0].MldSfy202);
  //           setMldSfy302(response.data.data[0].MldSfy302);
  //           setSuckBack(response.data.data[0].SuckBack);
  //           setBp101(response.data.data[0].Bp101);
  //           setBp102(response.data.data[0].Bp102);
  //           setBp103(response.data.data[0].Bp103);
  //           setBp104(response.data.data[0].Bp104);
  //           setMoldSafeT(response.data.data[0].MoldSafeT);
  //           setActual1(response.data.data[0].Actual1);
  //           setBp201(response.data.data[0].Bp201);
  //           setBp202(response.data.data[0].Bp202);
  //           setBp203(response.data.data[0].Bp203);
  //           setBp204(response.data.data[0].Bp204);
  //           setMelt101(response.data.data[0].Melt101);
  //           setMelt102(response.data.data[0].Melt102);
  //           setMelt103(response.data.data[0].Melt103);
  //           setMelt104(response.data.data[0].Melt104);
  //           setOpen401(response.data.data[0].Open401);
  //           setOpen402(response.data.data[0].Open402);
  //           setOpen403(response.data.data[0].Open403);
  //           setOpen201(response.data.data[0].Open201);
  //           setOpen202(response.data.data[0].Open202);
  //           setOpen203(response.data.data[0].Open203);
  //           setOpen301(response.data.data[0].Open301);
  //           setOpen302(response.data.data[0].Open302);
  //           setOpen303(response.data.data[0].Open303);
  //           setCore301(response.data.data[0].Core301);
  //           setCore302(response.data.data[0].Core302);
  //           setCore303(response.data.data[0].Core303);
  //           setCore101(response.data.data[0].Core101);
  //           setCore102(response.data.data[0].Core102);
  //           setCore103(response.data.data[0].Core103);
  //           setCore201(response.data.data[0].Core201);
  //           setCore202(response.data.data[0].Core202);
  //           setCore203(response.data.data[0].Core203);
  //           setCore401(response.data.data[0].Core401);
  //           setCore402(response.data.data[0].Core402);
  //           setCore403(response.data.data[0].Core403);
  //           setOilt(response.data.data[0].Oilt);
  //           setCycletme(response.data.data[0].Cycletme);
  //           setCooltme(response.data.data[0].Cooltme);
  //           setSwitch(response.data.data[0].Switch);
  //           setManFld1(response.data.data[0].ManFld1);
  //           setManFld2(response.data.data[0].ManFld2);
  //           setManFld3(response.data.data[0].ManFld3);
  //           setManFld4(response.data.data[0].ManFld4);
  //           setManFld5(response.data.data[0].ManFld5);
  //           setGte2(response.data.data[0].Gte2);
  //           setGte3(response.data.data[0].Gte3);
  //           setGte4(response.data.data[0].Gte4);
  //           setGte5(response.data.data[0].Gte5);
  //           setGte6(response.data.data[0].Gte6);
  //           setGate1(response.data.data[0].Gate1);
  //           setGate2(response.data.data[0].Gate2);
  //           setGate3(response.data.data[0].Gate3);
  //           setGate4(response.data.data[0].Gate4);
  //           setGate5(response.data.data[0].Gate5);
  //           setGate6(response.data.data[0].Gate6);
  //           setGate7(response.data.data[0].Gate7);
  //           setGate8(response.data.data[0].Gate8);
  //           setGate9(response.data.data[0].Gate9);
  //           setGate10(response.data.data[0].Gate10);
  //           setGate11(response.data.data[0].Gate11);
  //           setGate12(response.data.data[0].Gate12);
  //           setCre1(response.data.data[0].Cre1);
  //           setCre2(response.data.data[0].Cre2);
  //           setCre3(response.data.data[0].Cre3);
  //           setCre4(response.data.data[0].Cre4);

  //           setPrtSet(response.data.data[0].PrtSet);
  //           setNozzSet(response.data.data[0].NozzSet);
  //           setZneSet1(response.data.data[0].ZneSet1);
  //           setZneSet2(response.data.data[0].ZneSet2);
  //           setZneSet3(response.data.data[0].ZneSet3);
  //           setHoppSet(response.data.data[0].HoppSet);
  //           setCoreSet(response.data.data[0].CoreSet);
  //           setCavSet(response.data.data[0].CavSet);
  //           setMtcSet(response.data.data[0].MtcSet);
  //           setInjPresSet(response.data.data[0].InjPresSet);
  //           setInjSpdSet(response.data.data[0].InjSpdSet);
  //           setInjPosSet(response.data.data[0].InjPosSet);
  //           setInjTmeSet(response.data.data[0].InjTmeSet);
  //           setCycTmeSet(response.data.data[0].CycTmeSet);
  //           setCoolTmeSet(response.data.data[0].CoolTmeSet);
  //           setCusSet(response.data.data[0].CusSet);
  //           setManiFldSet1(response.data.data[0].ManiFldSet1);
  //           setManiFldSet2(response.data.data[0].ManiFldSet2);
  //           setManiFldSet3(response.data.data[0].ManiFldSet3);
  //           setManiFldSet4(response.data.data[0].ManiFldSet4);
  //           setManiFldSet5(response.data.data[0].ManiFldSet5);
  //           setGteSet(response.data.data[0].GteSet);
  //           setOnSet(response.data.data[0].OnSet);
  //           setOffSet(response.data.data[0].OffSet);
  //           setReFillPresSet(response.data.data[0].ReFillPresSet);
  //           setReFillSpdSet(response.data.data[0].ReFillSpdSet);
  //           setReFillPosSet(response.data.data[0].ReFillPosSet);
  //           setReFillTmeSet(response.data.data[0].ReFillTmeSet);
  //           setClsePresSet(response.data.data[0].ClsePresSet);
  //           setClseSpdSet(response.data.data[0].ClseSpdSet);
  //           setEjcSpdSet(response.data.data[0].EjcSpdSet);
  //           setEjcPresSet(response.data.data[0].EjcPresSet);
  //           setSukSet(response.data.data[0].SukSet);
  //           setOilSet(response.data.data[0].OilSet);
  //           setZneSet4(response.data.data[0].ZneSet4);
  //           setZneSet5(response.data.data[0].ZneSet5);
  //           setZneSet6(response.data.data[0].ZneSet6);
  //           setZone4(response.data.data[0].Zone4);
  //           setZone5(response.data.data[0].Zone5);
  //           setZone6(response.data.data[0].Zone6);
  //           setPreparedBy(response.data.data[0].PreparedBy);
  //           setApprovedBy(response.data.data[0].ApprovedBy);
  //           setprepared_by(response.data.data[0].prepared_by);
  //           setapproved_by(response.data.data[0].approved_by);
  //         } else {
  //           setTrackDate("");
  //           setMcNo("");
  //           setMatNme("");
  //           setNoCav("");
  //           setPrtNme("");
  //           setGrdNme("");
  //           setColour("");
  //           setPrtWgt("");
  //           setRunn("");
  //           setInj101("");
  //           setInj102("");
  //           setInj103("");
  //           setInj104("");
  //           setModl("");
  //           setInj201("");
  //           setInj202("");
  //           setInj203("");
  //           setInj204("");
  //           setInj301("");
  //           setInj302("");
  //           setInj303("");
  //           setInj304("");
  //           setInj401("");
  //           setInj402("");
  //           setInj403("");
  //           setInj404("");
  //           setFillT104("");
  //           setZone2("");
  //           setZone1("");
  //           setZone3("");
  //           setHozz("");
  //           setHopp("");
  //           setHold101("");
  //           setHold201("");
  //           setHold301("");
  //           setHold401("");
  //           setHold102("");
  //           setHold202("");
  //           setHold302("");
  //           setHold402("");
  //           setHold103("");
  //           setHold203("");
  //           setHold303("");
  //           setHold403("");
  //           setHold104("");
  //           setHold204("");
  //           setHold304("");
  //           setHold404("");
  //           setCore("");
  //           setCavity("");
  //           setDely("");
  //           setMTC("");
  //           setFwd("");
  //           setOnTme("");
  //           setRetSpd("");
  //           setSprBushHle("");
  //           setNozzHole("");
  //           setReFill101("");
  //           setReFill102("");
  //           setReFill103("");
  //           setReFill104("");
  //           setReFill201("");
  //           setReFill202("");
  //           setReFill203("");
  //           setReFill204("");
  //           setReFill301("");
  //           setReFill302("");
  //           setReFill303("");
  //           setReFill304("");
  //           setClose101("");
  //           setClose102("");
  //           setClose103("");
  //           setClose201("");
  //           setClose202("");
  //           setClose203("");
  //           setClose301("");
  //           setClose302("");
  //           setClose303("");
  //           setEjecFrd101("");
  //           setEjecFrd102("");
  //           setEjecFrd103("");
  //           setEjecFrd104("");
  //           setEjecFrd201("");
  //           setEjecFrd202("");
  //           setEjecFrd203("");
  //           setEjecFrd204("");
  //           setEjecRet101("");
  //           setEjecRet102("");
  //           setEjecRet103("");
  //           setEjecRet104("");
  //           setEjecRet201("");
  //           setEjecRet202("");
  //           setEjecRet203("");
  //           setEjecRet204("");
  //           setRefillEnd("");
  //           setMldSfy101("");
  //           setMldSfy201("");
  //           setMldSfy301("");
  //           setMldSfy102("");
  //           setMldSfy202("");
  //           setMldSfy302("");
  //           setSuckBack("");
  //           setBp101("");
  //           setBp102("");
  //           setBp103("");
  //           setBp104("");
  //           setMoldSafeT("");
  //           setActual1("");
  //           setBp201("");
  //           setBp202("");
  //           setBp203("");
  //           setBp204("");
  //           setMelt101("");
  //           setMelt102("");
  //           setMelt103("");
  //           setMelt104("");
  //           setOpen401("");
  //           setOpen402("");
  //           setOpen403("");
  //           setOpen201("");
  //           setOpen202("");
  //           setOpen203("");
  //           setOpen301("");
  //           setOpen302("");
  //           setOpen303("");
  //           setCore301("");
  //           setCore302("");
  //           setCore303("");
  //           setCore101("");
  //           setCore102("");
  //           setCore103("");
  //           setCore201("");
  //           setCore202("");
  //           setCore203("");
  //           setCore401("");
  //           setCore402("");
  //           setCore403("");
  //           setOilt("");
  //           setCycletme("");
  //           setCooltme("");
  //           setSwitch("");
  //           setManFld1("");
  //           setManFld2("");
  //           setManFld3("");
  //           setManFld4("");
  //           setManFld5("");
  //           setGte2("");
  //           setGte3("");
  //           setGte4("");
  //           setGte5("");
  //           setGte6("");
  //           setGate1("");
  //           setGate2("");
  //           setGate3("");
  //           setGate4("");
  //           setGate5("");
  //           setGate6("");
  //           setGate7("");
  //           setGate8("");
  //           setGate9("");
  //           setGate10("");
  //           setGate11("");
  //           setGate12("");
  //           setCre1("");
  //           setCre2("");
  //           setCre3("");
  //           setCre4("");

  //           setPrtSet("");
  //           setNozzSet("");
  //           setZneSet1("");
  //           setZneSet2("");
  //           setZneSet3("");
  //           setHoppSet("");
  //           setCoreSet("");
  //           setCavSet("");
  //           setMtcSet("");
  //           setInjPresSet("");
  //           setInjSpdSet("");
  //           setInjPosSet("");
  //           setInjTmeSet("");
  //           setCycTmeSet("");
  //           setCoolTmeSet("");
  //           setCusSet("");
  //           setManiFldSet1("");
  //           setManiFldSet2("");
  //           setManiFldSet3("");
  //           setManiFldSet4("");
  //           setManiFldSet5("");
  //           setGteSet("");
  //           setOnSet("");
  //           setOffSet("");
  //           setReFillPresSet("");
  //           setReFillSpdSet("");
  //           setReFillPosSet("");
  //           setReFillTmeSet("");
  //           setClsePresSet("");
  //           setClseSpdSet("");
  //           setEjcSpdSet("");
  //           setEjcPresSet("");
  //           setSukSet("");
  //           setOilSet("");
  //           setZone4("");
  //           setZone5("");
  //           setZone6("");
  //           setZneSet4("");
  //           setZneSet5("");
  //           setZneSet6("");
  //           setApprovedBy("");
  //           setPreparedBy("");
  //           setapproved_by("");
  //           setprepared_by("");
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  //   // else
  //   // {
  //   //           setTrackDate("");
  //   //           setMcNo("");
  //   //           setMatNme("");
  //   //           setNoCav("");
  //   //           setPrtNme("");
  //   //           setGrdNme("");
  //   //           setColour("");
  //   //           setPrtWgt("");
  //   //           setRunn("");
  //   //           setInj101("");
  //   //           setInj102("");
  //   //           setInj103("");
  //   //           setInj104("");
  //   //           setModl("");
  //   //           setInj201("");
  //   //           setInj202("");
  //   //           setInj203("");
  //   //           setInj204("");
  //   //           setInj301("");
  //   //           setInj302("");
  //   //           setInj303("");
  //   //           setInj304("");
  //   //           setInj401("");
  //   //           setInj402("");
  //   //           setInj403("");
  //   //           setInj404("");
  //   //           setFillT104("");
  //   //           setZone2("");
  //   //           setZone1("");
  //   //           setZone3("");
  //   //           setHozz("");
  //   //           setHopp("");
  //   //           setHold101("");
  //   //           setHold201("");
  //   //           setHold301("");
  //   //           setHold401("");
  //   //           setHold102("");
  //   //           setHold202("");
  //   //           setHold302("");
  //   //           setHold402("");
  //   //           setHold103("");
  //   //           setHold203("");
  //   //           setHold303("");
  //   //           setHold403("");
  //   //           setHold104("");
  //   //           setHold204("");
  //   //           setHold304("");
  //   //           setHold404("");
  //   //           setCore("");
  //   //           setCavity("");
  //   //           setDely("");
  //   //           setMTC("");
  //   //           setFwd("");
  //   //           setOnTme("");
  //   //           setRetSpd("");
  //   //           setSprBushHle("");
  //   //           setNozzHole("");
  //   //           setReFill101("");
  //   //           setReFill102("");
  //   //           setReFill103("");
  //   //           setReFill104("");
  //   //           setReFill201("");
  //   //           setReFill202("");
  //   //           setReFill203("");
  //   //           setReFill204("");
  //   //           setReFill301("");
  //   //           setReFill302("");
  //   //           setReFill303("");
  //   //           setReFill304("");
  //   //           setClose101("");
  //   //           setClose102("");
  //   //           setClose103("");
  //   //           setClose201("");
  //   //           setClose202("");
  //   //           setClose203("");
  //   //           setClose301("");
  //   //           setClose302("");
  //   //           setClose303("");
  //   //           setEjecFrd101("");
  //   //           setEjecFrd102("");
  //   //           setEjecFrd103("");
  //   //           setEjecFrd104("");
  //   //           setEjecFrd201("");
  //   //           setEjecFrd202("");
  //   //           setEjecFrd203("");
  //   //           setEjecFrd204("");
  //   //           setEjecRet101("");
  //   //           setEjecRet102("");
  //   //           setEjecRet103("");
  //   //           setEjecRet104("");
  //   //           setEjecRet201("");
  //   //           setEjecRet202("");
  //   //           setEjecRet203("");
  //   //           setEjecRet204("");
  //   //           setRefillEnd("");
  //   //           setMldSfy101("");
  //   //           setMldSfy201("");
  //   //           setMldSfy301("");
  //   //           setMldSfy102("");
  //   //           setMldSfy202("");
  //   //           setMldSfy302("");
  //   //           setSuckBack("");
  //   //           setBp101("");
  //   //           setBp102("");
  //   //           setBp103("");
  //   //           setBp104("");
  //   //           setMoldSafeT("");
  //   //           setActual1("");
  //   //           setBp201("");
  //   //           setBp202("");
  //   //           setBp203("");
  //   //           setBp204("");
  //   //           setMelt101("");
  //   //           setMelt102("");
  //   //           setMelt103("");
  //   //           setMelt104("");
  //   //           setOpen401("");
  //   //           setOpen402("");
  //   //           setOpen403("");
  //   //           setOpen201("");
  //   //           setOpen202("");
  //   //           setOpen203("");
  //   //           setOpen301("");
  //   //           setOpen302("");
  //   //           setOpen303("");
  //   //           setCore301("");
  //   //           setCore302("");
  //   //           setCore303("");
  //   //           setCore101("");
  //   //           setCore102("");
  //   //           setCore103("");
  //   //           setCore201("");
  //   //           setCore202("");
  //   //           setCore203("");
  //   //           setCore401("");
  //   //           setCore402("");
  //   //           setCore403("");
  //   //           setOilt("");
  //   //           setCycletme("");
  //   //           setCooltme("");
  //   //           setSwitch("");
  //   //           setManFld1("");
  //   //           setManFld2("");
  //   //           setManFld3("");
  //   //           setManFld4("");
  //   //           setManFld5("");
  //   //           setGte2("");
  //   //           setGte3("");
  //   //           setGte4("");
  //   //           setGte5("");
  //   //           setGte6("");
  //   //           setGate1("");
  //   //           setGate2("");
  //   //           setGate3("");
  //   //           setGate4("");
  //   //           setGate5("");
  //   //           setGate6("");
  //   //           setGate7("");
  //   //           setGate8("");
  //   //           setGate9("");
  //   //           setGate10("");
  //   //           setGate11("");
  //   //           setGate12("");
  //   //           setCre1("");
  //   //           setCre2("");
  //   //           setCre3("");
  //   //           setCre4("");

  //   //           setPrtSet("");
  //   //           setNozzSet("");
  //   //           setZneSet1("");
  //   //           setZneSet2("");
  //   //           setZneSet3("");
  //   //           setHoppSet("");
  //   //           setCoreSet("");
  //   //           setCavSet("");
  //   //           setMtcSet("");
  //   //           setInjPresSet("");
  //   //           setInjSpdSet("");
  //   //           setInjPosSet("");
  //   //           setInjTmeSet("");
  //   //           setCycTmeSet("");
  //   //           setCoolTmeSet("");
  //   //           setCusSet("");
  //   //           setManiFldSet1("");
  //   //           setManiFldSet2("");
  //   //           setManiFldSet3("");
  //   //           setManiFldSet4("");
  //   //           setManiFldSet5("");
  //   //           setGteSet("");
  //   //           setOnSet("");
  //   //           setOffSet("");
  //   //           setReFillPresSet("");
  //   //           setReFillSpdSet("");
  //   //           setReFillPosSet("");
  //   //           setReFillTmeSet("");
  //   //           setClsePresSet("");
  //   //           setClseSpdSet("");
  //   //           setEjcSpdSet("");
  //   //           setEjcPresSet("");
  //   //           setSukSet("");
  //   //           setOilSet("");

  //   //       }
  // };
  const handleMcNo = (selectedOption) => {

    const selected = dropdown.find(
      (option) => option.McNo === selectedOption.value
    );
    // console.log("selected",selected);
    if (selected) {
      setMcNo({ value: selected.McNo, label: selected.mc_name });
      getPartDropdown(selected.McNo);

    }

  };
  const handlePrtNme = (selectedOption) => {
    const selected = partDropdown.find(
      (option) => option.PrtNme === selectedOption.value
    );

    if (selected) {
      setPrtNme({ value: selected.PrtNme, label: selected.PrtNme });
    }
  };
  return (
    <div>
      <form className="form-smp-View">
        <table width="100%" style={{ textAlign: "center" }} border="0">
          <thead>
            <th style={{ textAlign: "center", width: "10%" }}>
              {/* <img className={style.sidebar_header_image} src={Logo} /> */}
              <img src={Unitechlogo} style={{ width: "50px" }}></img>
            </th>
            <th style={{ textAlign: "center" }} colSpan="5">
              <font color="#8B0000">View - STANDARD MOULDING PARAMETER</font>
            </th>
          </thead>
          <tr>
            <td>Format No</td>
            <td style={{ width: "26.9%" }}>
              <label className="smp_report_label">UPCL/PRO/D/01</label>
            </td>

            <td style={{ width: "13.9%" }}>Rev No</td>
            <td style={{ width: "15.5%" }}>
              <label className="smp_report_label">01</label>
            </td>
            <td style={{ width: "15%" }}>Date</td>
            <td>
              <label className="smp_report_label">
                {TrackDate ? formatDt(TrackDate) : ""}
              </label>
            </td>
          </tr>
          <thead>
            <th style={{ textAlign: "center" }} colSpan="10"></th>
          </thead>
          <tr>
            <td>Machine</td>
            <td>

              <Select
                id="dropdown"
                options={mcNoOptions}
                value={McNo}
                onChange={handleMcNo}
                placeholder="Select Machine No"

              />
            </td>
            <td>Raw Material Name</td>
            <td>
              <label className="smp_report_label">{MatNme}</label>
            </td>
            <td>No Of Cavity</td>
            <td>
              <label className="smp_report_label">{NoCav}</label>
            </td>
          </tr>
          <tr>
            <td>Part Name</td>
            <td>
              {/* <select
                id="dropdown"
                style={{ width: "100%" }}
                value={PrtNme}
                onChange={(e) => {
                  setPrtNme(e.target.value);
                  getPartValues("1", e.target.value);
                }}
              >
                <option value="">Select Part Name</option>
                {partDropdown.map((i) => (
                  <option value={i.mc_part_code}>{i.mc_part_name}</option>
                ))}
              </select> */}
              {/* <Select
                id="dropdown"
                styles={{ width: "100%" }}
                options={prtNmeOptions}
                value={prtNmeOptions.find((option) => option.value === PrtNme)}
                onChange={(selectedOption) => {
                  setPrtNme(selectedOption.value);
                  getPartValues("1", selectedOption.value);
                }}
                placeholder="Select Part Name"
              /> */}
              {/* <Select
                id="dropdown"
                styles={{ width: "100%" }}
                options={prtNmeOptions}
                value={
                  PrtNme &&
                  prtNmeOptions.find((option) => option.value === PrtNme)
                    ? prtNmeOptions.find((option) => option.value === PrtNme)
                    : null
                }
                onChange={(selectedOption) => {
                  setPrtNme(selectedOption.value);
                  getPartValues(selectedOption.value);
                }}
                placeholder="Select Part Name"
              /> */}
              <Select
                id="partdropdown"
                options={prtNmeOptions}
                value={PrtNme}
                onChange={handlePrtNme}
                // getPartValues(PrtNme.value);
                placeholder="Select Part Name"
                isDisabled={McNo === ""}
              />
            </td>
            <td>Grade Name</td>
            <td>
              <label className="smp_report_label">{GrdNme}</label>
            </td>
            <td>Colour</td>
            <td>
              <label className="smp_report_label">{Colour}</label>
            </td>
          </tr>
          <tr>
            <td>
              {/* Part weight*:± */}
              <span >Part Weight 1: <span style={{ fontWeight: "bold", color: "#1004c2" }} >{PartWeight1}</span><br></br>
                Part Weight 2: <span style={{ fontWeight: "bold", color: "#1004c2" }} >{PartWeight2}</span></span>
            </td>
            <td>
              <span ><span style={{ whiteSpace: "nowrap" }}>PW1*: <span style={{ fontWeight: "bold" }} className="smp_report_set_value">(-{PartWeightPlus1}/+{PartWeightMinus1})</span></span><br></br>
                <span style={{ whiteSpace: "nowrap" }}>PW2*: <span style={{ fontWeight: "bold" }} className="smp_report_set_value">(-{PartWeightPlus2}/+{PartWeightMinus2})</span></span></span>

            </td>
            <td>Runner WT</td>
            <td>
              <label className="smp_report_label">{Runn}</label>
            </td>
            <td>Model</td>
            <td>
              <label className="smp_report_label">{Modl}</label>
            </td>
          </tr>
        </table>
        <table width="100%" style={{ textAlign: "center" }} border="0">
          <thead>
            <th style={{ textAlign: "center" }} colSpan="5">
              INJECTION SETTING
            </th>

            <th style={{ textAlign: "center" }} colSpan="2">
              TEMPERATURE
            </th>

            <th style={{ textAlign: "center" }} colSpan="4">
              HOT RUNNER SYSTEM TEMPERATURE
            </th>

            <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
              SEQEMACE GATE
            </th>
            <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
              <tr>
                <th>
                  ON ±<span className="smp_report_set_value">{OnSet}</span>
                </th>
              </tr>
            </th>
            <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
              <tr>
                <th>
                  OFF ±<span className="smp_report_set_value">{OffSet}</span>
                </th>
              </tr>
            </th>
          </thead>
          <tbody>
            <tr>
              <td style={{ width: "10%" }}>STAGES</td>
              <td style={{ width: "7%" }}>
                PRESSURE<br></br>(BAR) ±
                <span className="smp_report_set_value">{InjPresSet}</span>*
              </td>
              <td style={{ width: "7%" }}>
                SPEED%<br></br>±
                <span className="smp_report_set_value">{InjSpdSet}</span>*
              </td>
              <td style={{ width: "7%" }}>
                POSITION <br></br>±
                <span className="smp_report_set_value">{InjPosSet}</span>{" "}
                MM&#x27E1;
              </td>
              <td style={{ width: "6%" }}>
                TIME(SEC) <br></br>±
                <span className="smp_report_set_value">{InjTmeSet}</span>*
              </td>
              <td style={{ width: "9%" }}>STAGES</td>
              <td style={{ width: "5%" }}>BARREL TEMP</td>
              <td style={{ width: "12%" }}>HOT RUNNER TEMPERATURE</td>
              <td style={{ width: "4%" }}>&deg;C</td>
              <td style={{ width: "11%" }}>HOT RUNNER TEMPERATURE</td>
              <td style={{ width: "4%" }}>
                &deg;C <br></br>±
                <span className="smp_report_set_value">{GteSet}</span>
              </td>
              <td>Gate 1</td>
              <td>
                <label className="smp_report_label">{Gate6}</label>
              </td>
              <td>
                <label className="smp_report_label">{Gate7}</label>
              </td>
            </tr>
            <tr>
              <td>Injection 1*</td>
              <td>
                <label className="smp_report_label">{Inj101}</label>
              </td>
              <td>
                <label className="smp_report_label">{Inj102}</label>
              </td>
              <td>
                <label className="smp_report_label">{Inj103}</label>
              </td>
              <td>
                <label className="smp_report_label">{Inj104}</label>
              </td>
              <td>
                Nozzle*±<span className="smp_report_set_value">{NozzSet}</span>
                &deg;C
              </td>
              <td>
                <label className="smp_report_label">{Hozz}</label>
              </td>
              <td>
                ManiFold 1 ±
                <span className="smp_report_set_value">{ManiFldSet1}</span>
                &deg;C
              </td>
              <td>
                <label className="smp_report_label">{ManFld1}</label>
              </td>
              <td>Gate 1</td>
              <td>
                <label className="smp_report_label">{Gate1}</label>
              </td>
              <td>Gate 2</td>
              <td>
                <label className="smp_report_label">{Gte2}</label>
              </td>
              <td>
                <label className="smp_report_label">{Gate8}</label>
              </td>
            </tr>
            <tr>
              <td>Injection 2*</td>
              <td>
                <label className="smp_report_label">{Inj201}</label>
              </td>
              <td>
                <label className="smp_report_label">{Inj202}</label>
              </td>
              <td>
                <label className="smp_report_label">{Inj203}</label>
              </td>
              <td>
                <label className="smp_report_label">{Inj204}</label>
              </td>
              <td>
                Zone 1*±<span className="smp_report_set_value">{ZneSet1}</span>
                &deg;C
              </td>
              <td>
                <label className="smp_report_label">{Zone1}</label>
              </td>
              <td>
                ManiFold 2 ±
                <span className="smp_report_set_value">{ManiFldSet2}</span>
                &deg;C
              </td>
              <td>
                <label className="smp_report_label">{ManFld2}</label>
              </td>
              <td>Gate 2</td>
              <td>
                <label className="smp_report_label">{Gate2}</label>
              </td>
              <td>Gate 3</td>
              <td>
                <label className="smp_report_label">{Gte3}</label>
              </td>
              <td>
                <label className="smp_report_label">{Gate9}</label>
              </td>
            </tr>
            <tr>
              <td>Injection 3*</td>
              <td>
                <label className="smp_report_label">{Inj301}</label>
              </td>
              <td>
                <label className="smp_report_label">{Inj302}</label>
              </td>
              <td>
                <label className="smp_report_label">{Inj303}</label>
              </td>
              <td>
                <label className="smp_report_label">{Inj304}</label>
              </td>
              <td>
                Zone 2*±<span className="smp_report_set_value">{ZneSet2}</span>
                &deg;C
              </td>
              <td>
                <label className="smp_report_label">{Zone2}</label>
              </td>
              <td>
                ManiFold 3 ±
                <span className="smp_report_set_value">{ManiFldSet3}</span>
                &deg;C
              </td>
              <td>
                <label className="smp_report_label">{ManFld3}</label>
              </td>

              <td>Gate 3</td>
              <td>
                <label className="smp_report_label">{Gate3}</label>
              </td>

              <td>Gate 4</td>
              <td>
                <label className="smp_report_label">{Gte4}</label>
              </td>
              <td>
                <label className="smp_report_label">{Gate10}</label>
              </td>
            </tr>
            <tr>
              <td>Injection 4*</td>
              <td>
                <label className="smp_report_label">{Inj401}</label>
              </td>
              <td>
                <label className="smp_report_label">{Inj402}</label>
              </td>
              <td>
                <label className="smp_report_label">{Inj403}</label>
              </td>
              <td>
                <label className="smp_report_label">{Inj404}</label>
              </td>
              <td>
                Zone 3*±<span className="smp_report_set_value">{ZneSet3}</span>
                &deg;C
              </td>
              <td>
                <label className="smp_report_label">{Zone3}</label>
              </td>
              <td>
                ManiFold 4 ±
                <span className="smp_report_set_value">{ManiFldSet4}</span>
                &deg;C
              </td>
              <td>
                <label className="smp_report_label">{ManFld4}</label>
              </td>
              <td>Gate 4</td>
              <td>
                <label className="smp_report_label">{Gate4}</label>
              </td>
              <td>Gate 5</td>
              <td>
                <label className="smp_report_label">{Gte5}</label>
              </td>
              <td>
                <label className="smp_report_label">{Gate11}</label>
              </td>
            </tr>
            <tr>
              <td>Fill Time(Actual)</td>
              <td colSpan="3"></td>

              <td>
                <label className="smp_report_label">{FillT104}</label>
              </td>

              <td>
                Zone 4* ±<span className="smp_report_set_value">{ZneSet4}</span>
                &deg;C
              </td>
              <td>
                <label className="smp_report_label">{Zone4}</label>
              </td>
              <td>
                ManiFold 5 ±
                <span className="smp_report_set_value">{ManiFldSet5}</span>
                &deg;C
              </td>
              <td>
                <label className="smp_report_label">{ManFld5}</label>
              </td>

              <td>Gate 5</td>
              <td>
                <label className="smp_report_label">{Gate5}</label>
              </td>

              <td>Gate 6</td>
              <td>
                <label className="smp_report_label">{Gte6}</label>
              </td>
              <td>
                <label className="smp_report_label">{Gate12}</label>
              </td>
            </tr>
            <tr>
              <td colSpan={5}></td>{" "}
              <td>
                Zone 5* ±<span className="smp_report_set_value">{ZneSet5}</span>
                &deg;C
              </td>
              <td>
                <label className="smp_report_label">{Zone5}</label>
              </td>
              <td colSpan={7}></td>
            </tr>
            <tr>
              <td colSpan={5}></td>{" "}
              <td>
                Zone 6* ±<span className="smp_report_set_value">{ZneSet6}</span>
                &deg;C
              </td>
              <td>
                <label className="smp_report_label">{Zone6}</label>
              </td>
              <td colSpan={7}></td>
            </tr>
            <tr>
              <td colSpan="3">Switch Over / Transfer Position(MM)&#x27E1;:</td>
              <td>
                <label className="smp_report_label">{Switch}</label>
              </td>
              <td></td>
              <td>
                Hopper* ±<span className="smp_report_set_value">{HoppSet}</span>
                &deg;C
              </td>
              <td>
                <label className="smp_report_label">{Hopp}</label>
              </td>
              <td colSpan={7}></td>
            </tr>{" "}
          </tbody>
        </table>
        <table width="100%" style={{ textAlign: "center" }} border="0">
          <thead>
            <th style={{ textAlign: "center" }} colSpan="5"></th>

            <th style={{ textAlign: "center" }} colSpan="6">
              INJECTION UNIT SETTINGS
            </th>

            <th
              style={{ textAlign: "center", whiteSpace: "nowrap" }}
              colSpan="4"
            >
              MOULD TEMP DETAILS
            </th>
          </thead>
          <tbody>
            <tr>
              <td style={{ width: "10%" }}>Hold On 1*</td>
              <td style={{ width: "7%" }}>
                <label className="smp_report_label">{Hold101}</label>
              </td>
              <td style={{ width: "7%" }}>
                <label className="smp_report_label">{Hold201}</label>
              </td>
              <td style={{ width: "7%" }}>
                <label className="smp_report_label">{Hold301}</label>
              </td>
              <td style={{ width: "6%" }}>
                <label className="smp_report_label">{Hold401}</label>
              </td>
              <td style={{ width: "9%" }}></td>
              <td> SPEED%</td>
              <td></td>
              <td>SEC</td>
              <td>SPRUE BREAK MODE&#x27E1;</td>
              <td>
                <tr>
                  <td style={{ width: "78px", height: "33px" }}></td>
                </tr>
              </td>
              <td style={{ width: "8.4%" }}>
                Core* ±<span className="smp_report_set_value">{CoreSet}</span>
              </td>
              <td>
                <label className="smp_report_label">{Core}</label>
              </td>
            </tr>
            <tr>
              <td>Hold On 2*</td>
              <td>
                <label className="smp_report_label">{Hold102}</label>
              </td>
              <td>
                <label className="smp_report_label">{Hold202}</label>
              </td>
              <td>
                <label className="smp_report_label">{Hold302}</label>
              </td>
              <td>
                <label className="smp_report_label">{Hold402}</label>
              </td>
              <td>INJ UNIT&#x27E1;</td>
              <td>
                <tr>
                  <td>ON</td>
                </tr>
              </td>
              <td>Sprue Break&#x27E1;</td>
              <td>
                <tr>
                  <td style={{ border: "1px solid black" }}>OFF</td>
                </tr>
              </td>
              <td>0 INJ</td>
              <td></td>
              <td>
                Cavity* ±<span className="smp_report_set_value">{CavSet}</span>
              </td>
              <td>
                <label className="smp_report_label">{Cavity}</label>
              </td>
            </tr>
            <tr>
              <td>Hold On 3*</td>
              <td>
                <label className="smp_report_label">{Hold103}</label>
              </td>
              <td>
                <label className="smp_report_label">{Hold203}</label>
              </td>
              <td>
                <label className="smp_report_label">{Hold303}</label>
              </td>
              <td>
                <label className="smp_report_label">{Hold403}</label>
              </td>
              <td>FWD SPD(MM/S)&#x27E1;</td>
              <td>
                <label className="smp_report_label">{Fwd}</label>
              </td>
              <td>Delay Time</td>
              <td>
                <label className="smp_report_label">{Dely}</label>
              </td>
              <td>1.Front Suck Back</td>
              <td>3.Rear Suck Back</td>

              <td>
                M T C Temp* ±
                <span className="smp_report_set_value">{MtcSet}</span>
              </td>
              <td>
                <label className="smp_report_label">{MTC}</label>
              </td>
            </tr>
            <tr>
              <td>Hold On 4*</td>
              <td>
                <label className="smp_report_label">{Hold104}</label>
              </td>
              <td>
                <label className="smp_report_label">{Hold204}</label>
              </td>
              <td>
                <label className="smp_report_label">{Hold304}</label>
              </td>
              <td>
                <label className="smp_report_label">{Hold404}</label>
              </td>
              <td>RET SPD(MM/S)&#x27E1;</td>
              <td>
                <label className="smp_report_label">{RetSpd}</label>
              </td>
              <td>On Time</td>
              <td>
                <label className="smp_report_label">{OnTme}</label>
              </td>
              <td>2.Refill</td>
              <td>4.Cooling</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan="2">Sprue Bush Hole(MM)&#x27E1;</td>

              <td>
                <label className="smp_report_label">{SprBushHle}</label>
              </td>
              <td colSpan="2">Nozzle Hole(MM) &#x27E1;</td>

              <td>
                <label className="smp_report_label">{NozzHole}</label>
              </td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <table width="100%" style={{ textAlign: "center" }} border="0">
          <thead>
            <th style={{ textAlign: "center" }} colSpan="5">
              REFILL SETTINGS
            </th>

            <th style={{ textAlign: "center" }} colSpan="4">
              MOULD CLOSING SETTINGS
            </th>

            <th style={{ textAlign: "center" }} colSpan="5">
              EJECTION SETTINGS
            </th>
          </thead>
          <tbody>
            <tr>
              <td style={{ width: "10%" }}></td>
              <td style={{ width: "7%" }}>
                PRESSURE<br></br>(BAR) ±
                <span className="smp_report_set_value">{ReFillPresSet}</span>
              </td>
              <td style={{ width: "7%" }}>
                SPEED%<br></br> ±
                <span className="smp_report_set_value">{ReFillSpdSet}</span>
              </td>
              <td style={{ width: "7%" }}>
                POSITION<br></br>(MM) ±
                <span className="smp_report_set_value">{ReFillPosSet}</span>
              </td>
              <td style={{ width: "6%" }}>
                TIME(SEC) <br></br>±
                <span className="smp_report_set_value">{ReFillTmeSet}</span>*
              </td>
              <td>STAGES&#x27E1;</td>
              <td>
                PRESSURE (BAR)&#x27E1; ±
                <span className="smp_report_set_value">{ClsePresSet}</span>
              </td>
              <td>
                SPEED% &#x27E1; ±
                <span className="smp_report_set_value">{ClseSpdSet}</span>
              </td>
              <td>POSITION (MM)&#x27E1;</td>
              <td>STAGES&#x27E1;</td>
              <td>
                PRESSURE (BAR)&#x27E1; ±
                <span className="smp_report_set_value">{EjcPresSet}</span>
              </td>
              <td>
                SPEED% ±
                <span className="smp_report_set_value">{EjcSpdSet}</span>
              </td>
              <td>TIME (SEC)</td>
              <td>POSITION (MM)</td>
            </tr>
            <tr>
              <td>Refill 1&#x27E1;</td>
              <td>
                <label className="smp_report_label">{ReFill101}</label>
              </td>
              <td>
                <label className="smp_report_label">{ReFill102}</label>
              </td>
              <td>
                <label className="smp_report_label">{ReFill103}</label>
              </td>
              <td>
                <label className="smp_report_label">{ReFill104}</label>
              </td>
              <td>Close 1</td>
              <td>
                <label className="smp_report_label">{Close101}</label>
              </td>
              <td>
                <label className="smp_report_label">{Close102}</label>
              </td>
              <td>
                <label className="smp_report_label">{Close103}</label>
              </td>
              <td>EJEC FRD 1</td>
              <td>
                <label className="smp_report_label">{EjecFrd101}</label>
              </td>
              <td>
                <label className="smp_report_label">{EjecFrd102}</label>
              </td>
              <td>
                <label className="smp_report_label">{EjecFrd103}</label>
              </td>
              <td>
                <label className="smp_report_label">{EjecFrd104}</label>
              </td>
            </tr>
            <tr>
              <td>Refill 2&#x27E1;</td>
              <td>
                <label className="smp_report_label">{ReFill201}</label>
              </td>
              <td>
                <label className="smp_report_label">{ReFill202}</label>
              </td>
              <td>
                <label className="smp_report_label">{ReFill203}</label>
              </td>
              <td>
                <label className="smp_report_label">{ReFill204}</label>
              </td>
              <td>Close 2</td>
              <td>
                <label className="smp_report_label">{Close201}</label>
              </td>
              <td>
                <label className="smp_report_label">{Close202}</label>
              </td>
              <td>
                <label className="smp_report_label">{Close203}</label>
              </td>
              <td>EJEC FRD 2</td>
              <td>
                <label className="smp_report_label">{EjecFrd201}</label>
              </td>
              <td>
                <label className="smp_report_label">{EjecFrd202}</label>
              </td>
              <td>
                <label className="smp_report_label">{EjecFrd203}</label>
              </td>
              <td>
                <label className="smp_report_label">{EjecFrd204}</label>
              </td>
            </tr>
            <tr>
              <td>Refill 3&#x27E1;</td>
              <td>
                <label className="smp_report_label">{ReFill301}</label>
              </td>
              <td>
                <label className="smp_report_label">{ReFill302}</label>
              </td>
              <td>
                <label className="smp_report_label">{ReFill303}</label>
              </td>
              <td>
                <label className="smp_report_label">{ReFill304}</label>
              </td>
              <td>Close 3</td>
              <td>
                <label className="smp_report_label">{Close301}</label>
              </td>
              <td>
                <label className="smp_report_label">{Close302}</label>
              </td>
              <td>
                <label className="smp_report_label">{Close303}</label>
              </td>
              <td>EJEC RET 1</td>
              <td>
                <label className="smp_report_label">{EjecRet101}</label>
              </td>
              <td>
                <label className="smp_report_label">{EjecRet102}</label>
              </td>
              <td>
                <label className="smp_report_label">{EjecRet103}</label>
              </td>
              <td>
                <label className="smp_report_label">{EjecRet104}</label>
              </td>
            </tr>
            <tr>
              <td colSpan="3">Refill End/Short Size(MM)*:</td>

              <td>
                <label className="smp_report_label">{RefillEnd}</label>
              </td>
              <td></td>
              <td>Mould safety/Protect&#x27E1; 1</td>
              <td>
                <label className="smp_report_label">{MldSfy101}</label>
              </td>
              <td>
                <label className="smp_report_label">{MldSfy201}</label>
              </td>
              <td>
                <label className="smp_report_label">{MldSfy301}</label>
              </td>
              <td>EJEC RET 2</td>
              <td>
                <label className="smp_report_label">{EjecRet201}</label>
              </td>
              <td>
                <label className="smp_report_label">{EjecRet202}</label>
              </td>
              <td>
                <label className="smp_report_label">{EjecRet203}</label>
              </td>
              <td>
                <label className="smp_report_label">{EjecRet204}</label>
              </td>
            </tr>
            <tr>
              <td colSpan="3">
                Suck Back&#x27E1;±
                <span className="smp_report_set_value">{SukSet}</span> (MM):
              </td>

              <td>
                <label className="smp_report_label">{SuckBack}</label>
              </td>
              <td></td>
              <td>Mould safety/Protect&#x27E1; 2</td>
              <td>
                <label className="smp_report_label">{MldSfy102}</label>
              </td>
              <td>
                <label className="smp_report_label">{MldSfy202}</label>
              </td>
              <td>
                <label className="smp_report_label">{MldSfy302}</label>
              </td>
              <td colSpan="5"></td>
            </tr>
            <tr>
              <td>Back Pres&#x27E1; 1</td>
              <td>
                <label className="smp_report_label">{Bp101}</label>
              </td>
              <td>
                <label className="smp_report_label">{Bp102}</label>
              </td>
              <td>
                <label className="smp_report_label">{Bp103}</label>
              </td>
              <td>
                <label className="smp_report_label">{Bp104}</label>
              </td>
              <td>Mould Safety Time(SEC)&#x27E1;:</td>
              <td>
                <label className="smp_report_label">{MoldSafeT}</label>
              </td>
              <td>Actual:</td>
              <td>
                <label className="smp_report_label">{Actual1}</label>
              </td>
              <td>STAGES&#x27E1;</td>
              <td>PRESSURE (BAR)&#x27E1;±2</td>
              <td>SPEED% ±10</td>
              <td>TIME (SEC)</td>
              <td>POSITION (MM)</td>
            </tr>

            <tr>
              <td>Back Pres&#x27E1; 2</td>
              <td>
                <label className="smp_report_label">{Bp201}</label>
              </td>
              <td>
                <label className="smp_report_label">{Bp202}</label>
              </td>
              <td>
                <label className="smp_report_label">{Bp203}</label>
              </td>
              <td>
                <label className="smp_report_label">{Bp204}</label>
              </td>
              <td colSpan="2">Clamp Force&#x27E1;</td>

              <td></td>
              <td></td>
              <td>CORE 1 IN</td>
              <td>
                <label className="smp_report_label">{Cre1}</label>
              </td>
              <td>
                <label className="smp_report_label">{Core101}</label>
              </td>
              <td>
                <label className="smp_report_label">{Core102}</label>
              </td>
              <td>
                <label className="smp_report_label">{Core103}</label>
              </td>
            </tr>
            <tr>
              <td>
                Melt Cushion<br></br>(MM)*±
                <span className="smp_report_set_value">{CusSet}</span>:
              </td>
              <td>
                <label className="smp_report_label">{Melt101}</label>
              </td>
              <td>
                <label className="smp_report_label">{Melt102}</label>
              </td>
              <td>
                <label className="smp_report_label">{Melt103}</label>
              </td>
              <td>
                <label className="smp_report_label">{Melt104}</label>
              </td>
              <th style={{ textAlign: "center" }} colSpan="4">
                MOULD OPENING SETTINGS
              </th>

              <td>CORE 1 OUT</td>
              <td>
                <label className="smp_report_label">{Cre2}</label>
              </td>
              <td>
                <label className="smp_report_label">{Core201}</label>
              </td>
              <td>
                <label className="smp_report_label">{Core202}</label>
              </td>
              <td>
                <label className="smp_report_label">{Core203}</label>
              </td>
            </tr>
            <tr>
              <td>
                Cooling Time* <br></br>±
                <span className="smp_report_set_value">{CoolTmeSet}</span>(Sec)
              </td>
              <td colSpan="4">
                <label className="smp_report_label">{Cooltme}</label>
              </td>

              <td>Open 1 &#x27E1;</td>
              <td>
                <label className="smp_report_label">{Open201}</label>
              </td>
              <td>
                <label className="smp_report_label">{Open202}</label>
              </td>
              <td>
                <label className="smp_report_label">{Open203}</label>
              </td>
              <td>CORE 2 IN</td>
              <td>
                <label className="smp_report_label">{Cre3}</label>
              </td>
              <td>
                <label className="smp_report_label">{Core301}</label>
              </td>
              <td>
                <label className="smp_report_label">{Core302}</label>
              </td>
              <td>
                <label className="smp_report_label">{Core303}</label>
              </td>
            </tr>
            <tr>
              <td>
                Cycle Time&#x27E1;<br></br>±
                <span className="smp_report_set_value">{CycTmeSet}</span>(Sec){" "}
              </td>
              <td colSpan="4">
                <label className="smp_report_label">{Cycletme}</label>
              </td>
              <td>Open 2 &#x27E1;</td>
              <td>
                <label className="smp_report_label">{Open301}</label>
              </td>
              <td>
                <label className="smp_report_label">{Open302}</label>
              </td>
              <td>
                <label className="smp_report_label">{Open303}</label>
              </td>
              <td>CORE 2 OUT</td>
              <td>
                <label className="smp_report_label">{Cre4}</label>
              </td>
              <td>
                <label className="smp_report_label">{Core401}</label>
              </td>
              <td>
                <label className="smp_report_label">{Core402}</label>
              </td>
              <td>
                <label className="smp_report_label">{Core403}</label>
              </td>
            </tr>
            <tr>
              <td>
                Oil Temp&#x27E1;<br></br>±
                <span className="smp_report_set_value">{OilSet}</span>°C
              </td>
              <td colSpan="4">
                <label className="smp_report_label">{Oilt}</label>
              </td>
              <td>Open 3 &#x27E1;</td>
              <td>
                <label className="smp_report_label">{Open401}</label>
              </td>
              <td>
                <label className="smp_report_label">{Open402}</label>
              </td>
              <td>
                <label className="smp_report_label">{Open403}</label>
              </td>
              <td colSpan="5"></td>
            </tr>
            <tr>
              <td colSpan={3}>Prepared By</td>
              <td colSpan={4}>
                <label className="smp_report_label">
                  {PreparedBy}-{prepared_by}
                </label>
              </td>
              <td colSpan={2}>Approver</td>
              <td colSpan={5}>
                <label className="smp_report_label">
                  {ApprovedBy}-{approved_by}
                </label>
              </td>
            </tr>
            {/* <tr>
              <td colSpan="14">
                <button type="submit" id="sub" onClick={() => handleCreate()}>
                  Submit
                </button>
              </td>
            </tr> */}
          </tbody>
        </table>
      </form>
    </div>
  );
}

export default SmpView;
