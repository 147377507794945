import React, { useState, useEffect } from "react";
import { SECRET_KEY, PROCESS_TRANSACTION_UPDATE_URL } from "../../../Utils/constants.js";
import Popup from "../../../Components/Popup/Popup.js";
import HandleRead from "../../../Lib/HandleRead.js"
import Pagination from "../../../Components/Pagination.js";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import UpdateRecord from "../../../Lib/updateRecord.js";
import { MdDelete } from "react-icons/md";
import DropdownFetcher from "../../../Lib/DropdownFetcher.js";
import Select from "react-select";
import ReadRecord from "../../../Lib/API/ReadRecord.js";

function MasterHeadView() {
  const [isCreating, setIsCreating] = useState(false);
  const [MasterheadViewState, setMasterheadViewState] = useState({
    Id: "",
    // Bay: "",
    MacNme: "",
    PrtNo: "",
    PrtName: "",
    RawMat: "",
    Flexy1: "",
    Flexy2: "",
    Flexy3: "",
    ActSts: "",
    isPaginationVisible: true,
    currentPage: 1,
    filterMcno: "",
    filterprtno: "",
    filterprtname:"",
    mas_hd: []
  })

  const [ButtonPopup2, setButtonPopup2] = useState(false);
  const [ButtonPopup, setButtonPopup] = useState(false);
  const [mshd_mas, setmshd_mas] = useState([]);
  const [dropdown, setDropdown] = useState([]);
  const [Prtdropdown, setPrtDropdown] = useState([]);
  const [Rmdropdown, setRmDropdown] = useState([]);
  const recordPerPage = 20;


  const filterRecords = () => {
    const filteredData = mshd_mas.filter((record) => {
      const mcnoMatch = record.Mh_mac_name
        .toLowerCase()
        .includes(MasterheadViewState.filterMcno.toLowerCase());
      const partcd = record.Mh_prt_no
        .toLowerCase().
        includes(MasterheadViewState.filterprtno.toLowerCase());
      const partname = record.Mh_prt_name
        .toLowerCase().
        includes(MasterheadViewState.filterprtname.toLowerCase());
      return mcnoMatch && partcd && partname;
    });
    return filteredData || [];
  };
  const filteredData = filterRecords();
  const npage = Math.ceil(filteredData.length / recordPerPage);
  const numbers = [...Array(npage).keys()].map((num) => num + 1);

  function handleFilterMcnoChange(event) {
    setMasterheadViewState({
      ...MasterheadViewState,
      currentPage: 1,
      filterMcno: event.target.value
    });
  }
  function handleFilterPrtnoChange(event) {
   
    setMasterheadViewState({
      ...MasterheadViewState,
      currentPage: 1,
      filterprtno: event.target.value
    });
  }
  function handleFilterPrtnameChange(event) {
    setMasterheadViewState({
      ...MasterheadViewState,
      currentPage: 1,
      filterprtname: event.target.value
    });
  }
  const firstIndex = (MasterheadViewState.currentPage - 1) * recordPerPage;
  const lastIndex = firstIndex + recordPerPage;
  const filteredRecords = filteredData.slice(firstIndex, lastIndex);

  function prePage() {
    if (MasterheadViewState.currentPage > 1) {
      setMasterheadViewState({
        ...MasterheadViewState,
        currentPage: MasterheadViewState.currentPage - 1
      });
    }
  }

  function nextPage() {
    if (MasterheadViewState.currentPage < npage) {
      setMasterheadViewState({
        ...MasterheadViewState,
        currentPage: MasterheadViewState.currentPage + 1
      });
    }
  }

  function changeCpage(id) {
    setMasterheadViewState({
      ...MasterheadViewState,
      currentPage: id
    });
  }

  const transferValue2 = (e) => {
    setMasterheadViewState({
      ...MasterheadViewState,
      isPaginationVisible: false,
      Id: e.Id,
      // Bay: e.Mh_bay,
      MacNme: e.Mh_mac_name,
      partcd: e.Mh_prt_no,
      PrtName: e.Mh_prt_name,
      RawMat: e.Mh_raw_mat,
      ActSts: e.Mh_active_status,
    });
    setButtonPopup2(true);

  };

  const viewPagination = () => {
    setMasterheadViewState({
      ...MasterheadViewState,
      isPaginationVisible: true
    });
  };

  const handleEdit = (e) => {
    setMasterheadViewState({
      ...MasterheadViewState,
      isPaginationVisible: false,
      Id: e.Id,
      // Bay: e.Mh_bay,
      MacNme: { value: e.Mh_mac_name, label: e.Mh_mac_name },
      partcd: { value: e.Mh_prt_no, label: e.Mh_prt_no} ,
      PrtName: { value: e.Mh_prt_name, label: e.Mh_prt_name} ,
      RawMat: { value: e.Mh_raw_mat, label: e.Mh_raw_mat, },
      ActSts: e.Mh_active_status,
    });
    setButtonPopup(true);
    console.log(e)

  };
  const mcNoOptions = dropdown.map((i) => ({
    value: i.mc_code,
    label: i.mc_name,
  }));
  const PrtNoOptions = Prtdropdown.map((i) => ({
    value: i.mc_part_code,
    label: i.mc_part_code,
  }));
  const PrtNameOptions = Prtdropdown.map((i) => ({
    value: i.mc_part_name,
    label: i.mc_part_name,
  }));
  const RmNoOptions = Rmdropdown.map((i) => ({
    value: i.Rm_code,
    label: i.Rm_description,
  }));
  const handleDropdownChange = (selectedOption) => {
    const selectedMachine = dropdown.find(
      (option) => option.mc_name === selectedOption.label
    );
    if (selectedMachine) {
      setMasterheadViewState({
        ...MasterheadViewState,
        MacNme: {
          value: selectedMachine.mc_code,
          label: selectedMachine.mc_name,
        }
      });
    }
  };
  const handlePrtDropdownChange = (selectedOption) => {
    const selectedMachine = Prtdropdown.find(
      (option) => option.mc_part_code === selectedOption.value
    );
    if (selectedMachine) {
      setMasterheadViewState({
        ...MasterheadViewState,
        partcd: {
          value: selectedMachine.mc_part_code,
          label: selectedMachine.mc_part_code,
        }
      });
    }
  };
  const handlePrtNameDropdownChange = (selectedOption) => {
    const selectedMachine = Prtdropdown.find(
      (option) => option.mc_part_name === selectedOption.value
    );
    if (selectedMachine) {
      setMasterheadViewState({
        ...MasterheadViewState,
        PrtName: {
          value: selectedMachine.mc_part_name,
          label: selectedMachine.mc_part_name,
        }
      });
    }
  };
  const handleRmDropdownChange = (selectedOption) => {
    const selectedRm = Rmdropdown.find((option) => option.Rm_code === selectedOption.value);
    if (selectedRm) {
      setMasterheadViewState({
        ...MasterheadViewState,
        RawMat: {
          value: selectedRm.Rm_code,
          label: selectedRm.Rm_description,
        }
      });
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    if (!MasterheadViewState.MacNme) {
      setIsCreating(true);
      alert(GeneralConstants.MANDATORY_ALERT);
      return;
    }
    const data = {
      data: [
        {
          Id: MasterheadViewState.Id,
          // Mh_bay: MasterheadViewState.Bay,
          Mh_mac_name: MasterheadViewState.MacNme.value,
          Mh_prt_no: MasterheadViewState.partcd.value,
          Mh_prt_name: MasterheadViewState.PrtName.value,
          Mh_raw_mat: MasterheadViewState.RawMat.value,
          Mh_active_status: MasterheadViewState.ActSts,
        },
      ],
    };
    data["_keyword_"] = "master_head_details",
      data["secretkey"] = SECRET_KEY;
    getAPIRequest(
      PROCESS_TRANSACTION_UPDATE_URL, 'POST', data
    ).then((response) => {
      setMasterheadViewState({
        ...MasterheadViewState,
        MacCde: "",
        MacNme: "",
        MacVer: "",
        Flexy1: "",
        Flexy2: "",
        Flexy3: "",
        isPaginationVisible: true,
      });
      alert(`${GeneralConstants.UPDATE_SUCCESSFUL}`);
      // window.location.reload();
      setButtonPopup(false);
      handleReadHeadMaster();

    })
      .catch((error) => {
        console.log(error);
        alert(GeneralConstants.UPDATE_FAILURE);
        window.location.reload();
      });

  };
  function GetSelectValue(value, keyword, setName) {

    if (value.length > 0) {
      ReadRecord({
        _keyword_: keyword,
        key: "%" + value + "%",
        value: "%" + value + "%"
      }).then((res) => {
        console.log(res.data);
        setName(res.data);
        // setKey(keyvalue[options.reference]);
      });
    }
  }
  function handleReadHeadMaster() {
    ReadRecord({
      _keyword_: "master_head_details"
    }).then((res) => {
      // console.log(res.data);
      setmshd_mas(res.data);
    });
  }
  useEffect(() => {
    handleReadHeadMaster();
  }, [])
  return (
    <div>
      <Popup

        trigger={ButtonPopup2}
        setTrigger={setButtonPopup2}
        viewPagination={viewPagination}
      >
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <form className="form-machinePartMaster-View">
          <table style={{ textAlign: "center", width: "100%" }} cellPadding="5" >
            <tbody>
              <tr>
                <th
                  id="mhdv.Title.View"
                  name="mhdv.Title.View"
                  colSpan="4"
                  style={{ textAlign: "center", whiteSpace: "nowrap" }}
                >
                  <font color="#8B0000">View - Master Head</font>
                </th>
              </tr>
              <tr>
                {/* <th
                  width="25%"
                  style={{ textAlign: "Center", whiteSpace: "nowrap" }}
                >
                  Bay
                </th>
                <td width="25%">
                  <label id="mhdv.masterheadbay.View"
                    name="mhdv.masterheadbay.View" >{MasterheadViewState.Bay}</label>
                </td> */}
                
                <th
                  width="25%"
                  style={{ textAlign: "Center", whiteSpace: "nowrap" }}
                >
                  Part Number
                </th>
                <td width="25%">
                  <label id="mhdv.masterheadpc.view"
                    name="mhdv.masterheadpc.view">{MasterheadViewState.partcd}</label>
                </td>
                <th
                  width="25%"
                  style={{ textAlign: "Center", whiteSpace: "nowrap" }}
                >
                  Part Name
                </th>
                <td width="25%">
                  <label id="mhdv.masterheadpc.view"
                    name="mhdv.masterheadpc.view">{MasterheadViewState.PrtName}</label>
                </td>
              </tr>
              <tr>
                
                <th
                  width="25%"
                  style={{ textAlign: "Center", whiteSpace: "nowrap" }}
                >
                  Raw Material
                </th>
                <td width="25%">
                  <label id="mhdv.masterheadrm.view"
                    name="mhdv.masterheadrm.view">{MasterheadViewState.RawMat}</label>
                </td>
                <th
                  width="25%"
                  style={{ textAlign: "Center", whiteSpace: "nowrap" }}
                >
                  Machine Name
                </th>
                <td width="25%">
                  <label id="mhdv.masterheadmn.View"
                    name="mhdv.masterheadmn.View">{MasterheadViewState.MacNme}</label>
                </td>
                
              </tr>
              <tr>
              <th style={{ textAlign: "Center", whiteSpace: "nowrap" }}>
                  Active Status
                </th>
                <td >
                  <label id="mhdv.masterheadAs.view"
                    name="mhdv.masterheadAs.view">{MasterheadViewState.ActSts}</label>
                </td>
                <td colSpan={2}></td>
              </tr>
              
            </tbody>
          </table>
        </form>
      </Popup>
      <Popup
        trigger={ButtonPopup}
        setTrigger={setButtonPopup}
        viewPagination={viewPagination}
      >
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <form
          className="form-machinePartMaster-Edit">
          <table style={{ textAlign: "center", width: "100%" }} cellPadding="5">
            <tbody>
              <tr>
                <th
                  id="mpme.Title"
                  name="mpme.Title"
                  colSpan="4"
                  style={{ textAlign: "center", whiteSpace: "nowrap" }}
                >
                  <font color="#8B0000">Edit - Master Head</font>
                </th>
              </tr>
              <tr>
                {/* <th>&nbsp;&nbsp;Bay<span className="Req-i">*</span></th>
                <td>
                  <select
                    value={MasterheadViewState.Bay}
                    onChange={(e) => setMasterheadViewState({
                      ...MasterheadViewState,
                      Bay: e.target.value
                    })}
                    style={{ width: "36%", textAlign: "center" }}
                  >
                    <option value="">-- Select --</option>
                    {[...Array(20).keys()].map((value) => (
                      <option key={value + 1} value={String(value + 1)}>
                        {value + 1}
                      </option>
                    ))}
                  </select>
                </td> */}
                
                <th>&nbsp;&nbsp;Part Number<span className="Req-i">*</span></th>
                <td>
                  {/* <DropdownFetcher
                  keyword="mac_part_dtl_dropdown"
                  setValue={setPrtDropdown}
                /> */}
                  <Select
                    value={MasterheadViewState.partcd}
                    onChange={handlePrtDropdownChange}
                    onInputChange={(event) => { GetSelectValue(event, "mac_part_dtl_dropdown", setPrtDropdown) }}
                    options={PrtNoOptions}
                    // placeholder={MasterheadViewState.partcd} 
                    />
                </td>
                <th>&nbsp;&nbsp;Part Name<span className="Req-i">*</span></th>
                <td>
                  {/* <DropdownFetcher
                  keyword="mac_part_dtl_dropdown"
                  setValue={setPrtDropdown}
                /> */}
                  <Select
                    value={MasterheadViewState.PrtName}
                    onChange={handlePrtNameDropdownChange}
                    onInputChange={(event) => { GetSelectValue(event, "mac_part_dtl_dropdown", setPrtDropdown) }}
                    options={PrtNameOptions}
                    // placeholder={MasterheadViewState.PrtName} 
                    />
                </td>
              </tr>
              <tr>         

                <th>&nbsp;&nbsp;Raw Material<span className="Req-i">*</span></th>
                <td>
                  {/* <DropdownFetcher
                  keyword="rm_details_dropdown"
                  setValue={setRmDropdown}
                /> */}
                  <Select
                    value={MasterheadViewState.RawMat}
                    onChange={handleRmDropdownChange}
                    options={RmNoOptions}
                    // placeholder={MasterheadViewState.RawMat}
                    onInputChange={(event) => { GetSelectValue(event, "rm_details_dropdown", setRmDropdown) }}

                  />
                </td>
                <th>&nbsp;&nbsp;Machine Name<span className="Req-i">*</span></th>
                <td>
                  <DropdownFetcher
                    keyword="mac_dtl_dropdown"
                    setValue={setDropdown}
                  />
                  <Select
                    value={MasterheadViewState.MacNme}
                    onChange={handleDropdownChange}
                    options={mcNoOptions}
                    // placeholder={MasterheadViewState.MacNme} 
                    />
                </td>
                
              </tr>
              <tr>
              <th>&nbsp;&nbsp;Active Status<span className="Req-i">*</span></th>
                <td >
                  <select
                    id="mhd.ActiveStatus"
                    name="mhd.ActiveStatus"
                    value={MasterheadViewState.ActSts}
                    onChange={(e) => setMasterheadViewState({
                      ...MasterheadViewState,
                      ActSts: e.target.value
                    })}
                    style={{ width: "90%", textAlign: "center" }}
                  >
                    <option id="mhd.ActiveStatus.active"
                      name="mhd.ActiveStatus.active"
                      value="Active">Active</option>
                    <option id="mhd.ActiveStatus.inactive"
                      name="mhd.ActiveStatus.inactive"
                      value="InActive">InActive</option>
                  </select>
                </td>
                <td colSpan={2}></td>
              </tr>
              <tr>
                <td colSpan="4" style={{ border: "none" }}>
                  <button id="mpme.submit" name="mpme.submit" type="submit" onClick={(e) => handleUpdate(e)}>
                    Update
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </Popup>

      <div>
        <table
          style={{ textAlign: "center", width: "100%" }}

        >
          <thead>
            <tr>
              <th
                id="mhdv.Title.List"
                name="mhdv.Title.List"
                colSpan="8"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">List - Master Head</font>
              </th>
            </tr>
            <tr>
              {/* <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                Machine No
              </td> */}
              <td>
                <input
                  id="mhdv.filter.machinename"
                  name="mhdv.filter.machinename"
                  type="text"
                  placeholder="Filter MachineName"
                  style={{ fontSize: "80%", width: "100%" }}
                  value={MasterheadViewState.filterMcno}
                  onChange={handleFilterMcnoChange}
                />
              </td>
              {/* <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                Part Number
              </td> */}
              <td>
                <input
                  id="mhdv.filter.partcode"
                  name="mhdv.filter.partcode"
                  type="text"
                  placeholder="Filter PartNumber"
                  style={{ fontSize: "80%", width: "100%" }}
                  value={MasterheadViewState.filterprtno}
                  onChange={handleFilterPrtnoChange}
                />
              </td>
              <td>
                <input
                  id="mhdv.filter.partName"
                  name="mhdv.filter.partName"
                  type="text"
                  placeholder="Filter PartName"
                  style={{ fontSize: "80%", width: "100%" }}
                  value={MasterheadViewState.filterprtname}
                  onChange={handleFilterPrtnameChange}
                />
              </td>
              <td colSpan={4}></td>
            </tr>
            <tr className="table_position">
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Machine No&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Part Number&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Part Name&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Raw Material&nbsp;&nbsp;
              </th>
              <th width="10%"></th>
              <th width="10%"></th>
              {/* <th width="10%"></th> */}
            </tr>
          </thead>
          <tbody>
            {/* <HandleRead keyword="master_head_details" setData={setmshd_mas} /> */}
            {filteredRecords.length === 0 ? (
              <td colSpan="12" style={{ textAlign: "center" }}>
                <p id="mhdv.noRecordFound" name="mhdv.noRecordFound" style={{ fontWeight: "bold" }}>No records found.</p>
              </td>
            ) : (
              filteredRecords.map((mshd_mas) => (
                <tr className="table_view" key={mshd_mas.id}>
                  <td id="mhdv.mhmac" name="mhdv.mhmac" className="table-cell">{mshd_mas.Mh_mac_name}</td>
                  <td id="mhdv.mhprtcd" name="mhdv.mhprtcd" className="table-cell">{mshd_mas.Mh_prt_no}</td>
                  <td id="mhdv.mhprtcd" name="mhdv.mhprtcd" >{mshd_mas.Mh_prt_name}</td>
                  <td id="mhdv.mhrm" name="mhdv.mhrm" className="table-cell">{mshd_mas.Mh_raw_mat}</td>

                  <td>
                    <button
                      id="mhdv.mhead.View" name="mhdv.mhead.View"
                      style={{ backgroundColor: "#cba423" }}
                      type="button"
                      onClick={() => transferValue2(mshd_mas)}
                    >
                      View&#x1F441;
                    </button>
                  </td>
                  <td>
                    <button id="mhdv.mhead.Edit" name="mhdv.mhead.Edit" type="button" onClick={() => handleEdit(mshd_mas)}>
                      Edit&#x270E;
                    </button>
                  </td>
                  {/* <td>
                    <button
                      id={`master_head_details-Delete`}
                      name={`master_head_details-Delete`}
                      style={{ whiteSpace: "nowrap", backgroundColor: "#DA251A" }}
                      type="button"
                      onClick={() => {
                        const userConfirmed = window.confirm('Are you sure you want to delete?');
                        if (userConfirmed) {
                          UpdateRecord({
                            _keyword_: 'master_head_details',
                            data: [
                              {
                                Id: mshd_mas.Id,
                                Mh_active_status: 'InActive',
                              },
                            ],
                          }).then(() => {
                            handleReadHeadMaster();
                          })
                          // console.log(mshd_mas.Id);


                          // window.location.reload();
                        }
                      }}
                    >
                      Delete<MdDelete />
                    </button>
                  </td> */}
                </tr>
              ))
            )}
          </tbody>
        </table>
        <div className="pagination" id="mhdv.pagination" name="mhdv.pagination">

          {MasterheadViewState.isPaginationVisible && filteredData.length > 0 && (

            <Pagination currentPage={MasterheadViewState.currentPage} npage={npage} prePage={prePage} nextPage={nextPage} changeCpage={changeCpage} numbers={numbers} />
          )}

        </div>
      </div>
    </div>
  );
}

export default MasterHeadView;
