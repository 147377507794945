import axios from "axios";
import {
  PROCESS_TRANSACTION_DELETE_URL_PAINT,
  SECRET_KEY,
} from "../../Utils/constants";

const DeleteOriginalRecordPaint = async (KeyWord, input) => {
  try {
    const res = await axios
      .post(PROCESS_TRANSACTION_DELETE_URL_PAINT, {
        data: input,
        _keyword_: KeyWord,
        secretkey: SECRET_KEY,
      })
      .then((res) => {
        console.log("DeleteOriginalRecord - ", input, res);
        return res;
      });
    return res.data;
  } catch (error) {
    return { error: error };
  }
};
export default DeleteOriginalRecordPaint;
