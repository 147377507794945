import React from "react";
import QaLayoutPaint from "../../QaLayoutPaint";
import ListViewPaint from "../../../../Components/ListView/ListViewPaint";

const FinalInspectionList = () => {
  return (
    <QaLayoutPaint quary={"paint_final_inspection"}>
      <div>
        <ListViewPaint
          Title={"Paint Final Inspection"}
          standard={"Delete_id = 0"}


          quary={
           "SELECT qp.Id,qp.Entry_date,ed_Ins_name.emp_name AS Ins_name,ed_Inspector_sign.emp_name AS Inspector_sign,ed_Shift_incharge.emp_name AS Shift_incharge,ed_Approved_sign.emp_name AS ApprovedBy FROM paint_final_inspection qp LEFT JOIN emp_details ed_Ins_name ON qp.Ins_name = ed_Ins_name.emp_code LEFT JOIN emp_details ed_Inspector_sign ON qp.Inspector_sign = ed_Inspector_sign.emp_code LEFT JOIN emp_details ed_Shift_incharge ON qp.Shift_incharge = ed_Shift_incharge.emp_code LEFT JOIN emp_details ed_Approved_sign ON qp.Approved_sign = ed_Approved_sign.emp_code group by qp.Id order by qp.Id desc"
          }
          table={"paint_final_inspection"}
          tableDelete={"paint_final_inspection"}
          navigation={"FinalInspection"}
          filter={["qp.Id"]}
          
          dateFilter={"qp.CreatedDate"}
          header={[
            { value: "qp.Id", label: "ID" },
            { value: "Entry_date", label: "Date" },
            { value: "Ins_name", label: "Ins Name" },
            { value: "Inspector_sign", label: "Inspector Sign"},
            { value: "Shift_incharge", label: "Shift Incharge"},
            { value: "ApprovedBy", label: "ApprovedBy"},
          ]}
        />
      </div>
    </QaLayoutPaint>
  );
};

export default FinalInspectionList;
