import React from "react";
import ListView from "../../../Components/ListView/ListView";
import QaLayout from "../QaLayout";

const FirstOffList = () => {
  return (
    <QaLayout quary={"quality_online_inspection"}>
      <div>
        <ListView 
          Title={"Online Inspection"}
          table={"quality_online_inspection qp"}
          tableDelete={"quality_online_inspection"}
          standard={"qp.ActiveStatus = 1"}
          // quary={"Select qp.Id,qp.Entry_date,qp.part_number,qp.Part_name,qp.Machine_no,qp.Raw_material_name,rm_details.rm_description AS rm_name from quality_online_inspection qp LEFT JOIN rm_details ON qp.raw_material_name =rm_details.rm_code group by qp.Id,qp.part_name,qp.part_number,qp.Raw_material_name,rm_details.rm_description order by qp.Id desc"}
          quary={"SELECT distinct qp.Id,qp.Entry_date,qp.part_number,qp.Part_name,qp.Machine_no,qp.Raw_material_name,rm_details.rm_description AS rm_name FROM quality_online_inspection qp LEFT JOIN rm_details ON qp.raw_material_name = rm_details.rm_code order by qp.Id desc"}
          navigation={"onlineinspection"}
          filter={["qp.Id","qp.Machine_no","qp.part_number","qp.Part_name"]}
        dateFilter={"qp.CreatedDate"}
        header={[
            { value: "qp.Id", label: "ID" },
            { value: "qp.Entry_date", label: "Date" },
            { value: "qp.Machine_no", label: "Machine No" },
            { value: "qp.part_number", label: "Part Number" },
            { value: "qp.Part_name", label: "Part Name" },
            // { value: "qp.Raw_material_name", label: "Raw Material" },
            { value: "rm_name", label: "Raw Name" },
            // { value: "Prodcution_operator_name", label: "Prodcution Operator" },
            // { value: "Approval_status", label: "Approval status" },
          ]}
        />
      </div>
    </QaLayout>
  );
};

export default FirstOffList;
