import React, { useState,useEffect } from "react";

import { PROCESS_TRANSACTION_URL, CODEGEN_URL, SECRET_KEY } from "../../../Utils/constants.js";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";

import "../../../Styles/Part3.css";
const initialState = {

    RmCde: "",
    RmDescn: "",
    Grade: "",
    LotNo: "",
    MatLdTm: "",
    PreHtHrs: "",
    PreHtTemp: "",
    Flexy1: "",
    Colour: "",
    Flexy3: "",
    Quantity: "",
    LsDetails: "",
    Rm_mas: []
}

function RMMasterCreate({tab1}) {
    const [isCreating, setIsCreating] = useState(false);
    const [rmMasterState, setRMMasterState] = useState(initialState);
    useEffect(() => {
      setRMMasterState(initialState);
      }, [tab1])
    const CodeGen = async () => {
        try {
            const val = {
                tablename: "rm_details",
                PrimaryCode: "id",
                PrimaryVal: rmMasterState.Id,
                FieldCode: "rm_code",
                FieldVal: rmMasterState.RmCde,
            };

            const response = await getAPIRequest(
                CODEGEN_URL, 'POST', val
            );
            const response1 = await response.json();
            return (response1.success === 'true');
        } catch (error) {
            throw error;
        }
    };


    const handleCreate = async (e) => {
        e.preventDefault();
        const isSubmissionSuccessful = true;

        if (!rmMasterState.RmCde || !rmMasterState.RmDescn || !rmMasterState.Grade || !rmMasterState.MatLdTm) {
            setIsCreating(true);

            alert(GeneralConstants.MANDATORY_ALERT);
            return;
        }
        try {
            // const isDuplicate = await CodeGen();

            // if (isDuplicate) {
            //     console.log("jj")
            //     alert(`${rmMasterState.RmDescn} ${GeneralConstants.ALREADY_EXIST}`);
            //     setRMMasterState({
            //         ...rmMasterState,
            //         ...initialState
            //     });
            //     setIsCreating(false);

            //     return;
            // }
            const data = {

                data: [
                    {
                        Rm_code: rmMasterState.RmCde,
                        Rm_description: rmMasterState.RmDescn,
                        Rm_grade: rmMasterState.Grade,
                        Rm_lotno: rmMasterState.LotNo,
                        Rm_matloadingtime: rmMasterState.MatLdTm,
                        Rm_preheathrs: rmMasterState.PreHtHrs,
                        Rm_preheattemp: rmMasterState.PreHtTemp,
                        Rm_flex_01: rmMasterState.Flexy1,
                        Rm_Colour: rmMasterState.Colour,
                        Rm_flex_03: rmMasterState.Flexy3,
                        rm_quantity: rmMasterState.Quantity,
                        rm_ls_details: rmMasterState.LsDetails,

                    },
                ],
                _keyword_: "rm_details_create",
                secretkey: SECRET_KEY,
            };

            const response = await getAPIRequest(
                PROCESS_TRANSACTION_URL, 'POST', data
            );
            const response1 = await response.json();

            if (response1.success === "false") {
                alert(GeneralConstants.FAIL_TO_SAVE_RM);
                window.location.reload();
            }
            else {
                const rmmas = [...rmMasterState.Rm_mas, response.data]
                setRMMasterState({ ...rmMasterState, Rm_mas: rmmas });
            }
            if (isSubmissionSuccessful) {
                alert(`Raw Material Master Code ${GeneralConstants.SUBMISSION_SUCCESSFUL}`);
                setRMMasterState({
                    ...rmMasterState, ...initialState
                });
                setIsCreating(false);

            }
        } catch (error) {
            console.log(error);
            alert(` ${GeneralConstants.SUBMISSION_FAILED}`);
            window.location.reload();
        }

    };

    return (
        <div >
            <form id="RmMasterCreate.formContainer" name="RmMasterCreate.formContainer" className="form-RmMaster-Create">
                <table style={{ textAlign: "center", width: "100%" }} cellPadding="5" >
                    <tbody>
                        <tr>
                            <th
                                id="rmmc.Title"
                                name="rmmc.Title"
                                colSpan="4"
                                style={{ textAlign: "center", whiteSpace: "nowrap" }}
                            >
                                <font color="#8B0000">Raw Material Master</font>
                            </th>
                        </tr>
                        <tr>
                            <th>&nbsp;&nbsp;Raw Material Code<span style={{ color: "red" }}>*</span></th>
                            <td>
                                {rmMasterState.RmCde === '' && isCreating && <span className="Req">Required *</span>}
                                <input
                                    id="rmmc.RMCode"
                                    name="rmmc.RMCode"
                                    type="text"
                                    value={rmMasterState.RmCde}
                                    onChange={(e) => setRMMasterState({
                                        ...rmMasterState,
                                        RmCde: e.target.value
                                    })}
                                    required
                                />
                            </td>
                            <th>&nbsp;&nbsp;Raw Material Description<span style={{ color: "red" }}>*</span></th>
                            <td>
                                {rmMasterState.RmDescn === '' && isCreating && <span className="Req">Required *</span>}
                                <input
                                    id="rmmc.RMName"
                                    name="rmmc.RMName"
                                    type="text"
                                    value={rmMasterState.RmDescn}
                                    onChange={(e) => setRMMasterState({
                                        ...rmMasterState,
                                        RmDescn: e.target.value
                                    })}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>&nbsp;&nbsp; Grade<span style={{ color: "red" }}>*</span></th>
                            <td>
                                {rmMasterState.Grade === '' && isCreating && <span className="Req">Required *</span>}
                                <input
                                    id="rmmc.FGGrade"
                                    name="rmmc.FGGrade"
                                    type="text"
                                    value={rmMasterState.Grade}
                                    onChange={(e) => setRMMasterState({
                                        ...rmMasterState,
                                        Grade: e.target.value
                                    })}
                                />
                            </td>
                            <th>&nbsp;&nbsp;Lot No</th>
                            <td>
                                <input
                                    id="rmmc.Lotno"
                                    name="rmmc.Lotno"
                                    type="number"
                                    value={rmMasterState.LotNo}
                                    onChange={(e) => setRMMasterState({
                                        ...rmMasterState,
                                        LotNo: e.target.value
                                    })}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>&nbsp;&nbsp;Unit Of Measure<span style={{ color: "red" }}>*</span></th>
                            <td>
                                {/* <input
                                    id="rmmc.MaterialLoadingTime"
                                    name="rmmc.MaterialLoadingTime"
                                    type="text"
                                    value={rmMasterState.MatLdTm}
                                    onChange={(e) => setRMMasterState({
                                        ...rmMasterState,
                                        MatLdTm: e.target.value
                                    })}
                                /> */}
                                 {rmMasterState.MatLdTm === '' && isCreating && <span className="Req">Required *</span>}
                                <select
                                    id="rmmc.UnitOfMeasure"
                                    name="rmmc.UnitOfMeasure"
                                    value={rmMasterState.MatLdTm}
                                    onChange={(e) => setRMMasterState({
                                        ...rmMasterState,
                                        MatLdTm: e.target.value
                                    })}
                                    style={{ width: "95%", textAlign: "center" }}
                                >
                                    <option id="rmmc.UnitOfMeasure.select"
                                        name="rmmc.UnitOfMeasure.select"
                                        value="">-- Select --</option>
                                    <option id="rmmc.UnitOfMeasure.KGS"
                                        name="rmmc.UnitOfMeasure.KGS"
                                        value="KGS">KGS</option>
                                    <option id="rmmc.UnitOfMeasure.LIT"
                                        name="rmmc.UnitOfMeasure.LIT"
                                        value="LIT">LIT</option>
                                    <option id="rmmc.UnitOfMeasure.NOS"
                                        name="rmmc.UnitOfMeasure.NOS"
                                        value="NOS">NOS</option>
                                </select>
                            </td>
                            {/* <th>&nbsp;&nbsp;Pre Heat Hours</th>
                            <td>
                                <input
                                    id="rmmc.PreHeatHours"
                                    name="rmmc.PreHeatHours"
                                    type="number"
                                    value={rmMasterState.PreHtHrs}
                                    onChange={(e) => setRMMasterState({
                                        ...rmMasterState,
                                        PreHtHrs: e.target.value
                                    })}
                                />
                            </td> */}
                            <th>&nbsp;&nbsp;Quantity</th>
                            <td>
                                <input
                                    id="rmmc.Quantity"
                                    name="rmmc.Quantity"
                                    type="number"
                                    value={rmMasterState.Quantity}
                                    onChange={(e) => setRMMasterState({
                                        ...rmMasterState,
                                        Quantity: e.target.value
                                    })}
                                />
                            </td>
                        </tr>


                        {/* <tr> */}
                            {/* <th>&nbsp;&nbsp;Pre Heating Temp</th>
                            <td>
                                <input
                                    id="rmmc.PreHeatingTime"
                                    name="rmmc.PreHeatingTime"
                                    type="number"
                                    value={rmMasterState.PreHtTemp}
                                    onChange={(e) => setRMMasterState({
                                        ...rmMasterState,
                                        PreHtTemp: e.target.value
                                    })}
                                />
                            </td> */}

                            {/* <th>&nbsp;&nbsp;Quantity</th>
                            <td>
                                <input
                                    id="rmmc.Quantity"
                                    name="rmmc.Quantity"
                                    type="number"
                                    value={rmMasterState.Quantity}
                                    onChange={(e) => setRMMasterState({
                                        ...rmMasterState,
                                        Quantity: e.target.value
                                    })}
                                />
                            </td> */}
                        {/* </tr> */}
                        {/* <tr> */}
                        {/* <th>&nbsp;&nbsp;L/s No</th>
                            <td>
                                <input
                                    id="rmmc.LsDetails"
                                    name="rmmc.LsDetails"
                                    type="text"
                                    value={rmMasterState.LsDetails}
                                    onChange={(e) => setRMMasterState({
                                        ...rmMasterState,
                                        LsDetails: e.target.value
                                    })}
                                />
                            </td>  */}
                        {/* <th>&nbsp;&nbsp;Flex 1</th>
                            <td>
                                <input
                                    id="rmmc.Flexy1"
                                    name="rmmc.Flexy1"
                                    type="text"
                                    value={rmMasterState.Flexy1}
                                    onChange={(e) => setRMMasterState({
                                        ...rmMasterState,
                                        Flexy1: e.target.value
                                    })}
                                />
                            </td>
                            </tr> */}
                        <tr>
                            <th>&nbsp;&nbsp;Colour</th>
                            <td>
                                <input
                                    id="rmmc.Colour"
                                    name="rmmc.Colour"
                                    type="text"
                                    value={rmMasterState.Colour}
                                    onChange={(e) => setRMMasterState({
                                        ...rmMasterState,
                                        Colour: e.target.value
                                    })}
                                />
                            </td>
                            <th>&nbsp;&nbsp;Flex 3</th>
                            <td>
                                <input
                                    id="rmmc.Flexy3"
                                    name="rmmc.Flexy3"
                                    type="text"
                                    value={rmMasterState.Flexy3}
                                    onChange={(e) => setRMMasterState({
                                        ...rmMasterState,
                                        Flexy3: e.target.value
                                    })}
                                />
                            </td>

                        </tr>
                        <tr>
                            <td colSpan="4" style={{ border: "none" }}>
                                <button type="submit" id="rmmc.submit" name="rmmc.submit" onClick={(e) => handleCreate(e)}>
                                    Submit
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    );
}


export default RMMasterCreate;
