import React from "react";
import QaLayoutPaint from "../../QaLayoutPaint";
import ListViewPaint from "../../../../Components/ListView/ListViewPaint";

const PaintMasterList = () => {
  return (
    <QaLayoutPaint quary={"paint_master"}>
    <div>
      <ListViewPaint
        Title={"Paint Master"}
        standard={["delete_id=0 "]}
        quary={"Select *,id AS Id from paint_master group by Id ORDER BY CASE WHEN active_status = 'Active' THEN 0 ELSE 1 END,Id DESC"}
        table={"paint_master"}
        tableDelete={"paint_master"}
        navigation={"PaintMaster"}
        filter={["Id","paint_code","paint_name","uom","active_status"]}
        dateFilter={"CreatedDate"}
        header={[
          { value: "Id", label: "ID" },
          { value: "CreatedDate", label: "Date" },
          { value: "paint_code", label: "Paint Code" },
          { value: "paint_name", label: "Paint Name" },
          { value: "uom", label: "Uom" },
          { value: "active_status", label: "Active Status" },
        ]}
      />   
    </div>
    </QaLayoutPaint>
  );
};

export default PaintMasterList;
