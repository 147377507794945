import axios from "axios";
import { API_URL, BUILD_RESULTS_GENERIC } from "../Utils/constants";
import { getAPIRequest } from "../Utils/APIUtility";

const GetRecord = async (data, setState) => {
  let success = false;
  let rows = "0";
  const response = await getAPIRequest(BUILD_RESULTS_GENERIC, "POST", data);
  const response1 = await response.json();
  // console.log(response1);
  // console.log(data._keyword_,response1.data);
  if (response1.rows == 0) {
    success = response1.success;
    
    setState([{}]);
  } else {
    setState(response1.data);
    rows = response1.rows;
    success = response1.success;
    if(success){
      return response1.data
    }
  }
  if (!success) {
    return "false";
  } else if (rows == 0) {
    return "NoRecordFound";
  }
};

function getAxios(data) {
  axios.post(
    `http://${API_URL}/GenericResultBuilderService/buildResults`,
    data
  );
}

export default GetRecord;
