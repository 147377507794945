
import React, { useState, useEffect } from "react";
import {useNavigate, useParams } from "react-router-dom";
import QaLayout from "../../../Quality/QaLayout.js";
import Field from "../../../../Components/Fields/Field.js";
import CreateRecordPaint from "../../../../Lib/API/CreateRecordPaint.js";
import ReadRecordPaint from "../../../../Lib/API/ReadRecordPaint.js";
import LoadingPage from "../../../../Components/Handler/LoadingPage.js";
import * as GeneralConstants from "../../../../Utils/GeneralConstants.js";
import UpdateRecordPaint from "../../../../Lib/API/UpdateRecordPaint.js";
import IsDuplicatePaint from "../../../../Lib/IsDuplicatePaint.js";
import Lable from "../../../../Components/Fields/Lable.js";
const initState = {
  // TrackDate: new Date().toISOString().split("T")[0],
  Program_code: "",
  Program_name: "",
  Active_status:"Active",
  Flex_01: "",
  Flex_02: "",
};

function ProgramMaster() {
  const [Create, setCreate] = useState(initState);
  const [warning, setwarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const { option, id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    console.log("Create", Create);
  }, [Create]);
   useEffect(() => {
    setwarning(false);
    document.title = "Paint Program Master";
    const fetchData = async () => {
      if (id === undefined) {
        setCreate(initState);
      } else {
        setLoading(true);
        try {
          const res = await ReadRecordPaint({
            _keyword_: "paint_program_master",
            Id: id,
          });  
          console.log("res",res)    
          setCreate(res.data[0])    
         // console.log("Res2",Create)
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, []);
  if (loading) {
    return <LoadingPage />;
  }

    const handleCreate = async () => {
      try {
        const IsDuplicatePaintCode = await IsDuplicatePaint("paint_program_master", [`program_code="${Create.Program_code}"`]);
  
        if (IsDuplicatePaintCode) {
          alert(`Program with code ${Create.Program_code} already exists.`);
          return;
        }
    
        const IsDuplicatePaintName = await IsDuplicatePaint("paint_program_master", [`program_name="${Create.Program_name}"`]);
    
        if (IsDuplicatePaintName) {
          alert(`Program with name ${Create.Program_name} already exists.`);
          return;
        }
        console.log(Create);
        if (!Create.Program_code || !Create.Program_name || !Create.Active_status) {
          alert("Please Fill the Mandatory Fields");
         setwarning(true);
          return;
        }
    
        let record = [{ ...Create }];
        const res = await CreateRecordPaint("paint_program_master", record);
        console.log("HandleCreate", res);
    
        alert(GeneralConstants.SUBMISSION_SUCCESSFUL);
       // setwarning(false);
        setCreate(initState);
      } catch (error) {
        console.error(error);
        alert(GeneralConstants.SUBMISSION_FAILED);
      //  setwarning(false);
      }
    };
    
    const handleUpdate = async () => {
      try { 
        if (!Create.Program_code || !Create.Program_name || !Create.Active_status) {
          alert("Please Fill the Mandatory Fields");
         // setwarning(true);
          return;
        } 
   
        let record = [{ ...Create }];
        const res = await UpdateRecordPaint("paint_program_master", record);
        console.log("HandleUpdate", res);
    
        alert(GeneralConstants.UPDATE_SUCCESSFUL);
      //  setwarning(false);
      } catch (error) {
        console.error(error);
        alert(GeneralConstants.UPDATE_FAILURE);
    
      }
      navigate(-1);
    };
   

  return (
    <QaLayout>
      <div>
        <table>
          <tbody>
            <tr>
              <th colSpan={8}>
                <Lable value={"Program Master"} type={"Head"} />
              </th>
            </tr>
            <tr>
              <td>
                <Field
                  label={"Program Code"}
                  name={"Program_code"}
                  options={{ type: "text" ,
                  required: true,
                  disabled: option === "view"|| option === "edit",
                  }}
                  warning={warning}
                  value={Create}
                  OnChange={(e) =>
                    setCreate({ ...Create, Program_code: e.target.value })
                  }
                />
              </td>
              <td>
                <Field
                  label={"Program Name"}
                  name={"Program_name"}
                  options={{ type: "text",  required: true,
                  disabled: option === "view"|| option === "edit",
                  }}
                  warning={warning}
                  value={Create}
                  OnChange={(e) =>
                    setCreate({ ...Create, Program_name: e.target.value })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>
                <Field
                  label={"Flex"}
                  name={"Flex_01"}
                  options={{ type: "text", disabled: option === "view", }}
                  value={Create}
                  OnChange={(e) =>
                    setCreate({ ...Create, Flex_01: e.target.value })
                  }
                />
              </td>
              <td>
                <Field
                  label={"Active Status"}
                  name={"Active_status"}
                  options={{
                    type: "dropdown",
                    required: true,
                    disabled: option === "view",
                    values: [
                      { value: "", label: "Select" },
                      { value: "Active", label: "Active" },
                      { value: "InActive", label: "InActive" },
                    ],
                  }}
                  warning={warning}
                  value={Create}
                  OnChange={(e) =>
                    setCreate({ ...Create, Active_status: e.target.value })
                  }
                />
              </td>
            </tr>
          
            {!(option === "view") && (
                <tr>
                  {id === undefined ? (
                    <td colSpan={19}>
                      {" "}
                      <Field
                        label={"Submit"}
                        options={{ type: "Button" }}
                        onClick={(e) => {
                          handleCreate(e);
                        }}
                      />
                    </td>
                  ) : (
                    <td colSpan={19}>
                      {" "}
                      <Field
                        label={"Update"}
                        options={{ type: "Button" }}
                        onClick={(e) => {
                          handleUpdate(e);
                        }}
                      />
                    </td>
                  )}
                </tr>
              )}
          </tbody>
        </table>
      </div>
    </QaLayout>
   
  );
}

export default ProgramMaster;
