//external import
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CloseButton from "react-bootstrap/CloseButton";

import * as XLSX from "xlsx";
import { Dropdown } from "react-bootstrap";
//internal import
import Field from "../../../../Components/Fields/FieldPaint";
import Lable from "../../../../Components/Fields/Lable";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useScrollEffect } from "../../../Quality/useScrollEffect";
import ReadRecordPaint from "../../../../Lib/API/ReadRecordPaint";
import approve from "../../../../Lib/approvePaint";
import reject from "../../../../Lib/rejectPaint";

//css import
import "./Paint.css";

//API imports
const PreViewPage = ({
  Records,
  setState,
  HandleCreate,
  option,
  HandleUpdate,
  id,
}) => {
  const scrollTop = useRef(null);

  const [exc, setexc] = useState(false);

  const [Approval, setApproval] = useState("");
  const [cardId, setcardId] = useState("");
  const [remarks, setremarks] = useState("");
  const [DownloadDiv, setDownloadDiv] = useState(true);
  const [downloadEnabled, setDownloadEnabled] = useState(true);
  useScrollEffect();
  useEffect(() => {
    console.log("90909", Records, option);
    // console.log("window.Location.href;", window.location.href.split('newSmpMain/')[1].split('/')[0]);
    console.log("window.Location.href;", window.location.href.includes("view"));
  }, []);
  const contentRef = useRef(null);
  const navigate = useNavigate();
  const paintNames = Records?.[0]?.paint_names.map((item, index) => {
    let value = "";
    if (index === 0) {
      value = Records[0].InspectorS[0].Emp_name || "";
    } else if (index === 1) {
      value = Records[0].Shiftincharge[0].Emp_name || "";
    } else if (index === 2) {
      value = Records[0].ApprovedS[0].Emp_name || "";
    }
    return { key: item.key, value };
  });

  // const totalReport = [
  //   uniquePartNames.length > 0 ? uniquePartNames : uniquePartNames1,
  //   paintNames,
  // ];
  // console.log("total:", totalReport);

  const totalFreshParts = {};
  Records[0].paint_final_inspection_bulk_report.forEach((report) => {
    let partName = "";
    if (
      report.PartName &&
      Array.isArray(report.PartName) &&
      report.PartName[0]
    ) {
      partName = report.PartName[0].Part_name;
    } else if (report.PartName && report.PartName.label) {
      partName = report.PartName.label;
    } else if (report.Part_name) {
      partName = report.Part_name;
    }
    const QtyChk = parseInt(report.Qty_chk) || 0;
    const QtyOk = parseInt(report.Qty_ok) || 0;
    const TotalRej = parseInt(report.Total_qty_rej) || 0;
    const TotalRejPer = QtyChk !== 0 ? (TotalRej / QtyChk) * 100 : 0;

    if (totalFreshParts.hasOwnProperty(partName)) {
      totalFreshParts[partName].QtyC += QtyChk;
      totalFreshParts[partName].QtyO += QtyOk;
      totalFreshParts[partName].TotalR += TotalRej;
      totalFreshParts[partName].TotalRPer = TotalRejPer;
    } else {
      totalFreshParts[partName] = {
        part: partName,
        QtyC: QtyChk,
        QtyO: QtyOk,
        TotalR: TotalRej,
        TotalRPer: TotalRejPer,
      };
    }
  });

  console.log("TotalReport:", totalFreshParts);
  // const maxLength = Object.keys(totalFreshParts).length > 7
  //     ? Object.keys(totalFreshParts).length
  //     : 7;
  const generatePDF = () => {
    setDownloadDiv(true);

    setTimeout(() => {
      document.querySelector(".myTable").style.display = "table";
      setDownloadEnabled(false);

      const pdf = new jsPDF("landscape", "mm", "a4");
      html2canvas(contentRef.current).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        pdf.addImage(imgData, "PNG", 10, 10, 280, 0);
        pdf.save(`Paint Final Inspection.pdf`);
        setDownloadEnabled(true);
      });

      setTimeout(() => {
        document.querySelector(".myTable").style.display = "none";
      }, 1000);
    }, 0);
  };
  const exportToExcel = () => {
    setexc(true);
    setTimeout(() => {
      if (Records.length === 0) {
        alert("No records found. Cannot export to Excel.");
        return;
      }
      const sheetData = document.getElementById("ExcelTable");
      const wsData = XLSX.utils.table_to_sheet(sheetData);
      wsData["!rows"] = [{ hpx: 50 }];
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, wsData, "Sheet1");
      XLSX.writeFile(wb, "Paint Final Inspection Report.xlsx");
      setexc(false);
    }, 1000);
  };

  useEffect(() => {
    console.log("PREVIEW Records", Records);
    // handleReadRecord("id",Records[0].Id);
    // setback(true)
  }, [Records]);
  useEffect(() => {
    if (Records[0].Id !== undefined) {
      const Id = Records[0].Id;
      const tablename = window.location.pathname.split("/")[1];
      ReadRecordPaint(
        `SELECT * FROM upd_data_model.approval where Approver="${localStorage.getItem(
          "empId"
        )}" and TableID="${Id}" and TableName="${tablename}"`
      ).then((res) => {
        console.log("Approcals Record", res.data[0].rows);
        setApproval(res.data[0].rows);
      });
    }
  }, []);
  useEffect(() => {
    console.log("Approcals Record ", Approval);
  });

  return (
    <div
      style={{
        padding: "2px",
        position: "absolute",
        top: "1px",
        left: "3%",
        width: "97%",
        background: "white",
      }}
    >
      <div style={{ marginRight: "2%", flexDirection: "row-reverse" }}>
        <CloseButton
          style={{
            padding: "12px",
            textAlign: "center",
            backgroundColor: "black",
            width: "5%",
          }}
          onClick={(e) => {
            setState(false);
          }}
        />
      </div>
      {Approval[0] &&
      window.location.href.includes("view") &&
      //  (localStorage.getItem("role") === "hod" ||
      //   localStorage.getItem("role") === "SR.EXECUTIVE" ||
      //   localStorage.getItem("role") === "GM" ||
      //   localStorage.getItem("role") === "ADMIN") &&
      Approval[0].Status === "Waiting for Approval" ? (
        <div className="actions">
          <button
            onClick={(e) => {
              approve(
                Records[0].Id,
                "FinalInspection",
                "paint_final_inspection"
              );
              navigate(-1);
            }}
            className="approvebtn"
          >
            Approve
          </button>
          <button
            onClick={() => {
              setcardId(id);
              // navigate(-1);
            }}
            className="rejectbtn"
          >
            Reject
          </button>
          {id === cardId && (
            <div>
              <div
                style={{
                  backgroundColor: "gray",
                  zIndex: "100",
                  filter: "blur(8px)",
                  position: "absolute",
                  opacity: ".8",
                  right: "0%",
                  bottom: "0%",
                  width: "100%",
                  height: "100%",
                }}
              ></div>
              <div
                style={{
                  position: "absolute",
                  zIndex: "110",
                  right: "32%",
                  color: "white",
                  bottom: "35%",
                  padding: "20px",
                  borderRadius: "10px",
                }}
              >
                <h3>Remarks</h3>
                <textarea
                  rows={5}
                  cols={50}
                  onChange={(e) => {
                    setremarks(e.target.value);
                  }}
                ></textarea>
                <button
                  onClick={(e) => {
                    reject(
                      Records[0].Id,
                      "FinalInspection",
                      remarks,
                      "paint_final_inspection"
                    );
                    navigate(-1);
                  }}
                >
                  Submit
                </button>
                <button
                  onClick={() => {
                    setcardId("");
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="ApprovalRemarks">
          {/* {console.log(Approval[0])} */}
          {Approval?.[0]?.Status === "Rejected" ? (
            <div className="reject">
              <div style={{ fontSize: "16px", fontWeight: "600" }}>
                The Reason OF Rejection is -
                {Approval[0].ApprovalRemark === undefined ||
                Approval[0].ApprovalRemark === ""
                  ? " "
                  : Approval[0].ApprovalRemark}
              </div>
            </div>
          ) : Approval?.[0]?.Status === "Approved" ? (
            <div
              className="approve"
              style={{ fontSize: "16px", fontWeight: "600" }}
            >
              The Record is Approved
            </div>
          ) : (
            ""
          )}
        </div>
      )}

      {/* {Approver === localStorage.getItem("empId") && window.location.href.includes("view") ?

(Records[0].Approval_status=== "0" && window.location.href.includes("view")? <div><span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#DA251A', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>This Form has been Rejected due to{RejectedReason}</span></div> 
: Records[0].Approval_status=== "1" && window.location.href.includes("view")? <div><span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#B9A44C', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>It is Approved Already, Contact Administrator if any change needed </span></div> 
: Records[0].Approval_status === "" && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
<div style={{ display: 'flex', width: '25%', gap: '5', justifyContent: 'space-between', alignItems: 'center', marginRight: "10px" }}><button
className="icon"
style={{ backgroundColor: "#06802f", marginRight: '10px' }}
type="button"
onClick={(e) => {
approve(Records[0].Id, "FinalInspection", "paint_final_inspection");
navigate(-1);
}}
>
Approve
</button>
<button
className="icon"
style={{ backgroundColor: "#9d080f" }}
type="button"
onClick={() => { setcardId(id) }}
>
Reject
</button>
{id === cardId && <div><div style={{ backgroundColor: 'gray', filter: 'blur(8px)', position: 'absolute', opacity: '.8', right: '0%', bottom: '0%', width: '100%', height: '100%' }}></div><div style={{ position: 'absolute', right: '32%', color: 'white', bottom: '35%', padding: '20px', borderRadius: '10px' }}><h3>Remarks</h3><textarea rows={5} cols={50} onChange={e => { setremarks(e.target.value) }}></textarea>
<button onClick={e => { reject(Records[0].Id, "FinalInspection",remarks, "paint_final_inspection");
console.log(Records[0].Id, "FinalInspection",remarks, "paint_final_inspection");
navigate(-1)
}}>Submit</button><button onClick={() => { setcardId('') }}>Cancel</button></div></div>}
</div>
</div>) :""

} */}
      <div className="excel">
        {downloadEnabled && (
          <Dropdown style={{ width: "100%", al: "center" }}>
            <Dropdown.Toggle style={{ color: "black" }}>
              Download ⬇️
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>
                <button
                  className="custom-button"
                  style={{ color: "black" }}
                  onClick={generatePDF}
                >
                  PDF ⬇️
                </button>
              </Dropdown.Item>
              <Dropdown.Item>
                <button
                  className="custom-button"
                  style={{ color: "black" }}
                  onClick={() => exportToExcel()}
                >
                  Export to Excel
                </button>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>

      <div style={{ marginLeft: "3%", width: "93%" }} ref={scrollTop}>
        <div ref={contentRef}>
          <table>
            <thead>
              <tr>
                <th colSpan={10}>
                  <Lable value={"Final Inspection"} type={"Head"} />
                </th>
                <th colSpan={2} style={{ width: "20%" }}>
                  <div className="previewLabel">
                    <span className="previewLabelLeft">Format No:</span>{" "}
                    <span className="previewLabelRight">
                      {Records?.[0].Format_no || ""}
                    </span>
                    <span className="previewLabelLeft">Rev No:</span>{" "}
                    <span className="previewLabelRight">
                      {Records?.[0]?.Rev_no || ""}
                    </span>
                    <span className="previewLabelLeft">Rev Date:</span>{" "}
                    <span className="previewLabelRight">
                      {Records?.[0].Rev_ate || ""}
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tr>
              <td colSpan={1}>
                <div className="previewLabel">
                  <span className="previewLabelLeft">Date:</span>
                </div>
              </td>
              <td colSpan={2}>
                <div className="previewLabel">
                  <span className="previewLabelRight">
                    {Records?.[0].Entry_date || ""}
                  </span>
                </div>
              </td>
              <td colSpan={1}>
                <div className="previewLabel">
                  <span className="previewLabelLeft">Shift:</span>
                </div>
              </td>
              <td colSpan={1}>
                <div className="previewLabel">
                  <span className="previewLabelRight">
                    {Records?.[0]?.Shift || ""}
                  </span>
                </div>
              </td>
              <td colSpan={2}>
                <div className="previewLabel">
                  <span className="previewLabelLeft">StartTime:</span>
                </div>
              </td>
              <td colSpan={1}>
                <div className="previewLabel">
                  <span className="previewLabelRight">
                    {Records?.[0]?.S_time || ""}
                  </span>
                </div>
              </td>
              <td colSpan={2}>
                <div className="previewLabel">
                  <span className="previewLabelLeft">CloseTime:</span>
                </div>
              </td>
              <td colSpan={1}>
                <div className="previewLabel">
                  <span className="previewLabelRight">
                    {Records?.[0]?.C_time || ""}
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              {" "}
              <td colSpan={4}>
                <div className="previewLabel">
                  <span>Insp Name</span>
                </div>
              </td>{" "}
              <td colSpan={8}>
                <div className="previewLabel">
                  <span className="previewLabelRight">
                    {Records?.[0]?.InsName[0]
                      ? Records[0].InsName[0].Emp_name
                      : ""}
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={23}>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <div style={{ width: "100%", padding: "0px" }}>
                    <table>
                      <thead>
                        <tr>
                          <td
                            colSpan={6}
                            style={{ width: "20%", padding: "5px" }}
                          >
                            <label style={{ width: "100%", padding: "15px" }}>
                              Part Name
                            </label>
                          </td>
                          <td colSpan={4} style={{ width: "15%" }}>
                            <label>Part No</label>
                          </td>
                          <td colSpan={4} style={{ width: "8%" }}>
                            <label>Qty. Checked</label>
                          </td>
                          <td colSpan={1} style={{ width: "8%" }}>
                            <label>Qty. OK</label>
                          </td>
                          <td
                            colSpan={1}
                            style={{ transform: "rotate(-90deg)" }}
                          >
                            <label>DUST</label>
                          </td>
                          <td
                            colSpan={1}
                            style={{ transform: "rotate(-90deg)" }}
                          >
                            <label>LINT</label>
                          </td>
                          <td
                            colSpan={1}
                            style={{ transform: "rotate(-90deg)" }}
                          >
                            <label>COLOUR MISMATCH</label>
                          </td>
                          <td
                            colSpan={1}
                            style={{ transform: "rotate(-90deg)" }}
                          >
                            <label>DRYSPRAY</label>
                          </td>
                          <td
                            colSpan={1}
                            style={{ transform: "rotate(-90deg)" }}
                          >
                            <label>SAG</label>
                          </td>
                          <td
                            colSpan={1}
                            style={{ transform: "rotate(-90deg)" }}
                          >
                            <label>LESS COVER</label>
                          </td>
                          <td
                            colSpan={1}
                            style={{ transform: "rotate(-90deg)" }}
                          >
                            <label>TOUCH MARK</label>
                          </td>
                          <td
                            colSpan={1}
                            style={{ transform: "rotate(-90deg)" }}
                          >
                            <label>STRESS MARK</label>
                          </td>
                          <td
                            colSpan={1}
                            style={{ transform: "rotate(-90deg)" }}
                          >
                            <label> PIN HOLE</label>
                          </td>
                          <td
                            colSpan={1}
                            style={{ transform: "rotate(-90deg)" }}
                          >
                            <label>BUBBLES</label>
                          </td>
                          <td
                            colSpan={1}
                            style={{ transform: "rotate(-90deg)" }}
                          >
                            <label>BLISTER</label>
                          </td>
                          <td
                            colSpan={1}
                            style={{ transform: "rotate(-90deg)" }}
                          >
                            <label>OIL MARK</label>
                          </td>
                          <td
                            colSpan={1}
                            style={{ transform: "rotate(-90deg)" }}
                          >
                            <label>DAMAGE</label>
                          </td>
                          <td
                            colSpan={1}
                            style={{ transform: "rotate(-90deg)" }}
                          >
                            <label>DENT</label>
                          </td>
                          <td
                            colSpan={1}
                            style={{ transform: "rotate(-90deg)" }}
                          >
                            <label>FLASH</label>
                          </td>
                          <td
                            colSpan={1}
                            style={{ transform: "rotate(-90deg)" }}
                          >
                            <label>OTHERS</label>
                          </td>
                          <td colSpan={1} style={{ width: "10%" }}>
                            <label>Total Qty. Rejected</label>
                          </td>
                          <td colSpan={1} style={{ width: "15%" }}>
                            <label>Reason for </label>
                            <label>Other Rejections</label>
                          </td>
                          <td colSpan={1} style={{ width: "8%" }}>
                            <label>Remarks</label>
                            {/* <Lable value={"Remarks"}></Lable> */}
                          </td>
                        </tr>

                        {Array.from(
                          {
                            length:
                              Records?.[0]?.paint_final_inspection_bulk_report
                                .length,
                          },
                          (_, i) => i + 1
                        ).map((item) => (
                          <tr>
                            <td className="previewLabelValue" colSpan={6}>
                              <span>
                                {Records?.[0]
                                  ?.paint_final_inspection_bulk_report[
                                  Number(item) - 1
                                ].Part_name || ""}
                                {/* {Records?.[0]?.paint_final_inspection_bulk_report[Number(item) - 1].PartName[0]?.Part_name ||
    Records?.[0]?.paint_final_inspection_bulk_report[Number(item) - 1].PartName?.label ||
    Records?.[0]?.paint_final_inspection_bulk_report[Number(item) - 1].Part_name ||
    ""} */}
                              </span>
                            </td>
                            <td className="previewLabelValue" colSpan={4}>
                              <span>
                                {Records?.[0]
                                  ?.paint_final_inspection_bulk_report[
                                  Number(item) - 1
                                ].Part_no || ""}
                              </span>
                            </td>
                            <td className="previewLabelValue" colSpan={4}>
                              <span>
                                {Records[0].paint_final_inspection_bulk_report[
                                  Number(item) - 1
                                ].Qty_chk || ""}
                              </span>
                            </td>
                            <td className="previewLabelValue" colSpan={1}>
                              <span>
                                {Records[0].paint_final_inspection_bulk_report[
                                  Number(item) - 1
                                ].Qty_ok || ""}
                              </span>
                            </td>
                            <td className="previewLabelValue" colSpan={1}>
                              <span>
                                {Records[0].paint_final_inspection_bulk_report[
                                  Number(item) - 1
                                ].Dust
                                  ? "✔️"
                                  : "❌"}
                              </span>
                            </td>
                            <td className="previewLabelValue" colSpan={1}>
                              <span>
                                {Records[0].paint_final_inspection_bulk_report[
                                  Number(item) - 1
                                ].Lint
                                  ? "✔️"
                                  : "❌"}
                              </span>
                            </td>
                            <td className="previewLabelValue" colSpan={1}>
                              <span>
                                {Records[0].paint_final_inspection_bulk_report[
                                  Number(item) - 1
                                ].Col_mismatch
                                  ? "✔️"
                                  : "❌"}
                              </span>
                            </td>
                            <td className="previewLabelValue" colSpan={1}>
                              <span>
                                {Records[0].paint_final_inspection_bulk_report[
                                  Number(item) - 1
                                ].Dry_spray
                                  ? "✔️"
                                  : "❌"}
                              </span>
                            </td>
                            <td className="previewLabelValue" colSpan={1}>
                              <span>
                                {Records[0].paint_final_inspection_bulk_report[
                                  Number(item) - 1
                                ].Sag
                                  ? "✔️"
                                  : "❌"}
                              </span>
                            </td>
                            <td className="previewLabelValue" colSpan={1}>
                              <span>
                                {Records[0].paint_final_inspection_bulk_report[
                                  Number(item) - 1
                                ].Less_cover
                                  ? "✔️"
                                  : "❌"}
                              </span>
                            </td>
                            <td className="previewLabelValue" colSpan={1}>
                              <span>
                                {Records[0].paint_final_inspection_bulk_report[
                                  Number(item) - 1
                                ].Touch_mark
                                  ? "✔️"
                                  : "❌"}
                              </span>
                            </td>
                            <td className="previewLabelValue" colSpan={1}>
                              <span>
                                {Records[0].paint_final_inspection_bulk_report[
                                  Number(item) - 1
                                ].Stress_mark
                                  ? "✔️"
                                  : "❌"}
                              </span>
                            </td>
                            <td className="previewLabelValue" colSpan={1}>
                              <span>
                                {Records[0].paint_final_inspection_bulk_report[
                                  Number(item) - 1
                                ].Pin_hole
                                  ? "✔️"
                                  : "❌"}
                              </span>
                            </td>
                            <td className="previewLabelValue" colSpan={1}>
                              <span>
                                {Records[0].paint_final_inspection_bulk_report[
                                  Number(item) - 1
                                ].Bubbles
                                  ? "✔️"
                                  : "❌"}
                              </span>
                            </td>{" "}
                            <td className="previewLabelValue" colSpan={1}>
                              <span>
                                {Records[0].paint_final_inspection_bulk_report[
                                  Number(item) - 1
                                ].Blister
                                  ? "✔️"
                                  : "❌"}
                              </span>
                            </td>
                            <td className="previewLabelValue" colSpan={1}>
                              <span>
                                {Records[0].paint_final_inspection_bulk_report[
                                  Number(item) - 1
                                ].Oil_mark
                                  ? "✔️"
                                  : "❌"}
                              </span>
                            </td>{" "}
                            <td className="previewLabelValue" colSpan={1}>
                              <span>
                                {Records[0].paint_final_inspection_bulk_report[
                                  Number(item) - 1
                                ].Damage
                                  ? "✔️"
                                  : "❌"}
                              </span>
                            </td>{" "}
                            <td className="previewLabelValue" colSpan={1}>
                              <span>
                                {Records[0].paint_final_inspection_bulk_report[
                                  Number(item) - 1
                                ].Dent
                                  ? "✔️"
                                  : "❌"}
                              </span>
                            </td>
                            <td className="previewLabelValue" colSpan={1}>
                              <span style={{ color: "green" }}>
                                {Records[0].paint_final_inspection_bulk_report[
                                  Number(item) - 1
                                ].Flash
                                  ? "✔️"
                                  : "❌"}
                              </span>
                            </td>
                            <td className="previewLabelValue" colSpan={1}>
                              <span style={{ color: "green" }}>
                                {Records[0].paint_final_inspection_bulk_report[
                                  Number(item) - 1
                                ].Others
                                  ? "✔️"
                                  : "❌"}
                              </span>
                            </td>
                            <td className="previewLabelValue" colSpan={1}>
                              <span>
                                {Records[0].paint_final_inspection_bulk_report[
                                  Number(item) - 1
                                ].Total_qty_rej || ""}
                              </span>
                            </td>
                            <td className="previewLabelValue" colSpan={1}>
                              <span>
                                {Records[0].paint_final_inspection_bulk_report[
                                  Number(item) - 1
                                ].Reason_for_rej || ""}
                              </span>
                            </td>
                            <td className="previewLabelValue" colSpan={2}>
                              <span>
                                {
                                  Records[0].paint_final_inspection_bulk_report[
                                    Number(item) - 1
                                  ].Remarks
                                }
                              </span>
                            </td>
                          </tr>
                        ))}
                      </thead>
                    </table>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <th colSpan={2} style={{ padding: "0px", width: "11%" }}>
                <Lable
                  value={"Part Name"}
                  type={"Head"}
                  style={{ padding: "1px" }}
                />
              </th>
              <th colSpan={1} style={{ padding: "0px", width: "13%" }}>
                <Lable
                  value={"Total Checked Qty."}
                  type={"Head"}
                  style={{ padding: "1px" }}
                />
              </th>
              <th colSpan={1} style={{ padding: "0px" }}>
                <Lable
                  value={"Total Ok Qty"}
                  type={"Head"}
                  style={{ padding: "1px" }}
                />
              </th>
              <th colSpan={1} style={{ padding: "0px" }}>
                <Lable
                  value={"Total Reject"}
                  type={"Head"}
                  style={{ padding: "1px" }}
                />
              </th>
              <th colSpan={1} style={{ width: "10%" }}>
                <Lable
                  value={"REJ%"}
                  type={"Head"}
                  style={{ padding: "1px" }}
                />
              </th>
              <th colSpan={2} style={{ width: "10%" }}>
                <Lable
                  value={"YEILD"}
                  type={"Head"}
                  style={{ padding: "1px" }}
                />
              </th>
              <th colSpan={4 } style={{ width: "10%" }} />
              {/* <th colSpan={2} style={{ width: "10%" }} /> */}
            </tr>

            {Array.from(
              { length: Math.max(3, Object.keys(totalFreshParts).length) },
              (_, index) => (
                <tr key={index}>
                  <td className="previewLabelValue">
                    {totalFreshParts[Object.keys(totalFreshParts)[index]]
                      ?.part || ""}
                  </td>
                  <td colSpan={2} className="previewLabelValue">
                    {totalFreshParts[Object.keys(totalFreshParts)[index]]
                      ?.QtyC || ""}
                  </td>
                  <td colSpan={1} className="previewLabelValue">
                    {totalFreshParts[Object.keys(totalFreshParts)[index]]
                      ?.QtyO || ""}
                  </td>
                  <td colSpan={1} className="previewLabelValue">
                    {totalFreshParts[Object.keys(totalFreshParts)[index]]
                      ?.TotalR || ""}
                  </td>
                  <td colSpan={1} className="previewLabelValue">
                    {totalFreshParts[Object.keys(totalFreshParts)[index]]
                      ?.TotalRPer || ""}
                    <span>%</span>
                  </td>
                  <td colSpan={2} className="previewLabelValue">
                    {totalFreshParts[Object.keys(totalFreshParts)[index]]
                      ?.Yeild || ""}
                  </td>
                  <td colSpan={2}>
                    {index < paintNames.length && paintNames[index]
                      ? paintNames[index].key
                      : ""}
                  </td>
                  <td className="previewLabelValue" colSpan={2}>
                    {index < paintNames.length && paintNames[index]
                      ? paintNames[index].value
                      : ""}
                  </td>
                </tr>
              )
            )}

            {/* <tr>
            <td colSpan={3}>
              <div className="previewLabel">
                <span className="previewLabelLeftt">Inspector Sign:</span>
              </div>

              <div className="previewLabel">
                <span className="previewLabelRight">
                  {Records?.[0]?.InspectorS?.[0]?.Emp_name || ""}
                </span>
              </div>
            </td>
            <td colSpan={4}>
              <div className="previewLabel">
                <span className="previewLabelLeftt">Shift Incharge Sign:</span>
              </div>

              <div className="previewLabel">
                <span className="previewLabelRight">
                  {Records?.[0]?.Shiftincharge[0].Emp_name||""}
                </span>
              </div>
            </td>
            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabeLeftt">Approved By:</span>
              </div>

              <div className="previewLabel">
                <span className="previewLabelRight">
                  {Records?.[0]?.ApprovedS[0].Emp_name||""}
                </span>
              </div>
            </td>
            
          </tr> */}

            {!window.location.href.includes("view") && (
              <tr>
                {window.location.href.includes("edit") ? (
                  <td colSpan={12}>
                    {" "}
                    <Field
                      className={"submit"}
                      label={"Update"}
                      options={{ type: "Button" }}
                      onClick={(e) => {
                        HandleUpdate(e);
                      }}
                    />
                  </td>
                ) : (
                  <td colSpan={12}>
                    {" "}
                    <Field
                      className={"submit"}
                      label={"Submit"}
                      options={{ type: "Button" }}
                      onClick={(e) => {
                        HandleCreate(e);
                      }}
                    />
                  </td>
                )}
              </tr>
            )}
            <br />
            <br />
            <br />
            <br />
            <br />
          </table>
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        {/* ExcelTable Design*/}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div ref={contentRef} id="content-to-pdf">
          <style>
            {`table {
  border-collapse: collapse;
  border: 1px solid;
  width:100%;
  margin: 0 auto;
}
.myTable {

  width:100%;
  display:none;
}

.vertical-text{
  border: none;
}
th, td ,tr{
  border: 1px solid;
 
}
tr.no-border td {
  border: none;

}`}
          </style>
          {DownloadDiv && (
            <table id="ExcelTable" className="myTable">
              {exc ? (
                <thead>
                  <tr>
                    <td    colSpan="4" rowSpan={3}></td>
                    <td
                      colSpan="16"
                      rowSpan={3}
                      style={{
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                        fontSize: "20px",
                      }}
                    >
                      <font>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; FINAL
                        INSPECTION
                      </font>
                    </td>

                    <td colSpan={3} style={{ width: "20%" }}>
                      Format No: &nbsp;&nbsp; {Records[0].Format_no}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3} style={{ width: "20%" }}>
                      Rev No: &nbsp;&nbsp; {Records[0].Rev_no}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3} style={{ width: "20%" }}>
                      Rev Date: &nbsp;&nbsp; {Records[0].Rev_ate}
                    </td>
                  </tr>
                </thead>
              ) : (
                <thead>
                  <tr>
                    <td colspan="20">
                      <div>
                        <label
                          style={{
                            fontSize: "24",
                            textAlignL: "center",
                            whiteSpace: "nowrap",
                            padding: "5px 0px",
                          }}
                          
                        >
                          FINAL INSPECTION
                        </label>
                      </div>
                    </td>
                    <td colSpan={4}>
                      <div className="previewLabel">
                        <span className="previewLabelLeft">Format No:</span>{" "}
                        <span className="previewLabelRight">
                          {Records?.[0].Format_no || ""}
                        </span>
                        <span className="previewLabelLeft">Rev No:</span>{" "}
                        <span className="previewLabelRight">
                          {Records?.[0]?.Rev_no || ""}
                        </span>
                        <span className="previewLabelLeft">Rev Date:</span>{" "}
                        <span className="previewLabelRight">
                          {Records?.[0].Rev_ate || ""}
                        </span>
                      </div>
                    </td>
                  </tr>
                </thead>
              )}

              <tr>
                <td colSpan={4}>
                  <div className="previewLabel">
                    <span className="previewLabelLeft">Date:</span>
                  </div>
                </td>
                <td colSpan={6}>
                  <div className="previewLabel">
                    <span className="previewLabelRight">
                      {Records?.[0].Entry_date || ""}
                    </span>
                  </div>
                </td>
                <td colSpan={6}>
                  <div className="previewLabel">
                    <span className="previewLabelLeft">Shift:</span>
                  </div>
                </td>
                <td colSpan={7}>
                  <div className="previewLabel">
                    <span className="previewLabelRight">
                      {Records?.[0]?.Shift || ""}
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                {" "}
                <td colSpan={4}>
                  <div className="previewLabel">
                    <span>Insp Name</span>
                  </div>
                </td>{" "}
                <td colSpan={19}>
                  <div className="previewLabel">
                    <span className="previewLabelRight">
                      {Records?.[0]?.InsName[0]
                        ? Records[0].InsName[0].Emp_name
                        : ""}
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={1} style={{ width: "25%", padding: "5px" }}>
                  <label style={{ width: "100%", padding: "15px" }}>
                    Part Name
                  </label>
                </td>
                <td colSpan={1} style={{ width: "20%" }}>
                  <label>Part No</label>
                </td>
                <td colSpan={1} style={{ width: "8%" }}>
                  <label>Qty. Checked</label>
                </td>
                <td colSpan={1} style={{ width: "8%" }}>
                  <label>Qty. OK</label>
                </td>
                <td colSpan={1} rowSpan={1} style={{ position: "relative" }}>
                  <div
                    style={{
                      transform: "rotate(90deg)",
                      transformOrigin: "left top",
                      marginLeft: "70%",
                      marginTop: "16%",
                      fontSize: "12px",
                      padding: "1px",
                      paddingBottom: "20px",
                      zIndex: 2,
                    }}
                  >
                    DUST
                  </div>
                </td>

                <td colSpan={1} rowSpan={1} style={{ position: "relative" }}>
                  <div
                    style={{
                      transform: "rotate(90deg)",
                      transformOrigin: "left top",
                      marginLeft: "72%",
                      marginTop: "16%",
                      fontSize: "12px",
                      padding: "1px",
                      paddingBottom: "20px",
                      zIndex: 2,
                    }}
                  >
                    LINT
                  </div>
                </td>
                <td colSpan={1} rowSpan={1} style={{ position: "relative" }}>
                  <div
                    style={{
                      transform: "rotate(90deg)",
                      transformOrigin: "left top",
                      marginLeft: "75%",
                      fontSize: "12px",
                      padding: "1px",
                      paddingBottom: "2px",
                      zIndex: 2,
                    }}
                  >
                    Colour Mismatch
                  </div>
                </td>
                <td colSpan={1} rowSpan={1} style={{ position: "relative" }}>
                  <div
                    style={{
                      transform: "rotate(90deg)",
                      transformOrigin: "left top",
                      marginLeft: "115%",
                      fontSize: "12px",
                      paddingBottom: "2px",
                      padding: "1px",
                      zIndex: 2,
                    }}
                  >
                    DRY SPRAY
                  </div>
                </td>

                <td colSpan={1} rowSpan={1} style={{ position: "relative" }}>
                  <div
                    style={{
                      transform: "rotate(90deg)",
                      transformOrigin: "left top",
                      marginLeft: "75%",
                      marginTop: "16%",
                      fontSize: "12px",
                      padding: "1px",
                      paddingBottom: "20px",
                      zIndex: 2,
                    }}
                  >
                    SAG
                  </div>
                </td>
                <td colSpan={1} rowSpan={1} style={{ position: "relative" }}>
                  <div
                    style={{
                      transform: "rotate(90deg)",
                      transformOrigin: "left top",
                      marginLeft: "99%",
                      marginTop: "16%",
                      fontSize: "12px",
                      padding: "1px",
                      paddingBottom: "2px",
                      zIndex: 2,
                    }}
                  >
                    LESS COVER
                  </div>
                </td>
                <td colSpan={1} rowSpan={1} style={{ position: "relative" }}>
                  <div
                    style={{
                      transform: "rotate(90deg)",
                      transformOrigin: "left top",
                      marginLeft: "99%",
                      marginTop: "16%",
                      fontSize: "12px",
                      paddingBottom: "2px",
                      padding: "1px",
                      zIndex: 2,
                    }}
                  >
                    TOUCH MARK
                  </div>
                </td>
                <td colSpan={1} rowSpan={1} style={{ position: "relative" }}>
                  <div
                    style={{
                      transform: "rotate(90deg)",
                      transformOrigin: "left top",
                      marginLeft: "99%",
                      marginTop: "16%",
                      fontSize: "12px",
                      paddingBottom: "2px",
                      padding: "1px",
                      zIndex: 2,
                    }}
                  >
                    STRESS MARK
                  </div>
                </td>
                <td colSpan={1} rowSpan={1} style={{ position: "relative" }}>
                  <div
                    style={{
                      transform: "rotate(90deg)",
                      transformOrigin: "left top",
                      marginLeft: "120%",
                      marginTop: "16%",
                      fontSize: "12px",
                      paddingBottom: "2px",
                      padding: "1px",
                      zIndex: 2,
                    }}
                  >
                    PIN HOLE
                  </div>
                </td>
                <td colSpan={1} rowSpan={1} style={{ position: "relative" }}>
                  <div
                    style={{
                      transform: "rotate(90deg)",
                      transformOrigin: "left top",
                      marginLeft: "90%",
                      fontSize: "12px",
                      padding: "1px",
                      paddingBottom: "20px",
                      zIndex: 2,
                    }}
                  >
                    {" "}
                    BUBBLES{" "}
                  </div>
                </td>
                <td colSpan={1} rowSpan={1} style={{ position: "relative" }}>
                  <div
                    style={{
                      transform: "rotate(90deg)",
                      transformOrigin: "left top",
                      marginLeft: "80%",
                      fontSize: "12px",
                      padding: "1px",
                      zIndex: 2,
                      paddingBottom: "20px",
                    }}
                  >
                    BLISTER
                  </div>
                </td>
                <td colSpan={1} rowSpan={1} style={{ position: "relative" }}>
                  <div
                    style={{
                      transform: "rotate(90deg)",
                      transformOrigin: "left top",
                      marginLeft: "100%",
                      fontSize: "12px",
                      padding: "1px",
                      zIndex: 2,
                      paddingBottom: "20px",
                    }}
                  >
                    OILMARK
                  </div>
                </td>
                <td colSpan={1} rowSpan={1} style={{ position: "relative" }}>
                  <div
                    style={{
                      transform: "rotate(90deg)",
                      transformOrigin: "left top",
                      marginLeft: "50%",
                      fontSize: "12px",
                      padding: "1px",
                      paddingBottom: "20px",
                      zIndex: 2,
                    }}
                  >
                    DAMAGE
                  </div>
                </td>
                <td colSpan={1} rowSpan={1} style={{ position: "relative" }}>
                  <div
                    style={{
                      transform: "rotate(90deg)",
                      transformOrigin: "left top",
                      marginLeft: "60%",
                      fontSize: "12px",
                      padding: "1px",
                      paddingBottom: "20px",
                      zIndex: 2,
                    }}
                  >
                    DENT
                  </div>
                </td>
                <td colSpan={1} rowSpan={1} style={{ position: "relative" }}>
                  <div
                    style={{
                      transform: "rotate(90deg)",
                      transformOrigin: "left top",
                      marginLeft: "60%",
                      paddingBottom: "20px",
                      fontSize: "12px",
                      padding: "1px",
                      zIndex: 2,
                    }}
                  >
                    FLASH
                  </div>
                </td>
                <td colSpan={1} rowSpan={1} style={{ position: "relative" }}>
                  <div
                    style={{
                      transform: "rotate(90deg)",
                      transformOrigin: "left top",
                      marginLeft: "50%",
                      paddingBottom: "20px",
                      fontSize: "12px",
                      padding: "1px",
                      zIndex: 2,
                    }}
                  >
                    OTHERS
                  </div>
                </td>
                <td colSpan={1} style={{ width: "10%" }}>
                  <label>Total Qty. Rejected</label>
                </td>
                <td colSpan={1} style={{ width: "15%" }}>
                  <label>Reason for </label>
                  <label>Other Rejections</label>
                </td>
                <td colSpan={3} style={{ width: "8%" }}>
                  <label>Remarks</label>
                </td>
              </tr>
              {Array.from(
                {
                  length:
                    Records?.[0]?.paint_final_inspection_bulk_report.length,
                },
                (_, i) => i + 1
              ).map((item) => (
                <tr>
                  <td className="previewLabelValue" colSpan={1}>
                    <span>
                      {Records?.[0]?.paint_final_inspection_bulk_report[
                        Number(item) - 1
                      ].Part_name || ""}
                    </span>
                  </td>
                  <td className="previewLabelValue" colSpan={1}>
                    <span>
                      {Records?.[0]?.paint_final_inspection_bulk_report[
                        Number(item) - 1
                      ].Part_no || ""}
                    </span>
                  </td>
                  <td className="previewLabelValue" colSpan={1}>
                    <span>
                      {Records[0].paint_final_inspection_bulk_report[
                        Number(item) - 1
                      ].Qty_chk || ""}
                    </span>
                  </td>
                  <td className="previewLabelValue" colSpan={1}>
                    <span>
                      {Records[0].paint_final_inspection_bulk_report[
                        Number(item) - 1
                      ].Qty_ok || ""}
                    </span>
                  </td>
                  <td className="previewLabelValue" colSpan={1}>
                    <span>
                      {Records[0].paint_final_inspection_bulk_report[
                        Number(item) - 1
                      ].Dust
                        ? "✔️"
                        : "❌"}
                    </span>
                  </td>
                  <td className="previewLabelValue" colSpan={1}>
                    <span>
                      {Records[0].paint_final_inspection_bulk_report[
                        Number(item) - 1
                      ].Lint
                        ? "✔️"
                        : "❌"}
                    </span>
                  </td>
                  <td className="previewLabelValue" colSpan={1}>
                    <span>
                      {Records[0].paint_final_inspection_bulk_report[
                        Number(item) - 1
                      ].Col_mismatch
                        ? "✔️"
                        : "❌"}
                    </span>
                  </td>
                  <td className="previewLabelValue" colSpan={1}>
                    <span>
                      {Records[0].paint_final_inspection_bulk_report[
                        Number(item) - 1
                      ].Dry_spray
                        ? "✔️"
                        : "❌"}
                    </span>
                  </td>
                  <td className="previewLabelValue" colSpan={1}>
                    <span>
                      {Records[0].paint_final_inspection_bulk_report[
                        Number(item) - 1
                      ].Sag
                        ? "✔️"
                        : "❌"}
                    </span>
                  </td>
                  <td className="previewLabelValue" colSpan={1}>
                    <span>
                      {Records[0].paint_final_inspection_bulk_report[
                        Number(item) - 1
                      ].Less_cover
                        ? "✔️"
                        : "❌"}
                    </span>
                  </td>
                  <td className="previewLabelValue" colSpan={1}>
                    <span>
                      {Records[0].paint_final_inspection_bulk_report[
                        Number(item) - 1
                      ].Touch_mark
                        ? "✔️"
                        : "❌"}
                    </span>
                  </td>
                  <td className="previewLabelValue" colSpan={1}>
                    <span>
                      {Records[0].paint_final_inspection_bulk_report[
                        Number(item) - 1
                      ].Stress_mark
                        ? "✔️"
                        : "❌"}
                    </span>
                  </td>
                  <td className="previewLabelValue" colSpan={1}>
                    <span>
                      {Records[0].paint_final_inspection_bulk_report[
                        Number(item) - 1
                      ].Pin_hole
                        ? "✔️"
                        : "❌"}
                    </span>
                  </td>
                  <td className="previewLabelValue" colSpan={1}>
                    <span>
                      {Records[0].paint_final_inspection_bulk_report[
                        Number(item) - 1
                      ].Bubbles
                        ? "✔️"
                        : "❌"}
                    </span>
                  </td>{" "}
                  <td className="previewLabelValue" colSpan={1}>
                    <span>
                      {Records[0].paint_final_inspection_bulk_report[
                        Number(item) - 1
                      ].Blister
                        ? "✔️"
                        : "❌"}
                    </span>
                  </td>
                  <td className="previewLabelValue" colSpan={1}>
                    <span>
                      {Records[0].paint_final_inspection_bulk_report[
                        Number(item) - 1
                      ].Oil_mark
                        ? "✔️"
                        : "❌"}
                    </span>
                  </td>{" "}
                  <td className="previewLabelValue" colSpan={1}>
                    <span>
                      {Records[0].paint_final_inspection_bulk_report[
                        Number(item) - 1
                      ].Damage
                        ? "✔️"
                        : "❌"}
                    </span>
                  </td>{" "}
                  <td className="previewLabelValue" colSpan={1}>
                    <span>
                      {Records[0].paint_final_inspection_bulk_report[
                        Number(item) - 1
                      ].Dent
                        ? "✔️"
                        : "❌"}
                    </span>
                  </td>
                  <td className="previewLabelValue" colSpan={1}>
                    <span style={{ color: "green" }}>
                      {Records[0].paint_final_inspection_bulk_report[
                        Number(item) - 1
                      ].Flash
                        ? "✔️"
                        : "❌"}
                    </span>
                  </td>
                  <td className="previewLabelValue" colSpan={1}>
                    <span style={{ color: "green" }}>
                      {Records[0].paint_final_inspection_bulk_report[
                        Number(item) - 1
                      ].Others
                        ? "✔️"
                        : "❌"}
                    </span>
                  </td>
                  <td className="previewLabelValue" colSpan={1}>
                    <span>
                      {Records[0].paint_final_inspection_bulk_report[
                        Number(item) - 1
                      ].Total_qty_rej || ""}
                    </span>
                  </td>
                  <td className="previewLabelValue" colSpan={1}>
                    <span>
                      {Records[0].paint_final_inspection_bulk_report[
                        Number(item) - 1
                      ].Reason_for_rej || ""}
                    </span>
                  </td>
                  <td className="previewLabelValue" colSpan={3}>
                    <span>
                      {
                        Records[0].paint_final_inspection_bulk_report[
                          Number(item) - 1
                        ].Remarks
                      }
                    </span>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan={4} className="previewLabelRight">
                  <label>Part Name</label>
                </td>
                <td colSpan={4} className="previewLabelRight">
                  <label>Total Checked Qty.</label>
                </td>
                <td colSpan={4} className="previewLabelRight">
                  <label>Total Ok Qty.</label>
                </td>
                <td colSpan={2} className="previewLabelRight">
                  <label>Total Reject.</label>
                </td>
                <td colSpan={2} className="previewLabelRight">
                  <label>REJ%</label>
                </td>
                <td colSpan={3} className="previewLabelRight">
                  <label>YEILD</label>
                </td>
                <td colSpan={6} />
                {/* <td colSpan={2} /> */}
              </tr>
              {Array.from(
                { length: Math.max(3, Object.keys(totalFreshParts).length) },
                (_, index) => (
                  <tr key={index}>
                    <td className="previewLabelValue" colSpan={4}>
                      {totalFreshParts[Object.keys(totalFreshParts)[index]]
                        ?.part || ""}
                    </td>
                    <td colSpan={4} className="previewLabelValue">
                      {totalFreshParts[Object.keys(totalFreshParts)[index]]
                        ?.QtyC || ""}
                    </td>
                    <td colSpan={4} className="previewLabelValue">
                      {totalFreshParts[Object.keys(totalFreshParts)[index]]
                        ?.QtyO || ""}
                    </td>
                    <td colSpan={2} className="previewLabelValue">
                      {totalFreshParts[Object.keys(totalFreshParts)[index]]
                        ?.TotalR || ""}
                    </td>
                    <td colSpan={2} className="previewLabelValue">
                      {totalFreshParts[Object.keys(totalFreshParts)[index]]
                        ?.TotalRPer || ""}
                      <span>%</span>
                    </td>
                    <td colSpan={3} className="previewLabelValue">
                      {totalFreshParts[Object.keys(totalFreshParts)[index]]
                        ?.Yeild || ""}
                    </td>
                    <td colSpan={2} style={{padding:"5px"}}>
                      {index < paintNames.length && paintNames[index]
                        ? paintNames[index].key
                        : ""}
                    </td>
                    <td className="previewLabelValue" colSpan={4}>
                      {index < paintNames.length && paintNames[index]
                        ? paintNames[index].value
                        : ""}
                    </td>
                  </tr>
                )
              )}
            </table>
          )}
        </div>
      </div>

      {/* </QaLayout> */}
    </div>
  );
};
export default PreViewPage;
