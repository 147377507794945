import { useState } from "react";
import ReadRecord from "./API/ReadRecord";

const IsDuplicate = async (TableName, fields) => {
  let Quary = `select `;
  fields.map((item) => {
    Quary += item.split("=")[0] + ",";
  });
  Quary =
    Quary.substring(0, Quary.length - 1) + " from " + TableName + " where ";
  fields.map((item) => {
    Quary += item + " and ";
  });
  if (TableName.toLowerCase().startsWith("paint")) {
    Quary += "Delete_id = 0 and ";
  }

  Quary = Quary.substring(0, Quary.length - 4);
  const res = await ReadRecord(Quary);
  console.log("q",Quary);
  return res.data[0].count > 0;
};

export default IsDuplicate;
