import React,{useState,useEffect} from "react";
import QaLayout from "../../Quality/QaLayout";
import ListViewCommon from "../../../Components/ListView/ListViewCommon";
import ListView from "../../../Components/ListView/ListView";

const RmMasterListView = () => {
  return (
 
    <div>
      <ListViewCommon
        Title={"RM Master List"}
        standard={"ActiveStatus = 'Active'"}
        // quary={"SELECT ins.id,ins.machine_no,prt.mc_part_name Part_name,ins.raw_material_name,emp.emp_name Prodcution_operator_name,ins.ActiveStatus FROM upd_data_model.rm_details ins left join upd_data_model.machine_part_details prt on ins.part_name=prt.mc_part_code left join upd_data_model.emp_details emp on ins.prodcution_operator_name=emp.emp_code where ins.ActiveStatus='1'"}
        quary={"SELECT rm_code AS Id,rm_code,rm_description,rm_grade,rm_Colour FROM rm_details group by rm_code,rm_description,rm_grade,rm_Colour"}
        table={"rm_details"}
        tableDelete={"rm_details_update"}
        navigation={"RMMaster"}
        filter={["rm_code","rm_description","rm_grade"]}
        // dateFilter={"CreatedDate"}
        header={[
        //   { value: "Id", label: "ID" },
          { value: "rm_code", label: "RM Code" },
          { value: "rm_description", label: "RM Description" },
          { value: "rm_grade", label: "RM Grade" },
          { value: "rm_Colour", label: "RM Colour" },
        //   { value: "rm_name", label: "Raw Name" },
          // { value: "Production_operator_name", label: "Prodcution Operator" },
        ]}
      />
    </div>

  );
};

export default RmMasterListView;
