//external import
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

//internal import
import Field from "../../../Components/Fields/Field";
import Lable from "../../../Components/Fields/Lable";
import QaLayout from "../../Quality/QaLayout";

//css import
import "./NewSmpMain.css";
//API imports
const BackPres = ({ Records, setRecords, setActiveKey, activeKey }) => {
  const { option, id } = useParams();
  const [ctrlPressed, setCtrlPressed] = useState(false);
  function InvalidCharacters(value) {
    return /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/.test(value);
  }
  useEffect(() => {
    if (activeKey === "four") {
        document.getElementById("1-Back_press_pressure").focus();
      }
    const handleKeyDown = (event) => {
      let activeField = document.activeElement;
      const fieldOptions = {
        Back_press_time: ["", ""],
        Back_press_position: ["Back_press_pressure", ""],
        Back_press_speed: ["", ""],
        Back_press_pressure: ["", "Back_press_position"],
      };

      let row = Records[0].backpres_function.length - 5;
      let currentField = activeField.id.split("-")[1];
      let indexOfField = activeField.id.split("-")[0];
      let nextField = "";

      if (currentField != undefined) {
        switch (event.ctrlKey + "+" + event.key + "+" + activeKey) {
          case "false+ArrowRight+four":
            nextField = fieldOptions[currentField][1];
            if (indexOfField > 0 && indexOfField < row+1) {
              if (nextField != "") {
                document.getElementById(indexOfField + "-" + nextField).focus();
              } else  {
                if (indexOfField == row) {
                    document.getElementById(row + 1 + "-Back_press_position").focus();
                  } else {
                    document
                      .getElementById(
                        Number(indexOfField) + 1 + "-Back_press_pressure"
                      )
                      .focus();
                  } 
              }
            }else {
                if (indexOfField > row && indexOfField< 6) {
                  document
                    .getElementById(Number(indexOfField) + 1 + "-Back_press_time")
                    .focus();
                }
              }
            break;

          case "false+ArrowLeft+four":
            nextField = fieldOptions[currentField][0];
            if (
              indexOfField > 0 &&
              indexOfField < row + 1 &&
              activeField.id != "1-Back_press_pressure"
            ) {
              if (nextField != "") {
                document.getElementById(indexOfField + "-" + nextField).focus();
              } else {
                document
                  .getElementById(indexOfField - 1 + "-Back_press_position")
                  .focus();
              }
            }if (indexOfField == row + 1) {
                document
                  .getElementById(indexOfField - 1 + "-Back_press_position")
                  .focus();
              } else if (indexOfField == row + 2) {
                document
                  .getElementById(indexOfField - 1 + "-Back_press_position")
                  .focus();
              }else if (indexOfField > row + 2) {
                document
                  .getElementById(indexOfField - 1 + "-Back_press_time")
                  .focus();
              }
            break;

          case "false+ArrowDown+four":
            // console.log("indexOfField",indexOfField,row,indexOfField == row);
            if (indexOfField < row) {
              document
                .getElementById(Number(indexOfField) + 1 + "-" + currentField)
                .focus();
            }else if (indexOfField == row) {
                document
                .getElementById(Number(indexOfField) + 1 + "-Back_press_position")
                .focus();
            }else if (indexOfField > row &&indexOfField < 6 ) {
                document
                .getElementById(Number(indexOfField) + 1 + "-Back_press_time")
                .focus();
            }
            break;

          case "false+ArrowUp+four":
            if (indexOfField > 1 && indexOfField < row+1 ) {
              document
                .getElementById(Number(indexOfField) - 1 + "-" + currentField)
                .focus();
            }else if(indexOfField > row&& indexOfField <= row+2){
                document
                .getElementById(Number(indexOfField) - 1 + "-Back_press_position")
                .focus();
            }else if(indexOfField > row+2){
                document
                .getElementById(Number(indexOfField) - 1 + "-Back_press_time")
                .focus();
            }
            break;

          case "true+ArrowRight+four":
            setActiveKey("five");
            break;

          default:
            break;
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setActiveKey, activeKey]);
  function HandleBackPres(Event, index) {
    setRecords((item) => {
      let record = [...Records];
      record[0].backpres_function[index] = {
        ...record[0].backpres_function[index],
        [Event.target.name]: Event.target.value,
      };
      return record;
    });
  }
  const DropdownValues = [
    { value: "", label: "Select" },
    { value: "1", label: "±1" },
    { value: "2", label: "±2" },
    { value: "3", label: "±3" },
    { value: "4", label: "±4" },
    { value: "5", label: "±5" },
    { value: "6", label: "±6" },
    { value: "7", label: "±7" },
    { value: "8", label: "±8" },
    { value: "9", label: "±9" },
    { value: "10", label: "±10" },
    { value: "11", label: "±11" },
    { value: "12", label: "±12" },
    { value: "13", label: "±13" },
    { value: "14", label: "±14" },
    { value: "15", label: "±15" },
  ];
  const DropdownValuesTime = [
    { value: "", label: "Select" },
    { value: "0.1", label: "±0.1" },
    { value: "0.2", label: "±0.2" },
    { value: "0.3", label: "±0.3" },
    { value: "0.4", label: "±0.4" },
    { value: "0.5", label: "±0.5" },
    { value: "0.6", label: "±0.6" },
    { value: "0.7", label: "±0.7" },
    { value: "0.8", label: "±0.8" },
    { value: "0.9", label: "±0.9" },
    { value: "1", label: "±1" },
    { value: "2", label: "±2" },
    { value: "3", label: "±3" },
    { value: "4", label: "±4" },
    { value: "5", label: "±5" },
  ];
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th colSpan={5}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "90% 10%",
                  alignItems: "center",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <Lable
                    value={"Back Press Settings"}
                    type={"Head"}
                    style={{ padding: "1px" }}
                  />
                </div>
                <div>
                  <button
                    className="nextButtonLabel"
                    onClick={(e) => {
                      setActiveKey("five");
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Lable
                value={Records[0].backpres_function[0].Back_press_stages}
                style={{ textAlign: "center", padding: "0px" }}
              />
            </td>
            <td colSpan={4}>
              <Lable
                value={"PRESSURE*(BAR)"}
                style={{ textAlign: "center", padding: "0px" }}
              />

              <Field
                label={""}
                name={"Back_press_pressure"}
                className={"fieldSingleGrid"}
                options={{
                  type: "dropdown",
                  disabled: option == "view",
                  values: DropdownValues,
                }}
                value={Records[0].backpres_function[0]}
                OnChange={(e) => {
                  HandleBackPres(e, 0);
                }}
              />
            </td>
            {/* <td>
                            <Lable value={"SPEED% *"} style={{ textAlign: 'center', padding: "0px" }} />
                            <Field
                                label={""}
                                name={"Back_press_speed"}
                                className={"fieldSingleGrid"}
                                options={{
                                    type: "dropdown",
                                    disabled: option == "view",
                                    values: DropdownValues
                                }}
                                value={Records[0].backpres_function[0]}
                                OnChange={(e) => {
                                    HandleBackPres(e, 0);
                                }}
                            />
                        </td>
                        <td>
                            <Lable value={"POSITION(MM)⟡"} style={{ textAlign: 'center', padding: "0px" }} />
                            <Field
                                label={""}
                                name={"Back_press_position"}
                                className={"fieldSingleGrid"}
                                options={{
                                    type: "dropdown",
                                    disabled: option == "view",
                                    values: DropdownValues
                                }}
                                value={Records[0].backpres_function[0]}
                                OnChange={(e) => {
                                    HandleBackPres(e, 0);
                                }}
                            />
                        </td>
                        <td>
                            <Lable value={"TIME(SEC) *"} style={{ textAlign: 'center', padding: "0px" }} />
                            <Field
                                label={""}
                                name={"Back_press_time"}
                                className={"fieldSingleGrid"}
                                options={{
                                    type: "dropdown",
                                    disabled: option == "view",
                                    values: DropdownValuesTime
                                }}
                                value={Records[0].backpres_function[0]}
                                OnChange={(e) => {
                                    HandleBackPres(e, 0);
                                }}
                            />
                        </td> */}
          </tr>
          {Records[0].backpres_function.map((item, index) => {
            if (index != 0 && index < 3)
              return (
                <tr key={index}>
                  <td>
                    <Lable
                    
                      value={item.Back_press_stages}
                      style={{ textAlign: "center", padding: "0px" }}
                    />
                  </td>
                  <td>
                    <Field
                      label={""}
                      id={index}
                      name={"Back_press_pressure"}
                      className={"fieldSingleGrid"}
                      options={{
                        type: "text",
                        disabled: option == "view",
                      }}
                      value={item}
                      OnChange={(e) => {
                        let value = e.target.value;
                        if (InvalidCharacters(value)) {
                          value = value.replace(/[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g, '');
                          e.target.value = value;
                        }
                        HandleBackPres(e, index);
                      }}
                    />
                  </td>
                  {/* <td>
                                    <Field
                                        label={""}
                                        name={"Back_press_speed"}
                                        className={"fieldSingleGrid"}
                                        options={{
                                            type: "text",
                                            disabled: option == "view",
                                        }}
                                        value={item}
                                        OnChange={(e) => {
                                            HandleBackPres(e, index);
                                        }}
                                    />
                                </td> */}
                  {/* <td>
                                    <Field
                                        label={""}
                                        name={"Back_press_time"}
                                        className={"fieldSingleGrid"}
                                        options={{
                                            type: "text",
                                            disabled: option == "view",
                                        }}
                                        value={item}
                                        OnChange={(e) => {
                                            HandleBackPres(e, index);
                                        }}
                                    />
                                </td> */}
                  <td>
                    <Field
                      id={index}
                      label={""}
                      name={"Back_press_position"}
                      className={"fieldSingleGrid"}
                      options={{
                        type: "text",
                        disabled: option == "view",
                      }}
                      value={item}
                      OnChange={(e) => {
                        let value = e.target.value;
                        if (InvalidCharacters(value)) {
                          value = value.replace(/[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g, '');
                          e.target.value = value;
                        }
                        HandleBackPres(e, index);
                      }}
                    />
                  </td>
                </tr>
              );
          })}
          <tr>
            <td>
              <div
                style={{ display: "grid", gridTemplateColumns: "auto auto" }}
              >
                <Lable
                  value={`${Records[0].backpres_function[3].Back_press_stages}`}
                  style={{ textAlign: "center", padding: "0px" }}
                />

                <Field
                  label={""}
                  name={"Back_press_set"}
                  className={"fieldSingleGrid"}
                  options={{
                    type: "dropdown",
                    disabled: option == "view",
                    values: DropdownValuesTime,
                  }}
                  value={Records[0].backpres_function[3]}
                  OnChange={(e) => {
                    HandleBackPres(e, 3);
                  }}
                />
              </div>
            </td>
            {/* <td>
                            <Field
                                label={""}
                                name={"Back_press_pressure"}
                                className={"fieldSingleGrid"}
                                options={{
                                    type: "text",
                                    disabled: option == "view",
                                }}
                                value={Records[0].backpres_function[3]}
                                OnChange={(e) => {
                                    HandleBackPres(e, 3);
                                }}
                            />
                        </td> */}
            {/* <td>
                            <Field
                                label={""}
                                name={"Back_press_speed"}
                                className={"fieldSingleGrid"}
                                options={{
                                    type: "text",
                                    disabled: option == "view",
                                }}
                                value={Records[0].backpres_function[3]}
                                OnChange={(e) => {
                                    HandleBackPres(e, 3);
                                }}
                            />
                        </td> */}
            <td colSpan={4}>
              <Field
                label={""}
                id={3}
                name={"Back_press_position"}
                className={"fieldSingleGrid"}
                options={{
                  type: "text",
                  disabled: option == "view",
                }}
                value={Records[0].backpres_function[3]}
                OnChange={(e) => {
                  let value = e.target.value;
                  if (InvalidCharacters(value)) {
                    value = value.replace(/[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g, '');
                    e.target.value = value;
                  }
                  HandleBackPres(e, 3);
                }}
              />
            </td>
            {/* <td>
                            <Field
                                label={""}
                                name={"Back_press_time"}
                                className={"fieldSingleGrid"}
                                options={{
                                    type: "text",
                                    disabled: option == "view",
                                }}
                                value={Records[0].backpres_function[3]}
                                OnChange={(e) => {
                                    HandleBackPres(e, 3);
                                }}
                            />
                        </td> */}
          </tr>
          <tr>
            <td>
              <div
                style={{ display: "grid", gridTemplateColumns: "auto auto" }}
              >
                <Lable
                  value={`${Records[0].backpres_function[4].Back_press_stages}`}
                  style={{ textAlign: "center", padding: "0px" }}
                />

                <Field
                  label={""}
                  name={"Back_press_set"}
                  className={"fieldSingleGrid"}
                  options={{
                    type: "dropdown",
                    disabled: option == "view",
                    values: DropdownValuesTime,
                  }}
                  value={Records[0].backpres_function[4]}
                  OnChange={(e) => {
                    HandleBackPres(e, 4);
                  }}
                />
              </div>
            </td>
            <td colSpan={4}>
              <Field
                id={4}
                label={""}
                name={"Back_press_time"}
                className={"fieldSingleGrid"}
                options={{
                  type: "text",
                  disabled: option == "view",
                }}
                value={Records[0].backpres_function[4]}
                OnChange={(e) => {
                  let value = e.target.value;
                  if (InvalidCharacters(value)) {
                    value = value.replace(/[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g, '');
                    e.target.value = value;
                  }
                  HandleBackPres(e, 4);
                }}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div
                style={{ display: "grid", gridTemplateColumns: "auto auto" }}
              >
                <Lable
                  value={`${Records[0].backpres_function[5].Back_press_stages}`}
                  style={{ textAlign: "center", padding: "0px" }}
                />

                <Field
                  label={""}
                  name={"Back_press_set"}
                  className={"fieldSingleGrid"}
                  options={{
                    type: "dropdown",
                    disabled: option == "view",
                    values: DropdownValuesTime,
                  }}
                  value={Records[0].backpres_function[5]}
                  OnChange={(e) => {
                    HandleBackPres(e, 5);
                  }}
                />
              </div>
            </td>
            <td colSpan={4}>
              <Field
                label={""}
                id={5}
                name={"Back_press_time"}
                className={"fieldSingleGrid"}
                options={{
                  type: "text",
                  disabled: option == "view",
                }}
                value={Records[0].backpres_function[5]}
                OnChange={(e) => {
                  let value = e.target.value;
                  if (InvalidCharacters(value)) {
                    value = value.replace(/[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g, '');
                    e.target.value = value;
                  }
                  HandleBackPres(e, 5);
                }}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div
                style={{ display: "grid", gridTemplateColumns: "auto auto" }}
              >
                <Lable
                  value={`${Records[0].backpres_function[6].Back_press_stages}`}
                  style={{ textAlign: "center", padding: "0px" }}
                />

                <Field
                  label={""}
                  name={"Back_press_set"}
                  className={"fieldSingleGrid"}
                  options={{
                    type: "dropdown",
                    disabled: option == "view",
                    values: DropdownValues,
                  }}
                  value={Records[0].backpres_function[6]}
                  OnChange={(e) => {
                    HandleBackPres(e, 6);
                  }}
                />
              </div>
            </td>
            <td colSpan={4}>
              <Field
                label={""}
                id={6}
                name={"Back_press_time"}
                className={"fieldSingleGrid"}
                options={{
                  type: "text",
                  disabled: option == "view",
                }}
                value={Records[0].backpres_function[6]}
                OnChange={(e) => {
                  let value = e.target.value;
                  if (InvalidCharacters(value)) {
                    value = value.replace(/[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g, '');
                    e.target.value = value;
                  }
                  HandleBackPres(e, 6);
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default BackPres;
