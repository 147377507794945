//external import
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

//internal import
import Field from "../../../Components/Fields/Field";
import Lable from "../../../Components/Fields/Lable";
import QaLayout from "../../Quality/QaLayout";

//css import
import "./NewSmpMain.css";
//API imports
const HoldingSettings = ({ Records, setRecords, setActiveKey, activeKey }) => {
  // const InitialHold = { Holding_stages: "", Holding_pressure: "", Holding_speed: "", Holding_position: "", Holding_time: "" }
  // const InitRoot = () => [
  //     {
  //         holding_functional: [InitialHold],

  //     }
  // ]
  // const [Records, setRecords] = useState(InitRoot);
  const { option, id } = useParams();
  const [ctrlPressed, setCtrlPressed] = useState(false);
  function InvalidCharacters(value) {
    return /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/.test(value);
  }
  useEffect(() => {
    if (activeKey === "second") {
      document.getElementById("1-Holding_pressure").focus();
    }
    const handleKeyDown = (event) => {
      let activeField = document.activeElement;
      const fieldOptions = {
        Holding_time: ["Holding_position", ""],
        Holding_position: ["Holding_speed", "Holding_time"],
        Holding_speed: ["Holding_pressure", "Holding_position"],
        Holding_pressure: ["", "Holding_speed"],
      };

      let row = Records[0].holding_functional.length - 1;
      let currentField = activeField.id.split("-")[1];
      let indexOfField = activeField.id.split("-")[0];
      let nextField = "";


      if (currentField != undefined) {
        switch (event.ctrlKey + "+" +event.key + "+" + activeKey) {
          case "false+ArrowRight+second":
            nextField = fieldOptions[currentField][1];
            if (indexOfField > 0 && indexOfField < row + 1) {
              if (nextField != "") {
                document.getElementById(indexOfField + "-" + nextField).focus();
              } else if (indexOfField < row) {
                document
                  .getElementById(
                    Number(indexOfField) + 1 + "-Holding_pressure"
                  )
                  .focus();
              }
            }
            break;

          case "false+ArrowLeft+second":
            nextField = fieldOptions[currentField][0];
            if (
              indexOfField > 0 &&
              indexOfField < row + 1 &&
              activeField.id != "1-Holding_pressure"
            ) {
              if (nextField != "") {
                document.getElementById(indexOfField + "-" + nextField).focus();
              } else {
                document
                  .getElementById(indexOfField - 1 + "-Holding_time")
                  .focus();
              }
            }
            break;

          case "false+ArrowDown+second":
            // console.log("indexOfField",indexOfField,row,indexOfField == row);
            if (indexOfField < row) {
              document
                .getElementById(Number(indexOfField) + 1 + "-" + currentField)
                .focus();
            }
            break;

          case "false+ArrowUp+second":
            if (indexOfField > 1) {
              //   if (indexOfField < row + 1) {
              document
                .getElementById(Number(indexOfField) - 1 + "-" + currentField)
                .focus();
              //   }
            }
            break;

          case "true+ArrowRight+second":
            setActiveKey("three");
            break;

          case "true+ArrowDown+second":
            handleIncrease(Records[0].holding_functional.length);
            document.getElementById("1-Holding_pressure").focus();
            break;

          case "true+ArrowUp+second":
            if(row>1){handleDecrease(Records[0].holding_functional.length);
            document.getElementById("1-Holding_pressure").focus();}
            break;

          default:
            break;
        }
      }
    };
    // const handleKeyUp = (event) => {
    //     if (event.key === 'Control') {
    //         setCtrlPressed(false);
    //     }
    // };
    document.addEventListener("keydown", handleKeyDown);
    // document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      // document.removeEventListener('keyup', handleKeyUp);
    };
  }, [setActiveKey, activeKey]);

  function HandleHolding(Event, index) {
    setRecords((item) => {
      let record = [...Records];
      record[0].holding_functional[index] = {
        ...record[0].holding_functional[index],
        [Event.target.name]: Event.target.value,
      };
      return record;
    });
  }
  const handleIncrease = (index) => {
    setRecords((prevRecords) => {
      let Records = [...prevRecords];
      Records[0].holding_functional.push({
        Holding_stages: "Hold On " + index,
      });
      return Records;
    });
  };
  const handleDecrease = (index) => {
    setRecords((prevRecords) => {
      let Records = [...prevRecords];
      Records[0].holding_functional.pop();
      return Records;
    });
  };

  const DropdownValues = [
    { value: "", label: "Select" },
    { value: "1", label: "±1" },
    { value: "2", label: "±2" },
    { value: "3", label: "±3" },
    { value: "4", label: "±4" },
    { value: "5", label: "±5" },
    { value: "6", label: "±6" },
    { value: "7", label: "±7" },
    { value: "8", label: "±8" },
    { value: "9", label: "±9" },
    { value: "10", label: "±10" },
    { value: "11", label: "±11" },
    { value: "12", label: "±12" },
    { value: "13", label: "±13" },
    { value: "14", label: "±14" },
    { value: "15", label: "±15" },
  ];
  const DropdownValuesTime = [
    { value: "", label: "Select" },
    { value: "0.1", label: "±0.1" },
    { value: "0.2", label: "±0.2" },
    { value: "0.3", label: "±0.3" },
    { value: "0.4", label: "±0.4" },
    { value: "0.5", label: "±0.5" },
    { value: "0.6", label: "±0.6" },
    { value: "0.7", label: "±0.7" },
    { value: "0.8", label: "±0.8" },
    { value: "0.9", label: "±0.9" },
    { value: "1", label: "±1" },
    { value: "2", label: "±2" },
    { value: "3", label: "±3" },
    { value: "4", label: "±4" },
    { value: "5", label: "±5" },
  ];

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th colSpan={5}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "90% 10%",
                  alignItems: "center",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <Lable
                    value={"Holding Settings"}
                    type={"Head"}
                    style={{ padding: "1px" }}
                  />
                </div>
                <div>
                  <button
                    className="nextButtonLabel"
                    onClick={(e) => {
                      setActiveKey("three");
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Lable
                value={Records[0].holding_functional[0].Holding_stages}
                style={{ textAlign: "center", padding: "0px" }}
              />
            </td>
            <td>
              <Lable
                value={"PRESSURE*(BAR)"}
                style={{ textAlign: "center", padding: "0px" }}
              />
              <Field
                label={""}
                name={"Holding_pressure"}
                className={"fieldSingleGrid"}
                options={{
                  type: "dropdown",
                  disabled: option == "view",
                  values: DropdownValues,
                }}
                value={Records[0].holding_functional[0]}
                OnChange={(e) => {
                  HandleHolding(e, 0);
                }}
              />
            </td>
            <td>
              <Lable
                value={"SPEED% *"}
                style={{ textAlign: "center", padding: "0px" }}
              />
              <Field
                label={""}
                name={"Holding_speed"}
                className={"fieldSingleGrid"}
                options={{
                  type: "dropdown",
                  disabled: option == "view",
                  values: DropdownValues,
                }}
                value={Records[0].holding_functional[0]}
                OnChange={(e) => {
                  HandleHolding(e, 0);
                }}
              />
            </td>
            <td>
              <Lable
                value={"POSITION(MM)⟡"}
                style={{ textAlign: "center", padding: "0px" }}
              />
              <Field
                label={""}
                name={"Holding_position"}
                className={"fieldSingleGrid"}
                options={{
                  type: "dropdown",
                  disabled: option == "view",
                  values: DropdownValues,
                }}
                value={Records[0].holding_functional[0]}
                OnChange={(e) => {
                  HandleHolding(e, 0);
                }}
              />
            </td>
            <td>
              <Lable
                value={"TIME(SEC) *"}
                style={{ textAlign: "center", padding: "0px" }}
              />
              <Field
                label={""}
                name={"Holding_time"}
                className={"fieldSingleGrid"}
                options={{
                  type: "dropdown",
                  disabled: option == "view",
                  values: DropdownValuesTime,
                }}
                value={Records[0].holding_functional[0]}
                OnChange={(e) => {
                  HandleHolding(e, 0);
                }}
              />
            </td>
          </tr>
          {/* {Array.from({ length: Records[0].holding_functional.length }, (_, i) => i + 1).map((item) => ( */}
          {Records[0].holding_functional.map((item, index) => {
            if (index != 0) {
              return (
                <tr>
                  <td>
                    <label>{item.Holding_stages}</label>
                  </td>

                  <td>
                    <Field
                      label={""}
                      id={index}
                      name={"Holding_pressure"}
                      className={"fieldSingleGrid"}
                      options={{
                        type: "text",

                        disabled: option == "view",
                      }}
                      value={item}
                      OnChange={(e) => {
                        let value = e.target.value;
                        if (InvalidCharacters(value)) {
                          value = value.replace(/[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g, '');
                          e.target.value = value;
                        }
                        HandleHolding(e, index);
                      }}
                    />
                  </td>
                  <td>
                    <Field
                      className={"fieldSingleGrid"}
                      label={""}
                      id={index}
                      name={"Holding_speed"}
                      options={{
                        type: "text",

                        disabled: option == "view",
                      }}
                      value={item}
                      OnChange={(e) => {
                        let value = e.target.value;
                        if (InvalidCharacters(value)) {
                          value = value.replace(/[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g, '');
                          e.target.value = value;
                        }
                        HandleHolding(e, index);
                      }}
                    />
                  </td>
                  <td>
                    <Field
                      className={"fieldSingleGrid"}
                      id={index}
                      label={""}
                      name={"Holding_position"}
                      options={{
                        type: "text",

                        disabled: option == "view",
                      }}
                      value={item}
                      OnChange={(e) => {
                        let value = e.target.value;
                        if (InvalidCharacters(value)) {
                          value = value.replace(/[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g, '');
                          e.target.value = value;
                        }
                        HandleHolding(e, index);
                      }}
                    />
                  </td>
                  <td>
                    <Field
                      className={"fieldSingleGrid"}
                      id={index}
                      label={""}
                      name={"Holding_time"}
                      options={{
                        type: "text",

                        disabled: option == "view",
                      }}
                      value={item}
                      OnChange={(e) => {
                        let value = e.target.value;
                        if (InvalidCharacters(value)) {
                          value = value.replace(/[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g, '');
                          e.target.value = value;
                        }
                        HandleHolding(e, index);
                      }}
                    />
                  </td>
                </tr>
              );
            }
          })}
          <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
            <div style={{}}>
              <Field
                style={{ width: "30px", fontSize: "18px" }}
                label={"+"}
                options={{ type: "Button" }}
                onClick={() =>
                  handleIncrease(Records[0].holding_functional.length)
                }
              />
            </div>
            <div>
              {Records[0].holding_functional.length > 2 && (
                <Field
                  style={{ width: "30px", fontSize: "18px" }}
                  label={"-"}
                  options={{ type: "Button" }}
                  onClick={() =>
                    handleDecrease(Records[0].holding_functional.length)
                  }
                />
              )}
            </div>
          </div>
        </tbody>
      </table>
    </div>
  );
};
export default HoldingSettings;
