import React, { useEffect, useState, useRef } from "react";
import { useNavigate} from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../Utils/constants.js";
import "../../../Styles/ProVali.css";
import Unitechlogo from "../../../icons/Unitechlogo.jpeg";
import Select from "react-select";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import OkNotOkayFetch from "../../../Components/OkAndNotOkFetcher.js";
import DatePicker from "../../../Lib/DatePicker.js";
import DropdownFetcher from "../../../Lib/DropdownFetcher.js";
import { setMessage } from "../../../Lib/SetMessage.js";
import useScanDetection from "use-scan-detection";
import CreateApproval from "../../../Lib/CreateApproval";
import IsDuplicate from "../../../Lib/IsDuplicate.js";

function ProcessValidationAndReValidationCreate() {
  const scrollTop = useRef(null);
  const handleDateChange = (newDate) => {
    setTrackDate(newDate);
  };
  const [Pro_Val, setPro_Val] = useState(false);
  const navigate = useNavigate();
  const [part, setPart] = useState([]);
  const [machine, setmachine] = useState([]);
  const [Id, setId] = useState("");
  const [Customer, setCustomer] = useState([]);
  const [Employee, setEmployee] = useState([]);
  const [PartName, setPartName] = useState("");
  const [TrackDate, setTrackDate] = useState("");
  const [MachineNo, setMachineNo] = useState("");
  const [Reason, setReason] = useState("");
  const [PartNo, setPartNo] = useState("");
  const [Process, setProcess] = useState("Injection Moulding");
  const [Model, setModel] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [ConductedBy, setConductedBy] = useState("");
  const [NozzleT101, setNozzleT101] = useState("");
  const [Zone101, setZone101] = useState("");
  const [Zone201, setZone201] = useState("");
  const [Zone301, setZone301] = useState("");
  const [Zone401, setZone401] = useState("");
  const [Zone501, setZone501] = useState("");
  const [CoreCavity101, setCoreCavity101] = useState("");
  const [ShortFill101, setShortFill101] = useState("");
  const [Flash101, setFlash101] = useState("");
  const [FlowMark101, setFlowMark101] = useState("");
  const [Srinkage101, setSrinkage101] = useState("");
  const [SilverStreiks101, setSilverStreiks101] = useState("");
  const [WeldLine101, setWeldLine101] = useState("");
  const [BurnMark101, setBurnMark101] = useState("");
  const [Result101, setResult101] = useState("");
  const [Remark101, setRemark101] = useState("");
  const [NozzleT102, setNozzleT102] = useState("");
  const [Zone102, setZone102] = useState("");
  const [Zone202, setZone202] = useState("");
  const [Zone302, setZone302] = useState("");
  const [Zone402, setZone402] = useState("");
  const [Zone502, setZone502] = useState("");
  const [CoreCavity102, setCoreCavity102] = useState("");
  const [ShortFill102, setShortFill102] = useState("");
  const [Flash102, setFlash102] = useState("");
  const [FlowMark102, setFlowMark102] = useState("");
  const [Srinkage102, setSrinkage102] = useState("");
  const [SilverStreiks102, setSilverStreiks102] = useState("");
  const [WeldLine102, setWeldLine102] = useState("");
  const [BurnMark102, setBurnMark102] = useState("");
  const [Result102, setResult102] = useState("");
  const [Remark102, setRemark102] = useState("");
  const [NozzleT103, setNozzleT103] = useState("");
  const [Zone103, setZone103] = useState("");
  const [Zone203, setZone203] = useState("");
  const [Zone303, setZone303] = useState("");
  const [Zone403, setZone403] = useState("");
  const [Zone503, setZone503] = useState("");
  const [CoreCavity103, setCoreCavity103] = useState("");
  const [ShortFill103, setShortFill103] = useState("");
  const [Flash103, setFlash103] = useState("");
  const [FlowMark103, setFlowMark103] = useState("");
  const [Srinkage103, setSrinkage103] = useState("");
  const [SilverStreiks103, setSilverStreiks103] = useState("");
  const [WeldLine103, setWeldLine103] = useState("");
  const [BurnMark103, setBurnMark103] = useState("");
  const [Result103, setResult103] = useState("");
  const [Remark103, setRemark103] = useState("");
  const [NozzleT104, setNozzleT104] = useState("");
  const [Zone104, setZone104] = useState("");
  const [Zone204, setZone204] = useState("");
  const [Zone304, setZone304] = useState("");
  const [Zone404, setZone404] = useState("");
  const [Zone504, setZone504] = useState("");
  const [CoreCavity104, setCoreCavity104] = useState("");
  const [ShortFill104, setShortFill104] = useState("");
  const [Flash104, setFlash104] = useState("");
  const [FlowMark104, setFlowMark104] = useState("");
  const [Srinkage104, setSrinkage104] = useState("");
  const [SilverStreiks104, setSilverStreiks104] = useState("");
  const [WeldLine104, setWeldLine104] = useState("");
  const [BurnMark104, setBurnMark104] = useState("");
  const [Result104, setResult104] = useState("");
  const [Remark104, setRemark104] = useState("");
  const [NozzleT105, setNozzleT105] = useState("");
  const [Zone105, setZone105] = useState("");
  const [Zone205, setZone205] = useState("");
  const [Zone305, setZone305] = useState("");
  const [Zone405, setZone405] = useState("");
  const [Zone505, setZone505] = useState("");
  const [CoreCavity105, setCoreCavity105] = useState("");
  const [ShortFill105, setShortFill105] = useState("");
  const [Flash105, setFlash105] = useState("");
  const [FlowMark105, setFlowMark105] = useState("");
  const [Srinkage105, setSrinkage105] = useState("");
  const [SilverStreiks105, setSilverStreiks105] = useState("");
  const [WeldLine105, setWeldLine105] = useState("");
  const [BurnMark105, setBurnMark105] = useState("");
  const [Result105, setResult105] = useState("");
  const [Remark105, setRemark105] = useState("");
  const [InjPres101, setInjPres101] = useState("");
  const [InjPres201, setInjPres201] = useState("");
  const [InjPres301, setInjPres301] = useState("");
  const [InjSpd101, setInjSpd101] = useState("");
  const [InjSpd201, setInjSpd201] = useState("");
  const [InjSpd301, setInjSpd301] = useState("");
  const [ShotSize201, setShotSize201] = useState("");
  const [ShortFill201, setShortFill201] = useState("");
  const [Flash201, setFlash201] = useState("");
  const [FlowMark201, setFlowMark201] = useState("");
  const [Srinkage201, setSrinkage201] = useState("");
  const [SilverStreiks201, setSilverStreiks201] = useState("");
  const [WeldLine201, setWeldLine201] = useState("");
  const [BurnMark201, setBurnMark201] = useState("");
  const [Result201, setResult201] = useState("");
  const [Remark201, setRemark201] = useState("");
  const [InjPres102, setInjPres102] = useState("");
  const [InjPres202, setInjPres202] = useState("");
  const [InjPres302, setInjPres302] = useState("");
  const [InjSpd102, setInjSpd102] = useState("");
  const [InjSpd202, setInjSpd202] = useState("");
  const [InjSpd302, setInjSpd302] = useState("");
  const [ShotSize202, setShotSize202] = useState("");
  const [ShortFill202, setShortFill202] = useState("");
  const [Flash202, setFlash202] = useState("");
  const [FlowMark202, setFlowMark202] = useState("");
  const [Srinkage202, setSrinkage202] = useState("");
  const [SilverStreiks202, setSilverStreiks202] = useState("");
  const [WeldLine202, setWeldLine202] = useState("");
  const [BurnMark202, setBurnMark202] = useState("");
  const [Result202, setResult202] = useState("");
  const [Remark202, setRemark202] = useState("");
  const [InjPres103, setInjPres103] = useState("");
  const [InjPres203, setInjPres203] = useState("");
  const [InjPres303, setInjPres303] = useState("");
  const [InjSpd103, setInjSpd103] = useState("");
  const [InjSpd203, setInjSpd203] = useState("");
  const [InjSpd303, setInjSpd303] = useState("");
  const [ShotSize203, setShotSize203] = useState("");
  const [ShortFill203, setShortFill203] = useState("");
  const [Flash203, setFlash203] = useState("");
  const [FlowMark203, setFlowMark203] = useState("");
  const [Srinkage203, setSrinkage203] = useState("");
  const [SilverStreiks203, setSilverStreiks203] = useState("");
  const [WeldLine203, setWeldLine203] = useState("");
  const [BurnMark203, setBurnMark203] = useState("");
  const [Result203, setResult203] = useState("");
  const [Remark203, setRemark203] = useState("");
  const [InjPres104, setInjPres104] = useState("");
  const [InjPres204, setInjPres204] = useState("");
  const [InjPres304, setInjPres304] = useState("");
  const [InjSpd104, setInjSpd104] = useState("");
  const [InjSpd204, setInjSpd204] = useState("");
  const [InjSpd304, setInjSpd304] = useState("");
  const [ShotSize204, setShotSize204] = useState("");
  const [ShortFill204, setShortFill204] = useState("");
  const [Flash204, setFlash204] = useState("");
  const [FlowMark204, setFlowMark204] = useState("");
  const [Srinkage204, setSrinkage204] = useState("");
  const [SilverStreiks204, setSilverStreiks204] = useState("");
  const [WeldLine204, setWeldLine204] = useState("");
  const [BurnMark204, setBurnMark204] = useState("");
  const [Result204, setResult204] = useState("");
  const [Remark204, setRemark204] = useState("");
  const [InjPres105, setInjPres105] = useState("");
  const [InjPres205, setInjPres205] = useState("");
  const [InjPres305, setInjPres305] = useState("");
  const [InjSpd105, setInjSpd105] = useState("");
  const [InjSpd205, setInjSpd205] = useState("");
  const [InjSpd305, setInjSpd305] = useState("");
  const [ShotSize205, setShotSize205] = useState("");
  const [ShortFill205, setShortFill205] = useState("");
  const [Flash205, setFlash205] = useState("");
  const [FlowMark205, setFlowMark205] = useState("");
  const [Srinkage205, setSrinkage205] = useState("");
  const [SilverStreiks205, setSilverStreiks205] = useState("");
  const [WeldLine205, setWeldLine205] = useState("");
  const [BurnMark205, setBurnMark205] = useState("");
  const [Result205, setResult205] = useState("");
  const [Remark205, setRemark205] = useState("");
  const [HoldPres101, setHoldPres101] = useState("");
  const [HoldSpd101, setHoldSpd101] = useState("");
  const [InjTme101, setInjTme101] = useState("");
  const [CoolTme101, setCoolTme101] = useState("");
  const [HoldTme101, setHoldTme101] = useState("");
  const [HydeaOilTemp101, setHydeaOilTemp101] = useState("");
  const [MTC101, setMTC101] = useState("");
  const [ShortFill301, setShortFill301] = useState("");
  const [Flash301, setFlash301] = useState("");
  const [FlowMark301, setFlowMark301] = useState("");
  const [Srinkage301, setSrinkage301] = useState("");
  const [SilverStreiks301, setSilverStreiks301] = useState("");
  const [WeldLine301, setWeldLine301] = useState("");
  const [BurnMark301, setBurnMark301] = useState("");
  const [Result301, setResult301] = useState("");
  const [Remark301, setRemark301] = useState("");
  const [HoldPres102, setHoldPres102] = useState("");
  const [HoldSpd102, setHoldSpd102] = useState("");
  const [InjTme102, setInjTme102] = useState("");
  const [CoolTme102, setCoolTme102] = useState("");
  const [HoldTme102, setHoldTme102] = useState("");
  const [HydeaOilTemp102, setHydeaOilTemp102] = useState("");
  const [MTC102, setMTC102] = useState("");
  const [ShortFill302, setShortFill302] = useState("");
  const [Flash302, setFlash302] = useState("");
  const [FlowMark302, setFlowMark302] = useState("");
  const [Srinkage302, setSrinkage302] = useState("");
  const [SilverStreiks302, setSilverStreiks302] = useState("");
  const [WeldLine302, setWeldLine302] = useState("");
  const [BurnMark302, setBurnMark302] = useState("");
  const [Result302, setResult302] = useState("");
  const [Remark302, setRemark302] = useState("");
  const [HoldPres103, setHoldPres103] = useState("");
  const [HoldSpd103, setHoldSpd103] = useState("");
  const [InjTme103, setInjTme103] = useState("");
  const [CoolTme103, setCoolTme103] = useState("");
  const [HoldTme103, setHoldTme103] = useState("");
  const [HydeaOilTemp103, setHydeaOilTemp103] = useState("");
  const [MTC103, setMTC103] = useState("");
  const [ShortFill303, setShortFill303] = useState("");
  const [Flash303, setFlash303] = useState("");
  const [FlowMark303, setFlowMark303] = useState("");
  const [Srinkage303, setSrinkage303] = useState("");
  const [SilverStreiks303, setSilverStreiks303] = useState("");
  const [WeldLine303, setWeldLine303] = useState("");
  const [BurnMark303, setBurnMark303] = useState("");
  const [Result303, setResult303] = useState("");
  const [Remark303, setRemark303] = useState("");
  const [HoldPres104, setHoldPres104] = useState("");
  const [HoldSpd104, setHoldSpd104] = useState("");
  const [InjTme104, setInjTme104] = useState("");
  const [CoolTme104, setCoolTme104] = useState("");
  const [HoldTme104, setHoldTme104] = useState("");
  const [HydeaOilTemp104, setHydeaOilTemp104] = useState("");
  const [MTC104, setMTC104] = useState("");
  const [ShortFill304, setShortFill304] = useState("");
  const [Flash304, setFlash304] = useState("");
  const [FlowMark304, setFlowMark304] = useState("");
  const [Srinkage304, setSrinkage304] = useState("");
  const [SilverStreiks304, setSilverStreiks304] = useState("");
  const [WeldLine304, setWeldLine304] = useState("");
  const [BurnMark304, setBurnMark304] = useState("");
  const [Result304, setResult304] = useState("");
  const [Remark304, setRemark304] = useState("");
  const [HoldPres105, setHoldPres105] = useState("");
  const [HoldSpd105, setHoldSpd105] = useState("");
  const [InjTme105, setInjTme105] = useState("");
  const [CoolTme105, setCoolTme105] = useState("");
  const [HoldTme105, setHoldTme105] = useState("");
  const [HydeaOilTemp105, setHydeaOilTemp105] = useState("");
  const [MTC105, setMTC105] = useState("");
  const [ShortFill305, setShortFill305] = useState("");
  const [Flash305, setFlash305] = useState("");
  const [FlowMark305, setFlowMark305] = useState("");
  const [Srinkage305, setSrinkage305] = useState("");
  const [SilverStreiks305, setSilverStreiks305] = useState("");
  const [WeldLine305, setWeldLine305] = useState("");
  const [BurnMark305, setBurnMark305] = useState("");
  const [Result305, setResult305] = useState("");
  const [Remark305, setRemark305] = useState("");
  const [NozzleBefore, setNozzleBefore] = useState("");
  const [Zone1Before, setZone1Before] = useState("");
  const [Zone2Before, setZone2Before] = useState("");
  const [Zone3Before, setZone3Before] = useState("");
  const [Zone4Before, setZone4Before] = useState("");
  const [Zone5Before, setZone5Before] = useState("");
  const [CoreCavityBefore, setCoreCavityBefore] = useState("");
  const [NozzleAfter, setNozzleAfter] = useState("");
  const [Zone1After, setZone1After] = useState("");
  const [Zone2After, setZone2After] = useState("");
  const [Zone3After, setZone3After] = useState("");
  const [Zone4After, setZone4After] = useState("");
  const [Zone5After, setZone5After] = useState("");
  const [CoreCavityAfter, setCoreCavityAfter] = useState("");
  const [InjPresSet, setInjPresSet] = useState("");
  const [InjSpdSet, setInjSpdSet] = useState("");
  const [NozzSet, setNozzSet] = useState("");
  const [ZneSet1, setZneSet1] = useState("");
  const [ZneSet2, setZneSet2] = useState("");
  const [ZneSet3, setZneSet3] = useState("");
  const [ZneSet4, setZneSet4] = useState("");
  const [ZneSet5, setZneSet5] = useState("");

  const [HoldPresSet, setHoldPresSet] = useState("");
  const [HoldSpdSet, setHoldSpdSet] = useState("");
  const [InjTmeSet, setInjTmeSet] = useState("");
  const [CoolTmeSet, setCoolTmeSet] = useState("");
  const [HoldTmeSet, setHoldTmeSet] = useState("");
  const [OilSet, setOilSet] = useState("");
  const [MtcSet, setMtcSet] = useState("");

  const [InjPres1Before, setInjPres1Before] = useState("");
  const [InjPres2Before, setInjPres2Before] = useState("");
  const [InjPres3Before, setInjPres3Before] = useState("");
  const [InjSped1Before, setInjSped1Before] = useState("");
  const [InjSped2Before, setInjSped2Before] = useState("");
  const [InjSped3Before, setInjSped3Before] = useState("");
  const [ShotSizeBefore, setShotSizeBefore] = useState("");
  const [InjPres1After, setInjPres1After] = useState("");
  const [InjPres2After, setInjPres2After] = useState("");
  const [InjPres3After, setInjPres3After] = useState("");
  const [InjSped1After, setInjSped1After] = useState("");
  const [InjSped2After, setInjSped2After] = useState("");
  const [InjSped3After, setInjSped3After] = useState("");
  const [ShotSizeAfter, setShotSizeAfter] = useState("");
  const [HoldPresBefore, setHoldPresBefore] = useState("");
  const [HoldSpdBefore, setHoldSpdBefore] = useState("");
  const [InjTmeBefore, setInjTmeBefore] = useState("");
  const [CoolTmeBefore, setCoolTmeBefore] = useState("");
  const [HldTmeBefore, setHldTmeBefore] = useState("");
  const [HydraOilTmpBefore, setHydraOilTmpBefore] = useState("");
  const [MtcBefore, setMtcBefore] = useState("");
  const [HoldPresAfter, setHoldPresAfter] = useState("");
  const [HoldSpdAfter, setHoldSpdAfter] = useState("");
  const [InjTmeAfter, setInjTmeAfter] = useState("");
  const [CoolTmeAfter, setCoolTmeAfter] = useState("");
  const [HldTmeAfter, setHldTmeAfter] = useState("");
  const [HydraOilTempAfter, setHydraOilTempAfter] = useState("");
  const [MtcAfter, setMtcAfter] = useState("");
  const [Validation, setValidation] = useState("");
  const [Prepared, setPrepared] = useState("");
  const [Approved, setApproved] = useState("");
  const [Note, setNote] = useState("");
  const [IsCreating1, setIsCreating1] = useState(true);
  const [isCreating, setIsCreating] = useState(false);

  var [mat_load, setmat_load] = useState([]);
  const [dropdown, setDropdown] = useState([]);
  const [partDropdown, setPartDropdown] = useState([]);
  function InvalidCharacters(value) {
    return /[a-zA-Z!@#$%^&*()_\+={[}\]|\\:;"'<,>?/~`]/.test(value);
  }
  const mcNoOptions = dropdown.map((i) => ({
    value: i.McNo,
    label: i.mc_name,
  }));

  const prtNmeOptions = partDropdown.map((i) => ({
    value: i.PrtNme,
    label: i.PrtNme,
  }));

  const getDropdown = () => {
    axios
      .post(`http://${API_URL}/GenericResultBuilderService/buildResults`, {
        _keyword_: "Get_SMP_MachineNumber",
      })
      .then((response) => {
        setDropdown(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPartDropdown = (McNovalue) => {
    console.log("McNovalue", McNovalue);
    axios
      .post(`http://${API_URL}/GenericResultBuilderService/buildResults`, {
        _keyword_: "Get_PartName_basedOn_MachineNo_form_SMP",
        Mcno: McNovalue,
      })
      .then((response) => {
        console.log("response.data", response.data);
        setPartDropdown(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getDropdown();
  }, []);

  const id = "ProcessValidationAndReValidation";
  const name = "ProcessValidationAndReValidation";
  // useEffect(() => {
  //   console.log(
  //   "Part_name:", PartNo.value,
  //   "Machine_no:", MachineNo.value,
  //   "Reason:", Reason,
  //   "Part_no:", PartName,)
  // },);

  // const CodeGen = async () => {
  //   const val = {
  //     tablename: "material_loading",
  //     PrimaryCode: "Mat_name",
  //     PrimaryVal: MachineNo.value,
  //     FieldCode: "Mat_grde",
  //     FieldVal: PartName,
  //   };

  //   const response = await axios.post(
  //     `http://${API_URL}/AppContextService/CodeGen`,
  //     val
  //   );
  //   return response.data.success === "true";
  // };

  const handleCreate = async (e) => {
    e.preventDefault();
    const isSubmissionSuccessful = true;

    if (!MachineNo || !Reason || !ConductedBy || !customerName || !PartNo) {
      setIsCreating(true);
      alert("Please fill the mandatory fields");
      if (scrollTop.current) {
        scrollTop.current.scrollIntoView({ behavior: "smooth" });
      }
      return;
    }

    try {
     
      // const isDuplicate = await CodeGen();

      // if (isDuplicate) {
      //   alert(`${MachineNo.value} Already Exist.`);

      //   window.location.reload();

      //   setPartName("");
      //   setMachineNo("");

      //   return;
      // }

      const data = {
        data: [
          {
            // Id: Id,
            TrackDate: TrackDate,
            Part_name: PartName,
            Machine_no: MachineNo.value,
            Reason: Reason,
            Part_no: PartNo.value,
            Process: Process,
            Model: Model,
            Customer: customerName.value,
            Conducted_by: ConductedBy.value,

            Nozzle_t_101: NozzleT101,
            Zone_101: Zone101,
            Zone_201: Zone201,
            Zone_301: Zone301,
            Zone_401: Zone401,
            Zone_501: Zone501,
            Core_cavity_101: CoreCavity101,
            Short_fill_101: ShortFill101,
            Flash_101: Flash101,
            Flow_mark_101: FlowMark101,
            Srinkage_101: Srinkage101,
            Silver_streiks_101: SilverStreiks101,
            Weld_line_101: WeldLine101,
            Burn_mark_101: BurnMark101,
            Result_101: Result101,
            Remark_101: Remark101,

            Nozzle_t_102: NozzleT102,
            Zone_102: Zone102,
            Zone_202: Zone202,
            Zone_302: Zone302,
            Zone_402: Zone402,
            Zone_502: Zone502,
            Core_cavity_102: CoreCavity102,
            Short_fill_102: ShortFill102,
            Flash_102: Flash102,
            Flow_mark_102: FlowMark102,
            Srinkage_102: Srinkage102,
            Silver_streiks_102: SilverStreiks102,
            Weld_line_102: WeldLine102,
            Burn_mark_102: BurnMark102,
            Result_102: Result102,
            Remark_102: Remark102,

            Nozzle_t_103: NozzleT103,
            Zone_103: Zone103,
            Zone_203: Zone203,
            Zone_303: Zone303,
            Zone_403: Zone403,
            Zone_503: Zone503,
            Core_cavity_103: CoreCavity103,
            Short_fill_103: ShortFill103,
            Flash_103: Flash103,
            Flow_mark_103: FlowMark103,
            Srinkage_103: Srinkage103,
            Silver_streiks_103: SilverStreiks103,
            Weld_line_103: WeldLine103,
            Burn_mark_103: BurnMark103,
            Result_103: Result103,
            Remark_103: Remark103,

            Nozzle_t_104: NozzleT104,
            Zone_104: Zone104,
            Zone_204: Zone204,
            Zone_304: Zone304,
            Zone_404: Zone404,
            Zone_504: Zone504,
            Core_cavity_104: CoreCavity104,
            Short_fill_104: ShortFill104,
            Flash_104: Flash104,
            Flow_mark_104: FlowMark104,
            Srinkage_104: Srinkage104,
            Silver_streiks_104: SilverStreiks104,
            Weld_line_104: WeldLine104,
            Burn_mark_104: BurnMark104,
            Result_104: Result104,
            Remark104: Remark104,

            Nozzle_t_105: NozzleT105,
            Zone_105: Zone105,
            Zone_205: Zone205,
            Zone_305: Zone305,
            Zone_405: Zone405,
            Zone_505: Zone505,
            Core_cavity_105: CoreCavity105,
            Short_fill_105: ShortFill105,
            Flash_105: Flash105,
            Flow_mark_105: FlowMark105,
            Srinkage_105: Srinkage105,
            Silver_streiks_105: SilverStreiks105,
            Weld_line_105: WeldLine105,
            Burn_mark_105: BurnMark105,
            Result_105: Result105,
            Remark_105: Remark105,

            Inj_pres_101: InjPres101,
            Inj_pres_201: InjPres201,
            Inj_pres_301: InjPres301,
            Inj_spd_101: InjSpd101,
            Inj_spd_201: InjSpd201,
            Inj_spd_301: InjSpd301,
            Shot_size_201: ShotSize201,
            Short_fill_201: ShortFill201,
            Flash_201: Flash201,
            Flow_mark_201: FlowMark201,
            Srinkage_201: Srinkage201,
            Silver_streiks_201: SilverStreiks201,
            Weld_line_201: WeldLine201,
            Burn_mark_201: BurnMark201,
            Result_201: Result201,
            Remark_201: Remark201,

            Inj_pres_102: InjPres102,
            Inj_pres_202: InjPres202,
            Inj_pres_302: InjPres302,
            Inj_spd_102: InjSpd102,
            Inj_spd_202: InjSpd202,
            Inj_spd_302: InjSpd302,
            Shot_size_202: ShotSize202,
            Short_fill_202: ShortFill202,
            Flash_202: Flash202,
            Flow_mark_202: FlowMark202,
            Srinkage_202: Srinkage202,
            Silver_streiks_202: SilverStreiks202,
            Weld_line_202: WeldLine202,
            Burn_mark_202: BurnMark202,
            Result_202: Result202,
            Remark_202: Remark202,

            Inj_pres_103: InjPres103,
            Inj_pres_203: InjPres203,
            Inj_pres_303: InjPres303,
            Inj_spd_103: InjSpd103,
            Inj_spd_203: InjSpd203,
            Inj_spd_303: InjSpd303,
            Shot_size_203: ShotSize203,
            Short_fill_203: ShortFill203,
            Flash_203: Flash203,
            Flow_mark_203: FlowMark203,
            Srinkage_203: Srinkage203,
            Silver_streiks_203: SilverStreiks203,
            Weld_line_203: WeldLine203,
            Burn_mark_203: BurnMark203,
            Result_203: Result203,
            Remark_203: Remark203,

            Inj_pres_104: InjPres104,
            Inj_pres_204: InjPres204,
            Inj_pres_304: InjPres304,
            Inj_spd_104: InjSpd104,
            Inj_spd_204: InjSpd204,
            Inj_spd_304: InjSpd304,
            Shot_size_204: ShotSize204,
            Short_fill_204: ShortFill204,
            Flash_204: Flash204,
            Flow_mark_204: FlowMark204,
            Srinkage_204: Srinkage204,
            Silver_streiks_204: SilverStreiks204,
            Weld_line_204: WeldLine204,
            Burn_mark_204: BurnMark204,
            Result_204: Result204,
            Remark_204: Remark204,

            Inj_pres_105: InjPres105,
            Inj_pres_205: InjPres205,
            Inj_pres_305: InjPres305,
            Inj_spd_105: InjSpd105,
            Inj_spd_205: InjSpd205,
            Inj_spd_305: InjSpd305,
            Shot_size_205: ShotSize205,
            Short_fill_205: ShortFill205,
            Flash_205: Flash205,
            Flow_mark_205: FlowMark205,
            Srinkage_205: Srinkage205,
            Silver_streiks_205: SilverStreiks205,
            Weld_line_205: WeldLine205,
            Burn_mark_205: BurnMark205,
            Result_205: Result205,
            Remark_205: Remark205,

            Hold_pres_101: HoldPres101,
            Hold_spd_101: HoldSpd101,
            Inj_tme_101: InjTme101,
            Cool_tme_101: CoolTme101,
            Hold_tme_101: HoldTme101,
            Hydea_oil_temp_101: HydeaOilTemp101,
            MTC_101: MTC101,
            Short_fill_301: ShortFill301,
            Flash_301: Flash301,
            Flow_mark_301: FlowMark301,
            Srinkage_301: Srinkage301,
            Silver_streiks_301: SilverStreiks301,
            Weld_line_301: WeldLine301,
            Burn_mark_301: BurnMark301,
            Result_301: Result301,
            Remark_301: Remark301,

            Hold_pres_102: HoldPres102,
            Hold_spd_102: HoldSpd102,
            Inj_tme_102: InjTme102,
            Cool_tme_102: CoolTme102,
            Hold_tme_102: HoldTme102,
            Hydea_oil_temp_102: HydeaOilTemp102,
            MTC_102: MTC102,
            Short_fill_302: ShortFill302,
            Flash_302: Flash302,
            Flow_mark_302: FlowMark302,
            Srinkage_302: Srinkage302,
            Silver_streiks_302: SilverStreiks302,
            Weld_line_302: WeldLine302,
            Burn_mark_302: BurnMark302,
            Result_302: Result302,
            Remark_302: Remark302,

            Hold_pres_103: HoldPres103,
            Hold_spd_103: HoldSpd103,
            Inj_tme_103: InjTme103,
            Cool_tme_103: CoolTme103,
            Hold_tme_103: HoldTme103,
            Hydea_oil_temp_103: HydeaOilTemp103,
            MTC_103: MTC103,
            Short_fill_303: ShortFill303,
            Flash_303: Flash303,
            Flow_mark_303: FlowMark303,
            Srinkage_303: Srinkage303,
            Silver_streiks_303: SilverStreiks303,
            Weld_line_303: WeldLine303,
            Burn_mark_303: BurnMark303,
            Result_303: Result303,
            Remark_303: Remark303,

            Hold_pres_104: HoldPres104,
            Hold_spd_104: HoldSpd104,
            Inj_tme_104: InjTme104,
            Cool_tme_104: CoolTme104,
            Hold_tme_104: HoldTme104,
            Hydea_oil_temp_104: HydeaOilTemp104,
            MTC_104: MTC104,
            Short_fill_304: ShortFill304,
            Flash_304: Flash304,
            Flow_mark_304: FlowMark304,
            Srinkage_304: Srinkage304,
            Silver_streiks_304: SilverStreiks304,
            Weld_line_304: WeldLine304,
            Burn_mark_304: BurnMark304,
            Result_304: Result304,
            Remark_304: Remark304,

            Hold_pres_105: HoldPres105,
            Hold_spd_105: HoldSpd105,
            Inj_tme_105: InjTme105,
            Cool_tme_105: CoolTme105,
            Hold_tme_105: HoldTme105,
            Hydea_oil_temp_105: HydeaOilTemp105,
            MTC_105: MTC105,
            Short_fill_305: ShortFill305,
            Flash_305: Flash305,
            Flow_mark_305: FlowMark305,
            Srinkage_305: Srinkage305,
            Silver_streiks_305: SilverStreiks305,
            Weld_line_305: WeldLine305,
            Burn_mark_305: BurnMark305,
            Result_305: Result305,
            Remark_305: Remark305,

            Nozzle_before: NozzleBefore,
            Zone1_before: Zone1Before,
            Zone2_before: Zone2Before,
            Zone3_before: Zone3Before,
            Zone4_before: Zone4Before,
            Zone5_before: Zone5Before,
            Core_cavity_before: CoreCavityBefore,

            Nozzle_after: NozzleAfter,
            Zone1_after: Zone1After,
            Zone2_after: Zone2After,
            Zone3_after: Zone3After,
            Zone4_after: Zone4After,
            Zone5_after: Zone5After,
            Core_cavity_after: CoreCavityAfter,

            Inj_pres1_before: InjPres1Before,
            Inj_pres2_before: InjPres2Before,
            Inj_pres3_before: InjPres3Before,
            Inj_sped1_before: InjSped1Before,
            Inj_sped2_before: InjSped2Before,
            Inj_sped3_before: InjSped3Before,
            Shot_size_before: ShotSizeBefore,

            Inj_pres1_after: InjPres1After,
            Inj_pres2_after: InjPres2After,
            Inj_pres3_after: InjPres3After,
            Inj_sped1_after: InjSped1After,
            Inj_sped2_after: InjSped2After,
            Inj_sped3_after: InjSped3After,
            Shot_size_after: ShotSizeAfter,

            Hold_pres_before: HoldPresBefore,
            Hold_spd_before: HoldSpdBefore,
            Inj_tme_before: InjTmeBefore,
            Cool_tme_before: CoolTmeBefore,
            Hld_tme_before: HldTmeBefore,
            Hydra_oil_tmp_before: HydraOilTmpBefore,
            Mtc_before: MtcBefore,

            Hold_pres_after: HoldPresAfter,
            Hold_spd_after: HoldSpdAfter,
            Inj_tme_after: InjTmeAfter,
            Cool_tme_after: CoolTmeAfter,
            Hld_tme_after: HldTmeAfter,
            Hydra_oil_temp_after: HydraOilTempAfter,
            Mtc_after: MtcAfter,

            Validation: Validation.value,
            Prepared: Prepared.value,
            Approved: Approved.value,
            Note: Note,
            Nozz_set: NozzSet,
            Zne_set1: ZneSet1,
            Zne_set2: ZneSet2,
            Zne_set3: ZneSet3,
            Zne_set4: ZneSet4,
            Zne_set5: ZneSet5,
            Inj_pres_set: InjPresSet,
            Inj_spd_set: InjSpdSet,
            Hold_pres_set: HoldPresSet,
            Hold_spd_set: HoldSpdSet,
            Inj_tme_set: InjTmeSet,
            Cool_tme_set: CoolTmeSet,
            Hold_tme_set: HoldTmeSet,
            Oil_set: OilSet,
            Mtc_set: MtcSet,
          },
        ],
        _keyword_: "Pro_validation_revalidation",
        secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
      };

      const response = await axios.post(
        `http://${API_URL}/GenericTransactionService/processTransaction`,
        data
      );

      if (response.data.success === "false") {
        alert("Problem in Saving Machine details");
        window.location.reload();
      } else {
        setmat_load([...mat_load, response.data]);
        alert(`${GeneralConstants.SUBMISSION_SUCCESSFUL}`);
        console.log("setting msg");
        console.log("setting msg", response);
        setMessage({
          NoteFrom: Prepared.value,
          NoteTo: Validation.value,
          NoteMessage: `The Process validation Has been Created By ${Prepared.value}-${Prepared.label}, which is conducted by ${ConductedBy.value}-${ConductedBy.label} Please do Validate`,
          NoteRecordId: response.data.ROWID,
          TableName: "ProcessValidation",
          NoteCreatedBy: localStorage.getItem("empId"),
        });
        // setMessage({
        //   NoteFrom: Prepared.value,
        //   NoteTo: Prepared.value,
        //   NoteMessage: `The Process validation Has been Created By ${localStorage.getItem("empId")}`,
        //   NoteRecordId: response.data.ROWID,
        //   TableName: "ProcessValidation",
        //   NoteCreatedBy: localStorage.getItem("empId"),

        // });
        setMessage({
          NoteFrom: Prepared.value,
          NoteTo: Approved.value,
          NoteMessage: `The Process validation Has been Created By ${Prepared.value}-${Prepared.label}, which is conducted by ${ConductedBy.value}-${ConductedBy.label} Please approve the form`,
          NoteRecordId: response.data.ROWID,
          TableName: "ProcessValidation",
          NoteCreatedBy: localStorage.getItem("empId"),
        });
        CreateApproval({
          RequestedBy: Prepared.value,
          Approver: Validation.value,
          Level: 1,
          Status: "Waiting for Approval",
          TableName: "ProcessValidation",
          TableID: response.data.ROWID,
          Description: `The Process validation Has been Created By ${Prepared.value}-${Prepared.label}, which is conducted by ${ConductedBy.value}-${ConductedBy.label} Please validate the form`,
          CreatedBy: localStorage.getItem("empId"),
        });
        CreateApproval({
          RequestedBy: Prepared.value,
          Approver: Approved.value,
          Level: 1,
          Status: "Waiting for Approval",
          TableName: "ProcessValidation",
          TableID: response.data.ROWID,
          Description: `The Process validation Has been Created By ${Prepared.value}-${Prepared.label}, which is conducted by ${ConductedBy.value}-${ConductedBy.label} Please approve the form`,
          CreatedBy: localStorage.getItem("empId"),
        });
      }
    } catch (error) {
      console.log(error);
      alert("Submission failed.");
    }
  //  window.location.reload();
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const isSubmissionSuccessful = true;

    if (
      !MachineNo.value ||
      !Reason ||
      !ConductedBy ||
      !customerName ||
      !PartNo
    ) {
      setIsCreating(true);
      alert("Please fill the mandatory fields");
      if (scrollTop.current) {
        scrollTop.current.scrollIntoView({ behavior: "smooth" });
      }
      return;
    }

    try {

      const data = {
        data: [
          {
            Id: Id,
            TrackDate: TrackDate,
            Part_name: PartNo.value,
            Machine_no: MachineNo.value,
            Reason: Reason,
            Part_no: PartName,
            Process: Process,
            Model: Model,
            Customer: customerName.value,
            Conducted_by: ConductedBy.value,

            Nozzle_t_101: NozzleT101,
            Zone_101: Zone101,
            Zone_201: Zone201,
            Zone_301: Zone301,
            Zone_401: Zone401,
            Zone_501: Zone501,
            Core_cavity_101: CoreCavity101,
            Short_fill_101: ShortFill101,
            Flash_101: Flash101,
            Flow_mark_101: FlowMark101,
            Srinkage_101: Srinkage101,
            Silver_streiks_101: SilverStreiks101,
            Weld_line_101: WeldLine101,
            Burn_mark_101: BurnMark101,
            Result_101: Result101,
            Remark_101: Remark101,

            Nozzle_t_102: NozzleT102,
            Zone_102: Zone102,
            Zone_202: Zone202,
            Zone_302: Zone302,
            Zone_402: Zone402,
            Zone_502: Zone502,
            Core_cavity_102: CoreCavity102,
            Short_fill_102: ShortFill102,
            Flash_102: Flash102,
            Flow_mark_102: FlowMark102,
            Srinkage_102: Srinkage102,
            Silver_streiks_102: SilverStreiks102,
            Weld_line_102: WeldLine102,
            Burn_mark_102: BurnMark102,
            Result_102: Result102,
            Remark_102: Remark102,

            Nozzle_t_103: NozzleT103,
            Zone_103: Zone103,
            Zone_203: Zone203,
            Zone_303: Zone303,
            Zone_403: Zone403,
            Zone_503: Zone503,
            Core_cavity_103: CoreCavity103,
            Short_fill_103: ShortFill103,
            Flash_103: Flash103,
            Flow_mark_103: FlowMark103,
            Srinkage_103: Srinkage103,
            Silver_streiks_103: SilverStreiks103,
            Weld_line_103: WeldLine103,
            Burn_mark_103: BurnMark103,
            Result_103: Result103,
            Remark_103: Remark103,

            Nozzle_t_104: NozzleT104,
            Zone_104: Zone104,
            Zone_204: Zone204,
            Zone_304: Zone304,
            Zone_404: Zone404,
            Zone_504: Zone504,
            Core_cavity_104: CoreCavity104,
            Short_fill_104: ShortFill104,
            Flash_104: Flash104,
            Flow_mark_104: FlowMark104,
            Srinkage_104: Srinkage104,
            Silver_streiks_104: SilverStreiks104,
            Weld_line_104: WeldLine104,
            Burn_mark_104: BurnMark104,
            Result_104: Result104,
            Remark104: Remark104,

            Nozzle_t_105: NozzleT105,
            Zone_105: Zone105,
            Zone_205: Zone205,
            Zone_305: Zone305,
            Zone_405: Zone405,
            Zone_505: Zone505,
            Core_cavity_105: CoreCavity105,
            Short_fill_105: ShortFill105,
            Flash_105: Flash105,
            Flow_mark_105: FlowMark105,
            Srinkage_105: Srinkage105,
            Silver_streiks_105: SilverStreiks105,
            Weld_line_105: WeldLine105,
            Burn_mark_105: BurnMark105,
            Result_105: Result105,
            Remark_105: Remark105,

            Inj_pres_101: InjPres101,
            Inj_pres_201: InjPres201,
            Inj_pres_301: InjPres301,
            Inj_spd_101: InjSpd101,
            Inj_spd_201: InjSpd201,
            Inj_spd_301: InjSpd301,
            Shot_size_201: ShotSize201,
            Short_fill_201: ShortFill201,
            Flash_201: Flash201,
            Flow_mark_201: FlowMark201,
            Srinkage_201: Srinkage201,
            Silver_streiks_201: SilverStreiks201,
            Weld_line_201: WeldLine201,
            Burn_mark_201: BurnMark201,
            Result_201: Result201,
            Remark_201: Remark201,

            Inj_pres_102: InjPres102,
            Inj_pres_202: InjPres202,
            Inj_pres_302: InjPres302,
            Inj_spd_102: InjSpd102,
            Inj_spd_202: InjSpd202,
            Inj_spd_302: InjSpd302,
            Shot_size_202: ShotSize202,
            Short_fill_202: ShortFill202,
            Flash_202: Flash202,
            Flow_mark_202: FlowMark202,
            Srinkage_202: Srinkage202,
            Silver_streiks_202: SilverStreiks202,
            Weld_line_202: WeldLine202,
            Burn_mark_202: BurnMark202,
            Result_202: Result202,
            Remark_202: Remark202,

            Inj_pres_103: InjPres103,
            Inj_pres_203: InjPres203,
            Inj_pres_303: InjPres303,
            Inj_spd_103: InjSpd103,
            Inj_spd_203: InjSpd203,
            Inj_spd_303: InjSpd303,
            Shot_size_203: ShotSize203,
            Short_fill_203: ShortFill203,
            Flash_203: Flash203,
            Flow_mark_203: FlowMark203,
            Srinkage_203: Srinkage203,
            Silver_streiks_203: SilverStreiks203,
            Weld_line_203: WeldLine203,
            Burn_mark_203: BurnMark203,
            Result_203: Result203,
            Remark_203: Remark203,

            Inj_pres_104: InjPres104,
            Inj_pres_204: InjPres204,
            Inj_pres_304: InjPres304,
            Inj_spd_104: InjSpd104,
            Inj_spd_204: InjSpd204,
            Inj_spd_304: InjSpd304,
            Shot_size_204: ShotSize204,
            Short_fill_204: ShortFill204,
            Flash_204: Flash204,
            Flow_mark_204: FlowMark204,
            Srinkage_204: Srinkage204,
            Silver_streiks_204: SilverStreiks204,
            Weld_line_204: WeldLine204,
            Burn_mark_204: BurnMark204,
            Result_204: Result204,
            Remark_204: Remark204,

            Inj_pres_105: InjPres105,
            Inj_pres_205: InjPres205,
            Inj_pres_305: InjPres305,
            Inj_spd_105: InjSpd105,
            Inj_spd_205: InjSpd205,
            Inj_spd_305: InjSpd305,
            Shot_size_205: ShotSize205,
            Short_fill_205: ShortFill205,
            Flash_205: Flash205,
            Flow_mark_205: FlowMark205,
            Srinkage_205: Srinkage205,
            Silver_streiks_205: SilverStreiks205,
            Weld_line_205: WeldLine205,
            Burn_mark_205: BurnMark205,
            Result_205: Result205,
            Remark_205: Remark205,

            Hold_pres_101: HoldPres101,
            Hold_spd_101: HoldSpd101,
            Inj_tme_101: InjTme101,
            Cool_tme_101: CoolTme101,
            Hold_tme_101: HoldTme101,
            Hydea_oil_temp_101: HydeaOilTemp101,
            MTC_101: MTC101,
            Short_fill_301: ShortFill301,
            Flash_301: Flash301,
            Flow_mark_301: FlowMark301,
            Srinkage_301: Srinkage301,
            Silver_streiks_301: SilverStreiks301,
            Weld_line_301: WeldLine301,
            Burn_mark_301: BurnMark301,
            Result_301: Result301,
            Remark_301: Remark301,

            Hold_pres_102: HoldPres102,
            Hold_spd_102: HoldSpd102,
            Inj_tme_102: InjTme102,
            Cool_tme_102: CoolTme102,
            Hold_tme_102: HoldTme102,
            Hydea_oil_temp_102: HydeaOilTemp102,
            MTC_102: MTC102,
            Short_fill_302: ShortFill302,
            Flash_302: Flash302,
            Flow_mark_302: FlowMark302,
            Srinkage_302: Srinkage302,
            Silver_streiks_302: SilverStreiks302,
            Weld_line_302: WeldLine302,
            Burn_mark_302: BurnMark302,
            Result_302: Result302,
            Remark_302: Remark302,

            Hold_pres_103: HoldPres103,
            Hold_spd_103: HoldSpd103,
            Inj_tme_103: InjTme103,
            Cool_tme_103: CoolTme103,
            Hold_tme_103: HoldTme103,
            Hydea_oil_temp_103: HydeaOilTemp103,
            MTC_103: MTC103,
            Short_fill_303: ShortFill303,
            Flash_303: Flash303,
            Flow_mark_303: FlowMark303,
            Srinkage_303: Srinkage303,
            Silver_streiks_303: SilverStreiks303,
            Weld_line_303: WeldLine303,
            Burn_mark_303: BurnMark303,
            Result_303: Result303,
            Remark_303: Remark303,

            Hold_pres_104: HoldPres104,
            Hold_spd_104: HoldSpd104,
            Inj_tme_104: InjTme104,
            Cool_tme_104: CoolTme104,
            Hold_tme_104: HoldTme104,
            Hydea_oil_temp_104: HydeaOilTemp104,
            MTC_104: MTC104,
            Short_fill_304: ShortFill304,
            Flash_304: Flash304,
            Flow_mark_304: FlowMark304,
            Srinkage_304: Srinkage304,
            Silver_streiks_304: SilverStreiks304,
            Weld_line_304: WeldLine304,
            Burn_mark_304: BurnMark304,
            Result_304: Result304,
            Remark_304: Remark304,

            Hold_pres_105: HoldPres105,
            Hold_spd_105: HoldSpd105,
            Inj_tme_105: InjTme105,
            Cool_tme_105: CoolTme105,
            Hold_tme_105: HoldTme105,
            Hydea_oil_temp_105: HydeaOilTemp105,
            MTC_105: MTC105,
            Short_fill_305: ShortFill305,
            Flash_305: Flash305,
            Flow_mark_305: FlowMark305,
            Srinkage_305: Srinkage305,
            Silver_streiks_305: SilverStreiks305,
            Weld_line_305: WeldLine305,
            Burn_mark_305: BurnMark305,
            Result_305: Result305,
            Remark_305: Remark305,

            Nozzle_before: NozzleBefore,
            Zone1_before: Zone1Before,
            Zone2_before: Zone2Before,
            Zone3_before: Zone3Before,
            Zone4_before: Zone4Before,
            Zone5_before: Zone5Before,
            Core_cavity_before: CoreCavityBefore,

            Nozzle_after: NozzleAfter,
            Zone1_after: Zone1After,
            Zone2_after: Zone2After,
            Zone3_after: Zone3After,
            Zone4_after: Zone4After,
            Zone5_after: Zone5After,
            Core_cavity_after: CoreCavityAfter,

            Inj_pres1_before: InjPres1Before,
            Inj_pres2_before: InjPres2Before,
            Inj_pres3_before: InjPres3Before,
            Inj_sped1_before: InjSped1Before,
            Inj_sped2_before: InjSped2Before,
            Inj_sped3_before: InjSped3Before,
            Shot_size_before: ShotSizeBefore,

            Inj_pres1_after: InjPres1After,
            Inj_pres2_after: InjPres2After,
            Inj_pres3_after: InjPres3After,
            Inj_sped1_after: InjSped1After,
            Inj_sped2_after: InjSped2After,
            Inj_sped3_after: InjSped3After,
            Shot_size_after: ShotSizeAfter,

            Hold_pres_before: HoldPresBefore,
            Hold_spd_before: HoldSpdBefore,
            Inj_tme_before: InjTmeBefore,
            Cool_tme_before: CoolTmeBefore,
            Hld_tme_before: HldTmeBefore,
            Hydra_oil_tmp_before: HydraOilTmpBefore,
            Mtc_before: MtcBefore,

            Hold_pres_after: HoldPresAfter,
            Hold_spd_after: HoldSpdAfter,
            Inj_tme_after: InjTmeAfter,
            Cool_tme_after: CoolTmeAfter,
            Hld_tme_after: HldTmeAfter,
            Hydra_oil_temp_after: HydraOilTempAfter,
            Mtc_after: MtcAfter,

            Validation: Validation.value,
            Prepared: Prepared.value,
            Approved: Approved.value,
            Note: Note,
            Nozz_set: NozzSet,
            Zne_set1: ZneSet1,
            Zne_set2: ZneSet2,
            Zne_set3: ZneSet3,
            Zne_set4: ZneSet4,
            Zne_set5: ZneSet5,
            Inj_pres_set: InjPresSet,
            Inj_spd_set: InjSpdSet,
            Hold_pres_set: HoldPresSet,
            Hold_spd_set: HoldSpdSet,
            Inj_tme_set: InjTmeSet,
            Cool_tme_set: CoolTmeSet,
            Hold_tme_set: HoldTmeSet,
            Oil_set: OilSet,
            Mtc_set: MtcSet,
          },
        ],
        _keyword_: "Pro_validation_revalidation",
        secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
      };

      const response = await axios.post(
        `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
        data
      );

      if (response.data.success === "false") {
        alert("Problem in Saving Machine details");
        window.location.reload();
      } else {
        setmat_load([...mat_load, response.data]);
        alert(`${MachineNo.label} Updated`);
        console.log("setting msg");
        console.log(response);
        setMessage({
          NoteFrom: localStorage.getItem("empId"),
          NoteTo: Validation.value,
          NoteMessage: `The Process validation Has been Created By ${localStorage.getItem(
            "empId"
          )}`,
          NoteRecordId: Id,
          TableName: "ProcessValidation",
          NoteCreatedBy: localStorage.getItem("empId"),
        });
        // setMessage({
        //   NoteFrom: localStorage.getItem("empId"),
        //   NoteTo: Prepared.value,
        //   NoteMessage: `The Process validation Has been Created By ${localStorage.getItem("empId")}`,
        //   NoteRecordId: response.ROWID,
        //   TableName: "ProcessValidation",
        //   NoteCreatedBy: localStorage.getItem("empId"),

        // });
        setMessage({
          NoteFrom: Prepared.value,
          NoteTo: Validation.value,
          NoteMessage: `The Process validation Has been Created By ${Prepared.value}-${Prepared.label}, which is conducted by ${ConductedBy.value}-${ConductedBy.label} Please do Validate`,
          NoteRecordId: Id,
          TableName: "ProcessValidation",
          NoteCreatedBy: localStorage.getItem("empId"),
        });
        // setMessage({
        //   NoteFrom: Prepared.value,
        //   NoteTo: Prepared.value,
        //   NoteMessage: `The Process validation Has been Created By ${localStorage.getItem("empId")}`,
        //   NoteRecordId: response.data.ROWID,
        //   TableName: "ProcessValidation",
        //   NoteCreatedBy: localStorage.getItem("empId"),

        // });
        setMessage({
          NoteFrom: Prepared.value,
          NoteTo: Approved.value,
          NoteMessage: `The Process validation Has been Created By ${Prepared.value}-${Prepared.label}, which is conducted by ${ConductedBy.value}-${ConductedBy.label} Please approve the form`,
          NoteRecordId: Id,
          TableName: "ProcessValidation",
          NoteCreatedBy: localStorage.getItem("empId"),
        });
        CreateApproval({
          RequestedBy: Prepared.value,
          Approver: Validation.value,
          Level: 1,
          Status: "Waiting for Approval",
          TableName: "ProcessValidation",
          TableID: Id,
          Description: `The Process validation Has been Created By ${Prepared.value}-${Prepared.label}, which is conducted by ${ConductedBy.value}-${ConductedBy.label} Please validate the form`,
          CreatedBy: localStorage.getItem("empId"),
        });
        CreateApproval({
          RequestedBy: Prepared.value,
          Approver: Approved.value,
          Level: 2,
          Status: "Waiting for Approval",
          TableName: "ProcessValidation",
          TableID: Id,
          Description: `The Process validation Has been Created By ${Prepared.value}-${Prepared.label}, which is conducted by ${ConductedBy.value}-${ConductedBy.label} Please approve the form`,
          CreatedBy: localStorage.getItem("empId"),
        });
      }
      // if (isSubmissionSuccessful) {
      //   alert(`${MachineNo.label} Updated`);
      // }
      navigate(-1)
    } catch (error) {
      console.log(error);
      alert("Submission failed.");
    }
    window.location.reload();
  };

  useEffect(() => {
    if (IsCreating1){
    // if (PartName.value !== "" && MachineNo.value !== "") {
      axios
        .post(`http://${API_URL}/GenericResultBuilderService/buildResults`, {
          _keyword_: "Get_SMP_by_Partname_Machine_processval",
          Partname: PartNo.value,
          Machineno: MachineNo.value,
        })
        .then((response) => {
          if (response.data.data.length > 0) {
            console.log(
              "Response Data.data[0]111",
              response.data.data,
              "temperatur",
              response.data.data[0].temperature_function
            );
            setNozzleT101(
              response.data.data[0].temperature_function[0].Temp_value
            );
            setZone101(
              response.data.data[0].temperature_function[2].Temp_value
            );
            setZone201(
              response.data.data[0].temperature_function[3].Temp_value
            );
            setZone301(
              response.data.data[0].temperature_function[4].Temp_value
            );
            setZone401(
              response.data.data[0].temperature_function[5].Temp_value
            );
            setZone501(
              response.data.data[0].temperature_function[6].Temp_value
            );
            // setZone101(response.data.data[0].Zone1);
            // setZone201(response.data.data[0].Zone2);
            // setZone301(response.data.data[0].Zone3);
            // setZone401(response.data.data[0].Zone4);
            // setZone501(response.data.data[0].Zone5);

            setCoreCavity101(
              response.data.data[0].mouldtempdetails_function[0]
                .Mould_temp_value +
                "/" +
                response.data.data[0].mouldtempdetails_function[1]
                  .Mould_temp_value
            );
            // setCoreCavity101(response.data.data[0].Core);
            setInjPres101(response.data.data[0].injection_functional[1].Injection_pressure);
            setInjPres201(response.data.data[0].injection_functional[2].Injection_pressure);
            setInjPres301(response.data.data[0].injection_functional[3].Injection_pressure);
            setInjSpd101(response.data.data[0].injection_functional[1].Injection_speed);
            setInjSpd201(response.data.data[0].injection_functional[2].Injection_speed);
            setInjSpd301(response.data.data[0].injection_functional[3].Injection_speed);
            setShotSize201(response.data.data[0].refilsetting_functional[3].Refilsetting_position);
            setHoldPres101(response.data.data[0].holding_functional[1].Holding_pressure);
            setHoldSpd101(response.data.data[0].holding_functional[1].Holding_speed);
            setInjTme101(response.data.data[0].injection_functional[1].Injection_time);
            setCoolTme101(response.data.data[0].backpres_function[4].Back_press_time);
            setHoldTme101(response.data.data[0].holding_functional[1].Holding_time);
            setHydeaOilTemp101(response.data.data[0].backpres_function[6].Back_press_time);
            setMTC101(response.data.data[0].mouldtempdetails_function[2].Mould_temp_value);
            setNozzleBefore(response.data.data[0].temperature_function[0].Temp_value);
            // setNozzleBefore(response.data.data[0].Hozz);
            setZone1Before(response.data.data[0].temperature_function[2].Temp_value);
            setZone2Before(response.data.data[0].temperature_function[3].Temp_value);
            setZone3Before(response.data.data[0].temperature_function[4].Temp_value);
            setZone4Before(response.data.data[0].temperature_function[5].Temp_value);
            setZone5Before(response.data.data[0].temperature_function[6].Temp_value);
            setCoreCavityBefore(response.data.data[0].mouldtempdetails_function[0]
              .Mould_temp_value +
              "/" +
              response.data.data[0].mouldtempdetails_function[1]
                .Mould_temp_value);
            setInjPres1Before(response.data.data[0].injection_functional[1].Injection_pressure);
            setInjPres2Before(response.data.data[0].injection_functional[2].Injection_pressure);
            setInjPres3Before(response.data.data[0].injection_functional[3].Injection_pressure);
            setInjSped1Before(response.data.data[0].injection_functional[1].Injection_speed);
            setInjSped2Before(response.data.data[0].injection_functional[2].Injection_speed);
            setInjSped3Before(response.data.data[0].injection_functional[3].Injection_speed);
            setShotSizeBefore(response.data.data[0].refilsetting_functional[3].Refilsetting_position);
            setHoldPresBefore(response.data.data[0].holding_functional[1].Holding_pressure);
            setHoldSpdBefore(response.data.data[0].holding_functional[1].Holding_speed);
            setInjTmeBefore(response.data.data[0].injection_functional[1].Injection_time);
            setCoolTmeBefore(response.data.data[0].backpres_function[4].Back_press_time);
            setHldTmeBefore(response.data.data[0].holding_functional[1].Holding_time);
            setHydraOilTmpBefore(response.data.data[0].backpres_function[6].Back_press_time);
            setMtcBefore(response.data.data[0].mouldtempdetails_function[2].Mould_temp_value);
            setModel(response.data.data[0].Model);

            setInjPresSet(
              response.data.data[0].injection_functional[0].Injection_pressure == ""
                ? null
                : response.data.data[0].injection_functional[0].Injection_pressure
                
            );
            setInjSpdSet(
              response.data.data[0].injection_functional[0].Injection_speed === ""? null
                : response.data.data[0].injection_functional[0].Injection_speed                
            );
            setNozzSet(
              response.data.data[0].temperature_function[0].Temp_set === ""? null
                : response.data.data[0].temperature_function[0].Temp_set);
            setZneSet1(
              response.data.data[0].temperature_function[2].Temp_set === ""? null
                : response.data.data[0].temperature_function[2].Temp_set);
            setZneSet2(
              response.data.data[0].temperature_function[3].Temp_set === ""? null
                : response.data.data[0].temperature_function[3].Temp_set);
            setZneSet3(
              response.data.data[0].temperature_function[4].Temp_set === ""? null
                : response.data.data[0].temperature_function[4].Temp_set
            );
            setZneSet4(
              response.data.data[0].temperature_function[5].Temp_set === ""? null
                : response.data.data[0].temperature_function[5].Temp_set
            );
            setZneSet5(
              response.data.data[0].temperature_function[6].Temp_set === ""? null
                : response.data.data[0].temperature_function[6].Temp_set
            );

            setHoldPresSet(
              response.data.data[0].holding_functional[0].Holding_pressure === ""? null
                : response.data.data[0].holding_functional[0].Holding_pressure
            );
            setHoldSpdSet(
              response.data.data[0].holding_functional[0].Holding_speed === ""
                ? null
                : response.data.data[0].holding_functional[0].Holding_speed
            );
            setInjTmeSet(
              response.data.data[0].injection_functional[0].Injection_time === ""? null
              : response.data.data[0].injection_functional[0].Injection_time);
            setCoolTmeSet(response.data.data[0].backpres_function[4].Back_press_set);
            setHoldTmeSet( response.data.data[0].holding_functional[0].Holding_time=== ""
              ? null
              : response.data.data[0].holding_functional[0].Holding_time
          );
              
              // response.data.data[0].InjTmeSet);
            setOilSet(
              response.data.data[0].backpres_function[6].Back_press_set
            );
            setMtcSet(
              response.data.data[0].mouldtempdetails_function[2].Mould_temp_set === ""
                ? null
                : response.data.data[0].mouldtempdetails_function[2].Mould_temp_set
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [MachineNo.value, PartNo.value]);

  useEffect(() => {
    // const isDuplicateCode = await IsDuplicate("pro_validation_revalidarion", [`Machine_no="${MachineNo.value}"`,`ActiveStatus = 'Active'`]);
  
    // if (isDuplicateCode) {
    //   alert(`machine with part ${MachineNo.value} already exists.`);
    // setUpdateApi(true)
    // }

    // const isDuplicateName = await IsDuplicate("pro_validation_revalidarion", [`Part_name="${PartNo.value}"`,`ActiveStatus = 'Active'`]);

    // if (isDuplicateName) {
    //   alert(`Part with machine ${PartNo.value} already exists.`);
    //   setUpdateApi(true)
    // }
    // alert( MachineNo.value && PartNo.value)
    console.log(MachineNo.value !=="" && PartNo.value !=="");
    if (MachineNo.value && PartNo.value) {
      axios
        .post(`http://${API_URL}/GenericResultBuilderService/buildResults`, {
          _keyword_: "pro_by_part_pro_validation",
          mc_part_name:PartNo.value,
          McNo: MachineNo.value,
        })
        .then((response) => {
          console.log("re3333");
          console.log(response.data);
          if (response.data.data.length > 0) {
            console.log("Response Data.data[0]", response.data.data[0]);
            setId(response.data.data[0].Id);
            setModel(response.data.data[0].Model);
            setReason(response.data.data[0].Reason);
            setCustomerName({
              value: response.data.data[0].Customer,
              label: response.data.data[0].CustomerL,
            });
            setConductedBy({
              value: response.data.data[0].Conducted_by,
              label: response.data.data[0].ConductedBy,
            });

            setShortFill101(response.data.data[0].Short_fill_101);
            setFlash101(response.data.data[0].Flash_101);
            setFlowMark101(response.data.data[0].Flow_mark_101);
            setSrinkage101(response.data.data[0].Srinkage_101);
            setSilverStreiks101(response.data.data[0].Silver_streiks_101);
            setWeldLine101(response.data.data[0].Weld_line_101);
            setBurnMark101(response.data.data[0].Burn_mark_101);
            setResult101(response.data.data[0].Result_101);

            setNozzleT102(response.data.data[0].Nozzle_t_102);
            setZone102(response.data.data[0].Zone_102);
            setZone202(response.data.data[0].Zone_202);
            setZone302(response.data.data[0].Zone_302);
            setZone402(response.data.data[0].Zone_402);
            setZone502(response.data.data[0].Zone_502);
            setCoreCavity102(response.data.data[0].Core_cavity_102);

            setShortFill102(response.data.data[0].Short_fill_102);
            setFlash102(response.data.data[0].Flash_102);
            setFlowMark102(response.data.data[0].Flow_mark_102);
            setSrinkage102(response.data.data[0].Srinkage_102);
            setSilverStreiks102(response.data.data[0].Silver_streiks_102);
            setWeldLine102(response.data.data[0].Weld_line_102);
            setBurnMark102(response.data.data[0].Burn_mark_102);
            setResult102(response.data.data[0].Result_102);

            setNozzleT103(response.data.data[0].Nozzle_t_103);
            setZone103(response.data.data[0].Zone_103);
            setZone203(response.data.data[0].Zone_203);
            setZone303(response.data.data[0].Zone_303);
            setZone403(response.data.data[0].Zone_403);
            setZone503(response.data.data[0].Zone_503);
            setCoreCavity103(response.data.data[0].Core_cavity_103);

            setShortFill103(response.data.data[0].Short_fill_103);
            setFlash103(response.data.data[0].Flash_103);
            setFlowMark103(response.data.data[0].Flow_mark_103);
            setSrinkage103(response.data.data[0].Srinkage_103);
            setSilverStreiks103(response.data.data[0].Silver_streiks_103);
            setWeldLine103(response.data.data[0].Weld_line_103);
            setBurnMark103(response.data.data[0].Burn_mark_103);
            setResult103(response.data.data[0].Result_103);

            setNozzleT104(response.data.data[0].Nozzle_t_104);
            setZone104(response.data.data[0].Zone_104);
            setZone204(response.data.data[0].Zone_204);
            setZone304(response.data.data[0].Zone_304);
            setZone404(response.data.data[0].Zone_404);
            setZone504(response.data.data[0].Zone_504);
            setCoreCavity104(response.data.data[0].Core_cavity_104);

            setShortFill104(response.data.data[0].Short_fill_104);
            setFlash104(response.data.data[0].Flash_104);
            setFlowMark104(response.data.data[0].Flow_mark_104);
            setSrinkage104(response.data.data[0].Srinkage_104);
            setSilverStreiks104(response.data.data[0].Silver_streiks_104);
            setWeldLine104(response.data.data[0].Weld_line_104);
            setBurnMark104(response.data.data[0].Burn_mark_104);
            setResult104(response.data.data[0].Result_104);

            setNozzleT105(response.data.data[0].Nozzle_t_105);
            setZone105(response.data.data[0].Zone_105);
            setZone205(response.data.data[0].Zone_205);
            setZone305(response.data.data[0].Zone_305);
            setZone405(response.data.data[0].Zone_405);
            setZone505(response.data.data[0].Zone_505);
            setCoreCavity105(response.data.data[0].Core_cavity_105);

            setShortFill105(response.data.data[0].Short_fill_105);
            setFlash105(response.data.data[0].Flash_105);
            setFlowMark105(response.data.data[0].Flow_mark_105);
            setSrinkage105(response.data.data[0].Srinkage_105);
            setSilverStreiks105(response.data.data[0].Silver_streiks_105);
            setWeldLine105(response.data.data[0].Weld_line_105);
            setBurnMark105(response.data.data[0].Burn_mark_105);
            setResult105(response.data.data[0].Result_105);

            setShortFill201(response.data.data[0].Short_fill_201);
            setFlash201(response.data.data[0].Flash_201);
            setFlowMark201(response.data.data[0].Flow_mark_201);
            setSrinkage201(response.data.data[0].Srinkage_201);
            setSilverStreiks201(response.data.data[0].Silver_streiks_201);
            setWeldLine201(response.data.data[0].Weld_line_201);
            setBurnMark201(response.data.data[0].Burn_mark_201);
            setResult201(response.data.data[0].Result_201);

            setInjPres102(response.data.data[0].Inj_pres_102);
            setInjPres202(response.data.data[0].Inj_pres_202);
            setInjPres302(response.data.data[0].Inj_pres_302);
            setInjSpd102(response.data.data[0].Inj_spd_102);
            setInjSpd202(response.data.data[0].Inj_spd_202);
            setInjSpd302(response.data.data[0].Inj_spd_302);

            setShotSize202(response.data.data[0].Shot_size_202);
            setShortFill202(response.data.data[0].Short_fill_202);
            setFlash202(response.data.data[0].Flash_202);
            setFlowMark202(response.data.data[0].Flow_mark_202);
            setSrinkage202(response.data.data[0].Srinkage_202);
            setSilverStreiks202(response.data.data[0].Silver_streiks_202);
            setWeldLine202(response.data.data[0].Weld_line_202);
            setBurnMark202(response.data.data[0].Burn_mark_202);
            setResult202(response.data.data[0].Result_202);

            setInjPres103(response.data.data[0].Inj_pres_103);
            setInjPres203(response.data.data[0].Inj_pres_203);
            setInjPres303(response.data.data[0].Inj_pres_303);
            setInjSpd103(response.data.data[0].Inj_spd_103);
            setInjSpd203(response.data.data[0].Inj_spd_203);
            setInjSpd303(response.data.data[0].Inj_spd_303);

            setShotSize203(response.data.data[0].Shot_size_203);
            setShortFill203(response.data.data[0].Short_fill_203);
            setFlash203(response.data.data[0].Flash_203);
            setFlowMark203(response.data.data[0].Flow_mark_203);
            setSrinkage203(response.data.data[0].Srinkage_203);
            setSilverStreiks203(response.data.data[0].Silver_streiks_203);
            setWeldLine203(response.data.data[0].Weld_line_203);
            setBurnMark203(response.data.data[0].Burn_mark_203);
            setResult203(response.data.data[0].Result_203);

            setInjPres104(response.data.data[0].Inj_pres_104);
            setInjPres204(response.data.data[0].Inj_pres_204);
            setInjPres304(response.data.data[0].Inj_pres_304);
            setInjSpd104(response.data.data[0].Inj_spd_104);
            setInjSpd204(response.data.data[0].Inj_spd_204);
            setInjSpd304(response.data.data[0].Inj_spd_304);

            setShotSize204(response.data.data[0].Shot_size_204);
            setShortFill204(response.data.data[0].Short_fill_204);
            setFlash204(response.data.data[0].Flash_204);
            setFlowMark204(response.data.data[0].Flow_mark_204);
            setSrinkage204(response.data.data[0].Srinkage_204);
            setSilverStreiks204(response.data.data[0].Silver_streiks_204);
            setWeldLine204(response.data.data[0].Weld_line_204);
            setBurnMark204(response.data.data[0].Burn_mark_204);
            setResult204(response.data.data[0].Result_204);

            setInjPres105(response.data.data[0].Inj_pres_105);
            setInjPres205(response.data.data[0].Inj_pres_205);
            setInjPres305(response.data.data[0].Inj_pres_305);
            setInjSpd105(response.data.data[0].Inj_spd_105);
            setInjSpd205(response.data.data[0].Inj_spd_205);
            setInjSpd305(response.data.data[0].Inj_spd_305);

            setShotSize205(response.data.data[0].Shot_size_205);
            setShortFill205(response.data.data[0].Short_fill_205);
            setFlash205(response.data.data[0].Flash_205);
            setFlowMark205(response.data.data[0].Flow_mark_205);
            setSrinkage205(response.data.data[0].Srinkage_205);
            setSilverStreiks205(response.data.data[0].Silver_streiks_205);
            setWeldLine205(response.data.data[0].Weld_line_205);
            setBurnMark205(response.data.data[0].Burn_mark_205);
            setResult205(response.data.data[0].Result_205);

            setShortFill301(response.data.data[0].Short_fill_301);
            setFlash301(response.data.data[0].Flash_301);
            setFlowMark301(response.data.data[0].Flow_mark_301);
            setSrinkage301(response.data.data[0].Srinkage_301);
            setSilverStreiks301(response.data.data[0].Silver_streiks_301);
            setWeldLine301(response.data.data[0].Weld_line_301);
            setBurnMark301(response.data.data[0].Burn_mark_301);
            setResult301(response.data.data[0].Result_301);

            setHoldPres102(response.data.data[0].Hold_pres_102);
            setHoldSpd102(response.data.data[0].Hold_spd_102);
            setInjTme102(response.data.data[0].Inj_tme_102);
            setCoolTme102(response.data.data[0].Cool_tme_102);
            setHoldTme102(response.data.data[0].Hold_tme_102);
            setHydeaOilTemp102(response.data.data[0].Hydea_oil_temp_102);
            setMTC102(response.data.data[0].MTC_102);

            setShortFill302(response.data.data[0].Short_fill_302);
            setFlash302(response.data.data[0].Flash_302);
            setFlowMark302(response.data.data[0].Flow_mark_302);
            setSrinkage302(response.data.data[0].Srinkage_302);
            setSilverStreiks302(response.data.data[0].Silver_streiks_302);
            setWeldLine302(response.data.data[0].Weld_line_302);
            setBurnMark302(response.data.data[0].Burn_mark_302);
            setResult302(response.data.data[0].Result_302);

            setHoldPres103(response.data.data[0].Hold_pres_103);
            setHoldSpd103(response.data.data[0].Hold_spd_103);
            setInjTme103(response.data.data[0].Inj_tme_103);
            setCoolTme103(response.data.data[0].Cool_tme_103);
            setHoldTme103(response.data.data[0].Hold_tme_103);
            setHydeaOilTemp103(response.data.data[0].Hydea_oil_temp_103);
            setMTC103(response.data.data[0].MTC_103);

            setShortFill303(response.data.data[0].Short_fill_303);
            setFlash303(response.data.data[0].Flash_303);
            setFlowMark303(response.data.data[0].Flow_mark_303);
            setSrinkage303(response.data.data[0].Srinkage_303);
            setSilverStreiks303(response.data.data[0].Silver_streiks_303);
            setWeldLine303(response.data.data[0].Weld_line_303);
            setBurnMark303(response.data.data[0].Burn_mark_303);
            setResult303(response.data.data[0].Result_303);

            setHoldPres104(response.data.data[0].Hold_pres_104);
            setHoldSpd104(response.data.data[0].Hold_spd_104);
            setInjTme104(response.data.data[0].Inj_tme_104);
            setCoolTme104(response.data.data[0].Cool_tme_104);
            setHoldTme104(response.data.data[0].Hold_tme_104);
            setHydeaOilTemp104(response.data.data[0].Hydea_oil_temp_104);
            setMTC104(response.data.data[0].MTC_104);

            setShortFill304(response.data.data[0].Short_fill_304);
            setFlash304(response.data.data[0].Flash_304);
            setFlowMark304(response.data.data[0].Flow_mark_304);
            setSrinkage304(response.data.data[0].Srinkage_304);
            setSilverStreiks304(response.data.data[0].Silver_streiks_304);
            setWeldLine304(response.data.data[0].Weld_line_304);
            setBurnMark304(response.data.data[0].Burn_mark_304);
            setResult304(response.data.data[0].Result_304);

            setHoldPres105(response.data.data[0].Hold_pres_105);
            setHoldSpd105(response.data.data[0].Hold_spd_105);
            setInjTme105(response.data.data[0].Inj_tme_105);
            setCoolTme105(response.data.data[0].Cool_tme_105);
            setHoldTme105(response.data.data[0].Hold_tme_105);
            setHydeaOilTemp105(response.data.data[0].Hydea_oil_temp_105);
            setMTC105(response.data.data[0].MTC_105);

            setShortFill305(response.data.data[0].Short_fill_305);
            setFlash305(response.data.data[0].Flash_305);
            setFlowMark305(response.data.data[0].Flow_mark_305);
            setSrinkage305(response.data.data[0].Srinkage_305);
            setSilverStreiks305(response.data.data[0].Silver_streiks_305);
            setWeldLine305(response.data.data[0].Weld_line_305);
            setBurnMark305(response.data.data[0].Burn_mark_305);
            setResult305(response.data.data[0].Result_305);

            setValidation({
              value: response.data.data[0].Validation,
              label: response.data.data[0].ValidationL,
            });
            setPrepared({
              value: response.data.data[0].Approved,
              label: response.data.data[0].PreparedL,
            });
            setApproved({
              value: response.data.data[0].P,
              label: response.data.data[0].ApprovedL,
            });
            setPro_Val(true);
            console.log(true);
            console.log("call Update API");
          } else {
            setPro_Val(false);
            console.log(false);
            console.log("call create API");
            setId(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [MachineNo.value, PartNo.value]);

  const handleMachine = (selectedOption) => {
    const selected = dropdown.find(
      (option) => option.McNo === selectedOption.value
    );
    if (selected) {
      setMachineNo({ value: selected.McNo, label: selected.mc_name });
      getPartDropdown(selected.McNo);
    }
  };

  const handlepart = (selectedOption) => {
    const selected = partDropdown.find(
      (option) => option.PrtNme === selectedOption.value
    );

    if (selected) {
      setPartName(selected.PartNo);
      setPartNo({ value: selected.PrtNme, label: selected.PrtNme });
    }
  };
  const handleCustomer = (selectedOption) => {
    const selected = Customer.find(
      (option) => option.Cus_code === selectedOption.value
    );

    if (selected) {
      setCustomerName({
        value: selected.Cus_code,
        label: selected.Cus_name,
      });
    }
  };
  const handleConductedBy = (selectedOption) => {
    const selected = Employee.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setConductedBy({
        value: selected.Emp_code,
        label: selected.Emp_name,
      });
    }
  };
  const handlePrepared = (selectedOption) => {
    const selected = Employee.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setPrepared({
        value: selected.Emp_code,
        label: selected.Emp_name,
      });
    }
  };
  const handleValidation = (selectedOption) => {
    const selected = Employee.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setValidation({
        value: selected.Emp_code,
        label: selected.Emp_name,
      });
    }
  };
  const handleApproved = (selectedOption) => {
    const selected = Employee.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setApproved({
        value: selected.Emp_code,
        label: selected.Emp_name,
      });
    }
  };
  // barcode scannning to get input
  useScanDetection({
    onComplete: (code) => {
      code = code.replaceAll("Shift", "");
      setField(code);
    },
    minLength: 3,

    // preventDefault: true,
  });

  // set value to the appropiate field
  function setField(code) {
    // let isnumber = Number(code).toString() !== "NaN";
    let current_element = document.activeElement.id;

    if (code.includes("EMP")) {
      handlePrepared({ value: code });
    } else if (code.includes("RMI")) {
      alert("Invalid Scan");
      return false;
    } else if (code.includes("CUS")) {
      alert("Invalid Scan");
      return false;
    } else if (code.includes("FG")) {
      alert("Invalid Scan");
      return false;
    } else if (code.includes("IMM")) {
      if (
        current_element === "" ||
        current_element === "react-select-2-input"
      ) {
        handleMachine({ value: code });
      } else {
        alert("Invalid Scan");
        return false;
      }
    } else if (code.includes("SUP")) {
      alert("Invalid Scan");
      return false;
    } else {
      MachineNo && handlepart({ value: code });
    }
  }

  useEffect(() => {
    handleDateChange(new Date().toISOString().split("T")[0]);
    document.title = "Process Validation and Re-Validation";
  }, []);

  return (
    <div>
      <form
        id="ProcessValidationAndReValidation-formContainer-Create"
        name="ProcessValidationAndReValidation-formContainer-Create"
        className="form-ProcessValidationAndReValidation-Create"
      >
        <div ref={scrollTop}>
          <table
            className="table"
            style={{ textAlign: "center", width: "100%" }}
          >
            <tbody>
              <tr>
                {/* <th colSpan={2} style={{ textAlign: "center", width: "5%" }}>
                  <img src={Unitechlogo} style={{ width: "70px" }}></img>
                </th> */}
                <th
                  id={`${id}-Title`}
                  name={`${name}-Title`}
                  colSpan="17"
                  style={{
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    padding: "10px 0px",
                  }}
                >
                  {/* <font style={{ fontSize: "24px" }}>
                    UNITECH PLASTO COMPONENTS (P) LTD.,
                  </font> */}
                  <br></br>
                  <font color="#8B0000">
                    Process Validation / Re-Validation Report
                  </font>
                </th>
              </tr>
              <tr>
                <td colSpan={3} className="topic-head">
                  Machine No:<span style={{ color: "red" }}>*</span>
                </td>
                <td colSpan={3}>
                  <Select
                    id="dropdown"
                    options={mcNoOptions}
                    value={MachineNo}
                    onChange={handleMachine}
                    placeholder="Select Machine No"
                  />
                  {MachineNo === "" && isCreating && (
                    <span className="Req">Required *</span>
                  )}
                </td>
                <td colSpan={3} className="topic-head">
                  Part Name:<span style={{ color: "red" }}>*</span>
                </td>
                <td colSpan={3}>
                  <Select
                    id="partdropdown"
                    options={prtNmeOptions}
                    value={PartNo}
                    onChange={handlepart}
                    placeholder="Select Part Name"
                  />

                  {PartNo === "" && isCreating && (
                    <span className="Req">Required *</span>
                  )}
                </td>
                <td colSpan={3} className="topic-head">
                  Part No:
                </td>
                <td colSpan={3}>
                  <input
                    id={`${id}-PartNo`}
                    name={`${name}-PartNo`}
                    type="text"
                    value={PartName}
                    onChange={(e) => setPartName(e.target.value)}
                  />
                </td>
              </tr>

              <tr>
                <td colSpan={3} className="topic-head">
                  Model:
                </td>
                <td colSpan={3}>
                  <input
                    id={`${id}-Model`}
                    name={`${name}-Model`}
                    type="text"
                    value={Model}
                    disabled={true}
                    onChange={(e) => setModel(e.target.value)}
                  />
                </td>
                <td colSpan={3} className="topic-head">
                  Process:
                </td>
                <td colSpan={3}>
                  <input
                    id={`${id}-Process`}
                    name={`${name}-Process`}
                    type="text"
                    value={Process}
                    onChange={(e) => setProcess(e.target.value)}
                  />
                </td>
                <td colSpan={3} className="topic-head">
                  Reason:<span style={{ color: "red" }}>*</span>
                </td>
                <td colSpan={3}>
                  <input
                    id={`${id}-Reason`}
                    name={`${name}-Reason`}
                    type="text"
                    value={Reason}
                    onChange={(e) => setReason(e.target.value)}
                  />
                  {Reason === "" && isCreating && (
                    <span className="Req">Required *</span>
                  )}
                </td>
              </tr>

              <tr>
                <td colSpan={3} className="topic-head">
                  Date:
                </td>
                <td colSpan={3}>
                  <DatePicker
                    id={`${id}-TrackDate`}
                    name={`${name}-TrackDate`}
                    value={TrackDate}
                    onChange={handleDateChange}
                    dis={true}
                  />
                </td>

                <td colSpan={3} className="topic-head">
                  Customer:<span style={{ color: "red" }}>*</span>
                </td>
                <td colSpan={3}>
                  <DropdownFetcher
                    keyword="customer_details"
                    setValue={setCustomer}
                  />
                  <Select
                    value={customerName}
                    onChange={handleCustomer}
                    options={Customer.map((i) => ({
                      value: i.Cus_code,
                      label: i.Cus_name,
                    }))}
                  />
                  {customerName === "" && isCreating && (
                    <span className="Req">Required *</span>
                  )}
                </td>

                <td colSpan={3} className="topic-head">
                  Conducted By:<span style={{ color: "red" }}>*</span>
                </td>
                <td colSpan={3}>
                  <DropdownFetcher
                    keyword="emp_details"
                    setValue={setEmployee}
                  />
                  <Select
                    styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                    value={ConductedBy}
                    onChange={handleConductedBy}
                    options={Employee.map((i) => ({
                      value: i.Emp_code,
                      label: i.Emp_name,
                    }))}
                  />
                  {ConductedBy === "" && isCreating && (
                    <span className="Req">Required *</span>
                  )}
                </td>
              </tr>
              <tr>
                <th rowSpan={3}>Trail No:</th>

                <th colSpan={7}> PROCESS PARAMETER</th>

                <th colSpan={7}>OBSERVATION</th>
                <th rowSpan={3}>Result</th>
                <th rowSpan={3}>Remarks</th>
              </tr>
              <tr>
                <th colSpan={6}>Barrel Temperature</th>
                <th>Mould</th>
                <th rowSpan={2}>Short Fill</th>
                <th rowSpan={2}>Flash</th>
                <th rowSpan={2}>Flow Mark</th>
                <th rowSpan={2}>Srinkage</th>
                <th rowSpan={2}>Silver Streiks</th>
                <th rowSpan={2}>Weld Line</th>
                <th rowSpan={2}>Burn Mark</th>
              </tr>
              <tr>
                <td className="topic-head-trail">Nozzle</td>
                <td className="topic-head-trail">Zone 1</td>
                <td className="topic-head-trail">Zone 2</td>
                <td className="topic-head-trail">Zone 3</td>
                <td className="topic-head-trail">Zone 4</td>
                <td className="topic-head-trail">Zone 5</td>
                <td className="topic-head-trail">Core/Cavity</td>
              </tr>
              <tr>
                <td className="topic-head">T1</td>
                <td>
                  <input
                    id={`${id}-NozzleT101`}
                    name={`${name}-NozzleT101`}
                    type="text"
                    value={NozzleT101}
                    onChange={(e) => setNozzleT101(e.target.value)}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone101`}
                    name={`${name}-Zone101`}
                    type="text"
                    value={Zone101}
                    onChange={(e) => setZone101(e.target.value)}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone201`}
                    name={`${name}-Zone201`}
                    type="text"
                    value={Zone201}
                    onChange={(e) => setZone201(e.target.value)}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone301`}
                    name={`${name}-Zone301`}
                    type="text"
                    value={Zone301}
                    onChange={(e) => setZone301(e.target.value)}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone401`}
                    name={`${name}-Zone401`}
                    type="text"
                    value={Zone401}
                    onChange={(e) => setZone401(e.target.value)}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone501`}
                    name={`${name}-Zone501`}
                    type="text"
                    value={Zone501}
                    onChange={(e) => setZone501(e.target.value)}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    id={`${id}-CoreCavity101`}
                    name={`${name}-CoreCavity101`}
                    type="text"
                    value={CoreCavity101}
                    onChange={(e) => setCoreCavity101(e.target.value)}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    id={`${id}-ShortFill101`}
                    name={`${name}-ShortFill101`}
                    type="text"
                    value={ShortFill101}
                    onChange={(e) => setShortFill101(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Flash101`}
                    name={`${name}-Flash101`}
                    type="text"
                    value={Flash101}
                    onChange={(e) => setFlash101(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-FlowMark101`}
                    name={`${name}-FlowMark101`}
                    type="text"
                    value={FlowMark101}
                    onChange={(e) => setFlowMark101(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Srinkage101`}
                    name={`${name}-Srinkage101`}
                    type="text"
                    value={Srinkage101}
                    onChange={(e) => setSrinkage101(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-SilverStreiks101`}
                    name={`${name}-SilverStreiks101`}
                    type="text"
                    value={SilverStreiks101}
                    onChange={(e) => setSilverStreiks101(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-WeldLine101`}
                    name={`${name}-WeldLine101`}
                    type="text"
                    value={WeldLine101}
                    onChange={(e) => setWeldLine101(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-BurnMark101`}
                    name={`${name}-BurnMark101`}
                    type="text"
                    value={BurnMark101}
                    onChange={(e) => setBurnMark101(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Result101`}
                    name={`${name}-Result101`}
                    type="text"
                    value={Result101}
                    onChange={(e) => setResult101(e.target.value)}
                  />
                </td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-Remark101`}
                    name={`${name}-Remark101`}
                    Value={Remark101}
                    onChange={(e) => setRemark101(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">T2</td>
                <td>
                  <input
                    id={`${id}-NozzleT102`}
                    name={`${name}-NozzleT102`}
                    type="text"
                    value={NozzleT102}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setNozzleT102(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone102`}
                    name={`${name}-Zone102`}
                    type="text"
                    value={Zone102}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone102(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone202`}
                    name={`${name}-Zone202`}
                    type="text"
                    value={Zone202}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone202(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone302`}
                    name={`${name}-Zone302`}
                    type="text"
                    value={Zone302}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone302(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone402`}
                    name={`${name}-Zone402`}
                    type="text"
                    value={Zone402}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone402(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone502`}
                    name={`${name}-Zone502`}
                    type="text"
                    value={Zone502}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone502(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-CoreCavity102`}
                    name={`${name}-CoreCavity102`}
                    type="text"
                    value={CoreCavity102}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setCoreCavity102(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-ShortFill102`}
                    name={`${name}-ShortFill102`}
                    type="text"
                    value={ShortFill102}
                    onChange={(e) => setShortFill102(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Flash102`}
                    name={`${name}-Flash102`}
                    type="text"
                    value={Flash102}
                    onChange={(e) => setFlash102(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-FlowMark102`}
                    name={`${name}-FlowMark102`}
                    type="text"
                    value={FlowMark102}
                    onChange={(e) => setFlowMark102(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Srinkage102`}
                    name={`${name}-Srinkage102`}
                    type="text"
                    value={Srinkage102}
                    onChange={(e) => setSrinkage102(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-SilverStreiks102`}
                    name={`${name}-SilverStreiks102`}
                    type="text"
                    value={SilverStreiks102}
                    onChange={(e) => setSilverStreiks102(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-WeldLine102`}
                    name={`${name}-WeldLine102`}
                    type="text"
                    value={WeldLine102}
                    onChange={(e) => setWeldLine102(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-BurnMark102`}
                    name={`${name}-BurnMark102`}
                    type="text"
                    value={BurnMark102}
                    onChange={(e) => setBurnMark102(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Result102`}
                    name={`${name}-Result102`}
                    type="text"
                    value={Result102}
                    onChange={(e) => setResult102(e.target.value)}
                  />
                </td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-Remark102`}
                    name={`${name}-Remark102`}
                    Value={Remark102}
                    onChange={(e) => setRemark102(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">T3</td>
                <td>
                  <input
                    id={`${id}-NozzleT103`}
                    name={`${name}-NozzleT103`}
                    type="text"
                    value={NozzleT103}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setNozzleT103(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone103`}
                    name={`${name}-Zone103`}
                    type="text"
                    value={Zone103}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone103(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone203`}
                    name={`${name}-Zone203`}
                    type="text"
                    value={Zone203}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone203(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone303`}
                    name={`${name}-Zone303`}
                    type="text"
                    value={Zone303}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone303(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone403`}
                    name={`${name}-Zone403`}
                    type="text"
                    value={Zone403}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone403(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone503`}
                    name={`${name}-Zone503`}
                    type="text"
                    value={Zone503}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone503(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-CoreCavity103`}
                    name={`${name}-CoreCavity103`}
                    type="text"
                    value={CoreCavity103}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setCoreCavity103(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-ShortFill103`}
                    name={`${name}-ShortFill103`}
                    type="text"
                    value={ShortFill103}
                    onChange={(e) => setShortFill103(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Flash103`}
                    name={`${name}-Flash103`}
                    type="text"
                    value={Flash103}
                    onChange={(e) => setFlash103(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-FlowMark103`}
                    name={`${name}-FlowMark103`}
                    type="text"
                    value={FlowMark103}
                    onChange={(e) => setFlowMark103(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Srinkage103`}
                    name={`${name}-Srinkage103`}
                    type="text"
                    value={Srinkage103}
                    onChange={(e) => setSrinkage103(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-SilverStreiks103`}
                    name={`${name}-SilverStreiks103`}
                    type="text"
                    value={SilverStreiks103}
                    onChange={(e) => setSilverStreiks103(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-WeldLine103`}
                    name={`${name}-WeldLine103`}
                    type="text"
                    value={WeldLine103}
                    onChange={(e) => setWeldLine103(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-BurnMark103`}
                    name={`${name}-BurnMark103`}
                    type="text"
                    value={BurnMark103}
                    onChange={(e) => setBurnMark103(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Result103`}
                    name={`${name}-Result103`}
                    type="text"
                    value={Result103}
                    onChange={(e) => setResult103(e.target.value)}
                  />
                </td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-Remark103`}
                    name={`${name}-Remark103`}
                    Value={Remark103}
                    onChange={(e) => setRemark103(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">T4</td>
                <td>
                  <input
                    id={`${id}-NozzleT104`}
                    name={`${name}-NozzleT104`}
                    type="text"
                    value={NozzleT104}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setNozzleT104(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone104`}
                    name={`${name}-Zone104`}
                    type="text"
                    value={Zone104}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone104(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone204`}
                    name={`${name}-Zone204`}
                    type="text"
                    value={Zone204}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone204(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone304`}
                    name={`${name}-Zone304`}
                    type="text"
                    value={Zone304}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone304(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone404`}
                    name={`${name}-Zone404`}
                    type="text"
                    value={Zone404}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone404(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone504`}
                    name={`${name}-Zone504`}
                    type="text"
                    value={Zone504}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone504(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-CoreCavity104`}
                    name={`${name}-CoreCavity104`}
                    type="text"
                    value={CoreCavity104}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setCoreCavity104(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-ShortFill104`}
                    name={`${name}-ShortFill104`}
                    type="text"
                    value={ShortFill104}
                    onChange={(e) => setShortFill104(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Flash104`}
                    name={`${name}-Flash104`}
                    type="text"
                    value={Flash104}
                    onChange={(e) => setFlash104(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-FlowMark104`}
                    name={`${name}-FlowMark104`}
                    type="text"
                    value={FlowMark104}
                    onChange={(e) => setFlowMark104(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Srinkage104`}
                    name={`${name}-Srinkage104`}
                    type="text"
                    value={Srinkage104}
                    onChange={(e) => setSrinkage104(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-SilverStreiks104`}
                    name={`${name}-SilverStreiks104`}
                    type="text"
                    value={SilverStreiks104}
                    onChange={(e) => setSilverStreiks104(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-WeldLine104`}
                    name={`${name}-WeldLine104`}
                    type="text"
                    value={WeldLine104}
                    onChange={(e) => setWeldLine104(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-BurnMark104`}
                    name={`${name}-BurnMark104`}
                    type="text"
                    value={BurnMark104}
                    onChange={(e) => setBurnMark104(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Result104`}
                    name={`${name}-Result104`}
                    type="text"
                    value={Result104}
                    onChange={(e) => setResult104(e.target.value)}
                  />
                </td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-Remark104`}
                    name={`${name}-Remark104`}
                    Value={Remark104}
                    onChange={(e) => setRemark104(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">T5</td>
                <td>
                  <input
                    id={`${id}-NozzleT105`}
                    name={`${name}-NozzleT105`}
                    type="text"
                    value={NozzleT105}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setNozzleT105(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone105`}
                    name={`${name}-Zone105`}
                    type="text"
                    value={Zone105}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone105(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone205`}
                    name={`${name}-Zone205`}
                    type="text"
                    value={Zone205}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone205(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone305`}
                    name={`${name}-Zone305`}
                    type="text"
                    value={Zone305}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone305(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone405`}
                    name={`${name}-Zone405`}
                    type="text"
                    value={Zone405}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone405(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone505`}
                    name={`${name}-Zone505`}
                    type="text"
                    value={Zone505}
                    onChange={(e) =>{
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      } setZone505(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-CoreCavity105`}
                    name={`${name}-CoreCavity105`}
                    type="text"
                    value={CoreCavity105}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setCoreCavity105(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-ShortFill105`}
                    name={`${name}-ShortFill105`}
                    type="text"
                    value={ShortFill105}
                    onChange={(e) => setShortFill105(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Flash105`}
                    name={`${name}-Flash105`}
                    type="text"
                    value={Flash105}
                    onChange={(e) => setFlash105(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-FlowMark105`}
                    name={`${name}-FlowMark105`}
                    type="text"
                    value={FlowMark105}
                    onChange={(e) => setFlowMark105(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Srinkage105`}
                    name={`${name}-Srinkage105`}
                    type="text"
                    value={Srinkage105}
                    onChange={(e) => setSrinkage105(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-SilverStreiks105`}
                    name={`${name}-SilverStreiks105`}
                    type="text"
                    value={SilverStreiks105}
                    onChange={(e) => setSilverStreiks105(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-WeldLine105`}
                    name={`${name}-WeldLine105`}
                    type="text"
                    value={WeldLine105}
                    onChange={(e) => setWeldLine105(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-BurnMark105`}
                    name={`${name}-BurnMark105`}
                    type="text"
                    value={BurnMark105}
                    onChange={(e) => setBurnMark105(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Result105`}
                    name={`${name}-Result105`}
                    type="text"
                    value={Result105}
                    onChange={(e) => setResult105(e.target.value)}
                  />
                </td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-Remark105`}
                    name={`${name}-Remark105`}
                    Value={Remark105}
                    onChange={(e) => setRemark105(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td colSpan={3} className="topic-head-trail">
                  Injection Pressure (BAR)
                </td>
                <td colSpan={3} className="topic-head-trail">
                  Injection Speed (%)
                </td>
                <td className="topic-head-trail">Shot Size</td>
                <td colSpan={9}></td>
              </tr>
              <tr>
                <td className="topic-head">T1</td>
                <td>
                  <input
                    id={`${id}-InjPres101`}
                    name={`${name}-InjPres101`}
                    type="text"
                    value={InjPres101}
                    onChange={(e) => setInjPres101(e.target.value)}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjPres201`}
                    name={`${name}-InjPres201`}
                    type="text"
                    value={InjPres201}
                    onChange={(e) => setInjPres201(e.target.value)}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjPres301`}
                    name={`${name}-InjPres301`}
                    type="text"
                    value={InjPres301}
                    onChange={(e) => setInjPres301(e.target.value)}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjSpd101`}
                    name={`${name}-InjSpd101`}
                    type="text"
                    value={InjSpd101}
                    onChange={(e) => setInjSpd101(e.target.value)}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjSpd201`}
                    name={`${name}-InjSpd201`}
                    type="text"
                    value={InjSpd201}
                    onChange={(e) => setInjSpd201(e.target.value)}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjSpd301`}
                    name={`${name}-InjSpd301`}
                    type="text"
                    value={InjSpd301}
                    onChange={(e) => setInjSpd301(e.target.value)}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    id={`${id}-ShotSize201`}
                    name={`${name}-ShotSize201`}
                    type="text"
                    value={ShotSize201}
                    onChange={(e) => setShotSize201(e.target.value)}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    id={`${id}-ShortFill201`}
                    name={`${name}-ShortFill201`}
                    type="text"
                    value={ShortFill201}
                    onChange={(e) => setShortFill201(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Flash201`}
                    name={`${name}-Flash201`}
                    type="text"
                    value={Flash201}
                    onChange={(e) => setFlash201(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-FlowMark201`}
                    name={`${name}-FlowMark201`}
                    type="text"
                    value={FlowMark201}
                    onChange={(e) => setFlowMark201(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Srinkage201`}
                    name={`${name}-Srinkage201`}
                    type="text"
                    value={Srinkage201}
                    onChange={(e) => setSrinkage201(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-SilverStreiks201`}
                    name={`${name}-SilverStreiks201`}
                    type="text"
                    value={SilverStreiks201}
                    onChange={(e) => setSilverStreiks201(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-WeldLine201`}
                    name={`${name}-WeldLine201`}
                    type="text"
                    value={WeldLine201}
                    onChange={(e) => setWeldLine201(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-BurnMark201`}
                    name={`${name}-BurnMark201`}
                    type="text"
                    value={BurnMark201}
                    onChange={(e) => setBurnMark201(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Result201`}
                    name={`${name}-Result201`}
                    type="text"
                    value={Result201}
                    onChange={(e) => setResult201(e.target.value)}
                  />
                </td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-Remark201`}
                    name={`${name}-Remark201`}
                    Value={Remark201}
                    onChange={(e) => setRemark201(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">T2</td>
                <td>
                  <input
                    id={`${id}-InjPres102`}
                    name={`${name}-InjPres102`}
                    type="text"
                    value={InjPres102}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjPres102(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjPres202`}
                    name={`${name}-InjPres202`}
                    type="text"
                    value={InjPres202}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjPres202(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjPres302`}
                    name={`${name}-InjPres302`}
                    type="text"
                    value={InjPres302}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjPres302(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjSpd102`}
                    name={`${name}-InjSpd102`}
                    type="text"
                    value={InjSpd102}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjSpd102(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjSpd202`}
                    name={`${name}-InjSpd202`}
                    type="text"
                    value={InjSpd202}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjSpd202(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjSpd302`}
                    name={`${name}-InjSpd302`}
                    type="text"
                    value={InjSpd302}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjSpd302(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-ShotSize202`}
                    name={`${name}-ShotSize202`}
                    type="text"
                    value={ShotSize202}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setShotSize202(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-ShortFill202`}
                    name={`${name}-ShortFill202`}
                    type="text"
                    value={ShortFill202}
                    onChange={(e) => setShortFill202(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Flash202`}
                    name={`${name}-Flash202`}
                    type="text"
                    value={Flash202}
                    onChange={(e) => setFlash202(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-FlowMark202`}
                    name={`${name}-FlowMark202`}
                    type="text"
                    value={FlowMark202}
                    onChange={(e) => setFlowMark202(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Srinkage202`}
                    name={`${name}-Srinkage202`}
                    type="text"
                    value={Srinkage202}
                    onChange={(e) => setSrinkage202(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-SilverStreiks202`}
                    name={`${name}-SilverStreiks202`}
                    type="text"
                    value={SilverStreiks202}
                    onChange={(e) => setSilverStreiks202(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-WeldLine202`}
                    name={`${name}-WeldLine202`}
                    type="text"
                    value={WeldLine202}
                    onChange={(e) => setWeldLine202(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-BurnMark202`}
                    name={`${name}-BurnMark202`}
                    type="text"
                    value={BurnMark202}
                    onChange={(e) => setBurnMark202(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Result202`}
                    name={`${name}-Result202`}
                    type="text"
                    value={Result202}
                    onChange={(e) => setResult202(e.target.value)}
                  />
                </td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-Remark202`}
                    name={`${name}-Remark202`}
                    Value={Remark202}
                    onChange={(e) => setRemark202(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">T3</td>
                <td>
                  <input
                    id={`${id}-InjPres103`}
                    name={`${name}-InjPres103`}
                    type="text"
                    value={InjPres103}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjPres103(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjPres203`}
                    name={`${name}-InjPres203`}
                    type="text"
                    value={InjPres203}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjPres203(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjPres303`}
                    name={`${name}-InjPres303`}
                    type="text"
                    value={InjPres303}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjPres303(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjSpd103`}
                    name={`${name}-InjSpd103`}
                    type="text"
                    value={InjSpd103}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjSpd103(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjSpd203`}
                    name={`${name}-InjSpd203`}
                    type="text"
                    value={InjSpd203}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjSpd203(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjSpd303`}
                    name={`${name}-InjSpd303`}
                    type="text"
                    value={InjSpd303}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjSpd303(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-ShotSize203`}
                    name={`${name}-ShotSize203`}
                    type="text"
                    value={ShotSize203}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setShotSize203(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-ShortFill203`}
                    name={`${name}-ShortFill203`}
                    type="text"
                    value={ShortFill203}
                    onChange={(e) => setShortFill203(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Flash203`}
                    name={`${name}-Flash203`}
                    type="text"
                    value={Flash203}
                    onChange={(e) => setFlash203(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-FlowMark203`}
                    name={`${name}-FlowMark203`}
                    type="text"
                    value={FlowMark203}
                    onChange={(e) => setFlowMark203(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Srinkage203`}
                    name={`${name}-Srinkage203`}
                    type="text"
                    value={Srinkage203}
                    onChange={(e) => setSrinkage203(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-SilverStreiks203`}
                    name={`${name}-SilverStreiks203`}
                    type="text"
                    value={SilverStreiks203}
                    onChange={(e) => setSilverStreiks203(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-WeldLine203`}
                    name={`${name}-WeldLine203`}
                    type="text"
                    value={WeldLine203}
                    onChange={(e) => setWeldLine203(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-BurnMark203`}
                    name={`${name}-BurnMark203`}
                    type="text"
                    value={BurnMark203}
                    onChange={(e) => setBurnMark203(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Result203`}
                    name={`${name}-Result203`}
                    type="text"
                    value={Result203}
                    onChange={(e) => setResult203(e.target.value)}
                  />
                </td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-Remark203`}
                    name={`${name}-Remark203`}
                    Value={Remark203}
                    onChange={(e) => setRemark203(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">T4</td>
                <td>
                  <input
                    id={`${id}-InjPres104`}
                    name={`${name}-InjPres104`}
                    type="text"
                    value={InjPres104}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjPres104(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjPres204`}
                    name={`${name}-InjPres204`}
                    type="text"
                    value={InjPres204}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjPres204(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjPres304`}
                    name={`${name}-InjPres304`}
                    type="text"
                    value={InjPres304}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjPres304(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjSpd104`}
                    name={`${name}-InjSpd104`}
                    type="text"
                    value={InjSpd104}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjSpd104(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjSpd204`}
                    name={`${name}-InjSpd204`}
                    type="text"
                    value={InjSpd204}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjSpd204(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjSpd304`}
                    name={`${name}-InjSpd304`}
                    type="text"
                    value={InjSpd304}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjSpd304(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-ShotSize204`}
                    name={`${name}-ShotSize204`}
                    type="text"
                    value={ShotSize204}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setShotSize204(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-ShortFill204`}
                    name={`${name}-ShortFill204`}
                    type="text"
                    value={ShortFill204}
                    onChange={(e) => setShortFill204(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Flash204`}
                    name={`${name}-Flash204`}
                    type="text"
                    value={Flash204}
                    onChange={(e) => setFlash204(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-FlowMark204`}
                    name={`${name}-FlowMark204`}
                    type="text"
                    value={FlowMark204}
                    onChange={(e) => setFlowMark204(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Srinkage204`}
                    name={`${name}-Srinkage204`}
                    type="text"
                    value={Srinkage204}
                    onChange={(e) => setSrinkage204(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-SilverStreiks204`}
                    name={`${name}-SilverStreiks204`}
                    type="text"
                    value={SilverStreiks204}
                    onChange={(e) => setSilverStreiks204(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-WeldLine204`}
                    name={`${name}-WeldLine204`}
                    type="text"
                    value={WeldLine204}
                    onChange={(e) => setWeldLine204(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-BurnMark204`}
                    name={`${name}-BurnMark204`}
                    type="text"
                    value={BurnMark204}
                    onChange={(e) => setBurnMark204(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Result204`}
                    name={`${name}-Result204`}
                    type="text"
                    value={Result204}
                    onChange={(e) => setResult204(e.target.value)}
                  />
                </td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-Remark204`}
                    name={`${name}-Remark204`}
                    Value={Remark204}
                    onChange={(e) => setRemark204(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">T5</td>
                <td>
                  <input
                    id={`${id}-InjPres105`}
                    name={`${name}-InjPres105`}
                    type="text"
                    value={InjPres105}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjPres105(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjPres205`}
                    name={`${name}-InjPres205`}
                    type="text"
                    value={InjPres205}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjPres205(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjPres305`}
                    name={`${name}-InjPres305`}
                    type="text"
                    value={InjPres305}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjPres305(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjSpd105`}
                    name={`${name}-InjSpd105`}
                    type="text"
                    value={InjSpd105}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjSpd105(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjSpd205`}
                    name={`${name}-InjSpd205`}
                    type="text"
                    value={InjSpd205}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjSpd205(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjSpd305`}
                    name={`${name}-InjSpd305`}
                    type="text"
                    value={InjSpd305}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjSpd305(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-ShotSize205`}
                    name={`${name}-ShotSize205`}
                    type="text"
                    value={ShotSize205}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setShotSize205(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-ShortFill205`}
                    name={`${name}-ShortFill205`}
                    type="text"
                    value={ShortFill205}
                    onChange={(e) => setShortFill205(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Flash205`}
                    name={`${name}-Flash205`}
                    type="text"
                    value={Flash205}
                    onChange={(e) => setFlash205(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-FlowMark205`}
                    name={`${name}-FlowMark205`}
                    type="text"
                    value={FlowMark205}
                    onChange={(e) => setFlowMark205(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Srinkage205`}
                    name={`${name}-Srinkage205`}
                    type="text"
                    value={Srinkage205}
                    onChange={(e) => setSrinkage205(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-SilverStreiks205`}
                    name={`${name}-SilverStreiks205`}
                    type="text"
                    value={SilverStreiks205}
                    onChange={(e) => setSilverStreiks205(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-WeldLine205`}
                    name={`${name}-WeldLine205`}
                    type="text"
                    value={WeldLine205}
                    onChange={(e) => setWeldLine205(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-BurnMark205`}
                    name={`${name}-BurnMark205`}
                    type="text"
                    value={BurnMark205}
                    onChange={(e) => setBurnMark205(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Result205`}
                    name={`${name}-Result205`}
                    type="text"
                    value={Result205}
                    onChange={(e) => setResult205(e.target.value)}
                  />
                </td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-Remark205`}
                    name={`${name}-Remark205`}
                    Value={Remark205}
                    onChange={(e) => setRemark205(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td className="topic-head-trail">Holding Pressure (BAR)</td>
                <td className="topic-head-trail">Holding Speed (%)</td>
                <td className="topic-head-trail">Injection Time (SEC)</td>
                <td className="topic-head-trail">Cooling Time (SEC)</td>
                <td className="topic-head-trail">Holding Time (SEC)</td>
                <td className="topic-head-trail">Hydraulic Oil Temp (°C)</td>
                <td className="topic-head-trail">MTC (°C)</td>
                <td colSpan={9}></td>
              </tr>
              <tr>
                <td className="topic-head">T1</td>
                <td>
                  <input
                    id={`${id}-HoldPres101`}
                    name={`${name}-HoldPres101`}
                    type="text"
                    value={HoldPres101}
                    onChange={(e) => setHoldPres101(e.target.value)}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    id={`${id}-HoldSpd101`}
                    name={`${name}-HoldSpd101`}
                    type="text"
                    value={HoldSpd101}
                    onChange={(e) => setHoldSpd101(e.target.value)}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjTme101`}
                    name={`${name}-InjTme101`}
                    type="text"
                    value={InjTme101}
                    onChange={(e) => setInjTme101(e.target.value)}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    id={`${id}-CoolTme101`}
                    name={`${name}-CoolTme101`}
                    type="text"
                    value={CoolTme101}
                    onChange={(e) => setCoolTme101(e.target.value)}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    id={`${id}-HoldTme101`}
                    name={`${name}-HoldTme101`}
                    type="text"
                    value={HoldTme101}
                    onChange={(e) => setHoldTme101(e.target.value)}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    id={`${id}-HydeaOilTemp101`}
                    name={`${name}-HydeaOilTemp101`}
                    type="text"
                    value={HydeaOilTemp101}
                    onChange={(e) => setHydeaOilTemp101(e.target.value)}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    id={`${id}-MTC101`}
                    name={`${name}-MTC101`}
                    type="text"
                    value={MTC101}
                    onChange={(e) => setMTC101(e.target.value)}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    id={`${id}-ShortFill301`}
                    name={`${name}-ShortFill301`}
                    type="text"
                    value={ShortFill301}
                    onChange={(e) => setShortFill301(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Flash301`}
                    name={`${name}-Flash301`}
                    type="text"
                    value={Flash301}
                    onChange={(e) => setFlash301(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-FlowMark301`}
                    name={`${name}-FlowMark301`}
                    type="text"
                    value={FlowMark301}
                    onChange={(e) => setFlowMark301(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Srinkage301`}
                    name={`${name}-Srinkage301`}
                    type="text"
                    value={Srinkage301}
                    onChange={(e) => setSrinkage301(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-SilverStreiks301`}
                    name={`${name}-SilverStreiks301`}
                    type="text"
                    value={SilverStreiks301}
                    onChange={(e) => setSilverStreiks301(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-WeldLine301`}
                    name={`${name}-WeldLine301`}
                    type="text"
                    value={WeldLine301}
                    onChange={(e) => setWeldLine301(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-BurnMark301`}
                    name={`${name}-BurnMark301`}
                    type="text"
                    value={BurnMark301}
                    onChange={(e) => setBurnMark301(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Result301`}
                    name={`${name}-Result301`}
                    type="text"
                    value={Result301}
                    onChange={(e) => setResult301(e.target.value)}
                  />
                </td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-Remark301`}
                    name={`${name}-Remark301`}
                    Value={Remark301}
                    onChange={(e) => setRemark301(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">T2</td>
                <td>
                  <input
                    id={`${id}-HoldPres102`}
                    name={`${name}-HoldPres102`}
                    type="text"
                    value={HoldPres102}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setHoldPres102(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-HoldSpd102`}
                    name={`${name}-HoldSpd102`}
                    type="text"
                    value={HoldSpd102}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setHoldSpd102(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjTme102`}
                    name={`${name}-InjTme102`}
                    type="text"
                    value={InjTme102}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjTme102(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-CoolTme102`}
                    name={`${name}-CoolTme102`}
                    type="text"
                    value={CoolTme102}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setCoolTme102(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-HoldTme102`}
                    name={`${name}-HoldTme102`}
                    type="text"
                    value={HoldTme102}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setHoldTme102(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-HydeaOilTemp102`}
                    name={`${name}-HydeaOilTemp102`}
                    type="text"
                    value={HydeaOilTemp102}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setHydeaOilTemp102(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-MTC102`}
                    name={`${name}-MTC102`}
                    type="text"
                    value={MTC102}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setMTC102(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-ShortFill302`}
                    name={`${name}-ShortFill302`}
                    type="text"
                    value={ShortFill302}
                    onChange={(e) => setShortFill302(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Flash302`}
                    name={`${name}-Flash302`}
                    type="text"
                    value={Flash302}
                    onChange={(e) => setFlash302(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-FlowMark302`}
                    name={`${name}-FlowMark302`}
                    type="text"
                    value={FlowMark302}
                    onChange={(e) => setFlowMark302(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Srinkage302`}
                    name={`${name}-Srinkage302`}
                    type="text"
                    value={Srinkage302}
                    onChange={(e) => setSrinkage302(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-SilverStreiks302`}
                    name={`${name}-SilverStreiks302`}
                    type="text"
                    value={SilverStreiks302}
                    onChange={(e) => setSilverStreiks302(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-WeldLine302`}
                    name={`${name}-WeldLine302`}
                    type="text"
                    value={WeldLine302}
                    onChange={(e) => setWeldLine302(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-BurnMark302`}
                    name={`${name}-BurnMark302`}
                    type="text"
                    value={BurnMark302}
                    onChange={(e) => setBurnMark302(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Result302`}
                    name={`${name}-Result302`}
                    type="text"
                    value={Result302}
                    onChange={(e) => setResult302(e.target.value)}
                  />
                </td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-Remark302`}
                    name={`${name}-Remark302`}
                    Value={Remark302}
                    onChange={(e) => setRemark302(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">T3</td>
                <td>
                  <input
                    id={`${id}-HoldPres103`}
                    name={`${name}-HoldPres103`}
                    type="text"
                    value={HoldPres103}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setHoldPres103(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-HoldSpd103`}
                    name={`${name}-HoldSpd103`}
                    type="text"
                    value={HoldSpd103}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setHoldSpd103(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjTme103`}
                    name={`${name}-InjTme103`}
                    type="text"
                    value={InjTme103}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjTme103(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-CoolTme103`}
                    name={`${name}-CoolTme103`}
                    type="text"
                    value={CoolTme103}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setCoolTme103(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-HoldTme103`}
                    name={`${name}-HoldTme103`}
                    type="text"
                    value={HoldTme103}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setHoldTme103(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-HydeaOilTemp103`}
                    name={`${name}-HydeaOilTemp103`}
                    type="text"
                    value={HydeaOilTemp103}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setHydeaOilTemp103(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-MTC103`}
                    name={`${name}-MTC103`}
                    type="text"
                    value={MTC103}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setMTC103(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-ShortFill303`}
                    name={`${name}-ShortFill303`}
                    type="text"
                    value={ShortFill303}
                    onChange={(e) => setShortFill303(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Flash303`}
                    name={`${name}-Flash303`}
                    type="text"
                    value={Flash303}
                    onChange={(e) => setFlash303(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-FlowMark303`}
                    name={`${name}-FlowMark303`}
                    type="text"
                    value={FlowMark303}
                    onChange={(e) => setFlowMark303(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Srinkage303`}
                    name={`${name}-Srinkage303`}
                    type="text"
                    value={Srinkage303}
                    onChange={(e) => setSrinkage303(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-SilverStreiks303`}
                    name={`${name}-SilverStreiks303`}
                    type="text"
                    value={SilverStreiks303}
                    onChange={(e) => setSilverStreiks303(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-WeldLine303`}
                    name={`${name}-WeldLine303`}
                    type="text"
                    value={WeldLine303}
                    onChange={(e) => setWeldLine303(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-BurnMark303`}
                    name={`${name}-BurnMark303`}
                    type="text"
                    value={BurnMark303}
                    onChange={(e) => setBurnMark303(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Result303`}
                    name={`${name}-Result303`}
                    type="text"
                    value={Result303}
                    onChange={(e) => setResult303(e.target.value)}
                  />
                </td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-Remark303`}
                    name={`${name}-Remark303`}
                    Value={Remark303}
                    onChange={(e) => setRemark303(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">T4</td>
                <td>
                  <input
                    id={`${id}-HoldPres104`}
                    name={`${name}-HoldPres104`}
                    type="text"
                    value={HoldPres104}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setHoldPres104(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-HoldSpd104`}
                    name={`${name}-HoldSpd104`}
                    type="text"
                    value={HoldSpd104}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setHoldSpd104(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjTme104`}
                    name={`${name}-InjTme104`}
                    type="text"
                    value={InjTme104}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjTme104(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-CoolTme104`}
                    name={`${name}-CoolTme104`}
                    type="text"
                    value={CoolTme104}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setCoolTme104(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-HoldTme104`}
                    name={`${name}-HoldTme104`}
                    type="text"
                    value={HoldTme104}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setHoldTme104(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-HydeaOilTemp104`}
                    name={`${name}-HydeaOilTemp104`}
                    type="text"
                    value={HydeaOilTemp104}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setHydeaOilTemp104(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-MTC104`}
                    name={`${name}-MTC104`}
                    type="text"
                    value={MTC104}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setMTC104(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-ShortFill304`}
                    name={`${name}-ShortFill304`}
                    type="text"
                    value={ShortFill304}
                    onChange={(e) => setShortFill304(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Flash304`}
                    name={`${name}-Flash304`}
                    type="text"
                    value={Flash304}
                    onChange={(e) => setFlash304(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-FlowMark304`}
                    name={`${name}-FlowMark304`}
                    type="text"
                    value={FlowMark304}
                    onChange={(e) => setFlowMark304(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Srinkage304`}
                    name={`${name}-Srinkage304`}
                    type="text"
                    value={Srinkage304}
                    onChange={(e) => setSrinkage304(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-SilverStreiks304`}
                    name={`${name}-SilverStreiks304`}
                    type="text"
                    value={SilverStreiks304}
                    onChange={(e) => setSilverStreiks304(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-WeldLine304`}
                    name={`${name}-WeldLine304`}
                    type="text"
                    value={WeldLine304}
                    onChange={(e) => setWeldLine304(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-BurnMark304`}
                    name={`${name}-BurnMark304`}
                    type="text"
                    value={BurnMark304}
                    onChange={(e) => setBurnMark304(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Result304`}
                    name={`${name}-Result304`}
                    type="text"
                    value={Result304}
                    onChange={(e) => setResult304(e.target.value)}
                  />
                </td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-Remark304`}
                    name={`${name}-Remark304`}
                    Value={Remark304}
                    onChange={(e) => setRemark304(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">T5</td>
                <td>
                  <input
                    id={`${id}-HoldPres105`}
                    name={`${name}-HoldPres105`}
                    type="text"
                    value={HoldPres105}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setHoldPres105(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-HoldSpd105`}
                    name={`${name}-HoldSpd105`}
                    type="text"
                    value={HoldSpd105}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setHoldSpd105(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjTme105`}
                    name={`${name}-InjTme105`}
                    type="text"
                    value={InjTme105}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjTme105(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-CoolTme105`}
                    name={`${name}-CoolTme105`}
                    type="text"
                    value={CoolTme105}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setCoolTme105(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-HoldTme105`}
                    name={`${name}-HoldTme105`}
                    type="text"
                    value={HoldTme105}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setHoldTme105(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-HydeaOilTemp105`}
                    name={`${name}-HydeaOilTemp105`}
                    type="text"
                    value={HydeaOilTemp105}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setHydeaOilTemp105(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-MTC105`}
                    name={`${name}-MTC105`}
                    type="text"
                    value={MTC105}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setMTC105(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-ShortFill305`}
                    name={`${name}-ShortFill305`}
                    type="text"
                    value={ShortFill305}
                    onChange={(e) => setShortFill305(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Flash305`}
                    name={`${name}-Flash305`}
                    type="text"
                    value={Flash305}
                    onChange={(e) => setFlash305(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-FlowMark305`}
                    name={`${name}-FlowMark305`}
                    type="text"
                    value={FlowMark305}
                    onChange={(e) => setFlowMark305(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Srinkage305`}
                    name={`${name}-Srinkage305`}
                    type="text"
                    value={Srinkage305}
                    onChange={(e) => setSrinkage305(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-SilverStreiks305`}
                    name={`${name}-SilverStreiks305`}
                    type="text"
                    value={SilverStreiks305}
                    onChange={(e) => setSilverStreiks305(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-WeldLine305`}
                    name={`${name}-WeldLine305`}
                    type="text"
                    value={WeldLine305}
                    onChange={(e) => setWeldLine305(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-BurnMark305`}
                    name={`${name}-BurnMark305`}
                    type="text"
                    value={BurnMark305}
                    onChange={(e) => setBurnMark305(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Result305`}
                    name={`${name}-Result305`}
                    type="text"
                    value={Result305}
                    onChange={(e) => setResult305(e.target.value)}
                  />
                </td>
                <td>
                  <OkNotOkayFetch
                    id={`${id}-Remark305`}
                    name={`${name}-Remark305`}
                    Value={Remark305}
                    onChange={(e) => setRemark305(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={17}>
                  <p>
                    Conclusion: Based on above process validation report, below
                    mentioned parameter are freezed
                  </p>
                </td>
              </tr>

              <tr>
                <th colSpan={9}>PROCESS PARAMETER - BEFORE</th>
                <th colSpan={8}>PROCESS PARAMETER - AFTER</th>
              </tr>
              <tr>
                <th>SI. No.,</th>
                <th colSpan={6}>Barrel Temperature</th>
                <th>Mould</th>
                <th>SI. No.,</th>
                <th colSpan={6}>Barrel Temperature</th>
                <th>Mould</th>
                <td colSpan={2} rowSpan={7}>
                  Note:
                  <textarea
                    id={`${id}-Note`}
                    name={`${name}-Note`}
                    rows={6}
                    cols={2}
                    value={Note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td rowSpan={2} className="topic-head-trail">
                  1
                </td>
                <td className="topic-head-trail">
                  Nozzle
                  <span style={{ color: "red" }}>
                    {" "}
                    ({"±"}
                    {NozzSet}°C)
                  </span>
                </td>
                <td className="topic-head-trail">
                  Zone 1
                  <span style={{ color: "red" }}>
                    {" "}
                    ({"±"}
                    {ZneSet1}°C)
                  </span>
                </td>
                <td className="topic-head-trail">
                  Zone 2
                  <span style={{ color: "red" }}>
                    {" "}
                    ({"±"}
                    {ZneSet2}°C)
                  </span>
                </td>
                <td className="topic-head-trail">
                  Zone 3
                  <span style={{ color: "red" }}>
                    {" "}
                    ({"±"}
                    {ZneSet3}°C)
                  </span>
                </td>
                <td className="topic-head-trail">
                  Zone 4{" "}
                  <span style={{ color: "red" }}>
                    <br /> ( {"±"} {ZneSet4}°C)
                  </span>
                </td>
                <td className="topic-head-trail">
                  Zone 5{" "}
                  <span style={{ color: "red" }}>
                    <br />( {"±"} {ZneSet5}°C)
                  </span>
                </td>
                <td className="topic-head-trail">Core/Cavity</td>
                <td rowSpan={2} className="topic-head-trail">
                  1
                </td>
                <td className="topic-head-trail">Nozzle(±10)°C</td>
                <td className="topic-head-trail">Zone 1(±10)°C</td>
                <td className="topic-head-trail">Zone 2(±10)°C</td>
                <td className="topic-head-trail">Zone 3(±10)°C</td>
                <td className="topic-head-trail">Zone 4(±10)°C</td>
                <td className="topic-head-trail">Zone 5(±10)°C</td>
                <td className="topic-head-trail">Core/Cavity</td>
              </tr>
              <tr>
                <td>
                  <input
                    id={`${id}-NozzleBefore`}
                    name={`${name}-NozzleBefore`}
                    type="text"
                    value={NozzleBefore}
                    
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }
                      setNozzleBefore(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone1Before`}
                    name={`${name}-Zone1Before`}
                    type="text"
                    value={Zone1Before}
                    onChange={(e) => 
                      {
                        const value = e.target.value;
                        if (InvalidCharacters(value)) {
                          e.preventDefault();
                          return;
                        }setZone1Before(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone2Before`}
                    name={`${name}-Zone2Before`}
                    type="text"
                    value={Zone2Before}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone2Before(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone3Before`}
                    name={`${name}-Zone3Before`}
                    type="text"
                    value={Zone3Before}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone3Before(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone4Before`}
                    name={`${name}-Zone4Before`}
                    type="text"
                    value={Zone4Before}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone4Before(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone5Before`}
                    name={`${name}-Zone5Before`}
                    type="text"
                    value={Zone5Before}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone5Before(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-CoreCavityBefore`}
                    name={`${name}-CoreCavityBefore`}
                    type="text"
                    value={CoreCavityBefore}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setCoreCavityBefore(e.target.value)}}
                  />
                </td>

                <td>
                  <input
                    id={`${id}-NozzleAfter`}
                    name={`${name}-NozzleAfter`}
                    type="text"
                    value={NozzleAfter}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setNozzleAfter(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone1After`}
                    name={`${name}-Zone1After`}
                    type="text"
                    value={Zone1After}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone1After(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone2After`}
                    name={`${name}-Zone2After`}
                    type="text"
                    value={Zone2After}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone2After(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone3After`}
                    name={`${name}-Zone3After`}
                    type="text"
                    value={Zone3After}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone3After(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone4After`}
                    name={`${name}-Zone4After`}
                    type="text"
                    value={Zone4After}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone4After(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-Zone5After`}
                    name={`${name}-Zone5After`}
                    type="text"
                    value={Zone5After}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setZone5After(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-CoreCavityAfter`}
                    name={`${name}-CoreCavityAfter`}
                    type="text"
                    value={CoreCavityAfter}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setCoreCavityAfter(e.target.value)}}
                  />
                </td>
              </tr>
              <tr>
                <td rowSpan={2} className="topic-head-trail">
                  2
                </td>
                <td colSpan={3} className="topic-head-trail">
                  (Injection Pressure)
                  <span style={{ color: "red" }}>
                    ({""} {"±"} {InjPresSet})
                  </span>
                </td>
                <td colSpan={3} className="topic-head-trail">
                  (Injection Speed)
                  <span style={{ color: "red" }}>
                    ({""} {"±"} {InjSpdSet})
                  </span>
                </td>
                <td className="topic-head-trail">Shot Size</td>
                <td rowSpan={2} className="topic-head-trail">
                  2
                </td>
                <td colSpan={3} className="topic-head-trail">
                  Injection Pressure(±3)
                </td>
                <td colSpan={3} className="topic-head-trail">
                  Injection Speed(±5)
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    id={`${id}-InjPres1Before`}
                    name={`${name}-InjPres1Before`}
                    type="text"
                    value={InjPres1Before}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjPres1Before(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjPres2Before`}
                    name={`${name}-InjPres2Before`}
                    type="text"
                    value={InjPres2Before}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjPres2Before(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjPres3Before`}
                    name={`${name}-InjPres3Before`}
                    type="text"
                    value={InjPres3Before}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjPres3Before(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjSped1Before`}
                    name={`${name}-InjSped1Before`}
                    type="text"
                    value={InjSped1Before}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjSped1Before(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjSped2Before`}
                    name={`${name}-InjSped2Before`}
                    type="text"
                    value={InjSped2Before}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjSped2Before(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjSped3Before`}
                    name={`${name}-InjSped3Before`}
                    type="text"
                    value={InjSped3Before}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjSped3Before(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-ShotSizeBefore`}
                    name={`${name}-ShotSizeBefore`}
                    type="text"
                    value={ShotSizeBefore}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setShotSizeBefore(e.target.value)}}
                  />
                </td>

                <td>
                  <input
                    id={`${id}-InjPres1After`}
                    name={`${name}-InjPres1After`}
                    type="text"
                    value={InjPres1After}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjPres1After(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjPres2After`}
                    name={`${name}-InjPres2After`}
                    type="text"
                    value={InjPres2After}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjPres2After(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjPres3After`}
                    name={`${name}-InjPres3After`}
                    type="text"
                    value={InjPres3After}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjPres3After(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjSped1After`}
                    name={`${name}-InjSped1After`}
                    type="text"
                    value={InjSped1After}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjSped1After(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjSped2After`}
                    name={`${name}-InjSped2After`}
                    type="text"
                    value={InjSped2After}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjSped2After(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjSped3After`}
                    name={`${name}-InjSped3After`}
                    type="text"
                    value={InjSped3After}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjSped3After(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-ShotSizeAfter`}
                    name={`${name}-ShotSizeAfter`}
                    type="text"
                    value={ShotSizeAfter}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setShotSizeAfter(e.target.value)}}
                  />
                </td>
              </tr>
              <tr>
                <td rowSpan={2} className="topic-head-trail">
                  3
                </td>
                <td className="topic-head-trail">
                  Holding Pressure
                  <span style={{ color: "red" }}>
                    {" "}
                    ({"±"}
                    {HoldPresSet}°C)
                  </span>
                </td>
                <td className="topic-head-trail">
                  Holding Speed
                  <span style={{ color: "red" }}>
                    {" "}
                    ({"±"}
                    {HoldSpdSet}%)
                  </span>
                </td>
                <td className="topic-head-trail">
                  Injection Time
                  <span style={{ color: "red" }}>
                    {" "}
                    ({"±"}
                    {InjTmeSet}SEC)
                  </span>
                </td>
                <td className="topic-head-trail">
                  Cooling Time
                  <span style={{ color: "red" }}>
                    {" "}
                    {"±"}({CoolTmeSet}SEC)
                  </span>
                </td>
                <td className="topic-head-trail">
                  Holding Time
                  <span style={{ color: "red" }}>
                    {" "}
                    ({"±"}
                    {HoldTmeSet}SEC)
                  </span>
                </td>
                <td className="topic-head-trail">
                  Hydraulic Oil Temp
                  <span style={{ color: "red" }}>
                    {" "}
                    ({"±"}
                    {OilSet}°C)
                  </span>
                </td>
                <td className="topic-head-trail">
                  MTC{" "}
                  <span style={{ color: "red" }}>
                    {" "}
                    ({"±"}
                    {MtcSet}°C)
                  </span>
                </td>
                <td rowSpan={2} className="topic-head-trail">
                  3
                </td>
                <td className="topic-head-trail">Holding Pressure(±3 BAR)</td>
                <td className="topic-head-trail">Holding Speed(±5 %)</td>
                <td className="topic-head-trail">Injection Time(±0.5 SEC)</td>
                <td className="topic-head-trail">Cooling Time(±1 SEC)</td>
                <td className="topic-head-trail">Holding Time(±0.5 SEC)</td>
                <td className="topic-head-trail">Hydraulic Oil Temp(±10 °C)</td>
                <td className="topic-head-trail">MTC (±10 °C)</td>
              </tr>
              <tr>
                <td>
                  <input
                    id={`${id}-HoldPresBefore`}
                    name={`${name}-HoldPresBefore`}
                    type="text"
                    value={HoldPresBefore}
                    onChange={(e) => 
                      {
                        const value = e.target.value;
                        if (InvalidCharacters(value)) {
                          e.preventDefault();
                          return;
                        }setHoldPresBefore(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-HoldSpdBefore`}
                    name={`${name}-HoldSpdBefore`}
                    type="text"
                    value={HoldSpdBefore}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setHoldSpdBefore(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjTmeBefore`}
                    name={`${name}-InjTmeBefore`}
                    type="text"
                    value={InjTmeBefore}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjTmeBefore(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-CoolTmeBefore`}
                    name={`${name}-CoolTmeBefore`}
                    type="text"
                    value={CoolTmeBefore}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setCoolTmeBefore(e.target.value)}}
                  />
                </td>

                <td>
                  <input
                    id={`${id}-HldTmeBefore`}
                    name={`${name}-HldTmeBefore`}
                    type="text"
                    value={HldTmeBefore}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setHldTmeBefore(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-HydraOilTmpBefore`}
                    name={`${name}-HydraOilTmpBefore`}
                    type="text"
                    value={HydraOilTmpBefore}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setHydraOilTmpBefore(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-MtcBefore`}
                    name={`${name}-MtcBefore`}
                    type="text"
                    value={MtcBefore}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setMtcBefore(e.target.value)}}
                  />
                </td>

                <td>
                  <input
                    id={`${id}-HoldPresAfter`}
                    name={`${name}-HoldPresAfter`}
                    type="text"
                    value={HoldPresAfter}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setHoldPresAfter(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-HoldSpdAfter`}
                    name={`${name}-HoldSpdAfter`}
                    type="text"
                    value={HoldSpdAfter}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setHoldSpdAfter(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-InjTmeAfter`}
                    name={`${name}-InjTmeAfter`}
                    type="text"
                    value={InjTmeAfter}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setInjTmeAfter(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-CoolTmeAfter`}
                    name={`${name}-CoolTmeAfter`}
                    type="text"
                    value={CoolTmeAfter}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setCoolTmeAfter(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-HldTmeAfter`}
                    name={`${name}-HldTmeAfter`}
                    type="text"
                    value={HldTmeAfter}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setHldTmeAfter(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-HydraOilTempAfter`}
                    name={`${name}-HydraOilTempAfter`}
                    type="text"
                    value={HydraOilTempAfter}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setHydraOilTempAfter(e.target.value)}}
                  />
                </td>
                <td>
                  <input
                    id={`${id}-MtcAfter`}
                    name={`${name}-MtcAfter`}
                    type="text"
                    value={MtcAfter}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }setMtcAfter(e.target.value)}}
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td colSpan={5}>
                  Validation By:
                  <br></br>
                  <br></br>
                  <Select
                    styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                    value={Validation}
                    onChange={handleValidation}
                    options={Employee.map((i) => ({
                      value: i.Emp_code,
                      label: i.Emp_name,
                    }))}
                  />
                </td>
                <td colSpan={5}>
                  Prepared By:
                  <br></br>
                  <br></br>
                  <Select
                    styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                    value={Prepared}
                    onChange={handlePrepared}
                    options={Employee.map((i) => ({
                      value: i.Emp_code,
                      label: i.Emp_name,
                    }))}
                  />
                </td>

                <td colSpan={6}>
                  Approved By:
                  <br></br>
                  <br></br>
                  <Select
                    styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                    value={Approved}
                    onChange={handleApproved}
                    options={Employee.map((i) => ({
                      value: i.Emp_code,
                      label: i.Emp_name,
                    }))}
                  />
                </td>
              </tr>
              {/* <tr>
              <th colSpan={17}>REVISION HISTORY</th>
            </tr>
            <tr>
              <th>SI. No.,</th>
              <th colSpan={2}>Rev No</th>
              <th colSpan={3}>Rev Date</th>
              <th colSpan={5}>Revision Details</th>
              <th colSpan={6}>Approved By</th>
            </tr>
            <tr>
              <td>1</td>
              <td colSpan={2}>00</td>
              <td colSpan={3}>01.11.2012</td>
              <td colSpan={5}>Initial Release</td>
              <td colSpan={6}>Mr.Suresh/Sakthivel</td>
            </tr>
            <tr>
              <td>2</td>
              <td colSpan={2}>01</td>
              <td colSpan={3}>01.06.2022</td>
              <td colSpan={5}>
                Format change - Before & After process included
              </td>
              <td colSpan={6}>Mr.Suresh/Sakthivel</td>
            </tr> */}
              <tr>
                <td colSpan="17" align="center" style={{ border: "none" }}>
                  {Pro_Val ? (
                    <button
                      type="submit"
                      id={`${id}-submit`}
                      name={`${name}-submit`}
                      onClick={(e) => handleUpdate(e)}
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      type="submit"
                      id={`${id}-submit`}
                      name={`${name}-submit`}
                      onClick={(e) => handleCreate(e)}
                    >
                      Submit
                    </button>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  );
}

export default ProcessValidationAndReValidationCreate;
