import React from 'react';

function DatePicker({ value, onChange ,dis}) {
  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 3;
  const today = new Date().toISOString().split('T')[0];

  const handleDateChange = (event) => {
    const selected = event.target.value;

    if (selected > today) {
      alert("Selecting Future Date!!!");
      onChange(selected);
    
    } 
    // else if(selected < today ) {
    // alert("Selecting Past Date!!!");
    // onChange(selected);
    // } 
    else {
      onChange(selected);
    }
  };

  return (
    <input
      type="date"
      value={value}
      onChange={handleDateChange}
      min={today}
      max={`${nextYear}-12-31`}
      disabled={dis}
    />
  );
}

export default DatePicker;
