import React, { useEffect, useState } from "react";
import Popup from "../../../Components/Popup/Popup";
import Unitechlogo from "../../../icons/Unitechlogo.jpeg";
import DropdownFetcher from '../../../Lib/DropdownFetcher';
// import DropdownSelect from '../../../Components/DropdownSelect.js';
import HandleRead from "../../../Lib/HandleRead"
import Pagination from "../../../Components/Pagination";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import { SECRET_KEY, PROCESS_TRANSACTION_UPDATE_URL } from "../../../Utils/constants.js";
import { formatDt } from "../../../Lib/formatDt";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import GetRecord from "../../../Lib/GetRecord";
import { FaBackspace } from 'react-icons/fa';
import Select from "react-select";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Dropdown } from 'react-bootstrap';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useRef } from "react";
import * as XLSX from 'xlsx';
import reject from "../../../Lib/reject";
import approve from "../../../Lib/approve";
import { GetAccess } from "../../../Lib/GetAccess";
import UpdateRecord from "../../../Lib/updateRecord";
import { MdDelete } from "react-icons/md";
import ReadRecord from "../../../Lib/API/ReadRecord.js";
import "../../../Styles/Part3.css";

const initialState = {
  Id: "",
  FormetNo: "",
  TrackDate: "",
  McNo: "",
  MatNme: "",
  NoCav: "",
  PrtNme: "",
  GrdNme: "",
  Colour: "",
  PartWeight1: "",
  PartWeight2: "",
  PartWeightSetMinus1: "",
  PartWeightSetMinus2: "",
  PartWeightSetPlus1: "",
  PartWeightSetPlus2: "",
  Runn: "",
  Inj101: "",
  Inj102: "",
  Inj103: "",
  Inj104: "",
  Modl: "",
  Inj201: "",
  Inj202: "",
  Inj203: "",
  Inj204: "",
  Inj301: "",
  Inj302: "",
  Inj303: "",
  Inj304: "",
  Inj401: "",
  Inj402: "",
  Inj403: "",
  Inj404: "",
  FillT104: "",
  Zone1: "",
  Zone2: "",
  Zone3: "",
  Hozz: "",
  Hopp: "",
  Hold101: "",
  Hold201: "",
  Hold301: "",
  Hold401: "",
  Hold102: "",
  Hold202: "",
  Hold302: "",
  Hold402: "",
  Hold103: "",
  Hold203: "",
  Hold303: "",
  Hold403: "",
  Hold104: "",
  Hold204: "",
  Hold304: "",
  Hold404: "",
  Core: "",
  Cavity: "",
  Dely: "",
  MTC: "",
  Fwd: "",
  OnTme: "",
  RetSpd: "",
  SprBushHle: "",
  NozzHole: "",
  ReFill101: "",
  ReFill102: "",
  ReFill103: "",
  ReFill104: "",
  ReFill201: "",
  ReFill202: "",
  ReFill203: "",
  ReFill204: "",
  ReFill301: "",
  ReFill302: "",
  ReFill303: "",
  ReFill304: "",
  Close101: "",
  Close102: "",
  Close103: "",
  Close201: "",
  Close202: "",
  Close203: "",
  Close301: "",
  Close302: "",
  Close303: "",
  EjecFrd101: "",
  EjecFrd102: "",
  EjecFrd103: "",
  EjecFrd104: "",
  EjecFrd201: "",
  EjecFrd202: "",
  EjecFrd203: "",
  EjecFrd204: "",
  EjecRet101: "",
  EjecRet102: "",
  EjecRet103: "",
  EjecRet104: "",
  EjecRet201: "",
  EjecRet202: "",
  EjecRet203: "",
  EjecRet204: "",
  RefillEnd: "",
  MldSfy101: "",
  MldSfy201: "",
  MldSfy301: "",
  MldSfy102: "",
  MldSfy202: "",
  MldSfy302: "",
  SuckBack: "",
  Bp101: "",
  Bp102: "",
  Bp103: "",
  Bp104: "",
  MoldSafeT: "",
  Actual1: "",
  Bp201: "",
  Bp202: "",
  Bp203: "",
  Bp204: "",
  Melt101: "",
  Melt102: "",
  Melt103: "",
  Melt104: "",
  Open401: "",
  Open402: "",
  Open403: "",
  Open201: "",
  Open202: "",
  Open203: "",
  Open301: "",
  Open302: "",
  Open303: "",
  Core301: "",
  Core302: "",
  Core303: "",
  Core101: "",
  Core102: "",
  Core103: "",
  Core201: "",
  Core202: "",
  Core203: "",
  Core401: "",
  Core402: "",
  Core403: "",
  ManFld1: "",
  ManFld2: "",
  ManFld3: "",
  ManFld4: "",
  ManFld5: "",
  Gte2: "",
  Gte3: "",
  Gte4: "",
  Gte5: "",
  Gte6: "",
  Gate1: "",
  Gate2: "",
  Gate3: "",
  Gate4: "",
  Gate5: "",
  Gate6: "",
  Gate7: "",
  Gate8: "",
  Gate9: "",
  Gate10: "",
  Gate11: "",
  Gate12: "",
  Cre1: "",
  Cre2: "",
  Cre3: "",
  Cre4: "",
  Oilt: "",
  Cycletme: "",
  Cooltme: "",
  Switch: "",
  NozzSet: "",
  ZneSet1: "",
  ZneSet2: "",
  ZneSet3: "",
  HoppSet: "",
  CoreSet: "",
  CavSet: "",
  MtcSet: "",
  InjPresSet: "",
  InjSpdSet: "",
  InjPosSet: "",
  InjTmeSet: "",
  CycTmeSet: "",
  CoolTmeSet: "",
  CusSet: "",
  ManiFldSet1: "",
  ManiFldSet2: "",
  ManiFldSet3: "",
  ManiFldSet4: "",
  ManiFldSet5: "",
  GteSet: "",
  OnSet: "",
  OffSet: "",
  ReFillPresSet: "",
  ReFillSpdSet: "",
  ReFillPosSet: "",
  ReFillTmeSet: "",
  ClsePresSet: "",
  ClseSpdSet: "",
  EjcPresSet: "",
  EjcSpdSet: "",
  SukSet: "",
  OilSet: "",
  Zone4: "",
  Zone5: "",
  Zone6: "",
  ZneSet6: "",
  ZneSet5: "",
  ZneSet4: "",
  ApprovedBy: "",
  PreparedBy: "",
  prepared_by: "",
  approved_by: "",
  viewMode: false,
  isPaginationVisible: true,
  filterDate: "",
  filterMcno: "",
  filterPrtNme: "",
  Approval: "",
  currentPage: 1
}

function SmpView() {
  const [isCreating, setIsCreating] = useState(false);
  const [smpReportState, setSmpReportState] = useState(initialState);
  // const [partDropdown, setPartDropdown] = useState([]);
  // const [dropdown, setDropdown] = useState([]);
  const [ButtonPopup, setButtonPopup] = useState(false);
  const [ButtonPopup2, setButtonPopup2] = useState(false);
  const [std_mld_par, setstd_mld_par] = useState([]);
  const [Employee, setEmployee] = useState([]);
  const [RawMaterialDropdown, setRawMaterialDropdown] = useState([]);

  const [downloadEnabled, setDownloadEnabled] = useState(true);
  const [back, setback] = useState(false);
  const navigate = useNavigate();
  const { Id } = useParams();
  const [ENC, setENC] = useState("");
  const [remarks, setremarks] = useState('');
  const [cardId, setcardId] = useState('');
  const [Approver, setApprover] = useState("");
  const [RejectedReason, setRejectedReason] = useState("");
  useEffect(() => {
    // console.log(id);
    if (Id !== undefined) {
      GetRecord(
        {
          _keyword_: "Get_SMP_by_id",
          Id: Id,
        },
        setENC
      ).then(async (res) => {
        console.log("res - ", res);
        handleViews(res[0])
        setButtonPopup2(true)
        setback(true)

        await timeout(1000);
        const popupDiv = document.querySelector(".popup");
        if (popupDiv) {
          const elementInsidePopup = popupDiv.querySelector("#close-btn");
          elementInsidePopup.style.display = "none";
        }
      })
    }
  }, [Id])
  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }
  function handleReadRecord(id) {
    ReadRecord({
      _keyword_: "Get_approver_by_empId",
      TableID: id,
      TableName: "Smp",
      Approver: localStorage.getItem("empId"),
    }).then((res) => {
      // console.log("res.data,",res);
      if (res.rows > 0) {
        setApprover(res.data[0].Approver);
        setRejectedReason(res.data[0].ApprovalRemark);
      } else {
        setApprover("");
        setRejectedReason("");
      }
    }).then(() => {
      ReadRecord({
        _keyword_: "Get_approver_ApprovalRemark",
        TableID: id,
        TableName: "Smp"
      }).then((res) => {
        if (res.rows > 0) {
          setRejectedReason(res.data[0].ApprovalRemark);
        } else {
          setRejectedReason("");
        }
      })
    })
  }
  const recordPerPage = 20;


  const filterRecords = () => {
    const filteredData = std_mld_par.filter((record) => {
      const dateMatch = record.TrackDate && record.TrackDate.includes(smpReportState.filterDate);
      const mcnoMatch = record.McNo && record.McNo.toLowerCase().includes(smpReportState.filterMcno.toLowerCase());
      const prtNmeMatch = record.PrtNme && record.PrtNme.toLowerCase().includes(smpReportState.filterPrtNme.toLowerCase());
      return dateMatch && mcnoMatch && prtNmeMatch;
    });

    return filteredData;
  };

  const filteredData = filterRecords();
  const npage = Math.ceil(filteredData.length / recordPerPage);
  const numbers = [...Array(npage).keys()].map((num) => num + 1);

  function handleFilterDateChange(event) {
    setSmpReportState({ ...smpReportState, filterDate: event.target.value, currentPage: 1 });
  }

  function handleFilterMcnoChange(event) {
    setSmpReportState({ ...smpReportState, filterMcno: event.target.value, currentPage: 1 });
  }

  function handleFilterPartNameChange(event) {
    setSmpReportState({ ...smpReportState, filterPrtNme: event.target.value, currentPage: 1 });
  }

  const firstIndex = (smpReportState.currentPage - 1) * recordPerPage;
  const lastIndex = firstIndex + recordPerPage;
  const filteredRecords = filteredData.slice(firstIndex, lastIndex);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}-${year}`;
  };

  function prePage() {
    if (smpReportState.currentPage > 1) {
      setSmpReportState({ ...smpReportState, currentPage: prev => prev - 1 });
    }
  }

  function nextPage() {
    if (smpReportState.currentPage < npage) {
      setSmpReportState({ ...smpReportState, currentPage: prev => prev + 1 });
    }
  }

  function changeCpage(Id) {
    setSmpReportState({ ...smpReportState, currentPage: Id });
  }

  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;
  const today = new Date().toISOString().split('T')[0];

  const handleDateChange = (event) => {
    const selected = event.target.value;

    if (selected >= today) {
      setSmpReportState({ ...smpReportState, TrackDate: selected });
    }
    if (selected > today) {
      alert(GeneralConstants.SELECTED_FUTURE_DATE);
    }
  };
  const rawMaterialOptions = RawMaterialDropdown.map((i) => ({
    value: i.Rm_code,
    label: i.Rm_code,
  }));

  const handleUpdate = (e) => {
    e.preventDefault();

    if (!smpReportState.McNo || !smpReportState.PrtNme) {
      console.log("machinealert");
      setIsCreating(true);
      alert(GeneralConstants.FILL_MACHINE_AND_PART);
      return;
    }
    console.log("materialAlert", smpReportState.GrdNme);

    if (!smpReportState.MatNme || !smpReportState.GrdNme || !smpReportState.Colour) {

      setIsCreating(true);
      alert(GeneralConstants.REQUIRED_ALERT);
      return;
    }
    const data = {
      data: [
        {
          Id: smpReportState.Id,
          TrackDate: smpReportState.TrackDate,
          McNo: smpReportState.McNo,
          MatNme: smpReportState.MatNme.value,
          NoCav: smpReportState.NoCav,
          PrtNme: smpReportState.PrtNme,
          GrdNme: smpReportState.GrdNme,
          Colour: smpReportState.Colour,
          Runn: smpReportState.Runn,
          Inj101: smpReportState.Inj101,
          Inj102: smpReportState.Inj102,
          Inj103: smpReportState.Inj103,
          Inj104: smpReportState.Inj104,
          Modl: smpReportState.Modl,
          Inj201: smpReportState.Inj201,
          Inj202: smpReportState.Inj202,
          Inj203: smpReportState.Inj203,
          Inj204: smpReportState.Inj204,
          Inj301: smpReportState.Inj301,
          Inj302: smpReportState.Inj302,
          Inj303: smpReportState.Inj303,
          Inj304: smpReportState.Inj304,
          Inj401: smpReportState.Inj401,
          Inj402: smpReportState.Inj402,
          Inj403: smpReportState.Inj403,
          Inj404: smpReportState.Inj404,
          FillT104: smpReportState.FillT104,
          Zone2: smpReportState.Zone2,
          Zone1: smpReportState.Zone1,
          Zone3: smpReportState.Zone3,
          Hozz: smpReportState.Hozz,
          Hopp: smpReportState.Hopp,
          Hold101: smpReportState.Hold101,
          Hold201: smpReportState.Hold201,
          Hold301: smpReportState.Hold301,
          Hold401: smpReportState.Hold401,
          Hold102: smpReportState.Hold102,
          Hold202: smpReportState.Hold202,
          Hold302: smpReportState.Hold302,
          Hold402: smpReportState.Hold402,
          Hold103: smpReportState.Hold103,
          Hold203: smpReportState.Hold203,
          Hold303: smpReportState.Hold303,
          Hold403: smpReportState.Hold403,
          Hold104: smpReportState.Hold104,
          Hold204: smpReportState.Hold204,
          Hold304: smpReportState.Hold304,
          Hold404: smpReportState.Hold404,
          Core: smpReportState.Core,
          Cavity: smpReportState.Cavity,
          Dely: smpReportState.Dely,
          MTC: smpReportState.MTC,
          Fwd: smpReportState.Fwd,
          OnTme: smpReportState.OnTme,
          RetSpd: smpReportState.RetSpd,
          SprBushHle: smpReportState.SprBushHle,
          NozzHole: smpReportState.NozzHole,
          ReFill101: smpReportState.ReFill101,
          ReFill102: smpReportState.ReFill102,
          ReFill103: smpReportState.ReFill103,
          ReFill104: smpReportState.ReFill104,
          ReFill201: smpReportState.ReFill201,
          ReFill202: smpReportState.ReFill202,
          ReFill203: smpReportState.ReFill203,
          ReFill204: smpReportState.ReFill204,
          ReFill301: smpReportState.ReFill301,
          ReFill302: smpReportState.ReFill302,
          ReFill303: smpReportState.ReFill303,
          ReFill304: smpReportState.ReFill304,
          Close101: smpReportState.Close101,
          Close102: smpReportState.Close102,
          Close103: smpReportState.Close103,
          Close201: smpReportState.Close201,
          Close202: smpReportState.Close202,
          Close203: smpReportState.Close203,
          Close301: smpReportState.Close301,
          Close302: smpReportState.Close302,
          Close303: smpReportState.Close303,
          EjecFrd101: smpReportState.EjecFrd101,
          EjecFrd102: smpReportState.EjecFrd102,
          EjecFrd103: smpReportState.EjecFrd103,
          EjecFrd104: smpReportState.EjecFrd104,
          EjecFrd201: smpReportState.EjecFrd201,
          EjecFrd202: smpReportState.EjecFrd202,
          EjecFrd203: smpReportState.EjecFrd203,
          EjecFrd204: smpReportState.EjecFrd204,
          EjecRet101: smpReportState.EjecRet101,
          EjecRet102: smpReportState.EjecRet102,
          EjecRet103: smpReportState.EjecRet103,
          EjecRet104: smpReportState.EjecRet104,
          EjecRet201: smpReportState.EjecRet201,
          EjecRet202: smpReportState.EjecRet202,
          EjecRet203: smpReportState.EjecRet203,
          EjecRet204: smpReportState.EjecRet204,
          RefillEnd: smpReportState.RefillEnd,
          MldSfy101: smpReportState.MldSfy101,
          MldSfy201: smpReportState.MldSfy201,
          MldSfy301: smpReportState.MldSfy301,
          MldSfy102: smpReportState.MldSfy102,
          MldSfy202: smpReportState.MldSfy202,
          MldSfy302: smpReportState.MldSfy302,
          SuckBack: smpReportState.SuckBack,
          Bp101: smpReportState.Bp101,
          Bp102: smpReportState.Bp102,
          Bp103: smpReportState.Bp103,
          Bp104: smpReportState.Bp104,
          MoldSafeT: smpReportState.MoldSafeT,
          Actual1: smpReportState.Actual1,
          Bp201: smpReportState.Bp201,
          Bp202: smpReportState.Bp202,
          Bp203: smpReportState.Bp203,
          Bp204: smpReportState.Bp204,
          Melt101: smpReportState.Melt101,
          Melt102: smpReportState.Melt102,
          Melt103: smpReportState.Melt103,
          Melt104: smpReportState.Melt104,
          Open401: smpReportState.Open401,
          Open402: smpReportState.Open402,
          Open403: smpReportState.Open403,
          Open201: smpReportState.Open201,
          Open202: smpReportState.Open202,
          Open203: smpReportState.Open203,
          Open301: smpReportState.Open301,
          Open302: smpReportState.Open302,
          Open303: smpReportState.Open303,
          Core301: smpReportState.Core301,
          Core302: smpReportState.Core302,
          Core303: smpReportState.Core303,
          Core101: smpReportState.Core101,
          Core102: smpReportState.Core102,
          Core103: smpReportState.Core103,
          Core201: smpReportState.Core201,
          Core202: smpReportState.Core202,
          Core203: smpReportState.Core203,
          Core401: smpReportState.Core401,
          Core402: smpReportState.Core402,
          Core403: smpReportState.Core403,
          Oilt: smpReportState.Oilt,
          Cycletme: smpReportState.Cycletme,
          Cooltme: smpReportState.Cooltme,
          Switch: smpReportState.Switch,
          ManFld1: smpReportState.ManFld1,
          ManFld2: smpReportState.ManFld2,
          ManFld3: smpReportState.ManFld3,
          ManFld4: smpReportState.ManFld4,
          ManFld5: smpReportState.ManFld5,
          Gte2: smpReportState.Gte2,
          Gte3: smpReportState.Gte3,
          Gte4: smpReportState.Gte4,
          Gte5: smpReportState.Gte5,
          Gte6: smpReportState.Gte6,
          Gate1: smpReportState.Gate1,
          Gate2: smpReportState.Gate2,
          Gate3: smpReportState.Gate3,
          Gate4: smpReportState.Gate4,
          Gate5: smpReportState.Gate5,
          Gate6: smpReportState.Gate6,
          Gate7: smpReportState.Gate7,
          Gate8: smpReportState.Gate8,
          Gate9: smpReportState.Gate9,
          Gate10: smpReportState.Gate10,
          Gate11: smpReportState.Gate11,
          Gate12: smpReportState.Gate12,
          Cre1: smpReportState.Cre1,
          Cre2: smpReportState.Cre2,
          Cre3: smpReportState.Cre3,
          Cre4: smpReportState.Cre4,
          NozzSet: smpReportState.NozzSet,
          ZneSet1: smpReportState.ZneSet1,
          ZneSet2: smpReportState.ZneSet2,
          ZneSet3: smpReportState.ZneSet3,
          HoppSet: smpReportState.HoppSet,
          CoreSet: smpReportState.CoreSet,
          CavSet: smpReportState.CavSet,
          MtcSet: smpReportState.MtcSet,
          InjPresSet: smpReportState.InjPresSet,
          InjSpdSet: smpReportState.InjSpdSet,
          InjPosSet: smpReportState.InjPosSet,
          InjTmeSet: smpReportState.InjTmeSet,
          CycTmeSet: smpReportState.CycTmeSet,
          CoolTmeSet: smpReportState.CoolTmeSet,
          CusSet: smpReportState.CusSet,
          ManiFldSet1: smpReportState.ManiFldSet1,
          ManiFldSet2: smpReportState.ManiFldSet2,
          ManiFldSet3: smpReportState.ManiFldSet3,
          ManiFldSet4: smpReportState.ManiFldSet4,
          ManiFldSet5: smpReportState.ManiFldSet5,
          GteSet: smpReportState.GteSet,
          OnSet: smpReportState.OnSet,
          OffSet: smpReportState.OffSet,
          ReFillPresSet: smpReportState.ReFillPresSet,
          ReFillSpdSet: smpReportState.ReFillSpdSet,
          ReFillPosSet: smpReportState.ReFillPosSet,
          ReFillTmeSet: smpReportState.ReFillTmeSet,
          ClsePresSet: smpReportState.ClsePresSet,
          ClseSpdSet: smpReportState.ClseSpdSet,
          EjcSpdSet: smpReportState.EjcSpdSet,
          EjcPresSet: smpReportState.EjcPresSet,
          SukSet: smpReportState.SukSet,
          OilSet: smpReportState.OilSet,
          Zone4: smpReportState.Zone4,
          Zone5: smpReportState.Zone5,
          Zone6: smpReportState.Zone6,
          ZneSet4: smpReportState.ZneSet4,
          ZneSet5: smpReportState.ZneSet5,
          ZneSet6: smpReportState.ZneSet6,
          PreparedBy: smpReportState.PreparedBy.value,
          ApprovedBy: smpReportState.ApprovedBy.value,
        }
      ],
    };
    data["_keyword_"] = "std_mld_par";
    data["secretkey"] = SECRET_KEY;

    getAPIRequest(
      PROCESS_TRANSACTION_UPDATE_URL, 'POST', data
    )
      // .then((response) => {
      //   setstd_mld_par([...std_mld_par, response.data]);
      // })

      .then(() => {
        setSmpReportState({
          ...smpReportState,
          isPaginationVisible: true,
        });
        alert(GeneralConstants.UPDATE_SUCCESSFUL);
        // window.location.reload();
        setButtonPopup(false);
        handleReadStandMldPar();

      })
      .catch((error) => {
        console.log(error);
        alert(GeneralConstants.UPDATE_FAILURE);
        window.location.reload();
      });
  };

  const handleDownload = () => {
    window.print();
    window.location.reload();
  };

  const handleViews = (e) => {
    setDownloadEnabled(true)
    handleReadRecord(e.Id);
    setButtonPopup2(true);
    const formatDate = (date) => {
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear();
      return `${day
        .toString()
        .padStart(2, "0")}-${month.toString().padStart(2, "0")}-${year}`;
    };
    const trackDate = new Date(e.TrackDate);
    const formattedDate = formatDate(trackDate);

    setSmpReportState({
      ...smpReportState,
      TrackDate: formattedDate,
      isPaginationVisible: false,
      McNo: e.McNo,
      MatNme: e.RawMaterials,
      NoCav: e.NoCav,
      PrtNme: e.PrtNme,
      GrdNme: e.GrdNme,
      Colour: e.Colour,
      PartWeight1: e.part_weight_1,
      PartWeightSetMinus1: e.part_weight_set_minus_1,
      PartWeightSetPlus1: e.part_weight_set_plus_1,
      PartWeight2: e.part_weight_2,
      PartWeightSetMinus2: e.part_weight_set_minus_2,
      PartWeightSetPlus2: e.part_weight_set_plus_2,
      Runn: e.Runn,
      Inj101: e.Inj101,
      Inj102: e.Inj102,
      Inj103: e.Inj103,
      Inj104: e.Inj104,
      Modl: e.Modl,
      Inj201: e.Inj201,
      Inj202: e.Inj202,
      Inj203: e.Inj203,
      Inj204: e.Inj204,
      Inj301: e.Inj301,
      Inj302: e.Inj302,
      Inj303: e.Inj303,
      Inj304: e.Inj304,
      Inj401: e.Inj401,
      Inj402: e.Inj402,
      Inj403: e.Inj403,
      Inj404: e.Inj404,
      FillT104: e.FillT104,
      Zone2: e.Zone2,
      Zone1: e.Zone1,
      Zone3: e.Zone3,
      Hozz: e.Hozz,
      Hopp: e.Hopp,
      Hold101: e.Hold101,
      Hold201: e.Hold201,
      Hold301: e.Hold301,
      Hold401: e.Hold401,
      Hold102: e.Hold102,
      Hold202: e.Hold202,
      Hold302: e.Hold302,
      Hold402: e.Hold402,
      Hold103: e.Hold103,
      Hold203: e.Hold203,
      Hold303: e.Hold303,
      Hold403: e.Hold403,
      Hold104: e.Hold104,
      Hold204: e.Hold204,
      Hold304: e.Hold304,
      Hold404: e.Hold404,
      Core: e.Core,
      Cavity: e.Cavity,
      Dely: e.Dely,
      MTC: e.MTC,
      Fwd: e.Fwd,
      OnTme: e.OnTme,
      RetSpd: e.RetSpd,
      SprBushHle: e.SprBushHle,
      NozzHole: e.NozzHole,
      ReFill101: e.ReFill101,
      ReFill102: e.ReFill102,
      ReFill103: e.ReFill103,
      ReFill104: e.ReFill104,
      ReFill201: e.ReFill201,
      ReFill202: e.ReFill202,
      ReFill203: e.ReFill203,
      ReFill204: e.ReFill204,
      ReFill301: e.ReFill301,
      ReFill302: e.ReFill302,
      ReFill303: e.ReFill303,
      ReFill304: e.ReFill304,
      Close101: e.Close101,
      Close102: e.Close102,
      Close103: e.Close103,
      Close201: e.Close201,
      Close202: e.Close202,
      Close203: e.Close203,
      Close301: e.Close301,
      Close302: e.Close302,
      Close303: e.Close303,
      EjecFrd101: e.EjecFrd101,
      EjecFrd102: e.EjecFrd102,
      EjecFrd103: e.EjecFrd103,
      EjecFrd104: e.EjecFrd104,
      EjecFrd201: e.EjecFrd201,
      EjecFrd202: e.EjecFrd202,
      EjecFrd203: e.EjecFrd203,
      EjecFrd204: e.EjecFrd204,
      EjecRet101: e.EjecRet101,
      EjecRet102: e.EjecRet102,
      EjecRet103: e.EjecRet103,
      EjecRet104: e.EjecRet104,
      EjecRet201: e.EjecRet201,
      EjecRet202: e.EjecRet202,
      EjecRet203: e.EjecRet203,
      EjecRet204: e.EjecRet204,
      RefillEnd: e.RefillEnd,
      MldSfy101: e.MldSfy101,
      MldSfy201: e.MldSfy201,
      MldSfy301: e.MldSfy301,
      MldSfy102: e.MldSfy102,
      MldSfy202: e.MldSfy202,
      MldSfy302: e.MldSfy302,
      SuckBack: e.SuckBack,
      Bp101: e.Bp101,
      Bp102: e.Bp102,
      Bp103: e.Bp103,
      Bp104: e.Bp104,
      MoldSafeT: e.MoldSafeT,
      Actual1: e.Actual1,
      Bp201: e.Bp201,
      Bp202: e.Bp202,
      Bp203: e.Bp203,
      Bp204: e.Bp204,
      Melt101: e.Melt101,
      Melt102: e.Melt102,
      Melt103: e.Melt103,
      Melt104: e.Melt104,
      Open401: e.Open401,
      Open402: e.Open402,
      Open403: e.Open403,
      Open201: e.Open201,
      Open202: e.Open202,
      Open203: e.Open203,
      Open301: e.Open301,
      Open302: e.Open302,
      Open303: e.Open303,
      Core301: e.Core301,
      Core302: e.Core302,
      Core303: e.Core303,
      Core101: e.Core101,
      Core102: e.Core102,
      Core103: e.Core103,
      Core201: e.Core201,
      Core202: e.Core202,
      Core203: e.Core203,
      Core401: e.Core401,
      Core402: e.Core402,
      Core403: e.Core403,
      Oilt: e.Oilt,
      Cycletme: e.Cycletme,
      Cooltme: e.Cooltme,
      Switch: e.Switch,
      ManFld1: e.ManFld1,
      ManFld2: e.ManFld2,
      ManFld3: e.ManFld3,
      ManFld4: e.ManFld4,
      ManFld5: e.ManFld5,
      Gte2: e.Gte2,
      Gte3: e.Gte3,
      Gte4: e.Gte4,
      Gte5: e.Gte5,
      Gte6: e.Gte6,
      Gate1: e.Gate1,
      Gate2: e.Gate2,
      Gate3: e.Gate3,
      Gate4: e.Gate4,
      Gate5: e.Gate5,
      Gate6: e.Gate6,
      Gate7: e.Gate7,
      Gate8: e.Gate8,
      Gate9: e.Gate9,
      Gate10: e.Gate10,
      Gate11: e.Gate11,
      Gate12: e.Gate12,
      Cre1: e.Cre1,
      Cre2: e.Cre2,
      Cre3: e.Cre3,
      Cre4: e.Cre4,
      NozzSet: e.NozzSet,
      ZneSet1: e.ZneSet1,
      ZneSet2: e.ZneSet2,
      ZneSet3: e.ZneSet3,
      HoppSet: e.HoppSet,
      CoreSet: e.CoreSet,
      CavSet: e.CavSet,
      MtcSet: e.MtcSet,
      InjPresSet: e.InjPresSet,
      InjSpdSet: e.InjSpdSet,
      InjPosSet: e.InjPosSet,
      InjTmeSet: e.InjTmeSet,
      CycTmeSet: e.CycTmeSet,
      CoolTmeSet: e.CoolTmeSet,
      CusSet: e.CusSet,
      ManiFldSet1: e.ManiFldSet1,
      ManiFldSet2: e.ManiFldSet2,
      ManiFldSet3: e.ManiFldSet3,
      ManiFldSet4: e.ManiFldSet4,
      ManiFldSet5: e.ManiFldSet5,
      GteSet: e.GteSet,
      OnSet: e.OnSet,
      OffSet: e.OffSet,
      ReFillPresSet: e.ReFillPresSet,
      ReFillSpdSet: e.ReFillSpdSet,
      ReFillPosSet: e.ReFillPosSet,
      ReFillTmeSet: e.ReFillTmeSet,
      ClsePresSet: e.ClsePresSet,
      ClseSpdSet: e.ClseSpdSet,
      EjcSpdSet: e.EjcSpdSet,
      EjcPresSet: e.EjcPresSet,
      SukSet: e.SukSet,
      OilSet: e.OilSet,
      Zone4: e.Zone4,
      Zone5: e.Zone5,
      Zone6: e.Zone6,
      ZneSet4: e.ZneSet4,
      ZneSet5: e.ZneSet5,
      ZneSet6: e.ZneSet6,
      PreparedBy: `${e.PreparedBy}-${e.prepared_by}`,
      ApprovedBy: `${e.ApprovedBy}-${e.approved_by}`,
      Approval: e.Approval_status

    })
  };


  const handleEdit = (e) => {
    setButtonPopup(true);
    setSmpReportState({
      ...smpReportState,
      isPaginationVisible: false,
      Id: e.Id,
      TrackDate: e.TrackDate,
      McNo: e.McNo,
      MatNme: { value: e.MatNme, label: e.RawMaterials },
      NoCav: e.NoCav,
      PrtNme: e.PrtNme,
      GrdNme: e.GrdNme,
      Colour: e.Colour,
      PartWeight1: e.part_weight_1,
      PartWeightSetMinus1: e.part_weight_set_minus_1,
      PartWeightSetPlus1: e.part_weight_set_plus_1,
      PartWeight2: e.part_weight_2,
      PartWeightSetMinus2: e.part_weight_set_minus_2,
      PartWeightSetPlus2: e.part_weight_set_plus_2,
      Runn: e.Runn,
      Inj101: e.Inj101,
      Inj102: e.Inj102,
      Inj103: e.Inj103,
      Inj104: e.Inj104,
      Modl: e.Modl,
      Inj201: e.Inj201,
      Inj202: e.Inj202,
      Inj203: e.Inj203,
      Inj204: e.Inj204,
      Inj301: e.Inj301,
      Inj302: e.Inj302,
      Inj303: e.Inj303,
      Inj304: e.Inj304,
      Inj401: e.Inj401,
      Inj402: e.Inj402,
      Inj403: e.Inj403,
      Inj404: e.Inj404,
      FillT104: e.FillT104,
      Zone2: e.Zone2,
      Zone1: e.Zone1,
      Zone3: e.Zone3,
      Hozz: e.Hozz,
      Hopp: e.Hopp,
      Hold101: e.Hold101,
      Hold201: e.Hold201,
      Hold301: e.Hold301,
      Hold401: e.Hold401,
      Hold102: e.Hold102,
      Hold202: e.Hold202,
      Hold302: e.Hold302,
      Hold402: e.Hold402,
      Hold103: e.Hold103,
      Hold203: e.Hold203,
      Hold303: e.Hold303,
      Hold403: e.Hold403,
      Hold104: e.Hold104,
      Hold204: e.Hold204,
      Hold304: e.Hold304,
      Hold404: e.Hold404,
      Core: e.Core,
      Cavity: e.Cavity,
      Dely: e.Dely,
      MTC: e.MTC,
      Fwd: e.Fwd,
      OnTme: e.OnTme,
      RetSpd: e.RetSpd,
      SprBushHle: e.SprBushHle,
      NozzHole: e.NozzHole,
      ReFill101: e.ReFill101,
      ReFill102: e.ReFill102,
      ReFill103: e.ReFill103,
      ReFill104: e.ReFill104,
      ReFill201: e.ReFill201,
      ReFill202: e.ReFill202,
      ReFill203: e.ReFill203,
      ReFill204: e.ReFill204,
      ReFill301: e.ReFill301,
      ReFill302: e.ReFill302,
      ReFill303: e.ReFill303,
      ReFill304: e.ReFill304,
      Close101: e.Close101,
      Close102: e.Close102,
      Close103: e.Close103,
      Close201: e.Close201,
      Close202: e.Close202,
      Close203: e.Close203,
      Close301: e.Close301,
      Close302: e.Close302,
      Close303: e.Close303,
      EjecFrd101: e.EjecFrd101,
      EjecFrd102: e.EjecFrd102,
      EjecFrd103: e.EjecFrd103,
      EjecFrd104: e.EjecFrd104,
      EjecFrd201: e.EjecFrd201,
      EjecFrd202: e.EjecFrd202,
      EjecFrd203: e.EjecFrd203,
      EjecFrd204: e.EjecFrd204,
      EjecRet101: e.EjecRet101,
      EjecRet102: e.EjecRet102,
      EjecRet103: e.EjecRet103,
      EjecRet104: e.EjecRet104,
      EjecRet201: e.EjecRet201,
      EjecRet202: e.EjecRet202,
      EjecRet203: e.EjecRet203,
      EjecRet204: e.EjecRet204,
      RefillEnd: e.RefillEnd,
      MldSfy101: e.MldSfy101,
      MldSfy201: e.MldSfy201,
      MldSfy301: e.MldSfy301,
      MldSfy102: e.MldSfy102,
      MldSfy202: e.MldSfy202,
      MldSfy302: e.MldSfy302,
      SuckBack: e.SuckBack,
      Bp101: e.Bp101,
      Bp102: e.Bp102,
      Bp103: e.Bp103,
      Bp104: e.Bp104,
      MoldSafeT: e.MoldSafeT,
      Actual1: e.Actual1,
      Bp201: e.Bp201,
      Bp202: e.Bp202,
      Bp203: e.Bp203,
      Bp204: e.Bp204,
      Melt101: e.Melt101,
      Melt102: e.Melt102,
      Melt103: e.Melt103,
      Melt104: e.Melt104,
      Open401: e.Open401,
      Open402: e.Open402,
      Open403: e.Open403,
      Open201: e.Open201,
      Open202: e.Open202,
      Open203: e.Open203,
      Open301: e.Open301,
      Open302: e.Open302,
      Open303: e.Open303,
      Core301: e.Core301,
      Core302: e.Core302,
      Core303: e.Core303,
      Core101: e.Core101,
      Core102: e.Core102,
      Core103: e.Core103,
      Core201: e.Core201,
      Core202: e.Core202,
      Core203: e.Core203,
      Core401: e.Core401,
      Core402: e.Core402,
      Core403: e.Core403,
      Oilt: e.Oilt,
      Cycletme: e.Cycletme,
      Cooltme: e.Cooltme,
      Switch: e.Switch,
      ManFld1: e.ManFld1,
      ManFld2: e.ManFld2,
      ManFld3: e.ManFld3,
      ManFld4: e.ManFld4,
      ManFld5: e.ManFld5,
      Gte2: e.Gte2,
      Gte3: e.Gte3,
      Gte4: e.Gte4,
      Gte5: e.Gte5,
      Gte6: e.Gte6,
      Gate1: e.Gate1,
      Gate2: e.Gate2,
      Gate3: e.Gate3,
      Gate4: e.Gate4,
      Gate5: e.Gate5,
      Gate6: e.Gate6,
      Gate7: e.Gate7,
      Gate8: e.Gate8,
      Gate9: e.Gate9,
      Gate10: e.Gate10,
      Gate11: e.Gate11,
      Gate12: e.Gate12,
      Cre1: e.Cre1,
      Cre2: e.Cre2,
      Cre3: e.Cre3,
      Cre4: e.Cre4,
      NozzSet: e.NozzSet,
      ZneSet1: e.ZneSet1,
      ZneSet2: e.ZneSet2,
      ZneSet3: e.ZneSet3,
      HoppSet: e.HoppSet,
      CoreSet: e.CoreSet,
      CavSet: e.CavSet,
      MtcSet: e.MtcSet,
      InjPresSet: e.InjPresSet,
      InjSpdSet: e.InjSpdSet,
      InjPosSet: e.InjPosSet,
      InjTmeSet: e.InjTmeSet,
      CycTmeSet: e.CycTmeSet,
      CoolTmeSet: e.CoolTmeSet,
      CusSet: e.CusSet,
      ManiFldSet1: e.ManiFldSet1,
      ManiFldSet2: e.ManiFldSet2,
      ManiFldSet3: e.ManiFldSet3,
      ManiFldSet4: e.ManiFldSet4,
      ManiFldSet5: e.ManiFldSet5,
      GteSet: e.GteSet,
      OnSet: e.OnSet,
      OffSet: e.OffSet,
      ReFillPresSet: e.ReFillPresSet,
      ReFillSpdSet: e.ReFillSpdSet,
      ReFillPosSet: e.ReFillPosSet,
      ReFillTmeSet: e.ReFillTmeSet,
      ClsePresSet: e.ClsePresSet,
      ClseSpdSet: e.ClseSpdSet,
      EjcSpdSet: e.EjcSpdSet,
      EjcPresSet: e.EjcPresSet,
      SukSet: e.SukSet,
      OilSet: e.OilSet,
      Zone4: e.Zone4,
      Zone5: e.Zone5,
      Zone6: e.Zone6,
      ZneSet4: e.ZneSet4,
      ZneSet5: e.ZneSet5,
      ZneSet6: e.ZneSet6,
      PreparedBy: { value: e.PreparedBy, label: e.prepared_by },
      ApprovedBy: { value: e.ApprovedBy, label: e.approved_by }
    })
  };
  const handlePreparedBy = (selectedOption) => {
    const selected = Employee.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setSmpReportState({
        ...smpReportState,
        PreparedBy: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      });
    }
  };
  const handleApprovedBy = (selectedOption) => {
    const selected = Employee.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setSmpReportState({
        ...smpReportState,
        ApprovedBy: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      });
    }
  };

  const viewPagination = () => {
    setSmpReportState({ ...smpReportState, isPaginationVisible: true })
  };
  const tableRef = useRef(null);

  const onButtonClick = () => {
    setDownloadEnabled(false);
    // Hide the dropdown before capturing the HTML content
    const dropdown = document.querySelector('.SmpDownload');
    if (dropdown) {
      dropdown.style.display = 'none';
    }
    const pdf = new jsPDF("landscape", "mm", "a4");
    html2canvas(tableRef.current).then((canvas) => {
      // Show the dropdown after capturing the HTML content
      if (dropdown) {
        dropdown.style.display = 'block';
      }

      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 10, 5, 280, 200);
      pdf.save(`SMP ${GeneralConstants.CURRENT_Date}.pdf`);
      setDownloadEnabled(true);
    });
  };
  const pagestyle = `@media all {
    .page-break {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @media print and (orientation: landscape) {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }
  
  @page {
    size: auto;
    margin: 15mm;
  }`;

  const exportToExcel = () => {
    const tables = ['excelTable1', 'excelTable2', 'excelTable3', 'excelTable4']; 
    const workbook = XLSX.utils.book_new();
    const wsAllTables = XLSX.utils.aoa_to_sheet([["STANDARD MOULDING PARAMETER"], ...tableToAoa('excelTable1'), ...tableToAoa('excelTable2'), ...tableToAoa('excelTable3'), ...tableToAoa('excelTable4')]);

    XLSX.utils.book_append_sheet(workbook, wsAllTables, 'AllTables');

    XLSX.writeFile(workbook, `STANDARD MOULDING PARAMETER ${today}).xlsx`);
  };

  function tableToAoa(tableId) {
    const table = document.getElementById(tableId);
    const rows = table.querySelectorAll('tr');
    const data = [];
    rows.forEach((row) => {
      const rowData = [];
      let columnIndex = 0; // Keep track of the current column index
      const cells = row.querySelectorAll('td, th');
      cells.forEach((cell) => {
        const colSpan = cell.getAttribute('colSpan');
        const cellData = cell.textContent;
        if (colSpan) {
          for (let i = 0; i < parseInt(colSpan); i++) {
            if (i === 0) {
              rowData.push(cellData); // Only add the value for the first cell when colSpan exists
            } else {
              rowData.push(''); // For the other cells within the colSpan, add empty values
            }
            columnIndex++;
          }
        } else {
          rowData.push(cellData);
          columnIndex++;
        }
      });
      data.push(rowData);
    });
    return data;
  }
  const handleRawMaterial = (selectedOption) => {
    console.log("selectedOption", selectedOption);
    const selected = RawMaterialDropdown.find(
      (option) => option.Rm_code === selectedOption.value
    );
    console.log("selected", selected);
    if (selected) {
      console.log("selected.Rm_grade", selected.Rm_grade);
      let grade = selected.Rm_grade;
      if (selected.Rm_grade === undefined) {
        grade = "";
      }
      // alert(grade);
      setSmpReportState({
        ...smpReportState,
        MatNme: {
          value: selected.Rm_code,
          label: selected.Rm_code,
        },
        GrdNme: grade,

      })
    }
  };
  function GetSelectValue(value, keyword, setName) {

    if (value.length > 0) {
      ReadRecord({
        _keyword_: keyword,
        key: "%" + value + "%",
        value: "%" + value + "%"
      }).then((res) => {
        console.log(res.data);
        setName(res.data);
        // setKey(keyvalue[options.reference]);
      });
    }
  }
  function handleReadStandMldPar() {
    ReadRecord({
      _keyword_: "std_mld_par"
    }).then((res) => {
      // console.log(res.data);
      setstd_mld_par(res.data);
    });
  }
  useEffect(() => {
    handleReadStandMldPar();
  }, [])
  return (
    <div>
      <div>
        <Popup
          trigger={ButtonPopup2}
          setTrigger={setButtonPopup2}
          viewPagination={viewPagination}
        >

          <br></br>
          {Approver === localStorage.getItem("empId") ?

            (smpReportState.Approval === "0" ? <div>{back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}<span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#DA251A', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>This Form has been Rejected due to {RejectedReason}</span></div> : smpReportState.Approval === "1" ? <div>{back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}<span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#B9A44C', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>It is Approved Already, Contact Administrator if any change needed </span></div> :
              back && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>
                <div style={{ display: 'flex', width: '25%', gap: '5', justifyContent: 'space-between', alignItems: 'center', marginRight: "10px" }}><button
                  className="icon"
                  style={{ backgroundColor: "#06802f", marginRight: '10px' }}
                  type="button"
                  onClick={(e) => {
                    approve(Id, "Smp", "std_mld_par")
                    navigate(-1)
                  }}
                >
                  Approve
                </button>
                  {/* {console.log(Id,"212"+Id)} */}
                  <button
                    className="icon"
                    style={{ backgroundColor: "#9d080f" }}
                    type="button"
                    onClick={() => { setcardId(Id) }}
                  >
                    Reject
                  </button>
                  {Id === cardId && <div><div style={{ backgroundColor: 'gray', filter: 'blur(8px)', position: 'absolute', opacity: '.8', right: '0%', bottom: '0%', width: '100%', height: '100%' }}></div><div style={{ position: 'absolute', right: '32%', color: 'white', bottom: '35%', padding: '20px', borderRadius: '10px' }}><h3>Remarks</h3><textarea rows={5} cols={50} onChange={e => { setremarks(e.target.value) }}></textarea><button onClick={e => { reject(Id, "Smp", remarks, "std_mld_par"); navigate(-1) }}>Submit</button><button onClick={() => { setcardId('') }}>Cancel</button></div></div>}
                </div>
              </div>) :
            (smpReportState.Approval === "0" ? <div>{back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}<span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#DA251A', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>This Form has been Rejected due to {RejectedReason}</span></div> : smpReportState.Approval === "1" ? <div>{back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}<span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#B9A44C', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>It is Approved Already, Contact Administrator if any change needed </span></div> : (back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>))
          }

          {/* {back&&<div style={{fontSize:'16px',fontWeight:'bolder',backgroundColor:'#86BBd8', width:'90px',padding:'10px',marginLeft:"20px",borderRadius:'10px',border:'2px solid #005C9F', cursor:'pointer'}} onClick={()=>{navigate(-1)}}><FaBackspace size={20}/> Back</div>} */}
          <br></br>
          <form name="smp.View.formContainer" className="form-smp-View1" >

            <div ref={tableRef} >
              <table id="excelTable1" width="100%" style={{ textAlign: "center" }} border="0">
                <thead>
                  <th style={{ textAlign: "center" }}>
                    <img src={Unitechlogo} style={{ width: "50px" }}></img>
                  </th>
                  <th
                    id="smp.View.Title" name="smp.View.Title"
                    style={{
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      fontSize: "22px",
                    }}
                    colSpan={4}
                  >
                    <font color="#8B0000">
                      STANDARD MOULDING PARAMETER
                    </font>
                  </th>
                  <th style={{ width: "20%" }}>
                    {/* <button id="smp-download" name="smp.download" type="button" onClick={() => handleDownload()}>
                    Download
                  </button> */}
                    {downloadEnabled && (
                      <Dropdown style={{ width: '50%', al: "center" }} className="SmpDownload" >
                        <Dropdown.Toggle>
                          Download ⬇️
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <button className="custom-button" onClick={onButtonClick} >
                              PDF ⬇️
                            </button>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            {/* <ReactHTMLTableToExcel
                            id="excelExportButton"
                            className="download-table-xls-button"
                            table={["excelTable1", "excelTable2", "excelTable3", "excelTable4"]}
                            filename="SMP Excel"
                            sheet="sheet1"
                            buttonText="Excel ⬇️"
                          /> */}
                            <button className="custom-button" onClick={exportToExcel}>Export to Excel</button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>)}
                  </th>
                </thead>
                <tr>
                  <td style={{ width: "19%" }}>Format No</td>
                  <td style={{ width: "15%" }}>
                    <label>UPCL/PRO/D/01</label>
                  </td>
                  <td style={{ width: "20%" }}>
                    <td style={{ width: "46%", border: "black" }}>Rev No</td>
                    <td style={{ width: "27%" }}>
                      <label>01</label>
                    </td>
                  </td>
                  <td style={{ width: "14%" }}>
                    Rev Date <label>06-29-2015</label>
                  </td>

                  <td>Date</td>
                  <td>
                    <label id="smp.Viw.date" name="smp.Viw.date">{smpReportState.TrackDate}</label>
                  </td>
                </tr>
                <thead>
                  <th style={{ textAlign: "center" }} colSpan="10"></th>
                </thead>
                <tr>
                  <td>Machine</td>
                  <td>
                    <label id="smp.Viw.mc" name="smp.viw.mc">{smpReportState.McNo}</label>
                  </td>
                  <td>Raw Material Name</td>
                  <td>
                    <label id="smp.Viw.MatNme" name="smp.Viw.MatNme">{smpReportState.MatNme}</label>
                  </td>
                  <td>No Of Cavity</td>
                  <td>
                    <label id="smp.Viw.NoCav" name="smp.Viw.NoCav">{smpReportState.NoCav}</label>
                  </td>
                </tr>
                <tr>
                  <td>Part Name</td>
                  <td>
                    <label id="smp.Viw.PrtNme" name="smp.Viw.PrtNme">{smpReportState.PrtNme}</label>
                  </td>
                  <td>Grade Name</td>
                  <td>
                    <label id="smp.Viw.GrdNme" name="smp.Viw.GrdNme">{smpReportState.GrdNme}</label>
                  </td>
                  <td>Colour</td>
                  <td>
                    <label id="smp.Viw.Colour" name="smp.Viw.Colour">{smpReportState.Colour}</label>
                  </td>
                </tr>
                <tr>
                  <td id="smp.Viw.PrtSet" name="smp.Viw.PrtSet">
                  Part Weight 1: <span style={{ fontWeight: "bold" }}>{smpReportState.PartWeight1}</span><br></br>
                    Part Weight 2: <span style={{ fontWeight: "bold" }}>{smpReportState.PartWeight2}</span>
                    </td>
                  <td>
                  <span style={{ whiteSpace: "nowrap" }}>PW1*: <span style={{ fontWeight: "bold" }}>(-{smpReportState.PartWeightSetPlus1}/+{smpReportState.PartWeightSetMinus1})</span></span><br></br>
                    <span style={{ whiteSpace: "nowrap" }}>PW2*:<span style={{ fontWeight: "bold" }}>(-{smpReportState.PartWeightSetPlus2}/+{smpReportState.PartWeightSetMinus2})</span></span>
                  </td>
                  <td>Runner WT</td>
                  <td>
                    <label id="smp.Viw.Runn" name="smp.Viw.Runn">{smpReportState.Runn}</label>
                  </td>
                  <td>Model</td>
                  <td>
                    <label id="smp.Viw.Modl" name="smp.Viw.Modl">{smpReportState.Modl}</label>
                  </td>
                </tr>
              </table>
              <table id="excelTable2" width="100%" style={{ textAlign: "center" }} border="0">
                <thead>
                  <th id="smp.Viw.InjSet.Title" name="smp.Viw.InjSet.Title" style={{ textAlign: "center" }} colSpan="5">
                    INJECTION SETTING
                  </th>

                  <th style={{ textAlign: "center" }} colSpan="2">
                    TEMPERATURE
                  </th>

                  <th style={{ textAlign: "center" }} colSpan="4">
                    HOT RUNNER SYSTEM TEMPERATURE
                  </th>

                  <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                    SEQEMACE GATE
                  </th>
                  <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                    <tr>
                      <th id="smp.Viw.OnSet"
                        name="smp.Viw.OnSet">ON ±{smpReportState.OnSet}</th>
                    </tr>
                  </th>
                  <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                    <tr>
                      <th id="smp.Viw.OffSet"
                        name="smp.Viw.OffSet">OFF ±{smpReportState.OffSet}</th>
                    </tr>
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: "10%" }}>STAGES</td>
                    <td id="smp.Viw.InjPresSet"
                      name="smp.Viw.InjPresSet">PRESSURE (BAR) ±{smpReportState.InjPresSet}*</td>
                    <td>SPEED% ±{smpReportState.InjSpdSet}*</td>
                    <td style={{ width: "10%" }}>
                      POSITION ±{smpReportState.InjPosSet} MM&#x27E1;
                    </td>
                    <td>TIME(SEC) ±{smpReportState.InjTmeSet}*</td>
                    <td>STAGES</td>
                    <td>BARREL TEMP</td>
                    <td>HOT RUNNER TEMPERATURE</td>
                    <td>CELSIUS<br></br>&deg;C</td>
                    <td>HOT RUNNER TEMPERATURE</td>
                    <td>CELSIUS<br></br>&deg;C ±{smpReportState.GteSet}</td>
                    <td>Gate 1</td>
                    <td>
                      <label>{smpReportState.Gate6}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Gate7}</label>
                    </td>
                  </tr>
                  <tr>
                    <td>Injection 1*</td>
                    <td>
                      <label>{smpReportState.Inj101}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Inj102}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Inj103}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Inj104}</label>
                    </td>
                    <td>Nozzle* ±{smpReportState.NozzSet}&deg;C</td>
                    <td>
                      <label>{smpReportState.Hozz}</label>
                    </td>
                    <td>ManiFold 1 ±{smpReportState.ManiFldSet1}&deg;C</td>
                    <td>
                      <label>{smpReportState.ManFld1}</label>
                    </td>
                    <td>Gate 1</td>
                    <td>
                      <label>{smpReportState.Gate1}</label>
                    </td>
                    <td>Gate 2</td>
                    <td>
                      <label>{smpReportState.Gte2}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Gate8}</label>
                    </td>
                  </tr>
                  <tr>
                    <td>Injection 2*</td>
                    <td>
                      <label>{smpReportState.Inj201}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Inj202}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Inj203}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Inj204}</label>
                    </td>
                    <td>Zone 1* ±{smpReportState.ZneSet1}&deg;C</td>
                    <td>
                      <label>{smpReportState.Zone1}</label>
                    </td>
                    <td>ManiFold 2 ±{smpReportState.ManiFldSet2}&deg;C</td>
                    <td>
                      <label>{smpReportState.ManFld2}</label>
                    </td>
                    <td>Gate 2</td>
                    <td>
                      <label>{smpReportState.Gate2}</label>
                    </td>
                    <td>Gate 3</td>
                    <td>
                      <label>{smpReportState.Gte3}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Gate9}</label>
                    </td>
                  </tr>
                  <tr>
                    <td>Injection 3*</td>
                    <td>
                      <label>{smpReportState.Inj301}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Inj302}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Inj303}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Inj304}</label>
                    </td>
                    <td>Zone 2* ±{smpReportState.ZneSet2}&deg;C</td>
                    <td>
                      <label>{smpReportState.Zone2}</label>
                    </td>
                    <td>ManiFold 3 ±{smpReportState.ManiFldSet3}&deg;C</td>
                    <td>
                      <label>{smpReportState.ManFld3}</label>
                    </td>

                    <td>Gate 3</td>
                    <td>
                      <label>{smpReportState.Gate3}</label>
                    </td>

                    <td>Gate 4</td>
                    <td>
                      <label>{smpReportState.Gte4}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Gate10}</label>
                    </td>
                  </tr>
                  <tr>
                    <td>Injection 4*</td>
                    <td>
                      <label>{smpReportState.Inj401}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Inj402}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Inj403}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Inj404}</label>
                    </td>
                    <td>Zone 3* ±{smpReportState.ZneSet3}&deg;C</td>
                    <td>
                      <label>{smpReportState.Zone3}</label>
                    </td>
                    <td>ManiFold 4 ±{smpReportState.ManiFldSet4}&deg;C</td>
                    <td>
                      <label>{smpReportState.ManFld4}</label>
                    </td>
                    <td>Gate 4</td>
                    <td>
                      <label>{smpReportState.Gate4}</label>
                    </td>
                    <td>Gate 5</td>
                    <td>
                      <label>{smpReportState.Gte5}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Gate11}</label>
                    </td>
                  </tr>
                  <tr>
                    <td>Fill Time(Actual)</td>
                    <td colSpan="3"></td>

                    <td>
                      <label>{smpReportState.FillT104}</label>

                    </td>

                    <td>
                      Zone 4*
                      ±{smpReportState.ZneSet4}&deg;C



                    </td>
                    <td>
                      <label>{smpReportState.Zone4}</label>

                    </td>
                    <td>
                      ManiFold 5
                      ±{smpReportState.ManiFldSet5}&deg;C

                    </td>
                    <td>
                      <label>{smpReportState.ManFld5}</label>

                    </td>

                    <td>Gate 5</td>
                    <td>
                      <label>{smpReportState.Gate5}</label>

                    </td>

                    <td>Gate 6</td>
                    <td>
                      <label>{smpReportState.Gte6}</label>

                    </td>
                    <td>
                      <label>{smpReportState.Gate12}</label>

                    </td>
                  </tr>
                  <tr>
                    <td colSpan={5}></td>{" "}
                    <td>
                      Zone 5* ±
                      {smpReportState.ZneSet5}&deg;C


                    </td>
                    <td>
                      <label>{smpReportState.Zone5}</label>

                    </td>
                    <td colSpan={7}></td>
                  </tr>
                  <tr>
                    <td colSpan={5}></td>{" "}
                    <td>
                      Zone 6* ±
                      {smpReportState.ZneSet6}&deg;C


                    </td>
                    <td>
                      <label>{smpReportState.Zone6}</label>

                    </td>
                    <td colSpan={7}></td>
                  </tr>

                  <tr>
                    <td colSpan="3">Switch Over / Transfer Position(MM)&#x27E1;:</td>
                    <td>
                      <label>{smpReportState.Switch}</label>

                    </td>
                    <td></td>
                    <td>
                      Hopper* ±
                      {smpReportState.HoppSet}&deg;C


                    </td>
                    <td>
                      <label>{smpReportState.Hopp}</label>

                    </td>
                    <td colSpan={7}></td>
                  </tr>
                </tbody>
              </table>
              <table id="excelTable3" width="100%" style={{ textAlign: "center" }} border="0">
                <thead>
                  <th style={{ textAlign: "center" }} colSpan="5"></th>

                  <th style={{ textAlign: "center" }} colSpan="6">
                    INJECTION UNIT SETTINGS
                  </th>

                  <th style={{ textAlign: "center" }} colSpan="4">
                    MOULD TEMP DETAILS
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: "9%" }}>Hold On 1*</td>
                    <td style={{ width: "9%" }}>
                      <label>{smpReportState.Hold101}</label>
                    </td>
                    <td style={{ width: "5.7%" }}>
                      <label>{smpReportState.Hold201}</label>
                    </td>
                    <td style={{ width: "9%" }}>
                      <label>{smpReportState.Hold301}</label>
                    </td>
                    <td style={{ width: "7.8%" }}>
                      <label>{smpReportState.Hold401}</label>
                    </td>
                    <td></td>
                    <td> SPEED%</td>
                    <td></td>
                    <td>SEC</td>
                    <td>SPRUE BREAK MODE&#x27E1;</td>
                    <td>
                      <tr>
                        <td></td>
                      </tr>
                    </td>
                    <td style={{ width: "8.4%" }}>Core* ±{smpReportState.CoreSet}</td>
                    <td>
                      <label>{smpReportState.Core}</label>
                    </td>
                  </tr>
                  <tr>
                    <td>Hold On 2*</td>
                    <td>
                      <label>{smpReportState.Hold102}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Hold202}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Hold302}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Hold402}</label>
                    </td>
                    <td>INJ UNIT&#x27E1;</td>
                    <td>
                      <tr>
                        <td>ON</td>
                      </tr>
                    </td>
                    <td>Sprue Break&#x27E1;</td>
                    <td>
                      <tr>
                        <td style={{ border: "1px solid black" }}>OFF</td>
                      </tr>
                    </td>
                    <td>0 INJ</td>
                    <td></td>
                    <td>Cavity* ±{smpReportState.CavSet}</td>
                    <td>
                      <label>{smpReportState.Cavity}</label>
                    </td>
                  </tr>
                  <tr>
                    <td>Hold On 3*</td>
                    <td>
                      <label>{smpReportState.Hold103}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Hold203}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Hold303}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Hold403}</label>
                    </td>
                    <td>FWD SPD(MM/S)&#x27E1;</td>
                    <td>
                      <label>{smpReportState.Fwd}</label>
                    </td>
                    <td>Delay Time</td>
                    <td>
                      <label>{smpReportState.Dely}</label>
                    </td>
                    <td>1.Front Suck Back</td>
                    <td>3.Rear Suck Back</td>

                    <td>M T C Temp* ±{smpReportState.MtcSet}</td>
                    <td>
                      <label>{smpReportState.MTC}</label>
                    </td>
                  </tr>
                  <tr>
                    <td>Hold On 4*</td>
                    <td>
                      <label>{smpReportState.Hold104}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Hold204}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Hold304}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Hold404}</label>
                    </td>
                    <td>RET SPD(MM/S)&#x27E1;</td>
                    <td>
                      <label>{smpReportState.RetSpd}</label>
                    </td>
                    <td>On Time</td>
                    <td>
                      <label>{smpReportState.OnTme}</label>
                    </td>
                    <td>2.Refill</td>
                    <td>4.Cooling</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colSpan="2">Sprue Bush Hole(MM)&#x27E1;</td>

                    <td>
                      <label>{smpReportState.SprBushHle}</label>
                    </td>
                    <td colSpan="2">Nozzle Hole(MM) &#x27E1;</td>

                    <td>
                      <label>{smpReportState.NozzHole}</label>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <table id="excelTable4" width="100%" style={{ textAlign: "center" }} border="0">
                <thead>
                  <th style={{ textAlign: "center" }} colSpan="5">
                    REFILL SETTINGS
                  </th>

                  <th style={{ textAlign: "center" }} colSpan="4">
                    MOULD CLOSING SETTINGS
                  </th>

                  <th style={{ textAlign: "center" }} colSpan="5">
                    EJECTION SETTINGS
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: "7.5%" }}></td>
                    <td style={{ width: "9%" }}>
                      PRESSURE<br></br>(BAR) ±{smpReportState.ReFillPresSet}
                    </td>
                    <td style={{ width: "5.7%" }}>SPEED% ±{smpReportState.ReFillSpdSet}</td>
                    <td style={{ width: "9%" }}>POSITION(MM) ±{smpReportState.ReFillPosSet}</td>
                    <td style={{ width: "7.8%" }}>TIME<br></br>(SEC) ±{smpReportState.ReFillTmeSet}*</td>
                    <td>STAGES&#x27E1;</td>
                    <td>PRESSURE (BAR)&#x27E1; ±{smpReportState.ClsePresSet}</td>
                    <td>SPEED% &#x27E1; ±{smpReportState.ClseSpdSet}</td>
                    <td>POSITION (MM)&#x27E1;</td>
                    <td>STAGES&#x27E1;</td>
                    <td>PRESSURE (BAR)&#x27E1; ±{smpReportState.EjcPresSet}</td>
                    <td>SPEED% ±{smpReportState.EjcSpdSet}</td>
                    <td>TIME (SEC)</td>
                    <td>POSITION (MM)</td>
                  </tr>
                  <tr>
                    <td>Refill 1&#x27E1;</td>
                    <td>
                      <label>{smpReportState.ReFill101}</label>
                    </td>
                    <td>
                      <label>{smpReportState.ReFill102}</label>
                    </td>
                    <td>
                      <label>{smpReportState.ReFill103}</label>
                    </td>
                    <td>
                      <label>{smpReportState.ReFill104}</label>
                    </td>
                    <td>Close 1</td>
                    <td>
                      <label>{smpReportState.Close101}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Close102}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Close103}</label>
                    </td>
                    <td>EJEC FRD 1</td>
                    <td>
                      <label>{smpReportState.EjecFrd101}</label>
                    </td>
                    <td>
                      <label>{smpReportState.EjecFrd102}</label>
                    </td>
                    <td>
                      <label>{smpReportState.EjecFrd103}</label>
                    </td>
                    <td>
                      <label>{smpReportState.EjecFrd104}</label>
                    </td>
                  </tr>
                  <tr>
                    <td>Refill 2&#x27E1;</td>
                    <td>
                      <label>{smpReportState.ReFill201}</label>
                    </td>
                    <td>
                      <label>{smpReportState.ReFill202}</label>
                    </td>
                    <td>
                      <label>{smpReportState.ReFill203}</label>
                    </td>
                    <td>
                      <label>{smpReportState.ReFill204}</label>
                    </td>
                    <td>Close 2</td>
                    <td>
                      <label>{smpReportState.Close201}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Close202}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Close203}</label>
                    </td>
                    <td>EJEC FRD 2</td>
                    <td>
                      <label>{smpReportState.EjecFrd201}</label>
                    </td>
                    <td>
                      <label>{smpReportState.EjecFrd202}</label>
                    </td>
                    <td>
                      <label>{smpReportState.EjecFrd203}</label>
                    </td>
                    <td>
                      <label>{smpReportState.EjecFrd204}</label>
                    </td>
                  </tr>
                  <tr>
                    <td>Refill 3&#x27E1;</td>
                    <td>
                      <label>{smpReportState.ReFill301}</label>
                    </td>
                    <td>
                      <label>{smpReportState.ReFill302}</label>
                    </td>
                    <td>
                      <label>{smpReportState.ReFill303}</label>
                    </td>
                    <td>
                      <label>{smpReportState.ReFill304}</label>
                    </td>
                    <td>Close 3</td>
                    <td>
                      <label>{smpReportState.Close301}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Close302}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Close303}</label>
                    </td>
                    <td>EJEC RET 1</td>
                    <td>
                      <label>{smpReportState.EjecRet101}</label>
                    </td>
                    <td>
                      <label>{smpReportState.EjecRet102}</label>
                    </td>
                    <td>
                      <label>{smpReportState.EjecRet103}</label>
                    </td>
                    <td>
                      <label>{smpReportState.EjecRet104}</label>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">Refill End/Short Size(MM)*:</td>

                    <td>
                      <label>{smpReportState.RefillEnd}</label>
                    </td>
                    <td></td>
                    <td>Mould safety/Protect&#x27E1; 1</td>
                    <td>
                      <label>{smpReportState.MldSfy101}</label>
                    </td>
                    <td>
                      <label>{smpReportState.MldSfy201}</label>
                    </td>
                    <td>
                      <label>{smpReportState.MldSfy301}</label>
                    </td>
                    <td>EJEC RET 2</td>
                    <td>
                      <label>{smpReportState.EjecRet201}</label>
                    </td>
                    <td>
                      <label>{smpReportState.EjecRet202}</label>
                    </td>
                    <td>
                      <label>{smpReportState.EjecRet203}</label>
                    </td>
                    <td>
                      <label>{smpReportState.EjecRet204}</label>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">Suck Back&#x27E1;±{smpReportState.SukSet} (MM):</td>

                    <td>
                      <label>{smpReportState.SuckBack}</label>
                    </td>
                    <td></td>
                    <td>Mould safety/Protect&#x27E1; 2</td>
                    <td>
                      <label>{smpReportState.MldSfy102}</label>
                    </td>
                    <td>
                      <label>{smpReportState.MldSfy202}</label>
                    </td>
                    <td>
                      <label>{smpReportState.MldSfy302}</label>
                    </td>
                    <td colSpan="5"></td>
                  </tr>
                  <tr>
                    <td>Back Pres&#x27E1; 1</td>
                    <td>
                      <label>{smpReportState.Bp101}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Bp102}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Bp103}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Bp104}</label>
                    </td>
                    <td>Mould Safety Time(SEC)&#x27E1;:</td>
                    <td>
                      <label>{smpReportState.MoldSafeT}</label>
                    </td>
                    <td>Actual:</td>
                    <td>
                      <label>{smpReportState.Actual1}</label>
                    </td>
                    <td>STAGES&#x27E1;</td>
                    <td>PRESSURE (BAR)&#x27E1;±2</td>
                    <td>SPEED% ±10</td>
                    <td>TIME (SEC)</td>
                    <td>POSITION (MM)</td>
                  </tr>

                  <tr>
                    <td>Back Pres&#x27E1; 2</td>
                    <td>
                      <label>{smpReportState.Bp201}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Bp202}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Bp203}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Bp204}</label>
                    </td>
                    <td colSpan="2">Clamp Force&#x27E1;</td>

                    <td></td>
                    <td></td>
                    <td>CORE 1 IN</td>
                    <td>
                      <label>{smpReportState.Cre1}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Core101}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Core102}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Core103}</label>
                    </td>
                  </tr>
                  <tr>
                    <td>Melt Cushion(MM)*±{smpReportState.CusSet}:</td>
                    <td>

                      <label>{smpReportState.Melt101}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Melt102}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Melt103}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Melt104}</label>
                    </td>
                    <th style={{ textAlign: "center" }} colSpan="4">
                      MOULD OPENING SETTINGS
                    </th>

                    <td>CORE 1 OUT</td>
                    <td>
                      <label>{smpReportState.Cre2}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Core201}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Core202}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Core203}</label>
                    </td>
                  </tr>
                  <tr>
                    <td>Cooling Time* ±{smpReportState.CoolTmeSet}(Sec)</td>
                    <td colSpan="4">
                      <label>{smpReportState.Cooltme}</label>
                    </td>

                    <td>Open 1 &#x27E1;</td>
                    <td>
                      <label>{smpReportState.Open201}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Open202}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Open203}</label>
                    </td>
                    <td>CORE 2 IN</td>
                    <td>
                      <label>{smpReportState.Cre3}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Core301}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Core302}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Core303}</label>
                    </td>
                  </tr>
                  <tr>
                    <td>Cycle Time&#x27E1;±{smpReportState.CycTmeSet}(Sec) </td>
                    <td colSpan="4">
                      <label>{smpReportState.Cycletme}</label>
                    </td>
                    <td>Open 2 &#x27E1;</td>
                    <td>
                      <label>{smpReportState.Open301}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Open302}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Open303}</label>
                    </td>
                    <td>CORE 2 OUT</td>
                    <td>
                      <label>{smpReportState.Cre4}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Core401}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Core402}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Core403}</label>
                    </td>
                  </tr>
                  <tr>
                    <td>Oil Temp&#x27E1;±{smpReportState.OilSet}°C</td>
                    <td colSpan="4">
                      <label>{smpReportState.Oilt}</label>
                    </td>
                    <td>Open 3 &#x27E1;</td>
                    <td>
                      <label>{smpReportState.Open401}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Open402}</label>
                    </td>
                    <td>
                      <label>{smpReportState.Open403}</label>
                    </td>
                    <td colSpan="5"></td>
                  </tr>
                  <tr>
                    <td colSpan={3}>Prepared By</td>
                    <td colSpan={4}><label>{smpReportState.PreparedBy}</label></td>
                    <td colSpan={2}>Approver</td>
                    <td colSpan={5}><label>{smpReportState.ApprovedBy}</label></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
        </Popup>
      </div>
      <div>
        <Popup
          trigger={ButtonPopup}
          setTrigger={setButtonPopup}
          viewPagination={viewPagination}
        >
          <form id="smp.Edit.formContainer" name="smp.Edit.formContainer" className="form-smp-Edit">
            <table width="100%" style={{ textAlign: "center" }} border="0">
              <thead>
                <th
                  id="smp.Edit.Title" name="smp.Edit.Title"
                  style={{
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    fontSize: "22px",
                  }}
                  colSpan={6}
                >
                  <font color="#8B0000">
                    Edit - Standard Moulding Parameter
                  </font>
                </th>
              </thead>
              <tr>
                <td>Format No</td>
                <td>
                  <label>UPCL/PRO/D/01</label>
                </td>

                <td>Rev No</td>
                <td>
                  <label>01</label>
                </td>
                <td>Date</td>
                <td>
                  <input
                    id="smp.Ed.date" name="smp.Ed.date"
                    type="date"
                    value={smpReportState.TrackDate}
                    onChange={handleDateChange}
                    min={today}
                    max={`${nextYear}-12-31`}
                    required
                  />
                </td>
              </tr>
              <thead>
                <th style={{ textAlign: "center" }} colSpan="10"></th>
              </thead>
              <tr>
                <td>Machine <span style={{ color: "red" }}>*</span></td>
                <td>
                  {smpReportState.McNo === '' && isCreating && <span className="Req">Required *</span>}
                  {/* <DropdownFetcher keyword="mac_dtl_dropdown" setValue={setDropdown} /> */}
                  {/* <DropdownSelect text="Select Machine Name" dropdown={dropdown} value={smpReportState.McNo} optionIdProp="mc_code" optionNameProp="mc_name" onChange={(e) => setSmpReportState({ ...smpReportState, McNo: e.target.value })} /> */}
                  <input type="text" value={smpReportState.McNo} disabled></input>
                </td>
                <td>Raw Material Name <span style={{ color: "red" }}>*</span></td>
                <td>
                  {smpReportState.MatNme === '' && isCreating && <span className="Req">Required *</span>}
                  {/* <input
                    id="smp.Ed.MatNme" name="smp.Ed.MatNme"
                    // readOnly={viewMode}
                    type="text"
                    required
                    value={smpReportState.MatNme}
                    onChange={(e) => setSmpReportState({ ...smpReportState, MatNme: e.target.value })}


                  /> */}
                  {/* <DropdownFetcher keyword="rm_details_dropdown_grade" setValue={setRawMaterialDropdown} /> */}

                  <Select
                    id="smp.ED.rawMaterial"
                    name="smp.ED.rawMaterial"
                    value={smpReportState.MatNme}
                    onChange={handleRawMaterial}
                    options={rawMaterialOptions}
                    onInputChange={(event) => { GetSelectValue(event, "rm_details_dropdown_grade", setRawMaterialDropdown) }}
                    placeholder="Search..."
                    required={true}


                  />

                </td>
                <td>No Of Cavity <span style={{ color: "red" }}>*</span></td>
                <td>
                  {smpReportState.NoCav === '' && isCreating && <span className="Req">Required *</span>}
                  <input
                    id="smp.Ed.NoCav" name="smp.Ed.NoCav"
                    // readOnly={viewMode}
                    type="text"
                    // required
                    value={smpReportState.NoCav}
                    onChange={(e) => setSmpReportState({ ...smpReportState, NoCav: e.target.value })}
                  />
                </td>
              </tr>
              <tr>
                <td>Part Name <span style={{ color: "red" }}>*</span></td>
                <td>
                  {smpReportState.PrtNme === '' && isCreating && <span className="Req">Required *</span>}
                  {/* <DropdownFetcher keyword="mac_part_dtl_dropdown" setValue={setPartDropdown} /> */}
                  {/* <DropdownSelect text="Select Part Name" dropdown={partDropdown} value={smpReportState.PrtNme} optionIdProp="mc_part_code" optionNameProp="mc_part_name" onChange={(e) => setSmpReportState({ ...smpReportState, PrtNme: e.target.value })} /> */}
                  <input type="text" value={smpReportState.PrtNme} disabled></input>
                </td>
                <td>Grade Name <span style={{ color: "red" }}>*</span></td>
                <td>
                  {smpReportState.GrdNme === '' && isCreating && <span className="Req">Required *</span>}
                  <input
                    id="smp.Ed.GrdNme" name="smp.Ed.GrdNme"
                    // readOnly={viewMode}
                    type="text"
                    required
                    value={smpReportState.GrdNme}
                    onChange={(e) => setSmpReportState({ ...smpReportState, GrdNme: e.target.value })}
                  />

                </td>
                <td>Colour <span style={{ color: "red" }}>*</span></td>
                <td>
                  {smpReportState.Colour === '' && isCreating && <span className="Req">Required *</span>}
                  <input
                    id="smp.Ed.Colour" name="smp.Ed.Colour"
                    // readOnly={viewMode}
                    type="text"
                    required
                    value={smpReportState.Colour}
                    onChange={(e) => setSmpReportState({ ...smpReportState, Colour: e.target.value })}


                  />
                </td>
              </tr>
              <tr>
                <td>
                  <span style={{ marginRight: "5px", float: "left" }}>
                  Part Weight 1: <span style={{ fontWeight: "bold" }}>{smpReportState.PartWeight1}</span><br></br>
                  Part Weight 2: <span style={{ fontWeight: "bold" }}>{smpReportState.PartWeight2}</span>
                    
                  </span>

                </td>
                <td>
                <span style={{ whiteSpace: "nowrap" }}>PW1*: <span style={{ fontWeight: "bold" }}>(-{smpReportState.PartWeightSetPlus1}/+{smpReportState.PartWeightSetMinus1})</span></span><br></br>
                    <span style={{ whiteSpace: "nowrap" }}>PW2*: <span style={{ fontWeight: "bold" }}>(-{smpReportState.PartWeightSetPlus2}/+{smpReportState.PartWeightSetMinus2})</span></span>
                </td>
                <td>Runner WT <span style={{ color: "red" }}>*</span></td>
                <td>
                  {smpReportState.Runn === '' && isCreating && <span className="Req">Required *</span>}
                  <input
                    id="smp.Ed.Runn" name="smp.Ed.Runn"
                    // readOnly={viewMode}
                    type="text"
                    value={smpReportState.Runn}
                    onChange={(e) => setSmpReportState({ ...smpReportState, Runn: e.target.value })}
                  />
                </td>
                <td>Model <span style={{ color: "red" }}>*</span></td>
                <td>
                  {smpReportState.Modl === '' && isCreating && <span className="Req">Required *</span>}
                  <input
                    id="smp.Ed.Modl" name="smp.Ed.Modl"
                    // readOnly={viewMode}
                    type="text"
                    value={smpReportState.Modl}
                    onChange={(e) => setSmpReportState({ ...smpReportState, Modl: e.target.value })}
                  />
                </td>
              </tr>
            </table>
            <table width="100%" style={{ textAlign: "center" }} border="0">
              <thead>
                <th id="smp.Ed.InjSet.Title" name="smp.Ed.InjSet.Title" style={{ textAlign: "center" }} colSpan="5">
                  INJECTION SETTING
                </th>

                <th style={{ textAlign: "center" }} colSpan="2">
                  TEMPERATURE
                </th>

                <th style={{ textAlign: "center" }} colSpan="4">
                  HOT RUNNER SYSTEM TEMPERATURE
                </th>

                <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                  SEQEMACE GATE
                </th>
                <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                  <tr>
                    <th>
                      ON{" "}
                      <select
                        id="smp.Ed.OnSet.dropdown"
                        name="smp.Ed.OnSet.dropdown"
                        value={smpReportState.OnSet}
                        onChange={(e) => setSmpReportState({ ...smpReportState, OnSet: e.target.value })}
                        style={{ backgroundColor: "#efd9b1" }}
                      >
                        <option id="smp.Ed.Onset.sct" name="smp.Ed.Onset.sct" value="">Select</option>
                        <option id="smp.Ed.Onset.1" name="smp.Ed.Onset.1" value="1">±1</option>
                        <option id="smp.Ed.Onset.2" name="smp.Ed.Onset.2" value="2">±2</option>
                        <option id="smp.Ed.Onset.3" name="smp.Ed.Onset.3" value="3">±3</option>
                        <option id="smp.Ed.Onset.4" name="smp.Ed.Onset.4" value="4">±4</option>
                        <option id="smp.Ed.Onset.5" name="smp.Ed.Onset.5" value="5">±5</option>
                        <option id="smp.Ed.Onset.6" name="smp.Ed.Onset.6" value="6">±6</option>
                        <option id="smp.Ed.Onset.7" name="smp.Ed.Onset.7" value="7">±7</option>
                        <option id="smp.Ed.Onset.8" name="smp.Ed.Onset.8" value="8">±8</option>
                        <option id="smp.Ed.Onset.9" name="smp.Ed.Onset.9" value="9">±9</option>
                        <option id="smp.Ed.Onset.10" name="smp.Ed.Onset.10" value="10">±10</option>
                        <option id="smp.Ed.Onset.11" name="smp.Ed.Onset.11" value="11">±11</option>
                        <option id="smp.Ed.Onset.12" name="smp.Ed.Onset.12" value="12">±12</option>
                        <option id="smp.Ed.Onset.13" name="smp.Ed.Onset.13" value="13">±13</option>
                        <option id="smp.Ed.Onset.14" name="smp.Ed.Onset.14" value="14">±14</option>
                        <option id="smp.Ed.Onset.15" name="smp.Ed.Onset.15" value="15">±15</option>
                      </select>
                    </th>
                  </tr>
                </th>
                <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                  <tr>
                    <th>
                      OFF{" "}
                      <select
                        id="smp.Ed.OffSet.dropdown"
                        name="smp.Ed.OffSet.dropdown"
                        value={smpReportState.OffSet}
                        onChange={(e) => setSmpReportState({ ...smpReportState, OffSet: e.target.value })}
                        style={{ backgroundColor: "#efd9b1" }}
                      >
                        <option id="smp.Ed.OffSet.sct" name="smp.Ed.OffSet.sct" value="">Select</option>
                        <option id="smp.Ed.OffSet.1" name="smp.Ed.OffSet.1" value="1">±1</option>
                        <option id="smp.Ed.OffSet.2" name="smp.Ed.OffSet.2" value="2">±2</option>
                        <option id="smp.Ed.OffSet.3" name="smp.Ed.OffSet.3" value="3">±3</option>
                        <option id="smp.Ed.OffSet.4" name="smp.Ed.OffSet.4" value="4">±4</option>
                        <option id="smp.Ed.OffSet.5" name="smp.Ed.OffSet.5" value="5">±5</option>
                        <option id="smp.Ed.OffSet.6" name="smp.Ed.OffSet.6" value="6">±6</option>
                        <option id="smp.Ed.OffSet.7" name="smp.Ed.OffSet.7" value="7">±7</option>
                        <option id="smp.Ed.OffSet.8" name="smp.Ed.OffSet.8" value="8">±8</option>
                        <option id="smp.Ed.OffSet.9" name="smp.Ed.OffSet.9" value="9">±9</option>
                        <option id="smp.Ed.OffSet.10" name="smp.Ed.OffSet.10" value="10">±10</option>
                        <option id="smp.Ed.OffSet.11" name="smp.Ed.OffSet.11" value="11">±11</option>
                        <option id="smp.Ed.OffSet.12" name="smp.Ed.OffSet.12" value="12">±12</option>
                        <option id="smp.Ed.OffSet.13" name="smp.Ed.OffSet.13" value="13">±13</option>
                        <option id="smp.Ed.OffSet.14" name="smp.Ed.OffSet.14" value="14">±14</option>
                        <option id="smp.Ed.OffSet.15" name="smp.Ed.OffSet.15" value="15">±15</option>
                      </select>
                    </th>
                  </tr>
                </th>
              </thead>
              <tbody>
                <tr>
                  <td>STAGES</td>
                  <td>
                    PRESSURE *(BAR)
                    <select
                      id="smp.Ed.InjPresSet.dropdown"
                      name="smp.Ed.InjPresSet.dropdown"
                      value={smpReportState.InjPresSet}
                      onChange={(e) => setSmpReportState({ ...smpReportState, InjPresSet: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option id="smp.Ed.InjPresSet.sct" name="smp.Ed.InjPresSet.sct" value="">Select</option>
                      <option id="smp.Ed.InjPresSet.1" name="smp.Ed.InjPresSet.1" value="1">±1</option>
                      <option id="smp.Ed.InjPresSet.2" name="smp.Ed.InjPresSet.2" value="2">±2</option>
                      <option id="smp.Ed.InjPresSet.3" name="smp.Ed.InjPresSet.3" value="3">±3</option>
                      <option id="smp.Ed.InjPresSet.4" name="smp.Ed.InjPresSet.4" value="4">±4</option>
                      <option id="smp.Ed.InjPresSet.5" name="smp.Ed.InjPresSet.5" value="5">±5</option>
                      <option id="smp.Ed.InjPresSet.6" name="smp.Ed.InjPresSet.6" value="6">±6</option>
                      <option id="smp.Ed.InjPresSet.7" name="smp.Ed.InjPresSet.7" value="7">±7</option>
                      <option id="smp.Ed.InjPresSet.8" name="smp.Ed.InjPresSet.8" value="8">±8</option>
                      <option id="smp.Ed.InjPresSet.9" name="smp.Ed.InjPresSet.9" value="9">±9</option>
                      <option id="smp.Ed.InjPresSet.10" name="smp.Ed.InjPresSet.10" value="10">±10</option>
                      <option id="smp.Ed.InjPresSet.11" name="smp.Ed.InjPresSet.11" value="11">±11</option>
                      <option id="smp.Ed.InjPresSet.12" name="smp.Ed.InjPresSet.12" value="12">±12</option>
                      <option id="smp.Ed.InjPresSet.13" name="smp.Ed.InjPresSet.13" value="13">±13</option>
                      <option id="smp.Ed.InjPresSet.14" name="smp.Ed.InjPresSet.14" value="14">±14</option>
                      <option id="smp.Ed.InjPresSet.15" name="smp.Ed.InjPresSet.15" value="15">±15</option>
                    </select>
                  </td>
                  <td>
                    SPEED% *
                    <select
                      id="dropdown"
                      value={smpReportState.InjSpdSet}
                      onChange={(e) => setSmpReportState({ ...smpReportState, InjSpdSet: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option value="">Select</option>
                      <option value="1">±1</option>
                      <option value="2">±2</option>
                      <option value="3">±3</option>
                      <option value="4">±4</option>
                      <option value="5">±5</option>
                      <option value="6">±6</option>
                      <option value="7">±7</option>
                      <option value="8">±8</option>
                      <option value="9">±9</option>
                      <option value="10">±10</option>
                      <option value="11">±11</option>
                      <option value="12">±12</option>
                      <option value="13">±13</option>
                      <option value="14">±14</option>
                      <option value="15">±15</option>
                    </select>
                  </td>
                  <td>
                    POSITION MM&#x27E1;
                    <select
                      id="dropdown"
                      value={smpReportState.InjPosSet}
                      onChange={(e) => setSmpReportState({ ...smpReportState, InjPosSet: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option value="">Select</option>
                      <option value="1">±1</option>
                      <option value="2">±2</option>
                      <option value="3">±3</option>
                      <option value="4">±4</option>
                      <option value="5">±5</option>
                      <option value="6">±6</option>
                      <option value="7">±7</option>
                      <option value="8">±8</option>
                      <option value="9">±9</option>
                      <option value="10">±10</option>
                      <option value="11">±11</option>
                      <option value="12">±12</option>
                      <option value="13">±13</option>
                      <option value="14">±14</option>
                      <option value="15">±15</option>
                    </select>{" "}
                  </td>
                  <td>
                    TIME(SEC) *
                    <select
                      id="dropdown"
                      value={smpReportState.InjTmeSet}
                      onChange={(e) => setSmpReportState({ ...smpReportState, InjTmeSet: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option value="">Select</option>
                      <option value="0.1">±0.1</option>
                      <option value="0.2">±0.2</option>
                      <option value="0.3">±0.3</option>
                      <option value="0.4">±0.4</option>
                      <option value="0.5">±0.5</option>
                      <option value="0.6">±0.6</option>
                      <option value="0.7">±0.7</option>
                      <option value="0.8">±0.8</option>
                      <option value="0.9">±0.9</option>
                      <option value="1">±1</option>
                      <option value="2">±2</option>
                      <option value="3">±3</option>
                      <option value="4">±4</option>
                      <option value="5">±5</option>
                    </select>
                  </td>
                  <td>STAGES</td>
                  <td>BARREL TEMP</td>
                  <td>HOT RUNNER TEMPERATURE</td>
                  <td>CELSIUS<br></br>&deg;C</td>
                  <td>HOT RUNNER TEMPERATURE</td>
                  <td>
                    CELSIUS <br></br>&deg;C{" "}
                    <select
                      id="dropdown"
                      value={smpReportState.GteSet}
                      onChange={(e) => setSmpReportState({ ...smpReportState, GteSet: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option value="">Select</option>
                      <option value="1">±1</option>
                      <option value="2">±2</option>
                      <option value="3">±3</option>
                      <option value="4">±4</option>
                      <option value="5">±5</option>
                      <option value="6">±6</option>
                      <option value="7">±7</option>
                      <option value="8">±8</option>
                      <option value="9">±9</option>
                      <option value="10">±10</option>
                      <option value="11">±11</option>
                      <option value="12">±12</option>
                      <option value="13">±13</option>
                      <option value="14">±14</option>
                      <option value="15">±15</option>
                    </select>
                  </td>
                  <td>Gate 1</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Gate6}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Gate6: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Gate7}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Gate7: e.target.value })}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Injection 1*</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Inj101}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Inj101: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Inj102}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Inj102: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Inj103}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Inj103: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Inj104}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Inj104: e.target.value })}
                    />
                  </td>
                  <td>
                    Nozzle*{" "}
                    <select
                      id="dropdown"
                      value={smpReportState.NozzSet}
                      onChange={(e) => setSmpReportState({ ...smpReportState, NozzSet: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option value="">Select</option>
                      <option value="1">±1</option>
                      <option value="2">±2</option>
                      <option value="3">±3</option>
                      <option value="4">±4</option>
                      <option value="5">±5</option>
                      <option value="6">±6</option>
                      <option value="7">±7</option>
                      <option value="8">±8</option>
                      <option value="9">±9</option>
                      <option value="10">±10</option>
                      <option value="11">±11</option>
                      <option value="12">±12</option>
                      <option value="13">±13</option>
                      <option value="14">±14</option>
                      <option value="15">±15</option>
                    </select>
                    &deg;C
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Hozz}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Hozz: e.target.value })}
                    />
                  </td>
                  <td>
                    ManiFold 1
                    <select
                      id="dropdown"
                      value={smpReportState.ManiFldSet1}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ManiFldSet1: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option value="">Select</option>
                      <option value="1">±1</option>
                      <option value="2">±2</option>
                      <option value="3">±3</option>
                      <option value="4">±4</option>
                      <option value="5">±5</option>
                      <option value="6">±6</option>
                      <option value="7">±7</option>
                      <option value="8">±8</option>
                      <option value="9">±9</option>
                      <option value="10">±10</option>
                      <option value="11">±11</option>
                      <option value="12">±12</option>
                      <option value="13">±13</option>
                      <option value="14">±14</option>
                      <option value="15">±15</option>
                    </select>
                  </td>
                  <td>
                    {" "}
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.ManFld1}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ManFld1: e.target.value })}
                    />
                  </td>
                  <td>Gate 1</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Gate1}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Gate1: e.target.value })}
                    />
                  </td>
                  <td>Gate 2</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Gte2}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Gte2: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Gate8}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Gate8: e.target.value })}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Injection 2*</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Inj201}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Inj201: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Inj202}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Inj202: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Inj203}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Inj203: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Inj204}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Inj204: e.target.value })}
                    />
                  </td>
                  <td>
                    Zone 1*{" "}
                    <select
                      id="dropdown"
                      value={smpReportState.ZneSet1}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ZneSet1: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option value="">Select</option>
                      <option value="1">±1</option>
                      <option value="2">±2</option>
                      <option value="3">±3</option>
                      <option value="4">±4</option>
                      <option value="5">±5</option>
                      <option value="6">±6</option>
                      <option value="7">±7</option>
                      <option value="8">±8</option>
                      <option value="9">±9</option>
                      <option value="10">±10</option>
                      <option value="11">±11</option>
                      <option value="12">±12</option>
                      <option value="13">±13</option>
                      <option value="14">±14</option>
                      <option value="15">±15</option>
                    </select>
                    &deg;C
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Zone1}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Zone1: e.target.value })}
                    />
                  </td>
                  <td>
                    ManiFold 2
                    <select
                      id="dropdown"
                      value={smpReportState.ManiFldSet2}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ManiFldSet2: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option value="">Select</option>
                      <option value="1">±1</option>
                      <option value="2">±2</option>
                      <option value="3">±3</option>
                      <option value="4">±4</option>
                      <option value="5">±5</option>
                      <option value="6">±6</option>
                      <option value="7">±7</option>
                      <option value="8">±8</option>
                      <option value="9">±9</option>
                      <option value="10">±10</option>
                      <option value="11">±11</option>
                      <option value="12">±12</option>
                      <option value="13">±13</option>
                      <option value="14">±14</option>
                      <option value="15">±15</option>
                    </select>
                  </td>
                  <td>
                    {" "}
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.ManFld2}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ManFld2: e.target.value })}
                    />
                  </td>

                  <td>Gate 2</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Gate2}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Gate2: e.target.value })}
                    />
                  </td>

                  <td>Gate 3</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Gte3}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Gte3: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Gate9}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Gate9: e.target.value })}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Injection 3*</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Inj301}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Inj301: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Inj302}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Inj302: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Inj303}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Inj303: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Inj304}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Inj304: e.target.value })}
                    />
                  </td>
                  <td>
                    Zone 2*{" "}
                    <select
                      id="dropdown"
                      value={smpReportState.ZneSet2}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ZneSet2: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option value="">Select</option>
                      <option value="1">±1</option>
                      <option value="2">±2</option>
                      <option value="3">±3</option>
                      <option value="4">±4</option>
                      <option value="5">±5</option>
                      <option value="6">±6</option>
                      <option value="7">±7</option>
                      <option value="8">±8</option>
                      <option value="9">±9</option>
                      <option value="10">±10</option>
                      <option value="11">±11</option>
                      <option value="12">±12</option>
                      <option value="13">±13</option>
                      <option value="14">±14</option>
                      <option value="15">±15</option>
                    </select>
                    &deg;C
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Zone2}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Zone2: e.target.value })}
                    />
                  </td>
                  <td>
                    ManiFold 3
                    <select
                      id="dropdown"
                      value={smpReportState.ManiFldSet3}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ManiFldSet3: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option value="">Select</option>
                      <option value="1">±1</option>
                      <option value="2">±2</option>
                      <option value="3">±3</option>
                      <option value="4">±4</option>
                      <option value="5">±5</option>
                      <option value="6">±6</option>
                      <option value="7">±7</option>
                      <option value="8">±8</option>
                      <option value="9">±9</option>
                      <option value="10">±10</option>
                      <option value="11">±11</option>
                      <option value="12">±12</option>
                      <option value="13">±13</option>
                      <option value="14">±14</option>
                      <option value="15">±15</option>
                    </select>
                  </td>
                  <td>
                    {" "}
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.ManFld3}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ManFld3: e.target.value })}
                    />
                  </td>

                  <td>Gate 3</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Gate3}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Gate3: e.target.value })}
                    />
                  </td>

                  <td>Gate 4</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Gte4}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Gte4: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Gate10}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Gate10: e.target.value })}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Injection 4*</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Inj401}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Inj401: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Inj402}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Inj402: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Inj403}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Inj403: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Inj404}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Inj404: e.target.value })}
                    />
                  </td>
                  <td>
                    Zone 3*{" "}
                    <select
                      id="dropdown"
                      value={smpReportState.ZneSet3}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ZneSet3: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option value="">Select</option>
                      <option value="1">±1</option>
                      <option value="2">±2</option>
                      <option value="3">±3</option>
                      <option value="4">±4</option>
                      <option value="5">±5</option>
                      <option value="6">±6</option>
                      <option value="7">±7</option>
                      <option value="8">±8</option>
                      <option value="9">±9</option>
                      <option value="10">±10</option>
                      <option value="11">±11</option>
                      <option value="12">±12</option>
                      <option value="13">±13</option>
                      <option value="14">±14</option>
                      <option value="15">±15</option>
                    </select>
                    &deg;C
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Zone3}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Zone3: e.target.value })}
                    />
                  </td>
                  <td>
                    ManiFold 4
                    <select
                      id="dropdown"
                      value={smpReportState.ManiFldSet4}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ManiFldSet4: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option value="">Select</option>
                      <option value="1">±1</option>
                      <option value="2">±2</option>
                      <option value="3">±3</option>
                      <option value="4">±4</option>
                      <option value="5">±5</option>
                      <option value="6">±6</option>
                      <option value="7">±7</option>
                      <option value="8">±8</option>
                      <option value="9">±9</option>
                      <option value="10">±10</option>
                      <option value="11">±11</option>
                      <option value="12">±12</option>
                      <option value="13">±13</option>
                      <option value="14">±14</option>
                      <option value="15">±15</option>
                    </select>
                  </td>
                  <td>
                    {" "}
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.ManFld4}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ManFld4: e.target.value })}
                    />
                  </td>

                  <td>Gate 4</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Gate4}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Gate4: e.target.value })}
                    />
                  </td>

                  <td>Gate 5</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Gte5}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Gte5: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Gate11}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Gate11: e.target.value })}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Fill Time(Actual)</td>
                  <td colSpan="3"></td>

                  <td>
                    <input
                      id="smp.Cr.FillT104"
                      name="smp.Cr.FillT104"
                      readOnly={smpReportState.viewMode}
                      type="text"
                      value={smpReportState.FillT104}
                      onChange={(e) =>
                        setSmpReportState({
                          ...smpReportState,
                          FillT104: e.target.value,
                        })
                      }
                    />
                  </td>

                  <td>
                    Zone 4*{" "}
                    <select
                      id="smp.Cr.ZneSet4.dropdown"
                      name="smp.Cr.ZneSet4.dropdown"
                      value={smpReportState.ZneSet4}
                      onChange={(e) =>
                        setSmpReportState({
                          ...smpReportState,
                          ZneSet4: e.target.value,
                        })
                      }
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option
                        id="smp.Cr.ZneSet4.sct"
                        name="smp.Cr.ZneSet4.sct"
                        value=""
                      >
                        Select
                      </option>
                      <option
                        id="smp.Cr.ZneSet4.1"
                        name="smp.Cr.ZneSet4.1"
                        value="1"
                      >
                        ±1
                      </option>
                      <option
                        id="smp.Cr.ZneSet4.2"
                        name="smp.Cr.ZneSet4.2"
                        value="2"
                      >
                        ±2
                      </option>
                      <option
                        id="smp.Cr.ZneSet4.3"
                        name="smp.Cr.ZneSet4.3"
                        value="3"
                      >
                        ±3
                      </option>
                      <option
                        id="smp.Cr.ZneSet4.4"
                        name="smp.Cr.ZneSet4.4"
                        value="4"
                      >
                        ±4
                      </option>
                      <option
                        id="smp.Cr.ZneSet4.5"
                        name="smp.Cr.ZneSet4.5"
                        value="5"
                      >
                        ±5
                      </option>
                      <option
                        id="smp.Cr.ZneSet4.6"
                        name="smp.Cr.ZneSet4.6"
                        value="6"
                      >
                        ±6
                      </option>
                      <option
                        id="smp.Cr.ZneSet4.7"
                        name="smp.Cr.ZneSet4.7"
                        value="7"
                      >
                        ±7
                      </option>
                      <option
                        id="smp.Cr.ZneSet4.8"
                        name="smp.Cr.ZneSet4.8"
                        value="8"
                      >
                        ±8
                      </option>
                      <option
                        id="smp.Cr.ZneSet4.9"
                        name="smp.Cr.ZneSet4.9"
                        value="9"
                      >
                        ±9
                      </option>
                      <option
                        id="smp.Cr.ZneSet4.10"
                        name="smp.Cr.ZneSet4.10"
                        value="10"
                      >
                        ±10
                      </option>
                      <option
                        id="smp.Cr.ZneSet4.11"
                        name="smp.Cr.ZneSet4.11"
                        value="11"
                      >
                        ±11
                      </option>
                      <option
                        id="smp.Cr.ZneSet4.12"
                        name="smp.Cr.ZneSet4.12"
                        value="12"
                      >
                        ±12
                      </option>
                      <option
                        id="smp.Cr.ZneSet4.13"
                        name="smp.Cr.ZneSet4.13"
                        value="13"
                      >
                        ±13
                      </option>
                      <option
                        id="smp.Cr.ZneSet4.14"
                        name="smp.Cr.ZneSet4.14"
                        value="14"
                      >
                        ±14
                      </option>
                      <option
                        id="smp.Cr.ZneSet4.15"
                        name="smp.Cr.ZneSet4.15"
                        value="15"
                      >
                        ±15
                      </option>
                    </select>
                    &deg;C
                  </td>
                  <td>
                    <input
                      id="smp.Cr.Zone4"
                      name="smp.Cr.Zone4"
                      readOnly={smpReportState.viewMode}
                      type="text"
                      value={smpReportState.Zone4}
                      onChange={(e) =>
                        setSmpReportState({
                          ...smpReportState,
                          Zone4: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td>
                    ManiFold 5
                    <select
                      id="smp.Cr.ManiFldSet5.dropdown"
                      name="smp.Cr.ManiFldSet5.dropdown"
                      value={smpReportState.ManiFldSet5}
                      onChange={(e) =>
                        setSmpReportState({
                          ...smpReportState,
                          ManiFldSet5: e.target.value,
                        })
                      }
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option
                        id="smp.Cr.ManiFldSet5.sct"
                        name="smp.Cr.ManiFldSet5.sct"
                        value=""
                      >
                        Select
                      </option>
                      <option
                        id="smp.Cr.ManiFldSet5.1"
                        name="smp.Cr.ManiFldSet5.1"
                        value="1"
                      >
                        ±1
                      </option>
                      <option
                        id="smp.Cr.ManiFldSet5.2"
                        name="smp.Cr.ManiFldSet5.2"
                        value="2"
                      >
                        ±2
                      </option>
                      <option
                        id="smp.Cr.ManiFldSet5.3"
                        name="smp.Cr.ManiFldSet5.3"
                        value="3"
                      >
                        ±3
                      </option>
                      <option
                        id="smp.Cr.ManiFldSet5.4"
                        name="smp.Cr.ManiFldSet5.4"
                        value="4"
                      >
                        ±4
                      </option>
                      <option
                        id="smp.Cr.ManiFldSet5.5"
                        name="smp.Cr.ManiFldSet5.5"
                        value="5"
                      >
                        ±5
                      </option>
                      <option
                        id="smp.Cr.ManiFldSet5.6"
                        name="smp.Cr.ManiFldSet5.6"
                        value="6"
                      >
                        ±6
                      </option>
                      <option
                        id="smp.Cr.ManiFldSet5.7"
                        name="smp.Cr.ManiFldSet5.7"
                        value="7"
                      >
                        ±7
                      </option>
                      <option
                        id="smp.Cr.ManiFldSet5.8"
                        name="smp.Cr.ManiFldSet5.8"
                        value="8"
                      >
                        ±8
                      </option>
                      <option
                        id="smp.Cr.ManiFldSet5.9"
                        name="smp.Cr.ManiFldSet5.9"
                        value="9"
                      >
                        ±9
                      </option>
                      <option
                        id="smp.Cr.ManiFldSet5.10"
                        name="smp.Cr.ManiFldSet5.10"
                        value="10"
                      >
                        ±10
                      </option>
                      <option
                        id="smp.Cr.ManiFldSet5.11"
                        name="smp.Cr.ManiFldSet5.11"
                        value="11"
                      >
                        ±11
                      </option>
                      <option
                        id="smp.Cr.ManiFldSet5.12"
                        name="smp.Cr.ManiFldSet5.12"
                        value="12"
                      >
                        ±12
                      </option>
                      <option
                        id="smp.Cr.ManiFldSet5.13"
                        name="smp.Cr.ManiFldSet5.13"
                        value="13"
                      >
                        ±13
                      </option>
                      <option
                        id="smp.Cr.ManiFldSet5.14"
                        name="smp.Cr.ManiFldSet5.14"
                        value="14"
                      >
                        ±14
                      </option>
                      <option
                        id="smp.Cr.ManiFldSet5.15"
                        name="smp.Cr.ManiFldSet5.15"
                        value="15"
                      >
                        ±15
                      </option>
                    </select>
                  </td>
                  <td>
                    {" "}
                    <input
                      id="smp.Cr.ManFld5"
                      name="smp.Cr.ManFld5"
                      readOnly={smpReportState.viewMode}
                      type="text"
                      value={smpReportState.ManFld5}
                      onChange={(e) =>
                        setSmpReportState({
                          ...smpReportState,
                          ManFld5: e.target.value,
                        })
                      }
                    />
                  </td>

                  <td>Gate 5</td>
                  <td>
                    <input
                      id="smp.Cr.Gate5"
                      name="smp.Cr.Gate5"
                      type="text"
                      value={smpReportState.Gate5}
                      onChange={(e) =>
                        setSmpReportState({
                          ...smpReportState,
                          Gate5: e.target.value,
                        })
                      }
                    />
                  </td>

                  <td>Gate 6</td>
                  <td>
                    <input
                      id="smp.Cr.Gte6"
                      name="smp.Cr.Gte6"
                      readOnly={smpReportState.viewMode}
                      type="text"
                      value={smpReportState.Gte6}
                      onChange={(e) =>
                        setSmpReportState({
                          ...smpReportState,
                          Gte6: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td>
                    <input
                      id="smp.Cr.Gate12"
                      name="smp.Cr.Gate12"
                      readOnly={smpReportState.viewMode}
                      type="text"
                      value={smpReportState.Gate12}
                      onChange={(e) =>
                        setSmpReportState({
                          ...smpReportState,
                          Gate12: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={5}></td>{" "}
                  <td>
                    Zone 5*{" "}
                    <select
                      id="smp.Cr.ZneSet5.dropdown"
                      name="smp.Cr.ZneSet5.dropdown"
                      value={smpReportState.ZneSet5}
                      onChange={(e) =>
                        setSmpReportState({
                          ...smpReportState,
                          ZneSet5: e.target.value,
                        })
                      }
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option
                        id="smp.Cr.ZneSet5.sct"
                        name="smp.Cr.ZneSet5.sct"
                        value=""
                      >
                        Select
                      </option>
                      <option
                        id="smp.Cr.ZneSet5.1"
                        name="smp.Cr.ZneSet5.1"
                        value="1"
                      >
                        ±1
                      </option>
                      <option
                        id="smp.Cr.ZneSet5.2"
                        name="smp.Cr.ZneSet5.2"
                        value="2"
                      >
                        ±2
                      </option>
                      <option
                        id="smp.Cr.ZneSet5.3"
                        name="smp.Cr.ZneSet5.3"
                        value="3"
                      >
                        ±3
                      </option>
                      <option
                        id="smp.Cr.ZneSet5.4"
                        name="smp.Cr.ZneSet5.4"
                        value="4"
                      >
                        ±4
                      </option>
                      <option
                        id="smp.Cr.ZneSet5.5"
                        name="smp.Cr.ZneSet5.5"
                        value="5"
                      >
                        ±5
                      </option>
                      <option
                        id="smp.Cr.ZneSet5.6"
                        name="smp.Cr.ZneSet5.6"
                        value="6"
                      >
                        ±6
                      </option>
                      <option
                        id="smp.Cr.ZneSet5.7"
                        name="smp.Cr.ZneSet5.7"
                        value="7"
                      >
                        ±7
                      </option>
                      <option
                        id="smp.Cr.ZneSet5.8"
                        name="smp.Cr.ZneSet5.8"
                        value="8"
                      >
                        ±8
                      </option>
                      <option
                        id="smp.Cr.ZneSet5.9"
                        name="smp.Cr.ZneSet5.9"
                        value="9"
                      >
                        ±9
                      </option>
                      <option
                        id="smp.Cr.ZneSet5.10"
                        name="smp.Cr.ZneSet5.10"
                        value="10"
                      >
                        ±10
                      </option>
                      <option
                        id="smp.Cr.ZneSet5.11"
                        name="smp.Cr.ZneSet5.11"
                        value="11"
                      >
                        ±11
                      </option>
                      <option
                        id="smp.Cr.ZneSet5.12"
                        name="smp.Cr.ZneSet5.12"
                        value="12"
                      >
                        ±12
                      </option>
                      <option
                        id="smp.Cr.ZneSet5.13"
                        name="smp.Cr.ZneSet5.13"
                        value="13"
                      >
                        ±13
                      </option>
                      <option
                        id="smp.Cr.ZneSet5.14"
                        name="smp.Cr.ZneSet5.14"
                        value="14"
                      >
                        ±14
                      </option>
                      <option
                        id="smp.Cr.ZneSet5.15"
                        name="smp.Cr.ZneSet5.15"
                        value="15"
                      >
                        ±15
                      </option>
                    </select>
                    &deg;C
                  </td>
                  <td>
                    <input
                      id="smp.Cr.Zone5"
                      name="smp.Cr.Zone5"
                      readOnly={smpReportState.viewMode}
                      type="text"
                      value={smpReportState.Zone5}
                      onChange={(e) =>
                        setSmpReportState({
                          ...smpReportState,
                          Zone5: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td colSpan={7}></td>
                </tr>
                <tr>
                  <td colSpan={5}></td>{" "}
                  <td>
                    Zone 6*{" "}
                    <select
                      id="smp.Cr.ZneSet6.dropdown"
                      name="smp.Cr.ZneSet6.dropdown"
                      value={smpReportState.ZneSet6}
                      onChange={(e) =>
                        setSmpReportState({
                          ...smpReportState,
                          ZneSet6: e.target.value,
                        })
                      }
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option
                        id="smp.Cr.ZneSet6.sct"
                        name="smp.Cr.ZneSet6.sct"
                        value=""
                      >
                        Select
                      </option>
                      <option
                        id="smp.Cr.ZneSet6.1"
                        name="smp.Cr.ZneSet6.1"
                        value="1"
                      >
                        ±1
                      </option>
                      <option
                        id="smp.Cr.ZneSet6.2"
                        name="smp.Cr.ZneSet6.2"
                        value="2"
                      >
                        ±2
                      </option>
                      <option
                        id="smp.Cr.ZneSet6.3"
                        name="smp.Cr.ZneSet6.3"
                        value="3"
                      >
                        ±3
                      </option>
                      <option
                        id="smp.Cr.ZneSet6.4"
                        name="smp.Cr.ZneSet6.4"
                        value="4"
                      >
                        ±4
                      </option>
                      <option
                        id="smp.Cr.ZneSet6.5"
                        name="smp.Cr.ZneSet6.5"
                        value="5"
                      >
                        ±5
                      </option>
                      <option
                        id="smp.Cr.ZneSet6.6"
                        name="smp.Cr.ZneSet6.6"
                        value="6"
                      >
                        ±6
                      </option>
                      <option
                        id="smp.Cr.ZneSet6.7"
                        name="smp.Cr.ZneSet6.7"
                        value="7"
                      >
                        ±7
                      </option>
                      <option
                        id="smp.Cr.ZneSet6.8"
                        name="smp.Cr.ZneSet6.8"
                        value="8"
                      >
                        ±8
                      </option>
                      <option
                        id="smp.Cr.ZneSet6.9"
                        name="smp.Cr.ZneSet6.9"
                        value="9"
                      >
                        ±9
                      </option>
                      <option
                        id="smp.Cr.ZneSet6.10"
                        name="smp.Cr.ZneSet6.10"
                        value="10"
                      >
                        ±10
                      </option>
                      <option
                        id="smp.Cr.ZneSet6.11"
                        name="smp.Cr.ZneSet6.11"
                        value="11"
                      >
                        ±11
                      </option>
                      <option
                        id="smp.Cr.ZneSet6.12"
                        name="smp.Cr.ZneSet6.12"
                        value="12"
                      >
                        ±12
                      </option>
                      <option
                        id="smp.Cr.ZneSet6.13"
                        name="smp.Cr.ZneSet6.13"
                        value="13"
                      >
                        ±13
                      </option>
                      <option
                        id="smp.Cr.ZneSet6.14"
                        name="smp.Cr.ZneSet6.14"
                        value="14"
                      >
                        ±14
                      </option>
                      <option
                        id="smp.Cr.ZneSet6.15"
                        name="smp.Cr.ZneSet6.15"
                        value="15"
                      >
                        ±15
                      </option>
                    </select>
                    &deg;C
                  </td>
                  <td>
                    <input
                      id="smp.Cr.Zone6"
                      name="smp.Cr.Zone6"
                      readOnly={smpReportState.viewMode}
                      type="text"
                      value={smpReportState.Zone6}
                      onChange={(e) =>
                        setSmpReportState({
                          ...smpReportState,
                          Zone6: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td colSpan={7}></td>
                </tr>

                <tr>
                  <td colSpan="3">Switch Over / Transfer Position(MM)&#x27E1;:</td>
                  <td>
                    <input
                      id="smp.Cr.Switch"
                      name="smp.Cr.Switch"
                      readOnly={smpReportState.viewMode}
                      type="text"
                      value={smpReportState.Switch}
                      onChange={(e) =>
                        setSmpReportState({
                          ...smpReportState,
                          Switch: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td></td>
                  <td>
                    Hopper*{" "}
                    <select
                      id="smp.Cr.HoppSet.dropdown"
                      name="smp.Cr.HoppSet.dropdown"
                      value={smpReportState.HoppSet}
                      onChange={(e) =>
                        setSmpReportState({
                          ...smpReportState,
                          HoppSet: e.target.value,
                        })
                      }
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option
                        id="smp.Cr.HoppSet.sct"
                        name="smp.Cr.HoppSet.sct"
                        value=""
                      >
                        Select
                      </option>
                      <option
                        id="smp.Cr.HoppSet.1"
                        name="smp.Cr.HoppSet.1"
                        value="1"
                      >
                        ±1
                      </option>
                      <option
                        id="smp.Cr.HoppSet.2"
                        name="smp.Cr.HoppSet.2"
                        value="2"
                      >
                        ±2
                      </option>
                      <option
                        id="smp.Cr.HoppSet.3"
                        name="smp.Cr.HoppSet.3"
                        value="3"
                      >
                        ±3
                      </option>
                      <option
                        id="smp.Cr.HoppSet.4"
                        name="smp.Cr.HoppSet.4"
                        value="4"
                      >
                        ±4
                      </option>
                      <option
                        id="smp.Cr.HoppSet.5"
                        name="smp.Cr.HoppSet.5"
                        value="5"
                      >
                        ±5
                      </option>
                      <option
                        id="smp.Cr.HoppSet.6"
                        name="smp.Cr.HoppSet.6"
                        value="6"
                      >
                        ±6
                      </option>
                      <option
                        id="smp.Cr.HoppSet.7"
                        name="smp.Cr.HoppSet.7"
                        value="7"
                      >
                        ±7
                      </option>
                      <option
                        id="smp.Cr.HoppSet.8"
                        name="smp.Cr.HoppSet.8"
                        value="8"
                      >
                        ±8
                      </option>
                      <option
                        id="smp.Cr.HoppSet.9"
                        name="smp.Cr.HoppSet.9"
                        value="9"
                      >
                        ±9
                      </option>
                      <option
                        id="smp.Cr.HoppSet.10"
                        name="smp.Cr.HoppSet.10"
                        value="10"
                      >
                        ±10
                      </option>
                      <option
                        id="smp.Cr.HoppSet.11"
                        name="smp.Cr.HoppSet.11"
                        value="11"
                      >
                        ±11
                      </option>
                      <option
                        id="smp.Cr.HoppSet.12"
                        name="smp.Cr.HoppSet.12"
                        value="12"
                      >
                        ±12
                      </option>
                      <option
                        id="smp.Cr.HoppSet.13"
                        name="smp.Cr.HoppSet.13"
                        value="13"
                      >
                        ±13
                      </option>
                      <option
                        id="smp.Cr.HoppSet.14"
                        name="smp.Cr.HoppSet.14"
                        value="14"
                      >
                        ±14
                      </option>
                      <option
                        id="smp.Cr.HoppSet.15"
                        name="smp.Cr.HoppSet.15"
                        value="15"
                      >
                        ±15
                      </option>
                    </select>
                    &deg;C
                  </td>
                  <td>
                    <input
                      id="smp.Cr.Hopp"
                      name="smp.Cr.Hopp"
                      readOnly={smpReportState.viewMode}
                      type="text"
                      value={smpReportState.Hopp}
                      onChange={(e) =>
                        setSmpReportState({
                          ...smpReportState,
                          Hopp: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td colSpan={7}></td>
                </tr>
              </tbody>
            </table>
            <table width="100%" style={{ textAlign: "center" }} border="0">
              <thead>
                <th style={{ textAlign: "center" }} colSpan="5"></th>

                <th style={{ textAlign: "center" }} colSpan="6">
                  INJECTION UNIT SETTINGS
                </th>

                <th style={{ textAlign: "center" }} colSpan="4">
                  MOULD TEMP DETAILS
                </th>
              </thead>
              <tbody>
                <tr>
                  <td style={{ width: "7%" }}>Hold On 1*</td>
                  <td style={{ width: "8%" }}>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Hold101}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Hold101: e.target.value })}
                    />
                  </td>
                  <td style={{ width: "8%" }}>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Hold201}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Hold201: e.target.value })}
                    />
                  </td>
                  <td style={{ width: "8%" }}>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Hold301}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Hold301: e.target.value })}
                    />
                  </td>
                  <td style={{ width: "8%" }}>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Hold401}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Hold401: e.target.value })}
                    />
                  </td>
                  <td></td>
                  <td>SPEED%</td>
                  <td></td>
                  <td>SEC</td>
                  <td>SPRUE BREAK MODE&#x27E1;</td>
                  <td>
                    <tr>
                      <td></td>
                    </tr>
                  </td>
                  <td>
                    Core*{" "}
                    <select
                      id="dropdown"
                      value={smpReportState.CoreSet}
                      onChange={(e) => setSmpReportState({ ...smpReportState, CoreSet: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option value="">Select</option>
                      <option value="1">±1</option>
                      <option value="2">±2</option>
                      <option value="3">±3</option>
                      <option value="4">±4</option>
                      <option value="5">±5</option>
                      <option value="6">±6</option>
                      <option value="7">±7</option>
                      <option value="8">±8</option>
                      <option value="9">±9</option>
                      <option value="10">±10</option>
                      <option value="11">±11</option>
                      <option value="12">±12</option>
                      <option value="13">±13</option>
                      <option value="14">±14</option>
                      <option value="15">±15</option>
                    </select>
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Core}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Core: e.target.value })}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Hold On 2*</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Hold102}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Hold102: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Hold202}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Hold202: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Hold302}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Hold302: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Hold402}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Hold402: e.target.value })}
                    />
                  </td>
                  <td>INJ UNIT&#x27E1;</td>
                  <td>
                    <tr>
                      <td>ON</td>
                    </tr>
                  </td>
                  <td>Sprue Break&#x27E1;</td>
                  <td>
                    <tr>
                      <td style={{ border: "1px solid black" }}>OFF</td>
                    </tr>
                  </td>
                  <td>0 INJ</td>
                  <td></td>
                  <td>
                    Cavity*{" "}
                    <select
                      id="dropdown"
                      value={smpReportState.CavSet}
                      onChange={(e) => setSmpReportState({ ...smpReportState, CavSet: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option value="">Select</option>
                      <option value="1">±1</option>
                      <option value="2">±2</option>
                      <option value="3">±3</option>
                      <option value="4">±4</option>
                      <option value="5">±5</option>
                      <option value="6">±6</option>
                      <option value="7">±7</option>
                      <option value="8">±8</option>
                      <option value="9">±9</option>
                      <option value="10">±10</option>
                      <option value="11">±11</option>
                      <option value="12">±12</option>
                      <option value="13">±13</option>
                      <option value="14">±14</option>
                      <option value="15">±15</option>
                    </select>
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Cavity}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Cavity: e.target.value })}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Hold On 3*</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Hold103}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Hold103: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Hold203}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Hold203: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Hold303}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Hold303: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Hold403}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Hold403: e.target.value })}
                    />
                  </td>
                  <td>FWD SPD(MM/S)&#x27E1;</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Fwd}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Fwd: e.target.value })}
                    />
                  </td>
                  <td>Delay Time</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Dely}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Dely: e.target.value })}
                    />
                  </td>
                  <td>1.Front Suck Back</td>
                  <td>3.Rear Suck Back</td>

                  <td>
                    {" "}
                    <span style={{ marginRight: "5px", float: "left" }}>
                      M T C Temp*{" "}
                    </span>
                    <select
                      id="dropdown"
                      value={smpReportState.MtcSet}
                      onChange={(e) => setSmpReportState({ ...smpReportState, MtcSet: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option value="">Select</option>
                      <option value="1">±1</option>
                      <option value="2">±2</option>
                      <option value="3">±3</option>
                      <option value="4">±4</option>
                      <option value="5">±5</option>
                      <option value="6">±6</option>
                      <option value="7">±7</option>
                      <option value="8">±8</option>
                      <option value="9">±9</option>
                      <option value="10">±10</option>
                      <option value="11">±11</option>
                      <option value="12">±12</option>
                      <option value="13">±13</option>
                      <option value="14">±14</option>
                      <option value="15">±15</option>
                    </select>
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.MTC}
                      onChange={(e) => setSmpReportState({ ...smpReportState, MTC: e.target.value })}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Hold On 4*</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Hold104}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Hold104: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Hold204}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Hold204: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Hold304}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Hold304: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Hold404}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Hold404: e.target.value })}
                    />
                  </td>
                  <td>RET SPD(MM/S)&#x27E1;</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.RetSpd}
                      onChange={(e) => setSmpReportState({ ...smpReportState, RetSpd: e.target.value })}
                    />
                  </td>
                  <td>On Time</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.OnTme}
                      onChange={(e) => setSmpReportState({ ...smpReportState, OnTme: e.target.value })}
                    />
                  </td>
                  <td>2.Refill</td>
                  <td>4.Cooling</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td colSpan="2">Sprue Bush Hole(MM)&#x27E1;</td>

                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.SprBushHle}
                      onChange={(e) => setSmpReportState({ ...smpReportState, SprBushHle: e.target.value })}
                    />
                  </td>
                  <td colSpan="2">Nozzle Hole(MM) &#x27E1;</td>

                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.NozzHole}
                      onChange={(e) => setSmpReportState({ ...smpReportState, NozzHole: e.target.value })}
                    />
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <table width="100%" style={{ textAlign: "center" }} border="0">
              <thead>
                <th style={{ textAlign: "center" }} colSpan="5">
                  REFILL SETTINGS
                </th>

                <th style={{ textAlign: "center" }} colSpan="4">
                  MOULD CLOSING SETTINGS
                </th>

                <th style={{ textAlign: "center" }} colSpan="5">
                  EJECTION SETTINGS
                </th>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td>
                    PRESSURE(BAR){" "}
                    <select
                      id="dropdown"
                      value={smpReportState.ReFillPresSet}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ReFillPresSet: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option value="">Select</option>
                      <option value="1">±1</option>
                      <option value="2">±2</option>
                      <option value="3">±3</option>
                      <option value="4">±4</option>
                      <option value="5">±5</option>
                      <option value="6">±6</option>
                      <option value="7">±7</option>
                      <option value="8">±8</option>
                      <option value="9">±9</option>
                      <option value="10">±10</option>
                      <option value="11">±11</option>
                      <option value="12">±12</option>
                      <option value="13">±13</option>
                      <option value="14">±14</option>
                      <option value="15">±15</option>
                    </select>
                  </td>
                  <td>
                    SPEED%{" "}
                    <select
                      id="dropdown"
                      value={smpReportState.ReFillSpdSet}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ReFillSpdSet: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option value="">Select</option>
                      <option value="1">±1</option>
                      <option value="2">±2</option>
                      <option value="3">±3</option>
                      <option value="4">±4</option>
                      <option value="5">±5</option>
                      <option value="6">±6</option>
                      <option value="7">±7</option>
                      <option value="8">±8</option>
                      <option value="9">±9</option>
                      <option value="10">±10</option>
                      <option value="11">±11</option>
                      <option value="12">±12</option>
                      <option value="13">±13</option>
                      <option value="14">±14</option>
                      <option value="15">±15</option>
                    </select>
                  </td>
                  <td>
                    POSITION(MM)
                    <select
                      id="dropdown"
                      value={smpReportState.ReFillPosSet}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ReFillPosSet: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option value="">Select</option>
                      <option value="1">±1</option>
                      <option value="2">±2</option>
                      <option value="3">±3</option>
                      <option value="4">±4</option>
                      <option value="5">±5</option>
                      <option value="6">±6</option>
                      <option value="7">±7</option>
                      <option value="8">±8</option>
                      <option value="9">±9</option>
                      <option value="10">±10</option>
                      <option value="11">±11</option>
                      <option value="12">±12</option>
                      <option value="13">±13</option>
                      <option value="14">±14</option>
                      <option value="15">±15</option>
                    </select>
                  </td>
                  <td>
                    TIME(SEC){" "}
                    <select
                      id="dropdown"
                      value={smpReportState.ReFillTmeSet}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ReFillTmeSet: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option value="">Select</option>
                      <option value="0.1">±0.1</option>
                      <option value="0.2">±0.2</option>
                      <option value="0.3">±0.3</option>
                      <option value="0.4">±0.4</option>
                      <option value="0.5">±0.5</option>
                      <option value="0.6">±0.6</option>
                      <option value="0.7">±0.7</option>
                      <option value="0.8">±0.8</option>
                      <option value="0.9">±0.9</option>
                      <option value="1">±1</option>
                      <option value="2">±2</option>
                      <option value="3">±3</option>
                      <option value="4">±4</option>
                      <option value="5">±5</option>
                    </select>
                    *
                  </td>
                  <td>STAGES&#x27E1;</td>
                  <td>
                    PRESSURE (BAR)&#x27E1;{" "}
                    <select
                      id="dropdown"
                      value={smpReportState.ClsePresSet}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ClsePresSet: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option value="">Select</option>
                      <option value="1">±1</option>
                      <option value="2">±2</option>
                      <option value="3">±3</option>
                      <option value="4">±4</option>
                      <option value="5">±5</option>
                      <option value="6">±6</option>
                      <option value="7">±7</option>
                      <option value="8">±8</option>
                      <option value="9">±9</option>
                      <option value="10">±10</option>
                      <option value="11">±11</option>
                      <option value="12">±12</option>
                      <option value="13">±13</option>
                      <option value="14">±14</option>
                      <option value="15">±15</option>
                    </select>
                  </td>
                  <td>
                    SPEED% &#x27E1;{" "}
                    <select
                      id="dropdown"
                      value={smpReportState.ClseSpdSet}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ClseSpdSet: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option value="">Select</option>
                      <option value="1">±1</option>
                      <option value="2">±2</option>
                      <option value="3">±3</option>
                      <option value="4">±4</option>
                      <option value="5">±5</option>
                      <option value="6">±6</option>
                      <option value="7">±7</option>
                      <option value="8">±8</option>
                      <option value="9">±9</option>
                      <option value="10">±10</option>
                      <option value="11">±11</option>
                      <option value="12">±12</option>
                      <option value="13">±13</option>
                      <option value="14">±14</option>
                      <option value="15">±15</option>
                    </select>
                  </td>
                  <td>POSITION (MM)&#x27E1;</td>
                  <td>STAGES&#x27E1;</td>
                  <td>
                    PRESSURE (BAR)&#x27E1;{" "}
                    <select
                      id="dropdown"
                      value={smpReportState.EjcPresSet}
                      onChange={(e) => setSmpReportState({ ...smpReportState, EjcPresSet: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option value="">Select</option>
                      <option value="1">±1</option>
                      <option value="2">±2</option>
                      <option value="3">±3</option>
                      <option value="4">±4</option>
                      <option value="5">±5</option>
                      <option value="6">±6</option>
                      <option value="7">±7</option>
                      <option value="8">±8</option>
                      <option value="9">±9</option>
                      <option value="10">±10</option>
                      <option value="11">±11</option>
                      <option value="12">±12</option>
                      <option value="13">±13</option>
                      <option value="14">±14</option>
                      <option value="15">±15</option>
                    </select>
                  </td>
                  <td>
                    SPEED%{" "}
                    <select
                      id="dropdown"
                      value={smpReportState.EjcSpdSet}
                      onChange={(e) => setSmpReportState({ ...smpReportState, EjcSpdSet: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option value="">Select</option>
                      <option value="1">±1</option>
                      <option value="2">±2</option>
                      <option value="3">±3</option>
                      <option value="4">±4</option>
                      <option value="5">±5</option>
                      <option value="6">±6</option>
                      <option value="7">±7</option>
                      <option value="8">±8</option>
                      <option value="9">±9</option>
                      <option value="10">±10</option>
                      <option value="11">±11</option>
                      <option value="12">±12</option>
                      <option value="13">±13</option>
                      <option value="14">±14</option>
                      <option value="15">±15</option>
                    </select>
                  </td>
                  <td>TIME (SEC)</td>
                  <td>POSITION (MM)</td>
                </tr>
                <tr>
                  <td>Refill 1&#x27E1;</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.ReFill101}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ReFill101: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.ReFill102}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ReFill102: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.ReFill103}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ReFill103: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.ReFill104}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ReFill104: e.target.value })}
                    />
                  </td>
                  <td>Close 1</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Close101}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Close101: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Close102}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Close102: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Close103}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Close103: e.target.value })}
                    />
                  </td>
                  <td>EJEC FRD 1</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.EjecFrd101}
                      onChange={(e) => setSmpReportState({ ...smpReportState, EjecFrd101: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.EjecFrd102}
                      onChange={(e) => setSmpReportState({ ...smpReportState, EjecFrd102: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.EjecFrd103}
                      onChange={(e) => setSmpReportState({ ...smpReportState, EjecFrd103: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.EjecFrd104}
                      onChange={(e) => setSmpReportState({ ...smpReportState, EjecFrd104: e.target.value })}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Refill 2&#x27E1;</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.ReFill201}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ReFill201: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.ReFill202}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ReFill202: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.ReFill203}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ReFill203: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.ReFill204}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ReFill204: e.target.value })}
                    />
                  </td>
                  <td>Close 2</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Close201}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Close201: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Close202}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Close202: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Close203}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Close203: e.target.value })}
                    />
                  </td>
                  <td>EJEC FRD 2</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.EjecFrd201}
                      onChange={(e) => setSmpReportState({ ...smpReportState, EjecFrd201: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.EjecFrd202}
                      onChange={(e) => setSmpReportState({ ...smpReportState, EjecFrd202: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.EjecFrd203}
                      onChange={(e) => setSmpReportState({ ...smpReportState, EjecFrd203: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.EjecFrd204}
                      onChange={(e) => setSmpReportState({ ...smpReportState, EjecFrd204: e.target.value })}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Refill 3&#x27E1;</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.ReFill301}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ReFill301: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.ReFill302}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ReFill302: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.ReFill303}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ReFill303: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.ReFill304}
                      onChange={(e) => setSmpReportState({ ...smpReportState, ReFill304: e.target.value })}
                    />
                  </td>
                  <td>Close 3</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Close301}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Close301: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Close302}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Close302: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Close303}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Close303: e.target.value })}
                    />
                  </td>
                  <td>EJEC RET 1</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.EjecRet101}
                      onChange={(e) => setSmpReportState({ ...smpReportState, EjecRet101: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.EjecRet102}
                      onChange={(e) => setSmpReportState({ ...smpReportState, EjecRet102: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.EjecRet103}
                      onChange={(e) => setSmpReportState({ ...smpReportState, EjecRet103: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.EjecRet104}
                      onChange={(e) => setSmpReportState({ ...smpReportState, EjecRet104: e.target.value })}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">Refill End/Short Size(MM)*:</td>

                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.RefillEnd}
                      onChange={(e) => setSmpReportState({ ...smpReportState, RefillEnd: e.target.value })}
                    />
                  </td>
                  <td></td>
                  <td>Mould safety/Protect&#x27E1; 1</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.MldSfy101}
                      onChange={(e) => setSmpReportState({ ...smpReportState, MldSfy101: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.MldSfy201}
                      onChange={(e) => setSmpReportState({ ...smpReportState, MldSfy201: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.MldSfy301}
                      onChange={(e) => setSmpReportState({ ...smpReportState, MldSfy301: e.target.value })}
                    />
                  </td>
                  <td>EJEC RET 2</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.EjecRet201}
                      onChange={(e) => setSmpReportState({ ...smpReportState, EjecRet201: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.EjecRet202}
                      onChange={(e) => setSmpReportState({ ...smpReportState, EjecRet202: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.EjecRet203}
                      onChange={(e) => setSmpReportState({ ...smpReportState, EjecRet203: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.EjecRet204}
                      onChange={(e) => setSmpReportState({ ...smpReportState, EjecRet204: e.target.value })}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    Suck Back&#x27E1;
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.SukSet}
                      onChange={(e) => setSmpReportState({ ...smpReportState, SukSet: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    />{" "}
                    (MM):
                  </td>

                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.SuckBack}
                      onChange={(e) => setSmpReportState({ ...smpReportState, SuckBack: e.target.value })}
                    />
                  </td>
                  <td></td>
                  <td>Mould safety/Protect&#x27E1; 2</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.MldSfy102}
                      onChange={(e) => setSmpReportState({ ...smpReportState, MldSfy102: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.MldSfy202}
                      onChange={(e) => setSmpReportState({ ...smpReportState, MldSfy202: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.MldSfy302}
                      onChange={(e) => setSmpReportState({ ...smpReportState, MldSfy302: e.target.value })}
                    />
                  </td>
                  <td colSpan="5"></td>
                </tr>
                <tr>
                  <td>Back Pres&#x27E1; 1</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Bp101}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Bp101: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Bp102}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Bp102: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Bp103}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Bp103: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Bp104}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Bp104: e.target.value })}
                    />
                  </td>
                  <td>Mould Safety Time(SEC)&#x27E1;:</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.MoldSafeT}
                      onChange={(e) => setSmpReportState({ ...smpReportState, MoldSafeT: e.target.value })}
                    />
                  </td>
                  <td>Actual:</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Actual1}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Actual1: e.target.value })}
                    />
                  </td>
                  <td>STAGES&#x27E1;</td>
                  <td>PRESSURE (BAR)&#x27E1;±2</td>
                  <td>SPEED% ±10</td>
                  <td>TIME (SEC)</td>
                  <td>POSITION (MM)</td>
                </tr>

                <tr>
                  <td>Back Pres&#x27E1; 2</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Bp201}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Bp201: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Bp202}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Bp202: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Bp203}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Bp203: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Bp204}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Bp204: e.target.value })}
                    />
                  </td>
                  <td colSpan="2">Clamp Force&#x27E1;</td>

                  <td></td>
                  <td></td>
                  <td>CORE 1 IN</td>
                  <td>
                    {" "}
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Cre1}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Cre1: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Core101}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Core101: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Core102}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Core102: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Core103}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Core103: e.target.value })}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Melt Cushion(MM)*
                    <select

                      value={smpReportState.CusSet}
                      onChange={(e) =>
                        setSmpReportState({
                          ...smpReportState,
                          CusSet: e.target.value,
                        })
                      }
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option
                        id="smp.Ed.CusSet.sct"
                        name="smp.Ed.CusSet.sct"
                        value=""
                      >
                        Select
                      </option>
                      <option
                        id="smp.Ed.CusSet.0.1"
                        name="smp.Ed.CusSet.0.1"
                        value="0.1"
                      >
                        ±0.1
                      </option>
                      <option
                        id="smp.Ed.CusSet.0.2"
                        name="smp.Ed.CusSet.0.2"
                        value="0.2"
                      >
                        ±0.2
                      </option>
                      <option
                        id="smp.Ed.CusSet.0.3"
                        name="smp.Ed.CusSet.0.3"
                        value="0.3"
                      >
                        ±0.3
                      </option>
                      <option
                        id="smp.Ed.CusSet.0.4"
                        name="smp.Ed.CusSet.0.4"
                        value="0.4"
                      >
                        ±0.4
                      </option>
                      <option
                        id="smp.Ed.CusSet.0.5"
                        name="smp.Ed.CusSet.0.5"
                        value="0.5"
                      >
                        ±0.5
                      </option>
                      <option
                        id="smp.Ed.CusSet.0.6"
                        name="smp.Ed.CusSet.0.6"
                        value="0.6"
                      >
                        ±0.6
                      </option>
                      <option
                        id="smp.Ed.CusSet.0.7"
                        name="smp.Ed.CusSet.0.7"
                        value="0.7"
                      >
                        ±0.7
                      </option>
                      <option
                        id="smp.Ed.CusSet.0.8"
                        name="smp.Ed.CusSet.0.8"
                        value="0.8"
                      >
                        ±0.8
                      </option>
                      <option

                        value="0.9"
                      >
                        ±0.9
                      </option>
                      <option id="smp.Ed.CusSet.1" name="smp.Ed.CusSet.1" value="1">
                        ±1
                      </option>
                      <option id="smp.Ed.CusSet.2" name="smp.Ed.CusSet.2" value="2">
                        ±2
                      </option>
                      <option id="smp.Ed.CusSet.3" name="smp.Ed.CusSet.3" value="3">
                        ±3
                      </option>
                      <option id="smp.Ed.CusSet.4" name="smp.Ed.CusSet.4" value="4">
                        ±4
                      </option>
                      <option id="smp.Ed.CusSet.5" name="smp.Ed.CusSet.5" value="5">
                        ±5
                      </option>
                      <option id="smp.Ed.CusSet.6" name="smp.Ed.CusSet.6" value="6">
                        ±6
                      </option>
                      <option value="7">
                        ±7
                      </option>
                      <option id="smp.Ed.CusSet.8" name="smp.Ed.CusSet.8" value="8">
                        ±8
                      </option>
                      <option id="smp.Ed.CusSet.9" name="smp.Ed.CusSet.9" value="9">
                        ±9
                      </option>
                      <option
                        id="smp.Ed.CusSet.10"
                        name="smp.Ed.CusSet.10"
                        value="10"
                      >
                        ±10
                      </option>
                    </select>
                    :
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Melt101}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Melt101: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Melt102}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Melt102: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Melt103}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Melt103: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Melt104}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Melt104: e.target.value })}
                    />
                  </td>
                  <th style={{ textAlign: "center" }} colSpan="4">
                    MOULD OPENING SETTINGS
                  </th>

                  <td>CORE 1 OUT</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Cre2}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Cre2: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Core201}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Core201: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Core202}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Core202: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Core203}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Core203: e.target.value })}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Cooling Time*
                    <select
                      id="dropdown"
                      value={smpReportState.CoolTmeSet}
                      onChange={(e) => setSmpReportState({ ...smpReportState, CoolTmeSet: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option value="">Select</option>
                      <option value="1">±1</option>
                      <option value="2">±2</option>
                      <option value="3">±3</option>
                      <option value="4">±4</option>
                      <option value="5">±5</option>
                      <option value="6">±6</option>
                      <option value="7">±7</option>
                      <option value="8">±8</option>
                      <option value="9">±9</option>
                      <option value="10">±10</option>
                      <option value="11">±11</option>
                      <option value="12">±12</option>
                      <option value="13">±13</option>
                      <option value="14">±14</option>
                      <option value="15">±15</option>
                    </select>
                    (Sec)
                  </td>
                  <td colSpan="4">
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Cooltme}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Cooltme: e.target.value })}
                    />
                  </td>

                  <td>Open 1 &#x27E1;</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Open201}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Open201: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Open202}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Open202: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Open203}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Open203: e.target.value })}
                    />
                  </td>
                  <td>CORE 2 IN</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Cre3}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Cre3: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Core301}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Core301: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Core302}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Core302: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Core303}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Core303: e.target.value })}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Cycle Time&#x27E1;
                    <select
                      id="dropdown"
                      value={smpReportState.CycTmeSet}
                      onChange={(e) => setSmpReportState({ ...smpReportState, CycTmeSet: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option value="">Select</option>
                      <option value="1">±1</option>
                      <option value="2">±2</option>
                      <option value="3">±3</option>
                      <option value="4">±4</option>
                      <option value="5">±5</option>
                      <option value="6">±6</option>
                      <option value="7">±7</option>
                      <option value="8">±8</option>
                      <option value="9">±9</option>
                      <option value="10">±10</option>
                      <option value="11">±11</option>
                      <option value="12">±12</option>
                      <option value="13">±13</option>
                      <option value="14">±14</option>
                      <option value="15">±15</option>
                    </select>
                    (Sec){" "}
                  </td>
                  <td colSpan="4">
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Cycletme}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Cycletme: e.target.value })}
                    />
                  </td>
                  <td>Open 2 &#x27E1;</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Open301}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Open301: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Open302}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Open302: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Open303}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Open303: e.target.value })}
                    />
                  </td>
                  <td>CORE 2 OUT</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Cre4}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Cre4: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Core401}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Core401: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Core402}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Core402: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Core403}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Core403: e.target.value })}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Oil Temp&#x27E1;
                    <select
                      id="dropdown"
                      value={smpReportState.OilSet}
                      onChange={(e) => setSmpReportState({ ...smpReportState, OilSet: e.target.value })}
                      style={{ backgroundColor: "#efd9b1" }}
                    >
                      <option value="">Select</option>
                      <option value="1">±1</option>
                      <option value="2">±2</option>
                      <option value="3">±3</option>
                      <option value="4">±4</option>
                      <option value="5">±5</option>
                      <option value="6">±6</option>
                      <option value="7">±7</option>
                      <option value="8">±8</option>
                      <option value="9">±9</option>
                      <option value="10">±10</option>
                      <option value="11">±11</option>
                      <option value="12">±12</option>
                      <option value="13">±13</option>
                      <option value="14">±14</option>
                      <option value="15">±15</option>
                    </select>
                    °C
                  </td>
                  <td colSpan="4">
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Oilt}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Oilt: e.target.value })}
                    />
                  </td>
                  <td>Open 3 &#x27E1;</td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Open401}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Open401: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Open402}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Open402: e.target.value })}
                    />
                  </td>
                  <td>
                    <input
                      // readOnly={viewMode}
                      type="text"
                      value={smpReportState.Open403}
                      onChange={(e) => setSmpReportState({ ...smpReportState, Open403: e.target.value })}
                    />
                  </td>
                  <td colSpan="5"></td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    Prepared By
                  </td>
                  <td colSpan={4}> <DropdownFetcher keyword="emp_details_dropdown_all" setValue={setEmployee} />
                    <Select
                      isDisabled={smpReportState.McNo === "" || smpReportState.PrtNme === "" || smpReportState.MatNme === "" || smpReportState.NoCav === "" || smpReportState.GrdNme === "" || smpReportState.Colour === "" || smpReportState.PrtWgt === "" || smpReportState.PrtSet === "" || smpReportState.Runn === "" || smpReportState.Modl === ""}
                      styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                      value={smpReportState.PreparedBy}
                      onChange={handlePreparedBy}
                      options={Employee.map((i) => ({
                        value: i.Emp_code,
                        label: i.Emp_name,
                      }))}
                    /></td>
                  <td colSpan={2}>Approver</td>
                  <td colSpan={5}>
                    {/* <DropdownFetcher keyword="emp_details_dropdown_all" setValue={setEmployee} /> */}
                    <Select
                      isDisabled={smpReportState.McNo === "" || smpReportState.PrtNme === "" || smpReportState.MatNme === "" || smpReportState.NoCav === "" || smpReportState.GrdNme === "" || smpReportState.Colour === "" || smpReportState.PrtWgt === "" || smpReportState.PrtSet === "" || smpReportState.Runn === "" || smpReportState.Modl === ""}

                      styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                      value={smpReportState.ApprovedBy}
                      onChange={handleApprovedBy}
                      options={Employee.map((i) => ({
                        value: i.Emp_code,
                        label: i.Emp_name,
                      }))}
                    /></td>
                </tr>
                <tr>
                  <td colSpan="14">
                    <button
                      type="submit"
                      id="sub"
                      onClick={(e) => handleUpdate(e)}
                    >
                      Submit
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </Popup>
      </div>

      <div>
        <main>

          <table style={{ maxWidth: "1400px" }}>
            <thead >
              <tr>
                <th
                  colSpan="10"
                  style={{ textAlign: "center", whiteSpace: "nowrap" }}
                >
                  <font color="#8B0000">
                    List - Standard Moulding Parameter
                  </font>
                </th>
              </tr>
              <tr>
                <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                  Date
                </td>
                <td colSpan="1">
                  <input
                    type="date"
                    placeholder="Filter by Date"
                    value={smpReportState.filterDate}
                    onChange={handleFilterDateChange}
                  />
                </td>
                <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                  MachineNo
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="Filter MachineNo"
                    style={{ fontSize: "70%", width: "100%" }}
                    value={smpReportState.filterMcno}
                    onChange={handleFilterMcnoChange}
                  />
                </td>
                <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                  Part Name
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="Filter PartName"
                    style={{ fontSize: "70%", width: "100%" }}
                    value={smpReportState.filterPrtNme}
                    onChange={handleFilterPartNameChange}
                  />
                </td>
                <td colSpan="8"></td>
              </tr>
              <tr className="table_position">
                <th style={{ textAlign: "center", width: "10%" }}>Date</th>
                <th style={{ textAlign: "center" }}>Machine No</th>
                <th style={{ textAlign: "center" }}>Raw Material Name</th>
                <th style={{ textAlign: "center" }}>Part Name</th>
                <th style={{ textAlign: "center" }}>Prepared By</th>
                <th style={{ textAlign: "center" }}>Approved By</th>
                <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                  Status
                </th>
                {/* <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                  &nbsp;&nbsp;Created Date&nbsp;&nbsp;
                </th> */}
                <th style={{ width: "12%" }}></th>
                <th style={{ width: "12%" }}></th>
                {GetAccess("ADMIN", "GM") &&
                  <th style={{ width: "12%" }}></th>
                }
              </tr>
            </thead>
            <tbody>
              {/* <HandleRead keyword="std_mld_par" setData={setstd_mld_par} /> */}
              {filteredRecords.length === 0 ? (
                // <tr>
                <td colSpan="13" style={{ textAlign: "center" }}>
                  <p style={{ fontWeight: "bold" }}>No records found.</p>
                </td>
              ) : (
                // </tr>
                filteredRecords.map((std_mld_par) => (
                  <tr className="table_view" key={std_mld_par.Id}>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {formatDt(std_mld_par.TrackDate)}
                    </td>
                    <td className="table-cell1">{std_mld_par.McNo}</td>
                    <td className="table-cell1">{std_mld_par.RawMaterials}</td>
                    <td className="table-cell1" >{std_mld_par.PrtNme}</td>
                    {/* <td
                      className="table-cell1" style={{ whiteSpace: "nowrap", width: "12%" }}>
                      {std_mld_par.CreatedDate}
                    </td> */}
                    <td className="table-cell1">{std_mld_par.PreparedBy}-{std_mld_par.prepared_by}</td>
                    <td className="table-cell1">{std_mld_par.ApprovedBy}-{std_mld_par.approved_by}</td>
                    <td
                      style={{

                        color: std_mld_par.Approval_status === "0" ? 'red' : std_mld_par.Approval_status === "1" ? 'green' : std_mld_par.Approval_status === undefined ? '#E59500' : "",

                      }}
                      className="table-cell"
                    >
                      &nbsp;&nbsp;{std_mld_par.Approval_status === undefined ? "Waiting for approval..." : std_mld_par.Approval_status === "1" ? "Approved" : std_mld_par.Approval_status === "0" ? "Rejected" : ""}
                    </td>

                    <td>
                      <button
                        style={{ backgroundColor: "#cba423" ,whiteSpace:"nowrap"}}
                        type="button"
                        onClick={() => handleViews(std_mld_par)}
                      >
                        View&#x1F441;
                      </button>
                    </td>
                    <td>
                      <button
                        type="button"
                        onClick={() => handleEdit(std_mld_par)}
                      >
                        Edit&#x270E;
                      </button>
                    </td>
                    {GetAccess("ADMIN", "GM") &&
                      <td>
                        <button
                          id={`smp-Delete`}
                          name={`smp-Delete`}
                          style={{ whiteSpace: "nowrap", backgroundColor: "#DA251A" }}
                          type="button"
                          onClick={() => {
                            const userConfirmed = window.confirm('Are you sure you want to delete?');
                            if (userConfirmed) {
                              UpdateRecord({
                                _keyword_: 'std_mld_par',
                                data: [
                                  {
                                    Id: std_mld_par.Id,
                                    ActiveStatus: 'InActive',
                                  },
                                ],
                              }).then(() => {
                                handleReadStandMldPar();
                              })
                              // window.location.reload();
                            }
                          }}
                        >
                          Delete<MdDelete />
                        </button>
                      </td>
                    }
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <div className="pagination" id="pagination">


            {smpReportState.isPaginationVisible && filteredData.length > 0 && (

              <Pagination currentPage={smpReportState.currentPage} npage={npage} prePage={prePage} nextPage={nextPage} changeCpage={changeCpage} numbers={numbers} />
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default SmpView;
