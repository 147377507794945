import React, { useState, useEffect, useRef } from "react";

import DropdownFetcher from '../../../Lib/DropdownFetcher.js';
// import DropdownSelect from '../../../Components/DropdownSelect.js';
import "../../../Styles/Part3.css";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import { PROCESS_TRANSACTION_URL, SECRET_KEY } from "../../../Utils/constants.js";
import Select from "react-select";
import { setMessage } from "../../../Lib/SetMessage.js";
import GetRecord from "../../../Lib/GetRecord.js";
import CreateApproval from "../../../Lib/CreateApproval";
import ReadRecord from "../../../Lib/API/ReadRecord.js";



const initialState = {
  PartNo: "",
  FormetNo: "",
  TrackDate: new Date().toISOString().split("T")[0],
  McNo: "",
  MatNme: "",
  NoCav: "",
  PrtNme: "",
  GrdNme: "",
  Colour: "",
  PrtWgt1: "",
  PrtWgt2: "",
  part_weight_1: "",
  part_weight_2: "",
  PrtSetplus1:"",
  PrtSetMinus1:"",
  PrtSetplus2:"",
  PrtSetMinus2:"",
  part_weight_set_minus_1: "",
  part_weight_set_minus_2: "",
  part_weight_set_plus_1: "",
  part_weight_set_plus_2: "",
  Runn: "",
  Inj101: "",
  Inj102: "",
  Inj103: "",
  Inj104: "",
  Modl: "",
  Inj201: "",
  Inj202: "",
  Inj203: "",
  Inj204: "",
  Inj301: "",
  Inj302: "",
  Inj303: "",
  Inj304: "",
  Inj401: "",
  Inj402: "",
  Inj403: "",
  Inj404: "",
  FillT104: "",
  Zone1: "",
  Zone2: "",
  Zone3: "",
  Hozz: "",
  Hopp: "",
  Hold101: "",
  Hold201: "",
  Hold301: "",
  Hold401: "",
  Hold102: "",
  Hold202: "",
  Hold302: "",
  Hold402: "",
  Hold103: "",
  Hold203: "",
  Hold303: "",
  Hold403: "",
  Hold104: "",
  Hold204: "",
  Hold304: "",
  Hold404: "",
  Core: "",
  Cavity: "",
  Dely: "",
  MTC: "",
  Fwd: "",
  OnTme: "",
  RetSpd: "",
  SprBushHle: "",
  NozzHole: "",
  ReFill101: "",
  ReFill102: "",
  ReFill103: "",
  ReFill104: "",
  ReFill201: "",
  ReFill202: "",
  ReFill203: "",
  ReFill204: "",
  ReFill301: "",
  ReFill302: "",
  ReFill303: "",
  ReFill304: "",
  Close101: "",
  Close102: "",
  Close103: "",
  Close201: "",
  Close202: "",
  Close203: "",
  Close301: "",
  Close302: "",
  Close303: "",
  EjecFrd101: "",
  EjecFrd102: "",
  EjecFrd103: "",
  EjecFrd104: "",
  EjecFrd201: "",
  EjecFrd202: "",
  EjecFrd203: "",
  EjecFrd204: "",
  EjecRet101: "",
  EjecRet102: "",
  EjecRet103: "",
  EjecRet104: "",
  EjecRet201: "",
  EjecRet202: "",
  EjecRet203: "",
  EjecRet204: "",
  RefillEnd: "",
  MldSfy101: "",
  MldSfy201: "",
  MldSfy301: "",
  MldSfy102: "",
  MldSfy202: "",
  MldSfy302: "",
  SuckBack: "",
  Bp101: "",
  Bp102: "",
  Bp103: "",
  Bp104: "",
  MoldSafeT: "",
  Actual1: "",
  Bp201: "",
  Bp202: "",
  Bp203: "",
  Bp204: "",
  Melt101: "",
  Melt102: "",
  Melt103: "",
  Melt104: "",
  Open401: "",
  Open402: "",
  Open403: "",
  Open201: "",
  Open202: "",
  Open203: "",
  Open301: "",
  Open302: "",
  Open303: "",
  Core301: "",
  Core302: "",
  Core303: "",
  Core101: "",
  Core102: "",
  Core103: "",
  Core201: "",
  Core202: "",
  Core203: "",
  Core401: "",
  Core402: "",
  Core403: "",
  ManFld1: "",
  ManFld2: "",
  ManFld3: "",
  ManFld4: "",
  ManFld5: "",
  Gte2: "",
  Gte3: "",
  Gte4: "",
  Gte5: "",
  Gte6: "",
  Gate1: "",
  Gate2: "",
  Gate3: "",
  Gate4: "",
  Gate5: "",
  Gate6: "",
  Gate7: "",
  Gate8: "",
  Gate9: "",
  Gate10: "",
  Gate11: "",
  Gate12: "",
  Cre1: "",
  Cre2: "",
  Cre3: "",
  Cre4: "",
  Oilt: "",
  Cycletme: "",
  Cooltme: "",
  Switch: "",
  NozzSet: "",
  ZneSet1: "",
  ZneSet2: "",
  ZneSet3: "",
  HoppSet: "",
  CoreSet: "",
  CavSet: "",
  MtcSet: "",
  InjPresSet: "",
  InjSpdSet: "",
  InjPosSet: "",
  InjTmeSet: "",
  CycTmeSet: "",
  CoolTmeSet: "",
  CusSet: "",
  ManiFldSet1: "",
  ManiFldSet2: "",
  ManiFldSet3: "",
  ManiFldSet4: "",
  ManiFldSet5: "",
  GteSet: "",
  OnSet: "",
  OffSet: "",
  ReFillPresSet: "",
  ReFillSpdSet: "",
  ReFillPosSet: "",
  ReFillTmeSet: "",
  ClsePresSet: "",
  ClseSpdSet: "",
  EjcPresSet: "",
  EjcSpdSet: "",
  SukSet: "",
  OilSet: "",
  Zone4: "",
  Zone5: "",
  Zone6: "",
  ZneSet6: "",
  ZneSet5: "",
  ZneSet4: "",
  PreparedBy: "",
  ApprovedBy: "",
  PartWeight1: "",
  PartWeight2: "",
  PartWeightPlus1: "",
  PartWeightPlus2: "",
  PartWeightMinus1: "",
  PartWeightMinus2: "",
  viewMode: false,
};

function SmpCreate() {
  const scrollTop = useRef(null);
  const [isCreating, setIsCreating] = useState(false);
  const [smpCreateState, setSmpCreateState] = useState(initialState);
  const [std_mld_par, setstd_mld_par] = useState([]);
  const [dropdown, setDropdown] = useState([]);
  const [RawMaterialDropdown, setRawMaterialDropdown] = useState([]);
  const [partDropdown, setPartDropdown] = useState([]);
  const [Employee, setEmployee] = useState([]);
  // const [Duplicate, setDuplicate] = useState([]);

  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;
  const today = new Date().toISOString().split("T")[0];

  const handleDateChange = (event) => {
    const selected = event.target.value;

    if (selected >= today) {
      setSmpCreateState({ ...smpCreateState, TrackDate: selected });
    }
    if (selected > today) {
      alert(GeneralConstants.SELECTED_FUTURE_DATE);

    }
  };
  const handleCreate = async (e) => {
    e.preventDefault();

    const currentDate = new Date().toISOString().split("T")[0];

    if (
      !smpCreateState.McNo ||
      !smpCreateState.MatNme ||
      !smpCreateState.PrtNme ||
      !smpCreateState.GrdNme ||
      !smpCreateState.Colour ||
      !smpCreateState.PreparedBy ||
      !smpCreateState.ApprovedBy
    ) {
      alert(GeneralConstants.REQUIRED_ALERT);
      setIsCreating(true);
      return;
    }

    try {
      const data = {
        data: [
          {
            FormetNo: smpCreateState.FormetNo,
            TrackDate: smpCreateState.TrackDate || currentDate,
            McNo: smpCreateState.McNo.value,
            MatNme: smpCreateState.MatNme.value,
            NoCav: smpCreateState.NoCav,
            PrtNme: smpCreateState.PrtNme.value,
            PartNo: smpCreateState.PartNo,
            GrdNme: smpCreateState.GrdNme,
            Colour: smpCreateState.Colour,
            part_weight_1: smpCreateState.PrtWgt1,
            part_weight_2: smpCreateState.PrtWgt2,
            part_weight_set_minus_1: smpCreateState.PrtSetMinus1,
            part_weight_set_minus_2: smpCreateState.PrtSetMinus2,
            part_weight_set_plus_1: smpCreateState.PrtSetplus1,
            part_weight_set_plus_2: smpCreateState.PrtSetplus2,
            // part_weight_1: smpCreateState.PartWeight1,
            // part_weight_2: smpCreateState.PartWeight2,
            // part_weight_set_minus_1: smpCreateState.PartWeightMinus1,
            // part_weight_set_minus_2: smpCreateState.PartWeightMinus2,
            // part_weight_set_plus_1: smpCreateState.PartWeightPlus1,
            // part_weight_set_plus_2: smpCreateState.PartWeightPlus2,
            Runn: smpCreateState.Runn,
            Inj101: smpCreateState.Inj101,
            Inj102: smpCreateState.Inj102,
            Inj103: smpCreateState.Inj103,
            Inj104: smpCreateState.Inj104,
            Modl: smpCreateState.Modl,
            Inj201: smpCreateState.Inj201,
            Inj202: smpCreateState.Inj202,
            Inj203: smpCreateState.Inj203,
            Inj204: smpCreateState.Inj204,
            Inj301: smpCreateState.Inj301,
            Inj302: smpCreateState.Inj302,
            Inj303: smpCreateState.Inj303,
            Inj304: smpCreateState.Inj304,
            Inj401: smpCreateState.Inj401,
            Inj402: smpCreateState.Inj402,
            Inj403: smpCreateState.Inj403,
            Inj404: smpCreateState.Inj404,
            FillT104: smpCreateState.FillT104,
            Zone2: smpCreateState.Zone2,
            Zone1: smpCreateState.Zone1,
            Zone3: smpCreateState.Zone3,
            Hozz: smpCreateState.Hozz,
            Hopp: smpCreateState.Hopp,
            Hold101: smpCreateState.Hold101,
            Hold201: smpCreateState.Hold201,
            Hold301: smpCreateState.Hold301,
            Hold401: smpCreateState.Hold401,
            Hold102: smpCreateState.Hold102,
            Hold202: smpCreateState.Hold202,
            Hold302: smpCreateState.Hold302,
            Hold402: smpCreateState.Hold402,
            Hold103: smpCreateState.Hold103,
            Hold203: smpCreateState.Hold203,
            Hold303: smpCreateState.Hold303,
            Hold403: smpCreateState.Hold403,
            Hold104: smpCreateState.Hold104,
            Hold204: smpCreateState.Hold204,
            Hold304: smpCreateState.Hold304,
            Hold404: smpCreateState.Hold404,
            Core: smpCreateState.Core,
            Cavity: smpCreateState.Cavity,
            Dely: smpCreateState.Dely,
            MTC: smpCreateState.MTC,
            Fwd: smpCreateState.Fwd,
            OnTme: smpCreateState.OnTme,
            RetSpd: smpCreateState.RetSpd,
            SprBushHle: smpCreateState.SprBushHle,
            NozzHole: smpCreateState.NozzHole,
            ReFill101: smpCreateState.ReFill101,
            ReFill102: smpCreateState.ReFill102,
            ReFill103: smpCreateState.ReFill103,
            ReFill104: smpCreateState.ReFill104,
            ReFill201: smpCreateState.ReFill201,
            ReFill202: smpCreateState.ReFill202,
            ReFill203: smpCreateState.ReFill203,
            ReFill204: smpCreateState.ReFill204,
            ReFill301: smpCreateState.ReFill301,
            ReFill302: smpCreateState.ReFill302,
            ReFill303: smpCreateState.ReFill303,
            ReFill304: smpCreateState.ReFill304,
            Close101: smpCreateState.Close101,
            Close102: smpCreateState.Close102,
            Close103: smpCreateState.Close103,
            Close201: smpCreateState.Close201,
            Close202: smpCreateState.Close202,
            Close203: smpCreateState.Close203,
            Close301: smpCreateState.Close301,
            Close302: smpCreateState.Close302,
            Close303: smpCreateState.Close303,
            EjecFrd101: smpCreateState.EjecFrd101,
            EjecFrd102: smpCreateState.EjecFrd102,
            EjecFrd103: smpCreateState.EjecFrd103,
            EjecFrd104: smpCreateState.EjecFrd104,
            EjecFrd201: smpCreateState.EjecFrd201,
            EjecFrd202: smpCreateState.EjecFrd202,
            EjecFrd203: smpCreateState.EjecFrd203,
            EjecFrd204: smpCreateState.EjecFrd204,
            EjecRet101: smpCreateState.EjecRet101,
            EjecRet102: smpCreateState.EjecRet102,
            EjecRet103: smpCreateState.EjecRet103,
            EjecRet104: smpCreateState.EjecRet104,
            EjecRet201: smpCreateState.EjecRet201,
            EjecRet202: smpCreateState.EjecRet202,
            EjecRet203: smpCreateState.EjecRet203,
            EjecRet204: smpCreateState.EjecRet204,
            RefillEnd: smpCreateState.RefillEnd,
            MldSfy101: smpCreateState.MldSfy101,
            MldSfy201: smpCreateState.MldSfy201,
            MldSfy301: smpCreateState.MldSfy301,
            MldSfy102: smpCreateState.MldSfy102,
            MldSfy202: smpCreateState.MldSfy202,
            MldSfy302: smpCreateState.MldSfy302,
            SuckBack: smpCreateState.SuckBack,
            Bp101: smpCreateState.Bp101,
            Bp102: smpCreateState.Bp102,
            Bp103: smpCreateState.Bp103,
            Bp104: smpCreateState.Bp104,
            MoldSafeT: smpCreateState.MoldSafeT,
            Actual1: smpCreateState.Actual1,
            Bp201: smpCreateState.Bp201,
            Bp202: smpCreateState.Bp202,
            Bp203: smpCreateState.Bp203,
            Bp204: smpCreateState.Bp204,
            Melt101: smpCreateState.Melt101,
            Melt102: smpCreateState.Melt102,
            Melt103: smpCreateState.Melt103,
            Melt104: smpCreateState.Melt104,
            Open401: smpCreateState.Open401,
            Open402: smpCreateState.Open402,
            Open403: smpCreateState.Open403,
            Open201: smpCreateState.Open201,
            Open202: smpCreateState.Open202,
            Open203: smpCreateState.Open203,
            Open301: smpCreateState.Open301,
            Open302: smpCreateState.Open302,
            Open303: smpCreateState.Open303,
            Core301: smpCreateState.Core301,
            Core302: smpCreateState.Core302,
            Core303: smpCreateState.Core303,
            Core101: smpCreateState.Core101,
            Core102: smpCreateState.Core102,
            Core103: smpCreateState.Core103,
            Core201: smpCreateState.Core201,
            Core202: smpCreateState.Core202,
            Core203: smpCreateState.Core203,
            Core401: smpCreateState.Core401,
            Core402: smpCreateState.Core402,
            Core403: smpCreateState.Core403,
            Oilt: smpCreateState.Oilt,
            Cycletme: smpCreateState.Cycletme,
            Cooltme: smpCreateState.Cooltme,
            Switch: smpCreateState.Switch,
            ManFld1: smpCreateState.ManFld1,
            ManFld2: smpCreateState.ManFld2,
            ManFld3: smpCreateState.ManFld3,
            ManFld4: smpCreateState.ManFld4,
            ManFld5: smpCreateState.ManFld5,
            Gte2: smpCreateState.Gte2,
            Gte3: smpCreateState.Gte3,
            Gte4: smpCreateState.Gte4,
            Gte5: smpCreateState.Gte5,
            Gte6: smpCreateState.Gte6,
            Gate1: smpCreateState.Gate1,
            Gate2: smpCreateState.Gate2,
            Gate3: smpCreateState.Gate3,
            Gate4: smpCreateState.Gate4,
            Gate5: smpCreateState.Gate5,
            Gate6: smpCreateState.Gate6,
            Gate7: smpCreateState.Gate7,
            Gate8: smpCreateState.Gate8,
            Gate9: smpCreateState.Gate9,
            Gate10: smpCreateState.Gate10,
            Gate11: smpCreateState.Gate11,
            Gate12: smpCreateState.Gate12,
            Cre1: smpCreateState.Cre1,
            Cre2: smpCreateState.Cre2,
            Cre3: smpCreateState.Cre3,
            Cre4: smpCreateState.Cre4,
            NozzSet: smpCreateState.NozzSet,
            ZneSet1: smpCreateState.ZneSet1,
            ZneSet2: smpCreateState.ZneSet2,
            ZneSet3: smpCreateState.ZneSet3,
            HoppSet: smpCreateState.HoppSet,
            CoreSet: smpCreateState.CoreSet,
            CavSet: smpCreateState.CavSet,
            MtcSet: smpCreateState.MtcSet,
            InjPresSet: smpCreateState.InjPresSet,
            InjSpdSet: smpCreateState.InjSpdSet,
            InjPosSet: smpCreateState.InjPosSet,
            InjTmeSet: smpCreateState.InjTmeSet,
            CycTmeSet: smpCreateState.CycTmeSet,
            CoolTmeSet: smpCreateState.CoolTmeSet,
            CusSet: smpCreateState.CusSet,
            ManiFldSet1: smpCreateState.ManiFldSet1,
            ManiFldSet2: smpCreateState.ManiFldSet2,
            ManiFldSet3: smpCreateState.ManiFldSet3,
            ManiFldSet4: smpCreateState.ManiFldSet4,
            ManiFldSet5: smpCreateState.ManiFldSet5,
            GteSet: smpCreateState.GteSet,
            OnSet: smpCreateState.OnSet,
            OffSet: smpCreateState.OffSet,
            ReFillPresSet: smpCreateState.ReFillPresSet,
            ReFillSpdSet: smpCreateState.ReFillSpdSet,
            ReFillPosSet: smpCreateState.ReFillPosSet,
            ReFillTmeSet: smpCreateState.ReFillTmeSet,
            ClsePresSet: smpCreateState.ClsePresSet,
            ClseSpdSet: smpCreateState.ClseSpdSet,
            EjcSpdSet: smpCreateState.EjcSpdSet,
            EjcPresSet: smpCreateState.EjcPresSet,
            SukSet: smpCreateState.SukSet,
            OilSet: smpCreateState.OilSet,
            Zone4: smpCreateState.Zone4,
            Zone5: smpCreateState.Zone5,
            Zone6: smpCreateState.Zone6,
            ZneSet4: smpCreateState.ZneSet4,
            ZneSet5: smpCreateState.ZneSet5,
            ZneSet6: smpCreateState.ZneSet6,
            PreparedBy: smpCreateState.PreparedBy.value,
            ApprovedBy: smpCreateState.ApprovedBy.value,
          },
        ],
        _keyword_: "std_mld_par",
        secretkey: SECRET_KEY,
      };

      const response = await getAPIRequest(PROCESS_TRANSACTION_URL, "POST", data);

      if (!response.ok) {
        console.error("Error:", response.status, response.statusText);
        alert(GeneralConstants.SUBMISSION_FAILED);
        return;
      }

      const responseBody = await response.json();
      console.log("Backend Response:", responseBody);


      const feedbackData = responseBody.feedback;


      alert(GeneralConstants.SUBMISSION_SUCCESSFUL);
      setSmpCreateState({
        ...smpCreateState, ...initialState
      });
      setMessage({
        NoteFrom: smpCreateState.PreparedBy.value,
        NoteTo: smpCreateState.ApprovedBy.value,
        NoteMessage: `Hi Mr.${smpCreateState.ApprovedBy.label} SMP Master for part Number ${smpCreateState.PrtNme.label} in machine ${smpCreateState.McNo.value}, This was prepared by ${smpCreateState.PreparedBy.value}-${smpCreateState.PreparedBy.label}`,
        NoteRecordId: feedbackData[0].ROWID,
        TableName: "Smp",
        NoteCreatedBy: localStorage.getItem("empId"),

      });
      CreateApproval({
        RequestedBy:
          `${smpCreateState.PreparedBy.value}`,
        Approver: smpCreateState.ApprovedBy.value,
        Level: 1,
        Status: "Waiting for Approval",
        TableName: "Smp",
        TableID: feedbackData[0].ROWID,
        Description:
          "The SMP Master Has been Created By - " +
          smpCreateState.PreparedBy.label +
          ", For The Part Name - " +
          smpCreateState.PrtNme.label +
          " aginst the Machine " +
          smpCreateState.McNo,
        CreatedBy: localStorage.getItem("empId"),

      });
      if (scrollTop.current) {
        scrollTop.current.scrollIntoView({ behavior: "smooth" });
      }
      // window.location.reload();
    } catch (error) {
      console.error("Error:", error);
      alert(GeneralConstants.SUBMISSION_FAILED);
      window.location.reload();
    }
  };


  const mcNoOptions = dropdown.map((i) => ({
    value: i.mh_mac_name,
    label: i.mh_mac_name,
  }));
  console.log("mcNoOptions", mcNoOptions);
  const partOptions = partDropdown.map((i) => ({
    value: i.mc_part_name,
    label: i.mc_part_name,
  }));

  useEffect(() => {
    if (
      (smpCreateState.McNo.value !== undefined || smpCreateState.McNo.value !== "")
      && (smpCreateState.PrtNme.value !== undefined ||
        smpCreateState.PrtNme.value !== "")
    ) {
      ReadRecord(
        {
          _keyword_: "std_mld_par_duplicate_entry_restriction",
          McNo: smpCreateState.McNo.value,
          PrtNme: smpCreateState.PrtNme.value,
        },
        // setDuplicate
      ).then(res => {
        console.log("std_mld_par_duplicate_entry_restriction", res);
        if (res.rows > 0) {
          alert("Already Exist!")
          setSmpCreateState({
            ...smpCreateState, ...initialState
          });
          return
        }
      })
    }
  }, [smpCreateState.McNo]);

  const handleMachine = (selectedOption) => {
    const selected = dropdown.find(
      (option) => option.mh_mac_name === selectedOption.value
    );

    if (selected) {

      setSmpCreateState({
        ...smpCreateState,
        McNo: {
          value: selected.mh_mac_name,
          label: selected.mh_mac_name,
        },
      })
    }
  };
  const handlePart = async (selectedOption) => {
    let RawMat = await GetRawMaterial(selectedOption.value);
    let PartWeight = await GetPartWeight(selectedOption.value);
    let ModelPart = await GetModelPart(selectedOption.value);
    let Machinen = await GetMachineNo(selectedOption.value);
    console.log("Machinen", Machinen);
    const selected = partDropdown.find(
      (option) => option.mc_part_name === selectedOption.value
    );
    if (selected) {
      setSmpCreateState({
        ...smpCreateState,
        PrtNme: {
          value: selected.mc_part_name,
          label: selected.mc_part_name,
        },
        PartNo: selected.mc_part_code,
        // PartWeight1: PartWeight[0].part_weight_1,
        // PartWeightPlus1: PartWeight[0].part_weight_set_plus_1,
        // PartWeightMinus1: PartWeight[0].part_weight_set_minus_1,
        // PartWeight2: PartWeight[0].part_weight_2,
        // PartWeightPlus2: PartWeight[0].part_weight_set_plus_2,
        // PartWeightMinus2: PartWeight[0].part_weight_set_minus_2,
        Modl: ModelPart[0].mc_part_flex_01,
        NoCav: ModelPart[0].mc_part_flex_02,


        // Colour: ModelPart[0].mc_part_flex_03,
        MatNme: RawMat != undefined ?
          { value: RawMat[0].Mh_raw_mat, label: RawMat[0].Mh_raw_mat_name } : "",
        GrdNme: RawMat != undefined ? RawMat[0].Rm_grade : "",
        Colour: RawMat != undefined ? RawMat[0].Rm_Colour : "",
        McNo: Machinen != undefined ? { value: Machinen[0].mh_mac_name, label: Machinen[0].mh_mac_name } : "",

        // GrdNme: "",
        // McNo: "",
      })
    }
  };

  function GetPartWeight(val) {
    return ReadRecord({
      _keyword_: "Get_part_weight_by_part_from_quality_plan",
      part: val,
    }).then((res) => {
      console.log("respose of part", res);
      if (res.rows > 0) {
        console.log("respose of part", res.data);
        return res.data
        // console.log("Setting State with:", {
        //   PartWeight1: res.data[0].part_weight_1,
        //   PartWeightPlus1: res.data[0].part_weight_set_plus_1,
        //   // ... other fields
        // });
        // setSmpCreateState({
        //   ...smpCreateState,
        // PartWeight1: res.data[0].part_weight_1,
        // PartWeightPlus1: res.data[0].part_weight_set_plus_1,
        // PartWeightMinus1: res.data[0].part_weight_set_minus_1,
        // PartWeight2: res.data[0].part_weight_2,
        // PartWeightPlus2: res.data[0].part_weight_set_plus_2,
        // PartWeightMinus2: res.data[0].part_weight_set_minus_2,
        // });
        // setSmpCreateState({
        //   ...smpCreateState,
        //   Modl: res.data[0].mc_part_flex_01,
        //   NoCav: res.data[0].mc_part_flex_02,
        //   Colour: res.data[0].mc_part_flex_03,
        // });
      }
    });
  }
  function GetModelPart(val) {
    return ReadRecord({
      _keyword_: "Get_model_by_part_name",
      part: val,
    }).then((res) => {
      // console.log("respose of part", res);

      if (res.rows > 0) {
        console.log("model and colour", res.data);
        return res.data;
        // console.log("Setting State with:", {
        //   PartWeight1: res.data[0].part_weight_1,
        //   PartWeightPlus1: res.data[0].part_weight_set_plus_1,
        //   // ... other fields
        // });
        // setSmpCreateState({
        //   ...smpCreateState,
        //   PartWeight1: res.data[0].part_weight_1,
        //   PartWeightPlus1: res.data[0].part_weight_set_plus_1,
        //   PartWeightMinus1: res.data[0].part_weight_set_minus_1,
        //   PartWeight2: res.data[0].part_weight_2,
        //   PartWeightPlus2: res.data[0].part_weight_set_plus_2,
        //   PartWeightMinus2: res.data[0].part_weight_set_minus_2,
        // });
        // setSmpCreateState({
        //   ...smpCreateState,
        //   Modl: res.data[0].mc_part_flex_01,
        //   NoCav: res.data[0].mc_part_flex_02,
        //   Colour: res.data[0].mc_part_flex_03,
        // });
      }
    });
  }
  // function GetRawMaterial(val) {
  //   ReadRecord({
  //     _keyword_: "Get_raw_material_by_part",
  //     part: val,
  //   }).then((res) => {
  //     if (res.rows > 0) {
  //       console.log("respose of raw", res.data);
  //       setRawMaterialDropdown(res.data);
  //     } else {
  //       alert("Contact Administrator to add Raw Material for this Selected Part!");
  //     }
  //   })
  // }
  function GetRawMaterial(val) {
    return ReadRecord({
      _keyword_: "Get_raw_material_by_part",
      part: val,
    }).then((res) => {
      if (res.rows == "1") {
        setRawMaterialDropdown([]);
        return res.data
      } else if (res.rows > "1") {
        console.log("respose of raw", res.data);
        setRawMaterialDropdown(res.data);
      } else {
        alert("Contact Administrator to add Raw Material for this Selected Part!");
      }
    })
  }
  // function GetMachineNo(val) {
  //   return ReadRecord({
  //     _keyword_: "Get_machine_by_part",
  //     part: val,
  //   }).then((res) => {
  //     if (res.rows > 0) {
  //       console.log("machinenen", res.data);
  //       setDropdown(res.data);
  //     } else {
  //       alert("Contact Administrator to add Machine for this Selected Part!");
  //     }
  //   })
  // }
  function GetMachineNo(val) {
    return ReadRecord({
      _keyword_: "Get_machine_by_part_name",
      part: val,
    }).then((res) => {
      if (res.rows == "1") {
        // console.log("machinenen", res.data);
        setDropdown([]);
        return res.data
      } else if (res.rows > "1") {
        setDropdown(res.data);
        // alert("Contact Administrator to add Machine for this Selected Part!");
      }
    })
  }
  const handleRawMaterial = (selectedOption) => {
    // console.log("selectedOption",selectedOption);
    const selected = RawMaterialDropdown.find(
      (option) => option.Mh_raw_mat === selectedOption.value
    );

    if (selected) {
      let grade = selected.Rm_grade;
      let cl = selected.Rm_Colour;
      if (selected.Rm_grade === undefined || selected.Rm_Colour === undefined) {
        grade = "";
        cl = "";
      }
      setSmpCreateState({
        ...smpCreateState,
        MatNme: {
          value: selected.Mh_raw_mat,
          label: selected.Mh_raw_mat_name,
        },
        GrdNme: grade,
        Colour: cl,
      })
    }
  };
  const rawMaterialOptions = RawMaterialDropdown.map((i) => ({
    value: i.Mh_raw_mat,
    label: i.Mh_raw_mat_name,
  }));
  const handlePreparedBy = (selectedOption) => {
    const selected = Employee.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setSmpCreateState({
        ...smpCreateState,
        PreparedBy: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      });
    }
  };
  const handleApprovedBy = (selectedOption) => {
    const selected = Employee.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setSmpCreateState({
        ...smpCreateState,
        ApprovedBy: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      });
    }
  };
  useEffect(() => {
    setSmpCreateState({
      ...smpCreateState,
      TrackDate: new Date().toISOString().split("T")[0],

    });


  }, []);
  useEffect(() => {
    document.title = "SMP"
  }, [])
  function GetSelectValue(value, keyword, setName) {

    if (value.length > 0) {
      ReadRecord({
        _keyword_: keyword,
        key: "%" + value + "%",
        value: "%" + value + "%"
      }).then((res) => {
        console.log(res.data);
        setName(res.data);
        // setKey(keyvalue[options.reference]);
      });
    }
  }
  const keywords = {
    "smp.Cr.Inj101": ["", "smp.Cr.Inj201", "", "smp.Cr.Inj102"],
    "smp.Cr.Inj102": ["", "smp.Cr.Inj202", "smp.Cr.Inj101", "smp.Cr.Inj103"],
    "smp.Cr.Inj103": ["", "smp.Cr.Inj203", "smp.Cr.Inj102", "smp.Cr.Inj104"],
    "smp.Cr.Inj104": ["", "smp.Cr.Inj204", "smp.Cr.Inj103", "smp.Cr.Hozz"],
    "smp.Cr.Inj201": ["smp.Cr.Inj101", "smp.Cr.Inj301", "", "smp.Cr.Inj202"],
    "smp.Cr.Inj202": ["smp.Cr.Inj102", "smp.Cr.Inj302", "smp.Cr.Inj201", "smp.Cr.Inj203"],
    "smp.Cr.Inj203": ["smp.Cr.Inj103", "smp.Cr.Inj303", "smp.Cr.Inj202", "smp.Cr.Inj204"],
    "smp.Cr.Inj204": ["smp.Cr.Inj104", "smp.Cr.Inj304", "smp.Cr.Inj203", "smp.Cr.Zone1"],
    "smp.Cr.Inj301": ["smp.Cr.Inj201", "smp.Cr.Inj401", "", "smp.Cr.Inj302"],
    "smp.Cr.Inj302": ["smp.Cr.Inj202", "smp.Cr.Inj402", "smp.Cr.Inj301", "smp.Cr.Inj303"],
    "smp.Cr.Inj303": ["smp.Cr.Inj203", "smp.Cr.Inj403", "smp.Cr.Inj302", "smp.Cr.Inj304"],
    "smp.Cr.Inj304": ["smp.Cr.Inj204", "smp.Cr.Inj404", "smp.Cr.Inj303", "smp.Cr.Zone2"],
    "smp.Cr.Inj401": ["smp.Cr.Inj301", "", "", "smp.Cr.Inj402"],
    "smp.Cr.Inj402": ["smp.Cr.Inj302", "", "smp.Cr.Inj401", "smp.Cr.Inj403"],
    "smp.Cr.Inj403": ["smp.Cr.Inj303", "", "smp.Cr.Inj402", "smp.Cr.Inj404"],
    "smp.Cr.Inj404": ["smp.Cr.Inj304", "smp.Cr.FillT104", "smp.Cr.Inj403", "smp.Cr.Zone3"],
    "smp.Cr.Switch": ["", "", "", "smp.Cr.Hopp"],
    "smp.Cr.Hopp": ["smp.Cr.Zone6", "", "smp.Cr.Switch", ""],
    "smp.Cr.Zone6": ["smp.Cr.Zone5", "smp.Cr.Hopp", "", ""],
    "smp.Cr.Zone5": ["smp.Cr.Zone4", "smp.Cr.Zone6", "", ""],
    "smp.Cr.Zone4": ["smp.Cr.Zone3", "smp.Cr.Zone5", "smp.Cr.FillT104", "smp.Cr.ManFld5"],
    "smp.Cr.Zone3": ["smp.Cr.Zone2", "smp.Cr.Zone4", "smp.Cr.Inj404", "smp.Cr.ManFld4"],
    "smp.Cr.Zone2": ["smp.Cr.Zone1", "smp.Cr.Zone3", "smp.Cr.Inj304", "smp.Cr.ManFld3"],
    "smp.Cr.Zone1": ["smp.Cr.Hozz", "smp.Cr.Zone2", "smp.Cr.Inj204", "smp.Cr.ManFld2"],
    "smp.Cr.Hozz": ["", "smp.Cr.Zone1", "smp.Cr.Inj104", "smp.Cr.ManFld1"],
    "smp.Cr.FillT104": ["smp.Cr.Inj404", "", "", "smp.Cr.Zone4"],
    "smp.Cr.ManFld1": ["", "smp.Cr.ManFld2", "smp.Cr.Hozz", "smp.Cr.Gate1"],
    "smp.Cr.ManFld2": ["smp.Cr.ManFld1", "smp.Cr.ManFld3", "smp.Cr.Zone1", "smp.Cr.Gate2"],
    "smp.Cr.ManFld3": ["smp.Cr.ManFld2", "smp.Cr.ManFld4", "smp.Cr.Zone2", "smp.Cr.Gate3"],
    "smp.Cr.ManFld4": ["smp.Cr.ManFld3", "smp.Cr.ManFld5", "smp.Cr.Zone3", "smp.Cr.Gate4"],
    "smp.Cr.ManFld5": ["smp.Cr.ManFld4", "", "smp.Cr.Zone4", "smp.Cr.Gate5"],
    "smp.Cr.Gate6": ["", "smp.Cr.Gte2", "", "smp.Cr.Gate7"],
    "smp.Cr.Gate7": ["", "smp.Cr.Gate8", "smp.Cr.Gate6", ""],
    "smp.Cr.Gate1": ["", "smp.Cr.Gate2", "smp.Cr.ManFld1", "smp.Cr.Gte2"],
    "smp.Cr.Gte2": ["smp.Cr.Gte3", "smp.Cr.Gte3", "smp.Cr.Gate1", "smp.Cr.Gate8"],
    "smp.Cr.Gate8": ["smp.Cr.Gate7", "smp.Cr.Gate9", "smp.Cr.Gte2", ""],
    "smp.Cr.Gate2": ["smp.Cr.Gate1", "smp.Cr.Gate3", "smp.Cr.ManFld2", "smp.Cr.Gte3"],
    "smp.Cr.Gate3": ["smp.Cr.Gate2", "smp.Cr.Gate4", "smp.Cr.ManFld3", "smp.Cr.Gte4"],
    "smp.Cr.Gate4": ["smp.Cr.Gate3", "smp.Cr.Gate5", "smp.Cr.ManFld4", "smp.Cr.Gte5"],
    "smp.Cr.Gate5": ["smp.Cr.Gate4", "", "smp.Cr.ManFld5", "smp.Cr.Gte6"],
    "smp.Cr.Gte3": ["smp.Cr.Gte2", "smp.Cr.Gte4", "smp.Cr.Gate2", "smp.Cr.Gate9"],
    "smp.Cr.Gte4": ["smp.Cr.Gte3", "smp.Cr.Gte5", "smp.Cr.Gate3", "smp.Cr.Gate10"],
    "smp.Cr.Gte5": ["smp.Cr.Gte4", "smp.Cr.Gte6", "smp.Cr.Gate4", "smp.Cr.Gate11"],
    "smp.Cr.Gte6": ["smp.Cr.Gte5", "", "smp.Cr.Gate5", "smp.Cr.Gate12"],
    "smp.Cr.Gate9": ["smp.Cr.Gate8", "smp.Cr.Gate10", "smp.Cr.Gte3", ""],
    "smp.Cr.Gate10": ["smp.Cr.Gate9", "smp.Cr.Gate11", "smp.Cr.Gte4", ""],
    "smp.Cr.Gate11": ["smp.Cr.Gate10", "smp.Cr.Gate12", "smp.Cr.Gte5", ""],
    "smp.Cr.Gate12": ["smp.Cr.Gate11", "", "smp.Cr.Gte6", ""],
    "smp.Cr.Hold101": ["", "smp.Cr.Hold102", "", "smp.Cr.Hold201"],
    "smp.Cr.Hold102": ["smp.Cr.Hold101", "smp.Cr.Hold103", "", "smp.Cr.Hold202"],
    "smp.Cr.Hold103": ["smp.Cr.Hold102", "smp.Cr.Hold104", "", "smp.Cr.Hold203"],
    "smp.Cr.Hold104": ["smp.Cr.Hold103", "", "", "smp.Cr.Hold204"],
    "smp.Cr.Hold201": ["", "smp.Cr.Hold202", "smp.Cr.Hold101", "smp.Cr.Hold301"],
    "smp.Cr.Hold301": ["", "smp.Cr.Hold302", "smp.Cr.Hold201", "smp.Cr.Hold401"],
    "smp.Cr.Hold401": ["", "smp.Cr.Hold402", "smp.Cr.Hold301", "smp.Cr.Core"],
    "smp.Cr.Hold202": ["smp.Cr.Hold201", "smp.Cr.Hold203", "smp.Cr.Hold102", "smp.Cr.Hold302"],
    "smp.Cr.Hold302": ["smp.Cr.Hold301", "smp.Cr.Hold303", "smp.Cr.Hold202", "smp.Cr.Hold402"],
    "smp.Cr.Hold402": ["smp.Cr.Hold401", "smp.Cr.Hold403", "smp.Cr.Hold302", "smp.Cr.Cavity"],
    "smp.Cr.Hold203": ["smp.Cr.Hold202", "smp.Cr.Hold204", "smp.Cr.Hold103", "smp.Cr.Hold303"],
    "smp.Cr.Hold303": ["smp.Cr.Hold302", "smp.Cr.Hold304", "smp.Cr.Hold203", "smp.Cr.Hold403"],
    "smp.Cr.Hold403": ["smp.Cr.Hold402", "smp.Cr.Hold404", "smp.Cr.Hold303", "smp.Cr.Fwd"],
    "smp.Cr.Hold204": ["smp.Cr.Hold203", "", "smp.Cr.Hold104", "smp.Cr.Hold304"],
    "smp.Cr.Hold304": ["smp.Cr.Hold303", "", "smp.Cr.Hold204", "smp.Cr.Hold404"],
    "smp.Cr.Hold404": ["smp.Cr.Hold403", "", "smp.Cr.Hold304", "smp.Cr.RetSpd"],
    "smp.Cr.Fwd": ["", "smp.Cr.RetSpd", "smp.Cr.Hold403", "smp.Cr.Dely"],
    "smp.Cr.RetSpd": ["smp.Cr.Fwd", "smp.Cr.SprBushHle", "smp.Cr.Hold404", "smp.Cr.OnTme"],
    "smp.Cr.Dely": ["", "smp.Cr.OnTme", "smp.Cr.Fwd", "smp.Cr.MTC"],
    "smp.Cr.OnTme": ["smp.Cr.Dely", "smp.Cr.SprBushHle", "smp.Cr.RetSpd", ""],
    "smp.Cr.SprBushHle": ["smp.Cr.OnTme", "", "", "smp.Cr.NozzHole"],
    "smp.Cr.NozzHole": ["", "", "smp.Cr.SprBushHle", ""],
    "smp.Cr.Core": ["", "smp.Cr.Cavity", "smp.Cr.Hold401", ""],
    "smp.Cr.Cavity": ["smp.Cr.Core", "smp.Cr.MTC", "smp.Cr.Hold402", ""],
    "smp.Cr.MTC": ["smp.Cr.Cavity", "", "smp.Cr.Dely", ""],
    "smp.Cr.ReFill101": ["", "smp.Cr.ReFill201", "", "smp.Cr.ReFill102"],
    "smp.Cr.ReFill102": ["", "smp.Cr.ReFill202", "smp.Cr.ReFill101", "smp.Cr.ReFill103"],
    "smp.Cr.ReFill103": ["", "smp.Cr.ReFill203", "smp.Cr.ReFill102", "smp.Cr.ReFill104"],
    "smp.Cr.ReFill104": ["", "smp.Cr.ReFill204", "smp.Cr.ReFill103", "smp.Cr.Close101"],
    "smp.Cr.ReFill201": ["smp.Cr.ReFill101", "smp.Cr.ReFill301", "", "smp.Cr.ReFill202"],
    "smp.Cr.ReFill202": ["smp.Cr.ReFill102", "smp.Cr.ReFill302", "smp.Cr.ReFill201", "smp.Cr.ReFill203"],
    "smp.Cr.ReFill203": ["smp.Cr.ReFill103", "smp.Cr.ReFill303", "smp.Cr.ReFill202", "smp.Cr.ReFill204"],
    "smp.Cr.ReFill204": ["smp.Cr.ReFill104", "smp.Cr.ReFill304", "smp.Cr.ReFill203", "smp.Cr.Close201"],
    "smp.Cr.ReFill301": ["smp.Cr.ReFill201", "", "", "smp.Cr.ReFill302"],
    "smp.Cr.ReFill302": ["smp.Cr.ReFill202", "", "smp.Cr.ReFill301", "smp.Cr.ReFill303"],
    "smp.Cr.ReFill303": ["smp.Cr.ReFill203", "smp.Cr.RefillEnd", "smp.Cr.ReFill302", "smp.Cr.ReFill304"],
    "smp.Cr.ReFill304": ["smp.Cr.ReFill204", "", "smp.Cr.ReFill303", "smp.Cr.Close301"],
    "smp.Cr.Close101": ["", "smp.Cr.Close201", "smp.Cr.ReFill104", "smp.Cr.Close102"],
    "smp.Cr.Close102": ["", "smp.Cr.Close202", "smp.Cr.Close101", "smp.Cr.Close103"],
    "smp.Cr.Close103": ["", "smp.Cr.Close203", "smp.Cr.Close102", "smp.Cr.EjecFrd101"],
    "smp.Cr.Close201": ["smp.Cr.Close101", "smp.Cr.Close301", "smp.Cr.ReFill204", "smp.Cr.Close202"],
    "smp.Cr.Close202": ["smp.Cr.Close102", "smp.Cr.Close302", "smp.Cr.Close201", "smp.Cr.Close203"],
    "smp.Cr.Close203": ["smp.Cr.Close103", "smp.Cr.Close303", "smp.Cr.Close202", "smp.Cr.EjecFrd201"],
    "smp.Cr.Close301": ["smp.Cr.Close201", "smp.Cr.MldSfy101", "smp.Cr.ReFill304", "smp.Cr.Close302"],
    "smp.Cr.Close302": ["smp.Cr.Close202", "smp.Cr.MldSfy201", "smp.Cr.Close301", "smp.Cr.Close303"],
    "smp.Cr.Close303": ["smp.Cr.Close203", "smp.Cr.MldSfy301", "smp.Cr.Close302", "smp.Cr.EjecRet101"],
    "smp.Cr.RefillEnd": ["smp.Cr.ReFill303", "smp.Cr.SuckBack", "", "smp.Cr.MldSfy101"],
    "smp.Cr.MldSfy101": ["smp.Cr.Close301", "smp.Cr.MldSfy102", "smp.Cr.RefillEnd", "smp.Cr.MldSfy201"],
    "smp.Cr.MldSfy201": ["smp.Cr.Close302", "smp.Cr.MldSfy202", "smp.Cr.MldSfy101", "smp.Cr.MldSfy301"],
    "smp.Cr.MldSfy301": ["smp.Cr.Close303", "smp.Cr.MldSfy302", "smp.Cr.MldSfy201", "smp.Cr.EjecRet201"],
    "smp.Cr.MldSfy102": ["smp.Cr.MldSfy101", "smp.Cr.MoldSafeT", "smp.Cr.SuckBack", "smp.Cr.MldSfy202"],
    "smp.Cr.MldSfy202": ["smp.Cr.MldSfy201", "", "smp.Cr.MldSfy102", "smp.Cr.MldSfy302"],
    "smp.Cr.MldSfy302": ["smp.Cr.MldSfy301", "smp.Cr.Actual1", "smp.Cr.MldSfy202", ""],
    "smp.Cr.SukSet": ["", "smp.Cr.Bp101", "", "smp.Cr.SuckBack"],
    "smp.Cr.SuckBack": ["smp.Cr.RefillEnd", "smp.Cr.Bp103", "smp.Cr.SukSet", "smp.Cr.MldSfy102"],
    "smp.Cr.EjecFrd101": ["", "smp.Cr.EjecFrd201", "smp.Cr.Close103", "smp.Cr.EjecFrd102"],
    "smp.Cr.EjecFrd102": ["", "smp.Cr.EjecFrd202", "smp.Cr.EjecFrd101", "smp.Cr.EjecFrd103"],
    "smp.Cr.EjecFrd103": ["", "smp.Cr.EjecFrd203", "smp.Cr.EjecFrd102", "smp.Cr.EjecFrd104"],
    "smp.Cr.EjecFrd104": ["", "smp.Cr.EjecFrd204", "smp.Cr.EjecFrd103", ""],
    "smp.Cr.EjecFrd201": ["smp.Cr.EjecFrd101", "smp.Cr.EjecRet101", "smp.Cr.Close203", "smp.Cr.EjecFrd202"],
    "smp.Cr.EjecFrd202": ["smp.Cr.EjecFrd102", "smp.Cr.EjecRet102", "smp.Cr.EjecFrd201", "smp.Cr.EjecFrd203"],
    "smp.Cr.EjecFrd203": ["smp.Cr.EjecFrd103", "smp.Cr.EjecRet103", "smp.Cr.EjecFrd202", "smp.Cr.EjecFrd204"],
    "smp.Cr.EjecFrd204": ["smp.Cr.EjecFrd104", "smp.Cr.EjecRet104", "smp.Cr.EjecFrd203", ""],
    "smp.Cr.EjecRet101": ["smp.Cr.EjecFrd201", "smp.Cr.EjecRet201", "smp.Cr.Close303", "smp.Cr.EjecRet102"],
    "smp.Cr.EjecRet102": ["smp.Cr.EjecFrd202", "smp.Cr.EjecRet202", "smp.Cr.EjecRet101", "smp.Cr.EjecRet103"],
    "smp.Cr.EjecRet103": ["smp.Cr.EjecFrd203", "smp.Cr.EjecRet203", "smp.Cr.EjecRet102", "smp.Cr.EjecRet104"],
    "smp.Cr.EjecRet104": ["smp.Cr.EjecFrd204", "smp.Cr.EjecRet204", "smp.Cr.EjecRet103", ""],
    "smp.Cr.EjecRet201": ["smp.Cr.EjecRet101", "", "smp.Cr.MldSfy301", "smp.Cr.EjecRet202"],
    "smp.Cr.EjecRet202": ["smp.Cr.EjecRet102", "", "smp.Cr.EjecRet201", "smp.Cr.EjecRet203"],
    "smp.Cr.EjecRet203": ["smp.Cr.EjecRet103", "", "smp.Cr.EjecRet202", "smp.Cr.EjecRet204"],
    "smp.Cr.EjecRet204": ["smp.Cr.EjecRet104", "", "smp.Cr.EjecRet203", ""],
    "smp.Cr.Bp101": ["smp.Cr.SukSet", "smp.Cr.Bp201", "", "smp.Cr.Bp102"],
    "smp.Cr.Bp102": ["smp.Cr.SukSet", "smp.Cr.Bp202", "smp.Cr.Bp101", "smp.Cr.Bp103"],
    "smp.Cr.Bp103": ["smp.Cr.SuckBack", "smp.Cr.Bp203", "smp.Cr.Bp102", "smp.Cr.Bp104"],
    "smp.Cr.Bp104": ["smp.Cr.SuckBack", "smp.Cr.Bp204", "smp.Cr.Bp103", "smp.Cr.MoldSafeT"],
    "smp.Cr.Bp201": ["smp.Cr.Bp101", "smp.Cr.Melt101", "", "smp.Cr.Bp102"],
    "smp.Cr.Bp202": ["smp.Cr.Bp102", "smp.Cr.Melt102", "smp.Cr.Bp201", "smp.Cr.Bp203"],
    "smp.Cr.Bp203": ["smp.Cr.Bp103", "smp.Cr.Melt103", "smp.Cr.Bp202", "smp.Cr.Bp204"],
    "smp.Cr.Bp204": ["smp.Cr.Bp104", "smp.Cr.Melt104", "smp.Cr.Bp203", "smp.Cr.Cre1"],
    "smp.Cr.MoldSafeT": ["smp.Cr.MldSfy102", "", "smp.Cr.Bp104", "smp.Cr.Actual1"],
    "smp.Cr.Actual1": ["smp.Cr.MldSfy302", "smp.Cr.Actual1", "smp.Cr.MoldSafeT", "smp.Cr.Actual1"],
    "smp.Cr.Cre1": ["smp.Cr.Actual1", "smp.Cr.Cre2", "smp.Cr.Bp204", "smp.Cr.Core101"],
    "smp.Cr.Core101": ["", "smp.Cr.Core201", "smp.Cr.Cre1", "smp.Cr.Core102"],
    "smp.Cr.Core102": ["", "smp.Cr.Core202", "smp.Cr.Core101", "smp.Cr.Core103"],
    "smp.Cr.Core103": ["", "smp.Cr.Core203", "smp.Cr.Core102", ""],
    "smp.Cr.Cre2": ["smp.Cr.Cre1", "smp.Cr.Cre3", "smp.Cr.Melt104", "smp.Cr.Core201"],
    "smp.Cr.Core201": ["smp.Cr.Core101", "smp.Cr.Core201", "smp.Cr.Cre2", "smp.Cr.Core202"],
    "smp.Cr.Core202": ["smp.Cr.Core102", "smp.Cr.Core202", "smp.Cr.Core201", "smp.Cr.Core203"],
    "smp.Cr.Core203": ["smp.Cr.Core103", "smp.Cr.Core203", "smp.Cr.Core202", ""],
    "smp.Cr.Cre3": ["smp.Cr.Cre2", "smp.Cr.Cre4", "smp.Cr.Open203", "smp.Cr.Core301"],
    "smp.Cr.Core301": ["smp.Cr.Core201", "smp.Cr.Core401", "smp.Cr.Cre3", "smp.Cr.Core302"],
    "smp.Cr.Core302": ["smp.Cr.Core202", "smp.Cr.Core402", "smp.Cr.Core301", "smp.Cr.Core303"],
    "smp.Cr.Core303": ["smp.Cr.Core203", "smp.Cr.Core403", "smp.Cr.Core302", ""],
    "smp.Cr.Cre4": ["smp.Cr.Cre3", "", "smp.Cr.Open303", "smp.Cr.Core401"],
    "smp.Cr.Core401": ["smp.Cr.Core301", "", "smp.Cr.Cre4", "smp.Cr.Core402"],
    "smp.Cr.Core402": ["smp.Cr.Core302", "", "smp.Cr.Core401", "smp.Cr.Core403"],
    "smp.Cr.Core403": ["smp.Cr.Core303", "", "smp.Cr.Core402", ""],
    "smp.Cr.Melt101": ["smp.Cr.Bp201", "smp.Cr.Cooltme", "", "smp.Cr.Melt102"],
    "smp.Cr.Melt102": ["smp.Cr.Bp202", "smp.Cr.Cooltme", "smp.Cr.Melt101", "smp.Cr.Melt103"],
    "smp.Cr.Melt103": ["smp.Cr.Bp203", "smp.Cr.Cooltme", "smp.Cr.Melt102", "smp.Cr.Melt104"],
    "smp.Cr.Melt104": ["smp.Cr.Bp204", "smp.Cr.Cooltme", "smp.Cr.Melt103", "smp.Cr.Cre2"],
    "smp.Cr.Cooltme": ["smp.Cr.Melt104", "smp.Cr.Cycletme", "", "smp.Cr.Open201"],
    "smp.Cr.Cycletme": ["smp.Cr.Cooltme", "smp.Cr.Oilt", "", "smp.Cr.Open301"],
    "smp.Cr.Oilt": ["smp.Cr.Cycletme", "", "", "smp.Cr.Open401"],
    "smp.Cr.Open201": ["", "smp.Cr.Open301", "smp.Cr.Cooltme", "smp.Cr.Open202"],
    "smp.Cr.Open202": ["", "smp.Cr.Open302", "smp.Cr.Open201", "smp.Cr.Open203"],
    "smp.Cr.Open203": ["", "smp.Cr.Open303", "smp.Cr.Open202", "smp.Cr.Cre3"],
    "smp.Cr.Open301": ["smp.Cr.Open201", "smp.Cr.Open401", "smp.Cr.Cycletme", "smp.Cr.Open302"],
    "smp.Cr.Open302": ["smp.Cr.Open202", "smp.Cr.Open402", "smp.Cr.Open301", "smp.Cr.Open303"],
    "smp.Cr.Open303": ["smp.Cr.Open203", "smp.Cr.Open403", "smp.Cr.Open302", "smp.Cr.Cre4"],
    "smp.Cr.Open401": ["smp.Cr.Open301", "", "smp.Cr.Oilt", "smp.Cr.Open402"],
    "smp.Cr.Open402": ["smp.Cr.Open302", "", "smp.Cr.Open401", "smp.Cr.Open403"],
    "smp.Cr.Open403": ["smp.Cr.Open303", "", "smp.Cr.Open402", "smp.Cr.Cre5"],

  }

  function FindKeyPress(key, arrow) {
    if (arrow === 'ArrowUp') {
      const nextInput = document.getElementById([keywords[key][0]]);
      if (nextInput) {
        nextInput.focus();
      }
    } else if (arrow === 'ArrowDown') {
      const nextInput = document.getElementById([keywords[key][1]]);
      if (nextInput) {
        nextInput.focus();
      }
    } else if (arrow === 'ArrowLeft') {
      const nextInput = document.getElementById([keywords[key][2]]);
      if (nextInput) {
        nextInput.focus();
      }
    } else if (arrow === 'ArrowRight') {
      const nextInput = document.getElementById([keywords[key][3]]);
      if (nextInput) {
        nextInput.focus();
      }
    }
  }

  return (
    <div >
      <form
        id="smp.Create.formContainer"
        name="smp.Create.formContainer"
        className="form-smp-Create"
        ref={scrollTop}
      >
        <table width="100%" style={{ textAlign: "center" }} border="0">
          <thead>
            <th

              colSpan="6"
              style={{
                textAlign: "center",
                whiteSpace: "nowrap",
                padding: "20px 0px",
              }}
              id="smp.Create.Title"
              name="smp.Create.Title"

            >
              <font color="#8B0000">STANDARD MOULDING PARAMETER</font>
            </th>
          </thead>
          <tr>
            <td style={{ width: "15%" }}>Format No</td>
            <td style={{ width: "23.8%" }}>
              <label>UPCL/PRO/D/01</label>
            </td>

            <td style={{ width: "11%" }}>Rev No</td>
            <td style={{ width: "26.6%" }}>
              <label>01</label>
            </td>
            <td style={{ width: "11%" }}>Date</td>
            <td>
              <input
                disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                id="smp.Cr.date"
                name="smp.Cr.date"
                readOnly={true}
                type="date"
                value={smpCreateState.TrackDate}
                onChange={handleDateChange}
                min={today}
                max={`${nextYear}-12-31`}
              />
            </td>
          </tr>
          <thead>
            <th style={{ textAlign: "center" }} colSpan="10"></th>
          </thead>
          <tr>
            <td>
              Part Name <span style={{ color: "red" }}>*</span>
            </td>
            <td>
              {smpCreateState.PrtNme === "" && isCreating && (
                <span className="Req">Required *</span>
              )}
              {/* <DropdownFetcher
                keyword="mac_part_dtl_with_model_dropdown"
                setValue={setPartDropdown}
              /> */}
              {/* <DropdownSelect
                text="Select Part Name"
                dropdown={partDropdown}
                value={smpCreateState.PrtNme}
                optionIdProp="Part_name"
                optionNameProp="Part_name"
                onChange={(e) =>
                  setSmpCreateState({
                    ...smpCreateState,
                    PrtNme: e.target.value,
                  })
                }
              /> */}
              <Select
                id="smp.Cr.Part"
                name="smp.Cr.Part"
                value={smpCreateState.PrtNme}
                onChange={handlePart}
                options={partOptions}
                onInputChange={(event) => { GetSelectValue(event, "Get_part_name_from_head", setPartDropdown) }}
                placeholder="Search Part..."
              // isDisabled={smpCreateState.PrtNme === ""}
              />

            </td>
            <td>
              Raw Material Name <span style={{ color: "red" }}>*</span>
            </td>
            <td>
              {smpCreateState.MatNme === "" && isCreating && (
                <span className="Req">Required *</span>
              )}
              {/* <input
                disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === ""}
                id="smp.Cr.MatNme"
                name="smp.Cr.MatNme"
                readOnly={smpCreateState.viewMode}
                type="text"
                required={true}
                value={smpCreateState.MatNme}
                onChange={(e) =>
                  setSmpCreateState({
                    ...smpCreateState,
                    MatNme: e.target.value,
                  })
                }
                
              /> */}
              {/* <DropdownFetcher keyword="rm_details_dropdown_grade" setValue={setRawMaterialDropdown} /> */}

              <Select
                id="smp.Cr.rawMaterial"
                name="smp.Cr.rawMaterial"
                value={smpCreateState.MatNme}
                onChange={handleRawMaterial}
                options={rawMaterialOptions}
                // onInputChange={(event) => { GetSelectValue(event, "rm_details_dropdown_grade", setRawMaterialDropdown) }}
                placeholder="Search..."
                required={true}
                isDisabled={smpCreateState.PrtNme === ""}

              />

            </td>
            <td>
              No Of Cavity <span style={{ color: "red" }}>*</span>
            </td>
            <td>
              {smpCreateState.NoCav === "" && isCreating && (
                <span className="Req">Required *</span>
              )}
              <input
                // disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === ""}
                disabled
                id="smp.Cr.NoCav"
                name="smp.Cr.NoCav"
                readOnly={smpCreateState.viewMode}
                type="text"
                value={smpCreateState.NoCav}
                onChange={(e) =>
                  setSmpCreateState({
                    ...smpCreateState,
                    NoCav: e.target.value,
                  })
                }
              />
            </td>
          </tr>
          <tr>

            <td>
              Machine <span style={{ color: "red" }}>*</span>
            </td>
            <td>
              {smpCreateState.McNo === "" && isCreating && (
                <span className="Req">Required *</span>
              )}
              <DropdownFetcher keyword="mac_dtl_dropdown" setValue={setDropdown} />
              {/* <DropdownSelect
                text="Select Machine Name"
                dropdown={dropdown}
                value={smpCreateState.McNo}
                optionIdProp="mh_mac_name"
                optionNameProp="mh_mac_name"
                onChange={(e) =>
                  setSmpCreateState({ ...smpCreateState, McNo: e.target.value })
                }
              /> */}

              <Select
                id="smp.Cr.Machine"
                name="smp.Cr.Machine"
                value={smpCreateState.McNo}
                onChange={handleMachine}
                options={mcNoOptions}
                isDisabled={smpCreateState.PrtNme === ""}
                placeholder="Select Machine No"

              />

            </td>
            <td>
              Grade Name <span style={{ color: "red" }}>*</span>
            </td>
            <td>
              {smpCreateState.GrdNme === "" && isCreating && (
                <span className="Req">Required *</span>
              )}
              <input
                // disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === ""}
                disabled
                id="smp.Cr.GrdNme"
                name="smp.Cr.GrdNme"
                readOnly={smpCreateState.viewMode}
                type="text"
                value={smpCreateState.GrdNme}
                onChange={(e) =>
                  setSmpCreateState({
                    ...smpCreateState,
                    GrdNme: e.target.value,
                  })
                }
              />
            </td>

            <td>
              Colour <span style={{ color: "red" }}>*</span>
            </td>
            <td>
              {smpCreateState.Colour === "" && isCreating && (
                <span className="Req">Required *</span>
              )}
              <input
                // disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === ""}
                disabled
                id="smp.Cr.Colour"
                name="smp.Cr.Colour"
                readOnly={smpCreateState.viewMode}
                type="text"
                value={smpCreateState.Colour}
                onChange={(e) =>
                  setSmpCreateState({
                    ...smpCreateState,
                    Colour: e.target.value,
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>
              {/* <span style={{ color: "red" }}>*</span> */}
              <span style={{ whiteSpace: "nowrap" }}>PW set 1:<br></br>
              <span style={{ fontWeight: "bold",display:'flex' }}>(-{<input
                disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === ""}
                id="smp.Cr.PrtSetplus1"
                name="smp.Cr.PrtSetplus1"
                style={{width:'40%'}}
                type="number"
                value={smpCreateState.PrtSetplus1}
                onChange={(e) =>
                  setSmpCreateState({
                    ...smpCreateState,
                    PrtSetplus1: e.target.value,
                  })
                }

              />}/+{<input
                disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === ""}
                id="smp.Cr.PrtSetMinus1"
                name="smp.Cr.PrtSetMinus1"
                style={{width:'40%'}}
                type="number"
                value={smpCreateState.PrtSetMinus1}
                onChange={(e) =>
                  setSmpCreateState({
                    ...smpCreateState,
                    PrtSetMinus1: e.target.value,
                  })
                }

              />})</span></span>
              <span style={{ whiteSpace: "nowrap" }}>PW set 2:
              <br></br><span style={{ fontWeight: "bold",display:'flex' }}>(-{<input
                disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === ""}
                id="smp.Cr.PrtSetPlus2"
                name="smp.Cr.PrtSetPlus2"
                style={{width:'40%'}}
                type="number"
                value={smpCreateState.PrtSetPlus2}
                onChange={(e) =>
                  setSmpCreateState({
                    ...smpCreateState,
                    PrtSetPlus2: e.target.value,
                  })
                }

              />}/+{<input
                disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === ""}
                id="smp.Cr.PrtSetMinus2"
                name="smp.Cr.PrtSetMinus2"
                style={{width:'40%'}}
                type="number"
                value={smpCreateState.PrtSetMinus2}
                onChange={(e) =>
                  setSmpCreateState({
                    ...smpCreateState,
                    PrtSetMinus2: e.target.value,
                  })
                }
              />})</span></span>

              {/* {smpCreateState.PrtSet === "" && isCreating && (
                <span className="Req">Required *</span>
              )}
              <input
                disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === ""}
                id="smp.Cr.PrtSet"
                name="smp.Cr.PrtSet"
                
                type="number"
                value={smpCreateState.PrtSet}
                onChange={(e) =>
                  setSmpCreateState({
                    ...smpCreateState,
                    PrtSet: e.target.value,
                  })
                }
                
              /> */}
            </td>
            <td>
              <span style={{ marginRight: "5px", float: "left", display: 'flex' }}>
                <span>PW1*:</span> <span style={{ fontWeight: "bold", padding: '5px' }}>{
                  <input
                    disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === ""}
                    id="smp.Cr.PrtWgt1"
                    name="smp.Cr.PrtWgt1"
                    type="number"
                    value={smpCreateState.PrtWgt1}
                    onChange={(e) =>
                      setSmpCreateState({
                        ...smpCreateState,
                        PrtWgt1: e.target.value,
                      })
                    }
                  />}</span></span><br></br>
              <span style={{ marginRight: "5px", float: "left", display: 'flex' }}>
                <span>PW2*:</span> <span style={{ fontWeight: "bold", padding: '5px' }}>{
                  <input
                    disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === ""}
                    id="smp.Cr.PrtWgt2"
                    name="smp.Cr.PrtWgt2"
                    type="number"
                    value={smpCreateState.PrtWgt2}
                    onChange={(e) =>
                      setSmpCreateState({
                        ...smpCreateState,
                        PrtWgt2: e.target.value,
                      })
                    }
                  />}
                </span>
              </span>


              {/* {smpCreateState.PrtWgt === "" && isCreating && (
                <span className="Req">Required *</span>
              )}
              <input
                disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === ""}
                id="smp.Cr.PrtWgt"
                name="smp.Cr.PrtWgt"
                readOnly={smpCreateState.viewMode}
                type="number"
                value={smpCreateState.PrtWgt}
                onChange={(e) =>
                  setSmpCreateState({
                    ...smpCreateState,
                    PrtWgt: e.target.value,
                  })
                }
              /> */}
            </td>
            {/* <td>
              <span style={{ marginRight: "5px", float: "left" }}>
                PW1*: <span style={{ fontWeight: "bold" }}>{smpCreateState.PartWeight1}</span><br></br>
                PW2*: <span style={{ fontWeight: "bold" }}>{smpCreateState.PartWeight2}</span>
              </span>              
            </td>
            <td>
              <span style={{ whiteSpace: "nowrap" }}>Part Weight 1:<span style={{ fontWeight: "bold" }}>(-{smpCreateState.PartWeightPlus1}/+{smpCreateState.PartWeightMinus1})</span></span><br></br>
              <span style={{ whiteSpace: "nowrap" }}>Part Weight 2:<span style={{ fontWeight: "bold" }}>(-{smpCreateState.PartWeightPlus2}/+{smpCreateState.PartWeightMinus2})</span></span>
            </td> */}
            <td>
              Runner WT <span style={{ color: "red" }}>*</span>
            </td>
            <td>
              {smpCreateState.Runn === "" && isCreating && (
                <span className="Req">Required *</span>
              )}
              <input
                disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === ""}
                id="smp.Cr.Runn"
                name="smp.Cr.Runn"
                readOnly={smpCreateState.viewMode}
                type="text"
                value={smpCreateState.Runn}
                onChange={(e) =>
                  setSmpCreateState({ ...smpCreateState, Runn: e.target.value })
                }
              />
            </td>
            <td>
              Model <span style={{ color: "red" }}>*</span>
            </td>
            <td>
              {smpCreateState.Modl === "" && isCreating && (
                <span className="Req">Required *</span>
              )}
              <input
                // disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === ""}
                disabled
                id="smp.Cr.Modl"
                name="smp.Cr.Modl"
                readOnly={smpCreateState.viewMode}
                type="text"
                value={smpCreateState.Modl}
                onChange={(e) =>
                  setSmpCreateState({ ...smpCreateState, Modl: e.target.value })
                }
              />
            </td>
          </tr>
        </table>
        <table width="100%" style={{ textAlign: "center" }} border="0">
          <thead>
            <th
              id="smp.Cr.InjSet.Title"
              name="smp.Cr.InjSet.Title"
              style={{ textAlign: "center" }}
              colSpan="5"
            >
              INJECTION SETTING
            </th>

            <th style={{ textAlign: "center" }} colSpan="2">
              TEMPERATURE
            </th>

            <th style={{ textAlign: "center" }} colSpan="4">
              HOT RUNNER SYSTEM TEMPERATURE
            </th>

            <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
              SEQEMACE GATE
            </th>
            <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
              <tr>
                <th>
                  ON{" "}
                  <select
                    disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                    id="smp.Cr.OnSet.dropdown"
                    name="smp.Cr.OnSet.dropdown"
                    value={smpCreateState.OnSet}
                    onChange={(e) =>
                      setSmpCreateState({
                        ...smpCreateState,
                        OnSet: e.target.value,
                      })
                    }
                    style={{ backgroundColor: "#efd9b1" }}
                  >
                    <option
                      id="smp.Cr.Onset.sct"
                      name="smp.Cr.Onset.sct"
                      value=""
                    >
                      Select
                    </option>
                    <option id="smp.Cr.Onset.1" name="smp.Cr.Onset.1" value="1">
                      ±1
                    </option>
                    <option id="smp.Cr.Onset.2" name="smp.Cr.Onset.2" value="2">
                      ±2
                    </option>
                    <option id="smp.Cr.Onset.3" name="smp.Cr.Onset.3" value="3">
                      ±3
                    </option>
                    <option id="smp.Cr.Onset.4" name="smp.Cr.Onset.4" value="4">
                      ±4
                    </option>
                    <option id="smp.Cr.Onset.5" name="smp.Cr.Onset.5" value="5">
                      ±5
                    </option>
                    <option id="smp.Cr.Onset.6" name="smp.Cr.Onset.6" value="6">
                      ±6
                    </option>
                    <option id="smp.Cr.Onset.7" name="smp.Cr.Onset.7" value="7">
                      ±7
                    </option>
                    <option id="smp.Cr.Onset.8" name="smp.Cr.Onset.8" value="8">
                      ±8
                    </option>
                    <option id="smp.Cr.Onset.9" name="smp.Cr.Onset.9" value="9">
                      ±9
                    </option>
                    <option
                      id="smp.Cr.Onset.10"
                      name="smp.Cr.Onset.10"
                      value="10"
                    >
                      ±10
                    </option>
                    <option
                      id="smp.Cr.Onset.11"
                      name="smp.Cr.Onset.11"
                      value="11"
                    >
                      ±11
                    </option>
                    <option
                      id="smp.Cr.Onset.12"
                      name="smp.Cr.Onset.12"
                      value="12"
                    >
                      ±12
                    </option>
                    <option
                      id="smp.Cr.Onset.13"
                      name="smp.Cr.Onset.13"
                      value="13"
                    >
                      ±13
                    </option>
                    <option
                      id="smp.Cr.Onset.14"
                      name="smp.Cr.Onset.14"
                      value="14"
                    >
                      ±14
                    </option>
                    <option
                      id="smp.Cr.Onset.15"
                      name="smp.Cr.Onset.15"
                      value="15"
                    >
                      ±15
                    </option>
                  </select>
                </th>
              </tr>
            </th>
            <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
              <tr>
                <th>
                  OFF{" "}
                  <select
                    disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                    id="smp.Cr.OffSet.dropdown"
                    name="smp.Cr.OffSet.dropdown"
                    value={smpCreateState.OffSet}
                    onChange={(e) =>
                      setSmpCreateState({
                        ...smpCreateState,
                        OffSet: e.target.value,
                      })
                    }
                    style={{ backgroundColor: "#efd9b1" }}
                  >
                    <option
                      id="smp.Cr.OffSet.sct"
                      name="smp.Cr.OffSet.sct"
                      value=""
                    >
                      Select
                    </option>
                    <option
                      id="smp.Cr.OffSet.1"
                      name="smp.Cr.OffSet.1"
                      value="1"
                    >
                      ±1
                    </option>
                    <option
                      id="smp.Cr.OffSet.2"
                      name="smp.Cr.OffSet.2"
                      value="2"
                    >
                      ±2
                    </option>
                    <option
                      id="smp.Cr.OffSet.3"
                      name="smp.Cr.OffSet.3"
                      value="3"
                    >
                      ±3
                    </option>
                    <option
                      id="smp.Cr.OffSet.4"
                      name="smp.Cr.OffSet.4"
                      value="4"
                    >
                      ±4
                    </option>
                    <option
                      id="smp.Cr.OffSet.5"
                      name="smp.Cr.OffSet.5"
                      value="5"
                    >
                      ±5
                    </option>
                    <option
                      id="smp.Cr.OffSet.6"
                      name="smp.Cr.OffSet.6"
                      value="6"
                    >
                      ±6
                    </option>
                    <option
                      id="smp.Cr.OffSet.7"
                      name="smp.Cr.OffSet.7"
                      value="7"
                    >
                      ±7
                    </option>
                    <option
                      id="smp.Cr.OffSet.8"
                      name="smp.Cr.OffSet.8"
                      value="8"
                    >
                      ±8
                    </option>
                    <option
                      id="smp.Cr.OffSet.9"
                      name="smp.Cr.OffSet.9"
                      value="9"
                    >
                      ±9
                    </option>
                    <option
                      id="smp.Cr.OffSet.10"
                      name="smp.Cr.OffSet.10"
                      value="10"
                    >
                      ±10
                    </option>
                    <option
                      id="smp.Cr.OffSet.11"
                      name="smp.Cr.OffSet.11"
                      value="11"
                    >
                      ±11
                    </option>
                    <option
                      id="smp.Cr.OffSet.12"
                      name="smp.Cr.OffSet.12"
                      value="12"
                    >
                      ±12
                    </option>
                    <option
                      id="smp.Cr.OffSet.13"
                      name="smp.Cr.OffSet.13"
                      value="13"
                    >
                      ±13
                    </option>
                    <option
                      id="smp.Cr.OffSet.14"
                      name="smp.Cr.OffSet.14"
                      value="14"
                    >
                      ±14
                    </option>
                    <option
                      id="smp.Cr.OffSet.15"
                      name="smp.Cr.OffSet.15"
                      value="15"
                    >
                      ±15
                    </option>
                  </select>
                </th>
              </tr>
            </th>
          </thead>
          <tbody>
            <tr>
              <td style={{ width: "9%" }}>STAGES</td>
              <td style={{ width: "8%" }}>
                PRESSURE *(BAR)
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.InjPresSet.dropdown"
                  name="smp.Cr.InjPresSet.dropdown"
                  value={smpCreateState.InjPresSet}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      InjPresSet: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.InjPresSet.sct"
                    name="smp.Cr.InjPresSet.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.InjPresSet.1"
                    name="smp.Cr.InjPresSet.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.InjPresSet.2"
                    name="smp.Cr.InjPresSet.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.InjPresSet.3"
                    name="smp.Cr.InjPresSet.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.InjPresSet.4"
                    name="smp.Cr.InjPresSet.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.InjPresSet.5"
                    name="smp.Cr.InjPresSet.5"
                    value="5"
                  >
                    ±5
                  </option>
                  <option
                    id="smp.Cr.InjPresSet.6"
                    name="smp.Cr.InjPresSet.6"
                    value="6"
                  >
                    ±6
                  </option>
                  <option
                    id="smp.Cr.InjPresSet.7"
                    name="smp.Cr.InjPresSet.7"
                    value="7"
                  >
                    ±7
                  </option>
                  <option
                    id="smp.Cr.InjPresSet.8"
                    name="smp.Cr.InjPresSet.8"
                    value="8"
                  >
                    ±8
                  </option>
                  <option
                    id="smp.Cr.InjPresSet.9"
                    name="smp.Cr.InjPresSet.9"
                    value="9"
                  >
                    ±9
                  </option>
                  <option
                    id="smp.Cr.InjPresSet.10"
                    name="smp.Cr.InjPresSet.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.InjPresSet.11"
                    name="smp.Cr.InjPresSet.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.InjPresSet.12"
                    name="smp.Cr.InjPresSet.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.InjPresSet.13"
                    name="smp.Cr.InjPresSet.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.InjPresSet.14"
                    name="smp.Cr.InjPresSet.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.InjPresSet.15"
                    name="smp.Cr.InjPresSet.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
              </td>
              <td style={{ width: "7%" }}>
                SPEED% *
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.InjSpdSet.dropdown"
                  name="smp.Cr.InjSpdSet.dropdown"
                  value={smpCreateState.InjSpdSet}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      InjSpdSet: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.InjSpdSet.sct"
                    name="smp.Cr.InjSpdSet.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.InjSpdSet.1"
                    name="smp.Cr.InjSpdSet.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.InjSpdSet.2"
                    name="smp.Cr.InjSpdSet.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.InjSpdSet.3"
                    name="smp.Cr.InjSpdSet.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.InjSpdSet.4"
                    name="smp.Cr.InjSpdSet.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.InjSpdSet.5"
                    name="smp.Cr.InjSpdSet.5"
                    value="5"
                  >
                    ±5
                  </option>
                  <option
                    id="smp.Cr.InjSpdSet.6"
                    name="smp.Cr.InjSpdSet.6"
                    value="6"
                  >
                    ±6
                  </option>
                  <option
                    id="smp.Cr.InjSpdSet.7"
                    name="smp.Cr.InjSpdSet.7"
                    value="7"
                  >
                    ±7
                  </option>
                  <option
                    id="smp.Cr.InjSpdSet.8"
                    name="smp.Cr.InjSpdSet.8"
                    value="8"
                  >
                    ±8
                  </option>
                  <option
                    id="smp.Cr.InjSpdSet.9"
                    name="smp.Cr.InjSpdSet.9"
                    value="9"
                  >
                    ±9
                  </option>
                  <option
                    id="smp.Cr.InjSpdSet.10"
                    name="smp.Cr.InjSpdSet.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.InjSpdSet.11"
                    name="smp.Cr.InjSpdSet.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.InjSpdSet.12"
                    name="smp.Cr.InjSpdSet.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.InjSpdSet.13"
                    name="smp.Cr.InjSpdSet.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.InjSpdSet.14"
                    name="smp.Cr.InjSpdSet.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.InjSpdSet.15"
                    name="smp.Cr.InjSpdSet.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
              </td>
              <td style={{ width: "8%" }}>
                POSITION MM&#x27E1;
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.InjPosSet.dropdown"
                  name="smp.Cr.InjPosSet.dropdown"
                  value={smpCreateState.InjPosSet}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      InjPosSet: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.InjPosSet.sct"
                    name="smp.Cr.InjPosSet.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.InjPosSet.1"
                    name="smp.Cr.InjPosSet.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.InjPosSet.2"
                    name="smp.Cr.InjPosSet.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.InjPosSet.3"
                    name="smp.Cr.InjPosSet.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.InjPosSet.4"
                    name="smp.Cr.InjPosSet.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.InjPosSet.5"
                    name="smp.Cr.InjPosSet.5"
                    value="5"
                  >
                    ±5
                  </option>
                  <option
                    id="smp.Cr.InjPosSet.6"
                    name="smp.Cr.InjPosSet.6"
                    value="6"
                  >
                    ±6
                  </option>
                  <option
                    id="smp.Cr.InjPosSet.7"
                    name="smp.Cr.InjPosSet.7"
                    value="7"
                  >
                    ±7
                  </option>
                  <option
                    id="smp.Cr.InjPosSet.8"
                    name="smp.Cr.InjPosSet.8"
                    value="8"
                  >
                    ±8
                  </option>
                  <option
                    id="smp.Cr.InjPosSet.9"
                    name="smp.Cr.InjPosSet.9"
                    value="9"
                  >
                    ±9
                  </option>
                  <option
                    id="smp.Cr.InjPosSet.10"
                    name="smp.Cr.InjPosSet.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.InjPosSet.11"
                    name="smp.Cr.InjPosSet.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.InjPosSet.12"
                    name="smp.Cr.InjPosSet.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.InjPosSet.13"
                    name="smp.Cr.InjPosSet.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.InjPosSet.14"
                    name="smp.Cr.InjPosSet.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.InjPosSet.15"
                    name="smp.Cr.InjPosSet.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>{" "}
              </td>
              <td style={{ width: "7%" }}>
                TIME(SEC) *
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.InjTmeSet.dropdown"
                  name="smp.Cr.InjTmeSet.dropdown"
                  value={smpCreateState.InjTmeSet}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      InjTmeSet: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.InjTmeSet.sct"
                    name="smp.Cr.InjTmeSet.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.InjTmeSet.0.1"
                    name="smp.Cr.InjTmeSet.0.1"
                    value="0.1"
                  >
                    ±0.1
                  </option>
                  <option
                    id="smp.Cr.InjTmeSet.0.2"
                    name="smp.Cr.InjTmeSet.0.2"
                    value="0.2"
                  >
                    ±0.2
                  </option>
                  <option
                    id="smp.Cr.InjTmeSet.0.3"
                    name="smp.Cr.InjTmeSet.0.3"
                    value="0.3"
                  >
                    ±0.3
                  </option>
                  <option
                    id="smp.Cr.InjTmeSet.0.4"
                    name="smp.Cr.InjTmeSet.0.4"
                    value="0.4"
                  >
                    ±0.4
                  </option>
                  <option
                    id="smp.Cr.InjTmeSet.0.5"
                    name="smp.Cr.InjTmeSet.0.5"
                    value="0.5"
                  >
                    ±0.5
                  </option>
                  <option
                    id="smp.Cr.InjTmeSet.0.6"
                    name="smp.Cr.InjTmeSet.0.6"
                    value="0.6"
                  >
                    ±0.6
                  </option>
                  <option
                    id="smp.Cr.InjTmeSet.0.7"
                    name="smp.Cr.InjTmeSet.0.7"
                    value="0.7"
                  >
                    ±0.7
                  </option>
                  <option
                    id="smp.Cr.InjTmeSet.0.8"
                    name="smp.Cr.InjTmeSet.0.8"
                    value="0.8"
                  >
                    ±0.8
                  </option>
                  <option
                    id="smp.Cr.InjTmeSet.0.9"
                    name="smp.Cr.InjTmeSet.0.9"
                    value="0.9"
                  >
                    ±0.9
                  </option>
                  <option
                    id="smp.Cr.InjTmeSet.1"
                    name="smp.Cr.InjTmeSet.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.InjTmeSet.2"
                    name="smp.Cr.InjTmeSet.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.InjTmeSet.3"
                    name="smp.Cr.InjTmeSet.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.InjTmeSet.4"
                    name="smp.Cr.InjTmeSet.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.InjTmeSet.5"
                    name="smp.Cr.InjTmeSet.5"
                    value="5"
                  >
                    ±5
                  </option>
                </select>
              </td>
              <td>STAGES</td>
              <td>BARREL TEMP</td>
              <td>HOT RUNNER TEMPERATURE</td>
              <td>CELSIUS &deg;C</td>
              <td>HOT RUNNER TEMPERATURE</td>
              <td>
                &deg;C{" "}
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.GteSet.dropdown"
                  name="smp.Cr.GteSet.dropdown"
                  value={smpCreateState.GteSet}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      GteSet: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.GteSet.sct"
                    name="smp.Cr.GteSet.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option id="smp.Cr.GteSet.1" name="smp.Cr.GteSet.1" value="1">
                    ±1
                  </option>
                  <option id="smp.Cr.GteSet.2" name="smp.Cr.GteSet.2" value="2">
                    ±2
                  </option>
                  <option id="smp.Cr.GteSet.3" name="smp.Cr.GteSet.3" value="3">
                    ±3
                  </option>
                  <option id="smp.Cr.GteSet.4" name="smp.Cr.GteSet.4" value="4">
                    ±4
                  </option>
                  <option id="smp.Cr.GteSet.5" name="smp.Cr.GteSet.5" value="5">
                    ±5
                  </option>
                  <option id="smp.Cr.GteSet.6" name="smp.Cr.GteSet.6" value="6">
                    ±6
                  </option>
                  <option id="smp.Cr.GteSet.7" name="smp.Cr.GteSet.7" value="7">
                    ±7
                  </option>
                  <option id="smp.Cr.GteSet.8" name="smp.Cr.GteSet.8" value="8">
                    ±8
                  </option>
                  <option id="smp.Cr.GteSet.9" name="smp.Cr.GteSet.9" value="9">
                    ±9
                  </option>
                  <option
                    id="smp.Cr.GteSet.10"
                    name="smp.Cr.GteSet.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.GteSet.11"
                    name="smp.Cr.GteSet.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.GteSet.12"
                    name="smp.Cr.GteSet.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.GteSet.13"
                    name="smp.Cr.GteSet.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.GteSet.14"
                    name="smp.Cr.GteSet.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.GteSet.15"
                    name="smp.Cr.GteSet.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
              </td>
              <td>Gate 1</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}

                  id="smp.Cr.Gate6"
                  name="smp.Cr.Gate6"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Gate6}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Gate6: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Gate7"
                  name="smp.Cr.Gate7"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Gate7}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Gate7: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Injection 1*</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                    console.log("onKeyDown E", e);
                  }}
                  // onKeyDown={(e) => {
                  // if (e.key === 'ArrowDown') {
                  //   // e.preventDefault();
                  //   const nextInput = document.getElementById('smp.Cr.Inj201');
                  //   if (nextInput) {
                  //     nextInput.focus();
                  //   }
                  // }else if (e.key === 'ArrowRight') {
                  //   // e.preventDefault();
                  //   const nextInput = document.getElementById('smp.Cr.Inj102');
                  //   if (nextInput) {
                  //     nextInput.focus();
                  //   }
                  // }
                  // }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Inj101"
                  name="smp.Cr.Inj101"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Inj101}
                  onChange={(e) => {
                    console.log("onchange E", e);
                    setSmpCreateState({
                      ...smpCreateState,
                      Inj101: e.target.value,
                    })
                  }
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Inj102"
                  name="smp.Cr.Inj102"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Inj102}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Inj102: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Inj103"
                  name="smp.Cr.Inj103"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Inj103}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Inj103: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Inj104"
                  name="smp.Cr.Inj104"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Inj104}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Inj104: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                Nozzle*{" "}
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.NozzSet.dropdown"
                  name="smp.Cr.NozzSet.dropdown"
                  value={smpCreateState.NozzSet}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      NozzSet: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.NozzSet.sct"
                    name="smp.Cr.NozzSet.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.NozzSet.1"
                    name="smp.Cr.NozzSet.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.NozzSet.2"
                    name="smp.Cr.NozzSet.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.NozzSet.3"
                    name="smp.Cr.NozzSet.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.NozzSet.4"
                    name="smp.Cr.NozzSet.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.NozzSet.5"
                    name="smp.Cr.NozzSet.5"
                    value="5"
                  >
                    ±5
                  </option>
                  <option
                    id="smp.Cr.NozzSet.6"
                    name="smp.Cr.NozzSet.6"
                    value="6"
                  >
                    ±6
                  </option>
                  <option
                    id="smp.Cr.NozzSet.7"
                    name="smp.Cr.NozzSet.7"
                    value="7"
                  >
                    ±7
                  </option>
                  <option
                    id="smp.Cr.NozzSet.8"
                    name="smp.Cr.NozzSet.8"
                    value="8"
                  >
                    ±8
                  </option>
                  <option
                    id="smp.Cr.NozzSet.9"
                    name="smp.Cr.NozzSet.9"
                    value="9"
                  >
                    ±9
                  </option>
                  <option
                    id="smp.Cr.NozzSet.10"
                    name="smp.Cr.NozzSet.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.NozzSet.11"
                    name="smp.Cr.NozzSet.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.NozzSet.12"
                    name="smp.Cr.NozzSet.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.NozzSet.13"
                    name="smp.Cr.NozzSet.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.NozzSet.14"
                    name="smp.Cr.NozzSet.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.NozzSet.15"
                    name="smp.Cr.NozzSet.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
                &deg;C
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Hozz"
                  name="smp.Cr.Hozz"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Hozz}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Hozz: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                ManiFold 1
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ManiFldSet1.dropdown"
                  name="smp.Cr.ManiFldSet1.dropdown"
                  value={smpCreateState.ManiFldSet1}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ManiFldSet1: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.ManiFldSet1.sct"
                    name="smp.Cr.ManiFldSet1.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet1.1"
                    name="smp.Cr.ManiFldSet1.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet1.2"
                    name="smp.Cr.ManiFldSet1.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet1.3"
                    name="smp.Cr.ManiFldSet1.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet1.4"
                    name="smp.Cr.ManiFldSet1.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet1.5"
                    name="smp.Cr.ManiFldSet1.5"
                    value="5"
                  >
                    ±5
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet1.6"
                    name="smp.Cr.ManiFldSet1.6"
                    value="6"
                  >
                    ±6
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet1.7"
                    name="smp.Cr.ManiFldSet1.7"
                    value="7"
                  >
                    ±7
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet1.8"
                    name="smp.Cr.ManiFldSet1.8"
                    value="8"
                  >
                    ±8
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet1.9"
                    name="smp.Cr.ManiFldSet1.9"
                    value="9"
                  >
                    ±9
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet1.10"
                    name="smp.Cr.ManiFldSet1.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet1.11"
                    name="smp.Cr.ManiFldSet1.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet1.12"
                    name="smp.Cr.ManiFldSet1.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet1.13"
                    name="smp.Cr.ManiFldSet1.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet1.14"
                    name="smp.Cr.ManiFldSet1.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet1.15"
                    name="smp.Cr.ManiFldSet1.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
              </td>
              <td>
                {" "}
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ManFld1"
                  name="smp.Cr.ManFld1"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.ManFld1}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ManFld1: e.target.value,
                    })
                  }
                />
              </td>
              <td>Gate 1</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Gate1"
                  name="smp.Cr.Gate1"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Gate1}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Gate1: e.target.value,
                    })
                  }
                />
              </td>
              <td>Gate 2</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Gte2"
                  name="smp.Cr.Gte2"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Gte2}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Gte2: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Gate8"
                  name="smp.Cr.Gate8"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Gate8}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Gate8: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Injection 2*</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Inj201"
                  name="smp.Cr.Inj201"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Inj201}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Inj201: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Inj202"
                  name="smp.Cr.Inj202"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Inj202}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Inj202: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Inj203"
                  name="smp.Cr.Inj203"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Inj203}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Inj203: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Inj204"
                  name="smp.Cr.Inj204"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Inj204}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Inj204: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                Zone 1*{" "}
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ZneSet1.dropdown"
                  name="smp.Cr.ZneSet1.dropdown"
                  value={smpCreateState.ZneSet1}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ZneSet1: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.ZneSet1.sct"
                    name="smp.Cr.ZneSet1.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.ZneSet1.1"
                    name="smp.Cr.ZneSet1.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.ZneSet1.2"
                    name="smp.Cr.ZneSet1.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.ZneSet1.3"
                    name="smp.Cr.ZneSet1.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.ZneSet1.4"
                    name="smp.Cr.ZneSet1.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.ZneSet1.5"
                    name="smp.Cr.ZneSet1.5"
                    value="5"
                  >
                    ±5
                  </option>
                  <option
                    id="smp.Cr.ZneSet1.6"
                    name="smp.Cr.ZneSet1.6"
                    value="6"
                  >
                    ±6
                  </option>
                  <option
                    id="smp.Cr.ZneSet1.7"
                    name="smp.Cr.ZneSet1.7"
                    value="7"
                  >
                    ±7
                  </option>
                  <option
                    id="smp.Cr.ZneSet1.8"
                    name="smp.Cr.ZneSet1.8"
                    value="8"
                  >
                    ±8
                  </option>
                  <option
                    id="smp.Cr.ZneSet1.9"
                    name="smp.Cr.ZneSet1.9"
                    value="9"
                  >
                    ±9
                  </option>
                  <option
                    id="smp.Cr.ZneSet1.10"
                    name="smp.Cr.ZneSet1.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.ZneSet1.11"
                    name="smp.Cr.ZneSet1.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.ZneSet1.12"
                    name="smp.Cr.ZneSet1.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.ZneSet1.13"
                    name="smp.Cr.ZneSet1.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.ZneSet1.14"
                    name="smp.Cr.ZneSet1.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.ZneSet1.15"
                    name="smp.Cr.ZneSet1.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
                &deg;C
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Zone1"
                  name="smp.Cr.Zone1"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Zone1}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Zone1: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                ManiFold 2
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ManiFldSet2.dropdown"
                  name="smp.Cr.ManiFldSet2.dropdown"
                  value={smpCreateState.ManiFldSet2}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ManiFldSet2: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.ManiFldSet2.sct"
                    name="smp.Cr.ManiFldSet2.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet2.1"
                    name="smp.Cr.ManiFldSet2.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet2.2"
                    name="smp.Cr.ManiFldSet2.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet2.3"
                    name="smp.Cr.ManiFldSet2.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet2.4"
                    name="smp.Cr.ManiFldSet2.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet2.5"
                    name="smp.Cr.ManiFldSet2.5"
                    value="5"
                  >
                    ±5
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet2.6"
                    name="smp.Cr.ManiFldSet2.6"
                    value="6"
                  >
                    ±6
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet2.7"
                    name="smp.Cr.ManiFldSet2.7"
                    value="7"
                  >
                    ±7
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet2.8"
                    name="smp.Cr.ManiFldSet2.8"
                    value="8"
                  >
                    ±8
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet2.9"
                    name="smp.Cr.ManiFldSet2.9"
                    value="9"
                  >
                    ±9
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet2.10"
                    name="smp.Cr.ManiFldSet2.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet2.11"
                    name="smp.Cr.ManiFldSet2.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet2.12"
                    name="smp.Cr.ManiFldSet2.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet2.13"
                    name="smp.Cr.ManiFldSet2.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet2.14"
                    name="smp.Cr.ManiFldSet2.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet2.15"
                    name="smp.Cr.ManiFldSet2.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
              </td>
              <td>
                {" "}
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ManFld2"
                  name="smp.Cr.ManFld2"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.ManFld2}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ManFld2: e.target.value,
                    })
                  }
                />
              </td>

              <td>Gate 2</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Gate2"
                  name="smp.Cr.Gate2"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Gate2}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Gate2: e.target.value,
                    })
                  }
                />
              </td>

              <td>Gate 3</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Gte3"
                  name="smp.Cr.Gte3"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Gte3}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Gte3: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Gate9"
                  name="smp.Cr.Gate9"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Gate9}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Gate9: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Injection 3*</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Inj301"
                  name="smp.Cr.Inj301"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Inj301}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Inj301: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Inj302"
                  name="smp.Cr.Inj302"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Inj302}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Inj302: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Inj303"
                  name="smp.Cr.Inj303"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Inj303}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Inj303: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Inj304"
                  name="smp.Cr.Inj304"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Inj304}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Inj304: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                Zone 2*{" "}
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ZneSet2.dropdown"
                  name="smp.Cr.ZneSet2.dropdown"
                  value={smpCreateState.ZneSet2}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ZneSet2: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.ZneSet2.sct"
                    name="smp.Cr.ZneSet2.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.ZneSet2.1"
                    name="smp.Cr.ZneSet2.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.ZneSet2.2"
                    name="smp.Cr.ZneSet2.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.ZneSet2.3"
                    name="smp.Cr.ZneSet2.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.ZneSet2.4"
                    name="smp.Cr.ZneSet2.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.ZneSet2.5"
                    name="smp.Cr.ZneSet2.5"
                    value="5"
                  >
                    ±5
                  </option>
                  <option
                    id="smp.Cr.ZneSet2.6"
                    name="smp.Cr.ZneSet2.6"
                    value="6"
                  >
                    ±6
                  </option>
                  <option
                    id="smp.Cr.ZneSet2.7"
                    name="smp.Cr.ZneSet2.7"
                    value="7"
                  >
                    ±7
                  </option>
                  <option
                    id="smp.Cr.ZneSet2.8"
                    name="smp.Cr.ZneSet2.8"
                    value="8"
                  >
                    ±8
                  </option>
                  <option
                    id="smp.Cr.ZneSet2.9"
                    name="smp.Cr.ZneSet2.9"
                    value="9"
                  >
                    ±9
                  </option>
                  <option
                    id="smp.Cr.ZneSet2.10"
                    name="smp.Cr.ZneSet2.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.ZneSet2.11"
                    name="smp.Cr.ZneSet2.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.ZneSet2.12"
                    name="smp.Cr.ZneSet2.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.ZneSet2.13"
                    name="smp.Cr.ZneSet2.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.ZneSet2.14"
                    name="smp.Cr.ZneSet2.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.ZneSet2.15"
                    name="smp.Cr.ZneSet2.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
                &deg;C
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Zone2"
                  name="smp.Cr.Zone2"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Zone2}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Zone2: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                ManiFold 3
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ManiFldSet3.dropdown"
                  name="smp.Cr.ManiFldSet3.dropdown"
                  value={smpCreateState.ManiFldSet3}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ManiFldSet3: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.ManiFldSet3.sct"
                    name="smp.Cr.ManiFldSet3.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet3.1"
                    name="smp.Cr.ManiFldSet3.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet3.2"
                    name="smp.Cr.ManiFldSet3.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet3.3"
                    name="smp.Cr.ManiFldSet3.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet3.4"
                    name="smp.Cr.ManiFldSet3.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet3.5"
                    name="smp.Cr.ManiFldSet3.5"
                    value="5"
                  >
                    ±5
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet3.6"
                    name="smp.Cr.ManiFldSet3.6"
                    value="6"
                  >
                    ±6
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet3.7"
                    name="smp.Cr.ManiFldSet3.7"
                    value="7"
                  >
                    ±7
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet3.8"
                    name="smp.Cr.ManiFldSet3.8"
                    value="8"
                  >
                    ±8
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet3.9"
                    name="smp.Cr.ManiFldSet3.9"
                    value="9"
                  >
                    ±9
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet3.10"
                    name="smp.Cr.ManiFldSet3.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet3.11"
                    name="smp.Cr.ManiFldSet3.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet3.12"
                    name="smp.Cr.ManiFldSet3.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet3.13"
                    name="smp.Cr.ManiFldSet3.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet3.14"
                    name="smp.Cr.ManiFldSet3.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet3.15"
                    name="smp.Cr.ManiFldSet3.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
              </td>
              <td>
                {" "}
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ManFld3"
                  name="smp.Cr.ManFld3"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.ManFld3}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ManFld3: e.target.value,
                    })
                  }
                />
              </td>

              <td>Gate 3</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Gate3"
                  name="smp.Cr.Gate3"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Gate3}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Gate3: e.target.value,
                    })
                  }
                />
              </td>

              <td>Gate 4</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Gte4"
                  name="smp.Cr.Gte4"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Gte4}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Gte4: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Gate10"
                  name="smp.Cr.Gate10"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Gate10}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Gate10: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Injection 4*</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Inj401"
                  name="smp.Cr.Inj401"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Inj401}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Inj401: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Inj402"
                  name="smp.Cr.Inj402"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Inj402}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Inj402: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Inj403"
                  name="smp.Cr.Inj403"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Inj403}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Inj403: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Inj404"
                  name="smp.Cr.Inj404"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Inj404}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Inj404: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                Zone 3*{" "}
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ZneSet3.dropdown"
                  name="smp.Cr.ZneSet3.dropdown"
                  value={smpCreateState.ZneSet3}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ZneSet3: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.ZneSet3.sct"
                    name="smp.Cr.ZneSet3.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.ZneSet3.1"
                    name="smp.Cr.ZneSet3.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.ZneSet3.2"
                    name="smp.Cr.ZneSet3.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.ZneSet3.3"
                    name="smp.Cr.ZneSet3.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.ZneSet3.4"
                    name="smp.Cr.ZneSet3.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.ZneSet3.5"
                    name="smp.Cr.ZneSet3.5"
                    value="5"
                  >
                    ±5
                  </option>
                  <option
                    id="smp.Cr.ZneSet3.6"
                    name="smp.Cr.ZneSet3.6"
                    value="6"
                  >
                    ±6
                  </option>
                  <option
                    id="smp.Cr.ZneSet3.7"
                    name="smp.Cr.ZneSet3.7"
                    value="7"
                  >
                    ±7
                  </option>
                  <option
                    id="smp.Cr.ZneSet3.8"
                    name="smp.Cr.ZneSet3.8"
                    value="8"
                  >
                    ±8
                  </option>
                  <option
                    id="smp.Cr.ZneSet3.9"
                    name="smp.Cr.ZneSet3.9"
                    value="9"
                  >
                    ±9
                  </option>
                  <option
                    id="smp.Cr.ZneSet3.10"
                    name="smp.Cr.ZneSet3.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.ZneSet3.11"
                    name="smp.Cr.ZneSet3.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.ZneSet3.12"
                    name="smp.Cr.ZneSet3.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.ZneSet3.13"
                    name="smp.Cr.ZneSet3.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.ZneSet3.14"
                    name="smp.Cr.ZneSet3.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.ZneSet3.15"
                    name="smp.Cr.ZneSet3.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
                &deg;C
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Zone3"
                  name="smp.Cr.Zone3"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Zone3}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Zone3: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                ManiFold 4
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ManiFldSet4.dropdown"
                  name="smp.Cr.ManiFldSet4.dropdown"
                  value={smpCreateState.ManiFldSet4}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ManiFldSet4: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.ManiFldSet4.sct"
                    name="smp.Cr.ManiFldSet4.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet4.1"
                    name="smp.Cr.ManiFldSet4.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet4.2"
                    name="smp.Cr.ManiFldSet4.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet4.3"
                    name="smp.Cr.ManiFldSet4.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet4.4"
                    name="smp.Cr.ManiFldSet4.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet4.5"
                    name="smp.Cr.ManiFldSet4.5"
                    value="5"
                  >
                    ±5
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet4.6"
                    name="smp.Cr.ManiFldSet4.6"
                    value="6"
                  >
                    ±6
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet4.7"
                    name="smp.Cr.ManiFldSet4.7"
                    value="7"
                  >
                    ±7
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet4.8"
                    name="smp.Cr.ManiFldSet4.8"
                    value="8"
                  >
                    ±8
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet4.9"
                    name="smp.Cr.ManiFldSet4.9"
                    value="9"
                  >
                    ±9
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet4.10"
                    name="smp.Cr.ManiFldSet4.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet4.11"
                    name="smp.Cr.ManiFldSet4.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet4.12"
                    name="smp.Cr.ManiFldSet4.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet4.13"
                    name="smp.Cr.ManiFldSet4.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet4.14"
                    name="smp.Cr.ManiFldSet4.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet4.15"
                    name="smp.Cr.ManiFldSet4.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
              </td>
              <td>
                {" "}
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ManFld4"
                  name="smp.Cr.ManFld4"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.ManFld4}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ManFld4: e.target.value,
                    })
                  }
                />
              </td>

              <td>Gate 4</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Gate4"
                  name="smp.Cr.Gate4"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Gate4}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Gate4: e.target.value,
                    })
                  }
                />
              </td>

              <td>Gate 5</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Gte5"
                  name="smp.Cr.Gte5"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Gte5}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Gte5: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Gate11"
                  name="smp.Cr.Gate11"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Gate11}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Gate11: e.target.value,
                    })
                  }
                />
              </td>
            </tr>

            <tr>
              <td>Fill Time(Actual)</td>
              <td colSpan="3"></td>

              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.FillT104"
                  name="smp.Cr.FillT104"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.FillT104}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      FillT104: e.target.value,
                    })
                  }
                />
              </td>

              <td>
                Zone 4*{" "}
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ZneSet4.dropdown"
                  name="smp.Cr.ZneSet4.dropdown"
                  value={smpCreateState.ZneSet4}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ZneSet4: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.ZneSet4.sct"
                    name="smp.Cr.ZneSet4.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.ZneSet4.1"
                    name="smp.Cr.ZneSet4.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.ZneSet4.2"
                    name="smp.Cr.ZneSet4.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.ZneSet4.3"
                    name="smp.Cr.ZneSet4.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.ZneSet4.4"
                    name="smp.Cr.ZneSet4.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.ZneSet4.5"
                    name="smp.Cr.ZneSet4.5"
                    value="5"
                  >
                    ±5
                  </option>
                  <option
                    id="smp.Cr.ZneSet4.6"
                    name="smp.Cr.ZneSet4.6"
                    value="6"
                  >
                    ±6
                  </option>
                  <option
                    id="smp.Cr.ZneSet4.7"
                    name="smp.Cr.ZneSet4.7"
                    value="7"
                  >
                    ±7
                  </option>
                  <option
                    id="smp.Cr.ZneSet4.8"
                    name="smp.Cr.ZneSet4.8"
                    value="8"
                  >
                    ±8
                  </option>
                  <option
                    id="smp.Cr.ZneSet4.9"
                    name="smp.Cr.ZneSet4.9"
                    value="9"
                  >
                    ±9
                  </option>
                  <option
                    id="smp.Cr.ZneSet4.10"
                    name="smp.Cr.ZneSet4.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.ZneSet4.11"
                    name="smp.Cr.ZneSet4.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.ZneSet4.12"
                    name="smp.Cr.ZneSet4.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.ZneSet4.13"
                    name="smp.Cr.ZneSet4.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.ZneSet4.14"
                    name="smp.Cr.ZneSet4.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.ZneSet4.15"
                    name="smp.Cr.ZneSet4.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
                &deg;C
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Zone4"
                  name="smp.Cr.Zone4"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Zone4}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Zone4: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                ManiFold 5
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ManiFldSet5.dropdown"
                  name="smp.Cr.ManiFldSet5.dropdown"
                  value={smpCreateState.ManiFldSet5}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ManiFldSet5: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.ManiFldSet5.sct"
                    name="smp.Cr.ManiFldSet5.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet5.1"
                    name="smp.Cr.ManiFldSet5.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet5.2"
                    name="smp.Cr.ManiFldSet5.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet5.3"
                    name="smp.Cr.ManiFldSet5.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet5.4"
                    name="smp.Cr.ManiFldSet5.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet5.5"
                    name="smp.Cr.ManiFldSet5.5"
                    value="5"
                  >
                    ±5
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet5.6"
                    name="smp.Cr.ManiFldSet5.6"
                    value="6"
                  >
                    ±6
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet5.7"
                    name="smp.Cr.ManiFldSet5.7"
                    value="7"
                  >
                    ±7
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet5.8"
                    name="smp.Cr.ManiFldSet5.8"
                    value="8"
                  >
                    ±8
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet5.9"
                    name="smp.Cr.ManiFldSet5.9"
                    value="9"
                  >
                    ±9
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet5.10"
                    name="smp.Cr.ManiFldSet5.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet5.11"
                    name="smp.Cr.ManiFldSet5.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet5.12"
                    name="smp.Cr.ManiFldSet5.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet5.13"
                    name="smp.Cr.ManiFldSet5.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet5.14"
                    name="smp.Cr.ManiFldSet5.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.ManiFldSet5.15"
                    name="smp.Cr.ManiFldSet5.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
              </td>
              <td>
                {" "}
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ManFld5"
                  name="smp.Cr.ManFld5"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.ManFld5}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ManFld5: e.target.value,
                    })
                  }
                />
              </td>

              <td>Gate 5</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Gate5"
                  name="smp.Cr.Gate5"
                  type="text"
                  value={smpCreateState.Gate5}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Gate5: e.target.value,
                    })
                  }
                />
              </td>

              <td>Gate 6</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Gte6"
                  name="smp.Cr.Gte6"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Gte6}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Gte6: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Gate12"
                  name="smp.Cr.Gate12"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Gate12}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Gate12: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td colSpan={5}></td>{" "}
              <td>
                Zone 5*{" "}
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ZneSet5.dropdown"
                  name="smp.Cr.ZneSet5.dropdown"
                  value={smpCreateState.ZneSet5}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ZneSet5: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.ZneSet5.sct"
                    name="smp.Cr.ZneSet5.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.ZneSet5.1"
                    name="smp.Cr.ZneSet5.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.ZneSet5.2"
                    name="smp.Cr.ZneSet5.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.ZneSet5.3"
                    name="smp.Cr.ZneSet5.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.ZneSet5.4"
                    name="smp.Cr.ZneSet5.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.ZneSet5.5"
                    name="smp.Cr.ZneSet5.5"
                    value="5"
                  >
                    ±5
                  </option>
                  <option
                    id="smp.Cr.ZneSet5.6"
                    name="smp.Cr.ZneSet5.6"
                    value="6"
                  >
                    ±6
                  </option>
                  <option
                    id="smp.Cr.ZneSet5.7"
                    name="smp.Cr.ZneSet5.7"
                    value="7"
                  >
                    ±7
                  </option>
                  <option
                    id="smp.Cr.ZneSet5.8"
                    name="smp.Cr.ZneSet5.8"
                    value="8"
                  >
                    ±8
                  </option>
                  <option
                    id="smp.Cr.ZneSet5.9"
                    name="smp.Cr.ZneSet5.9"
                    value="9"
                  >
                    ±9
                  </option>
                  <option
                    id="smp.Cr.ZneSet5.10"
                    name="smp.Cr.ZneSet5.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.ZneSet5.11"
                    name="smp.Cr.ZneSet5.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.ZneSet5.12"
                    name="smp.Cr.ZneSet5.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.ZneSet5.13"
                    name="smp.Cr.ZneSet5.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.ZneSet5.14"
                    name="smp.Cr.ZneSet5.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.ZneSet5.15"
                    name="smp.Cr.ZneSet5.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
                &deg;C
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Zone5"
                  name="smp.Cr.Zone5"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Zone5}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Zone5: e.target.value,
                    })
                  }
                />
              </td>
              <td colSpan={7}></td>
            </tr>
            <tr>
              <td colSpan={5}></td>{" "}
              <td>
                Zone 6*{" "}
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ZneSet6.dropdown"
                  name="smp.Cr.ZneSet6.dropdown"
                  value={smpCreateState.ZneSet6}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ZneSet6: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.ZneSet6.sct"
                    name="smp.Cr.ZneSet6.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.ZneSet6.1"
                    name="smp.Cr.ZneSet6.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.ZneSet6.2"
                    name="smp.Cr.ZneSet6.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.ZneSet6.3"
                    name="smp.Cr.ZneSet6.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.ZneSet6.4"
                    name="smp.Cr.ZneSet6.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.ZneSet6.5"
                    name="smp.Cr.ZneSet6.5"
                    value="5"
                  >
                    ±5
                  </option>
                  <option
                    id="smp.Cr.ZneSet6.6"
                    name="smp.Cr.ZneSet6.6"
                    value="6"
                  >
                    ±6
                  </option>
                  <option
                    id="smp.Cr.ZneSet6.7"
                    name="smp.Cr.ZneSet6.7"
                    value="7"
                  >
                    ±7
                  </option>
                  <option
                    id="smp.Cr.ZneSet6.8"
                    name="smp.Cr.ZneSet6.8"
                    value="8"
                  >
                    ±8
                  </option>
                  <option
                    id="smp.Cr.ZneSet6.9"
                    name="smp.Cr.ZneSet6.9"
                    value="9"
                  >
                    ±9
                  </option>
                  <option
                    id="smp.Cr.ZneSet6.10"
                    name="smp.Cr.ZneSet6.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.ZneSet6.11"
                    name="smp.Cr.ZneSet6.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.ZneSet6.12"
                    name="smp.Cr.ZneSet6.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.ZneSet6.13"
                    name="smp.Cr.ZneSet6.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.ZneSet6.14"
                    name="smp.Cr.ZneSet6.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.ZneSet6.15"
                    name="smp.Cr.ZneSet6.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
                &deg;C
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Zone6"
                  name="smp.Cr.Zone6"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Zone6}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Zone6: e.target.value,
                    })
                  }
                />
              </td>
              <td colSpan={7}></td>
            </tr>

            <tr>
              <td colSpan="3">Switch Over / Transfer Position(MM)&#x27E1;:</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Switch"
                  name="smp.Cr.Switch"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Switch}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Switch: e.target.value,
                    })
                  }
                />
              </td>
              <td></td>
              <td>
                Hopper*{" "}
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.HoppSet.dropdown"
                  name="smp.Cr.HoppSet.dropdown"
                  value={smpCreateState.HoppSet}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      HoppSet: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.HoppSet.sct"
                    name="smp.Cr.HoppSet.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.HoppSet.1"
                    name="smp.Cr.HoppSet.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.HoppSet.2"
                    name="smp.Cr.HoppSet.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.HoppSet.3"
                    name="smp.Cr.HoppSet.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.HoppSet.4"
                    name="smp.Cr.HoppSet.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.HoppSet.5"
                    name="smp.Cr.HoppSet.5"
                    value="5"
                  >
                    ±5
                  </option>
                  <option
                    id="smp.Cr.HoppSet.6"
                    name="smp.Cr.HoppSet.6"
                    value="6"
                  >
                    ±6
                  </option>
                  <option
                    id="smp.Cr.HoppSet.7"
                    name="smp.Cr.HoppSet.7"
                    value="7"
                  >
                    ±7
                  </option>
                  <option
                    id="smp.Cr.HoppSet.8"
                    name="smp.Cr.HoppSet.8"
                    value="8"
                  >
                    ±8
                  </option>
                  <option
                    id="smp.Cr.HoppSet.9"
                    name="smp.Cr.HoppSet.9"
                    value="9"
                  >
                    ±9
                  </option>
                  <option
                    id="smp.Cr.HoppSet.10"
                    name="smp.Cr.HoppSet.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.HoppSet.11"
                    name="smp.Cr.HoppSet.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.HoppSet.12"
                    name="smp.Cr.HoppSet.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.HoppSet.13"
                    name="smp.Cr.HoppSet.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.HoppSet.14"
                    name="smp.Cr.HoppSet.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.HoppSet.15"
                    name="smp.Cr.HoppSet.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
                &deg;C
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Hopp"
                  name="smp.Cr.Hopp"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Hopp}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Hopp: e.target.value,
                    })
                  }
                />
              </td>
              <td colSpan={7}></td>
            </tr>

          </tbody>
        </table>
        <table width="100%" style={{ textAlign: "center" }} border="0">
          <thead>
            <th style={{ textAlign: "center" }} colSpan="5"></th>

            <th style={{ textAlign: "center" }} colSpan="6">
              INJECTION UNIT SETTINGS
            </th>

            <th style={{ textAlign: "center" }} colSpan="4">
              MOULD TEMP DETAILS
            </th>
          </thead>
          <tbody>
            <tr>
              <td style={{ width: "9%" }}>Hold On 1*</td>
              <td style={{ width: "8%" }}>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Hold101"
                  name="smp.Cr.Hold101"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Hold101}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Hold101: e.target.value,
                    })
                  }
                />
              </td>
              <td style={{ width: "7%" }}>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Hold201"
                  name="smp.Cr.Hold201"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Hold201}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Hold201: e.target.value,
                    })
                  }
                />
              </td>
              <td style={{ width: "8%" }}>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Hold301"
                  name="smp.Cr.Hold301"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Hold301}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Hold301: e.target.value,
                    })
                  }
                />
              </td>
              <td style={{ width: "7%" }}>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Hold401"
                  name="smp.Cr.Hold401"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Hold401}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Hold401: e.target.value,
                    })
                  }
                />
              </td>
              <td></td>
              <td>SPEED%</td>
              <td></td>
              <td>SEC</td>
              <td>SPRUE BREAK MODE&#x27E1;</td>
              <td>
                <td
                  style={{
                    width: "50px",
                    height: "30px",
                    alignItems: "center",
                  }}
                >
                  {" "}
                </td>
              </td>
              <td>
                Core*{" "}
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.CoreSet.dropdown"
                  name="smp.Cr.CoreSet.dropdown"
                  value={smpCreateState.CoreSet}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      CoreSet: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.CoreSet.sct"
                    name="smp.Cr.CoreSet.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.CoreSet.1"
                    name="smp.Cr.CoreSet.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.CoreSet.2"
                    name="smp.Cr.CoreSet.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.CoreSet.3"
                    name="smp.Cr.CoreSet.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.CoreSet.4"
                    name="smp.Cr.CoreSet.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.CoreSet.5"
                    name="smp.Cr.CoreSet.5"
                    value="5"
                  >
                    ±5
                  </option>
                  <option
                    id="smp.Cr.CoreSet.6"
                    name="smp.Cr.CoreSet.6"
                    value="6"
                  >
                    ±6
                  </option>
                  <option
                    id="smp.Cr.CoreSet.7"
                    name="smp.Cr.CoreSet.7"
                    value="7"
                  >
                    ±7
                  </option>
                  <option
                    id="smp.Cr.CoreSet.8"
                    name="smp.Cr.CoreSet.8"
                    value="8"
                  >
                    ±8
                  </option>
                  <option
                    id="smp.Cr.CoreSet.9"
                    name="smp.Cr.CoreSet.9"
                    value="9"
                  >
                    ±9
                  </option>
                  <option
                    id="smp.Cr.CoreSet.10"
                    name="smp.Cr.CoreSet.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.CoreSet.11"
                    name="smp.Cr.CoreSet.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.CoreSet.12"
                    name="smp.Cr.CoreSet.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.CoreSet.13"
                    name="smp.Cr.CoreSet.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.CoreSet.14"
                    name="smp.Cr.CoreSet.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.CoreSet.15"
                    name="smp.Cr.CoreSet.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Core"
                  name="smp.Cr.Core"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Core}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Core: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Hold On 2*</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Hold102"
                  name="smp.Cr.Hold102"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Hold102}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Hold102: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Hold202"
                  name="smp.Cr.Hold202"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Hold202}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Hold202: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Hold302"
                  name="smp.Cr.Hold302"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Hold302}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Hold302: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Hold402"
                  name="smp.Cr.Hold402"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Hold402}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Hold402: e.target.value,
                    })
                  }
                />
              </td>
              <td>INJ UNIT&#x27E1;</td>
              <td>
                <td>ON</td>
              </td>
              <td>Sprue Break&#x27E1;</td>
              <td>
                <td style={{ border: "1px solid black" }}>OFF</td>
              </td>
              <td>0 INJ</td>
              <td></td>
              <td>
                Cavity*{" "}
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.CavSet.dropdown"
                  name="smp.Cr.CavSet.dropdown"
                  value={smpCreateState.CavSet}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      CavSet: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.CavSet.sct"
                    name="smp.Cr.CavSet.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option id="smp.Cr.CavSet.1" name="smp.Cr.CavSet.1" value="1">
                    ±1
                  </option>
                  <option id="smp.Cr.CavSet.2" name="smp.Cr.CavSet.2" value="2">
                    ±2
                  </option>
                  <option id="smp.Cr.CavSet.3" name="smp.Cr.CavSet.3" value="3">
                    ±3
                  </option>
                  <option id="smp.Cr.CavSet.4" name="smp.Cr.CavSet.4" value="4">
                    ±4
                  </option>
                  <option id="smp.Cr.CavSet.5" name="smp.Cr.CavSet.5" value="5">
                    ±5
                  </option>
                  <option id="smp.Cr.CavSet.6" name="smp.Cr.CavSet.6" value="6">
                    ±6
                  </option>
                  <option id="smp.Cr.CavSet.7" name="smp.Cr.CavSet.7" value="7">
                    ±7
                  </option>
                  <option id="smp.Cr.CavSet.8" name="smp.Cr.CavSet.8" value="8">
                    ±8
                  </option>
                  <option id="smp.Cr.CavSet.9" name="smp.Cr.CavSet.9" value="9">
                    ±9
                  </option>
                  <option
                    id="smp.Cr.CavSet.10"
                    name="smp.Cr.CavSet.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.CavSet.11"
                    name="smp.Cr.CavSet.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.CavSet.12"
                    name="smp.Cr.CavSet.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.CavSet.13"
                    name="smp.Cr.CavSet.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.CavSet.14"
                    name="smp.Cr.CavSet.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.CavSet.15"
                    name="smp.Cr.CavSet.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Cavity"
                  name="smp.Cr.Cavity"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Cavity}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Cavity: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Hold On 3*</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Hold103"
                  name="smp.Cr.Hold103"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Hold103}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Hold103: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Hold203"
                  name="smp.Cr.Hold203"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Hold203}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Hold203: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Hold303"
                  name="smp.Cr.Hold303"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Hold303}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Hold303: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Hold403"
                  name="smp.Cr.Hold403"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Hold403}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Hold403: e.target.value,
                    })
                  }
                />
              </td>
              <td>FWD SPD(MM/S)&#x27E1;</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Fwd"
                  name="smp.Cr.Fwd"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Fwd}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Fwd: e.target.value,
                    })
                  }
                />
              </td>
              <td>Delay Time</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Dely"
                  name="smp.Cr.Dely"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Dely}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Dely: e.target.value,
                    })
                  }
                />
              </td>
              <td>1.Front Suck Back</td>
              <td>3.Rear Suck Back</td>

              <td>
                {" "}
                <span style={{ marginRight: "5px", float: "left" }}>
                  M T C Temp*{" "}
                </span>
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.MtcSet.dropdown"
                  name="smp.Cr.MtcSet.dropdown"
                  value={smpCreateState.MtcSet}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      MtcSet: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.MtcSet.sct"
                    name="smp.Cr.MtcSet.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option id="smp.Cr.MtcSet.1" name="smp.Cr.MtcSet.1" value="1">
                    ±1
                  </option>
                  <option id="smp.Cr.MtcSet.2" name="smp.Cr.MtcSet.2" value="2">
                    ±2
                  </option>
                  <option id="smp.Cr.MtcSet.3" name="smp.Cr.MtcSet.3" value="3">
                    ±3
                  </option>
                  <option id="smp.Cr.MtcSet.4" name="smp.Cr.MtcSet.4" value="4">
                    ±4
                  </option>
                  <option id="smp.Cr.MtcSet.5" name="smp.Cr.MtcSet.5" value="5">
                    ±5
                  </option>
                  <option id="smp.Cr.MtcSet.6" name="smp.Cr.MtcSet.6" value="6">
                    ±6
                  </option>
                  <option id="smp.Cr.MtcSet.7" name="smp.Cr.MtcSet.7" value="7">
                    ±7
                  </option>
                  <option id="smp.Cr.MtcSet.8" name="smp.Cr.MtcSet.8" value="8">
                    ±8
                  </option>
                  <option id="smp.Cr.MtcSet.9" name="smp.Cr.MtcSet.9" value="9">
                    ±9
                  </option>
                  <option
                    id="smp.Cr.MtcSet.10"
                    name="smp.Cr.MtcSet.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.MtcSet.11"
                    name="smp.Cr.MtcSet.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.MtcSet.12"
                    name="smp.Cr.MtcSet.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.MtcSet.13"
                    name="smp.Cr.MtcSet.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.MtcSet.14"
                    name="smp.Cr.MtcSet.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.MtcSet.15"
                    name="smp.Cr.MtcSet.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.MTC"
                  name="smp.Cr.MTC"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.MTC}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      MTC: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Hold On 4*</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Hold104"
                  name="smp.Cr.Hold104"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Hold104}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Hold104: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Hold204"
                  name="smp.Cr.Hold204"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Hold204}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Hold204: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Hold304"
                  name="smp.Cr.Hold304"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Hold304}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Hold304: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Hold404"
                  name="smp.Cr.Hold404"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Hold404}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Hold404: e.target.value,
                    })
                  }
                />
              </td>
              <td>RET SPD(MM/S)&#x27E1;</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.RetSpd"
                  name="smp.Cr.RetSpd"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.RetSpd}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      RetSpd: e.target.value,
                    })
                  }
                />
              </td>
              <td>On Time</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.OnTme"
                  name="smp.Cr.OnTme"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.OnTme}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      OnTme: e.target.value,
                    })
                  }
                />
              </td>
              <td>2.Refill</td>
              <td>4.Cooling</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan="2">Sprue Bush Hole(MM)&#x27E1;</td>

              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.SprBushHle"
                  name="smp.Cr.SprBushHle"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.SprBushHle}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      SprBushHle: e.target.value,
                    })
                  }
                />
              </td>
              <td colSpan="2">Nozzle Hole(MM) &#x27E1;</td>

              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code)
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.NozzHole"
                  name="smp.Cr.NozzHole"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.NozzHole}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      NozzHole: e.target.value,
                    })
                  }
                />
              </td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <table width="100%" style={{ textAlign: "center" }} border="0">
          <thead>
            <th style={{ textAlign: "center" }} colSpan="5">
              REFILL SETTINGS
            </th>

            <th style={{ textAlign: "center" }} colSpan="4">
              MOULD CLOSING SETTINGS
            </th>

            <th style={{ textAlign: "center" }} colSpan="5">
              EJECTION SETTINGS
            </th>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td style={{ width: "5%" }}>
                PRESSURE(BAR){" "}
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ReFillPresSet.dropdown"
                  name="smp.Cr.ReFillPresSet.dropdown"
                  value={smpCreateState.ReFillPresSet}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ReFillPresSet: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.ReFillPresSet.sct"
                    name="smp.Cr.ReFillPresSet.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.ReFillPresSet.1"
                    name="smp.Cr.ReFillPresSet.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.ReFillPresSet.2"
                    name="smp.Cr.ReFillPresSet.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.ReFillPresSet.3"
                    name="smp.Cr.ReFillPresSet.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.ReFillPresSet.4"
                    name="smp.Cr.ReFillPresSet.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.ReFillPresSet.5"
                    name="smp.Cr.ReFillPresSet.5"
                    value="5"
                  >
                    ±5
                  </option>
                  <option
                    id="smp.Cr.ReFillPresSet.6"
                    name="smp.Cr.ReFillPresSet.6"
                    value="6"
                  >
                    ±6
                  </option>
                  <option
                    id="smp.Cr.ReFillPresSet.7"
                    name="smp.Cr.ReFillPresSet.7"
                    value="7"
                  >
                    ±7
                  </option>
                  <option
                    id="smp.Cr.ReFillPresSet.8"
                    name="smp.Cr.ReFillPresSet.8"
                    value="8"
                  >
                    ±8
                  </option>
                  <option
                    id="smp.Cr.ReFillPresSet.9"
                    name="smp.Cr.ReFillPresSet.9"
                    value="9"
                  >
                    ±9
                  </option>
                  <option
                    id="smp.Cr.ReFillPresSet.10"
                    name="smp.Cr.ReFillPresSet.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.ReFillPresSet.11"
                    name="smp.Cr.ReFillPresSet.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.ReFillPresSet.12"
                    name="smp.Cr.ReFillPresSet.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.ReFillPresSet.13"
                    name="smp.Cr.ReFillPresSet.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.ReFillPresSet.14"
                    name="smp.Cr.ReFillPresSet.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.ReFillPresSet.15"
                    name="smp.Cr.ReFillPresSet.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
              </td>
              <td style={{ width: "7%" }}>
                SPEED%{" "}
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ReFillSpdSet.dropdown"
                  name="smp.Cr.ReFillSpdSet.dropdown"
                  value={smpCreateState.ReFillSpdSet}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ReFillSpdSet: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.ReFillSpdSet.sct"
                    name="smp.Cr.ReFillSpdSet.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.ReFillSpdSet.1"
                    name="smp.Cr.ReFillSpdSet.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.ReFillSpdSet.2"
                    name="smp.Cr.ReFillSpdSet.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.ReFillSpdSet.3"
                    name="smp.Cr.ReFillSpdSet.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.ReFillSpdSet.4"
                    name="smp.Cr.ReFillSpdSet.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.ReFillSpdSet.5"
                    name="smp.Cr.ReFillSpdSet.5"
                    value="5"
                  >
                    ±5
                  </option>
                  <option
                    id="smp.Cr.ReFillSpdSet.6"
                    name="smp.Cr.ReFillSpdSet.6"
                    value="6"
                  >
                    ±6
                  </option>
                  <option
                    id="smp.Cr.ReFillSpdSet.7"
                    name="smp.Cr.ReFillSpdSet.7"
                    value="7"
                  >
                    ±7
                  </option>
                  <option
                    id="smp.Cr.ReFillSpdSet.8"
                    name="smp.Cr.ReFillSpdSet.8"
                    value="8"
                  >
                    ±8
                  </option>
                  <option
                    id="smp.Cr.ReFillSpdSet.9"
                    name="smp.Cr.ReFillSpdSet.9"
                    value="9"
                  >
                    ±9
                  </option>
                  <option
                    id="smp.Cr.ReFillSpdSet.10"
                    name="smp.Cr.ReFillSpdSet.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.ReFillSpdSet.11"
                    name="smp.Cr.ReFillSpdSet.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.ReFillSpdSet.12"
                    name="smp.Cr.ReFillSpdSet.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.ReFillSpdSet.13"
                    name="smp.Cr.ReFillSpdSet.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.ReFillSpdSet.14"
                    name="smp.Cr.ReFillSpdSet.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.ReFillSpdSet.15"
                    name="smp.Cr.ReFillSpdSet.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
              </td>
              <td style={{ width: "7%" }}>
                POSITION(MM)
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ReFillPosSet.dropdown"
                  name="smp.Cr.ReFillPosSet.dropdown"
                  value={smpCreateState.ReFillPosSet}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ReFillPosSet: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.ReFillPosSet.sct"
                    name="smp.Cr.ReFillPosSet.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.ReFillPosSet.1"
                    name="smp.Cr.ReFillPosSet.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.ReFillPosSet.2"
                    name="smp.Cr.ReFillPosSet.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.ReFillPosSet.3"
                    name="smp.Cr.ReFillPosSet.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.ReFillPosSet.4"
                    name="smp.Cr.ReFillPosSet.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.ReFillPosSet.5"
                    name="smp.Cr.ReFillPosSet.5"
                    value="5"
                  >
                    ±5
                  </option>
                  <option
                    id="smp.Cr.ReFillPosSet.6"
                    name="smp.Cr.ReFillPosSet.6"
                    value="6"
                  >
                    ±6
                  </option>
                  <option
                    id="smp.Cr.ReFillPosSet.7"
                    name="smp.Cr.ReFillPosSet.7"
                    value="7"
                  >
                    ±7
                  </option>
                  <option
                    id="smp.Cr.ReFillPosSet.8"
                    name="smp.Cr.ReFillPosSet.8"
                    value="8"
                  >
                    ±8
                  </option>
                  <option
                    id="smp.Cr.ReFillPosSet.9"
                    name="smp.Cr.ReFillPosSet.9"
                    value="9"
                  >
                    ±9
                  </option>
                  <option
                    id="smp.Cr.ReFillPosSet.10"
                    name="smp.Cr.ReFillPosSet.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.ReFillPosSet.11"
                    name="smp.Cr.ReFillPosSet.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.ReFillPosSet.12"
                    name="smp.Cr.ReFillPosSet.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.ReFillPosSet.13"
                    name="smp.Cr.ReFillPosSet.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.ReFillPosSet.14"
                    name="smp.Cr.ReFillPosSet.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.ReFillPosSet.15"
                    name="smp.Cr.ReFillPosSet.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
              </td>
              <td style={{ width: "7.3%" }}>
                TIME(SEC){" "}
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ReFillTmeSet.dropdown"
                  name="smp.Cr.ReFillTmeSet.dropdown"
                  value={smpCreateState.ReFillTmeSet}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ReFillTmeSet: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.ReFillTmeSet.sct"
                    name="smp.Cr.ReFillTmeSet."
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.ReFillTmeSet.0.1"
                    name="smp.Cr.ReFillTmeSet.0.1"
                    value="0.1"
                  >
                    ±0.1
                  </option>
                  <option
                    id="smp.Cr.ReFillTmeSet.0.2"
                    name="smp.Cr.ReFillTmeSet.0.2"
                    value="0.2"
                  >
                    ±0.2
                  </option>
                  <option
                    id="smp.Cr.ReFillTmeSet.0.3"
                    name="smp.Cr.ReFillTmeSet.0.3"
                    value="0.3"
                  >
                    ±0.3
                  </option>
                  <option
                    id="smp.Cr.ReFillTmeSet.0.4"
                    name="smp.Cr.ReFillTmeSet.0.4"
                    value="0.4"
                  >
                    ±0.4
                  </option>
                  <option
                    id="smp.Cr.ReFillTmeSet.0.5"
                    name="smp.Cr.ReFillTmeSet.0.5"
                    value="0.5"
                  >
                    ±0.5
                  </option>
                  <option
                    id="smp.Cr.ReFillTmeSet.0.6"
                    name="smp.Cr.ReFillTmeSet.0.6"
                    value="0.6"
                  >
                    ±0.6
                  </option>
                  <option
                    id="smp.Cr.ReFillTmeSet.0.7"
                    name="smp.Cr.ReFillTmeSet.0.7"
                    value="0.7"
                  >
                    ±0.7
                  </option>
                  <option
                    id="smp.Cr.ReFillTmeSet.0.8"
                    name="smp.Cr.ReFillTmeSet.0.8"
                    value="0.8"
                  >
                    ±0.8
                  </option>
                  <option
                    id="smp.Cr.ReFillTmeSet.0.9"
                    name="smp.Cr.ReFillTmeSet.0.9"
                    value="0.9"
                  >
                    ±0.9
                  </option>
                  <option
                    id="smp.Cr.ReFillTmeSet.1"
                    name="smp.Cr.ReFillTmeSet.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.ReFillTmeSet.2"
                    name="smp.Cr.ReFillTmeSet.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.ReFillTmeSet.3"
                    name="smp.Cr.ReFillTmeSet.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.ReFillTmeSet.4"
                    name="smp.Cr.ReFillTmeSet.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.ReFillTmeSet.5"
                    name="smp.Cr.ReFillTmeSet.5"
                    value="5"
                  >
                    ±5
                  </option>
                </select>
                *
              </td>
              <td>STAGES&#x27E1;</td>
              <td>
                PRESSURE (BAR)&#x27E1;{" "}
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ClsePresSet.dropdown"
                  name="smp.Cr.ClsePresSet.dropdown"
                  value={smpCreateState.ClsePresSet}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ClsePresSet: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.ClsePresSet.sct"
                    name="smp.Cr.ClsePresSet.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.ClsePresSet.1"
                    name="smp.Cr.ClsePresSet.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.ClsePresSet.2"
                    name="smp.Cr.ClsePresSet.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.ClsePresSet.3"
                    name="smp.Cr.ClsePresSet.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.ClsePresSet.4"
                    name="smp.Cr.ClsePresSet.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.ClsePresSet.5"
                    name="smp.Cr.ClsePresSet.5"
                    value="5"
                  >
                    ±5
                  </option>
                  <option
                    id="smp.Cr.ClsePresSet.6"
                    name="smp.Cr.ClsePresSet.6"
                    value="6"
                  >
                    ±6
                  </option>
                  <option
                    id="smp.Cr.ClsePresSet.7"
                    name="smp.Cr.ClsePresSet.7"
                    value="7"
                  >
                    ±7
                  </option>
                  <option
                    id="smp.Cr.ClsePresSet.8"
                    name="smp.Cr.ClsePresSet.8"
                    value="8"
                  >
                    ±8
                  </option>
                  <option
                    id="smp.Cr.ClsePresSet.9"
                    name="smp.Cr.ClsePresSet.9"
                    value="9"
                  >
                    ±9
                  </option>
                  <option
                    id="smp.Cr.ClsePresSet.10"
                    name="smp.Cr.ClsePresSet.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.ClsePresSet.11"
                    name="smp.Cr.ClsePresSet.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.ClsePresSet.12"
                    name="smp.Cr.ClsePresSet.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.ClsePresSet.13"
                    name="smp.Cr.ClsePresSet.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.ClsePresSet.14"
                    name="smp.Cr.ClsePresSet.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.ClsePresSet.15"
                    name="smp.Cr.ClsePresSet.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
              </td>
              <td>
                SPEED% &#x27E1;{" "}
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ClseSpdSet.dropdown"
                  name="smp.Cr.ClseSpdSet.dropdown"
                  value={smpCreateState.ClseSpdSet}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ClseSpdSet: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.ClseSpdSet.sct"
                    name="smp.Cr.ClseSpdSet.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.ClseSpdSet.1"
                    name="smp.Cr.ClseSpdSet.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.ClseSpdSet.2"
                    name="smp.Cr.ClseSpdSet.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.ClseSpdSet.3"
                    name="smp.Cr.ClseSpdSet.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.ClseSpdSet.4"
                    name="smp.Cr.ClseSpdSet.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.ClseSpdSet.5"
                    name="smp.Cr.ClseSpdSet.5"
                    value="5"
                  >
                    ±5
                  </option>
                  <option
                    id="smp.Cr.ClseSpdSet.6"
                    name="smp.Cr.ClseSpdSet.6"
                    value="6"
                  >
                    ±6
                  </option>
                  <option
                    id="smp.Cr.ClseSpdSet.7"
                    name="smp.Cr.ClseSpdSet.7"
                    value="7"
                  >
                    ±7
                  </option>
                  <option
                    id="smp.Cr.ClseSpdSet.8"
                    name="smp.Cr.ClseSpdSet.8"
                    value="8"
                  >
                    ±8
                  </option>
                  <option
                    id="smp.Cr.ClseSpdSet.9"
                    name="smp.Cr.ClseSpdSet.9"
                    value="9"
                  >
                    ±9
                  </option>
                  <option
                    id="smp.Cr.ClseSpdSet.10"
                    name="smp.Cr.ClseSpdSet.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.ClseSpdSet.11"
                    name="smp.Cr.ClseSpdSet.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.ClseSpdSet.12"
                    name="smp.Cr.ClseSpdSet.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.ClseSpdSet.13"
                    name="smp.Cr.ClseSpdSet.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.ClseSpdSet.14"
                    name="smp.Cr.ClseSpdSet.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.ClseSpdSet.15"
                    name="smp.Cr.ClseSpdSet.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
              </td>
              <td>POSITION (MM)&#x27E1;</td>
              <td>STAGES&#x27E1;</td>
              <td>
                PRESSURE (BAR)&#x27E1;{" "}
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.EjcPresSet.dropdown"
                  name="smp.Cr.EjcPresSet.dropdown"
                  value={smpCreateState.EjcPresSet}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      EjcPresSet: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.EjcPresSet.sct"
                    name="smp.Cr.EjcPresSet.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.EjcPresSet.1"
                    name="smp.Cr.EjcPresSet.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.EjcPresSet.2"
                    name="smp.Cr.EjcPresSet.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.EjcPresSet.3"
                    name="smp.Cr.EjcPresSet.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.EjcPresSet.4"
                    name="smp.Cr.EjcPresSet.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.EjcPresSet.5"
                    name="smp.Cr.EjcPresSet.5"
                    value="5"
                  >
                    ±5
                  </option>
                  <option
                    id="smp.Cr.EjcPresSet.6"
                    name="smp.Cr.EjcPresSet.6"
                    value="6"
                  >
                    ±6
                  </option>
                  <option
                    id="smp.Cr.EjcPresSet.7"
                    name="smp.Cr.EjcPresSet.7"
                    value="7"
                  >
                    ±7
                  </option>
                  <option
                    id="smp.Cr.EjcPresSet.8"
                    name="smp.Cr.EjcPresSet.8"
                    value="8"
                  >
                    ±8
                  </option>
                  <option
                    id="smp.Cr.EjcPresSet.9"
                    name="smp.Cr.EjcPresSet.9"
                    value="9"
                  >
                    ±9
                  </option>
                  <option
                    id="smp.Cr.EjcPresSet.10"
                    name="smp.Cr.EjcPresSet.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.EjcPresSet.11"
                    name="smp.Cr.EjcPresSet.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.EjcPresSet.12"
                    name="smp.Cr.EjcPresSet.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.EjcPresSet.13"
                    name="smp.Cr.EjcPresSet.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.EjcPresSet.14"
                    name="smp.Cr.EjcPresSet.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.EjcPresSet.15"
                    name="smp.Cr.EjcPresSet.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
              </td>
              <td>
                SPEED%{" "}
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.EjcSpdSet.dropdown"
                  name="smp.Cr.EjcSpdSet.dropdown"
                  value={smpCreateState.EjcSpdSet}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      EjcSpdSet: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.EjcSpdSet.sct"
                    name="smp.Cr.EjcSpdSet.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.EjcSpdSet.1"
                    name="smp.Cr.EjcSpdSet.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.EjcSpdSet.2"
                    name="smp.Cr.EjcSpdSet.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.EjcSpdSet.3"
                    name="smp.Cr.EjcSpdSet.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.EjcSpdSet.4"
                    name="smp.Cr.EjcSpdSet.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.EjcSpdSet.5"
                    name="smp.Cr.EjcSpdSet.5"
                    value="5"
                  >
                    ±5
                  </option>
                  <option
                    id="smp.Cr.EjcSpdSet.6"
                    name="smp.Cr.EjcSpdSet.6"
                    value="6"
                  >
                    ±6
                  </option>
                  <option
                    id="smp.Cr.EjcSpdSet.7"
                    name="smp.Cr.EjcSpdSet.7"
                    value="7"
                  >
                    ±7
                  </option>
                  <option
                    id="smp.Cr.EjcSpdSet.8"
                    name="smp.Cr.EjcSpdSet.8"
                    value="8"
                  >
                    ±8
                  </option>
                  <option
                    id="smp.Cr.EjcSpdSet.9"
                    name="smp.Cr.EjcSpdSet.9"
                    value="9"
                  >
                    ±9
                  </option>
                  <option
                    id="smp.Cr.EjcSpdSet.10"
                    name="smp.Cr.EjcSpdSet.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.EjcSpdSet.11"
                    name="smp.Cr.EjcSpdSet.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.EjcSpdSet.12"
                    name="smp.Cr.EjcSpdSet.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.EjcSpdSet.13"
                    name="smp.Cr.EjcSpdSet.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.EjcSpdSet.14"
                    name="smp.Cr.EjcSpdSet.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.EjcSpdSet.15"
                    name="smp.Cr.EjcSpdSet.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
              </td>
              <td>TIME (SEC)</td>
              <td>POSITION (MM)</td>
            </tr>
            <tr>
              <td>Refill 1&#x27E1;</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ReFill101"
                  name="smp.Cr.ReFill101"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.ReFill101}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ReFill101: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ReFill102"
                  name="smp.Cr.ReFill102"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.ReFill102}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ReFill102: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ReFill103"
                  name="smp.Cr.ReFill103"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.ReFill103}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ReFill103: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ReFill104"
                  name="smp.Cr.ReFill104"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.ReFill104}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ReFill104: e.target.value,
                    })
                  }
                />
              </td>
              <td>Close 1</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Close101"
                  name="smp.Cr.Close101"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Close101}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Close101: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Close102"
                  name="smp.Cr.Close102"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Close102}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Close102: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Close103"
                  name="smp.Cr.Close103"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Close103}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Close103: e.target.value,
                    })
                  }
                />
              </td>
              <td>EJEC FRD 1</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.EjecFrd101"
                  name="smp.Cr.EjecFrd101"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.EjecFrd101}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      EjecFrd101: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.EjecFrd102"
                  name="smp.Cr.EjecFrd102"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.EjecFrd102}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      EjecFrd102: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.EjecFrd103"
                  name="smp.Cr.EjecFrd103"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.EjecFrd103}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      EjecFrd103: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.EjecFrd104"
                  name="smp.Cr.EjecFrd104"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.EjecFrd104}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      EjecFrd104: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Refill 2&#x27E1;</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ReFill201"
                  name="smp.Cr.ReFill201"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.ReFill201}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ReFill201: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ReFill202"
                  name="smp.Cr.ReFill202"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.ReFill202}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ReFill202: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ReFill203"
                  name="smp.Cr.ReFill203"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.ReFill203}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ReFill203: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ReFill204"
                  name="smp.Cr.ReFill204"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.ReFill204}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ReFill204: e.target.value,
                    })
                  }
                />
              </td>
              <td>Close 2</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Close201"
                  name="smp.Cr.Close201"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Close201}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Close201: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Close202"
                  name="smp.Cr.Close202"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Close202}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Close202: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Close203"
                  name="smp.Cr.Close203"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Close203}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Close203: e.target.value,
                    })
                  }
                />
              </td>
              <td>EJEC FRD 2</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.EjecFrd201"
                  name="smp.Cr.EjecFrd201"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.EjecFrd201}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      EjecFrd201: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.EjecFrd202"
                  name="smp.Cr.EjecFrd202"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.EjecFrd202}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      EjecFrd202: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.EjecFrd203"
                  name="smp.Cr.EjecFrd203"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.EjecFrd203}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      EjecFrd203: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.EjecFrd204"
                  name="smp.Cr.EjecFrd204"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.EjecFrd204}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      EjecFrd204: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Refill 3&#x27E1;</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ReFill301"
                  name="smp.Cr.ReFill301"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.ReFill301}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ReFill301: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ReFill302"
                  name="smp.Cr.ReFill302"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.ReFill302}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ReFill302: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ReFill303"
                  name="smp.Cr.ReFill303"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.ReFill303}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ReFill303: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.ReFill304"
                  name="smp.Cr.ReFill304"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.ReFill304}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      ReFill304: e.target.value,
                    })
                  }
                />
              </td>
              <td>Close 3</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Close301"
                  name="smp.Cr.Close301"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Close301}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Close301: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Close302"
                  name="smp.Cr.Close302"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Close302}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Close302: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Close303"
                  name="smp.Cr.Close303"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Close303}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Close303: e.target.value,
                    })
                  }
                />
              </td>
              <td>EJEC RET 1</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.EjecRet101"
                  name="smp.Cr.EjecRet101"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.EjecRet101}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      EjecRet101: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.EjecRet102"
                  name="smp.Cr.EjecRet102"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.EjecRet102}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      EjecRet102: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.EjecRet103"
                  name="smp.Cr.EjecRet103"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.EjecRet103}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      EjecRet103: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.EjecRet104"
                  name="smp.Cr.EjecRet104"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.EjecRet104}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      EjecRet104: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td colSpan="3">Refill End/Short Size(MM)*:</td>

              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.RefillEnd"
                  name="smp.Cr.RefillEnd"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.RefillEnd}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      RefillEnd: e.target.value,
                    })
                  }
                />
              </td>
              <td></td>
              <td>Mould safety/Protect&#x27E1; 1</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.MldSfy101"
                  name="smp.Cr.MldSfy101"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.MldSfy101}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      MldSfy101: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.MldSfy201"
                  name="smp.Cr.MldSfy201"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.MldSfy201}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      MldSfy201: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.MldSfy301"
                  name="smp.Cr.MldSfy301"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.MldSfy301}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      MldSfy301: e.target.value,
                    })
                  }
                />
              </td>
              <td>EJEC RET 2</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.EjecRet201"
                  name="smp.Cr.EjecRet201"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.EjecRet201}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      EjecRet201: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.EjecRet202"
                  name="smp.Cr.EjecRet202"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.EjecRet202}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      EjecRet202: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.EjecRet203"
                  name="smp.Cr.EjecRet203"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.EjecRet203}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      EjecRet203: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.EjecRet204"
                  name="smp.Cr.EjecRet204"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.EjecRet204}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      EjecRet204: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td colSpan="3">
                <span>Suck Back&#x27E1;</span>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.SukSet"
                  name="smp.Cr.SukSet"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.SukSet}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      SukSet: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                />{" "}
                (MM):
              </td>

              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.SuckBack"
                  name="smp.Cr.SuckBack"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.SuckBack}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      SuckBack: e.target.value,
                    })
                  }
                />
              </td>
              <td></td>
              <td>Mould safety/Protect&#x27E1; 2</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.MldSfy102"
                  name="smp.Cr.MldSfy102"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.MldSfy102}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      MldSfy102: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.MldSfy202"
                  name="smp.Cr.MldSfy202"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.MldSfy202}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      MldSfy202: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.MldSfy302"
                  name="smp.Cr.MldSfy302"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.MldSfy302}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      MldSfy302: e.target.value,
                    })
                  }
                />
              </td>
              <td colSpan="5"></td>
            </tr>
            <tr>
              <td>Back Pres&#x27E1; 1</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Bp101"
                  name="smp.Cr.Bp101"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Bp101}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Bp101: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Bp102"
                  name="smp.Cr.Bp102"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Bp102}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Bp102: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Bp103"
                  name="smp.Cr.Bp103"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Bp103}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Bp103: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Bp104"
                  name="smp.Cr.Bp104"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Bp104}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Bp104: e.target.value,
                    })
                  }
                />
              </td>
              <td>Mould Safety Time(SEC)&#x27E1;:</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.MoldSafeT"
                  name="smp.Cr.MoldSafeT"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.MoldSafeT}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      MoldSafeT: e.target.value,
                    })
                  }
                />
              </td>
              <td>Actual:</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Actual1"
                  name="smp.Cr.Actual1"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Actual1}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Actual1: e.target.value,
                    })
                  }
                />
              </td>
              <td>STAGES&#x27E1;</td>
              <td>PRESSURE (BAR)&#x27E1;±2</td>
              <td>SPEED% ±10</td>
              <td>TIME (SEC)</td>
              <td>POSITION (MM)</td>
            </tr>

            <tr>
              <td>Back Pres&#x27E1; 2</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Bp201"
                  name="smp.Cr.Bp201"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Bp201}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Bp201: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Bp202"
                  name="smp.Cr.Bp202"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Bp202}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Bp202: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Bp203"
                  name="smp.Cr.Bp203"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Bp203}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Bp203: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Bp204"
                  name="smp.Cr.Bp204"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Bp204}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Bp204: e.target.value,
                    })
                  }
                />
              </td>
              <td colSpan="2">Clamp Force&#x27E1;</td>

              <td></td>
              <td></td>
              <td>CORE 1 IN</td>
              <td>
                {" "}
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Cre1"
                  name="smp.Cr.Cre1"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Cre1}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Cre1: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Core101"
                  name="smp.Cr.Core101"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Core101}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Core101: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Core102"
                  name="smp.Cr.Core102"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Core102}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Core102: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Core103"
                  name="smp.Cr.Core103"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Core103}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Core103: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>
                Melt Cushion(MM)*
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.CusSet.dropdown"
                  name="smp.Cr.CusSet.dropdown"
                  value={smpCreateState.CusSet}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      CusSet: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.CusSet.sct"
                    name="smp.Cr.CusSet.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.CusSet.0.1"
                    name="smp.Cr.CusSet.0.1"
                    value="0.1"
                  >
                    ±0.1
                  </option>
                  <option
                    id="smp.Cr.CusSet.0.2"
                    name="smp.Cr.CusSet.0.2"
                    value="0.2"
                  >
                    ±0.2
                  </option>
                  <option
                    id="smp.Cr.CusSet.0.3"
                    name="smp.Cr.CusSet.0.3"
                    value="0.3"
                  >
                    ±0.3
                  </option>
                  <option
                    id="smp.Cr.CusSet.0.4"
                    name="smp.Cr.CusSet.0.4"
                    value="0.4"
                  >
                    ±0.4
                  </option>
                  <option
                    id="smp.Cr.CusSet.0.5"
                    name="smp.Cr.CusSet.0.5"
                    value="0.5"
                  >
                    ±0.5
                  </option>
                  <option
                    id="smp.Cr.CusSet.0.6"
                    name="smp.Cr.CusSet.0.6"
                    value="0.6"
                  >
                    ±0.6
                  </option>
                  <option
                    id="smp.Cr.CusSet.0.7"
                    name="smp.Cr.CusSet.0.7"
                    value="0.7"
                  >
                    ±0.7
                  </option>
                  <option
                    id="smp.Cr.CusSet.0.8"
                    name="smp.Cr.CusSet.0.8"
                    value="0.8"
                  >
                    ±0.8
                  </option>
                  <option
                    id="smp.Cr.CusSet.0.9"
                    name="smp.Cr.CusSet.0.9"
                    value="0.9"
                  >
                    ±0.9
                  </option>
                  <option id="smp.Cr.CusSet.1" name="smp.Cr.CusSet.1" value="1">
                    ±1
                  </option>
                  <option id="smp.Cr.CusSet.2" name="smp.Cr.CusSet.2" value="2">
                    ±2
                  </option>
                  <option id="smp.Cr.CusSet.3" name="smp.Cr.CusSet.3" value="3">
                    ±3
                  </option>
                  <option id="smp.Cr.CusSet.4" name="smp.Cr.CusSet.4" value="4">
                    ±4
                  </option>
                  <option id="smp.Cr.CusSet.5" name="smp.Cr.CusSet.5" value="5">
                    ±5
                  </option>
                  <option id="smp.Cr.CusSet.6" name="smp.Cr.CusSet.6" value="6">
                    ±6
                  </option>
                  <option id="smp.Cr.CusSet.7" name="smp.Cr.CusSet.7" value="7">
                    ±7
                  </option>
                  <option id="smp.Cr.CusSet.8" name="smp.Cr.CusSet.8" value="8">
                    ±8
                  </option>
                  <option id="smp.Cr.CusSet.9" name="smp.Cr.CusSet.9" value="9">
                    ±9
                  </option>
                  <option
                    id="smp.Cr.CusSet.10"
                    name="smp.Cr.CusSet.10"
                    value="10"
                  >
                    ±10
                  </option>
                </select>
                :
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Melt101"
                  name="smp.Cr.Melt101"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Melt101}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Melt101: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Melt102"
                  name="smp.Cr.Melt102"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Melt102}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Melt102: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Melt103"
                  name="smp.Cr.Melt103"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Melt103}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Melt103: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Melt104"
                  name="smp.Cr.Melt104"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Melt104}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Melt104: e.target.value,
                    })
                  }
                />
              </td>
              <th style={{ textAlign: "center" }} colSpan="4">
                MOULD OPENING SETTINGS
              </th>

              <td>CORE 1 OUT</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Cre2"
                  name="smp.Cr.Cre2"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Cre2}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Cre2: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Core201"
                  name="smp.Cr.Core201"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Core201}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Core201: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Core202"
                  name="smp.Cr.Core202"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Core202}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Core202: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Core203"
                  name="smp.Cr.Core203"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Core203}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Core203: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>
                Cooling Time*
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.CoolTmeSet.dropdown"
                  name="smp.Cr.CoolTmeSet.dropdown"
                  value={smpCreateState.CoolTmeSet}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      CoolTmeSet: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.CoolTmeSet.sct"
                    name="smp.Cr.CoolTmeSet.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.CoolTmeSet.1"
                    name="smp.Cr.CoolTmeSet.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.CoolTmeSet.2"
                    name="smp.Cr.CoolTmeSet.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.CoolTmeSet.3"
                    name="smp.Cr.CoolTmeSet.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.CoolTmeSet.4"
                    name="smp.Cr.CoolTmeSet.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.CoolTmeSet.5"
                    name="smp.Cr.CoolTmeSet.5"
                    value="5"
                  >
                    ±5
                  </option>
                  <option
                    id="smp.Cr.CoolTmeSet.6"
                    name="smp.Cr.CoolTmeSet.6"
                    value="6"
                  >
                    ±6
                  </option>
                  <option
                    id="smp.Cr.CoolTmeSet.7"
                    name="smp.Cr.CoolTmeSet.7"
                    value="7"
                  >
                    ±7
                  </option>
                  <option
                    id="smp.Cr.CoolTmeSet.8"
                    name="smp.Cr.CoolTmeSet.8"
                    value="8"
                  >
                    ±8
                  </option>
                  <option
                    id="smp.Cr.CoolTmeSet.9"
                    name="smp.Cr.CoolTmeSet.9"
                    value="9"
                  >
                    ±9
                  </option>
                  <option
                    id="smp.Cr.CoolTmeSet.10"
                    name="smp.Cr.CoolTmeSet.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.CoolTmeSet.11"
                    name="smp.Cr.CoolTmeSet.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.CoolTmeSet.12"
                    name="smp.Cr.CoolTmeSet.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.CoolTmeSet.13"
                    name="smp.Cr.CoolTmeSet.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.CoolTmeSet.14"
                    name="smp.Cr.CoolTmeSet.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.CoolTmeSet.15"
                    name="smp.Cr.CoolTmeSet.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
                (Sec)
              </td>
              <td colSpan="4">
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Cooltme"
                  name="smp.Cr.Cooltme"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Cooltme}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Cooltme: e.target.value,
                    })
                  }
                />
              </td>

              <td>Open 1 &#x27E1;</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Open201"
                  name="smp.Cr.Open201"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Open201}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Open201: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Open202"
                  name="smp.Cr.Open202"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Open202}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Open202: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Open203"
                  name="smp.Cr.Open203"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Open203}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Open203: e.target.value,
                    })
                  }
                />
              </td>
              <td>CORE 2 IN</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Cre3"
                  name="smp.Cr.Cre3"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Cre3}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Cre3: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Core301"
                  name="smp.Cr.Core301"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Core301}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Core301: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Core302"
                  name="smp.Cr.Core302"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Core302}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Core302: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Core303"
                  name="smp.Cr.Core303"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Core303}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Core303: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>
                Cycle Time&#x27E1;
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.CycTmeSet.dropdown"
                  name="smp.Cr.CycTmeSet.dropdown"
                  value={smpCreateState.CycTmeSet}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      CycTmeSet: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.CycTmeSet.sct"
                    name="smp.Cr.CycTmeSet.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option
                    id="smp.Cr.CycTmeSet.1"
                    name="smp.Cr.CycTmeSet.1"
                    value="1"
                  >
                    ±1
                  </option>
                  <option
                    id="smp.Cr.CycTmeSet.2"
                    name="smp.Cr.CycTmeSet.2"
                    value="2"
                  >
                    ±2
                  </option>
                  <option
                    id="smp.Cr.CycTmeSet.3"
                    name="smp.Cr.CycTmeSet.3"
                    value="3"
                  >
                    ±3
                  </option>
                  <option
                    id="smp.Cr.CycTmeSet.4"
                    name="smp.Cr.CycTmeSet.4"
                    value="4"
                  >
                    ±4
                  </option>
                  <option
                    id="smp.Cr.CycTmeSet.5"
                    name="smp.Cr.CycTmeSet.5"
                    value="5"
                  >
                    ±5
                  </option>
                  <option
                    id="smp.Cr.CycTmeSet.6"
                    name="smp.Cr.CycTmeSet.6"
                    value="6"
                  >
                    ±6
                  </option>
                  <option
                    id="smp.Cr.CycTmeSet.7"
                    name="smp.Cr.CycTmeSet.7"
                    value="7"
                  >
                    ±7
                  </option>
                  <option
                    id="smp.Cr.CycTmeSet.8"
                    name="smp.Cr.CycTmeSet.8"
                    value="8"
                  >
                    ±8
                  </option>
                  <option
                    id="smp.Cr.CycTmeSet.9"
                    name="smp.Cr.CycTmeSet.9"
                    value="9"
                  >
                    ±9
                  </option>
                  <option
                    id="smp.Cr.CycTmeSet.10"
                    name="smp.Cr.CycTmeSet.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.CycTmeSet.11"
                    name="smp.Cr.CycTmeSet.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.CycTmeSet.12"
                    name="smp.Cr.CycTmeSet.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.CycTmeSet.13"
                    name="smp.Cr.CycTmeSet.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.CycTmeSet.14"
                    name="smp.Cr.CycTmeSet.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.CycTmeSet.15"
                    name="smp.Cr.CycTmeSet.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
                (Sec){" "}
              </td>
              <td colSpan="4">
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Cycletme"
                  name="smp.Cr.Cycletme"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Cycletme}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Cycletme: e.target.value,
                    })
                  }
                />
              </td>
              <td>Open 2 &#x27E1;</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Open301"
                  name="smp.Cr.Open301"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Open301}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Open301: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Open302"
                  name="smp.Cr.Open302"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Open302}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Open302: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Open303"
                  name="smp.Cr.Open303"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Open303}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Open303: e.target.value,
                    })
                  }
                />
              </td>
              <td>CORE 2 OUT</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Cre4"
                  name="smp.Cr.Cre4"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Cre4}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Cre4: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Core401"
                  name="smp.Cr.Core401"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Core401}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Core401: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Core402"
                  name="smp.Cr.Core402"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Core402}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Core402: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Core403"
                  name="smp.Cr.Core403"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Core403}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Core403: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>
                Oil Temp&#x27E1;
                <select
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.OilSet.dropdown"
                  name="smp.Cr.OilSet.dropdown"
                  value={smpCreateState.OilSet}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      OilSet: e.target.value,
                    })
                  }
                  style={{ backgroundColor: "#efd9b1" }}
                >
                  <option
                    id="smp.Cr.OilSet.sct"
                    name="smp.Cr.OilSet.sct"
                    value=""
                  >
                    Select
                  </option>
                  <option id="smp.Cr.OilSet.1" name="smp.Cr.OilSet.1" value="1">
                    ±1
                  </option>
                  <option id="smp.Cr.OilSet.2" name="smp.Cr.OilSet.2" value="2">
                    ±2
                  </option>
                  <option id="smp.Cr.OilSet.3" name="smp.Cr.OilSet.3" value="3">
                    ±3
                  </option>
                  <option id="smp.Cr.OilSet.4" name="smp.Cr.OilSet.4" value="4">
                    ±4
                  </option>
                  <option id="smp.Cr.OilSet.5" name="smp.Cr.OilSet.5" value="5">
                    ±5
                  </option>
                  <option id="smp.Cr.OilSet.6" name="smp.Cr.OilSet.6" value="6">
                    ±6
                  </option>
                  <option id="smp.Cr.OilSet.7" name="smp.Cr.OilSet.7" value="7">
                    ±7
                  </option>
                  <option id="smp.Cr.OilSet.8" name="smp.Cr.OilSet.8" value="8">
                    ±8
                  </option>
                  <option id="smp.Cr.OilSet.9" name="smp.Cr.OilSet.9" value="9">
                    ±9
                  </option>
                  <option
                    id="smp.Cr.OilSet.10"
                    name="smp.Cr.OilSet.10"
                    value="10"
                  >
                    ±10
                  </option>
                  <option
                    id="smp.Cr.OilSet.11"
                    name="smp.Cr.OilSet.11"
                    value="11"
                  >
                    ±11
                  </option>
                  <option
                    id="smp.Cr.OilSet.12"
                    name="smp.Cr.OilSet.12"
                    value="12"
                  >
                    ±12
                  </option>
                  <option
                    id="smp.Cr.OilSet.13"
                    name="smp.Cr.OilSet.13"
                    value="13"
                  >
                    ±13
                  </option>
                  <option
                    id="smp.Cr.OilSet.14"
                    name="smp.Cr.OilSet.14"
                    value="14"
                  >
                    ±14
                  </option>
                  <option
                    id="smp.Cr.OilSet.15"
                    name="smp.Cr.OilSet.15"
                    value="15"
                  >
                    ±15
                  </option>
                </select>
                °C
              </td>
              <td colSpan="4">
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Oilt"
                  name="smp.Cr.Oilt"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Oilt}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Oilt: e.target.value,
                    })
                  }
                />
              </td>
              <td>Open 3 &#x27E1;</td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Open401"
                  name="smp.Cr.Open401"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Open401}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Open401: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Open402"
                  name="smp.Cr.Open402"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Open402}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Open402: e.target.value,
                    })
                  }
                />
              </td>
              <td>
                <input
                  onKeyDown={(e) => {
                    // e.preventDefault();
                    FindKeyPress(e.target.id, e.code);
                  }}
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  id="smp.Cr.Open403"
                  name="smp.Cr.Open403"
                  readOnly={smpCreateState.viewMode}
                  type="text"
                  value={smpCreateState.Open403}
                  onChange={(e) =>
                    setSmpCreateState({
                      ...smpCreateState,
                      Open403: e.target.value,
                    })
                  }
                />
              </td>
              <td colSpan="5"></td>
            </tr>
            <tr>
              <td colSpan={3}>
                Prepared By<span style={{ color: "red" }}>*</span>
              </td>
              <td colSpan={4}> <DropdownFetcher keyword="emp_details_dropdown_all" setValue={setEmployee} />
                <Select
                  isDisabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}
                  styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                  value={smpCreateState.PreparedBy}
                  onChange={handlePreparedBy}
                  options={Employee.map((i) => ({
                    value: i.Emp_code,
                    label: i.Emp_name,
                  }))}
                /></td>
              <td colSpan={2}>Approver<span style={{ color: "red" }}>*</span></td>
              <td colSpan={5}>
                {/* <DropdownFetcher keyword="emp_details_dropdown_all" setValue={setEmployee} /> */}
                <Select
                  isDisabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === ""}

                  styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                  value={smpCreateState.ApprovedBy}
                  onChange={handleApprovedBy}
                  options={Employee.map((i) => ({
                    value: i.Emp_code,
                    label: i.Emp_name,
                  }))}
                /></td>
            </tr>
            <tr >
              <td colSpan="14" style={{ border: "none" }}>
                <button
                  disabled={smpCreateState.McNo === "" || smpCreateState.PrtNme === "" || smpCreateState.MatNme === "" || smpCreateState.NoCav === "" || smpCreateState.GrdNme === "" || smpCreateState.Colour === "" || smpCreateState.Runn === "" || smpCreateState.Modl === "" || smpCreateState.PreparedBy === "" || smpCreateState.ApprovedBy === ""}

                  type="submit"
                  id="smp.cr.submit"
                  name="smp.cr.submit"
                  onClick={(e) => handleCreate(e)}
                >
                  Submit
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>

      <div></div>
    </div>
  );
}

export default SmpCreate;
