import React, { useState, useEffect } from "react";

import Popup from "../../../Components/Popup/Popup";
import HandleRead from "../../../Lib/HandleRead.js";
import Pagination from "../../../Components/Pagination";
import Base64Convertor from "../../../Lib/Base64Convertor.js";

import {
  SECRET_KEY,
  PROCESS_TRANSACTION_UPDATE_URL,
} from "../../../Utils/constants.js";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import UpdateRecord from "../../../Lib/updateRecord";
import { MdDelete } from "react-icons/md";
import "../../../Styles/Part3.css";
import ReadRecord from "../../../Lib/API/ReadRecord.js";

//import "bootstrap/dist/css/bootstrap.min.css";

function EmployeeMasterView() {
  const [isCreating, setIsCreating] = useState(false);
  const [empMasterAddState, setEmpMasterAddState] = useState({
    SelectedOption: "",
    EmpCde: "",
    EmpNme: "",
    EmpDept: "",
    Loc: "",
    Desgn: "",
    Img: "",
    Gen: "",
    Sts: "",
    Flexy1: "",
    Flexy2: "",
    Flexy3: "",
    Flexy4: "",
    isPaginationVisible: true,
    currentPage: 1,
    Id: "",
    filterEmpnm: "",
    filterEmpcd: ""
  });

  const [emp_mas, setemp_mas] = useState([]);
  const [ButtonPopup2, setButtonPopup2] = useState(false);
  const [ButtonPopup, setButtonPopup] = useState(false);

  const recordPerPage = 20;
  const filterRecords = () => {
    const filteredData = emp_mas.filter((record) => {
      const EmpnmMatch = record.Emp_name
        .toLowerCase()
        .includes(empMasterAddState.filterEmpnm.toLowerCase());
      const EmpcdMatch = record.Emp_code
        .toLowerCase().
        includes(empMasterAddState.filterEmpcd.toLowerCase());
      return EmpnmMatch && EmpcdMatch;
    });
    return filteredData || [];
  };
  const filteredData = filterRecords();
  const npage = Math.ceil(filteredData.length / recordPerPage);
  const numbers = [...Array(npage).keys()].map((num) => num + 1);

  function handleFilterEmpnoChange(event) {
    setEmpMasterAddState({
      ...empMasterAddState,
      currentPage: 1,
      filterEmpnm: event.target.value
    });
  }
  function handleFilterEmpcdChange(event) {
    setEmpMasterAddState({
      ...empMasterAddState,
      currentPage: 1,
      filterEmpcd: event.target.value
    });
  }

  const firstIndex = (empMasterAddState.currentPage - 1) * recordPerPage;
  const lastIndex = firstIndex + recordPerPage;
  const filteredRecords = filteredData.slice(firstIndex, lastIndex);

  function prePage() {
    if (empMasterAddState.currentPage > 1) {
      setEmpMasterAddState({
        ...empMasterAddState,
        currentPage: empMasterAddState.currentPage - 1,
      });
    }
  }

  function changeCpage(id) {
    setEmpMasterAddState({
      ...empMasterAddState,
      currentPage: id,
    });
  }
  function nextPage() {
    if (empMasterAddState.currentPage < npage) {
      setEmpMasterAddState({
        ...empMasterAddState,
        currentPage: empMasterAddState.currentPage + 1,
      });
    }
  }

  const transferValue2 = (e) => {
    setEmpMasterAddState({
      ...empMasterAddState,
      isPaginationVisible: false,

      EmpCde: e.Emp_code,
      EmpNme: e.Emp_name,
      EmpDept: e.Emp_department,
      Loc: e.Emp_location,
      Desgn: e.Emp_designation,
      Img: e.Emp_image,
      Gen: e.Emp_gender,
      Sts: e.Emp_status,
      Flexy1: e.Emp_flex_01,
      Flexy2: e.Emp_flex_02,
      Flexy3: e.Emp_flex_03,
      Flexy4: e.Emp_flex_04,
    });
    setButtonPopup2(true);
  };

  const viewPagination = () => {
    setEmpMasterAddState({
      ...empMasterAddState,
      isPaginationVisible: true,
    });
  };

  const handleEdit = (e) => {
    setEmpMasterAddState({
      ...empMasterAddState,
      isPaginationVisible: false,
      Id: e.Id,
      EmpCde: e.Emp_code,
      EmpNme: e.Emp_name,
      EmpDept: e.Emp_department,
      Loc: e.Emp_location,
      Desgn: e.Emp_designation,
      Img: e.Emp_image,
      Gen: e.Emp_gender,
      Sts: e.Emp_status,
      Flexy1: e.Emp_flex_01,
      Flexy2: e.Emp_flex_02,
      Flexy3: e.Emp_flex_03,
      Flexy4: e.Emp_flex_04,
    });
    setButtonPopup(true);
  };
  const handleUpdate = (e) => {
    e.preventDefault();

    if (!empMasterAddState.EmpCde || !empMasterAddState.EmpNme) {
      setIsCreating(true);

      alert(GeneralConstants.MANDATORY_ALERT);
      return;
    }
    const data = {
      data: [
        {
          Id: empMasterAddState.Id,
          Emp_code: empMasterAddState.EmpCde,
          Emp_name: empMasterAddState.EmpNme,
          Emp_department: empMasterAddState.EmpDept,
          Emp_location: empMasterAddState.Loc,
          Emp_designation: empMasterAddState.Desgn,
          Emp_image: empMasterAddState.Img,
          Emp_gender: empMasterAddState.Gen,
          Emp_status: empMasterAddState.Sts,

          Emp_flex_01: empMasterAddState.Flexy1,
          Emp_flex_02: empMasterAddState.Flexy2,
          Emp_flex_03: empMasterAddState.Flexy3,
          Emp_flex_04: empMasterAddState.Flexy4,
        },
      ],
    };
    data["_keyword_"] = "emp_details";
    data["secretkey"] = SECRET_KEY;
    getAPIRequest(PROCESS_TRANSACTION_UPDATE_URL, "POST", data)
      .then((response) => {
        setEmpMasterAddState({
          ...empMasterAddState,
          SelectedOption: "",
          EmpCde: "",
          EmpNme: "",
          EmpDept: "",
          Loc: "",
          Desgn: "",
          Img: "",
          Gen: "",
          Sts: "",

          Flexy1: "",
          Flexy2: "",
          Flexy3: "",
          Flexy4: "",
          isPaginationVisible: true,

        });
        // setemp_mas([...emp_mas, response.data]);
        console.log(response.data);
        alert(`Employee master code ${GeneralConstants.UPDATE_SUCCESSFUL}`);
        // window.location.reload();
        handleReadEmployeeMaster();
        setButtonPopup(false);
      })
      .catch((error) => {
        console.log(error);
        alert(GeneralConstants.UPDATE_FAILURE);
        window.location.reload();


      });
  };

  const handlePhotoChangeAfter = async (e) => {
    const selectedFile = e.target.files[0];
    let img = await Base64Convertor(selectedFile);
    setEmpMasterAddState({
      ...empMasterAddState,
      Img: img,
    })
  };
  function handleReadEmployeeMaster() {
    ReadRecord({
      _keyword_: "emp_details"
    }).then((res) => {
      // console.log(res.data);
      setemp_mas(res.data);
    });
  }
  useEffect(() => {
    handleReadEmployeeMaster();
  }, [])
  return (
    <div>
      <Popup
        trigger={ButtonPopup2}
        setTrigger={setButtonPopup2}
        viewPagination={viewPagination}
      >
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <form
          id="empmv.view.container"
          name="empmv.view.container"
          className="foemp-empMaster-View"
        >
          <table
            id="empmv.view.table"
            name="empmv.view.table"
            style={{ textAlign: "center", width: "100%" }}
            cellPadding="5"
          >
            <tbody>
              <tr>
                <th
                  id="empmv.Title.View"
                  name="empmv.Title.View"
                  height="20%"
                  colSpan="4"
                  style={{ textAlign: "center", whiteSpace: "nowrap" }}
                >
                  <font color="#8B0000">View - Employee Details</font>
                </th>
              </tr>
              <tr>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Employee Code
                </th>
                <td width="25%">
                  <label id="empmv.view.empCode" name="empmv.view.empCode">
                    {empMasterAddState.EmpCde}
                  </label>
                </td>

                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Employee Name
                </th>
                <td width="25%">
                  <label id="empmv.view.empName" name="empmv.view.empName">
                    {empMasterAddState.EmpNme}
                  </label>
                </td>
              </tr>
              <tr>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Employee Department
                </th>
                <td>
                  <label
                    id="empmv.view.empDepartment"
                    name="empmv.view.empDepartment"
                    width="25%"
                  >
                    {empMasterAddState.EmpDept}
                  </label>
                </td>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Location
                </th>
                <td>
                  <label
                    id="empmv.view.empLocation"
                    name="empmv.view.empLocation"
                    width="25%"
                  >
                    {empMasterAddState.Loc}
                  </label>
                </td>
              </tr>
              <tr>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Designation
                </th>
                <td>
                  <label
                    id="empmv.view.empDesignation"
                    name="empmv.view.empDesignation"
                    width="25%"
                  >
                    {empMasterAddState.Desgn}
                  </label>
                </td>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Image
                </th>
                <td>
                  {empMasterAddState.Img ? <img
                    src={empMasterAddState.Img}
                    alt="Selected"
                    style={{
                      display: "block",
                      width: "150px",
                      height: "150px",
                    }}
                  /> : ""}
                </td>
              </tr>
              <tr>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Gender
                </th>
                <td>
                  <label
                    id="empmv.view.empGender"
                    name="empmv.view.empGender"
                    width="25%"
                  >
                    {empMasterAddState.Gen}
                  </label>
                </td>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Status
                </th>
                <td>
                  <label
                    id="empmv.view.empStatus"
                    name="empmv.view.empStatus"
                    width="25%"
                  >
                    {empMasterAddState.Sts}
                  </label>
                </td>
              </tr>

              <tr>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Flex 1
                </th>
                <td>
                  <label
                    id="empmv.view.empFlex1"
                    name="empmv.view.empFlex1"
                    width="25%"
                  >
                    {empMasterAddState.Flexy1}
                  </label>
                </td>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Flex 2
                </th>
                <td>
                  <label
                    id="empmv.view.empFlex2"
                    name="empmv.view.empFlex2"
                    width="25%"
                  >
                    {empMasterAddState.Flexy2}
                  </label>
                </td>
              </tr>

              <tr>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Flex 3
                </th>
                <td>
                  <label
                    id="cmv.view.empFlex3"
                    name="cmv.view.empFlex3"
                    width="25%"
                  >
                    {empMasterAddState.Flexy3}
                  </label>
                </td>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Flex 4
                </th>
                <td>
                  <label
                    id="empmv.view.empFlex4"
                    name="empmv.view.empFlex4"
                    width="25%"
                  >
                    {empMasterAddState.Flexy4}
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </Popup>

      <Popup
        trigger={ButtonPopup}
        setTrigger={setButtonPopup}
        viewPagination={viewPagination}
      >
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <form
          id="empmv.edit.container"
          name="empmv.edit.container"
          className="foemp-empMaster-Edit"
        >
          <table
            style={{ textAlign: "center", width: "100%" }}
            cellPadding="5"
          >
            <tbody>
              <tr>
                <th
                  id="empmv.Title.Edit"
                  name="empmv.Title.Edit"
                  colSpan="4"
                  style={{ textAlign: "center", whiteSpace: "nowrap" }}
                >
                  <font color="#8B0000">Edit - Employee Master</font>
                </th>
              </tr>
              <tr>
                <th>
                  Employee Code<span style={{ color: "red" }}>*</span>
                </th>
                <td>
                  <input
                    id="empmv.edit.empCode"
                    name="empmv.edit.empCode"
                    type="text"
                    value={empMasterAddState.EmpCde}
                    onChange={(e) =>
                      setEmpMasterAddState({
                        ...empMasterAddState,
                        EmpCde: e.target.value,
                      })
                    }
                    disabled
                  />
                </td>
                <th>
                  Employee Name<span style={{ color: "red" }}>*</span>
                </th>
                <td>
                  {empMasterAddState.EmpNme === '' && isCreating && <span className="Req">Required *</span>}
                  <input
                    id="empmv.edit.Name"
                    name="empmv.edit.empName"
                    type="text"
                    value={empMasterAddState.EmpNme}
                    onChange={(e) =>
                      setEmpMasterAddState({
                        ...empMasterAddState,
                        EmpNme: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>Employee Department</th>
                <td>
                  <input
                    id="empmv.edit.empDepartment"
                    name="empmv.edit.empDepartment"
                    type="text"
                    value={empMasterAddState.EmpDept}
                    onChange={(e) =>
                      setEmpMasterAddState({
                        ...empMasterAddState,
                        EmpDept: e.target.value,
                      })
                    }
                  />
                </td>

                <th>Location</th>
                <td>
                  <input
                    id="empmv.edit.empLocation"
                    name="empmv.edit.empLocation"
                    type="text"
                    value={empMasterAddState.Loc}
                    onChange={(e) =>
                      setEmpMasterAddState({
                        ...empMasterAddState,
                        Loc: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>Designation</th>
                <td>
                  <input
                    id="empmv.edit.empDesignation"
                    name="empmv.edit.empDesignation"
                    type="text"
                    value={empMasterAddState.Desgn}
                    onChange={(e) =>
                      setEmpMasterAddState({
                        ...empMasterAddState,
                        Desgn: e.target.value,
                      })
                    }
                  />
                </td>

                <th>Image</th>
                <td>
                  <input
                    id="empmc.EmployeeImage"
                    name="empmc.EmployeeImage"
                    type="file"
                    accept="Image/*"
                    onChange={handlePhotoChangeAfter}

                  />
                  {empMasterAddState.Img ? <img
                    src={empMasterAddState.Img}
                    alt="Selected"
                    style={{
                      display: "block",
                      width: "150px",
                      height: "150px",
                    }}
                  /> : ""}
                </td>
              </tr>
              <tr>
                <th>&nbsp;&nbsp;Gender<span style={{ color: "red" }}>*</span></th>
                <td>
                  <div>
                    <label className="emp-gender">
                    {empMasterAddState.Gen === '' && isCreating && <span className="Req">Required *</span>}
                      <input
                        type="radio"
                        name="gender"
                        value="male"
                        checked={empMasterAddState.Gen === "male"}
                        onChange={(e) =>
                          setEmpMasterAddState({
                            ...empMasterAddState,
                            Gen: e.target.value,
                          })
                        }
                      />
                      Male&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </label>
                    <label className="emp-gender">
                      <input
                        type="radio"
                        name="gender"
                        value="female"
                        checked={empMasterAddState.Gen === "female"}
                        onChange={(e) =>
                          setEmpMasterAddState({
                            ...empMasterAddState,
                            Gen: e.target.value,
                          })
                        }
                      />
                      Female&nbsp;&nbsp;&nbsp;&nbsp;
                    </label>
                    <label className="emp-gender">
                      <input
                        type="radio"
                        name="gender"
                        value="others"
                        checked={empMasterAddState.Gen === "others"}
                        onChange={(e) =>
                          setEmpMasterAddState({
                            ...empMasterAddState,
                            Gen: e.target.value,
                          })
                        }
                      />
                      Others
                    </label>
                  </div>
                </td>
                <th>Status</th>
                <td>
                  <input
                    id="empmv.edit.empStatus"
                    name="empmv.edit.empStatus"
                    type="text"
                    value={empMasterAddState.Sts}
                    onChange={(e) =>
                      setEmpMasterAddState({
                        ...empMasterAddState,
                        Sts: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>

              <tr>
                <th>Flex 1</th>
                <td>
                  <input
                    id="empmv.edit.empFlex1"
                    name="empmv.edit.empFlex1"
                    type="text"
                    value={empMasterAddState.Flexy1}
                    onChange={(e) =>
                      setEmpMasterAddState({
                        ...empMasterAddState,
                        Flexy1: e.target.value,
                      })
                    }
                  />
                </td>
                <th>Flex 2</th>
                <td>
                  <input
                    id="empmv.edit.empFlex2"
                    name="empmv.edit.empFlex2"
                    type="text"
                    value={empMasterAddState.Flexy2}
                    onChange={(e) =>
                      setEmpMasterAddState({
                        ...empMasterAddState,
                        Flexy2: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>

              <tr>
                <th>Flex 3</th>
                <td>
                  <input
                    id="cmv.edit.empFlex3"
                    name="mmv.edit.empFlex3"
                    type="text"
                    value={empMasterAddState.Flexy3}
                    onChange={(e) =>
                      setEmpMasterAddState({
                        ...empMasterAddState,
                        Flexy3: e.target.value,
                      })
                    }
                  />
                </td>
                <th>Flex 4</th>
                <td>
                  <input
                    id="empmv.edit.empFlex4"
                    name="empmv.edit.empFlex4"
                    type="text"
                    value={empMasterAddState.Flexy4}
                    onChange={(e) =>
                      setEmpMasterAddState({
                        ...empMasterAddState,
                        Flexy4: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>

              <tr>
                <td colSpan="4" style={{ border: "none" }}>
                  <button
                    id="empmv.update"
                    name="empmv.update"
                    type="submit"
                    style={{ border: "none" }}
                    onClick={(e) => handleUpdate(e)}
                  >
                    Update
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </Popup>

      <div>
        <table
          style={{ textAlign: "center", width: "100%" }}
        /*{ cellPadding="5" }*/
        >
          <thead>
            <tr>
              <th
                id="empmv.Title.List"
                name="empmv.Title.List"
                colSpan="9"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">List - Employee Master </font>
              </th>
            </tr>
            <tr>
              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                Employee Name
              </td>
              <td>
                <input
                  id="empmv.filter.Empname"
                  name="empmv.filter.Empname"
                  type="text"
                  placeholder="Filter Employee Name"
                  style={{ fontSize: "80%", width: "100%" }}
                  value={empMasterAddState.filterEmpnm}
                  onChange={handleFilterEmpnoChange}
                />
              </td>
              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                Employee Code
              </td>
              <td>
                <input
                  id="empmv.filter.Empcode"
                  name="empmv.filter.Empcode"
                  type="text"
                  placeholder="Filter Employee Code"
                  style={{ fontSize: "80%", width: "100%" }}
                  value={empMasterAddState.filterEmpcd}
                  onChange={handleFilterEmpcdChange}
                />
              </td>
              <td colSpan={5}></td>
            </tr>
            <tr className="table_position">

              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Employee Code&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Image&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Employee Name&nbsp;&nbsp;
              </th>

              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Designation&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Department&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Gender&nbsp;&nbsp;
              </th>
              {/* <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Status&nbsp;&nbsp;
              </th> */}

              {/* <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Gender&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Status&nbsp;&nbsp;
              </th> */}

              {/* <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Flex-1&nbsp;&nbsp;
              </th> */}
              {/*
			  <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Flex-2&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Flex-3&nbsp;&nbsp;
              </th>*/}
              {/* <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Created Date&nbsp;&nbsp;
              </th> */}
              <th width="10%"></th>
              <th width="10%"></th>
              <th width="10%"></th>
            </tr>
          </thead>
          <tbody>
            {/* <HandleRead keyword="emp_details" setData={setemp_mas} /> */}
            {filteredRecords.length === 0 ? (
              <td colSpan="12" style={{ textAlign: "center" }}>
                <p id="mmv.noRecordFound" name="mmv.noRecordFound" style={{ fontWeight: "bold" }}>No records found.</p>
              </td>
            ) : (
              filteredRecords.map((ele) => (
                <tr className="table_view" key={ele.Id}>

                  <td
                    id="empmv.list.empCode"
                    name="empmv.list.empCode"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{ele.Emp_code}
                  </td>
                  <td
                    id="empmv.list.empImage"
                    name="empmv.list.empImage"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{ele.Emp_image ? <img
                      src={ele.Emp_image}
                      alt="Selected"
                      style={{
                        display: "block",
                        width: "50px",
                        height: "50px",
                      }}
                    /> : ""}
                  </td>
                  <td
                    id="empmv.list.empName"
                    name="empmv.list.empName"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{ele.Emp_name}
                  </td>
                  <td
                    id="empmv.list.empDesignation"
                    name="empmv.list.empDesignation"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{ele.Emp_designation}
                  </td>
                  <td
                    id="empmv.list.empdepartment"
                    name="empmv.list.empdepartment"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{ele.Emp_department}
                  </td>
                  <td
                    id="empmv.list.empGender"
                    name="empmv.list.empGender"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{ele.Emp_gender}
                  </td>

                  {/* 
<td id="empmv.list.empGender"
name="empmv.list.empGender" className="table-cell">&nbsp;&nbsp;{ele.mc_name}</td>
    <td id="empmv.list.empStatus"
name="empmv.list.empStatus" className="table-cell">&nbsp;&nbsp;{ele.mc_name}</td> */}
                  {/* <td id="empmv.list.empGradeCode"
name="empmv.list.empCode"
className="table-cell">&nbsp;&nbsp;{ele.mc_version}</td>
    <td id="empmv.list.flex1"
className="table-cell">&nbsp;&nbsp;{ele.mc_version}</td>
    <td id="empmv.list.flex1"
className="table-cell">&nbsp;&nbsp;{ele.mc_version}</td>
    <td id="empmv.list.flex1"
className="table-cell">&nbsp;&nbsp;{ele.mc_version}</td> */}
                  {/* <td id="empmv.list.flex1"
name="empmv.list.flex1"
className="table-cell">&nbsp;&nbsp;{ele.mc_flex_01}</td> */}
                  {/*
    <td className="table-cell">&nbsp;&nbsp;{emp_mas.mc_flex_02}</td>
    <td className="table-cell">&nbsp;&nbsp;{emp_mas.mc_flex_03}</td>
        */}
                  {/* <td id="empmv.list.createddate"
name="empmv.list.createddate"
className="table-cell">&nbsp;&nbsp;{ele.mc_cr_dt}</td> */}
                  <td>
                    <button
                      id="empmv.view"
                      name="empmv.view"
                      style={{ backgroundColor: "#cba423", whiteSpace: "nowrap" }}
                      type="button"
                      onClick={() => transferValue2(ele)}
                    >
                      View&#x1F441;
                    </button>
                  </td>
                  <td>
                    <button
                      id="empmv.edit"
                      name="empmv.edit"
                      type="button"
                      onClick={() => handleEdit(ele)}
                    >
                      Edit&#x270E;
                    </button>
                  </td>
                  <td>
                    <button
                      id={`emp_details-Delete`}
                      name={`emp_details-Delete`}
                      style={{ whiteSpace: "nowrap", backgroundColor: "#DA251A" }}
                      type="button"
                      onClick={() => {
                        const userConfirmed = window.confirm('Are you sure you want to delete?');
                        if (userConfirmed) {
                          UpdateRecord({
                            _keyword_: 'emp_details',
                            data: [
                              {
                                Id: ele.Id,
                                ActiveStatus: 'InActive',
                              },
                            ],
                          }).then(() => {
                            handleReadEmployeeMaster();
                          })
                          // window.location.reload();
                        }
                      }}
                    >
                      Delete<MdDelete />
                    </button>
                  </td>
                </tr>
              )))}
          </tbody>
        </table>
        <div
          className="pagination"
          id="empmv.pagination"
          name="empmv.pagination"
        >
          {empMasterAddState.isPaginationVisible && filteredData.length > 0 && (
            <Pagination
              currentPage={empMasterAddState.currentPage}
              npage={npage}
              prePage={prePage}
              nextPage={nextPage}
              changeCpage={changeCpage}
              numbers={numbers}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default EmployeeMasterView;
