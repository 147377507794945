import React, { useState, useEffect } from "react";
import "../../../Styles/Report4M.css";

import Unitechlogo from "../../../icons/Unitechlogo.jpeg";
import {
  SECRET_KEY,
  CODEGEN_URL,
  PROCESS_TRANSACTION_URL,
} from "../../../Utils/constants.js";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import {
  CURRENT_Date,
  CURRENT_TIME,
  SHIFTMOLDINGPRODUCTION,
} from "../../../Utils/GeneralConstants";

import useScanDetection from "use-scan-detection";

import DatePicker from "../../../Lib/DatePicker.js";
import DropdownFetcher from "../../../Lib/DropdownFetcher.js";
import DropdownFilterSelect from "../../../Components/DropdownFilterSelectFetcher.js";
import Select from "react-select";
import ReadRecord from "../../../Lib/API/ReadRecord.js";
import { Dropdown } from "react-bootstrap";


const initialState = {
  TrackDate: new Date().toISOString().split("T")[0],
  MachineNo: "",
  PartName: "",
  Material: "",
  LumpKg: "",
  LumpRegKg: "",
  Reasn: "",
  Rmrks: "",
  inchrg:"",
  selected:"",
  inchrgname:"",
  Shft: SHIFTMOLDINGPRODUCTION,
  lumps_mon: [],
};
function LUMPMatCreate() {
  const [LumpCreate, setLumpCreate] = useState(initialState);
  const [partDropdown, setPartDropdown] = useState([]);
  const [dropdown, setDropdown] = useState([]);
  const [Rmdropdown, setRmDropdown] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [Name, setname] = useState([]);
  const [selectedinchrg, setSelectedinchrg] = useState(null);

  //Unique id and name
  const id = "LUMPCreate";
  const name = "LUMPCreate";

  // const CodeGen = async () => {
  //   try {
  //     const val = {
  //       tablename: "lumps_mon",
  //       PrimaryCode: "Machine_no",
  //       PrimaryVal: LumpCreate.MachineNo,
  //       FieldCode: "Part_name",
  //       FieldVal: LumpCreate.PartName,
  //     };
  //     console.log(val);
  //     const response = await getAPIRequest(CODEGEN_URL, "POST", val);
  //     const response1 = await response.json();
  //     console.log("response1", response1);
  //     return response1.success === "true";
  //   } catch (error) {
  //     throw error;
  //   }
  // };
  useEffect(() => {
    console.log(LumpCreate,)
     })
  useEffect(() => {
    
    document.title = "LUMP"
  }, [])
  const handleCreate = async (e) => {
    e.preventDefault();
 

    const isSubmissionSuccessful = true;

  //   if (!LumpCreate.PartName || !LumpCreate.MachineNo || !LumpCreate.Material) {
  //     setIsCreating(true);
  //     alert(GeneralConstants.MANDATORY_ALERT);
  //     return;
  //  }
    try {
      // const isDuplicate = await CodeGen();

      // if (isDuplicate) {
      //   setIsCreating(false);
      //   alert(
      //     `${GeneralConstants.ALREADY_EXIST}`
      //   );
      //   setLumpCreate({ ...LumpCreate, ...initialState });
      //   return;
      // }
      const data = {
        data: [
          {
            TrackDate: new Date().toISOString().split("T")[0],
            Machine_no: LumpCreate.MachineNo.value,
            Part_name: LumpCreate.PartName.value,
            Shift : SHIFTMOLDINGPRODUCTION,
            Material: LumpCreate.Material.value,
            Lumps_kg: LumpCreate.LumpKg,
            Lumps_reg_kg: LumpCreate.LumpRegKg,
            Reason: LumpCreate.Reasn,
            Remarks: LumpCreate.Rmrks,
            Incharge_Sign: LumpCreate.inchrg,
            Incharge_Sign_name: LumpCreate.inchrgname
          },
        ],
      };
      data["_keyword_"] = "LumpsMon";
      data["secretkey"] = SECRET_KEY;

      const response = await getAPIRequest(
        PROCESS_TRANSACTION_URL,
        "POST",
        data
      );
      const response1 = await response.json();

      if (response1.success === "false") {
        alert(GeneralConstants.FAIL_TO_SAVE_MACHINE);
        window.location.reload();
      } else {
        const moldLoadUnload = [
          ...LumpCreate.lumps_mon,
          response.data,
        ];
        setLumpCreate({
          ...LumpCreate,
          lumps_mon: moldLoadUnload,
        });
      }
      if (isSubmissionSuccessful) {
        alert(`${GeneralConstants.SUBMISSION_SUCCESSFUL}`);
        setLumpCreate({ ...LumpCreate, ...initialState });
        setIsCreating(false);
        return;
      }
    } catch (error) {
      console.log(error);
      alert(`${GeneralConstants.SUBMISSION_FAILED}`);
    //  window.location.reload();
    }
  };

  const partNoOptions = partDropdown.map((i) => ({
    value: i.mc_part_name,
    label: i.mc_part_name,
  }));
  const mcNoOptions = dropdown.map((i) => ({
    value: i.mh_mac_name,
    label: i.mh_mac_name,
  }));
  const rmOptions = Rmdropdown.map((i) => ({
    value: i.Mh_raw_mat,
    label: i.Mh_raw_mat_name,
  }));
  const handleDateChange = (newDate) => {
    setLumpCreate({ ...LumpCreate, TrackDate: newDate });
  };
  const handleDropdownChange = (selectedOption) => {
    const selectedMachine = partDropdown.find(
      (option) => option.mc_part_name === selectedOption.label
    );

    if (selectedMachine) {
      setLumpCreate({
        ...LumpCreate,
        PartName: {
          value: selectedMachine.mc_part_name,
          label: selectedMachine.mc_part_name,
        } 
        // MachineNo: selectedMachine.mc_part_name,
        // TrackDate: new Date().toISOString().split("T")[0],
        // Shift: determineShift(),
      });
    } else{
      alert("Part is not available in SMP!")
    }
  };
  // useEffect(() => {
  //   setLumpCreate({
  //     ...LumpCreate,
  //     TrackDate: new Date().toISOString().split("T")[0],

  //   });


  // },[]);
  // barcode scannning to get input
  useScanDetection({
    onComplete: (code) => {
      code = code.replaceAll("Shift", "");
      setField(code);
    },
    minLength: 3,

  });

  // set value to the appropiate field
  function setField(code) {
    let isnumber = Number(code).toString() !== "NaN";
    let current_element = document.activeElement.id;

    if (code.includes("EMP")) {
      // setEmployeeName(code);
      alert("Invalid Input");
      return false;
    } else
      if (code.includes("RMI")) {
        if (current_element === "" || current_element === "react-select-4-input") {
     LumpCreate.MachineNo && HandleRawMaterial({value:code})
          // setLumpCreate({
          //   ...LumpCreate,
          //   Material: code,
          // })
        } else {
          alert("Invalid Input");
          return false;
        }
      } else if (code.includes("CUS")) {
        alert("Invalid Input");
        return false;

      } else if (code.includes("FG")) {
        alert("Invalid Input");
        return false;

      } else if (code.includes("IMM")) {
        if (current_element === "" || current_element === "react-select-2-input") {
          HandleMachine({value:code})
          setLumpCreate({
            ...LumpCreate,
            MachineNo: code,
          })
        } else {
          alert("Invalid Input");
          return false;
        }
      } else if (code.includes("SUP")) {
        alert("Invalid Input");
        return false;

      } else {
        // setLumpCreate({
        //   ...LumpCreate,
        //   PartName: code})
        // GetSelectValue1(code, "mac_part_dtl_with_model_dropdown")
        LumpCreate.MachineNo && handleDropdownChange({value:code})
      }

  }
  function GetSelectValue(value, keyword, setName) {
    if (value.length > 0) {
      ReadRecord({
        _keyword_: keyword,
        key: "%" + value + "%",
        value: "%" + value + "%"
      }).then((res) => {
        console.log(res.data);
        setName(res.data);
        // setKey(keyvalue[options.reference]);
      });
    }
  }
  function GetSelectValue1(value, keyword) {
      ReadRecord({
        _keyword_: keyword,
        key: "%" + value + "%",
        value: "%" + value + "%"
      }).then((res) => {
        console.log("res",res);
        if(res.rows>0){
          setLumpCreate({
            ...LumpCreate,
            PartName: {value:res.data[0].mc_part_name,label:res.data[0].mc_part_name},})
        }else{
          alert("Not Available")
        }
      });
    
  }

  useEffect(() => {
    if (LumpCreate.MachineNo) {
      console.log(LumpCreate.MachineNo);
      Promise.all([
        ReadRecord({
          _keyword_: "Get_part_from_smp_by_machine",
          machine: LumpCreate.MachineNo.value,
        }),
        ReadRecord({
          _keyword_: "Get_raw_material_by_machine",
          machine: LumpCreate.MachineNo.value,
        })
      ]).then(([partRes, materialRes]) => {
        if (partRes.rows === "1") {
          setLumpCreate((prevState) => ({
            ...prevState,
            PartName: {
              value: partRes.data[0].mc_part_name,
              label: partRes.data[0].mc_part_name,
            },
          }));
        } else if (partRes.rows > "1") {
          setPartDropdown(partRes.data);
        } else {
          alert("Contact Administrator to add Part for this Selected Machine!");
        }
  
        if (materialRes.rows === "1") {
          setLumpCreate((prevState) => ({
            ...prevState,
            Material: {
              value: materialRes.data[0].Mh_raw_mat,
              label: materialRes.data[0].Mh_raw_mat_name,
            },
          }));
        } else if (materialRes.rows > "1") {
          setRmDropdown(materialRes.data);
        } else {
          alert("Contact Administrator to add Raw Material for this Selected Machine!");
        }
      }).catch(error => {
        console.error("Error fetching data:", error);
      });
    }
  }, [LumpCreate.MachineNo]);
  
  // function GetPartByMachine(val){
  //   if (LumpCreate.MachineNo)
  //   console.log(LumpCreate.MachineNo)
  //   ReadRecord({
  //     _keyword_: "Get_part_from_smp_by_machine",
  //     machine: val,
  //   }).then((res) => {
  //     if(res.rows=="1"){
  //       setLumpCreate({          
  //         PartName: {
  //           value: res.data[0].mc_part_name,
  //           label: res.data[0].mc_part_name,
  //         } 
  //       });
  //     }
  //     else if(res.rows > "1") {
  //       setPartDropdown(res.data);
  //     } else {
  //       alert("Contact Administrator to add Part for this Selected Machine!");
  //     }
  //   })
  // }
  function GetRawMaterial(val){
    ReadRecord({
      _keyword_: "Get_raw_material_by_machine",
      machine: val,
    }).then((res) => {
      if(res.rows=="1"){
        // let record = [...LumpCreate];
        setLumpCreate({ 
          Material: {value:res.data[0].Mh_raw_mat,label:res.data[0].Mh_raw_mat_name},
        });  
        // return record      
      }
      else if (res.rows > "1") {
        setRmDropdown(res.data);
      } else {
        alert("Contact Administrator to add Raw Material for this Selected Machine!");
      }
    })
  }
  const HandleRawMaterial=(selectedOption)=>{
    console.log("selectedOption",selectedOption);
    const selectedRaw = Rmdropdown.find(
      (option) => option.Mh_raw_mat === selectedOption.value
    );
    console.log("selectedMachine",selectedRaw);
    if (selectedRaw) {
      setLumpCreate({
        ...LumpCreate,
        Material: {value:selectedRaw.Mh_raw_mat,label:selectedRaw.Mh_raw_mat_name},        
      });
      
    } else {
      alert("Selected Raw Material is not available in SMP!");
    }
  }
const HandleMachine= (selectedOption) => {
  // GetPartByMachine(selectedOption.value);
  // GetRawMaterial(selectedOption.value);

  const selectedMachine = dropdown.find(
    (option) => option.mh_mac_name === selectedOption.value
  );
  if (selectedMachine) {
    setLumpCreate({
      ...LumpCreate,
      MachineNo: {value:selectedMachine.mh_mac_name,label:selectedMachine.mh_mac_name},
      PartName: "",
      Material: "",
    });
    
  } else {
    alert("Selected Machine is not available in SMP!");
  }
};
const handleIncharge = (selectedOption) => {
  setLumpCreate({
    ...LumpCreate,
    selected: selectedOption,
    inchrg: selectedOption ? selectedOption.value : "",
    inchrgname: selectedOption.label
  });
};

  return (
    <div>
      <form
        id="lumpsmon-forMachineNoontainer-Create"
        name="lumpsmon-forMachineNoontainer-Create"
        className="form-lumpsmon-Create"

      >
        <table style={{ textAlign: "center" }}>
          <tbody>
            <tr>
              {/* <th style={{ textAlign: "center", width: "15%" }}>
                  <img src={Unitechlogo} style={{ width: "70px" }}></img>
                </th> */}
              <th
                id={`${id}-Title`}
                name={`${name}-Title`}
                colSpan="7"
                style={{
                  textAlign: "center",
                  whiteSpace: "nowrap",
                  padding: "10px 0px",
                }}
              >
                {/* <font style={{ fontSize: "24px" }}>
                    UNITECH PLASTO COMPONENTS (P) LTD
                  </font>
                  <br></br> */}
                <font color="#8B0000">LUMPS MONITORING CHECK SHEET</font>
              </th>
            </tr>
            <tr>
              {/*  <td className="topic-head">&nbsp;&nbsp;S.NO</td>
            <td>
              <input type="no"
              value={LumpCreate.Sno}
              onChange={(e) => setSno(e.target.value)}
              /></td>  */}
              <td className="topic-head">&nbsp;&nbsp;DATE</td>
              <td>
                <DatePicker
                  value={LumpCreate.TrackDate}
                  onChange={handleDateChange}
                  dis={true}
                />
              </td>{" "}
              <td className="topic-head" colSpan={1}>
                Shift
              </td>
              <td colSpan={1}>
                <select
                  value={LumpCreate.Shft} 
                  onChange={(e) =>
                    setLumpCreate({
                      ...LumpCreate,
                      Shft: e.target.value,
                    })
                  }
                  
                  style={{ textAlign: "center" }}
                  disabled
                >
                  <option
                    id={`${id}-Shift-Select`}
                    name={`${name}-Shift-Select`}
                    value=""
                  >
                    -- Select --
                  </option>
                  <option id={`${id}-ShiftA`} name={`${name}-ShiftA`} value="A">
                    A12
                  </option>
                  <option id={`${id}-ShiftB`} name={`${name}-ShiftB`} value="B">
                    B12
                  </option>
                </select>
              </td>

            </tr>
            <tr>
              {" "}
              <td className="topic-head">
                &nbsp;&nbsp;MACHINE<span style={{ color: "red" }}>*</span>
              </td>
              <td>
                {/* <input
                  type="text"
                  value={LumpCreate.MachineNo}
                  onChange={(e) =>
                    setLumpCreate({
                      ...LumpCreate,
                      MachineNo: e.target.value,
                    })
                  }
                /> */}
                {/* <DropdownFetcher
                  keyword="mac_dtl_dropdown"
                  setValue={setDropdown}
                /> */}
                {/* <DropdownFilterSelect value={LumpCreate.MachineNo} onChange={(e) =>
                  setLumpCreate({
                    ...LumpCreate,
                    MachineNo: e.value,
                  })
                } options={mcNoOptions} placeholder="Select Machine No" /> */}
                <Select
                    value={LumpCreate.MachineNo}
                    onChange={HandleMachine}
                    // onChange={(e) =>
                    //   setLumpCreate({
                    //     ...LumpCreate,
                    //     MachineNo: e.value,
                    //   })
                    // }
                    onInputChange={(event) => { GetSelectValue(event, "Get_machine_from_smp", setDropdown) }}
                    options={mcNoOptions}
                    placeholder="Search Machine No..."
                    // isDisabled={prb === ""}
                  />
                {LumpCreate.MachineNo === '' && isCreating && <span className="Req">Required *</span>}

              </td>
              <td className="topic-head">
                &nbsp;&nbsp;PART NAME<span style={{ color: "red" }}>*</span>
              </td>
              <td>
                {/* <input
                  type="text"
                  value={LumpCreate.PartName}
                  onChange={(e) =>
                    setLumpCreate({
                      ...LumpCreate,
                      PartName: e.target.value,
                    })
                  }
                /> */}
                {/* <DropdownFetcher
                  keyword="mac_part_dtl_dropdown"
                  setValue={setPartDropdown}
                /> */}

                <Select value={LumpCreate.PartName} onChange={handleDropdownChange} options={partNoOptions}
                  // onInputChange={(event) => { GetSelectValue(event, "mac_part_dtl_dropdown", setPartDropdown) }}
                  placeholder="Search Part No..."
                  isDisabled={LumpCreate.MachineNo===""}
                />
                {LumpCreate.PartName === '' && isCreating && <span className="Req">Required *</span>}
              </td>
            </tr>
            <tr>
              {" "}
              <td className="topic-head">
                &nbsp;&nbsp;MATERIAL<span style={{ color: "red" }}>*</span>
              </td>
              <td>
                {/* <input
                  type="text"
                  value={LumpCreate.Material}
                  onChange={(e) =>
                    setLumpCreate({
                      ...LumpCreate,
                      Material: e.target.value,
                    })
                  }
                /> */}
                {/* <DropdownFetcher
                  keyword="rm_details_dropdown"
                  setValue={setRmDropdown}
                /> */}
                
                {/* <DropdownFilterSelect value={LumpCreate.Material} onChange={(e) =>
                  setLumpCreate({
                    ...LumpCreate,
                    Material: e.value,
                  })
                } options={rmOptions} placeholder="Search RM..."
                  // onInputChange={(event) => { GetSelectValue(event, "rm_details_dropdown", setRmDropdown) }}

                /> */}
                <Select
                    value={LumpCreate.Material}
                    onChange={HandleRawMaterial} 
                    options={rmOptions}
                    placeholder="Search RM..."
                    isDisabled={LumpCreate.MachineNo === ""}
                  />
                {LumpCreate.Material === '' && isCreating && <span className="Req">Required *</span>}
              </td>{" "}
              <td className="topic-head">&nbsp;&nbsp;LUMPS(KGS)</td>
              <td>
                <input
                  type="number"
                  value={LumpCreate.LumpKg}
                  onChange={(e) =>
                    setLumpCreate({
                      ...LumpCreate,
                      LumpKg: e.target.value,
                    })
                  }
                />
              </td>{" "}
            </tr>
            <tr>
              {" "}
              <td className="topic-head">&nbsp;&nbsp;LUMPS REJECTION(KGS)</td>
              <td>
                <input
                  type="number"
                  value={LumpCreate.LumpRegKg}
                  onChange={(e) =>
                    setLumpCreate({
                      ...LumpCreate,
                      LumpRegKg: e.target.value,
                    })
                  }
                />
              </td>{" "}
              <td className="topic-head">&nbsp;&nbsp;REASON</td>
              <td>
                <textarea
                  style={{ height: "auto" }}
                  cols={50}
                  value={LumpCreate.Reasn}
                  onChange={(e) =>
                    setLumpCreate({
                      ...LumpCreate,
                      Reasn: e.target.value,
                    })
                  }
                />
              </td>{" "}
             
              </tr>
            <tr>
            <td className="topic-head">&nbsp;&nbsp;REMARKS</td>
              <td>
                <textarea
                  style={{ height: "auto" }}
                  cols={50}
                  value={LumpCreate.Rmrks}
                  onChange={(e) =>
                    setLumpCreate({
                      ...LumpCreate,
                      Rmrks: e.target.value,
                    })
                  }
                />
              </td>
              <td className="topic-head">In-Charge Sign :</td>
              <td colSpan={1}>
                <Select
                  id={`${id}-EmployeeName`}
                  value={LumpCreate.selected}
                  options={Name.map((employee) => ({
                    value: employee.Emp_code,
                    label: employee.Emp_name,
                  }))}
                  onChange={handleIncharge}
                  onInputChange={(event) => { GetSelectValue(event, "emp_details_dropdown", setname) }}
                  // isDisabled={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                />
              </td></tr>
          

            <tr>
              <td colSpan="5" align="center" style={{ border: "none" }}>
                <button
                  type="submit"
                  id={`${id}-submit`}
                  name={`${name}-submit`}
                  onClick={(e) => handleCreate(e)}
                  disabled={LumpCreate.MachineNo === "" || LumpCreate.PartName === "" || LumpCreate.Material === ""}
                  hidden={LumpCreate.MachineNo === "" || LumpCreate.PartName === "" || LumpCreate.Material === ""}
                >
                  Submit
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
}

export default LUMPMatCreate;
