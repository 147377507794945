import React, { useEffect, useState, useRef } from "react";
import Field from "../../../Components/Fields/Field";
import Lable from "../../../Components/Fields/Lable";
import { useNavigate, useParams } from "react-router-dom";
import "./LayoutInspection.css";
import CreateRecord from "../../../Lib/API/CreateRecord";
import ReadRecord from "../../../Lib/API/ReadRecord";
import UpdateRecord from "../../../Lib/API/UpdateRecord";
import CreateApproval from "../../../Lib/CreateApproval";
import { setMessage } from "../../../Lib/SetMessage";
import QaLayout from "../QaLayout";
import { CURRENT_Date, SHIFTMOLDINGQUALITY } from "../../../Utils/GeneralConstants";
import LoadingPage from "../../../Components/Handler/LoadingPage";
import { Dropdown } from 'react-bootstrap';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import html2pdf from 'html2pdf.js';
import Logo from '../../../icons/Logo1.png';
import * as XLSX from 'xlsx';
import { useScrollEffect } from "../useScrollEffect";
const LayoutInspectionForm = () => {
  const contentRef = useRef(null);
  const CURRENT_TIME = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[1].substring(0, 5);
  const InitialOb = { Specifications_loc:"",Specification_val: "",Specification_minus:"",Specification_plus:"", Observation_x5: "", Observation_x4: "", Observation_x3: "", Observation_x2: "", Observation_x1: "", Judgement: "", Inspection_item: "", Checking_aid: "" }
  const InitRoot = [
    {
      download: false,
      // Prodcutionincharge: [{}],
      // Machineno: [{}],
      Rawmaterialname: [{}],
      Partname: [{}],
      PartNumber: [{}],
      Opt: "",
      // Onlineqasign: [{}],
      // Qasign: [{}],
      // Prodcutionoperatorname: [{}],
      Part_name: "",
      Raw_material_name: "",
      // Machine_no: "",
      // complaints: ["1", "2"],
      // cavity: [1, 2, 3, 4, 5, 6],
      Image: "",
      observations_functional: [InitialOb],
      // observations_functional: [
      //   {},
      //   {},
      //   {},
      //   {},
      //   {},
      //   {},
      //   {},
      //   {},
      //   {},
      //   {},
      //   {},
      //   {},
      //   {},
      //   {},
      //   {},
      //   {},
      //   {},
      //   {},
      //   {},
      // ],
      // change_details: [{}],
      // visual_inspection: [{}, {}, {}, {}, {}],
      // customer_complaints: [{}, {}],
      Inspection_quantity: "",
      No_of_cav: "",
      Total_cav: "",
      observations: [1],
      Entry_date: CURRENT_Date,
      Part_number: "",     
    },
  ];
  const tableRef = useRef(null);
  const scrollTop = useRef(null);
  useScrollEffect();  
  const [downloadEnabled, setDownloadEnabled] = useState(true);
  const [Records, setRecords] = useState(InitRoot);
  const [warning, setwarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Raw, setRaw] = useState([]);
  useEffect(() => {
    console.log("Records", Records);
  }, [Records]);

  const handleIncrease = () => {
    setRecords((prevRecords) => {
      let Records = [...prevRecords];
      // const { observations } = Records[0];
      Records[0].observations_functional.push(InitialOb);

      // if (observations && observations.length > 0) {
      //   const numberOfArrays = observations.length + 1;
      //   Records[0] = {
      //     ...Records[0],
      //     observations: Array.from({ length: numberOfArrays }, (_, i) => i + 1),
      //     observations_functional: Array.from({ length: numberOfArrays }, (_, i) => ({})),
      //   };
      // }
      //    console.log(Records[0].observations, Records[0].observations_functional);

      return Records;
    });
  };



  const { option, id } = useParams();
  const navigate = useNavigate();

  function HandleRecords(Event) {
    setRecords([{ ...Records[0], [Event.target.name]: Event.target.value }]);
  }

  function HandleRecordSelect(Event, column, value, label) {
    setRecords(() => {
      let record = [...Records];
      record[0][Event.name] = Event.values.value;
      record[0][column][0][value] = Event.values.value;
      record[0][column][0][label] = Event.values.label;
      return record;
    });
  }async function HandlePartCode(Event) {    
    Records[0].Part_name = "";
    Records[0].No_of_cav = "";
    Records[0]["Partname"][0]["Part_name"] = "";
    Records[0].Raw_material_name = "",
    Records[0]["Rawmaterialname"][0]["Mh_raw_mat"] = "";
    Records[0]["Rawmaterialname"][0]["Mh_raw_mat_name"] = "";
    console.log("Event", Event.values.value);
    // GetRawMaterial(Event.values.value);
    // if (await IsDuplicate("quality_product_plan", [`part_number="${Event.values.value}"`])) {
    ReadRecord({
      _keyword_: "Get_part_name_from_quality_plan_by_code",
      part: Event.values.value,
    }).then((res) => {
      if (res.rows === "1") {
        setRecords(() => {
          //       console.log("Event.values.value", Event.values.value);
          let record = [...Records];
          record[0].Part_number = Event.values.value,
            record[0]["PartNumber"][0]["Part_number"] = Event.values.value;
          record[0]["PartNumber"][0]["Part_number"] = Event.values.label;
          record[0].Part_name = res.data[0].Part_name,
            record[0]["Partname"][0]["Part_name"] = res.data[0].Part_name;
          record[0]["Partname"][0]["Part_name"] = res.data[0].Part_name;
          console.log("12131213", record);
          GetModelPart(res.data[0].Part_name);
          // GetMachineNo(res.data[0].Part_name);
          GetRawMaterial(res.data[0].Part_name);          
          return record;
          
        });
      } else if (res.rows > "1") {
        console.log("res.data", res.data);
        setRecords(() => {
          let record = [...Records];
          record[0].Part_number = Event.values.value,
            record[0]["PartNumber"][0]["Part_number"] = Event.values.value;
          record[0]["PartNumber"][0]["Part_number"] = Event.values.label;          
          return record;
        })
      }

    });
    // } else {
    //   alert("Part Number already Has Record in Quality Plan Records")
    // }

  }
  function HandlePartname(Event) {
  //   setRecords([
  //     {
  //       ...Records[0],
  //       Part_number: Event.values.value,
  //       [Event.name]: Event.values.value,
  //     },
  //   ]);
    GetPartCodeByName(Event.values.value);
    GetRawMaterial(Event.values.value);
    GetModelPart(Event.values.value); 
  }
  function GetPartCodeByName(val) {
    ReadRecord({
      _keyword_: "Get_part_code_from_quality_plan_by_name",
      mc_part_code: val,
    }).then((res) => {
      if (res.rows == "1") {
        setRecords(() => {
          //       console.log("Event.values.value", Event.values.value);
          let record = [...Records];
          record[0].Part_number = res.data[0].Part_number,
          record[0]["PartNumber"][0]["Part_number"] = res.data[0].Part_number;
          record[0]["PartNumber"][0]["Part_number"] = res.data[0].Part_number;
          return record;
        })
      } else if (res.rows > "1") {

      }
    });
  }
  function GetModelPart(val){    
    ReadRecord({
      _keyword_: "Get_model_by_part_name",
      part: val,
    }).then((res) => {
      if(res.rows>0){
      console.log("respose of raw", res.data);
      setRecords(() => {
        let record = [...Records];
        record[0].No_of_cav = res.data[0].mc_part_flex_02;
        // record[0].Part_colour = res.data[0].mc_part_flex_03;
        // record[0].Part_number = res.data[0].mc_part_code;
        return record;
      });
    }
    })
  }
  function GetRawMaterial(val) {
    ReadRecord({
      _keyword_: "Get_raw_material_by_part",
      part: val,
    }).then((res) => {
      if (res.rows == "1") {
        console.log("respose of raw", res.data);
        setRecords(() => {
          //       console.log("Event.values.value", Event.values.value);
          let record = [...Records];
          record[0].Raw_material_name = res.data[0].Mh_raw_mat,
          record[0].Part_colour = res.data[0].Rm_Colour,
            record[0]["Rawmaterialname"][0]["Mh_raw_mat"] = res.data[0].Mh_raw_mat;
          record[0]["Rawmaterialname"][0]["Mh_raw_mat_name"] = res.data[0].Mh_raw_mat_name;
          return record;
        })
      } else if( res.rows > "1"){
        setRaw(res.data);
        // alert("Contact Administrator to add Raw Material for this Selected Part!");
      }
    })
  }

  function HandleObservation(Event, index) {
    setRecords((item) => {
      let record = [...Records];
      record[0].observations_functional[index] = {
        ...record[0].observations_functional[index],
        [Event.target.name]: Event.target.value,
      };
      return record;
    });
  }



  function HandleCreate(event) {
    if (
      !Records[0].Part_name ||
      !Records[0].Part_number ||
      !Records[0].Raw_material_name ||
      !Records[0].Inspection_quantity ||
      !Records[0].No_of_cav ||
      !Records[0].Total_cav
    ) {
      alert("Please Fill the Mandatory Fields");
      setwarning(true);
    } else {
      let record = [...Records];
      record && delete record[0].Partname;
      record && delete record[0].PartNumber;
      record && delete record[0].Rawmaterialname;
      record && delete record[0].download;
      record && delete record[0].observations;

      CreateRecord("quality_inline_layout_inspection", record).then((res) => {
        console.log("HandleCreate", res, record);
      });
      alert("Submited Successfully");
      setwarning(false);
      setRecords(InitRoot);
      const img = document.getElementById('Image');
      console.log("img", img);
      if (img) {
        img.setAttribute('src', '');
        img.value = "";
      } else {
        console.error("Image element not found", img);
      }
      if (scrollTop.current) {
        scrollTop.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }

  function HandleUpdate(event) {
    let record = [...Records];
    record && delete record[0].Partname;
    record && delete record[0].PartNumber;
    record && delete record[0].Rawmaterialname;
    record && delete record[0].download;
    record && delete record[0].observations;

    UpdateRecord("quality_inline_layout_inspection", record).then((res) => {
      console.log("HandleUpdate", res);
    });
    alert("Record Updated!");
    setwarning(false);
    setRecords(InitRoot);
    navigate(-1);
  }

  useEffect(() => {
    setwarning(false);
    document.title = "Layout Inspection Report";
    const fetchData = async () => {
      if (id === undefined) {
        setRecords([
          {
            ...Records[0],
            Entry_date: CURRENT_Date,
          },
        ]);
      } else {
        setLoading(true);
        try {
          const res = await
            ReadRecord({
              _keyword_: "quality_inline_layout_inspection",
              Id: id,
            })
          // console.log(res.data[0]);
          if (res.data[0] === undefined) {
            setRecords(InitRoot);
          } else {
            console.log(res.data[0].observations_functional.length);
            const dynamicobservation = res.data[0].observations_functional || [];
            res.data[0].observations = Array.from({ length: dynamicobservation.length }, (_, i) => i + 1);
            setRecords(res.data);
          }

        } finally {
          setLoading(false);
        }
      }
    }
    fetchData();
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  const generatePDF = () => {
    document.querySelector('.myTable').style.display = 'table';
    const opt = {
      margin: 0.7,
      filename: 'Layout.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, height: contentRef.current.offsetHeight, width: contentRef.current.offsetWidth },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    html2pdf().from(contentRef.current).set(opt).save();
    setTimeout(() => {
      document.querySelector('.myTable').style.display = 'none';
    }, 1000);
  };
  const onButtonClick = () => {
  
    setDownloadEnabled(false);
    
    const dropdown = document.querySelector('.LayoutInspecDownload');
    if (dropdown) {
      dropdown.style.display = 'none';
    }
    const pdf = new jsPDF("portrait", "mm", "a4");
    html2canvas(tableRef.current).then((canvas) => {
      if (dropdown) {
        dropdown.style.display = 'block';
      }

      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 5, 5, 200, 200);
      pdf.setTextColor(50, 50, 50);
      pdf.save(`LayoutInspection.pdf`);
      setDownloadEnabled(true);
    });
  };
  
    const data = Records ? Array.from({ length: Records[0].observations.length }, (_, index) => {
      const observations = Records[0]?.observations_functional[index];
      const { Inspection_item } = observations;
      const column2 = Inspection_item;
      const { Checking_aid} = observations;
      const column3 = Checking_aid;
      const { Specifications_loc, Specification_val, Specification_minus, Specification_plus } = observations;
      const column4 = `${Specifications_loc}*${Specification_val}(-${Specification_minus}/+${Specification_plus})`;
      const {Observation_x1} = observations;
      const column5 = Observation_x1;
      const {Observation_x2} = observations;
      const column6 = Observation_x2;
      const {Observation_x3} = observations;
      const column7 = Observation_x3;
      const {Observation_x4} = observations;
      const column8 = Observation_x4;
      const {Observation_x5} = observations;
      const column9 = Observation_x5;
      const {Judgement} = observations;
      const column10 = Judgement;
    return {
        id: index + 1,
        column1: (index + 1).toString(), 
         column2: column2, 
         column3: column3,
         column4: column4,
         column5: column5,
         column6: column6,
         column7: column7,
         column8: column8,
         column9: column9,    
         column10:column10,
    };
        }) : [];
     const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
  const wsData = [
     ["", "Layout Inspection", "", "", "", "", ""],
     ["Part Number:", Records[0].Part_number, "Part Name:", Records[0].Part_name],
     ["Opt:",Records[0].Opt,"Date:", Records[0].Entry_date],
     ["Rawmaterial:", Records[0].Rawmaterialname[0].Mh_raw_mat_name,"Inspection Quantity:",Records[0].Inspection_quantity],
     ["Total Cavity:",Records[0].Total_cav,"No Of Cavity:",Records[0].No_of_cav],
     ["Inspection Reason:",Records[0].Inspection_reason],
     ["S.No","Inspection Item","Checking Aid","Specification","","","Observation","","","Judgement"],
     ["","","","","X1","X2","X3","X4","X5",""],]
     data.forEach(row => {
      wsData.push([
        row.column1,    
        row.column2,  
        row.column3,
        row.column4,
        row.column5,    
        row.column6,  
        row.column7,
        row.column8,
        row.column9,
        row.column10,
      ]);
      });
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');  
    XLSX.writeFile(wb, 'LayoutInspection.xlsx');
    };
  return (
    <QaLayout record={Records} quary={"quality_inline_layout_inspection"}>
      <div className="excel"> 
   
   {downloadEnabled && (option === "view") &&( 
    <Dropdown style={{ width: '50%', al: "center" }} >
      <Dropdown.Toggle style={{color:"black"}}>
        Download ⬇️
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item>
          <button className="custom-button"style={{color: "black"}} onClick={generatePDF} >
            PDF ⬇️
          </button>
        </Dropdown.Item>
        <Dropdown.Item>
          
        <button className="custom-button"style={{color: "black"}} onClick={() => exportToExcel()}>
      Export to Excel
    </button>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>)}
</div>
      <div className="Form" ref={scrollTop}>
      <div ref={tableRef} >
        <table>
          <tbody>
            <tr>
              <th colSpan={Records[0].download ? 21 : 26}>
                <Lable value={"Layout Inspection Report"} type={"Head"} />
              </th>
              
            </tr>
            <tr>
              <td colSpan={6}>
              {/* <Field
                  label={"Part Number"}
                  name={"Part_number"}
                  options={{ type: "text", disabled: true }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                /> */}
                <Field
                  label={"Part Number"}
                  name={"Part_number"}
                  options={{
                    type: "reference",
                    reference: "Get_part_no_from_Quality_plan",
                    required: true,
                    disabled: option === "view" || option === "edit",
                  }}
                  warning={warning}
                  value={{
                    value: Records[0].PartNumber[0]
                      ? Records[0].PartNumber[0].Part_number
                      : "",
                    label: Records[0].PartNumber[0]
                      ? Records[0].PartNumber[0].Part_number
                      : "",
                  }}
                  OnChange={(e) => {
                    HandleRecordSelect(
                      e,
                      "PartNumber",
                      "Part_number",
                      "Part_number"
                    );
                    HandlePartCode(e);
                  }}
                />
              </td>

              <td colSpan={20}>
              <Field
                  label={"Part Name"}
                  name={"Part_name"}
                  options={{
                    type: "reference",
                    key: Records[0].Part_number,
                    reference: "Get_part_name_from_Quality_plan",
                    required: true,
                    disabled: option === "view" || option === "edit",
                  }}
                  warning={warning}
                  value={{
                    value: Records[0].Partname[0]
                      ? Records[0].Partname[0].Part_name
                      : "",
                    label: Records[0].Partname[0]
                      ? Records[0].Partname[0].Part_name
                      : "",
                  }}
                  OnChange={(e) => {
                    HandleRecordSelect(
                      e,
                      "Partname",
                      "Part_name",
                      "Part_name"
                    );
                    HandlePartname(e);
                  }}
                />
                
              </td>
            </tr>
            <tr>
              <td colSpan={6}>
                
                <Field
                  label={"Raw Material Name"}
                  name={"Raw_material_name"}
                  options={{
                    type: "NoneReference",
                    reference: "Raw_material",
                    values: Raw,
                    required: true,
                    disabled: option === "view",
                  }}
                  warning={warning}
                  value={{
                    value: Records[0].Rawmaterialname[0]
                      ? Records[0].Rawmaterialname[0].Mh_raw_mat
                      : "",
                    label: Records[0].Rawmaterialname[0]
                      ? Records[0].Rawmaterialname[0].Mh_raw_mat_name
                      : "",
                  }}
                  OnChange={(e) => {
                    HandleRecordSelect(
                      e,
                      "Rawmaterialname",
                      "Mh_raw_mat",
                      "Mh_raw_mat_name"
                    );
                  }}
                />
              </td>
              <td colSpan={20}>
                <Field
                  label={"Inspection Quantity"}
                  name={"Inspection_quantity"}
                  options={{ type: "text", disabled: option === "view", required: true }}
                  warning={warning}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={6}>
                <Field
                  label={"Opt"}
                  name={"Opt"}
                  options={{ type: "text", disabled: option === "view" }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
              <td colSpan={20}>
                <Field
                  label={"Date"}
                  name={"Entry_date"}
                  options={{ type: "date", disabled: option !== "edit" }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={6}>
                <Field
                  label={"Total Cavity"}
                  name={"Total_cav"}
                  options={{ type: "text", disabled: option === "view", required: true }}
                  warning={warning}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
              <td colSpan={20}>
                <Field
                  label={"No Of Cavity"}
                  name={"No_of_cav"}
                  options={{ type: "text", disabled: option === "view", required: true }}
                  warning={warning}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={26}>
                <Field
                  label={"Inspection Reason"}
                  name={"Inspection_reason"}
                  doubleLine={false}
                  options={{
                    type: "SingleChoice",
                    disabled: option === "view",
                    values: [
                      "New Development",
                      "Source Change",
                      "Material Change",
                      "Specification Change",
                      "New Mould",
                      "Sample Inspection",
                      "Regular Lot",
                      "Mould Correction",
                    ],
                  }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={24}>
                <Field
                  label={"Image"}
                  name={"Image"}
                  accept="image/*,application/pdf"
                  options={{ type: "image", disabled: option === "view" }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                />
              </td>
            </tr>

            <tr>
              <th colSpan={1} rowSpan={2}>
                <Lable value={"S.No"} type={"Head"} />
              </th>
              <th colSpan={2} rowSpan={2}>
                <Lable value={"Inspection Item"} type={"Head"} />
              </th>
              <th colSpan={2} rowSpan={2}>
                <Lable value={"Checking Aid"} type={"Head"} />
              </th>
              <th colSpan={3} rowSpan={2}>
                <Lable value={"Specification"} type={"Head"} />
              </th>
              <th colSpan={15}>
                <Lable value={"Observation"} type={"Head"} />
              </th>
              <th colSpan={3} rowSpan={2}>
                <Lable value={"Judgement"} type={"Head"} />
              </th>
            </tr>
            <th colSpan={3}>
              <Lable value={"X1"} type={"Head"} />
            </th>
            <th colSpan={3}>
              <Lable value={"X2"} type={"Head"} />
            </th>
            <th colSpan={3}>
              <Lable value={"X3"} type={"Head"} />
            </th>
            <th colSpan={3}>
              <Lable value={"X4"} type={"Head"} />
            </th>
            <th colSpan={3}>
              <Lable value={"X5"} type={"Head"} />
            </th>
            {Array.from({ length: Records[0].observations_functional.length }, (_, i) => i + 1).map((item) => (
              <tr>
                <td style={{ width: "5%", textAlign: "center" }} colSpan={1}>
                  {item}
                </td>
                <td style={{ width: "10%" }} colSpan={2}>
                  <Field
                    name={"Inspection_item"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "Angle", label: "Angle" },
                        { value: "Dimension", label: "Dimension" },
                        { value: "Thickness", label: "Thickness" },
                      ],
                    }}
                    value={Records[0].observations_functional[Number(item) - 1]}
                    OnChange={(e) => {
                      HandleObservation(e, [Number(item) - 1]);
                    }}
                  />
                </td>
                <td style={{ width: "10%" }} colSpan={2}>
                  <Field
                    name={"Checking_aid"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "Vernier", label: "Vernier" },
                        { value: "Vms", label: "Vms" },
                        { value: "PinGauge", label: "PinGauge" },
                      ],
                    }}
                    value={Records[0].observations_functional[Number(item) - 1]}
                    OnChange={(e) => {
                      HandleObservation(e, [Number(item) - 1]);
                    }}
                  />
                </td>
                {/* <td style={{ width: "10%" }} colSpan={2}>
                  <Field
                    name={"Checking_aid"}
                    options={{ type: "text", disabled: option === "view" }}
                    value={Records[0].observations_functional[Number(item) - 1]}
                    OnChange={(e) => {
                      HandleObservation(e, [Number(item) - 1]);
                    }}
                  />
                </td> */}
                {/* <td style={{ width: "5%" }} colSpan={3}>
                  <Field
                    name={"Specification"}
                    options={{ type: "text", disabled: option === "view" }}
                    value={Records[0].observations_functional[Number(item) - 1]}
                    OnChange={(e) => {
                      HandleObservation(e, [Number(item) - 1]);
                    }}
                  />
                </td> */}

                <td colSpan={3}>
                  <Field
                    style={{ width: "20%", float: "left" }}
                    label={""}
                    name={"Specifications_loc"}
                    options={{ type: "number", disabled: option === "view" }}
                    value={Records[0].observations_functional[Number(item) - 1]}

                    OnChange={(e) => {
                      HandleObservation(e, [Number(item) - 1]);
                    }}

                  />
                  <span style={{ float: "left", marginTop: "10px", fontSize: "20px" }}> * </span>
                  <Field
                    style={{ width: "20%", float: "left" }}
                    label={""}
                    name={"Specification_val"}
                    options={{ type: "number", disabled: option === "view" }}
                    value={Records[0].observations_functional[Number(item) - 1]}
                    OnChange={(e) => {
                      HandleObservation(e, [Number(item) - 1]);
                    }}

                  />
                  <span
                    style={{ float: "left", fontSize: "30px" }}
                  >
                    (
                  </span>
                  <span
                    style={{ float: "left", fontSize: "25px" }}
                  >
                    -
                  </span>
                  <Field
                    style={{ width: "20%", float: "left", width: "20%" }}
                    label={""}
                    name={"Specification_minus"}
                    options={{ type: "number", disabled: option === "view" }}
                    value={Records[0].observations_functional[Number(item) - 1]}
                    OnChange={(e) => {
                      HandleObservation(e, [Number(item) - 1]);
                    }}

                  />
                  <span style={{ float: "left", marginTop: "10px", fontSize: "20px" }}>/+</span>
                  <Field
                    style={{ width: "20%", float: "left" }}
                    label={""}
                    name={"Specification_plus"}
                    options={{ type: "number", disabled: option === "view" }}
                    value={Records[0].observations_functional[Number(item) - 1]}
                    OnChange={(e) => {
                      HandleObservation(e, [Number(item) - 1]);
                      console.log("e",e);
                    }}

                  />
                  <span
                    style={{ float: "left", fontSize: "25px" }}
                  >
                    )
                  </span>
                </td>
                <td style={{ width: "5%" }} colSpan={3}>
                  {console.log(Number(item) - 1, Records[0].observations_functional[Number(item) - 1].Specification_val)}
                  <Field
                    name={"Observation_x1"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "text", disabled: option === "view" }}
                    value={Records[0].observations_functional[Number(item) - 1]}
                    min={parseFloat(Records[0].observations_functional[Number(item) - 1].Specification_val) - parseFloat(Records[0].observations_functional[Number(item) - 1].Specification_minus)}
                    max={parseFloat(Records[0].observations_functional[Number(item) - 1].Specification_val) + parseFloat(Records[0].observations_functional[Number(item) - 1].Specification_plus)}
                    OnChange={(e) => {
                      HandleObservation(e, [Number(item) - 1]);
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={3}>
                  <Field
                    name={"Observation_x2"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "text", disabled: option === "view" }}
                    value={Records[0].observations_functional[Number(item) - 1]}
                    min={parseFloat(Records[0].observations_functional[Number(item) - 1].Specification_val) - parseFloat(Records[0].observations_functional[Number(item) - 1].Specification_minus)}
                    max={parseFloat(Records[0].observations_functional[Number(item) - 1].Specification_val) + parseFloat(Records[0].observations_functional[Number(item) - 1].Specification_plus)}
                    OnChange={(e) => {
                      HandleObservation(e, [Number(item) - 1]);
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={3}>
                  <Field
                    name={"Observation_x3"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "text", disabled: option === "view" }}
                    value={Records[0].observations_functional[Number(item) - 1]}
                    min={parseFloat(Records[0].observations_functional[Number(item) - 1].Specification_val) - parseFloat(Records[0].observations_functional[Number(item) - 1].Specification_minus)}
                    max={parseFloat(Records[0].observations_functional[Number(item) - 1].Specification_val) + parseFloat(Records[0].observations_functional[Number(item) - 1].Specification_plus)}
                    OnChange={(e) => {
                      HandleObservation(e, [Number(item) - 1]);
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={3}>
                  <Field
                    name={"Observation_x4"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "text", disabled: option === "view" }}
                    value={Records[0].observations_functional[Number(item) - 1]}
                    min={parseFloat(Records[0].observations_functional[Number(item) - 1].Specification_val) - parseFloat(Records[0].observations_functional[Number(item) - 1].Specification_minus)}
                    max={parseFloat(Records[0].observations_functional[Number(item) - 1].Specification_val) + parseFloat(Records[0].observations_functional[Number(item) - 1].Specification_plus)}
                    OnChange={(e) => {
                      HandleObservation(e, [Number(item) - 1]);
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={3}>
                  <Field
                    name={"Observation_x5"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "text", disabled: option === "view" }}
                    value={Records[0].observations_functional[Number(item) - 1]}
                    min={parseFloat(Records[0].observations_functional[Number(item) - 1].Specification_val) - parseFloat(Records[0].observations_functional[Number(item) - 1].Specification_minus)}
                    max={parseFloat(Records[0].observations_functional[Number(item) - 1].Specification_val) + parseFloat(Records[0].observations_functional[Number(item) - 1].Specification_plus)}
                    OnChange={(e) => {
                      HandleObservation(e, [Number(item) - 1]);
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={3}>
                  <Field
                    name={"Judgement"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "OK", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].observations_functional[Number(item) - 1]}
                    OnChange={(e) => {
                      HandleObservation(e, [Number(item) - 1]);
                    }}
                  />
                </td>
                {/* <td colSpan={3}>
                <Field
                  style={{ width: "210px" }}
                  name={"Dimensions_cavity"}
                  options={{ type: "text",disabled: option==="view" }}
                  value={Records[0].dimensions[Number(item) - 1]}
                  OnChange={(e) => {
                    HandleDimension(e, [Number(item) - 1]);
                  }}
                />
              </td> */}
              </tr>
            ))}
            <Field
              style={{ width: "30px", fontSize: "18px" }}
              label={"+"}
              options={{ type: "Button" }}
              onClick={handleIncrease}
            />
            {!(option === "view") && (
              <tr>
                {id === undefined ? (
                  <td colSpan={26}>
                    {" "}
                    <Field
                      label={"Submit"}
                      options={{ type: "Button" }}
                      onClick={(e) => {
                        HandleCreate(e);
                      }}
                    />
                  </td>
                ) : (
                  <td colSpan={26}>
                    {" "}
                    <Field
                      label={"Update"}
                      options={{ type: "Button" }}
                      onClick={(e) => {
                        HandleUpdate(e);
                      }}
                    />
                  </td>
                )}
              </tr>
            )}
          </tbody>
        </table>
        </div>
      </div>
         {/* ExcelTable Design*/}
         <div ref={contentRef} id="content-to-pdf">

<style>
{
`table {
  border-collapse: collapse;
  border: 1px solid;
  width:100%;
  margin: 0 auto;
}
.myTable {
  width: 130%;
  display:none;
}
th, td ,tr{
  border: 1px solid;
  height:1%;
}`
}
</style>
  <table className="myTable">

<tr>
<td colspan="1" style={{ height:"2%",textAlign: "center", width: "20%" }}>
  <img style={{textAlign: "center", width: "50%" }} src={Logo} alt="Logo" />
</td>

<td colspan="9"style={{textAlign: "center",fontSize:"18",fontStyle:"bold", width: "40%"}}>
            <div ><label >Layout Inspection</label></div>
         </td>
 <td colspan="4">
            FORMAT NO: UPCL/QC/R/02
            <br />
            REV NO: 05
            <br />
            REV DATE: 18-08-2018
          </td>
     </tr>
      <tr>
      <td colspan="5"style={{ width: "50%"}} >Part Number : {Records[0].Part_number}</td>
      <td  colspan="8" >Part Name : {Records[0].Part_name}</td>
     </tr>
       <tr>
      <td colspan="5"style={{ width: "50%"}} >Raw Material Name: {Records[0].Rawmaterialname[0].Mh_raw_mat_name}</td>
      <td  colspan="8" >Inspection Quantity:{Records[0].Inspection_quantity}</td> 
      </tr>
      <tr>
      <td colspan="5"style={{ width: "50%"}} >Opt: {Records[0].Opt}</td>
      <td  colspan="8" >Date:{Records[0].Entry_date}</td> 
      </tr>
      <tr>
      <td colspan="5"style={{ width: "50%"}} >Total Cavity: {Records[0].Total_cav}</td>
      <td  colspan="8" >No Of Cavity:{Records[0].No_of_cav}</td> 
      </tr>
      <tr>
      <td >Inspection Reason:</td>
      <td>{Records[0].Inspection_reason}</td>
      </tr>
      <tr>
      <td colSpan="13" style={{ textAlign: 'center' }}>
      <img style={{ width: '40%', height: '20%' }} src={Records[0].Image} alt="Img" />
      </td>
      </tr>
      <tr><td style={{ width: '10%' }}> S.No</td>
      <td style={{ width: '10%' }}>Inspection Item</td>
      <td style={{ width: '10%' }}>Checking Aid</td>
      <td style={{ width: '10%' }}>Specification</td>
      <td colSpan={4} style={{ width: '20%' }}>Observation</td>
      <td style={{ width: '20%' }}colSpan={6} >Judgement</td></tr>

      <tr><td /><td /><td /><td /><td>X1</td><td>X2</td><td>X3</td><td>X4</td><td style={{ width: '50%' }} colSpan={5}></td></tr>
     
        {Records[0].observations_functional.map((Observation, index) => (
        <tr key={index}>
         <td colSpan="1">{index + 1}</td>
         <td>{Observation.Inspection_item}</td>
         <td>{Observation.Checking_aid}</td>
         <td>{`${Observation.Specifications_loc}*${Observation.Specification_val}(-${Observation.Specification_minus}/+${Observation.Specification_plus})`}</td>
         <td>{Observation.Observation_x1}</td>
         <td>{Observation.Observation_x2}</td>
         <td>{Observation.Observation_x3}</td>
         <td>{Observation.Observation_x4}</td>
         <td style={{ width: '50%' }} colSpan={5}>{Observation.Judgement}</td>
         
        </tr>
         ))}
         </table></div>
        </QaLayout>
        );
        };

export default LayoutInspectionForm;
