import React from 'react'

const PageLock = () => {
  return (
    <div style={{width:'100%',height:'90vh'}}>
      <img style={{width:'98%',height:'100%'}} src='/unauthorizedPage.svg' alt='Un-Authorized Page'/>
    </div>
  )
}

export default PageLock