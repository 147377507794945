import React, { useState, useEffect } from "react";
import "../../../Styles/DailyShift.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Unitechlogo from "../../../icons/Unitechlogo.jpeg";
import { useRef } from "react";
import Pagination from "../../../Components/Pagination";
import HandleRead from "../../../Lib/HandleRead.js";
import { formatDt } from "../../../Lib/formatDt";
import FilterReport from "../../../Components/FilterReport";
import { Dropdown } from "react-bootstrap";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";
function DailyShiftProcessCheckListReport() {
  const handleTimePeriodChange = (event) => {
    setTimePeriod(event.target.value);
  };
  const tableRef = useRef(null);
  const handleDownloadStatement = () => {
    window.print();
    window.location.reload();
  };
  const [downloadEnabled, setDownloadEnabled] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate1, setEndDate1] = useState("");
  const [startDate1, setStartDate1] = useState("");
  const [timePeriod, setTimePeriod] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [filtermonth, setFilterMonth] = useState("");

  var [Daily_shift_pro, setDaily_shift_pro] = useState([]);
  const [isPaginationVisible, setPaginationVisible] = useState(true);
  useEffect(() => {
    if (filterDate || (startDate && endDate)) {
      setDownloadEnabled(true);
    } else {
      setDownloadEnabled(false);
    }
  }, [filterDate, filtermonth, startDate, endDate]);
  const viewPagination = () => {
    setPaginationVisible(true);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 66;

  const filterRecords = () => {
    const filteredData = Daily_shift_pro.filter((record) => {
      const trackDate = new Date(record.TrackDate);
      const selectedStartDate = startDate ? new Date(startDate) : null;
      const selectedEndDate = endDate ? new Date(endDate) : null;
      if (filtermonth) {
        const selectedMonth = new Date(filtermonth);
        const trackMonth = trackDate.getMonth();
        const trackYear = trackDate.getFullYear();

        return (
          trackMonth === selectedMonth.getMonth() &&
          trackYear === selectedMonth.getFullYear()
        );
      } else if (selectedStartDate && selectedEndDate) {
        return trackDate >= selectedStartDate && trackDate <= selectedEndDate;
      } else if (selectedStartDate) {
        return trackDate >= selectedStartDate;
      } else if (selectedEndDate) {
        return trackDate <= selectedEndDate;
      } else {
        return record.TrackDate && record.TrackDate.includes(filterDate);
      }
    });

    let filteredByTimePeriod = [];
    if (timePeriod === "1") {
      filteredByTimePeriod = filteredData.slice(0, 1);
    } else if (timePeriod === "7") {
      filteredByTimePeriod = filteredData.slice(0, 7);
    } else if (timePeriod === "15") {
      filteredByTimePeriod = filteredData.slice(0, 15);
    } else if (timePeriod === "31") {
      filteredByTimePeriod = filteredData.slice(0, 31);
    } else {
      return Daily_shift_pro;
    }

    return filteredByTimePeriod;
  };

  const filteredData = filterRecords();
  const npage = Math.ceil(filteredData.length / recordPerPage);
  const numbers = [...Array(npage).keys()].map((num) => num + 1);
  function handleFilterDateChange(event) {
    setFilterDate(event.target.value);
    setCurrentPage(1);
  }
  const handleFilterMonthChange = (event) => {
    setFilterMonth(event.target.value);
    setCurrentPage(1);
  };

  const handleStartDateChange = (newStartDate) => {
    setStartDate(newStartDate);
  };

  const handleEndDateChange = (newEndDate) => {
    setEndDate(newEndDate);
  };
  const handleStartDateChange1 = (newStartDate) => {
    setStartDate1(newStartDate);
  };

  const handleEndDateChange1 = (newEndDate) => {
    setEndDate1(newEndDate);
  };

  const firstIndex = (currentPage - 1) * recordPerPage;
  const lastIndex = firstIndex + recordPerPage;
  const filteredRecords = filteredData.slice(firstIndex, lastIndex);
  function prePage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function nextPage() {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function changeCpage(Id) {
    setCurrentPage(Id);
  }

  const totalPages = Math.ceil(filteredData.length / recordPerPage);

  const validCurrentPage = Math.min(Math.max(currentPage, 1), totalPages);
  const exportToExcel = () => {
    setTimeout(() => {
      if (filteredRecords.length === 0) {
        alert("No records found. Cannot export to Excel.");
        return;
      }
      const sheetData = document.getElementById("excelTable");
      const wsData = XLSX.utils.table_to_sheet(sheetData);
      wsData["!rows"] = [{ hpx: 50 }];
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, wsData, "Sheet1");
      XLSX.writeFile(wb, "DailyShift.xlsx");
    }, 1000);
  };
  const onButtonClick = () => {
    const pdf = new jsPDF("landscape", "mm", "a4");
    html2canvas(tableRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 10, 10, 280, 0);

      pdf.save("DailyShift.pdf");
    });
  };
  const pagestyle = `@media all {
    .page-break {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @media print and (orientation: landscape) {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }
  
  @page {
    size: auto;
    margin: 15mm;
  }`;
  let widthPercentage;

  if (timePeriod === "31") {
    widthPercentage = filteredRecords.length <= 31 ? "300%" : "550%";
  } else if (timePeriod === " ") {
    widthPercentage = filteredRecords.length <= 60 ? "150%" : "550%";
  } else if (timePeriod === "1") {
    widthPercentage = "80%";
  } else if (timePeriod === "7") {
    widthPercentage = filteredRecords.length <= 7 ? "120%" : "250%";
  } else if (timePeriod === "15") {
    widthPercentage = filteredRecords.length <= 15 ? "180%" : "450%";
  } else {
    widthPercentage = filteredRecords.length <= 35 ? "150%" : "550%";
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "5%",
          padding: "10px",
        }}
      >
        <FilterReport
          timePeriod={timePeriod}
          handleTimePeriodChange={handleTimePeriodChange}
          filterDate={filterDate}
          handleFilterDateChange={handleFilterDateChange}
          filtermonth={filtermonth}
          handleFiltermonthChange={handleFilterMonthChange}
          startDate={startDate}
          handleStartDateChange={handleStartDateChange}
          endDate={endDate}
          handleEndDateChange={handleEndDateChange}
          startDate1={startDate1}
          handleStartDateChange1={handleStartDateChange1}
          endDate1={endDate1}
          handleEndDateChange1={handleEndDateChange1}
          handleDownloadStatement={handleDownloadStatement}
          downloadEnabled={downloadEnabled}
        />
        <Dropdown style={{ width: "20%" }}>
          <Dropdown.Toggle>Download ⬇️</Dropdown.Toggle>
          <Dropdown.Menu>
          
            <Dropdown.Item>
              <button className="custom-button" onClick={onButtonClick}>
                PDF ⬇️
              </button>
            </Dropdown.Item>
            <Dropdown.Item>
              <button onClick={exportToExcel} className="custom-button">
                Excel ⬇️
              </button>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>{" "}
      <br />
      <br />
      <div>
        <table
          ref={tableRef}
          id="excelTable"
          style={{ "text-align": "center", width: widthPercentage }}
          cellPadding="1"
        >
          <thead>
            <tr>
              <th style={{ "text-align": "center", width: "5%" }}>
                <img src={Unitechlogo} style={{ width: "40px" }}></img>
              </th>

              <th
                id="mlcss-Title-List"
                name="mlcss-Title-List"
                colSpan="80"
                style={{ "text-align": "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">
                  Consolidated Report - Daily Shift Wise Process Check List
                </font>
              </th>
            </tr>
            <tr>
              <th className="topic-head">S.No.</th>
              <th className="topic-head">Parameters</th>
              <th className="topic-head">Check Area</th>
              <th className="topic-head">How to Check</th>
              <th className="topic-head">Reaction if not ok</th>
              <HandleRead
                keyword="Daily_shift_process_checklist"
                setData={setDaily_shift_pro}
              />

              {filteredRecords.length === 0 ? (
                <td colSpan="200" style={{ textAlign: "center" }}>
                  <p
                    id="mlcss-list-NoRec"
                    name="mlcss-list-noRec"
                    style={{ fontWeight: "bold" }}
                  >
                    No records found.
                  </p>
                </td>
              ) : (
                filteredRecords.map((Daily_shift_pro) => (
                  <th className="topic-head">
                    {formatDt(Daily_shift_pro.TrackDate)}
                  </th>
                ))
              )}
            </tr>
            <tr>
              <td className="topic-head">D1</td>
              <td className="topic-head">
                Mold Safety Pressure Below 20 BAR & Time in SEC 0.05 SEC
              </td>
              <td className="topic-head">Clamping Page</td>
              <td className="topic-head">Visual</td>
              <td className="topic-head">Informed to incharge</td>
              <HandleRead
                keyword="Daily_shift_process_checklist"
                setData={setDaily_shift_pro}
              />
              {filteredRecords.length === 0 ? (
                <td colSpan="200" style={{ textAlign: "center" }}>
                  <p
                    id="mlcss-list-NoRec"
                    name="mlcss-list-noRec"
                    style={{ fontWeight: "bold" }}
                  >
                    No records found.
                  </p>
                </td>
              ) : (
                filteredRecords.map((Daily_shift_pro) => (
                  <td className="topic-head">
                    {Daily_shift_pro.Mold_saf_pres_blw_1}
                  </td>
                ))
              )}
            </tr>
            <tr>
              <td className="topic-head">D2</td>
              <td className="topic-head">
                Mold Safety Tonnage Not Exit Position 2MM
              </td>
              <td className="topic-head">Clamping Page</td>
              <td className="topic-head">Visual</td>
              <td className="topic-head">Informed to incharge</td>

              <HandleRead
                keyword="Daily_shift_process_checklist"
                setData={setDaily_shift_pro}
              />
              {filteredRecords.length === 0 ? (
                <td colSpan="200" style={{ textAlign: "center" }}>
                  <p
                    id="mlcss-list-NoRec"
                    name="mlcss-list-noRec"
                    style={{ fontWeight: "bold" }}
                  >
                    No records found.
                  </p>
                </td>
              ) : (
                filteredRecords.map((Daily_shift_pro) => (
                  <td className="topic-head">
                    {Daily_shift_pro.Mold_saf_ton_1}
                  </td>
                ))
              )}
            </tr>
            <tr>
              <td className="topic-head">D3</td>
              <td className="topic-head">Cycle Time Alarm ±5SEC</td>
              <td className="topic-head">Proc mon/Feat time</td>
              <td className="topic-head">Visual</td>
              <td className="topic-head">Informed to incharge</td>

              <HandleRead
                keyword="Daily_shift_process_checklist"
                setData={setDaily_shift_pro}
              />
              {filteredRecords.length === 0 ? (
                <td colSpan="200" style={{ textAlign: "center" }}>
                  <p
                    id="mlcss-list-NoRec"
                    name="mlcss-list-noRec"
                    style={{ fontWeight: "bold" }}
                  >
                    No records found.
                  </p>
                </td>
              ) : (
                filteredRecords.map((Daily_shift_pro) => (
                  <td className="topic-head">
                    {Daily_shift_pro.Cycle_tme_alrm_1}
                  </td>
                ))
              )}
            </tr>
            <tr>
              <td className="topic-head">D4</td>
              <td className="topic-head">Cushion Monitoring Alarm ±0.5MM</td>
              <td className="topic-head">Proc mon</td>
              <td className="topic-head">Visual</td>
              <td className="topic-head">Informed to incharge</td>
              <HandleRead
                keyword="Daily_shift_process_checklist"
                setData={setDaily_shift_pro}
              />
              {filteredRecords.length === 0 ? (
                <td colSpan="200" style={{ textAlign: "center" }}>
                  <p
                    id="mlcss-list-NoRec"
                    name="mlcss-list-noRec"
                    style={{ fontWeight: "bold" }}
                  >
                    No records found.
                  </p>
                </td>
              ) : (
                filteredRecords.map((Daily_shift_pro) => (
                  <td className="topic-head">
                    {Daily_shift_pro.Cush_mon_alrm_1}
                  </td>
                ))
              )}
            </tr>
            <tr>
              <td className="topic-head">D5</td>
              <td className="topic-head">Ejection Pressure Below (20 BAR)</td>
              <td className="topic-head">Ejection Page</td>
              <td className="topic-head">Visual</td>
              <td className="topic-head">Informed to incharge</td>
              <HandleRead
                keyword="Daily_shift_process_checklist"
                setData={setDaily_shift_pro}
              />
              {filteredRecords.length === 0 ? (
                <td colSpan="200" style={{ textAlign: "center" }}>
                  <p
                    id="mlcss-list-NoRec"
                    name="mlcss-list-noRec"
                    style={{ fontWeight: "bold" }}
                  >
                    No records found.
                  </p>
                </td>
              ) : (
                filteredRecords.map((Daily_shift_pro) => (
                  <td className="topic-head">
                    {Daily_shift_pro.Ejec_pres_blw_1}
                  </td>
                ))
              )}
            </tr>
            <tr>
              <td className="topic-head">D6</td>
              <td className="topic-head">Barrel Temperature Variation ±10°C</td>
              <td className="topic-head">Temp Page</td>
              <td className="topic-head">Visual</td>
              <td className="topic-head">Informed to incharge</td>
              <HandleRead
                keyword="Daily_shift_process_checklist"
                setData={setDaily_shift_pro}
              />
              {filteredRecords.length === 0 ? (
                <td colSpan="200" style={{ textAlign: "center" }}>
                  <p
                    id="mlcss-list-NoRec"
                    name="mlcss-list-noRec"
                    style={{ fontWeight: "bold" }}
                  >
                    No records found.
                  </p>
                </td>
              ) : (
                filteredRecords.map((Daily_shift_pro) => (
                  <td className="topic-head">
                    {Daily_shift_pro.Bar_temp_vari_1}
                  </td>
                ))
              )}
            </tr>
            <tr>
              <td className="topic-head">D7</td>
              <td className="topic-head">Mold Cleanning (MATERIAL DUST)</td>
              <td className="topic-head">Molding Side</td>
              <td className="topic-head">Visual</td>
              <td className="topic-head">Informed to incharge</td>
              <HandleRead
                keyword="Daily_shift_process_checklist"
                setData={setDaily_shift_pro}
              />
              {filteredRecords.length === 0 ? (
                <td colSpan="200" style={{ textAlign: "center" }}>
                  <p
                    id="mlcss-list-NoRec"
                    name="mlcss-list-noRec"
                    style={{ fontWeight: "bold" }}
                  >
                    No records found.
                  </p>
                </td>
              ) : (
                filteredRecords.map((Daily_shift_pro) => (
                  <td className="topic-head">{Daily_shift_pro.Mold_clean_1}</td>
                ))
              )}
            </tr>
            <tr>
              <td className="topic-head">D8</td>
              <td className="topic-head">3C 5S</td>
              <td className="topic-head">All Area</td>
              <td className="topic-head">Visual</td>
              <td className="topic-head">Informed to incharge</td>
              <HandleRead
                keyword="Daily_shift_process_checklist"
                setData={setDaily_shift_pro}
              />
              {filteredRecords.length === 0 ? (
                <td colSpan="200" style={{ textAlign: "center" }}>
                  <p
                    id="mlcss-list-NoRec"
                    name="mlcss-list-noRec"
                    style={{ fontWeight: "bold" }}
                  >
                    No records found.
                  </p>
                </td>
              ) : (
                filteredRecords.map((Daily_shift_pro) => (
                  <td className="topic-head">{Daily_shift_pro.C_and_s_1}</td>
                ))
              )}
            </tr>
            <tr>
              <td className="topic-head">D9</td>
              <td className="topic-head">SMP & PMS Verification</td>
              <td className="topic-head">SMP & PMS Sheet</td>
              <td className="topic-head">Visual</td>
              <td className="topic-head">Informed to incharge</td>
              <HandleRead
                keyword="Daily_shift_process_checklist"
                setData={setDaily_shift_pro}
              />
              {filteredRecords.length === 0 ? (
                <td colSpan="200" style={{ textAlign: "center" }}>
                  <p
                    id="mlcss-list-NoRec"
                    name="mlcss-list-noRec"
                    style={{ fontWeight: "bold" }}
                  >
                    No records found.
                  </p>
                </td>
              ) : (
                filteredRecords.map((Daily_shift_pro) => (
                  <td className="topic-head">
                    {Daily_shift_pro.Smp_and_pms_veri_1}
                  </td>
                ))
              )}
            </tr>
            {/* <tr>
              <td className="topic-head">D10</td>
              <td className="topic-head">Operator1 training</td>
              <td className="topic-head">Operator1 Skill</td>
              <td className="topic-head">Visual</td>
              <td className="topic-head">Informed to incharge</td>
              <HandleRead
                keyword="Daily_shift_process_checklist"
                setData={setDaily_shift_pro}
              />
              {filteredRecords.length === 0 ? (
                <td colSpan="200" style={{ textAlign: "center" }}>
                  <p
                    id="mlcss-list-NoRec"
                    name="mlcss-list-noRec"
                    style={{ fontWeight: "bold" }}
                  >
                    No records found.
                  </p>
                </td>
              ) : (
                filteredRecords.map((Daily_shift_pro) => (
                  <td className="topic-head">{Daily_shift_pro.Oper_train_1}</td>
                ))
              )}
            </tr> */}
            <tr>
              <td className="topic-head">D10</td>
              <td className="topic-head">LMP Monitoring</td>
              <td className="topic-head">Molding Side</td>
              <td className="topic-head">Visual</td>
              <td className="topic-head">Informed to incharge</td>
              <HandleRead
                keyword="Daily_shift_process_checklist"
                setData={setDaily_shift_pro}
              />
              {filteredRecords.length === 0 ? (
                <td colSpan="200" style={{ textAlign: "center" }}>
                  <p
                    id="mlcss-list-NoRec"
                    name="mlcss-list-noRec"
                    style={{ fontWeight: "bold" }}
                  >
                    No records found.
                  </p>
                </td>
              ) : (
                filteredRecords.map((Daily_shift_pro) => (
                  <td className="topic-head">
                    {Daily_shift_pro.Lmp_monitor_1}
                  </td>
                ))
              )}
            </tr>

            <tr>
              <td colSpan="4" rowSpan={5} className="topic-head"></td>
              <td className="topic-head">Shift</td>
              <HandleRead
                keyword="Daily_shift_process_checklist"
                setData={setDaily_shift_pro}
              />
              {filteredRecords.length === 0 ? (
                <td colSpan="200" style={{ textAlign: "center" }}>
                  <p
                    id="mlcss-list-NoRec"
                    name="mlcss-list-noRec"
                    style={{ fontWeight: "bold" }}
                  >
                    No records found.
                  </p>
                </td>
              ) : (
                filteredRecords.map((Daily_shift_pro) => (
                  <td className="topic-head">{Daily_shift_pro.Shift}</td>
                ))
              )}
            </tr>

            <tr>
              <td className="topic-head">Operator</td>
              <HandleRead
                keyword="Daily_shift_process_checklist"
                setData={setDaily_shift_pro}
              />
              {filteredRecords.length === 0 ? (
                <td colSpan="200" style={{ textAlign: "center" }}>
                  <p
                    id="mlcss-list-NoRec"
                    name="mlcss-list-noRec"
                    style={{ fontWeight: "bold" }}
                  >
                    No records found.
                  </p>
                </td>
              ) : (
                filteredRecords.map((Daily_shift_pro) => (
                  <td className="topic-head">
                    {Daily_shift_pro.Operator_1}-{Daily_shift_pro.Operator}
                  </td>
                ))
              )}
            </tr>

            <tr>
              <td className="topic-head">Mold Setter</td>
              <HandleRead
                keyword="Daily_shift_process_checklist"
                setData={setDaily_shift_pro}
              />
              {filteredRecords.length === 0 ? (
                <td colSpan="200" style={{ textAlign: "center" }}>
                  <p
                    id="mlcss-list-NoRec"
                    name="mlcss-list-noRec"
                    style={{ fontWeight: "bold" }}
                  >
                    No records found.
                  </p>
                </td>
              ) : (
                filteredRecords.map((Daily_shift_pro) => (
                  <td className="topic-head">
                    {Daily_shift_pro.Mold_setter_1}-{Daily_shift_pro.MoldSetter}
                  </td>
                ))
              )}
            </tr>

            <tr>
              <td className="topic-head">Incharge Name</td>
              <HandleRead
                keyword="Daily_shift_process_checklist"
                setData={setDaily_shift_pro}
              />
              {filteredRecords.length === 0 ? (
                <td colSpan="200" style={{ textAlign: "center" }}>
                  <p
                    id="mlcss-list-NoRec"
                    name="mlcss-list-noRec"
                    style={{ fontWeight: "bold" }}
                  >
                    No records found.
                  </p>
                </td>
              ) : (
                filteredRecords.map((Daily_shift_pro) => (
                  <td className="topic-head">
                    {Daily_shift_pro.Inchrge_1}-{Daily_shift_pro.Inchrge}
                  </td>
                ))
              )}
            </tr>

            <tr>
              <td className="topic-head">Senior Engg</td>
              <HandleRead
                keyword="Daily_shift_process_checklist"
                setData={setDaily_shift_pro}
              />
              {filteredRecords.length === 0 ? (
                <td colSpan="2" style={{ textAlign: "center" }}>
                  <p
                    id="mlcss-list-NoRec"
                    name="mlcss-list-noRec"
                    style={{ fontWeight: "bold" }}
                  >
                    No records found.
                  </p>
                </td>
              ) : (
                filteredRecords.map((Daily_shift_pro) => (
                  <td className="topic-head">
                    {Daily_shift_pro.Senior_engg_1}-{Daily_shift_pro.SeniorEngg}
                    {/* {Daily_shift_pro.SeniorEngg} */}
                  </td>
                ))
              )}
            </tr>
          </thead>
        </table>
      </div>
      {/* <div>
      
      <Pagination
        currentPage={currentPage}
        totalPages={npage}
        onPageChange={(page) => setCurrentPage(page)}
      />
  
    </div> */}
      <div className="pagination" id="pagination">
        {isPaginationVisible && filteredData.length > 0 && (
          <Pagination
            currentPage={currentPage}
            npage={npage}
            prePage={prePage}
            nextPage={nextPage}
            changeCpage={changeCpage}
            numbers={numbers}
          />
        )}
      </div>
    </div>
  );
}

export default DailyShiftProcessCheckListReport;
