import React, { useEffect, useState, useRef} from "react";
import Field from "../../../../Components/Fields/FieldPaint.js";
import Lable from "../../../../Components/Fields/Lable";
import { useNavigate, useParams } from "react-router-dom";
import "./LoadingReport.css";
import CreateRecordPaint from "../../../../Lib/API/CreateRecordPaint";
import ReadRecordPaint from "../../../../Lib/API/ReadRecordPaint";
import UpdateRecordPaint from "../../../../Lib/API/UpdateRecordPaint";
import * as GeneralConstants from "../../../../Utils/GeneralConstants.js";
import QaLayoutPaint from "../../QaLayoutPaint.js";
import {
  CURRENT_Date,
  SHIFTMOLDINGQUALITY,
} from "../../../../Utils/GeneralConstants";
import LoadingPage from "../../../../Components/Handler/LoadingPage";
import { useScrollEffect } from "../../../Quality/useScrollEffect";
import PreViewPage from "./PreViewPage.js";
import DeleteOriginalRecord from "../../../../Lib/API/DeleteOriginalRecord.js";
import {  setMessagePaint } from "../../../../Lib/SetMessagePaint.js";
import CreateApprovalPaint from "../../../../Lib/CreateApprovalPaint.js";
const LoadingReportForm = () => {
  const CURRENT_TIME = new Date(
    new Date().getTime() - new Date().getTimezoneOffset() * 60000
  )
    .toISOString()
    .split("T")[1]
    .substring(0, 5);
  const InitRoot = [
    {
      download: false,
      // Time: CURRENT_TIME,
      Shift: SHIFTMOLDINGQUALITY,
      Format_no: "UPCL/PRO/R/39",
      Rev_no: "01",
      Rev_ate: "18-05-2017",
      Entry_date: CURRENT_Date,
      // Partname: [{}],
      // PartName: [{}],
      PartNumber: [{}],
      paint_loading_bulk_report: [
        {
          Time: CURRENT_TIME,
          PartName:[{}],
          Part_name: "",
          Lot_no: "",
          Fresh_part: "",
          Rework_part: "",
          Rej_part: "",
          Total_Part: "",
          Remarks: "",
        },
      ],
      paint_names: [
        { key: "Loader Name", value: "" },
        { key: "Ipa Wiper Name", value: "" },
        { key: "Booth Assistant", value: "" },
        { key: "Painter Name1", value: "" },
        { key: "Painter Name2", value: "" },
        { key: "Operator Name1", value: "" },
        { key: "Operator Name2", value: "" },
      ],
      LoaderName: "",
      IpaName: "",
      BoothName: "",
      PainterName1: "",
      PainterName2: "",
      OperatorName1: "",
      OperatorName2: "",
      LoadIncharge: "",
      ShiftIncharge: "",
      ApprovedBy: "",
      CreatedBy: "",
      LoaderName: "",
      Loader_Name: [{}],
      Ipa_Name: [{}],
      Booth_Name: [{}],
      Painter_Name1: [{}],
      Painter_Name2: [{}],
      Operator_Name1: [{}],
      Operator_Name2: [{}],
      Load_Incharge: [{}],
      Shift_Incharge: [{}],
      Approved_By: [{}],
      Created_By: [{}],
      Id: "",
    },
  ];
  const tableRef = useRef(null);
  const scrollTop = useRef(null);
  useScrollEffect();
  const [downloadEnabled, setDownloadEnabled] = useState(true);
  const [ButtonPopup, setButtonPopup] = useState(false);
  const [Records, setRecords] = useState(InitRoot);
  const [Preview, setPreView] = useState(false);
  const [warning, setwarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(false);
  const [Machine, setMachine] = useState([]);
  const [Raw, setRaw] = useState([]);
  useEffect(() => {
    console.log("Records", Records);
  }, [Records]);

  const { option, id } = useParams();
  const navigate = useNavigate();

  function HandleRecords(Event) {
    setRecords([{ ...Records[0], [Event.target.name]: Event.target.value }]);
  }
  function HandleRecordSelect(Event, column, value, label) {
    setRecords(() => {
      let record = [...Records];
      //   console.log("record122", record);
      record[0][Event.name] = Event.values.value;
      record[0][column][0][value] = Event.values.value;
      record[0][column][0][label] = Event.values.label;
      return record;
    });
  }

  const viewPagination = () => {
    console.log("");
  };

  const addRow = () => {
    setRecords((prevRecords) => {
      const newRow = {
        Time: CURRENT_TIME,
        PartName: [{}],
        Part_name: "",
        Lot_no: "",
        Fresh_part: "",
        Rework_part: "",
        Rej_part: "",
        Total_Part: "",
        Remarks: "",
      };
      const updatedRecords = [...prevRecords];
      updatedRecords[0].paint_loading_bulk_report.push(newRow);
      return updatedRecords;
    });
  };

  const removeRow = () => {
    setRecords((prevRecords) => {
      if (prevRecords[0].paint_loading_bulk_report.length > 1) {
        const updatedRecords = [...prevRecords];
        updatedRecords[0].paint_loading_bulk_report.pop();
        return updatedRecords;
      } else {
        return prevRecords;
      }
    });
  };

  function Update_paint_loading_bulk_report(index, event) {
    console.log("Update_paint_loading_bulk_report", index, event);
    if (!Records[0]?.paint_loading_bulk_report[index]?.Part_name) {
      alert("Please select a PartName before entering LotNo.");
      return;
    }
    setRecords((prevRecords) => {
      prevRecords[0].paint_loading_bulk_report[index][event.target.name] =
        event.target.value;
      return [...prevRecords];
    });
  }
  function Update_paint_loading_bulk_report_part_name(index,Event, column, value, label) {
    // console.log("Update_paint_loading_bulk_report_part_name", Event);
    // console.log("target",Event.name);
    // console.log("columnname", column);
    // console.log("value", Event.values.value);
    // console.log("label", Event.values.label);

    setRecords((prevRecords) => {
      prevRecords[0].paint_loading_bulk_report[index][Event.name] =
      Event.values.value;
      prevRecords[0].paint_loading_bulk_report[index][column][0][value] =
      Event.values.label; 
      prevRecords[0].paint_loading_bulk_report[index][column][0][label] =
       Event.values.value;
      return [...prevRecords];
    });
  }
  function HandleCreate(event) {
    try {
      if (!Records[0].paint_loading_bulk_report[0].Part_name) {
        alert("Please fill at least one part");

        return;
      }
      if (
        !Records[0].LoaderName ||
        !Records[0].IpaName ||
        !Records[0].BoothName ||
        !Records[0].PainterName1 ||
        !Records[0].PainterName2 ||
        !Records[0].OperatorName1 ||
        !Records[0].OperatorName2 ||
        !Records[0].CreatedBy ||
        !Records[0].ApprovedBy
      ) {
        alert("Please fill all mandatory fields");
        setwarning(true);
        setPreView(false);
        return;
      } else {
        let record = [...Records];
        // record && delete record[0].Partname;
        record && delete record[0].Loader_Name;
        record && delete record[0].Ipa_Name;
        record && delete record[0].Booth_Name;
        record && delete record[0].Painter_Name1;
        record && delete record[0].Painter_Name2;
        record && delete record[0].Operator_Name1;
        record && delete record[0].Operator_Name2;
        record && delete record[0].Load_Incharge;
        record && delete record[0].Shift_Incharge;
        record && delete record[0].Created_By;
        record && delete record[0].Approved_By;
        record && delete record[0].PartNumber;
        record && delete record[0].paint_names;
        record && delete record[0].download;
        record && delete record[0].Id;
        if (record && record[0] && record[0].paint_loading_bulk_report) {
          record[0].paint_loading_bulk_report.forEach((report) => {
            delete report.PartName;
          });
        }

        console.log("createFter Delete", record);
        CreateRecordPaint("paint_loading_report", record).then((res) => {
          console.log("HandleCreate", res);
          setMessagePaint({
            NoteFrom: localStorage.getItem("empId"),
            NoteTo: Records[0].ApprovedBy,
            NoteMessage: `${localStorage.getItem(
              "empId"
            )}-${localStorage.getItem(
              "name"
            )} has submitted Paint LoadingReport Form, Please do verify`,
            NoteRecordId: res.ROWID,
            TableName: "LoadingReport",
            NoteCreatedBy: localStorage.getItem("empId"),
          });
          CreateApprovalPaint({
            RequestedBy: localStorage.getItem("empId"),
            Approver: Records[0].ApprovedBy,
            Level: 1,
            Status: "Waiting for Approval",
            TableName: "LoadingReport",
            TableID: res.ROWID,
            Description:
              "The Paint LoadingReport Form Has been Submitted By " +
              localStorage.getItem("empId") +
              "-" +
              localStorage.getItem("name"),
            CreatedBy: localStorage.getItem("empId"),
          });
        });
        alert(GeneralConstants.SUBMISSION_SUCCESSFUL);
        setwarning(false);
        setPreView(false);
        setRecords(InitRoot);
      }
    } catch (error) {
      console.error(error);
      alert(GeneralConstants.SUBMISSION_FAILED);
    }
  }
  function HandleUpdate(event) {
    try {
      let record = [...Records];
      record && delete record[0].Partname;
      record && delete record[0].Loader_Name;
      record && delete record[0].Ipa_Name;
      record && delete record[0].Booth_Name;
      record && delete record[0].Painter_Name1;
      record && delete record[0].Painter_Name2;
      record && delete record[0].Operator_Name1;
      record && delete record[0].Operator_Name2;
      record && delete record[0].Load_Incharge;
      record && delete record[0].Shift_Incharge;
      record && delete record[0].Created_By;
      record && delete record[0].Approved_By;
      record && delete record[0].PartNumber;
      record && delete record[0].paint_names;
      if (record && record[0] && record[0].paint_loading_bulk_report) {
        record[0].paint_loading_bulk_report.forEach((report) => {
          delete report.PartName;
        });
      }
      record && delete record[0].download;
      console.log("updaFter Delete", record);
      console.log(record[0].Id);
      let noId = {};
      let fields = ["paint_loading_bulk_report"];
      fields.map((field) => {
        record[0][field].map((data) => {
          if (!noId[field]) {
            noId[field] = data.Id === undefined ? true : false;
          }
        });
      });
      console.log("noId", noId);
      Object.keys(noId).map((item) => {
        if (noId[item]) {
          DeleteOriginalRecord(item, [
            { paint_loading_report_id: record[0].Id },
          ]).then((res) => {
            console.log("DeleteOriginalRecord", res);
            const newData = record[0][item].map((obj) => {
              const newObj = { ...obj };
              delete newObj.Id;
              newObj.paint_loading_report_id = record[0].Id;
              return newObj;
            });
            console.log("newData", newData);
            CreateRecordPaint(item, newData);
            delete record[0][item];
          });
          console.log("noId", record[0][item]);
        }
      });

      UpdateRecordPaint("paint_loading_report", record).then((res) => {
        console.log("HandleUpdate", res);
      });
      alert(GeneralConstants.UPDATE_SUCCESSFUL);
      setRecords(InitRoot);
      setPreView(false);
      navigate(-1);
    } catch (error) {
      console.error(error);
      alert(GeneralConstants.UPDATE_FAILURE);
    }
  }

  useEffect(() => {
    setwarning(false);
    document.title = "LoadingReport";
    const fetchData = async () => {
      if (id === undefined) {
        setRecords([
          {
            ...Records[0],
            // Entry_date: CURRENT_Date,
            // Time: CURRENT_TIME,
            Shift: SHIFTMOLDINGQUALITY,
          },
        ]);
      } else {
        setLoading(true);
        try {
          const res = await ReadRecordPaint({
            _keyword_: "paint_loading_report",
            Id: id,
          });
          console.log("234", res);
          if (res.data[0] === undefined) {
            setRecords(InitRoot);
          } else {
            const mergedData = {
              ...res.data[0],
              paint_names: InitRoot[0].paint_names,
            };
            setRecords([mergedData]);
            // setRecords(res.data);
          }
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  // useScanDetection({
  //   onComplete: (code) => {
  //     code = code.replaceAll("Shift", "");
  //     setField(code);
  //   },
  //   minLength: 3,
  // });
  // function setField(code) {
  //   console.log("code", code);
  //   let isnumber = Number(code).toString() !== "NaN";
  //   let current_element = document.activeElement.id;

  //   if (code.includes("EMP")) {
  //     if (
  //       current_element === "" ||
  //       current_element === "react-select-1-input"
  //     ) {
  //       ReadRecordPaint(
  //         `SELECT emp_code ,emp_name FROM emp_details where emp_code = '${code}' and ActiveStatus = "true"`
  //       ).then((res) => {
  //         if (res.data[0].count > "0") {
  //           setRecords(() => {
  //             let record = [...Records];
  //             (record[0].Prodcution_operator_name =
  //               res.data[0].rows[0].emp_code),
  //               (record[0]["Prodcutionoperatorname"][0]["Emp_code"] =
  //                 res.data[0].rows[0].emp_code);
  //             record[0]["Prodcutionoperatorname"][0]["Emp_name"] =
  //               res.data[0].rows[0].emp_name;
  //             return record;
  //           });
  //         } else {
  //           alert("Invalid data");
  //         }
  //       });
  //     }

  //     }else if (code.includes("LOT")) {
  //     setRecords(() => {
  //       let record = [...Records];
  //       record[0].paint_loading_bulk_report[index].Lot_no = code;
  //       return record;
  //     });
  //   }  else if (code.includes("RMI")) {
  //     alert("Invalid Input");
  //     return false;
  //   } else if (code.includes("CUS")) {
  //     alert("Invalid Input");
  //     return false;
  //   } else if (code.includes("FG")) {
  //     alert("Invalid Input");
  //     return false;
  //   } else if (code.includes("SUP")) {
  //     alert("Invalid Input");
  //     return false;
  //   }
  //   else {
  //     ReadRecordPaint(
  //       `SELECT Part_code,Part_name from paint_part_master WHERE active_status = 'Active'and Delete_id=0 and part_code = '${code}' `
  //     ).then((res) => {
  //       console.log("res", res.data[0].rows[0]);
  //     });
  //   }
  // }

  return (
    <QaLayoutPaint record={Records} quary={""}>
      <div className="Form" ref={scrollTop}>
        <div ref={tableRef}>
          <table>
            <tbody>
              <tr>
                <th colSpan={12}>
                  <Lable value={"LoadingReport"} type={"Head"} />
                </th>

                <td colSpan={2}>
                  <tr>
                    <td style={{ width: "25%" }}>
                      <Field
                        label={"DOC NO"}
                        name={"Format_no"}
                        style={{ padding: "0px" }}
                        options={{
                          type: "text",
                          disabled: true,
                        }}
                        value={Records[0]}
                        OnChange={(e) => {
                          HandleRecords(e);
                        }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td style={{ width: "25%" }}>
                      <Field
                        label={"REV NO"}
                        name={"Rev_no"}
                        style={{ padding: "0px" }}
                        options={{
                          type: "text",
                          // defaultValue: "00",
                          disabled: true,
                        }}
                        value={Records[0]}
                        OnChange={(e) => {
                          HandleRecords(e);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "25%" }}>
                      <Field
                        label={"REV DATE"}
                        name={"Rev_ate"}
                        style={{ padding: "0px" }}
                        options={{
                          type: "text",
                          //  defaultValue: "18-05-2017",
                          disabled: true,
                        }}
                        value={Records[0]}
                        OnChange={(e) => {
                          HandleRecords(e);
                        }}
                      />
                    </td>
                  </tr>
                </td>
                {/* )} */}
              </tr>
              <tr>
                <td colSpan={4}>
                  <Field
                    label={"Date"}
                    name={"Entry_date"}
                    options={{ type: "date", disabled: true }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                <td colSpan={9}>
                  <Field
                    label={"Shift"}
                    name={"Shift"}
                    options={{
                      type: "dropdown",
                      disabled: true,
                      values: [
                        { value: "A", label: "A12" },
                        { value: "B", label: "B12" },
                      ],
                    }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                <td colSpan={1}></td>
              </tr>
              <tr>
                <td colSpan={1}>
                  <Lable value={"Time"} />
                </td>
                <td colSpan={3}>
                  <Lable value={"Part Name"} />
                </td>
                <td colSpan={1}>
                  <Lable value={"Lot No"} />
                </td>

                <td colSpan={2}>
                  <Lable value={"Fresh Part"} />
                </td>
                <td colSpan={2}>
                  <Lable value={"Rework Part"} />
                </td>
                <td colSpan={2}>
                  <Lable value={"Rejection Part"} />
                </td>
                <td colSpan={2}>
                  <Lable value={"Total Part"} />
                </td>
                {/* </tr></td> */}
                <td colSpan={1}>
                  <Lable value={"Remarks"} />
                </td>
              </tr>

              {Array.from(
                { length: Records[0].paint_loading_bulk_report.length },
                (_, i) => i + 1
              ).map((item) => (
                <tr>
                  <td colSpan={1}>
                    <Field
                      name={"Time"}
                      options={{ type: "text", disabled: true }}
                      value={
                        Records[0].paint_loading_bulk_report[Number(item) - 1]
                      }
                      OnChange={(e) =>
                        Update_paint_loading_bulk_report([Number(item) - 1], e)
                      }
                    />
                  </td>
                  <td colSpan={3} style={{ width: "15%" }}>
                    <Field
                      name={"Part_name"}
                      options={{
                        type: "reference",
                        reference: "Get_paint_part_name",
                        required: true,
                        disabled: option === "view"||  (option === "edit" && Records?.[0]?.paint_loading_bulk_report[item - 1]?.PartName[0]?.Part_code) 
                      }}
                      warning={warning}
                      value={{
                        value:
                        Records?.[0]?.paint_loading_bulk_report[Number(item) - 1].PartName[0].Part_code ,
                        label:
                          Records?.[0]?.paint_loading_bulk_report[
                            Number(item) - 1
                          ].PartName[0].Part_name,
                      }}
                      OnChange={(e) =>
                        Update_paint_loading_bulk_report_part_name(
                          [Number(item) - 1],
                          e,
                          "PartName",
                          "Part_name",
                          "Part_code"
                        )
                      }
                      style={{ width: "100%" }}
                    />
                  </td>
                  {/* <td colSpan={3} style={{ width: "15%" }}>
                    <Field
                      name={"Part_name"}
                      options={{ type: "text", disabled: option === "view" }}
                      value={
                        Records[0].paint_loading_bulk_report[Number(item) - 1]
                      }
                      OnChange={(e) =>
                        handleRecordsChange(e, [Number(item) - 1])
                      }
                      style={{ width: "100%" }}
                    />
                  </td> */}
                  <td colSpan={1}>
                    <Field
                      name={"Lot_no"}
                      options={{ type: "text", disabled: option === "view" }}
                      value={
                        Records[0].paint_loading_bulk_report[Number(item) - 1]
                      }
                      OnChange={(e) =>
                        Update_paint_loading_bulk_report([Number(item) - 1], e)
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <Field
                      name={"Fresh_part"}
                      options={{ type: "text", disabled: option === "view" }}
                      value={
                        Records[0].paint_loading_bulk_report[Number(item) - 1]
                      }
                      OnChange={(e) =>
                        Update_paint_loading_bulk_report([Number(item) - 1], e)
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <Field
                      name={"Rework_part"}
                      options={{ type: "text", disabled: option === "view" }}
                      value={
                        Records[0].paint_loading_bulk_report[Number(item) - 1]
                      }
                      OnChange={(e) =>
                        Update_paint_loading_bulk_report([Number(item) - 1], e)
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <Field
                      name={"Rej_part"}
                      options={{ type: "text", disabled: option === "view" }}
                      value={
                        Records[0].paint_loading_bulk_report[Number(item) - 1]
                      }
                      OnChange={(e) =>
                        Update_paint_loading_bulk_report([Number(item) - 1], e)
                      }
                    />
                  </td>
                  <td colSpan={2}>
                    <Field
                      name={"Total_Part"}
                      options={{ type: "text", disabled: option === "view" }}
                      value={
                        Records[0].paint_loading_bulk_report[Number(item) - 1]
                      }
                      OnChange={(e) =>
                        Update_paint_loading_bulk_report([Number(item) - 1], e)
                      }
                    />
                  </td>
                  <td colSpan={1}>
                    <Field
                      name={"Remarks"}
                      options={{ type: "text", disabled: option === "view" }}
                      value={
                        Records[0].paint_loading_bulk_report[Number(item) - 1]
                      }
                      OnChange={(e) =>
                        Update_paint_loading_bulk_report([Number(item) - 1], e)
                      }
                    />
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan={2}>
                  <button onClick={addRow} disabled={option === "view"}>
                    Add Row
                  </button>
                </td>
                <td>
                  <button onClick={removeRow} disabled={option === "view"}>
                    -
                  </button>
                </td>
              </tr>
              <tr>
                <th colSpan={14}>
                  <Lable value={"Manpower Details"} type={"Head"} />
                </th>
              </tr>
              <tr>
                <td colSpan={4}>
                  {" "}
                  <Field
                    label={"LOADER NAME"}
                    name={"LoaderName"}
                    doubleLine={true}
                    options={{
                      type: "reference",
                      reference: "emp_details_dropdown",
                      required: true,
                      disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records[0].Loader_Name[0]
                        ? Records[0].Loader_Name[0].Emp_code
                        : "",
                      label: Records[0].Loader_Name[0]
                        ? Records[0].Loader_Name[0].Emp_name
                        : "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "Loader_Name",
                        "Emp_code",
                        "Emp_name"
                      );
                    }}
                  />
                </td>

                <td colSpan={4}>
                  {" "}
                  <Field
                    label={"IPA WIPER NAME"}
                    name={"IpaName"}
                    doubleLine={true}
                    options={{
                      type: "reference",
                      reference: "emp_details_dropdown",
                      required: true,
                      disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records[0].Ipa_Name[0]
                        ? Records[0].Ipa_Name[0].Emp_code
                        : "",
                      label: Records[0].Ipa_Name[0]
                        ? Records[0].Ipa_Name[0].Emp_name
                        : "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(e, "Ipa_Name", "Emp_code", "Emp_name");
                    }}
                  />
                </td>
                <td colSpan={6}>
                  {" "}
                  <Field
                    label={"BOOTH ASSITANT"}
                    name={"BoothName"}
                    doubleLine={true}
                    options={{
                      type: "reference",
                      reference: "emp_details_dropdown",
                      required: true,
                      disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records[0].Booth_Name[0]
                        ? Records[0].Booth_Name[0].Emp_code
                        : "",
                      label: Records[0].Booth_Name[0]
                        ? Records[0].Booth_Name[0].Emp_name
                        : "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "Booth_Name",
                        "Emp_code",
                        "Emp_name"
                      );
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={4}>
                  {" "}
                  <Field
                    label={"PAINTER NAME 1"}
                    name={"PainterName1"}
                    doubleLine={true}
                    options={{
                      type: "reference",
                      reference: "emp_details_dropdown",
                      required: true,
                      disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records[0].Painter_Name1[0]
                        ? Records[0].Painter_Name1[0].Emp_code
                        : "",
                      label: Records[0].Painter_Name1[0]
                        ? Records[0].Painter_Name1[0].Emp_name
                        : "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "Painter_Name1",
                        "Emp_code",
                        "Emp_name"
                      );
                    }}
                  />
                </td>
                <td colSpan={4}>
                  {" "}
                  <Field
                    label={"PAINTER NAME 2"}
                    name={"PainterName2"}
                    doubleLine={true}
                    options={{
                      type: "reference",
                      reference: "emp_details_dropdown",
                      required: true,
                      disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records[0].Painter_Name2[0]
                        ? Records[0].Painter_Name2[0].Emp_code
                        : "",
                      label: Records[0].Painter_Name2[0]
                        ? Records[0].Painter_Name2[0].Emp_name
                        : "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "Painter_Name2",
                        "Emp_code",
                        "Emp_name"
                      );
                    }}
                  />
                </td>

                <td colSpan={3}>
                  {" "}
                  <Field
                    label={"OPERATOR NAME 1"}
                    name={"OperatorName1"}
                    doubleLine={true}
                    options={{
                      type: "reference",
                      reference: "emp_details_dropdown",
                      required: true,
                      disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records[0].Operator_Name1[0]
                        ? Records[0].Operator_Name1[0].Emp_code
                        : "",
                      label: Records[0].Operator_Name1[0]
                        ? Records[0].Operator_Name1[0].Emp_name
                        : "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "Operator_Name1",
                        "Emp_code",
                        "Emp_name"
                      );
                    }}
                  />
                </td>
                <td colSpan={3}>
                  {" "}
                  <Field
                    label={"OPERATOR NAME 2"}
                    name={"OperatorName2"}
                    doubleLine={true}
                    options={{
                      type: "reference",
                      reference: "emp_details_dropdown",
                      required: true,
                      disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records[0].Operator_Name2[0]
                        ? Records[0].Operator_Name2[0].Emp_code
                        : "",
                      label: Records[0].Operator_Name2[0]
                        ? Records[0].Operator_Name2[0].Emp_name
                        : "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "Operator_Name2",
                        "Emp_code",
                        "Emp_name"
                      );
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={4}>
                  {" "}
                  <Field
                    label={"Loading In-charge"}
                    name={"LoadIncharge"}
                    doubleLine={true}
                    options={{
                      type: "reference",
                      reference: "emp_details_dropdown",
                      required: true,
                      disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records[0].Load_Incharge[0]
                        ? Records[0].Load_Incharge[0].Emp_code
                        : "",
                      label: Records[0].Load_Incharge[0]
                        ? Records[0].Load_Incharge[0].Emp_name
                        : "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "Load_Incharge",
                        "Emp_code",
                        "Emp_name"
                      );
                    }}
                  />
                </td>

                <td colSpan={4}>
                  {" "}
                  <Field
                    label={"Shift In-charge"}
                    name={"ShiftIncharge"}
                    doubleLine={true}
                    options={{
                      type: "reference",
                      reference: "emp_details_dropdown",
                      required: true,
                      disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records[0].Shift_Incharge[0]
                        ? Records[0].Shift_Incharge[0].Emp_code
                        : "",
                      label: Records[0].Shift_Incharge[0]
                        ? Records[0].Shift_Incharge[0].Emp_name
                        : "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "Shift_Incharge",
                        "Emp_code",
                        "Emp_name"
                      );
                    }}
                  />
                </td>
                <td colSpan={3}>
                  {" "}
                  <Field
                    label={"APPROVED BY"}
                    name={"ApprovedBy"}
                    doubleLine={true}
                    options={{
                      type: "reference",
                      reference: "emp_details_dropdown",
                      required: true,
                      disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records[0].Approved_By[0]
                        ? Records[0].Approved_By[0].Emp_code
                        : "",
                      label: Records[0].Approved_By[0]
                        ? Records[0].Approved_By[0].Emp_name
                        : "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "Approved_By",
                        "Emp_code",
                        "Emp_name"
                      );
                    }}
                  />
                </td>
                <td colSpan={3}>
                  {" "}
                  <Field
                    label={"CREATED BY"}
                    name={"CreatedBy"}
                    doubleLine={true}
                    options={{
                      type: "reference",
                      reference: "emp_details_dropdown",
                      required: true,
                      disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records[0].Created_By[0]
                        ? Records[0].Created_By[0].Emp_code
                        : "",
                      label: Records[0].Created_By[0]
                        ? Records[0].Created_By[0].Emp_name
                        : "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "Created_By",
                        "Emp_code",
                        "Emp_name"
                      );
                    }}
                  />
                </td>
              </tr>
              {Preview && (
                <PreViewPage
                  Records={Records}
                  setState={setPreView}
                  HandleCreate={HandleCreate}
                  option={option}
                  HandleUpdate={HandleUpdate}
                  id={id}
                />
              )}

              <tr>
                <td colSpan={19}>
                  {" "}
                  <Field
                    className={"submit"}
                    label={"Preview"}
                    options={{ type: "Button" }}
                    onClick={() => {
                      setPreView(true);
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </QaLayoutPaint>
  );
};

export default LoadingReportForm;
