import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import "./NewSmpMain.css";
import NewSmpInjectionSettings from './NewSmpInjectionSetting';
import HoldingSettings from './HoldSettings';
import TemperatureSmp from './TemperatureSmp';
import HotRunTemp from './HotRunTemp';
import SeqemaceGate from './SeqemaceGate';
import MouldTempDetails from './MouldTempDetails';
import RefilSettings from './RefilSettings';
import InjUnitSettings from './InjUnitSettings';
import MouldCloseSettings from './MouldCloseSettings';
import EjectionSettings from './EjectionSettings';
import CoreSettings from './CoreSettings';
import MouldOpenSettings from './MouldOpenSettings';
import BackPres from './BackPres';
import Lable from '../../../Components/Fields/Lable';
import { useState } from 'react';

function NewSmpTabs({ Records, setRecords }) {
    const [activeKey, setActiveKey] = useState("first");
    return (
        <Tab.Container id="left-tabs-example" activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
            <div className="gridOverView">
                <div className='gridSize1'>
                    <Lable value={"List Of Tabs"} type={"Head"} style={{ padding: "1px" }} />
                    <Nav onKeyDown={false} variant="pills" className="flex-column">
                        <Nav.Item>
                            <Nav.Link eventKey="first">Injection Settings</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second">Hold Settings</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="three">Refill Settings</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="four">Back Press Settings</Nav.Link>
                        </Nav.Item>                        
                        <Nav.Item>
                            <Nav.Link eventKey="five">Temperature</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="six">Hot Runner System Temperature</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="seven">Mould Temperature Details </Nav.Link>
                        </Nav.Item> 
                        <Nav.Item>
                            <Nav.Link eventKey="eight">Seqemace Gate</Nav.Link>
                        </Nav.Item>                                              
                        <Nav.Item>
                            <Nav.Link eventKey="nine">Mould Close Settings</Nav.Link>
                        </Nav.Item>                        
                        <Nav.Item>
                            <Nav.Link eventKey="ten">Mould Opening Settings</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="eleven">Ejection Settings</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="twele">Core Settings</Nav.Link>
                        </Nav.Item>                                                
                        <Nav.Item>
                            <Nav.Link eventKey="thirteen">Injection Unit Settings</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                <Tab.Content className="gridSize2">
                    <Tab.Pane eventKey="first"><NewSmpInjectionSettings Records={Records} setRecords={setRecords} setActiveKey={setActiveKey} activeKey={activeKey} /></Tab.Pane>
                    <Tab.Pane eventKey="second"><HoldingSettings Records={Records} setRecords={setRecords} setActiveKey={setActiveKey} activeKey={activeKey} /></Tab.Pane>
                    <Tab.Pane eventKey="three"><RefilSettings Records={Records} setRecords={setRecords} setActiveKey={setActiveKey} activeKey={activeKey} /></Tab.Pane>
                    <Tab.Pane eventKey="four"><BackPres Records={Records} setRecords={setRecords} setActiveKey={setActiveKey} activeKey={activeKey} /></Tab.Pane>                    
                    <Tab.Pane eventKey="five"><TemperatureSmp Records={Records} setRecords={setRecords} setActiveKey={setActiveKey} activeKey={activeKey} /></Tab.Pane>
                    <Tab.Pane eventKey="six"><HotRunTemp Records={Records} setRecords={setRecords} setActiveKey={setActiveKey} activeKey={activeKey} /></Tab.Pane>
                    <Tab.Pane eventKey="seven"><MouldTempDetails Records={Records} setRecords={setRecords} setActiveKey={setActiveKey} activeKey={activeKey} /></Tab.Pane>
                    <Tab.Pane eventKey="eight"><SeqemaceGate Records={Records} setRecords={setRecords} setActiveKey={setActiveKey} activeKey={activeKey} /></Tab.Pane>
                    <Tab.Pane eventKey="nine"><MouldCloseSettings Records={Records} setRecords={setRecords} setActiveKey={setActiveKey} activeKey={activeKey} /></Tab.Pane>
                    <Tab.Pane eventKey="ten"><MouldOpenSettings Records={Records} setRecords={setRecords} setActiveKey={setActiveKey} activeKey={activeKey} /></Tab.Pane>
                    <Tab.Pane eventKey="eleven"><EjectionSettings Records={Records} setRecords={setRecords} setActiveKey={setActiveKey} activeKey={activeKey} /></Tab.Pane>
                    <Tab.Pane eventKey="twele"><CoreSettings Records={Records} setRecords={setRecords} setActiveKey={setActiveKey} activeKey={activeKey} /></Tab.Pane>
                    <Tab.Pane eventKey="thirteen"><InjUnitSettings Records={Records} setRecords={setRecords} setActiveKey={setActiveKey} activeKey={activeKey} /></Tab.Pane>
                </Tab.Content>
            </div>
        </Tab.Container>
    );
}

export default NewSmpTabs;