import React, { useEffect, useState, useRef, useCallback } from "react";
import Field from "../../../Components/Fields/Field";
import Lable from "../../../Components/Fields/Lable";
import { useNavigate, useParams } from "react-router-dom";
import "./LastOff.css";
import CreateRecord from "../../../Lib/API/CreateRecord";
import ReadRecord from "../../../Lib/API/ReadRecord";
import UpdateRecord from "../../../Lib/API/UpdateRecord";
import { GetAccess } from "../../../Lib/GetAccess";
import {
  CURRENT_Date,
  SHIFTMOLDINGQUALITY,
} from "../../../Utils/GeneralConstants";
import QaLayout from "../QaLayout";
import LoadingPage from "../../../Components/Handler/LoadingPage";
import Popup from "../../../Components/Popup/Popup";
import { Dropdown } from "react-bootstrap";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";
import Logo from "../../../icons/Logo1.png";
import html2pdf from "html2pdf.js";
import Webcam from "react-webcam";
import { useScrollEffect } from "../useScrollEffect";
const LastOffForm = () => {
  const contentRef = useRef(null);
  const [showCamera, setShowCamera] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const CURRENT_TIME = new Date(
    new Date().getTime() - new Date().getTimezoneOffset() * 60000
  )
    .toISOString()
    .split("T")[1]
    .substring(0, 5);
  const initialVisual = {
    Free_from_flash: "",
    Calender_pin_to_be_verified: "",
    Free_from_color_variation: "",
    Free_from_short_mould: "",
    Free_from_sink_mark: "",
    Free_from_weld_line: "",
  };
  const initialComplaints = {
    Visual_inspection_4: "",
    Visual_inspection_3: "",
    Visual_inspection_2: "",
    Visual_inspection_1: "",
    Descripton: "",
  };
  const initialFunction = {
    Cavity_1_1: "",
    Cavity_1_2: "",
    Cavity_1_3: "",
    Cavity_2_1: "",
    Cavity_2_2: "",
    Cavity_2_3: "",
    Cavity_3_1: "",
    Cavity_3_2: "",
    Cavity_3_3: "",
    Cavity_4_1: "",
    Cavity_4_2: "",
    Cavity_4_3: "",
    Cavity_5_1: "",
    Cavity_5_2: "",
    Cavity_5_3: "",
  };
  const InitRoot = [
    {
      download: false,
      Prodcutionincharge: [{}],
      Machineno: [{}],
      Rawmaterialname: [],
      Partname: [{}],
      PartNumber: [{}],
      Onlineqcsign: [{}],
      Qasign: [{}],
      Prodcutionoperatorname: [{}],
      Part_name: "",
      Part_number: "",
      Raw_material_name: "",
      Machine_no: "",
      complaints: ["1", "2", "3"],
      cavity: [],
      dimensions_functional: [
        initialFunction,
        initialFunction,
        initialFunction,
      ],
      change_details: [{}],
      visual_inspection: [
        initialVisual,
        initialVisual,
        initialVisual,
        initialVisual,
        initialVisual,
      ],
      customer_complaints: [
        initialComplaints,
        initialComplaints,
        initialComplaints,
      ],
      dimensions: [],
      Part_weight_1: "",
      Part_weight_set_minus_1: "",
      Part_weight_set_plus_1: "",
      Part_weight_2: "",
      Part_weight_set_minus_2: "",
      Part_weight_set_plus_2: "",
      No_of_cavity: "",
      Part_colour: "",
      Part_number: "",
      Remarks: "",
      Format_no: "UPCL/QC/R/02",
      Rev_no: "05",
      Inspection_ate: "18-08-2018",
      Image: "",
      Entry_date: CURRENT_Date,
      ImageRemarks: "",
      Remarks: "",
      Capture_image: "",
      Two_k_part: "0",
    },
  ];
  const [ButtonPopup, setButtonPopup] = useState(false);
  const [DownloadDiv, setDownloadDiv] = useState(false);
  const tableRef = useRef(null);
  const scrollTop = useRef(null);
  useScrollEffect();
  const webcamRef = useRef(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageSrc(imageSrc);
    setRecords((prevRecords) => {
      const updatedRecords = [...prevRecords];
      updatedRecords[0].Capture_image = imageSrc;
      return updatedRecords;
    });
  }, [webcamRef]);

  const startCamera = () => {
    setShowCamera(true);
  };
  const stopCamera = () => {
    setShowCamera(false);
  };
  const [downloadEnabled, setDownloadEnabled] = useState(true);
  const [Records, setRecords] = useState(InitRoot);
  const [warning, setwarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(false);
  const [Raw, setRaw] = useState([]);
  const [Machine, setMachine] = useState([]);
  useEffect(() => {
    console.log("Records", Records);
  }, [Records]);

  const { option, id } = useParams();
  const navigate = useNavigate();

  function HandleRecords(Event) {
    setRecords([{ ...Records[0], [Event.target.name]: Event.target.value }]);
  }
  function handleRemarks(Event) {
    const results = Event.target.value;
    if (results.length > 10) {
      console.log("result", results);
      setResult(true);
    } else {
      setResult(false);
    }
  }

  function HandleRecordsBoolean(Event) {
    setRecords([
      { ...Records[0], [Event.target.name]: Event.target.checked + "" },
    ]);
  }

  function isInRange(value) {
    const isWithinRange =
      value >= partWeight - partWeightSet &&
      value <= partWeight + partWeightSet;

    //   if (isWithinRange) {
    //     console.log("Value is within the range");
    //   } else {
    //     console.log("Value is outside the range");
    //   }
    return isWithinRange;
  }
  function HandleStatus1() {
    console.log(
      "Cavity_1_1:",
      isInRange(Records[0].dimensions_functional[1].Cavity_1_1)
    );
    console.log(
      "Cavity_1_2:",
      isInRange(Records[0].dimensions_functional[1].Cavity_1_2)
    );
    console.log(
      "Cavity_1_3:",
      isInRange(Records[0].dimensions_functional[1].Cavity_1_3)
    );
    console.log(
      "Cavity_2_1:",
      isInRange(Records[0].dimensions_functional[1].Cavity_2_1)
    );
    console.log(
      "Cavity_2_2:",
      isInRange(Records[0].dimensions_functional[1].Cavity_2_2)
    );
    console.log(
      "Cavity_2_3:",
      isInRange(Records[0].dimensions_functional[1].Cavity_2_3)
    );
    console.log(
      "Cavity_3_1:",
      isInRange(Records[0].dimensions_functional[1].Cavity_3_1)
    );
    console.log(
      "Cavity_3_2:",
      isInRange(Records[0].dimensions_functional[1].Cavity_3_2)
    );
    console.log(
      "Cavity_3_3:",
      isInRange(Records[0].dimensions_functional[1].Cavity_3_3)
    );
    console.log(
      "Cavity_4_1:",
      isInRange(Records[0].dimensions_functional[1].Cavity_4_1)
    );
    console.log(
      "Cavity_4_2:",
      isInRange(Records[0].dimensions_functional[1].Cavity_4_2)
    );
    console.log(
      "Cavity_4_3:",
      isInRange(Records[0].dimensions_functional[1].Cavity_4_3)
    );
    console.log(
      "Cavity_5_1:",
      isInRange(Records[0].dimensions_functional[1].Cavity_5_1)
    );
    console.log(
      "Cavity_5_2:",
      isInRange(Records[0].dimensions_functional[1].Cavity_5_2)
    );
    console.log(
      "Cavity_5_3:",
      isInRange(Records[0].dimensions_functional[1].Cavity_5_3)
    );
    const isNotAccepted =
      !isInRange(Records[0].dimensions_functional[1].Cavity_1_1) ||
      !isInRange(Records[0].dimensions_functional[1].Cavity_1_2) ||
      !isInRange(Records[0].dimensions_functional[1].Cavity_1_3) ||
      !isInRange(Records[0].dimensions_functional[1].Cavity_2_1) ||
      !isInRange(Records[0].dimensions_functional[1].Cavity_2_2) ||
      !isInRange(Records[0].dimensions_functional[1].Cavity_2_3) ||
      !isInRange(Records[0].dimensions_functional[1].Cavity_3_1) ||
      !isInRange(Records[0].dimensions_functional[1].Cavity_3_2) ||
      !isInRange(Records[0].dimensions_functional[1].Cavity_3_3) ||
      !isInRange(Records[0].dimensions_functional[1].Cavity_4_1) ||
      !isInRange(Records[0].dimensions_functional[1].Cavity_4_2) ||
      !isInRange(Records[0].dimensions_functional[1].Cavity_4_3) ||
      !isInRange(Records[0].dimensions_functional[1].Cavity_5_1) ||
      !isInRange(Records[0].dimensions_functional[1].Cavity_5_2) ||
      !isInRange(Records[0].dimensions_functional[1].Cavity_5_3);
    const partStatus = isNotAccepted ? "Not Accepted" : "Accepted";
    setRecords([{ ...Records[0], Part_status: partStatus }]);
    console.log(partStatus);
  }

  function ResetRecords() {
    let record = InitRoot;
    setRecords(record);
    setRaw([]);
    setMachine([]);
  }
  function HandleRecordsBoolean2K(Event) {
    console.log("Event.target.name", Event.target.name);
    ResetRecords();
    if (Event.target.checked == true) {
      setRecords((record) => {
        record[0][Event.target.name] = "1";
        return [...record];
      });
    } else {
      setRecords((record) => {
        record[0][Event.target.name] = "0";
        return [...record];
      });
    }
    // setRecords((record)=>{

    //   record[0][Event.target.name]= Event.target.checked == true ? "1" : "0";
    //   return [...record];
    // })
  }
  function HandleRecordSelect(Event, column, value, label) {
    setRecords(() => {
      let record = [...Records];
      record[0][Event.name] = Event.values.value;
      record[0][column][0][value] = Event.values.value;
      record[0][column][0][label] = Event.values.label;
      return record;
    });
  }
  function HandleRecordSelectRaw1(Event, column, val, lab) {
    setRecords(() => {
      let record = [...Records];
      console.log("record122", record);
      record[0][Event.name] = Event.values.value;
      if (record[0][column].length === 0) {
        record[0][column].push({
          Mh_raw_mat: Event.values.value,
          Mh_raw_mat_name: Event.values.label,
        });
      } else {
        record[0][column][0]["Mh_raw_mat"] = Event.values.value;
        record[0][column][0]["Mh_raw_mat_name"] = Event.values.label;
      }

      // record[0][column][0][value] = Event.values.value;
      // record[0][column][0][label] = Event.values.label;
      return record;
    });
  }
  function HandleRecordSelectRaw2(Event, column, value, label) {
    setRecords(() => {
      let record = [...Records];
      console.log("Length", Event.values);
      if (Event.values.length <= 2) {
        record[0][Event.name] = JSON.stringify(Event.values);
        // record[0][column][0][value] = Event.values.value;
        // record[0][column][0][label] = Event.values.label;
        record[0][column] = Event.values;
      }
      return record;
    });
  }
  async function HandlePartCode(Event) {
    (Records[0].Image = ""), (Records[0].Part_name = "");
    Records[0].No_of_cavity = "";
    Records[0].Part_colour = "";
    Records[0]["Partname"][0]["Part_name"] = "";
    (Records[0].Raw_material_name = ""),
      (Records[0].Rawmaterialname = []),
      //   Records[0]["Rawmaterialname"][0]["Mh_raw_mat"] = "";
      // Records[0]["Rawmaterialname"][0]["Mh_raw_mat_name"] = "";
      console.log("Event", Event.values.value);
    // GetRawMaterial(Event.values.value);
    // if (await IsDuplicate("quality_product_plan", [`part_number="${Event.values.value}"`])) {
    ReadRecord({
      _keyword_: "Get_part_name_from_quality_plan_by_code",
      part: Event.values.value,
    }).then((res) => {
      if (res.rows === "1") {
        setRecords(() => {
          //       console.log("Event.values.value", Event.values.value);
          let record = [...Records];
          (record[0].Part_number = Event.values.value),
            (record[0]["PartNumber"][0]["Part_number"] = Event.values.value);
          record[0]["PartNumber"][0]["Part_number"] = Event.values.label;
          (record[0].Part_name = res.data[0].Part_name),
            (record[0]["Partname"][0]["Part_name"] = res.data[0].Part_name);
          record[0]["Partname"][0]["Part_name"] = res.data[0].Part_name;
          console.log("12131213", record);
          GetModelPart(res.data[0].Part_name);
          GetMachineNo(res.data[0].Part_name);
          GetRawMaterial(res.data[0].Part_name);
          GetPwandSpec(Event.values.value, res.data[0].Part_name);
          return record;
        });
      } else if (res.rows > "1") {
        console.log("res.data", res.data);
        setRecords(() => {
          let record = [...Records];
          (record[0].Part_number = Event.values.value),
            (record[0]["PartNumber"][0]["Part_number"] = Event.values.value);
          record[0]["PartNumber"][0]["Part_number"] = Event.values.label;
          return record;
        });
      }
    });
    // } else {
    //   alert("Part Number already Has Record in Quality Plan Records")
    // }
  }
  // function HandlePartname(Event) {

  //   GetRawMaterial(Event.values.value);
  //   GetModelPart(Event.values.value);
  //   GetMachineNo(Event.values.value);
  //   if (Event.values.value !== "") {
  //     console.log(Part_number.value);

  //     ReadRecord({
  //       _keyword_: "FirstOff_by_Productplan_qt",
  //       mc_part_code: Event.values.value,
  //     }).then((res1) => {
  //       console.log(res1.data[0]);
  //       if (res1.data[0] === undefined) {
  //         console.error("no return");
  //       } else {
  //         setRecords((prevRecords) => {
  //           let Records = [...prevRecords];
  //           const { dimensions } = res1.data[0];
  //           if (dimensions && dimensions.length > 0) {
  //             const numberOfArrays = dimensions.filter(
  //               (obj) =>
  //                 obj.Specification_minus !== undefined &&
  //                 obj.Specification_plus !== undefined &&
  //                 obj.Specification_val !== undefined &&
  //                 obj.Specification_minus !== "" &&
  //                 obj.Specification_plus !== "" &&
  //                 obj.Specification_val !== ""
  //             ).length;
  //             Records[0].cavity = Array.from({ length: numberOfArrays }, (_, i) => i + 1);
  //             Records[0].dimensions = Array.from({ length: numberOfArrays }, (_, i) => ({
  //               Specification_minus: res1.data[0].dimensions[i].Specification_minus,
  //               Specification_plus: res1.data[0].dimensions[i].Specification_plus,
  //               Specification_val: res1.data[0].dimensions[i].Specification_val,
  //               Specifications_loc: res1.data[0].dimensions[i].Specifications_loc,
  //             })
  //             );
  //             Records[0] = {
  //               ...Records[0],
  //               Image: res1.data[0].Image === undefined ? "" : res1.data[0].Image,
  //               Part_weight_1: res1.data[0].Part_weight_1,
  //               Part_weight_set_minus_1: res1.data[0].Part_weight_set_minus_1,
  //               Part_weight_set_plus_1: res1.data[0].Part_weight_set_plus_1,
  //               Part_weight_2: res1.data[0].Part_weight_2,
  //               Part_weight_set_minus_2: res1.data[0].Part_weight_set_minus_2,
  //               Part_weight_set_plus_2: res1.data[0].Part_weight_set_plus_2,
  //             };
  //             return Records;
  //           }
  //           return prevRecords;
  //         });
  //       }
  //     });
  //   }
  // }
  function HandlePartname(Event) {
    (Records[0].Image = ""), GetRawMaterial(Event.values.value);
    GetModelPart(Event.values.value);
    GetMachineNo(Event.values.value);
    GetPartCodeByName(Event.values.value);
    //GetPwandSpec(Records[0]?.Part_number ,Event.values.value);
    // }
  }
  function GetPartCodeByName(val) {
    ReadRecord({
      _keyword_: "Get_part_code_from_quality_plan_by_name",
      mc_part_code: val,
    }).then((res) => {
      if (res.rows == "1") {
        setRecords(() => {
          //       console.log("Event.values.value", Event.values.value);
          let record = [...Records];
          (record[0].Part_number = res.data[0].Part_number),
            (record[0]["PartNumber"][0]["Part_number"] =
              res.data[0].Part_number);
          record[0]["PartNumber"][0]["Part_number"] = res.data[0].Part_number;
          GetPwandSpec(res.data[0].Part_number ,val);
          return record;
        });
      } else if (res.rows > "1") {
      }
    });
  }
  function GetPwandSpec(code, name) {
    ReadRecord({
      _keyword_: "FirstOff_by_Productplan_qt",
      mc_part_code: code,
      mc_part_name: name,
    }).then((res1) => {
      console.log(res1.data[0]);
      // if (res1.data[0] === undefined) {
      //   console.error("no return");
      //   setRecords(InitRoot)
      //   alert("Part Name is Not Available in Quality Plan!")
      // } else {
      if (res1.rows == "1") {
        setRecords((prevRecords) => {
          let Records = [...prevRecords];
          const { dimensions } = res1.data[0];
          if (dimensions && dimensions.length > 0) {
            const numberOfArrays = dimensions.filter(
              (obj) =>
                // obj.Specification_minus !== undefined &&
                // obj.Specification_plus !== undefined &&
                obj.Specification_val !== undefined &&
                // obj.Specification_minus !== "" &&
                // obj.Specification_plus !== "" &&
                obj.Specification_val !== ""
            ).length;
            Records[0].cavity = Array.from(
              { length: numberOfArrays },
              (_, i) => i + 1
            );
            Records[0].dimensions = Array.from(
              { length: numberOfArrays },
              (_, i) => ({
                Specification_minus:
                  res1.data[0].dimensions[i].Specification_minus,
                Specification_plus:
                  res1.data[0].dimensions[i].Specification_plus,
                Specification_val: res1.data[0].dimensions[i].Specification_val,
                Specifications_loc:
                  res1.data[0].dimensions[i].Specifications_loc,
                Checking_aids: res1.data[0].dimensions[i].Checking_aids,
                Specification_symbol_1: res1.data[0].dimensions[i]
                  .Specification_symbol_1
                  ? res1.data[0].dimensions[i].Specification_symbol_1
                  : "-",
                Specification_symbol_2: res1.data[0].dimensions[i]
                  .Specification_symbol_2
                  ? res1.data[0].dimensions[i].Specification_symbol_2
                  : "+",
              })
            );
            Records[0] = {
              ...Records[0],
              Image: res1.data[0].Image === undefined ? "" : res1.data[0].Image,
              Part_weight_1: res1.data[0].Part_weight_1,
              Part_weight_set_minus_1: res1.data[0].Part_weight_set_minus_1,
              Part_weight_set_plus_1: res1.data[0].Part_weight_set_plus_1,
              Part_weight_2: res1.data[0].Part_weight_2,
              Part_weight_set_minus_2: res1.data[0].Part_weight_set_minus_2,
              Part_weight_set_plus_2: res1.data[0].Part_weight_set_plus_2,
            };
            // console.log("Updated Cavity Array:", Records[0].dimensions);
            // console.log("Updated Records:", Records);
            return Records;
          }
          return prevRecords;
        });
      }
    });
  }
  function GetModelPart(val) {
    ReadRecord({
      _keyword_: "Get_model_by_part_name",
      part: val,
    }).then((res) => {
      if (res.rows > 0) {
        console.log("respose of raw", res.data);
        setRecords(() => {
          let record = [...Records];
          record[0].No_of_cavity = res.data[0].mc_part_flex_02;
          return record;
        });
      }
    });
  }
  function GetRawMaterial(val) {
    ReadRecord({
      _keyword_: "Get_raw_material_by_part",
      part: val,
    }).then((res) => {
      if (res.rows == "1") {
        if (Records[0].Two_k_part == "0") {
          console.log("respose of raw", res.data);
          setRecords(() => {
            //       console.log("res.data[0].Part_numberEvent.values.value", Event.values.value);
            let record = [...Records];
            (record[0].Raw_material_name = res.data[0].Mh_raw_mat),
              (record[0].Part_colour = res.data[0].Rm_Colour),
              record[0]["Rawmaterialname"].push({
                Mh_raw_mat: res.data[0].Mh_raw_mat,
                Mh_raw_mat_name: res.data[0].Mh_raw_mat_name,
              });
            setRaw(res.data);
            //   record[0]["Rawmaterialname"][0]["Mh_raw_mat"] = res.data[0].Mh_raw_mat;
            // record[0]["Rawmaterialname"][0]["Mh_raw_mat_name"] = res.data[0].Mh_raw_mat_name;
            return record;
          });
        } else {
          setRaw(res.data);
        }
      } else if (res.rows > "1") {
        setRaw(res.data);
        // alert("Contact Administrator to add Raw Material for this Selected Part!");
      }
    });
  }
  function GetGradeColour(Event) {
    if (Records[0].Two_k_part == "0") {
      const selctedRaw = Raw.find(
        (option) => option.Mh_raw_mat === Event.values.value
      );
      let record = [...Records];
      record[0].Part_colour = selctedRaw.Rm_Colour;
      return record;
    } else {
      console.log("Event", Event);

      if (Event.values.length <= 2) {
        let colour = [];
        Event.values.map((item, index) => {
          const selctedRaw = Raw.find(
            (option) => option.Mh_raw_mat === item.value
          );
          colour.push(selctedRaw.Rm_Colour);
        });
        console.log("selctedRaw", colour);
        let record = [...Records];

        record[0].Part_colour = colour.toString();
        return record;
      }
    }
  }
  function GetMachineNo(val) {
    ReadRecord({
      _keyword_: "Get_machine_by_part_name",
      part: val,
    }).then((res) => {
      if (res.rows == "1") {
        console.log("machinenen", res.data);
        setRecords(() => {
          //       console.log("Event.values.value", Event.values.value);
          let record = [...Records];
          (record[0].Machine_no = res.data[0].mh_mac_name),
            (record[0]["Machineno"][0]["mh_mac_name"] =
              res.data[0].mh_mac_name);
          record[0]["Machineno"][0]["mh_mac_name"] = res.data[0].mh_mac_name;
          return record;
        });
      } else if (res.rows > "1") {
        setMachine(res.data);
        // alert("Contact Administrator to add Machine for this Selected Part!");
      }
    });
  }
  const partWeight = parseFloat(Records[0].Part_weight_1);
  const partWeightSet = parseFloat(Records[0].Part_weight_set);

  //  if (!isNaN(partWeight) && !isNaN(partWeightSet)) {
  //    const minValue = partWeight - partWeightSet;
  //    const maxValue = partWeight + partWeightSet;

  //   //  console.log('min', minValue);
  //   //  console.log('max', maxValue);
  //  } else {
  //  //  console.log("Part_weight or Part_weightSet is not a valid number.");
  //  }

  function HandleChangeDetails(Event) {
    setRecords((item) => {
      let record = [...Records];
      record[0].change_details[0] = {
        ...record[0].change_details[0],
        [Event.target.name]: Event.target.checked + "",
      };
      return record;
    });
  }
  function Handlevisualinspection(Event, index) {
    setRecords((item) => {
      let record = [...Records];
      record[0].visual_inspection[index] = {
        ...record[0].visual_inspection[index],
        [Event.target.name]: Event.target.value,
      };
      return record;
    });
  }
  function Handlecustomercomplaints(Event, index) {
    setRecords((item) => {
      let record = [...Records];
      record[0].customer_complaints[index] = {
        ...record[0].customer_complaints[index],
        [Event.target.name]: Event.target.value,
      };
      return record;
    });
  }
  function HandleDimension(Event, index) {
    setRecords((item) => {
      let record = [...Records];
      record[0].dimensions[index] = {
        ...record[0].dimensions[index],
        [Event.target.name]: Event.target.value,
      };
      return record;
    });
  }
  function HandleDimensionFunction(Event, index) {
    setRecords((item) => {
      let record = [...Records];
      record[0].dimensions_functional[index] = {
        ...record[0].dimensions_functional[index],
        [Event.target.name]: Event.target.value,
      };
      return record;
    });
  }

  function HandleCreate(event) {
    if (
      !Records[0].Part_name ||
      !Records[0].Part_number ||
      !Records[0].Raw_material_name ||
      !Records[0].Machine_no ||
      !Records[0].Prodcution_operator_name ||
      !Records[0].Prodcution_incharge ||
      !Records[0].Online_qc_sign ||
      !Records[0].Qa_sign ||
      !Records[0].No_of_cavity ||
      !Records[0].Part_colour ||
      (Records[0].Remarks.length > 10 && !Records[0].ImageRemarks) ||
      (Records[0].Two_k_part === "1" && Records[0].Rawmaterialname.length == 1)
    ) {
      alert("Please Fill the Mandatory Fields");
      setwarning(true);
    } else {
      let record = [...Records];
      record && delete record[0].complaints;
      record && delete record[0].cavity;
      record && delete record[0].Machineno;
      record && delete record[0].Onlineqcsign;
      record && delete record[0].Partname;
      record && delete record[0].PartNumber;
      record && delete record[0].Prodcutionincharge;
      record && delete record[0].Prodcutionoperatorname;
      record && delete record[0].Qasign;
      record && delete record[0].Rawmaterialname;
      record && delete record[0].download;

      CreateRecord("quality_last_off_inspection", record).then((res) => {
        console.log("HandleCreate", res);
      });

      alert("Submited Successfully");
      setwarning(false);
      setRecords(InitRoot);
      if (scrollTop.current) {
        scrollTop.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }
  function HandleUpdate(event) {
    let record = [...Records];

    record && delete record[0].complaints;
    record && delete record[0].cavity;
    record && delete record[0].Machineno;
    record && delete record[0].Onlineqcsign;
    record && delete record[0].Partname;
    record && delete record[0].PartNumber;
    record && delete record[0].Prodcutionincharge;
    record && delete record[0].Prodcutionoperatorname;
    record && delete record[0].Qasign;
    record && delete record[0].Rawmaterialname;
    record && delete record[0].download;

    UpdateRecord("quality_last_off_inspection", record).then((res) => {
      console.log("HandleUpdate", res);
    });
    alert("Record Updated!");
    setwarning(false);
    setRecords(InitRoot);
    navigate(-1);
  }
  useEffect(() => {
    console.log("id", id);
    setwarning(false);
    document.title = "Last Off Inspection";
    const fetchData = async () => {
      if (id === undefined) {
        setRecords([
          {
            ...Records[0],
            Entry_date: CURRENT_Date,
            Time: CURRENT_TIME,
            Shift: SHIFTMOLDINGQUALITY,
          },
        ]);
      } else {
        setLoading(true);
        try {
          const res = await ReadRecord({
            _keyword_: "quality_last_off_inspection",
            Id: id,
          });
          if (res.data[0] === undefined) {
            setRecords(InitRoot);
          } else {
            if (res.data[0].Two_k_part == "1") {
              console.log(res.data[0].dimensions.length);
              const dynamicDimensions = res.data[0].dimensions || [];
              res.data[0].cavity = Array.from(
                { length: dynamicDimensions.length },
                (_, i) => i + 1
              );
              res.data[0].complaints = ["1", "2", "3"];
              // setRecords(res.data);
              // console.log("res2", res.data[0].ImageRemarks);
              const fetchedData = res.data[0];
              setRaw(JSON.parse(fetchedData.Raw_material_name));
              setRecords([
                {
                  ...fetchedData,
                  dimensions: fetchedData.dimensions.map((dimension) => ({
                    ...dimension,
                    Specification_symbol_1:
                      dimension.Specification_symbol_1 || "-",
                    Specification_symbol_2:
                      dimension.Specification_symbol_2 || "+",
                  })),
                  Rawmaterialname: JSON.parse(fetchedData.Raw_material_name),
                },
              ]);
            } else {
              console.log(res.data[0].dimensions.length);
              const dynamicDimensions = res.data[0].dimensions || [];
              res.data[0].cavity = Array.from(
                { length: dynamicDimensions.length },
                (_, i) => i + 1
              );
              res.data[0].complaints = ["1", "2", "3"];
              // setRecords(res.data);
              // console.log("res2", res.data[0].ImageRemarks);
              const fetchedData = res.data[0];
              setRecords([
                {
                  ...fetchedData,
                  dimensions: fetchedData.dimensions.map((dimension) => ({
                    ...dimension,
                    Specification_symbol_1:
                      dimension.Specification_symbol_1 || "-",
                    Specification_symbol_2:
                      dimension.Specification_symbol_2 || "+",
                  })),
                },
              ]);
            }

            if (res.data[0].ImageRemarks) {
              setResult(true);
            } else {
              setResult(false);
            }
          }
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  const viewPagination = () => {
    console.log("");
  };
  const generatePDF = () => {
    setDownloadDiv(true);
    setTimeout(() => {
      document.querySelector(".myTable").style.display = "table";
      const pdf = new jsPDF("portrait", "mm", "a4");
      html2canvas(contentRef.current).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        pdf.addImage(imgData, "PNG", 5, 5, 200, 290);
        pdf.setTextColor(50, 50, 50);
        pdf.save(`LastOff.pdf`);
      });
      setTimeout(() => {
        document.querySelector(".myTable").style.display = "none";
      }, 1000);
    }, 0);
  };

  const Part1 = `${Records[0].Part_weight_1}(-${Records[0].Part_weight_set_minus_1}+${Records[0].Part_weight_set_plus_1}GMS)`;
  const Part2 = `${Records[0].Part_weight_2}(-${Records[0].Part_weight_set_minus_2}+${Records[0].Part_weight_set_plus_2}GMS)`;
  const onButtonClick = () => {
    setDownloadEnabled(false);

    const dropdown = document.querySelector(".LastOffDownload");
    if (dropdown) {
      dropdown.style.display = "none";
    }
    const pdf = new jsPDF("portrait", "mm", "a4");
    html2canvas(tableRef.current).then((canvas) => {
      if (dropdown) {
        dropdown.style.display = "block";
      }

      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 5, 5, 200, 280);
      pdf.setTextColor(50, 50, 50);
      pdf.save(`LastOff.pdf`);
      setDownloadEnabled(true);
    });
  };
  function processDimensions(specs) {
    const loc = specs?.Specifications_loc || "";
    const val = specs?.Specification_val || "";
    const symbol1 = specs?.Specification_symbol_1 || "";
    const minus = specs?.Specification_minus || "";
    const symbol2 = specs?.Specification_symbol_2 || "";
    const plus = specs?.Specification_plus || "";
    return [`${loc} * ${val} (${symbol1} ${minus} / ${symbol2} ${plus})`];
  }
  const specs1 = Records[0].dimensions[0];
  const dim1 = processDimensions(specs1);
  const specs2 = Records[0].dimensions[1];
  const dim2 = processDimensions(specs2);
  const specs3 = Records[0].dimensions[2];
  const dim3 = processDimensions(specs3);
  const specs4 = Records[0].dimensions[3];
  const dim4 = processDimensions(specs4);

  const exportToExcel = () => {
    setDownloadDiv(true);
    setTimeout(() => {
      const wb = XLSX.utils.book_new();
      const wsData = [
        [
          "",
          "Last Off Inspection",
          "",
          "",
          "",
          "",
          "FORMAT NO:",
          Records[0].Format_no,
        ],
        ["", "", "", "", "", "", "REV NO:", Records[0].Rev_no],
        ["", "", "", "", "", "", "REV DATE:", Records[0].Inspection_ate],
        [
          "Part Number:",
          Records[0].Part_number,
          "Part Name:",
          Records[0].Part_name,
          "Date:",
          Records[0].Entry_date,
        ],
        [
          "No Of Cavity:",
          Records[0].No_of_cavity,
          "Part Colour:",
          Records[0].Part_colour,
          "Shift:",
          Records[0].Shift,
        ],
        [
          "Rawmaterial:",
          Records[0].Two_k_part == "0"
          ? Records[0].Rawmaterialname[0].label +
          "," +
          Records[0]?.Rawmaterialname[1]?.label
          :Records[0]?.Rawmaterialname[0]?.Mh_raw_mat_name,
        
          "Machine No:",
          Records[0].Machine_no,
          "Time:",
          Records[0].Time,
        ],
        ["", "", "Change Details", "", ""],
        [
          "1. End of Production:",
          Records[0].change_details[0].end_of_production,
          "2. Mould BreakDown:",
          Records[0].change_details[0].Mould_breakdown,
        ],
        [
          "3. Material Change Bpm/Colour Change Bpm:",
          Records[0].change_details[0].material_change_bpm
          ,
          "4. Machine Breakdown::",
          Records[0].change_details[0].Machine_breakdown,
        ],
        [""],
        ["", "", "VISUAL INSPECTION", ""],
        ["SHORTS", "1", "2", "3", "4", "5"],
        [
          "1. " + " FREE FROM FLASH",
          Records[0].visual_inspection[0].Free_from_flash,
          Records[0].visual_inspection[1].Free_from_flash,
          Records[0].visual_inspection[2].Free_from_flash,
          Records[0].visual_inspection[3].Free_from_flash,
          Records[0].visual_inspection[4].Free_from_flash,
        ],
        [
          "2. " + " FREE FROM SHORT MOULD",
          Records[0].visual_inspection[0].Free_from_short_mould,
          Records[0].visual_inspection[1].Free_from_short_mould,
          Records[0].visual_inspection[2].Free_from_short_mould,
          Records[0].visual_inspection[3].Free_from_short_mould,
          Records[0].visual_inspection[4].Free_from_short_mould,
        ],
        [
          "3. " + " FREE FROM SINK MARK",
          Records[0].visual_inspection[0].Free_from_sink_mark,
          Records[0].visual_inspection[1].Free_from_sink_mark,
          Records[0].visual_inspection[2].Free_from_sink_mark,
          Records[0].visual_inspection[3].Free_from_sink_mark,
          Records[0].visual_inspection[4].Free_from_sink_mark,
        ],
        [
          "4. " + " FREE FROM WELD LINE",
          Records[0].visual_inspection[0].Free_from_weld_line,
          Records[0].visual_inspection[1].Free_from_weld_line,
          Records[0].visual_inspection[2].Free_from_weld_line,
          Records[0].visual_inspection[3].Free_from_weld_line,
          Records[0].visual_inspection[4].Free_from_weld_line,
        ],
        [
          "5. " + " FREE FROM COLOUR VARIATION",
          Records[0].visual_inspection[0].Free_from_color_variation,
          Records[0].visual_inspection[1].Free_from_color_variation,
          Records[0].visual_inspection[2].Free_from_color_variation,
          Records[0].visual_inspection[3].Free_from_color_variation,
          Records[0].visual_inspection[4].Free_from_color_variation,
        ],
        [
          "6. " + " CALENDER PIN TO BE VERIFIED (YEAR A MONTH)",
          Records[0].visual_inspection[0].Calender_pin_to_be_verified,
          Records[0].visual_inspection[1].Calender_pin_to_be_verified,
          Records[0].visual_inspection[2].Calender_pin_to_be_verified,
          Records[0].visual_inspection[3].Calender_pin_to_be_verified,
          Records[0].visual_inspection[4].Calender_pin_to_be_verified,
        ],
        ["", "", "CUSTOMER", "COMPLAINTS", "HISTORY", "", "", ""],
        [
          "1. " + Records[0].customer_complaints[0].Descripton,
          Records[0].customer_complaints[0].Visual_inspection_1,
          Records[0].customer_complaints[0].Visual_inspection_2,
          Records[0].customer_complaints[0].Visual_inspection_3,
          Records[0].customer_complaints[0].Visual_inspection_4,
          Records[0].customer_complaints[0].Visual_inspection_4,
        ],
        [
          "2. " + Records[0].customer_complaints[1].Descripton,
          Records[0].customer_complaints[1].Visual_inspection_1,
          Records[0].customer_complaints[1].Visual_inspection_2,
          Records[0].customer_complaints[1].Visual_inspection_3,
          Records[0].customer_complaints[1].Visual_inspection_4,
          Records[0].customer_complaints[1].Visual_inspection_5,
        ],
        ["3. "],
        [
          "DIMENSIONS / CAVITY",
          "CAVITY#1",
          "",
          "",
          "CAVITY#2",
          "",
          "",
          "CAVITY#3",
          "",
          "",
          "CAVITY#4",
          "",
          "",
          "CAVITY#5",
          "",
          "",
          "CAVITY#6",
          "",
          "",
          "CAVITY#7",
          "",
          "",
          "CAVITY#8",
          "",
          "",
        ],
      ];
      const dim1Row = [];
      for (let j = 1; j <= 8; j++) {
        for (let k = 1; k <= 3; k++) {
          const cavity = `Cavity_${j}_${k}`;
          if (Records[0]?.dimensions?.[0]?.[cavity] !== undefined) {
            dim1Row.push(Records[0].dimensions[0][cavity]);
          } else {
            dim1Row.push("");
          }
        }
      }
      wsData.push([`" 1.  " + ${dim1}`, ...dim1Row]);
      const dim2Row = [];
      for (let j = 1; j <= 8; j++) {
        for (let k = 1; k <= 3; k++) {
          const cavity = `Cavity_${j}_${k}`;
          if (Records[0]?.dimensions?.[1]?.[cavity] !== undefined) {
            dim2Row.push(Records[0].dimensions[1][cavity]);
          } else {
            dim2Row.push("");
          }
        }
      }
      wsData.push([`" 2.  " + ${dim2}`, ...dim2Row]);
      const dim3Row = [];
      for (let j = 1; j <= 8; j++) {
        for (let k = 1; k <= 3; k++) {
          const cavity = `Cavity_${j}_${k}`;
          if (Records[0]?.dimensions?.[2]?.[cavity] !== undefined) {
            dim3Row.push(Records[0].dimensions[2][cavity]);
          } else {
            dim3Row.push("");
          }
        }
      }
      wsData.push([`" 3.  " + ${dim3}`, ...dim3Row]);
      const dim4Row = [];
      for (let j = 1; j <= 8; j++) {
        for (let k = 1; k <= 3; k++) {
          const cavity = `Cavity_${j}_${k}`;
          if (Records[0]?.dimensions?.[3]?.[cavity] !== undefined) {
            dim4Row.push(Records[0].dimensions[3][cavity]);
          } else {
            dim4Row.push("");
          }
        }
      }
      wsData.push([`" 4.  " + ${dim4}`, ...dim4Row]);
      wsData.push(
        ["", "", " FUNCTIONAL ", ""],
        [
          "1. MATING/GAUGE CHECKING",
          Records[0].dimensions_functional[0].Cavity_1_1,
          Records[0].dimensions_functional[0].Cavity_1_2,
          Records[0].dimensions_functional[0].Cavity_1_3,
          Records[0].dimensions_functional[0].Cavity_2_1,
          Records[0].dimensions_functional[0].Cavity_2_2,
          Records[0].dimensions_functional[0].Cavity_2_3,
          Records[0].dimensions_functional[0].Cavity_3_1,
          Records[0].dimensions_functional[0].Cavity_3_2,
          Records[0].dimensions_functional[0].Cavity_3_3,
          Records[0].dimensions_functional[0].Cavity_4_1,
          Records[0].dimensions_functional[0].Cavity_4_2,
          Records[0].dimensions_functional[0].Cavity_4_3,
          Records[0].dimensions_functional[0].Cavity_5_1,
          Records[0].dimensions_functional[0].Cavity_5_2,
          Records[0].dimensions_functional[0].Cavity_5_3,
        ],
        [
          "2. PartWeight 1 :" +
            "  (" +
            Records[0].Part_weight_1 +
            ") " +
            "-" +
            Records[0].Part_weight_set_minus_1 +
            "/+" +
            Records[0].Part_weight_set_plus_1,
          Records[0].dimensions_functional[1].Cavity_1_1,
          Records[0].dimensions_functional[1].Cavity_1_2,
          Records[0].dimensions_functional[1].Cavity_1_3,
          Records[0].dimensions_functional[1].Cavity_2_1,
          Records[0].dimensions_functional[1].Cavity_2_2,
          Records[0].dimensions_functional[1].Cavity_2_3,
          Records[0].dimensions_functional[1].Cavity_3_1,
          Records[0].dimensions_functional[1].Cavity_3_2,
          Records[0].dimensions_functional[1].Cavity_3_3,
          Records[0].dimensions_functional[1].Cavity_4_1,
          Records[0].dimensions_functional[1].Cavity_4_2,
          Records[0].dimensions_functional[1].Cavity_4_3,
          Records[0].dimensions_functional[1].Cavity_5_1,
          Records[0].dimensions_functional[1].Cavity_5_2,
          Records[0].dimensions_functional[1].Cavity_5_3,
        ],
        [
          "3. PartWeight 2 :" +
            "  (" +
            Records[0].Part_weight_2 +
            ") " +
            "-" +
            Records[0].Part_weight_set_minus_2 +
            "/+" +
            Records[0].Part_weight_set_plus_2,
          Records[0].dimensions_functional[2].Cavity_1_1,
          Records[0].dimensions_functional[2].Cavity_1_2,
          Records[0].dimensions_functional[2].Cavity_1_3,
          Records[0].dimensions_functional[2].Cavity_2_1,
          Records[0].dimensions_functional[2].Cavity_2_2,
          Records[0].dimensions_functional[2].Cavity_2_3,
          Records[0].dimensions_functional[2].Cavity_3_1,
          Records[0].dimensions_functional[2].Cavity_3_2,
          Records[0].dimensions_functional[2].Cavity_3_3,
          Records[0].dimensions_functional[2].Cavity_4_1,
          Records[0].dimensions_functional[2].Cavity_4_2,
          Records[0].dimensions_functional[2].Cavity_4_3,
          Records[0].dimensions_functional[2].Cavity_5_1,
          Records[0].dimensions_functional[2].Cavity_5_2,
          Records[0].dimensions_functional[2].Cavity_5_3,
        ]
      );
      wsData.push(
        ["REMARKS-ANY PROBLEM UP DATED IN THIS COLUMN:"],
        ["", Records[0].Remarks],
        [
          "NOT APPLICABLE:",
          "N A",
          "OK:",
          "✓",
          "NOT OK:",
          "X",
          "ACCEPTED/NOT ACCEPTED",
        ],
        [
          "PRODUCTION OPERATOR NAME:",
          "PRODUCTION INCHARGE:",
          "ON LINE QC:",
          "QC HOD:",
        ],
        [
          Records[0].Prodcutionoperatorname[0].Emp_name,
          Records[0].Prodcutionincharge[0].Emp_name,
          Records[0].Onlineqcsign[0].Emp_name,
          Records[0].Qasign[0].Emp_name,
        ],
        [
          "Has the Job been set as per the Master Setting data:",
          Records[0].Master_setting_dates,
          "Store the last of Part with details:",
          Records[0].Store_the_last_of_part_with_details,
        ]
      );
      const ws = XLSX.utils.aoa_to_sheet(wsData);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "LastOff.xlsx");
    }, 0);
  };
  return (
    <QaLayout record={Records} quary={"quality_last_off_inspection"}>
      <div className="excel">
        {downloadEnabled && option === "view" && (
          <Dropdown style={{ width: "100%", al: "center" }}>
            <Dropdown.Toggle style={{ color: "black" }}>
              Download ⬇️
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>
                <button
                  className="custom-button"
                  style={{ color: "black" }}
                  onClick={generatePDF}
                >
                  PDF ⬇️
                </button>
              </Dropdown.Item>
              <Dropdown.Item>
                <button
                  className="custom-button"
                  style={{ color: "black" }}
                  onClick={() => exportToExcel()}
                >
                  Export to Excel
                </button>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
      <div className="Form" ref={scrollTop}>
        <div ref={tableRef}>
          <table>
            <tbody>
              <tr>
                <th colSpan={14}>
                  <Lable value={"Last Off Inspection"} type={"Head"} />
                  <input
                    style={{ width: "20px", height: "20px" }}
                    type="checkbox"
                    name="Two_k_part"
                    value={Records[0]}
                    checked={Records[0].Two_k_part == "1"}
                    disabled={option === "view" || option === "edit"}
                    onChange={(e) => {
                      HandleRecordsBoolean2K(e);
                    }}
                  />
                  Enable 2K Part
                </th>
                {/* {Records[0].download && ( */}
                <td colSpan={5}>
                  <tr>
                    <td style={{ width: "25%" }}>
                      <Field
                        label={"FORMAT NO"}
                        name={"Format_no"}
                        style={{ padding: "0px" }}
                        options={{
                          type: "text",
                          // defaultValue: "UPCL/QC/R/02",
                          disabled: true,
                        }}
                        value={Records[0]}
                        OnChange={(e) => {
                          HandleRecords(e);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "25%" }}>
                      <Field
                        label={"REV NO"}
                        name={"Rev_no"}
                        style={{ padding: "0px" }}
                        options={{
                          type: "text",
                          // defaultValue: "05",
                          disabled: true,
                        }}
                        value={Records[0]}
                        OnChange={(e) => {
                          HandleRecords(e);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "25%" }}>
                      <Field
                        label={"REV DATE"}
                        name={"Inspection_ate"}
                        style={{ padding: "0px" }}
                        options={{
                          type: "text",
                          // defaultValue: "18-08-2018",
                          disabled: true,
                        }}
                        value={Records[0]}
                        OnChange={(e) => {
                          HandleRecords(e);
                        }}
                      />
                    </td>
                  </tr>
                </td>
                {/* )} */}
              </tr>
              <tr>
                <td colSpan={7}>
                  {/* <Field
                  label={"Part Number"}
                  name={"Part_number"}
                  options={{ type: "text", disabled: true }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                /> */}
                  <Field
                    label={"Part Number"}
                    name={"Part_number"}
                    options={{
                      type: "reference",
                      reference: "Get_part_no_from_Quality_plan",
                      required: true,
                      disabled: option === "view" || option === "edit",
                    }}
                    warning={warning}
                    value={{
                      value: Records[0].PartNumber[0]
                        ? Records[0].PartNumber[0].Part_number
                        : "",
                      label: Records[0].PartNumber[0]
                        ? Records[0].PartNumber[0].Part_number
                        : "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "PartNumber",
                        "Part_number",
                        "Part_number"
                      );
                      HandlePartCode(e);
                    }}
                  />
                  {/* {console.log("Part_name",Records[0])} */}
                </td>
                <td colSpan={6}>
                  <Field
                    label={"Part Name"}
                    name={"Part_name"}
                    options={{
                      type: "reference",
                      key: Records[0].Part_number,
                      reference: "Get_part_name_from_Quality_plan",
                      required: true,
                      disabled: option === "view" || option === "edit",
                    }}
                    warning={warning}
                    value={{
                      value: Records[0].Partname[0]
                        ? Records[0].Partname[0].Part_name
                        : "",
                      label: Records[0].Partname[0]
                        ? Records[0].Partname[0].Part_name
                        : "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "Partname",
                        "Part_name",
                        "Part_name"
                      );
                      HandlePartname(e);
                    }}
                  />
                </td>
                <td colSpan={6}>
                  <Field
                    label={"Date"}
                    name={"Entry_date"}
                    options={{ type: "date", disabled: true }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={7}>
                  <Field
                    label={"No Of Cavity"}
                    name={"No_of_cavity"}
                    options={{ type: "text", disabled: true, required: true }}
                    warning={warning}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                <td colSpan={6}>
                  <Field
                    label={"Part Colour"}
                    name={"Part_colour"}
                    options={{ type: "text", disabled: true, required: true }}
                    warning={warning}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                      //  HandleStatus1();
                    }}
                  />
                </td>
                <td colSpan={6}>
                  <Field
                    label={"Shift"}
                    name={"Shift"}
                    options={{
                      type: "dropdown",
                      disabled: true,
                      values: [
                        { value: "A", label: "A12" },
                        { value: "B", label: "B12" },
                      ],
                    }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={7}>
                  <Field
                    label={"Raw Material Name"}
                    name={"Raw_material_name"}
                    options={{
                      type: "NoneReference",
                      reference:
                        option === "edit" && Records[0].Two_k_part == "1"
                          ? "Keyvalue"
                          : "Raw_material",
                      values: Raw,
                      required: true,
                      disabled: option === "view" || option === "edit",
                    }}
                    warning={warning}
                    isMulti={Records[0].Two_k_part == "1"}
                    // value={{
                    //   value: Records[0].Rawmaterialname[0] ? Records[0].Rawmaterialname[0].Mh_raw_mat : "",
                    //   label: Records[0].Rawmaterialname[0] ? Records[0].Rawmaterialname[0].Mh_raw_mat_name : "",
                    // }}
                    value={
                      Records[0].Two_k_part == "1"
                        ? Records[0].Rawmaterialname
                        : {
                            value: Records[0].Rawmaterialname[0]
                              ? Records[0].Rawmaterialname[0].Mh_raw_mat
                              : "",
                            label: Records[0].Rawmaterialname[0]
                              ? Records[0].Rawmaterialname[0].Mh_raw_mat_name
                              : "",
                          }
                    }
                    OnChange={(e) => {
                      Records[0].Two_k_part == "1"
                        ? HandleRecordSelectRaw2(
                            e,
                            "Rawmaterialname",
                            "Mh_raw_mat",
                            "Mh_raw_mat_name"
                          )
                        : HandleRecordSelectRaw1(
                            e,
                            "Rawmaterialname",
                            "Mh_raw_mat",
                            "Mh_raw_mat_name"
                          );
                      GetGradeColour(e);
                    }}
                  />
                </td>
                <td colSpan={6}>
                  <Field
                    label={"Machine No"}
                    name={"Machine_no"}
                    options={{
                      type: "NoneReference",
                      reference: "Machine_Number",
                      values: Machine,
                      required: true,
                      disabled: option === "view" || option === "edit",
                    }}
                    warning={warning}
                    value={{
                      value: Records[0].Machineno[0]
                        ? Records[0].Machineno[0].mh_mac_name
                        : "",
                      label: Records[0].Machineno[0]
                        ? Records[0].Machineno[0].mh_mac_name
                        : "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "Machineno",
                        "mh_mac_name",
                        "mh_mac_name"
                      );
                    }}
                  />
                </td>
                <td colSpan={6}>
                  <Field
                    label={"Time"}
                    name={"Time"}
                    options={{ type: "time", disabled: true }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={19}>
                  {option === "create" ? (
                    <Field
                      label={"Image"}
                      name={"Image"}
                      options={{ type: "image" }}
                      value={Records[0]}
                      OnChange={(e) => {
                        HandleRecords(e);
                      }}
                    />
                  ) : Records[0].Image &&
                    Records[0].Image.includes("image/") ? (
                    <img
                      width={1000}
                      height={400}
                      src={Records[0].Image}
                      alt=" "
                    />
                  ) : (
                    Records[0].Image &&
                    Records[0].Image.includes("application/pdf") && (
                      <div style={{ marginTop: "10px" }}>
                        {/* <iframe 
                width="500px"
                  height="350px"
                  alt={name}
                  src={value[name]}></iframe> */}

                        <button id="Pdf" onClick={() => setButtonPopup(true)}>
                          View
                        </button>
                        <Popup
                          trigger={ButtonPopup}
                          setTrigger={setButtonPopup}
                          viewPagination={viewPagination}
                        >
                          <object
                            width="90%"
                            height="90%"
                            data={Records[0].Image}
                            // src={Records[0].Image}
                            type="application/pdf"
                          >
                            <p>
                              Your browser does not support viewing PDFs.
                              <a
                                href={Records[0].Image}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Click here to view the PDF.
                              </a>
                            </p>
                          </object>
                        </Popup>
                      </div>
                    )
                  )}
                </td>
              </tr>
              <tr>
                {option !== "view" && !showCamera && (
                  <td colSpan={19}>
                    <button onClick={startCamera}>Start Camera 📷</button>
                    {Records &&
                    Records.length > 0 &&
                    Records[0].Capture_image ? (
                      <img
                        src={Records[0].Capture_image}
                        alt=""
                        style={{
                          height: "480px",
                          width: "640px",
                          padding: "20px",
                        }}
                      />
                    ) : null}
                  </td>
                )}
                {showCamera && (
                  <td colSpan={19}>
                    {imageSrc ? (
                      <img
                        src={imageSrc}
                        alt="Captured"
                        style={{
                          height: "480px",
                          width: "640px",
                          padding: "20px",
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          position: "relative",
                          width: 940,
                          height: 380,
                        }}
                      >
                        <Webcam
                          mirrored={true}
                          audio={false}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          width={940}
                          height={380}
                          videoConstraints={{
                            facingMode: "user",
                          }}
                        />
                        <button
                          onClick={capture}
                          style={{
                            position: "absolute",
                            bottom: 5,
                            left: "50%",
                            transform: "translateX(-50%)",
                            zIndex: 1,
                            borderRadius: "50%",
                            width: 40,
                            height: 40,
                            color: "white",
                            border: "1px solid white",
                            backgroundColor: "transparent",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        ></button>
                        <span
                          style={{
                            position: "absolute",
                            top: 0,
                            left: "24%",
                            color: "white",
                            cursor: "pointer",
                          }}
                          onClick={stopCamera}
                        >
                          &#9664;---
                        </span>
                      </div>
                    )}
                  </td>
                )}
              </tr>

              {Records &&
              Records.length > 0 &&
              Records[0].Capture_image &&
              option === "view" &&
              (Records[0].Capture_image.startsWith("data:image/jpeg") ||
                Records[0].Capture_image.startsWith("data:image/png")) ? (
                <tr>
                  <td colSpan={29}>
                    <label>Captured Image</label>
                    <img
                      style={{
                        width: "40%",
                        marginLeft: "40%",
                        padding: "2px",
                      }}
                      src={Records[0].Capture_image}
                      alt="Img"
                    />
                  </td>
                </tr>
              ) : null}
              <tr>
                <th colSpan={19}>
                  <Lable value={"Change Details"} type={"Head"} />
                </th>
              </tr>
              <tr>
                <td colSpan={9}>
                  <Field
                    label={"1. End of Production"}
                    name={"end_of_production"}
                    options={{ type: "boolean", disabled: option === "view" }}
                    value={Records[0].change_details[0]}
                    OnChange={(e) => {
                      HandleChangeDetails(e);
                    }}
                  />
                </td>
                <td colSpan={10}>
                  <Field
                    label={"2. Mould BreakDown"}
                    name={"Mould_breakdown"}
                    options={{ type: "boolean", disabled: option === "view" }}
                    value={Records[0].change_details[0]}
                    OnChange={(e) => {
                      HandleChangeDetails(e);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={9}>
                  <Field
                    label={"3. Material Change Bpm/Colour Change Bpm"}
                    name={"material_change_bpm"}
                    options={{ type: "boolean", disabled: option === "view" }}
                    value={Records[0].change_details[0]}
                    OnChange={(e) => {
                      HandleChangeDetails(e);
                    }}
                  />
                </td>
                <td colSpan={10}>
                  <Field
                    label={"4. Machine Breakdown"}
                    name={"Machine_breakdown"}
                    options={{ type: "boolean", disabled: option === "view" }}
                    value={Records[0].change_details[0]}
                    OnChange={(e) => {
                      HandleChangeDetails(e);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th colSpan={19}>
                  <Lable value={"VISUAL INSPECTION"} type={"Head"} />
                </th>
              </tr>
              <tr>
                <th style={{ width: "21%" }} colSpan={4}>
                  <Lable value={"SHORTS"} type={"Head"} />
                </th>
                <th style={{ width: "15%" }} colSpan={3}>
                  <Lable value={"1"} type={"Head"} />
                </th>
                <th style={{ width: "15%" }} colSpan={3}>
                  <Lable value={"2"} type={"Head"} />
                </th>
                <th style={{ width: "15%" }} colSpan={3}>
                  <Lable value={"3"} type={"Head"} />
                </th>
                <th style={{ width: "15%" }} colSpan={3}>
                  <Lable value={"4"} type={"Head"} />
                </th>
                <th style={{ width: "15%" }} colSpan={3}>
                  <Lable value={"5"} type={"Head"} />
                </th>
              </tr>
              <tr>
                <td style={{ width: "3%" }} colSpan={1}>
                  1
                </td>
                <td colSpan={3}>
                  <Lable style={{ width: "210px" }} value={"FREE FROM FLASH"} />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Free_from_flash"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[0]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 0);
                    }}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Free_from_flash"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[1]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 1);
                    }}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Free_from_flash"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[2]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 2);
                    }}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Free_from_flash"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[3]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 3);
                    }}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Free_from_flash"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[4]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 4);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={1}>2</td>
                <td colSpan={3}>
                  <Lable
                    style={{ width: "210px" }}
                    value={"FREE FROM SHORT MOULD"}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Free_from_short_mould"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[0]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 0);
                    }}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Free_from_short_mould"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[1]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 1);
                    }}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Free_from_short_mould"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[2]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 2);
                    }}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Free_from_short_mould"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[3]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 3);
                    }}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Free_from_short_mould"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[4]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 4);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={1}>3</td>
                <td colSpan={3}>
                  <Lable
                    style={{ width: "210px" }}
                    value={"FREE FROM SINK MARK"}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Free_from_sink_mark"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[0]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 0);
                    }}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Free_from_sink_mark"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[1]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 1);
                    }}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Free_from_sink_mark"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[2]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 2);
                    }}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Free_from_sink_mark"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[3]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 3);
                    }}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Free_from_sink_mark"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[4]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 4);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={1}>4</td>
                <td colSpan={3}>
                  <Lable
                    style={{ width: "210px" }}
                    value={"FREE FROM WELD LINE"}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Free_from_weld_line"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[0]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 0);
                    }}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Free_from_weld_line"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[1]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 1);
                    }}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Free_from_weld_line"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[2]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 2);
                    }}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Free_from_weld_line"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[3]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 3);
                    }}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Free_from_weld_line"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[4]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 4);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={1}>5</td>
                <td colSpan={3}>
                  <Lable
                    style={{ width: "210px" }}
                    value={"FREE FROM COLOUR VARIATION"}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Free_from_color_variation"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[0]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 0);
                    }}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Free_from_color_variation"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[1]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 1);
                    }}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Free_from_color_variation"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[2]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 2);
                    }}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Free_from_color_variation"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[3]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 3);
                    }}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Free_from_color_variation"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[4]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 4);
                    }}
                  />
                </td>
              </tr>

              <tr>
                <td colSpan={1}>6</td>
                <td colSpan={3}>
                  <Lable
                    style={{ width: "210px" }}
                    value={"CALENDER PIN TO BE VERIFIED (YEAR A MONTH)"}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Calender_pin_to_be_verified"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[0]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 0);
                    }}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Calender_pin_to_be_verified"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[1]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 1);
                    }}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Calender_pin_to_be_verified"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[2]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 2);
                    }}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Calender_pin_to_be_verified"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[3]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 3);
                    }}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    name={"Calender_pin_to_be_verified"}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].visual_inspection[4]}
                    OnChange={(e) => {
                      Handlevisualinspection(e, 4);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th style={{ width: "21%" }} colSpan={19}>
                  <Lable value={"CUSTOMER COMPLAINTS HISTORY"} type={"Head"} />
                </th>
              </tr>
              {Records[0].complaints.map((item) => (
                <tr>
                  <td colSpan={1}>{item}</td>
                  <td colSpan={3}>
                    {console.log(
                      Records[0].customer_complaints[Number(item) - 1],
                      Number(item) - 1
                    )}
                    <Field
                      style={{ width: "210px" }}
                      name={"Descripton"}
                      options={{ type: "text", disabled: option === "view" }}
                      value={Records[0].customer_complaints[Number(item) - 1]}
                      OnChange={(e) => {
                        Handlecustomercomplaints(e, Number(item) - 1);
                      }}
                    />
                  </td>
                  <td colSpan={3}>
                    <Field
                      name={"Visual_inspection_1"}
                      options={{
                        type: "dropdown",
                        disabled: option === "view",
                        values: [
                          { value: "NA", label: "NA" },
                          { value: "Ok", label: "✓" },
                          { value: "NotOk", label: "X" },
                        ],
                      }}
                      value={Records[0].customer_complaints[Number(item) - 1]}
                      OnChange={(e) => {
                        Handlecustomercomplaints(e, Number(item) - 1);
                      }}
                    />
                  </td>
                  <td colSpan={3}>
                    <Field
                      name={"Visual_inspection_2"}
                      options={{
                        type: "dropdown",
                        disabled: option === "view",
                        values: [
                          { value: "NA", label: "NA" },
                          { value: "Ok", label: "✓" },
                          { value: "NotOk", label: "X" },
                        ],
                      }}
                      value={Records[0].customer_complaints[Number(item) - 1]}
                      OnChange={(e) => {
                        Handlecustomercomplaints(e, Number(item) - 1);
                      }}
                    />
                  </td>
                  <td colSpan={3}>
                    <Field
                      name={"Visual_inspection_3"}
                      options={{
                        type: "dropdown",
                        disabled: option === "view",
                        values: [
                          { value: "NA", label: "NA" },
                          { value: "Ok", label: "✓" },
                          { value: "NotOk", label: "X" },
                        ],
                      }}
                      value={Records[0].customer_complaints[Number(item) - 1]}
                      OnChange={(e) => {
                        Handlecustomercomplaints(e, Number(item) - 1);
                      }}
                    />
                  </td>
                  <td colSpan={3}>
                    <Field
                      name={"Visual_inspection_4"}
                      options={{
                        type: "dropdown",
                        disabled: option === "view",
                        values: [
                          { value: "NA", label: "NA" },
                          { value: "Ok", label: "✓" },
                          { value: "NotOk", label: "X" },
                        ],
                      }}
                      value={Records[0].customer_complaints[Number(item) - 1]}
                      OnChange={(e) => {
                        Handlecustomercomplaints(e, Number(item) - 1);
                      }}
                    />
                  </td>
                  <td colSpan={3}>
                    <Field
                      name={"Visual_inspection_5"}
                      options={{
                        type: "dropdown",
                        disabled: option === "view",
                        values: [
                          { value: "NA", label: "NA" },
                          { value: "Ok", label: "✓" },
                          { value: "NotOk", label: "X" },
                        ],
                      }}
                      value={Records[0].customer_complaints[Number(item) - 1]}
                      OnChange={(e) => {
                        Handlecustomercomplaints(e, Number(item) - 1);
                      }}
                    />
                  </td>
                </tr>
              ))}
              <tr>
                <td
                  colSpan={20}
                  style={{ overflow: "hidden", overflowX: "scroll" }}
                >
                  <div style={{ width: "150%" }}>
                    <table>
                      <thead>
                        <tr>
                          <th colSpan={4}>
                            <Lable value={"DIMENSIONS / CAVITY"} />
                          </th>
                          <th colSpan={3}>
                            <Lable value={"CAVITY#1"} />
                          </th>
                          <th colSpan={3}>
                            <Lable value={"CAVITY#2"} />
                          </th>
                          <th colSpan={3}>
                            <Lable value={"CAVITY#3"} />
                          </th>
                          <th colSpan={3}>
                            <Lable value={"CAVITY#4"} />
                          </th>
                          <th colSpan={3}>
                            <Lable value={"CAVITY#5"} />
                          </th>
                          <th colSpan={3}>
                            <Lable value={"CAVITY#6"} />
                          </th>
                          <th colSpan={3}>
                            <Lable value={"CAVITY#7"} />
                          </th>
                          <th colSpan={3}>
                            <Lable value={"CAVITY#8"} />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Records[0].cavity.map((item) => (
                          <tr>
                            <td
                              colSpan={1}
                              style={{ width: "35px", textAlign: "center" }}
                            >
                              {item}
                            </td>
                            <td colSpan={3}>
                              <Lable
                                style={{ width: "210px" }}
                                value={`${
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specifications_loc ?? 0
                                } * 
          ${Records[0]?.dimensions[Number(item) - 1]?.Specification_val ?? 0} (
          ${
            Records[0]?.dimensions[Number(item) - 1]?.Specification_symbol_1 ??
            ""
          } 
          ${
            Records[0]?.dimensions[Number(item) - 1]?.Specification_minus ?? 0
          } /
          ${
            Records[0]?.dimensions[Number(item) - 1]?.Specification_symbol_2 ??
            ""
          } 
          ${
            Records[0]?.dimensions[Number(item) - 1]?.Specification_plus ?? 0
          }) ${Records[0]?.dimensions[Number(item) - 1]?.Checking_aids}`}
                              />
                              {/* <Field
                  style={{ width: "210px" }}
                  name={"Dimensions_cavity"}
                  options={{ type: "text",disabled: option==="view" }}
                  value={Records[0].dimensions[Number(item) - 1]}
                  OnChange={(e) => {
                    HandleDimension(e, [Number(item) - 1]);
                  }}
                /> */}
                            </td>
                            <td colSpan={1}>
                              <Field
                                style={{ padding: "1px 1px" }}
                                name={"Cavity_1_1"}
                                options={{
                                  type: "number",
                                  disabled: option === "view",
                                }}
                                min={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_1 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                }
                                max={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_2 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                }
                                value={Records[0].dimensions[Number(item) - 1]}
                                OnChange={(e) => {
                                  HandleDimension(e, [Number(item) - 1]);
                                }}
                              />
                            </td>
                            <td colSpan={1}>
                              <Field
                                style={{ padding: "1px 1px" }}
                                name={"Cavity_1_2"}
                                options={{
                                  type: "number",
                                  disabled: option === "view",
                                }}
                                min={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_1 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                }
                                max={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_2 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                }
                                value={Records[0].dimensions[Number(item) - 1]}
                                OnChange={(e) => {
                                  HandleDimension(e, [Number(item) - 1]);
                                }}
                              />
                            </td>
                            <td colSpan={1}>
                              <Field
                                style={{ padding: "1px 1px" }}
                                name={"Cavity_1_3"}
                                options={{
                                  type: "number",
                                  disabled: option === "view",
                                }}
                                min={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_1 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                }
                                max={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_2 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                }
                                value={Records[0].dimensions[Number(item) - 1]}
                                OnChange={(e) => {
                                  HandleDimension(e, [Number(item) - 1]);
                                }}
                              />
                            </td>
                            <td colSpan={1}>
                              <Field
                                style={{ padding: "1px 1px" }}
                                name={"Cavity_2_1"}
                                options={{
                                  type: "number",
                                  disabled: option === "view",
                                }}
                                min={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_1 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                }
                                max={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_2 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                }
                                value={Records[0].dimensions[Number(item) - 1]}
                                OnChange={(e) => {
                                  HandleDimension(e, [Number(item) - 1]);
                                }}
                              />
                            </td>
                            <td colSpan={1}>
                              <Field
                                style={{ padding: "1px 1px" }}
                                name={"Cavity_2_2"}
                                options={{
                                  type: "number",
                                  disabled: option === "view",
                                }}
                                min={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_1 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                }
                                max={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_2 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                }
                                value={Records[0].dimensions[Number(item) - 1]}
                                OnChange={(e) => {
                                  HandleDimension(e, [Number(item) - 1]);
                                }}
                              />
                            </td>
                            <td colSpan={1}>
                              <Field
                                style={{ padding: "1px 1px" }}
                                name={"Cavity_2_3"}
                                options={{
                                  type: "number",
                                  disabled: option === "view",
                                }}
                                min={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_1 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                }
                                max={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_2 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                }
                                value={Records[0].dimensions[Number(item) - 1]}
                                OnChange={(e) => {
                                  HandleDimension(e, [Number(item) - 1]);
                                }}
                              />
                            </td>
                            <td colSpan={1}>
                              <Field
                                style={{ padding: "1px 1px" }}
                                name={"Cavity_3_1"}
                                options={{
                                  type: "number",
                                  disabled: option === "view",
                                }}
                                min={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_1 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                }
                                max={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_2 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                }
                                value={Records[0].dimensions[Number(item) - 1]}
                                OnChange={(e) => {
                                  HandleDimension(e, [Number(item) - 1]);
                                }}
                              />
                            </td>
                            <td colSpan={1}>
                              <Field
                                style={{ padding: "1px 1px" }}
                                name={"Cavity_3_2"}
                                options={{
                                  type: "number",
                                  disabled: option === "view",
                                }}
                                min={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_1 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                }
                                max={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_2 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                }
                                value={Records[0].dimensions[Number(item) - 1]}
                                OnChange={(e) => {
                                  HandleDimension(e, [Number(item) - 1]);
                                }}
                              />
                            </td>
                            <td colSpan={1}>
                              <Field
                                style={{ padding: "1px 1px" }}
                                name={"Cavity_3_3"}
                                options={{
                                  type: "number",
                                  disabled: option === "view",
                                }}
                                min={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_1 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                }
                                max={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_2 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                }
                                value={Records[0].dimensions[Number(item) - 1]}
                                OnChange={(e) => {
                                  HandleDimension(e, [Number(item) - 1]);
                                }}
                              />
                            </td>
                            <td colSpan={1}>
                              <Field
                                style={{ padding: "1px 1px" }}
                                name={"Cavity_4_1"}
                                options={{
                                  type: "number",
                                  disabled: option === "view",
                                }}
                                min={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_1 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                }
                                max={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_2 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                }
                                value={Records[0].dimensions[Number(item) - 1]}
                                OnChange={(e) => {
                                  HandleDimension(e, [Number(item) - 1]);
                                }}
                              />
                            </td>
                            <td colSpan={1}>
                              <Field
                                style={{ padding: "1px 1px" }}
                                name={"Cavity_4_2"}
                                options={{
                                  type: "number",
                                  disabled: option === "view",
                                }}
                                min={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_1 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                }
                                max={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_2 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                }
                                value={Records[0].dimensions[Number(item) - 1]}
                                OnChange={(e) => {
                                  HandleDimension(e, [Number(item) - 1]);
                                }}
                              />
                            </td>
                            <td colSpan={1}>
                              <Field
                                style={{ padding: "1px 1px" }}
                                name={"Cavity_4_3"}
                                options={{
                                  type: "number",
                                  disabled: option === "view",
                                }}
                                min={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_1 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                }
                                max={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_2 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                }
                                value={Records[0].dimensions[Number(item) - 1]}
                                OnChange={(e) => {
                                  HandleDimension(e, [Number(item) - 1]);
                                }}
                              />
                            </td>
                            <td colSpan={1}>
                              <Field
                                style={{ padding: "1px 1px" }}
                                name={"Cavity_5_1"}
                                options={{
                                  type: "number",
                                  disabled: option === "view",
                                }}
                                min={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_1 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                }
                                max={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_2 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                }
                                value={Records[0].dimensions[Number(item) - 1]}
                                OnChange={(e) => {
                                  HandleDimension(e, [Number(item) - 1]);
                                }}
                              />
                            </td>
                            <td colSpan={1}>
                              <Field
                                style={{ padding: "1px 1px" }}
                                name={"Cavity_5_2"}
                                options={{
                                  type: "number",
                                  disabled: option === "view",
                                }}
                                min={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_1 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                }
                                max={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_2 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                }
                                value={Records[0].dimensions[Number(item) - 1]}
                                OnChange={(e) => {
                                  HandleDimension(e, [Number(item) - 1]);
                                }}
                              />
                            </td>
                            <td colSpan={1}>
                              <Field
                                style={{ padding: "1px 1px" }}
                                name={"Cavity_5_3"}
                                options={{
                                  type: "number",
                                  disabled: option === "view",
                                }}
                                min={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_1 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                }
                                max={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_2 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                }
                                value={Records[0].dimensions[Number(item) - 1]}
                                OnChange={(e) => {
                                  HandleDimension(e, [Number(item) - 1]);
                                }}
                              />
                            </td>
                            <td colSpan={1}>
                              <Field
                                style={{ padding: "1px 1px" }}
                                name={"Cavity_6_1"}
                                options={{
                                  type: "number",
                                  disabled: option === "view",
                                }}
                                min={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_1 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                }
                                max={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_2 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                }
                                value={Records[0].dimensions[Number(item) - 1]}
                                OnChange={(e) => {
                                  HandleDimension(e, [Number(item) - 1]);
                                }}
                              />
                            </td>
                            <td colSpan={1}>
                              <Field
                                style={{ padding: "1px 1px" }}
                                name={"Cavity_6_2"}
                                options={{
                                  type: "number",
                                  disabled: option === "view",
                                }}
                                min={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_1 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                }
                                max={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_2 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                }
                                value={Records[0].dimensions[Number(item) - 1]}
                                OnChange={(e) => {
                                  HandleDimension(e, [Number(item) - 1]);
                                }}
                              />
                            </td>
                            <td colSpan={1}>
                              <Field
                                style={{ padding: "1px 1px" }}
                                name={"Cavity_6_3"}
                                options={{
                                  type: "number",
                                  disabled: option === "view",
                                }}
                                min={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_1 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                }
                                max={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_2 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                }
                                value={Records[0].dimensions[Number(item) - 1]}
                                OnChange={(e) => {
                                  HandleDimension(e, [Number(item) - 1]);
                                }}
                              />
                            </td>
                            <td colSpan={1}>
                              <Field
                                style={{ padding: "1px 1px" }}
                                name={"Cavity_7_1"}
                                options={{
                                  type: "number",
                                  disabled: option === "view",
                                }}
                                min={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_1 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                }
                                max={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_2 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                }
                                value={Records[0].dimensions[Number(item) - 1]}
                                OnChange={(e) => {
                                  HandleDimension(e, [Number(item) - 1]);
                                }}
                              />
                            </td>
                            <td colSpan={1}>
                              <Field
                                style={{ padding: "1px 1px" }}
                                name={"Cavity_7_2"}
                                options={{
                                  type: "number",
                                  disabled: option === "view",
                                }}
                                min={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_1 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                }
                                max={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_2 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                }
                                value={Records[0].dimensions[Number(item) - 1]}
                                OnChange={(e) => {
                                  HandleDimension(e, [Number(item) - 1]);
                                }}
                              />
                            </td>
                            <td colSpan={1}>
                              <Field
                                style={{ padding: "1px 1px" }}
                                name={"Cavity_7_3"}
                                options={{
                                  type: "number",
                                  disabled: option === "view",
                                }}
                                min={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_1 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                }
                                max={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_2 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                }
                                value={Records[0].dimensions[Number(item) - 1]}
                                OnChange={(e) => {
                                  HandleDimension(e, [Number(item) - 1]);
                                }}
                              />
                            </td>
                            <td colSpan={1}>
                              <Field
                                style={{ padding: "1px 1px" }}
                                name={"Cavity_8_1"}
                                options={{
                                  type: "number",
                                  disabled: option === "view",
                                }}
                                min={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_1 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                }
                                max={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_2 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                }
                                value={Records[0].dimensions[Number(item) - 1]}
                                OnChange={(e) => {
                                  HandleDimension(e, [Number(item) - 1]);
                                }}
                              />
                            </td>
                            <td colSpan={1}>
                              <Field
                                style={{ padding: "1px 1px" }}
                                name={"Cavity_8_2"}
                                options={{
                                  type: "number",
                                  disabled: option === "view",
                                }}
                                min={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_1 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                }
                                max={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_2 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                }
                                value={Records[0].dimensions[Number(item) - 1]}
                                OnChange={(e) => {
                                  HandleDimension(e, [Number(item) - 1]);
                                }}
                              />
                            </td>
                            <td colSpan={1}>
                              <Field
                                style={{ padding: "1px 1px" }}
                                name={"Cavity_8_3"}
                                options={{
                                  type: "number",
                                  disabled: option === "view",
                                }}
                                min={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_1 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_minus || 0
                                      )
                                }
                                max={
                                  Records[0]?.dimensions[Number(item) - 1]
                                    ?.Specification_symbol_2 === "+"
                                    ? parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) +
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                    : parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_val ?? 0
                                      ) -
                                      parseFloat(
                                        Records[0]?.dimensions[Number(item) - 1]
                                          ?.Specification_plus || 0
                                      )
                                }
                                value={Records[0].dimensions[Number(item) - 1]}
                                OnChange={(e) => {
                                  HandleDimension(e, [Number(item) - 1]);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
              <tr>
                <th style={{ width: "21%" }} colSpan={19}>
                  <Lable value={"FUNCTIONAL"} type={"Head"} />
                </th>
              </tr>
              <tr>
                <td colSpan={1}>1</td>
                <td colSpan={3}>
                  <Lable
                    style={{ width: "210px" }}
                    value={"MATING/GAUGE CHECKING"}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_1_1"}
                    style={{ padding: "1px 1px" }}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].dimensions_functional[0]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 0);
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_1_2"}
                    style={{ padding: "1px 1px" }}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].dimensions_functional[0]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 0);
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_1_3"}
                    style={{ padding: "1px 1px" }}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].dimensions_functional[0]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 0);
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_2_1"}
                    style={{ padding: "1px 1px" }}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].dimensions_functional[0]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 0);
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_2_2"}
                    style={{ padding: "1px 1px" }}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].dimensions_functional[0]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 0);
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_2_3"}
                    style={{ padding: "1px 1px" }}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].dimensions_functional[0]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 0);
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_3_1"}
                    style={{ padding: "1px 1px" }}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].dimensions_functional[0]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 0);
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_3_2"}
                    style={{ padding: "1px 1px" }}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].dimensions_functional[0]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 0);
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_3_3"}
                    style={{ padding: "1px 1px" }}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].dimensions_functional[0]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 0);
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_4_1"}
                    style={{ padding: "1px 1px" }}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].dimensions_functional[0]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 0);
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_4_2"}
                    style={{ padding: "1px 1px" }}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].dimensions_functional[0]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 0);
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_4_3"}
                    style={{ padding: "1px 1px" }}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].dimensions_functional[0]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 0);
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_5_1"}
                    style={{ padding: "1px 1px" }}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].dimensions_functional[0]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 0);
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_5_2"}
                    style={{ padding: "1px 1px" }}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].dimensions_functional[0]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 0);
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_5_3"}
                    style={{ padding: "1px 1px" }}
                    options={{
                      type: "dropdown",
                      disabled: option === "view",
                      values: [
                        { value: "NA", label: "NA" },
                        { value: "Ok", label: "✓" },
                        { value: "NotOk", label: "X" },
                      ],
                    }}
                    value={Records[0].dimensions_functional[0]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 0);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={1}>2</td>
                <td colSpan={3}>
                  <Lable
                    style={{ width: "210px" }}
                    value={
                      "PART WEIGHT 1" +
                      "  (" +
                      Records[0].Part_weight_1 +
                      ") " +
                      "-" +
                      Records[0].Part_weight_set_minus_1 +
                      "/+" +
                      Records[0].Part_weight_set_plus_1
                    }
                  />
                </td>
                {/* <td colSpan={3}>
              
              <Lable style={{ width: "210px" }} 
              value={"PART WEIGHT.SPEC* "+" ("+Records[0].Part_weight+")"+"±"+"("+Records[0].Part_weight_set+")"}/>

            </td> */}
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_1_1"}
                    style={{ padding: "1px 1px" }}
                    options={{
                      type: "number",
                      disabled: option === "view",
                      required: true,
                    }}
                    min={
                      parseFloat(Records[0].Part_weight_1) -
                      parseFloat(Records[0].Part_weight_set_minus_1)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_1) +
                      parseFloat(Records[0].Part_weight_set_plus_1)
                    }
                    warning={warning}
                    value={Records[0].dimensions_functional[1]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 1);
                      //HandleStatus1();
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_1_2"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_1) -
                      parseFloat(Records[0].Part_weight_set_minus_1)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_1) +
                      parseFloat(Records[0].Part_weight_set_plus_1)
                    }
                    value={Records[0].dimensions_functional[1]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 1);
                      //HandleStatus1();
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_1_3"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_1) -
                      parseFloat(Records[0].Part_weight_set_minus_1)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_1) +
                      parseFloat(Records[0].Part_weight_set_plus_1)
                    }
                    value={Records[0].dimensions_functional[1]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 1);
                      //HandleStatus1();
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_2_1"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_1) -
                      parseFloat(Records[0].Part_weight_set_minus_1)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_1) +
                      parseFloat(Records[0].Part_weight_set_plus_1)
                    }
                    value={Records[0].dimensions_functional[1]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 1);
                      //HandleStatus1();
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_2_2"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_1) -
                      parseFloat(Records[0].Part_weight_set_minus_1)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_1) +
                      parseFloat(Records[0].Part_weight_set_plus_1)
                    }
                    value={Records[0].dimensions_functional[1]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 1);
                      //HandleStatus1();
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_2_3"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_1) -
                      parseFloat(Records[0].Part_weight_set_minus_1)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_1) +
                      parseFloat(Records[0].Part_weight_set_plus_1)
                    }
                    value={Records[0].dimensions_functional[1]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 1);
                      //HandleStatus1();
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_3_1"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_1) -
                      parseFloat(Records[0].Part_weight_set_minus_1)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_1) +
                      parseFloat(Records[0].Part_weight_set_plus_1)
                    }
                    value={Records[0].dimensions_functional[1]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 1);
                      //HandleStatus1();
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_3_2"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_1) -
                      parseFloat(Records[0].Part_weight_set_minus_1)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_1) +
                      parseFloat(Records[0].Part_weight_set_plus_1)
                    }
                    value={Records[0].dimensions_functional[1]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 1);
                      //HandleStatus1();
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_3_3"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_1) -
                      parseFloat(Records[0].Part_weight_set_minus_1)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_1) +
                      parseFloat(Records[0].Part_weight_set_plus_1)
                    }
                    value={Records[0].dimensions_functional[1]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 1);
                      //HandleStatus1();
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_4_1"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_1) -
                      parseFloat(Records[0].Part_weight_set_minus_1)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_1) +
                      parseFloat(Records[0].Part_weight_set_plus_1)
                    }
                    value={Records[0].dimensions_functional[1]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 1);
                      //HandleStatus1();
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_4_2"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_1) -
                      parseFloat(Records[0].Part_weight_set_minus_1)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_1) +
                      parseFloat(Records[0].Part_weight_set_plus_1)
                    }
                    value={Records[0].dimensions_functional[1]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 1);
                      //HandleStatus1();
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_4_3"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_1) -
                      parseFloat(Records[0].Part_weight_set_minus_1)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_1) +
                      parseFloat(Records[0].Part_weight_set_plus_1)
                    }
                    value={Records[0].dimensions_functional[1]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 1);
                      //HandleStatus1();
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_5_1"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_1) -
                      parseFloat(Records[0].Part_weight_set_minus_1)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_1) +
                      parseFloat(Records[0].Part_weight_set_plus_1)
                    }
                    value={Records[0].dimensions_functional[1]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 1);
                      //HandleStatus1();
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_5_2"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_1) -
                      parseFloat(Records[0].Part_weight_set_minus_1)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_1) +
                      parseFloat(Records[0].Part_weight_set_plus_1)
                    }
                    value={Records[0].dimensions_functional[1]}
                    OnChange={(e) => {
                      //HandleStatus1();
                      HandleDimensionFunction(e, 1);
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_5_3"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_1) -
                      parseFloat(Records[0].Part_weight_set_minus_1)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_1) +
                      parseFloat(Records[0].Part_weight_set_plus_1)
                    }
                    value={Records[0].dimensions_functional[1]}
                    OnChange={(e) => {
                      //HandleStatus1();
                      HandleDimensionFunction(e, 1);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={1}>3</td>
                <td colSpan={3}>
                  <Lable
                    style={{ width: "210px" }}
                    value={
                      "PART WEIGHT 2" +
                      "  (" +
                      Records[0].Part_weight_2 +
                      ") " +
                      "-" +
                      Records[0].Part_weight_set_minus_2 +
                      "/+" +
                      Records[0].Part_weight_set_plus_2
                    }
                  />
                </td>
                {/* <td colSpan={3}>
              
              <Lable style={{ width: "210px" }} 
              value={"PART WEIGHT.SPEC* "+" ("+Records[0].Part_weight+")"+"±"+"("+Records[0].Part_weight_set+")"}/>

            </td> */}
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_1_1"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_2) -
                      parseFloat(Records[0].Part_weight_set_minus_2)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_2) +
                      parseFloat(Records[0].Part_weight_set_plus_2)
                    }
                    warning={warning}
                    value={Records[0].dimensions_functional[2]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 2);
                      //HandleStatus1();
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_1_2"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_2) -
                      parseFloat(Records[0].Part_weight_set_minus_2)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_2) +
                      parseFloat(Records[0].Part_weight_set_plus_2)
                    }
                    value={Records[0].dimensions_functional[2]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 2);
                      //HandleStatus1();
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_1_3"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_2) -
                      parseFloat(Records[0].Part_weight_set_minus_2)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_2) +
                      parseFloat(Records[0].Part_weight_set_plus_2)
                    }
                    value={Records[0].dimensions_functional[2]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 2);
                      //HandleStatus1();
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_2_1"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_2) -
                      parseFloat(Records[0].Part_weight_set_minus_2)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_2) +
                      parseFloat(Records[0].Part_weight_set_plus_2)
                    }
                    value={Records[0].dimensions_functional[2]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 2);
                      //HandleStatus1();
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_2_2"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_2) -
                      parseFloat(Records[0].Part_weight_set_minus_2)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_2) +
                      parseFloat(Records[0].Part_weight_set_plus_2)
                    }
                    value={Records[0].dimensions_functional[2]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 2);
                      //HandleStatus1();
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_2_3"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_2) -
                      parseFloat(Records[0].Part_weight_set_minus_2)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_2) +
                      parseFloat(Records[0].Part_weight_set_plus_2)
                    }
                    value={Records[0].dimensions_functional[2]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 2);
                      //HandleStatus1();
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_3_1"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_2) -
                      parseFloat(Records[0].Part_weight_set_minus_2)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_2) +
                      parseFloat(Records[0].Part_weight_set_plus_2)
                    }
                    value={Records[0].dimensions_functional[2]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 2);
                      //HandleStatus1();
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_3_2"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_2) -
                      parseFloat(Records[0].Part_weight_set_minus_2)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_2) +
                      parseFloat(Records[0].Part_weight_set_plus_2)
                    }
                    value={Records[0].dimensions_functional[2]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 2);
                      //HandleStatus1();
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_3_3"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_2) -
                      parseFloat(Records[0].Part_weight_set_minus_2)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_2) +
                      parseFloat(Records[0].Part_weight_set_plus_2)
                    }
                    value={Records[0].dimensions_functional[2]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 2);
                      //HandleStatus1();
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_4_1"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_2) -
                      parseFloat(Records[0].Part_weight_set_minus_2)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_2) +
                      parseFloat(Records[0].Part_weight_set_plus_2)
                    }
                    value={Records[0].dimensions_functional[2]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 2);
                      //HandleStatus1();
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_4_2"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_2) -
                      parseFloat(Records[0].Part_weight_set_minus_2)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_2) +
                      parseFloat(Records[0].Part_weight_set_plus_2)
                    }
                    value={Records[0].dimensions_functional[2]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 2);
                      //HandleStatus1();
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_4_3"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_2) -
                      parseFloat(Records[0].Part_weight_set_minus_2)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_2) +
                      parseFloat(Records[0].Part_weight_set_plus_2)
                    }
                    value={Records[0].dimensions_functional[2]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 2);
                      //HandleStatus1();
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_5_1"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_2) -
                      parseFloat(Records[0].Part_weight_set_minus_2)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_2) +
                      parseFloat(Records[0].Part_weight_set_plus_2)
                    }
                    value={Records[0].dimensions_functional[2]}
                    OnChange={(e) => {
                      HandleDimensionFunction(e, 2);
                      //HandleStatus1();
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_5_2"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_2) -
                      parseFloat(Records[0].Part_weight_set_minus_2)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_2) +
                      parseFloat(Records[0].Part_weight_set_plus_2)
                    }
                    value={Records[0].dimensions_functional[2]}
                    OnChange={(e) => {
                      //HandleStatus1();
                      HandleDimensionFunction(e, 2);
                    }}
                  />
                </td>
                <td style={{ width: "5%" }} colSpan={1}>
                  <Field
                    name={"Cavity_5_3"}
                    style={{ padding: "1px 1px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    min={
                      parseFloat(Records[0].Part_weight_2) -
                      parseFloat(Records[0].Part_weight_set_minus_2)
                    }
                    max={
                      parseFloat(Records[0].Part_weight_2) +
                      parseFloat(Records[0].Part_weight_set_plus_2)
                    }
                    value={Records[0].dimensions_functional[2]}
                    OnChange={(e) => {
                      //HandleStatus1();
                      HandleDimensionFunction(e, 2);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={19}>
                  {" "}
                  <Field
                    label={"REMARKS-ANY PROBLEM UP DATED IN THIS COLUMN"}
                    name={"Remarks"}
                    options={{ type: "textarea", disabled: option === "view" }}
                    doubleLine={true}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                      handleRemarks(e);
                    }}
                  />
                  {result ? (
                    <Field
                      // label={"Image1"}
                      name={"ImageRemarks"}
                      accept="image/*"
                      options={{
                        type: "image",
                        disabled: option === "view",
                        required: true,
                      }}
                      warning={warning}
                      value={Records[0]}
                      OnChange={(e) => {
                        HandleRecords(e);
                      }}
                    />
                  ) : (
                    ""
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan={5}>
                  {" "}
                  <Field
                    label={"NOT APPLICABLE"}
                    options={{
                      type: "text",
                      defaultValue: "N A",
                      disabled: true,
                    }}
                    value={Records[0]}
                  />
                </td>
                <td colSpan={5}>
                  {" "}
                  <Field
                    label={"OK"}
                    options={{
                      type: "text",
                      defaultValue: "✓",
                      disabled: true,
                    }}
                    value={Records[0]}
                  />
                </td>
                <td colSpan={5}>
                  {" "}
                  <Field
                    label={"NOT OK"}
                    options={{
                      type: "text",
                      defaultValue: "X",
                      disabled: true,
                    }}
                    value={Records[0]}
                  />
                </td>
                <td colSpan={4}>
                  {" "}
                  {!GetAccess("production", "hod") ? (
                    <Field
                      label="ACCEPTED/NOT ACCEPTED"
                      name="Part_status"
                      options={{ type: "text", disabled: "true" }}
                      value={Records[0]}
                    />
                  ) : (
                    <div>
                      <Field
                        style={{ float: "left", width: "40%" }}
                        label="Accepted"
                        name="Part_status"
                        options={{
                          type: "Button",
                          disabled: option === "view",
                        }}
                        value={Records[0].Part_status}
                        onClick={(e) => {
                          HandleRecords({
                            target: { name: "Part_status", value: "Accepted" },
                          });
                        }}
                      />
                      <Field
                        label="Not Accepted"
                        name="Part_status"
                        options={{
                          type: "Button",
                          disabled: option === "view",
                        }}
                        value={Records[0]}
                        onClick={() => {
                          HandleRecords({
                            target: {
                              name: "Part_status",
                              value: "Not Accepted",
                            },
                          });
                        }}
                      />
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan={5}>
                  {" "}
                  <Field
                    label={"PRODUCTION OPERATOR NAME"}
                    name={"Prodcution_operator_name"}
                    doubleLine={true}
                    options={{
                      type: "reference",
                      reference: "emp_details_dropdown",
                      required: true,
                      disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records[0].Prodcutionoperatorname[0]
                        ? Records[0].Prodcutionoperatorname[0].Emp_code
                        : "",
                      label: Records[0].Prodcutionoperatorname[0]
                        ? Records[0].Prodcutionoperatorname[0].Emp_name
                        : "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "Prodcutionoperatorname",
                        "Emp_code",
                        "Emp_name"
                      );
                      HandleStatus1();
                    }}
                  />
                </td>
                <td colSpan={5}>
                  {" "}
                  <Field
                    label={"PRODUCTION INCHARGE"}
                    name={"Prodcution_incharge"}
                    doubleLine={true}
                    options={{
                      type: "reference",
                      reference: "emp_details_dropdown",
                      required: true,
                      disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records[0].Prodcutionincharge[0]
                        ? Records[0].Prodcutionincharge[0].Emp_code
                        : "",
                      label: Records[0].Prodcutionincharge[0]
                        ? Records[0].Prodcutionincharge[0].Emp_name
                        : "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "Prodcutionincharge",
                        "Emp_code",
                        "Emp_name"
                      );
                    }}
                  />
                </td>
                <td colSpan={5}>
                  {" "}
                  <Field
                    label={"ON LINE QC"}
                    name={"Online_qc_sign"}
                    doubleLine={true}
                    options={{
                      type: "reference",
                      reference: "emp_details_dropdown",
                      required: true,
                      disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records[0].Onlineqcsign[0]
                        ? Records[0].Onlineqcsign[0].Emp_code
                        : "",
                      label: Records[0].Onlineqcsign[0]
                        ? Records[0].Onlineqcsign[0].Emp_name
                        : "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "Onlineqcsign",
                        "Emp_code",
                        "Emp_name"
                      );
                    }}
                  />
                </td>
                <td colSpan={4}>
                  {" "}
                  <Field
                    label={"QC HOD"}
                    name={"Qa_sign"}
                    doubleLine={true}
                    options={{
                      type: "reference",
                      reference: "emp_details_dropdown",
                      required: true,
                      disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records[0].Qasign[0]
                        ? Records[0].Qasign[0].Emp_code
                        : "",
                      label: Records[0].Qasign[0]
                        ? Records[0].Qasign[0].Emp_name
                        : "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(e, "Qasign", "Emp_code", "Emp_name");
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={10}>
                  {" "}
                  <Field
                    label={
                      "Has the Job been set as per the Master Setting data"
                    }
                    name={"Master_setting_dates"}
                    options={{ type: "boolean", disabled: option === "view" }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecordsBoolean(e);
                    }}
                  />
                </td>
                <td colSpan={9}>
                  {" "}
                  <Field
                    label={"Store the last of Part with details"}
                    name={"Store_the_last_of_part_with_details"}
                    options={{ type: "boolean", disabled: option === "view" }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecordsBoolean(e);
                    }}
                  />
                </td>
              </tr>
              {!(option === "view") && (
                <tr>
                  {id === undefined ? (
                    <td colSpan={19}>
                      {" "}
                      <Field
                        label={"Submit"}
                        options={{ type: "Button" }}
                        onClick={(e) => {
                          HandleCreate(e);
                        }}
                      />
                    </td>
                  ) : (
                    <td colSpan={19}>
                      {" "}
                      <Field
                        label={"Update"}
                        options={{ type: "Button" }}
                        onClick={(e) => {
                          HandleUpdate(e);
                        }}
                      />
                    </td>
                  )}
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* ExcelTable Design*/}
      <div ref={contentRef} id="content-to-pdf">
        <style>
          {`table {
  border-collapse: collapse;
  border: 1px solid;
  width:100%;
  margin: 0 auto;
   }
  .myTable {
  border: 2px solid;
  width: 100%;
  padding:5px;  
  display:none;
  }
  th, td ,tr{
  border: 1px solid;
  height:100%;
  }`}
        </style>
        {DownloadDiv && (
          <table className="myTable">
            <tr>
              <td colspan="1" style={{ height: "2%" }}>
                <img style={{ width: "90%" }} src={Logo} alt="Logo" />
              </td>

              <td colspan="19">
                <div>
                  <label
                    style={{
                      textAlign: "center",
                      fontSize: "18",
                      fontStyle: "bold",
                    }}
                  >
                    Last Off Inspection
                  </label>
                </div>
              </td>
              <td colspan="9">
                FORMAT NO: UPCL/QC/R/02
                <br />
                REV NO: 05
                <br />
                REV DATE: 18-08-2018
              </td>
            </tr>
            <tr>
              <td colspan="10">Part Number : {Records[0].Part_number}</td>
              <td colspan="10">Part Name : {Records[0].Part_name}</td>
              <td colspan="9">Date: {Records[0].Entry_date}</td>
            </tr>
            <tr>
              <td colspan="10">No Of Cavity: {Records[0].No_of_cavity}</td>
              <td colspan="10">Part Colour: {Records[0].Part_colour}</td>
              <td colspan="9">Shift: {Records[0].Shift}</td>
            </tr>
            <tr>
              <td colSpan="10">
              Raw Material Name:{" "}
                {Records[0].Two_k_part === "1"
                  ? `${JSON.parse(Records[0].Raw_material_name)[0].label}, ${
                      JSON.parse(Records[0].Raw_material_name)[1].label
                    }`
                  : Records[0]?.Rawmaterialname[0]?.Mh_raw_mat_name}
              </td>
              <td colspan="10">Machine No: {Records[0].Machine_no}</td>
              <td colspan="9">Time: {Records[0].Time}</td>
            </tr>
            {Records &&
            Records.length > 0 &&
            Records[0].Image &&
            (Records[0].Image.startsWith("data:image/jpeg") ||
              Records[0].Image.startsWith("data:image/png")) ? (
              <tr>
                <td colSpan={29}>
                  <img
                    style={{ width: "40%", marginLeft: "40%", padding: "2px" }}
                    src={Records[0].Image}
                    alt="Img"
                  />
                </td>
              </tr>
            ) : null}
            <tr>
              <td colspan="29">
                <div>
                  <label style={{ textAlign: "center" }}>Change Details</label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="14">
                1. End of Production:{" "}
                {Records[0].change_details[0].end_of_production}
              </td>
              <td colspan="15">
                2. Mould BreakDown:{" "}
                {Records[0].change_details[0].Mould_breakdown}
              </td>
            </tr>
            <tr>
              <td colspan="14">
                3. Material Change Bpm/Colour Change Bpm:{" "}
                {Records[0].change_details[0].material_change_bpm}
              </td>
              <td colspan="15">
                4. Machine BreakDown:{" "}
                {Records[0].change_details[0].Machine_breakdown}
              </td>
            </tr>
            <tr>
              <td colspan="29">
                <div>
                  <label style={{ textAlign: "center" }}>
                    VISUAL INSPECTION
                  </label>
                </div>
              </td>{" "}
            </tr>
            <tr>
              <td colSpan="1"></td>

              <td colSpan="4" style={{ width: "10%" }}>
                <div>
                  <label>SHORTS</label>
                </div>
              </td>
              <td colSpan="5" style={{ width: "10%" }}>
                <div>
                  <label>1</label>
                </div>
              </td>
              <td colSpan="5" style={{ width: "10%" }}>
                <div>
                  <label>2</label>
                </div>
              </td>
              <td colSpan="5" style={{ width: "10%" }}>
                <div>
                  <label>3</label>
                </div>
              </td>
              <td colSpan="5" style={{ width: "10%" }}>
                <div>
                  <label>4</label>
                </div>
              </td>
              <td colSpan="5" style={{ width: "10%" }}>
                <div>
                  <label>5</label>
                </div>
              </td>
            </tr>
            <tr>
              <td style={{ width: "5%", textAlign: "center" }}>1</td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                FREE FROM FLASH
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[0].Free_from_flash}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[1].Free_from_flash}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[2].Free_from_flash}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[3].Free_from_flash}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[4].Free_from_flash}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "center", textAlign: "center" }}>2 </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                FREE FROM SHORT MOULD
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[0].Free_from_short_mould}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[1].Free_from_short_mould}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[2].Free_from_short_mould}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[3].Free_from_short_mould}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[4].Free_from_short_mould}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "center", textAlign: "center" }}>3 </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                FREE FROM SINK MARK
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[0].Free_from_sink_mark}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[1].Free_from_sink_mark}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[2].Free_from_sink_mark}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[3].Free_from_sink_mark}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[4].Free_from_sink_mark}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "center", textAlign: "center" }}>4 </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                FREE FROM WELD LINE
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[0].Free_from_short_mould}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[1].Free_from_short_mould}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[2].Free_from_short_mould}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[3].Free_from_short_mould}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[4].Free_from_short_mould}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "center", textAlign: "center" }}>5 </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                FREE FROM COLOUR VARIATION
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[0].Free_from_color_variation}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[1].Free_from_color_variation}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[2].Free_from_color_variation}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[3].Free_from_color_variation}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[4].Free_from_color_variation}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "center", textAlign: "center" }}>6 </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                CALENDER PIN TO BE VERIFIED (YEAR A MONTH)
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[0].Calender_pin_to_be_verified}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[1].Calender_pin_to_be_verified}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[2].Calender_pin_to_be_verified}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[3].Calender_pin_to_be_verified}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[4].Calender_pin_to_be_verified}
              </td>
            </tr>
            <tr>
              <td colspan="29">
                <div>
                  <label style={{ textAlign: "center" }}>
                    {" "}
                    CUSTOMER COMPLAINTS HISTORY
                  </label>
                </div>
              </td>{" "}
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>1.</td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].customer_complaints[0].Descripton}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].customer_complaints[0].Visual_inspection_1}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {" "}
                {Records[0].customer_complaints[0].Visual_inspection_2}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].customer_complaints[0].Visual_inspection_3}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].customer_complaints[0].Visual_inspection_4}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].customer_complaints[0].Visual_inspection_5}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>2.</td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].customer_complaints[1].Descripton}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].customer_complaints[1].Visual_inspection_1}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].customer_complaints[1].Visual_inspection_2}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].customer_complaints[1].Visual_inspection_3}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].customer_complaints[1].Visual_inspection_4}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].customer_complaints[1].Visual_inspection_5}
              </td>
            </tr>

            <tr>
              <td style={{ textAlign: "center" }}>3.</td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].customer_complaints[1].Descripton}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].customer_complaints[2].Visual_inspection_1}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].customer_complaints[2].Visual_inspection_2}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].customer_complaints[2].Visual_inspection_3}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].customer_complaints[2].Visual_inspection_4}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {Records[0].customer_complaints[2].Visual_inspection_5}
              </td>
            </tr>

            <tr>
              <td colSpan="1"></td>

              <td colSpan="4">
                <div>
                  <label style={{ textAlign: "center" }}>
                    DIMENSIONS / CAVITY
                  </label>
                </div>
              </td>
              <td colSpan="3" style={{ textAlign: "center", width: "10%" }}>
                CAVITY#1
              </td>
              <td colSpan="3" style={{ textAlign: "center", width: "10%" }}>
                CAVITY#2
              </td>
              <td colSpan="3" style={{ textAlign: "center", width: "10%" }}>
                CAVITY#3
              </td>
              <td colSpan="3" style={{ textAlign: "center", width: "10%" }}>
                CAVITY#4
              </td>
              <td colSpan="3" style={{ textAlign: "center", width: "10%" }}>
                CAVITY#5
              </td>
              <td colSpan="3" style={{ textAlign: "center", width: "10%" }}>
                CAVITY#6
              </td>
              <td colSpan="3" style={{ textAlign: "center", width: "10%" }}>
                CAVITY#7
              </td>
              <td colSpan="3" style={{ textAlign: "center", width: "10%" }}>
                CAVITY#8
              </td>
            </tr>
            {Records[0].dimensions.map((dimension, index) => (
              <tr key={index}>
                <td colSpan="1" style={{ textAlign: "center" }}>
                  {index + 1}
                </td>
                <td colSpan="4" style={{ width: "10%", textAlign: "center" }}>
                  {`${dimension.Specifications_loc}*${dimension.Specification_val}(-${dimension.Specification_minus}/+${dimension.Specification_plus})`}
                </td>
                <td style={{ textAlign: "center", width: "3%" }}>
                  {dimension.Cavity_1_1}
                </td>
                <td style={{ textAlign: "center", width: "3%" }}>
                  {dimension.Cavity_1_2}
                </td>
                <td style={{ textAlign: "center", width: "3%" }}>
                  {dimension.Cavity_1_3}
                </td>
                <td style={{ textAlign: "center", width: "3%" }}>
                  {dimension.Cavity_2_1}
                </td>
                <td style={{ textAlign: "center", width: "3%" }}>
                  {dimension.Cavity_2_2}
                </td>
                <td style={{ textAlign: "center", width: "3%" }}>
                  {dimension.Cavity_2_3}
                </td>
                <td style={{ textAlign: "center", width: "3%" }}>
                  {dimension.Cavity_3_1}
                </td>
                <td style={{ textAlign: "center", width: "3%" }}>
                  {dimension.Cavity_3_2}
                </td>
                <td style={{ textAlign: "center", width: "3%" }}>
                  {dimension.Cavity_3_3}
                </td>
                <td style={{ textAlign: "center", width: "3%" }}>
                  {dimension.Cavity_4_1}
                </td>
                <td style={{ textAlign: "center", width: "3%" }}>
                  {dimension.Cavity_4_2}
                </td>
                <td style={{ textAlign: "center", width: "3%" }}>
                  {dimension.Cavity_4_3}
                </td>
                <td style={{ textAlign: "center", width: "3%" }}>
                  {dimension.Cavity_5_1}
                </td>
                <td style={{ textAlign: "center", width: "3%" }}>
                  {dimension.Cavity_5_2}
                </td>
                <td style={{ textAlign: "center", width: "3%" }}>
                  {dimension.Cavity_5_3}
                </td>
                <td style={{ textAlign: "center", width: "3%" }}>
                  {dimension.Cavity_6_1}
                </td>
                <td style={{ textAlign: "center", width: "3%" }}>
                  {dimension.Cavity_6_2}
                </td>
                <td style={{ textAlign: "center", width: "3%" }}>
                  {dimension.Cavity_6_3}
                </td>
                <td style={{ textAlign: "center", width: "3%" }}>
                  {dimension.Cavity_7_1}
                </td>
                <td style={{ textAlign: "center", width: "3%" }}>
                  {dimension.Cavity_7_2}
                </td>
                <td style={{ textAlign: "center", width: "3%" }}>
                  {dimension.Cavity_7_3}
                </td>
                <td style={{ textAlign: "center", width: "3%" }}>
                  {dimension.Cavity_8_1}
                </td>
                <td style={{ textAlign: "center", width: "3%" }}>
                  {dimension.Cavity_8_2}
                </td>
                <td style={{ textAlign: "center", width: "3%" }}>
                  {dimension.Cavity_8_3}
                </td>
              </tr>
            ))}
            <tr>
              <td colspan="29">
                <div>
                  <label style={{ textAlign: "center" }}>FUNCTIONAL</label>
                </div>
              </td>{" "}
            </tr>
            <tr>
              <td colSpan="1" style={{ textAlign: "center" }}>
                {" "}
                1
              </td>
              <td colSpan="13" style={{ textAlign: "center" }}>
                {" "}
                MATING/GAUGE CHECKING
              </td>
              <td colSpan="1" style={{ width: "2%" }}>
                {Records[0].dimensions_functional[0].Cavity_1_1}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[0].Cavity_1_2}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[0].Cavity_1_3}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[0].Cavity_2_1}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[0].Cavity_2_2}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[0].Cavity_2_3}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[0].Cavity_3_1}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[0].Cavity_3_2}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[0].Cavity_3_3}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[0].Cavity_4_1}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[0].Cavity_4_2}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[0].Cavity_4_3}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[0].Cavity_5_1}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[0].Cavity_5_2}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[0].Cavity_5_3}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>2.</td>
              <td colSpan="13" style={{ textAlign: "center" }}>
                PW 1: {Part1}
              </td>
              <td colSpan="1" style={{ width: "3%" }}>
                {Records[0].dimensions_functional[1].Cavity_1_1}
              </td>
              <td colSpan="1" style={{ width: "3%" }}>
                {Records[0].dimensions_functional[1].Cavity_1_2}
              </td>
              <td colSpan="1" style={{ width: "3%" }}>
                {Records[0].dimensions_functional[1].Cavity_1_3}
              </td>
              <td colSpan="1" style={{ width: "3%" }}>
                {Records[0].dimensions_functional[1].Cavity_2_1}
              </td>
              <td colSpan="1" style={{ width: "3%" }}>
                {Records[0].dimensions_functional[1].Cavity_2_2}
              </td>
              <td colSpan="1" style={{ width: "3%" }}>
                {Records[0].dimensions_functional[1].Cavity_2_3}
              </td>
              <td colSpan="1" style={{ width: "3%" }}>
                {Records[0].dimensions_functional[1].Cavity_3_1}
              </td>
              <td colSpan="1" style={{ width: "3%" }}>
                {Records[0].dimensions_functional[1].Cavity_3_2}
              </td>
              <td colSpan="1" style={{ width: "3%" }}>
                {Records[0].dimensions_functional[1].Cavity_3_3}
              </td>
              <td colSpan="1" style={{ width: "3%" }}>
                {Records[0].dimensions_functional[1].Cavity_4_1}
              </td>
              <td colSpan="1" style={{ width: "3%" }}>
                {Records[0].dimensions_functional[1].Cavity_4_2}
              </td>
              <td colSpan="1" style={{ width: "3%" }}>
                {Records[0].dimensions_functional[1].Cavity_4_3}
              </td>
              <td colSpan="1" style={{ width: "3%" }}>
                {Records[0].dimensions_functional[1].Cavity_5_1}
              </td>
              <td colSpan="1" style={{ width: "3%" }}>
                {Records[0].dimensions_functional[1].Cavity_5_2}
              </td>
              <td colSpan="1" style={{ width: "3%" }}>
                {Records[0].dimensions_functional[1].Cavity_5_3}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>3. </td>
              <td colSpan="13" style={{ textAlign: "center" }}>
                PW 2: {Part2}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[2].Cavity_1_1}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[2].Cavity_1_2}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[2].Cavity_1_3}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[2].Cavity_2_1}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[2].Cavity_2_2}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[2].Cavity_2_3}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[2].Cavity_3_1}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[2].Cavity_3_2}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[2].Cavity_3_3}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[2].Cavity_4_1}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[2].Cavity_4_2}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[2].Cavity_4_3}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[2].Cavity_5_1}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[2].Cavity_5_2}
              </td>
              <td colSpan="1">
                {Records[0].dimensions_functional[2].Cavity_5_3}
              </td>
            </tr>
            <tr>
              <td colSpan={29}>
                REMARKS-ANY PROBLEM UP DATED IN THIS COLUMN: <br />
                <span>{Records[0].Remarks}</span>
              </td>
            </tr>
            <tr>
              <td colSpan="3">NOT APPLICABLE:</td>
              <td colSpan="4">N A</td>
              <td colSpan="3">OK:</td>
              <td colSpan="3">✓</td>
              <td colSpan="3">NOT OK:</td>
              <td colSpan="3">X</td>
              <td colSpan="10">ACCEPTED/NOT ACCEPTED</td>
            </tr>
            <tr>
              <td colSpan="7">
                PRODUCTION OPERATOR NAME:
                <span>{Records[0].Prodcutionoperatorname[0].Emp_name}</span>
              </td>
              <td colSpan="7">
                {" "}
                PRODUCTION INCHARGE:
                <span>{Records[0].Prodcutionincharge[0].Emp_name}</span>
              </td>
              <td colSpan="7">
                ON LINE QC:<span>{Records[0].Onlineqcsign[0].Emp_name}</span>
              </td>
              <td colSpan="8">
                QC HOD:<span>{Records[0].Qasign[0].Emp_name}</span>
              </td>
            </tr>
            <tr>
              <td colSpan="15">
                Has the Job been set as per the Master Setting data:{" "}
                {Records[0].Master_setting_dates}
              </td>
              <td colSpan="14">
                Store the last of Part with details:{" "}
                {Records[0].Store_the_last_of_part_with_details}
              </td>
            </tr>
          </table>
        )}
      </div>
    </QaLayout>
  );
};

export default LastOffForm;
