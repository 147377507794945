//external import
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

//internal import
import Field from "../../../Components/Fields/Field";
import Lable from "../../../Components/Fields/Lable";
import QaLayout from "../../Quality/QaLayout";

//css import
import './NewSmpMain.css'
const InjUnitSettings = ({ Records, setRecords, setActiveKey, activeKey }) => {
    const { option, id } = useParams();
    const [ctrlPressed, setCtrlPressed] = useState(false);
    function InvalidCharacters(value) {
        return /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/.test(value);
      }
    useEffect(() => {
        if (activeKey === "thirteen") {
            document.getElementById("2-Inj_unit_speed").focus();
          }
        const handleKeyDown = (event) => {
            
              
          let activeField = document.activeElement;
          let fieldOptions = {
            Inj_unit_speed: ["", "Inj_unit_sec"],
            Inj_unit_sec: ["Inj_unit_speed", ""],
            Inj_unit_time: ["", "Inj_unit_value"],
            Inj_unit_value: ["Inj_unit_time", ""],
          };    
    
          let row = Records[0].injectionunitsettings_function.length - 1;
          let currentField = activeField.id.split("-")[1];
          let indexOfField = activeField.id.split("-")[0];
          let nextField = "";
    
          if (currentField != undefined) {
            switch (event.ctrlKey + "+" + event.key + "+" + activeKey) {
              case "false+ArrowRight+thirteen":
                nextField = fieldOptions[currentField][1];
                if (indexOfField > 1 && indexOfField < 4) {
                  if (nextField != "") {
                    document.getElementById(indexOfField + "-" + nextField).focus();
                  } else {
                    if(indexOfField==3){
                        document
                        .getElementById(
                          Number(indexOfField) + 1 + "-Inj_unit_time"
                        )
                        .focus();
                    }else{
                        document
                        .getElementById(
                          Number(indexOfField) + 1 + "-Inj_unit_speed"
                        )
                        .focus();
                    }
                   
                  }
                }else if(indexOfField==4){
                    document
                    .getElementById(
                      indexOfField +"-Inj_unit_value"
                    )
                    .focus();
                }
                break;
    
              case "false+ArrowLeft+thirteen":
                nextField = fieldOptions[currentField][0];
                if (
                    indexOfField > 1 && indexOfField < 4 &&
                  activeField.id != "2-Inj_unit_speed"
                ) {
                  if (nextField != "") {
                    document.getElementById(indexOfField + "-" + nextField).focus();
                  } else {
                    document
                      .getElementById(indexOfField - 1 + "-Inj_unit_sec")
                      .focus();
                  }
                }else if(indexOfField==4){
                    if(currentField==="Inj_unit_value"){document
                    .getElementById(
                      indexOfField +"-Inj_unit_time"
                    )
                    .focus();}else{
                        document
                    .getElementById(
                      Number(indexOfField)-1 +"-Inj_unit_sec"
                    )
                    .focus();
                    }
                }
                break;
    
              case "false+ArrowDown+thirteen":
                // console.log("indexOfField",indexOfField,row,indexOfField == row);
                if (indexOfField < 3) {
                  document
                    .getElementById(Number(indexOfField) + 1 + "-" + currentField)
                    .focus();
                }else if(indexOfField == 3){
                    document
                    .getElementById(Number(indexOfField) + 1 + "-Inj_unit_time" )
                    .focus();
                }
                break;
    
              case "false+ArrowUp+thirteen":

                if (indexOfField > 2 && indexOfField < 4) {
                  //   if (indexOfField < row + 1) {
                  document
                    .getElementById(Number(indexOfField) - 1 + "-" + currentField)
                    .focus();
                  //   }
                }else if(indexOfField==4) {
                    document
                    .getElementById("3-Inj_unit_sec")
                    .focus();
                }
                break;
    
              case "true+ArrowRight+thirteen":
                setActiveKey("first");
                break;
    
    
              default:
                break;
            }
          }
        };
    
        document.addEventListener("keydown", handleKeyDown);
        return () => {
          document.removeEventListener("keydown", handleKeyDown);
        };
      }, [activeKey, setActiveKey]);


    function HandleInjUnitSet(Event, index) {
        setRecords((item) => {
            let record = [...Records];
            record[0].injectionunitsettings_function[index] = {
                ...record[0].injectionunitsettings_function[index],
                [Event.target.name]: Event.target.value,
            };
            return record;
        });
    }

    return (
        <div>
            <table>
            <thead>
                    <tr>
                        <th colSpan={6}>
                            <div style={{ display: "grid", gridTemplateColumns: "90% 10%", alignItems: "center" }}>
                                <div style={{ textAlign: "center" }}>
                                    <Lable value={"Injection Unit Settings"} type={"Head"} style={{ padding: "1px" }} />
                                </div>
                                <div>
                                <button className="nextButtonLabel" onClick={(e) => { setActiveKey("first") }}>Next</button>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {Records[0].injectionunitsettings_function.map((item, index) => {
                        if (index < 2) {
                            return (
                                <tr key={index}>
                                    <td>
                                        <Lable value={item.Inj_unit_stage} style={{ textAlign: 'center', padding: "0px" }} />
                                    </td>
                                    <td>
                                        <Lable value={item.Inj_unit_speed} style={{ textAlign: 'center', padding: "0px" }} />
                                    </td>
                                    <td>
                                        <Lable value={item.Inj_unit_time} style={{ textAlign: 'center', padding: "0px" }} />
                                    </td>
                                    <td>
                                        <Lable value={item.Inj_unit_sec} style={{ textAlign: 'center', padding: "0px" }} />
                                    </td>
                                    <td>
                                        <Lable value={item.Inj_unit_break_mode} style={{ textAlign: 'center', padding: "0px" }} />
                                    </td>
                                    <td>
                                        <Lable value={item.Inj_unit_value} style={{ textAlign: 'center', padding: "0px" }} />
                                    </td>
                                </tr>
                            )
                        }
                    })}
                    {Records[0].injectionunitsettings_function.map((item, index) => {
                        if (index > 1 && index < 4) {
                            return (
                                <tr key={index}>
                                    <td>
                                        <Lable value={item.Inj_unit_stage} style={{ textAlign: 'center', padding: "0px" }} />
                                    </td>
                                    <td>
                                        <Field
                                            // label={""}
                                            id={index}
                                            name={"Inj_unit_speed"}
                                            className={"fieldSingleGrid"}
                                            options={{
                                                type: "text",

                                                disabled: option == "view",
                                            }}
                                            value={item}
                                            OnChange={(e) => {
                                                const value = e.target.value;
                                                if (InvalidCharacters(value)) {
                                                  e.preventDefault();
                                                  return;
                                                }
                                                HandleInjUnitSet(e, index);
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <Lable value={item.Inj_unit_time} style={{ textAlign: 'center', padding: "0px" }} />
                                    </td>
                                    <td>
                                        {/* <Lable value={item.Inj_unit_sec} style={{ textAlign: 'center', padding: "0px" }} /> */}
                                        <Field
                                            id={index}
                                            label={""}
                                            name={"Inj_unit_sec"}
                                            className={"fieldSingleGrid"}
                                            options={{
                                                type: "text",

                                                disabled: option == "view",
                                            }}
                                            value={item}
                                            OnChange={(e) => {
                                                const value = e.target.value;
                                                if (InvalidCharacters(value)) {
                                                  e.preventDefault();
                                                  return;
                                                }
                                                HandleInjUnitSet(e, index);
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <Lable value={item.Inj_unit_break_mode} style={{ textAlign: 'center', padding: "0px" }} />
                                    </td>
                                    <td>
                                        <Lable value={item.Inj_unit_value} style={{ textAlign: 'center', padding: "0px" }} />
                                    </td>
                                </tr>
                            )
                        }
                    })}
                    <tr>
                        <td colSpan={2}>
                            <Lable value={Records[0].injectionunitsettings_function[4].Inj_unit_stage} style={{ textAlign: 'center', padding: "0px" }} />
                        </td>
                        <td>
                            <Field
                                label={""}
                                id={4}
                                name={"Inj_unit_time"}
                                className={"fieldSingleGrid"}
                                options={{
                                    type: "text",
                                    disabled: option == "view",
                                }}
                                value={Records[0].injectionunitsettings_function[4]}
                                OnChange={(e) => {
                                    const value = e.target.value;
                                    if (InvalidCharacters(value)) {
                                      e.preventDefault();
                                      return;
                                    }
                                    HandleInjUnitSet(e, 4);
                                }}
                            />
                        </td>
                        <td colSpan={2}>
                            <Lable value={Records[0].injectionunitsettings_function[4].Inj_unit_break_mode} style={{ textAlign: 'center', padding: "0px" }} />
                        </td>
                        <td>
                            <Field
                                id={4}
                                label={""}
                                name={"Inj_unit_value"}
                                className={"fieldSingleGrid"}
                                options={{
                                    type: "text",
                                    disabled: option == "view",
                                }}
                                value={Records[0].injectionunitsettings_function[4]}
                                OnChange={(e) => {
                                    const value = e.target.value;
                                    if (InvalidCharacters(value)) {
                                      e.preventDefault();
                                      return;
                                    }
                                    HandleInjUnitSet(e, 4);
                                }}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
export default InjUnitSettings;