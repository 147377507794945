export const FAIL_TO_SAVE_MACHINE = "Problem in Saving!";
export const FAIL_TO_SAVE_CUSTOMER = "Problem in Saving Customer details";
export const FAIL_TO_SAVE_SUPPLIER = "Problem in Saving Supplier details";
export const FAIL_TO_SAVE_FG = "Problem in Saving FG details";
export const FAIL_TO_SAVE_RM = "Problem in Saving RM details";
export const FAIL_TO_SAVE_EMP = "Problem in Saving Employee details";
export const MANDATORY_ALERT ="Please fill the mandatory fields";
export const ALREADY_EXIST = " Already Exist.";
export const SUBMISSION_SUCCESSFUL = "Submission successful!";
export const SUBMISSION_FAILED = "Submission Failed!";
export const UPDATE_SUCCESSFUL = "Updated successful!";
export const UPDATE_FAILURE = "Error in Updating the Field";
export const SELECTED_FUTURE_DATE = "Selecting Future Date!!!";
export const FILL_MACHINE_AND_PART = "Please fill Machine No and Part Name";
export const REQUIRED_ALERT = "Please fill the Required* Fields";
export const CURRENT_Date = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0];
export const CURRENT_TIME = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[1].substring(0, 5);
export const CURRENT_DateANDTIME = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString();
export const SHIFTMOLDINGPRODUCTION = determineShiftMoldingProduction();
export const SHIFTMOLDINGQUALITY = determineShiftMoldingQuality();

function determineShiftMoldingProduction() {
    const datetime = new Date(
      new Date().getTime() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[1]
      .substring(0, 5);
    if (datetime < "17:59" && datetime > "05:59") {
      return "A";
    } else {
      return "B";
    }
  };
function determineShiftMoldingQuality() {
    const datetime = new Date(
      new Date().getTime() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[1]
      .substring(0, 5);
    if (datetime < "19:59" && datetime > "07:59") {
      return "A";
    } else {
      return "B";
    }
  };
