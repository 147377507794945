import React,{useState,useEffect} from "react";
import MachineListView from "../../../Components/ListView/MachineListView";
import QaLayout from "../../Quality/QaLayout";

const MachineStartupView = () => {
  return (
    <QaLayout quary={"List - Daily Machine StartUp and ReStartUp"}>
    <div>
      <MachineListView
        Title={"Daily Machine StartUp"}
        standard={"qp.ActiveStatus = 1"}
        quary={"SELECT qp.Id, qp.Machine_No, qp.Part_Name,qp.Shot_1,qp.Shot_2,qp.Shot_3,qp.Shot_4,qp.Shot_5,qp.Shot_6,qp.Shot_7,qp.Shot_8,qp.Shot_9,qp.Shot_10,qp.Shot_11,qp.Remarks,qp.total1,qp.total2,qp.sign,qp.approved,qp.verified,qp.Date,qp.approved_name,qp.verified_name FROM daily_machine_startup qp group by qp.Id ORDER BY qp.Id DESC"}
        table={"daily_machine_startup qp"}
        tableDelete={"daily_machine_startup"}
        navigation={"MachineStartUp"}
        filter={["qp.Id","qp.Machine_No","qp.Part_Name"]}
        dateFilter={"qp.CreatedDate"}
        header={[
          { value: "qp.Id", label: "ID" },
          { value: "qp.Part_Name", label: "Part Name" },
          { value: "qp.Machine_No", label: "Machine No" },
          { value: "qp.Shot_1", label: "Shot 1" },
          { value: "qp.Shot_2", label: "Shot 2" },
          { value: "qp.Shot_3", label: "Shot 3" },
          { value: "qp.Shot_4", label: "Shot 4" },
          { value: "qp.Shot_5", label: "Shot 5" },
          { value: "qp.Shot_6", label: "Shot 6" },
          { value: "qp.Shot_7", label: "Shot 7" },
          { value: "qp.Shot_8", label: "Shot 8" },
          { value: "qp.Shot_9", label: "Shot 9" },
          { value: "qp.Shot_10", label: "Shot 10" },
          { value: "qp.Shot_11", label: "Shot 11" },

        ]}
      />

    </div>
    </QaLayout>
    
  );
};

export default MachineStartupView;
