import React from "react";
import ListView from "../../../Components/ListView/ListView";
import QaLayout from "../QaLayout";

const LayoutInspectionList = () => {
  return (
    <QaLayout quary={"quality_inline_layout_inspection"}>
    <div>
      <ListView
        Title={"Layout Inspection Report"}
        standard={"qp.ActiveStatus = 1"}
        quary={"Select qp.Id,qp.part_number,qp.Part_name,qp.Raw_material_name,rm_details.rm_description AS rm_name from quality_inline_layout_inspection qp LEFT JOIN rm_details ON qp.raw_material_name =rm_details.rm_code group by qp.Id,qp.part_name,qp.part_number,qp.Raw_material_name,rm_details.rm_description order by qp.Id"}
        // quary={"SELECT ins.id,ins.machine_no,prt.mc_part_name Part_name,ins.raw_material_name,emp.emp_name Prodcution_operator_name,ins.ActiveStatus FROM upd_data_model.quality_first_off_inspection ins left join upd_data_model.machine_part_details prt on ins.part_name=prt.mc_part_code left join upd_data_model.emp_details emp on ins.prodcution_operator_name=emp.emp_code where ins.ActiveStatus='1'"}
        table={"quality_inline_layout_inspection qp"}
        tableDelete={"quality_inline_layout_inspection"}
        navigation={"layoutinline"}
        filter={["qp.Id","qp.part_number","qp.Part_name"]}
        dateFilter={"qp.CreatedDate"}
        header={[
          { value: "qp.Id", label: "ID" },
          { value: "qp.part_number", label: "Part Number" },
          { value: "qp.Part_name", label: "Part Name" },         
          { value: "qp.Raw_material_name", label: "Raw Material" },
          { value: "rm_name", label: "Raw Name" },
         
        ]}
      />
      </div>
      </QaLayout>
  );
};

export default LayoutInspectionList;