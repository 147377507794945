import { useEffect, useState } from "react";
import PmsListView from "../../../Components/ListView/PmsListView";
import { CURRENT_Date } from "../../../Utils/GeneralConstants";
import QaLayout from "../../Quality/QaLayout";

function PMSNewView() {
  
  return (
    <QaLayout>
      <PmsListView
        Title={"List - Process Monitoring System"}
        standard={"1 = 1 "}
        // quary={"SELECT ins.id,ins.machine_no,prt.mc_part_name Part_name,ins.raw_material_name,emp.emp_name Prodcution_operator_name,ins.ActiveStatus FROM upd_data_model.quality_first_off_inspection ins left join upd_data_model.machine_part_details prt on ins.part_name=prt.mc_part_code left join upd_data_model.emp_details emp on ins.prodcution_operator_name=emp.emp_code where ins.ActiveStatus='1'"}   order by pms.CreatedDate desc;
        // quary={"SELECT distinct pms.id as Id,pms.TrackDate, smp.Machine_no, md.mc_mac_bay, tm.Track_7_am, tm.Track_11_am, tm.Track_15_pm, tm.Track_19_pm, tm.Track_23_pm, tm.Track_3_am from pro_mon_sys_parent pms right join production_smp_parent smp on smp.Machine_no = pms.Mcno inner join machine_details md on smp.Machine_no=md.mc_code left join pro_mon_sys_tracking_machine tm on pms.Id = tm.Pms_id and tm.Time_to_be_updated='Part name'"}
        quary={
          "SELECT distinct pms.id as Id,pms.TrackDate, md.mc_code, md.mc_mac_bay, IFNULL(tm.Track_7_am,'') as Track_7_am, IFNULL(tm.Track_11_am,'') as Track_11_am, IFNULL(tm.Track_15_pm,'') as Track_15_pm, IFNULL(tm.Track_19_pm,'') as Track_19_pm, IFNULL(tm.Track_23_pm,'') as Track_23_pm, IFNULL(tm.Track_3_am,'') as Track_3_am from pro_mon_sys_parent pms right join machine_details md on md.mc_code = pms.Mcno and pms.ActiveStatus='1' dateFilter% left join pro_mon_sys_tracking_machine tm on pms.Id = tm.Pms_id and tm.Time_to_be_updated='Part name'"
        }
        orderBy = {"order by md.mc_mac_bay,md.mc_code"}
        table={"machine_details md"}
        tableDelete={"pro_mon_sys_parent"}
        navigation={"Pms"}
        filter={["md.mc_mac_bay", "md.mc_code"]}
        dateFilter={"pms.CreatedDate"}
        header={[
          { value: "pms.TrackDate", label: "TrackDate" },
          { value: "md.mc_mac_bay", label: "Bay" },
          { value: "md.mc_code", label: "Machine No" },
          { value: "tm.Track_7_am", label: "08.00 AM - 09.00 AM" },
          { value: "tm.Track_11_am", label: "12.00 AM - 13.00 AM" },
          { value: "tm.Track_15_pm", label: "16.00 PM - 17.00 PM" },
          { value: "tm.Track_19_pm", label: "20.00 PM - 21.00 PM" },
          { value: "tm.Track_23_pm", label: "24.00 PM - 01.00 AM" },
          { value: "tm.Track_3_am", label: "04.00 AM - 05.00 AM" },
          // { value: "Production_operator_name", label: "Prodcution Operator" },
        ]}
      />
    </QaLayout>
  );
}

export default PMSNewView;
