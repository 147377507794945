import React, { useEffect, useState } from "react";
import "../../../Styles/Part3.css";
import {
  SECRET_KEY,
  CODEGEN_URL,
  PROCESS_TRANSACTION_URL,
} from "../../../Utils/constants.js";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import IsDuplicate from "../../../Lib/IsDuplicate.js";
import CreateRecord from "../../../Lib/API/CreateRecord.js";

const initialState = {
  SelectedOption: "",
  MacCde: "",
  MacNme: "",
  MacVer: "",
  Flexy1: "",
  Flexy2: "",
  Flexy3: "",
  mac_mas: [],
};

function MachinePartMasterCreate({ tab1 }) {
  const [isCreating, setIsCreating] = useState(false);

  const [machinePartMasterState, setMachinePartMasterState] =
    useState(initialState);
  useEffect(() => {
    setMachinePartMasterState(initialState);
  }, [tab1]);
  // const CodeGen = async () => {
  //     const val = {
  //         tablename: "machine_part_details",
  //         PrimaryCode: "id",
  //         PrimaryVal: machinePartMasterState.Id,
  //         FieldCode: "mc_part_code",
  //         FieldVal: machinePartMasterState.MacCde,
  //     };
  //   //  console.log(val);
  //     const response = await getAPIRequest(
  //       CODEGEN_URL,'POST', val
  //     );
  //     const response1 = await response.json();
  //     console.log("response1",response1);
  //     return (response1.success === 'true');

  // };
  const handleCreate = async (e) => {
    e.preventDefault();
    const trimmedCode = machinePartMasterState.MacCde.trim();
    const trimmedName = machinePartMasterState.MacNme.trim();
    if (
      !machinePartMasterState.MacCde ||
      !machinePartMasterState.MacNme ||
      !machinePartMasterState.Flexy1
    ) {
      setIsCreating(true);
      alert(GeneralConstants.MANDATORY_ALERT);
      return;
    }

    try {
      const isDuplicateCode = await IsDuplicate("machine_part_details", [
        `mc_part_code="${trimmedCode}"`,
        `mc_part_active_status = 'Active'`,
      ]);

      if (isDuplicateCode) {
        alert(
          `Part with code ${trimmedCode} already exists.`
        );
        return;
      }

      const isDuplicateName = await IsDuplicate("machine_part_details", [
        `mc_part_name="${trimmedName}"`,
        `mc_part_active_status = 'Active'`,
      ]);

      if (isDuplicateName) {
        alert(
          `Part with name ${trimmedName} already exists.`
        );
        return;
      }

      const data = {
        data: [
          {
            mc_part_code: trimmedCode,
            mc_part_name: trimmedName,
            mc_part_version: machinePartMasterState.MacVer,
            mc_part_flex_01: machinePartMasterState.Flexy1,
            mc_part_flex_02: machinePartMasterState.Flexy2,
            mc_part_flex_03: machinePartMasterState.Flexy3,
          },
        ],
      };
      CreateRecord("mac_part_dtl", data.data).then((res) => {
        if (res.feedback[0].success === true) {
          alert(
            `Machine Part Master Code ${GeneralConstants.SUBMISSION_SUCCESSFUL}`
          );
          setMachinePartMasterState({
            ...machinePartMasterState,
            ...initialState,
          });
          setIsCreating(false);
        }
      });
    } catch (error) {
      console.log(error);
      alert(
        `${machinePartMasterState.MacNme} ${GeneralConstants.SUBMISSION_FAILED}`
      );
      window.location.reload();
    }
  };

  return (
    <div
      id="machinePartMasterCreate.formContainer"
      name="machinePartMasterCreate.formContainer"
    >
      <form className="form-machinePartMaster-Create">
        <table style={{ textAlign: "center", width: "100%" }} cellPadding="5">
          <tbody>
            <tr>
              <th
                id="mpmc.Title"
                name="mpmc.Title"
                colSpan="4"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">Part Master</font>
              </th>
            </tr>
            <tr>
              <th>
                &nbsp;&nbsp;Part Code<span className="Req-i">*</span>
              </th>
              <td>
                {machinePartMasterState.MacCde === "" && isCreating && (
                  <span className="Req">Required *</span>
                )}
                <input
                  id="mpmc.machinePartCode.Create"
                  name="mpmc.machinePartCode.Create"
                  type="text"
                  value={machinePartMasterState.MacCde}
                  onChange={(e) =>
                    setMachinePartMasterState({
                      ...machinePartMasterState,
                      MacCde: e.target.value,
                    })
                  }
                  required
                />
              </td>
              <th>
                &nbsp;&nbsp;Part Name<span className="Req-i">*</span>
              </th>
              <td>
                {machinePartMasterState.MacNme === "" && isCreating && (
                  <span className="Req">Required *</span>
                )}
                <input
                  id="mpmc.machinePartName.Create"
                  name="mpmc.machinePartName.Create"
                  type="text"
                  value={machinePartMasterState.MacNme}
                  onChange={(e) =>
                    setMachinePartMasterState({
                      ...machinePartMasterState,
                      MacNme: e.target.value,
                    })
                  }
                  required
                />
              </td>
            </tr>
            <tr>
              <th>&nbsp;&nbsp;Part Version</th>
              <td>
                <input
                  id="mpmc.machinePartVer.Create"
                  name="mpmc.machinePartVer.Create"
                  type="text"
                  value={machinePartMasterState.MacVer}
                  onChange={(e) =>
                    setMachinePartMasterState({
                      ...machinePartMasterState,
                      MacVer: e.target.value,
                    })
                  }
                />
              </td>
              <th>
                &nbsp;&nbsp;Model<span className="Req-i">*</span>
              </th>
              <td>
                {machinePartMasterState.Flexy1 === "" && isCreating && (
                  <span className="Req">Required *</span>
                )}
                <input
                  id="mpmc.machinePartFlex1.Create"
                  name="mpmc.machinePartFlex1.Create"
                  type="text"
                  value={machinePartMasterState.Flexy1}
                  onChange={(e) =>
                    setMachinePartMasterState({
                      ...machinePartMasterState,
                      Flexy1: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <th>&nbsp;&nbsp;Cavity</th>
              <td>
                <input
                  id="mpmc.machinePartFlex2.Create"
                  name="mpmc.machinePartFlex2.Create"
                  type="text"
                  value={machinePartMasterState.Flexy2}
                  onChange={(e) =>
                    setMachinePartMasterState({
                      ...machinePartMasterState,
                      Flexy2: e.target.value,
                    })
                  }
                />
              </td>
              <th>&nbsp;&nbsp;Flex 1</th>
              <td>
                <input
                  id="mpmc.machinePartFlex3.Create"
                  name="mpmc.machinePartFlex3.Create"
                  type="text"
                  value={machinePartMasterState.Flexy3}
                  onChange={(e) =>
                    setMachinePartMasterState({
                      ...machinePartMasterState,
                      Flexy3: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td colSpan="4" style={{ border: "none" }}>
                <button
                  id="mpmc.submit"
                  name="mpmc.submit"
                  type="submit"
                  onClick={(e) => handleCreate(e)}
                >
                  Submit
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
}

export default MachinePartMasterCreate;
