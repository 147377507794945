import React, { useState, useEffect } from "react";

import "../../../Styles/MaterialLoading.css";

//import "bootstrap/dist/css/bootstrap.min.css";

import Unitechlogo from "../../../icons/Unitechlogo.jpeg";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import Pagination from "../../../Components/Pagination";
import HandleRead from "../../../Lib/HandleRead.js";
import { formatDt } from "../../../Lib/formatDt";
import FilterReport from "../../../Components/FilterReport";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Dropdown } from "react-bootstrap";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
//import Html from "react-pdf-html";
import ReactDOMServer from "react-dom/server";

import { Document, Text, Page, Image } from "@react-pdf/renderer";
import { CURRENT_Date } from "../../../Utils/GeneralConstants.js";

function MaterialLoadingReport() {
  const handleTimePeriodChange = (event) => {
    setTimePeriod(event.target.value);
  };

  const handleDownloadStatement = () => {
    window.print();
    window.location.reload();
  };
  const tableRef = useRef(null);
  const [downloadEnabled, setDownloadEnabled] = useState(false);
  const [filtermonth, setFilterMonth] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate1, setEndDate1] = useState("");
  const [startDate1, setStartDate1] = useState("");
  const [pdfprint, setpdfprint] = useState(true);
  const [timePeriod, setTimePeriod] = useState("");
  const [filterDate, setFilterDate] = useState("");
  var [mat_load, setmat_load] = useState([]);

  const [isPaginationVisible, setPaginationVisible] = useState(true);
  let componentRef = useRef();
  useEffect(() => {
    if (filterDate || (startDate && endDate)) {
      setDownloadEnabled(true);
    } else {
      setDownloadEnabled(false);
    }
  }, [filterDate, startDate, endDate]);
  const viewPagination = () => {
    setPaginationVisible(true);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 31;

  const filterRecords = () => {
    const filteredData = mat_load.filter((record) => {
      console.log("record", record);
      const trackDate = new Date(record.TrackDate);
      console.log("trackDate", trackDate);
      const selectedStartDate = startDate ? new Date(startDate) : null;
      const selectedEndDate = endDate ? new Date(endDate) : null;

      if (selectedStartDate && selectedEndDate) {
        return trackDate >= selectedStartDate && trackDate <= selectedEndDate;
      } else if (selectedStartDate) {
        return trackDate >= selectedStartDate;
      } else if (selectedEndDate) {
        return trackDate <= selectedEndDate;
      } else if (filtermonth) {
        const selectedMonth = new Date(filtermonth);
        const trackMonth = trackDate.getMonth();
        const trackYear = trackDate.getFullYear();

        return (
          trackMonth === selectedMonth.getMonth() &&
          trackYear === selectedMonth.getFullYear()
        );
      } else {
        return record.TrackDate && record.TrackDate.includes(filterDate);
      }
    });

    return filteredData;
  };

  const filteredData = filterRecords();
  const npage = Math.ceil(filteredData.length / recordPerPage);
  const numbers = [...Array(npage).keys()].map((num) => num + 1);

  function handleFilterDateChange(event) {
    setFilterDate(event.target.value);
    setCurrentPage(1);
  }

  const handleStartDateChange = (newStartDate) => {
    setStartDate(newStartDate);
  };

  const handleEndDateChange = (newEndDate) => {
    setEndDate(newEndDate);
  };
  const handleStartDateChange1 = (newStartDate) => {
    setStartDate1(newStartDate);
  };

  const handleEndDateChange1 = (newEndDate) => {
    setEndDate1(newEndDate);
  };
  const handleFilterMonthChange = (event) => {
    setFilterMonth(event.target.value);
    setCurrentPage(1);
  };
  const firstIndex = (currentPage - 1) * recordPerPage;
  const lastIndex = firstIndex + recordPerPage;
  const filteredRecords = filteredData.slice(firstIndex, lastIndex);
  function prePage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function nextPage() {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function changeCpage(Id) {
    setCurrentPage(Id);
  }
  const onButtonClick = () => {
    const pdf = new jsPDF("potrait", "mm", "a4");

    html2canvas(tableRef.current).then(async (canvas) => {
      let imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 10, 10, 200, 0);

      //  let canvasdata = await html2canvas()

      //  imgData = canvasdata.toDataURL("image/png");

      //  console.log(imgData);
      //   pdf.addPage();
      //     pdf.addImage(imgData, "PNG", 5, 5, 200, 0);

      pdf.save(`Material Loading ${CURRENT_Date}.pdf`);
    });
  };
  const pagestyle = `@media all {
    .page-break {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @media print and (orientation: portrait) {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }
  
  @page {
    size: auto;
    margin: 15mm;
  }`;

  return (
    <div>
      <div
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          marginLeft: "5%",
          padding: "0px",
        }}
      >
        <FilterReport
          timePeriod={timePeriod}
          handleTimePeriodChange={handleTimePeriodChange}
          filtermonth={filtermonth}
          handleFiltermonthChange={handleFilterMonthChange}
          filterDate={filterDate}
          handleFilterDateChange={handleFilterDateChange}
          startDate={startDate}
          startDate1={startDate1}
          handleStartDateChange={handleStartDateChange}
          endDate={endDate}
          endDate1={endDate1}
          handleEndDateChange={handleEndDateChange}
          handleEndDateChange1={handleEndDateChange1}
          handleStartDateChange1={handleStartDateChange1}
          componentref={componentRef}
          downloadEnabled={downloadEnabled}
        />

        <Dropdown style={{ width: "20%" }}>
          <Dropdown.Toggle>Download ⬇️</Dropdown.Toggle>

          <Dropdown.Menu>
            {/* <Dropdown.Item> */}
            <PDFDownloadLink
              document={
                <Document>
                  <Page>
                    {/* <Html> */}
                      {ReactDOMServer.renderToStaticMarkup(
                        <div>
                          <div
                            style={{ marginBottom: "10px", marginTop: "10px" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10",
                              }}
                            >
                              <div>
                                <img
                                  src={Unitechlogo}
                                  style={{ width: "40px", height: "40px" }}
                                ></img>
                              </div>
                              <div>
                                <div
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "900",
                                    marginTop:"10px"
                                  }}
                                >
                                  KASH Tech LLC
                                </div>
                                <div
                                  style={{
                                    textAlign: "center",
                                    paddingTop:"8px",
                                    fontSize: "12px",
                                    fontWeight: "800",
                                    width: "50%",
                                  }}
                                >
                                  Consolidated Report for Material Loading
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                borderBottom: "2px solid gray",
                                height: "10px",
                                margin: "4px 8%",
                              }}
                            ></div>
                          </div>
                          <table
                            id="excelTable"
                            style={{
                              textAlign: "center",
                              transform: "scale(.9%)",
                              borderTop: "1px solid black",
                              borderBottom: "1px solid gray",
                            }}
                            // cellPadding="1"
                          >
                            <thead>
                              <tr>
                                <td
                                  style={{
                                    padding: "10px 0px",
                                    fontSize: "10px",
                                    fontWeight: "800",
                                    borderTop: "1px solid black",
                                    textAlign: "center",
                                    backgroundColor: "#b7b7c4",
                                    borderRight: "1px solid black",
                                    borderLeft: "1px solid black",
                                  }}
                                >
                                  Date
                                </td>
                                <td
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: "800",
                                    borderTop: "1px solid black",
                                    padding: "10px 0px",
                                    textAlign: "center",
                                    backgroundColor: "#b7b7c4",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  Shift
                                </td>
                                <td
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: "800",
                                    borderTop: "1px solid black",
                                    padding: "10px 0px",
                                    textAlign: "center",
                                    backgroundColor: "#b7b7c4",
                                    borderRight: "1px solid black",
                                    width:"20px"
                                  }}
                                >
                                  Material Name
                                </td>
                                <td
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: "800",
                                    borderTop: "1px solid black",
                                    padding: "10px 0px",
                                    textAlign: "center",
                                    backgroundColor: "#b7b7c4",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  Grade
                                </td>

                                <td
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: "800",
                                    borderTop: "1px solid black",
                                    padding: "10px 0px",
                                    textAlign: "center",
                                    backgroundColor: "#b7b7c4",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  Material Lot No
                                </td>
                                <td
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: "800",
                                    borderTop: "1px solid black",
                                    padding: "10px 0px",
                                    textAlign: "center",
                                    backgroundColor: "#b7b7c4",
                                    borderRight: "1px solid black",
                                    width: "160px",
                                  }}
                                >
                                  Material Loading in Kgs
                                </td>
                                <td
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: "800",
                                    borderTop: "1px solid black",
                                    padding: "10px 0px",
                                    textAlign: "center",
                                    backgroundColor: "#b7b7c4",
                                    borderRight: "1px solid black",
                                    width: "160px",
                                  }}
                                >
                                  Material Loading Time
                                </td>
                                <td
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: "800",
                                    borderTop: "1px solid black",
                                    padding: "10px 0px",
                                    textAlign: "center",
                                    backgroundColor: "#b7b7c4",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  Pre Heat Hours
                                </td>
                                <td
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: "800",
                                    borderTop: "1px solid black",
                                    padding: "10px 0px",
                                    textAlign: "center",
                                    backgroundColor: "#b7b7c4",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  Pre Heating Temp
                                </td>
                                <td
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: "800",
                                    borderTop: "1px solid black",
                                    padding: "10px 5px 5px 0px",
                                    textAlign: "center",
                                    backgroundColor: "#b7b7c4",
                                    borderRight: "1px solid black",
                                    width: "200px",
                                  }}
                                >
                                  Approved By
                                </td>
                              </tr>
                            </thead>

                            <tbody>
                              <HandleRead
                                keyword="material_loading"
                                setData={setmat_load}
                              />

                              {filteredRecords.length === 0 ? (
                                <td
                                  colSpan="12"
                                  style={{ textAlign: "center" }}
                                >
                                  <p
                                    id="mlcss-list-NoRec"
                                    name="mlcss-list-noRec"
                                    style={{ fontWeight: "normal" }}
                                  >
                                    No records found.
                                  </p>
                                </td>
                              ) : (
                                filteredRecords.map((mat_load, Id) => (
                                  <tr className="table_view" key={mat_load.Id}>
                                    <td
                                      id="mlcss-list-TrackDate"
                                      name="mlcss-list-TrackDate"
                                      style={{
                                        fontSize: "8px",
                                        padding: "10px 0px 5px 0px",
                                        borderRight: "1px solid black",
                                        borderLeft: "1px solid black",
                                        borderTop: "1px solid gray",
                                        width: "10px",
                                      }}
                                    >
                                      {formatDt(mat_load.TrackDate)}
                                    </td>
                                    <td
                                      id="mlcss-list-Shift"
                                      name="mlcss-list-Shift"
                                      style={{
                                        fontSize: "8px",
                                        padding: "10px 0px 5px 0px",
                                        borderRight: "1px solid black",
                                        borderTop: "1px solid gray",
                                      }}
                                    >
                                      {mat_load.Shift}
                                    </td>
                                    <td
                                      id="mlcss-list-MatName"
                                      name="mlcss-list-MatName"
                                      style={{
                                        fontSize: "8px",
                                        padding: "10px 0px 5px 0px",
                                        borderRight: "1px solid black",
                                        borderTop: "1px solid gray",
                                      }}
                                    >
                                      {mat_load.RmDescrip}
                                    </td>
                                    <td
                                      id="mlcss-list-MatGrde"
                                      name="mlcss-list-MatGrde"
                                      style={{
                                        fontSize: "8px",
                                        padding: "10px 0px 5px 0px",
                                        borderRight: "1px solid black",
                                        borderTop: "1px solid gray",
                                      }}
                                    >
                                      {mat_load.Mat_grde}
                                    </td>

                                    <td
                                      id="mlcss-list-MatLotNo"
                                      name="mlcss-list-MatLotNo"
                                      style={{
                                        fontSize: "8px",
                                        padding: "10px 0px 5px 0px",
                                        borderRight: "1px solid black",
                                        borderTop: "1px solid gray",
                                      }}
                                    >
                                      {mat_load.Mat_lot_no}
                                    </td>
                                    <td
                                      id="mlcss-list-MatLdKgs"
                                      name="mlcss-list-MatLdKgs"
                                      style={{
                                        fontSize: "8px",
                                        padding: "10px 0px 5px 0px",
                                        borderRight: "1px solid black",
                                        borderTop: "1px solid gray",
                                      }}
                                    >
                                      {mat_load.Mat_ld_kgs}
                                    </td>
                                    <td
                                      id="mlcss-list-MatLdTime"
                                      name="mlcss-list-MatLdTime"
                                      style={{
                                        fontSize: "8px",
                                        padding: "10px 0px 5px 0px",
                                        borderRight: "1px solid black",
                                        borderTop: "1px solid gray",
                                      }}
                                    >
                                      {mat_load.Mat_ld_time}
                                    </td>
                                    <td
                                      id="mlcss-list-PreHtHrs"
                                      name="mlcss-list-PreHtHrs"
                                      style={{
                                        fontSize: "8px",
                                        padding: "10px 0px 5px 0px",
                                        borderRight: "1px solid black",
                                        borderTop: "1px solid gray",
                                      }}
                                    >
                                      {mat_load.Pre_ht_hrs}
                                    </td>
                                    <td
                                      id="mlcss-list-PreHtTmp"
                                      name="mlcss-list-PreHtTmp"
                                      style={{
                                        fontSize: "8px",
                                        padding: "10px 0px 5px 0px",
                                        borderRight: "1px solid black",
                                        borderTop: "1px solid gray",
                                      }}
                                    >
                                      {mat_load.Pre_ht_tmp}
                                    </td>
                                    <td
                                      id="mlcss-list-Qc"
                                      name="mlcss-list-Qc"
                                      style={{
                                        fontSize: "8px",
                                        padding: "10px 5px 5px 0px",
                                        borderRight: "1px solid black",
                                        borderTop: "1px solid gray",
                                      }}
                                    >
                                      {mat_load.Qc_sign}-{mat_load.QaName}
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>
                      )}
                    {/* </Html> */}
                    <Text render={({pageNumber,totalPages})=>(`Page #${pageNumber}/${totalPages}`)} fixed style={{position:'absolute',fontSize:12,bottom:10,left:0,right:0,textAlign:"center",color:'gray'}}/>
                  </Page>
                </Document>
              }
            >
              {({ loading }) =>
                loading ? (
                  "Please Wait ..... "
                ) : (
                  <div
                    style={{
                      backgroundColor: "#0a8df8",
                      padding: "4px",
                      borderRadius: "10px",
                      color: "white",
                      textAlign: "center",
                      zIndex: "100",
                      margin: "5px 24px",
                    }}
                  >
                    PDF ⬇️
                  </div>
                )
              }
            </PDFDownloadLink>

            {/* </Dropdown.Item> */}
            <Dropdown.Item>
              <ReactHTMLTableToExcel
                id="excelExportButton"
                className="download-table-xls-button"
                table="excelTable"
                filename="MaterialLoading Report"
                sheet="sheet1"
                buttonText="Excel ⬇️"
              />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {/* <div className="custom-button1">
                   <Dropdown>
                     <Dropdown.Toggle  variant="success" id="downloadDropdown">
                       Download ⬇️
                     </Dropdown.Toggle>
                     <Dropdown.Menu>
                       <Dropdown.Item>
                         <button className="custom-button" onClick={onButtonClick}>
                           PDF ⬇️
                         </button>
                       </Dropdown.Item>
                       <Dropdown.Item>
                         <ReactHTMLTableToExcel
                           id="excelExportButton"
                           className="download-table-xls-button"
                           table="excelTable"
                           filename="MaterialLoading Report"
                           sheet="sheet1"
                           buttonText="Excel ⬇️"
                         />
                       </Dropdown.Item>
                     </Dropdown.Menu>
                   </Dropdown></div>   */}
      {/* <div>
        <thead>
          <FilterReport
            timePeriod={timePeriod}
            handleTimePeriodChange={handleTimePeriodChange}
            filterDate={filterDate}
            handleFilterDateChange={handleFilterDateChange}
            startDate={startDate}
            handleStartDateChange={handleStartDateChange}
            endDate={endDate}
            handleEndDateChange={handleEndDateChange}
            componentref={componentRef}
            downloadEnabled={downloadEnabled}

          />

        </thead>


      </div> */}
      <br />
      <br />
      <div>
        <table
          id="excelTable"
          style={{ "text-align": "center", width: "100%" }}
          cellPadding="1"
        >
          <thead>
            <tr>
              <th style={{ "text-align": "center" }}>
                <img src={Unitechlogo} style={{ width: "70px" }}></img>
              </th>
              <th
                id="mlcss-Title-List"
                name="mlcss-Title-List"
                colSpan="9"
                style={{ "text-align": "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">
                  Consolidated Report - Material Loading
                </font>
              </th>
            </tr>
            <tr>
              <th className="table-cell2">Date</th>
              <th className="table-cell2">Shift</th>
              <th className="table-cell2">Material Name</th>
              <th className="table-cell2">Grade</th>

              <th className="table-cell2">Material Lot No</th>
              <th className="table-cell2">Material Loading in Kgs</th>
              <th className="table-cell2">Material Loading Time</th>
              <th className="table-cell2">Pre Heat Hours</th>
              <th className="table-cell2">Pre Heating Temp</th>
              <th className="table-cell3">Approved By</th>
            </tr>
          </thead>

          <tbody>
            <HandleRead keyword="material_loading" setData={setmat_load} />

            {filteredRecords.length === 0 ? (
              <td colSpan="12" style={{ textAlign: "center" }}>
                <p
                  id="mlcss-list-NoRec"
                  name="mlcss-list-noRec"
                  style={{ fontWeight: "bold" }}
                >
                  No records found.
                </p>
              </td>
            ) : (
              filteredRecords.map((mat_load, Id) => (
                <tr className="table_view" key={mat_load.Id}>
                  <td
                    id="mlcss-list-TrackDate"
                    name="mlcss-list-TrackDate"
                    className="table-cell"
                  >
                    {formatDt(mat_load.TrackDate)}
                  </td>
                  <td
                    id="mlcss-list-Shift"
                    name="mlcss-list-Shift"
                    className="table-cell"
                  >
                    {mat_load.Shift}
                  </td>
                  <td
                    id="mlcss-list-MatName"
                    name="mlcss-list-MatName"
                    className="table-cell"
                  >
                    {mat_load.RmDescrip}
                  </td>
                  <td
                    id="mlcss-list-MatGrde"
                    name="mlcss-list-MatGrde"
                    className="table-cell"
                  >
                    {mat_load.Mat_grde}
                  </td>

                  <td
                    id="mlcss-list-MatLotNo"
                    name="mlcss-list-MatLotNo"
                    className="table-cell"
                  >
                    {mat_load.Mat_lot_no}
                  </td>
                  <td
                    id="mlcss-list-MatLdKgs"
                    name="mlcss-list-MatLdKgs"
                    className="table-cell"
                  >
                    {mat_load.Mat_ld_kgs}
                  </td>
                  <td
                    id="mlcss-list-MatLdTime"
                    name="mlcss-list-MatLdTime"
                    className="table-cell"
                  >
                    {mat_load.Mat_ld_time}
                  </td>
                  <td
                    id="mlcss-list-PreHtHrs"
                    name="mlcss-list-PreHtHrs"
                    className="table-cell"
                  >
                    {mat_load.Pre_ht_hrs}
                  </td>
                  <td
                    id="mlcss-list-PreHtTmp"
                    name="mlcss-list-PreHtTmp"
                    className="table-cell"
                  >
                    {mat_load.Pre_ht_tmp}
                  </td>
                  <td
                    id="mlcss-list-Qc"
                    name="mlcss-list-Qc"
                    className="table-cell"
                  >
                    {mat_load.Qc_sign}-{mat_load.QaName}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MaterialLoadingReport;
