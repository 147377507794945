import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import SmpCreate from "./SmpCreate";
import SmpView from "./SmpView";
import SmpReport from "./SmpReport";
import { GetAccess } from "../../../Lib/GetAccess";

function SmpTabs() {
  const [reportTabClicked, setReportTabClicked] = useState(false);

  const handleTabClick = (tabKey) => {
    if (tabKey === "tab3" || tabKey === "tab2") {
      setReportTabClicked(true);
    } else {
      setReportTabClicked(false);
    }
  };
  
  return (
    <div>
      <div>
        <Tabs
          defaultActiveKey="tab1"
          // // transition={false}
          id="noanim-tab-example"
          className="mb-3"
          onSelect={handleTabClick}
        >
         {(GetAccess('production', 'hod') || GetAccess("ADMIN","GM") || GetAccess("production","INSPECTOR"))? <Tab name="smp.CreateTab" eventKey="tab1" title="Create">
            <div>
              <div className="set-layout">
                <SmpCreate />
              </div>
            </div>
          </Tab>:""}
          <Tab eventKey="tab2" title="View">
            <div>
              <div className="set-layout">
              {reportTabClicked && <SmpView />}
              </div>
            </div>
          </Tab>
          {(GetAccess('production', 'hod') || GetAccess("ADMIN","GM") || GetAccess("production","INSPECTOR"))?   <Tab name="smp.ViewTab" eventKey="tab3" title="Report">
            <div>
              <div className="set-layout">
              {reportTabClicked && <SmpReport />}
              </div>
            </div>
          </Tab>:""}
        </Tabs>
      </div>
    </div>
  );
}

export default SmpTabs;
