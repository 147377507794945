import React from 'react';


const RadioOkNotOkayFetch = (props) => {
  return(
    <div style={{display:'flex',gap:'25px'}}>
    <div style={{padding:"0px 10px" , cursor:"pointer",display:'flex',alignContent:'center',justifyContent:'center'}}>
    <input
    style={{cursor:"pointer",width:'18px',height:'18px'}}
    className="custom-radio"
    type="radio"
    name={props.inputName}
    value="Ok"
    
    checked={props.selectedValue === "Ok"}
    onChange={props.onChange}
    disabled={props.disabled}
  />
  <span style={{color:"green"}}>&nbsp;OK</span>
  </div>
  {/* <br></br> */}
  <div style={{whiteSpace:"nowrap",display:'flex',alignContent:'center',justifyContent:'center'}}>
  <input
  style={{cursor:"pointer",width:'18px',height:'18px'}}
    className="custom-radio"
    type="radio"
    name={props.inputName}
    value="NotOk"
    
    checked={props.selectedValue === "NotOk"}
    onChange={props.onChange}
    disabled={props.disabled}

  />
  <span style={{color:"red"}}>&nbsp;NOT OK</span>
  
  </div>
  </div>
);
}
export default RadioOkNotOkayFetch;