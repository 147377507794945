import React, { useEffect, useState, useRef } from "react";
import ReadRecord from "../../Lib/API/ReadRecord";
import Lable from "../Fields/Lable";
import Field from "../Fields/Field";
import { useNavigate } from "react-router-dom";
import DeleteRecord from "../../Lib/API/DeleteRecord";
import "./MachineListView.css";
import Pagination from "./Pagination";
import DatePicker from "react-datepicker";
import { Dropdown } from "react-bootstrap";
import { MdDelete, MdEnergySavingsLeaf } from "react-icons/md";
import { CURRENT_Date } from "../../Utils/GeneralConstants";
import LoadingPage from "../Handler/LoadingPage";
import * as XLSX from "xlsx";
const MachineListView = ({
  Title,
  header,
  filter,
  quary,
  table,
  navigation,
  standard,
  tableDelete,
  dateFilter,
  DefaultDateFilter,
  // HandleDateRangeFilter,
}) => {
  const limit = 10;
  const [showTable, setShowTable] = useState(false);
  const [exc, setexc] = useState(false);
  const [records, setrecords] = useState([{}]);
  const [Counts, setCounts] = useState(1);
  const [Quary, setQuary] = useState(CreateQuary());
  const [Filters, setFilters] = useState(() => {
    let fill = filter.map((item) => {
      return { [item]: "" };
    });
    fill.push({ start: "", end: "" });
    return fill;
  });
  const [FilterQuary, setFilterQuary] = useState("");
  const [Startdate, setStartdate] = useState("");
  const [Loading, setLoading] = useState(false);
  const [Selected, setSelected] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    HandleFilter();
    // setFilters(() => {
    //   Filters.push({ start: CURRENT_Date, end: "" });
    //   return Filters;
    // });
  }, []);

  // useEffect(() => {
  //   //console.log("Counts - ", Counts);
  // }, [Counts]);
  // useEffect(() => {
  //   //console.log("Quary review", Quary);
  // }, [Quary]);
  // useEffect(() => {
  //   //console.log("Filters review", Filters);
  // }, [Filters]);
  useEffect(() => {
    console.log("records review", records);
  }, [records]);
  // useEffect(() => {
  //   //console.log("Selected review", Selected);
  // }, [Selected]);

  function CreateFilterQuary() {
    let quary = Quary;
    let alter = "";
    Filters.map((item, index) => {
      if (index != Filters.length - 1) {
        let key = Object.keys(item)[0];
        //console.log("check", Object.keys(item)[0]);
        if (item[key] === "") {
          alter += "and " + key + " like '%' ";
        } else {
          alter += "and " + key + " like '%" + item[key] + "%' ";
        }
      }
    });

    if (dateFilter) {
      let date = Filters[Filters.length - 1];
      if (DefaultDateFilter?.start) {
        date.start = DefaultDateFilter?.start;
      }
      if (DefaultDateFilter?.end) {
        date.start = DefaultDateFilter?.end;
      }

      if (date.start === "") {
        if (date.end === "") {
        } else if (date.start === date.end) {
          
          const startDateTime = date.start + " 00:00:00";
          const endDateTime = date.end + " 23:59:59";
          alter += `and ${dateFilter} BETWEEN '${startDateTime}' and '${endDateTime}' `;
        } else {
          alter += "and " + dateFilter + " <= '" + date.end + "' ";
        }
      } else {
        if (date.end === "") {
          alter += "and " + dateFilter + " >= '" + date.start + "' ";
        } else if (date.start === date.end) {
          
          const startDateTime = date.start + " 00:00:00";
          const endDateTime = date.end + " 23:59:59";
          alter += `and ${dateFilter} BETWEEN '${startDateTime}' and '${endDateTime}' `;
        } else {
          alter +=
            "and " +
            dateFilter +
            " BETWEEN '" +
            date.start +
            "' and '" +
            date.end +
            "' ";
        }
      }
    }
    let filterQuary = "All";
    Filters.map((item, index) => {
      if (index != Filters.length - 1) {
        let key = Object.keys(item)[0];
        if (item[key] !== "") {
          filterQuary +=
            " > " +
            header
              .map((item) => (item.value === key ? item.label : false))
              .filter((item) => item != false)[0] +
            "=" +
            item[key];
        }
      }
    });
    setFilterQuary(filterQuary);

    return alter;
  }
  function HandleFilter() {
    let filter = CreateFilterQuary();
    runQuary(Quary.replace("Quary%", filter).replace("limit%", 0));

    getcount("where " + standard + " " + filter);
  }
  function ClearFilter() {
    setFilters(() => {
      let fill = filter.map((item) => {
        return { [item]: "" };
      });
      fill.push({ start: "", end: "" });
      return fill;
    });
    setFilterQuary("All");
    runQuary(Quary.replace("Quary%", "").replace("limit%", 0));
    getcount("where " + standard + " " + "");
  }

  function pageChange(currentPage) {
    // runQuary(
    //   Quary.replace(
    //     "Quary%",
    //     "and " + Filters[0] + " like '%" + Filters[1] + "%'"
    //   ).replace("limit%", currentPage)
    // );
    // getcount("where " + Filters[0] + " like '%" + Filters[1] + "%'");
    let filter = CreateFilterQuary();
    runQuary(Quary.replace("Quary%", filter).replace("limit%", currentPage));

    getcount("where " + standard + " " + filter);
  }

  function runQuary(Quary) {
    setLoading((prev) => {
      prev = true;
      return prev;
    });
    ReadRecord(Quary).then((res) => {
      //console.log("runQuary", res, "quary", Quary);
      setrecords(res.data[0].count === "0" ? [] : res.data[0].rows);
      setLoading((prev) => {
        prev = false;
        return prev;
      });
    });
  }
  function getcount(quary) {
    ReadRecord("Select count(Id) count from " + table + " " + quary).then(
      (res) => {
        //console.log("getcount", res, "Select count(Id) count from " + table + " " + quary);
        setCounts(
          Math.ceil(
            (res.data[0].rows[0] ? res.data[0].rows[0].count : 1) / limit
          )
        );
      }
    );
  }

  function CreateQuary() {
    let Quary = "";
    if (quary === undefined) {
      Quary += "SELECT ";
      header.map((item) => {
        Quary += item.value + ",";
      });
      Quary = Quary.substring(0, Quary.length - 1);
      Quary += " from " + table + " where ActiveStatus = 1 Quary%";
    } else {
      if (quary.includes("group by ")) {
        Quary = quary;
        Quary = Quary.split("group by ");
        Quary[1] = "group by " + Quary[1];
        Quary = Quary[0] + "where " + standard + " Quary% " + Quary[1];
      } else {
        Quary = quary + " where " + standard + " Quary% ";
      }
    }
    Quary += " limit " + limit + " offset limit%;";
    return Quary;
  }

  const exportToExcel = () => {
    setShowTable(true);
    setexc(true);
    setTimeout(() => {
      if (records.length === 0) {
        alert("No records found. Cannot export to Excel.");
        setFilters(() => {
          let fill = filter.map((item) => {
            return { [item]: "" };
          });
          fill.push({ start: "", end: "" });
          return fill;
        });

        setFilterQuary("All");
        getcount("where " + standard + " " + "");
        setrecords("");
        setexc(false);
        setTimeout(() => {
          setShowTable(false);
        }, 1000);
        return;
      }

      const sheetData = document.getElementById("excelTable");
      const wsData = XLSX.utils.table_to_sheet(sheetData);
      wsData["!rows"] = [{ hpx: 50 }];
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, wsData, "Sheet1");
      XLSX.writeFile(wb, "DailyMachine StartUp & RestartUp.xlsx");
      setFilters(() => {
        let fill = filter.map((item) => {
          return { [item]: "" };
        });
        fill.push({ start: "", end: "" });
        return fill;
      });

      setFilterQuary("All");
      getcount("where " + standard + " " + "");
      setrecords("");
      setexc(false);
      setShowTable(false);
    }, 1000);
  };
  const contentRef = useRef(null);
  return (
    <div className="MachineListView">
      <table style={{ textAlign: "center", width: "100%" }} cellPadding="1">
        <thead>
          <tr>
            <th colSpan={header.length + 4}>
              <Lable type={"Head"} value={Title} />
            </th>
          </tr>
          <tr>
            <td colSpan={header.length + 3} style={{ textAlign: "center" }}>
              <div
                style={
                  dateFilter
                    ? {
                        display: "grid",
                        gridTemplateColumns: "35% 25% 25% 15%",
                        alignItems: "center",
                      }
                    : {
                        display: "grid",
                        gridTemplateColumns: "40% 40% 20%",
                        alignItems: "center",
                      }
                }
              >
                {dateFilter && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      borderRight: "1px solid",
                    }}
                  >
                    <label style={{ fontSize: "16px", paddingRight: "10px" }}>
                      Start
                    </label>
                    <input
                      type="date"
                      placeholder="Filter by Start Date"
                      value={Filters[Filters.length - 1].start}
                      onChange={(event) => {
                        const currentEndDate = Filters[Filters.length - 1].end;

                        if (
                          currentEndDate &&
                          event.target.value > currentEndDate
                        ) {
                          alert("Start date cannot be after end date");
                          return;
                        }
                        setFilters(() => {
                          let prev = [...Filters];
                          prev[Filters.length - 1].start = event.target.value;
                          return prev;
                        });
                      }}
                      style={{ marginRight: "10px", width: "150px" }}
                    />

                    <label
                      style={{
                        fontSize: "16px",
                        paddingRight: "10px",
                        paddingLeft: "30px",
                      }}
                    >
                      End
                    </label>
                    <input
                      type="date"
                      style={{ marginRight: "10px", width: "150px" }}
                      placeholder="Filter by End Date"
                      value={Filters[Filters.length - 1].end}
                      onChange={(event) => {
                        if (
                          event.target.value < Filters[Filters.length - 1].start
                        ) {
                          alert("End date cannot be before start date");
                          return;
                        }
                        setFilters(() => {
                          let prev = [...Filters];
                          prev[Filters.length - 1].end = event.target.value;
                          return prev;
                        });
                      }}
                    />
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                    borderRight: "1px solid",
                  }}
                >
                  <Field
                    name={Selected}
                    options={{
                      type: "dropdown",
                      values: filter.map(
                        (item) =>
                          header.filter((head) => head.value === item)[0]
                      ),
                    }}
                    value={filter}
                    OnChange={(e) => {
                      setSelected(
                        Filters.map((item, index) => {
                          if (Object.keys(item).includes(e.target.value))
                            return index;
                        }).filter((item) => item != undefined)[0]
                      );
                      setQuary(CreateQuary());
                    }}
                  />
                  <h3> =</h3>
                  <Field
                    name={filter[Selected]}
                    options={{ type: "text" }}
                    value={Filters[Selected]}
                    OnChange={(e) => {
                      setFilters(() => {
                        let prev = [...Filters];
                        prev[Selected][filter[Selected]] = e.target.value;
                        return prev;
                      });
                      // HandleFilter(e.target.value);
                    }}
                  />
                </div>
                {/* <div
                  style={{
                    height: "100%",
                    borderRight: "1px solid",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      textAlign: "start",
                      paddingLeft: "5px",
                      paddingTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    {FilterQuary}
                  </p>
                </div> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Field
                    label={"Filter"}
                    options={{ type: "Button" }}
                    onClick={() => {
                      HandleFilter();
                    }}
                  />
                  <Field
                    label={"Clear Filter"}
                    options={{ type: "Button" }}
                    onClick={() => {
                      ClearFilter();
                    }}
                  />
                </div>
                {Filters[Filters.length - 1].start &&
                Filters[Filters.length - 1].end ? (
                  <div
                    style={{
                      width: "100%",
                      marginLeft: "20px",
                      borderRadius: "20px",
                    }}
                  >
                    <Dropdown style={{ width: "50%", al: "center" }}>
                      <Dropdown.Toggle
                        style={{
                          color: "black",
                          borderBottom: "1px solid #5abfbf",
                        }}
                      >
                        Download ⬇️
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {/* <Dropdown.Item>
                     <button style={{color: "black", borderBottom: "1px solid #5abfbf" }} onClick={generatePDF}>
                       PDF ⬇️
                     </button>
                   </Dropdown.Item> */}
                        <Dropdown.Item>
                          <button
                            style={{
                              color: "black",
                              borderBottom: "1px solid #5abfbf",
                            }}
                            onClick={exportToExcel}
                          >
                            Excel⬇️
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                ) : (
                  <div
                    style={{
                      fontSize: "16px",
                      marginLeft: "20%",
                      padding: "5px",
                    }}
                  >
                    <Field
                      label={"Download"}
                      options={{ type: "Button" }}
                      onClick={() => alert("Choose both start and end dates")}
                    />
                  </div>
                )}
              </div>
            </td>
          </tr>
          {/* <tr>
            <td colSpan={3}></td>
            <td colSpan={2}></td>

            <td colSpan={1}></td>
            <td colSpan={3}></td>
            <img 
    style={{ width: "50px", float: "left" }} 
    src={Filter} 
    alt="Filter"  
    onClick={handleFilterClick}
  />
            <td colSpan={header.length + 1}></td>
          </tr> */}
          <tr>
            {header.map((item) => (
              <th key={item.value}>{item.label}</th>
            ))}
            <th colSpan={3}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Loading ? (
            <tr>
              <td colSpan={header.length + 3} style={{ textAlign: "center" }}>
                <LoadingPage size={"350px"} />
              </td>
            </tr>
          ) : records.length == "0" ? (
            <tr>
              <td colSpan={header.length + 3} style={{ textAlign: "center" }}>
                No Record Found
              </td>
            </tr>
          ) : (
            records.map((record) => (
              <tr key={record.Id + 1} className={"table_view"}>
                {console.log("log for records", record)}
                {header.map((item) => (
                  <td
                    key={item.value + 1}
                    style={
                      table === "machine_details md"
                        ? item.value.split(".")[1] == undefined
                          ? record[item.value] === "" ||
                            record[item.value] === null
                            ? { backgroundColor: "#de7c9b" }
                            : { backgroundColor: "white" }
                          : record[item.value.split(".")[1]] === "" ||
                            record[item.value] === null
                          ? { backgroundColor: "#de7c9b" }
                          : { backgroundColor: "white" }
                        : { backgroundColor: "white" }
                    }
                  >
                    {item.value.split(".")[1] == undefined ? (
                      item.value === "Approval_status" ? (
                        <div>
                          {record.Approval_status === "1"
                            ? "Approved"
                            : record.Approval_status === "0"
                            ? "Rejected"
                            : "Waiting For Approval"}
                        </div>
                      ) : (
                        <div>{record[item.value]}</div>
                      )
                    ) : item.value.split(".")[1] === "Approval_status" ? (
                      <div>
                        {record.Approval_status === "1"
                          ? "Approved"
                          : record.Approval_status === "0"
                          ? "Rejected"
                          : "Waiting For Approval"}
                      </div>
                    ) : (
                      <div>{record[item.value.split(".")[1]]}</div>
                    )}
                  </td>
                ))}
                <td style={{ width: "2%", whiteSpace: "nowrap" }}>
                  <button
                    className="view"
                    disabled={record.Id === null || record.Id === undefined}
                    onClick={() => {
                      navigate("/" + navigation + "/view/" + record.Id);
                    }}
                  >
                    View&#x1F441;
                  </button>
                </td>
                <td style={{ width: "2%", whiteSpace: "nowrap" }}>
                  <button
                    className="edit"
                    disabled={record.Id === null || record.Id === undefined}
                    onClick={() => {
                      navigate("/" + navigation + "/edit/" + record.Id);
                    }}
                  >
                    Edit&#x270E;
                  </button>
                </td>
                <td style={{ width: "2%", whiteSpace: "nowrap" }}>
                  <button
                    className="delete"
                    disabled={record.Id === null || record.Id === undefined}
                    onClick={() => {
                      const userConfirmed = window.confirm(
                        "Are you sure you want to delete?"
                      );
                      if (userConfirmed) {
                        DeleteRecord(tableDelete, record.Id).then((res) => {
                          HandleFilter("");
                        });
                      }
                    }}
                  >
                    Delete
                    <MdDelete />
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <div style={{ marginTop: "200%", display: showTable ? "block" : "none" }}>
        <table ref={contentRef} id="excelTable">
          <thead>
            <tr>
              {exc ? <td colSpan={7}></td> : null}
              <td
                colSpan="20"
                style={{
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                <font>REPORT - Daily Machine StartUp and ReStartUp</font>
              </td>
            </tr>
            <tr>
              <td
                colSpan={1}
                style={{
                  "text-align": "center",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                Date
              </td>
              <td
                colSpan={2}
                style={{
                  "text-align": "center",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                Machine No
              </td>
              <td
                colSpan={3}
                style={{
                  "text-align": "center",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                Part Name
              </td>
              <td
                colSpan={1}
                style={{
                  "text-align": "center",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                Shot 1
              </td>
              <td
                colSpan={1}
                style={{
                  "text-align": "center",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                Shot 2
              </td>
              <td
                colSpan={1}
                style={{
                  "text-align": "center",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                Shot 3
              </td>
              <td
                colSpan={1}
                style={{
                  "text-align": "center",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                Shot 4
              </td>
              <td
                colSpan={1}
                style={{
                  "text-align": "center",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                Shot 5
              </td>
              <td
                colSpan={1}
                style={{
                  "text-align": "center",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                Shot 6
              </td>
              <td
                colSpan={1}
                style={{
                  "text-align": "center",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                Shot 7
              </td>
              <td
                colSpan={1}
                style={{
                  "text-align": "center",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                Shot 8
              </td>
              <td
                colSpan={1}
                style={{
                  "text-align": "center",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                Shot 9
              </td>
              <td
                colSpan={1}
                style={{
                  "text-align": "center",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                Shot 10
              </td>
              <td
                colSpan={1}
                style={{
                  "text-align": "center",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                Shot 11
              </td>
              <td
                colSpan={3}
                style={{
                  "text-align": "center",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                Approved By
              </td>
              <td
                colSpan={3}
                style={{
                  "text-align": "center",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                Verified By
              </td>
            </tr>
            {records.length > 0 ? (
              records.map((record) => (
                <tr key={record.Id}>
                  <td colSpan={1}> {record.Date}</td>
                  <td colSpan={2}> {record.Machine_No}</td>
                  <td colSpan={3}> {record.Part_Name}</td>
                  <td colSpan={1}> {record.Shot_1}</td>
                  <td colSpan={1}> {record.Shot_2}</td>
                  <td colSpan={1}> {record.Shot_3}</td>
                  <td colSpan={1}> {record.Shot_4}</td>
                  <td colSpan={1}> {record.Shot_5}</td>
                  <td colSpan={1}> {record.Shot_6}</td>
                  <td colSpan={1}> {record.Shot_7}</td>
                  <td colSpan={1}> {record.Shot_8}</td>
                  <td colSpan={1}> {record.Shot_9}</td>
                  <td colSpan={1}> {record.Shot_10}</td>
                  <td colSpan={1}> {record.Shot_11}</td>
                  <td colSpan={3}>
                    {record.approved ? `${record.approved}/` : ""}
                    {record.approved_name}
                  </td>
                  <td colSpan={3}>
                    {record.verified ? `${record.verified}/` : ""}
                    {record.verified_name}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="20"
                  style={{ "text-align": "center", whiteSpace: "nowrap" }}
                >
                  No Record Found
                </td>
              </tr>
            )}
          </thead>
        </table>
      </div>
      <Pagination npage={Number(Counts)} pageChange={pageChange} />
    </div>
  );
};

export default MachineListView;
