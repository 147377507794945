
import React, { useEffect, useState } from "react";
import GetRecord from "../../Lib/GetRecord";
import { useParams } from "react-router-dom";
import Base64Convertor from "../../Lib/Base64Convertor.js";
import {
  SECRET_KEY,
  PROCESS_TRANSACTION_UPDATE_URL,
} from "../../Utils/constants.js";
import * as GeneralConstants from "../../Utils/GeneralConstants.js";
import { getAPIRequest } from "../../Utils/APIUtility.js";
function EmployeeDetail() {
  const [isEditing, setIsEditing] = useState({
    EmpNme: false,
    EmpDept: false,
    Loc: false,

    Img: false,
    Gen: false,
    Sts: false,

    Flexy1: false,
    Flexy2: false,
    Flexy3: false,
    Flexy4: false,
  });
  const [empMasterAddState, setEmpMasterAddState] = useState({
    SelectedOption: "",
    EmpCde: "",
    EmpNme: "",
    EmpDept: "",
    Loc: "",
    Desgn: "",
    Img: "",
    Gen: "",
    Sts: "",

    Flexy1: "",
    Flexy2: "",
    Flexy3: "",
    Flexy4: "",
    isPaginationVisible: true,
    currentPage: 1,
    Id: "",
  });
  const [back, setback] = useState(false);
  const { empId } = useParams();
  const [emp_mas, setemp_mas] = useState("");
  useEffect(() => {
    console.log(empId);

    const empCodeFromLocalStorage = localStorage.getItem("empId");

    if (empCodeFromLocalStorage !== undefined && empCodeFromLocalStorage !== null) {
      GetRecord(
        {
          _keyword_: "emp_details_by_Profile",
          emp_code: empId,
        },
        setemp_mas
      ).then(async (res) => {
        console.log("res - ", res);
        transferValue2(res[0])
        await timeout(1000);
        setback(true);
      });
    }
  }, [empId]);

  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }

  const transferValue2 = (e) => {
    setEmpMasterAddState({
      ...empMasterAddState,
      isPaginationVisible: false,
      Id: e.Id,
      EmpCde: e.Emp_code,
      EmpNme: e.Emp_name,
      EmpDept: e.Emp_department,
      Loc: e.Emp_location,
      Desgn: e.Emp_designation,
      Img: e.Emp_image,
      Gen: e.Emp_gender,
      Sts: e.Emp_status,
      Flexy1: e.Emp_flex_01,
      Flexy2: e.Emp_flex_02,
      Flexy3: e.Emp_flex_03,
      Flexy4: e.Emp_flex_04,
    });

  };
  const handleUpdate = (e) => {
    e.preventDefault();

    if (!empMasterAddState.EmpCde || !empMasterAddState.EmpNme) {
      setIsCreating(true);

      alert(GeneralConstants.MANDATORY_ALERT);
      return;
    }
    const data = {
      data: [
        {
          Id: empMasterAddState.Id,
          Emp_code: empMasterAddState.EmpCde,
          Emp_name: empMasterAddState.EmpNme,
          Emp_department: empMasterAddState.EmpDept,
          Emp_location: empMasterAddState.Loc,
          Emp_designation: empMasterAddState.Desgn,
          Emp_image: empMasterAddState.Img,
          Emp_gender: empMasterAddState.Gen,
          Emp_status: empMasterAddState.Sts,

          Emp_flex_01: empMasterAddState.Flexy1,
          Emp_flex_02: empMasterAddState.Flexy2,
          Emp_flex_03: empMasterAddState.Flexy3,
          Emp_flex_04: empMasterAddState.Flexy4,
        },
      ],
    };
    data["_keyword_"] = "emp_details";
    data["secretkey"] = SECRET_KEY;
    getAPIRequest(PROCESS_TRANSACTION_UPDATE_URL, "POST", data)
      .then((response) => {
        setEmpMasterAddState({
          ...empMasterAddState,
          SelectedOption: "",
          EmpCde: "",
          EmpNme: "",
          EmpDept: "",
          Loc: "",
          Desgn: "",
          Img: "",
          Gen: "",
          Sts: "",

          Flexy1: "",
          Flexy2: "",
          Flexy3: "",
          Flexy4: "",
        });
        setemp_mas([...emp_mas, response.data]);
        console.log(response.data);
        alert(`Employee master code ${GeneralConstants.UPDATE_SUCCESSFUL}`);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        alert(GeneralConstants.UPDATE_FAILURE);
        window.location.reload();
      });
  };

  const handlePhotoChangeAfter = async (e) => {
    const selectedFile = e.target.files[0];
    let img = await Base64Convertor(selectedFile);
    setEmpMasterAddState({
      ...empMasterAddState,
      Img: img,
    })
  };
  return (
    <div>
      <form
        id="emp.edit.container"
        name="emp.edit.container"
        className="foemp-empMaster-Edit"
      >
        <table
          style={{ textAlign: "center", width: "100%", marginTop: "30px" }}
          cellPadding="5"
        >
          <tbody>
            <tr>
              <th
                id="emp.Title.Edit"
                name="emp.Title.Edit"
                colSpan="4"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">Employee Master</font>
              </th>
            </tr>
            <tr>
              <th>
                Employee Code
              </th>
              <td>
                <input
                  id="emp.edit.empCode"
                  name="emp.edit.empCode"
                  type="text"
                  value={empMasterAddState.EmpCde}
                  onChange={(e) =>
                    setEmpMasterAddState({
                      ...empMasterAddState,
                      EmpCde: e.target.value,
                    })
                  }
                  disabled
                />
              </td>
              <th>
                Employee Name
              </th>
              <td>
                {isEditing.EmpNme ? (
                  <input
                    id="emp.edit.Name"
                    name="emp.edit.empName"
                    type="text"
                    value={empMasterAddState.EmpNme}
                    onChange={(e) =>
                      setEmpMasterAddState({
                        ...empMasterAddState,
                        EmpNme: e.target.value,
                      })
                    }
                  />
                ) : (
                  <span>
                    {empMasterAddState.EmpNme}{" "}
                    <span
                      style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
                      onClick={() =>
                        setIsEditing({ ...isEditing, EmpNme: true })
                      }
                    >
                      ✏️
                    </span>
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <th>Employee Department</th>
              <td>
                {isEditing.EmpDept ? (
                  <input
                    id="emp.edit.Dept"
                    name="emp.edit.empDept"
                    type="text"
                    value={empMasterAddState.EmpDept}
                    onChange={(e) =>
                      setEmpMasterAddState({
                        ...empMasterAddState,
                        EmpDept: e.target.value,
                      })
                    }
                  />
                ) : (
                  <span>
                    {empMasterAddState.EmpDept}{" "}
                    <span
                      style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
                      onClick={() =>
                        setIsEditing({ ...isEditing, EmpDept: true })
                      }
                    >
                      ✏️
                    </span>
                  </span>
                )}
              </td>

              <th>Location</th>
              <td>
                {isEditing.Loc ? (
                  <input
                    id="emp.edit.Loc"
                    name="emp.edit.empLoc"
                    type="text"
                    value={empMasterAddState.Loc}
                    onChange={(e) =>
                      setEmpMasterAddState({
                        ...empMasterAddState,
                        Loc: e.target.value,
                      })
                    }
                  />
                ) : (
                  <span >
                    {empMasterAddState.Loc}{" "}
                    <span
                      style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
                      onClick={() =>
                        setIsEditing({ ...isEditing, Loc: true })
                      }
                    >
                      ✏️
                    </span>
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <th>Designation</th>
              <td>
                <input
                  id="emp.edit.empDesignation"
                  name="emp.edit.empDesignation"
                  type="text"
                  value={empMasterAddState.Desgn}
                  onChange={(e) =>
                    setEmpMasterAddState({
                      ...empMasterAddState,
                      Desgn: e.target.value,
                    })
                  } disabled
                />
              </td>

              <th>Image</th>
              <td>
                {isEditing.Img ? (
                  <input
                    id="empmc.EmployeeImage"
                    name="empmc.EmployeeImage"
                    type="file"
                    onChange={handlePhotoChangeAfter}
                  />
                ) : (

                  <div >
                    {empMasterAddState.Img ? (
                      <img
                        src={empMasterAddState.Img}
                        alt="Selected"
                        style={{
                          marginLeft: "20px",
                          display: "block",
                          width: "150px",
                          height: "150px",
                          justifyContent: "center"
                        }}
                      />
                    ) : (
                      ""
                    )}
                    <span
                      style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
                      onClick={() => setIsEditing({ ...isEditing, Img: true })}
                    >
                      ✏️
                    </span>
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <th>&nbsp;&nbsp;Gender</th>
              <td>
                {isEditing.Gen ? (
                  <div>
                    <label className="emp-gender">
                      <input
                        type="radio"
                        name="gender"
                        value="male"
                        checked={empMasterAddState.Gen === "male"}
                        onChange={(e) =>
                          setEmpMasterAddState({
                            ...empMasterAddState,
                            Gen: e.target.value,
                          })
                        }
                      />
                      Male&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </label>
                    <label className="emp-gender">
                      <input
                        type="radio"
                        name="gender"
                        value="female"
                        checked={empMasterAddState.Gen === "female"}
                        onChange={(e) =>
                          setEmpMasterAddState({
                            ...empMasterAddState,
                            Gen: e.target.value,
                          })
                        }
                      />
                      Female&nbsp;&nbsp;&nbsp;&nbsp;
                    </label>
                    <label className="emp-gender">
                      <input
                        type="radio"
                        name="gender"
                        value="others"
                        checked={empMasterAddState.Gen === "others"}
                        onChange={(e) =>
                          setEmpMasterAddState({
                            ...empMasterAddState,
                            Gen: e.target.value,
                          })
                        }
                      />
                      Others
                    </label>
                  </div>
                ) : (
                  <div>
                    {empMasterAddState.Gen}
                    <span
                      style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
                      onClick={() => setIsEditing({ ...isEditing, Gen: true })}
                    >
                      ✏️
                    </span>
                  </div>
                )}
              </td>

              <th>Status</th>
              <td>
                {!isEditing.Sts ? (
                  <span>
                    {empMasterAddState.Sts}{" "}
                    <span
                      style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
                      onClick={() => setIsEditing({ ...isEditing, Sts: true })}
                    >
                      ✏️
                    </span>
                  </span>
                ) : (
                  <input
                    id="emp.edit.empStatus"
                    name="emp.edit.empStatus"
                    type="text"
                    value={empMasterAddState.Sts}
                    onChange={(e) =>
                      setEmpMasterAddState({
                        ...empMasterAddState,
                        Sts: e.target.value,
                      })
                    }
                  />
                )}
              </td>
            </tr>


            <tr>
              <th>Flex 1</th>
              <td>
                {!isEditing.Flexy1 ? (
                  <span>
                    {empMasterAddState.Flexy1}{" "}
                    <span
                      style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
                      onClick={() => setIsEditing({ ...isEditing, Flexy1: true })}
                    >
                      ✏️
                    </span>
                  </span>
                ) : (
                  <input
                    id="emp.edit.empFlex1"
                    name="emp.edit.empFlex1"
                    type="text"
                    value={empMasterAddState.Flexy1}
                    onChange={(e) =>
                      setEmpMasterAddState({
                        ...empMasterAddState,
                        Flexy1: e.target.value,
                      })
                    }
                  />
                )}
              </td>

              <th>Flexy 2</th>
              <td>
                {!isEditing.Flexy2 ? (
                  <span>
                    {empMasterAddState.Flexy2}{" "}
                    <span
                      style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
                      onClick={() => setIsEditing({ ...isEditing, Flexy2: true })}
                    >
                      ✏️
                    </span>
                  </span>
                ) : (
                  <input
                    id="emp.edit.empFlex2"
                    name="emp.edit.empFlex2"
                    type="text"
                    value={empMasterAddState.Flexy2}
                    onChange={(e) =>
                      setEmpMasterAddState({
                        ...empMasterAddState,
                        Flexy2: e.target.value,
                      })
                    }
                  />
                )}
              </td>
            </tr>

            <tr>
              <th>Flex 3</th>
              <td>
                {!isEditing.Flexy3 ? (
                  <span>
                    {empMasterAddState.Flexy3}{" "}
                    <span
                      style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
                      onClick={() => setIsEditing({ ...isEditing, Flexy3: true })}
                    >
                      ✏️
                    </span>
                  </span>
                ) : (
                  <input
                    id="emp.edit.empFlex3"
                    name="emp.edit.empFlex3"
                    type="text"
                    value={empMasterAddState.Flexy3}
                    onChange={(e) =>
                      setEmpMasterAddState({
                        ...empMasterAddState,
                        Flexy3: e.target.value,
                      })
                    }
                  />
                )}
              </td>
              <th>Flexy 4</th>
              <td>
                {!isEditing.Flexy4 ? (
                  <span>
                    {empMasterAddState.Flexy4}{" "}
                    <span
                      style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
                      onClick={() => setIsEditing({ ...isEditing, Flexy4: true })}
                    >
                      ✏️
                    </span>
                  </span>
                ) : (
                  <input
                    id="emp.edit.empFlex4"
                    name="emp.edit.empFlex4"
                    type="text"
                    value={empMasterAddState.Flexy4}
                    onChange={(e) =>
                      setEmpMasterAddState({
                        ...empMasterAddState,
                        Flexy4: e.target.value,
                      })
                    }
                  />
                )}
              </td>
            </tr>

            <tr>
              <td colSpan="4" style={{ border: "none" }}>
                <button
                  id="emp.update"
                  name="emp.update"
                  type="submit"
                  style={{ border: "none" }}
                  onClick={(e) => handleUpdate(e)}
                >
                  Update
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
}
export default EmployeeDetail;