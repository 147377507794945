import React,{useState} from "react";
import { Tab, Tabs } from "react-bootstrap";
import LUMPCreate from "./LUMPCreate";
import LUMPView from "./LUMPView";
import LUMPReport from "./LUMPReport";
function LUMPTabs() {
  const [viewTabClicked, setViewTabClicked] = useState(false);
  const [refreshReport, setRefreshReport] = useState(0);

  const handleTabClick = (tabKey) => {
    if (tabKey === "tab2") {
      setViewTabClicked(true);
    } else {
      setViewTabClicked(false);
    }
    if (tabKey === "tab3") {
     
      setRefreshReport((prevRefresh) => prevRefresh + 1);
    }
  };
  
  return (
    <div>
      <div>
        <Tabs
          defaultActiveKey="tab1"
          // transition={false}
          id="noanim-tab-example"
          className="mb-3"
          onSelect={handleTabClick}
        >
          <Tab name="LUMPCreate-CreateTab" eventKey="tab1" title="Create">
            <div>
              <div className="set-layout">
                <LUMPCreate />
              </div>
            </div>
          </Tab>
          <Tab name="LUMPView-ViewTab" eventKey="tab2" title="View">
            <div>
              <div className="set-layout">
              {viewTabClicked && <LUMPView />}
              </div>
            </div>
          </Tab>
          <Tab name="LUMPReport-ReportTab" eventKey="tab3" title="Report">
            <div>
              <div className="set-layout">
                <LUMPReport key={refreshReport}/>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default LUMPTabs;
