import React, { useState } from "react";
import Popup from "../../../Components/Popup/Popup";
import "../../../Styles/EcnReport.css";
//import "bootstrap/dist/css/bootstrap.min.css";
import Unitechlogo from "../../../icons/Unitechlogo.jpeg";
import { GetAccess } from "../../../Lib/GetAccess";

import {
  SECRET_KEY,
  PROCESS_TRANSACTION_UPDATE_URL,
} from "../../../Utils/constants.js";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import { getAPIRequest } from "../../../Utils/APIUtility.js";

import HandleRead from "../../../Lib/HandleRead.js";
import Pagination from "../../../Components/Pagination";
import DatePicker from "../../../Lib/DatePicker.js";
import { formatDt } from "../../../Lib/formatDt";
import DropdownFetcher from "../../../Lib/DropdownFetcher.js";
import Base64Convertor from "../../../Lib/Base64Convertor";

import Select from "react-select";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import GetRecord from "../../../Lib/GetRecord";
import { FaBackspace } from 'react-icons/fa';
import reject from "../../../Lib/reject";
import approve from "../../../Lib/approve";
import UpdateRecord from "../../../Lib/updateRecord";
import { MdDelete } from "react-icons/md";
import ReadRecord from "../../../Lib/API/ReadRecord.js";

const initialState = {
  Respnse: "",
  Customer: "",
  Model: "",
  PartName: "",
  Problem: "",
  CrtDetails: "",
  Remarks: "",
  Before: "",
  After: "",
  TargDate: "",
  QualityHead: "",
  HoldHead: "",
  PlantHead: "",
  Stock: "",
  isPaginationVisible: true,
  currentPage: 1,
  filterMcno: "",
  filterDate: "",
  filterCustomer: "",
  Id: "",
  Approval: "",
};

function Report4MView() {
  const idView = "EcnReport-View";
  const nameView = "EcnReport-View";
  const nameEdit = "EcnReport-Edit";
  const idEdit = "EcnReport-Edit";
  const nameList = "EcnReport-List";
  const idList = "EcnReport-List";
  const [ecnReport, setecnReport] = useState(initialState);
  const [ecn_report, setecn_report] = useState([]);
  const [ButtonPopup2, setButtonPopup2] = useState(false);
  const [ButtonPopup, setButtonPopup] = useState(false);
  const [Employee, setEmployee] = useState([]);

  const [customer, setcustomer] = useState([]);
  const [part, setPart] = useState([]);
  const [HODEmployee, setHODEmployee] = useState([]);

  const [isCreating, setIsCreating] = useState(false);

  const [back, setback] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [ENC, setENC] = useState("");
  const [remarks, setremarks] = useState('');
  const [cardId, setcardId] = useState('');
  const [Approver, setApprover] = useState("");
  const [RejectedReason, setRejectedReason] = useState("");
  useEffect(() => {
    // console.log(id);
    if (id !== undefined) {
      GetRecord(
        {
          _keyword_: "Get_ENC_by_id",
          id: id,
        },
        setENC
      ).then(async (res) => {
        console.log("res - ", res);
        handleView(res[0])
        setButtonPopup2(true)
        setback(true)

        await timeout(1000);
        const popupDiv = document.querySelector(".popup");
        if (popupDiv) {
          const elementInsidePopup = popupDiv.querySelector("#close-btn");
          elementInsidePopup.style.display = "none";
        }
      })
    }
  }, [id])
  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }
  function handleReadRecord(id) {
    ReadRecord({
      _keyword_: "Get_approver_by_empId",
      TableID: id,
      TableName: "EcnReport",
      Approver: localStorage.getItem("empId"),
    }).then((res) => {
      // console.log("res.data,",res);
      if (res.rows > 0) {
        setApprover(res.data[0].Approver);
        setRejectedReason(res.data[0].ApprovalRemark);
      } else {
        setApprover("");
        setRejectedReason("");
      }
    }).then(()=>{
      ReadRecord({
        _keyword_: "Get_approver_ApprovalRemark",
        TableID: id,
        TableName: "EcnReport"
      }).then((res)=>{
        if(res.rows>0){
          setRejectedReason(res.data[0].ApprovalRemark);
        }else{
          setRejectedReason("");
        }
      })
    })
  }

  const recordPerPage = 20;

  const filterRecords = () => {
    const filteredData = ecn_report.filter((record) => {
      //   const filteredData = ecn_report;
      // const filteredData = ecn_report.filter((record) => {
      //   const dateMatch = record.TrackDate && record.TrackDate.includes(ecnReport.filterDate);
      const Cust = record.Customer && record.Customer
        .toLowerCase()
        .includes(ecnReport.filterCustomer.toLowerCase());
      return Cust;
    });
    return filteredData || [];
  };
  const filteredData = filterRecords();
  const npage = Math.ceil(filteredData.length / recordPerPage);
  const numbers = [...Array(npage).keys()].map((num) => num + 1);

  function handleFilterMcnoChange(event) {
    setecnReport({
      ...ecnReport,
      currentPage: 1,
      filterMcno: event.target.value,
    });
  }
  function handleFilterCustomer(event) {
    setecnReport({
      ...ecnReport,
      currentPage: 1,
      filterCustomer: event.target.value,
    });
  }
  // function handleFilterDateChange(event) {
  //   setecnReport({...ecnReport,
  //     currentPage: 1,
  //     filterDate: event.target.value
  //   });
  // }
  const firstIndex = (ecnReport.currentPage - 1) * recordPerPage;
  const lastIndex = firstIndex + recordPerPage;
  const filteredRecords = filteredData.slice(firstIndex, lastIndex);

  function prePage() {
    if (ecnReport.currentPage > 1) {
      setecnReport({ ...ecnReport, currentPage: ecnReport.currentPage - 1 });
    }
  }

  function nextPage() {
    if (ecnReport.currentPage < npage) {
      setecnReport({ ...ecnReport, currentPage: ecnReport.currentPage + 1 });
    }
  }

  function changeCpage(id) {
    setecnReport({ ...ecnReport, currentPage: id });
  }
  const viewPagination = () => {
    setecnReport({ ...ecnReport, isPaginationVisible: true });
  };
  const handleDateChange = (newDate) => {
    setecnReport({ ...ecnReport, TargDate: newDate });
  };

  const handleView = (e) => {
    handleReadRecord(e.Id);
    setButtonPopup2(true);
    setecnReport({
      ...ecnReport,
      Id: e.Id,
      isPaginationVisible: false,
      Respnse: e.Respnse,
      Customer: e.Customer,
      Model: e.Model,
      PartName: e.PartName,
      Problem: e.Problem,
      CrtDetails: e.Crt_details,
      Remarks: e.Remarks_ecn,
      Before: e.Before_ecn_pic,
      After: e.After_ecn_pic,
      TargDate: e.Target_date,
      QualityHead: `${e.Quality_head}-${e.QualityHead}`,
      HoldHead: `${e.Hold_head}-${e.HoldHead}`,
      PlantHead: `${e.Plant_head}-${e.PlantHead}`,
      Stock: e.Stock,
      Approval: e.Approval_status
    });
  };

  const handleEdit = (e) => {
    setButtonPopup(true);

    setecnReport({
      ...ecnReport,
      isPaginationVisible: false,
      Id: e.Id,
      Respnse: e.Respnse,
      Customer: { value: e.Customer, label: e.Customer },
      Model: e.Model,
      PartName: { value: e.Part_name, label: e.PartName },
      Problem: e.Problem,
      CrtDetails: e.Crt_details,
      Remarks: e.Remarks_ecn,
      Before: e.Before_ecn_pic,
      After: e.After_ecn_pic,
      TargDate: e.Target_date,
      QualityHead: { value: e.Quality_head, label: e.QualityHead },
      HoldHead: { value: e.Hold_head, label: e.HoldHead },
      PlantHead: { value: e.Plant_head, label: e.PlantHead },
      Stock: e.Stock,
    });
  };
  const handleUpdate = (e) => {
    e.preventDefault();

    if (
      !ecnReport.Respnse ||
      !ecnReport.Customer ||
      !ecnReport.Model ||
      !ecnReport.PartName ||
      !ecnReport.TargDate
    ) {
      setIsCreating(true);
      alert(GeneralConstants.MANDATORY_ALERT);
      return;
    }
    const data = {
      data: [
        {
          Id: ecnReport.Id,
          Respnse: ecnReport.Respnse,
          Customer: ecnReport.Customer.value,
          Model: ecnReport.Model,
          Part_name: ecnReport.PartName.value,
          Problem: ecnReport.Problem,
          Crt_details: ecnReport.CrtDetails,
          Remarks_ecn: ecnReport.Remarks,
          Before_ecn_pic: ecnReport.Before,
          After_ecn_pic: ecnReport.After,
          Target_date: ecnReport.TargDate,
          Quality_head: ecnReport.QualityHead.value,
          Hold_head: ecnReport.HoldHead.value,
          Plant_head: ecnReport.PlantHead.value,
          Stock: ecnReport.Stock,
        },
      ],
    };
    data["_keyword_"] = "Ecn_report";
    data["secretkey"] = SECRET_KEY;
    getAPIRequest(PROCESS_TRANSACTION_UPDATE_URL, "POST", data)
      .then((response) => {
        setecnReport({
          ...ecnReport,
          isPaginationVisible:true
        });
        // setecn_report([...ecn_report, response.data]);
        alert(`${GeneralConstants.UPDATE_SUCCESSFUL}`);
        // window.location.reload();
        setButtonPopup(false);
        handleReadEcnReport();

      })
      .catch((error) => {
        console.log(error);
        alert(GeneralConstants.UPDATE_FAILURE);
        // window.location.reload();
      });
  };
  const handlePhotoChangeAfter = async (e) => {
    const selectedFile = e.target.files[0];
    let img = await Base64Convertor(selectedFile);
    setecnReport({
      ...ecnReport,
      After: img,
    });
  };
  const handlePhotoChangeBefore = async (e) => {
    const selectedFile = e.target.files[0];
    let img = await Base64Convertor(selectedFile);
    setecnReport({
      ...ecnReport,
      Before: img,
    });
  };

  const handleCustomer = (selectedOption) => {
    const selected = customer.find(
      (option) => option.Cus_code === selectedOption.value
    );
    if (selected) {
      setecnReport({
        ...ecnReport,
        Customer: {
          value: selected.Cus_code,
          label: selected.Cus_name,
        },
      });
    }
  };
  const handlepart = (selectedOption) => {
    const selected = part.find(
      (option) => option.mc_part_code === selectedOption.value
    );

    if (selected) {
      setecnReport({
        ...ecnReport,
        PartName: {
          value: selected.mc_part_code,
          label: selected.mc_part_name,
        },
        Model: selected.mc_part_flex_01,

      });
    }
  };
  const handleQualityHead = (selectedOption) => {
    const selected = Employee.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setecnReport({
        ...ecnReport,
        QualityHead: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      });
    }
  };
  const HandleHoldingHead = (selectedOption) => {
    const selected = Employee.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setecnReport({
        ...ecnReport,
        HoldHead: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      });
    }
  };
  const HandlePlantHead = (selectedOption) => {
    const selected = HODEmployee.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setecnReport({
        ...ecnReport,
        PlantHead: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      });
    }
  };
  function GetSelectValue(value, keyword, setName) {

    if (value.length > 0) {
      ReadRecord({
        _keyword_: keyword,
        key: "%" + value + "%",
        value: "%" + value + "%"
      }).then((res) => {
        console.log(res.data);
        setName(res.data);
        // setKey(keyvalue[options.reference]);
      });
    }
  }
  function handleReadEcnReport() {
    ReadRecord({
      _keyword_: "Ecn_report"
    }).then((res) => {
      // console.log(res.data);
      setecn_report(res.data);
    });
  }
  useEffect(() => {
    handleReadEcnReport();
  }, [])
  return (
    <div>
      <Popup
        trigger={ButtonPopup2}
        setTrigger={setButtonPopup2}
        viewPagination={viewPagination}
      >
        <br></br>
        {Approver === localStorage.getItem("empId") ?
        (ecnReport.Approval === "0" ? <div> {back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}<span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#DA251A', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>This Form has been Rejected due to {RejectedReason}</span></div> : ecnReport.Approval === "1" ? <div>{back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}<span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#B9A44C', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>It is Approved Already, Contact Administrator if any change needed </span></div> :
          back && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>
            <div style={{ display: 'flex', width: '25%', gap: '5', justifyContent: 'space-between', alignItems: 'center', marginRight: "10px" }}><button
              className="icon"
              style={{ backgroundColor: "#06802f", marginRight: '10px' }}
              type="button"
              onClick={(e) => {
                approve(ecnReport.Id, "EcnReport", "Ecn_report")
                navigate(-1)
              }}
            >
              Approve
            </button>
              <button
                className="icon"
                style={{ backgroundColor: "#9d080f" }}
                type="button"
                onClick={() => { setcardId(ecnReport.Id) }}
              >
                Reject
              </button>
              {ecnReport.Id === cardId && <div><div style={{ backgroundColor: 'gray', filter: 'blur(8px)', position: 'absolute', opacity: '.8', right: '0%', bottom: '0%', width: '100%', height: '100%' }}></div><div style={{ position: 'absolute', right: '32%', color: 'white', bottom: '35%', padding: '20px', borderRadius: '10px' }}><h3>Remarks</h3><textarea rows={5} cols={50} onChange={e => { setremarks(e.target.value) }}></textarea><button onClick={e => { reject(ecnReport.Id, "EcnReport", remarks, "Ecn_report"); navigate(-1) }}>Submit</button><button onClick={() => { setcardId('') }}>Cancel</button></div></div>}
            </div>
          </div>):
            (ecnReport.Approval === "0" ? <div>{back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}<span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#DA251A', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>This Form has been Rejected due to {RejectedReason}</span></div> : ecnReport.Approval === "1" ? <div>{back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}<span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#B9A44C', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>It is Approved Already, Contact Administrator if any change needed </span></div> : (back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>))
            }
        {/* {back&&<div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <div style={{fontSize:'16px',fontWeight:'bolder',backgroundColor:'#86BBd8', width:'90px',padding:'3px 10px',marginLeft:"20px",borderRadius:'10px',border:'2px solid #005C9F', cursor:'pointer'}} onClick={()=>{navigate(-1)}}><FaBackspace size={20}/> Back</div>
          <div style={{display:'flex',width:'25%',gap:'5',justifyContent:'space-between',alignItems:'center',marginRight:"10px"}}><button
                      className="icon"
                      style={{ backgroundColor: "#06802f" ,marginRight:'10px'}}
                      type="button"
                      onClick={(e) => {
                        approve(ecnReport.Id,"EcnReport","Ecn_report")
                        navigate(-1)
                      }}
                    >
                      Approve
                    </button>
                    <button
                      className="icon"
                      style={{ backgroundColor: "#9d080f" }}
                      type="button"
                      onClick={() => {setcardId(ecnReport.Id)}}
                    >
                      Reject
                    </button>
                    {ecnReport.Id===cardId&&<div><div style={{backgroundColor:'gray',filter:'blur(8px)',position:'absolute',opacity:'.8',right:'0%',bottom:'0%',width:'100%',height:'100%'}}></div><div style={{position:'absolute',right:'32%',color:'white',bottom:'35%',padding:'20px',borderRadius:'10px'}}><h3>Remarks For Record No - {ecnReport.Id}</h3><textarea rows={5} cols={50} onChange={e=>{setremarks(e.target.value)}}></textarea><button onClick={e=>{reject(ecnReport.Id,"EcnReport",remarks,"Ecn_report");navigate(-1)}}>Submit</button><button onClick={()=>{setcardId('')}}>Cancel</button></div></div>}
                    </div>
          </div>} */}
        <form
          id="EcnReport-formContainer-View"
          name="EcnReport-formContainer-View"
          className="form-EcnReport-View"
        >
          <table style={{ textAlign: "center", width: "100%" }}>
            <tbody>
              <tr>
                <th
                  colSpan="3"
                  style={{ textAlign: "center", whiteSpace: "nowrap" }}
                >
                  <font color="#8B0000">View - ECN Report</font>
                </th>
              </tr>
              <tr>
                <td className="topic-head">&nbsp;&nbsp;Response</td>
                <td colSpan={2}>
                  <label>{ecnReport.Respnse}</label>
                </td>
              </tr>
              <tr>
                <td className="topic-head">&nbsp;&nbsp;Customer</td>
                <td colSpan={2}>
                  <label>{ecnReport.Customer}</label>
                </td>
              </tr>
              <tr>
                <td className="topic-head">&nbsp;&nbsp;Model</td>
                <td colSpan={2}>
                  <label>{ecnReport.Model}</label>
                </td>
              </tr>
              <tr>
                <td className="topic-head">&nbsp;&nbsp;Part Name</td>
                <td colSpan={2}>
                  <label>{ecnReport.PartName}</label>
                </td>
              </tr>
              <tr>
                <td className="topic-head">&nbsp;&nbsp;Problem</td>
                <td colSpan={2}>
                  <label>{ecnReport.Problem}</label>
                </td>
              </tr>

              <tr>
                <td className="topic-head">&nbsp;&nbsp;Correction Details</td>
                <td colSpan={2}>
                  <label>{ecnReport.CrtDetails}</label>
                </td>
              </tr>
              <tr>
                <td className="topic-head">&nbsp;&nbsp;Remarks</td>
                <td colSpan={2}>
                  <label>{ecnReport.Remarks}</label>
                </td>
              </tr>
              <tr>
                <td className="topic-head">&nbsp;&nbsp;Stock</td>
                <td colSpan={2}>
                  <label>{ecnReport.Stock}</label>
                </td>
              </tr>

              <tr>
                <th colSpan={3} style={{ textAlign: "center" }}>
                  Part Profile
                </th>
              </tr>
              <tr>
                <th>Before</th>
                <th colSpan={2}>After</th>
              </tr>
              <tr>

                <td>
                  <img
                    src={ecnReport.Before}
                    alt="Selected"
                    style={{
                      display: "block",
                      width: "500px",
                      height: "300px",
                      margin: "auto",
                    }}
                  />
                </td>
                <td colSpan={2}>
                  <img
                    src={ecnReport.After}
                    alt="Selected"
                    style={{
                      display: "block",
                      width: "500px",
                      height: "300px",
                      margin: "auto",
                    }}
                  />
                </td>
              </tr>

              <tr>
                <td className="topic-head">&nbsp;&nbsp;Target Date</td>
                <td colSpan={2}>

                  <label>{formatDt(ecnReport.TargDate)}</label>
                </td>
              </tr>
              <tr>
                <td className="topic-head">
                  &nbsp;&nbsp;Quality HOD
                  <br></br>
                  <br></br>
                  <label>{ecnReport.QualityHead}</label>
                </td>

                <td className="topic-head">
                  &nbsp;&nbsp;Molding HOD
                  <br></br>
                  <br></br>
                  <label>{ecnReport.HoldHead}</label>
                </td>
                <td className="topic-head">
                  &nbsp;&nbsp;Plant HOD
                  <br></br>
                  <br></br>
                  <label>{ecnReport.PlantHead}</label>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </Popup>
      <Popup
        trigger={ButtonPopup}
        setTrigger={setButtonPopup}
        viewPagination={viewPagination}
      >
        <br></br>
        <br></br>

        <form
          id="EcnReport-formContainer-Edit"
          name="EcnReport-formContainer-Edit"
          className="form-EcnReport-Edit"
        >
          <table style={{ textAlign: "center", width: "100%" }}>
            <tbody>
              <tr>
                {/* <th
                  colSpan={2}
                  style={{ textAlign: "center", width: "34%" }}
                >
                  <img src={Unitechlogo} style={{ width: "70px" }}></img>
                </th> */}
                <th
                  colSpan="6"
                  style={{
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    padding: "10px 0px",
                  }}
                >
                  {/* <font style={{ fontSize: "24px" }}>
                    UNITECH PLASTO COMPONENTS (P) LTD
                  </font>
                  <br></br> */}
                  <font color="#8B0000">ECN Report</font>
                </th>
              </tr>

              <tr>
                <td colSpan={2} className="topic-head">
                  Response<span style={{ color: "red" }}>*</span>
                </td>
                <td>
                  <input
                    type="text"
                    value={ecnReport.Respnse}
                    onChange={(e) =>
                      setecnReport({
                        ...ecnReport,
                        Respnse: e.target.value,
                      })
                    }
                  />
                  {ecnReport.Respnse === '' && isCreating && <span className="Req">Required</span>}

                </td>
              </tr>
              <tr>
                <td colSpan={2} className="topic-head">
                  Customer<span style={{ color: "red" }}>*</span>
                </td>
                <td>
                  <DropdownFetcher
                    keyword="customer_details"
                    setValue={setcustomer}
                  />
                  {/* <DropdownFilterSelect
                  value={ecnReport.Customer}
                  onChange={(e) =>
                    setecnReport({
                      ...ecnReport,
                      Customer: e.value,
                    })
                  }
                  options={mcNoOptions}
                  placeholder="Select Customer"
                /> */}
                  <Select
                    value={ecnReport.Customer}
                    onChange={handleCustomer}
                    options={customer.map((i) => ({
                      value: i.Cus_code,
                      label: i.Cus_name,
                    }))}
                    placeholder="Select Customer"
                  />
                  {ecnReport.Customer === '' && isCreating && <span className="Req">Required</span>}
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="topic-head">
                  Model<span style={{ color: "red" }}>*</span>
                </td>
                <td>
                  <input
                    type="text"
                    value={ecnReport.Model}
                    onChange={(e) =>
                      setecnReport({
                        ...ecnReport,
                        Model: e.target.value,
                      })
                    }
                  />
                  {ecnReport.Model === '' && isCreating && <span className="Req">Required</span>}
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="topic-head">
                  Part Name<span style={{ color: "red" }}>*</span>
                </td>
                <td>
                  {/* <DropdownFetcher keyword="mac_part_dtl_with_model_dropdown" setValue={setPart} /> */}
                  <Select
                    value={ecnReport.PartName}
                    onChange={handlepart}
                    options={part.map((i) => ({
                      value: i.mc_part_code,
                      label: i.mc_part_name,
                    }))}
                    onInputChange={(event) => { GetSelectValue(event, "mac_part_dtl_with_model_dropdown", setPart) }}
                    placeholder="Search Part..."
                  />
                  {ecnReport.PartName === '' && isCreating && <span className="Req">Required</span>}
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="topic-head">
                  Problem
                </td>
                <td>
                  <input
                    type="text"
                    value={ecnReport.Problem}
                    onChange={(e) =>
                      setecnReport({
                        ...ecnReport,
                        Problem: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>

              <tr>
                <td colSpan={2} className="topic-head">
                  Correction Details
                </td>
                <td>
                  <input
                    type="text"
                    value={ecnReport.CrtDetails}
                    onChange={(e) =>
                      setecnReport({
                        ...ecnReport,
                        CrtDetails: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="topic-head">
                  Remarks
                </td>
                <td>
                  {/* <input
                  type="text"
                  value={ecnReport.Remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                /> */}
                  <textarea
                    rows={4}
                    cols={50}
                    value={ecnReport.Remarks}
                    onChange={(e) =>
                      setecnReport({
                        ...ecnReport,
                        Remarks: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="topic-head">
                  Stock
                </td>
                <td>
                  <input
                    type="text"
                    value={ecnReport.Stock}
                    onChange={(e) =>
                      setecnReport({
                        ...ecnReport,
                        Stock: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr className="th-head">
                <th colSpan={3} style={{ textAlign: "center" }}>
                  Part Profile
                </th>
              </tr>
              <tr className="th-head">
                <th>Before</th>
                <th colSpan={2}>After</th>
              </tr>
              <tr>
                <td style={{ height: "250px", width: "50%" }}>
                  {ecnReport.Before && (
                    <img
                      src={ecnReport.Before}
                      alt="Selected"
                      style={{
                        display: "block",
                        width: "500px",
                        height: "300px",
                      }}
                    />
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    capture="camera"
                    onChange={(e) => handlePhotoChangeBefore(e)}
                  />
                </td>

                <td colSpan={2} style={{ height: "250px", width: "50%" }}>
                  {ecnReport.After && (
                    <img
                      src={ecnReport.After}
                      alt="Selected"
                      style={{
                        display: "block",
                        width: "500px",
                        height: "300px",
                      }}
                    />
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    capture="camera"
                    onChange={(e) => handlePhotoChangeAfter(e)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td className="topic-head" colSpan={2}>
                  Target Date<span style={{ color: "red" }}>*</span>
                </td>
                <td colSpan={5}>
                  <DatePicker
                    value={ecnReport.TargDate}
                    onChange={handleDateChange}
                  />
                  {ecnReport.TargDate === '' && isCreating && <span className="Req">Required</span>}
                </td>
              </tr>
              <tr>
                <td className="topic-head" colSpan={2}>
                  Quality HOD
                  <br></br>
                  <br></br>
                  <DropdownFetcher
                    keyword="emp_details"
                    setValue={setEmployee}
                  />
                  <Select
                    styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                    value={ecnReport.QualityHead}
                    onChange={handleQualityHead}
                    options={Employee.map((i) => ({
                      value: i.Emp_code,
                      label: i.Emp_name,
                    }))}
                  />
                </td>

                <td className="topic-head" colSpan={2}>
                  Molding HOD
                  <br></br>
                  <br></br>
                  <Select
                    styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                    value={ecnReport.HoldHead}
                    onChange={HandleHoldingHead}
                    options={Employee.map((i) => ({
                      value: i.Emp_code,
                      label: i.Emp_name,
                    }))}
                  />
                </td>
                <td className="topic-head" colSpan={3}>
                  Plant HOD
                  <br></br>
                  <br></br>
                  <DropdownFetcher keyword="emp_details_hod" setValue={setHODEmployee} />

                  <Select
                    styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                    value={ecnReport.PlantHead}
                    onChange={HandlePlantHead}
                    options={HODEmployee.map((i) => ({
                      value: i.Emp_code,
                      label: i.Emp_name,
                    }))}
                  />
                </td>
              </tr>

              <tr>
                <td colSpan="7" align="center" style={{ border: "none" }}>
                  <button type="submit" onClick={(e) => handleUpdate(e)}>
                    Update
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </Popup>

      <div>
        <table
          style={{ textAlign: "center", width: "100%" }}
        /*{ cellPadding="5" }*/
        >
          <thead>
            <tr>
              <th
                id="EcnReport-Title.List"
                name="EcnReport-Title.List"
                colSpan="12"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">List - ECN Report</font>
              </th>
            </tr>
            <tr>
              {/* <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                  Date
                </td>
                <td colSpan="1">
                  <input
                  id="Report4M-filter-PartName"
                  name="Report4M-filter-PartName"
                    type="text"
                    placeholder="Filter by Date"
                    value={filterDate}
                    onChange={handleFilterDateChange}
                  />
                </td> */}
              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                Customer
              </td>
              <td colSpan={2}>
                <input
                  id="EcnReport-filter-Customer"
                  name="EcnReport-filter-Customer"
                  type="text"
                  placeholder="Filter Customer"
                  style={{ fontSize: "70%", width: "100%" }}
                  value={ecnReport.filterCustomer}
                  onChange={handleFilterCustomer}
                />
              </td>
              <td colSpan="8"></td>
            </tr>
            <tr className="table_position">
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Response&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Customer&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Model&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Part Name&nbsp;&nbsp;
              </th>

              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Problem&nbsp;&nbsp;
              </th>

              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Target Date&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Status&nbsp;&nbsp;
              </th>
              <th className="table-list-head"></th>
              {(GetAccess("production", "hod") || GetAccess("ADMIN", "GM"))? <th className="table-list-head"></th> : ""}
              <th className="table-list-head"></th>
            </tr>
          </thead>
          <tbody>
            {/* <HandleRead keyword="Ecn_report" setData={setecn_report} /> */}

            {filteredRecords.length === 0 ? (
              // <tr>
              <td colSpan="10" style={{ textAlign: "center" }}>
                <p style={{ fontWeight: "bold" }}>No records found.</p>
              </td>
            ) : (
              // </tr>
              filteredRecords.map((Report) => (

                <tr className="table_view" key={Report.Id}>
                  {console.log(Report)}
                  <td className="table-cell">&nbsp;&nbsp;{Report.Respnse}</td>
                  <td className="table-cell">&nbsp;&nbsp;{Report.Customer}</td>
                  <td className="table-cell">&nbsp;&nbsp;{Report.Model}</td>
                  <td className="table-cell">&nbsp;&nbsp;{Report.Part_name}</td>

                  <td className="table-cell">&nbsp;&nbsp;{Report.Problem}</td>
                  <td className="table-cell">&nbsp;&nbsp;{Report.Target_date}</td>
                  {/* {console.log("Report.Approval_status",Report.Approval_status)} */}
                  <td
                    style={{

                      color: Report.Approval_status === "0" ? 'red' : Report.Approval_status === "1" ? 'green' : Report.Approval_status === undefined ? '#E59500' : "",

                    }}
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{Report.Approval_status === undefined ? "Waiting for approval..." : Report.Approval_status === "1" ? "Approved" : Report.Approval_status === "0" ? "Rejected" : ""}
                  </td>

                  {/* <td className="table-cell">&nbsp;&nbsp;{Report.Prt_weight}</td> */}
                  {/* <td className="table-cell">&nbsp;&nbsp;{Report.Mat_ld_time}</td> */}
                  {/* <td 
        className="table-cell">&nbsp;&nbsp;{Report.CreatedDate}</td> */}
                  <td>
                    <button
                      style={{ backgroundColor: "#cba423" }}
                      type="button"
                      onClick={() => handleView(Report)}
                    >
                      View&#x1F441;
                    </button>
                  </td>
                  {(GetAccess("production", "hod") || GetAccess("ADMIN", "GM")) ? <td>
                    <button type="button" onClick={() => handleEdit(Report)}>
                      Edit&#x270E;
                    </button>
                  </td> : ''}
                  <td>
                    <button
                      id={`Ecn_report-Delete`}
                      name={`Ecn_report-Delete`}
                      style={{ whiteSpace: "nowrap", backgroundColor: "#DA251A" }}
                      type="button"
                      onClick={() => {
                        const userConfirmed = window.confirm('Are you sure you want to delete?');
                        if (userConfirmed) {
                          UpdateRecord({
                            _keyword_: 'Ecn_report',
                            data: [
                              {
                                Id: Report.Id,
                                ActiveStatus: 'InActive',
                              },
                            ],
                          }).then(() => {
                            handleReadEcnReport();
                          })
                          // window.location.reload();
                        }
                      }}
                    >
                      Delete<MdDelete />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <div className="pagination" id="pagination">
          {ecnReport.isPaginationVisible && filteredData.length > 0 && (
            <Pagination
              currentPage={ecnReport.currentPage}
              npage={npage}
              prePage={prePage}
              nextPage={nextPage}
              changeCpage={changeCpage}
              numbers={numbers}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Report4MView;
