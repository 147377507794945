import React from "react";

const ShiftDropdown =({id,name,value,onChange,className,disabled1})=>{
    
    return(
        <select
        disabled ={disabled1}
        className={className}
                  id={`${id}-Shift`}
                  name={`${name}-Shift`}
                  value={value}
                  onChange={onChange}
                  style={{ textAlign: "center" }}
                >
                  <option
                    id={`${id}-Shift-Select`}
                    name={`${name}-Shift-Select`}
                    value=""
                  >
                    -- Select --
                  </option>
                  <option
                    id={`${id}-ShiftA12`}
                    name={`${name}-ShiftA12`}
                    value="A"
                  >
                    A
                  </option>
                  <option
                    id={`${id}-ShiftB12`}
                    name={`${name}-ShiftB12`}
                    value="B"
                  >
                    B
                  </option>
                </select>
    );
};
export default ShiftDropdown;