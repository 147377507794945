import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Popup from "../../../Components/Popup/Popup";
import { API_URL } from "../../../Utils/constants.js";
import "../../../Styles/Report4M.css";
//import "bootstrap/dist/css/bootstrap.min.css";
import HandleRead from "../../../Lib/HandleRead.js";
import Pagination from "../../../Components/Pagination";
import Unitechlogo from "../../../icons/Unitechlogo.jpeg";
import Select from "react-select";
import DropdownFetcher from "../../../Lib/DropdownFetcher.js";
import UpdateRecord from "../../../Lib/updateRecord";
import { useNavigate, useParams } from "react-router-dom";
import { FaBackspace } from 'react-icons/fa';
import GetRecord from "../../../Lib/GetTableRecord";
import { MdDelete } from "react-icons/md";
import ReadRecord from "../../../Lib/API/ReadRecord.js";
import LoadingPage from "../../../Components/Handler/LoadingPage.js";


function Report4MView() {
  const scrollTop = useRef(null);
  // const [Moving,setMoving] = useState("");
  const [loading, setLoading] = useState(false);
  const [Id, setId] = useState("");
  const [RmNme, setRmNme] = useState("");
  const [CyleCde, setCyleCde] = useState("");
  const [MldNme, setMldNme] = useState("");
  const [NoOfCvty, setNoOfCvty] = useState("");
  const [RmiCde, setRmiCde] = useState("");
  const [PrtWeght, setPrtWeght] = useState("");
  const [Plan, setPlan] = useState("");
  const [Receipt1, setReceipt1] = useState("");
  const [Receipt2, setReceipt2] = useState("");
  const [Balance1, setBalance1] = useState("");
  const [Balance2, setBalance2] = useState("");
  const [From, setFrom] = useState("");
  const [FgStock, setFgStock] = useState("");
  const [PartNo, setPartNo] = useState("");
  const [NoOfCvtyRun, setNoOfCvtyRun] = useState("");
  const [ItmCde, setItmCde] = useState("");
  const [Tonnage, setTonnage] = useState("");
  const [Material, setMaterial] = useState("");
  const [FgwStock, setFgwStock] = useState("");
  const [SmiStock1, setSmiStock1] = useState("");
  const [SmiStock2, setSmiStock2] = useState("");
  const [TtlStock1, setTtlStock1] = useState("");
  const [TtlStock2, setTtlStock2] = useState("");
  const [NodysCov1, setNodysCov1] = useState("");
  const [NodysCov2, setNodysCov2] = useState("");
  const [To, setTo] = useState("");
  const [keyValueObject1, setkeyValueObject1] = useState("");

  const [MovingProcess, setMovingProcess] = useState("");
  console.log("Moving", MovingProcess);
  // const array = ["Man", "Material", "Method", "Mould"];
  function handleKeyvalue(key) { 
  const keyValueObject = [];
  for (let i = 0; i < key.length; i++) {
    keyValueObject.push({
      id: key[i],
      value: key[i],
      label: key[i],
    });
  }
  setkeyValueObject1(keyValueObject);
  console.log("keyValueObject", keyValueObject);

}

  const [changeprocess, setchangeprocess] = useState("");
  const [MovingProcessRemarks, setMovingProcessRemarks] = useState("");
  const [PackingStd, setPackingStd] = useState("");
  const [ProductionHead, setProductionHead] = useState("");
  const [PreparedByName, setPreparedByName] = useState("");
  const [VendarName, setVendarName] = useState("");
  const [PurchaseName, setPurchaseName] = useState("");
  const [CustomerQualityName, setCustomerQualityName] = useState("");
  const [ProductionQualityName, setProductionQualityName] = useState("");
  const [ToolRoomSign, setToolRoomSign] = useState("");
  const [StoreMaterial, setStoreMaterial] = useState("");
  const [DirectorSign, setDirectorSign] = useState("");
  const [SqaSign, setSqaSign] = useState("");

  const [EmployeeDropdown, setEmployeeDropdown] = useState([]);
  const [HODEmployeeDropdown, setHODEmployeeDropdown] = useState([]);
  const [partDropdown, setPartDropdown] = useState([]);
  const [dropdown, setDropdown] = useState([]);
  const [Rmdropdown, setRmDropdown] = useState([]);
  const [vendorDropdown, setVendorDropdown] = useState([]);
  const [fgDropdown, setFgDropdown] = useState([]);
  const [isCreating, setIsCreating] = useState(false);

  var [Report, setReport] = useState([]);
  const [ButtonPopup2, setButtonPopup2] = useState(false);
  const [ButtonPopup, setButtonPopup] = useState(false);

  const [back, setback] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [ENC, setENC] = useState("");
  const [remarks, setremarks] = useState('');
  const [cardId, setcardId] = useState('');

  const [isPaginationVisible, setPaginationVisible] = useState(true);
  const idView = "Report4M-View";
  const nameView = "Report4M-View";
  const nameEdit = "Report4M-Edit";
  const idEdit = "Report4M-Edit";
  const nameList = "Report4M-List";
  const idList = "Report4M-List";

  const handleView = (e) => {
  
    setPaginationVisible(false);
    setButtonPopup2(true);

    setRmNme(e.Rm_name);
    setCyleCde(e.Cyle_cde);
    setMldNme(e.Mld_name);
    setNoOfCvty(e.No_of_cvty);
    setRmiCde(e.Rmi_cde);
    setPrtWeght(e.Prt_weight);
    setPlan(e.Plan);
    setReceipt1(e.Receipt_month_plan);
    setReceipt2(e.Receipt_material_plan);
    setBalance1(e.Balance_month_plan);
    setBalance2(e.Balance_material_plan);
    setFrom(e.From_1);
    setFgStock(e.Fg_stock);
    setPartNo(e.Part_no);
    setNoOfCvtyRun(e.No_of_cvty_run);
    setItmCde(e.Itm_cde);
    setTonnage(e.Tonnage);
    setMaterial(e.Material);
    setFgwStock(e.Fgw_stock);
    setSmiStock1(e.Smi_stock_month_plan);
    setSmiStock2(e.Smi_stock_material_plan);
    setTtlStock1(e.Ttl_stock_month_plan);
    setTtlStock2(e.Ttl_stock_material_plan);
    setNodysCov1(e.No_dys_cov_month_plan);
    setNodysCov2(e.No_dys_cov_material_plan);
    setTo(e.To_2);

    setMovingProcess(e.Moving_process);
    setMovingProcessRemarks(e.Moving_process_remarks);
    setPackingStd(e.Packing_std);
    setProductionHead(e.ProductionHead !== undefined && e.Production_head !== undefined ? `${e.Production_head}-${e.ProductionHead}` : "");
    setPreparedByName(e.PreparedByName !== undefined && e.Prepared_by_name !== undefined ? `${e.Prepared_by_name}-${e.PreparedByName}` : "");
    setVendarName(e.Vendar_name !== undefined && e.VendorName !== undefined ? `${e.Vendar_name}-${e.VendorName}` : "");
    setPurchaseName(e.PurchaseName !== undefined && e.Purchase_name !== undefined ? `${e.Purchase_name}-${e.PurchaseName}` : "");
    setCustomerQualityName(e.CustomerQualityName !== undefined && e.Customer_quality_name !== undefined ? `${e.Customer_quality_name}-${e.CustomerQualityName}` : "");
    setProductionQualityName(e.ProductionQualityName !== undefined && e.Production_quality_name !== undefined ? `${e.Production_quality_name}-${e.ProductionQualityName}` : "");
    setToolRoomSign(e.ToolRoomSign !== undefined && e.Tool_room_sign !== undefined ? `${e.Tool_room_sign}-${e.ToolRoomSign}` : "");
    setStoreMaterial(
      e.Store_material !== undefined && e.StoreMaterial !== undefined
        ? `${e.Store_material}-${e.StoreMaterial}`
        : ''
    );
    setDirectorSign(e.DirectorSign !== undefined && e.Director_sign !== undefined ? `${e.Director_sign}-${e.DirectorSign}` : "");
    setSqaSign(e.SqaSign !== undefined && e.Sqa_sign !== undefined ? `${e.Sqa_sign}-${e.SqaSign}` : "");
  };

  const viewPagination = () => {
    setPaginationVisible(true);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 20;

  const [filterDate, setFilterDate] = useState("");
  const [filterMcno, setFilterMcno] = useState("");
  const filterRecords = () => {
    const filteredData = Report.filter((record) => {
      // const dateMatch = record.TrackDate && record.TrackDate.includes(filterDate);
      const mcnoMatch =
        record.Moving_process &&
        record.Moving_process.toLowerCase().includes(filterMcno.toLowerCase());
      return mcnoMatch;
    });

    return filteredData;
  };

  const filteredData = filterRecords();
  const npage = Math.ceil(filteredData.length / recordPerPage);
  const numbers = [...Array(npage).keys()].map((num) => num + 1);

  function handleFilterDateChange(event) {
    setFilterDate(event.target.value);
    setCurrentPage(1);
  }

  function handleFilterMcnoChange(event) {
    setFilterMcno(event.target.value);
    setCurrentPage(1);
  }

  const firstIndex = (currentPage - 1) * recordPerPage;
  const lastIndex = firstIndex + recordPerPage;
  const filteredRecords = filteredData.slice(firstIndex, lastIndex);
  function prePage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function nextPage() {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function changeCpage(Id) {
    setCurrentPage(Id);
  }


  const handleEdit = (e) => {
    console.log("edit",e)
    setPaginationVisible(false);
    setButtonPopup(true);
    setId(e.Id);
    setRmNme( e.Rm_name);
    setCyleCde(e.Cyle_cde);
    setMldNme(e.Mld_name);
    setNoOfCvty(e.No_of_cvty);
    setRmiCde({value: e.Rmi_cde,label: e.Rmi_cde});
    setPrtWeght(e.Prt_weight);
    setPlan(e.Plan);
    setReceipt1(e.Receipt_month_plan);
    setReceipt2(e.Receipt_material_plan);
    setBalance1(e.Balance_month_plan);
    setBalance2(e.Balance_material_plan);
    setFrom(e.From_1);
    setFgStock(e.Fg_stock);
    setPartNo({ value: e.Part_no, label: e.Part_no });
    setNoOfCvtyRun(e.No_of_cvty_run);
    setItmCde({ value: e.Itm_cde, label: e.Itm_cde });
    setTonnage(e.Tonnage);
    setMaterial(e.Material);
    setFgwStock(e.Fgw_stock);
    setSmiStock1(e.Smi_stock_month_plan);
    setSmiStock2(e.Smi_stock_material_plan);
    setTtlStock1(e.Ttl_stock_month_plan);
    setTtlStock2(e.Ttl_stock_material_plan);
    setNodysCov1(e.No_dys_cov_month_plan);
    setNodysCov2(e.No_dys_cov_material_plan);
    setTo(e.To_2);

    setMovingProcess(e.Moving_process.split(","));
    setMovingProcessRemarks(e.Moving_process_remarks);
    setPackingStd(e.Packing_std);
    setProductionHead({ value: e.Production_head, label: e.ProductionHead });
    setPreparedByName({ value: e.Prepared_by_name, label: e.PreparedByName });
    setVendarName({ value: e.Vendar_name, label: e.VendorName });
    setPurchaseName({ value: e.Purchase_name, label: e.PurchaseName });
    setCustomerQualityName({
      value: e.Customer_quality_name,
      label: e.CustomerQualityName,
    });
    setProductionQualityName({
      value: e.Production_quality_name,
      label: e.ProductionQualityName,
    });
    setToolRoomSign({ value: e.Tool_room_sign, label: e.ToolRoomSign });
    setStoreMaterial({ value: e.Store_material, label: e.StoreMaterial });
    setDirectorSign({ value: e.Director_sign, label: e.DirectorSign });
    setSqaSign({ value: e.Sqa_sign, label: e.SqaSign });
    handleKeyvalue(e.Moving_process.split(","));
    // GetMovingProcess(MovingProcess);
  };
  // function GetMovingProcess(MovingProcess){
  //   setMoving(MovingProcess.split(","));
  // }
  function handleReadReport4M() {
    setLoading(true);
    ReadRecord({
      _keyword_: "Report_4M"
    }).then((res) => {
      console.log(res.data);
        console.log("View",res.data)
      setReport(res.data);
      setLoading(false)
    });
  }
  useEffect(() => {
    handleReadReport4M();
  }, [])

 
  useEffect(() => {
    console.log(id);
    if (id !== undefined) {
      GetRecord(
        {
          _keyword_: "Get_4MReport_by_id",
          id: id,
        },
        setENC
      ).then(async (res) => {
        console.log("res - ", res);
        handleView(res.data[0])
        setButtonPopup2(true)
        setback(true)
        await timeout(1000);

        const popupDiv = document.querySelector(".popup");
        if (popupDiv) {
          const elementInsidePopup = popupDiv.querySelector("#close-btn");
          elementInsidePopup.style.display = "none";
        }
      })
    }
  }, [id])
  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }

  const handleUpdate = (e) => {
    
    // console.log("changeprocess", changeprocess);
    const MovingProcesstostring = changeprocess.toString();
    console.log("MovingProcesstostring",MovingProcesstostring);
    
    e.preventDefault();
    if (!MovingProcess) {
      setIsCreating(true);
      alert("Please fill the mandatory fields");
      if (scrollTop.current) {
        scrollTop.current.scrollIntoView({ behavior: "smooth" });
      }
      return;
    }
    const data = {
      data: [
        {
          Id: Id,
          Mld_name: MldNme,
          No_of_cvty: NoOfCvty,
          Part_no: PartNo.value,
          No_of_cvty_run: NoOfCvtyRun,
          Itm_cde: ItmCde.value,
          Tonnage: Tonnage,
          Rmi_cde: RmiCde,
          Cyle_cde: CyleCde,
          Prt_weight: PrtWeght,
          Plan: Plan,
          Material: Material,
          Receipt_month_plan: Receipt1,
          Receipt_material_plan: Receipt2,
          Rm_name: RmNme,
          Balance_month_plan: Balance1,
          Balance_material_plan: Balance2,
          From_1: From,
          To_2: To,
          Fgw_stock: FgwStock,
          Smi_stock_month_plan: SmiStock1,
          No_dys_cov_month_plan: NodysCov1,
          No_dys_cov_material_plan: NodysCov2,
          Fg_stock: FgStock,
          Smi_stock_material_plan: SmiStock2,
          Ttl_stock_month_plan: TtlStock1,
          Ttl_stock_material_plan: TtlStock2,
          Moving_process: MovingProcesstostring||MovingProcess,
          Moving_process_remarks: MovingProcessRemarks,
          Packing_std: PackingStd,
          Production_head: ProductionHead.value,
          Prepared_by_name: PreparedByName.value,
          Vendar_name: VendarName.value,
          Customer_quality_name: CustomerQualityName.value,
          Production_quality_name: ProductionQualityName.value,
          Tool_room_sign: ToolRoomSign.value,
          Store_material: StoreMaterial.value,
          Director_sign: DirectorSign.value,
          Sqa_sign: SqaSign.value,
          Purchase_name: PurchaseName.value,
        },
      ],
    };
    data["_keyword_"] = "Report_4M";
    data["secretkey"] = "2bf52be7-9f68-4d52-9523-53f7f267153b";
    axios
      .post(
        `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
        data
      )
      .then((response) => {
        // setReport([...Report, response.data]);
        alert(`Updated Successfully!`);
        setButtonPopup(false);
        handleReadReport4M();
        setPaginationVisible(true);
        // window.location.reload();

        // if (scrollTop.current) {
        //   scrollTop.current.scrollIntoView({ behavior: "smooth" });
        // }
      }
      )
      .catch((error) => {
        console.log(error);
        alert(`Updation Failed!`);
        window.location.reload();

      });
  };
  const options = [
    { id: "select", value: "", label: "All" },
    { id: "Mould", value: "Mould", label: "Mould" },
    { id: "Man", value: "Man", label: "Man" },
    { id: "Material", value: "Material", label: "Material" },
    { id: "Method", value: "Method", label: "Method" },
  ];
  const options1 = [
    { id: "Mould", value: "Mould", label: "Mould" },
    { id: "Man", value: "Man", label: "Man" },
    { id: "Material", value: "Material", label: "Material" },
    { id: "Method", value: "Method", label: "Method" },
  ];

  const handleProductionHead = (selectedOption) => {
    const selected = HODEmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setProductionHead({ value: selected.Emp_code, label: selected.Emp_name });
    }
  };
  const handlePreparedByName = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setPreparedByName({ value: selected.Emp_code, label: selected.Emp_name });
    }
  };
  const handleVendarName = (selectedOption) => {
    const selected = vendorDropdown.find(
      (option) => option.Sup_code === selectedOption.value
    );

    if (selected) {
      setVendarName({ value: selected.Sup_code, label: selected.Sup_name });
    }
  };
  const handlePurchaseName = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setPurchaseName({ value: selected.Emp_code, label: selected.Emp_name });
    }
  };
  const handleCustomerQualityName = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setCustomerQualityName({
        value: selected.Emp_code,
        label: selected.Emp_name,
      });
    }
  };
  const handleProductionQualityName = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setProductionQualityName({
        value: selected.Emp_code,
        label: selected.Emp_name,
      });
    }
  };
  const handleToolRoomSign = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setToolRoomSign({ value: selected.Emp_code, label: selected.Emp_name });
    }
  };
  const handleMldNme = (selectedOption) => {
    const selected = partDropdown.find(
      (option) => option.mc_part_code === selectedOption.value
    );

    if (selected) {
      setPartNo({ value: selected.mc_part_code, label: selected.mc_part_code });
      setMldNme(selected.mc_part_name);
    }
  };
  const handleRmiCde = (selectedOption) => {
    const selected = Rmdropdown.find(
      (option) => option.Rm_code === selectedOption.value
    );

    if (selected) {
      setRmiCde({ value: selected.Rm_code, label: selected.Rm_code });
      setRmNme(selected.Rm_description);
    }
  };
  const handleStoreMaterial = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setStoreMaterial({ value: selected.Emp_code, label: selected.Emp_name });
    }
  };
  const handleItemCode = (selectedOption) => {
    const selected = fgDropdown.find(
      (option) => option.Fg_code === selectedOption.value
    );

    if (selected) {
      setItmCde({ value: selected.Fg_code, label: selected.Fg_code });
    }
  };
  const handleDirectorSign = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setDirectorSign({ value: selected.Emp_code, label: selected.Emp_name });
    }
  };
  const handleSqaSign = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setSqaSign({ value: selected.Emp_code, label: selected.Emp_name });
    }
  };
  const fgOptions = fgDropdown.map((i) => ({
    value: i.Fg_code,
    label: i.Fg_code,
  }));
  const vendorOptions = vendorDropdown.map((i) => ({
    value: i.Sup_code,
    label: i.Sup_name,
  }));
  const partNoOptions = partDropdown.map((i) => ({
    value: i.mc_part_code,
    label: i.mc_part_name,
  }));
  const mcNoOptions = dropdown.map((i) => ({
    value: i.mc_code,
    label: i.mc_name,
  }));
  const empMoldSetterOptions = EmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const empHODSetterOptions = HODEmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const rmOptions = Rmdropdown.map((i) => ({
    value: i.Rm_code,
    label: i.Rm_code,
  }));
  function GetSelectValue(value, keyword, setName) {

    if (value.length > 0) {
      ReadRecord({
        _keyword_: keyword,
        key: "%" + value + "%",
        value: "%" + value + "%"
      }).then((res) => {
        console.log(res.data);
        setName(res.data);
        // setKey(keyvalue[options.reference]);
      });
    }
  }

 
 
  return (
    <div>
      <Popup
        trigger={ButtonPopup2}
        setTrigger={setButtonPopup2}
        viewPagination={viewPagination}
      >
        <br></br>
        {back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}
        <br></br>
        <form
          id="4MReport-formContainer-View"
          name="4MReport-formContainer-View"
          className="form-4MReport-View"
        >

          <table style={{ textAlign: "center", width: "100%" }}>
            <tbody>
              <tr>
                <th style={{ textAlign: "center", width: "15%" }}>
                  <img src={Unitechlogo} style={{ width: "70px" }}></img>
                </th>
                <th
                  id={`${idView}-Title`}
                  name={`${nameView}-Title`}
                  colSpan="7"
                  style={{
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    padding: "10px 0px",
                  }}
                >
                  <font style={{ fontSize: "24px" }}>
                  KASH Tech LLC
                  </font>
                  <br></br>
                  <font color="#8B0000">View - 4M Report</font>
                </th>
              </tr>
              <tr>
                <td>
                  <p >&nbsp;&nbsp;Moving Process:</p>
                </td>
                <td colSpan={3}>
                  <label
                    id={`${idView}-MovingProcess`}
                    name={`${nameView}-MovingProcess`}
                  >
                    {MovingProcess}
                  </label>
                </td>
                <td >&nbsp;&nbsp;Remarks</td>
                <td colSpan={3}>
                  <label
                    id={`${idView}-MovingProcessRemarks`}
                    name={`${nameView}-MovingProcessRemarks`}
                  >
                    {MovingProcessRemarks}
                  </label>
                </td>
              </tr>
              <tr>
                <td>&nbsp;&nbsp;Mould Name</td>
                <td colSpan={3}>
                  <label
                    id={`${idView}-MouldName`}
                    name={`${nameView}-MouldName`}
                  >
                    {MldNme}
                  </label>
                </td>

                <td>&nbsp;&nbsp;No Of Cavity</td>
                <td colSpan={2}>
                  <label
                    id={`${idView}-NoOfCvty`}
                    name={`${nameView}-NoOfCvty`}
                  >
                    {NoOfCvty}
                  </label>
                </td>
              </tr>
              <tr>
                <td>&nbsp;&nbsp;Part No</td>
                <td colSpan={3}>
                  <label id={`${idView}-PartNo`} name={`${nameView}-PartNo`}>
                    {PartNo}
                  </label>
                </td>

                <td>&nbsp;&nbsp;No Of Cavity Run</td>
                <td colSpan={3}>
                  <label
                    id={`${idView}-NoOfCvtyRun`}
                    name={`${nameView}-NoOfCvtyRun`}
                  >
                    {NoOfCvtyRun}
                  </label>
                </td>
              </tr>
              <tr>
                <td>&nbsp;&nbsp;Item Code</td>
                <td colSpan={3}>
                  <label id={`${idView}-ItmCde`} name={`${nameView}-ItmCde`}>
                    {ItmCde}
                  </label>
                </td>

                <td>&nbsp;&nbsp;Tonnage</td>
                <td colSpan={2}>
                  <label id={`${idView}-Tonnage`} name={`${nameView}-Tonnage`}>
                    {Tonnage}
                  </label>
                </td>
              </tr>
              <tr>
                <td>&nbsp;&nbsp;Rmi Name</td>
                <td colSpan={3}>
                  <label id={`${idView}-RmiCde`} name={`${nameView}-RmiCde`}>
                    {RmNme}
                  </label>
                </td>

                <td>&nbsp;&nbsp;Cycle Code</td>
                <td colSpan={2}>
                  <label id={`${idView}-CyleCde`} name={`${nameView}-CyleCde`}>
                    {CyleCde}
                  </label>
                </td>
              </tr>
              <tr>
                <td>&nbsp;&nbsp;Rm Code</td>
                <td colSpan={3}>
                  <label id={`${idView}-RmNme`} name={`${nameView}-RmNme`}>
                  {RmiCde}
                  </label>
                </td>

                <td>&nbsp;&nbsp;Part Weight(gm)</td>
                <td colSpan={2}>
                  <label
                    id={`${idView}-PrtWeght`}
                    name={`${nameView}-PrtWeght`}
                  >
                    {PrtWeght}
                  </label>
                </td>
              </tr>
              <tr>
                <th colSpan={4} style={{ textAlign: "center" }}>
                  MONTH PLAN
                </th>
                <th colSpan={3} style={{ textAlign: "center" }}>
                  MATERIAL PLAN
                </th>
              </tr>
              <tr>
                <td>&nbsp;&nbsp;Plan</td>
                <td colSpan={3}>
                  <label id={`${idView}-Plan`} name={`${nameView}-plan`}>
                    {Plan}
                  </label>
                </td>
                <td>&nbsp;&nbsp;Material</td>
                <td colSpan={2}>
                  <label
                    id={`${idView}-Material`}
                    name={`${nameView}-Material`}
                  >
                    {Material}
                  </label>
                </td>
              </tr>
              <tr>
                <td>&nbsp;&nbsp;Receipt</td>
                <td colSpan={3}>
                  <label
                    id={`${idView}-Receipt1`}
                    name={`${nameView}-Receipt1`}
                  >
                    {Receipt1}
                  </label>
                </td>
                <td>&nbsp;&nbsp;Receipt</td>
                <td colSpan={2}>
                  <label
                    id={`${idView}-Receipt2`}
                    name={`${nameView}-Receipt2`}
                  >
                    {Receipt2}
                  </label>
                </td>
              </tr>
              <tr>
                <td>&nbsp;&nbsp;Balance</td>
                <td colSpan={3}>
                  <label
                    id={`${idView}-Balance1`}
                    name={`${nameView}-Balance1`}
                  >
                    {Balance1}
                  </label>
                </td>
                <td>&nbsp;&nbsp;Balance</td>
                <td colSpan={2}>
                  <label
                    id={`${idView}-Balance2`}
                    name={`${nameView}-Balance2`}
                  >
                    {Balance2}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <p >&nbsp;&nbsp;Packing Std</p>
                </td>
                <td colSpan={3}>
                  <label
                    id={`${idView}-PackingStd`}
                    name={`${nameView}-PackingStd`}
                  >
                    {PackingStd}
                  </label>
                </td>
                <td colSpan={5}></td>
              </tr>
              <tr>
                <th colSpan={4} style={{ textAlign: "center" }}>
                  FROM
                </th>
                <th colSpan={3} style={{ textAlign: "center" }}>
                  TO
                </th>
              </tr>
              <tr>
                <td colSpan={4} style={{ height: "24px" }}>
                  <label id={`${idView}-From`} name={`${nameView}-From`}>
                    {From}
                  </label>
                </td>
                <td colSpan={3}>
                  <label id={`${idView}-To`} name={`${nameView}-To`}>
                    {To}
                  </label>
                </td>
              </tr>
              <tr>
                <td colSpan={7}>
                  <sapn>
                    Note:
                    <ol>
                      <li>Kindly Attached Mold Inspection Report</li>
                      <li>kindly Attached Sample Part</li>
                      <li>Kindly Attached Proper Packing Standard Report</li>
                      <li>Kindly Attached Quality Plan</li>
                      <li>If You Ignored, We can't Accept The Report</li>
                    </ol>
                  </sapn>
                </td>
              </tr>
              <tr>
                <th colSpan={2} style={{ textAlign: "center" }}>
                  INHOUSE STOCK DETAILS
                </th>
                <td rowSpan={5} colSpan={3}>
                  <p className="Employee_name">Production Head</p>
                  <br></br>
                  <br></br>
                  <label>{ProductionHead}</label>
                </td>
                <th colSpan={2} style={{ textAlign: "center" }}>
                  SUPPLIER STOCK DETAILS
                </th>
              </tr>
              <tr>
                <td>&nbsp;&nbsp;Fg Stock</td>
                <td>
                  <label id={`${idView}-FgStock`} name={`${nameView}-FgStock`}>
                    {FgStock}
                  </label>
                </td>
                <td>&nbsp;&nbsp;Fgw Stock</td>
                <td colSpan={2}>
                  <label
                    id={`${idView}-FgwStock`}
                    name={`${nameView}-FgwStock`}
                  >
                    {FgwStock}
                  </label>
                </td>
              </tr>
              <tr>
                <td>&nbsp;&nbsp;Semi Stock</td>
                <td>
                  <label
                    id={`${idView}-SmiStock1`}
                    name={`${nameView}-SmiStock1`}
                  >
                    {SmiStock1}
                  </label>
                </td>
                <td>&nbsp;&nbsp;Semi Stock</td>
                <td colSpan={2}>
                  <label
                    id={`${idView}-SmiStock2`}
                    name={`${nameView}-SmiStock2`}
                  >
                    {SmiStock2}
                  </label>
                </td>
              </tr>
              <tr>
                <td>&nbsp;&nbsp;total Stock</td>
                <td>
                  <label
                    id={`${idView}-TtlStock1`}
                    name={`${nameView}-TtlStock1`}
                  >
                    {TtlStock1}
                  </label>
                </td>
                <td>&nbsp;&nbsp;Total Stock</td>
                <td colSpan={2}>
                  <label
                    id={`${idView}-TtlStock2`}
                    name={`${nameView}-TtlStock2`}
                  >
                    {TtlStock2}
                  </label>
                </td>
              </tr>
              <tr>
                <td>&nbsp;&nbsp;No Days Coverage</td>
                <td>
                  <label
                    id={`${idView}-NodysCov1`}
                    name={`${nameView}-NodysCov1`}
                  >
                    {NodysCov1}
                  </label>
                </td>
                <td>&nbsp;&nbsp;No Days Coverage</td>
                <td colSpan={2}>
                  <label
                    id={`${idView}-NodysCov2`}
                    name={`${nameView}-NodysCov2`}
                  >
                    {NodysCov2}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="Employee_name">Prepared By</p>
                  <br></br>
                  <br></br>
                  <label
                    id={`${idView}-PreparedByName`}
                    name={`${nameView}-PreparedByName`}
                  >
                    {PreparedByName}
                  </label>
                </td>
                <td>
                  <p className="Employee_name">Vendor Head</p>
                  <br></br>
                  <br></br>
                  <label
                    id={`${idView}-VendarName`}
                    name={`${nameView}-VendarName`}
                  >
                    {VendarName}
                  </label>
                </td>
                <td colSpan={2}>
                  <p className="Employee_name">Purchase Head</p>
                  <br></br>
                  <br></br>
                  <label
                    id={`${idView}-PurchaseName`}
                    name={`${nameView}-PurchaseName`}
                  >
                    {PurchaseName}
                  </label>
                </td>
                <td>
                  <p className="Employee_name">Customer Quality</p>
                  <br></br>
                  <br></br>
                  <label
                    id={`${idView}-CustomerQualityName`}
                    name={`${nameView}-CustomerQualityName`}
                  >
                    {CustomerQualityName}
                  </label>
                </td>
                <td>
                  <p className="Employee_name">Production Quality</p>
                  <br></br>
                  <br></br>
                  <label
                    id={`${idView}-ProductionQualityName`}
                    name={`${nameView}-ProductionQualityName`}
                  >
                    {ProductionQualityName}
                  </label>
                </td>
                <td>
                  <p className="Employee_name">Tool Room Sign</p>
                  <br></br>
                  <br></br>
                  <label
                    id={`${idView}-ToolRoomSign`}
                    name={`${nameView}-ToolRoomSign`}
                  >
                    {ToolRoomSign}
                  </label>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <p className="Employee_name">Store/Material</p>
                  <br></br>
                  <br></br>
                  <label
                    id={`${idView}-StoreMaterial`}
                    name={`${nameView}-StoreMaterial`}
                  >
                    {StoreMaterial}
                  </label>
                </td>
                <td colSpan={3}>
                  <p className="Employee_name">Director Sign</p>
                  <br></br>
                  <br></br>
                  <label
                    id={`${idView}-DirectorSign`}
                    name={`${nameView}-DirectorSign`}
                  >
                    {DirectorSign}
                  </label>
                </td>
                <td colSpan={2}>
                  <p className="Employee_name">SQA Sign</p>
                  <br></br>
                  <br></br>
                  <label id={`${idView}-SqaSign`} name={`${nameView}-SqaSign`}>
                    {SqaSign}
                  </label>
                </td>
              </tr>
            </tbody>
          </table>

        </form>
      </Popup>
      <Popup
        trigger={ButtonPopup}
        setTrigger={setButtonPopup}
        viewPagination={viewPagination}
      >
        <br></br>
        <br></br>

        <form
          id="4MReport-formContainer-Edit"
          name="4MReport-formContainer-Edit"
          className="form-4MReport-Edit"
        >
          <div ref={scrollTop}>
            <table style={{ textAlign: "center", width: "100%" }}>
              <tbody>
                <tr>
                  <th style={{ textAlign: "center", width: "15%" }}>
                    <img src={Unitechlogo} style={{ width: "70px" }}></img>
                  </th>
                  <th
                    id={`${idEdit}-Title`}
                    name={`${nameEdit}-Title`}
                    colSpan="7"
                    style={{
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      padding: "10px 0px",
                    }}
                  >
                    <font style={{ fontSize: "24px" }}>
                    KASH Tech LLC
                    </font>
                    <br></br>
                    <font color="#8B0000">4M Report - Edit</font>
                  </th>
                </tr>
                <tr>
                  <td>
                    <p className="topic-head">Moving Process:<span style={{ color: "red" }}>*</span></p>
                    {MovingProcess === '' && isCreating && <span className="Req">Required *</span>}

                  </td>
                  <td colSpan={3}>
                    {/* <select
                    id="MovingProcess.Edit"
                    name="MovingProcess.Edit"
                    value={MovingProcess}
                    onChange={(e) => setMovingProcess(e.target.value)}
                    style={{ width: "95%", textAlign: "center" }}
                  >
                    {options.map((option) => (
                      <option key={option.id} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select> */}
                    <Select
                      id="MovingProcess.Create"
                      name="MovingProcess.Create"
                      value={keyValueObject1} 
                      onChange={(e) => {
                        console.log("Event:", e);
                        // setMovingProcess(e);
                        setkeyValueObject1(e);
                        setchangeprocess(() => {
                          let data = [];
                          e.map((item) => {
                            data.push(item.id);
                          });
                          return data;
                        });
                      }}
                      style={{ width: "95%", textAlign: "center" }}
                      isMulti
                      options={options1}
                    />

                  </td>
                  <td className="topic-head">Remarks</td>
                  <td colSpan={3}>
                    <textarea
                      rows={3}
                      cols={2}
                      value={MovingProcessRemarks}
                      onChange={(e) => setMovingProcessRemarks(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head">&nbsp;&nbsp;Mould Name</td>
                  <td colSpan={3}>
                    <input
                      id={`${idEdit}-MouldName`}
                      name={`${nameEdit}-MouldName`}
                      type="text"
                      value={MldNme}
                      onChange={(e) => setMldNme(e.target.value)}
                      disabled
                    />
                    {/* <input
                id={`${idEdit}-MouldName`}
                name={`${nameEdit}-MouldName`}
                  type="text"
                  value={MldNme}
                  onChange={(e) => setMldNme(e.target.value)}
                  
                /> */}
                  </td>

                  <td className="topic-head">&nbsp;&nbsp;No Of Cavity</td>
                  <td colSpan={2}>
                    <input
                      id={`${idEdit}-NoOfCvty`}
                      name={`${nameEdit}-NoOfCvty`}
                      type="number"
                      value={NoOfCvty}
                      onChange={(e) => setNoOfCvty(e.target.value)}
                    />{" "}
                  </td>
                </tr>
                <tr>
                  <td className="topic-head">
                    &nbsp;&nbsp;Part No
                  </td>
                  <td colSpan={3}>
                    {/* <DropdownFetcher
                    keyword="mac_part_dtl_dropdown"
                    setValue={setPartDropdown}
                  /> */}

                    <Select
                      id={`${idEdit}-PartNo`}
                      name={`${nameEdit}-PartNo`}
                      value={PartNo}
                      onChange={handleMldNme}
                      onInputChange={(event) => { GetSelectValue(event, "mac_part_dtl_dropdown", setPartDropdown) }}
                      options={partNoOptions}
                      placeholder="Search Part No..."
                    />
                  </td>

                  <td className="topic-head">&nbsp;&nbsp;No Of Cavity Run</td>
                  <td colSpan={3}>
                    <input
                      id={`${idEdit}-NoOfCvtyRun`}
                      name={`${nameEdit}-NoOfCvtyRun`}
                      type="number"
                      value={NoOfCvtyRun}
                      onChange={(e) => setNoOfCvtyRun(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head">
                    &nbsp;&nbsp;Item Code
                  </td>

                  <td colSpan={3}>
                    {/* <DropdownFetcher
                    keyword="fg_details_only_code_dropdown"
                    setValue={setFgDropdown}
                  /> */}
                    <Select
                      id={`${idEdit}-ItmCde`}
                      name={`${nameEdit}-ItmCde`}
                      value={ItmCde}
                      onChange={handleItemCode}
                      onInputChange={(event) => { GetSelectValue(event, "fg_details_only_code_dropdown", setFgDropdown) }}
                      options={fgOptions}
                      placeholder="Search Item Code..."
                    />
                  </td>

                  <td className="topic-head">&nbsp;&nbsp;Tonnage</td>
                  <td colSpan={2}>
                    <input
                      id={`${idEdit}-Tonnage`}
                      name={`${nameEdit}-Tonnage`}
                      type="number"
                      value={Tonnage}
                      onChange={(e) => setTonnage(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head">
                    &nbsp;&nbsp;Rmi Code
                  </td>
                  <td colSpan={3}>
                    {/* <DropdownFetcher
                    keyword="rm_details_description_dropdown"
                    setValue={setRmDropdown}
                  /> */}
                    <Select
                      id={`${idEdit}-RmiCde`}
                      name={`${nameEdit}-RmiCde`}
                      value={RmiCde}
                      onChange={handleRmiCde}
                      onInputChange={(event) => { GetSelectValue(event, "rm_details_description_dropdown", setRmDropdown) }}
                      options={rmOptions}
                      placeholder="Search RMI Code.."

                    />
                    {/* <input
                id={`${idEdit}-RmiCde`}
                name={`${nameEdit}-RmiCde`}
                  type="text"
                  value={RmiCde}
                  onChange={(e) => setRmiCde(e.target.value)}

                /> */}
                  </td>

                  <td className="topic-head">&nbsp;&nbsp;Cycle Code</td>
                  <td colSpan={2}>
                    <input
                      id={`${idEdit}-CyleCde`}
                      name={`${nameEdit}-CyleCde`}
                      type="text"
                      value={CyleCde}
                      onChange={(e) => setCyleCde(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head">&nbsp;&nbsp;Rm Name</td>
                  <td colSpan={3}>
                    <input
                      id={`${idEdit}-RmNme`}
                      name={`${nameEdit}-RmNme`}
                      type="text"
                      value={RmNme}
                      onChange={(e) => setRmNme(e.target.value)}
                      disabled
                    />
                  </td>

                  <td className="topic-head">&nbsp;&nbsp;Part Weight(gm)</td>
                  <td colSpan={2}>
                    <input
                      id={`${idEdit}-PrtWeght`}
                      name={`${nameEdit}-PrtWeght`}
                      type="number"
                      value={PrtWeght}
                      onChange={(e) => setPrtWeght(e.target.value)}
                    />
                  </td>
                </tr>

                <tr>
                  <th colSpan={4} style={{ textAlign: "center" }}>
                    MONTH PLAN
                  </th>
                  <th colSpan={3} style={{ textAlign: "center" }}>
                    MATERIAL PLAN
                  </th>
                </tr>
                <tr>
                  <td className="topic-head">&nbsp;&nbsp;Plan</td>
                  <td colSpan={3}>
                    <input
                      id={`${idEdit}-Plan`}
                      name={`${nameEdit}-plan`}
                      type="text"
                      value={Plan}
                      onChange={(e) => setPlan(e.target.value)}
                    />
                  </td>
                  <td className="topic-head">&nbsp;&nbsp;Material</td>
                  <td colSpan={2}>
                    <input
                      id={`${idEdit}-Material`}
                      name={`${nameEdit}-Material`}
                      type="text"
                      value={Material}
                      onChange={(e) => setMaterial(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head">&nbsp;&nbsp;Receipt</td>
                  <td colSpan={3}>
                    <input
                      id={`${idEdit}-Receipt1`}
                      name={`${nameEdit}-Receipt1`}
                      type="text"
                      value={Receipt1}
                      onChange={(e) => setReceipt1(e.target.value)}
                    />
                  </td>
                  <td className="topic-head">&nbsp;&nbsp;Receipt</td>
                  <td colSpan={2}>
                    <input
                      id={`${idEdit}-Receipt2`}
                      name={`${nameEdit}-Receipt2`}
                      type="text"
                      value={Receipt2}
                      onChange={(e) => setReceipt2(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head">&nbsp;&nbsp;Balance</td>
                  <td colSpan={3}>
                    <input
                      id={`${idEdit}-Balance1`}
                      name={`${nameEdit}-Balance1`}
                      type="text"
                      value={Balance1}
                      onChange={(e) => setBalance1(e.target.value)}
                    />
                  </td>
                  <td className="topic-head">&nbsp;&nbsp;Balance</td>
                  <td colSpan={2}>
                    <input
                      id={`${idEdit}-Balance2`}
                      name={`${nameEdit}-Balance2`}
                      type="text"
                      value={Balance2}
                      onChange={(e) => setBalance2(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="topic-head">Packing Std</p>
                  </td>
                  <td colSpan={3}>
                    <textarea
                      id={`${idEdit}-PackingStd`}
                      name={`${nameEdit}-PackingStd`}
                      value={PackingStd}
                      onChange={(e) => setPackingStd(e.target.value)}
                    />
                  </td>
                  <td colSpan={5}></td>
                </tr>
                <tr>
                  <th colSpan={4} style={{ textAlign: "center" }}>
                    FROM
                  </th>
                  <th colSpan={3} style={{ textAlign: "center" }}>
                    TO
                  </th>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <input
                      id={`${idEdit}-From`}
                      name={`${nameEdit}-From`}
                      type="text"
                      value={From}
                      onChange={(e) => setFrom(e.target.value)}
                    />

                    {/* <input
                id={`${idEdit}-From`}
                name={`${nameEdit}-From`}
                    type="file"
                    accept="image/*"
                    capture="camera"
                    onChange={(e) => handlePhotoChangeFrom(e)} 
                   />
                   {From && <img src={From} alt="Selected" style={{ display: 'block', margin: '0 auto',
                   maxWidth: '150px', height: '150px' }} />} */}
                  </td>
                  <td colSpan={3}>
                    <input
                      id={`${idEdit}-To`}
                      name={`${nameEdit}-To`}
                      type="text"
                      value={To}
                      onChange={(e) => setTo(e.target.value)}
                    />

                    {/* <input
                id={`${idEdit}-To`}
                name={`${nameEdit}-To`}
                    type="file"
                    accept="image/*"
                    capture="camera"
                    onChange={(e) => handlePhotoChangeTo(e)} 
                   />
                   {To && <img src={To} alt="Selected" style={{ display: 'block', margin: '0 auto',
                   maxWidth: '150px', height: '150px' }} />} */}
                  </td>
                </tr>
                <tr>
                  <td colSpan={7}>
                    <span>
                      Note:
                      <ol>
                        <li>Kindly Attached Mold Inspection Report</li>
                        <li>kindly Attached Sample Part</li>
                        <li>Kindly Attached Proper Packing Standard Report</li>
                        <li>Kindly Attached Quality Plan</li>
                        <li>If You Ignored, We can't Accept The Report</li>
                      </ol>
                    </span>
                  </td>
                </tr>
                <tr>
                  <th colSpan={2} style={{ textAlign: "center" }}>
                    INHOUSE STOCK DETAILS
                  </th>
                  <td rowSpan={5} colSpan={3}>
                    <p className="topic-head">Production Head</p>

                    <DropdownFetcher
                      keyword="emp_details_dropdown_all"
                      setValue={setEmployeeDropdown}
                    />
                    <DropdownFetcher
                      keyword="emp_details_hod"
                      setValue={setHODEmployeeDropdown}
                    />
                    <Select
                      id={`${idEdit}-ProductionHead`}
                      name={`${nameEdit}-ProductionHead`}
                      value={ProductionHead}
                      onChange={handleProductionHead}
                      options={empHODSetterOptions}
                      placeholder="Select Production Head"
                    />
                  </td>
                  <th colSpan={2} style={{ textAlign: "center" }}>
                    SUPPLIER STOCK DETAILS
                  </th>
                </tr>
                <tr>
                  <td className="topic-head">&nbsp;&nbsp;Fg Stock</td>
                  <td>
                    <input
                      id={`${idEdit}-FgStock`}
                      name={`${nameEdit}-FgStock`}
                      type="number"
                      value={FgStock}
                      onChange={(e) => setFgStock(e.target.value)}
                    />
                  </td>
                  <td className="topic-head">&nbsp;&nbsp;Fgw Stock</td>
                  <td colSpan={2}>
                    <input
                      id={`${idEdit}-FgwStock`}
                      name={`${nameEdit}-FgwStock`}
                      type="number"
                      value={FgwStock}
                      onChange={(e) => setFgwStock(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head">&nbsp;&nbsp;Semi Stock</td>
                  <td>
                    <input
                      id={`${idEdit}-SmiStock1`}
                      name={`${nameEdit}-SmiStock1`}
                      type="number"
                      value={SmiStock1}
                      onChange={(e) => setSmiStock1(e.target.value)}
                    />
                  </td>
                  <td className="topic-head">&nbsp;&nbsp;Semi Stock</td>
                  <td colSpan={2}>
                    <input
                      id={`${idEdit}-SmiStock2`}
                      name={`${nameEdit}-SmiStock2`}
                      type="number"
                      value={SmiStock2}
                      onChange={(e) => setSmiStock2(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head">&nbsp;&nbsp;total Stock</td>
                  <td>
                    <input
                      id={`${idEdit}-TtlStock1`}
                      name={`${nameEdit}-TtlStock1`}
                      type="number"
                      value={TtlStock1}
                      onChange={(e) => setTtlStock1(e.target.value)}
                    />
                  </td>
                  <td className="topic-head">&nbsp;&nbsp;Total Stock</td>
                  <td colSpan={2}>
                    <input
                      id={`${idEdit}-TtlStock2`}
                      name={`${nameEdit}-TtlStock2`}
                      type="number"
                      value={TtlStock2}
                      onChange={(e) => setTtlStock2(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head">&nbsp;&nbsp;No Days Coverage</td>
                  <td>
                    <input
                      id={`${idEdit}-NodysCov1`}
                      name={`${nameEdit}-NodysCov1`}
                      type="number"
                      value={NodysCov1}
                      onChange={(e) => setNodysCov1(e.target.value)}
                    />
                  </td>
                  <td className="topic-head">&nbsp;&nbsp;No Days Coverage</td>
                  <td colSpan={2}>
                    <input
                      id={`${idEdit}-NodysCov2`}
                      name={`${nameEdit}-NodysCov2`}
                      type="number"
                      value={NodysCov2}
                      onChange={(e) => setNodysCov2(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="topic-head">Prepared By</p>

                    <Select
                      id={`${idEdit}-PreparedByName`}
                      name={`${nameEdit}-PreparedByName`}
                      value={PreparedByName}
                      onChange={handlePreparedByName}
                      options={empMoldSetterOptions}
                      placeholder="Select PreparedBy Name"
                    />
                  </td>
                  <td>
                    <p className="topic-head">Vendor Head</p>

                    <DropdownFetcher
                      keyword="supplier_details_dropdown"
                      setValue={setVendorDropdown}
                    />

                    <Select
                      id={`${idEdit}-VendarName`}
                      name={`${nameEdit}-VendarName`}
                      value={VendarName}
                      onChange={handleVendarName}
                      options={vendorOptions}
                      placeholder="Select Vendar Name"
                    />
                  </td>
                  <td colSpan={2}>
                    <p className="topic-head">Purchase Head</p>

                    <Select
                      id={`${idEdit}-PurchaseName`}
                      name={`${nameEdit}-PurchaseName`}
                      value={PurchaseName}
                      onChange={handlePurchaseName}
                      options={empMoldSetterOptions}
                      placeholder="Select Purchase Head"
                    />
                  </td>
                  <td>
                    <p className="topic-head">Customer Quality</p>

                    <Select
                      id={`${idEdit}-CustomerQualityName`}
                      name={`${nameEdit}-CustomerQualityName`}
                      value={CustomerQualityName}
                      onChange={handleCustomerQualityName}
                      options={empMoldSetterOptions}
                      placeholder="Select Customer Quality"
                    />
                  </td>
                  <td>
                    <p className="topic-head">Production Quality</p>

                    <Select
                      id={`${idEdit}-ProductionQualityName`}
                      name={`${nameEdit}-ProductionQualityName`}
                      value={ProductionQualityName}
                      onChange={handleProductionQualityName}
                      options={empMoldSetterOptions}
                      placeholder="Select Production Quality"
                    />
                  </td>
                  <td>
                    <p className="topic-head">Tool Room Sign</p>

                    <Select
                      id={`${idEdit}-ToolRoomSign`}
                      name={`${nameEdit}-ToolRoomSign`}
                      value={ToolRoomSign}
                      onChange={handleToolRoomSign}
                      options={empMoldSetterOptions}
                      placeholder="Select Tool Room Sign"
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <p className="topic-head">Store/Material</p>
                    <Select
                      id={`${idEdit}-StoreMaterial`}
                      name={`${nameEdit}-StoreMaterial`}
                      value={StoreMaterial}
                      onChange={handleStoreMaterial}
                      options={empMoldSetterOptions}
                      placeholder="Select Name"
                    />
                  </td>
                  <td colSpan={3}>
                    <p className="topic-head">Director Sign</p>
                    <Select
                      id={`${idEdit}-DirectorSign`}
                      name={`${nameEdit}-DirectorSign`}
                      value={DirectorSign}
                      onChange={handleDirectorSign}
                      options={empMoldSetterOptions}
                      placeholder="Select Director Sign"
                    />
                  </td>
                  <td colSpan={2}>
                    <p className="topic-head">SQA Sign</p>
                    <Select
                      id={`${idEdit}-SqaSign`}
                      name={`${nameEdit}-SqaSign`}
                      value={SqaSign}
                      onChange={handleSqaSign}
                      options={empMoldSetterOptions}
                      placeholder="Select SQA Name"
                    />
                  </td>
                </tr>

                <tr>
                  <td colSpan="7" align="center" style={{ border: "none" }}>
                    <button
                      type="submit"
                      id={`${idEdit}-submit`}
                      name={`${nameEdit}-submit`}
                      onClick={(e) => handleUpdate(e)}
                    >
                      Update
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </form>
      </Popup>

      <div>

        <table
          style={{ textAlign: "center", width: "100%" }}
        /*{ cellPadding="5" }*/
        >
          <thead>
            <tr>
              <th
                id="mlcss-Title.List"
                name="mlcss-Title.List"
                colSpan="12"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">List - 4M Report</font>
              </th>
            </tr>
            <tr>
              {/* <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                  Date
                </td>
                <td colSpan="1">
                  <input
                  id="Report4M-filter-PartName"
                  name="Report4M-filter-PartName"
                    type="text"
                    placeholder="Filter by Date"
                    value={filterDate}
                    onChange={handleFilterDateChange}
                  />
                </td> */}
              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                Moving Process
              </td>
              <td colSpan={2}>
                <select
                  id="MovingProcess.Filter"
                  name="MovingProcess.Filter"
                  value={filterMcno}
                  onChange={handleFilterMcnoChange}

                  style={{ width: "95%", textAlign: "center" }}
                >
                  {options.map((option) => (
                    <option key={option.id} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {/* <input
                  id="Report4M-filter-mouldName"
                  name="Report4M-filter-mouldName"
                  type="text"
                  placeholder="Filter Moving Process"
                  style={{ fontSize: "70%", width: "100%" }}
                  value={filterMcno}
                  onChange={handleFilterMcnoChange}
                /> */}
              </td>
              <td colSpan="8"></td>
            </tr>
            <tr className="table_position">
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Moving Process&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Mould Name&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;No Of Cavity&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Part No&nbsp;&nbsp;
              </th>
              {/* <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Item Code&nbsp;&nbsp;
              </th>

              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Cycle Code&nbsp;&nbsp;
              </th> */}
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Part Weight&nbsp;&nbsp;
              </th>
              {/* <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Created Date&nbsp;&nbsp;
              </th> */}
              <th className="table-list-head"></th>
              <th className="table-list-head"></th>
              <th className="table-list-head"></th>
            </tr>
          </thead>
          {loading ? (
            <td colSpan="10" className="load">
                <p >Loading......</p>
              </td>
      ) : (
          <tbody>

            {filteredRecords.length === 0 ? (
              // <tr>
              <td colSpan="10" style={{ textAlign: "center" }}>
                <p style={{ fontWeight: "bold" }}>No records found.</p>
              </td>
            ) : (
              // </tr>
              filteredRecords.map((Report) => (
                <tr className="table_view" key={Report.Id}>
                  <td
                    id="mlcss-list.Moving_process"
                    name="mlcss-list.Moving_process"
                    className="table-cell"
                    style={{whiteSpace:"normal"}}
                  >
                    &nbsp;&nbsp;{Report.Moving_process}
                  </td>
                  <td
                    id="mlcss-list.machineCode"
                    name="mlcss-list.machineCode"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{Report.Mld_name}
                  </td>
                  <td
                    id="mlcss-list.machineName"
                    name="mlcss-list.machineName"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{Report.No_of_cvty}
                  </td>
                  <td
                    id="mlcss-list.machinever"
                    name="mlcss-list.machinever"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{Report.Part_no}
                  </td>
                  {/* <td
                    id="mlcss-list.flex1"
                    name="mlcss-list.flex1"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{Report.Itm_cde}
                  </td> */}

                  {/* <td className="table-cell">&nbsp;&nbsp;{Report.Cyle_cde}</td> */}
                  <td className="table-cell">
                    &nbsp;&nbsp;{Report.Prt_weight}
                  </td>
                  {/* <td className="table-cell">&nbsp;&nbsp;{Report.Mat_ld_time}</td> */}
                  {/* <td id="mlcss-list.createddate"
        name="mlcss-list.createddate"
        className="table-cell">&nbsp;&nbsp;{Report.CreatedDate}</td> */}
                  <td>
                    <button
                      id="mlcss-view"
                      name="mlcss-view"
                      style={{ backgroundColor: "#cba423" }}
                      type="button"
                      onClick={() => handleView(Report)}
                    >
                      View&#x1F441;
                    </button>
                  </td>
                  <td>
                    <button
                      id="mlcss-edit"
                      name="mlcss-edit"
                      type="button"
                      onClick={() => handleEdit(Report)}
                    >
                      Edit&#x270E;
                    </button>
                  </td>
                  <td>
                    <button
                      id={`${idEdit}-Delete`}
                      name={`${idEdit}-Delete`}
                      style={{ whiteSpace: "nowrap", backgroundColor: "#DA251A" }}
                      type="button"
                      onClick={() => {
                        const userConfirmed = window.confirm('Are you sure you want to delete?');
                        if (userConfirmed) {
                          UpdateRecord({
                            _keyword_: 'Report_4M',
                            data: [
                              {
                                Id: Report.Id,
                                ActiveStatus: 'InActive',
                              },
                            ],
                          }).then(() => {
                            handleReadReport4M();
                          })
                          // window.location.reload();
                        }
                      }}
                    >
                      Delete<MdDelete />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
      )}
        </table>
        <div className="pagination" id="pagination">
          {isPaginationVisible && filteredData.length > 0 && (

            <Pagination
              currentPage={currentPage}
              npage={npage}
              prePage={prePage}
              nextPage={nextPage}
              changeCpage={changeCpage}
              numbers={numbers}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Report4MView;
