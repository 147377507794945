import React from 'react';


const OkNotOkayFetch = ( {Value, onChange,isDisabled} ) => {
  return(
    <select
                
                style={{ width: "95%" , textAlign:"center", cursor:"pointer" ,backgroundColor:"#bdaa8a"}}
                value={Value}
                onChange={onChange}
                disabled={isDisabled}
              //  defaultValue={"NA"}
              >
                <option value="NA">N A</option>
                <option value="Ok">✓</option>
                <option value="NotOk">X</option>
              </select>
);
}
export default OkNotOkayFetch;