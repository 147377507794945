//external import
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

//internal import
import Field from "../../../Components/Fields/Field";
import Lable from "../../../Components/Fields/Lable";
import QaLayout from "../../Quality/QaLayout";

//css import
import './NewSmpMain.css';
//API imports
const MouldTempDetails = ({ Records, setRecords, setActiveKey, activeKey }) => {
    const { option, id } = useParams();
    const [ctrlPressed, setCtrlPressed] = useState(false);
    function InvalidCharacters(value) {
        return /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/.test(value);
      }
    
    useEffect(() => {
        if (activeKey === "seven") {
            document.getElementById("1-Mould_temp_value").focus();
          }
        const handleKeyDown = (event) => {

          let activeField = document.activeElement;
          let fieldOptions = {
            Mani_fold_celsius: ["", "Hot_runner_gate_celsius"],
            Hot_runner_gate_celsius: ["Mani_fold_celsius", ""],
          };
    
          let row = Records[0].mouldtempdetails_function.length - 1;
          let currentField = activeField.id.split("-")[1];
          let indexOfField = activeField.id.split("-")[0];
          let nextField = "";
    
          if (currentField != undefined) {
            
            switch (event.ctrlKey + "+" + event.key + "+" + activeKey) {

              case "false+ArrowDown+seven":
                // console.log("indexOfField",indexOfField,row,indexOfField == row);
                if (indexOfField < row+1) {
                  document
                    .getElementById(Number(indexOfField) + 1 + "-" + currentField)
                    .focus();
                }
                break;
    
              case "false+ArrowUp+seven":
                if (indexOfField > 1) {
                  //   if (indexOfField < row + 1) {
                  document
                    .getElementById(Number(indexOfField) - 1 + "-" + currentField)
                    .focus();
                  //   }
                }
                break;
    
              case "true+ArrowRight+seven":
                setActiveKey("eight");
                break;

              default:
                break;
            }
          }
        };
    
        document.addEventListener("keydown", handleKeyDown);
        return () => {
          document.removeEventListener("keydown", handleKeyDown);
        };
      }, [activeKey, setActiveKey]);
    

    function HandleMouldTemp(Event, index) {
        // console.log("Event", Event.target.name);
        setRecords((item) => {
            let record = [...Records];
            record[0].mouldtempdetails_function[index] = {
                ...record[0].mouldtempdetails_function[index],
                [Event.target.name]: Event.target.value,
            };
            return record;
        });
    }
    function handleTempNameChange(Event, index){
        // console.log("evenr",event);
        setRecords((item) => {
            let record = [...Records];
            record[0].mouldtempdetails_function[index] = {
                ...record[0].mouldtempdetails_function[index],
                [Event.target.name]: Event.target.value,
            };
            
            if (index === 2 && record[0].mouldtempdetails_function[index].Mould_temp_details === "MTC Temp") {
                record[0].mouldtempdetails_function[index].Mould_temp_details = "Galen Temp";
            }else if (index === 2 && record[0].mouldtempdetails_function[index].Mould_temp_details === "Galen Temp"){
                record[0].mouldtempdetails_function[index].Mould_temp_details = "MTC Temp"; 
            }
    
            return record;
        });
    }


    const DropdownValues = [
        { value: "", label: "Select" },
        { value: "1", label: "±1" },
        { value: "2", label: "±2" },
        { value: "3", label: "±3" },
        { value: "4", label: "±4" },
        { value: "5", label: "±5" },
        { value: "6", label: "±6" },
        { value: "7", label: "±7" },
        { value: "8", label: "±8" },
        { value: "9", label: "±9" },
        { value: "10", label: "±10" },
        { value: "11", label: "±11" },
        { value: "12", label: "±12" },
        { value: "13", label: "±13" },
        { value: "14", label: "±14" },
        { value: "15", label: "±15" },
    ];

    return (
        <div>
            <table>
            <thead>
                    <tr>
                        <th colSpan={5}>
                            <div style={{ display: "grid", gridTemplateColumns: "90% 10%", alignItems: "center" }}>
                                <div style={{ textAlign: "center" }}>
                                    <Lable value={"Mould Temperature Details"} type={"Head"} style={{ padding: "1px" }} />
                                </div>
                                <div>
                                <button className="nextButtonLabel" onClick={(e) => { setActiveKey("eight") }}>Next</button>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {Records[0].mouldtempdetails_function.map((item, index) => (
                        <tr key={index}>
                            <td>
                                <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>

                                    <Lable onClick={(e)=>handleTempNameChange(e,index)} value={item.Mould_temp_details} style={{ textAlign: 'center', padding: "0px" }} />
                                    
                                    <Field
                                    label={""}
                                    name={"Mould_temp_set"}
                                    className={"fieldSingleGrid"}
                                    options={{
                                        type: "dropdown",
                                        disabled: option == "view",
                                        values: DropdownValues
                                    }}
                                    value={item}
                                    OnChange={(e) => {
                                        HandleMouldTemp(e, index);
                                    }}
                                />
                                </div>
                            </td>
                            <td>                                
                                <Field
                                label={""}
                                id={index+1}
                                name={"Mould_temp_value"}
                                className={"fieldSingleGrid"}
                                options={{
                                    type: "text",

                                    disabled: option == "view",
                                }}
                                value={item}
                                OnChange={(e) => {
                                    const value = e.target.value;
                                    if (InvalidCharacters(value)) {
                                      e.preventDefault();
                                      return;
                                    }
                                    HandleMouldTemp(e, index);
                                }}
                            />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
export default MouldTempDetails;
