import React from "react";
import ListView from "../../../Components/ListView/ListView";
import QaLayout from "../QaLayout";

const LastOffList = () => {
  return (
    <QaLayout quary={"quality_last_off_inspection"}>
    <div>
      
      <ListView
        Title={"Last Off Inspection"}
        standard={"qp.ActiveStatus = 1"}
        table={"quality_last_off_inspection qp"}
        tableDelete={"quality_last_off_inspection"}
        //group by qp.Id,qp.part_name,qp.part_number,qp.Raw_material_name,rm_details.rm_description 
        quary={"Select distinct qp.Id,qp.part_number,qp.Part_name,qp.Machine_no,qp.Raw_material_name,rm_details.rm_description AS rm_name from quality_last_off_inspection qp LEFT JOIN rm_details ON qp.raw_material_name =rm_details.rm_code order by qp.Id desc"}
        navigation={"lastoff"}
        filter={["qp.Id","qp.Machine_no","qp.part_number","qp.Part_name"]}
        dateFilter={"qp.CreatedDate"}
        header={[
          { value: "qp.Id", label: "ID" },
          { value: "qp.part_number", label: "Part Number" },
          { value: "qp.Part_name", label: "Part Name" },
          { value: "qp.Machine_no", label: "Machine No" },
          { value: "qp.Raw_material_name", label: "Raw Material" },
          { value: "rm_name", label: "Raw Name" },
          // { value: "Prodcution_operator_name", label: "Production Operator" },
          // { value: "Prodcution_incharge", label: "Production Incharge" },
        ]}
      />
    </div>
    </QaLayout>
  );
};

export default LastOffList;
