import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import QaLayout from "../../../Quality/QaLayout.js";
import Field from "../../../../Components/Fields/Field.js";
import CreateRecordPaint from "../../../../Lib/API/CreateRecordPaint.js";
import ReadRecordPaint from "../../../../Lib/API/ReadRecordPaint.js";
import LoadingPage from "../../../../Components/Handler/LoadingPage.js";
import * as GeneralConstants from "../../../../Utils/GeneralConstants.js";
import UpdateRecordPaint from "../../../../Lib/API/UpdateRecordPaint.js";
import IsDuplicatePaint from "../../../../Lib/IsDuplicatePaint.js";
import Lable from "../../../../Components/Fields/Lable.js";
const initState = {
  // TrackDate: new Date().toISOString().split("T")[0],
  Part_code: "",
  Part_name: "",
  Active_status: "Active",
  Flex_01: "",
  Flex_02: "",
};

function PartMaster() {
  const [Create, setCreate] = useState(initState);
  const [warning, setwarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const { option, id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    console.log("Create", Create);
  }, [Create]);
  useEffect(() => {
    setwarning(false);
    document.title = "Paint Part Master";
    const fetchData = async () => {
      if (id === undefined) {
        setCreate(initState);
      } else {
        setLoading(true);
        try {
          const res = await ReadRecordPaint({
            _keyword_: "paint_part_master",
            Id: id,
          });
          console.log("res", res);
          setCreate(res.data[0]);
          // console.log("Res2",Create)
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, []);
  if (loading) {
    return <LoadingPage />;
  }

  const handleCreate = async () => {
    try {
      // console.log(Create);
      const IsDuplicatePaintCode = await IsDuplicatePaint("paint_part_master", [
        `part_code="${Create.Part_code}"`,
      ]);

      if (IsDuplicatePaintCode) {
        alert(`Part with code ${Create.Part_code} already exists.`);
        return;
      }

      const IsDuplicatePaintName = await IsDuplicatePaint("paint_part_master", [
        `part_name="${Create.Part_name}"`,
      ]);

      if (IsDuplicatePaintName) {
        alert(`Part with name ${Create.Part_name} already exists.`);
        return;
      }

      if (!Create.Part_code || !Create.Part_name || !Create.Active_status) {
        alert("Please Fill the Mandatory Fields");
        setwarning(true);
        return;
      } else {
        let record = [{ ...Create }];
        CreateRecordPaint("paint_part_master", record).then((res) => {
          console.log("HandleCreate", res);
        });
        alert(GeneralConstants.SUBMISSION_SUCCESSFUL);
        setwarning(false);
        setCreate(initState);
      }
    } catch (error) {
      console.error(error);
      alert(GeneralConstants.SUBMISSION_FAILED);
      setwarning(false);
    }
  };
  const handleUpdate = async () => {
    try {
      if (!Create.Part_code || !Create.Part_name || !Create.Active_status) {
        alert("Please Fill the Mandatory Fields");
        setwarning(true);
        return;
      }

      let record = [{ ...Create }];
      UpdateRecordPaint("paint_part_master", record).then((res) => {
        console.log("HandleUpdate", res);
      });

      alert(GeneralConstants.UPDATE_SUCCESSFUL);
      setwarning(false);
      // setCreate(initState);
    } catch (error) {
      console.error(error);
      alert(GeneralConstants.UPDATE_FAILURE);
      setwarning(false);
    }
    navigate(-1);
  };
  return (
    <QaLayout>
      <div>
        <table>
          <tbody>
            <tr>
              <th colSpan={8}>
                <Lable value={"Paint Part Master"} type={"Head"} />
              </th>
            </tr>
            <tr>
              <td>
                <Field
                  label={"Part Code"}
                  name={"Part_code"}
                  options={{
                    type: "text",
                    required: true,
                    disabled: option === "view" || option === "edit",
                  }}
                  warning={warning}
                  value={Create}
                  OnChange={(e) =>
                    setCreate({ ...Create, Part_code: e.target.value })
                  }
                />
              </td>
              <td>
                <Field
                  label={"Part Name"}
                  name={"Part_name"}
                  options={{
                    type: "text",
                    required: true,
                    disabled: option === "view" || option === "edit",
                  }}
                  warning={warning}
                  value={Create}
                  OnChange={(e) =>
                    setCreate({ ...Create, Part_name: e.target.value })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>
                <Field
                  label={"Flex"}
                  name={"Flex_01"}
                  options={{ type: "text", disabled: option === "view" }}
                  value={Create}
                  OnChange={(e) =>
                    setCreate({ ...Create, Flex_01: e.target.value })
                  }
                />
              </td>
              <td>
                <Field
                  label={"Active Status"}
                  name={"Active_status"}
                  options={{
                    type: "dropdown",
                    required: true,
                    disabled: option === "view",
                    values: [
                      { value: "", label: "Select" },
                      { value: "Active", label: "Active" },
                      { value: "InActive", label: "InActive" },
                    ],
                  }}
                  value={Create}
                  warning={warning}
                  OnChange={(e) =>
                    setCreate({ ...Create, Active_status: e.target.value })
                  }
                />
              </td>
            </tr>

            {!(option === "view") && (
              <tr>
                {id === undefined ? (
                  <td colSpan={19}>
                    {" "}
                    <Field
                      label={"Submit"}
                      options={{ type: "Button" }}
                      onClick={(e) => {
                        handleCreate(e);
                      }}
                    />
                  </td>
                ) : (
                  <td colSpan={19}>
                    {" "}
                    <Field
                      label={"Update"}
                      options={{ type: "Button" }}
                      onClick={(e) => {
                        handleUpdate(e);
                      }}
                    />
                  </td>
                )}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </QaLayout>
    
  );
}

export default PartMaster;
