
import React from 'react';
import Select from "react-select";
const DropdownFilterSelect = ({ value  ,options , onChange , placeholder, isDisabled,onInputChange}) => {
  
return (
  <Select       
  isDisabled={isDisabled}     
  value={
    value
      ? {
          value: value,
          label: value,
        }
      : null
  }
  id="dropdown"
  
  // styles={{ width: "100%" , backgroundColor:"#fac364" }}
  options={options}
  onChange={onChange}
  onInputChange={onInputChange}
  placeholder={placeholder}
  required
/>
);
};
export default DropdownFilterSelect;