import React, { useEffect, useState } from "react";
import Popup from "../../../Components/Popup/Popup.js";
import axios from "axios";
import Unitechlogo from "../../../icons/Unitechlogo.jpeg";
import { API_URL } from "../../../Utils/constants.js";
import "../../../Styles/Machbd.css";

import Select from "react-select";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import GetRecord from "../../../Lib/GetRecord";
import { FaBackspace } from 'react-icons/fa';
import reject from "../../../Lib/reject";
import approve from "../../../Lib/approve";
import Pagination from "../../../Components/Pagination";
import HandleRead from "../../../Lib/HandleRead.js";
import DatePicker from "../../../Lib/DatePicker.js";
import { formatDt } from "../../../Lib/formatDt.js";
import DropdownFetcher from "../../../Lib/DropdownFetcher.js";
import UpdateRecord from "../../../Lib/updateRecord";
import { MdDelete } from "react-icons/md";
import ReadRecord from "../../../Lib/API/ReadRecord.js";
import { func } from "prop-types";
import {
  CURRENT_Date,
  CURRENT_TIME,
  SHIFTMOLDINGPRODUCTION,
} from "../../../Utils/GeneralConstants";
import { setMessage } from "../../../Lib/SetMessage.js";
import CreateApproval from "../../../Lib/CreateApproval.js";

function MacBDView() {
  const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const handleDateChange = newDate => {
    setD1(newDate)
    setT1(currentTime);
  }
  const handleDateChange1 = newDate => {
    setD2(newDate)
    setT2(currentTime);
  }
  const handleDateChange2 = newDate => {
    setD3(newDate)
    setT3(currentTime);
  }
  const [partname, setPartName] = useState([]);
  const [Employee, setEmployee] = useState([]);
  const [Name, setname] = useState([]);
  const [macno, setmacno] = useState([]);
  const [FormtNo, setFormtNo] = useState("");
  const [Id, setId] = useState("");
  const [Rev, setRev] = useState("");
  const [Date0, setDate0] = useState("");
  const [Frm, setFrm] = useState("");
  const [SerNo, setSerNo] = useState("");
  const [Dept, setDept] = useState("");
  const [InfTo, setInfTo] = useState("");
  const [ERPNo, setERPNo] = useState("");
  const [McNo, setMcNo] = useState("");
  const [PrtNme, setPrtNme] = useState("");
  const [Shft, setShft] = useState("");
  const [MacPrb, setMacPrb] = useState("");
  const [ProPrb, setProPrb] = useState("");
  const [PreStsM, setPreStsM] = useState("");
  const [PrbSts, setPrbSts] = useState("");
  const [BrkDwnT, setBrkDwnT] = useState("");
  const [MacCat, setMacCat] = useState("");
  const [NofP, setNofP] = useState("");
  const [AntiFault, setAntiFault] = useState("");
  const [D1, setD1] = useState("");
  const [D2, setD2] = useState("");
  const [D3, setD3] = useState("");
  const [T1, setT1] = useState("");
  const [T2, setT2] = useState("");
  const [T3, setT3] = useState("");
  const [S1, setS1] = useState("");
  const [S2, setS2] = useState("");
  const [S3, setS3] = useState("");
  const [THrs, setTHrs] = useState("");
  const [SRec, setSRec] = useState("");
  const [Temp, setTemp] = useState("");
  const [Permn, setPermn] = useState("");
  const [UnOb, setUnOb] = useState("");
  const [CorrAct, setCorrAct] = useState("");
  const [SprRep, setSprRep] = useState("");
  const [ItmNo, setItmNo] = useState("");
  const [Size, setSize] = useState("");
  const [Cost, setCost] = useState("");
  const [InCrg, setInCrg] = useState("");
  const [TechSign, setTechSign] = useState("");
  const [Approval, setApproval] = useState("");
  const [Approver, setApprover] = useState("");
  const [RejectedReason, setRejectedReason] = useState("");
  const [RecievingPerson, setRecievingPerson] = useState("");
  const [Received, setReceived] = useState("");
  const [FromPerson, setFromPerson] = useState("");
  const [TechSignature, setTechSignature] = useState("");

  var [MacBD, setMacBD] = useState([]);
  const { id } = useParams();
  const [back, setback] = useState(false);
  const navigate = useNavigate();
  const [cardId, setcardId] = useState('');
  const [remarks, setremarks] = useState('');

  const [isPaginationVisible, setPaginationVisible] = useState(true);
  const [isCreating, setIsCreating] = useState(false);

  const [selectedmac, setSelectedmac] = useState(null);
  const [ButtonPopup, setButtonPopup] = useState(false);
  const [ButtonPopup1, setButtonPopup1] = useState(false);
  const [ButtonPopup2, setButtonPopup2] = useState(false);

  useEffect(() => {
    // console.log(id);
    if (id !== undefined) {
      GetRecord(
        {
          _keyword_: "Get_BreakDown_by_id",
          id: id,
        },
        setMacBD
      ).then(async (res) => {
        handleView(res[0])
        setButtonPopup(true)
        setback(true)
        await timeout(1000);
        const popupDiv = document.querySelector(".popup");
        if (popupDiv) {
          const elementInsidePopup = popupDiv.querySelector("#close-btn");
          elementInsidePopup.style.display = "none";
        }
      })
    }
  }, [id])
  function handleReadRecord(id) {
    ReadRecord({
      _keyword_: "Get_approver_by_empId",
      TableID: id,
      TableName: "MacBD",
      Approver: localStorage.getItem("empId"),
    }).then((res) => {
      // console.log("res.data,",res);
      if (res.rows > 0) {
        setApprover(res.data[0].Approver);
        setRejectedReason(res.data[0].ApprovalRemark);
      } else {
        setApprover("");
        setRejectedReason("");
      }
    }).then(()=>{
      ReadRecord({
        _keyword_: "Get_approver_ApprovalRemark",
        TableID: id,
        TableName: "MacBD"
      }).then((res)=>{
        if(res.rows>0){
          setRejectedReason(res.data[0].ApprovalRemark);
        }else{
          setRejectedReason("");
        }
      })
    })
  }
  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }
  const viewPagination = () => {
    setPaginationVisible(true);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 20;

  const [filterDate, setFilterDate] = useState("");
  const [filterMcno, setFilterMcno] = useState("");
  const filterRecords = () => {
    const filteredData = MacBD.filter((record) => {
      const dateMatch = record.Date1 && record.Date1.includes(filterDate);
      const mcnoMatch =
        record.Machine_No &&
        record.Machine_No.toLowerCase().includes(filterMcno.toLowerCase());
      return mcnoMatch && dateMatch;
    });

    return filteredData;
  };

  const filteredData = filterRecords();
  const npage = Math.ceil(filteredData.length / recordPerPage);
  const numbers = [...Array(npage).keys()].map((num) => num + 1);

  function handleFilterDateChange(event) {
    setFilterDate(event.target.value);
    setCurrentPage(1);
  }

  function handleFilterMcnoChange(event) {
    setFilterMcno(event.target.value);
    setCurrentPage(1);
  }

  const firstIndex = (currentPage - 1) * recordPerPage;
  const lastIndex = firstIndex + recordPerPage;
  const filteredRecords = filteredData.slice(firstIndex, lastIndex);
  function prePage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function nextPage() {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function changeCpage(Id) {
    setCurrentPage(Id);
  }

  //Unique id and name
  // const id ="Mach_bd";
  // const name ="Mach_bd";


  const handleEmployeeChange = (selectedOption) => {
    const selected = Employee.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setDept(selected.Emp_department);
      setFrm({ value: selected.Emp_code, label: selected.Emp_name });
    }
  };

  const handleMachine = (selectedOption) => {
    const selected = macno.find(
      (option) => option.mc_code === selectedOption.value
    );
    if (selected) {
      setSelectedmac({
        value: selected.mc_code,
        label: selected.mc_name,
      });
      setMcNo(selected.mc_code);
    }
  };

  const handleInformedToEmployeeChange = (selectedOption) => {
    const selected = Employee.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setInfTo({ value: selected.Emp_code, label: selected.Emp_name });
    }
  };
  const handleS1 = (selectedOption) => {
    const selected = Employee.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setS1({ value: selected.Emp_code, label: selected.Emp_name });
    }
  };
  const handleS2 = (selectedOption) => {
    const selected = Employee.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setS2({ value: selected.Emp_code, label: selected.Emp_name });
    }
  };
  const handleS3 = (selectedOption) => {
    const selected = Employee.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setS3({ value: selected.Emp_code, label: selected.Emp_name });
    }
  };

  const handleIncharge = (selectedOption) => {
    const selected = Employee.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setInCrg({ value: selected.Emp_code, label: selected.Emp_name });
    }
  };

  const handleTechS = (selectedOption) => {
    const selected = Employee.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setTechSign({ value: selected.Emp_code, label: selected.Emp_name });
    }
  };


  function handleFieldChange(code) {
    let values = partname.find((key) => key["mc_part_code"] === code);
    let datetime = new Date();
    // console.log(values);
    setPrtNme(values["mc_part_code"]);
    if (datetime.getHours() < 18 && datetime.getMinutes < 59) {
      setShft("B");
    } else {
      setShft("A");
    }
  }


  const handleUpdate = (e) => {
    e.preventDefault();

    if (!SerNo) {
      alert("Please fill the mandatory fields");
      return;
    }
    const data = {
      data: [
        {
          Id: Id,
          Format_no: FormtNo,
          Revision: Rev,
          Date: Date0,
          Dept: Dept,
          From1: Frm.value,
          Serial_No: SerNo,
          ERPNo: ERPNo,
          Inform_To: InfTo.value,
          Machine_No: McNo,
          Part_Name: PrtNme,
          Shift: Shft,
          Machine_Prob: MacPrb,
          ProProb: ProPrb,
          Pre_Status_Mac: PreStsM,
          Problem_Status: PrbSts,
          BreakdownType: BrkDwnT,
          Mach_Category: MacCat,
          NatOfProb: NofP,
          Anticipated_Cause: AntiFault,
          Date1: D1,
          Date2: D2,
          Date3: D3,
          Time1: T1,
          Time2: T2,
          Time3: T3,
          Sign1: S1.value,
          Sign2: S2.value,
          Sign3: S3.value,
          TotalHours: THrs,
          Status_Rect: SRec,
          Temporary: Temp,
          Permanent: Permn,
          Under_Obs: UnOb,
          Corrective_Action: CorrAct,
          Spare_Replaced: SprRep,
          Item_No: ItmNo,
          Size: Size,
          Cost: Cost,
          Incharge_Sign: InCrg.value,
          Technician_Sign: TechSign.value,
        },
      ],
    };
    data["_keyword_"] = "Mach_breakdown";
    data["secretkey"] = "2bf52be7-9f68-4d52-9523-53f7f267153b";
    axios
      .post(
        `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
        data
      )
      .then((response) => {
        setMacBD([...MacBD, response.data]);
        alert("Updation Successful");
        // window.location.reload();
        setButtonPopup1(false);
        setPaginationVisible(true);
        handleReadMacBreakDown();

      })
      .catch((error) => {
        // console.log(error);
        alert("Updation failed!");
        window.location.reload();

      });

  };

  const handleEdit = (e) => {
    setPaginationVisible(false);
    setButtonPopup1(true);
    setId(e.Id);
    setRev(e.Revision);
    setFormtNo(e.Format_no);
    setDate0(e.Date);
    setSerNo(e.Serial_No);
    setFrm({ value: e.From1, label: e.From2 });
    setDept(e.Dept);
    setInfTo({ value: e.Inform_To, label: e.Inform_To2 });
    setERPNo(e.ERPNo);
    setMcNo(e.Machine_No);
    setPrtNme(e.Part_Name);
    setShft(e.Shift);
    setMacPrb(e.Machine_Prob);
    setProPrb(e.ProProb);
    setNofP(e.NatOfProb);
    setAntiFault(e.Anticipated_Cause);
    setD1(e.Date1);
    setD2(e.Date2);
    setD3(e.Date3);
    setT1(e.Time1);
    setT2(e.Time2);
    setT3(e.Time3);
    setS1({ value: e.Sign1, label: e.Sign11 });
    setS2({ value: e.Sign2, label: e.Sign22 });
    setS3({ value: e.Sign3, label: e.Sign33 });
    setTHrs(e.TotalHours);
    setCorrAct(e.Corrective_Action);
    setSprRep(e.Spare_Replaced);
    setItmNo(e.Item_No);
    setSize(e.Size);
    setCost(e.Cost);
    setInCrg({ value: e.Incharge_Sign, label: e.Incharge_Sign1 });
    setTechSign({ value: e.Technician_Sign, label: e.Technician_Sign1 });
  };

  const handleView = (e) => {
    handleReadRecord(e.Id);
    setPaginationVisible(false);
    setButtonPopup(true);
    setRev(e.Revision);
    setFormtNo(e.Format_no);
    setDate0(e.Date);
    setSerNo(e.Serial_No);
    setFrm(`${e.From1}-${e.From2}`);
    setDept(e.Dept);
    setInfTo(`${e.Inform_To}-${e.Inform_To2}`);
    setERPNo(e.ERPNo);
    setMcNo(e.Machine_No);
    setPrtNme(e.PartName);
    setShft(e.Shift);
    // setMacPrb(e.Machine_Prob);
    setProPrb(e.ProProb);
    setPreStsM(e.Pre_Status_Mac);
    setPrbSts(e.Problem_Status);
    setBrkDwnT(e.BreakdownType);
    setMacCat(e.Mach_Category);
    setNofP(e.NatOfProb);
    setAntiFault(e.Anticipated_Cause);
    setD1(e.Date1);
    setD2(e.Date2);
    setD3(e.Date3);
    setT1(e.Time1);
    setT2(e.Time2);
    setT3(e.Time3);
    setS1(e.Sign11 != undefined ?`${e.Sign1}-${e.Sign11}`:"");
    setS2(e.Sign22 !=undefined ?`${e.Sign2}-${e.Sign22}`:"");
    setS3(e.Sign33 != undefined ?`${e.Sign3}-${e.Sign33}`:"");
    setTemp(e.Temporary);
    setPermn(e.Permanent);
    setUnOb(e.Under_Obs);
    setTHrs(e.TotalHours);
    setCorrAct(e.Corrective_Action);
    setSprRep(e.Spare_Replaced);
    setItmNo(e.Item_No);
    setSize(e.Size);
    setCost(e.Cost);
    setInCrg(`${e.Incharge_Sign}-${e.Incharge_Sign1}`);
    setTechSign(`${e.Technician_Sign}-${e.Technician_Sign1}`);
    setApproval(e.Approval_status);
    handleRecieve(e.Id);
    setId(e.Id);
    setTechSignature(e.Technician_Sign);
    setFromPerson(e.From1);
  };
  function handleRecieve(id) {
    ReadRecord({
      _keyword_: "Mach_breakdown_by_empId",
      key: id
    }).then((res) => {
      // console.log(res.data);
      setRecievingPerson(res.data[0].Inform_To);
    setReceived(res.data[0].Received);

    });
  }

  // const handleDelete = (e) => {
  //   setPaginationVisible(true);
  //   setButtonPopup2(true);
  //   setRev(e.Revision);
  //   setFormtNo(e.Format_no);
  //   setDate0(e.Date);
  //   setSerNo(e.Serial_No);

  // };

  const fromOptions = Employee.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const informToOptions = Employee.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const S1Options = Employee.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const S2Options = Employee.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const S3Options = Employee.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const InchargeOptions = Employee.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const TechSOptions = Employee.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  function GetSelectValue(value, keyword, setName) {
    if (value.length > 0) {
      ReadRecord({
        _keyword_: keyword,
        key: "%" + value + "%",
        value: "%" + value + "%"
      }).then((res) => {
        // console.log(res.data);
        setName(res.data);
        // setKey(keyvalue[options.reference]);
      });
    }
  }
  function handleReadMacBreakDown() {
    ReadRecord({
      _keyword_: "Mach_breakdown"
    }).then((res) => {
      // console.log(res.data);
      setMacBD(res.data);
    });
  }
  useEffect(() => {
    handleReadMacBreakDown();
  }, [])
  function handleReceived() {
    // e.preventDefault();
    setD2(CURRENT_Date);
    console.log("D1", D2);
    let currentTime = new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    setT2(currentTime);
    console.log("T2",T2);
    const userConfirmed = window.confirm('you have confirmed that you have Received!');
    if (userConfirmed) {
      UpdateRecord({
        _keyword_: 'Mach_breakdown',
        data: [
          {
            Id: Id,
            Date2:CURRENT_Date,
            Time2:currentTime,
            Received:"0",
            Sign2:localStorage.getItem("empId")
          },
        ],
      }).then(() => {
        setButtonPopup(false);
        setPaginationVisible(true);
        handleReadMacBreakDown();
        setMessage({
          NoteFrom: localStorage.getItem("empId"),
          NoteTo: FromPerson,
          NoteMessage:`${Frm},The problem ${ProPrb} for Machine ${McNo},${localStorage.getItem("empId")} has Received the Form.`,
          NoteRecordId: Id,
          TableName: "MacBD",
          NoteCreatedBy: localStorage.getItem("empId"),
        });

      })
    }
  }
  function handleComplete() {
    // e.preventDefault();
    setD3(CURRENT_Date);
    console.log("D1", D3);
    let currentTime = new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    setT3(currentTime);
    console.log("T2",T3);
    calculateTimeDifference(CURRENT_Date, currentTime);
    
  }
  const calculateTimeDifference = (D3, T3) => {
    const date1 = new Date(`${D1},${T1}`);
    const date2 = new Date(`${D2},${T2}`);
    const date3 = new Date(`${D3},${T3}`);
    console.log("date1",date1,date2,date3);
    // console.log("date2", (date2 - date1) / (1000 * 60));
    // console.log("date3", (date3 - date2) / (1000 * 60));
    let c1 = ((date3 - date2) + (date2 - date1)) / (1000 * 60);
    console.log("c1", c1);
    const totalHours = Math.floor(c1 / 60);
    const remainingMinutes = Math.floor(c1 % 60);

    // console.log("Converted time: ", `${totalHours} hours ${remainingMinutes} minutes`);
    let TotalCompletedHours=(`${totalHours} hrs ${remainingMinutes} mins`);
    console.log("Thrs",TotalCompletedHours);
    const userConfirmed = window.confirm('you have confirmed that you have completed the machine Problem!');
    if (userConfirmed) {
      UpdateRecord({
        _keyword_: 'Mach_breakdown',
        data: [
          {
            Id: Id,
            Date3:CURRENT_Date,
            Time3:currentTime,
            Received:"1",
            Sign3:localStorage.getItem("empId"),
            TotalHours:TotalCompletedHours
          },
        ],
      }).then(() => {
        setButtonPopup(false);
        setPaginationVisible(true);
        handleReadMacBreakDown();
        setMessage({
          NoteFrom: localStorage.getItem("empId"),
          NoteTo: FromPerson,
          NoteMessage:`${Frm},The problem ${ProPrb} for Machine ${McNo},has been completed.`,
          NoteRecordId: Id,
          TableName: "MacBD",
          NoteCreatedBy: localStorage.getItem("empId"),
        });
        setMessage({
          NoteFrom: localStorage.getItem("empId"),
          NoteTo: TechSignature,
          NoteMessage:`${TechSign},The problem ${ProPrb} for Machine ${McNo},has been completed. Please do verify and Approve the Form`,
          NoteRecordId: Id,
          TableName: "MacBD",
          NoteCreatedBy: localStorage.getItem("empId"),
        });
        CreateApproval({
          RequestedBy: localStorage.getItem("empId"),
          Approver: TechSignature,
          Level: 1,
          Status: "Waiting for Approval",
          TableName: "MacBD",
          TableID: Id,
          Description: `${TechSign},The problem ${ProPrb} for Machine ${McNo},has been completed. Please do verify and Approve the Form`,
          CreatedBy:localStorage.getItem("empId"),
        });
      })
    }
  };
  return (
    <div>
      <div>
        <Popup trigger={ButtonPopup} setTrigger={setButtonPopup} viewPagination={viewPagination}>
          <br></br>

          {Approver === localStorage.getItem("empId") ?

            (Approval === "0" ? <div>{back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}<span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#DA251A', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>This Form has been Rejected due to {RejectedReason}</span><span >Reason:{RejectedReason}</span></div> : Approval === "1" ? <div>{back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}<span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#B9A44C', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>It is Approved Already, Contact Administrator if any change needed </span></div> :
              back && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>
                <div style={{ display: 'flex', width: '25%', gap: '5', justifyContent: 'space-between', alignItems: 'center', marginRight: "10px" }}>
                  <button
                    className="icon"
                    style={{ backgroundColor: "#06802f", marginRight: '10px' }}
                    type="button"
                    onClick={(e) => {
                      approve(id, "MacBD", "Mach_breakdown")
                      navigate(-1)
                    }}
                  >
                    Approve
                  </button>
                  <button
                    className="icon"
                    style={{ backgroundColor: "#9d080f" }}
                    type="button"
                    onClick={() => { setcardId(id) }}
                  >
                    Reject
                  </button>
                  {id === cardId && <div><div style={{ backgroundColor: 'gray', filter: 'blur(8px)', position: 'absolute', opacity: '.8', right: '0%', bottom: '0%', width: '100%', height: '100%' }}></div><div style={{ position: 'absolute', right: '32%', color: 'white', bottom: '35%', padding: '20px', borderRadius: '10px' }}><h3>Remarks</h3><textarea rows={5} cols={50} onChange={e => { setremarks(e.target.value) }}></textarea><button onClick={e => { reject(id, "MacBD", remarks, "Mach_breakdown"); navigate(-1) }}>Submit</button><button onClick={() => { setcardId('') }}>Cancel</button></div></div>}
                </div>
              </div>) :
            (Approval === "0" ? <div>{back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}<span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#DA251A', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>This Form has been Rejected due to {RejectedReason}</span></div> : Approval === "1" ? <div>{back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}<span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#B9A44C', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>It is Approved Already, Contact Administrator if any change needed </span></div> : (back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>))
            // (back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>)

          }

          {/* {back&&<div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <div style={{fontSize:'16px',fontWeight:'bolder',backgroundColor:'#86BBd8', width:'90px',padding:'3px 10px',marginLeft:"20px",borderRadius:'10px',border:'2px solid #005C9F', cursor:'pointer'}} onClick={()=>{navigate(-1)}}><FaBackspace size={20}/> Back</div>
          <div style={{display:'flex',width:'25%',gap:'5',justifyContent:'space-between',alignItems:'center',marginRight:"10px"}}><button
                      className="icon"
                      style={{ backgroundColor: "#06802f" ,marginRight:'10px'}}
                      type="button"
                      onClick={(e) => {
                        approve(MacBD.Id,"MacBD")
                        navigate(-1)
                      }}
                    >
                      Approve
                    </button>
                    <button
                      className="icon"
                      style={{ backgroundColor: "#9d080f" }}
                      type="button"
                      onClick={() => {setcardId(MacBD.Id)}}
                    >
                      Reject
                    </button>
                    {MacBD.Id===cardId&&<div><div style={{backgroundColor:'gray',filter:'blur(8px)',position:'absolute',opacity:'.8',right:'0%',bottom:'0%',width:'100%',height:'100%'}}></div><div style={{position:'absolute',right:'32%',color:'white',bottom:'35%',padding:'20px',borderRadius:'10px'}}><h3>Remarks For Record No - {MacBD.Id}</h3><textarea rows={5} cols={50} onChange={e=>{setremarks(e.target.value)}}></textarea><button onClick={e=>{reject(MacBD.Id,"MacBD",remarks);navigate(-1)}}>Submit</button><button onClick={()=>{setcardId('')}}>Cancel</button></div></div>}
                    </div>
          </div>} */}


          <form id="Machbd-formContainer-View" className="form-Machbd-View">
            <table width="100%" style={{ textAlign: "center" }} border="0">
              <thead>
                <th
                  style={{
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    padding: "10px 0px",
                  }} colSpan="8">

                  <font color="#8B0000">VIEW - MACHINE BREAK DOWN RECTIFICATION REPORT</font>
                </th>
              </thead>
              <tr>
                <td className="topic-head">From<span style={{ color: "red" }}>*</span></td>
                <td>
                  <label>{Frm}</label>
                </td>
                <td colSpan={4}></td>



                <td className="topic-head" style={{ width: "11%" }}>Serial No:<span style={{ color: "red" }}>*</span></td>
                <td><label>{SerNo}</label></td></tr>
              <tr>

                <td className="topic-head" style={{ width: "11%" }}>Dept :<span style={{ color: "red" }}>*</span></td>
                <td>
                  <label>{Dept}</label>
                </td>
                <td className="topic-head">Informed To :<span style={{ color: "red" }}>*</span></td>
                <td colSpan={3}>
                  <label>{InfTo}</label></td>

                <td className="topic-head">ERP No :<span style={{ color: "red" }}>*</span></td>
                <td>
                  <label>{ERPNo}</label>
                </td>
              </tr>
              <tr>
                <td className="topic-head" colSpan={1}>Machine No :<span style={{ color: "red" }}>*</span></td>
                <td colSpan={1}>
                  <label>{McNo}</label>

                </td>
                <td className="topic-head" colSpan={1}>Part Name<span style={{ color: "red" }}>*</span></td>
                <td colSpan={3}>
                  <label>{PrtNme}</label>
                </td>
                <td className="topic-head" colSpan={1}>Shift</td>
                <td colSpan={1}>
                  <label>{Shft}</label>
                </td>
              </tr>
              <tr>
                <td colSpan={8}>
                  <span className="topic-head" style={{ marginLeft: "25px", float: "left", width: "50" }}> Problem :</span>
                  <label style={{ marginTop: "12px", marginRight: "750px", }}>{ProPrb}</label></td>

              </tr>
              <tr>
                <td colSpan={8}>
                  <br></br>
                  <span className="topic-head" style={{ marginRight: "100px" }}> &nbsp; &nbsp;Present Status of Machine :</span>
                  <label style={{ marginRight: "750px", }}>{PreStsM}</label>
                  <br></br>
                  <br></br>
                  <span className="topic-head" style={{ marginRight: "163px" }}> &nbsp; &nbsp;Problem Status :</span>
                  <label style={{ marginRight: "750px" }}>{PrbSts}</label>
                  <br></br>
                  <br></br>
                  <span className="topic-head" style={{ marginRight: "140px" }}>&nbsp;&nbsp;BREAK DOWN TYPE :</span>
                  <label style={{ marginRight: "750px" }}>{BrkDwnT}</label>
                  <br></br>
                  <br></br>
                  <span className="topic-head" style={{ marginRight: "150px" }}>&nbsp;&nbsp;Machine Category:</span>
                  <label style={{ marginRight: "750px" }}>{MacCat}</label>
                </td>
              </tr>
              <tr>
                <td className="topic-head" colSpan={2}>Nature Of Problem :</td>
                <td colSpan={6}>
                  <label style={{ marginTop: "2px", marginRight: "750px", }}>{NofP}</label>
                </td>
              </tr>
              <tr>
                <td className="topic-head" colSpan={2}>Anticipated / briefing the cause before happening fault :</td>
                <td colSpan={6}>
                  <label style={{ marginTop: "2px", marginRight: "750px", }}>{AntiFault}</label></td>
              </tr>
              <tr>
                <td></td>
                <td className="topic-head" style={{ textAlign: "center" }}>Informed</td>
                <td className="topic-head" style={{ textAlign: "center" }}>Received</td>
                <td className="topic-head" style={{ textAlign: "center" }}>Completed</td>
                <td className="topic-head" style={{ textAlign: "center" }}>Total Hours</td>
                <td colSpan={3} className="topic-head" style={{ textAlign: "center" }}>Status Of Rectification</td>
              </tr>
              <tr>
                <td className="topic-head">Date</td>
                <td>
                  <label>{D1 ?formatDt(D1):""}</label>
                </td>
                <td>
                  <label>{D2 ? formatDt(D2):""}</label>
                </td>
                <td>
                  <label>{D3?formatDt(D3):""}</label>
                </td>
                <td rowSpan={3} ><p><label>{THrs}</label></p></td>
                <td className="topic-head">Temporary</td>
                <td>
                  <label>{Temp}</label>
                </td>
                <td rowSpan={3} colSpan={1} style={{ textAlign: "center" }}><p>Open / Close</p></td>
              </tr>
              <tr><td className="topic-head">Time</td>
                <td>
                  <label>{T1}</label>
                </td>
                <td>
                  <label>{T2}</label>
                </td>
                <td>
                  <label>{T3}</label>
                </td>
                <td className="topic-head">Permanent</td>
                <td>
                  <label>{Permn}</label>
                </td>
              </tr>
              <tr>
                <td className="topic-head">Signature</td>
                <td>
                  <label>{S1}</label>
                </td>
                <td>
                  {RecievingPerson === localStorage.getItem("empId") && Received==null ? <button className="Received" type="button" onClick={() => { handleReceived() }}>Received</button> :
                    <label>{S2}</label>
                  }
                </td>
                <td>
                {RecievingPerson === localStorage.getItem("empId") && Received== "0" ? <button className="Received" type="button" onClick={() => { handleComplete() }}>Completed</button> :
                    <label>{S3}</label>
                  }
                </td>
                <td className="topic-head"> Under Observation </td>
                <td>
                  <label>{UnOb}</label>
                </td>
              </tr>
              <tr>
                <td className="topic-head" colSpan={1}>Corrective Action :</td>
                <td colSpan={7}>
                  <label style={{ marginRight: "1000px" }}>{CorrAct}</label></td>
              </tr>
              <tr>
                <td className="topic-head">Spare Replaced :</td>
                <td><label>{SprRep}</label></td>
                <td className="topic-head">Item No :</td>
                <td><label>{ItmNo}</label></td>
                <td className="topic-head">Size :</td>
                <td><label>{Size}</label></td>
                <td className="topic-head">Cost :</td>
                <td><label>{Cost}</label></td>
              </tr>
              <tr>
                <td className="topic-head">In-Charge Sign :</td>
                <td colSpan={2}><label>{InCrg}</label></td>
                <td className="topic-head">M/C Technician Sign</td>
                <td colSpan={2}><label>{TechSign}</label></td>
                <td></td>
              </tr>

            </table>
          </form>
        </Popup>
      </div>

      <div>
        <Popup trigger={ButtonPopup1} setTrigger={setButtonPopup1} viewPagination={viewPagination}>
          <form id="Machbd-formContainer-Edit" className="form-Machbd-Edit">
            <table>
              <thead>

                <th
                  style={{
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    padding: "10px 0px",
                  }} colSpan="8">

                  <font color="#8B0000">EDIT - MACHINE BREAK DOWN RECTIFICATION REPORT</font>
                </th>
                {/* <th colSpan={2}style={{ width: "30%"}}>              
           <span  style={{ marginRight: "225px", float: "left", width: "20%"}}>
            Format No :
          </span>
          <label>{"UPCL/PRO/R06"}</label>
         <span style={{ marginRight: "190px", float: "left", width: "30%"}}>
             Revision :
          </span>
        <label>{"01"}</label>
            
          <span style={{ marginRight: "200px", float: "left", width: "20"}}> Date:</span>
         <label>{"01.07.2017"}</label></th> */}
              </thead>
              <tr>
                <td className="topic-head">From : <span style={{ color: "red" }}>*</span></td>
                <td>
                  {/* <DropdownFetcher keyword="emp_details" setValue={setEmployee} /> */}
                  {Frm === '' && isCreating && <span className="Req">Required *</span>}
                  <Select
                    id={`${id}-EmployeeName`}
                    //value={{ value: selectedEmployee, label: Frm2 }}
                    value={Frm}
                    options={fromOptions}
                    // options={Employee.map((employee) => ({
                    //   value: employee.Emp_code,
                    //   label: employee.Emp_name,
                    // }))}
                    onChange={handleEmployeeChange}
                  onInputChange={(event) => { GetSelectValue(event, "emp_details_dropdown", setEmployee) }}
                  />

                </td>
                <td colSpan={4}></td>



                <td className="topic-head" style={{ width: "11%" }}>Serial No:<span style={{ color: "red" }}>*</span></td>
                <td>
                  {SerNo === '' && isCreating && <span className="Req">Required *</span>}
                  <input
                    id="mac.Cr.serNo" name="mac.Cr.serNo"
                    type="text"
                    value={SerNo}
                    onChange={(e) => setSerNo(e.target.value)}
                  //  disabled
                  /></td></tr>
              <tr>

                <td className="topic-head" style={{ width: "11%" }}>Dept :</td>

                <td className="topic-head">{Dept}</td>

                <td className="topic-head">Informed To :<span style={{ color: "red" }}>*</span></td>
                <td colSpan={3}>
                  {/* <DropdownFetcher keyword="emp_details" setValue={setname} /> */}
                  {InfTo === '' && isCreating && <span className="Req">Required *</span>}
                  <Select
                    id={`${id}-EmployeeName`}
                    value={InfTo}
                    options={informToOptions}
                    onChange={handleInformedToEmployeeChange}
                  onInputChange={(event) => { GetSelectValue(event, "emp_details_dropdown", setname) }}
                  /></td>

                <td className="topic-head">ERP No :<span style={{ color: "red" }}>*</span></td>
                <td>
                  {ERPNo === '' && isCreating && <span className="Req">Required *</span>}
                  <input
                    id="mac.Cr.erpNo" name="mac.Cr.erpNo"
                    type="number"
                    value={ERPNo}
                    onChange={(e) => setERPNo(e.target.value)} />
                </td>
              </tr>
              <tr>
                <td className="topic-head" colSpan={1}>Machine No :<span style={{ color: "red" }}>*</span></td>
                <td colSpan={1} >
                  <label>{McNo}</label>
                  {/* <DropdownFetcher keyword="mac_dtl_dropdown" setValue={setmacno} />
          <Select
          id={`${id}-EmployeeName`}
          value={{ value: selectedmac, label: McNo }}
         // value={selectedmac}
          options={macno.map((i) => ({
            value: i.mc_code,
            label: i.mc_name,
          }))}
         onChange={handleMachine}
         isDisabled
         /> */}

                </td>
                <td className="topic-head" colSpan={1}>Part Name : <span style={{ color: "red" }}>*</span></td>
                <td colSpan={3}>
                  {/* <DropdownFetcher keyword="mac_part_dtl_dropdown" setValue={setPartName} /> */}
                  {PrtNme === '' && isCreating && <span className="Req">Required *</span>}
                  <Select
                    id={`${id}-PrtName`}
                    value={{ value: PrtNme, label: PrtNme }}
                    options={partname.map((i) => ({
                      value: i.mc_part_code,
                      label: i.mc_part_name,
                    }))}
                    onChange={(event) => handleFieldChange(event.value)}
                    onInputChange={(event) => { GetSelectValue(event, "mac_part_dtl_dropdown", setPartName) }}
                  />
                </td>
                <td className="topic-head" colSpan={1}>Shift</td>
                <td colSpan={1}>
                  <select
                    id={`${id}-Shift`}
                    name={`${name}-Shift`}
                    value={Shft}
                    onChange={(e) => setShft(e.target.value)}
                    style={{ textAlign: "center" }}
                  >
                    <option
                      id={`${id}-Shift-Select`}
                      name={`${name}-Shift-Select`}
                      value=""
                    >
                      -- Select --
                    </option>
                    <option id={`${id}-ShiftA`} name={`${name}-ShiftA`} value="A">
                      A12
                    </option>
                    <option id={`${id}-ShiftB`} name={`${name}-ShiftB`} value="B">
                      B12
                    </option>
                  </select>
                </td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <span className="topic-head" style={{ marginRight: "10px", float: "left", width: "30" }}> Machine Problem :</span>
                  <input
                    className="Machbd-radio"
                    type="radio"
                    value="machine Problem"
                    checked={ProPrb === 'machine Problem'}
                    onChange={(e) => setProPrb(e.target.value)}

                  /></td>

                <td colSpan={4}>
                  <span className="topic-head" style={{ marginRight: "10px", float: "left", width: "30" }}>Production Problem : </span>
                  <input
                    className="Machbd-radio"
                    type="radio"
                    value="production Problem"
                    checked={ProPrb === 'production Problem'}
                    onChange={(e) => setProPrb(e.target.value)}
                  /></td>
              </tr>
              <tr>
                <td colSpan={8}>
                  <br></br>
                  <span className="topic-head" style={{ marginRight: "100px", padding: "20px" }}> Present Status of Machine :</span>
                  <input
                    className="Machbd-radio"
                    type="radio"
                    // value={PreStsM}
                    value="Running"
                    checked={PreStsM === 'Running'}
                    onChange={(e) => setPreStsM(e.target.value)}
                  />Running

                  <span style={{ marginRight: "100px", padding: "20px" }}></span>
                  <input
                    className="Machbd-radio"
                    type="radio"
                    value="Stopped"
                    checked={PreStsM === 'Stopped'}
                    onChange={(e) => setPreStsM(e.target.value)}
                  />Stopped

                  <br></br>
                  <br></br>
                  <span className="topic-head" style={{ marginRight: "165px", padding: "20px" }}>Problem Status :</span>
                  <input
                    checked={PrbSts === 'New'}
                    className="Machbd-radio"
                    type="radio"
                    value="New"

                    onChange={(e) => setPrbSts(e.target.value)}
                  />New

                  <span style={{ marginRight: "120px", padding: "20px" }}></span>
                  <input
                    className="Machbd-radio"
                    type="radio"
                    value="Repeated"
                    checked={PrbSts === 'Repeated'}
                    onChange={(e) => setPrbSts(e.target.value)}
                  />Repeated

                  <br></br>
                  <br></br>
                  <span className="topic-head" style={{ marginRight: "160px", padding: "10px" }}>BREAK DOWN TYPE :</span>
                  <input
                    className="Machbd-radio"
                    type="radio"
                    value="BreakDown"
                    checked={BrkDwnT === 'BreakDown'}
                    onChange={(e) => setBrkDwnT(e.target.value)}
                  />BreakDown

                  <span style={{ marginRight: "100px", padding: "10px" }}></span>
                  <input
                    className="Machbd-radio"
                    type="radio"
                    value="Plant Breakdown"
                    checked={BrkDwnT === 'Plant Breakdown'}
                    onChange={(e) => setBrkDwnT(e.target.value)}
                  />Plant Breakdown

                  <span style={{ marginRight: "140px", padding: "10px" }}></span>
                  <input
                    className="Machbd-radio"
                    type="radio"
                    value="PM"
                    checked={BrkDwnT === 'PM'}
                    onChange={(e) => setBrkDwnT(e.target.value)}
                  />PM

                  <br></br>
                  <br></br>
                  <span className="topic-head" style={{ marginRight: "160px", padding: "15px" }}>Machine Category:</span>
                  <input
                    className="Machbd-radio"
                    type="radio"
                    value="Machine"
                    checked={MacCat === 'Machine'}
                    onChange={(e) => setMacCat(e.target.value)}
                  />Machine

                  <span style={{ marginRight: "110px", padding: "15px" }}></span>
                  <input
                    className="Machbd-radio"
                    type="radio"
                    value="MTC"
                    checked={MacCat === 'MTC'}
                    onChange={(e) => setMacCat(e.target.value)}
                  />MTC

                  <span style={{ marginRight: "200px", padding: "15px" }}></span>
                  <input
                    className="Machbd-radio"
                    type="radio"
                    value="HRC"
                    checked={MacCat === 'HRC'}
                    onChange={(e) => setMacCat(e.target.value)}
                  />HRC

                  <span style={{ marginRight: "100px", padding: "15px" }}></span>
                  <input
                    className="Machbd-radio"
                    type="radio"
                    value="Hopper"
                    checked={MacCat === 'Hopper'}
                    onChange={(e) => setMacCat(e.target.value)}
                  />Hopper
                  <span style={{ marginRight: "90px", padding: "15px" }}></span>
                  <input
                    className="Machbd-radio"
                    type="radio"
                    value="Robo"
                    checked={MacCat === 'Robo'}
                    onChange={(e) => setMacCat(e.target.value)}
                  />Robo
                </td>
              </tr>
              <tr>
                <td className="topic-head" colSpan={2}>Nature Of Problem :</td>
                <td colSpan={6}><input type="text"
                  value={NofP}
                  onChange={(e) => setNofP(e.target.value)} /></td>
              </tr>
              <tr>
                <td className="topic-head" colSpan={2}>Anticipated / briefing the cause before happening fault :</td>
                <td colSpan={6}>
                  <textarea
                    rows="2"
                    cols="30"
                    value={AntiFault}
                    onChange={(e) => setAntiFault(e.target.value)}
                  /></td>
              </tr>
              <tr>
                <td></td>
                <td className="topic-head" style={{ textAlign: "center" }}>Informed</td>
                <td className="topic-head" style={{ textAlign: "center" }}>Received</td>
                <td className="topic-head" style={{ textAlign: "center" }}>Completed</td>
                <td className="topic-head" style={{ textAlign: "center" }}>Total Hours</td>
                <td colSpan={3} className="topic-head" style={{ textAlign: "center" }}>Status Of Rectification</td>
              </tr>
              <tr>
                <td className="topic-head">Date</td>
                <td>
                  <DatePicker value={D1} onChange={handleDateChange} />
                </td>
                <td>
                  <DatePicker value={D2} onChange={handleDateChange1} />
                </td>
                <td>
                  <DatePicker value={D3} onChange={handleDateChange2} />
                </td>
                <td rowSpan={3} ><p><input
                  // type="time"
                  type="text"
                  value={THrs}
                  onChange={(e) => setTHrs(e.target.value)} /></p></td>
                <td className="topic-head">Temporary</td>
                <td>
                  <select
                    value={Temp}
                    onChange={(e) => setTemp(e.target.value)}>
                    <option value="">Select</option>
                    <option value="open">Open</option>
                    <option value="close">Close</option>
                  </select>
                </td>
                <td rowSpan={3} colSpan={1} style={{ textAlign: "center" }}><p>Open / Close</p></td>
              </tr>
              <tr><td className="topic-head">Time</td>
                <td className="topic-head">{T1}</td>
                <td className="topic-head">{T2}</td>
                <td className="topic-head">{T3}</td>

                <td className="topic-head">Permanent</td>
                <td>
                  <select
                    value={Permn}
                    onChange={(e) => setPermn(e.target.value)}>
                    <option value="">Select</option>
                    <option value="open">Open</option>
                    <option value="close">Close</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td className="topic-head">Signature</td>
                <td>
                  {/* <DropdownFetcher keyword="emp_details" setValue={setname} /> */}
                  <Select
                    id={`${id}-EmployeeName`}
                    value={S1}
                    options={S1Options}
                    onChange={handleS1}
                  onInputChange={(event) => { GetSelectValue(event, "emp_details_dropdown", setname) }}
                  />
                </td>
                <td>
                  <Select
                    id={`${id}-EmployeeName`}
                    value={S2}
                    options={S2Options}
                    onChange={handleS2}
                  onInputChange={(event) => { GetSelectValue(event, "emp_details_dropdown", setname) }}
                  />
                </td>
                <td>
                  {/* <DropdownFetcher keyword="emp_details" setValue={setname} /> */}
                  <Select
                    id={`${id}-EmployeeName`}
                    value={S3}
                    options={S3Options}
                    onChange={handleS3}
                  onInputChange={(event) => { GetSelectValue(event, "emp_details_dropdown", setname) }}
                  />
                </td>
                <td className="topic-head"> Under Observation </td>
                <td>
                  <select
                    value={UnOb}
                    onChange={(e) => setUnOb(e.target.value)}>
                    <option value="">Select</option>
                    <option value="open">Open</option>
                    <option value="close">Close</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td className="topic-head" colSpan={1}>Corrective Action :</td>
                <td colSpan={7}>
                  <textarea
                    rows="2"
                    cols="30"
                    value={CorrAct}
                    onChange={(e) => setCorrAct(e.target.value)}
                  /></td>
              </tr>
              <tr>
                <td className="topic-head">Spare Replaced :</td>
                <td><input
                  type="text"
                  value={SprRep}
                  onChange={(e) => setSprRep(e.target.value)} /></td>
                <td className="topic-head">Item No :</td>
                <td><input
                  type="text"
                  value={ItmNo}
                  onChange={(e) => setItmNo(e.target.value)}
                /></td>
                <td className="topic-head">Size :</td>
                <td><input
                  type="text"
                  value={Size}
                  onChange={(e) => setSize(e.target.value)} /></td>
                <td className="topic-head">Cost :</td>
                <td><input
                  type="text"
                  value={Cost}
                  onChange={(e) => setCost(e.target.value)}
                /></td>
              </tr>
              <tr>
                <td className="topic-head">In-Charge Sign :</td>
                <td colSpan={2}>    
                {/* <DropdownFetcher keyword="emp_details" setValue={setname} /> */}
                  <Select
                    id={`${id}-EmployeeName`}
                    value={InCrg}
                    options={InchargeOptions}
                    onChange={handleIncharge}
                  onInputChange={(event) => { GetSelectValue(event, "emp_details_dropdown", setname) }}
                  /></td>
                <td className="topic-head">M/C Technician Sign</td>
                <td colSpan={2}>
                  {/* <DropdownFetcher keyword="emp_details" setValue={setname} /> */}
                  <Select
                    id={`${id}-techsign`}
                    name={`${id}-techsign`}
                    value={TechSign}
                    options={TechSOptions}
                    onChange={handleTechS}
                  onInputChange={(event) => { GetSelectValue(event, "emp_details_dropdown", setname) }}
                  /></td>
                <td></td>
              </tr>
              <tr>
                <td colSpan="14" style={{ border: "none" }}>
                  <button type="submit" id="mca.ed.submit" name="mca.ed.submit" onClick={(e) => handleUpdate(e)}>
                    Update
                  </button>
                </td>
              </tr>
            </table>
          </form>
        </Popup>
      </div>



      <div>
        <main>
          <table className="Machbd-list" >
            <thead>

              <tr>
                <th colSpan={11}
                  id="machbdcss-Title.List"
                  name="machbdcss-Title.List"

                  style={{ textAlign: "center", whiteSpace: "nowrap" }}
                >
                  <font color="#8B0000" >List - Machine Breakdown</font>
                </th>
              </tr>
              <tr>
                <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                  Date
                </td>
                <td colSpan="1">
                  <input
                    id="MacBD-filter-PartName"
                    name="MacBD-filter-PartName"
                    type="date"
                    placeholder="Filter by Date"
                    value={filterDate}
                    onChange={handleFilterDateChange}
                  />
                </td>
                <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                  Machine No
                </td>
                <td colSpan={2}>
                  <input
                    id="MacBD-filter-MachineNo"
                    name="MacBD-filter-MachineNo"
                    type="text"
                    placeholder="Filter MachineNo"
                    style={{ fontSize: "70%", width: "100%" }}
                    value={filterMcno}
                    onChange={handleFilterMcnoChange}
                  />
                </td>
                <td colSpan="8"></td>
              </tr>
              <tr>
                <th style={{ textAlign: "center" }}>Machine No</th>
                <th style={{ textAlign: "center" }}>Date Informed</th>
                <th style={{ textAlign: "center" }}>From</th>
                <th style={{ textAlign: "center" }}>Department</th>
                <th style={{ textAlign: "center" }}>Inform To</th>
                <th style={{ textAlign: "center" }}>Part Name</th>
                <th style={{ textAlign: "center" }}>Problem</th>
                <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                  Status
                </th>
                <th className="table-list-head"></th>
                <th className="table-list-head"></th>
                <th className="table-list-head"></th>
              </tr>
            </thead>
            <tbody>
              {/* <HandleRead keyword="Mach_breakdown" setData={setMacBD} /> */}
              {filteredRecords.length === 0 ? (
                // <tr>
                <td colSpan="11" style={{ textAlign: "center" }}>
                  <p style={{ fontWeight: "bold" }}>No records found.</p>
                </td>
              ) : (
                // </tr>
                filteredRecords.map((MacBD) => (
                  <tr className="table_view" key={MacBD.Id}>
                    <td className="table-cell">{MacBD.Machine_No}</td>
                    <td className="table-cell">{formatDt(MacBD.Date1)}</td>
                    <td className="table-cell">{MacBD.From2}</td>
                    <td className="table-cell">{MacBD.Dept}</td>
                    <td className="table-cell">{MacBD.Inform_To2}</td>

                    <td className="table-cell">{MacBD.PartName}</td>
                    <td className="table-cell">{MacBD.ProProb}</td>

                    <td
                      style={{

                        color: MacBD.Approval_status === "0" ? 'red' : MacBD.Approval_status === "1" ? 'green' : MacBD.Approval_status === undefined ? '#E59500' : "",

                      }}
                      className="table-cell"
                    >
                      &nbsp;&nbsp;{MacBD.Approval_status === undefined ? "Waiting for approval..." : MacBD.Approval_status === "1" ? "Approved" : MacBD.Approval_status === "0" ? "Rejected" : ""}
                    </td>
                    <td>
                      <button
                        style={{ backgroundColor: "#cba423" }}
                        type="button"
                        onClick={() => handleView(MacBD)}
                      >
                        View&#x1F441;
                      </button>
                    </td>
                    <td>
                      <button type="button" onClick={() => handleEdit(MacBD)}>
                        Edit&#x270E;
                      </button>
                    </td>
                    <td>
                      <button
                        id={`Mach_breakdown-Delete`}
                        name={`Mach_breakdown-Delete`}
                        style={{ whiteSpace: "nowrap", backgroundColor: "#DA251A" }}
                        type="button"
                        onClick={() => {
                          const userConfirmed = window.confirm('Are you sure you want to delete?');
                          if (userConfirmed) {
                            UpdateRecord({
                              _keyword_: 'Mach_breakdown',
                              data: [
                                {
                                  Id: MacBD.Id,
                                  ActiveStatus: 'InActive',
                                },
                              ],
                            }).then(() => {
                              handleReadMacBreakDown();
                            })
                            // window.location.reload();
                          }
                        }}
                      >
                        Delete<MdDelete />
                      </button>
                    </td>
                  </tr>
                )))}
            </tbody>
          </table>
          <div className="pagination" id="pagination">
            {isPaginationVisible && filteredData.length > 0 && (

              <Pagination currentPage={currentPage} npage={npage} prePage={prePage} nextPage={nextPage} changeCpage={changeCpage} numbers={numbers} />
            )}
          </div>
        </main>
      </div>
    </div>
  );
}
export default MacBDView;