export const getAPIRequest = async (url, method, bodyData) => {  
    if (bodyData != null) {
      return fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      });
    } else {
      return fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
      });
    }
  };