import axios from "axios"
import { PROCESS_TRANSACTION_UPDATE_URL, SECRET_KEY } from "../../Utils/constants"

const UpdateRecord = (KeyWord, input) => {

    return axios
        .post(PROCESS_TRANSACTION_UPDATE_URL, {
            data: input,
            _keyword_: KeyWord,
            secretkey: SECRET_KEY,
        })
        .then(res => {
            console.log("UpdateRecord",KeyWord,input,res.data);
            return res.data
        })
        .catch(error => {
            return { error: error }
        })
}
export default UpdateRecord