import React, { useEffect, useState } from "react";
import Lable from "../Fields/Lable";
import Field from "../Fields/Field";
import { useNavigate } from "react-router-dom";
import "./ListviewPaint.css";
import DeleteRecord from "../../Lib/API/DeleteRecord";
import Pagination from "./Pagination";
import { MdDelete} from "react-icons/md";
import LoadingPage from "../Handler/LoadingPage";
import DeleteRecordByDeleteId from "../../Lib/API/DeleteRecordByDeleteId";
import ReadRecordPaint from "../../Lib/API/ReadRecordPaint";
const ListViewPaint = ({
  Title,
  header,
  filter,
  quary,
  table,
  navigation,
  standard,
  tableDelete,
  dateFilter,
  DefaultDateFilter
}) => {
  const limit = 10;
  const [records, setrecords] = useState([{}]);
  const [Counts, setCounts] = useState(1);
  const [Quary, setQuary] = useState(CreateQuary());
  const [Filters, setFilters] = useState(() => {
    let fill = filter.map((item) => {
      return { [item]: "" };
    });
    fill.push({ start: "", end: "" });
    return fill;
  });
  const [FilterQuary, setFilterQuary] = useState("");
  const [Startdate, setStartdate] = useState("");
  const [Loading, setLoading] = useState(false);
  const [Selected, setSelected] = useState(0);

  const navigate = useNavigate();
  useEffect(() => {
    HandleFilter();
  }, []);

  useEffect(() => {
    console.log("records review", records);
  }, [records]);
  function CreateFilterQuary() {
    let quary = Quary;
    let alter = "";
    Filters.map((item, index) => {
      if (index != Filters.length - 1) {
        let key = Object.keys(item)[0];
        //console.log("check", Object.keys(item)[0]);
        if (item[key] === "") {
          // alter += "and " + key + " like '%' ";
        } else {
          alter += "and " + key + " like '%" + item[key] + "%' ";
        }
      }
    });

    if (dateFilter) {
      let date = Filters[Filters.length - 1];
      if (DefaultDateFilter?.start) {
        date.start = DefaultDateFilter?.start;
      }
      if (DefaultDateFilter?.end) {
        date.start = DefaultDateFilter?.end;
      }
      if (date.start === "") {
        if (date.end === "") {
        }  else if (date.start === date.end) {
          
          const startDateTime = date.start + " 00:00:00";
          const endDateTime = date.end + " 23:59:59";
          alter += `and ${dateFilter} BETWEEN '${startDateTime}' and '${endDateTime}' `;
        }else {
          alter += "and " + dateFilter + " <= '" + date.end + "' ";
        }
      } else {
        if (date.end === "") {
          alter += "and " + dateFilter + " >= '" + date.start + "' ";
        }  else if (date.start === date.end) {
          
          const startDateTime = date.start + " 00:00:00";
          const endDateTime = date.end + " 23:59:59";
          alter += `and ${dateFilter} BETWEEN '${startDateTime}' and '${endDateTime}' `;
        }else {
          alter +=
            "and " +
            dateFilter +
            " BETWEEN '" +
            date.start +
            " " +
            "00:00:00" +
            "' and '" +
            date.end +
            " " +
            "23:59:00" +
            "' ";
        }
      }
    }
    let filterQuary = "All";
    Filters.map((item, index) => {
      if (index != Filters.length - 1) {
        let key = Object.keys(item)[0];
        if (item[key] !== "") {
          filterQuary +=
            " > " +
            header
              .map((item) => (item.value === key ? item.label : false))
              .filter((item) => item != false)[0] +
            "=" +
            item[key];
        }
      }
    });
    setFilterQuary(filterQuary);

    return alter;
  }
  function HandleFilter() {
    let filter = CreateFilterQuary();
    runQuary(Quary.replace("Quary%", filter).replace("limit%", 0));

    getcount("where " + standard + " " + filter);
  }
  function ClearFilter() {
    setFilters(() => {
      let fill = filter.map((item) => {
        return { [item]: "" };
      });
      fill.push({ start: "", end: "" });
      return fill;
    });
    setFilterQuary("All");
    runQuary(Quary.replace("Quary%", "").replace("limit%", 0));
    getcount("where " + standard + " " + "");
  }

  function pageChange(currentPage) {
    let filter = CreateFilterQuary();
    runQuary(Quary.replace("Quary%", filter).replace("limit%", currentPage));

    getcount("where " + standard + " " + filter);
  }

  function runQuary(Quary) {
    setLoading((prev) => {
      prev = true;
      return prev;
    });
    ReadRecordPaint(Quary).then((res) => {
      console.log("runQuary", res, "quary", Quary);
      if (res.data[0].count === "0") {
        setrecords([]);
      } else {
        let display = res.data[0].rows;

        display.map((record) => {
          Object.keys(record).map((field) => {
            if (record[field] != null) {
              if (record[field].startsWith("[{")) {
                let rmcode = [];
                let rmname = [];
                JSON.parse(record[field]).map((subfield) => {
                  rmcode.push(subfield.value);
                  rmname.push(subfield.label);
                });
                record[field] = rmcode.toString();
                record["rm_name"] = rmname.toString();
              }
            }
          });
        });

        console.log("display", display);
        setrecords(display);
      }

      setLoading((prev) => {
        prev = false;
        return prev;
      });
    });
  }
  function getcount(quary) {
    ReadRecordPaint("Select count(Id) count from " + table + " " + quary).then(
      (res) => {
        //console.log("getcount", res, "Select count(Id) count from " + table + " " + quary);
        setCounts(
          Math.ceil(
            (res.data[0].rows[0] ? res.data[0].rows[0].count : 1) / limit
          )
        );
      }
    );
  }

  function CreateQuary() {
    let Quary = "";
    if (quary === undefined) {
      Quary += "SELECT ";
      header.map((item) => {
        Quary += item.value + ",";
      });
      Quary = Quary.substring(0, Quary.length - 1);
      Quary += " from " + table + " where ActiveStatus = 1 Quary%";
    } else {
      if (quary.includes("group by ")) {
        Quary = quary;
        Quary = Quary.split("group by ");
        Quary[1] = "group by " + Quary[1];
        Quary = Quary[0] + "where " + standard + " Quary% " + Quary[1];
      } else if (quary.includes("order by ")) {
        Quary = quary;
        Quary = Quary.split("order by ");
        Quary[1] = "order by " + Quary[1];
        Quary = Quary[0] + "where " + standard + " Quary% " + Quary[1];
      } else {
        Quary = quary + " where " + standard + " Quary% ";
      }
    }
    Quary += " limit " + limit + " offset limit%;";
    return Quary;
  }

  return (
    <div className="ListviewPaint">
      {console.log("tableDelete", tableDelete)}
      <table style={{ textAlign: "center", width: "100%" }} cellPadding="1">
        <thead>
          <tr>
            <th colSpan={header.length + 4}>
              <Lable type={"Head"} value={Title} />
            </th>
          </tr>
          <tr>
            <td colSpan={header.length + 3} style={{ textAlign: "center" }}>
              <div
                style={
                  dateFilter
                    ? {
                        display: "grid",
                        gridTemplateColumns: "35% 25% 25% 15%",
                        alignItems: "center",
                      }
                    : {
                        display: "grid",
                        gridTemplateColumns: "40% 40% 20%",
                        alignItems: "center",
                      }
                }
              >
                {dateFilter && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      borderRight: "1px solid",
                    }}
                  >
                    <label style={{ fontSize: "16px", paddingRight: "10px" }}>
                      Start
                    </label>
                    <input
                      type="date"
                      placeholder="Filter by Start Date"
                      value={Filters[Filters.length - 1].start}
                      onChange={(event) => {
                        const currentEndDate = Filters[Filters.length - 1].end;

                        if (
                          currentEndDate &&
                          event.target.value > currentEndDate
                        ) {
                          alert("Start date cannot be after end date");
                          return;
                        }

                        setFilters(() => {
                          let prev = [...Filters];
                          prev[Filters.length - 1].start = event.target.value;
                          return prev;
                        });
                      }}
                      style={{ marginRight: "10px", width: "150px" }}
                    />

                    <label
                      style={{
                        fontSize: "16px",
                        paddingRight: "10px",
                        paddingLeft: "30px",
                      }}
                    >
                      End
                    </label>
                    <input
                      type="date"
                      style={{ marginRight: "10px", width: "150px" }}
                      placeholder="Filter by End Date"
                      value={Filters[Filters.length - 1].end}
                      onChange={(event) => {
                        if (
                          event.target.value < Filters[Filters.length - 1].start
                        ) {
                          alert("End date cannot be before start date");
                          return;
                        }

                        setFilters(() => {
                          let prev = [...Filters];
                          prev[Filters.length - 1].end = event.target.value;
                          return prev;
                        });
                      }}
                    />
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                    borderRight: "1px solid",
                  }}
                >
                  <Field
                    name={Selected}
                    options={{
                      type: "dropdown",
                      values: filter.map(
                        (item) =>
                          header.filter((head) => head.value === item)[0]
                      ),
                    }}
                    value={filter}
                    OnChange={(e) => {
                      setSelected(
                        Filters.map((item, index) => {
                          if (Object.keys(item).includes(e.target.value))
                            return index;
                        }).filter((item) => item != undefined)[0]
                      );
                      setQuary(CreateQuary());
                    }}
                  />
                  <h3> = </h3>
                  <Field
                    name={filter[Selected]}
                    options={{ type: "text" }}
                    value={Filters[Selected]}
                    OnChange={(e) => {
                      setFilters(() => {
                        let prev = [...Filters];
                        prev[Selected][filter[Selected]] = e.target.value;
                        return prev;
                      });
                      // HandleFilter(e.target.value);
                    }}
                  />
                </div>
                <div
                  style={{
                    height: "100%",
                    borderRight: "1px solid",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      textAlign: "start",
                      paddingLeft: "5px",
                      paddingTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    {FilterQuary}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Field
                    label={"Filter"}
                    options={{ type: "Button" }}
                    onClick={() => {
                      HandleFilter();
                    }}
                  />
                  <Field
                    label={"Clear Filter"}
                    options={{ type: "Button" }}
                    onClick={() => {
                      ClearFilter();
                    }}
                  />
                </div>
              </div>
            </td>
          </tr>
          
          <tr>
            {header.map((item) => (
              <th key={item.value}>{item.label}</th>
            ))}
            <th colSpan={3}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Loading ? (
            <tr>
              <td colSpan={header.length + 3} style={{ textAlign: "center" }}>
                <LoadingPage size={"350px"} />
              </td>
            </tr>
          ) : records.length == "0" ? (
            <tr>
              <td colSpan={header.length + 3} style={{ textAlign: "center" }}>
                No Record Found
              </td>
            </tr>
          ) : (
            records.map((record) => (
              <tr key={record.Id + 1} className={"table_view"}>
                {/* {console.log("log for records", )} */}
                {header.map((item) => (
                  <td
                    key={item.value + 1}
                    style={
                      table === "machine_details md"
                        ? item.value.split(".")[1] == undefined
                          ? record[item.value] === "" ||
                            record[item.value] === null
                            ? { backgroundColor: "#de7c9b" }
                            : { backgroundColor: "white" }
                          : record[item.value.split(".")[1]] === "" ||
                            record[item.value] === null
                          ? { backgroundColor: "#de7c9b" }
                          : { backgroundColor: "white" }
                        : { backgroundColor: "white" }
                    }
                  >
                    {item.value.split(".")[1] == undefined ? (
                      item.value === "Approval_status" ? (
                        <div>
                          {record.Approval_status === "1"
                            ? "Approved"
                            : record.Approval_status === "0"
                            ? "Rejected"
                            : "Waiting For Approval"}
                        </div>
                      ) : (
                        <div>{record[item.value]}</div>
                      )
                    ) : item.value.split(".")[1] === "Approval_status" ? (
                      <div>
                        {record.Approval_status === "1"
                          ? "Approved"
                          : record.Approval_status === "0"
                          ? "Rejected"
                          : "Waiting For Approval"}
                      </div>
                    ) : (
                      <div>{record[item.value.split(".")[1]]}</div>
                    )}
                  </td>
                ))}
                <td style={{ width: "2%", whiteSpace: "nowrap" }}>
                  <button
                    className="view"
                    disabled={record.Id === null || record.Id === undefined}
                    onClick={() => {
                      navigate("/" + navigation + "/view/" + record.Id);
                    }}
                  >
                    View&#x1F441;
                  </button>
                </td>
                <td style={{ width: "2%", whiteSpace: "nowrap" }}>
                  <button
                    className="edit"
                    disabled={record.Id === null || record.Id === undefined}
                    onClick={() => {
                      console.log("Edit", navigation);
                      navigate("/" + navigation + "/edit/" + record.Id);
                    }}
                  >
                    Edit&#x270E;
                  </button>
                </td>
                <td style={{ width: "2%", whiteSpace: "nowrap" }}>
                  <button
                    className="delete"
                    disabled={record.Id === null || record.Id === undefined}
                    onClick={() => {
                      const userConfirmed = window.confirm(
                        "Are you sure you want to delete?"
                      );
                      if (userConfirmed) {
                        console.log("id",record.Id);
                        if (table.includes("paint")) {
                          DeleteRecordByDeleteId(tableDelete, record.Id).then(
                            (res) => {
                              HandleFilter("");
                            }
                          );
                        } 
                        else {
                          DeleteRecord(tableDelete, record.Id).then((res) => {
                            // alert("The Record is Deleted");
                            HandleFilter("");
                          });
                        }
                      }
                    }}
                  >
                    Delete
                    <MdDelete />
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <Pagination
        npage={Number(Counts)}
        pageChange={pageChange}
        limit={limit}
      />
    </div>
  );
};

export default ListViewPaint;
