import React, { useEffect, useState, useRef } from "react";
import Popup from "../../../Components/Popup/Popup";
import axios from "axios";
import { API_URL } from "../../../Utils/constants.js";
import Unitechlogo from "../../../icons/Unitechlogo.jpeg";
import { HoursMinutesSelect } from "../../../Lib/Hrs,Min.js";
import "../../../Styles/MCActRequestReport.css";
import Select from "react-select";
import HandleRead from "../../../Lib/HandleRead.js";
import Pagination from "../../../Components/Pagination.js";
import DatePicker from "../../../Lib/DatePicker.js";
import { formatDt } from "../../../Lib/formatDt.js";
import DropdownFetcher from "../../../Lib/DropdownFetcher.js";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import GetRecord from "../../../Lib/GetRecord";
import { FaBackspace } from 'react-icons/fa';
import reject from "../../../Lib/reject";
import approve from "../../../Lib/approve";
import UpdateRecord from "../../../Lib/updateRecord";
import { MdDelete } from "react-icons/md";
import ReadRecord from "../../../Lib/API/ReadRecord.js";

function MCActRequestReportView() {
  const scrollTop = useRef(null);
  const handleDateChange = (newDate) => {
    setDate1(newDate);
  };
  const handleDateChange1 = (newDate) => {
    setDate0(newDate);
  };
  const [Id, setId] = useState("");
  const [ForNo, setForNo] = useState("");
  const [RevN, setRevN] = useState("");
  const [Date0, setDate0] = useState("");
  const [ERPReq, setERPReq] = useState("");
  const [prb, setprb] = useState("");

  const [Date1, setDate1] = useState("");
  const [SftTm, setSftTm] = useState("");
  const [BAY, setBay] = useState("");
  const [MacNo, setMacNo] = useState("");
  const [preSOM, setpreSOM] = useState("");
  const [customerName, setcustomerName] = useState("");
  const [Model, setModel] = useState("");
  const [MouldNo, setMouldNo] = useState("");
  const [MouldNm, setMouldNm] = useState("");
  const [Prio, setPrio] = useState("");
  const [NofPrb, setNofPrb] = useState("");
  const [AppCV, setAppCV] = useState("");
  const [RootCA, setRootCA] = useState("");
  const [MTC, setMTC] = useState("");
  const [CycTm, setCycTm] = useState("");
  const [WhyWhy, setWhyWhy] = useState("");
  // const [Mould, setMould] = useState("");
  const [Alrm, setAlrm] = useState("");
  const [Cusion, setCusion] = useState("");
  const [InjP, setInjP] = useState("");
  const [EjP, setEjP] = useState("");
  const [Speed, setSpeed] = useState("");
  const [Temp, setTemp] = useState("");
  const [LimitC, setLimitC] = useState("");
  const [Press, setPress] = useState("");
  const [Methd, setMethd] = useState("");
  const [RootC, setRootC] = useState("");
  const [CorrAct, setCorrAct] = useState("");
  const [SpareD, setSpareD] = useState("");
  const [Cost, setCost] = useState("");
  const [Stock, setStock] = useState("");
  const [ERPReqS, setERPReqS] = useState("");
  const [TWhrs, setTWhrs] = useState("");
  const [selectedHours, setSelectedHours] = useState(0);
  const [selectedMinutes, setSelectedMinutes] = useState(0);
  const [PRP1, setPRP1] = useState("");
  const [PRP2, setPRP2] = useState("");
  const [PRP3, setPRP3] = useState("");
  const [StsORec, setStsORec] = useState("");
  const [QP1, setQP1] = useState("");
  const [QP2, setQP2] = useState("");
  const [QP3, setQP3] = useState("");
  const [ReqBy, setReqBy] = useState("");
  const [SignBy, setSignBy] = useState("");
  const [APRBy, setAPRBy] = useState("");
  const [ProHd, setProHd] = useState("");
  const [Qty, setQty] = useState("");
  const [Purc, setPurc] = useState("");
  const [PlHd, setPlHd] = useState("");
  const [Rsn, setRsn] = useState("");
  const [frm, setFrm] = useState("");

  const [ModelNumber, setModelNumber] = useState("");
  const [MouldName, setMouldName] = useState([]);
  const [Mldname, setMldname] = useState("");
  const [Approval, setApproval] = useState("");
  const [Approver, setApprover] = useState("");
  const [RejectedReason, setRejectedReason] = useState("");
  const [machine, setmachine] = useState([]);
  const [Customer, setCustomer] = useState([]);
  const [Employee, setEmployee] = useState([]);

  var [mca_req, setmca_req] = useState([]);

  const [isPaginationVisible, setPaginationVisible] = useState(true);

  const [ButtonPopup, setButtonPopup] = useState(false);
  const [ButtonPopup1, setButtonPopup1] = useState(false);
  const [ButtonPopup2, setButtonPopup2] = useState(false);
  const { id } = useParams();
  const [back, setback] = useState(false);
  const navigate = useNavigate();
  const [cardId, setcardId] = useState('');
  const [remarks, setremarks] = useState('');

  useEffect(() => {
    console.log(id);
    if (id !== undefined) {
      GetRecord(
        {
          _keyword_: "Get_MouldCorrective_by_id",
          id: id,
        },
        setmca_req
      ).then(async (res) => {
        console.log("res - ", res);
        handleView(res[0])
        setButtonPopup(true)
        setback(true)

        await timeout(1000);
        const popupDiv = document.querySelector(".popup");
        if (popupDiv) {
          const elementInsidePopup = popupDiv.querySelector("#close-btn");
          elementInsidePopup.style.display = "none";
        }
      })
    }
  }, [id])
  function handleReadRecord(id) {
    ReadRecord({
      _keyword_: "Get_approver_by_empId",
      TableID: id,
      TableName: "MCActRequestReport",
      Approver: localStorage.getItem("empId"),
    }).then((res) => {
      // console.log("res.data,",res);
      if (res.rows > 0) {
        setApprover(res.data[0].Approver);
      } else {
        setApprover("");
      }
    }).then(() => {
      ReadRecord({
        _keyword_: "Get_approver_ApprovalRemark",
        TableID: id,
        TableName: "MCActRequestReport"
      }).then((res) => {
        if (res.rows > 0) {
          setRejectedReason(res.data[0].ApprovalRemark);
        } else {
          setRejectedReason("");
        }
      })
    })
  }
  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }


  const handleHoursChange = (hours) => {
    setSelectedHours(hours);
    setTWhrs(`${hours}:${selectedMinutes}`);
  };

  const handleMinutesChange = (minutes) => {
    setSelectedMinutes(minutes);
    setTWhrs(`${selectedHours}:${minutes}`);
  }
  const handleEdit2 = (e) => {
    setId(e.Id);
    setRsn(e.Reason);
  };


  const handleUpdate = (e) => {
    e.preventDefault();

    if (!ERPReq || !MouldName || !MacNo || !Customer || !ReqBy || !SignBy || !APRBy || !ProHd || !Qty || !Purc || !PlHd) {

      alert("Please fill the mandatory fields");

      //  setIsCreating(true);
      if (scrollTop.current) {
        scrollTop.current.scrollIntoView({ behavior: "smooth" });
      }
      return;
    }
    const data = {
      data: [
        {
          Id: Id,
          RDate: Date0,
          Erp_req_no: ERPReq,
          Prbs: prb,
          M_date: Date1,
          Shift_time: SftTm,
          Bay: BAY,
          Machine_no: MacNo.value,
          Machine_status: preSOM,
          Customer: customerName.value,
          Model: Model,
          Mould_name: Mldname.value,
          Mould_number: ModelNumber,
          Priority: Prio,
          N_of_prob: NofPrb,
          Approx_cost: AppCV,
          Root_causeA: RootCA,
          Mtc: MTC,
          Cycle_time: CycTm,
          Mould: WhyWhy,
          Alarm: Alrm,
          Cusion: Cusion,
          Injection_pressure: InjP,
          Ejection_pressure: EjP,
          Speed: Speed,
          Temperature: Temp,
          Limit_switch_connection: LimitC,
          Pressure: Press,
          Root_cause: RootC,
          Corrective_action: CorrAct,
          Spare_details: SpareD,
          Cost: Cost,
          Stock: Stock,
          Erp_req_status: ERPReqS,
          Total_working_hours: TWhrs,
          Prod_points1: PRP1,
          Prod_points2: PRP2,
          Prod_points3: PRP3,
          Quality_points1: QP1,
          Quality_points2: QP2,
          Quality_points3: QP3,
          Rectification_status: StsORec,
          Requested_by: ReqBy.value,
          Tech_sign: SignBy.value,
          Tool_hod_approval: APRBy.value,
          Production_hod: ProHd.value,
          Quality: Qty.value,
          Purchase: Purc.value,
          Plant_head_approval: PlHd.value,
          Reason: Rsn,
        },
      ],
    };
    data["_keyword_"] = "MouldCorrective";
    data["secretkey"] = "2bf52be7-9f68-4d52-9523-53f7f267153b";
    axios
      .post(
        `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
        data
      )
      .then((response) => {
        console.log(response.data);
        setmca_req([...mca_req, response.data]);
        alert("Updation Successfull");
        // window.location.reload();
        setButtonPopup1(false);
        handleReadMctActReqReport();
        setPaginationVisible(true);

      }).catch((error) => {
        console.log(error);
        alert("Updation Failed!");
        window.location.reload();
      });
  };
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 20;

  const [filterDate, setFilterDate] = useState("");
  const [filterMcno, setFilterMcno] = useState("");
  const filterRecords = () => {
    //   const filteredData = mca_req; 
    const filteredData = mca_req.filter((record) => {
      const dateMatch = record.M_date && record.M_date.includes(filterDate);
      const mcnoMatch =
        record.Model &&
        record.Model.toLowerCase().includes(filterMcno.toLowerCase());
      return dateMatch && mcnoMatch;
    });
    return filteredData;
  };

  const filteredData = filterRecords();
  const npage = Math.ceil(filteredData.length / recordPerPage);
  const numbers = [...Array(npage).keys()].map((num) => num + 1);

  function handleFilterDateChange(event) {
    setFilterDate(event.target.value);
    setCurrentPage(1);
  }

  function handleFilterMcnoChange(event) {
    setFilterMcno(event.target.value);
    setCurrentPage(1);
  }

  const firstIndex = (currentPage - 1) * recordPerPage;
  const lastIndex = firstIndex + recordPerPage;
  const filteredRecords = filteredData.slice(firstIndex, lastIndex);
  function prePage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function nextPage() {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function changeCpage(Id) {
    setCurrentPage(Id);
  }
  const handleEdit = (e) => {
    setPaginationVisible(false);
    setButtonPopup1(true);
    setId(e.Id);
    setDate0(e.Date);
    setDate1(e.M_date);
    setERPReq(e.Erp_req_no);
    setprb(e.Prbs);
    setModel(e.Model);
    setModelNumber(e.Mould_number);
    setMldname({ value: e.Mould_name, label: e.Mould_name });
    setMouldNm(e.Mould_name);
    setSftTm(e.Shift_time);
    setBay(e.Bay);
    setMacNo({ value: e.Machine_no, label: e.Machine_no });
    setcustomerName({ value: e.Customer, label: e.Customer });
    setPrio(e.Priority);
    setNofPrb(e.N_of_prob);
    setAppCV(e.Approx_cost);
    setRootCA(e.Root_causeA);
    setMTC(e.Mtc);
    setAlrm(e.Alarm);
    setInjP(e.Injection_pressure);
    setEjP(e.Ejection_pressure);
    setLimitC(e.Limit_switch_connection);
    setCycTm(e.Cycle_time);
    setCusion(e.Cusion);
    setSpeed(e.Speed);
    setTemp(e.Temperature);
    setPress(e.Pressure);
    setWhyWhy(e.Mould);
    setpreSOM(e.Machine_status);
    setRootC(e.Root_cause);
    setCorrAct(e.Corrective_action);
    setSpareD(e.Spare_details);
    setCost(e.Cost);
    setStock(e.Stock);
    setERPReqS(e.Erp_req_status);
    setTWhrs(e.Total_working_hours);
    setPRP1(e.Prod_points1);
    setPRP2(e.Prod_points2);
    setPRP3(e.Prod_points3);
    setQP1(e.Quality_points1);
    setQP2(e.Quality_points2);
    setQP3(e.Quality_points3);
    setStsORec(e.Rectification_status);
    setReqBy({ value: e.Requested_by, label: e.RequestedBy });
    setSignBy({ value: e.Tech_sign, label: e.TechSign });
    setAPRBy({ value: e.Tool_hod_approval, label: e.ToolHodApproval });
    setProHd({ value: e.Production_hod, label: e.ProductionHod });
    setQty({ value: e.Quality, label: e.Quality1 });
    setPurc({ value: e.Purchase, label: e.Purchase1 });
    setPlHd({ value: e.Plant_head_approval, label: e.PlantHeadApproval });
    //setReqBy(e.Requested_by);
    //setSignBy(e.Tech_sign);
    //setAPRBy(e.Tool_hod_approval);
    // setProHd(e.Production_hod);
    //setQty(e.Quality);
    // setPurc(e.Purchase);
    //  setPlHd(e.Plant_head_approval);
    setDate0(e.RDate);
    setRsn(e.Reason);
  };

  const handleView = (e) => {
    handleReadRecord(e.Id);
    setPaginationVisible(false);
    setButtonPopup(true);
    setId(e.Id);
    setDate0(e.Date);
    setDate1(e.M_date);
    setERPReq(e.Erp_req_no);
    setprb(e.Prbs);
    setModel(e.Model);
    setMouldNo(e.Mould_number);
    setSftTm(e.Shift_time);
    setBay(e.Bay);
    setMacNo(e.Machine_no);
    setMldname(e.Mould_name);
    // setMouldNm(e.PartName);
    setcustomerName(e.Customer);
    setPrio(e.Priority);
    setNofPrb(e.N_of_prob);
    setAppCV(e.Approx_cost);
    setRootCA(e.Root_causeA);
    setMTC(e.Mtc);
    setAlrm(e.Alarm);
    setInjP(e.Injection_pressure);
    setEjP(e.Ejection_pressure);
    setLimitC(e.Limit_switch_connection);
    setCycTm(e.Cycle_time);
    setCusion(e.Cusion);
    setSpeed(e.Speed);
    setTemp(e.Temperature);
    setPress(e.Pressure);
    setWhyWhy(e.Mould);
    setpreSOM(e.Machine_status);
    setRootC(e.Root_cause);
    setCorrAct(e.Corrective_action);
    setSpareD(e.Spare_details);
    setCost(e.Cost);
    setStock(e.Stock);
    setERPReqS(e.Erp_req_status);
    setTWhrs(e.Total_working_hours);
    setPRP1(e.Prod_points1);
    setPRP2(e.Prod_points2);
    setPRP3(e.Prod_points3);
    setQP1(e.Quality_points1);
    setQP2(e.Quality_points2);
    setQP3(e.Quality_points3);
    setStsORec(e.Rectification_status);
    setReqBy(`${e.Requested_by}-${e.RequestedBy}`);
    setSignBy(`${e.Tech_sign}-${e.TechSign}`);
    setAPRBy(`${e.Tool_hod_approval}-${e.ToolHodApproval}`);
    setProHd(`${e.Production_hod}-${e.ProductionHod}`);
    setQty(`${e.Quality}-${e.Quality1}`);
    setPurc(`${e.Purchase}-${e.Purchase1}`);
    setPlHd(`${e.Plant_head_approval}-${e.PlantHeadApproval}`);
    setDate0(e.RDate);
    setRsn(e.Reason);
    setApproval(e.Approval_status);
  };
  const handleChange = () => {
    const data = {
      data: [
        {
          Format_no: ForNo,
          Rev_no: RevN,
          RDate: Date0,
          Erp_req_no: ERPReq,
          M_date: Date1,
          Machine_no: MacNo,
          Machine_status: preSOM,
          Customer: customerName.value,
          Model: Model,
          Mould_name: MouldNm,
          Mould_number: MouldNo,
          N_of_prob: NofPrb,
        },
      ],
    };
    data["_keyword_"] = "MouldCorrective";
    data["secretkey"] = "2bf52be7-9f68-4d52-9523-53f7f267153b";
    axios
      .post(
        "http://localhost:4040/GenericTransactionService/processTransactionForUpdate",
        data
      )
      .then((response) => {
        setmca_req([...mca_req, response.data]);
        setId("");
        setERPReq("");
        setModel("");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleDelete = (e) => {
    setPaginationVisible(true);
    setButtonPopup2(true);
    setId(e.Id);
    setModel(e.Model);
    setERPReq(e.erp_req_no);
  };



  function GetSelectValue(value, keyword, setName) {

    if (value.length > 0) {
      ReadRecord({
        _keyword_: keyword,
        key: "%" + value + "%",
        value: "%" + value + "%"
      }).then((res) => {
        console.log(res.data);
        setName(res.data);
        // setKey(keyvalue[options.reference]);
      });
    }
  }



  const viewPagination = () => {
    setPaginationVisible(true);
  };

  // const handleReqByName = (selectedOption) => {
  //   const selected = Employee.find(
  //     (option) => option.Emp_code === selectedOption.value
  //   );

  //   if (selected) {
  //     setReqByName({ value: selected.Emp_code, label: selected.Emp_name });
  //   }
  // };

  const handleMouldName = (selectedOption) => {
    const selected = MouldName.find(
      (option) => option.mc_part_code === selectedOption.value
    );

    if (selected) {
      setMldname({ value: selected.mc_part_code, label: selected.mc_part_name });
      setModelNumber(selected.mc_part_code);
      setModel(selected.mc_part_flex_01);
    }
  };
  const handleMachine = (selectedOption) => {
    const selectedMachine = machine.find(
      (option) => option.mc_code === selectedOption.value
    );

    if (selectedMachine) {
      setMacNo({ value: selectedOption.value, label: selectedOption.label });
      setBay(selectedMachine.mc_flex_01);
    }
  };
  const handleCustomer = (selectedOption) => {
    const selected = Customer.find(
      (option) => option.Cus_code === selectedOption.value
    );

    if (selected) {
      setcustomerName({
        value: selected.Cus_code,
        label: selected.Cus_name,
      });
    }
  };
  const handleEmployee = (selectedOption) => {
    const selected = Employee.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setReqBy({
        value: selected.Emp_code,
        label: selected.Emp_name,
      });
    }
  };

  const determineShift = () => {
    const datetime = new Date();
    if (datetime.getHours() < 18 && datetime.getMinutes() < 59) {
      return "A12";
    } else {
      return "B12";
    }
  };
  function handleReadMctActReqReport() {
    ReadRecord({
      _keyword_: "MouldCorrective"
    }).then((res) => {
      // console.log(res.data);
      setmca_req(res.data);
    });
  }
  useEffect(() => {
    handleReadMctActReqReport();
  }, [])
  return (
    <div>
      <div>
        <Popup
          trigger={ButtonPopup}
          setTrigger={setButtonPopup}
          viewPagination={viewPagination}
        >
          <br></br>
          {/* {back&&<div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <div style={{fontSize:'16px',fontWeight:'bolder',backgroundColor:'#86BBd8', width:'90px',padding:'3px 10px',marginLeft:"20px",borderRadius:'10px',border:'2px solid #005C9F', cursor:'pointer'}} onClick={()=>{navigate(-1)}}><FaBackspace size={20}/> Back</div>
          <div style={{display:'flex',width:'25%',gap:'5',justifyContent:'space-between',alignItems:'center',marginRight:"10px"}}><button
                      className="icon"
                      style={{ backgroundColor: "#06802f" ,marginRight:'10px'}}
                      type="button"
                      onClick={(e) => {
                        approve(mca_req.Id,"mca_req")
                        navigate(-1)
                      }}
                    >
                      Approve
                    </button>
                    <button
                      className="icon"
                      style={{ backgroundColor: "#9d080f" }}
                      type="button"
                      onClick={() => {setcardId(mca_req.Id)}}
                    >
                      Reject
                    </button>
                    {mca_req.Id===cardId&&<div><div style={{backgroundColor:'gray',filter:'blur(8px)',position:'absolute',opacity:'.8',right:'0%',bottom:'0%',width:'100%',height:'100%'}}></div><div style={{position:'absolute',right:'32%',color:'white',bottom:'35%',padding:'20px',borderRadius:'10px'}}><h3>Remarks For Record No - {mca_req.Id}</h3><textarea rows={5} cols={50} onChange={e=>{setremarks(e.target.value)}}></textarea><button onClick={e=>{reject(mca_req.Id,"mca_req",remarks);navigate(-1)}}>Submit</button><button onClick={()=>{setcardId('')}}>Cancel</button></div></div>}
                    </div>
          </div>} */}
          {/* {Approval === "0" ? <div> {back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}<span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#DA251A', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>This Form has been Rejected due to {RejectedReason}</span></div> : Approval === "1" ? <div>{back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}<span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#B9A44C', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>It is Approved Already, Contact Administrator if any change needed </span></div> :
            back && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>
              <div style={{ display: 'flex', width: '25%', gap: '5', justifyContent: 'space-between', alignItems: 'center', marginRight: "10px" }}><button
                className="icon"
                style={{ backgroundColor: "#06802f", marginRight: '10px' }}
                type="button"
                onClick={(e) => {
                  approve(id, "MCActRequestReport", "MouldCorrective")
                  navigate(-1)
                }}
              >
                Approve
              </button>
                <button
                  className="icon"
                  style={{ backgroundColor: "#9d080f" }}
                  type="button"
                  onClick={() => { setcardId(id) }}
                >
                  Reject
                </button>
                {id === cardId && <div><div style={{ backgroundColor: 'gray', filter: 'blur(8px)', position: 'absolute', opacity: '.8', right: '0%', bottom: '0%', width: '100%', height: '100%' }}></div><div style={{ position: 'absolute', right: '32%', color: 'white', bottom: '35%', padding: '20px', borderRadius: '10px' }}><h3>Remarks</h3><textarea rows={5} cols={50} onChange={e => { setremarks(e.target.value) }}></textarea><button onClick={e => { reject(id, "MCActRequestReport", remarks, "MouldCorrective"); navigate(-1) }}>Submit</button><button onClick={() => { setcardid('') }}>Cancel</button></div></div>}
              </div>
            </div>} */}
          {Approver === localStorage.getItem("empId") ?

            (Approval === "0" ? <div>{back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}<span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#DA251A', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>This Form has been Rejected due to {RejectedReason}</span></div> : Approval === "1" ? <div>{back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}<span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#B9A44C', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>It is Approved Already, Contact Administrator if any change needed </span></div> :
              back && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>
                <div style={{ display: 'flex', width: '25%', gap: '5', justifyContent: 'space-between', alignItems: 'center', marginRight: "10px" }}>
                  <button
                    className="icon"
                    style={{ backgroundColor: "#06802f", marginRight: '10px' }}
                    type="button"
                    onClick={(e) => {
                      approve(id, "MCActRequestReport", "MouldCorrective")
                      navigate(-1)
                    }}
                  >
                    Approve
                  </button>
                  <button
                    className="icon"
                    style={{ backgroundColor: "#9d080f" }}
                    type="button"
                    onClick={() => { setcardId(id) }}
                  >
                    Reject
                  </button>
                  {id === cardId && <div><div style={{ backgroundColor: 'gray', filter: 'blur(8px)', position: 'absolute', opacity: '.8', right: '0%', bottom: '0%', width: '100%', height: '100%' }}></div><div style={{ position: 'absolute', right: '32%', color: 'white', bottom: '35%', padding: '20px', borderRadius: '10px' }}><h3>Remarks</h3><textarea rows={5} cols={50} onChange={e => { setremarks(e.target.value) }}></textarea><button onClick={e => { reject(id, "MCActRequestReport", remarks, "MouldCorrective"); navigate(-1) }}>Submit</button><button onClick={() => { setcardId('') }}>Cancel</button></div></div>}
                </div>
              </div>) :
            (Approval === "0" ? <div>{back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}<span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#DA251A', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>This Form has been Rejected due to {RejectedReason}</span></div> : Approval === "1" ? <div>{back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}<span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#B9A44C', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>It is Approved Already, Contact Administrator if any change needed </span></div> : (back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>))
            // (back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>)

          }
          <form
            id="mcareq-formContainer-View"
            name="mcareq-formContainer-View"
            className="form-mcareq-View"
          >
            <table style={{ textAlign: "center", width: "100%" }}>
              <tbody>
                <tr>
                  {/* <th style={{ textAlign: "center", width: "10%" }}>
                    <img src={Unitechlogo} style={{ width: "40px" }}></img>
                  </th> */}
                  <th
                    colSpan={7}
                    style={{
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      padding: "10px 0px",
                      color: "#8B0000"
                    }}
                  >
                    View - Mould Corrective Action Request Report
                  </th>
                  {/* <th colSpan={2}>
                <span
                  style={{ marginRight: "135px", float: "left", width: "30%" }}
                >
                  FORMAT NO :
                </span>
                <label>{"UPCL/PRO/R06"}</label>
                <span
                  style={{ marginRight: "133px", float: "left", width: "30%" }}
                >
                  REV NO :
                </span>
                <label>{"02"}</label>
                
                <span
                  style={{ marginRight: "135px", float: "left", width: "30%" }}
                >
                  DATE :
                </span>
                <label>{"05.01.2021"}</label>
              </th> */}
                </tr>

                <tr>
                  <td colSpan={5} className="topic-head">
                    <span style={{
                      marginRight: "5px",
                      float: "left",
                      width: "10%",
                    }}>Problem :</span>
                    <label style={{
                      marginRight: "500px",
                      float: "left",
                      width: "10%",
                    }}>{prb}</label>
                    {/* <input
                      className="Machbd-checkbox"
                      type="checkbox"
                      checked={prb}
                      onChange={(e) => setprb(e.target.checked ? "YES" : "")}
                      disabled
                    /> */}
                    {/* </td>
                  <td colSpan={2} className="topic-head">
                    {" "}
                    Production Problem
                    <label>{prb}</label> */}
                    {/* <input
                      className="Machbd-checkbox"
                      type="checkbox"
                      checked={prb}
                      onChange={(e) => setprb(e.target.checked ? "YES" : "")}
                      disabled
                    /> */}
                    {/* </td>
                  <td colSpan={2} className="topic-head">
                    {" "}
                    FLASH PROBLEM
                    <label>{prb}</label> */}
                    {/* <input
                      className="Machbd-checkbox"
                      type="checkbox"
                      checked={prb}
                      onChange={(e) => setprb(e.target.checked ? "YES" : "")}
                      disabled
                    /> */}
                  </td>
                  <td colSpan={2}>
                    <span className="topic-head"
                      style={{
                        marginRight: "5px",
                        float: "left",
                        width: "50%",
                      }}
                    >
                      ERP REQ NO :
                    </span>
                    <label>{ERPReq}</label>
                  </td>
                </tr>

                <tr>
                  <td className="topic-head">
                    <span
                      style={{
                        marginRight: "0px",
                        float: "left",
                        width: "35%",
                      }}
                    >
                      Date :{" "}
                    </span>
                    <label>{formatDt(Date1)}</label>
                  </td>
                  <td className="topic-head">
                    <span
                      style={{
                        marginRight: "5px",
                        float: "left",
                        width: "55%",
                      }}
                    >
                      SHIFT/TIME :
                    </span>
                    <label>{SftTm}</label>
                  </td>
                  <td className="topic-head">
                    <span
                      style={{
                        marginRight: "1px",
                        float: "left",
                        width: "25%",
                      }}
                    >
                      BAY :
                    </span>
                    <label>{BAY}</label>
                  </td>
                  <td className="topic-head">
                    <span
                      style={{
                        marginRight: "0px",
                        float: "left",
                        width: "60%",
                      }}
                    >
                      MACHINE NO :
                    </span>
                    <label>{MacNo}</label>
                  </td>
                  <td className="topic-head" colSpan="3">
                    <span>PRESENT STATUS OF MACHINE :</span>

                    <label>{preSOM}</label>
                  </td>
                </tr>
                <tr>
                  <td className="topic-head">
                    <span
                      style={{
                        marginRight: "0px",
                        float: "left",
                        width: "80%",
                      }}
                    >
                      CUSTOMER :
                    </span>
                    <label>{customerName}</label>
                  </td>
                  <td className="topic-head">
                    <span
                      style={{
                        marginRight: "0px",
                        float: "left",
                        width: "60%",
                      }}
                    >
                      MODEL :
                    </span>
                    <label>{Model}</label>
                  </td>
                  <td className="topic-head">
                    <span
                      style={{
                        marginRight: "0px",
                        float: "left",
                        width: "60%",
                      }}
                    >
                      MOULD NAME :
                    </span>
                    <label>{Mldname}</label>
                  </td>
                  <td className="topic-head">
                    <span
                      style={{
                        marginRight: "0px",
                        float: "left",
                        width: "40%",
                      }}
                    >
                      MOULD NUMBER:
                    </span>
                    <label>{MouldNo}</label>
                  </td>
                  <td className="topic-head" colSpan="3">
                    <span>PRIORITY :</span>

                    <label>{Prio}</label>
                  </td>
                </tr>

                <tr>
                  <td className="topic-head">NATURE OF PROBLEM</td>
                  <td colSpan={3}>
                    <label>{NofPrb}</label>
                  </td>
                  <td colSpan="5" className="topic-head">
                    <span
                      style={{
                        marginRight: "10px",
                        float: "left",
                        width: "50%",
                      }}
                    >
                      APPROXIMATE COST VALUE :
                    </span>
                    <label>{AppCV}</label>
                  </td>
                </tr>
                <tr>
                  <td colSpan={1} className="topic-head">
                    ROOT CAUSE ANALYSIS
                  </td>
                  <td colSpan={4}>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto auto auto",
                      }}
                    >
                      <div>
                        <label className="topic-head">{RootCA}</label>
                        {/* <input
                      className="mcareq-checkbox"
                      type="radio"
                      value="DOUBLE SHOT"
                      checked={RootCA === "DOUBLE SHOT"}
                      onChange={(e) => setRootCA(e.target.value)}
                      disabled
                    /> */}

                        {/* <span>DOUBLE SHOT</span>
                  </div>
                  <div>
                  <label>{RootCA}</label> */}
                        {/* <input
                      className="mcareq-checkbox"
                      type="radio"
                      value="OVER PACK"
                      checked={RootCA === "OVER PACK"}
                      disabled
                      onChange={(e) => setRootCA(e.target.value)}
                    /> */}
                        {/* <span>OVER PACK</span>
                  </div>
                  <div>
                  <label>{RootCA}</label> */}
                        {/* <input
                      className="mcareq-checkbox"
                      type="radio"
                      value="DELAY SHORT"
                      checked={RootCA === "DELAY SHORT"}
                      disabled
                      onChange={(e) => setRootCA(e.target.value)}
                    /> 
                 <span>DELAY SHORT</span>*/}
                      </div>
                    </div>
                  </td>

                  <td colSpan={2} style={{ textAlign: "center" }} className="topic-head">
                    WHY WHY ANALYSIS
                  </td>
                </tr>

                <tr>
                  <td colSpan={1} className="topic-head">
                    MTC :
                  </td>
                  <td colSpan={2}>
                    <div
                      style={{ display: "grid", gridTemplateColumns: "auto auto" }}
                    >
                      <div>
                        <label >{MTC}</label>
                        {/* <input
                      className="mcareq-checkbox"
                      type="radio"
                      value="MOULD"
                      checked={MTC === "MOULD"}
                      onChange={(e) => setMTC(e.target.value)}
                    /> 
                    <span>MOULD</span>
                  </div>
                  <div>
                    <input
                      className="mcareq-checkbox"
                      type="radio"
                      value="MACHINE"
                      checked={MTC === "MACHINE"}
                      onChange={(e) => setMTC(e.target.value)}
                    />
                    <span>MACHINE</span>*/}
                      </div>
                    </div>
                  </td>
                  <td className="topic-head" colSpan={2}>
                    CycleTime:
                    <span>
                      <label>{CycTm}</label>
                    </span>
                  </td>

                  <td className="topic-head" colSpan={2} rowSpan={6} style={{ textAlign: "center" }}>
                    <label>{WhyWhy}</label>

                  </td>
                </tr>

                <tr>
                  <td className="topic-head">ALARM :</td>
                  <td colSpan={2}>
                    <div
                      style={{ display: "grid", gridTemplateColumns: "auto auto" }}
                    >
                      <div>
                        <label>{Alrm}</label>
                        {/* <input
                      className="mcareq-checkbox"
                      type="radio"
                      value="METHOD"
                      checked={Alrm === "METHOD"}
                      onChange={(e) => setAlrm(e.target.value)}
                    />
                    METHOD
                  </div>
                  <div>
                    <input
                      className="mcareq-checkbox"
                      type="radio"
                      value="MATERIAL"
                      checked={Alrm === "MATERIAL"}
                      onChange={(e) => setAlrm(e.target.value)}
                    />
                    MATERIAL */}
                      </div>
                    </div>
                  </td>
                  <td className="topic-head" colSpan={2}>
                    CUSION :
                    <span>
                      <label>{Cusion}</label>
                      {/* <input
                    type="text"
                    value={Cusion}
                    onChange={(e) => setCusion(e.target.value)}
                  /> */}
                    </span>
                  </td>


                </tr>
                <tr>
                  <td className="topic-head">SMP:</td>
                  <td className="topic-head">INJECTION PRESSURE:</td>
                  <td>
                    <label>{InjP}</label>
                    {/* <input
                  type="text"
                  value={InjP}
                  onChange={(e) => setInjP(e.target.value)}
                /> */}
                  </td>
                  <td className="topic-head" colSpan={2}>
                    SPEED :
                    <span>
                      <label>{Speed}</label>
                      {/* <input
                    type="text"
                    value={Speed}
                    onChange={(e) => setSpeed(e.target.value)}
                  /> */}
                    </span>
                  </td>


                </tr>
                <tr>
                  <td></td>
                  <td className="topic-head">EJECTION PRESSURE:</td>
                  <td colSpan={1}>
                    <label>{EjP}</label>
                    {/* <input
                  type="text"
                  value={EjP}
                  onChange={(e) => setEjP(e.target.value)}
                /> */}
                  </td>
                  <td className="topic-head" colSpan={2}>
                    TEMPERATURE:
                    <label>{Temp}</label>
                    {/* <input
                  type="text"
                  value={Temp}
                  onChange={(e) => setTemp(e.target.value)}
                /> 
              </td>
              
             {/*    <td className="topic-head" colSpan={2} style={{textAlign:"center"}}>MACHINE
             <input
                      className="mcareq-checkbox"
                      type="radio"
                      value="MACHINE"
                      checked={Mould === "MACHINE"}
                      onChange={(e) => setMould(e.target.value)}
                    />  */}
                  </td>
                </tr>
                <tr>
                  <td colSpan={1} className="topic-head">
                    HRC
                  </td>
                  <td colSpan={2} className="topic-head">
                    LIMIT SWITCH CONNECTION :<span>ON/OFF/NILL</span>
                    <label>{LimitC}</label>
                    {/* <input
                  type="text"
                  value={LimitC}
                  onChange={(e) => setLimitC(e.target.value)}
                /> */}
                  </td>
                  <td className="topic-head" colSpan={2}>
                    PRESSURE :
                    <label>{Press}</label>
                    {/* <input
                  type="text"
                  value={Press}
                  onChange={(e) => setPress(e.target.value)}
                /> 
              </td>
              <td className="topic-head" colSpan={2} style={{textAlign:"center"}}>METHOD
              
              <input
                      className="mcareq-checkbox"
                      type="radio"
                      value="METHOD"
                      checked={Mould === "METHOD"}
                      onChange={(e) => setMould(e.target.value)}
                    /> */}
                  </td>

                </tr>
                <tr>
                  <td className="topic-head">ROOT CAUSE :</td>
                  <td colSpan={6}>
                    <label>{RootC}</label>
                  </td>
                </tr>
                <tr>
                  <td className="topic-head">CORRECTIVE ACTION :</td>
                  <td colSpan={6}>
                    <label>{CorrAct}</label>
                  </td>
                </tr>
                <tr>
                  <td className="topic-head">SPARE DETAILS</td>
                  <td>
                    <label>{SpareD}</label>
                  </td>
                  <td className="topic-head">
                    <span
                      style={{
                        marginRight: "5px",
                        float: "left",
                        width: "40%",
                      }}
                    >
                      COST :
                    </span>
                    <label>{Cost}</label>
                  </td>
                  <td className="topic-head">
                    <span
                      style={{
                        marginRight: "5px",
                        float: "left",
                        width: "40%",
                      }}
                    >
                      STOCK :
                    </span>
                    <label>{Stock}</label>
                  </td>

                  <td colSpan="3" className="topic-head">
                    <span
                      style={{
                        marginRight: "5px",
                        float: "left",
                        width: "40%",
                      }}
                    >
                      ERP REQ STATUS :
                    </span>
                    <label>{ERPReqS}</label>
                  </td>
                </tr>
                <tr>
                  <th colSpan={7} style={{ textAlign: "center" }}>
                    AFTER CORRECTION STATUS
                  </th>
                </tr>
                <tr>
                  <td></td>
                  <th style={{ textAlign: "center" }}>TRIAL-1</th>
                  <th style={{ textAlign: "center" }}>TRIAL-2</th>
                  <th style={{ textAlign: "center" }}>TRIAL-3</th>
                  <td colSpan="3" className="topic-head">
                    <span
                      style={{
                        marginRight: "5px",
                        float: "left",
                        width: "40%",
                      }}
                    >
                      TOTAL WORKING HOURS :
                    </span>
                    <label>{TWhrs}</label>
                  </td>
                </tr>
                <tr>
                  <td className="topic-head">PRODUCTION POINT</td>
                  <td colSpan={1}>
                    <label>{PRP1}</label>
                  </td>
                  <td colSpan={1}>
                    <label>{PRP2}</label>
                  </td>
                  <td colSpan={1}>
                    <label>{PRP3}</label>
                  </td>
                  <td
                    colSpan={3}
                    style={{ textAlign: "center" }}
                    className="topic-head"
                  >
                    STATUS OF RECTIFICATION
                  </td>
                </tr>
                <tr>
                  <td className="topic-head">QUALITY POINTS</td>
                  <td colSpan={1}>
                    <label>{QP1}</label>
                  </td>
                  <td colSpan={1}>
                    <label>{QP2}</label>
                  </td>
                  <td colSpan={1}>
                    <label>{QP3}</label>
                  </td>
                  <td colSpan={3}>
                    <label>{StsORec}</label>
                  </td>
                </tr>
                <tr>
                  <td className="topic-head">REQUESTED BY</td>
                  <td className="topic-head">SIGNATURE OF TECHNICIAN</td>
                  <td className="topic-head">APPROVED BY (TOOLROOMHOD)</td>
                  <td className="topic-head">PRODUCTION HOD</td>
                  <td className="topic-head">QUALITY</td>
                  <td className="topic-head">PURCHASE</td>
                  <td className="topic-head">PLANT HEAD</td>
                </tr>
                <tr>
                  <td colSpan={1}>
                    <label>{ReqBy}</label>
                  </td>
                  <td colSpan={1}>
                    <label>{SignBy}</label>
                  </td>
                  <td colSpan={1}>
                    <label>{APRBy}</label>
                  </td>
                  <td colSpan={1}>
                    <label>{ProHd}</label>
                  </td>
                  <td colSpan={1}>
                    <label>{Qty}</label>
                  </td>
                  <td colSpan={1}>
                    <label>{Purc}</label>
                  </td>
                  <td colSpan={1}>
                    <label>{PlHd}</label>
                  </td>
                </tr>
                {/* <tr>
                  <td className="topic-head">REVISION HISTORY</td>
                  <td colSpan={2} className="topic-head">
                   
                    <label>{"REV NO: 02"}</label>
                  </td>
                  <td className="topic-head">
                    DATE:
                    <label>{formatDt(Date0)}</label>
                  </td>
                  <td className="topic-head">REASON:</td>
                  <td colSpan={3}>
                    <label>{Rsn}</label>
                  </td>
                </tr> */}
              </tbody>
            </table>
            {/* <div className="mcaApprove">
    
             
    <button className="mcaApprove"
    style={{
      backgroundColor: "#9d080f",
      width: "90px",
      color: "white",
    }}
    onClick={(e) => {
      setRsn("Approved");
      handleApproval(e);
      }}>
    Approve✎
  </button>
  
    <button className="mcaApprove"
      style={{
        backgroundColor:"#06802f",
        width: "90px",
      }}
      onClick={(e) => {
        setRsn("Rejected");
        handleApproval(e);
      }}>
      Reject ✎
      </button>
     
            </div> */}
          </form>

        </Popup>
      </div>

      <div>
        <Popup
          trigger={ButtonPopup1}
          setTrigger={setButtonPopup1}
          viewPagination={viewPagination}
        >
          <form
            id="mcareq-formContainer-Edit"
            name="mcareq-formContainer-Edit"
            className="form-mcareq-Edit"
          >
            <div ref={scrollTop}>
              <table>
                <tbody>

                  {/* <th colSpan={1} style={{ textAlign: "center", width: "10%" }}>
                <img src={Unitechlogo} style={{ width: "40px" }}></img>
              </th> */}
                  {/* <th
                colSpan={4}
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">UNITECH PLASTO COMPONENTS (P) LTD</font>
              </th> */}
                  {/* <th colSpan={2}>
                <span
                  style={{ marginRight: "70px", float: "left", width: "25%" }}
                >
                  FORMAT NO:
                </span>
                <label>{"UPCL/PRO/R06"}</label>
                <span
                  style={{ marginRight: "110px", float: "left", width: "30%" }}
                >
                  REV NO:
                </span>
                <label>{"02"}</label>
                
                <span
                  style={{ marginRight: "80px", float: "left", width: "30%" }}
                >
                  DATE :
                </span>
                <label>{"05.01.2021"}</label>
              </th> */}

                  <tr>
                    <th
                      colSpan={6}
                      style={{
                        textAlign: "center",
                        whiteSpace: "nowrap",
                        padding: "10px 0px",
                        color: "#8B0000"
                      }}
                    >
                      Edit - Mould Corrective Action Request Report
                    </th>
                    <th colSpan={1}>
                      <span
                        style={{ marginRight: "5px", float: "left", width: "70%" }}
                      >
                        ERP REQ NO<span style={{ color: "red" }}>*</span> :
                      </span>
                      <input
                        id="mcareq.Cr.erpno"
                        name="mcareq.Cr.erpno"
                        type="text"
                        value={ERPReq}
                        onChange={(e) => setERPReq(e.target.value)}
                        style={{ width: "97%", backgroundColor: "#efd9b1" }}
                      />
                    </th>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <label>
                        <input
                          className="mcareq-checkbox"
                          type="radio"
                          value="MOULD PROBLEM"
                          checked={prb === "MOULD PROBLEM"}
                          onChange={(e) => setprb(e.target.value)}
                        />
                        MOULD PROBLEM
                      </label>
                    </td>

                    <td colSpan={2}>
                      <label>
                        <input
                          className="mcareq-checkbox"
                          type="radio"
                          value={"PRODUCTION PROBLEM"}
                          checked={prb === "PRODUCTION PROBLEM"}
                          onChange={(e) => setprb(e.target.value)}
                        />
                        PRODUCTION PROBLEM
                      </label>
                    </td>

                    <td colSpan={3}>
                      <label>
                        <input
                          className="mcareq-checkbox"
                          type="radio"
                          value={"FLASH PROBLEM"}
                          checked={prb === "FLASH PROBLEM"}
                          onChange={(e) => setprb(e.target.value)}
                        />
                        FLASH PROBLEM
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td className="topic-head">
                      <span style={{ marginRight: "0px", float: "left" }}>
                        Date<span style={{ color: "red" }}>*</span> :
                      </span>
                      <DatePicker value={Date1} onChange={handleDateChange} />
                    </td>
                    <td className="topic-head">
                      <span
                        style={{ marginRight: "5px", float: "left", width: "55%" }}
                      >
                        SHIFT/TIME<span style={{ color: "red" }}>*</span> :
                      </span>
                      <select
                        value={SftTm}
                        onChange={(e) => setSftTm(e.target.value)}
                        style={{ textAlign: "center" }}
                      >
                        <option
                          value=""
                        >
                          -- Select --
                        </option>
                        <option value="A12">A12</option>
                        <option value="B12">B12</option>
                      </select>
                    </td>
                    <td className="topic-head">
                      <span
                        style={{ marginRight: "1px", float: "left", width: "25%" }}
                      >
                        BAY<span style={{ color: "red" }}>*</span> :
                      </span>
                      <input
                        id="mcareq.Cr.erpno"
                        name="mcareq.Cr.erpno"
                        type="text"
                        value={BAY}
                        onChange={(e) => setBay(e.target.value)}
                        style={{ width: "100%", backgroundColor: "#efd9b1" }}
                      />
                    </td>
                    <td className="topic-head">
                      <span>
                        MACHINE NO<span style={{ color: "red" }}>*</span> :
                      </span>
                      <DropdownFetcher keyword="mac_dtl_dropdown" setValue={setmachine} />
                      <Select
                        value={MacNo}
                        onChange={handleMachine}
                        options={machine.map((i) => ({
                          value: i.mc_code,
                          label: i.mc_name,
                        }))}
                      />
                    </td>
                    {/* <input
                id="mcareq.Cr.erpno" name="mcareq.Cr.erpno"
                  type="text"
                  value={MacNo}
                  onChange={(e) => setMacNo(e.target.value)}
                  style={{ width: "40%", backgroundColor: "#efd9b1" }}
                />{MacNo === '' && <span className="required ">Required *</span>}</td> */}
                    <td className="topic-head" colSpan="3">
                      <span>PRESENT STATUS OF MACHINE :</span>
                      <input
                        className="mcareq-checkbox"
                        type="radio"
                        value="Running"
                        checked={preSOM === "Running"}
                        onChange={(e) => setpreSOM(e.target.value)}
                      />
                      RUNNING
                      <input
                        className="mcareq-checkbox"
                        type="radio"
                        value="Stopped"
                        checked={preSOM === "Stopped"}
                        onChange={(e) => setpreSOM(e.target.value)}
                      />
                      STOPPED
                    </td>
                  </tr>
                  <tr>
                    <td className="topic-head">
                      <span
                        style={{ marginRight: "0px", float: "left", width: "100%" }}
                      >
                        CUSTOMER<span style={{ color: "red" }}>*</span> :
                      </span>
                      <DropdownFetcher keyword="customer_details" setValue={setCustomer} />
                      <Select
                        value={customerName}
                        onChange={handleCustomer}
                        options={Customer.map((i) => ({
                          value: i.Cus_code,
                          label: i.Cus_name,
                        }))}
                      />
                    </td>
                    <td className="topic-head">
                      <span
                        style={{ marginRight: "0px", float: "left", width: "60%" }}
                      >
                        MODEL<span style={{ color: "red" }}>*</span> :
                      </span>

                      <input
                        type="text"
                        value={Model}
                        onChange={(e) => setModel(e.target.value)}
                        style={{ width: "100%", backgroundColor: "#efd9b1" }}
                      />
                    </td>
                    <td className="topic-head">
                      <span style={{ width: "60%" }}>
                        MOULD NAME<span style={{ color: "red" }}>*</span> :
                      </span>
                      {/* <DropdownFetcher
                        keyword="mac_part_dtl_with_model_dropdown"
                        setValue={setMouldName}
                      /> */}
                      <Select
                        styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                        value={Mldname}
                        onChange={handleMouldName}
                        onInputChange={(event) => { GetSelectValue(event, "mac_part_dtl_with_model_dropdown", setMouldName) }}
                        options={MouldName.map((i) => ({
                          value: i.mc_part_code,
                          label: i.mc_part_name,
                        }))}
                      />
                    </td>
                    <td className="topic-head">
                      <span
                        style={{ marginRight: "0px", float: "left", width: "60%" }}
                      >
                        MOULD NUMBER<span style={{ color: "red" }}>*</span> :
                      </span>
                      <input
                        type="text"
                        value={ModelNumber}
                        onChange={(e) => setModelNumber(e.target.value)}
                        style={{ width: "100%", backgroundColor: "#efd9b1" }}
                      />
                    </td>
                    <td className="topic-head" colSpan="3">
                      <span>PRIORITY :</span>
                      <input
                        className="mcareq-checkbox"
                        type="radio"
                        value="VERYHIGH"
                        checked={Prio === "VERYHIGH"}
                        onChange={(e) => setPrio(e.target.value)}
                      />
                      VERYHIGH
                      <input
                        className="mcareq-checkbox"
                        type="radio"
                        value="HIGH"
                        checked={Prio === "HIGH"}
                        onChange={(e) => setPrio(e.target.value)}
                      />
                      HIGH
                      <input
                        className="mcareq-checkbox"
                        type="radio"
                        value="LOW"
                        checked={Prio === "LOW"}
                        onChange={(e) => setPrio(e.target.value)}
                      />
                      LOW
                      <input
                        className="mcareq-checkbox"
                        type="radio"
                        value="VERYLOW"
                        checked={Prio === "VERYLOW"}
                        onChange={(e) => setPrio(e.target.value)}
                      />
                      VERYLOW
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={4}>
                      <span className="topic-head">NATURE OF PROBLEM</span>
                      <input
                        type="text"
                        value={NofPrb}
                        onChange={(e) => setNofPrb(e.target.value)}
                      />
                    </td>
                    <td colSpan={3} className="topic-head">
                      <span
                        style={{ marginRight: "10px", float: "left", width: "50%" }}
                      >
                        APPROXIMATE COST VALUE :
                      </span>
                      <input
                        type="number"
                        value={AppCV}
                        onChange={(e) => setAppCV(e.target.value)}
                        style={{ width: "98%", backgroundColor: "#efd9b1" }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} className="topic-head">
                      ROOT CAUSE ANALYSIS
                    </td>
                    <td colSpan={4}>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "auto auto auto",
                        }}
                      >
                        <div>
                          <input
                            className="mcareq-checkbox"
                            type="radio"
                            value="DOUBLE SHOT"
                            checked={RootCA === "DOUBLE SHOT"}
                            onChange={(e) => setRootCA(e.target.value)}
                          />
                          <span>DOUBLE SHOT</span>
                        </div>
                        <div>
                          <input
                            className="mcareq-checkbox"
                            type="radio"
                            value="OVER PACK"
                            checked={RootCA === "OVER PACK"}
                            onChange={(e) => setRootCA(e.target.value)}
                          />
                          <span>OVER PACK</span>
                        </div>
                        <div>
                          {" "}
                          <input
                            className="mcareq-checkbox"
                            type="radio"
                            value="DELAY SHORT"
                            checked={RootCA === "DELAY SHORT"}
                            onChange={(e) => setRootCA(e.target.value)}
                          />
                          <span>DELAY SHORT</span>
                        </div>
                      </div>
                    </td>

                    <td colSpan={2} style={{ textAlign: "center" }} className="topic-head">
                      WHY WHY ANALYSIS
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={1} className="topic-head">
                      MTC :
                    </td>
                    <td colSpan={2}>
                      <div
                        style={{ display: "grid", gridTemplateColumns: "auto auto" }}
                      >
                        <div>
                          <input
                            className="mcareq-checkbox"
                            type="radio"
                            value="MOULD"
                            checked={MTC === "MOULD"}
                            onChange={(e) => setMTC(e.target.value)}
                          />
                          <span>MOULD</span>
                        </div>
                        <div>
                          <input
                            className="mcareq-checkbox"
                            type="radio"
                            value="MACHINE"
                            checked={MTC === "MACHINE"}
                            onChange={(e) => setMTC(e.target.value)}
                          />
                          <span>MACHINE</span>
                        </div>
                      </div>
                    </td>
                    <td className="topic-head" colSpan={2}>
                      CycleTime:
                      <span>
                        <input
                          type="text"
                          value={CycTm}
                          onChange={(e) => setCycTm(e.target.value)}
                        />
                      </span>
                    </td>

                    <td className="topic-head" colSpan={2} rowSpan={5} style={{ verticalAlign: "top" }}>
                      <label style={{ display: "block", fontSize: "15px" }}>
                        MOULD
                        <input
                          className="mcareq-checkbox"
                          type="radio"
                          value="MOULD"
                          checked={WhyWhy === "MOULD"}
                          onChange={(e) => setWhyWhy(e.target.value)}
                          style={{ padding: "0", margin: "0" }}
                          disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                        /></label>
                      <br />
                      <label style={{ display: "block", fontSize: "15px" }}>
                        MEN
                        <input
                          className="mcareq-checkbox"
                          type="radio"
                          value="MEN"
                          checked={WhyWhy === "MEN"}
                          onChange={(e) => setWhyWhy(e.target.value)}
                          style={{ padding: "0", margin: "0" }}
                          disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                        /></label>
                      <br />
                      <label style={{ display: "block", fontSize: "15px" }}>
                        MATERIAL
                        <input
                          className="mcareq-checkbox"
                          type="radio"
                          value="MATERIAL"
                          checked={WhyWhy === "MATERIAL"}
                          onChange={(e) => setWhyWhy(e.target.value)}
                          style={{ padding: "0", margin: "0" }}
                          disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                        /></label>
                      <br />
                      <label style={{ display: "block", fontSize: "15px" }}>
                        MACHINE
                        <input
                          className="mcareq-checkbox"
                          type="radio"
                          value="MACHINE"
                          checked={WhyWhy === "MACHINE"}
                          onChange={(e) => setWhyWhy(e.target.value)}
                          style={{ padding: "0", margin: "0" }}
                          disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                        /></label>
                      <br />
                      <label style={{ display: "block", fontSize: "15px" }}>
                        METHOD
                        <input
                          className="mcareq-checkbox"
                          type="radio"
                          value="METHOD"
                          checked={WhyWhy === "METHOD"}
                          onChange={(e) => setWhyWhy(e.target.value)}
                          style={{ padding: "0", margin: "0" }}
                          disabled={ERPReq === "" || prb === "" || BAY === "" || MacNo === "" || customerName === "" || Mldname === ""}
                        /></label>
                    </td>

                  </tr>

                  <tr>
                    <td className="topic-head">ALARM :</td>
                    <td colSpan={2}>
                      <div
                        style={{ display: "grid", gridTemplateColumns: "auto auto" }}
                      >
                        <div>
                          <input
                            className="mcareq-checkbox"
                            type="radio"
                            value="METHOD"
                            checked={Alrm === "METHOD"}
                            onChange={(e) => setAlrm(e.target.value)}
                          />
                          METHOD
                        </div>
                        <div>
                          <input
                            className="mcareq-checkbox"
                            type="radio"
                            value="MATERIAL"
                            checked={Alrm === "MATERIAL"}
                            onChange={(e) => setAlrm(e.target.value)}
                          />
                          MATERIAL
                        </div>
                      </div>
                    </td>
                    <td className="topic-head" colSpan={2}>
                      CUSION :
                      <span>
                        <input
                          type="text"
                          value={Cusion}
                          onChange={(e) => setCusion(e.target.value)}
                        />
                      </span>
                    </td>

                    {/* <td className="topic-head" colSpan={2} style={{textAlign:"center"}}>MEN
              <input
                      className="mcareq-checkbox"
                      type="radio"
                      value="MEN"
                      checked={Mould === "MEN"}
                      onChange={(e) => setMould(e.target.value)}
                    /> 
               </td>*/}
                  </tr>
                  <tr>
                    <td className="topic-head">SMP:</td>
                    <td className="topic-head">INJECTION PRESSURE:</td>
                    <td>
                      <input
                        type="text"
                        value={InjP}
                        onChange={(e) => setInjP(e.target.value)}
                      />
                    </td>
                    <td className="topic-head" colSpan={2}>
                      SPEED :
                      <span>
                        <input
                          type="text"
                          value={Speed}
                          onChange={(e) => setSpeed(e.target.value)}
                        />
                      </span>
                    </td>

                    {/* <td className="topic-head" colSpan={2} style={{textAlign:"center"}}>MATERIAL
              <input
                      className="mcareq-checkbox"
                      type="radio"
                      value="MATERIAL"
                      checked={Mould === "MATERIAL"}
                      onChange={(e) => setMould(e.target.value)}
                    />
               </td> */}
                  </tr>
                  <tr>
                    <td></td>
                    <td className="topic-head">EJECTION PRESSURE:</td>
                    <td colSpan={1}>
                      <input
                        type="text"
                        value={EjP}
                        onChange={(e) => setEjP(e.target.value)}
                      />
                    </td>
                    <td className="topic-head" colSpan={2}>
                      TEMPERATURE:
                      <input
                        type="text"
                        value={Temp}
                        onChange={(e) => setTemp(e.target.value)}
                      />
                    </td>

                    {/* <td className="topic-head" colSpan={2} style={{textAlign:"center"}}>MACHINE
              <input
                      className="mcareq-checkbox"
                      type="radio"
                      value="MACHINE"
                      checked={Mould === "MACHINE"}
                      onChange={(e) => setMould(e.target.value)}
                    />
               </td> */}
                  </tr>
                  <tr>
                    <td colSpan={1} className="topic-head">
                      HRC
                    </td>
                    <td colSpan={2} className="topic-head">
                      LIMIT SWITCH CONNECTION :<span>ON/OFF/NILL</span>
                      <input
                        type="text"
                        value={LimitC}
                        onChange={(e) => setLimitC(e.target.value)}
                      />
                    </td>
                    <td className="topic-head" colSpan={2}>
                      PRESSURE :
                      <input
                        type="text"
                        value={Press}
                        onChange={(e) => setPress(e.target.value)}
                      />
                    </td>
                    {/* <td className="topic-head" colSpan={2} style={{textAlign:"center"}}>METHOD
              <input
                      className="mcareq-checkbox"
                      type="radio"
                      value="METHOD"
                      checked={Mould === "METHOD"}
                      onChange={(e) => setMould(e.target.value)}
                    />
               </td> */}

                  </tr>
                  <tr>
                    <td className="topic-head">ROOT CAUSE :</td>
                    <td colSpan={6}>
                      <input
                        type="text"
                        value={RootC}
                        onChange={(e) => setRootC(e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="topic-head">CORRECTIVE ACTION :</td>
                    <td colSpan={6}>
                      <input
                        id="mcareq.Cr.revno"
                        name="mcareq.Cr.revno"
                        type="text"
                        value={CorrAct}
                        onChange={(e) => setCorrAct(e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <span className="topic-head">SPARE DETAILS :</span>
                      <input
                        id="mcareq.Cr.revno"
                        name="mcareq.Cr.revno"
                        type="text"
                        value={SpareD}
                        onChange={(e) => setSpareD(e.target.value)}
                        style={{ width: "98%", backgroundColor: "#efd9b1" }}
                      />
                    </td>
                    <td colSpan={1} className="topic-head">
                      <span style={{ width: "40%" }}>COST :</span>
                      <input
                        id="mcareq.Cr.revno"
                        name="mcareq.Cr.revno"
                        type="number"
                        value={Cost}
                        onChange={(e) => setCost(e.target.value)}
                        style={{ width: "100%", backgroundColor: "#efd9b1" }}
                      />
                    </td>
                    <td colSpan={1} className="topic-head">
                      <span style={{ width: "40%" }}>STOCK :</span>
                      <input
                        id="mcareq.Cr.revno"
                        name="mcareq.Cr.revno"
                        type="text"
                        value={Stock}
                        onChange={(e) => setStock(e.target.value)}
                        style={{ width: "100%", backgroundColor: "#efd9b1" }}
                      />
                    </td>

                    <td colSpan={3} className="topic-head">
                      <span style={{ width: "40%" }}>ERP REQ STATUS :</span>
                      <input
                        type="text"
                        value={ERPReqS}
                        onChange={(e) => setERPReqS(e.target.value)}
                        style={{ width: "98%", backgroundColor: "#efd9b1" }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th colSpan={7} style={{ textAlign: "center" }}>
                      AFTER CORRECTION STATUS
                    </th>
                  </tr>
                  <tr>
                    <td></td>
                    <th style={{ textAlign: "center" }}>TRIAL-1</th>
                    <th style={{ textAlign: "center" }}>TRIAL-2</th>
                    <th style={{ textAlign: "center" }}>TRIAL-3</th>
                    <td colSpan="3" className="topic-head">
                      <span
                        style={{ marginRight: "5px", float: "left", width: "40%" }}
                      >
                        TOTAL WORKING HOURS :

                      </span>
                      <div>

                        <HoursMinutesSelect
                          hours={TWhrs.hours}
                          minutes={TWhrs.minutes}

                          // hours={selectedHours}
                          // minutes={selectedMinutes}
                          onHoursChange={handleHoursChange}
                          onMinutesChange={handleMinutesChange}
                        />
                      </div> <text style={{ fontSize: "20px" }}>{TWhrs}</text>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} className="topic-head">PRODUCTION POINT</td>
                    <td colSpan={1}>
                      <input
                        type="text"
                        value={PRP1}
                        onChange={(e) => setPRP1(e.target.value)}
                      />
                    </td>
                    <td colSpan={1}>
                      <input
                        type="text"
                        value={PRP2}
                        onChange={(e) => setPRP2(e.target.value)}
                      />
                    </td>
                    <td colSpan={1}>
                      <input
                        type="text"
                        value={PRP3}
                        onChange={(e) => setPRP3(e.target.value)}
                      />
                    </td>
                    <td colSpan={3}
                      style={{ textAlign: "center" }}
                      className="topic-head"
                    >
                      STATUS OF RECTIFICATION
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} className="topic-head">QUALITY POINTS</td>
                    <td colSpan={1}>
                      <input
                        type="text"
                        value={QP1}
                        onChange={(e) => setQP1(e.target.value)}
                      />
                    </td>
                    <td colSpan={1}>
                      <input
                        type="text"
                        value={QP2}
                        onChange={(e) => setQP2(e.target.value)}
                      />
                    </td>
                    <td colSpan={1}>
                      <input
                        type="text"
                        value={QP3}
                        onChange={(e) => setQP3(e.target.value)}
                      />
                    </td>
                    <td colSpan={1}>
                      <input
                        className="mcareq-checkbox"
                        type="radio"
                        value="TEMPORARY"
                        checked={StsORec === "TEMPORARY"}
                        onChange={(e) => setStsORec(e.target.value)}
                      />
                      TEMPORARY
                    </td>
                    <td colSpan={1}>
                      <input
                        className="mcareq-checkbox"
                        type="radio"
                        value="PERMANENT"
                        checked={StsORec === "PERMANENT"}
                        onChange={(e) => setStsORec(e.target.value)}
                      />
                      PERMANENT
                    </td>
                    <td colSpan={1}>
                      <input
                        className="mcareq-checkbox"
                        type="radio"
                        value="UNDER OBSERVATION"
                        checked={StsORec === "UNDER OBSERVATION"}
                        onChange={(e) => setStsORec(e.target.value)}
                      />
                      UNDER OBSERVATION
                    </td>
                  </tr>
                  <tr>
                    <td className="topic-head">REQUESTED BY</td>
                    <td className="topic-head">SIGNATURE OF TECHNICIAN</td>
                    <td className="topic-head">APPROVED BY (TOOLROOMHOD)</td>
                    <td className="topic-head">PRODUCTION HOD</td>
                    <td className="topic-head">QUALITY</td>
                    <td className="topic-head">PURCHASE</td>
                    <td className="topic-head">PLANT HEAD</td>
                  </tr>
                  <tr>
                    <td colSpan={1}>
                      <DropdownFetcher keyword="emp_details" setValue={setEmployee} />
                      <Select
                        styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                        value={ReqBy}
                        onChange={handleEmployee}
                        options={Employee.map((i) => ({
                          value: i.Emp_code,
                          label: i.Emp_name,
                        }))}
                      />
                    </td>
                    <td colSpan={1}>
                      <Select
                        styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                        value={SignBy}
                        onChange={setSignBy}
                        options={Employee.map((i) => ({
                          value: i.Emp_code,
                          label: i.Emp_name,
                        }))}
                      />
                    </td>
                    <td colSpan={1}>
                      <Select
                        styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                        value={APRBy}
                        onChange={setAPRBy}
                        options={Employee.map((i) => ({
                          value: i.Emp_code,
                          label: i.Emp_name,
                        }))}
                      />
                    </td>
                    <td colSpan={1}>
                      <Select
                        styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                        value={ProHd}
                        onChange={setProHd}
                        options={Employee.map((i) => ({
                          value: i.Emp_code,
                          label: i.Emp_name,
                        }))}
                      />
                    </td>
                    <td colSpan={1}>
                      <Select
                        styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                        value={Qty}
                        onChange={setQty}
                        options={Employee.map((i) => ({
                          value: i.Emp_code,
                          label: i.Emp_name,
                        }))}
                      />
                    </td>
                    <td colSpan={1}>
                      <Select
                        styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                        value={Purc}
                        onChange={setPurc}
                        options={Employee.map((i) => ({
                          value: i.Emp_code,
                          label: i.Emp_name,
                        }))}
                      />
                    </td>
                    <td colSpan={1}>
                      <Select
                        styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                        value={PlHd}
                        onChange={setPlHd}
                        options={Employee.map((i) => ({
                          value: i.Emp_code,
                          label: i.Emp_name,
                        }))}
                      />
                    </td>
                  </tr>
                  {/* <tr>
              <td className="topic-head">REVISION HISTORY</td>
              <td
                colSpan={2}
                className="topic-head"
                style={{ textAlign: "center" }}
              >
                REV NO: {"02"}
              </td>
              <td className="topic-head">
                DATE:
                <DatePicker value={Date0} onChange={handleDateChange1} />
              </td>
              <td className="topic-head">REASON:</td>
              <td className="topic-head" colSpan={3}>
                FORMAT REVISED WITH DETAILED ACTION FOR BREAKDOWN
              </td>
            </tr> */}
                  <tr>
                    <td colSpan="14" style={{ border: "none" }}>
                      <button
                        type="submit"
                        id="mcareq.cr.submit"
                        name="mcareq.cr.submit"
                        onClick={(e) => handleUpdate(e)}
                      >
                        Update
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
        </Popup>
      </div>

      <div>
        <Popup
          trigger={ButtonPopup2}
          setTrigger={setButtonPopup2}
          viewPagination={viewPagination}
        >
          <form className="form-container1">
            <table style={{ textAlign: "center" }}>
              <tbody>
                <tr>
                  <th>ERP-REQ NO</th>
                  <td>
                    <input
                      type="text"
                      value={ERPReq}
                      onChange={(e) => setERPReq(e.target.value)}
                      disabled
                    />
                  </td>
                </tr>
                <tr>
                  <th>Model</th>
                  <td>
                    <input
                      type="text"
                      value={Model}
                      onChange={(e) => setModel(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Mould Name</th>
                  <td>
                    <input
                      type="text"
                      value={Mldname}
                      onChange={(e) => setMldname(e.target.value)}
                    />
                  </td>
                </tr>
                <br></br>
                <tr>
                  <th>Active Status</th>
                  <td>
                    <select>
                      {/* // value={ActS}
                      //   onChange={(e) => setActS(e.target.value)}> */}
                      <option value="YES">Active</option>
                      <option value="NO">INActive</option>
                    </select>
                  </td>
                </tr>
                <br></br>
                <tr>
                  <td colSpan="2">
                    <button
                      type="submit"
                      id="sub"
                      onClick={() => handleChange()}
                    >
                      Submit
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </Popup>
      </div>

      <div>
        <main>
          <table style={{ height: "70%", width: "100%" }}>
            <thead>
              <tr>
                <th
                  id="mcacss-Title.List"
                  name="mcacss-Title.List"
                  colSpan="12"
                  style={{ textAlign: "center", whiteSpace: "nowrap" }}
                >
                  <font color="#8B0000">List - Mould Corrective Report</font>
                </th>
              </tr>
              <tr>
                <td className="cell">Date</td>
                <td colSpan="1">
                  <input
                    id="mlcss-filter-date"
                    name="mlcss-filter-date"
                    type="date"
                    placeholder="Filter by Date"
                    value={filterDate}
                    onChange={handleFilterDateChange}
                  />
                </td>
                <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                  Model
                </td>
                <td colSpan={2}>
                  <input
                    id="mlcss-filter-Model"
                    name="mlcss-filter-Model"
                    type="text"
                    placeholder="Filter Model"
                    style={{ fontSize: "70%", width: "100%" }}
                    value={filterMcno}
                    onChange={handleFilterMcnoChange}
                  />
                </td>
                <td colSpan={4}></td>
              </tr>

              <tr>
                <th style={{ textAlign: "center" }}>Date</th>
                <th style={{ textAlign: "center" }}>Customer</th>
                <th style={{ textAlign: "center" }}>Model</th>

                <th style={{ textAlign: "center" }}>Mould Name</th>
                <th style={{ textAlign: "center" }}>Mould No</th>
                <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                  Status
                </th>
                <th className="table-list-head"></th>
                <th className="table-list-head"></th>
                <th className="table-list-head"></th>
              </tr>
            </thead>
            <tbody>
              {/* <HandleRead keyword="MouldCorrective" setData={setmca_req} /> */}
              {filteredRecords.length === 0 ? (
                <td colSpan="12" style={{ textAlign: "center" }}>
                  <p
                    id="mlcss-list-NoRec"
                    name="mlcss-list-noRec"
                    style={{ fontWeight: "bold" }}
                  >
                    No records found.
                  </p>
                </td>
              ) : (
                filteredRecords.map((mca_req) => (
                  <tr className="table_view" key={mca_req.Id}>
                    <td className="table-cell">{formatDt(mca_req.M_date)}</td>
                    <td className="table-cell">{mca_req.Customer}</td>
                    <td
                      className="table-cell"
                      style={{ height: "50%", whiteSpace: "pre-wrap" }}
                    >
                      {mca_req.Model}
                    </td>
                    <td className="table-cell">{mca_req.Mould_name}</td>
                    <td className="table-cell">{mca_req.Mould_number}</td>
                    <td
                      style={{

                        color: mca_req.Approval_status === "0" ? 'red' : mca_req.Approval_status === "1" ? 'green' : mca_req.Approval_status === undefined ? '#E59500' : "",

                      }}
                      className="table-cell"
                    >
                      &nbsp;&nbsp;{mca_req.Approval_status === undefined ? "Waiting for approval..." : mca_req.Approval_status === "1" ? "Approved" : mca_req.Approval_status === "0" ? "Rejected" : ""}
                    </td>
                    <td>
                      <button
                        style={{ backgroundColor: "#cba423" }}
                        type="button"
                        //onClick={() => handleView(mca_req)}
                        onClick={() => {
                          handleView(mca_req);
                          handleEdit2(mca_req);
                          // console.log("clicked")
                        }}
                      >
                        View&#x1F441;
                      </button>
                    </td>
                    <td>
                      <button type="button" onClick={() => handleEdit(mca_req)}>
                        Edit&#x270E;
                      </button>
                    </td>
                    <td>
                      <button
                        id={`MouldCorrective-Delete`}
                        name={`MouldCorrective-Delete`}
                        style={{ whiteSpace: "nowrap", backgroundColor: "#DA251A" }}
                        type="button"
                        onClick={() => {
                          const userConfirmed = window.confirm('Are you sure you want to delete?');
                          if (userConfirmed) {
                            UpdateRecord({
                              _keyword_: 'MouldCorrective',
                              data: [
                                {
                                  Id: mca_req.Id,
                                  ActiveStatus: 'InActive',
                                },
                              ],
                            }).then(() => {
                              handleReadMctActReqReport();
                            })
                            // window.location.reload();
                          }
                        }}
                      >
                        Delete<MdDelete />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <div className="pagination" id="pagination">
            {isPaginationVisible && filteredData.length > 0 && (
              <Pagination
                currentPage={currentPage}
                npage={npage}
                prePage={prePage}
                nextPage={nextPage}
                changeCpage={changeCpage}
                numbers={numbers}
              />
            )}
          </div>
        </main>
      </div>
    </div>
  );
}
export default MCActRequestReportView;
