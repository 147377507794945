//external import
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

//internal import
import Field from "../../../Components/Fields/Field";
import Lable from "../../../Components/Fields/Lable";
import QaLayout from "../../Quality/QaLayout";

//css import
import "./NewSmpMain.css";
import NewSmpTabs from "./NewSmpTabs";
//API imports
import { CURRENT_Date } from "../../../Utils/GeneralConstants";
import CreateRecord from "../../../Lib/API/CreateRecord";
import DeleteOriginalRecord from "../../../Lib/API/DeleteOriginalRecord";
import PreViewSmpPage from "./PreViewSmpPage";
import ReadRecord from "../../../Lib/API/ReadRecord";
import IsDuplicate from "../../../Lib/IsDuplicate";
import { setMessage } from "../../../Lib/SetMessage";
import CreateApproval from "../../../Lib/CreateApproval";
import UpdateRecord from "../../../Lib/API/UpdateRecord";
const NewSmpMain = () => {
  function decodeHtmlEntity(encodedString) {
    return new DOMParser().parseFromString(encodedString, "text/html").body
      .textContent;
  }
  const InitialIs = {
    Injection_stages: "STAGES",
    Injection_pressure: "3",
    Injection_speed: "5",
    Injection_position: "5",
    Injection_time: "0.5",
  };
  const InitialHold = {
    Holding_stages: "",
    Holding_pressure: "3",
    Holding_speed: "5",
    Holding_position: "5",
    Holding_time: "0.5",
  };
  const IntialTempNozzle = {
    Temperature_stages: "Nozzle",
    Temp_set: "5",
    Temp_value: "",
  };
  const IntialTempHopper = {
    Temperature_stages: "Hopper",
    Temp_set: "10",
    Temp_value: "",
  };
  const IntialTempZone = {
    Temperature_stages: "Zone 1",
    Temp_set: "10",
    Temp_value: "",
  };
  const InitRoot = [
    {
      download: false,
      Preparedby: [{}],
      Approvedby: [{}],
      Partname: [{}],
      PartNumber: [{}],
      Part_name: "",
      injection_functional: [
        InitialIs,
        { Injection_stages: "Injection 1" },
        { Injection_stages: "Fill Time(Actual)" },
        { Injection_stages: "Switch Over / Transfer Position(MM)" },
      ],
      refilsetting_functional: [
        {
          Refilsetting_stages: "STAGES",
          Refilsetting_stage_set: "",
          Refilsetting_pressure: "3",
          Refilsetting_speed: "5",
          Refilsetting_position: "5",
          Refilsetting_time: "1",
        },
        { Refilsetting_stages: "Refill 1" },
        { Refilsetting_stages: "Refill End/Short Size(MM)*:" },
        { Refilsetting_stages: "Suck Back(MM)", Refilsetting_stage_set: "2" },
      ],
      holding_functional: [InitialHold, { Holding_stages: "Hold On 1" }],
      temperature_function: [
        IntialTempNozzle,
        IntialTempHopper,
        IntialTempZone,
      ],
      hotruntemp_function: [
        {
          Mani_fold_label: "HOT RUNNER TEMPERATURE",
          Mani_fold_set: "",
          Mani_fold_celsius: "CELSIUS °C",
          Hot_runner_gate: "HOT RUNNER TEMPERATURE",
          Hot_runner_gate_celsius: "",
        },
        { Mani_fold_label: "ManiFold 1", Hot_runner_gate: "Gate 1" },
      ],
      seqemacegate_function: [
        { Seqemace_gate: "Gate", Seqemace_gate_on: "", Seqemace_gate_off: "" },
        { Seqemace_gate: "Gate 1" },
      ],
      mouldtempdetails_function: [
        {
          Mould_temp_details: "Core",
          Mould_temp_set: "5",
          Mould_temp_value: "",
        },
        {
          Mould_temp_details: "Cavity",
          Mould_temp_set: "5",
          Mould_temp_value: "",
        },
        {
          Mould_temp_details: "MTC Temp",
          Mould_temp_set: "3",
          Mould_temp_value: "",
        },
      ],
      injectionunitsettings_function: [
        {
          Inj_unit_stage: "",
          Inj_unit_speed: "SPEED%",
          Inj_unit_time: "",
          Inj_unit_sec: "SEC",
          Inj_unit_break_mode: "SPRUE BREAK MODE",
          Inj_unit_value: "",
        },
        {
          Inj_unit_stage: "INJ UNIT",
          Inj_unit_speed: "ON",
          Inj_unit_time: "Sprue Break",
          Inj_unit_sec: "OFF",
          Inj_unit_break_mode: "0 INJ",
          Inj_unit_value: "",
        },
        {
          Inj_unit_stage: "FWD SPD(MM/S)",
          Inj_unit_speed: "",
          Inj_unit_time: "Delay Time",
          Inj_unit_sec: "",
          Inj_unit_break_mode: "1.Front Suck Back",
          Inj_unit_value: "3.Rear Suck Back",
        },
        {
          Inj_unit_stage: "RET SPD(MM/S)",
          Inj_unit_speed: "",
          Inj_unit_time: "On Time",
          Inj_unit_sec: "",
          Inj_unit_break_mode: "2.Refill",
          Inj_unit_value: "4.Cooling",
        },
        {
          Inj_unit_stage: "Sprue Bush Hole(MM)",
          Inj_unit_time: "",
          Inj_unit_break_mode: "Nozzle Hole(MM) ",
          Inj_unit_value: "",
        },
      ],
      mouldclosesettings_function: [
        {
          Mould_close_stages: `STAGES`,
          Mould_close_pressure: "2",
          Mould_close_speed: "10",
          Mould_close_position: "11",
        },
        {
          Mould_close_stages: "Close 1",
          Mould_close_pressure: "",
          Mould_close_speed: "",
          Mould_close_position: "",
        },
        {
          Mould_close_stages: "Close 2",
          Mould_close_pressure: "",
          Mould_close_speed: "",
          Mould_close_position: "",
        },
        {
          Mould_close_stages: "Close 3",
          Mould_close_pressure: "",
          Mould_close_speed: "",
          Mould_close_position: "",
        },
        {
          Mould_close_stages: "Mould safety/Protect 1",
          Mould_close_pressure: "",
          Mould_close_speed: "",
          Mould_close_position: "",
        },
        {
          Mould_close_stages: "Mould safety/Protect 2",
          Mould_close_pressure: "",
          Mould_close_speed: "",
          Mould_close_position: "",
        },
        {
          Mould_close_stages: "Mould Safety Time(SEC):",
          Mould_close_speed: "Actual:",
        },
      ],
      mouldopensettings_function: [
        {
          Mould_open_stages: "STAGES",
          Mould_open_pressure: "3",
          Mould_open_speed: "5",
          Mould_open_position: "5",
        },
        { Mould_open_stages: "Open 1 " },
        { Mould_open_stages: "Open 2 " },
        { Mould_open_stages: "Open 3 " },
      ],
      ejectionsettings_function: [
        {
          Ejection_settings_stages: "STAGES",
          Ejection_settings_pressure: "2",
          Ejection_settings_speed: "10",
          Ejection_settings_time: "0.5",
          Ejection_settings_position: "5",
        },
        { Ejection_settings_stages: "EJEC FRD 1" },
        { Ejection_settings_stages: "EJEC FRD 2" },
        { Ejection_settings_stages: "EJEC RET 1" },
        { Ejection_settings_stages: "EJEC RET 2" },
      ],
      coresettings_function: [
        {
          Core_settings_stages: "STAGES",
          Core_settings_pressure: "2",
          Core_settings_speed: "10",
          Core_settings_time: "0.5",
          Core_settings_position: "5",
        },
        { Core_settings_stages: "CORE 1 IN" },
        { Core_settings_stages: "CORE 1 OUT" },
        { Core_settings_stages: "CORE 2 IN" },
        { Core_settings_stages: "CORE 2 OUT" },
      ],
      backpres_function: [
        { Back_press_stages: "STAGES", Back_press_pressure: "2" },
        { Back_press_stages: "Back Pres 1" },
        { Back_press_stages: "Back Pres 2" },
        { Back_press_stages: "Melt Cushion(MM)*", Back_press_set: "0.5" },
        { Back_press_stages: "Cooling Time(SEC)*", Back_press_set: "1" },
        { Back_press_stages: "Cycle Time(SEC)", Back_press_set: "5" },
        { Back_press_stages: "Oil Temp°C", Back_press_set: "10" },
      ],
      Part_number: "",
      Rawmaterialname: [{}],
      Raw_material_name: "",
      Prepared_by: "",
      Approved_by: "",
      Model: "",
      Machine_no: "",
      MachineNo: [{}],
      Grade: "",
      Colour: "",
      Model: "",
      Runner_weight: "",
      Part_weight_1: "",
      Part_weight_2: "",
      No_of_cavity: "",
      Entry_date: CURRENT_Date,
      Part_weight_set_plus_1: "",
      Part_weight_set_plus_2: "",
      Part_weight_set_minus_1: "",
      Part_weight_set_minus_2: "",
      Format_no: "UPCL/PRO/D/01",
      Rev_no: "1",
      Rev_ate: "29.06.2015",
    },
  ];
  const { option, id } = useParams();
  const navigate = useNavigate();
  const [Records, setRecords] = useState(InitRoot);
  const [warning, setwarning] = useState(false);
  const [partWeight, setpartWeight] = useState(false);
  const [Preview, setPreView] = useState(false);
  const [Raw, setRaw] = useState([]);
  const [Machine, setMachine] = useState([]);
  function InvalidCharacters(value) {
    return /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/.test(value);
  }
  useEffect(() => {
    console.log("Records", Records);
  }, [Records]);
  useEffect(() => {
    console.log("id", id);
    const fetchData = async () => {
      if (id === undefined) {
        setRecords(InitRoot);
      } else if(option != "Duplicate") {
        try {
          const res = await ReadRecord({
            _keyword_: "production_smp_parent",
            Id: id,
          });

          if (res.data[0] === undefined) {
            setRecords(InitRoot);
          } else {
            setRecords(res.data);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          const res = await ReadRecord({
            _keyword_: "production_smp_parent_duplicate",
            Id: id,
          });

          if (res.data[0] === undefined) {
            setRecords(InitRoot);
          } else {
            console.log("InitRoot",res.data);
            let resp = res.data[0];
            setRecords(()=>{
              let record = [...Records]
              record[0].backpres_function = resp.backpres_function,
              record[0].coresettings_function = resp.coresettings_function,
              record[0].ejectionsettings_function = resp.ejectionsettings_function,
              record[0].holding_functional = resp.holding_functional,
              record[0].hotruntemp_function = resp.hotruntemp_function,
              record[0].injection_functional = resp.injection_functional,
              record[0].injectionunitsettings_function = resp.injectionunitsettings_function,
              record[0].mouldclosesettings_function = resp.mouldclosesettings_function,
              record[0].mouldopensettings_function = resp.mouldopensettings_function,
              record[0].refilsetting_functional = resp.refilsetting_functional,
              record[0].seqemacegate_function = resp.seqemacegate_function,
              record[0].temperature_function = resp.temperature_function;
              record[0].mouldtempdetails_function = resp.mouldtempdetails_function;
              record && delete record[0].ModifiedDate;
          record && delete record[0].CreatedDate;
          record && delete record[0].Id;
              // record[0].download = false,
              // record[0].Preparedby = [{}],
              // record[0].Approvedby = [{}],
              // record[0].Partname = [{}],
              // record[0].PartNumber = [{}],
              // record[0].Part_name = "",
              // record[0].Part_number = "",
              // record[0].Rawmaterialname = [{}],
              // record[0].Raw_material_name = "",
              // record[0].Prepared_by = "",
              // record[0].Approved_by = "",
              // record[0].Model = "",
              // record[0].Machine_no = "",
              // record[0].MachineNo = [{}],
              // record[0].Grade = "",
              // record[0].Colour = "",
              // record[0].Model = "",
              // record[0].Runner_weight = "",
              // record[0].Part_weight_1 = "",
              // record[0].Part_weight_2 = "",
              // record[0].No_of_cavity = "",
              // record[0].Entry_date = CURRENT_Date,
              // record[0].Part_weight_set_plus_1 = "",
              // record[0].Part_weight_set_plus_2 = "",
              // record[0].Part_weight_set_minus_1 = "",
              // record[0].Part_weight_set_minus_2 = "",
              // record[0].Format_no = "UPCL/PRO/D/01",
              // record[0].Rev_no = "1",
              // record[0].Rev_ate = "29.06.2015";

              return record
            });
            
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchData();
  }, [id]);
  function HandleRecords(Event) {
    setRecords([{ ...Records[0], [Event.target.name]: Event.target.value }]);
  }
  function HandleRecordSelect(Event, column, value, label) {
    setRecords(() => {
      let record = [...Records];
      record[0][Event.name] = Event.values.value;
      record[0][column][0][value] = Event.values.value;
      record[0][column][0][label] = Event.values.label;
      return record;
    });
  }
  function HandleCreate(event) {
    if (
      !Records[0].Part_name ||
      !Records[0].Raw_material_name ||
      !Records[0].Approved_by ||
      !Records[0].Prepared_by ||
      !Records[0].Model ||
      !Records[0].Part_weight_1 ||
      !Records[0].Part_weight_set_minus_1 ||
      !Records[0].Part_weight_set_plus_1 ||
      !Records[0].Runner_weight ||
      !Records[0].Part_number ||
      !Records[0].Machine_no
    ) {
      alert("Please Fill the Mandatory Fields");
      setwarning(true);
      setPreView(false);
    } else {
      try {
        let Approver = Records[0]?.Approvedby[0]?.Emp_name;
        let Prepared = Records[0]?.Preparedby[0]?.Emp_name;
        let record = [...Records];
        record && delete record[0].Partname;
        record && delete record[0].PartNumber;
        record && delete record[0].Rawmaterialname;
        record && delete record[0].MachineNo;
        record && delete record[0].Preparedby;
        record && delete record[0].Approvedby;
        record && delete record[0].download;
        // console.log("recordsdssds", record);
        CreateRecord("Production_smp_parent", record).then(async (res) => {
          console.log("HandleCreate", res);
          if (res.feedback[0].success === true) {
            await setMessage({
              NoteFrom: record[0].Prepared_by,
              NoteTo: record[0].Approved_by,
              NoteMessage: `Hi Mr.${Approver} SMP Master for part Number ${record[0]?.Part_name} in machine ${record[0]?.Machine_no}, This was prepared by ${record[0]?.Prepared_by}-${Prepared}`,
              NoteRecordId: res.ROWID,
              TableName: "newSmpMain",
              NoteCreatedBy: localStorage.getItem("empId"),
            });
            await CreateApproval({
              RequestedBy: record[0].Prepared_by,
              Approver: record[0].Approved_by,
              Level: 1,
              Status: "Waiting for Approval",
              TableName: "newSmpMain",
              TableID: res.ROWID,
              Description:
                "The SMP Master Has been Created By - " +
                Prepared +
                ", For The Part Name - " +
                record[0]?.Part_name +
                " aginst the Machine " +
                record[0]?.Machine_no,
              CreatedBy: localStorage.getItem("empId"),
            });
            alert("Submited Successfully");
            setwarning(false);
            setRecords(InitRoot);
            setRaw([]);
            setPreView(false);
            navigate('/newSmpMain');
            window.location.reload();
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }
  function HandleUpdate(event) {
    try {
      let record = [...Records];
      record && delete record[0].Partname;
      record && delete record[0].PartNumber;
      record && delete record[0].Rawmaterialname;
      record && delete record[0].MachineNo;
      record && delete record[0].Preparedby;
      record && delete record[0].Approvedby;
      record && delete record[0].download;
      console.log("recordsdssds", record);
      let noId = {};
      let fields = [
        "injection_functional",
        "refilsetting_functional",
        "holding_functional",
        "temperature_function",
        "hotruntemp_function",
        "seqemacegate_function",
        "mouldclosesettings_function",
        "mouldopensettings_function",
      ];
      fields.map((field) => {
        record[0][field].map((data) => {
          if (!noId[field]) {
            noId[field] = data.Id === undefined ? true : false;
          }
        });
      });
      console.log("noId", noId);
      Object.keys(noId).map((item) => {
        if (noId[item]) {
          DeleteOriginalRecord(item, [{ Smp_id: record[0].Id }]).then((res) => {
            console.log("DeleteOriginalRecord", res);
            const newData = record[0][item].map((obj) => {
              const newObj = { ...obj };
              delete newObj.Id;
              newObj.Smp_id = record[0].Id;
              return newObj;
            });
            console.log("newData", newData);
            CreateRecord(item, newData);
            delete record[0][item];
          });
          console.log("noId", record[0][item]);
        }
      });
      UpdateRecord("Production_smp_parent", record).then((res) => {
        console.log("HandleUpdate", res);
        if (res.feedback[0].count > 0) {
          alert("record Updated!");
          setwarning(false);
          setRecords(InitRoot);
          setRaw([]);
          navigate(-1);
          setPreView(false);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    setwarning(false);
    document.title = "SMP";
    setRecords([
      {
        ...Records[0],
        Entry_date: CURRENT_Date,
      },
    ]);
  }, []);
  async function HandlePartname(Event) {
    console.log("Event", Event.values.value);
    // if (await IsDuplicate("production_smp_parent", [`Part_name="${Event.values.value}"`,`Part_number`])) {
    await ReadRecord({
      _keyword_: "Get_model_by_part_name",
      part: Event.values.value,
    }).then(async (res) => {
      console.log(res.data[0].mc_part_flex_01);
      setRecords(() => {
        let record = [...Records];
        record[0].No_of_cavity = res.data[0].mc_part_flex_02;
        record[0][Event.name] = Event.values.value;
        (record[0].Model = res.data[0].mc_part_flex_01),
          (record[0].Part_number = res.data[0].mc_part_code),
          (record[0].Part_name = res.data[0].mc_part_name),
          (record[0]["Partname"][0]["mc_part_name"] = Event.values.value);
        // record[0]["Partname"][0]["mc_part_name"] = Event.values.label;
        record[0]["PartNumber"][0]["mc_part_code"] = res.data[0].mc_part_code;
        // record[0]["PartNumber"][0]["mc_part_code"] = res.data[0].mc_part_code;
        console.log(
          "GetRawMaterial(res.data[0].mc_part_name)",
          res.data[0].mc_part_name
        );
        GetPartWeightFromQualityPLan(
          res.data[0].mc_part_code,
          res.data[0].mc_part_name
        );
        GetRawMaterial(res.data[0].mc_part_name);
        // GetMachineNo(res.data[0].mc_part_code);
        return record;
      });
    });
    // } else {
    //     alert("Part and Machine already Has Record in Quality Plan Records")
    // }
  }
  function HandlePartCode(Event) {
    Records[0].No_of_cavity = "";
    Records[0].Model = "";
    Records[0].Part_name = "";
    Records[0].Grade = "";
    Records[0].Colour = "";
    Records[0]["Partname"][0]["mc_part_name"] = "";
    (Records[0].Raw_material_name = ""),
      (Records[0]["Rawmaterialname"][0]["Mh_raw_mat"] = "");
    Records[0]["Rawmaterialname"][0]["Mh_raw_mat_name"] = "";
    console.log("Event", Event.values.value);
    ReadRecord({
      // _keyword_: "Get_part_name_from_head_by_code",
      _keyword_: "Get_part_name_from_quality_by_code",
      key: Event.values.value,
    }).then((res) => {
      if (res.rows === "1") {
        // if (await IsDuplicate("production_smp_parent", [`Part_name="${res.data[0].mc_part_name}"`])) {
        setRecords(() => {
          let record = [...Records];
          (record[0].Part_number = Event.values.value),
            (record[0]["PartNumber"][0]["mc_part_code"] = Event.values.value);
          // record[0]["PartNumber"][0]["mc_part_code"] = Event.values.label;
          (record[0].Part_name = res.data[0].mc_part_name),
            (record[0]["Partname"][0]["mc_part_name"] =
              res.data[0].mc_part_name);
          // record[0]["Partname"][0]["mc_part_name"] = res.data[0].mc_part_name;
          console.log("12131213", record);
          GetRawMaterial(res.data[0].mc_part_name);
          GetPartWeightFromQualityPLan(
            Event.values.value,
            res.data[0].mc_part_name
          );
          // GetMachineNo(Event.values.value);
          ReadRecord({
            _keyword_: "Get_model_by_part_name",
            part: res.data[0].mc_part_name,
          }).then((res) => {
            console.log(res.data[0].mc_part_flex_01);
            setRecords((record) => {
              // let record = [...Records];
              record[0].Model = res.data[0].mc_part_flex_01;
              record[0].No_of_cavity = res.data[0].mc_part_flex_02;
              return record;
            });
          });
          return record;
        });
        // } else {
        //     alert("Part and Machine already Has Record in Quality Plan Records")
        // }
      } else if (res.rows > "1") {
        console.log("res.data", res.data);
        setRecords(() => {
          let record = [...Records];
          (record[0].Part_number = Event.values.value),
            (record[0]["PartNumber"][0]["mc_part_code"] = Event.values.value);
          // record[0]["PartNumber"][0]["mc_part_code"] = Event.values.label;
          return record;
        });
      }
    });
  }
  function GetRawMaterial(val) {
    ReadRecord({
      _keyword_: "Get_raw_material_by_part",
      part: val,
    }).then((res) => {
      if (res.rows == "1") {
        setRecords(() => {
          //       console.log("Event.values.value", Event.values.value);
          let record = [...Records];
          (record[0].Grade = res.data[0].Rm_grade),
            (record[0].Colour = res.data[0].Rm_Colour),
            (record[0].Raw_material_name = res.data[0].Mh_raw_mat),
            (record[0]["Rawmaterialname"][0]["Mh_raw_mat"] =
              res.data[0].Mh_raw_mat);
          record[0]["Rawmaterialname"][0]["Mh_raw_mat_name"] =
            res.data[0].Mh_raw_mat_name;
          GetMachineNo(record[0].Part_number);
          //     IsDuplicate("production_smp_parent", [`Part_name='${record[0].Part_name}'`, `Part_number='${record[0].Part_number}'`, `Raw_material_name='${res.data[0].Mh_raw_mat}'`]).then((res) => {
          //         console.log("IsDuplicate", res);
          //         if (res === true) {
          //             setRecords(InitRoot);
          //             alert("Part and Machine already Has Record in Standard Moulding Parameter!")
          //         }
          //     })
          return record;
        });
      } else if (res.rows > "1") {
        GetMachineNo(Records[0].Part_number);
        console.log("respose of raw", res.data);
        setRaw(res.data);
      } else {
        alert(
          "Contact Administrator to add Raw Material for this Selected Part!"
        );
      }
    });
  }
  function GetPartWeightFromQualityPLan(Part_code, Part_name) {
    ReadRecord(
      `Select part_weight_1,part_weight_set_minus_1,part_weight_set_plus_1,part_weight_2,part_weight_set_minus_2,part_weight_set_plus_2,Two_k_part from quality_product_plan where part_number = '${Part_code}' and part_name = '${Part_name}' and ActiveStatus = '1'`
    ).then((response) => {
      console.log(
        "response",
        response.data[0].rows[0]
      );
      if (response.data[0].rows[0] === undefined) {
        alert(" Add QC");
        return; 
       }
      if (
        response.data[0].rows[0].part_weight_2 === "" ||
        response.data[0].rows[0].part_weight_2 === undefined
      ) {
        setpartWeight(false);
        setRecords(() => {
        let records = [...Records];
        records[0].Part_weight_1 = response.data[0].rows[0].part_weight_1,
        records[0].Part_weight_set_minus_1 = response.data[0].rows[0].part_weight_set_minus_1,
        records[0].Part_weight_set_plus_1 = response.data[0].rows[0].part_weight_set_plus_1;
        records[0].Two_k_part = response.data[0].rows[0].Two_k_part;
        return records;
      });
      }else{
        setpartWeight(true);
        setRecords(() => {
          let records = [...Records];
          records[0].Part_weight_1 = response.data[0].rows[0].part_weight_1,
          records[0].Part_weight_set_minus_1 = response.data[0].rows[0].part_weight_set_minus_1,
          records[0].Part_weight_set_plus_1 = response.data[0].rows[0].part_weight_set_plus_1;
          records[0].Part_weight_2 = response.data[0].rows[0].part_weight_2,
          records[0].Part_weight_set_minus_2 = response.data[0].rows[0].part_weight_set_minus_2,
          records[0].Part_weight_set_plus_2 = response.data[0].rows[0].part_weight_set_plus_2;
        records[0].Two_k_part = response.data[0].rows[0].Two_k_part;
          return records;
        });
      }
      
    });
  }
  function GetMachineNo(val) {
    ReadRecord({
      _keyword_: "Get_machine_by_part_code_from_head",
      part: val,
    }).then((res) => {
      if (res.rows == "1") {
        setRecords(() => {
          //       console.log("Event.values.value", Event.values.value);
          let record = [...Records];
          record[0].Machine_no = res.data[0].mc_code;
          record[0]["MachineNo"][0]["mc_code"] = res.data[0].mc_code;
          record[0]["MachineNo"][0]["mc_name"] = res.data[0].mc_name;
          IsDuplicate("production_smp_parent", [
            `Part_name='${record[0].Part_name}'`,
            `Part_number='${record[0].Part_number}'`,
            `Raw_material_name='${record[0].Raw_material_name}'`,
            `Machine_no='${res.data[0].mc_code}'`,
            `ActiveStatus='1'`,
          ]).then((res) => {
            console.log("IsDuplicateGetMachineNo", res);
            if (res === true) {
              setRecords(InitRoot);
              alert(
                "Part and Machine already Has Record in Standard Moulding Parameter!"
              );
            }
          });
          return record;
        });
      } else if (res.rows > "1") {
        console.log("respose of raw", res.data);
        setMachine(res.data);
      } else {
        alert(
          "Contact Administrator to add Raw Material for this Selected Part!"
        );
      }
    });
  }
  function HandleMachineDuplicate(Event) {
    IsDuplicate("production_smp_parent", [
      `Part_name='${Records[0].Part_name}'`,
      `Part_number='${Records[0].Part_number}'`,
      `Raw_material_name='${Records[0].Raw_material_name}'`,
      `Machine_no='${Event.values.value}'`,
      `ActiveStatus='1'`,
    ]).then((res) => {
      console.log("IsDuplicateHandleMachineDuplicate", res);
      if (res === true) {
        setRecords(InitRoot);
        alert(
          "Part and Machine already Has Record in Standard Moulding Parameter!"
        );
      }
    });
  }
  function GetGradeColour(Event) {
    const selctedRaw = Raw.find(
      (option) => option.Mh_raw_mat === Event.values.value
    );
    let record = [...Records];
    record[0].Grade = selctedRaw.Rm_grade;
    record[0].Colour = selctedRaw.Rm_Colour;
    // IsDuplicate("production_smp_parent", [`Part_name='${record[0].Part_name}'`, `Part_number='${record[0].Part_number}'`, `Raw_material_name='${Event.values.value}'`]).then((res) => {
    //     console.log("IsDuplicate", res);
    //     if (res === true) {
    //         setRecords(InitRoot);
    //         setRaw([]);
    //         alert("Part and Machine already Has Record in Standard Moulding Parameter!");
    //     }
    // })
    return record;
  }
  return (
    <QaLayout record={Records} quary={"production_smp_parent"}>
      <div className="Form" style={{ height: "100vh" }}>
        <table>
          <tr>
            <td>
              <Field
                classLabel={"smpLabelField"}
                className={"SmpField"}
                label={"Part Number"}
                name={"Part_number"}
                options={{
                  type: "reference",
                  reference: "Get_part_code_from_quality_for_smp",
                  required: true,
                  disabled: option === "view" || option === "edit",
                }}
                warning={warning}
                value={{
                  value: Records[0]?.PartNumber[0]
                    ? Records[0]?.PartNumber[0]?.mc_part_code
                    : "",
                  label: Records[0]?.PartNumber[0]
                    ? Records[0]?.PartNumber[0]?.mc_part_code
                    : "",
                }}
                // OnChange={(e) => {
                //     HandleRecordSelect(e, "PartNumber", "mc_part_code", "mc_part_code");
                // }}
                OnChange={(e) => {
                  HandlePartCode(e);
                }}
              />
            </td>
            <td>
              <Field
                className={"SmpField"}
                classLabel={"smpLabelField"}
                label={"Part Name"}
                name={"Part_name"}
                options={{
                  type: "reference",
                  key: Records[0]?.Part_number,
                  reference: "Get_part_name_from_quality_for_smp",
                  required: true,
                  disabled: option === "view" || option === "edit",
                }}
                warning={warning}
                value={{
                  value: Records[0].Partname[0]
                    ? Records[0].Partname[0].mc_part_name
                    : "",
                  label: Records[0].Partname[0]
                    ? Records[0].Partname[0].mc_part_name
                    : "",
                }}
                // OnChange={(e) => {
                //     HandleRecordSelect(e, "Partname", "mc_part_name", "mc_part_name");
                // }}
                OnChange={(e) => {
                  HandlePartname(e);
                }}
              />
            </td>
            <td>
              <Field
                classLabel={"smpLabelField"}
                className={"SmpField"}
                label={"RM Name"}
                name={"Raw_material_name"}
                options={{
                  type: "NoneReference",
                  reference: "Raw_material",
                  values: Raw,
                  required: true,
                  disabled: option === "view" || option === "edit",
                }}
                warning={warning}
                value={{
                  value: Records[0].Rawmaterialname[0]
                    ? Records[0].Rawmaterialname[0].Mh_raw_mat
                    : "",
                  label: Records[0].Rawmaterialname[0]
                    ? Records[0].Rawmaterialname[0].Mh_raw_mat_name
                    : "",
                }}
                OnChange={(e) => {
                  HandleRecordSelect(
                    e,
                    "Rawmaterialname",
                    "Mh_raw_mat",
                    "Mh_raw_mat_name"
                  );
                  GetGradeColour(e);
                }}
              />
            </td>
            <td>
              <Field
                classLabel={"smpLabelField"}
                className={"SmpField"}
                label={"Machine No"}
                name={"Machine_no"}
                options={{
                  type: "NoneReference",
                  reference: "Machine_Number_master",
                  values: Machine,
                  required: true,
                  disabled: option === "view" || option === "edit",
                }}
                warning={warning}
                value={{
                  value: Records[0].MachineNo[0]
                    ? Records[0].MachineNo[0].mc_code
                    : "",
                  label: Records[0].MachineNo[0]
                    ? Records[0].MachineNo[0].mc_name
                    : "",
                }}
                OnChange={(e) => {
                  HandleRecordSelect(e, "MachineNo", "mc_code", "mc_name");
                  HandleMachineDuplicate(e);
                }}
              />
            </td>
          </tr>
          <tr>
            <td>
              <Field
                classLabel={"smpLabelField"}
                className={"SmpField"}
                label={"Model"}
                name={"Model"}
                options={{ type: "text", disabled: true }}
                value={Records[0]}
                OnChange={(e) => {
                  HandleRecords(e);
                }}
              />
            </td>
            <td>
              <Field
                classLabel={"smpLabelField"}
                className={"SmpField"}
                label={"No of Cavity"}
                name={"No_of_cavity"}
                options={{ type: "text", disabled: true }}
                value={Records[0]}
                OnChange={(e) => {
                  HandleRecords(e);
                }}
              />
            </td>
            <td>
              <Field
                classLabel={"smpLabelField"}
                className={"SmpField"}
                label={"Grade Name"}
                name={"Grade"}
                options={{ type: "text", disabled: true }}
                value={Records[0]}
                OnChange={(e) => {
                  HandleRecords(e);
                }}
              />
            </td>
            <td>
              <Field
                classLabel={"smpLabelField"}
                className={"SmpField"}
                label={"Colour"}
                name={"Colour"}
                options={{ type: "text", disabled: true }}
                value={Records[0]}
                OnChange={(e) => {
                  HandleRecords(e);
                }}
              />
            </td>
          </tr>

          <tr>
            <td>
              <div style={{ display: "flex", flexWrap: "nowrap" }}>
                {/* <div>
                                    <label>{`Part Weight:`}</label>
                                </div> */}
                <span className="MinusPlus">(-</span>
                <div>
                  <Field
                    className={"fieldSingleGrid"}
                    label={""}
                    name={"Part_weight_set_minus_1"}
                    options={{
                      type: "text",
                      disabled: option == "view",
                      required: true,
                      pattern: "[0-9]*",
                      disabled: true
                    }}
                    warning={warning}
                    value={Records[0]}
                    OnChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }
                      HandleRecords(e);
                    }}
                  />
                  {/* <input className="SmpMaininput" type="text" name={"Part_weight_set_minus_1"} value={Records[0].Part_weight_set_minus_1} onChange={(e) => { HandleRecords(e) }} ></input> */}
                </div>
                <span className="MinusPlus">/+</span>
                <div>
                  <Field
                    className={"fieldSingleGrid"}
                    label={""}
                    name={"Part_weight_set_plus_1"}
                    options={{
                      type: "text",
                      disabled: option == "view",
                      required: true,
                      disabled: true
                    }}
                    warning={warning}
                    value={Records[0]}
                    OnChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }
                      HandleRecords(e);
                    }}
                  />
                  {/* <input className="SmpMaininput" type="text" name={"Part_weight_set_plus_1"} value={Records[0].Part_weight_set_plus_1} onChange={(e) => { HandleRecords(e) }}></input> */}
                </div>
                <span className="MinusPlus">)</span>
              </div>
            </td>
            <td>
              <div style={{ display: "flex" }}>
                <div>
                  <Field
                    className={"SmpField"}
                    label={"Part Weight1"}
                    name={"Part_weight_1"}
                    classLabel={"smpLabelField"}
                    options={{ type: "text", required: true, disabled: true }}
                    warning={warning}
                    value={Records[0]}
                    OnChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }
                      HandleRecords(e);
                    }}
                  />
                </div>
                {/* <div>
                  <button
                    onClick={(e) => {
                      setpartWeight(!partWeight);
                    }}
                    style={{
                      borderRadius: "50%", // This will make the button round
                      width: "8px", // Adjust width as needed
                      height: "30px", // Adjust height as needed
                      border: "none", // Remove default border
                      // backgroundColor: 'lightblue', // Example background color
                      color: "black", // Example text color
                      fontSize: "16px", // Example font size
                      cursor: "pointer", // Show pointer cursor on hover
                      marginBottom: "0px",
                      boxShadow: "0 4px 2px -2px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    +
                  </button>
                </div> */}
              </div>
            </td>
            <td>
              {partWeight && (
                <div style={{ display: "flex", flexWrap: "nowrap" }}>
                  {/* <div>
                                    <label>{`Part Weight:`}</label>
                                </div> */}
                  <span className="MinusPlus">(-</span>
                  <div>
                    <Field
                      className={"fieldSingleGrid"}
                      label={""}
                      name={"Part_weight_set_minus_2"}
                      options={{
                        type: "text",
                        disabled: true,
                      }}
                      value={Records[0]}
                      OnChange={(e) => {
                        const value = e.target.value;
                        if (InvalidCharacters(value)) {
                          e.preventDefault();
                          return;
                        }
                        HandleRecords(e);
                      }}
                    />
                    {/* <input className="SmpMaininput" type="text" name={"Part_weight_set_minus_1"} value={Records[0].Part_weight_set_minus_1} onChange={(e) => { HandleRecords(e) }} ></input> */}
                  </div>
                  <span className="MinusPlus">/+</span>
                  <div>
                    <Field
                      className={"fieldSingleGrid"}
                      label={""}
                      name={"Part_weight_set_plus_2"}
                      options={{
                        type: "text",
                        disabled: true,
                      }}
                      value={Records[0]}
                      OnChange={(e) => {
                        const value = e.target.value;
                        if (InvalidCharacters(value)) {
                          e.preventDefault();
                          return;
                        }
                        HandleRecords(e);
                      }}
                    />
                    {/* <input className="SmpMaininput" type="text" name={"Part_weight_set_plus_1"} value={Records[0].Part_weight_set_plus_1} onChange={(e) => { HandleRecords(e) }}></input> */}
                  </div>
                  <span className="MinusPlus">)</span>
                </div>
              )}
            </td>
            <td>
              {partWeight && (
                <Field
                  className={"SmpField"}
                  label={"Part Weight2"}
                  name={"Part_weight_2"}
                  classLabel={"smpLabelField"}
                  options={{ type: "text", required: true,disabled: true }}
                  warning={warning}
                  value={Records[0]}
                  OnChange={(e) => {
                    const value = e.target.value;
                    if (InvalidCharacters(value)) {
                      e.preventDefault();
                      return;
                    }
                    HandleRecords(e);
                  }}
                />
              )}
            </td>
          </tr>
          <tr>
            <td>
              <Field
                className={"SmpField"}
                label={"Runner Weight"}
                name={"Runner_weight"}
                classLabel={"smpLabelField"}
                options={{ type: "text", required: true }}
                warning={warning}
                value={Records[0]}
                OnChange={(e) => {
                  const value = e.target.value;
                  if (InvalidCharacters(value)) {
                    e.preventDefault();
                    return;
                  }
                  HandleRecords(e);
                }}
              />
            </td>
            <td>
              <Field
                labelStyle={{ textAlign: "center" }}
                className={"SmpField"}
                label={"Date"}
                name={"Entry_date"}
                options={{ type: "date", disabled: true }}
                value={Records[0]}
                OnChange={(e) => {
                  HandleRecords(e);
                }}
              />
            </td>
            <td>
              <Field
                classLabel={"smpLabelField"}
                className={"SmpField"}
                label={"Prepared By"}
                name={"Prepared_by"}
                // doubleLine={true}
                options={{
                  type: "reference",
                  reference: "emp_details_dropdown",
                  required: true,
                  disabled: option === "view",
                }}
                warning={warning}
                value={{
                  value: Records[0].Preparedby[0]
                    ? Records[0].Preparedby[0].Emp_code
                    : "",
                  label: Records[0].Preparedby[0]
                    ? Records[0].Preparedby[0].Emp_name
                    : "",
                }}
                OnChange={(e) => {
                  HandleRecordSelect(e, "Preparedby", "Emp_code", "Emp_name");
                }}
              />
            </td>
            <td>
              <Field
                classLabel={"smpLabelField"}
                className={"SmpField"}
                label={"Approver"}
                name={"Approved_by"}
                // doubleLine={true}
                options={{
                  type: "reference",
                  reference: "emp_details_dropdown",
                  required: true,
                  disabled: option === "view",
                }}
                warning={warning}
                value={{
                  value: Records[0].Approvedby[0]
                    ? Records[0].Approvedby[0].Emp_code
                    : "",
                  label: Records[0].Approvedby[0]
                    ? Records[0].Approvedby[0].Emp_name
                    : "",
                }}
                OnChange={(e) => {
                  HandleRecordSelect(e, "Approvedby", "Emp_code", "Emp_name");
                }}
              />
            </td>
          </tr>
        </table>
        <NewSmpTabs
          Records={Records}
          setRecords={setRecords}
          InitialIs={InitialIs}
          InitialHold={InitialHold}
        />
        {/* <tr>
                    <td colSpan={19}>
                        {" "}
                        <Field
                            label={"Submit"}
                            options={{ type: "Button" }}
                            onClick={(e) => {
                                HandleCreate(e);
                            }}
                        />
                    </td>
                </tr> */}
        {Preview && (
          <PreViewSmpPage
            Records={Records}
            setState={setPreView}
            HandleCreate={HandleCreate}
            option={option}
            HandleUpdate={HandleUpdate}
            id={id}
          />
        )}
        {/* <table>
                <tr>
                    <td> */}{" "}
        <Field
          className={"submit"}
          label={"Preview"}
          options={{ type: "Button" }}
          onClick={() => {
            setPreView(true);
          }}
        />
        {/* </td>
                </tr>
                </table> */}
      </div>
    </QaLayout>
  );
};
export default NewSmpMain;
