//external import
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

//internal import
import Field from "../../../Components/Fields/Field";
import Lable from "../../../Components/Fields/Lable";
import QaLayout from "../../Quality/QaLayout";

//css import
import './NewSmpMain.css'
//API imports
const EjectionSettings = ({ Records, setRecords, setActiveKey, activeKey }) => {
    const { option, id } = useParams();
    const [ctrlPressed, setCtrlPressed] = useState(false);
    function InvalidCharacters(value) {
        return /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/.test(value);
      }
    useEffect(() => {
        if (activeKey === "eleven") {
            document.getElementById("1-Ejection_settings_pressure").focus();
          }
        const handleKeyDown = (event) => {
            
              
          let activeField = document.activeElement;
          let fieldOptions = {
            Ejection_settings_pressure: ["", "Ejection_settings_speed"],
            Ejection_settings_speed: ["Ejection_settings_pressure", "Ejection_settings_time"],
            Ejection_settings_time: ["Ejection_settings_speed", "Ejection_settings_position"],
            Ejection_settings_position: ["Ejection_settings_time", ""],
          };    
    
          let row = Records[0].ejectionsettings_function.length - 1;
          let currentField = activeField.id.split("-")[1];
          let indexOfField = activeField.id.split("-")[0];
          let nextField = "";
    
          if (currentField != undefined) {
            switch (event.ctrlKey + "+" + event.key + "+" + activeKey) {
              case "false+ArrowRight+eleven":
                nextField = fieldOptions[currentField][1];
                if (indexOfField > 0 && indexOfField < row+1) {
                  if (nextField != "") {
                    document.getElementById(indexOfField + "-" + nextField).focus();
                  } else if (indexOfField < row) {
                    document
                      .getElementById(
                        Number(indexOfField) + 1 + "-Ejection_settings_pressure"
                      )
                      .focus();
                  }
                }
                break;
    
              case "false+ArrowLeft+eleven":
                nextField = fieldOptions[currentField][0];
                if (
                  indexOfField > 0 &&
                  indexOfField < row + 1 &&
                  activeField.id != "1-Ejection_settings_pressure"
                ) {
                  if (nextField != "") {
                    document.getElementById(indexOfField + "-" + nextField).focus();
                  } else {
                    document
                      .getElementById(indexOfField - 1 + "-Ejection_settings_position")
                      .focus();
                  }
                }
                break;
    
              case "false+ArrowDown+eleven":
                // console.log("indexOfField",indexOfField,row,indexOfField == row);
                if (indexOfField < row) {
                  document
                    .getElementById(Number(indexOfField) + 1 + "-" + currentField)
                    .focus();
                }
                break;
    
              case "false+ArrowUp+eleven":
                if (indexOfField > 1) {
                  //   if (indexOfField < row + 1) {
                  document
                    .getElementById(Number(indexOfField) - 1 + "-" + currentField)
                    .focus();
                  //   }
                }
                break;
    
              case "true+ArrowRight+eleven":
                setActiveKey("twele");
                break;
    
    
              default:
                break;
            }
          }
        };
    
        document.addEventListener("keydown", handleKeyDown);
        return () => {
          document.removeEventListener("keydown", handleKeyDown);
        };
      }, [activeKey, setActiveKey]);

    function HandleEjectionSet(Event, index) {
        setRecords((item) => {
            let record = [...Records];
            record[0].ejectionsettings_function[index] = {
                ...record[0].ejectionsettings_function[index],
                [Event.target.name]: Event.target.value,
            };
            return record;
        });
    }
    const DropdownValues = [
        { value: "", label: "Select" },
        { value: "1", label: "±1" },
        { value: "2", label: "±2" },
        { value: "3", label: "±3" },
        { value: "4", label: "±4" },
        { value: "5", label: "±5" },
        { value: "6", label: "±6" },
        { value: "7", label: "±7" },
        { value: "8", label: "±8" },
        { value: "9", label: "±9" },
        { value: "10", label: "±10" },
        { value: "11", label: "±11" },
        { value: "12", label: "±12" },
        { value: "13", label: "±13" },
        { value: "14", label: "±14" },
        { value: "15", label: "±15" },
    ];
    const DropdownValuesTime = [
        { value: "", label: "Select" },
        { value: "0.1", label: "±0.1" },
        { value: "0.2", label: "±0.2" },
        { value: "0.3", label: "±0.3" },
        { value: "0.4", label: "±0.4" },
        { value: "0.5", label: "±0.5" },
        { value: "0.6", label: "±0.6" },
        { value: "0.7", label: "±0.7" },
        { value: "0.8", label: "±0.8" },
        { value: "0.9", label: "±0.9" },
        { value: "1", label: "±1" },
        { value: "2", label: "±2" },
        { value: "3", label: "±3" },
        { value: "4", label: "±4" },
        { value: "5", label: "±5" },
    ];
    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <th colSpan={5}>
                            <div style={{ display: "grid", gridTemplateColumns: "90% 10%", alignItems: "center" }}>
                                <div style={{ textAlign: "center" }}>
                                    <Lable value={"Ejection Settings"} type={"Head"} style={{ padding: "1px" }} />
                                </div>
                                <div>
                                    <button className="nextButtonLabel" onClick={(e) => { setActiveKey("twele") }}>Next</button>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <Lable value={Records[0].ejectionsettings_function[0].Ejection_settings_stages} style={{ textAlign: 'center', padding: "0px" }} />
                        </td>
                        <td>
                            <Lable value={"PRESSURE (BAR)⟡"} style={{ textAlign: 'center', padding: "0px" }} />
                            <Field
                                label={""}
                                name={"Ejection_settings_pressure"}
                                className={"fieldSingleGrid"}
                                options={{
                                    type: "dropdown",
                                    disabled: option == "view",
                                    values: DropdownValues
                                }}
                                value={Records[0].ejectionsettings_function[0]}
                                OnChange={(e) => {
                                    HandleEjectionSet(e, 0);
                                }}
                            />
                        </td>
                        <td>
                            <Lable value={"SPEED% ⟡"} style={{ textAlign: 'center', padding: "0px" }} />
                            <Field
                                label={""}
                                name={"Ejection_settings_speed"}
                                className={"fieldSingleGrid"}
                                options={{
                                    type: "dropdown",
                                    disabled: option == "view",
                                    values: DropdownValues
                                }}
                                value={Records[0].ejectionsettings_function[0]}
                                OnChange={(e) => {
                                    HandleEjectionSet(e, 0);
                                }}
                            />
                        </td>
                        <td>
                            <Lable value={"TIME (SEC)"} style={{ textAlign: 'center', padding: "0px" }} />
                            <Field
                                label={""}
                                name={"Ejection_settings_time"}
                                className={"fieldSingleGrid"}
                                options={{
                                    type: "dropdown",
                                    disabled: option == "view",
                                    values: DropdownValuesTime
                                }}
                                value={Records[0].ejectionsettings_function[0]}
                                OnChange={(e) => {
                                    HandleEjectionSet(e, 0);
                                }}
                            />
                        </td>
                        <td>
                            <Lable value={"POSITION (MM)⟡"} style={{ textAlign: 'center', padding: "0px" }} />
                            <Field
                                label={""}
                                name={"Ejection_settings_position"}
                                className={"fieldSingleGrid"}
                                options={{
                                    type: "dropdown",
                                    disabled: option == "view",
                                    values: DropdownValues
                                }}
                                value={Records[0].ejectionsettings_function[0]}
                                OnChange={(e) => {
                                    HandleEjectionSet(e, 0);
                                }}
                            />
                        </td>
                    </tr>
                    {Records[0].ejectionsettings_function.map((item, index) => {
                        if (index != 0) {
                            return (
                                <tr key={index}>
                                    <td>
                                        <Lable value={item.Ejection_settings_stages} style={{ textAlign: 'center', padding: "0px" }} />
                                    </td>
                                    <td>
                                        <Field
                                            id={index}
                                            label={""}
                                            name={"Ejection_settings_pressure"}
                                            className={"fieldSingleGrid"}
                                            options={{
                                                type: "text",
                                                disabled: option == "view",
                                            }}
                                            value={item}
                                            OnChange={(e) => {
                                                let value = e.target.value;
                                                if (InvalidCharacters(value)) {
                                                  value = value.replace(/[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g, '');
                                                  e.target.value = value;
                                                }
                                                HandleEjectionSet(e, index);
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <Field
                                            id={index}
                                            label={""}
                                            name={"Ejection_settings_speed"}
                                            className={"fieldSingleGrid"}
                                            options={{
                                                type: "text",
                                                disabled: option == "view",
                                            }}
                                            value={item}
                                            OnChange={(e) => {
                                                let value = e.target.value;
                                                if (InvalidCharacters(value)) {
                                                  value = value.replace(/[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g, '');
                                                  e.target.value = value;
                                                }
                                                HandleEjectionSet(e, index);
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <Field
                                            id={index}
                                            label={""}
                                            name={"Ejection_settings_time"}
                                            className={"fieldSingleGrid"}
                                            options={{
                                                type: "text",
                                                disabled: option == "view",
                                            }}
                                            value={item}
                                            OnChange={(e) => {
                                                let value = e.target.value;
                                                if (InvalidCharacters(value)) {
                                                  value = value.replace(/[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g, '');
                                                  e.target.value = value;
                                                }
                                                HandleEjectionSet(e, index);
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <Field
                                            label={""}
                                            id={index}
                                            name={"Ejection_settings_position"}
                                            className={"fieldSingleGrid"}
                                            options={{
                                                type: "text",
                                                disabled: option == "view",
                                            }}
                                            value={item}
                                            OnChange={(e) => {
                                                let value = e.target.value;
                                                if (InvalidCharacters(value)) {
                                                  value = value.replace(/[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g, '');
                                                  e.target.value = value;
                                                }
                                                HandleEjectionSet(e, index);
                                            }}
                                        />
                                    </td>
                                </tr>
                            )
                        }
                    })}
                </tbody>
            </table>
        </div>
    );
};
export default EjectionSettings;