import React, { useState, useEffect, useRef } from "react";
import Field from "../../../../Components/Fields/FieldPaint.js";
import Lable from "../../../../Components/Fields/Lable";
import { useNavigate, useParams } from "react-router-dom";
import * as GeneralConstants from "../../../../Utils/GeneralConstants.js";
import {
  CURRENT_Date,
  SHIFTMOLDINGQUALITY,
} from "../../../../Utils/GeneralConstants";
import PreViewPage from "./PreViewPage";
import LoadingPage from "../../../../Components/Handler/LoadingPage";
import QaLayoutPaint from "../../QaLayoutPaint.js";
import ReadRecordPaint from "../../../../Lib/API/ReadRecordPaint.js";
import CreateRecordPaint from "../../../../Lib/API/CreateRecordPaint.js";
import UpdateRecordPaint from "../../../../Lib/API/UpdateRecordPaint.js";
import DeleteOriginalRecordPaint from "../../../../Lib/API/DeleteOriginalRecordPaint.js";
import CreateApprovalPaint from "../../../../Lib/CreateApprovalPaint.js";
import { setMessagePaint } from "../../../../Lib/SetMessagePaint.js";
const FinalIns = () => {
  const [DownloadDiv, setDownloadDiv] = useState(false);
  const CURRENT_TIME = new Date(
    new Date().getTime() - new Date().getTimezoneOffset() * 60000
  )
    .toISOString()
    .split("T")[1]
    .substring(0, 5);

  const InitRoot = [
    { 
      option:"Preview",
      download: false,
      S_time: CURRENT_TIME,
      C_time: "",
      Shift: SHIFTMOLDINGQUALITY,
      Format_no: "UPCL/PRO/R/35",
      Rev_no: "01",
      Rev_ate: "18-05-2017",
      Entry_date: CURRENT_Date,
      Ins_name: "",
      Inspector_sign: "",
      Shift_incharge: "",
      Approved_sign: "",
      InsName: [{}],
      InspectorS: [{}],
      Shiftincharge: [{}],
      ApprovedS: [{}],
      paint_final_inspection_bulk_report: [
        {
          PartName: [{}],
          Part_name: "",
          Part_no: "",
          Qty_chk: "",
          Qty_ok: "",
          Dust: "",
          Lint: "",
          Col_mismatch: "",
          Dry_spray: "",
          Sag: "",
          Less_cover: "",
          Touch_mark: "",
          Stress_mark: "",
          Pin_hole: "",
          Bubbles: "",
          Blister: "",
          Oil_mark: "",
          Damage: "",
          Dent: "",
          Flash: "",
          Others: "",
          Total_qty_rej: "",
          Reason_for_rej: "",
          Remarks: "",
        },
      ],
      paint_names: [
        { key: "Inspector Sign", value: "" },
        { key: "Shift Incharge Sign", value: "" },
        { key: "Approved Sign", value: "" },
      ],
      Id: "",
      
    },
  ];
  const [Records, setRecords] = useState(InitRoot);
  const [Preview, setPreView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [warning, setwarning] = useState(false);
  const { option, id } = useParams();
  const navigate = useNavigate();
  
  function HandleRecords(Event) {
    setRecords([{ ...Records[0], [Event.target.name]: Event.target.value }]);
  }
  function HandleRecordsFinal_ins(index, event) {
    console.log("Final_ins(", index, event);
    if (!Records[0]?.paint_final_inspection_bulk_report[index]?.Part_name) {
      alert("Please select a PartName");
      return; 
    }
    setRecords((prevRecords) => {
      prevRecords[0].paint_final_inspection_bulk_report[index][
        event.target.name
      ] = event.target.value;
      return [...prevRecords];
    });
  }
  function HandleRecordsBoolean(index, event) {
    console.log(index, event, event.target.checked + "");
    console.log(
      [event.target.name],
      Records[0].paint_final_inspection_bulk_report
    );
    if (!Records[0]?.paint_final_inspection_bulk_report[index]?.Part_name) {
      alert("Please select a PartName.");
      return; 
    }
    setRecords((prevRecords) => {
      const updatedRecords = [...prevRecords];
      updatedRecords[0].paint_final_inspection_bulk_report[index][
        event.target.name
      ] = event.target.checked + "";
      return updatedRecords;
    });
  }
  function HandleRecordSelect(Event, column, value, label) {
    console.log("222", Event.name, column, value, label);
    setRecords(() => {
      let record = [...Records];
      record[0][Event.name] = Event.values.value;
      record[0][column][0][value] = Event.values.value;
      record[0][column][0][label] = Event.values.label;
      return record;
    });
  }

  const addRow = () => {
    setRecords((prevRecords) => {
      const newRow = {
        PartName: [{}],
        Part_name: "",
        Part_no: "",
        Qty_chk: "",
        Qty_ok: "",
        Dust: "",
        Lint: "",
        Col_mismatch: "",
        Dry_spray: "",
        Sag: "",
        Less_cover: "",
        Touch_mark: "",
        Stress_mark: "",
        Pin_hole: "",
        Bubbles: "",
        Blister: "",
        Oil_mark: "",
        Damage: "",
        Dent: "",
        Flash: "",
        Others: "",
        Total_qty_rej: "",
        Reason_for_rej: "",
        Remarks: "",
      };
      const updatedRecords = [...prevRecords];
      updatedRecords[0].paint_final_inspection_bulk_report.push(newRow);
      return updatedRecords;
    });
  };

  const removeRow = () => {
    setRecords((prevRecords) => {
      if (prevRecords[0].paint_final_inspection_bulk_report.length > 1) {
        const updatedRecords = [...prevRecords];
        updatedRecords[0].paint_final_inspection_bulk_report.pop();
        return updatedRecords;
      } else {
        return prevRecords;
      }
    });
  };
  function handlepaint_final_inspection_bulk_report(index, event) {
    console.log("handlepaint_final_inspection_bulk_report", index, event);
    setRecords((prevRecords) => {
      prevRecords[0].paint_final_inspection_bulk_report[index][
        event.target.name
      ] = event.target.value;
      return [...prevRecords];
    });
  }

  function handlepaint_final_inspection_bulk_report_part_name(
    index,
    event,
    columnName,
    label,
    value
  ) {
    //  console.log("Update_paint_loading_bulk_report_part_name", Event);
    // console.log("target",Event.name);
    // console.log("columnname", columnName);
    // console.log("value", value);
    // console.log("label", label);
    setRecords((prevRecords) => {
      const updatedRecords = [...prevRecords];

      updatedRecords[0].paint_final_inspection_bulk_report[index].Part_no =
        event.values.value;

      updatedRecords[0].paint_final_inspection_bulk_report[index][event.name] =
        event.values.label;

      updatedRecords[0].paint_final_inspection_bulk_report[index][columnName][0][value] = event.values.value;
      updatedRecords[0].paint_final_inspection_bulk_report[index][columnName][0][label] = event.values.label;

      return updatedRecords;
    });
  }
  function HandleCreate(event) {
    try {
      if (!Records[0].paint_final_inspection_bulk_report[0].Part_name) {
        alert("Please fill at least one part");
        setwarning(true);
        setPreView(false);
        return;
        
      }
      if (!Records[0].Ins_name || !Records[0].Inspector_sign || !Records[0].Shift_incharge || !Records[0].Approved_sign) {
        alert("Please fill all mandatory fields");
        setwarning(true);
        setPreView(false);
        return;
      } else {
        let record = [...Records];

        record && delete record[0].InsName;
        record && delete record[0].InspectorS;
        record && delete record[0].Shiftincharge;
        record && delete record[0].ApprovedS;
        record && delete record[0].paint_names;
        record && delete record[0].download;
        record && delete record[0].Id;
        if (record && record[0] && record[0].paint_final_inspection_bulk_report) {
          record[0].paint_final_inspection_bulk_report.forEach((report) => {
            delete report.PartName;
          });
        }
        console.log("createFter Delete", record);
        CreateRecordPaint("paint_final_inspection", record).then((res) => {
          console.log("HandleCreate", res);
          setMessagePaint({
            NoteFrom: localStorage.getItem("empId"),
            NoteTo: Records[0].Approved_sign,
            NoteMessage: `${localStorage.getItem("empId")}-${localStorage.getItem(
              "name"
            )} has submitted Paint Final Inspection Form, Please do verify`,
            NoteRecordId: res.ROWID,
            TableName: "FinalInspection",
            NoteCreatedBy: localStorage.getItem("empId"),
          });
          CreateApprovalPaint({
            RequestedBy: localStorage.getItem("empId"),
            Approver: Records[0].Approved_sign,
            Level: 1,
            Status: "Waiting for Approval",
            TableName: "FinalInspection",
            TableID: res.ROWID,
            Description:
              "The Paint Final Inspection Form Has been Submitted By " +
              localStorage.getItem("empId") +
              "-" +
              localStorage.getItem("name"),
            CreatedBy: localStorage.getItem("empId"),
          });
        });
     
        alert("Submited Successfully");
        setPreView(false);
        setwarning(false);
        setRecords(InitRoot);
      }
    } catch (error) {
      console.error(error);
      alert("Submission failed");
    }
  }
  function HandleUpdate(event) {
    try {
      let record = [...Records];
      record && delete record[0].InsName;
        record && delete record[0].InspectorS;
        record && delete record[0].Shiftincharge;
        record && delete record[0].ApprovedS;
        record && delete record[0].paint_names;
        record && delete record[0].download;
        if (record && record[0] && record[0].paint_final_inspection_bulk_report) {
          record[0].paint_final_inspection_bulk_report.forEach((report) => {
            delete report.PartName;
          });
        }
      console.log("updaFter Delete", record);
      console.log(record[0].Id);
      let noId = {};
      let fields = ["paint_final_inspection_bulk_report"];
      fields.map((field) => {
        record[0][field].map((data) => {
          if (!noId[field]) {
            noId[field] = data.Id === undefined ? true : false;
          }
        });
      });
      console.log("noId", noId);
      Object.keys(noId).map((item) => {
        if (noId[item]) {
          DeleteOriginalRecordPaint(item, [
            { paint_final_inspection_id: record[0].Id },
          ]).then((res) => {
            console.log("DeleteOriginalRecord", res);
            const newData = record[0][item].map((obj) => {
              const newObj = { ...obj };
              delete newObj.Id;
              newObj.paint_final_inspection_id = record[0].Id;
              return newObj;
            });
            console.log("newData", newData);
            CreateRecordPaint(item, newData);
            delete record[0][item];
          });
          console.log("noId", record[0][item]);
        }
      });

      UpdateRecordPaint("paint_final_inspection", record).then((res) => {
        console.log("HandleUpdate", res);
      });
      alert(GeneralConstants.UPDATE_SUCCESSFUL);
      setRecords(InitRoot);
      // setPreView(false)
      navigate(-1);
    } catch (error) {
      console.error(error);
      alert(GeneralConstants.UPDATE_FAILURE);
    }
  }
  useEffect(() => {
    console.log("Records", Records);
    console.log("Records", Records[0].paint_final_inspection_bulk_report);
  }, [Records]);
  useEffect(() => {
    setwarning(false);
    document.title = "FinalInspection";
    const fetchData = async () => {
      if (id === undefined) {
        setRecords([
          {
            ...Records[0],
            // Entry_date: CURRENT_Date,
            // Time: CURRENT_TIME,
            Shift: SHIFTMOLDINGQUALITY,
          },
        ]);
      } else {
        setLoading(true);
        try {
          const res = await ReadRecordPaint({
            _keyword_: "paint_final_inspection",
            Id: id,
          });
          console.log("234", res);
          if (res.data[0] === undefined) {
            setRecords(InitRoot);
          } else {
          

            const mergedData = {
              ...res.data[0],
              paint_names: InitRoot[0].paint_names,
          
            };
            setRecords([mergedData]);
          }
        
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <QaLayoutPaint record={Records}quary={""}>
      <div className="Form">
        <div>
          <table>
            <tbody>
              <tr>
                <th colSpan={18}>
                  <Lable value={"FINAL INSPECTION REPORT"} type={"Head"} />
                </th>

                <td colSpan={5}>
                  <tr>
                    <td style={{ width: "25%" }}>
                      <Field
                        label={"DOC NO"}
                        name={"Format_no"}
                        style={{ padding: "0px" }}
                        options={{
                          type: "text",
                          disabled: true,
                        }}
                        value={Records[0]}
                        OnChange={(e) => {
                          HandleRecords(e);
                        }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td style={{ width: "25%" }}>
                      <Field
                        label={"REV NO"}
                        name={"Rev_no"}
                        style={{ padding: "0px" }}
                        options={{
                          type: "text",
                          // defaultValue: "00",
                          disabled: true,
                        }}
                        value={Records[0]}
                        OnChange={(e) => {
                          HandleRecords(e);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "25%" }}>
                      <Field
                        label={"REV DATE"}
                        name={"Rev_ate"}
                        style={{ padding: "0px" }}
                        options={{
                          type: "text",
                          //  defaultValue: "18-05-2017",
                          disabled: true,
                        }}
                        value={Records[0]}
                        OnChange={(e) => {
                          HandleRecords(e);
                        }}
                      />
                    </td>
                  </tr>
                </td>
                {/* )} */}
              </tr>
              <tr>
                <td colSpan={6}>
                  <Field
                    label={"DATE"}
                    name={"Entry_date"}
                    style={{ padding: "0px" }}
                    options={{
                      type: "text",
                      disabled: true,
                    }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                <td colSpan={6}>
                  <Field
                    label={"Shift"}
                    name={"Shift"}
                    style={{ padding: "0px" }}
                    options={{
                      type: "text",
                      disabled: true,
                      //  defaultValue: "18-05-2017",
                    }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                <td colSpan={6}>
                  <Field
                    label={"StartTime"}
                    name={"S_time"}
                    style={{ padding: "0px" }}
                    options={{
                      type: "text",
                      disabled: true,
                      //  defaultValue: "18-05-2017",
                    }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                <td colSpan={5}>
                  <Field
                    label={"CloseTime"}
                    name={"C_time"}
                    style={{ padding: "0px" }}
                    options={{
                      type: "time",
                    }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
              </tr>

              <tr>
             
                <td colSpan={12}>
                  <Field
                    label={"Insp Name"}
                    name={"Ins_name"}
                    style={{ padding: "0px" }}
                    options={{
                      type: "reference",
                      reference: "emp_details_dropdown",
                      required: true,
                      padding: "0px",
                      //disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records[0].InsName[0]
                        ? Records[0].InsName[0].Emp_code
                        : "",
                      label: Records[0].InsName[0]
                        ? Records[0].InsName[0].Emp_name
                        : "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(e, "InsName", "Emp_code", "Emp_name");
                    }}
                  />
                </td>
                <td colSpan={15}></td>
              </tr>
              <tr>
                <td
                  colSpan={23}
                  // style={{ width: "21%" }}
                >
                  <div
                    style={{
                      width: "100%",
                      // overflow: "hidden",
                      // overflowX: "scroll",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <table>
                        <thead>
                          <tr>
                            <td colSpan={6} style={{ width: "35%" }}>
                              <label style={{ width: "100%", padding: "25px" }}>
                                Part Name
                              </label>
                            </td>
                            <td
                              colSpan={4}
                              style={{ width: "35%", padding: "15px" }}
                            >
                              <label>Part No</label>
                            </td>
                            <td colSpan={4} style={{ width: "8%" }}>
                              <label>Qty. Checked</label>
                            </td>
                            <td colSpan={1} style={{ width: "15%" }}>
                              <label>Qty. OK</label>
                            </td>
                            <td
                              colSpan={1}
                              style={{ transform: "rotate(-90deg)" }}
                            >
                              <label>DUST</label>
                            </td>
                            <td
                              colSpan={1}
                              style={{ transform: "rotate(-90deg)" }}
                            >
                              <label>LINT</label>
                            </td>
                            <td
                              colSpan={1}
                              style={{ transform: "rotate(-90deg)" }}
                            >
                              <label>COLOUR MISMATCH</label>
                            </td>
                            <td
                              colSpan={1}
                              style={{ transform: "rotate(-90deg)" }}
                            >
                              <label>DRYSPRAY</label>
                            </td>
                            <td
                              colSpan={1}
                              style={{ transform: "rotate(-90deg)" }}
                            >
                              <label>SAG</label>
                            </td>
                            <td
                              colSpan={1}
                              style={{ transform: "rotate(-90deg)" }}
                            >
                              <label>LESS COVER</label>
                            </td>
                            <td
                              colSpan={1}
                              style={{ transform: "rotate(-90deg)" }}
                            >
                              <label>TOUCH MARK</label>
                            </td>
                            <td
                              colSpan={1}
                              style={{ transform: "rotate(-90deg)" }}
                            >
                              <label>STRESS MARK</label>
                            </td>
                            <td
                              colSpan={1}
                              style={{ transform: "rotate(-90deg)" }}
                            >
                              <label> PIN HOLE</label>
                            </td>
                            <td
                              colSpan={1}
                              style={{ transform: "rotate(-90deg)" }}
                            >
                              <label>BUBBLES</label>
                            </td>
                            <td
                              colSpan={1}
                              style={{ transform: "rotate(-90deg)" }}
                            >
                              <label>BLISTER</label>
                            </td>
                            <td
                              colSpan={1}
                              style={{ transform: "rotate(-90deg)" }}
                            >
                              <label>OIL MARK</label>
                            </td>
                            <td
                              colSpan={1}
                              style={{ transform: "rotate(-90deg)" }}
                            >
                              <label>DAMAGE</label>
                            </td>
                            <td
                              colSpan={1}
                              style={{ transform: "rotate(-90deg)" }}
                            >
                              <label>DENT</label>
                            </td>
                            <td
                              colSpan={1}
                              style={{ transform: "rotate(-90deg)" }}
                            >
                              <label>FLASH</label>
                            </td>
                            <td
                              colSpan={1}
                              style={{ transform: "rotate(-90deg)" }}
                            >
                              <label>OTHERS</label>
                            </td>
                            <td colSpan={1}>
                              <label>Total Qty. Rejected</label>
                            </td>
                            <td colSpan={1}>
                              <label>Reason for </label>
                              <label>Other Rejections</label>
                            </td>
                            <td colSpan={1}>
                              <label>Remarks</label>
                              {/* <Lable value={"Remarks"}></Lable> */}
                            </td>
                          </tr>
                          {Array.from(
                            {
                              length:
                                Records[0].paint_final_inspection_bulk_report
                                  .length,
                            },
                            (_, i) => i + 1
                          ).map((item) => (
                            <tr>
                              <td colSpan={6} style={{ padding: "0px" }}>
                                <Field
                                  name={"Part_name"}
                                  options={{
                                    type: "reference",
                                    reference: "Get_paint_part_name",
                                    required: true,

                                    disabled: option === "view" ||  (option === "edit" && Records?.[0]?.paint_final_inspection_bulk_report[item - 1]?.PartName[0]?.Part_code) 
                                  }}
                                
                                  style={{ padding: "0px" }}
                                  // warning={warning}
                                
                                  value={{
                                    value:
                                     
                                      Records?.[0]?.paint_final_inspection_bulk_report[Number(item) - 1].PartName[0].Part_code ||
                                      Records?.[0]?.paint_final_inspection_bulk_report[Number(item) - 1].Part_no ||
                                      "",
                                    label:
                                     
                                      Records?.[0]?.paint_final_inspection_bulk_report[Number(item) - 1].PartName[0].Part_name ||
                                      Records?.[0]?.paint_final_inspection_bulk_report[Number(item) - 1].Part_name ||
                                      "",
                                  }}
                                  
                                  OnChange={(e) =>
                                    handlepaint_final_inspection_bulk_report_part_name(
                                      [Number(item) - 1],
                                      e,
                                      "PartName",
                                      "Part_name",
                          "Part_code"
                                    )
                                  }
                                />
                              </td>
                              <td style={{ padding: "0px" }} colSpan={4}>
                                <Field
                                  name={"Part_no"}
                                  style={{ padding: "0px" }}
                                  options={{
                                    type: "text",
                                    disabled: true,
                                  }}
                                  value={
                                    Records?.[0]
                                      ?.paint_final_inspection_bulk_report[
                                      Number(item) - 1
                                    ]
                                  }
                                  OnChange={(e) => {
                                    HandleRecordsFinal_ins(
                                      [Number(item) - 1],
                                      e
                                    );
                                  }}
                                />
                              </td>
                              <td style={{ padding: "0px" }} colSpan={4}>
                                <Field
                                  style={{ padding: "0px" }}
                                  name={"Qty_chk"}
                                  options={{
                                    type: "text",
                                  }}
                                  value={
                                    Records?.[0]
                                      ?.paint_final_inspection_bulk_report[
                                      Number(item) - 1
                                    ]
                                  }
                                  OnChange={(e) => {
                                    HandleRecordsFinal_ins(
                                      [Number(item) - 1],
                                      e
                                    );
                                  }}
                                />
                              </td>

                              <td style={{ padding: "0px" }}>
                                <Field
                                  name={"Qty_ok"}
                                  style={{ padding: "0px" }}
                                  options={{
                                    type: "text",
                                  }}
                                  value={
                                    Records?.[0]
                                      ?.paint_final_inspection_bulk_report[
                                      Number(item) - 1
                                    ]
                                  }
                                  OnChange={(e) => {
                                    HandleRecordsFinal_ins(
                                      [Number(item) - 1],
                                      e
                                    );
                                  }}
                                />
                              </td>

                              <td style={{ padding: "0px" }}>
                                <Field
                                  name={"Dust"}
                                  label={""}
                                  options={{
                                    type: "smallboolean",
                                    // disabled: option === "view" || firstoff === true,
                                  }}
                                  style={{ padding: "0px" }}
                                  value={
                                    Records[0]
                                      .paint_final_inspection_bulk_report[
                                      Number(item) - 1
                                    ]
                                  }
                                  OnChange={(e) =>
                                    HandleRecordsBoolean(Number(item) - 1, e)
                                  }
                                />
                              </td>
                              <td style={{ padding: "0px" }}>
                                <Field
                                  name={"Lint"}
                                  label={""}
                                  options={{
                                    type: "smallboolean",
                                    padding: "0px",
                                    // disabled: option === "view" || firstoff === true,
                                  }}
                                  style={{ padding: "0px" }}
                                  value={
                                    Records[0]
                                      .paint_final_inspection_bulk_report[
                                      Number(item) - 1
                                    ]
                                  }
                                  OnChange={(e) =>
                                    HandleRecordsBoolean(Number(item) - 1, e)
                                  }
                                />
                              </td>
                              <td
                                style={{ padding: "0px", whiteSpace: "nowrap" }}
                              >
                                <Field
                                  name={"Col_mismatch"}
                                  label={""}
                                  options={{
                                    type: "smallboolean",

                                    // disabled: option === "view" || firstoff === true,
                                  }}
                                  style={{ padding: "0px" }}
                                  value={
                                    Records[0]
                                      .paint_final_inspection_bulk_report[
                                      Number(item) - 1
                                    ]
                                  }
                                  OnChange={(e) =>
                                    HandleRecordsBoolean(Number(item) - 1, e)
                                  }
                                />
                              </td>
                              <td style={{ padding: "0px" }}>
                                <Field
                                  name={"Dry_spray"}
                                  label={""}
                                  options={{
                                    type: "smallboolean",

                                    // disabled: option === "view" || firstoff === true,
                                  }}
                                  style={{ padding: "0px" }}
                                  value={
                                    Records[0]
                                      .paint_final_inspection_bulk_report[
                                      Number(item) - 1
                                    ]
                                  }
                                  OnChange={(e) =>
                                    HandleRecordsBoolean(Number(item) - 1, e)
                                  }
                                />
                              </td>

                              <td style={{ padding: "0px" }}>
                                <Field
                                  name={"Sag"}
                                  label={""}
                                  options={{
                                    type: "smallboolean",

                                    // disabled: option === "view" || firstoff === true,
                                  }}
                                  style={{ padding: "0px" }}
                                  value={
                                    Records[0]
                                      .paint_final_inspection_bulk_report[
                                      Number(item) - 1
                                    ]
                                  }
                                  OnChange={(e) =>
                                    HandleRecordsBoolean(Number(item) - 1, e)
                                  }
                                />
                              </td>
                              <td style={{ padding: "0px" }}>
                                <Field
                                  name={"Less_cover"}
                                  label={""}
                                  options={{
                                    type: "smallboolean",

                                    // disabled: option === "view" || firstoff === true,
                                  }}
                                  style={{ padding: "0px" }}
                                  value={
                                    Records[0]
                                      .paint_final_inspection_bulk_report[
                                      Number(item) - 1
                                    ]
                                  }
                                  OnChange={(e) =>
                                    HandleRecordsBoolean(Number(item) - 1, e)
                                  }
                                />
                              </td>
                              <td style={{ padding: "0px" }}>
                                <Field
                                  name={"Touch_mark"}
                                  label={""}
                                  options={{
                                    type: "smallboolean",

                                    // disabled: option === "view" || firstoff === true,
                                  }}
                                  style={{ padding: "0px" }}
                                  value={
                                    Records[0]
                                      .paint_final_inspection_bulk_report[
                                      Number(item) - 1
                                    ]
                                  }
                                  OnChange={(e) =>
                                    HandleRecordsBoolean(Number(item) - 1, e)
                                  }
                                />
                              </td>
                              <td style={{ padding: "0px" }}>
                                <Field
                                  name={"Stress_mark"}
                                  label={""}
                                  options={{
                                    type: "smallboolean",

                                    // disabled: option === "view" || firstoff === true,
                                  }}
                                  value={
                                    Records[0]
                                      .paint_final_inspection_bulk_report[
                                      Number(item) - 1
                                    ]
                                  }
                                  OnChange={(e) =>
                                    HandleRecordsBoolean(Number(item) - 1, e)
                                  }
                                />
                              </td>
                              <td style={{ padding: "0px" }}>
                                <Field
                                  name={"Pin_hole"}
                                  label={""}
                                  options={{
                                    type: "smallboolean",

                                    // disabled: option === "view" || firstoff === true,
                                  }}
                                  style={{ padding: "0px" }}
                                  value={
                                    Records[0]
                                      .paint_final_inspection_bulk_report[
                                      Number(item) - 1
                                    ]
                                  }
                                  OnChange={(e) =>
                                    HandleRecordsBoolean(Number(item) - 1, e)
                                  }
                                />
                              </td>
                              <td style={{ padding: "0px" }}>
                                <Field
                                  name={"Bubbles"}
                                  label={""}
                                  options={{
                                    type: "smallboolean",

                                    // disabled: option === "view" || firstoff === true,
                                  }}
                                  style={{ padding: "0px" }}
                                  value={
                                    Records[0]
                                      .paint_final_inspection_bulk_report[
                                      Number(item) - 1
                                    ]
                                  }
                                  OnChange={(e) =>
                                    HandleRecordsBoolean(Number(item) - 1, e)
                                  }
                                />
                              </td>
                              <td style={{ padding: "0px" }}>
                                <Field
                                  name={"Blister"}
                                  label={""}
                                  options={{
                                    type: "smallboolean",

                                    // disabled: option === "view" || firstoff === true,
                                  }}
                                  style={{ padding: "0px" }}
                                  value={
                                    Records[0]
                                      .paint_final_inspection_bulk_report[
                                      Number(item) - 1
                                    ]
                                  }
                                  OnChange={(e) =>
                                    HandleRecordsBoolean(Number(item) - 1, e)
                                  }
                                />
                              </td>
                              <td style={{ padding: "0px" }}>
                                <Field
                                  name={"Oil_mark"}
                                  label={""}
                                  options={{
                                    type: "smallboolean",

                                    // disabled: option === "view" || firstoff === true,
                                  }}
                                  style={{ padding: "0px" }}
                                  value={
                                    Records[0]
                                      .paint_final_inspection_bulk_report[
                                      Number(item) - 1
                                    ]
                                  }
                                  OnChange={(e) =>
                                    HandleRecordsBoolean(Number(item) - 1, e)
                                  }
                                />
                              </td>
                              <td style={{ padding: "0px" }}>
                                <Field
                                  name={"Damage"}
                                  label={""}
                                  options={{
                                    type: "smallboolean",

                                    // disabled: option === "view" || firstoff === true,
                                  }}
                                  style={{ padding: "0px" }}
                                  value={
                                    Records[0]
                                      .paint_final_inspection_bulk_report[
                                      Number(item) - 1
                                    ]
                                  }
                                  OnChange={(e) =>
                                    HandleRecordsBoolean(Number(item) - 1, e)
                                  }
                                />
                              </td>
                              <td style={{ padding: "0px" }}>
                                <Field
                                  name={"Dent"}
                                  label={""}
                                  options={{
                                    type: "smallboolean",

                                    // disabled: option === "view" || firstoff === true,
                                  }}
                                  // style={{ padding: "0px" }}
                                  value={
                                    Records[0]
                                      .paint_final_inspection_bulk_report[
                                      Number(item) - 1
                                    ]
                                  }
                                  OnChange={(e) =>
                                    HandleRecordsBoolean(Number(item) - 1, e)
                                  }
                                />
                              </td>
                              <td style={{ padding: "0px" }}>
                                <Field
                                  name={"Flash"}
                                  label={""}
                                  options={{
                                    type: "smallboolean",

                                    // disabled: option === "view" || firstoff === true,
                                  }}
                                  // style={{ padding: "0px" }}
                                  value={
                                    Records[0]
                                      .paint_final_inspection_bulk_report[
                                      Number(item) - 1
                                    ]
                                  }
                                  OnChange={(e) =>
                                    HandleRecordsBoolean(Number(item) - 1, e)
                                  }
                                />
                              </td>
                              <td style={{ padding: "0px" }}>
                                <Field
                                  name={"Others"}
                                  label={""}
                                  options={{
                                    type: "smallboolean",

                                    // disabled: option === "view" || firstoff === true,
                                  }}
                                  style={{ padding: "0px" }}
                                  value={
                                    Records[0]
                                      .paint_final_inspection_bulk_report[
                                      Number(item) - 1
                                    ]
                                  }
                                  OnChange={(e) =>
                                    HandleRecordsBoolean(Number(item) - 1, e)
                                  }
                                />
                              </td>
                              <td>
                                <Field
                                  name={"Total_qty_rej"}
                                  style={{ padding: "0px" }}
                                  options={{
                                    type: "text",
                                  }}
                                  value={
                                    Records[0]
                                      .paint_final_inspection_bulk_report[
                                      Number(item) - 1
                                    ]
                                  }
                                  OnChange={(e) => {
                                    HandleRecordsFinal_ins(Number(item) - 1, e);
                                  }}
                                />
                              </td>
                              <td>
                                <Field
                                  name={"Reason_for_rej"}
                                  style={{ padding: "0px" }}
                                  options={{
                                    type: "textarea",
                                  }}
                                  value={
                                    Records[0]
                                      .paint_final_inspection_bulk_report[
                                      Number(item) - 1
                                    ]
                                  }
                                  OnChange={(e) => {
                                    HandleRecordsFinal_ins(Number(item) - 1, e);
                                  }}
                                />
                              </td>

                              <td style={{ width: "2%" }}>
                                <Field
                                  name={"Remarks"}
                                  style={{ padding: "0px" }}
                                  options={{
                                    type: "textarea",
                                  }}
                                  value={
                                    Records[0]
                                      .paint_final_inspection_bulk_report[
                                      Number(item) - 1
                                    ]
                                  }
                                  OnChange={(e) => {
                                    HandleRecordsFinal_ins(Number(item) - 1, e);
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </thead>
                      </table>
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td colSpan={2}>
                  <button
                    onClick={addRow}
                    // disabled={option === "view"}
                  >
                    Add Row
                  </button>
                </td>
                <td>
                  <button
                    onClick={removeRow}
                    // disabled={option === "view"}
                  >
                    -
                  </button>
                </td>
                <td colSpan={20}></td>
              </tr>
              <tr>
                <td colSpan={7}>
                  {" "}
                  <Field
                    label={"Inspector Sign"}
                    name={"Inspector_sign"}
                    doubleLine={true}
                    options={{
                      type: "reference",
                      reference: "emp_details_dropdown",
                      required: true,
                      //disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records[0].InspectorS[0]
                        ? Records[0].InspectorS[0].Emp_code
                        : "",
                      label: Records[0].InspectorS[0]
                        ? Records[0].InspectorS[0].Emp_name
                        : "",
                    }}
                    style={{ padding: "0px" }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "InspectorS",
                        "Emp_code",
                        "Emp_name"
                      );
                    }}
                  />
                </td>
                <td colSpan={8}>
                  {" "}
                  <Field
                    label={"Shift Incharge Sign"}
                    name={"Shift_incharge"}
                    doubleLine={true}
                    options={{
                      type: "reference",
                      reference: "emp_details_dropdown",
                      required: true,
                      //disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records[0].Shiftincharge[0]
                        ? Records[0].Shiftincharge[0].Emp_code
                        : "",
                      label: Records[0].Shiftincharge[0]
                        ? Records[0].Shiftincharge[0].Emp_name
                        : "",
                    }}
                    style={{ padding: "0px" }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "Shiftincharge",
                        "Emp_code",
                        "Emp_name"
                      );
                    }}
                  />
                </td>
                <td colSpan={7}>
                  {" "}
                  <Field
                    label={"Approved Sign"}
                    name={"Approved_sign"}
                    doubleLine={true}
                    options={{
                      type: "reference",
                      reference: "emp_details_dropdown",
                      required: true,
                      // //disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records[0].ApprovedS[0]
                        ? Records[0].ApprovedS[0].Emp_code
                        : "",
                      label: Records[0].ApprovedS[0]
                        ? Records[0].ApprovedS[0].Emp_name
                        : "",
                    }}
                    style={{ padding: "0px" }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "ApprovedS",
                        "Emp_code",
                        "Emp_name"
                      );
                    }}
                  />
                </td>
              </tr>
              <tr>
                {Preview && (
                  <PreViewPage
                    Records={Records}
                    setState={setPreView}
                    HandleCreate={HandleCreate}
                    option={option}
                    HandleUpdate={HandleUpdate}
                    id={id}
                  />
                )}
                <td colSpan={23}>
                  {" "}
                  <Field
                    className={"submit"}
                    label={"Preview"}
                    options={{ type: "Button" }}
                    onClick={() => {
                      setPreView(true);
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </QaLayoutPaint>
  );
};

export default FinalIns;
