//external import
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

//internal import
import Field from "../../../Components/Fields/Field";
import Lable from "../../../Components/Fields/Lable";
import QaLayout from "../../Quality/QaLayout";

//css import
import "./NewSmpMain.css";
const TemperatureSmp = ({ Records, setRecords, setActiveKey, activeKey }) => {
  const { option, id } = useParams();
  const [ctrlPressed, setCtrlPressed] = useState(false);
  function InvalidCharacters(value) {
    return /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/.test(value);
  }
  useEffect(() => {
    if (activeKey === "five") {
      document.getElementById("1-Temp_value").focus();
    }
    const handleKeyDown = (event) => {
      let activeField = document.activeElement;

      let row = Records[0].temperature_function.length;
      let currentField = activeField.id.split("-")[1];
      let indexOfField = activeField.id.split("-")[0];
      let nextField = "";

      if (currentField != undefined) {
        switch (event.ctrlKey + "+" + event.key + "+" + activeKey) {
          case "false+ArrowDown+five":
            // console.log("indexOfField",indexOfField,row,indexOfField == row);
            if (indexOfField < row) {
              if (indexOfField == 1) {
                document
                  .getElementById(Number(indexOfField) + 2 + "-" + currentField)
                  .focus();
              } else if (indexOfField == 2) {
              } else {
                document
                  .getElementById(Number(indexOfField) + 1 + "-" + currentField)
                  .focus();
              }
            } else {
              if (indexOfField == row) {
                document.getElementById("2-" + currentField).focus();
              }
            }
            break;

          case "false+ArrowUp+five":
            if (indexOfField > 1) {
              if (indexOfField == 2) {
                document.getElementById(row + "-" + currentField).focus();
              } else if (indexOfField == 3) {
                document.getElementById("1-" + currentField).focus();
              } else {
                document
                  .getElementById(Number(indexOfField) - 1 + "-" + currentField)
                  .focus();
              }
            }
            break;

          case "true+ArrowRight+five":
            setActiveKey("six");
            break;

          case "true+ArrowDown+five":
            console.log("true+ArrowDown");
            handleIncrease(Records[0].temperature_function.length - 1);
            document.getElementById("1-Temp_value").focus();
            break;

          case "true+ArrowUp+five":
            if (row > 3) {
              handleDecrease(Records[0].temperature_function.length - 1);
              document.getElementById("1-Temp_value").focus();
            }
            break;

          default:
            break;
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setActiveKey, activeKey]);

  function HandleTemp(Event, index) {
    console.log("Event", Event.target.name);
    setRecords((item) => {
      let record = [...Records];
      record[0].temperature_function[index] = {
        ...record[0].temperature_function[index],
        [Event.target.name]: Event.target.value,
      };
      return record;
    });
  }
  const handleIncrease = (index) => {
    setRecords((prevRecords) => {
      let Records = [...prevRecords];
      Records[0].temperature_function.push({
        Temperature_stages: "Zone " + index,
        Temp_set: "10",
      });
      return Records;
    });
  };
  const handleDecrease = (index) => {
    setRecords((prevRecords) => {
      let Records = [...prevRecords];
      Records[0].temperature_function.pop();
      return Records;
    });
  };

  const DropdownValues = [
    { value: "", label: "Select" },
    { value: "1", label: "±1" },
    { value: "2", label: "±2" },
    { value: "3", label: "±3" },
    { value: "4", label: "±4" },
    { value: "5", label: "±5" },
    { value: "6", label: "±6" },
    { value: "7", label: "±7" },
    { value: "8", label: "±8" },
    { value: "9", label: "±9" },
    { value: "10", label: "±10" },
    { value: "11", label: "±11" },
    { value: "12", label: "±12" },
    { value: "13", label: "±13" },
    { value: "14", label: "±14" },
    { value: "15", label: "±15" },
  ];
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th colSpan={2}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "90% 10%",
                  alignItems: "center",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <Lable
                    value={"Temperature"}
                    type={"Head"}
                    style={{ padding: "1px" }}
                  />
                </div>
                <div>
                  <button
                    className="nextButtonLabel"
                    onClick={(e) => {
                      setActiveKey("six");
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Lable
                value={"STAGES"}
                style={{ textAlign: "center", padding: "0px" }}
              />
            </td>
            <td>
              <Lable
                value={"BARREL TEMP"}
                style={{ textAlign: "center", padding: "0px" }}
              />
            </td>
          </tr>
          {Records[0].temperature_function.map((item, index) => {
            if (index != 1) {
              return (
                <tr>
                  <td>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto auto",
                      }}
                    >
                      <Lable
                        value={`${item.Temperature_stages}`}
                        style={{ textAlign: "center", padding: "0px" }}
                      />

                      <Field
                        id={index + 1}
                        label={""}
                        name={"Temp_set"}
                        className={"fieldSingleGrid"}
                        options={{
                          type: "dropdown",
                          disabled: option == "view",
                          values: DropdownValues,
                        }}
                        value={item}
                        OnChange={(e) => {
                          HandleTemp(e, index);
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <Field
                      id={index + 1}
                      label={""}
                      name={"Temp_value"}
                      className={"fieldSingleGrid"}
                      options={{
                        type: "text",

                        disabled: option == "view",
                      }}
                      value={item}
                      OnChange={(e) => {
                        const value = e.target.value;
                        if (InvalidCharacters(value)) {
                          e.preventDefault();
                          return;
                        }
                        HandleTemp(e, index);
                      }}
                    />
                  </td>
                </tr>
              );
            }
          })}
          <tr>
            <td>
              <div
                style={{ display: "grid", gridTemplateColumns: "auto auto" }}
              >
                <Lable
                  value={`${Records[0].temperature_function[1].Temperature_stages}`}
                  style={{ textAlign: "center", padding: "0px" }}
                />

                <Field
                  label={""}
                  id={2}
                  name={"Temp_set"}
                  className={"fieldSingleGrid"}
                  options={{
                    type: "dropdown",
                    disabled: option == "view",
                    values: DropdownValues,
                  }}
                  value={Records[0].temperature_function[1]}
                  OnChange={(e) => {
                    HandleTemp(e, 1);
                  }}
                />
              </div>
            </td>
            <td>
              <Field
                id={2}
                label={""}
                name={"Temp_value"}
                className={"fieldSingleGrid"}
                options={{
                  type: "text",

                  disabled: option == "view",
                }}
                value={Records[0].temperature_function[1]}
                OnChange={(e) => {
                  const value = e.target.value;
                  if (InvalidCharacters(value)) {
                    e.preventDefault();
                    return;
                  }
                  HandleTemp(e, 1);
                }}
              />
            </td>
          </tr>
          <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
            <div style={{}}>
              <Field
                style={{ width: "30px", fontSize: "18px" }}
                label={"+"}
                options={{ type: "Button" }}
                onClick={() =>
                  handleIncrease(Records[0].temperature_function.length - 1)
                }
              />
            </div>
            <div>
              {Records[0].temperature_function.length > 3 && (
                <Field
                  buttonStyle={"cusbtn"}
                  style={{ width: "30px", fontSize: "18px" }}
                  label={"-"}
                  options={{ type: "Button" }}
                  onClick={() =>
                    handleDecrease(Records[0].temperature_function.length - 1)
                  }
                />
              )}
            </div>
          </div>
        </tbody>
      </table>
    </div>
  );
};
export default TemperatureSmp;
