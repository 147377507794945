import React, { useState, useRef, useEffect } from "react";
import "../../../Styles/Deviation.css";

import {
  SECRET_KEY,
  CODEGEN_URL,
  PROCESS_TRANSACTION_URL,
} from "../../../Utils/constants.js";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import { setMessage } from "../../../Lib/SetMessage.js";

import Unitechlogo from "../../../icons/Unitechlogo.jpeg";

import useScanDetection from "use-scan-detection";

import DatePicker from "../../../Lib/DatePicker";
import DropdownFetcher from "../../../Lib/DropdownFetcher.js";
import DropdownFilterSelect from "../../../Components/DropdownFilterSelectFetcher.js";
import Base64Convertor from "../../../Lib/Base64Convertor";
import Select from "react-select";
import ReadRecord from "../../../Lib/API/ReadRecord.js";


const initialState = {
  Model: "",
  partName: "",
  Date1: new Date().toISOString().split("T")[0],
  RforDev: "",
  DevPic: "",
  InvStsCp: "",
  InvtStsFGoods: "",
  StkAtCustomer: "",
  TempAction: "",
  Dev: "",
  TillDate: "",
  DResp: "",
  PerAct: "",
  TargetDate: "",
  PerAResp: "",
  PerNotes: "",
  PerAChecBy: "",
  PerARecBy: "",
  PerAAprBy: "",
  ReqComm: "",
  ReqNotes: "",
  ReqChecBy: "",
  ReqRecBy: "",
  ReqAprBy: "",
  Machine_No: "",
  dev_frm: [],
};
function DeviationFormCreate() {
  const scrollTop = useRef(null);
  const [isCreating, setIsCreating] = useState(false);

  const [DevForm, setDevForm] = useState(initialState);
  const [partDropdown, setPartDropdown] = useState([]);
  const [EmployeeDropdown, setEmployeeDropdown] = useState([]);


  const handleDateChange = (newDate) => {
    setDevForm({ ...DevForm, Date: newDate });
  };
  const handleDateChange1 = (newDate) => {
    setDevForm({ ...DevForm, TillDate: newDate });
  };
  const handleDateChange2 = (newDate) => {
    setDevForm({ ...DevForm, TargetDate: newDate });
  };
  // const handleImageChange = (image) => {
  //   setDevPic(URL.createObjectURL(image));
  // };
  const handlePhotoChange = async (e) => {
    const selectedFile = e.target.files[0];
    let img = await Base64Convertor(selectedFile);
    setDevForm({ ...DevForm, DevPic: img });
  };

  const id = "DevFrm";
  const name = "DevFrm";
  // const CodeGen = async () => {
  //   try {
  //     const val = {
  //       tablename: "machine_part_details",
  //       PrimaryCode: "mc_part_code",
  //       PrimaryVal: DevForm.MachineNo,
  //       FieldCode: "mc_part_name",
  //       FieldVal: DevForm.MachineName,
  //     };
  //     console.log(val);
  //     const response = await getAPIRequest(CODEGEN_URL, "POST", val);
  //     const response1 = await response.json();
  //     console.log("response1", response1);
  //     return response1.success === "true";
  //   } catch (error) {
  //     throw error;
  //   }
  // };

  useEffect(() => {
    document.title = "Deviation Form"
  }, [])
  const handleCreate = async (e) => {
    e.preventDefault();
    const currentDate = new Date().toISOString().split("T")[0];
    const isSubmissionSuccessful = true;
    if (!DevForm.partName || !DevForm.RforDev || !DevForm.TargetDate || !DevForm.DResp || !DevForm.PerAResp || !DevForm.DevPic) {
      setIsCreating(true);
      alert(GeneralConstants.MANDATORY_ALERT);
      if (scrollTop.current) {
        scrollTop.current.scrollIntoView({ behavior: "smooth" });
      }
      return;
    }
    try {
      // const isDuplicate = await CodeGen();

      // if (isDuplicate) {
      //   alert(`${GeneralConstants.ALREADY_EXIST}`);
      //   setDevForm({ ...DevForm, ...initialState });
      //   setIsCreating(false);

      //   return;
      // }
      const data = {
        data: [
          {
            Req_note: DevForm.ReqNotes,
            Per_appro_by: DevForm.PerAAprBy.value,
            Req_rec_by: DevForm.ReqRecBy.value,
            Part_name: DevForm.partName.value,
            Per_rec_by: DevForm.PerARecBy.value,
            Temp_act: DevForm.TempAction,
            Dev_pic: DevForm.DevPic,
            Dev_resp: DevForm.DResp.value,
            Date: DevForm.Date1,
            Permanent_act: DevForm.PerAct,
            Req_appro_by: DevForm.ReqAprBy.value,
            Req_comments: DevForm.ReqComm,
            Per_checked_by: DevForm.PerAChecBy.value,
            Dev: DevForm.Dev,
            Invt_sts_fin_goods: DevForm.InvtStsFGoods,
            Model: DevForm.Model,
            Invt_sts_child_part: DevForm.InvStsCp,
            Per_resp: DevForm.PerAResp.value,
            Req_checked_by: DevForm.ReqChecBy.value,
            Till_date: DevForm.TillDate || currentDate,
            Target_date: DevForm.TargetDate,
            Per_note: DevForm.PerNotes,
            Rea_for_dev: DevForm.RforDev,
            Stk_at_cus: DevForm.StkAtCustomer,
          },
        ],
      };
      data["_keyword_"] = "dev_form";
      data["secretkey"] = SECRET_KEY;

      const response = await getAPIRequest(
        PROCESS_TRANSACTION_URL,
        "POST",
        data
      );
      const response1 = await response.json();

      if (response1.success === "false") {
        alert(GeneralConstants.FAIL_TO_SAVE_MACHINE);
        window.location.reload();
      } else {
        const dailypro = [...DevForm.dev_frm, response.data];
        setDevForm({
          ...DevForm,
          dev_frm: dailypro,
        });
      }
      if (isSubmissionSuccessful) {


        setMessage({
          NoteFrom: DevForm.DResp.value,
          NoteTo: DevForm.PerAChecBy.value,
          NoteMessage:
            `The Deviation of Part ${DevForm.partName.label} has been detected and noted by ${DevForm.DResp.value}-
          ${DevForm.DResp.label}, Please do check the Form!`,
          NoteRecordId: response1.ROWID,
          TableName: "DeviationForm",
          NoteCreatedBy: localStorage.getItem("empId"),
        });
        setMessage({
          NoteFrom: DevForm.DResp.value,
          NoteTo: DevForm.PerARecBy.value,
          NoteMessage:
            `The Deviation of Part ${DevForm.partName.label} has been detected and noted by ${DevForm.DResp.value}-
          ${DevForm.DResp.label}`,
          NoteRecordId: response1.ROWID,
          TableName: "DeviationForm",
          NoteCreatedBy: localStorage.getItem("empId"),
        });
        setMessage({
          NoteFrom: DevForm.DResp.value,
          NoteTo: DevForm.PerAAprBy.value,
          NoteMessage:
            `The Deviation of Part ${DevForm.partName.label} has been detected and noted by ${DevForm.DResp.value}-
          ${DevForm.DResp.label}, Please do verify!`,
          NoteRecordId: response1.ROWID,
          TableName: "DeviationForm",
          NoteCreatedBy: localStorage.getItem("empId"),
        });
        setMessage({
          NoteFrom: DevForm.PerAResp.value,
          NoteTo: DevForm.ReqChecBy.value,
          NoteMessage:
            `The Deviation of Part ${DevForm.partName.label} has been detected and noted by ${DevForm.PerAResp.value}-
          ${DevForm.PerAResp.label}, Please do check the Form!`,
          NoteRecordId: response1.ROWID,
          TableName: "DeviationForm",
          NoteCreatedBy: localStorage.getItem("empId"),
        });
        setMessage({
          NoteFrom: DevForm.PerAResp.value,
          NoteTo: DevForm.ReqRecBy.value,
          NoteMessage:
            `The Deviation of Part ${DevForm.partName.label} has been detected and noted by ${DevForm.PerAResp.value}-
          ${DevForm.PerAResp.label}`,
          NoteRecordId: response1.ROWID,
          TableName: "DeviationForm",
          NoteCreatedBy: localStorage.getItem("empId"),
        });
        setMessage({
          NoteFrom: DevForm.PerAResp.value,
          NoteTo: DevForm.ReqAprBy.value,
          NoteMessage:
            `The Deviation of Part ${DevForm.partName.label} has been detected and noted by ${DevForm.PerAResp.value}-
          ${DevForm.PerAResp.label}, Please do verify!`,
          NoteRecordId: response1.ROWID,
          TableName: "DeviationForm",
          NoteCreatedBy: localStorage.getItem("empId"),
        });
        const img = document.getElementById('dev_pics');
        img.setAttribute('src', '');
        img.value = "";

        alert(`${GeneralConstants.SUBMISSION_SUCCESSFUL}`);
        setDevForm({ ...DevForm, ...initialState });
        setIsCreating(false);


        if (scrollTop.current) {
          scrollTop.current.scrollIntoView({ behavior: "smooth" });
        }

      }
    } catch (error) {
      console.log(error);
      alert(`${DevForm.MachineName} ${GeneralConstants.SUBMISSION_FAILED}`);
      window.location.reload();
    }
  };
  const partNoOptions = partDropdown.map((i) => ({
    value: i.mc_part_name,
    label: i.mc_part_name,
  }));
  const empPerARespOptions = EmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const empDRespOptions = EmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const empPerAChecByOptions = EmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const empPerARecByOptions = EmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const empPerAAprByOptions = EmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const empReqChecByOptions = EmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const empReqRecByOptions = EmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const empReqAprByOptions = EmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const currentDateTime = new Date();
  const hours = currentDateTime.getHours().toString().padStart(2, "0");
  const minutes = currentDateTime.getMinutes().toString().padStart(2, "0");
  // const currentTime = `${hours}:${minutes}`;
  const handleDropdownChange = (selectedOption) => {
    const selectedMachine = partDropdown.find(
      (option) => option.mc_part_name === selectedOption.value
    );

    if (selectedMachine) {
      setDevForm({
        ...DevForm,
        partName: {
          value: selectedMachine.mc_part_name,
          label: selectedMachine.mc_part_name,
        },
        Model: selectedMachine.mc_part_flex_01,
      });
    }
  };
  const handleDevResponsibility = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setDevForm({
        ...DevForm,
        DResp: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      });
    }
  };
  const handlePerActResponsibility = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setDevForm({
        ...DevForm,
        PerAResp: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      });
    }
  };
  const handleDevCheckedBy = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setDevForm({
        ...DevForm,
        PerAChecBy: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      });
    }
  };
  const handleDevReceivedBy = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setDevForm({
        ...DevForm,
        PerARecBy: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      });
    }
  };
  const handleDevApprovedBy = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setDevForm({
        ...DevForm,
        PerAAprBy: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      });
    }
  };
  const handlePerActCheckedBy = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setDevForm({
        ...DevForm,
        ReqChecBy: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      });
    }
  };
  const handlePerActReceivedBy = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setDevForm({
        ...DevForm,
        ReqRecBy: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      });
    }
  };
  const handlePerActApprovedBy = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setDevForm({
        ...DevForm,
        ReqAprBy: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      });
    }
  };

  // barcode scannning to get input
  useScanDetection({
    onComplete: (code) => {
      code = code.replaceAll("Shift", "");
      setField(code);
    },
    minLength: 3,
  });
  // useEffect(() => {
  //   setDevForm({
  //     ...DevForm,

  //     Date1: currentDateTime.toISOString().split("T")[0],

  //   });


  // },[]);

  // set value to the appropiate field
  function setField(code) {
    // let isnumber = Number(code).toString() !== "NaN";
    if (code.includes("EMP")) {
      // setEmployeeName(code);
      alert("Invalid Input");
      return false;
    } else
      if (code.includes("RMI")) {
        alert("Invalid Input");
        return false;
      } else if (code.includes("CUS")) {
        alert("Invalid Input");
        return false;
      } else if (code.includes("FG")) {
        alert("Invalid Input");
        return false;
      } else if (code.includes("IMM")) {
        alert("Invalid Input");
        return false;
      } else if (code.includes("SUP")) {
        alert("Invalid Input");
        return false;
      } else {
        // handleDropdownChange({ value: code });
        GetSelectValue1(code, "mac_part_dtl_with_model_dropdown")
      }

  }
  function GetSelectValue(value, keyword, setName) {
    if (value.length > 0) {
      ReadRecord({
        _keyword_: keyword,
        key: "%" + value + "%",
        value: "%" + value + "%"
      }).then((res) => {
        console.log(res.data);
        setName(res.data);
        // setKey(keyvalue[options.reference]);
      });
    }
  }
  function GetSelectValue1(value, keyword) {
      ReadRecord({
        _keyword_: keyword,
        key: "%" + value + "%",
        value: "%" + value + "%"
      }).then((res) => {
        console.log("res",res);
        // setName(res.data);
        if(res.rows>0){
          setDevForm({
            ...DevForm,
            partName: {
              value: res.data[0].mc_part_code,
              label: res.data[0].mc_part_name ,
            },
            Model: res.data[0].mc_part_flex_01 ,
          });
        }else{
          alert("Not Available")
        }
        
      });
  }

  return (
    <div>
      <form id="DevFrm-formContainer-Create" className="form-Deviation-Create">
        <div ref={scrollTop}>
          <table style={{ textAlign: "center", width: "100%" }}>
            <tbody>
              <tr>
                {/* <th style={{ textAlign: "center", width: "20%" }}>
                <img src={Unitechlogo} style={{ width: "50px" }}></img>
              </th> */}
                <th
                  id={`${id}-Title`}
                  name={`${name}-Title`}
                  colSpan="6"
                  style={{
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    padding: "10px 0px",
                  }}
                >
                  {/* <font style={{ fontSize: "24px" }}>
                  UNITECH PLASTO COMPONENTS (P) LTD
                </font>
                <br></br> */}
                  <font color="#8B0000">DEVIATION FORM</font>
                </th>
              </tr>

              <tr>
                <td className="topic-head">Supplier: </td>
                <td className="topic-head">UNITECH</td>
                <td className="topic-head">DATE</td>
                <td>
                  <DatePicker value={DevForm.Date1} onChange={handleDateChange} dis={true} />
                </td>
              </tr>
              <tr>
                <td className="topic-head">
                  Model<span style={{ color: "red" }}>*</span>
                </td>
                <td>
                  <input

                    type="text"
                    value={DevForm.Model}
                    onChange={(e) =>
                      setDevForm({
                        ...DevForm,
                        Model: e.target.value,
                      })
                    }
                    disabled={DevForm.partName === ""}
                  />
                  {DevForm.Model === '' && isCreating && <span className="Req">Required *</span>}


                </td>
                <td rowSpan={3} colSpan={2} className="topic-head">
                  <div>
                    {DevForm.DevPic && (
                      <img
                        src={DevForm.DevPic}
                        alt="Selected"
                        style={{
                          display: "block",
                          margin: "auto",
                          width: "300px",
                          height: "250px",
                        }}
                      />
                    )}
                    <span>Deviation Photo</span>
                    <br />
                    <input
                      id="dev_pics"
                      type="file"
                      accept="image/*"
                      capture="camera"
                      onChange={(e) => handlePhotoChange(e)}
                      disabled={DevForm.partName === ""}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="topic-head">
                  Part Name<span style={{ color: "red" }}>*</span>
                </td>
                <td>
                  {/* <DropdownFetcher
                    keyword="mac_part_dtl_with_model_dropdown"
                    setValue={setPartDropdown}
                  /> */}
                  {/* <DropdownSelect text="Select Part Name" 
               dropdown={partDropdown} 
               value={DevForm.partName}
                optionIdProp="mc_part_code"
                 optionNameProp="mc_part_name" 
                 onChange={(e) =>
                  setDevForm({
                    ...DevForm,
                    partName: e.target.value,
                  })
                }   */}
                  <Select

                    id={`${id}-partName`}
                    name={`${name}-partName`}
                    value={DevForm.partName}
                    onChange={handleDropdownChange}
                    onInputChange={(event) => { GetSelectValue(event, "Get_part_from_smp", setPartDropdown) }}
                    options={partNoOptions}
                    placeholder="Search Part..."
                  />
                  {DevForm.partName === '' && isCreating && <span className="Req">Required *</span>}

                </td>
              </tr>
              <tr>
                <td colSpan="2" className="topic-head">
                  <span style={{ textAlign: "center" }}>
                    Reason for deviation<span style={{ color: "red" }}>*</span>
                  </span>
                  <textarea
                    type="text"
                    value={DevForm.RforDev}
                    onChange={(e) =>
                      setDevForm({
                        ...DevForm,
                        RforDev: e.target.value,
                      })
                    }
                    disabled={DevForm.partName === ""}
                  />{" "}

                  {DevForm.RforDev === '' && isCreating && <span className="Req">Required *</span>}

                </td>
              </tr>
              <tr>
                <td colSpan="2" className="topic-head">
                  <span>Inventory Status Child Part</span>
                  <br></br>
                  <br></br><br></br>
                  <input
                    disabled={DevForm.partName === "" || DevForm.RforDev === "" || DevForm.DevPic === ""}
                    type="text"
                    value={DevForm.InvStsCp}
                    onChange={(e) =>
                      setDevForm({
                        ...DevForm,
                        InvStsCp: e.target.value,
                      })
                    }
                  />
                </td>
                <td colSpan="1.5" className="topic-head">
                  <span>Inventory Status Finished Goods</span>
                  <br></br>
                  <br></br><br></br>
                  <input
                    disabled={DevForm.partName === "" || DevForm.RforDev === "" || DevForm.DevPic === ""}
                    type="text"
                    value={DevForm.InvtStsFGoods}
                    onChange={(e) =>
                      setDevForm({
                        ...DevForm,
                        InvtStsFGoods: e.target.value,
                      })
                    }
                  />
                </td>
                <td colSpan="1.5" className="topic-head">
                  <span>Expected stock at Customer</span>
                  <br></br>
                  <br></br><br></br>
                  <input
                    disabled={DevForm.partName === "" || DevForm.RforDev === "" || DevForm.DevPic === ""}
                    type="text"
                    value={DevForm.StkAtCustomer}
                    onChange={(e) =>
                      setDevForm({
                        ...DevForm,
                        StkAtCustomer: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>

              <tr>
                <td colSpan="4" className="topic-head">
                  <span style={{ textAlign: "center" }}> Temporary Action:</span>
                  <input
                    disabled={DevForm.partName === "" || DevForm.RforDev === "" || DevForm.DevPic === ""}
                    type="text"
                    value={DevForm.TempAction}
                    onChange={(e) =>
                      setDevForm({
                        ...DevForm,
                        TempAction: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>

              <tr>
                <td colSpan="2" className="topic-head">
                  <span style={{ textAlign: "center" }}> Deviation</span>
                  <br></br> <br></br> <br></br>
                  <input
                    disabled={DevForm.partName === "" || DevForm.RforDev === "" || DevForm.DevPic === ""}
                    type="text"
                    value={DevForm.Dev}
                    onChange={(e) =>
                      setDevForm({
                        ...DevForm,
                        Dev: e.target.value,
                      })
                    }
                  />
                </td>
                <td className="topic-head">
                  <span>Till Date</span>
                  <br></br> <br></br> <br></br>
                  <DatePicker
                    value={DevForm.TillDate}
                    onChange={handleDateChange1}
                  />
                </td>
                <td className="topic-head">
                  <span>Responsibility</span><span style={{ color: "red" }}>*</span>
                  <br></br> <br></br> <br></br>
                  {/* <input 
disabled={DevForm.partName==="" || DevForm.RforDev === "" || DevForm.DevPic === ""}
                  type="text"
                  value={DevForm.DResp}
                  onChange={(e) =>
                    setDevForm({
                      ...DevForm,
                      DResp: e.target.value,
                    })
                  }
                /> */}
                  <Select
                    isDisabled={DevForm.partName === "" || DevForm.RforDev === "" || DevForm.DevPic === ""}

                    id={`${id}-DResp`}
                    name={`${name}-DResp`}
                    value={DevForm.DResp}
                    onChange={handleDevResponsibility}
                    options={empDRespOptions}
                    placeholder="Select Receiver"
                  />
                  {DevForm.DResp === '' && isCreating && <span className="Req">Required *</span>}

                </td>
              </tr>
              <tr>
                <td colSpan="2" className="topic-head">
                  <span>Permanent Action</span>
                  <br></br> <br></br> <br></br>
                  <input
                    disabled={DevForm.partName === "" || DevForm.RforDev === "" || DevForm.DevPic === ""}
                    type="text"
                    value={DevForm.PerAct}
                    onChange={(e) =>
                      setDevForm({
                        ...DevForm,
                        PerAct: e.target.value,
                      })
                    }
                  />
                </td>
                <td className="topic-head">
                  <span>Target date<span style={{ color: "red" }}>*</span></span>
                  <br></br> <br></br> <br></br>
                  <DatePicker
                    value={DevForm.TargetDate}
                    onChange={handleDateChange2}
                  />
                  {DevForm.TargetDate === '' && isCreating && <span className="Req">Required *</span>}

                </td>

                <td className="topic-head">
                  <span>Responsibility</span><span style={{ color: "red" }}>*</span>
                  <br></br> <br></br> <br></br>
                  <Select
                    isDisabled={DevForm.partName === "" || DevForm.RforDev === "" || DevForm.DevPic === ""}
                    id={`${id}-PerAResp`}
                    name={`${name}-PerAResp`}
                    value={DevForm.PerAResp}
                    onChange={handlePerActResponsibility}
                    options={empPerARespOptions}
                    placeholder="Select Receiver"
                  />
                  {DevForm.PerAResp === '' && isCreating && <span className="Req">Required *</span>}

                </td>
              </tr>
              <tr>
                <td className="topic-head">
                  <span>Note:</span>
                  <br></br> <br></br> <br></br>
                  <input
                    disabled={DevForm.partName === "" || DevForm.RforDev === "" || DevForm.DevPic === ""}
                    type="text"
                    value={DevForm.PerNotes}
                    onChange={(e) =>
                      setDevForm({
                        ...DevForm,
                        PerNotes: e.target.value,
                      })
                    }
                  />
                </td>
                <td className="topic-head">
                  <span>Checker</span>
                  <br></br> <br></br> <br></br>
                  <DropdownFetcher
                    keyword="emp_details_dropdown_all"
                    setValue={setEmployeeDropdown}
                  />
                  <Select
                    isDisabled={DevForm.partName === "" || DevForm.RforDev === "" || DevForm.DevPic === ""}
                    id={`${id}-PerAChecBy`}
                    name={`${name}-PerAChecBy`}
                    value={DevForm.PerAChecBy}
                    onChange={handleDevCheckedBy}
                    options={empPerAChecByOptions}
                    placeholder="Select Checker"
                  />
                </td>
                <td className="topic-head">
                  <span>Receiver</span>
                  <br></br> <br></br> <br></br>
                  <DropdownFetcher
                    keyword="emp_details_dropdown_all"
                    setValue={setEmployeeDropdown}
                  />
                  <Select
                    isDisabled={DevForm.partName === "" || DevForm.RforDev === "" || DevForm.DevPic === ""}
                    id={`${id}-PerARecByLabel`}
                    name={`${name}-PerARecByLabel`}
                    value={DevForm.PerARecBy}
                    onChange={handleDevReceivedBy}
                    options={empPerARecByOptions}
                    placeholder="Select Receiver"
                  />
                </td>
                <td className="topic-head">
                  <span>Approver</span>
                  <br></br> <br></br> <br></br>
                  <DropdownFetcher
                    keyword="emp_details_dropdown_all"
                    setValue={setEmployeeDropdown}
                  />
                  <Select
                    isDisabled={DevForm.partName === "" || DevForm.RforDev === "" || DevForm.DevPic === ""}
                    id={`${id}-PerAAprBy`}
                    name={`${name}-PerAAprBy`}
                    value={DevForm.PerAAprBy}
                    onChange={handleDevApprovedBy}
                    options={empPerAAprByOptions}
                    placeholder="Select Approver"
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="4" className="topic-head">
                  <span>If any requirements / comments :</span>

                  <textarea
                    rows={5}
                    cols={50}
                    value={DevForm.ReqComm}
                    onChange={(e) =>
                      setDevForm({
                        ...DevForm,
                        ReqComm: e.target.value,
                      })
                    }
                    disabled={DevForm.partName === "" || DevForm.RforDev === "" || DevForm.DevPic === ""}

                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">
                  <span>Note:</span>
                  <br></br> <br></br> <br></br>
                  <input
                    disabled={DevForm.partName === "" || DevForm.RforDev === "" || DevForm.DevPic === ""}
                    type="text"
                    value={DevForm.ReqNotes}
                    onChange={(e) =>
                      setDevForm({
                        ...DevForm,
                        ReqNotes: e.target.value,
                      })
                    }
                  />
                </td>
                <td className="topic-head">
                  <span>Checker</span>
                  <br></br> <br></br> <br></br>
                  <DropdownFetcher
                    keyword="emp_details_dropdown_all"
                    setValue={setEmployeeDropdown}
                  /><Select
                    isDisabled={DevForm.partName === "" || DevForm.RforDev === "" || DevForm.DevPic === ""}
                    id={`${id}-ReqChecBy`}
                    name={`${name}-ReqChecBy`}
                    value={DevForm.ReqChecBy}
                    onChange={handlePerActCheckedBy}
                    options={empReqChecByOptions}
                    placeholder="Select Checker"
                  />
                </td>
                <td className="topic-head">
                  <span>Receiver</span>
                  <br></br> <br></br> <br></br>
                  <DropdownFetcher
                    keyword="emp_details_dropdown_all"
                    setValue={setEmployeeDropdown}
                  />
                  <Select
                    isDisabled={DevForm.partName === "" || DevForm.RforDev === "" || DevForm.DevPic === ""}
                    id={`${id}-ReqRecBy`}
                    name={`${name}-ReqRecBy`}
                    value={DevForm.ReqRecBy}
                    onChange={handlePerActReceivedBy}
                    options={empReqRecByOptions}
                    placeholder="Select Receiver"
                  />

                </td>
                <td className="topic-head">
                  <span>Approver</span>
                  <br></br> <br></br> <br></br>
                  <DropdownFetcher
                    keyword="emp_details_dropdown_all"
                    setValue={setEmployeeDropdown}
                  />
                  <Select
                    isDisabled={DevForm.partName === "" || DevForm.RforDev === "" || DevForm.DevPic === ""}
                    id={`${id}-ReqAprBy`}
                    name={`${name}-ReqAprBy`}
                    value={DevForm.ReqAprBy}
                    onChange={handlePerActApprovedBy}
                    options={empReqAprByOptions}
                    placeholder="Select Approver"
                  />

                </td>
              </tr>
              <tr>
                <td colSpan={5} style={{ border: "none" }}>
                  <button type="submit" id="sub" onClick={(e) => handleCreate(e)}
                    disabled={DevForm.partName === "" || DevForm.DevPic === "" || DevForm.DResp === "" || DevForm.PerAResp === ""}
                    hidden={DevForm.partName === "" || DevForm.DevPic === "" || DevForm.DResp === "" || DevForm.PerAResp === ""}
                  >
                    Submit
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  );
}

export default DeviationFormCreate;
