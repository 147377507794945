import React, { useEffect, useState, useRef } from "react";
import Popup from "../../../Components/Popup/Popup";
import axios from "axios";

import { API_URL } from "../../../Utils/constants.js";
import "../../../Styles/Part3.css";
import "../../../Styles/Deviation.css";

import * as GeneralConstants from "../../../Utils/GeneralConstants.js";

import Pagination from "../../../Components/Pagination";
import { formatDt } from "../../../Lib/formatDt.js";
import HandleRead from "../../../Lib/HandleRead.js";
import DropdownFetcher from "../../../Lib/DropdownFetcher.js";
import DropdownFilterSelect from "../../../Components/DropdownFilterSelectFetcher.js";
import Base64Convertor from "../../../Lib/Base64Convertor";
import DatePicker from "../../../Lib/DatePicker";
import Select from "react-select";
import GetRecord from "../../../Lib/GetRecord";
import { FaBackspace } from 'react-icons/fa';
import reject from "../../../Lib/reject";
import approve from "../../../Lib/approve";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import UpdateRecord from "../../../Lib/updateRecord";
import { MdDelete } from "react-icons/md";
import ReadRecord from "../../../Lib/API/ReadRecord.js";



function DeviationFormView() {

  const [Id, setId] = useState("");
  const [Model, setModel] = useState("");
  const [PartNme, setPartNme] = useState("");
  const [Date1, setDate] = useState("");
  const [RforDev, setRforDev] = useState("");
  const [DevPic, setDevPic] = useState("");
  const [InvStsCp, setInvStsCp] = useState("");
  const [InvtStsFGoods, setInvtStsFGoods] = useState("");
  const [StkAtCustomer, setStkAtCustomer] = useState("");
  const [TempAction, setTempAction] = useState("");
  const [Dev, setDev] = useState("");
  const [TillDate, setTillDate] = useState("");
  const [DResp, setDResp] = useState("");
  const [PerAct, setPerAct] = useState("");
  const [TargetDate, setargetDate] = useState("");
  const [PerAResp, setPerAResp] = useState("");
  const [PerNotes, setPerNotes] = useState("");
  const [PerAChecBy, setPerAChecB] = useState("");
  const [PerARecBy, setPerARecB] = useState("");
  const [PerAAprBy, setPerAAprB] = useState("");
  const [ReqComm, setReqComm] = useState("");
  const [ReqNotes, setReqNotes] = useState("");
  const [ReqChecBy, setReqChecBy] = useState("");
  const [ReqRecBy, setReqRecBy] = useState("");
  const [ReqAprBy, setReqAprBy] = useState("");
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [CompCde, setCompCde] = useState("");
  const [CompNme, setCompNme] = useState("");
  const [ActS, setActS] = useState("");
  const [Approval, setApproval] = useState("");
  const [EmployeeDropdown, setEmployeeDropdown] = useState([]);
  const [partDropdown, setPartDropdown] = useState([]);
  const [Approver, setApprover] = useState("");
  const [RejectedReason, setRejectedReason] = useState("");
  var [Devfrm, setDevfrm] = useState([]);
  const [isPaginationVisible, setPaginationVisible] = useState(true);

  const [ButtonPopup, setButtonPopup] = useState(false);
  const [ButtonPopup1, setButtonPopup1] = useState(false);
  const [ButtonPopup2, setButtonPopup2] = useState(false);

  // const handlePhotoChange = (e) => {
  //   const selectedFile = e.target.files[0];
  //   setSelectedPhoto(selectedFile);
  //   setDevPic(selectedFile.name);
  // };
  const [back, setback] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [ENC, setENC] = useState("");
  const [remarks, setremarks] = useState('');
  const [cardId, setcardId] = useState('');
  useEffect(() => {
    console.log(id);
    if (id !== undefined) {
      GetRecord(
        {
          _keyword_: "Get_DEVFORM_by_id",
          id: id,
        },
        setENC
      ).then(async (res) => {
        console.log("res - ", res);
        handleView(res[0])
        setButtonPopup(true)
        setback(true)

        await timeout(1000);
        const popupDiv = document.querySelector(".popup");
        if (popupDiv) {
          const elementInsidePopup = popupDiv.querySelector("#close-btn");
          elementInsidePopup.style.display = "none";
        }
      })
    }
  }, [id])
  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }
  function handleReadRecord(id) {
    ReadRecord({
      _keyword_: "Get_approver_by_empId",
      TableID: id,
      TableName: "DeviationForm",
      Approver: localStorage.getItem("empId"),
    }).then((res) => {
      // console.log("res.data,",res);
      if (res.rows > 0) {
        setApprover(res.data[0].Approver);
        setRejectedReason(res.data[0].ApprovalRemark);
      } else {
        setApprover("");
        setRejectedReason("");
      }
    }).then(()=>{
      ReadRecord({
        _keyword_: "Get_approver_ApprovalRemark",
        TableID: id,
        TableName: "DeviationForm"
      }).then((res)=>{
        if(res.rows>0){
          setRejectedReason(res.data[0].ApprovalRemark);
        }else{
          setRejectedReason("");
        }
      })
    })
  }
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 20;

  const [filterDate, setFilterDate] = useState("");
  const [filterPart, setfilterPart] = useState("");
  const filterRecords = () => {
    const filteredData = Devfrm.filter((record) => {
      const dateMatch = record.Date && record.Date.includes(filterDate);
      const mcnoMatch =
        record.Part_name &&
        record.Part_name.toLowerCase().includes(filterPart.toLowerCase());
      return mcnoMatch && dateMatch;
    });

    return filteredData;
  };

  const filteredData = filterRecords();
  const npage = Math.ceil(filteredData.length / recordPerPage);
  const numbers = [...Array(npage).keys()].map((num) => num + 1);

  function handleFilterDateChange(event) {
    setFilterDate(event.target.value);
    setCurrentPage(1);
  }

  function handlefilterPartChange(event) {
    setfilterPart(event.target.value);
    setCurrentPage(1);
  }

  const firstIndex = (currentPage - 1) * recordPerPage;
  const lastIndex = firstIndex + recordPerPage;
  const filteredRecords = filteredData.slice(firstIndex, lastIndex);
  function prePage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function nextPage() {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function changeCpage(Id) {
    setCurrentPage(Id);
  }

  const handleDateChange1 = (newDate) => {
    setTillDate(newDate);
  };

  const handleDateChange2 = (newDate) => {
    setargetDate(newDate);
  };


  // const handlePhotoChangeAfter = async (e) => {
  //   const selectedFile = e.target.files[0];
  //   let img = await Base64Convertor(selectedFile);
  //   setEmpMasterAddState({
  //     ...empMasterAddState,
  //     Img: img,
  //   })
  // };
  const handlePhotoChange = async (e) => {
    const selectedFile = e.target.files[0];
    let img = await Base64Convertor(selectedFile);


    // setSelectedPhoto(selectedFile);
    setDevPic(img);
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    const currentDate = new Date().toISOString().split("T")[0];
    if ( !RforDev || !TargetDate) {
      alert(GeneralConstants.MANDATORY_ALERT);
      return;
    }
    const data = {
      data: [
        {
          Id: Id,
          Date: Date1,
          Model: Model,
          Req_note: ReqNotes,
          Per_appro_by: PerAAprBy.value,
          Req_rec_by: ReqRecBy.value,
          Per_rec_by: PerARecBy.value,
          Temp_act: TempAction,
          Dev_pic: DevPic,
          Dev_resp: DResp.value,
          Date: Date1,
          Permanent_act: PerAct,
          Req_appro_by: ReqAprBy.value,
          Req_comments: ReqComm,
          Per_checked_by: PerAChecBy.value,
          Dev: Dev,
          Invt_sts_fin_goods: InvtStsFGoods,
          Model: Model,
          Invt_sts_child_part: InvStsCp,
          Per_resp: PerAResp.value,
          Req_checked_by: ReqChecBy.value,
          Till_date: TillDate,
          Target_date: TargetDate,
          Per_note: PerNotes,
          Rea_for_dev: RforDev,
          Stk_at_cus: StkAtCustomer,
        },
      ],
    };
    data["_keyword_"] = "dev_form";
    data["secretkey"] = "2bf52be7-9f68-4d52-9523-53f7f267153b";
    axios
      .post(
        `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
        data
      )
      .then((response) => {        
        if (response.data.success === "true") {
        alert("Updated Successfully!");
        setButtonPopup1(false);
        handleReadDeviationForm();
        setPaginationVisible(true);
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Updated Failed!");
       window.location.reload();
      });

  };

  const handleEdit = (e) => {
    console.log("edit",e);
    
    setPaginationVisible(false);
    setButtonPopup1(true);
    setId(e.Id);
    setDate(e.Date);
    setModel(e.Model);
    setPartNme(e.PartName);
    setRforDev(e.Rea_for_dev);
    setDevPic(e.Dev_pic);
    setInvStsCp(e.Invt_sts_child_part);
    setInvtStsFGoods(e.Invt_sts_fin_goods);
    setStkAtCustomer(e.Stk_at_cus);
    setTempAction(e.Temp_act);
    setDev(e.Dev);
    setTillDate(e.Till_date);
    setDResp({ value: e.Dev_resp, label: e.DevResp });
    setPerAct(e.Permanent_act);
    setargetDate(e.Target_date);
    setPerAResp({ value: e.Per_resp, label: e.PerResp });
    setPerNotes(e.Per_note);
    setPerAChecB({ value: e.Per_checked_by, label: e.PerCheckedBy });
    setPerARecB({ value: e.Per_rec_by, label: e.PerRrecBy });
    setPerAAprB({ value: e.Per_appro_by, label: e.PerApproBy });
    setReqComm(e.Req_comments);
    setReqNotes(e.Req_note);
    setReqChecBy({ value: e.Req_checked_by, label: e.ReqCheckedBy });
    setReqRecBy({ value: e.Req_rec_by, label: e.ReqRecBy });
    setReqAprBy({ value: e.Req_appro_by, label: e.ReqApproBy });

  };
  const handleView = (e) => {
    handleReadRecord(e.Id);
    setPaginationVisible(false);
    setButtonPopup(true);
    setId(e.Id);
    setDate(e.Date);
    setModel(e.Model);
    setPartNme(e.PartName);
    setRforDev(e.Rea_for_dev);
    setDevPic(e.Dev_pic);
    setInvStsCp(e.Invt_sts_child_part);
    setInvtStsFGoods(e.Invt_sts_fin_goods);
    setStkAtCustomer(e.Stk_at_cus);
    setTempAction(e.Temp_act);
    setDev(e.Dev);
    setTillDate(e.Till_date);
    setDResp(`${e.Dev_resp}-${e.DevResp}`);
    setPerAct(e.Permanent_act);
    setargetDate(e.Target_date);
    setPerAResp(`${e.Per_resp}-${e.PerResp}`);
    setPerNotes(e.Per_note);
    setPerAChecB(e.Per_checked_by !== undefined && e.PerCheckedBy !== undefined ? `${e.Per_checked_by}-${e.PerCheckedBy}` : "");
    setPerARecB(e.Per_rec_by !== undefined && e.PerRrecBy !== undefined ? `${e.Per_rec_by}-${e.PerRrecBy}` : "");
    setPerAAprB(e.Per_appro_by !== undefined && e.PerApproBy !== undefined ? `${e.Per_appro_by}-${e.PerApproBy}` : "");
    setReqComm(e.Req_comments);
    setReqNotes(e.Req_note);
    setReqChecBy(e.Req_checked_by !== undefined && e.ReqCheckedBy !== undefined ? `${e.Req_checked_by}-${e.ReqCheckedBy}` : "");
    setReqRecBy(e.Req_rec_by !== undefined && e.ReqRecBy !== undefined ? `${e.Req_rec_by}-${e.ReqRecBy}` : "");
    setReqAprBy(e.Req_appro_by !== undefined && e.ReqApproBy !== undefined ? `${e.Req_appro_by}-${e.ReqApproBy}` : "");
    setApproval(e.Approval_status);

  };
  // const handleChange = (e) => {
  //   const confirmation = window.confirm("Do you want to delete?");
  //   if (!confirmation) {
  //     return;
  //   }

  //   const data = {
  //     data: [
  //       {
  //         Id: Id,
  //         Model: Model,
  //         ActiveStatus: "ok",
  //       },
  //     ],
  //   };
  //   data["_keyword_"] = "dev_form";
  //   data["secretkey"] = "2bf52be7-9f68-4d52-9523-53f7f267153b";
  //   axios
  //     .post(
  //       `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
  //       data
  //     )
  //     .then((response) => {
  //       setDevfrm([...Devfrm, response.data]);
  //       alert(`${Model} Deleted Successfully!`);

  //       window.location.reload();
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       alert(`${Model} Delete Failed!`);

  //       window.location.reload();
  //     });
  // };
  // const handleDelete = (e) => {
  //   setPaginationVisible(true);
  //   setButtonPopup2(true);
  //   setId(e.Id);
  //   setModel(e.Model);
  //   setActS(e.ActiveStatus);
  // };

  // const handleApproval = (e) => {
  //   e.preventDefault();

  //   const data = {
  //     data: [
  //       {
  //         Id: Id,
  //         Req_note: ReqNotes,
  //         Req_comments: ReqComm,
  //       },
  //     ],
  //   };
  //   data["_keyword_"] = "dev-Approval";
  //   data["secretkey"] = "2bf52be7-9f68-4d52-9523-53f7f267153b";
  //   axios
  //     .post(
  //       `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
  //       data
  //     )
  //     .then((response) => {
  //       setDevfrm([...Devfrm, response.data]);
  //       alert("status Updated !");
  //       console.log("dat");
  //       // window.location.reload();
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       alert("Failed!");

  //       //window.location.reload();
  //     });
  // };


  const viewPagination = () => {
    setPaginationVisible(true);
  };
  const partNoOptions = partDropdown.map((i) => ({
    value: i.mc_part_code,
    label: i.mc_part_name,
  }));
  const empPerARespOptions = EmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const empDRespOptions = EmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const empPerAChecByOptions = EmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const empPerARecByOptions = EmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const empPerAAprByOptions = EmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const empReqChecByOptions = EmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const empReqRecByOptions = EmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const empReqAprByOptions = EmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const handleDevResponsibility = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setDResp({
        value: selected.Emp_code,
        label: selected.Emp_name,
      },
      );
    }
  };
  const handlePerActResponsibility = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setPerAResp({
        value: selected.Emp_code,
        label: selected.Emp_name,
      },
      );
    }
  };
  const handleDevCheckedBy = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setPerAChecB({
        value: selected.Emp_code,
        label: selected.Emp_name,
      },
      );
    }
  };
  const handleDevReceivedBy = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setPerARecB({
        value: selected.Emp_code,
        label: selected.Emp_name,
      },
      );
    }
  };
  const handleDevApprovedBy = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setPerAAprB({
        value: selected.Emp_code,
        label: selected.Emp_name,
      },
      );
    }
  };
  const handlePerActCheckedBy = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setReqChecBy({ value: selected.Emp_code, label: selected.Emp_name },
      );
    }
  };
  const handlePerActReceivedBy = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setReqRecBy({
        value: selected.Emp_code,
        label: selected.Emp_name,
      },
      );
    }
  };
  const handlePerActApprovedBy = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setReqAprBy({
        value: selected.Emp_code,
        label: selected.Emp_name,
      },
      );
    }
  };

  function handleReadDeviationForm() {
    ReadRecord({
      _keyword_: "dev_form"
    }).then((res) => {
      setDevfrm(res.data);
    });
  }
  useEffect(() => {
    handleReadDeviationForm();
  }, [])
   useEffect(() => {
   console.log("Devfrm",Devfrm,);
   
  }, [Devfrm])
  return (
    <div>
      <div>
        <Popup
          trigger={ButtonPopup}
          setTrigger={setButtonPopup}
          viewPagination={viewPagination}
        >
          <br></br>
          <br></br>
          {Approver === localStorage.getItem("empId") ?

            (Approval === "0" ? back && <div><div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div><span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#DA251A', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>This Form has been Rejected due to {RejectedReason}</span></div> : Approval === "1" ? <div>{back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}<span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#B9A44C', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>It is Approved Already, Contact Administrator if any change needed </span></div> :
              back && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>
                <div style={{ display: 'flex', width: '25%', gap: '5', justifyContent: 'space-between', alignItems: 'center', marginRight: "10px" }}><button
                  className="icon"
                  style={{ backgroundColor: "#06802f", marginRight: '10px' }}
                  type="button"
                  onClick={(e) => {
                    approve(id, "DeviationForm", "dev_form")
                    navigate(-1)
                  }}
                >
                  Approve
                </button>
                  <button
                    className="icon"
                    style={{ backgroundColor: "#9d080f" }}
                    type="button"
                    onClick={() => { setcardId(id) }}
                  >
                    Reject
                  </button>
                  {id === cardId && <div><div style={{ backgroundColor: 'gray', filter: 'blur(8px)', position: 'absolute', opacity: '.8', right: '0%', bottom: '0%', width: '100%', height: '100%' }}></div><div style={{ position: 'absolute', right: '32%', color: 'white', bottom: '35%', padding: '20px', borderRadius: '10px' }}><h3>Remarks</h3><textarea rows={5} cols={50} onChange={e => { setremarks(e.target.value) }}></textarea><button onClick={e => { reject(id, "DeviationForm", remarks, "dev_form"); navigate(-1) }}>Submit</button><button onClick={() => { setcardId('') }}>Cancel</button></div></div>}
                </div>
              </div>) :
            (Approval === "0" ? <div>{back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}<span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#DA251A', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>This Form has been Rejected due to {RejectedReason}</span></div> : Approval === "1" ? <div>{back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}<span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#B9A44C', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>It is Approved Already, Contact Administrator if any change needed </span></div> : (back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>))
          }
          {/* {back&&<div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <div style={{fontSize:'16px',fontWeight:'bolder',backgroundColor:'#86BBd8', width:'90px',padding:'3px 10px',marginLeft:"20px",borderRadius:'10px',border:'2px solid #005C9F', cursor:'pointer'}} onClick={()=>{navigate(-1)}}><FaBackspace size={20}/> Back</div>
          <div style={{display:'flex',width:'25%',gap:'5',justifyContent:'space-between',alignItems:'center',marginRight:"10px"}}><button
                      className="icon"
                      style={{ backgroundColor: "#06802f" ,marginRight:'10px'}}
                      type="button"
                      onClick={(e) => {
                        approve(Id,"DeviationForm")
                        navigate(-1)
                      }}
                    >
                      Approve
                    </button>
                    <button
                      className="icon"
                      style={{ backgroundColor: "#9d080f" }}
                      type="button"
                      onClick={() => {setcardId(Id)}}
                    >
                      Reject
                    </button>
                    {Id===cardId&&<div><div style={{backgroundColor:'gray',filter:'blur(8px)',position:'absolute',opacity:'.8',right:'0%',bottom:'0%',width:'100%',height:'100%'}}></div><div style={{position:'absolute',right:'32%',color:'white',bottom:'35%',padding:'20px',borderRadius:'10px'}}><h3>Remarks For Record No - {ecnReport.Id}</h3><textarea rows={5} cols={50} onChange={e=>{setremarks(e.target.value)}}></textarea><button onClick={e=>{reject(ecnReport.Id,"EcnReport",remarks);navigate(-1)}}>Submit</button><button onClick={()=>{setcardId('')}}>Cancel</button></div></div>}
                    </div>
          </div>} */}
          <form id="DevFrm-formContainer-View" className="form-Deviation-View">
            <table style={{ textAlign: "center", width: "100%" }}>
              <tbody>
                <tr>
                  <th
                    //   id={`${id}-Title`}
                    //  name={`${name}-Title`}
                    style={{ textAlign: "center" }}
                    colSpan="5"
                  >
                    <font color="#8B0000">VIEW - DEVIATION FORM</font>
                  </th>
                </tr>
                <tr>
                  <td>Supplier: </td>
                  <td>
                    <label>UNITECH</label>
                  </td>
                  <td>Date</td>
                  <td>
                    <label>{formatDt(Date1)}</label>
                  </td>
                </tr>
                <tr>
                  <td>Model</td>
                  <td>
                    <label>{Model}</label>
                  </td>
                  <td colSpan="2" rowSpan={2}>
                    <div>
                      <span>Deviation Photo</span>
                      <img src={DevPic} style={{
                        display: "block",
                        margin: "auto",

                        width: "300px",
                        height: "250px",
                      }}
                      />

                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Part Name</td>
                  <td>
                    <label>{PartNme}</label>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" >
                    <span>Inventory Status Child Part</span>
                    <br></br>
                    <br></br><br></br>
                    <label>{InvStsCp}</label>

                  </td>
                  <td colSpan="1.5" >
                    <span>Inventory Status Finished Goods</span>
                    <br></br>
                    <br></br><br></br>
                    <label>{InvtStsFGoods}</label>

                  </td>
                  <td colSpan="1.5" >
                    <span>Expected stock at Customer</span>
                    <br></br>
                    <br></br><br></br>
                    <label>{StkAtCustomer}</label>

                  </td>
                </tr>
                <tr>
                  <td colSpan="4">
                    <span style={{ textAlign: "center" }}>
                      {" "}
                      Temporary Action:
                    </span>
                    <br></br>
                    <label>{TempAction}</label>
                  </td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <span style={{ textAlign: "center" }}> Deviation</span>
                    <br></br> <br></br> <br></br>
                    <label>{Dev}</label>
                  </td>
                  <td>
                    <span>Till Date</span>
                    <br></br> <br></br> <br></br>
                    <label>{formatDt(TillDate)}</label>
                  </td>
                  <td>
                    <span>Responsibility</span>
                    <br></br> <br></br> <br></br>
                    <label>{DResp}</label>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <span>Permanent Action</span>
                    <br></br> <br></br> <br></br>
                    <label>{PerAct}</label>
                  </td>
                  <td>
                    <span>Target date</span>
                    <br></br> <br></br> <br></br>
                    <label>{formatDt(TargetDate)}</label>
                  </td>
                  <td>
                    <span>Responsibility</span>
                    <br></br> <br></br> <br></br>
                    <label>{PerAResp}</label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Note:</span>
                    <br></br> <br></br> <br></br>
                    <label>{PerNotes}</label>
                  </td>
                  <td>
                    <span>Checked By</span>
                    <br></br> <br></br> <br></br>
                    <label>{PerAChecBy}</label>
                  </td>
                  <td>
                    <span>Received By</span>
                    <br></br> <br></br> <br></br>
                    <label>{PerARecBy}</label>
                  </td>
                  <td>
                    <span>Approved By</span>
                    <br></br> <br></br> <br></br>
                    <label>{PerAAprBy}</label>
                  </td>
                </tr>
                <tr>
                  <td colSpan="4">
                    <span>If any requirements / comments :</span>
                    <br></br> <br></br> <br></br>
                    <label>{ReqComm}</label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Note:</span>
                    <br></br> <br></br> <br></br>
                    <label>{ReqNotes}</label>
                  </td>
                  <td>
                    <span>Checked By</span>
                    <br></br> <br></br> <br></br>
                    <label>{ReqChecBy}</label>
                  </td>
                  <td>
                    <span>Received By</span>
                    <br></br> <br></br> <br></br>
                    <label>{ReqRecBy}</label>
                  </td>
                  <td>
                    <span>Approved By</span>
                    <br></br> <br></br> <br></br>
                    <label>{ReqAprBy}</label>
                  </td>
                </tr>
              </tbody>
            </table>



          </form>

        </Popup>
      </div>

      <div>
        <Popup
          trigger={ButtonPopup1}
          setTrigger={setButtonPopup1}
          viewPagination={viewPagination}
        >
          <form id="DevFrm-formContainer-Edit" className="form-Deviation-Edit">
          <table style={{ textAlign: "center", width: "100%",marginTop:"30px" }}>
         
              <tbody>
                <tr>
                  <th
                    //   id={`${id}-Title`}
                    //  name={`${name}-Title`}
                    style={{ textAlign: "center" }}
                    colSpan="5"
                  >
                    <font color="#8B0000">EDIT - DEVIATION FORM</font>
                  </th>
                </tr>
                <tr>
                  <td className="topic-head">Supplier: </td>
                  <td className="topic-head">UNITECH</td>
                  <td className="topic-head">DATE</td>
                  <td className="topic-head">
                    <input
                      type="date"
                      value={Date1}
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head">
                    Model<span style={{ color: "red" }}>*</span>
                  </td>
                  <td>
                    <input
                      type="text"
                      value={Model}
                      onChange={(e) => setModel(e.target.value)}
                      disabled
                    />
                  </td>
                  <td colSpan="2" rowSpan="3" className="topic-head">

                    <div>
                      {DevPic && (
                        <img
                          src={DevPic}
                          alt="Selected"
                          style={{
                            display: "block",
                            margin: "auto",

                            width: "300px",
                            height: "250px",
                          }}
                        />
                      )}
                      <span>Deviation Photo</span>
                      <br />
                      <input
                        type="file"
                        accept="image/*"
                        capture="camera"
                        onChange={(e) => handlePhotoChange(e)}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="topic-head">
                    Part Name<span style={{ color: "red" }}>*</span>
                  </td>
                  <td>
                    <input
                      type="text"
                      value={PartNme}
                      // onChange={(e) => setPartNme(e.target.value)}
                      disabled
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" className="topic-head">
                    <span style={{ textAlign: "center" }}>
                      Reason for deviation
                    </span>
                    <input
                      type="text"
                      value={RforDev}
                      onChange={(e) => setRforDev(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" className="topic-head">
                    Inventory Status Child Part
                  </td>
                  <td colSpan="1.5" className="topic-head">
                    Inventory Status Finished Goods
                  </td>
                  <td colSpan="1.5" className="topic-head">
                    Expected stock at Customer
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" className="topic-head">
                    <input
                      type="text"
                      value={InvStsCp}
                      onChange={(e) => setInvStsCp(e.target.value)}
                    />
                  </td>
                  <td colSpan="1.5" className="topic-head">
                    <input
                      type="text"
                      value={InvtStsFGoods}
                      onChange={(e) => setInvtStsFGoods(e.target.value)}
                    />
                  </td>
                  <td colSpan="1.5" className="topic-head">
                    <input
                      type="text"
                      value={StkAtCustomer}
                      onChange={(e) => setStkAtCustomer(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="4" className="topic-head">
                    <span style={{ textAlign: "center" }}>
                      {" "}
                      Temporary Action:
                    </span>
                    <input
                      type="text"
                      value={TempAction}
                      onChange={(e) => setTempAction(e.target.value)}
                    />
                  </td>
                </tr>

                <tr>
                  <td colSpan="2" className="topic-head">
                    <span style={{ textAlign: "center" }}> Deviation</span>
                    <br></br> <br></br> <br></br>
                    <input
                      type="text"
                      value={Dev}
                      onChange={(e) => setDev(e.target.value)}
                    />
                  </td>
                  <td className="topic-head">
                    <span>Till Date</span>
                    <br></br> <br></br> <br></br>
                    <DatePicker
                      value={TillDate}
                      onChange={handleDateChange1}
                    />
                  </td>
                  <td className="topic-head">
                    <span>Responsibility</span>
                    <br></br> <br></br> <br></br>

                    <Select
                      value={DResp}
                      onChange={handleDevResponsibility}
                      options={empDRespOptions}
                      placeholder="Select Receiver"
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" className="topic-head">
                    <span>Permanent Action</span>
                    <br></br> <br></br> <br></br>
                    <input
                      type="text"
                      value={PerAct}
                      onChange={(e) => setPerAct(e.target.value)}
                    />
                  </td>
                  <td className="topic-head">
                    <span >Target date</span>
                    <br></br> <br></br> <br></br>
                    <DatePicker
                      value={TargetDate}
                      onChange={handleDateChange2}
                    />
                  </td>
                  <td className="topic-head">
                    <span>Responsibility</span>
                    <br></br> <br></br> <br></br>

                    <Select
                      value={PerAResp}
                      onChange={handlePerActResponsibility}
                      options={empPerARespOptions}
                      placeholder="Select Receiver"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head">
                    <span>Note:</span>
                    <br></br> <br></br> <br></br>
                    <input
                      type="text"
                      value={PerNotes}
                      onChange={(e) => setPerNotes(e.target.value)}
                    />
                  </td>

                  <td className="topic-head">
                    <span>Checked By</span>
                    <br></br> <br></br> <br></br>
                    <DropdownFetcher
                      keyword="emp_details_dropdown_all"
                      setValue={setEmployeeDropdown}
                    />
                    <Select
                      value={PerAChecBy}
                      onChange={handleDevCheckedBy}
                      options={empPerAChecByOptions}
                      placeholder="Select Checker"
                    />
                    {/* <input
                  type="text"
                  value={PerAChecBy}
                  onChange={(e) => setPerAChecB(e.target.value)}
                /> */}
                  </td>
                  <td className="topic-head">
                    <span>Received By</span>
                    <br></br> <br></br> <br></br>
                    <DropdownFetcher
                      keyword="emp_details_dropdown_all"
                      setValue={setEmployeeDropdown}
                    />
                    <Select
                      value={PerARecBy}
                      onChange={handleDevReceivedBy}
                      options={empPerARecByOptions}
                      placeholder="Select Receiver"
                    />
                    {/* <input
                  type="text"
                  value={PerARecBy}
                  onChange={(e) => setPerARecB(e.target.value)}
                /> */}
                  </td>
                  <td className="topic-head">
                    <span>Approved By</span>
                    <br></br> <br></br> <br></br>
                    <DropdownFetcher
                      keyword="emp_details_dropdown_all"
                      setValue={setEmployeeDropdown}
                    />
                    <Select
                      value={PerAAprBy}
                      onChange={handleDevApprovedBy}
                      options={empPerAAprByOptions}
                      placeholder="Select Approver"
                    />
                    {/* <input
                  type="text"
                  value={PerAAprBy}
                  onChange={(e) => setPerAAprB(e.target.value)}
                /> */}
                  </td>
                </tr>
                <tr>
                  <td colSpan="4" className="topic-head">
                    <span>If any requirements / comments :</span>
                    <br></br> <br></br> <br></br>
                    <input
                      type="text"
                      value={ReqComm}
                      onChange={(e) => setReqComm(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="topic-head">
                    <span>Note:</span>
                    <br></br> <br></br> <br></br>
                    <input
                      type="text"
                      value={ReqNotes}
                      onChange={(e) => setReqNotes(e.target.value)}
                    />
                  </td>
                  <td className="topic-head">
                    <span>Checked By</span>
                    <br></br> <br></br> <br></br>
                    <DropdownFetcher
                      keyword="emp_details_dropdown_all"
                      setValue={setEmployeeDropdown}
                    />
                    <Select
                      value={ReqChecBy}
                      onChange={handlePerActCheckedBy}
                      options={empReqChecByOptions}
                      placeholder="Select Checker"
                    />
                    {/* <input
                  type="text"
                  value={ReqChecBy}
                  onChange={(e) => setReqChecBy(e.target.value)}
                /> */}
                  </td>
                  <td className="topic-head">
                    <span>Received By</span>
                    <br></br> <br></br> <br></br>
                    <DropdownFetcher
                      keyword="emp_details_dropdown_all"
                      setValue={setEmployeeDropdown}
                    />
                    <Select
                      value={ReqRecBy}
                      onChange={handlePerActReceivedBy}
                      options={empReqRecByOptions}
                      placeholder="Select Receiver"
                    />
                    {/* <input
                  type="text"
                  value={ReqRecBy}
                  onChange={(e) => setReqRecBy(e.target.value)}
                /> */}
                  </td>
                  <td className="topic-head">
                    <span>Approved By</span>
                    <br></br> <br></br> <br></br>
                    <DropdownFetcher
                      keyword="emp_details_dropdown_all"
                      setValue={setEmployeeDropdown}
                    />
                    <Select
                      value={ReqAprBy}
                      onChange={handlePerActApprovedBy}
                      options={empReqAprByOptions}
                      placeholder="Select Approver"
                    />
                    {/* <input
                  type="text"
                  value={ReqAprBy}
                  onChange={(e) => setReqAprBy(e.target.value)}
                /> */}
                  </td>
                </tr>
                <tr>
                  <td colSpan={7}>
                    <button
                      type="submit"
                      id="sub"
                      onClick={(e) => handleUpdate(e)}
                    >
                      Update
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </Popup>
      </div>

      <div>
        <Popup
          trigger={ButtonPopup2}
          setTrigger={setButtonPopup2}
          viewPagination={viewPagination}
        >
          <form className="form-container1">
            <table style={{ textAlign: "center" }}>
              <tbody>
                <tr>
                  <th>Id</th>
                  <td>
                    <input
                      type="text"
                      value={Id}
                      onChange={(e) => setId(e.target.value)}
                      disabled
                    />
                  </td>
                </tr>
                <tr>
                  <th>Model</th>
                  <td>
                    <input
                      type="text"
                      value={Model}
                      onChange={(e) => setModel(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Part Name</th>
                  <td>
                    <input
                      type="text"
                      value={PartNme}
                    // onChange={(e) => setPartNme(e.target.value)}
                    />
                  </td>
                </tr>

                <tr>
                  <th>Active Status</th>
                  <td>
                    <select
                      value={ActS}
                      onChange={(e) => setActS(e.target.value)}
                    >
                      <option value="YES">Active</option>
                      <option value="NO">INActive</option>
                    </select>
                  </td>
                </tr>
                <br></br>
                <tr>
                  <td colSpan="2">
                    <button
                      style={{ border: "none" }}
                      type="submit"
                      id="sub"
                      onClick={(e) => handleChange(e)}
                    >
                      Submit
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </Popup>
      </div>

      <div>
        <main>
          <table style={{ height: "50%", width: "100%" }}>
            <thead>
              <tr>
                <th
                  id="mlcss-Title.List"
                  name="mlcss-Title.List"
                  colSpan="12"
                  style={{ textAlign: "center", whiteSpace: "nowrap" }}
                >
                  <font color="#8B0000">List - DeviationForm</font>
                </th>
              </tr>
              <tr>
                <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                  Date
                </td>
                <td colSpan="1">
                  <input
                    id="MacBD-filter-Date"
                    name="MacBD-filter-Date"
                    type="date"
                    placeholder="Filter by Date"
                    value={filterDate}
                    onChange={handleFilterDateChange}
                  />
                </td>
                <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                  Part Name
                </td>
                <td >
                  <input
                    id="MacBD-filter-PartName"
                    name="MacBD-filter-PartName"
                    type="text"
                    placeholder="Filter Part Name"
                    style={{ fontSize: "70%", width: "100%" }}
                    value={filterPart}
                    onChange={handlefilterPartChange}
                  />
                </td>
                <td colSpan="8"></td>
              </tr>
              <tr>
                <th style={{ textAlign: "center" }}>Date</th>
                <th style={{ textAlign: "center" }}>Model</th>

                <th style={{ textAlign: "center" }}>Part Name</th>
                <th style={{ textAlign: "center" }}>Reason For Dev</th>
                <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                  Status
                </th>
                {/* <th style={{ textAlign: "center" }}>Created Date</th> */}
                <th className="table-list-head"></th>
                <th className="table-list-head"></th>
                <th className="table-list-head"></th>
              </tr>
            </thead>
            <tbody>
              {/* <HandleRead keyword="dev_form" setData={setDevfrm} /> */}
              {filteredRecords.length === 0 ? (
                // <tr>
                <td colSpan="12" style={{ textAlign: "center" }}>
                  <p
                    id="Dev-list-NoRec"
                    name="Dev-list-noRec"
                    style={{ fontWeight: "bold" }}
                  >
                    No records found.
                  </p>
                </td>
              ) : (
                // </tr>
                filteredRecords.map((Devfrm) => (
                  <tr className="table_view" key={Devfrm.Id}>
                    <td className="table-cell">{formatDt(Devfrm.Date)}</td>
                    <td className="table-cell">{Devfrm.Model}</td>
                    <td className="table-cell">{Devfrm.Part_name}</td>
                    <td className="table-cell">{Devfrm.Rea_for_dev}</td>
                    {/* <td className="table-cell">{Devfrm.CreatedDate}</td> */}

                    {/* <td className="table-cell">
                 {new Date(Devfrm.CreatedDate).toLocaleDateString('en-GB')}
                    </td> */}
                    <td
                      style={{

                        color: Devfrm.Approval_status === "0" ? 'red' : Devfrm.Approval_status === "1" ? 'green' : Devfrm.Approval_status === undefined ? '#E59500' : "",

                      }}
                      className="table-cell"
                    >
                      &nbsp;&nbsp;{Devfrm.Approval_status === undefined ? "Waiting for approval..." : Devfrm.Approval_status === "1" ? "Approved" : Devfrm.Approval_status === "0" ? "Rejected" : ""}
                    </td>
                    <td>
                      <button
                        style={{ backgroundColor: "#cba423" }}
                        type="button"
                        onClick={() => {
                          handleView(Devfrm)
                          //   handleEdit2(Devfrm);
                          //  console.log("clicked")
                        }}
                      >
                        View&#x1F441;
                      </button>
                    </td>
                    <td>
                      <button type="button"
                        onClick={() => handleEdit(Devfrm)}>
                        Edit&#x270E;
                      </button>
                    </td>
                    <td>
                      <button
                        id={`dev_form-Delete`}
                        name={`dev_form-Delete`}
                        style={{ whiteSpace: "nowrap", backgroundColor: "#DA251A" }}
                        type="button"
                        onClick={() => {
                          const userConfirmed = window.confirm('Are you sure you want to delete?');
                          if (userConfirmed) {
                            UpdateRecord({
                              _keyword_: 'dev_form',
                              data: [
                                {
                                  Id: Devfrm.Id,
                                  ActiveStatus: 'InActive',
                                },
                              ],
                            }).then(() => {
                              handleReadDeviationForm();
                            })
                            // window.location.reload();
                          }
                        }}
                      >
                        Delete<MdDelete />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <div className="pagination" id="pagination">
            {isPaginationVisible && filteredData.length > 0 && (
              <Pagination
                currentPage={currentPage}
                npage={npage}
                prePage={prePage}
                nextPage={nextPage}
                changeCpage={changeCpage}
                numbers={numbers}
              />
            )}
          </div>
        </main>
      </div>
    </div>
  );
}
export default DeviationFormView;
