import React, { useEffect, useState } from "react";
import FieldPaint from "../../../../Components/Fields/FieldPaint.js";
import Lable from "../../../../Components/Fields/Lable.js";
import { useNavigate, useParams } from "react-router-dom";
import LoadingPage from "../../../../Components/Handler/LoadingPage.js";
import * as GeneralConstants from "../../../../Utils/GeneralConstants.js";
import IsDuplicatePaint from "../../../../Lib/IsDuplicatePaint.js";
import QaLayoutPaint from "../../QaLayoutPaint.js";
import CreateRecordPaint from "../../../../Lib/API/CreateRecordPaint.js";
import ReadRecordPaint from "../../../../Lib/API/ReadRecordPaint.js";
import UpdateRecordPaint from "../../../../Lib/API/UpdateRecordPaint.js";

const initState = [
  {
    Batch_no: "",
    Ps: "",
    Model: "",
    Part_name: "",
    Supplier: "",
    Category: "",
    Customer: "",
    Paint_name: "",
    Hardner_name: "",
    Thinner_name: "",
    Vis_set_plus: "",
    Vis_set_minus: "",
    Mixing_ratio: "",
    ActiveStatus: "Active",
    CusName: [{}],
    SupName: [{}],
    PartName: [{}],
    PaintName: [{}],
    HardnerName: [{}],
    ThinnerName: [{}],
  },
];
function PaintMasterHead() {
  const [Create, setCreate] = useState(initState);
  const [warning, setwarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const { option, id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    console.log("Create", Create);
  });
  useEffect(() => {
    setwarning(false);
    document.title = "Paint Master Head";
    const fetchData = async () => {
      if (id === undefined) {
        setCreate(initState);
      } else {
        setLoading(true);
        try {
          const res = await ReadRecordPaint({
            _keyword_: "paint_master_head",
            Id: id,
          });
          console.log("res", res.data[0]);
          setCreate([res.data[0]]);
          console.log("Res2", Create[0]);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, []);
  if (loading) {
    return <LoadingPage />;
  }

  function HandleRecordSelect(Event, column, value, label) {
    setCreate(() => {
      let record = [...Create];
      record[0][Event.name] = Event.values.value;
      record[0][column][0][value] = Event.values.value;
      record[0][column][0][label] = Event.values.label;
      return record;
    });
  }
  function HandleRecords(Event) {
    setCreate([{ ...Create[0], [Event.target.name]: Event.target.value }]);
  }

  const HandleCreate = async () => {
    const IsDuplicatePaintName = await IsDuplicatePaint("paint_master_head", [
      `Batch_no="${Create[0].Batch_no}"`,
    ]);
    const IsDuplicatePaintCombination = await IsDuplicatePaint("paint_master_head", [
      `Ps= "${Create[0].Ps}"`,
      `Customer= "${Create[0].Customer}"`,
      `Model= "${Create[0].Model}"`,
      `Part_Name= "${Create[0].Part_name}"`,
      `Supplier= "${Create[0].Supplier}"`,
      `Category= "${Create[0].Category}"`,
      `Paint_name= "${Create[0].Paint_name}"`,
      `Hardner_name= "${Create[0].Hardner_name}"`,
      `Thinner_name= "${Create[0].Thinner_name}"`,
      `Mixing_ratio= "${Create[0].Mixing_ratio}"`,
    ]);

    if (IsDuplicatePaintCombination) {
      alert(
        `Combination of Ps,Part, Program, Paint, and Customer already exists.`
      );
      return;
    } else if (IsDuplicatePaintName) {
      alert(`BatchNo is already exists.`);
      return;
    }

    if (
      !Create[0].Ps ||
      !Create[0].Category ||
      !Create[0].Part_name ||
      !Create[0].Batch_no ||
      !Create[0].Model ||
      !Create[0].Supplier ||
      !Create[0].Customer ||
      !Create[0].Paint_name ||
      !Create[0].Hardner_name ||
      !Create[0].Thinner_name ||
      !Create[0].Vis_set_minus ||
      !Create[0].Vis_set_plus ||
      !Create[0].ActiveStatus
    ) {
      alert("Please Fill the Mandatory FieldPaints");
      setwarning(true);
      return;
    } else {
      try {
        let record = [...Create];

        record && delete record[0].CusName;
        record && delete record[0].PartName;
        record && delete record[0].SupName;
        record && delete record[0].PaintName;
        record && delete record[0].HardnerName;
        record && delete record[0].ThinnerName;
        // Create && delete Create.CusName;

        console.log("After Delete Record:", record);

        CreateRecordPaint("paint_master_head", record).then((res) => {
          console.log("HandleCreate", res);
          alert(GeneralConstants.SUBMISSION_SUCCESSFUL);

          setCreate([
            {
              Batch_no: "",
              Ps: "",
              Model: "",
              Part_name: "",
              Supplier: "",
              Category: "",
              Customer: "",
              Paint_name: "",
              Hardner_name: "",
              Thinner_name: "",
              Vis_set_plus: "",
              Vis_set_minus: "",
              Mixing_ratio: "",
              ActiveStatus: "Active",
              CusName: [{}],
              SupName: [{}],
              PartName: [{}],
              PaintName: [{}],
              HardnerName: [{}],
              ThinnerName: [{}],
            },
          ]);
        });
        setwarning(false);
      } catch (error) {
        console.log(error);
        setCreate([
          {
            Batch_no: "",
            Ps: "",
            Model: "",
            Part_name: "",
            Supplier: "",
            Category: "",
            Customer: "",
            Paint_name: "",
            Hardner_name: "",
            Thinner_name: "",
            Vis_set_plus: "",
            Vis_set_minus: "",
            Mixing_ratio: "",
            ActiveStatus: "Active",
            CusName: [{}],
            SupName: [{}],
            PartName: [{}],
            PaintName: [{}],
            HardnerName: [{}],
            ThinnerName: [{}],
          },
        ]);
        alert(GeneralConstants.SUBMISSION_FAILED);
      }
    }
  };
  function HandleUpdate() {
    try {
      if (
        !Create[0].Ps ||
        !Create[0].Category ||
        !Create[0].Part_name ||
        !Create[0].Batch_no ||
        !Create[0].Model ||
        !Create[0].Supplier ||
        !Create[0].Customer ||
        !Create[0].Paint_name ||
        !Create[0].Hardner_name ||
        !Create[0].Thinner_name ||
        !Create[0].Vis_set_minus ||
        !Create[0].Vis_set_plus ||
        !Create[0].ActiveStatus
      ) {
        alert("Please Fill the Mandatory FieldPaints");
        setwarning(true);
        return;
      }

      let record = [...Create];
      record && delete record[0].CusName;
      record && delete record[0].PartName;
      record && delete record[0].SupName;
      record && delete record[0].PaintName;
      record && delete record[0].HardnerName;
      record && delete record[0].ThinnerName;
      console.log("up", record);
      UpdateRecordPaint("paint_master_head", record).then((res) => {
        console.log("HandleUpdate", res);
      });

      alert(GeneralConstants.UPDATE_SUCCESSFUL);
      setwarning(false);

      setCreate(initState);
    } catch (error) {
      console.error(error);
      alert(GeneralConstants.UPDATE_FAILURE);
      setwarning(false);
    }
    navigate(-1);
  }
  function InvalidCharacters(value) {
    return /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/.test(value);
  }
  function InvalidCharacters1(value) {
    return /[a-zA-Z!@#$%^&*()_\+={[}\]|\\;"'<,>?/~`]/.test(value);
  }
  return (
  <QaLayoutPaint>
      <div>
        <table>
          <tbody>
            <tr>
              <th colSpan={8}>
                <Lable value={"Paint Master Head"} type={"Head"} />
              </th>
            </tr>
            <tr>
              <td>
                <FieldPaint
                  label={"Batch No"}
                  name={"Batch_no"}
                  options={{
                    type: "number",
                    required: true,
                    disabled: option === "view" || option === "edit",
                  }}
                  warning={warning}
                  value={Create[0]}
                  OnChange={(e) => HandleRecords(e)}
                />
              </td>
              <td>
                <FieldPaint
                  label={"PS"}
                  name={"Ps"}
                  options={{
                    type: "dropdown",
                    required: true,
                    disabled: option === "view",
                    values: [
                      { value: "", label: "Select" },
                      { value: "s-1", label: "S-1" },
                      { value: "s-2", label: "S-2" },
                      { value: "s-3", label: "S-3" },
                      { value: "ps-1", label: "PS-1" },
                      { value: "ps-2", label: "PS-2" },
                      { value: "ps-3", label: "PS-3" },
                    ],
                  }}
                  warning={warning}
                  value={Create[0]}
                  OnChange={(e) => HandleRecords(e)}
                />
              </td>
              <td>
                <FieldPaint
                  label={"Customer"}
                  name={"Customer"}
                  options={{
                    type: "reference",
                    reference: "Get_paint_customer_name",
                    required: true,
                    disabled: option === "view",
                  }}
                  warning={warning}
                  value={{
                    value: Create?.[0]?.CusName?.[0]
                      ? Create[0].CusName[0].Customer_code
                      : "",
                    label: Create?.[0]?.CusName?.[0]
                      ? Create[0].CusName[0].Customer_name
                      : "",
                  }}
                  OnChange={(e) => {
                    HandleRecordSelect(
                      e,
                      "CusName",
                      "Customer_code",
                      "Customer_name"
                    );
                  }}
                />
              </td>
              <td>
                <FieldPaint
                  label={"Model"}
                  name={"Model"}
                  options={{
                    type: "text",
                    required: true,
                    disabled: option === "view" || option === "edit",
                  }}
                  warning={warning}
                  value={Create[0]}
                  OnChange={(e) => HandleRecords(e)}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FieldPaint
                  label={"PartName"}
                  name={"Part_name"}
                  options={{
                    type: "reference",
                    reference: "Get_paint_part_name",
                    required: true,
                    disabled: option === "view",
                  }}
                  warning={warning}
                  value={{
                    value: Create?.[0]?.PartName?.[0]
                      ? Create[0].PartName[0].Part_code
                      : "",
                    label: Create?.[0]?.PartName?.[0]
                      ? Create[0].PartName[0].Part_name
                      : "",
                  }}
                  OnChange={(e) => {
                    HandleRecordSelect(e, "PartName", "Part_code", "Part_name");
                  }}
                />
              </td>
              <td>
                <FieldPaint
                  label={"Supplier"}
                  name={"Supplier"}
                  options={{
                    type: "reference",
                    reference: "Get_paint_supplier_name",
                    required: true,
                    disabled: option === "view",
                  }}
                  warning={warning}
                  value={{
                    value: Create?.[0]?.SupName?.[0]
                      ? Create[0].SupName[0].Supplier_code
                      : "",
                    label: Create?.[0]?.SupName?.[0]
                      ? Create[0].SupName[0].Supplier_name
                      : "",
                  }}
                  OnChange={(e) => {
                    HandleRecordSelect(
                      e,
                      "SupName",
                      "Supplier_code",
                      "Supplier_name"
                    );
                  }}
                />
              </td>
              <td>
                <FieldPaint
                  label={"Category"}
                  name={"Category"}
                  options={{
                    type: "dropdown",
                    required: true,
                    disabled: option === "view",
                    values: [
                      { value: "", label: "Select" },
                      { value: "base", label: "BASE" },
                      { value: "primer", label: "PRIMER" },
                      { value: "clear", label: "CLEAR" },
                      { value: "base-1", label: "BASE 1" },
                      { value: "base-2", label: "BASE 2" },
                      { value: "base(UFX)", label: "BASE(UFX)" },
                      { value: "Base(RET)", label: "BASE(RET)" },
                    ],
                  }}
                  warning={warning}
                  value={Create[0]}
                  OnChange={(e) => HandleRecords(e)}
                />
              </td>
              <td>
                <FieldPaint
                  label={"Paint"}
                  name={"Paint_name"}
                  options={{
                    type: "reference",
                    reference: "Get_paint_name",
                    required: true,
                    disabled: option === "view",
                  }}
                  warning={warning}
                  value={{
                    value: Create?.[0]?.PaintName?.[0]
                      ? Create[0].PaintName[0].Paint_code
                      : "",
                    label: Create?.[0]?.PaintName?.[0]
                      ? Create[0].PaintName[0].Paint_name
                      : "",
                  }}
                  OnChange={(e) => {
                    HandleRecordSelect(
                      e,
                      "PaintName",
                      "Paint_code",
                      "Paint_name"
                    );
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <FieldPaint
                  label={"Hardner"}
                  name={"Hardner_name"}
                  options={{
                    type: "reference",
                    reference: "Get_paint_hardner_name",
                    required: true,
                    disabled: option === "view",
                  }}
                  warning={warning}
                  value={{
                    value: Create?.[0]?.HardnerName?.[0]
                      ? Create[0].HardnerName[0].Hardner_code
                      : "",
                    label: Create?.[0]?.HardnerName?.[0]
                      ? Create[0].HardnerName[0].Hardner_name
                      : "",
                  }}
                  OnChange={(e) => {
                    HandleRecordSelect(
                      e,
                      "HardnerName",
                      "Hardner_code",
                      "Hardner_name"
                    );
                  }}
                />
              </td>
              <td>
                <FieldPaint
                  label={"Thinner"}
                  name={"Thinner_name"}
                  options={{
                    type: "reference",
                    reference: "Get_paint_thinner_name",
                    required: true,
                    disabled: option === "view",
                  }}
                  warning={warning}
                  value={{
                    value: Create?.[0]?.ThinnerName?.[0]
                      ? Create[0].ThinnerName[0].Thinner_code
                      : "",
                    label: Create?.[0]?.ThinnerName?.[0]
                      ? Create[0].ThinnerName[0].Thinner_name
                      : "",
                  }}
                  OnChange={(e) => {
                    HandleRecordSelect(
                      e,
                      "ThinnerName",
                      "Thinner_code",
                      "Thinner_name"
                    );
                  }}
                />
              </td>
              <td colSpan={2}>
                <FieldPaint
                  label={"Mixing Ratio(%)"}
                  name={"Mixing_ratio"}
                  options={{
                    type: "text",
                    required: true,
                    disabled: option === "view" || option === "edit",
                  }}
                  warning={warning}
                  value={Create[0]}
                  OnChange={(e) => {
                    const value = e.target.value;
                    if (InvalidCharacters1(value)) {
                      e.preventDefault();
                      return;
                    }
                    HandleRecords(e);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                {" "}
                <div>
                  <Lable value={"Viscosity (Sec)"} />
                </div>
              </td>
              <td>
                <div style={{ display: "flex", flexWrap: "nowrap" }}>
                  <span className="MinusPlus">(-</span>
                  <div>
                    <FieldPaint
                      className={"FieldPaintSingleGrid"}
                      label={""}
                      name={"Vis_set_minus"}
                      options={{
                        type: "text",
                        disabled: option == "view",
                        required: true,
                        pattern: "[0-9]*",
                      }}
                      warning={warning}
                      value={Create[0]}
                      OnChange={(e) => {
                        const value = e.target.value;
                        if (InvalidCharacters(value)) {
                          e.preventDefault();
                          return;
                        }
                        HandleRecords(e);
                      }}
                    />
                    {/* <input className="SmpMaininput" type="text" name={"Part_weight_set_minus_1"} value={Records[0].Part_weight_set_minus_1} onChange={(e) => { HandleRecords(e) }} ></input> */}
                  </div>
                  <span className="MinusPlus">/+</span>
                  <div>
                    <FieldPaint
                      className={"FieldPaintSingleGrid"}
                      label={""}
                      name={"Vis_set_plus"}
                      options={{
                        type: "text",
                        disabled: option == "view",
                        required: true,
                      }}
                      warning={warning}
                      value={Create[0]}
                      OnChange={(e) => {
                        const value = e.target.value;
                        if (InvalidCharacters(value)) {
                          e.preventDefault();
                          return;
                        }
                        HandleRecords(e);
                      }}
                    />
                    {/* <input className="SmpMaininput" type="text" name={"Part_weight_set_plus_1"} value={Records[0].Part_weight_set_plus_1} onChange={(e) => { HandleRecords(e) }}></input> */}
                  </div>
                  <span className="MinusPlus">)</span>
                </div>
              </td>
              <td colSpan={2}>
                <FieldPaint
                  label={"Active Status"}
                  name={"ActiveStatus"}
                  options={{
                    type: "dropdown",
                    required: true,
                    disabled: option === "view",
                    values: [
                      { value: "", label: "--Select--" },
                      { value: "Active", label: "Active" },
                      { value: "InActive", label: "InActive" },
                    ],
                  }}
                  warning={warning}
                  value={Create[0]}
                  OnChange={(e) => HandleRecords(e)}
                />
              </td>
            </tr>
            {!(option === "view") && (
              <tr>
                {id === undefined ? (
                  <td colSpan={19}>
                    {" "}
                    <FieldPaint
                      label={"Submit"}
                      options={{ type: "Button" }}
                      onClick={(e) => {
                        HandleCreate(e);
                      }}
                    />
                  </td>
                ) : (
                  <td colSpan={19}>
                    {" "}
                    <FieldPaint
                      label={"Update"}
                      options={{ type: "Button" }}
                      onClick={(e) => {
                        HandleUpdate(e);
                      }}
                    />
                  </td>
                )}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </QaLayoutPaint>
  );
}

export default PaintMasterHead;
