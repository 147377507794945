import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PreViewSmpPage from "./PreViewSmpPage";
import ReadRecord from "../../../Lib/API/ReadRecord";
import { CURRENT_Date } from "../../../Utils/GeneralConstants";

const NewSMpViewPage = () => {
    const InitialIs = { Injection_stages: "STAGES", Injection_pressure: "3", Injection_speed: "5", Injection_position: "5", Injection_time: "0.5" }
    const InitialHold = { Holding_stages: "", Holding_pressure: "3", Holding_speed: "5", Holding_position: "5", Holding_time: "0.5" }
    const IntialTempNozzle = { Temperature_stages: "Nozzle", Temp_set: "10", Temp_value: "" }
    const IntialTempHopper = { Temperature_stages: "Hopper", Temp_set: "10", Temp_value: "" }
    const IntialTempZone = { Temperature_stages: "Zone 1", Temp_set: "5", Temp_value: "" }
    const InitRoot = () => [
        {
            download: false,
            Preparedby: [{}],
            Approvedby: [{}],
            Partname: [{}],
            PartNumber: [{}],
            Part_name: "",
            injection_functional: [InitialIs, { Injection_stages: "Injection 1" }, { Injection_stages: "Fill Time(Actual)" }, { Injection_stages: "Switch Over / Transfer Position(MM)⟡" }],
            refilsetting_functional: [{ Refilsetting_stages: "STAGES", Refilsetting_stage_set: "", Refilsetting_pressure: "3", Refilsetting_speed: "5", Refilsetting_position: "5", Refilsetting_time: "0.5" }, { Refilsetting_stages: "Refill 1" }, { Refilsetting_stages: "Refill End/Short Size(MM)*:" }, { Refilsetting_stages: "Suck Back(MM)⟡" }],
            holding_functional: [InitialHold, { Holding_stages: "Hold On 1" }],
            temperature_function: [IntialTempNozzle, IntialTempHopper, IntialTempZone],
            hotruntemp_function: [{ Mani_fold_label: "HOT RUNNER TEMPERATURE", Mani_fold_set: "", Mani_fold_celsius: "CELSIUS °C", Hot_runner_gate: "HOT RUNNER TEMPERATURE", Hot_runner_gate_celsius: "" }, { Mani_fold_label: "ManiFold 1", Hot_runner_gate: "Gate 1" }],
            seqemacegate_function: [{ Seqemace_gate: "Gate", Seqemace_gate_on: "", Seqemace_gate_off: "" }, { Seqemace_gate: "Gate 1" }],
            mouldtempdetails_function: [{ Mould_temp_details: "Core", Mould_temp_set: "5", Mould_temp_value: "" }, { Mould_temp_details: "Cavity", Mould_temp_set: "5", Mould_temp_value: "" }, { Mould_temp_details: "MTC Temp", Mould_temp_set: "3", Mould_temp_value: "" }],
            injectionunitsettings_function: [{ Inj_unit_stage: "", Inj_unit_speed: "SPEED%", Inj_unit_time: "", Inj_unit_sec: "SEC", Inj_unit_break_mode: "SPRUE BREAK MODE⟡", Inj_unit_value: "" }, { Inj_unit_stage: "INJ UNIT⟡", Inj_unit_speed: "ON", Inj_unit_time: "Sprue Break⟡", Inj_unit_sec: "OFF", Inj_unit_break_mode: "0 INJ", Inj_unit_value: "" }, { Inj_unit_stage: "FWD SPD(MM/S)⟡", Inj_unit_speed: "", Inj_unit_time: "Delay Time", Inj_unit_sec: "", Inj_unit_break_mode: "1.Front Suck Back", Inj_unit_value: "3.Rear Suck Back" }, { Inj_unit_stage: "RET SPD(MM/S)⟡", Inj_unit_speed: "", Inj_unit_time: "On Time", Inj_unit_sec: "", Inj_unit_break_mode: "2.Refill", Inj_unit_value: "4.Cooling" }, { Inj_unit_stage: "Sprue Bush Hole(MM)⟡", Inj_unit_time: "", Inj_unit_break_mode: "Nozzle Hole(MM) ⟡", Inj_unit_value: "" }],
            mouldclosesettings_function: [{ Mould_close_stages: "STAGES⟡", Mould_close_pressure: "3", Mould_close_speed: "5", Mould_close_position: "5" }, { Mould_close_stages: "Close 1" }, { Mould_close_stages: "Close 2" }, { Mould_close_stages: "Close 3" }, { Mould_close_stages: "Mould safety/Protect⟡ 1" }, { Mould_close_stages: "Mould safety/Protect⟡ 2" }, { Mould_close_stages: "Mould Safety Time(SEC)⟡:", Mould_close_speed: "Actual:" }],
            mouldopensettings_function: [{ Mould_open_stages: "STAGES⟡", Mould_open_pressure: "3", Mould_open_speed: "5", Mould_open_position: "5" }, { Mould_open_stages: "Open 1 ⟡" }, { Mould_open_stages: "Open 2 ⟡" }, { Mould_open_stages: "Open 3 ⟡" }],
            ejectionsettings_function: [{ Ejection_settings_stages: "STAGES⟡", Ejection_settings_pressure: "2", Ejection_settings_speed: "10", Ejection_settings_time: "0.5", Ejection_settings_position: "5" }, { Ejection_settings_stages: "EJEC FRD 1" }, { Ejection_settings_stages: "EJEC FRD 2" }, { Ejection_settings_stages: "EJEC RET 1" }, { Ejection_settings_stages: "EJEC RET 2" }],
            coresettings_function: [{ Core_settings_stages: "STAGES⟡", Core_settings_pressure: "2", Core_settings_speed: "10", Core_settings_time: "0.5", Core_settings_position: "5" }, { Core_settings_stages: "CORE 1 IN" }, { Core_settings_stages: "CORE 1 OUT" }, { Core_settings_stages: "CORE 2 IN" }, { Core_settings_stages: "CORE 2 OUT" }],
            backpres_function: [{ Back_press_stages: "STAGES⟡", Back_press_pressure: "3", Back_press_speed: "5", Back_press_position: "5", Back_press_time: "0.5" }, { Back_press_stages: "Back Pres⟡ 1" }, { Back_press_stages: "Back Pres⟡ 2" }, { Back_press_stages: "Melt Cushion(MM)*", Back_press_set: "0.5" }, { Back_press_stages: "Cooling Time(SEC)*", Back_press_set: "1" }, { Back_press_stages: "Cycle Time(SEC)⟡", Back_press_set: "3" }, { Back_press_stages: "Oil Temp°C⟡", Back_press_set: "10" }],
            Part_number: "",
            Rawmaterialname: [{}],
            Raw_material_name: "",
            Prepared_by: "",
            Approved_by: "",
            Model: "",
            Machine_no: "",
            MachineNo: [{}],
            Grade: "",
            Colour: "",
            Model: "",
            Runner_weight: "",
            Part_weight_1: "",
            Part_weight_2: "",
            No_of_cavity: "",
            Entry_date: CURRENT_Date,
            Part_weight_set_plus_1: "",
            Part_weight_set_plus_2: "",
            Part_weight_set_minus_1: "",
            Part_weight_set_minus_2: ""
        }
    ]
    const navigate = useNavigate();
    const [Records, setRecords] = useState(InitRoot);
    const [Preview, setPreView] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        console.log("Records", Records);
    }, [Records]);

    useEffect(() => {
        console.log("id",id);
        document.title = 'SMP';
        const fetchData = async () => {
            if (id === undefined) {
                setRecords({});
            } else {
                try {
                    const res = await ReadRecord({
                        _keyword_: 'production_smp_parent',
                        Id: id,
                    });

                    if (res.data[0] === undefined) {
                        setRecords(InitRoot);
                    } else {
                        setRecords(res.data);
                        setPreView(true);
                    }
                } catch(error) {
                    console.log(error);
                }
            }
        };

        fetchData();
    }, [id]);

    useEffect(() => {
        if (Preview === false) {
            navigate(-1);
        }
    }, [Preview, navigate]);

    return Preview && <PreViewSmpPage Records={Records} setState={setPreView}/>;
}

export default NewSMpViewPage;
