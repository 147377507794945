import React,{useState} from "react";
import { Tab, Tabs } from "react-bootstrap";
import MachinePartMasterCreate from "./MachinePartMasterCreate";
import MachinePartMasterView from "./MachinePartMasterView";

function MachinePartMasterTab() {
  const [viewTabClicked, setViewTabClicked] = useState(false);
  const [activeKey, setActiveKey] = useState("tab1");

  const handleTabClick = (tabKey) => {
    setActiveKey(tabKey);
    if (tabKey === "tab2") {
      setViewTabClicked(true);
    } else {
      setViewTabClicked(false);
    }
  };
  return (
    <div>
      <div>
        <Tabs
          defaultActiveKey="tab1"
          // transition={false}
          id="noanim-tab-example"
          className="mb-3"
          onSelect={handleTabClick}
        >
          <Tab name="Mc.part.CreateTab" eventKey="tab1" title="Create">
            <div>
              <div className="set-layout">
                <MachinePartMasterCreate tab1={activeKey === "tab1"}/>
              </div>
            </div>
          </Tab>
          <Tab name="Mc.part.ViewTab" eventKey="tab2" title="View">
            <div>
              <div className="set-layout">
              {viewTabClicked &&<MachinePartMasterView />}
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default MachinePartMasterTab;
