import React, { useEffect, useState } from "react";
import ReadRecord from "../../Lib/API/ReadRecord";
import Field from "../../Components/Fields/Field";
import "./Approval.css";
import { useNavigate } from "react-router-dom";

const Approvals = () => {
  const [Tables, setTables] = useState([{}]);
  const [Select, setSelect] = useState({ DropdownData: "All" });
  const [Type, setType] = useState({ type: "Waiting for Approval" });
  const [Selector, setSelector] = useState({ Selector: "All" });
  const [Records, setRecords] = useState([{}]);
  const navigate = useNavigate();

  useEffect(() => {
    GetTableInfo();
    GetRecords("%","Waiting for Approval");
  }, []);

  let count = 0;

  function GetTableInfo() {
    ReadRecord(
      "SELECT count(Status) count,TableName FROM approval where Status='Waiting for Approval' and Approver='"+localStorage.getItem("empId")+"' group by Status,TableName"
    ).then((res) => {
      res.data[0].rows.map((item) => {
        count += Number(item.count);
      });
      res.data[0].rows.push({ TableName: "All", count: count });
      let data = res.data[0].rows.map((item) => ({
        value: `${item.TableName}`,
        label: `${item.TableName} - (${item.count})`,
      }));
      console.log(data);
      setTables(data);
    });
  }
  function GetapprovalRecords(type) {
    ReadRecord(
      "SELECT count(Status) count,TableName FROM approval where Status='"+type+"' and Approver='"+localStorage.getItem("empId")+"' group by Status,TableName"
    ).then((res) => {
      res.data[0].rows.map((item) => {
        count += Number(item.count);
      });
      res.data[0].rows.push({ TableName: "All", count: count });
      let data = res.data[0].rows.map((item) => ({
        value: `${item.TableName}`,
        label: `${item.TableName} - (${item.count})`,
      }));
      console.log(data);
      setTables(data);
    });
  }

  function GetRecords(TableName,status){
    ReadRecord("SELECT * FROM approval where TableName like '"+(TableName==="All"?"%":TableName)+"' and Status like '"+status+"' and Approver='"+localStorage.getItem("empId")+"'")
    .then(res=>{
        console.log(res.data[0].rows);
        setRecords(res.data[0].rows)
    })
  }

  return (
    <div className="ApprovalScreen">
      <div className="row">
        <h2 style={{ paddingLeft: "10px" }}>Approvals</h2>
      </div>
      <div className="row">
        {Type.type==="Waiting for Approval"?<Field
          name={"DropdownData"}
          options={{ type: "dropdown", values: Tables }}
          value={Select}
          OnChange={(e) => {
            setSelect({ DropdownData: e.target.value });
            GetRecords(e.target.value,Type.type)
            GetTableInfo()
          }}
        />:
        <Field
          name={"Selector"}
          options={{ type: "dropdown", values: Tables }}
          value={Selector}
          OnChange={(e) => {
            setSelector({ Selector: e.target.value });
            GetRecords(e.target.value,Type.type)
            GetapprovalRecords(Type.type);
          }}
        />}
        <Field
          name={"type"}
          options={{ type: "dropdown", values: [
            {value:"Waiting for Approval",label:"Waiting For Approval"},
            {value:"Approved",label:"Approved"},
            {value:"Rejected",label:"Rejected"},
        ] }}
          value={Select}
          OnChange={(e) => {
            setSelector({ Selector: "All" })
            setType({ type: e.target.value });
            GetRecords("All",e.target.value)
            e.target.value==="Waiting For Approval"?GetTableInfo():
            GetapprovalRecords(e.target.value);
            
          }}
        />
      </div>
      <div className="row">
        <table
          style={{ textAlign: "center", width: "100%", overflowY: "scroll", margin:"0px 10px" }}
        >
          <thead>

            <tr className="table_position">
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                S.No
              </th>
              <th
                style={{
                  textAlign: "center",
                  whiteSpace: "nowrap",
                  width: "40%",
                }}
              >
                Description
              </th>
              <th
                style={{
                  textAlign: "center",
                  whiteSpace: "nowrap",
                  width: "110px",
                }}
              >
                Requested By
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                Status
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                Created Date
              </th>
              <th className="table-list-head" colSpan={3}></th>
            </tr>
          </thead>
          <tbody>
            {Records[0] ?
              Records.map((item) => (
                <tr
                  className="table_view"
                  key={item.Id}
                  style={{ position: "relative" }}
                >
                  <td>{item.Id}</td>
                  <td>{item.Description}</td>
                  <td>{item.RequestedBy}</td>
                  <td>{item.Status}</td>
                  <td>{item.CreatedDate}</td>
                  <td>
                    <button
                      className="icon"
                      style={{ backgroundColor: "#cba423" }}
                      type="button"
                      onClick={() => {
                        navigate(
                          "/" + item.TableName + "/view/" + item.TableID
                        );

                      }}
                    >
                      view
                    </button>
                  </td>
                  
                </tr>
              )):<tr>
                <td colSpan={8} style={{textAlign:'center'}}>No Records Found</td></tr>}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Approvals;
