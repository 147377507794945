//external import
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

//internal import
import Field from "../../../Components/Fields/Field";
import Lable from "../../../Components/Fields/Lable";
import QaLayout from "../../Quality/QaLayout";

//css import
import "./NewSmpMain.css";
//API imports
const MouldCloseSettings = ({
  Records,
  setRecords,
  setActiveKey,
  activeKey,
}) => {
  const { option, id } = useParams();
  const [ctrlPressed, setCtrlPressed] = useState(false);
  function InvalidCharacters(value) {
    return /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/.test(value);
  }
  useEffect(() => {
    if (activeKey === "nine") {
      document.getElementById("1-Mould_close_pressure").focus();
    }
    const handleKeyDown = (event) => {
      let activeField = document.activeElement;
      let fieldOptions = {
        Mould_close_speed: ["Mould_close_pressure", "Mould_close_position"],
        Mould_close_position: ["Mould_close_speed", ""],
        Mould_close_pressure: ["", "Mould_close_speed"],
      };

      let row = Records[0].mouldclosesettings_function.length - 1;
      let currentField = activeField.id.split("-")[1];
      let indexOfField = activeField.id.split("-")[0];
      let nextField = "";

      if (currentField != undefined) {
        switch (event.ctrlKey + "+" + event.key + "+" + activeKey) {
          case "false+ArrowRight+nine":
            nextField = fieldOptions[currentField][1];
            if (indexOfField > 0 && indexOfField < row) {
              if (nextField != "") {
                document.getElementById(indexOfField + "-" + nextField).focus();
              } else if (indexOfField < row) {
                document
                  .getElementById(
                    Number(indexOfField) + 1 + "-Mould_close_pressure"
                  )
                  .focus();
              }
            } else if (indexOfField == row) {
              document
                .getElementById(indexOfField + "-Mould_close_position")
                .focus();
            }
            break;

          case "false+ArrowLeft+nine":
            nextField = fieldOptions[currentField][0];
            if (
              indexOfField > 0 &&
              indexOfField < row &&
              activeField.id != "1-Mould_close_pressure"
            ) {
              if (nextField != "") {
                document.getElementById(indexOfField + "-" + nextField).focus();
              } else {
                document
                  .getElementById(indexOfField - 1 + "-Mould_close_position")
                  .focus();
              }
            } else if (indexOfField == row) {
              if (currentField === "Mould_close_position") {
                document
                  .getElementById(indexOfField + "-Mould_close_pressure")
                  .focus();
              } else {
                document
                  .getElementById(indexOfField - 1 + "-Mould_close_pressure")
                  .focus();
              }
            }
            break;

          case "false+ArrowDown+nine":
            // console.log("indexOfField",indexOfField,row,indexOfField == row);
            if (indexOfField < row - 1) {
              document
                .getElementById(Number(indexOfField) + 1 + "-" + currentField)
                .focus();
            } else if (
              indexOfField == row - 1 &&
              currentField != "Mould_close_speed"
            ) {
              document
                .getElementById(Number(indexOfField) + 1 + "-" + currentField)
                .focus();
            }
            break;

          case "false+ArrowUp+nine":
            if (indexOfField > 1) {
              //   if (indexOfField < row + 1) {
              document
                .getElementById(Number(indexOfField) - 1 + "-" + currentField)
                .focus();
              //   }
            }
            break;

          case "true+ArrowRight+nine":
            setActiveKey("ten");
            break;
          case "true+ArrowDown+nine":
            console.log("true+ArrowDown+nine");
            handleIncrease(Records[0].mouldclosesettings_function.length);
            document.getElementById("1-Mould_close_pressure").focus();
            break;

          case "true+ArrowUp+nine":
            if (row > 6) {
              handleDecrease(Records[0].mouldclosesettings_function.length - 4);
              document.getElementById("1-Mould_close_pressure").focus();
            }
            break;
          default:
            break;
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [activeKey, setActiveKey]);

  function HandleMouldClose(Event, index) {
    setRecords((item) => {
      let record = [...Records];
      record[0].mouldclosesettings_function[index] = {
        ...record[0].mouldclosesettings_function[index],
        [Event.target.name]: Event.target.value,
      };
      return record;
    });
  }
  const handleIncrease = (index) => {
    console.log("index", index);
    setRecords((prevRecords) => {
      let Records = [...prevRecords];
      Records[0].mouldclosesettings_function.splice(index - 3, 0, {
        Mould_close_stages: "Close " + (index - 3),
        Mould_close_pressure: "",
        Mould_close_speed: "",
        Mould_close_position: "",
      });
      return Records;
    });
  };
  const handleDecrease = (index) => {
    setRecords((prevRecords) => {
      let Records = [...prevRecords];
      // Records[0].mouldclosesettings_function.pop();
      Records[0].mouldclosesettings_function =
        Records[0].mouldclosesettings_function.filter(
          (item, id) => id != index
        );
      return Records;
    });
  };
  const DropdownValues = [
    { value: "", label: "Select" },
    { value: "1", label: "±1" },
    { value: "2", label: "±2" },
    { value: "3", label: "±3" },
    { value: "4", label: "±4" },
    { value: "5", label: "±5" },
    { value: "6", label: "±6" },
    { value: "7", label: "±7" },
    { value: "8", label: "±8" },
    { value: "9", label: "±9" },
    { value: "10", label: "±10" },
    { value: "11", label: "±11" },
    { value: "12", label: "±12" },
    { value: "13", label: "±13" },
    { value: "14", label: "±14" },
    { value: "15", label: "±15" },
  ];

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th colSpan={5}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "90% 10%",
                  alignItems: "center",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <Lable
                    value={"Mould Close Settings"}
                    type={"Head"}
                    style={{ padding: "1px" }}
                  />
                </div>
                <div>
                  <button
                    className="nextButtonLabel"
                    onClick={(e) => {
                      setActiveKey("ten");
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Lable
                value={`${Records[0].mouldclosesettings_function[0].Mould_close_stages}`}
                style={{ textAlign: "center", padding: "0px" }}
              />
            </td>
            <td>
              <Lable
                value={"PRESSURE (BAR)⟡"}
                style={{ textAlign: "center", padding: "0px" }}
              />
              <Field
                label={""}
                name={"Mould_close_pressure"}
                className={"fieldSingleGrid"}
                options={{
                  type: "dropdown",
                  disabled: option == "view",
                  values: DropdownValues,
                }}
                value={Records[0].mouldclosesettings_function[0]}
                OnChange={(e) => {
                  HandleMouldClose(e, 0);
                }}
              />
            </td>
            <td>
              <Lable
                value={"SPEED% ⟡"}
                style={{ textAlign: "center", padding: "0px" }}
              />
              <Field
                label={""}
                name={"Mould_close_speed"}
                className={"fieldSingleGrid"}
                options={{
                  type: "dropdown",
                  disabled: option == "view",
                  values: DropdownValues,
                }}
                value={Records[0].mouldclosesettings_function[0]}
                OnChange={(e) => {
                  HandleMouldClose(e, 0);
                }}
              />
            </td>
            <td>
              <Lable
                value={"POSITION (MM)⟡"}
                style={{ textAlign: "center", padding: "0px" }}
              />
              <Field
                label={""}
                name={"Mould_close_position"}
                className={"fieldSingleGrid"}
                options={{
                  type: "dropdown",
                  disabled: option == "view",
                  values: DropdownValues,
                }}
                value={Records[0].mouldclosesettings_function[0]}
                OnChange={(e) => {
                  HandleMouldClose(e, 0);
                }}
              />
            </td>
          </tr>
          {Records[0].mouldclosesettings_function.map((item, index) => {
            if (
              index != 0 &&
              index != Records[0].mouldclosesettings_function.length - 1
            ) {
              return (
                <tr key={index}>
                  <td>
                    <Lable
                      value={item.Mould_close_stages}
                      style={{ textAlign: "center", padding: "0px" }}
                    />
                  </td>
                  <td>
                    <Field
                      label={""}
                      id={index}
                      name={"Mould_close_pressure"}
                      className={"fieldSingleGrid"}
                      options={{
                        type: "text",
                        disabled: option == "view",
                      }}
                      value={item}
                      OnChange={(e) => {
                        const value = e.target.value;
                        if (InvalidCharacters(value)) {
                          e.preventDefault();
                          return;
                        }
                        HandleMouldClose(e, index);
                      }}
                    />
                  </td>
                  <td>
                    <Field
                      label={""}
                      id={index}
                      name={"Mould_close_speed"}
                      className={"fieldSingleGrid"}
                      options={{
                        type: "text",
                        disabled: option == "view",
                      }}
                      value={item}
                      OnChange={(e) => {
                        const value = e.target.value;
                        if (InvalidCharacters(value)) {
                          e.preventDefault();
                          return;
                        }
                        HandleMouldClose(e, index);
                      }}
                    />
                  </td>
                  <td>
                    <Field
                      label={""}
                      id={index}
                      name={"Mould_close_position"}
                      className={"fieldSingleGrid"}
                      options={{
                        type: "text",
                        disabled: option == "view",
                      }}
                      value={item}
                      OnChange={(e) => {
                        const value = e.target.value;
                        if (InvalidCharacters(value)) {
                          e.preventDefault();
                          return;
                        }
                        HandleMouldClose(e, index);
                      }}
                    />
                  </td>
                </tr>
              );
            }
          })}
          <tr>
            <td>
              <Lable
                value={`${
                  Records[0].mouldclosesettings_function[
                    Records[0].mouldclosesettings_function.length - 1
                  ].Mould_close_stages
                }⟡`}
                style={{ textAlign: "center", padding: "0px" }}
              />
            </td>
            <td>
              <Field
                label={""}
                id={Records[0].mouldclosesettings_function.length - 1}
                name={"Mould_close_pressure"}
                className={"fieldSingleGrid"}
                options={{
                  type: "text",
                  disabled: option == "view",
                }}
                value={
                  Records[0].mouldclosesettings_function[
                    Records[0].mouldclosesettings_function.length - 1
                  ]
                }
                OnChange={(e) => {
                  let value = e.target.value;
                        if (InvalidCharacters(value)) {
                          value = value.replace(/[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g, '');
                          e.target.value = value;
                        }
                  HandleMouldClose(
                    e,
                    Records[0].mouldclosesettings_function.length - 1
                  );
                }}
              />
            </td>
            <td>
              <Lable
                value={
                  Records[0].mouldclosesettings_function[
                    Records[0].mouldclosesettings_function.length - 1
                  ].Mould_close_speed
                }
                style={{ textAlign: "center", padding: "0px" }}
              />
            </td>
            <td>
              <Field
                label={""}
                id={Records[0].mouldclosesettings_function.length - 1}
                name={"Mould_close_position"}
                className={"fieldSingleGrid"}
                options={{
                  type: "text",
                  disabled: option == "view",
                }}
                value={
                  Records[0].mouldclosesettings_function[
                    Records[0].mouldclosesettings_function.length - 1
                  ]
                }
                OnChange={(e) => {
                  let value = e.target.value;
                  if (InvalidCharacters(value)) {
                    value = value.replace(/[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g, '');
                    e.target.value = value;
                  }
                  HandleMouldClose(
                    e,
                    Records[0].mouldclosesettings_function.length - 1
                  );
                }}
              />
            </td>
          </tr>
          <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
            <div style={{}}>
              <Field
                style={{ width: "30px", fontSize: "18px" }}
                label={"+"}
                options={{ type: "Button" }}
                onClick={() =>
                  handleIncrease(Records[0].mouldclosesettings_function.length)
                }
              />
            </div>
            <div>
              {Records[0].mouldclosesettings_function.length > 7 && (
                <Field
                  style={{ width: "30px", fontSize: "18px" }}
                  label={"-"}
                  options={{ type: "Button" }}
                  onClick={() =>
                    handleDecrease(
                      Records[0].mouldclosesettings_function.length - 4
                    )
                  }
                />
              )}
            </div>
          </div>
        </tbody>
      </table>
    </div>
  );
};
export default MouldCloseSettings;
