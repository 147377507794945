import React from 'react'
import './Field.css'

const Lable = ({value,type,style,onClick,required=false}) => {
  return (
    <div className='Field'>
       <label onClick={onClick} className={type==="Head"?"Heading":'SingleGrid'} style={style}>
        {value}{required && <span style={{color:'red'}}>*</span>}
       </label>
    </div>
  )
}

export default Lable

