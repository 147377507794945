import React from "react";
import "./Scores.css";
import { useState } from "react";

export const Scores = ({value, name ,setpoint, isDisable}) => {
  const [display, setdisplay] = useState("none");
  function Handleimage(e) {
    setdisplay("flex");
  }
  
  return (
    <div className="card">
      <div
        className="outputimage"
        onClick={() => {
          if(!isDisable){
            Handleimage();
          }
        }}
      >
        <img className="pro_skill_img"  src={`/${value}.png`} alt="image" />
      </div>
      <div className="SelectionBar" style={{ display: display }}>
        <div
          onClick={() => {
            setpoint(name,0)
            setdisplay("none");
          }}
        >
          <img className="pro_skill_img"  src={`/0.png`} alt="image" />
        </div>
        <div
          onClick={() => {
            setpoint(name,1)
            setdisplay("none");
          }}
        >
          <img className="pro_skill_img"  src={`/1.png`} alt="image" />
        </div>
        <div
          onClick={() => {
            setpoint(name,2)
            setdisplay("none");
          }}
        >
          <img className="pro_skill_img"  src={`/2.png`} alt="image" />
        </div>
        <div
          onClick={() => {
            setpoint(name,3)
            setdisplay("none");
          }}
        >
          <img className="pro_skill_img"  src={`/3.png`} alt="image" />
        </div>
        <div
          onClick={() => {
            setpoint(name,4)
            setdisplay("none");
          }}
        >
          <img className="pro_skill_img"  src={`/4.png`} alt="image" />
        </div>
      </div>
    </div>
  );
};
