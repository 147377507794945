//external import
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

//internal import
import Field from "../../../Components/Fields/Field";
import Lable from "../../../Components/Fields/Lable";
import QaLayout from "../../Quality/QaLayout";

//css import
import "./NewSmpMain.css";
//API imports
const RefilSettings = ({ Records, setRecords, setActiveKey, activeKey }) => {
  const { option, id } = useParams();
  const [ctrlPressed, setCtrlPressed] = useState(false);
  function InvalidCharacters(value) {
    return /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/.test(value);
  }
  useEffect(() => {
    if (activeKey === "three") {
      document.getElementById("1-Refilsetting_pressure").focus();
    }
    const fieldOptions = {
      Refilsetting_time: ["Refilsetting_position", ""],
      Refilsetting_position: ["Refilsetting_speed", "Refilsetting_time"],
      Refilsetting_speed: ["Refilsetting_pressure", "Refilsetting_position"],
      Refilsetting_pressure: ["", "Refilsetting_speed"],
    };
    const handleKeyDown = (event) => {
      let activeField = document.activeElement;
      let row = Records[0].refilsetting_functional.length - 3;
      let currentField = activeField.id.split("-")[1];
      let indexOfField = activeField.id.split("-")[0];
      let nextField = "";

      if (currentField != undefined) {
        switch (event.ctrlKey + "+" + event.key + "+" + activeKey) {
          case "false+ArrowRight+three":
            nextField = fieldOptions[currentField][1];
            if (indexOfField > 0 && indexOfField < row + 1) {
              if (nextField != "") {
                document.getElementById(indexOfField + "-" + nextField).focus();
              } else{
                if (indexOfField == row) {
                    console.log(row + 1 + "-Refilsetting_time");
                  document
                    .getElementById(row + 1 + "-Refilsetting_position")
                    .focus();
                } else {
                  document
                    .getElementById(
                      Number(indexOfField) + 1 + "-Refilsetting_pressure"
                    )
                    .focus();
                }
              }
            }else {
                if (indexOfField == row + 1) {
                  document
                    .getElementById(row + 2 + "-Refilsetting_position")
                    .focus();
                }
              }
            break;

          case "false+ArrowLeft+three":
            nextField = fieldOptions[currentField][0];
            if (
              indexOfField > 0 &&
              indexOfField < row + 1 &&
              activeField.id != "1-Refilsetting_pressure"
            ) {
              if (nextField != "") {
                document.getElementById(indexOfField + "-" + nextField).focus();
              } else {
                document
                  .getElementById(indexOfField - 1 + "-Refilsetting_time")
                  .focus();
              }
            } else {
              if (indexOfField == row + 1) {
                document
                  .getElementById(indexOfField - 1 + "-Refilsetting_time")
                  .focus();
              } else if (indexOfField == row + 2) {
                document
                  .getElementById(indexOfField - 1 + "-Refilsetting_position")
                  .focus();
              }
            }
            break;

          case "false+ArrowDown+three":
            // console.log("indexOfField",indexOfField,row,indexOfField == row);
            if (indexOfField < row) {
              document
                .getElementById(Number(indexOfField) + 1 + "-" + currentField)
                .focus();
            }else if (indexOfField == row) {
                document.getElementById(row + 1 + "-Refilsetting_position").focus();
              } else if (indexOfField == row + 1) {
                document.getElementById(row + 2 + "-Refilsetting_position").focus();
              }
            break;

          case "false+ArrowUp+three":
            if (indexOfField > 1) {
              //   if (indexOfField < row + 1) {
              document
                .getElementById(Number(indexOfField) - 1 + "-" + currentField)
                .focus();
              //   }
            }
            break;

          case "true+ArrowRight+three":
            setActiveKey("four");
            break;

          case "true+ArrowDown+three":
            handleIncrease(Records[0].refilsetting_functional.length);
            document.getElementById("1-Refilsetting_pressure").focus();
            break;

          case "true+ArrowUp+three":
            if (row > 1) {
              handleDecrease(Records[0].refilsetting_functional.length - 3);
              document.getElementById("1-Refilsetting_pressure").focus();
            }
            break;

          default:
            break;
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setActiveKey, activeKey]);

  function HandleRefilSet(Event, index) {
    setRecords((item) => {
      let record = [...Records];
      record[0].refilsetting_functional[index] = {
        ...record[0].refilsetting_functional[index],
        [Event.target.name]: Event.target.value,
      };
      return record;
    });
  }
  const handleIncrease = (index) => {
    console.log("index", index);
    setRecords((prevRecords) => {
      let Records = [...prevRecords];
      Records[0].refilsetting_functional.splice(index - 2, 0, {
        Refilsetting_stages: "Refill " + (index - 2),
      });
      return Records;
    });
  };
  const handleDecrease = (index) => {
    setRecords((prevRecords) => {
      let Records = [...prevRecords];
      // Records[0].refilsetting_functional.pop();
      Records[0].refilsetting_functional =
        Records[0].refilsetting_functional.filter((item, id) => id != index);
      return Records;
    });
  };

  const DropdownValues = [
    { value: "", label: "Select" },
    { value: "1", label: "±1" },
    { value: "2", label: "±2" },
    { value: "3", label: "±3" },
    { value: "4", label: "±4" },
    { value: "5", label: "±5" },
    { value: "6", label: "±6" },
    { value: "7", label: "±7" },
    { value: "8", label: "±8" },
    { value: "9", label: "±9" },
    { value: "10", label: "±10" },
    { value: "11", label: "±11" },
    { value: "12", label: "±12" },
    { value: "13", label: "±13" },
    { value: "14", label: "±14" },
    { value: "15", label: "±15" },
  ];
  const DropdownValuesTime = [
    { value: "", label: "Select" },
    { value: "0.1", label: "±0.1" },
    { value: "0.2", label: "±0.2" },
    { value: "0.3", label: "±0.3" },
    { value: "0.4", label: "±0.4" },
    { value: "0.5", label: "±0.5" },
    { value: "0.6", label: "±0.6" },
    { value: "0.7", label: "±0.7" },
    { value: "0.8", label: "±0.8" },
    { value: "0.9", label: "±0.9" },
    { value: "1", label: "±1" },
    { value: "2", label: "±2" },
    { value: "3", label: "±3" },
    { value: "4", label: "±4" },
    { value: "5", label: "±5" },
  ];

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th colSpan={5}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "90% 10%",
                  alignItems: "center",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <Lable
                    value={"Refill Settings"}
                    type={"Head"}
                    style={{ padding: "1px" }}
                  />
                </div>
                <div>
                  <button
                    className="nextButtonLabel"
                    onClick={(e) => {
                      setActiveKey("four");
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Lable
                value={
                  Records[0].refilsetting_functional[0].Refilsetting_stages
                }
                style={{ textAlign: "center", padding: "0px" }}
              />
            </td>
            <td>
              <Lable
                value={"PRESSURE*(BAR)"}
                style={{ textAlign: "center", padding: "0px" }}
              />

              <Field
                label={""}
                name={"Refilsetting_pressure"}
                className={"fieldSingleGrid"}
                options={{
                  type: "dropdown",
                  disabled: option == "view",
                  values: DropdownValues,
                }}
                value={Records[0].refilsetting_functional[0]}
                OnChange={(e) => {
                  HandleRefilSet(e, 0);
                }}
              />
            </td>
            <td>
              <Lable
                value={"SPEED% *"}
                style={{ textAlign: "center", padding: "0px" }}
              />
              <Field
                label={""}
                name={"Refilsetting_speed"}
                className={"fieldSingleGrid"}
                options={{
                  type: "dropdown",
                  disabled: option == "view",
                  values: DropdownValues,
                }}
                value={Records[0].refilsetting_functional[0]}
                OnChange={(e) => {
                  HandleRefilSet(e, 0);
                }}
              />
            </td>
            <td>
              <Lable
                value={"POSITION(MM)⟡"}
                style={{ textAlign: "center", padding: "0px" }}
              />
              <Field
                label={""}
                name={"Refilsetting_position"}
                className={"fieldSingleGrid"}
                options={{
                  type: "dropdown",
                  disabled: option == "view",
                  values: DropdownValues,
                }}
                value={Records[0].refilsetting_functional[0]}
                OnChange={(e) => {
                  HandleRefilSet(e, 0);
                }}
              />
            </td>
            <td>
              <Lable
                value={"TIME(SEC) *"}
                style={{ textAlign: "center", padding: "0px" }}
              />
              <Field
                label={""}
                name={"Refilsetting_time"}
                className={"fieldSingleGrid"}
                options={{
                  type: "dropdown",
                  disabled: option == "view",
                  values: DropdownValuesTime,
                }}
                value={Records[0].refilsetting_functional[0]}
                OnChange={(e) => {
                  HandleRefilSet(e, 0);
                }}
              />
            </td>
          </tr>
          {/* {Array.from({ length: Records[0].refilsetting_functional.length }, (_, i) => i + 1).map((item) => ( */}
          {Records[0].refilsetting_functional.map((item, index) => {
            if (
              index != 0 &&
              index != Records[0].refilsetting_functional.length - 1 &&
              index != Records[0].refilsetting_functional.length - 2
            ) {
              return (
                <tr>
                  <td>
                    <label>{item.Refilsetting_stages}</label>
                  </td>
                  {/* <td>
                                    <Field
                                        label={""}
                                        name={"Refilsetting_stages"}
                                        
                                        options={{
                                            type: "text",

                                            disabled: option == "view",
                                        }}
                                        value={item}
                                        OnChange={(e) => {
                                            HandleRefilSet(e, index);
                                        }}
                                    />
                                </td> */}
                  <td>
                    <Field
                      label={""}
                      id={index}
                      name={"Refilsetting_pressure"}
                      className={"fieldSingleGrid"}
                      options={{
                        type: "text",

                        disabled: option == "view",
                      }}
                      value={item}
                      OnChange={(e) => {
                        let value = e.target.value;
                        if (InvalidCharacters(value)) {
                          value = value.replace(/[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g, '');
                          e.target.value = value;
                        }
                        HandleRefilSet(e, index);
                      }}
                    />
                  </td>
                  <td>
                    <Field
                      className={"fieldSingleGrid"}
                      id={index}
                      label={""}
                      name={"Refilsetting_speed"}
                      options={{
                        type: "text",

                        disabled: option == "view",
                      }}
                      value={item}
                      OnChange={(e) => {
                        let value = e.target.value;
                        if (InvalidCharacters(value)) {
                          value = value.replace(/[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g, '');
                          e.target.value = value;
                        }
                        HandleRefilSet(e, index);
                      }}
                    />
                  </td>
                  <td>
                    <Field
                      className={"fieldSingleGrid"}
                      id={index}
                      label={""}
                      name={"Refilsetting_position"}
                      options={{
                        type: "text",

                        disabled: option == "view",
                      }}
                      value={item}
                      OnChange={(e) => {
                        let value = e.target.value;
                        if (InvalidCharacters(value)) {
                          value = value.replace(/[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g, '');
                          e.target.value = value;
                        }
                        HandleRefilSet(e, index);
                      }}
                    />
                  </td>
                  <td>
                    <Field
                      className={"fieldSingleGrid"}
                      id={index}
                      label={""}
                      name={"Refilsetting_time"}
                      options={{
                        type: "text",

                        disabled: option == "view",
                      }}
                      value={item}
                      OnChange={(e) => {
                        let value = e.target.value;
                        if (InvalidCharacters(value)) {
                          value = value.replace(/[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g, '');
                          e.target.value = value;
                        }
                        HandleRefilSet(e, index);
                      }}
                    />
                  </td>
                </tr>
              );
            }
          })}
          <tr>
            <td>
              <label>
                {
                  Records[0].refilsetting_functional[
                    Records[0].refilsetting_functional.length - 2
                  ].Refilsetting_stages
                }
              </label>
            </td>
            <td colSpan={2}></td>
            <td>
              <Field
                className={"fieldSingleGrid"}
                label={""}
                id={Records[0].refilsetting_functional.length - 2}
                name={"Refilsetting_position"}
                options={{
                  type: "text",
                  disabled: option == "view",
                }}
                value={
                  Records[0].refilsetting_functional[
                    Records[0].refilsetting_functional.length - 2
                  ]
                }
                OnChange={(e) => {
                  let value = e.target.value;
                  if (InvalidCharacters(value)) {
                    value = value.replace(/[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g, '');
                    e.target.value = value;
                  }
                  HandleRefilSet(
                    e,
                    Records[0].refilsetting_functional.length - 2
                  );
                }}
              />
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <label>
                {
                  Records[0].refilsetting_functional[
                    Records[0].refilsetting_functional.length - 1
                  ].Refilsetting_stages
                }
              </label>
              <Field
                label={""}
                id={Records[0].refilsetting_functional.length - 1}
                name={"Refilsetting_stage_set"}
                className={"fieldSingleGrid"}
                options={{
                  type: "dropdown",
                  disabled: option == "view",
                  values: DropdownValues,
                }}
                value={
                  Records[0].refilsetting_functional[
                    Records[0].refilsetting_functional.length - 1
                  ]
                }
                OnChange={(e) => {
                  HandleRefilSet(
                    e,
                    Records[0].refilsetting_functional.length - 1
                  );
                }}
              />
            </td>
            <td colSpan={2}></td>
            <td>
              <Field
                className={"fieldSingleGrid"}
                id={Records[0].refilsetting_functional.length - 1}
                label={""}
                name={"Refilsetting_position"}
                options={{
                  type: "text",
                  disabled: option == "view",
                }}
                value={
                  Records[0].refilsetting_functional[
                    Records[0].refilsetting_functional.length - 1
                  ]
                }
                OnChange={(e) => {
                  let value = e.target.value;
                  if (InvalidCharacters(value)) {
                    value = value.replace(/[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g, '');
                    e.target.value = value;
                  }
                  HandleRefilSet(
                    e,
                    Records[0].refilsetting_functional.length - 1
                  );
                }}
              />
            </td>
            <td></td>
          </tr>
          <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
            <div style={{}}>
              <Field
                style={{ width: "30px", fontSize: "18px" }}
                label={"+"}
                options={{ type: "Button" }}
                onClick={() =>
                  handleIncrease(Records[0].refilsetting_functional.length)
                }
              />
            </div>
            <div>
              {Records[0].refilsetting_functional.length > 4 && (
                <Field
                  style={{ width: "30px", fontSize: "18px" }}
                  label={"-"}
                  options={{ type: "Button" }}
                  onClick={() =>
                    handleDecrease(
                      Records[0].refilsetting_functional.length - 3
                    )
                  }
                />
              )}
            </div>
          </div>
        </tbody>
      </table>
    </div>
  );
};
export default RefilSettings;
