import React,{useState,useEffect} from "react";
import QaLayoutPaint from "../../QaLayoutPaint";
import ListViewPaint from "../../../../Components/ListView/ListViewPaint";

const ProgramMasterList = () => {
 
  return (
    <QaLayoutPaint quary={"paint_program_master"}>
    <div>
      <ListViewPaint
        Title={"Paint Program Master"}
        standard={["delete_id=0"]}
        quary={"Select id as Id,Program_code,Program_name,active_status,CreatedDate from paint_program_master group by Id ORDER BY CASE WHEN active_status = 'Active' THEN 0 ELSE 1 END,Id DESC"}
        table={"paint_program_master"}
        tableDelete={"paint_program_master"}
        navigation={"PaintProgramMaster"}
        filter={["Id","Program_code","Program_name","active_status"]}
        dateFilter={"CreatedDate"}
        header={[
          { value: "Id", label: "ID" },
          { value: "CreatedDate", label: "Date" },
          { value: "Program_code", label: "Program Code" },
          { value: "Program_name", label: "Program Name" },
          { value: "active_status", label: "Active Status" },
        ]}
      />   
    </div>
    </QaLayoutPaint>
  );
};

export default ProgramMasterList;
