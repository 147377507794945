import React, { useState, useEffect } from "react";
import axios from "axios";
import Popup from "../../../Components/Popup/Popup";
import { API_URL } from "../../../Utils/constants.js";
import "../../../Styles/proskill.css";
//import "bootstrap/dist/css/bootstrap.min.css";

import Unitechlogo from "../../../icons/Unitechlogo.jpeg";

import HandleRead from "../../../Lib/HandleRead.js";
import { Scores } from "../../../Components/Scores/Scores.js";
import Pagination from "../../../Components/Pagination";
import DatePicker from "../../../Lib/DatePicker";
import { formatDt } from "../../../Lib/formatDt";
import DropdownFetcher from "../../../Lib/DropdownFetcher";
import UpdateRecord from "../../../Lib/updateRecord";
import { MdDelete } from "react-icons/md";
import ReadRecord from "../../../Lib/API/ReadRecord.js";


import Select from "react-select";
function ProductionSkillView() {
  const handleDateChange = (newDate) => {
    setEvlOn(newDate);
  };
  const handlenextDateChange = (newDate) => {
    setnxtEvlOn(newDate);
  };
  const [Id, setId] = useState("");
  const [Revno, setrevno] = useState("");
  const [preparedby, setpreparedby] = useState("");
  const [Fno, setFno] = useState("");
  const [RDate, setRDate] = useState("");
  const [Name, setName] = useState("");
  const [Photo, setPhoto] = useState("");
  const [Desn, setDesn] = useState("");
  const [Knw3C55, setKnw3C55] = useState(0);
  const [Rmknw, setRmknw] = useState(0);
  const [CommSkill, setCommSkill] = useState(0);
  const [ProKnw, setProKnw] = useState(0);
  const [HourMon, setHourMon] = useState(0);
  const [DocKnw, setDocKnw] = useState(0);
  const [Cumpoint, setCumpoint] = useState(0);
  const [LvlCls, setLvlcls] = useState(0);
  const [EvlOn, setEvlOn] = useState("");
  const [nxtEvlOn, setnxtEvlOn] = useState("");
  const [Crdate, setCrDate] = useState("");
  const [cummpoint, setcummpoint] = useState(true);

  const [Employee, setEmployee] = useState([]);

  var [Pro_skill, setPro_skill] = useState([]);

  const [isPaginationVisible, setPaginationVisible] = useState(true);

  const [ButtonPopup, setButtonPopup] = useState(false);
  const [ButtonPopup1, setButtonPopup1] = useState(false);
  const [ButtonPopup2, setButtonPopup2] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  // const records = Pro_skill.slice(firstIndex, lastIndex);

  const idView = "proskillView";
  const nameView = "proskillView";
  const nameEdit = "proskillEdit";
  const idEdit = "proskillEdit";
  const nameList = "proskillList";
  const idList = "proskillList";

  const handleView = (e) => {
    setPaginationVisible(false);
    setButtonPopup2(true);
    
    setName(`${e.Name}-${e.EmpName}`);
    setPhoto(e.Photo);
    setDesn(e.Designation);
    setKnw3C55(e.Knw_3c_55);
    setRmknw(e.RM_Knowledge);
    setCommSkill(e.Comm_Skill);
    setProKnw(e.Pro_knw);
    setHourMon(e.Hour_mon);
    setDocKnw(e.Doc_knw);
    setCumpoint(e.Cum_Points);
    setLvlcls(e.Level_Class);
    setEvlOn(e.Eval_on);
    setnxtEvlOn(e.Next_Eval_on);
    setpreparedby(`${e.Prepared_by}-${e.PreparedBy}`);
console.log(e);
    // setCrDate(e.CreatedDate);
  };

  const viewPagination = () => {
    setPaginationVisible(true);
  };

 
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 20;
  

  // const [filterDate, setFilterDate] = useState("");
  const [filterMcno, setFilterMcno] = useState("");
  // const [filterMachine, setFilterMachine] = useState("");
  const filterRecords = () => {
    const filteredData = Pro_skill.filter((record) => {
      // const dateMatch = record.TrackDate && record.TrackDate.includes(filterDate);
      const mcnoMatch = record.Name && record.Name.toLowerCase().includes(filterMcno.toLowerCase()) || record.EmpName && record.EmpName.toLowerCase().includes(filterMcno.toLowerCase());
      
      return  mcnoMatch ;
    });
  
  return filteredData;
  };
  
  const filteredData = filterRecords();
  const npage = Math.ceil(filteredData.length / recordPerPage);
  const numbers = [...Array(npage).keys()].map((num) => num + 1);

  // function handleFilterDateChange(event) {
  //   setFilterDate(event.target.value);
  //   setCurrentPage(1);
  // }
  
  function handleFilterMcnoChange(event) {
    setFilterMcno(event.target.value);
    setCurrentPage(1); 
  }

  
  const firstIndex = (currentPage - 1) * recordPerPage;
  const lastIndex = firstIndex + recordPerPage;
  const filteredRecords = filteredData.slice(firstIndex, lastIndex);
  function prePage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function nextPage() {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function changeCpage(Id) {
    setCurrentPage(Id);
  }


  const handleEdit = (e) => {
    setPaginationVisible(false);
    setButtonPopup(true);
    setId(e.Id);  
    setName({value:e.Name,label:e.EmpName})
    setPhoto(e.Photo);
    setDesn(e.Designation);
    setKnw3C55(e.Knw_3c_55);
    setRmknw(e.RM_Knowledge);
    setCommSkill(e.Comm_Skill);
    setProKnw(e.Pro_knw);
    setHourMon(e.Hour_mon);
    setDocKnw(e.Doc_knw);
    setCumpoint(e.Cum_Points);
    setLvlcls(e.Level_Class);
    setEvlOn(e.Eval_on);
    setnxtEvlOn(e.Next_Eval_on);
    setpreparedby({value:e.Prepared_by,label:e.PreparedBy});
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    if (!Name || !preparedby || !nxtEvlOn) {
      setIsCreating(true);
      alert("Please fill the mandatory fields");

      return;
    }
    const data = {
      data: [
        {
          Id: Id,
          Name: Name.value,
          Photo: Photo,
          Designation: Desn,
          Knw_3c_55: Knw3C55,
          RM_Knowledge: Rmknw,
          Comm_Skill: CommSkill,
          Pro_knw: ProKnw,
          Hour_mon: HourMon,
          Doc_knw: DocKnw,
          Cum_Points: Cumpoint,
          Level_Class: LvlCls,
          Eval_on: EvlOn,
          Next_Eval_on: nxtEvlOn,
          Prepared_by:preparedby.value
        },
      ],
    };
    data["_keyword_"] = "Production_skill";
    data["secretkey"] = "2bf52be7-9f68-4d52-9523-53f7f267153b";
    axios
      .post(
        `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
        data
      )
      .then((response) => {
        setPro_skill([...Pro_skill, response.data]);
        alert("Updation Successful");
        setButtonPopup(false);
        handleReadProSkillMatrix();
        setPaginationVisible(true);

        // swal({
        //   title:"Good job!",
        //   text:`${Name} Submitted`,
        //   icon:"success",
        //   button:"Okay",
        //   closeOnClickOutside: false,
        // }) .then(() => {
        //   window.location.reload();
        //       })
      })
      .catch((error) => {
        console.log(error);
        alert("Updation failed.");
      window.location.reload();

      });
  };

  const handleEmployee = (selectedOption) => {
    const selected = Employee.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setName({
        value: selected.Emp_code,
        label: selected.Emp_name,
      });
      setPhoto(selected.Emp_image);
      setDesn(selected.Emp_designation);
    }
  };
  function handlecummpoint() {
    let cumm =
      Number(Knw3C55) +
      Number(Rmknw) +
      Number(CommSkill) +
      Number(ProKnw) +
      Number(HourMon) +
      Number(DocKnw);
    let LvlCls = cumm*4
    
      setCumpoint(cumm)
      setLvlcls(LvlCls)
   //   setcummpoint(true)
  }
  const handleprepared = (selectedOption) => {
    const selected = Employee.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
  
        setpreparedby({
          value: selected.Emp_code,
          label: selected.Emp_name,
        })
    }
  };
  function handleReadProSkillMatrix() {
    ReadRecord({
      _keyword_: "Production_skill"
    }).then((res) => {
      // console.log(res.data);
      setPro_skill(res.data);
    });
  }
  useEffect(() => {
    handleReadProSkillMatrix();
  }, [])
  return (
    <div>
      <Popup
        trigger={ButtonPopup2}
        setTrigger={setButtonPopup2}
        viewPagination={viewPagination}
      >
        <form
            id="proskill-formContainer-Create"
            name="proskill-formContainer-Create"
            className="form-proskill-Create"
            style={{margin:"auto"}}
          >
            <table
              style={{ textAlign: "center", marginLeft: "70px", width: "80%" }}
            >
              <tbody>
                <tr>
                  <th style={{ textAlign: "center", width: "10%" }}>
                    <img src={Unitechlogo} style={{ width: "40px" }}></img>
                  </th>

                  <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                    <font color="#8B0000">PRODUCTION SKILL MATRIX</font>
                  </th>
                  <th>
                    <span
                    style={{ marginRight: "100px", float: "left", width: "50%" }}
                    >
                      FORMAT NO :
                    </span>
                    <label>{"UPCL/HRD/D/03"}</label>

                    <span
                    style={{ marginRight: "40px", float: "left", width: "40%" }}
                    >
                      REV NO :
                    </span>
                    <label>{"1"}</label>

                    <span
                    style={{ marginRight: "0px", float: "left", width: "55%" }}
                    >
                      REV DATE :
                    </span>
                    <label style={{textAlign:"end",paddingRight:"4%"}}>{"19.01.2016"}</label>
                  </th>
                </tr>
                <tr>
                  <td colSpan={2} className="topic-head">
                    Name :{" "}
                  </td>
                  <td>
                    <DropdownFetcher
                      keyword="emp_details"
                      setValue={setEmployee}
                    />
                    <label>{Name}</label>
                    {/* <Select
                      styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                      value={Name}
                      isDisabled={true}
                      onChange={handleEmployee}
                      options={Employee.map((i) => ({
                        value: i.Emp_code,
                        label: i.Emp_name,
                      }))}
                    /> */}
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="topic-head">
                    {" "}
                    Photo :
                  </td>
                  <td>
                    {Photo && (
                      <img className="img" src={Photo} alt="Selected" />
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="topic-head">
                    Designation :
                  </td>
                  <td>
                    <input
                      type="text"
                      value={Desn}
                      disabled
                      name="Desn"
                    />
                  </td>{" "}
                </tr>

                <tr>
                  {" "}
                  <td colSpan={2} className="topic-head">
                    Knowledge in 3C & 55 :
                  </td>
                  <td>
                    <Scores
                    isDisable={true}
                      setpoint={(name,value)=>{

                        setKnw3C55(value)
                      setcummpoint(false);
                    }}
                      name={"Knw3C55"}
                      value={Knw3C55}
                    />
                  </td>
                </tr>
                <tr>
                  {" "}
                  <td colSpan={2} className="topic-head">
                    RM Knowledge :
                  </td>
                  <td>
                    <Scores isDisable={true} value={Rmknw} name={"Rmknw"} setpoint={(name,value)=>{
                        setRmknw(value)
                      setcummpoint(false);
                    }} />
                  </td>
                </tr>
                <tr>
                  {" "}
                  <td colSpan={2} className="topic-head">
                    Communication Skill :
                  </td>
                  <td>
                    <Scores
                    isDisable={true}
                      value={CommSkill}
                      name={"CommSkill"}
                      setpoint={(name,value)=>{
                        setCommSkill(value)
                      setcummpoint(false);
                    }}
                    />
                  </td>
                </tr>
                <tr>
                  {" "}
                  <td colSpan={2} className="topic-head">
                    Product Knowledge :
                  </td>
                  <td>
                    <Scores
                    isDisable={true}
                      value={ProKnw}
                      name={"ProKnw"}
                      setpoint={(name,value)=>{
                        setProKnw(value)
                      setcummpoint(false);
                    }}
                    />
                  </td>
                </tr>
                <tr>
                  {" "}
                  <td colSpan={2} className="topic-head">
                    Hourly Production Monitoring :
                  </td>
                  <td>
                    <Scores
                    isDisable={true}
                      value={HourMon}
                      name={"HourMon"}
                      setpoint={(name,value)=>{
                        setHourMon(value)
                      setcummpoint(false);
                    }}
                    />
                  </td>
                </tr>
                <tr>
                  {" "}
                  <td colSpan={2} className="topic-head">
                    Document Knowledge :
                  </td>
                  <td>
                    <Scores
                    isDisable={true}
                      value={DocKnw}
                      name={"DocKnw"}
                      setpoint={(name,value)=>{
                        setDocKnw(value)
                      setcummpoint(false);
                    }}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="topic-head">
                    Cumulative Points :
                  </td>
                  <td>
                    {cummpoint ? (
                      <input
                        type="text"
                        value={Cumpoint}
                        name="Cumpoint"
                        disabled
                      />
                    ) : (
                      <div
                        onClick={handlecummpoint}
                        style={{
                          width: "98%",
                          height: "98%",
                          backgroundColor: "#96d4d4",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "center",

                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <h4>Populate</h4>
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  {" "}
                  <td colSpan={2} className="topic-head">
                    Level Classification :
                  </td>
                  <td>
                    <input
                      type="text"
                      value={LvlCls}
                      onChange={setLvlcls}
                      name="LvlCls"
                    />
                  </td>
                </tr>
                <tr>
                  {" "}
                  <td colSpan={2} className="topic-head">
                    Evaluated On :
                  </td>
                  <td>
                    <DatePicker value={EvlOn} onChange={handleDateChange} isDisable={true}/>
                  </td>
                </tr>
                <tr>
                  {" "}
                  <td colSpan={2} className="topic-head">
                    Next Evaluated On :
                  </td>
                  <td>
                    <DatePicker value={nxtEvlOn} onChange={handlenextDateChange} isDisable={true}/>

                  </td>
                </tr>
                <tr>
              <td colSpan={2} className="topic-head">
                Prepared By :{" "}
              </td>
              <td>
                <label>{preparedby}</label>
                {/* <Select
                  styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                  value={preparedby}
                  onChange={handleprepared}
                  isDisabled={true}
                  options={Employee.map((i) => ({
                    value: i.Emp_code,
                    label: i.Emp_name,
                  }))}
                /> */}
              </td>
            </tr>

                {/* <tr>
                  <td colSpan="5" align="center" style={{ border: "none" }}>
                    <button type="submit" onClick={(e) => handleUpdate(e)}>
                      Update
                    </button>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </form>
      </Popup>

      <Popup
        trigger={ButtonPopup}
        setTrigger={setButtonPopup}
        viewPagination={viewPagination}
      >
        <div style={{position:'relative'}}>
          <div
            style={{
              display: "flex",
              marginRight: "50px",
              position: "fixed",
              right: "35px",
              width:"20%",
              flexDirection: "column",
              borderRadius: "5px",
              overflow: "hidden",
              backgroundColor: "#96d4d4",
              border: "1px solid black",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "50px",
                padding: "5px 40px",
                borderBottom: "2px solid black",
              }}
            >
              <img className="pro_skill_img" src={"/0.png"} alt="image" />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h3>Point - 0</h3>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "50px",
                padding: "5px 40px",
                borderBottom: "2px solid black",
              }}
            >
              <img className="pro_skill_img" src={"/1.png"} alt="image" />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h3>Point - 1</h3>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "50px",
                padding: "5px 40px",
                borderBottom: "2px solid black",
              }}
            >
              <img className="pro_skill_img" src={"/2.png"} alt="image" />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h3>Point - 2</h3>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "50px",
                padding: "5px 40px",
                borderBottom: "2px solid black",
              }}
            >
              <img className="pro_skill_img" src={"/3.png"} alt="image" />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h3>Point - 3</h3>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "50px",
                padding: "5px 40px",
              }}
            >
              <img className="pro_skill_img" src={"/4.png"} alt="image" />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h3>Point - 4</h3>
              </div>
            </div>
          </div>
          <form
            id="proskill-formContainer-Create"
            name="proskill-formContainer-Create"
            className="form-proskill-Create"
          >
            <table
              style={{ textAlign: "center", marginLeft: "70px", width: "70%" }}
            >
              <tbody>
                <tr>
                  <th style={{ textAlign: "center", width: "10%" }}>
                    <img src={Unitechlogo} style={{ width: "40px" }}></img>
                  </th>

                  <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                    <font color="#8B0000">PRODUCTION SKILL MATRIX</font>
                  </th>
                  <th>
                    <span
                    style={{ marginRight: "100px", float: "left", width: "50%" }}
                    >
                      FORMAT NO :
                    </span>
                    <label>{"UPCL/HRD/D/03"}</label>

                    <span
                    style={{ marginRight: "40px", float: "left", width: "40%" }}
                    >
                      REV NO :
                    </span>
                    <label>{"1"}</label>

                    <span
                    style={{ marginRight: "0px", float: "left", width: "55%" }}
                    >
                      REV DATE :
                    </span>
                    <label style={{textAlign:"end",paddingRight:"4%"}}>{"19.01.2016"}</label>
                  </th>
                </tr>
                <tr>
                  <td colSpan={2} className="topic-head">
                    Name :{" "}<span style={{ color: "red" }}>*</span>
                  </td>
                  <td>
                    <DropdownFetcher
                      keyword="emp_details"
                      setValue={setEmployee}
                    />
                    <Select
                      styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                      value={Name}
                      onChange={handleEmployee}
                      options={Employee.map((i) => ({
                        value: i.Emp_code,
                        label: i.Emp_name,
                      }))}
                      isDisabled={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="topic-head">
                    {" "}
                    Photo :
                  </td>
                  <td>
                    {Photo && (
                      <img className="img" src={Photo} alt="Selected" />
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="topic-head">
                    Designation :
                  </td>
                  <td>
                    <input
                      type="text"
                      value={Desn}
                      onChange={setDesn}
                      name="Desn"
                    />
                  </td>{" "}
                </tr>

                <tr>
                  {" "}
                  <td colSpan={2} className="topic-head">
                    Knowledge in 3C & 55 :
                  </td>
                  <td>
                    <Scores
                      setpoint={(name,value)=>{

                        setKnw3C55(value)
                      setcummpoint(false);
                    }}
                      name={"Knw3C55"}
                      value={Knw3C55}
                    />
                  </td>
                </tr>
                <tr>
                  {" "}
                  <td colSpan={2} className="topic-head">
                    RM Knowledge :
                  </td>
                  <td>
                    <Scores value={Rmknw} name={"Rmknw"} setpoint={(name,value)=>{
                        setRmknw(value)
                      setcummpoint(false);
                    }} />
                  </td>
                </tr>
                <tr>
                  {" "}
                  <td colSpan={2} className="topic-head">
                    Communication Skill :
                  </td>
                  <td>
                    <Scores
                      value={CommSkill}
                      name={"CommSkill"}
                      setpoint={(name,value)=>{
                        setCommSkill(value)
                      setcummpoint(false);
                    }}
                    />
                  </td>
                </tr>
                <tr>
                  {" "}
                  <td colSpan={2} className="topic-head">
                    Product Knowledge :
                  </td>
                  <td>
                    <Scores
                      value={ProKnw}
                      name={"ProKnw"}
                      setpoint={(name,value)=>{
                        setProKnw(value)
                      setcummpoint(false);
                    }}
                    />
                  </td>
                </tr>
                <tr>
                  {" "}
                  <td colSpan={2} className="topic-head">
                    Hourly Production Monitoring :
                  </td>
                  <td>
                    <Scores
                      value={HourMon}
                      name={"HourMon"}
                      setpoint={(name,value)=>{
                        setHourMon(value)
                      setcummpoint(false);
                    }}
                    />
                  </td>
                </tr>
                <tr>
                  {" "}
                  <td colSpan={2} className="topic-head">
                    Document Knowledge :
                  </td>
                  <td>
                    <Scores
                      value={DocKnw}
                      name={"DocKnw"}
                      setpoint={(name,value)=>{
                        setDocKnw(value)
                      setcummpoint(false);
                    }}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="topic-head">
                    Cumulative Points :
                  </td>
                  <td>
                    {cummpoint ? (
                      <input
                        type="text"
                        value={Cumpoint}
                        name="Cumpoint"
                        disabled
                      />
                    ) : (
                      <div
                        onClick={handlecummpoint}
                        style={{
                          width: "98%",
                          height: "98%",
                          backgroundColor: "#96d4d4",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "center",

                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <h4>Populate</h4>
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  {" "}
                  <td colSpan={2} className="topic-head">
                    Level Classification :
                  </td>
                  <td>
                    <input
                      type="text"
                      value={LvlCls}
                      onChange={setLvlcls}
                      name="LvlCls"
                      disabled
                    />
                  </td>
                </tr>
                <tr>
                  {" "}
                  <td colSpan={2} className="topic-head">
                    Evaluated On :<span style={{ color: "red" }}>*</span>
                  </td>
                  <td>
                    <DatePicker value={EvlOn} onChange={handleDateChange} />
                    {EvlOn === '' && isCreating && <span className="Req">Required *</span>}

                  </td>
                </tr>
                <tr>
                  {" "}
                  <td colSpan={2} className="topic-head">
                    Next Evaluated On :<span style={{ color: "red" }}>*</span>
                  </td>
                  <td>
                    <DatePicker value={nxtEvlOn} onChange={handlenextDateChange} />
                    {nxtEvlOn === '' && isCreating && <span className="Req">Required *</span>}

                  </td>
                </tr>
                <tr>
              <td colSpan={2} className="topic-head">
                Prepared By :{" "}
              </td>
              <td>
                <Select
                  styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                  value={preparedby}
                  onChange={handleprepared}
                  options={Employee.map((i) => ({
                    value: i.Emp_code,
                    label: i.Emp_name,
                  }))}
                />
              </td>
            </tr>

                <tr>
                  <td colSpan="5" align="center" style={{ border: "none" }}>
                    <button type="submit" onClick={(e) => handleUpdate(e)}>
                      Update
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </Popup>

      <div>
        <table
          style={{ textAlign: "center", width: "100%" }}
          /*{ cellPadding="5" }*/
        >
          <thead>
            <tr>
              <th
                id="proskillcss-Title.List"
                name="proskill-Title.List"
                colSpan="12"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">List - PRODUCTION SKILL MATRIX </font>
              </th>
            </tr>
            <tr>
                {/* <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                  Date
                </td>
                <td colSpan="1">
                  <input
                  id="ProSkill-date"
                  name="ProSkill-date"
                    type="date"
                    placeholder="Filter by Date"
                    value={filterDate}
                    onChange={handleFilterDateChange}
                  />
                </td> */}
                
                <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                  Employee Name
                </td>
                <td colSpan={2}>
                  <input
                  id="ProSkill-EmpName"
                  name="ProSkill-EmpName"
                    type="text"
                    placeholder="Filter Employee Name"
                    style={{ fontSize: "70%", width: "100%" }}
                    value={filterMcno}
                    onChange={handleFilterMcnoChange}
                  />
                </td>
               
                <td colSpan="8"></td>
              </tr>
            <tr className="table_position">
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                Name
              </th>

              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                Designation
              </th>

              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
              Cumulative Points
              </th>

              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                Evaluated On
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                Next Evaluvation
              </th>

              <th className="table-list-head"></th>
              <th className="table-list-head"></th>
              <th className="table-list-head"></th>
            </tr>
          </thead>
          <tbody>
            {/* <HandleRead keyword="Production_skill" setData={setPro_skill} /> */}
            {filteredRecords.length === 0 ? (
              // <tr>
              <td colSpan="13" style={{ textAlign: "center" }}>
                <p
                  id="lump-list-NoRec"
                  name="lump-list-noRec"
                  style={{ fontWeight: "bold" }}
                >
                  No records found.
                </p>
              </td>
            ) : (
              // </tr>
              filteredRecords.map((skill) => (
                <tr className="table_view" key={skill.Id}>
                  <td className="table-cell">{skill.Name}-{skill.EmpName}</td>
                  <td className="table-cell">{skill.Designation}</td>
                  <td className="table-cell">{skill.Cum_Points}</td>
                  <td className="table-cell">{formatDt(skill.Eval_on)}</td>
                  <td className="table-cell">{formatDt(skill.Next_Eval_on)}</td>
                  <td>
                    <button
                      style={{ backgroundColor: "#cba423" }}
                      type="button"
                      onClick={() => handleView(skill)}
                    >
                      View&#x1F441;
                    </button>
                  </td>
                  <td>
                    <button type="button" onClick={() => handleEdit(skill)}>
                      Edit&#x270E;
                    </button>
                  </td>
                  <td>
                    <button
                      id={`Production_skill-Delete`}
                      name={`Production_skill-Delete`}
                      style={{whiteSpace:"nowrap" , backgroundColor:"#DA251A"}}
                      type="button"
                      onClick={() => {
                        const userConfirmed = window.confirm('Are you sure you want to delete?');
                        if (userConfirmed) {                          
                          UpdateRecord({
                            _keyword_: 'Production_skill',
                            data: [
                              {
                                Id: skill.Id,
                                ActiveStatus: 'InActive',
                              },
                            ],
                          }).then(()=>{
                            handleReadProSkillMatrix();
                          })
                          // window.location.reload();
                        }
                      }}
                    >
                      Delete<MdDelete/>
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <div className="pagination" id="pagination">
          {isPaginationVisible && filteredData.length > 0 && (
            <Pagination
              currentPage={currentPage}
              npage={npage}
              prePage={prePage}
              nextPage={nextPage}
              changeCpage={changeCpage}
              numbers={numbers}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductionSkillView;
