import axios from "axios";
import { API_URL, BUILD_RESULTS_GENERIC } from "../Utils/constants";
import { getAPIRequest } from "../Utils/APIUtility";

const GetRecord = async (data) => {
  let success = false;
  let rows = "0";
  const response = await getAPIRequest(BUILD_RESULTS_GENERIC, "POST", data);
  const response1 = await response.json();
//   console.log(response1);
//   console.log(data._keyword_,response1.data);
  if (response1.rows == 0) {
    success = response1.success;
    return {data:["NoRecordFound"],row:response1.rows};
  } else {
    rows = response1.rows;
    success = response1.success;
    if(success){
      return {data:response1.data,row:response1.rows}
    }
  }
  if (!success) {
    return ["false"];
  } else if (rows == 0) {
    
  }
};

export default GetRecord;
