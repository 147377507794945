import axios from "axios"
import { BUILD_RESULTS_GENERIC_PAINT, BUILD_RESULTS_GENERIC_QUARY_PAINT } from "../../Utils/constants"

const ReadRecordPaint = async(Input,apiType) => {
    
    if(Input._keyword_===undefined){
        return axios
        .post(BUILD_RESULTS_GENERIC_QUARY_PAINT, {
            "sql-query": Input,
          "sql-query-params": [],
          })
        .then(res => {
            console.log("ReadRecordPaint",Input,res.data);
            return res.data
        })
        .catch(error => {
            console.log(error);
            return {data:[{count:'',rows:''}]}
        })
    }else{
        return axios
        .post(BUILD_RESULTS_GENERIC_PAINT, Input)
        .then(res => {
            console.log("ReadRecordPaint",Input,res.data);
            return res.data
        })
        .catch(error => {
            console.log(error);
            return {data:[{count:'',rows:''}]}
        })
    }
    
}
export default ReadRecordPaint