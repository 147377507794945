import PmsReportView from "../../../Components/ListView/PmsReportView";
import { CURRENT_Date } from "../../../Utils/GeneralConstants";
import QaLayout from "../../Quality/QaLayout";

function PmsReport() {
    return (
       <QaLayout>
       <PmsReportView
        Title={"Report - Process Monitoring System"}
        standard={"1 = 1"}
        //quary={"SELECT distinct pms.id as Id,pms.CreatedDate,pms.TrackDate,md.mc_code, md.mc_mac_bay, IFNULL(tm.Track_7_am,'') as Track_7_am, IFNULL(tm.Track_11_am,'') as Track_11_am, IFNULL(tm.Track_15_pm,'') as Track_15_pm, IFNULL(tm.Track_19_pm,'') as Track_19_pm, IFNULL(tm.Track_23_pm,'') as Track_23_pm, IFNULL(tm.Track_3_am,'') as Track_3_am from pro_mon_sys_parent pms right join machine_details md on md.mc_code = pms.Mcno and pms.ActiveStatus='1' left join pro_mon_sys_tracking_machine tm on pms.Id = tm.Pms_id and tm.Time_to_be_updated='Part name'"}
        quary= {"SELECT DISTINCT pms.id AS Id,pms.CreatedDate,pms.TrackDate,md.mc_code, md.mc_mac_bay,IFNULL(tm.Track_7_am, '') AS Track_7_am, IFNULL(tm.Track_11_am, '') AS Track_11_am,IFNULL(tm.Track_15_pm, '') AS Track_15_pm,IFNULL(tm.Track_19_pm, '') AS Track_19_pm,IFNULL(tm.Track_23_pm, '') AS Track_23_pm,IFNULL(tm.Track_3_am, '') AS Track_3_am FROM pro_mon_sys_parent pms RIGHT JOIN machine_details md ON md.mc_code = pms.Mcno LEFT JOIN pro_mon_sys_tracking_machine tm ON pms.Id = tm.Pms_id group by pms.id"}
        table={"machine_details md"}
        tableDelete={"pro_mon_sys_parent"}
        navigation={"Pms"}
        filter={["md.mc_mac_bay","md.mc_code"]}
        dateFilter={"pms.CreatedDate"}
        // DefaultDateFilter={{start:CURRENT_Date,end:""}}
        header={[
          { value: "pms.TrackDate", label: "TrackDate" },
          { value: "md.mc_mac_bay", label: "Bay" },
          { value: "md.mc_code", label: "Machine No" },
          { value: "tm.Track_7_am", label: "07.00 AM - 08.00 AM" },
          { value: "tm.Track_11_am", label: "11.00 AM - 12.00 AM" },
          { value: "tm.Track_15_pm", label: "15.00 PM - 16.00 PM" },
          { value: "tm.Track_19_pm", label: "19.00 PM - 20.00 PM" },
          { value: "tm.Track_23_pm", label: "23.00 PM - 01.00 AM" },
          { value: "tm.Track_3_am", label: "03.00 AM - 04.00 AM" },
          ]}
          />
        </QaLayout>
        )
        }

export default PmsReport;