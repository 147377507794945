import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { API_URL } from "../../../Utils/constants.js";
import Select from "react-select";
import DatePicker from "../../../Lib/DatePicker.js";
import "../../../Styles/Part3.css";
import GetRecord from "../../../Lib/GetRecord.js";
import useScanDetection from "use-scan-detection";
import { setMessage } from "../../../Lib/SetMessage.js";
import CreateApproval from "../../../Lib/CreateApproval.js";
import ReadRecord from "../../../Lib/API/ReadRecord.js";
import { recordExpression } from "@babel/types";
import { CURRENT_Date } from "../../../Utils/GeneralConstants.js";
import CreateRecord from "../../../Lib/API/CreateRecord.js";
import UpdateRecord from "../../../Lib/API/UpdateRecord.js";
import { useNavigate, useParams } from "react-router-dom";
import QaLayout from "../../Quality/QaLayout.js";
import Field from "../../../Components/Fields/Field.js";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Dropdown } from "react-bootstrap";
import Logo from "../../../icons/Logo1.png";
import DeleteOriginalRecord from "../../../Lib/API/DeleteOriginalRecord.js";

function PmsCreate() {
  const Navigate = useNavigate();
  const { option, id } = useParams();
  const InitRecord = {
    EmpDetails: [],
    Parts: [],
    Disable: {},
    Machines: [],
    SMP: {},
    InsertRecord: false,
    NewRecord: "",
    MouldChange: [],

    MachineNo: [],
    ShiftInchargeC: [{}],
    ShiftInchargeA: [{}],
    ShiftInchargeB: [{}],
    LineInspecQaC: [{}],
    LineInspecQaB: [{}],
    LineInspecQaA: [{}],

    MouldChangeHour: "",
    PW31: "",
    CoreSet: "",
    PW2: "",
    PW1: "",
    PW4: "",
    PW3: "",
    PW6: "",
    PW5: "",
    TrackDate: CURRENT_Date,
    Cavity: "",
    Tempcore3: "",
    Tempcore4: "",
    Tempcore5: "",
    Tempcore6: "",
    Core: "",
    PW21: "",
    PW61: "",
    Injection: [
      {
        Inject_inst_used: "On pannel",
        Inject_desc: "Cycle time",
        Inject_sl: "1",
        Inject_units: "SEC",
        Inject_actual_1: "",
        Inject_actual_2: "",
        Inject_actual_3: "",
        Inject_actual_4: "",
        Inject_actual_5: "",
        Inject_actual_6: "",
      },
      {
        Inject_inst_used: "On pannel",
        Inject_desc: "Cooling time",
        Inject_sl: "2",
        Inject_units: "SEC",
        Inject_actual_1: "",
        Inject_actual_2: "",
        Inject_actual_3: "",
        Inject_actual_4: "",
        Inject_actual_5: "",
        Inject_actual_6: "",
      },
    ],
    Heat: [
      {
        Heat_sl: "1",
        Heat_desc: "MTC Temperature",
        Heat_inst_used: "On pannel",
        Heat_actual_1: "",
        Heat_actual_2: "",
        Heat_actual_3: "",
        Heat_actual_4: "",
        Heat_actual_5: "",
        Heat_actual_6: "",
      },
      {
        Heat_sl: "2",
        Heat_desc: "Nozzle Temperature",
        Heat_inst_used: "On pannel",
        Heat_actual_1: "",
        Heat_actual_2: "",
        Heat_actual_3: "",
        Heat_actual_4: "",
        Heat_actual_5: "",
        Heat_actual_6: "",
      },
      {
        Heat_sl: "3",
        Heat_desc: "Hopper Temperature",
        Heat_inst_used: "On pannel",
        Heat_actual_1: "",
        Heat_actual_2: "",
        Heat_actual_3: "",
        Heat_actual_4: "",
        Heat_actual_5: "",
        Heat_actual_6: "",
      },
    ],
    Tempcav5: "",
    Tempcav4: "",
    PW11: "",
    Tempcav6: "",
    Tracking: [
      {
        Time_to_be_updated: "Actual updated time",
        Track_7_am: "",
        Track_11_am: "",
        Track_15_pm: "",
        Track_19_pm: "",
        Track_23_pm: "",
        Track_3_am: "",
      },
      {
        Time_to_be_updated: "Part name",
        Track_7_am: "",
        Track_11_am: "",
        Track_15_pm: "",
        Track_19_pm: "",
        Track_23_pm: "",
        Track_3_am: "",
      },
      {
        Time_to_be_updated: "Colour",
        Track_7_am: "",
        Track_11_am: "",
        Track_15_pm: "",
        Track_19_pm: "",
        Track_23_pm: "",
        Track_3_am: "",
      },
      {
        Time_to_be_updated: "Material used",
        Track_7_am: "",
        Track_11_am: "",
        Track_15_pm: "",
        Track_19_pm: "",
        Track_23_pm: "",
        Track_3_am: "",
      },
      {
        Time_to_be_updated: "Material grade",
        Track_7_am: "",
        Track_11_am: "",
        Track_15_pm: "",
        Track_19_pm: "",
        Track_23_pm: "",
        Track_3_am: "",
      },
    ],
    PW51: "",
    PartWeightMinus1: "",
    PartWeightMinus2: "",
    PartWeightPlus2: "",
    PartWeightPlus1: "",
    Mcno: "",
    RM2: "",
    RM1: "",
    RM4: "",
    RM3: "",
    RM6: "",
    PartWeight: "",
    RM5: "",
    ShiftInchargeShiftC: "",
    ShiftInchargeShiftB: "",
    Tempcav1: "",
    ShiftInchargeShiftA: "",
    Tempcav3: "",
    Tempcav2: "",
    PW41: "",
    Tempcore1: "",
    Tempcore2: "",
    CavSet: "",
    PrtSet: "",
    LineInspecQaShiftA: "",
    PartWeight2: "",
    PartWeight1: "",
    LineInspecQaShiftC: "",
    LineInspecQaShiftB: "",
    Mtc: true,
    Two_k_part: "0",
  };
  const [Records, setRecords] = useState(InitRecord);
  const RawTracking = {
    Track_7_am: false,
    Track_11_am: false,
    Track_15_pm: false,
    Track_19_pm: false,
    Track_23_pm: false,
    Track_3_am: false,
  };

  const [RawMat, setRawMat] = useState(RawTracking);
  const [RawMatDropDown, setRawMatDropDown] = useState([]);
  function InvalidCharacters(value) {
    return /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/.test(value);
  }
  function UpdateObject(Key, value) {
    setRecords((Record) => {
      return { ...Record, [Key]: value };
    });
  }
  function UpdateIndex(object, index, key, value) {
    setRecords((Record) => {
      Record[object][index][key] = value;
      return { ...Record };
    });
  }
  function UpdateIndexAsFunction(object) {
    setRecords((Record) => {
      Record = object(Record);
      return { ...Record };
    });
  }

  const time = new Date(
    new Date().getTime() - new Date().getTimezoneOffset() * 60000
  )
    .toISOString()
    .split("T")[1]
    .substring(0, 5);

  useEffect(() => {
    document.title = "Process Monitoring System";
    if (id) {
      ReadRecord({
        _keyword_: "Get_ALL_PMS_by_ID",
        Id: id,
      }).then((res) => {
        let records = res.data[0];
        records.EmpDetails = [];
        records.Parts = [];
        records.Machines = [];
        if (option === "view") {
          records.Disable = {
            Hour1: true,
            Hour2: true,
            Hour3: true,
            Hour4: true,
            Hour5: true,
            Hour6: true,
          };
        } else {
          records.Disable = {
            Hour1: false,
            Hour2: false,
            Hour3: false,
            Hour4: false,
            Hour5: false,
            Hour6: false,
          };
        }
        records.SMP = {};
        (records.InsertRecord = false), (records.NewRecord = false);

        let check = [
          "Track_7_am",
          "Track_11_am",
          "Track_15_pm",
          "Track_19_pm",
          "Track_23_pm",
          "Track_3_am",
        ];
        let result = check.map((item, index) => {
          if (records.Tracking[1][item] !== "") return item;
        });
        let filter = result.filter((item) => item != undefined);
        filter = filter
          .map((item) =>
            ["IDEAL", "MOULD CHANGE"].includes(records.Tracking[1][item])
              ? undefined
              : records.Tracking[1][item]
          )
          .filter((item) => item != undefined)[0];
        records.Parts = [{ partname: filter }];

        setRecords(records);
      });
    } else {
      UpdateObject("Disable", {
        Hour1: true,
        Hour2: true,
        Hour3: true,
        Hour4: true,
        Hour5: true,
        Hour6: true,
      });
    }
  }, [option, id]);

  useEffect(() => {
    console.log("====================================");
    console.log("Records Deteils - ", Records);
    console.log("====================================");
    console.log("RawMat Deteils - ", RawMat);
    console.log("====================================");

  }, [RawMat]);

  useEffect(() => {
    LoadParts([
      "Track_7_am",
      "Track_11_am",
      "Track_15_pm",
      "Track_19_pm",
      "Track_23_pm",
      "Track_3_am",
    ]);
  }, [Records.Mcno]);

  useEffect(() => {
    // if (activeKey === "three") {
    //   document.getElementById("1-Refilsetting_pressure").focus();
    // }
    const fieldOptions = {
      Heat_actual_1: ["", "Heat_actual_2"],
      Heat_actual_2: ["Heat_actual_1", "Heat_actual_3"],
      Heat_actual_3: ["Heat_actual_2", "Heat_actual_4"],
      Heat_actual_4: ["Heat_actual_3", "Heat_actual_5"],
      Heat_actual_5: ["Heat_actual_4", "Heat_actual_6"],
      Heat_actual_6: ["Heat_actual_5", ""],
      Inject_actual_1: ["", "Inject_actual_2"],
      Inject_actual_2: ["Inject_actual_1", "Inject_actual_3"],
      Inject_actual_3: ["Inject_actual_2", "Inject_actual_4"],
      Inject_actual_4: ["Inject_actual_3", "Inject_actual_5"],
      Inject_actual_5: ["Inject_actual_4", "Inject_actual_6"],
      Inject_actual_6: ["Inject_actual_5", ""],
    };
    const handleInject = (event) => {
      let activeField = document.activeElement;
      let row = Records.Injection.length;
      let currentField = activeField.id.split("-")[1];
      let indexOfField = activeField.id.split("-")[0];
      let nextField = "";
      if (currentField != undefined) {
        switch (event.key) {
          case "ArrowRight":
            nextField = fieldOptions[currentField][1];
            if (indexOfField > 0 && indexOfField < row + 1) {
              if (nextField != "") {
                document.getElementById(indexOfField + "-" + nextField).focus();
              } else {
                if (indexOfField < row) {
                  document
                    .getElementById(
                      Number(indexOfField) + 1 + "-Inject_actual_1"
                    )
                    .focus();
                } else if (currentField === "Inject_actual_6") {
                  document.getElementById("Tempcore1").focus();
                }
              }
            } else {
              if (indexOfField == row + 1) {
                document
                  .getElementById(row + 2 + "-Refilsetting_position")
                  .focus();
              }
            }
            break;

          case "ArrowLeft":
            nextField = fieldOptions[currentField][0];
            if (
              indexOfField > 0 &&
              indexOfField < row + 1 &&
              activeField.id != "1-Inject_actual_1"
            ) {
              if (nextField != "") {
                document.getElementById(indexOfField + "-" + nextField).focus();
              } else {
                document
                  .getElementById(indexOfField - 1 + "-Inject_actual_6")
                  .focus();
              }
            } else {
              document
                .getElementById(Records.Heat.length + "-Heat_actual_6")
                .focus();
            }
            break;

          case "ArrowDown":
            // console.log("indexOfField",indexOfField,row,indexOfField == row);
            if (indexOfField < row) {
              document
                .getElementById(Number(indexOfField) + 1 + "-" + currentField)
                .focus();
            } else if (indexOfField == row) {
              document.getElementById("Tempcore1").focus();
            }
            break;

          case "ArrowUp":
            if (indexOfField > 1) {
              document
                .getElementById(Number(indexOfField) - 1 + "-" + currentField)
                .focus();
            } else {
              // console.log(
              //   "AgetElementById",
              //   Records.Heat.length +
              //     "-Heat_actual_" +
              //     currentField[currentField.length - 1]
              // );
              document
                .getElementById(
                  Records.Heat.length +
                    "-Heat_actual_" +
                    currentField[currentField.length - 1]
                )
                .focus();
            }
            break;

          default:
            break;
        }
      }
    };
    const handleHeat = (event) => {
      let activeField = document.activeElement;
      let row = Records.Heat.length;
      let currentField = activeField.id.split("-")[1];
      let indexOfField = activeField.id.split("-")[0];
      let nextField = "";
      if (currentField != undefined) {
        switch (event.key) {
          case "ArrowRight":
            nextField = fieldOptions[currentField][1];
            // console.log("nextField", nextField, currentField);
            if (indexOfField > 0 && indexOfField < row + 1) {
              if (nextField != "") {
                document.getElementById(indexOfField + "-" + nextField).focus();
              } else {
                if (indexOfField < row) {
                  document
                    .getElementById(Number(indexOfField) + 1 + "-Heat_actual_1")
                    .focus();
                } else {
                  document.getElementById("1-Inject_actual_1").focus();
                }
              }
            }
            break;

          case "ArrowLeft":
            nextField = fieldOptions[currentField][0];
            if (
              indexOfField > 0 &&
              indexOfField < row + 1 &&
              activeField.id != "1-Heat_actual_1"
            ) {
              if (nextField != "") {
                document.getElementById(indexOfField + "-" + nextField).focus();
              } else {
                document
                  .getElementById(indexOfField - 1 + "-Heat_actual_6")
                  .focus();
              }
            }
            break;

          case "ArrowDown":
            if (indexOfField < row) {
              document
                .getElementById(Number(indexOfField) + 1 + "-" + currentField)
                .focus();
            } else {
              document
                .getElementById(
                  "1-Inject_actual_" + currentField[currentField.length - 1]
                )
                .focus();
            }

            break;

          case "ArrowUp":
            if (indexOfField > 1) {
              document
                .getElementById(Number(indexOfField) - 1 + "-" + currentField)
                .focus();
            }
            break;

          default:
            break;
        }
      }
    };

    // const handleMould = (event) => {
    //   let activeField = event.target;
    //   let currentField = activeField.name;
    //   let nextField = "";
    //   console.log("Current Field:", currentField);
    //   console.log("Event Key:", event.key);

    //   switch (event.key) {
    //     case "ArrowRight":
    //       nextField = fieldOptions[currentField][1];
    //       console.log("Next Field:", nextField);
    //       if (nextField !== "") {
    //         document.getElementsByName(nextField)[0].focus();
    //       }
    //       break;
    //     case "ArrowLeft":
    //       nextField = fieldOptions[currentField][0];
    //       console.log("Next Field:", nextField);
    //       if (nextField !== "") {
    //         document.getElementsByName(nextField)[0].focus();
    //       }
    //       break;
    //     default:
    //       break;
    //   }
    // };

    function handleAction(event) {
      let activeField = document.activeElement;
      activeField = activeField.id;
      activeField = activeField.split("-")[1] || "";

      activeField = activeField.substring(0, activeField.length - 2);

      // console.log("activeField", activeField);

      if (activeField === "Heat_actual") {
        handleHeat(event);
      } else if (activeField === "Inject_actual") {
        handleInject(event);
      }
      // } else if (activeField.startsWith("Tempcore") || activeField.startsWith("Tempcav")) {
      //   handleMould(event);
      // }
    }
    document.addEventListener("keydown", handleAction);
    return () => {
      document.removeEventListener("keydown", handleAction);
    };
  }, [Records]);

  const [dropdown1, setdropdown1] = useState(false);
  // const [dropdown2, setdropdown2] = useState(false);

  const dropdownRef1 = useRef(null);
  // const dropdownRef2 = useRef(null);
  function HandleRecordsBoolean(Event) {
    setRawMat(RawTracking);
    ResetRecords();
    if (Event.target.checked == true) {
      UpdateObject("Two_k_part", "1");
    } else {
      UpdateObject("Two_k_part", "0");
    }
  }
  useEffect(() => {
    if (dropdown1) {
      dropdownRef1.current.focus();
      setdropdown1(false);
    }
    // if (dropdown2) {
    //   dropdownRef2.current.focus();
    //     setdropdown2(false);
    // }
  }, [dropdown1]);

  function LoadSMP(Partname, RawMaterial) {
    UpdateObject("Heat", [
      {
        Heat_sl: "1",
        Heat_desc: "MTC Temperature",
        Heat_inst_used: "On pannel",
        Heat_actual_1: "",
        Heat_actual_2: "",
        Heat_actual_3: "",
        Heat_actual_4: "",
        Heat_actual_5: "",
        Heat_actual_6: "",
      },
      {
        Heat_sl: "2",
        Heat_desc: "Nozzle Temperature",
        Heat_inst_used: "On pannel",
        Heat_actual_1: "",
        Heat_actual_2: "",
        Heat_actual_3: "",
        Heat_actual_4: "",
        Heat_actual_5: "",
        Heat_actual_6: "",
      },
      {
        Heat_sl: "3",
        Heat_desc: "Hopper Temperature",
        Heat_inst_used: "On pannel",
        Heat_actual_1: "",
        Heat_actual_2: "",
        Heat_actual_3: "",
        Heat_actual_4: "",
        Heat_actual_5: "",
        Heat_actual_6: "",
      },
    ]);
    UpdateObject("Injection", [
      {
        Inject_inst_used: "On pannel",
        Inject_desc: "Cycle time",
        Inject_sl: "1",
        Inject_units: "SEC",
        Inject_actual_1: "",
        Inject_actual_2: "",
        Inject_actual_3: "",
        Inject_actual_4: "",
        Inject_actual_5: "",
        Inject_actual_6: "",
      },
      {
        Inject_inst_used: "On pannel",
        Inject_desc: "Cooling time",
        Inject_sl: "2",
        Inject_units: "SEC",
        Inject_actual_1: "",
        Inject_actual_2: "",
        Inject_actual_3: "",
        Inject_actual_4: "",
        Inject_actual_5: "",
        Inject_actual_6: "",
      },
    ]);
    console.log("LoadSMP", RawMaterial);
    ReadRecord({
      _keyword_: "Get_SMP_by_Partname_MachineCode",
      Partname: Partname,
      Machineno: Records.Mcno,
      RawMaterial: `%${RawMaterial}%`,
    }).then((res) => {
      console.log("LoadSMP", res);
      let SMP = res.data[0];
      UpdateObject("SMP", SMP);
      UpdateIndexAsFunction((record) => {
        record.PartWeightMinus1 = SMP.Part_weight_set_minus_1;
        record.PartWeightPlus1 = SMP.Part_weight_set_plus_1;
        record.PartWeightMinus2 = SMP.Part_weight_set_minus_2;
        record.PartWeightPlus2 = SMP.Part_weight_set_plus_2;
        record.PartWeight1 = SMP.Part_weight_1;
        record.PartWeight2 = SMP.Part_weight_2;
        record.Heat[1].Heat_smp_data = SMP.temperature_function[0]?.Temp_value;
        record.Heat[1].Heat_smp_set = SMP.temperature_function[0]?.Temp_set;
        record.Heat[2].Heat_smp_data = SMP.temperature_function[1]?.Temp_value;
        record.Heat[2].Heat_smp_set = SMP.temperature_function[1]?.Temp_set;
        record.Mtc =
          SMP.mouldtempdetails_function[2]?.Mould_temp_details === "MTC Temp";

        if (
          SMP.mouldtempdetails_function[2]?.Mould_temp_value != undefined &&
          SMP.mouldtempdetails_function[2]?.Mould_temp_value != "" &&
          SMP.mouldtempdetails_function[2]?.Mould_temp_set != undefined &&
          SMP.mouldtempdetails_function[2]?.Mould_temp_set != ""
        ) {
          record.Heat[0].Heat_smp_data =
            SMP.mouldtempdetails_function[2]?.Mould_temp_value;
          record.Heat[0].Heat_desc =
            SMP.mouldtempdetails_function[2]?.Mould_temp_details;
          record.Heat[0].Heat_smp_set =
            SMP.mouldtempdetails_function[2]?.Mould_temp_set;
        }

        SMP.temperature_function.map((item, index) => {
          if (index > 1) {
            record.Heat.push({
              Heat_inst_used: "On pannel",
              Heat_smp_data: item.Temp_value,
              Heat_smp_set: item.Temp_set,
              Heat_desc: item.Temperature_stages,
              Heat_sl: index + 2,
            });
          }
        });

        let backpres = SMP.backpres_function.filter((item) =>
          ["Cooling Time(SEC)*", "Cycle Time(SEC)"].includes(
            item.Back_press_stages
          )
        );

        backpres.map((item, index) => {
          let option = { ["Cooling Time(SEC)*"]: 1, ["Cycle Time(SEC)"]: 0 };
          if (
            item.Back_press_set != undefined &&
            item.Back_press_set != "" &&
            item.Back_press_time != undefined &&
            item.Back_press_time != ""
          ) {
            record.Injection[option[item.Back_press_stages]].Inject_smp_set =
              item.Back_press_set;
            record.Injection[option[item.Back_press_stages]].Inject_smp_data =
              item.Back_press_time;
          }
        });
        let options = [
          "Injection_time",
          "Injection_pressure",
          "Injection_speed",
        ];
        options.map((Option, index) => {
          SMP.injection_functional.map((item, indx) => {
            if (
              indx !== 0 &&
              indx != SMP.injection_functional.length - 1 &&
              indx != SMP.injection_functional.length - 2 &&
              SMP.injection_functional[0][Option] != undefined &&
              SMP.injection_functional[0][Option] != "" &&
              item[Option] != undefined &&
              item[Option] != ""
            ) {
              record.Injection.push({
                Inject_inst_used: "On pannel",
                Inject_desc: Option + " - " + indx,
                Inject_sl: record.Injection.length + 1,
                Inject_smp_set: SMP.injection_functional[0][Option],
                Inject_smp_data: item[Option],
                Inject_units: "SEC",
              });
            }
          });
        });

        let HoldingOptions = [
          "Holding_pressure",
          "Holding_speed",
          "Holding_time",
        ];
        HoldingOptions.map((option) => {
          SMP.holding_functional.map((item, index) => {
            if (
              index != 0 &&
              SMP.holding_functional[0][option] != undefined &&
              SMP.holding_functional[0][option] != "" &&
              item[option] != undefined &&
              item[option] != ""
            ) {
              record.Injection.push({
                Inject_inst_used: "On pannel",
                Inject_desc: option + " - " + index,
                Inject_sl: record.Injection.length + 1,
                Inject_smp_set: SMP.holding_functional[0][option],
                Inject_smp_data: item[option],
                Inject_units: "SEC",
              });
            }
          });
        });

        backpres = SMP.refilsetting_functional.filter(
          (item) =>
            ["Refill End/Short Size(MM)*:"].includes(
              item.Refilsetting_stages
            ) &&
            item.Refilsetting_position != undefined &&
            item.Refilsetting_position != ""
        )[0];
        // console.log("SMP.refilsetting_functional", backpres);

        backpres != undefined &&
          record.Injection.push({
            Inject_inst_used: "On pannel",
            Inject_desc: "Shot size",
            Inject_sl: record.Injection.length + 1,
            Inject_smp_set:
              SMP.refilsetting_functional[0].Refilsetting_position,
            Inject_smp_data: backpres.Refilsetting_position,
            Inject_units: "SEC",
          });

        backpres = SMP.backpres_function.filter(
          (item) =>
            ["Melt Cushion(MM)*"].includes(item.Back_press_stages) &&
            item.Back_press_position != undefined &&
            item.Back_press_position != "" &&
            item.Back_press_set != undefined &&
            item.Back_press_set != ""
        )[0];

        backpres != undefined &&
          record.Injection.push({
            Inject_inst_used: "On pannel",
            Inject_desc: "Cushion",
            Inject_sl: record.Injection.length + 1,
            Inject_smp_set: backpres.Back_press_set,
            Inject_smp_data: backpres.Back_press_position,
            Inject_units: "SEC",
          });

        record.CoreSet = SMP.mouldtempdetails_function[0]?.Mould_temp_set;
        record.Core = SMP.mouldtempdetails_function[0]?.Mould_temp_value;
        record.CavSet = SMP.mouldtempdetails_function[1]?.Mould_temp_set;
        record.Cavity = SMP.mouldtempdetails_function[1]?.Mould_temp_value;

        return record;
      });
    });
  }

  function LoadParts(check) {
    // console.log("LoadParts");
    // let check = [
    //   "Track_7_am",
    //   "Track_11_am",
    //   "Track_15_pm",
    //   "Track_19_pm",
    //   "Track_23_pm",
    //   "Track_3_am",
    // ];

    let result = check.map((item, index) => {
      // console.log("Return item ",Records.Tracking[1][item],"--", item );
      if (Records.Tracking[1][item] !== "") return item;
    });

    let filter = result.filter((item) => item != undefined);
    console.log("Testing3", result, filter, filter[filter.length - 1]);
    if (filter[filter.length - 1] === undefined) {
      ReadRecord(
        `SELECT distinct Part_name as partname FROM production_smp_parent where Machine_no='${Records.Mcno}' and Two_k_part='${Records.Two_k_part}' and ActiveStatus = '1'`
      ).then((res) => {
        UpdateObject("Parts", res.data[0].count > 0 ? res.data[0].rows : []);
      });
    } else {
      let part = Records.Tracking[1][filter[filter.length - 1]];
      //   let partname = result
      // console.log("Testing1", result, filter, "PArt - ",part);

      filter = filter
        .map((item) =>
          ["MOULD CHANGE", "new", "IDEAL"].includes(Records.Tracking[1][item])
            ? undefined
            : Records.Tracking[1][item]
        )
        .filter((item) => item != undefined)[0];
      // console.log("test99",filter);
      console.log("Testing1", result, filter, "PArt - ", part);
      if (part === "new" || part === "IDEAL") {
        ReadRecord(
          `SELECT distinct Part_name as partname FROM production_smp_parent where Machine_no='${Records.Mcno}' and Two_k_part='${Records.Two_k_part}' and ActiveStatus = '1'`
        ).then((res) => {
          UpdateObject("Parts", res.data[0].count > 0 ? res.data[0].rows : []);
        });
      } else {
        UpdateObject("Parts", [{ partname: filter }]);
      }
    }
  }
  function Handle_raw_mat_7am(Event, index, functionTrack, PreviousTrack) {
    // console.log("Handle_raw_mat_7am", Event.target.value);
    // let records = {...Records}
    (Records.ShiftInchargeC = [{}]),
      (Records.ShiftInchargeA = [{}]),
      (Records.ShiftInchargeB = [{}]),
      (Records.LineInspecQaC = [{}]),
      (Records.LineInspecQaB = [{}]),
      (Records.LineInspecQaA = [{}]),
      (Records.ShiftInchargeShiftC = ""),
      (Records.ShiftInchargeShiftB = ""),
      (Records.ShiftInchargeShiftA = ""),
      (Records.LineInspecQaShiftA = ""),
      (Records.LineInspecQaShiftC = ""),
      (Records.LineInspecQaShiftB = ""),
      (Records.Tempcore3 = ""),
      (Records.Tempcore4 = ""),
      (Records.Tempcore5 = ""),
      (Records.Tempcore6 = ""),
      (Records.Tempcore1 = ""),
      (Records.Tempcore2 = ""),
      (Records.Tempcav5 = ""),
      (Records.Tempcav4 = ""),
      (Records.Tempcav3 = ""),
      (Records.Tempcav2 = ""),
      (Records.Tempcav1 = ""),
      (Records.Tempcav6 = "");
    Records.PW2 = "";
    Records.PW1 = "";
    Records.PW4 = "";
    Records.PW3 = "";
    Records.PW6 = "";
    Records.PW5 = "";
    Records.PW21 = "";
    Records.PW11 = "";
    Records.PW41 = "";
    Records.PW31 = "";
    Records.PW61 = "";
    Records.PW51 = "";
    Records.RM2 = "";
    Records.RM1 = "";
    Records.RM4 = "";
    Records.RM3 = "";
    Records.RM6 = "";
    Records.RM5 = "";
    delete Records.Id;
    const selectedValue = RawMatDropDown.find((option) => {
      return option.Raw_material_name === Event.target.value;
    });
    if (selectedValue) {
      let current = new Date();
      let start = new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      );
      let end = new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      );
      if (current.getHours() > 7) {
        end.setDate(end.getDate() + 1);
        start = start.toISOString().slice(0, 10) + " 08:00:00";
        end = end.toISOString().slice(0, 10) + " 08:00:00";
      } else {
        start.setDate(start.getDate() - 1);
        start = start.toISOString().slice(0, 10) + " 08:00:00";
        end = end.toISOString().slice(0, 10) + " 08:00:00";
      }
      ReadRecord(
        `select count(pms.id) as count,pms.id from pro_mon_sys_parent pms left join pro_mon_sys_Tracking_machine tm on pms.id = tm.pms_id where (tm.Track_7_am in ('${Records.Parts[0].partname}', '${selectedValue.rm_description}') or tm.Track_11_am  in ('${Records.Parts[0].partname}', '${selectedValue.rm_description}') or tm.Track_15_pm in ('${Records.Parts[0].partname}', '${selectedValue.rm_description}') or tm.Track_19_pm  in ('${Records.Parts[0].partname}', '${selectedValue.rm_description}') or tm.Track_23_pm in ('${Records.Parts[0].partname}', '${selectedValue.rm_description}') or tm.Track_3_am  in ('${Records.Parts[0].partname}', '${selectedValue.rm_description}')) and pms.CreatedDate between '${start}' and '${end}' and pms.ActiveStatus = "1" group by pms.id`
      ).then((res) => {
        let objrt = res.data[0].rows.find((obj) => obj.count == "2");
        console.log("objrt ", objrt, res);
        // console.log("Handle_raw_mat_7am", res.data[0]);
        if (objrt != undefined) {
          console.log("get pms id");
          ReadRecord({
            _keyword_: "Get_ALL_PMS_by_Mcno_and_raw_mat",
            Id: objrt.id,
            StartDate: start,
            EndDate: end,
          }).then((response) => {
            // console.log("response", response);
            if (response.rows > 0) {
              console.log("Get_ALL_PMS_by_Mcno_and_raw_mat by id");
              setRecords((records) => {
                // records = { ...Records };
                records = response.data[0];
                records.EmpDetails = [];
                records.Parts = Records.Parts;
                records.Machines = Records.Machines;
                records.Two_k_part = "1";
                records.SMP = {};
                records.InsertRecord = false;
                records.NewRecord = false;
                records.Mtc = true;

                records.Disable = {
                  Hour1: !(time > "08:00" && time < "12:00"),
                  Hour2: !(time > "12:00" && time < "16:00"),
                  Hour3: !(time > "16:00" && time < "20:00"),
                  Hour4: !(time > "20:00" && time < "24:00"),
                  Hour5: !(time > "24:00" || time < "04:00"),
                  Hour6: !(time > "04:00" && time < "08:00"),
                  // Hour1: false,
                  // Hour2: false,
                  // Hour3: false,
                  // Hour4: false,
                  // Hour5: false,
                  // Hour6: false,
                };
                records.Tracking[0][functionTrack] =
                  Records.Tracking[0][functionTrack];
                records.Tracking[1][functionTrack] =
                  Records.Tracking[1][functionTrack];
                records.Tracking[2][functionTrack] = selectedValue.Colour;
                records.Tracking[3][functionTrack] =
                  selectedValue.rm_description;
                records.Tracking[4][functionTrack] = selectedValue.Grade;

                console.log("records", records);
                return { ...records };
              });
            } else {
              console.log("Get_ALL_PMS_by_Mcno_and_raw_mat by id NOPE");
              UpdateIndex("Tracking", 2, functionTrack, selectedValue.Colour);
              UpdateIndex(
                "Tracking",
                3,
                functionTrack,
                selectedValue.rm_description
              );
              UpdateIndex("Tracking", 4, functionTrack, selectedValue.Grade);
              let track = [
                {
                  Time_to_be_updated: "Actual updated time",
                  Track_7_am: "",
                  Track_11_am: "",
                  Track_15_pm: "",
                  Track_19_pm: "",
                  Track_23_pm: "",
                  Track_3_am: "",
                },
                {
                  Time_to_be_updated: "Part name",
                  Track_7_am: "",
                  Track_11_am: "",
                  Track_15_pm: "",
                  Track_19_pm: "",
                  Track_23_pm: "",
                  Track_3_am: "",
                },
                {
                  Time_to_be_updated: "Colour",
                  Track_7_am: "",
                  Track_11_am: "",
                  Track_15_pm: "",
                  Track_19_pm: "",
                  Track_23_pm: "",
                  Track_3_am: "",
                },
                {
                  Time_to_be_updated: "Material used",
                  Track_7_am: "",
                  Track_11_am: "",
                  Track_15_pm: "",
                  Track_19_pm: "",
                  Track_23_pm: "",
                  Track_3_am: "",
                },
                {
                  Time_to_be_updated: "Material grade",
                  Track_7_am: "",
                  Track_11_am: "",
                  Track_15_pm: "",
                  Track_19_pm: "",
                  Track_23_pm: "",
                  Track_3_am: "",
                },
              ];
              track[0][functionTrack] = Records.Tracking[0][functionTrack];
              track[1][functionTrack] = Records.Tracking[1][functionTrack];
              track[2][functionTrack] = selectedValue.Colour;
              track[3][functionTrack] = selectedValue.rm_description;
              track[4][functionTrack] = selectedValue.Grade;
              UpdateObject("Tracking", track);
              LoadSMP(
                Records.Tracking[1][functionTrack],
                Event.target.value != "" ? Event.target.value : ""
              );
              Records.NewRecord = true;
            }
          });
        } else {
          console.log("if not this pms id");
          UpdateIndex("Tracking", 2, functionTrack, selectedValue.Colour);
          UpdateIndex(
            "Tracking",
            3,
            functionTrack,
            selectedValue.rm_description
          );
          UpdateIndex("Tracking", 4, functionTrack, selectedValue.Grade);
          let track = [
            {
              Time_to_be_updated: "Actual updated time",
              Track_7_am: "",
              Track_11_am: "",
              Track_15_pm: "",
              Track_19_pm: "",
              Track_23_pm: "",
              Track_3_am: "",
            },
            {
              Time_to_be_updated: "Part name",
              Track_7_am: "",
              Track_11_am: "",
              Track_15_pm: "",
              Track_19_pm: "",
              Track_23_pm: "",
              Track_3_am: "",
            },
            {
              Time_to_be_updated: "Colour",
              Track_7_am: "",
              Track_11_am: "",
              Track_15_pm: "",
              Track_19_pm: "",
              Track_23_pm: "",
              Track_3_am: "",
            },
            {
              Time_to_be_updated: "Material used",
              Track_7_am: "",
              Track_11_am: "",
              Track_15_pm: "",
              Track_19_pm: "",
              Track_23_pm: "",
              Track_3_am: "",
            },
            {
              Time_to_be_updated: "Material grade",
              Track_7_am: "",
              Track_11_am: "",
              Track_15_pm: "",
              Track_19_pm: "",
              Track_23_pm: "",
              Track_3_am: "",
            },
          ];
          track[0][functionTrack] = Records.Tracking[0][functionTrack];
          track[1][functionTrack] = Records.Tracking[1][functionTrack];
          track[2][functionTrack] = selectedValue.Colour;
          track[3][functionTrack] = selectedValue.rm_description;
          track[4][functionTrack] = selectedValue.Grade;
          UpdateObject("Tracking", track);
          LoadSMP(
            Records.Tracking[1][functionTrack],
            Event.target.value != "" ? Event.target.value : ""
          );
          Records.NewRecord = true;
        }
      });
    }
  }
  function HandleMachine(event) {
    setRawMat(RawTracking);
    let current = new Date();
    let start = new Date(
      new Date().getTime() - new Date().getTimezoneOffset() * 60000
    );
    let end = new Date(
      new Date().getTime() - new Date().getTimezoneOffset() * 60000
    );

    // check for the date changes
    if (current.getHours() > 7) {
      end.setDate(end.getDate() + 1);
      start = start.toISOString().slice(0, 10) + " 08:00:00";
      end = end.toISOString().slice(0, 10) + " 08:00:00";
    } else {
      start.setDate(start.getDate() - 1);
      start = start.toISOString().slice(0, 10) + " 08:00:00";
      end = end.toISOString().slice(0, 10) + " 08:00:00";
    }
    if (Records.Two_k_part == "0") {
      ReadRecord({
        _keyword_: "Get_ALL_PMS_by_Mcno",
        Mcno: event.value,
        StartDate: start,
        EndDate: end,
      }).then((res) => {
        setRecords((records) => {
          if (res.rows > 0) {
            records = res.data[0];
            records.EmpDetails = [];
            records.Parts = [];
            records.Machines = Records.Machines;
            records.SMP = {};
            records.InsertRecord = false;
            records.NewRecord = false;
            records.Mtc = true;
            records.Two_k_part = "0"

            records.Disable = {
              Hour1: !(time > "08:00" && time < "12:00"),
              Hour2: !(time > "12:00" && time < "16:00"),
              Hour3: !(time > "16:00" && time < "20:00"),
              Hour4: !(time > "20:00" && time < "24:00"),
              Hour5: !(time > "24:00" || time < "04:00"),
              Hour6: !(time > "04:00" && time < "08:00"),
              // Hour1: false,
              // Hour2: false,
              // Hour3: false,
              // Hour4: false,
              // Hour5: false,
              // Hour6: false,
            };
          } else {
            records = { ...InitRecord };
            records.Mcno = event.value;
            records.MachineNo = [event];
            records.Machines = Records.Machines;
            records.NewRecord = true;
            records.Disable = {
              Hour1: !(time > "08:00" && time < "12:00"),
              Hour2: !(time > "12:00" && time < "16:00"),
              Hour3: !(time > "16:00" && time < "20:00"),
              Hour4: !(time > "20:00" && time < "24:00"),
              Hour5: !(time > "24:00" || time < "04:00"),
              Hour6: !(time > "04:00" && time < "08:00"),
              // Hour5: !(time > "22:30"),
              // Hour6: !(time > "00:00" && time < "04:30"),
              // Hour1: false,
              // Hour2: false,
              // Hour3: false,
              // Hour4: false,
              // Hour5: false,
              // Hour6: false,
            };
          }
          return { ...records };
        });
        // LoadParts(event.value); // load the parts based on the Machine number and other perspective
      });
    } else {
      setRecords((records) => {
        records = { ...InitRecord };
        records.Two_k_part = "1";
        records.Mcno = event.value;
        records.MachineNo = [event];
        records.Machines = Records.Machines;
        records.NewRecord = true;
        records.Disable = {
          Hour1: !(time > "08:00" && time < "12:00"),
          Hour2: !(time > "12:00" && time < "16:00"),
          Hour3: !(time > "16:00" && time < "20:00"),
          Hour4: !(time > "20:00" && time < "24:00"),
          Hour5: !(time > "24:00" || time < "04:00"),
          Hour6: !(time > "04:00" && time < "08:00"),
          // Hour5: !(time > "22:30"),
          // Hour6: !(time > "00:00" && time < "04:30"),
          // Hour1: false,
          // Hour2: false,
          // Hour3: false,
          // Hour4: false,
          // Hour5: false,
          // Hour6: false,
        };
        return { ...records };
      });
    }
  }

  function handle7am(event) {
    let value = event.target.value;
    if (["IDEAL", "MOULD CHANGE"].includes(value)) {
      setRawMatDropDown([]);
      setRawMat(RawTracking);
      let Tracking = Records.Tracking.map((item, index) => {
        if (index === 0) {
          item.Track_7_am = time;
        } else {
          item.Track_7_am = value;
        }
        return item;
      });
      let Heat = Records.Heat.map((item) => {
        item.Heat_actual_1 = value;
        return item;
      });
      let Injection = Records.Injection.map((item) => {
        item.Inject_actual_1 = value;
        return item;
      });
      UpdateObject("Tracking", Tracking);
      UpdateObject("Heat", Heat);
      UpdateObject("Injection", Injection);
      if (value === "MOULD CHANGE") {
        UpdateObject("InsertRecord", true);
        UpdateObject("NewRecord", false);
        UpdateObject("MouldChange", [
          "Track_7_am",
          "Heat_actual_1",
          "Inject_actual_1",
        ]);
      }
    } else if (value === "") {
      LoadParts([
        "Track_11_am",
        "Track_15_pm",
        "Track_19_pm",
        "Track_23_pm",
        "Track_3_am",
      ]);
      setRawMatDropDown([]);
      setRawMat(RawTracking);
      let Tracking = Records.Tracking.map((item, index) => {
        if (index === 0) {
          item.Track_7_am = "";
        } else {
          item.Track_7_am = "";
        }
        return item;
      });
      let Heat = Records.Heat.map((item) => {
        item.Heat_actual_1 = "";
        return item;
      });
      let Injection = Records.Injection.map((item) => {
        item.Inject_actual_1 = "";
        return item;
      });
      UpdateObject("Tracking", Tracking);
      UpdateObject("Heat", Heat);
      UpdateObject("Injection", Injection);
    }  else if (value === "new") {
      let Tracking = Records.Tracking.map((item, index) => {
        if (index === 0) {
          item.Track_7_am = "";
        } else {
          item.Track_7_am = "";
        }
        return item;
      });
      let Heat = Records.Heat.map((item) => {
        item.Heat_actual_1 = "";
        return item;
      });
      let Injection = Records.Injection.map((item) => {
        item.Inject_actual_1 = "";
        return item;
      });
      UpdateObject("Tracking", Tracking);
      UpdateObject("Heat", Heat);
      UpdateObject("Injection", Injection);
    } else {
      ReadRecord(
        `SELECT smp.Raw_material_name,rm.rm_description,smp.Colour,smp.Grade,smp.Part_name FROM production_smp_parent smp LEFT JOIN rm_details rm ON smp.Raw_material_name = rm.rm_code WHERE smp.Machine_no = '${Records.Mcno}' AND smp.Part_name = '${value}' AND smp.Two_k_part='${Records.Two_k_part}'  AND smp.ActiveStatus='1'  GROUP BY smp.Raw_material_name,rm.rm_description,smp.Colour,smp.Grade,smp.Part_name;`
      ).then((res) => {
        console.log("resRaw", res);
        if (Records.Two_k_part == "0") {
          setRawMat(RawTracking);
          let resp = res.data[0].rows[0];
          UpdateIndex("Tracking", 0, "Track_7_am", time);
          UpdateIndex("Tracking", 2, "Track_7_am", resp.Colour);
          UpdateIndex("Tracking", 3, "Track_7_am", resp.rm_description);
          UpdateIndex("Tracking", 4, "Track_7_am", resp.Grade);
          UpdateIndex("Tracking", 1, "Track_7_am", value);
        } else {
          setRawMat((value) => {
            value.Track_7_am = true;
            return { ...value };
          });
          setRawMatDropDown(res.data[0].rows);
          UpdateIndex("Tracking", 0, "Track_7_am", time);
          UpdateIndex("Tracking", 1, "Track_7_am", value);
          UpdateIndex("Tracking", 2, "Track_7_am", "");
          UpdateIndex("Tracking", 3, "Track_7_am", "");
          UpdateIndex("Tracking", 4, "Track_7_am", "");
        }
        // if (
        //   res.data[0].count == "1" ||
        //   (res.data[0].count > "0" &&
        //     res.data[0].rows.find((option) => option.two_k_part === "0"))
        // ) {
        //   setRawMat(RawTracking);
        //   let resp = res.data[0].rows[0];
        //   UpdateIndex("Tracking", 0, "Track_7_am", time);
        //   UpdateIndex("Tracking", 2, "Track_7_am", resp.Colour);
        //   UpdateIndex("Tracking", 3, "Track_7_am", resp.rm_description);
        //   UpdateIndex("Tracking", 4, "Track_7_am", resp.Grade);
        //   UpdateIndex("Tracking", 1, "Track_7_am", value);
        // } else if (
        //   res.data[0].count > "0" &&
        //   res.data[0].rows.find((option) => option.two_k_part === "1")
        // ) {
        //   setRawMat((value) => {
        //     value.Track_7_am = true;
        //     return { ...value };
        //   });
        //   setRawMatDropDown(res.data[0].rows);
        //   UpdateIndex("Tracking", 0, "Track_7_am", time);
        //   UpdateIndex("Tracking", 1, "Track_7_am", value);
        //   UpdateIndex("Tracking", 2, "Track_7_am", "");
        //   UpdateIndex("Tracking", 3, "Track_7_am", "");
        //   UpdateIndex("Tracking", 4, "Track_7_am", "");
        // }
        let Heat = Records.Heat.map((item) => {
          item.Heat_actual_1 = "";
          return item;
        });
        let Injection = Records.Injection.map((item) => {
          item.Inject_actual_1 = "";
          return item;
        });
        UpdateObject("Heat", Heat);
        UpdateObject("Injection", Injection);
      });
      Records.PartWeight1 === "" &&
        Records.Two_k_part == "0" &&
        LoadSMP(value, "");
      // UpdateObject("Parts", [{ partname: value }]);
    }
  }
  function handle11am(event) {
    let value = event.target.value;
    // console.log("dododo", value);
    if (["IDEAL", "MOULD CHANGE"].includes(value)) {
      setRawMatDropDown([]);
      setRawMat(RawTracking);
      let Tracking = Records.Tracking.map((item, index) => {
        if (index === 0) {
          item.Track_11_am = time;
        } else {
          item.Track_11_am = value;
        }
        return item;
      });
      let Heat = Records.Heat.map((item) => {
        item.Heat_actual_2 = value;
        return item;
      });
      let Injection = Records.Injection.map((item) => {
        item.Inject_actual_2 = value;
        return item;
      });
      UpdateObject("Tracking", Tracking);
      UpdateObject("Heat", Heat);
      UpdateObject("Injection", Injection);
      if (value === "MOULD CHANGE") {
        UpdateObject("InsertRecord", true);
        UpdateObject("NewRecord", false);
        UpdateObject("MouldChange", [
          "Track_11_am",
          "Heat_actual_2",
          "Inject_actual_2",
        ]);
      }
    } else if (value === "") {
      LoadParts([
        "Track_7_am",
        "Track_15_pm",
        "Track_19_pm",
        "Track_23_pm",
        "Track_3_am",
      ]);
      let Tracking = Records.Tracking.map((item, index) => {
        if (index === 0) {
          item.Track_11_am = "";
        } else {
          item.Track_11_am = "";
        }
        return item;
      });
      let Heat = Records.Heat.map((item) => {
        item.Heat_actual_2 = "";
        return item;
      });
      let Injection = Records.Injection.map((item) => {
        item.Inject_actual_2 = "";
        return item;
      });
      setRawMatDropDown([]);
      setRawMat(RawTracking);
      UpdateObject("Tracking", Tracking);
      UpdateObject("Heat", Heat);
      UpdateObject("Injection", Injection);
    }else if ( value === "new") {
      let Tracking = Records.Tracking.map((item, index) => {
        if (index === 0) {
          item.Track_11_am = "";
        } else {
          item.Track_11_am = "";
        }
        return item;
      });
      let Heat = Records.Heat.map((item) => {
        item.Heat_actual_2 = "";
        return item;
      });
      let Injection = Records.Injection.map((item) => {
        item.Inject_actual_2 = "";
        return item;
      });
      UpdateObject("Tracking", Tracking);
      UpdateObject("Heat", Heat);
      UpdateObject("Injection", Injection);
    } else {
      ReadRecord(
        `SELECT smp.Raw_material_name,rm.rm_description,smp.Colour,smp.Grade,smp.Part_name FROM production_smp_parent smp LEFT JOIN rm_details rm ON smp.Raw_material_name = rm.rm_code WHERE smp.Machine_no = '${Records.Mcno}' AND smp.Part_name = '${value}' AND smp.Two_k_part='${Records.Two_k_part}'  AND smp.ActiveStatus='1'  GROUP BY smp.Raw_material_name,rm.rm_description,smp.Colour,smp.Grade,smp.Part_name;`
      ).then((res) => {
        if (Records.Two_k_part == "0") {
          setRawMat(RawTracking);
          let resp = res.data[0].rows[0];
          UpdateIndex("Tracking", 0, "Track_11_am", time);
          UpdateIndex("Tracking", 2, "Track_11_am", resp.Colour);
          UpdateIndex("Tracking", 3, "Track_11_am", resp.rm_description);
          UpdateIndex("Tracking", 4, "Track_11_am", resp.Grade);
          UpdateIndex("Tracking", 1, "Track_11_am", value);
        } else {
          setRawMat((value) => {
            value.Track_11_am = true;
            return { ...value };
          });
          setRawMatDropDown(res.data[0].rows);
          UpdateIndex("Tracking", 0, "Track_11_am", time);
          UpdateIndex("Tracking", 1, "Track_11_am", value);
          UpdateIndex("Tracking", 2, "Track_11_am", "");
          UpdateIndex("Tracking", 3, "Track_11_am", "");
          UpdateIndex("Tracking", 4, "Track_11_am", "");
        }
        // setRawMat(RawTracking);
        // if (Records.Tracking[1].Track_7_am == value) {
        //   UpdateIndex("Tracking", 0, "Track_11_am", time);
        //   UpdateIndex("Tracking", 1, "Track_11_am", value);
        //   UpdateIndex(
        //     "Tracking",
        //     2,
        //     "Track_11_am",
        //     Records.Tracking[2].Track_7_am
        //   );
        //   UpdateIndex(
        //     "Tracking",
        //     3,
        //     "Track_11_am",
        //     Records.Tracking[3].Track_7_am
        //   );
        //   UpdateIndex(
        //     "Tracking",
        //     4,
        //     "Track_11_am",
        //     Records.Tracking[4].Track_7_am
        //   );
        // } else if (
        //   res.data[0].count == "1" ||
        //   (res.data[0].count > "0" &&
        //     res.data[0].rows.find((option) => option.two_k_part === "0") &&
        //     Records.Tracking[1].Track_7_am != value)
        // ) {
        //   setRawMat(RawTracking);
        //   let resp = res.data[0].rows[0];
        //   UpdateIndex("Tracking", 0, "Track_11_am", time);
        //   UpdateIndex("Tracking", 2, "Track_11_am", resp.Colour);
        //   UpdateIndex("Tracking", 3, "Track_11_am", resp.rm_description);
        //   UpdateIndex("Tracking", 4, "Track_11_am", resp.Grade);
        //   UpdateIndex("Tracking", 1, "Track_11_am", value);
        // } else if (
        //   res.data[0].count > "0" &&
        //   res.data[0].rows.find((option) => option.two_k_part === "1") &&
        //   Records.Tracking[1].Track_7_am != value
        // ) {
        //   setRawMat((value) => {
        //     value.Track_11_am = true;
        //     return { ...value };
        //   });
        //   setRawMatDropDown(res.data[0].rows);
        //   UpdateIndex("Tracking", 0, "Track_11_am", time);
        //   UpdateIndex("Tracking", 1, "Track_11_am", value);
        //   UpdateIndex("Tracking", 2, "Track_11_am", "");
        //   UpdateIndex("Tracking", 3, "Track_11_am", "");
        //   UpdateIndex("Tracking", 4, "Track_11_am", "");
        // }

        let Heat = Records.Heat.map((item) => {
          item.Heat_actual_2 = "";
          return item;
        });
        let Injection = Records.Injection.map((item) => {
          item.Inject_actual_2 = "";
          return item;
        });
        UpdateObject("Heat", Heat);
        UpdateObject("Injection", Injection);
      });
      Records.PartWeight1 === "" &&
        Records.Two_k_part == "0" &&
        LoadSMP(value, "");
      // UpdateObject("Parts", [{ partname: value }]);
    }
  }
  function handle15pm(event) {
    let value = event.target.value;
    if (["IDEAL", "MOULD CHANGE"].includes(value)) {
      setRawMatDropDown([]);
      setRawMat(RawTracking);
      let Tracking = Records.Tracking.map((item, index) => {
        if (index === 0) {
          item.Track_15_pm = time;
        } else {
          item.Track_15_pm = value;
        }
        return item;
      });
      let Heat = Records.Heat.map((item) => {
        item.Heat_actual_3 = value;
        return item;
      });
      let Injection = Records.Injection.map((item) => {
        item.Inject_actual_3 = value;
        return item;
      });
      UpdateObject("Tracking", Tracking);
      UpdateObject("Heat", Heat);
      UpdateObject("Injection", Injection);
      if (value === "MOULD CHANGE") {
        UpdateObject("InsertRecord", true);
        UpdateObject("NewRecord", false);
        UpdateObject("MouldChange", [
          "Track_15_pm",
          "Heat_actual_3",
          "Inject_actual_3",
        ]);
      }
    } else if (value === "") {
      LoadParts([
        "Track_7_am",
        "Track_11_am",
        "Track_19_pm",
        "Track_23_pm",
        "Track_3_am",
      ]);
      let Tracking = Records.Tracking.map((item, index) => {
        if (index === 0) {
          item.Track_15_pm = "";
        } else {
          item.Track_15_pm = "";
        }
        return item;
      });
      let Heat = Records.Heat.map((item) => {
        item.Heat_actual_3 = "";
        return item;
      });
      let Injection = Records.Injection.map((item) => {
        item.Inject_actual_3 = "";
        return item;
      });
      setRawMatDropDown([]);
      setRawMat(RawTracking);
      UpdateObject("Tracking", Tracking);
      UpdateObject("Heat", Heat);
      UpdateObject("Injection", Injection);
    }else if (value === "new") {
      let Tracking = Records.Tracking.map((item, index) => {
        if (index === 0) {
          item.Track_15_pm = "";
        } else {
          item.Track_15_pm = "";
        }
        return item;
      });
      let Heat = Records.Heat.map((item) => {
        item.Heat_actual_3 = "";
        return item;
      });
      let Injection = Records.Injection.map((item) => {
        item.Inject_actual_3 = "";
        return item;
      });
      UpdateObject("Tracking", Tracking);
      UpdateObject("Heat", Heat);
      UpdateObject("Injection", Injection);
    } else {
      ReadRecord(
        `SELECT smp.Raw_material_name,rm.rm_description,smp.Colour,smp.Grade,smp.Part_name FROM production_smp_parent smp LEFT JOIN rm_details rm ON smp.Raw_material_name = rm.rm_code WHERE smp.Machine_no = '${Records.Mcno}' AND smp.Part_name = '${value}' AND smp.Two_k_part='${Records.Two_k_part}'  AND smp.ActiveStatus='1'  GROUP BY smp.Raw_material_name,rm.rm_description,smp.Colour,smp.Grade,smp.Part_name;`
      ).then((res) => {
        if (Records.Two_k_part == "0") {
          setRawMat(RawTracking);
          let resp = res.data[0].rows[0];
          UpdateIndex("Tracking", 0, "Track_15_pm", time);
          UpdateIndex("Tracking", 2, "Track_15_pm", resp.Colour);
          UpdateIndex("Tracking", 3, "Track_15_pm", resp.rm_description);
          UpdateIndex("Tracking", 4, "Track_15_pm", resp.Grade);
          UpdateIndex("Tracking", 1, "Track_15_pm", value);
        } else {
          setRawMat((value) => {
            value.Track_15_pm = true;
            return { ...value };
          });
          setRawMatDropDown(res.data[0].rows);
          UpdateIndex("Tracking", 0, "Track_15_pm", time);
          UpdateIndex("Tracking", 1, "Track_15_pm", value);
          UpdateIndex("Tracking", 2, "Track_15_pm", "");
          UpdateIndex("Tracking", 3, "Track_15_pm", "");
          UpdateIndex("Tracking", 4, "Track_15_pm", "");
        }
        // setRawMat(RawTracking);
        // if (Records.Tracking[1].Track_11_am == value) {
        //   UpdateIndex("Tracking", 0, "Track_15_pm", time);
        //   UpdateIndex("Tracking", 1, "Track_15_pm", value);
        //   UpdateIndex(
        //     "Tracking",
        //     2,
        //     "Track_15_pm",
        //     Records.Tracking[2].Track_11_am
        //   );
        //   UpdateIndex(
        //     "Tracking",
        //     3,
        //     "Track_15_pm",
        //     Records.Tracking[3].Track_11_am
        //   );
        //   UpdateIndex(
        //     "Tracking",
        //     4,
        //     "Track_15_pm",
        //     Records.Tracking[4].Track_11_am
        //   );
        // } else if (
        //   res.data[0].count == "1" ||
        //   (res.data[0].count > "0" &&
        //     res.data[0].rows.find((option) => option.two_k_part === "0") &&
        //     Records.Tracking[1].Track_11_am != value)
        // ) {
        //   setRawMat(RawTracking);
        //   let resp = res.data[0].rows[0];
        //   UpdateIndex("Tracking", 0, "Track_15_pm", time);
        //   UpdateIndex("Tracking", 2, "Track_15_pm", resp.Colour);
        //   UpdateIndex("Tracking", 3, "Track_15_pm", resp.rm_description);
        //   UpdateIndex("Tracking", 4, "Track_15_pm", resp.Grade);
        //   UpdateIndex("Tracking", 1, "Track_15_pm", value);
        // } else if (
        //   res.data[0].count > "0" &&
        //   res.data[0].rows.find((option) => option.two_k_part === "1") &&
        //   Records.Tracking[1].Track_11_am != value
        // ) {
        //   setRawMat((value) => {
        //     value.Track_15_pm = true;
        //     return { ...value };
        //   });
        //   setRawMatDropDown(res.data[0].rows);
        //   UpdateIndex("Tracking", 0, "Track_15_pm", time);
        //   UpdateIndex("Tracking", 1, "Track_15_pm", value);
        //   UpdateIndex("Tracking", 2, "Track_15_pm", "");
        //   UpdateIndex("Tracking", 3, "Track_15_pm", "");
        //   UpdateIndex("Tracking", 4, "Track_15_pm", "");
        // }

        let Heat = Records.Heat.map((item) => {
          item.Heat_actual_3 = "";
          return item;
        });
        let Injection = Records.Injection.map((item) => {
          item.Inject_actual_3 = "";
          return item;
        });
        UpdateObject("Heat", Heat);
        UpdateObject("Injection", Injection);
      });
      Records.PartWeight1 === "" &&
        Records.Two_k_part == "0" &&
        LoadSMP(value, "");
      UpdateObject("Parts", [{ partname: value }]);
    }
  }
  function handle19pm(event) {
    let value = event.target.value;
    if (["IDEAL", "MOULD CHANGE"].includes(value)) {
      setRawMatDropDown([]);
      setRawMat(RawTracking);
      let Tracking = Records.Tracking.map((item, index) => {
        if (index === 0) {
          item.Track_19_pm = time;
        } else {
          item.Track_19_pm = value;
        }
        return item;
      });
      let Heat = Records.Heat.map((item) => {
        item.Heat_actual_4 = value;
        return item;
      });
      let Injection = Records.Injection.map((item) => {
        item.Inject_actual_4 = value;
        return item;
      });
      UpdateObject("Tracking", Tracking);
      UpdateObject("Heat", Heat);
      UpdateObject("Injection", Injection);
      if (value === "MOULD CHANGE") {
        UpdateObject("InsertRecord", true);
        UpdateObject("NewRecord", false);
        UpdateObject("MouldChange", [
          "Track_19_pm",
          "Heat_actual_4",
          "Inject_actual_4",
        ]);
      }
    } else if (value === "") {
      LoadParts([
        "Track_7_am",
        "Track_11_am",
        "Track_15_pm",
        "Track_23_pm",
        "Track_3_am",
      ]);
      let Tracking = Records.Tracking.map((item, index) => {
        if (index === 0) {
          item.Track_19_pm = "";
        } else {
          item.Track_19_pm = "";
        }
        return item;
      });
      let Heat = Records.Heat.map((item) => {
        item.Heat_actual_4 = "";
        return item;
      });
      let Injection = Records.Injection.map((item) => {
        item.Inject_actual_4 = "";
        return item;
      });
      setRawMatDropDown([]);
      setRawMat(RawTracking);
      UpdateObject("Tracking", Tracking);
      UpdateObject("Heat", Heat);
      UpdateObject("Injection", Injection);
    }else if (value === "new") {
      let Tracking = Records.Tracking.map((item, index) => {
        if (index === 0) {
          item.Track_19_pm = "";
        } else {
          item.Track_19_pm = "";
        }
        return item;
      });
      let Heat = Records.Heat.map((item) => {
        item.Heat_actual_4 = "";
        return item;
      });
      let Injection = Records.Injection.map((item) => {
        item.Inject_actual_4 = "";
        return item;
      });
      UpdateObject("Tracking", Tracking);
      UpdateObject("Heat", Heat);
      UpdateObject("Injection", Injection);
    } else {
      ReadRecord(
        `SELECT smp.Raw_material_name,rm.rm_description,smp.Colour,smp.Grade,smp.Part_name FROM production_smp_parent smp LEFT JOIN rm_details rm ON smp.Raw_material_name = rm.rm_code WHERE smp.Machine_no = '${Records.Mcno}' AND smp.Part_name = '${value}' AND smp.Two_k_part='${Records.Two_k_part}'  AND smp.ActiveStatus='1'  GROUP BY smp.Raw_material_name,rm.rm_description,smp.Colour,smp.Grade,smp.Part_name;`
      ).then((res) => {
        if (Records.Two_k_part == "0") {
          setRawMat(RawTracking);
          let resp = res.data[0].rows[0];
          UpdateIndex("Tracking", 0, "Track_19_pm", time);
          UpdateIndex("Tracking", 2, "Track_19_pm", resp.Colour);
          UpdateIndex("Tracking", 3, "Track_19_pm", resp.rm_description);
          UpdateIndex("Tracking", 4, "Track_19_pm", resp.Grade);
          UpdateIndex("Tracking", 1, "Track_19_pm", value);
        } else {
          setRawMat((value) => {
            value.Track_19_pm = true;
            return { ...value };
          });
          setRawMatDropDown(res.data[0].rows);
          UpdateIndex("Tracking", 0, "Track_19_pm", time);
          UpdateIndex("Tracking", 1, "Track_19_pm", value);
          UpdateIndex("Tracking", 2, "Track_19_pm", "");
          UpdateIndex("Tracking", 3, "Track_19_pm", "");
          UpdateIndex("Tracking", 4, "Track_19_pm", "");
        }
        // setRawMat(RawTracking);
        // if (Records.Tracking[1].Track_15_pm == value) {
        //   UpdateIndex("Tracking", 0, "Track_19_pm", time);
        //   UpdateIndex("Tracking", 1, "Track_19_pm", value);
        //   UpdateIndex(
        //     "Tracking",
        //     2,
        //     "Track_19_pm",
        //     Records.Tracking[2].Track_15_pm
        //   );
        //   UpdateIndex(
        //     "Tracking",
        //     3,
        //     "Track_19_pm",
        //     Records.Tracking[3].Track_15_pm
        //   );
        //   UpdateIndex(
        //     "Tracking",
        //     4,
        //     "Track_19_pm",
        //     Records.Tracking[4].Track_15_pm
        //   );
        // } else if (
        //   res.data[0].count == "1" ||
        //   (res.data[0].count > "0" &&
        //     res.data[0].rows.find((option) => option.two_k_part === "0") &&
        //     Records.Tracking[1].Track_15_pm != value)
        // ) {
        //   setRawMat(RawTracking);
        //   let resp = res.data[0].rows[0];
        //   UpdateIndex("Tracking", 0, "Track_19_pm", time);
        //   UpdateIndex("Tracking", 2, "Track_19_pm", resp.Colour);
        //   UpdateIndex("Tracking", 3, "Track_19_pm", resp.rm_description);
        //   UpdateIndex("Tracking", 4, "Track_19_pm", resp.Grade);
        //   UpdateIndex("Tracking", 1, "Track_19_pm", value);
        // } else if (
        //   res.data[0].count > "0" &&
        //   res.data[0].rows.find((option) => option.two_k_part === "1") &&
        //   Records.Tracking[1].Track_15_pm != value
        // ) {
        //   setRawMat((value) => {
        //     value.Track_19_pm = true;
        //     return { ...value };
        //   });
        //   setRawMatDropDown(res.data[0].rows);
        //   UpdateIndex("Tracking", 0, "Track_19_pm", time);
        //   UpdateIndex("Tracking", 1, "Track_19_pm", value);
        //   UpdateIndex("Tracking", 2, "Track_19_pm", "");
        //   UpdateIndex("Tracking", 3, "Track_19_pm", "");
        //   UpdateIndex("Tracking", 4, "Track_19_pm", "");
        // }
        let Heat = Records.Heat.map((item) => {
          item.Heat_actual_4 = "";
          return item;
        });
        let Injection = Records.Injection.map((item) => {
          item.Inject_actual_4 = "";
          return item;
        });
        UpdateObject("Heat", Heat);
        UpdateObject("Injection", Injection);
      });
      Records.PartWeight1 === "" &&
        Records.Two_k_part == "0" &&
        LoadSMP(value, "");
      UpdateObject("Parts", [{ partname: value }]);
    }
  }
  function handle23pm(event) {
    let value = event.target.value;
    if (["IDEAL", "MOULD CHANGE"].includes(value)) {
      setRawMatDropDown([]);
      setRawMat(RawTracking);
      let Tracking = Records.Tracking.map((item, index) => {
        if (index === 0) {
          item.Track_23_pm = time;
        } else {
          item.Track_23_pm = value;
        }
        return item;
      });
      let Heat = Records.Heat.map((item) => {
        item.Heat_actual_5 = value;
        return item;
      });
      let Injection = Records.Injection.map((item) => {
        item.Inject_actual_5 = value;
        return item;
      });
      UpdateObject("Tracking", Tracking);
      UpdateObject("Heat", Heat);
      UpdateObject("Injection", Injection);
      if (value === "MOULD CHANGE") {
        UpdateObject("InsertRecord", true);
        UpdateObject("NewRecord", false);
        UpdateObject("MouldChange", [
          "Track_23_pm",
          "Heat_actual_5",
          "Inject_actual_5",
        ]);
      }
    } else if (value === "") {
      LoadParts([
        "Track_7_am",
        "Track_11_am",
        "Track_15_pm",
        "Track_19_pm",
        "Track_3_am",
      ]);
      let Tracking = Records.Tracking.map((item, index) => {
        if (index === 0) {
          item.Track_23_pm = "";
        } else {
          item.Track_23_pm = "";
        }
        return item;
      });
      let Heat = Records.Heat.map((item) => {
        item.Heat_actual_5 = "";
        return item;
      });
      let Injection = Records.Injection.map((item) => {
        item.Inject_actual_5 = "";
        return item;
      });
      setRawMatDropDown([]);
      setRawMat(RawTracking);
      UpdateObject("Tracking", Tracking);
      UpdateObject("Heat", Heat);
      UpdateObject("Injection", Injection);
    } else if (value === "new") {
      let Tracking = Records.Tracking.map((item, index) => {
        if (index === 0) {
          item.Track_23_pm = "";
        } else {
          item.Track_23_pm = "";
        }
        return item;
      });
      let Heat = Records.Heat.map((item) => {
        item.Heat_actual_5 = "";
        return item;
      });
      let Injection = Records.Injection.map((item) => {
        item.Inject_actual_5 = "";
        return item;
      });
      UpdateObject("Tracking", Tracking);
      UpdateObject("Heat", Heat);
      UpdateObject("Injection", Injection);
    } else {
      ReadRecord(
        `SELECT smp.Raw_material_name,rm.rm_description,smp.Colour,smp.Grade,smp.Part_name FROM production_smp_parent smp LEFT JOIN rm_details rm ON smp.Raw_material_name = rm.rm_code WHERE smp.Machine_no = '${Records.Mcno}' AND smp.Part_name = '${value}' AND smp.Two_k_part='${Records.Two_k_part}'  AND smp.ActiveStatus='1'  GROUP BY smp.Raw_material_name,rm.rm_description,smp.Colour,smp.Grade,smp.Part_name;`
      ).then((res) => {
        if (Records.Two_k_part == "0") {
          setRawMat(RawTracking);
          let resp = res.data[0].rows[0];
          UpdateIndex("Tracking", 0, "Track_23_pm", time);
          UpdateIndex("Tracking", 2, "Track_23_pm", resp.Colour);
          UpdateIndex("Tracking", 3, "Track_23_pm", resp.rm_description);
          UpdateIndex("Tracking", 4, "Track_23_pm", resp.Grade);
          UpdateIndex("Tracking", 1, "Track_23_pm", value);
        } else {
          setRawMat((value) => {
            value.Track_23_pm = true;
            return { ...value };
          });
          setRawMatDropDown(res.data[0].rows);
          UpdateIndex("Tracking", 0, "Track_23_pm", time);
          UpdateIndex("Tracking", 1, "Track_23_pm", value);
          UpdateIndex("Tracking", 2, "Track_23_pm", "");
          UpdateIndex("Tracking", 3, "Track_23_pm", "");
          UpdateIndex("Tracking", 4, "Track_23_pm", "");
        }
        // setRawMat(RawTracking);
        // if (Records.Tracking[1].Track_19_pm == value) {
        //   UpdateIndex("Tracking", 0, "Track_23_pm", time);
        //   UpdateIndex("Tracking", 1, "Track_23_pm", value);
        //   UpdateIndex(
        //     "Tracking",
        //     2,
        //     "Track_23_pm",
        //     Records.Tracking[2].Track_19_pm
        //   );
        //   UpdateIndex(
        //     "Tracking",
        //     3,
        //     "Track_23_pm",
        //     Records.Tracking[3].Track_19_pm
        //   );
        //   UpdateIndex(
        //     "Tracking",
        //     4,
        //     "Track_23_pm",
        //     Records.Tracking[4].Track_19_pm
        //   );
        // } else if (
        //   res.data[0].count == "1" ||
        //   (res.data[0].count > "0" &&
        //     res.data[0].rows.find((option) => option.two_k_part === "0") &&
        //     Records.Tracking[1].Track_19_pm != value)
        // ) {
        //   setRawMat(RawTracking);
        //   let resp = res.data[0].rows[0];
        //   UpdateIndex("Tracking", 0, "Track_23_pm", time);
        //   UpdateIndex("Tracking", 2, "Track_23_pm", resp.Colour);
        //   UpdateIndex("Tracking", 3, "Track_23_pm", resp.rm_description);
        //   UpdateIndex("Tracking", 4, "Track_23_pm", resp.Grade);
        //   UpdateIndex("Tracking", 1, "Track_23_pm", value);
        // } else if (
        //   res.data[0].count > "0" &&
        //   res.data[0].rows.find((option) => option.two_k_part === "1") &&
        //   Records.Tracking[1].Track_19_pm != value
        // ) {
        //   setRawMat((value) => {
        //     value.Track_23_pm = true;
        //     return { ...value };
        //   });
        //   setRawMatDropDown(res.data[0].rows);
        //   UpdateIndex("Tracking", 0, "Track_23_pm", time);
        //   UpdateIndex("Tracking", 1, "Track_23_pm", value);
        //   UpdateIndex("Tracking", 2, "Track_23_pm", "");
        //   UpdateIndex("Tracking", 3, "Track_23_pm", "");
        //   UpdateIndex("Tracking", 4, "Track_23_pm", "");
        // }
        let Heat = Records.Heat.map((item) => {
          item.Heat_actual_5 = "";
          return item;
        });
        let Injection = Records.Injection.map((item) => {
          item.Inject_actual_5 = "";
          return item;
        });
        UpdateObject("Heat", Heat);
        UpdateObject("Injection", Injection);
      });
      Records.PartWeight1 === "" &&
        Records.Two_k_part == "0" &&
        LoadSMP(value, "");
      UpdateObject("Parts", [{ partname: value }]);
    }
  }
  function handle3am(event) {
    let value = event.target.value;
    // console.log("Value", value);
    if (["IDEAL", "MOULD CHANGE"].includes(value)) {
      setRawMatDropDown([]);
      setRawMat(RawTracking);
      let Tracking = Records.Tracking.map((item, index) => {
        if (index === 0) {
          item.Track_3_am = time;
        } else {
          item.Track_3_am = value;
        }
        return item;
      });
      let Heat = Records.Heat.map((item) => {
        item.Heat_actual_6 = value;
        return item;
      });
      let Injection = Records.Injection.map((item) => {
        item.Inject_actual_6 = value;
        return item;
      });
      UpdateObject("Tracking", Tracking);
      UpdateObject("Heat", Heat);
      UpdateObject("Injection", Injection);
      if (value === "MOULD CHANGE") {
        UpdateObject("InsertRecord", true);
        // UpdateObject("NewRecord", false);
        UpdateObject("MouldChange", [
          "Track_3_am",
          "Heat_actual_6",
          "Inject_actual_6",
        ]);
      }
    }else if (value === "") {
      LoadParts([
        "Track_7_am",
        "Track_11_am",
        "Track_15_pm",
        "Track_19_pm",
        "Track_23_pm",
      ]);
      let Tracking = Records.Tracking.map((item, index) => {
        if (index === 0) {
          item.Track_3_am = "";
        } else {
          item.Track_3_am = "";
        }
        return item;
      });
      let Heat = Records.Heat.map((item) => {
        item.Heat_actual_6 = "";
        return item;
      });
      let Injection = Records.Injection.map((item) => {
        item.Inject_actual_6 = "";
        return item;
      });
      setRawMatDropDown([]);
      setRawMat(RawTracking);
      UpdateObject("Tracking", Tracking);
      UpdateObject("Heat", Heat);
      UpdateObject("Injection", Injection);
    } else if (value === "new") {
      let Tracking = Records.Tracking.map((item, index) => {
        if (index === 0) {
          item.Track_3_am = "";
        } else {
          item.Track_3_am = "";
        }
        return item;
      });
      let Heat = Records.Heat.map((item) => {
        item.Heat_actual_6 = "";
        return item;
      });
      let Injection = Records.Injection.map((item) => {
        item.Inject_actual_6 = "";
        return item;
      });
      UpdateObject("Tracking", Tracking);
      UpdateObject("Heat", Heat);
      UpdateObject("Injection", Injection);
    } else {
      ReadRecord(
        `SELECT smp.Raw_material_name,rm.rm_description,smp.Colour,smp.Grade,smp.Part_name FROM production_smp_parent smp LEFT JOIN rm_details rm ON smp.Raw_material_name = rm.rm_code WHERE smp.Machine_no = '${Records.Mcno}' AND smp.Part_name = '${value}' AND smp.Two_k_part='${Records.Two_k_part}'  AND smp.ActiveStatus='1'  GROUP BY smp.Raw_material_name,rm.rm_description,smp.Colour,smp.Grade,smp.Part_name;`
      ).then((res) => {
        if (Records.Two_k_part == "0") {
          setRawMat(RawTracking);
          let resp = res.data[0].rows[0];
          UpdateIndex("Tracking", 0, "Track_3_am", time);
          UpdateIndex("Tracking", 2, "Track_3_am", resp.Colour);
          UpdateIndex("Tracking", 3, "Track_3_am", resp.rm_description);
          UpdateIndex("Tracking", 4, "Track_3_am", resp.Grade);
          UpdateIndex("Tracking", 1, "Track_3_am", value);
        } else {
          setRawMat((value) => {
            value.Track_3_am = true;
            return { ...value };
          });
          setRawMatDropDown(res.data[0].rows);
          UpdateIndex("Tracking", 0, "Track_3_am", time);
          UpdateIndex("Tracking", 1, "Track_3_am", value);
          UpdateIndex("Tracking", 2, "Track_3_am", "");
          UpdateIndex("Tracking", 3, "Track_3_am", "");
          UpdateIndex("Tracking", 4, "Track_3_am", "");
        }
        // setRawMat(RawTracking);
        // if (Records.Tracking[1].Track_23_pm == value) {
        //   UpdateIndex("Tracking", 0, "Track_3_am", time);
        //   UpdateIndex("Tracking", 1, "Track_3_am", value);
        //   UpdateIndex(
        //     "Tracking",
        //     2,
        //     "Track_3_am",
        //     Records.Tracking[2].Track_23_pm
        //   );
        //   UpdateIndex(
        //     "Tracking",
        //     3,
        //     "Track_3_am",
        //     Records.Tracking[3].Track_23_pm
        //   );
        //   UpdateIndex(
        //     "Tracking",
        //     4,
        //     "Track_3_am",
        //     Records.Tracking[4].Track_23_pm
        //   );
        // } else if (
        //   res.data[0].count == "1" ||
        //   (res.data[0].count > "0" &&
        //     res.data[0].rows.find((option) => option.two_k_part === "0") &&
        //     Records.Tracking[1].Track_23_pm != value)
        // ) {
        //   setRawMat(RawTracking);
        //   let resp = res.data[0].rows[0];
        //   UpdateIndex("Tracking", 0, "Track_3_am", time);
        //   UpdateIndex("Tracking", 2, "Track_3_am", resp.Colour);
        //   UpdateIndex("Tracking", 3, "Track_3_am", resp.rm_description);
        //   UpdateIndex("Tracking", 4, "Track_3_am", resp.Grade);
        //   UpdateIndex("Tracking", 1, "Track_3_am", value);
        // } else if (
        //   res.data[0].count > "0" &&
        //   res.data[0].rows.find((option) => option.two_k_part === "1") &&
        //   Records.Tracking[1].Track_23_pm != value
        // ) {
        //   setRawMat((value) => {
        //     value.Track_3_am = true;
        //     return { ...value };
        //   });
        //   setRawMatDropDown(res.data[0].rows);
        //   UpdateIndex("Tracking", 0, "Track_3_am", time);
        //   UpdateIndex("Tracking", 1, "Track_3_am", value);
        //   UpdateIndex("Tracking", 2, "Track_3_am", "");
        //   UpdateIndex("Tracking", 3, "Track_3_am", "");
        //   UpdateIndex("Tracking", 4, "Track_3_am", "");
        // }
        let Heat = Records.Heat.map((item) => {
          item.Heat_actual_6 = "";
          return item;
        });
        let Injection = Records.Injection.map((item) => {
          item.Inject_actual_6 = "";
          return item;
        });
        UpdateObject("Heat", Heat);
        UpdateObject("Injection", Injection);
      });
      Records.PartWeight1 === "" &&
        Records.Two_k_part == "0" &&
        LoadSMP(value, "");
      UpdateObject("Parts", [{ partname: value }]);
    }
  }

  function ResetRecords() {
    let record = InitRecord;
    record.NewRecord = true;
    record.Disable = {
      Hour1: true,
      Hour2: true,
      Hour3: true,
      Hour4: true,
      Hour5: true,
      Hour6: true,
    };
    setRecords(record);
  }

  function handleCreate(Events) {
    Events.preventDefault();
    let Record = {};
    let remove = [
      "EmpDetails",
      "Mtc",
      "popup",
      "Parts",
      "Disable",
      "Machines",
      "SMP",
      "InsertRecord",
      "NewRecord",
      "MachineNo",
      "ShiftInchargeC",
      "ShiftInchargeA",
      "ShiftInchargeB",
      "LineInspecQaC",
      "LineInspecQaB",
      "MouldChange",
      "LineInspecQaA",
    ];
    Object.keys(Records).map((item) => {
      if (!remove.includes(item)) {
        Record[item] = Records[item];
      }
    });
    CreateRecord("Set_PMS_Records", [Record]).then((res) => {
      if (res.success) {
        alert("Successfully Submitted");
        setRawMat(RawTracking);
        ResetRecords();
        setRawMatDropDown([]);
      } else {
        alert("submission  Failed");
      }
    });
  }

  function handleUpdate(Events) {
    Events.preventDefault();
    let Record = {};
    let remove = [
      "EmpDetails",
      "Mtc",
      "popup",
      "Parts",
      "Disable",
      "Machines",
      "SMP",
      "InsertRecord",
      "NewRecord",
      "MachineNo",
      "ShiftInchargeC",
      "ShiftInchargeA",
      "ShiftInchargeB",
      "LineInspecQaC",
      "LineInspecQaB",
      "MouldChange",
      "LineInspecQaA",
    ];
    Object.keys(Records).map((item) => {
      if (!remove.includes(item)) {
        Record[item] = Records[item];
      }
    });

    let noId = {};
    let fields = ["Heat", "Injection"];
    let mapping = { Heat: "PMS_Heat", Injection: "PMS_Injection" };

    fields.map((field) => {
      Record[field].map((data) => {
        if (!noId[field]) {
          noId[field] = data.Id === undefined ? true : false;
        }
      });
    });

    Object.keys(noId).map((key) => {
      if (noId[key]) {
        DeleteOriginalRecord(mapping[key], [{ Pms_id: Record.Id }]).then(
          (res) => {
            let data = [];
            console.log("Record[key]", Record, Record[key], key);
            Record[key].map((single) => {
              let obj = {};
              Object.keys(single).map((item) => {
                if (
                  ![
                    "Id",
                    "CreatedDate",
                    "CreatedBy",
                    "Pms_id",
                    "ModifiedDate",
                  ].includes(item)
                ) {
                  obj[item] = single[item];
                }
              });
              obj.Pms_id = Record.Id;
              data.push(obj);
            });
            console.log("Record[" + key + "] - ", data);
            CreateRecord(mapping[key], data);
            delete Record[key];
          }
        );
      }
    });

    UpdateRecord("Set_PMS_Records", [Record]).then((res) => {
      if (res.success) {
        alert("Successfully Updated");
        // Navigate(-1);
        setRawMat(RawTracking);
        ResetRecords();
        setRawMatDropDown([]);
      } else {
        alert("Update Failed");
        window.location.reload();
        // Navigate(-1);
      }
    });
  }

  function handleUpdateCreate(Events) {
    let NEWPMS = { ...InitRecord };
    let remove = [
      "EmpDetails",
      "Mtc",
      "popup",
      "Parts",
      "Disable",
      "Machines",
      "SMP",
      "InsertRecord",
      "NewRecord",
      "MachineNo",
      "ShiftInchargeC",
      "ShiftInchargeA",
      "ShiftInchargeB",
      "LineInspecQaC",
      "LineInspecQaB",
      "MouldChange",
      "LineInspecQaA",
    ];
    let check = [
      "Track_7_am",
      "Track_11_am",
      "Track_15_pm",
      "Track_19_pm",
      "Track_23_pm",
      "Track_3_am",
    ];

    let endindex = -1;
    let hour = time.split(":")[0];
    if (hour > "8" && hour < "12") {
      endindex = 0;
    } else if (hour >= "12" && hour < "16") {
      endindex = 1;
    } else if (hour >= "16" && hour < "20") {
      endindex = 2;
    } else if (hour >= "20" && hour < "24") {
      endindex = 3;
    } else if (hour >= "24" || hour < "4") {
      endindex = 4;
    } else if (hour >= "4" && hour < "8") {
      endindex = 5;
    }
    // console.log("TEst1", endindex, hour, [...Array(endindex).keys()]);

    let Tracking = [];

    if (Records.NewRecord === true) {
      // creating dupilicate for the new Mould for same machine
      let newRecord = InitRecord;
      let newRecordForSubmission = {};
      check.map((position, value) => {
        if (value <= endindex) {
          Tracking = newRecord.Tracking.map((record, index) => {
            if (index === 0) {
              record[position] = time;
            } else {
              record[position] = "new";
            }
            return record;
          });
        }
      });
      newRecord.Tracking = Tracking;
      newRecord.TrackDate = Records.TrackDate;
      newRecord.Mcno = Records.Mcno;
      newRecord.MouldChangeHour = Events;

      Object.keys(newRecord).map((item) => {
        if (!remove.includes(item)) {
          newRecordForSubmission[item] = newRecord[item];
        }
      });

      // console.log("newRecordForSubmission",newRecordForSubmission);
      CreateRecord("Set_PMS_Records", [newRecordForSubmission]).then((res) => {
        if (res.success) {
          alert("Successfully Created");
          window.location.reload();
        } else {
          alert("submission  Failed");
        }
      });
    } else {
      check.map((position, value) => {
        if (value >= endindex && Records.Tracking[1][position] === "") {
          Tracking = Records.Tracking.map((record, index) => {
            if (index === 0) {
              record[position] = time;
            } else {
              record[position] = "MOULD CHANGE";
            }
            return record;
          });
        }
      });

      let Record = {};

      Object.keys(Records).map((item) => {
        if (!remove.includes(item)) {
          Record[item] = Records[item];
        }
      });
      Record.Tracking = Tracking;
      Record.MouldChangeHour = Events;

      // update the record for mould change
      UpdateRecord("Set_PMS_Records", [Record]).then((res) => {
        if (res.success) {
          alert("Successfully Updated");
          window.location.reload();
        } else {
          alert("Update Failed");
        }
      });

      // creating dupilicate for the new Mould for same machine
      let newRecord = InitRecord;
      let newRecordForSubmission = {};
      check.map((position, value) => {
        if (value <= endindex) {
          Tracking = newRecord.Tracking.map((record, index) => {
            if (index === 0) {
              record[position] = time;
            } else {
              record[position] = "new";
            }
            return record;
          });
        }
      });
      newRecord.Tracking = Tracking;
      newRecord.TrackDate = Records.TrackDate;
      newRecord.Mcno = Records.Mcno;
      newRecord.MouldChangeHour = Events;

      Object.keys(newRecord).map((item) => {
        if (!remove.includes(item)) {
          newRecordForSubmission[item] = newRecord[item];
        }
      });

      // console.log("newRecordForSubmission",newRecordForSubmission);
      CreateRecord("Set_PMS_Records", [newRecordForSubmission]).then((res) => {
        if (res.success) {
          alert("Successfully Created");
          window.location.reload();
        } else {
          alert("submission  Failed");
        }
      });
    }

    // let result = check
    //   .map((item, index) => {
    //     if (Records.Tracking[1][item] !== "") return index;
    //   })
    //   .filter((item) => item != undefined);
    // result = result[result.length - 1];
    // let Tracking = [];
    // let Heat = [];
    // let Injection = [];

    // if (result != undefined) {
    //   check.map((item, index) => {
    //     if (index > result) {
    //       Tracking = Records.Tracking.map((record, index) => {
    //         if (index === 0) {
    //           record[item] = time;
    //         } else {
    //           record[item] = "MOULD CHANGE";
    //         }
    //         return record;
    //       });
    //       // Heat = Records.Heat.map((record) => {
    //       //   record["Heat_actual_" + (index + 1)] = "MOULD CHANGE";
    //       //   return record;
    //       // });
    //       // Injection = Records.Injection.map((record) => {
    //       //   record["Inject_actual_" + (index + 1)] = "MOULD CHANGE";
    //       //   return record;
    //       // });
    //     }
    //   });

    //   let Record = {};

    //   Object.keys(Records).map((item) => {
    //     if (!remove.includes(item)) {
    //       Record[item] = Records[item];
    //     }
    //   });
    //   Record.Tracking = Tracking;
    //   Record.Heat = Heat;
    //   Record.Injection = Injection;
    //   Record.MouldChangeHour = Events;

    //   // console.log("Result records", Record, result);

    // }
    // // result = result === undefined ? 5 : result;
    //
    // let row = Object.keys(Records.Disable)
    //   .map((item, index) => {
    //     if (Records.Disable[item] != true) return index;
    //   })
    //   .filter((item) => item != undefined);
    // let len = row.length;
    // row = row[len - 1];

    // result = result === undefined ? (row === 0 ? 0 : row - 1) : result;

    // // if(result!=undefined){
    // check.map((item, index) => {
    //   if (index <= result) {
    //     Tracking = InitRecord.Tracking.map((record, index) => {
    //       if (index === 0) {
    //         record[item] = time;
    //       } else {
    //         record[item] = "New MOULD";
    //       }
    //       return record;
    //     });
    //     Heat = InitRecord.Heat.map((record) => {
    //       record["Heat_actual_" + (index + 1)] = "New MOULD";
    //       return record;
    //     });
    //     Injection = InitRecord.Injection.map((record) => {
    //       record["Inject_actual_" + (index + 1)] = "New MOULD";
    //       return record;
    //     });
    //   }
    // });
    // // }else{
    // //     let row = Object.keys(Records.Disable).map((item,index)=>{
    // //       if(Records.Disable[item]!=true) return index
    // //     }).filter(item=>item!=undefined)
    // //     let len = row.length
    // //     row = row[len-1]

    // //     // console.log("rows - ",row,len);
    // //     Tracking = InitRecord.Tracking.map((record, index) => {
    // //       if (index === 0) {
    // //         record[check[row]] = time;
    // //       } else {
    // //         record[check[row]] = "MOULD CHANGE";
    // //       }
    // //       return record;
    // //     });
    // //     Heat = InitRecord.Heat.map((record) => {
    // //       record["Heat_actual_"+(row+1)] = "MOULD CHANGE";
    // //       return record;
    // //     });
    // //     Injection = InitRecord.Injection.map((record) => {
    // //       record["Inject_actual_"+(row+1)] = "MOULD CHANGE";
    // //       return record;
    // //     });
    // //   }
    //

    //
  }

  const contentRef = useRef(null);
  const generatePDF = () => {
    document.querySelector(".table1").style.display = "table";
    const pdf = new jsPDF("potrait", "mm", "a4");
    html2canvas(contentRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 5, 5, 200, 240);
      pdf.save(`PMS.pdf`);
    });
    setTimeout(() => {
      document.querySelector(".table1").style.display = "none";
    }, 1000);
  };

  const keywords = {
    Tempcore1: {
      ArrowUp: `${Records.Injection.length}-Inject_actual_1`,
      ArrowDown: "Tempcav1",
      ArrowLeft: Records.Injection.length + "-Inject_actual_6",
      ArrowRight: "Tempcore2",
    },
    Tempcore2: {
      ArrowUp: Records.Injection.length + "-Inject_actual_3",
      ArrowDown: "Tempcav2",
      ArrowLeft: "Tempcore1",
      ArrowRight: "Tempcore3",
    },
    Tempcore3: {
      ArrowUp: Records.Injection.length + "-Inject_actual_5",
      ArrowDown: "Tempcav3",
      ArrowLeft: "Tempcore2",
      ArrowRight: "Tempcav1",
    },
    Tempcav1: {
      ArrowUp: "Tempcore1",
      ArrowDown: "Tempcore4",
      ArrowLeft: "Tempcore3",
      ArrowRight: "Tempcav2",
    },
    Tempcav2: {
      ArrowUp: "Tempcore2",
      ArrowDown: "Tempcore5",
      ArrowLeft: "Tempcav1",
      ArrowRight: "Tempcav3",
    },
    Tempcav3: {
      ArrowUp: "Tempcore3",
      ArrowDown: "Tempcore6",
      ArrowLeft: "Tempcav2",
      ArrowRight: "Tempcore4",
    },
    Tempcore4: {
      ArrowUp: "Tempcav1",
      ArrowDown: "Tempcav4",
      ArrowLeft: "Tempcav3",
      ArrowRight: "Tempcore5",
    },
    Tempcore5: {
      ArrowUp: "Tempcav2",
      ArrowDown: "Tempcav5",
      ArrowLeft: "Tempcore4",
      ArrowRight: "Tempcore6",
    },
    Tempcore6: {
      ArrowUp: "Tempcav3",
      ArrowDown: "Tempcav6",
      ArrowLeft: "Tempcore5",
      ArrowRight: "Tempcav4",
    },
    Tempcav4: {
      ArrowUp: "Tempcore4",
      ArrowDown: "",
      ArrowLeft: "Tempcore6",
      ArrowRight: "Tempcav5",
    },
    Tempcav5: {
      ArrowUp: "Tempcore5",
      ArrowDown: "",
      ArrowLeft: "Tempcav4",
      ArrowRight: "Tempcav6",
    },
    Tempcav6: {
      ArrowUp: "Tempcore6",
      ArrowDown: "",
      ArrowLeft: "Tempcav5",
      ArrowRight: "dropdown1",
    },
    //  dropdown1: { ArrowUp: 'Tempcav4', ArrowDown: '', ArrowLeft: 'Tempcav6', ArrowRight: 'dropdown2' },
    //  dropdown2: { ArrowUp: 'Tempcav5', ArrowDown: '', ArrowLeft: 'dropdown1', ArrowRight: 'dropdown3' },
    //  dropdown3: { ArrowUp: 'Tempcav6', ArrowDown: '', ArrowLeft: 'dropdown2', ArrowRight: '' }
  };
  function FindKeyPress(key, arrow) {
    if (keywords[key] && keywords[key][arrow]) {
      const nextInputId = keywords[key][arrow];
      // console.log("Next input ID:", nextInputId);

      if (nextInputId === "dropdown1") {
        setdropdown1(true);
      }
      //  else if (nextInputId === 'dropdown2') {
      //   setdropdown2(true);
      //  }
      else {
        setdropdown1(false);
        //    setdropdown2(false);
        const nextInput = document.getElementById(nextInputId);
        if (nextInput) {
          nextInput.focus();
        }
      }
    } else {
      console.error("Invalid key or arrow:", key, arrow);
    }
  }

  return (
    <QaLayout>
      {option === "view" && (
        <div className="Pdf">
          <Dropdown style={{ width: "100%", alignItems: "center" }}>
            <Dropdown.Toggle style={{ color: "black" }}>
              Download ⬇️
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>
                <button
                  style={{ color: "black", width: "100%" }}
                  onClick={generatePDF}
                >
                  PDF ⬇️
                </button>
              </Dropdown.Item>
              {/* <Dropdown.Item>
                <button style={{ color: "black" }}onClick={generatePDF}>Export to Excel</button>
              </Dropdown.Item>*/}
            </Dropdown.Menu> 
          </Dropdown>
        </div>
      )}
      <div>
        {Records.popup && (
          <div
            style={{
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(163, 158, 160, .1)",
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backdropFilter: "blur(2px)",
              top: "0",
              left: "0",
              zIndex: "99",
            }}
          >
            <div
              style={{
                backgroundColor: "#4c4c4d",
                borderRadius: "10px",
                padding: "0px 0px 20px 0px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#323233",
                  top: "0",
                  left: "0",
                  width: "100%",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "20px 60px",
                  fontSize: "20px",
                  color: "#cbd4cb",
                  fontWeight: "bold",
                }}
              >
                Mould Change Has Initated
              </div>
              <div
                style={{
                  textAlign: "center",
                  color: "#b9b9bd",
                  marginTop: "20px",
                  padding: "10px",
                }}
              >
                {" "}
                Request Time - {time}
              </div>
              <div style={{ textAlign: "center", color: "#b9b9bd" }}>
                {" "}
                Are you sure, You are doing Mould Change
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                  marginTop: "20px",
                }}
              >
                <button
                  style={{ borderRadius: "10px", backgroundColor: "#67e667" }}
                  onClick={() =>
                    handleUpdateCreate("Mould Changed Time - " + time)
                  }
                >
                  Confirm
                </button>
                <button
                  style={{ borderRadius: "10px" }}
                  onClick={() => UpdateObject("popup", false)}
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
        )}
        <form
          className="form-pms-Create"
          onSubmit={(e) => {
            e.preventDefault();
            // handleCreate(e);
          }}
        >
          <table
            className="table"
            width="100%"
            style={{ textAlign: "center" }}
            border="0"
          >
            <tbody>
              <tr style={{ textAlign: "center" }}>
                <th colSpan="4">
                  <div style={{ textAlign: "center" }}>
                    <font color="#8B0000">PROCESS MONITORING SYSTEM</font>
                  </div>
                  <div>
                    <span>
                      <input
                        style={{ width: "20px", height: "20px" }}
                        type="checkbox"
                        name="Two_k_part"
                        value={Records}
                        checked={Records.Two_k_part == "1"}
                        disabled={option == "view" || option == "edit"}
                        onChange={(e) => {
                          HandleRecordsBoolean(e);
                        }}
                      />
                      Enable 2K Part
                    </span>
                  </div>
                </th>
              </tr>
              <tr>
                <td>Format No</td>
                <td style={{ textAlign: "center" }}>
                  <label>UPCL/PRO/R/28</label>
                </td>

                <td>Rev No</td>
                <td style={{ textAlign: "center" }}>
                  <label>02</label>
                </td>
              </tr>
              <tr>
                <td>Track Date</td>
                <td>
                  <DatePicker value={Records.TrackDate} dis={true} />
                </td>
                <td>
                  M/C NO<span style={{ color: "red" }}>*</span>
                </td>
                <td>
                  {Records.Mcno === undefined && (
                    <span className="Req">Required *</span>
                  )}
                  <Select
                    id="dropdown"
                    styles={{ width: "100%" }}
                    options={Records.Machines}
                    onFocus={() => {
                      ReadRecord(
                        `SELECT Machine_no as value, Machine_no as label FROM production_smp_parent where Two_k_part='${Records.Two_k_part}' group by Machine_no`
                      ).then((res) => {
                        UpdateObject("Machines", res.data[0].rows);
                      });
                    }}
                    value={{
                      value: Records?.MachineNo[0]?.value,
                      label: Records?.MachineNo[0]?.label,
                    }}
                    onChange={HandleMachine}
                    placeholder="Select Machine No"
                    required
                    isDisabled={
                      option === "view"
                      // || Records?.MachineNo[0]?.value !== undefined
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <table
            className="table"
            width="50%"
            style={{ textAlign: "left" }}
            border="0"
          >
            <tbody>
              <tr>
                <th colSpan="15"></th>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td colSpan="2">
                  Part weight1 (-{Records.PartWeightMinus1}/+
                  {Records.PartWeightPlus1})
                </td>

                <td>
                  <input
                    style={{ width: "100%" }}
                    type="number"
                    name="PartWeight1"
                    value={Records.PartWeight1}
                    // onChange={HandleFeildChangePMS}
                    disabled
                  />
                </td>
                <td></td>
                <td>
                  <input
                    style={
                      !(
                        parseFloat(Records.PW1) >
                          parseFloat(Records.PartWeight1) +
                            parseFloat(Records.PartWeightPlus1) ||
                        parseFloat(Records.PW1) <
                          parseFloat(Records.PartWeight1) -
                            parseFloat(Records.PartWeightMinus1)
                      )
                        ? { width: "100%" }
                        : {
                            width: "100%",
                            backgroundColor: "#d98984",
                            border: "2px solid #DA251A",
                          }
                    }
                    type="text"
                    name="PW1"
                    id="PW1"
                    value={Records.PW1}
                    disabled={Records.Disable.Hour1}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }
                      UpdateObject("PW1", e.target.value);
                    }}
                  />
                </td>

                <td>
                  <input
                    style={
                      !(
                        parseFloat(Records.PW2) >
                          parseFloat(Records.PartWeight1) +
                            parseFloat(Records.PartWeightPlus1) ||
                        parseFloat(Records.PW2) <
                          parseFloat(Records.PartWeight1) -
                            parseFloat(Records.PartWeightMinus1)
                      )
                        ? { width: "100%" }
                        : {
                            width: "100%",
                            backgroundColor: "#d98984",
                            border: "2px solid #DA251A",
                          }
                    }
                    type="text"
                    name="PW2"
                    id="PW2"
                    value={Records.PW2}
                    disabled={Records.Disable.Hour2}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }
                      UpdateObject("PW2", e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    style={
                      !(
                        parseFloat(Records.PW3) >
                          parseFloat(Records.PartWeight1) +
                            parseFloat(Records.PartWeightPlus1) ||
                        parseFloat(Records.PW3) <
                          parseFloat(Records.PartWeight1) -
                            parseFloat(Records.PartWeightMinus1)
                      )
                        ? { width: "100%" }
                        : {
                            width: "100%",
                            backgroundColor: "#d98984",
                            border: "2px solid #DA251A",
                          }
                    }
                    type="text"
                    name="PW3"
                    id="PW3"
                    value={Records.PW3}
                    disabled={Records.Disable.Hour3}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }
                      UpdateObject("PW3", e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    style={
                      !(
                        parseFloat(Records.PW4) >
                          parseFloat(Records.PartWeight1) +
                            parseFloat(Records.PartWeightPlus1) ||
                        parseFloat(Records.PW4) <
                          parseFloat(Records.PartWeight1) -
                            parseFloat(Records.PartWeightMinus1)
                      )
                        ? { width: "100%" }
                        : {
                            width: "100%",
                            backgroundColor: "#d98984",
                            border: "2px solid #DA251A",
                          }
                    }
                    type="text"
                    name="PW4"
                    id="PW4"
                    value={Records.PW4}
                    disabled={Records.Disable.Hour4}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }

                      UpdateObject("PW4", e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    style={
                      !(
                        parseFloat(Records.PW5) >
                          parseFloat(Records.PartWeight1) +
                            parseFloat(Records.PartWeightPlus1) ||
                        parseFloat(Records.PW5) <
                          parseFloat(Records.PartWeight1) -
                            parseFloat(Records.PartWeightMinus1)
                      )
                        ? { width: "100%" }
                        : {
                            width: "100%",
                            backgroundColor: "#d98984",
                            border: "2px solid #DA251A",
                          }
                    }
                    type="text"
                    name="PW5"
                    id="PW5"
                    value={Records.PW5}
                    disabled={Records.Disable.Hour5}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }
                      UpdateObject("PW5", e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    style={
                      !(
                        parseFloat(Records.PW6) >
                          parseFloat(Records.PartWeight1) +
                            parseFloat(Records.PartWeightPlus1) ||
                        parseFloat(Records.PW6) <
                          parseFloat(Records.PartWeight1) -
                            parseFloat(Records.PartWeightMinus1)
                      )
                        ? { width: "100%" }
                        : {
                            width: "100%",
                            backgroundColor: "#d98984",
                            border: "2px solid #DA251A",
                          }
                    }
                    type="text"
                    name="PW6"
                    id="PW6"
                    value={Records.PW6}
                    disabled={Records.Disable.Hour6}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }
                      UpdateObject("PW6", e.target.value);
                    }}
                  />
                </td>
              </tr>
              {Records.PartWeight2 != "" && (
                <tr style={{ textAlign: "center" }}>
                  <td colSpan="2">
                    Part weight2 (-{Records.PartWeightMinus2}/+
                    {Records.PartWeightPlus2})
                  </td>

                  <td>
                    <input
                      style={{ width: "100%" }}
                      type="text"
                      name="PartWeight2"
                      value={Records.PartWeight2}
                      // onChange={HandleFeildChangePMS}
                      disabled
                    />
                  </td>
                  <td></td>
                  <td>
                    <input
                      style={
                        !(
                          parseFloat(Records.PW11) >
                            parseFloat(Records.PartWeight2) +
                              parseFloat(Records.PartWeightPlus2) ||
                          parseFloat(Records.PW11) <
                            parseFloat(Records.PartWeight2) -
                              parseFloat(Records.PartWeightMinus2)
                        )
                          ? { width: "100%" }
                          : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                      }
                      type="text"
                      name="PW11"
                      id="PW11"
                      value={Records.PW11}
                      disabled={Records.Disable.Hour1}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (InvalidCharacters(value)) {
                          e.preventDefault();
                          return;
                        }
                        UpdateObject("PW11", e.target.value);
                      }}
                    />
                  </td>

                  <td>
                    <input
                      style={
                        !(
                          parseFloat(Records.PW21) >
                            parseFloat(Records.PartWeight2) +
                              parseFloat(Records.PartWeightPlus2) ||
                          parseFloat(Records.PW21) <
                            parseFloat(Records.PartWeight2) -
                              parseFloat(Records.PartWeightMinus2)
                        )
                          ? { width: "100%" }
                          : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                      }
                      type="text"
                      name="PW21"
                      id="PW21"
                      value={Records.PW21}
                      disabled={Records.Disable.Hour2}
                      onChange={(e) => {
                        UpdateObject("PW21", e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      style={
                        !(
                          parseFloat(Records.PW31) >
                            parseFloat(Records.PartWeight2) +
                              parseFloat(Records.PartWeightPlus2) ||
                          parseFloat(Records.PW31) <
                            parseFloat(Records.PartWeight2) -
                              parseFloat(Records.PartWeightMinus2)
                        )
                          ? { width: "100%" }
                          : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                      }
                      type="text"
                      name="PW31"
                      id="PW31"
                      value={Records.PW31}
                      disabled={Records.Disable.Hour3}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (InvalidCharacters(value)) {
                          e.preventDefault();
                          return;
                        }
                        UpdateObject("PW31", e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      style={
                        !(
                          parseFloat(Records.PW41) >
                            parseFloat(Records.PartWeight2) +
                              parseFloat(Records.PartWeightPlus2) ||
                          parseFloat(Records.PW41) <
                            parseFloat(Records.PartWeight2) -
                              parseFloat(Records.PartWeightMinus2)
                        )
                          ? { width: "100%" }
                          : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                      }
                      type="text"
                      name="PW41"
                      id="PW41"
                      value={Records.PW41}
                      disabled={Records.Disable.Hour4}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (InvalidCharacters(value)) {
                          e.preventDefault();
                          return;
                        }
                        UpdateObject("PW41", e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      style={
                        !(
                          parseFloat(Records.PW51) >
                            parseFloat(Records.PartWeight2) +
                              parseFloat(Records.PartWeightPlus2) ||
                          parseFloat(Records.PW51) <
                            parseFloat(Records.PartWeight2) -
                              parseFloat(Records.PartWeightMinus2)
                        )
                          ? { width: "100%" }
                          : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                      }
                      type="text"
                      name="PW51"
                      id="PW51"
                      value={Records.PW51}
                      disabled={Records.Disable.Hour5}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (InvalidCharacters(value)) {
                          e.preventDefault();
                          return;
                        }
                        UpdateObject("PW51", e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      style={
                        !(
                          parseFloat(Records.PW61) >
                            parseFloat(Records.PartWeight2) +
                              parseFloat(Records.PartWeightPlus2) ||
                          parseFloat(Records.PW61) <
                            parseFloat(Records.PartWeight2) -
                              parseFloat(Records.PartWeightMinus2)
                        )
                          ? { width: "100%" }
                          : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                      }
                      type="text"
                      name="PW61"
                      id="PW61"
                      value={Records.PW61}
                      disabled={Records.Disable.Hour6}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (InvalidCharacters(value)) {
                          e.preventDefault();
                          return;
                        }
                        UpdateObject("PW61", e.target.value);
                      }}
                    />
                  </td>
                </tr>
              )}
              <tr>
                <th width="7%" style={{ textAlign: "center", width: "1%" }}>
                  SL
                </th>
                <th
                  style={{
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    width: "15.5%",
                  }}
                >
                  Description
                </th>
                <th width="15%" style={{ textAlign: "center", width: "9%" }}>
                  Inst used
                </th>
                <th width="20%" style={{ textAlign: "center", width: "11%" }}>
                  Raw-Material
                </th>
                <th width="7%" style={{ textAlign: "center" }}>
                  Actual Level
                </th>
                <th width="7%" style={{ textAlign: "center" }}>
                  Actual Level
                </th>
                <th width="7%" style={{ textAlign: "center" }}>
                  Actual Level
                </th>
                <th width="7%" style={{ textAlign: "center" }}>
                  Actual Level
                </th>
                <th width="7%" style={{ textAlign: "center" }}>
                  Actual Level
                </th>
                <th width="7%" style={{ textAlign: "center" }}>
                  Actual Level
                </th>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>1</td>
                <td style={{ whiteSpace: "nowrap" }}>Raw-Material-Level</td>
                <td>On hooper</td>
                <td>Min level/Max Level</td>
                <td>
                  <select
                    id="dropdown"
                    style={{ width: "100%" }}
                    name="RM1"
                    value={Records.RM1}
                    onChange={(e) => {
                      UpdateObject("RM1", e.target.value);
                    }}
                    disabled={Records.Disable.Hour1}
                  >
                    <option value="NA">N A</option>
                    <option value="Active">✓</option>
                    <option value="Inactive">X</option>
                  </select>
                </td>
                <td>
                  <select
                    id="dropdown"
                    style={{ width: "100%" }}
                    name="RM2"
                    value={Records.RM2}
                    onChange={(e) => {
                      UpdateObject("RM2", e.target.value);
                    }}
                    disabled={Records.Disable.Hour2}
                  >
                    <option value="NA">N A</option>
                    <option value="Active">✓</option>
                    <option value="Inactive">X</option>
                  </select>
                </td>

                <td>
                  <select
                    id="dropdown"
                    style={{ width: "100%" }}
                    name="RM3"
                    value={Records.RM3}
                    onChange={(e) => {
                      UpdateObject("RM3", e.target.value);
                    }}
                    disabled={Records.Disable.Hour3}
                  >
                    <option value="NA">N A</option>
                    <option value="Active">✓</option>
                    <option value="Inactive">X</option>
                  </select>
                </td>
                <td>
                  <select
                    id="dropdown"
                    style={{ width: "100%" }}
                    name="RM4"
                    value={Records.RM4}
                    onChange={(e) => {
                      UpdateObject("RM4", e.target.value);
                    }}
                    disabled={Records.Disable.Hour4}
                  >
                    <option value="NA">N A</option>
                    <option value="Active">✓</option>
                    <option value="Inactive">X</option>
                  </select>
                </td>
                <td>
                  <select
                    id="dropdown"
                    style={{ width: "100%" }}
                    name="RM5"
                    value={Records.RM5}
                    onChange={(e) => {
                      UpdateObject("RM5", e.target.value);
                    }}
                    disabled={Records.Disable.Hour5}
                  >
                    <option value="NA">N A</option>
                    <option value="Active">✓</option>
                    <option value="Inactive">X</option>
                  </select>
                </td>
                <td>
                  <select
                    id="dropdown"
                    style={{ width: "100%" }}
                    name="RM6"
                    value={Records.RM6}
                    onChange={(e) => {
                      UpdateObject("RM6", e.target.value);
                    }}
                    disabled={Records.Disable.Hour6}
                  >
                    <option value="NA">N A</option>
                    <option value="Active">✓</option>
                    <option value="Inactive">X</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>

          <table
            className="table"
            width="50%"
            style={{ textAlign: "center" }}
            border="0"
          >
            <tbody>
              <tr style={{ textAlign: "center" }}>
                <th
                  style={{ textAlign: "center", position: "relative" }}
                  colSpan="15"
                >
                  Tracking Machine
                  <div
                    style={{
                      position: "absolute",
                      right: "0",
                      top: "5px",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      paddingRight: "10px",
                    }}
                  >
                    <div>{Records.MouldChangeHour}</div>
                    <button
                      type="button"
                      style={{ backgroundColor: "#fc535b", color: "black" }}
                      onClick={() => UpdateObject("popup", true)}
                      disabled={
                        Records?.MachineNo[0]?.value === undefined ||
                        option === "view"
                      }
                    >
                      Mould Change
                    </button>
                  </div>
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    width: "39%",
                  }}
                >
                  Time to be updated
                </th>
                <th
                  width="5%"
                  style={{ textAlign: "center", whiteSpace: "break-spaces" }}
                >
                  08.00 AM - 09.00 AM
                </th>
                <th
                  width="5%"
                  style={{ textAlign: "center", whiteSpace: "break-spaces" }}
                >
                  12.00 AM - 13.00 AM
                </th>
                <th
                  width="5%"
                  style={{ textAlign: "center", whiteSpace: "break-spaces" }}
                >
                  16.00 PM - 17.00 PM
                </th>
                <th
                  width="5%"
                  style={{ textAlign: "center", whiteSpace: "break-spaces" }}
                >
                  20.00 PM - 21.00 PM
                </th>
                <th
                  width="5%"
                  style={{ textAlign: "center", whiteSpace: "break-spaces" }}
                >
                  24.00 PM - 01.00 AM
                </th>
                <th
                  width="5%"
                  style={{ textAlign: "center", whiteSpace: "break-spaces" }}
                >
                  04.00 AM - 05.00 AM
                </th>
              </tr>
              <tr>
                <td>{Records.Tracking[0].Time_to_be_updated}</td>
                <td>
                  <input
                    type="text"
                    value={Records.Tracking[0].Track_7_am}
                    onChange={(e) =>
                      UpdateIndex("Tracking", 0, "Track_7_am", e.target.value)
                    }
                    disabled
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={Records.Tracking[0].Track_11_am}
                    onChange={(e) =>
                      UpdateIndex("Tracking", 0, "Track_11_am", e.target.value)
                    }
                    disabled
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={Records.Tracking[0].Track_15_pm}
                    onChange={(e) =>
                      UpdateIndex("Tracking", 0, "Track_15_pm", e.target.value)
                    }
                    disabled
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={Records.Tracking[0].Track_19_pm}
                    onChange={(e) =>
                      UpdateIndex("Tracking", 0, "Track_19_pm", e.target.value)
                    }
                    disabled
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={Records.Tracking[0].Track_23_pm}
                    onChange={(e) =>
                      UpdateIndex("Tracking", 0, "Track_23_pm", e.target.value)
                    }
                    disabled
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={Records.Tracking[0].Track_3_am}
                    onChange={(e) =>
                      UpdateIndex("Tracking", 0, "Track_3_am", e.target.value)
                    }
                    disabled
                  />
                </td>
              </tr>
              <tr>
                <td>Part name</td>
                <td>
                  <select
                    title={Records.Tracking[1].Track_7_am}
                    required
                    id="dropdown"
                    name="PartName1"
                    style={{ width: "100%" }}
                    value={Records.Tracking[1].Track_7_am}
                    onChange={handle7am}
                    // onFocus={LoadParts}
                    disabled={
                      Records.Disable.Hour1
                      // || Records.Tracking[1].Track_7_am === "new"
                    }
                  >
                    <option value="">Select Part Name</option>
                    <option value="IDEAL">IDEAL</option>
                    <option value="new">New MOULD</option>
                    {/* <option value="MOULD CHANGE">MOULD CHANGE</option> */}
                    {Records.Parts.map((item, index) => (
                      <option key={index} value={item.partname}>
                        {item.partname}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <select
                    title={Records.Tracking[1].Track_11_am}
                    required
                    id="dropdown"
                    name="PartName1"
                    style={{ width: "100%" }}
                    value={Records.Tracking[1].Track_11_am}
                    onChange={handle11am}
                    // onFocus={LoadParts}
                    disabled={
                      Records.Disable.Hour2
                      // || Records.Tracking[1].Track_11_am === "new"
                    }
                  >
                    <option value="">Select Part Name</option>
                    <option value="IDEAL">IDEAL</option>
                    <option value="new">New MOULD</option>
                    {/* <option value="MOULD CHANGE">MOULD CHANGE</option> */}
                    {Records.Parts.map((item, index) => (
                      <option key={index} value={item.partname}>
                        {item.partname}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <select
                    title={Records.Tracking[1].Track_15_pm}
                    required
                    id="dropdown"
                    name="PartName1"
                    style={{ width: "100%" }}
                    value={Records.Tracking[1].Track_15_pm}
                    onChange={handle15pm}
                    // onFocus={LoadParts}
                    disabled={
                      Records.Disable.Hour3
                      // ||Records.Tracking[1].Track_15_pm === "new"
                    }
                  >
                    <option value="">Select Part Name</option>
                    <option value="IDEAL">IDEAL</option>
                    <option value="new">New MOULD</option>
                    {/* <option value="MOULD CHANGE">MOULD CHANGE</option> */}
                    {Records.Parts.map((item, index) => (
                      <option key={index} value={item.partname}>
                        {item.partname}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <select
                    title={Records.Tracking[1].Track_19_pm}
                    required
                    id="dropdown"
                    name="PartName1"
                    style={{ width: "100%" }}
                    value={Records.Tracking[1].Track_19_pm}
                    onChange={handle19pm}
                    // onFocus={LoadParts}
                    disabled={
                      Records.Disable.Hour4
                      // ||Records.Tracking[1].Track_19_pm === "new"
                    }
                  >
                    <option value="">Select Part Name</option>
                    <option value="IDEAL">IDEAL</option>
                    <option value="new">New MOULD</option>
                    {/* <option value="MOULD CHANGE">MOULD CHANGE</option> */}
                    {Records.Parts.map((item, index) => (
                      <option key={index} value={item.partname}>
                        {item.partname}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <select
                    title={Records.Tracking[1].Track_23_pm}
                    required
                    id="dropdown"
                    name="PartName1"
                    style={{ width: "100%" }}
                    value={Records.Tracking[1].Track_23_pm}
                    onChange={handle23pm}
                    // onFocus={LoadParts}
                    disabled={
                      Records.Disable.Hour5
                      // || Records.Tracking[1].Track_23_pm === "new"
                    }
                  >
                    <option value="">Select Part Name</option>
                    <option value="IDEAL">IDEAL</option>
                    <option value="new">New MOULD</option>
                    {/* <option value="MOULD CHANGE">MOULD CHANGE</option> */}
                    {Records.Parts.map((item, index) => (
                      <option key={index} value={item.partname}>
                        {item.partname}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <select
                    title={Records.Tracking[1].Track_3_am}
                    required
                    id="dropdown"
                    name="PartName1"
                    style={{ width: "100%" }}
                    value={Records.Tracking[1].Track_3_am}
                    onChange={handle3am}
                    // onFocus={LoadParts}
                    disabled={
                      Records.Disable.Hour6
                      // || Records.Tracking[1].Track_3_am === "new"
                    }
                  >
                    <option value="">Select Part Name</option>
                    <option value="IDEAL">IDEAL</option>
                    <option value="new">New MOULD</option>
                    {/* <option value="MOULD CHANGE">MOULD CHANGE</option> */}
                    {Records.Parts.map((item, index) => (
                      <option key={index} value={item.partname}>
                        {item.partname}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              {Records.Tracking.map((item, index) => {
                if (index > 1) {
                  return (
                    <tr>
                      <td>{item.Time_to_be_updated}</td>
                      <td>
                        {!RawMat.Track_7_am ? (
                          <input
                            type="text"
                            value={item.Track_7_am}
                            onChange={(e) =>
                              UpdateIndex(
                                "Tracking",
                                index,
                                "Track_7_am",
                                e.target.value
                              )
                            }
                            disabled
                            title={item.Track_7_am}
                          />
                        ) : (
                          RawMat &&
                          index != 3 && (
                            <input
                              type="text"
                              value={item.Track_7_am}
                              onChange={(e) =>
                                UpdateIndex(
                                  "Tracking",
                                  index,
                                  "Track_7_am",
                                  e.target.value
                                )
                              }
                              disabled
                              title={item.Track_7_am}
                            />
                          )
                        )}
                        {index == 3 && RawMat.Track_7_am && (
                          <select
                            title={item.Track_7_am}
                            id="dropdown"
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              Handle_raw_mat_7am(
                                e,
                                index,
                                "Track_7_am",
                                "Track_7_am"
                              );
                            }}
                            disabled={Records.Disable.Hour1}
                          >
                            <option value={""}>Select 2k Raw Material</option>
                            {RawMatDropDown?.map((option) => (
                              <option value={option.Raw_material_name}>
                                {option.rm_description}
                              </option>
                            ))}
                          </select>
                        )}
                      </td>
                      <td>
                        {!RawMat.Track_11_am ? (
                          <input
                            type="text"
                            value={item.Track_11_am}
                            onChange={(e) =>
                              UpdateIndex(
                                "Tracking",
                                index,
                                "Track_11_am",
                                e.target.value
                              )
                            }
                            disabled
                            title={item.Track_11_am}
                          />
                        ) : (
                          RawMat.Track_11_am &&
                          index != 3 && (
                            <input
                              type="text"
                              value={item.Track_11_am}
                              onChange={(e) =>
                                UpdateIndex(
                                  "Tracking",
                                  index,
                                  "Track_11_am",
                                  e.target.value
                                )
                              }
                              disabled
                              title={item.Track_11_am}
                            />
                          )
                        )}
                        {index == 3 && RawMat.Track_11_am && (
                          <select
                            title={item.Track_11_am}
                            id="dropdown"
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              Handle_raw_mat_7am(
                                e,
                                index,
                                "Track_11_am",
                                "Track_7_am"
                              );
                            }}
                            disabled={Records.Disable.Hour2}
                          >
                            <option value={""}>Select 2k Raw Material</option>
                            {RawMatDropDown?.map((option) => (
                              <option value={option.Raw_material_name}>
                                {option.rm_description}
                              </option>
                            ))}
                          </select>
                        )}
                      </td>
                      <td>
                        {!RawMat.Track_15_pm ? (
                          <input
                            type="text"
                            value={item.Track_15_pm}
                            onChange={(e) =>
                              UpdateIndex(
                                "Tracking",
                                index,
                                "Track_15_pm",
                                e.target.value
                              )
                            }
                            disabled
                            title={item.Track_15_pm}
                          />
                        ) : (
                          RawMat.Track_15_pm &&
                          index != 3 && (
                            <input
                              type="text"
                              value={item.Track_15_pm}
                              onChange={(e) =>
                                UpdateIndex(
                                  "Tracking",
                                  index,
                                  "Track_15_pm",
                                  e.target.value
                                )
                              }
                              disabled
                              title={item.Track_15_pm}
                            />
                          )
                        )}
                        {index == 3 && RawMat.Track_15_pm && (
                          <select
                            title={item.Track_15_pm}
                            id="dropdown"
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              Handle_raw_mat_7am(
                                e,
                                index,
                                "Track_15_pm",
                                "Track_11_am"
                              );
                            }}
                            disabled={Records.Disable.Hour3}
                          >
                            <option value={""}>Select 2k Raw Material</option>
                            {RawMatDropDown?.map((option) => (
                              <option value={option.Raw_material_name}>
                                {option.rm_description}
                              </option>
                            ))}
                          </select>
                        )}
                      </td>
                      <td>
                        {!RawMat.Track_19_pm ? (
                          <input
                            type="text"
                            value={item.Track_19_pm}
                            onChange={(e) =>
                              UpdateIndex(
                                "Tracking",
                                index,
                                "Track_19_pm",
                                e.target.value
                              )
                            }
                            disabled
                            title={item.Track_19_pm}
                          />
                        ) : (
                          RawMat.Track_19_pm &&
                          index != 3 && (
                            <input
                              type="text"
                              value={item.Track_19_pm}
                              onChange={(e) =>
                                UpdateIndex(
                                  "Tracking",
                                  index,
                                  "Track_19_pm",
                                  e.target.value
                                )
                              }
                              disabled
                              title={item.Track_19_pm}
                            />
                          )
                        )}
                        {index == 3 && RawMat.Track_19_pm && (
                          <select
                            title={item.Track_19_pm}
                            id="dropdown"
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              Handle_raw_mat_7am(
                                e,
                                index,
                                "Track_19_pm",
                                "Track_15_pm"
                              );
                            }}
                            disabled={Records.Disable.Hour4}
                          >
                            <option value={""}>Select 2k Raw Material</option>
                            {RawMatDropDown?.map((option) => (
                              <option value={option.Raw_material_name}>
                                {option.rm_description}
                              </option>
                            ))}
                          </select>
                        )}
                      </td>
                      <td>
                        {!RawMat.Track_23_pm ? (
                          <input
                            type="text"
                            value={item.Track_23_pm}
                            onChange={(e) =>
                              UpdateIndex(
                                "Tracking",
                                index,
                                "Track_23_pm",
                                e.target.value
                              )
                            }
                            disabled
                            title={item.Track_23_pm}
                          />
                        ) : (
                          RawMat.Track_23_pm &&
                          index != 3 && (
                            <input
                              type="text"
                              value={item.Track_23_pm}
                              onChange={(e) =>
                                UpdateIndex(
                                  "Tracking",
                                  index,
                                  "Track_23_pm",
                                  e.target.value
                                )
                              }
                              disabled
                              title={item.Track_23_pm}
                            />
                          )
                        )}
                        {index == 3 && RawMat.Track_23_pm && (
                          <select
                            title={item.Track_23_pm}
                            id="dropdown"
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              Handle_raw_mat_7am(
                                e,
                                index,
                                "Track_23_pm",
                                "Track_19_pm"
                              );
                            }}
                            disabled={Records.Disable.Hour5}
                          >
                            <option value={""}>Select 2k Raw Material</option>
                            {RawMatDropDown?.map((option) => (
                              <option value={option.Raw_material_name}>
                                {option.rm_description}
                              </option>
                            ))}
                          </select>
                        )}
                      </td>
                      <td>
                        {!RawMat.Track_3_am ? (
                          <input
                            type="text"
                            value={item.Track_3_am}
                            onChange={(e) =>
                              UpdateIndex(
                                "Tracking",
                                index,
                                "Track_3_am",
                                e.target.value
                              )
                            }
                            disabled
                            title={item.Track_3_am}
                          />
                        ) : (
                          RawMat.Track_3_am &&
                          index != 3 && (
                            <input
                              type="text"
                              value={item.Track_3_am}
                              onChange={(e) =>
                                UpdateIndex(
                                  "Tracking",
                                  index,
                                  "Track_3_am",
                                  e.target.value
                                )
                              }
                              disabled
                              title={item.Track_3_am}
                            />
                          )
                        )}
                        {index == 3 && RawMat.Track_3_am && (
                          <select
                            title={item.Track_3_am}
                            id="dropdown"
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              Handle_raw_mat_7am(
                                e,
                                index,
                                "Track_3_am",
                                "Track_23_pm"
                              );
                            }}
                            disabled={Records.Disable.Hour6}
                          >
                            <option value={""}>Select 2k Raw Material</option>
                            {RawMatDropDown?.map((option) => (
                              <option value={option.Raw_material_name}>
                                {option.rm_description}
                              </option>
                            ))}
                          </select>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>

          <table
            className="table"
            width="50%"
            style={{ textAlign: "center" }}
            border="0"
          >
            <tbody>
              <tr style={{ textAlign: "center" }}>
                <th style={{ textAlign: "center" }} colSpan="15">
                  Heating parameter
                </th>
              </tr>
              <tr>
                <th width="8%" style={{ textAlign: "center", width: "1%" }}>
                  SL
                </th>
                <th width="30%" style={{ textAlign: "center" }}>
                  Description
                </th>
                <th width="25%" style={{ textAlign: "center", width: "8%" }}>
                  Inst-used
                </th>
                <th width="5%" style={{ textAlign: "center" }}>
                  Set
                </th>
                <th
                  style={{
                    textAlign: "center",
                    width: "7%",
                    padding: "0px 20px",
                  }}
                >
                  Units
                </th>
                <th width="5%" style={{ textAlign: "center" }}>
                  Actual
                </th>
                <th width="5%" style={{ textAlign: "center" }}>
                  Actual
                </th>
                <th width="5%" style={{ textAlign: "center" }}>
                  Actual
                </th>
                <th width="5%" style={{ textAlign: "center" }}>
                  Actual
                </th>
                <th width="5%" style={{ textAlign: "center" }}>
                  Actual
                </th>
                <th width="5%" style={{ textAlign: "center" }}>
                  Actual
                </th>
              </tr>
              {Records.Heat.map((item, index) => (
                <tr>
                  <td>{item.Heat_sl}</td>
                  <td>{item.Heat_desc}</td>
                  <td>{item.Heat_inst_used}</td>
                  <td>
                    {" "}
                    ({item.Heat_smp_data}) ±{item.Heat_smp_set}°C
                  </td>
                  <td>°C</td>
                  <td>
                    <input
                      id={index + 1 + "-Heat_actual_1"}
                      type="text"
                      value={item.Heat_actual_1}
                      style={
                        !(
                          parseFloat(item.Heat_actual_1) >
                            parseFloat(item.Heat_smp_data) +
                              parseFloat(item.Heat_smp_set) ||
                          parseFloat(item.Heat_actual_1) <
                            parseFloat(item.Heat_smp_data) -
                              parseFloat(item.Heat_smp_set)
                        )
                          ? { width: "100%" }
                          : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                      }
                      onChange={(e) => {
                        const value = e.target.value;
                        if (InvalidCharacters(value)) {
                          e.preventDefault();
                          return;
                        }
                        UpdateIndex(
                          "Heat",
                          index,
                          "Heat_actual_1",
                          e.target.value
                        );
                      }}
                      disabled={Records.Disable.Hour1}
                    />
                  </td>
                  <td>
                    <input
                      id={index + 1 + "-Heat_actual_2"}
                      type="text"
                      value={item.Heat_actual_2}
                      style={
                        !(
                          parseFloat(item.Heat_actual_2) >
                            parseFloat(item.Heat_smp_data) +
                              parseFloat(item.Heat_smp_set) ||
                          parseFloat(item.Heat_actual_2) <
                            parseFloat(item.Heat_smp_data) -
                              parseFloat(item.Heat_smp_set)
                        )
                          ? { width: "100%" }
                          : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                      }
                      onChange={(e) => {
                        let value = e.target.value;
                        if (InvalidCharacters(value)) {
                          value = value.replace(
                            /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g,
                            ""
                          );
                          e.target.value = value;
                        }
                        UpdateIndex(
                          "Heat",
                          index,
                          "Heat_actual_2",
                          e.target.value
                        );
                      }}
                      disabled={Records.Disable.Hour2}
                    />
                  </td>
                  <td>
                    <input
                      id={index + 1 + "-Heat_actual_3"}
                      type="text"
                      value={item.Heat_actual_3}
                      style={
                        !(
                          parseFloat(item.Heat_actual_3) >
                            parseFloat(item.Heat_smp_data) +
                              parseFloat(item.Heat_smp_set) ||
                          parseFloat(item.Heat_actual_3) <
                            parseFloat(item.Heat_smp_data) -
                              parseFloat(item.Heat_smp_set)
                        )
                          ? { width: "100%" }
                          : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                      }
                      onChange={(e) => {
                        let value = e.target.value;
                        if (InvalidCharacters(value)) {
                          value = value.replace(
                            /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g,
                            ""
                          );
                          e.target.value = value;
                        }
                        UpdateIndex(
                          "Heat",
                          index,
                          "Heat_actual_3",
                          e.target.value
                        );
                      }}
                      disabled={Records.Disable.Hour3}
                    />
                  </td>
                  <td>
                    <input
                      id={index + 1 + "-Heat_actual_4"}
                      type="text"
                      value={item.Heat_actual_4}
                      style={
                        !(
                          parseFloat(item.Heat_actual_4) >
                            parseFloat(item.Heat_smp_data) +
                              parseFloat(item.Heat_smp_set) ||
                          parseFloat(item.Heat_actual_4) <
                            parseFloat(item.Heat_smp_data) -
                              parseFloat(item.Heat_smp_set)
                        )
                          ? { width: "100%" }
                          : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                      }
                      onChange={(e) => {
                        {
                          let value = e.target.value;
                          if (InvalidCharacters(value)) {
                            value = value.replace(
                              /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g,
                              ""
                            );
                            e.target.value = value;
                          }
                        }

                        UpdateIndex(
                          "Heat",
                          index,
                          "Heat_actual_4",
                          e.target.value
                        );
                      }}
                      disabled={Records.Disable.Hour4}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      id={index + 1 + "-Heat_actual_5"}
                      value={item.Heat_actual_5}
                      style={
                        !(
                          parseFloat(item.Heat_actual_5) >
                            parseFloat(item.Heat_smp_data) +
                              parseFloat(item.Heat_smp_set) ||
                          parseFloat(item.Heat_actual_5) <
                            parseFloat(item.Heat_smp_data) -
                              parseFloat(item.Heat_smp_set)
                        )
                          ? { width: "100%" }
                          : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                      }
                      onChange={(e) => {
                        let value = e.target.value;
                        if (InvalidCharacters(value)) {
                          value = value.replace(
                            /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g,
                            ""
                          );
                          e.target.value = value;
                        }

                        UpdateIndex(
                          "Heat",
                          index,
                          "Heat_actual_5",
                          e.target.value
                        );
                      }}
                      disabled={Records.Disable.Hour5}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      id={index + 1 + "-Heat_actual_6"}
                      value={item.Heat_actual_6}
                      style={
                        !(
                          parseFloat(item.Heat_actual_6) >
                            parseFloat(item.Heat_smp_data) +
                              parseFloat(item.Heat_smp_set) ||
                          parseFloat(item.Heat_actual_6) <
                            parseFloat(item.Heat_smp_data) -
                              parseFloat(item.Heat_smp_set)
                        )
                          ? { width: "100%" }
                          : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                      }
                      onChange={(e) => {
                        let value = e.target.value;
                        if (InvalidCharacters(value)) {
                          value = value.replace(
                            /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g,
                            ""
                          );
                          e.target.value = value;
                        }
                        UpdateIndex(
                          "Heat",
                          index,
                          "Heat_actual_6",
                          e.target.value
                        );
                      }}
                      disabled={Records.Disable.Hour6}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <table
            className="table"
            width="50%"
            style={{ textAlign: "center" }}
            border="0"
          >
            <tbody>
              <tr style={{ textAlign: "center" }}>
                <th style={{ textAlign: "center" }} colSpan="15">
                  Injection parameter
                </th>
              </tr>
              <tr>
                <th width="10%" style={{ textAlign: "center", width: "1%" }}>
                  SL
                </th>
                <th width="35%" style={{ textAlign: "center" }}>
                  Description
                </th>
                <th width="25%" style={{ textAlign: "center", width: "8%" }}>
                  Inst-used
                </th>
                <th width="5%" style={{ textAlign: "center" }}>
                  Set
                </th>
                <th
                  width="5%"
                  style={{
                    textAlign: "center",
                    width: "7%",
                    padding: "0px 20px",
                  }}
                >
                  Units
                </th>
                <th width="5%" style={{ textAlign: "center" }}>
                  Actual
                </th>
                <th width="5%" style={{ textAlign: "center" }}>
                  Actual
                </th>
                <th width="5%" style={{ textAlign: "center" }}>
                  Actual
                </th>
                <th width="5%" style={{ textAlign: "center" }}>
                  Actual
                </th>
                <th width="5%" style={{ textAlign: "center" }}>
                  Actual
                </th>
                <th width="5%" style={{ textAlign: "center" }}>
                  Actual
                </th>
              </tr>
              {Records.Injection.map((item, index) => (
                <tr keys={item.Inject_sl}>
                  <td>{item.Inject_sl}</td>
                  <td>{item.Inject_desc}</td>
                  <td>{item.Inject_inst_used}</td>
                  <td>
                    ({item.Inject_smp_data})±{item.Inject_smp_set}
                  </td>
                  <td>{item.Inject_units}</td>
                  <td>
                    <input
                      id={item.Inject_sl + "-Inject_actual_1"}
                      type="text"
                      style={
                        !(
                          parseFloat(item.Inject_actual_1) >
                            parseFloat(item.Inject_smp_data) +
                              parseFloat(item.Inject_smp_set) ||
                          parseFloat(item.Inject_actual_1) <
                            parseFloat(item.Inject_smp_data) -
                              parseFloat(item.Inject_smp_set)
                        )
                          ? { width: "100%" }
                          : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                      }
                      value={item.Inject_actual_1}
                      onChange={(e) => {
                        let value = e.target.value;
                        if (InvalidCharacters(value)) {
                          value = value.replace(
                            /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g,
                            ""
                          );
                          e.target.value = value;
                        }
                        UpdateIndex(
                          "Injection",
                          index,
                          "Inject_actual_1",
                          e.target.value
                        );
                      }}
                      disabled={Records.Disable.Hour1}
                    />
                  </td>
                  <td>
                    <input
                      id={item.Inject_sl + "-Inject_actual_2"}
                      type="text"
                      value={item.Inject_actual_2}
                      style={
                        !(
                          parseFloat(item.Inject_actual_2) >
                            parseFloat(item.Inject_smp_data) +
                              parseFloat(item.Inject_smp_set) ||
                          parseFloat(item.Inject_actual_2) <
                            parseFloat(item.Inject_smp_data) -
                              parseFloat(item.Inject_smp_set)
                        )
                          ? { width: "100%" }
                          : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                      }
                      onChange={(e) => {
                        let value = e.target.value;
                        if (InvalidCharacters(value)) {
                          value = value.replace(
                            /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g,
                            ""
                          );
                          e.target.value = value;
                        }
                        UpdateIndex(
                          "Injection",
                          index,
                          "Inject_actual_2",
                          e.target.value
                        );
                      }}
                      disabled={Records.Disable.Hour2}
                    />
                  </td>
                  <td>
                    <input
                      id={item.Inject_sl + "-Inject_actual_3"}
                      type="text"
                      style={
                        !(
                          parseFloat(item.Inject_actual_3) >
                            parseFloat(item.Inject_smp_data) +
                              parseFloat(item.Inject_smp_set) ||
                          parseFloat(item.Inject_actual_3) <
                            parseFloat(item.Inject_smp_data) -
                              parseFloat(item.Inject_smp_set)
                        )
                          ? { width: "100%" }
                          : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                      }
                      value={item.Inject_actual_3}
                      onChange={(e) => {
                        let value = e.target.value;
                        if (InvalidCharacters(value)) {
                          value = value.replace(
                            /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g,
                            ""
                          );
                          e.target.value = value;
                        }
                        UpdateIndex(
                          "Injection",
                          index,
                          "Inject_actual_3",
                          e.target.value
                        );
                      }}
                      disabled={Records.Disable.Hour3}
                    />
                  </td>
                  <td>
                    <input
                      id={item.Inject_sl + "-Inject_actual_4"}
                      type="text"
                      style={
                        !(
                          parseFloat(item.Inject_actual_4) >
                            parseFloat(item.Inject_smp_data) +
                              parseFloat(item.Inject_smp_set) ||
                          parseFloat(item.Inject_actual_4) <
                            parseFloat(item.Inject_smp_data) -
                              parseFloat(item.Inject_smp_set)
                        )
                          ? { width: "100%" }
                          : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                      }
                      value={item.Inject_actual_4}
                      onChange={(e) => {
                        let value = e.target.value;
                        if (InvalidCharacters(value)) {
                          value = value.replace(
                            /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g,
                            ""
                          );
                          e.target.value = value;
                        }
                        UpdateIndex(
                          "Injection",
                          index,
                          "Inject_actual_4",
                          e.target.value
                        );
                      }}
                      disabled={Records.Disable.Hour4}
                    />
                  </td>
                  <td>
                    <input
                      id={item.Inject_sl + "-Inject_actual_5"}
                      type="text"
                      style={
                        !(
                          parseFloat(item.Inject_actual_5) >
                            parseFloat(item.Inject_smp_data) +
                              parseFloat(item.Inject_smp_set) ||
                          parseFloat(item.Inject_actual_5) <
                            parseFloat(item.Inject_smp_data) -
                              parseFloat(item.Inject_smp_set)
                        )
                          ? { width: "100%" }
                          : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                      }
                      value={item.Inject_actual_5}
                      onChange={(e) => {
                        let value = e.target.value;
                        if (InvalidCharacters(value)) {
                          value = value.replace(
                            /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g,
                            ""
                          );
                          e.target.value = value;
                        }
                        UpdateIndex(
                          "Injection",
                          index,
                          "Inject_actual_5",
                          e.target.value
                        );
                      }}
                      disabled={Records.Disable.Hour5}
                    />
                  </td>
                  <td>
                    <input
                      //  id="inj6"
                      id={item.Inject_sl + "-Inject_actual_6"}
                      type="text"
                      style={
                        !(
                          parseFloat(item.Inject_actual_6) >
                            parseFloat(item.Inject_smp_data) +
                              parseFloat(item.Inject_smp_set) ||
                          parseFloat(item.Inject_actual_6) <
                            parseFloat(item.Inject_smp_data) -
                              parseFloat(item.Inject_smp_set)
                        )
                          ? { width: "100%" }
                          : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                      }
                      value={item.Inject_actual_6}
                      onChange={(e) => {
                        let value = e.target.value;
                        if (InvalidCharacters(value)) {
                          value = value.replace(
                            /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g,
                            ""
                          );
                          e.target.value = value;
                        }
                        UpdateIndex(
                          "Injection",
                          index,
                          "Inject_actual_6",
                          e.target.value
                        );
                      }}
                      disabled={Records.Disable.Hour6}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <table
            className="table_hi"
            width="50%"
            style={{ textAlign: "center" }}
            border="5"
          >
            <tbody>
              <tr style={{ textAlign: "center" }}>
                <th style={{ textAlign: "center" }} colSpan="10">
                  Mould Core & Cavity Temperature Monitoring
                </th>
              </tr>
              <tr>
                <th style={{ width: "3.5%", textAlign: "center" }} width="5%">
                  A
                </th>
                <th style={{ textAlign: "center" }} width="25%">
                  Normal condition
                </th>
                <th style={{ textAlign: "center", width: "5%" }}>Set</th>
                <th style={{ textAlign: "center" }}>Actual value</th>
              </tr>
              <tr>
                <th colSpan="3"></th>
                <td width="50">
                  <table className="table_hi">
                    <tr valign="top">
                      <th width="30%" style={{ textAlign: "center" }}>
                        Shift-A
                      </th>
                      <th width="30%" style={{ textAlign: "center" }}>
                        Shift-B
                      </th>
                      <th width="30%" style={{ textAlign: "center" }}>
                        Shift-C
                      </th>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>01</td>
                <td>TEMPERATURE of core side while machine running</td>
                <td>
                  ({Records.Core})±{Records.CoreSet}°C
                </td>
                <td>
                  <table className="table_hi">
                    <tbody>
                      <tr>
                        <td width="30%">
                          <input
                            onKeyDown={(e) => {
                              FindKeyPress(e.target.id, e.code);
                            }}
                            id="Tempcore1"
                            type="text"
                            style={
                              !(
                                parseFloat(Records.Tempcore1) >
                                  parseFloat(Records.Core) +
                                    parseFloat(Records.CoreSet) ||
                                parseFloat(Records.Tempcore1) <
                                  parseFloat(Records.Core) -
                                    parseFloat(Records.CoreSet)
                              )
                                ? { width: "95%" }
                                : {
                                    width: "95%",
                                    backgroundColor: "#d98984",
                                    border: "2px solid #DA251A",
                                  }
                            }
                            value={Records.Tempcore1}
                            name="Tempcore1"
                            onChange={(e) => {
                              let value = e.target.value;
                              if (InvalidCharacters(value)) {
                                value = value.replace(
                                  /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g,
                                  ""
                                );
                                e.target.value = value;
                              }
                              UpdateObject("Tempcore1", e.target.value);
                            }}
                            disabled={
                              !(
                                (
                                  !Records.Disable.Hour1 ||
                                  !Records.Disable.Hour2
                                )
                                // && !Records.Mtc
                              )
                            }
                          />
                        </td>
                        <td width="30%">
                          <input
                            onKeyDown={(e) => {
                              FindKeyPress(e.target.id, e.code);
                            }}
                            type="text"
                            id="Tempcore2"
                            // id={Records.Tempcore2 + "-Tempcore2"}
                            value={Records.Tempcore2}
                            style={
                              !(
                                parseFloat(Records.Tempcore2) >
                                  parseFloat(Records.Core) +
                                    parseFloat(Records.CoreSet) ||
                                parseFloat(Records.Tempcore2) <
                                  parseFloat(Records.Core) -
                                    parseFloat(Records.CoreSet)
                              )
                                ? { width: "95%" }
                                : {
                                    width: "95%",
                                    backgroundColor: "#d98984",
                                    border: "2px solid #DA251A",
                                  }
                            }
                            name="Tempcore2"
                            onChange={(e) => {
                              let value = e.target.value;
                              if (InvalidCharacters(value)) {
                                value = value.replace(
                                  /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g,
                                  ""
                                );
                                e.target.value = value;
                              }
                              UpdateObject("Tempcore2", e.target.value);
                            }}
                            disabled={
                              !(
                                (
                                  !Records.Disable.Hour3 ||
                                  !Records.Disable.Hour4
                                )
                                // && !Records.Mtc
                              )
                            }
                          />
                        </td>
                        <td width="30%">
                          <input
                            onKeyDown={(e) => {
                              FindKeyPress(e.target.id, e.code);
                            }}
                            type="text"
                            id="Tempcore3"
                            //  id={Records.Tempcore3 + "-Tempcore3"}
                            style={
                              !(
                                parseFloat(Records.Tempcore3) >
                                  parseFloat(Records.Core) +
                                    parseFloat(Records.CoreSet) ||
                                parseFloat(Records.Tempcore3) <
                                  parseFloat(Records.Core) -
                                    parseFloat(Records.CoreSet)
                              )
                                ? { width: "95%" }
                                : {
                                    width: "95%",
                                    backgroundColor: "#d98984",
                                    border: "2px solid #DA251A",
                                  }
                            }
                            value={Records.Tempcore3}
                            name="Tempcore3"
                            onChange={(e) => {
                              let value = e.target.value;
                              if (InvalidCharacters(value)) {
                                value = value.replace(
                                  /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g,
                                  ""
                                );
                                e.target.value = value;
                              }
                              UpdateObject("Tempcore3", e.target.value);
                            }}
                            disabled={
                              !(
                                (
                                  !Records.Disable.Hour5 ||
                                  !Records.Disable.Hour6
                                )
                                // && !Records.Mtc
                              )
                            }
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>02</td>
                <td>TEMPERATURE of CAVITY side while machine running</td>
                <td>
                  ({Records.Cavity})±{Records.CavSet}°C
                </td>
                <td>
                  <table className="table_hi">
                    <tbody>
                      <tr>
                        <td width="30%">
                          <input
                            onKeyDown={(e) => {
                              FindKeyPress(e.target.id, e.code);
                              // console.log("onKeyDown E", e);
                            }}
                            type="text"
                            id="Tempcav1"
                            name="Tempcav1"
                            style={
                              !(
                                parseFloat(Records.Tempcav1) >
                                  parseFloat(Records.Cavity) +
                                    parseFloat(Records.CavSet) ||
                                parseFloat(Records.Tempcav1) <
                                  parseFloat(Records.Cavity) -
                                    parseFloat(Records.CavSet)
                              )
                                ? { width: "95%" }
                                : {
                                    width: "95%",
                                    backgroundColor: "#d98984",
                                    border: "2px solid #DA251A",
                                  }
                            }
                            value={Records.Tempcav1}
                            onChange={(e) => {
                              let value = e.target.value;
                              if (InvalidCharacters(value)) {
                                value = value.replace(
                                  /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g,
                                  ""
                                );
                                e.target.value = value;
                              }
                              UpdateObject("Tempcav1", e.target.value);
                            }}
                            disabled={
                              !(
                                (
                                  !Records.Disable.Hour1 ||
                                  !Records.Disable.Hour2
                                )
                                // && !Records.Mtc
                              )
                            }
                          />
                        </td>
                        <td width="30%">
                          <input
                            onKeyDown={(e) => {
                              FindKeyPress(e.target.id, e.code);
                            }}
                            type="text"
                            //   id={Records.Tempcav2 + "-Tempcav2"}
                            id="Tempcav2"
                            name="Tempcav2"
                            style={
                              !(
                                parseFloat(Records.Tempcav2) >
                                  parseFloat(Records.Cavity) +
                                    parseFloat(Records.CavSet) ||
                                parseFloat(Records.Tempcav2) <
                                  parseFloat(Records.Cavity) -
                                    parseFloat(Records.CavSet)
                              )
                                ? { width: "95%" }
                                : {
                                    width: "95%",
                                    backgroundColor: "#d98984",
                                    border: "2px solid #DA251A",
                                  }
                            }
                            value={Records.Tempcav2}
                            onChange={(e) => {
                              let value = e.target.value;
                              if (InvalidCharacters(value)) {
                                value = value.replace(
                                  /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g,
                                  ""
                                );
                                e.target.value = value;
                              }
                              UpdateObject("Tempcav2", e.target.value);
                            }}
                            disabled={
                              !(
                                (
                                  !Records.Disable.Hour3 ||
                                  !Records.Disable.Hour4
                                )
                                // && !Records.Mtc
                              )
                            }
                          />
                        </td>
                        <td width="30%">
                          <input
                            onKeyDown={(e) => {
                              FindKeyPress(e.target.id, e.code);
                            }}
                            type="text"
                            id="Tempcav3"
                            name="Tempcav3"
                            //  id={Records.Tempcav3 + "-Tempcav3"}
                            style={
                              !(
                                parseFloat(Records.Tempcav3) >
                                  parseFloat(Records.Cavity) +
                                    parseFloat(Records.CavSet) ||
                                parseFloat(Records.Tempcav3) <
                                  parseFloat(Records.Cavity) -
                                    parseFloat(Records.CavSet)
                              )
                                ? { width: "95%" }
                                : {
                                    width: "95%",
                                    backgroundColor: "#d98984",
                                    border: "2px solid #DA251A",
                                  }
                            }
                            value={Records.Tempcav3}
                            onChange={(e) => {
                              let value = e.target.value;
                              if (InvalidCharacters(value)) {
                                value = value.replace(
                                  /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/g,
                                  ""
                                );
                                e.target.value = value;
                              }
                              UpdateObject("Tempcav3", e.target.value);
                            }}
                            disabled={
                              !(
                                (
                                  !Records.Disable.Hour5 ||
                                  !Records.Disable.Hour6
                                )
                                // && !Records.Mtc
                              )
                            }
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>

          <table
            className="table_hi"
            width="50%"
            style={{ textAlign: "center" }}
            border="5"
          >
            <tbody>
              <tr>
                <th style={{ width: "3.5%", textAlign: "center" }} width="5%">
                  B
                </th>
                <th style={{ textAlign: "center" }} width="25%">
                  With M T C
                </th>
                <th style={{ textAlign: "center", width: "5%" }}>Set</th>
                <th style={{ textAlign: "center" }}>Actual value</th>
              </tr>
              <tr>
                <td>01</td>
                <td>TEMPERATURE of core side while machine running</td>
                <td>
                  ({Records.Core})±{Records.CoreSet}°C
                </td>
                <td>
                  <table className="table_hi">
                    <tbody>
                      <tr>
                        <td width="30%">
                          <input
                            onKeyDown={(e) => {
                              FindKeyPress(e.target.id, e.code);
                            }}
                            type="text"
                            id="Tempcore4"
                            //  id={Records.Tempcore4 + "-Tempcore4"}
                            style={
                              !(
                                parseFloat(Records.Tempcore4) >
                                  parseFloat(Records.Core) +
                                    parseFloat(Records.CoreSet) ||
                                parseFloat(Records.Tempcore4) <
                                  parseFloat(Records.Core) -
                                    parseFloat(Records.CoreSet)
                              )
                                ? { width: "95%" }
                                : {
                                    width: "95%",
                                    backgroundColor: "#d98984",
                                    border: "2px solid #DA251A",
                                  }
                            }
                            value={Records.Tempcore4}
                            name="Tempcore4"
                            onChange={(e) => {
                              const value = e.target.value;
                              if (InvalidCharacters(value)) {
                                e.preventDefault();
                                return;
                              }
                              UpdateObject("Tempcore4", e.target.value);
                            }}
                            disabled={
                              !(
                                (
                                  !Records.Disable.Hour1 ||
                                  !Records.Disable.Hour2
                                )
                                // && Records.Mtc
                              )
                            }
                          />
                        </td>
                        <td width="30%">
                          <input
                            onKeyDown={(e) => {
                              FindKeyPress(e.target.id, e.code);
                            }}
                            type="text"
                            id="Tempcore5"
                            // id={Records.Tempcore5 + "-Tempcore5"}
                            style={
                              !(
                                parseFloat(Records.Tempcore5) >
                                  parseFloat(Records.Core) +
                                    parseFloat(Records.CoreSet) ||
                                parseFloat(Records.Tempcore5) <
                                  parseFloat(Records.Core) -
                                    parseFloat(Records.CoreSet)
                              )
                                ? { width: "95%" }
                                : {
                                    width: "95%",
                                    backgroundColor: "#d98984",
                                    border: "2px solid #DA251A",
                                  }
                            }
                            value={Records.Tempcore5}
                            name="Tempcore5"
                            onChange={(e) => {
                              const value = e.target.value;
                              if (InvalidCharacters(value)) {
                                e.preventDefault();
                                return;
                              }
                              UpdateObject("Tempcore5", e.target.value);
                            }}
                            disabled={
                              !(
                                (
                                  !Records.Disable.Hour3 ||
                                  !Records.Disable.Hour4
                                )
                                // && Records.Mtc
                              )
                            }
                          />
                        </td>
                        <td width="30%">
                          <input
                            onKeyDown={(e) => {
                              FindKeyPress(e.target.id, e.code);
                            }}
                            type="text"
                            // id={Records.Tempcore6 + "-Tempcore6"}
                            id="Tempcore6"
                            style={
                              !(
                                parseFloat(Records.Tempcore6) >
                                  parseFloat(Records.Core) +
                                    parseFloat(Records.CoreSet) ||
                                parseFloat(Records.Tempcore6) <
                                  parseFloat(Records.Core) -
                                    parseFloat(Records.CoreSet)
                              )
                                ? { width: "95%" }
                                : {
                                    width: "95%",
                                    backgroundColor: "#d98984",
                                    border: "2px solid #DA251A",
                                  }
                            }
                            value={Records.Tempcore6}
                            name="Tempcore6"
                            onChange={(e) => {
                              const value = e.target.value;
                              if (InvalidCharacters(value)) {
                                e.preventDefault();
                                return;
                              }
                              UpdateObject("Tempcore6", e.target.value);
                            }}
                            disabled={
                              !(
                                (
                                  !Records.Disable.Hour5 ||
                                  !Records.Disable.Hour6
                                )
                                // && Records.Mtc
                              )
                            }
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>02</td>
                <td>TEMPERATURE of CAVITY side while machine running</td>
                <td>
                  ({Records.Cavity})±{Records.CavSet}°C
                </td>
                <td>
                  <table className="table_hi">
                    <tbody>
                      <tr>
                        <td width="30%">
                          <input
                            onKeyDown={(e) => {
                              FindKeyPress(e.target.id, e.code);
                            }}
                            type="text"
                            id="Tempcav4"
                            //  id={Records.Tempcav4+ "-Tempcav4"}
                            style={
                              !(
                                parseFloat(Records.Tempcav4) >
                                  parseFloat(Records.Cavity) +
                                    parseFloat(Records.CavSet) ||
                                parseFloat(Records.Tempcav4) <
                                  parseFloat(Records.Cavity) -
                                    parseFloat(Records.CavSet)
                              )
                                ? { width: "95%" }
                                : {
                                    width: "95%",
                                    backgroundColor: "#d98984",
                                    border: "2px solid #DA251A",
                                  }
                            }
                            value={Records.Tempcav4}
                            name="Tempcav4"
                            onChange={(e) => {
                              const value = e.target.value;
                              if (InvalidCharacters(value)) {
                                e.preventDefault();
                                return;
                              }
                              UpdateObject("Tempcav4", e.target.value);
                            }}
                            disabled={
                              !(
                                (
                                  !Records.Disable.Hour1 ||
                                  !Records.Disable.Hour2
                                )
                                // && Records.Mtc
                              )
                            }
                          />
                        </td>
                        <td width="30%">
                          <input
                            onKeyDown={(e) => {
                              FindKeyPress(e.target.id, e.code);
                            }}
                            type="text"
                            id="Tempcav5"
                            // id={Records.Tempcav5+ "-Tempcav5"}
                            style={
                              !(
                                parseFloat(Records.Tempcav5) >
                                  parseFloat(Records.Cavity) +
                                    parseFloat(Records.CavSet) ||
                                parseFloat(Records.Tempcav5) <
                                  parseFloat(Records.Cavity) -
                                    parseFloat(Records.CavSet)
                              )
                                ? { width: "95%" }
                                : {
                                    width: "95%",
                                    backgroundColor: "#d98984",
                                    border: "2px solid #DA251A",
                                  }
                            }
                            value={Records.Tempcav5}
                            name="Tempcav5"
                            onChange={(e) => {
                              const value = e.target.value;
                              if (InvalidCharacters(value)) {
                                e.preventDefault();
                                return;
                              }
                              UpdateObject("Tempcav5", e.target.value);
                            }}
                            disabled={
                              !(
                                (
                                  !Records.Disable.Hour3 ||
                                  !Records.Disable.Hour4
                                )
                                // && Records.Mtc
                              )
                            }
                          />
                        </td>
                        <td width="30%">
                          <input
                            onKeyDown={(e) => {
                              FindKeyPress(e.target.id, e.code);
                            }}
                            type="text"
                            id="Tempcav6"
                            //     id={Records.Tempcav6 + "-Tempcav6"}
                            style={
                              !(
                                parseFloat(Records.Tempcav6) >
                                  parseFloat(Records.Cavity) +
                                    parseFloat(Records.CavSet) ||
                                parseFloat(Records.Tempcav6) <
                                  parseFloat(Records.Cavity) -
                                    parseFloat(Records.CavSet)
                              )
                                ? { width: "95%" }
                                : {
                                    width: "95%",
                                    backgroundColor: "#d98984",
                                    border: "2px solid #DA251A",
                                  }
                            }
                            value={Records.Tempcav6}
                            name="Tempcav6"
                            onChange={(e) => {
                              const value = e.target.value;
                              if (InvalidCharacters(value)) {
                                e.preventDefault();
                                return;
                              }
                              UpdateObject("Tempcav6", e.target.value);
                            }}
                            disabled={
                              !(
                                (
                                  !Records.Disable.Hour5 ||
                                  !Records.Disable.Hour6
                                )
                                // && Records.Mtc
                              )
                            }
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>

          <table
            className="table_hi"
            width="50%"
            style={{ textAlign: "center" }}
            border="5"
          >
            <tbody>
              <tr>
                <td style={{ width: "33.5%" }}>
                  SIGNATURE OF THE SHIFT INCHARGE - PRODUCTION
                </td>
                <td>
                  <table className="table_hi">
                    <tbody>
                      <tr>
                        <td width="30%">
                          <Select
                            id="dropdown1"
                            ref={dropdownRef1}
                            styles={{ width: "100%" }}
                            onFocus={() => {
                              ReadRecord(
                                "SELECT emp_code as value, emp_name as label FROM upd_data_model.emp_details;"
                              ).then((res) => {
                                UpdateObject("EmpDetails", res.data[0].rows);
                              });
                            }}
                            options={Records.EmpDetails}
                            value={Records.ShiftInchargeA[0]}
                            onChange={(e) => {
                              // if (
                              //   // e.value === Records.ShiftInchargeB[0].value ||
                              //   // e.value === Records.ShiftInchargeC[0].value ||
                              //   e.value === Records.LineInspecQaA[0].value 
                              //   // e.value === Records.LineInspecQaB[0].value ||
                              //   // e.value === Records.LineInspecQaC[0].value
                              // ) {
                              //   alert("Signature Cannot Be Same.");
                              //   return;
                              // }
                              UpdateObject("ShiftInchargeA", [e]);
                              UpdateObject("ShiftInchargeShiftA", e.value);
                            }}
                            placeholder="Select Machine No"
                            isDisabled={
                              Records.Disable.Hour1 && Records.Disable.Hour2
                            }
                          />
                        </td>
                        <td width="30%">
                          <Select
                            id="dropdown2"
                            //  ref={dropdownRef2}
                            styles={{ width: "100%" }}
                            onFocus={() => {
                              ReadRecord(
                                "SELECT emp_code as value, emp_name as label FROM upd_data_model.emp_details;"
                              ).then((res) => {
                                UpdateObject("EmpDetails", res.data[0].rows);
                              });
                            }}
                            options={Records.EmpDetails}
                            value={Records.ShiftInchargeB[0]}
                            onChange={(e) => {
                              // if (
                              //   // e.value === Records.ShiftInchargeA[0].value ||
                              //   // e.value === Records.ShiftInchargeC[0].value ||
                              //   // e.value === Records.LineInspecQaA[0].value ||
                              //   e.value === Records.LineInspecQaB[0].value 
                              //   // e.value === Records.LineInspecQaC[0].value
                              // ) {
                              //   alert("Signature Cannot Be Same.");
                              //   return;
                              // }
                              // console.log(e.value)
                              UpdateObject("ShiftInchargeB", [e]);
                              UpdateObject("ShiftInchargeShiftB", e.value);
                            }}
                            placeholder="Select Machine No"
                            isDisabled={
                              Records.Disable.Hour3 && Records.Disable.Hour4
                            }
                          />
                        </td>
                        <td width="30%">
                          <Select
                            id="dropdown"
                            styles={{ width: "100%" }}
                            onFocus={() => {
                              ReadRecord(
                                "SELECT emp_code as value, emp_name as label FROM upd_data_model.emp_details;"
                              ).then((res) => {
                                UpdateObject("EmpDetails", res.data[0].rows);
                              });
                            }}
                            options={Records.EmpDetails}
                            value={Records.ShiftInchargeC[0]}
                            onChange={(e) => {
                              // if (
                              //   // e.value === Records.ShiftInchargeB[0].value ||
                              //   // e.value === Records.ShiftInchargeA[0].value ||
                              //   // e.value === Records.LineInspecQaA[0].value ||
                              //   // e.value === Records.LineInspecQaB[0].value ||
                              //      e.value === Records.LineInspecQaC[0].value
                              // ) {
                              //   alert("Signature Cannot Be Same.");
                              //   return;
                              // }
                              
                              UpdateObject("ShiftInchargeC", [e]);
                              UpdateObject("ShiftInchargeShiftC", e.value);
                            }}
                            placeholder="Select Machine No"
                            isDisabled={
                              Records.Disable.Hour5 && Records.Disable.Hour6
                            }
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>SIGNATURE OF THE ON LINE INSPECTION - QUALITY</td>

                <td colSpan={4}>
                  <table className="table_hi">
                    <tbody>
                      <tr>
                        <td width="30%">
                          <Select
                            id="dropdown"
                            styles={{ width: "100%" }}
                            onFocus={() => {
                              ReadRecord(
                                "SELECT emp_code as value, emp_name as label FROM upd_data_model.emp_details;"
                              ).then((res) => {
                                UpdateObject("EmpDetails", res.data[0].rows);
                              });
                            }}
                            options={Records.EmpDetails}
                            value={Records.LineInspecQaA[0]}
                            onChange={(e) => {
                              // if (
                              //   e.value === Records.ShiftInchargeA[0].value 
                              //   // e.value === Records.ShiftInchargeB[0].value ||
                              //   // e.value === Records.ShiftInchargeC[0].value ||
                              //   // e.value === Records.LineInspecQaB[0].value ||
                              //   // e.value === Records.LineInspecQaC[0].value
                              // ) {
                              //   alert("Signature Cannot Be Same.");
                              //   return;
                              // }
                              UpdateObject("LineInspecQaA", [e]);
                              UpdateObject("LineInspecQaShiftA", e.value);
                            }}
                            placeholder="Select Machine No"
                            isDisabled={
                              Records.Disable.Hour1 && Records.Disable.Hour2
                            }
                          />
                        </td>
                        <td width="30%">
                          <Select
                            id="dropdown"
                            styles={{ width: "100%" }}
                            onFocus={() => {
                              ReadRecord(
                                "SELECT emp_code as value, emp_name as label FROM upd_data_model.emp_details;"
                              ).then((res) => {
                                UpdateObject("EmpDetails", res.data[0].rows);
                              });
                            }}
                            options={Records.EmpDetails}
                            value={Records.LineInspecQaB[0]}
                            onChange={(e) => {
                              // if (
                              //  // e.value === Records.ShiftInchargeA[0].value ||
                              //   e.value === Records.ShiftInchargeB[0].value 
                              //   // e.value === Records.ShiftInchargeC[0].value ||
                              //   // e.value === Records.LineInspecQaA[0].value ||
                              //   // e.value === Records.LineInspecQaC[0].value
                              // ) {
                              //   alert("Signature Cannot Be Same.");
                              //   return;
                              // }
                              UpdateObject("LineInspecQaB", [e]);
                              UpdateObject("LineInspecQaShiftB", e.value);
                            }}
                            placeholder="Select Machine No"
                            isDisabled={
                              Records.Disable.Hour3 && Records.Disable.Hour4
                            }
                          />
                        </td>
                        <td width="30%">
                          <Select
                            id="dropdown"
                            styles={{ width: "100%" }}
                            onFocus={() => {
                              ReadRecord(
                                "SELECT emp_code as value, emp_name as label FROM upd_data_model.emp_details;"
                              ).then((res) => {
                                UpdateObject("EmpDetails", res.data[0].rows);
                              });
                            }}
                            options={Records.EmpDetails}
                            value={Records.LineInspecQaC[0]}
                            onChange={(e) => {
                              // if (
                              //   // e.value === Records.ShiftInchargeA[0].value ||
                              //   // e.value === Records.ShiftInchargeB[0].value ||
                              //   e.value === Records.ShiftInchargeC[0].value 
                              //   // e.value === Records.LineInspecQaA[0].value ||
                              //   // e.value === Records.LineInspecQaB[0].value 
                              // ) {
                              //   alert("Signature Cannot Be Same.");
                              //   return;
                              // }
                              UpdateObject("LineInspecQaC", [e]);
                              UpdateObject("LineInspecQaShiftC", e.value);
                            }}
                            placeholder="Select Machine No"
                            isDisabled={
                              Records.Disable.Hour5 && Records.Disable.Hour6
                            }
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              {option !== "view" && (
                <tr>
                  <td colSpan="4" style={{ border: "none" }}>
                    {Records.NewRecord === "" ? (
                      ""
                    ) : Records.NewRecord === true ? (
                      Records.InsertRecord === true ? (
                        <Field
                          label={"Insert & Update"}
                          className={"Submit"}
                          options={{ type: "Button" }}
                          onClick={(e) => handleUpdateCreate(e)}
                        />
                      ) : (
                        <Field
                          label={"Submit"}
                          className={"Submit"}
                          options={{ type: "Button" }}
                          onClick={(e) => handleCreate(e)}
                        />
                      )
                    ) : Records.InsertRecord === true ? (
                      <Field
                        label={"Insert & Update"}
                        className={"Submit"}
                        options={{ type: "Button" }}
                        onClick={(e) => handleUpdateCreate(e)}
                      />
                    ) : (
                      <Field
                        label={"Update"}
                        className={"Submit"}
                        options={{ type: "Button" }}
                        onClick={(e) => handleUpdate(e)}
                      />
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </form>
      </div>

      {/* ExcelPDF Design*/}
      <div ref={contentRef}>
        <table className="table1">
          <tbody>
            <tr style={{ textAlign: "center" }}>
              <th colspan="1" style={{ height: "2%" }}>
                <img style={{ width: "90%" }} src={Logo} alt="Logo" />
              </th>
              <th style={{ textAlign: "center" }} colSpan="11">
                <font color="#8B0000">NEW PROCESS MONITORING SYSTEM</font>
              </th>
            </tr>
            <tr>
              <td colSpan="3">Format No</td>
              <td colSpan="3" style={{ textAlign: "center" }}>
                <label>UPCL/PRO/R/28</label>
              </td>

              <td colSpan="3">Rev No</td>
              <td colSpan="3" style={{ textAlign: "center" }}>
                <label>02</label>
              </td>
            </tr>
            <tr>
              <td colSpan="3">Track Date</td>
              <td colSpan="3" style={{ textAlign: "center" }}>
                {Records.TrackDate}
              </td>
              <td colSpan="3">
                M/C NO<span style={{ color: "red" }}>*</span>
              </td>
              <td colSpan="3" style={{ textAlign: "center" }}>
                {Records.MachineNo[0]?.label ? Records.MachineNo[0].label : ""}
              </td>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td colSpan="2">
                Part weight1 (-{Records.PartWeightMinus1}/+
                {Records.PartWeightPlus1})
              </td>
              <td>{Records.PartWeight1}</td>
              <td colSpan={2}></td>
              <td>{Records.PW1}</td>
              <td>{Records.PW2}</td>
              <td>{Records.PW3}</td>
              <td>{Records.PW4}</td>
              <td>{Records.PW5}</td>
              <td>{Records.PW6}</td>
            </tr>
            <tr>
              <th width="7%" style={{ textAlign: "center", width: "1%" }}>
                SL
              </th>
              <th
                style={{
                  textAlign: "center",
                  whiteSpace: "nowrap",
                  width: "15.5%",
                }}
              >
                Description
              </th>
              <th width="15%" style={{ textAlign: "center", width: "9%" }}>
                Inst used
              </th>
              <th
                colSpan={2}
                width="20%"
                style={{ textAlign: "center", width: "11%" }}
              >
                Raw-Material
              </th>
              <th width="7%" style={{ textAlign: "center" }}>
                Actual Level
              </th>
              <th width="7%" style={{ textAlign: "center" }}>
                Actual Level
              </th>
              <th width="7%" style={{ textAlign: "center" }}>
                Actual Level
              </th>
              <th width="7%" style={{ textAlign: "center" }}>
                Actual Level
              </th>
              <th width="7%" style={{ textAlign: "center" }}>
                Actual Level
              </th>
              <th width="7%" style={{ textAlign: "center" }}>
                Actual Level
              </th>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>1</td>
              <td style={{ whiteSpace: "nowrap" }}>Raw-Material-Level</td>
              <td>On hooper</td>
              <td colSpan={2}>Min level/Max Level</td>

              <td>{Records.RM1}</td>
              <td>{Records.RM2}</td>
              <td>{Records.RM3}</td>
              <td>{Records.RM4}</td>
              <td>{Records.RM5}</td>
              <td>{Records.RM6}</td>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <th
                style={{ textAlign: "center", position: "relative" }}
                colSpan="15"
              >
                Tracking Machine
                <div
                  style={{
                    position: "absolute",
                    right: "0",
                    top: "5px",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    paddingRight: "10px",
                  }}
                >
                  <div>{Records.MouldChangeHour}</div>
                </div>
              </th>
            </tr>
            <tr>
              <th
                colSpan={5}
                style={{
                  textAlign: "center",
                  whiteSpace: "nowrap",
                  width: "39%",
                }}
              >
                Time to be updated
              </th>
              <th
                width="5%"
                style={{ textAlign: "center", whiteSpace: "break-spaces" }}
              >
                07.00 AM - 08.00 AM
              </th>
              <th
                width="5%"
                style={{ textAlign: "center", whiteSpace: "break-spaces" }}
              >
                11.00 AM - 12.00 AM
              </th>
              <th
                width="5%"
                style={{ textAlign: "center", whiteSpace: "break-spaces" }}
              >
                15.00 PM - 16.00 PM
              </th>
              <th
                width="5%"
                style={{ textAlign: "center", whiteSpace: "break-spaces" }}
              >
                19.00 PM - 20.00 PM
              </th>
              <th
                width="5%"
                style={{ textAlign: "center", whiteSpace: "break-spaces" }}
              >
                23.00 PM - 01.00 AM
              </th>
              <th
                width="5%"
                style={{ textAlign: "center", whiteSpace: "break-spaces" }}
              >
                03.00 AM - 04.00 AM
              </th>
            </tr>

            {Records.Tracking.map((item, index) => (
              <tr key={index}>
                <td colSpan={5}>{item.Time_to_be_updated}</td>
                <td>{item.Track_7_am}</td>
                <td>{item.Track_11_am}</td>
                <td>{item.Track_15_pm}</td>
                <td>{item.Track_19_pm}</td>
                <td>{item.Track_23_pm}</td>
                <td>{item.Track_3_am}</td>
              </tr>
            ))}

            <tr style={{ textAlign: "center" }}>
              <th style={{ textAlign: "center" }} colSpan="15">
                Heating parameter
              </th>
            </tr>
            <tr>
              <th width="8%" style={{ textAlign: "center", width: "1%" }}>
                SL
              </th>
              <th width="30%" style={{ textAlign: "center" }}>
                Description
              </th>
              <th width="25%" style={{ textAlign: "center", width: "8%" }}>
                Inst-used
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Set
              </th>
              <th
                style={{
                  textAlign: "center",
                  width: "7%",
                  padding: "0px 20px",
                }}
              >
                Units
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Actual
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Actual
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Actual
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Actual
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Actual
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Actual
              </th>
            </tr>
            {Records.Heat.map((item, index) => (
              <tr>
                <td>{item.Heat_sl}</td>
                <td>{item.Heat_desc}</td>
                <td>{item.Heat_inst_used}</td>
                <td>
                  {" "}
                  ({item.Heat_smp_data}) ±{item.Heat_smp_set}°C
                </td>
                <td>°C</td>
                <td>{item.Heat_actual_1}</td>
                <td>{item.Heat_actual_2}</td>
                <td>{item.Heat_actual_3}</td>
                <td>{item.Heat_actual_4}</td>
                <td>{item.Heat_actual_5}</td>
                <td>{item.Heat_actual_6}</td>
              </tr>
            ))}

            <tr style={{ textAlign: "center" }}>
              <th style={{ textAlign: "center" }} colSpan="15">
                Injection parameter
              </th>
            </tr>
            <tr>
              <th width="10%" style={{ textAlign: "center", width: "1%" }}>
                SL
              </th>
              <th width="35%" style={{ textAlign: "center" }}>
                Description
              </th>
              <th width="25%" style={{ textAlign: "center", width: "8%" }}>
                Inst-used
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Set
              </th>
              <th
                width="5%"
                style={{
                  textAlign: "center",
                  width: "7%",
                  padding: "0px 20px",
                }}
              >
                Units
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Actual
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Actual
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Actual
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Actual
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Actual
              </th>
              <th width="5%" style={{ textAlign: "center" }}>
                Actual
              </th>
            </tr>
            {Records.Injection.map((item, index) => (
              <tr keys={item.Inject_sl}>
                <td>{item.Inject_sl}</td>
                <td>{item.Inject_desc}</td>
                <td>{item.Inject_inst_used}</td>
                <td>
                  ({item.Inject_smp_data})±{item.Inject_smp_set}
                </td>
                <td>{item.Inject_units}</td>
                <td>{item.Inject_actual_1}</td>
                <td>{item.Inject_actual_2}</td>
                <td>{item.Inject_actual_3}</td>
                <td>{item.Inject_actual_4}</td>
                <td>{item.Inject_actual_5}</td>
                <td>{item.Inject_actual_6}</td>
              </tr>
            ))}
            <tr style={{ textAlign: "center" }}>
              <th style={{ textAlign: "center" }} colSpan="12">
                Mould Core & Cavity Temperature Monitoring
              </th>
            </tr>
            <tr>
              <th style={{ width: "3.5%", textAlign: "center" }} width="5%">
                A
              </th>
              <th style={{ textAlign: "center" }} width="25%" colSpan={3}>
                Normal condition
              </th>
              <th style={{ textAlign: "center", width: "5%" }}>Set</th>
              <th style={{ textAlign: "center" }} colSpan={6}>
                Actual value
              </th>
            </tr>
            <tr>
              <th colSpan="5"></th>

              <th width="30%" style={{ textAlign: "center" }} colSpan={2}>
                Shift-A
              </th>
              <th width="30%" style={{ textAlign: "center" }} colSpan={2}>
                Shift-B
              </th>
              <th width="30%" style={{ textAlign: "center" }} colSpan={2}>
                Shift-C
              </th>
            </tr>

            <tr>
              <td>01</td>
              <td colSpan={3}>
                TEMPERATURE of core side while machine running
              </td>
              <td colSpan={1}>
                ({Records.Core})±{Records.CoreSet}°C
              </td>
              <td colSpan={2}>{Records.Tempcore1}</td>
              <td colSpan={2}>{Records.Tempcore2}</td>
              <td colSpan={2}>{Records.Tempcore3}</td>
            </tr>
            <tr>
              <td>02</td>
              <td colSpan={3}>
                TEMPERATURE of CAVITY side while machine running
              </td>
              <td>
                ({Records.Cavity})±{Records.CavSet}°C
              </td>
              <td colSpan={2}>{Records.Tempcav1}</td>
              <td colSpan={2}>{Records.Tempcav2}</td>
              <td colSpan={2}>{Records.Tempcav3}</td>
            </tr>
            <tr>
              <th style={{ width: "3.5%", textAlign: "center" }} width="5%">
                B
              </th>
              <th style={{ textAlign: "center" }} width="25%" colSpan={3}>
                With M T C
              </th>
              <th style={{ textAlign: "center", width: "5%" }}>Set</th>
              <th style={{ textAlign: "center" }} colSpan={6}>
                Actual value
              </th>
            </tr>
            <tr>
              <th colSpan="5"></th>

              <th width="30%" style={{ textAlign: "center" }} colSpan={2}>
                Shift-A
              </th>
              <th width="30%" style={{ textAlign: "center" }} colSpan={2}>
                Shift-B
              </th>
              <th width="30%" style={{ textAlign: "center" }} colSpan={2}>
                Shift-C
              </th>
            </tr>
            <tr>
              <td>01</td>
              <td colSpan={3}>
                {" "}
                TEMPERATURE of core side while machine running
              </td>
              <td colSpan={1}>
                ({Records.Core})±{Records.CoreSet}°C
              </td>
              <td colSpan={2}>{Records.Tempcore4}</td>
              <td colSpan={2}>{Records.Tempcore5}</td>
              <td colSpan={2}>{Records.Tempcore6}</td>
            </tr>
            <tr>
              <td>02</td>
              <td colSpan={3}>
                TEMPERATURE of CAVITY side while machine running
              </td>
              <td>
                ({Records.Cavity})±{Records.CavSet}°C
              </td>
              <td colSpan={2}>{Records.Tempcav4}</td>
              <td colSpan={2}>{Records.Tempcav5}</td>
              <td colSpan={2}>{Records.Tempcav6}</td>
            </tr>
            <tr>
              <td width="30%" colSpan={5}>
                SIGNATURE OF THE SHIFT INCHARGE - PRODUCTION
              </td>
              <td colSpan={2}>
                {Records.ShiftInchargeA[0]?.label
                  ? Records.ShiftInchargeA[0].label
                  : ""}{" "}
              </td>
              <td colSpan={2}>
                {Records.ShiftInchargeB[0]?.label
                  ? Records.ShiftInchargeB[0].label
                  : ""}
              </td>
              <td colSpan={2}>
                {Records.ShiftInchargeC[0]?.label
                  ? Records.ShiftInchargeC[0].label
                  : ""}
              </td>
            </tr>
            <tr>
              <td width="30%" colSpan={5}>
                SIGNATURE OF THE ON LINE INSPECTION - QUALITY
              </td>
              <td colSpan={2}>
                {Records.LineInspecQaA[0]?.label
                  ? Records.LineInspecQaA[0].label
                  : ""}
              </td>
              <td colSpan={2}>
                {Records.LineInspecQaB[0]?.label
                  ? Records.LineInspecQaB[0].label
                  : ""}
              </td>
              <td colSpan={2}>
                {Records.LineInspecQaC[0]?.label
                  ? Records.LineInspecQaC[0].label
                  : ""}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </QaLayout>
  );
}

export default PmsCreate;
