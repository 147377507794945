import React, { useEffect, useState } from "react";
import style from "./Sidebar.module.css";

// Icon importing
import hammerIcon from "../../icons/hammer.svg"
import masterIcon from "../../icons/mastersvg.svg"
import open from "../../icons/open.png";
import machineIcon from "../../icons/machine-svgrepo.svg";
import mouldingIcon from "../../icons/gears-svgrepo.svg";
import qualityIcon from "../../icons/quality-svgrepo.svg";
import logOutIcon from "../../icons/logout-svgrepo.svg";
import Logo from "../../icons/Unitechlogo.png";
import Approval from "../../icons/Approval.svg";
import Painting from "../../icons/Painting.svg";
import './Toggle.css';
import Collapsible from "react-collapsible";
import "../../Styles/Add.css";
import "../../Styles/Forms.css";
import "../../Styles/List.css";
import "../../Styles/View.css";
import { useNavigate } from "react-router-dom";
import Tooltip from "rc-tooltip";
import 'rc-tooltip/assets/bootstrap_white.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { GetAccess } from "../../Lib/GetAccess";
import { Col } from "react-bootstrap";


function Sidebar({ isOpen, toggleSidebar }) {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("");
  const [activeItem, setActiveItem] = useState("");
  const [expandedTab, setExpandedTab] = useState("");
  const [rotation, setRotation] = useState("");

  // const toggleSidebar = () => {
  //   setIsOpen(!isOpen);
  //   setRotation(rotation => rotation + 180);
  // }

  const handleTabSelection = (tab) => {
    if (expandedTab === tab) {
      setExpandedTab("");
    } else {
      setExpandedTab(tab);
    }
  };


  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
    toggleSidebar(false);
  };

  const isItemActive = (itemName) => {
    return activeItem === itemName ? style.active : "";
  };

  const logout = () => {
    localStorage.removeItem("role");
    localStorage.removeItem("login");
    localStorage.removeItem("token");
    // localStorage.removeItem("name");
navigate("/");
    window.location.reload();
  };

  let AccordionHead1 = (name, id) => {
    return (
      <div className={style.sidebar_accordion_head_container}>
        <div className={style.sidebar_accordion_head_left}>
          <img className={style.sidebar_accordion_head_icon} src={masterIcon}></img>
          <div id={id} className={style.sidebar_accordion_head_text}>
            {name}
          </div>
        </div>
      </div>
    );
  };
  let AccordionHead2 = (name, id) => {
    return (
      <div className={style.sidebar_accordion_head_container}>
        <div className={style.sidebar_accordion_head_left}>
          <img className={style.sidebar_accordion_head_icon} src={machineIcon}></img>
          <div id={id} className={style.sidebar_accordion_head_text}>
            {name}
          </div>
        </div>
      </div>
    );
  };
  let AccordionHead3 = (name, id) => {
    return (
      <div className={style.sidebar_accordion_head_container}>
        <div className={style.sidebar_accordion_head_left}>
          <img className={style.sidebar_accordion_head_icon} src={mouldingIcon} ></img>
          <div id={id} className={style.sidebar_accordion_head_text}>
            {name}
          </div>
        </div>
      </div>
    );
  };
  let AccordionHead4 = (name, id) => {
    return (
      <div className={style.sidebar_accordion_head_container}>
        <div className={style.sidebar_accordion_head_left}>
          <img className={style.sidebar_accordion_head_icon} src={hammerIcon} ></img>
          <div id={id} className={style.sidebar_accordion_head_text}>
            {name}
          </div>
        </div>
      </div>
    );
  };
  let AccordionHead5 = (name, id) => {
    return (
      <div className={style.sidebar_accordion_head_container}>
        <div className={style.sidebar_accordion_head_left}>
          <img className={style.sidebar_accordion_head_icon} src={qualityIcon} ></img>
          <div id={id} className={style.sidebar_accordion_head_text}>
            {name}
          </div>
        </div>
      </div>
    );
  };
  let AccordionHead6 = (name, id) => {
    return (
      <div className={style.sidebar_accordion_head_container}>
        <div className={style.sidebar_accordion_head_left}>
          <img className={style.sidebar_accordion_head_icon} src={logOutIcon} ></img>
          <div id={id} className={style.sidebar_accordion_head_text}>
            {name}
          </div>
        </div>
      </div>
    );
  };
  let AccordionHead7 = (name, id) => {
    return (
      <div className={style.sidebar_accordion_head_container}>
        <div className={style.sidebar_accordion_head_left}>
          <img className={style.sidebar_accordion_head_icon} src={Approval} ></img>
          <div id={id} className={style.sidebar_accordion_head_text}>
            {name}
          </div>
        </div>
      </div>
    );
  };
  let AccordionHead8 = (name, id) => {
    return (
      <div className={style.sidebar_accordion_head_container}>
        <div className={style.sidebar_accordion_head_left}>
          <img className={style.sidebar_accordion_head_icon} src={Painting} ></img>
          <div id={id} className={style.sidebar_accordion_head_text}>
            {name}
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const handleBackButton = () => {
      if (window.location.pathname === '/dashboard') {
        setSelectedTab(false);
        setActiveItem('');
      }
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);
  
    return (
      <div>
        <div className={`sidebar ${isOpen ? 'open' : ''}`} style={{zIndex:'20'}}>
          <div
            id="sidebar.toggle"
            name="sidebar.toggle"
            className={`toggle-button ${isOpen ? "open" : ""}`}
            onClick={toggleSidebar}
            style={{ left: isOpen ? "230px" : "15px" }}
          >
            <img src={open} alt="Toggle Sidebar" style={{ transform: isOpen ? "rotate(90deg)" : "rotate(-90deg)" }} />
          </div>
          <div className={style.sidebar_header}>
            {/* <img className={style.sidebar_header_image} src={Logo} /> */}
            <span className={style.sidebar_heading}>KASH Tech LLC</span>
          </div>

          <div>
            {(GetAccess("ADMIN","GM") || GetAccess("Quality (QC)","SR.EXECUTIVE" )) && (
              <Collapsible

                className={style.sidebar_collapsible}
                trigger={AccordionHead1("Master->", "Master.Collapsible")}
                contentInnerClassName={style.sidebar_collapsible_open}
                onOpening={() => handleTabSelection("Master->")}
              >
                <div
                  id="sidebar.bc.Tabs"
                  name="sidebar.bc.Tabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'Bar Code' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('Bar Code');
                    navigate('/BarcodeGenerator');
                  }}
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Barcode Generator</span>}>
                    <span>Barcode Generator{" "}</span>
                  </Tooltip>

                </div>
                <div
                  id="sidebar.mc.Tabs"
                  name="sidebar.mc.Tabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'Machine Master' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('Machine Master');
                    navigate('/MachineMaster');
                  }}
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Machine Master</span>}>
                    <span>Machine Master{" "}</span>
                  </Tooltip>

                </div>
                <div
                  id="sidebar.mc.PartTabs"
                  name="sidebar.mc.PartTabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'Part Master' ? style.active : ''}`}

                  onClick={() => {
                    handleItemClick('Part Master');
                    navigate("/MachinePartMaster");
                  }
                  }
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Part Master</span>}>
                    <span>Part Master{" "}</span>
                  </Tooltip>

                </div>
                <div
                  id="sidebar.FGMasterTab"
                  name="sidebar.FGMasterTab"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'F G Master' ? style.active : ''}`}

                  onClick={() => {
                    handleItemClick('F G Master');
                    navigate("/FGMaster");
                  }
                  }
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>FG Master</span>}>
                    <span>FG Master{" "}</span>
                  </Tooltip>

                </div>
                <div
                  id="sidebar.SupplierMasterTab"
                  name="sidebar.SupplierMasterTab"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'Supplier Master' ? style.active : ''}`}

                  onClick={() => {
                    handleItemClick('Supplier Master');
                    navigate("/SupplierMaster");
                  }
                  }
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Supplier Master</span>}>
                    <span>Standard Supplier Master{" "}</span>
                  </Tooltip>

                </div>
                <div
                  id="sidebar.RMMasterTab"
                  name="sidebar.RMMasterTab"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'Raw Material Master' ? style.active : ''}`}

                  onClick={() => {
                    handleItemClick('Raw Material Master');
                    navigate("/RMMaster");
                  }
                  }
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Raw Material Master</span>}>
                    <span>Raw Material Master{" "}</span>
                  </Tooltip>

                </div>
                <div
                  id="sidebar.EmployeeMasterTab"
                  name="sidebar.EmployeeMasterTab"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'Employee Master' ? style.active : ''}`}

                  onClick={() => {
                    handleItemClick('Employee Master');
                    navigate("/EmployeeMaster");
                  }
                  }
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Employee Master</span>}>
                    <span>Employee Master{" "}</span>
                  </Tooltip>

                </div>
                <div
                  id="sidebar.CustomerMasterTab"
                  name="sidebar.CustomerMasterTab"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'Customer Master' ? style.active : ''}`}

                  onClick={() => {
                    handleItemClick('Customer Master');
                    navigate("/CustomerMaster");
                  }
                  }
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Customer Master</span>}>
                    <span>Customer Master{" "}</span>
                  </Tooltip>

                </div>
                <div
                  id="sidebar.MasterTab"
                  name="sidebar.MasterTab"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'Master Head' ? style.active : ''}`}

                  onClick={() => {
                    handleItemClick('Master Head');
                    navigate("/MasterHead");
                  }
                  }
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Master Head</span>}>
                    <span>Master Head{" "}</span>
                  </Tooltip>

                </div>
                <div
                  id="sidebar.MaterialStandardTab"
                  name="sidebar.MaterialStandardTab"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'Material Standard' ? style.active : ''}`}

                  onClick={() => {
                    handleItemClick('Material Standard');
                    navigate("/MaterialStandard");
                  }
                  }
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Material Standard</span>}>
                    <span>Material Standard{" "}</span>
                  </Tooltip>

                </div>
              </Collapsible>
            )}
            <Collapsible
              className={style.sidebar_collapsible}
              trigger={AccordionHead2("Machine Tracking->", "MachineTracking.Collapsible")}
              contentInnerClassName={style.sidebar_collapsible_open}
              onOpening={() => handleTabSelection("Machine Tracking->")}
            >
              <div
                id="sidebar.SmpTabs"
                name="sidebar.SmpTabs"
                className={`${style.sidebar_collapsible_item} ${activeItem === 'S M P' ? style.active : ''}`}
                onClick={() => {
                  handleItemClick('S M P');
                  navigate("/newSmpMain");
                }
                }
              >
                <Tooltip placement="right" trigger={['hover']} overlay={<span>Standard Moulding Parameter</span>}>
                  <span >Standard Moulding Parameter{" "}</span>
                </Tooltip>

              </div>

              <div
                id="sidebar.PmsTabs"
                name="sidebar.PmsTabs"
                className={`${style.sidebar_collapsible_item} ${activeItem === 'P M S' ? style.active : ''}`}
                onClick={() => {
                  handleItemClick('P M S');
                  navigate("/Pms");
                }
                }
              >
                <Tooltip placement="right" trigger={['hover']} overlay={<span>Process Monitoring System </span>}>
                  <span >Process Monitoring System {" "}</span>
                </Tooltip>

              </div>
            </Collapsible>
            <Collapsible
              className={style.sidebar_collapsible}
              trigger={AccordionHead3("Moulding Department->", "MouldingDepartment.Collapsible")}
              contentInnerClassName={style.sidebar_collapsible_open}
              onOpening={() => handleTabSelection("Moulding Department->")}
            >
              <Collapsible
                className={style.sidebar_collapsible}
                trigger={AccordionHead4("Production->", "MoldingProduction.Collapsible")}
                contentInnerClassName={style.sidebar_collapsible_open}
                onOpening={() => handleTabSelection("Production->")}
              >
                <div
                  id="sidebar.MatLdTabs"
                  name="sidebar.MatLdTabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'M L' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('M L');
                    navigate('/MaterialLoading');
                  }
                  }
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Material Loading</span>}>
                    <span>Material Loading{" "}</span>
                  </Tooltip>

                </div>
                <div
                  id="sidebar.Report4MTabs"
                  name="sidebar.Report4MTabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === '4 M' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('4 M');
                    navigate("/Report4M");
                  }
                  }
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>4M Report</span>}>
                    <span>4M Report{" "}</span>
                  </Tooltip>

                </div>
                <div
                  id="sidebar.MldUnLoadAndLoadTabs"
                  name="sidebar.MldUnLoadAndLoadTabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'M U L' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('M U L');
                    navigate("/MldUnLoadAndLoad");
                  }
                  }
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Mould Loading & Un-Loading Check List</span>}>
                    <span>Mould Loading & Un-Loading Check List{" "}</span>
                  </Tooltip>
                </div>
                <div
                  id="sidebar.DvFrmTabs"
                  name="sidebar.DvFrmTabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'D F' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('D F');
                    navigate("/DeviationForm");
                  }
                  }
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Deviation Form</span>}>
                    <span>Deviation Form{" "}</span>
                  </Tooltip>

                </div>
                <div
                  id="sidebar.MCAFrmTabs"
                  name="sidebar.MCAFrmTabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'M C' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('M C');
                    navigate("/MCActRequestReport");
                  }
                  }
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Mould Corrective Action</span>}>
                    <span>Mould Corrective Action{" "}</span>
                  </Tooltip>

                </div>
                <div
                  id="sidebar.TemperatureSheetTabs"
                  name="sidebar.TemperatureSheetTabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'T S' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('T S');
                    navigate("/TemperatureSheet");
                  }
                  }
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Mould Temperature</span>}>
                    <span>Mould Temperature{" "}</span>
                  </Tooltip>

                </div>
                <div
                  id="sidebar.MachbdTabs"
                  name="sidebar.MachbdTabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'M B' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('M B');
                    navigate("/MacBD");
                  }
                  }
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Machine BreakDown Report</span>}>
                    <span>Machine BreakDown Report{" "}</span>
                  </Tooltip>

                </div>
                <div
                  id="sidebar.LumpTabs"
                  name="sidebar.LumpTabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'L T' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('L T');
                    navigate("/LUMP");
                  }
                  }
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Lump Monitoring</span>}>
                    <span>Lump Monitoring{" "}</span>
                  </Tooltip>

                </div>
                <div
                  id="sidebar.ProSkillTabs"
                  name="sidebar.ProSkillTabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'P S' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('P S');
                    navigate("/ProductionSkill");
                  }
                  }
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Production Skill</span>}>
                    <span>Production Skill{" "}</span>
                  </Tooltip>

                </div>
                <div
                  id="sidebar.EcnReportTab"
                  name="sidebar.EcnReportTab"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'E C N R' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('E C N R');
                    navigate("/EcnReport");
                  }
                  }
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>ECN Report</span>}>
                    <span>ECN Report{" "}</span>
                  </Tooltip>

                </div>
                <div
                  id="sidebar.ProcessValidationTabs"
                  name="sidebar.ProcessValidationTabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'P V' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('P V');
                    navigate("/ProcessValidation");
                  }
                  }
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Process Validation/Re-Validation Report</span>}>
                    <span>Process Validation/Re-Validation Report{" "}</span>
                  </Tooltip>

                </div>
                <div
                  id="sidebar.DailyShiftProcessCheckListTabs"
                  name="sidebar.DailyShiftProcessCheckListTabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'D S P C L' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('D S P C L');
                    navigate("/DailyShiftProcessCheckList");
                  }
                  }
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Daily Shift Process CheckList</span>}>
                    <span>Daily Shift Process CheckList{" "}</span>
                  </Tooltip>

                </div>
             
                <div
                  id="sidebar./MachineStartupTabs"
                  name="sidebar./MachineStartupTabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'M S U' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('/M S U');
                    navigate("/MachineStartup");
                  }
                  }
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Daily MAchine StartUp and RestartUp</span>}>
                    <span>StartUP and RestartUP{" "}</span>
                  </Tooltip>

                </div>
              </Collapsible>
         
                
              <Collapsible
                className={style.sidebar_collapsible}
                trigger={AccordionHead5("Quality->", "MoldingQuality.Collapsible")}
                contentInnerClassName={style.sidebar_collapsible_open}
                onOpening={() => handleTabSelection("Quality->")}
              >

                <div
                  id="sidebar.ProductQualityPlanTabs"
                  name="sidebar.ProductQualityPlanTabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'P Q P' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('P Q P');
                    navigate("/quality_plan");
                  }
                  }
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Product Quality Plan</span>}>
                    <span>Product Quality Plan{" "}</span>
                  </Tooltip>

                </div>
            
                <div
                  id="sidebar.FirstOffInspTabs"
                  name="sidebar.FirstOffInspTabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'F O I' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('F O I');
                    navigate("/firstoff");
                  }
                  }
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>First-Off Inspection</span>}>
                    <span>First-Off Inspection{" "}</span>
                  </Tooltip>

                </div>

                <div
                  id="sidebar.LastOffInspTabs"
                  name="sidebar.LastOffInspTabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'L O I' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('L O I');
                    navigate("/LastOff");
                  }
                  }
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Last-Off Inspection</span>}>
                    <span>Last-Off Inspection{" "}</span>
                  </Tooltip>

                </div>
                <div
                  id="sidebar.OnlineInsTabs"
                  name="sidebar.OnlineInsTabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === ' O I' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('O I');
                    navigate("/onlineinspection");
                  }
                  }
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>OnLine Inspection</span>}>
                    <span>OnLine Inspection{" "}</span>
                  </Tooltip>

                </div>
                <div
                  id="sidebar.InlineInspTabs"
                  name="sidebar.InlineInspTabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'I I' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('I I');
                    navigate("layoutinline");
                  }
                  }
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Layout Inspection</span>}>
                    <span>Layout Inspection{" "}</span>
                  </Tooltip>

                </div>
              </Collapsible>

            </Collapsible>
            
              <Collapsible
  className={style.sidebar_collapsible}
  trigger={AccordionHead8("Painting Department->", "Painting.Collapsible")}
  contentInnerClassName={style.sidebar_collapsible_open}
  onOpening={() => handleTabSelection("Painting->")}
>
  <div>
  {(GetAccess("ADMIN","GM") || GetAccess("Quality (QC)","SR.EXECUTIVE" )) && (

      <Collapsible
        className={style.sidebar_collapsible}
        trigger={AccordionHead1("Master->", "Master.Collapsible")}
        contentInnerClassName={style.sidebar_collapsible_open}
        onOpening={() => handleTabSelection("Master->")}
            >
            <div
                  id="sidebar.mc.Tabs"
                  name="sidebar.mc.Tabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'PaintMaster' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('Paint Master');
                    navigate('/PaintMaster');
                  }}
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Machine Master</span>}>
                    <span>Paint Master{" "}</span>
                  </Tooltip>

                </div>
        <div
          id="sidebar.bc.Tabs"
          name="sidebar.bc.Tabs"
          className={`${style.sidebar_collapsible_item} ${activeItem === 'Paint Part Master' ? style.active : ''}`}
          onClick={() => {
            handleItemClick('Paint Part Master');
            navigate('/PaintPartMaster');
          }}
        >
          <Tooltip placement="right" trigger={['hover']} overlay={<span>Paint Part Master</span>}>
            <span>Paint Part Master{" "}</span>
          </Tooltip>
        </div>
        <div
                  id="sidebar.ppm.Tabs"
                  name="sidebar.ppm.Tabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'Paint Program Master' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('Paint Program Master');
                    navigate('/PaintProgramMaster');
                  }}
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Paint Program Master</span>}>
                    <span>Paint Program Master{" "}</span>
                  </Tooltip>

                </div>
            
                <div
                  id="sidebar.pcm.Tabs"
                  name="sidebar.pcm.Tabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'Paint customer Master' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('Paint customer Master');
                    navigate('/PaintCustomerMaster');
                  }}
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Paint customer Master</span>}>
                    <span>Paint Customer Master{" "}</span>
                  </Tooltip>

                </div>
                <div
                  id="sidebar.psm.Tabs"
                  name="sidebar.psm.Tabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'Paint Supplier Master' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('Paint Supplier Master');
                    navigate('/PaintSupplierMaster');
                  }}
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Paint Supplier Master</span>}>
                    <span>Paint Supplier Master{" "}</span>
                  </Tooltip>

                </div>
                <div
                  id="sidebar.phm.Tabs"
                  name="sidebar.phm.Tabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'Paint Hardner Master' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('Paint Hardner Master');
                    navigate('/PaintHardnerMaster');
                  }}
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Paint Hardner Master</span>}>
                    <span>Paint Hardner Master{" "}</span>
                  </Tooltip>

                </div>
                <div
                  id="sidebar.ppm.Tabs"
                  name="sidebar.ppm.Tabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'Paint Primer Master' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('Paint Primer Master');
                    navigate('/PaintPrimerMaster');
                  }}
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Paint Primer Master</span>}>
                    <span>Paint Primer Master{" "}</span>
                  </Tooltip>

                </div>
                <div
                  id="sidebar.ptm.Tabs"
                  name="sidebar.ptm.Tabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'Paint Thinner Master' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('Paint Thinner Master');
                    navigate('/PaintThinnerMaster');
                  }}
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Paint Thinner Master</span>}>
                    <span>Paint Thinner Master{" "}</span>
                  </Tooltip>

                </div>
                <div
                  id="sidebar.pmh.Tabs"
                  name="sidebar.pmh.Tabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'Paint Master Head' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('Paint Master Head');
                    navigate('/PaintMasterHead');
                  }}
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Paint Master Head</span>}>
                    <span>Paint Master Head{" "}</span>
                  </Tooltip>

                </div>
             </Collapsible>
                  )}

                  </div>

               <Collapsible
                className={style.sidebar_collapsible}
                trigger={AccordionHead4("Production->", "MoldingProduction.Collapsible")}
                contentInnerClassName={style.sidebar_collapsible_open}
                onOpening={() => handleTabSelection("Production->")}
              >
                <div
                  id="sidebar.SPPTabs"
                  name="sidebar.SPPTabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'SPP' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('SPP');
                    navigate('/StandardProcessParameter');
                  }
                  }
                >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Standard Process Parameter</span>}>
                    <span>Standard Process Parameter{" "}</span>
                  </Tooltip>

                </div>
                  <div
                  id="sidebar.PMMTabs"
                  name="sidebar.PMMTabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'PMM' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('PMM');
                    navigate('/PaintMixingMonitoring');
                   }
                   }
                   >
                   <Tooltip placement="right" trigger={['hover']} overlay={<span>Paint Mixing Monitoring</span>}>
                    <span>Paint Mixing Monitoring{" "}</span>
                   </Tooltip>
                   </div>

                  <div
                  id="sidebar.LPTabs"
                  name="sidebar.LPTabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'LP' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('LP');
                    navigate('/LoadingReport');
                  }
                  }
                  >
                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Loading Report</span>}>
                  <span>Loading Report{" "}</span>
                  </Tooltip>
                  </div>
                  </Collapsible >
                  <Collapsible  className={style.sidebar_collapsible}
                trigger={AccordionHead5("Quality->", "Painting.Collapsible")}
                contentInnerClassName={style.sidebar_collapsible_open}
                onOpening={() => handleTabSelection("Production->")}>

                  <div  id="sidebar.F&LTabs"
                  name="sidebar.F&LTabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'SPP' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('F&L');
                    navigate('/FirstandLastoff');
                  }
                  }>
                       <Tooltip placement="right" trigger={['hover']} overlay={<span>FirstOff & LastOff</span>}>
                    <span>FirstOff & LastOff{" "}</span>
                  </Tooltip>
                  </div>
                  <div  id="sidebar.FITabs"
                  name="sidebar.FITabs"
                  className={`${style.sidebar_collapsible_item} ${activeItem === 'Fi' ? style.active : ''}`}
                  onClick={() => {
                    handleItemClick('FI');
                    navigate('/FinalInspection');
                  }
                  }>
                       <Tooltip placement="right" trigger={['hover']} overlay={<span>Final Inspection</span>}>
                    <span>Final Inspection{" "}</span>
                  </Tooltip>
                  </div>
                  </Collapsible>
                  </Collapsible>
                
            {(GetAccess("ADMIN","GM") || GetAccess("production","hod") || GetAccess("Quality (QC)","SR.EXECUTIVE" )) && (<Collapsible
              className={style.sidebar_collapsible}
              trigger={AccordionHead7("Approval ✎->", "Session.Collapsible")}
              contentInnerClassName={style.sidebar_collapsible_open}
              onOpening={() => handleTabSelection("Approval ✎->")}
            >

              <div
                className={`${style.sidebar_collapsible_item} ${isItemActive(
                  "Approval"
                )}`}
                onClick={() => {
                  handleItemClick('A L');
                  navigate("Approval");
                }
                }
              >
                 <Tooltip placement="right" trigger={['hover']} overlay={<span>Approval✎</span>}>
                  <span>My Approval</span>
                </Tooltip>

              </div>
            </Collapsible>)}

            <Collapsible
              className={style.sidebar_collapsible}
              trigger={AccordionHead6("Session->", "Session.Collapsible")}
              contentInnerClassName={style.sidebar_collapsible_open}
              onOpening={() => handleTabSelection("Session->")}
            >

              <div
                className={`${style.sidebar_collapsible_item} ${isItemActive(
                  "Logout"
                )}`}
                onClick={logout}
              >

                <Tooltip placement="right" trigger={['hover']} overlay={<span>LogOut</span>}>
                  <span>LogOut{" "}</span>
                </Tooltip>
              </div>
            </Collapsible>
          </div>
        </div>
      </div>
    )
}

export default Sidebar;
