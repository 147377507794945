// export const API_URL = "192.168.0.213:4040";
// export const API_URL ="13.233.24.25:4040"
// export const API_URL = "52.167.226.44:4040";
// export const API_URL = "192.168.0.109:4040";
export const API_URL = "unitech-backend.binarywavesolutions.com";
export const API_URL_PAINT = "13.233.24.25:4141";


//Default API
export const SECRET_KEY = "2bf52be7-9f68-4d52-9523-53f7f267153b";
export const CODEGEN_URL = `https://${API_URL}/AppContextService/CodeGen`;
export const SIGNIN_URL = `https://${API_URL}/AppContextService/SignIn`;
export const PROCESS_TRANSACTION_URL = `https://${API_URL}/GenericTransactionService/processTransaction`;
export const PROCESS_TRANSACTION_UPDATE_URL = `https//${API_URL}/GenericTransactionService/processTransactionForUpdate`;
export const PROCESS_TRANSACTION_DELETE_URL = `https://${API_URL}/GenericTransactionService/processTransactionForDelete`;
export const BUILD_RESULTS_GENERIC = `https://${API_URL}/GenericResultBuilderService/buildResults`;
export const BUILD_RESULTS_GENERIC_QUARY = `https://${API_URL}/GenericResultBuilderService/buildResultsFromDirectQuery`;
export const RUN_ALL_GENERIC_QUARY = `https://${API_URL}/DataExportConfigurationService/executeQueryOnDataSourceForTenant`;

//Paint API
export const PROCESS_TRANSACTION_URL_PAINT = `http://${API_URL_PAINT}/GenericTransactionService/processTransaction`;
export const PROCESS_TRANSACTION_UPDATE_URL_PAINT = `http://${API_URL_PAINT}/GenericTransactionService/processTransactionForUpdate`;
export const PROCESS_TRANSACTION_DELETE_URL_PAINT = `http://${API_URL_PAINT}/GenericTransactionService/processTransactionForDelete`;
export const BUILD_RESULTS_GENERIC_PAINT = `http://${API_URL_PAINT}/GenericResultBuilderService/buildResults`;
export const BUILD_RESULTS_GENERIC_QUARY_PAINT = `http://${API_URL_PAINT}/GenericResultBuilderService/buildResultsFromDirectQuery`;
export const RUN_ALL_GENERIC_QUARY_PAINT = `http://${API_URL_PAINT}/DataExportConfigurationService/executeQueryOnDataSourceForTenant`;
export const SOFTWAREBREAK = false;
export const BREAKDATE = SOFTWAREBREAK ? "17-12-2023" : "";
export const BREAKTIME = SOFTWAREBREAK ? "(04:00pm)-(04:30pm)" : "";