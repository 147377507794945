import React, {useState,useEffect}from "react";
import { PROCESS_TRANSACTION_URL, CODEGEN_URL, SECRET_KEY } from "../../../Utils/constants.js";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";

import "../../../Styles/Part3.css";
import IsDuplicate from "../../../Lib/IsDuplicate.js";
const initialState = {
  
  FgCde: "",
  FgDescn: "",
  ClassDesc: "",
  Flexy1: "",
  Flexy2: "",
  Flexy3: "",
  UnitOfMeasure:"",
  Lot:"",
  Fg_mas: []
}

function FGMasterCreate({tab1}) {
  const [isCreating, setIsCreating] = useState(false); 
  const [fgMasterState, setFGMasterState] = useState(initialState);
  useEffect(() => {
    setFGMasterState(initialState);
  }, [tab1]);
  
  const CodeGen = async () => {
    try {
      const val = {
          tablename: "fg_details",
          PrimaryCode: "id",
          PrimaryVal: fgMasterState.Id,
          FieldCode: "fg_code",
          FieldVal: fgMasterState.FgCde,
      };
	   
      const response = await getAPIRequest(
        CODEGEN_URL,'POST', val
      );
      const response1 = await response.json();
      return (response1.success === 'true');
    } catch (error) {
      throw error;
    }
  };
  

  const handleCreate = async (e) => {
    e.preventDefault();
    
    const isSubmissionSuccessful = true; 

    if (!fgMasterState.FgCde || !fgMasterState.FgDescn) {
      setIsCreating(true);
      alert(GeneralConstants.MANDATORY_ALERT);
      return;
    }
    try {
      // const isDuplicate = await CodeGen();

      // if (isDuplicate) {
      //   console.log("jj")
      //   alert(`${fgMasterState.FgDescn} ${GeneralConstants.ALREADY_EXIST}`);
      //   setFGMasterState({...fgMasterState,
      //     ...initialState
      //   });
      //   setIsCreating(false);
      //   return;
      // }
      const trimmedCode = fgMasterState.FgCde.trim();
    const trimmedName = fgMasterState.FgDescn.trim();
      const isDuplicateCode = await IsDuplicate("fg_details", [
        `fg_code="${trimmedCode}"`,
        `active_status = 'Active'`,
      ]);

      if (isDuplicateCode) {
        alert(
          `Fg code ${trimmedCode} already exists.`
        );
        return;
      }
      const isDuplicateCode1 = await IsDuplicate("fg_details", [
        `fg_description="${trimmedName}"`,
        `active_status = 'Active'`,
      ]);

      if (isDuplicateCode1) {
        alert(
          `Fg name ${trimmedName} already exists.`
        );
        return;
      }
      const data = {
        
        data: [
          {
            Fg_code: trimmedCode,
            Fg_description: trimmedName,
            Class_desc: fgMasterState.ClassDesc,
            Fg_flex_01: fgMasterState.Flexy1,
            Fg_flex_02: fgMasterState.Flexy2,
            Fg_flex_03: fgMasterState.Flexy3,
            Unit_of_measure:fgMasterState.UnitOfMeasure,
            Lot:fgMasterState.Lot,
          
          },
        ],
        _keyword_: "fg_details_create",
        secretkey: SECRET_KEY,
      };     

      const response = await getAPIRequest(
        PROCESS_TRANSACTION_URL,'POST', data
      );
      const response1 = await response.json();

      if(response1.success === "false"){
          alert(GeneralConstants.FAIL_TO_SAVE_FG);
          window.location.reload();
      }
      else{
        const fgmas = [...fgMasterState.Fg_mas, response.data]
        setFGMasterState({...fgMasterState, Fg_mas: fgmas});
      }
      if (isSubmissionSuccessful) {
      alert(`FG Master Code ${GeneralConstants.SUBMISSION_SUCCESSFUL}`); 
        setFGMasterState({...fgMasterState, ...initialState
        });  
        setIsCreating(false);

      }
    } catch (error) {
      console.log(error);
      alert(`${fgMasterState.FgDescn} ${GeneralConstants.SUBMISSION_FAILED}`); 
      window.location.reload();
    }
     
  };

  return (
    <div >
      <form id="fgMasterCreate.formContainer" name="fgMasterCreate.formContainer" className="form-fgMaster-Create">
        <table style={{ textAlign: "center", width: "100%" }}  cellPadding="5" >
          <tbody>
            <tr>
              <th
              id="fgmc.Title"
              name="fgmc.Title"
                colSpan="4"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">FG Master</font>
              </th>
            </tr>
            <tr>
              <th>&nbsp;&nbsp;FG Code<span style={{color:"red"}}>*</span></th>
              <td>
              {fgMasterState.FgCde === '' && isCreating && <span className="Req">Required *</span>}
                <input
                id="fgmc.FGCode"
                name="fgmc.FGCode"
                  type="text"
                  value={fgMasterState.FgCde}
                  onChange={(e) => setFGMasterState({...fgMasterState,
                    FgCde: e.target.value
                  })}
                  required
                />
              </td>
              <th>&nbsp;&nbsp;FG Description<span style={{color:"red"}}>*</span></th>
              <td>
              {fgMasterState.FgDescn === '' && isCreating && <span className="Req">Required *</span>}
                <input
                id="fgmc.FGName"
                name="fgmc.FGName"
                  type="text"
                  value={fgMasterState.FgDescn}
                  onChange={(e) => setFGMasterState({...fgMasterState,
                    FgDescn: e.target.value
                  })}
                  required
                />
              </td>
            </tr>
            <tr>
              <th>&nbsp;&nbsp;Class Description</th>
              <td>
                {/* <input
                id="fgmc.ClassDesc"
                name="fgmc.ClassDesc"
                  type="text"
                  
                  value={fgMasterState.ClassDesc}
                  onChange={(e) => setFGMasterState({...fgMasterState,
                    ClassDesc: e.target.value
                  })}
                /> */}
                <select
                  id="fgmc.ClassDesc"
                  name="fgmc.ClassDesc"
                  value={fgMasterState.ClassDesc}
                  onChange={(e) => setFGMasterState({...fgMasterState,
                    ClassDesc: e.target.value
                  })}
                  style={{width:"95%" ,textAlign:"center"}} 
                >
                  <option id="fgmc.ClassDesc.select"
                  name="fgmc.ClassDesc.select"
                  value="">-- Select --</option>
                  <option id="fgmc.ClassDesc.FINISHED-GOOODS"
                  name="fgmc.ClassDesc.FINISHED-GOOODS"
                  value="FINISHED GOOODS">FINISHED GOOODS</option>
                  <option id="fgmc.ClassDesc.FIXED-ASSETS"
                  name="fgmc.ClassDesc.FIXED-ASSETS"
                  value="FIXED ASSETS">FIXED ASSETS</option>
                  <option id="fgmc.ClassDesc.JIG-AND-FIXTURES"
                  name="fgmc.ClassDesc.JIG-AND-FIXTURES"
                  value="JIG AND FIXTURES">JIG AND FIXTURES</option>
                  <option id="fgmc.ClassDesc.SEMI-FINISHED-GOODS"
                  name="fgmc.ClassDesc.SEMI-FINISHED-GOODS"
                  value="SEMI FINISHED GOODS">SEMI FINISHED GOODS</option>
                </select>
              </td>
              <th>&nbsp;&nbsp;Lot</th>
              <td>
                <input
                id="fgmc.Lot"
                name="fgmc.Lot"
                  type="text"
                  value={fgMasterState.Lot}
                  onChange={(e) => setFGMasterState({...fgMasterState,
                    Lot: e.target.value
                  })}
                />
              </td>
              </tr><tr>
              <th>&nbsp;&nbsp;Unit Of Measure</th>
              <td>

              <select
                  id="fgmc.UnitOfMeasure"
                  name="fgmc.UnitOfMeasure"
                  value={fgMasterState.UnitOfMeasure}
                  onChange={(e) => setFGMasterState({...fgMasterState,
                    UnitOfMeasure: e.target.value
                  })}
                  style={{width:"95%" ,textAlign:"center"}} 
                >
                  <option id="fgmc.UnitOfMeasure.select"
                  name="fgmc.UnitOfMeasure.select"
                  value="">-- Select --</option>
                  <option id="fgmc.UnitOfMeasure.KGS"
                  name="fgmc.UnitOfMeasure.KGS"
                  value="KGS">KGS</option>
                  <option id="fgmc.UnitOfMeasure.LIT"
                  name="fgmc.UnitOfMeasure.LIT"
                  value="LIT">LIT</option>
                  <option id="fgmc.UnitOfMeasure.NOS"
                  name="fgmc.UnitOfMeasure.NOS"
                  value="NOS">NOS</option>
                </select>
                
                {/* <input
                id="fgmc.UnitOfMeasure"
                name="fgmc.UnitOfMeasure"
                  type="text"
                  value={fgMasterState.UnitOfMeasure}
                  onChange={(e) => setFGMasterState({...fgMasterState,
                    UnitOfMeasure: e.target.value
                  })}
                /> */}
              </td>
              <th>&nbsp;&nbsp;FG Stock</th>
              <td>
                <input
                id="fgmc.Flexy1"
                name="fgmc.Flexy1"
                  type="text"
                  value={fgMasterState.Flexy1}
                  onChange={(e) => setFGMasterState({...fgMasterState,
                    Flexy1: e.target.value
                  })}
                />
              </td>
            </tr>
            <tr>
              <th>&nbsp;&nbsp;Flex 1</th>
              <td>
                <input
                id="fgmc.Flexy2"
                name="fgmc.Flexy2"
                  type="text"
                  value={fgMasterState.Flexy2}
                  onChange={(e) => setFGMasterState({...fgMasterState,
                    Flexy2: e.target.value
                  })}
                />
              </td>
              <th>&nbsp;&nbsp;Flex 2</th>
              <td>
                <input
                id="fgmc.Flexy3"
                name="fgmc.Flexy3"
                  type="text"
                  value={fgMasterState.Flexy3}
                  onChange={(e) => setFGMasterState({...fgMasterState,
                    Flexy3: e.target.value
                  })}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="4" style={{border:"none"}}>
                <button type="submit" id="fgmc.submit" name ="fgmc.submit" onClick={(e) => handleCreate(e)}>
                  Submit
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
}


export default FGMasterCreate;
