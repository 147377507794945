import React from "react";
import "./Popup.css"
export function PopupPdf(props) {  
  return (props.trigger) ? (
    <div className="popupPdf" id="popupPdf">
      <div className="popup-inner-pdf">
        <button id="close-btn-pdf"
        name="close-btn-pdf"
        className="close-btn-pdf" onClick={() => {props.setTrigger(false); props.viewPagination()}}>
          X
        </button>
        {props.children}
      </div>
    </div>
  ) : 
    ""
  ;
  

}

export default PopupPdf;
