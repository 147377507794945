import React, { useEffect, useState, useRef, useCallback } from "react";
import Field from "../../../../Components/Fields/FieldPaint.js";
import Lable from "../../../../Components/Fields/Lable";
import { useNavigate, useParams } from "react-router-dom";
import "./PMMForm.css";
import * as GeneralConstants from "../../../../Utils/GeneralConstants.js";
import {
  CURRENT_Date,
  SHIFTMOLDINGQUALITY,
} from "../../../../Utils/GeneralConstants";
import LoadingPage from "../../../../Components/Handler/LoadingPage";

import { useScrollEffect } from "../../../Quality/useScrollEffect";
import CreateRecordPaint from "../../../../Lib/API/CreateRecordPaint.js";
import ReadRecordPaint from "../../../../Lib/API/ReadRecordPaint.js";
import UpdateRecordPaint from "../../../../Lib/API/UpdateRecordPaint.js";
import QaLayoutPaint from "../../QaLayoutPaint.js";

const PMMFormnew = () => {
  useScrollEffect();
  const [DownloadDiv, setDownloadDiv] = useState(false);
  const CURRENT_TIME = new Date(
    new Date().getTime() - new Date().getTimezoneOffset() * 60000
  )
    .toISOString()
    .split("T")[1]
    .substring(0, 5);

  const InitRoot = [
    {
      download: false,
      Total_Weight: "0.000",
      Batch_no: "",
      BatchNo: [{}],
    Ps: "",
    Model: "",
    Part_name: "",
    Supplier: "",
    Category: "",
    Customer: "",
    Paint_name: "",
    Hardner_name: "",
    Thinner_name: "",
    Vis_set_plus: "",
    Vis_set_minus: "",
    Mixing_ratio: "00:00:00",
    ActiveStatus: "Active",
    CusName: [{}],
    SupName: [{}],
    PartName: [{}],
    PaintName: [{}],
    HardnerName: [{}],
    ThinnerName: [{}],
      Date: CURRENT_Date,
     
    },
  ];
  const tableRef = useRef(null);
  const scrollTop = useRef(null);

  const [Records, setRecords] = useState(InitRoot);
  const [warning, setwarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(false);
  useEffect(() => {
    console.log("Records", Records);
  }, [Records]);

  const { option, id } = useParams();
  const navigate = useNavigate();

  function HandleRecords(Event) {
    setRecords([{ ...Records[0], [Event.target.name]: Event.target.value }]);
  }

  function HandleCreate(event) {
    try {
      if (
        !Records[0].Part_name ||
        !Records[0].Created_By ||
        !Records[0].Approved_By
      ) {
        alert("Please Fill the Mandatory Fields");
        setwarning(true);
      } else {
        let record = [...Records];
        record && delete record[0].PartName;
        record && delete record[0].PartNumber;
        record && delete record[0].SupName;
        record && delete record[0].CusName;
        record && delete record[0].ThinnerEng;
        record && delete record[0].HardnerEng;
        record && delete record[0].PrimerEng;
        record && delete record[0].CreatedBy;
        record && delete record[0].ApprovedBy;
        console.log("createFter Delete", record);
        CreateRecordPaint("paint_mixing_monitor", record).then((res) => {
          console.log("HandleCreate", res);
        });
        alert(GeneralConstants.SUBMISSION_SUCCESSFUL);
        setwarning(false);
        setRecords(InitRoot);
      }
    } catch (error) {
      console.error(error);
      alert(GeneralConstants.SUBMISSION_FAILED);
    }
  }
  function HandleUpdate(event) {
    try {
      if (
        !Records[0].Part_name ||
        !Records[0].Created_By ||
        !Records[0].Approved_By
      ) {
        alert("Please Fill the Mandatory Fields");
        setwarning(true);
      } else {
        let record = [...Records];
        record && delete record[0].PartName;
        record && delete record[0].PartNumber;
        record && delete record[0].SupName;
        record && delete record[0].CusName;
        record && delete record[0].ThinnerEng;
        record && delete record[0].HardnerEng;
        record && delete record[0].PrimerEng;
        record && delete record[0].CreatedBy;
        record && delete record[0].ApprovedBy;
        UpdateRecordPaint("paint_mixing_monitor", record).then((res) => {
          console.log("HandleUpdate", res);
        });
        alert(GeneralConstants.UPDATE_SUCCESSFUL);
        setwarning(false);
        navigate(-1);
      }
    } catch (error) {
      console.error(error);
      alert(GeneralConstants.UPDATE_FAILURE);
    }
  }
  useEffect(() => {
  console.log("ioio",Records[0].Batch_no);
  })
  useEffect(() => {
    setwarning(false);
    document.title = "Paint Mixing Monitor";
    const fetchData = async () => {
      if (id === undefined) {
        
        setRecords([
          {
            ...Records[0],
            Date: CURRENT_Date,
            //Time: CURRENT_TIME,
            Shift: SHIFTMOLDINGQUALITY,
          },
        ]);
      } else {
        setLoading(true);
        try {
          const res = await ReadRecordPaint({
            _keyword_: "paint_mixing_monitor",
            Id: id,
          });
          if (res.data[0] === undefined) {
            setRecords(InitRoot);
          } else {
            setRecords(res.data);
            setResult(false);
          }
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return <LoadingPage />;
  }
  useEffect(() => {
    const fetchData1 = async () => {
        setwarning(false);
        try {
            if (Records?.[0]?.Batch_no) {
                try {
                    const res1 = await ReadRecordPaint({
                        _keyword_: "Get_pain_master_Head_Batchno",
                        Batch_no: Records[0].Batch_no,
                    });
                    if (res1.data[0] === undefined) {
                        setRecords(InitRoot);
                    } else {
                        console.log("000result",res1.data);
                        setRecords([
                            {
                                ...Records[0], 
                                ...res1.data[0], 
                            }
                        ]);
                        setResult(false);
                    }
                } finally {
                    setLoading(false);
                    console.log(Records);
                }
            } else {
                setRecords([
                    {
                        ...Records[0], 
                        Date: CURRENT_Date,
                        Shift: SHIFTMOLDINGQUALITY,
                    },
                ]);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };

    fetchData1();
}, [Records?.[0]?.Batch_no]); 


  function HandleRecordSelect(Event, column, value, label) {
    console.log(Event);
    setRecords(() => {
      let record = [...Records];
      record[0][Event.name] = Event.values.value;
      record[0][column][0][value] = Event.values.value;
      record[0][column][0][label] = Event.values.label;

      return record;
    });
  }
  function HandleRecordSelectPartname(Event, column, value, label) {
    setRecords(() => {
      let record = [...Records];
      record[0][Event.name] = Event.values.label;
      record[0][column][0][value] = Event.values.value;
      record[0][column][0][label] = Event.values.label;

      return record;
    });
  }
  function HandlePartname(e) {
    const PN = e.values.label;
    ReadRecordPaint({
      _keyword_: "Get_part_code_from_paint",
      PartName: PN,
    }).then((res) => {
      if (res.rows == "1") {
        setRecords(() => {
          let record = [...Records];
          console.log(res.data[0].part_code);
          record[0].Part_number = res.data[0].part_code;
          //  (record[0]["PartNumber"][0]["Part_number"] = res.data[0].part_code);
          return record;
        });
      } else if (res.rows > "1") {
        console.log("HandlePartname else", res.rows);
      }
      console.log("HandlePartname", res.rows);
    });
  }

  return (
    <QaLayoutPaint record={Records}>
      <div className="Form" ref={scrollTop}>
        <div ref={tableRef}>
          <table>
            <tbody>
                <tr>
            <th colSpan={7}>
                  <Lable
                    style={{ padding: "0px" }}
                    value={"Paint Mixing Monitoring"}
                    type={"Head"}
                  />
                </th></tr>
              <tr>
                <td colSpan={2}>
                  {" "}
                  <Field
                    label={"CLEAR"}
                    options={{ type: "Button" }}
                    onClick={(e) => {
                      HandleUpdate(e);
                    }}
                  />
                </td>
                <td colSpan={2}>
                  <Field
                    label={"CALC"}
                    options={{ type: "Button" }}
                    onClick={(e) => {
                      HandleUpdate(e);
                    }}
                  />
                </td>
                <td colSpan={2}>
                  <Lable
                    value={"WEIGHT :"}
                    style={{ float: "left", padding: "2px" }}
                  >
                    {" "}
                  </Lable>
                  <span>
                    <Lable
                      value={Records[0].Total_Weight}
                      style={{
                        width: "70%",
                        borderRadius: "10px",
                        padding: "2px",
                        marginLeft: "20%",
                        fontSize: "larger",
                        fontWeight: "700",
                        textAlign: "center",
                        backgroundColor: "#96d4d4",
                      }}
                    ></Lable>
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <Lable value={"P.Id"} type={"Head"} />
                </td>
                <td>
                  <Lable value={"Product Name"} type={"Head"} />
                </td>
                <td>
                  <Lable value={"Actual Weight"} type={"Head"} />
                </td>
                <td>
                  <Lable value={"Deviation"} type={"Head"} />
                </td>
                <td>
                  <Lable value={"Standard"} type={"Head"} />
                </td>
                <td />
              </tr>
              <tr>
                <td colSpan={1}>{Records[0].PaintName[0].Paint_code}</td>
                <td colSpan={1}>{Records[0].PaintName[0].Paint_name}</td>
                <td colSpan={1}>{898}</td>
                <td colSpan={1}>{898}</td>
                <td colSpan={1}>{898}</td>

                <td rowSpan={6}>
                  <Field
                    label={"BatchNo"}
                    name={"Batch_no"}
                    options={{
                      type: "reference",
                      reference: "Get_paint_batch_no",
                      required: true,

                      // disabled: option === "view" ,
                    }}
                    // style={{ padding: "10px" }}
                    // warning={warning}

                    value={{
                      value: Records?.[0]?.BatchNo?.[0].Batch_no || "",
                      label: Records?.[0]?.BatchNo?.[0].Batch_no || "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(e, "BatchNo", "Batch_no", "Batch_no");
                    }}
                  />
                 
                  <Field
                    style={{ whiteSpace: "nowrap", float: "left" ,marginLeft:"70%"}}
                    label={"Lock"}
                    options={{ type: "Button" }}
                    onClick={(e) => {
                      HandleUpdate(e);
                    }}
                  />
                  <Field
                    style={{ whiteSpace: "nowrap" }}
                    label={"Un-Lock"}
                    options={{ type: "Button" }}
                    onClick={(e) => {
                      HandleUpdate(e);
                    }}
                  />
                </td>
              </tr>
              <tr>

              </tr>
              <tr>
                {" "}
                <td colSpan={1}>{Records[0].HardnerName[0].Hardner_code}</td>
                <td colSpan={1}>{Records[0].HardnerName[0].Hardner_name}</td>
                <td colSpan={1}>{898}</td>
                <td colSpan={1}>{898}</td>
                <td colSpan={1}>{898}</td>
              </tr>
              <tr>
                {" "}
                <td colSpan={1}>{Records[0].ThinnerName[0].Thinner_code}</td>
                <td colSpan={1}>{Records[0].ThinnerName[0].Thinner_name}</td>
                <td colSpan={1}>{898}</td>
                <td colSpan={1}>{898}</td>
                <td colSpan={1}>{898}</td>
              </tr>
            <tr>  <td colSpan={5}>
                  <Lable
                    value={"Ratio :"}
                    style={{ float: "left", padding: "2px" }}
                  >
                    {" "}
                  </Lable>
                  <span>
                    <Lable
                      value={Records[0].Mixing_ratio
                      }
                      style={{
                        width: "70%",
                        borderRadius: "10px",
                        padding: "2px",
                        marginLeft: "20%",
                        fontSize: "larger",
                        fontWeight: "700",
                        textAlign: "center",
                        backgroundColor: "#96d4d4",
                      }}
                    ></Lable>
                  </span>
                </td></tr>
            </tbody>
          </table>
        </div>
      </div>
    </QaLayoutPaint>
  );
};

export default PMMFormnew;
