import React,{useState,useEffect} from "react";
import ListViewPaint from "../../../../Components/ListView/ListViewPaint";
import QaLayoutPaint from "../../QaLayoutPaint";

const PartMasterList = () => {
 
  return (
    <QaLayoutPaint quary={"paint_part_master"}>
    <div>
      <ListViewPaint
        Title={"Paint Part Master"}
        standard={["delete_id=0"]}
        quary={"Select id as Id,part_code,part_name,active_status,CreatedDate from paint_part_master group by Id ORDER BY CASE WHEN active_status = 'Active' THEN 0 ELSE 1 END,Id DESC"}
        table={"paint_part_master"}
        tableDelete={"paint_part_master"}
        navigation={"PaintPartMaster"}
        filter={["Id","part_code","part_name","active_status"]}
        dateFilter={"CreatedDate"}
        header={[
          { value: "Id", label: "ID" },
          { value: "CreatedDate", label: "Date" },
          { value: "part_code", label: "Part Code" },
          { value: "part_name", label: "Part Name" },
          { value: "active_status", label: "Active Status" },
        ]}
      />   
    </div>
    </QaLayoutPaint>
  );
};

export default PartMasterList;
