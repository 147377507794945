import React from "react";

import ListViewPaint from "../../../../Components/ListView/ListViewPaint";
import QaLayoutPaint from "../../QaLayoutPaint";

const LoadingList = () => {
  return (
    <QaLayoutPaint quary={"paint_loading_report"}>
      <div>
        <ListViewPaint
          Title={"Paint Loading Report"}
          standard={["Delete_id=0"]}
          quary={
            "SELECT qp.Id,qp.Entry_date,ed_loader.emp_name AS LoaderName,ed_incharge.emp_name AS LoadIncharge,ed_approver.emp_name AS ApprovedBy FROM upd_data_model.paint_loading_report qp LEFT JOIN emp_details ed_loader ON qp.LoaderName = ed_loader.emp_code LEFT JOIN emp_details ed_incharge ON qp.LoadIncharge = ed_incharge.emp_code LEFT JOIN emp_details ed_approver ON qp.ApprovedBy = ed_approver.emp_code group by qp.Id order by qp.Id desc"
          }
          table={"paint_loading_report"}
          tableDelete={"paint_loading_report"}
          navigation={"LoadingReport"}
          filter={["qp.Id"]}
          dateFilter={"qp.CreatedDate"}
          header={[
            { value: "qp.Id", label: "ID" },
            { value: "Entry_date", label: "Date" },
            { value: "LoaderName", label: "LoaderName" },
            { value: "LoadIncharge", label: "LoadIncharge"},
            { value: "ApprovedBy", label: "ApprovedBy"},
          ]}
        />
      </div>
    </QaLayoutPaint>
  );
};

export default LoadingList;
