import React, { useEffect, useState, useRef } from "react";
import {
  SECRET_KEY,
  CODEGEN_URL,
  PROCESS_TRANSACTION_URL,
} from "../../../Utils/constants.js";
import { getAPIRequest } from "../../../Utils/APIUtility";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import "../../../Styles/Machbd.css";
import Unitechlogo from "../../../icons/Unitechlogo.jpeg";
import DatePicker from "../../../Lib/DatePicker.js";
import DropdownFetcher from "../../../Lib/DropdownFetcher.js";
import Select from "react-select";
import useScanDetection from "use-scan-detection";
import { setMessage } from "../../../Lib/SetMessage.js";
import CreateApproval from "../../../Lib/CreateApproval";
import ReadRecord from "../../../Lib/API/ReadRecord.js";
import {
  CURRENT_Date,
  CURRENT_TIME,
  SHIFTMOLDINGPRODUCTION,
} from "../../../Utils/GeneralConstants";

function MacBDCreate() {

  const scrollTop = useRef(null);
  // const currentTime = new Date().toLocaleTimeString([], {
  //   hour: "2-digit",
  //   minute: "2-digit",
  // });
  const handleDateChange = (newDate) => {
    let currentTime = new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    setD1(newDate);
    setT1(currentTime);
  };

  const handleDateChange1 = (newDate) => {
    let currentTime = new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    setD2(newDate);
    setT2(currentTime);
  };
  const handleDateChange2 = (newDate) => {
    let currentTime = new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    setD3(newDate);
    setT3(currentTime);
    calculateTimeDifference(newDate, currentTime);

  };
  const calculateTimeDifference = (D3, T3) => {
    const date1 = new Date(`${D1},${T1}`);
    const date2 = new Date(`${D2},${T2}`);
    const date3 = new Date(`${D3},${T3}`);
    // console.log("date2", (date2 - date1) / (1000 * 60));
    // console.log("date3", (date3 - date2) / (1000 * 60));
    let c1 = ((date3 - date2) + (date2 - date1)) / (1000 * 60);
    // console.log("c1", c1);
    const totalHours = Math.floor(c1 / 60);
    const remainingMinutes = Math.floor(c1 % 60);

    // console.log("Converted time: ", `${totalHours} hours ${remainingMinutes} minutes`);
    setTHrs(`${totalHours} hrs ${remainingMinutes} mins`);
  };

  const [partname, setPartName] = useState([]);
  const [Employee, setEmployee] = useState([]);
  const [Name, setname] = useState([]);
  const [macno, setmacno] = useState([]);
  const [FormtNo, setFormtNo] = useState("");
  const [Rev, setRev] = useState("");
  const [Date0, setDate0] = useState("");
  const [Frm, setFrm] = useState("");
  const [SerNo, setSerNo] = useState("");
  const [Dept, setDept] = useState("");
  const [InfTo, setInfTo] = useState("");
  const [ERPNo, setERPNo] = useState("");
  const [empNo, setempNo] = useState("");
  const [PrtNme, setPrtNme] = useState("");
  const [Shft, setShft] = useState(SHIFTMOLDINGPRODUCTION);
  const [MacPrb, setMacPrb] = useState("");
  const [ProPrb, setProPrb] = useState("");
  const [PreStsM, setPreStsM] = useState("");
  const [PrbSts, setPrbSts] = useState("");
  const [BrkDwnT, setBrkDwnT] = useState("");
  const [MacCat, setMacCat] = useState("");
  const [NofP, setNofP] = useState("");
  const [AntiFault, setAntiFault] = useState("");
  const [D1, setD1] = useState("");
  const [D2, setD2] = useState("");
  const [D3, setD3] = useState("");
  const [T1, setT1] = useState("");
  const [T2, setT2] = useState("");
  const [T3, setT3] = useState("");
  const [S1, setS1] = useState("");
  const [S2, setS2] = useState("");
  const [S3, setS3] = useState("");
  const [THrs, setTHrs] = useState("");
  const [SRec, setSRec] = useState("");
  const [Temp, setTemp] = useState("");
  const [Permn, setPermn] = useState("");
  const [UnOb, setUnOb] = useState("");
  const [CorrAct, setCorrAct] = useState("");
  const [SprRep, setSprRep] = useState("");
  const [ItmNo, setItmNo] = useState("");
  const [Size, setSize] = useState("");
  const [Cost, setCost] = useState("");
  const [InCrg, setInCrg] = useState("");
  const [TechSign, setTechSign] = useState("");
  const [Sign1, setsign1] = useState("");
  var [macbd, setmac_bd] = useState([]);

  const [isCreating, setIsCreating] = useState(false);

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedInfto, setSelectedinfto] = useState(null);
  const [selectedmac, setSelectedmac] = useState(null);
  const [selecteds1, setSelecteds1] = useState(null);
  const [selecteds2, setSelecteds2] = useState(null);
  const [selecteds3, setSelecteds3] = useState(null);
  const [selectedinchrg, setSelectedinchrg] = useState(null);
  const [selectedtechS, setSelectedtechS] = useState(null);

  const handleEmployee = (selectedOption) => {
    const selected = Employee.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setSelectedEmployee({
        value: selected.Emp_code,
        label: selected.Emp_name,
      });
      setDept(selected.Emp_department);
      setFrm(selected.Emp_code);
      setSelecteds1(selected.Emp_name);
      // let NewDate = new date
      handleDateChange(CURRENT_Date);
    }
  };
  function GetPartDetails(val) {
    ReadRecord({
      _keyword_: "Get_part_from_smp_by_machine",
      machine: val,
    }).then((res) => {
      if (res.rows =="1") {
        console.log("respose of raw", res.data);
      setPrtNme({ value: res.data[0].mc_part_name, label: res.data[0].mc_part_name });
      } else if(res.rows>"1"){
        setPartName(res.data);
      } else{
        alert("Contact Administrator to add Part for this Selected Machine!");
      }
    })
  }
  const handleMachine = (selectedOption) => {
    GetPartDetails(selectedOption.value);
    const selected = macno.find(
      (option) => option.mh_mac_name === selectedOption.value
    );
    if (selected) {
      setSelectedmac({
        value: selected.mh_mac_name,
        label: selected.mh_mac_name,
      });
      setempNo(selected.mh_mac_name);
    }
  };

  const handleInformedToEmployeeChange = (selectedOption) => {
    setSelectedinfto(selectedOption);
    setInfTo(selectedOption ? selectedOption.value : "");
  };
  const handleS1 = (selectedOption) => {
    setSelecteds1(selectedOption);
    setS1(selectedOption ? selectedOption.value : "");
  };
  const handleS2 = (selectedOption) => {
    setSelecteds2(selectedOption);
    setS2(selectedOption ? selectedOption.value : "");
  };
  const handleS3 = (selectedOption) => {
    setSelecteds3(selectedOption);
    setS3(selectedOption ? selectedOption.value : "");
  };

  const handleIncharge = (selectedOption) => {
    setSelectedinchrg(selectedOption);
    setInCrg(selectedOption ? selectedOption.value : "");
  };
  const handleTechS = (selectedOption) => {
    setSelectedtechS(selectedOption);
    setTechSign(selectedOption ? selectedOption.value : "");
  };
  useEffect(() => {
    // let datetime = new Date();
    // let date = new Date().toISOString().split("T")[0]
    // // setTrackDate(new Date().toISOString().split("T")[0]);

    // if (datetime.getHours() < 18 && datetime.getMinutes < 59) {
    //   setShft("B");
    // } else {
    //   setShft("A");
    // }
    document.title = "Machine Breakdown"

  }, []);
  // function handleFieldChange(code) {
  //   let values = partname.find((key) => key["mc_part_code"] === code);
  //    setPrtNme(values["mc_part_code"]);

  // }
  const handleFieldChange = (selectedOption) => {    
    const selected = partname.find(
      (option) => option.mc_part_name === selectedOption.value
    );
    if (selected) {
      setPrtNme({ value: selected.mc_part_name, label: selected.mc_part_name });
      // setMldNme(selected.mc_part_name);
    }
  };


  const id = "Mach_bd";
  const name = "Mach_bd";

  const handleCreate = async (e) => {
    e.preventDefault();
    const isSubmissionSuccessful = true;

    if (!ERPNo || !Frm || !SerNo || !InfTo || !macno || !PrtNme || !D1) {
      setIsCreating(true);
      alert("Please fill the mandatory fields");
      if (scrollTop.current) {
        scrollTop.current.scrollIntoView({ behavior: "smooth" });
      }
      return;
    }

    try {
      //  const isDuplicate = await CodeGen();

      // if 
      // (isDuplicate) {

      //   alert(`${empNo} Already Exist.`);
      //   // setGrade("");
      //   setempNo("");
      //  // window.location.reload();
      //   return;
      // }

      const data = {
        data: [
          {
            Format_no: FormtNo,
            Revision: Rev,
            // Date:Date0,
            Dept: Dept,
            From1: Frm,
            Serial_No: SerNo,
            ERPNo: ERPNo,
            Inform_To: InfTo,
            Machine_No: empNo,
            Part_Name: PrtNme.value,
            Shift: Shft,
            Machine_Prob: MacPrb,
            ProProb: ProPrb,
            Pre_Status_Mac: PreStsM,
            Problem_Status: PrbSts,
            BreakdownType: BrkDwnT,
            Mach_Category: MacCat,
            NatOfProb: NofP,
            Anticipated_Cause: AntiFault,
            Date1: D1,
            Time1: T1,
            Time2: T2,
            Time3: T3,
            Sign1: Frm,
            Sign2: S2,
            Sign3: S3,
            TotalHours: THrs,
            Status_Rect: SRec,
            Temporary: Temp,
            Permanent: Permn,
            Under_Obs: UnOb,
            Corrective_Action: CorrAct,
            Spare_Replaced: SprRep,
            Item_No: ItmNo,
            Size: Size,
            Cost: Cost,
            Incharge_Sign: InCrg,
            Technician_Sign: TechSign,
          },
        ],
      };
      data["_keyword_"] = "Mach_breakdown";
      data["secretkey"] = SECRET_KEY;
      const response = await getAPIRequest(
        PROCESS_TRANSACTION_URL,
        "POST",
        data
      );
      const response1 = await response.json();
      console.log(response1);
      alert(`Submited Succcessfully!`);
      setMessage({
        NoteFrom: Frm,
        NoteTo: InfTo,
        NoteMessage:
          `The machine breakdown Report Has been Created By ${Frm}}-
        ${selectedEmployee.label} 
        , For The Part Name - 
        ${PrtNme.label}
        and The Problem is
        ${ProPrb}`,
        NoteRecordId: response1.ROWID,
        TableName: "MacBD",
        NoteCreatedBy: localStorage.getItem("empId"),
      });
      // console.log('message:', message);
      setMessage({
        NoteFrom: Frm,
        NoteTo: TechSign,
        NoteMessage:
          `The Machine Breakdown Report Has been Created By ${Frm}-
        ${selectedEmployee.label} 
        , For The Part Name - 
        ${PrtNme.label}
        and The Problem is
        ${ProPrb}`,
        NoteRecordId: response1.ROWID,
        TableName: "MacBD",
        NoteCreatedBy: localStorage.getItem("empId"),
      });


      // if (response.data.success === "false") {
      //   alert("Problem in Saving Machine details");
      // } else {
      //   setmac_bd(response.data);
      //   alert(`${empNo} Submitted`);
      //   if (scrollTop.current) {
      //     scrollTop.current.scrollIntoView({ behavior: "smooth" });
      //   }
      //   // window.location.reload();
      // }
    } catch (error) {
      console.log(error);
      alert(`${GeneralConstants.SUBMISSION_FAILED}`);
    }
    window.location.reload();

  };

  // barcode scannning to get input
  useScanDetection({
    onComplete: (code) => {
      code = code.replaceAll("Shift", "");
      setField(code);
    },
    minLength: 3,
  });

  // set value to the appropiate field
  function setField(code) {
    // let isnumber = Number(code).toString() !== "NaN";
    let current_element = document.activeElement.id;

    if (code.includes("EMP")) {
      if (current_element === "" || current_element === "react-select-6-input") {

        PrtNme && handleEmployee({ value: code });
      } else {
        alert("Invalid Input");
        return false;
      }
    } else
      if (code.includes("RMI")) {
      } else if (code.includes("CUS")) {
        alert("Invalid Input");
        return false;
      } else if (code.includes("FG")) {
        alert("Invalid Input");
        return false;
      } else if (code.includes("IMM")) {
        // handleMachine({ value: code })
        GetSelectValueMachine(code, "Get_machine_from_smp", setmacno);
      } else if (code.includes("SUP")) {
        alert("Invalid Input");
        return false;
      } else {
        // empNo && GetSelectValue1(code, "mac_part_dtl_dropdown")
        empNo && handleFieldChange({value:code});
      }

  }
  useEffect(() => {
    document.title = "Machine Breakdown"
  }, []);
  function GetSelectValue(value, keyword, setName) {
    if (value.length > 0) {
      ReadRecord({
        _keyword_: keyword,
        key: "%" + value + "%",
        value: "%" + value + "%"
      }).then((res) => {
        console.log("res.data", res.data);
        setName(res.data);
        // setKey(keyvalue[options.reference]);

      });
    }
  }
  function GetSelectValue1(value, keyword) {
    ReadRecord({
      _keyword_: keyword,
      key: "%" + value + "%",
      value: "%" + value + "%"
    }).then((res) => {
      if (res.rows > 0) {
        setPrtNme({ value: res.data[0].mc_part_code, label: res.data[0].mc_part_name })
      } else {
        alert("Not Available")
      }
    });
  }
  function GetSelectValueMachine(value, keyword,setName) {
    ReadRecord({
      _keyword_: keyword,
      key: "%" + value + "%",
      value: "%" + value + "%"
    }).then((res) => {
      if (res.rows > 0) {
        // setPrtNme({ value: res.data[0].mc_part_code, label: res.data[0].mc_part_name })
        setName(res.data);
        setSelectedmac({
          value: res.data[0].mh_mac_name,
          label: res.data[0].mh_mac_name,
        });
        setempNo(res.data[0].mh_mac_name);
        GetPartDetails(res.data[0].mh_mac_name);
      } else {
        alert("Not Available")
      }
    });
  }

  return (
    <div>
      <form id="Machbd-forempontainer-Create" className="form-Machbd-Create">
        <div ref={scrollTop}>
          <table>
            <thead>
              <th style={{
                textAlign: "center",
                whiteSpace: "nowrap",
                padding: "10px 0px",
              }} colSpan="8">
                <font color="#8B0000"> MACHINE BREAK DOWN RECTIFICATION REPORT</font>

              </th>

            </thead>
            <tr>
              <td className="topic-head">From :<span style={{ color: "red" }}>*</span></td>
              <td>
                {/* <DropdownFetcher keyword="emp_details" setValue={setEmployee} /> */}
                {Frm === '' && isCreating && <span className="Req">Required *</span>}
                <Select
                  id={`${id}-EmployeeName`}
                  value={selectedEmployee}
                  options={Employee.map((employee) => ({
                    value: employee.Emp_code,
                    label: employee.Emp_name,
                  }))}
                  onChange={handleEmployee}
                  onInputChange={(event) => { GetSelectValue(event, "emp_details_dropdown", setEmployee) }}
                  placeholder="Search..."
                />
              </td>
              <td colSpan={4}></td>

              <td className="topic-head" style={{ width: "11%" }}>
                Serial No:<span style={{ color: "red" }}>*</span>
              </td>
              <td>
                {SerNo === '' && isCreating && <span className="Req">Required *</span>}
                <input
                  id="mac.Cr.serNo"
                  name="mac.Cr.serNo"
                  type="text"
                  value={SerNo}
                  onChange={(e) => setSerNo(e.target.value)}

                />
              </td>
            </tr>
            <tr>
              <td className="topic-head" style={{ width: "11%" }}>
                Dept :
              </td>

              <td className="topic-head">{Dept}</td>

              <td className="topic-head">Informed To :<span style={{ color: "red" }}>*</span></td>
              <td colSpan={3}>
                {/* <DropdownFetcher keyword="emp_details" setValue={setname} /> */}
                {InfTo === '' && isCreating && <span className="Req">Required *</span>}
                <Select
                  id={`${id}-EmployeeName`}
                  value={selectedInfto}
                  options={Name.map((employee) => ({
                    value: employee.Emp_code,
                    label: employee.Emp_name,
                  }))}
                  onChange={handleInformedToEmployeeChange}
                  onInputChange={(event) => { GetSelectValue(event, "emp_details_dropdown", setname) }}
                />
              </td>

              <td className="topic-head">ERP No :<span style={{ color: "red" }}>*</span></td>
              <td>
                {ERPNo === '' && isCreating && <span className="Req">Required *</span>}
                <input
                  id="mac.Cr.erpNo"
                  name="mac.Cr.erpNo"
                  type="number"
                  value={ERPNo}
                  onChange={(e) => setERPNo(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td className="topic-head" colSpan={1}>
                Machine No :<span style={{ color: "red" }}>*</span>
              </td>
              <td>
                {/* <DropdownFetcher keyword="mac_dtl_dropdown" setValue={setmacno} /> */}
                {empNo === '' && isCreating && <span className="Req">Required *</span>}
                <Select
                  id={`${id}-EmployeeName`}
                  value={selectedmac}
                  onInputChange={(event) => { GetSelectValue(event, "Get_machine_from_smp", setmacno) }}
                  options={macno.map((i) => ({
                    value: i.mh_mac_name,
                    label: i.mh_mac_name,
                  }))}
                  onChange={handleMachine}
                />
              </td>

              <td className="topic-head" colSpan={1}>
                Part Name<span style={{ color: "red" }}>*</span>
              </td>
              <td colSpan={3}>
                {/* <DropdownFetcher keyword="mac_part_dtl_dropdown" setValue={setPartName} /> */}
                {PrtNme === '' && isCreating && <span className="Req">Required *</span>}
                {/* <Select
               value = {PrtNme} 
              onChange={handleFieldChange} 
              options={partNoOptions}
             placeholder="Select Part Name" /> */}
                <Select
                  id={`${id}-PrtName`}
                  value={PrtNme}
                  options={partname.map((i) => ({
                    value: i.mc_part_name,
                    label: i.mc_part_name,
                  }))}
                  onChange={(event) => handleFieldChange(event)}
                  // onInputChange={(event) => { GetSelectValue(event, "mac_part_dtl_dropdown", setPartName) }}
                  placeholder="Search Part No..."
                  isDisabled={empNo===""}
                />
              </td>
              <td className="topic-head" colSpan={1}>
                Shift
              </td>
              <td colSpan={1}>
                <select
                  id={`${id}-Shift`}
                  name={`${name}-Shift`}
                  value={Shft}
                  onChange={(e) => setShft(e.target.value)}
                  style={{ textAlign: "center" }}
                  disabled
                >
                  <option
                    id={`${id}-Shift-Select`}
                    name={`${name}-Shift-Select`}
                    value=""
                  >
                    -- Select --
                  </option>
                  <option id={`${id}-ShiftA`} name={`${name}-ShiftA`} value="A">
                    A12
                  </option>
                  <option id={`${id}-ShiftB`} name={`${name}-ShiftB`} value="B">
                    B12
                  </option>
                </select>
              </td>

            </tr>

            <tr>
              <td colSpan={4}>
                <span
                  className="topic-head"
                  style={{ marginRight: "10px", float: "left", width: "30" }}>
                  {" "}
                  Machine Problem :
                </span>

                <input
                  className="Machbd-radio"
                  type="radio"
                  value="machine Problem"
                  checked={ProPrb === "machine Problem"}
                  onChange={(e) => setProPrb(e.target.value)}
                  disabled={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                />
              </td>

              <td colSpan={4}>
                <span
                  className="topic-head"
                  style={{ marginRight: "10px", float: "left", width: "30" }}
                >
                  Production Problem :
                </span>
                <input
                  className="Machbd-radio"
                  type="radio"
                  value="production Problem"
                  checked={ProPrb === "production Problem"}
                  onChange={(e) => setProPrb(e.target.value)}
                  disabled={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={8}>
                <br></br>
                <span
                  className="topic-head"
                  style={{ marginRight: "95px", padding: "20px" }}
                >
                  {" "}
                  Present Status of Machine :
                </span>
                <input
                  className="Machbd-radio"
                  type="radio"
                  value="Running"
                  checked={PreStsM === "Running"}
                  onChange={(e) => setPreStsM(e.target.value)}
                  disabled={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                />
                Running
                <span style={{ marginRight: "100px", padding: "20px" }}></span>
                <input
                  className="Machbd-radio"
                  type="radio"
                  value="Stopped"
                  checked={PreStsM === "Stopped"}
                  onChange={(e) => setPreStsM(e.target.value)}
                  disabled={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                />
                Stopped
                <br></br>
                <br></br>
                <span
                  className="topic-head"
                  style={{ marginRight: "165px", padding: "20px" }}
                >
                  Problem Status :
                </span>
                <input
                  className="Machbd-radio"
                  type="radio"
                  value="New"
                  checked={PrbSts === "New"}
                  onChange={(e) => setPrbSts(e.target.value)}
                  disabled={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                />
                New
                <span style={{ marginRight: "120px", padding: "20px" }}></span>
                <input
                  className="Machbd-radio"
                  type="radio"
                  value="Repeated"
                  checked={PrbSts === "Repeated"}
                  onChange={(e) => setPrbSts(e.target.value)}
                  disabled={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                />
                Repeated
                <br></br>
                <br></br>
                <span
                  className="topic-head"
                  style={{ marginRight: "158px", padding: "10px" }}
                >
                  BREAK DOWN TYPE :
                </span>
                <input
                  className="Machbd-radio"
                  type="radio"
                  value="BreakDown"
                  checked={BrkDwnT === "BreakDown"}
                  onChange={(e) => setBrkDwnT(e.target.value)}
                  disabled={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                />
                BreakDown
                <span style={{ marginRight: "100px", padding: "10px" }}></span>
                <input
                  className="Machbd-radio"
                  type="radio"
                  value="Plant Breakdown"
                  checked={BrkDwnT === "Plant Breakdown"}
                  onChange={(e) => setBrkDwnT(e.target.value)}
                  disabled={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                />
                Plant Breakdown
                <span style={{ marginRight: "43px", padding: "10px" }}></span>
                <input
                  className="Machbd-radio"
                  type="radio"
                  value="PM"
                  checked={BrkDwnT === "PM"}
                  onChange={(e) => setBrkDwnT(e.target.value)}
                  disabled={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                />
                PM
                <br></br>
                <br></br>
                <span
                  className="topic-head"
                  style={{ marginRight: "160px", padding: "15px" }}
                >
                  Machine Category:
                </span>
                <input
                  className="Machbd-radio"
                  type="radio"
                  value="Machine"
                  checked={MacCat === "Machine"}
                  onChange={(e) => setMacCat(e.target.value)}
                  disabled={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                />
                Machine
                <span style={{ marginRight: "110px", padding: "15px" }}></span>
                <input
                  className="Machbd-radio"
                  type="radio"
                  value="MTC"
                  checked={MacCat === "MTC"}
                  onChange={(e) => setMacCat(e.target.value)}
                  disabled={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                />
                MTC
                <span style={{ marginRight: "100px", padding: "15px" }}></span>
                <input
                  className="Machbd-radio"
                  type="radio"
                  value="HRC"
                  checked={MacCat === "HRC"}
                  onChange={(e) => setMacCat(e.target.value)}
                  disabled={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                />
                HRC
                <span style={{ marginRight: "100px", padding: "15px" }}></span>
                <input
                  className="Machbd-radio"
                  type="radio"
                  value="Hopper"
                  checked={MacCat === "Hopper"}
                  onChange={(e) => setMacCat(e.target.value)}
                  disabled={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                />
                Hopper
                <span style={{ marginRight: "100px", padding: "15px" }}></span>
                <input
                  className="Machbd-radio"
                  type="radio"
                  value="Robo"
                  checked={MacCat === "Robo"}
                  onChange={(e) => setMacCat(e.target.value)}
                  disabled={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                />
                Robo
              </td>
            </tr>
            <tr>
              <td className="topic-head" colSpan={2}>
                Nature Of Problem :
              </td>
              <td colSpan={6}>
                <input
                  type="text"
                  value={NofP}
                  onChange={(e) => setNofP(e.target.value)}
                  readOnly={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                />
              </td>
            </tr>
            <tr>
              <td className="topic-head" colSpan={2}>
                Anticipated / briefing the cause before happening fault :
              </td>
              <td colSpan={6}>
                <textarea
                  rows="2"
                  cols="30"
                  value={AntiFault}
                  onChange={(e) => setAntiFault(e.target.value)}
                  readOnly={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td className="topic-head" style={{ textAlign: "center" }}>
                Informed
              </td>
              <td className="topic-head" style={{ textAlign: "center" }}>
                Received
              </td>
              <td className="topic-head" style={{ textAlign: "center" }}>
                Completed
              </td>
              <td className="topic-head" style={{ textAlign: "center" }}>
                Total Hours
              </td>
              <td
                colSpan={3}
                className="topic-head"
                style={{ textAlign: "center" }}
              >
                Status Of Rectification
              </td>
            </tr>
            <tr>
              <td className="topic-head">Date</td>
              <td>
                <div className={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === "" ? 'disabled-date-picker' : ''}>
                  <DatePicker value={D1} onChange={handleDateChange} dis={true} />
                  {D1 === '' && isCreating && <span className="Req">Required *</span>}
                </div>
              </td>
              <td>
                <div className={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === "" ? 'disabled-date-picker' : ''}>
                  <DatePicker value={D2} onChange={handleDateChange1} dis={true} />
                  {D2 === '' && isCreating && <span className="Req">Required *</span>}
                </div>
              </td>
              <td>
                <div className={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === "" ? 'disabled-date-picker' : ''}>
                  <DatePicker value={D3} onChange={handleDateChange2} dis={true} />
                  {D3 === '' && isCreating && <span className="Req">Required *</span>}
                </div>
              </td>

              <td rowSpan={3}>
                <p>
                  <label
                    style={{ whiteSpace: "nowrap" }}
                  >{THrs}</label>
                </p>
              </td>
              <td className="topic-head">Temporary</td>
              <td>
                <select value={Temp} onChange={(e) => setTemp(e.target.value)} disabled={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}>
                  <option value="">Select</option>
                  <option value="open">Open</option>
                  <option value="close">Close</option>
                </select>
              </td>
              <td rowSpan={3} colSpan={1} style={{ textAlign: "center" }}>
                <p>Open / Close</p>
              </td>
            </tr>
            <tr>
              <td className="topic-head">Time</td>

              <td className="topic-head">{T1}</td>

              <td className="topic-head">{T2}</td>
              <td className="topic-head">{T3}</td>
              <td className="topic-head">Permanent</td>
              <td>
                <select value={Permn} onChange={(e) => setPermn(e.target.value)} disabled={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}>
                  <option value="">Select</option>
                  <option value="open">Open</option>
                  <option value="close">Close</option>
                </select>
              </td>
            </tr>
            <tr>
              <td className="topic-head">Signature</td>
              <td>
                <label>{selecteds1}</label>
                {/* <DropdownFetcher keyword="emp_details" setValue={setname} /> */}

                {/* <Select
                  id={`${id}-EmployeeName`}
                  value={selecteds1}
                  options={Name.map((employee) => ({
                    value: employee.Emp_code,
                    label: employee.Emp_name,
                  }))}
                  onChange={handleS1}
                  isDisabled={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                /> */}
              </td>
              <td>
                {/* <DropdownFetcher keyword="emp_details" setValue={setname} /> */}
                <Select
                  id={`${id}-EmployeeName`}
                  value={selecteds2}
                  options={Name.map((employee) => ({
                    value: employee.Emp_code,
                    label: employee.Emp_name,
                  }))}
                  onChange={handleS2}
                  onInputChange={(event) => { GetSelectValue(event, "emp_details_dropdown", setname) }}
                  // isDisabled={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                  isDisabled={true}
                />
              </td>
              <td>
                {/* <DropdownFetcher keyword="emp_details" setValue={setname} /> */}
                <Select
                  id={`${id}-EmployeeName`}
                  value={selecteds3}
                  options={Name.map((employee) => ({
                    value: employee.Emp_code,
                    label: employee.Emp_name,
                  }))}
                  onChange={handleS3}
                  onInputChange={(event) => { GetSelectValue(event, "emp_details_dropdown", setname) }}
                  // isDisabled={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                  isDisabled={true}
                />
              </td>
              <td className="topic-head"> Under Observation </td>
              <td>
                <select
                  value={UnOb}
                  onChange={(e) => setUnOb(e.target.value)}
                  disabled={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                >
                  <option value="">Select</option>
                  <option value="open">Open</option>
                  <option value="close">Close</option>
                </select>
              </td>
            </tr>
            <tr>
              <td className="topic-head" colSpan={1}>
                Corrective Action :
              </td>
              <td colSpan={7}>
                <textarea
                  rows="2"
                  cols="30"
                  value={CorrAct}
                  onChange={(e) => setCorrAct(e.target.value)}
                  readOnly={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                />
              </td>
            </tr>
            <tr>
              <td className="topic-head">Spare Replaced :</td>
              <td>
                <input
                  type="text"
                  value={SprRep}
                  onChange={(e) => setSprRep(e.target.value)}
                  readOnly={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                />
              </td>
              <td className="topic-head">Item No :</td>
              <td>
                <input
                  type="text"
                  value={ItmNo}
                  onChange={(e) => setItmNo(e.target.value)}
                  readOnly={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                />
              </td>
              <td className="topic-head">Size :</td>
              <td>
                <input
                  type="text"
                  value={Size}
                  onChange={(e) => setSize(e.target.value)}
                  readOnly={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                />
              </td>
              <td className="topic-head">Cost :</td>
              <td>
                <input
                  type="text"
                  value={Cost}
                  onChange={(e) => setCost(e.target.value)}
                  readOnly={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                />
              </td>
            </tr>
            <tr>
              <td className="topic-head">In-Charge Sign :</td>
              <td colSpan={2}>
                {/* <DropdownFetcher keyword="emp_details" setValue={setname} /> */}
                <Select
                  id={`${id}-EmployeeName`}
                  value={selectedinchrg}
                  options={Name.map((employee) => ({
                    value: employee.Emp_code,
                    label: employee.Emp_name,
                  }))}
                  onChange={handleIncharge}
                  onInputChange={(event) => { GetSelectValue(event, "emp_details_dropdown", setname) }}
                  isDisabled={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                />
              </td>
              <td className="topic-head">M/C Technician Sign</td>
              <td colSpan={2}>
                {/* <DropdownFetcher keyword="emp_details" setValue={setname} /> */}
                <Select
                  id={`${id}-EmployeeName`}
                  value={selectedtechS}
                  options={Name.map((employee) => ({
                    value: employee.Emp_code,
                    label: employee.Emp_name,
                  }))}
                  onChange={handleTechS}
                  onInputChange={(event) => { GetSelectValue(event, "emp_details_dropdown", setname) }}
                  isDisabled={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="14" style={{ border: "none" }}>
                <button
                  type="submit"
                  id="machbd.cr.submit"
                  name="machbd.cr.submit"
                  onClick={(e) => handleCreate(e)}
                  disabled={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}
                  hidden={SerNo === "" || ERPNo === "" || Frm === "" || InfTo === "" || empNo === "" || PrtNme === ""}>
                  Submit
                </button>
              </td>
            </tr>

          </table>
        </div>
      </form>
    </div>
  );
}

export default MacBDCreate;
