import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Lable from '../../../../Components/Fields/Lable';
import { useState } from 'react';
import Direction from './Direction';

function SPPTabs({ Records, setRecords }) {
    const [activeKey, setActiveKey] = useState("first");
    return (
        <Tab.Container id="left-tabs-example" activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
            <div className="gridOverView">
                <div className='gridSize1'>
                    <Lable value={"List Of Tabs"} type={"Head"} style={{ padding: "1px" }} />
                    <Nav onKeyDown={false} variant="pills" className="flex-column">
                        <Nav.Item>
                            <Nav.Link eventKey="first">Direction ( 0 Degree)</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second">Direction ( 90 Degree)</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="three">Direction ( 180 Degree)</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="four">Direction ( 270 Degree)</Nav.Link>
                        </Nav.Item>                        
                    </Nav>
                </div>
                <Tab.Content className="gridSize2">
                     <Tab.Pane eventKey="first"><Direction Records={Records} setRecords={setRecords} setActiveKey={setActiveKey} activeKey={activeKey} /></Tab.Pane>
                     <Tab.Pane eventKey="second"><Direction Records={Records} setRecords={setRecords} setActiveKey={setActiveKey} activeKey={activeKey} /></Tab.Pane>
                     <Tab.Pane eventKey="three"><Direction Records={Records} setRecords={setRecords} setActiveKey={setActiveKey} activeKey={activeKey} /></Tab.Pane>
                     <Tab.Pane eventKey="four"><Direction Records={Records} setRecords={setRecords} setActiveKey={setActiveKey} activeKey={activeKey} /></Tab.Pane>                    
                </Tab.Content>
            </div>
        </Tab.Container>
    );
}

export default SPPTabs;