import loadingGif from './loading.gif';
import React from 'react';
import './ErrorPage.css';

const LoadingPage = ({size}) => (
  <div className="gif-placeholder">
    <img src={loadingGif} width={size?size:"500px"} height={size?size:"500px"} alt={"Loading..."} />
  </div>
);

export default LoadingPage;
