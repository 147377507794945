import React,{useState} from "react";
import { Tab, Tabs } from "react-bootstrap";
import ProcessValidationCreate from "./ProcessValidationCreate";
import ProcessValidationView from "./ProcessValidationView";
function MaterialLoadingTabs() {
  const [viewTabClicked, setViewTabClicked] = useState(false);
  

  const handleTabClick = (tabKey) => {
    if (tabKey === "tab2") {
      setViewTabClicked(true);
    } else {
      setViewTabClicked(false);
    }
  };
  
  return (
    <div>
      <div>
        <Tabs
          defaultActiveKey="tab1"
          // transition={false}
          id="noanim-tab-example"
          className="mb-3"
          onSelect={handleTabClick}
        >
          <Tab name="mat.load.CreateTab" eventKey="tab1" title="Create">
            <div>
              <div className="set-layout">
                <ProcessValidationCreate />
              </div>
            </div>
          </Tab>
          <Tab name="mat.load.ViewTab" eventKey="tab2" title="View">
            <div>
              <div className="set-layout">
              {viewTabClicked && <ProcessValidationView />}
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default MaterialLoadingTabs;
