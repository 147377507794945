import React, { useEffect, useState } from "react";
import {
  PROCESS_TRANSACTION_URL,
  CODEGEN_URL,
  SECRET_KEY,
} from "../../../Utils/constants";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";

import "../../../Styles/Part3.css";
import IsDuplicate from "../../../Lib/IsDuplicate.js";
import CreateRecord from "../../../Lib/API/CreateRecord.js";

const initialState = {
  Id: "",
  SelectedOption: "",
  CusCde: "",
  CusNme: "",
  Flexy1: "",
  Flexy2: "",
  Flexy3: "",
  Flexy4: "",
  cus_mas: [],
};

function CustomerMasterCreate({ tab1 }) {
  const [isCreating, setIsCreating] = useState(false);
  const [customerMasterState, setCustomerMasterState] = useState(initialState);
  useEffect(() => {
    setCustomerMasterState(initialState);
  }, [tab1]);



  const handleCreate = async (e) => {
    e.preventDefault();
    const trimmedCode = customerMasterState.CusCde.trim();
    const trimmedName = customerMasterState.CusNme.trim();
    if (!customerMasterState.CusCde || !customerMasterState.CusNme) {
      setIsCreating(true);
      alert(GeneralConstants.MANDATORY_ALERT);
      return;
    }
    try {
      const isDuplicateCode = await IsDuplicate("customer_details", [
        `cus_code="${trimmedCode}"`,
        `active_status = 'Active'`,
      ]);

      if (isDuplicateCode) {
        alert(`Customer code: ${trimmedCode} already exists.`);
        return;
      }
      const isDuplicateName = await IsDuplicate("customer_details", [
        `cus_name="${trimmedName}"`,
        `active_status = 'Active'`,
      ]);

      if (isDuplicateName) {
        alert(`Customer name: ${trimmedName} already exists.`);
        return;
      }
      const data = {
        data: [
          {
            Cus_code: trimmedCode,
            Cus_name: trimmedName,
            Cus_flex_01: customerMasterState.Flexy1,
            Cus_flex_02: customerMasterState.Flexy2,
            Cus_flex_03: customerMasterState.Flexy3,
            cus_flex_04: customerMasterState.Flexy4,
          },
        ],
        // _keyword_: "",
        // secretkey: SECRET_KEY,
      };

      CreateRecord("customer_details", data.data).then((res) => {
        if (res.feedback[0].success === true) {
          alert(`Customer ${GeneralConstants.SUBMISSION_SUCCESSFUL}`);
          setCustomerMasterState({
            ...customerMasterState,
            ...initialState,
          });
          setIsCreating(false);
        }
      });
    } catch (error) {
      console.log(error);
      alert(
        `${customerMasterState.CusCde} ${GeneralConstants.SUBMISSION_FAILED}`
      );
      window.location.reload();
    }
  };

  return (
    <div>
      <form
        id="customerMasterCreate.formContainer"
        name="customerMasterCreate.formContainer"
        className="form-customerMAster-Create"
      >
        <table style={{ textAlign: "center", width: "100%" }} cellPadding="5">
          <tbody>
            <tr>
              <th
                id="cd.Title"
                name="cd.Title"
                colSpan="4"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">Customer Master</font>
              </th>
            </tr>
            <tr>
              <th>
                &nbsp;&nbsp;Customer Code{" "}
                <span style={{ color: "red" }}>*</span>
              </th>
              <td>
                {customerMasterState.CusCde === "" && isCreating && (
                  <span className="Req">Required *</span>
                )}
                <input
                  id="cd.CustomerCode"
                  name="cd.CustomerCode"
                  type="text"
                  value={customerMasterState.CusCde}
                  onChange={(e) =>
                    setCustomerMasterState({
                      ...customerMasterState,
                      CusCde: e.target.value,
                    })
                  }
                  required
                />
              </td>
              <th>
                &nbsp;&nbsp;Customer Name<span style={{ color: "red" }}>*</span>
              </th>
              <td>
                {customerMasterState.CusNme === "" && isCreating && (
                  <span className="Req">Required *</span>
                )}
                <input
                  id="cd.CustomerName"
                  name="cd.CustomerName"
                  type="text"
                  value={customerMasterState.CusNme}
                  onChange={(e) =>
                    setCustomerMasterState({
                      ...customerMasterState,
                      CusNme: e.target.value,
                    })
                  }
                  required
                />
              </td>
            </tr>
            <tr>
              <th>&nbsp;&nbsp;Flex 1</th>
              <td>
                <input
                  id="cd.Flexy1"
                  name="cd.Flexy1"
                  type="text"
                  value={customerMasterState.Flexy1}
                  onChange={(e) =>
                    setCustomerMasterState({
                      ...customerMasterState,
                      Flexy1: e.target.value,
                    })
                  }
                />
              </td>

              <th>&nbsp;&nbsp;Flex 2</th>
              <td>
                <input
                  id="cd.Flexy2"
                  name="cd.Flexy2"
                  type="text"
                  value={customerMasterState.Flexy2}
                  onChange={(e) =>
                    setCustomerMasterState({
                      ...customerMasterState,
                      Flexy2: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <th>&nbsp;&nbsp;Flex 3</th>
              <td>
                <input
                  id="cd.Flexy3"
                  name="cd.Flexy3"
                  type="text"
                  value={customerMasterState.Flexy3}
                  onChange={(e) =>
                    setCustomerMasterState({
                      ...customerMasterState,
                      Flexy3: e.target.value,
                    })
                  }
                />
              </td>

              <th>&nbsp;&nbsp;Flex 4</th>
              <td>
                <input
                  id="cd.Flexy4"
                  name="cd.Flexy4"
                  type="text"
                  value={customerMasterState.Flexy4}
                  onChange={(e) =>
                    setCustomerMasterState({
                      ...customerMasterState,
                      Flexy4: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td colSpan="4" style={{ border: "none" }}>
                <button
                  type="submit"
                  id="cd.submit"
                  name="cd.submit"
                  onClick={(e) => handleCreate(e)}
                >
                  Submit
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
}

export default CustomerMasterCreate;
