import React, { useEffect, useState, useRef } from "react";
import Field from "../../../Components/Fields/Field";
import Lable from "../../../Components/Fields/Lable";
import { useNavigate, useParams } from "react-router-dom";
import "./OnlineInspectionForm.css";
import CreateRecord from "../../../Lib/API/CreateRecord";
import ReadRecord from "../../../Lib/API/ReadRecord";
import UpdateRecord from "../../../Lib/API/UpdateRecord";
import { setMessage } from "../../../Lib/SetMessage";
import CreateApproval from "../../../Lib/CreateApproval";
import QaLayout from "../QaLayout";
import Popup from "../../../Components/Popup/Popup";
import { Dropdown } from "react-bootstrap";
import html2canvas from "html2canvas";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import html2pdf from "html2pdf.js";
import Logo from "../../../icons/Logo1.png";
import { useScrollEffect } from "../useScrollEffect";
import LoadingPage from "../../../Components/Handler/LoadingPage";
import // CURRENT_Date,
// SHIFTMOLDINGQUALITY,
"../../../Utils/GeneralConstants";

const OnlineInspectionForm = () => {
  const CURRENT_Date = new Date(
    new Date().getTime() - new Date().getTimezoneOffset() * 60000
  )
    .toISOString()
    .split("T")[0];
  const SHIFTMOLDINGQUALITY = determineShiftMoldingQuality();
  function determineShiftMoldingQuality() {
    const datetime = new Date(
      new Date().getTime() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[1]
      .substring(0, 5);
    if (datetime < "19:59" && datetime > "07:59") {
      return "A";
    } else {
      return "B";
    }
  }
  useEffect(() => {
    console.log("SHIFTMOLDINGQUALITY", SHIFTMOLDINGQUALITY);
    console.log("CURRENT_TIME", CURRENT_TIME);
  console.log("Hours", Hours);
  }, [SHIFTMOLDINGQUALITY]);
  const contentRef = useRef(null);
  const CURRENT_TIME = new Date(
    new Date().getTime() - new Date().getTimezoneOffset() * 60000
  )
    .toISOString()
    .split("T")[1]
    .substring(0, 5);
  const InitialVisual1 = {
    Hours: SHIFTMOLDINGQUALITY === "A" ? "08 AM - 11 AM" : "08 PM - 11 PM",
    Free_from_flash: "",
    Free_from_short_mould: "",
    Free_from_shrinkage: "",
    Free_from_no_color_variation: "",
    Free_from_weld_line: "",
    Calender_pin_to_be_verified: "",
    Free_from_breakage: "",
    Free_from_alaram: "",
  };
  const InitialVisual2 = {
    Hours: SHIFTMOLDINGQUALITY === "A" ? "11 AM - 02 PM" : "11 PM - 02 AM",
    Free_from_flash: "",
    Free_from_short_mould: "",
    Free_from_shrinkage: "",
    Free_from_no_color_variation: "",
    Free_from_weld_line: "",
    Calender_pin_to_be_verified: "",
    Free_from_breakage: "",
    Free_from_alaram: "",
  };
  const InitialVisual3 = {
    Hours: SHIFTMOLDINGQUALITY === "A" ? "02 PM - 05 PM" : "02 AM - 05 AM",
    Free_from_flash: "",
    Free_from_short_mould: "",
    Free_from_shrinkage: "",
    Free_from_no_color_variation: "",
    Free_from_weld_line: "",
    Calender_pin_to_be_verified: "",
    Free_from_breakage: "",
    Free_from_alaram: "",
  };
  const InitialVisual4 = {
    Hours: SHIFTMOLDINGQUALITY === "A" ? "05 PM - 08 PM" : "05 AM - 08 AM",
    Free_from_flash: "",
    Free_from_short_mould: "",
    Free_from_shrinkage: "",
    Free_from_no_color_variation: "",
    Free_from_weld_line: "",
    Calender_pin_to_be_verified: "",
    Free_from_breakage: "",
    Free_from_alaram: "",
  };
  // const InitialVisual5 = { Hours: "12-1", Free_from_flash: "", Free_from_short_mould: "", Free_from_shrinkage: "", Free_from_no_color_variation: "", Free_from_weld_line: "", Calender_pin_to_be_verified: "", Free_from_breakage: "", Free_from_alaram: "" }
  // const InitialVisual6 = { Hours: "1-2", Free_from_flash: "", Free_from_short_mould: "", Free_from_shrinkage: "", Free_from_no_color_variation: "", Free_from_weld_line: "", Calender_pin_to_be_verified: "", Free_from_breakage: "", Free_from_alaram: "" }
  // const InitialVisual7 = { Hours: "2-3", Free_from_flash: "", Free_from_short_mould: "", Free_from_shrinkage: "", Free_from_no_color_variation: "", Free_from_weld_line: "", Calender_pin_to_be_verified: "", Free_from_breakage: "", Free_from_alaram: "" }
  // const InitialVisual8 = { Hours: "3-4", Free_from_flash: "", Free_from_short_mould: "", Free_from_shrinkage: "", Free_from_no_color_variation: "", Free_from_weld_line: "", Calender_pin_to_be_verified: "", Free_from_breakage: "", Free_from_alaram: "" }
  // const InitialVisual9 = { Hours: "4-5", Free_from_flash: "", Free_from_short_mould: "", Free_from_shrinkage: "", Free_from_no_color_variation: "", Free_from_weld_line: "", Calender_pin_to_be_verified: "", Free_from_breakage: "", Free_from_alaram: "" }
  // const InitialVisual10 = { Hours: "5-6", Free_from_flash: "", Free_from_short_mould: "", Free_from_shrinkage: "", Free_from_no_color_variation: "", Free_from_weld_line: "", Calender_pin_to_be_verified: "", Free_from_breakage: "", Free_from_alaram: "" }
  // const InitialVisual11 = { Hours: "6-7", Free_from_flash: "", Free_from_short_mould: "", Free_from_shrinkage: "", Free_from_no_color_variation: "", Free_from_weld_line: "", Calender_pin_to_be_verified: "", Free_from_breakage: "", Free_from_alaram: "" }
  // const InitialVisual12 = { Hours: "7-8", Free_from_flash: "", Free_from_short_mould: "", Free_from_shrinkage: "", Free_from_no_color_variation: "", Free_from_weld_line: "", Calender_pin_to_be_verified: "", Free_from_breakage: "", Free_from_alaram: "" }
  const InitialCus = {
    Customer_descripton: "",
    Visual_inspection_1: "",
    Visual_inspection_2: "",
    Visual_inspection_3: "",
    Visual_inspection_4: "",
  };
  // const InitialCus = { Customer_descripton: "", Visual_inspection_1: "", Visual_inspection_2: "", Visual_inspection_3: "", Visual_inspection_4: "", Visual_inspection_5: "", Visual_inspection_6: "", Visual_inspection_7: "", Visual_inspection_8: "", Visual_inspection_9: "", Visual_inspection_10: "", Visual_inspection_11: "", Visual_inspection_12: "" }
  const IniDimenFun = {
    Dimension_1: "",
    Dimension_2: "",
    Dimension_3: "",
    Dimension_4: "",
    Dimension_5: "",
    Dimension_6: "",
    Dimension_7: "",
    Dimension_8: "",
    Dimension_9: "",
    Dimension_10: "",
    Dimension_11: "",
    Dimension_12: "",
    Dimension_13: "",
    Dimension_14: "",
    Dimension_15: "",
    Dimension_16: "",
  };
  const InitRoot = [
    {
      download: false,
      Prodcutionincharge: [{}],
      Machineno: [{}],
      Rawmaterialname: [],
      Partname: [{}],
      PartNumber: [{}],
      Onlineqasign: [{}],
      Qasign: [{}],
      Prodcutionoperatorname: [{}],
      Part_name: "",
      Part_number: "",
      Raw_material_name: "",
      Machine_no: "",
      complaints: ["1", "2", "3"],
      dimensions_functional: [
        IniDimenFun,
        IniDimenFun,
        IniDimenFun,
        IniDimenFun,
        IniDimenFun,
        IniDimenFun,
        IniDimenFun,
        IniDimenFun,
        IniDimenFun,
      ],
      visual_inspection: [
        InitialVisual1,
        InitialVisual2,
        InitialVisual3,
        InitialVisual4,
      ],
      // visual_inspection: [InitialVisual1, InitialVisual2, InitialVisual3, InitialVisual4, InitialVisual5, InitialVisual6, InitialVisual7, InitialVisual8, InitialVisual9, InitialVisual10, InitialVisual11, InitialVisual12],
      customer_complaints: [InitialCus, InitialCus, InitialCus],
      // dimensions: [{}, {}, {}, {}, {}],
      dimensions: [],
      cavity: [],
      Part_weight_1: "",
      Part_weight_set_minus_1: "",
      Part_weight_set_plus_1: "",
      Part_weight_2: "",
      Part_weight_set_minus_2: "",
      Part_weight_set_plus_2: "",
      Part_number: "",
      No_of_cavity: "",
      Part_colour: "",
      Format_no: "UPCL/QC/R/17",
      Rev_no: "04",
      Rev_ate: "26-08-2019",
      Entry_date: CURRENT_Date,
      Time: CURRENT_TIME,
      Shift: SHIFTMOLDINGQUALITY,
      ImageRemarks: "",
      Remarks: "",
      Two_k_part: "0",
    },
  ];
  const tableRef = useRef(null);
  const scrollTop = useRef(null);
  useScrollEffect();
  const [downloadEnabled, setDownloadEnabled] = useState(true);
  const [ButtonPopup, setButtonPopup] = useState(false);
  const [RawMat, setRawMat] = useState(false);
  const [Records, setRecords] = useState(InitRoot);
  const [MouldChange, setMouldChange] = useState(false);
  const [warning, setwarning] = useState(false);
  const [result, setResult] = useState(false);
  const [DownloadDiv, setDownloadDiv] = useState(false);
  const [Raw, setRaw] = useState([]);
  const [loading, setLoading] = useState(false);
  const [load, setload] = useState(false);
  const [QualityPlanAPI, setQualityPlanAPI] = useState(false);
  const [Machine, setMachine] = useState([]);
  const [Hours, setHours] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);

  useEffect(() => {
    console.log("Records", Records);
  }, [Records]);

  const { option, id } = useParams();
  const navigate = useNavigate();

  function HandleRecords(Event) {
    setRecords([{ ...Records[0], [Event.target.name]: Event.target.value }]);
  }
  function handleRemarks(Event) {
    const results = Event.target.value;
    if (results.length > 10) {
      console.log("result", results);
      setResult(true);
    } else {
      setResult(false);
    }
  }
  function ResetRecords() {
    let record = InitRoot;
    setRecords(record);
    setRaw([]);
    setMachine([]);
  }
  function HandleRecordsBoolean2K(Event) {
    console.log("Event.target.name", Event.target.name);
    ResetRecords();
    if (Event.target.checked == true) {
      setRecords((record) => {
        record[0][Event.target.name] = "1";
        return [...record];
      });
    } else {
      setRecords((record) => {
        record[0][Event.target.name] = "0";
        return [...record];
      });
    }
    // setRecords((record)=>{

    //   record[0][Event.target.name]= Event.target.checked == true ? "1" : "0";
    //   return [...record];
    // })
  }
  function HandleRecordSelectRaw1(Event, column, val, lab) {
    setRecords(() => {
      let record = [...Records];
      console.log("record122", record);
      record[0][Event.name] = Event.values.value;
      if (record[0][column].length === 0) {
        record[0][column].push({
          Mh_raw_mat: Event.values.value,
          Mh_raw_mat_name: Event.values.label,
        });
      } else {
        record[0][column][0]["Mh_raw_mat"] = Event.values.value;
        record[0][column][0]["Mh_raw_mat_name"] = Event.values.label;
      }

      // record[0][column][0][value] = Event.values.value;
      // record[0][column][0][label] = Event.values.label;
      return record;
    });
  }
  function HandleRecordSelectRaw2(Event, column, value, label) {
    setRecords(() => {
      let record = [...Records];
      console.log("Length", Event.values);
      if (Event.values.length <= 2) {
        record[0][Event.name] = JSON.stringify(Event.values);
        // record[0][column][0][value] = Event.values.value;
        // record[0][column][0][label] = Event.values.label;
        record[0][column] = Event.values;
      }
      return record;
    });
  }
  function HandleRecordSelect(Event, column, value, label) {
    setRecords(() => {
      let record = [...Records];
      record[0][Event.name] = Event.values.value;
      record[0][column][0][value] = Event.values.value;
      record[0][column][0][label] = Event.values.label;
      return record;
    });
  }
  const viewPagination = () => {
    console.log("");
  };

  async function HandlePartCode(Event) {
    setQualityPlanAPI(false);
    Records[0].Part_name = "";
    Records[0].No_of_cavity = "";
    Records[0].Part_colour = "";
    Records[0]["Partname"][0]["Part_name"] = "";
    (Records[0].Raw_material_name = ""),
      (Records[0]["Rawmaterialname"] = []),
      //   (Records[0]["Rawmaterialname"][0]["Mh_raw_mat"] = "");
      // Records[0]["Rawmaterialname"][0]["Mh_raw_mat_name"] = "";
      console.log("Event", Event.values.value);
    // if (Event.values.value) {
    //   // setload(true);
    // }
    // GetRawMaterial(Event.values.value);
    // if (await IsDuplicate("quality_product_plan", [`part_number="${Event.values.value}"`])) {
    ReadRecord({
      _keyword_: "Get_part_name_from_quality_plan_by_code",
      part: Event.values.value,
    }).then((res) => {
      if (res.rows === "1") {
        setRecords(() => {
          //       console.log("Event.values.value", Event.values.value);
          let record = [...Records];
          (record[0].Part_number = Event.values.value),
            (record[0]["PartNumber"][0]["Part_number"] = Event.values.value);
          record[0]["PartNumber"][0]["Part_number"] = Event.values.label;
          (record[0].Part_name = res.data[0].Part_name),
            (record[0]["Partname"][0]["Part_name"] = res.data[0].Part_name);
          record[0]["Partname"][0]["Part_name"] = res.data[0].Part_name;
          // console.log("12131213", record);
          GetModelPart(res.data[0].Part_name);
          GetMachineNo(res.data[0].Part_name);
          GetRawMaterial(res.data[0].Part_name);
          GetPwandSpec(Event.values.value, res.data[0].Part_name);
          return record;
        });
      } else if (res.rows > "1") {
        console.log("res.data", res.data);
        setRecords(() => {
          let record = [...Records];
          (record[0].Part_number = Event.values.value),
            (record[0]["PartNumber"][0]["Part_number"] = Event.values.value);
          record[0]["PartNumber"][0]["Part_number"] = Event.values.label;
          return record;
        });
      }
    });
    // } else {
    //   alert("Part Number already Has Record in Quality Plan Records")
    // }
  }
  function HandlePartname(Event) {
    setQualityPlanAPI(false);
    Records[0].No_of_cavity = "";
    Records[0].Part_colour = "";
    (Records[0].Raw_material_name = ""),
      (Records[0]["Rawmaterialname"] = []),
      (Records[0].Machine_no = ""),
      (Records[0]["Machineno"][0]["mh_mac_name"] = "");

    setRawMat(false);
    // if (Event.values.value) {
    //   setload(true);
    // }
    setRecords([
      {
        ...Records[0],
        Part_number: Event.values.value,
        [Event.name]: Event.values.value,
      },
    ]);
    GetRawMaterial(Event.values.value);
    GetModelPart(Event.values.value);
    GetMachineNo(Event.values.value);
    GetPartCodeByName(Event.values.value);
  }
  function GetPartCodeByName(val) {
    setQualityPlanAPI(false);
    ReadRecord({
      _keyword_: "Get_part_code_from_quality_plan_by_name",
      mc_part_code: val,
    }).then((res) => {
      if (res.rows == "1") {
        setRecords(() => {
          //       console.log("Event.values.value", Event.values.value);
          let record = [...Records];
          (record[0].Part_number = res.data[0].Part_number),
            (record[0]["PartNumber"][0]["Part_number"] =
              res.data[0].Part_number);
          record[0]["PartNumber"][0]["Part_number"] = res.data[0].Part_number;
          // GetPwandSpec(res.data[0].Part_number, val);
          return record;
        });
      } else if (res.rows > "1") {
      }
    });
  }
  function GetPwandSpec(code, name) {
    if (QualityPlanAPI && Records[0].Machine_no) {
      ReadRecord({
        _keyword_: "FirstOff_by_Productplan_qt",
        mc_part_code: code,
        mc_part_name: name,
      }).then((res1) => {
       
        // if (res1.data[0] === undefined) {
        //   console.error("no return");
        //   setRecords(InitRoot)
        //   alert("Part Name is Not Available in Quality Plan!")
        // } else {
        if (res1.rows == "1") {
          // setload(false);
          setRecords((prevRecords) => {
            let Records = [...prevRecords];
            const { dimensions } = res1.data[0];
            if (dimensions && dimensions.length > 0) {
              const numberOfArrays = dimensions.filter(
                (obj) =>
                  // obj.Specification_minus !== undefined &&
                  // obj.Specification_plus !== undefined &&
                  obj.Specification_val !== undefined &&
                  // obj.Specification_minus !== "" &&
                  // obj.Specification_plus !== "" &&
                  obj.Specification_val !== ""
              ).length;
              Records[0].cavity = Array.from(
                { length: numberOfArrays },
                (_, i) => i + 1
              );
              Records[0].dimensions = Array.from(
                { length: numberOfArrays },
                (_, i) => ({
                  Specification_minus:
                    res1.data[0].dimensions[i].Specification_minus,
                  Specification_plus:
                    res1.data[0].dimensions[i].Specification_plus,
                  Specification_val:
                    res1.data[0].dimensions[i].Specification_val,
                  Specifications_loc:
                    res1.data[0].dimensions[i].Specifications_loc,
                  Checking_aids: res1.data[0].dimensions[i].Checking_aids,
                  Specification_symbol_1: res1.data[0].dimensions[i]
                    .Specification_symbol_1
                    ? res1.data[0].dimensions[i].Specification_symbol_1
                    : "-",
                  Specification_symbol_2: res1.data[0].dimensions[i]
                    .Specification_symbol_2
                    ? res1.data[0].dimensions[i].Specification_symbol_2
                    : "+",
                })
              );
              Records[0] = {
                ...Records[0],
                Image:
                  res1.data[0].Image === undefined ? "" : res1.data[0].Image,
                Part_weight_1: res1.data[0].Part_weight_1,
                Part_weight_set_minus_1: res1.data[0].Part_weight_set_minus_1,
                Part_weight_set_plus_1: res1.data[0].Part_weight_set_plus_1,
                Part_weight_2: res1.data[0].Part_weight_2,
                Part_weight_set_minus_2: res1.data[0].Part_weight_set_minus_2,
                Part_weight_set_plus_2: res1.data[0].Part_weight_set_plus_2,
              };
              // console.log("Updated Cavity Array:", Records[0].dimensions);
              // console.log("Updated Records:", Records);
              return Records;
            }
            return prevRecords;
          });
        }
      });
    }
  }

  function GetModelPart(val) {
    ReadRecord({
      _keyword_: "Get_model_by_part_name",
      part: val,
    }).then((res) => {
      if (res.rows > 0) {
        // console.log("respose of raw", res.data);
        setRecords(() => {
          let record = [...Records];
          record[0].No_of_cavity = res.data[0].mc_part_flex_02;
          return record;
        });
      }
    });
  }
  function GetRawMaterial(val) {
    ReadRecord({
      _keyword_: "Get_raw_material_by_part",
      part: val,
    }).then((res) => {
      if (res.rows == "1") {
        if (Records[0].Two_k_part == "0") {
          console.log("respose of raw", res.data);
          setRecords(() => {
            //       console.log("Event.values.value", Event.values.value);
            let record = [...Records];
            (record[0].Raw_material_name = res.data[0].Mh_raw_mat),
              (record[0].Part_colour = res.data[0].Rm_Colour),
              record[0]["Rawmaterialname"].push({
                Mh_raw_mat: res.data[0].Mh_raw_mat,
                Mh_raw_mat_name: res.data[0].Mh_raw_mat_name,
              });
            setRaw(res.data);
            //   (record[0]["Rawmaterialname"][0]["Mh_raw_mat"] =
            //     res.data[0].Mh_raw_mat);
            // record[0]["Rawmaterialname"][0]["Mh_raw_mat_name"] =
            //   res.data[0].Mh_raw_mat_name;
            return record;
          });
        } else {
          setRaw(res.data);
        }
      } else if (res.rows > "1") {
        setRaw(res.data);
        // alert("Contact Administrator to add Raw Material for this Selected Part!");
      }
    });
  }
  function GetGradeColour(Event) {
    if (Records[0].Two_k_part == "0") {
      const selctedRaw = Raw.find(
        (option) => option.Mh_raw_mat === Event.values.value
      );
      let record = [...Records];
      record[0].Part_colour = selctedRaw.Rm_Colour;
      return record;
    } else {
      console.log("Event", Event);

      if (Event.values.length <= 2) {
        let colour = [];
        Event.values.map((item, index) => {
          const selctedRaw = Raw.find(
            (option) => option.Mh_raw_mat === item.value
          );
          colour.push(selctedRaw.Rm_Colour);
        });
        console.log("selctedRaw", colour);
        let record = [...Records];

        record[0].Part_colour = colour.toString();
        return record;
      }
    }
  }
  function GetMachineNo(val) {
    (Records[0].Machine_no = ""),
      (Records[0]["Machineno"][0]["mh_mac_name"] = "");
// setload(true)
    console.log("machin1", Records[0].Machineno);
    ReadRecord({
      _keyword_: "Get_machine_by_part_name",
      part: val,
    }).then((res) => {
      if (res.rows == "1") {
        console.log("machinenen", res.data, res.data.length, res.rows == "1");
        let current = new Date();
        let start = new Date(
          new Date().getTime() - new Date().getTimezoneOffset() * 60000
        );
        let end = new Date(
          new Date().getTime() - new Date().getTimezoneOffset() * 60000
        );
        if (Records[0].Shift == 'B' && current.getHours() >= 12) {
          end.setDate(end.getDate() + 1);
          start = start.toISOString().slice(0, 10) + " 20:00:02";
          end = end.toISOString().slice(0, 10) + " 08:05:00";
        } 
        else if ( Records[0].Shift== 'B' && current.getHours() < 12) {
          start.setDate(end.getDate() - 1);
          start = start.toISOString().slice(0, 10) + " 19:00:02";
          end = end.toISOString().slice(0, 10) + " 08:01:00";
        } 
        else if (Records[0].Shift == 'A' && current.getHours() > 7) {      
           start = start.toISOString().slice(0, 10) + " 08:00:01";
          end = end.toISOString().slice(0, 10) + " 21:00:00";
        } 
        else {
          start.setDate(start.getDate() - 1);
          start = start.toISOString().slice(0, 10) + " 08:00:00";
          end = end.toISOString().slice(0, 10) + " 08:00:00";
        }
      
        ReadRecord({
          _keyword_: "quality_online_inspection_by_Shift",
          shift: SHIFTMOLDINGQUALITY,
          Part_name: Records[0].Part_name,
          StartDate: start,
          EndDate: end,
          Machine: res.data[0].mh_mac_name,
        }).then((res1) => {
          console.log(res1.rows == 0, res.rows);
        
          if (res1.rows == 0) {
            console.log("quality_online_inspection_by_Shift", res1.data);

            setRecords(() => {
              let record = [...Records];
              (record[0].Machine_no = res.data[0].mh_mac_name),
                (record[0]["Machineno"][0]["mh_mac_name"] =
                  res.data[0].mh_mac_name);
              record[0]["Machineno"][0]["mh_mac_name"] =
                res.data[0].mh_mac_name;
              return record;
            });
            setQualityPlanAPI(true);
          } else if (res1.rows > 0) {
            console.log("1");
            // setload(false);
            // setRecords(res1.data);
            if (res1.data[0].Two_k_part == "1") {
              const fetchedData = res1.data[0];
              setRaw(JSON.parse(fetchedData.Raw_material_name));
              setRecords([
                {
                  ...fetchedData,
                  Rawmaterialname: JSON.parse(fetchedData.Raw_material_name),
                },
              ]);
            } else {
              setRecords(res1.data);
            }
          } else {
            setRecords(() => {
              //       console.log("Event.values.value", Event.values.value);
              let record = [...Records];
              (record[0].Machine_no = res.data[0].mh_mac_name),
                (record[0]["Machineno"][0]["mh_mac_name"] =
                  res.data[0].mh_mac_name);
              record[0]["Machineno"][0]["mh_mac_name"] =
                res.data[0].mh_mac_name;
              return record;
            });
          }
        });
      } else if (res.rows == 1) {
        setRecords(() => {
          //       console.log("Event.values.value", Event.values.value);
          let record = [...Records];
          (record[0].Machine_no = res.data[0].mh_mac_name),
            (record[0]["Machineno"][0]["mh_mac_name"] =
              res.data[0].mh_mac_name);
          record[0]["Machineno"][0]["mh_mac_name"] = res.data[0].mh_mac_name;
          return record;
        });
      } else if (res.data.length == "1") {
        setRecords(() => {
          //       console.log("Event.values.value", Event.values.value);
          let record = [...Records];
          (record[0].Machine_no = res.data[0].mh_mac_name),
            (record[0]["Machineno"][0]["mh_mac_name"] =
              res.data[0].mh_mac_name);
          record[0]["Machineno"][0]["mh_mac_name"] = res.data[0].mh_mac_name;
          return record;
        });
      } else if (res.rows > "1") {
        // setQualityPlanAPI(false)
        // setload(true);
        // alert("List")
        setMachine(res.data);
        return;

        // alert("Contact Administrator to add Machine for this Selected Part!");
      }
    });
  }
  function HandleMachine(event) {
    console.log("machine", event);
    // setload(true)
    let current = new Date();
    let start = new Date(
      new Date().getTime() - new Date().getTimezoneOffset() * 60000
    );
    let end = new Date(
      new Date().getTime() - new Date().getTimezoneOffset() * 60000
    );
    if (Records[0].Shift == 'B' && current.getHours() >= 12) {
      end.setDate(end.getDate() + 1);
      start = start.toISOString().slice(0, 10) + " 20:00:02";
      end = end.toISOString().slice(0, 10) + " 08:05:00";
    } 
    else if ( Records[0].Shift== 'B' && current.getHours() < 12) {
      start.setDate(end.getDate() - 1);
      start = start.toISOString().slice(0, 10) + " 19:00:02";
      end = end.toISOString().slice(0, 10) + " 08:01:00";
    } 
    else if (Records[0].Shift == 'A' && current.getHours() > 7) {      
       start = start.toISOString().slice(0, 10) + " 08:00:01";
      end = end.toISOString().slice(0, 10) + " 21:00:00";
    } 
    else {
      start.setDate(start.getDate() - 1);
      start = start.toISOString().slice(0, 10) + " 08:00:00";
      end = end.toISOString().slice(0, 10) + " 08:00:00";
    }
    ReadRecord({
      _keyword_: "quality_online_inspection_by_Shift",
      shift: SHIFTMOLDINGQUALITY,
      Part_name: Records[0].Part_name,
      StartDate: start,
      EndDate: end,
      Machine: event.values.value,
    }).then((res) => {
      console.log("check", res);
      console.log("ALERT", start,end);
      if (res.rows > 0) {
        // alert("Update")
        // setload(false);
        console.log("quality_online_inspection_by_Shift", res.data);
        if (res.data[0].Two_k_part == "1") {
          const fetchedData = res.data[0];
          setRaw(JSON.parse(fetchedData.Raw_material_name));
          setRecords([
            {
              ...fetchedData,
              Rawmaterialname: JSON.parse(fetchedData.Raw_material_name),
            },
          ]);
          setRawMat(true);
        } else {
          // alert("New767676 Record")
          setRecords(res.data);
          setRawMat(true);
        }
      } else if (res.rows == 0) {
        // alert("New Record")
        // for (let key in Records[0]) {
        //   if (key !== 'Part_number' && key !== 'Part_name' && key !== 'Partname' && key !== 'PartNumber' && key !== 'Raw_material_name' && key !== 'Machine_no' && key !== 'Machineno' && key !== 'Rawmaterialname' && key !== 'complaints' && key !== 'dimensions' && key !== 'PartNumber' && key !== 'Raw_material_name' && key !== 'Machine_no' && key !== 'Machineno' && key !== 'Rawmaterialname' && key !== 'complaints' && key !== 'dimensions_functional' && key !== 'visual_inspection' && key !== 'customer_complaints') {
        //       delete Records[0][key];
        //   }
        // }
        setQualityPlanAPI(true);

        // GetPwandSpec(Records[0].Part_number, Records[0].Part_name);
        // setRawMat(false);
      }
    });
  }

  useEffect(() => {
    if (QualityPlanAPI) {
      // setload(true)
      const keysToRetain = [
        "Two_k_part",
        "Entry_date",
        "Machine_no",
        "Part_number",
        "PartNumber",
        "Part_name",
        "Partname",
        "Part_colour",
        "No_of_cavity",
        "Machineno",
        "Rawmaterialname",
        "Raw_material_name",
        "Shift",
        "Time",
      ];
      const filteredObject = keysToRetain.reduce((obj, key) => {
        if (Records[0].hasOwnProperty(key)) {
          obj[key] = Records[0][key];
        }
        return obj;
      }, {});
      InitRoot.forEach((obj) => {
        obj.Two_k_part = filteredObject["Two_k_part"] || "";
        obj.Entry_date = filteredObject["Entry_date"] || "";
        obj.Machine_no = filteredObject["Machine_no"] || "";
        obj.Part_number = filteredObject["Part_number"] || "";
        obj.Part_name = filteredObject["Part_name"] || "";
        obj.Part_colour = filteredObject["Part_colour"] || "";
        obj.No_of_cavity = filteredObject["No_of_cavity"] || "";
        obj.PartNumber = filteredObject["PartNumber"] || "";
        obj.Partname = filteredObject["Partname"] || "";
        obj.Machineno = filteredObject["Machineno"] || "";
        obj.Rawmaterialname = filteredObject["Rawmaterialname"] || "";
        obj.Raw_material_name = filteredObject["Raw_material_name"] || "";
        obj.Shift = filteredObject["Shift"] || "";
        obj.Time = filteredObject["Time"] || "";
      });

      //  console.log("Updated InitRoot:", InitRoot);
      setRecords(InitRoot);
      // console.log("InitRoot1",filteredObject);
      GetPwandSpec(Records[0].Part_number, Records[0].Part_name);
      setRawMat(false);
    }
  }, [QualityPlanAPI, Records[0].Machine_no]);

  function Handlevisualinspection(Event, index) {
    setRecords((item) => {
      let record = [...Records];
      record[0].visual_inspection[index] = {
        ...record[0].visual_inspection[index],
        [Event.target.name]: Event.target.value,
      };
      if (Event.target.value === "mouldchange") {
        record[0] = {
          ...record[0],
          visual_inspection_row: index,
          visual_inspection_key: Event.target.name,
        };
      }
      return record;
    });
    if (Event.target.value === "mouldchange") {
      setMouldChange(true);
    }
    // else{
    //   setMouldChange(false)
    // }
  }
  function Handlecustomercomplaints(Event, index) {
    setRecords((item) => {
      let record = [...Records];
      record[0].customer_complaints[index] = {
        ...record[0].customer_complaints[index],
        [Event.target.name]: Event.target.value,
      };
      if (Event.target.value === "mouldchange") {
        record[0] = {
          ...record[0],
          customercomplaints_row: Event.target.name,
        };
      }
      return record;
    });
    if (Event.target.value === "mouldchange") {
      setMouldChange(true);
    }
    // else{
    //   setMouldChange(false)
    // }
  }
  function HandleDimension(Event, index) {
    setRecords((item) => {
      let record = [...Records];
      record[0].dimensions[index] = {
        ...record[0].dimensions[index],
        [Event.target.name]: Event.target.value,
      };
      return record;
    });
  }
  function HandleDimensionFunction(Event, index) {
    setRecords((item) => {
      let record = [...Records];
      record[0].dimensions_functional[index] = {
        ...record[0].dimensions_functional[index],
        [Event.target.name]: Event.target.value,
      };
      return record;
    });
  }
  function HandleCreate(event) {
    console.log("Records[0].Remarks", Records[0].Remarks);
    if (
      !Records[0].Part_name ||
      !Records[0].Part_number ||
      !Records[0].Raw_material_name ||
      !Records[0].Machine_no ||
      !Records[0].Prodcution_operator_name ||
      !Records[0].Prodcution_incharge ||
      !Records[0].Online_qa_sign ||
      !Records[0].Qa_sign ||
      !Records[0].No_of_cavity ||
      !Records[0].Part_colour ||
      (Records[0].Remarks.length > 10 && !Records[0].ImageRemarks) ||
      (Records[0].Two_k_part === "1" && Records[0].Rawmaterialname.length == 1)
    ) {
      alert("Please Fill the Mandatory Fields");
      setwarning(true);
    } else {
      let record = [...Records];
      record && delete record[0].complaints;
      record && delete record[0].cavity;
      record && delete record[0].Machineno;
      record && delete record[0].Onlineqasign;
      record && delete record[0].Partname;
      record && delete record[0].PartNumber;
      record && delete record[0].Prodcutionincharge;
      record && delete record[0].Prodcutionoperatorname;
      record && delete record[0].Qasign;
      record && delete record[0].visual_inspection_row;
      record && delete record[0].visual_inspection_key;
      record && delete record[0].Rawmaterialname;
      record && delete record[0].download;

      CreateRecord("quality_online_inspection", record).then((res) => {
        console.log("HandleCreate", res);
        setMessage({
          NoteFrom: localStorage.getItem("empId"),
          NoteTo: Records[0].Prodcution_operator_name,
          NoteMessage: `${localStorage.getItem("empId")}-${localStorage.getItem(
            "name"
          )} has submitted Online Inspection Form, Please do verify`,
          NoteRecordId: res.ROWID,
          TableName: "onlineinspection",
          NoteCreatedBy: localStorage.getItem("empId"),
        });
        setMessage({
          NoteFrom: localStorage.getItem("empId"),
          NoteTo: Records[0].Prodcution_incharge,
          NoteMessage: `${localStorage.getItem("empId")}-${localStorage.getItem(
            "name"
          )} has submitted Online Inspection Form, Please do verify`,
          NoteRecordId: res.ROWID,
          TableName: "onlineinspection",
          NoteCreatedBy: localStorage.getItem("empId"),
        });
        setMessage({
          NoteFrom: localStorage.getItem("empId"),
          NoteTo: Records[0].Online_qa_sign,
          NoteMessage: `${localStorage.getItem("empId")}-${localStorage.getItem(
            "name"
          )} has submitted Online Inspection Form, Please do verify`,
          NoteRecordId: res.ROWID,
          TableName: "onlineinspection",
          NoteCreatedBy: localStorage.getItem("empId"),
        });
        setMessage({
          NoteFrom: localStorage.getItem("empId"),
          NoteTo: Records[0].Qa_sign,
          NoteMessage: `${localStorage.getItem("empId")}-${localStorage.getItem(
            "name"
          )} has submitted Online Inspection Form, Please do verify`,
          NoteRecordId: res.ROWID,
          TableName: "onlineinspection",
          NoteCreatedBy: localStorage.getItem("empId"),
        });
        CreateApproval({
          RequestedBy: localStorage.getItem("empId"),
          Approver: Records[0].Qa_sign,
          Level: 1,
          Status: "Waiting for Approval",
          TableName: "onlineinspection",
          TableID: res.ROWID,
          Description:
            "The Online Inspection Form Has been Submitted By " +
            localStorage.getItem("empId") +
            "-" +
            localStorage.getItem("name"),
          CreatedBy: localStorage.getItem("empId"),
        });
      });

      alert("Submited Successfully");
      setwarning(false);
      setRecords(InitRoot);
      setMachine([]);
      setRaw([]);
      if (scrollTop.current) {
        scrollTop.current.scrollIntoView({ behavior: "smooth" });
      }
      // window.location.reload();
    }
  }
  function HandleUpdate(event) {
    let record = [...Records];

    record && delete record[0].complaints;
    record && delete record[0].cavity;
    record && delete record[0].Machineno;
    record && delete record[0].Onlineqasign;
    record && delete record[0].Partname;
    record && delete record[0].PartNumber;
    record && delete record[0].Prodcutionincharge;
    record && delete record[0].Prodcutionoperatorname;
    record && delete record[0].Qasign;
    record && delete record[0].Entry_date;
    record && delete record[0].Rawmaterialname;
    record && delete record[0].visual_inspection_row;
    record && delete record[0].visual_inspection_key;
    record && delete record[0].download;
    record.Status = "true";

    UpdateRecord("quality_online_inspection", record).then((res) => {
      console.log("HandleUpdate", res);
    });
    alert("record Update");

    setwarning(false);
    setRecords(InitRoot);
    // navigate(-1);
    window.location.reload();
  }
  function HandleUpdateRecord(event) {
    let record = [...Records];

    record && delete record[0].complaints;
    record && delete record[0].cavity;
    record && delete record[0].Machineno;
    record && delete record[0].Onlineqasign;
    record && delete record[0].Partname;
    record && delete record[0].PartNumber;
    record && delete record[0].Prodcutionincharge;
    record && delete record[0].Prodcutionoperatorname;
    record && delete record[0].Qasign;
    record && delete record[0].Entry_date;
    record && delete record[0].Rawmaterialname;
    record && delete record[0].visual_inspection_row;
    record && delete record[0].visual_inspection_key;
    record && delete record[0].download;
    record.Status = "true";

    UpdateRecord("quality_online_inspection", record).then((res) => {
      console.log("HandleUpdate", res);
    });
    alert("record Update");

    setwarning(false);
    setRecords(InitRoot);
    navigate(-1);
    // window.location.reload();
  }

  function HandleInsertUpdate(event) {
    // HandleUpdate("summa")
    let record = [...Records];

    record && delete record[0].complaints;
    record && delete record[0].cavity;
    record && delete record[0].Machineno;
    record && delete record[0].Onlineqasign;
    record && delete record[0].Partname;
    record && delete record[0].PartNumber;
    record && delete record[0].Prodcutionincharge;
    record && delete record[0].Prodcutionoperatorname;
    record && delete record[0].Qasign;
    record && delete record[0].Rawmaterialname;
    record && delete record[0].visual_inspection_row;
    record && delete record[0].visual_inspection_key;
    record && delete record[0].download;
    UpdateRecord("quality_online_inspection", record).then((res) => {
      console.log("HandleUpdate", res);
    });
    // console.log("HandleInsertUpdate",Records);

    let record2 = [...InitRoot];
    record2 && delete record2[0].complaints;
    record2 && delete record2[0].cavity;
    record2 && delete record2[0].Machineno;
    record2 && delete record2[0].Onlineqasign;
    record2 && delete record2[0].Partname;
    record2 && delete record2[0].PartNumber;
    record2 && delete record2[0].Prodcutionincharge;
    record2 && delete record2[0].Prodcutionoperatorname;
    record2 && delete record2[0].Qasign;
    record2 && delete record2[0].Rawmaterialname;
    record2 && delete record2[0].visual_inspection_row;
    record2 && delete record2[0].visual_inspection_key;
    record2 && delete record2[0].download;
    record2 && delete record2[0].No_of_cavity;

    if (Records[0].visual_inspection_row) {
      record2[0].visual_inspection[Records[0].visual_inspection_row] =
        Records[0].visual_inspection[Records[0].visual_inspection_row];
      console.log(
        "Records[0].visual_inspection",
        Records[0].visual_inspection[Records[0].visual_inspection_row]
      );
      record2 &&
        delete record2[0].visual_inspection[Records[0].visual_inspection_row]
          .ActiveStatus;
      record2 &&
        delete record2[0].visual_inspection[Records[0].visual_inspection_row]
          .CreatedBy;
      record2 &&
        delete record2[0].visual_inspection[Records[0].visual_inspection_row]
          .CreatedDate;
      record2 &&
        delete record2[0].visual_inspection[Records[0].visual_inspection_row]
          .Id;
      record2 &&
        delete record2[0].visual_inspection[Records[0].visual_inspection_row]
          .ModifiedDate;
      record2 &&
        delete record2[0].visual_inspection[Records[0].visual_inspection_row]
          .Quality_online_inspection_id;
    }
    if (Records[0].customercomplaints_row) {
      Array.from(
        { length: Records[0].customer_complaints.length },
        (_, index) => index + 1
      ).map((index) => {
        console.log(
          "customer_complaints",
          index - 1,
          Records[0].customer_complaints[index - 1][
            Records[0].customercomplaints_row
          ]
        );
        record2[0].customer_complaints[index - 1][
          Records[0].customercomplaints_row
        ] =
          Records[0].customer_complaints[index - 1][
            Records[0].customercomplaints_row
          ];
      });
    }

    record2[0].Entry_date = CURRENT_Date;
    record2[0].Time = CURRENT_TIME;
    record2[0].Shift = SHIFTMOLDINGQUALITY;
    record2[0].Part_name = Records[0].Part_name;
    record2[0].Part_number = Records[0].Part_number;

    // console.log("HandleInsertUpdate", Records, record2);

    // CreateRecord("quality_online_inspection", record2).then((res) => {
    //   // alert("Record is inserted and updated");
    //   console.log("Inserted and Updated ", res);
    // });
    // (record2[0].Prodcutionincharge = [{}]),
    //   (record2[0].Machineno = [{}]),
    //   (record2[0].Rawmaterialname = [{}]),
    //   (record2[0].Partname = [{}]),
    //   (record2[0].Onlineqasign = [{}]),
    //   (record2[0].Qasign = [{}]),
    //   (record2[0].Prodcutionoperatorname = [{}]),
    alert("Mould change has initated");
    //   setwarning(false);
    // window.location.reload();
    // setRecords(InitRoot);
    // window.location.reload();
    // navigate(-1);
  }

  useEffect(() => {
    setwarning(false);
    document.title = "Online Inspection";
    const fetchData = async () => {
      if (id === undefined) {
        setRecords([
          {
            ...Records[0],
            Entry_date: CURRENT_Date,
            Time: CURRENT_TIME,
            Shift: SHIFTMOLDINGQUALITY,
          },
        ]);
        CurrectHourSlot();
      } else {
        // setLoading(true);
        try {
          const res = await ReadRecord({
            _keyword_: "quality_online_inspection",
            Id: id,
          });
          if (res.data[0] === undefined) {
            setRecords(InitRoot);
          } else {
            if (res.data[0].Two_k_part == "1") {
              const dynamicDimensions = res.data[0].dimensions || [];
              res.data[0].cavity = Array.from(
                { length: dynamicDimensions.length },
                (_, i) => i + 1
              );
              res.data[0].complaints = ["1", "2", "3"];
              // setRecords(res.data);
              const fetchedData = res.data[0];
              setRaw(JSON.parse(fetchedData.Raw_material_name));
              setRecords([
                {
                  ...fetchedData,
                  dimensions: fetchedData.dimensions.map((dimension) => ({
                    ...dimension,
                    Specification_symbol_1:
                      dimension.Specification_symbol_1 || "-",
                    Specification_symbol_2:
                      dimension.Specification_symbol_2 || "+",
                  })),
                  Rawmaterialname: JSON.parse(fetchedData.Raw_material_name),
                },
              ]);
            } else {
              const dynamicDimensions = res.data[0].dimensions || [];
              res.data[0].cavity = Array.from(
                { length: dynamicDimensions.length },
                (_, i) => i + 1
              );
              res.data[0].complaints = ["1", "2", "3"];
              // setRecords(res.data);
              const fetchedData = res.data[0];
              setRecords([
                {
                  ...fetchedData,
                  dimensions: fetchedData.dimensions.map((dimension) => ({
                    ...dimension,
                    Specification_symbol_1:
                      dimension.Specification_symbol_1 || "-",
                    Specification_symbol_2:
                      dimension.Specification_symbol_2 || "+",
                  })),
                },
              ]);
            }

            if (res.data[0].ImageRemarks) {
              setResult(true);
            } else {
              setResult(false);
            }
          }
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  function CurrectHourSlot() {
    setHours(() => {
      let hour = [...Hours];
      (hour[0] =
        SHIFTMOLDINGQUALITY === "A"
          ? !(CURRENT_TIME > "08:00" && CURRENT_TIME < "11:00")
          : !(CURRENT_TIME > "20:00" && CURRENT_TIME < "23:00")),
        (hour[1] =
          SHIFTMOLDINGQUALITY === "A"
            ? !(CURRENT_TIME > "11:00" && CURRENT_TIME < "14:00")
            : // : !((CURRENT_TIME > "23:00" || CURRENT_TIME > "00:00" || CURRENT_TIME > "01:00") && CURRENT_TIME < "02:00")),
              !(CURRENT_TIME > "23:00" || CURRENT_TIME < "02:00")),
        (hour[2] =
          SHIFTMOLDINGQUALITY === "A"
            ? !(CURRENT_TIME > "14:00" && CURRENT_TIME < "17:00")
            : !(CURRENT_TIME > "02:00" && CURRENT_TIME < "05:00")),
        (hour[3] =
          SHIFTMOLDINGQUALITY === "A"
            ? !(CURRENT_TIME > "17:00" && CURRENT_TIME < "20:00")
            : !(CURRENT_TIME > "05:00" && CURRENT_TIME < "08:00"));
      // (hour[4] = SHIFTMOLDINGQUALITY === "A" ? !(CURRENT_TIME > "12:00" && CURRENT_TIME < "16:00") : !(CURRENT_TIME > "00:00" && CURRENT_TIME < "01:00")),
      // (hour[5] = SHIFTMOLDINGQUALITY === "A" ? !(CURRENT_TIME > "12:00" && CURRENT_TIME < "16:00") : !(CURRENT_TIME > "01:00" && CURRENT_TIME < "02:00")),
      // (hour[6] = SHIFTMOLDINGQUALITY === "A" ? !(CURRENT_TIME > "12:00" && CURRENT_TIME < "16:00") : !(CURRENT_TIME > "02:00" && CURRENT_TIME < "03:00")),
      // (hour[7] = SHIFTMOLDINGQUALITY === "A" ? !(CURRENT_TIME > "12:00" && CURRENT_TIME < "16:00") : !(CURRENT_TIME > "03:00" && CURRENT_TIME < "04:00")),
      // (hour[8] = SHIFTMOLDINGQUALITY === "A" ? !(CURRENT_TIME > "16:00" && CURRENT_TIME < "20:00") : !(CURRENT_TIME > "04:00" && CURRENT_TIME < "05:00")),
      // (hour[9] = SHIFTMOLDINGQUALITY === "A" ? !(CURRENT_TIME > "16:00" && CURRENT_TIME < "20:00") : !(CURRENT_TIME > "05:00" && CURRENT_TIME < "06:00")),
      // (hour[10] = SHIFTMOLDINGQUALITY === "A" ? !(CURRENT_TIME > "16:00" && CURRENT_TIME < "20:00") : !(CURRENT_TIME > "06:00" && CURRENT_TIME < "07:00")),
      // (hour[11] = SHIFTMOLDINGQUALITY === "A" ? !(CURRENT_TIME > "16:00" && CURRENT_TIME < "20:00") : !(CURRENT_TIME > "07:00" && CURRENT_TIME < "08:00"))
      // (hour[0] = SHIFTMOLDINGQUALITY === "A" ? !(CURRENT_TIME > "08:00" && CURRENT_TIME < "09:00") : !(CURRENT_TIME > "20:00" && CURRENT_TIME < "21:00")),
      //   (hour[1] = SHIFTMOLDINGQUALITY === "A" ? !(CURRENT_TIME > "09:00" && CURRENT_TIME < "10:00") : !(CURRENT_TIME > "21:00" && CURRENT_TIME < "22:00")),
      //   (hour[2] = SHIFTMOLDINGQUALITY === "A" ? !(CURRENT_TIME > "10:00" && CURRENT_TIME < "11:00") : !(CURRENT_TIME > "22:00" && CURRENT_TIME < "23:00")),
      //   (hour[3] = SHIFTMOLDINGQUALITY === "A" ? !(CURRENT_TIME > "11:00" && CURRENT_TIME < "12:00") : !(CURRENT_TIME > "23:00" && CURRENT_TIME < "00:00")),
      //   (hour[4] = SHIFTMOLDINGQUALITY === "A" ? !(CURRENT_TIME > "12:00" && CURRENT_TIME < "13:00") : !(CURRENT_TIME > "00:00" && CURRENT_TIME < "01:00")),
      //   (hour[5] = SHIFTMOLDINGQUALITY === "A" ? !(CURRENT_TIME > "13:00" && CURRENT_TIME < "14:00") : !(CURRENT_TIME > "01:00" && CURRENT_TIME < "02:00")),
      //   (hour[6] = SHIFTMOLDINGQUALITY === "A" ? !(CURRENT_TIME > "14:00" && CURRENT_TIME < "15:00") : !(CURRENT_TIME > "02:00" && CURRENT_TIME < "03:00")),
      //   (hour[7] = SHIFTMOLDINGQUALITY === "A" ? !(CURRENT_TIME > "15:00" && CURRENT_TIME < "16:00") : !(CURRENT_TIME > "03:00" && CURRENT_TIME < "04:00")),
      //   (hour[8] = SHIFTMOLDINGQUALITY === "A" ? !(CURRENT_TIME > "16:00" && CURRENT_TIME < "17:00") : !(CURRENT_TIME > "04:00" && CURRENT_TIME < "05:00")),
      //   (hour[9] = SHIFTMOLDINGQUALITY === "A" ? !(CURRENT_TIME > "17:00" && CURRENT_TIME < "18:00") : !(CURRENT_TIME > "05:00" && CURRENT_TIME < "06:00")),
      //   (hour[10] = SHIFTMOLDINGQUALITY === "A" ? !(CURRENT_TIME > "18:00" && CURRENT_TIME < "19:00") : !(CURRENT_TIME > "06:00" && CURRENT_TIME < "07:00")),
      //   (hour[11] = SHIFTMOLDINGQUALITY === "A" ? !(CURRENT_TIME > "19:00" && CURRENT_TIME < "20:00") : !(CURRENT_TIME > "07:00" && CURRENT_TIME < "08:00"))

      // (hour[0] = false),
      // (hour[1] = false),
      // (hour[2] = false),
      // (hour[3] = false),
      // (hour[4] = false),
      // (hour[5] = false),
      // (hour[6] = false),
      // (hour[7] = false),
      // (hour[8] = false),
      // (hour[9] = false),
      // (hour[10] = false),
      // (hour[11] = false)
      return hour;
    });
    // console.log("CURRENT_TIME", CURRENT_TIME);
  }
  const generatePDF = () => {
    const pdf = new jsPDF("landscape", "mm", "a4");
    html2canvas(contentRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 3, 3, 290, 203);
      pdf.save(`Online.pdf`);
    });
  };
  // const generatePDF = () => {
  //   document.querySelector('.myTable').style.display = 'table';
  //   const opt = {
  //     margin: 0.1,
  //     filename: 'OnlineInspection.pdf',
  //     image: { type: 'jpeg', quality: 0.98 },
  //     html2canvas: { scale: 2, height: contentRef.current.offsetHeight, width: contentRef.current.offsetWidth },
  //     jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' }
  //   };
  //   html2pdf().from(contentRef.current).set(opt).save();
  //   setTimeout(() => {
  //     document.querySelector('.myTable').style.display = 'none';
  //   }, 1000);
  // };
  const onButtonClick = () => {
    setDownloadDiv(true);
    setTimeout(() => {
      document.querySelector(".myTable").style.display = "table";
      setDownloadEnabled(false);

      const dropdown = document.querySelector(".OnlineDownload");
      if (dropdown) {
        dropdown.style.display = "none";
      }
      const pdf = new jsPDF("portrait", "mm", "a4");
      html2canvas(contentRef.current).then((canvas) => {
        if (dropdown) {
          dropdown.style.display = "block";
        }

        const imgData = canvas.toDataURL("image/png");
        pdf.addImage(imgData, "PNG", 2, 2, 200, 290);
        pdf.setTextColor(50, 50, 50);
        pdf.save(`OnlineIns.pdf`);
        setDownloadEnabled(true);
      });
      setTimeout(() => {
        document.querySelector(".myTable").style.display = "none";
      }, 1000);
    }, 0);
  };
  function processDimensions(specs) {
    const dimensionsLength = Records[0].dimensions.length;
    console.log("Length of dimensions array:", dimensionsLength);
    const loc = specs?.Specifications_loc || "";
    const val = specs?.Specification_val || "";
    const symbol1 = specs?.Specification_symbol_1 || "";
    const minus = specs?.Specification_minus || "";
    const symbol2 = specs?.Specification_symbol_2 || "";
    const plus = specs?.Specification_plus || "";
    return [`${loc} * ${val} (${symbol1} ${minus} / ${symbol2} ${plus})`];
  }
  const exportToExcel = () => {
    setDownloadDiv(true);
    setTimeout(() => {
      const wb = XLSX.utils.book_new();
      const wsData = [
        [
          "",
          "",
          "ONLINE INSPECTION",
          "",
          "",
          "",
          "FORMAT NO:" + Records[0].Format_no,
        ],
        ["", "", "", "", "", "", "REV NO:", Records[0].Rev_no],
        ["", "", "", "", "", "", "REV DATE: ", Records[0].Rev_ate],
        [
          "Part Number:",
          Records[0].Part_number,
          "Part Name:",
          Records[0].PartNumber[0].mc_part_name,
          "Date:",
          Records[0].Entry_date,
        ],
        [
          "No Of Cavity:",
          Records[0].No_of_cavity,
          "Part Colour:",
          Records[0].Part_colour,
          "Shift:",
          Records[0].Shift,
        ],
        [
          "Rawmaterial:",
          Records[0].Two_k_part == "0"
            ? Records[0]?.Rawmaterialname[0]?.Mh_raw_mat_name
            : Records[0].Rawmaterialname[0].label +
              "," +
              Records[0].Rawmaterialname[1].label,
          "Machine No:",
          Records[0].Machine_no,
          "Time:",
          Records[0].Time,
        ],
        ["", "", "VISUAL INSPECTION", "", ""],
        [
          "",
          "HOURS",
          Records[0].visual_inspection[0].Hours,
          Records[0].visual_inspection[1].Hours,
          Records[0].visual_inspection[2].Hours,
          Records[0].visual_inspection[3].Hours,
        ],
        [
          "1.",
          "FREE FROM FLASH",
          Records[0].visual_inspection[0].Free_from_flash,
          Records[0].visual_inspection[1].Free_from_flash,
          Records[0].visual_inspection[2].Free_from_flash,
          Records[0].visual_inspection[3].Free_from_flash,
        ],
        [
          "2.",
          "FREE FROM SHORT MOULD",
          Records[0].visual_inspection[0].Free_from_short_mould,
          Records[0].visual_inspection[1].Free_from_short_mould,
          Records[0].visual_inspection[2].Free_from_short_mould,
          Records[0].visual_inspection[3].Free_from_short_mould,
        ],
        [
          "3.",
          "FREE FROM SHRINKAGE",
          Records[0].visual_inspection[0].Free_from_shrinkage,
          Records[0].visual_inspection[1].Free_from_shrinkage,
          Records[0].visual_inspection[2].Free_from_shrinkage,
          Records[0].visual_inspection[3].Free_from_shrinkage,
        ],
        [
          "4.",
          "FREE FROM COLOR VARIATION",
          Records[0].visual_inspection[0].Free_from_no_color_variation,
          Records[0].visual_inspection[1].Free_from_no_color_variation,
          Records[0].visual_inspection[2].Free_from_no_color_variation,
          Records[0].visual_inspection[3].Free_from_no_color_variation,
        ],
        [
          "5.",
          "FREE FROM WELD LINE",
          Records[0].visual_inspection[0].Free_from_weld_line,
          Records[0].visual_inspection[2].Free_from_weld_line,
          Records[0].visual_inspection[2].Free_from_weld_line,
          Records[0].visual_inspection[3].Free_from_weld_line,
        ],
        [
          "6.",
          "CALENDAR PIN TO BE VERIFIED",
          Records[0].visual_inspection[0].Calender_pin_to_be_verified,
          Records[0].visual_inspection[1].Calender_pin_to_be_verified,
          Records[0].visual_inspection[2].Calender_pin_to_be_verified,
          Records[0].visual_inspection[3].Calender_pin_to_be_verified,
        ],
        [
          "7.",
          "FREE FROM BREAKAGE",
          Records[0].visual_inspection[0].Free_from_breakage,
          Records[0].visual_inspection[1].Free_from_breakage,
          Records[0].visual_inspection[2].Free_from_breakage,
          Records[0].visual_inspection[3].Free_from_breakage,
        ],
        [
          "8.",
          "FREE FROM ALARM",
          Records[0].visual_inspection[0].Free_from_alaram,
          Records[0].visual_inspection[1].Free_from_alaram,
          Records[0].visual_inspection[2].Free_from_alaram,
          Records[0].visual_inspection[3].Free_from_alaram,
        ],
        ["", "", "", "CUSTOMER", "COMPLAINTS", "HISTORY", "", ""],
        [
          "",
          "HOURS",
          Records[0].visual_inspection[0].Hours,
          Records[0].visual_inspection[1].Hours,
          Records[0].visual_inspection[2].Hours,
          Records[0].visual_inspection[3].Hours,
        ],
        [
          "1.",
          Records[0].customer_complaints[0].Customer_descripton,
          Records[0].customer_complaints[0].Visual_inspection_1,
          Records[0].customer_complaints[0].Visual_inspection_2,
          Records[0].customer_complaints[0].Visual_inspection_3,
          Records[0].customer_complaints[0].Visual_inspection_4,
        ],
        [
          "2.",
          Records[0].customer_complaints[1].Customer_descripton,
          Records[0].customer_complaints[1].Visual_inspection_1,
          Records[0].customer_complaints[1].Visual_inspection_2,
          Records[0].customer_complaints[1].Visual_inspection_3,
          Records[0].customer_complaints[1].Visual_inspection_4,
        ],
        [
          "3.",
          Records[0].customer_complaints[2].Customer_descripton,
          Records[0].customer_complaints[2].Visual_inspection_1,
          Records[0].customer_complaints[2].Visual_inspection_2,
          Records[0].customer_complaints[2].Visual_inspection_3,
          Records[0].customer_complaints[2].Visual_inspection_4,
        ],
        ["", "DIMENSIONS", ""],
        [
          "",
          "SPEC/NO OF CAVITY",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
        ],
      ];
      const dims = [];
      let rowNumber = 1;
      for (let i = 0; i < Records[0].dimensions.length; i++) {
        const specs = Records[0].dimensions[i];
        dims.push(processDimensions(specs));
      }
      for (
        let dimensionIndex = 0;
        dimensionIndex < Records[0].dimensions.length;
        dimensionIndex++
      ) {
        const dimRow = [];
        const dimension = Records[0].dimensions[dimensionIndex];
        const specs = dims[dimensionIndex];

        for (let i = 0; i <= 18; i++) {
          const dimensionKey = `Dimension_${i}`;
          if (dimension[dimensionKey] !== undefined) {
            dimRow.push(dimension[dimensionKey]);
          } else {
            dimRow.push("");
          }
        }
        const filteredSpecs = specs.filter((value) => value !== "");
        const filteredDimRow = dimRow.filter((value) => value !== "");
        wsData.push([rowNumber++, ...filteredSpecs, ...filteredDimRow]);
      }
      wsData.push(
        ["", "", " FUNCTIONAL ", ""],
        [
          "",
          "HOURS",
          Records[0].visual_inspection[0].Hours,
          Records[0].visual_inspection[1].Hours,
          Records[0].visual_inspection[2].Hours,
          Records[0].visual_inspection[3].Hours,
        ],
        [
          "1",
          "MATING/GAUGE",
          "CHECKING",
          Records[0].dimensions_functional[0].Dimension_1,
          Records[0].dimensions_functional[0].Dimension_2,
          Records[0].dimensions_functional[0].Dimension_3,
          Records[0].dimensions_functional[0].Dimension_4,
        ],

        [
          "2",
          "C1",
          "PW1" +
            "  (" +
            Records[0].Part_weight_1 +
            ") " +
            "-" +
            Records[0].Part_weight_set_minus_1 +
            "/+" +
            Records[0].Part_weight_set_plus_1,
          Records[0].dimensions_functional[1].Dimension_1,
          Records[0].dimensions_functional[1].Dimension_2,
          Records[0].dimensions_functional[1].Dimension_3,
          Records[0].dimensions_functional[1].Dimension_4,
        ],
        [
          "",
          "",
          "PW2" +
            "  (" +
            Records[0].Part_weight_2 +
            ") " +
            "-" +
            Records[0].Part_weight_set_minus_2 +
            "/+" +
            Records[0].Part_weight_set_plus_2,
          Records[0].dimensions_functional[2].Dimension_1,
          Records[0].dimensions_functional[2].Dimension_2,
          Records[0].dimensions_functional[2].Dimension_3,
          Records[0].dimensions_functional[2].Dimension_4,
        ],

        [
          "3",
          "C2",
          "PW1" +
            "  (" +
            Records[0].Part_weight_1 +
            ") " +
            "-" +
            Records[0].Part_weight_set_minus_1 +
            "/+" +
            Records[0].Part_weight_set_plus_1,
          Records[0].dimensions_functional[3].Dimension_1,
          Records[0].dimensions_functional[3].Dimension_2,
          Records[0].dimensions_functional[3].Dimension_3,
          Records[0].dimensions_functional[3].Dimension_4,
        ],
        [
          "",
          "",
          "PW2" +
            "  (" +
            Records[0].Part_weight_2 +
            ") " +
            "-" +
            Records[0].Part_weight_set_minus_2 +
            "/+" +
            Records[0].Part_weight_set_plus_2,
          Records[0].dimensions_functional[4].Dimension_1,
          Records[0].dimensions_functional[4].Dimension_2,
          Records[0].dimensions_functional[4].Dimension_3,
          Records[0].dimensions_functional[4].Dimension_4,
        ],

        [
          "4",
          "C3",
          "PW1" +
            "  (" +
            Records[0].Part_weight_1 +
            ") " +
            "-" +
            Records[0].Part_weight_set_minus_1 +
            "/+" +
            Records[0].Part_weight_set_plus_1,
          Records[0].dimensions_functional[5].Dimension_1,
          Records[0].dimensions_functional[5].Dimension_2,
          Records[0].dimensions_functional[5].Dimension_3,
          Records[0].dimensions_functional[5].Dimension_4,
        ],
        [
          "",
          "",
          "PW2" +
            "  (" +
            Records[0].Part_weight_2 +
            ") " +
            "-" +
            Records[0].Part_weight_set_minus_2 +
            "/+" +
            Records[0].Part_weight_set_plus_2,
          Records[0].dimensions_functional[6].Dimension_1,
          Records[0].dimensions_functional[6].Dimension_2,
          Records[0].dimensions_functional[6].Dimension_3,
          Records[0].dimensions_functional[6].Dimension_4,
        ],

        [
          "5",
          "C4",
          "PW1" +
            "  (" +
            Records[0].Part_weight_1 +
            ") " +
            "-" +
            Records[0].Part_weight_set_minus_1 +
            "/+" +
            Records[0].Part_weight_set_plus_1,
          Records[0].dimensions_functional[7].Dimension_1,
          Records[0].dimensions_functional[7].Dimension_2,
          Records[0].dimensions_functional[7].Dimension_3,
          Records[0].dimensions_functional[7].Dimension_4,
        ],
        [
          "",
          "",
          "PW2" +
            "  (" +
            Records[0].Part_weight_2 +
            ") " +
            "-" +
            Records[0].Part_weight_set_minus_2 +
            "/+" +
            Records[0].Part_weight_set_plus_2,
          Records[0].dimensions_functional[8].Dimension_1,
          Records[0].dimensions_functional[8].Dimension_2,
          Records[0].dimensions_functional[8].Dimension_3,
          Records[0].dimensions_functional[8].Dimension_4,
        ],
        [
          "NOT APPLICABLE:",
          "N A",
          "OK:",
          "✓",
          "NOT OK:",
          "X",
          "ACCEPTED/NOT ACCEPTED",
        ],
        ["REMARKS-ANY PROBLEM UP DATED IN THIS COLUMN:"],
        ["", Records[0].Remarks],
        [
          "PRODUCTION OPERATOR NAME:",
          "PRODUCTION INCHARGE:",
          "ON LINE QC:",
          "QC HOD:",
        ],
        [
          Records[0].Prodcutionoperatorname[0].Emp_name,
          Records[0].Prodcutionincharge[0].Emp_name,
          Records[0].Onlineqasign[0].Emp_name,
          Records[0].Qasign[0].Emp_name,
        ],
        [
          "Has the Job been set as per the Master Setting data:",
          Records[0].Master_setting_dates,
          "Store the last of Part with details:",
          Records[0].Store_the_last_of_part_with_details,
        ]
      );
      const ws = XLSX.utils.aoa_to_sheet(wsData);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "OnlineIns.xlsx");
    }, 0);
  };
  return (
    <QaLayout record={Records} quary={"quality_online_inspection"}>
      {/* {console.log("Form", Records)} */}
      <div className="excel">
        {downloadEnabled && option === "view" && (
          <Dropdown style={{ width: "100%", al: "center" }}>
            <Dropdown.Toggle style={{ color: "black" }}>
              Download ⬇️
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>
                <button
                  className="custom-button"
                  style={{ color: "black" }}
                  onClick={onButtonClick}
                >
                  PDF ⬇️
                </button>
              </Dropdown.Item>
              <Dropdown.Item>
                <button
                  className="custom-button"
                  style={{ color: "black" }}
                  onClick={() => exportToExcel()}
                >
                  Export to Excel
                </button>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
      <div className="Form" ref={scrollTop}>
        <div ref={tableRef}>
          <table>
            <tbody>
              <tr>
                <th colSpan={14}>
                  <Lable value={"ONLINE INSPECTION"} type={"Head"} />
                  <input
                    type="checkbox"
                    name="Two_k_part"
                    value={Records[0]}
                    disabled={option === "view" || option === "edit"}
                    checked={Records[0].Two_k_part == "1"}
                    onChange={(e) => {
                      HandleRecordsBoolean2K(e);
                    }}
                    style={{ width: "20px", height: "20px" }}
                  />
                  Enable 2K Part
                </th>
                {/* {Records[0] && Records[0].download && ( */}
                <td colSpan={6}>
                  <tr>
                    <td style={{ width: "25%" }}>
                      <Field
                        label={"FORMAT NO"}
                        name={"Format_no"}
                        style={{ padding: "0px" }}
                        options={{
                          type: "text",
                          // defaultValue: "UPCL/QC/R/17",
                          disabled: true,
                        }}
                        value={Records[0]}
                        OnChange={(e) => {
                          HandleRecords(e);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "25%" }}>
                      <Field
                        label={"REV NO"}
                        name={"Rev_no"}
                        style={{ padding: "0px" }}
                        options={{
                          type: "text",
                          // defaultValue: "04",
                          disabled: true,
                        }}
                        value={Records[0]}
                        OnChange={(e) => {
                          HandleRecords(e);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "25%" }}>
                      <Field
                        label={"REV DATE"}
                        name={"Rev_ate"}
                        style={{ padding: "0px" }}
                        options={{
                          type: "text",
                          // defaultValue: "26-08-2019",
                          disabled: true,
                        }}
                        value={Records[0]}
                        OnChange={(e) => {
                          HandleRecords(e);
                        }}
                      />
                    </td>
                  </tr>
                </td>
                {/* )} */}
              </tr>
              <tr>
                <td colSpan={7}>
                  {/* <Field
                  label={"Part Number"}
                  name={"Part_number"}
                  options={{ type: "text", disabled: true }}
                  value={Records[0]}
                  OnChange={(e) => {
                    HandleRecords(e);
                  }}
                /> */}
                  {/* {console.log(Records)} */}
                  <Field
                    label={"Part Number"}
                    name={"Part_number"}
                    options={{
                      type: "reference",
                      reference: "Get_part_no_from_Quality_plan",
                      required: true,
                      disabled: option === "view" || option === "edit",
                    }}
                    warning={warning}
                    value={{
                      value:
                        Records[0] &&
                        Records[0].PartNumber &&
                        Records[0].PartNumber[0]
                          ? Records[0].PartNumber[0].Part_number
                          : "",
                      label:
                        Records[0] &&
                        Records[0].PartNumber &&
                        Records[0].PartNumber[0]
                          ? Records[0].PartNumber[0].Part_number
                          : "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "PartNumber",
                        "Part_number",
                        "Part_number"
                      );
                      HandlePartCode(e);
                    }}
                  />
                </td>
                <td colSpan={7}>
                  <Field
                    label={"Part Name"}
                    name={"Part_name"}
                    options={{
                      type: "reference",
                      key: Records[0].Part_number,
                      reference: "Get_part_name_from_Quality_plan",
                      required: true,
                      disabled: option === "view" || option === "edit",
                    }}
                    warning={warning}
                    value={{
                      value:
                        Records[0] &&
                        Records[0].Partname &&
                        Records[0].Partname[0]
                          ? Records[0].Partname[0].Part_name
                          : "",
                      label:
                        Records[0] &&
                        Records[0].Partname &&
                        Records[0].Partname[0]
                          ? Records[0].Partname[0].Part_name
                          : "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "Partname",
                        "Part_name",
                        "Part_name"
                      );
                      HandlePartname(e);
                    }}
                  />
                </td>
                <td colSpan={6}>
                  <Field
                    label={"Date"}
                    name={"Entry_date"}
                    options={{ type: "date", disabled: true }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={7}>
                  <Field
                    label={"No Of Cavity"}
                    name={"No_of_cavity"}
                    options={{ type: "text", disabled: true, required: true }}
                    // options={{ type: "text", disabled: true, required: true }}
                    warning={warning}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                <td colSpan={7}>
                  <Field
                    label={"Part Colour"}
                    name={"Part_colour"}
                    options={{ type: "text", disabled: true, required: true }}
                    warning={warning}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                <td colSpan={6}>
                  <Field
                    label={"Shift"}
                    name={"Shift"}
                    options={{
                      type: "dropdown",
                      disabled: true,
                      values: [
                        { value: "A", label: "A12" },
                        { value: "B", label: "B12" },
                      ],
                    }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={7}>
                  <Field
                    label={"Raw Material Name"}
                    name={"Raw_material_name"}
                    options={{
                      type: "NoneReference",
                      reference:
                        option === "edit" && Records[0].Two_k_part == "1"
                          ? "Keyvalue"
                          : "Raw_material",
                      values: Raw,
                      required: true,
                      disabled:
                        option === "view" ||
                        option === "edit" ||
                        RawMat === true,
                    }}
                    isMulti={Records[0].Two_k_part == "1"}
                    warning={warning}
                    value={
                      Records[0].Two_k_part == "1"
                        ? Records[0].Rawmaterialname
                        : {
                            value: Records[0].Rawmaterialname[0]
                              ? Records[0].Rawmaterialname[0].Mh_raw_mat
                              : "",
                            label: Records[0].Rawmaterialname[0]
                              ? Records[0].Rawmaterialname[0].Mh_raw_mat_name
                              : "",
                          }
                    }
                    OnChange={(e) => {
                      Records[0].Two_k_part == "1"
                        ? HandleRecordSelectRaw2(
                            e,
                            "Rawmaterialname",
                            "Mh_raw_mat",
                            "Mh_raw_mat_name"
                          )
                        : HandleRecordSelectRaw1(
                            e,
                            "Rawmaterialname",
                            "Mh_raw_mat",
                            "Mh_raw_mat_name"
                          );
                      GetGradeColour(e);
                    }}
                  />
                </td>
                <td colSpan={7}>
                  <Field
                    label={"Machine No"}
                    name={"Machine_no"}
                    options={{
                      type: "NoneReference",
                      reference: "Machine_Number",
                      values: Machine,
                      required: true,
                      disabled: option === "view" || option === "edit",
                    }}
                    warning={warning}
                    value={{
                      value:
                        Records[0] &&
                        Records[0].Machineno &&
                        Records[0].Machineno[0]
                          ? Records[0].Machineno[0].mh_mac_name
                          : "",
                      label:
                        Records[0] &&
                        Records[0].Machineno &&
                        Records[0].Machineno[0]
                          ? Records[0].Machineno[0].mh_mac_name
                          : "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "Machineno",
                        "mh_mac_name",
                        "mh_mac_name"
                      );
                      HandleMachine(e);
                    }}
                  />
                </td>
                <td colSpan={6}>
                  <Field
                    label={"Time"}
                    name={"Time"}
                    options={{ type: "time", disabled: true }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={20}>
                  {option === "create" ? (
                    <Field
                      label={"Image"}
                      name={"Image"}
                      options={{ type: "image" }}
                      value={Records[0]}
                      OnChange={(e) => {
                        HandleRecords(e);
                      }}
                    />
                  ) : Records[0].Image &&
                    Records[0].Image.includes("image/") ? (
                    <img
                      width={1000}
                      height={400}
                      src={Records[0].Image}
                      alt=" "
                    />
                  ) : (
                    Records[0].Image &&
                    Records[0].Image.includes("application/pdf") && (
                      <div style={{ marginTop: "10px" }}>
                        {/* <iframe 
                    width="500px"
                      height="350px"
                      alt={name}
                      src={value[name]}></iframe> */}

                        <button id="Pdf" onClick={() => setButtonPopup(true)}>
                          View
                        </button>
                        <Popup
                          trigger={ButtonPopup}
                          setTrigger={setButtonPopup}
                          viewPagination={viewPagination}
                        >
                          <object
                            width="90%"
                            height="90%"
                            data={Records[0].Image}
                            // src={Records[0].Image}
                            type="application/pdf"
                          >
                            <p>
                              Your browser does not support viewing PDFs.
                              <a
                                href={Records[0].Image}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Click here to view the PDF.
                              </a>
                            </p>
                          </object>
                        </Popup>
                      </div>
                    )
                  )}
                </td>
              </tr>

             
                
                  <tr >
                    <th colSpan={20}>
                      <Lable value={"VISUAL INSPECTION"} type={"Head"} />
                    </th>
                  </tr>

                  <tr >
                    <th style={{ width: "21%" }} colSpan={4}>
                      <Lable value={"HOURS"} type={"Head"} />
                    </th>
                    <th style={{}} colSpan={4}>
                      <Field
                        name={"Hours"}
                        options={{
                          type: "text",
                          disabled: true || Hours[0],
                        }}
                        value={Records[0].visual_inspection[0]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 0);
                        }}
                      />
                    </th>
                    <th style={{}} colSpan={4}>
                      <Field
                        name={"Hours"}
                        options={{
                          type: "text",
                          disabled: true || Hours[1],
                        }}
                        value={Records[0].visual_inspection[1]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 1);
                        }}
                      />
                    </th>
                    <th style={{}} colSpan={4}>
                      <Field
                        name={"Hours"}
                        options={{
                          type: "text",
                          disabled: true || Hours[2],
                        }}
                        value={Records[0].visual_inspection[2]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 2);
                        }}
                      />
                    </th>
                    <th style={{}} colSpan={4}>
                      <Field
                        name={"Hours"}
                        options={{
                          type: "text",
                          disabled: true || Hours[3],
                        }}
                        value={Records[0].visual_inspection[3]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 3);
                        }}
                      />
                    </th>
                  </tr>

                  <tr >
                    <td
                      style={{ width: "3%", textAlign: "center" }}
                      colSpan={1}
                    >
                      1
                    </td>
                    <td colSpan={3}>
                      <Lable
                        style={{ width: "210px" }}
                        value={"FREE FROM FLASH"}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Free_from_flash"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[0],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[0]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 0);
                        }}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Free_from_flash"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[1],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[1]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 1);
                        }}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Free_from_flash"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[2],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[2]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 2);
                        }}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Free_from_flash"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[3],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[3]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 3);
                        }}
                      />
                    </td>
                  </tr>
                  <tr >
                    <td
                      style={{ width: "3%", textAlign: "center" }}
                      colSpan={1}
                    >
                      2
                    </td>
                    <td colSpan={3}>
                      <Lable
                        style={{ width: "210px" }}
                        value={"FREE FROM SHORT MOULD"}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Free_from_short_mould"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[0],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[0]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 0);
                        }}
                      />
                    </td>
                    <td colSpan={4} >
                      <Field
                        name={"Free_from_short_mould"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[1],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[1]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 1);
                        }}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Free_from_short_mould"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[2],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[2]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 2);
                        }}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Free_from_short_mould"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[3],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[3]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 3);
                        }}
                      />
                    </td>
                  </tr>
                  <tr >
                    <td
                      style={{ width: "3%", textAlign: "center" }}
                      colSpan={1}
                    >
                      3
                    </td>
                    <td colSpan={3}>
                      <Lable
                        style={{ width: "210px" }}
                        value={"FREE FROM SHRINKAGE"}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Free_from_shrinkage"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[0],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[0]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 0);
                        }}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Free_from_shrinkage"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[1],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[1]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 1);
                        }}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Free_from_shrinkage"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[2],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[2]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 2);
                        }}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Free_from_shrinkage"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[3],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[3]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 3);
                        }}
                      />
                    </td>
                  </tr>

                  <tr >
                    <td
                      style={{ width: "3%", textAlign: "center" }}
                      colSpan={1}
                    >
                      4
                    </td>
                    <td colSpan={3}>
                      <Lable
                        style={{ width: "210px" }}
                        value={"FREE FROM COLOR VARIATION"}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Free_from_no_color_variation"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[0],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[0]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 0);
                        }}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Free_from_no_color_variation"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[1],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[1]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 1);
                        }}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Free_from_no_color_variation"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[2],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[2]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 2);
                        }}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Free_from_no_color_variation"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[3],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[3]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 3);
                        }}
                      />
                    </td>
                  </tr>

                  <tr > 
                    <td
                      style={{ width: "3%", textAlign: "center" }}
                      colSpan={1}
                    >
                      5
                    </td>
                    <td colSpan={3}>
                      <Lable
                        style={{ width: "210px" }}
                        value={"FREE FROM WELD LINE"}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Free_from_weld_line"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[0],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[0]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 0);
                        }}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Free_from_weld_line"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[1],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[1]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 1);
                        }}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Free_from_weld_line"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[2],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[2]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 2);
                        }}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Free_from_weld_line"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[3],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[3]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 3);
                        }}
                      />
                    </td>
                  </tr>
                  <tr >
                    <td
                      style={{ width: "3%", textAlign: "center" }}
                      colSpan={1}
                    >
                      6
                    </td>
                    <td colSpan={3}>
                      <Lable
                        style={{ width: "210px" }}
                        value={"CALENDAR PIN TO BE VERIFIED"}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Calender_pin_to_be_verified"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[0],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[0]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 0);
                        }}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Calender_pin_to_be_verified"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[1],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[1]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 1);
                        }}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Calender_pin_to_be_verified"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[2],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[2]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 2);
                        }}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Calender_pin_to_be_verified"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[3],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[3]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 3);
                        }}
                      />
                    </td>
                  </tr>
                  <tr >
                    <td
                      style={{ width: "3%", textAlign: "center" }}
                      colSpan={1}
                    >
                      7
                    </td>
                    <td colSpan={3}>
                      <Lable
                        style={{ width: "210px" }}
                        value={"FREE FROM BREAKAGE"}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Free_from_breakage"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[0],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[0]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 0);
                        }}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Free_from_breakage"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[1],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[1]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 1);
                        }}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Free_from_breakage"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[2],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[2]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 2);
                        }}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Free_from_breakage"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[3],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[3]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 3);
                        }}
                      />
                    </td>
                  </tr>
                  <tr >
                    <td
                      style={{ width: "3%", textAlign: "center" }}
                      colSpan={1}
                    >
                      8
                    </td>
                    <td colSpan={3}>
                      <Lable
                        style={{ width: "210px" }}
                        value={"FREE FROM ALARM"}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Free_from_alaram"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[0],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[0]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 0);
                        }}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Free_from_alaram"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[1],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[1]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 1);
                        }}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Free_from_alaram"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[2],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[2]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 2);
                        }}
                      />
                    </td>
                    <td colSpan={4}>
                      <Field
                        name={"Free_from_alaram"}
                        options={{
                          type: "dropdown",
                          disabled:
                            option === "edit"
                              ? false
                              : option === "view" || Hours[3],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                            { value: "mouldchange", label: "Mould Change" },
                            {
                              value: "machinebreakdown",
                              label: "Machine Breakdown",
                            },
                            {
                              value: "mouldbreakdown",
                              label: "Mould Breakdown",
                            },
                            { value: "ideal", label: "Ideal" },
                          ],
                        }}
                        value={Records[0].visual_inspection[3]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 3);
                        }}
                      />
                    </td>
                  </tr>

                  <tr >
                    <th style={{ width: "21%" }} colSpan={28}>
                      <Lable
                        value={"CUSTOMER COMPLAINTS HISTORY"}
                        type={"Head"}
                      />
                    </th>
                  </tr>

                  {Array.from(
                    { length: Records[0].customer_complaints.length },
                    (_, index) => index + 1
                  ).map((item) => (
                    <tr >
                      <td
                        colSpan={1}
                        style={{ textAlign: "center", width: "3%" }}
                      >
                        {item}
                      </td>
                      <td colSpan={3}>
                        <Field
                          style={{ width: "210px" }}
                          name={"Customer_descripton"}
                          options={{
                            type: "text",
                            disabled: option === "view",
                          }}
                          value={
                            Records[0].customer_complaints[Number(item) - 1]
                          }
                          OnChange={(e) => {
                            Handlecustomercomplaints(e, Number(item) - 1);
                          }}
                        />
                      </td>
                      <td colSpan={4}>
                        <Field
                          name={"Visual_inspection_1"}
                          options={{
                            type: "dropdown",
                            disabled:
                              option === "edit"
                                ? false
                                : option === "view" || Hours[0],
                            values: [
                              { value: "NA", label: "NA" },
                              { value: "Ok", label: "✓" },
                              { value: "NotOk", label: "X" },
                              {
                                value: "mouldchange",
                                label: "Mould Change",
                              },
                              {
                                value: "machinebreakdown",
                                label: "Machine Breakdown",
                              },
                              {
                                value: "mouldbreakdown",
                                label: "Mould Breakdown",
                              },
                              { value: "ideal", label: "Ideal" },
                            ],
                          }}
                          value={
                            Records[0].customer_complaints[Number(item) - 1]
                          }
                          OnChange={(e) => {
                            Handlecustomercomplaints(e, Number(item) - 1);
                          }}
                        />
                      </td>
                      <td colSpan={4}>
                        <Field
                          name={"Visual_inspection_2"}
                          options={{
                            type: "dropdown",
                            disabled:
                              option === "edit"
                                ? false
                                : option === "view" || Hours[1],
                            values: [
                              { value: "NA", label: "NA" },
                              { value: "Ok", label: "✓" },
                              { value: "NotOk", label: "X" },
                              {
                                value: "mouldchange",
                                label: "Mould Change",
                              },
                              {
                                value: "machinebreakdown",
                                label: "Machine Breakdown",
                              },
                              {
                                value: "mouldbreakdown",
                                label: "Mould Breakdown",
                              },
                              { value: "ideal", label: "Ideal" },
                            ],
                          }}
                          value={
                            Records[0].customer_complaints[Number(item) - 1]
                          }
                          OnChange={(e) => {
                            Handlecustomercomplaints(e, Number(item) - 1);
                          }}
                        />
                      </td>
                      <td colSpan={4}>
                        <Field
                          name={"Visual_inspection_3"}
                          options={{
                            type: "dropdown",
                            disabled:
                              option === "edit"
                                ? false
                                : option === "view" || Hours[2],
                            values: [
                              { value: "NA", label: "NA" },
                              { value: "Ok", label: "✓" },
                              { value: "NotOk", label: "X" },
                              {
                                value: "mouldchange",
                                label: "Mould Change",
                              },
                              {
                                value: "machinebreakdown",
                                label: "Machine Breakdown",
                              },
                              {
                                value: "mouldbreakdown",
                                label: "Mould Breakdown",
                              },
                              { value: "ideal", label: "Ideal" },
                            ],
                          }}
                          value={
                            Records[0].customer_complaints[Number(item) - 1]
                          }
                          OnChange={(e) => {
                            Handlecustomercomplaints(e, Number(item) - 1);
                          }}
                        />
                      </td>
                      <td colSpan={4}>
                        <Field
                          name={"Visual_inspection_4"}
                          options={{
                            type: "dropdown",
                            disabled:
                              option === "edit"
                                ? false
                                : option === "view" || Hours[3],
                            values: [
                              { value: "NA", label: "NA" },
                              { value: "Ok", label: "✓" },
                              { value: "NotOk", label: "X" },
                              {
                                value: "mouldchange",
                                label: "Mould Change",
                              },
                              {
                                value: "machinebreakdown",
                                label: "Machine Breakdown",
                              },
                              {
                                value: "mouldbreakdown",
                                label: "Mould Breakdown",
                              },
                              { value: "ideal", label: "Ideal" },
                            ],
                          }}
                          value={
                            Records[0].customer_complaints[Number(item) - 1]
                          }
                          OnChange={(e) => {
                            Handlecustomercomplaints(e, Number(item) - 1);
                          }}
                        />
                      </td>
                    </tr>
                  ))}

                  <tr >
                    <th style={{ width: "21%" }} colSpan={20}>
                      <Lable value={"DIMENSIONS"} type={"Head"} />
                    </th>
                  </tr>
                  <tr>
                    <td
                      colSpan={20}
                      style={{ overflow: "hidden", overflowX: "scroll" }}
                    >
                      <div style={{ width: "150%" }}>
                        <table>
                          <tr >
                            <td colSpan={4}>
                              <Lable value={"SPEC/NO OF CAVITY"} />
                            </td>
                            <td colSpan={1} style={{ textAlign: "center" }}>
                              <Lable value={"1"} />
                            </td>
                            <td colSpan={1} style={{ textAlign: "center" }}>
                              <Lable value={"2"} />
                            </td>
                            <td colSpan={1} style={{ textAlign: "center" }}>
                              <Lable value={"3"} />
                            </td>
                            <td colSpan={1} style={{ textAlign: "center" }}>
                              <Lable value={"4"} />
                            </td>
                            <td colSpan={1} style={{ textAlign: "center" }}>
                              <Lable value={"5"} />
                            </td>
                            <td colSpan={1} style={{ textAlign: "center" }}>
                              <Lable value={"6"} />
                            </td>
                            <td colSpan={1} style={{ textAlign: "center" }}>
                              <Lable value={"7"} />
                            </td>
                            <td colSpan={1} style={{ textAlign: "center" }}>
                              <Lable value={"8"} />
                            </td>
                            <td colSpan={1} style={{ textAlign: "center" }}>
                              <Lable value={"9"} />
                            </td>
                            <td colSpan={1} style={{ textAlign: "center" }}>
                              <Lable value={"10"} />
                            </td>
                            <td colSpan={1} style={{ textAlign: "center" }}>
                              <Lable value={"11"} />
                            </td>
                            <td colSpan={1} style={{ textAlign: "center" }}>
                              <Lable value={"12"} />
                            </td>
                            <td colSpan={1} style={{ textAlign: "center" }}>
                              <Lable value={"13"} />
                            </td>
                            <td colSpan={1} style={{ textAlign: "center" }}>
                              <Lable value={"14"} />
                            </td>
                            <td colSpan={1} style={{ textAlign: "center" }}>
                              <Lable value={"15"} />
                            </td>
                            <td colSpan={1} style={{ textAlign: "center" }}>
                              <Lable value={"16"} />
                            </td>
                          </tr>

                          {/* {Array.from(
              { length: Records[0].dimensions.length },
              (_, index) => index + 1
            ).map((item) => ( */}
                          {/* {console.log(";Records[0].cavity", Records[0].cavity)} */}
                          {Array.from(
                            { length: Records[0].dimensions.length },
                            (_, index) => index + 1
                          ).map((item) => (
                            <tr >
                              <td colSpan={1} style={{ textAlign: "center" }}>
                                {item}
                              </td>
                              <td colSpan={3}>
                                <Lable
                                  style={{ width: "210px" }}
                                  value={`${
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specifications_loc ?? 0
                                  } * 
          ${Records[0]?.dimensions[Number(item) - 1]?.Specification_val ?? 0} (
          ${
            Records[0]?.dimensions[Number(item) - 1]?.Specification_symbol_1 ??
            ""
          } 
          ${
            Records[0]?.dimensions[Number(item) - 1]?.Specification_minus ?? 0
          } /
          ${
            Records[0]?.dimensions[Number(item) - 1]?.Specification_symbol_2 ??
            ""
          } 
          ${
            Records[0]?.dimensions[Number(item) - 1]?.Specification_plus ?? 0
          }) ${Records[0]?.dimensions[Number(item) - 1]?.Checking_aids}`}
                                />
                              </td>
                              <td style={{ width: "5%" }} colSpan={1}>
                                <Field
                                  name={"Dimension_1"}
                                  style={{ padding: "1px 1px" }}
                                  options={{
                                    type: "number",
                                    disabled: option === "view",
                                  }}
                                  min={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_1 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                  }
                                  max={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_2 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                  }
                                  value={
                                    Records[0].dimensions[Number(item) - 1]
                                  }
                                  OnChange={(e) => {
                                    HandleDimension(e, [Number(item) - 1]);
                                  }}
                                />
                              </td>
                              <td style={{ width: "5%" }} colSpan={1}>
                                <Field
                                  name={"Dimension_2"}
                                  style={{ padding: "1px 1px" }}
                                  options={{
                                    type: "number",
                                    disabled: option === "view",
                                  }}
                                  min={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_1 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                  }
                                  max={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_2 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                  }
                                  value={
                                    Records[0].dimensions[Number(item) - 1]
                                  }
                                  OnChange={(e) => {
                                    HandleDimension(e, [Number(item) - 1]);
                                  }}
                                />
                              </td>
                              <td style={{ width: "5%" }} colSpan={1}>
                                <Field
                                  name={"Dimension_3"}
                                  style={{ padding: "1px 1px" }}
                                  options={{
                                    type: "number",
                                    disabled: option === "view",
                                  }}
                                  min={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_1 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                  }
                                  max={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_2 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                  }
                                  value={
                                    Records[0].dimensions[Number(item) - 1]
                                  }
                                  OnChange={(e) => {
                                    HandleDimension(e, [Number(item) - 1]);
                                  }}
                                />
                              </td>
                              <td style={{ width: "5%" }} colSpan={1}>
                                <Field
                                  name={"Dimension_4"}
                                  style={{ padding: "1px 1px" }}
                                  options={{
                                    type: "number",
                                    disabled: option === "view",
                                  }}
                                  min={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_1 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                  }
                                  max={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_2 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                  }
                                  value={
                                    Records[0].dimensions[Number(item) - 1]
                                  }
                                  OnChange={(e) => {
                                    HandleDimension(e, [Number(item) - 1]);
                                  }}
                                />
                              </td>
                              <td style={{ width: "5%" }} colSpan={1}>
                                <Field
                                  name={"Dimension_5"}
                                  style={{ padding: "1px 1px" }}
                                  options={{
                                    type: "number",
                                    disabled: option === "view",
                                  }}
                                  min={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_1 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                  }
                                  max={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_2 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                  }
                                  value={
                                    Records[0].dimensions[Number(item) - 1]
                                  }
                                  OnChange={(e) => {
                                    HandleDimension(e, [Number(item) - 1]);
                                  }}
                                />
                              </td>
                              <td style={{ width: "5%" }} colSpan={1}>
                                <Field
                                  name={"Dimension_6"}
                                  style={{ padding: "1px 1px" }}
                                  options={{
                                    type: "number",
                                    disabled: option === "view",
                                  }}
                                  min={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_1 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                  }
                                  max={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_2 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                  }
                                  value={
                                    Records[0].dimensions[Number(item) - 1]
                                  }
                                  OnChange={(e) => {
                                    HandleDimension(e, [Number(item) - 1]);
                                  }}
                                />
                              </td>
                              <td style={{ width: "5%" }} colSpan={1}>
                                <Field
                                  name={"Dimension_7"}
                                  style={{ padding: "1px 1px" }}
                                  options={{
                                    type: "number",
                                    disabled: option === "view",
                                  }}
                                  min={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_1 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                  }
                                  max={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_2 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                  }
                                  value={
                                    Records[0].dimensions[Number(item) - 1]
                                  }
                                  OnChange={(e) => {
                                    HandleDimension(e, [Number(item) - 1]);
                                  }}
                                />
                              </td>
                              <td style={{ width: "5%" }} colSpan={1}>
                                <Field
                                  name={"Dimension_8"}
                                  style={{ padding: "1px 1px" }}
                                  options={{
                                    type: "number",
                                    disabled: option === "view",
                                  }}
                                  min={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_1 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                  }
                                  max={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_2 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                  }
                                  value={
                                    Records[0].dimensions[Number(item) - 1]
                                  }
                                  OnChange={(e) => {
                                    HandleDimension(e, [Number(item) - 1]);
                                  }}
                                />
                              </td>
                              <td style={{ width: "5%" }} colSpan={1}>
                                <Field
                                  name={"Dimension_9"}
                                  style={{ padding: "1px 1px" }}
                                  options={{
                                    type: "number",
                                    disabled: option === "view",
                                  }}
                                  min={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_1 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                  }
                                  max={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_2 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                  }
                                  value={
                                    Records[0].dimensions[Number(item) - 1]
                                  }
                                  OnChange={(e) => {
                                    HandleDimension(e, [Number(item) - 1]);
                                  }}
                                />
                              </td>
                              <td style={{ width: "5%" }} colSpan={1}>
                                <Field
                                  name={"Dimension_10"}
                                  style={{ padding: "1px 1px" }}
                                  options={{
                                    type: "number",
                                    disabled: option === "view",
                                  }}
                                  min={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_1 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                  }
                                  max={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_2 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                  }
                                  value={
                                    Records[0].dimensions[Number(item) - 1]
                                  }
                                  OnChange={(e) => {
                                    HandleDimension(e, [Number(item) - 1]);
                                  }}
                                />
                              </td>
                              <td style={{ width: "5%" }} colSpan={1}>
                                <Field
                                  name={"Dimension_11"}
                                  style={{ padding: "1px 1px" }}
                                  options={{
                                    type: "number",
                                    disabled: option === "view",
                                  }}
                                  min={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_1 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                  }
                                  max={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_2 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                  }
                                  value={
                                    Records[0].dimensions[Number(item) - 1]
                                  }
                                  OnChange={(e) => {
                                    HandleDimension(e, [Number(item) - 1]);
                                  }}
                                />
                              </td>
                              <td style={{ width: "5%" }} colSpan={1}>
                                <Field
                                  name={"Dimension_12"}
                                  style={{ padding: "1px 1px" }}
                                  options={{
                                    type: "number",
                                    disabled: option === "view",
                                  }}
                                  min={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_1 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                  }
                                  max={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_2 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                  }
                                  value={
                                    Records[0].dimensions[Number(item) - 1]
                                  }
                                  OnChange={(e) => {
                                    HandleDimension(e, [Number(item) - 1]);
                                  }}
                                />
                              </td>
                              <td style={{ width: "5%" }} colSpan={1}>
                                <Field
                                  name={"Dimension_13"}
                                  style={{ padding: "1px 1px" }}
                                  options={{
                                    type: "number",
                                    disabled: option === "view",
                                  }}
                                  min={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_1 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                  }
                                  max={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_2 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                  }
                                  value={
                                    Records[0].dimensions[Number(item) - 1]
                                  }
                                  OnChange={(e) => {
                                    HandleDimension(e, [Number(item) - 1]);
                                  }}
                                />
                              </td>
                              <td style={{ width: "5%" }} colSpan={1}>
                                <Field
                                  name={"Dimension_14"}
                                  style={{ padding: "1px 1px" }}
                                  options={{
                                    type: "number",
                                    disabled: option === "view",
                                  }}
                                  min={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_1 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                  }
                                  max={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_2 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                  }
                                  value={
                                    Records[0].dimensions[Number(item) - 1]
                                  }
                                  OnChange={(e) => {
                                    HandleDimension(e, [Number(item) - 1]);
                                  }}
                                />
                              </td>
                              <td style={{ width: "5%" }} colSpan={1}>
                                <Field
                                  name={"Dimension_15"}
                                  style={{ padding: "1px 1px" }}
                                  options={{
                                    type: "number",
                                    disabled: option === "view",
                                  }}
                                  min={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_1 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                  }
                                  max={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_2 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                  }
                                  value={
                                    Records[0].dimensions[Number(item) - 1]
                                  }
                                  OnChange={(e) => {
                                    HandleDimension(e, [Number(item) - 1]);
                                  }}
                                />
                              </td>
                              <td style={{ width: "5%" }} colSpan={1}>
                                <Field
                                  name={"Dimension_16"}
                                  style={{ padding: "1px 1px" }}
                                  options={{
                                    type: "number",
                                    disabled: option === "view",
                                  }}
                                  min={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_1 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_minus || 0
                                        )
                                  }
                                  max={
                                    Records[0]?.dimensions[Number(item) - 1]
                                      ?.Specification_symbol_2 === "+"
                                      ? parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) +
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                      : parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_val ?? 0
                                        ) -
                                        parseFloat(
                                          Records[0]?.dimensions[
                                            Number(item) - 1
                                          ]?.Specification_plus || 0
                                        )
                                  }
                                  value={
                                    Records[0].dimensions[Number(item) - 1]
                                  }
                                  OnChange={(e) => {
                                    HandleDimension(e, [Number(item) - 1]);
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </td>
                  </tr>
                  <tr >
                    <th colSpan={20}>
                      <Lable value={"FUNCTIONAL"} type={"Head"} />
                    </th>
                  </tr>
                  <tr >
                    <th style={{ width: "21%" }} colSpan={4}>
                      <Lable value={"HOURS"} type={"Head"} />
                    </th>
                    <th style={{}} colSpan={4}>
                      <Field
                        name={"Hours"}
                        options={{
                          // style:{textAlign:"center",fontWeight:"bold"},
                          type: "text",
                          disabled: true || Hours[0],
                        }}
                        value={Records[0].visual_inspection[0]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 0);
                        }}
                      />
                    </th>
                    <th style={{}} colSpan={4}>
                      <Field
                        name={"Hours"}
                        options={{
                          type: "text",
                          disabled: true || Hours[1],
                        }}
                        value={Records[0].visual_inspection[1]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 1);
                        }}
                      />
                    </th>
                    <th style={{}} colSpan={4}>
                      <Field
                        name={"Hours"}
                        options={{
                          type: "text",
                          disabled: true || Hours[2],
                        }}
                        value={Records[0].visual_inspection[2]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 2);
                        }}
                      />
                    </th>
                    <th style={{}} colSpan={4}>
                      <Field
                        name={"Hours"}
                        options={{
                          type: "text",
                          disabled: true || Hours[3],
                        }}
                        value={Records[0].visual_inspection[3]}
                        OnChange={(e) => {
                          Handlevisualinspection(e, 3);
                        }}
                      />
                    </th>
                  </tr>

                  <tr >
                    <td
                      colSpan={1}
                      style={{ textAlign: "center", width: "5%" }}
                    >
                      1
                    </td>
                    <td colSpan={3}>
                      <Lable value={"MATING/GAUGE CHECKING"} />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_1"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "dropdown",
                          disabled: option === "view" || Hours[0],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                          ],
                        }}
                        value={Records[0].dimensions_functional[0]}
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 0);
                        }}
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_2"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "dropdown",
                          disabled: option === "view" || Hours[1],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                          ],
                        }}
                        value={Records[0].dimensions_functional[0]}
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 0);
                        }}
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_3"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "dropdown",
                          disabled: option === "view" || Hours[2],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                          ],
                        }}
                        value={Records[0].dimensions_functional[0]}
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 0);
                        }}
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_4"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "dropdown",
                          disabled: option === "view" || Hours[3],
                          values: [
                            { value: "NA", label: "NA" },
                            { value: "Ok", label: "✓" },
                            { value: "NotOk", label: "X" },
                          ],
                        }}
                        value={Records[0].dimensions_functional[0]}
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 0);
                        }}
                      />
                    </td>
                  </tr>

                  <tr >
                    <td colSpan={1} rowSpan={2} style={{ textAlign: "center" }}>
                      2
                    </td>
                    <td colSpan={0.5} rowSpan={2}>
                      <Lable style={{ width: "50px" }} value={"C1"} />
                    </td>
                    <td colSpan={2.5}>
                      <Lable
                        value={
                          "PW1" +
                          "  (" +
                          Records[0].Part_weight_1 +
                          ") " +
                          "-" +
                          Records[0].Part_weight_set_minus_1 +
                          "/+" +
                          Records[0].Part_weight_set_plus_1
                        }
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_1"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[0],
                        }}
                        value={Records[0].dimensions_functional[1]}
                        min={
                          parseFloat(Records[0].Part_weight_1) -
                          parseFloat(Records[0].Part_weight_set_minus_1)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_1) +
                          parseFloat(Records[0].Part_weight_set_plus_1)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 1);
                        }}
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_2"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[1],
                        }}
                        value={Records[0].dimensions_functional[1]}
                        min={
                          parseFloat(Records[0].Part_weight_1) -
                          parseFloat(Records[0].Part_weight_set_minus_1)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_1) +
                          parseFloat(Records[0].Part_weight_set_plus_1)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 1);
                        }}
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_3"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[2],
                        }}
                        value={Records[0].dimensions_functional[1]}
                        min={
                          parseFloat(Records[0].Part_weight_1) -
                          parseFloat(Records[0].Part_weight_set_minus_1)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_1) +
                          parseFloat(Records[0].Part_weight_set_plus_1)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 1);
                        }}
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_4"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[3],
                        }}
                        value={Records[0].dimensions_functional[1]}
                        min={
                          parseFloat(Records[0].Part_weight_1) -
                          parseFloat(Records[0].Part_weight_set_minus_1)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_1) +
                          parseFloat(Records[0].Part_weight_set_plus_1)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 1);
                        }}
                      />
                    </td>
                  </tr>
                  <tr >
                    <td colSpan={2.5}>
                      <Lable
                        value={
                          "PW2" +
                          "  (" +
                          Records[0].Part_weight_2 +
                          ") " +
                          "-" +
                          Records[0].Part_weight_set_minus_2 +
                          "/+" +
                          Records[0].Part_weight_set_plus_2
                        }
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_1"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[0],
                        }}
                        value={Records[0].dimensions_functional[2]}
                        min={
                          parseFloat(Records[0].Part_weight_2) -
                          parseFloat(Records[0].Part_weight_set_minus_2)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_2) +
                          parseFloat(Records[0].Part_weight_set_plus_2)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 2);
                        }}
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_2"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[1],
                        }}
                        value={Records[0].dimensions_functional[2]}
                        min={
                          parseFloat(Records[0].Part_weight_2) -
                          parseFloat(Records[0].Part_weight_set_minus_2)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_2) +
                          parseFloat(Records[0].Part_weight_set_plus_2)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 2);
                        }}
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_3"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[2],
                        }}
                        value={Records[0].dimensions_functional[2]}
                        min={
                          parseFloat(Records[0].Part_weight_2) -
                          parseFloat(Records[0].Part_weight_set_minus_2)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_2) +
                          parseFloat(Records[0].Part_weight_set_plus_2)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 2);
                        }}
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_4"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[3],
                        }}
                        value={Records[0].dimensions_functional[2]}
                        min={
                          parseFloat(Records[0].Part_weight_2) -
                          parseFloat(Records[0].Part_weight_set_minus_2)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_2) +
                          parseFloat(Records[0].Part_weight_set_plus_2)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 2);
                        }}
                      />
                    </td>
                  </tr>
                  <tr >
                    <td colSpan={1} rowSpan={2} style={{ textAlign: "center" }}>
                      3
                    </td>
                    <td colSpan={1} rowSpan={2}>
                      <Lable style={{ width: "50px" }} value={"C2"} />
                    </td>
                    <td colSpan={2}>
                      <Lable
                        style={{ width: "210px" }}
                        value={
                          "PW1" +
                          "  (" +
                          Records[0].Part_weight_1 +
                          ") " +
                          "-" +
                          Records[0].Part_weight_set_minus_1 +
                          "/+" +
                          Records[0].Part_weight_set_plus_1
                        }
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_1"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[0],
                        }}
                        value={Records[0].dimensions_functional[3]}
                        min={
                          parseFloat(Records[0].Part_weight_1) -
                          parseFloat(Records[0].Part_weight_set_minus_1)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_1) +
                          parseFloat(Records[0].Part_weight_set_plus_1)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 3);
                        }}
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_2"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[1],
                        }}
                        value={Records[0].dimensions_functional[3]}
                        min={
                          parseFloat(Records[0].Part_weight_1) -
                          parseFloat(Records[0].Part_weight_set_minus_1)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_1) +
                          parseFloat(Records[0].Part_weight_set_plus_1)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 3);
                        }}
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_3"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[2],
                        }}
                        value={Records[0].dimensions_functional[3]}
                        min={
                          parseFloat(Records[0].Part_weight_1) -
                          parseFloat(Records[0].Part_weight_set_minus_1)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_1) +
                          parseFloat(Records[0].Part_weight_set_plus_1)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 3);
                        }}
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_4"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[3],
                        }}
                        value={Records[0].dimensions_functional[3]}
                        min={
                          parseFloat(Records[0].Part_weight_1) -
                          parseFloat(Records[0].Part_weight_set_minus_1)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_1) +
                          parseFloat(Records[0].Part_weight_set_plus_1)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 3);
                        }}
                      />
                    </td>
                  </tr>
                  <tr >
                    <td colSpan={2}>
                      <Lable
                        style={{ width: "210px" }}
                        value={
                          "PW2" +
                          "  (" +
                          Records[0].Part_weight_2 +
                          ") " +
                          "-" +
                          Records[0].Part_weight_set_minus_2 +
                          "/+" +
                          Records[0].Part_weight_set_plus_2
                        }
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_1"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[0],
                        }}
                        value={Records[0].dimensions_functional[4]}
                        min={
                          parseFloat(Records[0].Part_weight_2) -
                          parseFloat(Records[0].Part_weight_set_minus_2)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_2) +
                          parseFloat(Records[0].Part_weight_set_plus_2)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 4);
                        }}
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_2"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[1],
                        }}
                        value={Records[0].dimensions_functional[4]}
                        min={
                          parseFloat(Records[0].Part_weight_2) -
                          parseFloat(Records[0].Part_weight_set_minus_2)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_2) +
                          parseFloat(Records[0].Part_weight_set_plus_2)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 4);
                        }}
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_3"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[2],
                        }}
                        value={Records[0].dimensions_functional[4]}
                        min={
                          parseFloat(Records[0].Part_weight_2) -
                          parseFloat(Records[0].Part_weight_set_minus_2)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_2) +
                          parseFloat(Records[0].Part_weight_set_plus_2)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 4);
                        }}
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_4"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[3],
                        }}
                        value={Records[0].dimensions_functional[4]}
                        min={
                          parseFloat(Records[0].Part_weight_2) -
                          parseFloat(Records[0].Part_weight_set_minus_2)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_2) +
                          parseFloat(Records[0].Part_weight_set_plus_2)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 4);
                        }}
                      />
                    </td>
                  </tr>
                  <tr >
                    <td colSpan={1} rowSpan={2} style={{ textAlign: "center" }}>
                      4
                    </td>
                    <td colSpan={1} rowSpan={2}>
                      <Lable style={{ width: "50px" }} value={"C3"} />
                    </td>
                    <td colSpan={2}>
                      <Lable
                        value={
                          "PW1" +
                          "  (" +
                          Records[0].Part_weight_1 +
                          ") " +
                          "-" +
                          Records[0].Part_weight_set_minus_1 +
                          "/+" +
                          Records[0].Part_weight_set_plus_1
                        }
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_1"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[0],
                        }}
                        value={Records[0].dimensions_functional[5]}
                        min={
                          parseFloat(Records[0].Part_weight_1) -
                          parseFloat(Records[0].Part_weight_set_minus_1)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_1) +
                          parseFloat(Records[0].Part_weight_set_plus_1)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 5);
                        }}
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_2"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[1],
                        }}
                        value={Records[0].dimensions_functional[5]}
                        min={
                          parseFloat(Records[0].Part_weight_1) -
                          parseFloat(Records[0].Part_weight_set_minus_1)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_1) +
                          parseFloat(Records[0].Part_weight_set_plus_1)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 5);
                        }}
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_3"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[2],
                        }}
                        value={Records[0].dimensions_functional[5]}
                        min={
                          parseFloat(Records[0].Part_weight_1) -
                          parseFloat(Records[0].Part_weight_set_minus_1)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_1) +
                          parseFloat(Records[0].Part_weight_set_plus_1)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 5);
                        }}
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_4"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[3],
                        }}
                        value={Records[0].dimensions_functional[5]}
                        min={
                          parseFloat(Records[0].Part_weight_1) -
                          parseFloat(Records[0].Part_weight_set_minus_1)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_1) +
                          parseFloat(Records[0].Part_weight_set_plus_1)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 5);
                        }}
                      />
                    </td>
                  </tr>
                  <tr >
                    <td colSpan={2}>
                      <Lable
                        value={
                          "PW2" +
                          "  (" +
                          Records[0].Part_weight_2 +
                          ") " +
                          "-" +
                          Records[0].Part_weight_set_minus_2 +
                          "/+" +
                          Records[0].Part_weight_set_plus_2
                        }
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_1"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[0],
                        }}
                        value={Records[0].dimensions_functional[6]}
                        min={
                          parseFloat(Records[0].Part_weight_2) -
                          parseFloat(Records[0].Part_weight_set_minus_2)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_2) +
                          parseFloat(Records[0].Part_weight_set_plus_2)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 6);
                        }}
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_2"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[1],
                        }}
                        value={Records[0].dimensions_functional[6]}
                        min={
                          parseFloat(Records[0].Part_weight_2) -
                          parseFloat(Records[0].Part_weight_set_minus_2)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_2) +
                          parseFloat(Records[0].Part_weight_set_plus_2)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 6);
                        }}
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_3"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[2],
                        }}
                        value={Records[0].dimensions_functional[6]}
                        min={
                          parseFloat(Records[0].Part_weight_2) -
                          parseFloat(Records[0].Part_weight_set_minus_2)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_2) +
                          parseFloat(Records[0].Part_weight_set_plus_2)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 6);
                        }}
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_4"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[3],
                        }}
                        value={Records[0].dimensions_functional[6]}
                        min={
                          parseFloat(Records[0].Part_weight_2) -
                          parseFloat(Records[0].Part_weight_set_minus_2)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_2) +
                          parseFloat(Records[0].Part_weight_set_plus_2)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 6);
                        }}
                      />
                    </td>
                  </tr>
                  <tr >
                    <td colSpan={1} rowSpan={2} style={{ textAlign: "center" }}>
                      5
                    </td>
                    <td colSpan={1} rowSpan={2}>
                      <Lable style={{ width: "50px" }} value={"C4"} />
                    </td>
                    <td colSpan={2}>
                      <Lable
                        value={
                          "PW1" +
                          "  (" +
                          Records[0].Part_weight_1 +
                          ") " +
                          "-" +
                          Records[0].Part_weight_set_minus_1 +
                          "/+" +
                          Records[0].Part_weight_set_plus_1
                        }
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_1"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[0],
                        }}
                        value={Records[0].dimensions_functional[7]}
                        min={
                          parseFloat(Records[0].Part_weight_1) -
                          parseFloat(Records[0].Part_weight_set_minus_1)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_1) +
                          parseFloat(Records[0].Part_weight_set_plus_1)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 7);
                        }}
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_2"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[1],
                        }}
                        value={Records[0].dimensions_functional[7]}
                        min={
                          parseFloat(Records[0].Part_weight_1) -
                          parseFloat(Records[0].Part_weight_set_minus_1)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_1) +
                          parseFloat(Records[0].Part_weight_set_plus_1)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 7);
                        }}
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_3"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[2],
                        }}
                        value={Records[0].dimensions_functional[7]}
                        min={
                          parseFloat(Records[0].Part_weight_1) -
                          parseFloat(Records[0].Part_weight_set_minus_1)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_1) +
                          parseFloat(Records[0].Part_weight_set_plus_1)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 7);
                        }}
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_4"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[3],
                        }}
                        value={Records[0].dimensions_functional[7]}
                        min={
                          parseFloat(Records[0].Part_weight_1) -
                          parseFloat(Records[0].Part_weight_set_minus_1)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_1) +
                          parseFloat(Records[0].Part_weight_set_plus_1)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 7);
                        }}
                      />
                    </td>
                  </tr>
                  <tr >
                    <td colSpan={2}>
                      <Lable
                        value={
                          "PW2" +
                          "  (" +
                          Records[0].Part_weight_2 +
                          ") " +
                          "-" +
                          Records[0].Part_weight_set_minus_2 +
                          "/+" +
                          Records[0].Part_weight_set_plus_2
                        }
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_1"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[0],
                        }}
                        value={Records[0].dimensions_functional[8]}
                        min={
                          parseFloat(Records[0].Part_weight_2) -
                          parseFloat(Records[0].Part_weight_set_minus_2)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_2) +
                          parseFloat(Records[0].Part_weight_set_plus_2)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 8);
                        }}
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_2"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[1],
                        }}
                        value={Records[0].dimensions_functional[8]}
                        min={
                          parseFloat(Records[0].Part_weight_2) -
                          parseFloat(Records[0].Part_weight_set_minus_2)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_2) +
                          parseFloat(Records[0].Part_weight_set_plus_2)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 8);
                        }}
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_3"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[2],
                        }}
                        value={Records[0].dimensions_functional[8]}
                        min={
                          parseFloat(Records[0].Part_weight_2) -
                          parseFloat(Records[0].Part_weight_set_minus_2)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_2) +
                          parseFloat(Records[0].Part_weight_set_plus_2)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 8);
                        }}
                      />
                    </td>
                    <td style={{ width: "5%" }} colSpan={4}>
                      <Field
                        name={"Dimension_4"}
                        style={{ padding: "1px 1px" }}
                        options={{
                          type: "number",
                          disabled: option === "view" || Hours[3],
                        }}
                        value={Records[0].dimensions_functional[8]}
                        min={
                          parseFloat(Records[0].Part_weight_2) -
                          parseFloat(Records[0].Part_weight_set_minus_2)
                        }
                        max={
                          parseFloat(Records[0].Part_weight_2) +
                          parseFloat(Records[0].Part_weight_set_plus_2)
                        }
                        OnChange={(e) => {
                          HandleDimensionFunction(e, 8);
                        }}
                      />
                    </td>
                  </tr>
                  {/* </table>
                </div> */}
                  {/* </td> */}
                  {/* </tr> */}
                  <tr >
                    <td colSpan={4}>
                      <Field
                        label={"NOT APPLICABLE"}
                        options={{
                          type: "text",
                          defaultValue: "N A",
                          disabled: true,
                        }}
                        value={Records[0]}
                      />
                    </td>
                    <td colSpan={5}>
                      {" "}
                      <Field
                        label={"OK"}
                        options={{
                          type: "text",
                          defaultValue: "✓",
                          disabled: true,
                        }}
                        value={Records[0]}
                      />
                    </td>
                    <td colSpan={5}>
                      {" "}
                      <Field
                        label={"NOT OK"}
                        options={{
                          type: "text",
                          defaultValue: "X",
                          disabled: true,
                        }}
                        value={Records[0]}
                      />
                    </td>
                    <td colSpan={5}>
                      {" "}
                      <Lable value={"ACCEPTED/NOT ACCEPTED"} />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={20}>
                      {" "}
                      <Field
                        label={"REMARKS-ANY PROBLEM UP DATED IN THIS COLUMN"}
                        name={"Remarks"}
                        options={{
                          type: "textarea",
                          disabled: option === "view",
                        }}
                        doubleLine={true}
                        value={Records[0]}
                        OnChange={(e) => {
                          HandleRecords(e);
                          handleRemarks(e);
                        }}
                      />
                      {result ? (
                        <Field
                          // label={"Image1"}
                          name={"ImageRemarks"}
                          accept="image/*"
                          options={{
                            type: "image",
                            disabled: option === "view",
                            required: true,
                          }}
                          warning={warning}
                          value={Records[0]}
                          OnChange={(e) => {
                            HandleRecords(e);
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4}>
                      {" "}
                      <Field
                        label={"PRODUCTION OPERATOR NAME"}
                        name={"Prodcution_operator_name"}
                        doubleLine={true}
                        options={{
                          type: "reference",
                          reference: "emp_details_dropdown",
                          required: true,
                          disabled: option === "view",
                        }}
                        warning={warning}
                        value={{
                          value:
                            Records[0] &&
                            Records[0].Prodcutionoperatorname &&
                            Records[0].Prodcutionoperatorname[0]
                              ? Records[0].Prodcutionoperatorname[0].Emp_code
                              : "",
                          label:
                            Records[0] &&
                            Records[0].Prodcutionoperatorname &&
                            Records[0].Prodcutionoperatorname[0]
                              ? Records[0].Prodcutionoperatorname[0].Emp_name
                              : "",
                        }}
                        OnChange={(e) => {
                          HandleRecordSelect(
                            e,
                            "Prodcutionoperatorname",
                            "Emp_code",
                            "Emp_name"
                          );
                        }}
                      />
                    </td>
                    <td colSpan={5}>
                      {" "}
                      <Field
                        label={"PRODUCTION INCHARGE"}
                        name={"Prodcution_incharge"}
                        doubleLine={true}
                        options={{
                          type: "reference",
                          reference: "emp_details_dropdown",
                          required: true,
                          disabled: option === "view",
                        }}
                        warning={warning}
                        value={{
                          value:
                            Records[0] &&
                            Records[0].Prodcutionincharge &&
                            Records[0].Prodcutionincharge[0]
                              ? Records[0].Prodcutionincharge[0].Emp_code
                              : "",
                          label:
                            Records[0] &&
                            Records[0].Prodcutionincharge &&
                            Records[0].Prodcutionincharge[0]
                              ? Records[0].Prodcutionincharge[0].Emp_name
                              : "",
                        }}
                        OnChange={(e) => {
                          HandleRecordSelect(
                            e,
                            "Prodcutionincharge",
                            "Emp_code",
                            "Emp_name"
                          );
                        }}
                      />
                    </td>
                    <td colSpan={5}>
                      {" "}
                      <Field
                        label={"ON LINE QC"}
                        name={"Online_qa_sign"}
                        doubleLine={true}
                        options={{
                          type: "reference",
                          reference: "emp_details_dropdown",
                          required: true,
                          disabled: option === "view",
                        }}
                        warning={warning}
                        value={{
                          value:
                            Records[0] &&
                            Records[0].Onlineqasign &&
                            Records[0].Onlineqasign[0]
                              ? Records[0].Onlineqasign[0].Emp_code
                              : "",
                          label:
                            Records[0] &&
                            Records[0].Onlineqasign &&
                            Records[0].Onlineqasign[0]
                              ? Records[0].Onlineqasign[0].Emp_name
                              : "",
                        }}
                        OnChange={(e) => {
                          HandleRecordSelect(
                            e,
                            "Onlineqasign",
                            "Emp_code",
                            "Emp_name"
                          );
                        }}
                      />
                    </td>
                    <td colSpan={5}>
                      {" "}
                      <Field
                        label={"QC HOD"}
                        name={"Qa_sign"}
                        doubleLine={true}
                        options={{
                          type: "reference",
                          reference: "emp_details_dropdown",
                          required: true,
                          disabled: option === "view",
                        }}
                        warning={warning}
                        value={{
                          value:
                            Records[0] &&
                            Records[0].Qasign &&
                            Records[0].Qasign[0]
                              ? Records[0].Qasign[0].Emp_code
                              : "",
                          label:
                            Records[0] &&
                            Records[0].Qasign &&
                            Records[0].Qasign[0]
                              ? Records[0].Qasign[0].Emp_name
                              : "",
                        }}
                        OnChange={(e) => {
                          HandleRecordSelect(
                            e,
                            "Qasign",
                            "Emp_code",
                            "Emp_name"
                          );
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={9}>
                      {" "}
                      <Field
                        label={
                          "Has the Job been set as per the Master Setting data"
                        }
                        options={{
                          type: "text",
                          disabled: true,
                          defaultValue: "✓",
                        }}
                        value={Records[0]}
                      />
                    </td>
                    <td colSpan={11}>
                      {" "}
                      <Field
                        label={"Store the last of Part with details"}
                        options={{
                          type: "text",
                          disabled: true,
                          defaultValue: "✓",
                        }}
                        value={Records[0]}
                      />
                    </td>
                  </tr>
       
              {!(option === "view") && (
                <tr>
                  {Records[0].Id === undefined ? (
                    <td colSpan={20}>
                      {" "}
                      <Field
                        label={"Submit"}
                        options={{ type: "Button" }}
                        onClick={(e) => {
                          HandleCreate(e);
                        }}
                      />
                    </td>
                  ) : MouldChange ? (
                    <td colSpan={20}>
                      <Field
                        label={"Insert & Update"}
                        options={{ type: "Button" }}
                        onClick={(e) => {
                          HandleInsertUpdate(e);
                        }}
                      />
                    </td>
                  ) : option === "view" ? (
                    <td colSpan={20}>
                      {" "}
                      <Field
                        label={"Update"}
                        options={{ type: "Button" }}
                        onClick={(e) => {
                          HandleUpdate(e);
                        }}
                      />
                    </td>
                  ) : (
                    <td colSpan={20}>
                      {" "}
                      <Field
                        label={"UPDATE"}
                        options={{ type: "Button" }}
                        onClick={(e) => {
                          HandleUpdateRecord(e);
                        }}
                      />
                    </td>
                  )}
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* ExcelTable Design*/}
      <div ref={contentRef} id="content-to-pdf">
        <style>
          {`table {
  border-collapse: collapse;
  border: 1px solid;
  width:100%;
  margin: 0 auto;
}
.myTable {
  border: 2px solid;
  width: 90%;
  padding:5px;  
  height:100%;
  display:none;
}
th, td ,tr{
  border: 1px solid;
}`}
        </style>
        {DownloadDiv && (
          <table className="myTable">
            <tr>
              <td colspan="2">
                <img style={{ width: "100%" }} src={Logo} alt="Logo" />
              </td>

              <td colspan="12">
                <label
                  style={{
                    textAlignL: "center",
                    whiteSpace: "nowrap",
                    padding: "10px 0px",
                  }}
                >
                  Online Inspection
                </label>
              </td>
              <td colspan="5" style={{ marginRight: "10px" }}>
                FORMAT NO: UPCL/QC/R/17
                <br />
                REV NO: 04
                <br />
                REV DATE: 26-08-2019
              </td>
            </tr>
            <tr>
              <td colspan="3">Part Number : {Records[0].Part_number}</td>
              <td colspan="8">
                Part Name : {Records[0]?.PartNumber[0]?.mc_part_name}
              </td>
              <td colspan="8">Date : {Records[0].Entry_date}</td>
            </tr>
            <tr>
              <td colspan="3">No Of Cavity: {Records[0].No_of_cavity}</td>
              <td colspan="8">Part Colour: {Records[0].Part_colour}</td>
              <td colspan="8">Shift: {Records[0].Shift}</td>
            </tr>
            <tr>
              <td colspan="3">
                Raw Material Name :{" "}
                {Records[0].Two_k_part == "0"
                  ? Records[0]?.Rawmaterialname[0]?.Mh_raw_mat_name
                  : `${JSON.parse(Records[0].Raw_material_name)[0].label},
                ${JSON.parse(Records[0].Raw_material_name)[1].label}`}
              </td>
              <td colspan="8">Machine No : {Records[0].Machine_no}</td>
              <td colspan="8">Time: {Records[0].Time}</td>
            </tr>

            {Records &&
            Records.length > 0 &&
            Records[0].Image &&
            (Records[0].Image.startsWith("data:image/jpeg") ||
              Records[0].Image.startsWith("data:image/png")) ? (
              <tr>
                <td colSpan={22}>
                  <img
                    style={{ width: "40%", marginLeft: "40%", padding: "2px" }}
                    src={Records[0].Image}
                    alt="Img"
                  />
                </td>
              </tr>
            ) : null}

            <tr>
              <td colspan="19" style={{ textAlign: "center" }}>
                VISUAL INSPECTION
              </td>
            </tr>
            <tr>
              <td colspan="3" style={{ width: "20%", textAlign: "center" }}>
                HOURS
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].visual_inspection[0].Hours}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].visual_inspection[1].Hours}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].visual_inspection[2].Hours}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].visual_inspection[3].Hours}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>1</td>
              <td colspan="2" style={{ width: "15%", textAlign: "center" }}>
                FREE FROM FLASH
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[0].Free_from_flash}
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[1].Free_from_flash}
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[2].Free_from_flash}
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[3].Free_from_flash}
              </td>
            </tr>
            <tr>
              <td colspan="1" style={{ textAlign: "center" }}>
                2{" "}
              </td>
              <td colSpan="2" style={{ width: "20%", textAlign: "center" }}>
                FREE FROM SHORT MOULD
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[0].Free_from_short_mould}
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[1].Free_from_short_mould}
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[2].Free_from_short_mould}
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[3].Free_from_short_mould}
              </td>
            </tr>
            <tr>
              <td colspan="1" style={{ textAlign: "center" }}>
                3{" "}
              </td>
              <td colSpan="2" style={{ width: "20%", textAlign: "center" }}>
                FREE FROM SHRINKAGE
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[0].Free_from_shrinkage}
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[1].Free_from_shrinkage}
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[2].Free_from_shrinkage}
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[3].Free_from_shrinkage}
              </td>
            </tr>
            <tr>
              <td colspan="1" style={{ textAlign: "center" }}>
                4{" "}
              </td>
              <td colSpan="2" style={{ width: "20%", textAlign: "center" }}>
                FREE FROM COLOR VARIATION
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[0].Free_from_no_color_variation}
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[1].Free_from_no_color_variation}
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[2].Free_from_no_color_variation}
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[3].Free_from_no_color_variation}
              </td>
            </tr>
            <tr>
              <td colspan="1" style={{ textAlign: "center" }}>
                5{" "}
              </td>
              <td colSpan="2" style={{ width: "20%", textAlign: "center" }}>
                FREE FROM WELD LINE
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[0].Free_from_weld_line}
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[1].Free_from_weld_line}
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[2].Free_from_weld_line}
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[3].Free_from_weld_line}
              </td>
            </tr>
            <tr>
              <td colspan="1" style={{ textAlign: "center" }}>
                6{" "}
              </td>
              <td colSpan="2" style={{ width: "20%", textAlign: "center" }}>
                CALENDAR PIN TO BE VERIFIED
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[0].Calender_pin_to_be_verified}
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[1].Calender_pin_to_be_verified}
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[2].Calender_pin_to_be_verified}
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[3].Calender_pin_to_be_verified}
              </td>
            </tr>
            <tr>
              <td colspan="1" style={{ textAlign: "center" }}>
                7{" "}
              </td>
              <td colSpan="2" style={{ width: "20%", textAlign: "center" }}>
                FREE FROM BREAKAGE
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[0].Free_from_breakage}
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[1].Free_from_breakage}
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[2].Free_from_breakage}
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[3].Free_from_breakage}
              </td>
            </tr>
            <tr>
              <td colspan="1" style={{ textAlign: "center" }}>
                8{" "}
              </td>
              <td colSpan="2" style={{ width: "20%", textAlign: "center" }}>
                FREE FROM ALARM
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[0].Calender_pin_to_be_verified}
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[1].Calender_pin_to_be_verified}
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[2].Calender_pin_to_be_verified}
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                {Records[0].visual_inspection[3].Calender_pin_to_be_verified}
              </td>
            </tr>
            <tr>
              <td colspan="19" style={{ textAlign: "center" }}>
                CUSTOMER COMPLAINTS HISTORY
              </td>
            </tr>
            <tr>
              <td colspan="1" style={{ textAlign: "center" }}>
                1
              </td>
              <td colspan="2" style={{ width: "10%", textAlign: "center" }}>
                {Records[0].customer_complaints[0].Customer_descripton}
              </td>
              <td colspan="4" style={{ width: "10%", textAlign: "center" }}>
                {Records[0].customer_complaints[0].Visual_inspection_1}
              </td>
              <td colspan="4" style={{ width: "10%", textAlign: "center" }}>
                {Records[0].customer_complaints[0].Visual_inspection_2}
              </td>
              <td colspan="4" style={{ width: "10%", textAlign: "center" }}>
                {Records[0].customer_complaints[0].Visual_inspection_3}
              </td>
              <td colspan="4" style={{ width: "10%", textAlign: "center" }}>
                {Records[0].customer_complaints[0].Visual_inspection_4}
              </td>
            </tr>
            <tr>
              <td colspan="1" style={{ textAlign: "center" }}>
                2
              </td>
              <td colspan="2" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].customer_complaints[1].Customer_descripton}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].customer_complaints[1].Visual_inspection_1}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].customer_complaints[1].Visual_inspection_2}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].customer_complaints[1].Visual_inspection_3}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].customer_complaints[1].Visual_inspection_4}
              </td>
            </tr>
            <tr>
              <td colspan="1" style={{ textAlign: "center" }}>
                3
              </td>
              <td colspan="2" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].customer_complaints[2].Customer_descripton}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].customer_complaints[2].Visual_inspection_1}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].customer_complaints[2].Visual_inspection_2}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].customer_complaints[2].Visual_inspection_3}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].customer_complaints[2].Visual_inspection_4}
              </td>
            </tr>
            <tr>
              <td colspan="19" style={{ textAlign: "center" }}>
                {" "}
                DIMENSIONS
              </td>
            </tr>
            <tr>
              <td colspan="3" style={{ width: "5%", textAlign: "center" }}>
                {" "}
                SPEC/NO OF CAVITY
              </td>
              <td style={{ width: "3%", textAlign: "center" }}>1</td>
              <td style={{ width: "3%", textAlign: "center" }}>2</td>
              <td style={{ width: "3%", textAlign: "center" }}>3</td>
              <td style={{ width: "3%", textAlign: "center" }}>4</td>
              <td style={{ width: "3%", textAlign: "center" }}>5</td>
              <td style={{ width: "3%", textAlign: "center" }}>6</td>
              <td style={{ width: "3%", textAlign: "center" }}>7</td>
              <td style={{ width: "3%", textAlign: "center" }}>8</td>
              <td style={{ width: "3%", textAlign: "center" }}>9</td>
              <td style={{ width: "3%", textAlign: "center" }}>10</td>
              <td style={{ width: "3%", textAlign: "center" }}>11</td>
              <td style={{ width: "3%", textAlign: "center" }}>12</td>
              <td style={{ width: "3%", textAlign: "center" }}>13</td>
              <td style={{ width: "3%", textAlign: "center" }}>14</td>
              <td style={{ width: "3%", textAlign: "center" }}>15</td>
              <td style={{ width: "3%", textAlign: "center" }}>16</td>
            </tr>
            {Records[0].dimensions.map((dimension, index) => (
              <tr key={index}>
                <td colSpan="1" style={{ width: "3%", textAlign: "center" }}>
                  {index + 1}
                </td>
                <td colSpan="2" style={{ width: "5%", textAlign: "center" }}>
                  {dimension.Specifications_loc &&
                    dimension.Specification_val &&
                    dimension.symbol1 &&
                    dimension.minus &&
                    dimension.symbol2 &&
                    dimension.plus &&
                    `${dimension.Specifications_loc} * ${dimension.Specification_val} (${dimension.symbol1} ${dimension.minus} / ${dimension.symbol2} ${dimension.plus})`}
                </td>
                <td style={{ textAlign: "center" }}>{dimension.Dimension_1}</td>
                <td style={{ textAlign: "center" }}>{dimension.Dimension_2}</td>
                <td style={{ textAlign: "center" }}>{dimension.Dimension_3}</td>
                <td style={{ textAlign: "center" }}>{dimension.Dimension_4}</td>
                <td style={{ textAlign: "center" }}>{dimension.Dimension_5}</td>
                <td style={{ textAlign: "center" }}>{dimension.Dimension_6}</td>
                <td style={{ textAlign: "center" }}>{dimension.Dimension_7}</td>
                <td style={{ textAlign: "center" }}>{dimension.Dimension_8}</td>
                <td style={{ textAlign: "center" }}>{dimension.Dimension_9}</td>
                <td style={{ textAlign: "center" }}>
                  {dimension.Dimension_10}
                </td>
                <td style={{ textAlign: "center" }}>
                  {dimension.Dimension_11}
                </td>
                <td style={{ textAlign: "center" }}>
                  {dimension.Dimension_12}
                </td>
                <td style={{ textAlign: "center" }}>
                  {dimension.Dimension_13}
                </td>
                <td style={{ textAlign: "center" }}>
                  {dimension.Dimension_14}
                </td>
                <td style={{ textAlign: "center" }}>
                  {dimension.Dimension_15}
                </td>
                <td style={{ textAlign: "center" }}>
                  {dimension.Dimension_16}
                </td>
              </tr>
            ))}
            <tr>
              <td colspan="19" style={{ textAlign: "center" }}>
                FUNCTIONAL
              </td>
            </tr>
            <tr>
              <td colspan="3" style={{ width: "10%", textAlign: "center" }}>
                HOURS
              </td>
              <td colspan="4" style={{ width: "5%", textAlign: "center" }}>
                {Records[0].visual_inspection[0].Hours}
              </td>
              <td colspan="4" style={{ width: "5%", textAlign: "center" }}>
                {Records[0].visual_inspection[1].Hours}
              </td>
              <td colspan="4" style={{ width: "5%", textAlign: "center" }}>
                {Records[0].visual_inspection[2].Hours}
              </td>
              <td colspan="4" style={{ width: "5%", textAlign: "center" }}>
                {Records[0].visual_inspection[3].Hours}
              </td>
            </tr>

            <tr>
              <td colspan="1" style={{ width: "2%", textAlign: "center" }}>
                1
              </td>
              <td colspan="2" style={{ width: "2%", textAlign: "center" }}>
                MATING/GAUGE CHECKING
              </td>
              <td colspan="4" style={{ width: "10%", textAlign: "center" }}>
                {Records[0].dimensions_functional[0].Dimension_1}
              </td>
              <td colspan="4" style={{ width: "10%", textAlign: "center" }}>
                {Records[0].dimensions_functional[0].Dimension_2}
              </td>
              <td colspan="4" style={{ width: "10%", textAlign: "center" }}>
                {Records[0].dimensions_functional[0].Dimension_2}
              </td>
              <td colspan="4" style={{ width: "10%", textAlign: "center" }}>
                {Records[0].dimensions_functional[0].Dimension_4}
              </td>
            </tr>

            <tr>
              <td style={{ width: "2%", textAlign: "center" }}>2</td>
              <td style={{ width: "1%", textAlign: "center" }}>C1</td>
              <td style={{ width: "1%", textAlign: "center" }}>
                PW-1
                {"(" +
                  Records[0].Part_weight_1 +
                  ") " +
                  "-" +
                  Records[0].Part_weight_set_minus_1 +
                  "/+" +
                  Records[0].Part_weight_set_plus_1}
              </td>
              <td colspan="4" style={{ width: "10%", textAlign: "center" }}>
                {Records[0].dimensions_functional[1].Dimension_1}
              </td>
              <td colspan="4" style={{ width: "10%", textAlign: "center" }}>
                {Records[0].dimensions_functional[1].Dimension_2}
              </td>
              <td colspan="4" style={{ width: "10%", textAlign: "center" }}>
                {Records[0].dimensions_functional[1].Dimension_2}
              </td>
              <td colspan="4" style={{ width: "10%", textAlign: "center" }}>
                {Records[0].dimensions_functional[1].Dimension_4}
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td style={{ width: "25%", textAlign: "center" }}>
                PW-2
                {"(" +
                  Records[0].Part_weight_2 +
                  ") " +
                  "-" +
                  Records[0].Part_weight_set_minus_2 +
                  "/+" +
                  Records[0].Part_weight_set_plus_2}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[2].Dimension_1}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[2].Dimension_2}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[2].Dimension_2}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[2].Dimension_4}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>3</td>
              <td style={{ width: "2%", textAlign: "center" }}>C2</td>
              <td style={{ textAlign: "center" }}>
                PW-1
                {"(" +
                  Records[0].Part_weight_1 +
                  ") " +
                  "-" +
                  Records[0].Part_weight_set_minus_1 +
                  "/+" +
                  Records[0].Part_weight_set_plus_1}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[3].Dimension_1}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[3].Dimension_2}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[3].Dimension_2}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[3].Dimension_4}
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td style={{ textAlign: "center" }}>
                PW-2
                {"(" +
                  Records[0].Part_weight_2 +
                  ") " +
                  "-" +
                  Records[0].Part_weight_set_minus_2 +
                  "/+" +
                  Records[0].Part_weight_set_plus_2}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[4].Dimension_1}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[4].Dimension_2}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[4].Dimension_2}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[4].Dimension_4}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>4</td>
              <td style={{ width: "5%", textAlign: "center" }}>C3</td>
              <td style={{ textAlign: "center" }}>
                PW-1
                {"(" +
                  Records[0].Part_weight_1 +
                  ") " +
                  "-" +
                  Records[0].Part_weight_set_minus_1 +
                  "/+" +
                  Records[0].Part_weight_set_plus_1}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[5].Dimension_1}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[5].Dimension_2}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[5].Dimension_2}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[5].Dimension_4}
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td style={{ textAlign: "center" }}>
                PW-2
                {"(" +
                  Records[0].Part_weight_2 +
                  ") " +
                  "-" +
                  Records[0].Part_weight_set_minus_2 +
                  "/+" +
                  Records[0].Part_weight_set_plus_2}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[6].Dimension_1}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[6].Dimension_2}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[6].Dimension_2}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[6].Dimension_4}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>5</td>
              <td style={{ textAlign: "center" }}>C4</td>
              <td style={{ textAlign: "center" }}>
                PW-1
                {"(" +
                  Records[0].Part_weight_1 +
                  ") " +
                  "-" +
                  Records[0].Part_weight_set_minus_1 +
                  "/+" +
                  Records[0].Part_weight_set_plus_1}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[7].Dimension_1}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[7].Dimension_2}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[7].Dimension_2}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[7].Dimension_4}
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td style={{ textAlign: "center" }}>
                PW-2
                {"(" +
                  Records[0].Part_weight_2 +
                  ") " +
                  "-" +
                  Records[0].Part_weight_set_minus_2 +
                  "/+" +
                  Records[0].Part_weight_set_plus_2}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[8].Dimension_1}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[8].Dimension_2}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[8].Dimension_2}
              </td>
              <td colspan="4" style={{ width: "20%", textAlign: "center" }}>
                {Records[0].dimensions_functional[8].Dimension_4}
              </td>
            </tr>
            <tr>
              <td colSpan="3">NOT APPLICABLE:</td>
              <td colSpan="3">N A</td>
              <td colSpan="3">OK:</td>
              <td colSpan="3">✓</td>
              <td colSpan="3">NOT OK:</td>
              <td colSpan="2">X</td>
              <td colSpan="2">ACCEPTED/NOT ACCEPTED</td>
            </tr>
            <tr>
              <td colSpan={28}>
                REMARKS-ANY PROBLEM UP DATED IN THIS COLUMN: <br />
                <span>{Records[0].Remarks}</span>
              </td>
            </tr>
            <tr>
              <td colSpan="5">
                PRODUCTION OPERATOR NAME:
                <span>{Records[0].Prodcutionoperatorname[0].Emp_name}</span>
              </td>
              <td colSpan="5">
                {" "}
                PRODUCTION INCHARGE:
                <span>{Records[0].Prodcutionincharge[0].Emp_name}</span>
              </td>
              <td colSpan="5">
                ON LINE QC:<span>{Records[0].Onlineqasign[0].Emp_name}</span>
              </td>
              <td colSpan="7">
                QC HOD:<span>{Records[0].Qasign[0].Emp_name}</span>
              </td>
            </tr>
            <tr>
              <td colSpan="12">
                Has the Job been set as per the Master Setting data:{" "}
                {Records[0].Master_setting_dates || "✓"}
              </td>
              <td colSpan="10">
                Store the last of Part with details:{" "}
                {Records[0].Store_the_last_of_part_with_details || "✓"}
              </td>
            </tr>
          </table>
        )}
      </div>
    </QaLayout>
  );
};

export default OnlineInspectionForm;
