import React, { useEffect, useState, useRef, useCallback } from "react";
import useScanDetection from "use-scan-detection";
import Field from "../../../../Components/Fields/FieldPaint.js";
import Lable from "../../../../Components/Fields/Lable";
import { useNavigate, useParams } from "react-router-dom";
import "./SPPForm.css";
import QaLayoutPaint from "../../QaLayoutPaint.js";
import { setMessagePaint } from "../../../../Lib/SetMessagePaint.js";
import {
  CURRENT_Date,
  SHIFTMOLDINGQUALITY,
} from "../../../../Utils/GeneralConstants";
import LoadingPage from "../../../../Components/Handler/LoadingPage";
import SPPTabs from "./SPPTabs";
import PreviewPage from "./PreviewPage";
import * as GeneralConstants from "../../../../Utils/GeneralConstants.js";
import CreateRecordPaint from "../../../../Lib/API/CreateRecordPaint.js";
import ReadRecordPaint from "../../../../Lib/API/ReadRecordPaint.js";
import UpdateRecordPaint from "../../../../Lib/API/UpdateRecordPaint.js";
import CreateApprovalPaint from "../../../../Lib/CreateApprovalPaint.js";
import IsDuplicatePaint from "../../../../Lib/IsDuplicatePaint.js";
const SPPForm = () => {
  const CURRENT_TIME = new Date(
    new Date().getTime() - new Date().getTimezoneOffset() * 60000
  )
    .toISOString()
    .split("T")[1]
    .substring(0, 5);

  const InitRoot = [
    {
      download: false,
      CusName: [{}],
      Createdname: [{}],
      Approvedname: [{}],
      PartName: [{}],
      PaintName: {},
      ProgramName: [{}],
      Part_Name: "",
      Program_Name: "",
      Paint: "",
      Paint_Number: "",
      Part_Number: "",
      Program_Number: "",
      Customer: "",
      Created_By: "",
      Approved_By: "",
      Id: "",
      //  Entry_date: CURRENT_Date,
      //  Time: CURRENT_TIME,
      //  Shift: SHIFTMOLDINGQUALITY,
      Doc_No: "UPCL/PRO/D/70",
      Rev_No: "00",
      Gun_Height: "",
      Gun_Angle: "",
      Rev_ate: "01-06-2018",
      direction_function0: [
        {
          Direction_0: "A0#1(Automization Air)",
          key: "Authorization_Air",
          value: "",
        },
        { Direction_0: "A0#2(Fan Air)", key: "Fan_Air", value: "" },
        {
          Direction_0: "A0#3(Material Pressure)±0.5 Bar",
          key: "Material_Pressure",
          value: "",
        },
        { Direction_0: "File NO", key: "File_No", value: "" },
        { Direction_0: "NO Of Strokes", key: "No_of_Strokes", value: "" },
        {
          Direction_0: "Position Speed (mm/s)",
          key: "Position_speed",
          value: "",
        },
      ],
      direction_function90: [
        {
          Direction_90: "A0#1(Automization Air)",
          key: "Authorization_Air",
          value: "",
        },
        { Direction_90: "A0#2(Fan Air)", key: "Fan_Air", value: "" },
        {
          Direction_90: "A0#3(Material Pressure)±0.5 Bar",
          key: "Material_Pressure",
          value: "",
        },
        { Direction_90: "File NO", key: "File_No", value: "" },
        { Direction_90: "NO Of Strokes", key: "No_of_Strokes", value: "" },
        {
          Direction_90: "Position Speed (mm/s)",
          key: "Position_speed",
          value: "",
        },
      ],
      direction_function180: [
        {
          Direction_180: "A0#1(Automization Air)",
          key: "Authorization_Air",
          value: "",
        },
        { Direction_180: "A0#2(Fan Air)", key: "Fan_Air", value: "" },
        {
          Direction_180: "A0#3(Material Pressure)±0.5 Bar",
          key: "Material_Pressure",
          value: "",
        },
        { Direction_180: "File NO", key: "File_No", value: "" },
        { Direction_180: "NO Of Strokes", key: "No_of_Strokes", value: "" },
        {
          Direction_180: "Position Speed (mm/s)",
          key: "Position_speed",
          value: "",
        },
      ],
      direction_function270: [
        {
          Direction_270: "A0#1(Automization Air)",
          key: "Authorization_Air",
          value: "",
        },
        { Direction_270: "A0#2(Fan Air)", key: "Fan_Air", value: "" },
        {
          Direction_270: "A0#3(Material Pressure)±0.5 Bar",
          key: "Material_Pressure",
          value: "",
        },
        { Direction_270: "File NO", key: "File_No", value: "" },
        { Direction_270: "NO Of Strokes", key: "No_of_Strokes", value: "" },
        {
          Direction_270: "Position Speed (mm/s)",
          key: "Position_speed",
          value: "",
        },
      ],
      painting_smp_direction: [],
    },
  ];
  const [Records, setRecords] = useState(InitRoot);

  const directionValues1 =
    (Records[0].direction_function0 &&
      Records[0].direction_function0.map((item) => item.value)) ||
    [];
  const directionId1 =
    (Records?.[0]?.direction_function0 &&
      Records?.[0]?.direction_function0?.map((item) => item.Id)) ||
    [];

  const mappedValues1 = {
    Directions: "0",
    Id: directionId1.length > 0 ? directionId1[0] : "",
    Authorization_Air: directionValues1[0],
    Fan_Air: directionValues1[1],
    Material_Pressure: directionValues1[2],
    File_No: directionValues1[3],
    No_of_Strokes: directionValues1[4],
    Position_speed: directionValues1[5],
  };

  console.log("mappedValues1", mappedValues1);

  const directionValues2 =
    Records?.[0]?.direction_function90?.map((item) => item.value) || [];
  const directionId2 =
    (Records?.[0]?.direction_function90 &&
      Records?.[0]?.direction_function90?.map((item) => item.Id)) ||
    [];
  const mappedValues2 = {
    Directions: "90",
    Id: directionId2[0] || "",
    Authorization_Air: directionValues2[0],
    Fan_Air: directionValues2[1],
    Material_Pressure: directionValues2[2],
    File_No: directionValues2[3],
    No_of_Strokes: directionValues2[4],
    Position_speed: directionValues2[5],
  };
  const directionValues3 =
    Records?.[0]?.direction_function180?.map((item) => item.value) || [];
  const directionId3 =
    (Records?.[0]?.direction_function180 &&
      Records?.[0]?.direction_function180?.map((item) => item.Id)) ||
    [];
  const mappedValues3 = {
    Directions: "180",
    Id: directionId3[0] || "",
    Authorization_Air: directionValues3[0],
    Fan_Air: directionValues3[1],
    Material_Pressure: directionValues3[2],
    File_No: directionValues3[3],
    No_of_Strokes: directionValues3[4],
    Position_speed: directionValues3[5],
  };
  const directionValues4 =
    Records?.[0]?.direction_function270?.map((item) => item.value) || [];
  const directionId4 =
    (Records?.[0]?.direction_function270 &&
      Records?.[0]?.direction_function270?.map((item) => item.Id)) ||
    [];
  const mappedValues4 = {
    Directions: "270",
    Id: directionId4[0] || "",
    Authorization_Air: directionValues4[0],
    Fan_Air: directionValues4[1],
    Material_Pressure: directionValues4[2],
    File_No: directionValues4[3],
    No_of_Strokes: directionValues4[4],
    Position_speed: directionValues4[5],
  };
  Records[0].painting_smp_direction = [
    mappedValues1,
    mappedValues2,
    mappedValues3,
    mappedValues4,
  ];

  const tableRef = useRef(null);
  const scrollTop = useRef(null);
  const [Preview, setPreView] = useState(null);
  const [warning, setwarning] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("Records", Records);
    console.log("warnig", warning);
    console.log("afterdir", Records[0].painting_smp_direction);
  }, [Records]);

  const { option, id } = useParams();
  const navigate = useNavigate();

  function HandleRecords(Event) {
    setRecords([{ ...Records[0], [Event.target.name]: Event.target.value }]);
  }

  function HandleRecordSelect(Event, column, value, label) {
    //  console.log(Event.target.value)
    setRecords(() => {
      let record = [...Records];
      record[0][Event.name] = Event.values.value;
      record[0][column][0][value] = Event.values.value;
      record[0][column][0][label] = Event.values.label;
      return record;
    });
  }
  function HandleRecordSelectPart(Event, column, value, label) {
    setRecords(() => {
      let record = [...Records];
      record[0][Event.name] = Event.values.label;
      record[0].Part_Number = Event.values.value;
      record[0][column][value] = Event.values.value;
      record[0][column][label] = Event.values.label;
      return record;
    });
  }
  function HandleRecordSelectProg(Event, column, value, label) {
    setRecords(() => {
      let record = [...Records];
      record[0][Event.name] = Event.values.label;
      record[0].Program_Number = Event.values.value;
      record[0][column][value] = Event.values.value;
      record[0][column][label] = Event.values.label;
      return record;
    });
  }
  function HandleRecordSelectPaint(Event, column, value, label) {
    setRecords(() => {
      let record = [...Records];
      record[0][Event.name] = Event.values.value;
      record[0].Paint = Event.values.label;
      record[0][column][value] = Event.values.value;
      record[0][column][label] = Event.values.label;
      return record;
    });
  }
  const HandleCreate = async () => {
    // console.log(!Records[0].Part_Name);
    const isDuplicateCombination = await IsDuplicatePaint("painting_smp", [
      `Part_Name= "${Records[0].Part_Name}"`,
      `Program_Name= "${Records[0].Program_Name}"`,
      `Paint= "${Records[0].Paint}"`,
      `Customer= "${Records[0].Customer}"`,
    ]);

    if (isDuplicateCombination) {
      // console(`Part Name :"${Records[0].Part_Name}", Program Name :"${Records[0].Program_Name}", Paint: "${Records[0].Paint}",
      //    Customer: "${Records[0].Customer}" `);
      alert(
        `Combination of Part, Program, Paint, and Customer already exists.`
      );
      return;
    }

    if (
      !Records[0].Part_Name ||
      !Records[0].Customer ||
      !Records[0].Program_Name ||
      !Records[0].Paint ||
      !Records[0].Gun_Angle ||
      !Records[0].Gun_Height ||
      !Records[0].Created_By ||
      !Records[0].Approved_By
    ) {
      alert("Please Fill the Mandatory Fields");
      setwarning(true);
      setPreView(false);
    } else {
      let record = [...Records];
      console.log("created", record);
      const data = {
        data: [
          {
            Doc_No: Records[0].Doc_No,
            Rev_No: Records[0].Rev_No,
            Rev_ate: Records[0].Rev_ate,
            Part_Number: Records[0].Part_Number,
            Program_Number: Records[0].Program_Number,
            Paint_Number: Records[0].Paint_Number,
            Part_Name: Records[0].Part_Name,
            Program_Name: Records[0].Program_Name,
            Paint: Records[0].Paint,
            Customer: Records[0].CusName[0].Customer_code,
            Created_By: Records[0].Createdname[0].Emp_code,
            Approved_By: Records[0].Approvedname[0].Emp_code,
            Gun_Height: Records[0].Gun_Height,
            Gun_Angle: Records[0].Gun_Angle,
            painting_smp_direction: Records[0].painting_smp_direction,
          },
        ],
      };
      try {
        CreateRecordPaint("paint_SMP", data.data).then((res) => {
          if (res.feedback[0].success === true) {
            setMessagePaint({
              NoteFrom: localStorage.getItem("empId"),
              NoteTo: Records[0].Approved_By,
              NoteMessage: `${localStorage.getItem(
                "empId"
              )}-${localStorage.getItem(
                "name"
              )} has submitted Standard Process Parameter Form, Please do verify`,
              NoteRecordId: res.ROWID,
              TableName: "StandardProcessParameter",
              NoteCreatedBy: localStorage.getItem("empId"),
            });
            CreateApprovalPaint({
              RequestedBy: localStorage.getItem("empId"),
              Approver: Records[0].Approved_By,
              Level: 1,
              Status: "Waiting for Approval",
              TableName: "StandardProcessParameter",
              TableID: res.ROWID,
              Description:
                "The Standard Process Parameter Form Has been Created By " +
                localStorage.getItem("empId") +
                "-" +
                localStorage.getItem("name"),
            });
          }
        });
        alert(GeneralConstants.SUBMISSION_SUCCESSFUL);
        setRecords(InitRoot);
        setwarning(false);
        setPreView(false);
      } catch (error) {
        console.log(error);
        alert(GeneralConstants.SUBMISSION_FAILED);
      }
    }
  };

  useEffect(() => {
    document.title = "Standard Process Parameter";
    const fetchData = async () => {
      if (id === undefined) {
        setRecords([
          {
            ...Records[0],
            Entry_date: CURRENT_Date,
            Time: CURRENT_TIME,
            Shift: SHIFTMOLDINGQUALITY,
          },
        ]);
      } else {
        setLoading(true);
        try {
          const res = await ReadRecordPaint({
            _keyword_: "paint_SMP",
            Id: id,
          });
          if (res.data[0] === undefined) {
            setRecords(InitRoot);
          } else {
            console.log("InitRootupdate", res.data);
            let resp = res.data[0];
            const updatedDirectionFunction0 =
              Records[0].direction_function0.map((direction) => {
                const paintingDirection =
                  resp.painting_smp_direction[0][direction.key];
                const paintingDirectionId = resp.painting_smp_direction[0].Id;
                return {
                  ...direction,
                  value: paintingDirection,
                  Id: paintingDirectionId,
                };
              });
            // console.log("correction",updatedDirectionFunction0);
            const updatedDirectionFunction90 =
              Records[0].direction_function90.map((direction) => {
                const paintingDirection =
                  resp.painting_smp_direction[1][direction.key];
                const paintingDirectionId = resp.painting_smp_direction[1].Id;
                return {
                  ...direction,
                  value: paintingDirection,
                  Id: paintingDirectionId,
                };
              });
            // console.log("correction1",updatedDirectionFunction90);
            const updatedDirectionFunction180 =
              Records[0].direction_function180.map((direction) => {
                const paintingDirection =
                  resp.painting_smp_direction[2][direction.key];
                const paintingDirectionId = resp.painting_smp_direction[2].Id;
                return {
                  ...direction,
                  value: paintingDirection,
                  Id: paintingDirectionId,
                };
              });
            // console.log("correction2",updatedDirectionFunction180);
            const updatedDirectionFunction270 =
              Records[0].direction_function270.map((direction) => {
                const paintingDirection =
                  resp.painting_smp_direction[3][direction.key];
                const paintingDirectionId = resp.painting_smp_direction[3].Id;
                return {
                  ...direction,
                  value: paintingDirection,
                  Id: paintingDirectionId,
                };
              });

            setRecords(() => {
              let record = [...Records];
              (record[0].Id = resp.Id),
                (record[0].Gun_Angle = resp.Gun_Angle),
                (record[0].Gun_Height = resp.Gun_Height),
                (record[0].CusName = resp.CusName),
                (record[0].Rev_ate = resp.Rev_ate),
                (record[0].PaintName = {
                  Paint_code: resp.Paint_Number,
                  Paint_name: resp.Paint,
                });
              (record[0].PartName = {
                Part_code: resp.Part_Number,
                Part_name: resp.Part_Name,
              });
              (record[0].ProgramName = {
                Program_code: resp.Program_Number,
                Program_name: resp.Program_Name,
              });
              (record[0].Paint = resp.Paint),
                (record[0].Customer = resp.CusName),
                (record[0].Part_Name = resp.Part_Name),
                (record[0].Part_Number = resp.Part_Number),
                (record[0].Paint_Number = resp.Paint_Number),
                (record[0].Program_Number = resp.Program_Number);
              // (record[0].PartName = resp.PartName),
                (record[0].Created_By = resp.Created_By),
                (record[0].Approved_By = resp.Approved_By),
                (record[0].Program_Name = resp.Program_Name);
              // (record[0].ProgramName = resp.ProgramName),
                (record[0].Createdname = resp.Createdname),
                (record[0].Approvedname = resp.Approvedname),
                (record[0].direction_function0 =
                  updatedDirectionFunction0 || ""),
                (record[0].direction_function90 =
                  updatedDirectionFunction90 || ""),
                (record[0].direction_function180 =
                  updatedDirectionFunction180 || ""),
                (record[0].direction_function270 =
                  updatedDirectionFunction270 || "");
              // record[0].direction_function0 = resp.painting_smp_direction[0]
              // record[0].painting_smp_direction = resp.painting_smp_direction
              //  record[0].painting_smp_direction = Records[0].painting_smp_direction
              // record[0].backpres_function = resp.backpres_function,
              // record && delete record[0].Id;
              return record;
            });
            console.log("recupdate", Records);
          }
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchData();
  }, [id]);

  function HandleUpdate(event) {
    let record = [...Records];
    console.log("5555", record);
    if (
      !Records[0].Part_Name ||
      !Records[0].Customer ||
      !Records[0].Program_Name ||
      !Records[0].Paint ||
      !Records[0].Gun_Angle ||
      !Records[0].Gun_Height ||
      !Records[0].Created_By ||
      !Records[0].Approved_By
    ) {
      setwarning(true);
      alert("Please Fill the Mandatory Fields");
      setPreView(false);

      return;
    }
    const data = {
      data: [
        {
          Id: Records[0]?.Id,
          Doc_No: Records[0]?.Doc_No,
          Rev_No: Records[0]?.Rev_No,
          Rev_ate: Records[0]?.Rev_ate,
          Part_Number: Records[0].Part_Number,
          Program_Number: Records[0].Program_Number,
          Paint_Number: Records[0].Paint_Number,
          Part_Name: Records[0].Part_Name,
          Program_Name: Records[0].Program_Name,
          Paint: Records[0].Paint,
          Customer: Records[0]?.CusName[0]?.Customer_code,
          Created_By: Records[0]?.Createdname[0]?.Emp_code,
          Approved_By: Records[0]?.Approvedname[0]?.Emp_code,
          Gun_Height: Records[0]?.Gun_Height,
          Gun_Angle: Records[0]?.Gun_Angle,
          painting_smp_direction: Records[0]?.painting_smp_direction,
        },
      ],
    };
    try {
      UpdateRecordPaint("paint_SMP", data.data).then((res) => {
        if (res.feedback[0].success === true) {
          alert(GeneralConstants.UPDATE_SUCCESSFUL);
          setRecords({
            ...Records,
            ...InitRoot,
          });
        }
      });
      setPreView(false);
      setwarning(false);
      alert(GeneralConstants.UPDATE_SUCCESSFUL);
      navigate(-1);
    } catch (error) {
      console.log(error);
      alert(GeneralConstants.UPDATE_FAILURE);
    }
  }

  // const updatedDirectionFunction90 = direction_function90.map(direction => {
  //   const paintingDirection = Records[0].painting_smp_direction[0][direction.key];
  //   return { ...direction, value: paintingDirection };
  // });

  // console.log(updatedDirectionFunction90);
  if (loading) {
    return <LoadingPage />;
  }

  return (
    <QaLayoutPaint record={Records} quary={"StandardProcessParameter"}>
      <div style={{ width: "100%" }}>
        <div ref={scrollTop}>
          <div ref={tableRef}>
            <table>
              <tbody>
                {/* <tr>
                <th colSpan={16} >
                  <Lable value={"Standard Process Parameter"} type={"Head"} />
                </th>
                <td colSpan={3}>
                  <tr>
                    <td style={{ width: "25%" }}>
                      <Field
                        label={"DOC NO"}
                        name={"Doc_No"}
                        style={{ padding: "0px" }}
                        options={{
                          type: "text",
                          // defaultValue: "UPCL/QC/R/02",
                          disabled: true,
                        }}
                        value={Records[0]}
                        OnChange={(e) => {
                          HandleRecords(e);
                        }}
                      />
                    </td>
                  </tr>

                  // <tr>
                  //   <td style={{ width: "25%" }}>
                  //     <Field
                  //       label={"REV NO"}
                  //       name={"Rev_No"}
                  //       style={{ padding: "0px" }}
                  //       options={{
                  //         type: "text",
                  //         // defaultValue: "00",
                  //         disabled: true,
                  //       }}
                  //       value={Records[0]}
                  //       OnChange={(e) => {
                  //         HandleRecords(e);
                  //       }}
                  //     />
                  //   </td>
                  // </tr>
                  <tr>
                    <td style={{ width: "25%" }}>
                      <Field
                        label={"REV DATE"}
                        name={"Rev_ate"}
                        style={{ padding: "0px" }}
                        options={{
                          type: "text",
                          // defaultValue: "18-08-2018",
                          disabled: true,
                        }}
                        value={Records[0]}
                        OnChange={(e) => {
                          HandleRecords(e);
                        }}
                      />
                    </td>
                  </tr>
                </td>
             
              </tr> */}

                <tr>
                  <td colSpan={5}>
                    <Field
                      label={"Part Name"}
                      name={"Part_Name"}
                      options={{
                        type: "reference",
                        reference: "Get_paint_part_name",
                        required: true,
                        disabled: option === "view",
                      }}
                      warning={warning}
                      value={{
                        value: Records[0].PartName
                          ? Records[0].PartName.Part_code
                          : "",
                        label: Records[0].PartName
                          ? Records[0].PartName.Part_name
                          : "",
                      }}
                      OnChange={(e) => {
                        HandleRecordSelectPart(
                          e,
                          "PartName",
                          "Part_code",
                          "Part_name"
                        );
                      }}
                    />
                  </td>

                  <td colSpan={5}>
                    <Field
                      label={"Program Name"}
                      name={"Program_Name"}
                      options={{
                        type: "reference",
                        reference: "Get_paint_program_name",
                        required: true,
                        disabled: option === "view",
                      }}
                      warning={warning}
                      value={{
                        value: Records[0].ProgramName
                          ? Records[0].ProgramName.Program_code
                          : "",
                        label: Records[0].ProgramName
                          ? Records[0].ProgramName.Program_name
                          : "",
                      }}
                      OnChange={(e) => {
                        HandleRecordSelectProg(
                          e,
                          "ProgramName",
                          "Program_code",
                          "Program_name"
                        );
                      }}
                    />
                  </td>

                  <td colSpan={5}>
                    <Field
                      label={"Paint"}
                      name={"Paint_Number"}
                      options={{
                        type: "reference",
                        reference: "Get_paint_name",
                        required: true,
                        disabled: option === "view",
                      }}
                      warning={warning}
                      value={{
                        value: Records[0].PaintName
                          ? Records[0].PaintName.Paint_code
                          : "",
                        label: Records[0].PaintName
                          ? Records[0].PaintName.Paint_name
                          : "",
                      }}
                      // value={{
                      //   value: Records[0].Paint_Number
                      //     ? Records[0].Paint_Number
                      //     : "",
                      //   label: Records[0].Paint ? Records[0].Paint : "",
                      // }}
                      OnChange={(e) => {
                        HandleRecordSelectPaint(
                          e,
                          "PaintName",
                          "Paint_code",
                          "Paint_name"
                        );
                      }}
                    />
                  </td>

                  <td colSpan={5}>
                    <Field
                      label={"Customer"}
                      name={"Customer"}
                      options={{
                        type: "reference",
                        reference: "Get_paint_customer_name",
                        required: true,
                        disabled: option === "view",
                      }}
                      warning={warning}
                      value={{
                        value: Records[0].CusName[0]
                          ? Records[0].CusName[0].Customer_code
                          : "",
                        label: Records[0].CusName[0]
                          ? Records[0].CusName[0].Customer_name
                          : "",
                      }}
                      OnChange={(e) => {
                        HandleRecordSelect(
                          e,
                          "CusName",
                          "Customer_code",
                          "Customer_name"
                        );
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={5}>
                    <Field
                      label={"GUN HEIGHT"}
                      name={"Gun_Height"}
                      options={{
                        type: "text",
                        required: true,
                        disabled: option === "view",
                      }}
                      value={Records[0]}
                      warning={warning}
                      OnChange={(e) => {
                        HandleRecords(e);
                      }}
                    />
                  </td>
                  <td colSpan={5}>
                    <Field
                      label={"GUN ANGLE"}
                      name={"Gun_Angle"}
                      options={{
                        type: "text",
                        required: true,
                        disabled: option === "view",
                      }}
                      warning={warning}
                      value={Records[0]}
                      OnChange={(e) => {
                        HandleRecords(e);
                      }}
                    />
                  </td>
                  <td colSpan={5}>
                    <Field
                      label={"Created By"}
                      name={"Created_By"}
                      options={{
                        type: "reference",
                        reference: "emp_details_dropdown",
                        required: true,
                        disabled: option === "view",
                      }}
                      warning={warning}
                      value={{
                        value: Records[0].Createdname[0]
                          ? Records[0].Createdname[0].Emp_code
                          : "",
                        label: Records?.[0]?.Createdname?.[0]
                          ? Records[0].Createdname[0].Emp_name
                          : "",
                      }}
                      OnChange={(e) => {
                        HandleRecordSelect(
                          e,
                          "Createdname",
                          "Emp_code",
                          "Emp_name"
                        );
                      }}
                    />
                  </td>
                  <td colSpan={5}>
                    <Field
                      label={"Approved By"}
                      name={"Approved_By"}
                      options={{
                        type: "reference",
                        reference: "emp_details_dropdown",
                        required: true,
                        disabled: option === "view",
                      }}
                      warning={warning}
                      value={{
                        value: Records[0].Approvedname[0]
                          ? Records[0].Approvedname[0].Emp_code
                          : "",
                        label: Records[0].Approvedname[0]
                          ? Records[0].Approvedname[0].Emp_name
                          : "",
                      }}
                      OnChange={(e) => {
                        HandleRecordSelect(
                          e,
                          "Approvedname",
                          "Emp_code",
                          "Emp_name"
                        );
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <SPPTabs
            Records={Records}
            setRecords={setRecords}
            InitRoot={InitRoot}
          />
          <Field
            className={"submit"}
            label={"Preview"}
            options={{ type: "Button" }}
            onClick={() => {
              setPreView(true);
            }}
          />
        </div>
        {Preview && (
          <PreviewPage
            Records={Records}
            setState={setPreView}
            HandleCreate={HandleCreate}
            option={option}
            HandleUpdate={HandleUpdate}
            id={id}
          />
        )}
      </div>
    </QaLayoutPaint>
  );
};

export default SPPForm;
