import React, { useState,useEffect } from "react";
import { PROCESS_TRANSACTION_URL, CODEGEN_URL, SECRET_KEY } from "../../../Utils/constants.js";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";

import "../../../Styles/Part3.css";
import IsDuplicate from "../../../Lib/IsDuplicate.js";
import CreateRecord from "../../../Lib/API/CreateRecord.js";
// import CodeGen from "../../ReUsableComponents/CodeGen.js";
const initialState = {
  MacBay: "",
  SelectedOption: "",
  MacCde: "",
  MacNme: "",
  MacVer: "",
  Flexy1: "",
  Flexy2: "",
  Flexy3: "",
  mac_mas: []
}

function MachineMasterCreate({ tab1 }){
 
  const [isCreating, setIsCreating] = useState(false);
  const [machineMasterState, setMachineMasterState] = useState(initialState);
  useEffect(() => {
  setMachineMasterState(initialState);
  }, [tab1])
  const handleCreate = async (e) => {
    console.log("initial",machineMasterState);
    console.log("machineMasterState.MacNme",machineMasterState.MacBay);
    e.preventDefault();
    const trimmedCode = machineMasterState.MacCde.trim();
    const trimmedName = machineMasterState.MacNme.trim();
    if (!machineMasterState.MacCde || !machineMasterState.SelectedOption || !machineMasterState.MacNme || !machineMasterState.MacBay || machineMasterState.MacBay==="") {
      setIsCreating(true);
      alert(GeneralConstants.MANDATORY_ALERT);
      return;
    }
    try {
    
      const isDuplicateCode = await IsDuplicate("machine_details", [
        `mc_code="${trimmedCode}"`,
        `mc_active_status = 'Active'`,
      ]);
      const isDuplicateCode1 = await IsDuplicate("machine_details", [
        `mc_name="${trimmedName}"`,
        `mc_active_status = 'Active'`,
      ]);
      if (isDuplicateCode) {
        alert(`Machine code  ${trimmedCode} already exists.`);
        setIsCreating(false);
        return;
      }    
      if (isDuplicateCode1) {
        alert(`Machine name ${trimmedName} already exists.`);
        setIsCreating(false);
        return;
      }   
      const data = {

        data: [
          {
            mc_mac_bay: machineMasterState.MacBay,
            mc_code: trimmedCode,
            mc_name: trimmedName,
            mc_version: machineMasterState.MacVer,
            mc_flex_01: machineMasterState.Flexy1,
            mc_flex_02: machineMasterState.Flexy2,
            mc_flex_03: machineMasterState.Flexy3,
            mc_active_status: machineMasterState.SelectedOption,
          },
        ],
        // _keyword_: "mac_dtl",
        // secretkey: SECRET_KEY,
      };
      CreateRecord("mac_dtl", data.data).then((res) => {
        if (res.feedback[0].success === true) {
          alert(`Machine Code ${GeneralConstants.SUBMISSION_SUCCESSFUL}`);
          setMachineMasterState({...machineMasterState,...initialState});
          setIsCreating(false);
        }
      });
    } catch (error) {
      console.log(error);
      alert(
        `${machineMasterState.MacCde} ${GeneralConstants.SUBMISSION_FAILED}`
      );
      window.location.reload();
    }
  };
  //     const response = await getAPIRequest(
  //       PROCESS_TRANSACTION_URL, 'POST', data
  //     );
  //     const response1 = await response.json();

  //     if (response1.success === "false") {
  //       alert(GeneralConstants.FAIL_TO_SAVE_MACHINE);
  //       window.location.reload();
  //     }
  //     else {
  //       const macmas = [...machineMasterState.mac_mas, response.data]
  //       setMachineMasterState({ ...machineMasterState, mac_mas: macmas });
  //     }
  //     if (IsCreating) {
  //       alert(`Machine Master Code ${GeneralConstants.SUBMISSION_SUCCESSFUL}`);
  //       setMachineMasterState({...machineMasterState,...initialState});
  //       setIsCreating(false);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     alert(`${machineMasterState.MacNme} ${GeneralConstants.SUBMISSION_FAILED}`);
  //     window.location.reload();
  //   }

  // };
  // const options = [
  //   { value: "", label: "--Select--" },
  //   { value: "Bay 1", label: "Bay 1" },
  //   { value: "Bay 2", label: "Bay 2" },
  //   { value: "Bay 3", label: "Bay 3" },
  //   { value: "Bay 4", label: "Bay 4" },
  //   { value: "Bay 5", label: "Bay 5" },
  //   { value: "Plant 2", label: "Plant 2" },
  // ]; 
  return (
    <div >
      <form id="machineMasterCreate.formContainer" name="machineMasterCreate.formContainer" className="form-machineMaster-Create">
        <table style={{ textAlign: "center", width: "100%" }} cellPadding="5" >
          <tbody>
            <tr>
              <th
                id="mmc.Title"
                name="mmc.Title"
                colSpan="4"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">Machine Master</font>
              </th>
            </tr>
            <tr>
              <th>&nbsp;&nbsp;Bay<span className="Req-i">*</span></th>
              <td>
                {machineMasterState.MacBay === '' && isCreating && <span className="Req">Required *</span>}
                <select
                  id="mmc.MacBay"
                  name="mmc.MacBay"
                  onChange={(e) =>
                    setMachineMasterState({
                      ...machineMasterState,
                      MacBay: e.target.value,
                    })
                  }
                  style={{textAlign:"center"}}
                  value={machineMasterState.MacBay}
                >
                  <option value="">--Select Bay--</option>
                  <option value="Bay 1">Bay 1</option>
                  <option value="Bay 2">Bay 2</option>
                  <option value="Bay 3">Bay 3</option>
                  <option value="Bay 4">Bay 4</option>
                  <option value="Bay 5">Bay 5</option>
                  <option value="Bay 6">Bay 6</option>
                  <option value="Plant 2">Plant 2</option>                  
                </select>
              </td>
             
              <th>&nbsp;&nbsp;Machine Code<span className="Req-i">*</span></th>
              <td>
                {machineMasterState.MacCde === '' && isCreating && <span className="Req">Required *</span>}
                <input
                  id="mmc.MachineCode"
                  name="mmc.MachineCode"
                  type="text"
                  value={machineMasterState.MacCde}
                  onChange={(e) => setMachineMasterState({
                    ...machineMasterState,
                    MacCde: e.target.value
                  })}
                  required
                />
              </td>
              </tr>
              <tr>
              <th>&nbsp;&nbsp;Machine Name<span className="Req-i">*</span></th>
              <td>
                {machineMasterState.MacNme === '' && isCreating && <span className="Req">Required *</span>}
                <input
                  id="mmc.MachineName"
                  name="mmc.MachineName"
                  type="text"
                  value={machineMasterState.MacNme}   
                  onChange={(e) => setMachineMasterState({
                    ...machineMasterState,
                    MacNme: e.target.value
                  })}            
                 required
                />
              </td>
              <th>&nbsp;&nbsp;Machine Version</th>
              <td>
                <input
                  id="mmc.MachineVersion"
                  name="mmc.MachineVersion"
                  type="text"
                  value={machineMasterState.MacVer}
                  onChange={(e) => setMachineMasterState({
                    ...machineMasterState,
                    MacVer: e.target.value
                  })}
                />
              </td>
            </tr>
            <tr>
             
              <th>&nbsp;&nbsp;Flex 1</th>
              <td>
                <input
                  id="mmc.Flexy1"
                  name="mmc.Flexy1"
                  type="text"
                  value={machineMasterState.Flexy1}
                  onChange={(e) => setMachineMasterState({
                    ...machineMasterState,
                    Flexy1: e.target.value
                  })}
                />
              </td>
              <th>&nbsp;&nbsp;Flex 2</th>
              <td>
                <input
                  id="mmc.Flexy2"
                  name="mmc.Flexy2"
                  type="text"
                  value={machineMasterState.Flexy2}
                  onChange={(e) => setMachineMasterState({
                    ...machineMasterState,
                    Flexy2: e.target.value
                  })}
                />
              </td>
            </tr>
            <tr>
             
              <th>&nbsp;&nbsp;Flex 3</th>
              <td>
                <input
                  id="mmc.Flexy3"
                  name="mmc.Flexy3"
                  type="text"
                  value={machineMasterState.Flexy3}
                  onChange={(e) => setMachineMasterState({
                    ...machineMasterState,
                    Flexy3: e.target.value
                  })}
                />
              </td>
           
              <th>&nbsp;&nbsp;Active Status<span className="Req-i">*</span></th>
              <td colSpan={3}>
              {machineMasterState.SelectedOption === '' && isCreating && <span className="Req">Required *</span>}
                <select
                  id="mmc.ActiveStatus"
                  name="mmc.ActiveStatus"
                  
                  value={machineMasterState.SelectedOption}
                  onChange={(e) => setMachineMasterState({
                    ...machineMasterState,
                    SelectedOption: e.target.value
                  })}
                  style={{ textAlign: "center" }}
                >
                  <option id="mmc.ActiveStatus.select"
                    name="mmc.ActiveStatus.select"
                    value="">-- Select --</option>
                  <option id="mmc.ActiveStatus.active"
                    name="mmc.ActiveStatus.active"
                    value="Active">Active</option>
                  <option id="mmc.ActiveStatus.inactive"
                    name="mmc.ActiveStatus.inactive"
                    value="InActive">InActive</option>
                </select>
              </td>
            </tr>
            <tr>
              <td colSpan="4" style={{ border: "none" }}>
                <button type="submit" id="mmc.submit" name="mmc.submit" onClick={(e) => handleCreate(e)}>
                  Submit
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
}

export default MachineMasterCreate;
