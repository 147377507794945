import React, { useState, useEffect } from "react";
import Popup from "../../../Components/Popup/Popup";
import { SECRET_KEY, PROCESS_TRANSACTION_UPDATE_URL } from "../../../Utils/constants";
//import "bootstrap/dist/css/bootstrap.min.css";
import HandleRead from "../../../Lib/HandleRead.js"
import "../../../Styles/Part3.css";
import Pagination from "../../../Components/Pagination";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import UpdateRecord from "../../../Lib/updateRecord";
import { MdDelete } from "react-icons/md";
import ReadRecord from "../../../Lib/API/ReadRecord.js";

function SupplierMasterView() {
  const [isCreating, setIsCreating] = useState(false);
  const [supplierMasterAddState, setSupplierMasterAddState] = useState({
    SelectedOption: "",
    SupCde: "",
    SupNme: "",
    Flexy1: "",
    Flexy2: "",
    Flexy3: "",
    Flexy4: "",
    isPaginationVisible: true,
    currentPage: 1,
    filterSmnm: "",
    filterSmcd: ""
  })
  const [sup_mas, setsup_mas] = useState([]);
  const [ButtonPopup2, setButtonPopup2] = useState(false);
  const [ButtonPopup, setButtonPopup] = useState(false);

  const recordPerPage = 20;
  const filterRecords = () => {
    const filteredData = sup_mas.filter((record) => {
      const smnmMatch = record.Sup_name
        .toLowerCase()
        .includes(supplierMasterAddState.filterSmnm.toLowerCase());
      const smcdMatch = record.Sup_code
        .toLowerCase().
        includes(supplierMasterAddState.filterSmcd.toLowerCase());
      return smnmMatch && smcdMatch;
    });
    return filteredData || [];
  };
  const filteredData = filterRecords();
  const npage = Math.ceil(filteredData.length / recordPerPage);
  const numbers = [...Array(npage).keys()].map((num) => num + 1);

  function handleFilterSmNmChange(event) {
    setSupplierMasterAddState({
      ...supplierMasterAddState,
      currentPage: 1,
      filterSmnm: event.target.value
    });
  }
  function handleFilterSmCdChange(event) {
    setSupplierMasterAddState({
      ...supplierMasterAddState,
      currentPage: 1,
      filterSmcd: event.target.value
    });
  }

  const firstIndex = (supplierMasterAddState.currentPage - 1) * recordPerPage;
  const lastIndex = firstIndex + recordPerPage;
  const filteredRecords = filteredData.slice(firstIndex, lastIndex);
  const records = sup_mas.slice(firstIndex, lastIndex);


  function prePage() {
    if (supplierMasterAddState.currentPage > 1) {
      setSupplierMasterAddState({
        ...supplierMasterAddState,
        currentPage: supplierMasterAddState.currentPage - 1
      });
    }
  }

  function changeCpage(id) {
    setSupplierMasterAddState({
      ...supplierMasterAddState,
      currentPage: id
    });
  }
  function nextPage() {
    if (supplierMasterAddState.currentPage < npage) {
      setSupplierMasterAddState({
        ...supplierMasterAddState,
        currentPage: supplierMasterAddState.currentPage + 1
      });
    }
  }


  const transferValue2 = (e) => {
    setSupplierMasterAddState({
      ...supplierMasterAddState,
      isPaginationVisible: false,

      SupCde: e.Sup_code,
      SupNme: e.Sup_name,

      Flexy1: e.Sup_flex_01,
      Flexy2: e.Sup_flex_02,
      Flexy3: e.Sup_flex_03,
      Flexy4: e.Sup_flex_04,
    });
    setButtonPopup2(true);
  };

  const viewPagination = () => {
    setSupplierMasterAddState({
      ...supplierMasterAddState,
      isPaginationVisible: true
    });
  };


  const handleEdit = (e) => {
    setSupplierMasterAddState({
      ...supplierMasterAddState,
      isPaginationVisible: false,
      Id: e.Id,
      SupCde: e.Sup_code,
      SupNme: e.Sup_name,

      Flexy1: e.Sup_flex_01,
      Flexy2: e.Sup_flex_02,
      Flexy3: e.Sup_flex_03,
      Flexy4: e.Sup_flex_04,
    });
    setButtonPopup(true);
  };
  const handleUpdate = (e) => {
    e.preventDefault();

    if (!supplierMasterAddState.SupCde || !supplierMasterAddState.SupNme) {
      setIsCreating(true);

      alert(GeneralConstants.MANDATORY_ALERT);
      return;
    }

    const data = {
      data: [
        {
          Id: supplierMasterAddState.Id,
          Sup_code: supplierMasterAddState.SupCde,
          Sup_name: supplierMasterAddState.SupNme,

          Sup_flex_01: supplierMasterAddState.Flexy1,
          Sup_flex_02: supplierMasterAddState.Flexy2,
          Sup_flex_03: supplierMasterAddState.Flexy3,
          Sup_flex_04: supplierMasterAddState.Flexy4,
          // mc_active_status: supplierMasterAddState.SelectedOption,
        },
      ],
    };
    data["_keyword_"] = "supplier_details";
    data["secretkey"] = SECRET_KEY;
    ;
    getAPIRequest(
      PROCESS_TRANSACTION_UPDATE_URL, 'POST', data
    ).then((response) => {
      setSupplierMasterAddState({
        ...supplierMasterAddState,
        SelectedOption: "",
        SupCde: "",
        SupNme: "",
        Flexy1: "",
        Flexy2: "",
        Flexy3: "",
        Flexy4: "",
        isPaginationVisible: true,
      });
      // setsup_mas([...sup_mas, response.data]);
      alert(`Supplier master code ${GeneralConstants.UPDATE_SUCCESSFUL}`);
      // window.location.reload();
      setButtonPopup(false);
      handleReadSupMaster();

    })
      .catch((error) => {
        console.log(error);
        alert(GeneralConstants.UPDATE_FAILURE);
        window.location.reload();
      });
  };
  function handleReadSupMaster() {
    ReadRecord({
      _keyword_: "supplier_details"
    }).then((res) => {
      // console.log(res.data);
      setsup_mas(res.data);
    });
  }
  useEffect(() => {
    handleReadSupMaster();
  }, [])
  return (
    <div>
      <Popup
        trigger={ButtonPopup2}
        setTrigger={setButtonPopup2}
        viewPagination={viewPagination}
      >
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <form id="smv.view.container"
          name="smv.view.container" className="form-supplierMaster-View">
          <table
            id="smv.view.table"
            name="smv.view.table"
            style={{ textAlign: "center", width: "100%" }}
            cellPadding="5"
          >
            <tbody>
              <tr>
                <th id="smv.Title.View"
                  name="smv.Title.View"
                  height="20%"
                  colSpan="4"
                  style={{ textAlign: "center", whiteSpace: "nowrap" }}
                >
                  <font color="#8B0000">View - Supplier Master</font>
                </th>
              </tr>
              <tr>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Supplier-Code
                </th>
                <td
                  width="25%">
                  <label id="smv.view.customerCode"
                    name="smv.view.customerCode" >{supplierMasterAddState.SupCde}</label>
                </td>


                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Supplier-Name
                </th>
                <td
                  width="25%">
                  <label id="smv.view.supplierName"
                    name="smv.view.supplierName">{supplierMasterAddState.SupNme}</label>
                </td>
              </tr>
              <tr>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Flex 1
                </th>
                <td>
                  <label id="smv.view.supplierFlex1"
                    name="smv.view.supplierFlex1"
                    width="25%">{supplierMasterAddState.Flexy1}</label>
                </td>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Flex 2
                </th>
                <td>
                  <label id="smv.view.supplierFlex2"
                    name="smv.view.supplierFlex2"
                    width="25%">{supplierMasterAddState.Flexy2}</label>
                </td>
              </tr>

              <tr>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Flex 3
                </th>
                <td>
                  <label id="smv.view.supplierFlex3"
                    name="smv.view.supplierFlex3"
                    width="25%">{supplierMasterAddState.Flexy3}</label>
                </td>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Flex 4
                </th>
                <td>
                  <label id="smv.view.supplierFlex2"
                    name="smv.view.supplierFlex2"
                    width="25%">{supplierMasterAddState.Flexy4}</label>
                </td>

              </tr>



            </tbody>
          </table>
        </form>
      </Popup>
      <Popup
        trigger={ButtonPopup}
        setTrigger={setButtonPopup}
        viewPagination={viewPagination}
      >
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <form id="smv.edit.container"
          name="smv.edit.container"
          className="form-supplierMaster-Edit">
          <table
            style={{ textAlign: "center", width: "100%" }}
            cellPadding="4"
          >
            <tbody>
              <tr>
                <th
                  id="smv.Title.Edit"
                  name="smv.Title.Edit"
                  colSpan="4"
                  style={{ textAlign: "center", whiteSpace: "nowrap" }}
                >
                  <font color="#8B0000">Edit - Supplier Master</font>
                </th>
              </tr>
              <tr>
                <th>Supplier Code<span style={{ color: "red" }}>*</span></th>
                <td>
                  <input
                    id="smv.edit.supplierCode"
                    name="smv.edit.supplierCode"
                    type="text"
                    value={supplierMasterAddState.SupCde}
                    onChange={(e) => setSupplierMasterAddState({
                      ...supplierMasterAddState,
                      SupCde: e.target.value
                    })}
                    disabled
                  />
                </td>
                <th>Supplier Name<span style={{ color: "red" }}>*</span></th>
                <td>
                  {supplierMasterAddState.SupNme === '' && isCreating && <span className="Req">Required *</span>}
                  <input
                    id="smv.edit.supplierName"
                    name="smv.edit.supplierName"
                    type="text"
                    value={supplierMasterAddState.SupNme}
                    onChange={(e) => setSupplierMasterAddState({
                      ...supplierMasterAddState,
                      SupNme: e.target.value
                    })}
                    disabled
                  />
                </td>
              </tr>
              <tr>
                <th>Flex 1</th>
                <td>
                  <input
                    id="smv.edit.supplierFlex1"
                    name="smv.edit.supplierFlex1"
                    type="text"
                    value={supplierMasterAddState.Flexy1}
                    onChange={(e) => setSupplierMasterAddState({
                      ...supplierMasterAddState,
                      Flexy1: e.target.value
                    })}
                  />
                </td>
                <th>Flex 2</th>
                <td>
                  <input
                    id="smv.edit.supplierFlex2"
                    name="smv.edit.supplierFlex2"
                    type="text"
                    value={supplierMasterAddState.Flexy2}
                    onChange={(e) => setSupplierMasterAddState({
                      ...supplierMasterAddState,
                      Flexy2: e.target.value
                    })}
                  />
                </td>
              </tr>
              <tr>
                <th>Flex 3</th>
                <td>
                  <input
                    id="smv.edit.supplierFlex3"
                    name="smv.edit.supplierFlex3"
                    type="text"
                    value={supplierMasterAddState.Flexy3}
                    onChange={(e) => setSupplierMasterAddState({
                      ...supplierMasterAddState,
                      Flexy3: e.target.value
                    })}
                  />
                </td>
                <th>Flex 4</th>
                <td>
                  <input
                    id="smv.edit.supplierFlex4"
                    name="smv.edit.supplierFlex4"
                    type="text"
                    value={supplierMasterAddState.Flexy4}
                    onChange={(e) => setSupplierMasterAddState({
                      ...supplierMasterAddState,
                      Flexy4: e.target.value
                    })}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="4">
                  <button
                    id="smv.update" name="smv.update"
                    type="submit"
                    style={{ border: "none" }}
                    onClick={(e) => handleUpdate(e)}
                  >
                    Update
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </Popup>



      <div>
        <table
          style={{ textAlign: "center", width: "100%" }}
        /*{ cellPadding="5" }*/
        >
          <thead>
            <tr>
              <th
                id="smv.Title.List"
                name="smv.Title.List"
                colSpan="9"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">List - Supplier Master</font>
              </th>
            </tr>
            <tr>
              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                Supplier Name
              </td>
              <td>
                <input
                  id="smv.filter.smname"
                  name="smv.filter.smname"
                  type="text"
                  placeholder="Filter Supplier Name"
                  style={{ fontSize: "80%", width: "100%" }}
                  value={supplierMasterAddState.filterSmnm}
                  onChange={handleFilterSmNmChange}
                />
              </td>
              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                Supplier Code
              </td>
              <td>
                <input
                  id="smv.filter.smcode"
                  name="smv.filter.smcode"
                  type="text"
                  placeholder="Filter Supplier Code"
                  style={{ fontSize: "80%", width: "100%" }}
                  value={supplierMasterAddState.filterSmcd}
                  onChange={handleFilterSmCdChange}
                />
              </td>
              <td></td>
            </tr>
            <tr className="table_position">
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Supplier-Code&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Supplier-Name&nbsp;&nbsp;
              </th>

              {/* <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Flex-1&nbsp;&nbsp;
              </th> */}
              {/*
			  <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Flex-2&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Flex-3&nbsp;&nbsp;
              </th>*/}
              {/* <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Created Date&nbsp;&nbsp;
              </th> */}
              <th width="10%">

              </th>
              <th width="10%">

              </th>
              <th width="10%">

              </th>
            </tr>
          </thead>
          <tbody>
            {/* <HandleRead keyword="supplier_details" setData={setsup_mas} /> */}
            {filteredRecords.length === 0 ? (
              <td colSpan="12" style={{ textAlign: "center" }}>
                <p id="mmv.noRecordFound" name="mmv.noRecordFound" style={{ fontWeight: "bold" }}>No records found.</p>
              </td>
            ) : (
              filteredRecords.map((ele) => (
                <tr className="table_view" key={ele.Id}>
                  <td id="smv.list.upplierCode"
                    name="smv.list.SupplierCode" className="table-cell">&nbsp;&nbsp;{ele.Sup_code}</td>
                  <td id="smv.list.supplierName"
                    name="smv.list.supplierName" className="table-cell">&nbsp;&nbsp;{ele.Sup_name}</td>

                  {/* <td id="smv.list.flex1"
        name="smv.list.flex1"
        className="table-cell">&nbsp;&nbsp;{ele.Sup_flex_01}</td> */}
                  {/*
				<td className="table-cell">&nbsp;&nbsp;{mac_mas.mc_flex_02}</td>
                <td className="table-cell">&nbsp;&nbsp;{mac_mas.mc_flex_03}</td>
					*/}
                  {/* <td id="mmv.list.createddate"
        name="mmv.list.createddate"
        className="table-cell">&nbsp;&nbsp;{ele.mc_cr_dt}</td> */}
                  <td>
                    <button
                      id="smv.view" name="smv.view"
                      style={{ backgroundColor: "#cba423", whiteSpace: "nowrap" }}
                      type="button"
                      onClick={() => transferValue2(ele)}
                    >
                      View&#x1F441;
                    </button>
                  </td>
                  <td>
                    <button
                      id="smv.edit" name="smv.edit"

                      type="button" onClick={() => handleEdit(ele)}>
                      Edit&#x270E;
                    </button>
                  </td>
                  <td>
                    <button
                      id={`supplier_details-Delete`}
                      name={`supplier_details-Delete`}
                      style={{ whiteSpace: "nowrap", backgroundColor: "#DA251A" }}
                      type="button"
                      onClick={() => {
                        const userConfirmed = window.confirm('Are you sure you want to delete?');
                        if (userConfirmed) {
                          { console.log("ele.id", ele.Id) }

                          UpdateRecord({
                            _keyword_: 'supplier_details',
                            data: [
                              {
                                Id: ele.Id,
                                ActiveStatus: 'InActive',
                              },
                            ],
                          }).then(() => {
                            handleReadSupMaster();
                          })
                          // window.location.reload();
                        }
                      }}
                    >
                      Delete<MdDelete />
                    </button>
                  </td>
                </tr>
              )))}
          </tbody>
        </table>
        <div className="pagination" id="smv.pagination" name="smv.pagination">
          {supplierMasterAddState.isPaginationVisible && filteredData.length > 0 && (
            <Pagination currentPage={supplierMasterAddState.currentPage} npage={npage} prePage={prePage} nextPage={nextPage} changeCpage={changeCpage} numbers={numbers} />
          )}


        </div>
      </div>
    </div>
  );
}

export default SupplierMasterView;