import React, { useEffect, useState,useRef } from "react";
import axios from "axios";
import Popup from "../../../Components/Popup/Popup.js";
import { API_URL } from "../../../Utils/constants.js";
import Unitechlogo from "../../../icons/Unitechlogo.jpeg";
import Select from "react-select";
import Tooltip from "rc-tooltip";
import Pagination from "../../../Components/Pagination.js";
import DatePicker from "../../../Lib/DatePicker.js";
import { formatDt } from "../../../Lib/formatDt.js";
import "../../../Styles/MaterialLoading.css";
import { useNavigate, useParams } from "react-router-dom";
import DropdownFetcher from "../../../Lib/DropdownFetcher.js";
import DropdownFilterSelect from "../../../Components/DropdownFilterSelectFetcher.js";
import { FaBackspace } from 'react-icons/fa';
import { GetAccess } from "../../../Lib/GetAccess.js";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import { SECRET_KEY, PROCESS_TRANSACTION_UPDATE_URL } from "../../../Utils/constants.js";
import UpdateRecord from "../../../Lib/updateRecord.js";
import { MdDelete } from "react-icons/md";
import ReadRecord from "../../../Lib/API/ReadRecord.js";
const initialState = {
  TrackDate: new Date().toISOString().split("T")[0],
  MachineNo: "",
  PartName: "",
  Rmrks: "",
  shot_1: "",
  shot_2: "",
  shot_3: "",
  shot_4: "",
  shot_5: "",
  shot_6: "",
  shot_7: "",
  shot_8: "",
  shot_9: "",
  shot_10: "",
  shot_11: "",
  total1:"",
  total2:"",
  sign: "",
  verified: "",
  approved: "",
  isPaginationVisible: true,
  approved1: "Mr.RAJKUMAR",
  approved2: "Mr.RAJKUMAR",
  startUp: [],
  Id: ""
};

function MachineStartupView() {

  const [View, setView] = useState(initialState);
  var [machine_view, setmachine_view] = useState([]);
  const [partDropdown, setPartDropdown] = useState([]);
  const [dropdown, setDropdown] = useState([]);
  const [dropdown1, setDropdown1] = useState([]);
  const scrollTop = useRef(null);
  const [isCreating, setIsCreating] = useState(false);
  const [back, setback] = useState(false);
  const navigate = useNavigate();


  const handleDateChange = (newDate) => {
    setTrackDate(newDate);
  };


  const viewPagination = () => {
    setView({
      ...View,
      isPaginationVisible: true
    }); }

  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 20;
  const [ButtonPopup, setButtonPopup] = useState(false);
  const [Viewing, setViewing] = useState(false);
  const [filterDate, setFilterDate] = useState("");
  const [filterpartname, setfilterpartname] = useState("");
  const [filterMachine, setFilterMachine] = useState("");

  const filterRecords = () => {
      const filteredData = machine_view.filter((record) => {
      const dateMatch = record.Date && record.Date.includes(filterDate);
      const partnameMatch = record.Part_Name && record.Part_Name.toLowerCase().includes(filterpartname.toLowerCase());
      const machineMatch = record.Machine_No && record.Machine_No.toLowerCase().includes(filterMachine.toLowerCase());
    return dateMatch && partnameMatch && machineMatch;
    });
    return filteredData;
  };

  
  // const filterRecords = () => {
  //   console.log("LImb Dtat ", machine_view);
  //   const filteredData = machine_view.filter((record) => {
  //     const dateMatch = record.Date && record.Date.includes(filterDate);
  //     const partnameMatch = record.Machine_No && record.Machine_No.toLowerCase().includes(filterpartname.toLowerCase());
  //     return dateMatch && partnameMatch;
  //   });
  //   return filteredData;
  // };
  
  const filteredData = filterRecords();
  const npage = Math.ceil(filteredData.length / recordPerPage);
  const numbers = [...Array(npage).keys()].map((num) => num + 1);

  function handleFilterDateChange(event) {
    setFilterDate(event.target.value);
    setCurrentPage(1);
  }

  function handlefilterpartnameChange(event) {
    setfilterpartname(event.target.value);
    setCurrentPage(1);
  }
  function handleFilterMachineChange(event) {
    setFilterMachine(event.target.value);
    setCurrentPage(1);
  }

  const firstIndex = (currentPage - 1) * recordPerPage;
  const lastIndex = firstIndex + recordPerPage;
  const filteredRecords = filteredData.slice(firstIndex, lastIndex);
  function prePage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function nextPage() {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function changeCpage(Id) {
    setCurrentPage(Id);
  }
  const handleView = (e) => {
    setView({
      ...View,
      isPaginationVisible: false,
       Id: e.Id,
       Date: e.Date,
      MachineNo: e.Machine_No,
      PartName: e.Part_Name,
      Material: e.Material,
      shot_1: e.Shot_1,
      shot_2: e.Shot_2,
      shot_3: e.Shot_3,
      shot_4: e.Shot_4,
      shot_5: e.Shot_5,
      shot_6: e.Shot_6,
      shot_7: e.Shot_7,
      shot_8: e.Shot_8,
      shot_9: e.Shot_9,
      shot_10: e.Shot_10,
      shot_11: e.Shot_11,
      total1: e.Total1,
      total2: e.Total2,
      sign:e.Sign,
      sign_name:e.Sign_name,
      approved:e.Approved,
      verified:e.Verified,
      approved1:e.Approved1,
      approved2:e.Approved2,
      Reasn: e.Reason,
      Rmrks: e.Remarks,
    });
    setButtonPopup(true);
    setViewing(true);
  };
  const handleEdit = (e) => {
    setView({
      ...View,
      isPaginationVisible: false,
       Id: e.Id,
       Date: e.Date,
      MachineNo: e.Machine_No,
      PartName: e.Part_Name,
      Material: e.Material,
      shot_1: e.Shot_1,
      shot_2: e.Shot_2,
      shot_3: e.Shot_3,
      shot_4: e.Shot_4,
      shot_5: e.Shot_5,
      shot_6: e.Shot_6,
      shot_7: e.Shot_7,
      shot_8: e.Shot_8,
      shot_9: e.Shot_9,
      shot_10: e.Shot_10,
      shot_11: e.Shot_11,
      total1: e.Total1,
      total2: e.Total2,
      sign:e.Sign,
      approved:e.Approved,
      verified:e.Verified,
      approved1:e.Approved1,
      approved2:e.Approved2,
      Reasn: e.Reason,
      Rmrks: e.Remarks,
    });
    setButtonPopup(true);
    setViewing(false);
  };
  const handleUpdate = (e) => {
    e.preventDefault();   
 
    if ( Create.approved_name || Create.verified_name) {
      setIsCreating(true);
      if (scrollTop.current) {
        scrollTop.current.scrollIntoView({ behavior: "smooth" });
      }
      alert(GeneralConstants.MANDATORY_ALERT);
      return;
    }
    const data = {
      data: [
        {
          Id: View.Id,
          Date: View.TrackDate,
          Machine_No: View.MachineNo,
          Part_Name: View.PartName,
          Remarks: View.Rmrks,
          Shot_1: View.shot_1,
          Shot_2: View.shot_2,
          Shot_3: View.shot_3,
          Shot_4: View.shot_4,
          Shot_5: View.shot_5,
          Shot_6: View.shot_6,
          Shot_7: View.shot_7,
          Shot_8: View.shot_8,
          Shot_9: View.shot_9,
          Shot_10: View.shot_10,
          Shot_11: View.shot_11,
          Sign: View.sign,
          Total1: View.total1,
          Total2: View.total2,
          Verified: View.verified,
          Approved: View.approved,
          approved1: View.approved1,
          approved2: View.approved2,
        },
      ],
    };
    
    data["_keyword_"] = "daily_machine_startup";
    data["secretkey"] = SECRET_KEY;
  
    getAPIRequest(PROCESS_TRANSACTION_UPDATE_URL, 'POST', data)
      .then((response) => {
        console.log(response);
        setView({
          ...View,
          isPaginationVisible: true
        });
        alert(`${GeneralConstants.UPDATE_SUCCESSFUL}`);
        setButtonPopup(false);
        // window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        alert(GeneralConstants.UPDATE_FAILURE);
        // window.location.reload();
      });
  };
  
     
    
      const mcNoOptions = dropdown.map((i) => ({
      value: i.mc_code,
     label: i.mc_name,
       }));

      const partNoOptions = partDropdown.map((i) => ({
      value: i.mc_part_name,
       label: i.mc_part_name,
         }));
      const empAllOptions = dropdown1.map((i) => ({
      label: i.Emp_name,
       value: i.Emp_name,
          }));


  function handleReadMachineStartup() {
    ReadRecord({
      _keyword_: "daily_machine_startup"
    }).then((res) => {
       console.log(res.data);
     // setView(res.data);
      setmachine_view(res.data);
    });
 
  }
  useEffect(() => {
    handleReadMachineStartup();
  }, [])

  return (
    <div>
      <Popup
        trigger={ButtonPopup}
        setTrigger={setButtonPopup}
        viewPagination={viewPagination}
      >
        <br></br>
        <br></br>
        <form >     
              <table className="table" style={{marginLeft:"5%", textAlign: "center", width: "90%" }}>
            <tbody>
             
          
              <tr>
                <th style={{ textAlign: "center" }} colSpan={5}>
                <br />
                  <font color="#8B0000">Daily Machine StartUp and ReStartUp</font>
                </th>
                <th colspan="1">
                   FORMAT NO: UPCL/QC/R/23
                   <br />
                   REV NO: 01
                   <br />
                   REV DATE: 28-12-2019
                 </th>
                </tr>
              <tr>
              <td className="topic-head">
                &nbsp;&nbsp;Machine No<span style={{ color: "red" }}>*</span>
              </td>
               <td>
                <DropdownFetcher
                  keyword="mac_dtl_dropdown"
                  setValue={setDropdown}
                 
                />  
                <DropdownFilterSelect value={View.MachineNo}  onChange={(e) =>
                  setView({
                    ...View,
                    MachineNo: e.value,
                  })
                } 
                
                options={mcNoOptions} placeholder="Select Machine" />
                {View.MachineNo === '' && isCreating && <span className="Req">Required *</span>}

              </td>
                
                <td className="topic-head">&nbsp;&nbsp;Date</td>
                <td>
                <DatePicker
                  value={View.TrackDate}
                  onChange={handleDateChange}
                  isDisabled={Viewing}
                />
               </td>
               <td className="topic-head">
                  Part Name<span style={{ color: "red" }}>*</span>
                </td>
                <td>
                <DropdownFetcher keyword="mac_part_dtl" setValue={setPartDropdown} />
               
               <DropdownFilterSelect value={View.PartName} isDisabled={Viewing===true}onChange={(e) =>
                  setView({
                    ...View,
                    PartName: e.value,
                  })
                } options={partNoOptions} placeholder="Select Part name" />
              
                </td>
              </tr>

              <tr>
             

                <td className="topic-head">&nbsp;&nbsp;Shot No 1</td>
                <td>
                <input
                    type="text"
                    value={View.shot_1}
                    disabled={Viewing}
                    onChange={(e) =>
                      setView({...View,
                        shot_1: e.target.value })}/>
                </td>
                <td className="topic-head">&nbsp;&nbsp;Shot No 2</td>
                <td>
                <input
                    type="text"
                    value={View.shot_2}
                    disabled={Viewing}
                    onChange={(e) =>
                      setView({...View,
                        shot_2: e.target.value })}/>
                </td>
                <td className="topic-head">&nbsp;&nbsp;Shot No 3</td>
                <td>
                <input
                    type="text"
                    value={View.shot_3}
                    disabled={Viewing}
                    onChange={(e) =>
                      setView({...View,
                        shot_3: e.target.value })}/>
                </td>
                </tr>
                <tr>
                <td className="topic-head">&nbsp;&nbsp;Shot No 4</td>
                <td>
                <input
                    type="text"
                    value={View.shot_4}
                    disabled={Viewing}
                    onChange={(e) =>
                      setView({...View,
                        shot_4: e.target.value })}/>
                </td>
                <td className="topic-head">&nbsp;&nbsp;Shot No 5</td>
                <td>
                <input
                    type="text"
                    value={View.shot_5}
                    disabled={Viewing}
                    onChange={(e) =>
                      setView({...View,
                        shot_5: e.target.value })}/>
                </td>
           
              <td className="topic-head">&nbsp;&nbsp;Shot No 6</td>
                <td>
                <input
                    type="text"
                    value={View.shot_6}
                    disabled={Viewing}
                    onChange={(e) =>
                      setView({...View,
                        shot_6: e.target.value })}/>
                </td>
                </tr>
                <tr>
                <td className="topic-head">&nbsp;&nbsp;Shot No 7</td>
                <td>
                <input
                    type="text"
                    value={View.shot_7}
                    disabled={Viewing}
                    onChange={(e) =>
                      setView({...View,
                        shot_7: e.target.value })}/>
                </td>
                <td className="topic-head">&nbsp;&nbsp;Shot No 8</td>
                <td>
                <input
                    type="text"
                    value={View.shot_8}
                    disabled={Viewing}
                    onChange={(e) =>
                      setView({...View,
                        shot_8: e.target.value })}/>
                </td>
          
             
                <td className="topic-head">&nbsp;&nbsp;Shot No 9</td>
                <td>
                <input
                    type="text"
                    value={View.shot_9}
                    disabled={Viewing}
                    onChange={(e) =>
                      setView({...View,
                        shot_9: e.target.value })}/>
                </td>
              </tr>
              <tr>
              <td className="topic-head">&nbsp;&nbsp;Shot No 10</td>
                <td>
                <input
                    type="text"
                    value={View.shot_10}
                    disabled={Viewing}
                    onChange={(e) =>
                      setView({...View,
                        shot_10: e.target.value })}/>
                </td>

                <td className="topic-head">&nbsp;&nbsp;Shot No 11</td>
                <td>
                <input
                    type="text"
                    value={View.shot_11}
                    disabled={Viewing}
                    onChange={(e) =>
                      setView({...View,
                        shot_11: e.target.value })}/>
                 </td>
                <td className="topic-head">&nbsp;&nbsp;Total</td>
                <td>
                <input
                
                    type="text"
                    value={View.total1}
                    disabled={Viewing}
                    onChange={(e) =>
                      setView({
                        ...View,
                        total1: e.target.value,
                      })}  
                  />
                </td>
               
                      </tr>
                   <tr>
                   <td className="topic-head">&nbsp;&nbsp;Total</td>
                <td>
                <input
                
                    type="text"
                    value={View.total2}
                    disabled={Viewing}
                    onChange={(e) =>
                      setView({
                        ...View,
                        total2: e.target.value,
                      })}  
                  />
                </td>
                  <td className="topic-head">&nbsp;&nbsp;Remarks</td>
                <td>
                <input
                
                    type="text"
                    value={View.Rmrks}
                    disabled={Viewing}
                    onChange={(e) =>
                      setView({
                        ...View,
                        Rmrks: e.target.value,
                      })}  
                  />
                </td>
                <td className="topic-head">&nbsp;&nbsp;Signature</td>
                <td>
                <DropdownFetcher
                    keyword="emp_details_dropdown_all"
                    setValue={setDropdown1}
                  />
                  <DropdownFilterSelect
                     value={View.sign}
                     isDisabled={Viewing===true}
                   
                    onChange={(e) =>
                      setView({
                        ...View,
                        sign:  e.value,
                      })
                    }
                    options={empAllOptions}
                    placeholder="Sign"
                    
                  />
                
                </td>
               </tr>
               {/* <tr>
                <td colSpan={1} className="topic-head">Remarks:</td>
                <td colSpan={5}></td>
               </tr> */}
               <tr>
                <td colSpan={1} className="topic-head">Notes:</td>
                <td colSpan={5}>
                <span>Each and every Machine Restart up condition need to fill the format(Power cut,Mould loading,Nozzle blockage)</span></td>
               </tr>
               <tr>
  <td colSpan={6} style={{ whiteSpace: 'nowrap' }}>
    <span style={{ borderRight: '2px solid black', padding: '0 15px',marginLeft:"5%" }}>A: SHORT FILL </span>
    <span style={{ borderRight: '2px solid black', padding: '0 15px',marginLeft:"5%"  }}>B: STREAKERS </span>
    <span style={{ borderRight: '2px solid black', padding: '0 15px',marginLeft:"5%"  }}>C: SHRINKAGE </span>
    <span style={{ borderRight: '2px solid black', padding: '0 15px',marginLeft:"5%"  }}>D: WEILD LINE </span>
    <span style={{ borderRight: '2px solid black', padding: '0 15px',marginLeft:"5%"  }}>E: FLOW MARK </span>
    <span style={{ borderRight: '2px solid black', padding: '0 15px',marginLeft:"5%"  }}>F: FLASH </span>
    <span style={{ padding: '0 15px' ,marginLeft:"5%" }}>G: DENT MARK</span>
  </td>
</tr>
<tr>
  <td colSpan={6} style={{ whiteSpace: 'nowrap' }}>
    <span style={{ borderRight: '2px solid black', padding: '0 15px',marginLeft:"0%" }}>H: OIL MARK&WAX MARK</span>
    <span style={{ borderRight: '2px solid black', padding: '0 15px',marginLeft:"2%"  }}>I: MATERIAL MIXUP </span>
    <span style={{ borderRight: '2px solid black', padding: '0 15px',marginLeft:"5%"  }}>J: BLACK SHADE</span>
    <span style={{ borderRight: '2px solid black', padding: '0 15px',marginLeft:"2%"  }}>K: INSERT  BROCKEN </span>
    <span style={{ borderRight: '2px solid black', padding: '0 15px',marginLeft:"5%"  }}> L: BURN  MARK </span>
    <span style={{ borderRight: '2px solid black', padding: '0 15px',marginLeft:"4%"  }}> M: SCORING</span>
    <span style={{ padding: '0 15px' ,marginLeft:"3%" }}>N: SHANING MARK</span>
  </td>
</tr>
                  <tr>
                    <td colSpan={1} className="topic-head">
                       VERIFIED
                    </td>
                     <td colSpan={2}>
                     <DropdownFetcher
                    keyword="emp_details_dropdown_all"
                    setValue={setDropdown1}
                  />
                  <DropdownFilterSelect
                    value={View.verified}
                    isDisabled={Viewing===true}
                    onChange={(e) =>
                      setView({
                        ...View,
                        verified: e.value,
                      })
                    }
                    options={empAllOptions}
                    placeholder="Verified By"
                  />
                        </td>
                        <td colSpan={1} className="topic-head">
                      APPROVED
                    </td>
                    <td colSpan={2}>
                    <DropdownFetcher
                    keyword="emp_details_dropdown_all"
                    setValue={setDropdown1}
                  />
                  <DropdownFilterSelect
                    value={View.approved}
                    isDisabled={Viewing===true}
                    onChange={(e) =>
                      setView({
                        ...View,
                        approved: e.value,
                      })
                    }
                    options={empAllOptions}
                    placeholder="Approved By"
                    
                  /></td>
                  </tr>
               <tr>
               <td>sl.no</td>
               <td>Rev.no</td>
               <td colSpan={3} style={{textAlign:"center"}}>Revision Details</td>
               <td>Approved</td>
            </tr>
            <tr>
               <td>1</td>
               <td>0</td>
               <td colSpan={3} style={{textAlign:"center"}}>initial relase</td>
               <td>  <DropdownFetcher
                    keyword="emp_details_dropdown_all"
                    setValue={setDropdown1}
                  />
                  <DropdownFilterSelect
                    value={View.approved1}
                    isDisabled={Viewing===true}
                    onChange={(e) =>
                      setView({
                        ...View,
                        approved1: e.value,
                      })
                    }
                    options={empAllOptions}
                    placeholder="Approved"
                    
                  /></td>
            </tr>
            <tr>
               <td>2</td>
               <td>1</td>
               <td colSpan={3} style={{textAlign:"center"}}>ReStartUp time added</td>
               <td>  <DropdownFetcher
                    keyword="emp_details_dropdown_all"
                    setValue={setDropdown1}
                  />
                  <DropdownFilterSelect
                    value={View.approved2}
                    isDisabled={Viewing===true}
                    onChange={(e) =>
                      setView({
                        ...View,
                        approved2: e.value,
                      })
                    }
                    options={empAllOptions}
                    placeholder="Approved"
                    
                  /></td>
            </tr>
            <tr>
              <td colSpan={6} style={{textAlign:"center"}}>
              NOTE: If within 10 Shots part not meet Master Sample Requirement then given Re _First parts approval to us.
              </td>
            </tr>
              <tr>
                <td colSpan={6} >
                <button
                    type="submit"
                    onClick={(e) => handleUpdate(e)}
                  >
                    Update
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </Popup>

      <div>
        <table
          style={{ textAlign: "center", width: "100%" }}
        /*{ cellPadding="5" }*/
        >
          <thead>
            <tr>
              <th
                id="msr-Title-List"
                name="msr-Title-List"
                colSpan="17"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">List - Daily Machine StartUp and ReStartUp</font>
              </th>
            </tr>
            <tr>
              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>Date</td>
              <td colSpan="1">
                <input
                  id="msr-filter-date"
                  name="msr-filter-date"
                  type="date"
                  placeholder="Filter by Date"
                  value={filterDate}
                  onChange={handleFilterDateChange}
                />
              </td>
              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                Machine No

              </td>
              <td colSpan={2}>
                <input
                  id="msr-filter-MachineNo"
                  name="msr-filter-MachineNo"
                  type="text"
                  placeholder="Filter MachineNo"
                  style={{ fontSize: "70%", width: "100%" }}
                  value={filterMachine}
                  onChange={handleFilterMachineChange}
                />
              </td>
              <td colSpan={2}>
                Part Name
              </td>
              <td colSpan={2}>
                <input
                  id="msr-filter-PartName"
                  name="msr-filter-PartName"
                  type="text"
                  placeholder="Filter PartName"
                  style={{ fontSize: "70%", width: "100%" }}
                  value={filterpartname}
                  onChange={handlefilterpartnameChange}
                />
              </td>
              <td colSpan="8"></td>
            </tr>
            <tr className="table_position">
              <th className="table-cell2">
                Date
              </th>
              <th className="table-cell2">
                Machine No
              </th>
              <th className="table-cell2">
               Part Name
              </th>
              <th style={{whiteSpace:"nowrap"}}>Shot_1</th>
              <th style={{whiteSpace:"nowrap"}}>Shot_2</th>
              <th style={{whiteSpace:"nowrap"}}>Shot_3</th>
              <th style={{whiteSpace:"nowrap"}}>Shot_4</th>
              <th style={{whiteSpace:"nowrap"}}>Shot_5</th>
              <th>Shot_6</th>
              <th>Shot_7</th>
              <th>Shot_8</th>
              <th>Shot_9</th>
              <th>Shot_10</th>
              <th>Shot_11</th>
              <th></th>
              {(GetAccess('production', 'hod') || GetAccess("ADMIN", "GM")) ? <th></th> : ''}

              {(GetAccess('production', 'hod') || GetAccess("ADMIN", "GM")) ? <th></th> : ''}
            </tr>
          </thead>
          <tbody>

                  {filteredRecords.length === 0 ? (
              // <tr>
              <td colSpan="17" style={{ textAlign: "center" }}>
                <p
                  id="msr-list-NoRec"
                  name="msr-list-noRec"
                  style={{ fontWeight: "bold" }}
                >
                  No records found.
                </p>
              </td>
            ) : ( 
              filteredRecords.map((View) => (
                <tr className="table_view" key={View.Id}>
                  <td
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{formatDt(View.Date)}
                  </td>
                  <td
                    id="msr-list-Machine"
                    name="msr-list-Machine"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{View.Machine_No}
                  </td>
                  <td
                    id="msr-list-Shift"
                    name="msr-list-Shift"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{View.Part_Name}
                  </td>
                  <td
                    id="msr-list-s1"
                    name="msr-list-s1"
                    className="table-cell"
                    style={{whiteSpace:"inherit"}}
                  >
                    &nbsp;&nbsp;{View.Shot_1}
                  </td>
                  <td
                    id="msr-list-s2"
                    name="msr-list-s2"
                    className="table-cell"
                    style={{whiteSpace:"inherit"}}
                  >
                    &nbsp;&nbsp;{View.Shot_2}
                    </td>
                    <td
                    id="msr-list-s3"
                    name="msr-list-s3"
                    className="table-cell"
                    style={{whiteSpace:"inherit"}}
                  >
                    &nbsp;&nbsp;{View.Shot_3}
                  </td>
                  <td
                    id="msr-list-s4"
                    name="msr-list-s4"
                    className="table-cell"
                    style={{whiteSpace:"inherit"}}
                  >
                    &nbsp;&nbsp;{View.Shot_4}
                  </td>
                  <td
                    id="msr-list-s5"
                    name="msr-list-s5"
                    className="table-cell"
                    style={{whiteSpace:"inherit"}}
                  >
                    &nbsp;&nbsp;{View.Shot_5}
                  </td>
                  <td
                    id="msr-list-s6"
                    name="msr-list-s6"
                    className="table-cell"
                    style={{whiteSpace:"inherit"}}
                  >
                    &nbsp;&nbsp;{View.Shot_6}
                  </td>
                  <td
                    id="msr-list-s7"
                    name="msr-list-s7"
                    className="table-cell"
                    style={{whiteSpace:"inherit"}}
                  >
                    &nbsp;&nbsp;{View.Shot_7}
                  </td>
                 
                  <td
                    id="msr-list-s8"
                    name="msr-list-s8"
                    className="table-cell"
                    style={{whiteSpace:"inherit"}}
                  >
                    &nbsp;&nbsp;{View.Shot_8}
                  </td>
                  <td
                    id="msr-list-s9"
                    name="msr-list-s9"
                    className="table-cell"
                    style={{whiteSpace:"inherit"}}
                  >
                    &nbsp;&nbsp;{View.Shot_9}
                  </td>
                  <td
                    id="msr-list-s10"
                    name="msr-list-s10"
                    className="table-cell"
                    style={{whiteSpace:"inherit"}}
                  >
                    &nbsp;&nbsp;{View.Shot_10}
                  </td>
                  <td
                    id="msr-list-s11"
                    name="msr-list-s11"
                    className="table-cell"
                    style={{whiteSpace:"inherit"}}
                  >
                    &nbsp;&nbsp;{View.Shot_11}
                  </td>
                  <td>
                    <button
                      id="msr-view"
                      name="msr-view"
                      style={{ backgroundColor: "#cba423", width: "55px" }}
                      type="button"
                      onClick={() => handleView(View)}
                    >
                      View&#x1F441;
                    </button>
                  </td>
                  {(GetAccess('production', 'hod') || GetAccess("ADMIN", "GM")) ? <td>
                    <button
                      id="msr-edit"
                      name="msr-edit"
                      type="button"
                      style={{ width: "55px" }}
                      onClick={() => handleEdit(View)}
                    >
                      Edit&#x270E;
                    </button>
                  </td> : ''}
                  {(GetAccess('production', 'hod') || GetAccess("ADMIN", "GM")) ?
                    <td>
                      <button
                        id={`View-Delete`}
                        name={`View-Delete`}
                        style={{ whiteSpace: "nowrap", backgroundColor: "#DA251A", width: "60px" }}
                        type="button"
                        onClick={() => {
                          const userConfirmed = window.confirm('Are you sure you want to delete?');
                          if (userConfirmed) {
                            UpdateRecord({
                              _keyword_: 'material_loading',
                              data: [
                                {
                                  Id: View.Id,
                                  Mat_active_status: 'InActive',
                                },
                              ],
                            }).then(() => {
                              handleReadMachineStartup();
                            })
                            // window.location.reload();
                          }
                        }}
                      >
                        Delete<MdDelete />
                      </button>
                    </td> : ''}
                </tr>
              ))
            )}
          </tbody>
        </table>
        <div className="pagination" id="pagination">
          {View.isPaginationVisible && filteredData.length > 0 && (

            <Pagination
              currentPage={currentPage}
              npage={npage}
              prePage={prePage}
              nextPage={nextPage}
              changeCpage={changeCpage}
              numbers={numbers}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MachineStartupView;
