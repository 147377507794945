import React from "react";
import QaLayoutPaint from "../../QaLayoutPaint";
import ListViewPaint from "../../../../Components/ListView/ListViewPaint";

const FirstandlastoffList = () => {
  return (
    <QaLayoutPaint quary={"FirstandLastoff"}>
      <div>
        <ListViewPaint
          Title={"First and Last Off"}
          standard={["delete_id=0"]}
          quary={
            "SELECT Id,CreatedDate,part_name,insp_type,part_number,model FROM paint_firstoff_and_lastoff group by Id ORDER BY Id desc"
          }
          table={"paint_firstoff_and_lastoff"}
          tableDelete={"paint_firstandlast_off"}
          navigation={"FirstandLastoff"}
          filter={["Id", "part_name","insp_type", "part_number", "model"]}
          dateFilter={"CreatedDate"}
          header={[
            { value: "Id", label: "ID" },
            { value: "CreatedDate", label: "Date" },
            { value: "part_name", label: "Part Name" },
            { value: "part_number", label: "Part Number" },
            { value: "model", label: "Model" },
            { value: "insp_type", label: "Type" },
          ]}
        />
      </div>
    </QaLayoutPaint>
  );
};

export default FirstandlastoffList;
