import React, { useState, useEffect } from "react";
import { useRef } from "react";

import Popup from "../../../Components/Popup/Popup";
import "../../../Styles/lumpsmon.css";
//import "bootstrap/dist/css/bootstrap.min.css";

import Unitechlogo from "../../../icons/Unitechlogo.jpeg";
import Select from "react-select";
import { SECRET_KEY, PROCESS_TRANSACTION_UPDATE_URL } from "../../../Utils/constants.js";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import { getAPIRequest } from "../../../Utils/APIUtility.js";

import HandleRead from "../../../Lib/HandleRead.js";
import Pagination from "../../../Components/Pagination";
import DatePicker from "../../../Lib/DatePicker.js";
import { formatDt } from "../../../Lib/formatDt";
import DropdownFetcher from "../../../Lib/DropdownFetcher.js";
import DropdownFilterSelect from "../../../Components/DropdownFilterSelectFetcher.js";
import UpdateRecord from "../../../Lib/updateRecord";
import { MdDelete } from "react-icons/md";
import ReadRecord from "../../../Lib/API/ReadRecord.js";

const initialState = {
  TrackDate: "",
  MachineNo: "",
  PartName: "",
  Material: "",
  LumpKg: "",
  LumpRegKg: "",
  Reasn: "",
  Rmrks: "",
  inchrg:"",
  inchrgname:"",
  isPaginationVisible: true,
  currentPage: 1,
  filterMcno: "",
  filterPartNm: "",
  filterBay: "",
  filterDate: "",
  Shft:"",
  Id: ""
};
function LUMPViewTab() {
  const [LumpCreate, setLumpCreate] = useState(initialState);
  var [lumps_mon, setlumps_mon] = useState([]);
  const [Name, setname] = useState([]);
  const [partDropdown, setPartDropdown] = useState([]);
  const [dropdown, setDropdown] = useState([]);
  const [Rmdropdown, setRmDropdown] = useState([]);
  const [selectedOption, setSelectedOption] = useState(""); 

  const idView = "lumpsmon-View";
  const nameView = "lumpsmon-View";
  const nameEdit = "lumpsmon-Edit";
  const idEdit = "lumpsmon-Edit";
  const nameList = "lumpsmon-List";
  const idList = "lumpsmon-List";

  let componentref = useRef();

  // const [Month, setMonth] = useState("");
  // const [Sno, setSno] = useState("");
  // const [Date, setDate] = useState("");
  // const [MC, setMC] = useState("");
  // const [PartName, setPartName] = useState("");
  // const [Mat, setMat] = useState("");
  // const [LumpKg, setLumpKg] = useState("");
  // const [Reasn, setReasn] = useState("");
  // const [Rmrks, setRmrks] = useState("");
  // const [Crdate, setCrDate] = useState("");

  // var [lumps_mon, setlumps_mon] = useState([]);


  const [isCreating, setIsCreating] = useState(false);


  const [ButtonPopup, setButtonPopup] = useState(false);

  const [ButtonPopup2, setButtonPopup2] = useState(false);

  const recordPerPage = 20;


  const filterRecords = () => {
    console.log("Lumb Data ", lumps_mon);
      const filteredData = lumps_mon.filter((record) => {
      const dateMatch = record.TrackDate && record.TrackDate.includes(LumpCreate.filterDate);
      const mcnoMatch = record.Machine_no
        .toLowerCase()
        .includes(LumpCreate.filterMcno.toLowerCase());
        const PartnameMatch = record.Part_name
        .toLowerCase()
        .includes(LumpCreate.filterPartNm.toLowerCase());
        const BayMatch = record.MacBay
        .toLowerCase()
        .includes(LumpCreate.filterBay.toLowerCase());
         return dateMatch && mcnoMatch && PartnameMatch && BayMatch;
         });
        return filteredData || [];
          };
  const filteredData = filterRecords();
  const npage = Math.ceil(filteredData.length / recordPerPage);
  const numbers = [...Array(npage).keys()].map((num) => num + 1);

  function handleFilterMcnoChange(event) {
    setLumpCreate({
      ...LumpCreate,
      currentPage: 1,
      filterMcno: event.target.value
    });
  }
  function handleFilterPartNmChange(event) {
    setLumpCreate({
      ...LumpCreate,
      currentPage: 1,
      filterPartNm: event.target.value
    });
  }
  function handleFilterbayChange(event) {
    setLumpCreate({
      ...LumpCreate,
      currentPage: 1,
      filterBay: event.target.value
    });
  }
  function handleFilterDateChange(event) {
    setLumpCreate({
      ...LumpCreate,
      currentPage: 1,
      filterDate: event.target.value
    });
  }
  const firstIndex = (LumpCreate.currentPage - 1) * recordPerPage;
  const lastIndex = firstIndex + recordPerPage;
  const filteredRecords = filteredData.slice(firstIndex, lastIndex);

  function prePage() {
    if (LumpCreate.currentPage > 1) {
      setLumpCreate({
        ...LumpCreate,
        currentPage: LumpCreate.currentPage - 1
      });
    }
  }

  function nextPage() {
    if (LumpCreate.currentPage < npage) {
      setLumpCreate({
        ...LumpCreate,
        currentPage: LumpCreate.currentPage + 1
      });
    }
  }

  function changeCpage(id) {
    setLumpCreate({
      ...LumpCreate,
      currentPage: id
    });
  }
  const viewPagination = () => {
    setLumpCreate({
      ...LumpCreate,
      isPaginationVisible: true
    });

  }
  function GetSelectValue(value, keyword, setName) {
    // console.log("hai");
    console.log("Request number ", value);
    if (value.length > 0) {
      ReadRecord({
        _keyword_: keyword,
        key: "%" + value + "%",
        value: "%" + value + "%"
      }).then((res) => {
        console.log(res.data);
        setName(res.data);
        // setKey(keyvalue[options.reference]);
      });
    }
  }
  const handleView = (e) => {
    setLumpCreate({
      ...LumpCreate,
      isPaginationVisible: false,
      TrackDate: e.TrackDate,
      Shft: e.Shift,
      MachineNo: e.Machine_no,
      PartName: e.Part_name,
      Material: e.Material,
      LumpKg: e.Lumps_kg,
      LumpRegKg: e.Lumps_reg_kg,
      Reasn: e.Reason,
      Rmrks: e.Remarks,
      inchrg: e.Incharge_Sign,
      inchrgname: e.Incharge_Sign_name
    });
    setButtonPopup2(true);
  };

  const handleEdit = (e) => {
    setLumpCreate({
      ...LumpCreate,
      isPaginationVisible: false,

      Id: e.Id,
      TrackDate: e.TrackDate,
      MachineNo: e.Machine_no,
      Shft: e.Shift,
      PartName: e.Part_name,
      Material: e.Material,
      LumpKg: e.Lumps_kg,
      LumpRegKg: e.Lumps_reg_kg,
      Reasn: e.Reason,
      Rmrks: e.Remarks,
      inchrg: e.Incharge_Sign,
      inchrgname: e.Incharge_Sign_name
    });
    setButtonPopup(true);
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    if (!LumpCreate.PartName || !LumpCreate.MachineNo || !LumpCreate.Material) {
      setIsCreating(true);
      alert(GeneralConstants.MANDATORY_ALERT);
      return;
    }
    const data = {
      data: [
        {
          Id: LumpCreate.Id,
          TrackDate: LumpCreate.TrackDate,
          Machine_no: LumpCreate.MachineNo,
          // Part_name: LumpCreate.PartName,
          Material: LumpCreate.Material,
          Lumps_kg: LumpCreate.LumpKg,
          Lumps_reg_kg: LumpCreate.LumpRegKg,
          Reason: LumpCreate.Reasn,
          Remarks: LumpCreate.Rmrks,
          Incharge_Sign: LumpCreate.inchrg,
          Incharge_Sign_name: LumpCreate.inchrgname
        },
      ],
    };
    data["_keyword_"] = "LumpsMon";
    data["secretkey"] = SECRET_KEY;
    ;
    getAPIRequest(
      PROCESS_TRANSACTION_UPDATE_URL, 'POST', data
    ).then((response) => {
      setLumpCreate({
        ...LumpCreate,
        isPaginationVisible: true
      });
      // setlumps_mon([...lumps_mon, response.data]);
      alert(`${GeneralConstants.UPDATE_SUCCESSFUL}`);
      // window.location.reload();
      setButtonPopup(false);
      handleReadLumpSheet();

    })
      .catch((error) => {
        console.log(error);
        alert(GeneralConstants.UPDATE_FAILURE);
        window.location.reload();
      });
  };
  const partNoOptions = partDropdown.map((i) => ({
    value: i.mc_part_code,
    label: i.mc_part_name,
  }));
  const mcNoOptions = dropdown.map((i) => ({
    value: i.mc_code,
    label: i.mc_name,
  }));
  const rmOptions = Rmdropdown.map((i) => ({
    value: i.Rm_code,
    label: i.Rm_code,
  }));
  const handleDateChange = (newDate) => {
    setLumpCreate({ ...LumpCreate, TrackDate: newDate });
  };
  function handleReadLumpSheet() {
    ReadRecord({
      _keyword_: "Lumpsmon"
    }).then((res) => {
       console.log(res.data);
      setlumps_mon(res.data);
    });
  }
  useEffect(() => {
    handleReadLumpSheet();
  }, [])
  const handleIncharge = (selectedOption) => {
    setLumpCreate({
      ...LumpCreate,
      selected: selectedOption,
      inchrg: selectedOption ? selectedOption.value : "",
      inchrgname: selectedOption.label
    });
  };
  
  return (
    <div>
      <Popup
        trigger={ButtonPopup2}
        setTrigger={setButtonPopup2}
        viewPagination={viewPagination}

      >

        <br></br>
        <form
          id="lumpsmon-forMachineNoontainer-View"
          name="lumpsmon-forMachineNoontainer-View"
          className="form-lumpsmon-View"
          ref={(element) => {
            componentref = element;
          }}
        >
          <table style={{ textAlign: "center" }}>
            <tbody>
              <tr>
                <th style={{ textAlign: "center", width: "5%" }}>
                  <img src={Unitechlogo} style={{ width: "50px" }}></img>
                </th>
                <th
                  id={`${idView}-Title`}
                  name={`${nameView}-Title`}
                  colSpan="3"
                  style={{
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    padding: "10px 0px",
                  }}
                >
                  <font style={{ fontSize: "24px" }}>
                  KASH Tech LLC
                  </font>
                  <br></br>
                  <font color="#8B0000">LUMPS MONITORING CHECK SHEET</font>
                </th>

                <button content={() => componentref} >Download</button>
              </tr>
              <tr>
                {/*  <td className="topic-head">&nbsp;&nbsp;S.NO</td>
            <td>
              <input type="no"
              value={LumpCreate.Sno}
              onChange={(e) => setSno(e.target.value)}
              /></td>  */}
                <td className="topic-head">&nbsp;&nbsp;DATE</td>
                <td>

                  <label>{formatDt(LumpCreate.TrackDate)}</label>
                </td>{" "}
                <td className="topic-head" colSpan={1}>
                Shift
              </td>
           
                <td ><label>{LumpCreate.Shft}</label> </td>
              </tr>
              <tr>
                {" "}
                <td className="topic-head">
                  &nbsp;&nbsp;MACHINE
                </td>
                <td>

                  <label>{LumpCreate.MachineNo}</label>

                </td>
                <td className="topic-head">
                  &nbsp;&nbsp;PART NAME
                </td>
                <td>
                  <label>{LumpCreate.PartName}</label>


                </td>
              </tr>
              <tr>
                {" "}
                <td className="topic-head">
                  &nbsp;&nbsp;MATERIAL
                </td>
                <td>
                  {/* <input
                  type="text"
                  value={LumpCreate.Material}
                  onChange={(e) =>
                    setLumpCreate({
                      ...LumpCreate,
                      Material: e.target.value,
                    })
                  }
                /> */}
                  <label>{LumpCreate.Material}</label>
                </td>{" "}
                <td className="topic-head">&nbsp;&nbsp;LUMPS(KGS)</td>
                <td>

                  <label>{LumpCreate.LumpKg}</label>
                </td>{" "}
              </tr>
              <tr>
                {" "}
                <td className="topic-head">&nbsp;&nbsp;LUMPS REJECTION(KGS)</td>
                  <td>
                   <label>{LumpCreate.LumpRegKg}</label>
                      </td>{" "}
                <td className="topic-head">&nbsp;&nbsp;REASON</td>
                <td>

                  <label>{LumpCreate.Reasn}</label>
                </td>{" "}
                </tr>
                
                <tr>
                <td className="topic-head">&nbsp;&nbsp;REMARKS</td>
                <td>
                  <label>{LumpCreate.Rmrks}</label>

                </td>
                <td className="topic-head">In-Charge Sign:</td>
              <td colSpan={1}>
                <label>{LumpCreate.inchrgname}</label></td>
              </tr>


            </tbody>
          </table>
        </form>
      </Popup>



      <Popup
        trigger={ButtonPopup}
        setTrigger={setButtonPopup}
        viewPagination={viewPagination}
      >
        <br></br>
        <br></br>

        <form
          id="lumpsmon-forMachineNoontainer-Edit"
          name="lumpsmon-forMachineNoontainer-Edit"
          className="form-lumpsmon-Edit"
        >
          <table style={{ textAlign: "center" }}>
            <tbody>
              <tr>
                <th style={{ textAlign: "center", width: "5%" }}>
                  <img src={Unitechlogo} style={{ width: "50px" }}></img>
                </th>
                <th
                  id={`${idEdit}-Title`}
                  name={`${nameEdit}-Title`}
                  colSpan="3"
                  style={{
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    padding: "10px 0px",
                  }}
                >
                  <font style={{ fontSize: "24px" }}>
                  KASH Tech LLC
                  </font>
                  <br></br>
                  <font color="#8B0000">LUMPS MONITORING CHECK SHEET</font>
                </th>
              </tr>
              <tr>
                {/*  <td className="topic-head">&nbsp;&nbsp;S.NO</td>
            <td>
              <input type="no"
              value={LumpCreate.Sno}
              onChange={(e) => setSno(e.target.value)}
              /></td>  */}
                <td className="topic-head">&nbsp;&nbsp;DATE</td>
                <td>
                  <DatePicker
                    value={LumpCreate.TrackDate}
                    onChange={handleDateChange}
                  />
                </td>{" "}
                <td className="topic-head" colSpan={1}>
                Shift
              </td>
              <td colSpan={1}>
                <select
                  value={LumpCreate.Shft} 
                  onChange={(e) =>
                    setLumpCreate({
                      ...LumpCreate,
                      Shft: e.target.value,
                    })
                  }
                  
                  style={{ textAlign: "center" }}
                  disabled
                >
                  <option
                 
                    value=""
                  >
                    -- Select --
                  </option>
                  <option   value="A">
                    A12
                  </option>
                  <option   value="B">
                    B12
                  </option>
                </select>
              </td>
              </tr>
              <tr>
                {" "}
                <td className="topic-head">
                  &nbsp;&nbsp;MACHINE<span style={{ color: "red" }}>*</span>
                </td>
                <td>
                  <input
                    type="text"
                    value={LumpCreate.MachineNo}
                    onChange={(e) =>
                      setLumpCreate({
                        ...LumpCreate,
                        MachineNo: e.target.value,
                      })
                    }
                    disabled
                  />
                  {/* <DropdownFetcher
                  keyword="mac_dtl_dropdown"
                  setValue={setDropdown}
                />
                <DropdownFilterSelect value = {LumpCreate.MachineNo} onChange={(e) =>
                    setLumpCreate({
                      ...LumpCreate,
                      MachineNo: e.value,
                    })
                  } options={mcNoOptions} placeholder="Select Machine No" 
                  
                  /> */}
                </td>
                <td className="topic-head">
                  &nbsp;&nbsp;PART NAME<span style={{ color: "red" }}>*</span>
                </td>
                <td>
                  <input
                    type="text"
                    value={LumpCreate.PartName}
                    onChange={(e) =>
                      setLumpCreate({
                        ...LumpCreate,
                        PartName: e.target.value,
                      })
                    }
                    disabled
                  />
                  {/* <DropdownFetcher
                  keyword="mac_part_dtl_dropdown"
                  setValue={setPartDropdown}
                />
                
                <DropdownFilterSelect value = {LumpCreate.PartName} onChange={handleDropdownChange} options={partNoOptions} placeholder="Select Part Name" />
             */} </td>
              </tr>
              <tr>
                {" "}
                <td className="topic-head">
                  &nbsp;&nbsp;MATERIAL<span style={{ color: "red" }}>*</span>
                </td>
                <td>
                  {/* <input
                  type="text"
                  value={LumpCreate.Material}
                  onChange={(e) =>
                    setLumpCreate({
                      ...LumpCreate,
                      Material: e.target.value,
                    })
                  }
                /> */}
                  {/* <DropdownFetcher
                    keyword="rm_details_dropdown"
                    setValue={setRmDropdown}
                  /> */}
                  <DropdownFilterSelect value={LumpCreate.Material} onChange={(e) =>
                    setLumpCreate({
                      ...LumpCreate,
                      Material: e.value,
                    })
                  } options={rmOptions} placeholder="Search RMI..."
                    onInputChange={(event) => { GetSelectValue(event, "rm_details_dropdown", setRmDropdown) }}
                  />
                  {LumpCreate.Material === '' && isCreating && <span className="Req">Required *</span>}
                </td>{" "}
                <td className="topic-head">&nbsp;&nbsp;LUMPS(KGS)</td>
                <td>
                  <input
                    type="number"
                    value={LumpCreate.LumpKg}
                    onChange={(e) =>
                      setLumpCreate({
                        ...LumpCreate,
                        LumpKg: e.target.value,
                      })
                    }
                  />
                </td>{" "}
              </tr>
              <tr>
                {" "}
                <td className="topic-head">&nbsp;&nbsp;LUMPS REJECTION(KGS)</td>
               <td>
                <input
                  type="number"
                  value={LumpCreate.LumpRegKg}
                  onChange={(e) =>
                    setLumpCreate({
                      ...LumpCreate,
                      LumpRegKg: e.target.value,
                    })
                  }
                />
               </td>{" "}
            
             
                <td className="topic-head">&nbsp;&nbsp;REASON</td>
                <td>
                  <textarea
                    style={{ height: "auto" }}
                    cols={50}
                    value={LumpCreate.Reasn}
                    onChange={(e) =>
                      setLumpCreate({
                        ...LumpCreate,
                        Reasn: e.target.value,
                      })
                    }
                  />
                </td>{" "}
                </tr>
                      <tr>
              
                <td className="topic-head">&nbsp;&nbsp;REMARKS</td>
                <td>
                  <textarea
                    style={{ height: "auto" }}
                    cols={50}
                    value={LumpCreate.Rmrks}
                    onChange={(e) =>
                      setLumpCreate({
                        ...LumpCreate,
                        Rmrks: e.target.value,
                      })
                    }
                  />
                </td>
                <td className="topic-head">In-Charge Sign :</td>
               <td colSpan={1}>
                <Select
                  value={{
                    value: LumpCreate.inchrg,
                    label: LumpCreate.inchrgname,
                  }}
                  options={Name.map((employee) => ({
                    value: employee.Emp_code,
                    label: employee.Emp_name,
                  }))}
                  onChange={handleIncharge}
                  onInputChange={(event) => { GetSelectValue(event, "emp_details_dropdown", setname) }}
                
                />
               </td>
                      </tr>
                      <tr>
                <td colSpan="5" align="center" style={{ border: "none" }}>
                  <button
                    type="submit"
                    id={`${idEdit}-submit`}
                    name={`${nameEdit}-submit`}
                    onClick={(e) => handleUpdate(e)}
                  >
                    Update
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </Popup>

      <div>
        <table
          style={{ textAlign: "center", width: "100%" }}
        /*{ cellPadding="5" }*/
        >
          <thead>
            <tr>
              <th
                id="lumpcss-Title.List"
                name="lump-Title.List"
                colSpan="12"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">List - LUMPS MONITORING CHECK SHEET </font>
              </th>
            </tr>
            <tr>
              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                Date
              </td>
              <td colSpan="1">
                <input
                  id="LumpCreate-filter-date"
                  name="LumpCreate-filter-date"
                  type="date"
                  placeholder="Filter by Date"
                  value={LumpCreate.filterDate}
                  onChange={handleFilterDateChange}
                />
              </td>
              {/* <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                Machine Name
              </td>
              <td colSpan={2}>
                <input
                  id="LumpCreate-filter-MaterialName"
                  name="LumpCreate-filter-MaterialName"
                  type="text"
                  placeholder="Filter MachineNo"
                  style={{ fontSize: "70%", width: "100%" }}
                  value={LumpCreate.filterMcno}
                  onChange={handleFilterMcnoChange}
                />
              </td>
              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                PartName
              </td>
              <td colSpan={2}>
                <input
                  id="LumpCreate-filter-PartName"
                  name="LumpCreate-filter-PartName"
                  type="text"
                  placeholder="Filter PartName"
                  style={{ fontSize: "70%", width: "100%" }}
                  value={LumpCreate.filterPartNm}
                  onChange={handleFilterPartNmChange}
                />
              </td> */}
              {/* <td colSpan="8"></td> */}
              

           <td colSpan={1}>
                   <select
                id="LumpCreate-select-field"
               name="LumpCreate-select-field"    
                         style={{ fontSize: "100%", width: "50%" }}
                         onChange={(e) => setSelectedOption(e.target.value)}
                                         >
                                          <option value="">Select</option>
                        <option value="PartName">PartName</option>
                        <option value="MachineName">MachineName</option>
                        <option value="Bay">Bay</option>
                         </select>
                         </td>
                         <td colSpan={2}>
                               <input
                                   id="LumpCreate-filter-PartName"
                                  name="LumpCreate-filter-PartName"
                                 type="text"
                                placeholder="Filter"
                               style={{ fontSize: "70%", width: "100%" }}
                          value={(selectedOption === "PartName" && LumpCreate.filterPartNm) ||
                           (selectedOption === "MachineName" && LumpCreate.filterMcno) ||  (selectedOption === "Bay" && LumpCreate.filterBay) ||""}
                         onChange={(e) => {
                          if (selectedOption === "PartName") {
                             handleFilterPartNmChange(e);
                              } else if (selectedOption === "MachineName") {
                              handleFilterMcnoChange(e);
                                     }
                                     else if (selectedOption === "Bay") {
                                      handleFilterbayChange(e);
                                             }
                                    }}
                                       />
                                    </td>
                          {/*   <td colSpan={2}>
                              <input
                             id="LumpCreate-filter-PartName"
                              name="LumpCreate-filter-PartName"
                              type="text"
                              placeholder="Filter"
                              style={{ fontSize: "70%", width: "100%" }}
                              value={LumpCreate.filterPartNm}
                              onChange={handleFilterPartNmChange}
                             />
                             </td> */}

            </tr>
            <tr className="table_position">
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                Date
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                Bay
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                Machine
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                Part Name
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                Material
              </th>

              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                Lump Kg
              </th>


              {/* <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                Created Date
              </th> */}
              <th className="table-list-head" >

              </th>
              <th className="table-list-head">

              </th>
              <th className="table-list-head">

              </th>
            </tr>
          </thead>
          <tbody>
            {/* <HandleRead keyword="Lumpsmon" setData={setlumps_mon} /> */}
            {filteredRecords.length === 0 ? (
              // <tr>
              <td colSpan="13" style={{ textAlign: "center" }}>
                <p id="lump-list-NoRec"
                  name="lump-list-noRec" style={{ fontWeight: "bold" }}>No records found.</p>
              </td>
            ) : (
              // </tr>
              filteredRecords.map((lumps_mon) => (
                <tr className="table_view" key={lumps_mon.Id}>
                  <td id={`${idList}-date`}
                    name={`${nameList}-date`} className="table-cell">{formatDt(lumps_mon.TrackDate)}</td>
                    <td id={`${idList}-bay`}
                    name={`${nameList}-bay`} className="table-cell">{lumps_mon.MacBay}</td>
                  <td id={`${idList}-Sno`}
                    name={`${nameList}-Sno`} className="table-cell">{lumps_mon.Machine_no}</td>
                  <td id={`${idList}-mc`}
                    name={`${nameList}-mc`}
                    className="table-cell">{lumps_mon.Part_name}</td>
                  <td id={`${idList}-Partname`}
                    name={`${nameList}-Partname`}
                    className="table-cell">{lumps_mon.Material}</td>
                  <td id={`${idList}-mat`}
                    name={`${nameList}-mat`}
                    className="table-cell">{lumps_mon.Lumps_kg}</td>

                  {/* <td id={`${idList}-CrdDate`}
                name={`${nameList}-CrdDate`}
                   className="table-cell">{lumps_mon.CreatedDate}</td> */}
                  <td>
                    <button
                      id={`${idView}-view`} name={`${idView}-view`}
                      style={{ backgroundColor: "#cba423" }}
                      type="button"
                      onClick={() => handleView(lumps_mon)}
                    >
                      View&#x1F441;
                    </button>
                  </td>
                  <td>
                    <button
                      id={`${idEdit}-Edit`} name={`${idEdit}-Edit`}
                      type="button" onClick={() => handleEdit(lumps_mon)}>
                      Edit&#x270E;
                    </button>
                  </td>
                  <td>
                    <button
                      id={`LumpsMon-Delete`}
                      name={`LumpsMon-Delete`}
                      style={{ whiteSpace: "nowrap", backgroundColor: "#DA251A" }}
                      type="button"
                      onClick={() => {
                        const userConfirmed = window.confirm('Are you sure you want to delete?');
                        if (userConfirmed) {
                          UpdateRecord({
                            _keyword_: 'LumpsMon',
                            data: [
                              {
                                Id: lumps_mon.Id,
                                ActiveStatus: 'InActive',
                              },
                            ],
                          }).then(() => {
                            handleReadLumpSheet();
                          })
                          // window.location.reload();
                        }
                      }}
                    >
                      Delete<MdDelete />
                    </button>
                  </td>
                </tr>
              )))}
          </tbody>
        </table>
        <div className="pagination" id="pagination">
          {LumpCreate.isPaginationVisible && filteredData.length > 0 && (

            <Pagination currentPage={LumpCreate.currentPage} npage={npage} prePage={prePage} nextPage={nextPage} changeCpage={changeCpage} numbers={numbers} />
          )}
        </div>
      </div>
    </div>
  );
}

export default LUMPViewTab;
