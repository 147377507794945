import React from 'react';

function FilterReport({
  timePeriod,
  handleTimePeriodChange,
  filterDate,
  handleFilterDateChange,
  filtermonth,
  handleFiltermonthChange,
  startDate,
  handleStartDateChange,
  endDate,
  handleEndDateChange,
  startDate1,
  endDate1,
  handleStartDateChange1,
  handleEndDateChange1,
}) {
  
  const dateRangePicker1 = (
    <td style={{ border: 'none'}}>
      <input
        id="filter-date"
        name="filter-date"
        type="date"
        placeholder="Select Date"
        value={filterDate}
        onChange={handleFilterDateChange}
        style={{color: 'white',fontSize: '20px', marginLeft: '40px',marginRight: '20px', width: '150px', border: 'none' ,background:"#005C9F",}}
    
      />
    </td>
  );

  const dateRangePicker2 = (
    <td style={{ border: 'none' }}>
      <input
        id="filter-month"
        name="filter-month"
        type="month"
        placeholder="Select Month"
        value={filtermonth}
        onChange={handleFiltermonthChange}
        style={{color: 'white',fontSize: '20px', marginLeft: '80px',marginRight: '20px',  Width: '200px', border: 'none' ,background:"#005C9F",}}
      />
    </td>
  );

  const dateRangePicker = (
    <td style={{ border: 'none' }}>
      <input
        style={{color: 'white',fontSize: '20px', marginLeft: '40px',marginRight: '20px', float: 'left', width: '150px', border: 'none' ,background:"#005C9F",}}
        id="start-date"
        name="start-date"
        type="date"
        placeholder="Start Date"
        value={startDate}
        
        onChange={(e) => {
          const newStartDate = e.target.value;
          handleStartDateChange(newStartDate);

          if (timePeriod === '7') {
            const endDateForOneWeek = new Date(newStartDate);
            endDateForOneWeek.setDate(endDateForOneWeek.getDate() + 6);
            const formattedEndDate = endDateForOneWeek.toISOString().split('T')[0];
            handleEndDateChange(formattedEndDate);
          }
          // } else if (timePeriod === '15') {
          //   const endDateForOneW = new Date(newStartDate);
          //   endDateForOneW.setDate(endDateForOneW.getDate() + 14);
          //   const formattedEndDate = endDateForOneW.toISOString().split('T')[0];
          //   handleEndDateChange(formattedEndDate);
          // } 
          // else if (timePeriod === '30') {
            
          // }
        }}
      />
      <input
        id="end-date"
        name="end-date"
        type="date"
        placeholder="End Date"
        value={endDate}
        onChange={handleEndDateChange}
        style={{color: 'white',fontSize: '20px',marginRight:"20px", marginLeft: '100px', width: '150px',background:"#005C9F",}}
        disabled
      />
    </td>
  );
  

  const dateRangePicker3 = (
    <td style={{ border: 'none' }}>
      <input
        style={{color: 'white',fontSize: '20px', marginLeft: '40px',marginRight: '20px', float: 'left', width: '150px', border: 'none' ,background:"#005C9F",}}
        id="start-datew"
        name="start-datew"
        type="date"
        placeholder="Start Date"
        value={startDate1}
        
        onChange={(e) => {
          const newStartDate1 = e.target.value;
          handleStartDateChange1(newStartDate1);


          if (timePeriod === '15') {
            const endDateForOneW = new Date(newStartDate1);
            endDateForOneW.setDate(endDateForOneW.getDate() + 14);
            const formattedEndDate = endDateForOneW.toISOString().split('T')[0];
            handleEndDateChange1(formattedEndDate);
          } 
    
        }}
      />
      <input
        id="end-date"
        name="end-date"
        type="date"
        placeholder="End Date"
        value={endDate1}
        onChange={handleEndDateChange}
        style={{color: 'white',fontSize: '20px',marginRight:"20px", marginLeft: '100px', width: '150px',background:"#005C9F",}}
        disabled
      />
    </td>
  );
  return (
    <tr>
      <td style={{ border: 'none'}}>
        <select
          id="time-period"
          value={timePeriod}
          onChange={handleTimePeriodChange}
      
          style={{
            width: '150px',
            background:"#005C9F",
            color: 'white',
            fontSize: '20px',
            border: 'none',
            marginLeft: '70px',marginRight: '10px',
          }}
        >
          <option value=" ">select</option>
          <option value="1">1 Day</option>
          <option value="7">1 Week</option>
          <option value="15">15 Days</option>
          <option value="31">Month</option>
        </select>
      </td>
    
      {timePeriod === '1' && dateRangePicker1}
      {timePeriod === '7' && dateRangePicker}
      {timePeriod === '15' && dateRangePicker3}
      {timePeriod === '31' && dateRangePicker2}
    </tr>
  );
}

export default FilterReport;
