import React, { useState,useEffect } from "react";
import DropdownFetcher from "../../../Lib/DropdownFetcher.js";
import { SECRET_KEY, PROCESS_TRANSACTION_UPDATE_URL } from "../../../Utils/constants.js";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import GetRecord from "../../../Lib/GetRecord";
import Select from "react-select";
const initialCheckboxStates = {
   PRODUCTION: [
    { key: "materialloadingCreate", value: false },
    { key: "materialloadingView", value: false },
    { key: "materialloadingDelete", value: false },
    { key: "materialloadingUpdate", value: false },
    { key: "4mreportCreate", value: false },
    { key: "4mreportView", value: false },
    { key: "4mreportDelete", value: false },
    { key: "4mreportUpdate", value: false },
    { key: "mouldloadunloadCreate", value: false },
    { key: "mouldloadunloadView", value: false },
    { key: "mouldloadunloadDelete", value: false },
    { key: "mouldloadunloadUpdate", value: false },
    { key: "deviationCreate", value: false },
    { key: "deviationView", value: false },
    { key: "deviationDelete", value: false },
    { key: "deviationUpdate", value: false },
    { key: "mouldcorrectiveactionCreate", value: false },
    { key: "mouldcorrectiveactionView", value: false },
    { key: "mouldcorrectiveactionDelete", value: false },
    { key: "mouldcorrectiveactionUpdate", value: false },
    { key: "mouldtemperatureCreate", value: false },
    { key: "mouldtemperatureView", value: false },
    { key: "mouldtemperatureDelete", value: false },
    { key: "mouldtemperatureUpdate", value: false },
    { key: "machinebreakdownCreate", value: false },
    { key: "machinebreakdownView", value: false },
    { key: "machinebreakdownDelete", value: false },
    { key: "machinebreakdownUpdate", value: false },
    { key: "lumpmonitoringCreate", value: false },
    { key: "lumpmonitoringView", value: false },
    { key: "lumpmonitoringDelete", value: false },
    { key: "lumpmonitoringUpdate", value: false },
    { key: "productionskillCreate", value: false },
    { key: "productionskillView", value: false },
    { key: "productionskillDelete", value: false },
    { key: "productionskillUpdate", value: false },
    { key: "ecnreportCreate", value: false },
    { key: "ecnreportView", value: false },
    { key: "ecnreportDelete", value: false },
    { key: "ecnreportUpdate", value: false },
    { key: "processvalidationrevalidationCreate", value: false },
    { key: "processvalidationrevalidationView", value: false },
    { key: "processvalidationrevalidationDelete", value: false },
    { key: "processvalidationrevalidationUpdate", value: false },
    { key: "dailyshiftprocesschecklistCreate", value: false },
    { key: "dailyshiftprocesschecklistView", value: false },
    { key: "dailyshiftprocesschecklistDelete", value: false },
    { key: "dailyshiftprocesschecklistUpdate", value: false },
  ],  
   MASTER: [
    { key: "barcodegenerationCreate", value: false },
    { key: "barcodegenerationView", value: false },
    { key: "barcodegenerationUpdate", value: false },
    { key: "barcodegenerationDelete", value: false },
    { key: "machineCreate", value: false },
    { key: "machineView", value: false },
    { key: "machineUpdate", value: false },
    { key: "machineDelete", value: false },
    { key: "machinePartCreate", value: false },
    { key: "machinePartView", value: false },
    { key: "machinePartUpdate", value: false },
    { key: "machinePartDelete", value: false },
    { key: "fgmasterCreate", value: false },
    { key: "fgmasterView", value: false },
    { key: "fgmasterDelete", value: false },
    { key: "fgmasterUpdate", value: false },
    { key: "standardsuppliermasterCreate", value: false },
    { key: "standardsuppliermasterView", value: false },
    { key: "standardsuppliermasterDelete", value: false },
    { key: "standardsuppliermasterUpdate", value: false },
    { key: "rawmaterialdetailsCreate", value: false },
    { key: "rawmaterialdetailsrView", value: false },
    { key: "rawmaterialdetailsDelete", value: false },
    { key: "rawmaterialdetailsUpdate", value: false },
    { key: "employeemasterCreate", value: false },
    { key: "employeemasterView", value: false },
    { key: "employeemasterDelete", value: false },
    { key: "employeemasterUpdate", value: false },
    { key: "customermasterCreate", value: false },
    { key: "customermasterView", value: false },
    { key: "customermasterDelete", value: false },
    { key: "customermasterUpdate", value: false },
    { key: "masterheadCreate", value: false },
    { key: "masterheadView", value: false },
    { key: "masterheadDelete", value: false },
    { key: "masterheadUpdate", value: false },
    { key: "materialstandardCreate", value: false },
    { key: "materialstandardView", value: false },
    { key: "materialstandardDelete", value: false },
    { key: "materialstandardUpdate", value: false },
  ],  
  QUALITY: [
  { key: "firstoffinsCreate", value: false },
  { key: "firstoffinsView", value: false },
  { key: "firstoffinsDelete", value: false },
  { key: "firstoffinsUpdate", value: false },
  { key: "lastoffinsCreate", value: false },
  { key: "lastoffinsView", value: false },
  { key: "lastoffinsDelete", value: false },
  { key: "lastoffinsUpdate", value: false },
  { key: "onlineinsCreate", value: false },
  { key: "onlineinsView", value: false },
  { key: "onlineinsDelete", value: false },
  { key: "onlineinsUpdate", value: false },
  { key: "inlineinsCreate", value: false },
  { key: "inlineinsView", value: false },
  { key: "inlineinsDelete", value: false },
  { key: "inlineinsUpdate", value: false },
],
}
function AccessListTab() {
  const [Id, setId] = useState("");
  const [Employee, setEmployee] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [checkboxStates, setCheckboxStates] = useState(initialCheckboxStates)
  var [AccList, setAccList] = useState([]);
  const jsonString = JSON.stringify(initialCheckboxStates);
  // useEffect(() => {
  //   console.log('Initial Checkbox States:', checkboxStates);
  // }, []); 
  const handleCheckboxChange = (category, key, action) => {
   // console.log(category, key, action)
    const updatedCheckboxStates = { ...checkboxStates };
    const categoryPermissions = updatedCheckboxStates[category];
    const targetCheckbox = categoryPermissions.find((checkbox) => checkbox.key === key);
//    console.log("Before:", updatedCheckboxStates);
    if (targetCheckbox) {
      switch (action) {
        case "Create":
        case "View":
        case "Update":
        case "Delete":
        targetCheckbox.value = !targetCheckbox.value;
        break;
        default:
        break;
      }      
    }
  //  console.log("After:", updatedCheckboxStates,selectedEmployee);
    setCheckboxStates({ ...updatedCheckboxStates });
  };
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const handleEmployee = (selectedOption) => {
    const selected = Employee.find(
      (option) => option.Emp_code === selectedOption.value
    );
    if (selected) {
      setSelectedEmployee({
        value: selected.Emp_code,
        label: selected.Emp_name,
      });
      setShowAdditionalFields(true);
    }
    GetRecord(
      { _keyword_: "emp_details_by_empCode", emp_code: selected.Emp_code },
    setId
    ).then((res) => {
      // console.log(selected.Emp_code)
      // console.log(res[0].Id)
     setId( res[0].Id)
      });
  };
  
  const handleUpdate = (e) => {
 //   console.log("checkboxStates",checkboxStates);
    e.preventDefault();
    const data = {
      data: [
        {
          Id:Id,
          Access_control:jsonString,
        },
      ],
    };
    data["_keyword_"] = "emp_details";
    data["secretkey"] = SECRET_KEY;
 //   console.log("Id before API request:", data.data[0].Id);
 //   console.log("Emp_flex_01 before API request:", data.data[0].Access_control);

    getAPIRequest(PROCESS_TRANSACTION_UPDATE_URL, "POST", data)
    .then((response) => {
      setAccList([...AccList, response.data]);
      console.log(response,AccList)
      alert("Updation Successful");
      window.location.reload();
  })

      .catch((error) => {
        console.log(error);
        alert(GeneralConstants.UPDATE_FAILURE);
        window.location.reload();
      });
   };
   return (
    <div>
      <div
        style={{marginLeft:"40%", textAlign: "center", width: "20%" }}  cellPadding="10" >
          <form>
          <DropdownFetcher keyword="emp_details_dropdown_all" setValue={setEmployee} />
                <Select
                  styles={{ width: "100%", backgroundColor: "#efd9b1" }}
                  value={selectedEmployee}
                  onChange={handleEmployee}
                  placeholder="select Employee"
                  options={Employee.map((i) => ({
                    value: i.Emp_code,
                    label: i.Emp_name,
                  }))}
                  formatOptionLabel={(option) => (
                    <div>
                      <div>{option.label}</div>
                      <div style={{ fontSize: 12, color: "#777" }}>{option.value}</div>
                    </div>
                  )}
                  />
          </form>
          </div>
        <div style={{marginLeft:"40%", textAlign: "center", width: "20%" }}  cellPadding="10" >     
       </div>    
       <div style={{align:"center"}}>
     
        <form id="fgmasterCreate.formContainer" name="fgmasterCreate.formContainer" className="form-fgmaster-Create">
        <table style={{ textAlign: "center", width: "100%"}}  cellPadding="2" >        
          <tr>
          <th style={{ textAlign: "center"}}colSpan={2} >  
        Master</th>
         </tr>
         <tr>
         <td>
        Barcode Generation
      <div>
      <label>
      <input type="checkbox" style={{ marginRight:"5px",marginLeft:"20px"}}onChange={() => handleCheckboxChange("MASTER","barcodegenerationCreate","Create")}disabled={!showAdditionalFields}/>Create
      <input type="checkbox" style={{ marginRight:"5px",marginLeft:"20px"}}onChange={() => handleCheckboxChange("MASTER","barcodegenerationView","View")}disabled={!showAdditionalFields}/>View
      <input type="checkbox" style={{ marginRight:"5px",marginLeft:"20px"}}onChange={() => handleCheckboxChange("MASTER","barcodegenerationUpdate","Update")} disabled={!showAdditionalFields}/>Update
      <input type="checkbox" style={{ marginRight:"5px",marginLeft:"20px"}}onChange={() => handleCheckboxChange("MASTER","barcodegenerationDelete","Delete")} disabled={!showAdditionalFields}/>Delete   
      </label>
      </div>
      </td>
 
    <td>Machine details
         <div>
         <label>
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }}onChange={() => handleCheckboxChange("MASTER","machineCreate", "Create")} disabled={!showAdditionalFields}/>Create    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }}onChange={() => handleCheckboxChange("MASTER","machineView", "View")} disabled={!showAdditionalFields}/>View    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }}onChange={() => handleCheckboxChange("MASTER","machineUpdate", "Update")} disabled={!showAdditionalFields}/>Update 
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }}onChange={() => handleCheckboxChange("MASTER","machineDelete", "Delete")} disabled={!showAdditionalFields}/>Delete 
    </label>
  </div></td></tr>
  <tr>  
    <td>Machine Part details
         <div>
         <label>
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }}onChange={() => handleCheckboxChange("MASTER","machinePartCreate", "Create")} disabled={!showAdditionalFields}/>Create   
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }}onChange={() => handleCheckboxChange("MASTER","machinePartView", "View")} disabled={!showAdditionalFields}/>View    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }}onChange={() => handleCheckboxChange("MASTER","machinePartUpdate", "Update")} disabled={!showAdditionalFields}/>Update 
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }}onChange={() => handleCheckboxChange("MASTER","machinePartDelete", "Delete")} disabled={!showAdditionalFields}/>Delete 
    </label>
  </div>
    </td>

    <td>Fg Details
         <div>
      <label>
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }}onChange={() => handleCheckboxChange("MASTER","fgmasterCreate", "Create")} disabled={!showAdditionalFields}/>Create    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }}onChange={() => handleCheckboxChange("MASTER","fgmasterView", "View")} disabled={!showAdditionalFields}/>View    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }}onChange={() => handleCheckboxChange("MASTER","fgmasterUpdate", "Update")} disabled={!showAdditionalFields}/>Update 
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }}onChange={() => handleCheckboxChange("MASTER","fgmasterDelete", "Delete")} disabled={!showAdditionalFields}/>Delete 
    </label>
  </div></td></tr>
    <tr>
    <td>Standard Supplier master
    <div>
    <label>
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }}onChange={() => handleCheckboxChange("MASTER","standardsuppliermasterCreate", "Create")} disabled={!showAdditionalFields}/>Create    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }}onChange={() => handleCheckboxChange("MASTER","standardsuppliermasterView", "View")} disabled={!showAdditionalFields}/>View    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }}onChange={() => handleCheckboxChange("MASTER","standardsuppliermasterUpdate", "Update")} disabled={!showAdditionalFields}/>Update 
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }}onChange={() => handleCheckboxChange("MASTER","standardsuppliermasterDelete", "Delete")} disabled={!showAdditionalFields}/>Delete 
    </label>
  </div></td>
  <td>Raw Material details
         <div>
         <label>
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("MASTER","rawmaterialdetailsCreate", "Create")} disabled={!showAdditionalFields}/>Create    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("MASTER","rawmaterialdetailsView", "View")} disabled={!showAdditionalFields}/>View    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("MASTER","rawmaterialdetailsUpdate", "Update")} disabled={!showAdditionalFields}/>Update 
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("MASTER","rawmaterialdetailsDelete", "Delete")} disabled={!showAdditionalFields}/>Delete 
    </label>
  </div></td></tr> 
  <tr>
    <td>Employee details
         <div>
         <label>
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("MASTER","employeemasterCreate", "Create")} disabled={!showAdditionalFields}/>Create    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("MASTER","employeemasterView", "View")} disabled={!showAdditionalFields}/>View    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("MASTER","employeemasterUpdate", "Update")} disabled={!showAdditionalFields}/>Update 
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("MASTER","employeemasterDelete", "Delete")} disabled={!showAdditionalFields}/>Delete 
    </label>
  </div></td>
    <td>Customer Mater
         <div>
         <label>
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("MASTER","customermasterCreate", "Create")} disabled={!showAdditionalFields}/>Create    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("MASTER","customermasterView", "View")} disabled={!showAdditionalFields}/>View    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("MASTER","customermasterUpdate", "Update")} disabled={!showAdditionalFields}/>Update 
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("MASTER","customermasterDelete", "Delete")} disabled={!showAdditionalFields}/>Delete 
    </label>
  </div></td>
  </tr>
  <tr><td>Master Head
         <div>
         <label>
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("MASTER","masterheadCreate", "Create")} disabled={!showAdditionalFields}/>Create    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("MASTER","masterheadView", "View")} disabled={!showAdditionalFields}/>View    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("MASTER","masterheadUpdate", "Update")} disabled={!showAdditionalFields}/>Update 
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("MASTER","masterheadDelete", "Delete")} disabled={!showAdditionalFields}/>Delete 
    </label>
  </div></td>
 <td> Material Standard
         <div>
         <label>
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("MASTER","materialstandardCreate", "Create")} disabled={!showAdditionalFields}/>Create    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("MASTER","materialstandardView", "View")} disabled={!showAdditionalFields}/>View    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("MASTER","materialstandardUpdate", "Update")} disabled={!showAdditionalFields}/>Update 
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("MASTER","materialstandardDelete", "Delete")} disabled={!showAdditionalFields}/>Delete 
        </label>
  </div></td></tr>
  <br />
  <tr>
  <th colSpan={2}style={{ textAlign: "center"}}>
    Molding - Production
    </th></tr>
    <tr><td>Material Loading  
    <label>
    <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","materialloadingCreate", "Create")} disabled={!showAdditionalFields}/>Create    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px"}} onChange={() => handleCheckboxChange("PRODUCTION","materialloadingView", "View")} disabled={!showAdditionalFields}/>View    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","materialloadingUpdate", "Update")} disabled={!showAdditionalFields}/>Update 
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","materialloadingDelete", "Delete")} disabled={!showAdditionalFields}/>Delete 
        </label></td>
      <td>4M Report  
      <label>
     <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","4mreportCreate", "Create")} disabled={!showAdditionalFields}/>Create    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","4mreportView", "View")} disabled={!showAdditionalFields}/>View    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","4mreportUpdate", "Update")} disabled={!showAdditionalFields}/>Update 
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","4mreportDelete", "Delete")} disabled={!showAdditionalFields}/>Delete 
        </label></td>
      </tr>
      <tr><td>Mould Loading and UnLoading  
      <label>
    <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","mouldloadunloadCreate", "Create")} disabled={!showAdditionalFields}/>Create    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","mouldloadunloadView", "View")} disabled={!showAdditionalFields}/>View    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","mouldloadunloadUpdate", "Update")} disabled={!showAdditionalFields}/>Update 
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","mouldloadunloadDelete", "Delete")} disabled={!showAdditionalFields}/>Delete 
        </label></td>
      <td>Deviation Form  
      <label>
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","deviationCreate", "Create")} disabled={!showAdditionalFields}/>Create    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","deviationView", "View")} disabled={!showAdditionalFields}/>View    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","deviationUpdate", "Update")} disabled={!showAdditionalFields}/>Update 
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","deviationDelete", "Delete")} disabled={!showAdditionalFields}/>Delete 
        </label></td>
      </tr>
      <tr><td>Mould Corrective Action  
      <label>
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","mouldcorrectiveactionCreate", "Create")} disabled={!showAdditionalFields}/>Create    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","mouldcorrectiveactionView", "View")} disabled={!showAdditionalFields}/>View    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","mouldcorrectiveactionUpdate", "Update")} disabled={!showAdditionalFields}/>Update 
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","mouldcorrectiveactionDelete", "Delete")} disabled={!showAdditionalFields}/>Delete 
      </label>
      </td>
    <td>Mould Temperature  
      <label>
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","mouldtemperatureCreate", "Create")} disabled={!showAdditionalFields}/>Create    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","mouldtemperatureView", "View")} disabled={!showAdditionalFields}/>View    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","mouldtemperatureUpdate", "Update")} disabled={!showAdditionalFields}/>Update 
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","mouldtemperatureDelete", "Delete")} disabled={!showAdditionalFields}/>Delete 
        </label></td>
      </tr>
      <tr><td>Machine breakDown   
      <label>
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","machinebreakdownCreate", "Create")} disabled={!showAdditionalFields}/>Create    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","machinebreakdownView", "View")} disabled={!showAdditionalFields}/>View    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","machinebreakdownUpdate", "Update")} disabled={!showAdditionalFields}/>Update 
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","machinebreakdownDelete", "Delete")} disabled={!showAdditionalFields}/>Delete 
      </label></td>
      <td>Lump Monitoring  
      <label>
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","lumpmonitoringCreate", "Create")} disabled={!showAdditionalFields}/>Create    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","lumpmonitoringView", "View")} disabled={!showAdditionalFields}/>View    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","lumpmonitoringUpdate", "Update")} disabled={!showAdditionalFields}/>Update 
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","lumpmonitoringDelete", "Delete")} disabled={!showAdditionalFields}/>Delete 
      </label></td>
      </tr>
      <tr><td>Production Skill 
      <label>
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","productionskillCreate", "Create")} disabled={!showAdditionalFields}/>Create    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","productionskillView", "View")} disabled={!showAdditionalFields}/>View    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","productionskillUpdate", "Update")} disabled={!showAdditionalFields}/>Update 
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","productionskillDelete", "Delete")} disabled={!showAdditionalFields}/>Delete 
        </label></td>
      <td>ECN Report  
      <label>
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","ecnreportCreate", "Create")} disabled={!showAdditionalFields}/>Create    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","ecnreportView", "View")} disabled={!showAdditionalFields}/>View    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","ecnreportUpdate", "Update")} disabled={!showAdditionalFields}/>Update 
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","ecnreportDelete", "Delete")} disabled={!showAdditionalFields}/>Delete 
        </label></td>
      </tr>
      <tr><td>ProcessValidation Revalidation  
      <label>
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","processvalidationrevalidationCreate", "Create")} disabled={!showAdditionalFields}/>Create    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","processvalidationrevalidationView", "View")} disabled={!showAdditionalFields}/>View    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","processvalidationrevalidationUpdate", "Update")} disabled={!showAdditionalFields}/>Update 
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","processvalidationrevalidationDelete", "Delete")} disabled={!showAdditionalFields}/>Delete 
        </label></td>
      <td>DailyShift Process CheckList  
      <label>
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","dailyshiftprocesschecklistCreate", "Create")} disabled={!showAdditionalFields}/>Create    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","dailyshiftprocesschecklistView", "View")} disabled={!showAdditionalFields}/>View    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","dailyshiftprocesschecklistUpdate", "Update")} disabled={!showAdditionalFields}/>Update 
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("PRODUCTION","dailyshiftprocesschecklistDelete", "Delete")} disabled={!showAdditionalFields}/>Delete 
        </label></td>
      </tr>
      <tr>
  <th colSpan={2} style={{ textAlign: "center"}}>
    Molding - Quality
    </th></tr>
    <tr><td>FirstOff -inspection  
    <label>
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("QUALITY","firstoffinsCreate", "Create")} disabled={!showAdditionalFields}/>Create    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("QUALITY","firstoffinsView", "View")} disabled={!showAdditionalFields}/>View    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("QUALITY","firstoffinsUpdate", "Update")} disabled={!showAdditionalFields}/>Update 
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("QUALITY","firstoffinsDelete", "Delete")} disabled={!showAdditionalFields}/>Delete 
        </label></td>
      <td>LastOff -inspection  
      <label>
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("QUALITY","lastoffinsCreate", "Create")} disabled={!showAdditionalFields}/>Create    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("QUALITY","lastoffinsView", "View")} disabled={!showAdditionalFields}/>View    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("QUALITY","lastoffinsUpdate", "Update")} disabled={!showAdditionalFields}/>Update 
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("QUALITY","lastoffinsDelete", "Delete")} disabled={!showAdditionalFields}/>Delete 
        </label></td>
      </tr>
      <tr><td>Online -inspection  
      <label>
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("QUALITY","onlineinsCreate", "Create")} disabled={!showAdditionalFields}/>Create    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("QUALITY","onlineinsView", "View")} disabled={!showAdditionalFields}/>View    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("QUALITY","onlineinsUpdate", "Update")} disabled={!showAdditionalFields}/>Update 
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("QUALITY","onlineinsDelete", "Delete")} disabled={!showAdditionalFields}/>Delete 
        </label></td>
      <td>Inline -inspection  
      <label>
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("QUALITY","inlineinsCreate", "Create")} disabled={!showAdditionalFields}/>Create    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("QUALITY","inlineinsView", "View")} disabled={!showAdditionalFields}/>View    
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("QUALITY","inlineinsUpdate", "Update")} disabled={!showAdditionalFields}/>Update 
      <input type="checkbox" style={{ marginRight: "5px", marginLeft: "20px" }} onChange={() => handleCheckboxChange("QUALITY","inlineinsDelete", "Delete")} disabled={!showAdditionalFields}/>Delete 
        </label></td>
      </tr>
   </table>
    <button type="submit" onClick={(e) => handleUpdate(e)}>
      Submit
    </button> 
   </form>   
</div>
    </div>
  );
}

export default AccessListTab;
