import React,{useState} from "react";
import { Tab, Tabs } from "react-bootstrap";
import "../../../Styles/Part3.css";
import MCActRequestReportCreate from "./MCActRequestReportCreate";
import MCActRequestReportView from "./MCActRequestReportView";

function MCActRequestReportTabs() {
  const [viewTabClicked, setViewTabClicked] = useState(false);

  const handleTabClick = (tabKey) => {
    if (tabKey === "tab2") {
      setViewTabClicked(true);
    } else {
      setViewTabClicked(false);
    }
  };
  
  return (
    <div>
      <div>
        <Tabs
          defaultActiveKey="tab1"
          // transition={false}
          id="noanim-tab-example"
          className="mb-3"
          onSelect={handleTabClick}
        >
          <Tab name="Report4MCreate-CreateTab" eventKey="tab1" title="Create">
            <div>
              <div className="set-layout">
                <MCActRequestReportCreate />
              </div>
            </div>
          </Tab>
          <Tab name="Report4MView-ViewTab" eventKey="tab2" title="View">
            <div>
              <div className="set-layout">
            {viewTabClicked && <MCActRequestReportView />} 
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default MCActRequestReportTabs;
