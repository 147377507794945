import React, { useState,useEffect } from "react";

import Base64Convertor from "../../../Lib/Base64Convertor";

import {
  PROCESS_TRANSACTION_URL,
  CODEGEN_URL,
  SECRET_KEY,
} from "../../../Utils/constants.js";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";

import "../../../Styles/Part3.css";
import IsDuplicate from "../../../Lib/IsDuplicate.js";

const initialState = {
  Id:"",
  SelectedOption: "",
  EmpCde: "",
  EmpNme: "",
  EmpDept: "",
  Loc: "",
  Desgn: "",
  Img: "",
  Gen: "",
  Sts: "",
  Flexy1: "",
  Flexy2: "",
  Flexy3: "",
  Flexy4: "",
  Emp_mas: [],
};

function EmployeeMasterCreate({tab1}) {
  const [isCreating, setIsCreating] = useState(false);
  const [empMasterState, setEmpMasterState] = useState(initialState);
  useEffect(() => {
    setEmpMasterState(initialState);
  }, [tab1]);
  const handleCreate = async (e) => {
    e.preventDefault();
    const trimmedCode = empMasterState.EmpCde.trim();
    const trimmedName= empMasterState.EmpNme.trim();

    const isSubmissionSuccessful = true;
    if (!empMasterState.EmpCde || !empMasterState.EmpNme || !empMasterState.Gen) {
    setIsCreating(true);

      alert(GeneralConstants.MANDATORY_ALERT);
      return;
    }
    try {
      const isDuplicateCode = await IsDuplicate("emp_details", [
        `emp_code="${trimmedCode}"`,
        `ActiveStatus = 'true'`,
      ]);

      if (isDuplicateCode) {
        alert(
          `Employee with code ${trimmedCode} already exists.`
        );
        return;
      }
      const isDuplicateName = await IsDuplicate("emp_details", [
        `emp_name="${trimmedName}"`,
        `ActiveStatus = 'true'`,
      ]);

      if (isDuplicateName) {
        alert(
          `Employee with name ${trimmedName} already exists.`
        );
        return;
      }
      const data = {
        data: [
          {
            Emp_code: trimmedCode,
            Emp_name: trimmedName,
            Emp_department: empMasterState.EmpDept,
            Emp_location: empMasterState.Loc,
            Emp_designation: empMasterState.Desgn,
            Emp_image: empMasterState.Img,
            Emp_gender: empMasterState.Gen,
            Emp_status: empMasterState.Sts,
            Emp_flex_01: empMasterState.Flexy1,
            Emp_flex_02: empMasterState.Flexy2,
            Emp_flex_03: empMasterState.Flexy3,
            Emp_flex_04: empMasterState.Flexy4,
            Emp_login:[{Emp_code:empMasterState.EmpCde,Emp_password:empMasterState.EmpCde,Emp_role:empMasterState.Desgn,Emp_group:empMasterState.EmpDept}]
          },
        ],
        _keyword_: "emp_details",
        secretkey: SECRET_KEY,
      };

      const response = await getAPIRequest(
        PROCESS_TRANSACTION_URL,
        "POST",
        data
      );
      const response1 = await response.json();

      if (response1.success === "false") {
        alert(GeneralConstants.FAIL_TO_SAVE_EMP);
        window.location.reload();
      } else {
        const empmas = [...empMasterState.Emp_mas, response.data];
        setEmpMasterState({ ...empMasterState, Emp_mas: empmas });
      }
      if (isSubmissionSuccessful) {
        alert(`Employee Master  ${GeneralConstants.SUBMISSION_SUCCESSFUL}`);
        setEmpMasterState({
          ...empMasterState,
          ...initialState,
        });
    setIsCreating(false);

      }
    } catch (error) {
      console.log(error);
      alert(`${empMasterState.EmpNme} ${GeneralConstants.SUBMISSION_FAILED}`);
      window.location.reload();
    }
  };
  const handlePhotoChangeAfter = async (e) => {
    const selectedFile = e.target.files[0];
    let img = await Base64Convertor(selectedFile);
    setEmpMasterState({
      ...empMasterState,
      Img: img,
    })
  };

  return (
    <div>
      <form
        id="empMasterCreate.formContainer"
        name="empMasterCreate.formContainer"
        className="form-empMaster-Create"
      >
        <table style={{ textAlign: "center", width: "100%" }} cellPadding="5">
          <tbody>
            <tr>
              <th
                id="empmc.Title"
                name="empmc.Title"
                colSpan="4"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">Employee Master</font>
              </th>
            </tr>
            <tr>
              <th>
                &nbsp;&nbsp;Employee Code<span style={{ color: "red" }}>*</span>
              </th>
              <td>
              {empMasterState.EmpCde === '' && isCreating && <span className="Req">Required *</span>}
                <input
                  id="empmc.EmployeeCode"
                  name="empmc.EmployeeCode"
                  type="text"
                  value={empMasterState.EmpCde}
                  onChange={(e) =>
                    setEmpMasterState({
                      ...empMasterState,
                      EmpCde: e.target.value,
                    })
                  }
                  onKeyDown={(e) => {
                    if (e.key === 'Backspace' && (empMasterState?.EmpCde || '') === 'EMP') {
                        e.preventDefault();
                    }
                }}
                  required
                />
              </td>
              <th>
                &nbsp;&nbsp;Employee Name<span style={{ color: "red" }}>*</span>
              </th>
              <td>
              {empMasterState.EmpNme === '' && isCreating && <span className="Req">Required *</span>}
                <input
                  id="empmc.EmployeeName"
                  name="empmc.EmployeeName"
                  type="text"
                  value={empMasterState.EmpNme}
                  onChange={(e) =>
                    setEmpMasterState({
                      ...empMasterState,
                      EmpNme: e.target.value,
                    })
                  }
                  required
                />
              </td>
            </tr>
            <tr>
              <th>&nbsp;&nbsp; Department </th>
              <td>
                <input
                  id="empmc.EmployeeDept"
                  name="empmc.EmployeeDept"
                  type="text"
                  value={empMasterState.EmpDept}
                  onChange={(e) =>
                    setEmpMasterState({
                      ...empMasterState,
                      EmpDept: e.target.value,
                    })
                  }
                />
              </td>
              <th>&nbsp;&nbsp;Location</th>
              <td>
                <input
                  id="empmc.EmployeeLocation"
                  name="empmc.EmployeeLocation"
                  type="text"
                  value={empMasterState.Loc}
                  onChange={(e) =>
                    setEmpMasterState({
                      ...empMasterState,
                      Loc: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <th>&nbsp;&nbsp;Designation</th>
              <td>
                <input
                  id="empmc.EmployeeDesgn"
                  name="empmc.EmployeeDesgn"
                  type="text"
                  value={empMasterState.Desgn}
                  onChange={(e) =>
                    setEmpMasterState({
                      ...empMasterState,
                      Desgn: e.target.value,
                    })
                  }
                />
              </td>
              <th>&nbsp;&nbsp;Image</th>
              <td>
                <input
                  id="empmc.EmployeeImage"
                  name="empmc.EmployeeImage"
                  type="file"
                  accept="Image/*"
                  onChange={handlePhotoChangeAfter}
                  
                />
                {empMasterState.Img?<img
                    src={empMasterState.Img}
                    alt="Selected"
                    style={{
                      display: "block",
                      width: "150px",
                      height: "150px",
                    }}
                  />:""}
              </td>
            </tr>
            <tr>
              <th>&nbsp;&nbsp;Gender<span style={{ color: "red" }}>*</span></th>
              <td>
                <div>
                {empMasterState.Gen === '' && isCreating && <span className="Req">Required *</span>}
                  <label className="emp-gender">
                 
                    <input
                      type="radio"
                      name="gender"
                      value="male"
                      checked={empMasterState.Gen === "male"}
                      onChange={(e) =>
                        setEmpMasterState({
                          ...empMasterState,
                          Gen: e.target.value,
                        })
                      }
                    />
                    Male&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </label>
                  <label className="emp-gender">
                    <input
                      type="radio"
                      name="gender"
                      value="female"
                      checked={empMasterState.Gen === "female"}
                      onChange={(e) =>
                        setEmpMasterState({
                          ...empMasterState,
                          Gen: e.target.value,
                        })
                      }
                    />
                    Female&nbsp;&nbsp;&nbsp;&nbsp;
                  </label>
                  <label className="emp-gender">
                    <input
                      type="radio"
                      name="gender"
                      value="others"
                      checked={empMasterState.Gen === "others"}
                      onChange={(e) =>
                        setEmpMasterState({
                          ...empMasterState,
                          Gen: e.target.value,
                        })
                      }
                    />
                    Others
                  </label>
                </div>

                {/* <input type="radio" id="empmc.EmployeeGender" name= "empmc.EmployeeGender" value={empMasterState.Gen}
                                    onChange={(e) => setEmpMasterState({
                                        ...empMasterState,
                                        Gen: e.target.value
                                    })} />
      <label for="Gneder">Male</label>
      <input type="radio"  name="Male" value="Male" />
      <label for="Gneder">Female</label>
      <input type="radio" name="Female" value="Female" />
      <label for="Gneder">Others</label>
      
      <input type="radio" name="Others" value="Others" /> */}
              </td>
              <th>&nbsp;&nbsp;Status</th>
              <td>
                <input
                  id="empmc.EmployeeStatus"
                  name="empmc.EmployeeStatus"
                  type="text"
                  value={empMasterState.Sts}
                  onChange={(e) =>
                    setEmpMasterState({
                      ...empMasterState,
                      Sts: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <th>&nbsp;&nbsp;Flex 1</th>
              <td>
                <input
                  id="empmc.Flexy"
                  name="empmc.Flexy1"
                  type="text"
                  value={empMasterState.Flexy1}
                  onChange={(e) =>
                    setEmpMasterState({
                      ...empMasterState,
                      Flexy1: e.target.value,
                    })
                  }
                />
              </td>
              <th>&nbsp;&nbsp;Flex 2</th>
              <td>
                <input
                  id="empmc.Flexy2"
                  name="empmc.Flexy2"
                  type="text"
                  value={empMasterState.Flexy2}
                  onChange={(e) =>
                    setEmpMasterState({
                      ...empMasterState,
                      Flexy2: e.target.value,
                    })
                  }
                />
              </td>
            </tr>

            <tr>
              <th>&nbsp;&nbsp;Flex 3</th>
              <td>
                <input
                  id="empmc.Flexy3"
                  name="empmc.Flexy3"
                  type="text"
                  value={empMasterState.Flexy3}
                  onChange={(e) =>
                    setEmpMasterState({
                      ...empMasterState,
                      Flexy3: e.target.value,
                    })
                  }
                />
              </td>
              <th>&nbsp;&nbsp;Flex 4</th>
              <td>
                <input
                  id="empmc.Flexy4"
                  name="empmc.Flexy4"
                  type="text"
                  value={empMasterState.Flexy4}
                  onChange={(e) =>
                    setEmpMasterState({
                      ...empMasterState,
                      Flexy4: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td colSpan="4" style={{ border: "none" }}>
                <button
                  type="submit"
                  id="empmc.submit"
                  name="empmc.submit"
                  onClick={(e) => handleCreate(e)}
                >
                  Submit
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
}

export default EmployeeMasterCreate;
