import React, { useEffect, useState } from "react";
// import Modal from 'react-modal';
import "./Field.css";
import Select, { components } from "react-select";
import ReadRecord from "../../Lib/API/ReadRecord";
import Base64Convertor from "../../Lib/Base64Convertor";
import { all } from "axios";
import PopupPdf from "../Popup/PopupPdf";
import Popup from "../Popup/Popup";
import { pdf } from "@react-pdf/renderer";

const Field = ({
  id,
  label,
  name,
  options,
  value,
  onClick,
  doubleLine,
  warning,
  OnChange,
  style,
  min,
  max,
  accept,
  className,
  classLabel,
  buttonStyle,
  labelStyle,
  onkeydown,
  isMulti,
}) => {
  const [values, setvalues] = useState([
    { value: "0", label: "Select record" },
  ]);
  const [error, seterror] = useState(false);
  const [Pdf, setPdf] = useState(false);
  const [ImageTag, setImageTag] = useState(true);
  const [ButtonPopup, setButtonPopup] = useState(false);
  const [selectValue, setselectValue] = useState([{ value: "", label: "" }]);
  const [Key, setKey] = useState({ key: "", value: "" });
  const [Keys, setKeys] = useState({ key: "", value: "" });
  const keyvalue = {
    mac_part_dtl_dropdown: { key: "mc_part_code", value: "mc_part_name" },
    Get_part_name_from_head: { key: "mc_part_name", value: "mc_part_name" },
    Get_part_name_from_quality_for_smp: {
      key: "mc_part_name",
      value: "mc_part_name",
    },
    Get_part_code_from_head: { key: "mc_part_code", value: "mc_part_code" },
    Get_part_code_from_quality_for_smp: {
      key: "mc_part_code",
      value: "mc_part_code",
    },
    mac_part_dtl_dropdown: { key: "mc_part_code", value: "mc_part_name" },
    rm_details_dropdown: { key: "Rm_code", value: "Rm_description" },
    mac_dtl_dropdown_like: { key: "mc_code", value: "mc_name" },
    emp_details_dropdown: { key: "Emp_code", value: "Emp_name" },
    customer_details_dropdown: { key: "Cus_code", value: "Cus_name" },
    Raw_material: { key: "Mh_raw_mat", value: "Mh_raw_mat_name" },
    Keyvalue: { key: "value", value: "label" },
    Get_part_no_from_Quality_plan: { key: "Part_number", value: "Part_number" },
    Get_part_name_from_Quality_plan: { key: "Part_name", value: "Part_name" },
    Machine_Number: { key: "mh_mac_name", value: "mh_mac_name" },
    Machine_Number_master: { key: "mc_code", value: "mc_name" },
    Get_paint_name: { key: "Paint_code", value: "Paint_name" },
    Get_paint_part_name: { key: "Part_code", value: "Part_name" },
    Get_paint_program_name: { key: "Program_code", value: "Program_name" },
    Get_paint_customer_name: { key: "Customer_code", value: "Customer_name" },
    Get_paint_supplier_name: { key: "Supplier_code", value: "Supplier_name" },
    Get_paint_hardner_name: { key: "Hardner_code", value: "Hardner_name" },
    Get_paint_thinner_name: { key: "Thinner_code", value: "Thinner_name" },
    Get_paint_batch_no: { key: "Batch_no", value: "Batch_no" },
  };

  // select the value for the dropdown react select
  function HandleDropdown(val) {
    setvalues(options.values);
    // console.log("Values",options.values);
    // setKeys({key:"Mh_raw_mat",value:"Mh_raw_mat"});
    setKeys(keyvalue[options.reference]);
  }

  const handleIconClick = () => {
    const fileInput = document.getElementById(`${name}-file-input`);
    if (fileInput) fileInput.click();
  };
  function GetSelectValue(value) {
    if (value.length > 0) {
      // console.log(
      //   "GetSelectValue",
      //   "_keyword_",
      //   options.reference,
      //   "key",
      //   "%" + value + "%",
      //   "value",
      //   "%" + value + "%"
      // );
      ReadRecord({
        _keyword_: options.reference,
        key: "%" + value + "%",
        value: "%" + value + "%",
      }).then((res) => {
        seterror(false);
        if (res.rows > 0) {
          // console.log(
          //   res.data
          // );
          setvalues(res.data);
          setKey(keyvalue[options.reference]);
        } else {
          // alert("Not Available!")
          seterror(true);
        }
      });
    }
  }
  const isImageFile =
    accept && (accept.includes("image/") || accept.includes("application/pdf"));
  useEffect(() => {
    if (!(options.key == "" || options.key == undefined)) {
      GetSelectValue(options.key);
      // console.log("options.key", options.key);
    }
    // HandleDropdown()
  }, [options.key]);

  const customStyles = {
    indicatorsContainer: (provided) => ({
      ...provided,
      display: "none",
    }),
  };
  const viewPagination = () => {
    console.log("");
  };
  return (
    <div>
      <div
        style={style}
        className={
          options.type !== "Button"
            ? label
              ? doubleLine
                ? "Field SingleGrid " + className
                : "Field DoubleGrid " + className
              : "Field SingleGrid " + className
            : "Field " + className
        }
        hidden={options.visible == "0"}
      >
        {label && options.type !== "Button" && (
          <label className={classLabel} style={labelStyle}>
            {label}:
            {options.required && <span style={{ color: "red" }}>*</span>}
          </label>
        )}
        {options.type === "Button" ? (
          <div className="DivButton">
            <button
              // style={{buttonStyle}}
              type="button"
              onClick={(e) => {
                onClick(e);
              }}
              className={
                buttonStyle ? buttonStyle : "Button " + " " + className
              }
            >
              {label}
            </button>
          </div>
        ) : options.type === "Icon1" ? (
          <div className="DivButton">
            <span style={{ color: "red" }}>*</span>

            <input
              id={`${name}-file-input`}
              name={name}
              autoComplete="off"
              //  className="hidden-input"

              style={{ width: "100px", padding: "0px" }}
              type="file"
              defaultValue={options.defaultValue}
              disabled={options.disabled}
              // onClick={handleIconClick}
              onChange={async (e) => {
                const selectedFile = e.target.files[0];
                if (selectedFile) {
                  if (
                    selectedFile.name.includes(".pdf") ||
                    selectedFile.name.includes(".jpg") ||
                    selectedFile.name.includes(".png") ||
                    selectedFile.name.includes(".jpeg")
                  ) {
                    setImageTag(true);
                    let img = await Base64Convertor(selectedFile);
                    OnChange({
                      target: { name: name, value: img, label: e.target.value },
                    });
                  } else {
                    setImageTag(false);

                    const img = document.getElementById(name);
                    img.value = "";
                    const Pdf = document.getElementById("Pdf");
                  }
                }
              }}
              accept={accept}
              className={
                (options.required &&
                  warning &&
                  value[name] === false &&
                  value[name] === "") ||
                (value[name] === "" && warning)
                  ? "required"
                  : ""
              }
            />
            {ImageTag &&
              (value[name]?.includes("image/") ||
                value[name]?.includes("application/pdf")) && (
                <div style={{ marginTop: "5px", padding: "5px" }}>
                  <span
                    style={{ border: "1px solid blue", left: "5px" }}
                    onClick={() => setButtonPopup(true)}
                  >
                    view
                  </span>
                  <PopupPdf
                    trigger={ButtonPopup}
                    setTrigger={setButtonPopup}
                    viewPagination={viewPagination}
                  >
                    <object
                      width="80%"
                      height="90%"
                      data={value[name]}
                      style={{ zIndex: "100%", marginLeft: "30%" }}
                      type="application/pdf"
                    >
                      <p>
                        Your browser does not support viewing PDFs.
                        <a
                          href={value[name]}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Click here to view the PDF.
                        </a>
                      </p>
                    </object>
                  </PopupPdf>
                </div>
              )}
            {!ImageTag && (
              <div style={{ marginTop: "10px", color: "red" }}>
                Please select Image or PDF file.
              </div>
            )}
            {ImageTag && value[label]?.includes("application/pdf") && (
              <div style={{ marginTop: "10px" }}>
                <button id="Pdf" onClick={() => setButtonPopup(true)}>
                  View
                </button>
                <PopupPdf
                  trigger={ButtonPopup}
                  setTrigger={setButtonPopup}
                  viewPagination={viewPagination}
                >
                  <object
                    width="90%"
                    height="90%"
                    data={value[label]}
                    style={{ zIndex: "100%" }}
                    type="application/pdf"
                  >
                    <p>
                      Your browser does not support viewing PDFs.
                      <a
                        href={value[label]}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click here to view the PDF.
                      </a>
                    </p>
                  </object>
                </PopupPdf>
              </div>
            )}
            {/* <span className="Icon" onClick={handleIconClick}>
                Img
              </span> */}
          </div>
        ) : options.type === "Icon2" ? (
          <div className="DivButton">
            <input
              id={`${name}-file-input`}
              name={name}
              autoComplete="off"
              //  className="hidden-input"

              style={{ width: "100px", padding: "0px" }}
              type="file"
              defaultValue={options.defaultValue}
              disabled={options.disabled}
              onChange={async (e) => {
                const selectedFile = e.target.files[0];
                if (selectedFile) {
                  if (
                    selectedFile.name.includes(".pdf") ||
                    selectedFile.name.includes(".jpg") ||
                    selectedFile.name.includes(".png") ||
                    selectedFile.name.includes(".jpeg")
                  ) {
                    setImageTag(true);
                    let img = await Base64Convertor(selectedFile);
                    OnChange({
                      target: { name: name, value: img, label: e.target.value },
                    });
                  } else {
                    setImageTag(false);

                    const img = document.getElementById(name);
                    img.value = "";
                    const Pdf = document.getElementById("Pdf");
                  }
                }
              }}
              accept={accept}
              className={
                (options.required &&
                  warning &&
                  value[name] === false &&
                  value[name] === "") ||
                (value[name] === "" && warning)
                  ? "required"
                  : ""
              }
            />
            {ImageTag &&
              (value[name]?.includes("image/") ||
                value[name]?.includes("application/pdf")) && (
                <div style={{ marginTop: "5px", padding: "5px" }}>
                  <span
                    style={{ border: "1px solid blue", left: "5px" }}
                    onClick={() => setButtonPopup(true)}
                  >
                    view
                  </span>
                  <PopupPdf
                    trigger={ButtonPopup}
                    setTrigger={setButtonPopup}
                    viewPagination={viewPagination}
                  >
                    <object
                      width="80%"
                      height="90%"
                      data={value[name]}
                      style={{ zIndex: "100%", marginLeft: "30%" }}
                      type="application/pdf"
                    >
                      <p>
                        Your browser does not support viewing PDFs.
                        <a
                          href={value[name]}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Click here to view the PDF.
                        </a>
                      </p>
                    </object>
                  </PopupPdf>
                </div>
              )}
            {!ImageTag && (
              <div style={{ marginTop: "10px", color: "red" }}>
                Please select Image or PDF file.
              </div>
            )}
            {ImageTag && value[label]?.includes("application/pdf") && (
              <div style={{ marginTop: "10px" }}>
                <button id="Pdf" onClick={() => setButtonPopup(true)}>
                  View
                </button>
                <PopupPdf
                  trigger={ButtonPopup}
                  setTrigger={setButtonPopup}
                  viewPagination={viewPagination}
                >
                  <object
                    width="90%"
                    height="90%"
                    data={value[label]}
                    style={{ zIndex: "100%" }}
                    type="application/pdf"
                  >
                    <p>
                      Your browser does not support viewing PDFs.
                      <a
                        href={value[label]}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click here to view the PDF.
                      </a>
                    </p>
                  </object>
                </PopupPdf>
              </div>
            )}
          </div>
        ) : options.type === "Icon" ? (
          <div className="DivButton">
            <input
              id={`${name}-file-input`}
              name={name}
              autoComplete="off"
              className="hidden-input"
              style={{ display: "none" }}
              type="file"
              defaultValue={options.defaultValue}
              disabled={options.disabled}
              onChange={async (e) => {
                const selectedFile = e.target.files[0];
                if (selectedFile) {
                  if (
                    selectedFile.name.includes(".pdf") ||
                    selectedFile.name.includes(".jpg") ||
                    selectedFile.name.includes(".png") ||
                    selectedFile.name.includes(".jpeg")
                  ) {
                    setImageTag(true);
                    let img = await Base64Convertor(selectedFile);
                    OnChange({
                      target: { name: name, value: img, label: e.target.value },
                    });
                  } else {
                    setImageTag(false);

                    const img = document.getElementById(name);
                    img.value = "";
                    const Pdf = document.getElementById("Pdf");
                  }
                }
              }}
              accept={accept}
            />

            {ImageTag && value[name]?.includes("image/") && (
              <div style={{ marginTop: "10px" }}>
                <img
                  id="Img"
                  width={100}
                  height={100}
                  alt={name}
                  src={value[name]}
                />
              </div>
            )}
            {!ImageTag && (
              /* {ImageTag && !(value[label].includes("image/") || value[label].includes("application/pdf")) && ( */
              <div style={{ marginTop: "10px", color: "red" }}>
                Please select Image or PDF file.
              </div>
            )}
            {ImageTag && value[label]?.includes("application/pdf") && (
              <div style={{ marginTop: "10px" }}>
                {/* <iframe 
                width="500px"
                  height="350px"
                  alt={name}
                  src={value[name]}></iframe> */}
                <button id="Pdf" onClick={() => setButtonPopup(true)}>
                  View
                </button>
                <PopupPdf
                  trigger={ButtonPopup}
                  setTrigger={setButtonPopup}
                  viewPagination={viewPagination}
                >
                  <object
                    width="90%"
                    height="90%"
                    data={value[label]}
                    style={{ zIndex: "100%" }}
                    type="application/pdf"
                  >
                    <p>
                      Your browser does not support viewing PDFs.
                      <a
                        href={value[label]}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click here to view the PDF.
                      </a>
                    </p>
                  </object>
                </PopupPdf>
              </div>
            )}
            <span className="Icon" onClick={handleIconClick}>
              Img
            </span>
          </div>
        ) : options.type === "boolean" ? (
          <div className={name}>
            <input
              style={{ height: "30px" }}
              autocomplete="off"
              id={name}
              name={name}
              type={"checkbox"}
              className={
                (options.required && warning && value[name] === undefined) ||
                value[name] === ""
                  ? "required"
                  : ""
              }
              // value={value[name]}
              checked={value[name] == "true"}
              onChange={(e) => {
                OnChange(e);
              }}
              defaultValue={options.defaultValue}
              disabled={options.disabled}
              required={options.required}
            />
          </div>
        ) : options.type === "smallboolean" ? (
          <div className={name}>
            <input
              style={{ height: "15px" }}
              autocomplete="off"
              id={name}
              name={name}
              type={"checkbox"}
              className={
                (options.required && warning && value[name] === undefined) ||
                value[name] === ""
                  ? "required"
                  : ""
              }
              // value={value[name]}
              checked={value[name] == "true"}
              onChange={(e) => {
                OnChange(e);
              }}
              defaultValue={options.defaultValue}
              disabled={options.disabled}
              required={options.required}
            />
          </div>
        ) : options.type === "dropdown" ? (
          <div className={name}>
            <select
              id={name}
              autoComplete="off"
              name={name}
              style={{ textAlign: "center" }}
              disabled={options.disabled}
              value={value[name]}
              onChange={(e) => {
                OnChange(e);
              }}
              className={
                options.required &&
                warning &&
                (value[name] === undefined || value[name] === "")
                  ? "required"
                  : ""
              }
            >
              {options.values.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        ) : options.type === "SingleChoice" ? (
          <div className={name}>
            {options.values.map((item) => (
              <div
                className="singleFlex"
                style={{ padding: "5px", border: "1px solid gray" }}
              >
                <label style={{ width: "120px" }}>{item}</label>
                <input
                  id={name}
                  name={item}
                  autocomplete="off"
                  className={
                    (options.required &&
                      warning &&
                      value[name] === undefined) ||
                    value[name] === ""
                      ? "required"
                      : ""
                  }
                  type={"radio"}
                  checked={value[name] === item}
                  onChange={(e) => {
                    OnChange({ target: { name: name, value: e.target.name } });
                  }}
                  onKeyDown={onkeydown}
                  defaultValue={options.defaultValue}
                  disabled={options.disabled}
                  required={options.required}
                />
              </div>
            ))}
          </div>
        ) : options.type === "reference" ? (
          <div
            className={
              error
                ? "required"
                : "" + options.required && warning && value.value === undefined
                ? "required " + name
                : " " + name
            }
          >
            <Select
              styles={customStyles}
              id={name}
              name={name}
              onInputChange={(e) => {
                GetSelectValue(e);
              }}
              onChange={(e) => {
                seterror(false);
                OnChange({ values: e, name: name });
              }}
              options={values.map((item) => ({
                value: item[Key.key],
                label: item[Key.value],
              }))}
              value={
                value.value === undefined || value.value === "" ? null : value
              }
              // value={value}
              placeholder="Search..."
              isDisabled={options.disabled}
              required={options.required}
            />
          </div>
        ) : options.type === "NoneReference" ? (
          <div
            className={
              options.required && warning && value.value === undefined
                ? "required " + name
                : " " + name
            }
          >
            {/* {console.log("value", value)} */}
            <Select
              styles={customStyles}
              id={name}
              name={name}
              onFocus={(e) => {
                HandleDropdown(e);
              }}
              isMulti={isMulti}
              onChange={(e) => {
                seterror(false);
                // console.log("NoneReference", e);
                OnChange({ values: e, name: name });
              }}
              // onClick={(e)=>{}}
              options={values.map((item) => {
                // console.log(
                //   "item",
                //   item,
                //   Keys.key,
                //   item[Keys.key],
                //   item[Keys.value]
                // );
                return {
                  value: item[Keys.key],
                  label: item[Keys.value],
                };
              })}
              // value={
              //   value.value === undefined || value.value === "" ? null : value
              // }

              value={value}
              placeholder="Search..."
              isDisabled={options.disabled}
              required={options.required}
            />
          </div>
        ) : options.type === "textarea" ? (
          <div className={name}>
            <textarea
              id={name}
              name={name}
              value={value[name]}
              // className={options.required && warning && value[name] === undefined || value[name] === "" ? "required" : ""}
              // type={"text"}
              disabled={options.disabled}
              defaultValue={options.defaultValue}
              onChange={(e) => {
                // console.log("ee",e);
                OnChange(e);
              }}
            />
          </div>
        ) : options.type === "image" ? (
          <div className={name}>
            <input
              id={name}
              name={name}
              autocomplete="off"
              className={
                options.required &&
                warning &&
                (value[name] === undefined || value[name] === "")
                  ? "required"
                  : ""
              }
              type={"file"}
              defaultValue={options.defaultValue}
              disabled={options.disabled}
              onChange={async (e) => {
                const selectedFile = e.target.files[0];
                if (selectedFile) {
                  if (
                    selectedFile.name.includes(".pdf") ||
                    selectedFile.name.includes(".jpg") ||
                    selectedFile.name.includes(".png") ||
                    selectedFile.name.includes(".jpeg")
                  ) {
                    setImageTag(true);
                    let img = await Base64Convertor(selectedFile);
                    OnChange({
                      target: { name: name, value: img, label: e.target.value },
                    });
                    // console.log("selectedFile.name", selectedFile.name);
                  } else {
                    setImageTag(false);
                    // console.log("selectedFile.name", selectedFile.name);
                    // console.log("selectedFile.name", e);
                    const img = document.getElementById(name);
                    img.value = "";
                    const Pdf = document.getElementById("Pdf");
                    // try {
                    //         // img.setAttribute('src', '');
                    // Pdf.setAttribute("hidden", "true");

                    //       } catch {
                    //         console.log("No attribute");
                    //       }
                    // OnChange({
                    //   target: { name: name, value: "", label:"" },
                    // });
                  }
                }
              }}
              accept={accept}
            />

            {ImageTag && value[name]?.includes("image/") && (
              <div style={{ marginTop: "10px" }}>
                <img
                  id="Img"
                  width={250}
                  height={200}
                  alt={name}
                  src={value[name]}
                />
              </div>
            )}
            {!ImageTag && (
              /* {ImageTag && !(value[label].includes("image/") || value[label].includes("application/pdf")) && ( */
              <div style={{ marginTop: "10px", color: "red" }}>
                Please select Image or PDF file.
              </div>
            )}
            {ImageTag && value[label]?.includes("application/pdf") && (
              <div style={{ marginTop: "10px" }}>
                {/* <iframe 
                width="500px"
                  height="350px"
                  alt={name}
                  src={value[name]}></iframe> */}
                <button id="Pdf" onClick={() => setButtonPopup(true)}>
                  View
                </button>
                <PopupPdf
                  trigger={ButtonPopup}
                  setTrigger={setButtonPopup}
                  viewPagination={viewPagination}
                >
                  <object
                    width="90%"
                    height="90%"
                    data={value[label]}
                    style={{ zIndex: "100%" }}
                    type="application/pdf"
                  >
                    <p>
                      Your browser does not support viewing PDFs.
                      <a
                        href={value[label]}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click here to view the PDF.
                      </a>
                    </p>
                  </object>
                </PopupPdf>
              </div>
            )}
          </div>
        ) : (
          <div className={name}>
            <input
              id={id ? id + "-" + name : name}
              name={name}
              autocomplete="off"
              className={
                options.required &&
                warning &&
                (value[name] === undefined || value[name] === "")
                  ? "required"
                  : max > min &&
                    (value[name] > max || value[name] < min) &&
                    !(value[name] === undefined || value[name] === "")
                  ? "warning"
                  : max < min &&
                    (value[name] < max || value[name] > min) &&
                    !(value[name] === undefined || value[name] === "")
                  ? "warning"
                  : ""
              }
              type={options.type}
              defaultValue={options.defaultValue}
              value={value[name]}
              onChange={(e) => {
                OnChange(e);
              }}
              disabled={options.disabled}
              required={options.required}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Field;
