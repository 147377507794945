import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Field from "../../../../Components/Fields/Field.js";
import LoadingPage from "../../../../Components/Handler/LoadingPage.js";
import * as GeneralConstants from "../../../../Utils/GeneralConstants.js";
import IsDuplicatePaint from "../../../../Lib/IsDuplicatePaint.js";
import Lable from "../../../../Components/Fields/Lable.js";
import QaLayoutPaint from "../../QaLayoutPaint.js";
import CreateRecordPaint from "../../../../Lib/API/CreateRecordPaint.js";
import ReadRecordPaint from "../../../../Lib/API/ReadRecordPaint.js";
import UpdateRecordPaint from "../../../../Lib/API/UpdateRecordPaint.js";
const initState = {
  // TrackDate: new Date().toISOString().split("T")[0],
  Paint_code: "",
  Paint_name: "",
  Uom: "",
  Active_status: "Active",
};

function PaintMaser() {
  const [Create, setCreate] = useState(initState);
  const [warning, setwarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const { option, id } = useParams();
  const navigate = useNavigate();
  // useEffect(() => {
  //   console.log("Create", Create);
  // }, [Create]);

  useEffect(() => {
    // setwarning(false);
    document.title = "Paint Master";
    const fetchData = async () => {
      if (id === undefined) {
        setCreate(initState);
      } else {
        setLoading(true);
        try {
          const res = await ReadRecordPaint({
            _keyword_: "paint_master",
            Id: id,
          });
          console.log("res", res);
          setCreate(res.data[0]);
          // console.log("Res2",Create)
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, []);
  if (loading) {
    return <LoadingPage />;
  }

  const handleCreate = async () => {
    try {
      const IsDuplicatePaintCode = await IsDuplicatePaint("paint_master", [
        `paint_code="${Create.Paint_code}"`,
      ]);

      if (IsDuplicatePaintCode) {
        alert(`Paint with code ${Create.Paint_code} already exists.`);
        return;
      }

      const IsDuplicatePaintName = await IsDuplicatePaint("paint_master", [
        `paint_name="${Create.Paint_name}"`,
      ]);

      if (IsDuplicatePaintName) {
        alert(`Paint with name ${Create.Paint_name} already exists.`);
        return;
      }

      console.log(Create);
      if (
        !Create.Paint_code ||
        !Create.Paint_name ||
        !Create.Uom ||
        !Create.Active_status
      ) {
        alert("Please Fill the Mandatory Fields");
          setwarning(true);
        return;
      }

      let record = [{ ...Create }];
      const res = await CreateRecordPaint("paint_master", record);
      console.log("HandleCreate", res);

      alert(GeneralConstants.SUBMISSION_SUCCESSFUL);
       setwarning(false);
      setCreate(initState);
    } catch (error) {
      console.error(error);
      alert(GeneralConstants.SUBMISSION_FAILED);
        setwarning(false);
    }
  };

  const handleUpdate = async () => {
    try {
      if (
        !Create.Paint_code ||
        !Create.Paint_name ||
        !Create.Uom ||
        !Create.Active_status
      ) {
        alert("Please Fill the Mandatory Fields");
         setwarning(true);
        return;
      }
      let record = [{ ...Create }];
      const res = await UpdateRecordPaint("paint_master", record);
      console.log("HandleUpdate", res);

      alert(GeneralConstants.UPDATE_SUCCESSFUL);
        setwarning(false);
    } catch (error) {
      console.error(error);
      alert(GeneralConstants.UPDATE_FAILURE);
    }
    navigate(-1);
  };

  return (
    <QaLayoutPaint>
      <div>
        <table>
          <tbody>
            <tr>
              <th colSpan={8}>
                <Lable value={"Paint Master"} type={"Head"} />
              </th>
            </tr>
            <tr>
              <td>
                <Field
                  label={"Paint Code"}
                  name={"Paint_code"}
                  options={{
                    type: "text",
                    required: true,
                    disabled: option === "view" || option === "edit",
                  }}
                  warning={warning}
                  value={Create}
                  OnChange={(e) =>
                    setCreate({ ...Create, Paint_code: e.target.value })
                  }
                />
              </td>
              <td>
                <Field
                  label={"Paint Name"}
                  name={"Paint_name"}
                  options={{
                    type: "text",
                    required: true,
                    disabled: option === "view" || option === "edit",
                  }}
                  warning={warning}
                  value={Create}
                  OnChange={(e) =>
                    setCreate({ ...Create, Paint_name: e.target.value })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>
                <Field
                  label={"UOM"}
                  name={"Uom"}
                  options={{
                    type: "dropdown",
                    required: true,
                    disabled: option === "view",
                    values: [
                      { value: "", label: "Select" },
                      { value: "gm", label: "GM" },
                      { value: "kgcm", label: "KGCM" },
                      { value: "kgs", label: "KGS" },
                      { value: "LIT", label: "LIT" },
                      { value: "Ml", label: "ML" },
                      { value: "Nos", label: "NOS" },
                    ],
                  }}
                  warning={warning}
                  value={Create}
                  OnChange={(e) =>
                    setCreate({ ...Create, Uom: e.target.value })
                  }
                />
              </td>
              <td>
                <Field
                  label={"Active Status"}
                  name={"Active_status"}
                  options={{
                    type: "dropdown",
                    required: true,
                    disabled: option === "view",
                    values: [
                      { value: "", label: "Select" },
                      { value: "Active", label: "Active" },
                      { value: "InActive", label: "InActive" },
                    ],
                  }}
                  warning={warning}
                  value={Create}
                  OnChange={(e) =>
                    setCreate({ ...Create, Active_status: e.target.value })
                  }
                />
              </td>
            </tr>

            {!(option === "view") && (
              <tr>
                {id === undefined ? (
                  <td colSpan={19}>
                    {" "}
                    <Field
                      label={"Submit"}
                      options={{ type: "Button" }}
                      onClick={(e) => {
                        handleCreate(e);
                      }}
                    />
                  </td>
                ) : (
                  <td colSpan={19}>
                    {" "}
                    <Field
                      label={"Update"}
                      options={{ type: "Button" }}
                      onClick={(e) => {
                        handleUpdate(e);
                      }}
                    />
                  </td>
                )}
              </tr>
            )}
          </tbody>
        </table>
      </div>
      </QaLayoutPaint>
  );
}

export default PaintMaser;
