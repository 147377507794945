import React, { useState, useEffect } from "react";
import { SECRET_KEY, PROCESS_TRANSACTION_UPDATE_URL } from "../../../Utils/constants.js";
import Popup from "../../../Components/Popup/Popup.js";
import HandleRead from "../../../Lib/HandleRead.js"
import Pagination from "../../../Components/Pagination.js";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import UpdateRecord from "../../../Lib/updateRecord.js";
import { MdDelete } from "react-icons/md";
import ReadRecord from "../../../Lib/API/ReadRecord.js";

function MaterialStandardView() {

  const [MatStand, setMatStand] = useState({
    Material: "",
    DryTemp: "",
    DryTempTol: "",
    DMinHrs: "",
    DMaxHrs: "",
    Id: "",
    ActSts: "",
    isPaginationVisible: true,
    currentPage: 1,
    filterMat: "",
    filterprtno: "",
    Mat_stand: []
  })

  const [ButtonPopup2, setButtonPopup2] = useState(false);
  const [ButtonPopup, setButtonPopup] = useState(false);
  const [mat_std, setmat_std] = useState([]);
  const recordPerPage = 20;

  const filterRecords = () => {
    const filteredData = mat_std.filter((record) => {
      const mcnoMatch = record.Material
        .toLowerCase()
        .includes(MatStand.filterMat.toLowerCase());
      return mcnoMatch;
    });
    return filteredData || [];
  };

  const filteredData = filterRecords();
  const npage = Math.ceil(filteredData.length / recordPerPage);
  const numbers = [...Array(npage).keys()].map((num) => num + 1);

  function handleFilterMaterial(event) {
    setMatStand({
      ...MatStand,
      currentPage: 1,
      filterMat: event.target.value
    });
  }
  const firstIndex = (MatStand.currentPage - 1) * recordPerPage;
  const lastIndex = firstIndex + recordPerPage;
  const filteredRecords = filteredData.slice(firstIndex, lastIndex);

  function prePage() {
    if (MatStand.currentPage > 1) {
      setMatStand({
        ...MatStand,
        currentPage: MatStand.currentPage - 1
      });
    }
  }

  function nextPage() {
    if (MatStand.currentPage < npage) {
      setMatStand({
        ...MatStand,
        currentPage: MatStand.currentPage + 1
      });
    }
  }

  function changeCpage(id) {
    setMatStand({
      ...MatStand,
      currentPage: id
    });
  }

  const transferValue2 = (e) => {
    setMatStand({
      ...MatStand,
      isPaginationVisible: false,
      Id: e.Id,
      Material: e.Material,
      DryTemp: e.Dry_temp,
      DryTempTol: e.Dry_temp_tol,
      DMaxHrs: e.Max_hrs,
      DMinHrs: e.Min_hrs,
      ActSts: e.ActiveStatus,
    });
    setButtonPopup2(true);

  };

  const viewPagination = () => {
    setMatStand({
      ...MatStand,
      isPaginationVisible: true
    });
  };

  const handleEdit = (e) => {
    setMatStand({
      ...MatStand,
      isPaginationVisible: false,
      Id: e.Id,
      Material: e.Material,
      DryTemp: e.Dry_temp,
      DryTempTol: e.Dry_temp_tol,
      DMaxHrs: e.Max_hrs,
      DMinHrs: e.Min_hrs,
      ActSts: e.ActiveStatus,
    });
    setButtonPopup(true);
    console.log(e)

  };
  
  
  const handleUpdate = async (e) => {
    e.preventDefault();
    if ( !MatStand.DryTemp || !MatStand.DryTempTol || !MatStand.DMinHrs || !MatStand.DMaxHrs || !MatStand.ActSts) {
      alert(GeneralConstants.MANDATORY_ALERT);
      return;
    }

    const data = {
      data: [
        {
          Id: MatStand.Id,
          Material: MatStand.Material,
          Dry_temp: MatStand.DryTemp,
          Dry_temp_tol: MatStand.DryTempTol,
          Min_hrs: MatStand.DMinHrs,
          Max_hrs: MatStand.DMaxHrs,
          ActiveStatus: MatStand.ActSts,
        }
      ]
    };
    data["_keyword_"] = "material_standard_details";
    data["secretkey"] = SECRET_KEY;

    const response = await getAPIRequest(
      PROCESS_TRANSACTION_UPDATE_URL, 'POST', data
    ).then((response) => {
      setMatStand({
        ...MatStand,
        Material: "",
        DryTemp: "",
        DryTempTol: "",
        DMinHrs: "",
        DMaxHrs: "",
        Id: "",
        ActSts: "",
        isPaginationVisible: true,
      });
      console.log("response", response);
      alert(`${GeneralConstants.UPDATE_SUCCESSFUL}`);
      // window.location.reload();
      setButtonPopup(false);
      handleReadMaterialStand();

    }).catch((error) => {
      console.log(error);
      alert(GeneralConstants.UPDATE_FAILURE);
      window.location.reload();
    });

    // const response1 = await response.json();
    // if (response1.success === "true") {
    //   // const matstd = [...MatStand.Mat_stand, response.data];
    //   // setMatStand({ ...MatStand, Mat_stand: matstd });
    //   alert(`Material Standard Code ${GeneralConstants.SUBMISSION_SUCCESSFUL}`);
    //   window.location.reload();
    // } else {
    //   alert(GeneralConstants.FAIL_TO_SAVE_MACHINE);
    // }
  };
  function handleReadMaterialStand() {
    ReadRecord({
      _keyword_: "material_standard_details - List"
    }).then((res) => {
      // console.log(res.data);
      setmat_std(res.data);
    });
  }
  useEffect(() => {
    handleReadMaterialStand();
  }, [])
  return (
    <div>
      <Popup
        trigger={ButtonPopup2}
        setTrigger={setButtonPopup2}
        viewPagination={viewPagination}
      >
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <form className="form-machinePartMaster-View">
          <table style={{ textAlign: "center", width: "100%" }} cellPadding="5" >
            <tbody>
              <tr>
                <th
                  id="mtstd.Title.View"
                  name="mtstd.Title.View"
                  colSpan="4"
                  style={{ textAlign: "center", whiteSpace: "nowrap" }}
                >
                  <font color="#8B0000">View - Material Standard</font>
                </th>
              </tr>
              <tr>
                <th
                  width="25%"
                  style={{ textAlign: "Center", whiteSpace: "nowrap" }}
                >
                  Material
                </th>
                <td width="25%">
                  <label id="mtstd.matstdmat.View"
                    name="mtstd.matstdmat.View" >{MatStand.Material}</label>
                </td>
                <th
                  width="25%"
                  style={{ textAlign: "Center", whiteSpace: "nowrap" }}
                >
                  Dry Temperature
                </th>
                <td width="25%">
                  <label id="mtstd.matstddt.View"
                    name="mtstd.matstddt.View">{MatStand.DryTemp} ± {MatStand.DryTempTol}</label>
                </td>
              </tr>
              <tr>
                <th
                  width="25%"
                  style={{ textAlign: "Center", whiteSpace: "nowrap" }}
                >
                  Time
                </th>
                <td width="25%">
                  <label id="mtstd.matstdmin.view"
                    name="mtstd.matstdmatmin.view">{MatStand.DMinHrs} - {MatStand.DMaxHrs}</label>
                </td>


                <th style={{ textAlign: "Center", whiteSpace: "nowrap" }}>
                  Active Status
                </th>
                <td>
                  <label id="mtstd.matstdmatAs.view"
                    name="mtstd.matstdmatAs.view">{MatStand.ActSts}</label>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </Popup>
      <Popup
        trigger={ButtonPopup}
        setTrigger={setButtonPopup}
        viewPagination={viewPagination}
      >
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <form
          className="form-machinePartMaster-Edit">
          <table style={{ textAlign: "center", width: "100%" }} cellPadding="5">
            <tbody>
              <tr>
                <th
                  id="matstd.Title"
                  name="matstd.Title"
                  colSpan="4"
                  style={{ textAlign: "center", whiteSpace: "nowrap" }}
                >
                  <font color="#8B0000">Edit - Material Standard</font>
                </th>
              </tr>
              <tr>
                <th>&nbsp;&nbsp; Material<span className="Req-i">*</span></th>
                <td>
                  <input
                    type="text"
                    id="Material"
                    name="Material"
                    value={MatStand.Material}
                    onChange={(e) => setMatStand({ ...MatStand, Material: e.target.value })}
                    disabled

                  />
                </td>
                <th>&nbsp;&nbsp; Dry Temperature<span className="Req-i">*</span></th>
                <td>
                  <input
                    style={{ marginLeft: "30px", width: "100px", float: "left" }}
                    type="number"
                    id="DryTemp"
                    name="DryTemp"
                    value={MatStand.DryTemp}
                    onChange={(e) => setMatStand({ ...MatStand, DryTemp: e.target.value })}
                    required
                  />
                  <span style={{ marginLeft: "10px", float: "left", fontSize: "25px" }}>±</span>
                  <input
                    style={{ marginLeft: "10px", width: "100px", float: "left" }}
                    type="number"
                    id="DryTemp"
                    name="DryTemp"
                    value={MatStand.DryTempTol}
                    onChange={(e) => setMatStand({ ...MatStand, DryTempTol: e.target.value })}
                    required
                  />
                </td>
              </tr>
              <tr>
                {/* <th>&nbsp;&nbsp; Dry Temperature Tolerance<span className="Req-i">*</span></th><td>
                                <input
                                    type="number"
                                    id="DryTemp"
                                    name="DryTemp"
                                    value={MatStand.DryTempTol}
                                    onChange={(e) => setMatStand({ ...MatStand, DryTempTol: e.target.value })}
                                    required
                                />
                            </td> */}
                <th>&nbsp;&nbsp; Dry Time<span className="Req-i">*</span></th>
                <td style={{ alignItems: "center" }}>
                  <span style={{ marginLeft: "10px", float: "left" }}>MIN </span>
                  <input
                    style={{ marginLeft: "30px", width: "100px", float: "left" }}
                    type="number"
                    id="MinHrs"
                    name="MinHrs"
                    value={MatStand.DMinHrs}
                    onChange={(e) => setMatStand({ ...MatStand, DMinHrs: e.target.value })}
                    required
                  />
                  <span style={{ marginLeft: "10px", float: "left" }}>MAX </span>
                  <input
                    style={{ marginLeft: "230px", width: "100px" }}
                    type="number"
                    id="MaxHrs"
                    name="MaxHrs"
                    value={MatStand.DMaxHrs}
                    onChange={(e) => setMatStand({ ...MatStand, DMaxHrs: e.target.value })}
                    required
                  />
                </td>

                <th>&nbsp;&nbsp;Active Status</th>
                <td>
                {MatStand.ActSts === "" &&(
                  <span className="Req">Required *</span>
                )}
                  <select
                    id="ms.ActiveStatus"
                    name="ms.ActiveStatus"
                    value={MatStand.ActSts}
                    onChange={(e) => setMatStand({
                      ...MatStand,
                      ActSts: e.target.value
                    })}
                    style={{ width: "36%", textAlign: "center" }}
                  >
                    <option id="ms.ActiveStatus.select"
                      name="ms.ActiveStatus.select"
                      value="">-- Select --</option>
                    <option id="ms.ActiveStatus.active"
                      name="ms.ActiveStatus.active"
                      value="Active">Active</option>
                    <option id="ms.ActiveStatus.inactive"
                      name="ms.ActiveStatus.inactive"
                      value="InActive">InActive</option>
                  </select>
                </td>
              </tr>

              <tr>
                <td colSpan="4" style={{ border: "none" }}>
                  <button id="matstd.submit" name="matstd.submit" type="submit" onClick={(e) => handleUpdate(e)}>
                    Update
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </Popup>

      <div>
        <table
          style={{ textAlign: "center", width: "100%" }}

        >
          <thead>
            <tr>
              <th
                id="matstd.Title.List"
                name="matstd.Title.List"
                colSpan="8"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">List - MaterialStandard</font>
              </th>
            </tr>
            <tr>
              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                Material
              </td>
              <td>
                <input
                  id="matstd.filter.machinename"
                  name="matstd.filter.machinename"
                  type="text"
                  placeholder="Filter MachineName"
                  style={{ fontSize: "80%", width: "100%" }}
                  value={MatStand.filterMat}
                  onChange={handleFilterMaterial}
                />
              </td>

              <td colSpan={4}></td>
            </tr>
            <tr className="table_position">
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Material&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Dry Temperature&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp; Dry Time&nbsp;&nbsp;
              </th>
              <th width="10%"></th>
              <th width="10%"></th>
              <th width="10%"></th>
            </tr>
          </thead>
          <tbody>
            {/* <HandleRead keyword="material_standard_details - List" setData={setmat_std} /> */}
            {filteredRecords.length === 0 ? (
              <td colSpan="12" style={{ textAlign: "center" }}>
                <p id="matstd.noRecordFound" name="matstd.noRecordFound" style={{ fontWeight: "bold" }}>No records found.</p>
              </td>
            ) : (
              filteredRecords.map((mat_std) => (
                <tr className="table_view" key={mat_std.Id}>
                  <td> {mat_std.Material} </td>
                  <td> {mat_std.Dry_temp} ± {mat_std.Dry_temp_tol} </td>
                  <td> {mat_std.Min_hrs} - {mat_std.Max_hrs} </td>
                  <td>
                    <button
                      id="matstd.mhead.View" name="matstd.mhead.View"
                      style={{ backgroundColor: "#cba423" }}
                      type="button"
                      onClick={() => transferValue2(mat_std)}
                    >
                      View&#x1F441;
                    </button>
                  </td>
                  <td>
                    <button id="matstd.mhead.Edit" name="matstd.mhead.Edit" type="button" onClick={() => handleEdit(mat_std)}>
                      Edit&#x270E;
                    </button>
                  </td>
                  <td>
                    <button
                      id={`master_head_details-Delete`}
                      name={`master_head_details-Delete`}
                      style={{ whiteSpace: "nowrap", backgroundColor: "#DA251A" }}
                      type="button"
                      onClick={() => {
                        const userConfirmed = window.confirm('Are you sure you want to delete?');
                        if (userConfirmed) {
                          console.log("Id", mat_std.Id)
                          UpdateRecord({
                            _keyword_: 'material_standard_details',
                            data: [
                              {
                                Id: mat_std.Id,
                                ActiveStatus: 'InActive',
                              },
                            ],
                          }).then(() => {
                            handleReadMaterialStand();
                          })

                          // window.location.reload();
                        }
                      }}
                    >
                      Delete<MdDelete />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <div className="pagination" id="matstd.pagination" name="matstd.pagination">

          {MatStand.isPaginationVisible && filteredData.length > 0 && (

            <Pagination currentPage={MatStand.currentPage} npage={npage} prePage={prePage} nextPage={nextPage} changeCpage={changeCpage} numbers={numbers} />
          )}

        </div>
      </div>
    </div>
  );
}

export default MaterialStandardView;
