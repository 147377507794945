import React, { useState,useRef } from "react";
import axios from "axios";
import Modal from 'react-modal';
import { API_URL } from "../../../Utils/constants.js";
import "../../../Styles/tsheet.css";
import Unitechlogo from "../../../icons/Unitechlogo.jpeg";
import Select from "react-select";
import DropdownFetcher from "../../../Lib/DropdownFetcher.js";
import Base64Convertor from "../../../Lib/Base64Convertor";
import useScanDetection from "use-scan-detection";
import { setMessage } from "../../../Lib/SetMessage.js";
import CreateApproval from "../../../Lib/CreateApproval";
import { useEffect } from "react";
import CanvasDraw from 'react-canvas-draw';
import Draggable from 'react-draggable';
import { FaPen } from 'react-icons/fa';
import { MdRestore } from 'react-icons/md';
import Pagination from "../../../Components/Pagination";
import Popup from "../../../Components/Popup/Popup";
function TemperatureSheetCreate() {
  const [Id, setId] = useState("");
  const [AppBy, setAppBy] = useState("");
  const [PrepBy, setPrepBy] = useState("");
  const [ButtonPopup, setButtonPopup] = useState(false);
  const [ButtonPopup1, setButtonPopup1] = useState(false);
  const [brushColor, setBrushColor] = useState('#000');
  const [image, setImage] = useState(null);
  const [image1, setImage1] = useState(null);
  const [CoreImg, setCoreImg] = useState("");
  const [cimage, setcImage] = useState(null);
  const [cimage1, setcImage1] = useState(null);
  const [CavImg, setCavImg] = useState("");
  const [NotesBefore, setNotesBefore] = useState("");
  const [NotesAfter, setNotesAfter] = useState("");
  const [EmployeeDropdown, setEmployeeDropdown] = useState([]);
    const [numbers, setNumbers] = useState([ ]);
  const [isCreating, setIsCreating] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDrawing, setIsDrawing] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
  const [isDrawing1, setIsDrawing1] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleClose1 = () => {
    setIsOpen1(false);
  };
  var [t_sheet, sett_sheet] = useState([]);

  //Unique id and name
  const id ="tsheetCreate";
  const name ="tsheetCreate";

  useEffect(() => {
    document.title="Mould Tempeature"
  }, [])
  
  
  const CodeGen = async () => {
    try {
      const val = {
          tablename: "mould_temperature",
          PrimaryCode: "id",
          PrimaryVal: Id,
          FieldCode: "cav_img",
          FieldVal: CavImg,
      };
	   
      const response = await axios.post(
        `http://${API_URL}/AppContextService/CodeGen`,val
      );
      return response.data.success === "true";
    } catch (error) {
      throw error;
    }
  };



  const viewPagination = () => {

  };
  const handleCreate = async (e) => {

    e.preventDefault();
    console.log(CoreImg,CavImg)
    const isSubmissionSuccessful = true; 

    if (!CavImg || !PrepBy || !NotesBefore) {
      setIsCreating(true);
       alert("Please fill the mandatory fields");
      return;
    }
	
    try {
      const isDuplicate = await CodeGen();

      if (isDuplicate) {
       
       alert("Duplicate entry found in the database. Please enter unique values.");
         
        setId("");
         window.location.reload();
        return;
      }

      const data = {
        data: [
          {
            Core_img :CoreImg,
            Cav_img :CavImg,
            App_by:AppBy.value,
            Prep_by:PrepBy.value,
            Notes_after:NotesAfter,
            Note_before:NotesBefore,
          },
        ],
        _keyword_: "MouldTemperature",
        secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
      };

      const response = await axios.post(`http://${API_URL}/GenericTransactionService/processTransaction`,data);

      if(response.data.success === "false"){
          alert("Problem in Saving Machine details");
          window.location.reload();
      }
      else{
        sett_sheet([...t_sheet, response.data]);
        console.log(response.data);
        setMessage({
          NoteFrom: PrepBy.value,
          NoteTo: AppBy.value,
          NoteMessage: `${PrepBy.value}-${PrepBy.label} has submitted Mould Temperature Form, Please do verify`,
          NoteRecordId: response.data.ROWID,
          TableName: "TemperatureSheet",
          NoteCreatedBy : localStorage.getItem("empId"),

        });
        CreateApproval({
          RequestedBy:PrepBy.value,
          Approver: AppBy.value,
          Level: 1,
          Status: "Waiting for Approval",
          TableName: "TemperatureSheet",
          TableID: response.data.ROWID,
          Description:"The Mould Temperature Form Has been Submitted By "+PrepBy.value+"-"+PrepBy.label,
        CreatedBy:localStorage.getItem("empId"),
        });
      }
      if (isSubmissionSuccessful) {
       alert('Submission successful!');
      }
          } catch (error) {
      console.log(error);
       alert('Submission failed.');
     
    }
     window.location.reload();
  };
  const empMoldSetterOptions = EmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const handlePreparedBy = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setPrepBy({ value: selected.Emp_code, label: selected.Emp_name });
    }
  };
  const handleApprovedBy = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setAppBy({ value: selected.Emp_code, label: selected.Emp_name });
    }
  };
  // const handlePhotoChangeAfter = async (e) => {
    
  //   const selectedFile = e.target.files[0];
  //   let img = await Base64Convertor(selectedFile);
  //    setCavImg(img);
  // };
  // const handlePhotoChangeBefore = async (e) => {
  //   const selectedFile = e.target.files[0];
  //   let img = await Base64Convertor(selectedFile);
  //   setCoreImg(img);
    
  // };

   // barcode scannning to get input
   useScanDetection({
    onComplete: (code) => {
      code = code.replaceAll("Shift", "");      
      setField(code);
    },
    minLength: 3,
    
  });

  // set value to the appropiate field
  function setField(code) {
    let isnumber = Number(code).toString() !== "NaN";
    if (code.includes("EMP")) {
      handlePreparedBy({value:code});
    } else 
      if (code.includes("RMI")) {
        alert("Invalid Input");
        return false;

      } else if(code.includes("CUS")){
        alert("Invalid Input");
        return false;
        
      }else if(code.includes("FG") ){
        alert("Invalid Input");
        return false;
        
      }else if(code.includes("IMM")){
        alert("Invalid Input");
        return false;

      }else if(code.includes("SUP")){
        alert("Invalid Input");
        return false;

        
      }else {
        alert("Invalid Input");
        return false;
      }
      }

  const [isCanvasReady, setIsCanvasReady] = useState(false);
  const canvasDrawRef1 = useRef(null);
  const canvasDrawRef2 = useRef(null);
  const [inputText, setInputText] = useState('');


  const handleColorChange = (e) => {
    setBrushColor(e.target.value);
  };

  const draggedNumbers = [];
  const textInputs = {};
  let prevNum = [];
  const handleDrag = (e, num) => {
    e.preventDefault();
    const canvas = canvasDrawRef1.current.canvas.drawing;
    const context = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;
    console.log('Drag event:', num, x, y);
    console.log('Previous num:', prevNum, 'Current num:', num);
    if (x >= 0 && x <= canvas.width && y >= 0 && y <= canvas.height) {
        if (!prevNum.includes(num)) {
            const radius = 10; 
            context.beginPath();
            context.arc(x, y, radius, 0, 2 * Math.PI);
            context.strokeStyle = 'black'; 
            context.stroke();
            context.font = '14px Arial';
            context.fillStyle = 'black';
            context.textAlign = 'center';
            context.textBaseline = 'middle';
            context.fillText(num.toString(), x, y);
            draggedNumbers.push(num);
            prevNum.push(num);
            if (!textInputs[num]) {
                let input = document.createElement('input');
                input.id = `textInput${num}`;
                input.type = 'text';
                input.style.position = 'absolute';
                input.style.width = '120px';
                input.style.height = '20px';
                input.style.left = `${x + 90}px`;
                input.style.top = `${y + 40}px`;

                input.placeholder = 'Enter text';
                document.body.appendChild(input);
                textInputs[num] = input;
            }

            let button = document.createElement('button');
            button.innerText = '+';
            button.style.position = 'absolute';

            button.style.font = '13px Arial';

            button.style.left = `${x + 200}px`;
            button.style.top = `${y + 40}px`;
            button.onclick = () => {
                context.fillText(textInputs[num].value, x+55, y);
                document.body.removeChild(textInputs[num]);
                document.body.removeChild(button);
                console.log(textInputs[num].value);
                delete textInputs[num];
                const index = draggedNumbers.indexOf(num);
                if (index !== -1) {
                    draggedNumbers.splice(index, 1);
                }
            };

            document.body.appendChild(button);
        }
    }
};


const handleDrag1 = (e, num) => {
  e.preventDefault();
  const canvas = canvasDrawRef2.current.canvas.drawing;
  const context = canvas.getContext('2d');
  const rect = canvas.getBoundingClientRect();
  let x = e.clientX - rect.left;
  let y = e.clientY - rect.top;
  console.log('Drag event:', num, x, y);
  console.log('Previous num:', prevNum, 'Current num:', num);
  if (x >= 0 && x <= canvas.width && y >= 0 && y <= canvas.height) {
     
    if (!prevNum.includes(num)) {
              draggedNumbers.push(num);
              const radius = 10; 
              context.beginPath();
              context.arc(x, y, radius, 0, 2 * Math.PI);
              context.strokeStyle = 'black'; 
              context.stroke();
              context.font = '14px Arial';
              context.fillStyle = 'black';
              context.textAlign = 'center';
              context.textBaseline = 'middle';
              context.fillText(num.toString(), x, y);
              prevNum.push(num);
              if (!textInputs[num]) {
                  let input = document.createElement('input');
                  input.id = `textInput${num}`;
                  input.type = 'text';
                  input.style.position = 'absolute';
                  input.style.width = '120px';
                  input.style.height = '20px';
                  input.style.left = `${x + 90}px`;
                  input.style.top = `${y + 40}px`;

                  input.placeholder = 'Enter text';
                  document.body.appendChild(input);
                  textInputs[num] = input;
              }

              let button = document.createElement('button');
              button.innerText = '+';
              button.style.position = 'absolute';

              button.style.font = '13px Arial';

              button.style.left = `${x + 200}px`;
              button.style.top = `${y + 40}px`;
              button.onclick = () => {
                  context.fillText(textInputs[num].value, x + 25, y);
                  document.body.removeChild(textInputs[num]);
                  document.body.removeChild(button);
                  console.log(textInputs[num].value);
                  delete textInputs[num];
                  const index = draggedNumbers.indexOf(num);
                  if (index !== -1) {
                      draggedNumbers.splice(index, 1);
                  }
              };

              document.body.appendChild(button);
          }
        }
};

const handleDrop = (e, number) => {
    e.preventDefault();

    let input = textInputs[number];
    if (input) {
        
        setInputText(input.value);
       
        const canvas = canvasDrawRef1.current.canvas.drawing;
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);

        numbers.forEach((num) => {
            context.font = '20px Arial';
            context.fillStyle = 'black';
         
          
            context.fillText(`${num}: ${input.value}`, num * 30, 30);
            context.fillText((input).toString(), x, y)
        });
    }
};


    const set1 = (e) => {
   e.preventDefault();  
   setIsVisible(false)
     };
     const set2 = (e) => {
      e.preventDefault(); 
      setIsVisible(false)
      };
     const setedit = (e) => {
      e.preventDefault();
      setIsVisible(true)
      setIsDrawing(false)
        };
        const setedit1 = (e) => {
          e.preventDefault();
          setIsVisible(true)
          setIsDrawing1(false)
            }; 
        const combineImages = (image, editedImageData) => {
          return new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
        
   
            const img = new Image();
            img.src = image;
            img.onload = () => {
              canvas.width = img.width;
              canvas.height = img.height;
              ctx.drawImage(img, 0, 0);
              
              const editedImg = new Image();
              editedImg.src = editedImageData;
              editedImg.onload = () => {
                ctx.drawImage(editedImg, 0, 0, canvas.width, canvas.height); 
                
    
                const combinedImageData = canvas.toDataURL('image/png', 1.0);
                resolve(combinedImageData);
              };
            };
          });
        };
        
        const combineImages1 = (checkimage, editedImageData) => {
          return new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
     
            const img = new Image();
            img.src = cimage;
            img.onload = () => {
              canvas.width = img.width;
              canvas.height = img.height;
              ctx.drawImage(img, 0, 0);
              
              const editedImg = new Image();
              editedImg.src = editedImageData;
              editedImg.onload = () => {
                ctx.drawImage(editedImg, 0, 0, canvas.width, canvas.height); 
                
    
                const combinedImageData = canvas.toDataURL('image/png', 1.0);
                resolve(combinedImageData);
              };
            };
          });
        };
    

    
    useEffect(() => {
      if (image && canvasDrawRef1.current) {
        const editedImageData = canvasDrawRef1.current.canvas.drawing.toDataURL('image/png',1.0);
        combineImages(image, editedImageData).then((combinedImageData) => {
          setImage1(combinedImageData);
          setCoreImg(combinedImageData);
        });
        }
        }, [set1]);
        useEffect(() => {
          if (cimage && canvasDrawRef2.current) {
            const editedImageData = canvasDrawRef2.current.canvas.drawing.toDataURL('image/png',1.0);
            combineImages1(cimage, editedImageData).then((combinedImageData) => {
              setcImage1(combinedImageData);
              setCavImg(combinedImageData);
            });
            }
            }, [set2]);
     
        const handleReset = (e) => {
          e.preventDefault();
          canvasDrawRef1.current.clear();
          const canvas = canvasDrawRef1.current.canvas.drawing;
          const context = canvas.getContext('2d');
          
          context.clearRect(0, 0, canvas.width, canvas.height);
          const img = new Image();
          img.src = image;
          img.onload = () => {
            context.drawImage(img, 0, 0, canvas.width, canvas.height);
          };
        };
        
      const handleReset1 = (e) => {
      e.preventDefault(); 
      canvasDrawRef2.current.clear();
      };
   
      const handleImageChange = (e) => {
      e.preventDefault(); 
      setIsDrawing(false)
      setButtonPopup(true)
       setIsVisible(false)
      const file = e.target.files[0];
      if (!file) return;
      const reader = new FileReader();
      reader.onload = () => {
      const imageData = reader.result;
      
      setImage(imageData);
      setIsOpen(true)
        };
        reader.readAsDataURL(file);
        };
        const handleImageChange1 = (e) => {
          e.preventDefault(); 
          setIsDrawing1(false)
          setButtonPopup1(true)
           setIsVisible(false)
          const file = e.target.files[0];
          if (!file) return;
          const reader = new FileReader();
          reader.onload = () => {
          const imageData = reader.result;
          
          setcImage(imageData);
          setIsOpen(true)
            };
            reader.readAsDataURL(file);
            };
     
        const [canvasDimensions, setCanvasDimensions] = useState({ width: 400, height: 600 });
        useEffect(() => {
          if (image) {
            const img = new Image();
            img.src = image;
            img.onload = () => {
              const aspectRatio = img.width / img.height;
              const maxWidth = 800; 
              const maxHeight = 700; 
        
              let canvasWidth = maxWidth;
              let canvasHeight = maxHeight;
        
              if (aspectRatio > 1) {
                canvasWidth = Math.min(maxWidth, img.width);
                canvasHeight = canvasWidth / aspectRatio;
              } else {
                canvasHeight = Math.min(maxHeight, img.height);
                canvasWidth = canvasHeight * aspectRatio;
              }
        
              setCanvasDimensions({ width: canvasWidth, height: canvasHeight });
            };
          }
        }, [image,cimage]);
       return(
        <div>
        <form id="tsheet-formContainer-Create" name="tsheet-formContainer-Create" className="form-tsheet-Create">
        <table>
        <tbody> 
            <tr>
              <th style={{ textAlign: "center", width: "10%" }}>
              <img src={Unitechlogo} style={{ width: "40px" }} alt="Unitech Logo" />
              </th>
              <th colSpan={4} id={`${id}-Title`} name={`${name}-Title`} style={{ textAlign: "center", whiteSpace: "nowrap" }}>
              <font color="#8B0000">WORK INSTRUCTIONS</font>
              </th>
              </tr>
              <tr>
              <th colSpan={6} className="topic-head" style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                MOULD TEMPERATURE MEASURING AREA (APPLICABLE FOR ALL MOULD)
              </th>
              </tr>
              <tr>
              <td colSpan={6} className="topic-head" style={{ textAlign: "center", whiteSpace: "nowrap" }}>
              The Maintenance Inspector Should Following Instructions For Mould Core & Cavity Temperature :
              </td>
              </tr>
                 <tr>    
                 <td  colSpan={2} className="topic-head"><div>
                 {CoreImg === '' && isCreating && <span className="Req">Required *</span>}
                 <input type="file" accept="image/*" onChange={handleImageChange} />  
                 <br />
                 <Popup
    trigger={ButtonPopup}
    setTrigger={setButtonPopup}
    viewPagination={viewPagination}
>
  < br />
    <div style={{
      marginLeft:"5%",
      padding:"5px",
        position: 'absolute',
        width: '80%',
        height: '130%',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        // display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }}>
      
        <div
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            style={{ position: 'relative',marginLeft:"1%" }}
        >
        {!isVisible && (
            <button
            onClick={(e) => {
                if (canvasDrawRef1.current) {
                    handleReset(e);
                    setedit(e);
                }
            }}
            style={{ border: 'none', padding: '10px', backgroundColor: 'rgba(0, 0, 0, 0.1)', marginLeft:"10px"}}
        >
            edit
        </button>)}

        {isVisible && (
            <>
                <span
                    style={{ width: '20px', height: '20px', marginLeft:"10px", border: 'none', padding: 0 }}
                    onClick={() => {
                        setIsDrawing(prevState => !prevState);
                    }}
                >
                    <FaPen />
                </span>

                <input
                    type="color"
                    value={brushColor}
                    onChange={handleColorChange}
                    style={{ width: '20px', height: '20px', border: 'none', padding: 0 , marginLeft:"30px",}}
                />color

               
                <MdRestore onClick={handleReset} style={{ width: '20px', height: '20px', border: 'none', padding: 0,marginLeft:"20px" }} />Reset
                <button onClick={set1} style={{ marginLeft: '20px', border: 'none', padding: 0 }}>OK</button>
            </>
        )}
            <CanvasDraw
                ref={canvasDrawRef1}
                imgSrc={image}
                hideGrid={true}
                canvasHeight={canvasDimensions.height}
                canvasWidth={canvasDimensions.width}
                // canvasHeight={600}
                // canvasWidth={400}
                brushRadius={1}
                lazyRadius={0}
                brushColor={brushColor}
                disabled={!isDrawing}
                style={{ padding: 1 }}
            />
             {[...Array(5).keys()].map((num) => (
                    <Draggable key={num}>
                        <span
                            draggable
                            onDragStart={(e) => handleDrag(e, (num + 1))}
                            style={{
                                borderRadius: '100%',
                                display: 'inline-block',
                                width: '20px',
                                height: '20px',
                                margin: '5px',
                                border: '1px solid black',
                                textAlign: 'center',
                                lineHeight: '20px',
                                marginLeft:"10px",
                            }}
                        >
                            {num + 1}
                        </span>
                    </Draggable>
                ))}
        </div>
        <br />
      
    </div>
</Popup>

                 {CoreImg && (
                  <img
                    src={CoreImg}
                    alt="Selected"
                    style={{
                      display: "block",
                      width: "400px",
                      height: "350px",
                      margin: "0 auto",
                    }}
                  />
                )}
                     <br />
                   
                     <br></br>
                     <span>Notes:<span style={{ color: "red" }}>*</span>

                      <br></br>
                      <textarea
                rows={3} 
                cols={2}
                value={NotesBefore}
                onChange={(e)=>setNotesBefore(e.target.value)}
                
                />
                {NotesBefore === '' && isCreating && <span className="Req">Required *</span>}

                    </span>

                   {/* <span>CORE Side Temperature Should be check the above mention location</span> */}
                    </div>
                    <br></br>
                    <span>Prepared By<span style={{ color: "red" }}>*</span></span>
                    <br></br>
                    
                    <DropdownFetcher keyword="emp_details_dropdown_all" setValue={setEmployeeDropdown} />

                    <Select
                id={`${id}-PreparedBy`}
                name={`${name}-PreparedBy`}
                value={PrepBy}
                onChange={handlePreparedBy}
                  options={empMoldSetterOptions}
                  
                  placeholder="Select..."
                />
                {PrepBy === '' && isCreating && <span className="Req">Required *</span>}

                    </td>
                    <td  colSpan={2} className="topic-head">
             {CavImg === '' && isCreating && <span className="Req">Required *</span>}
             {/* <br /> */}
               <input type="file" accept="image/*" onChange={handleImageChange1} />
               <br />
               <Popup
    trigger={ButtonPopup1}
    setTrigger={setButtonPopup1}
    viewPagination={viewPagination}
>
  < br />
    <div style={{
      marginLeft:"5%",
        padding:"5px",
        position: 'absolute',
        width: '70%',
        height: '120%',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        // display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }}>
      
        <div
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            style={{ position: 'relative',marginLeft:"1%" }}
        >
        {!isVisible && (
            <button
            onClick={(e) => {
                if (canvasDrawRef2.current) {
                    handleReset1(e);
                    setedit1(e);
                }
            }}
            style={{ border: 'none', padding: '10px', backgroundColor: 'rgba(0, 0, 0, 0.1)', marginLeft:"10px"}}
        >
            edit
        </button>)}

        {isVisible && (
            <>
                <span
                    style={{ width: '20px', height: '20px', marginLeft:"10px", border: 'none', padding: 0 }}
                    onClick={() => {
                        setIsDrawing1(prevState => !prevState);
                    }}
                >
                    <FaPen />
                </span>

                <input
                    type="color"
                    value={brushColor}
                    onChange={handleColorChange}
                    style={{ width: '20px', height: '20px', border: 'none', padding: 0 , marginLeft:"30px",}}
                />color

               
                <MdRestore onClick={handleReset1} style={{ width: '20px', height: '20px', border: 'none', padding: 0,marginLeft:"20px" }} />Reset
                <button onClick={set2} style={{ marginLeft: '20px', border: 'none', padding: 0 }}>OK</button>
            </>
        )}
            <CanvasDraw
                ref={canvasDrawRef2}
                imgSrc={cimage}
                hideGrid={true}
                canvasHeight={canvasDimensions.height}
                canvasWidth={canvasDimensions.width}
                brushRadius={1}
                lazyRadius={0}
                brushColor={brushColor}
                disabled={!isDrawing1}
                style={{ padding: 1 }}
            />
             {[...Array(5).keys()].map((num) => (
                    <Draggable key={num}>
                        <span
                            draggable
                            onDragStart={(e) => handleDrag1(e, (num + 1))}
                            style={{
                                borderRadius: '100%',
                                display: 'inline-block',
                                width: '20px',
                                height: '20px',
                                margin: '5px',
                                border: '1px solid black',
                                textAlign: 'center',
                                lineHeight: '20px',
                                marginLeft:"10px",
                            }}
                        >
                            {num + 1}
                        </span>
                    </Draggable>
                ))}
        </div>
        <br />
      
    </div>
</Popup>

               
{CavImg && (
                  <img
                    src={CavImg}
                    alt="Selected"
                    style={{
                      display: "block",
                      width: "400px",
                      height: "350px",
                      margin: "0 auto",
                    }}
                  />
                )}
                   <br></br>
             
                   <br />
                   <span>
                   Notes: <span style={{ color: "red" }}>*</span>
                    <br></br>                    
                    <textarea
                rows={3} 
                cols={2}
                value={NotesAfter}
                onChange={(e)=>setNotesAfter(e.target.value)}
                
                />{NotesAfter === '' && isCreating && <span className="Req">Required *</span>}
                   </span>
                   {/* <span>CAVITY Side Temperature Should be check the above mention location</span> */}
                  
                    <br />
                    <span>Approved By <span style={{ color: "red" }}>*</span></span>
                    <br></br>
                          <Select
                id={`${id}-ApprovedBy`}
                name={`${name}-ApprovedBy`}
                value={AppBy}
                onChange={handleApprovedBy}
                  options={empMoldSetterOptions}
                  
                  placeholder="Select..."
                />{AppBy === '' && isCreating && <span className="Req">Required *</span>}
                          </td>
             </tr>
              
          </tbody>
          <td colSpan={5} style={{border:"none"}}>
                <button  type="submit" id="sub" onClick={(e) => handleCreate(e)}
                disabled={PrepBy==="" || AppBy==="" || NotesBefore === "" }
                hidden={PrepBy==="" || AppBy==="" || NotesBefore === "" }
                >
                  Submit
                </button>
              </td>
       
        </table>
      </form>
    </div>
  );
  
}

export default TemperatureSheetCreate;
  