import React,{useState,useEffect} from "react";
import ListView from "../../../Components/ListView/ListView";
import QaLayout from "../QaLayout";

const FirstOffList = () => {
  return (
    <QaLayout quary={"quality_first_off_inspection"}>
    <div>
      <ListView
        Title={"First Off Inspection"}
        standard={"qp.ActiveStatus = 1"}
        // quary={"SELECT ins.id,ins.machine_no,prt.mc_part_name Part_name,ins.raw_material_name,emp.emp_name Prodcution_operator_name,ins.ActiveStatus FROM upd_data_model.quality_first_off_inspection ins left join upd_data_model.machine_part_details prt on ins.part_name=prt.mc_part_code left join upd_data_model.emp_details emp on ins.prodcution_operator_name=emp.emp_code where ins.ActiveStatus='1'"}
        //group by qp.Id,qp.CreatedDate,qp.part_name,qp.part_number,qp.Raw_material_name,rm_details.rm_description 
        quary={"Select distinct qp.Id,qp.CreatedDate,qp.part_number,qp.Part_name,qp.Machine_no,qp.Raw_material_name,rm_details.rm_description AS rm_name from quality_first_off_inspection qp LEFT JOIN rm_details ON qp.raw_material_name =rm_details.rm_code order by qp.Id desc"}
        table={"quality_first_off_inspection qp"}
        tableDelete={"quality_first_off_inspection"}
        navigation={"firstoff"}
        filter={["qp.Id","qp.Machine_no","qp.part_number","qp.Part_name"]}
        dateFilter={"qp.CreatedDate"}
        header={[
          { value: "qp.Id", label: "ID" },
          { value: "qp.part_number", label: "Part Number" },
          { value: "qp.Part_name", label: "Part Name" },
          { value: "qp.Machine_no", label: "Machine No" },
          { value: "qp.Raw_material_name", label: "Raw Material" },
          { value: "rm_name", label: "Raw Name" },
          // { value: "Production_operator_name", label: "Prodcution Operator" },
        ]}
      />
  

      {/* <ListView
        Title={"First Off Inspection"}
        table={"emp_details"}
        filter={["Emp_code","Emp_name"]}
        navigation={"EmployeeMaster"}
        header={[
          { value: "Id", label: "ID" },
          { value: "emp_name", label: "Employee Name" },
          { value: "Emp_designation", label: "Emp designation" },
          { value: "Emp_department", label: "Emp department" },
          { value: "Emp_code", label: "Employee code" },
        ]}
      /> */}
    </div>
    </QaLayout>
  );
};

export default FirstOffList;
