import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../../Utils/constants.js";
import { setMessage } from "../../../Lib/SetMessage.js";
import "../../../Styles/MaterialLoading.css";
import "../../../Styles/List.css";
import Unitechlogo from "../../../icons/Unitechlogo.jpeg";
import DatePicker from "../../../Lib/DatePicker.js";
import useScanDetection from "use-scan-detection";
import Select from "react-select";
// import DropdownFetcher from "../../../Lib/DropdownFetcher.js";
import ReadRecord from "../../../Lib/API/ReadRecord.js";
import {
  CURRENT_Date,
  CURRENT_TIME,
  SHIFTMOLDINGPRODUCTION,
} from "../../../Utils/GeneralConstants";
function MaterialLoadingCreate() {
  const handleDateChange = (newDate) => {
    setTrackDate(newDate);
  };
  const scrollTop = useRef(null);
  const [isCreating, setIsCreating] = useState(false);
  const [redAlert,setRedAlert] = useState(false);
  const [matlot, setmatlot] = useState([]);
  const [data, setdata] = useState([]);
  const [Material, setMaterial] = useState([]);
  const [Shift, setShift] = useState(SHIFTMOLDINGPRODUCTION);
  const [MachineNo, setMachineNo] = useState("");
  const [TrackDate, setTrackDate] = useState(CURRENT_Date);
  const [MatNme, setMatNme] = useState("");
  const [Grade, setGrade] = useState("");
  const [MatLotNo, setMatLotNo] = useState("");
  const [MatLdKg, setMatLdKg] = useState("");
  const [MatLdTme, setMatLdTme] = useState("");
  const [PreHtHrs, setPreHtHrs] = useState("");
  const [PreHtTmp, setPreHtTmp] = useState("");
  const [Inchrge, setInchrge] = useState("");
  const [QcSign, setQcSign] = useState("");
  const [Moisture, setMoisture] = useState("");
  const [DryTemp, setDryTemp] = useState("");
  const [DryTempTolerance, setDryTempTolerance] = useState("");
  const [MinHours, setMinHours] = useState("");
  const [MaxHours, setMaxHours] = useState("");
  const [mat_load, setmat_load] = useState([]);
  const [employee, setemployee] = useState([]);
  const [machine, setMachine] = useState([]);


  const id = "MaterialLoading";
  const name = "MaterialLoading";

  // const CodeGen = async () => {
  //   try {
  //     const val = {
  //       tablename: "material_loading",
  //       PrimaryCode: "Mat_name",
  //       PrimaryVal: MatNme,
  //       FieldCode: "Mat_grde",
  //       FieldVal: Grade,
  //     };

  //     const response = await axios.post(
  //       `http://${API_URL}/AppContextService/CodeGen`,
  //       val
  //     );
  //     return response.data.success === "true";
  //   } catch (error) {
  //     throw error;
  //   }
  // };
  function GetSelectValue(value, keyword, setName) {
    if (value.length > 0) {
    // setMachineNo("");
      ReadRecord({
        _keyword_: keyword,
        key: "%" + value + "%",
        value: "%" + value + "%"
      }).then((res) => {
        if(res.rows>0){
          console.log("read", res.data);
          setName(res.data);
          setRedAlert(false);
        }else{
          console.log("123424");
          setRedAlert(true);
        }
        
        // setKey(keyvalue[options.reference]);
      });
    }
  }
  function GetSelectValue1(value, keyword, setName) {
    ReadRecord({
      _keyword_: keyword,
      key: "%" + value + "%",
      value: "%" + value + "%"
    }).then((res) => {
      console.log("res.data", res.data);
      setName(res.data);
      if (res.rows > 0) {
        //  handleRawMaterial({value:res.data[0].Mh_raw_mat,label:res.data[0].Mh_raw_mat_name})
        setMatNme({
          value: res.data[0].Mh_raw_mat,
          label: res.data[0].Mh_raw_mat_name,
        })
        const RmGrade = res.data[0].Rm_grade ? res.data[0].Rm_grade : "";
        setGrade(RmGrade);
        setMatLdTme(CURRENT_TIME);
      handleMaterialStandard(res.data[0].Mh_raw_mat_name);
        ReadRecord({
          _keyword_: "rm_lot_by_rm_code",
          key: res.data[0].Mh_raw_mat
        }).then((res) => {
          console.log("RmLotData", res);
          const RmLotData = res.data.map(item => item.Rm_lotno)
          setmatlot(RmLotData);
        })
     
      } else {
        alert("Not Available")
      }


    })
    // .then(() => {
    //   ReadRecord({
    //     _keyword_: "rm_lot_by_rm_code",
    //     key: RmCodeData
    //   }).then((res) => {
    //     console.log("RmLotData", res);
    //     const RmLotData = res.data.map(item => item.Rm_lotno)
    //     setmatlot(RmLotData);
    //   })
    // })
  }
  // function GetSelectValue1(value, keyword, setName) {
  //   ReadRecord({
  //     _keyword_: keyword,
  //     key: "%" + value + "%",
  //     value: "%" + value + "%"
  //   }).then((res) => {
  //     setName(res.data);
  //     // return res
  //     // const uniqueRmCodes = [...new Set(res.data.map(item => item.Mh_raw_mat))];
  //     // console.log("res.data.Mh_raw_mat", res.data[0].Mh_raw_mat);
  //     // console.log("res", res);
  //     if (res.rows > 0) {
  //       // console.log("res.data[0].Mh_raw_mat",res.data[0].Mh_raw_mat);
  //       const RmCodeData = res.data[0].Mh_raw_mat;
  //       setMatNme(RmCodeData)
  //       // let time = new Date().toTimeString().substring(0, 5)
  //       setMatLdTme(CURRENT_TIME);
  //       const RmGrade = res.data[0].Rm_grade ? res.data[0].Rm_grade : "";
  //       setGrade(RmGrade);
  //       handleMaterialStandard(res.data[0].Mh_raw_mat_name);

  //     } else {
  //       alert("Not Available")
  //     }


  //   }).then(() => {
  //     ReadRecord({
  //       _keyword_: "rm_lot_by_rm_code",
  //       key: MatNme
  //     }).then((res) => {
  //       console.log("RmLotData", res);
  //       const RmLotData = res.data.map(item => item.Rm_lotno)
  //       setmatlot(RmLotData);
  //     })
  //   })
  // }
  function GetSelectValue2(value, keyword, setName) {
    ReadRecord({
      _keyword_: keyword,
      key: "%" + value + "%",
      value: "%" + value + "%"
    }).then((res) => {
      if (res.rows > 0) {
        setName({ value: res.data[0].Emp_code, label: res.data[0].Emp_name })
      } else {
        alert("Not Available")
      }
    });
  }

  const handleCreate = async (e) => {
    console.log("MatNme.value", MatNme);
    // const currentDate = new Date().toISOString().split("T")[0];
    e.preventDefault();

    const isSubmissionSuccessful = true;

    if (!MatNme || !Grade || !MachineNo || !MatLotNo || !QcSign || !Inchrge || !MatLdKg || !PreHtTmp || !PreHtHrs) {
      setIsCreating(true);
      alert("Please fill the mandatory fields");

      if (scrollTop.current) {
        scrollTop.current.scrollIntoView({ behavior: "smooth" });
      }
      return;
    }

    try {
      //   const isDuplicate = await CodeGen();

      //   if (isDuplicate) {
      //   setIsCreating(false);

      //     alert(`${MatNme} Already Exist.`);

      //     // swal({
      //     //   title:"Oops!",
      //     //   text:`${MatNme} Already Exist.`,
      //     //   icon:'error',
      //     //   button:"Okay",
      //     //   closeOnClickOutside: false,
      //     // }) .then(() => {

      //     //   window.location.reload();
      //     // }
      //     // )
      //     setMachineNo("");
      //     setShift("");
      //     setTrackDate("");
      //     setMatNme("");
      //     setGrade("");
      //     setMatLotNo("");
      //     setmatlot("");
      //     setMatLdKg("");
      //     setMatLdTme("");
      //     setPreHtHrs("");
      //     setPreHtTmp("");
      //     setInchrge("");
      //     setQcSign("");
      //     setMoisture("");
      //     // window.location.reload();
      //     return;
      //   }

      const data = {
        data: [
          {
            Machine_no: MachineNo.value,
            TrackDate: TrackDate,
            Mat_name: MatNme.value,
            Mat_grde: Grade,
            Mat_lot_no: MatLotNo,
            Mat_ld_kgs: MatLdKg,
            Mat_ld_time: MatLdTme,
            Shift: Shift,
            Pre_ht_hrs: PreHtHrs,
            Pre_ht_tmp: PreHtTmp,
            In_chrge: Inchrge.value,
            Qc_sign: QcSign.value,
            Moisture: Moisture,
            DryTemp: DryTemp,
            DryTempTolerance: DryTempTolerance,
            MinHours: MinHours,
            MaxHours: MaxHours
          },
        ],
        _keyword_: "material_loading",
        secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
      };

      const response = await axios.post(
        `http://${API_URL}/GenericTransactionService/processTransaction`,
        data
      )
      console.log("res", response);
      if (response.data.ROWID > 1) {

        console.log("response.data.ROWID",response.data.ROWID);
        setIsCreating(false);

        alert(`Submitted Successfully!`);
        setMessage({
          NoteFrom: Inchrge.value,
          NoteTo: QcSign.value,
          NoteMessage: `${Inchrge.value}-${Inchrge.label} has submitted a form, Please do verify`,
          NoteRecordId: response.data.ROWID,
          TableName: "MaterialLoading",
          NoteCreatedBy: localStorage.getItem("empId"),

        });
        setMachineNo("");  
        setMatNme("");
        setGrade("");
        setMatLotNo("");
        setMatLdKg("");
        setMatLdTme("");
        setPreHtHrs("");
        setmatlot("");
        setPreHtTmp("");
        setInchrge("");
        setQcSign("");
        setMoisture("");
        setMinHours("");
        setMaxHours("");
        setDryTemp("");
        setDryTempTolerance("");
        if (scrollTop.current) {
          scrollTop.current.scrollIntoView({ behavior: "smooth" });
        }

        // window.location.reload();

      }

      // if (response.data.success === "true" && response.data.feedback.length>0) {
      //   alert("Created.......");

      // }  

      // if (response.data.success === "true" && response.data.feedback.length === 0) {
      //   alert("Duplicate Entry....");
      // }


      // if (isSubmissionSuccessful) {
      //   setIsCreating(false);

      //   alert(`Submitted Successfully!`);
      //   await setMessage({
      //     NoteFrom: Inchrge.value,
      //     NoteTo: QcSign.value,
      //     NoteMessage: `${Inchrge.value}-${Inchrge.label} has submitted a form, Please do verify`,
      //     NoteRecordId: response.data.ROWID,
      //     TableName: "MaterialLoading",
      //     NoteCreatedBy: localStorage.getItem("empId"),

      //   });
      //   setMachineNo("");
      //   setShift("");
      //   setTrackDate("");
      //   setMatNme("");
      //   setGrade("");
      //   setMatLotNo("");
      //   setMatLdKg("");
      //   setMatLdTme("");
      //   setPreHtHrs("");
      //   setmatlot("");
      //   setPreHtTmp("");
      //   setInchrge("");
      //   setQcSign("");
      //   setMoisture("");
      //   setMinHours("");
      //   setMaxHours("");
      //   setDryTemp("");
      //   setDryTempTolerance("");
      //   if (scrollTop.current) {
      //     scrollTop.current.scrollIntoView({ behavior: "smooth" });
      //   }
      //   // window.scrollTo({ top: 0, behavior: "smooth" });
      // }

    }
    catch (error) {
      console.log("error",error);
      alert("Submission failed.");
      window.location.reload();

    }
    // window.location.reload();
  };

  const handleMatLotChange = (event) => {
    const selectedMatLot = event.target.value;
    setMatLotNo(selectedMatLot);
  };
  // barcode scannning to get input
  
    useScanDetection({
      onComplete: (code) => {
        code = code.replaceAll("Shift", "");
        setField(code);
      },
      minLength: 3,

      // preventDefault: true,
    });


  // set value to the appropiate field
  // function setField(code) {
  //   console.log("code", code);
  //   let isnumber = Number(code).toString() !== "NaN";
  //   let current_element = document.activeElement.id;

  //   if (isnumber) {
  //     console.log(code);
  //     // document.getElementById("incharge").focus();
  //     // document.getElementById("incharge");
  //     if (current_element === "" || current_element === "react-select-4-input") {

  //       setEmployeeName(code);
  //     } else {
  //       alert("Invalid Input");
  //       return false;
  //     }
  //   } else {
  //     if (code.includes("RMI")) {
  //       if (current_element === "" || current_element === "react-select-3-input") {

  //         // handleFieldChange(code);
  //         // setMatNme(code)
  //         GetSelectValue1(code, "rm_details_view", setMaterial)

  //       } else {
  //         alert("Invalid Input");
  //         return false;
  //       }
  //     } else if (code.includes("CUS")) {
  //       alert("Invalid Input");
  //       return false;
  //     } else if (code.includes("FG")) {
  //       alert("Invalid Input");
  //       return false;
  //     } else if (code.includes("IMM")) {
  //       // document.getElementById("smp_Cr_Machine").focus();
  //       if (current_element === "" || current_element === "react-select-2-input") {
  //         console.log("macjine", code);
  //         // GetSelectValue(code,"mac_dtl_dropdown_like",setMachine)
  //         // handleMachine({value:code})
  //         setMachineNo({ value: code, label: code })
  //       } else {
  //         alert("Invalid Input");
  //         return false;
  //       }
  //     }
  //     // else if(code.includes("PRT")){
  //     //   setMatLotNo
  //     // }
  //     else if (code.includes("SUP")) {
  //       alert("Invalid Input");
  //       return false;

  //     } else {
  //       alert("Invalid Input");
  //       return false;
  //     }
  //   }
  // }

  function setField(code) {
    console.log("code", code);
    let isnumber = Number(code).toString() !== "NaN";
    let current_element = document.activeElement.id;

    if (code.includes("EMP")) {
      // console.log(code);
      // document.getElementById("incharge").focus();
      // document.getElementById("incharge");
      if (current_element === "" || current_element === "react-select-4-input") {
        GetSelectValue2(code, "emp_details_dropdown", setInchrge)
        // setInchrge({value:code,label:code});
      } else {
        alert("Invalid Input");
        return false;
      }
    } else
      if (code.includes("RMI")) {
        if (current_element === "" || current_element === "react-select-3-input") {

          // handleFieldChange(code);
          // setMatNme(code)
          // GetSelectValue1(code, "rm_details_dropdown_grade", setMaterial)
          MachineNo && handleRawMaterial({value:code,label:code});
        } else {
          alert("Invalid Input");
          return false;
        }
      } else if (code.includes("CUS")) {
        alert("Invalid Input");
        return false;
      } else if (code.includes("FG")) {
        alert("Invalid Input");
        return false;
      } else if (code.includes("IMM")) {
        // document.getElementById("smp_Cr_Machine").focus();
        if (current_element === "" || current_element === "react-select-2-input") {
          console.log("macjine", code);
          // GetSelectValue(code,"mac_dtl_dropdown_like",setMachine)
          handleMachine({value:code})
          GetSelectValue(code, "Get_machine_from_smp", setMachine)
          setMachineNo({ value: code, label: code })
          // GetSelectValue1(code, "Get_raw_material_by_machine", setMachine)

        } else {
          alert("Invalid Input");
          return false;
        }
      }
      // else if(code.includes("PRT")){
      //   setMatLotNo
      // }
      else if (code.includes("SUP")) {
        alert("Invalid Input");
        return false;

      }
    // else {
    //   alert("Invalid Input else");
    //   return false;
    // }

  }
  // auto populate employee names
  function setEmployeeName(code) {

    const selected = employee.find(
      (option) => option.Emp_code === code
    );
    if (selected) {
      setInchrge({ value: selected.Emp_code, label: selected.Emp_name });
    }

  }
  function setQAName(code) {

    const selected = employee.find(
      (option) => option.Emp_code === code
    );
    if (selected) {
      setQcSign({ value: selected.Emp_code, label: selected.Emp_name });
    }

  }
  // function handleMachine(selectedOption) {

  //   const selected = machine.find(      
  //     (option) => option.mh_mac_name === selectedOption.value
  //   );
  //   if (selected) {
  //     setMachineNo({ value: selected.mh_mac_name, label: selected.mh_mac_name });
  //   }
  //   console.log(selected);

  // }
  const mcNoOptions = machine.map((i) => ({
    value: i.mh_mac_name,
    label: i.mh_mac_name,
  }));
  // const uniqueRmCodes = [...new Set(Material.map(i => i.Mh_raw_mat))];

  // const rmOptions = uniqueRmCodes.map(code => ({
  //   value: code,
  //   label: code,
  // }));
  const rmOptions = Material.map((i) => ({
    value: i.Mh_raw_mat,
    label: i.Mh_raw_mat_name,
  }));
  const handleMachine = (selectedOption) => {
    console.log("selectedOption",selectedOption);
    GetRawMaterial(selectedOption.value);
    const selected = machine.find(
      (option) => option.mh_mac_name === selectedOption.value
    );
    if (selected) {
      setMachineNo({ value: selected.mh_mac_name, label: selected.mh_mac_name });      
      setMatNme("");
      setGrade("");
      setMatLotNo("");
      setMatLdKg("");
      setMatLdTme("");
      setPreHtHrs("");
      setmatlot("");
      setPreHtTmp("");
      setInchrge("");
      setQcSign("");
      setMoisture("");
      setMinHours("");
      setMaxHours("");
      setDryTemp("");
      setDryTempTolerance("");
    }

  };
  async function GetRawMaterial(val) {
    try {
        const res = await ReadRecord({
            _keyword_: "Get_raw_material_by_machine",
            machine: val,
        });

        if (res.rows > 0) {
            console.log("response of raw", res.data);
            setMaterial(res.data);
        } else  {
            alert("Contact Administrator to add Raw Material for this Selected Machine!");
            // setMatNme({
            //   value: res.data[0].Mh_raw_mat,
            //   label: res.data[0].Mh_raw_mat_name,
            // })
        }
    } catch (error) {
        console.error("Error fetching raw material:", error);
    }
}

  useEffect(() => {
    document.title = "Material Loading Check Sheet"
  }, []);

  function handleMaterialStandard(MatNmeLabel) {
    console.log("MatNme", MatNmeLabel);
    ReadRecord({
      _keyword_: "Master_material_standard_based_on_RMI",
    }).then((res) => {
      console.log("Response from Material Standard", res.data.filter(item => MatNmeLabel.includes(item.Material)));
      const MaterialStandard = res.data.filter(item => MatNmeLabel.includes(item.Material));
      if (MaterialStandard.length > 0) {
        // console.log("MaterialStandard", MaterialStandard[0].Dry_temp);
        setDryTemp(MaterialStandard[0].Dry_temp === undefined ? "NA" : MaterialStandard[0].Dry_temp);
        setDryTempTolerance(MaterialStandard[0].Dry_temp_tol === undefined ? "NA" : MaterialStandard[0].Dry_temp_tol);
        setMinHours(MaterialStandard[0].Min_hrs === undefined ? "NA" : MaterialStandard[0].Min_hrs);
        setMaxHours(MaterialStandard[0].Max_hrs === undefined ? "NA" : MaterialStandard[0].Max_hrs);
      } else {
        alert("Temperature and Time Not Available!")
        setMinHours("");
        setMaxHours("");
        setDryTemp("");
        setDryTempTolerance("");
      }
    })

  }

  
  const handleRawMaterial = (selectedOption) => {
    console.log("selectedOption", selectedOption);
    console.log("Material", Material);
    const selected = Material.find(
      (option) => option.Mh_raw_mat === selectedOption.value
    );
    console.log("selected", selected);
    if (selected) {
      let grade = selected.Rm_grade;
      if (selected.Rm_grade === undefined) {
        grade = "";
      }
      setMatNme({
        value: selected.Mh_raw_mat,
        label: selected.Mh_raw_mat_name,
      })
      setGrade(grade);
      setMatLdTme(CURRENT_TIME);
      handleMaterialStandard(selected.Mh_raw_mat_name);
      ReadRecord({
        _keyword_: "rm_lot_by_rm_code",
        key: selected.Mh_raw_mat
      }).then((res) => {
        console.log("RmLotData", res);
        const RmLotData = res.data.map(item => item.Rm_lotno)
        setmatlot(RmLotData);
      })
    } else {
      alert('Selected Raw Material not connected with Machine!');
    }
  };
  return (
    <div>
      <form
        id="MaterialLoading-formContainer-Create"
        name="MaterialLoading-formContainer-Create"
        className="form-MaterialLoading-Create"
      >
        <div ref={scrollTop}>
          <table className="table" style={{ textAlign: "center", width: "100%" }}>
            <tbody>
              <tr>
                <th style={{ textAlign: "center" }} colSpan={5}>
                  {/* <img src={Unitechlogo} style={{ width: "70px" }}></img>
              </th>
              <th
                id={`${id}-Title`}
                name={`${name}-Title`}
                colSpan="4"
                style={{
                  textAlign: "center",
                  whiteSpace: "nowrap",
                  padding: "10px 0px",
                }}
              > */}
                  {/* <font style={{ fontSize: "24px" }}>
                  UNITECH PLASTO COMPONENTS (P) LTD
                </font>
                <br></br> */}
                  <font color="#8B0000">Material Loading Check Sheet</font>
                </th>
              </tr>
              <tr>
                <td className="topic-head">
                  &nbsp;&nbsp;Machine No<span style={{ color: "red" }}>*</span>
                </td>
                <td
                // style={redAlert === true ? {  border:'5px solid red' }:""}
                
                 >
                  {/* <DropdownFetcher keyword="mac_dtl_dropdown" setValue={setMachine} /> */}

                  {/* <Select
                value={MachineNo}
                  options={machine.map((item) => ({
                    value: item.mh_mac_name,
                    label: item.mh_mac_name,
                  }))}
                  onChange={event =>{handleMachine(event.value)}}
                  
                /> */}
                <div
                style={redAlert?{border:'3px solid red'}:{}}>
                {/* {console.log("redAlert",redAlert)} */}
                  <Select
                    id="smp_Cr_Machine"
                    name="smp_Cr_Machine"
                    value={MachineNo}
                    onChange={(e)=>{handleMachine(e);setRedAlert(false)}}
                    options={mcNoOptions}
                    onInputChange={(event) => { GetSelectValue(event, "Get_machine_from_smp", setMachine) }}
                    placeholder="Search Machine No..."
                  />
                  {MachineNo === '' && isCreating && <span className="Req">Required *</span>}
                  </div>
                </td>
                <td colSpan={2}></td>
              </tr>

              <tr>
                <td className="topic-head">&nbsp;&nbsp;Date</td>
                <td>
                  <DatePicker value={TrackDate} onChange={handleDateChange} dis={true} />
                </td>

                <td className="topic-head">&nbsp;&nbsp;Shift</td>
                <td>
                  <select
                    id={`${id}-Shift`}
                    name={`${name}-Shift`}
                    value={Shift}
                    onChange={(e) => setShift(e.target.value)}
                    style={{ textAlign: "center" }}
                    disabled
                  >
                    <option
                      id={`${id}-Shift-Select`}
                      name={`${name}-Shift-Select`}
                      value=""
                    >
                      -- Select --
                    </option>
                    <option id={`${id}-ShiftA`} name={`${name}-ShiftA`} value="A">
                      A12
                    </option>
                    <option id={`${id}-ShiftB`} name={`${name}-ShiftB`} value="B">
                      B12
                    </option>
                  </select>
                </td>
              </tr>
              <tr>
                <td className="topic-head">
                  &nbsp;&nbsp;Material Name<span style={{ color: "red" }}>*</span>
                </td>
                <td>
                  {MatNme === '' && isCreating && <span className="Req">Required *</span>}
                  {/* <DropdownFetcher keyword="rm_details_view" setValue={setMaterial} /> */}
                  <Select
                    id={`${id}-MaterialName`}
                    value={MatNme}
                    // options={Material.map((item) => ({
                    //   value: item.Mh_raw_mat,
                    //   label: item.Mh_raw_mat,
                    // }))}
                    options={rmOptions}
                    // onInputChange={(event) => { GetSelectValue(event, "rm_details_dropdown_grade", setMaterial) }}
                    // onChange={(event) => { handleFieldChange(event.value), handleMaterialStandard(event.label) }}
                    // onChange={(event) => { handleMaterialStandard(event.label) }}
                    // onBlur={(event)=>{handleMaterialStandard(event),console.log("event.value",event)}}
                    onChange={handleRawMaterial}
                    placeholder="Search..."
                  />
                </td>

                <td className="topic-head">
                  &nbsp;&nbsp;Grade<span style={{ color: "red" }}>*</span>
                </td>
                <td>
                  {Grade === '' && isCreating && <span className="Req">Required *</span>}
                  <input
                    id={`${id}-Grade`}
                    name={`${name}-Grade`}
                    type="text"
                    value={Grade}
                    onChange={(e) => setGrade(e.target.value)}
                    readOnly={MatNme === '' || Inchrge === '' || MachineNo === ''}
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">&nbsp;&nbsp;Material Lot No<span style={{ color: "red" }}>*</span></td>

                <td >{MatLotNo === '' && isCreating && <span className="Req">Required *</span>}
                  <select value={MatLotNo} onChange={handleMatLotChange}>
                    <option value="">Select Lot No</option>
                    {Array.isArray(matlot) ? (
                      matlot.map((item) => (
                        <option key={item}>{item}</option>
                      ))
                    ) : (
                      <option value="">No MatLot data available</option>
                    )}
                  </select>
                </td>

                <td className="topic-head">
                  &nbsp;&nbsp;Material Loading in Kgs<span style={{ color: "red" }}>*</span>
                </td>
                <td>
                  <input
                    id={`${id}-MatLdKg`}
                    name={`${name}-MatLdKg`}
                    type="number"
                    value={MatLdKg}
                    onChange={(e) => setMatLdKg(e.target.value)}
                    readOnly={MatNme === '' || Inchrge === '' || MachineNo === ''}
                  />
                  {MatLdKg === '' && isCreating && <span className="Req">Required *</span>}

                </td>
              </tr>
              <tr>
                <td className="topic-head">&nbsp;&nbsp;Material Loading Time</td>
                <td>
                  <input
                    id={`${id}-MatLdTme`}
                    name={`${name}-MatLdTme`}
                    type="time"
                    value={MatLdTme}
                    onChange={(e) => setMatLdTme(e.target.value)}
                    readOnly
                  />
                </td>

                <td className="topic-head">&nbsp;&nbsp;Pre Heat Hours<span style={{ color: "red" }}>*</span>
                  (<span>{MinHours ? MinHours : ""}</span>-<span>{MaxHours ? MaxHours : ""}</span>)
                </td>
                <td>
                  <input
                    id={`${id}-PreHtHrs`}
                    name={`${name}-PreHtHrs`}
                    type="number"
                    value={PreHtHrs}
                    onChange={(e) => setPreHtHrs(e.target.value)}
                    // readOnly
                    style={
                      (parseFloat(PreHtHrs) >= parseFloat(MinHours) && parseFloat(PreHtHrs) <= parseFloat(MaxHours) || PreHtHrs == "")

                        ? { width: "100%" }
                        : {
                          width: "100%",
                          backgroundColor: "#d98984",
                          border: "2px solid #DA251A",
                        }
                    }
                  />
                  {PreHtHrs === '' && isCreating && <span className="Req">Required *</span>}

                </td>
              </tr>
              <tr>
                <td className="topic-head">&nbsp;&nbsp;Pre Heating Temp<span style={{ color: "red" }}>*</span>

                  (<span>{DryTemp ? DryTemp : ""}</span>±<span>{DryTempTolerance ? DryTempTolerance : ""}</span>)</td>
                <td>
                  <input
                    id={`${id}-PreHtTmp`}
                    name={`${name}-PreHtTmp`}
                    type="number"
                    value={PreHtTmp}
                    onChange={(e) => setPreHtTmp(e.target.value)}
                    style={
                      !(
                        parseFloat(PreHtTmp) >
                        parseFloat(DryTemp) +
                        parseFloat(DryTempTolerance) ||
                        parseFloat(PreHtTmp) <
                        parseFloat(DryTemp) -
                        parseFloat(DryTempTolerance)
                      )
                        ? { width: "100%" }
                        : {
                          width: "100%",
                          backgroundColor: "#d98984",
                          border: "2px solid #DA251A",
                        }
                    }
                  />
                  {PreHtTmp === '' && isCreating && <span className="Req">Required *</span>}

                </td>
                <td className="topic-head">&nbsp;&nbsp;Moisture</td>
                <td>
                  <input
                    id={`${id}-Moisture`}
                    name={`${name}-Moisture`}
                    type="number"
                    value={Moisture}
                    onChange={(e) => setMoisture(e.target.value)}
                    // onKeyDown="return false"
                    readOnly={MatNme === '' || Inchrge === '' || MachineNo === ''}
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">&nbsp;&nbsp;Incharge Name<span style={{ color: "red" }}>*</span></td>
                <td>
                  {Inchrge === '' && isCreating && <span className="Req">Required *</span>}
                  {/* <DropdownFetcher keyword="emp_details" setValue={setemployee} /> */}
                  <Select
                    id="incharge"
                    value={Inchrge}
                    options={employee.map((item) => ({
                      value: item.Emp_code,
                      label: item.Emp_name,
                    }))}
                    onInputChange={(event) => { GetSelectValue(event, "emp_details_dropdown", setemployee) }}
                    onChange={event => { setEmployeeName(event.value) }}
                    placeholder="Search..."
                  />
                </td>

                <td className="topic-head">&nbsp;&nbsp;QC Sign<span style={{ color: "red" }}>*</span></td>
                <td>
                  {/* <input
                  id={`${id}-QcSign`}
                  name={`${name}-QcSign`}
                  type="text"
                  value={QcSign}
                  onChange={(e) => setQcSign(e.target.value)}
                /> */}
                  {QcSign === '' && isCreating && <span className="Req">Required *</span>}
                  {/* <DropdownFetcher keyword="emp_details" setValue={setemployee} /> */}
                  <Select
                    value={QcSign}
                    options={employee.map((item) => ({
                      value: item.Emp_code,
                      label: item.Emp_name,
                    }))}
                    onInputChange={(event) => { GetSelectValue(event, "emp_details_dropdown", setemployee) }}
                    onChange={event => { setQAName(event.value) }}
                    placeholder="Search..."
                  />
                </td>
              </tr>

              <tr>
                <td colSpan={4} className="materialLoadingSubmit">
                  <button
                    type="submit"
                    id={`${id}-submit`}
                    name={`${name}-submit`}
                    onClick={(e) => handleCreate(e)}
                    disabled={MachineNo === "" || MatNme === "" || Inchrge === "" || QcSign === ""}
                    hidden={MachineNo === "" || MatNme === "" || Inchrge === "" || QcSign === ""}
                  >
                    Submit
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  );
}

export default MaterialLoadingCreate;
