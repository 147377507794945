import React,{useState} from "react";
import { Tab, Tabs } from "react-bootstrap";
import SupplierMasterCreate from "./SupplierMasterCreate";
import SupplierMasterView from "./SupplierMasterView";


function SupplierMasterTab () {
    const [viewTabClicked, setViewTabClicked] = useState(false);
    const [activeKey, setActiveKey] = useState("tab1");
const handleTabClick = (tabKey) => {
  setActiveKey(tabKey);
    if (tabKey === "tab2") {
      setViewTabClicked(true);
    } else {
      setViewTabClicked(false);
    }
  };

  return (
    <div>
      <div>
        <Tabs
          defaultActiveKey="tab1"
          // transition={false}
          id="noanim-tab-example"
          className="mb-3"
           onSelect={handleTabClick}
        >
          <Tab name="Sp.mas.CreateTab" eventKey="tab1" title="Create">
            <div>
              <div className="set-layout">
                <SupplierMasterCreate tab1={activeKey === "tab1"}/>
              </div>
            </div>
          </Tab>
          <Tab name="Sp.mas.ViewTab" eventKey="tab2" title="View">
            <div>
              <div className="set-layout">
                {viewTabClicked && <SupplierMasterView />}
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default SupplierMasterTab;