import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import style from "./Layout.module.css";
// import { ToastContainer } from "react-toastify";

import "bootstrap/dist/css/bootstrap.min.css";

// Pages importing
import Sidebar from "./Components/Sidebar/Sidebar";
import Dashboard from "./Pages/Dashboard/Dashboard";
import MainHeader from "./Components/Header/MainHeader";

import MaterialStandardTab from "./Pages/MasterScreen/MaterialStandard/MachineMasterTab";
import MasterHeadTab from "./Pages/MasterScreen/MasterHead/MasterHeadTab";
import CustomerMasterTab from "./Pages/MasterScreen/CustomerMaster/CustomerMasterTab";
import EmployeeMasterTab from "./Pages/MasterScreen/EmployeeMaster/EmployeeMasterTab";
import BarcodeGenerator from "./Pages/MasterScreen/BarcodeGeneration/BarcodeHandler";
import MachineMasterTab from "./Pages/MasterScreen/MachineMaster/MachineMasterTab";
import MachinePartMasterTab from "./Pages/MasterScreen/MachinePartMaster/MachinePartMasterTab";
import FGMasterTab from "./Pages/MasterScreen/FGMaster/FGMasterTab";
import SupplierMasterTab from "./Pages/MasterScreen/SupplierMaster/SupplierMasterTab";
import RMMasterTab from "./Pages/MasterScreen/RMMaster/RMMasterTab";
import RMMasterView from "./Pages/MasterScreen/RMMaster/RMMasterView";
import SmpTabs from "./Pages/MoldingScreen/Smp/SmpTabs";
import PmsTabs from "./Pages/MoldingScreen/Pms/PmsTabs";

import MaterialLoadingTabs from "./Pages/MoldingProduction/MaterialLoading/MaterialLoadingTabs";
import Report4MTabs from "./Pages/MoldingProduction/4MReport/Report4MTabs";
import MldUnLoadAndLoadTabs from "./Pages/MoldingProduction/MldUnLoadAndLoad/MldUnLoadAndLoadTabs";
import DeviationFormTabs from "./Pages/MoldingProduction/DeviationForm/DeviationFormTabs";
import MCActRequestReportTabs from "./Pages/MoldingProduction/MCActRequestReport/MCActRequestReportTabs";
import TemperatureSheetTabs from "./Pages/MoldingProduction/Temperaturesheet/TemperatureSheetTabs";
import MacBDTabs from "./Pages/MoldingProduction/MacBreakDown/MacBDTabs";
import LUMPTabs from "./Pages/MoldingProduction/LUMP/LUMPTabs";
import ProductionSkillTabs from "./Pages/MoldingProduction/ProductionSkillM/ProductionSkillTabs";
import EcnReportView from "./Pages/MoldingProduction/EcnReport/EcnReportView";
import EcnReportTabs from "./Pages/MoldingProduction/EcnReport/EcnReportTabs";
import ProcessValidationTabs from "./Pages/MoldingProduction/ProcessValidationandReValidation/ProcessValidationTabs";
import DailyShiftProcessCheckListTabs from "./Pages/MoldingProduction/DailyShiftProcessCheckList/DailyShiftProcessCheckListTabs";
import { GetAccess } from "./Lib/GetAccess";
import PageLock from "./Pages/PageLock/PageLock";
import Approval from "./Pages/Approval/ApprovalTab";
import DeviationFormView from "./Pages/MoldingProduction/DeviationForm/DeviationFormView";
import MacBDView from "./Pages/MoldingProduction/MacBreakDown/MacBDView";
import MldUnLoadAndLoadView from "./Pages/MoldingProduction/MldUnLoadAndLoad/MldUnLoadAndLoadView";
import MCActRequestReportView from "./Pages/MoldingProduction/MCActRequestReport/MCActRequestReportView";
import ProductionSkillView from "./Pages/MoldingProduction/ProductionSkillM/ProductionSkillView";
import ProcessValidationView from "./Pages/MoldingProduction/ProcessValidationandReValidation/ProcessValidationView";
// import store from "../share/store/store";
// import Popup from "./UnitechPlasto/Popup/Popup";
// import FirstOffInspTabs from "./UnitechPlasto/MoldingScreen/MoldingQuality/FirstOffInspection/FirstOffInspTabs";
// import LastOffInspTabs from "./UnitechPlasto/MoldingScreen/MoldingQuality/LastOffInspection/LastOffInspTabs";
// import InlineInspectionTabs from "./UnitechPlasto/MoldingScreen/MoldingQuality/InlineInspection/InlineInspectionTabs";
// import OnlineInspectionTabs from "./UnitechPlasto/MoldingScreen/MoldingQuality/OnlineInspection/OnlineInspectionTabs";
import NotificationList from "./Pages/Notification/NotificationList";
import MaterialLoadingView from "./Pages/MoldingProduction/MaterialLoading/MaterialLoadingView";
import Report4MView from "./Pages/MoldingProduction/4MReport/Report4MView";
import DailyShiftProcessCheckListView from "./Pages/MoldingProduction/DailyShiftProcessCheckList/DailyShiftProcessCheckListView";
import TemperatureSheetView from "./Pages/MoldingProduction/Temperaturesheet/TemperatureSheetView";
import MachineStartupTabs from "./Pages/MoldingProduction/MachineStartup/MachineStartupTabs";
import SmpReport from "./Pages/MoldingScreen/Smp/SmpReport";
import SmpReport1 from "./Pages/MoldingScreen/NewSmp/SmpReport";
import PmsReport from "./Pages/MoldingScreen/NewPMS/PmsReport";
import EmployeeDetail from "./Pages/EmpDetails/EmployeeDetail";
import FirstOffForm from "./Pages/Quality/First-Off/FirstOffForm";
import FirstOffList from "./Pages/Quality/First-Off/FirstOffList";
import LastOffForm from "./Pages/Quality/Last-Off Ins/LastOffForm";
import LastOffList from "./Pages/Quality/Last-Off Ins/LastOffList";
import QaLayout from "./Pages/Quality/QaLayout";
import OnlineInspectionForm from "./Pages/Quality/Online-Inspection/OnlineInspectionForm";
import OnlineInspectionList from "./Pages/Quality/Online-Inspection/OnlineInspectionList";
import LayoutInspectionForm from "./Pages/Quality/LayoutInspection/LayoutInspectionForm";
import LayoutInspectionList from "./Pages/Quality/LayoutInspection/LayoutInspectionList";
import ProductQualityPlanForm from "./Pages/Quality/ProductQualityPlan/ProductQualityPlanForm";
import ProductQualityPlanList from "./Pages/Quality/ProductQualityPlan/ProductQualityPlanList";
import Approvals from "./Pages/Approval/Approvals";
import PmsCreate from "./Pages/MoldingScreen/NewPMS/PmsCreate";
import PMSNewView from "./Pages/MoldingScreen/NewPMS/PMSNewView";
import NewSmpInjectionSettings from "./Pages/MoldingScreen/NewSmp/NewSmpInjectionSetting";
import NewSmpMain from "./Pages/MoldingScreen/NewSmp/NewSmpMain";
import NewSmpList from "./Pages/MoldingScreen/NewSmp/NewSmpList";
import PreViewSmpPage from "./Pages/MoldingScreen/NewSmp/PreViewSmpPage";
import NewSMpViewPage from "./Pages/MoldingScreen/NewSmp/NewSmpViewPage";
import RmMasterListView from "./Pages/MasterScreen/RMMaster/RmMasterListView";
import MachineStartupList from "./Pages/MoldingProduction/MachineStartup/MachineStartupList";
import MachineStartupCreate from "./Pages/MoldingProduction/MachineStartup/MachineStartupCreate";

import SPPForm from "./Pages/PaintingProduction/Production/StandardProcessParam/SPPForm";
import SPPList from "./Pages/PaintingProduction/Production/StandardProcessParam/SPPList";
import PMMForm from "./Pages/PaintingProduction/Production/PaintMixingMonitor/PMMForm";
import PMMList from "./Pages/PaintingProduction/Production/PaintMixingMonitor/PMMList";
import LRForm from "./Pages/PaintingProduction/Production/LoadingReport/LoadingReportForm";
import LRList from "./Pages/PaintingProduction/Production/LoadingReport/LoadingList";
import PaintMaster from "./Pages/PaintingProduction/Master/PaintMaster/PaintMaser";
import PaintMasterList from "./Pages/PaintingProduction/Master/PaintMaster/PaintMasterList";
import PartMaster from "./Pages/PaintingProduction/Master/PartMaster/PartMaster";
import PartMasterList from "./Pages/PaintingProduction/Master/PartMaster/PartMasterList";
import ProgramMaster from "./Pages/PaintingProduction/Master/ProgramMaster/ProgramMaster";
import ProgramMasterList from "./Pages/PaintingProduction/Master/ProgramMaster/ProgramMasterList";
import CustomerMaster from "./Pages/PaintingProduction/Master/CustomerMaster/CustomerMaster";
import CustomerMasterList from "./Pages/PaintingProduction/Master/CustomerMaster/CustomerMasterList";

import Firstandlastoff from "./Pages/PaintingProduction/Quality/FirstandLastOff/Firstandlastoff";
import FinalIns from "./Pages/PaintingProduction/Quality/FinalInspection/FinalInspection";
import SPPViewPage from "./Pages/PaintingProduction/Production/StandardProcessParam/SPPViewPage";
import PaintSupplierMaster from "./Pages/PaintingProduction/Master/SupplierMaster/SupplierMaster";
import PaintSupplierMasterList from "./Pages/PaintingProduction/Master/SupplierMaster/SupplierMasterList";
import HardnerMaster from "./Pages/PaintingProduction/Master/HardnerMaster/HardnerMaster";
import HardnerMasterList from "./Pages/PaintingProduction/Master/HardnerMaster/HardnerMasterList";
import PrimerMasterList from "./Pages/PaintingProduction/Master/PrimerMaster/PrimerMasterList";
import PrimerMaster from "./Pages/PaintingProduction/Master/PrimerMaster/PrimerMaster";
import ThinnerMaster from "./Pages/PaintingProduction/Master/ThinnerMaster/ThinnerMaster";
import ThinnerMasterList from "./Pages/PaintingProduction/Master/ThinnerMaster/ThinnerMasterList";
import FirstandlastoffList from "./Pages/PaintingProduction/Quality/FirstandLastOff/FirstandlastoffList";
import PaintLoadingViewPage from "./Pages/PaintingProduction/Production/LoadingReport/ViewPage";
import FinalViewPage from "./Pages/PaintingProduction/Quality/FinalInspection/ViewPage";
import FinalInspectionList from "./Pages/PaintingProduction/Quality/FinalInspection/FinalInspectionList";
import PaintMasterHead from "./Pages/PaintingProduction/Master/PaintMasterHead/PaintMasterHead";
import PaintMasterHeadList from "./Pages/PaintingProduction/Master/PaintMasterHead/PaintMasterHeadList";
import PMMFormnew from "./Pages/PaintingProduction/Production/PaintMixingMonitor/PMMFormnew";
export default function Layout() {
  const [isOpen, setIsOpen] = useState(false);


  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Router>
      <div className={style.layout_main}>
        <div style={{ position: "absolute", width: "100%" }}>
          <MainHeader />
        </div>
        <div className={`${isOpen ? "" : style.collapsed}`}>
          <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
        </div>

        <div className={style.layout_right_container}>
          {/* <ToastContainer /> */}

          <div className={style.layout_main_area_container}>
            <div>
              <div>
                <Routes>
                  <Route exact path="/" element={<Dashboard />} />
                  <Route exact path="/dashboard" element={<Dashboard />} />

                  {/* <Route
                    exact
                    path="/CustomerMaster"
                    element={
                      !GetAccess("ADMIN", "GM") ? (
                        <PageLock />
                      ) : (
                        <CustomerMasterTab />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path="/EmployeeDetail/:empId"
                    element={<EmployeeDetail />}
                  />
                  <Route
                    exact
                    path="/CustomerMaster"
                    element={!(GetAccess("ADMIN", "GM") || GetAccess("Quality (QC)","SR.EXECUTIVE")) ? <PageLock /> : <CustomerMasterTab />}
                  />
                  <Route
                    exact
                    path="/EmployeeMaster"
                    element={
                      !(GetAccess("ADMIN", "GM") || GetAccess("Quality (QC)","SR.EXECUTIVE")) ? (
                        <PageLock />
                      ) : (
                        <EmployeeMasterTab />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/BarcodeGenerator"
                    element={
                      !(GetAccess("ADMIN", "GM") || GetAccess("Quality (QC)","SR.EXECUTIVE")) ? (
                        <PageLock />
                      ) : (
                        <BarcodeGenerator />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/MachineMaster"
                    element={
                      !(GetAccess("ADMIN", "GM") || GetAccess("Quality (QC)","SR.EXECUTIVE")) ? (
                        <PageLock />
                      ) : (
                        <MachineMasterTab />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/MachinePartMaster"
                    element={
                      !(GetAccess("ADMIN", "GM") || GetAccess("Quality (QC)","SR.EXECUTIVE")) ? (
                        <PageLock />
                      ) : (
                        <MachinePartMasterTab />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/FGMaster"
                    element={
                      !(GetAccess("ADMIN", "GM") || GetAccess("Quality (QC)","SR.EXECUTIVE")) ? (
                        <PageLock />
                      ) : (
                        <FGMasterTab />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/SupplierMaster"
                    element={
                      !(GetAccess("ADMIN", "GM") || GetAccess("Quality (QC)","SR.EXECUTIVE")) ? (
                        <PageLock />
                      ) : (
                        <SupplierMasterTab />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/RMMaster"
                    element={
                      !(GetAccess("ADMIN", "GM") || GetAccess("Quality (QC)","SR.EXECUTIVE")) ? (
                        <PageLock />
                      ) : (
                        <RMMasterTab />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/RMMaster/List"
                    element={<RmMasterListView />}
                  />
                   <Route path="/RMMaster/:tabKey" element={<RMMasterTab />} />
                  <Route
                    exact
                    path="/MasterHead"
                    element={
                      !(GetAccess("ADMIN", "GM") || GetAccess("Quality (QC)","SR.EXECUTIVE")) ? (
                        <PageLock />
                      ) : (
                        <MasterHeadTab />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/MaterialStandard"
                    element={
                      !(GetAccess("ADMIN", "GM") || GetAccess("Quality (QC)","SR.EXECUTIVE")) ? (
                        <PageLock />
                      ) : (
                        <MaterialStandardTab />
                      )
                    }
                  />
                  <Route exact path="/Smp" element={<SmpTabs />} />
                  <Route
                    exact
                    path="/MaterialLoading"
                    element={<MaterialLoadingTabs />}
                  />
                  <Route exact path="/Pms" element={<PmsCreate />} />
                  <Route exact path="/Pms/:option/:id" element={<PmsCreate />} />
                  <Route exact path="/Pms/list" element={<PMSNewView />} />
                  <Route
                    exact
                    path="/Pms/Report"
                    element={<PmsReport />}
                  />
                  <Route exact path="/Newpms" element={<PmsTabs />} />
                  <Route exact path="/Report4M" element={<Report4MTabs />} />
                  <Route exact path="/RMMaster/:option/:id" element={<RMMasterView />} />
                  <Route
                    exact
                    path="/MldUnLoadAndLoad"
                    element={<MldUnLoadAndLoadTabs />}
                  />
                  <Route
                    exact
                    path="/DeviationForm"
                    element={<DeviationFormTabs />}
                  />

                  <Route
                    exact
                    path="/MCActRequestReport"
                    element={<MCActRequestReportTabs />}
                  />
                  <Route
                    exact
                    path="/TemperatureSheet"
                    element={<TemperatureSheetTabs />}
                  />
                  <Route exact path="/MacBD" element={<MacBDTabs />} />
                  <Route exact path="/LUMP" element={<LUMPTabs />} />
                  <Route
                    exact
                    path="/ProductionSkill"
                    element={<ProductionSkillTabs />}
                  />
                  <Route exact path="/EcnReport" element={<EcnReportTabs />} />
                  <Route
                    exact
                    path="/ProcessValidation"
                    element={<ProcessValidationTabs />}
                  />

                  <Route
                    exact
                    path="/DailyShiftProcessCheckList"
                    element={<DailyShiftProcessCheckListTabs />}
                  />
                  <Route
                    exact
                    path="/MachineStartup"
                    element={<MachineStartupCreate />}
                  />
                    <Route
                    exact
                    path="/MachineStartup"
                    element={<MachineStartupTabs />}
                  />
                     <Route
                    exact
                    path="/MachineStartup/list"
                    element={<MachineStartupList />}
                  />
               
                  <Route
                    exact
                    path="/MachineStartup/:option/:id"
                    element={<MachineStartupCreate />}
                  />
                   
                  <Route
                    exact
                    path="/NotificationList"
                    element={<NotificationList />}
                  />
                  {/* 
                    <Route exact path="/Popup" element={<Popup />} />
                    <Route
                      exact
                      path="/FirstOffInspTabs"
                      element={<FirstOffInspTabs />}
                    />
                    <Route
                      exact
                      path="/LastOffInspTabs"
                      element={<LastOffInspTabs />}
                    />
                    <Route
                      exact
                      path="/InlineInspectionTabs"
                      element={<InlineInspectionTabs />}
                    />
                    <Route
                      exact
                      path="/OnlineInspectionTabs"
                      element={<OnlineInspectionTabs />}
                    />
                    */}
                  {/* <Route
                    exact
                    path="/Approval"
                    element={
                      !GetAccess("production", "hod") ? (
                        <PageLock />
                      ) : (
                        <Approval />
                      )
                    }
                  /> */}
                  <Route
                    exact
                    path="/Approval"
                    element={
                      (GetAccess("production", "hod") || GetAccess("ADMIN", "GM") || GetAccess("Quality (QC)","SR.EXECUTIVE" )) ? (
                        <Approvals />
                      ) : (
                        <PageLock />

                      )
                    }
                  />
                  <Route
                    exact
                    path="/DeviationForm/view/:id"
                    element={<DeviationFormView />}
                  />
                  <Route
                    exact
                    path="/EcnReport/view/:id"
                    element={
                      (GetAccess("production", "hod") || GetAccess("ADMIN", "GM")) ? (
                        <EcnReportView />
                      ) : (
                        <PageLock />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/MaterialLoading/view/:id"
                    element={<MaterialLoadingView />}
                  />
                  <Route
                    exact
                    path="/Report4M/view/:id"
                    element={<Report4MView />}
                  />
                  <Route
                    exact
                    path="/DailyShiftProcessCheckList/view/:id"
                    element={<DailyShiftProcessCheckListView />}
                  />
                  <Route
                    exact
                    path="/TemperatureSheet/view/:id"
                    element={<TemperatureSheetView />}
                  />
                  <Route
                    exact
                    path="/Smp/view/:Id"
                    element={<SmpReport />}
                  />
                  <Route
                    exact
                    path="/MacBD/view/:id"
                    element={<MacBDView />}
                  />
                  <Route
                    exact
                    path="/MldUnLoadAndLoad/view/:id"
                    element={<MldUnLoadAndLoadView />}
                  />
                  <Route
                    exact
                    path="/MCActRequestReport/view/:id"
                    element={<MCActRequestReportView />}
                  />
                  <Route
                    exact
                    path="/ProductionSkill/view/:id"
                    element={<ProductionSkillView />}
                  />
                  <Route
                    exact
                    path="/ProcessValidation/view/:id"
                    element={<ProcessValidationView />}
                  />

                  <Route
                    exact
                    path="/quality_plan"
                    element={<ProductQualityPlanForm />}
                  />
                  <Route
                    exact
                    path="/quality_plan/list"
                    element={<ProductQualityPlanList />}
                  />
                  <Route
                    exact
                    path="/quality_plan/:option/:id"
                    element={<ProductQualityPlanForm />}
                  />

                  <Route
                    exact
                    path="/firstoff"
                    element={<FirstOffForm />}
                  />
                  <Route
                    exact
                    path="/firstoff/list"
                    element={<FirstOffList />}
                  />
                  <Route
                    exact
                    path="/firstoff/:option/:id"
                    element={<FirstOffForm />}
                  />
                  <Route
                    exact
                    path="/lastoff"
                    element={<LastOffForm />}
                  />
                  <Route
                    exact
                    path="/lastoff/list"
                    element={<LastOffList />}
                  />
                  <Route
                    exact
                    path="/lastoff/:option/:id"
                    element={<LastOffForm />}
                  />

                  <Route
                    exact
                    path="/onlineinspection"
                    element={<OnlineInspectionForm />}
                  />
                  <Route
                    exact
                    path="/onlineinspection/list"
                    element={<OnlineInspectionList />}
                  />
                  <Route
                    exact
                    path="/onlineinspection/:option/:id"
                    element={<OnlineInspectionForm />}
                  />
                  <Route
                    exact
                    path="/layoutinline"
                    element={<LayoutInspectionForm />}
                  />
                  <Route
                    exact
                    path="/layoutinline/list"
                    element={<LayoutInspectionList />}
                  />
                  <Route
                    exact
                    path="/layoutinline/:option/:id"
                    element={<LayoutInspectionForm />}
                  />
                  <Route
                    exact
                    path="/NewSmpInjectionSettings"
                    element={<NewSmpInjectionSettings />}
                  />
                  <Route
                    exact
                    path="/newSmpMain"
                    element={<NewSmpMain />}
                  />
                  <Route
                    exact
                    path="/newSmpMain/:option/:id"
                    element={<NewSmpMain />}
                  />
                  <Route
                    exact
                    path="/newSmpMain/list"
                    element={<NewSmpList />}
                  />
                  <Route
                    exact
                    path="/newSmpMain/Report"
                    element={<SmpReport1 />}
                  />
                  <Route
                    exact
                    path="/newSmpMain/view/:id"
                    element={<NewSMpViewPage />}
                  />
                  <Route
                    exact
                    path="/newSmpMain/:option/:id"
                    element={<NewSmpMain />}
                  />
                   <Route
                    exact
                    path="/PaintMaster"
                    element={<PaintMaster />}
                  />
                  <Route
                    exact
                    path="/PaintMaster/list"
                    element={<PaintMasterList />}
                  />
                   
                  <Route
                    exact
                    path="/PaintMaster/:option/:id"
                    element={<PaintMaster />}
                  />
                  <Route
                    exact
                    path="/PaintPartMaster"
                    element={<PartMaster />}
                  />

                  <Route
                    exact
                    path="/PaintPartMaster/list"
                    element={<PartMasterList />}
                  />
                  <Route
                    exact
                    path="/PaintPartMaster/:option/:id"
                    element={<PartMaster />}
                  />
                  <Route
                  exact
                  path="/PaintProgramMaster"
                  element={<ProgramMaster/>}
                  />
                    <Route
                    exact
                    path="/PaintProgramMaster/list"
                    element={<ProgramMasterList />}
                  />
                  <Route
                    exact
                    path="/PaintProgramMaster/:option/:id"
                    element={<ProgramMaster />}
                  />
                    <Route
                  exact
                  path="/PaintCustomerMaster"
                  element={<CustomerMaster/>}
                  />
                    <Route
                    exact
                    path="/PaintCustomerMaster/list"
                    element={<CustomerMasterList />}
                  />
                  <Route
                    exact
                    path="/PaintCustomerMaster/:option/:id"
                    element={<CustomerMaster />}
                  />
                  <Route
                  exact
                  path="/PaintSupplierMaster"
                  element={<PaintSupplierMaster />}
                  />
                    <Route
                    exact
                    path="/PaintSupplierMaster/list"
                    element={<PaintSupplierMasterList />}
                  />
                  <Route
                    exact
                    path="/PaintSupplierMaster/:option/:id"
                    element={<PaintSupplierMaster />}
                  />
                    <Route
                  exact
                  path="/PaintHardnerMaster"
                  element={<HardnerMaster />}
                  />
                    <Route
                    exact
                    path="/PaintHardnerMaster/list"
                    element={<HardnerMasterList />}
                  />
                  <Route
                    exact
                    path="/PaintHardnerMaster/:option/:id"
                    element={<HardnerMaster />}
                  />
                    <Route
                  exact
                  path="/PaintPrimerMaster"
                  element={<PrimerMaster />}
                  />
                    <Route
                    exact
                    path="/PaintPrimerMaster/list"
                    element={<PrimerMasterList />}
                  />
                  <Route
                    exact
                    path="/PaintPrimerMaster/:option/:id"
                    element={<PrimerMaster />}
                  />
                    <Route
                  exact
                  path="/PaintThinnerMaster"
                  element={<ThinnerMaster />}
                  />
                    <Route
                    exact
                    path="/PaintThinnerMaster/list"
                    element={<ThinnerMasterList />}
                  />
                  <Route
                    exact
                    path="/PaintThinnerMaster/:option/:id"
                    element={<ThinnerMaster />}
                  />
                   <Route
                  exact
                  path="/PaintMasterHead"
                  element={<PaintMasterHead />}
                  />
                    <Route
                    exact
                    path="/PaintMasterHead/list"
                    element={<PaintMasterHeadList />}
                  />
                  <Route
                    exact
                    path="/PaintMasterHead/:option/:id"
                    element={<PaintMasterHead />}
                  />
                    <Route
                    exact
                    path="/StandardProcessParameter"
                    element={<SPPForm />}
                  />
                  <Route
                    exact
                    path="/StandardProcessParameter/list"
                    element={<SPPList />}
                  />
                   <Route
                    exact
                    path="/StandardProcessParameter/view/:id"
                    element={<SPPViewPage />}
                  />
                  <Route
                    exact
                    path="/StandardProcessParameter/:option/:id"
                    element={<SPPForm />}
                  />
                   <Route
                    exact
                    path="/PaintMixingMonitoring"
                    element={<PMMFormnew />}
                  />
                  <Route
                    exact
                    path="/PaintMixingMonitoring/list"
                    element={<PMMList />}
                  />
                 <Route
                    exact
                    path="/PMMForm/:option/:id"
                    element={<PMMForm />}
                  />
                   <Route
                    exact
                    path="/LoadingReport"
                    element={<LRForm />}
                  />
                  <Route
                    exact
                    path="/LoadingReport/list"
                    element={<LRList />}
                  />
                  <Route
                    exact
                    path="/LoadingReport/:option/:id"
                    element={<LRForm />}
                  />
                  <Route
                    exact
                    path="/LoadingReport/view/:id"
                    element={<PaintLoadingViewPage />}
                  />

                    <Route
                    exact
                    path="/FirstandLastoff"
                    element={<Firstandlastoff />}
                  />
                  <Route
                    exact
                    path="/FirstandLastoff/list"
                    element={<FirstandlastoffList />}
                  />
                  <Route
                    exact
                    path="/FirstandLastoff/:option/:id"
                    element={<Firstandlastoff />}
                  />
                   <Route
                    exact
                    path="/FinalInspection"
                    element={<FinalIns/>}
                  />
                  <Route
                    exact
                    path="/FinalInspection/list"
                    element={<FinalInspectionList />}
                  />
                  <Route
                    exact
                    path="/FinalInspection/:option/:id"
                    element={<FinalIns />}
                  />
                    <Route
                    exact
                    path="/FinalInspection/view/:id"
                    element={<FinalViewPage />}
                  />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
}
//Layout commit