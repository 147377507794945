import React, { useState, useEffect } from "react";
import Popup from "../../../Components/Popup/Popup";
import { SECRET_KEY, PROCESS_TRANSACTION_UPDATE_URL } from "../../../Utils/constants";
//import "bootstrap/dist/css/bootstrap.min.css";
import HandleRead from "../../../Lib/HandleRead.js"
import "../../../Styles/Part3.css";
import Pagination from "../../../Components/Pagination";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import UpdateRecord from "../../../Lib/updateRecord";
import { MdDelete } from "react-icons/md";
import ReadRecord from "../../../Lib/API/ReadRecord.js";

function FGMasterView() {
  const [fgMasterAddState, setFGMasterAddState] = useState({
    FgCde: "",
    FgDescn: "",
    ClassDesc: "",
    Flexy1: "",
    Flexy2: "",
    Flexy3: "",
    UnitOfMeasure: "",
    Lot: "",
    isPaginationVisible: true,
    currentPage: 1,
    filterFgcd: "",
    filterFgnm: ""
  })
  const [isCreating, setIsCreating] = useState(false);
  const [fg_mas, setfg_mas] = useState([]);
  const [ButtonPopup2, setButtonPopup2] = useState(false);
  const [ButtonPopup, setButtonPopup] = useState(false);

  const recordPerPage = 20;

  const filterRecords = () => {
    const filteredData = fg_mas.filter((record) => {
      const fgnmMatch = record.Fg_description
        .toLowerCase()
        .includes(fgMasterAddState.filterFgnm.toLowerCase());
      const fgcdMatch = record.Fg_code
        .toLowerCase().
        includes(fgMasterAddState.filterFgcd.toLowerCase());
      return fgnmMatch && fgcdMatch;
    });
    return filteredData || [];
  };
  const filteredData = filterRecords();
  const npage = Math.ceil(filteredData.length / recordPerPage);
  const numbers = [...Array(npage).keys()].map((num) => num + 1);

  function handleFilterfgnmChange(event) {
    setFGMasterAddState({
      ...fgMasterAddState,
      currentPage: 1,
      filterFgnm: event.target.value
    });
  }
  function handleFilterfgcdChange(event) {
    setFGMasterAddState({
      ...fgMasterAddState,
      currentPage: 1,
      filterFgcd: event.target.value
    });
  }

  const firstIndex = (fgMasterAddState.currentPage - 1) * recordPerPage;
  const lastIndex = firstIndex + recordPerPage;
  const filteredRecords = filteredData.slice(firstIndex, lastIndex);


  // const currentPage = fgMasterAddState.currentPage;

  // const firstIndex = (currentPage - 1) * recordPerPage;
  // const lastIndex = Math.min(firstIndex + recordPerPage, fg_mas.length);

  // const records = fg_mas.slice(firstIndex, lastIndex);
  // const npage = Math.ceil(fg_mas.length / recordPerPage);
  // const numbers = [...Array(npage + 1).keys()].slice(1);



  function prePage() {

    if (fgMasterAddState.currentPage > 1) {
      setFGMasterAddState({
        ...fgMasterAddState,
        currentPage: fgMasterAddState.currentPage - 1
      });
    }
  }

  function changeCpage(id) {
    setFGMasterAddState({
      ...fgMasterAddState,
      currentPage: id
    });
  }
  function nextPage() {
    if (fgMasterAddState.currentPage < npage) {
      setFGMasterAddState({
        ...fgMasterAddState,
        currentPage: fgMasterAddState.currentPage + 1
      });
    }
  }

  const transferValue2 = (e) => {

    setFGMasterAddState({
      ...fgMasterAddState,
      isPaginationVisible: false,

      FgCde: e.Fg_code,
      FgDescn: e.Fg_description,
      ClassDesc: e.Class_desc,
      Flexy1: e.Fg_flex_01,
      Flexy2: e.Fg_flex_02,
      Flexy3: e.Fg_flex_03,
      UnitOfMeasure: e.Unit_of_measure,
      Lot: e.Lot,
    });
    setButtonPopup2(true);
  };

  const viewPagination = () => {
    setFGMasterAddState({
      ...fgMasterAddState,
      isPaginationVisible: true
    });
  };

  const handleEdit = (e) => {
    setFGMasterAddState({
      ...fgMasterAddState,
      isPaginationVisible: false,
      Id: e.Id,
      FgCde: e.Fg_code,
      FgDescn: e.Fg_description,
      ClassDesc: e.Class_desc,
      Flexy1: e.Fg_flex_01,
      Flexy2: e.Fg_flex_02,
      Flexy3: e.Fg_flex_03,
      UnitOfMeasure: e.Unit_of_measure,
      Lot: e.Lot,
    });
    setButtonPopup(true);
  };
  const handleUpdate = (e) => {
    e.preventDefault();

    if (!fgMasterAddState.FgCde || !fgMasterAddState.FgDescn) {
      setIsCreating(true);

      alert(GeneralConstants.MANDATORY_ALERT);
      return;
    }
    const data = {
      data: [
        {
          Id: fgMasterAddState.Id,
          Fg_code: fgMasterAddState.FgCde,
          Fg_description: fgMasterAddState.FgDescn,
          Class_desc: fgMasterAddState.ClassDesc,
          Fg_flex_01: fgMasterAddState.Flexy1,
          Fg_flex_02: fgMasterAddState.Flexy2,
          Fg_flex_03: fgMasterAddState.Flexy3,
          Unit_of_measure: fgMasterAddState.UnitOfMeasure,
          Lot: fgMasterAddState.Lot,

        },
      ],
    };
    data["_keyword_"] = "fg_details_update";
    data["secretkey"] = SECRET_KEY;
    ;
    console.log(data);
    getAPIRequest(
      PROCESS_TRANSACTION_UPDATE_URL, 'POST', data
    ).then((response) => {
      setFGMasterAddState({
        ...fgMasterAddState,
        SelectedOption: "",
        FgCde: "",
        FgDescn: "",
        Stock: "",
        Flexy1: "",
        Flexy2: "",
        Flexy3: "",
        isPaginationVisible: true,
      });
      // setfg_mas([...fg_mas, response.data]);
      alert(`FG master code ${GeneralConstants.UPDATE_SUCCESSFUL}`);
      // window.location.reload();
      setButtonPopup(false);
      handleReadFgMaster();

    })
      .catch((error) => {
        console.log(error);
        alert(GeneralConstants.UPDATE_FAILURE);
        window.location.reload();
      });
  };
  function handleReadFgMaster() {
    ReadRecord({
      _keyword_: "fg_details_view"
    }).then((res) => {
      // console.log(res.data);
      setfg_mas(res.data);
    });
  }
  useEffect(() => {
    handleReadFgMaster();
  }, [])
  return (
    <div>
      <Popup

        trigger={ButtonPopup2}
        setTrigger={setButtonPopup2}
        viewPagination={viewPagination}
      >
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <form id="fgmv.view.container"
          name="fgmv.view.container" className="form-fgMaster-View">
          <table
            id="fgmv.view.table"
            name="fgmv.view.table"
            style={{ textAlign: "center", width: "100%" }}
            cellPadding="5"
          >
            <tbody>
              <tr>
                <th id="fgmv.Title.View"
                  name="fgmv.Title.View"
                  height="20%"
                  colSpan="4"
                  style={{ textAlign: "center", whiteSpace: "nowrap" }}
                >
                  <font color="#8B0000">View - FG Master</font>
                </th>
              </tr>
              <tr>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;FG -Code
                </th>
                <td
                  width="25%">
                  <label id="fgmv.view.fgCode"
                    name="fgmv.view.fgCode" >{fgMasterAddState.FgCde}</label>
                </td>

                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;FG-Description
                </th>
                <td
                  width="25%">
                  <label id="fgmv.view.fgName"
                    name="fgmv.view.fgName">{fgMasterAddState.FgDescn}</label>
                </td>
              </tr>
              <tr>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Class Description
                </th>
                <td>
                  <label id="fgmv.view.fgVer"
                    name="fgmv.view.fgVer"
                    width="25%">{fgMasterAddState.ClassDesc}</label>
                </td>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Lot
                </th>
                <td>
                  <label id="fgmv.view.fgVer"
                    name="fgmv.view.fgVer"
                    width="25%">{fgMasterAddState.Lot}</label>
                </td>
              </tr><tr>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Unit Of Measure
                </th>
                <td>
                  <label id="fgmv.view.fgVer"
                    name="fgmv.view.fgVer"
                    width="25%">{fgMasterAddState.UnitOfMeasure}</label>
                </td>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;FG Stock
                </th>
                <td>
                  <label id="fgmv.view.fgFlex1"
                    name="fgmv.view.fgFlex1"
                    width="25%">{fgMasterAddState.Flexy1}</label>
                </td>
              </tr>
              <tr>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Flex 1
                </th>
                <td>
                  <label id="fgmv.view.fgFlex2"
                    name="fgmv.view.fgFlex2"
                    width="25%">{fgMasterAddState.Flexy2}</label>
                </td>
                <th
                  width="25%"
                  style={{ textAlign: "left", whiteSpace: "nowrap" }}
                >
                  &nbsp;&nbsp;Flex 2
                </th>
                <td>
                  <label id="fgmv.view.fgFlex3"
                    name="fgmv.view.fgFlex3"
                    width="25%">{fgMasterAddState.Flexy3}</label>
                </td>
              </tr>

            </tbody>
          </table>
        </form>
      </Popup>
      <Popup
        trigger={ButtonPopup}
        setTrigger={setButtonPopup}
        viewPagination={viewPagination}
      >
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <form id="fgMasterEdit.formContainer" name="fgMasterEdit.formContainer" className="form-fgMaster-Edit">
          <table style={{ textAlign: "center", width: "100%" }} cellPadding="5" >
            <tbody>
              <tr>
                <th
                  id="fgmc.Title"
                  name="fgmc.Title"
                  colSpan="4"
                  style={{ textAlign: "center", whiteSpace: "nowrap" }}
                >
                  <font color="#8B0000">Edit - FG Master</font>
                </th>
              </tr>
              <tr>
                <th>&nbsp;&nbsp;FG Code<span style={{ color: "red" }}>*</span></th>
                <td>
                  <input
                    id="fgmc.FGCode"
                    name="fgmc.FGCode"
                    type="text"
                    value={fgMasterAddState.FgCde}
                    onChange={(e) => setFGMasterAddState({
                      ...fgMasterAddState,
                      FgCde: e.target.value
                    })}
                    required
                    disabled
                  />
                </td>
                <th>&nbsp;&nbsp;FG Description<span style={{ color: "red" }}>*</span></th>
                <td>
                  {fgMasterAddState.FgDescn === '' && isCreating && <span className="Req">Required *</span>}
                  <input
                    id="fgmc.FGName"
                    name="fgmc.FGName"
                    type="text"
                    value={fgMasterAddState.FgDescn}
                    onChange={(e) => setFGMasterAddState({
                      ...fgMasterAddState,
                      FgDescn: e.target.value
                    })}
                    required
                  />
                </td>
              </tr>
              <tr>
                <th>&nbsp;&nbsp;Class Description</th>
                <td>
                  {/* <input
                id="fgmc.ClassDesc"
                name="fgmc.ClassDesc"
                  type="text"
                  
                  value={fgMasterAddState.ClassDesc}
                  onChange={(e) => setFGMasterAddState({...fgMasterAddState,
                    ClassDesc: e.target.value
                  })}
                /> */}
                  <select
                    id="fgmc.ClassDesc"
                    name="fgmc.ClassDesc"
                    value={fgMasterAddState.ClassDesc}
                    onChange={(e) => setFGMasterAddState({
                      ...fgMasterAddState,
                      ClassDesc: e.target.value
                    })}
                    style={{ width: "95%", textAlign: "center" }}
                  >
                    <option id="fgmc.ClassDesc.select"
                      name="fgmc.ClassDesc.select"
                      value="">-- Select --</option>
                    <option id="fgmc.ClassDesc.FINISHED-GOOODS"
                      name="fgmc.ClassDesc.FINISHED-GOOODS"
                      value="FINISHED GOOODS">FINISHED GOOODS</option>
                    <option id="fgmc.ClassDesc.FIXED-ASSETS"
                      name="fgmc.ClassDesc.FIXED-ASSETS"
                      value="FIXED ASSETS">FIXED ASSETS</option>
                    <option id="fgmc.ClassDesc.JIG-AND-FIXTURES"
                      name="fgmc.ClassDesc.JIG-AND-FIXTURES"
                      value="JIG AND FIXTURES">JIG AND FIXTURES</option>
                    <option id="fgmc.ClassDesc.SEMI-FINISHED-GOODS"
                      name="fgmc.ClassDesc.SEMI-FINISHED-GOODS"
                      value="SEMI FINISHED GOODS">SEMI FINISHED GOODS</option>
                  </select>
                </td>
                <th>&nbsp;&nbsp;Lot</th>
                <td>
                  <input
                    id="fgmc.Lot"
                    name="fgmc.Lot"
                    type="text"
                    value={fgMasterAddState.Lot}
                    onChange={(e) => setFGMasterAddState({
                      ...fgMasterAddState,
                      Lot: e.target.value
                    })}
                  />
                </td>
              </tr><tr>
                <th>&nbsp;&nbsp;Unit Of Measure</th>
                <td>

                  <select
                    id="fgmc.UnitOfMeasure"
                    name="fgmc.UnitOfMeasure"
                    value={fgMasterAddState.UnitOfMeasure}
                    onChange={(e) => setFGMasterAddState({
                      ...fgMasterAddState,
                      UnitOfMeasure: e.target.value
                    })}
                    style={{ width: "95%", textAlign: "center" }}
                  >
                    <option id="fgmc.UnitOfMeasure.select"
                      name="fgmc.UnitOfMeasure.select"
                      value="">-- Select --</option>
                    <option id="fgmc.UnitOfMeasure.KGS"
                      name="fgmc.UnitOfMeasure.KGS"
                      value="KGS">KGS</option>
                    <option id="fgmc.UnitOfMeasure.LIT"
                      name="fgmc.UnitOfMeasure.LIT"
                      value="LIT">LIT</option>
                    <option id="fgmc.UnitOfMeasure.NOS"
                      name="fgmc.UnitOfMeasure.NOS"
                      value="NOS">NOS</option>
                  </select>

                  {/* <input
                id="fgmc.UnitOfMeasure"
                name="fgmc.UnitOfMeasure"
                  type="text"
                  value={fgMasterAddState.UnitOfMeasure}
                  onChange={(e) => setFGMasterAddState({...fgMasterAddState,
                    UnitOfMeasure: e.target.value
                  })}
                /> */}
                </td>
                <th>&nbsp;&nbsp;FG Stock</th>
                <td>
                  <input
                    id="fgmc.Flexy1"
                    name="fgmc.Flexy1"
                    type="text"
                    value={fgMasterAddState.Flexy1}
                    onChange={(e) => setFGMasterAddState({
                      ...fgMasterAddState,
                      Flexy1: e.target.value
                    })}
                  />
                </td>
              </tr>
              <tr>
                <th>&nbsp;&nbsp;Flex 1</th>
                <td>
                  <input
                    id="fgmc.Flexy2"
                    name="fgmc.Flexy2"
                    type="text"
                    value={fgMasterAddState.Flexy2}
                    onChange={(e) => setFGMasterAddState({
                      ...fgMasterAddState,
                      Flexy2: e.target.value
                    })}
                  />
                </td>
                <th>&nbsp;&nbsp;Flex 2</th>
                <td>
                  <input
                    id="fgmc.Flexy3"
                    name="fgmc.Flexy3"
                    type="text"
                    value={fgMasterAddState.Flexy3}
                    onChange={(e) => setFGMasterAddState({
                      ...fgMasterAddState,
                      Flexy3: e.target.value
                    })}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="4" style={{ border: "none" }}>
                  <button type="submit" id="fgmc.submit" name="fgmc.submit" onClick={(e) => handleUpdate(e)}>
                    Update
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </Popup>

      <div>
        <table
          style={{ textAlign: "center", width: "100%" }}
        /*{ cellPadding="5" }*/
        >
          <thead>
            <tr>
              <th
                id="fgmv.Title.List"
                name="fgmv.Title.List"
                colSpan="9"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">List - FG Master</font>
              </th>
            </tr>
            <tr>
              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                FG Name
              </td>
              <td>
                <input
                  id="mpmv.filter.Fgnm"
                  name="mpmv.filter.Fgnm"
                  type="text"
                  placeholder="Filter Fg name"
                  style={{ fontSize: "80%", width: "100%" }}
                  value={fgMasterAddState.filterFgnm}
                  onChange={handleFilterfgnmChange}
                />
              </td>
              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                FG Code
              </td>
              <td colSpan={6}>
                <input
                  id="mpmv.filter.Fgcd"
                  name="mpmv.filter.Fgcd"
                  type="text"
                  placeholder="Filter Fg code"
                  style={{ fontSize: "80%", width: "100%" }}
                  value={fgMasterAddState.filterFgcd}
                  onChange={handleFilterfgcdChange}
                />
              </td>
            </tr>
            <tr className="table_position">
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;FG-Code&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp; FG-Name&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Class Description&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;FG-Stock&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Lot&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Unit Of Measure&nbsp;&nbsp;
              </th>
              {/* <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Flex-3&nbsp;&nbsp;
              </th>*/}
              {/* <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Created Date&nbsp;&nbsp;
              </th> */}
              <th width="10%">

              </th>
              <th width="10%">

              </th>
              <th width="10%">

              </th>
            </tr>
          </thead>
          <tbody>
            {/* <HandleRead keyword="fg_details_view" setData={setfg_mas} /> */}
            {filteredRecords.length === 0 ? (
              <td colSpan="12" style={{ textAlign: "center" }}>
                <p id="mmv.noRecordFound" name="mmv.noRecordFound" style={{ fontWeight: "bold" }}>No records found.</p>
              </td>
            ) : (
              filteredRecords.map((ele) => (
                <tr className="table_view" key={ele.Id}>
                  <td id="fgmv.list.fgCode"
                    name="fgmv.list.fgCode" className="table-cell">&nbsp;&nbsp;{ele.Fg_code}</td>
                  <td id="fgmv.list.fgName"
                    name="fgmv.list.fgName" className="table-cell">&nbsp;&nbsp;{ele.Fg_description}</td>
                  <td id="fgmv.list.fgver"
                    name="fgmv.list.fgver"
                    className="table-cell">&nbsp;&nbsp;{ele.Class_desc}</td>
                  <td id="fgmv.list.flex1"
                    name="fgmv.list.flex1"    
                    className="table-cell">&nbsp;&nbsp;{ele.Fg_flex_01}</td>
  <td className="table-cell">&nbsp;&nbsp;{ele.Lot}</td>
                  <td className="table-cell">&nbsp;&nbsp;{ele.Unit_of_measure}</td>
                  {/*   <td className="table-cell">&nbsp;&nbsp;{fg_mas.mc_flex_03}</td>
					*/}
                  {/* <td id="fgmv.list.createddate"
        name="fgmv.list.createddate"
        className="table-cell">&nbsp;&nbsp;{ele.mc_cr_dt}</td> */}
                  <td>
                    <button
                      id="fgmv.view" name="fgmv.view"
                      style={{ backgroundColor: "#cba423", whiteSpace: "nowrap" }}
                      type="button"
                      onClick={() => transferValue2(ele)}
                    >
                      View&#x1F441;
                    </button>
                  </td>
                  <td>
                    <button
                      id="fgmv.edit" name="fgmv.edit"
                      type="button" onClick={() => handleEdit(ele)}>
                      Edit&#x270E;
                    </button>
                  </td>
                  <td>
                    <button
                      id={`fg_details_update-Delete`}
                      name={`fg_details_update-Delete`}
                      style={{ whiteSpace: "nowrap", backgroundColor: "#DA251A" }}
                      type="button"
                      onClick={() => {
                        const userConfirmed = window.confirm('Are you sure you want to delete?');
                        if (userConfirmed) {
                          UpdateRecord({
                            _keyword_: 'fg_details_update',
                            data: [
                              {
                                Id: ele.Id,
                                Active_status: 'InActive',
                              },
                            ],
                          }).then(() => {
                            handleReadFgMaster();
                          })
                          // window.location.reload();
                        }
                      }}
                    >
                      Delete<MdDelete />
                    </button>
                  </td>
                </tr>
              )))}
          </tbody>
        </table>
        <div className="pagination" id="fgmv.pagination" name="fgmv.pagination">
          {fgMasterAddState.isPaginationVisible && filteredData.length > 0 && (
            <Pagination currentPage={fgMasterAddState.currentPage} npage={npage} prePage={prePage} nextPage={nextPage} changeCpage={changeCpage} numbers={numbers} />
          )}


        </div>
      </div>
    </div>
  );
}

export default FGMasterView;
