import React from "react";


export function Popup(props) {  
  return (props.trigger) ? (
    <div className="popup" id="popup">
      <div className="popup-inner">
        <button id="close-btn"
        name="close-btn"
        className="close-btn" onClick={() => {props.setTrigger(false); props.viewPagination()}}>
          X
        </button>
        {props.children}
      </div>
    </div>
  ) : 
    ""
  ;
  

}

export default Popup;
