import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PreviewView from "./PreViewPage";
import { CURRENT_Date, CURRENT_TIME, SHIFTMOLDINGQUALITY } from "../../../../Utils/GeneralConstants";
import ReadRecordPaint from "../../../../Lib/API/ReadRecordPaint";

const ViewPage = () => {
  
    const InitRoot = [
        {
          download: false,
          // Time: CURRENT_TIME,
          Shift: SHIFTMOLDINGQUALITY,
          Format_no: "UPCL/PRO/R/39",
          Rev_no: "01",
          Rev_ate: "18-05-2017",
          Entry_date: CURRENT_Date,
          // Partname: [{}],
          // PartName: [{}],
          PartNumber: [{}],
          paint_loading_bulk_report: [
            {
              Time: CURRENT_TIME,
              PartName: {},
              Part_name: "",
              Lot_no: "",
              Fresh_part: "",
              Rework_part: "",
              Rej_part: "",
              Total_Part: "",
              Remarks: "",
            },
          ],
          paint_names: [
            {key:"Loader Name"},
            {key:"Ipa Wiper Name"},
            {key:"Booth Assistant"},
            {key:"Painter Name1" }, 
            {key:"Painter Name2" }, 
            {key:"Operator Name1" }, 
            {key:"Operator Name2" }, 
          ],
          LoaderName: "",
          IpaName: "",
          BoothName: "",
          PainterName1: "",
          PainterName2: "",
          OperatorName1: "",
          OperatorName2: "",
          LoadIncharge: "",
          ShiftIncharge: "",
          ApprovedBy: "",
          CreatedBy: "",
          LoaderName: "",
          Loader_Name: [{}],
          Ipa_Name: [{}],
          Booth_Name: [{}],
          Painter_Name1: [{}],
          Painter_Name2: [{}],
          Operator_Name1: [{}],
          Operator_Name2: [{}],
          Load_Incharge: [{}],
          Shift_Incharge: [{}],
          Approved_By: [{}],
          Created_By: [{}],
        },
      ];
    const navigate = useNavigate();
    const [Records, setRecords] = useState(InitRoot);
    const [Preview, setPreView] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        console.log("Records", Records);
    }, [Records]);

    useEffect(() => {
        console.log("id",id);
        document.title = 'Loading Report';
        const fetchData = async () => {
            if (id === undefined) {
                setRecords({});
            } else {
                try {
                    const res = await ReadRecordPaint({
                        _keyword_: 'paint_loading_report',
                        Id: id,
                    });

                    if (res.data[0] === undefined) {
                        setRecords(InitRoot);
                    } else {
                        const mergedData = { ...res.data[0], paint_names: InitRoot[0].paint_names };
                        setRecords([mergedData]);
                       // setRecords(res.data);
                        setPreView(true);
                    }
                } catch(error) {
                    console.log(error);
                }
            }
        };

        fetchData();
    }, [id]);

    useEffect(() => {
        if (Preview === false) {
            navigate(-1);
        }
    }, [Preview, navigate]);

    return Preview && <PreviewView Records={Records} setState={setPreView}/>;
}

export default ViewPage;
