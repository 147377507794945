import axios from "axios"
import { PROCESS_TRANSACTION_UPDATE_URL, SECRET_KEY } from "../../Utils/constants"

const DeleteRecordByActive = (KeyWord, id) => {
    return axios
        .post(PROCESS_TRANSACTION_UPDATE_URL, {
            data: [{
                Id: id + "",
                ActiveStatus: "InActive"
            }],
            _keyword_: KeyWord,
            secretkey: SECRET_KEY,
        })
        .then(res => {
            return res.data
        })
        .catch(error => {
            return { error: error }
        })
}
export default DeleteRecordByActive