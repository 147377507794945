import React, { useEffect, useState, useRef, useCallback } from "react";
import Field from "../../../../Components/Fields/Field";
import Lable from "../../../../Components/Fields/Lable";
import { useNavigate, useParams } from "react-router-dom";
import "./PMMForm.css";
import * as GeneralConstants from "../../../../Utils/GeneralConstants.js";
import {
  CURRENT_Date,
  SHIFTMOLDINGQUALITY,
} from "../../../../Utils/GeneralConstants";
import LoadingPage from "../../../../Components/Handler/LoadingPage";
import { Dropdown } from "react-bootstrap";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";

import Logo from "../../../../icons/Logo1.png";
import { useScrollEffect } from "../../../Quality/useScrollEffect";
import CreateRecordPaint from "../../../../Lib/API/CreateRecordPaint.js";
import ReadRecordPaint from "../../../../Lib/API/ReadRecordPaint.js";
import UpdateRecordPaint from "../../../../Lib/API/UpdateRecordPaint.js";
import QaLayoutPaint from "../../QaLayoutPaint.js";
const PMMForm = () => {
  const contentRef = useRef(null);
  useScrollEffect();
  const [DownloadDiv, setDownloadDiv] = useState(false);
  const CURRENT_TIME = new Date(
    new Date().getTime() - new Date().getTimezoneOffset() * 60000
  )
    .toISOString()
    .split("T")[1]
    .substring(0, 5);

  const InitRoot = [
    {
      download: false,
      Part_name: "",
      Part_number: "",
      Customer: "",
      Paint_Supplier: "",
      Approved_By: "",
      Created_By: "",
      Date: CURRENT_Date,
      Shift: SHIFTMOLDINGQUALITY,
      Format_No: "UPCL/PRO/R/34",
      Rev_No: "00",
      Rev_ate: "09-03-2020",
      Mix_Time: "",
      Mixing_Ratio: "",
      Primer_base_clear: "",
      Hardner: "",
      Thinner: "",
      Primer_Batch_No: "",
      Primer_Starting_Time: "",
      Primer_Quantity: "",
      Primer_Viscosity: "",
      Primer_Air_Pressure_Level: "",
      Primer_Paint_Mixer_Name: "",
      Primer_Engg_Sign: "",
      Hardner_Batch_No: "",
      Hardner_Starting_Time: "",
      Hardner_Quantity: "",
      Hardner_Viscosity: "",
      Hardner_Air_Pressure_Level: "",
      Hardner_Paint_Mixer_Name: "",
      Hardner_Engg_Sign: "",
      Thinner_Batch_No: "",
      Thinner_Starting_Time: "",
      Thinner_Quantity: "",
      Thinner_Viscosity: "",
      Thinner_Air_Pressure_Level: "",
      Thinner_Paint_Mixer_Name: "",
      Thinner_Engg_Sign: "",
      PartName: [{}],
      PartNumber: [{}],
      ApprovedBy: [{}],
      CreatedBy: [{}],
      SupName: [{}],
      CusName: [{}],
      ThinnerEng: [{}],
      PrimerEng: [{}],
      HardnerEng: [{}],
    },
  ];
  const tableRef = useRef(null);
  const scrollTop = useRef(null);

  const [downloadEnabled, setDownloadEnabled] = useState(true);
  const [ButtonPopup, setButtonPopup] = useState(false);
  const [Records, setRecords] = useState(InitRoot);
  const [warning, setwarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(false);
  useEffect(() => {
    console.log("Records", Records);
  }, [Records]);
    
  const { option, id } = useParams();
  const navigate = useNavigate();

  function HandleRecords(Event) {
    setRecords([{ ...Records[0], [Event.target.name]: Event.target.value }]);
  }

  function HandleCreate(event) {
    try{
    if (
      !Records[0].Part_name ||
      !Records[0].Created_By ||
      !Records[0].Approved_By
    ) {
      alert("Please Fill the Mandatory Fields");
      setwarning(true);
    } else {
      let record = [...Records];
      record && delete record[0].PartName;
      record && delete record[0].PartNumber;
      record && delete record[0].SupName;
      record && delete record[0].CusName;
      record && delete record[0].ThinnerEng;
      record && delete record[0].HardnerEng;
      record && delete record[0].PrimerEng;
      record && delete record[0].CreatedBy;
      record && delete record[0].ApprovedBy;
      console.log("createFter Delete", record);
      CreateRecordPaint("paint_mixing_monitor", record).then((res) => {
        console.log("HandleCreate", res);
      });
      alert(GeneralConstants.SUBMISSION_SUCCESSFUL);
      setwarning(false);
      setRecords(InitRoot);
    }}
    catch (error){
      console.error(error);
      alert(GeneralConstants.SUBMISSION_FAILED);
    }
  }
  function HandleUpdate(event) {
    try{
    if (
      !Records[0].Part_name ||
      !Records[0].Created_By ||
      !Records[0].Approved_By
    ) {
      alert("Please Fill the Mandatory Fields");
      setwarning(true);
    } else {
      let record = [...Records];
      record && delete record[0].PartName;
      record && delete record[0].PartNumber;
      record && delete record[0].SupName;
      record && delete record[0].CusName;
      record && delete record[0].ThinnerEng;
      record && delete record[0].HardnerEng;
      record && delete record[0].PrimerEng;
      record && delete record[0].CreatedBy;
      record && delete record[0].ApprovedBy;
      UpdateRecordPaint("paint_mixing_monitor", record).then((res) => {
        console.log("HandleUpdate", res);
      });
      alert(GeneralConstants.UPDATE_SUCCESSFUL);
      setwarning(false);
      navigate(-1);
    }}
    catch (error){
      console.error(error);
      alert(GeneralConstants.UPDATE_FAILURE);
    }
  }
  useEffect(() => {
    setwarning(false);
    document.title = "Paint Mixing Monitor";
    const fetchData = async () => {
      if (id === undefined) {
        setRecords([
          {
            ...Records[0],
            Date: CURRENT_Date,
            //Time: CURRENT_TIME,
            Shift: SHIFTMOLDINGQUALITY,
          },
        ]);
      } else {
        setLoading(true);
        try {
          const res = await ReadRecordPaint({
            _keyword_: "paint_mixing_monitor",
            Id: id,
          });
          if (res.data[0] === undefined) {
            setRecords(InitRoot);
          } else {
            setRecords(res.data);
            setResult(false);
          }
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return <LoadingPage />;
  }

  function HandleRecordSelect(Event, column, value, label) {
    setRecords(() => {
      let record = [...Records];
      record[0][Event.name] = Event.values.value;
      record[0][column][0][value] = Event.values.value;
      record[0][column][0][label] = Event.values.label;

      return record;
    });
  }
  function HandleRecordSelectPartname(Event, column, value, label) {
    setRecords(() => {
      let record = [...Records];
      record[0][Event.name] = Event.values.label;
      record[0][column][0][value] = Event.values.value;
      record[0][column][0][label] = Event.values.label;

      return record;
    });
  }
  function HandlePartname(e) {
    const PN = e.values.label;
    ReadRecordPaint({
      _keyword_: "Get_part_code_from_paint",
      PartName: PN,
    }).then((res) => {
      if (res.rows == "1") {
        setRecords(() => {
          let record = [...Records];
          console.log(res.data[0].part_code);
            (record[0].Part_number = res.data[0].part_code)
          //  (record[0]["PartNumber"][0]["Part_number"] = res.data[0].part_code);
          return record;
        });
      } else if (res.rows > "1") {
        console.log("HandlePartname else", res.rows);
      }
      console.log("HandlePartname", res.rows);
    });
  }

  return (
    <QaLayoutPaint record={Records}>
      {/* <div className="excel">
        {downloadEnabled && option === "view" && (
          <Dropdown style={{ width: "100%", al: "center" }}>
            <Dropdown.Toggle style={{ color: "black" }}>
              Download ⬇️
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>
                <button
                  className="custom-button"
                  style={{ color: "black" }}
                  onClick={generatePDF}
                >
                  PDF ⬇️
                </button>
              </Dropdown.Item>
              <Dropdown.Item>
                <button
                  className="custom-button"
                  style={{ color: "black" }}
                  onClick={() => exportToExcel()}
                >
                  Export to Excel
                </button>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div> */}
      <div className="Form" ref={scrollTop}>
        <div ref={tableRef}>
          <table>
            <tbody>
              <tr>
                <th colSpan={7}>
                  <Lable
                    style={{ padding: "0px" }}
                    value={"Paint Mixing Monitoring"}
                    type={"Head"}
                  />
                </th>

                <td colSpan={1}>
                  <tr>
                    <td style={{ width: "15%" }}>
                      <Field
                        label={"FORMAT NO"}
                        name={"Format_No"}
                        style={{ padding: "0px" }}
                        options={{
                          type: "text",
                          // defaultValue: "UPCL/QC/R/02",
                          disabled: true,
                        }}
                        value={Records[0]}
                        OnChange={(e) => {
                          HandleRecords(e);
                        }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td style={{ width: "25%" }}>
                      <Field
                        label={"REV NO"}
                        name={"Rev_No"}
                        style={{ padding: "0px" }}
                        options={{
                          type: "text",
                          // defaultValue: "00",
                          disabled: true ,
                        }}
                        value={Records[0]}
                        OnChange={(e) => {
                          HandleRecords(e);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "25%" }}>
                      <Field
                        label={"REV DATE"}
                        name={"Rev_ate"}
                        style={{ padding: "0px" }}
                        options={{
                          type: "text",
                          // defaultValue: "18-08-2018",
                          disabled: true,
                        }}
                        value={Records[0]}
                        OnChange={(e) => {
                          HandleRecords(e);
                        }}
                      />
                    </td>
                  </tr>
                </td>
                {/* )} */}
              </tr>
              <tr>
                <td colSpan={2}>
                  <Field
                    label={"Part Name"}
                    name={"Part_name"}
                    options={{
                      type: "reference",
                      key: Records[0].Part_number,
                      reference: "Get_paint_part_name",
                      required: true,
                      disabled: option === "view" ,
                    }}
                    warning={warning}
                 
                    value={{
                      value: Records?.[0]?.PartName?.[0]?.Part_name || "",
                      label: Records?.[0]?.PartName?.[0]?.Part_name || "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelectPartname(
                        e,
                        "PartName",
                        "Part_name",
                        "Part_name"
                      );

                      HandlePartname(e);
                    }}
                  />
                </td>
                <td colSpan={2}>
                  <Field
                    label={"Date"}
                    name={"Date"}
                    options={{ type: "date", disabled: true }}
                    value={Records[0]}
                    // style={{ padding: "0px", width: "250px" }}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                <td colSpan={2}>
                  <Field
                    label={"Mix Time"}
                    name={"Mix_Time"}
                    options={{
                      type: "time",
                      disabled: option === "view" || !option === "edit",
                    }}
                    value={Records[0]}
                    // style={{ padding: "0px", width: "250px" }}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                <td colSpan={2}>
                  <Field
                    label={"Customer"}
                    name={"Customer"}
                    options={{
                      type: "reference",
                      reference: "Get_paint_customer_name",
                      required: true,
                      disabled: option === "view",
                    }}
                    // style={{ padding: "0px", width: "380px" }}
                    warning={warning}
                    value={{
                      value: Records?.[0]?.CusName?.[0]?.Customer_code || "",
                      label: Records?.[0]?.CusName?.[0]?.Customer_name || "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "CusName",
                        "Customer_code",
                        "Customer_name"
                      );
                    }}
                  />
                </td>
              </tr>

              <tr>
                <td colSpan={2}>
                  <Field
                    label={"Part Number"}
                    name={"Part_number"}
                   // style={{ padding: "0px" }}
                    options={{
                      type: "text",
                      disabled: true,
                    }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  ></Field>
                </td>
                <td colSpan={2}>
                  <Field
                    label={"Shift"}
                    name={"Shift"}
                    options={{
                      type: "dropdown",
                      disabled: true,
                      values: [
                        { value: "A", label: "A12" },
                        { value: "B", label: "B12" },
                      ],
                    }}
                    value={Records[0]}
                   // style={{ padding: "0px", width: "250px" }}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>

                <td colSpan={2}>
                  <Field
                    label={"Mixing Ratio"}
                    name={"Mixing_Ratio"}
                    options={{ type: "number", disabled: option === "view" }}
                    warning={warning}
                    value={Records[0]}
                   // style={{ padding: "0px", width: "250px" }}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                <td colSpan={2}>
                  <Field
                    label={"Paint Supplier"}
                    name={"Paint_Supplier"}
                    options={{
                      type: "reference",
                      reference: "Get_paint_supplier_name",
                      required: true,
                      disabled: option === "view",
                    }}
                   
                    warning={warning}
                    value={{
                      value: Records?.[0]?.SupName?.[0]?.Supplier_code || "",
                      label: Records?.[0]?.SupName?.[0]?.Supplier_name || "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "SupName",
                        "Supplier_code",
                        "Supplier_name"
                      );
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th colSpan={8}>
                  <Lable value={"PaintDetails"} type={"Head"} />
                </th>
              </tr>
              <tr>
                <td colSpan={3}  style={{width:"46%"}}>
                  <Field
                    label={"Primer/Base/Clear"}
                    name={"Primer_base_clear"}
                    options={{ type: "number", disabled: option === "view" }}
                    //  warning={warning}
                    //doubleLine={true}
                 
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                <td  colSpan={1}>
                  <Field
                    label={"Batch No"}
                    name={"Primer_Batch_No"}
                    options={{ type: "number", disabled: option === "view" }}
                    warning={warning}
                   // //doubleLine={true}
                   // style={{ padding: "0px" }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                {/* <td style={{ width: "10%" }} colSpan={1}>
                  <Field
                    label={"Starting Time"}
                    name={"Primer_Starting_Time"}
                    options={{ type: "number", disabled: option === "view" }}
                    warning={warning}
                    //doubleLine={true}
                    style={{ padding: "0px", width: "120px" }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td> */}
                <td  colSpan={2}>
                  <Field
                    label={"Qty"}
                    name={"Primer_Quantity"}
                    options={{ type: "number", disabled: option === "view" }}
                    warning={warning}
                    //doubleLine={true}
                   // style={{ padding: "0px" }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                <td colSpan={2}>
                  <Field
                    label={"Viscosity"}
                    name={"Primer_Viscosity"}
                    options={{ type: "number", disabled: option === "view" }}
                    warning={warning}
                    //doubleLine={true}
                   // style={{ padding: "0px" }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                {/* <td style={{ width: "10%" }} colSpan={1}>
                  <Field
                    label={"AirPressureLevel"}
                    name={"Primer_Air_Pressure_Level"}
                    //doubleLine={true}
                    style={{ padding: "0px", width: "150px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    warning={warning}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                <td style={{ width: "10%" }} colSpan={1}>
                  <Field
                    label={"PainterMixerName"}
                    name={"Primer_Paint_Mixer_Name"}
                    //doubleLine={true}
                    style={{ padding: "0px", width: "150px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    warning={warning}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                <td style={{ width: "10%" }} colSpan={3}>
                  <Field
                    label={"Engg Sign"}
                    name={"Primer_Engg_Sign"}
                    //doubleLine={true}
                    style={{ padding: "0px", width: "200px" }}
                    options={{
                      type: "reference",
                      reference: "emp_details_dropdown",
                      required: true,
                      disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records?.[0]?.PrimerEng?.[0]?.Emp_code || "",
                      label: Records?.[0]?.PrimerEng?.[0]?.Emp_name || "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "PrimerEng",
                        "Emp_code",
                        "Emp_name"
                      );
                    }}
                  />
                </td> */}
              </tr>
              <tr></tr>
              <tr>
                <td  colSpan={3}>
                  <Field
                    label={"Hardner"}
                    name={"Hardner"}
                    options={{ type: "number", disabled: option === "view" }}
                    //  warning={warning}
                    //doubleLine={true}
                  //  style={{ padding: "0px" }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                <td  colSpan={1}>
                  <Field
                    label={"Batch No"}
                    name={"Hardner_Batch_No"}
                    options={{ type: "number", disabled: option === "view" }}
                    warning={warning}
                    //doubleLine={true}
                  //  style={{ padding: "0px" }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                {/* <td style={{ width: "10%" }} colSpan={1}>
                  <Field
                    label={"Starting Time"}
                    name={"Hardner_Starting_Time"}
                    options={{ type: "number", disabled: option === "view" }}
                    warning={warning}
                    //doubleLine={true}
                    style={{ padding: "0px", width: "120px" }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td> */}
                <td  colSpan={2}>
                  <Field
                    label={"Qty"}
                    name={"Hardner_Quantity"}
                    options={{ type: "number", disabled: option === "view" }}
                    warning={warning}
                    //doubleLine={true}
                   // style={{ padding: "0px"}}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                <td  colSpan={2}>
                  <Field
                    label={"Viscosity"}
                    name={"Hardner_Viscosity"}
                    options={{ type: "number", disabled: option === "view" }}
                    warning={warning}
                    //doubleLine={true}
                  //  style={{ padding: "0px" }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
               {/*  <td style={{ width: "10%" }} colSpan={1}>
                  <Field
                    label={"AirPressureLevel"}
                    name={"Hardner_Air_Pressure_Level"}
                    //doubleLine={true}
                    style={{ padding: "0px", width: "150px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    warning={warning}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                <td style={{ width: "10%" }} colSpan={1}>
                  <Field
                    label={"PainterMixerName"}
                    name={"Hardner_Paint_Mixer_Name"}
                    //doubleLine={true}
                    style={{ padding: "0px", width: "150px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    warning={warning}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                <td style={{ width: "10%" }} colSpan={1}>
                  <Field
                    label={"Engg Sign"}
                    name={"Hardner_Engg_Sign"}
                    //doubleLine={true}
                    style={{ padding: "0px", width: "200px" }}
                    options={{
                      type: "reference",
                      reference: "emp_details_dropdown",
                      required: true,
                      disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records?.[0]?.HardnerEng?.[0]?.Emp_code || "",
                      label: Records?.[0]?.HardnerEng?.[0]?.Emp_name || "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "HardnerEng",
                        "Emp_code",
                        "Emp_name"
                      );
                    }}
                  />
                </td> */}
              </tr>
             
              <tr>
                <td  colSpan={2}>
                  <Field
                    label={"Thinner"}
                    name={"Thinner"}
                    options={{ type: "number", disabled: option === "view" }}
                    //  warning={warning}
                    //doubleLine={true}
                  //  style={{ padding: "0px" }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                <td  colSpan={2}>
                  <Field
                    label={"Batch No"}
                    name={"Thinner_Batch_No"}
                    options={{ type: "number", disabled: option === "view" }}
                    warning={warning}
                    //doubleLine={true}
                  //  style={{ padding: "0px" }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                {/* <td style={{ width: "10%" }} colSpan={1}>
                  <Field
                    label={"Starting Time"}
                    name={"Thinner_Starting_Time"}
                    options={{ type: "number", disabled: option === "view" }}
                    warning={warning}
                    //doubleLine={true}
                    style={{ padding: "0px", width: "120px" }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td> */}
                <td  colSpan={2}>
                  <Field
                    label={"Qty"}
                    name={"Thinner_Quantity"}
                    options={{ type: "number", disabled: option === "view" }}
                    warning={warning}
                    //doubleLine={true}
                   // style={{ padding: "0px" }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                <td  colSpan={2}>
                  <Field
                    label={"Viscosity"}
                    name={"Thinner_Viscosity"}
                    options={{ type: "number", disabled: option === "view" }}
                    warning={warning}
                    //doubleLine={true}
                   // style={{ padding: "0px" }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
               {/*  <td style={{ width: "10%" }} colSpan={1}>
                  <Field
                    label={"AirPressureLevel"}
                    name={"Thinner_Air_Pressure_Level"}
                    //doubleLine={true}
                    style={{ padding: "0px", width: "150px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    warning={warning}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                <td style={{ width: "10%" }} colSpan={1}>
                  <Field
                    label={"PainterMixerName"}
                    name={"Thinner_Paint_Mixer_Name"}
                    //doubleLine={true}
                    style={{ padding: "0px", width: "150px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    warning={warning}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                <td style={{ width: "10%" }} colSpan={1}>
                  <Field
                    label={"Engg Sign"}
                    name={"Thinner_Engg_Sign"}
                    //doubleLine={true}
                    style={{ padding: "0px", width: "200px" }}
                    options={{
                      type: "reference",
                      reference: "emp_details_dropdown",
                      required: true,
                      disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records?.[0]?.ThinnerEng?.[0]?.Emp_code || "",
                      label: Records?.[0]?.ThinnerEng?.[0]?.Emp_name || "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "ThinnerEng",
                        "Emp_code",
                        "Emp_name"
                      );
                    }}
                  />
                </td> */}
              </tr>
              <tr>
                 <td  colSpan={2}>
                  <Field
                    label={"Starting Time"}
                    name={"Primer_Starting_Time"}
                    options={{ type: "number", disabled: option === "view" }}
                    warning={warning}
                    //doubleLine={true}
                   // style={{ padding: "0px" }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                <td colSpan={3}>
                  <Field
                    label={"AirPressureLevel"}
                    name={"Thinner_Air_Pressure_Level"}
                    //doubleLine={true}
                 //   style={{ padding: "0px" }}
                    options={{ type: "number", disabled: option === "view" }}
                    warning={warning}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                  </td>
                <td  colSpan={3} >
                  <Field
                    label={"PainterMixer Name"}
                    name={"Primer_Paint_Mixer_Name"}
                    //doubleLine={true}
                 
                    options={{ type: "number", disabled: option === "view" }}
                    warning={warning}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
                
              </tr>
              <tr>
              <td  colSpan={3}>
                  <Field
                    label={"Engg Sign"}
                    name={"Primer_Engg_Sign"}
                    //doubleLine={true}
                  //  style={{ padding: "0px", width: "200px" }}
                    options={{
                      type: "reference",
                      reference: "emp_details_dropdown",
                      required: true,
                      disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records?.[0]?.PrimerEng?.[0]?.Emp_code || "",
                      label: Records?.[0]?.PrimerEng?.[0]?.Emp_name || "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "PrimerEng",
                        "Emp_code",
                        "Emp_name"
                      );
                    }}
                  />
                </td>
               
              <td colSpan={2}>
                  {" "}
                  <Field
                    label={"Created By"}
                    name={"Created_By"}
                    //doubleLine={true}
                    options={{
                      type: "reference",
                      reference: "emp_details_dropdown",
                      required: true,
                      disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records?.[0]?.CreatedBy?.[0]?.Emp_code || "",
                      label: Records?.[0]?.CreatedBy?.[0]?.Emp_name || "",
                    }}
                  //  style={{ padding: "0px" }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "CreatedBy",
                        "Emp_code",
                        "Emp_name"
                      );
                    }}
                  />
                </td>
                <td colSpan={3}>
                  {" "}
                  <Field
                    label={"Approved By"}
                    name={"Approved_By"}
                    //doubleLine={true}
                    options={{
                      type: "reference",
                      reference: "emp_details_dropdown",
                      required: true,
                      disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records?.[0]?.ApprovedBy?.[0]?.Emp_code || "",
                      label: Records?.[0]?.ApprovedBy?.[0]?.Emp_name || "",
                    }}
                  //  style={{ padding: "0px" }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "ApprovedBy",
                        "Emp_code",
                        "Emp_name"
                      );
                    }}
                  />
                </td>
              </tr>

              {!(option === "view") && (
                <tr>
                  {id === undefined ? (
                    <td colSpan={8}>
                      {" "}
                      <Field
                        label={"Submit"}
                        options={{ type: "Button" }}
                        onClick={(e) => {
                          HandleCreate(e);
                        }}
                      />
                    </td>
                  ) : (
                    <td colSpan={8}>
                      {" "}
                      <Field
                        label={"Update"}
                        options={{ type: "Button" }}
                        onClick={(e) => {
                          HandleUpdate(e);
                        }}
                      />
                    </td>
                  )}
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </QaLayoutPaint>
  );
};

export default PMMForm;
