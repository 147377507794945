import React, { useEffect } from "react";
import Field from "../../../../Components/Fields/Field";
import Lable from "../../../../Components/Fields/Lable";
import { useParams } from "react-router-dom";
import { useScrollEffect } from "../../../Quality/useScrollEffect";

const Direction = ({ Records, setRecords, setActiveKey, activeKey }) => {
  useScrollEffect();
  const handleNext = () => {
    console.log("act4545", activeKey);
    setActiveKey(activeKey);
    switch (activeKey) {
      case "first":
        setActiveKey("second");
        break;
      case "second":
        setActiveKey("three");
        break;
      case "three":
        setActiveKey("four");
        break;
      case "four":
        setActiveKey("first");
        break;
      default:
        setActiveKey("first");
    }
  };

  const { option, id } = useParams();
  const ActiveKeys = {
    first: ["direction_function0", "Direction (0 Degree)", "Direction_0"],
    second: ["direction_function90", "Direction (90 Degree)", "Direction_90"],
    three: ["direction_function180", "Direction (180 Degree)", "Direction_180"],
    four: ["direction_function270", "Direction (270 Degree)", "Direction_270"],
  };
  console.log("activeKey", activeKey);

  function HandleDirection(Event, index) {
    console.log("Event", Event.target.value);
    console.log("Index", index);
    console.log("durect", Records);
    setRecords((item) => {
      let record = [...Records];
      console.log("new2324", record);
      if (activeKey === "first") {
        record[0].direction_function0[index] = {
          ...record[0].direction_function0[index],
          [Event.target.name]: Event.target.value,
          // value: Event.target.value,
        };
        // const newRecords = [...prevRecords];
        // if (activeKey === "first") {
        //     newRecords[0].direction_function0[index] = {
        //         ...newRecords[0].direction_function0[index],
        //         [Event.target.name]: Event.target.value,
        //        // value: Event.target.value,
        //     };
      } else if (activeKey === "second") {
        record[0].direction_function90[index] = {
          ...record[0].direction_function90[index],
          value: Event.target.value,
        };
      } else if (activeKey === "three") {
        record[0].direction_function180[index] = {
          ...record[0].direction_function180[index],
          value: Event.target.value,
        };
      } else if (activeKey === "four") {
        record[0].direction_function270[index] = {
          ...record[0].direction_function270[index],
          value: Event.target.value,
        };
      }
      return record;
      // return newRecords;
    });
    // setRecords((item) => {
    //     let record = [...Records];
    //     record[0].direction_function0[index] = {
    //       ...record[0].direction_function0[index],
    //       value: Event.target.value,
    //     };
    //     return record;
    //   });
  }

  // useEffect(()=>{
  // console.log("Records",Records);
  // },[Records])

  return (
    <div>
      <table style={{ width: "100%", textAlign: "center" }}>
        <tr>
          <th colSpan={2}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "90% 10%",
                alignItems: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <Lable
                  value={ActiveKeys?.[activeKey][1]}
                  type={"Head"}
                  style={{ padding: "1px" }}
                />
              </div>
              <div>
                <button
                  className="nextButtonLabel"
                  onClick={() => {
                    handleNext();
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </th>
        </tr>

        {Records?.[0]?.[ActiveKeys?.[activeKey]?.[0]]?.map((item, index) =>
          index % 2 === 0 ? (
            <tr key={index / 2}>
              <td>
                <Field
                  label={
                    Records[0][ActiveKeys[activeKey][0]][index][
                      ActiveKeys[activeKey][2]
                    ]
                  }
                  name={"value"}
                  options={{ type: "number" }}
                  value={Records[0][ActiveKeys[activeKey][0]][index]}
                  OnChange={(e) => {
                    HandleDirection(e, index);
                  }}
                />
              </td>

              {index + 1 < Records[0][ActiveKeys[activeKey][0]].length && (
                <td>
                  <Field
                    label={
                      Records[0][ActiveKeys[activeKey][0]][index + 1][
                        ActiveKeys[activeKey][2]
                      ]
                    }
                    name={"value"}
                    options={{ type: "number" }}
                  
                    value={Records[0][ActiveKeys[activeKey][0]][index + 1]}
                    OnChange={(e) => {
                      HandleDirection(e, index + 1);
                    }}
                    
                  />
                </td>
              )}
            </tr>
          ) : null
        )}
      </table>
    </div>
  );
};

export default Direction;
