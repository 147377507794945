import React from "react";
import QaLayoutPaint from "../../QaLayoutPaint";
import ListViewPaint from "../../../../Components/ListView/ListViewPaint";

const PrimerMasterList = () => {
 
  return (
    <QaLayoutPaint quary={"paint_primer_master"}>
    <div>
      <ListViewPaint
        Title={"Paint Primer Master"}
        standard={["delete_id=0"]}
        quary={"Select Id,primer_code,primer_name,ActiveStatus,CreatedDate from paint_primer_master group by Id  ORDER BY CASE WHEN ActiveStatus = 'Active' THEN 0 ELSE 1 END,Id DESC"}
        table={"paint_primer_master"}
        tableDelete={"paint_primer_master"}
        navigation={"PaintPrimerMaster"}
        filter={["Id","primer_code","primer_name","ActiveStatus"]}
        dateFilter={"CreatedDate"}
        header={[
          { value: "Id", label: "ID" },
          { value: "CreatedDate", label: "Date" },
          { value: "primer_code", label: "Primer Code" },
          { value: "primer_name", label: "Primer Name" },
          { value: "ActiveStatus", label: "Active Status" },
        ]}
      />   
    </div>
    </QaLayoutPaint>
  );
};

export default PrimerMasterList;
