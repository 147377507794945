import React, { useEffect, useState } from "react";
import Popup from "../../../Components/Popup/Popup";
import axios from "axios";
import { API_URL } from "../../../Utils/constants.js";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import Unitechlogo from "../../../icons/Unitechlogo.jpeg";
import DatePicker from "../../../Lib/DatePicker.js";
import { formatDt } from "../../../Lib/formatDt.js";
import "../../../Styles/Part3.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Pagination from "../../../Components/Pagination";
import GetRecord from "../../../Lib/GetRecord";
import Select from "react-select";
import ReactToPrint from "react-to-print";
import { useRef } from "react";
import { GetAccess } from "../../../Lib/GetAccess";
import { useNavigate, useParams } from "react-router-dom";
import { FaBackspace } from 'react-icons/fa';
import reject from "../../../Lib/reject";
import approve from "../../../Lib/approve";
import { Dropdown } from 'react-bootstrap';

function PmsView() {
  const handleDateChange = (newDate) => {
    setTrackDate(newDate);
  };

  const initTracking = {
    Track_15_pm: "",
    Track_19_pm: "",
    Number: "",
    Track_7_am: "",
    Track_11_am: "",
    Track_23_pm: "",
    Track_3_am: "",
    Pms_id: "",
  };
  const initHeat = {
    Heat_inst_used: "",
    Heat_actual_1: "",
    Heat_units: "",
    Heat_desc: "",
    Heat_sl: "",
    Heat_smp_set: "",
    Heat_smp_data: "",
    Heat_actual_6: "",
    Heat_actual_5: "",
    Pms_id: "",
    Heat_actual_4: "",
    Heat_actual_3: "",
    Heat_actual_2: "",
  };
  const initInject = {
    Inject_desc: "",
    Inject_smp_data: "",
    Inject_actual_4: "",
    Inject_actual_3: "",
    Inject_actual_6: "",
    Inject_actual_5: "",
    Inject_actual_2: "",
    Inject_actual_1: "",
    Inject_inst_used: "",
    Inject_smp_set: "",
    Inject_sl: "",
    Inject_units: "",
    Pms_id: "",
  };
  const initMachine = {
    CoreSet: "",
    Cavity: "",
    CavSet: "",
    Core: "",
    Tempcore1: "",
    Tempcore2: "",
    Tempcore3: "",
    Tempcore4: "",
    Tempcore5: "",
    Tempcore6: "",
    Tempcav1: "",
    Tempcav2: "",
    Tempcav3: "",
    Tempcav4: "",
    Tempcav5: "",
    Tempcav6: "",
    RM2: "",
    RM1: "",
    RM4: "",
    RM3: "",
    RM6: "",
    RM5: "",
    PartWeight: "",
    PW1: "",
    PW2: "",
    PW3: "",
    PW4: "",
    PW5: "",
    PW6: "",
    PrtSet: "",
    TrackDate: "",
    Mcno: "",
    mc_name: "",
    part_weight_1: "",
    part_weight_2: "",
    part_weight_set_minus_1: "",
    part_weight_set_minus_2: "",
    part_weight_set_plus_1: "",
    part_weight_set_plus_2: "",
  };
  const initEmpName = {
    ShiftInchargeShiftC: "",
    ShiftInchargeShiftB: "",
    ShiftInchargeShiftA: "",
    LineInspecQaShiftAEmp_name: "",
    LineInspecQaShiftCEmp_name: "",
    LineInspecQaShiftBEmp_name: "",
    ShiftInchargeShiftAEmp_name: "",
    ShiftInchargeShiftBEmp_name: "",
    ShiftInchargeShiftCEmp_name: "",
    LineInspecQaShiftA: "",
    LineInspecQaShiftC: "",
    LineInspecQaShiftB: "",
  };
  const initsmp = {
    OnTme: "",
    CoreSet: "",
    OffSet: "",
    SprBushHle: "",
    ActiveStatus: "",
    Cavity: "",
    Gte2: "",
    Gte3: "",
    Gte6: "",
    Gte4: "",
    Gte5: "",
    Close202: "",
    Close203: "",
    CreatedBy: "",
    Close201: "",
    ReFillSpdSet: "",
    McNo: "",
    NozzHole: "",
    NoCav: "",
    ModifiedDate: "",
    CoolTmeSet: "",
    ManiFldSet1: "",
    ManiFldSet2: "",
    SuckBack: "",
    Close301: "",
    Close302: "",
    Close303: "",
    Transfer_id: "",
    MldSfy102: "",
    CavSet: "",
    ManiFldSet3: "",
    ManiFldSet4: "",
    ManiFldSet5: "",
    RefillEnd: "",
    MldSfy101: "",
    MoldSafeT: "",
    CusSet: "",
    Hozz: "",
    GrdNme: "",
    Remarks: "",
    MldSfy201: "",
    MldSfy202: "",
    Gate1: "",
    MtcSet: "",
    Gate2: "",
    PrtWgt: "",
    Gate9: "",
    CreatedDate: "",
    ClseSpdSet: "",
    Gate7: "",
    Gate8: "",
    Gate5: "",
    Gate6: "",
    Gate3: "",
    Gate4: "",
    Inj104: "",
    InjTmeSet: "",
    Zone1: "",
    Zone2: "",
    Zone3: "",
    Zone4: "",
    Zone5: "",
    Zone6: "",
    RetSpd: "",
    Inj101: "",
    Inj102: "",
    Inj103: "",
    Open201: "",
    Open203: "",
    Transfer_status: "",
    Open202: "",
    MldSfy301: "",
    Transfer_dt: "",
    MldSfy302: "",
    InjSpdSet: "",
    Hold203: "",
    Hold204: "",
    Hold201: "",
    Hold202: "",
    Hold304: "",
    Hold302: "",
    Hold303: "",
    Hold301: "",
    Hold101: "",
    Hold104: "",
    Hold102: "",
    Hold103: "",
    Hold403: "",
    Hold404: "",
    Hold401: "",
    Hold402: "",
    Cooltme: "",
    Gate12: "",
    Gate11: "",
    Gate10: "",
    Inj203: "",
    Inj204: "",
    EjecRet104: "",
    ManFld5: "",
    EjecRet103: "",
    ManFld4: "",
    EjecRet102: "",
    EjecRet101: "",
    EjecFrd104: "",
    ModifiedBy: "",
    Bp102: "",
    Bp101: "",
    Bp104: "",
    Bp103: "",
    Id: "",
    ManFld1: "",
    ManFld3: "",
    ManFld2: "",
    Cre1: "",
    Cre2: "",
    ZneSet1: "",
    Cre3: "",
    Inj201: "",
    Cre4: "",
    Inj202: "",
    FillT104: "",
    Open303: "",
    ReFillTmeSet: "",
    Open302: "",
    Open301: "",
    Oilt: "",
    Switch: "",
    EjecFrd101: "",
    EjecFrd102: "",
    Core401: "",
    EjecFrd103: "",
    Core402: "",
    ReFill302: "",
    Core403: "",
    ReFill301: "",
    SukSet: "",
    Inj302: "",
    Inj303: "",
    Inj304: "",
    Runn: "",
    Hopp: "",
    CycTmeSet: "",
    ReFill304: "",
    ReFill303: "",
    EjecFrd203: "",
    EjecFrd204: "",
    Colour: "",
    Bp201: "",
    Bp203: "",
    Bp202: "",
    Cycletme: "",
    InjPresSet: "",
    Bp204: "",
    ClsePresSet: "",
    Inj301: "",
    Open403: "",
    Open402: "",
    Open401: "",
    ReFillPresSet: "",
    OnSet: "",
    PrtSet: "",
    Melt101: "",
    Melt102: "",
    Melt104: "",
    Melt104: "",
    Core301: "",
    EjecFrd201: "",
    Core302: "",
    EjecFrd202: "",
    Core303: "",
    ReFill203: "",
    ReFill202: "",
    ReFill201: "",
    MTC: "",
    Inj401: "",
    Inj402: "",
    EjcPresSet: "",
    Inj403: "",
    Inj404: "",
    ReFill204: "",
    Fwd: "",
    TrackDate: "",
    PrtNme: "",
    OilSet: "",
    Core: "",
    FormetNo: "",
    EjcSpdSet: "",
    Core201: "",
    Core202: "",
    Core203: "",
    ReFill104: "",
    ReFill103: "",
    ReFillPosSet: "",
    ReFill102: "",
    ReFill101: "",
    Actual1: "",
    NozzSet: "",
    EjecRet203: "",
    EjecRet202: "",
    EjecRet201: "",
    EjecRet204: "",
    Dely: "",
    MatNme: "",
    Close103: "",
    Close101: "",
    Close102: "",
    Modl: "",
    HoppSet: "",
    Core101: "",
    GteSet: "",
    Core102: "",
    Core103: "",
    InjPosSet: "",
    ZneSet2: "",
    ZneSet3: "",
    PartWeight1: "",
    PartWeight2: "",
    PartWeightMinus1: "",
    PartWeightMinus2: "",
    PartWeightPlus1: "",
    PartWeightPlus2: "",
    part_weight_1: "",
    part_weight_2: "",
    part_weight_set_minus_1: "",
    part_weight_set_minus_2: "",
    part_weight_set_plus_1: "",
    part_weight_set_plus_2: "",
  };
  const Heatvalue = [
    { key: "Mtc", value: "MtcSet" },
    { key: "Hopp", value: "HoppSet" },
    { key: "Nozz", value: "NozzSet" },
    { key: "Zone1", value: "ZneSet1" },
    { key: "Zone2", value: "ZneSet2" },
    { key: "Zone3", value: "ZneSet3" },
    { key: "Zone4", value: "ZneSet4" },
    { key: "Zone5", value: "ZneSet5" },
    { key: "Zone6", value: "ZneSet6" },
  ];
  const injectvalue = [
    { key: "Cycletme", value: "CycTmeSet" },
    { key: "Cooltme", value: "CoolTmeSet" },
    { key: "Inj104", value: "InjTmeSet" },
    { key: "Inj204", value: "InjTmeSet" },
    { key: "Inj304", value: "InjTmeSet" },
    { key: "Inj404", value: "InjTmeSet" },
    { key: "Inj101", value: "InjPresSet" },
    { key: "Inj201", value: "InjPresSet" },
    { key: "Inj301", value: "InjPresSet" },
    { key: "Inj401", value: "InjPresSet" },
    { key: "Inj102", value: "InjSpdSet" },
    { key: "Inj202", value: "InjSpdSet" },
    { key: "Inj302", value: "InjSpdSet" },
    { key: "Inj402", value: "InjSpdSet" },
    { key: "Hold101", value: "InjPresSet" },
    { key: "Hold102", value: "InjPresSet" },
    { key: "Hold103", value: "InjPresSet" },
    { key: "Hold104", value: "InjPresSet" },
    { key: "Hold201", value: "InjSpdSet" },
    { key: "Hold202", value: "InjSpdSet" },
    { key: "Hold203", value: "InjSpdSet" },
    { key: "Hold204", value: "InjSpdSet" },
    { key: "Hold401", value: "InjTmeSet" },
    { key: "Hold402", value: "InjTmeSet" },
    { key: "Hold403", value: "InjTmeSet" },
    { key: "Hold404", value: "InjTmeSet" },
    { key: "RefillEnd", value: "ReFillPosSet" },
    { key: "Melt104", value: "CusSet" },
  ];
  const [Tracking, setTracking, getTracking] = useState([
    initTracking,
    initTracking,
    initTracking,
    initTracking,
    initTracking,
  ]);
  const [Heat, setHeat] = useState([
    initHeat,
    initHeat,
    initHeat,
    initHeat,
    initHeat,
    initHeat,
    initHeat,
    initHeat,
    initHeat,
  ]);
  const [Inject, setInject] = useState([
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
    initInject,
  ]);
  const [downloadEnabled, setDownloadEnabled] = useState(true);
  const [Machine, setMachine] = useState([initMachine]);
  const [Part, setPart] = useState([{}]);
  const [Disable, setDisable] = useState({});
  const [submit, setsubmit] = useState();
  const [New, setNew] = useState();
  const [NewInsert, setNewInsert] = useState();
  const [Insert, setInsert] = useState(["", "", ""]);

  const [FormetNo, setFormetNo] = useState("");
  const [Id, setId] = useState("");
  const [TrackDate, setTrackDate] = useState("");
  const [Mcno, setMcno] = useState("");
  const [partWeight, setpartWeight] = useState("");

  // Set Column Values (read only)
  const [MTC, setMTC] = useState("");
  const [Hopp, setHopp] = useState("");
  const [Hozz, setHozz] = useState("");
  const [Zone1, setZone1] = useState("");
  const [Zone2, setZone2] = useState("");
  const [Zone3, setZone3] = useState("");
  const [Zone4, setZone4] = useState("");

  const [NA, setNA] = useState("");
  const [Cycletme, setCycletme] = useState("");
  const [Cooltme, setCooltme] = useState("");
  const [Inj104, setInj104] = useState("");
  const [Inj101, setInj101] = useState("");
  const [Inj201, setInj201] = useState("");
  const [Inj301, setInj301] = useState("");
  const [Inj102, setInj102] = useState("");
  const [Inj202, setInj202] = useState("");
  const [Inj302, setInj302] = useState("");
  const [Hold101, setHold101] = useState("");
  const [Hold201, setHold201] = useState("");
  const [Hold401, setHold401] = useState("");
  const [RefillEnd, setRefillEnd] = useState("");
  const [Melt101, setMelt101] = useState("");

  const [AUT01, setAUT01] = useState("");
  const [AUT02, setAUT02] = useState("");
  const [AUT03, setAUT03] = useState("");
  const [AUT04, setAUT04] = useState("");
  const [AUT05, setAUT05] = useState("");
  const [AUT06, setAUT06] = useState("");

  const [PartName1, setPartName1] = useState("");
  const [PartName2, setPartName2] = useState("");
  const [PartName3, setPartName3] = useState("");
  const [PartName4, setPartName4] = useState("");
  const [PartName5, setPartName5] = useState("");
  const [PartName6, setPartName6] = useState("");

  const [Colour1, setColour1] = useState("");
  const [Colour2, setColour2] = useState("");
  const [Colour3, setColour3] = useState("");
  const [Colour4, setColour4] = useState("");
  const [Colour5, setColour5] = useState("");
  const [Colour6, setColour6] = useState("");

  const [MaterialUsed1, setMaterialUsed1] = useState("");
  const [MaterialUsed2, setMaterialUsed2] = useState("");
  const [MaterialUsed3, setMaterialUsed3] = useState("");
  const [MaterialUsed4, setMaterialUsed4] = useState("");
  const [MaterialUsed5, setMaterialUsed5] = useState("");
  const [MaterialUsed6, setMaterialUsed6] = useState("");

  const [MaterialGrade1, setMaterialGrade1] = useState("");
  const [MaterialGrade2, setMaterialGrade2] = useState("");
  const [MaterialGrade3, setMaterialGrade3] = useState("");
  const [MaterialGrade4, setMaterialGrade4] = useState("");
  const [MaterialGrade5, setMaterialGrade5] = useState("");
  const [MaterialGrade6, setMaterialGrade6] = useState("");

  const [RM1, setRM1] = useState("");
  const [RM2, setRM2] = useState("");
  const [RM3, setRM3] = useState("");
  const [RM4, setRM4] = useState("");
  const [RM5, setRM5] = useState("");
  const [RM6, setRM6] = useState("");

  const [PW1, setPW1] = useState("");
  const [PW2, setPW2] = useState("");
  const [PW3, setPW3] = useState("");
  const [PW4, setPW4] = useState("");
  const [PW5, setPW5] = useState("");
  const [PW6, setPW6] = useState("");
  const [PW11, setPW11] = useState("");
  const [PW21, setPW21] = useState("");
  const [PW31, setPW31] = useState("");
  const [PW41, setPW41] = useState("");
  const [PW51, setPW51] = useState("");
  const [PW61, setPW61] = useState("");

  const [A101, setA101] = useState("");
  const [A201, setA201] = useState("");
  const [A301, setA301] = useState("");
  const [A401, setA401] = useState("");
  const [A501, setA501] = useState("");
  const [A601, setA601] = useState("");
  const [A102, setA102] = useState("");
  const [A202, setA202] = useState("");
  const [A302, setA302] = useState("");
  const [A402, setA402] = useState("");
  const [A502, setA502] = useState("");
  const [A602, setA602] = useState("");
  const [A103, setA103] = useState("");
  const [A203, setA203] = useState("");
  const [A303, setA303] = useState("");
  const [A403, setA403] = useState("");
  const [A503, setA503] = useState("");
  const [A603, setA603] = useState("");
  const [A104, setA104] = useState("");
  const [A204, setA204] = useState("");
  const [A304, setA304] = useState("");
  const [A404, setA404] = useState("");
  const [A504, setA504] = useState("");
  const [A604, setA604] = useState("");
  const [A105, setA105] = useState("");
  const [A205, setA205] = useState("");
  const [A305, setA305] = useState("");
  const [A405, setA405] = useState("");
  const [A505, setA505] = useState("");
  const [A605, setA605] = useState("");
  const [A106, setA106] = useState("");
  const [A206, setA206] = useState("");
  const [A306, setA306] = useState("");
  const [A406, setA406] = useState("");
  const [A506, setA506] = useState("");
  const [A606, setA606] = useState("");
  const [A113, setA113] = useState("");
  const [A213, setA213] = useState("");
  const [A313, setA313] = useState("");
  const [A413, setA413] = useState("");
  const [A513, setA513] = useState("");
  const [A613, setA613] = useState("");

  const [cycle1, setcycle1] = useState("");
  const [cycle2, setcycle2] = useState("");
  const [cycle3, setcycle3] = useState("");
  const [cycle4, setcycle4] = useState("");
  const [cycle5, setcycle5] = useState("");
  const [cycle6, setcycle6] = useState("");

  const [cool1, setcool1] = useState("");
  const [cool2, setcool2] = useState("");
  const [cool3, setcool3] = useState("");
  const [cool4, setcool4] = useState("");
  const [cool5, setcool5] = useState("");
  const [cool6, setcool6] = useState("");

  const [injectt1, setinjectt1] = useState("");
  const [injectt2, setinjectt2] = useState("");
  const [injectt3, setinjectt3] = useState("");
  const [injectt4, setinjectt4] = useState("");
  const [injectt5, setinjectt5] = useState("");
  const [injectt6, setinjectt6] = useState("");

  const [injectp101, setinjectp101] = useState("");
  const [injectp102, setinjectp102] = useState("");
  const [injectp103, setinjectp103] = useState("");
  const [injectp104, setinjectp104] = useState("");
  const [injectp105, setinjectp105] = useState("");
  const [injectp106, setinjectp106] = useState("");
  const [injectp201, setinjectp201] = useState("");
  const [injectp202, setinjectp202] = useState("");
  const [injectp203, setinjectp203] = useState("");
  const [injectp204, setinjectp204] = useState("");
  const [injectp205, setinjectp205] = useState("");
  const [injectp206, setinjectp206] = useState("");
  const [injectp301, setinjectp301] = useState("");
  const [injectp302, setinjectp302] = useState("");
  const [injectp303, setinjectp303] = useState("");
  const [injectp304, setinjectp304] = useState("");
  const [injectp305, setinjectp305] = useState("");
  const [injectp306, setinjectp306] = useState("");
  const [injects101, setinjects101] = useState("");
  const [injects102, setinjects102] = useState("");
  const [injects103, setinjects103] = useState("");
  const [injects104, setinjects104] = useState("");
  const [injects105, setinjects105] = useState("");
  const [injects106, setinjects106] = useState("");
  const [injects201, setinjects201] = useState("");
  const [injects202, setinjects202] = useState("");
  const [injects203, setinjects203] = useState("");
  const [injects204, setinjects204] = useState("");
  const [injects205, setinjects205] = useState("");
  const [injects206, setinjects206] = useState("");
  const [injects301, setinjects301] = useState("");
  const [injects302, setinjects302] = useState("");
  const [injects303, setinjects303] = useState("");
  const [injects304, setinjects304] = useState("");
  const [injects305, setinjects305] = useState("");
  const [injects306, setinjects306] = useState("");

  const [hp1, sethp1] = useState("");
  const [hp2, sethp2] = useState("");
  const [hp3, sethp3] = useState("");
  const [hp4, sethp4] = useState("");
  const [hp5, sethp5] = useState("");
  const [hp6, sethp6] = useState("");
  const [hs1, seths1] = useState("");
  const [hs2, seths2] = useState("");
  const [hs3, seths3] = useState("");
  const [hs4, seths4] = useState("");
  const [hs5, seths5] = useState("");
  const [hs6, seths6] = useState("");
  const [ht1, setht1] = useState("");
  const [ht2, setht2] = useState("");
  const [ht3, setht3] = useState("");
  const [ht4, setht4] = useState("");
  const [ht5, setht5] = useState("");
  const [ht6, setht6] = useState("");

  const [shsi1, setshsi1] = useState("");
  const [shsi2, setshsi2] = useState("");
  const [shsi3, setshsi3] = useState("");
  const [shsi4, setshsi4] = useState("");
  const [shsi5, setshsi5] = useState("");
  const [shsi6, setshsi6] = useState("");

  const [Cushion1, setCushion1] = useState("");
  const [Cushion2, setCushion2] = useState("");
  const [Cushion3, setCushion3] = useState("");
  const [Cushion4, setCushion4] = useState("");
  const [Cushion5, setCushion5] = useState("");
  const [Cushion6, setCushion6] = useState("");
  const [tempcav1, settempcav1] = useState("");
  const [tempcav2, settempcav2] = useState("");
  const [tempcav3, settempcav3] = useState("");

  const [tempcore1, settempcore1] = useState("");
  const [tempcore2, settempcore2] = useState("");
  const [tempcore3, settempcore3] = useState("");
  const [tempcore4, settempcore4] = useState("");
  const [tempcore5, settempcore5] = useState("");
  const [tempcore6, settempcore6] = useState("");

  const [tempcav4, settempcav4] = useState("");
  const [tempcav5, settempcav5] = useState("");
  const [tempcav6, settempcav6] = useState("");

  const [MtcSet, setMtcSet] = useState("");
  const [HoppSet, setHoppSet] = useState("");
  const [NozzSet, setNozzSet] = useState("");
  const [ZneSet1, setZneSet1] = useState("");
  const [ZneSet2, setZneSet2] = useState("");
  const [ZneSet3, setZneSet3] = useState("");
  const [CycTmeSet, setCycTmeSet] = useState("");
  const [CoolTmeSet, setCoolTmeSet] = useState("");
  const [InjTmeSet, setInjTmeSet] = useState("");
  const [InjPresSet, setInjPresSet] = useState("");
  const [InjSpdSet, setInjSpdSet] = useState("");
  const [ReFillPosSet, setReFillPosSet] = useState("");
  const [CusSet, setCusSet] = useState("");
  const [Melt104, setMelt104] = useState("");
  const [CoreSet, setCoreSet] = useState("");
  const [Core, setCore] = useState("");
  const [Cavity, setCavity] = useState("");
  const [CavSet, setCavSet] = useState("");
  const [VeriPer, setVeriPer] = useState("");
  const [PrtSet, setPrtSet] = useState("");
  const [PartWeight1, setPartWeight1] = useState("");
  const [PartWeight2, setPartWeight2] = useState("");
  const [PartWeightPlus1, setPartWeightPlus1] = useState("");
  const [PartWeightPlus2, setPartWeightPlus2] = useState("");
  const [PartWeightMinus1, setPartWeightMinus1] = useState("");
  const [PartWeightMinus2, setPartWeightMinus2] = useState("");
  const [viewMode, setviewMode] = useState(false);

  // const [viewMode, setviewMode] = useState(false);
  //   const [data, setData] = useState([]);
  const [pro_mon_sys, setpro_mon_sys] = useState([]);
  const [ButtonPopup1, setButtonPopup1] = useState(false);
  const [ButtonPopup, setButtonPopup] = useState(false);
  const [isPaginationVisible, setPaginationVisible] = useState(true);
  const [partDropdown, setPartDropdown] = useState([]);
  const [values, setValues] = useState([]);
  const [dropdown, setDropdown] = useState([]);
  const [MappedValues, setMappedValues] = useState({});
  const [EmpName, setEmpName] = useState(initEmpName);
  const [emp, setemp] = useState([{}]);
  const [Smp, setSmp] = useState([initsmp]);
  const [empDetails, setempDetails] = useState([{}]);

  const [PMS, setPMS] = useState([{}]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}-${year}`;
  };

  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 20;
  const [filterDate, setFilterDate] = useState("");
  const [filterMcno, setFilterMcno] = useState("");
  const filterRecords = () => {
    const filteredData = pro_mon_sys.filter((record) => {
      const dateMatch =
        record.TrackDate && record.TrackDate.includes(filterDate);
      const mcnoMatch =
        record.Mcno &&
        record.Mcno.toLowerCase().includes(filterMcno.toLowerCase());
      return dateMatch && mcnoMatch;
    });

    return filteredData;
  };

  const filteredData = filterRecords();
  const npage = Math.ceil(filteredData.length / recordPerPage);
  const numbers = [...Array(npage).keys()].map((num) => num + 1);

  function handleFilterDateChange(event) {
    setFilterDate(event.target.value);
    setCurrentPage(1);
  }

  function handleFilterMcnoChange(event) {
    setFilterMcno(event.target.value);
    setCurrentPage(1);
  }

  const firstIndex = (currentPage - 1) * recordPerPage;
  const lastIndex = firstIndex + recordPerPage;
  const filteredRecords = filteredData.slice(firstIndex, lastIndex);

  //   const filterRecords = () => {
  //   const filteredData = pro_mon_sys.filter((record) => {
  //     const dateMatch = record.TrackDate && record.TrackDate.includes(filterDate);
  //     const mcnoMatch = record.Mcno && record.Mcno.toLowerCase().includes(filterMcno.toLowerCase());
  //     return dateMatch && mcnoMatch;
  //     });
  //     return filteredData;
  //   };

  //   const npage = Math.ceil(filterRecords().length / recordPerPage);
  //  // const numbers = [...Array(Math.min(npage, 5)).keys()].map((num) => num + 1);
  //    const numbers = [...Array(npage).keys()].map((num) => num + 1);

  //   const filteredRecords = filterRecords().slice(firstIndex, lastIndex);

  function prePage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function nextPage() {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function changeCpage(Id) {
    setCurrentPage(Id);
  }
  const handleDownload = () => {
    window.print();
    // alert('Download started successfully.');
  };

  function formatTimeDigits(value) {
    return value.toString().padStart(2, "0");
  }

  const getPartValues = (number, partCode) => {
    if (partCode !== "" && Mcno !== "") {
      axios
        .post(`http://${API_URL}/GenericResultBuilderService/buildResults`, {
          _keyword_: "'std_mld_params_by_part",
          mc_part_code: partCode,
          McNo: Mcno,
        })
        .then((response) => {
          if (response.data.data.length > 0) {
            if (number == "1") {
              setColour1(response.data.data[0].Colour);
              setMaterialGrade1(response.data.data[0].GrdNme);
              setMaterialUsed1(response.data.data[0].MatNme);
              setpartWeight(response.data.data[0].PrtWgt);
              setMTC(response.data.data[0].MTC);
              setHopp(response.data.data[0].Hopp);
              setHozz(response.data.data[0].Hozz);
              setZone1(response.data.data[0].Zone1);
              setZone2(response.data.data[0].Zone2);
              setZone3(response.data.data[0].Zone3);
              setCycletme(response.data.data[0].Cycletme);
              setCooltme(response.data.data[0].Cooltme);
              setInj104(response.data.data[0].Inj104);
              setInj101(response.data.data[0].Inj101);
              setInj201(response.data.data[0].Inj201);
              setInj301(response.data.data[0].Inj301);
              setInj102(response.data.data[0].Inj102);
              setInj202(response.data.data[0].Inj202);
              setInj302(response.data.data[0].Inj302);
              setHold101(response.data.data[0].Hold101);
              setHold201(response.data.data[0].Hold201);
              setHold401(response.data.data[0].Hold401);
              setRefillEnd(response.data.data[0].RefillEnd);
              setMelt101(response.data.data[0].Melt101);
              setPrtSet(response.data.data[0].PrtSet);
              setMtcSet(response.data.data[0].MtcSet);
              setHoppSet(response.data.data[0].HoppSet);
              setNozzSet(response.data.data[0].NozzSet);
              setZneSet1(response.data.data[0].ZneSet1);
              setZneSet2(response.data.data[0].ZneSet2);
              setZneSet3(response.data.data[0].ZneSet3);
              setCycTmeSet(response.data.data[0].CycTmeSet);
              setCoolTmeSet(response.data.data[0].CoolTmeSet);
              setInjTmeSet(response.data.data[0].InjTmeSet);
              setInjPresSet(response.data.data[0].InjPresSet);
              setInjSpdSet(response.data.data[0].InjSpdSet);
              setReFillPosSet(response.data.data[0].ReFillPosSet);

              setCusSet(response.data.data[0].CusSet);
              setMelt104(response.data.data[0].Melt104);
              setCoreSet(response.data.data[0].CoreSet);
              setCore(response.data.data[0].Core);
              setCavity(response.data.data[0].Cavity);
              setCavSet(response.data.data[0].CavSet);
              setPartWeight1(response.data.data[0].part_weight_1);
              setPartWeight2(response.data.data[0].part_weight_2);
              setPartWeightPlus1(response.data.data[0].part_weight_set_plus_1);
              setPartWeightPlus2(response.data.data[0].part_weight_set_plus_2);
              setPartWeightMinus1(response.data.data[0].part_weight_set_minus_1);
              setPartWeightMinus2(response.data.data[0].part_weight_set_minus_2);

              let now = new Date();
              setAUT01(now.getHours() + ":" + now.getMinutes());
            } else if (number == "2") {
              setColour2(response.data.data[0].Colour);
              setMaterialGrade2(response.data.data[0].GrdNme);
              setMaterialUsed2(response.data.data[0].MatNme);
              setpartWeight(response.data.data[0].PrtWgt);
              setMTC(response.data.data[0].MTC);
              setHopp(response.data.data[0].Hopp);
              setHozz(response.data.data[0].Hozz);
              setZone1(response.data.data[0].Zone1);
              setZone2(response.data.data[0].Zone2);
              setZone3(response.data.data[0].Zone3);
              setCycletme(response.data.data[0].Cycletme);
              setCooltme(response.data.data[0].Cooltme);
              setInj104(response.data.data[0].Inj104);
              setInj101(response.data.data[0].Inj101);
              setInj201(response.data.data[0].Inj201);
              setInj301(response.data.data[0].Inj301);
              setInj102(response.data.data[0].Inj102);
              setInj202(response.data.data[0].Inj202);
              setInj302(response.data.data[0].Inj302);
              setHold101(response.data.data[0].Hold101);
              setHold201(response.data.data[0].Hold201);
              setHold401(response.data.data[0].Hold401);
              setRefillEnd(response.data.data[0].RefillEnd);
              setMelt101(response.data.data[0].Melt101);
              setPrtSet(response.data.data[0].PrtSet);
              setMtcSet(response.data.data[0].MtcSet);
              setHoppSet(response.data.data[0].HoppSet);
              setNozzSet(response.data.data[0].NozzSet);
              setZneSet1(response.data.data[0].ZneSet1);
              setZneSet2(response.data.data[0].ZneSet2);
              setZneSet3(response.data.data[0].ZneSet3);
              setCycTmeSet(response.data.data[0].CycTmeSet);
              setCoolTmeSet(response.data.data[0].CoolTmeSet);
              setInjTmeSet(response.data.data[0].InjTmeSet);
              setInjPresSet(response.data.data[0].InjPresSet);
              setInjSpdSet(response.data.data[0].InjSpdSet);
              setReFillPosSet(response.data.data[0].ReFillPosSet);

              setCusSet(response.data.data[0].CusSet);
              setMelt104(response.data.data[0].Melt104);
              setCoreSet(response.data.data[0].CoreSet);
              setCore(response.data.data[0].Core);
              setCavity(response.data.data[0].Cavity);
              setCavSet(response.data.data[0].CavSet);
              setPartWeight1(response.data.data[0].part_weight_1);
            setPartWeight2(response.data.data[0].part_weight_2);
            setPartWeightPlus1(response.data.data[0].part_weight_set_plus_1);
            setPartWeightPlus2(response.data.data[0].part_weight_set_plus_2);
            setPartWeightMinus1(response.data.data[0].part_weight_set_minus_1);
            setPartWeightMinus2(response.data.data[0].part_weight_set_minus_2);

              let now = new Date();
              setAUT02(now.getHours() + ":" + now.getMinutes());
            } else if (number == "3") {
              setColour3(response.data.data[0].Colour);
              setMaterialGrade3(response.data.data[0].GrdNme);
              setMaterialUsed3(response.data.data[0].MatNme);
              setpartWeight(response.data.data[0].PrtWgt);
              setMTC(response.data.data[0].MTC);
              setHopp(response.data.data[0].Hopp);
              setHozz(response.data.data[0].Hozz);
              setZone1(response.data.data[0].Zone1);
              setZone2(response.data.data[0].Zone2);
              setZone3(response.data.data[0].Zone3);
              setCycletme(response.data.data[0].Cycletme);
              setCooltme(response.data.data[0].Cooltme);
              setInj104(response.data.data[0].Inj104);
              setInj101(response.data.data[0].Inj101);
              setInj201(response.data.data[0].Inj201);
              setInj301(response.data.data[0].Inj301);
              setInj102(response.data.data[0].Inj102);
              setInj202(response.data.data[0].Inj202);
              setInj302(response.data.data[0].Inj302);
              setHold101(response.data.data[0].Hold101);
              setHold201(response.data.data[0].Hold201);
              setHold401(response.data.data[0].Hold401);
              setRefillEnd(response.data.data[0].RefillEnd);
              setMelt101(response.data.data[0].Melt101);
              setPrtSet(response.data.data[0].PrtSet);
              setMtcSet(response.data.data[0].MtcSet);
              setHoppSet(response.data.data[0].HoppSet);
              setNozzSet(response.data.data[0].NozzSet);
              setZneSet1(response.data.data[0].ZneSet1);
              setZneSet2(response.data.data[0].ZneSet2);
              setZneSet3(response.data.data[0].ZneSet3);
              setCycTmeSet(response.data.data[0].CycTmeSet);
              setCoolTmeSet(response.data.data[0].CoolTmeSet);
              setInjTmeSet(response.data.data[0].InjTmeSet);
              setInjPresSet(response.data.data[0].InjPresSet);
              setInjSpdSet(response.data.data[0].InjSpdSet);
              setReFillPosSet(response.data.data[0].ReFillPosSet);

              setCusSet(response.data.data[0].CusSet);
              setMelt104(response.data.data[0].Melt104);
              setCoreSet(response.data.data[0].CoreSet);
              setCore(response.data.data[0].Core);
              setCavity(response.data.data[0].Cavity);
              setCavSet(response.data.data[0].CavSet);
              setPartWeight1(response.data.data[0].part_weight_1);
            setPartWeight2(response.data.data[0].part_weight_2);
            setPartWeightPlus1(response.data.data[0].part_weight_set_plus_1);
            setPartWeightPlus2(response.data.data[0].part_weight_set_plus_2);
            setPartWeightMinus1(response.data.data[0].part_weight_set_minus_1);
            setPartWeightMinus2(response.data.data[0].part_weight_set_minus_2);

              let now = new Date();
              setAUT03(now.getHours() + ":" + now.getMinutes());
            } else if (number == "4") {
              setColour4(response.data.data[0].Colour);
              setMaterialGrade4(response.data.data[0].GrdNme);
              setMaterialUsed4(response.data.data[0].MatNme);
              setpartWeight(response.data.data[0].PrtWgt);
              setMTC(response.data.data[0].MTC);
              setHopp(response.data.data[0].Hopp);
              setHozz(response.data.data[0].Hozz);
              setZone1(response.data.data[0].Zone1);
              setZone2(response.data.data[0].Zone2);
              setZone3(response.data.data[0].Zone3);
              setCycletme(response.data.data[0].Cycletme);
              setCooltme(response.data.data[0].Cooltme);
              setInj104(response.data.data[0].Inj104);
              setInj101(response.data.data[0].Inj101);
              setInj201(response.data.data[0].Inj201);
              setInj301(response.data.data[0].Inj301);
              setInj102(response.data.data[0].Inj102);
              setInj202(response.data.data[0].Inj202);
              setInj302(response.data.data[0].Inj302);
              setHold101(response.data.data[0].Hold101);
              setHold201(response.data.data[0].Hold201);
              setHold401(response.data.data[0].Hold401);
              setRefillEnd(response.data.data[0].RefillEnd);
              setMelt101(response.data.data[0].Melt101);
              setPrtSet(response.data.data[0].PrtSet);
              setMtcSet(response.data.data[0].MtcSet);
              setHoppSet(response.data.data[0].HoppSet);
              setNozzSet(response.data.data[0].NozzSet);
              setZneSet1(response.data.data[0].ZneSet1);
              setZneSet2(response.data.data[0].ZneSet2);
              setZneSet3(response.data.data[0].ZneSet3);
              setCycTmeSet(response.data.data[0].CycTmeSet);
              setCoolTmeSet(response.data.data[0].CoolTmeSet);
              setInjTmeSet(response.data.data[0].InjTmeSet);
              setInjPresSet(response.data.data[0].InjPresSet);
              setInjSpdSet(response.data.data[0].InjSpdSet);
              setReFillPosSet(response.data.data[0].ReFillPosSet);

              setCusSet(response.data.data[0].CusSet);
              setMelt104(response.data.data[0].Melt104);
              setCoreSet(response.data.data[0].CoreSet);
              setCore(response.data.data[0].Core);
              setCavity(response.data.data[0].Cavity);
              setCavSet(response.data.data[0].CavSet);
              setPartWeight1(response.data.data[0].part_weight_1);
            setPartWeight2(response.data.data[0].part_weight_2);
            setPartWeightPlus1(response.data.data[0].part_weight_set_plus_1);
            setPartWeightPlus2(response.data.data[0].part_weight_set_plus_2);
            setPartWeightMinus1(response.data.data[0].part_weight_set_minus_1);
            setPartWeightMinus2(response.data.data[0].part_weight_set_minus_2);

              let now = new Date();
              setAUT04(now.getHours() + ":" + now.getMinutes());
            } else if (number == "5") {
              setColour5(response.data.data[0].Colour);
              setMaterialGrade5(response.data.data[0].GrdNme);
              setMaterialUsed5(response.data.data[0].MatNme);
              setpartWeight(response.data.data[0].PrtWgt);
              setMTC(response.data.data[0].MTC);
              setHopp(response.data.data[0].Hopp);
              setHozz(response.data.data[0].Hozz);
              setZone1(response.data.data[0].Zone1);
              setZone2(response.data.data[0].Zone2);
              setZone3(response.data.data[0].Zone3);
              setCycletme(response.data.data[0].Cycletme);
              setCooltme(response.data.data[0].Cooltme);
              setInj104(response.data.data[0].Inj104);
              setInj101(response.data.data[0].Inj101);
              setInj201(response.data.data[0].Inj201);
              setInj301(response.data.data[0].Inj301);
              setInj102(response.data.data[0].Inj102);
              setInj202(response.data.data[0].Inj202);
              setInj302(response.data.data[0].Inj302);
              setHold101(response.data.data[0].Hold101);
              setHold201(response.data.data[0].Hold201);
              setHold401(response.data.data[0].Hold401);
              setRefillEnd(response.data.data[0].RefillEnd);
              setMelt101(response.data.data[0].Melt101);
              setPrtSet(response.data.data[0].PrtSet);
              setMtcSet(response.data.data[0].MtcSet);
              setHoppSet(response.data.data[0].HoppSet);
              setNozzSet(response.data.data[0].NozzSet);
              setZneSet1(response.data.data[0].ZneSet1);
              setZneSet2(response.data.data[0].ZneSet2);
              setZneSet3(response.data.data[0].ZneSet3);
              setCycTmeSet(response.data.data[0].CycTmeSet);
              setCoolTmeSet(response.data.data[0].CoolTmeSet);
              setInjTmeSet(response.data.data[0].InjTmeSet);
              setInjPresSet(response.data.data[0].InjPresSet);
              setInjSpdSet(response.data.data[0].InjSpdSet);
              setReFillPosSet(response.data.data[0].ReFillPosSet);

              setCusSet(response.data.data[0].CusSet);
              setMelt104(response.data.data[0].Melt104);
              setCoreSet(response.data.data[0].CoreSet);
              setCore(response.data.data[0].Core);
              setCavity(response.data.data[0].Cavity);
              setCavSet(response.data.data[0].CavSet);
              setPartWeight1(response.data.data[0].part_weight_1);
            setPartWeight2(response.data.data[0].part_weight_2);
            setPartWeightPlus1(response.data.data[0].part_weight_set_plus_1);
            setPartWeightPlus2(response.data.data[0].part_weight_set_plus_2);
            setPartWeightMinus1(response.data.data[0].part_weight_set_minus_1);
            setPartWeightMinus2(response.data.data[0].part_weight_set_minus_2);

              let now = new Date();
              setAUT05(now.getHours() + ":" + now.getMinutes());
            } else {
              setColour6(response.data.data[0].Colour);
              setMaterialGrade6(response.data.data[0].GrdNme);
              setMaterialUsed6(response.data.data[0].MatNme);
              setpartWeight(response.data.data[0].PrtWgt);
              setMTC(response.data.data[0].MTC);
              setHopp(response.data.data[0].Hopp);
              setHozz(response.data.data[0].Hozz);
              setZone1(response.data.data[0].Zone1);
              setZone2(response.data.data[0].Zone2);
              setZone3(response.data.data[0].Zone3);
              setCycletme(response.data.data[0].Cycletme);
              setCooltme(response.data.data[0].Cooltme);
              setInj104(response.data.data[0].Inj104);
              setInj101(response.data.data[0].Inj101);
              setInj201(response.data.data[0].Inj201);
              setInj301(response.data.data[0].Inj301);
              setInj102(response.data.data[0].Inj102);
              setInj202(response.data.data[0].Inj202);
              setInj302(response.data.data[0].Inj302);
              setHold101(response.data.data[0].Hold101);
              setHold201(response.data.data[0].Hold201);
              setHold401(response.data.data[0].Hold401);
              setRefillEnd(response.data.data[0].RefillEnd);
              setMelt101(response.data.data[0].Melt101);
              setPrtSet(response.data.data[0].PrtSet);
              setMtcSet(response.data.data[0].MtcSet);
              setHoppSet(response.data.data[0].HoppSet);
              setNozzSet(response.data.data[0].NozzSet);
              setZneSet1(response.data.data[0].ZneSet1);
              setZneSet2(response.data.data[0].ZneSet2);
              setZneSet3(response.data.data[0].ZneSet3);
              setCycTmeSet(response.data.data[0].CycTmeSet);
              setCoolTmeSet(response.data.data[0].CoolTmeSet);
              setInjTmeSet(response.data.data[0].InjTmeSet);
              setInjPresSet(response.data.data[0].InjPresSet);
              setInjSpdSet(response.data.data[0].InjSpdSet);
              setReFillPosSet(response.data.data[0].ReFillPosSet);

              setCusSet(response.data.data[0].CusSet);
              setMelt104(response.data.data[0].Melt104);
              setCoreSet(response.data.data[0].CoreSet);
              setCore(response.data.data[0].Core);
              setCavity(response.data.data[0].Cavity);
              setCavSet(response.data.data[0].CavSet);
              setPartWeight1(response.data.data[0].part_weight_1);
            setPartWeight2(response.data.data[0].part_weight_2);
            setPartWeightPlus1(response.data.data[0].part_weight_set_plus_1);
            setPartWeightPlus2(response.data.data[0].part_weight_set_plus_2);
            setPartWeightMinus1(response.data.data[0].part_weight_set_minus_1);
            setPartWeightMinus2(response.data.data[0].part_weight_set_minus_2);

              let now = new Date();
              setAUT06(now.getHours() + ":" + now.getMinutes());
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      if (number == "1") {
        setColour1("");
        setMaterialGrade1("");
        setMaterialUsed1("");
        setAUT01("");
      } else if (number == "2") {
        setColour2("");
        setMaterialGrade2("");
        setMaterialUsed2("");
        setAUT02("");
      } else if (number == "3") {
        setColour3("");
        setMaterialGrade3("");
        setMaterialUsed3("");
        setAUT03("");
      } else if (number == "4") {
        setColour4("");
        setMaterialGrade4("");
        setMaterialUsed4("");
        setAUT04("");
      } else if (number == "5") {
        setColour5("");
        setMaterialGrade5("");
        setMaterialUsed5("");
        setAUT05("");
      } else {
        setColour6("");
        setMaterialGrade6("");
        setMaterialUsed6("");
        setAUT06("");
      }
    }
  };

  // const handleUpdate = (e) => {
  //   e.preventDefault();
  //   // if (!PartName1 || !PartName2 || !PartName3 || !PartName4 || !PartName5 || !PartName6) {
  //   //   alert("Please Select Part Name");
  //   //          return;
  //   //     }
  //   const data = {
  //     data: [
  //       {
  //         // FormetNo: FormetNo,
  //         Id: Id,
  //         TrackDate: TrackDate,
  //         Mcno: Mcno,
  //         PartWeight: partWeight,

  //         PW1: PW1,
  //         PW2: PW2,
  //         PW3: PW3,
  //         PW4: PW4,
  //         PW5: PW5,
  //         PW6: PW6,

  //         AUT01: AUT01,
  //         AUT02: AUT02,
  //         AUT03: AUT03,
  //         AUT04: AUT04,
  //         AUT05: AUT05,
  //         AUT06: AUT06,
  //         PartName1: PartName1,
  //         PartName2: PartName2,
  //         PartName3: PartName3,
  //         PartName4: PartName4,
  //         PartName5: PartName5,
  //         PartName6: PartName6,
  //         Colour1: Colour1,
  //         Colour2: Colour2,
  //         Colour3: Colour3,
  //         Colour4: Colour4,
  //         Colour5: Colour5,
  //         Colour6: Colour6,
  //         MaterialUsed1: MaterialUsed1,
  //         MaterialUsed2: MaterialUsed2,
  //         MaterialUsed3: MaterialUsed3,
  //         MaterialUsed4: MaterialUsed4,
  //         MaterialUsed5: MaterialUsed5,
  //         MaterialUsed6: MaterialUsed6,
  //         MaterialGrade1: MaterialGrade1,
  //         MaterialGrade2: MaterialGrade2,
  //         MaterialGrade3: MaterialGrade3,
  //         MaterialGrade4: MaterialGrade4,
  //         MaterialGrade5: MaterialGrade5,
  //         MaterialGrade6: MaterialGrade6,
  //         RM1: RM1,
  //         RM2: RM2,
  //         RM3: RM3,
  //         RM4: RM4,
  //         RM5: RM5,
  //         RM6: RM6,
  //         A101: A101,
  //         A201: A201,
  //         A301: A301,
  //         A401: A401,
  //         A501: A501,
  //         A601: A601,
  //         A102: A102,
  //         A202: A202,
  //         A302: A302,
  //         A402: A402,
  //         A502: A502,
  //         A602: A602,
  //         A103: A103,
  //         A203: A203,
  //         A303: A303,
  //         A403: A403,
  //         A503: A503,
  //         A603: A603,
  //         A104: A104,
  //         A204: A204,
  //         A304: A304,
  //         A404: A404,
  //         A504: A504,
  //         A604: A604,
  //         A105: A105,
  //         A205: A205,
  //         A305: A305,
  //         A405: A405,
  //         A505: A505,
  //         A605: A605,
  //         A106: A106,
  //         A206: A206,
  //         A306: A306,
  //         A406: A406,
  //         A506: A506,
  //         A606: A606,
  //         A113: A113,
  //         A213: A213,
  //         A313: A313,
  //         A413: A413,
  //         A513: A513,
  //         A613: A613,

  //         Cycle1: cycle1,
  //         Cycle2: cycle2,
  //         Cycle3: cycle3,
  //         Cycle4: cycle4,
  //         Cycle5: cycle5,
  //         Cycle6: cycle6,
  //         Cool1: cool1,
  //         Cool2: cool2,
  //         Cool3: cool3,
  //         Cool4: cool4,
  //         Cool5: cool5,
  //         Cool6: cool6,
  //         Injectt1: injectt1,
  //         Injectt2: injectt2,
  //         Injectt3: injectt3,
  //         Injectt4: injectt4,
  //         Injectt5: injectt5,
  //         Injectt6: injectt6,
  //         Injectp101: injectp101,
  //         Injectp102: injectp102,
  //         Injectp103: injectp103,
  //         Injectp104: injectp104,
  //         Injectp105: injectp105,
  //         Injectp106: injectp106,
  //         Injectp201: injectp201,
  //         Injectp202: injectp202,
  //         Injectp203: injectp203,
  //         Injectp204: injectp204,
  //         Injectp205: injectp205,
  //         Injectp206: injectp206,
  //         Injectp301: injectp301,
  //         Injectp302: injectp302,
  //         Injectp303: injectp303,
  //         Injectp304: injectp304,
  //         Injectp305: injectp305,
  //         Injectp306: injectp306,
  //         Injects101: injects101,
  //         Injects102: injects102,
  //         Injects103: injects103,
  //         Injects104: injects104,
  //         Injects105: injects105,
  //         Injects106: injects106,
  //         Injects201: injects201,
  //         Injects202: injects202,
  //         Injects203: injects203,
  //         Injects204: injects204,
  //         Injects205: injects205,
  //         Injects206: injects206,
  //         Injects301: injects301,
  //         Injects302: injects302,
  //         Injects303: injects303,
  //         Injects304: injects304,
  //         Injects305: injects305,
  //         Injects306: injects306,
  //         Hp1: hp1,
  //         Hp2: hp2,
  //         Hp3: hp3,
  //         Hp4: hp4,
  //         Hp5: hp5,
  //         Hp6: hp6,
  //         Hs1: hs1,
  //         Hs2: hs2,
  //         Hs3: hs3,
  //         Hs4: hs4,
  //         Hs5: hs5,
  //         Hs6: hs6,
  //         Ht1: ht1,
  //         Ht2: ht2,
  //         Ht3: ht3,
  //         Ht4: ht4,
  //         Ht5: ht5,
  //         Ht6: ht6,
  //         Shsi1: shsi1,
  //         Shsi2: shsi2,
  //         Shsi3: shsi3,
  //         Shsi4: shsi4,
  //         Shsi5: shsi5,
  //         Shsi6: shsi6,
  //         Cushion1: Cushion1,
  //         Cushion2: Cushion2,
  //         Cushion3: Cushion3,
  //         Cushion4: Cushion4,
  //         Cushion5: Cushion5,
  //         Cushion6: Cushion6,
  //         Tempcav1: tempcav1,
  //         Tempcav2: tempcav2,
  //         Tempcav3: tempcav3,
  //         Tempcore1: tempcore1,
  //         Tempcore2: tempcore2,
  //         Tempcore3: tempcore3,
  //         Tempcore4: tempcore4,
  //         Tempcore5: tempcore5,
  //         Tempcore6: tempcore6,
  //         Tempcav4: tempcav4,
  //         Tempcav5: tempcav5,
  //         Tempcav6: tempcav6,
  //         VeriPer: VeriPer,
  //       },
  //     ],
  //   };

  //   data["_keyword_"] = "pro_mon_sys";
  //   data["secretkey"] = "2bf52be7-9f68-4d52-9523-53f7f267153b";
  //   axios
  //     .post(
  //       `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
  //       data
  //     )
  //     .then((response) => {
  //       setpro_mon_sys([...pro_mon_sys, response.data]);
  //       // setFormetNo("");
  //       setId("");
  //       setTrackDate("");
  //       setMcno("");
  //       setpartWeight("");

  //       setPW1("");
  //       setPW2("");
  //       setPW3("");
  //       setPW4("");
  //       setPW5("");
  //       setPW6("");

  //       setAUT01("");
  //       setAUT02("");
  //       setAUT03("");
  //       setAUT04("");
  //       setAUT05("");
  //       setAUT06("");
  //       setPartName1("");
  //       setPartName2("");
  //       setPartName3("");
  //       setPartName4("");
  //       setPartName5("");
  //       setPartName6("");
  //       setColour1("");
  //       setColour2("");
  //       setColour3("");
  //       setColour4("");
  //       setColour5("");
  //       setColour6("");
  //       setMaterialUsed1("");
  //       setMaterialUsed2("");
  //       setMaterialUsed3("");
  //       setMaterialUsed4("");
  //       setMaterialUsed5("");
  //       setMaterialUsed6("");
  //       setMaterialGrade1("");
  //       setMaterialGrade2("");
  //       setMaterialGrade3("");
  //       setMaterialGrade4("");
  //       setMaterialGrade5("");
  //       setMaterialGrade6("");
  //       setRM1("");
  //       setRM2("");
  //       setRM3("");
  //       setRM4("");
  //       setRM5("");
  //       setRM6("");
  //       setA101("");
  //       setA201("");
  //       setA301("");
  //       setA401("");
  //       setA501("");
  //       setA601("");
  //       setA102("");
  //       setA202("");
  //       setA302("");
  //       setA402("");
  //       setA502("");
  //       setA602("");
  //       setA103("");
  //       setA203("");
  //       setA303("");
  //       setA403("");
  //       setA503("");
  //       setA603("");
  //       setA104("");
  //       setA204("");
  //       setA304("");
  //       setA404("");
  //       setA504("");
  //       setA604("");
  //       setA105("");
  //       setA205("");
  //       setA305("");
  //       setA405("");
  //       setA505("");
  //       setA605("");
  //       setA106("");
  //       setA206("");
  //       setA306("");
  //       setA406("");
  //       setA506("");
  //       setA606("");
  //       setA113("");
  //       setA213("");
  //       setA313("");
  //       setA413("");
  //       setA513("");
  //       setA613("");

  //       setcycle1("");
  //       setcycle2("");
  //       setcycle3("");
  //       setcycle4("");
  //       setcycle5("");
  //       setcycle6("");
  //       setcool1("");
  //       setcool2("");
  //       setcool3("");
  //       setcool4("");
  //       setcool5("");
  //       setcool6("");
  //       setinjectt1("");
  //       setinjectt2("");
  //       setinjectt3("");
  //       setinjectt4("");
  //       setinjectt5("");
  //       setinjectt6("");
  //       setinjectp101("");
  //       setinjectp102("");
  //       setinjectp103("");
  //       setinjectp104("");
  //       setinjectp105("");
  //       setinjectp106("");
  //       setinjectp201("");
  //       setinjectp202("");
  //       setinjectp203("");
  //       setinjectp204("");
  //       setinjectp205("");
  //       setinjectp206("");
  //       setinjectp301("");
  //       setinjectp302("");
  //       setinjectp303("");
  //       setinjectp304("");
  //       setinjectp305("");
  //       setinjectp306("");
  //       setinjects101("");
  //       setinjects102("");
  //       setinjects103("");
  //       setinjects104("");
  //       setinjects105("");
  //       setinjects106("");
  //       setinjects201("");
  //       setinjects202("");
  //       setinjects203("");
  //       setinjects204("");
  //       setinjects205("");
  //       setinjects206("");
  //       setinjects301("");
  //       setinjects302("");
  //       setinjects303("");
  //       setinjects304("");
  //       setinjects305("");
  //       setinjects306("");
  //       sethp1("");
  //       sethp2("");
  //       sethp3("");
  //       sethp4("");
  //       sethp5("");
  //       sethp6("");
  //       seths1("");
  //       seths2("");
  //       seths3("");
  //       seths4("");
  //       seths5("");
  //       seths6("");
  //       setht1("");
  //       setht2("");
  //       setht3("");
  //       setht4("");
  //       setht5("");
  //       setht6("");
  //       setshsi1("");
  //       setshsi2("");
  //       setshsi3("");
  //       setshsi4("");
  //       setshsi5("");
  //       setshsi6("");
  //       setCushion1("");
  //       setCushion2("");
  //       setCushion3("");
  //       setCushion4("");
  //       setCushion5("");
  //       setCushion6("");
  //       settempcav1("");
  //       settempcav2("");
  //       settempcav3("");
  //       settempcore1("");
  //       settempcore2("");
  //       settempcore3("");
  //       settempcore4("");
  //       settempcore5("");
  //       settempcore6("");
  //       settempcav4("");
  //       settempcav5("");
  //       settempcav6("");
  //       setVeriPer("");
  //       alert('Update successful!');
  //       window.location.reload();
  //       // swal({
  //       //   title:"Good job!",
  //       //   text:`Update successful`,
  //       //   icon:"success",
  //       //   button:"Okay",
  //       //   closeOnClickOutside: false,
  //       // }) .then(() => {

  //         // window.location.reload();
  //         //     }  )
  //         })
  //        .catch((error) => {
  //         console.log(error);
  //         alert('Update failed.');
  //         // window.stop();
  //         // swal({
  //         //   title:"Update failed!",
  //         //   text:`Please Press Try Again to Reload the Page.`,
  //         //   icon:'error',
  //         //   button:"Try Again",
  //         //   closeOnClickOutside: false,
  //         // }) .then(() => {
  //         //   window.location.reload();
  //         // }
  //         // )
  //         window.location.reload();
  //       });
  //     }

  async function handleMachine(event) {
    const result = await GetRecord(
      {
        _keyword_: "Get_PMS_Parant_by_MachineNo_in_CurrentDate",
        Mcno: event.value,
      },
      setPMS
    ).then((res) => {
      if (res === "NoRecordFound") {
        setNew(true);
        setPMS([
          {
            ...initMachine,
            Mcno: event.value,
            mc_name: event.label,
            TrackDate: new Date(
              new Date().getTime() - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .split("T")[0],
          },
        ]);
      } else {
        setNew(false);
        setInsert(false);
      }
    });
  }

  const handleEdit = async (e) => {
    await GetRecord({ _keyword_: "Get_PMS_Parant_by_ID", Id: e }, setPMS).then(
      (res) => {
        let employee = {};
        GetRecord(
          {
            _keyword_: "emp_details_for_PMS_by_empCode",
            emp_code: res[0].ShiftInchargeShiftA,
          },
          setemp
        ).then((empres) => {
          employee.ShiftInchargeShiftA = empres[0].Emp_code;
          employee.ShiftInchargeShiftAEmp_name = empres[0].Emp_name;
          GetRecord(
            {
              _keyword_: "emp_details_for_PMS_by_empCode",
              emp_code: res[0].ShiftInchargeShiftB,
            },
            setemp
          ).then((empres) => {
            employee.ShiftInchargeShiftB = empres[0].Emp_code;
            employee.ShiftInchargeShiftBEmp_name = empres[0].Emp_name;
            // console.log(empres);
            GetRecord(
              {
                _keyword_: "emp_details_for_PMS_by_empCode",
                emp_code: res[0].ShiftInchargeShiftC,
              },
              setemp
            ).then((empres) => {
              employee.ShiftInchargeShiftC = empres[0].Emp_code;
              employee.ShiftInchargeShiftCEmp_name = empres[0].Emp_name;
              GetRecord(
                {
                  _keyword_: "emp_details_for_PMS_by_empCode",
                  emp_code: res[0].LineInspecQaShiftA,
                },
                setemp
              ).then((empres) => {
                employee.LineInspecQaShiftA = empres[0].Emp_code;
                employee.LineInspecQaShiftAEmp_name = empres[0].Emp_name;
                GetRecord(
                  {
                    _keyword_: "emp_details_for_PMS_by_empCode",
                    emp_code: res[0].LineInspecQaShiftB,
                  },
                  setemp
                ).then((empres) => {
                  employee.LineInspecQaShiftB = empres[0].Emp_code;
                  employee.LineInspecQaShiftBEmp_name = empres[0].Emp_name;
                  GetRecord(
                    {
                      _keyword_: "emp_details_for_PMS_by_empCode",
                      emp_code: res[0].LineInspecQaShiftC,
                    },
                    setemp
                  ).then((empres) => {
                    employee.LineInspecQaShiftC = empres[0].Emp_code;
                    employee.LineInspecQaShiftCEmp_name = empres[0].Emp_name;
                    setEmpName(employee);
                  });
                });
              });
            });
          });
        });
      }
    );
    setButtonPopup(true);
    setNew(false);
    setInsert(false);
    setPaginationVisible(false);
  };

  const HandleView = async (e) => {
    setDownloadEnabled(true)
    await GetRecord({ _keyword_: "Get_PMS_Parant_by_ID", Id: e }, setPMS).then(
      (res) => {
        let employee = {};
        GetRecord(
          {
            _keyword_: "emp_details_for_PMS_by_empCode",
            emp_code: res[0].ShiftInchargeShiftA,
          },
          setemp
        ).then((empres) => {
          employee.ShiftInchargeShiftA = empres[0].Emp_code;
          employee.ShiftInchargeShiftAEmp_name = empres[0].Emp_name;
          GetRecord(
            {
              _keyword_: "emp_details_for_PMS_by_empCode",
              emp_code: res[0].ShiftInchargeShiftB,
            },
            setemp
          ).then((empres) => {
            employee.ShiftInchargeShiftB = empres[0].Emp_code;
            employee.ShiftInchargeShiftBEmp_name = empres[0].Emp_name;
            // console.log(empres);
            GetRecord(
              {
                _keyword_: "emp_details_for_PMS_by_empCode",
                emp_code: res[0].ShiftInchargeShiftC,
              },
              setemp
            ).then((empres) => {
              employee.ShiftInchargeShiftC = empres[0].Emp_code;
              employee.ShiftInchargeShiftCEmp_name = empres[0].Emp_name;
              GetRecord(
                {
                  _keyword_: "emp_details_for_PMS_by_empCode",
                  emp_code: res[0].LineInspecQaShiftA,
                },
                setemp
              ).then((empres) => {
                employee.LineInspecQaShiftA = empres[0].Emp_code;
                employee.LineInspecQaShiftAEmp_name = empres[0].Emp_name;
                GetRecord(
                  {
                    _keyword_: "emp_details_for_PMS_by_empCode",
                    emp_code: res[0].LineInspecQaShiftB,
                  },
                  setemp
                ).then((empres) => {
                  employee.LineInspecQaShiftB = empres[0].Emp_code;
                  employee.LineInspecQaShiftBEmp_name = empres[0].Emp_name;
                  GetRecord(
                    {
                      _keyword_: "emp_details_for_PMS_by_empCode",
                      emp_code: res[0].LineInspecQaShiftC,
                    },
                    setemp
                  ).then((empres) => {
                    employee.LineInspecQaShiftC = empres[0].Emp_code;
                    employee.LineInspecQaShiftCEmp_name = empres[0].Emp_name;
                    setEmpName(employee);
                  });
                });
              });
            });
          });
        });
      }
    );
    setButtonPopup1(true);
    setNew();
    setInsert(false);
    setPaginationVisible(false);
  };

  function HandleFeildChangePMS(event) {
    setPMS([{ ...PMS[0], [event.target.name]: event.target.value }]);
  }

  function HandlePart7Am(event) {
    if (event.target.value === "MOULD CHANGE") {
      setInsert(["Track_7_am", "Heat_actual_1", "Inject_actual_1"]);
      setTracking([
        {
          ...Tracking[0],
          Track_7_am: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[1]
            .substring(0, 5),
        },
        { ...Tracking[1], Track_7_am: event.target.value },
        { ...Tracking[2], Track_7_am: event.target.value },
        { ...Tracking[3], Track_7_am: event.target.value },
        { ...Tracking[4], Track_7_am: event.target.value },
      ]);
      setHeat([
        { ...Heat[0], Heat_actual_1: event.target.value },
        { ...Heat[1], Heat_actual_1: event.target.value },
        { ...Heat[2], Heat_actual_1: event.target.value },
        { ...Heat[3], Heat_actual_1: event.target.value },
        { ...Heat[4], Heat_actual_1: event.target.value },
        { ...Heat[5], Heat_actual_1: event.target.value },
        { ...Heat[6], Heat_actual_1: event.target.value },
        { ...Heat[7], Heat_actual_1: event.target.value },
        { ...Heat[8], Heat_actual_1: event.target.value },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_1: event.target.value },
        { ...Inject[1], Inject_actual_1: event.target.value },
        { ...Inject[2], Inject_actual_1: event.target.value },
        { ...Inject[3], Inject_actual_1: event.target.value },
        { ...Inject[4], Inject_actual_1: event.target.value },
        { ...Inject[5], Inject_actual_1: event.target.value },
        { ...Inject[6], Inject_actual_1: event.target.value },
        { ...Inject[7], Inject_actual_1: event.target.value },
        { ...Inject[8], Inject_actual_1: event.target.value },
        { ...Inject[9], Inject_actual_1: event.target.value },
        { ...Inject[10], Inject_actual_1: event.target.value },
        { ...Inject[11], Inject_actual_1: event.target.value },
        { ...Inject[12], Inject_actual_1: event.target.value },
        { ...Inject[13], Inject_actual_1: event.target.value },
        { ...Inject[14], Inject_actual_1: event.target.value },
        { ...Inject[15], Inject_actual_1: event.target.value },
        { ...Inject[16], Inject_actual_1: event.target.value },
        { ...Inject[17], Inject_actual_1: event.target.value },
        { ...Inject[18], Inject_actual_1: event.target.value },
        { ...Inject[19], Inject_actual_1: event.target.value },
        { ...Inject[20], Inject_actual_1: event.target.value },
        { ...Inject[21], Inject_actual_1: event.target.value },
        { ...Inject[22], Inject_actual_1: event.target.value },
        { ...Inject[23], Inject_actual_1: event.target.value },
        { ...Inject[24], Inject_actual_1: event.target.value },
        { ...Inject[25], Inject_actual_1: event.target.value },
        { ...Inject[26], Inject_actual_1: event.target.value },
        { ...Inject[27], Inject_actual_1: event.target.value },
      ]);
    } else if (event.target.value === "IDEAL") {
      setTracking([
        {
          ...Tracking[0],
          Track_7_am: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[1]
            .substring(0, 5),
        },
        { ...Tracking[1], Track_7_am: event.target.value },
        { ...Tracking[2], Track_7_am: event.target.value },
        { ...Tracking[3], Track_7_am: event.target.value },
        { ...Tracking[4], Track_7_am: event.target.value },
      ]);
      setHeat([
        { ...Heat[0], Heat_actual_1: event.target.value },
        { ...Heat[1], Heat_actual_1: event.target.value },
        { ...Heat[2], Heat_actual_1: event.target.value },
        { ...Heat[3], Heat_actual_1: event.target.value },
        { ...Heat[4], Heat_actual_1: event.target.value },
        { ...Heat[5], Heat_actual_1: event.target.value },
        { ...Heat[6], Heat_actual_1: event.target.value },
        { ...Heat[7], Heat_actual_1: event.target.value },
        { ...Heat[8], Heat_actual_1: event.target.value },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_1: event.target.value },
        { ...Inject[1], Inject_actual_1: event.target.value },
        { ...Inject[2], Inject_actual_1: event.target.value },
        { ...Inject[3], Inject_actual_1: event.target.value },
        { ...Inject[4], Inject_actual_1: event.target.value },
        { ...Inject[5], Inject_actual_1: event.target.value },
        { ...Inject[6], Inject_actual_1: event.target.value },
        { ...Inject[7], Inject_actual_1: event.target.value },
        { ...Inject[8], Inject_actual_1: event.target.value },
        { ...Inject[9], Inject_actual_1: event.target.value },
        { ...Inject[10], Inject_actual_1: event.target.value },
        { ...Inject[11], Inject_actual_1: event.target.value },
        { ...Inject[12], Inject_actual_1: event.target.value },
        { ...Inject[13], Inject_actual_1: event.target.value },
        { ...Inject[14], Inject_actual_1: event.target.value },
        { ...Inject[15], Inject_actual_1: event.target.value },
        { ...Inject[16], Inject_actual_1: event.target.value },
        { ...Inject[17], Inject_actual_1: event.target.value },
        { ...Inject[18], Inject_actual_1: event.target.value },
        { ...Inject[19], Inject_actual_1: event.target.value },
        { ...Inject[20], Inject_actual_1: event.target.value },
        { ...Inject[21], Inject_actual_1: event.target.value },
        { ...Inject[22], Inject_actual_1: event.target.value },
        { ...Inject[23], Inject_actual_1: event.target.value },
        { ...Inject[24], Inject_actual_1: event.target.value },
        { ...Inject[25], Inject_actual_1: event.target.value },
        { ...Inject[26], Inject_actual_1: event.target.value },
        { ...Inject[27], Inject_actual_1: event.target.value },
      ]);
    } else {
      GetRecord(
        {
          _keyword_: "std_mld_params_by_part_pms",
          PrtNme: event.target.value,
          McNo: PMS[0].Mcno,
        },
        setSmp
      ).then((res) => {
        setTracking([
          {
            ...Tracking[0],
            Track_7_am: new Date(
              new Date().getTime() - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .split("T")[1]
              .substring(0, 5),
          },
          { ...Tracking[1], Track_7_am: res[0].PrtNme },
          { ...Tracking[2], Track_7_am: res[0].Colour },
          { ...Tracking[3], Track_7_am: res[0].MatNme },
          { ...Tracking[4], Track_7_am: res[0].GrdNme },
        ]);
        setHeat([
          { ...Heat[0], Heat_actual_1: "" },
          { ...Heat[1], Heat_actual_1: "" },
          { ...Heat[2], Heat_actual_1: "" },
          { ...Heat[3], Heat_actual_1: "" },
          { ...Heat[4], Heat_actual_1: "" },
          { ...Heat[5], Heat_actual_1: "" },
          { ...Heat[6], Heat_actual_1: "" },
          { ...Heat[7], Heat_actual_1: "" },
          { ...Heat[8], Heat_actual_1: "" },
        ]);
        setInject([
          { ...Inject[0], Inject_actual_1: "" },
          { ...Inject[1], Inject_actual_1: "" },
          { ...Inject[2], Inject_actual_1: "" },
          { ...Inject[3], Inject_actual_1: "" },
          { ...Inject[4], Inject_actual_1: "" },
          { ...Inject[5], Inject_actual_1: "" },
          { ...Inject[6], Inject_actual_1: "" },
          { ...Inject[7], Inject_actual_1: "" },
          { ...Inject[8], Inject_actual_1: "" },
          { ...Inject[9], Inject_actual_1: "" },
          { ...Inject[10], Inject_actual_1: "" },
          { ...Inject[11], Inject_actual_1: "" },
          { ...Inject[12], Inject_actual_1: "" },
          { ...Inject[13], Inject_actual_1: "" },
          { ...Inject[14], Inject_actual_1: "" },
          { ...Inject[15], Inject_actual_1: "" },
          { ...Inject[16], Inject_actual_1: "" },
          { ...Inject[17], Inject_actual_1: "" },
          { ...Inject[18], Inject_actual_1: "" },
          { ...Inject[19], Inject_actual_1: "" },
          { ...Inject[20], Inject_actual_1: "" },
          { ...Inject[21], Inject_actual_1: "" },
          { ...Inject[22], Inject_actual_1: "" },
          { ...Inject[23], Inject_actual_1: "" },
          { ...Inject[24], Inject_actual_1: "" },
          { ...Inject[25], Inject_actual_1: "" },
          { ...Inject[26], Inject_actual_1: "" },
          { ...Inject[27], Inject_actual_1: "" },
        ]);
      });
    }
  }
  function HandlePart11Am(event) {
    if (event.target.value === "MOULD CHANGE") {
      setInsert(["Track_11_am", "Heat_actual_2", "Inject_actual_2"]);
      setTracking([
        {
          ...Tracking[0],
          Track_11_am: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[1]
            .substring(0, 5),
        },
        { ...Tracking[1], Track_11_am: event.target.value },
        { ...Tracking[2], Track_11_am: event.target.value },
        { ...Tracking[3], Track_11_am: event.target.value },
        { ...Tracking[4], Track_11_am: event.target.value },
      ]);
      setHeat([
        { ...Heat[0], Heat_actual_2: event.target.value },
        { ...Heat[1], Heat_actual_2: event.target.value },
        { ...Heat[2], Heat_actual_2: event.target.value },
        { ...Heat[3], Heat_actual_2: event.target.value },
        { ...Heat[4], Heat_actual_2: event.target.value },
        { ...Heat[5], Heat_actual_2: event.target.value },
        { ...Heat[6], Heat_actual_2: event.target.value },
        { ...Heat[7], Heat_actual_2: event.target.value },
        { ...Heat[8], Heat_actual_2: event.target.value },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_2: event.target.value },
        { ...Inject[1], Inject_actual_2: event.target.value },
        { ...Inject[2], Inject_actual_2: event.target.value },
        { ...Inject[3], Inject_actual_2: event.target.value },
        { ...Inject[4], Inject_actual_2: event.target.value },
        { ...Inject[5], Inject_actual_2: event.target.value },
        { ...Inject[6], Inject_actual_2: event.target.value },
        { ...Inject[7], Inject_actual_2: event.target.value },
        { ...Inject[8], Inject_actual_2: event.target.value },
        { ...Inject[9], Inject_actual_2: event.target.value },
        { ...Inject[10], Inject_actual_2: event.target.value },
        { ...Inject[11], Inject_actual_2: event.target.value },
        { ...Inject[12], Inject_actual_2: event.target.value },
        { ...Inject[13], Inject_actual_2: event.target.value },
        { ...Inject[14], Inject_actual_2: event.target.value },
        { ...Inject[15], Inject_actual_2: event.target.value },
        { ...Inject[16], Inject_actual_2: event.target.value },
        { ...Inject[17], Inject_actual_2: event.target.value },
        { ...Inject[18], Inject_actual_2: event.target.value },
        { ...Inject[19], Inject_actual_2: event.target.value },
        { ...Inject[20], Inject_actual_2: event.target.value },
        { ...Inject[21], Inject_actual_2: event.target.value },
        { ...Inject[22], Inject_actual_2: event.target.value },
        { ...Inject[23], Inject_actual_2: event.target.value },
        { ...Inject[24], Inject_actual_2: event.target.value },
        { ...Inject[25], Inject_actual_2: event.target.value },
        { ...Inject[26], Inject_actual_2: event.target.value },
        { ...Inject[27], Inject_actual_2: event.target.value },
      ]);
    } else if (event.target.value === "IDEAL") {
      setTracking([
        {
          ...Tracking[0],
          Track_11_am: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[1]
            .substring(0, 5),
        },
        { ...Tracking[1], Track_11_am: event.target.value },
        { ...Tracking[2], Track_11_am: event.target.value },
        { ...Tracking[3], Track_11_am: event.target.value },
        { ...Tracking[4], Track_11_am: event.target.value },
      ]);
      setHeat([
        { ...Heat[0], Heat_actual_2: event.target.value },
        { ...Heat[1], Heat_actual_2: event.target.value },
        { ...Heat[2], Heat_actual_2: event.target.value },
        { ...Heat[3], Heat_actual_2: event.target.value },
        { ...Heat[4], Heat_actual_2: event.target.value },
        { ...Heat[5], Heat_actual_2: event.target.value },
        { ...Heat[6], Heat_actual_2: event.target.value },
        { ...Heat[7], Heat_actual_2: event.target.value },
        { ...Heat[8], Heat_actual_2: event.target.value },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_2: event.target.value },
        { ...Inject[1], Inject_actual_2: event.target.value },
        { ...Inject[2], Inject_actual_2: event.target.value },
        { ...Inject[3], Inject_actual_2: event.target.value },
        { ...Inject[4], Inject_actual_2: event.target.value },
        { ...Inject[5], Inject_actual_2: event.target.value },
        { ...Inject[6], Inject_actual_2: event.target.value },
        { ...Inject[7], Inject_actual_2: event.target.value },
        { ...Inject[8], Inject_actual_2: event.target.value },
        { ...Inject[9], Inject_actual_2: event.target.value },
        { ...Inject[10], Inject_actual_2: event.target.value },
        { ...Inject[11], Inject_actual_2: event.target.value },
        { ...Inject[12], Inject_actual_2: event.target.value },
        { ...Inject[13], Inject_actual_2: event.target.value },
        { ...Inject[14], Inject_actual_2: event.target.value },
        { ...Inject[15], Inject_actual_2: event.target.value },
        { ...Inject[16], Inject_actual_2: event.target.value },
        { ...Inject[17], Inject_actual_2: event.target.value },
        { ...Inject[18], Inject_actual_2: event.target.value },
        { ...Inject[19], Inject_actual_2: event.target.value },
        { ...Inject[20], Inject_actual_2: event.target.value },
        { ...Inject[21], Inject_actual_2: event.target.value },
        { ...Inject[22], Inject_actual_2: event.target.value },
        { ...Inject[23], Inject_actual_2: event.target.value },
        { ...Inject[24], Inject_actual_2: event.target.value },
        { ...Inject[25], Inject_actual_2: event.target.value },
        { ...Inject[26], Inject_actual_2: event.target.value },
        { ...Inject[27], Inject_actual_2: event.target.value },
      ]);
    } else {
      GetRecord(
        {
          _keyword_: "std_mld_params_by_part_pms",
          PrtNme: event.target.value,
          McNo: PMS[0].Mcno,
        },
        setSmp
      ).then((res) => {
        setTracking([
          {
            ...Tracking[0],
            Track_11_am: new Date(
              new Date().getTime() - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .split("T")[1]
              .substring(0, 5),
          },
          { ...Tracking[1], Track_11_am: res[0].PrtNme },
          { ...Tracking[2], Track_11_am: res[0].Colour },
          { ...Tracking[3], Track_11_am: res[0].MatNme },
          { ...Tracking[4], Track_11_am: res[0].GrdNme },
        ]);
      });
      setHeat([
        { ...Heat[0], Heat_actual_2: "" },
        { ...Heat[1], Heat_actual_2: '' },
        { ...Heat[2], Heat_actual_2: '' },
        { ...Heat[3], Heat_actual_2: '' },
        { ...Heat[4], Heat_actual_2: '' },
        { ...Heat[5], Heat_actual_2: '' },
        { ...Heat[6], Heat_actual_2: '' },
        { ...Heat[7], Heat_actual_2: '' },
        { ...Heat[8], Heat_actual_2: '' },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_2: '' },
        { ...Inject[1], Inject_actual_2: '' },
        { ...Inject[2], Inject_actual_2: '' },
        { ...Inject[3], Inject_actual_2: '' },
        { ...Inject[4], Inject_actual_2: '' },
        { ...Inject[5], Inject_actual_2: '' },
        { ...Inject[6], Inject_actual_2: '' },
        { ...Inject[7], Inject_actual_2: '' },
        { ...Inject[8], Inject_actual_2: '' },
        { ...Inject[9], Inject_actual_2: '' },
        { ...Inject[10], Inject_actual_2: '' },
        { ...Inject[11], Inject_actual_2: '' },
        { ...Inject[12], Inject_actual_2: '' },
        { ...Inject[13], Inject_actual_2: '' },
        { ...Inject[14], Inject_actual_2: '' },
        { ...Inject[15], Inject_actual_2: '' },
        { ...Inject[16], Inject_actual_2: '' },
        { ...Inject[17], Inject_actual_2: '' },
        { ...Inject[18], Inject_actual_2: '' },
        { ...Inject[19], Inject_actual_2: '' },
        { ...Inject[20], Inject_actual_2: '' },
        { ...Inject[21], Inject_actual_2: '' },
        { ...Inject[22], Inject_actual_2: '' },
        { ...Inject[23], Inject_actual_2: '' },
        { ...Inject[24], Inject_actual_2: '' },
        { ...Inject[25], Inject_actual_2: '' },
        { ...Inject[26], Inject_actual_2: '' },
        { ...Inject[27], Inject_actual_2: '' },
      ]);
    }
  }
  function HandlePart15Pm(event) {
    if (event.target.value === "MOULD CHANGE") {
      setInsert(["Track_15_pm", "Heat_actual_3", "Inject_actual_3"]);
      setTracking([
        {
          ...Tracking[0],
          Track_15_pm: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[1]
            .substring(0, 5),
        },
        { ...Tracking[1], Track_15_pm: event.target.value },
        { ...Tracking[2], Track_15_pm: event.target.value },
        { ...Tracking[3], Track_15_pm: event.target.value },
        { ...Tracking[4], Track_15_pm: event.target.value },
      ]);
      setHeat([
        { ...Heat[0], Heat_actual_3: event.target.value },
        { ...Heat[1], Heat_actual_3: event.target.value },
        { ...Heat[2], Heat_actual_3: event.target.value },
        { ...Heat[3], Heat_actual_3: event.target.value },
        { ...Heat[4], Heat_actual_3: event.target.value },
        { ...Heat[5], Heat_actual_3: event.target.value },
        { ...Heat[6], Heat_actual_3: event.target.value },
        { ...Heat[7], Heat_actual_3: event.target.value },
        { ...Heat[8], Heat_actual_3: event.target.value },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_3: event.target.value },
        { ...Inject[1], Inject_actual_3: event.target.value },
        { ...Inject[2], Inject_actual_3: event.target.value },
        { ...Inject[3], Inject_actual_3: event.target.value },
        { ...Inject[4], Inject_actual_3: event.target.value },
        { ...Inject[5], Inject_actual_3: event.target.value },
        { ...Inject[6], Inject_actual_3: event.target.value },
        { ...Inject[7], Inject_actual_3: event.target.value },
        { ...Inject[8], Inject_actual_3: event.target.value },
        { ...Inject[9], Inject_actual_3: event.target.value },
        { ...Inject[10], Inject_actual_3: event.target.value },
        { ...Inject[11], Inject_actual_3: event.target.value },
        { ...Inject[12], Inject_actual_3: event.target.value },
        { ...Inject[13], Inject_actual_3: event.target.value },
        { ...Inject[14], Inject_actual_3: event.target.value },
        { ...Inject[15], Inject_actual_3: event.target.value },
        { ...Inject[16], Inject_actual_3: event.target.value },
        { ...Inject[17], Inject_actual_3: event.target.value },
        { ...Inject[18], Inject_actual_3: event.target.value },
        { ...Inject[19], Inject_actual_3: event.target.value },
        { ...Inject[20], Inject_actual_3: event.target.value },
        { ...Inject[21], Inject_actual_3: event.target.value },
        { ...Inject[22], Inject_actual_3: event.target.value },
        { ...Inject[23], Inject_actual_3: event.target.value },
        { ...Inject[24], Inject_actual_3: event.target.value },
        { ...Inject[25], Inject_actual_3: event.target.value },
        { ...Inject[26], Inject_actual_3: event.target.value },
        { ...Inject[27], Inject_actual_3: event.target.value },
      ]);
    } else if (event.target.value === "IDEAL") {
      setTracking([
        {
          ...Tracking[0],
          Track_15_pm: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[1]
            .substring(0, 5),
        },
        { ...Tracking[1], Track_15_pm: event.target.value },
        { ...Tracking[2], Track_15_pm: event.target.value },
        { ...Tracking[3], Track_15_pm: event.target.value },
        { ...Tracking[4], Track_15_pm: event.target.value },
      ]);
      setHeat([
        { ...Heat[0], Heat_actual_3: event.target.value },
        { ...Heat[1], Heat_actual_3: event.target.value },
        { ...Heat[2], Heat_actual_3: event.target.value },
        { ...Heat[3], Heat_actual_3: event.target.value },
        { ...Heat[4], Heat_actual_3: event.target.value },
        { ...Heat[5], Heat_actual_3: event.target.value },
        { ...Heat[6], Heat_actual_3: event.target.value },
        { ...Heat[7], Heat_actual_3: event.target.value },
        { ...Heat[8], Heat_actual_3: event.target.value },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_3: event.target.value },
        { ...Inject[1], Inject_actual_3: event.target.value },
        { ...Inject[2], Inject_actual_3: event.target.value },
        { ...Inject[3], Inject_actual_3: event.target.value },
        { ...Inject[4], Inject_actual_3: event.target.value },
        { ...Inject[5], Inject_actual_3: event.target.value },
        { ...Inject[6], Inject_actual_3: event.target.value },
        { ...Inject[7], Inject_actual_3: event.target.value },
        { ...Inject[8], Inject_actual_3: event.target.value },
        { ...Inject[9], Inject_actual_3: event.target.value },
        { ...Inject[10], Inject_actual_3: event.target.value },
        { ...Inject[11], Inject_actual_3: event.target.value },
        { ...Inject[12], Inject_actual_3: event.target.value },
        { ...Inject[13], Inject_actual_3: event.target.value },
        { ...Inject[14], Inject_actual_3: event.target.value },
        { ...Inject[15], Inject_actual_3: event.target.value },
        { ...Inject[16], Inject_actual_3: event.target.value },
        { ...Inject[17], Inject_actual_3: event.target.value },
        { ...Inject[18], Inject_actual_3: event.target.value },
        { ...Inject[19], Inject_actual_3: event.target.value },
        { ...Inject[20], Inject_actual_3: event.target.value },
        { ...Inject[21], Inject_actual_3: event.target.value },
        { ...Inject[22], Inject_actual_3: event.target.value },
        { ...Inject[23], Inject_actual_3: event.target.value },
        { ...Inject[24], Inject_actual_3: event.target.value },
        { ...Inject[25], Inject_actual_3: event.target.value },
        { ...Inject[26], Inject_actual_3: event.target.value },
        { ...Inject[27], Inject_actual_3: event.target.value },
      ]);
    } else {
      GetRecord(
        {
          _keyword_: "std_mld_params_by_part_pms",
          PrtNme: event.target.value,
          McNo: PMS[0].Mcno,
        },
        setSmp
      ).then((res) => {
        setTracking([
          {
            ...Tracking[0],
            Track_15_pm: new Date(
              new Date().getTime() - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .split("T")[1]
              .substring(0, 5),
          },
          { ...Tracking[1], Track_15_pm: res[0].PrtNme },
          { ...Tracking[2], Track_15_pm: res[0].Colour },
          { ...Tracking[3], Track_15_pm: res[0].MatNme },
          { ...Tracking[4], Track_15_pm: res[0].GrdNme },
        ]);
      });
      setHeat([
        { ...Heat[0], Heat_actual_3: '' },
        { ...Heat[1], Heat_actual_3: '' },
        { ...Heat[2], Heat_actual_3: '' },
        { ...Heat[3], Heat_actual_3: '' },
        { ...Heat[4], Heat_actual_3: '' },
        { ...Heat[5], Heat_actual_3: '' },
        { ...Heat[6], Heat_actual_3: '' },
        { ...Heat[7], Heat_actual_3: '' },
        { ...Heat[8], Heat_actual_3: '' },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_3: '' },
        { ...Inject[1], Inject_actual_3: '' },
        { ...Inject[2], Inject_actual_3: '' },
        { ...Inject[3], Inject_actual_3: '' },
        { ...Inject[4], Inject_actual_3: '' },
        { ...Inject[5], Inject_actual_3: '' },
        { ...Inject[6], Inject_actual_3: '' },
        { ...Inject[7], Inject_actual_3: '' },
        { ...Inject[8], Inject_actual_3: '' },
        { ...Inject[9], Inject_actual_3: '' },
        { ...Inject[10], Inject_actual_3: '' },
        { ...Inject[11], Inject_actual_3: '' },
        { ...Inject[12], Inject_actual_3: '' },
        { ...Inject[13], Inject_actual_3: '' },
        { ...Inject[14], Inject_actual_3: '' },
        { ...Inject[15], Inject_actual_3: '' },
        { ...Inject[16], Inject_actual_3: '' },
        { ...Inject[17], Inject_actual_3: '' },
        { ...Inject[18], Inject_actual_3: '' },
        { ...Inject[19], Inject_actual_3: '' },
        { ...Inject[20], Inject_actual_3: '' },
        { ...Inject[21], Inject_actual_3: '' },
        { ...Inject[22], Inject_actual_3: '' },
        { ...Inject[23], Inject_actual_3: '' },
        { ...Inject[24], Inject_actual_3: '' },
        { ...Inject[25], Inject_actual_3: '' },
        { ...Inject[26], Inject_actual_3: '' },
        { ...Inject[27], Inject_actual_3: '' },
      ]);
    }
  }
  function HandlePart19Pm(event) {

    if (event.target.value === "MOULD CHANGE") {
      setInsert(["Track_19_pm", "Heat_actual_4", "Inject_actual_4"]);
      setTracking([
        {
          ...Tracking[0],
          Track_19_pm: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[1]
            .substring(0, 5),
        },
        { ...Tracking[1], Track_19_pm: event.target.value },
        { ...Tracking[2], Track_19_pm: event.target.value },
        { ...Tracking[3], Track_19_pm: event.target.value },
        { ...Tracking[4], Track_19_pm: event.target.value },
      ]);
      setHeat([
        { ...Heat[0], Heat_actual_4: event.target.value },
        { ...Heat[1], Heat_actual_4: event.target.value },
        { ...Heat[2], Heat_actual_4: event.target.value },
        { ...Heat[3], Heat_actual_4: event.target.value },
        { ...Heat[4], Heat_actual_4: event.target.value },
        { ...Heat[5], Heat_actual_4: event.target.value },
        { ...Heat[6], Heat_actual_4: event.target.value },
        { ...Heat[7], Heat_actual_4: event.target.value },
        { ...Heat[8], Heat_actual_4: event.target.value },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_4: event.target.value },
        { ...Inject[1], Inject_actual_4: event.target.value },
        { ...Inject[2], Inject_actual_4: event.target.value },
        { ...Inject[3], Inject_actual_4: event.target.value },
        { ...Inject[4], Inject_actual_4: event.target.value },
        { ...Inject[5], Inject_actual_4: event.target.value },
        { ...Inject[6], Inject_actual_4: event.target.value },
        { ...Inject[7], Inject_actual_4: event.target.value },
        { ...Inject[8], Inject_actual_4: event.target.value },
        { ...Inject[9], Inject_actual_4: event.target.value },
        { ...Inject[10], Inject_actual_4: event.target.value },
        { ...Inject[11], Inject_actual_4: event.target.value },
        { ...Inject[12], Inject_actual_4: event.target.value },
        { ...Inject[13], Inject_actual_4: event.target.value },
        { ...Inject[14], Inject_actual_4: event.target.value },
        { ...Inject[15], Inject_actual_4: event.target.value },
        { ...Inject[16], Inject_actual_4: event.target.value },
        { ...Inject[17], Inject_actual_4: event.target.value },
        { ...Inject[18], Inject_actual_4: event.target.value },
        { ...Inject[19], Inject_actual_4: event.target.value },
        { ...Inject[20], Inject_actual_4: event.target.value },
        { ...Inject[21], Inject_actual_4: event.target.value },
        { ...Inject[22], Inject_actual_4: event.target.value },
        { ...Inject[23], Inject_actual_4: event.target.value },
        { ...Inject[24], Inject_actual_4: event.target.value },
        { ...Inject[25], Inject_actual_4: event.target.value },
        { ...Inject[26], Inject_actual_4: event.target.value },
        { ...Inject[27], Inject_actual_4: event.target.value },
      ]);
    } else if (event.target.value === "IDEAL") {
      setTracking([
        {
          ...Tracking[0],
          Track_19_pm: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[1]
            .substring(0, 5),
        },
        { ...Tracking[1], Track_19_pm: event.target.value },
        { ...Tracking[2], Track_19_pm: event.target.value },
        { ...Tracking[3], Track_19_pm: event.target.value },
        { ...Tracking[4], Track_19_pm: event.target.value },
      ]);
      setHeat([
        { ...Heat[0], Heat_actual_4: event.target.value },
        { ...Heat[1], Heat_actual_4: event.target.value },
        { ...Heat[2], Heat_actual_4: event.target.value },
        { ...Heat[3], Heat_actual_4: event.target.value },
        { ...Heat[4], Heat_actual_4: event.target.value },
        { ...Heat[5], Heat_actual_4: event.target.value },
        { ...Heat[6], Heat_actual_4: event.target.value },
        { ...Heat[7], Heat_actual_4: event.target.value },
        { ...Heat[8], Heat_actual_4: event.target.value },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_4: event.target.value },
        { ...Inject[1], Inject_actual_4: event.target.value },
        { ...Inject[2], Inject_actual_4: event.target.value },
        { ...Inject[3], Inject_actual_4: event.target.value },
        { ...Inject[4], Inject_actual_4: event.target.value },
        { ...Inject[5], Inject_actual_4: event.target.value },
        { ...Inject[6], Inject_actual_4: event.target.value },
        { ...Inject[7], Inject_actual_4: event.target.value },
        { ...Inject[8], Inject_actual_4: event.target.value },
        { ...Inject[9], Inject_actual_4: event.target.value },
        { ...Inject[10], Inject_actual_4: event.target.value },
        { ...Inject[11], Inject_actual_4: event.target.value },
        { ...Inject[12], Inject_actual_4: event.target.value },
        { ...Inject[13], Inject_actual_4: event.target.value },
        { ...Inject[14], Inject_actual_4: event.target.value },
        { ...Inject[15], Inject_actual_4: event.target.value },
        { ...Inject[16], Inject_actual_4: event.target.value },
        { ...Inject[17], Inject_actual_4: event.target.value },
        { ...Inject[18], Inject_actual_4: event.target.value },
        { ...Inject[19], Inject_actual_4: event.target.value },
        { ...Inject[20], Inject_actual_4: event.target.value },
        { ...Inject[21], Inject_actual_4: event.target.value },
        { ...Inject[22], Inject_actual_4: event.target.value },
        { ...Inject[23], Inject_actual_4: event.target.value },
        { ...Inject[24], Inject_actual_4: event.target.value },
        { ...Inject[25], Inject_actual_4: event.target.value },
        { ...Inject[26], Inject_actual_4: event.target.value },
        { ...Inject[27], Inject_actual_4: event.target.value },
      ]);
    } else {
      GetRecord(
        {
          _keyword_: "std_mld_params_by_part_pms",
          PrtNme: event.target.value,
          McNo: PMS[0].Mcno,
        },
        setSmp
      ).then((res) => {
        setTracking([
          {
            ...Tracking[0],
            Track_19_pm: new Date(
              new Date().getTime() - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .split("T")[1]
              .substring(0, 5),
          },
          { ...Tracking[1], Track_19_pm: res[0].PrtNme },
          { ...Tracking[2], Track_19_pm: res[0].Colour },
          { ...Tracking[3], Track_19_pm: res[0].MatNme },
          { ...Tracking[4], Track_19_pm: res[0].GrdNme },
        ]);
        setHeat([
          { ...Heat[0], Heat_actual_4: '' },
          { ...Heat[1], Heat_actual_4: '' },
          { ...Heat[2], Heat_actual_4: '' },
          { ...Heat[3], Heat_actual_4: '' },
          { ...Heat[4], Heat_actual_4: '' },
          { ...Heat[5], Heat_actual_4: '' },
          { ...Heat[6], Heat_actual_4: '' },
          { ...Heat[7], Heat_actual_4: '' },
          { ...Heat[8], Heat_actual_4: '' },
        ]);
        setInject([
          { ...Inject[0], Inject_actual_4: '' },
          { ...Inject[1], Inject_actual_4: '' },
          { ...Inject[2], Inject_actual_4: '' },
          { ...Inject[3], Inject_actual_4: '' },
          { ...Inject[4], Inject_actual_4: '' },
          { ...Inject[5], Inject_actual_4: '' },
          { ...Inject[6], Inject_actual_4: '' },
          { ...Inject[7], Inject_actual_4: '' },
          { ...Inject[8], Inject_actual_4: '' },
          { ...Inject[9], Inject_actual_4: '' },
          { ...Inject[10], Inject_actual_4: '' },
          { ...Inject[11], Inject_actual_4: '' },
          { ...Inject[12], Inject_actual_4: '' },
          { ...Inject[13], Inject_actual_4: '' },
          { ...Inject[14], Inject_actual_4: '' },
          { ...Inject[15], Inject_actual_4: '' },
          { ...Inject[16], Inject_actual_4: '' },
          { ...Inject[17], Inject_actual_4: '' },
          { ...Inject[18], Inject_actual_4: '' },
          { ...Inject[19], Inject_actual_4: '' },
          { ...Inject[20], Inject_actual_4: '' },
          { ...Inject[21], Inject_actual_4: '' },
          { ...Inject[22], Inject_actual_4: '' },
          { ...Inject[23], Inject_actual_4: '' },
          { ...Inject[24], Inject_actual_4: '' },
          { ...Inject[25], Inject_actual_4: '' },
          { ...Inject[26], Inject_actual_4: '' },
          { ...Inject[27], Inject_actual_4: '' },
        ]);
      });
    }
  }
  function HandlePart23Pm(event) {
    if (event.target.value === "MOULD CHANGE") {
      setInsert(["Track_23_pm", "Heat_actual_5", "Inject_actual_5"]);
      setTracking([
        {
          ...Tracking[0],
          Track_23_pm: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[1]
            .substring(0, 5),
        },
        { ...Tracking[1], Track_23_pm: event.target.value },
        { ...Tracking[2], Track_23_pm: event.target.value },
        { ...Tracking[3], Track_23_pm: event.target.value },
        { ...Tracking[4], Track_23_pm: event.target.value },
      ]);
      setHeat([
        { ...Heat[0], Heat_actual_5: event.target.value },
        { ...Heat[1], Heat_actual_5: event.target.value },
        { ...Heat[2], Heat_actual_5: event.target.value },
        { ...Heat[3], Heat_actual_5: event.target.value },
        { ...Heat[4], Heat_actual_5: event.target.value },
        { ...Heat[5], Heat_actual_5: event.target.value },
        { ...Heat[6], Heat_actual_5: event.target.value },
        { ...Heat[7], Heat_actual_5: event.target.value },
        { ...Heat[8], Heat_actual_5: event.target.value },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_5: event.target.value },
        { ...Inject[1], Inject_actual_5: event.target.value },
        { ...Inject[2], Inject_actual_5: event.target.value },
        { ...Inject[3], Inject_actual_5: event.target.value },
        { ...Inject[4], Inject_actual_5: event.target.value },
        { ...Inject[5], Inject_actual_5: event.target.value },
        { ...Inject[6], Inject_actual_5: event.target.value },
        { ...Inject[7], Inject_actual_5: event.target.value },
        { ...Inject[8], Inject_actual_5: event.target.value },
        { ...Inject[9], Inject_actual_5: event.target.value },
        { ...Inject[10], Inject_actual_5: event.target.value },
        { ...Inject[11], Inject_actual_5: event.target.value },
        { ...Inject[12], Inject_actual_5: event.target.value },
        { ...Inject[13], Inject_actual_5: event.target.value },
        { ...Inject[14], Inject_actual_5: event.target.value },
        { ...Inject[15], Inject_actual_5: event.target.value },
        { ...Inject[16], Inject_actual_5: event.target.value },
        { ...Inject[17], Inject_actual_5: event.target.value },
        { ...Inject[18], Inject_actual_5: event.target.value },
        { ...Inject[19], Inject_actual_5: event.target.value },
        { ...Inject[20], Inject_actual_5: event.target.value },
        { ...Inject[21], Inject_actual_5: event.target.value },
        { ...Inject[22], Inject_actual_5: event.target.value },
        { ...Inject[23], Inject_actual_5: event.target.value },
        { ...Inject[24], Inject_actual_5: event.target.value },
        { ...Inject[25], Inject_actual_5: event.target.value },
        { ...Inject[26], Inject_actual_5: event.target.value },
        { ...Inject[27], Inject_actual_5: event.target.value },
      ]);
    } else if (event.target.value === "IDEAL") {
      setTracking([
        {
          ...Tracking[0],
          Track_23_pm: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[1]
            .substring(0, 5),
        },
        { ...Tracking[1], Track_23_pm: event.target.value },
        { ...Tracking[2], Track_23_pm: event.target.value },
        { ...Tracking[3], Track_23_pm: event.target.value },
        { ...Tracking[4], Track_23_pm: event.target.value },
      ]);
      setHeat([
        { ...Heat[0], Heat_actual_5: event.target.value },
        { ...Heat[1], Heat_actual_5: event.target.value },
        { ...Heat[2], Heat_actual_5: event.target.value },
        { ...Heat[3], Heat_actual_5: event.target.value },
        { ...Heat[4], Heat_actual_5: event.target.value },
        { ...Heat[5], Heat_actual_5: event.target.value },
        { ...Heat[6], Heat_actual_5: event.target.value },
        { ...Heat[7], Heat_actual_5: event.target.value },
        { ...Heat[8], Heat_actual_5: event.target.value },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_5: event.target.value },
        { ...Inject[1], Inject_actual_5: event.target.value },
        { ...Inject[2], Inject_actual_5: event.target.value },
        { ...Inject[3], Inject_actual_5: event.target.value },
        { ...Inject[4], Inject_actual_5: event.target.value },
        { ...Inject[5], Inject_actual_5: event.target.value },
        { ...Inject[6], Inject_actual_5: event.target.value },
        { ...Inject[7], Inject_actual_5: event.target.value },
        { ...Inject[8], Inject_actual_5: event.target.value },
        { ...Inject[9], Inject_actual_5: event.target.value },
        { ...Inject[10], Inject_actual_5: event.target.value },
        { ...Inject[11], Inject_actual_5: event.target.value },
        { ...Inject[12], Inject_actual_5: event.target.value },
        { ...Inject[13], Inject_actual_5: event.target.value },
        { ...Inject[14], Inject_actual_5: event.target.value },
        { ...Inject[15], Inject_actual_5: event.target.value },
        { ...Inject[16], Inject_actual_5: event.target.value },
        { ...Inject[17], Inject_actual_5: event.target.value },
        { ...Inject[18], Inject_actual_5: event.target.value },
        { ...Inject[19], Inject_actual_5: event.target.value },
        { ...Inject[20], Inject_actual_5: event.target.value },
        { ...Inject[21], Inject_actual_5: event.target.value },
        { ...Inject[22], Inject_actual_5: event.target.value },
        { ...Inject[23], Inject_actual_5: event.target.value },
        { ...Inject[24], Inject_actual_5: event.target.value },
        { ...Inject[25], Inject_actual_5: event.target.value },
        { ...Inject[26], Inject_actual_5: event.target.value },
        { ...Inject[27], Inject_actual_5: event.target.value },
      ]);
    } else {
      GetRecord(
        {
          _keyword_: "std_mld_params_by_part_pms",
          PrtNme: event.target.value,
          McNo: PMS[0].Mcno,
        },
        setSmp
      ).then((res) => {
        setTracking([
          {
            ...Tracking[0],
            Track_23_pm: new Date(
              new Date().getTime() - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .split("T")[1]
              .substring(0, 5),
          },
          { ...Tracking[1], Track_23_pm: res[0].PrtNme },
          { ...Tracking[2], Track_23_pm: res[0].Colour },
          { ...Tracking[3], Track_23_pm: res[0].MatNme },
          { ...Tracking[4], Track_23_pm: res[0].GrdNme },
        ]);
        setHeat([
          { ...Heat[0], Heat_actual_5: '' },
          { ...Heat[1], Heat_actual_5: '' },
          { ...Heat[2], Heat_actual_5: '' },
          { ...Heat[3], Heat_actual_5: '' },
          { ...Heat[4], Heat_actual_5: '' },
          { ...Heat[5], Heat_actual_5: '' },
          { ...Heat[6], Heat_actual_5: '' },
          { ...Heat[7], Heat_actual_5: '' },
          { ...Heat[8], Heat_actual_5: '' },
        ]);
        setInject([
          { ...Inject[0], Inject_actual_5: '' },
          { ...Inject[1], Inject_actual_5: '' },
          { ...Inject[2], Inject_actual_5: '' },
          { ...Inject[3], Inject_actual_5: '' },
          { ...Inject[4], Inject_actual_5: '' },
          { ...Inject[5], Inject_actual_5: '' },
          { ...Inject[6], Inject_actual_5: '' },
          { ...Inject[7], Inject_actual_5: '' },
          { ...Inject[8], Inject_actual_5: '' },
          { ...Inject[9], Inject_actual_5: '' },
          { ...Inject[10], Inject_actual_5: '' },
          { ...Inject[11], Inject_actual_5: '' },
          { ...Inject[12], Inject_actual_5: '' },
          { ...Inject[13], Inject_actual_5: '' },
          { ...Inject[14], Inject_actual_5: '' },
          { ...Inject[15], Inject_actual_5: '' },
          { ...Inject[16], Inject_actual_5: '' },
          { ...Inject[17], Inject_actual_5: '' },
          { ...Inject[18], Inject_actual_5: '' },
          { ...Inject[19], Inject_actual_5: '' },
          { ...Inject[20], Inject_actual_5: '' },
          { ...Inject[21], Inject_actual_5: '' },
          { ...Inject[22], Inject_actual_5: '' },
          { ...Inject[23], Inject_actual_5: '' },
          { ...Inject[24], Inject_actual_5: '' },
          { ...Inject[25], Inject_actual_5: '' },
          { ...Inject[26], Inject_actual_5: '' },
          { ...Inject[27], Inject_actual_5: '' },
        ]);
      });
    }
  }
  function HandlePart3Am(event) {
    if (event.target.value === "MOULD CHANGE") {
      setInsert(["Track_3_am", "Heat_actual_6", "Inject_actual_6"]);
      setTracking([
        {
          ...Tracking[0],
          Track_3_am: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[1]
            .substring(0, 5),
        },
        { ...Tracking[1], Track_3_am: event.target.value },
        { ...Tracking[2], Track_3_am: event.target.value },
        { ...Tracking[3], Track_3_am: event.target.value },
        { ...Tracking[4], Track_3_am: event.target.value },
      ]);
      setHeat([
        { ...Heat[0], Heat_actual_6: event.target.value },
        { ...Heat[1], Heat_actual_6: event.target.value },
        { ...Heat[2], Heat_actual_6: event.target.value },
        { ...Heat[3], Heat_actual_6: event.target.value },
        { ...Heat[4], Heat_actual_6: event.target.value },
        { ...Heat[5], Heat_actual_6: event.target.value },
        { ...Heat[6], Heat_actual_6: event.target.value },
        { ...Heat[7], Heat_actual_6: event.target.value },
        { ...Heat[8], Heat_actual_6: event.target.value },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_6: event.target.value },
        { ...Inject[1], Inject_actual_6: event.target.value },
        { ...Inject[2], Inject_actual_6: event.target.value },
        { ...Inject[3], Inject_actual_6: event.target.value },
        { ...Inject[4], Inject_actual_6: event.target.value },
        { ...Inject[5], Inject_actual_6: event.target.value },
        { ...Inject[6], Inject_actual_6: event.target.value },
        { ...Inject[7], Inject_actual_6: event.target.value },
        { ...Inject[8], Inject_actual_6: event.target.value },
        { ...Inject[9], Inject_actual_6: event.target.value },
        { ...Inject[10], Inject_actual_6: event.target.value },
        { ...Inject[11], Inject_actual_6: event.target.value },
        { ...Inject[12], Inject_actual_6: event.target.value },
        { ...Inject[13], Inject_actual_6: event.target.value },
        { ...Inject[14], Inject_actual_6: event.target.value },
        { ...Inject[15], Inject_actual_6: event.target.value },
        { ...Inject[16], Inject_actual_6: event.target.value },
        { ...Inject[17], Inject_actual_6: event.target.value },
        { ...Inject[18], Inject_actual_6: event.target.value },
        { ...Inject[19], Inject_actual_6: event.target.value },
        { ...Inject[20], Inject_actual_6: event.target.value },
        { ...Inject[21], Inject_actual_6: event.target.value },
        { ...Inject[22], Inject_actual_6: event.target.value },
        { ...Inject[23], Inject_actual_6: event.target.value },
        { ...Inject[24], Inject_actual_6: event.target.value },
        { ...Inject[25], Inject_actual_6: event.target.value },
        { ...Inject[26], Inject_actual_6: event.target.value },
        { ...Inject[27], Inject_actual_6: event.target.value },
      ]);
    } else if (event.target.value === "IDEAL") {
      setTracking([
        {
          ...Tracking[0],
          Track_3_am: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[1]
            .substring(0, 5),
        },
        { ...Tracking[1], Track_3_am: event.target.value },
        { ...Tracking[2], Track_3_am: event.target.value },
        { ...Tracking[3], Track_3_am: event.target.value },
        { ...Tracking[4], Track_3_am: event.target.value },
      ]);
      setHeat([
        { ...Heat[0], Heat_actual_6: event.target.value },
        { ...Heat[1], Heat_actual_6: event.target.value },
        { ...Heat[2], Heat_actual_6: event.target.value },
        { ...Heat[3], Heat_actual_6: event.target.value },
        { ...Heat[4], Heat_actual_6: event.target.value },
        { ...Heat[5], Heat_actual_6: event.target.value },
        { ...Heat[6], Heat_actual_6: event.target.value },
        { ...Heat[7], Heat_actual_6: event.target.value },
        { ...Heat[8], Heat_actual_6: event.target.value },
      ]);
      setInject([
        { ...Inject[0], Inject_actual_6: event.target.value },
        { ...Inject[1], Inject_actual_6: event.target.value },
        { ...Inject[2], Inject_actual_6: event.target.value },
        { ...Inject[3], Inject_actual_6: event.target.value },
        { ...Inject[4], Inject_actual_6: event.target.value },
        { ...Inject[5], Inject_actual_6: event.target.value },
        { ...Inject[6], Inject_actual_6: event.target.value },
        { ...Inject[7], Inject_actual_6: event.target.value },
        { ...Inject[8], Inject_actual_6: event.target.value },
        { ...Inject[9], Inject_actual_6: event.target.value },
        { ...Inject[10], Inject_actual_6: event.target.value },
        { ...Inject[11], Inject_actual_6: event.target.value },
        { ...Inject[12], Inject_actual_6: event.target.value },
        { ...Inject[13], Inject_actual_6: event.target.value },
        { ...Inject[14], Inject_actual_6: event.target.value },
        { ...Inject[15], Inject_actual_6: event.target.value },
        { ...Inject[16], Inject_actual_6: event.target.value },
        { ...Inject[17], Inject_actual_6: event.target.value },
        { ...Inject[18], Inject_actual_6: event.target.value },
        { ...Inject[19], Inject_actual_6: event.target.value },
        { ...Inject[20], Inject_actual_6: event.target.value },
        { ...Inject[21], Inject_actual_6: event.target.value },
        { ...Inject[22], Inject_actual_6: event.target.value },
        { ...Inject[23], Inject_actual_6: event.target.value },
        { ...Inject[24], Inject_actual_6: event.target.value },
        { ...Inject[25], Inject_actual_6: event.target.value },
        { ...Inject[26], Inject_actual_6: event.target.value },
        { ...Inject[27], Inject_actual_6: event.target.value },
      ]);
    } else {
      GetRecord(
        {
          _keyword_: "std_mld_params_by_part_pms",
          PrtNme: event.target.value,
          McNo: PMS[0].Mcno,
        },
        setSmp
      ).then((res) => {
        setTracking([
          {
            ...Tracking[0],
            Track_3_am: new Date(
              new Date().getTime() - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .split("T")[1]
              .substring(0, 5),
          },
          { ...Tracking[1], Track_3_am: res[0].PrtNme },
          { ...Tracking[2], Track_3_am: res[0].Colour },
          { ...Tracking[3], Track_3_am: res[0].MatNme },
          { ...Tracking[4], Track_3_am: res[0].GrdNme },
        ]);
        setHeat([
          { ...Heat[0], Heat_actual_6: '' },
          { ...Heat[1], Heat_actual_6: '' },
          { ...Heat[2], Heat_actual_6: '' },
          { ...Heat[3], Heat_actual_6: '' },
          { ...Heat[4], Heat_actual_6: '' },
          { ...Heat[5], Heat_actual_6: '' },
          { ...Heat[6], Heat_actual_6: '' },
          { ...Heat[7], Heat_actual_6: '' },
          { ...Heat[8], Heat_actual_6: '' },
        ]);
        setInject([
          { ...Inject[0], Inject_actual_6: '' },
          { ...Inject[1], Inject_actual_6: '' },
          { ...Inject[2], Inject_actual_6: '' },
          { ...Inject[3], Inject_actual_6: '' },
          { ...Inject[4], Inject_actual_6: '' },
          { ...Inject[5], Inject_actual_6: '' },
          { ...Inject[6], Inject_actual_6: '' },
          { ...Inject[7], Inject_actual_6: '' },
          { ...Inject[8], Inject_actual_6: '' },
          { ...Inject[9], Inject_actual_6: '' },
          { ...Inject[10], Inject_actual_6: '' },
          { ...Inject[11], Inject_actual_6: '' },
          { ...Inject[12], Inject_actual_6: '' },
          { ...Inject[13], Inject_actual_6: '' },
          { ...Inject[14], Inject_actual_6: '' },
          { ...Inject[15], Inject_actual_6: '' },
          { ...Inject[16], Inject_actual_6: '' },
          { ...Inject[17], Inject_actual_6: '' },
          { ...Inject[18], Inject_actual_6: '' },
          { ...Inject[19], Inject_actual_6: '' },
          { ...Inject[20], Inject_actual_6: '' },
          { ...Inject[21], Inject_actual_6: '' },
          { ...Inject[22], Inject_actual_6: '' },
          { ...Inject[23], Inject_actual_6: '' },
          { ...Inject[24], Inject_actual_6: '' },
          { ...Inject[25], Inject_actual_6: '' },
          { ...Inject[26], Inject_actual_6: '' },
          { ...Inject[27], Inject_actual_6: '' },
        ]);
      });
    }
  }
  async function gettracking() {
    console.log("GET Tracking ---------------------------------------");
    GetCurrentTime();
    let result = await GetRecord(
      { _keyword_: "Get_Tracking_by_PmsID", Pms_id: PMS[0].Id },
      setTracking
    );
    if (result === "NoRecordFound") {
      setTracking([
        { ...initTracking, Number: "1" },
        { ...initTracking, Number: "2" },
        { ...initTracking, Number: "3" },
        { ...initTracking, Number: "4" },
        { ...initTracking, Number: "5" },
      ]);
    }
    // Get Heat Parameter Data
    result = await GetRecord(
      { _keyword_: "Get_HeatParams_by_PmsId", Pms_id: PMS[0].Id },
      setHeat
    );
    if (result === "NoRecordFound") {
      setHeat([
        { ...initHeat, Heat_sl: "1" },
        { ...initHeat, Heat_sl: "2" },
        { ...initHeat, Heat_sl: "3" },
        { ...initHeat, Heat_sl: "4" },
        { ...initHeat, Heat_sl: "5" },
        { ...initHeat, Heat_sl: "6" },
        { ...initHeat, Heat_sl: "7" },
        { ...initHeat, Heat_sl: "8" },
        { ...initHeat, Heat_sl: "9" },
      ]);
    }
    // Get Inject parameter data
    result = await GetRecord(
      { _keyword_: "Get_inject_by_PmsId", Pms_id: PMS[0].Id },
      setInject
    );
    if (result === "NoRecordFound") {
      setInject([
        {
          ...initInject,
          Inject_sl: "1",
          Inject_desc: "Cycle time",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "2",
          Inject_desc: "Cooling time",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "3",
          Inject_desc: "Injection time - 1",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "4",
          Inject_desc: "Injection time - 2",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "5",
          Inject_desc: "Injection time - 3",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "6",
          Inject_desc: "Injection time - 4",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "7",
          Inject_desc: "Injection-Pressure-1",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "8",
          Inject_desc: "Injection-Pressure-2",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "9",
          Inject_desc: "Injection-Pressure-3",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "10",
          Inject_desc: "Injection-Pressure-4",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "11",
          Inject_desc: "Injection speed 1",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "12",
          Inject_desc: "Injection speed 2",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "13",
          Inject_desc: "Injection speed 3",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "14",
          Inject_desc: "Injection speed 4",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "15",
          Inject_desc: "Holding pressure-1",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "16",
          Inject_desc: "Holding pressure-2",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "17",
          Inject_desc: "Holding pressure-3",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "18",
          Inject_desc: "Holding pressure-4",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "19",
          Inject_desc: "Holding speed-1",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "20",
          Inject_desc: "Holding speed-2",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "21",
          Inject_desc: "Holding speed-3",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "22",
          Inject_desc: "Holding speed-4",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "23",
          Inject_desc: "Holding time-1",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "24",
          Inject_desc: "Holding time-2",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "25",
          Inject_desc: "Holding time-3",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "26",
          Inject_desc: "Holding time-4",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "27",
          Inject_desc: "Shot size",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
        {
          ...initInject,
          Inject_sl: "28",
          Inject_desc: "Cushion",
          Inject_inst_used: "On pannel",
          Inject_units: "SEC",
        },
      ]);
    }
  }

  useEffect(() => {
    GetRecord({ _keyword_: "Get_SMP_MachineNumber" }, setMachine);
    GetRecord({ _keyword_: "emp_details" }, setempDetails);
  }, []);


  useEffect(() => {
    GetRecord(
      {
        _keyword_: "Get_PartName_basedOn_MachineNo_form_SMP",
        Mcno: PMS[0].Mcno,
      },
      setPart
    );
    console.log("Pms updated", PMS);
  }, [PMS[0].Mcno]);

  useEffect(() => {
    NewInsert ? handleInsertcreate() : gettracking();
  }, [PMS[0].Id]);
  // useEffect(() => {
  //   setInject((data) => {
  //     let Value = [...Inject];
  //     for (let index = 0; index < Inject.length; index++) {
  //       Value[index] = {
  //         ...Inject[index],
  //         Inject_smp_data: Smp[0][injectvalue[index].key],
  //         Inject_smp_set: Smp[0][injectvalue[index].value],
  //       };
  //     }
  //     return Value;
  //   });
  //   setHeat((data) => {
  //     let Value = [...Heat];
  //     for (let index = 0; index < Heat.length; index++) {
  //       Value[index] = {
  //         ...Inject[index],
  //         Heat_smp_data: Smp[0][Heatvalue[index].key],
  //         Heat_smp_set: Smp[0][Heatvalue[index].value],
  //       };
  //     }
  //     return Value;
  //   });
  // }, [Smp]);

  const handleCreate = (e) => {
    const currentDate = new Date().toISOString().split("T")[0];
    e.preventDefault();
    // const isSubmissionSuccessful = true;
    const data1 = {
      data: PMS,
      _keyword_: "pro_mon_sys_parent",
      secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
    };
    axios
      .post(
        `http://${API_URL}/GenericTransactionService/processTransaction`,
        data1
      )
      .then((response1) => {
        // console.log(response1);
        let Pms_id = response1.data.ROWID;
        setHeat([
          { ...Heat[0], Pms_id: Pms_id },
          { ...Heat[1], Pms_id: Pms_id },
          { ...Heat[2], Pms_id: Pms_id },
          { ...Heat[3], Pms_id: Pms_id },
          { ...Heat[4], Pms_id: Pms_id },
          { ...Heat[5], Pms_id: Pms_id },
          { ...Heat[6], Pms_id: Pms_id },
          { ...Heat[7], Pms_id: Pms_id },
          { ...Heat[8], Pms_id: Pms_id },
        ]);
        setTracking([
          { ...Tracking[0], Pms_id: Pms_id },
          { ...Tracking[1], Pms_id: Pms_id },
          { ...Tracking[2], Pms_id: Pms_id },
          { ...Tracking[3], Pms_id: Pms_id },
          { ...Tracking[4], Pms_id: Pms_id },
        ]);
        setInject([
          { ...Inject[0], Pms_id: Pms_id },
          { ...Inject[1], Pms_id: Pms_id },
          { ...Inject[2], Pms_id: Pms_id },
          { ...Inject[3], Pms_id: Pms_id },
          { ...Inject[4], Pms_id: Pms_id },
          { ...Inject[5], Pms_id: Pms_id },
          { ...Inject[6], Pms_id: Pms_id },
          { ...Inject[7], Pms_id: Pms_id },
          { ...Inject[8], Pms_id: Pms_id },
          { ...Inject[9], Pms_id: Pms_id },
          { ...Inject[10], Pms_id: Pms_id },
          { ...Inject[11], Pms_id: Pms_id },
          { ...Inject[12], Pms_id: Pms_id },
          { ...Inject[13], Pms_id: Pms_id },
          { ...Inject[14], Pms_id: Pms_id },
          { ...Inject[15], Pms_id: Pms_id },
          { ...Inject[16], Pms_id: Pms_id },
          { ...Inject[17], Pms_id: Pms_id },
          { ...Inject[18], Pms_id: Pms_id },
          { ...Inject[19], Pms_id: Pms_id },
          { ...Inject[20], Pms_id: Pms_id },
          { ...Inject[21], Pms_id: Pms_id },
          { ...Inject[22], Pms_id: Pms_id },
          { ...Inject[23], Pms_id: Pms_id },
          { ...Inject[24], Pms_id: Pms_id },
          { ...Inject[25], Pms_id: Pms_id },
          { ...Inject[26], Pms_id: Pms_id },
          { ...Inject[27], Pms_id: Pms_id },
        ]);
        setsubmit(true);
      })
      .catch((error) => {
        console.log(error);
        alert("Submission failed.");
        window.location.reload();
      });
  };

  useEffect(() => {
    console.log("EmpName update - ", EmpName);
  }, [EmpName]);

  useEffect(() => {
    axios
      .post(`http://${API_URL}/GenericTransactionService/processTransaction`, {
        data: Heat,
        _keyword_: "pro_mon_sys_heat_parameter",
        secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
      })
      .then((res) => {
        // console.log(Heat,"Heat res",res);
      });
    axios
      .post(`http://${API_URL}/GenericTransactionService/processTransaction`, {
        data: Tracking,
        _keyword_: "pro_mon_sys_tracking_machine",
        secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
      })
      .then((res) => {
        // console.log(Tracking,"Tracking res",res);
      });
    axios
      .post(`http://${API_URL}/GenericTransactionService/processTransaction`, {
        data: Inject,
        _keyword_: "pro_mon_sys_inject_parameter",
        secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
      })
      .then((res) => {
        // console.log(Inject,"Inject res",res);
      });
    PMS[0].Mcno &&
      alert(
        "The PMS record for Machine No - " + PMS[0].Mcno + ", Has Been Created"
      );
    reset();
    // setNew(false);
  }, [submit]);

  const handleUpdate = (e) => {
    e.preventDefault();
    const data1 = {
      data: PMS,
      _keyword_: "pro_mon_sys_parent",
      secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
    };
    axios
      .post(
        `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
        data1
      )
      .then((response1) => {
        axios
          .post(
            `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
            {
              data: Heat,
              _keyword_: "pro_mon_sys_heat_parameter",
              secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
            }
          )
          .then((res) => {
            // console.log("Heat res",res);
          });
        axios
          .post(
            `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
            {
              data: Tracking,
              _keyword_: "pro_mon_sys_tracking_machine",
              secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
            }
          )
          .then((res) => {
            // console.log("Tracking res",res);
          });
        axios
          .post(
            `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
            {
              data: Inject,
              _keyword_: "pro_mon_sys_inject_parameter",
              secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
            }
          )
          .then((res) => {
            // console.log("Inject res",res);
          });
      })
      .catch((error) => {
        // console.log(error);
        alert("Submission failed.");
        window.location.reload();
      });
    PMS[0].Mcno &&
      alert(
        "The PMS record for MacHine No - " + PMS[0].Mcno + ", Has Been Updated"
      );
    reset();
  };

  const handleUpdateCreate = async (e) => {
    e.preventDefault();
    const data1 = {
      data: PMS,
      _keyword_: "pro_mon_sys_parent",
      secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
    };
    axios
      .post(
        `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
        data1
      )
      .then((response1) => {
        axios
          .post(
            `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
            {
              data: Heat,
              _keyword_: "pro_mon_sys_heat_parameter",
              secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
            }
          )
          .then((res) => {
            // console.log("Heat insert",res);
          });
        axios
          .post(
            `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
            {
              data: Tracking,
              _keyword_: "pro_mon_sys_tracking_machine",
              secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
            }
          )
          .then((res) => {
            // console.log("Tracking insert",res);
          });
        axios
          .post(
            `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
            {
              data: Inject,
              _keyword_: "pro_mon_sys_inject_parameter",
              secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
            }
          )
          .then((res) => {
            // console.log("Inject insert",res);
          });
        PMS[0].Mcno &&
          alert(
            "The PMS record for MacHine No - " +
            PMS[0].Mcno +
            ", Has Been Updated"
          );
        setNewInsert(true);
      })
      .catch((error) => {
        console.log(error);
        alert("Submission failed.");
      });
  };
  useEffect(() => {
    setPMS([
      {
        ...initMachine,
        Mcno: PMS[0].Mcno,
        mc_name: PMS[0].mc_name,
        TrackDate: new Date(
          new Date().getTime() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0],
      },
    ]);
    setTracking([
      { ...initTracking, [Insert[0]]: "MOULD CHANGE", Number: "1" },
      { ...initTracking, [Insert[0]]: "MOULD CHANGE", Number: "2" },
      { ...initTracking, [Insert[0]]: "MOULD CHANGE", Number: "3" },
      { ...initTracking, [Insert[0]]: "MOULD CHANGE", Number: "4" },
      { ...initTracking, [Insert[0]]: "MOULD CHANGE", Number: "5" },
    ]);
    setHeat([
      { ...initHeat, [Insert[1]]: "MOULD CHANGE", Heat_sl: "1" },
      { ...initHeat, [Insert[1]]: "MOULD CHANGE", Heat_sl: "2" },
      { ...initHeat, [Insert[1]]: "MOULD CHANGE", Heat_sl: "3" },
      { ...initHeat, [Insert[1]]: "MOULD CHANGE", Heat_sl: "4" },
      { ...initHeat, [Insert[1]]: "MOULD CHANGE", Heat_sl: "5" },
      { ...initHeat, [Insert[1]]: "MOULD CHANGE", Heat_sl: "6" },
      { ...initHeat, [Insert[1]]: "MOULD CHANGE", Heat_sl: "7" },
      { ...initHeat, [Insert[1]]: "MOULD CHANGE", Heat_sl: "8" },
      { ...initHeat, [Insert[1]]: "MOULD CHANGE", Heat_sl: "9" },
    ]);
    setInject([
      {
        ...initInject,
        Inject_sl: "1",
        Inject_desc: "Cycle time",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "2",
        Inject_desc: "Cooling time",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "3",
        Inject_desc: "Injection time - 1",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "4",
        Inject_desc: "Injection time - 2",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "5",
        Inject_desc: "Injection time - 3",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "6",
        Inject_desc: "Injection time - 4",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "7",
        Inject_desc: "Injection-Pressure-1",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "8",
        Inject_desc: "Injection-Pressure-2",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "9",
        Inject_desc: "Injection-Pressure-3",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "10",
        Inject_desc: "Injection-Pressure-4",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "11",
        Inject_desc: "Injection speed 1",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "12",
        Inject_desc: "Injection speed 2",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "13",
        Inject_desc: "Injection speed 3",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "14",
        Inject_desc: "Injection speed 4",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "15",
        Inject_desc: "Holding pressure-1",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "16",
        Inject_desc: "Holding pressure-2",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "17",
        Inject_desc: "Holding pressure-3",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "18",
        Inject_desc: "Holding pressure-4",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "19",
        Inject_desc: "Holding speed-1",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "20",
        Inject_desc: "Holding speed-2",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "21",
        Inject_desc: "Holding speed-3",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "22",
        Inject_desc: "Holding speed-4",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "23",
        Inject_desc: "Holding time-1",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "24",
        Inject_desc: "Holding time-2",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "25",
        Inject_desc: "Holding time-3",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "26",
        Inject_desc: "Holding time-4",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "27",
        Inject_desc: "Shot size",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
      {
        ...initInject,
        Inject_sl: "28",
        Inject_desc: "Cushion",
        Inject_inst_used: "On pannel",
        Inject_units: "SEC",
        [Insert[2]]: "MOULD CHANGE",
      },
    ]);
  }, [NewInsert]);

  const handleInsertcreate = () => {
    console.log("track", Tracking);
    const data1 = {
      data: PMS,
      _keyword_: "pro_mon_sys_parent",
      secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
    };
    axios
      .post(
        `http://${API_URL}/GenericTransactionService/processTransaction`,
        data1
      )
      .then((response1) => {
        console.log(response1);
        let Pms_id = response1.data.ROWID;
        setHeat([
          { ...Heat[0], Pms_id: Pms_id },
          { ...Heat[1], Pms_id: Pms_id },
          { ...Heat[2], Pms_id: Pms_id },
          { ...Heat[3], Pms_id: Pms_id },
          { ...Heat[4], Pms_id: Pms_id },
          { ...Heat[5], Pms_id: Pms_id },
          { ...Heat[6], Pms_id: Pms_id },
          { ...Heat[7], Pms_id: Pms_id },
          { ...Heat[8], Pms_id: Pms_id },
        ]);
        setTracking([
          { ...Tracking[0], Pms_id: Pms_id },
          { ...Tracking[1], Pms_id: Pms_id },
          { ...Tracking[2], Pms_id: Pms_id },
          { ...Tracking[3], Pms_id: Pms_id },
          { ...Tracking[4], Pms_id: Pms_id },
        ]);
        setInject([
          { ...Inject[0], Pms_id: Pms_id },
          { ...Inject[1], Pms_id: Pms_id },
          { ...Inject[2], Pms_id: Pms_id },
          { ...Inject[3], Pms_id: Pms_id },
          { ...Inject[4], Pms_id: Pms_id },
          { ...Inject[5], Pms_id: Pms_id },
          { ...Inject[6], Pms_id: Pms_id },
          { ...Inject[7], Pms_id: Pms_id },
          { ...Inject[8], Pms_id: Pms_id },
          { ...Inject[9], Pms_id: Pms_id },
          { ...Inject[10], Pms_id: Pms_id },
          { ...Inject[11], Pms_id: Pms_id },
          { ...Inject[12], Pms_id: Pms_id },
          { ...Inject[13], Pms_id: Pms_id },
          { ...Inject[14], Pms_id: Pms_id },
          { ...Inject[15], Pms_id: Pms_id },
          { ...Inject[16], Pms_id: Pms_id },
          { ...Inject[17], Pms_id: Pms_id },
          { ...Inject[18], Pms_id: Pms_id },
          { ...Inject[19], Pms_id: Pms_id },
          { ...Inject[20], Pms_id: Pms_id },
          { ...Inject[21], Pms_id: Pms_id },
          { ...Inject[22], Pms_id: Pms_id },
          { ...Inject[23], Pms_id: Pms_id },
          { ...Inject[24], Pms_id: Pms_id },
          { ...Inject[25], Pms_id: Pms_id },
          { ...Inject[26], Pms_id: Pms_id },
          { ...Inject[27], Pms_id: Pms_id },
        ]);
        setsubmit(true);
        return Pms_id;
      })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
        alert("Submission failed.");
        window.location.reload();
      });
  };
  function reset() {
    setHeat([
      initHeat,
      initHeat,
      initHeat,
      initHeat,
      initHeat,
      initHeat,
      initHeat,
      initHeat,
      initHeat,
    ]);
    setInject([
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
      initInject,
    ]);
    setTracking([
      initTracking,
      initTracking,
      initTracking,
      initTracking,
      initTracking,
    ]);
    setPMS([initMachine]);
    setEmpName(initEmpName);
    setNew();
    setNewInsert();
    setsubmit();
    setButtonPopup(false)
    setButtonPopup1(false)
  }

  // get current data and time
  function GetCurrentTime() {
    let time = new Date();
    time = time.getHours() + ":" + time.getMinutes();
    console.log(time > "6:30" && time < "8:30", time);
    setDisable({
      ...Disable,
      // ,Hour1:!(time>"6:30"&&time<"8:30")
      // ,Hour2:!(time>"10:30"&&time<"12:30")
      // ,Hour3:!(time>"14:30"&&time<"16:30")
      // ,Hour4:!(time>"18:30"&&time<"20:30")
      // ,Hour5:!(time>"22:30"&&time<"1:30")
      // ,Hour6:!(time>"2:30"&&time<"4:30")
      Hour1: !true,
      Hour2: !true,
      Hour3: !true,
      Hour4: !true,
      Hour5: !true,
      Hour6: !true,
    });
  }

  const viewPagination = () => {
    setPaginationVisible(true);
  };

  useEffect(() => {
    GetRecord({ _keyword_: "Get_PMS_Records_for_ListView" }, setpro_mon_sys);

    GetRecord({ _keyword_: "emp_details" }, setempDetails);
  }, []);

  useEffect(() => {
    const getPartValues = (partCode) => {
      if (partCode !== "") {
        axios
          .post(`http://${API_URL}/GenericResultBuilderService/buildResults`, {
            _keyword_: "mld_params_by_part",
            mc_part_code: partCode,
          })
          .then((response) => {
            if (response.data.data.length > 0) {
              // setpartWeight(response.data.data[0].PrtWgt);
              // setMTC(response.data.data[0].MTC);
              // setHopp(response.data.data[0].Hopp);
              // setHozz(response.data.data[0].Hozz);
              // setZone1(response.data.data[0].Zone1);
              // setZone2(response.data.data[0].Zone2);
              // setZone3(response.data.data[0].Zone3);
              // setCycletme(response.data.data[0].Cycletme);
              // setCooltme(response.data.data[0].Cooltme);
              // setInj104(response.data.data[0].Inj104);
              // setInj101(response.data.data[0].Inj101);
              // setInj201(response.data.data[0].Inj201);
              // setInj301(response.data.data[0].Inj301);
              // setInj102(response.data.data[0].Inj102);
              // setInj202(response.data.data[0].Inj202);
              // setInj302(response.data.data[0].Inj302);
              // setHold101(response.data.data[0].Hold101);
              // setHold201(response.data.data[0].Hold201);
              // setHold401(response.data.data[0].Hold401);
              // setRefillEnd(response.data.data[0].RefillEnd);
              // setMelt101(response.data.data[0].Melt101);
              // setPrtSet(response.data.data[0].PrtSet);
              // setMtcSet(response.data.data[0].MtcSet);
              // setHoppSet(response.data.data[0].HoppSet);
              // setNozzSet(response.data.data[0].NozzSet);
              // setZneSet1(response.data.data[0].ZneSet1);
              // setZneSet2(response.data.data[0].ZneSet2);
              // setZneSet3(response.data.data[0].ZneSet3);
              // setCycTmeSet(response.data.data[0].CycTmeSet);
              // setCoolTmeSet(response.data.data[0].CoolTmeSet);
              // setInjTmeSet(response.data.data[0].InjTmeSet);
              // setInjPresSet(response.data.data[0].InjPresSet);
              // setInjSpdSet(response.data.data[0].InjSpdSet);
              // setReFillPosSet(response.data.data[0].ReFillPosSet);

              // setCusSet(response.data.data[0].CusSet);
              // setMelt104(response.data.data[0].Melt104);
              // setCoreSet(response.data.data[0].CoreSet);
              // setCore(response.data.data[0].Core);
              // setCavity(response.data.data[0].Cavity);
              // setCavSet(response.data.data[0].CavSet);
              setpartWeight(response.data.data[0].PrtWgt);
              setMTC(response.data.data[0].MTC);
              setHopp(response.data.data[0].Hopp);
              setHozz(response.data.data[0].Hozz);
              setZone1(response.data.data[0].Zone1);
              setZone2(response.data.data[0].Zone2);
              setZone3(response.data.data[0].Zone3);
              setCycletme(response.data.data[0].Cycletme);
              setCooltme(response.data.data[0].Cooltme);
              setInj104(response.data.data[0].Inj104);
              setInj101(response.data.data[0].Inj101);
              setInj201(response.data.data[0].Inj201);
              setInj301(response.data.data[0].Inj301);
              setInj102(response.data.data[0].Inj102);
              setInj202(response.data.data[0].Inj202);
              setInj302(response.data.data[0].Inj302);
              setHold101(response.data.data[0].Hold101);
              setHold201(response.data.data[0].Hold201);
              setHold401(response.data.data[0].Hold401);
              setRefillEnd(response.data.data[0].RefillEnd);
              setMelt101(response.data.data[0].Melt101);
              setPrtSet(response.data.data[0].PrtSet);
              setMtcSet(response.data.data[0].MtcSet);
              setHoppSet(response.data.data[0].HoppSet);
              setNozzSet(response.data.data[0].NozzSet);
              setZneSet1(response.data.data[0].ZneSet1);
              setZneSet2(response.data.data[0].ZneSet2);
              setZneSet3(response.data.data[0].ZneSet3);
              setCycTmeSet(response.data.data[0].CycTmeSet);
              setCoolTmeSet(response.data.data[0].CoolTmeSet);
              setInjTmeSet(response.data.data[0].InjTmeSet);
              setInjPresSet(response.data.data[0].InjPresSet);
              setInjSpdSet(response.data.data[0].InjSpdSet);
              setReFillPosSet(response.data.data[0].ReFillPosSet);

              setCusSet(response.data.data[0].CusSet);
              setMelt104(response.data.data[0].Melt104);
              setCoreSet(response.data.data[0].CoreSet);
              setCore(response.data.data[0].Core);
              setCavity(response.data.data[0].Cavity);
              setCavSet(response.data.data[0].CavSet);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    //     if (PartName1 == "MOULDCHANGE" || PartName1 == "IDEAL") {
    //       getPartValues(PartName2);}
    //       else if (PartName2 == ""){
    //         getPartValues(PartName2);

    //     }
    //     else if (PartName3 == ""){
    //       getPartValues(PartName3);

    //   }
    //   else if (PartName4 !== ""){
    //     getPartValues(PartName4);

    // }
    // else if (PartName5 !== ""){
    //   getPartValues(PartName5);

    // }
    // else if (PartName6 !== ""){
    //   getPartValues(PartName6);

    // }
    if (PartName1 == "MOULDCHANGE" || PartName1 == "IDEAL") {
      if (PartName2 == "MOULDCHANGE" || PartName2 == "IDEAL") {
        if (PartName3 == "MOULDCHANGE" || PartName3 == "IDEAL") {
          if (PartName4 == "MOULDCHANGE" || PartName4 == "IDEAL") {
            if (PartName5 == "MOULDCHANGE" || PartName5 == "IDEAL") {
              getPartValues(PartName6);
            } else {
              getPartValues(PartName5);
            }
          } else {
            getPartValues(PartName4);
          }
        } else {
          getPartValues(PartName3);
        }
      } else {
        getPartValues(PartName2);
      }
    } else {
      getPartValues(PartName1);
    }
  }, [PartName1, PartName2, PartName3, PartName4, PartName5, PartName6]);

  const getMappedValues = (mcNo) => {
    console.log("Machine No: " + mcNo);
    axios
      .post(`http://${API_URL}/GenericResultBuilderService/buildResults`, {
        _keyword_: "std_mld_par-by-mcno",
        McNo: mcNo,
      })
      .then((response) => {
        setMappedValues(response.data.data[0]);
        console.log("Response Data ", response.data);
        console.log("Response Data.data[0] ", response.data.data[0]);
        console.log("MappedValues", MappedValues);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateFieldValue = (val, id, actual, tolerance) => {
    // if (MappedValues && MappedValues[mapping]) {
    // if (isNaN(val)) {
    //   // swal({
    //   //   title:"Note!",
    //   //   text:`Value ${val} is non-numeric.`,
    //   //   icon:"warning",
    //   //   button:"Okay",
    //   //   closeOnClickOutside: false,
    //   // })
    //    alert("Error: Value " + val + " is non-numeric.");
    //   return false;
    // }
    if (
      parseFloat(val) > parseFloat(actual) + parseFloat(tolerance) ||
      parseFloat(val) < parseFloat(actual) - parseFloat(tolerance)
    ) {
      // alert("Input value is not within the tolerance range.");
      const popupDiv = document.querySelector(".popup");
      const elementInsidePopup = document.querySelector("#" + id);
      if (elementInsidePopup) {
        elementInsidePopup.style.backgroundColor = "#d9756f";
        elementInsidePopup.style.border = "2px solid #DA251A";
      }
    } else {
      const popupDiv = document.querySelector(".popup");
      const elementInsidePopup = document.querySelector("#" + id);
      if (elementInsidePopup) {
        elementInsidePopup.style.backgroundColor = "white";
        elementInsidePopup.style.border = "none";
      }
    }
    // }
  };

  function handleShiftInchargeShiftA(event) {
    GetRecord(
      { _keyword_: "emp_details_by_empCode", emp_code: event.value },
      setemp
    ).then((res) => {
      // console.log("handleShiftInchargeShiftA res - ",res);
      setEmpName({
        ...EmpName,
        ShiftInchargeShiftA: res[0].Emp_code,
        ShiftInchargeShiftAEmp_name: res[0].Emp_name,
      });
      setPMS([{ ...PMS[0], ShiftInchargeShiftA: res[0].Emp_code }]);
    });
  }
  function handleShiftInchargeShiftB(event) {
    GetRecord(
      { _keyword_: "emp_details_by_empCode", emp_code: event.value },
      setemp
    ).then((res) => {
      setEmpName({
        ...EmpName,
        ShiftInchargeShiftB: res[0].Emp_code,
        ShiftInchargeShiftBEmp_name: res[0].Emp_name,
      });
      setPMS([{ ...PMS[0], ShiftInchargeShiftB: res[0].Emp_code }]);
    });
  }
  function handleShiftInchargeShiftC(event) {
    GetRecord(
      { _keyword_: "emp_details_by_empCode", emp_code: event.value },
      setemp
    ).then((res) => {
      setEmpName({
        ...EmpName,
        ShiftInchargeShiftC: res[0].Emp_code,
        ShiftInchargeShiftCEmp_name: res[0].Emp_name,
      });
      setPMS([{ ...PMS[0], ShiftInchargeShiftC: res[0].Emp_code }]);
    });
  }
  function handleLineInspecQaShiftA(event) {
    GetRecord(
      { _keyword_: "emp_details_by_empCode", emp_code: event.value },
      setemp
    ).then((res) => {
      setEmpName({
        ...EmpName,
        LineInspecQaShiftA: res[0].Emp_code,
        LineInspecQaShiftAEmp_name: res[0].Emp_name,
      });
      setPMS([{ ...PMS[0], LineInspecQaShiftA: res[0].Emp_code }]);
    });
  }
  function handleLineInspecQaShiftB(event) {
    GetRecord(
      { _keyword_: "emp_details_by_empCode", emp_code: event.value },
      setemp
    ).then((res) => {
      setEmpName({
        ...EmpName,
        LineInspecQaShiftB: res[0].Emp_code,
        LineInspecQaShiftBEmp_name: res[0].Emp_name,
      });
      setPMS([{ ...PMS[0], LineInspecQaShiftB: res[0].Emp_code }]);
    });
  }
  function handleLineInspecQaShiftC(event) {
    GetRecord(
      { _keyword_: "emp_details_by_empCode", emp_code: event.value },
      setemp
    ).then((res) => {
      setEmpName({
        ...EmpName,
        LineInspecQaShiftC: res[0].Emp_code,
        LineInspecQaShiftCEmp_name: res[0].Emp_name,
      });
      setPMS([{ ...PMS[0], LineInspecQaShiftC: res[0].Emp_code }]);
    });
  }
  let componentref = useRef(null);
  const [back, setback] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [ENC, setENC] = useState("");
  const [remarks, setremarks] = useState('');
  const [cardId, setcardId] = useState('');
  const onButtonClick = () => {
    setDownloadEnabled(false);
    // Hide the dropdown before capturing the HTML content
    const dropdown = document.querySelector('.SmpDownload');
    if (dropdown) {
      dropdown.style.display = 'none';
    }
    const pdf = new jsPDF("portrait", "mm", "a4");
    html2canvas(componentref.current).then((canvas) => {
      // Show the dropdown after capturing the HTML content
      if (dropdown) {
        dropdown.style.display = 'block';
      }

      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 3, 3, 205, 500);
      pdf.save(`PMS ${GeneralConstants.CURRENT_Date}.pdf`);
      setDownloadEnabled(true);
    });
  };
  useEffect(() => {
    // console.log("id 1 ",id);
    if (id !== undefined) {

      // console.log("id 2 ",id);
      HandleView(id)
      // await timeout(1000);
      setback(true);
      const popupDiv = document.querySelector(".popup");
      if (popupDiv) {
        const elementInsidePopup = popupDiv.querySelector("#close-btn");
        elementInsidePopup.style.display = "none";
      }
    }
    // console.log(back,ButtonPopup,ButtonPopup1);
  }, [id])
  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }

  return (
    <div>
      <div >
        <Popup
          trigger={ButtonPopup1}
          viewPagination={viewPagination}
        >
          <div
            style={{
              display: "flex",
              gap: "350px",
              alignItems: "center",
              padding: "20px 10px 20px 25px",
              width: "97%",
              backgroundColor: "#96d4d4",
            }}
          >
            <div>
              <img
                src={Unitechlogo}
                style={{ width: "50px", height: "50px" }}
              ></img>
            </div>
            <div>
              <font color="#8B0000">PROCESS MONITORING SYSTEM</font>
            </div>

          </div>
          <form className="form-pms-View">
            <table width="95%" style={{ "text-align": "center" }} border="0">

              <tr>
                <td>M/C NO</td>
                <td>
                  <label>{PMS[0].Mcno}</label>
                </td>

                <td>Rev No</td>
                <td>
                  <label>02</label>
                </td>
              </tr>

              <tr>
                <td>Track Date</td>
                <td>
                  <label>{formatDt(PMS[0].TrackDate)}</label>
                </td>
                <td>Format No</td>
                <td>
                  <label>UPCL/PRO/R/28</label>
                </td>
              </tr>

              <tr>
                {/* <td>Part weight</td>
                <td>
                  <label>{partWeight}</label>
                </td> */}
                <td colSpan={2}></td>

                <td>Rev Date</td>
                <td>
                  <label>02-04-2019</label>
                </td>
              </tr>
            </table>
            <table width="50%" style={{ "text-align": "center" }} border="0">
              <tr style={{ "text-align": "center" }}>
                <td colSpan="2">Part weight1 (-{PMS[0].PartWeightMinus1}/+{PMS[0].PartWeightPlus1})</td>

                <td colSpan="2">
                  <label>{PMS[0].PartWeight1}</label>
                </td>

                <td>
                  <label>{PMS[0].PW1}</label>
                </td>
                <td>
                  <label>{PMS[0].PW2}</label>
                </td>
                <td>
                  <label>{PMS[0].PW3}</label>
                </td>
                <td>
                  <label>{PMS[0].PW4}</label>
                </td>
                <td>
                  <label>{PMS[0].PW5}</label>
                </td>
                <td>
                  <label>{PMS[0].PW6}</label>
                </td>
              </tr>
              <tr style={{ "text-align": "center" }}>
                <td colSpan="2">Part weight2 (-{PMS[0].PartWeightMinus2}/+{PMS[0].PartWeightPlus2})</td>

                <td colSpan="2">
                  <label>{PMS[0].PartWeight2}</label>
                </td>

                <td>
                  <label>{PMS[0].PW11}</label>
                </td>
                <td>
                  <label>{PMS[0].PW21}</label>
                </td>
                <td>
                  <label>{PMS[0].PW31}</label>
                </td>
                <td>
                  <label>{PMS[0].PW41}</label>
                </td>
                <td>
                  <label>{PMS[0].PW51}</label>
                </td>
                <td>
                  <label>{PMS[0].PW61}</label>
                </td>
              </tr>
              <tr>
                <th nowrap style={{ "text-align": "center", width: "1%" }}>
                  SL
                </th>
                <th
                  width="18%"
                  nowrap
                  style={{ "text-align": "center", width: "8%" }}
                >
                  Description
                </th>
                <th
                  width="15%"
                  nowrap
                  style={{ "text-align": "center", width: "3%" }}
                >
                  Inst used
                </th>
                <th
                  width="20%"
                  nowrap
                  style={{ "text-align": "center", width: "6.8%" }}
                >
                  Raw-Mat
                </th>
                <th width="7%" nowrap style={{ "text-align": "center" }}>
                  Actual Level
                </th>
                <th width="7%" nowrap style={{ "text-align": "center" }}>
                  Actual Level
                </th>
                <th width="7%" nowrap style={{ "text-align": "center" }}>
                  Actual Level
                </th>
                <th width="7%" nowrap style={{ "text-align": "center" }}>
                  Actual Level
                </th>
                <th width="7%" nowrap style={{ "text-align": "center" }}>
                  Actual Level
                </th>
                <th width="7%" nowrap style={{ "text-align": "center" }}>
                  Actual Level
                </th>
              </tr>

              <tr>
                <td>1</td>
                <td nowrap>Raw-Mat-Lvl</td>
                <td>On hooper</td>
                <td>Min level/Max Level</td>

                <td>
                  <label>
                    {PMS[0].RM1 === "Active" ? "✓" : PMS[0].RM1 === "Inactive" ? "X" : "NA"}
                  </label>
                </td>

                <td>
                  <label>
                    {PMS[0].RM2 === "Active" ? "✓" : PMS[0].RM2 === "Inactive" ? "X" : "NA"}
                  </label>
                </td>
                <td>
                  <label>
                    {PMS[0].RM3 === "Active" ? "✓" : PMS[0].RM3 === "Inactive" ? "X" : "NA"}
                  </label>
                </td>
                <td>
                  <label>
                    {PMS[0].RM4 === "Active" ? "✓" : PMS[0].RM4 === "Inactive" ? "X" : "NA"}
                  </label>
                </td>
                <td>
                  <label>
                    {PMS[0].RM5 === "Active" ? "✓" : PMS[0].RM5 === "Inactive" ? "X" : "NA"}
                  </label>
                </td>
                <td>
                  <label>
                    {PMS[0].RM6 === "Active" ? "✓" : PMS[0].RM6 === "Inactive" ? "X" : "NA"}
                  </label>
                </td>
              </tr>
            </table>

            <table width="50%" style={{ "text-align": "center" }} border="0">
              <tr style={{ "text-align": "center" }}>
                <th style={{ "text-align": "center" }} colSpan="15">
                  Tracking Machine
                </th>
              </tr>
              <tr>
                <th
                  width="10%"
                  nowrap
                  style={{ "text-align": "center", width: "13.8%" }}
                >
                  Time to be updated
                </th>
                <th width="5%" nowrap style={{ "text-align": "center" }}>
                  (07.00-08.00)AM
                </th>
                <th width="5%" nowrap style={{ "text-align": "center" }}>
                  (11.00-12.00)AM
                </th>
                <th width="5%" nowrap style={{ "text-align": "center" }}>
                  (15.00-16.00)PM
                </th>
                <th width="5%" nowrap style={{ "text-align": "center" }}>
                  (19.00-20.00)PM
                </th>
                <th width="5%" nowrap style={{ "text-align": "center" }}>
                  (23.00-01.00)AM
                </th>
                <th width="5%" nowrap style={{ "text-align": "center" }}>
                  (03.00-04.00)AM
                </th>
              </tr>

              <tr>
                <td>Actual updated time</td>
                <td style={{ textAlign: "center" }}>
                  <label>{Tracking[0].Track_7_am}</label>
                </td>
                <td style={{ textAlign: "center" }}>
                  <label>{Tracking[0].Track_11_am}</label>
                </td>
                <td style={{ textAlign: "center" }}>
                  <label>{Tracking[0].Track_15_pm}</label>
                </td>
                <td style={{ textAlign: "center" }}>
                  <label>{Tracking[0].Track_19_pm}</label>
                </td>
                <td style={{ textAlign: "center" }}>
                  <label>{Tracking[0].Track_23_pm}</label>
                </td>
                <td style={{ textAlign: "center" }}>
                  <label>{Tracking[0].Track_3_am}</label>
                </td>
              </tr>

              <tr>
                <td>Part name</td>
                <td>
                  <label>{Tracking[1].Track_7_am}</label>
                </td>
                <td>
                  <label>{Tracking[1].Track_11_am}</label>
                </td>
                <td>
                  <label>{Tracking[1].Track_15_pm}</label>
                </td>
                <td>
                  <label>{Tracking[1].Track_19_pm}</label>
                </td>
                <td>
                  <label>{Tracking[1].Track_23_pm}</label>
                </td>
                <td>
                  <label>{Tracking[1].Track_3_am}</label>
                </td>
              </tr>

              <tr>
                <td class="lbl">Colour</td>
                <td>
                  <label>{Tracking[2].Track_7_am}</label>
                </td>
                <td>
                  <label>{Tracking[2].Track_11_am}</label>
                </td>
                <td>
                  <label>{Tracking[2].Track_15_pm}</label>
                </td>
                <td>
                  <label>{Tracking[2].Track_19_pm}</label>
                </td>
                <td>
                  <label>{Tracking[2].Track_23_pm}</label>
                </td>
                <td>
                  <label>{Tracking[2].Track_3_am}</label>
                </td>
              </tr>

              <tr>
                <td>Material used</td>
                <td>
                  <label>{Tracking[3].Track_7_am}</label>
                </td>
                <td>
                  <label>{Tracking[3].Track_11_am}</label>
                </td>
                <td>
                  <label>{Tracking[3].Track_15_pm}</label>
                </td>
                <td>
                  <label>{Tracking[3].Track_19_pm}</label>
                </td>
                <td>
                  <label>{Tracking[3].Track_23_pm}</label>
                </td>
                <td>
                  <label>{Tracking[3].Track_3_am}</label>
                </td>
              </tr>

              <tr>
                <td>Material grade</td>
                <td>
                  <label>{Tracking[4].Track_7_am}</label>
                </td>
                <td>
                  <label>{Tracking[4].Track_11_am}</label>
                </td>
                <td>
                  <label>{Tracking[4].Track_15_pm}</label>
                </td>
                <td>
                  <label>{Tracking[4].Track_19_pm}</label>
                </td>
                <td>
                  <label>{Tracking[4].Track_23_pm}</label>
                </td>
                <td>
                  <label>{Tracking[4].Track_3_am}</label>
                </td>
              </tr>
            </table>

            <table width="50%" style={{ "text-align": "center" }} border="0">
              <tr style={{ "text-align": "center" }}>
                <th style={{ "text-align": "center" }} colSpan="15">
                  Heating parameter
                </th>
              </tr>
              <tr>
                <th
                  width="8%"
                  nowrap
                  style={{ "text-align": "center", width: "1%" }}
                >
                  SL
                </th>
                <th nowrap style={{ "text-align": "center", width: "3.5%" }}>
                  Description
                </th>
                <th nowrap style={{ "text-align": "center", width: "1.5%" }}>
                  Inst-used
                </th>
                <th
                  width="5%"
                  nowrap
                  style={{ "text-align": "center", width: "3.5%" }}
                >
                  Set
                </th>
                <th
                  width="5%"
                  nowrap
                  style={{ "text-align": "center", width: "0.9%" }}
                >
                  Units
                </th>
                <th width="5%" nowrap style={{ "text-align": "center" }}>
                  Actual
                </th>
                <th width="5%" nowrap style={{ "text-align": "center" }}>
                  Actual
                </th>
                <th width="5%" nowrap style={{ "text-align": "center" }}>
                  Actual
                </th>
                <th width="5%" nowrap style={{ "text-align": "center" }}>
                  Actual
                </th>
                <th width="5%" nowrap style={{ "text-align": "center" }}>
                  Actual
                </th>
                <th width="5%" nowrap style={{ "text-align": "center" }}>
                  Actual
                </th>
              </tr>

              <tr>
                <td>01</td>
                <td>MTC temperature</td>
                <td>Onpannel</td>
                <td>
                  ({Heat[0].Heat_smp_data}) ±{Heat[0].Heat_smp_set}°C
                </td>
                <td>°C</td>
                <td>
                  <label>{Heat[0].Heat_actual_1}</label>
                </td>
                <td>
                  <label>{Heat[0].Heat_actual_2}</label>
                </td>
                <td>
                  <label>{Heat[0].Heat_actual_3}</label>
                </td>
                <td>
                  <label>{Heat[0].Heat_actual_4}</label>
                </td>
                <td>
                  <label>{Heat[0].Heat_actual_5}</label>
                </td>
                <td>
                  <label>{Heat[0].Heat_actual_6}</label>
                </td>
              </tr>

              <tr>
                <td>02</td>
                <td>Hopper Temperature</td>
                <td>Onpannel</td>
                <td>
                  ({Heat[1].Heat_smp_data})±{Heat[1].Heat_smp_set}°C
                </td>
                <td>°C</td>
                <td>
                  <label>{Heat[1].Heat_actual_1}</label>
                </td>
                <td>
                  <label>{Heat[1].Heat_actual_2}</label>
                </td>
                <td>
                  <label>{Heat[1].Heat_actual_3}</label>
                </td>
                <td>
                  <label>{Heat[1].Heat_actual_4}</label>
                </td>
                <td>
                  <label>{Heat[1].Heat_actual_5}</label>
                </td>
                <td>
                  <label>{Heat[1].Heat_actual_6}</label>
                </td>
              </tr>
              <tr>
                <td>03</td>
                <td>Nozzle Temperature</td>
                <td>Onpannel</td>
                <td>
                  ({Heat[2].Heat_smp_data})±{Heat[2].Heat_smp_set}°C
                </td>
                <td>°C</td>
                <td>
                  <label>{Heat[2].Heat_actual_1}</label>
                </td>
                <td>
                  <label>{Heat[2].Heat_actual_2}</label>
                </td>
                <td>
                  <label>{Heat[2].Heat_actual_3}</label>
                </td>
                <td>
                  <label>{Heat[2].Heat_actual_4}</label>
                </td>
                <td>
                  <label>{Heat[2].Heat_actual_5}</label>
                </td>
                <td>
                  <label>{Heat[2].Heat_actual_6}</label>
                </td>
              </tr>

              <tr>
                <td>04</td>
                <td>Zone 1</td>
                <td>Onpannel</td>
                <td>
                  ({Heat[3].Heat_smp_data})±{Heat[3].Heat_smp_set}°C
                </td>
                <td>°C</td>
                <td>
                  <label>{Heat[3].Heat_actual_1}</label>
                </td>
                <td>
                  <label>{Heat[3].Heat_actual_2}</label>
                </td>
                <td>
                  <label>{Heat[3].Heat_actual_3}</label>
                </td>
                <td>
                  <label>{Heat[3].Heat_actual_4}</label>
                </td>
                <td>
                  <label>{Heat[3].Heat_actual_5}</label>
                </td>
                <td>
                  <label>{Heat[3].Heat_actual_6}</label>
                </td>
              </tr>

              <tr>
                <td>05</td>
                <td>Zone 2</td>
                <td>Onpannel</td>
                <td>
                  ({Heat[4].Heat_smp_data})±{Heat[4].Heat_smp_set}°C
                </td>
                <td>°C</td>
                <td>
                  <label>{Heat[4].Heat_actual_1}</label>
                </td>
                <td>
                  <label>{Heat[4].Heat_actual_2}</label>
                </td>
                <td>
                  <label>{Heat[4].Heat_actual_3}</label>
                </td>
                <td>
                  <label>{Heat[4].Heat_actual_4}</label>
                </td>
                <td>
                  <label>{Heat[4].Heat_actual_5}</label>
                </td>
                <td>
                  <label>{Heat[4].Heat_actual_6}</label>
                </td>
              </tr>

              <tr>
                <td>06</td>
                <td>Zone 3</td>
                <td>Onpannel</td>
                <td>
                  ({Heat[5].Heat_smp_data})±{Heat[5].Heat_smp_set}°C
                </td>
                <td>°C</td>
                <td>
                  <label>{Heat[5].Heat_actual_1}</label>
                </td>
                <td>
                  <label>{Heat[5].Heat_actual_2}</label>
                </td>
                <td>
                  <label>{Heat[5].Heat_actual_3}</label>
                </td>
                <td>
                  <label>{Heat[5].Heat_actual_4}</label>
                </td>
                <td>
                  <label>{Heat[5].Heat_actual_5}</label>
                </td>
                <td>
                  <label>{Heat[5].Heat_actual_6}</label>
                </td>
              </tr>

              <tr>
                <td>07</td>
                <td>Zone 4</td>
                <td>Onpannel</td>
                <td>({Heat[6].Heat_smp_data})±{Heat[6].Heat_smp_set}°C</td>
                <td>°C</td>
                <td>
                  <label>{Heat[6].Heat_actual_1}</label>
                </td>
                <td>
                  <label>{Heat[6].Heat_actual_2}</label>
                </td>
                <td>
                  <label>{Heat[6].Heat_actual_3}</label>
                </td>
                <td>
                  <label>{Heat[6].Heat_actual_4}</label>
                </td>
                <td>
                  <label>{Heat[6].Heat_actual_5}</label>
                </td>
                <td>
                  <label>{Heat[6].Heat_actual_6}</label>
                </td>
              </tr>

              <tr>
                <td>08</td>
                <td>Zone 5</td>
                <td>Onpannel</td>
                <td>({Heat[7].Heat_smp_data})±{Heat[7].Heat_smp_set}°C</td>
                <td>°C</td>
                <td>
                  <label>{Heat[7].Heat_actual_1}</label>
                </td>
                <td>
                  <label>{Heat[7].Heat_actual_2}</label>
                </td>
                <td>
                  <label>{Heat[7].Heat_actual_3}</label>
                </td>
                <td>
                  <label>{Heat[7].Heat_actual_4}</label>
                </td>
                <td>
                  <label>{Heat[7].Heat_actual_5}</label>
                </td>
                <td>
                  <label>{Heat[7].Heat_actual_6}</label>
                </td>
              </tr>

              <tr>
                <td>09</td>
                <td>Zone 6</td>
                <td>Onpannel</td>
                <td>({Heat[8].Heat_smp_data})±{Heat[8].Heat_smp_set}°C</td>
                <td>°C</td>
                <td>
                  <label>{Heat[8].Heat_actual_1}</label>
                </td>
                <td>
                  <label>{Heat[8].Heat_actual_2}</label>
                </td>
                <td>
                  <label>{Heat[8].Heat_actual_3}</label>
                </td>
                <td>
                  <label>{Heat[8].Heat_actual_4}</label>
                </td>
                <td>
                  <label>{Heat[8].Heat_actual_5}</label>
                </td>
                <td>
                  <label>{Heat[8].Heat_actual_6}</label>
                </td>
              </tr>
            </table>

            <table width="50%" style={{ "text-align": "center" }} border="0">
              <tr style={{ "text-align": "center" }}>
                <th style={{ "text-align": "center" }} colSpan="15">
                  Injection parameter
                </th>
              </tr>
              <tr>
                <th
                  width="10%"
                  nowrap
                  style={{ "text-align": "center", width: "1%" }}
                >
                  SL
                </th>
                <th
                  width="35%"
                  nowrap
                  style={{ "text-align": "center", width: "3.5%" }}
                >
                  Description
                </th>
                <th nowrap style={{ "text-align": "center", width: "2.5%" }}>
                  Inst-used
                </th>
                <th
                  width="5%"
                  nowrap
                  style={{ "text-align": "center", width: "3.5%" }}
                >
                  Set
                </th>
                <th
                  width="5%"
                  nowrap
                  style={{ "text-align": "center", width: "0.9%" }}
                >
                  Units
                </th>
                <th width="5%" nowrap style={{ "text-align": "center" }}>
                  Actual
                </th>
                <th width="5%" nowrap style={{ "text-align": "center" }}>
                  Actual
                </th>
                <th width="5%" nowrap style={{ "text-align": "center" }}>
                  Actual
                </th>
                <th width="5%" nowrap style={{ "text-align": "center" }}>
                  Actual
                </th>
                <th width="5%" nowrap style={{ "text-align": "center" }}>
                  Actual
                </th>
                <th width="5%" nowrap style={{ "text-align": "center" }}>
                  Actual
                </th>
              </tr>
              {Inject.map((item) => (
                <tr>
                  <td>{item.Inject_sl}</td>
                  <td>{item.Inject_desc}</td>
                  <td>{item.Inject_inst_used}</td>
                  <td>
                    ({item.Inject_smp_data})±{item.Inject_smp_set}
                  </td>
                  <td>{item.Inject_units}</td>
                  <td>
                    <label>{item.Inject_actual_1}</label>
                  </td>
                  <td>
                    <label>{item.Inject_actual_2}</label>
                  </td>
                  <td>
                    <label>{item.Inject_actual_3}</label>
                  </td>
                  <td>
                    <label>{item.Inject_actual_4}</label>
                  </td>
                  <td>
                    <label>{item.Inject_actual_5}</label>
                  </td>
                  <td>
                    <label>{item.Inject_actual_6}</label>
                  </td>
                </tr>
              ))}
            </table>

            <table width="50%" style={{ textAlign: "center", marginTop: "210px" }} border="5">
              <tr style={{ "text-align": "center" }}>
                <th style={{ "text-align": "center" }} colSpan="10">
                  Mould Core & Cavity Temperature Monitoring
                </th>
              </tr>
              <tr>
                <th style={{ width: "3%", textAlign: "center" }} width="5%">
                  A
                </th>
                <th width="25%" style={{ width: "20.5%" }}>
                  Normal condition
                </th>
                <th width="10%" style={{ width: "8.5%" }}>
                  Set
                </th>
                <th>Actual value</th>
              </tr>

              <tr>
                <th colSpan="3"></th>
                <td width="50">
                  <table>
                    <tr valign="top">
                      <th width="30%" style={{ "text-align": "center" }}>
                        Shift-A
                      </th>
                      <th width="30%" style={{ "text-align": "center" }}>
                        Shift-B
                      </th>
                      <th width="30%" style={{ "text-align": "center" }}>
                        Shift-C
                      </th>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr>
                <td>01</td>
                <td>TEMPERATURE of core side</td>
                <td>
                  ({PMS[0].Core})±{PMS[0].CoreSet}°C
                </td>
                <td>
                  <table>
                    <tr>
                      <td width="30%" style={{ padding: "1%" }}>
                        <label>{PMS[0].Tempcore1}</label>
                      </td>
                      <td width="30%">
                        <label>{PMS[0].Tempcore2}</label>
                      </td>
                      <td width="30%">
                        <label>{PMS[0].Tempcore3}</label>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>02</td>
                <td>TEMPERATURE of CAVITY</td>
                <td>({PMS[0].Cavity})±{PMS[0].CavSet}°C</td>
                <td>
                  <table>
                    <tr>
                      <td width="30%" style={{ padding: "1%" }}>
                        <label>{PMS[0].Tempcav1}</label>
                      </td>
                      <td width="30%">
                        <label>{PMS[0].Tempcav2}</label>
                      </td>
                      <td width="30%">
                        <label>{PMS[0].Tempcav3}</label>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <table width="50%" style={{ "text-align": "center" }} border="5">
              <tr>
                <th style={{ width: "3%", textAlign: "center" }}>B</th>
                <th width="25%" style={{ width: "20.5%" }}>
                  With M T C
                </th>
                <th width="10%" style={{ width: "8.5%" }}>
                  Set
                </th>
                <th>Actual value</th>
              </tr>
              <tr>
                <td>01</td>
                <td>TEMPERATURE of core side</td>
                <td>({PMS[0].Core})±{PMS[0].CoreSet}°C</td>
                <td>
                  <table>
                    <tr>
                      <td width="30%" style={{ padding: "1%" }}>
                        <label>{PMS[0].Tempcore4}</label>
                      </td>
                      <td width="30%">
                        <label>{PMS[0].Tempcore5}</label>
                      </td>
                      <td width="30%">
                        <label>{PMS[0].Tempcore6}</label>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>02</td>
                <td>TEMPERATURE of CAVITY side</td>
                <td>({PMS[0].Cavity})±{PMS[0].CavSet}°C</td>
                <td>
                  <table>
                    <tr>
                      <td width="30%" style={{ padding: "1%" }}>
                        <label>{PMS[0].Tempcav4}</label>
                      </td>
                      <td width="30%">
                        <label>{PMS[0].Tempcav5}</label>
                      </td>
                      <td width="30%">
                        <label>{PMS[0].Tempcav6}</label>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr>
                <td colSpan={3} style={{ textAlign: "end" }}>
                  Signature of the Shift Incharge - Production
                </td>

                <td>
                  <table>
                    <tr>
                      <td width="30%">{EmpName.ShiftInchargeShiftA + "-" + EmpName.ShiftInchargeShiftAEmp_name}</td>
                      <td width="30%">{EmpName.ShiftInchargeShiftB + "-" + EmpName.ShiftInchargeShiftBEmp_name}</td>
                      <td width="30%">{EmpName.ShiftInchargeShiftC + "-" + EmpName.ShiftInchargeShiftCEmp_name}</td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan={3} style={{ textAlign: "end" }}>
                  Signature of the On-Line Inspector - Quality
                </td>

                <td>
                  <table>
                    <tr>
                      <td width="30%">{EmpName.LineInspecQaShiftA + "-" + EmpName.LineInspecQaShiftAEmp_name}</td>
                      <td width="30%">{EmpName.LineInspecQaShiftB + "-" + EmpName.LineInspecQaShiftBEmp_name}</td>
                      <td width="30%">{EmpName.LineInspecQaShiftC + "-" + EmpName.LineInspecQaShiftCEmp_name}</td>
                    </tr>
                  </table>
                </td>
              </tr>
              {/* <tr>
                <td colSpan={3}>
                  <label>Verified by</label>
                </td>
                <td>
                  <label>
                    {VeriPer == "emp1"
                      ? "500541 - Silambarasan"
                      : "3044 - Antony"}
                  </label>
                </td>
              </tr> */}
            </table>
          </form>
        </Popup>
      </div>
      <div>
        <Popup
          trigger={ButtonPopup1}
          setTrigger={setButtonPopup1}
          viewPagination={viewPagination}
        >
          <br />
          <br />
          {back && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { setback(false); setButtonPopup1(false); navigate(-1); }}><FaBackspace size={20} /> Back</div>
            <div style={{ display: 'flex', width: '25%', gap: '5', justifyContent: 'space-between', alignItems: 'center', marginRight: "10px" }}><button
              className="icon"
              style={{ backgroundColor: "#06802f", marginRight: '10px' }}
              type="button"
              onClick={(e) => {
                approve(id, "Pms")
                navigate(-1)
              }}
            >
              Approve
            </button>
              <button
                className="icon"
                style={{ backgroundColor: "#9d080f" }}
                type="button"
                onClick={() => { setcardId(id) }}
              >
                Reject
              </button>
              {id === cardId && <div><div style={{ backgroundColor: 'gray', filter: 'blur(8px)', position: 'absolute', opacity: '.8', right: '0%', bottom: '0%', width: '100%', height: '100%' }}></div><div style={{ position: 'absolute', right: '32%', color: 'white', bottom: '35%', padding: '20px', borderRadius: '10px' }}><h3>Remarks</h3><textarea rows={5} cols={50} onChange={e => { setremarks(e.target.value) }}></textarea><button onClick={e => { reject(id, "Pms", remarks); navigate(-1) }}>Submit</button><button onClick={() => { setcardId('') }}>Cancel</button></div></div>}
            </div>
          </div>}
          <div style={{ zIndex: "60" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "20px 10px 20px 25px",
                width: "97%",
                backgroundColor: "#96d4d4",
              }}
            >
              <div>
                <img
                  src={Unitechlogo}
                  style={{ width: "50px", height: "50px" }}
                ></img>
              </div>
              <div>
                <font color="#8B0000">PROCESS MONITORING SYSTEM</font>
              </div>
              <div>
              {downloadEnabled && (
                      <Dropdown style={{ width: '100%', al: "center" }} className="SmpDownload" >
                        <Dropdown.Toggle>
                          Download ⬇️
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <button className="custom-button" onClick={onButtonClick} >
                              PDF ⬇️
                            </button>
                          </Dropdown.Item>                          
                        </Dropdown.Menu>
                      </Dropdown>)}
                {/* <ReactToPrint
                  trigger={() => <button id="pms-download" name="pms.download" type="button" style={{
                    padding: "8px 25px",
                    fontSize: "16px",
                    borderRadius: " 5px",
                    color: "#fff",
                    cursor: " pointer",
                  }}>Download</button>}
                  content={() => componentref}
                /> */}
              </div>
            </div>
            <div ref={componentref}>
            <form
              className="form-pms-Create1"
              onSubmit={(e) => {
                e.preventDefault();
                handleCreate(e);
              }}
            >
              <table
                className="table"
                width="100%"
                style={{ textAlign: "center" }}
                border="0"
              >
                <tbody>
                  <tr style={{ textAlign: "center" }}>
                    <th style={{ textAlign: "center" }} colSpan="4">
                      <font color="#8B0000">PROCESS MONITORING SYSTEM</font>
                    </th>
                  </tr>
                  <tr>
                    <td>Format No</td>
                    <td style={{ textAlign: "center" }}>
                      <label>UPCL/PRO/R/28</label>
                    </td>

                    <td>Rev No</td>
                    <td style={{ textAlign: "center" }}>
                      <label>02</label>
                    </td>
                  </tr>
                  <tr>
                    <td>Track Date</td>
                    <td>
                      <DatePicker value={PMS[0].TrackDate} dis={true} />
                    </td>
                    <td>
                      M/C NO<span style={{ color: "red" }}>*</span>
                    </td>
                    <td>
                      {PMS[0].Mcno === undefined && (
                        <span className="Req">Required *</span>
                      )}
                      <Select
                        id="dropdown"
                        styles={{ width: "100%" }}
                        options={Machine.map((item) => ({
                          value: item.McNo,
                          label: item.mc_name,
                        }))}
                        value={{
                          value: PMS[0].Mcno,
                          label: PMS[0].Mcno,
                        }}
                        onChange={handleMachine}
                        placeholder="Select Machine No"
                        isDisabled={true}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                className="table"
                width="50%"
                style={{ textAlign: "left" }}
                border="0"
              >
                <tbody>
                  <tr>
                    <th colSpan="15"></th>
                  </tr>
                  <tr style={{ textAlign: "center" }}>
              <td colSpan="2">Part weight1 (-{PMS[0].PartWeightMinus1}/+{PMS[0].PartWeightPlus1})</td>

              <td>
                <input
                  style={{ width: "100%" }}
                  type="number"
                  name="PartWeight1"
                  value={PMS[0].PartWeight1}
                  onChange={HandleFeildChangePMS}
                  disabled
                />
              </td>
              <td></td>
              <td>
                <input
                  style={
                    !(
                      parseFloat(PMS[0].PW1) >
                      parseFloat(PMS[0].PartWeight1) +
                      parseFloat(PMS[0].PartWeightPlus1) ||
                      parseFloat(PMS[0].PW1) <
                      parseFloat(PMS[0].PartWeight1) -
                      parseFloat(PMS[0].PartWeightMinus1)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  type="number"
                  name="PW1"
                  id="PW1"
                  value={PMS[0].PW1}
                  disabled={true}
                  onChange={(e) => {
                    HandleFeildChangePMS(e);
                  }}
                />
              </td>

              <td>
                <input
                  style={
                    !(
                      parseFloat(PMS[0].PW2) >
                      parseFloat(PMS[0].PartWeight1) +
                      parseFloat(PMS[0].PartWeightPlus1) ||
                      parseFloat(PMS[0].PW2) <
                      parseFloat(PMS[0].PartWeight1) -
                      parseFloat(PMS[0].PartWeightMinus1)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  type="number"
                  name="PW2"
                  id="PW2"
                  value={PMS[0].PW2}
                  disabled={true}
                  onChange={(e) => {
                    HandleFeildChangePMS(e);
                  }}
                />
              </td>
              <td>
                <input
                  style={
                    !(
                      parseFloat(PMS[0].PW3) >
                      parseFloat(PMS[0].PartWeight1) +
                      parseFloat(PMS[0].PartWeightPlus1) ||
                      parseFloat(PMS[0].PW3) <
                      parseFloat(PMS[0].PartWeight1) -
                      parseFloat(PMS[0].PartWeightMinus1)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  type="number"
                  name="PW3"
                  id="PW3"
                  value={PMS[0].PW3}
                  disabled={true}
                  onChange={(e) => {
                    HandleFeildChangePMS(e);
                  }}
                />
              </td>
              <td>
                <input
                  style={
                    !(
                      parseFloat(PMS[0].PW4) >
                      parseFloat(PMS[0].PartWeight1) +
                      parseFloat(PMS[0].PartWeightPlus1) ||
                      parseFloat(PMS[0].PW4) <
                      parseFloat(PMS[0].PartWeight1) -
                      parseFloat(PMS[0].PartWeightMinus1)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  type="number"
                  name="PW4"
                  id="PW4"
                  value={PMS[0].PW4}
                  disabled={true}
                  onChange={(e) => {
                    HandleFeildChangePMS(e);
                  }}
                />
              </td>
              <td>
                <input
                  style={
                    !(
                      parseFloat(PMS[0].PW5) >
                      parseFloat(PMS[0].PartWeight1) +
                      parseFloat(PMS[0].PartWeightPlus1) ||
                      parseFloat(PMS[0].PW5) <
                      parseFloat(PMS[0].PartWeight1) -
                      parseFloat(PMS[0].PartWeightMinus1)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  type="number"
                  name="PW5"
                  id="PW5"
                  value={PMS[0].PW5}
                  disabled={true}
                  onChange={(e) => {
                    HandleFeildChangePMS(e);
                  }}
                />
              </td>
              <td>
                <input
                  style={
                    !(
                      parseFloat(PMS[0].PW6) >
                      parseFloat(PMS[0].PartWeight1) +
                      parseFloat(PMS[0].PartWeightPlus1) ||
                      parseFloat(PMS[0].PW6) <
                      parseFloat(PMS[0].PartWeight1) -
                      parseFloat(PMS[0].PartWeightMinus1)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  type="number"
                  name="PW6"
                  id="PW6"
                  value={PMS[0].PW6}
                  disabled={true}
                  onChange={(e) => {
                    HandleFeildChangePMS(e);
                  }}
                />
              </td>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td colSpan="2">Part weight2 (-{PMS[0].PartWeightMinus2}/+{PMS[0].PartWeightPlus2})</td>

              <td>
                <input
                  style={{ width: "100%" }}
                  type="number"
                  name="PartWeight2"
                  value={PMS[0].PartWeight2}
                  onChange={HandleFeildChangePMS}
                  disabled
                />
              </td>
              <td></td>
              <td>
                <input
                  style={
                    !(
                      parseFloat(PMS[0].PW11) >
                      parseFloat(PMS[0].PartWeight2) +
                      parseFloat(PMS[0].PartWeightPlus2) ||
                      parseFloat(PMS[0].PW11) <
                      parseFloat(PMS[0].PartWeight2) -
                      parseFloat(PMS[0].PartWeightMinus2)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  type="number"
                  name="PW11"
                  id="PW11"
                  value={PMS[0].PW11}
                  disabled={true}
                  onChange={(e) => {
                    HandleFeildChangePMS(e);
                  }}
                />
              </td>

              <td>
                <input
                  style={
                    !(
                      parseFloat(PMS[0].PW21) >
                      parseFloat(PMS[0].PartWeight2) +
                      parseFloat(PMS[0].PartWeightPlus2) ||
                      parseFloat(PMS[0].PW21) <
                      parseFloat(PMS[0].PartWeight2) -
                      parseFloat(PMS[0].PartWeightMinus2)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  type="number"
                  name="PW21"
                  id="PW21"
                  value={PMS[0].PW21}
                  disabled={true}
                  onChange={(e) => {
                    HandleFeildChangePMS(e);
                  }}
                />
              </td>
              <td>
                <input
                  style={
                    !(
                      parseFloat(PMS[0].PW31) >
                      parseFloat(PMS[0].PartWeight2) +
                      parseFloat(PMS[0].PartWeightPlus2) ||
                      parseFloat(PMS[0].PW31) <
                      parseFloat(PMS[0].PartWeight2) -
                      parseFloat(PMS[0].PartWeightMinus2)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  type="number"
                  name="PW31"
                  id="PW31"
                  value={PMS[0].PW31}
                  disabled={true}
                  onChange={(e) => {
                    HandleFeildChangePMS(e);
                  }}
                />
              </td>
              <td>
                <input
                  style={
                    !(
                      parseFloat(PMS[0].PW41) >
                      parseFloat(PMS[0].PartWeight2) +
                      parseFloat(PMS[0].PartWeightPlus2) ||
                      parseFloat(PMS[0].PW41) <
                      parseFloat(PMS[0].PartWeight2) -
                      parseFloat(PMS[0].PartWeightMinus2)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  type="number"
                  name="PW41"
                  id="PW41"
                  value={PMS[0].PW41}
                  disabled={true}
                  onChange={(e) => {
                    HandleFeildChangePMS(e);
                  }}
                />
              </td>
              <td>
                <input
                  style={
                    !(
                      parseFloat(PMS[0].PW51) >
                      parseFloat(PMS[0].PartWeight2) +
                      parseFloat(PMS[0].PartWeightPlus2) ||
                      parseFloat(PMS[0].PW51) <
                      parseFloat(PMS[0].PartWeight2) -
                      parseFloat(PMS[0].PartWeightMinus2)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  type="number"
                  name="PW51"
                  id="PW51"
                  value={PMS[0].PW51}
                  disabled={true}
                  onChange={(e) => {
                    HandleFeildChangePMS(e);
                  }}
                />
              </td>
              <td>
                <input
                  style={
                    !(
                      parseFloat(PMS[0].PW61) >
                      parseFloat(PMS[0].PartWeight2) +
                      parseFloat(PMS[0].PartWeightPlus2) ||
                      parseFloat(PMS[0].PW61) <
                      parseFloat(PMS[0].PartWeight2) -
                      parseFloat(PMS[0].PartWeightMinus2)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }
                  type="number"
                  name="PW61"
                  id="PW61"
                  value={PMS[0].PW61}
                  disabled={true}
                  onChange={(e) => {
                    HandleFeildChangePMS(e);
                  }}
                />
              </td>
            </tr>
                  <tr>
                    <th width="7%" style={{ textAlign: "center", width: "1%" }}>
                      SL
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        whiteSpace: "nowrap",
                        width: "15.5%",
                      }}
                    >
                      Description
                    </th>
                    <th
                      width="15%"
                      style={{ textAlign: "center", width: "9%" }}
                    >
                      Inst used
                    </th>
                    <th
                      width="20%"
                      style={{ textAlign: "center", width: "11%" }}
                    >
                      Raw-Material
                    </th>
                    <th width="7%" style={{ textAlign: "center" }}>
                      Actual Level
                    </th>
                    <th width="7%" style={{ textAlign: "center" }}>
                      Actual Level
                    </th>
                    <th width="7%" style={{ textAlign: "center" }}>
                      Actual Level
                    </th>
                    <th width="7%" style={{ textAlign: "center" }}>
                      Actual Level
                    </th>
                    <th width="7%" style={{ textAlign: "center" }}>
                      Actual Level
                    </th>
                    <th width="7%" style={{ textAlign: "center" }}>
                      Actual Level
                    </th>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center" }}>1</td>
                    <td style={{ whiteSpace: "nowrap" }}>Raw-Material-Level</td>
                    <td>On hooper</td>
                    <td>Min level/Max Level</td>
                    <td>
                      <select
                        id="dropdown"
                        style={{ width: "100%" }}
                        name="RM1"
                        value={PMS[0].RM1}
                        onChange={HandleFeildChangePMS}
                        disabled={true}
                      >
                        <option value="NA">N A</option>
                        <option value="Active">✓</option>
                        <option value="Inactive">X</option>
                      </select>
                    </td>
                    <td>
                      <select
                        id="dropdown"
                        style={{ width: "100%" }}
                        name="RM2"
                        value={PMS[0].RM2}
                        onChange={HandleFeildChangePMS}
                        disabled={true}
                      >
                        <option value="NA">N A</option>
                        <option value="Active">✓</option>
                        <option value="Inactive">X</option>
                      </select>
                    </td>

                    <td>
                      <select
                        id="dropdown"
                        style={{ width: "100%" }}
                        name="RM3"
                        value={PMS[0].RM3}
                        onChange={HandleFeildChangePMS}
                        disabled={true}
                      >
                        <option value="NA">N A</option>
                        <option value="Active">✓</option>
                        <option value="Inactive">X</option>
                      </select>
                    </td>
                    <td>
                      <select
                        id="dropdown"
                        style={{ width: "100%" }}
                        name="RM4"
                        value={PMS[0].RM4}
                        onChange={HandleFeildChangePMS}
                        disabled={true}
                      >
                        <option value="NA">N A</option>
                        <option value="Active">✓</option>
                        <option value="Inactive">X</option>
                      </select>
                    </td>
                    <td>
                      <select
                        id="dropdown"
                        style={{ width: "100%" }}
                        name="RM5"
                        value={PMS[0].RM5}
                        onChange={HandleFeildChangePMS}
                        disabled={true}
                      >
                        <option value="NA">N A</option>
                        <option value="Active">✓</option>
                        <option value="Inactive">X</option>
                      </select>
                    </td>
                    <td>
                      <select
                        id="dropdown"
                        style={{ width: "100%" }}
                        name="RM6"
                        value={PMS[0].RM6}
                        onChange={HandleFeildChangePMS}
                        disabled={true}
                      >
                        <option value="NA">N A</option>
                        <option value="Active">✓</option>
                        <option value="Inactive">X</option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                className="table"
                width="50%"
                style={{ textAlign: "center" }}
                border="0"
              >
                <tbody>
                  <tr style={{ textAlign: "center" }}>
                    <th style={{ textAlign: "center" }} colSpan="15">
                      Tracking Machine
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        textAlign: "center",
                        whiteSpace: "nowrap",
                        width: "39%",
                      }}
                    >
                      Time to be updated
                    </th>
                    <th
                      width="5%"
                      style={{
                        textAlign: "center",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      07.00 AM - 08.00 AM
                    </th>
                    <th
                      width="5%"
                      style={{
                        textAlign: "center",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      11.00 AM - 12.00 AM
                    </th>
                    <th
                      width="5%"
                      style={{
                        textAlign: "center",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      15.00 PM - 16.00 PM
                    </th>
                    <th
                      width="5%"
                      style={{
                        textAlign: "center",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      19.00 PM - 20.00 PM
                    </th>
                    <th
                      width="5%"
                      style={{
                        textAlign: "center",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      23.00 PM - 01.00 AM
                    </th>
                    <th
                      width="5%"
                      style={{
                        textAlign: "center",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      03.00 AM - 04.00 AM
                    </th>
                  </tr>
                  <tr>
                    <td>Actual updated time</td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[0].Track_7_am}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0], Track_7_am: e.target.value },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3] },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[0].Track_11_am}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0], Track_11_am: e.target.value },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3] },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[0].Track_15_pm}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0], Track_15_pm: e.target.value },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3] },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[0].Track_19_pm}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0], Track_19_pm: e.target.value },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3] },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[0].Track_23_pm}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0], Track_23_pm: e.target.value },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3] },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[0].Track_3_am}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0], Track_3_am: e.target.value },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3] },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Part name</td>
                    <td>
                      <select
                        required
                        id="dropdown"
                        name="PartName1"
                        style={{ width: "100%" }}
                        value={Tracking[1].Track_7_am}
                        onChange={HandlePart7Am}
                        disabled={true}
                      >
                        <option value="">Select Part Name</option>
                        <option value="IDEAL">IDEAL</option>
                        <option value="MOULD CHANGE">MOULD CHANGE</option>
                        {Part.map((i, index) => (
                          <option key={index} value={i.PrtNme}>
                            {i.PrtNme}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <select
                        id="dropdown"
                        style={{ width: "100%" }}
                        value={Tracking[1].Track_11_am}
                        onChange={HandlePart11Am}
                        disabled={true}
                      >
                        <option value="">Select Part Name</option>
                        <option value="IDEAL">IDEAL</option>
                        <option value="MOULD CHANGE">MOULD CHANGE</option>
                        {Part.map((i, index) => (
                          <option key={index} value={i.PrtNme}>
                            {i.PrtNme}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <select
                        id="dropdown"
                        style={{ width: "100%" }}
                        value={Tracking[1].Track_15_pm}
                        onChange={HandlePart15Pm}
                        disabled={true}
                      >
                        <option value="">Select Part Name</option>
                        <option value="IDEAL">IDEAL</option>
                        <option value="MOULD CHANGE">MOULD CHANGE</option>
                        {Part.map((i, index) => (
                          <option key={index} value={i.PrtNme}>
                            {i.PrtNme}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <select
                        id="dropdown"
                        style={{ width: "100%" }}
                        value={Tracking[1].Track_19_pm}
                        onChange={HandlePart19Pm}
                        disabled={true}
                      >
                        <option value="">Select Part Name</option>
                        <option value="IDEAL">IDEAL</option>
                        <option value="MOULD CHANGE">MOULD CHANGE</option>
                        {Part.map((i, index) => (
                          <option key={index} value={i.PrtNme}>
                            {i.PrtNme}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <select
                        id="dropdown"
                        style={{ width: "100%" }}
                        value={Tracking[1].Track_23_pm}
                        onChange={HandlePart23Pm}
                        disabled={true}
                      >
                        <option value="">Select Part Name</option>
                        <option value="IDEAL">IDEAL</option>
                        <option value="MOULD CHANGE">MOULD CHANGE</option>
                        {Part.map((i, index) => (
                          <option key={index} value={i.PrtNme}>
                            {i.PrtNme}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <select
                        id="dropdown"
                        style={{ width: "100%" }}
                        value={Tracking[1].Track_3_am}
                        onChange={HandlePart3Am}
                        disabled={true}
                      >
                        <option value="">Select Part Name</option>
                        <option value="IDEAL">IDEAL</option>
                        <option value="MOULD CHANGE">MOULD CHANGE</option>
                        {Part.map((i, index) => (
                          <option key={index} value={i.PrtNme}>
                            {i.PrtNme}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td className="lbl">Colour</td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[2].Track_7_am}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2], Track_7_am: e.target.value },
                            { ...Tracking[3] },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[2].Track_11_am}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2], Track_11_am: e.target.value },
                            { ...Tracking[3] },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>

                    <td>
                      <input
                        type="text"
                        value={Tracking[2].Track_15_pm}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2], Track_15_pm: e.target.value },
                            { ...Tracking[3] },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[2].Track_19_pm}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2], Track_19_pm: e.target.value },
                            { ...Tracking[3] },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[2].Track_23_pm}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2], Track_23_pm: e.target.value },
                            { ...Tracking[3] },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[2].Track_3_am}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2], Track_3_am: e.target.value },
                            { ...Tracking[3] },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Material used</td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[3].Track_7_am}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3], Track_7_am: e.target.value },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[3].Track_11_am}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3], Track_11_am: e.target.value },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[3].Track_15_pm}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3], Track_15_pm: e.target.value },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[3].Track_19_pm}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3], Track_19_pm: e.target.value },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[3].Track_23_pm}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3], Track_23_pm: e.target.value },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[3].Track_3_am}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3], Track_3_am: e.target.value },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Material grade</td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[4].Track_7_am}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3] },
                            { ...Tracking[4], Track_7_am: e.target.value },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[4].Track_11_am}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3] },
                            { ...Tracking[4], Track_11_am: e.target.value },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[4].Track_15_pm}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3] },
                            { ...Tracking[4], Track_15_pm: e.target.value },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[4].Track_19_pm}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3] },
                            { ...Tracking[4], Track_19_pm: e.target.value },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[4].Track_23_pm}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3] },
                            { ...Tracking[4], Track_23_pm: e.target.value },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[4].Track_3_am}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3] },
                            { ...Tracking[4], Track_3_am: e.target.value },
                          ])
                        }
                        disabled
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                className="table"
                width="50%"
                style={{ textAlign: "center" }}
                border="0"
              >
                <tbody>
                  <tr style={{ textAlign: "center" }}>
                    <th style={{ textAlign: "center" }} colSpan="15">
                      Heating parameter
                    </th>
                  </tr>
                  <tr>
                    <th width="8%" style={{ textAlign: "center", width: "1%" }}>
                      SL
                    </th>
                    <th width="30%" style={{ textAlign: "center" }}>
                      Description
                    </th>
                    <th
                      width="25%"
                      style={{ textAlign: "center", width: "8%" }}
                    >
                      Inst-used
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Set
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        width: "7%",
                        padding: "0px 20px",
                      }}
                    >
                      Units
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Actual
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Actual
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Actual
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Actual
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Actual
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Actual
                    </th>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>MTC Temperature</td>
                    <td>On pannel</td>
                    <td>
                      {" "}
                      ({Heat[0].Heat_smp_data}) ±{Heat[0].Heat_smp_set}°C
                    </td>
                    <td>°C</td>
                    <td>
                      <input
                        id="Heat1-1"
                        type="text"
                        value={Heat[0].Heat_actual_1}
                        style={
                          !(
                            parseFloat(Heat[0].Heat_actual_1) >
                            parseFloat(Heat[0].Heat_smp_data) +
                            parseFloat(Heat[0].Heat_smp_set) ||
                            parseFloat(Heat[0].Heat_actual_1) <
                            parseFloat(Heat[0].Heat_smp_data) -
                            parseFloat(Heat[0].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0], Heat_actual_1: e.target.value },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        id="Heat1-2"
                        type="text"
                        value={Heat[0].Heat_actual_2}
                        style={
                          !(
                            parseFloat(Heat[0].Heat_actual_2) >
                            parseFloat(Heat[0].Heat_smp_data) +
                            parseFloat(Heat[0].Heat_smp_set) ||
                            parseFloat(Heat[0].Heat_actual_2) <
                            parseFloat(Heat[0].Heat_smp_data) -
                            parseFloat(Heat[0].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0], Heat_actual_2: e.target.value },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        id="Heat1-3"
                        type="text"
                        value={Heat[0].Heat_actual_3}
                        style={
                          !(
                            parseFloat(Heat[0].Heat_actual_3) >
                            parseFloat(Heat[0].Heat_smp_data) +
                            parseFloat(Heat[0].Heat_smp_set) ||
                            parseFloat(Heat[0].Heat_actual_3) <
                            parseFloat(Heat[0].Heat_smp_data) -
                            parseFloat(Heat[0].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0], Heat_actual_3: e.target.value },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        id="Heat1-4"
                        type="text"
                        value={Heat[0].Heat_actual_4}
                        style={
                          !(
                            parseFloat(Heat[0].Heat_actual_4) >
                            parseFloat(Heat[0].Heat_smp_data) +
                            parseFloat(Heat[0].Heat_smp_set) ||
                            parseFloat(Heat[0].Heat_actual_4) <
                            parseFloat(Heat[0].Heat_smp_data) -
                            parseFloat(Heat[0].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0], Heat_actual_4: e.target.value },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        id="Heat1-5"
                        value={Heat[0].Heat_actual_5}
                        style={
                          !(
                            parseFloat(Heat[0].Heat_actual_5) >
                            parseFloat(Heat[0].Heat_smp_data) +
                            parseFloat(Heat[0].Heat_smp_set) ||
                            parseFloat(Heat[0].Heat_actual_5) <
                            parseFloat(Heat[0].Heat_smp_data) -
                            parseFloat(Heat[0].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0], Heat_actual_5: e.target.value },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        id="Heat1-6"
                        value={Heat[0].Heat_actual_6}
                        style={
                          !(
                            parseFloat(Heat[0].Heat_actual_6) >
                            parseFloat(Heat[0].Heat_smp_data) +
                            parseFloat(Heat[0].Heat_smp_set) ||
                            parseFloat(Heat[0].Heat_actual_6) <
                            parseFloat(Heat[0].Heat_smp_data) -
                            parseFloat(Heat[0].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0], Heat_actual_6: e.target.value },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>02</td>
                    <td>Hopper Temperature</td>
                    <td>On pannel</td>
                    <td>
                      {" "}
                      ({Heat[1].Heat_smp_data})±{Heat[1].Heat_smp_set}°C
                    </td>
                    <td>°C</td>
                    <td>
                      <input
                        type="text"
                        value={Heat[1].Heat_actual_1}
                        style={
                          !(
                            parseFloat(Heat[1].Heat_actual_1) >
                            parseFloat(Heat[1].Heat_smp_data) +
                            parseFloat(Heat[1].Heat_smp_set) ||
                            parseFloat(Heat[1].Heat_actual_1) <
                            parseFloat(Heat[1].Heat_smp_data) -
                            parseFloat(Heat[1].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1], Heat_actual_1: e.target.value },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat2-1"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[1].Heat_actual_2) >
                            parseFloat(Heat[1].Heat_smp_data) +
                            parseFloat(Heat[1].Heat_smp_set) ||
                            parseFloat(Heat[1].Heat_actual_2) <
                            parseFloat(Heat[1].Heat_smp_data) -
                            parseFloat(Heat[1].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[1].Heat_actual_2}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1], Heat_actual_2: e.target.value },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat2-2"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[1].Heat_actual_3) >
                            parseFloat(Heat[1].Heat_smp_data) +
                            parseFloat(Heat[1].Heat_smp_set) ||
                            parseFloat(Heat[1].Heat_actual_3) <
                            parseFloat(Heat[1].Heat_smp_data) -
                            parseFloat(Heat[1].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[1].Heat_actual_3}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1], Heat_actual_3: e.target.value },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat2-3"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[1].Heat_actual_4) >
                            parseFloat(Heat[1].Heat_smp_data) +
                            parseFloat(Heat[1].Heat_smp_set) ||
                            parseFloat(Heat[1].Heat_actual_4) <
                            parseFloat(Heat[1].Heat_smp_data) -
                            parseFloat(Heat[1].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[1].Heat_actual_4}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1], Heat_actual_4: e.target.value },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat2-4"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[1].Heat_actual_5) >
                            parseFloat(Heat[1].Heat_smp_data) +
                            parseFloat(Heat[1].Heat_smp_set) ||
                            parseFloat(Heat[1].Heat_actual_5) <
                            parseFloat(Heat[1].Heat_smp_data) -
                            parseFloat(Heat[1].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[1].Heat_actual_5}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1], Heat_actual_5: e.target.value },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat2-5"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[1].Heat_actual_6) >
                            parseFloat(Heat[1].Heat_smp_data) +
                            parseFloat(Heat[1].Heat_smp_set) ||
                            parseFloat(Heat[1].Heat_actual_6) <
                            parseFloat(Heat[1].Heat_smp_data) -
                            parseFloat(Heat[1].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[1].Heat_actual_6}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1], Heat_actual_6: e.target.value },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat2-6"
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>03</td>
                    <td>Nozzle Temperature</td>
                    <td>On pannel</td>
                    <td>
                      {" "}
                      ({Heat[2].Heat_smp_data})±{Heat[2].Heat_smp_set}°C
                    </td>
                    <td>°C</td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[2].Heat_actual_1) >
                            parseFloat(Heat[2].Heat_smp_data) +
                            parseFloat(Heat[2].Heat_smp_set) ||
                            parseFloat(Heat[2].Heat_actual_1) <
                            parseFloat(Heat[2].Heat_smp_data) -
                            parseFloat(Heat[2].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[2].Heat_actual_1}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2], Heat_actual_1: e.target.value },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat3-1"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[2].Heat_actual_2) >
                            parseFloat(Heat[2].Heat_smp_data) +
                            parseFloat(Heat[2].Heat_smp_set) ||
                            parseFloat(Heat[2].Heat_actual_2) <
                            parseFloat(Heat[2].Heat_smp_data) -
                            parseFloat(Heat[2].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[2].Heat_actual_2}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2], Heat_actual_2: e.target.value },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat3-2"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[2].Heat_actual_3) >
                            parseFloat(Heat[2].Heat_smp_data) +
                            parseFloat(Heat[2].Heat_smp_set) ||
                            parseFloat(Heat[2].Heat_actual_3) <
                            parseFloat(Heat[2].Heat_smp_data) -
                            parseFloat(Heat[2].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[2].Heat_actual_3}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2], Heat_actual_3: e.target.value },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat3-3"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[2].Heat_actual_4) >
                            parseFloat(Heat[2].Heat_smp_data) +
                            parseFloat(Heat[2].Heat_smp_set) ||
                            parseFloat(Heat[2].Heat_actual_4) <
                            parseFloat(Heat[2].Heat_smp_data) -
                            parseFloat(Heat[2].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[2].Heat_actual_4}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2], Heat_actual_4: e.target.value },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat3-4"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[2].Heat_actual_5) >
                            parseFloat(Heat[2].Heat_smp_data) +
                            parseFloat(Heat[2].Heat_smp_set) ||
                            parseFloat(Heat[2].Heat_actual_5) <
                            parseFloat(Heat[2].Heat_smp_data) -
                            parseFloat(Heat[2].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[2].Heat_actual_5}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2], Heat_actual_5: e.target.value },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat3-5"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[2].Heat_actual_6) >
                            parseFloat(Heat[2].Heat_smp_data) +
                            parseFloat(Heat[2].Heat_smp_set) ||
                            parseFloat(Heat[2].Heat_actual_6) <
                            parseFloat(Heat[2].Heat_smp_data) -
                            parseFloat(Heat[2].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[2].Heat_actual_6}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2], Heat_actual_6: e.target.value },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat3-6"
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>04</td>
                    <td>Zone 1</td>
                    <td>On pannel</td>
                    <td>
                      {" "}
                      ({Heat[3].Heat_smp_data})±{Heat[3].Heat_smp_set}°C
                    </td>
                    <td>°C</td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[3].Heat_actual_1) >
                            parseFloat(Heat[3].Heat_smp_data) +
                            parseFloat(Heat[3].Heat_smp_set) ||
                            parseFloat(Heat[3].Heat_actual_1) <
                            parseFloat(Heat[3].Heat_smp_data) -
                            parseFloat(Heat[3].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[3].Heat_actual_1}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3], Heat_actual_1: e.target.value },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat4-1"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[3].Heat_actual_2) >
                            parseFloat(Heat[3].Heat_smp_data) +
                            parseFloat(Heat[3].Heat_smp_set) ||
                            parseFloat(Heat[3].Heat_actual_2) <
                            parseFloat(Heat[3].Heat_smp_data) -
                            parseFloat(Heat[3].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[3].Heat_actual_2}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3], Heat_actual_2: e.target.value },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat4-2"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[3].Heat_actual_3) >
                            parseFloat(Heat[3].Heat_smp_data) +
                            parseFloat(Heat[3].Heat_smp_set) ||
                            parseFloat(Heat[3].Heat_actual_3) <
                            parseFloat(Heat[3].Heat_smp_data) -
                            parseFloat(Heat[3].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[3].Heat_actual_3}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3], Heat_actual_3: e.target.value },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat4-3"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[3].Heat_actual_4) >
                            parseFloat(Heat[3].Heat_smp_data) +
                            parseFloat(Heat[3].Heat_smp_set) ||
                            parseFloat(Heat[3].Heat_actual_4) <
                            parseFloat(Heat[3].Heat_smp_data) -
                            parseFloat(Heat[3].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[3].Heat_actual_4}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3], Heat_actual_4: e.target.value },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat4-4"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[3].Heat_actual_5) >
                            parseFloat(Heat[3].Heat_smp_data) +
                            parseFloat(Heat[3].Heat_smp_set) ||
                            parseFloat(Heat[3].Heat_actual_5) <
                            parseFloat(Heat[3].Heat_smp_data) -
                            parseFloat(Heat[3].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[3].Heat_actual_5}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3], Heat_actual_5: e.target.value },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat4-5"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[3].Heat_actual_6) >
                            parseFloat(Heat[3].Heat_smp_data) +
                            parseFloat(Heat[3].Heat_smp_set) ||
                            parseFloat(Heat[3].Heat_actual_6) <
                            parseFloat(Heat[3].Heat_smp_data) -
                            parseFloat(Heat[3].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[3].Heat_actual_6}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3], Heat_actual_6: e.target.value },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat4-6"
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>05</td>
                    <td>Zone 2</td>
                    <td>On pannel</td>
                    <td>
                      {" "}
                      ({Heat[4].Heat_smp_data})±{Heat[4].Heat_smp_set}°C
                    </td>
                    <td>°C</td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[4].Heat_actual_1) >
                            parseFloat(Heat[4].Heat_smp_data) +
                            parseFloat(Heat[4].Heat_smp_set) ||
                            parseFloat(Heat[4].Heat_actual_1) <
                            parseFloat(Heat[4].Heat_smp_data) -
                            parseFloat(Heat[4].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[4].Heat_actual_1}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4], Heat_actual_1: e.target.value },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat5-1"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[4].Heat_actual_2) >
                            parseFloat(Heat[4].Heat_smp_data) +
                            parseFloat(Heat[4].Heat_smp_set) ||
                            parseFloat(Heat[4].Heat_actual_2) <
                            parseFloat(Heat[4].Heat_smp_data) -
                            parseFloat(Heat[4].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[4].Heat_actual_2}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4], Heat_actual_2: e.target.value },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat5-2"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[4].Heat_actual_3) >
                            parseFloat(Heat[4].Heat_smp_data) +
                            parseFloat(Heat[4].Heat_smp_set) ||
                            parseFloat(Heat[4].Heat_actual_3) <
                            parseFloat(Heat[4].Heat_smp_data) -
                            parseFloat(Heat[4].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[4].Heat_actual_3}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4], Heat_actual_3: e.target.value },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat5-3"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[4].Heat_actual_4) >
                            parseFloat(Heat[4].Heat_smp_data) +
                            parseFloat(Heat[4].Heat_smp_set) ||
                            parseFloat(Heat[4].Heat_actual_4) <
                            parseFloat(Heat[4].Heat_smp_data) -
                            parseFloat(Heat[4].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[4].Heat_actual_4}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4], Heat_actual_4: e.target.value },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat5-4"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[4].Heat_actual_5) >
                            parseFloat(Heat[4].Heat_smp_data) +
                            parseFloat(Heat[4].Heat_smp_set) ||
                            parseFloat(Heat[4].Heat_actual_5) <
                            parseFloat(Heat[4].Heat_smp_data) -
                            parseFloat(Heat[4].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[4].Heat_actual_5}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4], Heat_actual_5: e.target.value },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat5-5"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[4].Heat_actual_6) >
                            parseFloat(Heat[4].Heat_smp_data) +
                            parseFloat(Heat[4].Heat_smp_set) ||
                            parseFloat(Heat[4].Heat_actual_6) <
                            parseFloat(Heat[4].Heat_smp_data) -
                            parseFloat(Heat[4].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[4].Heat_actual_6}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4], Heat_actual_6: e.target.value },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat5-6"
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>06</td>
                    <td>Zone 3</td>
                    <td>On pannel</td>
                    <td>
                      {" "}
                      ({Heat[5].Heat_smp_data})±{Heat[5].Heat_smp_set}°C
                    </td>
                    <td>°C</td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[5].Heat_actual_1) >
                            parseFloat(Heat[5].Heat_smp_data) +
                            parseFloat(Heat[5].Heat_smp_set) ||
                            parseFloat(Heat[5].Heat_actual_1) <
                            parseFloat(Heat[5].Heat_smp_data) -
                            parseFloat(Heat[5].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[5].Heat_actual_1}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5], Heat_actual_1: e.target.value },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat6-1"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[5].Heat_actual_2) >
                            parseFloat(Heat[5].Heat_smp_data) +
                            parseFloat(Heat[5].Heat_smp_set) ||
                            parseFloat(Heat[5].Heat_actual_2) <
                            parseFloat(Heat[5].Heat_smp_data) -
                            parseFloat(Heat[5].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[5].Heat_actual_2}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5], Heat_actual_2: e.target.value },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat6-2"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[5].Heat_actual_3) >
                            parseFloat(Heat[5].Heat_smp_data) +
                            parseFloat(Heat[5].Heat_smp_set) ||
                            parseFloat(Heat[5].Heat_actual_3) <
                            parseFloat(Heat[5].Heat_smp_data) -
                            parseFloat(Heat[5].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[5].Heat_actual_3}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5], Heat_actual_3: e.target.value },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat6-3"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[5].Heat_actual_4) >
                            parseFloat(Heat[5].Heat_smp_data) +
                            parseFloat(Heat[5].Heat_smp_set) ||
                            parseFloat(Heat[5].Heat_actual_4) <
                            parseFloat(Heat[5].Heat_smp_data) -
                            parseFloat(Heat[5].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[5].Heat_actual_4}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5], Heat_actual_4: e.target.value },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat6-4"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[5].Heat_actual_5) >
                            parseFloat(Heat[5].Heat_smp_data) +
                            parseFloat(Heat[5].Heat_smp_set) ||
                            parseFloat(Heat[5].Heat_actual_5) <
                            parseFloat(Heat[5].Heat_smp_data) -
                            parseFloat(Heat[5].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[5].Heat_actual_5}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5], Heat_actual_5: e.target.value },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat6-5"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[5].Heat_actual_6) >
                            parseFloat(Heat[5].Heat_smp_data) +
                            parseFloat(Heat[5].Heat_smp_set) ||
                            parseFloat(Heat[5].Heat_actual_6) <
                            parseFloat(Heat[5].Heat_smp_data) -
                            parseFloat(Heat[5].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[5].Heat_actual_6}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5], Heat_actual_6: e.target.value },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat6-6"
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>07</td>
                    <td>Zone 4</td>
                    <td>On pannel</td>
                    <td>
                      ({Heat[6].Heat_smp_data})±{Heat[6].Heat_smp_set}°C
                    </td>
                    <td>°C</td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[6].Heat_actual_1) >
                            parseFloat(Heat[6].Heat_smp_data) +
                            parseFloat(Heat[6].Heat_smp_set) ||
                            parseFloat(Heat[6].Heat_actual_1) <
                            parseFloat(Heat[6].Heat_smp_data) -
                            parseFloat(Heat[6].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[6].Heat_actual_1}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6], Heat_actual_1: e.target.value },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat7-1"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[6].Heat_actual_2) >
                            parseFloat(Heat[6].Heat_smp_data) +
                            parseFloat(Heat[6].Heat_smp_set) ||
                            parseFloat(Heat[6].Heat_actual_2) <
                            parseFloat(Heat[6].Heat_smp_data) -
                            parseFloat(Heat[6].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[6].Heat_actual_2}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6], Heat_actual_2: e.target.value },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat7-2"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[6].Heat_actual_3) >
                            parseFloat(Heat[6].Heat_smp_data) +
                            parseFloat(Heat[6].Heat_smp_set) ||
                            parseFloat(Heat[6].Heat_actual_3) <
                            parseFloat(Heat[6].Heat_smp_data) -
                            parseFloat(Heat[6].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[6].Heat_actual_3}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6], Heat_actual_3: e.target.value },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat7-3"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[6].Heat_actual_4) >
                            parseFloat(Heat[6].Heat_smp_data) +
                            parseFloat(Heat[6].Heat_smp_set) ||
                            parseFloat(Heat[6].Heat_actual_4) <
                            parseFloat(Heat[6].Heat_smp_data) -
                            parseFloat(Heat[6].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[6].Heat_actual_4}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6], Heat_actual_4: e.target.value },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat7-4"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[6].Heat_actual_5) >
                            parseFloat(Heat[6].Heat_smp_data) +
                            parseFloat(Heat[6].Heat_smp_set) ||
                            parseFloat(Heat[6].Heat_actual_5) <
                            parseFloat(Heat[6].Heat_smp_data) -
                            parseFloat(Heat[6].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[6].Heat_actual_5}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6], Heat_actual_5: e.target.value },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat7-5"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[6].Heat_actual_6) >
                            parseFloat(Heat[6].Heat_smp_data) +
                            parseFloat(Heat[6].Heat_smp_set) ||
                            parseFloat(Heat[6].Heat_actual_6) <
                            parseFloat(Heat[6].Heat_smp_data) -
                            parseFloat(Heat[6].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[6].Heat_actual_6}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6], Heat_actual_6: e.target.value },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat7-6"
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>08</td>
                    <td>Zone 5</td>
                    <td>On pannel</td>
                    <td>
                      ({Heat[7].Heat_smp_data})±{Heat[7].Heat_smp_set}°C
                    </td>
                    <td>°C</td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[7].Heat_actual_1) >
                            parseFloat(Heat[7].Heat_smp_data) +
                            parseFloat(Heat[7].Heat_smp_set) ||
                            parseFloat(Heat[7].Heat_actual_1) <
                            parseFloat(Heat[7].Heat_smp_data) -
                            parseFloat(Heat[7].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[7].Heat_actual_1}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7], Heat_actual_1: e.target.value },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat8-1"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[7].Heat_actual_2) >
                            parseFloat(Heat[7].Heat_smp_data) +
                            parseFloat(Heat[7].Heat_smp_set) ||
                            parseFloat(Heat[7].Heat_actual_2) <
                            parseFloat(Heat[7].Heat_smp_data) -
                            parseFloat(Heat[7].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[7].Heat_actual_2}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7], Heat_actual_2: e.target.value },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat8-2"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[7].Heat_actual_3) >
                            parseFloat(Heat[7].Heat_smp_data) +
                            parseFloat(Heat[7].Heat_smp_set) ||
                            parseFloat(Heat[7].Heat_actual_3) <
                            parseFloat(Heat[7].Heat_smp_data) -
                            parseFloat(Heat[7].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[7].Heat_actual_3}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7], Heat_actual_3: e.target.value },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat8-3"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[7].Heat_actual_4) >
                            parseFloat(Heat[7].Heat_smp_data) +
                            parseFloat(Heat[7].Heat_smp_set) ||
                            parseFloat(Heat[7].Heat_actual_4) <
                            parseFloat(Heat[7].Heat_smp_data) -
                            parseFloat(Heat[7].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[7].Heat_actual_4}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7], Heat_actual_4: e.target.value },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat8-4"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[7].Heat_actual_5) >
                            parseFloat(Heat[7].Heat_smp_data) +
                            parseFloat(Heat[7].Heat_smp_set) ||
                            parseFloat(Heat[7].Heat_actual_5) <
                            parseFloat(Heat[7].Heat_smp_data) -
                            parseFloat(Heat[7].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[7].Heat_actual_5}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7], Heat_actual_5: e.target.value },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat8-5"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[7].Heat_actual_6) >
                            parseFloat(Heat[7].Heat_smp_data) +
                            parseFloat(Heat[7].Heat_smp_set) ||
                            parseFloat(Heat[7].Heat_actual_6) <
                            parseFloat(Heat[7].Heat_smp_data) -
                            parseFloat(Heat[7].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[7].Heat_actual_6}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7], Heat_actual_6: e.target.value },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat8-6"
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>09</td>
                    <td>Zone 6</td>
                    <td>On pannel</td>
                    <td>
                      ({Heat[8].Heat_smp_data})±{Heat[8].Heat_smp_set}°C
                    </td>
                    <td>°C</td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[8].Heat_actual_1) >
                            parseFloat(Heat[8].Heat_smp_data) +
                            parseFloat(Heat[8].Heat_smp_set) ||
                            parseFloat(Heat[8].Heat_actual_1) <
                            parseFloat(Heat[8].Heat_smp_data) -
                            parseFloat(Heat[8].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[8].Heat_actual_1}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8], Heat_actual_1: e.target.value },
                          ]);
                        }}
                        id="Heat9-1"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[8].Heat_actual_2) >
                            parseFloat(Heat[8].Heat_smp_data) +
                            parseFloat(Heat[8].Heat_smp_set) ||
                            parseFloat(Heat[8].Heat_actual_2) <
                            parseFloat(Heat[8].Heat_smp_data) -
                            parseFloat(Heat[8].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[8].Heat_actual_2}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8], Heat_actual_2: e.target.value },
                          ]);
                        }}
                        id="Heat9-2"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[8].Heat_actual_3) >
                            parseFloat(Heat[8].Heat_smp_data) +
                            parseFloat(Heat[8].Heat_smp_set) ||
                            parseFloat(Heat[8].Heat_actual_3) <
                            parseFloat(Heat[8].Heat_smp_data) -
                            parseFloat(Heat[8].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[8].Heat_actual_3}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8], Heat_actual_3: e.target.value },
                          ]);
                        }}
                        id="Heat9-3"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[8].Heat_actual_4) >
                            parseFloat(Heat[8].Heat_smp_data) +
                            parseFloat(Heat[8].Heat_smp_set) ||
                            parseFloat(Heat[8].Heat_actual_4) <
                            parseFloat(Heat[8].Heat_smp_data) -
                            parseFloat(Heat[8].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[8].Heat_actual_4}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8], Heat_actual_4: e.target.value },
                          ]);
                        }}
                        id="Heat9-4"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[8].Heat_actual_5) >
                            parseFloat(Heat[8].Heat_smp_data) +
                            parseFloat(Heat[8].Heat_smp_set) ||
                            parseFloat(Heat[8].Heat_actual_5) <
                            parseFloat(Heat[8].Heat_smp_data) -
                            parseFloat(Heat[8].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[8].Heat_actual_5}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8], Heat_actual_5: e.target.value },
                          ]);
                        }}
                        id="Heat9-5"
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[8].Heat_actual_6) >
                            parseFloat(Heat[8].Heat_smp_data) +
                            parseFloat(Heat[8].Heat_smp_set) ||
                            parseFloat(Heat[8].Heat_actual_6) <
                            parseFloat(Heat[8].Heat_smp_data) -
                            parseFloat(Heat[8].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[8].Heat_actual_6}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8], Heat_actual_6: e.target.value },
                          ]);
                        }}
                        id="Heat9-6"
                        disabled={true}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                className="table"
                width="50%"
                style={{ textAlign: "center" }}
                border="0"
              >
                <tbody>
                  <tr style={{ textAlign: "center" }}>
                    <th style={{ textAlign: "center" }} colSpan="15">
                      Injection parameter
                    </th>
                  </tr>
                  <tr>
                    <th
                      width="10%"
                      style={{ textAlign: "center", width: "1%" }}
                    >
                      SL
                    </th>
                    <th width="35%" style={{ textAlign: "center" }}>
                      Description
                    </th>
                    <th
                      width="25%"
                      style={{ textAlign: "center", width: "8%" }}
                    >
                      Inst-used
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Set
                    </th>
                    <th
                      width="5%"
                      style={{
                        textAlign: "center",
                        width: "7%",
                        padding: "0px 20px",
                      }}
                    >
                      Units
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Actual
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Actual
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Actual
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Actual
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Actual
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Actual
                    </th>
                  </tr>
                  {Inject.map((item) => (
                    <tr keys={item.Inject_sl}>
                      <td>{item.Inject_sl}</td>
                      <td>{item.Inject_desc}</td>
                      <td>{item.Inject_inst_used}</td>
                      <td>
                        ({item.Inject_smp_data})±{item.Inject_smp_set}
                      </td>
                      <td>{item.Inject_units}</td>
                      <td>
                        <input
                          id={"cycle1-" + item.Inject_sl}
                          type="text"
                          style={
                            !(
                              parseFloat(item.Inject_actual_1) >
                              parseFloat(item.Inject_smp_data) +
                              parseFloat(item.Inject_smp_set) ||
                              parseFloat(item.Inject_actual_1) <
                              parseFloat(item.Inject_smp_data) -
                              parseFloat(item.Inject_smp_set)
                            )
                              ? { width: "100%" }
                              : {
                                width: "100%",
                                backgroundColor: "#d98984",
                                border: "2px solid #DA251A",
                              }
                          }
                          value={item.Inject_actual_1}
                          onChange={(e) => {
                            setInject((data) => {
                              let Value = [...Inject];
                              Value[Number(item.Inject_sl) - 1] = {
                                ...Inject[Number(item.Inject_sl) - 1],
                                Inject_actual_1: e.target.value,
                              };
                              return Value;
                            });
                          }}
                          disabled={true}
                        />
                      </td>
                      <td>
                        <input
                          id={"cycle2-" + item.Inject_sl}
                          type="text"
                          value={item.Inject_actual_2}
                          style={
                            !(
                              parseFloat(item.Inject_actual_2) >
                              parseFloat(item.Inject_smp_data) +
                              parseFloat(item.Inject_smp_set) ||
                              parseFloat(item.Inject_actual_2) <
                              parseFloat(item.Inject_smp_data) -
                              parseFloat(item.Inject_smp_set)
                            )
                              ? { width: "100%" }
                              : {
                                width: "100%",
                                backgroundColor: "#d98984",
                                border: "2px solid #DA251A",
                              }
                          }
                          onChange={(e) => {
                            setInject((data) => {
                              let Value = [...Inject];
                              Value[Number(item.Inject_sl) - 1] = {
                                ...Inject[Number(item.Inject_sl) - 1],
                                Inject_actual_2: e.target.value,
                              };
                              return Value;
                            });
                          }}
                          disabled={true}
                        />
                      </td>
                      <td>
                        <input
                          id={"cycle3-" + item.Inject_sl}
                          type="text"
                          style={
                            !(
                              parseFloat(item.Inject_actual_3) >
                              parseFloat(item.Inject_smp_data) +
                              parseFloat(item.Inject_smp_set) ||
                              parseFloat(item.Inject_actual_3) <
                              parseFloat(item.Inject_smp_data) -
                              parseFloat(item.Inject_smp_set)
                            )
                              ? { width: "100%" }
                              : {
                                width: "100%",
                                backgroundColor: "#d98984",
                                border: "2px solid #DA251A",
                              }
                          }
                          value={item.Inject_actual_3}
                          onChange={(e) => {
                            setInject((data) => {
                              let Value = [...Inject];
                              Value[Number(item.Inject_sl) - 1] = {
                                ...Inject[Number(item.Inject_sl) - 1],
                                Inject_actual_3: e.target.value,
                              };
                              return Value;
                            });
                          }}
                          disabled={true}
                        />
                      </td>
                      <td>
                        <input
                          id={"cycle4-" + item.Inject_sl}
                          type="text"
                          style={
                            !(
                              parseFloat(item.Inject_actual_4) >
                              parseFloat(item.Inject_smp_data) +
                              parseFloat(item.Inject_smp_set) ||
                              parseFloat(item.Inject_actual_4) <
                              parseFloat(item.Inject_smp_data) -
                              parseFloat(item.Inject_smp_set)
                            )
                              ? { width: "100%" }
                              : {
                                width: "100%",
                                backgroundColor: "#d98984",
                                border: "2px solid #DA251A",
                              }
                          }
                          value={item.Inject_actual_4}
                          onChange={(e) => {
                            setInject((data) => {
                              let Value = [...Inject];
                              Value[Number(item.Inject_sl) - 1] = {
                                ...Inject[Number(item.Inject_sl) - 1],
                                Inject_actual_4: e.target.value,
                              };
                              return Value;
                            });
                          }}
                          disabled={true}
                        />
                      </td>
                      <td>
                        <input
                          id={"cycle5-" + item.Inject_sl}
                          type="text"
                          style={
                            !(
                              parseFloat(item.Inject_actual_5) >
                              parseFloat(item.Inject_smp_data) +
                              parseFloat(item.Inject_smp_set) ||
                              parseFloat(item.Inject_actual_5) <
                              parseFloat(item.Inject_smp_data) -
                              parseFloat(item.Inject_smp_set)
                            )
                              ? { width: "100%" }
                              : {
                                width: "100%",
                                backgroundColor: "#d98984",
                                border: "2px solid #DA251A",
                              }
                          }
                          value={item.Inject_actual_5}
                          onChange={(e) => {
                            setInject((data) => {
                              let Value = [...Inject];
                              Value[Number(item.Inject_sl) - 1] = {
                                ...Inject[Number(item.Inject_sl) - 1],
                                Inject_actual_5: e.target.value,
                              };
                              return Value;
                            });
                          }}
                          disabled={true}
                        />
                      </td>
                      <td>
                        <input
                          id={"cycle6-" + item.Inject_sl}
                          type="text"
                          style={
                            !(
                              parseFloat(item.Inject_actual_6) >
                              parseFloat(item.Inject_smp_data) +
                              parseFloat(item.Inject_smp_set) ||
                              parseFloat(item.Inject_actual_6) <
                              parseFloat(item.Inject_smp_data) -
                              parseFloat(item.Inject_smp_set)
                            )
                              ? { width: "100%" }
                              : {
                                width: "100%",
                                backgroundColor: "#d98984",
                                border: "2px solid #DA251A",
                              }
                          }
                          value={item.Inject_actual_6}
                          onChange={(e) => {
                            setInject((data) => {
                              let Value = [...Inject];
                              Value[Number(item.Inject_sl) - 1] = {
                                ...Inject[Number(item.Inject_sl) - 1],
                                Inject_actual_6: e.target.value,
                              };
                              return Value;
                            });
                          }}
                          disabled={true}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <table
                className="table_hi"
                width="50%"
                style={{ textAlign: "center" }}
                border="5"
              >
                <tbody>
                  <tr style={{ textAlign: "center" }}>
                    <th style={{ textAlign: "center" }} colSpan="10">
                      Mould Core & Cavity Temperature Monitoring
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{ width: "3.5%", textAlign: "center" }}
                      width="5%"
                    >
                      A
                    </th>
                    <th style={{ textAlign: "center" }} width="25%">
                      Normal condition
                    </th>
                    <th style={{ textAlign: "center", width: "5%" }}>Set</th>
                    <th style={{ textAlign: "center" }}>Actual value</th>
                  </tr>
                  <tr>
                    <th colSpan="3"></th>
                    <td width="50">
                      <table className="table_hi">
                        <tr valign="top">
                          <th width="30%" style={{ textAlign: "center" }}>
                            Shift-A
                          </th>
                          <th width="30%" style={{ textAlign: "center" }}>
                            Shift-B
                          </th>
                          <th width="30%" style={{ textAlign: "center" }}>
                            Shift-C
                          </th>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>TEMPERATURE of core side while machine running</td>
                    <td>
                      ({PMS[0].Core})±{PMS[0].CoreSet}°C
                    </td>
                    <td>
                      <table className="table_hi">
                        <tbody>
                          <tr>
                            <td width="30%">
                              <input
                                type="text"
                                style={
                                  !(
                                    parseFloat(PMS[0].Tempcore1) >
                                    parseFloat(PMS[0].Core) +
                                    parseFloat(PMS[0].CoreSet) ||
                                    parseFloat(PMS[0].Tempcore1) <
                                    parseFloat(PMS[0].Core) -
                                    parseFloat(PMS[0].CoreSet)
                                  )
                                    ? { width: "95%" }
                                    : {
                                      width: "95%",
                                      backgroundColor: "#d98984",
                                      border: "2px solid #DA251A",
                                    }
                                }
                                value={PMS[0].Tempcore1}
                                name="Tempcore1"
                                onChange={HandleFeildChangePMS}
                                disabled
                              />
                            </td>
                            <td width="30%">
                              <input
                                type="text"
                                value={PMS[0].Tempcore2}
                                style={
                                  !(
                                    parseFloat(PMS[0].Tempcore2) >
                                    parseFloat(PMS[0].Core) +
                                    parseFloat(PMS[0].CoreSet) ||
                                    parseFloat(PMS[0].Tempcore2) <
                                    parseFloat(PMS[0].Core) -
                                    parseFloat(PMS[0].CoreSet)
                                  )
                                    ? { width: "95%" }
                                    : {
                                      width: "95%",
                                      backgroundColor: "#d98984",
                                      border: "2px solid #DA251A",
                                    }
                                }
                                disabled
                                name="Tempcore2"
                                onChange={HandleFeildChangePMS}
                              />
                            </td>
                            <td width="30%">
                              <input
                                type="text"
                                style={
                                  !(
                                    parseFloat(PMS[0].Tempcore3) >
                                    parseFloat(PMS[0].Core) +
                                    parseFloat(PMS[0].CoreSet) ||
                                    parseFloat(PMS[0].Tempcore3) <
                                    parseFloat(PMS[0].Core) -
                                    parseFloat(PMS[0].CoreSet)
                                  )
                                    ? { width: "95%" }
                                    : {
                                      width: "95%",
                                      backgroundColor: "#d98984",
                                      border: "2px solid #DA251A",
                                    }
                                }
                                disabled
                                value={PMS[0].Tempcore3}
                                name="Tempcore3"
                                onChange={HandleFeildChangePMS}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>02</td>
                    <td>TEMPERATURE of CAVITY side while machine running</td>
                    <td>
                      ({PMS[0].Cavity})±{PMS[0].CavSet}°C
                    </td>
                    <td>
                      <table className="table_hi">
                        <tbody>
                          <tr>
                            <td width="30%">
                              <input
                                type="text"
                                style={
                                  !(
                                    parseFloat(PMS[0].Tempcav1) >
                                    parseFloat(PMS[0].Cavity) +
                                    parseFloat(PMS[0].CavSet) ||
                                    parseFloat(PMS[0].Tempcav1) <
                                    parseFloat(PMS[0].Cavity) -
                                    parseFloat(PMS[0].CavSet)
                                  )
                                    ? { width: "95%" }
                                    : {
                                      width: "95%",
                                      backgroundColor: "#d98984",
                                      border: "2px solid #DA251A",
                                    }
                                }
                                disabled
                                value={PMS[0].Tempcav1}
                                name="Tempcav1"
                                onChange={HandleFeildChangePMS}
                              />
                            </td>
                            <td width="30%">
                              <input
                                type="text"
                                style={
                                  !(
                                    parseFloat(PMS[0].Tempcav2) >
                                    parseFloat(PMS[0].Cavity) +
                                    parseFloat(PMS[0].CavSet) ||
                                    parseFloat(PMS[0].Tempcav2) <
                                    parseFloat(PMS[0].Cavity) -
                                    parseFloat(PMS[0].CavSet)
                                  )
                                    ? { width: "95%" }
                                    : {
                                      width: "95%",
                                      backgroundColor: "#d98984",
                                      border: "2px solid #DA251A",
                                    }
                                }
                                disabled
                                value={PMS[0].Tempcav2}
                                name="Tempcav2"
                                onChange={HandleFeildChangePMS}
                              />
                            </td>
                            <td width="30%">
                              <input
                                type="text"
                                style={
                                  !(
                                    parseFloat(PMS[0].Tempcav3) >
                                    parseFloat(PMS[0].Cavity) +
                                    parseFloat(PMS[0].CavSet) ||
                                    parseFloat(PMS[0].Tempcav3) <
                                    parseFloat(PMS[0].Cavity) -
                                    parseFloat(PMS[0].CavSet)
                                  )
                                    ? { width: "95%" }
                                    : {
                                      width: "95%",
                                      backgroundColor: "#d98984",
                                      border: "2px solid #DA251A",
                                    }
                                }
                                disabled
                                value={PMS[0].Tempcav3}
                                name="Tempcav3"
                                onChange={HandleFeildChangePMS}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                className="table_hi"
                width="50%"
                style={{ textAlign: "center" }}
                border="5"
              >
                <tbody>
                  <tr>
                    <th
                      style={{ width: "3.5%", textAlign: "center" }}
                      width="5%"
                    >
                      B
                    </th>
                    <th style={{ textAlign: "center" }} width="25%">
                      With M T C
                    </th>
                    <th style={{ textAlign: "center", width: "5%" }}>Set</th>
                    <th style={{ textAlign: "center" }}>Actual value</th>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>TEMPERATURE of core side while machine running</td>
                    <td>
                      ({PMS[0].Core})±{PMS[0].CoreSet}°C
                    </td>
                    <td>
                      <table className="table_hi">
                        <tbody>
                          <tr>
                            <td width="30%">
                              <input
                                type="text"
                                style={
                                  !(
                                    parseFloat(PMS[0].Tempcore4) >
                                    parseFloat(PMS[0].Core) +
                                    parseFloat(PMS[0].CoreSet) ||
                                    parseFloat(PMS[0].Tempcore4) <
                                    parseFloat(PMS[0].Core) -
                                    parseFloat(PMS[0].CoreSet)
                                  )
                                    ? { width: "95%" }
                                    : {
                                      width: "95%",
                                      backgroundColor: "#d98984",
                                      border: "2px solid #DA251A",
                                    }
                                }
                                disabled
                                value={PMS[0].Tempcore4}
                                name="Tempcore4"
                                onChange={HandleFeildChangePMS}
                              />
                            </td>
                            <td width="30%">
                              <input
                                type="text"
                                style={
                                  !(
                                    parseFloat(PMS[0].Tempcore5) >
                                    parseFloat(PMS[0].Core) +
                                    parseFloat(PMS[0].CoreSet) ||
                                    parseFloat(PMS[0].Tempcore5) <
                                    parseFloat(PMS[0].Core) -
                                    parseFloat(PMS[0].CoreSet)
                                  )
                                    ? { width: "95%" }
                                    : {
                                      width: "95%",
                                      backgroundColor: "#d98984",
                                      border: "2px solid #DA251A",
                                    }
                                }
                                disabled
                                value={PMS[0].Tempcore5}
                                name="Tempcore5"
                                onChange={HandleFeildChangePMS}
                              />
                            </td>
                            <td width="30%">
                              <input
                                type="text"
                                style={
                                  !(
                                    parseFloat(PMS[0].Tempcore6) >
                                    parseFloat(PMS[0].Core) +
                                    parseFloat(PMS[0].CoreSet) ||
                                    parseFloat(PMS[0].Tempcore6) <
                                    parseFloat(PMS[0].Core) -
                                    parseFloat(PMS[0].CoreSet)
                                  )
                                    ? { width: "95%" }
                                    : {
                                      width: "95%",
                                      backgroundColor: "#d98984",
                                      border: "2px solid #DA251A",
                                    }
                                }
                                disabled
                                value={PMS[0].Tempcore6}
                                name="Tempcore6"
                                onChange={HandleFeildChangePMS}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>02</td>
                    <td>TEMPERATURE of CAVITY side while machine running</td>
                    <td>
                      ({PMS[0].Cavity})±{PMS[0].CavSet}°C
                    </td>
                    <td>
                      <table className="table_hi">
                        <tbody>
                          <tr>
                            <td width="30%">
                              <input
                                type="text"
                                style={
                                  !(
                                    parseFloat(PMS[0].Tempcav4) >
                                    parseFloat(PMS[0].Cavity) +
                                    parseFloat(PMS[0].CavSet) ||
                                    parseFloat(PMS[0].Tempcav4) <
                                    parseFloat(PMS[0].Cavity) -
                                    parseFloat(PMS[0].CavSet)
                                  )
                                    ? { width: "95%" }
                                    : {
                                      width: "95%",
                                      backgroundColor: "#d98984",
                                      border: "2px solid #DA251A",
                                    }
                                }
                                disabled
                                value={PMS[0].Tempcav4}
                                name="Tempcav4"
                                onChange={HandleFeildChangePMS}
                              />
                            </td>
                            <td width="30%">
                              <input
                                type="text"
                                style={
                                  !(
                                    parseFloat(PMS[0].Tempcav5) >
                                    parseFloat(PMS[0].Cavity) +
                                    parseFloat(PMS[0].CavSet) ||
                                    parseFloat(PMS[0].Tempcav5) <
                                    parseFloat(PMS[0].Cavity) -
                                    parseFloat(PMS[0].CavSet)
                                  )
                                    ? { width: "95%" }
                                    : {
                                      width: "95%",
                                      backgroundColor: "#d98984",
                                      border: "2px solid #DA251A",
                                    }
                                }
                                disabled
                                value={PMS[0].Tempcav5}
                                name="Tempcav5"
                                onChange={HandleFeildChangePMS}
                              />
                            </td>
                            <td width="30%">
                              <input
                                type="text"
                                style={
                                  !(
                                    parseFloat(PMS[0].Tempcav6) >
                                    parseFloat(PMS[0].Cavity) +
                                    parseFloat(PMS[0].CavSet) ||
                                    parseFloat(PMS[0].Tempcav6) <
                                    parseFloat(PMS[0].Cavity) -
                                    parseFloat(PMS[0].CavSet)
                                  )
                                    ? { width: "95%" }
                                    : {
                                      width: "95%",
                                      backgroundColor: "#d98984",
                                      border: "2px solid #DA251A",
                                    }
                                }
                                disabled
                                value={PMS[0].Tempcav6}
                                name="Tempcav6"
                                onChange={HandleFeildChangePMS}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                className="table_hi"
                width="50%"
                style={{ textAlign: "center" }}
                border="5"
              >
                <tbody>

                  <tr>
                    <td style={{ width: "33.5%" }}>
                      SIGNATURE OF THE SHIFT INCHARGE - PRODUCTION
                    </td>
                    <td>
                      <table className="table_hi">
                        <tbody>
                          <tr>
                            <td width="30%">
                              <Select
                                id="dropdown"
                                styles={{ width: "100%" }}
                                options={empDetails.map((item) => ({
                                  value: item.Emp_code,
                                  label: item.Emp_name,
                                }))}
                                value={{
                                  value: EmpName.ShiftInchargeShiftA,
                                  label: EmpName.ShiftInchargeShiftAEmp_name,
                                }}
                                onChange={handleShiftInchargeShiftA}
                                isDisabled={true}
                                placeholder="Select Machine No"
                              />
                            </td>
                            <td width="30%">
                              <Select
                                id="dropdown"
                                styles={{ width: "100%" }}
                                options={empDetails.map((item) => ({
                                  value: item.Emp_code,
                                  label: item.Emp_name,
                                }))}
                                value={{
                                  value: EmpName.ShiftInchargeShiftB,
                                  label: EmpName.ShiftInchargeShiftBEmp_name,
                                }}
                                onChange={handleShiftInchargeShiftB}
                                isDisabled={true}
                                placeholder="Select Machine No"
                              />
                            </td>
                            <td width="30%">
                              <Select
                                id="dropdown"
                                styles={{ width: "100%" }}
                                options={empDetails.map((item) => ({
                                  value: item.Emp_code,
                                  label: item.Emp_name,
                                }))}
                                value={{
                                  value: EmpName.ShiftInchargeShiftC,
                                  label: EmpName.ShiftInchargeShiftCEmp_name,
                                }}
                                onChange={handleShiftInchargeShiftC}
                                placeholder="Select Machine No"
                                isDisabled={true}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>SIGNATURE OF THE ON LINE INSPECTION - QUALITY</td>

                    <td colSpan={4}>
                      <table className="table_hi">
                        <tbody>
                          <tr>
                            <td width="30%">
                              <Select
                                id="dropdown"
                                styles={{ width: "100%" }}
                                options={empDetails.map((item) => ({
                                  value: item.Emp_code,
                                  label: item.Emp_name,
                                }))}
                                value={{
                                  value: EmpName.LineInspecQaShiftA,
                                  label: EmpName.LineInspecQaShiftAEmp_name,
                                }}
                                onChange={handleLineInspecQaShiftA}
                                isDisabled={true}
                                placeholder="Select Machine No"
                              />
                            </td>
                            <td width="30%">
                              <Select
                                id="dropdown"
                                styles={{ width: "100%" }}
                                options={empDetails.map((item) => ({
                                  value: item.Emp_code,
                                  label: item.Emp_name,
                                }))}
                                value={{
                                  value: EmpName.LineInspecQaShiftB,
                                  label: EmpName.LineInspecQaShiftBEmp_name,
                                }}
                                onChange={handleLineInspecQaShiftB}
                                isDisabled={true}
                                placeholder="Select Machine No"
                              />
                            </td>
                            <td width="30%">
                              <Select
                                id="dropdown"
                                styles={{ width: "100%" }}
                                options={empDetails.map((item) => ({
                                  value: item.Emp_code,
                                  label: item.Emp_name,
                                }))}
                                value={{
                                  value: EmpName.LineInspecQaShiftC,
                                  label: EmpName.LineInspecQaShiftCEmp_name,
                                }}
                                onChange={handleLineInspecQaShiftC}
                                isDisabled={true}
                                placeholder="Select Machine No"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  {/* <tr>
                    <td colSpan="4" style={{ border: "none" }}>
                      {New === undefined ? (
                        ""
                      ) : New ? (
                        <button
                          type="submit"
                          // onClick={(e) => handleCreate(e)}
                        >
                          Submit
                        </button>
                      ) : Insert ? (
                        <button
                          type="submit"
                          onClick={(e) => handleUpdateCreate(e)}
                        >
                          Insert & Update
                        </button>
                      ) : (
                        <button type="submit" onClick={(e) => handleUpdate(e)}>
                          Update
                        </button>
                      )}
                    </td>
                  </tr>  */}
                </tbody>
              </table>
            </form>
            </div>
          </div>
        </Popup>
      </div>

      <div>
        <Popup
          trigger={ButtonPopup}
          setTrigger={setButtonPopup}
          viewPagination={viewPagination}
        >
          <div>
            <form
              className="form-pms-Create1"
              onSubmit={(e) => {
                e.preventDefault();
                handleCreate(e);
              }}
            >
              <table
                className="table"
                width="100%"
                style={{ textAlign: "center" }}
                border="0"
              >
                <tbody>
                  <tr style={{ textAlign: "center" }}>
                    <th style={{ textAlign: "center" }} colSpan="4">
                      <font color="#8B0000">PROCESS MONITORING SYSTEM</font>
                    </th>
                  </tr>
                  <tr>
                    <td>Format No</td>
                    <td style={{ textAlign: "center" }}>
                      <label>UPCL/PRO/R/28</label>
                    </td>

                    <td>Rev No</td>
                    <td style={{ textAlign: "center" }}>
                      <label>02</label>
                    </td>
                  </tr>
                  <tr>
                    <td>Track Date</td>
                    <td>
                      <DatePicker value={PMS[0].TrackDate} dis={true} />
                    </td>
                    <td>
                      M/C NO<span style={{ color: "red" }}>*</span>
                    </td>
                    <td>
                      {PMS[0].Mcno === undefined && (
                        <span className="Req">Required *</span>
                      )}
                      <Select
                        id="dropdown"
                        styles={{ width: "100%" }}
                        options={Machine.map((item) => ({
                          value: item.McNo,
                          label: item.mc_name,
                        }))}
                        value={{
                          value: PMS[0].Mcno,
                          label: PMS[0].Mcno,
                        }}
                        onChange={handleMachine}
                        placeholder="Select Machine No"
                        required
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                className="table"
                width="50%"
                style={{ textAlign: "left" }}
                border="0"
              >
                <tbody>
                  <tr>
                    <th colSpan="15"></th>
                  </tr>
                  <tr style={{ textAlign: "center" }}>
                    <td colSpan="2">Part weight1 (-{PMS[0].PartWeightMinus1}/+{PMS[0].PartWeightPlus1})</td>

                    <td>
                      <input
                        style={{ width: "100%" }}
                        type="number"
                        name="PartWeight1"
                        value={PMS[0].PartWeight1}
                        onChange={HandleFeildChangePMS}
                        disabled
                      />
                    </td>
                    <td></td>
                    <td>
                      <input
                        style={
                          !(
                            parseFloat(PMS[0].PW1) >
                            parseFloat(PMS[0].PartWeight1) +
                            parseFloat(PMS[0].PartWeightPlus1) ||
                            parseFloat(PMS[0].PW1) <
                            parseFloat(PMS[0].PartWeight1) -
                            parseFloat(PMS[0].PartWeightMinus1)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        type="number"
                        name="PW1"
                        id="PW1"
                        value={PMS[0].PW1}
                        disabled={Disable.Hour1}
                        onChange={(e) => {
                          HandleFeildChangePMS(e);
                        }}
                      />
                    </td>

                    <td>
                      <input
                        style={
                          !(
                            parseFloat(PMS[0].PW2) >
                            parseFloat(PMS[0].PartWeight1) +
                            parseFloat(PMS[0].PartWeightPlus1) ||
                            parseFloat(PMS[0].PW2) <
                            parseFloat(PMS[0].PartWeight1) -
                            parseFloat(PMS[0].PartWeightMinus1)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        type="number"
                        name="PW2"
                        id="PW2"
                        value={PMS[0].PW2}
                        disabled={Disable.Hour2}
                        onChange={(e) => {
                          HandleFeildChangePMS(e);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        style={
                          !(
                            parseFloat(PMS[0].PW3) >
                            parseFloat(PMS[0].PartWeight1) +
                            parseFloat(PMS[0].PartWeightPlus1) ||
                            parseFloat(PMS[0].PW3) <
                            parseFloat(PMS[0].PartWeight1) -
                            parseFloat(PMS[0].PartWeightMinus1)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        type="number"
                        name="PW3"
                        id="PW3"
                        value={PMS[0].PW3}
                        disabled={Disable.Hour3}
                        onChange={(e) => {
                          HandleFeildChangePMS(e);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        style={
                          !(
                            parseFloat(PMS[0].PW4) >
                            parseFloat(PMS[0].PartWeight1) +
                            parseFloat(PMS[0].PartWeightPlus1) ||
                            parseFloat(PMS[0].PW4) <
                            parseFloat(PMS[0].PartWeight1) -
                            parseFloat(PMS[0].PartWeightMinus1)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        type="number"
                        name="PW4"
                        id="PW4"
                        value={PMS[0].PW4}
                        disabled={Disable.Hour4}
                        onChange={(e) => {
                          HandleFeildChangePMS(e);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        style={
                          !(
                            parseFloat(PMS[0].PW5) >
                            parseFloat(PMS[0].PartWeight1) +
                            parseFloat(PMS[0].PartWeightPlus1) ||
                            parseFloat(PMS[0].PW5) <
                            parseFloat(PMS[0].PartWeight1) -
                            parseFloat(PMS[0].PartWeightMinus1)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        type="number"
                        name="PW5"
                        id="PW5"
                        value={PMS[0].PW5}
                        disabled={Disable.Hour5}
                        onChange={(e) => {
                          HandleFeildChangePMS(e);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        style={
                          !(
                            parseFloat(PMS[0].PW6) >
                            parseFloat(PMS[0].PartWeight1) +
                            parseFloat(PMS[0].PartWeightPlus1) ||
                            parseFloat(PMS[0].PW6) <
                            parseFloat(PMS[0].PartWeight1) -
                            parseFloat(PMS[0].PartWeightMinus1)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        type="number"
                        name="PW6"
                        id="PW6"
                        value={PMS[0].PW6}
                        disabled={Disable.Hour6}
                        onChange={(e) => {
                          HandleFeildChangePMS(e);
                        }}
                      />
                    </td>
                  </tr>
                  <tr style={{ textAlign: "center" }}>
                    <td colSpan="2">Part weight2 (-{PMS[0].PartWeightMinus2}/+{PMS[0].PartWeightPlus2})</td>

                    <td>
                      <input
                        style={{ width: "100%" }}
                        type="number"
                        name="PartWeight2"
                        value={PMS[0].PartWeight2}
                        onChange={HandleFeildChangePMS}
                        disabled
                      />
                    </td>
                    <td></td>
                    <td>
                      <input
                        style={
                          !(
                            parseFloat(PMS[0].PW11) >
                            parseFloat(PMS[0].PartWeight2) +
                            parseFloat(PMS[0].PartWeightPlus2) ||
                            parseFloat(PMS[0].PW11) <
                            parseFloat(PMS[0].PartWeight2) -
                            parseFloat(PMS[0].PartWeightMinus2)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        type="number"
                        name="PW11"
                        id="PW11"
                        value={PMS[0].PW11}
                        disabled={Disable.Hour1}
                        onChange={(e) => {
                          HandleFeildChangePMS(e);
                        }}
                      />
                    </td>

                    <td>
                      <input
                        style={
                          !(
                            parseFloat(PMS[0].PW21) >
                            parseFloat(PMS[0].PartWeight2) +
                            parseFloat(PMS[0].PartWeightPlus2) ||
                            parseFloat(PMS[0].PW21) <
                            parseFloat(PMS[0].PartWeight2) -
                            parseFloat(PMS[0].PartWeightMinus2)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        type="number"
                        name="PW21"
                        id="PW21"
                        value={PMS[0].PW21}
                        disabled={Disable.Hour2}
                        onChange={(e) => {
                          HandleFeildChangePMS(e);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        style={
                          !(
                            parseFloat(PMS[0].PW31) >
                            parseFloat(PMS[0].PartWeight2) +
                            parseFloat(PMS[0].PartWeightPlus2) ||
                            parseFloat(PMS[0].PW31) <
                            parseFloat(PMS[0].PartWeight2) -
                            parseFloat(PMS[0].PartWeightMinus2)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        type="number"
                        name="PW31"
                        id="PW31"
                        value={PMS[0].PW31}
                        disabled={Disable.Hour3}
                        onChange={(e) => {
                          HandleFeildChangePMS(e);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        style={
                          !(
                            parseFloat(PMS[0].PW41) >
                            parseFloat(PMS[0].PartWeight2) +
                            parseFloat(PMS[0].PartWeightPlus2) ||
                            parseFloat(PMS[0].PW41) <
                            parseFloat(PMS[0].PartWeight2) -
                            parseFloat(PMS[0].PartWeightMinus2)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        type="number"
                        name="PW41"
                        id="PW41"
                        value={PMS[0].PW41}
                        disabled={Disable.Hour4}
                        onChange={(e) => {
                          HandleFeildChangePMS(e);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        style={
                          !(
                            parseFloat(PMS[0].PW51) >
                            parseFloat(PMS[0].PartWeight2) +
                            parseFloat(PMS[0].PartWeightPlus2) ||
                            parseFloat(PMS[0].PW51) <
                            parseFloat(PMS[0].PartWeight2) -
                            parseFloat(PMS[0].PartWeightMinus2)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        type="number"
                        name="PW51"
                        id="PW51"
                        value={PMS[0].PW51}
                        disabled={Disable.Hour5}
                        onChange={(e) => {
                          HandleFeildChangePMS(e);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        style={
                          !(
                            parseFloat(PMS[0].PW61) >
                            parseFloat(PMS[0].PartWeight2) +
                            parseFloat(PMS[0].PartWeightPlus2) ||
                            parseFloat(PMS[0].PW61) <
                            parseFloat(PMS[0].PartWeight2) -
                            parseFloat(PMS[0].PartWeightMinus2)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        type="number"
                        name="PW61"
                        id="PW61"
                        value={PMS[0].PW61}
                        disabled={Disable.Hour6}
                        onChange={(e) => {
                          HandleFeildChangePMS(e);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th width="7%" style={{ textAlign: "center", width: "1%" }}>
                      SL
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        whiteSpace: "nowrap",
                        width: "15.5%",
                      }}
                    >
                      Description
                    </th>
                    <th
                      width="15%"
                      style={{ textAlign: "center", width: "9%" }}
                    >
                      Inst used
                    </th>
                    <th
                      width="20%"
                      style={{ textAlign: "center", width: "11%" }}
                    >
                      Raw-Material
                    </th>
                    <th width="7%" style={{ textAlign: "center" }}>
                      Actual Level
                    </th>
                    <th width="7%" style={{ textAlign: "center" }}>
                      Actual Level
                    </th>
                    <th width="7%" style={{ textAlign: "center" }}>
                      Actual Level
                    </th>
                    <th width="7%" style={{ textAlign: "center" }}>
                      Actual Level
                    </th>
                    <th width="7%" style={{ textAlign: "center" }}>
                      Actual Level
                    </th>
                    <th width="7%" style={{ textAlign: "center" }}>
                      Actual Level
                    </th>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center" }}>1</td>
                    <td style={{ whiteSpace: "nowrap" }}>Raw-Material-Level</td>
                    <td>On hooper</td>
                    <td>Min level/Max Level</td>
                    <td>
                      <select
                        id="dropdown"
                        style={{ width: "100%" }}
                        name="RM1"
                        value={PMS[0].RM1}
                        onChange={HandleFeildChangePMS}
                        disabled={Disable.Hour1}
                      >
                        <option value="NA">N A</option>
                        <option value="Active">✓</option>
                        <option value="Inactive">X</option>
                      </select>
                    </td>
                    <td>
                      <select
                        id="dropdown"
                        style={{ width: "100%" }}
                        name="RM2"
                        value={PMS[0].RM2}
                        onChange={HandleFeildChangePMS}
                        disabled={Disable.Hour2}
                      >
                        <option value="NA">N A</option>
                        <option value="Active">✓</option>
                        <option value="Inactive">X</option>
                      </select>
                    </td>

                    <td>
                      <select
                        id="dropdown"
                        style={{ width: "100%" }}
                        name="RM3"
                        value={PMS[0].RM3}
                        onChange={HandleFeildChangePMS}
                        disabled={Disable.Hour3}
                      >
                        <option value="NA">N A</option>
                        <option value="Active">✓</option>
                        <option value="Inactive">X</option>
                      </select>
                    </td>
                    <td>
                      <select
                        id="dropdown"
                        style={{ width: "100%" }}
                        name="RM4"
                        value={PMS[0].RM4}
                        onChange={HandleFeildChangePMS}
                        disabled={Disable.Hour4}
                      >
                        <option value="NA">N A</option>
                        <option value="Active">✓</option>
                        <option value="Inactive">X</option>
                      </select>
                    </td>
                    <td>
                      <select
                        id="dropdown"
                        style={{ width: "100%" }}
                        name="RM5"
                        value={PMS[0].RM5}
                        onChange={HandleFeildChangePMS}
                        disabled={Disable.Hour5}
                      >
                        <option value="NA">N A</option>
                        <option value="Active">✓</option>
                        <option value="Inactive">X</option>
                      </select>
                    </td>
                    <td>
                      <select
                        id="dropdown"
                        style={{ width: "100%" }}
                        name="RM6"
                        value={PMS[0].RM6}
                        onChange={HandleFeildChangePMS}
                        disabled={Disable.Hour6}
                      >
                        <option value="NA">N A</option>
                        <option value="Active">✓</option>
                        <option value="Inactive">X</option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                className="table"
                width="50%"
                style={{ textAlign: "center" }}
                border="0"
              >
                <tbody>
                  <tr style={{ textAlign: "center" }}>
                    <th style={{ textAlign: "center" }} colSpan="15">
                      Tracking Machine
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        textAlign: "center",
                        whiteSpace: "nowrap",
                        width: "39%",
                      }}
                    >
                      Time to be updated
                    </th>
                    <th
                      width="5%"
                      style={{
                        textAlign: "center",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      07.00 AM - 08.00 AM
                    </th>
                    <th
                      width="5%"
                      style={{
                        textAlign: "center",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      11.00 AM - 12.00 AM
                    </th>
                    <th
                      width="5%"
                      style={{
                        textAlign: "center",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      15.00 PM - 16.00 PM
                    </th>
                    <th
                      width="5%"
                      style={{
                        textAlign: "center",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      19.00 PM - 20.00 PM
                    </th>
                    <th
                      width="5%"
                      style={{
                        textAlign: "center",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      23.00 PM - 01.00 AM
                    </th>
                    <th
                      width="5%"
                      style={{
                        textAlign: "center",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      03.00 AM - 04.00 AM
                    </th>
                  </tr>
                  <tr>
                    <td>Actual updated time</td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[0].Track_7_am}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0], Track_7_am: e.target.value },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3] },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[0].Track_11_am}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0], Track_11_am: e.target.value },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3] },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[0].Track_15_pm}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0], Track_15_pm: e.target.value },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3] },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[0].Track_19_pm}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0], Track_19_pm: e.target.value },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3] },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[0].Track_23_pm}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0], Track_23_pm: e.target.value },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3] },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[0].Track_3_am}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0], Track_3_am: e.target.value },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3] },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Part name</td>
                    <td>
                      <select
                        required
                        id="dropdown"
                        name="PartName1"
                        style={{ width: "100%" }}
                        value={Tracking[1].Track_7_am}
                        onChange={HandlePart7Am}
                        disabled={Disable.Hour1}
                      >
                        <option value="">Select Part Name</option>
                        <option value="IDEAL">IDEAL</option>
                        <option value="MOULD CHANGE">MOULD CHANGE</option>
                        {Part.map((i, index) => (
                          <option key={index} value={i.PrtNme}>
                            {i.PrtNme}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <select
                        id="dropdown"
                        style={{ width: "100%" }}
                        value={Tracking[1].Track_11_am}
                        onChange={HandlePart11Am}
                        disabled={Disable.Hour2}
                      >
                        <option value="">Select Part Name</option>
                        <option value="IDEAL">IDEAL</option>
                        <option value="MOULD CHANGE">MOULD CHANGE</option>
                        {Part.map((i, index) => (
                          <option key={index} value={i.PrtNme}>
                            {i.PrtNme}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <select
                        id="dropdown"
                        style={{ width: "100%" }}
                        value={Tracking[1].Track_15_pm}
                        onChange={HandlePart15Pm}
                        disabled={Disable.Hour3}
                      >
                        <option value="">Select Part Name</option>
                        <option value="IDEAL">IDEAL</option>
                        <option value="MOULD CHANGE">MOULD CHANGE</option>
                        {Part.map((i, index) => (
                          <option key={index} value={i.PrtNme}>
                            {i.PrtNme}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <select
                        id="dropdown"
                        style={{ width: "100%" }}
                        value={Tracking[1].Track_19_pm}
                        onChange={HandlePart19Pm}
                        disabled={Disable.Hour4}
                      >
                        <option value="">Select Part Name</option>
                        <option value="IDEAL">IDEAL</option>
                        <option value="MOULD CHANGE">MOULD CHANGE</option>
                        {Part.map((i, index) => (
                          <option key={index} value={i.PrtNme}>
                            {i.PrtNme}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <select
                        id="dropdown"
                        style={{ width: "100%" }}
                        value={Tracking[1].Track_23_pm}
                        onChange={HandlePart23Pm}
                        disabled={Disable.Hour5}
                      >
                        <option value="">Select Part Name</option>
                        <option value="IDEAL">IDEAL</option>
                        <option value="MOULD CHANGE">MOULD CHANGE</option>
                        {Part.map((i, index) => (
                          <option key={index} value={i.PrtNme}>
                            {i.PrtNme}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <select
                        id="dropdown"
                        style={{ width: "100%" }}
                        value={Tracking[1].Track_3_am}
                        onChange={HandlePart3Am}
                        disabled={Disable.Hour6}
                      >
                        <option value="">Select Part Name</option>
                        <option value="IDEAL">IDEAL</option>
                        <option value="MOULD CHANGE">MOULD CHANGE</option>
                        {Part.map((i, index) => (
                          <option key={index} value={i.PrtNme}>
                            {i.PrtNme}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td className="lbl">Colour</td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[2].Track_7_am}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2], Track_7_am: e.target.value },
                            { ...Tracking[3] },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[2].Track_11_am}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2], Track_11_am: e.target.value },
                            { ...Tracking[3] },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>

                    <td>
                      <input
                        type="text"
                        value={Tracking[2].Track_15_pm}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2], Track_15_pm: e.target.value },
                            { ...Tracking[3] },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[2].Track_19_pm}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2], Track_19_pm: e.target.value },
                            { ...Tracking[3] },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[2].Track_23_pm}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2], Track_23_pm: e.target.value },
                            { ...Tracking[3] },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[2].Track_3_am}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2], Track_3_am: e.target.value },
                            { ...Tracking[3] },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Material used</td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[3].Track_7_am}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3], Track_7_am: e.target.value },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[3].Track_11_am}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3], Track_11_am: e.target.value },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[3].Track_15_pm}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3], Track_15_pm: e.target.value },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[3].Track_19_pm}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3], Track_19_pm: e.target.value },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[3].Track_23_pm}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3], Track_23_pm: e.target.value },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[3].Track_3_am}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3], Track_3_am: e.target.value },
                            { ...Tracking[4] },
                          ])
                        }
                        disabled
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Material grade</td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[4].Track_7_am}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3] },
                            { ...Tracking[4], Track_7_am: e.target.value },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[4].Track_11_am}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3] },
                            { ...Tracking[4], Track_11_am: e.target.value },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[4].Track_15_pm}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3] },
                            { ...Tracking[4], Track_15_pm: e.target.value },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[4].Track_19_pm}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3] },
                            { ...Tracking[4], Track_19_pm: e.target.value },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[4].Track_23_pm}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3] },
                            { ...Tracking[4], Track_23_pm: e.target.value },
                          ])
                        }
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={Tracking[4].Track_3_am}
                        onChange={(e) =>
                          setTracking([
                            { ...Tracking[0] },
                            { ...Tracking[1] },
                            { ...Tracking[2] },
                            { ...Tracking[3] },
                            { ...Tracking[4], Track_3_am: e.target.value },
                          ])
                        }
                        disabled
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                className="table"
                width="50%"
                style={{ textAlign: "center" }}
                border="0"
              >
                <tbody>
                  <tr style={{ textAlign: "center" }}>
                    <th style={{ textAlign: "center" }} colSpan="15">
                      Heating parameter
                    </th>
                  </tr>
                  <tr>
                    <th width="8%" style={{ textAlign: "center", width: "1%" }}>
                      SL
                    </th>
                    <th width="30%" style={{ textAlign: "center" }}>
                      Description
                    </th>
                    <th
                      width="25%"
                      style={{ textAlign: "center", width: "8%" }}
                    >
                      Inst-used
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Set
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        width: "7%",
                        padding: "0px 20px",
                      }}
                    >
                      Units
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Actual
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Actual
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Actual
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Actual
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Actual
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Actual
                    </th>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>MTC Temperature</td>
                    <td>On pannel</td>
                    <td>
                      {" "}
                      ({Heat[0].Heat_smp_data}) ±{Heat[0].Heat_smp_set}°C
                    </td>
                    <td>°C</td>
                    <td>
                      <input
                        id="Heat1-1"
                        type="text"
                        value={Heat[0].Heat_actual_1}
                        style={
                          !(
                            parseFloat(Heat[0].Heat_actual_1) >
                            parseFloat(Heat[0].Heat_smp_data) +
                            parseFloat(Heat[0].Heat_smp_set) ||
                            parseFloat(Heat[0].Heat_actual_1) <
                            parseFloat(Heat[0].Heat_smp_data) -
                            parseFloat(Heat[0].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0], Heat_actual_1: e.target.value },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        disabled={Disable.Hour1}
                      />
                    </td>
                    <td>
                      <input
                        id="Heat1-2"
                        type="text"
                        value={Heat[0].Heat_actual_2}
                        style={
                          !(
                            parseFloat(Heat[0].Heat_actual_2) >
                            parseFloat(Heat[0].Heat_smp_data) +
                            parseFloat(Heat[0].Heat_smp_set) ||
                            parseFloat(Heat[0].Heat_actual_2) <
                            parseFloat(Heat[0].Heat_smp_data) -
                            parseFloat(Heat[0].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0], Heat_actual_2: e.target.value },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        disabled={Disable.Hour2}
                      />
                    </td>
                    <td>
                      <input
                        id="Heat1-3"
                        type="text"
                        value={Heat[0].Heat_actual_3}
                        style={
                          !(
                            parseFloat(Heat[0].Heat_actual_3) >
                            parseFloat(Heat[0].Heat_smp_data) +
                            parseFloat(Heat[0].Heat_smp_set) ||
                            parseFloat(Heat[0].Heat_actual_3) <
                            parseFloat(Heat[0].Heat_smp_data) -
                            parseFloat(Heat[0].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0], Heat_actual_3: e.target.value },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        disabled={Disable.Hour3}
                      />
                    </td>
                    <td>
                      <input
                        id="Heat1-4"
                        type="text"
                        value={Heat[0].Heat_actual_4}
                        style={
                          !(
                            parseFloat(Heat[0].Heat_actual_4) >
                            parseFloat(Heat[0].Heat_smp_data) +
                            parseFloat(Heat[0].Heat_smp_set) ||
                            parseFloat(Heat[0].Heat_actual_4) <
                            parseFloat(Heat[0].Heat_smp_data) -
                            parseFloat(Heat[0].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0], Heat_actual_4: e.target.value },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        disabled={Disable.Hour4}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        id="Heat1-5"
                        value={Heat[0].Heat_actual_5}
                        style={
                          !(
                            parseFloat(Heat[0].Heat_actual_5) >
                            parseFloat(Heat[0].Heat_smp_data) +
                            parseFloat(Heat[0].Heat_smp_set) ||
                            parseFloat(Heat[0].Heat_actual_5) <
                            parseFloat(Heat[0].Heat_smp_data) -
                            parseFloat(Heat[0].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0], Heat_actual_5: e.target.value },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        disabled={Disable.Hour5}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        id="Heat1-6"
                        value={Heat[0].Heat_actual_6}
                        style={
                          !(
                            parseFloat(Heat[0].Heat_actual_6) >
                            parseFloat(Heat[0].Heat_smp_data) +
                            parseFloat(Heat[0].Heat_smp_set) ||
                            parseFloat(Heat[0].Heat_actual_6) <
                            parseFloat(Heat[0].Heat_smp_data) -
                            parseFloat(Heat[0].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0], Heat_actual_6: e.target.value },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        disabled={Disable.Hour6}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>02</td>
                    <td>Hopper Temperature</td>
                    <td>On pannel</td>
                    <td>
                      {" "}
                      ({Heat[1].Heat_smp_data})±{Heat[1].Heat_smp_set}°C
                    </td>
                    <td>°C</td>
                    <td>
                      <input
                        type="text"
                        value={Heat[1].Heat_actual_1}
                        style={
                          !(
                            parseFloat(Heat[1].Heat_actual_1) >
                            parseFloat(Heat[1].Heat_smp_data) +
                            parseFloat(Heat[1].Heat_smp_set) ||
                            parseFloat(Heat[1].Heat_actual_1) <
                            parseFloat(Heat[1].Heat_smp_data) -
                            parseFloat(Heat[1].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1], Heat_actual_1: e.target.value },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat2-1"
                        disabled={Disable.Hour1}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[1].Heat_actual_2) >
                            parseFloat(Heat[1].Heat_smp_data) +
                            parseFloat(Heat[1].Heat_smp_set) ||
                            parseFloat(Heat[1].Heat_actual_2) <
                            parseFloat(Heat[1].Heat_smp_data) -
                            parseFloat(Heat[1].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[1].Heat_actual_2}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1], Heat_actual_2: e.target.value },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat2-2"
                        disabled={Disable.Hour2}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[1].Heat_actual_3) >
                            parseFloat(Heat[1].Heat_smp_data) +
                            parseFloat(Heat[1].Heat_smp_set) ||
                            parseFloat(Heat[1].Heat_actual_3) <
                            parseFloat(Heat[1].Heat_smp_data) -
                            parseFloat(Heat[1].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[1].Heat_actual_3}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1], Heat_actual_3: e.target.value },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat2-3"
                        disabled={Disable.Hour3}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[1].Heat_actual_4) >
                            parseFloat(Heat[1].Heat_smp_data) +
                            parseFloat(Heat[1].Heat_smp_set) ||
                            parseFloat(Heat[1].Heat_actual_4) <
                            parseFloat(Heat[1].Heat_smp_data) -
                            parseFloat(Heat[1].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[1].Heat_actual_4}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1], Heat_actual_4: e.target.value },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat2-4"
                        disabled={Disable.Hour4}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[1].Heat_actual_5) >
                            parseFloat(Heat[1].Heat_smp_data) +
                            parseFloat(Heat[1].Heat_smp_set) ||
                            parseFloat(Heat[1].Heat_actual_5) <
                            parseFloat(Heat[1].Heat_smp_data) -
                            parseFloat(Heat[1].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[1].Heat_actual_5}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1], Heat_actual_5: e.target.value },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat2-5"
                        disabled={Disable.Hour5}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[1].Heat_actual_6) >
                            parseFloat(Heat[1].Heat_smp_data) +
                            parseFloat(Heat[1].Heat_smp_set) ||
                            parseFloat(Heat[1].Heat_actual_6) <
                            parseFloat(Heat[1].Heat_smp_data) -
                            parseFloat(Heat[1].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[1].Heat_actual_6}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1], Heat_actual_6: e.target.value },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat2-6"
                        disabled={Disable.Hour6}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>03</td>
                    <td>Nozzle Temperature</td>
                    <td>On pannel</td>
                    <td>
                      {" "}
                      ({Heat[2].Heat_smp_data})±{Heat[2].Heat_smp_set}°C
                    </td>
                    <td>°C</td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[2].Heat_actual_1) >
                            parseFloat(Heat[2].Heat_smp_data) +
                            parseFloat(Heat[2].Heat_smp_set) ||
                            parseFloat(Heat[2].Heat_actual_1) <
                            parseFloat(Heat[2].Heat_smp_data) -
                            parseFloat(Heat[2].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[2].Heat_actual_1}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2], Heat_actual_1: e.target.value },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat3-1"
                        disabled={Disable.Hour1}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[2].Heat_actual_2) >
                            parseFloat(Heat[2].Heat_smp_data) +
                            parseFloat(Heat[2].Heat_smp_set) ||
                            parseFloat(Heat[2].Heat_actual_2) <
                            parseFloat(Heat[2].Heat_smp_data) -
                            parseFloat(Heat[2].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[2].Heat_actual_2}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2], Heat_actual_2: e.target.value },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat3-2"
                        disabled={Disable.Hour2}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[2].Heat_actual_3) >
                            parseFloat(Heat[2].Heat_smp_data) +
                            parseFloat(Heat[2].Heat_smp_set) ||
                            parseFloat(Heat[2].Heat_actual_3) <
                            parseFloat(Heat[2].Heat_smp_data) -
                            parseFloat(Heat[2].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[2].Heat_actual_3}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2], Heat_actual_3: e.target.value },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat3-3"
                        disabled={Disable.Hour3}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[2].Heat_actual_4) >
                            parseFloat(Heat[2].Heat_smp_data) +
                            parseFloat(Heat[2].Heat_smp_set) ||
                            parseFloat(Heat[2].Heat_actual_4) <
                            parseFloat(Heat[2].Heat_smp_data) -
                            parseFloat(Heat[2].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[2].Heat_actual_4}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2], Heat_actual_4: e.target.value },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat3-4"
                        disabled={Disable.Hour4}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[2].Heat_actual_5) >
                            parseFloat(Heat[2].Heat_smp_data) +
                            parseFloat(Heat[2].Heat_smp_set) ||
                            parseFloat(Heat[2].Heat_actual_5) <
                            parseFloat(Heat[2].Heat_smp_data) -
                            parseFloat(Heat[2].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[2].Heat_actual_5}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2], Heat_actual_5: e.target.value },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat3-5"
                        disabled={Disable.Hour5}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[2].Heat_actual_6) >
                            parseFloat(Heat[2].Heat_smp_data) +
                            parseFloat(Heat[2].Heat_smp_set) ||
                            parseFloat(Heat[2].Heat_actual_6) <
                            parseFloat(Heat[2].Heat_smp_data) -
                            parseFloat(Heat[2].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[2].Heat_actual_6}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2], Heat_actual_6: e.target.value },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat3-6"
                        disabled={Disable.Hour6}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>04</td>
                    <td>Zone 1</td>
                    <td>On pannel</td>
                    <td>
                      {" "}
                      ({Heat[3].Heat_smp_data})±{Heat[3].Heat_smp_set}°C
                    </td>
                    <td>°C</td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[3].Heat_actual_1) >
                            parseFloat(Heat[3].Heat_smp_data) +
                            parseFloat(Heat[3].Heat_smp_set) ||
                            parseFloat(Heat[3].Heat_actual_1) <
                            parseFloat(Heat[3].Heat_smp_data) -
                            parseFloat(Heat[3].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[3].Heat_actual_1}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3], Heat_actual_1: e.target.value },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat4-1"
                        disabled={Disable.Hour1}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[3].Heat_actual_2) >
                            parseFloat(Heat[3].Heat_smp_data) +
                            parseFloat(Heat[3].Heat_smp_set) ||
                            parseFloat(Heat[3].Heat_actual_2) <
                            parseFloat(Heat[3].Heat_smp_data) -
                            parseFloat(Heat[3].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[3].Heat_actual_2}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3], Heat_actual_2: e.target.value },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat4-2"
                        disabled={Disable.Hour2}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[3].Heat_actual_3) >
                            parseFloat(Heat[3].Heat_smp_data) +
                            parseFloat(Heat[3].Heat_smp_set) ||
                            parseFloat(Heat[3].Heat_actual_3) <
                            parseFloat(Heat[3].Heat_smp_data) -
                            parseFloat(Heat[3].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[3].Heat_actual_3}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3], Heat_actual_3: e.target.value },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat4-3"
                        disabled={Disable.Hour3}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[3].Heat_actual_4) >
                            parseFloat(Heat[3].Heat_smp_data) +
                            parseFloat(Heat[3].Heat_smp_set) ||
                            parseFloat(Heat[3].Heat_actual_4) <
                            parseFloat(Heat[3].Heat_smp_data) -
                            parseFloat(Heat[3].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[3].Heat_actual_4}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3], Heat_actual_4: e.target.value },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat4-4"
                        disabled={Disable.Hour4}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[3].Heat_actual_5) >
                            parseFloat(Heat[3].Heat_smp_data) +
                            parseFloat(Heat[3].Heat_smp_set) ||
                            parseFloat(Heat[3].Heat_actual_5) <
                            parseFloat(Heat[3].Heat_smp_data) -
                            parseFloat(Heat[3].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[3].Heat_actual_5}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3], Heat_actual_5: e.target.value },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat4-5"
                        disabled={Disable.Hour5}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[3].Heat_actual_6) >
                            parseFloat(Heat[3].Heat_smp_data) +
                            parseFloat(Heat[3].Heat_smp_set) ||
                            parseFloat(Heat[3].Heat_actual_6) <
                            parseFloat(Heat[3].Heat_smp_data) -
                            parseFloat(Heat[3].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[3].Heat_actual_6}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3], Heat_actual_6: e.target.value },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat4-6"
                        disabled={Disable.Hour6}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>05</td>
                    <td>Zone 2</td>
                    <td>On pannel</td>
                    <td>
                      {" "}
                      ({Heat[4].Heat_smp_data})±{Heat[4].Heat_smp_set}°C
                    </td>
                    <td>°C</td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[4].Heat_actual_1) >
                            parseFloat(Heat[4].Heat_smp_data) +
                            parseFloat(Heat[4].Heat_smp_set) ||
                            parseFloat(Heat[4].Heat_actual_1) <
                            parseFloat(Heat[4].Heat_smp_data) -
                            parseFloat(Heat[4].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[4].Heat_actual_1}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4], Heat_actual_1: e.target.value },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat5-1"
                        disabled={Disable.Hour1}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[4].Heat_actual_2) >
                            parseFloat(Heat[4].Heat_smp_data) +
                            parseFloat(Heat[4].Heat_smp_set) ||
                            parseFloat(Heat[4].Heat_actual_2) <
                            parseFloat(Heat[4].Heat_smp_data) -
                            parseFloat(Heat[4].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[4].Heat_actual_2}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4], Heat_actual_2: e.target.value },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat5-2"
                        disabled={Disable.Hour2}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[4].Heat_actual_3) >
                            parseFloat(Heat[4].Heat_smp_data) +
                            parseFloat(Heat[4].Heat_smp_set) ||
                            parseFloat(Heat[4].Heat_actual_3) <
                            parseFloat(Heat[4].Heat_smp_data) -
                            parseFloat(Heat[4].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[4].Heat_actual_3}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4], Heat_actual_3: e.target.value },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat5-3"
                        disabled={Disable.Hour3}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[4].Heat_actual_4) >
                            parseFloat(Heat[4].Heat_smp_data) +
                            parseFloat(Heat[4].Heat_smp_set) ||
                            parseFloat(Heat[4].Heat_actual_4) <
                            parseFloat(Heat[4].Heat_smp_data) -
                            parseFloat(Heat[4].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[4].Heat_actual_4}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4], Heat_actual_4: e.target.value },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat5-4"
                        disabled={Disable.Hour4}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[4].Heat_actual_5) >
                            parseFloat(Heat[4].Heat_smp_data) +
                            parseFloat(Heat[4].Heat_smp_set) ||
                            parseFloat(Heat[4].Heat_actual_5) <
                            parseFloat(Heat[4].Heat_smp_data) -
                            parseFloat(Heat[4].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[4].Heat_actual_5}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4], Heat_actual_5: e.target.value },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat5-5"
                        disabled={Disable.Hour5}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[4].Heat_actual_6) >
                            parseFloat(Heat[4].Heat_smp_data) +
                            parseFloat(Heat[4].Heat_smp_set) ||
                            parseFloat(Heat[4].Heat_actual_6) <
                            parseFloat(Heat[4].Heat_smp_data) -
                            parseFloat(Heat[4].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[4].Heat_actual_6}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4], Heat_actual_6: e.target.value },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat5-6"
                        disabled={Disable.Hour6}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>06</td>
                    <td>Zone 3</td>
                    <td>On pannel</td>
                    <td>
                      {" "}
                      ({Heat[5].Heat_smp_data})±{Heat[5].Heat_smp_set}°C
                    </td>
                    <td>°C</td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[5].Heat_actual_1) >
                            parseFloat(Heat[5].Heat_smp_data) +
                            parseFloat(Heat[5].Heat_smp_set) ||
                            parseFloat(Heat[5].Heat_actual_1) <
                            parseFloat(Heat[5].Heat_smp_data) -
                            parseFloat(Heat[5].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[5].Heat_actual_1}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5], Heat_actual_1: e.target.value },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat6-1"
                        disabled={Disable.Hour1}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[5].Heat_actual_2) >
                            parseFloat(Heat[5].Heat_smp_data) +
                            parseFloat(Heat[5].Heat_smp_set) ||
                            parseFloat(Heat[5].Heat_actual_2) <
                            parseFloat(Heat[5].Heat_smp_data) -
                            parseFloat(Heat[5].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[5].Heat_actual_2}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5], Heat_actual_2: e.target.value },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat6-2"
                        disabled={Disable.Hour2}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[5].Heat_actual_3) >
                            parseFloat(Heat[5].Heat_smp_data) +
                            parseFloat(Heat[5].Heat_smp_set) ||
                            parseFloat(Heat[5].Heat_actual_3) <
                            parseFloat(Heat[5].Heat_smp_data) -
                            parseFloat(Heat[5].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[5].Heat_actual_3}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5], Heat_actual_3: e.target.value },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat6-3"
                        disabled={Disable.Hour3}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[5].Heat_actual_4) >
                            parseFloat(Heat[5].Heat_smp_data) +
                            parseFloat(Heat[5].Heat_smp_set) ||
                            parseFloat(Heat[5].Heat_actual_4) <
                            parseFloat(Heat[5].Heat_smp_data) -
                            parseFloat(Heat[5].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[5].Heat_actual_4}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5], Heat_actual_4: e.target.value },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat6-4"
                        disabled={Disable.Hour4}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[5].Heat_actual_5) >
                            parseFloat(Heat[5].Heat_smp_data) +
                            parseFloat(Heat[5].Heat_smp_set) ||
                            parseFloat(Heat[5].Heat_actual_5) <
                            parseFloat(Heat[5].Heat_smp_data) -
                            parseFloat(Heat[5].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[5].Heat_actual_5}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5], Heat_actual_5: e.target.value },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat6-5"
                        disabled={Disable.Hour5}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[5].Heat_actual_6) >
                            parseFloat(Heat[5].Heat_smp_data) +
                            parseFloat(Heat[5].Heat_smp_set) ||
                            parseFloat(Heat[5].Heat_actual_6) <
                            parseFloat(Heat[5].Heat_smp_data) -
                            parseFloat(Heat[5].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[5].Heat_actual_6}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5], Heat_actual_6: e.target.value },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat6-6"
                        disabled={Disable.Hour6}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>07</td>
                    <td>Zone 4</td>
                    <td>On pannel</td>
                    <td>
                      ({Heat[6].Heat_smp_data})±{Heat[6].Heat_smp_set}°C
                    </td>
                    <td>°C</td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[6].Heat_actual_1) >
                            parseFloat(Heat[6].Heat_smp_data) +
                            parseFloat(Heat[6].Heat_smp_set) ||
                            parseFloat(Heat[6].Heat_actual_1) <
                            parseFloat(Heat[6].Heat_smp_data) -
                            parseFloat(Heat[6].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[6].Heat_actual_1}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6], Heat_actual_1: e.target.value },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat7-1"
                        disabled={Disable.Hour1}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[6].Heat_actual_2) >
                            parseFloat(Heat[6].Heat_smp_data) +
                            parseFloat(Heat[6].Heat_smp_set) ||
                            parseFloat(Heat[6].Heat_actual_2) <
                            parseFloat(Heat[6].Heat_smp_data) -
                            parseFloat(Heat[6].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[6].Heat_actual_2}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6], Heat_actual_2: e.target.value },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat7-2"
                        disabled={Disable.Hour2}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[6].Heat_actual_3) >
                            parseFloat(Heat[6].Heat_smp_data) +
                            parseFloat(Heat[6].Heat_smp_set) ||
                            parseFloat(Heat[6].Heat_actual_3) <
                            parseFloat(Heat[6].Heat_smp_data) -
                            parseFloat(Heat[6].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[6].Heat_actual_3}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6], Heat_actual_3: e.target.value },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat7-3"
                        disabled={Disable.Hour3}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[6].Heat_actual_4) >
                            parseFloat(Heat[6].Heat_smp_data) +
                            parseFloat(Heat[6].Heat_smp_set) ||
                            parseFloat(Heat[6].Heat_actual_4) <
                            parseFloat(Heat[6].Heat_smp_data) -
                            parseFloat(Heat[6].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[6].Heat_actual_4}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6], Heat_actual_4: e.target.value },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat7-4"
                        disabled={Disable.Hour4}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[6].Heat_actual_5) >
                            parseFloat(Heat[6].Heat_smp_data) +
                            parseFloat(Heat[6].Heat_smp_set) ||
                            parseFloat(Heat[6].Heat_actual_5) <
                            parseFloat(Heat[6].Heat_smp_data) -
                            parseFloat(Heat[6].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[6].Heat_actual_5}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6], Heat_actual_5: e.target.value },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat7-5"
                        disabled={Disable.Hour5}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[6].Heat_actual_6) >
                            parseFloat(Heat[6].Heat_smp_data) +
                            parseFloat(Heat[6].Heat_smp_set) ||
                            parseFloat(Heat[6].Heat_actual_6) <
                            parseFloat(Heat[6].Heat_smp_data) -
                            parseFloat(Heat[6].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[6].Heat_actual_6}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6], Heat_actual_6: e.target.value },
                            { ...Heat[7] },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat7-6"
                        disabled={Disable.Hour6}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>08</td>
                    <td>Zone 5</td>
                    <td>On pannel</td>
                    <td>
                      ({Heat[7].Heat_smp_data})±{Heat[7].Heat_smp_set}°C
                    </td>
                    <td>°C</td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[7].Heat_actual_1) >
                            parseFloat(Heat[7].Heat_smp_data) +
                            parseFloat(Heat[7].Heat_smp_set) ||
                            parseFloat(Heat[7].Heat_actual_1) <
                            parseFloat(Heat[7].Heat_smp_data) -
                            parseFloat(Heat[7].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[7].Heat_actual_1}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7], Heat_actual_1: e.target.value },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat8-1"
                        disabled={Disable.Hour1}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[7].Heat_actual_2) >
                            parseFloat(Heat[7].Heat_smp_data) +
                            parseFloat(Heat[7].Heat_smp_set) ||
                            parseFloat(Heat[7].Heat_actual_2) <
                            parseFloat(Heat[7].Heat_smp_data) -
                            parseFloat(Heat[7].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[7].Heat_actual_2}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7], Heat_actual_2: e.target.value },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat8-2"
                        disabled={Disable.Hour2}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[7].Heat_actual_3) >
                            parseFloat(Heat[7].Heat_smp_data) +
                            parseFloat(Heat[7].Heat_smp_set) ||
                            parseFloat(Heat[7].Heat_actual_3) <
                            parseFloat(Heat[7].Heat_smp_data) -
                            parseFloat(Heat[7].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[7].Heat_actual_3}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7], Heat_actual_3: e.target.value },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat8-3"
                        disabled={Disable.Hour3}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[7].Heat_actual_4) >
                            parseFloat(Heat[7].Heat_smp_data) +
                            parseFloat(Heat[7].Heat_smp_set) ||
                            parseFloat(Heat[7].Heat_actual_4) <
                            parseFloat(Heat[7].Heat_smp_data) -
                            parseFloat(Heat[7].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[7].Heat_actual_4}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7], Heat_actual_4: e.target.value },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat8-4"
                        disabled={Disable.Hour4}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[7].Heat_actual_5) >
                            parseFloat(Heat[7].Heat_smp_data) +
                            parseFloat(Heat[7].Heat_smp_set) ||
                            parseFloat(Heat[7].Heat_actual_5) <
                            parseFloat(Heat[7].Heat_smp_data) -
                            parseFloat(Heat[7].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[7].Heat_actual_5}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7], Heat_actual_5: e.target.value },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat8-5"
                        disabled={Disable.Hour5}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[7].Heat_actual_6) >
                            parseFloat(Heat[7].Heat_smp_data) +
                            parseFloat(Heat[7].Heat_smp_set) ||
                            parseFloat(Heat[7].Heat_actual_6) <
                            parseFloat(Heat[7].Heat_smp_data) -
                            parseFloat(Heat[7].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[7].Heat_actual_6}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7], Heat_actual_6: e.target.value },
                            { ...Heat[8] },
                          ]);
                        }}
                        id="Heat8-6"
                        disabled={Disable.Hour6}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>09</td>
                    <td>Zone 6</td>
                    <td>On pannel</td>
                    <td>
                      ({Heat[8].Heat_smp_data})±{Heat[8].Heat_smp_set}°C
                    </td>
                    <td>°C</td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[8].Heat_actual_1) >
                            parseFloat(Heat[8].Heat_smp_data) +
                            parseFloat(Heat[8].Heat_smp_set) ||
                            parseFloat(Heat[8].Heat_actual_1) <
                            parseFloat(Heat[8].Heat_smp_data) -
                            parseFloat(Heat[8].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[8].Heat_actual_1}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8], Heat_actual_1: e.target.value },
                          ]);
                        }}
                        id="Heat9-1"
                        disabled={Disable.Hour1}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[8].Heat_actual_2) >
                            parseFloat(Heat[8].Heat_smp_data) +
                            parseFloat(Heat[8].Heat_smp_set) ||
                            parseFloat(Heat[8].Heat_actual_2) <
                            parseFloat(Heat[8].Heat_smp_data) -
                            parseFloat(Heat[8].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[8].Heat_actual_2}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8], Heat_actual_2: e.target.value },
                          ]);
                        }}
                        id="Heat9-2"
                        disabled={Disable.Hour2}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[8].Heat_actual_3) >
                            parseFloat(Heat[8].Heat_smp_data) +
                            parseFloat(Heat[8].Heat_smp_set) ||
                            parseFloat(Heat[8].Heat_actual_3) <
                            parseFloat(Heat[8].Heat_smp_data) -
                            parseFloat(Heat[8].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[8].Heat_actual_3}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8], Heat_actual_3: e.target.value },
                          ]);
                        }}
                        id="Heat9-3"
                        disabled={Disable.Hour3}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[8].Heat_actual_4) >
                            parseFloat(Heat[8].Heat_smp_data) +
                            parseFloat(Heat[8].Heat_smp_set) ||
                            parseFloat(Heat[8].Heat_actual_4) <
                            parseFloat(Heat[8].Heat_smp_data) -
                            parseFloat(Heat[8].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[8].Heat_actual_4}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8], Heat_actual_4: e.target.value },
                          ]);
                        }}
                        id="Heat9-4"
                        disabled={Disable.Hour4}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[8].Heat_actual_5) >
                            parseFloat(Heat[8].Heat_smp_data) +
                            parseFloat(Heat[8].Heat_smp_set) ||
                            parseFloat(Heat[8].Heat_actual_5) <
                            parseFloat(Heat[8].Heat_smp_data) -
                            parseFloat(Heat[8].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[8].Heat_actual_5}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8], Heat_actual_5: e.target.value },
                          ]);
                        }}
                        id="Heat9-5"
                        disabled={Disable.Hour5}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={
                          !(
                            parseFloat(Heat[8].Heat_actual_6) >
                            parseFloat(Heat[8].Heat_smp_data) +
                            parseFloat(Heat[8].Heat_smp_set) ||
                            parseFloat(Heat[8].Heat_actual_6) <
                            parseFloat(Heat[8].Heat_smp_data) -
                            parseFloat(Heat[8].Heat_smp_set)
                          )
                            ? { width: "100%" }
                            : {
                              width: "100%",
                              backgroundColor: "#d98984",
                              border: "2px solid #DA251A",
                            }
                        }
                        value={Heat[8].Heat_actual_6}
                        onChange={(e) => {
                          setHeat([
                            { ...Heat[0] },
                            { ...Heat[1] },
                            { ...Heat[2] },
                            { ...Heat[3] },
                            { ...Heat[4] },
                            { ...Heat[5] },
                            { ...Heat[6] },
                            { ...Heat[7] },
                            { ...Heat[8], Heat_actual_6: e.target.value },
                          ]);
                        }}
                        id="Heat9-6"
                        disabled={Disable.Hour6}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                className="table"
                width="50%"
                style={{ textAlign: "center" }}
                border="0"
              >
                <tbody>
                  <tr style={{ textAlign: "center" }}>
                    <th style={{ textAlign: "center" }} colSpan="15">
                      Injection parameter
                    </th>
                  </tr>
                  <tr>
                    <th
                      width="10%"
                      style={{ textAlign: "center", width: "1%" }}
                    >
                      SL
                    </th>
                    <th width="35%" style={{ textAlign: "center" }}>
                      Description
                    </th>
                    <th
                      width="25%"
                      style={{ textAlign: "center", width: "8%" }}
                    >
                      Inst-used
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Set
                    </th>
                    <th
                      width="5%"
                      style={{
                        textAlign: "center",
                        width: "7%",
                        padding: "0px 20px",
                      }}
                    >
                      Units
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Actual
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Actual
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Actual
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Actual
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Actual
                    </th>
                    <th width="5%" style={{ textAlign: "center" }}>
                      Actual
                    </th>
                  </tr>
                  {Inject.map((item) => (
                    <tr keys={item.Inject_sl}>
                      <td>{item.Inject_sl}</td>
                      <td>{item.Inject_desc}</td>
                      <td>{item.Inject_inst_used}</td>
                      <td>
                        ({item.Inject_smp_data})±{item.Inject_smp_set}
                      </td>
                      <td>{item.Inject_units}</td>
                      <td>
                        <input
                          id={"cycle1-" + item.Inject_sl}
                          type="text"
                          style={
                            !(
                              parseFloat(item.Inject_actual_1) >
                              parseFloat(item.Inject_smp_data) +
                              parseFloat(item.Inject_smp_set) ||
                              parseFloat(item.Inject_actual_1) <
                              parseFloat(item.Inject_smp_data) -
                              parseFloat(item.Inject_smp_set)
                            )
                              ? { width: "100%" }
                              : {
                                width: "100%",
                                backgroundColor: "#d98984",
                                border: "2px solid #DA251A",
                              }
                          }
                          value={item.Inject_actual_1}
                          onChange={(e) => {
                            setInject((data) => {
                              let Value = [...Inject];
                              Value[Number(item.Inject_sl) - 1] = {
                                ...Inject[Number(item.Inject_sl) - 1],
                                Inject_actual_1: e.target.value,
                              };
                              return Value;
                            });
                          }}
                          disabled={Disable.Hour1}
                        />
                      </td>
                      <td>
                        <input
                          id={"cycle2-" + item.Inject_sl}
                          type="text"
                          value={item.Inject_actual_2}
                          style={
                            !(
                              parseFloat(item.Inject_actual_2) >
                              parseFloat(item.Inject_smp_data) +
                              parseFloat(item.Inject_smp_set) ||
                              parseFloat(item.Inject_actual_2) <
                              parseFloat(item.Inject_smp_data) -
                              parseFloat(item.Inject_smp_set)
                            )
                              ? { width: "100%" }
                              : {
                                width: "100%",
                                backgroundColor: "#d98984",
                                border: "2px solid #DA251A",
                              }
                          }
                          onChange={(e) => {
                            setInject((data) => {
                              let Value = [...Inject];
                              Value[Number(item.Inject_sl) - 1] = {
                                ...Inject[Number(item.Inject_sl) - 1],
                                Inject_actual_2: e.target.value,
                              };
                              return Value;
                            });
                          }}
                          disabled={Disable.Hour2}
                        />
                      </td>
                      <td>
                        <input
                          id={"cycle3-" + item.Inject_sl}
                          type="text"
                          style={
                            !(
                              parseFloat(item.Inject_actual_3) >
                              parseFloat(item.Inject_smp_data) +
                              parseFloat(item.Inject_smp_set) ||
                              parseFloat(item.Inject_actual_3) <
                              parseFloat(item.Inject_smp_data) -
                              parseFloat(item.Inject_smp_set)
                            )
                              ? { width: "100%" }
                              : {
                                width: "100%",
                                backgroundColor: "#d98984",
                                border: "2px solid #DA251A",
                              }
                          }
                          value={item.Inject_actual_3}
                          onChange={(e) => {
                            setInject((data) => {
                              let Value = [...Inject];
                              Value[Number(item.Inject_sl) - 1] = {
                                ...Inject[Number(item.Inject_sl) - 1],
                                Inject_actual_3: e.target.value,
                              };
                              return Value;
                            });
                          }}
                          disabled={Disable.Hour3}
                        />
                      </td>
                      <td>
                        <input
                          id={"cycle4-" + item.Inject_sl}
                          type="text"
                          style={
                            !(
                              parseFloat(item.Inject_actual_4) >
                              parseFloat(item.Inject_smp_data) +
                              parseFloat(item.Inject_smp_set) ||
                              parseFloat(item.Inject_actual_4) <
                              parseFloat(item.Inject_smp_data) -
                              parseFloat(item.Inject_smp_set)
                            )
                              ? { width: "100%" }
                              : {
                                width: "100%",
                                backgroundColor: "#d98984",
                                border: "2px solid #DA251A",
                              }
                          }
                          value={item.Inject_actual_4}
                          onChange={(e) => {
                            setInject((data) => {
                              let Value = [...Inject];
                              Value[Number(item.Inject_sl) - 1] = {
                                ...Inject[Number(item.Inject_sl) - 1],
                                Inject_actual_4: e.target.value,
                              };
                              return Value;
                            });
                          }}
                          disabled={Disable.Hour4}
                        />
                      </td>
                      <td>
                        <input
                          id={"cycle5-" + item.Inject_sl}
                          type="text"
                          style={
                            !(
                              parseFloat(item.Inject_actual_5) >
                              parseFloat(item.Inject_smp_data) +
                              parseFloat(item.Inject_smp_set) ||
                              parseFloat(item.Inject_actual_5) <
                              parseFloat(item.Inject_smp_data) -
                              parseFloat(item.Inject_smp_set)
                            )
                              ? { width: "100%" }
                              : {
                                width: "100%",
                                backgroundColor: "#d98984",
                                border: "2px solid #DA251A",
                              }
                          }
                          value={item.Inject_actual_5}
                          onChange={(e) => {
                            setInject((data) => {
                              let Value = [...Inject];
                              Value[Number(item.Inject_sl) - 1] = {
                                ...Inject[Number(item.Inject_sl) - 1],
                                Inject_actual_5: e.target.value,
                              };
                              return Value;
                            });
                          }}
                          disabled={Disable.Hour5}
                        />
                      </td>
                      <td>
                        <input
                          id={"cycle6-" + item.Inject_sl}
                          type="text"
                          style={
                            !(
                              parseFloat(item.Inject_actual_6) >
                              parseFloat(item.Inject_smp_data) +
                              parseFloat(item.Inject_smp_set) ||
                              parseFloat(item.Inject_actual_6) <
                              parseFloat(item.Inject_smp_data) -
                              parseFloat(item.Inject_smp_set)
                            )
                              ? { width: "100%" }
                              : {
                                width: "100%",
                                backgroundColor: "#d98984",
                                border: "2px solid #DA251A",
                              }
                          }
                          value={item.Inject_actual_6}
                          onChange={(e) => {
                            setInject((data) => {
                              let Value = [...Inject];
                              Value[Number(item.Inject_sl) - 1] = {
                                ...Inject[Number(item.Inject_sl) - 1],
                                Inject_actual_6: e.target.value,
                              };
                              return Value;
                            });
                          }}
                          disabled={Disable.Hour6}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <table
                className="table_hi"
                width="50%"
                style={{ textAlign: "center" }}
                border="5"
              >
                <tbody>
                  <tr style={{ textAlign: "center" }}>
                    <th style={{ textAlign: "center" }} colSpan="10">
                      Mould Core & Cavity Temperature Monitoring
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{ width: "3.5%", textAlign: "center" }}
                      width="5%"
                    >
                      A
                    </th>
                    <th style={{ textAlign: "center" }} width="25%">
                      Normal condition
                    </th>
                    <th style={{ textAlign: "center", width: "5%" }}>Set</th>
                    <th style={{ textAlign: "center" }}>Actual value</th>
                  </tr>
                  <tr>
                    <th colSpan="3"></th>
                    <td width="50">
                      <table className="table_hi">
                        <tr valign="top">
                          <th width="30%" style={{ textAlign: "center" }}>
                            Shift-A
                          </th>
                          <th width="30%" style={{ textAlign: "center" }}>
                            Shift-B
                          </th>
                          <th width="30%" style={{ textAlign: "center" }}>
                            Shift-C
                          </th>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>TEMPERATURE of core side while machine running</td>
                    <td>
                      ({PMS[0].Core})±{PMS[0].CoreSet}°C
                    </td>
                    <td>
                      <table className="table_hi">
                        <tbody>
                          <tr>
                            <td width="30%">
                              <input
                                type="text"
                                style={
                                  !(
                                    parseFloat(PMS[0].Tempcore1) >
                                    parseFloat(PMS[0].Core) +
                                    parseFloat(PMS[0].CoreSet) ||
                                    parseFloat(PMS[0].Tempcore1) <
                                    parseFloat(PMS[0].Core) -
                                    parseFloat(PMS[0].CoreSet)
                                  )
                                    ? { width: "95%" }
                                    : {
                                      width: "95%",
                                      backgroundColor: "#d98984",
                                      border: "2px solid #DA251A",
                                    }
                                }
                                value={PMS[0].Tempcore1}
                                name="Tempcore1"
                                onChange={HandleFeildChangePMS}
                              />
                            </td>
                            <td width="30%">
                              <input
                                type="text"
                                value={PMS[0].Tempcore2}
                                style={
                                  !(
                                    parseFloat(PMS[0].Tempcore2) >
                                    parseFloat(PMS[0].Core) +
                                    parseFloat(PMS[0].CoreSet) ||
                                    parseFloat(PMS[0].Tempcore2) <
                                    parseFloat(PMS[0].Core) -
                                    parseFloat(PMS[0].CoreSet)
                                  )
                                    ? { width: "95%" }
                                    : {
                                      width: "95%",
                                      backgroundColor: "#d98984",
                                      border: "2px solid #DA251A",
                                    }
                                }
                                name="Tempcore2"
                                onChange={HandleFeildChangePMS}
                              />
                            </td>
                            <td width="30%">
                              <input
                                type="text"
                                style={
                                  !(
                                    parseFloat(PMS[0].Tempcore3) >
                                    parseFloat(PMS[0].Core) +
                                    parseFloat(PMS[0].CoreSet) ||
                                    parseFloat(PMS[0].Tempcore3) <
                                    parseFloat(PMS[0].Core) -
                                    parseFloat(PMS[0].CoreSet)
                                  )
                                    ? { width: "95%" }
                                    : {
                                      width: "95%",
                                      backgroundColor: "#d98984",
                                      border: "2px solid #DA251A",
                                    }
                                }
                                value={PMS[0].Tempcore3}
                                name="Tempcore3"
                                onChange={HandleFeildChangePMS}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>02</td>
                    <td>TEMPERATURE of CAVITY side while machine running</td>
                    <td>
                      ({PMS[0].Cavity})±{PMS[0].CavSet}°C
                    </td>
                    <td>
                      <table className="table_hi">
                        <tbody>
                          <tr>
                            <td width="30%">
                              <input
                                type="text"
                                style={
                                  !(
                                    parseFloat(PMS[0].Tempcav1) >
                                    parseFloat(PMS[0].Cavity) +
                                    parseFloat(PMS[0].CavSet) ||
                                    parseFloat(PMS[0].Tempcav1) <
                                    parseFloat(PMS[0].Cavity) -
                                    parseFloat(PMS[0].CavSet)
                                  )
                                    ? { width: "95%" }
                                    : {
                                      width: "95%",
                                      backgroundColor: "#d98984",
                                      border: "2px solid #DA251A",
                                    }
                                }
                                value={PMS[0].Tempcav1}
                                name="Tempcav1"
                                onChange={HandleFeildChangePMS}
                              />
                            </td>
                            <td width="30%">
                              <input
                                type="text"
                                style={
                                  !(
                                    parseFloat(PMS[0].Tempcav2) >
                                    parseFloat(PMS[0].Cavity) +
                                    parseFloat(PMS[0].CavSet) ||
                                    parseFloat(PMS[0].Tempcav2) <
                                    parseFloat(PMS[0].Cavity) -
                                    parseFloat(PMS[0].CavSet)
                                  )
                                    ? { width: "95%" }
                                    : {
                                      width: "95%",
                                      backgroundColor: "#d98984",
                                      border: "2px solid #DA251A",
                                    }
                                }
                                value={PMS[0].Tempcav2}
                                name="Tempcav2"
                                onChange={HandleFeildChangePMS}
                              />
                            </td>
                            <td width="30%">
                              <input
                                type="text"
                                style={
                                  !(
                                    parseFloat(PMS[0].Tempcav3) >
                                    parseFloat(PMS[0].Cavity) +
                                    parseFloat(PMS[0].CavSet) ||
                                    parseFloat(PMS[0].Tempcav3) <
                                    parseFloat(PMS[0].Cavity) -
                                    parseFloat(PMS[0].CavSet)
                                  )
                                    ? { width: "95%" }
                                    : {
                                      width: "95%",
                                      backgroundColor: "#d98984",
                                      border: "2px solid #DA251A",
                                    }
                                }
                                value={PMS[0].Tempcav3}
                                name="Tempcav3"
                                onChange={HandleFeildChangePMS}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                className="table_hi"
                width="50%"
                style={{ textAlign: "center" }}
                border="5"
              >
                <tbody>
                  <tr>
                    <th
                      style={{ width: "3.5%", textAlign: "center" }}
                      width="5%"
                    >
                      B
                    </th>
                    <th style={{ textAlign: "center" }} width="25%">
                      With M T C
                    </th>
                    <th style={{ textAlign: "center", width: "5%" }}>Set</th>
                    <th style={{ textAlign: "center" }}>Actual value</th>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>TEMPERATURE of core side while machine running</td>
                    <td>
                      ({PMS[0].Core})±{PMS[0].CoreSet}°C
                    </td>
                    <td>
                      <table className="table_hi">
                        <tbody>
                          <tr>
                            <td width="30%">
                              <input
                                type="text"
                                style={
                                  !(
                                    parseFloat(PMS[0].Tempcore4) >
                                    parseFloat(PMS[0].Core) +
                                    parseFloat(PMS[0].CoreSet) ||
                                    parseFloat(PMS[0].Tempcore4) <
                                    parseFloat(PMS[0].Core) -
                                    parseFloat(PMS[0].CoreSet)
                                  )
                                    ? { width: "95%" }
                                    : {
                                      width: "95%",
                                      backgroundColor: "#d98984",
                                      border: "2px solid #DA251A",
                                    }
                                }
                                value={PMS[0].Tempcore4}
                                name="Tempcore4"
                                onChange={HandleFeildChangePMS}
                              />
                            </td>
                            <td width="30%">
                              <input
                                type="text"
                                style={
                                  !(
                                    parseFloat(PMS[0].Tempcore5) >
                                    parseFloat(PMS[0].Core) +
                                    parseFloat(PMS[0].CoreSet) ||
                                    parseFloat(PMS[0].Tempcore5) <
                                    parseFloat(PMS[0].Core) -
                                    parseFloat(PMS[0].CoreSet)
                                  )
                                    ? { width: "95%" }
                                    : {
                                      width: "95%",
                                      backgroundColor: "#d98984",
                                      border: "2px solid #DA251A",
                                    }
                                }
                                value={PMS[0].Tempcore5}
                                name="Tempcore5"
                                onChange={HandleFeildChangePMS}
                              />
                            </td>
                            <td width="30%">
                              <input
                                type="text"
                                style={
                                  !(
                                    parseFloat(PMS[0].Tempcore6) >
                                    parseFloat(PMS[0].Core) +
                                    parseFloat(PMS[0].CoreSet) ||
                                    parseFloat(PMS[0].Tempcore6) <
                                    parseFloat(PMS[0].Core) -
                                    parseFloat(PMS[0].CoreSet)
                                  )
                                    ? { width: "95%" }
                                    : {
                                      width: "95%",
                                      backgroundColor: "#d98984",
                                      border: "2px solid #DA251A",
                                    }
                                }
                                value={PMS[0].Tempcore6}
                                name="Tempcore6"
                                onChange={HandleFeildChangePMS}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>02</td>
                    <td>TEMPERATURE of CAVITY side while machine running</td>
                    <td>
                      ({PMS[0].Cavity})±{PMS[0].CavSet}°C
                    </td>
                    <td>
                      <table className="table_hi">
                        <tbody>
                          <tr>
                            <td width="30%">
                              <input
                                type="text"
                                style={
                                  !(
                                    parseFloat(PMS[0].Tempcav4) >
                                    parseFloat(PMS[0].Cavity) +
                                    parseFloat(PMS[0].CavSet) ||
                                    parseFloat(PMS[0].Tempcav4) <
                                    parseFloat(PMS[0].Cavity) -
                                    parseFloat(PMS[0].CavSet)
                                  )
                                    ? { width: "95%" }
                                    : {
                                      width: "95%",
                                      backgroundColor: "#d98984",
                                      border: "2px solid #DA251A",
                                    }
                                }
                                value={PMS[0].Tempcav4}
                                name="Tempcav4"
                                onChange={HandleFeildChangePMS}
                              />
                            </td>
                            <td width="30%">
                              <input
                                type="text"
                                style={
                                  !(
                                    parseFloat(PMS[0].Tempcav5) >
                                    parseFloat(PMS[0].Cavity) +
                                    parseFloat(PMS[0].CavSet) ||
                                    parseFloat(PMS[0].Tempcav5) <
                                    parseFloat(PMS[0].Cavity) -
                                    parseFloat(PMS[0].CavSet)
                                  )
                                    ? { width: "95%" }
                                    : {
                                      width: "95%",
                                      backgroundColor: "#d98984",
                                      border: "2px solid #DA251A",
                                    }
                                }
                                value={PMS[0].Tempcav5}
                                name="Tempcav5"
                                onChange={HandleFeildChangePMS}
                              />
                            </td>
                            <td width="30%">
                              <input
                                type="text"
                                style={
                                  !(
                                    parseFloat(PMS[0].Tempcav6) >
                                    parseFloat(PMS[0].Cavity) +
                                    parseFloat(PMS[0].CavSet) ||
                                    parseFloat(PMS[0].Tempcav6) <
                                    parseFloat(PMS[0].Cavity) -
                                    parseFloat(PMS[0].CavSet)
                                  )
                                    ? { width: "95%" }
                                    : {
                                      width: "95%",
                                      backgroundColor: "#d98984",
                                      border: "2px solid #DA251A",
                                    }
                                }
                                value={PMS[0].Tempcav6}
                                name="Tempcav6"
                                onChange={HandleFeildChangePMS}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                className="table_hi"
                width="50%"
                style={{ textAlign: "center" }}
                border="5"
              >
                <tbody>
                  {/* <tr>
            <th style={{ textAlign: "center", width: "5%" }}>Set</th>
            <th style={{ textAlign: "center" }}>Actual value</th>
          </tr> */}
                  <tr>
                    {/* <td>01</td> */}
                    <td style={{ width: "33.5%" }}>
                      SIGNATURE OF THE SHIFT INCHARGE - PRODUCTION
                    </td>
                    {/* <td>±5°C</td> */}
                    <td>
                      <table className="table_hi">
                        <tbody>
                          <tr>
                            <td width="30%">
                              <Select
                                id="dropdown"
                                styles={{ width: "100%" }}
                                options={empDetails.map((item) => ({
                                  value: item.Emp_code,
                                  label: item.Emp_name,
                                }))}
                                value={{
                                  value: EmpName.ShiftInchargeShiftA,
                                  label: EmpName.ShiftInchargeShiftAEmp_name,
                                }}
                                onChange={handleShiftInchargeShiftA}
                                placeholder="Select Machine No"
                              />
                            </td>
                            <td width="30%">
                              <Select
                                id="dropdown"
                                styles={{ width: "100%" }}
                                options={empDetails.map((item) => ({
                                  value: item.Emp_code,
                                  label: item.Emp_name,
                                }))}
                                value={{
                                  value: EmpName.ShiftInchargeShiftB,
                                  label: EmpName.ShiftInchargeShiftBEmp_name,
                                }}
                                onChange={handleShiftInchargeShiftB}
                                placeholder="Select Machine No"
                              />
                            </td>
                            <td width="30%">
                              <Select
                                id="dropdown"
                                styles={{ width: "100%" }}
                                options={empDetails.map((item) => ({
                                  value: item.Emp_code,
                                  label: item.Emp_name,
                                }))}
                                value={{
                                  value: EmpName.ShiftInchargeShiftC,
                                  label: EmpName.ShiftInchargeShiftCEmp_name,
                                }}
                                onChange={handleShiftInchargeShiftC}
                                placeholder="Select Machine No"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>SIGNATURE OF THE ON LINE INSPECTION - QUALITY</td>

                    <td colSpan={4}>
                      <table className="table_hi">
                        <tbody>
                          <tr>
                            <td width="30%">
                              <Select
                                id="dropdown"
                                styles={{ width: "100%" }}
                                options={empDetails.map((item) => ({
                                  value: item.Emp_code,
                                  label: item.Emp_name,
                                }))}
                                value={{
                                  value: EmpName.LineInspecQaShiftA,
                                  label: EmpName.LineInspecQaShiftAEmp_name,
                                }}
                                onChange={handleLineInspecQaShiftA}
                                placeholder="Select Machine No"
                              />
                            </td>
                            <td width="30%">
                              <Select
                                id="dropdown"
                                styles={{ width: "100%" }}
                                options={empDetails.map((item) => ({
                                  value: item.Emp_code,
                                  label: item.Emp_name,
                                }))}
                                value={{
                                  value: EmpName.LineInspecQaShiftB,
                                  label: EmpName.LineInspecQaShiftBEmp_name,
                                }}
                                onChange={handleLineInspecQaShiftB}
                                placeholder="Select Machine No"
                              />
                            </td>
                            <td width="30%">
                              <Select
                                id="dropdown"
                                styles={{ width: "100%" }}
                                options={empDetails.map((item) => ({
                                  value: item.Emp_code,
                                  label: item.Emp_name,
                                }))}
                                value={{
                                  value: EmpName.LineInspecQaShiftC,
                                  label: EmpName.LineInspecQaShiftCEmp_name,
                                }}
                                onChange={handleLineInspecQaShiftC}
                                placeholder="Select Machine No"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4" style={{ border: "none" }}>
                      {New === undefined ? (
                        ""
                      ) : New ? (
                        <button
                          type="submit"
                        // onClick={(e) => handleCreate(e)}
                        >
                          Submit
                        </button>
                      ) : Insert ? (
                        <button
                          type="submit"
                          onClick={(e) => handleUpdateCreate(e)}
                        >
                          Insert & Update
                        </button>
                      ) : (
                        <button type="submit" onClick={(e) => handleUpdate(e)}>
                          Update
                        </button>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </Popup>
      </div>

      <div>
        <main>
          <table
            style={{ height: "50%", width: "100%" }}
            className="Table_name"
          >
            <thead>
              <tr>
                <th
                  colSpan="11"
                  style={{ textAlign: "center", whiteSpace: "nowrap" }}
                >
                  <font color="#8B0000">List - Process Monitoring System</font>
                </th>
              </tr>
              <tr>
                <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                  Date
                </td>
                <td colSpan="1">
                  <input
                    type="date"
                    placeholder="Filter by Date"
                    value={filterDate}
                    onChange={handleFilterDateChange}
                  />
                </td>
                <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                  MachineNo
                </td>
                <td colSpan={2}>
                  <input
                    type="text"
                    placeholder="Filter MachineNo"
                    style={{ fontSize: "70%", width: "100%" }}
                    value={filterMcno}
                    onChange={handleFilterMcnoChange}
                  />
                </td>
                <td colSpan="8"></td>
              </tr>

              <tr className="table_position">
                <th style={{ textAlign: "center", whiteSpace: "nowrap", width: "4%" }}>
                  Date
                </th>
                <th style={{ textAlign: "center", whiteSpace: "nowrap", width: "3%" }}>
                  Machine No
                </th>
                <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                  07.00 AM - <br></br>08.00 AM{" "}
                </th>{" "}
                <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                  11.00 AM - <br></br>12.00 AM{" "}
                </th>
                <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                  15.00 PM - <br></br>16.00 PM{" "}
                </th>
                <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                  19.00 PM - <br></br>20.00 PM{" "}
                </th>
                <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                  23.00 PM - <br></br>01.00 AM{" "}
                </th>
                <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                  03.00 AM - <br></br>04.00 AM{" "}
                </th>
                {/* <th style={{ "text-align": "center", whiteSpace: "nowrap" }}>
                  &nbsp;&nbsp;Created Date&nbsp;&nbsp;
                </th> */}
                <th style={{ width: "2%" }}></th>
                {(GetAccess("production", "hod") || GetAccess("ADMIN", "GM")) ?
                  (
                    <th style={{ width: "2%" }}></th>) : ""}
              </tr>
            </thead>
            <tbody>
              {/* <HandleRead keyword="pro_mon_sys" setData={setpro_mon_sys} /> */}
              {filteredRecords.length === 0 ? (
                // <tr>
                <td colSpan="12" style={{ textAlign: "center" }}>
                  <p style={{ fontWeight: "bold" }}>No records found.</p>
                </td>
              ) : (
                // </tr>
                filteredRecords.map((pro_mon_sys) => (
                  // {filteredRecords.map((pro_mon_sys) => (
                  <tr className="table_view" key={pro_mon_sys.Id}>
                    <td
                      className="table-cell1"
                      style={{ height: "50%", whiteSpace: "nowrap" }}
                    >
                      {formatDt(pro_mon_sys.TrackDate)}
                    </td>
                    <td className="table-cell1">{pro_mon_sys.Mcno}</td>
                    <td
                      className={`table-cell1 ${pro_mon_sys.Track_7_am === "" ? "highlighted" : ""
                        }`}
                      style={{ width: "10%", whiteSpace: "break-spaces" }}
                    >
                      {pro_mon_sys.Track_7_am}
                    </td>
                    <td
                      className={`table-cell1 ${pro_mon_sys.Track_11_am === "" ? "highlighted" : ""
                        }`}
                      style={{ width: "10%", whiteSpace: "break-spaces" }}
                    >
                      {pro_mon_sys.Track_11_am}
                    </td>
                    <td
                      className={`table-cell1 ${pro_mon_sys.Track_15_pm === "" ? "highlighted" : ""
                        }`}
                      style={{ width: "10%", whiteSpace: "break-spaces" }}
                    >
                      {pro_mon_sys.Track_15_pm}
                    </td>
                    <td
                      className={`table-cell1 ${pro_mon_sys.Track_19_pm === "" ? "highlighted" : ""
                        }`}
                      style={{ width: "10%", whiteSpace: "break-spaces" }}
                    >
                      {pro_mon_sys.Track_19_pm}
                    </td>
                    <td
                      className={`table-cell1 ${pro_mon_sys.Track_23_pm === "" ? "highlighted" : ""
                        }`}
                      style={{ width: "10%", whiteSpace: "break-spaces" }}
                    >
                      {pro_mon_sys.Track_23_pm}
                    </td>
                    <td
                      className={`table-cell1 ${pro_mon_sys.Track_3_am === "" ? "highlighted" : ""
                        }`}
                      style={{ width: "10%", whiteSpace: "break-spaces" }}
                    >
                      {pro_mon_sys.Track_3_am}
                    </td>
                    {/* <td
                      className="table-cell1"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {pro_mon_sys.CreatedDate}
                    </td> */}
                    <td>
                      <button
                        style={{
                          backgroundColor: "#cba423",
                          whiteSpace: "nowrap",
                        }}
                        type="button"
                        onClick={() => HandleView(pro_mon_sys.Id)}
                      >
                        View&#x1F441;
                      </button>
                    </td>

                    {(GetAccess("production", "hod") || GetAccess("ADMIN", "GM")) ?
                      (<td>
                        <button
                          type="button"
                          onClick={() => handleEdit(pro_mon_sys.Id)}
                        >
                          Edit&#x270E;
                        </button>
                      </td>) : ""}
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <div className="pagination" id="pagination">
            {isPaginationVisible && filteredData.length > 0 && (
              // <nav>
              //   <ul className="pagination">
              //     <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              //       <a
              //         href="#"
              //         className="page-link"
              //         onClick={prePage}
              //         disabled={currentPage === 1}
              //       >
              //         Prev
              //       </a>
              //     </li>
              //     {numbers.map((pageNumber) => (
              //       <li
              //         className={`page-item ${currentPage === pageNumber ? "active" : ""}`}
              //         key={pageNumber}
              //       >
              //         <a
              //           href="#"
              //           className={`page-link ${currentPage === pageNumber ? "disabled" : ""}`}
              //           onClick={() => changeCpage(pageNumber)}
              //           disabled={currentPage === pageNumber}
              //         >
              //           {pageNumber}
              //         </a>
              //       </li>
              //     ))}
              //     <li className={`page-item ${currentPage === npage ? "disabled" : ""}`}>
              //       <a
              //         href="#"
              //         className="page-link"
              //         onClick={nextPage}
              //         disabled={currentPage === npage}
              //       >
              //         Next
              //       </a>
              //     </li>
              //   </ul>
              // </nav>
              <Pagination
                currentPage={currentPage}
                npage={npage}
                prePage={prePage}
                nextPage={nextPage}
                changeCpage={changeCpage}
                numbers={numbers}
              />
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default PmsView;
