import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Dropdown } from "react-bootstrap";
import Field from "../../../../Components/Fields/Field";
import Lable from "../../../../Components/Fields/Lable";
import * as XLSX from "xlsx";
import CloseButton from "react-bootstrap/CloseButton";

import "./SPPForm.css";
import approve from "../../../../Lib/approvePaint";
import reject from "../../../../Lib/rejectPaint";
import ReadRecordPaint from "../../../../Lib/API/ReadRecordPaint";

const PreviewPage = ({
  Records,
  setState,

  HandleCreate,
  option,
  HandleUpdate,
  id,
}) => {
  const InitRoot = [
    {
      download: false,
      Createdname: [{}],
      Approvedname: [{}],
      PartName: [{}],
      PaintName: [{}],
      ProgramName: [{}],
      CusName: [{}],
      Part_name: "",
      Program_name: "",
      Paint_name: "",
      Cus_name: "",
      Doc_No: "UPCL/PRO/D/70",
      Rev_No: "00",
      Rev_ate: "01-06-2018",
      Prodcution_operator_name: "",
      Approval_status: "",
      direction_function0: [
        { Direction_0: "A0#1(Automization Air)", value: "" },
        { Direction_0: "A0#2(Fan Air)", value: "" },
        { Direction_0: "A0#3(Material Pressure)±0.5 Bar", value: "" },
        { Direction_0: "File NO", value: "" },
        { Direction_0: "NO Of Strokes", value: "" },
        { Direction_0: "Position Speed (mm/s)", value: "" },
      ],
      direction_function90: [
        { Direction_90: "A0#1(Automization Air)", value: "" },
        { Direction_90: "A0#2(Fan Air)", value: "" },
        { Direction_90: "A0#3(Material Pressure)±0.5 Bar", value: "" },
        { Direction_90: "File NO", value: "" },
        { Direction_90: "NO Of Strokes", value: "" },
        { Direction_90: "Position Speed (mm/s)", value: "" },
      ],
      direction_function180: [
        { Direction_180: "A0#1(Automization Air)", value: "" },
        { Direction_180: "A0#2(Fan Air)", value: "" },
        { Direction_180: "A0#3(Material Pressure)±0.5 Bar", value: "" },
        { Direction_180: "File NO", value: "" },
        { Direction_180: "NO Of Strokes", value: "" },
        { Direction_180: "Position Speed (mm/s)", value: "" },
      ],
      direction_function270: [
        { Direction_270: "A0#1(Automization Air)", value: "" },
        { Direction_270: "A0#2(Fan Air)", value: "" },
        { Direction_270: "A0#3(Material Pressure)±0.5 Bar", value: "" },
        { Direction_270: "File NO", value: "" },
        { Direction_270: "NO Of Strokes", value: "" },
        { Direction_270: "Position Speed (mm/s)", value: "" },
      ],
    },
  ];
  const contentRef = useRef(null);
  const navigate = useNavigate();
  const [Approver, setApprover] = useState("");
  const [Approval, setApproval] = useState("");
  const [back, setback] = useState(false);
  const [cardId, setcardId] = useState("");
  const [remarks, setremarks] = useState("");
  const [RejectedReason, setRejectedReason] = useState("");
  const [exc, setexc] = useState(false);
  const generatePDF = () => {
    const pdf = new jsPDF("landscape", "mm", "a4");
    html2canvas(contentRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      pdf.addImage(imgData, "PNG", 5, 5, 280, 170);

      pdf.save("Standard Process Parameter.pdf");
    });
  };
  const exportToExcel = () => {
    setexc(true);
    setTimeout(() => {
      if (Records.length === 0) {
        alert("No records found. Cannot export to Excel.");
        return;
      }
      const sheetData = document.getElementById("excelTable");
      const wsData = XLSX.utils.table_to_sheet(sheetData);
      wsData["!rows"] = [{ hpx: 50 }];
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, wsData, "Sheet1");
      XLSX.writeFile(wb, "Standard Process Parameter.xlsx");
      setexc(false);
    }, 1000);
  };

  useEffect(() => {
    console.log("PREVIEW Records", Records);
    handleReadRecord("id", Records[0].Id);
    // setback(true)
  }, [Records]);

  function handleReadRecord(id) {
    ReadRecordPaint({
      _keyword_: "Get_approver_by_empId",
      TableID: Records[0].Id,
      TableName: "StandardProcessParameter",
      Approver: localStorage.getItem("empId"),
    })
      .then((res) => {
        console.log("Approver", res.data[0]);
        if (res.rows > 0) {
          setApprover(res.data[0].Approver);
          setRejectedReason(res.data[0].ApprovalRemark);
        } else {
          setApprover("");
          setRejectedReason("");
        }
      })
      .then(() => {
        ReadRecordPaint({
          _keyword_: "Get_approver_ApprovalRemark",
          TableID: Records[0].Id,
          TableName: "StandardProcessParameter",
        }).then((res) => {
          if (res.rows > 0) {
            console.log(res.rows);
            setRejectedReason(res.data[0].ApprovalRemark);
            setApproval(res.data[0].ApprovalRemark);
          } else {
            setRejectedReason("");
          }
        });
      });
  }
  function handleReadSPP() {
    ReadRecordPaint({
      _keyword_: "paint_SMP",
      Id: Records[0].Id,
    }).then((res) => {
      console.log("ooo", res);
      const record = res.data;
      console.log("0876", record);
      setback(true);
      setback(true);
    });
  }
  useEffect(() => {
    handleReadSPP();
  }, []);
  useEffect(() => {
    console.log("999", Records[0].Approval_status, Records[0].Id);
    console.log(Approver === localStorage.getItem("empId"));
    console.log(localStorage.getItem("empId"));
  });

  // const Id =Records[0].Id;
  return (
    <div
      style={{
        padding: "20px",
        position: "absolute",
        top: "3px",
        left: "2%",
        width: "98%",
        background: "white",
      }}
    >
      <div style={{ flexDirection: "row-reverse" }}>
        <CloseButton
          style={{
            padding: "12px",
            textAlign: "center",
            backgroundColor: "black",
            width: "5%",
          }}
          onClick={(e) => {
            setState(false);
          }}
        />
      </div>

      {
        Approver === localStorage.getItem("empId") &&
        window.location.href.includes("view") ? (
          Records[0].Approval_status === "0" &&
          window.location.href.includes("view") ? (
            <div>
              <span
                style={{
                  marginRight: "0%",
                  fontSize: "16px",
                  fontWeight: "bolder",
                  backgroundColor: "#DA251A",
                  width: "90px",
                  padding: "3px 10px",
                  marginLeft: "20px",
                  borderRadius: "10px",
                  border: "2px solid #005C9F",
                  color: "",
                }}
              >
                This Form has been Rejected due to{RejectedReason}
              </span>
            </div>
          ) : Records[0].Approval_status === "1" &&
            window.location.href.includes("view") ? (
            <div>
              <span
                style={{
                  marginRight: "0%",
                  fontSize: "16px",
                  fontWeight: "bolder",
                  backgroundColor: "#B9A44C",
                  width: "90px",
                  padding: "3px 10px",
                  marginLeft: "20px",
                  borderRadius: "10px",
                  border: "2px solid #005C9F",
                  color: "",
                }}
              >
                It is Approved Already, Contact Administrator if any change
                needed{" "}
              </span>
            </div>
          ) : (
            Records[0].Approval_status === "" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "25%",
                    gap: "5",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginRight: "10px",
                  }}
                >
                  <button
                    className="icon"
                    style={{ backgroundColor: "#06802f", marginRight: "10px" }}
                    type="button"
                    onClick={(e) => {
                      approve(
                        Records[0].Id,
                        "StandardProcessParameter",
                        "paint_SMP"
                      );
                      navigate(-1);
                    }}
                  >
                    Approve
                  </button>
                  <button
                    className="icon"
                    style={{ backgroundColor: "#9d080f" }}
                    type="button"
                    onClick={() => {
                      setcardId(id);
                    }}
                  >
                    Reject
                  </button>
                  {id === cardId && (
                    <div>
                      <div
                        style={{
                          backgroundColor: "gray",
                          filter: "blur(8px)",
                          position: "absolute",
                          opacity: ".8",
                          right: "0%",
                          bottom: "0%",
                          width: "100%",
                          height: "100%",
                        }}
                      ></div>
                      <div
                        style={{
                          position: "absolute",
                          right: "32%",
                          color: "white",
                          bottom: "35%",
                          padding: "20px",
                          borderRadius: "10px",
                        }}
                      >
                        <h3>Remarks</h3>
                        <textarea
                          rows={5}
                          cols={50}
                          onChange={(e) => {
                            setremarks(e.target.value);
                          }}
                        ></textarea>
                        <button
                          onClick={(e) => {
                            reject(
                              Records[0].Id,
                              "StandardProcessParameter",
                              remarks,
                              "paint_SMP"
                            );
                            console.log(
                              Records[0].Id,
                              "StandardProcessParameter",
                              remarks,
                              "paint_SMP"
                            );
                            navigate(-1);
                          }}
                        >
                          Submit
                        </button>
                        <button
                          onClick={() => {
                            setcardId("");
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )
          )
        ) : (
          ""
        )
        // (Records[0].Approval_status=== '0' ? <div><span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#DA251A', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>This Form has been Rejected due to {RejectedReason}</span></div>
        // :Records[0].Approval_status === "1" ? <div><span style={{ marginRight: '0%', fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#B9A44C', width: '90px', padding: '3px 10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', color: '' }}>It is Approved Already, Contact Administrator if any change needed </span></div>
        // :"")
      }

      {window.location.href.includes("view") && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "80%",
              padding: "1px",
            }}
          >
            <Dropdown style={{ width: "50%" }}>
              <Dropdown.Toggle>Download ⬇️</Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <button className="custom-button" onClick={generatePDF}>
                    PDF ⬇️
                  </button>
                </Dropdown.Item>
                <Dropdown.Item>
                  <button
                    className="custom-button"
                    style={{ color: "black" }}
                    onClick={() => exportToExcel()}
                  >
                    Excel ⬇️
                  </button>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>{" "}
          {/* <br></br> */}
        </>
      )}
      <div style={{ marginLeft: "1%", width: "98%" }} ref={contentRef}>
        <table>
          <thead>
            <tr>
              <th colSpan={15}>
                <Lable value={"Standard Process Parameter"} type={"Head"} />
              </th>
              <th colSpan={2} style={{ width: "20%" }}>
                <div className="previewLabel">
                  <span className="previewLabelLeft">DOC NO:</span>{" "}
                  <span className="previewLabelRight">{Records[0].Doc_No}</span>
                  <span className="previewLabelLeft">Rev No:</span>{" "}
                  <span className="previewLabelRight">{Records[0].Rev_No}</span>
                  <span className="previewLabelLeft">Rev Date:</span>{" "}
                  <span className="previewLabelRight">
                    {Records[0].Rev_ate}
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tr>
            <td colSpan={5}>
              {" "}
              <div className="previewLabel">
                <span className="previewLabelLeft">Part Name : </span>
                <span className="previewLabelRight">
                  {Records?.[0]?.Part_Name || ""}
                </span>
              </div>
            </td>
            <td colSpan={5}>
              {" "}
              <div className="previewLabel">
                <span className="previewLabelLeft">Program name : </span>
                <span className="previewLabelRight">
                  {Records?.[0]?.Program_Name || ""}
                  {/* {Records[0]?.ProgramName[0] &&
                    Records[0]?.ProgramName[0].Program_name} */}
                </span>
              </div>
            </td>
            <td colSpan={5}>
              {" "}
              <div className="previewLabel">
                <span className="previewLabelLeft">Paint : </span>
                <span className="previewLabelRight">
                  {Records?.[0]?.Paint || ""}
                  {/* {Records[0]?.PaintName[0] &&
                    Records[0]?.PaintName[0].Paint_name} */}
                </span>
              </div>
            </td>

            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabelLeft">Customer : </span>
                <span className="previewLabelRight">
                  {Records[0]?.CusName[0] &&
                    Records[0]?.CusName[0].Customer_name}
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={5} style={{ textAlign: "center" }}>
              Direction ( 0 Degree)
            </td>
            <td colSpan={5} style={{ textAlign: "center" }}>
              Direction ( 90 Degree)
            </td>
            <td colSpan={5} style={{ textAlign: "center" }}>
              Direction ( 180 Degree)
            </td>
            <td colSpan={5} style={{ textAlign: "center" }}>
              Direction ( 270 Degree)
            </td>
          </tr>
          <tr>
            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabelLeft">
                  A0#1(Automization Air):{" "}
                </span>
                <span className="previewLabelRight">
                  {Records?.[0]?.direction_function0?.[0]?.value ||
                    Records?.[0]?.painting_smp_direction?.[0]
                      ?.Authorization_Air ||
                    ""}
                </span>
              </div>
            </td>
            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabelLeft">
                  A0#1(Automization Air):{" "}
                </span>
                <span className="previewLabelRight">
                  {Records?.[0]?.direction_function90?.[0]?.value ||
                    Records?.[0]?.painting_smp_direction?.[1]
                      ?.Authorization_Air ||
                    ""}
                </span>
              </div>
            </td>
            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabelLeft">
                  A0#1(Automization Air):{" "}
                </span>
                <span className="previewLabelRight">
                  {Records?.[0]?.direction_function180?.[0]?.value ||
                    Records?.[0]?.painting_smp_direction?.[2]
                      ?.Authorization_Air ||
                    ""}
                </span>
              </div>
            </td>
            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabelLeft">
                  A0#1(Automization Air):{" "}
                </span>
                <span className="previewLabelRight">
                  &nbsp;&nbsp;&nbsp;
                  {Records?.[0]?.direction_function270?.[0]?.value ||
                    Records?.[0]?.painting_smp_direction?.[3]
                      ?.Authorization_Air ||
                    ""}
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabelLeft">A0#2(Fan Air): </span>

                <span className="previewLabelRight">
                  {Records?.[0]?.direction_function0?.[1]?.value ||
                    Records?.[0]?.painting_smp_direction?.[0]?.Fan_Air ||
                    ""}
                </span>
              </div>
            </td>

            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabelLeft">A0#2(Fan Air): </span>
                <span className="previewLabelRight">
                  {Records?.[0]?.direction_function90?.[1]?.value ||
                    Records?.[0]?.painting_smp_direction?.[1]?.Fan_Air ||
                    ""}
                </span>
              </div>
            </td>
            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabelLeft">A0#2(Fan Air): </span>
                <span className="previewLabelRight">
                  {Records?.[0]?.direction_function180?.[1]?.value ||
                    Records?.[0].painting_smp_direction?.[2]?.Fan_Air ||
                    ""}
                </span>
              </div>
            </td>
            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabelLeft">A0#2(Fan Air): </span>
                <span className="previewLabelRight">
                  &nbsp;&nbsp;&nbsp;
                  {Records?.[0]?.direction_function270?.[1]?.value ||
                    Records?.[0]?.painting_smp_direction?.[3]?.Fan_Air ||
                    ""}
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabelLeft">
                  A0#3(Material Pressure)±0.5 Bar:{" "}
                </span>
                <span className="previewLabelRight">
                  {Records?.[0]?.direction_function0?.[2]?.value ||
                    Records?.[0]?.painting_smp_direction?.[0]
                      ?.Material_Pressure ||
                    ""}
                </span>
              </div>
            </td>
            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabelLeft">
                  A0#3(Material Pressure)±0.5 Bar :{" "}
                </span>
                <span className="previewLabelRight">
                  {Records?.[0]?.direction_function90?.[2]?.value ||
                    Records?.[0]?.painting_smp_direction?.[1]
                      ?.Material_Pressure ||
                    ""}
                </span>
              </div>
            </td>
            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabelLeft">
                  A0#3(Material Pressure)±0.5 Bar :{" "}
                </span>
                <span className="previewLabelRight">
                  {Records?.[0]?.direction_function180?.[2]?.value ||
                    Records?.[0]?.painting_smp_direction?.[2]
                      ?.Material_Pressure ||
                    ""}
                </span>
              </div>
            </td>
            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabelLeft">
                  A0#3(Material Pressure)±0.5 Bar :{" "}
                </span>
                <span className="previewLabelRight">
                  &nbsp;&nbsp;&nbsp;{" "}
                  {Records?.[0]?.direction_function270?.[2]?.value ||
                    Records?.[0].painting_smp_direction?.[3]
                      ?.Material_Pressure ||
                    ""}
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabelLeft">File NO : </span>
                <span className="previewLabelRight">
                  {Records?.[0]?.direction_function0?.[3]?.value ||
                    Records?.[0]?.painting_smp_direction?.[0]?.File_No ||
                    ""}
                </span>
              </div>
            </td>
            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabelLeft">File NO : </span>
                <span className="previewLabelRight">
                  {Records?.[0]?.direction_function90?.[3]?.value ||
                    Records?.[0]?.painting_smp_direction?.[1]?.File_No ||
                    ""}
                </span>
              </div>
            </td>
            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabelLeft">File NO : </span>
                <span className="previewLabelRight">
                  {Records?.[0]?.direction_function180?.[3]?.value ||
                    Records?.[0]?.painting_smp_direction?.[2]?.File_No ||
                    ""}
                </span>
              </div>
            </td>
            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabelLeft">File NO : </span>
                <span className="previewLabelRight">
                  &nbsp;&nbsp;&nbsp;
                  {Records?.[0]?.direction_function270?.[3]?.value ||
                    Records?.[0]?.painting_smp_direction?.[3]?.File_No ||
                    ""}
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabelLeft">NO Of Strokes : </span>
                <span className="previewLabelRight">
                  {Records?.[0]?.direction_function0?.[4]?.value ||
                    Records?.[0]?.painting_smp_direction?.[0]?.No_of_Strokes ||
                    ""}
                </span>
              </div>
            </td>
            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabelLeft">NO Of Strokes : </span>
                <span className="previewLabelRight">
                  {Records?.[0]?.direction_function90?.[4]?.value ||
                    Records?.[0]?.painting_smp_direction?.[1]?.No_of_Strokes ||
                    ""}
                </span>
              </div>
            </td>
            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabelLeft">NO Of Strokes: </span>
                <span className="previewLabelRight">
                  {Records?.[0]?.direction_function180?.[4]?.value ||
                    Records?.[0]?.painting_smp_direction?.[2]?.No_of_Strokes ||
                    ""}
                </span>
              </div>
            </td>
            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabelLeft">NO Of Strokes : </span>
                <span className="previewLabelRight">
                  &nbsp;&nbsp;&nbsp;
                  {Records?.[0]?.direction_function270?.[4]?.value ||
                    Records?.[0]?.painting_smp_direction?.[3]?.No_of_Strokes ||
                    ""}
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabelLeft">
                  Position Speed (mm/s) :{" "}
                </span>
                <span className="previewLabelRight">
                  {Records?.[0]?.direction_function0?.[4]?.value ||
                    Records?.[0]?.painting_smp_direction?.[0]?.Position_speed ||
                    ""}
                </span>
              </div>
            </td>
            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabelLeft">
                  Position Speed (mm/s) :{" "}
                </span>
                <span className="previewLabelRight">
                  {Records?.[0]?.direction_function90?.[4]?.value ||
                    Records?.[0]?.painting_smp_direction?.[1]?.Position_speed ||
                    ""}
                </span>
              </div>
            </td>
            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabelLeft">
                  Position Speed (mm/s) :{" "}
                </span>
                <span className="previewLabelRight">
                  {Records?.[0]?.direction_function180?.[4]?.value ||
                    Records?.[0]?.painting_smp_direction?.[2]?.Position_speed ||
                    ""}
                </span>
              </div>
            </td>
            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabelLeft">
                  Position Speed (mm/s) :{" "}
                </span>
                <span className="previewLabelRight">
                  &nbsp;&nbsp;&nbsp;
                  {Records?.[0]?.direction_function270?.[4]?.value ||
                    Records?.[0]?.painting_smp_direction?.[3]?.Position_speed ||
                    ""}
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabelLeft">GUN HEIGHT : </span>
              </div>
            </td>
            <td colSpan={15}>
              <div className="previewLabel">
                <span className="previewLabelRight">
                  {Records[0]?.Gun_Height && Records[0]?.Gun_Height}
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={5}>
              <div className="previewLabel">
                <span className="previewLabelLeft">GUN ANGLE : </span>
              </div>
            </td>
            <td colSpan={15}>
              <div className="previewLabel">
                <span className="previewLabelRight">
                  {Records[0]?.Gun_Angle && Records[0]?.Gun_Angle}
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={5}>
              {" "}
              <span style={{ marginLeft: "1%" }}>Created By : </span>
            </td>
            <td colSpan={5} style={{ marginLeft: "1%" }}>
              <span className="previewLabelRight" style={{ marginLeft: "10%" }}>
                {(Records?.[0]?.Createdname &&
                  Records?.[0]?.Createdname?.[0]?.Emp_name) ||
                  Records?.[0]?.Created_By ||
                  ""}
              </span>
            </td>
            <td colSpan={5}>
              {" "}
              <span style={{ marginLeft: "1%" }}>Approved By : </span>
            </td>
            <td colSpan={5}>
              <span className="previewLabelRight" style={{ marginLeft: "10%" }}>
                {(Records?.[0]?.Approvedname &&
                  Records[0]?.Approvedname?.[0]?.Emp_name) ||
                  Records?.[0]?.Approved_By ||
                  ""}
              </span>
            </td>
          </tr>

          {!window.location.href.includes("view") && (
            <tr>
              {id === undefined || option === "Duplicate" ? (
                <td colSpan={20}>
                  {" "}
                  <Field
                    label={"Submit"}
                    options={{ type: "Button" }}
                    onClick={(e) => {
                      HandleCreate(e);
                    }}
                  />
                </td>
              ) : (
                <td colSpan={20}>
                  {" "}
                  <Field
                    label={"Update"}
                    options={{ type: "Button" }}
                    onClick={(e) => {
                      HandleUpdate(e);
                    }}
                  />
                </td>
              )}
            </tr>
          )}
        </table>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      {/* Excel Export*/}
      <div>
        {exc ? (
          <table id="excelTable">
            <thead>
              <tr>
                <td colSpan="5" rowSpan={3}></td>
                <td
                  colSpan="10"
                  rowSpan={3}
                  style={{
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                    fontSize: "20px",
                  }}
                >
                  <font>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    STANDARD PROCESS PARAMETER
                  </font>
                </td>
                <td colSpan={5} style={{ width: "20%" }}>
                  DOC NO: &nbsp;&nbsp; {Records[0].Doc_No}
                </td>
              </tr>
              <tr>
                {" "}
                <td colSpan={5} style={{ width: "20%" }}>
                  Rev No: &nbsp;&nbsp; {Records[0].Rev_No}
                </td>
              </tr>
              <tr>
                {" "}
                <td colSpan={5} style={{ width: "20%" }}>
                  Rev Date: &nbsp;&nbsp; {Records[0].Rev_ate}
                </td>
              </tr>

              <tr>
                <td colSpan={3}>
                  {" "}
                  <div className="previewLabel">
                    <span className="previewLabelLeft">Part Name : </span>
                  </div>
                </td>
                <td colSpan={2}>
                  {" "}
                  <div className="previewLabel">
                    <span className="previewLabelRight">
                      {(Records[0]?.PartName[0] &&
                        Records[0]?.PartName[0].Part_name) ||
                        Records?.[0]?.Part_Name ||
                        ""}
                    </span>
                  </div>
                </td>
                <td colSpan={3}>
                  {" "}
                  <div className="previewLabel">
                    <span className="previewLabelLeft">Program name : </span>
                  </div>
                </td>
                <td colSpan={2}>
                  <span className="previewLabelRight">
                    {Records[0]?.Program_Name || ""}
                  </span>
                </td>
                <td colSpan={2}>
                  {" "}
                  <div className="previewLabel">
                    <span className="previewLabelLeft">Paint : </span>
                  </div>
                </td>
                <td colSpan={3}>
                  <span className="previewLabelRight">
                    {Records[0]?.Paint || ""}
                  </span>
                </td>
                <td colSpan={3}>
                  <div className="previewLabel">
                    <span className="previewLabelLeft">Customer : </span>
                  </div>
                </td>
                <td colSpan={2}>
                  <span className="previewLabelRight">
                    {Records[0]?.CusName[0] &&
                      Records[0]?.CusName[0].Customer_name}
                  </span>
                </td>
              </tr>
              <tr>
                <td colSpan={5} style={{ textAlign: "center" }}>
                  Direction ( 0 Degree)
                </td>
                <td colSpan={5} style={{ textAlign: "center" }}>
                  Direction ( 90 Degree)
                </td>
                <td colSpan={5} style={{ textAlign: "center" }}>
                  Direction ( 180 Degree)
                </td>
                <td colSpan={5} style={{ textAlign: "center" }}>
                  Direction ( 270 Degree)
                </td>
              </tr>
              <tr>
                <td colSpan={4}>A0#1(Automization Air): </td>
                <td colSpan={1}>
                  {Records?.[0]?.direction_function0?.[0]?.value ||
                    Records?.[0]?.painting_smp_direction?.[0]
                      ?.Authorization_Air ||
                    ""}
                </td>
                <td colSpan={4}>A0#1(Automization Air): </td>
                <td colSpan={1}>
                  {Records?.[0]?.direction_function90?.[0]?.value ||
                    Records?.[0]?.painting_smp_direction?.[1]
                      ?.Authorization_Air ||
                    ""}
                </td>
                <td colSpan={4}>A0#1(Automization Air): </td>
                <td colSpan={1}>
                  {Records?.[0]?.direction_function180?.[0]?.value ||
                    Records?.[0]?.painting_smp_direction?.[2]
                      ?.Authorization_Air ||
                    ""}
                </td>
                <td colSpan={4}>A0#1(Automization Air): </td>
                <td colSpan={1}>
                  {Records?.[0]?.direction_function270?.[0]?.value ||
                    Records?.[0]?.painting_smp_direction?.[3]
                      ?.Authorization_Air ||
                    ""}
                </td>
              </tr>
              <tr>
                <td colSpan={4}>A0#2(Fan Air):</td>
                <td colSpan={1}>
                  {Records?.[0]?.direction_function0?.[1]?.value ||
                    Records?.[0]?.painting_smp_direction?.[0]?.Fan_Air ||
                    ""}
                </td>
                <td colSpan={4}>A0#2(Fan Air):</td>{" "}
                <td colSpan={1}>
                  {Records?.[0]?.direction_function90?.[1]?.value ||
                    Records?.[0]?.painting_smp_direction?.[1]?.Fan_Air ||
                    ""}
                </td>
                <td colSpan={4}>A0#2(Fan Air):</td>
                <td colSpan={1}>
                  {Records?.[0]?.direction_function180?.[1]?.value ||
                    Records?.[0].painting_smp_direction?.[2]?.Fan_Air ||
                    ""}
                </td>
                <td colSpan={4}>A0#2(Fan Air):</td>
                <td colSpan={1}>
                  {Records?.[0]?.direction_function270?.[1]?.value ||
                    Records?.[0]?.painting_smp_direction?.[3]?.Fan_Air ||
                    ""}
                </td>
              </tr>
              <tr>
                <td colSpan={4}>A0#3(Material Pressure)±0.5 Bar: </td>
                <td colSpan={1}>
                  {Records?.[0]?.direction_function0?.[2]?.value ||
                    Records?.[0]?.painting_smp_direction?.[0]
                      ?.Material_Pressure ||
                    ""}
                </td>
                <td colSpan={4}>A0#3(Material Pressure)±0.5 Bar: </td>
                <td colSpan={1}>
                  {Records?.[0]?.direction_function90?.[2]?.value ||
                    Records?.[0]?.painting_smp_direction?.[1]
                      ?.Material_Pressure ||
                    ""}
                </td>
                <td colSpan={4}>A0#3(Material Pressure)±0.5 Bar: </td>
                <td colSpan={1}>
                  {Records?.[0]?.direction_function180?.[2]?.value ||
                    Records?.[0]?.painting_smp_direction?.[2]
                      ?.Material_Pressure ||
                    ""}
                </td>
                <td colSpan={4}>A0#3(Material Pressure)±0.5 Bar: </td>
                <td colSpan={1}>
                  {Records?.[0]?.direction_function270?.[2]?.value ||
                    Records?.[0].painting_smp_direction?.[3]
                      ?.Material_Pressure ||
                    ""}
                </td>
              </tr>
              <tr>
                <td colSpan={4}>File NO : </td>
                <td colSpan={1}>
                  {Records?.[0]?.direction_function0?.[3]?.value ||
                    Records?.[0]?.painting_smp_direction?.[0]?.File_No ||
                    ""}
                </td>
                <td colSpan={4}>File NO : </td>
                <td colSpan={1}>
                  {Records?.[0]?.direction_function90?.[3]?.value ||
                    Records?.[0]?.painting_smp_direction?.[1]?.File_No ||
                    ""}
                </td>
                <td colSpan={4}>File NO : </td>
                <td colSpan={1}>
                  {Records?.[0]?.direction_function180?.[3]?.value ||
                    Records?.[0]?.painting_smp_direction?.[2]?.File_No ||
                    ""}
                </td>
                <td colSpan={4}>File NO : </td>
                <td colSpan={1}>
                  {Records?.[0]?.direction_function270?.[3]?.value ||
                    Records?.[0]?.painting_smp_direction?.[3]?.File_No ||
                    ""}
                </td>
              </tr>
              <tr>
                <td colSpan={4}>NO Of Strokes :</td>
                <td colSpan={1}>
                  {Records?.[0]?.direction_function0?.[4]?.value ||
                    Records?.[0]?.painting_smp_direction?.[0]?.No_of_Strokes ||
                    ""}
                </td>
                <td colSpan={4}>NO Of Strokes :</td>
                <td colSpan={1}>
                  {Records?.[0]?.direction_function90?.[4]?.value ||
                    Records?.[0]?.painting_smp_direction?.[1]?.No_of_Strokes ||
                    ""}
                </td>
                <td colSpan={4}>NO Of Strokes: </td>

                <td colSpan={1}>
                  {Records?.[0]?.direction_function180?.[4]?.value ||
                    Records?.[0]?.painting_smp_direction?.[2]?.No_of_Strokes ||
                    ""}
                </td>
                <td colSpan={4}>
                  <span className="previewLabelLeft">NO Of Strokes : </span>
                </td>
                <td colSpan={1}>
                  {Records?.[0]?.direction_function270?.[4]?.value ||
                    Records?.[0]?.painting_smp_direction?.[3]?.No_of_Strokes ||
                    ""}
                </td>
              </tr>
              <tr>
                <td colSpan={4}>Position Speed (mm/s) : </td>

                <td colSpan={1}>
                  {Records?.[0]?.direction_function0?.[4]?.value ||
                    Records?.[0]?.painting_smp_direction?.[0]?.Position_speed ||
                    ""}
                </td>
                <td colSpan={4}>Position Speed (mm/s) : </td>
                <td colSpan={1}>
                  {Records?.[0]?.direction_function90?.[4]?.value ||
                    Records?.[0]?.painting_smp_direction?.[1]?.Position_speed ||
                    ""}
                </td>
                <td colSpan={4}>
                  <span className="previewLabelLeft">
                    Position Speed (mm/s) :{" "}
                  </span>
                </td>
                <td colSpan={1}>
                  {" "}
                  <span className="previewLabelRight">
                    {Records?.[0]?.direction_function180?.[4]?.value ||
                      Records?.[0]?.painting_smp_direction?.[2]
                        ?.Position_speed ||
                      ""}
                  </span>
                </td>
                <td colSpan={4}>
                  <span className="previewLabelLeft">
                    Position Speed (mm/s) :{" "}
                  </span>
                </td>
                <td colSpan={1}>
                  <span className="previewLabelRight">
                    {Records?.[0]?.direction_function270?.[4]?.value ||
                      Records?.[0]?.painting_smp_direction?.[3]
                        ?.Position_speed ||
                      ""}
                  </span>
                </td>
              </tr>
              <tr>
                <td colSpan={5}>
                  <div className="previewLabel">
                    <span className="previewLabelLeft">GUN HEIGHT : </span>
                  </div>
                </td>
                <td colSpan={5}>
                  <div className="previewLabel">
                    <span className="previewLabelRight">
                      {Records[0]?.Gun_Height && Records[0]?.Gun_Height}
                    </span>
                  </div>
                </td>
                <td colSpan={10} />
              </tr>
              <tr>
                <td colSpan={5}>
                  <div className="previewLabel">
                    <span className="previewLabelLeft">GUN ANGLE : </span>
                  </div>
                </td>
                <td colSpan={5}>
                  <div className="previewLabel">
                    <span className="previewLabelRight">
                      {Records[0]?.Gun_Angle && Records[0]?.Gun_Angle}
                    </span>
                  </div>
                </td>
                <td colSpan={10} />
              </tr>
              <tr>
                <td colSpan={10}>
                  {" "}
                  <span className="previewLabelLeft">Created By : </span>
                  <span className="previewLabelRight">
                    &nbsp;&nbsp;
                    {(Records?.[0]?.Createdname &&
                      Records?.[0]?.Createdname?.[0]?.Emp_name) ||
                      Records?.[0]?.Created_By ||
                      ""}
                  </span>
                </td>
                <td colSpan={10}>
                  {" "}
                  <span className="previewLabelLeft">Approved By : </span>
                  <span className="previewLabelRight">
                    &nbsp;&nbsp;
                    {(Records?.[0]?.Approvedname &&
                      Records[0]?.Approvedname?.[0]?.Emp_name) ||
                      Records?.[0]?.Approved_By ||
                      ""}
                  </span>
                </td>
              </tr>
            </thead>
          </table>
        ) : null}
      </div>
    </div>
  );
};

export default PreviewPage;
