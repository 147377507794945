import { SECRET_KEY } from "../Utils/constants";
import GetTableRecord from "./GetTableRecordPaint";
import UpdateRecord from "./updateRecordPaint";
import { setMessagePaint } from "./SetMessagePaint";
import UpdateRecordPaint from "./API/UpdateRecordPaint";

const approve = async (tableId, tableName, UpdateQueryKeyword) => {
  let Level = 0;
  let approval = "";
  GetTableRecord({
    _keyword_: "Get_approval_by_statuswaiting_and_empId",
    TableID: tableId,
    TableName: tableName,
    Approver: localStorage.getItem("empId"),
  })
    .then(async (res) => {

      Level = res.data[0].Level;
      approval = res.data[0].Id;
      
      setMessagePaint({
        NoteFrom: localStorage.getItem("empId"),
        NoteTo: res.data[0].RequestedBy,
        NoteMessage:
          `Your request for record-${tableName}, Has been Approved by ${localStorage.getItem("empId")}-
        ${localStorage.getItem("name")}`,
        NoteRecordId: tableId,
        TableName: tableName,
        NoteCreatedBy: localStorage.getItem("empId"),
      });
      alert("The record has been Approved")
    })
    .then(async () => {
      GetTableRecord({
        _keyword_: "Get_approval_by_statuswaiting",
        TableID: tableId,
        TableName: tableName,
      }).then(async (res) => {
        console.log(res);
        if (res.row > 1) {
          let WFA = res.data.filter(item => item.Status === "Waiting for Approval")
          let levels = res.data.filter(item => item.Level > Level)
          console.log(WFA,levels);
          if (levels.length > 0) {
            await UpdateRecord({
              _keyword_: "approval",
              secretkey: SECRET_KEY,
              data: [
                {
                  Id: approval,
                  Status: "Approved",
                },
              ],
            });
            return res;
          } else if (WFA.length > 0) {
            await UpdateRecordPaint({
              _keyword_: "approval",
              secretkey: SECRET_KEY,
              data: [
                {
                  Id: approval,
                  Status: "Approved",
                },
              ],
            });
            return res;
          }
        }else{
          await UpdateRecordPaint({
            _keyword_: UpdateQueryKeyword,
            secretkey: SECRET_KEY,
            data: [
              {
                Id: tableId,
                Approval_status: "1",
              },
            ],
          });
          await UpdateRecordPaint({
            _keyword_: "approval",
            secretkey: SECRET_KEY,
            data: [
              {
                Id: approval,
                Status: "Approved",
              },
            ],
          });
          return res;
        }
        
      });
    });
};

export default approve;
