import React, { useEffect, useState, useRef } from "react";
import Field from "../../../Components/Fields/Field";
import Lable from "../../../Components/Fields/Lable";
import { useNavigate, useParams } from "react-router-dom";
import "./ProductQualityPlanForm.css";
import CreateRecord from "../../../Lib/API/CreateRecord";
import ReadRecord from "../../../Lib/API/ReadRecord";
import UpdateRecord from "../../../Lib/API/UpdateRecord";
import QaLayout from "../QaLayout";
import CreateApproval from "../../../Lib/CreateApproval";
import { setMessage } from "../../../Lib/SetMessage";
import IsDuplicate from "../../../Lib/IsDuplicate";
import {
  CURRENT_Date,
  SHIFTMOLDINGQUALITY,
} from "../../../Utils/GeneralConstants";
import LoadingPage from "../../../Components/Handler/LoadingPage";
import { Dropdown } from "react-bootstrap";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useScrollEffect } from "../useScrollEffect";
import * as XLSX from "xlsx";
import html2pdf from "html2pdf.js";
import Logo from "../../../icons/Logo1.png";


const ProductQualityPlanForm = () => {
  const contentRef = useRef(null);
  const [downloadEnabled, setDownloadEnabled] = useState(true);
  const [DownloadDiv, setDownloadDiv] = useState(false);
  const CURRENT_TIME = new Date(
    new Date().getTime() - new Date().getTimezoneOffset() * 60000
  )
    .toISOString()
    .split("T")[1]
    .substring(0, 5);
  const rows = Array.from({ length: 20 }, (_, index) => index + 1);

  const initDimension = {
    Specification_minus: "",
    Checking_aids: "Vernier",
    Specification_plus: "",
    Specification_val: "",
    Specifications_loc: "",
    Specification_symbol_1: "-",
    Specification_symbol_2: "+",
  };
  const InitRoot = [
    {
      download: false,
      Preparedby: [{}],
      Approvedby: [{}],
      Rawmaterialname: [],
      Partname: [{}],
      PartNumber: [{}],
      Prodcutionoperatorname: [{}],
      Part_name: "",
      Part_number: "",
      Raw_material_name: "",
      Prepared_by: "",
      Approved_by: "",
      dimensions: [
        initDimension,
        initDimension,
        initDimension,
        initDimension,
        initDimension,
        initDimension,
        initDimension,
        initDimension,
        initDimension,
        initDimension,
        initDimension,
        initDimension,
        initDimension,
        initDimension,
        initDimension,
        initDimension,
        initDimension,
        initDimension,
        initDimension,
        initDimension,
      ],
      Customername: [{}],
      Customer: "",
      Model: "",
      Image: "",
      Format_no: "UPCL/QC/D/05",
      Rev_no: "00",
      Rev_ate: "01-11-2012",
      Part_weight_1: "",
      Part_weight_set_minus_1: "",
      Part_weight_set_plus_1: "",
      Part_weight_2: "",
      Part_weight_set_minus_2: "",
      Part_weight_set_plus_2: "",
      Quality_product_plan_dt: CURRENT_Date,
      Two_k_part: "0",
    },
  ];
  const tableRef = useRef(null);
  const scrollTop = useRef(null);
  useScrollEffect();
  const [Records, setRecords] = useState(InitRoot);
  const [warning, setwarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Raw, setRaw] = useState([]);
  const [PartDropdown, setPartDropdown] = useState([]);
  const [addSubStates1, setAddSubStates1] = useState(Array(20).fill(false));
  const [addSubStates2, setAddSubStates2] = useState(Array(20).fill(false));

  const toggleAddSubState1 = (rowNumber) => {
    const newAddSubStates1 = [...addSubStates1];
    newAddSubStates1[rowNumber - 1] = !newAddSubStates1[rowNumber - 1];
    setAddSubStates1(newAddSubStates1);
  };
  const toggleAddSubState2 = (rowNumber) => {
    const newAddSubStates2 = [...addSubStates2];
    newAddSubStates2[rowNumber - 1] = !newAddSubStates2[rowNumber - 1];
    setAddSubStates2(newAddSubStates2);
  };
  useEffect(() => {
    console.log("Records", Records);
  }, [Records]);

  const { option, id } = useParams();
  const navigate = useNavigate();

  function InvalidCharacters(value) {
    return /[a-zA-Z!@#$%^&*()_\-+={[}\]|\\:;"'<,>?/~`]/.test(value);
  }
  function HandleRecords(Event) {
    setRecords([{ ...Records[0], [Event.target.name]: Event.target.value }]);
  }
  function ResetRecords() {
    let record = InitRoot;
    setRecords(record);
    setRaw([]);
  }
  function HandleRecordsBoolean(Event) {
    console.log("Event.target.name",Event.target.name);
    ResetRecords();
    if (Event.target.checked == true) {
      setRecords((record)=>{      
        record[0][Event.target.name]= '1';
        return [...record];
      })
    } else {
      setRecords((record)=>{      
        record[0][Event.target.name]= '0';
        return [...record];
      })
    }
    // setRecords((record)=>{
      
    //   record[0][Event.target.name]= Event.target.checked == true ? "1" : "0";
    //   return [...record];
    // })
  }
  const handleSymbolClick = (rowNumber, symbol, name) => {
    // console.log(`Clicked ${symbol} for row ${rowNumber}`);
    setRecords((item) => {
      let record = [...Records];
      record[0].dimensions[rowNumber] = {
        ...record[0].dimensions[rowNumber],
        [name]: symbol,
      };
      return record;
    });
  };

  function HandleRecordSelect(Event, column, value, label) {
    setRecords(() => {
      let record = [...Records];
      console.log("record122", record);
      record[0][Event.name] = Event.values.value;
      record[0][column][0][value] = Event.values.value;
      record[0][column][0][label] = Event.values.label;
      return record;
    });
  }
  function HandleRecordSelectRaw1(Event, column, val, lab) {
    setRecords(() => {
      let record = [...Records];
      console.log("record122", record);
      record[0][Event.name] = Event.values.value;
      if (record[0][column].length === 0) {
        record[0][column].push({
          Mh_raw_mat: Event.values.value,
          Mh_raw_mat_name: Event.values.label,
        });
      } else {
        record[0][column][0]["Mh_raw_mat"] = Event.values.value;
        record[0][column][0]["Mh_raw_mat_name"] = Event.values.label;
      }

      // record[0][column][0][value] = Event.values.value;
      // record[0][column][0][label] = Event.values.label;
      return record;
    });
  }
  function HandleRecordSelectRaw2(Event, column, value, label) {
    setRecords(() => {
      let record = [...Records];
      console.log("Length", Event.values);
      if (Event.values.length <= 2) {
        record[0][Event.name] = JSON.stringify(Event.values);
        // record[0][column][0][value] = Event.values.value;
        // record[0][column][0][label] = Event.values.label;
        record[0][column] = Event.values;
      }
      return record;
    });
  }
  async function HandlePartname(Event) {
    console.log("Event", Event.values.value);
    //  IsDuplicate("quality_product_plan", [`part_number="${Records[0].Part_number}"`,`part_name="${Event.values.value}"`,`ActiveStatus='1'`]).then((res2) => {
    // if (res2 != true) {
    ReadRecord({
      _keyword_: "Get_model_by_part_name",
      part: Event.values.value,
    }).then((res) => {
      console.log(res.data[0].mc_part_flex_01);
      IsDuplicate("quality_product_plan", [
        `part_number="${res.data[0].mc_part_code}"`,
        `part_name="${Event.values.value}"`,
        `ActiveStatus='1'`,
      ]).then((res2) => {
        if (res2 != true) {
          setRecords(() => {
            let record = [...Records];
            record[0][Event.name] = Event.values.value;
            (record[0].Model = res.data[0].mc_part_flex_01),
              (record[0].Part_number = res.data[0].mc_part_code),
              (record[0].Part_name = res.data[0].mc_part_name),
              (record[0]["Partname"][0]["mc_part_name"] = Event.values.value);
            record[0]["Partname"][0]["mc_part_name"] = Event.values.label;
            record[0]["PartNumber"][0]["mc_part_code"] =
              res.data[0].mc_part_code;
            record[0]["PartNumber"][0]["mc_part_code"] =
              res.data[0].mc_part_code;
            console.log(
              "GetRawMaterial(res.data[0].mc_part_name)",
              res.data[0].mc_part_name
            );
            GetRawMaterial(res.data[0].mc_part_name);
            return record;
          });
        } else {
          alert("Part already Has Record in Quality Plan Records");
        }
      });
      // setRecords(() => {
      //   let record = [...Records];
      //   record[0][Event.name] = Event.values.value;
      //   (record[0].Model = res.data[0].mc_part_flex_01),
      //     (record[0].Part_number = res.data[0].mc_part_code),
      //     (record[0].Part_name = res.data[0].mc_part_name),
      //     (record[0]["Partname"][0]["mc_part_name"] = Event.values.value);
      //   record[0]["Partname"][0]["mc_part_name"] = Event.values.label;
      //   record[0]["PartNumber"][0]["mc_part_code"] = res.data[0].mc_part_code;
      //   record[0]["PartNumber"][0]["mc_part_code"] = res.data[0].mc_part_code;
      //   console.log(
      //     "GetRawMaterial(res.data[0].mc_part_name)",
      //     res.data[0].mc_part_name
      //   );
      //   GetRawMaterial(res.data[0].mc_part_name);
      //   return record;
      // });
    });
    // } else {
    //   alert("Part already Has Record in Quality Plan Records")
    // }
    // })
  }
  async function HandlePartCode(Event) {
    Records[0].Model = "";
    Records[0].Part_name = "";
    Records[0]["Partname"][0]["mc_part_name"] = "";
    (Records[0].Raw_material_name = ""), (Records[0].Rawmaterialname = []);
    // (Records[0]["Rawmaterialname"][0]["Mh_raw_mat"] = "");
    // Records[0]["Rawmaterialname"][0]["Mh_raw_mat_name"] = "";
    console.log("Event", Event.values.value);
    // GetRawMaterial(Event.values.value);
    // if (await IsDuplicate("quality_product_plan", [`part_number="${Event.values.value}"`])) {
    ReadRecord({
      _keyword_: "Get_part_name_from_head_by_code",
      key: Event.values.value,
    }).then(async (res) => {
      if (res.rows === "1") {
        await IsDuplicate("quality_product_plan", [
          `part_number="${Event.values.value}"`,
          `part_name="${res.data[0].mc_part_name}"`,
          `ActiveStatus='1'`,
        ]).then((res1) => {
          console.log("IsDuplicate", res1);
          if (res1 != true) {
            setRecords(() => {
              //       console.log("Event.values.value", Event.values.value);
              let record = [...Records];
              (record[0].Part_number = Event.values.value),
                (record[0]["PartNumber"][0]["mc_part_code"] =
                  Event.values.value);
              record[0]["PartNumber"][0]["mc_part_code"] = Event.values.label;
              (record[0].Part_name = res.data[0].mc_part_name),
                (record[0]["Partname"][0]["mc_part_name"] =
                  res.data[0].mc_part_name);
              record[0]["Partname"][0]["mc_part_name"] =
                res.data[0].mc_part_name;
              console.log("12131213", record);
              GetRawMaterial(res.data[0].mc_part_name);
              ReadRecord({
                _keyword_: "Get_model_by_part_name",
                part: res.data[0].mc_part_name,
              }).then((res) => {
                console.log(res.data[0].mc_part_flex_01);
                setRecords(() => {
                  let record = [...Records];
                  record[0].Model = res.data[0].mc_part_flex_01;
                  return record;
                });
              });
              return record;
            });
          } else {
            alert("Part already Has Record in Quality Plan Records");
          }
        });
      } else if (res.rows > "1") {
        console.log("res.data", res.data);
        setRecords(() => {
          //     setPartDropdown(res.data);
          let record = [...Records];
          (record[0].Part_number = Event.values.value),
            (record[0]["PartNumber"][0]["mc_part_code"] = Event.values.value);
          record[0]["PartNumber"][0]["mc_part_code"] = Event.values.label;
          //     // record[0].Part_name = res.data[0].mc_part_name,
          //     //   record[0]["Partname"][0]["mc_part_name"] = res.data[0].mc_part_name;
          //     // record[0]["Partname"][0]["mc_part_name"] = res.data[0].mc_part_name;
          //     console.log("12131213", record);
          //     // setRecords(() => {
          //     //   let record = [...Records];
          //     //   record[0][Event.name] = Event.values.value;
          //     //   record[0].Model = res.data[0].mc_part_flex_01,
          //     //     record[0].Part_number = Event.values.value,
          //     //     record[0]["Partname"][0]["mc_part_code"] = Event.values.value;
          //     //   record[0]["Partname"][0]["mc_part_code"] = Event.values.label;
          //     //   return record;
          //     // });
          return record;
        });
      }
    });
    // } else {
    //   alert("Part Number already Has Record in Quality Plan Records")
    // }
  }
  function GetRawMaterial(val) {
    setRaw([]);

    ReadRecord({
      _keyword_: "Get_raw_material_by_part",
      part: val,
    }).then((res) => {
      if (res.rows == "1") {
        if(Records[0].Two_k_part == '0'){
          setRecords(() => {
            //       console.log("Event.values.value", Event.values.value);
            let record = [...Records];
            record[0].Raw_material_name = res.data[0].Mh_raw_mat;
  
            let RawMat = {
              Mh_raw_mat: res.data[0].Mh_raw_mat,
              Mh_raw_mat_name: res.data[0].Mh_raw_mat_name,
            };
            // console.log("RawMat",RawMat);
            record[0]["Rawmaterialname"].push(RawMat);
            setRaw(res.data);
            //   (record[0]["Rawmaterialname"][0]["Mh_raw_mat"] =
            //     res.data[0].Mh_raw_mat);
            // record[0]["Rawmaterialname"][0]["Mh_raw_mat_name"] =
            //   res.data[0].Mh_raw_mat_name;
            // record[0].Rawmaterialname
            return record;
          });
        }else{
          setRaw(res.data);
        }
        
      } else if (res.rows > "1") {
        console.log("respose of raw", res.data);
        setRaw(res.data);
      } else {
        alert(
          "Contact Administrator to add Raw Material for this Selected Part!"
        );
      }
    });
  }
  function HandleDimensions(Event, index) {   
      setRecords((item) => {
      let record = [...Records];
      record[0].dimensions[index] = {
        ...record[0].dimensions[index],
      [Event.target.name]: Event.target.value,
      };
      return record;
      });
      }
      function HandleCreate(event) {
     
        for (let key in Records[0]) {
          if (key === 'Rev_no' || key === 'Two_k_part') {
            continue; 
          }
          // if (parseFloat(Records[0][key]) === 0) {
          //   console.log("Found key with value 0:", key);
          //   alert(`${key} value cannot be 0.`);
          //   return;
          // }
        }
        // for (let i = 0; i < Records[0].dimensions.length; i++) {
        //   const dimension = Records[0].dimensions[i];
        //   for (let dimensionKey in dimension) {
        //     if (parseFloat(dimension[dimensionKey]) === 0) {
        //       alert(`${dimensionKey} at index ${i+1} cannot be 0.`);
        //       return;
        //     }
        //   }
        //}
      if (
      !Records[0].Part_name ||
      !Records[0].Raw_material_name ||
      !Records[0].Approved_by ||
      !Records[0].Prepared_by ||
      !Records[0].Model ||
      !Records[0].Part_weight_1 ||
      !Records[0].Customer ||
      !Records[0].Image 
      || (Records[0].Two_k_part === '1' && Records[0].Rawmaterialname.length == 1)
      ) {
      alert("Please Fill the Mandatory Fields");
      setwarning(true);
      }   
      else {
      let record = [...Records];
      record && delete record[0].Partname;
      record && delete record[0].PartNumber;
      record && delete record[0].Rawmaterialname;
      record && delete record[0].Preparedby;
      record && delete record[0].Customername;
      record && delete record[0].Approvedby;
      record && delete record[0].download;
      record && delete record[0].Prodcutionoperatorname;
      record && delete record[0].Shift;
      record && delete record[0].Time;
      console.log("recordsdssds", record);
      CreateRecord("quality_product_plan", record).then((res) => {
      console.log("HandleCreate", res);
        // setMessage({
        //   NoteFrom: localStorage.getItem("empId"),
        //   NoteTo: Records[0].Approved_by,
        //   NoteMessage: `${localStorage.getItem("empId")}-${localStorage.getItem(
        //     "name"
        //   )} has submitted Product Quality Plan Form, Please do verify`,
        //   NoteRecordId: res.ROWID,
        //   TableName: "quality_plan",
        //   NoteCreatedBy: localStorage.getItem("empId"),
        // });
        // CreateApproval({
        //   RequestedBy: localStorage.getItem("empId"),
        //   Approver: Records[0].Approved_by,
        //   Level: 1,
        //   Status: "Waiting for Approval",
        //   TableName: "quality_plan",
        //   TableID: res.ROWID,
        //   Description:
        //     "The Product Quality Plan Form Has been Submitted By " +
        //     localStorage.getItem("empId") +
        //     "-" +
        //     localStorage.getItem("name"),
        //   CreatedBy: localStorage.getItem("empId"),

        // });
      });
      alert("Submited Successfully");
      setwarning(false);
      const img = document.getElementById("Image");
      console.log("img", img);
      if (img) {
        // alert("1",img.getAttribute("src"));
        // if(img.getAttribute("src")){
        img.setAttribute("src", "");
        img.value = "";
        // }
      } else {
        // alert("2",img.getAttribute("src"));
        console.error("Image element not found", img);
      }
      setRecords(InitRoot);
      setRaw([]);
      if (scrollTop.current) {
        scrollTop.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }
  function HandleUpdate(event) {
    let record = [...Records];

    record && delete record[0].Partname;
    record && delete record[0].PartNumber;
    record && delete record[0].Rawmaterialname;
    record && delete record[0].Preparedby;
    record && delete record[0].Customername;
    record && delete record[0].Approvedby;
    record && delete record[0].download;
    record && delete record[0].Prodcutionoperatorname;
    record && delete record[0].Shift;
    record && delete record[0].Time;

    UpdateRecord("quality_product_plan", record).then((res) => {
      console.log("HandleUpdate", res);
    });
    alert("record Updated!");
    setwarning(false);
    setRecords(InitRoot);
    navigate(-1);
  }
  useEffect(() => {
    // onDuplicateCreate();
    setwarning(false);
    document.title = "Product Quality Plan";
    const fetchData = async () => {
      if (id === undefined) {
        setRecords([
          {
            ...InitRoot[0],
            Quality_product_plan_dt: CURRENT_Date,
            Time: CURRENT_TIME,
            Shift: SHIFTMOLDINGQUALITY,
          },
        ]);
      } else {
        setLoading(true);
        try {
          const res = await ReadRecord({
            _keyword_: "quality_product_plan",
            Id: id,
          });

          if (res.data[0] === undefined) {
            setRecords(InitRoot);
          } else {
            console.log("res.data", res.data[0].Two_k_part == 0);
            // setRecords(res.data);
            if (res.data[0].Two_k_part == "1") {
              const fetchedData = res.data[0];
              console.log("JSON ", JSON.parse(fetchedData.Raw_material_name));
              setRaw(JSON.parse(fetchedData.Raw_material_name));
              setRecords([
                {
                  ...fetchedData,
                  dimensions: fetchedData.dimensions.map((dimension) => ({
                    ...dimension,
                    Specification_symbol_1:
                      dimension.Specification_symbol_1 || "-",
                    Specification_symbol_2:
                      dimension.Specification_symbol_2 || "+",
                  })),
                  Rawmaterialname: JSON.parse(fetchedData.Raw_material_name),
                },
              ]);
            } else {
              const fetchedData = res.data[0];
              setRecords([
                {
                  ...fetchedData,
                  dimensions: fetchedData.dimensions.map((dimension) => ({
                    ...dimension,
                    Specification_symbol_1:
                      dimension.Specification_symbol_1 || "-",
                    Specification_symbol_2:
                      dimension.Specification_symbol_2 || "+",
                  })),
                },
              ]);
            }
          }
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [id]);
  if (loading) {
    return <LoadingPage />;
  }
  const onDuplicateCreate = async (e) => {
    navigate("/quality_plan");

    // console.log("recotahsdfords", Records[0].Id);
    // setRecords(Records[0])

    // setRecords((prevRecords) => {
    //   let record = [...prevRecords];
    // record[0].Id = "",
    // record[0].ModifiedDate = "",
    // record[0].CreatedDate = "",
    // record[0].Model = "",
    // record[0].Image = "",
    // record[0].Part_number = "",
    //   record[0]["PartNumber"][0]["mc_part_code"] = "",
    // record[0]["PartNumber"][0]["mc_part_code"] = "",
    // record[0].Part_name = "",
    //   record[0]["Partname"][0]["mc_part_name"] = "",
    // record[0]["Partname"][0]["mc_part_name"] = "",
    // record[0].Raw_material_name = "",
    //   record[0].Customer = "",
    //   record[0].Quality_product_plan_dt = CURRENT_Date,
    //   record[0]["Rawmaterialname"][0]["Mh_raw_mat"] = "",
    // record[0]["Rawmaterialname"][0]["Mh_raw_mat_name"] = "",
    // record[0].Customername[0].Cus_code = "",
    // record[0].Customername[0].Cus_name = "",
    // record[0].Preparedby[0].Emp_code = "",
    // record[0].Preparedby[0].Emp_name = "",
    // record[0].Approvedby[0].Emp_code = "",
    // record[0].Approvedby[0].Emp_name = "",
    // record[0].Approved_by = "",
    // record[0].Prepared_by = "",
    //   record[0].dimensions = prevRecords[0].dimensions,
    //     record[0].Part_weight_1 = prevRecords[0].Part_weight_1,
    //     record[0].Part_weight_2 = prevRecords[0].Part_weight_2,
    //     record[0].Part_weight_set_minus_1 = prevRecords[0].Part_weight_set_minus_1,
    //     record[0].Part_weight_set_plus_1 = prevRecords[0].Part_weight_set_plus_1,
    //     record[0].Part_weight_set_minus_2 = prevRecords[0].Part_weight_set_minus_2,
    //     record[0].Part_weight_set_plus_2 = prevRecords[0].Part_weight_set_plus_2;
    //   return record;

    // })

    setLoading(true);
    try {
      const res = await ReadRecord({
        _keyword_: "quality_product_plan_duplicate",
        Id: e,
      });
      if (res.data[0] === undefined) {
        setRecords(InitRoot);
      } else {
        setRecords(() => {
          let record = [...Records];
          (record[0].Model = ""),
            (record[0].Image = ""),
            (record[0].Part_number = ""),
            (record[0]["PartNumber"][0]["mc_part_code"] = ""),
            (record[0]["PartNumber"][0]["mc_part_code"] = ""),
            (record[0].Part_name = ""),
            (record[0]["Partname"][0]["mc_part_name"] = ""),
            (record[0]["Partname"][0]["mc_part_name"] = ""),
            (record[0].Raw_material_name = ""),
            (record[0].Customer = ""),
            (record[0]["Rawmaterialname"][0]["Mh_raw_mat"] = ""),
            (record[0]["Rawmaterialname"][0]["Mh_raw_mat_name"] = ""),
            (record[0].Customername[0].Cus_code = ""),
            (record[0].Customername[0].Cus_name = ""),
            (record[0].Preparedby[0].Emp_code = ""),
            (record[0].Preparedby[0].Emp_name = ""),
            (record[0].Approvedby[0].Emp_code = ""),
            (record[0].Approvedby[0].Emp_name = ""),
            (record[0].Approved_by = ""),
            (record[0].Prepared_by = ""),
            (record[0].Two_k_part = "0"),
            record && delete record[0].ModifiedDate;
          record && delete record[0].CreatedDate;
          record && delete record[0].Id;
          (record[0].Quality_product_plan_dt = CURRENT_Date),
            (record[0].dimensions = res.data[0].dimensions),
            (record[0].Part_weight_1 = res.data[0].Part_weight_1),
            (record[0].Part_weight_2 = res.data[0].Part_weight_2),
            (record[0].Part_weight_set_minus_1 =
              res.data[0].Part_weight_set_minus_1),
            (record[0].Part_weight_set_plus_1 =
              res.data[0].Part_weight_set_plus_1),
            (record[0].Part_weight_set_minus_2 =
              res.data[0].Part_weight_set_minus_2),
            (record[0].Part_weight_set_plus_2 =
              res.data[0].Part_weight_set_plus_2),
            console.log("record65464", record);
          return record;
        });
      }
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };
  const generatePDF = () => {
    setDownloadDiv(true);
    setTimeout(() => {
      document.querySelector(".myTable").style.display = "table";
      setDownloadEnabled(false);

      const dropdown = document.querySelector(".OnlineDownload");
      if (dropdown) {
        dropdown.style.display = "none";
      }

      const pdf = new jsPDF("portrait", "mm", "a4");
      html2canvas(contentRef.current).then((canvas) => {
        if (dropdown) {
          dropdown.style.display = "block";
        }
        const imgData = canvas.toDataURL("image/png");
        pdf.addImage(imgData, "PNG", 5, 5, 200, 280);
        pdf.setTextColor(50, 50, 50);
        pdf.save(`ProductPlan.pdf`);
        setDownloadEnabled(true);
      });

      setTimeout(() => {
        document.querySelector(".myTable").style.display = "none";
      }, 1000);
    }, 0);
  };

  // const generatePDF11 = () => {
  //   document.querySelector(".myTable").style.display = "table";
  //   const opt = {
  //     margin: 0.5,
  //     filename: "ProductPlan.pdf",
  //     image: { type: "jpeg", quality: 0.98 },
  //     html2canvas: {
  //       scale: 3,
  //       height: contentRef.current.offsetHeight,
  //       width: contentRef.current.offsetWidth,
  //     },
  //     jsPDF: { unit: "in", format: "A4", orientation: "portrait" },
  //   };
  //   html2pdf().from(contentRef.current).set(opt).save();
  //   setTimeout(() => {
  //     document.querySelector(".myTable").style.display = "none";
  //   }, 1000);
  // };

  const data = Records
    ? Array.from({ length: 20 }, (_, index) => {
        const dimension = Records[0]?.dimensions[index];

        const {
          Specifications_loc,
          Specification_val,
          Specification_minus,
          Specification_plus,
        } = dimension;
        const formattedSpec = `${Specifications_loc}*${Specification_val}(-${Specification_minus}/+${Specification_plus})`;
        const { Checking_aids } = dimension;
        const column4 = Checking_aids;
        return {
          id: index + 1,
          column1: (index + 1).toString(),
          column2: index === 7 ? "Dimensions" : "",
          column3: formattedSpec,
          column4: column4,
          column5: index === 5 ? " N=3 SHOTS" : "",
          column6: index === 5 ? " Each cavity / Every Shift" : "",
          column7: index === 5 ? " N=3 SHOTS" : "",
          column8:
            index === 5
              ? " Each cavity 1 Nos Checked and entered in incoming inspection area"
              : "",
          column9:
            index === 5
              ? " Each cavity 5 Nos Checked and entered in final/PDI area"
              : "",
        };
      })
    : [];
  const Part1 = `${Records[0].Part_weight_1}(-${Records[0].Part_weight_set_minus_1}+${Records[0].Part_weight_set_plus_1}GMS)`;
  const Part2 = `${Records[0].Part_weight_2}(-${Records[0].Part_weight_set_minus_2}+${Records[0].Part_weight_set_plus_2}GMS)`;
  // const ExportExcelTable = ({ data }) => {
  const exportToExcel = () => {
    setDownloadDiv(true);
    setTimeout(() => {
      const wb = XLSX.utils.book_new();
      const wsData = [
        [
          "",
          "",
          "Product Quality Plan",
          "",
          "",
          "",
          "",
          "",
          "FORMAT NO: UPCL/QC/D/05",
        ],
        ["", "", "", "", "", "", "", "", "REV NO: 00"],
        ["", "", "", "", "", "", "", "", "REV DATE: 01-11-2012"],
        [
          "Part Number:",
          Records[0].Part_number,
          "",
          "",
          "Part Name:",
          Records[0].Part_name,
        ],
        [
          "Rawmaterial:",
          Records[0].Two_k_part == "1"
            ? Records[0].Rawmaterialname[0].label + ","+
            Records[0].Rawmaterialname[1].label:
            Records[0]?.Rawmaterialname[0]?.Mh_raw_mat_name,
          "",
          "",
          "Model:",
          Records[0].Model,
        ],
        [
          "Customer:",
          Records[0]?.Customername[0]?.Cus_name,
          "Date:",
          Records[0].CreatedDate,
        ],
        [
          "SI.No",
          "Parameters",
          "Specifications",
          "Checking Aids",
          "firstoff",
          "Online",
          "Last off",
          "Incoming",
          "Final/PDI",
        ],
      ];
      data.forEach((row) => {
        wsData.push([
          row.column1,
          row.column2,
          row.column3,
          row.column4,
          row.column5,
          row.column6,
          row.column7,
          row.column8,
          row.column9,
        ]);
      });

      wsData.push(
        [
          "PW-1",
          "PART WEIGHT",
          Part1,
          "Weight machine",
          "N=5 Shots",
          "Each cavity / every shift Hourly once",
          "N=3 Shots",
          "Each cavity 1 Nos Checked and entered in incoming inspection area</td><td>Each cavity 5 Nos Checked and entered in PDI area",
        ],
        [
          "PW-2",
          "PART WEIGHT",
          Part2,
          "Weight machine",
          "N=5 Shots",
          "Each cavity / every shift Hourly once",
          "N=3 Shots",
          "Each cavity 1 Nos Checked and entered in incoming inspection area</td><td>Each cavity 5 Nos Checked and entered in PDI area",
        ],

        ["", "", "", "", "", "", "", "", "", "", ""],
        [
          "V-1",
          "",
          "free from flash",
          "Should not Exist",
          "Visual",
          "",
          "",
          "",
          "",
        ],
        [
          "V-2",
          "",
          "Free from short mould",
          "Should not Exist",
          "Visual",
          "",
          "",
          "",
          "",
        ],
        [
          "V-3",
          "",
          "free from weld line",
          "Should not Exist",
          "Visual",
          "",
          "",
          "",
          "",
        ],
        [
          "V-4",
          "VISUAL",
          "free from sink",
          "Should not Exist",
          "Visual",
          "N=5 Shots",
          "N=1 shots frequency every one hours",
          "N=3 SHOTS",
          "As per sampling plan",
          "As per sampling plan",
        ],
        [
          "V-5",
          "",
          "No Color Variation",
          "As per master sample",
          "Visual Against Ref Sample",
          "",
          "",
          "",
          "",
        ],
        [
          "V-6",
          "",
          "Free from short mould,streaks,sink,impression,flash,colour variation,weld line,flowmark,damages",
          "Should not Exist",
          "Visual Against Ref Sample",
          "",
          "",
          "",
          "",
        ],
        [
          "P-1",
          "PACKING",
          "",
          "",
          "Proper arrangement",
          "Weighing machine / Manually",
          "",
          "N/A",
          "",
          "",
          "As per packing Std",
        ],
        [
          "Prepared By:",
          Records[0].Preparedby[0].Emp_name,
          "",
          "Approved By:",
          Records[0].Approvedby[0].Emp_name,
          "",
        ]
      );

      const ws = XLSX.utils.aoa_to_sheet(wsData);

      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      XLSX.writeFile(wb, "Product Quality.xlsx");
    }, 0);
  };
  const createdDate = new Date(Records[0].CreatedDate);
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  let formattedDate = createdDate.toLocaleDateString("en-GB", options);
  formattedDate = formattedDate.replace(/\//g, "-");
  return (
    <QaLayout record={Records} quary={"quality_product_plan"}>
      <div className="excel">
        {downloadEnabled && option === "view" && (
          <Dropdown style={{ width: "100%", al: "center" }}>
            <Dropdown.Toggle style={{ color: "black" }}>
              Download ⬇️
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>
                <button
                  className="custom-button"
                  style={{ color: "black" }}
                  onClick={generatePDF}
                >
                  PDF ⬇️
                </button>
              </Dropdown.Item>
              <Dropdown.Item>
                <button
                  className="custom-button"
                  style={{ color: "black" }}
                  onClick={() => exportToExcel()}
                >
                  Export to Excel
                </button>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
      <div className="Form" ref={scrollTop}>
        <div style={{ display: option === "view" ? "block" : "none" }}>
          <span style={{ marginLeft: "75%" }}>
            <a
              value={Records[0]?.Id}
              href="#"
              onClick={(e) => {
                onDuplicateCreate(Records[0]?.Id);
              }}
            >
              Click here
            </a>{" "}
            to duplicate the data
            </span>
            </div>
            <div ref={tableRef}>
            <table>
            <tbody>
              <tr>
                     {/* <th colSpan={Records[0].download ? 14 : 19}> */}
                     <th colSpan={13}>
                     <Lable value={"Product Quality Plan"} type={"Head"} />
                    
                     <input
                     style={{ width: "20px", height: "20px" }}
                     type="checkbox"
                     name="Two_k_part"
                     value={Records[0]}
                    checked={Records[0].Two_k_part == "1"}
                    disabled={option === "view" || option === "edit"}
                    onChange={(e) => {
                      HandleRecordsBoolean(e);
                    }}
                  />
                  Enable 2K Part
                </th>
                {/* {Records[0].download && ( */}
                <td colSpan={5}>
                  <tr>
                    <td style={{ width: "25%" }}>
                      <Field
                        label={"FORMAT NO"}
                        name={"Format_no"}
                        style={{ padding: "0px" }}
                        options={{
                          type: "text",
                          // defaultValue: "UPCL/QC/D/05",
                          disabled: option != "edit",
                        }}
                        value={Records[0]}
                        OnChange={(e) => {
                          HandleRecords(e);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "25%" }}>
                      <Field
                        label={"REV NO"}
                        name={"Rev_no"}
                        style={{ padding: "0px" }}
                        options={{
                          type: "text",
                          // defaultValue: "00",
                          disabled: option != "edit",
                        }}
                        value={Records[0]}
                        OnChange={(e) => {
                          HandleRecords(e);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "25%" }}>
                      <Field
                        label={"REV DATE"}
                        name={"Rev_ate"}
                        style={{ padding: "0px" }}
                        options={{
                          type: "text",
                          // defaultValue: "01-11-2012",
                          disabled: option != "edit",
                        }}
                        value={Records[0]}
                        OnChange={(e) => {
                          HandleRecords(e);
                        }}
                      />
                    </td>
                  </tr>
                </td>
                {/* )} */}
              </tr>
              <tr>
                <td colSpan={8}>
                  <Field
                    label={"Part Number"}
                    name={"Part_number"}
                    
                    options={{
                 
                      type: "reference",
                      reference: "Get_part_code_from_head",
                      required: true,
                      disabled: option === "view" || option === "edit",
                    }}
                    warning={warning}
                    value={{
                      value: Records[0].PartNumber[0]
                        ? Records[0].PartNumber[0].mc_part_code
                        : "",
                      label: Records[0].PartNumber[0]
                        ? Records[0].PartNumber[0].mc_part_code
                        : "",
                    }}
                    OnChange={(e) => {
                      HandlePartCode(e);
                    }}
                  />
                </td>
                <td colSpan={10}>
                  <Field
                    label={"Part Name"}
                    name={"Part_name"}
                    options={{
                      type: "reference",
                      key: Records[0].Part_number,
                      reference: "Get_part_name_from_head",
                     
                      required: true,
                      disabled: option === "view" || option === "edit",
                    }}
                    // doubleLine={true}
                    warning={warning}
                    value={{
                      value: Records[0].Partname[0]
                        ? Records[0].Partname[0].mc_part_name
                        : "",
                      label: Records[0].Partname[0]
                        ? Records[0].Partname[0].mc_part_name
                        : "",
                    }}
                    OnChange={(e) => {
                      HandlePartname(e);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={8}>
                  <Field
                    label={"Raw Material Name"}
                    name={"Raw_material_name"}
                    options={{
                      type: "NoneReference",
                      reference:
                        option === "edit" && Records[0].Two_k_part == "1"
                          ? "Keyvalue"
                          : "Raw_material",
                      values: Raw,
                      required: true,
                      disabled: option === "view" || option === "edit",
                    }}
                    isMulti={Records[0].Two_k_part == "1"}
                    warning={warning}
                    // value={{
                    //   value: Records[0].Rawmaterialname[0]
                    //     ? Records[0].Rawmaterialname[0].Mh_raw_mat
                    //     : "",
                    //   label: Records[0].Rawmaterialname[0]
                    //     ? Records[0].Rawmaterialname[0].Mh_raw_mat_name
                    //     : "",
                    // }}

                    value={
                      Records[0].Two_k_part == "1"
                        ? Records[0].Rawmaterialname
                        : {
                            value: Records[0].Rawmaterialname[0]
                              ? Records[0].Rawmaterialname[0].Mh_raw_mat
                              : "",
                            label: Records[0].Rawmaterialname[0]
                              ? Records[0].Rawmaterialname[0].Mh_raw_mat_name
                              : "",
                          }
                    }
                    // value={{{
                    //   value:Mh_raw_mat,
                    //   label:Mh_raw_mat
                    // }}}
                    OnChange={(e) => {
                      Records[0].Two_k_part == "1"
                        ? HandleRecordSelectRaw2(
                            e,
                            "Rawmaterialname",
                            "Mh_raw_mat",
                            "Mh_raw_mat_name"
                          )
                        : HandleRecordSelectRaw1(
                            e,
                            "Rawmaterialname",
                            "Mh_raw_mat",
                            "Mh_raw_mat_name"
                          );
                    }}
                  />
                </td>
                <td colSpan={10}>
                  <Field
                    label={"Model"}
                    name={"Model"}
                    options={{ type: "text", disabled: true, required: true }}
                    warning={warning}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
              </tr>

              <tr>
                <td colSpan={8}>
                  <Field
                    label={"Customer"}
                    name={"Customer"}
                    options={{
                      type: "reference",
                      reference: "customer_details_dropdown",
                      required: true,
                      disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records[0].Customername[0]
                        ? Records[0].Customername[0].Cus_code
                        : "",
                      label: Records[0].Customername[0]
                        ? Records[0].Customername[0].Cus_name
                        : "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "Customername",
                        "Cus_code",
                        "Cus_name"
                      );
                    }}
                  />
                </td>
                <td colSpan={10}>
                  <Field
                    label={"Date"}
                    name={"Quality_product_plan_dt"}
                    options={{ type: "date", disabled:true }}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
              </tr>

              <tr>
                <td colSpan={21}>
                  <Field
                    label={"Image"}
                    name={"Image"}
                    accept="image/*,application/pdf"
                    options={{
                      type: "image",
                      disabled: option === "view",
                      required: true,
                    }}
                    warning={warning}
                    value={Records[0]}
                    OnChange={(e) => {
                      HandleRecords(e);
                    }}
                  />
                </td>
              </tr>

              <tr>
                <th style={{ width: "5%" }} colSpan={2}>
                  <Lable value={"SI.No"} type={"Head"} />
                </th>
                <th style={{ width: "30%" }} colSpan={2}>
                  <Lable value={"Parameters"} type={"Head"} />
                </th>
                <th style={{ width: "20%" }} colSpan={5}>
                  <Lable value={"Specifications"} type={"Head"} />
                </th>
                <th style={{ width: "10%" }} colSpan={2}>
                  <Lable value={"Checking Aids"} type={"Head"} />
                </th>
                <th style={{ width: "5%" }} colSpan={1}>
                  <Lable value={"first off"} type={"Head"} />
                </th>
                <th style={{ width: "5%" }} colSpan={1}>
                  <Lable value={"Online"} type={"Head"} />
                </th>
                <th style={{ width: "5%" }} colSpan={1}>
                  <Lable value={"Last off"} type={"Head"} />
                </th>
                <th style={{ width: "10%" }} colSpan={2}>
                  <Lable value={"Incoming"} type={"Head"} />
                </th>
                <th style={{ width: "10%" }} colSpan={2}>
                  <Lable value={"Final/PDI"} type={"Head"} />
                </th>
              </tr>
              {rows.map((rowNumber) => (
                <tr key={rowNumber}>
                  <td style={{ width: "3%", textAlign: "center" }} colSpan={2}>
                    {rowNumber}
                  </td>

                  {rowNumber && rowNumber == 1 && (
                    <td colSpan={2} rowSpan={20}>
                      <Lable value={"Dimensions"} />
                    </td>
                  )}

                  <td colSpan={5}>
                    <Field
                      style={{ width: "20%", float: "left" }}
                      label={""}
                      name={"Specifications_loc"}
                      options={{ type: "text", disabled: option === "view" }}
                      value={Records[0].dimensions[rowNumber - 1]}
                      OnChange={(e) => {
                        const value = e.target.value;
                        if (InvalidCharacters(value)) {
                          e.preventDefault();
                          return;
                        }
                        HandleDimensions(e, [rowNumber - 1]);
                      }}
                    />
                    <span
                      style={{
                        float: "left",
                        marginTop: "10px",
                        fontSize: "20px",
                      }}
                    >
                      {" "}
                      *{" "}
                    </span>
                    <Field
                      style={{ width: "20%", float: "left" }}
                      label={""}
                      name={"Specification_val"}
                      options={{ type: "text", disabled: option === "view" }}
                      value={Records[0].dimensions[rowNumber - 1]}
                      OnChange={(e) => {
                        const value = e.target.value;
                        if (InvalidCharacters(value)) {
                          e.preventDefault();
                          return;
                        }
                        HandleDimensions(e, [rowNumber - 1]);
                      }}
                    />
                    <span style={{ float: "left", fontSize: "30px" }}>(</span>
                    {/* {addSubStates1[rowNumber - 1] ? (
                    <span onClick={() => { toggleAddSubState1(rowNumber); handleSymbolClick([rowNumber - 1], '-', "Specification_symbol_1") }}
                      style={{ float: "left", fontSize: "25px", cursor: "pointer" }}>
                      +
                    </span>
                  ) : (
                    <span onClick={() => { toggleAddSubState1(rowNumber); handleSymbolClick([rowNumber - 1], '+', "Specification_symbol_1") }}
                      style={{ float: "left", fontSize: "25px", cursor: "pointer" }}>
                      -
                    </span>
                  )} */}
                    <div>
                      <span
                        onClick={(e) => {
                          option !== "view" && toggleAddSubState1(rowNumber);
                          option !== "view" &&
                            handleSymbolClick(
                              [rowNumber - 1],
                              Records[0].dimensions[rowNumber - 1]
                                .Specification_symbol_1 === "-"
                                ? "+"
                                : "-",
                              "Specification_symbol_1"
                            );
                        }}
                        style={{
                          float: "left",
                          fontSize: "25px",
                          cursor: "pointer",
                        }}
                      >
                        {
                          Records[0].dimensions[rowNumber - 1]
                            .Specification_symbol_1
                        }
                      </span>
                    </div>

                    <Field
                      style={{ width: "20%", float: "left", width: "20%" }}
                      label={""}
                      name={"Specification_minus"}
                      options={{ type: "text", disabled: option === "view" }}
                      value={Records[0].dimensions[rowNumber - 1]}
                      OnChange={(e) => {
                        const value = e.target.value;
                        if (InvalidCharacters(value)) {
                          e.preventDefault();
                          return;
                        }
                        HandleDimensions(e, [rowNumber - 1]);
                      }}
                    />
                    {/* {addSubStates2[rowNumber - 1] ? (
                    <div>
                      <span style={{ float: "left", fontSize: "25px" }}>/</span><span onClick={(e) => { toggleAddSubState2(rowNumber); handleSymbolClick([rowNumber - 1], '+', "Specification_symbol_2") }} style={{ float: "left", fontSize: "25px", cursor: "pointer" }}>
                      {Records[0].dimensions[rowNumber - 1].Specification_symbol_2 === '+' ? '+' : '-'}
                      </span>
                    </div>
                  ) : (
                    <div>
                      <span style={{ float: "left", fontSize: "25px" }}>/</span>
                      <span onClick={(e) => { toggleAddSubState2(rowNumber); handleSymbolClick([rowNumber - 1], '-',"Specification_symbol_2") }}  style={{ float: "left", fontSize: "25px", cursor: "pointer" }}>
                      {Records[0].dimensions[rowNumber - 1].Specification_symbol_2 === '+' ? '+' : '-'}
                      </span>
                    </div>
                  )} */}
                    <div>
                      <span style={{ float: "left", fontSize: "25px" }}>/</span>
                      <span
                        onClick={(e) => {
                          option !== "view" && toggleAddSubState2(rowNumber);
                          option !== "view" &&
                            handleSymbolClick(
                              [rowNumber - 1],
                              Records[0].dimensions[rowNumber - 1]
                                .Specification_symbol_2 === "+"
                                ? "-"
                                : "+",
                              "Specification_symbol_2"
                            );
                        }}
                        style={{
                          float: "left",
                          fontSize: "25px",
                          cursor: "pointer",
                        }}
                      >
                        {
                          Records[0].dimensions[rowNumber - 1]
                            .Specification_symbol_2
                        }
                      </span>
                    </div>
                    <Field
                      style={{ width: "20%", float: "left" }}
                      label={""}
                      name={"Specification_plus"}
                      options={{
                        required: true,
                        type: "text",
                        disabled: option === "view",
                        
                      }}
                                                   
                      value={Records[0].dimensions[rowNumber - 1]}  
                      OnChange={(e) => {
                        const value = e.target.value;
                        if (InvalidCharacters(value)) {
                          e.preventDefault();
                          return;
                        }
                      HandleDimensions(e, [rowNumber - 1]);
                        console.log(Records[0].dimensions[rowNumber - 1]);
                      }}
                    />
                    <span style={{ float: "left", fontSize: "25px" }}>)</span>
                  </td>

                  <td colSpan={2}>
                    <Field
                      name={"Checking_aids"}
                      options={{
                        type: "dropdown",
                        disabled: option === "view",
                        values: [
                          { value: "Vernier", label: "Vernier" },
                          { value: "Vms", label: "Vms" },
                          { value: "PinGauge", label: "PinGauge" },
                        ],
                      }}
                      value={Records[0].dimensions[rowNumber - 1]}
                      OnChange={(e) => {
                        HandleDimensions(e, [rowNumber - 1]);
                      }}
                    />
                  </td>
                  {rowNumber && rowNumber == 1 && (
                    <>
                      <td colSpan={1} rowSpan={20}>
                        <Lable
                          style={{
                            writingMode: "vertical-lr",
                            transform: "rotate(0deg)",
                          }}
                          value={"N=3 SHOTS"}
                        />
                      </td>

                      <td colSpan={1} rowSpan={20}>
                        <Lable
                          style={{
                            writingMode: "vertical-lr",
                            transform: "rotate(0deg)",
                          }}
                          value={"Each cavity / Every Shift"}
                        />
                      </td>
                      <td colSpan={1} rowSpan={20}>
                        <Lable
                          style={{
                            writingMode: "vertical-lr",
                            transform: "rotate(0deg)",
                          }}
                          value={"N=3 SHOTS"}
                        />
                      </td>
                      <td colSpan={2} rowSpan={20}>
                        <Lable
                          style={{
                            writingMode: "vertical-lr",
                            transform: "rotate(0deg)",
                          }}
                          value={
                            "Each cavity 1 Nos Checked and entered in incoming inspection area"
                          }
                        />
                      </td>
                      <td colSpan={2} rowSpan={20}>
                        <Lable
                          style={{
                            writingMode: "vertical-lr",
                            transform: "rotate(0deg)",
                          }}
                          value={
                            "Each cavity 5 Nos Checked and entered in final/PDI area"
                          }
                        />
                      </td>
                    </>
                  )}
                </tr>
              ))}

              <tr>
                <td colSpan={2}>
                  <Lable value={"PW-1"} />
                </td>
                <td colSpan={2}>
                  <Lable value={`PART WEIGHT`} required={true} />
                </td>
                <td colSpan={2}>
                  <Field
                    style={{ width: "25%", float: "left" }}
                    label={""}
                    name={"Part_weight_1"}
                    options={{
                      type: "text",
                      disabled: option === "view",
                      required: true,
                    
                    }}
                    warning={warning}
                    value={Records[0]}
                    OnChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }
                      HandleRecords(e);
                    }}
                  />
                  <span
                    style={{
                      float: "left",
                      marginTop: "0px",
                      fontSize: "30px",
                    }}
                  >
                    (
                  </span>
                  <span
                    style={{
                      float: "left",
                      marginTop: "5px",
                      fontSize: "25px",
                    }}
                  >
                    {" "}
                    -{" "}
                  </span>
                  <Field
                    style={{ width: "20%", float: "left", padding: "10px 1px" }}
                    label={""}
                    name={"Part_weight_set_minus_1"}
                    options={{ type: "text",required: true, disabled: option === "view" }}
                    warning={warning}
                    value={Records[0]}
                    OnChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }
                      HandleRecords(e);
                    }}
                  />
                  <span
                    style={{
                      float: "left",
                      marginTop: "8px",
                      fontSize: "20px",
                    }}
                  >
                    {" "}
                    +{" "}
                  </span>
                  <Field
                    style={{ width: "20%", float: "left", padding: "10px 1px" }}
                    label={""}
                    name={"Part_weight_set_plus_1"} //+
                    options={{ type: "text",required: true, disabled: option === "view" ,
                  }}
                    warning={warning}
                    value={Records[0]}
                    OnChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }
                      HandleRecords(e);
                    }}
                  />
                  <span
                    style={{
                      float: "left",
                      marginTop: "15px",
                      fontSize: "12px",
                    }}
                  >
                    GMS
                  </span>
                  <span
                    style={{
                      float: "left",
                      marginTop: "0px",
                      fontSize: "30px",
                    }}
                  >
                    )
                  </span>
                </td>
                <td colSpan={3}>
                  <Lable value={"Weight machine"} />
                </td>
                <td colSpan={1}>
                  <Lable
                    style={{
                      writingMode: "vertical-lr",
                      transform: "rotate(0deg)",
                    }}
                    value={"N=5 Shots"}
                  />
                </td>
                <td colSpan={2}>
                  <Lable value={"Each cavity / every shift Hourly once"} />
                </td>
                <td colSpan={1}>
                  <Lable
                    style={{
                      writingMode: "vertical-lr",
                      transform: "rotate(0deg)",
                    }}
                    value={"N=3 Shots"}
                  />
                </td>
                <td colSpan={2}>
                  <Lable
                    value={
                      "Each cavity 1 Nos Checked and entered in incoming inspection area"
                    }
                  />
                </td>
                <td colSpan={3}>
                  <Lable
                    value={"Each cavity 5 Nos Checked and entered in PDI area"}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <Lable value={"PW-2"} />
                </td>
                <td colSpan={2}>
                  <Lable value={"PART WEIGHT"} />
                </td>
                <td colSpan={2}>
                  <Field
                    style={{ width: "25%", float: "left" }}
                    label={""}
                    name={"Part_weight_2"}
                    options={{
                      type: "text",
                      disabled: option === "view",
                      // required: true,
                    }}
                    // warning={warning}
                    value={Records[0]}
                    OnChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }
                      HandleRecords(e);
                    }}
                  />
                  <span
                    style={{
                      float: "left",
                      marginTop: "0px",
                      fontSize: "30px",
                    }}
                  >
                    (
                  </span>
                  <span
                    style={{
                      float: "left",
                      marginTop: "5px",
                      fontSize: "25px",
                    }}
                  >
                    {" "}
                    -{" "}
                  </span>
                  <Field
                    style={{ width: "20%", float: "left", padding: "10px 1px" }}
                    label={""}
                    name={"Part_weight_set_minus_2"} //-
                    options={{ type: "text",
                    // required: true, 
                    disabled: option === "view" }}
                    // warning={warning}
                    value={Records[0]} 
                    OnChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }
                      HandleRecords(e);
                    }}     
                  
                  />
                  <span
                    style={{
                      float: "left",
                      marginTop: "8px",
                      fontSize: "20px",
                    }}
                  >
                    {" "}
                    +{" "}
                  </span>
                  <Field
                    style={{ width: "20%", float: "left", padding: "10px 1px" }}
                    label={""}
                    name={"Part_weight_set_plus_2"} //+
                    options={{ type: "text",
                    // required: true,
                     disabled: option === "view" }}

                    // warning={warning}
                    value={Records[0]}
                    OnChange={(e) => {
                      const value = e.target.value;
                      if (InvalidCharacters(value)) {
                        e.preventDefault();
                        return;
                      }
                      HandleRecords(e);
                    }}
                  />
                  <span
                    style={{
                      float: "left",
                      marginTop: "15px",
                      fontSize: "12px",
                    }}
                  >
                    GMS
                  </span>
                  <span
                    style={{
                      float: "left",
                      marginTop: "0px",
                      fontSize: "30px",
                    }}
                  >
                    )
                  </span>
                </td>
                <td colSpan={3}>
                  <Lable value={"Weight machine"} />
                </td>
                <td colSpan={1}>
                  <Lable
                    style={{
                      writingMode: "vertical-lr",
                      transform: "rotate(0deg)",
                    }}
                    value={"N=5 Shots"}
                  />
                </td>
                <td colSpan={2}>
                  <Lable value={"Each cavity / every shift Hourly once"} />
                </td>
                <td colSpan={1}>
                  <Lable
                    style={{
                      writingMode: "vertical-lr",
                      transform: "rotate(0deg)",
                    }}
                    value={"N=3 Shots"}
                  />
                </td>
                <td colSpan={2}>
                  <Lable
                    value={
                      "Each cavity 1 Nos Checked and entered in incoming inspection area"
                    }
                  />
                </td>
                <td colSpan={3}>
                  <Lable
                    value={"Each cavity 5 Nos Checked and entered in PDI area"}
                  />
                </td>
              </tr>

              <tr>
                <td colSpan={2}>
                  <Lable value={"V-1"} />
                </td>
                <td colSpan={1} rowSpan={6}>
                  <Lable
                    style={{
                      writingMode: "vertical-lr",
                      transform: "rotate(0deg)",
                    }}
                    value={"VISUAL"}
                  />
                </td>
                <td colSpan={3}>
                  <Lable value={"free from flash"} />
                </td>
                <td colSpan={3}>
                  <Lable value={"Should not Exist"} />
                </td>
                <td colSpan={3}>
                  <Lable value={"Visual"} />
                </td>
                <td colSpan={1} rowSpan={6}>
                  <Lable
                    style={{
                      writingMode: "vertical-lr",
                      transform: "rotate(0deg)",
                    }}
                    value={"N=5 Shots"}
                  />
                </td>
                <td colSpan={1} rowSpan={6}>
                  <Lable
                    style={{
                      writingMode: "vertical-lr",
                      transform: "rotate(0deg)",
                    }}
                    value={"N=1 shots frequency every one hours"}
                  />
                </td>
                <td colSpan={1} rowSpan={6}>
                  <Lable
                    style={{
                      writingMode: "vertical-lr",
                      transform: "rotate(0deg)",
                    }}
                    value={"N=5 Shots"}
                  />
                </td>
                <td colSpan={2} rowSpan={6}>
                  <Lable
                    style={{
                      writingMode: "vertical-lr",
                      transform: "rotate(0deg)",
                    }}
                    value={"As per sampling plan"}
                  />
                </td>
                <td colSpan={2} rowSpan={6}>
                  <Lable
                    style={{
                      writingMode: "vertical-lr",
                      transform: "rotate(0deg)",
                    }}
                    value={"As per sampling plan"}
                  />
                </td>
              </tr>

              <tr>
                <td colSpan={2}>
                  <Lable value={"V-2"} />
                </td>
                <td colSpan={3}>
                  <Lable value={"Free from short mould"} />
                </td>
                <td colSpan={3}>
                  <Lable value={"Should not Exist"} />
                </td>
                <td colSpan={3}>
                  <Lable value={"Visual"} />
                </td>
              </tr>

              <tr>
                <td colSpan={2}>
                  <Lable value={"V-3"} />
                </td>
                <td colSpan={3}>
                  <Lable value={"free from weld line"} />
                </td>
                <td colSpan={3}>
                  <Lable value={"Should not Exist"} />
                </td>
                <td colSpan={3}>
                  <Lable value={"Visual"} />
                </td>
              </tr>

              <tr>
                <td colSpan={2}>
                  <Lable value={"V-4"} />
                </td>
                <td colSpan={3}>
                  <Lable value={"free from sink"} />
                </td>
                <td colSpan={3}>
                  <Lable value={"Should not Exist"} />
                </td>
                <td colSpan={3}>
                  <Lable value={"Visual"} />
                </td>
              </tr>

              <tr>
                <td colSpan={2}>
                  <Lable value={"V-5"} />
                </td>
                <td colSpan={3}>
                  <Lable value={"No Color Variation"} />
                </td>
                <td colSpan={3}>
                  <Lable value={"As per master sample"} />
                </td>
                <td colSpan={3}>
                  <Lable value={"Visual Against Ref Sample"} />
                </td>
              </tr>

              <tr>
                <td colSpan={2}>
                  <Lable value={"V-6"} />
                </td>
                <td colSpan={3}>
                  <Lable
                    value={
                      "Free from short mould,streaks,sink,impression,flash,colour variation,weld line,flowmark,damages"
                    }
                  />
                </td>
                <td colSpan={3}>
                  <Lable value={"Should not Exist"} />
                </td>
                <td colSpan={3}>
                  <Lable value={"Visual Against Ref Sample"} />
                </td>
              </tr>

              <tr>
                <td colSpan={2}>
                  <Lable value={"P-1"} />
                </td>
                <td colSpan={4}>
                  <Lable value={"PACKING"} />
                </td>
                <td colSpan={3}>
                  <Lable value="Proper arrangement" />
                </td>
                <td colSpan={3}>
                  <Lable value="Weighing machine / Manually" />
                </td>
                <td colSpan={5}>
                  <Lable value={"N/A"} />
                </td>
                <td colSpan={2}>
                  <Lable value={"As per packing Std"} />
                </td>
              </tr>
              <tr>
                <td colSpan={9}>
                  {" "}
                  <Field
                    label={"Prepared By"}
                    name={"Prepared_by"}
                    doubleLine={true}
                    options={{
                      type: "reference",
                      reference: "emp_details_dropdown",
                      required: true,
                      disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records[0].Preparedby[0]
                        ? Records[0].Preparedby[0].Emp_code
                        : "",
                      label: Records[0].Preparedby[0]
                        ? Records[0].Preparedby[0].Emp_name
                        : "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "Preparedby",
                        "Emp_code",
                        "Emp_name"
                      );
                    }}
                  />
                </td>
                <td colSpan={10}>
                  {" "}
                  <Field
                    label={"Approved By"}
                    name={"Approved_by"}
                    doubleLine={true}
                    options={{
                      type: "reference",
                      reference: "emp_details_dropdown",
                      required: true,
                      disabled: option === "view",
                    }}
                    warning={warning}
                    value={{
                      value: Records[0].Approvedby[0]
                        ? Records[0].Approvedby[0].Emp_code
                        : "",
                      label: Records[0].Approvedby[0]
                        ? Records[0].Approvedby[0].Emp_name
                        : "",
                    }}
                    OnChange={(e) => {
                      HandleRecordSelect(
                        e,
                        "Approvedby",
                        "Emp_code",
                        "Emp_name"
                      );
                    }}
                  />
                </td>
              </tr>
              {!(option === "view") && (
                <tr>
                  {id === undefined ? (
                    <td colSpan={19}>
                      {" "}
                      <Field
                        label={"Submit"}
                        options={{ type: "Button" }}
                        onClick={(e) => {
                          HandleCreate(e);
                        }}
                      />
                    </td>
                  ) : (
                    <td colSpan={19}>
                      {" "}
                      <Field
                        label={"Update"}
                        options={{ type: "Button" }}
                        onClick={(e) => {
                          HandleUpdate(e);
                        }}
                      />
                    </td>
                  )}
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* ExcelTable Design*/}

      <div ref={contentRef} id="content-to-pdf">
        <style>
          {`table {
  border-collapse: collapse;
  border: 1px solid;
  width:100%;
  margin: 0 auto;
}
.myTable {
  width:100%;
  display:none;
}

.vertical-text{
  border: none;
}
th, td ,tr{
  border: 1px solid;
 
}
tr.no-border td {
  border: none;

}`}
        </style>
        {DownloadDiv && (
          <table className="myTable">
            <tr>
              <td style={{ width: "5%" }}>
                <img
                  style={{ width: "100%", whiteSpace: "nowrap" }}
                  src={Logo}
                  alt="Logo"
                />
              </td>
              <td colspan="17">
                <div>
                  <label
                    style={{
                      textAlignL: "center",
                      whiteSpace: "nowrap",
                      padding: "10px 0px",
                    }}
                  >
                    Product Quality Plan
                  </label>
                </div>
              </td>
              <td colspan="4">
                FORMAT NO: UPCL/QC/D/05
                <br />
                REV NO: 00
                <br />
                REV DATE: 01-11-2012
              </td>
            </tr>
            <tr>
              <td colspan="11">Part Number : {Records[0].Part_number}</td>
              <td colspan="11">Part Name : {Records[0].Part_name}</td>
            </tr>
            <tr>
              <td colspan="11" width="30%">
                Raw Material Name :{" "}
                {Records[0].Two_k_part == "1"
                  ? `${JSON.parse(Records[0].Raw_material_name)[0].label},
                ${JSON.parse(Records[0].Raw_material_name)[1].label}`
                  :Records[0]?.Rawmaterialname[0]?.Mh_raw_mat_name }
              </td>
              <td colspan="11" width="30%">
                Model : {Records[0].Model}
              </td>
            </tr>
            <tr>
              <td colSpan="11" width="30%">
                Customer : {Records[0].Customername[0].Cus_name}
              </td>
              <td colspan="11" width="30%">
                Date : {formattedDate}
              </td>
            </tr>
            {Records[0].Image.startsWith("data:image/png") && (
              <tr>
                <td colSpan={22}>
                  <img
                    style={{ width: "40%", marginLeft: "20%", padding: "3px" }}
                    src={Records[0].Image}
                    alt="Img"
                  />
                </td>
              </tr>
            )}

            <tr>
              <td colspan="1">
                <div>
                  <label>SI.No</label>
                </div>
              </td>
              <td colSpan="4">
                <div>
                  <label>Parameters</label>
                </div>
              </td>
              <td colSpan="3">
                <div>
                  <label>Specifications</label>
                </div>
              </td>
              <td colSpan="2">
                <div>
                  <label>Checking Aids</label>
                </div>
              </td>
              <td colSpan="2">
                <div>
                  <label>first off</label>
                </div>
              </td>
              <td colSpan="2">
                <div>
                  <label>Online</label>
                </div>
              </td>
              <td colSpan="2">
                <div>
                  <label>Last off</label>
                </div>
              </td>
              <td colSpan="3">
                <div>
                  <label>Incoming</label>
                </div>
              </td>
              <td colSpan="3">
                <div>
                  <label>Final/PDI</label>
                </div>
              </td>
            </tr>
            <tr>
              <td />
              <td colSpan={4} rowSpan={21} style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1,
                    background: "white",
                  }}
                ></div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                    zIndex: 2,
                  }}
                >
                  Dimensions
                </div>
              </td>
              <td colspan="5" />

              <td colSpan={2} rowSpan={21} style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1,
                    background: "white",
                  }}
                ></div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                    zIndex: 2,
                  }}
                >
                  N=3
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                    marginTop: "10%",
                    zIndex: 2,
                  }}
                >
                  SHOTS
                </div>
              </td>

              <td colSpan={2} rowSpan={21} style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1,
                    background: "white",
                  }}
                ></div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "50%",
                    marginTop: "10%",
                    padding: "2px",
                    zIndex: 2,
                  }}
                >
                  EachCavity
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "50%",
                    marginTop: "50%",
                    padding: "2px",
                    zIndex: 2,
                  }}
                >
                  /EveryShift
                </div>
              </td>
              <td colSpan={2} rowSpan={21} style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1,
                    background: "white",
                  }}
                ></div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                    zIndex: 2,
                  }}
                >
                  N=3
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                    marginTop: "10%",
                    zIndex: 2,
                  }}
                >
                  SHOTS
                </div>
              </td>
              <td colSpan={3} rowSpan={21} style={{ position: "relative" }}>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "50%",
                    marginTop: "16%",
                    padding: "1px",
                    zIndex: 2,
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1,
                    background: "white",
                  }}
                ></div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "50%",
                    marginTop: "1%",
                    padding: "1px",
                    zIndex: 2,
                  }}
                >
                  EachCavity
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "50%",
                    marginTop: "21%",
                    padding: "1px",
                    zIndex: 2,
                  }}
                >
                  {" "}
                  1
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "50%",
                    padding: "1px",
                    marginTop: "1%",
                    zIndex: 2,
                  }}
                >
                  Nos
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "50%",
                    padding: "1px",
                    marginTop: "5%",
                    zIndex: 2,
                  }}
                >
                  Checked
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "50%",
                    marginTop: "17%",
                    padding: "1px",
                    zIndex: 2,
                  }}
                >
                  and
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "50%",
                    marginTop: "5%",
                    padding: "1px",
                    zIndex: 2,
                  }}
                >
                  entered
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "50%",
                    marginTop: "8%",
                    padding: "1px",
                    zIndex: 2,
                  }}
                >
                  in
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "50%",
                    marginTop: "1%",
                    padding: "1px",
                    zIndex: 2,
                  }}
                >
                  incoming
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "50%",
                    marginTop: "19%",
                    padding: "1px",
                    zIndex: 2,
                  }}
                >
                  inspection
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "50%",
                    marginTop: "16%",
                    padding: "1px",
                    zIndex: 2,
                  }}
                >
                  area
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "50%",
                    marginTop: "16%",
                    padding: "1px",
                    zIndex: 2,
                  }}
                ></div>
              </td>
              <td colSpan={3} rowSpan={21} style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1,
                    background: "white",
                  }}
                ></div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "50%",
                    marginTop: "1%",
                    padding: "1px",
                    zIndex: 2,
                  }}
                >
                  EachCavity
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "50%",
                    marginTop: "35%",
                    padding: "1px",
                    zIndex: 2,
                  }}
                >
                  {" "}
                  5
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "50%",
                    padding: "1px",
                    marginTop: "1%",
                    zIndex: 2,
                  }}
                >
                  Nos
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "50%",
                    padding: "1px",
                    marginTop: "5%",
                    zIndex: 2,
                  }}
                >
                  Checked
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "50%",
                    marginTop: "25%",
                    padding: "1px",
                    zIndex: 2,
                  }}
                >
                  and
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "50%",
                    marginTop: "5%",
                    padding: "1px",
                    zIndex: 2,
                  }}
                >
                  entered
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "50%",
                    marginTop: "8%",
                    padding: "1px",
                    zIndex: 2,
                  }}
                >
                  in
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "50%",
                    marginTop: "1%",
                    padding: "1px",
                    zIndex: 2,
                  }}
                >
                  final/PDI
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "50%",
                    marginTop: "33%",
                    padding: "1px",
                    zIndex: 2,
                  }}
                >
                  area
                </div>
              </td>
            </tr>
            {Records[0].dimensions.map((dimension, index) => (
              <tr key={index}>
                <td colSpan="1" style={{ textAlign: "center" }}>
                  {index + 1}
                </td>

                <td colSpan="3" style={{ textAlign: "center" }}>
                  {`${dimension.Specifications_loc}*${dimension.Specification_val}(-${dimension.Specification_minus}/+${dimension.Specification_plus})`}
                </td>
                <td colSpan="2" style={{ textAlign: "center" }}>
                  {dimension.Checking_aids}
                </td>
              </tr>
            ))}
            <tr style={{ height: "100px" }}>
              <td colSpan="1" style={{ textAlign: "center" }}>
                PW-1
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                PART WEIGHT
              </td>
              <td colSpan="2" style={{ textAlign: "center" }}>
                {Part1}
              </td>
              <td style={{ textAlign: "center" }}>Weight machine</td>
              <td colSpan={2}>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                  }}
                >
                  N=5{" "}
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                    marginTop: "10%",
                  }}
                >
                  {" "}
                  SHOTS
                </div>
              </td>
              <td colSpan="3">Each cavity / every shift Hourly once</td>
              <td colSpan={2}>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                  }}
                >
                  N=3{" "}
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                    marginTop: "10%",
                  }}
                >
                  {" "}
                  SHOTS
                </div>
              </td>
              <td colSpan="2">
                Each cavity 1 Nos Checked and entered in incoming inspection
                area
              </td>
              <td colSpan="4">
                Each cavity 5 Nos Checked and entered in PDI area
              </td>
            </tr>
            <tr style={{ height: "100px" }}>
              <td colSpan="1" style={{ textAlign: "center" }}>
                PW-2
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                PART WEIGHT
              </td>
              <td colSpan="2" style={{ textAlign: "center" }}>
                {Part2}
              </td>
              <td style={{ textAlign: "center" }}>Weight machine</td>
              <td colSpan={2}>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                  }}
                >
                  N=5{" "}
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                    marginTop: "10%",
                  }}
                >
                  {" "}
                  SHOTS
                </div>
              </td>
              <td colSpan="3">Each cavity / every shift Hourly once</td>
              <td colSpan={2}>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                  }}
                >
                  N=3{" "}
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                    marginTop: "10%",
                  }}
                >
                  {" "}
                  SHOTS
                </div>
              </td>
              <td colSpan="2">
                Each cavity 1 Nos Checked and entered in incoming inspection
                area
              </td>
              <td colSpan="4">
                Each cavity 5 Nos Checked and entered in PDI area
              </td>
            </tr>
            <tr style={{ height: "80px" }}>
              <td colSpan="1" style={{ textAlign: "center" }}>
                V-1
              </td>
              <td colSpan={5} rowSpan={6} style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1,
                    background: "white",
                  }}
                ></div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                    zIndex: 2,
                  }}
                >
                  VISUAL
                </div>
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                free from flash
              </td>
              <td style={{ textAlign: "center" }}>Should not Exist</td>
              <td colSpan="3" style={{ textAlign: "center" }}>
                Visual
              </td>
              <td colSpan={2} rowSpan={6} style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1,
                    background: "white",
                  }}
                ></div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                    zIndex: 2,
                  }}
                >
                  N=5
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                    marginTop: "10%",
                    zIndex: 2,
                  }}
                >
                  SHOTS
                </div>
              </td>
              <td colSpan={1} rowSpan={6} style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1,
                    background: "white",
                  }}
                ></div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                    zIndex: 2,
                  }}
                >
                  N=1
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                    marginTop: "10%",
                    zIndex: 2,
                  }}
                >
                  SHOTS
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                    marginTop: "18%",
                    zIndex: 2,
                  }}
                >
                  frequency
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                    marginTop: "35%",
                    zIndex: 2,
                  }}
                >
                  every
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                    marginTop: "20%",
                    zIndex: 2,
                  }}
                >
                  one hours
                </div>
              </td>
              <td colSpan={1} rowSpan={6} style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1,
                    background: "white",
                  }}
                ></div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                    zIndex: 2,
                  }}
                >
                  N=5
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                    marginTop: "10%",
                    zIndex: 2,
                  }}
                >
                  SHOTS
                </div>
              </td>
              <td colSpan={2} rowSpan={6} style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1,
                    background: "white",
                  }}
                ></div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                    zIndex: 2,
                  }}
                >
                  As
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                    marginTop: "10%",
                    zIndex: 2,
                  }}
                >
                  per
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                    marginTop: "10%",
                    zIndex: 2,
                  }}
                >
                  sampling
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                    marginTop: "55%",
                    zIndex: 2,
                  }}
                >
                  plan
                </div>
              </td>
              <td colSpan={2} rowSpan={6} style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1,
                    background: "white",
                  }}
                ></div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                    zIndex: 2,
                  }}
                >
                  As
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                    marginTop: "10%",
                    zIndex: 2,
                  }}
                >
                  per
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                    marginTop: "10%",
                    zIndex: 2,
                  }}
                >
                  sampling
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    transformOrigin: "left top",
                    marginLeft: "45%",
                    marginTop: "55%",
                    zIndex: 2,
                  }}
                >
                  plan
                </div>
              </td>
            </tr>

            <tr style={{ height: "80px" }}>
              <td colSpan="1" style={{ textAlign: "center" }}>
                V-2
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                Free from short mould
              </td>
              <td style={{ textAlign: "center" }}>Should not Exist</td>
              <td colSpan="3" style={{ textAlign: "center" }}>
                Visual
              </td>
            </tr>
            <tr style={{ height: "80px" }}>
              <td colSpan="1" style={{ textAlign: "center" }}>
                V-3
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                free from weld line
              </td>
              <td style={{ textAlign: "center" }}>Should not Exist</td>
              <td colSpan="3" style={{ textAlign: "center" }}>
                Visual
              </td>
            </tr>
            <tr style={{ height: "80px" }}>
              <td colSpan="1" style={{ textAlign: "center" }}>
                V-4
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                free from sink
              </td>
              <td style={{ textAlign: "center" }}>Should not Exist</td>
              <td colSpan="3" style={{ textAlign: "center" }}>
                Visual
              </td>
            </tr>
            <tr style={{ height: "80px" }}>
              <td colSpan="1" style={{ textAlign: "center" }}>
                V-5
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                No Color Variation
              </td>
              <td style={{ textAlign: "center" }}>As per master sample</td>
              <td colSpan="3" style={{ textAlign: "center" }}>
                Visual Against <br />
                <span>Ref Sample</span>
              </td>
            </tr>
            <tr style={{ height: "80px" }}>
              <td colSpan="1" style={{ textAlign: "center" }}>
                V-6
              </td>
              <td colSpan="4" style={{ textAlign: "center" }}>
                Free from short mould,streaks,sink,impression,flash,colour
                variation,weld line,flowmark,damages
              </td>
              <td style={{ textAlign: "center" }}>Should not Exist</td>
              <td colSpan="3" style={{ textAlign: "center" }}>
                Visual Against <br />
                <span>Ref Sample</span>
              </td>
            </tr>
            <tr>
              <td colSpan={1} style={{ textAlign: "center" }}>
                P-1
              </td>
              <td colSpan={8} style={{ textAlign: "center" }}>
                PACKING
              </td>
              <td colSpan={2} style={{ textAlign: "center" }}>
                Proper arrangement
              </td>
              <td colSpan={5}>Weighing machine / Manually</td>
              <td colSpan={4}>N/A</td>
              <td colSpan={5}>As per packing Std</td>
            </tr>
            <tr>
              <td colspan="12" width="10">
                Prepared By: {Records[0].Preparedby[0].Emp_name}
              </td>
              <td colspan="12" width="20">
                Approved By: {Records[0].Approvedby[0].Emp_name}
              </td>
            </tr>
          </table>
        )}
      </div>
    </QaLayout>
  );
};
export default ProductQualityPlanForm;
