import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../../Styles/MaterialLoading.css";
import "../../../Styles/List.css";
import DatePicker from "../../../Lib/DatePicker.js";
import DropdownFetcher from "../../../Lib/DropdownFetcher.js";
import DropdownFilterSelect from "../../../Components/DropdownFilterSelectFetcher.js";
import Field from "../../../Components/Fields/Field";
import LoadingPage from "../../../Components/Handler/LoadingPage";
import DeleteOriginalRecord from "../../../Lib/API/DeleteOriginalRecord";
import CreateRecord from "../../../Lib/API/CreateRecord";
import Select from "react-select";
import {
  SECRET_KEY,
  PROCESS_TRANSACTION_URL, PROCESS_TRANSACTION_UPDATE_URL
} from "../../../Utils/constants.js";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import * as GeneralConstants from "../../../Utils/GeneralConstants.js";
import ReadRecord from "../../../Lib/API/ReadRecord.js";
import QaLayout from "../../Quality/QaLayout.js";
const initialState = {
  TrackDate: new Date().toISOString().split("T")[0],
  Machine_No: "",
  PartName: "",
  Rmrks: "",
  shot_1: "",
  shot_2: "",
  shot_3: "",
  shot_4: "",
  shot_5: "",
  shot_6: "",
  shot_7: "",
  shot_8: "",
  shot_9: "",
  shot_10: "",
  shot_11: "",
  Shots: [],
  total1:"",
  total2:"",
  sign: "",
  verified: "",
  approved: "",
  sign_name: "",
  verified_name: "",
  approved_name: "",
  approved1: "Mr.RAJKUMAR",
  approved2: "Mr.RAJKUMAR",
  Id: "",
  startUp: [],
};

function MachineStartupCreate() {
  const [Create, setCreate] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [View, setView] = useState(false);
  const [partDropdown, setPartDropdown] = useState([]);
  const [dropdown, setDropdown] = useState([]);
  const [dropdown1, setDropdown1] = useState([]);
  const handleDateChange = (newDate) => {
    setCreate({ ...Create, TrackDate: newDate });
  };

  const scrollTop = useRef(null);
  const [isCreating, setIsCreating] = useState(false);
  const [lastShotNumber, setLastShotNumber] = useState(0);
  const { option, id } = useParams();
  useEffect(()=>{
    console.log("Create",Create)
  },[Create]
)
  useEffect(() => {
    
    console.log(loading)
    document.title = "daily machine startup";
    const fetchData = async () => {
      if (id === undefined) {
        setCreate({ ...initialState });
      } else {   
        setLoading(true)
        try {
          const res = await ReadRecord({
            _keyword_: "daily_machine_startup",
            Id: id,
          }); 
          console.log("Response:", res.data);
          if (res.data[0] === undefined) {
            setCreate(initialState);
          } else {
        
            setCreate({
              Id: res.data[0].Id,
              TrackDate: res.data[0].Date,
              Machine_No: res.data[0].Machine_No,
              PartName: res.data[0].Part_Name,
              Rmrks: res.data[0].Remarks,
              shot_1: res.data[0].Shot_1,
              shot_2: res.data[0].Shot_2,
              shot_3: res.data[0].Shot_3,
              shot_4: res.data[0].Shot_4,
              shot_5: res.data[0].Shot_5,
              shot_6: res.data[0].Shot_6,
              shot_7: res.data[0].Shot_7,
              shot_8: res.data[0].Shot_8,
              shot_9: res.data[0].Shot_9,
              shot_10: res.data[0].Shot_10,
              shot_11: res.data[0].Shot_11,
              total1: res.data[0].Total1,
              total2: res.data[0].Total2,
              sign: res.data[0].Sign,
              sign_name: res.data[0].Sign_name,
              verified_name: res.data[0].Verified_name,
              approved_name: res.data[0].Approved_name,
              approved1: res.data[0].Approved1,
              approved2: res.data[0].Approved2,
              startUp: [],    
               Shots: res.data[0].shots.map((shot) => ({
                Shot_number: shot.Shot_number,
                value: shot.Value,
                Id: shot.Id,
                Daily_machine_startup_id:shot.Daily_machine_startup_id
              })),
             
            });
         
            setLastShotNumber(true)
            setView(true)
          }
        } finally {
         setLoading(false)
        }
      }
    };
    fetchData();
}, []);

       const handleUpdate = (e) => {
        console.log(Create)
        e.preventDefault();   
        if (scrollTop.current) {
         scrollTop.current.scrollIntoView({ behavior: "smooth" });
           }  
           console.log(Create.Id);
           let noId = {};
           let fields = [
             "Shots",
           ];    
           fields.map((field) => {
             Create[field].map((data) => {
               if (!noId[field]) {
                 noId[field] = data.Id === undefined ? true : false;
               }
             });
           });
           console.log("noId", noId);
           Object.keys(noId).map((item) => {
             if (noId[item]) {
               DeleteOriginalRecord(item, [{ Daily_machine_startup_id: Create.Id }]).then((res) => {
                 console.log("DeleteOriginalRecord", res);
                 const newData = Create[item].map((obj) => {
                   const newObj = { ...obj };
                   delete newObj.Id;
                   newObj.Daily_machine_startup_id = Create.Id;
                   console.log("newob",newObj)
                   return newObj;
                 });
                 console.log("newData", newData);
                 CreateRecord(item, newData);
                 delete Create[item];
               });
               console.log("noId", Create[item]);
             }
           });
           console.log("HandleU",Create)
           
  const data = {
    data: [
      {
        Id: Create.Id,
        Date: Create.Date,
        Machine_No: Create.Machine_No,
        Part_Name:Create.PartName,
        Remarks: Create.Rmrks,
        Shot_1: Create.shot_1,
        Shot_2: Create.shot_2,
        Shot_3: Create.shot_3,
        Shot_4: Create.shot_4,
        Shot_5: Create.shot_5,
        Shot_6: Create.shot_6,
        Shot_7: Create.shot_7,
        Shot_8: Create.shot_8,
        Shot_9: Create.shot_9,
        Shot_10: Create.shot_10,
        Shot_11: Create.shot_11,
        Sign: Create.sign,
        Sign_name: Create.sign_name,
        Total1: Create.total1,
        Total2: Create.total2,
        Verified: Create.verified,
        Approved: Create.approved,
        Verified_name: Create.verified_name,
        Approved_name: Create.approved_name,
        approved1: Create.approved1,
        approved2: Create.approved2,
      },
    ],
  };
  
  data["_keyword_"] = "daily_machine_startup";
  data["secretkey"] = SECRET_KEY;

  getAPIRequest(PROCESS_TRANSACTION_UPDATE_URL, 'POST', data)
    .then((response) => {
      console.log(response);
      setCreate({
        ...Create,
        isPaginationVisible: true
      });
      alert(`${GeneralConstants.UPDATE_SUCCESSFUL}`);
  
    //  window.location.reload();
    })
    .catch((error) => {
      console.log(error);
      alert(GeneralConstants.UPDATE_FAILURE);
      // window.location.reload();
    });
};
  function GetSelectValue(value, keyword, setName) {
    if (value.length > 0) {
    // setMachine_No("");
      ReadRecord({
        _keyword_: keyword,
        key: "%" + value + "%",
        value: "%" + value + "%"
      }).then((res) => {
        if(res.rows>0){
          console.log("read", res.data);
          setName(res.data);
          setRedAlert(false);
        }else{
          console.log("123424");
          setRedAlert(true);
        }
        
        // setKey(keyvalue[options.reference]);
      });
    }
  }
  

  function GetSelectValue2(value, keyword, setName) {
    ReadRecord({
      _keyword_: keyword,
      key: "%" + value + "%",
      value: "%" + value + "%"
    }).then((res) => {
      if (res.rows > 0) {
        setName({ value: res.data[0].Emp_code, label: res.data[0].Emp_name })
      } else {
        alert("Not Available")
      }
    });
  }
  const handleCreate = async (e) => {
    console.log(Create)
    e.preventDefault();
    const isSubmissionSuccessful = true;
    if (!Create.PartName || !Create.Machine_No || !Create.approved_name || !Create.verified_name || !Create.sign_name) {
      setIsCreating(true);
      if (scrollTop.current) {
        scrollTop.current.scrollIntoView({ behavior: "smooth" });
      }
      alert(GeneralConstants.MANDATORY_ALERT);
      return;
    }
    try {
     
      const data = {
        data: [
          {
            Date: Create.TrackDate,
            Machine_No: Create.Machine_No,
            Part_Name: Create.PartName,
            Remarks: Create.Rmrks,
            Shot_1: Create.shot_1,
            Shot_2: Create.shot_2,
            Shot_3: Create.shot_3,
            Shot_4: Create.shot_4,
            Shot_5: Create.shot_5,
            Shot_6: Create.shot_6,
            Shot_7: Create.shot_7,
            Shot_8: Create.shot_8,
            Shot_9: Create.shot_9,
            Shot_10: Create.shot_10,
            Shot_11: Create.shot_11,
            Sign: Create.sign,
            Sign_name: Create.sign_name,
            Total1: Create.total1,
            Total2: Create.total2,
            Verified: Create.verified,
            Approved: Create.approved,
            verified_name: Create.verified_name,
            approved_name: Create.approved_name,
            approved1: "Mr.RAJKUMAR",
            approved2: "Mr.RAJKUMAR",
            shots:Create.Shots,
          },
        ],
      };
   
      data["_keyword_"] = "daily_machine_startup";
      data["secretkey"] = SECRET_KEY;
    //  console.log(daily_machine_startup_shots)
      const response = await getAPIRequest(
        PROCESS_TRANSACTION_URL,
        "POST",
        data
      );
      if (response.success < 0) {
        alert(GeneralConstants.FAIL_TO_SAVE_MACHINE);
      } else {
        const updatedStartUp = [...Create.startUp, response.data];
        setCreate({ ...Create, startUp: updatedStartUp });
        alert(`${GeneralConstants.SUBMISSION_SUCCESSFUL}`);
        setCreate({ ...initialState });
        setIsCreating(false);
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      alert(`${GeneralConstants.SUBMISSION_FAILED}`);
      window.location.reload();
    }
  };
  
              const mcNoOptions = dropdown.map((i) => ({
                   value: i.mc_code,
                  label: i.mc_name,
                    }));
 
        const partNoOptions = partDropdown.map((i) => ({
         value: i.mc_part_name,
         label: i.mc_part_name,
            }));
        const empAllOptions = dropdown1.map((i) => ({
            label: i.Emp_name,
            value: i.Emp_code,
           }));
  
          useEffect(() => {
          document.title = "Machine StartUp"
           }, []);
           if (loading) {
            return <LoadingPage />;
          }
          const handleAddShot = (e) => {
            e.preventDefault();
            const Keys = Create.Shots.map(shot => shot.Shot_number);
            const lastShotNumber = Keys.length > 0 ? Math.max(...Keys) : 11; 
            if (lastShotNumber < 50) {
              const nextShotNumber = lastShotNumber + 1;
              setCreate(prevState => ({
                ...prevState,
                Shots: [...prevState.Shots, { Shot_number: nextShotNumber.toString(), value: "" }]
              }));
              setLastShotNumber(nextShotNumber);
            } else {
              alert('Cannot add more than shot 50')
              console.log('Cannot add more than shot 50');
            }
          };
          const handleRemoveShot = (e) => {
            e.preventDefault();
            setCreate(prevState => ({
              ...prevState,
              Shots: prevState.Shots.slice(0, -1) 
            }));
          };
          
          const handleShotChange = (shotNumber, newValue) => {
            setCreate(prevState => ({
              ...prevState,
              Shots: prevState.Shots.map(shot =>
                shot.Shot_number === shotNumber
                  ? { ...shot, value: newValue}
                  : shot
              )
            }));
          };
          
   
     
        
        
        
        
  return (
  <QaLayout  >

    <div>
      <form
        id="MaterialLoading-formContainer-Create"
        name="MaterialLoading-formContainer-Create"
        className="form-MaterialLoading-Create"
      >
        <div ref={scrollTop}>
          <table className="table" style={{ textAlign: "center", width: "100%" }}>
            <tbody>
              <tr>
                <th style={{ textAlign: "center" }} colSpan={5}>
                <br />
                  <font color="#8B0000">Daily Machine StartUp and ReStartUp</font>
                </th>
                <th colspan="1">
            FORMAT NO: UPCL/QC/R/23
            <br />
            REV NO: 01
            <br />
            REV DATE: 28-12-2019
          </th>
              </tr>
              {/* <tr>
              {" "}
              <td className="topic-head">
                &nbsp;&nbsp;MACHINE<span style={{ color: "red" }}>*</span>
              </td>
              <td>
              
                <Select
                    value={Create.Machine_No}

                    onChange={(e) =>
                      setCreate({
                        ...Create,
                        Machine_No: e.value,
                      })
                    }
                    onInputChange={(event) => { GetSelectValue(event, "Get_machine_from_smp", setDropdown) }}
                    options={mcNoOptions}
                    placeholder="Search Machine No..."
                  />
                {Create.Machine_No === '' && isCreating && <span className="Req">Required *</span>}

              </td></tr> */}
              <tr>
              <td className="topic-head">
                &nbsp;&nbsp;Machine No<span style={{ color: "red" }}>*</span>
              </td>
            <td>
               
                <DropdownFetcher
                  keyword="mac_dtl_dropdown"
                  setValue={setDropdown}
                />  
                <DropdownFilterSelect value={Create.Machine_No}  isDisabled={option === "view" || option === "edit"} onChange={(e) =>
                  setCreate({
                    ...Create,
                    Machine_No: e.value,
                  })
                } options={mcNoOptions} placeholder="Select Machine" />
                {Create.Machine_No === '' && isCreating && <span className="Req">Required *</span>}

              </td>
                
                <td className="topic-head">&nbsp;&nbsp;Date</td>
                <td>
                <DatePicker
                  value={Create.TrackDate}
                  onChange={handleDateChange}
                  dis={true}
                />
               </td>
               <td className="topic-head">
                  Part Name<span style={{ color: "red" }}>*</span>
                </td>
                <td>
                <DropdownFetcher keyword="mac_part_dtl" setValue={setPartDropdown} />
               
               <DropdownFilterSelect value={Create.PartName} 
             isDisabled={option === "view" || option === "edit"}
               onChange={(e) =>
                  setCreate({
                    ...Create,
                    PartName: e.value,
                  })
                } options={partNoOptions} placeholder="Select Part name" />
                 {Create.PartName === '' && isCreating && <span className="Req">Required *</span>}
                </td>
              </tr>

              <tr>
             

                <td className="topic-head">&nbsp;&nbsp;Shot No 1</td>
                <td>
                <input
                    type="text"
                    disabled={option === "view"}
                    value={Create.shot_1}
                    onChange={(e) =>
                      setCreate({...Create,
                        shot_1: e.target.value })}/>
                </td>
                <td className="topic-head">&nbsp;&nbsp;Shot No 2</td>
                <td>
                <input
                    type="text"
                    disabled={option === "view"}
                    value={Create.shot_2}
                    onChange={(e) =>
                      setCreate({...Create,
                        shot_2: e.target.value })}/>
                </td>
                <td className="topic-head">&nbsp;&nbsp;Shot No 3</td>
                <td>
                <input
                    type="text"
                    disabled={option === "view"}
                    value={Create.shot_3}
                    onChange={(e) =>
                      setCreate({...Create,
                        shot_3: e.target.value })}/>
                </td>
                </tr>
                <tr>
                <td className="topic-head">&nbsp;&nbsp;Shot No 4</td>
                <td>
                <input
                    type="text"
                    disabled={option === "view"}
                    value={Create.shot_4}
                    onChange={(e) =>
                      setCreate({...Create,
                        shot_4: e.target.value })}/>
                </td>
                <td className="topic-head">&nbsp;&nbsp;Shot No 5</td>
                <td>
                <input
                    type="text"
                    disabled={option === "view"}
                    value={Create.shot_5}
                    onChange={(e) =>
                      setCreate({...Create,
                        shot_5: e.target.value })}/>
                </td>
           
              <td className="topic-head">&nbsp;&nbsp;Shot No 6</td>
                <td>
                <input
                    type="text"
                    disabled={option === "view"}
                    value={Create.shot_6}
                    onChange={(e) =>
                      setCreate({...Create,
                        shot_6: e.target.value })}/>
                </td>
                </tr>
                <tr>
                <td className="topic-head">&nbsp;&nbsp;Shot No 7</td>
                <td>
                <input
                    type="text"
                    disabled={option === "view"}
                    value={Create.shot_7}
                    onChange={(e) =>
                      setCreate({...Create,
                        shot_7: e.target.value })}/>
                </td>
                <td className="topic-head">&nbsp;&nbsp;Shot No 8</td>
                <td>
                <input
                    type="text"
                    disabled={option === "view"}
                    value={Create.shot_8}
                    onChange={(e) =>
                      setCreate({...Create,
                        shot_8: e.target.value })}/>
                </td>
          
             
                <td className="topic-head">&nbsp;&nbsp;Shot No 9</td>
                <td>
                <input
                    type="text"
                    disabled={option === "view"}
                    value={Create.shot_9}
                    onChange={(e) =>
                      setCreate({...Create,
                        shot_9: e.target.value })}/>
                </td>
              </tr>
              <tr>
              <td className="topic-head">&nbsp;&nbsp;Shot No 10</td>
                <td>
                <input
                    type="text"
                    disabled={option === "view"}
                    value={Create.shot_10}
                    onChange={(e) =>
                      setCreate({...Create,
                        shot_10: e.target.value })}/>
                </td>

                <td className="topic-head">&nbsp;&nbsp;Shot No 11</td>
                <td>
                <input
                    type="text"
                    disabled={option === "view"}
                    value={Create.shot_11}
                    onChange={(e) =>
                      setCreate({...Create,
                        shot_11: e.target.value })}/>
                  </td> 

                  <td> <button  onClick={handleAddShot}disabled={option === "view"}>Add Next Shot</button></td>  
                  <td><button  onClick={handleRemoveShot}disabled={option === "view"}>-</button></td>
                  
                  </tr>
                  {Create.Shots && Create.Shots.map((Shot, index) => (
       index % 3 === 0 ? (
      <tr key={`row_${index / 3}`}>
      {[0, 1, 2].map((colIndex) => {
        const shotIndex = index + colIndex;
        if (shotIndex < Create.Shots.length) {
          const currentShot = Create.Shots[shotIndex];
            const shotno= currentShot.Shot_number
          return (
            <React.Fragment key={`shot_${currentShot.Shot_number}`}>
              <td className="topic-head">Shot No {currentShot.Shot_number}</td>
              <td>
                <input
                   type="text"
                   disabled={option === "view"}
                   value= {currentShot.value}
                   onChange={(e) => handleShotChange(shotno, e.target.value)}/>
               </td>
            </React.Fragment>
          );
        }
        return <React.Fragment key={`empty_${colIndex}`}></React.Fragment>;
       })}
       </tr>
       ) : null
       ))}
        {/* {lastShotNumber >= 12 && (
        <>
        {[...Array(Math.ceil((lastShotNumber - 11) / 3))].map((_, rowIndex) => {
        return (
         <tr key={`row_${rowIndex}`}>
          {[...Array(3)].map((__, colIndex) => {
            const shotNumber = rowIndex * 3 + colIndex + 12;
                if (shotNumber <= lastShotNumber) {
                return (
                <React.Fragment key={`shot_${shotNumber}`}>
                  <td className="topic-head">&nbsp;&nbsp;Shot No {shotNumber}</td>
                   <td>
                   <input
                   type="text"
                   disabled={option === "view"}
                   value={Create?.Shots?.find(shot => shot.Shot_number === shotNumber)?.value || ""}
                   onChange={(e) => handleShotChange(shotNumber, e.target.value)}    
                     />
                    </td>
                </React.Fragment>
               );
              } else {
               return <React.Fragment key={`empty_${colIndex}`}></React.Fragment>;
              }
          })}
        </tr>
      );
    })}
  </>
)} */}
          <tr>
                <td className="topic-head">&nbsp;&nbsp;Total OK</td>
                <td>
                <input
                
                    type="text"
                    disabled={option === "view"}
                    value={Create.total1}
                    onChange={(e) =>
                      setCreate({
                        ...Create,
                        total1: e.target.value,
                      })}  
                  />
                </td>
               
                      </tr>
                   <tr>
                   <td className="topic-head">&nbsp;&nbsp;Total REJ</td>
                <td>
                <input
                
                    type="text"
                    value={Create.total2}
                    disabled={option === "view"}
                    onChange={(e) =>
                      setCreate({
                        ...Create,
                        total2: e.target.value,
                      })}  
                  />
                </td>
                  <td className="topic-head">&nbsp;&nbsp;Remarks</td>
                <td>
                <input
                
                    type="text"
                    value={Create.Rmrks}
                    disabled={option === "view"}
                    onChange={(e) =>
                      setCreate({
                        ...Create,
                        Rmrks: e.target.value,
                      })}  
                  />
                </td>
                <td className="topic-head">&nbsp;&nbsp;Signature<span style={{ color: "red" }}>*</span></td>
                <td>
                {Create.sign_name === '' && isCreating && <span className="Req">Required *</span>}
                <DropdownFetcher
                    keyword="emp_details_dropdown_all"
                    setValue={setDropdown1}
                  />
                  <DropdownFilterSelect
                     value={Create.sign_name}
                   isDisabled={option === "view"}
                    onChange={(e) =>
                      setCreate({
                        ...Create,
                        sign_name:  e.label,
                        sign: e.value,

                      })
                    }
                    options={empAllOptions}
                    placeholder="Sign"
                    
                  />
                
                </td>
               </tr>
               {/* <tr>
                <td colSpan={1} className="topic-head">Remarks:</td>
                <td colSpan={5}></td>
               </tr> */}
               <tr>
                <td colSpan={1} className="topic-head">Notes:</td>
                <td colSpan={5}>
                <span>Each and every Machine Restart up condition need to fill the format(Power cut,Mould loading,Nozzle blockage)</span></td>
               </tr>
               <tr>
  <td colSpan={6} style={{ whiteSpace: 'nowrap' }}>
    <span style={{ borderRight: '2px solid black', padding: '0 15px',marginLeft:"5%" }}>A: SHORT FILL </span>
    <span style={{ borderRight: '2px solid black', padding: '0 15px',marginLeft:"5%"  }}>B: STREAKERS </span>
    <span style={{ borderRight: '2px solid black', padding: '0 15px',marginLeft:"5%"  }}>C: SHRINKAGE </span>
    <span style={{ borderRight: '2px solid black', padding: '0 15px',marginLeft:"5%"  }}>D: WEILD LINE </span>
    <span style={{ borderRight: '2px solid black', padding: '0 15px',marginLeft:"5%"  }}>E: FLOW MARK </span>
    <span style={{ borderRight: '2px solid black', padding: '0 15px',marginLeft:"5%"  }}>F: FLASH </span>
    <span style={{ padding: '0 15px' ,marginLeft:"5%" }}>G: DENT MARK</span>
  </td>
</tr>
<tr>
  <td colSpan={6} style={{ whiteSpace: 'nowrap' }}>
    <span style={{ borderRight: '2px solid black', padding: '0 15px',marginLeft:"0%" }}>H: OIL MARK&WAX MARK</span>
    <span style={{ borderRight: '2px solid black', padding: '0 15px',marginLeft:"2%"  }}>I: MATERIAL MIXUP </span>
    <span style={{ borderRight: '2px solid black', padding: '0 15px',marginLeft:"5%"  }}>J: BLACK SHADE</span>
    <span style={{ borderRight: '2px solid black', padding: '0 15px',marginLeft:"2%"  }}>K: INSERT  BROCKEN </span>
    <span style={{ borderRight: '2px solid black', padding: '0 15px',marginLeft:"5%"  }}> L: BURN  MARK </span>
    <span style={{ borderRight: '2px solid black', padding: '0 15px',marginLeft:"4%"  }}> M: SCORING</span>
    <span style={{ padding: '0 15px' ,marginLeft:"5%" }}>N: SHANING MARK</span>
  </td>
</tr>
                  <tr>
                    <td colSpan={1} className="topic-head">
                       VERIFIED<span style={{ color: "red" }}>*</span>
                    </td>
                     <td colSpan={2}>
                     {Create.verified_name === '' && isCreating && <span className="Req">Required *</span>}
                     <DropdownFetcher
                    keyword="emp_details_dropdown_all"
                    setValue={setDropdown1}
                  />
                  <DropdownFilterSelect
                    value={Create.verified_name}
                    isDisabled={option === "view"}
                    onChange={(e) =>
                      setCreate({
                        ...Create,
                        verified: e.value,
                        verified_name: e.label,
                       
                      })
                    }
                    options={empAllOptions}
                    placeholder="Verified By"
                  />
                        </td>
                        <td colSpan={1} className="topic-head">
                      APPROVED<span style={{ color: "red" }}>*</span>
                    </td>
                    <td colSpan={2}>
                    {Create.approved_name === '' && isCreating && <span className="Req">Required *</span>}
                    <DropdownFetcher
                    keyword="emp_details_dropdown_all"
                    setValue={setDropdown1}
                  />
                  <DropdownFilterSelect
                    value={Create.approved_name}
                    isDisabled={option === "view"}
                    onChange={(e) =>
                      setCreate({
                        ...Create,
                        approved: e.value,
                        approved_name: e.label,
                      })
                    }
                    options={empAllOptions}
                    placeholder="Approved By"
                    
                  /></td>
                  </tr>
               <tr>
               <td>sl.no</td>
               <td>Rev.no</td>
               <td colSpan={3} style={{textAlign:"center"}}>Revision Details</td>
               <td>Approved</td>
            </tr>
            <tr>
               <td>1</td>
               <td>0</td>
               <td colSpan={3} style={{textAlign:"center"}}>initial relase</td>
               <td>  <DropdownFetcher
                    keyword="emp_details_dropdown_all"
                    setValue={setDropdown1}
                  />
                  <DropdownFilterSelect
                    value={Create.approved1}
                    isDisabled={option === "view"}
                    onChange={(e) =>
                      setCreate({
                        ...Create,
                        approved1: e.label,
                      })
                    }
                    options={empAllOptions}
                    placeholder="label"
                    
                  /></td>
            </tr>
            <tr>
               <td>2</td>
               <td>1</td>
               <td colSpan={3} style={{textAlign:"center"}}>ReStartUp time added</td>
               <td>  <DropdownFetcher
                    keyword="emp_details_dropdown_all"
                    setValue={setDropdown1}
                  />
                  <DropdownFilterSelect
                    value={Create.approved2}
                    isDisabled={option === "view"}
                    onChange={(e) =>
                      setCreate({
                        ...Create,
                        approved2: e.label,
                      })
                    }
                    options={empAllOptions}
                    placeholder="Approved"
                    
                  /></td>
            </tr>
            <tr>
              <td colSpan={6} style={{textAlign:"center"}}>
              NOTE: If within 10 Shots part not meet Master Sample Requirement then given Re-First parts approval to us.
              </td>
            </tr>
            {!(option === "view") && <tr>
              {id === undefined ? (
                <td colSpan={19}>
                  {" "}
                  <Field
                    label={"Submit"}
                    options={{ type: "Button" }}
                    onClick={(e) => {
                      handleCreate(e);
                    }}
                  />
                </td>
              ) : (
                <td colSpan={19}>
                  {" "}
                  <Field
                    label={"Update"}
                    options={{ type: "Button" }}
                    onClick={(e) => {
                      handleUpdate(e);
                    }}
                  />
                </td>
              )}
            </tr>}
           
            </tbody>
          </table>
        </div>
      </form>
    </div>
    </QaLayout>
  );
}

export default MachineStartupCreate;
