import React from "react";

const Pagination = ({ currentPage, npage, prePage, nextPage, changeCpage }) => {
  const displayPageCount = 5; 
  const halfDisplayCount = Math.floor(displayPageCount / 2);

  const getDisplayedPages = () => {
    let pages = npage-1
    if (pages <= displayPageCount) {
      return [...Array(pages).keys()].map((num) => num + 1);
    }
    // console.log(displayedPages);
    if (currentPage <= halfDisplayCount) {
      return [...Array(displayPageCount).keys()].map((num) => num + 1);
    } else if (currentPage >= pages - halfDisplayCount) {
      return [...Array(displayPageCount).keys()].map((num) => pages - displayPageCount + num + 1);
    } else {
      return [...Array(displayPageCount).keys()].map((num) => currentPage - halfDisplayCount + num + 1);
    }
  };

  const displayedPages = getDisplayedPages();

  console.log(displayedPages);

  return (
    <div>
      <nav>
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <a
              href="#"
              className="page-link"
              onClick={prePage}
              disabled={currentPage === 1}
            >
              Prev
            </a>
          </li>
          <li className="page-item">
            <a href="#" className="page-link" onClick={() => changeCpage(1)}>
              1
            </a>
          </li>
          {currentPage > halfDisplayCount + 1 && (
            <li className="page-item disabled">
              <span className="page-link">...</span>
            </li>
          )}
          {displayedPages.map((pageNumber) => {
            if(pageNumber!==1){
              return (
                <li
                  className={`page-item ${currentPage === pageNumber ? "active" : ""}`}
                  key={pageNumber}
                >
                  <a
                    href="#"
                    className={`page-link ${currentPage === pageNumber ? "disabled" : ""}`}
                    onClick={() => changeCpage(pageNumber)}
                    disabled={currentPage === pageNumber}
                  >
                    {pageNumber}
                  </a>
                </li>
              )
            }
          })}
            {currentPage < (npage) - halfDisplayCount && npage > 5 &&(
              <li className="page-item disabled">
                <span className="page-link">...</span>
              </li>
            )}
         {npage > 1 && ( <li className="page-item">
            
            <a href="#" className="page-link" onClick={() => changeCpage(npage)}>
              {npage}
            </a>
            
          </li>)
          }
          <li className={`page-item ${currentPage === npage ? "disabled" : ""}`}>
            <a
              href="#"
              className="page-link"
              onClick={nextPage}
              disabled={currentPage === npage}
            >
              Next
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
