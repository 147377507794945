import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { API_URL } from "../../../Utils/constants";
import "../../../Styles/Report4M.css";
import Unitechlogo from "../../../icons/Unitechlogo.jpeg";
import DropdownFetcher from "../../../Lib/DropdownFetcher";
import Select from "react-select";
import useScanDetection from "use-scan-detection";
import { setMessage } from "../../../Lib/SetMessage.js";
import ReadRecord from "../../../Lib/API/ReadRecord.js";

// import swal from 'sweetalert';

function Report4MCreate() {
  const scrollTop = useRef(null);
  const [RmNme, setRmNme] = useState("");
  const [CyleCde, setCyleCde] = useState("");
  const [MldNme, setMldNme] = useState("");
  const [NoOfCvty, setNoOfCvty] = useState("");
  const [RmiCde, setRmiCde] = useState("");
  const [PrtWeght, setPrtWeght] = useState("");
  const [Plan, setPlan] = useState("");
  const [Receipt1, setReceipt1] = useState("");
  const [Receipt2, setReceipt2] = useState("");
  const [Balance1, setBalance1] = useState("");
  const [Balance2, setBalance2] = useState("");
  const [From, setFrom] = useState("");
  const [FgStock, setFgStock] = useState("");
  const [PartNo, setPartNo] = useState("");
  const [NoOfCvtyRun, setNoOfCvtyRun] = useState("");
  const [ItmCde, setItmCde] = useState("");
  const [Tonnage, setTonnage] = useState("");
  const [Material, setMaterial] = useState("");
  const [FgwStock, setFgwStock] = useState("");
  const [SmiStock1, setSmiStock1] = useState("");
  const [SmiStock2, setSmiStock2] = useState("");
  const [TtlStock1, setTtlStock1] = useState("");
  const [TtlStock2, setTtlStock2] = useState("");
  const [NodysCov1, setNodysCov1] = useState("");
  const [NodysCov2, setNodysCov2] = useState("");
  const [To, setTo] = useState("");

  const [MovingProcess, setMovingProcess] = useState("");
  const [MovingProcessRemarks, setMovingProcessRemarks] = useState("");
  const [changeprocess, setchangeprocess] = useState("");
  const [PackingStd, setPackingStd] = useState("");
  const [ProductionHead, setProductionHead] = useState("");
  const [PreparedByName, setPreparedByName] = useState("");
  const [VendarName, setVendarName] = useState("");
  const [PurchaseName, setPurchaseName] = useState("");
  const [CustomerQualityName, setCustomerQualityName] = useState("");
  const [ProductionQualityName, setProductionQualityName] = useState("");
  const [ToolRoomSign, setToolRoomSign] = useState("");
  const [StoreMaterial, setStoreMaterial] = useState("");
  const [DirectorSign, setDirectorSign] = useState("");
  const [SqaSign, setSqaSign] = useState("");
  const [isCreating, setIsCreating] = useState(false);

  const [EmployeeDropdown, setEmployeeDropdown] = useState([]);
  const [HODEmployeeDropdown, setHODEmployeeDropdown] = useState([]);
  const [partDropdown, setPartDropdown] = useState([]);
  const [dropdown, setDropdown] = useState([]);
  const [Rmdropdown, setRmDropdown] = useState([]);
  const [vendorDropdown, setVendorDropdown] = useState([]);
  const [fgDropdown, setFgDropdown] = useState([]);

  var [Report, setReport] = useState([]);

  //Unique id and name
  const id = "Report4M";
  const name = "Report4M";

  // const CodeGen = async () => {
  //   try {
  //     const val = {
  //       tablename: "Report_4M",
  //       PrimaryCode: "Mat_name",
  //       PrimaryVal: MldNme,
  //       // FieldCode: "Mat_grde",
  //       // FieldVal: MldNme,
  //     };

  //     const response = await axios.post(
  //       `http://${API_URL}/AppContextService/CodeGen`,
  //       val
  //     );
  //     return response.data.success === "true";
  //   } catch (error) {
  //     throw error;
  //   }
  // };

  const handleCreate = async (e) => {
    // console.log("MovingProcess", MovingProcess);
    // // console.log("changeprocess65e",changeprocess.toString());
    const MovingProcessChange = changeprocess.toString();
    e.preventDefault();
    if (!MovingProcess || !ProductionHead || !PreparedByName || !VendarName || !PurchaseName || !CustomerQualityName || !ProductionQualityName || !ToolRoomSign || !StoreMaterial || !DirectorSign || !SqaSign) {
      alert("Please fill the mandatory fields");

      setIsCreating(true);
      if (scrollTop.current) {
        scrollTop.current.scrollIntoView({ behavior: "smooth" });
      }
      return;
    }

    // if (MovingProcess === "Man") {
    //   if (!MovingProcess || !ProductionHead || !PreparedByName || !DirectorSign) {
    //     if (scrollTop.current) {
    //       scrollTop.current.scrollIntoView({ behavior: "smooth" });
    //     }
    //     setIsCreating(true);
    //     alert("Please fill the mandatory fields");
    //     return;
    //   }
    // } else if (MovingProcess === "Mould") {
    //   if (!MovingProcess || !ProductionHead || !PreparedByName || !DirectorSign || !VendarName || !PurchaseName || !ProductionQualityName || !ToolRoomSign || !SqaSign) {
    //     if (scrollTop.current) {
    //       scrollTop.current.scrollIntoView({ behavior: "smooth" });
    //     }
    //     setIsCreating(true);
    //     alert("Please fill the mandatory fields");
    //     return;
    //   }
    // } else if (MovingProcess === "Material") {
    //   if (!MovingProcess || !ProductionHead || !PreparedByName || !DirectorSign || !VendarName || !PurchaseName || !ProductionQualityName || !StoreMaterial || !SqaSign) {
    //     if (scrollTop.current) {
    //       scrollTop.current.scrollIntoView({ behavior: "smooth" });
    //     }
    //     setIsCreating(true);
    //     alert("Please fill the mandatory fields");
    //     return;
    //   }
    // } else if (MovingProcess === "Method") {
    //   if (!MovingProcess || !ProductionHead || !PreparedByName || !DirectorSign || !ProductionQualityName || !ToolRoomSign || !SqaSign) {
    //     if (scrollTop.current) {
    //       scrollTop.current.scrollIntoView({ behavior: "smooth" });
    //     }
    //     setIsCreating(true);
    //     alert("Please fill the mandatory fields");
    //     return;
    //   }

    // } else if (!MovingProcess) {
    //   if (scrollTop.current) {
    //     scrollTop.current.scrollIntoView({ behavior: "smooth" });
    //   }
    //   setIsCreating(true);
    //   alert("Please fill the mandatory fields");
    //   return;
    // }



    try {
      // const isDuplicate = await CodeGen();

      // if (isDuplicate) {

      //   setIsCreating(false);

      //   alert(`${MldNme} Already Exist.`);

      //   setRmNme("");
      //   setCyleCde("");
      //   setMldNme("");
      //   setNoOfCvty("");
      //   setRmiCde("");
      //   setPrtWeght("");
      //   setPlan("");
      //   setReceipt1("");
      //   setReceipt2("");
      //   setBalance1("");
      //   setBalance2("");
      //   setFrom("");
      //   setFgStock("");
      //   setPartNo("");
      //   setNoOfCvtyRun("");
      //   setItmCde("");
      //   setTonnage("");
      //   setMaterial("");
      //   setFgwStock("");
      //   setSmiStock1("");
      //   setSmiStock2("");
      //   setTtlStock1("");
      //   setTtlStock2("");
      //   setNodysCov1("");
      //   setNodysCov2("");
      //   setTo("");

      //   setMovingProcess("");
      //   setMovingProcessRemarks("");
      //   setPackingStd("");
      //   setProductionHead("");
      //   setPreparedByName("");
      //   setVendarName("");
      //   setPurchaseName("");
      //   setCustomerQualityName("");
      //   setProductionQualityName("");
      //   setToolRoomSign("");
      //   setStoreMaterial("");
      //   setDirectorSign("");
      //   setSqaSign("");
      //   if (scrollTop.current) {
      //     scrollTop.current.scrollIntoView({ behavior: "smooth" });
      //   }
      //   return;
      // }

      const data = {
        data: [
          {
            Mld_name: MldNme.value,
            No_of_cvty: NoOfCvty,
            Part_no: PartNo,
            No_of_cvty_run: NoOfCvtyRun,
            Itm_cde: ItmCde.value,
            Tonnage: Tonnage,
            Rmi_cde: RmiCde,
            Cyle_cde: CyleCde,
            Prt_weight: PrtWeght,
            Plan: Plan,
            Material: Material,
            Receipt_month_plan: Receipt1,
            Receipt_material_plan: Receipt2,
            Rm_name: RmNme.label,
            Balance_month_plan: Balance1,
            Balance_material_plan: Balance2,
            From_1: From,
            To_2: To,
            Fgw_stock: FgwStock,
            Smi_stock_month_plan: SmiStock1,
            No_dys_cov_month_plan: NodysCov1,
            No_dys_cov_material_plan: NodysCov2,
            Fg_stock: FgStock,
            Smi_stock_material_plan: SmiStock2,
            Ttl_stock_month_plan: TtlStock1,
            Ttl_stock_material_plan: TtlStock2,

            Moving_process: MovingProcessChange,
            Moving_process_remarks: MovingProcessRemarks,
            Packing_std: PackingStd,
            Production_head: ProductionHead.value,
            Prepared_by_name: PreparedByName.value,
            Vendar_name: VendarName.value,
            Customer_quality_name: CustomerQualityName.value,
            Production_quality_name: ProductionQualityName.value,
            Tool_room_sign: ToolRoomSign.value,
            Store_material: StoreMaterial.value,
            Director_sign: DirectorSign.value,
            Sqa_sign: SqaSign.value,
            Purchase_name: PurchaseName.value,
          },
        ],
        _keyword_: "Report_4M",
        secretkey: "2bf52be7-9f68-4d52-9523-53f7f267153b",
      };

      const response = await axios.post(
        `http://${API_URL}/GenericTransactionService/processTransaction`,
        data
      ).then((response) => {
        // console.log("res", res);
        if (response.data.ROWID > 0) {
          alert(`4m Report Submitted Successfully!`);
          setRmNme("");
          setCyleCde("");
          setMldNme("");
          setNoOfCvty("");
          setRmiCde("");
          setPrtWeght("");
          setPlan("");
          setReceipt1("");
          setReceipt2("");
          setBalance1("");
          setBalance2("");
          setFrom("");
          setFgStock("");
          setPartNo("");
          setNoOfCvtyRun("");
          setItmCde("");
          setTonnage("");
          setMaterial("");
          setFgwStock("");
          setSmiStock1("");
          setSmiStock2("");
          setTtlStock1("");
          setTtlStock2("");
          setNodysCov1("");
          setNodysCov2("");
          setTo("");

          setMovingProcess("");
          setMovingProcessRemarks("");
          setPackingStd("");
          setProductionHead("");
          setPreparedByName("");
          setVendarName("");
          setPurchaseName("");
          setCustomerQualityName("");
          setProductionQualityName("");
          setToolRoomSign("");
          setStoreMaterial("");
          setDirectorSign("");
          setSqaSign("");

          setMessage({
            NoteFrom: PreparedByName.value,
            NoteTo: ProductionHead.value,
            NoteMessage:
              `Hi Mr.${ProductionHead.label} The 4M Report has been created and moved From ${From} To ${To},This was created by ${PreparedByName.value}-${PreparedByName.label}`,
            NoteRecordId: response.data.ROWID,
            TableName: "Report4M",
            NoteCreatedBy: localStorage.getItem("empId"),
          });
          setMessage({
            NoteFrom: PreparedByName.value,
            NoteTo: DirectorSign.value,
            NoteMessage:
              `Hi Mr.${DirectorSign.label} The 4M Report has been created and moved From ${From} To ${To},This was created by ${PreparedByName.value}-${PreparedByName.label}`,
            NoteRecordId: response.data.ROWID,
            TableName: "Report4M",
            NoteCreatedBy: localStorage.getItem("empId"),
          });
          setMessage({
            NoteFrom: PreparedByName.value,
            NoteTo: PurchaseName.value,
            NoteMessage:
              `Hi Mr.${CustomerQualityName.label} The 4M Report has been created and moved From ${From} To ${To},This was created by ${PreparedByName.value}-${PreparedByName.label}`,
            NoteRecordId: response.data.ROWID,
            TableName: "Report4M",
            NoteCreatedBy: localStorage.getItem("empId"),
          });
          setMessage({
            NoteFrom: PreparedByName.value,
            NoteTo: CustomerQualityName.value,
            NoteMessage:
              `Hi Mr.${CustomerQualityName.label} The 4M Report has been created and moved From ${From} To ${To},This was created by ${PreparedByName.value}-${PreparedByName.label}`,
            NoteRecordId: response.data.ROWID,
            TableName: "Report4M",
            NoteCreatedBy: localStorage.getItem("empId"),
          });
          setMessage({
            NoteFrom: PreparedByName.value,
            NoteTo: ToolRoomSign.value,
            NoteMessage:
              `Hi Mr.${ToolRoomSign.label} The 4M Report has been created and moved From ${From} To ${To},This was created by ${PreparedByName.value}-${PreparedByName.label}`,
            NoteRecordId: response.data.ROWID,
            TableName: "Report4M",
            NoteCreatedBy: localStorage.getItem("empId"),
          });
          setMessage({
            NoteFrom: PreparedByName.value,
            NoteTo: ProductionQualityName.value,
            NoteMessage:
              `Hi Mr.${ProductionQualityName.label} The 4M Report has been created and moved From ${From} To ${To},This was created by ${PreparedByName.value}-${PreparedByName.label}`,
            NoteRecordId: response.data.ROWID,
            TableName: "Report4M",
            NoteCreatedBy: localStorage.getItem("empId"),
          });
          setMessage({
            NoteFrom: PreparedByName.value,
            NoteTo: StoreMaterial.value,
            NoteMessage:
              `Hi Mr.${StoreMaterial.label} The 4M Report has been created and moved From ${From} To ${To},This was created by ${PreparedByName.value}-${PreparedByName.label}`,
            NoteRecordId: response.data.ROWID,
            TableName: "Report4M",
            NoteCreatedBy: localStorage.getItem("empId"),
          });
          setMessage({
            NoteFrom: PreparedByName.value,
            NoteTo: SqaSign.value,
            NoteMessage:
              `Hi Mr.${SqaSign.label} The 4M Report has been created and moved From ${From} To ${To},This was created by ${PreparedByName.value}-${PreparedByName.label}`,
            NoteRecordId: response.data.ROWID,
            TableName: "Report4M",
            NoteCreatedBy: localStorage.getItem("empId"),
          });
          if (scrollTop.current) {
            scrollTop.current.scrollIntoView({ behavior: "smooth" });
          }
          setIsCreating(false)
        }
      })
    } catch (error) {
      console.log("error", error);
      alert("Submission failed!");
      // window.location.reload();
    }
  };
  useEffect(() => {
    document.title = "4M Report"
  }, []);

  const options = [
    // { id: "select", value: "", label: "-- Select --" },
    { id: "Mould", value: "Mould", label: "Mould" },
    { id: "Man", value: "Man", label: "Man" },
    { id: "Material", value: "Material", label: "Material" },
    { id: "Method", value: "Method", label: "Method" },
  ];

  const handleProductionHead = (selectedOption) => {
    const selected = HODEmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setProductionHead({ value: selected.Emp_code, label: selected.Emp_name });
    }
  };
  const handlePreparedByName = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setPreparedByName({ value: selected.Emp_code, label: selected.Emp_name });
    }
  };
  const handleVendarName = (selectedOption) => {
    const selected = vendorDropdown.find(
      (option) => option.Sup_code === selectedOption.value
    );

    if (selected) {
      setVendarName({ value: selected.Sup_code, label: selected.Sup_name });
    }
  };
  const handlePurchaseName = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setPurchaseName({ value: selected.Emp_code, label: selected.Emp_name });
    }
  };
  const handleCustomerQualityName = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setCustomerQualityName({
        value: selected.Emp_code,
        label: selected.Emp_name,
      });
    }
  };
  const handleProductionQualityName = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setProductionQualityName({
        value: selected.Emp_code,
        label: selected.Emp_name,
      });
    }
  };
  const handleToolRoomSign = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setToolRoomSign({ value: selected.Emp_code, label: selected.Emp_name });
    }
  };
  const handleMldNme = (selectedOption) => {
    GetRawMaterial(selectedOption.value);
    const selected = partDropdown.find(
      (option) => option.mc_part_name === selectedOption.value
    );

    if (selected) {
      setMldNme({ value: selected.mc_part_name, label: selected.mc_part_name });
      setPartNo(selected.mc_part_code);
    }
  };
  async function GetRawMaterial(val) {
    try {
      const res = await ReadRecord({
        _keyword_: "Get_raw_material_by_part_from_smp",
        part: val,
      });
      if (res.rows == "1") {
        setRmNme({ value: res.data[0].Rm_code, label: res.data[0].Rm_description });
        setRmiCde(res.data[0].Rm_code);
      }

       else if(res.rows > "1") {
        console.log("response of raw", res.data);
        setRmDropdown(res.data);
      } else {
        alert("Contact Administrator to add Raw Material for this Selected Part!");
      }
    } catch (error) {
      console.error("Error fetching raw material:", error);
    }
  }
  const handleRmiCde = (selectedOption) => {
    const selected = Rmdropdown.find(
      (option) => option.Rm_code === selectedOption.value
    );
    // console.log(selected);
    if (selected) {
      setRmNme({ value: selected.Rm_code, label: selected.Rm_description });
      setRmiCde(selected.Rm_code);
    } else {
      alert("Contact Administrator to add Raw Material for this Selected Part!")
    }
  };
  const handleStoreMaterial = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setStoreMaterial({ value: selected.Emp_code, label: selected.Emp_name });
    }
  };
  const handleDirectorSign = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setDirectorSign({ value: selected.Emp_code, label: selected.Emp_name });
    }
  };
  const handleSqaSign = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setSqaSign({ value: selected.Emp_code, label: selected.Emp_name });
    }
  };
  const handleItemCode = (selectedOption) => {
    const selected = fgDropdown.find(
      (option) => option.Fg_code === selectedOption.value
    );

    if (selected) {
      setItmCde({ value: selected.Fg_code, label: selected.Fg_code });
    }
  };
  const fgOptions = fgDropdown.map((i) => ({
    value: i.Fg_code,
    label: i.Fg_code,
  }));
  const vendorOptions = vendorDropdown.map((i) => ({
    value: i.Sup_code,
    label: i.Sup_name,
  }));
  const partNoOptions = partDropdown.map((i) => ({
    value: i.mc_part_name,
    label: i.mc_part_name,
  }));
  const mcNoOptions = dropdown.map((i) => ({
    value: i.mc_code,
    label: i.mc_name,
  }));
  const empMoldSetterOptions = EmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const empHODSetterOptions = HODEmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const rmOptions = Rmdropdown.map((i) => ({
    value: i.Rm_code,
    label: i.Rm_description,
  }));
  // barcode scannning to get input
  useScanDetection({
    onComplete: (code) => {
      code = code.replaceAll("Shift", "");
      setField(code);
    },
    minLength: 3,
  });

  // set value to the appropiate field
  function setField(code) {
    let isnumber = Number(code).toString() !== "NaN";
    let current_element = document.activeElement.id;

    if (isnumber) {
      if (current_element === "" || current_element === "react-select-6-input") {

        MovingProcess && handlePreparedByName({ value: code });
      } else {
        alert("Invalid Input");
        return false;
      }
    } else {
      if (code.includes("RMI")) {
        if (current_element === "" || current_element === "react-select-4-input") {
          MldNme && handleRmiCde({ value: code })
          // MovingProcess && GetSelectValue3(code, "rm_details_description_dropdown", setRmDropdown);
        } else {
          alert("Invalid Input");
          return false;
        }
      } else if (code.includes("CUS")) {
        alert("Invalid Input");
        return false;
      } else if (code.includes("FG")) {
        if (current_element === "" || current_element === "react-select-3-input") {
          MovingProcess && GetSelectValue2(code, "fg_details_only_code_dropdown", setFgDropdown);
        } else {
          alert("Invalid Input");
          return false;
        }
      } else if (code.includes("IMM")) {
        alert("Invalid Input");
        return false;
      } else if (code.includes("SUP")) {
        alert("Invalid Input");
        return false;
      } else {
        MovingProcess && GetSelectValue1(code, "Get_part_from_smp", setPartDropdown);
        GetRawMaterial(code);

      }
    }
  }
  function GetSelectValue(value, keyword, setName) {
    if (value.length > 0) {
      ReadRecord({
        _keyword_: keyword,
        key: "%" + value + "%",
        value: "%" + value + "%"
      }).then((res) => {
        // console.log(res.data);
        setName(res.data);
        // setKey(keyvalue[options.reference]);
      });
    }
  }
  function GetSelectValue1(value, keyword, setName) {
    ReadRecord({
      _keyword_: keyword,
      key: "%" + value + "%",
      value: "%" + value + "%"
    }).then((res) => {
      setName(res.data);
      if (res.rows > 0) {
        setMldNme({ value: res.data[0].mc_part_code, label: res.data[0].mc_part_name });
        setPartNo(res.data[0].mc_part_code);
      } else {
        alert("Not Available")
      }
    });
  }
  function GetSelectValue2(value, keyword, setName) {
    ReadRecord({
      _keyword_: keyword,
      key: "%" + value + "%",
      value: "%" + value + "%"
    }).then((res) => {
      setName(res.data);
      if (res.rows > 0) {
        setItmCde({ value: res.data[0].Fg_code, label: res.data[0].Fg_code });
      } else {
        alert("Not Available")
      }
    });
  }
  // function GetSelectValue3(value, keyword, setName) {
  //   ReadRecord({
  //     _keyword_: keyword,
  //     key: "%" + value + "%",
  //     value: "%" + value + "%"
  //   }).then((res) => {
  //     setName(res.data);
  //     if (res.rows > 0) {
  //       setRmiCde({ value: res.data[0].Rm_code, label: res.data[0].Rm_code });
  //       setRmNme(res.data[0].Rm_description);
  //     } else {
  //       alert("Not Available")
  //     }
  //   });
  // }

  return (
    <div>
      <form
        id="4MReport-formContainer-Create"
        name="4MReport-formContainer-Create"
        className="form-4MReport-Create"
      >
        <div ref={scrollTop}>
          <table style={{ textAlign: "center", width: "100%" }}>
            <tbody>
              <tr>
                {/* <th style={{ textAlign: "center", width: "15%" }}>
                  <img src={Unitechlogo} style={{ width: "70px" }}></img>
                </th> */}
                <th
                  id={`${id}-Title`}
                  name={`${name}-Title`}
                  colSpan="7"
                  style={{
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    padding: "10px 0px",

                  }}
                >
                  {/* <font style={{ fontSize: "24px" }}>
                    UNITECH PLASTO COMPONENTS (P) LTD
                  </font>
                  <br></br> */}
                  <font color="#8B0000">4M Report</font>
                </th>
              </tr>
              <tr>
                <td>
                  <p className="topic-head">
                    Moving Process:<span style={{ color: "red" }}>*</span>
                  </p>

                </td>
                <td colSpan={3}>
                  <Select
                    id="MovingProcess.Create"
                    name="MovingProcess.Create"
                    value={MovingProcess}
                    onChange={(e) => {
                      // console.log("Event:", e);
                      setMovingProcess(e);
                      setchangeprocess(() => {
                        let data = []
                        e.map(item => {
                          data.push(item.id)
                        })
                        return data
                      })
                    }}
                    style={{ width: "95%", textAlign: "center" }}
                    isMulti
                    options={options}
                  />


                  {MovingProcess === "" && isCreating && (
                    <span className="Req">Required</span>
                  )}
                </td>
                <td className="topic-head">Remarks</td>
                <td colSpan={3}>
                  <textarea
                    rows={3}
                    cols={2}
                    value={MovingProcessRemarks}
                    onChange={(e) => setMovingProcessRemarks(e.target.value)}
                    disabled={MovingProcess === ""}
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">&nbsp;&nbsp;Mould Name</td>
                <td colSpan={3}>
                  <Select
                    id={`${id}-MouldName`}
                    name={`${name}-MouldName`}
                    value={MldNme}
                    onChange={handleMldNme}
                    onInputChange={(event) => { GetSelectValue(event, "Get_part_from_smp", setPartDropdown) }}
                    options={partNoOptions}
                    placeholder="Search Part Name..."
                    isDisabled={MovingProcess === ""}
                  />
                </td>

                <td className="topic-head">&nbsp;&nbsp;No Of Cavity</td>
                <td colSpan={2}>
                  <input


                    id={`${id}-NoOfCvty`}
                    name={`${name}-NoOfCvty`}
                    type="number"
                    value={NoOfCvty}
                    onChange={(e) => setNoOfCvty(e.target.value)}
                    disabled={MovingProcess === ""}

                  />{" "}
                </td>
              </tr>
              <tr>
                <td className="topic-head">&nbsp;&nbsp;Part No</td>
                <td colSpan={3}>
                  {/* <input 
                    disabled={MovingProcess === ""}

                id={`${id}-PartNo`}
                name={`${name}-PartNo`}
                  type="text"
                  value={PartNo}
                  onChange={(e) => setPartNo(e.target.value)}
                  disabled
                /> */}
                  {/* <DropdownFetcher
                    keyword="mac_part_dtl_dropdown"
                    setValue={setPartDropdown}
                  /> */}
                  <input
                    id={`${id}-PartNo`}
                    name={`${name}-PartNo`}
                    type="text"
                    value={PartNo}
                    onChange={(e) => setPartNo(e.target.value)}
                    disabled
                  />

                </td>

                <td className="topic-head">&nbsp;&nbsp;No Of Cavity Run</td>
                <td colSpan={3}>
                  <input


                    id={`${id}-NoOfCvtyRun`}
                    name={`${name}-NoOfCvtyRun`}
                    type="number"
                    value={NoOfCvtyRun}
                    onChange={(e) => setNoOfCvtyRun(e.target.value)}
                    disabled={MovingProcess === ""}

                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">&nbsp;&nbsp;Item Code</td>
                <td colSpan={3}>
                  {/* <DropdownFetcher
                    keyword="fg_details_only_code_dropdown"
                    setValue={setFgDropdown}
                  /> */}
                  <Select
                    id={`${id}-ItmCde`}
                    name={`${name}-ItmCde`}
                    value={ItmCde}
                    onChange={handleItemCode}
                    onInputChange={(event) => { GetSelectValue(event, "fg_details_only_code_dropdown", setFgDropdown) }}
                    options={fgOptions}
                    placeholder="Search Item Code..."
                    isDisabled={MovingProcess === ""}

                  />
                  {/* <input 
                    disabled={MovingProcess === ""}

                id={`${id}-ItmCde`}
                name={`${name}-ItmCde`}
                  type="text"
                  value={ItmCde}
                  onChange={handleItemCode}
                  
                /> */}
                </td>

                <td className="topic-head">&nbsp;&nbsp;Tonnage</td>
                <td colSpan={2}>
                  <input
                    disabled={MovingProcess === ""}

                    id={`${id}-Tonnage`}
                    name={`${name}-Tonnage`}
                    type="number"
                    value={Tonnage}
                    onChange={(e) => setTonnage(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">&nbsp;&nbsp;Rmi Name</td>
                <td colSpan={3}>
                  {/* <DropdownFetcher
                    keyword="rm_details_description_dropdown"
                    setValue={setRmDropdown}
                  /> */}

                  <Select
                    id={`${id}-RmNme`}
                    name={`${name}-RmNme`}
                    value={RmNme}
                    onChange={handleRmiCde}
                    // onInputChange={(event) => { GetSelectValue(event, "rm_details_description_dropdown", setRmDropdown) }}
                    options={rmOptions}
                    placeholder="Search RMI Name.."
                    isDisabled={MovingProcess === ""}

                  />
                  {/* <input 
                    disabled={MovingProcess === ""}

                id={`${id}-RmiCde`}
                name={`${name}-RmiCde`}
                  type="text"
                  value={RmiCde}
                  onChange={(e) => setRmiCde(e.target.value)}

                /> */}
                </td>

                <td className="topic-head">&nbsp;&nbsp;Cycle Code</td>
                <td colSpan={2}>
                  <input
                    disabled={MovingProcess === ""}

                    id={`${id}-CyleCde`}
                    name={`${name}-CyleCde`}
                    type="text"
                    value={CyleCde}
                    onChange={(e) => setCyleCde(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">&nbsp;&nbsp;Rm Code</td>
                <td colSpan={3}>
                  <input
                    id={`${id}-RmiCde`}
                    name={`${name}-RmiCde`}
                    type="text"
                    value={RmiCde}
                    onChange={(e) => setRmiCde(e.target.value)}
                    disabled
                  />
                </td>

                <td className="topic-head">&nbsp;&nbsp;Part Weight(gm)</td>
                <td colSpan={2}>
                  <input
                    disabled={MovingProcess === ""}

                    id={`${id}-PrtWeght`}
                    name={`${name}-PrtWeght`}
                    type="number"
                    value={PrtWeght}
                    onChange={(e) => setPrtWeght(e.target.value)}
                  />
                </td>
              </tr>

              <tr>
                <th colSpan={4} style={{ textAlign: "center" }}>
                  MONTH PLAN
                </th>
                <th colSpan={3} style={{ textAlign: "center" }}>
                  MATERIAL PLAN
                </th>
              </tr>
              <tr>
                <td className="topic-head">&nbsp;&nbsp;Plan</td>
                <td colSpan={3}>
                  <input
                    disabled={MovingProcess === ""}

                    id={`${id}-Plan`}
                    name={`${name}-plan`}
                    type="text"
                    value={Plan}
                    onChange={(e) => setPlan(e.target.value)}
                  />
                </td>
                <td className="topic-head">&nbsp;&nbsp;Material</td>
                <td colSpan={2}>
                  <input
                    disabled={MovingProcess === ""}

                    id={`${id}-Material`}
                    name={`${name}-Material`}
                    type="text"
                    value={Material}
                    onChange={(e) => setMaterial(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">&nbsp;&nbsp;Receipt</td>
                <td colSpan={3}>
                  <input
                    disabled={MovingProcess === ""}

                    id={`${id}-Receipt1`}
                    name={`${name}-Receipt1`}
                    type="text"
                    value={Receipt1}
                    onChange={(e) => setReceipt1(e.target.value)}
                  />
                </td>
                <td className="topic-head">&nbsp;&nbsp;Receipt</td>
                <td colSpan={2}>
                  <input
                    disabled={MovingProcess === ""}

                    id={`${id}-Receipt2`}
                    name={`${name}-Receipt2`}
                    type="text"
                    value={Receipt2}
                    onChange={(e) => setReceipt2(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">&nbsp;&nbsp;Balance</td>
                <td colSpan={3}>
                  <input
                    disabled={MovingProcess === ""}

                    id={`${id}-Balance1`}
                    name={`${name}-Balance1`}
                    type="text"
                    value={Balance1}
                    onChange={(e) => setBalance1(e.target.value)}
                  />
                </td>
                <td className="topic-head">&nbsp;&nbsp;Balance</td>
                <td colSpan={2}>
                  <input
                    disabled={MovingProcess === ""}

                    id={`${id}-Balance2`}
                    name={`${name}-Balance2`}
                    type="text"
                    value={Balance2}
                    onChange={(e) => setBalance2(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="topic-head">Packing Std</p>
                </td>
                <td colSpan={3}>
                  <textarea
                    id={`${id}-PackingStd`}
                    name={`${name}-PackingStd`}
                    value={PackingStd}
                    onChange={(e) => setPackingStd(e.target.value)}
                    disabled={MovingProcess === ""}

                  />
                </td>
                <td colSpan={5}></td>
              </tr>
              <tr>
                <th colSpan={4} style={{ textAlign: "center" }}>
                  FROM
                </th>
                <th colSpan={3} style={{ textAlign: "center" }}>
                  TO
                </th>
              </tr>
              <tr>
                <td colSpan={4}>
                  <input
                    disabled={MovingProcess === ""}

                    id={`${id}-From`}
                    name={`${name}-From`}
                    type="text"
                    value={From}
                    onChange={(e) => setFrom(e.target.value)}
                  />

                  {/* <input 
                    disabled={MovingProcess === ""}

                id={`${id}-From`}
                name={`${name}-From`}
                    type="file"
                    accept="image/*"
                    capture="camera"
                    onChange={(e) => handlePhotoChangeFrom(e)} 
                   />
                   {From && <img src={From} alt="Selected" style={{ display: 'block', margin: '0 auto',
                   maxWidth: '150px', height: '150px' }} />} */}
                </td>
                <td colSpan={3}>
                  <input
                    disabled={MovingProcess === ""}

                    id={`${id}-To`}
                    name={`${name}-To`}
                    type="text"
                    value={To}
                    onChange={(e) => setTo(e.target.value)}
                  />

                  {/* <input 
                    disabled={MovingProcess === ""}

                id={`${id}-To`}
                name={`${name}-To`}
                    type="file"
                    accept="image/*"
                    capture="camera"
                    onChange={(e) => handlePhotoChangeTo(e)} 
                   />
                   {To && <img src={To} alt="Selected" style={{ display: 'block', margin: '0 auto',
                   maxWidth: '150px', height: '150px' }} />} */}
                </td>
              </tr>
              <tr>
                <td colSpan={7}>
                  <span>
                    Note:
                    <ol>
                      <li>Kindly Attached Mold Inspection Report</li>
                      <li>kindly Attached Sample Part</li>
                      <li>Kindly Attached Proper Packing Standard Report</li>
                      <li>Kindly Attached Quality Plan</li>
                      <li>If You Ignored, We can't Accept The Report</li>
                    </ol>
                  </span>
                </td>
              </tr>
              <tr>
                <th colSpan={2} style={{ textAlign: "center" }}>
                  INHOUSE STOCK DETAILS
                </th>
                <td rowSpan={5} colSpan={3}>
                  <p className="topic-head">Production Head<span style={{ color: "red" }}>*</span></p>

                  <DropdownFetcher
                    keyword="emp_details_hod"
                    setValue={setHODEmployeeDropdown}
                  />
                  <DropdownFetcher
                    keyword="emp_details_dropdown_all"
                    setValue={setEmployeeDropdown}
                  />
                  <Select
                    id={`${id}-ProductionHead`}
                    name={`${name}-ProductionHead`}
                    value={ProductionHead}
                    onChange={handleProductionHead}
                    options={empHODSetterOptions}
                    placeholder="Select Production Head"
                    isDisabled={MovingProcess === ""}

                  />
                  {ProductionHead === "" && isCreating && (
                    <span className="Req">Required</span>
                  )}
                </td>
                <th colSpan={2} style={{ textAlign: "center" }}>
                  SUPPLIER STOCK DETAILS
                </th>
              </tr>
              <tr>
                <td className="topic-head">&nbsp;&nbsp;Fg Stock</td>
                <td>
                  <input
                    disabled={MovingProcess === ""}

                    id={`${id}-FgStock`}
                    name={`${name}-FgStock`}
                    type="number"
                    value={FgStock}
                    onChange={(e) => setFgStock(e.target.value)}
                  />
                </td>
                <td className="topic-head">&nbsp;&nbsp;Fgw Stock</td>
                <td colSpan={2}>
                  <input
                    disabled={MovingProcess === ""}

                    id={`${id}-FgwStock`}
                    name={`${name}-FgwStock`}
                    type="number"
                    value={FgwStock}
                    onChange={(e) => setFgwStock(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">&nbsp;&nbsp;Semi Stock</td>
                <td>
                  <input
                    disabled={MovingProcess === ""}

                    id={`${id}-SmiStock1`}
                    name={`${name}-SmiStock1`}
                    type="number"
                    value={SmiStock1}
                    onChange={(e) => setSmiStock1(e.target.value)}
                  />
                </td>
                <td className="topic-head">&nbsp;&nbsp;Semi Stock</td>
                <td colSpan={2}>
                  <input
                    disabled={MovingProcess === ""}

                    id={`${id}-SmiStock2`}
                    name={`${name}-SmiStock2`}
                    type="number"
                    value={SmiStock2}
                    onChange={(e) => setSmiStock2(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">&nbsp;&nbsp;total Stock</td>
                <td>
                  <input
                    disabled={MovingProcess === ""}

                    id={`${id}-TtlStock1`}
                    name={`${name}-TtlStock1`}
                    type="number"
                    value={TtlStock1}
                    onChange={(e) => setTtlStock1(e.target.value)}
                  />
                </td>
                <td className="topic-head">&nbsp;&nbsp;Total Stock</td>
                <td colSpan={2}>
                  <input
                    disabled={MovingProcess === ""}

                    id={`${id}-TtlStock2`}
                    name={`${name}-TtlStock2`}
                    type="number"
                    value={TtlStock2}
                    onChange={(e) => setTtlStock2(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">&nbsp;&nbsp;No Days Coverage</td>
                <td>
                  <input
                    disabled={MovingProcess === ""}

                    id={`${id}-NodysCov1`}
                    name={`${name}-NodysCov1`}
                    type="number"
                    value={NodysCov1}
                    onChange={(e) => setNodysCov1(e.target.value)}
                  />
                </td>
                <td className="topic-head">&nbsp;&nbsp;No Days Coverage</td>
                <td colSpan={2}>
                  <input
                    disabled={MovingProcess === ""}

                    id={`${id}-NodysCov2`}
                    name={`${name}-NodysCov2`}
                    type="number"
                    value={NodysCov2}
                    onChange={(e) => setNodysCov2(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="topic-head">Prepared By<span style={{ color: "red" }}>*</span></p>

                  <Select
                    id={`${id}-PreparedByName`}
                    name={`${name}-PreparedByName`}
                    value={PreparedByName}
                    onChange={handlePreparedByName}
                    options={empMoldSetterOptions}
                    placeholder="Select PreparedBy Name"
                    isDisabled={MovingProcess === ""}

                  />
                  {PreparedByName === "" && isCreating && (
                    <span className="Req">Required</span>
                  )}
                </td>
                <td>
                  <p className="topic-head">Vendor Head<span style={{ color: "red" }}>*</span></p>

                  <DropdownFetcher
                    keyword="supplier_details_dropdown"
                    setValue={setVendorDropdown}
                  />


                  <Select
                    id={`${id}-VendarName`}
                    name={`${name}-VendarName`}
                    value={VendarName}
                    onChange={handleVendarName}
                    options={vendorOptions}
                    placeholder="Select Vendar Name"
                    isDisabled={MovingProcess === ""}
                  />
                  {VendarName === "" && isCreating && (
                    <span className="Req">Required</span>
                  )}
                </td>
                <td colSpan={2}>
                  <p className="topic-head">Purchase Head<span style={{ color: "red" }}>*</span></p>

                  <Select
                    id={`${id}-PurchaseName`}
                    name={`${name}-PurchaseName`}
                    value={PurchaseName}
                    onChange={handlePurchaseName}
                    options={empMoldSetterOptions}
                    placeholder="Select Purchase Head"
                    isDisabled={MovingProcess === ""}
                  />
                  {PurchaseName === "" && isCreating && (
                    <span className="Req">Required</span>
                  )}
                </td>
                <td>
                  <p className="topic-head">Customer Quality<span style={{ color: "red" }}>*</span></p>

                  <Select
                    id={`${id}-CustomerQualityName`}
                    name={`${name}-CustomerQualityName`}
                    value={CustomerQualityName}
                    onChange={handleCustomerQualityName}
                    options={empMoldSetterOptions}
                    placeholder="Select Customer Quality"
                    isDisabled={MovingProcess === ""}
                  />
                </td>
                <td>
                  <p className="topic-head">Production Quality<span style={{ color: "red" }}>*</span></p>

                  <Select
                    id={`${id}-ProductionQualityName`}
                    name={`${name}-ProductionQualityName`}
                    value={ProductionQualityName}
                    onChange={handleProductionQualityName}
                    options={empMoldSetterOptions}
                    placeholder="Select Production Quality"
                    isDisabled={MovingProcess === ""}
                  />
                  {ProductionQualityName === "" && isCreating && (
                    <span className="Req">Required</span>
                  )}
                </td>
                <td>
                  <p className="topic-head">Tool Room Sign<span style={{ color: "red" }}>*</span></p>

                  <Select
                    id={`${id}-ToolRoomSign`}
                    name={`${name}-ToolRoomSign`}
                    value={ToolRoomSign}
                    onChange={handleToolRoomSign}
                    options={empMoldSetterOptions}
                    placeholder="Select Tool Room Sign"
                    isDisabled={MovingProcess === ""}
                  />
                  {ToolRoomSign === "" && isCreating && (
                    <span className="Req">Required</span>
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <p className="topic-head">Store/Material<span style={{ color: "red" }}>*</span></p>
                  <Select
                    id={`${id}-StoreMaterial`}
                    name={`${name}-StoreMaterial`}
                    value={StoreMaterial}
                    onChange={handleStoreMaterial}
                    options={empMoldSetterOptions}
                    placeholder="Select Name"
                    isDisabled={MovingProcess === ""}
                  />
                  {StoreMaterial === "" && isCreating && (
                    <span className="Req">Required</span>
                  )}
                </td>
                <td colSpan={3}>
                  <p className="topic-head">Director Sign<span style={{ color: "red" }}>*</span></p>
                  <Select
                    id={`${id}-DirectorSign`}
                    name={`${name}-DirectorSign`}
                    value={DirectorSign}
                    onChange={handleDirectorSign}
                    options={empMoldSetterOptions}
                    placeholder="Select Director Sign"
                    isDisabled={MovingProcess === ""}
                  />
                  {DirectorSign === "" && isCreating && (
                    <span className="Req">Required</span>
                  )}
                </td>
                <td colSpan={2}>
                  <p className="topic-head">SQA Sign<span style={{ color: "red" }}>*</span></p>
                  <Select
                    id={`${id}-SqaSign`}
                    name={`${name}-SqaSign`}
                    value={SqaSign}
                    onChange={handleSqaSign}
                    options={empMoldSetterOptions}
                    placeholder="Select SQA Name"
                    isDisabled={MovingProcess === ""}
                  />
                  {SqaSign === "" && isCreating && (
                    <span className="Req">Required</span>
                  )}
                </td>
              </tr>

              <tr>
                <td colSpan="7" align="center" style={{ border: "none" }}>
                  <button
                    disabled={ProductionHead === "" || MovingProcess === ""}
                    hidden={ProductionHead === "" || MovingProcess === ""}
                    type="submit"
                    id={`${id}-submit`}
                    name={`${name}-submit`}
                    onClick={(e) => handleCreate(e)}

                  >
                    Submit
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  );
}

export default Report4MCreate;
