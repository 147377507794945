import axios from 'axios';
import { useEffect } from 'react';
import { API_URL } from "../Utils/constants";

const handleRead = ({keyword,setData}) => {
    useEffect(() => {
    const data = {
      _keyword_: keyword,
    };
    
    axios
  .post(
    `http://${API_URL}/GenericResultBuilderService/buildResults`,
    data
  )
  .then((response) => {
    if (response.data && response.data.success === 'true') {
      
      response.data.data.reverse();
      setData(response.data.data);
      console.log(keyword, response.data.data);
    } else {
     
      console.error("API Error:", response.data.message);
    }
  })
  .catch((error) => {
   
    console.error("Error:", error);
  });

    },[keyword , setData]);
    return null; 
  };
  export default handleRead;