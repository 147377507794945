import React, { useEffect, useState } from "react";
import axios from "axios";
import Popup from "../../../Components/Popup/Popup";
import { API_URL } from "../../../Utils/constants";

import Unitechlogo from "../../../icons/Unitechlogo.jpeg";
import Select from "react-select";
import Tooltip from "rc-tooltip";
import Pagination from "../../../Components/Pagination";
import HandleRead from "../../../Lib/HandleRead.js";
import DatePicker from "../../../Lib/DatePicker";
import { formatDt } from "../../../Lib/formatDt";
import DropdownFetcher from "../../../Lib/DropdownFetcher";
import GetTableRecord from '../../../Lib/GetTableRecord'

import "../../../Styles/MaterialLoading.css";
import { useNavigate, useParams } from "react-router-dom";
import GetRecord from "../../../Lib/GetTableRecord";
import { FaBackspace } from 'react-icons/fa';
import { GetAccess } from "../../../Lib/GetAccess";
//import "bootstrap/dist/css/bootstrap.min.css";
import UpdateRecord from "../../../Lib/updateRecord";
import { MdDelete } from "react-icons/md";
import ReadRecord from "../../../Lib/API/ReadRecord.js";

function MaterialLoadingView() {


  const [back, setback] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [ENC, setENC] = useState("");
  useEffect(() => {
    console.log(id);
    if (id !== undefined) {
      GetRecord(
        {
          _keyword_: "material_loading_by_ID",
          Id: id,
        },
        setENC
      ).then(async (res) => {
        console.log("res - ", res);
        handleView(res.data[0])
        setButtonPopup2(true)
        await timeout(1000);
        setback(true)
        const popupDiv = document.querySelector(".popup");
        if (popupDiv) {
          const elementInsidePopup = popupDiv.querySelector("#close-btn");
          elementInsidePopup.style.display = "none";
        }
      })
    }
  }, [id])
  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }


  const handleDateChange = (newDate) => {
    setTrackDate(newDate);
  };
  const [isCreating, setIsCreating] = useState(false);
  const [editedRowId, setEditedRowId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [matlot, setmatlot] = useState([]);
  const [data, setdata] = useState([]);
  const [Material, setMaterial] = useState([]);
  const [Shift, setShift] = useState("");
  const [TrackDate, setTrackDate] = useState("");
  const [MatNme, setMatNme] = useState("");
  const [Grade, setGrade] = useState("");
  const [MatLotNo, setMatLotNo] = useState("");
  const [MatLdKg, setMatLdKg] = useState("");
  const [MatLdTme, setMatLdTme] = useState("");
  const [PreHtHrs, setPreHtHrs] = useState("");
  const [PreHtTmp, setPreHtTmp] = useState("");
  const [Inchrge, setInchrge] = useState("");
  const [QcSign, setQcSign] = useState("");
  const [Id, setId] = useState("");
  const [Moisture, setMoisture] = useState("");
  const [MachineNo, setMachineNo] = useState("");
  const [DryTemp, setDryTemp] = useState("");
  const [DryTempTolerance, setDryTempTolerance] = useState("");
  const [MinHours, setMinHours] = useState("");
  const [MaxHours, setMaxHours] = useState("");
  var [mat_load, setmat_load] = useState([]);
  var [NotificationListViewer, setNotificationListViewer] = useState([]);
  const [ButtonPopup2, setButtonPopup2] = useState(false);
  const [ButtonPopup, setButtonPopup] = useState(false);

  const [isPaginationVisible, setPaginationVisible] = useState(true);
  const [editableRow, setEditableRow] = useState(null);
  const [employee, setemployee] = useState([]);
  const [dropdown, setDropdown] = useState([]);
  const [EmployeeDropdown, setEmployeeDropdown] = useState([]);

  const idEdit = "MaterialLoading-edit";
  const nameEdit = "MaterialLoading-edit";
  const empMoldSetterOptions = EmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const getDropdown = () => {
    axios
      .post(`http://${API_URL}/GenericResultBuilderService/buildResults`, {
        _keyword_: "mac_dtl_dropdown",
      })
      .then((response) => {
        setDropdown(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getDropdown();

  }, []);


  const handleView = (e) => {
    // GetTableRecord({_keyword_:"material_loading_by_ID",Id:e.Id}).then(res=>{
    //   let values = data.filter((item) => item["Mat_name"] === e.Mat_name);
    // values = values.map((item) => item["Mat_lot_no"]);
    //   setmatlot(values);
    //   setPaginationVisible(false);
    //   setButtonPopup2(true);

    //   setTrackDate(res.data[0].TrackDate);
    //   setMatNme(res.data[0].Mat_name);
    //   setGrade(res.data[0].Mat_grde);
    //   setMatLotNo(res.data[0].Mat_lot_no);
    //   setMatLdKg(res.data[0].Mat_ld_kgs);
    //   setMatLdTme(res.data[0].Mat_ld_time);
    //   setShift(res.data[0].Shift);
    //   setPreHtHrs(res.data[0].Pre_ht_hrs);
    //   setPreHtTmp(res.data[0].Pre_ht_tmp);
    //   setInchrge(res.data[0].In_chrge+"-"+res.data[0].InChargeName);
    //   setQcSign(res.data[0].Qc_sign+"-"+res.data[0].QaName);
    //   setMoisture(res.data[0].Moisture);
    //   setMachineNo(res.data[0].Machine_no);
    // })
    let values = data.filter((item) => item["Mat_name"] === e.Mat_name);
    values = values.map((item) => item["Mat_lot_no"]);
    setmatlot(values);
    setPaginationVisible(false);
    setButtonPopup2(true);
    setTrackDate(e.TrackDate);
    setMatNme(e.RmDescrip);
    setGrade(e.Mat_grde);
    setMatLotNo(e.Mat_lot_no);
    setMatLdKg(e.Mat_ld_kgs);
    setMatLdTme(e.Mat_ld_time);
    setShift(e.Shift);
    setPreHtHrs(e.Pre_ht_hrs);
    setPreHtTmp(e.Pre_ht_tmp);
    setInchrge(`${e.In_chrge}-${e.InchargeName}`);
    setQcSign(`${e.Qc_sign}-${e.QaName}`);
    setMoisture(e.Moisture);
    setMachineNo(e.Machine_no);
    setDryTemp(e.DryTemp);
    setDryTempTolerance(e.DryTempTolerance);
    setMinHours(e.MinHours);
    setMaxHours(e.MaxHours);
  };

  const viewPagination = () => {
    setPaginationVisible(true);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 20;

  const [filterDate, setFilterDate] = useState("");
  const [filterMcno, setFilterMcno] = useState("");
  const [filterMachine, setFilterMachine] = useState("");
  const filterRecords = () => {
    const filteredData = mat_load.filter((record) => {
      const dateMatch =
        record.TrackDate && record.TrackDate.includes(filterDate);
      const mcnoMatch =
        record.RmDescrip &&
        record.RmDescrip.toLowerCase().includes(filterMcno.toLowerCase());
      const machineMatch =
        record.Machine_no && record.Machine_no.toLowerCase().includes(filterMachine.toLowerCase());
      return dateMatch && mcnoMatch && machineMatch;
    });

    return filteredData;
  };

  const filteredData = filterRecords();
  const npage = Math.ceil(filteredData.length / recordPerPage);
  const numbers = [...Array(npage).keys()].map((num) => num + 1);

  function handleFilterDateChange(event) {
    setFilterDate(event.target.value);
    setCurrentPage(1);
  }

  function handleFilterMcnoChange(event) {
    setFilterMcno(event.target.value);
    setCurrentPage(1);
  }
  function handleFilterMachineChange(event) {
    setFilterMachine(event.target.value);
    setCurrentPage(1);
  }
  const handleMatLotChange = (event) => {

    const selectedMatLot = event.target.value;
    setMatLotNo(selectedMatLot);
  };
  const firstIndex = (currentPage - 1) * recordPerPage;
  const lastIndex = firstIndex + recordPerPage;
  const filteredRecords = filteredData.slice(firstIndex, lastIndex);
  function prePage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function nextPage() {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function changeCpage(Id) {
    setCurrentPage(Id);
  }

  const handleEdit = (e) => {
    let values = data.filter((item) => item["Mat_name"] === e.Mat_name);
    values = values.map((item) => item["Mat_lot_no"]);

    // let  values = Material.filter((item) => item["Rm_code"] === e.Mat_name);
    // console.log(values);
    // console.log(e.Mat_name);
    //   values = values.map((item) => item["Rm_lotno"]);
    //   setmatlot(values);

    //  handleFieldChange(e.Mat_name);
    setmatlot(values);
    setPaginationVisible(false);
    setButtonPopup(true);
    setId(e.Id);
    setTrackDate(e.TrackDate);
    setMatNme(e.RmDescrip);
    setGrade(e.Mat_grde);
    setMatLotNo(e.Mat_lot_no);
    console.log(e.Mat_lot_no);
    setMatLdKg(e.Mat_ld_kgs);
    setMatLdTme(e.Mat_ld_time);
    setShift(e.Shift);
    setPreHtHrs(e.Pre_ht_hrs);
    setPreHtTmp(e.Pre_ht_tmp);
    setInchrge({ value: e.In_chrge, label: e.InchargeName });
    setQcSign({ value: e.Qc_sign, label: e.QaName });
    setMoisture(e.Moisture);
    setMachineNo(e.Machine_no);
    setDryTemp(e.DryTemp);
    setDryTempTolerance(e.DryTempTolerance);
    setMinHours(e.MinHours);
    setMaxHours(e.MaxHours);
    ReadRecord({
      _keyword_: "rm_lot_by_rm_code",
      key: e.Mat_name
    }).then((res) => {
      console.log("RmLotData", res);
      const RmLotData = res.data.map(item => item.Rm_lotno)
      setmatlot(RmLotData);
    })

  };
  const handleUpdate = (e) => {
    e.preventDefault();

    if (!MatNme || !Grade) {
      setIsCreating(true);
      alert("Please fill the mandatory fields");
      return;
    }
    const data = {
      data: [
        {
          Machine_no: MachineNo,
          Id: Id,
          TrackDate: TrackDate,
          // Mat_name: MatNme,
          Mat_grde: Grade,
          Mat_lot_no: MatLotNo,
          Mat_ld_kgs: MatLdKg,
          Mat_ld_time: MatLdTme,
          Shift: Shift,
          Pre_ht_hrs: PreHtHrs,
          Pre_ht_tmp: PreHtTmp,
          In_chrge: Inchrge.value,
          Qc_sign: QcSign.value,
          Moisture: Moisture,
        },
      ],
    };
    data["_keyword_"] = "material_loading";
    data["secretkey"] = "2bf52be7-9f68-4d52-9523-53f7f267153b";
    axios
      .post(
        `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
        data
      )
      .then((response) => {
        setmat_load([...mat_load, response.data]);
        alert(`${MatNme} Updated`);

        handleReadMaterialLoading();
        setButtonPopup(false);
        setPaginationVisible(true);


        // window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        alert("Submission failed!");
        window.location.reload();

      });

  };
  // const handleEdit1 = (mat_load) => {

  //   setEditedRowId(mat_load.Id);
  //   setIsEditing(true);
  //   setId(mat_load.Id);
  //   setQcSign(mat_load.Qc_sign);

  // };

  // const handleCancelEdit = () => {

  //   setQcSign(mat_load.Qc_sign);
  //   setIsEditing(false);
  //   setEditedRowId(null);
  // };
  // const handle1 = (e) => {
  //   setIsEditing(false);
  //   setEditedRowId(null);
  //   if (editableRow !== null) {

  //     console.log("Edited QcSign:", QcSign);
  //     setEditableRow(null);
  //   }

  //   if (!QcSign) {
  //     alert("Please fill the mandatory fields");
  //     return;
  //   }
  //   const data = {
  //     data: [
  //       {
  //         Id: Id,
  //         Qc_sign: QcSign,

  //       },
  //     ],
  //   };
  //   data["_keyword_"] = "material_loading";
  //   data["secretkey"] = "2bf52be7-9f68-4d52-9523-53f7f267153b";
  //   axios
  //     .post(
  //       `http://${API_URL}/GenericTransactionService/processTransactionForUpdate`,
  //       data
  //     )
  //     .then((response) => {
  //       setmat_load([...mat_load, response.data]);
  //       alert(`${QcSign} Updated`);
  //       window.location.reload();
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       alert("Submission failed!")

  //       window.location.reload();
  //     });
  // };

  // function handleFieldChange(code) {
  //   let values = Material.find((key) => key["Rm_code"] === code);
  //   let datetime = new Date();
  //   let date = new Date().toISOString().split("T")[0]
  //   let time = new Date().toTimeString().substring(0, 5)
  //   console.log(values);
  //   //Set values to respective fields
  //   // setTrackDate(date);
  //   setMatNme(values["Rm_code"]);
  //   setMatLdTme(time);
  //   setPreHtTmp(values.Rm_preheattemp ? values.Rm_preheattemp : "")
  //   setPreHtHrs(values.Rm_preheathrs ? values.Rm_preheathrs : "")
  //   setGrade(values.Rm_grade ? values.Rm_grade : "")

  //   // select the shift
  //   if (datetime.getHours() < 18 && datetime.getMinutes < 59) {
  //     setShift("B");
  //   } else {
  //     setShift("A");
  //   }
  //   // select the value for the material lot
  //   values = Material.filter((item) => item["Rm_code"] === code);
  //   values = values.map((item) => item["Rm_lotno"]);
  //   setmatlot(values);
  // }
  // auto populate employee names
  function setEmployeeName(code) {
    const selected = employee.find(
      (option) => option.Emp_code === code
    );
    if (selected) {
      setInchrge({ value: selected.Emp_code, label: selected.Emp_name });
    }

  }
  function setQAName(code) {

    const selected = employee.find(
      (option) => option.Emp_code === code
    );
    if (selected) {
      setQcSign({ value: selected.Emp_code, label: selected.Emp_name });
    }

  }
  function GetSelectValue(value, keyword, setName) {

    if (value.length > 0) {
      ReadRecord({
        _keyword_: keyword,
        key: "%" + value + "%",
        value: "%" + value + "%"
      }).then((res) => {
        console.log(res.data);
        setName(res.data);
        // setKey(keyvalue[options.reference]);
      });
    }
  }
  function handleReadMaterialLoading() {
    ReadRecord({
      _keyword_: "material_loading"
    }).then((res) => {
      // console.log(res.data);
      setmat_load(res.data);
    });
    ReadRecord({
      _keyword_: "Notification_read_unread_emp",
      TableName: "MaterialLoading"
    }).then((res) => {
      // console.log(res);
      setNotificationListViewer(res.data);

    });
  }
  useEffect(() => {
    handleReadMaterialLoading();
  }, [])

  return (
    <div>
      <Popup
        trigger={ButtonPopup2}
        setTrigger={setButtonPopup2}
        viewPagination={viewPagination}
      >
        <br></br>
        {back && <div style={{ fontSize: '16px', fontWeight: 'bolder', backgroundColor: '#86BBd8', width: '90px', padding: '10px', marginLeft: "20px", borderRadius: '10px', border: '2px solid #005C9F', cursor: 'pointer' }} onClick={() => { navigate(-1) }}><FaBackspace size={20} /> Back</div>}
        <br></br>
        <form
          id="MaterialLoading-formContainer-View"
          name="MaterialLoading-formContainer-View"
          className="form-MaterialLoading-View"
        >
          <table style={{ textAlign: "center", width: "100%" }}>
            <tbody>
              <tr>
                <th
                  id="mlcss-view-Title"
                  name="mlcss-view-Title"
                  colSpan="4"
                  style={{ textAlign: "center", whiteSpace: "nowrap" }}
                >
                  <font color="#8B0000">
                    View - Material Loading Check Sheet
                  </font>
                </th>
              </tr>
              <tr>
                <td>

                  &nbsp;&nbsp;Machine No
                  <span style={{ color: "red" }}>*</span>
                </td>
                <td><label>{MachineNo}</label></td>
                <td colSpan={2}></td>
              </tr>
              <tr>
                <td style={{ width: "35%" }}>&nbsp;&nbsp;Date</td>
                <td>
                  <label id="mlcss-view-TrackDate" name="mlcss-view-TrackDate">
                    {formatDt(TrackDate)}
                  </label>
                </td>

                <td>&nbsp;&nbsp;Shift</td>
                <td>
                  <label id="mlcss-view-Shift" name="mlcss-view-Shift">
                    {Shift}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  &nbsp;&nbsp;Material Name
                  <span style={{ color: "red" }}>*</span>
                </td>
                <td>
                  <label
                    id="mlcss-view-MaterialName"
                    name="mlcss-view-MaterialName"
                  >
                    {MatNme}
                  </label>
                </td>

                <td>&nbsp;&nbsp;Grade <span style={{ color: "red" }}>*</span></td>
                <td>
                  <label id="mlcss-view-Grade" name="mlcss-view-Grade">
                    {Grade}
                  </label>
                </td>
              </tr>
              <tr>
                <td>&nbsp;&nbsp;Material Lot No</td>
                <td>
                  <label id="mlcss-view-MatLotNo" name="mlcss-view-MatLotNo">
                    {MatLotNo}
                  </label>
                </td>

                <td>&nbsp;&nbsp;Material Loading in Kgs</td>
                <td>
                  <label id="mlcss-view-MatLdKg" name="mlcss-view-MatLdKg">
                    {MatLdKg}
                  </label>
                </td>
              </tr>
              <tr>
                <td>&nbsp;&nbsp;Material Loading Time</td>
                <td>
                  <label id="mlcss-view-MatLdTme" name="mlcss-view-MatLdTme">
                    {MatLdTme}
                  </label>
                </td>

                <td>&nbsp;&nbsp;Pre Heat Hours
                  (<span>{MinHours}</span>-<span>{MaxHours}</span>)
                </td>
                <td>
                  <label id="mlcss-view-PreHtHrs" name="mlcss-view-PreHtHrs" style={
                    (parseFloat(PreHtHrs) >= parseFloat(MinHours) && parseFloat(PreHtHrs) <= parseFloat(MaxHours) || PreHtHrs == "")

                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }>
                    {PreHtHrs}
                  </label>
                </td>
              </tr>
              <tr>

                <td>&nbsp;&nbsp;Pre Heating Temp (<span>{DryTemp}</span>±<span>{DryTempTolerance}</span>)</td>
                <td>
                  <label id="mlcss-view-PreHtTmp" name="mlcss-view-PreHtTmp" style={
                    !(
                      parseFloat(PreHtTmp) >
                      parseFloat(DryTemp) +
                      parseFloat(DryTempTolerance) ||
                      parseFloat(PreHtTmp) <
                      parseFloat(DryTemp) -
                      parseFloat(DryTempTolerance)
                    )
                      ? { width: "100%" }
                      : {
                        width: "100%",
                        backgroundColor: "#d98984",
                        border: "2px solid #DA251A",
                      }
                  }>
                    {PreHtTmp}
                  </label>
                </td>
                <td>&nbsp;&nbsp;Moisture</td>
                <td>
                  <label id="mlcss-view-Moisture" name="mlcss-view-Moisture">
                    {Moisture}
                  </label>
                </td>
              </tr>
              <tr>
                <td>&nbsp;&nbsp;Incharge Name</td>
                <td>
                  <label id="mlcss-view-Inchrge" name="mlcss-view-Inchrge">
                    {Inchrge}
                  </label>
                </td>

                <td>&nbsp;&nbsp;QC Sign</td>
                <td>
                  <label id="mlcss-view-QcSign" name="mlcss-view-QcSign">
                    {QcSign}
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </Popup>
      <Popup
        trigger={ButtonPopup}
        setTrigger={setButtonPopup}
        viewPagination={viewPagination}
      >
        <br></br>
        <br></br>
        <form
          id="MaterialLoading-formContainer-Edit"
          name="MaterialLoading-formContainer-Edit"
          className="form-MaterialLoading-Edit"
        >
          <table
            className="table"
            style={{ textAlign: "center", width: "100%" }}
          >
            <tbody>
              <tr>
                <th style={{ textAlign: "center" }}>
                  <img src={Unitechlogo} style={{ width: "70px" }}></img>
                </th>
                <th
                  colSpan="4"
                  style={{
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    padding: "10px 0px",
                  }}
                >
                  <font style={{ fontSize: "24px" }}>
                  KASH Tech LLC
                  </font>
                  <br></br>
                  <font color="#8B0000">Material Loading Check Sheet</font>
                </th>
              </tr>
              <tr><td className="topic-head">&nbsp;&nbsp;Machine No
                <span style={{ color: "red" }}>*</span></td>
                <td><input value={MachineNo} type="text" disabled></input></td>
                <td colSpan={2}></td></tr>
              <tr>
                <td className="topic-head">&nbsp;&nbsp;Date</td>
                <td>
                  <DatePicker value={TrackDate} onChange={handleDateChange} dis={true} />
                </td>

                <td className="topic-head">&nbsp;&nbsp;Shift</td>
                <td>
                  <select
                    value={Shift}
                    onChange={(e) => setShift(e.target.value)}
                    style={{ textAlign: "center" }}
                    disabled
                  >
                    <option value="">-- Select --</option>
                    <option value="A">A12</option>
                    <option value="B">B12</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td className="topic-head">
                  &nbsp;&nbsp;Material Name
                  <span style={{ color: "red" }}>*</span>
                </td>
                <td>
                  
                  <label>{MatNme}</label>
                  {/* <DropdownFetcher keyword="rm_details_view" setValue={setMaterial} />
                <Select
                 value={{ value: MatNme, label: MatNme }}
                  options={Material.map((item) => ({
                    value: item.Rm_code,
                    label: item.Rm_code,
                  }))}
                  onChange={(event) => handleFieldChange(event.value)}
               // readOnly
              />  */}
                </td>

                <td className="topic-head">
                  &nbsp;&nbsp;Grade<span style={{ color: "red" }}>*</span>
                </td>
                <td>
                  <input
                    type="text"
                    value={Grade}
                    onChange={(e) => setGrade(e.target.value)}
                  // readOnly={true}
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">&nbsp;&nbsp;Material Lot No</td>
                <td>

                  {/* < DropdownFetcher keyword="rm_details_view" setValue={setMaterial} /> */}
                  <select value={MatLotNo} onChange={handleMatLotChange}>
                    <option value={MatLotNo}>{MatLotNo}</option>
                    {Array.isArray(matlot) ? (
                      matlot.map((item) => (
                        <option key={item}>{item}</option>
                      ))
                    ) : (
                      <option value="">No MatLot data available</option>
                    )}
                  </select>
                </td>

                <td className="topic-head">
                  &nbsp;&nbsp;Material Loading in Kgs
                </td>
                <td>
                  <input
                    type="number"
                    value={MatLdKg}
                    onChange={(e) => setMatLdKg(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">
                  &nbsp;&nbsp;Material Loading Time
                </td>
                <td>
                  <input
                    type="time"
                    value={MatLdTme}
                    onChange={(e) => setMatLdTme(e.target.value)}
                    readOnly
                  />
                </td>

                <td className="topic-head">&nbsp;&nbsp;Pre Heat Hours
                  (<span>{MinHours}</span>-<span>{MaxHours}</span>)</td>
                <td>
                  <input
                    type="number"
                    value={PreHtHrs}
                    onChange={(e) => setPreHtHrs(e.target.value)}
                    // readOnly
                    style={
                      (parseFloat(PreHtHrs) >= parseFloat(MinHours) && parseFloat(PreHtHrs) <= parseFloat(MaxHours) || PreHtHrs == "")

                        ? { width: "100%" }
                        : {
                          width: "100%",
                          backgroundColor: "#d98984",
                          border: "2px solid #DA251A",
                        }
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">&nbsp;&nbsp;Pre Heating Temp (<span>{DryTemp}</span>±<span>{DryTempTolerance}</span>)</td>
                <td>
                  <input
                    type="number"
                    value={PreHtTmp}
                    onChange={(e) => setPreHtTmp(e.target.value)}
                    //  readOnly
                    style={
                      !(
                        parseFloat(PreHtTmp) >
                        parseFloat(DryTemp) +
                        parseFloat(DryTempTolerance) ||
                        parseFloat(PreHtTmp) <
                        parseFloat(DryTemp) -
                        parseFloat(DryTempTolerance)
                      )
                        ? { width: "100%" }
                        : {
                          width: "100%",
                          backgroundColor: "#d98984",
                          border: "2px solid #DA251A",
                        }
                    }
                  />
                </td>
                <td className="topic-head">&nbsp;&nbsp;Moisture</td>
                <td>
                  <input
                    type="number"
                    value={Moisture}
                    onChange={(e) => setMoisture(e.target.value)}
                  // onKeyDown="return false"
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">&nbsp;&nbsp;Incharge Name</td>
                <td>
                  {/* <DropdownFetcher
                    keyword="emp_details"
                    setValue={setemployee}
                  /> */}
                  <Select
                    value={Inchrge}
                    options={employee.map((item) => ({
                      value: item.Emp_code,
                      label: item.Emp_name,
                    }))}
                    onChange={(event) => {
                      setEmployeeName(event.value);
                    }}
                    onInputChange={(event) => { GetSelectValue(event, "emp_details_dropdown", setemployee) }}
                  // isDisabled={true}
                  />
                </td>

                <td className="topic-head">&nbsp;&nbsp;QC Sign</td>
                <td>
                  {/* <DropdownFetcher keyword="emp_details" setValue={setemployee} /> */}
                  <Select
                    value={QcSign}
                    options={employee.map((item) => ({
                      value: item.Emp_code,
                      label: item.Emp_name,
                    }))}
                    onChange={event => { setQAName(event.value) }}
                    onInputChange={(event) => { GetSelectValue(event, "emp_details_dropdown", setemployee) }}
                  />
                </td>
              </tr>

              <tr>
                <td colSpan={4} className="materialLoadingSubmit">
                  <button
                    type="submit"
                    onClick={(e) => handleUpdate(e)}
                  >
                    Update
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </Popup>

      <div>
        <table
          style={{ textAlign: "center", width: "100%" }}
        /*{ cellPadding="5" }*/
        >
          <thead>
            <tr>
              <th
                id="mlcss-Title-List"
                name="mlcss-Title-List"
                colSpan="14"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">List - Material Loading Check Sheet</font>
              </th>
            </tr>
            <tr>
              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>Date</td>
              <td colSpan="1">
                <input
                  id="mlcss-filter-date"
                  name="mlcss-filter-date"
                  type="date"
                  placeholder="Filter by Date"
                  value={filterDate}
                  onChange={handleFilterDateChange}
                />
              </td>
              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                Machine No

              </td>
              <td colSpan={2}>
                <input
                  id="mlcss-filter-MachineNo"
                  name="mlcss-filter-MachineNo"
                  type="text"
                  placeholder="Filter MachineNo"
                  style={{ fontSize: "70%", width: "100%" }}
                  value={filterMachine}
                  onChange={handleFilterMachineChange}
                />
              </td>
              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                Material Name
              </td>
              <td colSpan={2}>
                <input
                  id="mlcss-filter-MaterialName"
                  name="mlcss-filter-MaterialName"
                  type="text"
                  placeholder="Filter MaterialName"
                  style={{ fontSize: "70%", width: "100%" }}
                  value={filterMcno}
                  onChange={handleFilterMcnoChange}
                />
              </td>
              <td colSpan="8"></td>
            </tr>
            <tr className="table_position">
              <th className="table-cell2">
                Date
              </th>
              <th className="table-cell2">
                Machine No
              </th>
              <th className="table-cell2">
                Shift
              </th>
              <th className="table-cell2">
                Material Name
              </th>
              <th className="table-cell2">
                Grade
              </th>

              <th className="table-cell2">
                Material Lot No
              </th>
              <th className="table-cell2">
                Material Loading in Kgs
              </th>
              <th className="table-cell2">
                Material Loading Time
              </th>
              <th className="table-cell2">
                Pre Heat Hours
              </th>
              {/* <th className="table-cell2">
               Pre Heating Temp
              </th> */}
              <th className="table-cell3">
                QA Sign

              </th>
              <th >
                Viewers

              </th>
              {/* <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Created Date&nbsp;&nbsp;
              </th> */}
              <th></th>
              {(GetAccess('production', 'hod') || GetAccess("ADMIN", "GM")) ? <th></th> : ''}

              {(GetAccess('production', 'hod') || GetAccess("ADMIN", "GM")) ? <th></th> : ''}
            </tr>
          </thead>
          <tbody>
            {/* <HandleRead keyword="material_loading" setData={setmat_load} /> */}

            {filteredRecords.length === 0 ? (
              // <tr>
              <td colSpan="14" style={{ textAlign: "center" }}>
                <p
                  id="mlcss-list-NoRec"
                  name="mlcss-list-noRec"
                  style={{ fontWeight: "bold" }}
                >
                  No records found.
                </p>
              </td>
            ) : (
              // </tr>
              filteredRecords.map((mat_load) => (
                <tr className="table_view" key={mat_load.Id}>
                  <td
                    id="mlcss-list-TrackDate"
                    name="mlcss-list-TrackDate"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{formatDt(mat_load.TrackDate)}
                  </td>
                  <td
                    id="mlcss-list-Machine"
                    name="mlcss-list-Machine"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{mat_load.Machine_no}
                  </td>
                  <td
                    id="mlcss-list-Shift"
                    name="mlcss-list-Shift"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{mat_load.Shift}
                  </td>
                  <td
                    id="mlcss-list-MatName"
                    name="mlcss-list-MatName"
                    className="table-cell"
                    style={{whiteSpace:"inherit"}}
                  >
                    &nbsp;&nbsp;{mat_load.RmDescrip}
                  </td>
                  <td
                    id="mlcss-list-MatGrde"
                    name="mlcss-list-MatGrde"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{mat_load.Mat_grde}
                  </td>

                  <td
                    id="mlcss-list-MatLotNo"
                    name="mlcss-list-MatLotNo"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{mat_load.Mat_lot_no}
                  </td>
                  <td
                    id="mlcss-list-MatLdKgs"
                    name="mlcss-list-MatLdKgs"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{mat_load.Mat_ld_kgs}
                  </td>
                  <td
                    id="mlcss-list-MatLdTime"
                    name="mlcss-list-MatLdTime"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{mat_load.Mat_ld_time}
                  </td>
                  <td
                    id="mlcss-list-PreHtHrs"
                    name="mlcss-list-PreHtHrs"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{mat_load.Pre_ht_hrs}
                  </td>
                  {/* <td
                    id="mlcss-list-PreHtTmp"
                    name="mlcss-list-PreHtTmp"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{mat_load.Pre_ht_tmp}
                  </td> */}
                  <td id="mlcss-list-Qc_sign"
                    name="mlcss-list-Qc_sign"
                    className="table-cell"
                    style={{ whiteSpace: "inherit" }}>
                    &nbsp;&nbsp;{mat_load.Qc_sign}-{mat_load.QaName}
                  </td>
                  {/* {localStorage.getItem("role") !== "hod" ? (
        <td id="mlcss-list-Qc" name="mlcss-list-Qc" className="table-cell">
          {mat_load.Qc_sign}
          </td>
                ):(     
           <td>
             {isEditing && editedRowId === mat_load.Id ? (
              <div>
             <label textAlign="center" className="text" onClick={handle1}>Save</label>
             <DropdownFetcher keyword="emp_details_moldsetter" setValue={setEmployeeDropdown} />
             <Select
              id="dropdown"
              options={empMoldSetterOptions}
              value={
                QcSign && empMoldSetterOptions.find((option) => option.value === QcSign)
                  ? empMoldSetterOptions.find((option) => option.value === QcSign)
                  : null
              }
              onChange={(selectedOption) => setQcSign(selectedOption.value)}
              placeholder="Select..."
            />
            <label className="text1"  onClick={handleCancelEdit}>Cancel</label> 
               </div>
            ) : (
              <div>
              <label textAlign="center"onClick={() => handleEdit1(mat_load)}>
              {mat_load.Qc_sign}<br/>
              </label>
              </div>
            
                )}
               </td>
                )} */}

                  {/* <td id="mlcss-list-createddate"
        name="mlcss-list-createddate"
        className="table-cell">&nbsp;&nbsp;{mat_load.Mat_cr_dt}</td> */}

                  <td >
                  <div className="grid-container">
                    {
                      NotificationListViewer.map((item) => {
                        if (item.note_record_id === mat_load.Id) {
                          return (                                                                             
                              item.note_readed==1?(<div className="grid-item-green"><Tooltip placement="top" trigger={['hover']} overlay={<span>{item.EmpName}</span>}>
                                <span>{item.EmpName.charAt(0)}{" "}</span>
                              </Tooltip></div>) :
                              (<div className="grid-item-red"><Tooltip placement="top" trigger={['hover']} overlay={<span>{item.EmpName}</span>}>
                                <span>{item.EmpName.charAt(0)}{" "}</span>
                              </Tooltip></div>)                                                     
                          );
                        }
                      })
                    }
                            </div>

                  </td>
                  <td>
                    <button
                      id="mlcss-view"
                      name="mlcss-view"
                      style={{ backgroundColor: "#cba423", width: "55px" }}
                      type="button"
                      onClick={() => handleView(mat_load)}
                    >
                      View&#x1F441;
                    </button>
                  </td>
                  {(GetAccess('production', 'hod') || GetAccess("ADMIN", "GM")) ? <td>
                    <button
                      id="mlcss-edit"
                      name="mlcss-edit"
                      type="button"
                      style={{ width: "55px" }}
                      onClick={() => handleEdit(mat_load)}
                    >
                      Edit&#x270E;
                    </button>
                  </td> : ''}
                  {(GetAccess('production', 'hod') || GetAccess("ADMIN", "GM")) ?
                    <td>
                      <button
                        id={`mat_load-Delete`}
                        name={`mat_load-Delete`}
                        style={{ whiteSpace: "nowrap", backgroundColor: "#DA251A", width: "60px" }}
                        type="button"
                        onClick={() => {
                          const userConfirmed = window.confirm('Are you sure you want to delete?');
                          if (userConfirmed) {
                            UpdateRecord({
                              _keyword_: 'material_loading',
                              data: [
                                {
                                  Id: mat_load.Id,
                                  Mat_active_status: 'InActive',
                                },
                              ],
                            }).then(() => {
                              handleReadMaterialLoading();
                            })
                            // window.location.reload();
                          }
                        }}
                      >
                        Delete<MdDelete />
                      </button>
                    </td> : ''}
                </tr>
              ))
            )}
          </tbody>
        </table>
        <div className="pagination" id="pagination">
          {isPaginationVisible && filteredData.length > 0 && (

            <Pagination
              currentPage={currentPage}
              npage={npage}
              prePage={prePage}
              nextPage={nextPage}
              changeCpage={changeCpage}
              numbers={numbers}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MaterialLoadingView;
