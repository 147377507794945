import React,{useState,useEffect} from "react";
import QaLayoutPaint from "../../QaLayoutPaint";
import ListViewPaint from "../../../../Components/ListView/ListViewPaint";


const CustomerMasterList = () => {
  
  return (
    <QaLayoutPaint quary={"paint_customer_master"}>
    <div>
      <ListViewPaint
        Title={"Paint Customer Master"}
        standard={["delete_id=0"]}
        quary={"Select id as Id,Customer_code,Customer_name,active_status,CreatedDate from paint_customer_master group by Id ORDER BY CASE WHEN active_status = 'Active' THEN 0 ELSE 1 END,Id DESC"}
        table={"paint_customer_master"}
        tableDelete={"paint_customer_master"}
        navigation={"PaintCustomerMaster"}
        filter={["Id","Customer_code","Customer_name","active_status"]}
        dateFilter={"CreatedDate"}
        header={[
          { value: "Id", label: "ID" },
          { value: "CreatedDate", label: "Date" },
          { value: "Customer_code", label: "Customer Code" },
          { value: "Customer_name", label: "Customer Name" },
          { value: "active_status", label: "Active Status" },
        ]}
      />   
    </div>
    </QaLayoutPaint>
  );
};

export default CustomerMasterList;
