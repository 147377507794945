import React, { useEffect, useState, useRef } from "react";
import Popup from "../../../Components/Popup/Popup";

import "../../../Styles/DailyShift.css";
//import "bootstrap/dist/css/bootstrap.min.css";

import Unitechlogo from "../../../icons/Unitechlogo.jpeg";

import {
  SECRET_KEY,
  PROCESS_TRANSACTION_UPDATE_URL,
} from "../../../Utils/constants.js";
import * as GeneralConstants from "../../../Utils/GeneralConstants";
import { getAPIRequest } from "../../../Utils/APIUtility.js";
import Pagination from "../../../Components/Pagination";
import HandleRead from "../../../Lib/HandleRead.js";
import RadioOkNotOkayFetch from "../../../Components/RadioOkandNotOkFetcher.js";
import DatePicker from "../../../Lib/DatePicker.js";
import { formatDt } from "../../../Lib/formatDt";
import ShiftDropdown from "../../../Components/ShiftDropdown";
import DropdownFetcher from "../../../Lib/DropdownFetcher";
import UpdateRecord from "../../../Lib/updateRecord";
import Select from "react-select";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import GetRecord from "../../../Lib/GetRecord";
import { FaBackspace } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import ReadRecord from "../../../Lib/API/ReadRecord.js";

const initialState = {
  Shift: "",
  MachineName: "",
  MachineNo: "",
  TrackDate: "",
  MoldSafPresBlw1: "",
  MoldSafTon1: "",
  CycleTmeAlrm1: "",
  CushMonAlrm1: "",
  EjecPresBlw1: "",
  BarTempVari1: "",
  MoldClean1: "",
  CAndS1: "",
  SmpAndPmsVeri1: "",
  OperTrain1: "",
  LmpMonitor1: "",
  Operator1: "",
  MoldSetter1: "",
  Inchrge1: "",
  SeniorEngg1: "",
  isPaginationVisible: true,
  currentPage: 1,
  filterMcno: "",
  filterDate: "",
  Id: "",
};
function DailyShiftProcessCheckListView() {
  const scrollTop = useRef(null);
  const [isCreating, setIsCreating] = useState(false);
  const [DailyShiftProcess, setDailyShiftProcess] = useState(initialState);
  const [Daily_shift_pro, setDaily_shift_pro] = useState([]);
  const [ButtonPopup2, setButtonPopup2] = useState(false);
  const [ButtonPopup, setButtonPopup] = useState(false);
  const [EmployeeDropdown, setEmployeeDropdown] = useState([]);
  const [EmployeeMoldDropdown, setEmployeeMoldDropdown] = useState([]);
  const [InchargeEmployeeDropdown, setInchargeEmployeeDropdown] = useState([]);
  const [EnggEmployeeDropdown, setEnggEmployeeDropdown] = useState([]);

  const [back, setback] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [ENC, setENC] = useState("");
  const [remarks, setremarks] = useState("");
  const [cardId, setcardId] = useState("");
  useEffect(() => {
    console.log(id);
    if (id !== undefined) {
      GetRecord(
        {
          _keyword_: "Get_DSPC_by_id",
          id: id,
        },
        setENC
      ).then(async (res) => {
        console.log("res - ", res);
        handleView(res[0]);
        setButtonPopup2(true);
        setback(true);

        await timeout(1000);
        const popupDiv = document.querySelector(".popup");
        if (popupDiv) {
          const elementInsidePopup = popupDiv.querySelector("#close-btn");
          elementInsidePopup.style.display = "none";
        }
      });
    }
  }, [id]);
  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const recordPerPage = 20;
  const filterRecords = () => {
    const filteredData = Daily_shift_pro.filter((record) => {
      if (record && record.TrackDate) {
        const dateMatch = record.TrackDate.includes(
          DailyShiftProcess.filterDate
        );
        const mcnoMatch = record.Machine_no.toLowerCase().includes(
          DailyShiftProcess.filterMcno.toLowerCase()
        );
        return dateMatch && mcnoMatch;
      }
      return false;
    });
    return filteredData || [];
  };

  const filteredData = filterRecords();
  const npage = Math.ceil(filteredData.length / recordPerPage);
  const numbers = [...Array(npage).keys()].map((num) => num + 1);

  function handleFilterMcnoChange(event) {
    setDailyShiftProcess({
      ...DailyShiftProcess,
      currentPage: 1,
      filterMcno: event.target.value,
    });
  }
  function handleFilterDateChange(event) {
    setDailyShiftProcess({
      ...DailyShiftProcess,
      currentPage: 1,
      filterDate: event.target.value,
    });
  }
  const firstIndex = (DailyShiftProcess.currentPage - 1) * recordPerPage;
  const lastIndex = firstIndex + recordPerPage;
  const filteredRecords = filteredData.slice(firstIndex, lastIndex);

  function prePage() {
    if (DailyShiftProcess.currentPage > 1) {
      setDailyShiftProcess({
        ...DailyShiftProcess,
        currentPage: DailyShiftProcess.currentPage - 1,
      });
    }
  }

  function nextPage() {
    if (DailyShiftProcess.currentPage < npage) {
      setDailyShiftProcess({
        ...DailyShiftProcess,
        currentPage: DailyShiftProcess.currentPage + 1,
      });
    }
  }

  function changeCpage(id) {
    setDailyShiftProcess({ ...DailyShiftProcess, currentPage: id });
  }
  const viewPagination = () => {
    setDailyShiftProcess({ ...DailyShiftProcess, isPaginationVisible: true });
  };
  // const handleDateChange = (newDate) => {
  //   setDailyShiftProcess({...DailyShiftProcess,TrackDate:newDate});
  // };

  const idView = "DailyShiftProcess-View";
  const nameView = "DailyShiftProcess-View";
  const idEdit = "DailyShiftProcess-Edit";
  const nameEdit = "DailyShiftProcess-Edit";

  const handleView = (e) => {
    setDailyShiftProcess({
      ...DailyShiftProcess,
      isPaginationVisible: false,

      TrackDate: e.TrackDate,
      Shift: e.Shift,
      MachineName: e.Machine_name,
      MachineNo: e.Machine_no,
      MoldSafPresBlw1: e.Mold_saf_pres_blw_1,
      MoldSafTon1: e.Mold_saf_ton_1,
      CycleTmeAlrm1: e.Cycle_tme_alrm_1,
      CushMonAlrm1: e.Cush_mon_alrm_1,
      EjecPresBlw1: e.Ejec_pres_blw_1,
      BarTempVari1: e.Bar_temp_vari_1,
      MoldClean1: e.Mold_clean_1,
      CAndS1: e.C_and_s_1,
      SmpAndPmsVeri1: e.Smp_and_pms_veri_1,
      OperTrain1: e.Oper_train_1,
      LmpMonitor1: e.Lmp_monitor_1,
      Operator1: `${e.Operator_1}-${e.Operator}`,
      MoldSetter1: `${e.Mold_setter_1}-${e.MoldSetter}`,
      Inchrge1: `${e.Inchrge_1}-${e.Inchrge}`,
      SeniorEngg1: `${e.Senior_engg_1}-${e.SeniorEngg}`,
    });
    setButtonPopup2(true);
  };

  const handleEdit = (e) => {
    setDailyShiftProcess({
      ...DailyShiftProcess,
      isPaginationVisible: false,

      Id: e.Id,
      TrackDate: e.TrackDate,
      Shift: e.Shift,
      MachineName: e.Machine_name,
      MachineNo: e.Machine_no,
      MoldSafPresBlw1: e.Mold_saf_pres_blw_1,
      MoldSafTon1: e.Mold_saf_ton_1,
      CycleTmeAlrm1: e.Cycle_tme_alrm_1,
      CushMonAlrm1: e.Cush_mon_alrm_1,
      EjecPresBlw1: e.Ejec_pres_blw_1,
      BarTempVari1: e.Bar_temp_vari_1,
      MoldClean1: e.Mold_clean_1,
      CAndS1: e.C_and_s_1,
      SmpAndPmsVeri1: e.Smp_and_pms_veri_1,
      OperTrain1: e.Oper_train_1,
      LmpMonitor1: e.Lmp_monitor_1,
      Operator1: { value: e.Operator_1, label: e.Operator },
      MoldSetter1: { value: e.Mold_setter_1, label: e.MoldSetter },
      Inchrge1: { value: e.Inchrge_1, label: e.Inchrge },
      SeniorEngg1: { value: e.Senior_engg_1, label: e.SeniorEngg },
    });
    setButtonPopup(true);
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    // setIsCreating(true);

    if (!DailyShiftProcess.MachineNo || !DailyShiftProcess.MachineName) {
      alert(GeneralConstants.MANDATORY_ALERT);
      if (scrollTop.current) {
        scrollTop.current.scrollIntoView({ behavior: "smooth" });
      }
      return;
    }
    const data = {
      data: [
        {
          Id: DailyShiftProcess.Id,
          Machine_name: DailyShiftProcess.MachineName,
          Machine_no: DailyShiftProcess.MachineNo,
          TrackDate: DailyShiftProcess.TrackDate,
          Mold_saf_pres_blw_1: DailyShiftProcess.MoldSafPresBlw1,
          Mold_saf_ton_1: DailyShiftProcess.MoldSafTon1,
          Cycle_tme_alrm_1: DailyShiftProcess.CycleTmeAlrm1,
          Cush_mon_alrm_1: DailyShiftProcess.CushMonAlrm1,
          Ejec_pres_blw_1: DailyShiftProcess.EjecPresBlw1,
          Bar_temp_vari_1: DailyShiftProcess.BarTempVari1,
          Mold_clean_1: DailyShiftProcess.MoldClean1,
          C_and_s_1: DailyShiftProcess.CAndS1,
          Smp_and_pms_veri_1: DailyShiftProcess.SmpAndPmsVeri1,
          Oper_train_1: DailyShiftProcess.OperTrain1,
          Lmp_monitor_1: DailyShiftProcess.LmpMonitor1,
          Shift: DailyShiftProcess.Shift,
          Operator_1: DailyShiftProcess.Operator1.value,
          Mold_setter_1: DailyShiftProcess.MoldSetter1.value,
          Inchrge_1: DailyShiftProcess.Inchrge1.value,
          Senior_engg_1: DailyShiftProcess.SeniorEngg1.value,
        },
      ],
    };
    data["_keyword_"] = "Daily_shift_process_checklist";
    data["secretkey"] = SECRET_KEY;
    getAPIRequest(PROCESS_TRANSACTION_UPDATE_URL, "POST", data)
      .then((response) => {
        // setDaily_shift_pro([...Daily_shift_pro, response.data]);
        alert(`${GeneralConstants.UPDATE_SUCCESSFUL}`);
        if (scrollTop.current) {
          scrollTop.current.scrollIntoView({ behavior: "smooth" });
        }
        setDailyShiftProcess({
          ...DailyShiftProcess,

          isPaginationVisible: true,
        });
        setButtonPopup(false);
        handleReadDailyCheckSheet();

        //  window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        alert(GeneralConstants.UPDATE_FAILURE);
        if (scrollTop.current) {
          scrollTop.current.scrollIntoView({ behavior: "smooth" });
          window.location.reload();
        }
      });
  };
  const handleDateChange = (newDate) => {
    setDailyShiftProcess({ ...DailyShiftProcess, TrackDate: newDate });
  };
  const empOperatorOptions = EmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const empMoldSetterOptions = EmployeeMoldDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const empInchrgeOptions = InchargeEmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const empEnggOptions = EnggEmployeeDropdown.map((i) => ({
    value: i.Emp_code,
    label: i.Emp_name,
  }));
  const handleOperator = (selectedOption) => {
    const selected = EmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setDailyShiftProcess({
        ...DailyShiftProcess,
        Operator1: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      });
    }
  };
  const handleMoldSetter = (selectedOption) => {
    const selected = EmployeeMoldDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setDailyShiftProcess({
        ...DailyShiftProcess,
        MoldSetter1: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      });
    }
  };
  const handleEnggSetter = (selectedOption) => {
    const selected = EnggEmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setDailyShiftProcess({
        ...DailyShiftProcess,
        SeniorEngg1: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      });
    }
  };
  const handleInchrge = (selectedOption) => {
    const selected = InchargeEmployeeDropdown.find(
      (option) => option.Emp_code === selectedOption.value
    );

    if (selected) {
      setDailyShiftProcess({
        ...DailyShiftProcess,
        Inchrge1: {
          value: selected.Emp_code,
          label: selected.Emp_name,
        },
      });
    }
  };
  function handleReadDailyCheckSheet() {
    ReadRecord({
      _keyword_: "Daily_shift_process_checklist",
    }).then((res) => {
      // console.log(res.data);
      setDaily_shift_pro(res.data);
    });
  }
  useEffect(() => {
    handleReadDailyCheckSheet();
  }, []);

  return (
    <div >
      <Popup
        trigger={ButtonPopup2}
        setTrigger={setButtonPopup2}
        viewPagination={viewPagination}
      >
        <br></br>
        {back && (
          <div
            style={{
              fontSize: "16px",
              fontWeight: "bolder",
              backgroundColor: "#86BBd8",
              width: "90px",
              padding: "10px",
              marginLeft: "20px",
              borderRadius: "10px",
              border: "2px solid #005C9F",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaBackspace size={20} /> Back
          </div>
        )}

        <form
          id="DailyShiftProcessCheckList-formContainer-View"
          name="DailyShiftProcessCheckList-formContainer-View"
          className="form-DailyShiftProcessCheckList-View"
        >
          <table style={{ textAlign: "center", width: "100%" }}>
            <tbody>
              <tr>
                <th style={{ textAlign: "center", width: "5%" }}>
                  <img src={Unitechlogo} style={{ width: "50px" }}></img>
                </th>
                <th
                  id={`${idView}-Title`}
                  name={`${nameView}-Title`}
                  colSpan="5"
                  style={{
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    padding: "20px 0px",
                  }}
                >
                  <font style={{ fontSize: "24px" }}>
                  KASH Tech LLC
                  </font>
                  <br></br>
                  <font color="#8B0000">
                    View - Daily Shift Wise Process Check List
                  </font>
                </th>
              </tr>
              <tr>
                <td style={{textAlign:"center"}}>M/C Name:</td>
                <td style={{textAlign:"center"}}>
                  <label
                    id={`${idView}-MachineName`}
                    name={`${nameView}-MachineName`}>
                    {DailyShiftProcess.MachineName}
                  </label>
                </td>

                <td style={{textAlign:"center"}}>M/C No:</td>
                <td>
                  <label id={`${idView}-MachineNo`}name={`${nameView}-MachineNo`}>
                    {DailyShiftProcess.MachineNo}
                  </label>
                </td>

                <td style={{textAlign:"center"}}>Date:</td>
                <td>
                  <label
                    id={`${idView}-TrackDate`}
                    name={`${nameView}-TrackDate`}
                  >
                    {formatDt(DailyShiftProcess.TrackDate)}
                  </label>
                </td>
              </tr>
              <tr>
                <th style={{ whiteSpace: "nowrap",textAlign:"center" }}>S. No.,</th>
                <th style={{ whiteSpace: "nowrap",textAlign:"center" }}>Parameters</th>
                <th style={{ whiteSpace: "nowrap",textAlign:"center" }}>Check Area</th>
                <th style={{ whiteSpace: "nowrap",textAlign:"center", }}>How to Check</th>
                <th style={{ whiteSpace: "nowrap",textAlign:"center", padding: "10px 0px" }}>
                  Reaction if not ok
                </th>
                <th style={{textAlign:"center"}}>Ok/Not Ok</th>
              </tr>
              <tr>
                <td style={{textAlign:"center"}}>D1</td>
                <td style={{textAlign:"center"}}>
                  Mold Safety Pressure Below 20 BAR & Time in SEC 0.05 SEC
                </td>
                <td style={{textAlign:"center"}}>Clamping Page</td>
                <td style={{textAlign:"center"}}>Visual</td>
                <td style={{textAlign:"center"}}>Informed to incharge</td>
                <td > 
                  <label>
                    {DailyShiftProcess.MoldSafPresBlw1 === "Ok"
                      ? "✓"
                      : DailyShiftProcess.MoldSafPresBlw1 === "NotOk"
                      ? "X"
                      : null}
                  </label>
                </td>
              </tr>
              <tr>
                <td style={{textAlign:"center"}}>D2</td>
                <td style={{textAlign:"center"}}>Mold Safety Tonnage Not Exit Position 2MM</td>
                <td style={{textAlign:"center"}}>Clamping Page</td>
                <td style={{textAlign:"center"}}>Visual</td>
                <td style={{textAlign:"center"}}>Informed to incharge</td>
                <td>
                  <label>
                    {DailyShiftProcess.MoldSafTon1 === "Ok"
                      ? "✓"
                      : DailyShiftProcess.MoldSafTon1 === "NotOk"
                      ? "X"
                      : null}
                  </label>
                </td>
              </tr>
              <tr>
                <td style={{textAlign:"center"}}>D3</td>
                <td style={{textAlign:"center"}}>Cycle Time Alarm ±5SEC</td>
                <td style={{textAlign:"center"}}>Proc mon/Feat time</td>
                <td style={{textAlign:"center"}}>Visual</td>
                <td style={{textAlign:"center"}}>Informed to incharge</td>
                <td>
                  <label>
                    {DailyShiftProcess.CycleTmeAlrm1 === "Ok"
                      ? "✓"
                      : DailyShiftProcess.CycleTmeAlrm1 === "NotOk"
                      ? "X"
                      : null}
                  </label>
                </td>
              </tr>
              <tr>
                <td style={{textAlign:"center"}}>D4</td>
                <td style={{textAlign:"center"}}>Cushion Monitoring Alarm ±0.5MM</td>
                <td style={{textAlign:"center"}}>Proc mon</td>
                <td style={{textAlign:"center"}}>Visual</td>
                <td style={{textAlign:"center"}}>Informed to incharge</td>
                <td>
                  <label>
                    {DailyShiftProcess.CushMonAlrm1 === "Ok"
                      ? "✓"
                      : DailyShiftProcess.CushMonAlrm1 === "NotOk"
                      ? "X"
                      : null}
                  </label>
                </td>
              </tr>
              <tr>
                <td style={{textAlign:"center"}}>D5</td>
                <td style={{textAlign:"center"}}>Ejection Pressure Below (20 BAR)</td>
                <td style={{textAlign:"center"}}>Ejection Page</td>
                <td style={{textAlign:"center"}}>Visual</td>
                <td style={{textAlign:"center"}}>Informed to incharge</td>
                <td>
                  <label>
                    {DailyShiftProcess.EjecPresBlw1 === "Ok"
                      ? "✓"
                      : DailyShiftProcess.EjecPresBlw1 === "NotOk"
                      ? "X"
                      : null}
                  </label>
                </td>
              </tr>
              <tr>
                <td style={{textAlign:"center"}}>D6</td>
                <td style={{textAlign:"center"}}>Barrel Temperature Variation ±10°C</td>
                <td style={{textAlign:"center"}}>Temp Page</td>
                <td style={{textAlign:"center"}}>Visual</td>
                <td style={{textAlign:"center"}}>Informed to incharge</td>
                <td>
                  <label>
                    {DailyShiftProcess.BarTempVari1 === "Ok"
                      ? "✓"
                      : DailyShiftProcess.BarTempVari1 === "NotOk"
                      ? "X"
                      : null}
                  </label>
                </td>
              </tr>
              <tr>
                <td style={{textAlign:"center"}}>D8</td>
                <td style={{textAlign:"center"}}>3C 5S</td>
                <td style={{textAlign:"center"}}>All Area</td>
                <td style={{textAlign:"center"}}>Visual</td>
                <td style={{textAlign:"center"}}>Informed to incharge</td>
                <td>
                  <label>
                    {DailyShiftProcess.CAndS1 === "Ok"
                      ? "✓"
                      : DailyShiftProcess.CAndS1 === "NotOk"
                      ? "X"
                      : null}
                  </label>
                </td>
              </tr>
              <tr>
                <td style={{textAlign:"center"}}>D9</td>
                <td style={{textAlign:"center"}}>SMP & PMS Verification</td>
                <td style={{textAlign:"center"}}>SMP & PMS Sheet</td>
                <td style={{textAlign:"center"}}>Visual</td>
                <td style={{textAlign:"center"}}>Informed to incharge</td>
                <td>
                  <label>
                    {DailyShiftProcess.SmpAndPmsVeri1 === "Ok"
                      ? "✓"
                      : DailyShiftProcess.SmpAndPmsVeri1 === "NotOk"
                      ? "X"
                      : null}
                  </label>
                </td>
              </tr>
              {/* <tr>
            <td >D10</td>
            <td >Operator1 training</td>
            <td >Operator1 Skill</td>
            <td >Visual</td>
            <td >Informed to incharge</td>
            <td> 
            <label>{DailyShiftProcess.OperTrain1 === "Ok" ? '✓' : DailyShiftProcess.OperTrain1 === "NotOk" ? 'X' : null}</label>

              </td>
           
              
            </tr> */}
              <tr>
                <td style={{textAlign:"center"}}>D10</td>
                <td style={{textAlign:"center"}}>LMP Monitoring</td>
                <td style={{textAlign:"center"}}>Molding Side</td>
                <td style={{textAlign:"center"}}>Visual</td>
                <td style={{textAlign:"center"}}>Informed to incharge</td>
                <td>
                  <label>
                    {DailyShiftProcess.LmpMonitor1 === "Ok"
                      ? "✓"
                      : DailyShiftProcess.LmpMonitor1 === "NotOk"
                      ? "X"
                      : null}
                  </label>
                </td>
              </tr>
              <tr>
                <td colSpan="4" rowSpan={4} style={{ padding:"10px" }}>
                  Shift:
                  <label>{DailyShiftProcess.Shift}</label>
                </td>

                <td style={{textAlign:"center"}}>Operator</td>
                <td>
                  <label>{DailyShiftProcess.Operator1}</label>
                </td>
              </tr>
              <tr>
                <td style={{textAlign:"center"}}>Mold Setter</td>
                <td>
                  <label>{DailyShiftProcess.MoldSetter1}</label>
                </td>
              </tr>

              <tr>
                <td style={{textAlign:"center"}}>Incharge Name</td>
                <td>
                  <label>{DailyShiftProcess.Inchrge1}</label>
                </td>
              </tr>
              <tr>
                <td style={{textAlign:"center"}}>Senior Engg</td>
                <td>
                  <label>{DailyShiftProcess.SeniorEngg1}</label>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </Popup>
      <Popup
        trigger={ButtonPopup}
        setTrigger={setButtonPopup}
        viewPagination={viewPagination}
      >
        <br></br>

        <form
          id="DailyShiftProcessCheckList-formContainer-Edit"
          name="DailyShiftProcessCheckList-formContainer-Edit"
          className="form-DailyShiftProcessCheckList-Edit"
        >
          <table style={{ textAlign: "center", width: "100%" }}>
            <tbody>
              <tr>
                <th style={{ textAlign: "center", width: "5%" }}>
                  <img src={Unitechlogo} style={{ width: "50px" }}></img>
                </th>
                <th
                  id={`${idEdit}-Title`}
                  name={`${nameEdit}-Title`}
                  colSpan="5"
                  style={{
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    padding: "20px 0px",
                  }}
                >
                  <font style={{ fontSize: "24px" }}>
                  KASH Tech LLC
                  </font>
                  <br></br>
                  <font color="#8B0000">
                    Daily Shift Wise Process Check List
                  </font>
                </th>
              </tr>
              <tr>
                <td className="topic-head">
                  M/C Name:<span style={{ color: "red" }}>*</span>
                </td>
                <td>
                  <input
                    id={`${idEdit}-MachineName`}
                    name={`${nameEdit}-MachineName`}
                    type="text"
                    value={DailyShiftProcess.MachineName}
                    onChange={(e) =>
                      setDailyShiftProcess({
                        ...DailyShiftProcess,
                        MachineName: e.target.value,
                      })
                    }
                    disabled
                  />
                </td>

                <td className="topic-head">
                  M/C No:<span style={{ color: "red" }}>*</span>
                </td>
                <td>
                  {DailyShiftProcess.MachineNo === "" && isCreating && (
                    <span className="Req">Required *</span>
                  )}
                  <input
                    id={`${idEdit}-MachineNo`}
                    name={`${nameEdit}-MachineNo`}
                    type="text"
                    value={DailyShiftProcess.MachineNo}
                    onChange={(e) =>
                      setDailyShiftProcess({
                        ...DailyShiftProcess,
                        MachineNo: e.target.value,
                      })
                    }
                    required
                    disabled
                  />
                </td>

                <td className="topic-head">Date</td>
                <td>
                  {/* <input
                id={`${idEdit}-Month`}
                name={`${nameEdit}-Month`}
                  type="date"
                 value={DailyShiftProcess.Month}
                  onChange={(e) => setMonth(e.target.value)}
                  required
                /> */}
                  <DatePicker
                    value={DailyShiftProcess.TrackDate}
                    onChange={handleDateChange}
                  />
                </td>
              </tr>
              <tr>
                <th>S. No.,</th>
                <th>Parameters</th>
                <th style={{ whiteSpace: "nowrap" }}>Check Area</th>
                <th style={{ whiteSpace: "nowrap" }}>How to Check</th>
                <th style={{ whiteSpace: "nowrap", padding: "10px 0px" }}>
                  Reaction if not ok
                </th>
                <th>Ok/Not Ok</th>
              </tr>
              <tr>
                <td className="topic-head">D1</td>
                <td className="topic-head">
                  Mold Safety Pressure Below 20 BAR & Time in SEC 0.05 SEC
                </td>
                <td className="topic-head">Clamping Page</td>
                <td className="topic-head">Visual</td>
                <td className="topic-head">Informed to incharge</td>
                <td>
                  {/* <OkNotOkayFetchvalue={DailyShiftProcess.MoldSafPresBlw1} setValue={setMoldSafPresBlw1} /> */}
                  <RadioOkNotOkayFetch
                    inputName="MoldSafPresBlw1"
                    selectedValue={DailyShiftProcess.MoldSafPresBlw1}
                    onChange={(e) =>
                      setDailyShiftProcess({
                        ...DailyShiftProcess,
                        MoldSafPresBlw1: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">D2</td>
                <td className="topic-head">
                  Mold Safety Tonnage Not Exit Position 2MM
                </td>
                <td className="topic-head">Clamping Page</td>
                <td className="topic-head">Visual</td>
                <td className="topic-head">Informed to incharge</td>
                <td>
                  <RadioOkNotOkayFetch
                    inputName="MoldSafTon1"
                    selectedValue={DailyShiftProcess.MoldSafTon1}
                    onChange={(e) =>
                      setDailyShiftProcess({
                        ...DailyShiftProcess,
                        MoldSafTon1: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">D3</td>
                <td className="topic-head">Cycle Time Alarm ±5SEC</td>
                <td className="topic-head">Proc mon/Feat time</td>
                <td className="topic-head">Visual</td>
                <td className="topic-head">Informed to incharge</td>
                <td>
                  <RadioOkNotOkayFetch
                    inputName="CycleTmeAlrm1"
                    selectedValue={DailyShiftProcess.CycleTmeAlrm1}
                    onChange={(e) =>
                      setDailyShiftProcess({
                        ...DailyShiftProcess,
                        CycleTmeAlrm1: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">D4</td>
                <td className="topic-head">Cushion Monitoring Alarm ±0.5MM</td>
                <td className="topic-head">Proc mon</td>
                <td className="topic-head">Visual</td>
                <td className="topic-head">Informed to incharge</td>
                <td>
                  <RadioOkNotOkayFetch
                    inputName="CushMonAlrm1"
                    selectedValue={DailyShiftProcess.CushMonAlrm1}
                    onChange={(e) =>
                      setDailyShiftProcess({
                        ...DailyShiftProcess,
                        CushMonAlrm1: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">D5</td>
                <td className="topic-head">Ejection Pressure Below (20 BAR)</td>
                <td className="topic-head">Ejection Page</td>
                <td className="topic-head">Visual</td>
                <td className="topic-head">Informed to incharge</td>
                <td>
                  <RadioOkNotOkayFetch
                    inputName="EjecPresBlw1"
                    selectedValue={DailyShiftProcess.EjecPresBlw1}
                    onChange={(e) =>
                      setDailyShiftProcess({
                        ...DailyShiftProcess,
                        EjecPresBlw1: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">D6</td>
                <td className="topic-head">
                  Barrel Temperature Variation ±10°C
                </td>
                <td className="topic-head">Temp Page</td>
                <td className="topic-head">Visual</td>
                <td className="topic-head">Informed to incharge</td>
                <td>
                  <RadioOkNotOkayFetch
                    inputName="BarTempVari1"
                    selectedValue={DailyShiftProcess.BarTempVari1}
                    onChange={(e) =>
                      setDailyShiftProcess({
                        ...DailyShiftProcess,
                        BarTempVari1: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">D7</td>
                <td className="topic-head">Mold Cleanning (MATERIAL DUST)</td>
                <td className="topic-head">Molding Side</td>
                <td className="topic-head">Visual</td>
                <td className="topic-head">Informed to incharge</td>
                <td>
                  <RadioOkNotOkayFetch
                    inputName="MoldClean1"
                    selectedValue={DailyShiftProcess.MoldClean1}
                    onChange={(e) =>
                      setDailyShiftProcess({
                        ...DailyShiftProcess,
                        MoldClean1: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">D8</td>
                <td className="topic-head">3C 5S</td>
                <td className="topic-head">All Area</td>
                <td className="topic-head">Visual</td>
                <td className="topic-head">Informed to incharge</td>
                <td>
                  <RadioOkNotOkayFetch
                    inputName="CAndS1"
                    selectedValue={DailyShiftProcess.CAndS1}
                    onChange={(e) =>
                      setDailyShiftProcess({
                        ...DailyShiftProcess,
                        CAndS1: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">D9</td>
                <td className="topic-head">SMP & PMS Verification</td>
                <td className="topic-head">SMP & PMS Sheet</td>
                <td className="topic-head">Visual</td>
                <td className="topic-head">Informed to incharge</td>
                <td>
                  <RadioOkNotOkayFetch
                    inputName="SmpAndPmsVeri1"
                    selectedValue={DailyShiftProcess.SmpAndPmsVeri1}
                    onChange={(e) =>
                      setDailyShiftProcess({
                        ...DailyShiftProcess,
                        SmpAndPmsVeri1: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              {/* <tr>
            <td className="topic-head">D10</td>
            <td className="topic-head">Operator1 training</td>
            <td className="topic-head">Operator1 Skill</td>
            <td className="topic-head">Visual</td>
            <td className="topic-head">Informed to incharge</td>
            <td>
            
            <RadioOkNotOkayFetch inputName="OperTrain1" selectedValue={DailyShiftProcess.OperTrain1} 
            onChange={(e) => setDailyShiftProcess({...DailyShiftProcess,OperTrain1: e.target.value})}/>
            </td>
            
            </tr> */}
              <tr>
                <td className="topic-head">D10</td>
                <td className="topic-head">LMP Monitoring</td>
                <td className="topic-head">Molding Side</td>
                <td className="topic-head">Visual</td>
                <td className="topic-head">Informed to incharge</td>
                <td>
                  <RadioOkNotOkayFetch
                    inputName="LmpMonitor1"
                    selectedValue={DailyShiftProcess.LmpMonitor1}
                    onChange={(e) =>
                      setDailyShiftProcess({
                        ...DailyShiftProcess,
                        LmpMonitor1: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="4" rowSpan={4} className="topic-head">
                  Shift
                  <ShiftDropdown
                    className="Shift-dropdown"
                    id={idEdit}
                    name={nameEdit}
                    value={DailyShiftProcess.Shift}
                    onChange={(e) =>
                      setDailyShiftProcess({
                        ...DailyShiftProcess,
                        Shift: e.target.value,
                      })
                    }
                  />
                </td>

                <td className="topic-head">Operator</td>
                <td>
                  {/* <input
                  id={`${idView}-Operator1`}
                  name={`${nameView}-Operator1`}
                  type="text"
                  value={DailyShiftProcess.Operator1}
                  onChange={(e) =>
                    setDailyShiftProcess({
                      ...DailyShiftProcess,
                      Operator1: e.target.value,
                    })
                  }
                /> */}
                  <DropdownFetcher
                    keyword="emp_details_operator"
                    setValue={setEmployeeDropdown}
                  />
                  <Select
                    id={`${idView}-Operator1`}
                    name={`${nameView}-Operator1`}
                    value={DailyShiftProcess.Operator1}
                    onChange={handleOperator}
                    options={empOperatorOptions}
                    placeholder="Select Operator"
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">Mold Setter</td>
                <td>
                  {/* <input
                  id={`${idView}-MoldSetter1`}
                  name={`${nameView}-MoldSetter1`}
                  type="text"
                  value={DailyShiftProcess.MoldSetter1}
                  onChange={(e) =>
                    setDailyShiftProcess({
                      ...DailyShiftProcess,
                      MoldSetter1: e.target.value,
                    })
                  }
                /> */}
                  <DropdownFetcher
                    keyword="emp_details_moldsetter"
                    setValue={setEmployeeMoldDropdown}
                  />
                  <Select
                    id={`${idView}-MoldSetter1`}
                    name={`${nameView}-MoldSetter1`}
                    value={DailyShiftProcess.MoldSetter1}
                    onChange={handleMoldSetter}
                    options={empMoldSetterOptions}
                    placeholder="Select Mould Setter"
                  />
                </td>
              </tr>

              <tr>
                <td className="topic-head">Incharge Name</td>
                <td>
                  {/* <input
                  id={`${idView}-Inchrge1`}
                  name={`${nameView}-Inchrge1`}
                  type="text"
                  value={DailyShiftProcess.Inchrge1}
                  onChange={(e) =>
                    setDailyShiftProcess({
                      ...DailyShiftProcess,
                      Inchrge1: e.target.value,
                    })
                  }
                /> */}
                  <DropdownFetcher
                    keyword="emp_details_incharge"
                    setValue={setInchargeEmployeeDropdown}
                  />
                  <Select
                    id={`${idView}-Inchrge1`}
                    name={`${nameView}-Inchrge1`}
                    value={DailyShiftProcess.Inchrge1}
                    onChange={handleInchrge}
                    options={empInchrgeOptions}
                    placeholder="Select Incharge"
                  />
                </td>
              </tr>
              <tr>
                <td className="topic-head">Senior Engg</td>
                <td>
                  {/* <input
                  id={`${idView}-SeniorEngg1`}
                  name={`${nameView}-SeniorEngg1`}
                  type="text"
                  value={DailyShiftProcess.SeniorEngg1}
                  onChange={(e) =>
                    setDailyShiftProcess({
                      ...DailyShiftProcess,
                      SeniorEngg1: e.target.value,
                    })
                  }
                /> */}
                  <DropdownFetcher
                    keyword="emp_details_engg"
                    setValue={setEnggEmployeeDropdown}
                  />
                  <Select
                    id={`${idView}-SeniorEngg1`}
                    name={`${nameView}-SeniorEngg1`}
                    value={DailyShiftProcess.SeniorEngg1}
                    onChange={handleEnggSetter}
                    options={empEnggOptions}
                    placeholder="Select Engg"
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="6" align="center" style={{ border: "none" }}>
                  <button
                    type="submit"
                    id={`${idEdit}-submit`}
                    name={`${nameEdit}-submit`}
                    onClick={(e) => handleUpdate(e)}
                  >
                    Update
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </Popup>
      <div>
        <table>
          <thead>
            <tr>
              <th
                id="DailyShiftProcess-Title-List"
                name="DailyShiftProcess-Title-List"
                colSpan="12"
                style={{ textAlign: "center", whiteSpace: "nowrap" }}
              >
                <font color="#8B0000">
                  Daily Shift Wise Process Check List
                </font>
              </th>
            </tr>
            <tr>
              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>Date</td>
              <td colSpan="1">
                <input
                  id="DailyShiftProcess-filter-date"
                  name="DailyShiftProcess-filter-date"
                  type="date"
                  placeholder="Filter by Date"
                  value={DailyShiftProcess.filterDate}
                  onChange={handleFilterDateChange}
                />
              </td>
              <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                Machine No
              </td>
              <td colSpan={2}>
                <input
                  id="DailyShiftProcess-filter-MaterialName"
                  name="DailyShiftProcess-filter-MaterialName"
                  type="text"
                  placeholder="Filter MachineNo"
                  style={{ fontSize: "70%", width: "100%" }}
                  value={DailyShiftProcess.filterMcno}
                  onChange={handleFilterMcnoChange}
                />
              </td>
              <td colSpan="9"></td>
            </tr>
            {/* <tr className="table_position"> */}
            <tr>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;Date&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;Machine No&nbsp;&nbsp;
              </th>

              <th style={{  whiteSpace: "nowrap" }}>
               &nbsp;Mold Safety Pressure&nbsp;&nbsp;
              </th>

              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Shift&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Operator&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Mold Setter&nbsp;&nbsp;
              </th>
              <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Incharge Name&nbsp;&nbsp;
              </th>
              {/* <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Senior Engg&nbsp;&nbsp;
              </th> */}
              {/* <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                &nbsp;&nbsp;Created Date&nbsp;&nbsp;
              </th> */}
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {/* <HandleRead keyword="Daily_shift_process_checklist" setData={setDaily_shift_pro} /> */}

            {filteredRecords.length === 0 ? (
              // <tr>
              <td colSpan="12" style={{ textAlign: "center" }}>
                <p
                  id="DailyShiftProcess-list-NoRec"
                  name="DailyShiftProcess-list-noRec"
                  style={{ fontWeight: "bold" }}
                >
                  No records found.
                </p>
              </td>
            ) : (
              // </tr>
              filteredRecords.map((Daily_shift_pro) => (
                <tr className="table_view" key={Daily_shift_pro.Id}>
                  <td
                    id="DailyShiftProcess-list-Shift"
                    name="DailyShiftProcess-list-Shift"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{formatDt(Daily_shift_pro.TrackDate)}
                  </td>
                  <td
                    id="DailyShiftProcess-list-TrackDate"
                    name="DailyShiftProcess-list-TrackDate"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{Daily_shift_pro.Machine_no}
                  </td>
                  <td
                    id="DailyShiftProcess-list-MatGrde"
                    name="DailyShiftProcess-list-MatGrde"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{Daily_shift_pro.Mold_saf_pres_blw_1}
                  </td>

                  <td
                    id="DailyShiftProcess-list-MatLotNo"
                    name="DailyShiftProcess-list-MatLotNo"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{Daily_shift_pro.Shift}
                  </td>
                  <td
                    id="DailyShiftProcess-list-MatLdKgs"
                    name="DailyShiftProcess-list-MatLdKgs"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{Daily_shift_pro.Operator_1}-
                    {Daily_shift_pro.Operator}
                  </td>
                  <td
                    id="DailyShiftProcess-list-MatLdTime"
                    name="DailyShiftProcess-list-MatLdTime"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{Daily_shift_pro.Mold_setter_1}-
                    {Daily_shift_pro.MoldSetter}
                  </td>
                  <td
                    id="DailyShiftProcess-list-PreHtHrs"
                    name="DailyShiftProcess-list-PreHtHrs"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{Daily_shift_pro.Inchrge_1}-
                    {Daily_shift_pro.Inchrge}
                  </td>
                  {/* <td
                    id="DailyShiftProcess-list-PreHtTmp"
                    name="DailyShiftProcess-list-PreHtTmp"
                    className="table-cell"
                  >
                    &nbsp;&nbsp;{Daily_shift_pro.Senior_engg_1}-
                    {Daily_shift_pro.SeniorEngg}
                  </td> */}

                  {/* <td id="DailyShiftProcess-list-createddate"
        name="DailyShiftProcess-list-createddate"
        className="table-cell">&nbsp;&nbsp;{Daily_shift_pro.Mat_cr_dt}</td> */}
                  <td style={{width:"10%"}}>
                    <button
                      id="DailyShiftProcess-view"
                      name="DailyShiftProcess-view"
                      style={{ backgroundColor: "#cba423" }}
                      type="button"
                      onClick={() => handleView(Daily_shift_pro)}
                    >
                      View&#x1F441;
                    </button>
                  </td>
                  <td style={{width:"10%"}}>
                    <button
                      id="DailyShiftProcess-edit"
                      name="DailyShiftProcess-edit"
                      type="button"
                      onClick={() => handleEdit(Daily_shift_pro)}
                    >
                      Edit&#x270E;
                    </button>
                  </td>
                  <td style={{width:"10%"}}>
                    <button
                      id={`${idEdit}-Delete`}
                      name={`${idEdit}-Delete`}
                      style={{
                        whiteSpace: "nowrap",
                        backgroundColor: "#DA251A",
                      }}
                      type="button"
                      onClick={() => {
                        const userConfirmed = window.confirm(
                          "Are you sure you want to delete?"
                        );
                        if (userConfirmed) {
                          UpdateRecord({
                            _keyword_: "Daily_shift_process_checklist",
                            data: [
                              {
                                Id: Daily_shift_pro.Id,
                                ActiveStatus: "false",
                              },
                            ],
                          }).then(() => {
                            handleReadDailyCheckSheet();
                          });
                          // window.location.reload();
                        }
                      }}
                    >
                      Delete
                      <MdDelete />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      
    
      </div>
      <div className="pagination" id="pagination">
          {DailyShiftProcess.isPaginationVisible && filteredData.length > 0 && (
            <Pagination
              currentPage={DailyShiftProcess.currentPage}
              npage={npage}
              prePage={prePage}
              nextPage={nextPage}
              changeCpage={changeCpage}
              numbers={numbers}
            />
          )}
        </div>
    </div>
  );
}

export default DailyShiftProcessCheckListView;
