import React,{useEffect, useState} from "react";
import { Tab, Tabs } from "react-bootstrap";
import EmployeeMasterCreate from "./EmployeeMasterCreate";
import EmployeeMasterView from "./EmployeeMasterView";
import AccessListTab from "./AccessListTab";



function EmployeeMasterTab() {
    const [viewTabClicked, setViewTabClicked] = useState(false);
    const [activeKey, setActiveKey] = useState("tab1");
   const handleTabClick = (tabKey) => {
    setActiveKey(tabKey);
     if (tabKey === "tab2") {
       setViewTabClicked(true);
     } else {
       setViewTabClicked(false);
     }
   };
   useEffect(() => {
    document.title = "Employee Master"
  })
   return (
     <div>
       <div>
         <Tabs
           defaultActiveKey="tab1"
           // transition={false}
           id="noanim-tab-example"
           className="mb-3"
            onSelect={handleTabClick}
         >
           <Tab name="emp.mas.CreateTab" eventKey="tab1" title="Create">
             <div>
               <div className="set-layout">
                 <EmployeeMasterCreate tab1={activeKey === "tab1"}/>
               </div>
             </div>
           </Tab>
           <Tab name="emp.mas.ViewTab" eventKey="tab2" title="View">
             <div>
               <div className="set-layout">
                 {viewTabClicked && <EmployeeMasterView/>}
               </div>
             </div>
           </Tab>
           <Tab name="emp.mas.AccessTab" eventKey="tab3" title="Access">
             <div>
               <div className="set-layout">
                <AccessListTab/>
               </div>
             </div>
           </Tab>
         </Tabs>
       </div>
     </div>
   );
 }
 
 export default EmployeeMasterTab;